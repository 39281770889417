import ApiClient from './apiClient'
import {
  FamilyStructureResponse,
  FamilyStructureUpdateValues,
} from '../models/familyStructuresModel'

export const getFamilyStructure = async () => {
  return await ApiClient.get(
    '/family_structures',
    (response: FamilyStructureResponse) => response,
  )
}

export const getFamilyStructureV2 = async () => {
  return await ApiClient.get(
    '/family_structures',
    (response: FamilyStructureResponse) => response,
    undefined,
    false,
    'v2',
  )
}

export const patchFamilyStructure = async (values: FamilyStructureUpdateValues) => {
  return await ApiClient.patch('/family_structures', () => {}, values)
}
