import {
  isDateExisted,
  isNotEmptyValue,
  castNumberWithoutOperator,
} from '@/models/commonsModelFunc'
import moment from 'moment'

const message = {
  required: '必須項目です。',
  mailAddress: '正しいメールアドレスの形式でご入力ください。',
  password: '英文字、数字を組み合わせた8文字以上72文字以内でご入力ください',
  match: 'が一致していません。',
  passwordMismatch: 'パスワードが一致していません。',
  kana: 'ひらがなでご入力ください。',
  number: '半角の数字でご入力ください。',
  phoneNumber: 'ハイフンを含まない、半角の数字でご入力ください。',
  floatNumber: '半角の数字あるいは小数でご入力ください。',
  numberIncludingDecimal: '半角数字(小数点も可)でご入力ください。',
  limitOrMoreNumber: '以上でご入力ください。',
  limitLessThanNumber: '以下でご入力ください。',
  limitRangeNumber: 'までの範囲でご入力ください。',
  greaterThanNumber: 'より大きい数字でご入力ください。',
  minLength: '桁以上でご入力ください。',
  maxLength: '桁以下でご入力ください。',
  specificNumberLength: '桁でご入力ください。',
  specificOtherNumberLength: '桁か',
  date: '正しい日にちをご入力ください。',
  afterDate: '開始日より後の日付をご入力ください。',
  allChecked: '全ての項目にチェックを入れてください。',
}

const Regex = {
  mailAddress: /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
  password: /^(?=.*?[a-zA-Z])(?=.*?\d)[a-zA-Z\d!-~]{8,72}$/,
  kana: /^[ぁ-んー　]*$/,
  number: /^[0-9-]+$/,
  phoneNumber: /^[0-9]+$/,
  numberIncludingDecimal: /^[0-9]+\.?[0-9]+$/,
  floatNumber: /^([1-9]\d*|0)(\.\d+)?$/,
}

export const composeValidators = (...validators: any[]) => (
  value: any,
  allValues?: any,
) => {
  return validators.reduce((error, validator) => {
    return error || validator?.(value, allValues)
  }, undefined)
}

export const required = (value: any) => {
  return isNotEmptyValue(value) ? undefined : message.required
}

export const requiredForArray = (value: any) => {
  return value instanceof Array && value.length > 0 ? undefined : message.required
}

export const requiredForArrayAllChecked = (value: any, length: number) => {
  return value instanceof Array && value.length === length
    ? undefined
    : message.allChecked
}

export const mailAddress = (value: any) => {
  return value && !Regex.mailAddress.test(value) ? message.mailAddress : undefined
}

export const password = (value: any) => {
  return value && !Regex.password.test(value) ? message.password : undefined
}

export const passwordMatch = (password: string, passwordConfirmation: string) => {
  return password !== passwordConfirmation ? message.passwordMismatch : undefined
}

export const match = (value1: any, value2: any, prefix: string) => {
  return value1 !== value2 ? `${prefix}${message.match}` : undefined
}

export const kana = (value: any) => {
  return value && !Regex.kana.test(value) ? message.kana : undefined
}

export const number = (value: any) => {
  return value && !Regex.number.test(value) ? message.number : undefined
}

export const phoneNumber = (value: any) => {
  return value && !Regex.phoneNumber.test(value) ? message.phoneNumber : undefined
}

export const numberIncludingDecimal = (value: any) => {
  return value && !Regex.numberIncludingDecimal.test(value)
    ? message.numberIncludingDecimal
    : undefined
}

export const floatNumber = (value: any) => {
  return value && !Regex.floatNumber.test(value) ? message.floatNumber : undefined
}

export const zeroOrMoreNumber = (value: any) => {
  return value && Number(value) < 0 ? `0${message.limitOrMoreNumber}` : undefined
}

export const zeroGreaterThanNumber = (value: any) => {
  return value && Number(value) <= 0 ? `0${message.greaterThanNumber}` : undefined
}

export const oneOrMoreNumber = (value: any) => {
  return value && Number(value) < 1 ? `1${message.limitOrMoreNumber}` : undefined
}

export const limitOrMoreNumber = (value: any, lower: number) => {
  return value && Number(value) < lower
    ? `${lower.toLocaleString()}${message.limitOrMoreNumber}`
    : undefined
}

export const oneHundredLessThanNumber = (value: any) => {
  return value && Number(value) > 100 ? `100${message.limitLessThanNumber}` : undefined
}

export const lessThanOrEqualNumber = (value: any, maxNum: number) => {
  return value && Number(value) > maxNum
    ? `${maxNum.toLocaleString()}${message.limitLessThanNumber}`
    : undefined
}

export const limitRangeNumber = (value: any, lower: number, upper: number) => {
  return value && (Number(value) < lower || upper < Number(value))
    ? `${lower}〜${upper}${message.limitRangeNumber}`
    : undefined
}

export const minLength = (value: any, length: number) => {
  return value && value.length < length ? `${length}${message.minLength}` : undefined
}

export const maxLength = (value: any, length: number) => {
  return value && value.length > length ? `${length}${message.maxLength}` : undefined
}

export const commaNumberMaxLength = (value: any, length: number) => {
  return value && String(castNumberWithoutOperator(value)).length > length
    ? `${length}${message.maxLength}`
    : undefined
}

export const specificNumberLength = (value: any, length: number) => {
  return value && value.length !== length
    ? `${length}${message.specificNumberLength}`
    : undefined
}

export const twoSpecificNumbersLength = (value: any, length: number, length2: number) => {
  return value && value.length !== length && value.length !== length2
    ? `${length}${message.specificOtherNumberLength}${length2}${message.specificNumberLength}`
    : undefined
}

export const date = (value: any) => {
  return !isDateExisted(value) ? message.date : undefined
}

export const afterDate = (value: any, compareDate: string) => {
  return !moment(value).isAfter(moment(compareDate)) ? message.afterDate : undefined
}
