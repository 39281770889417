import LocalStorage from '@/utils/localStorage'

const KEY = 'isDisabledPaidAccount'

export const getStorageIsDisabledPaidAccount = () => {
  return LocalStorage.getItem(KEY)
}

export const setStorageIsDisabledPaidAccount = (value: boolean) => {
  return LocalStorage.setItem(KEY, value)
}

export const removeStorageIsDisabledPaidAccount = () => {
  LocalStorage.removeItem(KEY)
}
