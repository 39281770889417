import React, { FC, useMemo } from 'react'
import { useField } from 'react-final-form'
import Typography from '@/components/v2/atoms/Typography'
import NumberField from '@/components/v2/molecules/NumberField'
import {
  commaNumberMaxLength,
  composeValidators,
  required,
  zeroOrMoreNumber,
} from '@/utils/validate'
import { getTargetRentAttributesName } from '@/models/commonHousingModelFunc'
import { INPUT_HOUSING_RENT_TYPE } from '@/models/inputs/inputHousingRentsModelFunc'

interface MonthlyRentProps {
  isFuture?: boolean
}

const MonthlyRent: FC<MonthlyRentProps> = (props) => {
  const { isFuture } = props
  const targetHousingRentAttributesName = getTargetRentAttributesName(isFuture)
  const {
    input: { value: rentType },
  } = useField(`${targetHousingRentAttributesName}[0]rent_type`)
  const isNotSelfRentType = useMemo(
    () =>
      rentType === INPUT_HOUSING_RENT_TYPE.companyRent ||
      rentType === INPUT_HOUSING_RENT_TYPE.allowance,
    [rentType],
  )
  const rentMonthlyLabel = (isNotSelfRentType ? '本来の' : '') + '家賃'

  return (
    <>
      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
        <Typography
          className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
          isBold
        >
          {rentMonthlyLabel}
        </Typography>
        <div className="flex flex-auto items-baseline">
          <NumberField
            name={
              isNotSelfRentType
                ? `${targetHousingRentAttributesName}[0]whole_rent_monthly`
                : `${targetHousingRentAttributesName}[0]rent_monthly`
            }
            isFullWidth
            validate={composeValidators(required, zeroOrMoreNumber, (value: any) =>
              commaNumberMaxLength(value, 10),
            )}
          />
          <Typography className="pl-[8px] text-[14px] text-black-800 min-w-[45px]">
            円/月
          </Typography>
        </div>
      </div>
      {isNotSelfRentType && (
        <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
          <Typography
            className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
            isBold
          >
            {`自分で${isFuture ? '払う' : '払っている'}金額`}
          </Typography>
          <div className="flex flex-auto items-baseline">
            <NumberField
              name={`${targetHousingRentAttributesName}[0]rent_monthly`}
              isFullWidth
              validate={composeValidators(required, zeroOrMoreNumber, (value: any) =>
                commaNumberMaxLength(value, 10),
              )}
            />
            <Typography
              className="min-w-[fit-content] pl-[8px] text-[14px] text-black-800"
              isBold
            >
              円/月
            </Typography>
          </div>
        </div>
      )}
    </>
  )
}

export default MonthlyRent
