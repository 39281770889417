import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { unauthorizedCheck } from '../accountsSlice'
import {
  getInputContributionPensions,
  postInputContributionPension,
  patchInputContributionPension,
  deleteInputContributionPension,
} from '../../api/inputs/inputContributionPensions'
import { ApiError } from '../../models/commonsModel'
import {
  InputContributionPension,
  InputContributionPensionCreateValues,
  InputContributionPensionUpdateValues,
} from '../../models/inputs/inputContributionPensionsModel'
import { AppThunk } from '../../store'

interface InputContributionPensionsState {
  inputContributionPensions: InputContributionPension[]
  isLoading: boolean
}

const initialState: InputContributionPensionsState = {
  inputContributionPensions: [],
  isLoading: false,
}

const inputContributionPensionsSlice = createSlice({
  name: 'inputContributionPensions',
  initialState,
  reducers: {
    setInputContributionPensions: (
      state,
      action: PayloadAction<InputContributionPension[]>,
    ) => {
      state.inputContributionPensions = action.payload
      return state
    },
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    resetInputContributionPensionsStore: (state) => {
      state = initialState
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    getInputContributionPensionsSuccess: (
      state,
      action: PayloadAction<InputContributionPension[]>,
    ) => {
      state.inputContributionPensions = action.payload
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
  },
})

export const {
  setInputContributionPensions,
  requestStart,
  resetInputContributionPensionsStore,
  requestSuccess,
  getInputContributionPensionsSuccess,
  requestFailure,
} = inputContributionPensionsSlice.actions
export const inputContributionPensionsReducer = inputContributionPensionsSlice.reducer

export const fetchInputContributionPensions = (): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  getInputContributionPensions()
    .then((response) => dispatch(getInputContributionPensionsSuccess(response)))
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const createInputContributionPension = (
  values: InputContributionPensionCreateValues,
  callback: () => void,
  resolve: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postInputContributionPension(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error, resolve))
    })
}

export const updateInputContributionPension = (
  id: number,
  values: InputContributionPensionUpdateValues,
  callback: () => void,
  resolve: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchInputContributionPension(id, values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error, resolve))
    })
}

export const destroyInputContributionPension = (
  id: number,
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  deleteInputContributionPension(id)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}
