import React, { FC, useEffect, useState, useMemo, useContext, ChangeEvent } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  reduce as _reduce,
  filter as _filter,
  maxBy as _maxBy,
  minBy as _minBy,
  sortBy as _sortBy,
} from 'lodash'
import Typography from '@/components/v2/atoms/Typography'
import Button from '@/components/v2/atoms/Button'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import GraphComposed from '@/components/v2/molecules/GraphComposed'
import renderCustomArea from '@/components/v2/molecules/renderCustomArea'
import renderCustomBar from '@/components/v2/molecules/renderCustomBar'
import renderCustomLine from '@/components/v2/molecules/renderCustomLine'
import { SelectBoxOption } from '@/components/v2/molecules/SelectBox'
import Tab from '@/components/v2/molecules/Tab'
import SelectBoxWithoutFinalForm from '@/components/v2/molecules/SelectBoxWithoutFinalForm'
import {
  fetchNewDetailFutureBase,
  fetchNewAdminDetailFutureBase,
} from '@/containers/futures/futureBasesSlice'
import { useRechartsActiveInfo } from '@/hooks/useRecharts'
import { AccountContext } from '@/models/accountsModelFunc'
import { RootState } from '@/store'
import TooltipBalance from '@/templates/v2/simulations/_tooltipBalance'
import TooltipInvestment from '@/templates/v2/simulations/_tooltipInvestment'
import TooltipLoan from '@/templates/v2/simulations/_tooltipLoan'
import TooltipNetAsset from '@/templates/v2/simulations/_tooltipNetAsset'
import TooltipRisk from '@/templates/v2/simulations/_tooltipRisk'
import Modal from '@/components/v2/atoms/Modal'
import {
  getBalanceGraphTicks,
  getInsuranceGraphTicks,
  getInvestmentGraphTicks,
  getLoanGraphTicks,
  getMaxSizeEstateEvaluationObject,
  getMaxSizeEstateRemainLoanObject,
  getNetAssetGraphTicks,
} from '@/utils/graph'

export const SIMULATION_GRAPH_TAB_INDEX = {
  investment: 0,
  netAsset: 1,
  loan: 2,
  balance: 3,
  risk: 4,
}

export const ESTATE_EVALUATION_COLOR = [
  '#DDE283',
  '#f4f9a2',
  '#FAFCDA',
  '#F4F6C2',
  '#FAFCDA',
  '#FAFCDA',
  '#FAFCDA',
  '#FAFCDA',
  '#FAFCDA',
  '#FAFCDA',
]
export const ESTATE_REMAIN_LOANS_COLOR = [
  '#ADD6F0',
  '#c6e5f9',
  '#EBF7FF',
  '#D6EFFF',
  '#EBF7FF',
  '#EBF7FF',
  '#EBF7FF',
  '#EBF7FF',
  '#EBF7FF',
  '#EBF7FF',
]

const MY_DATA_VALUE = 0

interface SimulationGraphBodyProps {
  /** クラス名 */
  className?: string
  /** true: 比較有り, false: 比較無し */
  isCompare: boolean
  /** true: マイデータ, false: シミュレーションプラン */
  isCompareMyData: boolean
  /** 比較フラグ変更メソッド */
  setIsCompare: (isCompare: boolean) => void
  /** マイデータフラグ変更メソッド */
  setIsCompareMyData: (isCompareMyData: boolean) => void
  /** 保険グラフ表示フラグ変更メソッド */
  setIsShowInsurance: (isShowInsurance: boolean) => void
  /** グラフローディングフラグ変更メソッド */
  setIsGraphLoading: (isLoading: boolean) => void
}

const SimulationGraphBody: FC<SimulationGraphBodyProps> = (props) => {
  const dispatch = useDispatch()
  const { isAdmin } = useContext(AccountContext)

  const {
    className,
    isCompare,
    isCompareMyData,
    setIsCompare,
    setIsCompareMyData,
    setIsShowInsurance,
  } = props
  const { futureBasesV2, detailFutureBase, detailFutureBaseV2 } = useSelector(
    (state: RootState) => state.futureBases,
  )

  const { familyInfoV2 } = useSelector((state: RootState) => state.familyStructures)

  const [activeInfo, setActiveInfo, resetActiveInfo] = useRechartsActiveInfo()
  const [breakpoint, setBreakpoint] = useState<string>('lg')
  const [comparePlanName, setComparePlanName] = useState<string>(
    'マイデータ設定の生活を続けた場合',
  )
  const [selectedAge, setSelectedAge] = useState<number | ''>('')
  const [selectedFutureBaseId, setSelectedFutureBaseId] = useState<Number>(MY_DATA_VALUE)
  const [currentTabIndex, setCurrentTabIndex] = useState<number>(
    SIMULATION_GRAPH_TAB_INDEX.investment,
  )
  const [isCompareGraphLoading, setIsCompareGraphLoading] = useState<boolean>(false)
  const [isCurrentGraphLoading, setIsCurrentGraphLoading] = useState<boolean>(false)

  const [isOpenCompareGraphModal, setIsOpenCompareGraphModal] = useState<boolean>(false)
  const [isOpenCurrentGraphModal, setIsOpenCurrentGraphModal] = useState<boolean>(false)

  const { isGetDetailFutureBaseLoading } = useSelector(
    (state: RootState) => state.futureBases,
  )

  const futureBaseIdOptions = useMemo(() => {
    const myDataOptions = [
      { value: MY_DATA_VALUE, label: 'マイデータ設定の生活を続けた場合' },
    ]
    const planOptions = futureBasesV2.map((item) => ({
      value: item.id,
      label: item.future_name || '（プラン名未設定）',
    }))

    return [...myDataOptions, ...planOptions]
  }, [futureBasesV2])

  const ageOptions = useMemo(
    () =>
      _reduce(
        detailFutureBaseV2.v2_investment_graph,
        (result: SelectBoxOption[], item) => {
          if (item.age % 10 === 0) {
            result.push({ value: item.age, label: `${item.age}歳まで` })
          }
          return result
        },
        [],
      ),
    [detailFutureBaseV2],
  )

  const simulationGraphs = useMemo(() => {
    const filterFn = (arr: any) => {
      if (selectedAge === '') return arr
      return _filter(arr, (item) => item.age <= selectedAge)
    }

    const dfb2 = detailFutureBaseV2
    return {
      v2InvestmentGraph: filterFn(dfb2.v2_investment_graph),
      netAssetGraph: filterFn(dfb2.net_asset_graph),
      loanGraph: filterFn(dfb2.loan_graph),
      v2BalanceGraph: filterFn(dfb2.v2_balance_graph),
      insuranceGraph: filterFn(dfb2.insurance_graph),
    }
  }, [selectedAge, detailFutureBaseV2])

  const compareSimulationGraphs = useMemo(() => {
    const filterFn = (arr: any) => {
      const thisYear = new Date().getFullYear()
      return selectedAge === ''
        ? _filter(arr, (item) => thisYear <= item.name)
        : _filter(arr, (item) => thisYear <= item.name && item.age <= selectedAge)
    }

    if (isCompareMyData) {
      const dfb2 = detailFutureBaseV2
      return {
        v2InvestmentGraph: filterFn(dfb2.input_v2_investment_graph),
        netAssetGraph: filterFn(dfb2.input_net_asset_graph),
        loanGraph: filterFn(dfb2.input_loan_graph),
        v2BalanceGraph: filterFn(dfb2.input_v2_balance_graph),
        insuranceGraph: filterFn(dfb2.input_insurance_graph),
      }
    }

    const dfb = detailFutureBase
    return {
      v2InvestmentGraph: filterFn(dfb.v2_investment_graph),
      netAssetGraph: filterFn(dfb.net_asset_graph),
      loanGraph: filterFn(dfb.loan_graph),
      v2BalanceGraph: filterFn(dfb.v2_balance_graph),
      insuranceGraph: filterFn(dfb.insurance_graph),
    }
  }, [selectedAge, detailFutureBase, detailFutureBaseV2, isCompareMyData])

  const graphLength = simulationGraphs.v2InvestmentGraph?.length

  const handleWindowResize = () => {
    let breakpoint = ''

    if (window.innerWidth >= 1024) {
      breakpoint = 'lg'
    } else if (window.innerWidth >= 768) {
      breakpoint = 'md'
    } else {
      breakpoint = 'sm'
    }

    setBreakpoint(breakpoint)
  }

  const handleClickAway = () => {
    resetActiveInfo()
  }

  useEffect(() => {
    handleWindowResize()
    window.addEventListener('resize', handleWindowResize)
    document.addEventListener('mousedown', handleClickAway)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
      document.removeEventListener('mousedown', handleClickAway)
    }
  }, [])

  useEffect(() => {
    const personAge = familyInfoV2?.person_age

    if (personAge) {
      const minimumRenderingYears = 40
      let targetAge = 80
      let renderingYears = targetAge - personAge

      if (renderingYears < minimumRenderingYears) {
        // 1の位を四捨五入する
        targetAge = Math.ceil((personAge + minimumRenderingYears) / 10) * 10
        // 上限は110歳
        targetAge = Math.min(targetAge, 110)
      }

      setSelectedAge(targetAge)
    }
  }, [familyInfoV2])

  const maxSizeEstateEvaluationObject = useMemo(() => {
    return getMaxSizeEstateEvaluationObject(simulationGraphs)
  }, [simulationGraphs?.loanGraph])
  const maxSizeEstateRemainLoanObject = useMemo(() => {
    return getMaxSizeEstateRemainLoanObject(simulationGraphs)
  }, [simulationGraphs?.loanGraph])

  const maxSizeEstateEvaluationCompareObject = useMemo(() => {
    return getMaxSizeEstateEvaluationObject(compareSimulationGraphs)
  }, [compareSimulationGraphs?.loanGraph])
  const maxSizeEstateRemainLoanCompareObject = useMemo(() => {
    return getMaxSizeEstateRemainLoanObject(compareSimulationGraphs)
  }, [compareSimulationGraphs?.loanGraph])

  const setTooltipPosition = () => {
    const y = 0
    const x =
      !isCompare && graphLength / 2 < activeInfo.activeIndex
        ? activeInfo.x - 250
        : activeInfo.x + 20

    return { x, y }
  }

  const setGraphWidth = () => {
    return breakpoint === 'sm' ? 1280 : undefined
  }

  const setGraphHeight = () => {
    return breakpoint === 'lg' ? 400 : 250
  }

  const handleChangeAge = (age: number) => {
    setSelectedAge(age)
    resetActiveInfo()
  }

  const handleClickLine = (payload: any) => {
    setActiveInfo({ ...payload.payload, x: payload.cx, y: payload.cy }, payload.index)
  }

  const handleChangeFutureBaseId = (futureBaseId: number) => {
    resetActiveInfo()
    setSelectedFutureBaseId(futureBaseId)
    setIsCompareGraphLoading(true)

    if (futureBaseId === MY_DATA_VALUE) {
      setIsCompareMyData(true)
      setComparePlanName('マイデータ')
      return
    }

    dispatch(
      isAdmin
        ? fetchNewAdminDetailFutureBase(futureBaseId, (response: any) => {
            setIsCompareMyData(false)
            setComparePlanName(
              response.simulation_info?.future_name || '（プラン名未設定）',
            )
          })
        : fetchNewDetailFutureBase(futureBaseId, (response: any) => {
            setIsCompareMyData(false)
            setComparePlanName(
              response.simulation_info?.future_name || '（プラン名未設定）',
            )
          }),
    )
  }

  useEffect(() => {
    if (isCurrentGraphLoading && !isGetDetailFutureBaseLoading) {
      if (isCompareGraphLoading) setIsCompareGraphLoading(false)
    }
    setIsCurrentGraphLoading(isGetDetailFutureBaseLoading)
  }, [isGetDetailFutureBaseLoading])

  useEffect(() => {
    props.setIsGraphLoading(isCompareGraphLoading)
  }, [isCompareGraphLoading])

  const investmentGraphTicks = useMemo(() => {
    return isCompare
      ? getInvestmentGraphTicks(
          simulationGraphs.v2InvestmentGraph,
          compareSimulationGraphs.v2InvestmentGraph,
        )
      : undefined
  }, [
    simulationGraphs.v2InvestmentGraph,
    compareSimulationGraphs.v2InvestmentGraph,
    isCompare,
  ])

  const netAssetGraphTicks = useMemo(() => {
    return isCompare
      ? getNetAssetGraphTicks(
          simulationGraphs.netAssetGraph,
          compareSimulationGraphs.netAssetGraph,
        )
      : undefined
  }, [simulationGraphs.netAssetGraph, compareSimulationGraphs.netAssetGraph, isCompare])

  const loanGraphTicks = useMemo(() => {
    return isCompare
      ? getLoanGraphTicks(simulationGraphs.loanGraph, compareSimulationGraphs.loanGraph)
      : undefined
  }, [simulationGraphs.loanGraph, compareSimulationGraphs.loanGraph, isCompare])

  const balanceGraphTicks = useMemo(() => {
    return isCompare
      ? getBalanceGraphTicks(
          simulationGraphs.v2BalanceGraph,
          compareSimulationGraphs.v2BalanceGraph,
        )
      : undefined
  }, [simulationGraphs.v2BalanceGraph, compareSimulationGraphs.v2BalanceGraph, isCompare])

  const insuranceGraphTicks = useMemo(() => {
    return isCompare
      ? getInsuranceGraphTicks(
          simulationGraphs.insuranceGraph,
          compareSimulationGraphs.insuranceGraph,
        )
      : undefined
  }, [simulationGraphs.insuranceGraph, compareSimulationGraphs.insuranceGraph, isCompare])

  return (
    <>
      <div className={className}>
        <CircleTitle
          classNameTitle="text-18 md:text-24"
          className="pb-[24px]"
          title="ライフプランシミュレーショングラフ"
        />

        <div className="flex flex-col gap-y-[20px] pb-[20px] md:flex-row md:items-baseline md:gap-x-[40px] md:pb-[40px]">
          <div className="md:flex items-baseline gap-x-[12px]">
            <Typography className="text-[14px]" isBold>
              期間指定
            </Typography>
            <SelectBoxWithoutFinalForm
              name="age"
              placeholder="選択する"
              widthClassName="w-[285px]"
              value={selectedAge}
              options={ageOptions}
              isFullWidth
              onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                handleChangeAge(Number(e.target.value))
              }
            />
          </div>

          <div className="flex gap-x-[12px] items-baseline">
            <Typography className="leading-normal text-[14px] text-black-900">
              他のプランと比較する
            </Typography>
            <div className="flex border-black-500 border rounded-[3px] overflow-hidden">
              <button
                className="flex items-center justify-center disabled:cursor-default disabled:bg-action-600 bg-black-300 rounded-none w-[48px] h-[24px] disabled:text-white text-black-500"
                disabled={isCompare}
                onClick={() => {
                  setIsCompare(true)
                }}
              >
                <Typography className="text-[14px]" isBold>
                  ON
                </Typography>
              </button>

              <button
                className="flex items-center justify-center disabled:cursor-default disabled:bg-action-600 bg-black-300 rounded-none w-[48px] h-[24px] disabled:text-white text-black-500"
                disabled={!isCompare}
                onClick={() => {
                  setIsCompare(false)
                }}
              >
                <Typography className="text-[14px]" isBold>
                  OFF
                </Typography>
              </button>
            </div>
          </div>
        </div>

        <Tab
          className={'h-[50px]' + (!isCompare ? ' mb-[50px]' : '')}
          currentIndex={currentTabIndex}
          options={[
            '金融資産',
            '純資産',
            '不動産<span class="hidden md:inline">・</span><br class="block md:hidden" />ローン',
            '収支',
            '保険',
          ]}
          onClick={(index) => {
            setCurrentTabIndex(index)
            setIsShowInsurance(index === SIMULATION_GRAPH_TAB_INDEX.risk)
          }}
        />

        <div className="flex flex-col sm:flex-row pb-[48px]">
          <div className={isCompare ? 'w-full sm:w-3/6' : 'w-full'}>
            {isCompare && (
              <div className="flex h-[60px] items-center justify-between">
                <Typography
                  className="sticky left-0 pl-[66px] pt-[4px] text-[14px] leading-normal text-black-900"
                  isBold
                >
                  {isCompare
                    ? '作成中のシミュレーションの場合'
                    : '将来の予定を実行した場合（作成中のシミュレーション）'}
                </Typography>

                <Button
                  className="border border-solid rounded-[8px] border-secondary-500 px-[8px] py-[6px]"
                  onClick={() => setIsOpenCurrentGraphModal(true)}
                >
                  <Typography className="text-[10px] leading-normal text-secondary-500 whitespace-nowrap">
                    拡大
                  </Typography>
                </Button>
              </div>
            )}
            {currentTabIndex === SIMULATION_GRAPH_TAB_INDEX.investment && (
              <GraphComposed
                id="v2-investment-graph"
                width={setGraphWidth()}
                height={setGraphHeight()}
                xDataKey="age"
                data={simulationGraphs.v2InvestmentGraph}
                tooltipHide={breakpoint === 'sm' || (breakpoint === 'md' && isCompare)}
                tooltipPosition={{ x: setTooltipPosition().x, y: setTooltipPosition().y }}
                tooltipContent={
                  <TooltipInvestment
                    activeIndex={activeInfo.activeIndex}
                    basePlanName="現在作成中のプラン"
                    baseItem={simulationGraphs.v2InvestmentGraph}
                    isCompare={isCompare}
                    comparePlanName={comparePlanName}
                    compareItem={compareSimulationGraphs.v2InvestmentGraph}
                  />
                }
                yTicks={investmentGraphTicks}
              >
                {renderCustomBar({
                  name: '流動性資産',
                  dataKey: 'deposit',
                  activeIndex: activeInfo.activeIndex,
                  stackId: 'investment',
                  color: '#B9D3E9',
                  data: simulationGraphs.v2InvestmentGraph,
                  onClick: setActiveInfo,
                })}
                {renderCustomBar({
                  name: '投資信託',
                  dataKey: 'investment_trust',
                  activeIndex: activeInfo.activeIndex,
                  stackId: 'investment',
                  color: '#BBE1DF',
                  data: simulationGraphs.v2InvestmentGraph,
                  onClick: setActiveInfo,
                })}
                {renderCustomBar({
                  name: '株式',
                  dataKey: 'stock',
                  activeIndex: activeInfo.activeIndex,
                  stackId: 'investment',
                  color: '#F9EBA3',
                  data: simulationGraphs.v2InvestmentGraph,
                  onClick: setActiveInfo,
                })}
                {renderCustomBar({
                  name: '債券',
                  dataKey: 'bond',
                  activeIndex: activeInfo.activeIndex,
                  stackId: 'investment',
                  color: '#FF9933',
                  data: simulationGraphs.v2InvestmentGraph,
                  onClick: setActiveInfo,
                })}
                {renderCustomBar({
                  name: '社内預金・財形・持株など',
                  dataKey: 'deducted_savings',
                  activeIndex: activeInfo.activeIndex,
                  stackId: 'investment',
                  color: '#CC6666',
                  data: simulationGraphs.v2InvestmentGraph,
                  onClick: setActiveInfo,
                })}
                {renderCustomBar({
                  name: 'その他',
                  dataKey: 'other',
                  activeIndex: activeInfo.activeIndex,
                  stackId: 'investment',
                  color: '#E1E0E2',
                  data: simulationGraphs.v2InvestmentGraph,
                  onClick: setActiveInfo,
                })}
                {renderCustomBar({
                  name: 'マイナス資産',
                  dataKey: 'substruction',
                  activeIndex: activeInfo.activeIndex,
                  stackId: 'investment',
                  color: '#D4C7DA',
                  data: simulationGraphs.v2InvestmentGraph,
                  onClick: setActiveInfo,
                })}
                {renderCustomBar({
                  name: '貯蓄用保険資産',
                  dataKey: 'insurance_asset',
                  activeIndex: activeInfo.activeIndex,
                  stackId: 'investment',
                  color: '#F3BCC2',
                  data: simulationGraphs.v2InvestmentGraph,
                  onClick: setActiveInfo,
                })}
              </GraphComposed>
            )}

            {currentTabIndex === SIMULATION_GRAPH_TAB_INDEX.netAsset && (
              <GraphComposed
                id="net-asset-graph"
                width={setGraphWidth()}
                height={setGraphHeight()}
                xDataKey="age"
                data={simulationGraphs.netAssetGraph}
                tooltipHide={breakpoint === 'sm' || (breakpoint === 'md' && isCompare)}
                tooltipPosition={{ x: setTooltipPosition().x, y: setTooltipPosition().y }}
                tooltipContent={
                  <TooltipNetAsset
                    activeIndex={activeInfo.activeIndex}
                    basePlanName="現在作成中のプラン"
                    baseItem={simulationGraphs.netAssetGraph}
                    isCompare={isCompare}
                    comparePlanName={comparePlanName}
                    compareItem={compareSimulationGraphs.netAssetGraph}
                  />
                }
                yTicks={netAssetGraphTicks}
              >
                {renderCustomBar({
                  name: '金融資産',
                  dataKey: 'financial_asset_total',
                  activeIndex: activeInfo.activeIndex,
                  stackId: 'netAsset',
                  color: '#FFD9B1',
                  data: simulationGraphs.netAssetGraph,
                  onClick: setActiveInfo,
                })}
                {renderCustomBar({
                  name: '不動産',
                  dataKey: 'estate_evaluation_total_manyen',
                  activeIndex: activeInfo.activeIndex,
                  stackId: 'netAsset',
                  color: '#AFE2E9',
                  data: simulationGraphs.netAssetGraph,
                  onClick: setActiveInfo,
                })}
                {renderCustomBar({
                  name: '負債',
                  dataKey: 'substruction_total_negative',
                  activeIndex: activeInfo.activeIndex,
                  stackId: 'netAsset',
                  color: '#F6B2A1',
                  data: simulationGraphs.netAssetGraph,
                  onClick: setActiveInfo,
                })}
                {renderCustomLine({
                  name: '純資産合計',
                  dataKey: 'net_asset_total',
                  color: '#C891AE',
                  onClick: handleClickLine,
                })}
              </GraphComposed>
            )}

            {currentTabIndex === SIMULATION_GRAPH_TAB_INDEX.loan && (
              <GraphComposed
                id="loan-graph"
                width={setGraphWidth()}
                height={setGraphHeight()}
                xDataKey="age"
                data={simulationGraphs.loanGraph}
                tooltipHide={breakpoint === 'sm' || (breakpoint === 'md' && isCompare)}
                tooltipPosition={{ x: setTooltipPosition().x, y: setTooltipPosition().y }}
                tooltipContent={
                  <TooltipLoan
                    activeIndex={activeInfo.activeIndex}
                    loanIndexObject={{
                      maxSizeEstateEvaluationObject,
                      maxSizeEstateRemainLoanObject,
                      maxSizeEstateEvaluationCompareObject,
                      maxSizeEstateRemainLoanCompareObject,
                    }}
                    basePlanName="現在作成中のプラン"
                    baseItem={simulationGraphs.loanGraph}
                    isCompare={isCompare}
                    comparePlanName={comparePlanName}
                    compareItem={compareSimulationGraphs.loanGraph}
                  />
                }
                yTicks={loanGraphTicks}
              >
                {renderCustomArea({
                  name: '評価額 自宅',
                  dataKey: 'housing_asset_evaluation',
                  stackId: 'loanArea',
                  color: '#E5F5AD',
                })}
                {Object.keys(maxSizeEstateEvaluationObject || {}).map((key, index) => [
                  renderCustomArea({
                    name: `評価額 物件${index + 1}`,
                    dataKey: `estate_evaluations.${key}`,
                    stackId: 'loanArea',
                    color: ESTATE_EVALUATION_COLOR[index],
                  }),
                ])}
                {renderCustomBar({
                  name: 'ローン残高 自宅',
                  dataKey: 'housing_loan_balance',
                  activeIndex: activeInfo.activeIndex,
                  stackId: 'loanBar',
                  color: '#5BAFE4',
                  data: simulationGraphs.loanGraph,
                  onClick: setActiveInfo,
                })}
                {Object.keys(maxSizeEstateRemainLoanObject || {}).map((key, index) => [
                  renderCustomBar({
                    name: `ローン残高 物件${index + 1}`,
                    dataKey: `estate_remain_loans_hash.${key}`,
                    activeIndex: activeInfo.activeIndex,
                    stackId: 'loanBar',
                    color: ESTATE_REMAIN_LOANS_COLOR[index],
                    data: simulationGraphs.loanGraph,
                    onClick: setActiveInfo,
                  }),
                ])}
                {renderCustomLine({
                  name: '評価額',
                  dataKey: 'asset_evaluation_total',
                  color: '#289D48',
                  onClick: handleClickLine,
                })}
              </GraphComposed>
            )}

            {currentTabIndex === SIMULATION_GRAPH_TAB_INDEX.balance && (
              <GraphComposed
                id="v2-balance-graph"
                width={setGraphWidth()}
                height={setGraphHeight()}
                xDataKey="age"
                data={simulationGraphs.v2BalanceGraph}
                tooltipHide={breakpoint === 'sm' || (breakpoint === 'md' && isCompare)}
                tooltipPosition={{ x: setTooltipPosition().x, y: setTooltipPosition().y }}
                tooltipContent={
                  <TooltipBalance
                    activeIndex={activeInfo.activeIndex}
                    basePlanName="現在作成中のプラン"
                    baseItem={simulationGraphs.v2BalanceGraph}
                    isCompare={isCompare}
                    comparePlanName={comparePlanName}
                    compareItem={compareSimulationGraphs.v2BalanceGraph}
                  />
                }
                yTicks={balanceGraphTicks}
              >
                {renderCustomBar({
                  name: '年間収支',
                  dataKey: 'balance_total',
                  activeIndex: activeInfo.activeIndex,
                  color: '#ADD6F0',
                  data: simulationGraphs.v2BalanceGraph,
                  onClick: setActiveInfo,
                })}
                {renderCustomLine({
                  name: '収入合計',
                  dataKey: 'income_total',
                  color: '#4A667F',
                  onClick: handleClickLine,
                })}
                {renderCustomLine({
                  name: '支出合計',
                  dataKey: 'spending_total',
                  color: '#C891AE',
                  onClick: handleClickLine,
                })}
              </GraphComposed>
            )}

            {currentTabIndex === SIMULATION_GRAPH_TAB_INDEX.risk && (
              <GraphComposed
                id="insurance-graph"
                width={setGraphWidth()}
                height={setGraphHeight()}
                xDataKey="age"
                data={simulationGraphs.insuranceGraph}
                tooltipHide={breakpoint === 'sm' || (breakpoint === 'md' && isCompare)}
                tooltipPosition={{ x: setTooltipPosition().x, y: setTooltipPosition().y }}
                tooltipContent={
                  <TooltipRisk
                    activeIndex={activeInfo.activeIndex}
                    basePlanName="現在作成中のプラン"
                    baseItem={simulationGraphs.insuranceGraph}
                    isCompare={isCompare}
                    comparePlanName={comparePlanName}
                    compareItem={compareSimulationGraphs.insuranceGraph}
                  />
                }
                yTicks={insuranceGraphTicks}
              >
                {renderCustomBar({
                  name: '金融資産合計',
                  dataKey: 'total_financial_assets',
                  activeIndex: activeInfo.activeIndex,
                  stackId: 'risk',
                  color: '#191970',
                  data: simulationGraphs.insuranceGraph,
                  onClick: setActiveInfo,
                })}
                {renderCustomBar({
                  name: '既加入保険金額',
                  dataKey: 'existing_insurance_total',
                  activeIndex: activeInfo.activeIndex,
                  stackId: 'risk',
                  color: '#c71585',
                  data: simulationGraphs.insuranceGraph,
                  onClick: setActiveInfo,
                })}
                {renderCustomBar({
                  name: '不足額',
                  dataKey: 'shortfall_total',
                  activeIndex: activeInfo.activeIndex,
                  stackId: 'risk',
                  color: '#c0c0c0',
                  data: simulationGraphs.insuranceGraph,
                  onClick: setActiveInfo,
                })}
                {renderCustomLine({
                  name: '必要保障額',
                  dataKey: 'required_security_amount_total',
                  color: '#ffa500',
                  onClick: handleClickLine,
                })}
              </GraphComposed>
            )}
          </div>

          {isCompare && (
            <div className="w-full sm:w-3/6">
              <div className="flex h-[60px] items-center justify-between gap-x-[8px]">
                <SelectBoxWithoutFinalForm
                  name="future_base_id"
                  placeholder="選択する"
                  className="sticky left-0 pl-[66px] pt-[4px]"
                  heightClassName="h-[40px]"
                  widthClassName="w-[200px] md:w-auto"
                  value={selectedFutureBaseId}
                  options={futureBaseIdOptions}
                  onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                    handleChangeFutureBaseId(Number(e.target.value))
                  }
                />
                <Button
                  className="border border-solid rounded-[8px] border-secondary-500 px-[8px] py-[6px]"
                  onClick={() => setIsOpenCompareGraphModal(true)}
                >
                  <Typography className="text-[10px] leading-normal text-secondary-500 whitespace-nowrap">
                    拡大
                  </Typography>
                </Button>
              </div>
              {currentTabIndex === SIMULATION_GRAPH_TAB_INDEX.investment && (
                <GraphComposed
                  id="v2-investment-compare-graph"
                  width={setGraphWidth()}
                  height={setGraphHeight()}
                  xDataKey="age"
                  data={compareSimulationGraphs.v2InvestmentGraph}
                  tooltipHide
                  yTicks={investmentGraphTicks}
                  isLoading={isCompareGraphLoading}
                >
                  {renderCustomBar({
                    name: '流動性資産',
                    dataKey: 'deposit',
                    activeIndex: activeInfo.activeIndex,
                    stackId: 'investment',
                    color: '#B9D3E9',
                    data: compareSimulationGraphs.v2InvestmentGraph,
                    onClick: setActiveInfo,
                  })}
                  {renderCustomBar({
                    name: '投資信託',
                    dataKey: 'investment_trust',
                    activeIndex: activeInfo.activeIndex,
                    stackId: 'investment',
                    color: '#BBE1DF',
                    data: compareSimulationGraphs.v2InvestmentGraph,
                    onClick: setActiveInfo,
                  })}
                  {renderCustomBar({
                    name: '株式',
                    dataKey: 'stock',
                    activeIndex: activeInfo.activeIndex,
                    stackId: 'investment',
                    color: '#F9EBA3',
                    data: compareSimulationGraphs.v2InvestmentGraph,
                    onClick: setActiveInfo,
                  })}
                  {renderCustomBar({
                    name: '債券',
                    dataKey: 'bond',
                    activeIndex: activeInfo.activeIndex,
                    stackId: 'investment',
                    color: '#FF9933',
                    data: compareSimulationGraphs.v2InvestmentGraph,
                    onClick: setActiveInfo,
                  })}
                  {renderCustomBar({
                    name: '社内預金・財形・持株など',
                    dataKey: 'deducted_savings',
                    activeIndex: activeInfo.activeIndex,
                    stackId: 'investment',
                    color: '#CC6666',
                    data: compareSimulationGraphs.v2InvestmentGraph,
                    onClick: setActiveInfo,
                  })}
                  {renderCustomBar({
                    name: 'その他',
                    dataKey: 'other',
                    activeIndex: activeInfo.activeIndex,
                    stackId: 'investment',
                    color: '#E1E0E2',
                    data: compareSimulationGraphs.v2InvestmentGraph,
                    onClick: setActiveInfo,
                  })}
                  {renderCustomBar({
                    name: 'マイナス資産',
                    dataKey: 'substruction',
                    activeIndex: activeInfo.activeIndex,
                    stackId: 'investment',
                    color: '#D4C7DA',
                    data: compareSimulationGraphs.v2InvestmentGraph,
                    onClick: setActiveInfo,
                  })}
                  {renderCustomBar({
                    name: '貯蓄用保険資産',
                    dataKey: 'insurance_asset',
                    activeIndex: activeInfo.activeIndex,
                    stackId: 'investment',
                    color: '#F3BCC2',
                    data: compareSimulationGraphs.v2InvestmentGraph,
                    onClick: setActiveInfo,
                  })}
                </GraphComposed>
              )}

              {currentTabIndex === SIMULATION_GRAPH_TAB_INDEX.netAsset && (
                <GraphComposed
                  id="net-asset-compare-graph"
                  width={setGraphWidth()}
                  height={setGraphHeight()}
                  xDataKey="age"
                  data={compareSimulationGraphs.netAssetGraph}
                  tooltipHide
                  yTicks={netAssetGraphTicks}
                  isLoading={isCompareGraphLoading}
                >
                  {renderCustomBar({
                    name: '金融資産',
                    dataKey: 'financial_asset_total',
                    activeIndex: activeInfo.activeIndex,
                    stackId: 'netAsset',
                    color: '#FFD9B1',
                    data: compareSimulationGraphs.netAssetGraph,
                    onClick: setActiveInfo,
                  })}
                  {renderCustomBar({
                    name: '不動産',
                    dataKey: 'estate_evaluation_total_manyen',
                    activeIndex: activeInfo.activeIndex,
                    stackId: 'netAsset',
                    color: '#AFE2E9',
                    data: compareSimulationGraphs.netAssetGraph,
                    onClick: setActiveInfo,
                  })}
                  {renderCustomBar({
                    name: '負債',
                    dataKey: 'substruction_total_negative',
                    activeIndex: activeInfo.activeIndex,
                    stackId: 'netAsset',
                    color: '#F6B2A1',
                    data: compareSimulationGraphs.netAssetGraph,
                    onClick: setActiveInfo,
                  })}
                  {renderCustomLine({
                    name: '純資産合計',
                    dataKey: 'net_asset_total',
                    color: '#C891AE',
                    onClick: handleClickLine,
                  })}
                </GraphComposed>
              )}

              {currentTabIndex === SIMULATION_GRAPH_TAB_INDEX.loan && (
                <GraphComposed
                  id="loan-compare-graph"
                  width={setGraphWidth()}
                  height={setGraphHeight()}
                  xDataKey="age"
                  data={compareSimulationGraphs.loanGraph}
                  tooltipHide
                  yTicks={loanGraphTicks}
                  isLoading={isCompareGraphLoading}
                >
                  {renderCustomArea({
                    name: '評価額 自宅',
                    dataKey: 'housing_asset_evaluation',
                    stackId: 'loanArea',
                    color: '#E5F5AD',
                  })}
                  {Object.keys(maxSizeEstateEvaluationCompareObject || {}).map(
                    (key, index) => [
                      renderCustomArea({
                        name: `評価額 物件${index + 1}`,
                        dataKey: `estate_evaluations.${key}`,
                        stackId: 'loanArea',
                        color: ESTATE_EVALUATION_COLOR[index],
                      }),
                    ],
                  )}
                  {renderCustomBar({
                    name: 'ローン残高 自宅',
                    dataKey: 'housing_loan_balance',
                    activeIndex: activeInfo.activeIndex,
                    stackId: 'loanBar',
                    color: '#5BAFE4',
                    data: compareSimulationGraphs.loanGraph,
                    onClick: setActiveInfo,
                  })}
                  {Object.keys(maxSizeEstateRemainLoanCompareObject || {}).map(
                    (key, index) => [
                      renderCustomBar({
                        name: `ローン残高 物件${index + 1}`,
                        dataKey: `estate_remain_loans_hash.${key}`,
                        activeIndex: activeInfo.activeIndex,
                        stackId: 'loanBar',
                        color: ESTATE_REMAIN_LOANS_COLOR[index],
                        data: compareSimulationGraphs.loanGraph,
                        onClick: setActiveInfo,
                      }),
                    ],
                  )}
                  {renderCustomLine({
                    name: '評価額',
                    dataKey: 'asset_evaluation_total',
                    color: '#289D48',
                    onClick: handleClickLine,
                  })}
                </GraphComposed>
              )}

              {currentTabIndex === SIMULATION_GRAPH_TAB_INDEX.balance && (
                <GraphComposed
                  id="v2-balance-compare-graph"
                  width={setGraphWidth()}
                  height={setGraphHeight()}
                  xDataKey="age"
                  data={compareSimulationGraphs.v2BalanceGraph}
                  tooltipHide
                  yTicks={balanceGraphTicks}
                  isLoading={isCompareGraphLoading}
                >
                  {renderCustomBar({
                    name: '年間収支',
                    dataKey: 'balance_total',
                    activeIndex: activeInfo.activeIndex,
                    color: '#ADD6F0',
                    data: compareSimulationGraphs.v2BalanceGraph,
                    onClick: setActiveInfo,
                  })}
                  {renderCustomLine({
                    name: '収入合計',
                    dataKey: 'income_total',
                    color: '#4A667F',
                    onClick: handleClickLine,
                  })}
                  {renderCustomLine({
                    name: '支出合計',
                    dataKey: 'spending_total',
                    color: '#C891AE',
                    onClick: handleClickLine,
                  })}
                </GraphComposed>
              )}

              {currentTabIndex === SIMULATION_GRAPH_TAB_INDEX.risk && (
                <GraphComposed
                  id="insurance-compare-graph"
                  width={setGraphWidth()}
                  height={setGraphHeight()}
                  xDataKey="age"
                  data={compareSimulationGraphs.insuranceGraph}
                  tooltipHide
                  yTicks={insuranceGraphTicks}
                  isLoading={isCompareGraphLoading}
                >
                  {renderCustomBar({
                    name: '金融資産合計',
                    dataKey: 'total_financial_assets',
                    activeIndex: activeInfo.activeIndex,
                    stackId: 'risk',
                    color: '#191970',
                    data: compareSimulationGraphs.insuranceGraph,
                    onClick: setActiveInfo,
                  })}
                  {renderCustomBar({
                    name: '既加入保険金額',
                    dataKey: 'existing_insurance_total',
                    activeIndex: activeInfo.activeIndex,
                    stackId: 'risk',
                    color: '#c71585',
                    data: compareSimulationGraphs.insuranceGraph,
                    onClick: setActiveInfo,
                  })}
                  {renderCustomBar({
                    name: '不足額',
                    dataKey: 'shortfall_total',
                    activeIndex: activeInfo.activeIndex,
                    stackId: 'risk',
                    color: '#c0c0c0',
                    data: compareSimulationGraphs.insuranceGraph,
                    onClick: setActiveInfo,
                  })}
                  {renderCustomLine({
                    name: '必要保障額',
                    dataKey: 'required_security_amount_total',
                    color: '#ffa500',
                    onClick: handleClickLine,
                  })}
                </GraphComposed>
              )}
            </div>
          )}
        </div>
      </div>

      <Modal
        isOpen={isOpenCurrentGraphModal}
        onClose={() => setIsOpenCurrentGraphModal(false)}
        className="w-[95%]"
        isFullModal={breakpoint !== 'lg'}
      >
        <div className="flex flex-col w-full">
          <div className="flex flex-row justify-between px-[20px] pb-[12px] items-baseline">
            <Typography className="leading-normal text-[12px] md:text-[14px] text-black-900">
              作成中のシミュレーションの場合
            </Typography>

            <Button
              className="border border-solid rounded-[8px] border-secondary-500 px-[8px] py-[6px]"
              onClick={() => setIsOpenCurrentGraphModal(false)}
            >
              <Typography className="text-[10px] leading-normal text-secondary-500 whitespace-nowrap">
                拡大表示を終了する
              </Typography>
            </Button>
          </div>
          <div className="pr-[10px]">
            {currentTabIndex === SIMULATION_GRAPH_TAB_INDEX.investment && (
              <GraphComposed
                id="v2-investment-graph-modal"
                width={setGraphWidth()}
                height={setGraphHeight()}
                xDataKey="age"
                data={simulationGraphs.v2InvestmentGraph}
                tooltipHide={breakpoint === 'sm' || (breakpoint === 'md' && isCompare)}
                tooltipPosition={{
                  x: setTooltipPosition().x,
                  y: setTooltipPosition().y,
                }}
                tooltipContent={
                  <TooltipInvestment
                    activeIndex={activeInfo.activeIndex}
                    basePlanName="現在作成中のプラン"
                    baseItem={simulationGraphs.v2InvestmentGraph}
                    isCompare={isCompare}
                    comparePlanName={comparePlanName}
                    compareItem={compareSimulationGraphs.v2InvestmentGraph}
                  />
                }
                yTicks={investmentGraphTicks}
              >
                {renderCustomBar({
                  name: '流動性資産',
                  dataKey: 'deposit',
                  activeIndex: activeInfo.activeIndex,
                  stackId: 'investment',
                  color: '#B9D3E9',
                  data: simulationGraphs.v2InvestmentGraph,
                  onClick: setActiveInfo,
                })}
                {renderCustomBar({
                  name: '投資信託',
                  dataKey: 'investment_trust',
                  activeIndex: activeInfo.activeIndex,
                  stackId: 'investment',
                  color: '#BBE1DF',
                  data: simulationGraphs.v2InvestmentGraph,
                  onClick: setActiveInfo,
                })}
                {renderCustomBar({
                  name: '株式',
                  dataKey: 'stock',
                  activeIndex: activeInfo.activeIndex,
                  stackId: 'investment',
                  color: '#F9EBA3',
                  data: simulationGraphs.v2InvestmentGraph,
                  onClick: setActiveInfo,
                })}
                {renderCustomBar({
                  name: '債券',
                  dataKey: 'bond',
                  activeIndex: activeInfo.activeIndex,
                  stackId: 'investment',
                  color: '#FF9933',
                  data: simulationGraphs.v2InvestmentGraph,
                  onClick: setActiveInfo,
                })}
                {renderCustomBar({
                  name: '社内預金・財形・持株など',
                  dataKey: 'deducted_savings',
                  activeIndex: activeInfo.activeIndex,
                  stackId: 'investment',
                  color: '#CC6666',
                  data: simulationGraphs.v2InvestmentGraph,
                  onClick: setActiveInfo,
                })}
                {renderCustomBar({
                  name: 'その他',
                  dataKey: 'other',
                  activeIndex: activeInfo.activeIndex,
                  stackId: 'investment',
                  color: '#E1E0E2',
                  data: simulationGraphs.v2InvestmentGraph,
                  onClick: setActiveInfo,
                })}
                {renderCustomBar({
                  name: 'マイナス資産',
                  dataKey: 'substruction',
                  activeIndex: activeInfo.activeIndex,
                  stackId: 'investment',
                  color: '#D4C7DA',
                  data: simulationGraphs.v2InvestmentGraph,
                  onClick: setActiveInfo,
                })}
                {renderCustomBar({
                  name: '貯蓄用保険資産',
                  dataKey: 'insurance_asset',
                  activeIndex: activeInfo.activeIndex,
                  stackId: 'investment',
                  color: '#F3BCC2',
                  data: simulationGraphs.v2InvestmentGraph,
                  onClick: setActiveInfo,
                })}
              </GraphComposed>
            )}

            {currentTabIndex === SIMULATION_GRAPH_TAB_INDEX.netAsset && (
              <GraphComposed
                id="net-asset-graph-modal"
                width={setGraphWidth()}
                height={setGraphHeight()}
                xDataKey="age"
                data={simulationGraphs.netAssetGraph}
                tooltipHide={breakpoint === 'sm' || (breakpoint === 'md' && isCompare)}
                tooltipPosition={{
                  x: setTooltipPosition().x,
                  y: setTooltipPosition().y,
                }}
                tooltipContent={
                  <TooltipNetAsset
                    activeIndex={activeInfo.activeIndex}
                    basePlanName="現在作成中のプラン"
                    baseItem={simulationGraphs.netAssetGraph}
                    isCompare={isCompare}
                    comparePlanName={comparePlanName}
                    compareItem={compareSimulationGraphs.netAssetGraph}
                  />
                }
                yTicks={netAssetGraphTicks}
              >
                {renderCustomBar({
                  name: '金融資産',
                  dataKey: 'financial_asset_total',
                  activeIndex: activeInfo.activeIndex,
                  stackId: 'netAsset',
                  color: '#FFD9B1',
                  data: simulationGraphs.netAssetGraph,
                  onClick: setActiveInfo,
                })}
                {renderCustomBar({
                  name: '不動産',
                  dataKey: 'estate_evaluation_total_manyen',
                  activeIndex: activeInfo.activeIndex,
                  stackId: 'netAsset',
                  color: '#AFE2E9',
                  data: simulationGraphs.netAssetGraph,
                  onClick: setActiveInfo,
                })}
                {renderCustomBar({
                  name: '負債',
                  dataKey: 'substruction_total_negative',
                  activeIndex: activeInfo.activeIndex,
                  stackId: 'netAsset',
                  color: '#F6B2A1',
                  data: simulationGraphs.netAssetGraph,
                  onClick: setActiveInfo,
                })}
                {renderCustomLine({
                  name: '純資産合計',
                  dataKey: 'net_asset_total',
                  color: '#C891AE',
                  onClick: handleClickLine,
                })}
              </GraphComposed>
            )}

            {currentTabIndex === SIMULATION_GRAPH_TAB_INDEX.loan && (
              <GraphComposed
                id="loan-graph-modal"
                width={setGraphWidth()}
                height={setGraphHeight()}
                xDataKey="age"
                data={simulationGraphs.loanGraph}
                tooltipHide={breakpoint === 'sm' || (breakpoint === 'md' && isCompare)}
                tooltipPosition={{
                  x: setTooltipPosition().x,
                  y: setTooltipPosition().y,
                }}
                tooltipContent={
                  <TooltipLoan
                    activeIndex={activeInfo.activeIndex}
                    loanIndexObject={{
                      maxSizeEstateEvaluationObject,
                      maxSizeEstateRemainLoanObject,
                      maxSizeEstateEvaluationCompareObject,
                      maxSizeEstateRemainLoanCompareObject,
                    }}
                    basePlanName="現在作成中のプラン"
                    baseItem={simulationGraphs.loanGraph}
                    isCompare={isCompare}
                    comparePlanName={comparePlanName}
                    compareItem={compareSimulationGraphs.loanGraph}
                  />
                }
                yTicks={loanGraphTicks}
              >
                {renderCustomArea({
                  name: '評価額 自宅',
                  dataKey: 'housing_asset_evaluation',
                  stackId: 'loanArea',
                  color: '#E5F5AD',
                })}
                {Object.keys(maxSizeEstateEvaluationObject || {}).map((key, index) => [
                  renderCustomArea({
                    name: `評価額 物件${index + 1}`,
                    dataKey: `estate_evaluations.${key}`,
                    stackId: 'loanArea',
                    color: ESTATE_EVALUATION_COLOR[index],
                  }),
                ])}
                {renderCustomBar({
                  name: 'ローン残高 自宅',
                  dataKey: 'housing_loan_balance',
                  activeIndex: activeInfo.activeIndex,
                  stackId: 'loanBar',
                  color: '#5BAFE4',
                  data: simulationGraphs.loanGraph,
                  onClick: setActiveInfo,
                })}
                {Object.keys(maxSizeEstateRemainLoanObject || {}).map((key, index) => [
                  renderCustomBar({
                    name: `ローン残高 物件${index + 1}`,
                    dataKey: `estate_remain_loans_hash.${key}`,
                    activeIndex: activeInfo.activeIndex,
                    stackId: 'loanBar',
                    color: ESTATE_REMAIN_LOANS_COLOR[index],
                    data: simulationGraphs.loanGraph,
                    onClick: setActiveInfo,
                  }),
                ])}
                {renderCustomLine({
                  name: '評価額',
                  dataKey: 'asset_evaluation_total',
                  color: '#289D48',
                  onClick: handleClickLine,
                })}
              </GraphComposed>
            )}

            {currentTabIndex === SIMULATION_GRAPH_TAB_INDEX.balance && (
              <GraphComposed
                id="v2-balance-graph-modal"
                width={setGraphWidth()}
                height={setGraphHeight()}
                xDataKey="age"
                data={simulationGraphs.v2BalanceGraph}
                tooltipHide={breakpoint === 'sm' || (breakpoint === 'md' && isCompare)}
                tooltipPosition={{
                  x: setTooltipPosition().x,
                  y: setTooltipPosition().y,
                }}
                tooltipContent={
                  <TooltipBalance
                    activeIndex={activeInfo.activeIndex}
                    basePlanName="現在作成中のプラン"
                    baseItem={simulationGraphs.v2BalanceGraph}
                    isCompare={isCompare}
                    comparePlanName={comparePlanName}
                    compareItem={compareSimulationGraphs.v2BalanceGraph}
                  />
                }
                yTicks={balanceGraphTicks}
              >
                {renderCustomBar({
                  name: '年間収支',
                  dataKey: 'balance_total',
                  activeIndex: activeInfo.activeIndex,
                  color: '#ADD6F0',
                  data: simulationGraphs.v2BalanceGraph,
                  onClick: setActiveInfo,
                })}
                {renderCustomLine({
                  name: '収入合計',
                  dataKey: 'income_total',
                  color: '#4A667F',
                  onClick: handleClickLine,
                })}
                {renderCustomLine({
                  name: '支出合計',
                  dataKey: 'spending_total',
                  color: '#C891AE',
                  onClick: handleClickLine,
                })}
              </GraphComposed>
            )}

            {currentTabIndex === SIMULATION_GRAPH_TAB_INDEX.risk && (
              <GraphComposed
                id="insurance-graph-modal"
                width={setGraphWidth()}
                height={setGraphHeight()}
                xDataKey="age"
                data={simulationGraphs.insuranceGraph}
                tooltipHide={breakpoint === 'sm' || (breakpoint === 'md' && isCompare)}
                tooltipPosition={{
                  x: setTooltipPosition().x,
                  y: setTooltipPosition().y,
                }}
                tooltipContent={
                  <TooltipRisk
                    activeIndex={activeInfo.activeIndex}
                    basePlanName="現在作成中のプラン"
                    baseItem={simulationGraphs.insuranceGraph}
                    isCompare={isCompare}
                    comparePlanName={comparePlanName}
                    compareItem={compareSimulationGraphs.insuranceGraph}
                  />
                }
                yTicks={insuranceGraphTicks}
              >
                {renderCustomBar({
                  name: '金融資産合計',
                  dataKey: 'total_financial_assets',
                  activeIndex: activeInfo.activeIndex,
                  stackId: 'risk',
                  color: '#191970',
                  data: simulationGraphs.insuranceGraph,
                  onClick: setActiveInfo,
                })}
                {renderCustomBar({
                  name: '既加入保険金額',
                  dataKey: 'existing_insurance_total',
                  activeIndex: activeInfo.activeIndex,
                  stackId: 'risk',
                  color: '#c71585',
                  data: simulationGraphs.insuranceGraph,
                  onClick: setActiveInfo,
                })}
                {renderCustomBar({
                  name: '不足額',
                  dataKey: 'shortfall_total',
                  activeIndex: activeInfo.activeIndex,
                  stackId: 'risk',
                  color: '#c0c0c0',
                  data: simulationGraphs.insuranceGraph,
                  onClick: setActiveInfo,
                })}
                {renderCustomLine({
                  name: '必要保障額',
                  dataKey: 'required_security_amount_total',
                  color: '#ffa500',
                  onClick: handleClickLine,
                })}
              </GraphComposed>
            )}
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={isOpenCompareGraphModal}
        onClose={() => setIsOpenCompareGraphModal(false)}
        className="w-[95%]"
        isFullModal={breakpoint !== 'lg'}
      >
        <div className="flex flex-col w-full">
          <div className="flex flex-row justify-between px-[20px] pb-[12px] items-baseline">
            <Typography className="leading-normal text-[12px] md:text-[14px] text-black-900">
              {comparePlanName}
            </Typography>

            <Button
              className="border border-solid rounded-[8px] border-secondary-500 px-[8px] py-[6px]"
              onClick={() => setIsOpenCompareGraphModal(false)}
            >
              <Typography className="text-[10px] leading-normal text-secondary-500 whitespace-nowrap">
                拡大表示を終了する
              </Typography>
            </Button>
          </div>
          <div className="pr-[10px]">
            {currentTabIndex === SIMULATION_GRAPH_TAB_INDEX.investment && (
              <GraphComposed
                id="v2-investment-compare-graph-modal"
                width={setGraphWidth()}
                height={setGraphHeight()}
                xDataKey="age"
                data={compareSimulationGraphs.v2InvestmentGraph}
                tooltipHide
                yTicks={investmentGraphTicks}
                isLoading={isCompareGraphLoading}
              >
                {renderCustomBar({
                  name: '流動性資産',
                  dataKey: 'deposit',
                  activeIndex: activeInfo.activeIndex,
                  stackId: 'investment',
                  color: '#B9D3E9',
                  data: compareSimulationGraphs.v2InvestmentGraph,
                  onClick: setActiveInfo,
                })}
                {renderCustomBar({
                  name: '投資信託',
                  dataKey: 'investment_trust',
                  activeIndex: activeInfo.activeIndex,
                  stackId: 'investment',
                  color: '#BBE1DF',
                  data: compareSimulationGraphs.v2InvestmentGraph,
                  onClick: setActiveInfo,
                })}
                {renderCustomBar({
                  name: '株式',
                  dataKey: 'stock',
                  activeIndex: activeInfo.activeIndex,
                  stackId: 'investment',
                  color: '#F9EBA3',
                  data: compareSimulationGraphs.v2InvestmentGraph,
                  onClick: setActiveInfo,
                })}
                {renderCustomBar({
                  name: '債券',
                  dataKey: 'bond',
                  activeIndex: activeInfo.activeIndex,
                  stackId: 'investment',
                  color: '#FF9933',
                  data: compareSimulationGraphs.v2InvestmentGraph,
                  onClick: setActiveInfo,
                })}
                {renderCustomBar({
                  name: '社内預金・財形・持株など',
                  dataKey: 'deducted_savings',
                  activeIndex: activeInfo.activeIndex,
                  stackId: 'investment',
                  color: '#CC6666',
                  data: compareSimulationGraphs.v2InvestmentGraph,
                  onClick: setActiveInfo,
                })}
                {renderCustomBar({
                  name: 'その他',
                  dataKey: 'other',
                  activeIndex: activeInfo.activeIndex,
                  stackId: 'investment',
                  color: '#E1E0E2',
                  data: compareSimulationGraphs.v2InvestmentGraph,
                  onClick: setActiveInfo,
                })}
                {renderCustomBar({
                  name: 'マイナス資産',
                  dataKey: 'substruction',
                  activeIndex: activeInfo.activeIndex,
                  stackId: 'investment',
                  color: '#D4C7DA',
                  data: compareSimulationGraphs.v2InvestmentGraph,
                  onClick: setActiveInfo,
                })}
                {renderCustomBar({
                  name: '貯蓄用保険資産',
                  dataKey: 'insurance_asset',
                  activeIndex: activeInfo.activeIndex,
                  stackId: 'investment',
                  color: '#F3BCC2',
                  data: compareSimulationGraphs.v2InvestmentGraph,
                  onClick: setActiveInfo,
                })}
              </GraphComposed>
            )}

            {currentTabIndex === SIMULATION_GRAPH_TAB_INDEX.netAsset && (
              <GraphComposed
                id="net-asset-compare-graph-modal"
                width={setGraphWidth()}
                height={setGraphHeight()}
                xDataKey="age"
                data={compareSimulationGraphs.netAssetGraph}
                tooltipHide
                yTicks={netAssetGraphTicks}
                isLoading={isCompareGraphLoading}
              >
                {renderCustomBar({
                  name: '金融資産',
                  dataKey: 'financial_asset_total',
                  activeIndex: activeInfo.activeIndex,
                  stackId: 'netAsset',
                  color: '#FFD9B1',
                  data: compareSimulationGraphs.netAssetGraph,
                  onClick: setActiveInfo,
                })}
                {renderCustomBar({
                  name: '不動産',
                  dataKey: 'estate_evaluation_total_manyen',
                  activeIndex: activeInfo.activeIndex,
                  stackId: 'netAsset',
                  color: '#AFE2E9',
                  data: compareSimulationGraphs.netAssetGraph,
                  onClick: setActiveInfo,
                })}
                {renderCustomBar({
                  name: '負債',
                  dataKey: 'substruction_total_negative',
                  activeIndex: activeInfo.activeIndex,
                  stackId: 'netAsset',
                  color: '#F6B2A1',
                  data: compareSimulationGraphs.netAssetGraph,
                  onClick: setActiveInfo,
                })}
                {renderCustomLine({
                  name: '純資産合計',
                  dataKey: 'net_asset_total',
                  color: '#C891AE',
                  onClick: handleClickLine,
                })}
              </GraphComposed>
            )}

            {currentTabIndex === SIMULATION_GRAPH_TAB_INDEX.loan && (
              <GraphComposed
                id="loan-compare-graph-modal"
                width={setGraphWidth()}
                height={setGraphHeight()}
                xDataKey="age"
                data={compareSimulationGraphs.loanGraph}
                tooltipHide
                yTicks={loanGraphTicks}
                isLoading={isCompareGraphLoading}
              >
                {renderCustomArea({
                  name: '評価額 自宅',
                  dataKey: 'housing_asset_evaluation',
                  stackId: 'loanArea',
                  color: '#E5F5AD',
                })}
                {Object.keys(maxSizeEstateEvaluationCompareObject || {}).map(
                  (key, index) => [
                    renderCustomArea({
                      name: `評価額 物件${index + 1}`,
                      dataKey: `estate_evaluations.${key}`,
                      stackId: 'loanArea',
                      color: ESTATE_EVALUATION_COLOR[index],
                    }),
                  ],
                )}
                {renderCustomBar({
                  name: 'ローン残高 自宅',
                  dataKey: 'housing_loan_balance',
                  activeIndex: activeInfo.activeIndex,
                  stackId: 'loanBar',
                  color: '#5BAFE4',
                  data: compareSimulationGraphs.loanGraph,
                  onClick: setActiveInfo,
                })}
                {Object.keys(maxSizeEstateRemainLoanCompareObject || {}).map(
                  (key, index) => [
                    renderCustomBar({
                      name: `ローン残高 物件${index + 1}`,
                      dataKey: `estate_remain_loans_hash.${key}`,
                      activeIndex: activeInfo.activeIndex,
                      stackId: 'loanBar',
                      color: ESTATE_REMAIN_LOANS_COLOR[index],
                      data: compareSimulationGraphs.loanGraph,
                      onClick: setActiveInfo,
                    }),
                  ],
                )}
                {renderCustomLine({
                  name: '評価額',
                  dataKey: 'asset_evaluation_total',
                  color: '#289D48',
                  onClick: handleClickLine,
                })}
              </GraphComposed>
            )}

            {currentTabIndex === SIMULATION_GRAPH_TAB_INDEX.balance && (
              <GraphComposed
                id="v2-balance-compare-graph-modal"
                width={setGraphWidth()}
                height={setGraphHeight()}
                xDataKey="age"
                data={compareSimulationGraphs.v2BalanceGraph}
                tooltipHide
                yTicks={balanceGraphTicks}
                isLoading={isCompareGraphLoading}
              >
                {renderCustomBar({
                  name: '年間収支',
                  dataKey: 'balance_total',
                  activeIndex: activeInfo.activeIndex,
                  color: '#ADD6F0',
                  data: compareSimulationGraphs.v2BalanceGraph,
                  onClick: setActiveInfo,
                })}
                {renderCustomLine({
                  name: '収入合計',
                  dataKey: 'income_total',
                  color: '#4A667F',
                  onClick: handleClickLine,
                })}
                {renderCustomLine({
                  name: '支出合計',
                  dataKey: 'spending_total',
                  color: '#C891AE',
                  onClick: handleClickLine,
                })}
              </GraphComposed>
            )}

            {currentTabIndex === SIMULATION_GRAPH_TAB_INDEX.risk && (
              <GraphComposed
                id="insurance-compare-graph-modal"
                width={setGraphWidth()}
                height={setGraphHeight()}
                xDataKey="age"
                data={compareSimulationGraphs.insuranceGraph}
                tooltipHide
                yTicks={insuranceGraphTicks}
                isLoading={isCompareGraphLoading}
              >
                {renderCustomBar({
                  name: '金融資産合計',
                  dataKey: 'total_financial_assets',
                  activeIndex: activeInfo.activeIndex,
                  stackId: 'risk',
                  color: '#191970',
                  data: compareSimulationGraphs.insuranceGraph,
                  onClick: setActiveInfo,
                })}
                {renderCustomBar({
                  name: '既加入保険金額',
                  dataKey: 'existing_insurance_total',
                  activeIndex: activeInfo.activeIndex,
                  stackId: 'risk',
                  color: '#c71585',
                  data: compareSimulationGraphs.insuranceGraph,
                  onClick: setActiveInfo,
                })}
                {renderCustomBar({
                  name: '不足額',
                  dataKey: 'shortfall_total',
                  activeIndex: activeInfo.activeIndex,
                  stackId: 'risk',
                  color: '#c0c0c0',
                  data: compareSimulationGraphs.insuranceGraph,
                  onClick: setActiveInfo,
                })}
                {renderCustomLine({
                  name: '必要保障額',
                  dataKey: 'required_security_amount_total',
                  color: '#ffa500',
                  onClick: handleClickLine,
                })}
              </GraphComposed>
            )}
          </div>
        </div>
      </Modal>

      {(breakpoint === 'sm' || (breakpoint === 'md' && isCompare)) && (
        <Modal
          className="w-[95%] md:w-[fit-content]"
          modalContainerClassName="h-[95%] px-10 py-10"
          isOpen={!!activeInfo?.activeIndex || activeInfo?.activeIndex === 0}
          onClose={() => handleClickAway()}
        >
          {currentTabIndex === SIMULATION_GRAPH_TAB_INDEX.investment && (
            <TooltipInvestment
              activeIndex={activeInfo.activeIndex}
              baseItem={simulationGraphs.v2InvestmentGraph}
              isCompare={isCompare}
              comparePlanName={comparePlanName}
              compareItem={compareSimulationGraphs.v2InvestmentGraph}
            />
          )}
          {currentTabIndex === SIMULATION_GRAPH_TAB_INDEX.netAsset && (
            <TooltipNetAsset
              activeIndex={activeInfo.activeIndex}
              basePlanName="現在作成中のプラン"
              baseItem={simulationGraphs.netAssetGraph}
              isCompare={isCompare}
              comparePlanName={comparePlanName}
              compareItem={compareSimulationGraphs.netAssetGraph}
            />
          )}
          {currentTabIndex === SIMULATION_GRAPH_TAB_INDEX.loan && (
            <TooltipLoan
              activeIndex={activeInfo.activeIndex}
              loanIndexObject={{
                maxSizeEstateEvaluationObject,
                maxSizeEstateRemainLoanObject,
                maxSizeEstateEvaluationCompareObject,
                maxSizeEstateRemainLoanCompareObject,
              }}
              basePlanName="現在作成中のプラン"
              baseItem={simulationGraphs.loanGraph}
              isCompare={isCompare}
              comparePlanName={comparePlanName}
              compareItem={compareSimulationGraphs.loanGraph}
            />
          )}
          {currentTabIndex === SIMULATION_GRAPH_TAB_INDEX.balance && (
            <TooltipBalance
              activeIndex={activeInfo.activeIndex}
              basePlanName="現在作成中のプラン"
              baseItem={simulationGraphs.v2BalanceGraph}
              isCompare={isCompare}
              comparePlanName={comparePlanName}
              compareItem={compareSimulationGraphs.v2BalanceGraph}
            />
          )}
          {currentTabIndex === SIMULATION_GRAPH_TAB_INDEX.risk && (
            <TooltipRisk
              activeIndex={activeInfo.activeIndex}
              basePlanName="現在作成中のプラン"
              baseItem={simulationGraphs.insuranceGraph}
              isCompare={isCompare}
              comparePlanName={comparePlanName}
              compareItem={compareSimulationGraphs.insuranceGraph}
            />
          )}
        </Modal>
      )}
    </>
  )
}

export default SimulationGraphBody
