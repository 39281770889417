import React, { FC, useContext, useEffect } from 'react'
import { useField, useForm } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { OCCURANCE_TYPE_SELECT_ITEMS } from '@/constants/formItem/futures/futureV2Housings'
import { DescriptionContext } from '@/hooks/useDescriptionTemplates'
import { FutureV2HousingOtherCost } from '@/models/futures/futureV2HousingOtherCostsModel'
import { OCCURANCE_TYPE } from '@/models/futures/futureV2HousingOtherCostsModelFunc'

import Button from '@/components/v2/atoms/Button'
import Typography from '@/components/v2/atoms/Typography'
import SelectBox from '@/components/v2/molecules/SelectBox'
import NumberField from '@/components/v2/molecules/NumberField'
import TextField from '@/components/v2/atoms/TextField'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import CrossIcon from '@/assets/images/v2/cross.svg'

const OtherCostDetail: FC = () => {
  const { pickupDescriptionTemplates, setSendPageValueKeys } = useContext(
    DescriptionContext,
  )

  const { change, getState } = useForm()
  const { values } = getState()

  const otherCostAttributesFieldName =
    'future_v2_housing.future_v2_housing_other_attributes.future_v2_housing_other_costs_attributes'

  const {
    input: { value: otherCostsAttributes },
  } = useField(otherCostAttributesFieldName)

  useEffect(() => {
    setSendPageValueKeys([otherCostAttributesFieldName])
  }, [])

  useEffect(() => {
    pickupDescriptionTemplates(values)
  }, [otherCostsAttributes])

  const handleAddFutureV2HousingOtherCosts = () => {
    const newOtherCostsAttributes = [...otherCostsAttributes, {}]
    change(otherCostAttributesFieldName, newOtherCostsAttributes)
  }

  const handleDuplicateFutureV2HousingOtherCosts = (
    targetOtherCost: FutureV2HousingOtherCost,
  ) => {
    const copiedOtherCostsAttributes = { ...targetOtherCost }
    change(otherCostAttributesFieldName, [
      ...otherCostsAttributes,
      copiedOtherCostsAttributes,
    ])
  }

  return (
    <>
      <table className="col-span-1 md:col-span-2 w-[100%]">
        <tbody>
          <tr className="hidden lg:table-row">
            <th className="">
              <Typography className="pb-[10px] md:pb-0 text-[14px] text-black-800" isBold>
                項目名
              </Typography>
            </th>
            <th className="">
              <Typography className="pb-[10px] md:pb-0 text-[14px] text-black-800" isBold>
                頻度
              </Typography>
            </th>
            <th className="">
              <Typography className="pb-[10px] md:pb-0 text-[14px] text-black-800" isBold>
                金額
              </Typography>
            </th>
            <th className=""></th>
            <th className=""></th>
          </tr>

          <FieldArray name={otherCostAttributesFieldName}>
            {({ fields }) =>
              fields.map((name, index) => {
                return (
                  <React.Fragment key={index}>
                    <tr className="block lg:table-row border-b-[0.5px] border-black-400 lg:border-none py-[20px]">
                      {index > 0 ? (
                        <td className="w-full block lg:hidden">
                          <div className="flex relative items-center  w-full justify-end">
                            <CrossIcon
                              className="cursor-pointer"
                              onClick={() => fields.remove(index)}
                            />
                          </div>
                        </td>
                      ) : (
                        <td className="lg:hidden" />
                      )}
                      {/* 項目名 */}
                      <td className="p-[10px] w-full lg:w-auto block lg:table-cell">
                        <div className="flex items-center">
                          <Typography
                            className="pb-[10px] md:pb-0 text-[14px] text-black-800 table-cell lg:hidden min-w-[100px]"
                            isBold
                          >
                            項目名
                          </Typography>
                          <TextField type="text-right" name={`${name}name`} isFullWidth />
                        </div>
                      </td>

                      {/* 頻度 */}
                      <td className="p-[10px] w-full lg:w-auto block lg:table-cell">
                        <div className="flex flex-auto items-center">
                          <Typography
                            className="pb-[10px] md:pb-0 text-[14px] text-black-800 table-cell lg:hidden min-w-[100px]"
                            isBold
                          >
                            頻度
                          </Typography>
                          <div className="md:flex items-center">
                            <SelectBox
                              name={`${name}occurance_type`}
                              options={OCCURANCE_TYPE_SELECT_ITEMS}
                              isFullWidth
                            />
                            {otherCostsAttributes[index].occurance_type ===
                              OCCURANCE_TYPE.custom && (
                              <>
                                <div className="mt-[10px] md:mt-0 flex items-center">
                                  <NumberField
                                    name={`${name}custom_occurance_in_years`}
                                    isFullWidth
                                    className="mx-[10px]"
                                  />
                                  <Typography
                                    className="ml-[10px] text-[14px] text-black-800 min-w-[fit-content]"
                                    isBold
                                  >
                                    年ごと
                                  </Typography>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </td>

                      {/* 金額 */}
                      <td className=" p-[10px] w-full lg:w-auto block lg:table-cell ">
                        <div className="flex items-center">
                          <Typography
                            className="pb-[10px] md:pb-0 text-[14px] text-black-800 table-cell lg:hidden min-w-[100px]"
                            isBold
                          >
                            金額
                          </Typography>
                          <div className="flex flex-auto items-center">
                            <NumberField name={`${name}cost`} isFullWidth />
                            <Typography
                              className="pl-[8px] text-[14px] text-black-800"
                              isBold
                            >
                              円
                            </Typography>
                          </div>
                        </div>
                      </td>

                      {/* 複製ボタン */}
                      <td className="w-auto lg:table-cell flex justify-end">
                        <Button
                          className="w-[84px] md:w-[112px] h-[26px] md:h-[37px] mr-0 md:mr-[9px] rounded-[4px] bg-white border border-black-600 hover:bg-black-400"
                          onClick={() =>
                            handleDuplicateFutureV2HousingOtherCosts(fields.value[index])
                          }
                        >
                          <Typography
                            className="relative bottom-[1px] text-[12px] md:text-[14px] text-black-600"
                            isBold
                          >
                            複製する
                          </Typography>
                        </Button>
                      </td>

                      {/* 削除ボタン */}
                      {index > 0 ? (
                        <td className="hidden lg:w-auto lg:table-cell">
                          <div className="flex relative items-center  w-full justify-end">
                            <CrossIcon
                              className="cursor-pointer"
                              onClick={() => fields.remove(index)}
                            />
                          </div>
                        </td>
                      ) : (
                        <td className="" />
                      )}
                    </tr>
                  </React.Fragment>
                )
              })
            }
          </FieldArray>
        </tbody>
      </table>

      <ButtonPrimary
        className="w-[188px] h-[45px] mx-auto  my-[16px] col-span-1 md:col-span-2"
        onClick={() => handleAddFutureV2HousingOtherCosts()}
        // isDisabled={!isNotEmptyValue(otherCostsAttributes)}
      >
        <Typography className="text-[16px] text-white" isBold>
          追加する
        </Typography>
      </ButtonPrimary>
    </>
  )
}

export default OtherCostDetail
