import { useSelector } from 'react-redux'
import moment from 'moment'
import { RootState } from '@/store'
import { MANIPULATED_TYPE } from '@/constants/formItem/accounts'
import { HANDLER_TYPE } from '@/constants/formItem/futures/futureBases'

export const useAccountControl = () => {
  const {
    account: { manipulated_type, manipulated_stop_at },
    futureItems,
  } = useSelector((state: RootState) => state.accounts)

  const isMyDataManipulatedApproval = () => {
    if (manipulated_type === MANIPULATED_TYPE.forever) return true
    if (manipulated_type === MANIPULATED_TYPE.limited) {
      return moment().isSameOrBefore(manipulated_stop_at, 'day')
    }

    return false
  }

  const isSimulationManipulatedApproval = () => {
    if (futureItems?.handler_type === HANDLER_TYPE.fp) return true
    if (manipulated_type === MANIPULATED_TYPE.forever) return true

    if (manipulated_type === MANIPULATED_TYPE.limited) {
      return moment().isSameOrBefore(manipulated_stop_at, 'day')
    }

    return false
  }

  return { isMyDataManipulatedApproval, isSimulationManipulatedApproval }
}
