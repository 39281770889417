import { isNull as _isNull } from 'lodash'
import ApiClient from '@/api/apiClient'
import { getStorageAuthHeaders } from './localStorage/authHeadersStorage'

export const isLogind = () => {
  const authHeaders = getStorageAuthHeaders()
  if (_isNull(authHeaders)) return false

  ApiClient.setAuthHeaders(authHeaders)
  return true
}
