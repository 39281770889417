import ApiClient from '../apiClient'
import {
  InputCar,
  InputCarCreateValues,
  InputCarUpdateValues,
  InputCarDestroyValues,
} from '@/models/inputs/inputCarsModel'

export const getInputCars = async () => {
  return await ApiClient.get(
    '/input_cars',
    (response: InputCar[]) => response,
    undefined,
    false,
    'v2',
  )
}

export const postInputCar = async (values: InputCarCreateValues) => {
  return await ApiClient.post('/input_cars', () => {}, values)
}

export const patchInputCar = async (values: InputCarUpdateValues) => {
  return await ApiClient.patch('/input_cars', () => {}, values)
}

export const deleteInputCar = async (values: { params: InputCarDestroyValues }) => {
  return await ApiClient.delete('/input_cars/delete', () => {}, values)
}
