import React, { FC } from 'react'
import Typography from '@/components/v2/atoms/Typography'
import { FutureV2Housing } from '@/models/futures/futureV2HousingsModel'
import {
  convertOccuranceType,
  OCCURANCE_TYPE,
} from '@/models/futures/futureV2HousingOtherCostsModelFunc'
import { convertLocaleString } from '@/models/commonsModelFunc'

interface FutureV2HousingOtherBodyProps {
  futureV2Housing: Partial<FutureV2Housing>
}

const FutureV2HousingOtherBody: FC<FutureV2HousingOtherBodyProps> = (props) => {
  const { futureV2Housing } = props

  return (
    <>
      <table className="w-[100%] mb-[20px] last:mb-0 text-[12px]">
        <tbody>
          <tr className="h-[45px] odd:bg-black-100">
            <td className="w-[40%] px-[16px]">
              <Typography isBold>住み替え先</Typography>
            </td>
            <td className="w-[60%] px-[16px]">
              <Typography>実家・セカンドハウスなど</Typography>
            </td>
          </tr>
        </tbody>
      </table>

      <Typography isBold className="mb-[5px]">
        費用項目
      </Typography>
      <table className="w-[100%] mb-[20px] last:mb-0 text-[12px]">
        <tbody>
          {futureV2Housing.future_v2_housing_other?.future_v2_housing_other_costs.map(
            (otherCost, index) => (
              <React.Fragment key={index}>
                <tr className="h-[45px] odd:bg-black-100">
                  <td className="w-[40%] px-[16px]">
                    <Typography isBold>{otherCost?.name}</Typography>
                  </td>
                  <td className="w-[60%] px-[16px]">
                    {otherCost?.occurance_type === OCCURANCE_TYPE.custom ? (
                      <Typography>
                        {`${
                          otherCost?.custom_occurance_in_years
                        }年ごとに${convertLocaleString(otherCost?.cost)}円`}
                      </Typography>
                    ) : (
                      <Typography>
                        {`${convertOccuranceType(
                          otherCost?.occurance_type,
                        )} ${convertLocaleString(otherCost?.cost)}円`}
                      </Typography>
                    )}
                  </td>
                </tr>
              </React.Fragment>
            ),
          )}
        </tbody>
      </table>
    </>
  )
}

export default FutureV2HousingOtherBody
