import React, { FC, useEffect, useMemo, useContext } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import { findIndex as _findIndex, includes as _includes } from 'lodash'
import FamilyMonochromaticIcon from '@/assets/images/v2/family-monochromatic.svg'
import LoadingOverlay from '@/components/v1/atoms/LoadingOverlay'
import MarginContainer from '@/components/v2/atoms/MarginContainer'
import AlertError from '@/components/v2/molecules/AlertError'
import Breadcrumb from '@/components/v2/molecules/Breadcrumb'
import PaperTitle from '@/components/v2/molecules/PaperTitle'
import AifpPaper from '@/components/v2/organisms/AifpPaper'
import SelectionIconButton from '@/components/v2/molecules/SelectionIconButton'
import { fetchAccount, fetchAccountRegistryStatusV2 } from '@/containers/accountsSlice'
import { useAccountControl } from '@/hooks/useAccountControl'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import { AccountContext } from '@/models/accountsModelFunc'
import { RootState } from '@/store'
import MyDataListMainWrapper from '@/templates/v2/myData/_ListMainWrapper'
import AccountDetailHeader from '@/templates/admins/accounts/details/_accountDetailHeader'

interface MyDataListWrapperProps {
  /** タイトル */
  title: string
  /** 説明 */
  description?: string | JSX.Element
  /** 追加要素としてのボタン */
  subButton?: string | JSX.Element
  buttonTitle?: string
  /** onClickメソッド */
  onClick?: () => void
  updatedAt?: string
}

const MyDataListWrapper: FC<MyDataListWrapperProps> = (props) => {
  const dispatch = useDispatch()
  const locaiton = useLocation()
  const { account_id } = useParams<{ account_id?: string }>()

  const { handleMovePage } = useCustomHistory()
  const { isMyDataManipulatedApproval } = useAccountControl()
  const { isAdmin } = useContext(AccountContext)

  const { title, description, buttonTitle, onClick, children, subButton } = props
  const { registryStatus, isLoading } = useSelector((state: RootState) => state.accounts)
  const { account } = useSelector((state: RootState) => state.accounts)
  const { isDeleteLoading } = useSelector((state: RootState) => state.inputAssets)

  //** Moneytreeと連携しているデータの場合削除に時間がかかるため、別途ローディングフラグを用意 */
  const isAssetDeleteLoading = useMemo(
    () => _includes(location.pathname, '/myData/assets') && isDeleteLoading,
    [location.pathname, isDeleteLoading],
  )

  const urlPrefix = useMemo(() => {
    return isAdmin ? `/v2/accounts/${account_id}` : '/v2'
  }, [isAdmin])

  const getUrl = (path: string) => `${urlPrefix}${path}`

  const myDataSelectionOption = useMemo(() => {
    return [
      {
        title: 'ご家族の情報',
        icon: <i className="ico family" />,
        isNotice: !registryStatus?.family_structure,
        url: getUrl('/myData/families'),
      },
      {
        title: 'お仕事・年金',
        icon: <i className="ico user" />,
        isNotice: !registryStatus?.person_info,
        url: getUrl('/myData/salaries'),
      },
      {
        title: '基本生活費',
        icon: <i className="ico yen-bag" />,
        url: getUrl('/myData/monthlyCosts'),
        isNotice: !registryStatus?.monthly_cost,
      },
      {
        title: '住宅',
        icon: <i className="ico home" />,
        url: getUrl('/myData/housings'),
        isNotice: !registryStatus?.housing,
      },
      {
        title: '車・バイク',
        icon: <i className="ico car" />,
        url: getUrl('/myData/cars'),
        isNotice: false,
      },
      {
        title: '保険',
        icon: <i className="ico heart" />,
        url: getUrl('/myData/insurances'),
        isNotice: false,
      },
      {
        title: '万一の保障',
        icon: <i className="ico angel" />,
        url: getUrl('/myData/risk'),
        isNotice: false,
      },
      {
        title: '金融資産',
        icon: <i className="ico yen" />,
        url: getUrl('/myData/assets'),
        isNotice: !registryStatus?.asset,
      },
      {
        title: '不動産投資',
        icon: <i className="ico building" />,
        url: getUrl('/myData/estateInvestments'),
        isNotice: false,
      },
      {
        title: 'カスタム設定',
        icon: <i className="ico gear" />,
        url: getUrl('/myData/customItems'),
        isNotice: false,
      },
    ]
  }, [registryStatus])

  const needAlertArr = useMemo(() => {
    if (!registryStatus) return []

    const trueArr = Object.keys(registryStatus).reduce((acc: any, key: any) => {
      const skipKeyArr = ['custom_item', 'estate_investment', 'car', 'insurance']
      if (skipKeyArr.includes(key)) return acc
      if (!registryStatus[key]) {
        acc.push(key)
      }
      return acc
    }, [])

    return trueArr
  }, [registryStatus])

  const needAlertList = {
    asset: {
      message: '金融資産の情報が未入力です。編集ボタンより入力を完了させてください。',
      onClick: () => handleMovePage('/v2/myData/assets'),
    },
    car: {
      message: 'お車の情報が未入力です。編集ボタンより入力を完了させてください。',
      onClick: () => handleMovePage('/v2/myData/cars'),
    },
    custom_item: {
      message: 'カスタム設定の情報が未入力です。編集ボタンより入力を完了させてください。',
      onClick: () => handleMovePage('/v2/myData/customItems'),
    },
    estate_investment: {
      message: '不動産投資の情報が未入力です。編集ボタンより入力を完了させてください。',
      onClick: () => handleMovePage('/v2/myData/estateInvestments'),
    },
    family_structure: {
      message:
        'ご家族の情報、教育費の情報に不足があります。編集ボタンより入力を完了させてください。',
      onClick: () => handleMovePage('/v2/myData/families'),
    },
    housing: {
      message: '住宅の情報が未入力です。編集ボタンより入力を完了させてください。',
      onClick: () => handleMovePage('/v2/myData/housings'),
    },
    insurance: {
      message: '保険の情報が未入力です。編集ボタンより入力を完了させてください。',
      onClick: () => handleMovePage('/v2/myData/insurances'),
    },
    monthly_cost: {
      message: '基本生活費の情報が未入力です。編集ボタンより入力を完了させてください。',
      onClick: () => handleMovePage('/v2/myData/monthlyCosts'),
    },
    person_info: {
      message: 'お仕事・年金の情報が未入力です。編集ボタンより入力を完了させてください。',
      onClick: () => handleMovePage('/v2/myData/salaries'),
    },
  }

  const currentIndex = useMemo(
    () => _findIndex(myDataSelectionOption, { url: locaiton.pathname }),
    [location.pathname],
  )

  const { isConfirmingMydata } = useSelector((state: RootState) => state.futureBases)

  useEffect(() => {
    dispatch(fetchAccountRegistryStatusV2())
    !isAdmin && (account?.id ?? 0) < 1 && dispatch(fetchAccount())
  }, [])

  return (
    <>
      <LoadingOverlay isLoading={isLoading || isAssetDeleteLoading} />
      {isAdmin && <AccountDetailHeader />}

      <MarginContainer
        className={clsx({
          ['px-[8px] md:px-[32px] lg:px-[0] py-[8px] md:py-[40px]']: !isAdmin,
          ['px-[8px] pb-[8px] max-w-[initial]']: isAdmin,
        })}
      >
        <Breadcrumb
          className={'pb-[10px] md:pb-[32px] ' + (isConfirmingMydata ? 'hidden' : '')}
          data={[
            {
              name: 'マイデータ',
              url: getUrl('/myData'),
            },
            { name: 'マイデータ設定' },
          ]}
        />

        <PaperTitle
          className="mb-[16px] md:mb-[40px]"
          title="マイデータ設定"
          description="日々の収入や支出を登録することで改善案などのご提案が可能になります"
          icon={<FamilyMonochromaticIcon />}
          smallIcon={
            <FamilyMonochromaticIcon
              className="h-[82px] w-[130px]"
              viewBox="0 0 117 154"
            />
          }
        />

        {account && !account['is_done_with_required_input?'] && (
          <AifpPaper
            text="次に、現在のお金の状況を登録していきましょう。\nより正確な数字を入れることで、サマリーを詳細に確認できるようになります。"
            className="mb-[16px] md:mb-[40px]"
          />
        )}
        {account &&
          account['is_done_with_required_input?'] &&
          !account['is_done_with_steps?'] && (
            <AifpPaper
              text="設定お疲れ様でした。\n次に、未来のお金の状況を知るために、シミュレーションプランを作成していきましょう！"
              buttonLabel="次のステップへ進む"
              onClick={() => handleMovePage('/v2/dashboards')}
              isDisabled={isAdmin}
            />
          )}

        <div className="pb-[24px] md:pb-[44px] px-[0] md:px-[40px]">
          {needAlertArr?.map((item: any) => {
            return (
              <AlertError
                key={item}
                className="mb-[10px] last:mb-[0] cursor-pointer"
                message={(needAlertList as any)?.[item]?.message}
                onClick={(needAlertList as any)?.[item]?.onClick}
                hideCloseButton
              />
            )
          })}
        </div>

        <SelectionIconButton
          className="pb-[8px] lg:pb-[43px]"
          currentIndex={currentIndex}
          options={myDataSelectionOption}
          onClick={(item) => handleMovePage(item.url)}
        />

        <MyDataListMainWrapper
          title={title}
          description={description}
          onClick={onClick}
          buttonTitle={buttonTitle}
          isManipulatedApproval={isAdmin ? isMyDataManipulatedApproval() : true}
          subButton={subButton}
          updatedAt={props.updatedAt}
        >
          {children}
        </MyDataListMainWrapper>
      </MarginContainer>
    </>
  )
}

export default MyDataListWrapper
