import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { parse } from 'query-string'
import ApiClient from '@/api/apiClient'
import MarginContainer from '@/components/v2/atoms/MarginContainer'
import Paper from '@/components/v2/atoms/Paper'
import {
  fetchAccountShowInvited,
  updateAccountPasswordSetup,
} from '@/containers/accountsSlice'
import { setPageMeta } from '@/containers/pageMetasSlice'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import LogindRedirect from '@/layouts/v2/LogindRedirect'
import AccountPasswordForm from '@/templates/v2/accounts/commons/AccountPasswordForm'
import { AccountPasswordUpdateValues } from '@/models/accountsModel'
import { removeStorageAuthHeaders } from '@/utils/localStorage/authHeadersStorage'

const AccountInvited: FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { handleMovePage } = useCustomHistory()

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: '招待専用会員登録',
        description: '招待専用会員登録',
        keyword: '招待専用会員登録',
      }),
    )

    const { token, ma } = parse(location.search)

    ApiClient.setAuthHeaders({
      Authorization: `Token token=${token}`,
      MailAddress: ma as string,
    })

    dispatch(
      fetchAccountShowInvited(() => {
        window.alert(
          `パスワード設定期限が切れているか、招待リンクのURLが間違っている可能性があります`,
        )
        removeStorageAuthHeaders()
        handleMovePage('/v2/')
      }),
    )
  }, [])

  const handleSubmit = (values: AccountPasswordUpdateValues) => {
    return new Promise((resolve) => {
      dispatch(
        updateAccountPasswordSetup(
          values,
          () => {
            history.push('/v2/accounts/password/completed')
          },
          resolve,
        ),
      )
    })
  }

  return (
    <LogindRedirect>
      <MarginContainer className="py-40">
        <Paper className="mx-8 px-16 py-24 sm:p-40 bg-white">
          <AccountPasswordForm
            title="招待専用会員登録"
            isConfirm
            handleSubmit={handleSubmit}
          />
        </Paper>
      </MarginContainer>
    </LogindRedirect>
  )
}

export default AccountInvited
