import React, { FC, useEffect, useContext } from 'react'
import FamilyMonochromaticIcon from '@/assets/images/v2/family-monochromatic.svg'
import Divider from '@/components/v2/atoms/Divider'
import MarginContainer from '@/components/v2/atoms/MarginContainer'
import Paper from '@/components/v2/atoms/Paper'
import Typography from '@/components/v2/atoms/Typography'
import Breadcrumb from '@/components/v2/molecules/Breadcrumb'
import { useDispatch } from 'react-redux'
import { cloneDeep as _cloneDeep, find as _find, set as _set } from 'lodash'
import { useParams } from 'react-router'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import { setPageMeta } from '@/containers/pageMetasSlice'
import PaperTitle from '@/components/v2/molecules/PaperTitle'
import { AccountContext } from '@/models/accountsModelFunc'
import MyDataHousingsEditForm from './Form'

const MyDataHousingsEdit: FC = () => {
  const dispatch = useDispatch()
  const { handleMovePage } = useCustomHistory()
  const { isAdmin } = useContext(AccountContext)
  const { account_id } = useParams<{ account_id?: string }>()
  const { id } = useParams<{ id?: string }>()

  const movePageAfterForm = () => {
    handleMovePage(
      isAdmin ? `/v2/accounts/${account_id}/myData/housings` : '/v2/myData/housings',
    )
  }

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'マイデータ | 住居 編集',
        description: 'マイデータ | 住居 編集',
        keyword: 'マイデータ | 住居 編集',
      }),
    )
  }, [])

  return (
    <MarginContainer className="px-8 md:px-32 lg:px-0 py-8 md:py-40">
      <Breadcrumb
        className="pb-24 md:pb-32"
        data={[
          {
            name: 'マイデータ',
            url: isAdmin ? `/v2/accounts/${account_id}/myData` : '/v2/myData',
          },
          {
            name: 'マイデータ設定',
            url: isAdmin
              ? `/v2/accounts/${account_id}/myData/housings`
              : '/v2/myData/housings',
          },
          { name: '住居の設定' },
        ]}
      />

      <PaperTitle
        className="mb-[16px] md:mb-[40px]"
        title="住居の設定"
        description="日々の収入や支出を登録することで改善案などのご提案が可能になります"
        icon={<FamilyMonochromaticIcon />}
        smallIcon={
          <FamilyMonochromaticIcon className="h-[82px] w-[130px]" viewBox="0 0 117 154" />
        }
      />

      <Paper className="bg-white p-24 md:p-40">
        <div className="flex items-center pb-10">
          <Typography
            className="bottom-2 pr-8 relative text-24 md:text-28 text-black-800"
            isBold
          >
            ご自宅について
          </Typography>
        </div>
        <div className="flex items-center pb-10">
          <Typography>
            {/*TODO: No.25 住宅ローン減税の更新: こちらのリンク先が決定したらリンクを設定してコメントイン*/}
            {/*住宅ローン減税と補助金の反映内容、例外の入力方法については<a href="">こちら</a>をご確認ください。
            <br />
            <br />*/}
            <div className="text-[12px]">本シミュレーションに関わる計算は、以下の情報をもとに計算しております（2024年2月1日現在）。</div>
            <div className="text-[12px]">住宅ローン減税：<a className="text-[12px] text-tertiary-500 border-b border-tertiary-500" target="_blank" href="https://www.mlit.go.jp/report/press/content/001714809.pdf">https://www.mlit.go.jp/report/press/content/001714809.pdf</a></div>
            <br />
            <div className="text-[12px]">以下補助金については、計算対象としておりませんので、カスタム設定にて設定をお願いいたします。</div>
            <div className="text-[12px]">補助金（子育てエコホーム支援事業）：<a className="text-[12px] text-tertiary-500 border-b border-tertiary-500" target="_blank" href="https://kosodate-ecohome.mlit.go.jp/">https://kosodate-ecohome.mlit.go.jp/</a></div>
            <div className="text-[12px]">※ 出典元：国土交通省</div>
          </Typography>
        </div>

        <Divider className="border-black-300 border-t-2 pb-20 md:pb-24" />

        <MyDataHousingsEditForm
          id={id}
          onClose={movePageAfterForm}
          onSubmit={movePageAfterForm}
        />
      </Paper>
    </MarginContainer>
  )
}

export default MyDataHousingsEdit
