import { FC, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const ScrollToTopOnInit: FC = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    const jsContent = window.document.getElementById('js-content')
    if (jsContent) jsContent.scrollTop = 0
  }, [pathname])

  return null
}
export default ScrollToTopOnInit
