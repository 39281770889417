import { isUndefined as _isUndefined, find as _find, filter as _filter } from 'lodash'
import { CHANGE_TYPE } from '@/models/commonIncomeChangesModelFunc'
import { FutureIncomeBase } from '@/models/futures/futureIncomeBasesModel'

export const buildUpdateInitialValues = (futureIncome: FutureIncomeBase | undefined) => {
  if (_isUndefined(futureIncome)) {
    return { future_income: { future_income_changes_attributes: [] }, retirement_age: {} }
  }

  const firstIncomeChangeItems = _filter(
    futureIncome.future_income_changes,
    (item) => item.change_type !== CHANGE_TYPE.retirementAge,
  )

  const secondIncomeChangeItem = _find(
    futureIncome.future_income_changes,
    (item) => item.change_type === CHANGE_TYPE.retirementAge,
  )

  return {
    future_income: {
      ...futureIncome,
      future_income_changes_attributes: firstIncomeChangeItems,
    },
    retirement_age: secondIncomeChangeItem,
  }
}
