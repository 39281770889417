import { filter as _filter, includes as _includes } from 'lodash'
import {
  YEARLY_LIVING_COST_LARGE_ELECTRONIC_KEYS,
  YEARLY_LIVING_COST_SMALL_ELECTRONIC_KEYS,
  YEARLY_LIVING_COST_ELECTRONIC_SHEET,
  OCCURANCE_TERM_TYPE,
} from '@/models/commonYearlyLivingCostsModelFunc'

export const buildFutureYearlyLivingCostCreateInitialValues = () => {
  const ceremonies = [
    { affected_start_at_age: 20, affected_end_at_age: 29, yearly_cost: 0 },
    { affected_start_at_age: 30, affected_end_at_age: 39, yearly_cost: 0 },
    { affected_start_at_age: 40, affected_end_at_age: 49, yearly_cost: 0 },
    { affected_start_at_age: 50, affected_end_at_age: 59, yearly_cost: 0 },
    { affected_start_at_age: 60, affected_end_at_age: 69, yearly_cost: 0 },
    { affected_start_at_age: 70, affected_end_at_age: 79, yearly_cost: 0 },
  ]

  return {
    future_base: {
      future_yearly_living_cost_ceremonies_attributes: ceremonies,
      future_yearly_living_cost_home_comings_attributes: [
        {
          single_cost: 0,
          occurance_term_in_years: 1,
          occurance_per_occurance_term: 1,
          affected_end_at_age: 60,
        },
      ],
      future_yearly_living_cost_travels_attributes: [
        {
          single_cost: 0,
          occurance_term_in_years: 1,
          occurance_per_occurance_term: 1,
          affected_end_at_age: 60,
        },
      ],
    },
    future_yearly_living_cost_large_electronics: YEARLY_LIVING_COST_LARGE_ELECTRONIC_KEYS.map(
      (key) => ({
        default_key: key,
        display_name: YEARLY_LIVING_COST_ELECTRONIC_SHEET[key].display_name,
      }),
    ),
    future_yearly_living_cost_small_electronics: YEARLY_LIVING_COST_SMALL_ELECTRONIC_KEYS.map(
      (key) => ({
        default_key: key,
        display_name: YEARLY_LIVING_COST_ELECTRONIC_SHEET[key].display_name,
      }),
    ),
  }
}

export const buildFutureYearlyLivingCostUpdateInitialValues = (
  ceremonies: any,
  electronics: any,
  homeComings: any,
  travels: any,
) => {
  let largeElectronics = _filter(electronics, (item) =>
    _includes(YEARLY_LIVING_COST_LARGE_ELECTRONIC_KEYS, item.default_key),
  )
  largeElectronics = largeElectronics.map((item) => ({
    ...item,
    is_active: item.is_active ? [true] : false,
  }))

  let smallElectronics = _filter(
    electronics,
    (item) =>
      _includes(YEARLY_LIVING_COST_SMALL_ELECTRONIC_KEYS, item.default_key) ||
      !item['is_default?'],
  )
  smallElectronics = smallElectronics.map((item) => ({
    ...item,
    is_active: item.is_active ? [true] : false,
  }))

  const newHomeComings = homeComings.map((item: any) => ({
    ...item,
    occurance_term_type:
      item.occurance_per_occurance_term === 1
        ? OCCURANCE_TERM_TYPE.year
        : OCCURANCE_TERM_TYPE.time,
  }))

  const newTravels = travels.map((item: any) => ({
    ...item,
    occurance_term_type:
      item.occurance_per_occurance_term === 1
        ? OCCURANCE_TERM_TYPE.year
        : OCCURANCE_TERM_TYPE.time,
  }))

  return {
    future_base: {
      future_yearly_living_cost_ceremonies_attributes: ceremonies,
      future_yearly_living_cost_home_comings_attributes: newHomeComings,
      future_yearly_living_cost_travels_attributes: newTravels,
    },
    future_yearly_living_cost_large_electronics: largeElectronics,
    future_yearly_living_cost_small_electronics: smallElectronics,
  }
}

export const buildFutureYearlyLivingCostSubmitValuesV2 = (values: any, age: number) => {
  const {
    future_yearly_living_cost_large_electronics,
    future_yearly_living_cost_small_electronics,
  } = values
  const {
    future_yearly_living_cost_home_comings_attributes,
    future_yearly_living_cost_travels_attributes,
  } = values.future_base
  const newValues = { future_base: { ...values.future_base } }

  let newElectronics = [
    ...future_yearly_living_cost_large_electronics,
    ...future_yearly_living_cost_small_electronics,
  ]
  newElectronics = newElectronics.map((item) => ({
    ...item,
    is_active: !!item.is_active,
  }))

  const newHomeComings = future_yearly_living_cost_home_comings_attributes.map(
    (item: any) => {
      const isYear = item.occurance_term_type === OCCURANCE_TERM_TYPE.year
      return {
        ...item,
        affected_start_at_age: age,
        occurance_term_in_years: isYear ? item.occurance_term_in_years : 1,
        occurance_per_occurance_term: isYear ? 1 : item.occurance_per_occurance_term,
      }
    },
  )

  const newTravelComings = future_yearly_living_cost_travels_attributes.map(
    (item: any) => {
      const isYear = item.occurance_term_type === OCCURANCE_TERM_TYPE.year
      return {
        ...item,
        affected_start_at_age: age,
        occurance_term_in_years: isYear ? item.occurance_term_in_years : 1,
        occurance_per_occurance_term: isYear ? 1 : item.occurance_per_occurance_term,
      }
    },
  )

  newValues.future_base.future_yearly_living_cost_electronics_attributes = newElectronics
  newValues.future_base.future_yearly_living_cost_home_comings_attributes = newHomeComings
  newValues.future_base.future_yearly_living_cost_travels_attributes = newTravelComings

  return newValues
}
