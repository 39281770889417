import React, { FC, useEffect, useContext } from 'react'
import { useDispatch } from 'react-redux'
import {
  isEmpty as _isEmpty,
  isUndefined as _isUndefined,
  pick as _pick,
  isNull as _isNull,
  every as _every,
  filter as _filter,
  slice as _slice,
  reduce as _reduce,
  find as _find,
} from 'lodash'
import FamilyMonochromaticIcon from '@/assets/images/v2/family-monochromatic.svg'
import MarginContainer from '@/components/v2/atoms/MarginContainer'
import Paper from '@/components/v2/atoms/Paper'
import PaperTitle from '@/components/v2/molecules/PaperTitle'
import Typography from '@/components/v2/atoms/Typography'
import Breadcrumb from '@/components/v2/molecules/Breadcrumb'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import { useParams } from 'react-router'
import { setPageMeta } from '@/containers/pageMetasSlice'
import { AccountContext } from '@/models/accountsModelFunc'
import MyDataEstateInvestmentForm from './Form'

const MyDataEstateInvestmentEdit: FC<any> = () => {
  const dispatch = useDispatch()
  const { handleMovePage } = useCustomHistory()
  const { isAdmin } = useContext(AccountContext)
  const { account_id } = useParams<{ account_id?: string }>()

  const { id } = useParams<{ id?: string }>()

  const movePageAfterForm = () => {
    handleMovePage(
      isAdmin
        ? `/v2/accounts/${account_id}/myData/estateInvestments`
        : '/v2/myData/estateInvestments',
    )
  }

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'マイデータ | 投資用不動産 編集',
        description: 'マイデータ | 投資用不動産 編集',
        keyword: 'マイデータ | 投資用不動産 編集',
      }),
    )
  }, [])

  return (
    <MarginContainer className="px-[8px] md:px-[32px] lg:px-0 py-[8px] md:py-[40px]">
      <Breadcrumb
        className="pb-[10px] md:pb-[32px]"
        data={[
          {
            name: 'マイデータ',
            url: isAdmin ? `/v2/accounts/${account_id}/myData` : '/v2/myData',
          },
          {
            name: 'マイデータ設定',
            url: isAdmin
              ? `/v2/accounts/${account_id}/myData/estateInvestments`
              : '/v2/myData/estateInvestments',
          },
          { name: '不動産投資の設定' },
        ]}
      />

      <PaperTitle
        className="mb-[16px] md:mb-[40px]"
        title="不動産投資"
        description="不動産投資を設定しましょう"
        icon={<FamilyMonochromaticIcon />}
        smallIcon={
          <FamilyMonochromaticIcon className="h-[82px] w-[130px]" viewBox="0 0 117 154" />
        }
      />

      <Paper className="bg-white p-[24px] md:p-[40px]">
        <Typography
          className="mb-[32px] pb-[10px] text-[24px] md:text-[28px] text-black-800 border-b-2 border-black-300"
          isBold
        >
          不動産投資の設定について
        </Typography>

        <MyDataEstateInvestmentForm
          id={id}
          onClose={movePageAfterForm}
          onSubmit={movePageAfterForm}
        />
      </Paper>
    </MarginContainer>
  )
}

export default MyDataEstateInvestmentEdit
