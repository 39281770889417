import React, { FC } from 'react'

interface DividerProps {
  /** クラス名 */
  className?: string
}

const Divider: FC<DividerProps> = (props) => {
  const { className } = props
  return <hr className={className} />
}

export default Divider
