import { API_FLAG } from '../../../models/commonsModelFunc'
import {
  INPUT_PROPERTY_TYPE,
  INPUT_MANAGEMENT_FEE_TYPE,
  INPUT_VACANCY_SETTING_TYPE,
} from '../../../models/inputs/inputEstateInvestmentsModelFunc'
import { INPUT_REPAYMENT_TYPE } from '../../../models/inputs/inputEstateLoanCalculationsModelFunc'

// 物件タイプ
export const INPUT_PROPERTY_TYPE_RADIO_ITEMS = [
  { value: INPUT_PROPERTY_TYPE.building, label: '１棟' },
  { value: INPUT_PROPERTY_TYPE.section, label: '区分' },
]

// 年間下落率タイプ
export const INPUT_ANNUAL_RATE_OF_DECLINE_TYPE_RADIO_ITEMS = [
  { value: API_FLAG.on, label: '設定する' },
  { value: API_FLAG.off, label: '設定しない' },
]

// 建物・賃貸管理手数料 設定タイプ
export const INPUT_MANAGEMENT_FEE_TYPE_RADIO_ITEMS = [
  { value: INPUT_MANAGEMENT_FEE_TYPE.rate, label: '賃料に対する比率で設定' },
  { value: INPUT_MANAGEMENT_FEE_TYPE.yen, label: '円で設定' },
]

// 空室率設定タイプ
export const INPUT_VACANCY_SETTING_TYPE_RADIO_ITEMS = [
  { value: INPUT_VACANCY_SETTING_TYPE.own, label: '直接入力' },
  { value: INPUT_VACANCY_SETTING_TYPE.auto, label: '必要項目を入力して自動計算' },
]

// 金利変化有無
export const INPUT_RATE_CHANGE_TYPE_RADIO_ITEMS = [
  { value: API_FLAG.on, label: 'ある' },
  { value: API_FLAG.off, label: 'ない' },
]

// 団体信用生命保険の加入有無
export const INPUT_LIFE_INSURANCE_TYPE_RADIO_ITEMS = [
  { value: API_FLAG.on, label: '加入している' },
  { value: API_FLAG.off, label: '加入していない' },
]

// 過去繰上げ返済有無
export const INPUT_ADVANCE_REPAYMENT_TYPE_RADIO_ITEMS = [
  { value: API_FLAG.off, label: '行ったことがない' },
  { value: API_FLAG.on, label: '行ったことがある' },
]

// ボーナス払い設定有無
export const INPUT_BONUS_FLG_RADIO_ITEMS = [
  { value: API_FLAG.on, label: '設定した' },
  { value: API_FLAG.off, label: '設定していない' },
]

// 過去繰上げ返済方法
export const INPUT_REPAYMENT_TYPE_SELECT_ITEMS = [
  { value: INPUT_REPAYMENT_TYPE.shortTerm, label: '期間短縮型' },
  { value: INPUT_REPAYMENT_TYPE.amountMigration, label: '返済額軽減型' },
]
