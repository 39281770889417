import ApiClient from '@/api/apiClient'
import {
  UnassignedAccountResponse,
  UnassignedAccountCreateValues,
} from '@/models/admins/fpAccountAssignsModel'

export const getFpAccountAssigns = async () => {
  return await ApiClient.get(`/fp_account_assigns`, (response: any[]) => response)
}

export const getUnassignedAccounts = async (pageNum?: number | string, params?: any | undefined) => {

  return await ApiClient.get(
    `/admin/accounts/unassigned_accounts?page=${pageNum || 1}&` +
    `name=${params.name || ''}&` +
    `age_lteq=${params.age_lteq || ''}&` +
    `age_gteq=${params.age_gteq || ''}&` +
    `last_login_at_lteq=${params.last_login_at_lteq || ''}&` +
    `last_login_at_gteq=${params.last_login_at_gteq || ''}&` +
    `income_total_lteq=${params.income_total_lteq || ''}&` +
    `income_total_gteq=${params.income_total_gteq || ''}&` +
    `financial_asset_total_lteq=${params.financial_asset_total_lteq || ''}&` +
    `financial_asset_total_gteq=${params.financial_asset_total_gteq || ''}&` +
    `bank_asset_total_lteq=${params.bank_asset_total_lteq || ''}&` +
    `bank_asset_total_gteq=${params.bank_asset_total_gteq || ''}&` +
    `other_asset_total_lteq=${params.other_asset_total_lteq || ''}&` +
    `other_asset_total_gteq=${params.other_asset_total_gteq || ''}&` +
    `created_at_lteq=${params.created_at_lteq || ''}&` +
    `created_at_gteq=${params.created_at_gteq || ''}&` +
    `account_type=${params.account_type || ''}&` +
    `fp_account_id=${params.search_fp_account_id || ''}`,
    (response: UnassignedAccountResponse[]) => response,
  )
}

export const postFpAccountAssigns = async (values: UnassignedAccountCreateValues) => {
  return await ApiClient.post('/admin/fp_account_assigns/bulk_create', () => {}, values)
}
