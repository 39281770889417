import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import Button from '@/components/v2/atoms/Button'
import Divider from '@/components/v2/atoms/Divider'

import Typography from '@/components/v2/atoms/Typography'
import {
  sumPastMonthlyCost,
  renderPastMonthlyCostLabel,
} from '@/models/aggBalanceMonthliesModelFunc'
import { RootState } from '@/store'

const classes = {
  root: 'p-15 border border-black-500 my-30',
  averageHeaderBlock: 'pb-10 text-16 justify-between',
  divider: 'pb-10',
  averageBlock: 'pb-10 flex justify-between',
  collapseButtonBlock: 'text-14 w-full text-black-500',
  collapseButton: 'mx-auto',
}
const FutureMonthlyLivingCostAverageLivingCostBody: FC = () => {
  const [isCollapseBodyOpen, setIsCollapseBodyOpen] = useState<boolean>(false)
  const { aggBalanceMonthly } = useSelector(
    (state: RootState) => state.aggBalanceMonthlies,
  )

  return (
    <div className={classes.root}>
      <div className={classes.averageHeaderBlock}>
        <Typography isBold className="w-[fit-content] mx-auto">
          過去3ヶ月の基本生活費の平均
        </Typography>
        <div className={classes.averageBlock}>
          <Typography>合計</Typography>
          <Typography>{`月間${sumPastMonthlyCost(aggBalanceMonthly)}円`}</Typography>
        </div>
      </div>
      {isCollapseBodyOpen && (
        <div>
          <Divider className={classes.divider} />
          <div className={classes.averageBlock}>
            <Typography>食費</Typography>
            <Typography>
              {renderPastMonthlyCostLabel(aggBalanceMonthly?.food_expenses)}
            </Typography>
          </div>
          <div className={classes.averageBlock}>
            <Typography>水道光熱費</Typography>
            <Typography>
              {renderPastMonthlyCostLabel(aggBalanceMonthly?.utility_bills)}
            </Typography>
          </div>
          <div className={classes.averageBlock}>
            <Typography>日用品雑貨費</Typography>
            <Typography>
              {renderPastMonthlyCostLabel(aggBalanceMonthly?.daily_necessities_cost)}
            </Typography>
          </div>
          <div className={classes.averageBlock}>
            <Typography>交通費</Typography>
            <Typography>
              {renderPastMonthlyCostLabel(aggBalanceMonthly?.transport)}
            </Typography>
          </div>
          <div className={classes.averageBlock}>
            <Typography>被服費</Typography>
            <Typography>
              {renderPastMonthlyCostLabel(aggBalanceMonthly?.clothing_cost)}
            </Typography>
          </div>
          <div className={classes.averageBlock}>
            <Typography>趣味・娯楽費</Typography>
            <Typography>
              {renderPastMonthlyCostLabel(aggBalanceMonthly?.hobby_expenses)}
            </Typography>
          </div>
          <div className={classes.averageBlock}>
            <Typography>通信費</Typography>
            <Typography>
              {renderPastMonthlyCostLabel(aggBalanceMonthly?.communication_cost)}
            </Typography>
          </div>
          <div className={classes.averageBlock}>
            <Typography>交際費</Typography>
            <Typography>
              {renderPastMonthlyCostLabel(aggBalanceMonthly?.entertainment_expenses)}
            </Typography>
          </div>
          <div className={classes.averageBlock}>
            <Typography>医療費</Typography>
            <Typography>
              {renderPastMonthlyCostLabel(aggBalanceMonthly?.medical_expenses)}
            </Typography>
          </div>
          <div className={classes.averageBlock}>
            <Typography>こづかい</Typography>
            <Typography>
              {renderPastMonthlyCostLabel(aggBalanceMonthly?.pocket_money)}
            </Typography>
          </div>
          <div className={classes.averageBlock}>
            <Typography>未分類・使途不明金</Typography>
            <Typography>
              {renderPastMonthlyCostLabel(
                Number(aggBalanceMonthly?.uncategorized_cash) +
                  Number(aggBalanceMonthly?.uncategorized_expense) +
                  Number(aggBalanceMonthly?.other_cost),
              )}
            </Typography>
          </div>
        </div>
      )}

      <div
        className={classes.collapseButtonBlock}
        onClick={() => setIsCollapseBodyOpen(!isCollapseBodyOpen)}
      >
        <Button
          className={classes.collapseButton}
          children={isCollapseBodyOpen ? '内訳を非表示にする' : '内訳を表示する'}
        />
      </div>
    </div>
  )
}

export default FutureMonthlyLivingCostAverageLivingCostBody
