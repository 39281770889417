import React, { FC } from 'react'
import SettingTitleIcon from '@/assets/images/v2/setting-title.svg'
import Heading from '@/components/v2/atoms/Heading'
import Paper from '@/components/v2/atoms/Paper'
import Typography from '@/components/v2/atoms/Typography'
import Breadcrumb from '@/components/v2/molecules/Breadcrumb'

const AccountSettingsTitle: FC = () => (
  <>
    <Breadcrumb className="pb-24 md:pb-32" data={[{ name: 'アカウント設定' }]} />

    <Paper className="bg-secondary-500 mb-24 md:mb-40 py-24 relative">
      <div className="flex pb-16">
        <div className="bg-white h-48 mr-16 rounded-r-8 w-8" />
        <Heading size={0} color="white" className="bottom-2 relative">
          アカウント設定
        </Heading>
      </div>
      <Typography className="pl-24 text-14 text-white">
        サービスのアカウント設定
      </Typography>
      <SettingTitleIcon className="hidden sm:block absolute bottom-17 sm:right-17 md:right-123" />
    </Paper>
  </>
)

export default AccountSettingsTitle
