import React, { FC } from 'react'

// v2用のファイルがまだない
import HousingLoanRepaymentTable from '@/templates/v2/simulations/futureV2Housings/FutureLendHousingModalForm/commons/_housingLoanRepaymentTable'

const LoanRepaymentTable: FC = () => {
  return (
    <HousingLoanRepaymentTable housingLoansFormKey="future_lend_housing.housing_loan_attributes" />
  )
}

export default LoanRepaymentTable
