import {
  ANNUAL_TYPE,
  INVESTMENT_TYPE,
  TRANSFER_TYPE,
  CONTINUAL_TRANSFER_TYPE,
} from '@/models/commonAssetsModelFunc'
import { API_BOOLEAN_FLAG } from '@/models/commonsModelFunc'

// 課税対象種別
export const IS_TAX_CHARGABLE_SELECT_ITEMS = [
  { value: API_BOOLEAN_FLAG.on, label: '課税対象とする' },
  { value: API_BOOLEAN_FLAG.off, label: '課税対象としない' },
]

// 運用利回りの変化有無
export const ANNUAL_TYPE_SELECT_ITEMS = [
  { value: ANNUAL_TYPE.yes, label: 'ある' },
  { value: ANNUAL_TYPE.no, label: 'ない' },
]

// 投資方法
export const TRANSFER_TYPE_SELECT_ITEMS = [
  { value: TRANSFER_TYPE.once, label: '一時金の投資を設定する' },
  { value: TRANSFER_TYPE.countinual, label: '積立金の投資を設定する' },
]

// 投資の振替先
export const INVESTMENT_TYPE_SELECT_ITEMS = [
  { value: INVESTMENT_TYPE.deductedSavings, label: '社内預金、財形、持株など' },
  { value: INVESTMENT_TYPE.trustFund, label: '投資信託' },
  { value: INVESTMENT_TYPE.stock, label: '株式' },
  { value: INVESTMENT_TYPE.bond, label: '債券' },
  { value: INVESTMENT_TYPE.other, label: 'その他' },
]

// 年間積立額種別
export const CONTINUAL_TRANSFER_TYPE_RADIO_ITEMS = [
  { value: CONTINUAL_TRANSFER_TYPE.input, label: '金額で設定' },
  { value: CONTINUAL_TRANSFER_TYPE.rate, label: '割合で設定' },
]
