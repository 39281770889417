import {
  find as _find,
  includes as _includes,
  isEmpty as _isEmpty,
  isNull as _isNull,
  isUndefined as _isUndefined,
} from 'lodash'
import { InputSalaryCommonValues } from './inputSalariesModel'
import { API_FLAG, defaultToEmptyString, isNotEmptyValue } from '../commonsModelFunc'
import {
  INPUT_EMPLOYEE_SIZE_BUTTON_ITEMS,
  INPUT_INDUSTRY_BUTTON_ITEMS,
  INPUT_INSURANCE_PENSION_TYPE_BUTTON_ITEMS,
  INPUT_PERSONAL_BUSINESS_AOIRO_DEDUCTION_SELECT_ITEMS,
  INPUT_PERSONAL_BUSINESS_TAX_RATE_SELECT_ITEMS,
} from '@/constants/formItem/inputs/inputSalaries'

// 職業
export const INPUT_PROFESSION = {
  owner: 0,
  companyExecutive: 1,
  regularEmployee: 2,
  contractEmployee: 3,
  partTimeJob: 4,
  freelance: 5,
  student: 6,
  other: 7,
  housewife: 8,
}

// 業種
export const INPUT_INDUSTRY = {
  average: 0,
  mining: 1,
  construction: 2,
  manufacturing: 3,
  lifeline: 4,
  telecommunications: 5,
  transportation: 6,
  wholesale: 7,
  finance: 8,
  realEstate: 9,
  restaurant: 10,
  medical: 11,
  education: 12,
  postOffice: 13,
  research: 14,
  lifeService: 15,
  otherService: 16,
}

// 従業員規模
export const INPUT_EMPLOYEE_SIZE = {
  average: 0,
  thousand: 1,
  hundred: 2,
  ten: 3,
  one: 4,
}

// 加入保険/年金
export const INPUT_INSURANCE_PENSION_TYPE = {
  welfarePension: 'welfare_pension',
  mutualAidPension: 'old_mutual_aid_pension',
  nationalPension: 'national_pension',
  notSubscribed: 'uninsured',
}

// 個人事業税の区分
export const INPUT_PERSONAL_BUSINESS_TAX_RATE_TYPE = {
  excluded: 0,
  threePercent: 0.03,
  fourPercent: 0.04,
  fivePercent: 0.05,
}

export const INPUT_NET_INCOME_MONTHLY_FIELDS = [
  'health_insurance_monthly',
  'welfare_pension_monthly',
  'employment_insurance_monthly',
  'income_tax_monthly',
  'resident_tax_monthly',
  'mutual_aid_pension_monthly',
  'company_saving_amount_monthly',
  'other_deduction_monthly',
  'deducted_housing_ids',
  'insurance_deduction_type',
  'deducted_insurances_attributes',
  'gross_income_monthly',
  'commutation_allowance',
  'salary_slip_confirmation',
]

export const INPUT_FREELANCE_NET_INCOME_MONTHLY_FIELDS = [
  'input_salary_freelancer_attributes.gross_income_monthly',
  'input_salary_freelancer_attributes.expense_monthly',
]

export const convertProfession = (profession: number | null) => {
  if (profession == INPUT_PROFESSION.owner) return '経営者'
  if (profession == INPUT_PROFESSION.companyExecutive) return '会社役員'
  if (profession == INPUT_PROFESSION.regularEmployee) return '正社員・公務員'
  if (profession == INPUT_PROFESSION.contractEmployee) return '契約社員'
  if (profession == INPUT_PROFESSION.partTimeJob) return 'アルバイト・パート・フリーター'
  if (profession == INPUT_PROFESSION.freelance) return 'フリーランス・自営業・個人事業主'
  if (profession == INPUT_PROFESSION.student) return '学生'
  if (profession == INPUT_PROFESSION.other) return 'その他・無職・年金受給者'
  if (profession == INPUT_PROFESSION.housewife) return '専業主婦・主夫'

  return ''
}

export const convertIndustry = (industry: number | null) => {
  if (industry == INPUT_INDUSTRY.average) {
    return '全業種平均'
  } else {
    return _find(INPUT_INDUSTRY_BUTTON_ITEMS, (item) => item.value == industry)?.label
  }
}

export const convertEmployeeSize = (employeeSize: number | null) => {
  if (employeeSize == INPUT_EMPLOYEE_SIZE.average) {
    return '全規模平均'
  } else {
    return _find(INPUT_EMPLOYEE_SIZE_BUTTON_ITEMS, (item) => item.value == employeeSize)
      ?.label
  }
}

export const convertInsurancePensionType = (insurancePensionType: string | null) => {
  return _find(
    INPUT_INSURANCE_PENSION_TYPE_BUTTON_ITEMS,
    (item) => item.value == insurancePensionType,
  )?.label
}

export const convertPersonalBusinessTaxRate = (taxRate: number | null | undefined) => {
  const label = _find(
    INPUT_PERSONAL_BUSINESS_TAX_RATE_SELECT_ITEMS,
    (item) => item.value == taxRate,
  )?.label

  return defaultToEmptyString(label)
}

export const convertPersonalBusinessAoiroDeduction = (deduction: number | null | undefined) => {
  const label = _find(
    INPUT_PERSONAL_BUSINESS_AOIRO_DEDUCTION_SELECT_ITEMS,
    (item) => item.value == deduction,
  )?.label

  return defaultToEmptyString(label)
}

export const isFreelancer = (profession: number | null) => {
  if (_isNull(profession)) return false
  return profession == INPUT_PROFESSION.freelance
}

export const isNoBonusProfession = (profession: number | null) => {
  if (_isNull(profession)) return false
  return (
    profession == INPUT_PROFESSION.student || profession == INPUT_PROFESSION.freelance
  )
}

export const isNoSalaryProfession = (profession: number | null) => {
  if (_isNull(profession)) return false
  return (
    profession == INPUT_PROFESSION.student ||
    profession == INPUT_PROFESSION.other ||
    profession == INPUT_PROFESSION.housewife
  )
}

export const validateInsurancePensionType = (values: InputSalaryCommonValues) => {
  const { insurance_deduction_type, deducted_insurance_ids } = values

  if (!isNotEmptyValue(insurance_deduction_type)) {
    return undefined
  }

  if (
    Number(insurance_deduction_type) == API_FLAG.on &&
    (_isUndefined(deducted_insurance_ids) || _isEmpty(deducted_insurance_ids))
  ) {
    return {
      insurance_pension_type: '控除される保険料を入力してください。',
    }
  }

  return undefined
}
