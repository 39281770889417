import React, { FC } from 'react'
import Typography from '@/components/v2/atoms/Typography'

const AbountLoanDeduction: FC = () => {
  return (
    <>
      <Typography className="pb-[10px] font-bold">住宅ローン控除について</Typography>
      <Typography>
        住宅ローン控除は、下記の１、２の両方ともに当てはまる方に適用されます。
        <br />
        １. 家の面積が５０平方メートル以上
        <br />
        ２. 新築もしくは、認定中古住宅
      </Typography>
    </>
  )
}

export default AbountLoanDeduction
