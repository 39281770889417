import React, { FC, useEffect, useMemo, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'react-final-form'
import { isUndefined as _isUndefined, find as _find, filter as _filter } from 'lodash'
import ChevronRightIcon from '@/assets/images/v2/chevron-right.svg'
import Button from '@/components/v2/atoms/Button'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import Grid from '@/components/v2/atoms/Grid'
import Typography from '@/components/v2/atoms/Typography'
import NumberField from '@/components/v2/molecules/NumberField'
import SelectBox from '@/components/v2/molecules/SelectBox'
import { AGE_SELECT_ITEMS } from '@/constants/formItem/commons'
import {
  PENSION_YEARS_SELECT_ITEMS,
  RECEIVING_PENSION_AGE_SELECT_ITEMS,
  RECEIVING_PENSION_TYPE_SELECT_ITEMS,
} from '@/constants/formItem/inputs/inputContributionPensions'
import {
  fetchInputContributionPensions,
  createInputContributionPension,
  updateInputContributionPension,
} from '@/containers/inputs/inputContributionPensionsSlice'
import { ContributionType } from '@/models/inputs/inputContributionPensionsModel'
import {
  RECEIVING_PENSION_TYPE,
  CONTRIBUTION_TYPE,
} from '@/models/inputs/inputContributionPensionsModelFunc'
import { RootState } from '@/store'
import {
  required,
  composeValidators,
  zeroOrMoreNumber,
  commaNumberMaxLength,
  limitRangeNumber,
  oneHundredLessThanNumber,
} from '@/utils/validate'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import Modal from '@/components/v2/atoms/Modal'
import WholeSubmitErrorMessage from '@/components/v2/molecules/WholeSubmitErrorMessage'
import LoadingOverlay from '@/components/v1/atoms/LoadingOverlay'
import NumberFieldDecimal from '@/components/v2/molecules/NumberFieldDecimal'
import { castNumberWithoutOperator } from '@/models/commonsModelFunc'
import clsx from 'clsx'
import SubmitErrorMessage from '@/components/v2/molecules/SubmitErrorMessage'
import { updateResetFlg } from '@/containers/futures/futureBasesSlice'

interface MyDataPersonContributionPensionEditFormProps {
  personFlg: number
  onClose: () => void
  onSubmit: () => void
}

const MyDataPersonContributionPensionEditForm: FC<MyDataPersonContributionPensionEditFormProps> = (
  props,
) => {
  const dispatch = useDispatch()
  const { showFlashMsg } = useFlashAlert()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const { inputContributionPensions, isLoading } = useSelector(
    (state: RootState) => state.inputContributionPensions,
  )

  const { personFlg } = props
  const targetContributionPension = useMemo(
    () =>
      _find(
        inputContributionPensions,
        (item) =>
          item.person_flg === personFlg &&
          item.contribution_type === CONTRIBUTION_TYPE.ideco,
      ),
    [inputContributionPensions],
  )

  useEffect(() => {
    if (inputContributionPensions.length == 0) dispatch(fetchInputContributionPensions())
  }, [])

  const handleSubmit = (values: any) => {
    const isEdit = !_isUndefined(values.input_contribution_pension.id)
    const newValues = {
      input_contribution_pension: {
        ...values.input_contribution_pension,
        person_flg: personFlg,
        contribution_type: CONTRIBUTION_TYPE.ideco as ContributionType,
      },
    }
    const { receiving_pension_type } = values.input_contribution_pension

    // 受取方式: 年金として分割して受け取るを選択
    if (receiving_pension_type === RECEIVING_PENSION_TYPE.pension) {
      newValues.input_contribution_pension.lump_sum_ratio_percent = null
      newValues.input_contribution_pension.lump_sum_receiving_at_age = null
    }

    // 受取方式: 一時金として一括で受け取るを選択
    if (receiving_pension_type === RECEIVING_PENSION_TYPE.lump) {
      newValues.input_contribution_pension.lump_sum_ratio_percent = null
      newValues.input_contribution_pension.pension_start_at_age = null
      newValues.input_contribution_pension.pension_years = null
    }

    return new Promise((resolve) =>
      isEdit
        ? dispatch(
            updateInputContributionPension(
              values.input_contribution_pension.id,
              newValues,
              () => {
                showFlashMsg(isEdit ? '更新しました' : '作成しました')
                dispatch(fetchInputContributionPensions())
                dispatch(updateResetFlg())
                props.onSubmit()
              },
              resolve,
            ),
          )
        : dispatch(
            createInputContributionPension(
              newValues,
              () => {
                showFlashMsg(isEdit ? '更新しました' : '作成しました')
                dispatch(fetchInputContributionPensions())
                dispatch(updateResetFlg())
                props.onSubmit()
              },
              resolve,
            ),
          ),
    )
  }

  return (
    <>
      <LoadingOverlay isLoading={isLoading} />
      <Form
        onSubmit={handleSubmit}
        initialValues={{ input_contribution_pension: targetContributionPension }}
        render={({ handleSubmit, errors, values, submitErrors, submitFailed }) => (
          <form onSubmit={handleSubmit}>
            <Grid className="gap-x-40 gap-y-16 grid-cols-1 md:grid-cols-2 pb-24 md:pb-40">
              <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                <Typography
                  className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                  isBold
                >
                  支払い期間
                </Typography>
                <div className="flex flex-auto justify-end items-baseline">
                  <SelectBox
                    name="input_contribution_pension.contribution_period_start_at_age"
                    widthClassName="max-w-[100px]"
                    options={
                      _filter(AGE_SELECT_ITEMS, (item: any) => item.value > 18) as any
                    }
                    validate={required}
                  />
                  <Typography className="px-8 pr-18 text-14 text-black-800" isBold>
                    歳から
                  </Typography>
                  <SelectBox
                    name="input_contribution_pension.contribution_period_end_at_age"
                    widthClassName="max-w-[100px]"
                    options={
                      _filter(
                        AGE_SELECT_ITEMS,
                        (item: any) =>
                          item.value >
                          (values?.input_contribution_pension
                            ?.contribution_period_start_at_age || 18),
                      ) as any
                    }
                    validate={(value: any) => {
                      if (
                        value <
                        values?.input_contribution_pension
                          ?.contribution_period_start_at_age
                      )
                        return '開始年齢より後の年齢をご入力ください'
                      return required(value)
                    }}
                  />
                  <Typography className="pl-8 text-14 text-black-800" isBold>
                    歳未満
                  </Typography>
                </div>
              </div>

              <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                <Typography
                  className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                  isBold
                >
                  拠出額
                </Typography>
                <div className="flex flex-auto items-baseline">
                  <NumberField
                    name="input_contribution_pension.contribution_amount_monthly"
                    isFullWidth
                    validate={composeValidators(
                      required,
                      zeroOrMoreNumber,
                      (value: any) => commaNumberMaxLength(value, 6),
                    )}
                  />
                  <Typography
                    className="pl-8 text-14 text-black-800 min-w-[fit-content]"
                    isBold
                  >
                    円/月
                  </Typography>
                </div>
              </div>

              <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                <Typography
                  className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                  isBold
                >
                  評価額
                </Typography>
                <div className="flex flex-auto items-baseline">
                  <NumberField
                    name="input_contribution_pension.valuation"
                    isFullWidth
                    validate={composeValidators(
                      required,
                      zeroOrMoreNumber,
                      (value: any) => commaNumberMaxLength(value, 10),
                    )}
                  />
                  <Typography className="pl-8 text-14 text-black-800" isBold>
                    円
                  </Typography>
                </div>
              </div>

              <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                <Typography
                  className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                  isBold
                >
                  平均利回り
                </Typography>
                <div className="flex flex-auto justify-end items-baseline">
                  <NumberFieldDecimal
                    name="input_contribution_pension.average_yield_percent"
                    widthClassName="max-w-[100px]"
                    validate={composeValidators(required, (value: any) =>
                      limitRangeNumber(castNumberWithoutOperator(value), -100, 100),
                    )}
                  />
                  <Typography className="pl-8 text-14 text-black-800" isBold>
                    %
                  </Typography>
                </div>
              </div>

              <div className="pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                <div className="md:flex items-center">
                  <Typography
                    className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                    isBold
                  >
                    受取方式
                  </Typography>
                  <SelectBox
                    name="input_contribution_pension.receiving_pension_type"
                    placeholder="選択する"
                    options={RECEIVING_PENSION_TYPE_SELECT_ITEMS}
                    isFullWidth
                    validate={required}
                  />
                </div>

                <div
                  className="flex items-baseline cursor-pointer justify-end"
                  onClick={() => setIsModalOpen(true)}
                >
                  <Typography
                    className="min-w-[140px] lg:min-w-[164px] md:pb-0 text-[12px] text-black-800 leading-45"
                    isBold
                  >
                    受取方式ごとのメリットを確認する
                  </Typography>
                </div>
              </div>

              {isModalOpen && (
                <Modal
                  isOpen={isModalOpen}
                  onClose={() => setIsModalOpen(false)}
                  className="w-[95%] md:w-auto"
                >
                  <Typography
                    className="text-14 md:text-[20px] mb-[10px] text-gray-900"
                    isBold
                  >
                    受取方式ごとのメリットについて
                  </Typography>

                  <div className="py-10">
                    <Typography className="text-[12px] md:text-[14px] text-gray-700 pb-5">
                      分割で受け取る場合・・・公的年金等控除が受けられます。他の公的年金と併せて公的年金等控除が適用されるため、所得税を支払っていて、実際よりも多く源泉徴収されている場合は、確定申告をすることにより環付を受けることができます。
                    </Typography>
                  </div>

                  <div className="py-10">
                    <Typography className="text-[12px] md:text-[14px] text-gray-700 pb-5">
                      一括で受け取る場合・・・退職所得控除が受けられます。確定拠出年金は退職一時金として扱われるため、勤続年数に応じた控除を受けることができます。
                    </Typography>
                  </div>
                </Modal>
              )}

              {values?.input_contribution_pension?.receiving_pension_type ===
                RECEIVING_PENSION_TYPE.both && (
                <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                  <Typography
                    className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                    isBold
                  >
                    一時金の受取割合
                  </Typography>
                  <div className="flex flex-auto justify-end items-baseline">
                    <NumberFieldDecimal
                      name="input_contribution_pension.lump_sum_ratio_percent"
                      widthClassName="max-w-[100px]"
                      validate={composeValidators(
                        required,
                        zeroOrMoreNumber,
                        oneHundredLessThanNumber,
                      )}
                    />
                    <Typography className="pl-8 text-14 text-black-800" isBold>
                      %
                    </Typography>
                  </div>
                </div>
              )}

              {(values?.input_contribution_pension?.receiving_pension_type ===
                RECEIVING_PENSION_TYPE.both ||
                values?.input_contribution_pension?.receiving_pension_type ===
                  RECEIVING_PENSION_TYPE.lump) && (
                <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                  <Typography
                    className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                    isBold
                  >
                    一時金の受取年齢
                  </Typography>
                  <div className="flex flex-auto justify-end items-baseline">
                    <SelectBox
                      name="input_contribution_pension.lump_sum_receiving_at_age"
                      widthClassName="max-w-[100px]"
                      options={RECEIVING_PENSION_AGE_SELECT_ITEMS}
                      validate={required}
                    />

                    <Typography className="pl-8 text-14 text-black-800" isBold>
                      歳のとき
                    </Typography>
                  </div>
                </div>
              )}
              {(values?.input_contribution_pension?.receiving_pension_type ===
                RECEIVING_PENSION_TYPE.pension ||
                values?.input_contribution_pension?.receiving_pension_type ===
                  RECEIVING_PENSION_TYPE.both) && (
                <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                  <Typography
                    className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                    isBold
                  >
                    受取開始年齢
                  </Typography>
                  <div className="flex flex-auto justify-end items-baseline">
                    <SelectBox
                      name="input_contribution_pension.pension_start_at_age"
                      widthClassName="max-w-[100px]"
                      options={RECEIVING_PENSION_AGE_SELECT_ITEMS}
                      validate={required}
                    />
                    <Typography className="px-8 pr-18 text-14 text-black-800" isBold>
                      歳から
                    </Typography>
                    <SelectBox
                      name="input_contribution_pension.pension_years"
                      widthClassName="max-w-[100px]"
                      options={PENSION_YEARS_SELECT_ITEMS}
                      validate={required}
                    />
                  </div>
                </div>
              )}
            </Grid>

            <WholeSubmitErrorMessage submitErrors={submitErrors} />
            <SubmitErrorMessage show={!submitErrors && submitFailed} />
            <ButtonPrimary
              className={clsx(
                { ['opacity-50']: Object.keys(errors).length > 0 },
                'w-[246px] h-[64px] mx-auto mb-[16px] ',
              )}
              isDisabled={isLoading}
              onClick={() => handleSubmit(values)}
            >
              <Typography
                className="relative bottom-[2px] pl-[30px] text-[16px] text-white flex-auto"
                isBold
              >
                保存する
              </Typography>
              <ChevronRightIcon className="mr-[17px]" />
            </ButtonPrimary>
            <Button
              className="mx-auto text-[14px] text-black-700"
              onClick={props.onClose}
              isDisabled={isLoading}
            >
              キャンセル
            </Button>
          </form>
        )}
      />
    </>
  )
}

export default MyDataPersonContributionPensionEditForm
