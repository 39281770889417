import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { find as _find, isUndefined as _isUndefined, isNull as _isNull } from 'lodash'
import Typography from '@/components/v2/atoms/Typography'
import {
  convertRateSettingType,
  CURRENT_SALARY_INCREASE_RATE_SETTING_TYPE,
} from '@/models/commonIncomeBasesModelFunc'
import {
  CHANGE_TYPE,
  convertChangeType,
  renderIncomeItemLabel,
  convertReEmploymentType,
} from '@/models/commonIncomeChangesModelFunc'
import {
  defaultToEmptyString,
  convertYenToManyen,
  convertLocaleString,
  API_FLAG,
} from '@/models/commonsModelFunc'
import { PERSON_FLG } from '@/models/familyStructuresModelFunc'
import { RootState } from '@/store'
import SimulationAccordion from '@/templates/v2/simulations/_simulationAccordion'
import Modal from '@/components/v2/atoms/Modal'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import MyDataIncomeEditForm from '@/templates/v2/myData/incomes/Form'
import Button from '@/components/v2/atoms/Button'
import SimulationNewButton from '@/templates/v2/simulations/_simulationNewButton'

interface InputIncomeBodyProps {
  /** true: ご本人, false: 配偶者 */
  personFlg: boolean
  /** 更新時の処理 */
  onUpdate: () => void
}

export const IncomeTable: FC<any> = ({ isPerson }) => {
  const { inputSalaries } = useSelector((state: RootState) => state.inputSalaries)
  const { inputIncomeBases } = useSelector((state: RootState) => state.inputIncomes)
  const { companySalaryBase } = useSelector(
    (state: RootState) => state.companySalaryBases,
  )

  const isCompanySalary = !_isNull(companySalaryBase)
  const personFlg = isPerson ? PERSON_FLG.person : PERSON_FLG.spouse
  const targetInputIncome = _find(
    inputIncomeBases,
    (item) => item.person_flg === personFlg,
  )
  const targetInputSalary = _find(inputSalaries, (item) => item.person_flg === personFlg)
  const retirementAgeItem = _find(
    targetInputIncome?.input_income_changes,
    (item) => item.change_type === CHANGE_TYPE.retirementAge,
  )

  return (
    <>
      {!_isUndefined(targetInputIncome) ? (
        <table className="w-[100%] text-[12px] md:text-[14px]">
          <tbody>
            {!isCompanySalary && (
              <>
                <tr className="h-[45px] odd:bg-black-100">
                  <td className="w-[30%] px-[16px]">
                    <Typography isBold>
                      上昇率
                      <br className="md:hidden" />
                      タイプ
                    </Typography>
                  </td>
                  <td className="w-[70%] px-[16px]">
                    <Typography>
                      {convertRateSettingType(
                        targetInputIncome?.current_salary_increase_rate_setting_type,
                      )}
                    </Typography>
                  </td>
                </tr>
                {targetInputIncome?.current_salary_increase_rate_setting_type ===
                  CURRENT_SALARY_INCREASE_RATE_SETTING_TYPE.input && (
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="px-[16px]">
                      <Typography isBold>収入の上昇率</Typography>
                    </td>
                    <td className="px-[16px]">
                      <Typography>{`${defaultToEmptyString(
                        targetInputIncome?.current_salary_increase_rate_percent,
                      )}%`}</Typography>
                    </td>
                  </tr>
                )}
              </>
            )}

            {isCompanySalary && (
              <>
                {targetInputSalary?.input_salary_company_base?.input_salary_company_changes?.map(
                  (item) => (
                    <tr key={item.id} className="h-[45px] odd:bg-black-100">
                      <td className="w-[30%] px-[16px]">
                        <Typography isBold>{`${defaultToEmptyString(
                          item.scheduled_at_age,
                        )}歳`}</Typography>
                      </td>
                      <td className="w-[70%] px-[16px]">
                        <Typography>
                          {`${
                            item.is_managerial_retired
                              ? '役職定年'
                              : item.company_salary_grade_name
                          } 年収${convertYenToManyen(item.initial_salary_yearly)}万円`}
                        </Typography>
                      </td>
                    </tr>
                  ),
                )}
              </>
            )}

            {targetInputIncome?.input_income_changes?.map((item) => (
              <tr key={item.id} className="h-[45px] odd:bg-black-100">
                <td className="w-[30%] px-[16px]">
                  <Typography isBold>{`${defaultToEmptyString(
                    item.scheduled_at_age,
                  )}歳`}</Typography>
                </td>
                <td className="w-[70%] px-[16px]">
                  <Typography>
                    {`${convertChangeType(item.change_type)} ${renderIncomeItemLabel(
                      item,
                    )}`}
                  </Typography>
                </td>
              </tr>
            ))}

            <tr className="h-[45px] odd:bg-black-100">
              <td className="px-[16px]">
                <Typography isBold>再就職</Typography>
              </td>
              <td className="px-[16px]">
                <Typography>
                  {convertReEmploymentType(retirementAgeItem?.re_employment_type)}
                </Typography>
              </td>
            </tr>
            {retirementAgeItem?.re_employment_type === API_FLAG.on && (
              <>
                <tr className="h-[45px] odd:bg-black-100">
                  <td className="px-[16px]">
                    <Typography isBold>再就職後の給与</Typography>
                  </td>
                  <td className="px-[16px]">
                    <Typography>
                      {`${convertLocaleString(
                        retirementAgeItem?.re_employment_annual_income_manyen,
                      )}万円/年`}
                    </Typography>
                  </td>
                </tr>
                <tr className="h-[45px] odd:bg-black-100">
                  <td className="px-[16px]">
                    <Typography isBold>再就職後の退職年齢</Typography>
                  </td>
                  <td className="px-[16px]">
                    <Typography>
                      {`${defaultToEmptyString(
                        retirementAgeItem?.re_employment_finish_at_age,
                      )}歳`}
                    </Typography>
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      ) : (
        <Typography className="text-[14px] md:text-[16px] text-center" isBold>
          {`${isPerson ? 'ご本人' : '配偶者'}の収入の変化が登録されていません。`}
        </Typography>
      )}
    </>
  )
}

const InputIncomeBody: FC<InputIncomeBodyProps> = (props) => {
  const { personFlg, onUpdate } = props
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const closeModal = () => setIsOpenModal(false)
  const openModal = () => setIsOpenModal(true)

  const { inputIncomeBases } = useSelector((state: RootState) => state.inputIncomes)
  const inputedIncomeBase =
    inputIncomeBases.find((ib) => Number(!personFlg) === ib.person_flg) != null

  return (
    <>
      <SimulationAccordion
        className="pb-[16px]"
        title={`${personFlg ? 'ご本人' : '配偶者'}の収入の変化`}
      >
        {inputedIncomeBase && (
          <div className="flex justify-end mb-[8px]">
            <Button onClick={openModal}>
              <i className="ico edit text-[14px] text-black-700" />
              <Typography className="pl-[5px] text-[14px] text-black-700">
                編集
              </Typography>
            </Button>
          </div>
        )}
        <IncomeTable isPerson={personFlg} />
        {!inputedIncomeBase && <SimulationNewButton onClick={openModal} />}
      </SimulationAccordion>

      <Modal className="lg:w-[1024px] w-[95%]" isOpen={isOpenModal} onClose={closeModal}>
        <CircleTitle
          className="pb-[32px]"
          title={`${personFlg ? 'ご本人' : '配偶者'}の収入の変化`}
        />
        <MyDataIncomeEditForm
          personFlg={personFlg ? PERSON_FLG.person : PERSON_FLG.spouse}
          onClose={closeModal}
          onSubmit={() => {
            closeModal()
            onUpdate()
          }}
        />
      </Modal>
    </>
  )
}

export default InputIncomeBody
