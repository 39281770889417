import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { sortBy as _sortBy } from 'lodash'
import {
  postFutureLendHousingV2,
  patchFutureLendHousingV2,
  deleteFutureLendHousingV2,
} from '@/api/futures/futureLendHousings'
import { unauthorizedCheck } from '@/containers/accountsSlice'
import {
  THIS_YEAR,
  compareDiffMomentDate,
  convertMomentDate,
  castNumberWithoutOperator,
} from '@/models/commonsModelFunc'
import { FutureLendHousing } from '@/models/futures/futureLendHousingsModel'
import {
  MANAGEMENT_FEE_TYPE,
  PROPERTY_TYPE,
} from '@/models/futures/futureLendHousingsModelFunc'
import { AppThunk } from '@/store'

interface FutureLendHousingState {
  futureLendHousings: FutureLendHousing[]
  isLoading: boolean
}

const initialState: FutureLendHousingState = {
  futureLendHousings: [],
  isLoading: false,
}

const futureLendHousingsSlice = createSlice({
  name: 'futureLendHousings',
  initialState,
  reducers: {
    setFutureLendHousings: (state, action: PayloadAction<FutureLendHousing[]>) => {
      const sortedFutureLendHousings = _sortBy(action.payload, 'id')

      state.futureLendHousings = sortedFutureLendHousings.map((item) => ({
        ...item,
        lend_start_year: convertMomentDate(item.lend_start_at, 'YYYY'),
        management_fee_money:
          item.management_fee_type === MANAGEMENT_FEE_TYPE.percent
            ? null
            : item.property_type === PROPERTY_TYPE.division
            ? Math.round(castNumberWithoutOperator(item.management_fee_money) / 12)
            : item.management_fee_money,
        future_lend_housing_declines: item.future_lend_housing_declines.map(
          (item: any) => ({
            ...item,
            affected_number: compareDiffMomentDate(item.affected_at, THIS_YEAR, 'years'),
          }),
        ),
      }))

      return state
    },
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
  },
})

export const {
  setFutureLendHousings,
  requestStart,
  requestSuccess,
  requestFailure,
} = futureLendHousingsSlice.actions
export const futureLendHousingsReducer = futureLendHousingsSlice.reducer

export const createFutureLendHousingV2 = (
  values: any,
  callback: (id: number) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postFutureLendHousingV2(values)
    .then((response: FutureLendHousing) => {
      dispatch(requestSuccess())
      callback(response.id)
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const updateFutureLendHousingV2 = (
  values: any,
  id: number,
  callback?: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchFutureLendHousingV2(values, id)
    .then(() => {
      dispatch(requestSuccess())
      callback && callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const destroyFutureLendHousingV2 = (
  id: number,
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  deleteFutureLendHousingV2(id)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}
