import React, { FC, useMemo, useState, useContext, useEffect } from 'react'
import clsx from 'clsx'
import Typography from '@/components/v2/atoms/Typography'
import SimulationAccordion from '@/templates/v2/simulations/_simulationAccordion'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/store'
import {
  THIS_YEAR_NUMBER,
  convertLocaleString,
  defaultToEmptyString,
  extractYearOrMonthFromDate,
} from '@/models/commonsModelFunc'
import Button from '@/components/v2/atoms/Button'
import SimulationNewButton from './_simulationNewButton'
import { filter as _filter, find as _find } from 'lodash'
import {
  CHANGE_TYPE,
  convertChangeType,
  renderIncomeItemLabel,
} from '@/models/commonIncomeChangesModelFunc'
import {
  convertRateSettingType,
  CURRENT_SALARY_INCREASE_RATE_SETTING_TYPE,
} from '@/models/commonIncomeBasesModelFunc'
import FutureIncomeModalForm from '@/templates/v2/simulations/_futureIncomeModalForm'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import { fetchAccountFutureItems } from '@/containers/accountsSlice'
import { destroyFutureIncomeV2 } from '@/containers/futures/futureIncomesSlice'
import { SimulationDetailEditContext } from '@/models/futures/futureBasesModelFunc'
import SimulationItemAccordion from '@/templates/v2/simulations/_simulationItemAccordion'
import { isPerson } from '@/models/familyStructuresModelFunc'
import { convertInsurancePensionType } from '@/models/inputs/inputSalariesModelFunc'

interface FutureIncomeFormProps {
  /** タイトル */
  title: string
  /** 本人フラグ */
  personFlg: number
  /** 選択シミュレーションID */
  selectedSimulationId: number | undefined
  /** 選択中の将来結婚ID */
  selectedMarriageId?: number | undefined
  /** 選択メソッド */
  handleSelect: (item: any) => void
  /* FutureItemsを参照するID */
  futureBaseId?: number
  /* 初期化すべきかどうか */
  shouldInitialize?: boolean
  /* 表示するかどうか */
  isDisabled?: boolean
  /* 編集を確認したか */
  confirmEdit?: boolean
  /* 編集確認モーダルを展開するメソッド */
  openEditConfirmModal?: () => void
}

interface FutureIncomeTableProps {
  /** アイテム */
  item: any
  /** td要素のクラス */
  tdClassName?: string
}

export const FutureIncomeTable: FC<FutureIncomeTableProps> = ({ item }) => {
  return (
    <table
      key={item.id}
      className={clsx('w-[100%] mb-[20px] last:mb-0 text-[12px] md:text-[14px] bg-white')}
    >
      <tbody>
        <tr className="h-[45px] odd:bg-black-100">
          <td className="w-[30%] px-[16px]">
            <Typography isBold>現在のお仕事</Typography>
          </td>
          <td className="px-[16px]">
            <div className="p-10" >
              <Typography className="pb-[5px] text-[11px]">
                { `収入上昇率 `}
                {
                  item.current_salary_increase_rate_setting_type == CURRENT_SALARY_INCREASE_RATE_SETTING_TYPE.input ?
                    `${defaultToEmptyString(item.current_salary_increase_rate_percent )}%` :
                    `${convertRateSettingType(item.current_salary_increase_rate_setting_type )}`
                }
              </Typography>
              { Number(item.severance_pay_manyen) > 0 && (
                <Typography className="pb-[5px] text-[11px]">
                  {`退職金 ${item.severance_pay_manyen}万円`}
                </Typography>
              )}
            </div>
          </td>
        </tr>

        {item.future_income_changes?.map((change: any) => (
          <React.Fragment key={change.id}>
            <tr className="h-[45px] odd:bg-black-100">
              <td className="px-[16px]">
                <Typography isBold>
                  {`${defaultToEmptyString( change.scheduled_at_age,)}歳の時`}
                </Typography>
              </td>
              <td className="px-[16px]">
                {Number(change.change_type) === CHANGE_TYPE.employment && (
                  <div className="p-10" >
                    <Typography className="pb-[5px] text-[11px]">
                      {convertChangeType(change.change_type)}
                    </Typography>
                    <Typography className="pb-[5px] text-[11px]">
                      {`変更後の年収 ${convertLocaleString(
                        Number(change.change_type) === CHANGE_TYPE.employment
                          ? change.estimated_annual_income_manyen
                          : change.retirement_officers_amount_manyen,
                      )}万円`}
                    </Typography>
                    <Typography className="pb-[5px] text-[11px]">
                      {`変更後の社会保険 ${ convertInsurancePensionType( change.insurance_pension_type || null )}`}
                    </Typography>
                    <Typography className="pb-[5px] text-[11px]">
                      {`変更後の収入上昇率 `}
                      {
                        change.current_salary_increase_rate_setting_type == CURRENT_SALARY_INCREASE_RATE_SETTING_TYPE.input ?
                          `${defaultToEmptyString(change.current_salary_increase_rate_percent )}%` :
                          `${convertRateSettingType(change.current_salary_increase_rate_setting_type )}`
                      }
                    </Typography>
                    { Number(change.severance_pay_manyen) > 0 && (
                      <Typography className="pb-[5px] text-[11px]">
                        {`退職金 ${change.severance_pay_manyen}万円`}
                      </Typography>
                    )}
                  </div>
                )}

                {Number(change.change_type) === CHANGE_TYPE.retirementOfficer && (
                  <div className="p-10" >
                    <Typography className="pb-[5px] text-[11px]">
                      {convertChangeType(change.change_type)}
                    </Typography>
                    <Typography className="pb-[5px] text-[11px]">
                      {`変更後の年収　${convertLocaleString(
                        Number(change.change_type) === CHANGE_TYPE.employment
                          ? change.estimated_annual_income_manyen
                          : change.retirement_officers_amount_manyen,
                      )}万円`}
                    </Typography>
                    <Typography className="pb-[5px] text-[11px]">
                      {`変更後の収入上昇率 `}
                      {
                        change.current_salary_increase_rate_setting_type == CURRENT_SALARY_INCREASE_RATE_SETTING_TYPE.input ?
                          `${defaultToEmptyString(change.current_salary_increase_rate_percent )}%` :
                          `${convertRateSettingType(change.current_salary_increase_rate_setting_type )}`
                      }
                    </Typography>
                    { Number(change.severance_pay_manyen) > 0 && (
                      <Typography className="pb-[5px] text-[11px]">
                        {`退職金 ${change.severance_pay_manyen}万円`}
                      </Typography>
                    )}
                  </div>
                )}

                {Number(change.change_type) === CHANGE_TYPE.retirement && (
                  <div className="p-10" >
                    <Typography className="pb-[5px] text-[11px]">
                      {convertChangeType(change.change_type)}
                    </Typography>
                    <Typography className="pb-[5px] text-[11px]">
                      {`変更後の社会保険 ${ convertInsurancePensionType( change.insurance_pension_type || null )}`}
                    </Typography>
                  </div>
                )}

                {Number(change.change_type) === CHANGE_TYPE.retirementAge && (
                  <div className="p-10" >
                    <Typography className="pb-[5px] text-[11px]">
                      {convertChangeType(change.change_type)}
                    </Typography>
                    { change.re_employment_type == 0 ? (
                      <>
                        <Typography className="pb-[5px] text-[11px]">
                          再就職 する
                        </Typography>
                        <Typography className="pb-[5px] text-[11px]">
                          再就職後の年収 {`${change.re_employment_annual_income_manyen} 万円`}
                        </Typography>
                      </>
                    ) : (
                      <Typography className="pb-[5px] text-[11px]">
                        再就職 しない
                      </Typography>
                    )}
                  </div>
                )}
              </td>
            </tr>

            {change.change_type == 2 && change.re_employment_type == 0 && (
              <>
                <tr className="h-[45px] odd:bg-black-100">
                  <td className="px-[16px]">
                    <Typography isBold>
                      {`${defaultToEmptyString( change.re_employment_finish_at_age,)}歳の時`}
                    </Typography>
                  </td>
                  <td className="px-[16px]">
                    <div className="p-10" >
                      <Typography className="pb-[5px] text-[11px]">
                        退職
                      </Typography>
                    </div>
                  </td>
                </tr>
              </>
            )}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  )
}

const FutureIncomeForm: FC<FutureIncomeFormProps> = (props) => {
  const dispatch = useDispatch()
  const { showFlashMsg } = useFlashAlert()
  const { isManipulatedApproval } = useContext(SimulationDetailEditContext)
  const {
    title,
    personFlg,
    selectedSimulationId,
    selectedMarriageId,
    handleSelect,
    isDisabled,
  } = props

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)

  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [incomeBaseId, setIncomeBaseId] = useState<number | null>(null)

  const futureIncomeBases = useSelector(
    (state: RootState) => state.accounts.futureItems?.future_income_bases,
  )
  const { familyInfoV2 } = useSelector((state: RootState) => state.familyStructures)

  const segmentedFutureIncomeBases = useMemo(() => {
    return _filter(futureIncomeBases, { person_flg: personFlg })
  }, [futureIncomeBases, personFlg])

  useEffect(() => {
    if (props.futureBaseId == null || !props.shouldInitialize) return
    const item = _find(segmentedFutureIncomeBases, { future_base_id: props.futureBaseId })
    if (item != null) handleSelect(item)
  }, [props.shouldInitialize])

  const sortedSegmentedFutureIncomeBases = useMemo(() => {
    if (props.futureBaseId == null) return segmentedFutureIncomeBases
    const eqFbId = (item: any) => item.future_base_id === props.futureBaseId
    return [
      ...segmentedFutureIncomeBases.filter((item) => eqFbId(item)),
      ...segmentedFutureIncomeBases.filter((item) => !eqFbId(item)),
    ]
  }, [segmentedFutureIncomeBases])

  const titleIndex = (index: number) => {
    if (props.futureBaseId == null || props.confirmEdit == null) return index + 1
    const eqFbId = (item: any) => item.future_base_id === props.futureBaseId
    return index + 1 - segmentedFutureIncomeBases.filter((item) => eqFbId(item)).length
  }

  const age = isPerson(personFlg)
    ? THIS_YEAR_NUMBER - extractYearOrMonthFromDate(familyInfoV2?.birth_day, 'year') || 0
    : THIS_YEAR_NUMBER -
        extractYearOrMonthFromDate(familyInfoV2?.spouse_birth_day, 'year') || 0

  const isExpiredSettings = (incomeChanges: any[]) => {
    return incomeChanges.some((ic) => {
      return ic.scheduled_at_age < age
    })
  }

  const handleExecIfConfired = (exec: (item: any) => void, item: any) => {
    props.confirmEdit === false ? props.openEditConfirmModal?.() : exec(item)
  }

  return (
    <SimulationAccordion
      className={'pb-[16px] ' + (isDisabled ? 'hidden' : '')}
      title={title}
      isSelected={!!selectedSimulationId}
      itemCount={sortedSegmentedFutureIncomeBases.length}
      isExpiredSettings={sortedSegmentedFutureIncomeBases.some(
        (item) =>
          item.id === selectedSimulationId &&
          isExpiredSettings(item.future_income_changes),
      )}
    >
      <div className="flex flex-col gap-y-[12px] pt-[6px]">
        {sortedSegmentedFutureIncomeBases?.map((item: any, index: number) => {
          const handleDestroyV2 = (item: any) => {
            const result = confirm('本当に削除してよろしいですか？')
            if (!result) return

            const destroyFunc = new Promise((resolve) => {
              dispatch(
                destroyFutureIncomeV2(item, item.id, () => {
                  resolve('')
                  showFlashMsg('削除しました')
                }),
              )
            })

            Promise.all([destroyFunc]).then(() => {
              if (item.id === selectedSimulationId) handleSelect(item)
              dispatch(fetchAccountFutureItems(props.futureBaseId))
            })
          }

          const handleEdit = (item: any) => {
            setIncomeBaseId(item.id)
            setIsEdit(true)
            setIsOpenModal(true)
          }

          return (
            <SimulationItemAccordion
              key={`future_income_base_${item.id}_${index}`}
              title={
                props.futureBaseId === item.future_base_id && props.confirmEdit != null
                  ? `登録中の${personFlg == 0 ? 'ご本人' : '配偶者'}の収入の変化`
                  : `${personFlg == 0 ? 'ご本人' : '配偶者'}の収入の変化${titleIndex(
                      index,
                    )}`
              }
              isSelected={item.id === selectedSimulationId}
              isExpiredSettings={isExpiredSettings(item.future_income_changes)}
              inputType="radio"
              onSelect={() => {
                handleExecIfConfired(handleSelect, item)
              }}
            >
              <div className="flex justify-end mb-[5px]">
                <Button
                  className="mr-[16px]"
                  isDisabled={!isManipulatedApproval}
                  onClick={(e) => {
                    handleExecIfConfired(handleEdit, item)
                    e.stopPropagation()
                  }}
                >
                  <i className="ico edit text-[14px] text-black-700" />
                  <Typography className="pl-[5px] text-[14px] text-black-700">
                    編集
                  </Typography>
                </Button>
                <Button
                  isDisabled={!isManipulatedApproval}
                  onClick={(e) => {
                    handleExecIfConfired(handleDestroyV2, item)
                    e.stopPropagation()
                  }}
                >
                  <Typography className="pl-[5px] text-[14px] text-black-700">
                    削除
                  </Typography>
                </Button>
              </div>
              <FutureIncomeTable item={item} />
            </SimulationItemAccordion>
          )
        })}
      </div>

      <SimulationNewButton
        isDisabled={!isManipulatedApproval}
        onClick={() => {
          setIncomeBaseId(null)
          setIsEdit(false)
          setIsOpenModal(true)
        }}
      />

      {isOpenModal && (
        <FutureIncomeModalForm
          isOpen={isOpenModal}
          isEdit={isEdit}
          onClose={() => setIsOpenModal(false)}
          id={incomeBaseId}
          selectedMarriageId={selectedMarriageId}
          personFlg={personFlg}
          futureBaseId={props.futureBaseId}
        />
      )}
    </SimulationAccordion>
  )
}

export default FutureIncomeForm

