import React, { FC, useEffect, ChangeEvent, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import ChevronRightIcon from '@/assets/images/v2/chevron-right.svg'
import HelpCircleIcon from '@/assets/images/v2/help-circle.svg'
import Button from '@/components/v2/atoms/Button'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import Tooltip from '@/components/v2/atoms/Tooltip'
import Typography from '@/components/v2/atoms/Typography'
import NumberField from '@/components/v2/molecules/NumberField'
import SelectBox from '@/components/v2/molecules/SelectBox'
import {
  NORMAL_SCHOOL_TYPE_SELECT_ITEMS,
  EDUCATIONAL_POLICY_TYPE_SELECT_ITEMS,
  UNIVERSITY_SCHOOL_TYPE_SELECT_ITEMS,
} from '@/constants/formItem/inputs/inputPlanTuitions'
import { setPageMeta } from '@/containers/pageMetasSlice'
import {
  InputPlanTuitionCommonItem,
  InputPlanTuitionCreateValues,
  InputPlanTuitionUpdateValues,
} from '@/models/inputs/inputPlanTuitionsModel'
import {
  SCHOOL_AGE,
  TUITION_TEMPLATES,
  isChildbirthWithinRange,
  convertEducationalPolicyTypeKey,
  buildSendTuition,
  AGE_TYPE,
  SCHOOL_KEY,
  SCHOOL_YEAR,
  buildReceiveTuition,
  EDUCATIONAL_POLICY_TYPE,
  SCHOOL_TYPE,
  SCHOOL_TYPE_TEMPLATES,
} from '@/models/inputs/inputPlanTuitionsModelFunc'
import { RootState } from '@/store'
import {
  composeValidators,
  required,
  zeroOrMoreNumber,
  commaNumberMaxLength,
} from '@/utils/validate'
import { isUndefined as _isUndefined, isNull as _isNull, find as _find } from 'lodash'
import { extractYearOrMonthFromDate } from '@/models/commonsModelFunc'
import { calcAge } from '@/models/familyStructuresModelFunc'
import {
  createInputPlanTuition,
  updateInputPlanTuition,
} from '@/containers/inputs/inputPlanTuitionsSlice'
import { fetchFamilyStructureV2 } from '@/containers/familyStructuresSlice'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import WholeSubmitErrorMessage from '@/components/v2/molecules/WholeSubmitErrorMessage'
import clsx from 'clsx'
import SubmitErrorMessage from '@/components/v2/molecules/SubmitErrorMessage'
import { updateResetFlg } from '@/containers/futures/futureBasesSlice'
import LoadingOverlay from '@/components/v1/atoms/LoadingOverlay'

interface MyDataTuitionEditFormProps {
  id: string | undefined
  onClose: () => void
  onSubmit: () => void
}

const MyDataTuitionEditForm: FC<MyDataTuitionEditFormProps> = (props) => {
  const dispatch = useDispatch()
  const { showFlashMsg } = useFlashAlert()
  const { familyStructureChildren, isLoading } = useSelector(
    (state: RootState) => state.familyStructures,
  )

  const { id } = props

  const targetFamilyStructureChild = useMemo(
    () => _find(familyStructureChildren, { id: Number(id) }),
    [familyStructureChildren],
  )

  const isEdit = useMemo(
    () =>
      !!id &&
      targetFamilyStructureChild &&
      targetFamilyStructureChild.input_plan_tuitions.length > 0,
    [targetFamilyStructureChild],
  )

  const childbirth = useMemo(
    () => extractYearOrMonthFromDate(targetFamilyStructureChild?.birth_day, 'year'),
    [targetFamilyStructureChild],
  )

  useEffect(() => {
    if (familyStructureChildren.length == 0) dispatch(fetchFamilyStructureV2())
  }, [])

  const handleSubmit = (
    values: InputPlanTuitionCreateValues & InputPlanTuitionUpdateValues,
  ) => {
    const { input_plan_tuitions } = values
    const createInputPlanTuitionFunc = (
      familyStructureChildId: number,
      childIndex: number,
    ) => {
      return new Promise((resolve) => {
        const newValues: InputPlanTuitionCreateValues = {
          family_structure_child_id: familyStructureChildId,
          input_plan_tuitions_attributes: [],
        }
        const childAge = calcAge(
          _find(familyStructureChildren, {
            id: familyStructureChildId,
          })?.birth_day,
        )
        const targetChild = input_plan_tuitions?.[0]

        // 0〜3歳児保育
        newValues.input_plan_tuitions_attributes.push({
          age_type: AGE_TYPE.toddler,
          ...buildSendTuition(
            SCHOOL_KEY.toddler,
            SCHOOL_YEAR.toddler,
            childAge,
            !!id ? input_plan_tuitions?.[0] : input_plan_tuitions?.[childIndex],
            targetFamilyStructureChild?.is_early_born,
          ),
        })

        // 幼稚園・保育園
        newValues.input_plan_tuitions_attributes.push({
          age_type: AGE_TYPE.kindergarten,
          school_type:
            childAge <= SCHOOL_AGE.kindergarten3
              ? targetChild?.kindergarten_school_type
              : null,
          ...buildSendTuition(
            SCHOOL_KEY.kindergarten,
            SCHOOL_YEAR.kindergarten,
            childAge,
            !!id ? input_plan_tuitions?.[0] : input_plan_tuitions?.[childIndex],
          ),
        })

        // 小学校
        newValues.input_plan_tuitions_attributes.push({
          age_type: AGE_TYPE.primarySchool,
          school_type:
            childAge <= SCHOOL_AGE.primarySchool6
              ? targetChild?.primary_school_type
              : null,
          ...buildSendTuition(
            SCHOOL_KEY.primarySchool,
            SCHOOL_YEAR.primarySchool,
            childAge,
            !!id ? input_plan_tuitions?.[0] : input_plan_tuitions?.[childIndex],
          ),
        })

        // 中学校
        newValues.input_plan_tuitions_attributes.push({
          age_type: AGE_TYPE.juniorHighSchool,
          school_type:
            childAge <= SCHOOL_AGE.juniorHighSchool3
              ? targetChild?.junior_high_school_type
              : null,
          ...buildSendTuition(
            SCHOOL_KEY.juniorHighSchool,
            SCHOOL_YEAR.juniorHighSchool,
            childAge,
            !!id ? input_plan_tuitions?.[0] : input_plan_tuitions?.[childIndex],
          ),
        })

        // 高校
        newValues.input_plan_tuitions_attributes.push({
          age_type: AGE_TYPE.highSchool,
          school_type:
            childAge <= SCHOOL_AGE.highSchool3 ? targetChild?.high_school_type : null,
          ...buildSendTuition(
            SCHOOL_KEY.highSchool,
            SCHOOL_YEAR.highSchool,
            childAge,
            !!id ? input_plan_tuitions?.[0] : input_plan_tuitions?.[childIndex],
          ),
        })

        // 大学
        newValues.input_plan_tuitions_attributes.push({
          age_type: AGE_TYPE.university,
          school_type:
            childAge <= SCHOOL_AGE.university4
              ? targetChild?.university_school_type
              : null,
          ...buildSendTuition(
            SCHOOL_KEY.university,
            SCHOOL_YEAR.university,
            childAge,
            !!id ? input_plan_tuitions?.[0] : input_plan_tuitions?.[childIndex],
          ),
        })

        // 大学院
        newValues.input_plan_tuitions_attributes.push({
          age_type: AGE_TYPE.graduateSchool,
          ...buildSendTuition(
            SCHOOL_KEY.graduateSchool,
            SCHOOL_YEAR.graduateSchool,
            childAge,
            !!id ? input_plan_tuitions?.[0] : input_plan_tuitions?.[childIndex],
          ),
        })

        dispatch(
          createInputPlanTuition(newValues, resolve, () => {
            showFlashMsg(isEdit ? '更新しました' : '作成しました')
            dispatch(fetchFamilyStructureV2())
            dispatch(updateResetFlg())
            props.onSubmit()
          }),
        )
      })
    }

    const updateInputPlanTuitionFunc = (familyStructureChildId: number) => {
      return new Promise((resolve) => {
        const newValues: InputPlanTuitionUpdateValues = {
          family_structure_child_id: familyStructureChildId,
          input_plan_tuitions_attributes: [],
        }
        const childAge = calcAge(
          _find(familyStructureChildren, {
            id: familyStructureChildId,
          })?.birth_day,
        )
        const targetPlanTuitionArr = targetFamilyStructureChild?.input_plan_tuitions as any

        const targetPlanTuition = input_plan_tuitions?.[0]

        // 0〜3歳児保育
        targetPlanTuitionArr[0] &&
          newValues.input_plan_tuitions_attributes.push({
            id: targetPlanTuitionArr[0].id,
            age_type: AGE_TYPE.toddler,
            ...buildSendTuition(
              SCHOOL_KEY.toddler,
              SCHOOL_YEAR.toddler,
              childAge,
              input_plan_tuitions?.[0],
              targetFamilyStructureChild?.is_early_born,
            ),
          })

        // 幼稚園・保育園
        targetPlanTuitionArr[1] &&
          newValues.input_plan_tuitions_attributes.push({
            id: targetPlanTuitionArr[1].id,
            age_type: AGE_TYPE.kindergarten,
            school_type: targetPlanTuition?.kindergarten_school_type,
            ...buildSendTuition(
              SCHOOL_KEY.kindergarten,
              SCHOOL_YEAR.kindergarten,
              childAge,
              input_plan_tuitions?.[0],
            ),
          })

        // 小学校
        targetPlanTuitionArr[2] &&
          newValues.input_plan_tuitions_attributes.push({
            id: targetPlanTuitionArr[2].id,
            age_type: AGE_TYPE.primarySchool,
            school_type: targetPlanTuition?.primary_school_type,
            ...buildSendTuition(
              SCHOOL_KEY.primarySchool,
              SCHOOL_YEAR.primarySchool,
              childAge,
              input_plan_tuitions?.[0],
            ),
          })

        // 中学校
        targetPlanTuitionArr[3] &&
          newValues.input_plan_tuitions_attributes.push({
            id: targetPlanTuitionArr[3].id,
            age_type: AGE_TYPE.juniorHighSchool,
            school_type: targetPlanTuition?.junior_high_school_type,
            ...buildSendTuition(
              SCHOOL_KEY.juniorHighSchool,
              SCHOOL_YEAR.juniorHighSchool,
              childAge,
              input_plan_tuitions?.[0],
            ),
          })

        // 高校
        targetPlanTuitionArr[4] &&
          newValues.input_plan_tuitions_attributes.push({
            id: targetPlanTuitionArr[4].id,
            age_type: AGE_TYPE.highSchool,
            school_type: targetPlanTuition?.high_school_type,
            ...buildSendTuition(
              SCHOOL_KEY.highSchool,
              SCHOOL_YEAR.highSchool,
              childAge,
              input_plan_tuitions?.[0],
            ),
          })

        // 大学
        targetPlanTuitionArr[5] &&
          newValues.input_plan_tuitions_attributes.push({
            id: targetPlanTuitionArr[5].id,
            age_type: AGE_TYPE.university,
            school_type: targetPlanTuition?.university_school_type,
            ...buildSendTuition(
              SCHOOL_KEY.university,
              SCHOOL_YEAR.university,
              childAge,
              input_plan_tuitions?.[0],
            ),
          })

        // 大学院
        targetPlanTuitionArr[6] &&
          newValues.input_plan_tuitions_attributes.push({
            id: targetPlanTuitionArr[6].id,
            age_type: AGE_TYPE.graduateSchool,
            ...buildSendTuition(
              SCHOOL_KEY.graduateSchool,
              SCHOOL_YEAR.graduateSchool,
              childAge,
              input_plan_tuitions?.[0],
            ),
          })

        dispatch(
          updateInputPlanTuition(newValues, resolve, () => {
            showFlashMsg(isEdit ? '更新しました' : '作成しました')
            dispatch(fetchFamilyStructureV2())
            dispatch(updateResetFlg())
            props.onSubmit()
          }),
        )
      })
    }

    isEdit && !!targetFamilyStructureChild
      ? updateInputPlanTuitionFunc(Number(id)!)
      : !!targetFamilyStructureChild &&
        createInputPlanTuitionFunc(
          targetFamilyStructureChild.id,
          targetFamilyStructureChild.child_index,
        )
  }

  const builtInputPlanTuitions = useMemo(() => {
    return {
      input_plan_tuitions: !targetFamilyStructureChild
        ? []
        : [targetFamilyStructureChild].map((item: any) => ({
            child_id: item.id,
            ...buildReceiveTuition(
              SCHOOL_KEY.toddler,
              SCHOOL_YEAR.toddler,
              item.input_plan_tuitions[0],
            ),
            kindergarten_school_type:
              !_isUndefined(item.input_plan_tuitions[1]) &&
              !_isNull(item.input_plan_tuitions[1].school_type)
                ? item.input_plan_tuitions[1].school_type
                : 0,
            ...buildReceiveTuition(
              SCHOOL_KEY.kindergarten,
              SCHOOL_YEAR.kindergarten,
              item.input_plan_tuitions[1],
            ),
            primary_school_type:
              !_isUndefined(item.input_plan_tuitions[2]) &&
              !_isNull(item.input_plan_tuitions[2].school_type)
                ? item.input_plan_tuitions[2].school_type
                : 0,
            ...buildReceiveTuition(
              SCHOOL_KEY.primarySchool,
              SCHOOL_YEAR.primarySchool,
              item.input_plan_tuitions[2],
            ),
            junior_high_school_type:
              !_isUndefined(item.input_plan_tuitions[3]) &&
              !_isNull(item.input_plan_tuitions[3].school_type)
                ? item.input_plan_tuitions[3].school_type
                : 0,
            ...buildReceiveTuition(
              SCHOOL_KEY.juniorHighSchool,
              SCHOOL_YEAR.juniorHighSchool,
              item.input_plan_tuitions[3],
            ),
            high_school_type:
              !_isUndefined(item.input_plan_tuitions[4]) &&
              !_isNull(item.input_plan_tuitions[4].school_type)
                ? item.input_plan_tuitions[4].school_type
                : 0,
            ...buildReceiveTuition(
              SCHOOL_KEY.highSchool,
              SCHOOL_YEAR.highSchool,
              item.input_plan_tuitions[4],
            ),
            university_school_type:
              !_isUndefined(item.input_plan_tuitions[5]) &&
              !_isNull(item.input_plan_tuitions[5].school_type)
                ? item.input_plan_tuitions[5].school_type
                : 0,
            ...buildReceiveTuition(
              SCHOOL_KEY.university,
              SCHOOL_YEAR.university,
              item.input_plan_tuitions[5],
            ),
            ...buildReceiveTuition(
              SCHOOL_KEY.graduateSchool,
              SCHOOL_YEAR.graduateSchool,
              item.input_plan_tuitions[6],
            ),
          })),
      educational_policy_type: String(EDUCATIONAL_POLICY_TYPE.useCreatedValues),
    }
  }, [targetFamilyStructureChild])

  const handleChangePolicyType = (
    name: string,
    educationalPolicyType: number,
    change: (key: string, value: number) => void,
    values?: InputPlanTuitionCommonItem,
  ) => {
    const key = convertEducationalPolicyTypeKey(educationalPolicyType)

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.toddler0)) {
      change(`${name}toddler_tuition_1_manyen`, TUITION_TEMPLATES[key].firstToddler)
      change(
        `${name}off_toddler_tuition_1_manyen`,
        TUITION_TEMPLATES[key].firstOffToddler,
      )
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.toddler1)) {
      change(`${name}toddler_tuition_2_manyen`, TUITION_TEMPLATES[key].nextToddler)
      change(`${name}off_toddler_tuition_2_manyen`, TUITION_TEMPLATES[key].nextOffToddler)
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.toddler2)) {
      change(`${name}toddler_tuition_3_manyen`, TUITION_TEMPLATES[key].nextToddler)
      change(`${name}off_toddler_tuition_3_manyen`, TUITION_TEMPLATES[key].nextOffToddler)
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.toddler3)) {
      change(`${name}toddler_tuition_4_manyen`, TUITION_TEMPLATES[key].nextToddler)
      change(`${name}off_toddler_tuition_4_manyen`, TUITION_TEMPLATES[key].nextOffToddler)
    }

    changeKindergartenTuition(name, TUITION_TEMPLATES[key], change)
    changeKindergartenTuition(name, TUITION_TEMPLATES[key], change)
    changePrimarySchoolTuition(name, TUITION_TEMPLATES[key], change)
    changeJuniorHighSchoolTuition(name, TUITION_TEMPLATES[key], change)
    changeHighSchoolTuition(name, TUITION_TEMPLATES[key], change)
    changeUniversityTuition(name, TUITION_TEMPLATES[key], change)

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.graduateSchool1)) {
      change(
        `${name}graduate_school_tuition_1_manyen`,
        TUITION_TEMPLATES[key].firstGraduateSchool,
      )
      change(
        `${name}off_graduate_school_tuition_1_manyen`,
        TUITION_TEMPLATES[key].firstOffGraduateSchool,
      )
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.graduateSchool2)) {
      change(
        `${name}graduate_school_tuition_2_manyen`,
        TUITION_TEMPLATES[key].nextGraduateSchool,
      )
      change(
        `${name}off_graduate_school_tuition_2_manyen`,
        TUITION_TEMPLATES[key].nextOffGraduateSchool,
      )
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.kindergarten3)) {
      change(`${name}kindergarten_school_type`, SCHOOL_TYPE_TEMPLATES[key].kindergarten)
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.primarySchool6)) {
      change(`${name}primary_school_type`, SCHOOL_TYPE_TEMPLATES[key].primarySchool)
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.juniorHighSchool3)) {
      change(
        `${name}junior_high_school_type`,
        SCHOOL_TYPE_TEMPLATES[key].juniorHighSchool,
      )
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.highSchool3)) {
      change(`${name}high_school_type`, SCHOOL_TYPE_TEMPLATES[key].highSchool)
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.university4)) {
      change(`${name}university_school_type`, SCHOOL_TYPE_TEMPLATES[key].university)
    }
  }

  const handleChangeSchoolType = (
    name: string,
    schoolKey: string,
    value: number,
    change: (key: string, value: number) => void,
  ) => {
    const targetTuitionTemplates =
      value === SCHOOL_TYPE.public
        ? TUITION_TEMPLATES.notReallyHang
        : value === SCHOOL_TYPE.privateLiberalArts
        ? TUITION_TEMPLATES.averageHang
        : TUITION_TEMPLATES.thoroughlyHang

    switch (schoolKey) {
      case SCHOOL_KEY.kindergarten:
        changeKindergartenTuition(name, targetTuitionTemplates, change)
        break
      case SCHOOL_KEY.primarySchool:
        changePrimarySchoolTuition(name, targetTuitionTemplates, change)
        break
      case SCHOOL_KEY.juniorHighSchool:
        changeJuniorHighSchoolTuition(name, targetTuitionTemplates, change)
        break
      case SCHOOL_KEY.highSchool:
        changeHighSchoolTuition(name, targetTuitionTemplates, change)
        break
      case SCHOOL_KEY.university:
        changeUniversityTuition(name, targetTuitionTemplates, change)
        break
      default:
        break
    }
  }

  const changeKindergartenTuition = (
    name: string,
    tuitionTemplate: { [key: string]: number },
    change: (key: string, value: number) => void,
  ) => {
    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.kindergarten1)) {
      change(`${name}kindergarten_tuition_1_manyen`, tuitionTemplate.firstKindergarten)
      change(
        `${name}off_kindergarten_tuition_1_manyen`,
        tuitionTemplate.firstOffKindergarten,
      )
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.kindergarten2)) {
      change(`${name}kindergarten_tuition_2_manyen`, tuitionTemplate.nextKindergarten)
      change(
        `${name}off_kindergarten_tuition_2_manyen`,
        tuitionTemplate.nextOffKindergarten,
      )
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.kindergarten3)) {
      change(`${name}kindergarten_tuition_3_manyen`, tuitionTemplate.nextKindergarten)
      change(
        `${name}off_kindergarten_tuition_3_manyen`,
        tuitionTemplate.nextOffKindergarten,
      )
    }
  }

  const changePrimarySchoolTuition = (
    name: string,
    tuitionTemplate: { [key: string]: number },
    change: (key: string, value: number) => void,
  ) => {
    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.primarySchool1)) {
      change(`${name}primary_school_tuition_1_manyen`, tuitionTemplate.firstPrimarySchool)
      change(
        `${name}off_primary_school_tuition_1_manyen`,
        tuitionTemplate.firstOffPrimarySchool,
      )
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.primarySchool2)) {
      change(`${name}primary_school_tuition_2_manyen`, tuitionTemplate.nextPrimarySchool)
      change(
        `${name}off_primary_school_tuition_2_manyen`,
        tuitionTemplate.nextOffPrimarySchool,
      )
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.primarySchool3)) {
      change(`${name}primary_school_tuition_3_manyen`, tuitionTemplate.nextPrimarySchool)
      change(
        `${name}off_primary_school_tuition_3_manyen`,
        tuitionTemplate.nextOffPrimarySchool,
      )
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.primarySchool4)) {
      change(`${name}primary_school_tuition_4_manyen`, tuitionTemplate.nextPrimarySchool)
      change(
        `${name}off_primary_school_tuition_4_manyen`,
        tuitionTemplate.nextOffPrimarySchool,
      )
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.primarySchool5)) {
      change(`${name}primary_school_tuition_5_manyen`, tuitionTemplate.nextPrimarySchool)
      change(
        `${name}off_primary_school_tuition_5_manyen`,
        tuitionTemplate.nextOffPrimarySchool,
      )
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.primarySchool6)) {
      change(`${name}primary_school_tuition_6_manyen`, tuitionTemplate.nextPrimarySchool)
      change(
        `${name}off_primary_school_tuition_6_manyen`,
        tuitionTemplate.nextOffPrimarySchool,
      )
    }
  }

  const changeJuniorHighSchoolTuition = (
    name: string,
    tuitionTemplate: { [key: string]: number },
    change: (key: string, value: number) => void,
  ) => {
    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.juniorHighSchool1)) {
      change(
        `${name}junior_high_school_tuition_1_manyen`,
        tuitionTemplate.firstJuniorHighSchool,
      )
      change(
        `${name}off_junior_high_school_tuition_1_manyen`,
        tuitionTemplate.firstOffJuniorHighSchool,
      )
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.juniorHighSchool2)) {
      change(
        `${name}junior_high_school_tuition_2_manyen`,
        tuitionTemplate.nextJuniorHighSchool,
      )
      change(
        `${name}off_junior_high_school_tuition_2_manyen`,
        tuitionTemplate.nextOffJuniorHighSchool,
      )
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.juniorHighSchool3)) {
      change(
        `${name}junior_high_school_tuition_3_manyen`,
        tuitionTemplate.nextJuniorHighSchool,
      )
      change(
        `${name}off_junior_high_school_tuition_3_manyen`,
        tuitionTemplate.nextOffJuniorHighSchool,
      )
    }
  }

  const changeHighSchoolTuition = (
    name: string,
    tuitionTemplate: { [key: string]: number },
    change: (key: string, value: number) => void,
  ) => {
    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.highSchool1)) {
      change(`${name}high_school_tuition_1_manyen`, tuitionTemplate.firstHighSchool)
      change(
        `${name}off_high_school_tuition_1_manyen`,
        tuitionTemplate.firstOffHighSchool,
      )
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.highSchool2)) {
      change(`${name}high_school_tuition_2_manyen`, tuitionTemplate.nextHighSchool)
      change(`${name}off_high_school_tuition_2_manyen`, tuitionTemplate.nextOffHighSchool)
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.highSchool3)) {
      change(`${name}high_school_tuition_3_manyen`, tuitionTemplate.nextHighSchool)
      change(`${name}off_high_school_tuition_3_manyen`, tuitionTemplate.nextOffHighSchool)
    }
  }

  const changeUniversityTuition = (
    name: string,
    tuitionTemplate: { [key: string]: number },
    change: (key: string, value: number) => void,
  ) => {
    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.university1)) {
      change(`${name}university_tuition_1_manyen`, tuitionTemplate.firstUniversity)
      change(`${name}off_university_tuition_1_manyen`, tuitionTemplate.firstOffUniversity)
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.university2)) {
      change(`${name}university_tuition_2_manyen`, tuitionTemplate.nextUniversity)
      change(`${name}off_university_tuition_2_manyen`, tuitionTemplate.nextOffUniversity)
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.university3)) {
      change(`${name}university_tuition_3_manyen`, tuitionTemplate.nextUniversity)
      change(`${name}off_university_tuition_3_manyen`, tuitionTemplate.nextOffUniversity)
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.university4)) {
      change(`${name}university_tuition_4_manyen`, tuitionTemplate.nextUniversity)
      change(`${name}off_university_tuition_4_manyen`, tuitionTemplate.nextOffUniversity)
    }
  }

  const initialValues: any = useMemo(
    () =>
      isEdit
        ? { input_plan_tuitions: builtInputPlanTuitions.input_plan_tuitions }
        : { input_plan_tuitions: [{}] },
    [targetFamilyStructureChild, isEdit, builtInputPlanTuitions],
  )

  return (
    <>
      <LoadingOverlay isLoading={isLoading} />
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        mutators={{ ...arrayMutators }}
        render={({
          handleSubmit,
          values,
          errors,
          submitErrors,
          submitFailed,
          form: { change },
        }) => (
          <form onSubmit={handleSubmit}>
            <FieldArray name="input_plan_tuitions">
              {({ fields }) =>
                fields.map((name, index) => (
                  <div key={name} className="pb-[64px] last:pb-0">
                    <div className="md:flex md:w-6/12 pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                      <Typography
                        className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                        isBold
                      >
                        教育方針
                      </Typography>
                      <SelectBox
                        name={`educational_policy_type`}
                        placeholder="選択する"
                        options={EDUCATIONAL_POLICY_TYPE_SELECT_ITEMS}
                        isFullWidth
                        onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                          handleChangePolicyType(
                            name,
                            Number(event.target.value),
                            change,
                            fields.value[index],
                          )
                        }
                      />
                    </div>

                    <div className="md:flex justify-between items-center py-[20px] border-b border-black-300">
                      <div className="flex w-full md:w-[30%] md:justify-center">
                        <Typography className="pb-[10px] md:pb-0 text-[14px]">
                          {`0〜${
                            targetFamilyStructureChild?.is_early_born ? '2' : '3'
                          }歳児保育`}
                        </Typography>
                      </div>

                      <div className="flex w-full md:w-[20%] justify-center">
                        <Typography className="pb-[20px] md:pb-0 text-[14px]">
                          ー
                        </Typography>
                      </div>

                      <div className="md:flex items-center w-full md:w-[50%]">
                        <div className="flex items-center pb-[10px] md:pb-0 pr-[20px]">
                          <Typography className="pr-[5px] text-[14px] w-[max-content]">
                            学費+学外費
                          </Typography>
                          <Tooltip
                            item="※学外費とは、学習塾や家庭教師・習い事などの活動費のことを指します。"
                            itemClassName="w-[200px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                          >
                            <HelpCircleIcon />
                          </Tooltip>
                        </div>
                        <div className="w-full">
                          {isChildbirthWithinRange(childbirth, SCHOOL_AGE.toddler0) ? (
                            <div className="flex items-baseline pb-[10px] last:pb-0 justify-center">
                              <NumberField
                                name={`${name}toddler_tuition_1_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="px-[8px] text-[14px]">
                                万円
                              </Typography>
                              <NumberField
                                name={`${name}off_toddler_tuition_1_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="pl-[8px] text-[14px]">
                                万円
                              </Typography>
                            </div>
                          ) : (
                            <Typography className="pb-[10px] last:pb-0 text-[14px] text-center">
                              ー
                            </Typography>
                          )}
                          {isChildbirthWithinRange(childbirth, SCHOOL_AGE.toddler1) ? (
                            <div className="flex items-baseline pb-[10px] last:pb-0 justify-center">
                              <NumberField
                                name={`${name}toddler_tuition_2_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="px-[8px] text-[14px]">
                                万円
                              </Typography>
                              <NumberField
                                name={`${name}off_toddler_tuition_2_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="pl-[8px] text-[14px]">
                                万円
                              </Typography>
                            </div>
                          ) : (
                            <Typography className="pb-[10px] last:pb-0 text-[14px] text-center">
                              ー
                            </Typography>
                          )}
                          {isChildbirthWithinRange(childbirth!, SCHOOL_AGE.toddler2) ? (
                            <div className="flex items-baseline pb-[10px] last:pb-0 justify-center">
                              <NumberField
                                name={`${name}toddler_tuition_3_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="px-[8px] text-[14px]">
                                万円
                              </Typography>
                              <NumberField
                                name={`${name}off_toddler_tuition_3_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="pl-[8px] text-[14px]">
                                万円
                              </Typography>
                            </div>
                          ) : (
                            <Typography className="pb-[10px] last:pb-0 text-[14px] text-center justify-center">
                              ー
                            </Typography>
                          )}
                          {!targetFamilyStructureChild?.is_early_born && (
                            <>
                              {isChildbirthWithinRange(
                                childbirth!,
                                SCHOOL_AGE.toddler3,
                              ) ? (
                                <div className="flex items-baseline pb-[10px] last:pb-0 justify-center">
                                  <NumberField
                                    name={`${name}toddler_tuition_4_manyen`}
                                    widthClassName="max-w-[100px]"
                                    validate={composeValidators(
                                      required,
                                      zeroOrMoreNumber,
                                      (value: any) => commaNumberMaxLength(value, 6),
                                    )}
                                  />
                                  <Typography className="px-[8px] text-[14px]">
                                    万円
                                  </Typography>
                                  <NumberField
                                    name={`${name}off_toddler_tuition_4_manyen`}
                                    widthClassName="max-w-[100px]"
                                    validate={composeValidators(
                                      required,
                                      zeroOrMoreNumber,
                                      (value: any) => commaNumberMaxLength(value, 6),
                                    )}
                                  />
                                  <Typography className="pl-[8px] text-[14px]">
                                    万円
                                  </Typography>
                                </div>
                              ) : (
                                <Typography className="pb-[10px] last:pb-0 text-[14px] text-center">
                                  ー
                                </Typography>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="md:flex justify-between items-center py-[20px] border-b border-black-300">
                      <div className="flex w-full md:w-[30%] md:justify-center">
                        <Typography className="pb-[10px] md:pb-0 text-[14px]">
                          幼稚園・保育園
                        </Typography>
                      </div>

                      <div className="flex w-full md:w-[20%] justify-center">
                        {isChildbirthWithinRange(
                          childbirth!,
                          SCHOOL_AGE.kindergarten3,
                        ) ? (
                          <SelectBox
                            name={`${name}kindergarten_school_type`}
                            className="pb-[20px] md:pb-0"
                            placeholder="選択する"
                            options={NORMAL_SCHOOL_TYPE_SELECT_ITEMS}
                            validate={required}
                            onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                              handleChangeSchoolType(
                                name,
                                SCHOOL_KEY.kindergarten,
                                Number(event.target.value),
                                change,
                              )
                            }
                          />
                        ) : (
                          <Typography className="pb-[20px] md:pb-0 text-[14px]">
                            ー
                          </Typography>
                        )}
                      </div>

                      <div className="md:flex items-center w-full md:w-[50%]">
                        <div className="flex items-center pb-[10px] md:pb-0 pr-[20px]">
                          <Typography className="pr-[5px] text-[14px] w-[max-content]">
                            学費+学外費
                          </Typography>
                          <Tooltip
                            item="※学外費とは、学習塾や家庭教師・習い事などの活動費のことを指します。"
                            itemClassName="w-[200px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                          >
                            <HelpCircleIcon />
                          </Tooltip>
                        </div>
                        <div className="w-full">
                          {isChildbirthWithinRange(
                            childbirth!,
                            SCHOOL_AGE.kindergarten1,
                          ) ? (
                            <div className="flex items-baseline pb-[10px] last:pb-0 justify-center">
                              <NumberField
                                name={`${name}kindergarten_tuition_1_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="px-[8px] text-[14px]">
                                万円
                              </Typography>
                              <NumberField
                                name={`${name}off_kindergarten_tuition_1_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="pl-[8px] text-[14px]">
                                万円
                              </Typography>
                            </div>
                          ) : (
                            <Typography className="pb-[10px] last:pb-0 text-[14px] text-center">
                              ー
                            </Typography>
                          )}
                          {isChildbirthWithinRange(
                            childbirth!,
                            SCHOOL_AGE.kindergarten2,
                          ) ? (
                            <div className="flex items-baseline pb-[10px] last:pb-0 justify-center">
                              <NumberField
                                name={`${name}kindergarten_tuition_2_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="px-[8px] text-[14px]">
                                万円
                              </Typography>
                              <NumberField
                                name={`${name}off_kindergarten_tuition_2_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="pl-[8px] text-[14px]">
                                万円
                              </Typography>
                            </div>
                          ) : (
                            <Typography className="pb-[10px] last:pb-0 text-[14px] text-center">
                              ー
                            </Typography>
                          )}
                          {isChildbirthWithinRange(
                            childbirth!,
                            SCHOOL_AGE.kindergarten3,
                          ) ? (
                            <div className="flex items-baseline pb-[10px] last:pb-0 justify-center">
                              <NumberField
                                name={`${name}kindergarten_tuition_3_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="px-[8px] text-[14px]">
                                万円
                              </Typography>
                              <NumberField
                                name={`${name}off_kindergarten_tuition_3_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="pl-[8px] text-[14px]">
                                万円
                              </Typography>
                            </div>
                          ) : (
                            <Typography className="pb-[10px] last:pb-0 text-[14px] text-center">
                              ー
                            </Typography>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="md:flex justify-between items-center py-[20px] border-b border-black-300">
                      <div className="flex w-full md:w-[30%] md:justify-center">
                        <Typography className="pb-[10px] md:pb-0 text-[14px]">
                          小学校
                        </Typography>
                      </div>

                      <div className="flex w-full md:w-[20%] justify-center">
                        {isChildbirthWithinRange(
                          childbirth!,
                          SCHOOL_AGE.primarySchool6,
                        ) ? (
                          <SelectBox
                            name={`${name}primary_school_type`}
                            className="pb-[20px] md:pb-0"
                            placeholder="選択する"
                            options={NORMAL_SCHOOL_TYPE_SELECT_ITEMS}
                            validate={required}
                            onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                              handleChangeSchoolType(
                                name,
                                SCHOOL_KEY.primarySchool,
                                Number(event.target.value),
                                change,
                              )
                            }
                          />
                        ) : (
                          <Typography className="pb-[20px] md:pb-0 text-[14px]">
                            ー
                          </Typography>
                        )}
                      </div>

                      <div className="md:flex items-center w-full md:w-[50%]">
                        <div className="flex items-center pb-[10px] md:pb-0 pr-[20px]">
                          <Typography className="pr-[5px] text-[14px] w-[max-content]">
                            学費+学外費
                          </Typography>
                          <Tooltip
                            item="※学外費とは、学習塾や家庭教師・習い事などの活動費のことを指します。"
                            itemClassName="w-[200px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                          >
                            <HelpCircleIcon />
                          </Tooltip>
                        </div>
                        <div className="w-full">
                          {isChildbirthWithinRange(
                            childbirth!,
                            SCHOOL_AGE.primarySchool1,
                          ) ? (
                            <div className="flex items-baseline pb-[10px] last:pb-0 justify-center">
                              <NumberField
                                name={`${name}primary_school_tuition_1_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="px-[8px] text-[14px]">
                                万円
                              </Typography>
                              <NumberField
                                name={`${name}off_primary_school_tuition_1_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="pl-[8px] text-[14px]">
                                万円
                              </Typography>
                            </div>
                          ) : (
                            <Typography className="pb-[10px] last:pb-0 text-[14px] text-center">
                              ー
                            </Typography>
                          )}
                          {isChildbirthWithinRange(
                            childbirth!,
                            SCHOOL_AGE.primarySchool2,
                          ) ? (
                            <div className="flex items-baseline pb-[10px] last:pb-0 justify-center">
                              <NumberField
                                name={`${name}primary_school_tuition_2_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="px-[8px] text-[14px]">
                                万円
                              </Typography>
                              <NumberField
                                name={`${name}off_primary_school_tuition_2_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="pl-[8px] text-[14px]">
                                万円
                              </Typography>
                            </div>
                          ) : (
                            <Typography className="pb-[10px] last:pb-0 text-[14px] text-center">
                              ー
                            </Typography>
                          )}
                          {isChildbirthWithinRange(
                            childbirth!,
                            SCHOOL_AGE.primarySchool3,
                          ) ? (
                            <div className="flex items-baseline pb-[10px] last:pb-0 justify-center">
                              <NumberField
                                name={`${name}primary_school_tuition_3_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="px-[8px] text-[14px]">
                                万円
                              </Typography>
                              <NumberField
                                name={`${name}off_primary_school_tuition_3_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="pl-[8px] text-[14px]">
                                万円
                              </Typography>
                            </div>
                          ) : (
                            <Typography className="pb-[10px] last:pb-0 text-[14px] text-center">
                              ー
                            </Typography>
                          )}
                          {isChildbirthWithinRange(
                            childbirth!,
                            SCHOOL_AGE.primarySchool4,
                          ) ? (
                            <div className="flex items-baseline pb-[10px] last:pb-0 justify-center">
                              <NumberField
                                name={`${name}primary_school_tuition_4_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="px-[8px] text-[14px]">
                                万円
                              </Typography>
                              <NumberField
                                name={`${name}off_primary_school_tuition_4_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="pl-[8px] text-[14px]">
                                万円
                              </Typography>
                            </div>
                          ) : (
                            <Typography className="pb-[10px] last:pb-0 text-[14px] text-center">
                              ー
                            </Typography>
                          )}
                          {isChildbirthWithinRange(
                            childbirth!,
                            SCHOOL_AGE.primarySchool5,
                          ) ? (
                            <div className="flex items-baseline pb-[10px] last:pb-0 justify-center">
                              <NumberField
                                name={`${name}primary_school_tuition_5_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="px-[8px] text-[14px]">
                                万円
                              </Typography>
                              <NumberField
                                name={`${name}off_primary_school_tuition_5_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="pl-[8px] text-[14px]">
                                万円
                              </Typography>
                            </div>
                          ) : (
                            <Typography className="pb-[10px] last:pb-0 text-[14px] text-center">
                              ー
                            </Typography>
                          )}
                          {isChildbirthWithinRange(
                            childbirth!,
                            SCHOOL_AGE.primarySchool6,
                          ) ? (
                            <div className="flex items-baseline pb-[10px] last:pb-0 justify-center">
                              <NumberField
                                name={`${name}primary_school_tuition_6_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="px-[8px] text-[14px]">
                                万円
                              </Typography>
                              <NumberField
                                name={`${name}off_primary_school_tuition_6_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="pl-[8px] text-[14px]">
                                万円
                              </Typography>
                            </div>
                          ) : (
                            <Typography className="pb-[10px] last:pb-0 text-[14px] text-center">
                              ー
                            </Typography>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="md:flex justify-between items-center py-[20px] border-b border-black-300">
                      <div className="flex w-full md:w-[30%] md:justify-center">
                        <Typography className="pb-[10px] md:pb-0 text-[14px]">
                          中学校
                        </Typography>
                      </div>

                      <div className="flex w-full md:w-[20%] justify-center">
                        {isChildbirthWithinRange(
                          childbirth!,
                          SCHOOL_AGE.juniorHighSchool3,
                        ) ? (
                          <SelectBox
                            name={`${name}junior_high_school_type`}
                            className="pb-[20px] md:pb-0"
                            placeholder="選択する"
                            options={NORMAL_SCHOOL_TYPE_SELECT_ITEMS}
                            validate={required}
                            onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                              handleChangeSchoolType(
                                name,
                                SCHOOL_KEY.juniorHighSchool,
                                Number(event.target.value),
                                change,
                              )
                            }
                          />
                        ) : (
                          <Typography className="pb-[20px] md:pb-0 text-[14px]">
                            ー
                          </Typography>
                        )}
                      </div>

                      <div className="md:flex items-center w-full md:w-[50%]">
                        <div className="flex items-center pb-[10px] md:pb-0 pr-[20px]">
                          <Typography className="pr-[5px] text-[14px] w-[max-content]">
                            学費+学外費
                          </Typography>
                          <Tooltip
                            item="※学外費とは、学習塾や家庭教師・習い事などの活動費のことを指します。"
                            itemClassName="w-[200px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                          >
                            <HelpCircleIcon />
                          </Tooltip>
                        </div>
                        <div className="w-full">
                          {isChildbirthWithinRange(
                            childbirth!,
                            SCHOOL_AGE.juniorHighSchool1,
                          ) ? (
                            <div className="flex items-baseline pb-[10px] last:pb-0 justify-center">
                              <NumberField
                                name={`${name}junior_high_school_tuition_1_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="px-[8px] text-[14px]">
                                万円
                              </Typography>
                              <NumberField
                                name={`${name}off_junior_high_school_tuition_1_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="pl-[8px] text-[14px]">
                                万円
                              </Typography>
                            </div>
                          ) : (
                            <Typography className="pb-[10px] last:pb-0 text-[14px] text-center">
                              ー
                            </Typography>
                          )}
                          {isChildbirthWithinRange(
                            childbirth!,
                            SCHOOL_AGE.juniorHighSchool2,
                          ) ? (
                            <div className="flex items-baseline pb-[10px] last:pb-0 justify-center">
                              <NumberField
                                name={`${name}junior_high_school_tuition_2_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="px-[8px] text-[14px]">
                                万円
                              </Typography>
                              <NumberField
                                name={`${name}off_junior_high_school_tuition_2_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="pl-[8px] text-[14px]">
                                万円
                              </Typography>
                            </div>
                          ) : (
                            <Typography className="pb-[10px] last:pb-0 text-[14px] text-center">
                              ー
                            </Typography>
                          )}
                          {isChildbirthWithinRange(
                            childbirth!,
                            SCHOOL_AGE.juniorHighSchool3,
                          ) ? (
                            <div className="flex items-baseline pb-[10px] last:pb-0 justify-center">
                              <NumberField
                                name={`${name}junior_high_school_tuition_3_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="px-[8px] text-[14px]">
                                万円
                              </Typography>
                              <NumberField
                                name={`${name}off_junior_high_school_tuition_3_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="pl-[8px] text-[14px]">
                                万円
                              </Typography>
                            </div>
                          ) : (
                            <Typography className="pb-[10px] last:pb-0 text-[14px] text-center">
                              ー
                            </Typography>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="md:flex justify-between items-center py-[20px] border-b border-black-300">
                      <div className="flex w-full md:w-[30%] md:justify-center">
                        <Typography className="pb-[10px] md:pb-0 text-[14px]">
                          高校
                        </Typography>
                      </div>
                      <div className="flex w-full md:w-[20%] justify-center">
                        {isChildbirthWithinRange(childbirth!, SCHOOL_AGE.highSchool3) ? (
                          <SelectBox
                            name={`${name}high_school_type`}
                            className="pb-[20px] md:pb-0"
                            placeholder="選択する"
                            options={NORMAL_SCHOOL_TYPE_SELECT_ITEMS}
                            validate={required}
                            onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                              handleChangeSchoolType(
                                name,
                                SCHOOL_KEY.highSchool,
                                Number(event.target.value),
                                change,
                              )
                            }
                          />
                        ) : (
                          <Typography className="pb-[20px] md:pb-0 text-[14px]">
                            ー
                          </Typography>
                        )}
                      </div>

                      <div className="md:flex items-center w-full md:w-[50%]">
                        <div className="flex items-center pb-[10px] md:pb-0 pr-[20px]">
                          <Typography className="pr-[5px] text-[14px] w-[max-content]">
                            学費+学外費
                          </Typography>
                          <Tooltip
                            item="※学外費とは、学習塾や家庭教師・習い事などの活動費のことを指します。"
                            itemClassName="w-[200px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                          >
                            <HelpCircleIcon />
                          </Tooltip>
                        </div>
                        <div className="w-full">
                          {isChildbirthWithinRange(
                            childbirth!,
                            SCHOOL_AGE.highSchool1,
                          ) ? (
                            <div className="flex items-baseline pb-[10px] last:pb-0 justify-center">
                              <NumberField
                                name={`${name}high_school_tuition_1_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="px-[8px] text-[14px]">
                                万円
                              </Typography>
                              <NumberField
                                name={`${name}off_high_school_tuition_1_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="pl-[8px] text-[14px]">
                                万円
                              </Typography>
                            </div>
                          ) : (
                            <Typography className="pb-[10px] last:pb-0 text-[14px] text-center">
                              ー
                            </Typography>
                          )}
                          {isChildbirthWithinRange(
                            childbirth!,
                            SCHOOL_AGE.highSchool2,
                          ) ? (
                            <div className="flex items-baseline pb-[10px] last:pb-0 justify-center">
                              <NumberField
                                name={`${name}high_school_tuition_2_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="px-[8px] text-[14px]">
                                万円
                              </Typography>
                              <NumberField
                                name={`${name}off_high_school_tuition_2_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="pl-[8px] text-[14px]">
                                万円
                              </Typography>
                            </div>
                          ) : (
                            <Typography className="pb-[10px] last:pb-0 text-[14px] text-center">
                              ー
                            </Typography>
                          )}
                          {isChildbirthWithinRange(
                            childbirth!,
                            SCHOOL_AGE.highSchool3,
                          ) ? (
                            <div className="flex items-baseline pb-[10px] last:pb-0 justify-center">
                              <NumberField
                                name={`${name}high_school_tuition_3_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="px-[8px] text-[14px]">
                                万円
                              </Typography>
                              <NumberField
                                name={`${name}off_high_school_tuition_3_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="pl-[8px] text-[14px]">
                                万円
                              </Typography>
                            </div>
                          ) : (
                            <Typography className="pb-[10px] last:pb-0 text-[14px] text-center">
                              ー
                            </Typography>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="md:flex justify-between items-center py-[20px] border-b border-black-300">
                      <div className="flex w-full md:w-[30%] md:justify-center">
                        <Typography className="pb-[10px] md:pb-0 text-[14px]">
                          大学
                        </Typography>
                      </div>
                      <div className="flex w-full md:w-[20%] justify-center">
                        {isChildbirthWithinRange(childbirth!, SCHOOL_AGE.university4) ? (
                          <SelectBox
                            name={`${name}university_school_type`}
                            className="pb-[20px] md:pb-0"
                            placeholder="選択する"
                            options={UNIVERSITY_SCHOOL_TYPE_SELECT_ITEMS}
                            validate={required}
                            onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                              handleChangeSchoolType(
                                name,
                                SCHOOL_KEY.university,
                                Number(event.target.value),
                                change,
                              )
                            }
                          />
                        ) : (
                          <Typography className="pb-[20px] md:pb-0 text-[14px]">
                            ー
                          </Typography>
                        )}
                      </div>

                      <div className="md:flex items-center w-full md:w-[50%]">
                        <div className="flex items-center pb-[10px] md:pb-0 pr-[20px]">
                          <Typography className="pr-[5px] text-[14px] w-[max-content]">
                            学費+学外費
                          </Typography>
                          <Tooltip
                            item="※学外費とは、学習塾や家庭教師・習い事などの活動費のことを指します。"
                            itemClassName="w-[200px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                          >
                            <HelpCircleIcon />
                          </Tooltip>
                        </div>
                        <div className="w-full">
                          {isChildbirthWithinRange(
                            childbirth!,
                            SCHOOL_AGE.university1,
                          ) ? (
                            <div className="flex items-baseline pb-[10px] last:pb-0 justify-center">
                              <NumberField
                                name={`${name}university_tuition_1_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="px-[8px] text-[14px]">
                                万円
                              </Typography>
                              <NumberField
                                name={`${name}off_university_tuition_1_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="pl-[8px] text-[14px]">
                                万円
                              </Typography>
                            </div>
                          ) : (
                            <Typography className="pb-[10px] last:pb-0 text-[14px] text-center">
                              ー
                            </Typography>
                          )}
                          {isChildbirthWithinRange(
                            childbirth!,
                            SCHOOL_AGE.university2,
                          ) ? (
                            <div className="flex items-baseline pb-[10px] last:pb-0 justify-center">
                              <NumberField
                                name={`${name}university_tuition_2_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="px-[8px] text-[14px]">
                                万円
                              </Typography>
                              <NumberField
                                name={`${name}off_university_tuition_2_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="pl-[8px] text-[14px]">
                                万円
                              </Typography>
                            </div>
                          ) : (
                            <Typography className="pb-[10px] last:pb-0 text-[14px] text-center">
                              ー
                            </Typography>
                          )}
                          {isChildbirthWithinRange(
                            childbirth!,
                            SCHOOL_AGE.university3,
                          ) ? (
                            <div className="flex items-baseline pb-[10px] last:pb-0 justify-center">
                              <NumberField
                                name={`${name}university_tuition_3_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="px-[8px] text-[14px]">
                                万円
                              </Typography>
                              <NumberField
                                name={`${name}off_university_tuition_3_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="pl-[8px] text-[14px]">
                                万円
                              </Typography>
                            </div>
                          ) : (
                            <Typography className="pb-[10px] last:pb-0 text-[14px] text-center">
                              ー
                            </Typography>
                          )}
                          {isChildbirthWithinRange(
                            childbirth!,
                            SCHOOL_AGE.university4,
                          ) ? (
                            <div className="flex items-baseline pb-[10px] last:pb-0 justify-center">
                              <NumberField
                                name={`${name}university_tuition_4_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="px-[8px] text-[14px]">
                                万円
                              </Typography>
                              <NumberField
                                name={`${name}off_university_tuition_4_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="pl-[8px] text-[14px]">
                                万円
                              </Typography>
                            </div>
                          ) : (
                            <Typography className="pb-[10px] last:pb-0 text-[14px] text-center">
                              ー
                            </Typography>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="md:flex justify-between items-center py-[20px] border-b border-black-300">
                      <div className="flex w-full md:w-[30%] md:justify-center">
                        <Typography className="pb-[10px] md:pb-0 text-[14px]">
                          大学院
                        </Typography>
                      </div>
                      <div className="flex w-full md:w-[20%] justify-center">
                        <Typography className="pb-[20px] md:pb-0 text-[14px]">
                          ー
                        </Typography>
                      </div>

                      <div className="md:flex items-center w-full md:w-[50%]">
                        <div className="flex items-center pb-[10px] md:pb-0 pr-[20px]">
                          <Typography className="pr-[5px] text-[14px] w-[max-content]">
                            学費+学外費
                          </Typography>
                          <Tooltip
                            item="※学外費とは、学習塾や家庭教師・習い事などの活動費のことを指します。"
                            itemClassName="w-[200px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                          >
                            <HelpCircleIcon />
                          </Tooltip>
                        </div>
                        <div className="w-full">
                          {isChildbirthWithinRange(
                            childbirth!,
                            SCHOOL_AGE.graduateSchool1,
                          ) ? (
                            <div className="flex items-baseline pb-[10px] last:pb-0 justify-center">
                              <NumberField
                                name={`${name}graduate_school_tuition_1_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="px-[8px] text-[14px]">
                                万円
                              </Typography>
                              <NumberField
                                name={`${name}off_graduate_school_tuition_1_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="pl-[8px] text-[14px]">
                                万円
                              </Typography>
                            </div>
                          ) : (
                            <Typography className="pb-[10px] last:pb-0 text-[14px] text-center">
                              ー
                            </Typography>
                          )}
                          {isChildbirthWithinRange(
                            childbirth!,
                            SCHOOL_AGE.graduateSchool2,
                          ) ? (
                            <div className="flex items-baseline pb-[10px] last:pb-0 justify-center">
                              <NumberField
                                name={`${name}graduate_school_tuition_2_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="px-[8px] text-[14px]">
                                万円
                              </Typography>
                              <NumberField
                                name={`${name}off_graduate_school_tuition_2_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="pl-[8px] text-[14px]">
                                万円
                              </Typography>
                            </div>
                          ) : (
                            <Typography className="pb-[10px] last:pb-0 text-[14px] text-center">
                              ー
                            </Typography>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              }
            </FieldArray>

            <WholeSubmitErrorMessage submitErrors={submitErrors} />
            <SubmitErrorMessage show={!submitErrors && submitFailed} />
            <ButtonPrimary
              className={clsx(
                { ['opacity-50']: Object.keys(errors).length > 0 },
                'w-[246px] h-[64px] mx-auto mb-[16px] ',
              )}
              isDisabled={isLoading}
              onClick={() => handleSubmit(values)}
            >
              <Typography
                className="relative bottom-[2px] pl-[30px] text-[16px] text-white flex-auto"
                isBold
              >
                保存する
              </Typography>
              <ChevronRightIcon className="mr-[17px]" />
            </ButtonPrimary>
            <Button
              className="mx-auto text-[14px] text-black-700"
              onClick={() => props.onClose()}
              isDisabled={isLoading}
            >
              キャンセル
            </Button>
          </form>
        )}
      />
    </>
  )
}

export default MyDataTuitionEditForm
