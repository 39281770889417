import {
  isNull as _isNull,
  isString as _isString,
  isBoolean as _isBoolean,
  isNumber as _isNumber,
  isObject as _isObject,
} from 'lodash'

class LocalStorage {
  static storage = window.localStorage

  static getItem(key: string) {
    const value = this.storage.getItem(key)
    return this.parseItem(value)
  }

  static setItem(key: string, value: string | number | object | boolean) {
    try {
      let conversionValue = ''
      if (_isString(value)) conversionValue = value
      if (_isNumber(value) || _isBoolean(value)) conversionValue = String(value)
      if (_isObject(value)) conversionValue = JSON.stringify(value)

      this.storage.setItem(key, conversionValue)
      return value
    } catch (error) {
      return error
    }
  }

  static removeItem(key: string) {
    this.storage.removeItem(key)
  }

  static parseItem(value: string | null) {
    if (_isNull(value)) return null

    try {
      return JSON.parse(value)
    } catch {
      return value
    }
  }
}

export default LocalStorage
