import ApiClient from '@/api/apiClient'
import {
  InputCorporatePension,
  InputCorporatePensionCreateValues,
  InputCorporatePensionUpdateValues,
  InputCorporatePensionDestroyValues,
} from '@/models/inputs/inputCorporatePensionsModel'

export const getInputCorporatePensions = async () => {
  return await ApiClient.get(
    '/input_corporate_pensions',
    (response: InputCorporatePension[]) => response,
    undefined,
    false,
    'v2',
  )
}

export const postInputCorporatePension = async (
  values: InputCorporatePensionCreateValues,
) => {
  return await ApiClient.post('/input_corporate_pensions', () => {}, values)
}

export const patchInputCorporatePension = async (
  values: InputCorporatePensionUpdateValues,
) => {
  return await ApiClient.patch('/input_corporate_pensions', () => {}, values)
}

export const deleteInputCorporatePension = async (values: {
  params: InputCorporatePensionDestroyValues
}) => {
  return await ApiClient.delete('/input_corporate_pensions', () => {}, values)
}
