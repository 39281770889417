import React, { FC } from 'react'
import { Switch, Route } from 'react-router-dom'
import MyDataIndex from '@/templates/v2/myData/index'
import MyDataSimple from '@/templates/v2/myData/Simple'
import MyDataSalary from '@/templates/v2/myData/Salary'
import MyDataSalaryForm from '@/templates/v2/myData/salaries/Edit'
import MyDataFamily from '@/templates/v2/myData/Family'
import MyDataFamilyEdit from '@/templates/v2/myData/families/Edit'
import MyDataHousing from '@/templates/v2/myData/Housing'
import MyDataHousingNew from '@/templates/v2/myData/housings/Edit'
import MyDataHousingEdit from '@/templates/v2/myData/housings/Edit'
import MyDataHousingFormTransfer from '@/templates/v2/myData/housings/EditTransfer'
import MyDataCar from '@/templates/v2/myData/Car'
import MyDataCarEdit from '@/templates/v2/myData/cars/Edit'
import MyDataCarBulkEdit from '@/templates/v2/myData/cars/BulkEdit'
import MyDataInsurance from '@/templates/v2/myData/Insurance'
import MyDataInsuranceEdit from '@/templates/v2/myData/insurances/Edit'
import MyDataInsuranceBulkEdit from '@/templates/v2/myData/insurances/BulkEdit'
import MyDataRisk from '@/templates/v2/myData/Risk'
import MyDataRiskEdit from '@/templates/v2/myData/risks/Edit'
import MyDataAsset from '@/templates/v2/myData/Asset'
import MyDataAssetBulkEdit from '@/templates/v2/myData/assets/BulkEdit'
import MyDataAssetForm from '@/templates/v2/myData/assets/Edit'
import MyDataEstateInvestment from '@/templates/v2/myData/EstateInvestment'
import MyDataEstateInvestmentNew from '@/templates/v2/myData/estateInvestments/Edit'
import MyDataEstateInvestmentEdit from '@/templates/v2/myData/estateInvestments/Edit'
import MyDataCorporatePension from '@/templates/v2/myData/CorporatePension'
import MyDataCorporatePensionEdit from '@/templates/v2/myData/corporatePensions/Edit'
import MyDataCompanyContributionPension from '@/templates/v2/myData/CompanyContributionPension'
import MyDataCompanyContributionPensionEdit from '@/templates/v2/myData/companyContributionPensions/Edit'
import MyDataPersonContributionPension from '@/templates/v2/myData/PersonContributionPension'
import MyDataPersonContributionPensionEdit from '@/templates/v2/myData/personContributionPensions/Edit'
import MyDataSubIncome from '@/templates/v2/myData/SubIncome'
import MyDataSubIncomeEdit from '@/templates/v2/myData/subIncomes/Edit'
import MyDataYearlyLivingCost from '@/templates/v2/myData/YearlyLivingCost'
import MyDataYearlyLivingCostForm from '@/templates/v2/myData/yearlyLivingCosts/Edit'
import MyDataYearCost from '@/templates/v2/myData/YearCost'
import MyDataYearCostEdit from '@/templates/v2/myData/yearCosts/Edit'
import MyDataIncome from '@/templates/v2/myData/Income'
import MyDataIncomeEdit from '@/templates/v2/myData/incomes/Edit'
import MyDataTuition from '@/templates/v2/myData/Tuition'
import MyDataTuitionForm from '@/templates/v2/myData/tuitions/Edit'
import PublicPensionEdit from '@/templates/v2/myData/publicPensions/Edit'
import Journal from '@/templates/v2/myData/journals'
import MonthlyBalance from '@/templates/v2/myData/journals/MonthlyBalance'
import MyDataMonthlyCost from '@/templates/v2/myData/MonthlyCost'
import MyDataMonthlyCostForm from '@/templates/v2/myData/monthlyCosts/Edit'
import MyDataCustomItem from '@/templates/v2/myData/CustomItem'
import MyDataCustomItemForm from '@/templates/v2/myData/customItems/Edit'
import ConfirmMydata from '@/components/v2/organisms/ConfirmMydata'

const MyData: FC = () => {
  return (
    <>
      <Switch>
        <Route exact path="/v2/myData" component={MyDataIndex} />
        <Route exact path="/v2/myData/simple" component={MyDataSimple} />
        <Route exact path="/v2/myData/salaries" component={MyDataSalary} />
        <Route
          exact
          path="/v2/myData/salaries/edit/:person_flg"
          component={MyDataSalaryForm}
        />
        <Route exact path="/v2/myData/families" component={MyDataFamily} />
        <Route exact path="/v2/myData/families/edit" component={MyDataFamilyEdit} />
        <Route exact path="/v2/myData/housings" component={MyDataHousing} />
        <Route exact path="/v2/myData/housings/new" component={MyDataHousingNew} />
        <Route exact path="/v2/myData/housings/edit/:id" component={MyDataHousingEdit} />
        <Route
          exact
          path="/v2/myData/housings/newTransfer"
          component={MyDataHousingFormTransfer}
        />
        <Route
          exact
          path="/v2/myData/housings/editTransfer/:id"
          component={MyDataHousingFormTransfer}
        />
        <Route exact path="/v2/myData/cars" component={MyDataCar} />
        <Route exact path="/v2/myData/cars/new" component={MyDataCarEdit} />
        <Route exact path="/v2/myData/cars/edit/:id" component={MyDataCarEdit} />
        <Route exact path="/v2/myData/cars/edit" component={MyDataCarBulkEdit} />
        <Route exact path="/v2/myData/insurances" component={MyDataInsurance} />
        <Route
          exact
          path="/v2/myData/insurances/edit"
          component={MyDataInsuranceBulkEdit}
        />
        <Route exact path="/v2/myData/insurances/new" component={MyDataInsuranceEdit} />
        <Route
          exact
          path="/v2/myData/insurances/edit/:id"
          component={MyDataInsuranceEdit}
        />
        <Route exact path="/v2/myData/risk" component={MyDataRisk} />
        <Route exact path="/v2/myData/risk/new/:target" component={MyDataRiskEdit} />
        <Route exact path="/v2/myData/risk/edit/:id/:target" component={MyDataRiskEdit} />
        <Route exact path="/v2/myData/assets" component={MyDataAsset} />
        <Route exact path="/v2/myData/assets/new" component={MyDataAssetForm} />
        <Route exact path="/v2/myData/assets/edit/:id" component={MyDataAssetForm} />
        <Route exact path="/v2/myData/assets/edit/" component={MyDataAssetBulkEdit} />

        {/** MEMO: 新しい金融資産のフォームが正式に反映されるまで一旦コメントアウト */}
        {/* <Route
        exact
        path="/v2/myData/assetInvestments/new"
        component={MyDataAssetInvestmentForm}
      />
      <Route
        exact
        path="/v2/myData/assetInvestments/edit/:id"
        component={MyDataAssetInvestmentForm}
      /> */}

        <Route
          exact
          path="/v2/myData/estateInvestments"
          component={MyDataEstateInvestment}
        />
        <Route
          exact
          path="/v2/myData/estateInvestments/new"
          component={MyDataEstateInvestmentNew}
        />
        <Route
          exact
          path="/v2/myData/estateInvestments/edit/:id"
          component={MyDataEstateInvestmentEdit}
        />
        <Route
          exact
          path="/v2/myData/corporatePensions"
          component={MyDataCorporatePension}
        />
        <Route
          exact
          path="/v2/myData/corporatePensions/person/edit"
          component={MyDataCorporatePensionEdit}
        />
        <Route
          exact
          path="/v2/myData/corporatePensions/spouse/edit"
          component={MyDataCorporatePensionEdit}
        />
        <Route
          exact
          path="/v2/myData/companyContributionPensions"
          component={MyDataCompanyContributionPension}
        />
        <Route
          exact
          path="/v2/myData/companyContributionPensions/person/edit"
          component={MyDataCompanyContributionPensionEdit}
        />
        <Route
          exact
          path="/v2/myData/companyContributionPensions/spouse/edit"
          component={MyDataCompanyContributionPensionEdit}
        />
        <Route
          exact
          path="/v2/myData/personContributionPensions"
          component={MyDataPersonContributionPension}
        />
        <Route
          exact
          path="/v2/myData/personContributionPensions/person/edit"
          component={MyDataPersonContributionPensionEdit}
        />
        <Route
          exact
          path="/v2/myData/personContributionPensions/spouse/edit"
          component={MyDataPersonContributionPensionEdit}
        />
        <Route exact path="/v2/myData/subIncomes" component={MyDataSubIncome} />
        <Route
          exact
          path="/v2/myData/subIncomes/person/edit"
          component={MyDataSubIncomeEdit}
        />
        <Route
          exact
          path="/v2/myData/subIncomes/spouse/edit"
          component={MyDataSubIncomeEdit}
        />
        <Route
          exact
          path="/v2/myData/yearlyLivingCosts"
          component={MyDataYearlyLivingCost}
        />
        <Route
          exact
          path="/v2/myData/yearlyLivingCosts/new"
          component={MyDataYearlyLivingCostForm}
        />
        <Route
          exact
          path="/v2/myData/yearlyLivingCosts/edit"
          component={MyDataYearlyLivingCostForm}
        />
        <Route exact path="/v2/myData/yearCosts" component={MyDataYearCost} />
        <Route exact path="/v2/myData/yearCosts/edit" component={MyDataYearCostEdit} />
        <Route exact path="/v2/myData/incomes" component={MyDataIncome} />
        <Route exact path="/v2/myData/incomes/person/edit" component={MyDataIncomeEdit} />
        <Route exact path="/v2/myData/incomes/spouse/edit" component={MyDataIncomeEdit} />
        <Route exact path="/v2/myData/tuitions" component={MyDataTuition} />
        <Route
          exact
          path="/v2/myData/publicPensions/edit/:person_flg"
          component={PublicPensionEdit}
        />
        <Route exact path="/v2/myData/tuitions/new/" component={MyDataTuitionForm} />
        <Route exact path="/v2/myData/tuitions/edit/:id" component={MyDataTuitionForm} />
        <Route exact path="/v2/myData/journals" component={Journal} />
        <Route
          exact
          path="/v2/myData/journals/monthlyBalance"
          component={MonthlyBalance}
        />
        <Route exact path="/v2/myData/monthlyCosts" component={MyDataMonthlyCost} />
        <Route
          exact
          path="/v2/myData/monthlyCosts/edit"
          component={MyDataMonthlyCostForm}
        />
        <Route
          exact
          path="/v2/myData/CustomItems/edit"
          component={MyDataCustomItemForm}
        />
        <Route exact path="/v2/myData/CustomItems" component={MyDataCustomItem} />
      </Switch>
      <ConfirmMydata />
    </>
  )
}

export default MyData
