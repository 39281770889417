import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  postFutureMonthlyLivingCostBaseV2,
  patchFutureMonthlyLivingCostBaseV2,
  deleteFutureMonthlyLivingCostBaseV2,
} from '@/api/futures/futureMonthlyLivingCostBases'
import { unauthorizedCheck } from '@/containers/accountsSlice'
import { AppThunk } from '@/store'

interface FutureMonthlyLivingCostBaseState {
  futureMonthlyLivingCostBase: any
  isLoading: boolean
}

const initialState: FutureMonthlyLivingCostBaseState = {
  futureMonthlyLivingCostBase: null,
  isLoading: false,
}

const futureMonthlyLivingCostBasesSlice = createSlice({
  name: 'futureMonthlyLivingCostBases',
  initialState,
  reducers: {
    setFutureMonthlyLivingCostBase: (state, action: PayloadAction<any>) => {
      state.futureMonthlyLivingCostBase = action.payload
      return state
    },
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
  },
})

export const {
  setFutureMonthlyLivingCostBase,
  requestStart,
  requestSuccess,
  requestFailure,
} = futureMonthlyLivingCostBasesSlice.actions
export const futureMonthlyLivingCostBasesReducer =
  futureMonthlyLivingCostBasesSlice.reducer

export const createFutureMonthlyLivingCostBaseV2 = (
  values: any,
  callback?: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postFutureMonthlyLivingCostBaseV2(values)
    .then(() => {
      dispatch(requestSuccess())
      callback && callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const updateFutureMonthlyLivingCostBaseV2 = (
  values: any,
  id: number,
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchFutureMonthlyLivingCostBaseV2(values, id)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const destroyFutureMonthlyLivingCostBaseV2 = (
  id: number,
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  deleteFutureMonthlyLivingCostBaseV2(id)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}
