import { find as _find, isUndefined as _isUndefined } from 'lodash'
import { REPAIR_RESERVE_FUND_INCREASE_TYPE_SELECT_ITEMS } from '@/constants/formItem/commonEstateInvestment'
import {
  BUILDING_STRUCTURE_TYPE_SELECT_ITEMS,
  PROPERTY_TYPE_RADIO_ITEMS,
} from '@/constants/formItem/futures/futureEstateInvestments'
import {
  defaultToEmptyString,
  convertLocaleString,
  convertMathFloor,
} from '@/models/commonsModelFunc'
import {
  BuildingStructureType,
  PropertyType,
} from '@/models/futures/futureEstateInvestmentsModel'

// 物件タイプ
export const PROPERTY_TYPE = {
  whole: 'whole',
  division: 'division',
}
// シミュレーションタイプ
export const SIMULATION_TYPE = {
  simple: 'simple',
  detail: 'detail',
}

// 物件構造
export const BUILDING_STRUCTURE_TYPE = {
  rc: 'rc',
  src: 'src',
  steel: 'steel',
  lightSteel: 'light_steel',
  wood: 'wood',
}

// 建物管理手数料タイプ
export const MANAGEMENT_FEE_TYPE = {
  percent: 'percent',
  yen: 'yen',
}

export const VACANCY_SETTING_TYPE = {
  own: 0,
  auto: 1,
}

export const FUTURE_ESTATE_INVESTMENT_DEFAULT_VALUES = {
  breadth: 200,
  constructed_number: 2,
  property_price: 2000000,
  rent_income: 100000,
  renewal_fee_period_months: 24,
  repair_cost_span_years: 3,
  loan_down_payment_manyen: 300,
  loan_interest_ratio_percent: 3.1,
  repayment_period_years: 35,
}

export const VACANCY_RATE_FIELDS = [
  'future_estate_investment.room_count',
  'future_estate_investment.move_out_frequency',
  'future_estate_investment.time_between',
]

// 空室率算出
export const calcVacancyRate = (values: any) => {
  const { room_count, move_out_frequency, time_between } = values.future_estate_investment
  if (
    _isUndefined(values) ||
    _isUndefined(room_count) ||
    _isUndefined(move_out_frequency) ||
    _isUndefined(time_between)
  )
    return

  return Math.round(
    ((room_count * ((120 / move_out_frequency) * time_between)) / 600) * 100,
  )
}

export const convertPropertyType = (propertyType: PropertyType | null) => {
  const propertyTypeLabel = _find(
    PROPERTY_TYPE_RADIO_ITEMS,
    (item) => item.value === propertyType,
  )?.label

  return defaultToEmptyString(propertyTypeLabel)
}

export const convertBuildingStructureType = (
  buildingStructureType: BuildingStructureType | null,
) => {
  const buildingStructureTypeLabel = _find(
    BUILDING_STRUCTURE_TYPE_SELECT_ITEMS,
    (item) => item.value === buildingStructureType,
  )?.label

  return defaultToEmptyString(buildingStructureTypeLabel)
}

export const convertPeriodCost = (period: number | null, cost: number | null) => {
  return `${defaultToEmptyString(period)}年に1回 ${convertLocaleString(cost)}円`
}

export const convertRepairReserveFundIncreaseType = (
  repairReserveFundIncreaseType: number | null,
) => {
  const label = _find(
    REPAIR_RESERVE_FUND_INCREASE_TYPE_SELECT_ITEMS,
    (item) => item.value === repairReserveFundIncreaseType,
  )?.label

  return defaultToEmptyString(label)
}

export const calculateTax = (
  constructedNumber: number,
  breadth: number,
  propertyPriceManyen: number,
) => {
  let tax = 0
  if (constructedNumber < 1 && 50 <= breadth && breadth <= 280) {
    tax = propertyPriceManyen * 0.7 * 0.017 * 0.5 * 10000
  } else {
    tax = propertyPriceManyen * 0.7 * 0.017 * 10000
  }

  return convertMathFloor(tax)
}
