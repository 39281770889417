import React, { FC, useEffect, useMemo } from 'react'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import ChevronRightIcon from '@/assets/images/v2/chevron-right.svg'
import CircleIcon from '@/assets/images/v2/circle.svg'
import Button from '@/components/v2/atoms/Button'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import Grid from '@/components/v2/atoms/Grid'
import Typography from '@/components/v2/atoms/Typography'
import { useDispatch, useSelector } from 'react-redux'
import {
  InputHousingCreateValues,
  InputHousingUpdateValues,
} from '@/models/inputs/inputHousingsModel'
import {
  createInitialValues,
  handleSetInitialValues,
  hasTransferHouse,
} from '@/models/inputs/inputHousingsModelFunc'
import { HOUSE_TYPE } from '@/models/commonHousingModelFunc'
import { buildHousingTransferSubmitValues } from '@/utils/modalForms/inputHousings'
import {
  createInputHousingTransfer,
  fetchInputHousings,
  updateInputHousingTransfer,
} from '@/containers/inputs/inputHousingsSlice'
import { RootState } from '@/store'
import { API_FLAG } from '@/models/commonsModelFunc'
import TransferType from '@/templates/v2/myData/housings/transfers/TransferType'
import TransferMonthlyRent from '@/templates/v2/myData/housings/transfers/TransferMonthlyRent'
import TransferContractUpdate from '@/templates/v2/myData/housings/transfers/TransferContractUpdate'
import TransferUpdateCost from '@/templates/v2/myData/housings/transfers/TransferUpdateCost'
import { find as _find, set as _set } from 'lodash'
import { setPageMeta } from '@/containers/pageMetasSlice'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import WholeSubmitErrorMessage from '@/components/v2/molecules/WholeSubmitErrorMessage'
import LoadingOverlay from '@/components/v1/atoms/LoadingOverlay'
import clsx from 'clsx'
import SubmitErrorMessage from '@/components/v2/molecules/SubmitErrorMessage'
import { updateResetFlg } from '@/containers/futures/futureBasesSlice'

const DESCRIPTION_TEMPLATES: any[] = [
  {
    children: <TransferType />,
    condition: (values: any) => !values?.hasTransfer,
  },
  {
    children: <TransferMonthlyRent />,
    condition: (values: any) =>
      !values?.hasTransfer &&
      hasTransferHouse(
        values?.input_housing?.input_housing_transfer_attributes.transfer_type,
      ),
  },
  {
    children: <TransferContractUpdate />,
    condition: (values: any) =>
      !values?.hasTransfer &&
      hasTransferHouse(
        values?.input_housing?.input_housing_transfer_attributes.transfer_type,
      ),
  },
  {
    children: <TransferUpdateCost />,
    condition: (values: any) =>
      !values?.hasTransfer &&
      hasTransferHouse(
        values?.input_housing?.input_housing_transfer_attributes.transfer_type,
      ) &&
      Number(
        values?.input_housing?.input_housing_transfer_attributes?.transfer_update_type,
      ) === API_FLAG.on,
  },
]

interface MyDataHousingsEditFormProps {
  id?: string
  onClose: () => void
  onSubmit: () => void
}

const MyDataHousingsEditForm: FC<MyDataHousingsEditFormProps> = (props) => {
  const dispatch = useDispatch()
  const { showFlashMsg } = useFlashAlert()

  const hasTransfer = false
  const isOnlyTransfer = true
  const { inputHousings, isLoading } = useSelector(
    (state: RootState) => state.inputHousings,
  )

  const { id } = props

  const isEdit = useMemo(() => !!id && inputHousings.length > 0, [inputHousings])

  const targetInputHousing = useMemo(
    () =>
      isOnlyTransfer
        ? _find(inputHousings, (item) => item.house_type === HOUSE_TYPE.transfer)
        : _find(inputHousings, (item) => item.house_type !== HOUSE_TYPE.transfer),
    [inputHousings],
  )

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'マイデータ | 単身赴任 編集',
        description: 'マイデータ | 単身赴任 編集',
        keyword: 'マイデータ | 単身赴任 編集',
      }),
    )
    if (inputHousings.length == 0) {
      dispatch(fetchInputHousings())
    }
  }, [])

  const initialValues = useMemo(() => {
    return isEdit
      ? handleSetInitialValues(targetInputHousing) // edit
      : {
          // create
          ...createInitialValues,
          isOnlyTransfer,
          hasTransfer,
        }
  }, [targetInputHousing])

  const wrappedCompletedCallback = () => {
    showFlashMsg(isEdit ? '更新しました' : '作成しました')
    dispatch(fetchInputHousings())
    dispatch(updateResetFlg())
    props.onSubmit()
  }

  const handleSubmitCreate = (values: InputHousingCreateValues) => {
    const { input_housing } = values

    const createInputHousingTransferFunc = () => {
      const { input_housing_transfer_attributes: transferAttributes } = input_housing

      const newTransferValues = {
        input_housing: {
          house_type: HOUSE_TYPE.transfer,
          input_housing_transfer_attributes: {
            ...buildHousingTransferSubmitValues(transferAttributes as any),
          },
        },
      }

      new Promise((resolve: any) => {
        dispatch(
          createInputHousingTransfer(
            newTransferValues,
            () => (isOnlyTransfer ? wrappedCompletedCallback() : {}),
            resolve,
          ),
        )
      })
    }

    return createInputHousingTransferFunc()
  }

  const handleSubmitUpdate = (values: InputHousingUpdateValues) => {
    const pickedValues: any = values
    const { input_housing } = pickedValues

    const updateInputHousingTransferFunc = () => {
      const { input_housing_transfer_attributes: transferAttributes } = input_housing

      const newTransferValues = {
        input_housing_id: (initialValues.input_housing as any).input_housing_id,
        input_housing: {
          house_type: HOUSE_TYPE.transfer,
          input_housing_transfer_attributes: {
            ...buildHousingTransferSubmitValues(transferAttributes),
          },
        },
      }

      new Promise(() => {
        dispatch(
          updateInputHousingTransfer(newTransferValues, () => wrappedCompletedCallback()),
        )
      })
    }

    return updateInputHousingTransferFunc()
  }

  const handleSubmit = (values: any) => {
    return isEdit ? handleSubmitUpdate(values) : handleSubmitCreate(values)
  }

  return (
    <>
      <LoadingOverlay isLoading={isLoading} />
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        mutators={{ ...arrayMutators }}
        render={({ handleSubmit, values, errors, submitErrors, submitFailed }) => (
          <form onSubmit={handleSubmit}>
            <div className="flex items-center pb-20 md:pb-24">
              <CircleIcon className="mr-8" />
              <Typography
                className="bottom-2 relative text-20 md:text-24 text-black-800"
                isBold
              >
                基本情報
              </Typography>
            </div>

            <Grid className="grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px] mb-[30px]">
              {DESCRIPTION_TEMPLATES.map(
                (item, index) =>
                  item.condition(values) && (
                    <React.Fragment key={index}>{item.children}</React.Fragment>
                  ),
              )}
            </Grid>

            <WholeSubmitErrorMessage submitErrors={submitErrors} />
            <SubmitErrorMessage show={!submitErrors && submitFailed} />

            <ButtonPrimary
              className={clsx(
                { ['opacity-50']: Object.keys(errors).length > 0 },
                'w-[246px] h-[64px] mx-auto mb-[16px] ',
              )}
              isDisabled={isLoading}
              onClick={() => handleSubmit(values)}
            >
              <Typography
                className="relative bottom-[2px] pl-[30px] text-[16px] text-white flex-auto"
                isBold
              >
                保存する
              </Typography>
              <ChevronRightIcon className="mr-17" />
            </ButtonPrimary>
            <Button
              className="mx-auto text-[14px] text-black-700"
              onClick={props.onClose}
              isDisabled={isLoading}
            >
              キャンセル
            </Button>
          </form>
        )}
      />
    </>
  )
}

export default MyDataHousingsEditForm
