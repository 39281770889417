import React, { FC, useEffect } from 'react'
import { useField } from 'react-final-form'
import { PROPERTY_TYPE } from '@/models/futures/futureLendHousingsModelFunc'
import {
  composeValidators,
  required,
  zeroOrMoreNumber,
  commaNumberMaxLength,
} from '@/utils/validate'

import Typography from '@/components/v2/atoms/Typography'
import NumberFieldDecimal from '@/components/v2/molecules/NumberFieldDecimal'

const HousingBreadth: FC = () => {
  const {
    input: { value: propertyType },
  } = useField('future_lend_housing.property_type')

  useEffect(() => {
    const targetKeys = []

    if (propertyType === PROPERTY_TYPE.house || propertyType === PROPERTY_TYPE.whole) {
      targetKeys.push(
        'future_lend_housing.site_area',
        'future_lend_housing.total_floor_area',
      )
    }

    if (propertyType === PROPERTY_TYPE.division) {
      targetKeys.push('future_lend_housing.breadth')
    }
  }, [])

  return (
    <>
      {propertyType === PROPERTY_TYPE.division && (
        <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
          <Typography
            className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
            isBold
          >
            広さ
          </Typography>
          <div className="flex flex-auto items-baseline">
            <NumberFieldDecimal
              name="future_lend_housing.breadth"
              isFullWidth
              validate={composeValidators(required, zeroOrMoreNumber, (value: any) =>
                commaNumberMaxLength(value, 6),
              )}
            />
            <Typography className="pl-[8px] text-[14px] text-black-800" isBold>
              ㎡
            </Typography>
          </div>
        </div>
      )}

      {(propertyType === PROPERTY_TYPE.house || propertyType === PROPERTY_TYPE.whole) && (
        <>
          <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
            <Typography
              className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
              isBold
            >
              延床面積
            </Typography>
            <div className="flex flex-auto items-baseline">
              <NumberFieldDecimal
                name="future_lend_housing.total_floor_area"
                isFullWidth
                validate={composeValidators(required, zeroOrMoreNumber, (value: any) =>
                  commaNumberMaxLength(value, 6),
                )}
              />
              <Typography className="pl-[8px] text-[14px] text-black-800" isBold>
                ㎡
              </Typography>
            </div>
          </div>

          <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
            <Typography
              className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
              isBold
            >
              敷地面積
            </Typography>
            <div className="flex flex-auto items-baseline">
              <NumberFieldDecimal
                name="future_lend_housing.site_area"
                isFullWidth
                validate={composeValidators(required, zeroOrMoreNumber, (value: any) =>
                  commaNumberMaxLength(value, 6),
                )}
              />
              <Typography className="pl-[8px] text-[14px] text-black-800" isBold>
                ㎡
              </Typography>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default HousingBreadth
