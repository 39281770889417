import { useMemo } from 'react'
import {
  filter as _filter,
  isNumber as _isNumber,
  isUndefined as _isUndefined,
  findIndex as _findIndex,
  times as _times,
  reverse as _reverse,
} from 'lodash'
import moment, { Moment } from 'moment'
import { SelectItem } from '@/components/v1/molecules/SelectBox'
import { SelectBoxOption } from '@/components/v2/molecules/SelectBox'
import { AGE_SELECT_ITEMS } from '@/constants/formItem/commons'
import { isNotEmptyValue, combineTwoTypesYear } from '@/models/commonsModelFunc'

export const buildRangeYearSelectItems = (
  startYear: number,
  endYear: number,
  isReverse?: boolean,
) => {
  const yearSelectItems = []

  for (let i = startYear; i <= endYear; i += 1) {
    yearSelectItems.push({
      value: String(i),
      label: String(i),
    })
  }

  return isReverse ? _reverse(yearSelectItems) : yearSelectItems
}
export const buildTwoTypesRangeYearSelectItems = (
  startYear: number,
  endYear: number,
  isReverse?: boolean,
) => {
  const yearSelectItems = []

  for (let i = startYear; i <= endYear; i += 1) {
    yearSelectItems.push({
      value: String(i),
      label: combineTwoTypesYear(i),
    })
  }

  return isReverse ? _reverse(yearSelectItems) : yearSelectItems
}

export const buildAgeLimitSelectItems = (age: number | null | undefined) => {
  return isNotEmptyValue(age)
    ? _filter(AGE_SELECT_ITEMS, (item) => item.value >= age!)
    : AGE_SELECT_ITEMS
}

export const buildRangeAgeSelectItems = (
  lower: number,
  upper: number,
  ageSelectItem: SelectItem[] = AGE_SELECT_ITEMS,
) => {
  return _filter(ageSelectItem, (item) => item.value >= lower && item.value <= upper)
}

export const buildStandardSelectItems = (
  selectItems: SelectBoxOption[],
  standardValue: any,
  placeholder?: string,
) => {
  const index = _findIndex(selectItems, { value: standardValue })
  if (index === -1) return selectItems

  const copiedSelectItems = [...selectItems]

  if (placeholder) {
    copiedSelectItems.splice(index, 0, {
      value: '',
      isDisabled: true,
      label: String(placeholder),
    })
  }

  return copiedSelectItems
}

export const buildDateMonthSelectItems = (dateMonth: Moment | string, length: number) => {
  return useMemo(
    () =>
      _times(length, (index) => ({
        value: moment(dateMonth).subtract(index, 'months').format('YYYY/MM'),
        label: moment(dateMonth).subtract(index, 'months').format('YYYY年MM月'),
      })),
    [dateMonth, length],
  )
}

export const convertRadioGroupValue = (value: any) => {
  return _isNumber(value) ? String(value) : null
}

export const convertCheckboxValue = (value: any, checkValue: any, notCheckValue: any) => {
  if (_isUndefined(value)) return notCheckValue
  return value.length > 0 ? checkValue : notCheckValue
}
