import { isNull as _isNull, find as _find, filter as _filter } from 'lodash'
import {
  DemoLearningVideo,
  LearningVideo,
  Viewability,
} from '@/models/commonLearningVideosModel'
import { defaultToEmptyString } from '@/models/commonsModelFunc'

// 公開設定
export const VIEWABILITY = {
  published: 'published',
  limited: 'limited',
  unpublished: 'unpublished',
}

import { VIEWABILITY_SELECT_ITEMS } from '@/constants/formItem/admins/learningVideos'
import { DisplayStatus } from './learningVideosModel'

export const convertViewability = (value: Viewability) => {
  const label = _find(VIEWABILITY_SELECT_ITEMS, (item) => item.value === value)?.label
  return defaultToEmptyString(label)
}

export const buildVideoInitialValues = (learningVideo: LearningVideo | null) => {
  if (_isNull(learningVideo)) return undefined

  return {
    id: learningVideo.id,
    company_watchable_learning_videos: !_isNull(learningVideo.watchable_companies)
      ? learningVideo.watchable_companies?.map((item) => item.id)
      : [],
    learning_video_category_relations: learningVideo.learning_video_categories?.map(
      (item) => item.id,
    ),
    thumbnail_url: learningVideo.thumbnail_url,
    learning_video: {
      title: learningVideo.title,
      subtitle: learningVideo.subtitle,
      description: learningVideo.description,
      viewability: learningVideo.viewability,
      vimeo_url: learningVideo.vimeo_url,
      order: learningVideo.order,
      learning_video_related_videos: !_isNull(learningVideo.learning_video_related_videos)
        ? learningVideo.learning_video_related_videos
        : [],
    },
  }
}

export const learnigVideoSortedByDisplayStatus = (
  learningVideos: LearningVideo[],
  displayStatus: DisplayStatus,
): LearningVideo[] => {
  if (displayStatus == 'non_demo') {
    return _filter(learningVideos, (item: LearningVideo) => {
      return item.display_status == displayStatus
    })?.sort((item: LearningVideo, next_item: LearningVideo) => {
      return item.order < next_item.order ? -1 : 1
    })
  } else {
    return _filter(learningVideos, (item: DemoLearningVideo) => {
      return item.display_status == displayStatus
    })?.sort((item: DemoLearningVideo, next_item: DemoLearningVideo) => {
      return item.display_status_id < next_item.display_status_id ? -1 : 1
    })
  }
}
