import React, { FC } from 'react'
import SimulationItemCreate from '@/assets/images/v2/simulation-g-item-create.png'
import Typography from '@/components/v2/atoms/Typography'
import GuidanceModal from '@/components/v2/organisms/GuidanceModal'

interface SimulationFirstInstructionNextModalProps {
  /** true: オープン, false: クローズ */
  isOpen: boolean
  /** モーダルクローズメソッド */
  onClose: () => void
}

const SimulationFirstInstructionNextModal: FC<SimulationFirstInstructionNextModalProps> = (props) => {
  const { isOpen, onClose } = props

  return (
    <GuidanceModal
      isOpen={isOpen}
      contents={[
        {
          title: 'シミュレーションを作成しましょう',
          text: (
            <div className="pb-[30px]">
              <Typography className="text-12 md:text-16">
                まずは、シミュレーションを作成しましょう。
                <br />
                画面の「設定パネルを開く」から、
                <br />
                車やマイホームの購入など、将来のプランを入力しましょう。
              </Typography>
            </div>
          ),
          src: SimulationItemCreate,
          alt: 'simulation panel',
        },
      ]}
      onClose={onClose}
    />
  )
}

export default SimulationFirstInstructionNextModal
