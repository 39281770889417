import React, { FC, useMemo } from 'react'
import { useForm } from 'react-final-form'
import { buildRangeAgeSelectItems } from '@/utils/formItem'
import { commaNumberMaxLength, composeValidators, required, zeroOrMoreNumber } from '@/utils/validate'
import Typography from '@/components/v2/atoms/Typography'
import SelectBox from '@/components/v2/molecules/SelectBox'
import NumberField from '@/components/v2/molecules/NumberField'
import { RETIREMENT_AGE } from '@/models/commonIncomeChangesModelFunc'

const CurrentWork: FC = () => {
  const { getState } = useForm()
  const { values } = getState()

  const { age } = values
  const ageSelectItems = useMemo(() => buildRangeAgeSelectItems(age, RETIREMENT_AGE), [])

  return (
    <>
      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2">
        <div>
          <Typography
            className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
            isBold
          >
            収入変更予定年齢
          </Typography>
          <Typography className="py-[8px] text-10 text-black-700">
            ※ 転職予定、役職定年予定を追加する場合はその年齢を入力してください
          </Typography>
        </div>
        <div className="flex flex-auto justify-end items-baseline">
          <SelectBox
            isFullWidth
            widthClassName="md:max-w-[100px]"
            name="future_income.quit_current_job_at_age"
            validate={required}
            options={ageSelectItems}
          />
          <Typography
            className="min-w-[fit-content] pl-[8px] text-[14px] text-black-800"
            isBold
          >
            歳
          </Typography>
        </div>
      </div>
      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-center">
        <div>
          <Typography
            className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
            isBold
          >
            現在のお仕事の退職金
          </Typography>
        </div>
        <div className="flex flex-auto justify-end items-baseline">
          <NumberField
            isFullWidth
            name="future_income.severance_pay_manyen"
            defaultValue={values?.severance_pay_manyen ?? 0}
            validate={composeValidators(
              required,
              zeroOrMoreNumber,
              (value: any) => commaNumberMaxLength(value, 6),
            )}
          />
          <Typography
            className="pl-[8px] text-[14px] text-black-800 min-w-[fit-content]"
            isBold
          >
            万円
          </Typography>
        </div>
      </div>
    </>
  )
}

export default CurrentWork

