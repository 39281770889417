import ApiClient from '../apiClient'
import {
  FutureRiskCreateValues,
  FutureRiskUpdateValues,
  FutureRiskDestroyValues,
} from '../../models/futures/futureRisksModel'

export const postFutureRiskV2 = async (values: FutureRiskCreateValues) => {
  return await ApiClient.post('/future_risks', () => {}, values, undefined, 'v2')
}

export const patchFutureRiskV2 = async (values: FutureRiskUpdateValues, id: number) => {
  return await ApiClient.patch(`/future_risks/${id}`, () => {}, values, undefined, 'v2')
}

export const deleteFutureRiskV2 = async (id: number) => {
  return await ApiClient.delete(
    `/future_risks/${id}`,
    () => {},
    undefined,
    undefined,
    'v2',
  )
}
