import { createTheme } from '@material-ui/core/styles'

// MEMO: Zeplin_カラー参考ページ参照

// プライマリーカラー
export const PRIMARY_COLOR = '#90b83f'
export const PRIMARY_DEEP_COLOR = '#709e27'
export const PRIMARY_PALE_COLOR = '#e9f1d9'

// セカンダリーカラー
export const SECONDARY_COLOR = '#2F4960'
export const SECONDARY_DEEP_COLOR = '#1E3141'
export const SECONDARY_PALE_COLOR = '#999999'
export const SECONDARY_OLD_COLOR = '#666666'

// 白・黒
export const WHITE_COLOR = '#fff'
export const BLACK_COLOR = '#000'
export const PALE_BLACK_COLOR = '#4a4a4a'

// グレー
export const VERY_DEEP_GRAY_COLOR = '#525252'
export const DEEP_GRAY_COLOR = '#737373'
export const GRAY_COLOR = '#b3b3b3'
export const LIGHT_GRAY_COLOR = '#c2c2c2'
export const PALE_GRAY_COLOR = '#e0e0e0'
export const FURTHER_PALE_GRAY_COLOR = '#ebebeb'
export const VERY_PALE_GRAY_COLOR = '#f5f5f5'

// 青
export const VERY_DEEP_BLUE_COLOR = '#2248c5'
export const DEEP_BLUE_COLOR = '#3867d6'
export const BLUE_COLOR = '#4b7bec'
export const PALE_BLUE_COLOR = '#88a4e6'
export const VERY_PALE_BLUE_COLOR = '#b8c8f0'
export const ORIGINAL_ICON_PALE_BLUE_COLOR = '#88a4e6'

// 水色
export const DEEP_LITE_BLUE_COLOR = '#3398da'
export const LITE_BLUE_COLOR = '#5cade1'
export const PALE_LITE_BLUE_COLOR = '#85c1e9'
export const VERY_PALE_LITE_BLUE_COLOR = '#add6f0'
export const ORIGINAL_GRAPH_VERY_DEEP_LITE_BLUE_COLOR = '#00bdea'
export const ORIGINAL_GRAPH_DEEP_LITE_BLUE_COLOR = '#def0fd'
export const ORIGINAL_GRAPH_LITE_BLUE_COLOR = '#e7f3fb'
export const ORIGINAL_TABLE_LITE_BLUE_COLOR = '#d6eaf7'
export const ORIGINAL_ICON_LITE_BLUE_COLOR = '#85c1e9'

// アクアマリン
export const AQUA_MARINE_COLOR = '#3fcbba'
export const PALE_AQUA_MARINE_COLOR = '#88d5d0'
export const VERY_PALE_AQUA_MARINE_COLOR = '#b0e3e0'
export const ORIGINAL_GRAPH_AQUA_MARINE_COLOR = '#27a49b'
export const ORIGINAL_TABLE_AQUA_MARINE_COLOR = '#d7f1ef'

// 緑
export const VERY_DEEP_GREEN_COLOR = '#22a74b'
export const DEEP_GREEN_COLOR = '#38bf6b'
export const GREEN_COLOR = '#60cc89'
export const PALE_GREEN_COLOR = '#9bdfb5'
export const HOPE_GREEN_COLOR = '#74c328'
export const GRAPH_PALE_GREEN_COLOR = '#cdefda'
export const ORIGINAL_GREEN_COLOR = '#e2f6e9'
export const ORIGINAL_GRAPH_GREEN_COLOR = '#43de81'
export const ORIGINAL_GRAPH_PALE_GREEN_COLOR = '#ddf9e9'
export const ORIGINAL_ICON_EMERALD_GREEN_COLOR = '#88d5d0'
export const ORIGINAL_ICON_PALE_GREEN_COLOR = '#9bdfb5'

// 黄
export const VERY_DEEP_YELLOW_COLOR = '#f39d1d'
export const DEEP_YELLOW_COLOR = '#f7b731'
export const YELLOW_COLOR = '#fbd331'
export const PALE_YELLOW_COLOR = '#fce06e'
export const VERY_PALE_YELLOW_COLOR = '#fde998'
export const ORIGINAL_TABLE_YELLOW_COLOR = '#fef4cb'
export const ORIGINAL_ICON_YELLOW_COLOR = '#fce06e'

// オレンジ
export const VERY_DEEP_ORANGE_COLOR = '#f3621e'
export const ORANGE_COLOR = '#f89644'
export const PALE_ORANGE_COLOR = '#fab57c'
export const VERY_PALE_ORANGE_COLOR = '#fbcaa1'
export const ORIGINAL_GRAPH_ORANGE_COLOR = '#feedd1'
export const ORIGINAL_TABLE_ORANGE_COLOR = '#fde4d0'
export const ORIGINAL_ICON_PALE_ORANGE_COLOR = '#fab57c'

// 赤
export const VERY_DEEP_RED_COLOR = '#a8263a'
export const DEEP_RED_COLOR = '#eb3b5a'
export const RED_COLOR = '#f65c65'
export const PALE_RED_COLOR = '#f88c93'
export const VERY_PALE_RED_COLOR = '#fbbabe'
export const ORIGINAL_RED_COLOR = '#ff1100'
export const ORIGINAL_GRAPH_RED_COLOR = '#d70c31'
export const ORIGINAL_GRAPH_PALE_RED_COLOR = '#fee2e4'

// ピンク
export const VERY_DEEP_PINK_COLOR = '#bd235d'
export const DEEP_PINK_COLOR = '#bd235d'
export const PINK_COLOR = '#d96197'
export const PALE_PINK_COLOR = '#e79cbe'
export const VERY_PALE_PINK_COLOR = '#f3cdde'
export const ORIGINAL_DEEP_PINK_COLOR = '#fdecef'
export const ORIGINAL_PINK_COLOR = '#fff1f3'
export const ORIGINAL_GRAPH_DEEP_PINK_COLOR = '#fcc6c9'
export const ORIGINAL_GRAPH_PINK_COLOR = '#fff5f5'
export const ORIGINAL_TABLE_PINK_COLOR = '#f9e6ee'
export const ORIGINAL_ICON_PINK_COLOR = '#f88c93'

// ヴァイオレット
export const VIOLET_COLOR = '#a076d9'
export const PALE_VIOLET_COLOR = '#c3a9e7'
export const VERY_PALE_VIOLET_COLOR = '#dbcbf0'
export const ORIGINAL_GRAPH_VIOLET_COLOR = '#7b48c8'
export const ORIGINAL_ICON_PALE_VIOLET_COLOR = '#c3a9e7'
export const ORIGINAL_ICON_LITE_VIOLET_COLOR = '#c8a6d1'

// 紫
export const VERY_DEEP_PURPLE_COLOR = '#8c469f'
export const PALE_PURPLE_COLOR = '#c8a6d1'
export const VERY_PALE_PURPLE_COLOR = '#dac4e0'
export const ORIGINAL_TABLE_PURPLE_COLOR = '#ece1ef'
export const ORIGINAL_ICON_LITE_PURPLE_COLOR = '#e79cbe'

type NestedTableColor = {
  /** 偶数 */
  even: NestedTableItem
  /** 奇数 */
  odd: NestedTableItem
} & { [key: string]: any }

interface NestedTableItem {
  /** 色 */
  color: string
  /** 線 */
  border: string
}

// 入れ子テーブル用カラー
export const NESTED_TABLE_COLORS: NestedTableColor[] = [
  {
    even: {
      color: ORIGINAL_TABLE_AQUA_MARINE_COLOR,
      border: VERY_PALE_AQUA_MARINE_COLOR,
    },
    odd: { color: ORIGINAL_TABLE_PURPLE_COLOR, border: VERY_PALE_PURPLE_COLOR },
  },
  {
    even: { color: ORIGINAL_TABLE_PINK_COLOR, border: VERY_PALE_PINK_COLOR },
    odd: { color: ORIGINAL_TABLE_LITE_BLUE_COLOR, border: VERY_PALE_LITE_BLUE_COLOR },
  },
  {
    even: { color: ORIGINAL_TABLE_YELLOW_COLOR, border: VERY_PALE_YELLOW_COLOR },
    odd: { color: ORIGINAL_TABLE_ORANGE_COLOR, border: VERY_PALE_ORANGE_COLOR },
  },
]

export const CONTAINER_WIDTH = '1280px'

export const theme = createTheme({
  palette: {
    primary: {
      main: PRIMARY_COLOR,
    },
    secondary: {
      main: SECONDARY_COLOR,
    },
  },
  zIndex: {
    snackbar: 3000,
  },
})

export const adminTheme = createTheme({
  palette: {
    primary: {
      main: PRIMARY_COLOR,
    },
    secondary: {
      main: SECONDARY_COLOR,
    },
  },
  zIndex: {
    snackbar: 3000,
  },
  typography: {
    fontSize: 12,
    body1: {
      fontSize: 12,
    },
  },
})

export const widths = {
  adminMainWrapper: '800px',
  adminAccountPage: '1280px',
}
