import ApiClient from '@/api/apiClient'
import { FutureLendHousingResponse } from '@/models/futures/futureLendHousingsModel'

export const postFutureLendHousingV2 = async (values: any) => {
  return await ApiClient.post(
    '/future_lend_housings',
    (response: FutureLendHousingResponse) => response,
    values,
    undefined,
    'v2',
  )
}

export const patchFutureLendHousingV2 = async (values: any, id: number) => {
  return await ApiClient.patch(
    `/future_lend_housings/${id}`,
    (response: FutureLendHousingResponse) => response,
    values,
    undefined,
    'v2',
  )
}

export const deleteFutureLendHousingV2 = async (id: number) => {
  return await ApiClient.delete(
    `/future_lend_housings/${id}`,
    () => {},
    {},
    undefined,
    'v2',
  )
}
