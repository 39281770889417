import React, { FC, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import {
  isEmpty as _isEmpty,
  isNull as _isNull,
  filter as _filter,
  cloneDeep as _cloneDeep,
} from 'lodash'
import moment from 'moment'
import ChevronRightIcon from '@/assets/images/v2/chevron-right.svg'
import HelpCircleIcon from '@/assets/images/v2/help-circle.svg'
import PlusIcon from '@/assets/images/v2/plus-primary.svg'
import Button from '@/components/v2/atoms/Button'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import Grid from '@/components/v2/atoms/Grid'
import TextField from '@/components/v2/atoms/TextField'
import Typography from '@/components/v2/atoms/Typography'
import SelectBox from '@/components/v2/molecules/SelectBox'
import NumberField from '@/components/v2/molecules/NumberField'
import Tooltip from '@/components/v2/atoms/Tooltip'
import { RootState } from '@/store'
import {
  composeValidators,
  zeroOrMoreNumber,
  commaNumberMaxLength,
  required,
} from '@/utils/validate'
import { MONTH_KANJI_SELECT_ITEMS } from '@/constants/formItem/commons'
import {
  extractYearOrMonthFromDate,
  THIS_YEAR_NUMBER,
  convertLocaleString,
  castNumberWithoutOperator,
  convertDateMonth,
} from '@/models/commonsModelFunc'
import { buildTwoTypesRangeYearSelectItems } from '@/utils/formItem'
import { Decorator } from 'final-form'
import createDecorator from 'final-form-calculate'
import { InputMonthlyCostCommonValues } from '@/models/inputs/inputMonthlyCostsModel'
import {
  createInputMonthlyCost,
  fetchInputMonthlyCostV2,
  updateInputMonthlyCost,
} from '@/containers/inputs/inputMonthlyCostsSlice'
import { fetchAggBalanceLastMonth } from '@/containers/aggBalanceMonthliesSlice'
import {
  renderPastMonthlyCostLabel,
  sumPastMonthlyCost,
} from '@/models/aggBalanceMonthliesModelFunc'
import {
  INPUT_MONTHLY_COST_SUM_FIELDS,
  sumInputMonthlyCost,
  convertMoneytreeAverageMonths,
} from '@/models/inputs/inputMonthlyCostsModelFunc'
import clsx from 'clsx'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import WholeSubmitErrorMessage from '@/components/v2/molecules/WholeSubmitErrorMessage'
import LoadingOverlay from '@/components/v1/atoms/LoadingOverlay'
import SubmitErrorMessage from '@/components/v2/molecules/SubmitErrorMessage'
import { updateResetFlg } from '@/containers/futures/futureBasesSlice'

const classes = {
  gridTable:
    'grid-cols-1 md:grid-cols-3 gap-x-[40px] md:gap-y-16 items-center pb-16 text-14 md:border-b border-black-300',
  gridTableItem_title: 'flex items-center mt-24 md:mt-0',
  gridTableItem_expect: 'pb-10 md:pb-0 border-b md:border-b-0 border-black-300',
  gridTableItem_average: 'flex justify-between md:justify-end items-center mt-8 md:mt-0',
  gridTableBorder: 'hidden md:block col-span-full border-b border-black-300',
}

const calculator: Decorator<any> = createDecorator(
  {
    field: INPUT_MONTHLY_COST_SUM_FIELDS,
    updates: {
      total: (_, allValues: InputMonthlyCostCommonValues) =>
        sumInputMonthlyCost(allValues),
    },
  },
  {
    field: /input_monthly_cost\.input_scholarships_attributes\[\d\]scholarship_monthly/,
    updates: {
      total: (_, allValues: InputMonthlyCostCommonValues) =>
        sumInputMonthlyCost(allValues),
    },
  },
  {
    field: /input_monthly_cost\.input_monthly_cost_others_attributes\[\d\]cost_monthly/,
    updates: {
      total: (_, allValues: InputMonthlyCostCommonValues) =>
        sumInputMonthlyCost(allValues),
    },
  },
)

interface MyDataMonthlyCostFormProps {
  onClose: () => void
  onSubmit: () => void
}

const MyDataMonthlyCostForm: FC<MyDataMonthlyCostFormProps> = (props) => {
  const dispatch = useDispatch()
  const { showFlashMsg } = useFlashAlert()
  const { account } = useSelector((state: RootState) => state.accounts)
  const { inputMonthlyCost } = useSelector((state: RootState) => state.inputMonthlyCosts)

  const isEdit = useMemo(() => !!inputMonthlyCost, [inputMonthlyCost])

  const initialValues: any = useMemo(
    () =>
      isEdit
        ? {
            input_monthly_cost: {
              ...inputMonthlyCost,
              input_scholarships_attributes: !inputMonthlyCost
                ? []
                : inputMonthlyCost.input_scholarships?.map((item) => ({
                    scholarship_finish_year: extractYearOrMonthFromDate(
                      item.scholarship_finish_at,
                      'year',
                    ),
                    scholarship_finish_month: extractYearOrMonthFromDate(
                      item.scholarship_finish_at,
                      'month',
                    ),
                    scholarship_monthly: item.scholarship_monthly,
                  })),
              input_monthly_cost_others_attributes:
                inputMonthlyCost?.input_monthly_cost_others,
            },
          }
        : { input_monthly_cost: {} },
    [inputMonthlyCost],
  )

  const { aggBalanceMonthly } = useSelector(
    (state: RootState) => state.aggBalanceMonthlies,
  )

  // isLoadingFlag
  const isLoadingAggBalanceMonthlies = useSelector(
    (state: RootState) => state.aggBalanceMonthlies.isLoading,
  )
  const isLoadingInputMonthlyCosts = useSelector(
    (state: RootState) => state.inputMonthlyCosts.isLoading,
  )

  useEffect(() => {
    if (!inputMonthlyCost) dispatch(fetchInputMonthlyCostV2())
    if (!aggBalanceMonthly) dispatch(fetchAggBalanceLastMonth())
  }, [])

  const handleCalcTotalValue = (
    values: InputMonthlyCostCommonValues,
    change: (key: string, value: number) => void,
    name: 'scholarship' | 'other',
  ) => {
    const {
      input_scholarships_attributes,
      input_monthly_cost_others_attributes,
    } = values.input_monthly_cost

    const lastScholarshipMonthly =
      input_scholarships_attributes[input_scholarships_attributes.length - 1]
        ?.scholarship_monthly
    const lastCostMonthly =
      input_monthly_cost_others_attributes[
        input_monthly_cost_others_attributes.length - 1
      ]?.cost_monthly

    return name === 'scholarship'
      ? change(
          'total',
          sumInputMonthlyCost(values) - castNumberWithoutOperator(lastScholarshipMonthly),
        )
      : change(
          'total',
          sumInputMonthlyCost(values) - castNumberWithoutOperator(lastCostMonthly),
        )
  }

  const handleSubmit = (values: any) => {
    const filteredCostOthersAttributes = _filter(
      values.input_monthly_cost.input_monthly_cost_others_attributes,
      (item) => !_isNull(item.cost_monthly),
    )
    const filteredScholarshipsAttributes = _filter(
      values.input_monthly_cost.input_scholarships_attributes,
      (item) => !_isNull(item.scholarship_monthly),
    )

    const newValues = {
      input_monthly_cost: {
        ..._cloneDeep(values.input_monthly_cost),
        moneytree_average_months:
          values.input_monthly_cost.moneytree_average_months || null,
        input_scholarships_attributes: !_isEmpty(filteredScholarshipsAttributes[0])
          ? values.input_monthly_cost.input_scholarships_attributes?.map((item: any) => ({
              scholarship_finish_at: convertDateMonth(
                item.scholarship_finish_year,
                item.scholarship_finish_month,
              ),
              scholarship_monthly: item.scholarship_monthly,
            }))
          : [],
        input_monthly_cost_others_attributes: !_isEmpty(filteredCostOthersAttributes[0])
          ? filteredCostOthersAttributes
          : [],
      },
    }

    const commonCallback = () => {
      showFlashMsg(isEdit ? '更新しました' : '作成しました')
      dispatch(fetchInputMonthlyCostV2())
      dispatch(updateResetFlg())
      props.onSubmit()
    }

    return new Promise((resolve) =>
      isEdit
        ? dispatch(updateInputMonthlyCost(newValues, () => commonCallback(), resolve))
        : dispatch(createInputMonthlyCost(newValues, () => commonCallback(), resolve)),
    )
  }

  const handleCopyCost = (change: (key: string, value: any) => void) => {
    change(
      'input_monthly_cost.food_expenses_monthly',
      aggBalanceMonthly?.food_expenses ? aggBalanceMonthly?.food_expenses : 0,
    )
    change(
      'input_monthly_cost.utility_bills_monthly',
      aggBalanceMonthly?.utility_bills ? aggBalanceMonthly?.utility_bills : 0,
    )
    change(
      'input_monthly_cost.daily_necessities_cost_monthly',
      aggBalanceMonthly?.daily_necessities_cost
        ? aggBalanceMonthly?.daily_necessities_cost
        : 0,
    )
    change(
      'input_monthly_cost.transport_monthly',
      aggBalanceMonthly?.transport ? aggBalanceMonthly?.transport : 0,
    )
    change(
      'input_monthly_cost.clothing_cost_monthly',
      aggBalanceMonthly?.clothing_cost ? aggBalanceMonthly?.clothing_cost : 0,
    )
    change(
      'input_monthly_cost.hobby_expenses_monthly',
      aggBalanceMonthly?.hobby_expenses ? aggBalanceMonthly?.hobby_expenses : 0,
    )
    change(
      'input_monthly_cost.communication_cost_monthly',
      aggBalanceMonthly?.communication_cost ? aggBalanceMonthly?.communication_cost : 0,
    )
    change(
      'input_monthly_cost.entertainment_expenses_monthly',
      aggBalanceMonthly?.entertainment_expenses
        ? aggBalanceMonthly?.entertainment_expenses
        : 0,
    )
    change(
      'input_monthly_cost.medical_expenses_monthly',
      aggBalanceMonthly?.medical_expenses ? aggBalanceMonthly?.medical_expenses : 0,
    )
    change(
      'input_monthly_cost.pocket_money_monthly',
      aggBalanceMonthly?.pocket_money ? aggBalanceMonthly?.pocket_money : 0,
    )
    change(
      'input_monthly_cost.unknown_cost_monthly',
      Number(aggBalanceMonthly?.uncategorized_cash) +
        Number(aggBalanceMonthly?.uncategorized_expense) +
        Number(aggBalanceMonthly?.other_cost),
    )
  }

  const renderExpectedLivingExpensesLabel = () => (
    <Typography className="md:hidden text-12">
      今後想定する
      <span className="inline-block">金額</span>
    </Typography>
  )

  const renderAverageLivingExpensesLabel = () => (
    <div className="md:hidden flex">
      <Typography className="text-12 mr-5">
        過去３ヶ月の
        <span className="inline-block">平均金額</span>
      </Typography>
      <div>
        <Tooltip
          item="登録から３か月未満の場合は、登録開始から前月までの平均となります。"
          itemClassName="w-160 p-8 text-10 text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
        >
          <HelpCircleIcon />
        </Tooltip>
      </div>
    </div>
  )

  return (
    <>
      <LoadingOverlay
        isLoading={isLoadingAggBalanceMonthlies || isLoadingInputMonthlyCosts}
      />

      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        mutators={{ ...arrayMutators }}
        decorators={[calculator]}
        render={({
          handleSubmit,
          values,
          errors,
          submitErrors,
          submitFailed,
          form: {
            change,
            mutators: { push },
          },
        }) => {
          const { total } = values
          const { moneytree_average_months } = values.input_monthly_cost || {}

          return (
            <form className="text-black-900" onSubmit={handleSubmit}>
              {account['has_connected_with_moneytree?'] && (
                <Grid className="pb-[64px] grid-cols-1 gap-x-[40px] gap-y-[16px]">
                  <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                    <Typography
                      className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800 mr-10"
                      isBold
                    >
                      基本生活費の設定方法
                    </Typography>

                    <SelectBox
                      name="input_monthly_cost.moneytree_average_months"
                      isPlaceholderHidden
                      options={[
                        { value: '', label: '手動で設定する' },
                        { value: 1, label: '過去1ヶ月分の平均' },
                        { value: 3, label: '過去3ヶ月分の平均' },
                        { value: 6, label: '過去6ヶ月分の平均' },
                        { value: 12, label: '過去1年分の平均' },
                        { value: 24, label: '過去2年分の平均' },
                        { value: 36, label: '過去3年分の平均' },
                      ]}
                      isFullWidth
                      onChange={(event: any) => {
                        const targetData = moment()
                          .subtract(event.target.value, 'months')
                          .startOf('month')
                          .format('YYYY/MM/DD')

                        dispatch(fetchAggBalanceLastMonth(String(targetData)))
                      }}
                    />
                  </div>
                </Grid>
              )}

              {!moneytree_average_months && (
                <Button
                  className="h-37 md:mb-40 ml-auto mr-0 px-17 text-secondary-500 border border-secondary-500 rounded-8"
                  onClick={() => handleCopyCost(change)}
                >
                  <i className="ico edit text-24" />
                  <Typography
                    className="ml-10 bottom-2 flex-auto relative mr-0 text-12 md:text-14"
                    isBold
                  >
                    過去3ヶ月の基本生活費の値をコピーする
                  </Typography>
                </Button>
              )}

              <Grid className={classes.gridTable}>
                <div className="hidden md:block" />
                {!moneytree_average_months && (
                  <div className="hidden md:block">
                    <Typography isBold>今後想定する基本生活費</Typography>
                  </div>
                )}
                <div className="hidden md:block">
                  <div className="flex justify-end">
                    <Typography isBold>
                      {`${convertMoneytreeAverageMonths(
                        moneytree_average_months,
                      )}基本生活費`}
                    </Typography>
                    <div>
                      <Tooltip
                        item="登録から３か月未満の場合は、登録開始から前月までの平均となります。"
                        itemClassName="left-[-26px] w-160 p-8 text-10 text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                      >
                        <HelpCircleIcon />
                      </Tooltip>
                    </div>
                  </div>
                </div>

                <div className={classes.gridTableBorder} />

                <div className={classes.gridTableItem_title}>
                  <Typography className="mr-5" isBold>
                    食費
                  </Typography>
                  <Tooltip
                    item="例) 食材、お弁当、外食代など"
                    itemClassName="w-[106px] md:w-[160px] p-8 text-10 text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                  >
                    <HelpCircleIcon />
                  </Tooltip>
                </div>
                {!moneytree_average_months && (
                  <div className={classes.gridTableItem_expect}>
                    <div className="flex justify-between items-center">
                      {renderExpectedLivingExpensesLabel()}
                      <div className="flex items-baseline">
                        <NumberField
                          name="input_monthly_cost.food_expenses_monthly"
                          validate={composeValidators(
                            required,
                            zeroOrMoreNumber,
                            (value: any) => commaNumberMaxLength(value, 10),
                          )}
                          defaultValue={initialValues ?? 0}
                          widthClassName="max-w-[120px]"
                        />
                        <Typography className="min-w-[fit-content] pl-8">
                          円/月
                        </Typography>
                      </div>
                    </div>
                  </div>
                )}

                {!moneytree_average_months ? (
                  <>
                    <div className="flex justify-between md:justify-end items-center mt-8 md:mt-0">
                      {renderAverageLivingExpensesLabel()}
                      <div className="flex items-baseline">
                        <Typography className="min-w-[fit-content] pl-8">
                          {renderPastMonthlyCostLabel(aggBalanceMonthly?.food_expenses)}
                        </Typography>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="flex justify-end items-center mt-8 md:mt-0">
                      <div className="flex items-baseline">
                        <Typography className="min-w-[fit-content] pl-8">
                          {renderPastMonthlyCostLabel(aggBalanceMonthly?.food_expenses)}
                        </Typography>
                      </div>
                    </div>
                  </>
                )}

                <div className={classes.gridTableBorder} />

                <div className={classes.gridTableItem_title}>
                  <Typography className="mr-5" isBold>
                    水道光熱費
                  </Typography>
                  <Tooltip
                    item="例) 水道、電気、ガス代など"
                    itemClassName="w-160 p-8 text-10 text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                  >
                    <HelpCircleIcon />
                  </Tooltip>
                </div>
                {!moneytree_average_months && (
                  <div className={classes.gridTableItem_expect}>
                    <div className="flex justify-between items-center">
                      {renderExpectedLivingExpensesLabel()}
                      <div className="flex items-baseline">
                        <NumberField
                          name="input_monthly_cost.utility_bills_monthly"
                          validate={composeValidators(
                            required,
                            zeroOrMoreNumber,
                            (value: any) => commaNumberMaxLength(value, 10),
                          )}
                          defaultValue={initialValues ?? 0}
                          widthClassName="max-w-[120px]"
                        />
                        <Typography className="min-w-[fit-content] pl-8">
                          円/月
                        </Typography>
                      </div>
                    </div>
                  </div>
                )}

                {!moneytree_average_months ? (
                  <>
                    <div className="flex justify-between md:justify-end items-center mt-8 md:mt-0">
                      {renderAverageLivingExpensesLabel()}
                      <div className="flex items-baseline">
                        <Typography className="min-w-[fit-content] pl-8">
                          {renderPastMonthlyCostLabel(aggBalanceMonthly?.utility_bills)}
                        </Typography>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="flex justify-end items-center mt-8 md:mt-0">
                      <div className="flex items-baseline">
                        <Typography className="min-w-[fit-content] pl-8">
                          {renderPastMonthlyCostLabel(aggBalanceMonthly?.utility_bills)}
                        </Typography>
                      </div>
                    </div>
                  </>
                )}

                <div className={classes.gridTableBorder} />

                <div className={classes.gridTableItem_title}>
                  <Typography className="mr-5" isBold>
                    日用品雑貨費
                  </Typography>
                  <Tooltip
                    item="例) シャンプー、ティッシュペーパー、おむつ代"
                    itemClassName="w-160 p-8 text-10 text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                  >
                    <HelpCircleIcon />
                  </Tooltip>
                </div>
                {!moneytree_average_months && (
                  <div className={classes.gridTableItem_expect}>
                    <div className="flex justify-between items-center">
                      {renderExpectedLivingExpensesLabel()}
                      <div className="flex items-baseline">
                        <NumberField
                          name="input_monthly_cost.daily_necessities_cost_monthly"
                          validate={composeValidators(
                            required,
                            zeroOrMoreNumber,
                            (value: any) => commaNumberMaxLength(value, 10),
                          )}
                          defaultValue={initialValues ?? 0}
                          widthClassName="max-w-[120px]"
                        />
                        <Typography className="min-w-[fit-content] pl-8">
                          円/月
                        </Typography>
                      </div>
                    </div>
                  </div>
                )}

                {!moneytree_average_months ? (
                  <>
                    <div className="flex justify-between md:justify-end items-center mt-8 md:mt-0">
                      {renderAverageLivingExpensesLabel()}
                      <div className="flex items-baseline">
                        <Typography className="min-w-[fit-content] pl-8">
                          {renderPastMonthlyCostLabel(
                            aggBalanceMonthly?.daily_necessities_cost,
                          )}
                        </Typography>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="flex justify-end items-center mt-8 md:mt-0">
                      <div className="flex items-baseline">
                        <Typography className="min-w-[fit-content] pl-8">
                          {renderPastMonthlyCostLabel(
                            aggBalanceMonthly?.daily_necessities_cost,
                          )}
                        </Typography>
                      </div>
                    </div>
                  </>
                )}

                <div className={classes.gridTableBorder} />

                <div className={classes.gridTableItem_title}>
                  <Typography className="mr-5" isBold>
                    交通費
                  </Typography>
                  <Tooltip
                    item="例) 電車賃、バス代"
                    itemClassName="w-160 p-8 text-10 text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                  >
                    <HelpCircleIcon />
                  </Tooltip>
                </div>
                {!moneytree_average_months && (
                  <div className={classes.gridTableItem_expect}>
                    <div className="flex justify-between items-center">
                      {renderExpectedLivingExpensesLabel()}
                      <div className="flex items-baseline">
                        <NumberField
                          name="input_monthly_cost.transport_monthly"
                          validate={composeValidators(
                            required,
                            zeroOrMoreNumber,
                            (value: any) => commaNumberMaxLength(value, 10),
                          )}
                          defaultValue={initialValues ?? 0}
                          widthClassName="max-w-[120px]"
                        />
                        <Typography className="min-w-[fit-content] pl-8">
                          円/月
                        </Typography>
                      </div>
                    </div>
                  </div>
                )}

                {!moneytree_average_months ? (
                  <>
                    <div className="flex justify-between md:justify-end items-center mt-8 md:mt-0">
                      {renderAverageLivingExpensesLabel()}
                      <div className="flex items-baseline">
                        <Typography className="min-w-[fit-content] pl-8">
                          {renderPastMonthlyCostLabel(aggBalanceMonthly?.transport)}
                        </Typography>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="flex justify-end items-center mt-8 md:mt-0">
                      <div className="flex items-baseline">
                        <Typography className="min-w-[fit-content] pl-8">
                          {renderPastMonthlyCostLabel(aggBalanceMonthly?.transport)}
                        </Typography>
                      </div>
                    </div>
                  </>
                )}

                <div className={classes.gridTableBorder} />

                <div className={classes.gridTableItem_title}>
                  <Typography className="mr-5" isBold>
                    被服費
                  </Typography>
                  <Tooltip
                    item="例) 洋服、靴、肌着、子供服代など"
                    itemClassName="w-160 p-8 text-10 text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                  >
                    <HelpCircleIcon />
                  </Tooltip>
                </div>
                {!moneytree_average_months && (
                  <div className={classes.gridTableItem_expect}>
                    <div className="flex justify-between items-center">
                      {renderExpectedLivingExpensesLabel()}
                      <div className="flex items-baseline">
                        <NumberField
                          name="input_monthly_cost.clothing_cost_monthly"
                          validate={composeValidators(
                            required,
                            zeroOrMoreNumber,
                            (value: any) => commaNumberMaxLength(value, 10),
                          )}
                          defaultValue={initialValues ?? 0}
                          widthClassName="max-w-[120px]"
                        />
                        <Typography className="min-w-[fit-content] pl-8">
                          円/月
                        </Typography>
                      </div>
                    </div>
                  </div>
                )}

                {!moneytree_average_months ? (
                  <>
                    <div className="flex justify-between md:justify-end items-center mt-8 md:mt-0">
                      {renderAverageLivingExpensesLabel()}
                      <div className="flex items-baseline">
                        <Typography className="min-w-[fit-content] pl-8">
                          {renderPastMonthlyCostLabel(aggBalanceMonthly?.clothing_cost)}
                        </Typography>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="flex justify-end items-center mt-8 md:mt-0">
                      <div className="flex items-baseline">
                        <Typography className="min-w-[fit-content] pl-8">
                          {renderPastMonthlyCostLabel(aggBalanceMonthly?.clothing_cost)}
                        </Typography>
                      </div>
                    </div>
                  </>
                )}

                <div className={classes.gridTableBorder} />

                <div className={classes.gridTableItem_title}>
                  <Typography className="mr-5" isBold>
                    趣味・娯楽費
                  </Typography>
                  <Tooltip
                    item="例) 入園料、ユニフォーム、道具代など"
                    itemClassName="w-160 p-8 text-10 text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                  >
                    <HelpCircleIcon />
                  </Tooltip>
                </div>
                {!moneytree_average_months && (
                  <div className={classes.gridTableItem_expect}>
                    <div className="flex justify-between items-center">
                      {renderExpectedLivingExpensesLabel()}
                      <div className="flex items-baseline">
                        <NumberField
                          name="input_monthly_cost.hobby_expenses_monthly"
                          validate={composeValidators(
                            required,
                            zeroOrMoreNumber,
                            (value: any) => commaNumberMaxLength(value, 10),
                          )}
                          defaultValue={initialValues ?? 0}
                          widthClassName="max-w-[120px]"
                        />
                        <Typography className="min-w-[fit-content] pl-8">
                          円/月
                        </Typography>
                      </div>
                    </div>
                  </div>
                )}

                {!moneytree_average_months ? (
                  <>
                    <div className="flex justify-between md:justify-end items-center mt-8 md:mt-0">
                      {renderAverageLivingExpensesLabel()}
                      <div className="flex items-baseline">
                        <Typography className="min-w-[fit-content] pl-8">
                          {renderPastMonthlyCostLabel(aggBalanceMonthly?.hobby_expenses)}
                        </Typography>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="flex justify-end items-center mt-8 md:mt-0">
                      <div className="flex items-baseline">
                        <Typography className="min-w-[fit-content] pl-8">
                          {renderPastMonthlyCostLabel(aggBalanceMonthly?.hobby_expenses)}
                        </Typography>
                      </div>
                    </div>
                  </>
                )}

                <div className={classes.gridTableBorder} />

                <div className={classes.gridTableItem_title}>
                  <Typography className="mr-5" isBold>
                    通信費
                  </Typography>
                  <Tooltip
                    item="例) 携帯電話、固定電話、プロバイダー、NHK受信料など"
                    itemClassName="w-160 p-8 text-10 text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                  >
                    <HelpCircleIcon />
                  </Tooltip>
                </div>
                {!moneytree_average_months && (
                  <div className={classes.gridTableItem_expect}>
                    <div className="flex justify-between items-center">
                      {renderExpectedLivingExpensesLabel()}
                      <div className="flex items-baseline">
                        <NumberField
                          name="input_monthly_cost.communication_cost_monthly"
                          validate={composeValidators(
                            required,
                            zeroOrMoreNumber,
                            (value: any) => commaNumberMaxLength(value, 10),
                          )}
                          defaultValue={initialValues ?? 0}
                          widthClassName="max-w-[120px]"
                        />
                        <Typography className="min-w-[fit-content] pl-8">
                          円/月
                        </Typography>
                      </div>
                    </div>
                  </div>
                )}

                {!moneytree_average_months ? (
                  <>
                    <div className="flex justify-between md:justify-end items-center mt-8 md:mt-0">
                      {renderAverageLivingExpensesLabel()}
                      <div className="flex items-baseline">
                        <Typography className="min-w-[fit-content] pl-8">
                          {renderPastMonthlyCostLabel(
                            aggBalanceMonthly?.communication_cost,
                          )}
                        </Typography>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="flex justify-end items-center mt-8 md:mt-0">
                      <div className="flex items-baseline">
                        <Typography className="min-w-[fit-content] pl-8">
                          {renderPastMonthlyCostLabel(
                            aggBalanceMonthly?.communication_cost,
                          )}
                        </Typography>
                      </div>
                    </div>
                  </>
                )}

                <div className={classes.gridTableBorder} />

                <div className={classes.gridTableItem_title}>
                  <Typography className="mr-5" isBold>
                    交際費
                  </Typography>
                  <Tooltip
                    item="例) プレゼント、お土産、お見舞い、お中元、お歳暮代など"
                    itemClassName="w-160 p-8 text-10 text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                  >
                    <HelpCircleIcon />
                  </Tooltip>
                </div>
                {!moneytree_average_months && (
                  <div className={classes.gridTableItem_expect}>
                    <div className="flex justify-between items-center">
                      {renderExpectedLivingExpensesLabel()}
                      <div className="flex items-baseline">
                        <NumberField
                          name="input_monthly_cost.entertainment_expenses_monthly"
                          validate={composeValidators(
                            required,
                            zeroOrMoreNumber,
                            (value: any) => commaNumberMaxLength(value, 10),
                          )}
                          defaultValue={initialValues ?? 0}
                          widthClassName="max-w-[120px]"
                        />
                        <Typography className="min-w-[fit-content] pl-8">
                          円/月
                        </Typography>
                      </div>
                    </div>
                  </div>
                )}

                {!moneytree_average_months ? (
                  <>
                    <div className="flex justify-between md:justify-end items-center mt-8 md:mt-0">
                      {renderAverageLivingExpensesLabel()}
                      <div className="flex items-baseline">
                        <Typography className="min-w-[fit-content] pl-8">
                          {renderPastMonthlyCostLabel(
                            aggBalanceMonthly?.entertainment_expenses,
                          )}
                        </Typography>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="flex justify-end items-center mt-8 md:mt-0">
                      <div className="flex items-baseline">
                        <Typography className="min-w-[fit-content] pl-8">
                          {renderPastMonthlyCostLabel(
                            aggBalanceMonthly?.entertainment_expenses,
                          )}
                        </Typography>
                      </div>
                    </div>
                  </>
                )}

                <div className={classes.gridTableBorder} />

                <div className={classes.gridTableItem_title}>
                  <Typography className="mr-5" isBold>
                    医療費
                  </Typography>
                  <Tooltip
                    item="例) 診察費、入院費など"
                    itemClassName="w-160 p-8 text-10 text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                  >
                    <HelpCircleIcon />
                  </Tooltip>
                </div>
                {!moneytree_average_months && (
                  <div className={classes.gridTableItem_expect}>
                    <div className="flex justify-between items-center">
                      {renderExpectedLivingExpensesLabel()}
                      <div className="flex items-baseline">
                        <NumberField
                          name="input_monthly_cost.medical_expenses_monthly"
                          validate={composeValidators(
                            required,
                            zeroOrMoreNumber,
                            (value: any) => commaNumberMaxLength(value, 10),
                          )}
                          defaultValue={initialValues ?? 0}
                          widthClassName="max-w-[120px]"
                        />
                        <Typography className="min-w-[fit-content] pl-8">
                          円/月
                        </Typography>
                      </div>
                    </div>
                  </div>
                )}

                {!moneytree_average_months ? (
                  <>
                    <div className="flex justify-between md:justify-end items-center mt-8 md:mt-0">
                      {renderAverageLivingExpensesLabel()}
                      <div className="flex items-baseline">
                        <Typography className="min-w-[fit-content] pl-8">
                          {renderPastMonthlyCostLabel(
                            aggBalanceMonthly?.medical_expenses,
                          )}
                        </Typography>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="flex justify-end items-center mt-8 md:mt-0">
                      <div className="flex items-baseline">
                        <Typography className="min-w-[fit-content] pl-8">
                          {renderPastMonthlyCostLabel(
                            aggBalanceMonthly?.medical_expenses,
                          )}
                        </Typography>
                      </div>
                    </div>
                  </>
                )}

                <div className={classes.gridTableBorder} />

                <div className={classes.gridTableItem_title}>
                  <Typography className="mr-5" isBold>
                    こづかい
                  </Typography>
                  <Tooltip
                    item="例) おこづかい代"
                    itemClassName="w-160 p-8 text-10 text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                  >
                    <HelpCircleIcon />
                  </Tooltip>
                </div>
                {!moneytree_average_months && (
                  <div className={classes.gridTableItem_expect}>
                    <div className="flex justify-between items-center">
                      {renderExpectedLivingExpensesLabel()}
                      <div className="flex items-baseline">
                        <NumberField
                          name="input_monthly_cost.pocket_money_monthly"
                          validate={composeValidators(
                            required,
                            zeroOrMoreNumber,
                            (value: any) => commaNumberMaxLength(value, 10),
                          )}
                          defaultValue={initialValues ?? 0}
                          widthClassName="max-w-[120px]"
                        />
                        <Typography className="min-w-[fit-content] pl-8">
                          円/月
                        </Typography>
                      </div>
                    </div>
                  </div>
                )}

                {!moneytree_average_months ? (
                  <>
                    <div className="flex justify-between md:justify-end items-center mt-8 md:mt-0">
                      {renderAverageLivingExpensesLabel()}
                      <div className="flex items-baseline">
                        <Typography className="min-w-[fit-content] pl-8">
                          {renderPastMonthlyCostLabel(aggBalanceMonthly?.pocket_money)}
                        </Typography>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="flex justify-end items-center mt-8 md:mt-0">
                      <div className="flex items-baseline">
                        <Typography className="min-w-[fit-content] pl-8">
                          {renderPastMonthlyCostLabel(aggBalanceMonthly?.pocket_money)}
                        </Typography>
                      </div>
                    </div>
                  </>
                )}

                <div className={classes.gridTableBorder} />

                {!moneytree_average_months && (
                  <>
                    <div className={classes.gridTableItem_title}>
                      <Typography className="mr-5" isBold>
                        奨学金
                      </Typography>
                    </div>
                    <div
                      className={clsx(
                        classes.gridTableItem_expect,
                        'md:col-span-2 space-y-16',
                      )}
                    >
                      <FieldArray name="input_monthly_cost.input_scholarships_attributes">
                        {({ fields }) =>
                          fields.map((name, index) => (
                            <div key={index} className="mt-16 md:mt-0 space-y-7">
                              <div className="md:flex items-center">
                                <div className="flex items-center mr-[30px]">
                                  <SelectBox
                                    placeholder="年を選択"
                                    name={`${name}scholarship_finish_year`}
                                    options={buildTwoTypesRangeYearSelectItems(
                                      THIS_YEAR_NUMBER,
                                      THIS_YEAR_NUMBER + 15,
                                    )}
                                    validate={required}
                                  />
                                  <SelectBox
                                    className="mx-5"
                                    widthClassName="w-[70px]"
                                    placeholder="月"
                                    name={`${name}scholarship_finish_month`}
                                    options={MONTH_KANJI_SELECT_ITEMS}
                                    validate={required}
                                  />
                                  <Typography className="min-w-[fit-content]">
                                    まで
                                  </Typography>
                                </div>

                                <div className="flex items-center mt-[10px] md:mt-0">
                                  <NumberField
                                    isFullWidth
                                    name={`${name}scholarship_monthly`}
                                    widthClassName="md:w-[120px]"
                                    validate={composeValidators(
                                      required,
                                      zeroOrMoreNumber,
                                      (value: any) => commaNumberMaxLength(value, 10),
                                    )}
                                  />
                                  <Typography className="ml-5">円/月</Typography>
                                  <div
                                    onClick={() => {
                                      fields.remove(index)
                                      handleCalcTotalValue(values, change, 'scholarship')
                                    }}
                                  >
                                    <i className="ico close ml-[5px] text-24 text-black-700" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        }
                      </FieldArray>

                      <Button
                        className="w-full h-53 mt-14 md:mt-0 border border-dashed border-primary-500 text-14 text-primary-500 hover:bg-primary-50"
                        onClick={() =>
                          push('input_monthly_cost.input_scholarships_attributes', {})
                        }
                      >
                        <PlusIcon className="mr-8" />
                        <Typography isBold>奨学金の入力枠を追加する</Typography>
                      </Button>
                    </div>
                    <div className={classes.gridTableBorder} />
                  </>
                )}

                {!moneytree_average_months && (
                  <>
                    <div className={classes.gridTableItem_title}>
                      <Typography className="mr-5" isBold>
                        その他月単位の費用
                      </Typography>
                      <Tooltip
                        item="例)その他雑費など"
                        itemClassName="w-160 p-8 text-10 text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                      >
                        <HelpCircleIcon />
                      </Tooltip>
                    </div>
                    <div
                      className={clsx(
                        classes.gridTableItem_expect,
                        'md:col-span-2 space-y-16',
                      )}
                    >
                      <FieldArray name="input_monthly_cost.input_monthly_cost_others_attributes">
                        {({ fields }) =>
                          fields.map((name, index) => (
                            <div key={index} className="mt-16 md:mt-0 space-y-7">
                              <div className="md:flex items-center">
                                <TextField
                                  isFullWidth
                                  type="text"
                                  name={`${name}journal_name`}
                                  widthClassName="md:max-w-[293px]"
                                  placeholder="項目名を入力"
                                />
                                <div className="flex items-center mt-[10px] md:mt-0">
                                  <NumberField
                                    isFullWidth
                                    name={`${name}cost_monthly`}
                                    widthClassName="md:w-[120px]"
                                    validate={composeValidators(
                                      required,
                                      zeroOrMoreNumber,
                                      (value: any) => commaNumberMaxLength(value, 10),
                                    )}
                                  />
                                  <Typography className="ml-5">円/月</Typography>
                                  <div
                                    onClick={() => {
                                      fields.remove(index)
                                      handleCalcTotalValue(values, change, 'other')
                                    }}
                                  >
                                    <i className="ico close ml-[5px] text-24 text-black-700" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        }
                      </FieldArray>

                      <Button
                        className="w-full h-53 mt-14 md:mt-0 border border-dashed border-primary-500 text-14 text-primary-500 hover:bg-primary-50"
                        onClick={() =>
                          push(
                            'input_monthly_cost.input_monthly_cost_others_attributes',
                            {},
                          )
                        }
                      >
                        <PlusIcon className="mr-8" />
                        <Typography isBold>その他の入力枠を追加する</Typography>
                      </Button>
                    </div>

                    <div className={classes.gridTableBorder} />
                  </>
                )}

                <div className={classes.gridTableItem_title}>
                  <Typography className="mr-5" isBold>
                    未分類・使途不明金
                  </Typography>
                </div>
                {!moneytree_average_months && (
                  <div className={classes.gridTableItem_expect}>
                    <div className="flex justify-between items-center">
                      {renderExpectedLivingExpensesLabel()}
                      <div className="flex items-baseline">
                        <NumberField
                          name="input_monthly_cost.unknown_cost_monthly"
                          validate={composeValidators(
                            required,
                            zeroOrMoreNumber,
                            (value: any) => commaNumberMaxLength(value, 10),
                          )}
                          defaultValue={initialValues ?? 0}
                          widthClassName="max-w-[120px]"
                        />
                        <Typography className="min-w-[fit-content] pl-8">
                          円/月
                        </Typography>
                      </div>
                    </div>
                  </div>
                )}

                {!moneytree_average_months ? (
                  <>
                    <div className="flex justify-between md:justify-end items-center mt-8 md:mt-0">
                      {renderAverageLivingExpensesLabel()}
                      <div className="flex items-baseline">
                        <Typography className="min-w-[fit-content] pl-8">
                          {renderPastMonthlyCostLabel(
                            Number(aggBalanceMonthly?.uncategorized_cash) +
                              Number(aggBalanceMonthly?.uncategorized_expense) +
                              Number(aggBalanceMonthly?.other_cost),
                          )}
                        </Typography>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="flex justify-end items-center mt-8 md:mt-0">
                      <div className="flex items-baseline">
                        <Typography className="min-w-[fit-content] pl-8">
                          {renderPastMonthlyCostLabel(
                            Number(aggBalanceMonthly?.uncategorized_cash) +
                              Number(aggBalanceMonthly?.uncategorized_expense) +
                              Number(aggBalanceMonthly?.other_cost),
                          )}
                        </Typography>
                      </div>
                    </div>
                  </>
                )}
              </Grid>

              <div className="flex flex-col md:flex-row md:items-center md:justify-between mt-32 p-8 md:px-17 md:py-23 text-12 md:text-14 bg-primary-50">
                <Typography className="text-14">基本生活費の合計</Typography>
                <div className="md:flex flex-1 justify-between mt-12 md:mt-0 md:space-x-50">
                  {!moneytree_average_months && (
                    <div className="md:w-[50%] flex items-baseline justify-between md:flex-col md:items-end">
                      <Typography isBold>今後想定する基本生活費</Typography>
                      <div className="flex items-baseline">
                        <Typography className="text-32 text-alert-600 mx-5" isBold>
                          {total ? convertLocaleString(total) : 0}
                        </Typography>
                        <Typography isBold>円/月</Typography>
                      </div>
                    </div>
                  )}

                  <div className="md:w-[50%] flex items-baseline justify-between md:flex-col md:items-end">
                    <Typography isBold>{`${convertMoneytreeAverageMonths(
                      moneytree_average_months,
                    )}基本生活費`}</Typography>
                    <div className="flex items-baseline">
                      <Typography className="text-32 text-alert-600 mx-5" isBold>
                        {sumPastMonthlyCost(aggBalanceMonthly)}
                      </Typography>
                      <Typography isBold>円/月</Typography>
                    </div>
                  </div>
                </div>
              </div>

              <WholeSubmitErrorMessage submitErrors={submitErrors} />
              <SubmitErrorMessage show={!submitErrors && submitFailed} />
              <ButtonPrimary
                className={clsx(
                  { ['opacity-50']: Object.keys(errors).length > 0 },
                  'w-[246px] h-[64px] mx-auto my-[16px] ',
                )}
                isDisabled={isLoadingInputMonthlyCosts}
                onClick={() => handleSubmit(values)}
              >
                <Typography
                  className="relative bottom-[2px] pl-[30px] text-[16px] text-white flex-auto"
                  isBold
                >
                  保存する
                </Typography>
                <ChevronRightIcon className="mr-[17px]" />
              </ButtonPrimary>
              <Button
                className="mx-auto text-[14px] text-black-700"
                onClick={props.onClose}
                isDisabled={isLoadingInputMonthlyCosts}
              >
                キャンセル
              </Button>
            </form>
          )
        }}
      />
    </>
  )
}

export default MyDataMonthlyCostForm
