import React, { FC, useState, useContext, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { find as _find } from 'lodash'
import Button from '@/components/v2/atoms/Button'
import Typography from '@/components/v2/atoms/Typography'
import AccordionSet from '@/components/v1/molecules/AccordionSet'
import {
  DEEP_GRAY_COLOR,
  PALE_GRAY_COLOR,
  VERY_PALE_GRAY_COLOR,
  WHITE_COLOR,
} from '@/constants/theme'
import { destroyFutureHousingSaleV2 } from '@/containers/futures/futureHousingSalesSlice'
import {
  convertDateToTwoTypesYearsDateFormat,
  convertLocaleString,
} from '@/models/commonsModelFunc'
import { SimulationDetailEditContext } from '@/models/futures/futureBasesModelFunc'
import { FutureHousingSale } from '@/models/futures/futureHousingSalesModel'
import { convertTransferExceptionType } from '@/models/futures/futureHousingSalesModelFunc'
import { FutureV2Housing, NextHouseType } from '@/models/futures/futureV2HousingsModel'
import { NEXT_HOUSE_TYPE } from '@/models/futures/futureV2HousingsModelFunc'
import { InputHousing } from '@/models/inputs/inputHousingsModel'

import { RootState } from '@/store'
import FutureV2HousingBody from '@/templates/v2/simulations/futureV2Housings/FutureV2HousingBody'
import FutureV2HousingCreateModalForm from '@/templates/v2/simulations/futureV2Housings/FutureV2HousingModalForm/createIndex'
import FutureHousingSaleUpdateModalForm from '@/templates/v2/simulations/futureV2Housings/FutureHousingSaleModalForm/updateIndex'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import Tooltip from '@/components/v2/atoms/Tooltip'
import HelpCircleIcon from '@/assets/images/v2/help-circle.svg'

const useStyles = makeStyles(() =>
  createStyles({
    settingItem: {
      padding: '10px 0',
      backgroundColor: VERY_PALE_GRAY_COLOR,
      '&:not(:last-child)': {
        marginBottom: '20px',
      },
    },
    emptyBlockDescription: {
      color: DEEP_GRAY_COLOR,
      paddingBottom: '20px',
      fontSize: '14px',
    },
    emptyBlockButtonWrap: {
      position: 'relative',
      padding: '30px 10px',
      margin: '0 10px',
      textAlign: 'center',
      backgroundColor: WHITE_COLOR,
      border: `4px solid ${PALE_GRAY_COLOR}`,
    },
  }),
)

interface FutureHousingSaleBodyProps {
  /** 将来住居の売却オブジェクト */
  futureHousingSale: Partial<FutureHousingSale>
  /** 次の住居開始年齢 */
  nextScheduledAtAge: number | null | undefined
  /** 共通API呼び出しメソッド */
  commonCallApi: () => void
  /** 次の将来住居の家タイプセットメソッド */
  setNextHouseType: (nextHousetype: NextHouseType | undefined) => void
  /** 次の将来住居の開始年齢セットメソッド */
  setNextScheduledAtAge: (nextScheduledAtAge: number | undefined) => void
  /** 直前の住居オブジェクトセットメソッド */
  setPrevHousing: ((prevHousing: FutureV2Housing) => void) | undefined
  /** 購入用住み替えモーダルオープンフラグ */
  futureV2HousingForPurchaseModalStatus:
    | { targetId: number | undefined; isModalOpen: boolean }
    | undefined
  /** 購入用住み替えモーダルオープンメソッド */
  setFutureV2HousingForPurchaseModalStatus: (
    futureV2HousingForPurchaseModalStatus:
      | {
          targetId: number | undefined
          isModalOpen: boolean
        }
      | undefined,
  ) => void
  afterDestory: () => void
}

interface FutureHousingSaleTableProps {
  /** アイテム */
  futureHousingSale: any

  /** td要素のクラス */
  tdClassName?: string
}

export const FutureHousingSaleTable: FC<FutureHousingSaleTableProps> = ({
  futureHousingSale,
  tdClassName,
}) => {
  return (
    <>
      <table className="w-[100%] mb-[60px] last:mb-0 text-[12px]">
        <tbody>
          <tr className="h-[45px] odd:bg-black-100">
            <td className={`${tdClassName ?? 'w-[50%]'} px-[16px] md:px-[16px]`}>
              <Typography isBold>予定売却額</Typography>
            </td>
            <td className="px-[16px]">
              <Typography className="">{`${convertLocaleString(
                futureHousingSale?.sale_amount_manyen,
              )}万円`}</Typography>
            </td>
          </tr>

          <tr className="h-[45px] odd:bg-black-100">
            <td className="px-[16px]">
              <div className="flex">
                <Typography isBold className="flex items-center">
                  予定売却費用
                  <Tooltip
                    item="例) 仲介手数料・印紙代など"
                    itemClassName="w-[200px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                  >
                    <HelpCircleIcon />
                  </Tooltip>
                </Typography>
              </div>
            </td>
            <td className="px-[16px]">
              <Typography className="">{`${convertLocaleString(
                futureHousingSale?.cost_manyen,
              )}万円`}</Typography>
            </td>
          </tr>

          <tr className="h-[45px] odd:bg-black-100">
            <td className="px-[16px]">
              <div className="flex">
                <Typography isBold>売却特例利用</Typography>
                <Tooltip
                  item="マイホーム売却時の特例とは、所有期間に関係なく譲渡所得から最高3,000万円までの控除が出来る特例です。「居住用財産を譲渡した場合の3,000万円の特別控除の特例」といいます。"
                  itemClassName="w-[150px] sm:w-[200px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                >
                  <HelpCircleIcon />
                </Tooltip>
              </div>
            </td>
            <td className="px-[16px]">
              <Typography className="">
                {convertTransferExceptionType(
                  futureHousingSale?.transfer_exception_type!,
                )}
              </Typography>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

const FutureHousingSaleBody: FC<FutureHousingSaleBodyProps> = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { showFlashMsg } = useFlashAlert()
  const { isManipulatedApproval } = useContext(SimulationDetailEditContext)

  const {
    futureHousingSale,
    nextScheduledAtAge,
    commonCallApi,
    setNextHouseType,
    setNextScheduledAtAge,
    setPrevHousing,
    futureV2HousingForPurchaseModalStatus,
    setFutureV2HousingForPurchaseModalStatus,
  } = props

  const [updateSaleTargetId, setUpdateSaleTargetId] = useState<number | undefined>(
    undefined,
  )
  const [targetHousingSaleId, setTargetHousingSaleId] = useState<number | undefined>(
    undefined,
  )
  const [isFutureV2HousingModalOpen, setIsFutureV2HousingModalOpen] = useState<boolean>()

  const futureV2Housings = useSelector(
    (state: RootState) => state.accounts.futureItems?.future_v2_housings,
  )
  const { inputHousings } = useSelector((state: RootState) => state.inputHousings)

  const sellAtAge = useMemo(() => futureHousingSale?.sell_at_age, [futureHousingSale])

  const prevFutureV2Housing: FutureV2Housing | undefined = useMemo(
    () => _find(futureV2Housings, (item) => item.id === futureHousingSale?.housing_id),
    [futureHousingSale, futureV2Housings],
  )
  const prevInputHousing: InputHousing | undefined = useMemo(
    () => _find(inputHousings, (item) => item.id === futureHousingSale?.housing_id),
    [futureHousingSale, inputHousings],
  )
  const prevHousing = useMemo(() => {
    if (futureHousingSale?.housing_type === 'InputHousing') {
      return prevInputHousing
    }

    return prevFutureV2Housing
  }, [prevFutureV2Housing, prevInputHousing])

  const isModalForPurchaseOpen = useMemo(
    () =>
      futureV2HousingForPurchaseModalStatus?.targetId === futureHousingSale?.id &&
      futureV2HousingForPurchaseModalStatus?.isModalOpen,
    [futureV2HousingForPurchaseModalStatus, futureHousingSale],
  )

  const selectFutureV2Housing = (futureV2HousingId: number | null | undefined) => {
    return _find(futureV2Housings, (item) => item.id === futureV2HousingId)
  }

  const relocationFutureV2Housing = useMemo(
    () => selectFutureV2Housing(futureHousingSale?.future_v2_housing_id),
    [futureHousingSale],
  )

  const handleFutureV2HousingModalClose = () => {
    setIsFutureV2HousingModalOpen(false)
    setTargetHousingSaleId(undefined)
    setFutureV2HousingForPurchaseModalStatus(undefined)
    setNextHouseType(undefined)
    setNextScheduledAtAge(undefined)
  }

  const completedFutureV2HousingCallback = () => {
    commonCallApi()
    showFlashMsg('登録しました')
    setIsFutureV2HousingModalOpen(false)
    setTargetHousingSaleId(undefined)
    setFutureV2HousingForPurchaseModalStatus(undefined)
    setNextHouseType(undefined)
    setNextScheduledAtAge(undefined)
  }

  const continueForPurchaseCallback = (nextHouseValues: {
    nextHouseType: NextHouseType
    nextScheduledAtAge: number
    prevHousing: FutureV2Housing
  }) => {
    commonCallApi()
    setNextHouseType(nextHouseValues.nextHouseType)
    setNextScheduledAtAge(nextHouseValues.nextScheduledAtAge)
    setPrevHousing && setPrevHousing(nextHouseValues.prevHousing)
    setTargetHousingSaleId(undefined)
    setIsFutureV2HousingModalOpen(false)
    showFlashMsg('登録しました')
    setFutureV2HousingForPurchaseModalStatus({
      targetId: futureHousingSale?.id,
      isModalOpen: true,
    })
  }

  const handleDestroy = (id: number) => {
    const result = confirm(
      'これ以降の予定が全て削除されます。\n選択した売却予定を削除してもよろしいですか？',
    )
    if (!result) return

    dispatch(
      destroyFutureHousingSaleV2(id, () => {
        commonCallApi()
        showFlashMsg('削除しました')
        props.afterDestory()
      }),
    )
  }

  return (
    <div
      className="m-[25px]"
      onClick={(event: any) =>
        (isFutureV2HousingModalOpen ||
          isModalForPurchaseOpen ||
          updateSaleTargetId === futureHousingSale?.id) &&
        event.stopPropagation()
      }
    >
      <AccordionSet
        title="売却について"
        defaultClose
        titleClassName="text-14"
        customElement={
          <>
            <div className="flex justify-end mb-[5px]">
              <Button
                className="mx-5"
                isDisabled={!isManipulatedApproval}
                onClick={(event: any) => {
                  event.stopPropagation()
                  setUpdateSaleTargetId(futureHousingSale?.id!)
                }}
              >
                <i className="ico edit text-[14px] text-black-700" />
                <Typography className="pl-[5px] text-[14px] text-black-700">
                  編集
                </Typography>
              </Button>
              <Button
                className="mx-5"
                isDisabled={!isManipulatedApproval}
                onClick={(event: any) => {
                  event.stopPropagation()
                  handleDestroy(futureHousingSale?.id!)
                }}
              >
                <i className="ico edit text-[14px] text-black-700" />
                <Typography className="pl-[5px] text-[14px] text-black-700">
                  削除
                </Typography>
              </Button>
            </div>
          </>
        }
      >
        {futureHousingSale && (
          <FutureHousingSaleTable futureHousingSale={futureHousingSale} />
        )}
      </AccordionSet>

      {updateSaleTargetId === futureHousingSale?.id && (
        <FutureHousingSaleUpdateModalForm
          targetHousingSale={futureHousingSale}
          isOpen={updateSaleTargetId === futureHousingSale?.id}
          handleClose={() => setUpdateSaleTargetId(0)}
          commonCallApi={commonCallApi}
        />
      )}

      {!!selectFutureV2Housing(futureHousingSale?.future_v2_housing_id!) ? (
        <FutureV2HousingBody
          title="住み替え予定"
          futureV2Housing={selectFutureV2Housing(
            futureHousingSale?.future_v2_housing_id!,
          )}
          targetHousingSaleId={futureHousingSale?.id}
          commonCallApi={commonCallApi}
          afterDestory={props.afterDestory}
        />
      ) : (
        <>
          <AccordionSet title="住み替えについて" titleClassName="text-14">
            <div className={classes.settingItem}>
              <div className={classes.emptyBlockButtonWrap}>
                <Typography className={classes.emptyBlockDescription}>
                  住み替え先が入力されていません。
                  <br />
                  下記のボタンから登録を行ってください。
                </Typography>
                <Button
                  className="w-full h-[30px] mx-auto my-[0] border border-dashed border-primary-500 hover:bg-primary-50"
                  isDisabled={!isManipulatedApproval}
                  onClick={(event: any) => {
                    setTargetHousingSaleId(futureHousingSale?.id!)
                    setIsFutureV2HousingModalOpen(true)
                    event.stopPropagation()
                  }}
                >
                  <Typography
                    className="relative bottom-[1px] px-[12px] text-primary-500"
                    isBold
                  >
                    住み替え先を登録する
                  </Typography>
                </Button>
              </div>
            </div>
          </AccordionSet>
        </>
      )}

      {(isFutureV2HousingModalOpen || isModalForPurchaseOpen) && (
        <FutureV2HousingCreateModalForm
          targetHousingSaleId={targetHousingSaleId}
          isOpen={!!isFutureV2HousingModalOpen || !!isModalForPurchaseOpen}
          prevHousing={relocationFutureV2Housing || prevHousing}
          nextScheduledAtAge={nextScheduledAtAge || sellAtAge}
          nextHouseType={
            !!isModalForPurchaseOpen ? (NEXT_HOUSE_TYPE.own as NextHouseType) : undefined
          }
          handleClose={handleFutureV2HousingModalClose}
          completedCallback={completedFutureV2HousingCallback}
          continueToPurchaseCallback={continueForPurchaseCallback}
        />
      )}
    </div>
  )
}

export default FutureHousingSaleBody
