import moment from 'moment'
import { isNull as _isNull, isUndefined as _isUndefined } from 'lodash'
import {
  API_FLAG,
  castNumberWithoutOperator,
  convertDateToTwoTypesYearsDateFormat,
  extractYearOrMonthFromDate,
} from '@/models/commonsModelFunc'
import { INPUT_HOUSING_RENT_TYPE } from '@/models/inputs/inputHousingRentsModelFunc'

// Housing =====
// 所有タイプ
export const HOUSE_TYPE = {
  rent: 0,
  own: 1,
  transfer: 2,
  other: 3,
  notSelf: 4,
}

// 家タイプ
export const OWNED_HOUSE_TYPE = {
  house: 0,
  mansion: 1,
}

// 家状態
export const HOUSING_TYPE2 = {
  lowCarbon: 0,
  sumuStock: 1,
  newly: 2,
  fullOrder: 3,
  renovated: 4,
  secondhand: 5,
  zehFullOrder: 6,
  zehNewly: 7,
  energySavingFullOrder: 8,
  energySavingNewly: 9,
  energySavingSecondhand: 10,
  energySavingRenovated: 11,
}

// 修繕積立上昇率の設定
export const REPAIR_RESERVE_FUND_RATE_TYPE = {
  change: 0,
  fixed: 1,
  noSet: 2,
}

// HousingLoans =====
// ローン方式
export const LOAN_TYPE = {
  personOnly: 0,
  spouseOnly: 1,
  pairLoan: 2,
  combined: 3,
}

// ローン借り入れ先
export const LOAN_LENDER = {
  flat: 0,
  private: 1,
}

// ローンタイプ(土地/建物)
export const LOAN_FOR = {
  land: 'land',
  building: 'building',
}

export const selectLoanObjectNumber = (
  isBuilding: boolean | undefined,
  isSpouse?: boolean | undefined,
) => {
  return isBuilding ? (!isSpouse ? 2 : 3) : !isSpouse ? 0 : 1
}

export const getTargetRentAttributesName = (isFuture: boolean | undefined) => {
  return isFuture
    ? 'future_v2_housing.future_v2_housing_rents_attributes'
    : 'input_housing.input_housing_rents_attributes'
}

export const getTargetSelfOwnedAttributesName = (isFuture: boolean | undefined) => {
  return isFuture
    ? 'future_v2_housing.future_v2_housing_self_owned_attributes'
    : 'input_housing.input_housing_self_owned_attributes'
}

export const getTargetLoanAttributesName = (isFuture: boolean | undefined) => {
  return isFuture
    ? 'future_v2_housing.future_v2_housing_self_owned_attributes.future_v2_housing_loans_attributes'
    : 'input_housing.input_housing_self_owned_attributes.input_loans_attributes'
}

export const getTargetLoanCalculationRateName = (isFuture: boolean | undefined) => {
  return isFuture ? 'future_v2_housing_loan_calculation_rates' : 'input_calculation_rates'
}

export const isSelfRentType = (rentType: string | number | null | undefined) => {
  return rentType == INPUT_HOUSING_RENT_TYPE.selfRent
}

export const isMansionType = (ownedHouseType: number | null | undefined) => {
  return ownedHouseType == OWNED_HOUSE_TYPE.mansion
}

export const isHousingType2Custom = (houseType2: number | null | undefined) => {
  return houseType2 == HOUSING_TYPE2.fullOrder || houseType2 == HOUSING_TYPE2.sumuStock
}

export const isWithLoan = (loanType1: number | null | undefined) => {
  return loanType1 == API_FLAG.on
}

export const isWithChartLoan = (repaymentChart: number | null | undefined) => {
  return repaymentChart == API_FLAG.on
}

export const isWithCalcLoan = (repaymentChart: number | null | undefined) => {
  return repaymentChart == API_FLAG.off
}

export const isPairLoan = (loanType: number | null | undefined) => {
  return Number(loanType) == LOAN_TYPE.pairLoan
}

export const isFlatLoan = (loanLender: number | string | null | undefined) => {
  return Number(loanLender) == LOAN_LENDER.flat
}

export const isBonusFlgOn = (bonusFlg: string | number | null | undefined) => {
  return Number(bonusFlg) == API_FLAG.on
}

export const isWithAdvanceRepayment = (
  advanceRepayment: number | string | undefined | null,
) => {
  return Number(advanceRepayment) == API_FLAG.on && !_isNull(advanceRepayment)
}

export const convertOwnedHouseType = (houseType: number | null | undefined) => {
  switch (houseType) {
    case OWNED_HOUSE_TYPE.mansion:
      return 'マンション'
    case OWNED_HOUSE_TYPE.house:
      return '戸建て'
    default:
      return ''
  }
}

export const convertHousingType2 = (
  housingType2: number | null | undefined,
  isMansion: boolean,
) => {
  if (!isMansion) {
    switch (housingType2) {
      case HOUSING_TYPE2.sumuStock:
        return '長期優良・低炭素住宅（注文）'
      case HOUSING_TYPE2.lowCarbon:
        return '長期優良・低炭素住宅（建売または買取再販）'
      case HOUSING_TYPE2.newly:
        return 'その他住宅（建売または買取再販）'
      case HOUSING_TYPE2.fullOrder:
        return 'その他住宅（注文）'
      case HOUSING_TYPE2.secondhand:
        return '中古（通常）'
      case HOUSING_TYPE2.zehFullOrder:
        return 'ZEH水準省エネ住宅（注文）'
      case HOUSING_TYPE2.zehNewly:
        return 'ZEH水準省エネ住宅（建売または買取再販）'
      case HOUSING_TYPE2.energySavingFullOrder:
        return '省エネ基準適合住宅（注文）'
      case HOUSING_TYPE2.energySavingNewly:
        return '省エネ基準適合住宅（建売または買取再販）'
      case HOUSING_TYPE2.energySavingSecondhand:
        return '中古(省エネ以上)'
      default:
        return ''
    }
  } else {
    switch (housingType2) {
      case HOUSING_TYPE2.newly:
        return '新築マンションまたは買取再販（通常）'
      case HOUSING_TYPE2.secondhand:
        return '中古（通常）'
      case HOUSING_TYPE2.lowCarbon:
        return '新築マンションまたは買取再販（長期優良基準）'
      case HOUSING_TYPE2.zehNewly:
        return '新築マンションまたは買取再販（ZEH基準）'
      case HOUSING_TYPE2.energySavingSecondhand:
        return '中古（省エネ以上）'
      default:
        return ''
    }
  }
}

export const convertLoanType = (loanType: number | null | undefined) => {
  switch (loanType) {
    case LOAN_TYPE.personOnly:
      return 'ご本人単独'
    case LOAN_TYPE.spouseOnly:
      return '配偶者単独'
    case LOAN_TYPE.pairLoan:
      return 'ペアローン'
    case LOAN_TYPE.combined:
      return '収入合算'
    default:
      return ''
  }
}

export const convertLoanLender = (loanLender: number | string | null | undefined) => {
  switch (Number(loanLender)) {
    case LOAN_LENDER.flat:
      return 'フラット20/フラット35'
    case LOAN_LENDER.private:
      return '民間住宅ローン'
    default:
      return ''
  }
}

export const convertLoanType2 = (loanType2: number | null | undefined) => {
  switch (loanType2) {
    case API_FLAG.on:
      return '分けて借りている'
    case API_FLAG.off:
      return '分けて借りていない'
    default:
      return '分けて借りていない'
  }
}

export const convertInputLoanLoanType = (loanType: number | null | undefined) => {
  switch (loanType) {
    case LOAN_TYPE.personOnly:
      return 'ご本人単独'
    case LOAN_TYPE.spouseOnly:
      return '配偶者単独'
    case LOAN_TYPE.pairLoan:
      return 'ペアローン・連帯債務'
    case LOAN_TYPE.combined:
      return '収入合算'
    default:
      return 'ご本人単独'
  }
}

export const convertInsuranceType = (type: string | number | null | undefined) => {
  switch (Number(type)) {
    case API_FLAG.on:
      return '加入している'
    case API_FLAG.off:
      return '加入していない'
    default:
      return '加入していない'
  }
}

export const convertDeductionType = (type: string | number | null | undefined) => {
  switch (Number(type)) {
    case API_FLAG.on:
      return '対象'
    case API_FLAG.off:
      return '非対象'
    default:
      return '非対象'
  }
}

export const convertRateChangeType = (type: string | null | undefined) => {
  switch (type) {
    case String(API_FLAG.on):
      return '設定している'
    case String(API_FLAG.off):
      return '設定していない'
    default:
      return '設定していない'
  }
}

export const convertRateChangeValues = (
  rateStartAt: string | null | undefined,
  changedRate: number | null | undefined,
) => {
  if (_isUndefined(rateStartAt) || _isUndefined(changedRate)) return ''

  if (_isNull(rateStartAt)) {
    return `${changedRate}％`
  } else {
    const formatYear = convertDateToTwoTypesYearsDateFormat(
      moment(rateStartAt).format('YYYY'),
      'japaneseYear',
    )
    return `${formatYear}年から ${castNumberWithoutOperator(changedRate)}％`
  }
}

export const convertIsFlatApplied = (isFlatApllied: boolean | null | undefined) => {
  return isFlatApllied ? 'フラット' : '変動金利'
}

export const calcEstimatedMiscellaneousCost = (
  propertyPriceManyen: number | null,
  propertyPriceBuildingManyen?: number | null,
) => {
  return propertyPriceBuildingManyen
    ? Math.round(
        (castNumberWithoutOperator(propertyPriceManyen) +
          castNumberWithoutOperator(propertyPriceBuildingManyen)) *
          0.07,
      )
    : Math.round(castNumberWithoutOperator(propertyPriceManyen) * 0.07)
}

export const convertToJapaneseFullDate = (date: string | null | undefined) => {
  const formatDate = convertDateToTwoTypesYearsDateFormat(
    moment(date).format('YYYY-MM-DD'),
    'fullDateWithJapaneseYear',
  )

  return formatDate
}
