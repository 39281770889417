import React, { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { parse } from 'query-string'
import MarginContainer from '@/components/v2/atoms/MarginContainer'
import Paper from '@/components/v2/atoms/Paper'
import Typography from '@/components/v2/atoms/Typography'
import MoneytreeConnect from '@/templates/v2/plans/commons/MoneytreeConnect'
import { setPageMeta } from '@/containers/pageMetasSlice'
import { createMtAccessToken } from '@/containers/mtAccessTokensSlice'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import { MtAccessTokenCreateValue } from '@/models/mtAccessTokenModel'
import { STATE_LOCAL_STORAGE_KEY } from '@/utils/moneytree'
import Heading from '@/components/v2/atoms/Heading'
import Link from '@/components/v2/atoms/Link'
import Modal from '@/components/v2/atoms/Modal'

const MoneytreeRegistration: FC = () => {
  const dispatch = useDispatch()
  const { handleMovePage } = useCustomHistory()
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)

  const queryParams = parse(location.search)
  const stateInLocalStorage = window.localStorage[STATE_LOCAL_STORAGE_KEY]

  const createValue: MtAccessTokenCreateValue = {
    code: queryParams.code as string,
  }

  const isAuthorized: boolean =
    createValue?.code?.length > 0 && queryParams.state == stateInLocalStorage
  const isFromSettingPage: boolean = window.localStorage.isFromSettingPage === 'true'

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'Moneytree連携',
        description: 'Moneytree連携',
        keyword: 'Moneytree連携',
      }),
    )

    if (isAuthorized) {
      dispatch(createMtAccessToken(createValue, handleNextPage))
      return
    } else {
      setIsOpenModal(true)
    }
  }, [])

  const handleNextPage = () => {
    handleMovePage('/v2/plans/dataConnection')
  }

  const handleSkipConnection = () => {
    isFromSettingPage
      ? handleMovePage('/v2/settings/account')
      : handleMovePage('/v2/dashboards')
  }

  return (
    <MarginContainer className="py-40 space-y-24">
      <Paper className="lg:max-w-820 mx-8 lg:mx-auto px-16 py-24 sm:p-40 space-y-24 bg-white">
        <Heading className="text-center animate-flash">
          Moneytreeのアカウントを
          <span className="sm:inline-block block" />
          連携しています
          <br />
          このページのまましばらくお待ちください
        </Heading>
        <Typography className="text-14 text-black-700 text-center">
          このページは自動で切り替わります。
          <br />
          Moneytreeにご登録されているデータ量によっては、
          <span className="sm:inline-block block">
            連携完了までお時間がかかる場合があります。
          </span>
          <br />
          5分以上経過しても切り替わらない場合は、
          <span className="sm:inline-block block">
            下記のボタンから再度連携をお願いします。
          </span>
        </Typography>

        <div className="p-16 md:p-24 xl:p-40 rounded-16 bg-primary-75">
          <div className="flex flex-col items-center p-12 space-y-24 rounded-8 bg-white">
            <MoneytreeConnect />
          </div>
        </div>
        <div className="text-center">
          <Link
            className="text-14 text-primary-900"
            children={
              isFromSettingPage ? '連携せずに個人設定ページに戻る' : '連携せずに進む'
            }
            onClick={handleSkipConnection}
          />
        </div>
      </Paper>

      <Modal
        className="w-[95%] md:w-[400px]"
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(false)}
      >
        <Typography className="text-center text-[20px] pb-[10px]" isBold>
          Moneytree連携エラー
        </Typography>
        <Typography className="text-center">認証コードが入力されていません。</Typography>
      </Modal>
    </MarginContainer>
  )
}

export default MoneytreeRegistration
