// ハンドラータイプ
export const HANDLER_TYPE = {
  user: 'user',
  fp: 'fp',
}

// プランステータス
export const PLAN_STATUS = {
  published: 'published',
  hide: 'hide',
}
