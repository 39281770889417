import LocalStorage from '@/utils/localStorage'

const KEY = 'isDisabledTrialAccount'

export const getStorageIsDisabledTrialAccount = () => {
  return LocalStorage.getItem(KEY)
}

export const setStorageIsDisabledTrialAccount = (value: boolean) => {
  return LocalStorage.setItem(KEY, value)
}

export const removeStorageIsDisabledTrialAccount = () => {
  LocalStorage.removeItem(KEY)
}
