import React, { FC } from 'react'

interface PolicyLinkProps {
  /** クラス名 */
  className?: string
}

const PolicyLink: FC<PolicyLinkProps> = (props) => {
  const { className } = props

  return (
    <div className={`pt-[10px] px-[10px] m-auto w-[80%] border-solid border-t text-14 relative ${className}`}>
      <div className="text-center">
        <a
          className="cursor-pointer text-gray-500 stroke-current hover:underline"
          onClick={() => window.open('/v2/policy')}
        >
          プライバシーポリシー
        </a>
      </div>
    </div>
  )
}

export default PolicyLink
