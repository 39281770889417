import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  getCompanySalaryBase,
  getCompanySalaryBaseV2,
  postCompanySalaryBaseCalc,
  patchCompanySalaryBase,
} from '@/api/admins/companySalaryBases'
import { unauthorizedAdminCheck } from '@/containers/admins/fpAccountsSlice'
import { RecursivePartial, ApiError } from '@/models/commonsModel'
import {
  CompanySalaryBaseResponse,
  CompanySalaryBase,
  CompanySalaryBaseUpdateValues,
  CompanySalaryBaseCalcResponse,
  CompanySalaryBaseCalc,
  CompanySalaryBaseCalcValues,
} from '@/models/admins/companySalaryBasesModel'
import { AppThunk } from '@/store'

interface CompanySalaryBaseState {
  companySalaryBase: CompanySalaryBase | null
  companySalaryBaseCalc: CompanySalaryBaseCalc
  isLoading: boolean
}

const initialState: CompanySalaryBaseState = {
  companySalaryBase: null,
  companySalaryBaseCalc: {
    company_salary_grades: [],
  },
  isLoading: false,
}

const companySalaryBasesSlice = createSlice({
  name: 'companySalaryBases',
  initialState,
  reducers: {
    setCompanySalaryBase: (state, action: PayloadAction<CompanySalaryBase | null>) => {
      state.companySalaryBase = action.payload
      return state
    },
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    getCompanySalaryBaseSuccess: (
      state,
      action: PayloadAction<CompanySalaryBaseResponse>,
    ) => {
      state.companySalaryBase = action.payload
      state.isLoading = false
      return state
    },
    getCompanySalaryBaseV2Success: (
      state,
      action: PayloadAction<CompanySalaryBaseResponse>,
    ) => {
      state.companySalaryBase =
        Object.keys(action.payload).length > 0 ? action.payload : null
      state.isLoading = false
      return state
    },
    postCompanySalaryBaseCalcSuccess: (
      state,
      action: PayloadAction<CompanySalaryBaseCalcResponse>,
    ) => {
      state.companySalaryBaseCalc = action.payload
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
    resetCompanySalaryBases: () => {
      return initialState
    },
  },
})

export const {
  setCompanySalaryBase,
  requestStart,
  requestSuccess,
  getCompanySalaryBaseSuccess,
  getCompanySalaryBaseV2Success,
  postCompanySalaryBaseCalcSuccess,
  requestFailure,
  resetCompanySalaryBases,
} = companySalaryBasesSlice.actions
export const companySalaryBasesReducer = companySalaryBasesSlice.reducer

export const fetchCompanySalaryBase = (id: number): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  getCompanySalaryBase(id)
    .then((response) => dispatch(getCompanySalaryBaseSuccess(response)))
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedAdminCheck(error))
    })
}

export const fetchCompanySalaryBaseV2 = (): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  getCompanySalaryBaseV2()
    .then((response) => dispatch(getCompanySalaryBaseV2Success(response)))
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedAdminCheck(error))
    })
}

export const createCompanySalaryBaseCalc = (
  id: number,
  values: RecursivePartial<CompanySalaryBaseUpdateValues>,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postCompanySalaryBaseCalc(id, values)
    .then((response) => dispatch(postCompanySalaryBaseCalcSuccess(response)))
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedAdminCheck(error))
    })
}

export const updateCompanySalaryBase = (
  id: number,
  values: RecursivePartial<CompanySalaryBaseCalcValues>,
  callback: () => void,
  resolve: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchCompanySalaryBase(id, values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedAdminCheck(error, resolve))
    })
}
