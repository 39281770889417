import { times as _times } from 'lodash'
import {
  REPAIR_RESERVE_FUND_INCREASE_TYPE,
  TAX_PAPER_PRESENCE_TYPE,
} from '@/models/commonEstateInvestmentModelFunc'
import { API_FLAG } from '@/models/commonsModelFunc'

// 頻度
export const ESTATE_INVESTMENT_SPAN_SELECT_ITEMS = _times(25, (index) => ({
  value: index + 1,
  label: String(index + 1),
}))

export const REPAIR_RESERVE_FUND_INCREASE_TYPE_SELECT_ITEMS = [
  {
    value: REPAIR_RESERVE_FUND_INCREASE_TYPE.change,
    label: '上昇率を設定',
  },
  { value: REPAIR_RESERVE_FUND_INCREASE_TYPE.noSet, label: '上昇率を設定しない' },
]

// 礼金・更新料種別
export const RENEWAL_FEE_TYPE_RADIO_ITEMS = [
  { value: API_FLAG.on, label: 'はい' },
  { value: API_FLAG.off, label: 'いいえ' },
]

export const TAX_PAPER_PRESENCE_TYPE_RADIO_ITEMS = [
  { value: TAX_PAPER_PRESENCE_TYPE.yes, label: '手元にある' },
  { value: TAX_PAPER_PRESENCE_TYPE.no, label: '手元にない' },
]
