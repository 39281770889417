import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Form } from 'react-final-form'
import { cloneDeep as _cloneDeep } from 'lodash'
import Heading from '@/components/v2/atoms/Heading'
import Typography from '@/components/v2/atoms/Typography'
import TextField, { SHORT_LENGTH } from '@/components/v2/atoms/TextField'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import FieldBlock from '@/components/v2/molecules/FieldBlock'
import RadioGroup from '@/components/v2/molecules/RadioGroup'
import WholeSubmitErrorMessage from '@/components/v2/molecules/WholeSubmitErrorMessage'
import { NEW_SEX_RADIO_ITEMS } from '@/constants/formItem/commons'
import { RootState } from '@/store'
import {
  composeValidators,
  required,
  mailAddress,
  kana,
  password,
} from '@/utils/validate'

interface AccountPasswordFormProps {
  /** タイトル */
  title: string
  /** true: 会員登録確認画面, false: 会員登録確認画面以外 */
  isConfirm: boolean
  /** onSubmitイベントメソッド */
  handleSubmit: (values: any) => void
  /** 修正ボタンメソッド */
  handleFix?: () => void
}

const AccountPasswordForm: FC<AccountPasswordFormProps> = (props) => {
  const { title, isConfirm, handleSubmit, handleFix } = props
  const { account, isLoading } = useSelector((state: RootState) => state.accounts)

  const handleCommonSubmit = (values: any) => {
    const newValues = _cloneDeep(values)
    newValues.sex = Number(newValues.sex)

    return handleSubmit(newValues)
  }

  return (
    <div className="space-y-30 text-black-800">
      <Heading className="text-center">{title}</Heading>
      <Form
        onSubmit={handleCommonSubmit}
        initialValues={account}
        render={({ handleSubmit, values, errors, submitErrors, form: { change } }) => (
          <form className="space-y-24" onSubmit={handleSubmit}>
            <FieldBlock label="メールアドレス" required>
              <TextField
                type="text"
                name="mail_address"
                disabled={isConfirm}
                isFullWidth
                validate={composeValidators(required, mailAddress)}
              />
            </FieldBlock>
            <div className="flex flex-col md:flex-row space-y-24 md:space-y-0 md:space-x-24">
              <FieldBlock label="お名前(姓)" className="flex-1" required>
                <TextField
                  type="text"
                  name="name_sei"
                  disabled={isConfirm}
                  isFullWidth
                  validate={required}
                />
              </FieldBlock>
              <FieldBlock label="お名前(名)" className="flex-1" required>
                <TextField
                  type="text"
                  name="name_mei"
                  disabled={isConfirm}
                  isFullWidth
                  validate={required}
                />
              </FieldBlock>
            </div>
            <div className="flex flex-col md:flex-row space-y-24 md:space-y-0 md:space-x-24">
              <FieldBlock label="ふりがな(せい)" className="flex-1" required>
                <TextField
                  type="text"
                  name="name_sei_kana"
                  disabled={isConfirm}
                  isFullWidth
                  validate={composeValidators(required, kana)}
                />
              </FieldBlock>
              <FieldBlock label="ふりがな(めい)" className="flex-1" required>
                <TextField
                  type="text"
                  name="name_mei_kana"
                  disabled={isConfirm}
                  isFullWidth
                  validate={composeValidators(required, kana)}
                />
              </FieldBlock>
            </div>
            <FieldBlock label="性別" required>
              <RadioGroup
                name="sex"
                isDispRow
                disabled={isConfirm}
                validate={required}
                datas={NEW_SEX_RADIO_ITEMS}
                onChange={(event) => change('sex', Number(event.target.value))}
              />
            </FieldBlock>
            <FieldBlock label="パスワード" required>
              <TextField
                type="password"
                name="password"
                isFullWidth
                maxLength={SHORT_LENGTH}
                validate={composeValidators(required, password)}
              />
            </FieldBlock>
            <FieldBlock label="パスワード再入力" required>
              <TextField
                type="password"
                name="password_confirmation"
                isFullWidth
                maxLength={SHORT_LENGTH}
                validate={composeValidators(required, password)}
              />
            </FieldBlock>
            <WholeSubmitErrorMessage submitErrors={submitErrors} />
            <div className="flex justify-center">
              <ButtonPrimary
                className="h-45 w-full md:w-215 text-white"
                isDisabled={Object.keys(errors).length > 0 || isLoading}
                onClick={() => handleSubmit(values)}
              >
                <Typography className="bottom-2 flex-auto pl-30 relative text-16" isBold>
                  登録する
                </Typography>
                <i className="ico chevron-right mr-17 text-24" />
              </ButtonPrimary>
            </div>
          </form>
        )}
      />
    </div>
  )
}

export default AccountPasswordForm
