import ApiClient from '@/api/apiClient'
import {
  AggBalanceMonthlyResponse,
  AggBalanceMonthlyUpdateValues,
  AggBalanceMonthlyInputTypeUpdateValues,
} from '@/models/aggBalanceMonthliesModel'

export const getAggBalanceLastMonth = async (values?: string) => {
  return await ApiClient.get(
    !!values
      ? `/agg_balance_monthlies/last_month?start_date=${values}`
      : '/agg_balance_monthlies/last_month',
    (response: AggBalanceMonthlyResponse) => response,
  )
}

export const patchAggBalanceMonthly = async (values: AggBalanceMonthlyUpdateValues) => {
  return await ApiClient.patch('/agg_balance_monthlies/update', () => {}, values)
}

export const patchAggBalanceMonthlyInputType = async (
  values: AggBalanceMonthlyInputTypeUpdateValues,
) => {
  return await ApiClient.patch(
    '/agg_balance_monthlies/update_input_type',
    () => {},
    values,
  )
}
