import React, { FC, useEffect, ChangeEvent } from 'react'
import { Field, useForm } from 'react-final-form'
import { replace as _replace } from 'lodash'
import clsx from 'clsx'
import ValidMessage from '@/components/v2/atoms/ValidMessage'
import {
  isNotEmptyValue,
  convertLocaleString,
  castNumberWithoutOperator,
} from '@/models/commonsModelFunc'
import { composeValidators, number } from '@/utils/validate'

interface NumberFieldProps {
  /** 名前 */
  name: string
  /** デフォルト値 */
  defaultValue?: any
  /** クラス名 */
  className?: string
  /** 幅用クラス名 */
  widthClassName?: string
  /** true: width100%, false: width100%無し */
  isFullWidth?: boolean
  /** true: 非活性, false: 活性 */
  disabled?: boolean
  /** バリデーションメソッド */
  validate?: (value: any, allValues: any) => void
  /** onChangeメソッド */
  onChange?: (event: ChangeEvent) => void
  /** onBlurメソッド */
  onBlur?: (event: ChangeEvent) => void
  /** true: バリデーションメッセージを表示しない, false: バリデーションメッセージ表示あり */
  disableValidationMessage?: boolean
}

const NumberField: FC<NumberFieldProps> = (props) => {
  const {
    name,
    defaultValue,
    className,
    widthClassName,
    isFullWidth,
    validate,
    onChange,
    onBlur,
    disableValidationMessage,
    ...rest
  } = props
  const { change, getFieldState } = useForm()

  useEffect(() => {
    addCommaToValue()
  }, [])

  useEffect(() => {
    if (isNotEmptyValue(getFieldState(name)?.value)) return
    change(name, 0)
  }, [getFieldState(name)?.value])

  const handleFocus = () => {
    removeCommaFromValue()
  }

  const removeCommaFromValue = () => {
    const resultValue = Number(_replace(getFieldState(name)?.value, /[^0-9.-]/g, ''))
    change(name, resultValue || 0)
  }

  const removeCommaFromValueBeforeUpdate = () => {
    const resultValue = Number(_replace(getFieldState(name)?.value, /[^0-9.-]/g, ''))
    change(name, resultValue || 0)
  }

  const addCommaToValue = () => {
    change(name, handleFormat(getFieldState(name)?.value))
  }

  const handleFormat = (value: any) => {
    let newValue = value ? _replace(value, /．/g, '.') : value // MEMO: 全角のカンマがある場合は、半角カンマに変換
    let commaRegex = /\./g

    if (
      ((newValue && String(newValue).match(new RegExp(commaRegex, 'g'))) || []).length > 1
    ) {
      const indexAfterFirstComma = String(newValue).indexOf('.') + 1
      newValue =
        String(newValue).slice(0, indexAfterFirstComma) +
        _replace(String(newValue).slice(indexAfterFirstComma), commaRegex, '')
    }

    newValue = _replace(newValue, /[０-９]/g, (v) =>
      String.fromCharCode(v.charCodeAt(0) - 0xfee0),
    )
    newValue = _replace(newValue, /[^0-9.-]/g, '')
    return isNotEmptyValue(newValue) ? convertLocaleString(Number(newValue)) : 0
  }

  return (
    <Field
      type="text"
      name={name}
      defaultValue={defaultValue ?? 0}
      validate={composeValidators((value: any) => {
        return number(castNumberWithoutOperator(value))
      }, validate)}
      formatOnBlur={true}
      format={handleFormat}
      beforeSubmit={removeCommaFromValueBeforeUpdate}
    >
      {(props) => {
        const { input, meta } = props

        const handleChange = (event: ChangeEvent) => {
          input.onChange(event)
          onChange?.(event)
        }

        const handleBlur = (event: any) => {
          input.onBlur(event)
          onBlur?.(event)
        }

        return (
          <div
            className={clsx(widthClassName, {
              ['w-full']: isFullWidth,
            })}
          >
            <input
              {...input}
              className={clsx(
                `${className} ${widthClassName} appearance-none bg-white border border-black-300 h-[44px] p-[10px] rounded-[2px] text-black-900 text-right disabled:bg-black-200 hover:border-black-500`,
                {
                  ['w-full']: isFullWidth,
                },
              )}
              onBlur={handleBlur}
              onChange={handleChange}
              onFocus={handleFocus}
              {...rest}
            />
            {!disableValidationMessage && <ValidMessage meta={meta} />}
          </div>
        )
      }}
    </Field>
  )
}

export default NumberField
