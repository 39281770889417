import React, { FC } from 'react'
import CircleTitle from '@/components/v2/molecules/CircleTitle'

interface PairLoanSeparatorProps {
  /** true: 配偶者, false: ご本人 */
  isSpouse?: boolean
}

const PairLoanSeparator: FC<PairLoanSeparatorProps> = (props) => {
  const { isSpouse } = props

  return (
    <>
      <CircleTitle
        className="pt-[32px]"
        title={isSpouse ? '配偶者のローンについて' : 'ご本人のローンについて'}
      />
      <div className="col-span-1 md:col-span-2" />
    </>
  )
}

export default PairLoanSeparator
