import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import MarginContainer from '@/components/v2/atoms/MarginContainer'
import Paper from '@/components/v2/atoms/Paper'
import Heading from '@/components/v2/atoms/Heading'
import Typography from '@/components/v2/atoms/Typography'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import { setPageMeta } from '@/containers/pageMetasSlice'

const DeactivationCompleted: FC = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'マネソル退会完了',
        description: 'マネソル退会完了',
        keyword: 'マネソル退会完了',
      }),
    )
  }, [])

  return (
    <MarginContainer className="w-full sm:w-524 py-40">
      <Paper className="mx-8 sm:mx-0 px-16 py-24 sm:p-40 space-y-24 bg-white">
        <Heading className="text-center">退会完了</Heading>
        <Typography className="mt-24 sm:text-center text-14 text-black-700">
          ご利用ありがとうございました。
          <br />
          また利用する際はこちらよりお願いいたします。
        </Typography>
        <div className="flex justify-center">
          <ButtonPrimary
            className="h-45 w-full md:w-188 text-white"
            onClick={() => (window.location.href = 'https://moneysol.jp')}
          >
            <Typography className="bottom-2 flex-auto pl-30 relative text-16" isBold>
              トップページへ
            </Typography>
            <i className="ico chevron-right mr-17 text-24" />
          </ButtonPrimary>
        </div>
      </Paper>
    </MarginContainer>
  )
}

export default DeactivationCompleted
