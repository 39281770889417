import React, { FC } from 'react'
import {
  AccordionDetails as MuiAccordionDetails,
  AccordionDetailsProps as MuiAccordionDetailsProps,
} from '@material-ui/core'

interface AccordionDetailsProps extends MuiAccordionDetailsProps {}

const AccordionDetails: FC<AccordionDetailsProps> = (props) => {
  return <MuiAccordionDetails {...props} />
}

export default AccordionDetails
