import { createContext } from 'react'
import { reduce as _reduce } from 'lodash'
import { convertNumberYenToManyenFloor } from '@/models/commonsModelFunc'

interface SimulationDetailEditContextValue {
  /** true: 操作許可, false: 操作拒否 */
  isManipulatedApproval: boolean
}

export const SimulationDetailEditContext = createContext<
  SimulationDetailEditContextValue
>({
  isManipulatedApproval: false,
})

export const buildNetAssetGraph = (netAssetGraph: any[]) => {
  return netAssetGraph.map((item: any) => ({
    ...item,
    car_loan_balance: convertNumberYenToManyenFloor(item.car_loan_balance),
    deposit: convertNumberYenToManyenFloor(item.deposit),
    estate_evaluation_total: convertNumberYenToManyenFloor(item.estate_evaluation_total),
    estate_investment_remain_loan_amount_total: convertNumberYenToManyenFloor(
      item.estate_investment_remain_loan_amount_total,
    ),
    housing_loan_balance: convertNumberYenToManyenFloor(item.housing_loan_balance),
    insurance_asset: convertNumberYenToManyenFloor(item.insurance_asset),
    investment_estate_estimated_value: convertNumberYenToManyenFloor(
      item.investment_estate_estimated_value,
    ),
    investment_trust: convertNumberYenToManyenFloor(item.investment_trust),
    other: convertNumberYenToManyenFloor(item.other),
    stock: convertNumberYenToManyenFloor(item.stock),
    bond: convertNumberYenToManyenFloor(item.bond),
    deducted_savings: convertNumberYenToManyenFloor(item.deducted_savings),
    substruction: convertNumberYenToManyenFloor(item.substruction),
    estate_evaluations: _reduce(
      item.estate_evaluations,
      (result: any, value: any, key: string) => ({
        ...result,
        [key]: convertNumberYenToManyenFloor(value),
      }),
      {},
    ),
  }))
}
