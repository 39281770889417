import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { unauthorizedCheck } from '@/containers/accountsSlice'
import {
  getInputEstateInvestments,
  postInputEstateInvestment,
  patchInputEstateInvestment,
  deleteInputEstateInvestment,
} from '@/api/inputs/inputEstateInvestments'
import { ApiError } from '@/models/commonsModel'
import {
  InputEstateInvestment,
  InputEstateInvestmentCreateValues,
  InputEstateInvestmentUpdateValues,
  InputEstateInvestmentDestroyValues,
} from '@/models/inputs/inputEstateInvestmentsModel'
import { convertAnnualRateOfDeclineYear } from '@/models/inputs/inputEstateInvestmentsModelFunc'
import {
  addZeroToStringMonth,
  API_FLAG,
  extractYearOrMonthFromDate,
} from '@/models/commonsModelFunc'
import { AppThunk } from '@/store'
import { sortBy as _sortBy } from 'lodash'

interface InputEstateInvestmentState {
  inputEstateInvestments: InputEstateInvestment[]
  isLoading: boolean
}

const initialState: InputEstateInvestmentState = {
  inputEstateInvestments: [],
  isLoading: false,
}

const inputEstateInvestmentsSlice = createSlice({
  name: 'inputEstateInvestments',
  initialState,
  reducers: {
    setInputEstateInvestment: (state, action: PayloadAction<InputEstateInvestment[]>) => {
      state.inputEstateInvestments = action.payload.map((item) => ({
        ...item,
        delivery_year: extractYearOrMonthFromDate(item.delivery_date, 'year'),
        repair_costs_span: addZeroToStringMonth(item.repair_costs_span as number | null),
        annual_rate_of_decline_type:
          item.input_declines.length > 0 ? API_FLAG.on : API_FLAG.off,
        input_declines_attributes:
          item.input_declines.length > 0 &&
          item.input_declines.map((inputDecline) => ({
            annual_rate_of_decline_year: convertAnnualRateOfDeclineYear(
              String(inputDecline.annual_rate_of_decline_year),
            ),
            annual_rate_of_decline_percent: Number(
              inputDecline.annual_rate_of_decline_percent,
            ),
          })),
      }))

      return state
    },
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    resetInputEstateInvestmentsStore: (state) => {
      state = initialState
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    getInputEstateInvestmentsSuccess: (
      state,
      action: PayloadAction<InputEstateInvestment[]>,
    ) => {
      state.inputEstateInvestments = _sortBy(
        action.payload.map((item) => ({
          ...item,
          delivery_year: extractYearOrMonthFromDate(item.delivery_date, 'year'),
          repair_costs_span: addZeroToStringMonth(
            item.repair_costs_span as number | null,
          ),
          annual_rate_of_decline_type:
            item.input_declines.length > 0 ? API_FLAG.on : API_FLAG.off,
          input_declines_attributes:
            item.input_declines.length > 0 &&
            item.input_declines.map((inputDecline) => ({
              annual_rate_of_decline_year: convertAnnualRateOfDeclineYear(
                String(inputDecline.annual_rate_of_decline_year),
              ),
              annual_rate_of_decline_percent: Number(
                inputDecline.annual_rate_of_decline_percent,
              ),
            })),
        })),
        'id',
      )
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
  },
})

export const {
  setInputEstateInvestment,
  requestStart,
  resetInputEstateInvestmentsStore,
  requestSuccess,
  getInputEstateInvestmentsSuccess,
  requestFailure,
} = inputEstateInvestmentsSlice.actions
export const inputEstateInvestmentsReducer = inputEstateInvestmentsSlice.reducer

export const fetchInputEstateInvestments = (): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  getInputEstateInvestments()
    .then((response) => dispatch(getInputEstateInvestmentsSuccess(response)))
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const createInputEstateInvestment = (
  values: InputEstateInvestmentCreateValues,
  callback: () => void,
  resolve: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postInputEstateInvestment(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error, resolve))
    })
}

export const updateInputEstateInvestment = (
  values: InputEstateInvestmentUpdateValues,
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchInputEstateInvestment(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const destroyInputEstateInvestment = (
  values: { params: InputEstateInvestmentDestroyValues },
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  deleteInputEstateInvestment(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}
