import React, { FC, useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ContactIcon from '@/assets/images/v2/contact.svg'
import DashboardIcon from '@/assets/images/v2/dashboard.svg'
import DatabaseIcon from '@/assets/images/v2/database.svg'
import SettingIcon from '@/assets/images/v2/setting.svg'
import Button from '@/components/v2/atoms/Button'
import Typography from '@/components/v2/atoms/Typography'
import { pathCategory } from '@/components/v2/organisms/Header'
import { fetchAccountShowStepStatusV2, accountLogoutV2 } from '@/containers/accountsSlice'
import { updateTemporaryAccountDemoResetV2 } from '@/containers/admins/temporaryAccountsSlice'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import { AccountStepStatus } from '@/models/accountsModel'
import { ListenerEvent } from '@/models/commonsModel'
import { RootState } from '@/store'
import { removeBaseItemsInLocalStorage } from '@/utils/localStorage/commonLocalStorage'

interface HamburgerMenuProps {
  /** true: オープン, false: クローズ */
  isOpen: boolean
  /** コンポーネント外クリックメソッド */
  onClickAway: () => void
}

const HamburgerMenu: FC<HamburgerMenuProps> = (props) => {
  const dispatch = useDispatch()
  const { handleMovePage } = useCustomHistory()
  const { isOpen, onClickAway } = props
  const hamburgerMenuRef = useRef<HTMLDivElement>(null)
  const { account } = useSelector((state: RootState) => state.accounts)

  const activeCategory: pathCategory = useMemo(() => {
    if (location.pathname.includes('/v2/dashboard')) return 'dashboard'
    if (location.pathname.includes('/v2/myData')) return 'myData'
    if (location.pathname.includes('/v2/simulations')) return 'simulation'
    return ''
  }, [location.pathname])

  const handleClickAway = (event: ListenerEvent<HTMLDivElement>) => {
    if (hamburgerMenuRef.current?.contains(event.target)) return
    onClickAway()
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickAway)

    return () => {
      document.removeEventListener('mousedown', handleClickAway)
    }
  }, [])

  const handleLogout = () => {
    dispatch(accountLogoutV2())
  }

  const handleDataReset = () => {
    const result = confirm('本当にリセットしてよろしいですか？')
    if (!result) return

    dispatch(
      updateTemporaryAccountDemoResetV2(account?.id, () => {
        removeBaseItemsInLocalStorage()
        window.alert('データをリセットを完了しました。画面を再読み込みします。')
        window.location.href = '/v2/dashboards'
      }),
    )
  }

  const getActiveClassNameTextIcon = (category: pathCategory) => {
    return category == activeCategory ? 'stroke-[#000]' : 'stroke-[#000]'
  }

  const handleMoveMyData = () => {
    dispatch(
      fetchAccountShowStepStatusV2((response: AccountStepStatus) => {
        const url = response.has_input_simple_data ? '/v2/myData' : '/v2/myData/simple'
        handleMovePage(url)
      }),
    )
  }

  return (
    <>
      {isOpen && (
        <div
          className="lg:hidden absolute bg-primary-50 left-0 leading-4rem top-55 w-full z-[1000]"
          ref={hamburgerMenuRef}
        >
          {!account['is_disabled_trial_account?'] && (
            <>
              <Button
                className="w-full"
                onClick={() => {
                  onClickAway()
                  handleMovePage('/v2/dashboards')
                }}
              >
                <DashboardIcon className={getActiveClassNameTextIcon('dashboard')} />
                <Typography className="pl-10 text-14" isBold>
                  ダッシュボード
                </Typography>
              </Button>

              <Button
                className="w-full"
                onClick={() => {
                  onClickAway()
                  handleMovePage('/v2/simulations')
                }}
              >
                <DatabaseIcon className={getActiveClassNameTextIcon('simulation')} />
                <Typography className="pl-10 text-14" isBold>
                  シミュレーション
                </Typography>
              </Button>

              <Button
                className="w-full"
                onClick={() => {
                  onClickAway()
                  handleMoveMyData()
                }}
              >
                <ContactIcon className={getActiveClassNameTextIcon('myData')} />
                <Typography className="pl-10 text-14" isBold>
                  マイデータ
                </Typography>
              </Button>
            </>
          )}

          <Button
            className="w-full"
            onClick={() => {
              onClickAway()
              handleMovePage('/v2/settings/account')
            }}
          >
            <SettingIcon />
            <Typography className="pl-10 text-12 text-black-700" isBold>
              個人設定
            </Typography>
          </Button>

          {account?.['is_demo_account?'] && (
            <Button
              className="w-full"
              onClick={() => {
                onClickAway()
                handleDataReset()
              }}
            >
              <Typography className="pl-10 text-12 text-black-700" isBold>
                データリセット
              </Typography>
            </Button>
          )}

          <Button
            className="w-full"
            onClick={() => {
              onClickAway()
              handleLogout()
            }}
          >
            <Typography className="pl-10 text-12 text-black-700" isBold>
              ログアウト
            </Typography>
          </Button>
          <Button
            className="w-full"
          >
            <a href={process.env.BAG_REPORT_URL} target="_blank">
              <Typography className="pl-10 text-12 text-black-700" isBold>
                不具合報告
              </Typography>
            </a>
          </Button>
        </div>
      )}
    </>
  )
}

export default HamburgerMenu
