import React, { FC } from 'react'
import CrossIcon from '@/assets/images/v2/cross.svg'
import Button from '@/components/v2/atoms/Button'
import Typography from '@/components/v2/atoms/Typography'

interface AlertProps {
  /** クラス名 */
  className?: string
  /** メッセージ */
  message: string
  /** アイコン */
  icon: JSX.Element
  /** true: 太字, false: 通常 */
  isBold?: boolean
  /** 背景カラークラス名 */
  bgColorClassName: string
  /** ボーダーカラークラス名 */
  borderColorClassName: string
  /** メッセージカラークラス名 */
  messageColorClassName: string

  hideCloseButton?: boolean

  onClick?: () => void
}

const Alert: FC<AlertProps> = (props) => {
  const {
    className,
    message,
    icon,
    bgColorClassName,
    borderColorClassName,
    messageColorClassName,
    hideCloseButton,
    onClick,
  } = props

  return (
    <div
      className={`${className} ${bgColorClassName} ${borderColorClassName} border border-solid flex items-center justify-between p-12 rounded-4`}
      onClick={onClick}
    >
      <div className="flex items-center">
        <div className="min-w-[30px]">{icon}</div>
        <Typography
          className={`${messageColorClassName} bottom-px relative text-[12px] md:text-[14px] md:font-bold`}
        >
          {message}
        </Typography>
      </div>
      {!hideCloseButton && (
        <Button className="ml-[10px]">
          <CrossIcon />
        </Button>
      )}
    </div>
  )
}

export default Alert
