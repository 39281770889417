import React, { FC, ChangeEvent } from 'react'
import { Field } from 'react-final-form'
import clsx from 'clsx'
import ValidMessage from '@/components/v2/atoms/ValidMessage'

interface TextFieldProps {
  /** タイプ */
  type: string
  /** 名前 */
  name: string
  /** クラス名 */
  className?: string
  /** 幅用クラス名 */
  widthClassName?: string
  /** プレースホルダー */
  placeholder?: string
  /** 最大文字数 */
  maxLength?: number
  /** true: width100%, false: width100%無し */
  isFullWidth?: boolean
  /** true: 非活性, false: 活性 */
  disabled?: boolean
  /** オートコンプリート */
  autoComplete?: string
  /** バリデーションメソッド */
  validate?: (value: any, allValues: any) => void
  /** onChangeメソッド */
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
}

export const SHORT_LENGTH = 72

const TextField: FC<TextFieldProps> = (props) => {
  const {
    type,
    name,
    className,
    widthClassName,
    isFullWidth,
    validate,
    onChange,
    ...rest
  } = props

  return (
    <Field type={type} name={name} validate={validate}>
      {(props) => {
        const { input, meta } = props

        const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
          input.onChange(event)
          onChange?.(event)
        }

        return (
          <div
            className={clsx(className, widthClassName, {
              ['w-full']: isFullWidth,
            })}
          >
            <input
              {...input}
              className={clsx(
                widthClassName,
                'appearance-none bg-white border border-black-300 h-[44px] p-[10px] rounded-[2px] text-black-900 disabled:bg-black-200 hover:border-black-500',
                {
                  ['w-full']: isFullWidth,
                  ['text-right']: input.type === 'number',
                },
              )}
              onChange={handleChange}
              {...rest}
            />
            <ValidMessage meta={meta} />
          </div>
        )
      }}
    </Field>
  )
}

TextField.defaultProps = {
  maxLength: 255,
}

export default TextField
