import { CurrentSalaryIncreaseRateSettingType } from '@/models/commonIncomeBasesModel'

// 仕事タイプ
export const ACTIVE_FLG = {
  active: 0,
  retire: 1,
}

// 現在_上昇率タイプ
export const CURRENT_SALARY_INCREASE_RATE_SETTING_TYPE = {
  average: 'average',
  input: 'input',
  stable: 'stable',
}

export const convertRateSettingType = (
  value: CurrentSalaryIncreaseRateSettingType | undefined,
) => {
  switch (value) {
    case CURRENT_SALARY_INCREASE_RATE_SETTING_TYPE.average:
      return '業界平均に準じて上昇'
    case CURRENT_SALARY_INCREASE_RATE_SETTING_TYPE.input:
      return '上昇率を手入力'
    case CURRENT_SALARY_INCREASE_RATE_SETTING_TYPE.stable:
      return '上昇率を適用しない'
    default:
      return ''
  }
}
