import React, { FC, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'react-final-form'
import { isUndefined as _isUndefined, find as _find } from 'lodash'
import ChevronRightIcon from '@/assets/images/v2/chevron-right.svg'
import Button from '@/components/v2/atoms/Button'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import Grid from '@/components/v2/atoms/Grid'
import TextField from '@/components/v2/atoms/TextField'
import Typography from '@/components/v2/atoms/Typography'
import SelectBox from '@/components/v2/molecules/SelectBox'
import NumberField from '@/components/v2/molecules/NumberField'
import { PENSION_TYPE_SELECT_ITEMS } from '@/constants/formItem/inputs/inputCorporatePensions'
import { RECEIVING_PENSION_AGE_SELECT_ITEMS } from '@/constants/formItem/inputs/inputContributionPensions'
import {
  fetchInputCorporatePensions,
  createInputCorporatePension,
  updateInputCorporatePension,
} from '@/containers/inputs/inputCorporatePensionsSlice'
import { PENSION_TYPE } from '@/models/inputs/inputCorporatePensionsModelFunc'
import { RootState } from '@/store'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import WholeSubmitErrorMessage from '@/components/v2/molecules/WholeSubmitErrorMessage'
import LoadingOverlay from '@/components/v1/atoms/LoadingOverlay'
import SubmitErrorMessage from '@/components/v2/molecules/SubmitErrorMessage'
import clsx from 'clsx'
import {
  composeValidators,
  required,
  zeroOrMoreNumber,
  commaNumberMaxLength,
} from '@/utils/validate'
import { updateResetFlg } from '@/containers/futures/futureBasesSlice'

interface MyDataCorporatePensionEditFormProps {
  personFlg: number
  onClose: () => void
  onSubmit: () => void
}

const MyDataCorporatePensionEditForm: FC<MyDataCorporatePensionEditFormProps> = (
  props,
) => {
  const dispatch = useDispatch()
  const { showFlashMsg } = useFlashAlert()

  const { inputCorporatePensions, isLoading } = useSelector(
    (state: RootState) => state.inputCorporatePensions,
  )

  const { personFlg } = props
  const targetCorporatePensions = useMemo(
    () => _find(inputCorporatePensions, (item) => item.person_flg === personFlg),
    [inputCorporatePensions],
  )

  useEffect(() => {
    if (inputCorporatePensions.length == 0) dispatch(fetchInputCorporatePensions())
  }, [])

  const handleSubmit = (values: any) => {
    const isEdit = !_isUndefined(values.input_corporate_pension.id)
    const { pension_type, receipt_period } = values.input_corporate_pension
    const newValues = {
      input_corporate_pension: {
        ...values.input_corporate_pension,
        person_flg: personFlg,
        receipt_period: pension_type === PENSION_TYPE.confirm ? receipt_period : null,
      },
      person_flg: personFlg,
    }

    return new Promise((resolve) =>
      isEdit
        ? dispatch(
            updateInputCorporatePension(
              newValues,
              () => {
                showFlashMsg(isEdit ? '更新しました' : '作成しました')
                dispatch(fetchInputCorporatePensions())
                dispatch(updateResetFlg())
                props.onSubmit()
              },
              resolve,
            ),
          )
        : dispatch(
            createInputCorporatePension(
              newValues,
              () => {
                showFlashMsg(isEdit ? '更新しました' : '作成しました')
                dispatch(fetchInputCorporatePensions())
                dispatch(updateResetFlg())
                props.onSubmit()
              },
              resolve,
            ),
          ),
    )
  }

  return (
    <>
      <LoadingOverlay isLoading={isLoading} />
      <Form
        onSubmit={handleSubmit}
        initialValues={{ input_corporate_pension: targetCorporatePensions }}
        render={({ handleSubmit, errors, values, submitErrors, submitFailed }) => (
          <form onSubmit={handleSubmit}>
            <Grid className="pb-[64px] grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px]">
              <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                <Typography
                  className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                  isBold
                >
                  企業年金名
                </Typography>
                <TextField
                  type="text"
                  name="input_corporate_pension.name"
                  isFullWidth
                  validate={required}
                />
              </div>

              <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                <Typography
                  className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                  isBold
                >
                  年金タイプ
                </Typography>
                <SelectBox
                  name="input_corporate_pension.pension_type"
                  placeholder="選択する"
                  options={PENSION_TYPE_SELECT_ITEMS}
                  isFullWidth
                  validate={required}
                />
              </div>

              {values?.input_corporate_pension?.pension_type === PENSION_TYPE.confirm && (
                <>
                  <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                    <Typography
                      className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                      isBold
                    >
                      受取期間
                    </Typography>
                    <div className="flex flex-auto justify-end items-baseline">
                      <NumberField
                        name="input_corporate_pension.receipt_period"
                        widthClassName="max-w-[100px]"
                        isFullWidth
                      />
                      <Typography
                        className="min-w-[fit-content] pl-[8px] text-[14px] text-black-800"
                        isBold
                      >
                        年間
                      </Typography>
                    </div>
                  </div>
                  <div className="hidden md:block" />
                </>
              )}

              <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                <Typography
                  className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                  isBold
                >
                  受取年金額
                </Typography>
                <div className="flex flex-auto items-baseline">
                  <SelectBox
                    name="input_corporate_pension.start_receiving_year_at_age"
                    widthClassName="max-w-[100px]"
                    options={RECEIVING_PENSION_AGE_SELECT_ITEMS}
                    validate={composeValidators(zeroOrMoreNumber, required)}
                  />
                  <Typography
                    className="min-w-[fit-content] px-[8px] text-[14px] text-black-800"
                    isBold
                  >
                    歳から年間
                  </Typography>
                  <NumberField
                    name="input_corporate_pension.receiving_pension_amount_manyen"
                    isFullWidth
                    validate={composeValidators(
                      zeroOrMoreNumber,
                      (value: any) => commaNumberMaxLength(value, 4),
                      required,
                    )}
                  />
                  <Typography
                    className="min-w-[fit-content] pl-[8px] text-[14px] text-black-800"
                    isBold
                  >
                    万円
                  </Typography>
                </div>
              </div>
            </Grid>

            <WholeSubmitErrorMessage submitErrors={submitErrors} />
            <SubmitErrorMessage show={!submitErrors && submitFailed} />

            <ButtonPrimary
              className={clsx(
                { ['opacity-50']: Object.keys(errors).length > 0 },
                'w-[246px] h-[64px] mx-auto mb-[16px] ',
              )}
              isDisabled={isLoading}
              onClick={() => handleSubmit(values)}
            >
              <Typography
                className="relative bottom-[2px] pl-[30px] text-[16px] text-white flex-auto"
                isBold
              >
                保存する
              </Typography>
              <ChevronRightIcon className="mr-[17px]" />
            </ButtonPrimary>
            <Button
              className="mx-auto text-[14px] text-black-700"
              onClick={props.onClose}
              isDisabled={isLoading}
            >
              キャンセル
            </Button>
          </form>
        )}
      />
    </>
  )
}

export default MyDataCorporatePensionEditForm
