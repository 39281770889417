import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { isNull as _isNull } from 'lodash'
import { unauthorizedCheck } from '../accountsSlice'
import {
  postFutureMarriageV2,
  patchFutureMarriageV2,
  deleteFutureMarriageV2,
} from '../../api/futures/futureMarriages'
import { ApiError } from '../../models/commonsModel'
import { FutureMarriage } from '../../models/futures/futureMarriagesModel'
import { sumMarriageCost } from '../../models/futures/futureMarriagesModelFunc'
import { AppThunk } from '../../store'

interface FutureMarriageState {
  futureMarriage: FutureMarriage | null
  isLoading: boolean
}

const initialState: FutureMarriageState = {
  futureMarriage: null,
  isLoading: false,
}

const futureMarriagesSlice = createSlice({
  name: 'futureMarriages',
  initialState,
  reducers: {
    setFutureMarriage: (state, action: PayloadAction<FutureMarriage | null>) => {
      const { payload } = action

      if (!_isNull(payload)) {
        state.futureMarriage = {
          ...payload,
          total_cost: sumMarriageCost(payload),
        }
      } else {
        state.futureMarriage = payload
      }

      return state
    },
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
  },
})

export const {
  setFutureMarriage,
  requestStart,
  requestSuccess,
  requestFailure,
} = futureMarriagesSlice.actions
export const futureMarriagesReducer = futureMarriagesSlice.reducer

export const createFutureMarriageV2 = (
  values: any,
  callback: () => void,
  resolve: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postFutureMarriageV2(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error, resolve))
    })
}

export const updateFutureMarriageV2 = (
  values: any,
  id: number,
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchFutureMarriageV2(values, id)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const destroyFutureMarriageV2 = (
  values: any,
  id: number,
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  deleteFutureMarriageV2(values, id)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}
