import React, { ChangeEvent, FC, useMemo, useState } from 'react'
import ChevronDownIcon from '@/assets/images/v2/chevron-down.svg'
import MinusIcon from '@/assets/images/v2/minus.svg'
import PlusIcon from '@/assets/images/v2/plus-white.svg'
import HelpCircleWhiteIcon from '@/assets/images/v2/help-circle-white.svg'
import Button from '@/components/v2/atoms/Button'
import Paper from '@/components/v2/atoms/Paper'
import Tooltip from '@/components/v2/atoms/Tooltip'
import Typography from '@/components/v2/atoms/Typography'
import SelectBoxWithoutFinalForm from '@/components/v2/molecules/SelectBoxWithoutFinalForm'
import { updateJournalV2 } from '@/containers/journalsSlice'
import { useDispatch } from 'react-redux'
import { sortBy as _sortBy } from 'lodash'
import { convertLocaleString } from '@/models/commonsModelFunc'
import clsx from 'clsx'

interface JournalPaperCommonProps {
  /** クラス名 */
  className?: string
  /** タイトル */
  title: string
  /** データ */
  data: any[]
  /** オプション */
  options: { value: any; label: string }[]
  /** ヘッダー背景カラークラス名 */
  headerBgColorClassName: string
  /** データ背景カラークラス名 */
  dataBgColorClassName: string
  /** テキストカラークラス名 */
  textColorClassName: string
  /** アイコン */
  icon: any
  /** 仕訳後のコールバック */
  journalUpdateCallback: () => void
}

const JournalPaperCommon: FC<JournalPaperCommonProps> = (props) => {
  const [isJournalOpen, setIsJournalOpen] = useState(true)

  const {
    className,
    title,
    data,
    options,
    headerBgColorClassName,
    dataBgColorClassName,
    textColorClassName,
    icon,
    journalUpdateCallback,
  } = props

  const dispatch = useDispatch()
  const defaultDisplayNum = 5
  const [displayNum, setDisplayNum] = useState(defaultDisplayNum)
  const handleUpdate = (id: number, money_sol_category_key: any) => {
    dispatch(
      updateJournalV2(
        { id: id, money_sol_category_key: money_sol_category_key },
        journalUpdateCallback,
      ),
    )
  }

  const sortedJournals = useMemo(() => {
    return _sortBy(data, ['date'])
  }, [data])

  const handleMoreClick = () => {
    setDisplayNum(sortedJournals.length)
  }

  const handleLessClick = () => {
    setDisplayNum(defaultDisplayNum)
  }

  return sortedJournals.length > 0 ? (
    <Paper className={`${className} bg-white h-fit-content`}>
      <div
        className={clsx(
          { ['rounded-b-16']: !isJournalOpen },
          `${headerBgColorClassName} flex items-center justify-between p-13 rounded-t-16 mb-[10px]`,
        )}
      >
        <div className="flex items-center">
          <Typography className="bottom-2 pr-5 relative text-18 text-white" isBold>
            {title}
          </Typography>
          <Tooltip
            item="すでにマネーツリーに登録されているクレジットカードの口座からの引き落とし情報はすべて「振替」に登録をお願いします。"
            itemClassName="bg-secondary-50 leading-tight p-[8px] text-[10px] w-[268px] before:border-t-secondary-50"
          >
            <HelpCircleWhiteIcon />
          </Tooltip>
        </div>
        {isJournalOpen ? (
          <>
            <Button onClick={() => setIsJournalOpen(false)}>
              <MinusIcon />
            </Button>
          </>
        ) : (
          <>
            <Button onClick={() => setIsJournalOpen(true)}>
              <PlusIcon />
            </Button>
          </>
        )}
      </div>
      {isJournalOpen && (
        <>
          <div className="p-16">
            {sortedJournals.slice(0, displayNum).map((item) => (
              <div
                key={item.id}
                className={`${dataBgColorClassName} sm:flex items-center justify-between mb-8 px-15 py-8 rounded-20 last:mb-0`}
              >
                <div className="flex justify-start items-center md:space-x-12 mb-[10px] md:mb-0">
                  <div className="flex">
                    <Typography className="pr-16 text-12 min-w-[max-content]">
                      {item.date.replace(/-/g, '/').split('T')[0]}
                    </Typography>
                    <Typography className="text-12 mr-5">
                      {item.description_pretty}
                    </Typography>
                  </div>
                  <Tooltip
                    item={item.mt_link_connected_account_name}
                    itemClassName="bg-secondary-50 leading-tight p-[8px] text-[10px] w-[max-content] before:border-t-secondary-50"
                  >
                    {icon}
                  </Tooltip>
                </div>
                <div className="flex items-center justify-end sm:justify-start">
                  <Typography
                    className={`${textColorClassName} px-16 text-12 min-w-[max-content]`}
                  >
                    {`${convertLocaleString(Math.abs(item.amount))}円`}
                  </Typography>
                  <SelectBoxWithoutFinalForm
                    name={`${item.id}value`}
                    heightClassName="h-31"
                    widthClassName="max-w-[108px]"
                    placeholder="選択する"
                    options={options}
                    value=""
                    onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                      handleUpdate(item.id, e.target.value)
                    }}
                  />
                </div>
              </div>
            ))}

            {sortedJournals.length > 5 &&
              (displayNum === defaultDisplayNum ? (
                <Button className="mx-auto my-0" onClick={handleMoreClick}>
                  <Typography
                    className={`${textColorClassName} bottom-2 relative text-12`}
                    isBold
                  >
                    もっと見る（あと{sortedJournals.length - 5}件あります）
                  </Typography>
                  <ChevronDownIcon className={`stroke-current ${textColorClassName}`} />
                </Button>
              ) : (
                <Button className="mx-auto my-0" onClick={handleLessClick}>
                  <Typography
                    className={`${textColorClassName} bottom-2 relative text-12`}
                    isBold
                  >
                    閉じる
                  </Typography>
                </Button>
              ))}
          </div>
        </>
      )}
    </Paper>
  ) : null
}

export default JournalPaperCommon
