import React, { FC } from 'react'
import Typography from '@/components/v2/atoms/Typography'

const AboutTax: FC = () => {
  return (
    <>
      <Typography>
        固定資産税納税通知書をご確認ください。
        <br />
        「概算を入力」ボタンの押下による計算結果は、
      </Typography>
      <Typography className="u-bold u-ml30">物件価格×0.3%</Typography>
      <Typography>で計算しています。</Typography>
    </>
  )
}

export default AboutTax
