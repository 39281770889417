import React, { FC } from 'react'

interface MarginContainerProps {
  /** クラス名 */
  className?: string
  /** 幅クラス名 */
  widthClassName?: string
}

const MarginContainer: FC<MarginContainerProps> = (props) => {
  const { className, widthClassName, children } = props

  return (
    <div className={`${className} ${widthClassName ?? 'max-w-1080'} mx-auto my-0`}>
      {children}
    </div>
  )
}

export default MarginContainer
