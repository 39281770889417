import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'react-final-form'
import { isEmpty as _isEmpty, isUndefined as _isUndefined } from 'lodash'
import GmailQueryImg from '@/assets/images/gmail-query.png'
import MarginContainer from '@/components/v2/atoms/MarginContainer'
import Paper from '@/components/v2/atoms/Paper'
import Heading from '@/components/v2/atoms/Heading'
import Typography from '@/components/v2/atoms/Typography'
import TextField from '@/components/v2/atoms/TextField'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import FieldBlock from '@/components/v2/molecules/FieldBlock'
import WholeSubmitErrorMessage from '@/components/v2/molecules/WholeSubmitErrorMessage'
import ImageMagnifiableModal from '@/components/v2/organisms/ImageModal'
import { createAccountResendPasswordReset } from '@/containers/accountsSlice'
import { removeAllFlashAlerts, setFlashAlert } from '@/containers/flashAlertsSlice'
import { setPageMeta } from '@/containers/pageMetasSlice'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import LogindRedirect from '@/layouts/v2/LogindRedirect'
import { RootState } from '@/store'
import { composeValidators, required, mailAddress } from '@/utils/validate'

const classes = {
  secondLine: 'ml-25',
}

const MailHelp: FC = () => {
  const dispatch = useDispatch()
  const { handleMovePage } = useCustomHistory()

  const [submitErrors, setSubmitErrors] = useState<any>(undefined)
  const [isImageModalOpen, setIsImageModalOpen] = useState<boolean>(false)

  const { flashAlertMessageObjects } = useSelector(
    (state: RootState) => state.flashAlerts,
  )

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: '本登録の有効期限が切れてしまった場合',
        description: '本登録の有効期限が切れてしまった場合',
        keyword: '本登録の有効期限が切れてしまった場合',
      }),
    )
  }, [])

  const showFlashMsg = () => {
    if (!_isEmpty(flashAlertMessageObjects)) {
      dispatch(removeAllFlashAlerts())
    }

    dispatch(
      setFlashAlert({
        flashAlertMessageObjects: [
          { message: 'メールを送信しました', variant: 'success' },
        ],
      }),
    )
  }

  const handleModalClose = () => {
    setIsImageModalOpen(false)
  }

  const handleAccountSubmit = async (values: any) => {
    const submitErrors = await new Promise((resolve) =>
      dispatch(createAccountResendPasswordReset(values, resolve)),
    )

    if (_isUndefined(submitErrors)) {
      handleMovePage('/v2/accounts/password/mailResendCompleted')
      showFlashMsg()
    } else {
      setSubmitErrors(submitErrors)
    }
  }

  return (
    <LogindRedirect>
      <MarginContainer className="py-40">
        <Paper className="mx-8 xl:mx-0 px-16 py-24 sm:p-36 space-y-24 bg-white">
          <Heading className="text-center">本登録の有効期限が切れてしまった場合</Heading>
          <div className="space-y-24">
            <div className="lg:mx-50">
              <Typography className="text-center">
                ご登録いただいたメールアドレスを入力して『再送する』ボタンをクリックしてください。
              </Typography>
              <div className="mt-24">
                <Form
                  onSubmit={() => {}}
                  render={({ handleSubmit, values, errors }) => (
                    <form className="u-pb10" onSubmit={handleSubmit}>
                      <div className="flex flex-col justify-center items-center space-y-24">
                        <FieldBlock label="メールアドレス" className="w-full md:w-320">
                          <TextField
                            type="text"
                            name="account.mail_address"
                            autoComplete="off"
                            placeholder="sample@example.com"
                            isFullWidth
                            validate={composeValidators(required, mailAddress)}
                          />
                        </FieldBlock>
                        <WholeSubmitErrorMessage submitErrors={submitErrors} />
                        <ButtonPrimary
                          className="h-64 w-full md:w-188 text-white"
                          isDisabled={Object.keys(errors).length > 0}
                          onClick={() => handleAccountSubmit(values)}
                        >
                          <Typography
                            className="bottom-2 flex-auto pl-30 relative text-16"
                            isBold
                          >
                            再送する
                          </Typography>
                          <i className="ico chevron-right mr-17 text-24" />
                        </ButtonPrimary>
                      </div>
                    </form>
                  )}
                />
              </div>
            </div>
          </div>
        </Paper>
        <ImageMagnifiableModal
          src={GmailQueryImg}
          alt="gmail query"
          isOpen={isImageModalOpen}
          onClose={handleModalClose}
        />
      </MarginContainer>
    </LogindRedirect>
  )
}

export default MailHelp
