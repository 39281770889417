import React, { FC, useEffect, useMemo, useContext, ChangeEvent } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import {
  cloneDeep as _cloneDeep,
  isUndefined as _isUndefined,
  split as _split,
} from 'lodash'
import arrayMutators from 'final-form-arrays'
import ChevronRightIcon from '@/assets/images/v2/chevron-right.svg'
import FamilyMonochromaticIcon from '@/assets/images/v2/family-monochromatic.svg'
import Button from '@/components/v2/atoms/Button'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import Grid from '@/components/v2/atoms/Grid'
import MarginContainer from '@/components/v2/atoms/MarginContainer'
import Paper from '@/components/v2/atoms/Paper'
import TextField from '@/components/v2/atoms/TextField'
import Typography from '@/components/v2/atoms/Typography'
import Breadcrumb from '@/components/v2/molecules/Breadcrumb'
import NumberField from '@/components/v2/molecules/NumberField'
import PaperTitle from '@/components/v2/molecules/PaperTitle'
import SelectBox from '@/components/v2/molecules/SelectBox'
import {
  MONTH_KANJI_SELECT_ITEMS,
  TWO_TYPES_YEAR_SELECT_ITEMS,
} from '@/constants/formItem/commons'
import {
  CANCELLATION_REFUND_TYPE_RADIO_ITEMS,
  CONTRACTOR_TYPE_SELECT_ITEMS,
  INSURANCE_CLASSIFICATION_SELECT_ITEMS,
  INSURANCE_FEE_PAYMENT_OCCURRENCE_IN_MONTHS_RADIO_ITEMS,
  INSURANCE_TYPE1_SELECT_ITEMS,
  INSURANCE_TYPE2_SELECT_ITEMS,
  INSURED_TYPE_SELECT_ITEMS,
  NON_LIFE_INSURANCE_FEE_MONTHS_RADIO_ITEMS,
  NON_LIFE_INSURANCE_TYPE1_SELECT_ITEMS,
  REJECT_INSURANCE_TYPE2_SELECT_ITEMS,
  CONTRACTOR_TYPE_WITHOUT_SPOUSE_SELECT_ITEMS,
  INSURED_TYPE_WITHOUT_SPOUSE_SELECT_ITEMS,
} from '@/constants/formItem/commonInsurances'
import { fetchFamilyStructureV2 } from '@/containers/familyStructuresSlice'
import { setPageMeta } from '@/containers/pageMetasSlice'
import { fetchInputCars } from '@/containers/inputs/inputCarsSlice'
import { fetchInputEstateInvestments } from '@/containers/inputs/inputEstateInvestmentsSlice'
import { fetchInputHousings } from '@/containers/inputs/inputHousingsSlice'
import {
  fetchInputInsurances,
  createInputInsurance,
  updateInputInsurance,
} from '@/containers/inputs/inputInsurancesSlice'
import { INSURANCE_CLASSIFICATION } from '@/constants/formItem/commonInsurances'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import {
  CANCELLATION_REFUND_TYPE,
  CONTRACTOR_TYPE,
  INSURANCE_FEE_TYPE,
  INSURANCE_PERIOD_TYPE,
  INSURANCE_TYPE1,
  INSURANCE_TYPE2,
  INSURED_TYPE,
  PURPOSE,
  NON_LIFE_INSURANCE_TYPE1,
  NON_LIFE_INSURANCE_FEE_MONTHS,
  selectChangeTargetForPurpose,
  selectChangeTargetForPeriodType,
  isInsuranceTypeWholeLife,
  selectTargetType,
  isNotInsurancePeriodTypeAge,
  isNotTargetInsuranceType2Selected,
} from '@/models/commonInsurancesModelFunc'
import {
  extractYearOrMonthFromDate,
  THIS_YEAR_NUMBER,
  API_FLAG,
  convertDateMonth,
  convertDateYear,
  isNotEmptyValue,
} from '@/models/commonsModelFunc'
import { convertHouseType } from '@/models/inputs/inputHousingsModelFunc'
import { RootState } from '@/store'
import {
  convertRadioGroupValue,
  buildTwoTypesRangeYearSelectItems,
  buildStandardSelectItems,
  buildAgeLimitSelectItems,
} from '@/utils/formItem'
import {
  required,
  composeValidators,
  commaNumberMaxLength,
  zeroOrMoreNumber,
  limitOrMoreNumber,
} from '@/utils/validate'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import { AccountContext } from '@/models/accountsModelFunc'
import { useParams } from 'react-router-dom'
import LoadingOverlay from '@/components/v1/atoms/LoadingOverlay'
import Tooltip from '@/components/v2/atoms/Tooltip'
import HelpCircleIcon from '@/assets/images/v2/help-circle.svg'
import NumberFieldDecimal from '@/components/v2/molecules/NumberFieldDecimal'

const MyDataInsuranceEdit: FC = () => {
  const dispatch = useDispatch()
  const { handleMovePage } = useCustomHistory()
  const { showFlashMsg } = useFlashAlert()
  const { isAdmin } = useContext(AccountContext)
  const { account_id } = useParams<{ account_id?: string }>()

  const { inputCars } = useSelector((state: RootState) => state.inputCars)
  const { inputHousings } = useSelector((state: RootState) => state.inputHousings)
  const { inputInsurances, isLoading } = useSelector(
    (state: RootState) => state.inputInsurances,
  )
  const { inputEstateInvestments } = useSelector(
    (state: RootState) => state.inputEstateInvestments,
  )
  const { familyStructures } = useSelector((state: RootState) => state.familyStructures)

  // LoadingFlag
  const isLoadingInputCars = useSelector((state: RootState) => state.inputCars.isLoading)
  const isLoadingInputHousings = useSelector(
    (state: RootState) => state.inputHousings.isLoading,
  )
  const isLoadingInputEstateInvestments = useSelector(
    (state: RootState) => state.inputEstateInvestments.isLoading,
  )
  const isLoadingFamilyStructures = useSelector(
    (state: RootState) => state.familyStructures.isLoading,
  )

  const hasSpouse = familyStructures?.spouse_type === API_FLAG.on
  const familyAge = {
    person_current_age: familyStructures?.person_age,
    spouse_current_age: familyStructures?.spouse_age,
  }
  const insuredSubjectCarSelectItems = useMemo(
    () =>
      inputCars.map((item) => ({
        label: item.car_name,
        value: `InputCar_${item.id}`,
      })),
    [inputCars],
  )

  const insuredSubjectPropertySelectItems = useMemo(() => {
    const estateInvestmentSelectItems = inputEstateInvestments.map((item) => ({
      label: item.property_name,
      value: `InputEstateInvestment_${item.id}`,
    }))

    const housingSelectItems = inputHousings.map((item) => ({
      label: convertHouseType(item.house_type),
      value: `InputHousing_${item.id}`,
    }))

    return [...estateInvestmentSelectItems, ...housingSelectItems]
  }, [inputEstateInvestments, inputHousings])

  const buildLifeInitialValues = (item: any) => {
    return {
      ...item,
      classification: INSURANCE_CLASSIFICATION.life,
      insurance_fee_payment_occurrence_in_months: convertRadioGroupValue(
        item.insurance_fee_payment_occurrence_in_months,
      ),
      contracted_year: extractYearOrMonthFromDate(item.contracted_at, 'year'),
      contracted_month: extractYearOrMonthFromDate(item.contracted_at, 'month'),
      insurance_period_finish_year: extractYearOrMonthFromDate(
        item.insurance_period_finish_at,
        'year',
      ),
      insurance_period_finish_month: extractYearOrMonthFromDate(
        item.insurance_period_finish_at,
        'month',
      ),
      insurance_fee_payment_period_finish_year: extractYearOrMonthFromDate(
        item.insurance_fee_payment_period_finish_at,
        'year',
      ),
      insurance_fee_payment_period_finish_month: extractYearOrMonthFromDate(
        item.insurance_fee_payment_period_finish_at,
        'month',
      ),
      cancellation_refund_at_year: extractYearOrMonthFromDate(
        item.cancellation_refund_at,
        'year',
      ),
      cancellation_refund_at_month: extractYearOrMonthFromDate(
        item.cancellation_refund_at,
        'month',
      ),
    }
  }

  const buildNonLifeInitialValues = (item: any) => {
    const isBulk = item?.insurance_fee_payment_occurrence_in_months >= 24
    const insuranceFeeMonths = isBulk
      ? NON_LIFE_INSURANCE_FEE_MONTHS.bulk
      : String(item?.insurance_fee_payment_occurrence_in_months)

    const insuranceFeeYear = isBulk
      ? item?.insurance_fee_payment_occurrence_in_months / 12
      : null

    const insuredSubjectCars =
      isNotEmptyValue(item?.input_cars) && item?.input_cars.length > 0
        ? item?.input_cars.map((item: any) => `InputCar_${item.id}`)
        : []

    const inputEstateInvestments =
      isNotEmptyValue(item?.input_estate_investments) &&
      item?.input_estate_investments.length > 0
        ? item?.input_estate_investments.map(
            (item: any) => `InputEstateInvestment_${item.id}`,
          )
        : []

    const inputHousings =
      isNotEmptyValue(item?.input_housings) && item?.input_housings.length > 0
        ? item?.input_housings.map((item: any) => `InputHousing_${item.id}`)
        : []

    return {
      id: item.id,
      classification: INSURANCE_CLASSIFICATION.nonLife,
      company_name: item?.company_name,
      insurance_type1: item?.insurance_type1,
      insurance_fee_payment_occurrence_in_months: insuranceFeeMonths,
      contracted_at: extractYearOrMonthFromDate(item?.contracted_at, 'year'),
      insurance_fee: item?.insurance_fee,
      insurance_fee_payment_occurrence_in_year: insuranceFeeYear,
      insured_subject_cars: insuredSubjectCars[0],
      insured_subject_properties: [...inputEstateInvestments, ...inputHousings][0],
    }
  }

  const buildInitialValuesBase = useMemo(
    () => ({
      input_insurances: inputInsurances.map((item) =>
        item['is_life_insurance?']
          ? buildLifeInitialValues(item)
          : buildNonLifeInitialValues(item),
      ),
    }),
    [inputInsurances],
  )

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'マイデータ | 保険 編集',
        description: 'マイデータ | 保険 編集',
        keyword: 'マイデータ | 保険 編集',
      }),
    )
    dispatch(fetchInputInsurances())
    dispatch(fetchInputCars())
    dispatch(fetchInputHousings())
    dispatch(fetchInputEstateInvestments())
    dispatch(fetchFamilyStructureV2())
  }, [])

  const submitLifeInsuranceFunc = (values: any) => {
    const isEdit = !_isUndefined(values.id)
    let newValues = {
      ...values,
      contracted_at: convertDateMonth(values.contracted_year, values.contracted_month),
    }

    // 「保険種別1, 保険種別2
    if (
      isNotTargetInsuranceType2Selected(values?.insurance_type1, values?.insurance_type2)
    ) {
      newValues.insurance_type2 = null
    }

    // 保険種別1: 生命保険以外を選択
    if (values?.insurance_type1 !== INSURANCE_TYPE1.life) {
      newValues.cancellation_refund_amount_manyen = null
      newValues.insured_income_monthly_manyen = null
      newValues.maturity_money = null
    }

    // 「保険種別1: 生命保険を選択」 かつ 「保険種別2: 終身型以外を選択」
    if (
      values?.insurance_type1 === INSURANCE_TYPE1.life &&
      values?.insurance_type2 !== INSURANCE_TYPE2.wholeLife
    ) {
      newValues.cancellation_refund_amount_input_type = null
      newValues.cancellation_refund_amount_manyen = null
      newValues.cancellation_refund_rate_percent = null
      newValues.cancellation_refund_target = null
      newValues.cancellation_refund_at = null
      newValues.cancellation_refund_at_age = null
      delete newValues.cancellation_refund_type
    }

    // 「保険種別1: 生命保険を選択」 かつ 「保険種別2: 終身型を選択」 かつ 「利用目的: 貯蓄のためを選択」
    if (
      values?.insurance_type1 === INSURANCE_TYPE1.life &&
      values?.insurance_type2 === INSURANCE_TYPE2.wholeLife &&
      values?.purpose === PURPOSE.savings
    ) {
      newValues.cancellation_refund_at = convertDateMonth(
        values.cancellation_refund_at_year,
        values.cancellation_refund_at_month,
      )
    }

    // 「保険種別1: 生命保険を選択」 かつ 「保険種別2: 終身型を選択」 かつ 「利用目的: 保障のためを選択」
    if (
      values?.insurance_type1 === INSURANCE_TYPE1.life &&
      values?.insurance_type2 === INSURANCE_TYPE2.wholeLife &&
      values?.purpose === PURPOSE.security
    ) {
      newValues.cancellation_refund_amount_input_type = null
      newValues.cancellation_refund_amount_manyen = null
      newValues.cancellation_refund_rate_percent = null
      newValues.cancellation_refund_target = null
      newValues.cancellation_refund_at = null
      newValues.cancellation_refund_at_age = null
      delete newValues.cancellation_refund_type
    }

    // 「保険種別1: 生命保険を選択」 かつ 「保険種別2: 収入保障保険以外を選択」
    if (
      values?.insurance_type1 === INSURANCE_TYPE1.life &&
      values?.insurance_type2 !== INSURANCE_TYPE2.incomeSecurity
    ) {
      newValues.insured_income_monthly_manyen = null
    }

    // 「保険種別1: 生命保険を選択」 かつ 「保険種別2: 収入保障を選択」
    if (
      values?.insurance_type1 === INSURANCE_TYPE1.life &&
      values?.insurance_type2 === INSURANCE_TYPE2.incomeSecurity
    ) {
      newValues.insurance_amount_manyen = null
    }

    // 「保険種別1: 生命保険を選択」 かつ 「保険種別2: 養老保険以外を選択」
    if (
      values?.insurance_type1 === INSURANCE_TYPE1.life &&
      values?.insurance_type2 !== INSURANCE_TYPE2.endowment
    ) {
      newValues.maturity_money_type = null
      newValues.maturity_money = null
    }

    // 保険種別: 個人年金保険以外を選択
    if (values?.insurance_type1 !== INSURANCE_TYPE1.personalAnnuity) {
      newValues.receiving_pension_at_age = null
      newValues.receiving_pension_amount = null
      newValues.receiving_years = null
      newValues.receiving_guaranteed_years = null
    }

    // 契約者: 自由入力以外を選択
    if (values?.contractor_type !== CONTRACTOR_TYPE.otherPersonContract) {
      newValues.contractor_name = null
      newValues.insurance_fee_payment_period_finish_at = null
    }

    // 契約者: 自由入力を選択
    if (values?.contractor_type === CONTRACTOR_TYPE.otherPersonContract) {
      newValues.insurance_fee_payment_period_finish_at_age = null
      newValues.insurance_fee_payment_period_finish_at = convertDateMonth(
        values.insurance_fee_payment_period_finish_year,
        values.insurance_fee_payment_period_finish_month,
      )
    }

    // 被保険者: 自由入力以外を選択
    if (values?.insured_type !== INSURED_TYPE.otherPersonInsured) {
      newValues.insured_name = null
      newValues.insurance_period_finish_at = null
    }

    // 被保険者: 自由入力を選択
    if (values?.insured_type === INSURED_TYPE.otherPersonInsured) {
      newValues.insurance_period_finish_at_age = null
      newValues.insurance_period_finish_at = convertDateMonth(
        values.insurance_period_finish_year,
        values.insurance_period_finish_month,
      )
    }

    // 保険期間: 終身を選択
    if (values?.insurance_period_type === INSURANCE_PERIOD_TYPE.wholeLife) {
      newValues.insurance_period_finish_at = null
      newValues.insurance_period_finish_at_age = null
    }

    // 保険の払い込み: 終身を選択
    if (values?.insurance_fee_payment_period_type === INSURANCE_PERIOD_TYPE.wholeLife) {
      newValues.insurance_fee_payment_period_finish_at = null
      newValues.insurance_fee_payment_period_finish_at_age = null
    }

    // 保険の払い込み: 1回のみを選択
    if (values?.insurance_fee_payment_period_type === INSURANCE_PERIOD_TYPE.oneTime) {
      newValues.insurance_fee_payment_period_finish_at = null
      newValues.insurance_fee_payment_occurrence_in_months = null
      newValues.insurance_fee_payment_period_finish_at_age = null
    }

    // 満期金: なしを選択
    if (values?.maturity_money_type === INSURANCE_FEE_TYPE.none) {
      newValues.maturity_money = null
    }

    newValues = { id: values.id, input_insurance: newValues }
    return new Promise((resolve) =>
      isEdit
        ? dispatch(
            updateInputInsurance(
              newValues,
              () => {
                showFlashMsg(isEdit ? '更新しました' : '作成しました')
                handleMovePage(
                  isAdmin
                    ? `/v2/accounts/${account_id}/myData/insurances`
                    : '/v2/myData/insurances',
                )
              },
              resolve,
            ),
          )
        : dispatch(
            createInputInsurance(
              newValues,
              () => {
                showFlashMsg(isEdit ? '更新しました' : '作成しました')
                handleMovePage(
                  isAdmin
                    ? `/v2/accounts/${account_id}/myData/insurances`
                    : '/v2/myData/insurances',
                )
              },
              resolve,
            ),
          ),
    )
  }

  const submitNonLifeInsuranceFunc = (values: any) => {
    const isEdit = !_isUndefined(values.id)
    const newValues = {
      id: values.id,
      input_insurance: {
        ...values,
        contracted_at: convertDateYear(values?.contracted_at),
        purpose: PURPOSE.security,
        insurance_fee_payment_period_type: INSURANCE_PERIOD_TYPE.wholeLife,
        insurance_fee_type: INSURANCE_FEE_TYPE.input,
        insurance_fee_payment_period_finish_at: null,
        contractor_type: CONTRACTOR_TYPE.otherPersonContract,
        insured_type: INSURED_TYPE.otherPersonInsured,
      },
    }

    if (
      values?.insurance_fee_payment_occurrence_in_months ===
      NON_LIFE_INSURANCE_FEE_MONTHS.bulk
    ) {
      newValues.input_insurance.insurance_fee_payment_occurrence_in_months =
        values?.insurance_fee_payment_occurrence_in_year * 12
    }

    if (values?.insurance_type1 === NON_LIFE_INSURANCE_TYPE1.vehicle) {
      newValues.input_insurance.insured_subjects_attributes = [
        {
          subject_type: _split(values.insured_subject_cars, '_')[0],
          subject_id: _split(values.insured_subject_cars, '_')[1],
        },
      ]
    }

    if (
      values?.insurance_type1 === NON_LIFE_INSURANCE_TYPE1.fire ||
      values?.insurance_type1 === NON_LIFE_INSURANCE_TYPE1.earthquake
    ) {
      newValues.input_insurance.insured_subjects_attributes = [
        {
          subject_type: _split(values.insured_subject_properties, '_')[0],
          subject_id: _split(values.insured_subject_properties, '_')[1],
        },
      ]
    }

    return new Promise((resolve) =>
      isEdit
        ? dispatch(
            updateInputInsurance(newValues, () => {
              showFlashMsg(isEdit ? '更新しました' : '作成しました')
              resolve({})
            }),
          )
        : dispatch(
            createInputInsurance(newValues, () => {
              showFlashMsg(isEdit ? '更新しました' : '作成しました')
              resolve({})
            }),
          ),
    )
  }

  const handleSubmit = (values: any) => {
    _cloneDeep(values.input_insurances)
      .reduce(
        (acc: any, item: any) =>
          acc.then(
            item.classification === INSURANCE_CLASSIFICATION.life
              ? () => submitLifeInsuranceFunc(item)
              : () => submitNonLifeInsuranceFunc(item),
          ),
        Promise.resolve(),
      )
      .then(() =>
        handleMovePage(
          isAdmin
            ? `/v2/accounts/${account_id}/myData/insurances`
            : '/v2/myData/insurances',
        ),
      )
  }

  return (
    <>
      <LoadingOverlay
        isLoading={
          isLoading ||
          isLoadingFamilyStructures ||
          isLoadingInputEstateInvestments ||
          isLoadingInputHousings ||
          isLoadingInputCars
        }
      />
      <MarginContainer className="px-[8px] md:px-[32px] lg:px-0 py-[8px] md:py-[40px]">
        <Breadcrumb
          className="pb-[10px] md:pb-[32px]"
          data={[
            {
              name: 'マイデータ',
              url: isAdmin ? `/v2/accounts/${account_id}/myData` : '/v2/myData',
            },
            {
              name: 'マイデータ設定',
              url: isAdmin
                ? `/v2/accounts/${account_id}/myData/insurances`
                : '/v2/myData/insurances',
            },
            { name: '保険の設定' },
          ]}
        />

        <PaperTitle
          className="mb-[16px] md:mb-[40px]"
          title="保険"
          description="日々の収入や支出を登録することで改善案などのご提案が可能になります"
          icon={<FamilyMonochromaticIcon />}
          smallIcon={
            <FamilyMonochromaticIcon
              className="h-[82px] w-[130px]"
              viewBox="0 0 117 154"
            />
          }
        />

        <Paper className="bg-white p-[24px] md:p-[40px]">
          <Typography
            className="mb-[32px] pb-[10px] text-[24px] md:text-[28px] text-black-800 border-b-2 border-black-300"
            isBold
          >
            保険について
          </Typography>

          <Form
            onSubmit={handleSubmit}
            initialValues={buildInitialValuesBase}
            mutators={{ ...arrayMutators }}
            render={({
              handleSubmit,
              values,
              errors,
              form: {
                change,
                mutators: { push },
              },
            }) => (
              <form onSubmit={handleSubmit}>
                <FieldArray name="input_insurances">
                  {({ fields }) =>
                    fields.map((name, index) => (
                      <div key={name} className="pb-[64px]">
                        <Grid className="grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px]">
                          <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                            <Typography
                              className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                              isBold
                            >
                              保険の分類
                            </Typography>
                            <SelectBox
                              name={`${name}classification`}
                              placeholder="選択する"
                              isFullWidth
                              options={INSURANCE_CLASSIFICATION_SELECT_ITEMS}
                              validate={required}
                            />
                          </div>
                          <div className="hidden md:block" />

                          {fields.value[index]?.classification ===
                            INSURANCE_CLASSIFICATION.life && (
                            <>
                              <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                                <Typography
                                  className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                                  isBold
                                >
                                  契約年月
                                </Typography>
                                <div className="flex flex-auto items-baseline">
                                  <SelectBox
                                    name={`${name}contracted_year`}
                                    className="pr-[8px]"
                                    placeholder="年を選択"
                                    isFullWidth
                                    options={buildTwoTypesRangeYearSelectItems(
                                      THIS_YEAR_NUMBER - 40,
                                      THIS_YEAR_NUMBER,
                                      true,
                                    )}
                                    validate={required}
                                  />
                                  <SelectBox
                                    name={`${name}contracted_month`}
                                    widthClassName="max-w-[100px]"
                                    placeholder="月を選択"
                                    options={MONTH_KANJI_SELECT_ITEMS}
                                    validate={required}
                                  />
                                </div>
                              </div>

                              <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                                <Typography
                                  className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                                  isBold
                                >
                                  保険会社
                                </Typography>
                                <TextField
                                  type="text"
                                  name={`${name}company_name`}
                                  isFullWidth
                                  validate={required}
                                />
                              </div>

                              <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                                <Typography
                                  className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                                  isBold
                                >
                                  保険種別
                                </Typography>
                                <div className="flex flex-auto items-baseline">
                                  <SelectBox
                                    name={`${name}insurance_type1`}
                                    placeholder="選択する"
                                    isFullWidth
                                    options={INSURANCE_TYPE1_SELECT_ITEMS}
                                    validate={required}
                                  />
                                  {fields.value[index]?.insurance_type1 !==
                                    INSURANCE_TYPE1.personalAnnuity && (
                                    <SelectBox
                                      name={`${name}insurance_type2`}
                                      className="pl-[8px]"
                                      placeholder="選択する"
                                      isFullWidth
                                      options={
                                        fields.value[index]?.insurance_type1 ===
                                        INSURANCE_TYPE1.life
                                          ? INSURANCE_TYPE2_SELECT_ITEMS
                                          : REJECT_INSURANCE_TYPE2_SELECT_ITEMS
                                      }
                                      validate={required}
                                      onChange={(
                                        event: ChangeEvent<HTMLSelectElement>,
                                      ) => {
                                        selectChangeTargetForPurpose(
                                          event.target.value,
                                          `${name}purpose`,
                                          change,
                                        )
                                        selectChangeTargetForPeriodType(
                                          event.target.value,
                                          `${name}insurance_fee_payment_period_type`,
                                          change,
                                        )
                                        selectChangeTargetForPeriodType(
                                          event.target.value,
                                          `${name}insurance_period_type`,
                                          change,
                                        )
                                      }}
                                    />
                                  )}
                                </div>
                              </div>

                              <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                                <Typography
                                  className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                                  isBold
                                >
                                  利用目的
                                </Typography>
                                <SelectBox
                                  name={`${name}purpose`}
                                  placeholder="選択する"
                                  isFullWidth
                                  options={[
                                    {
                                      label: '保障のため',
                                      value: PURPOSE.security,
                                    },
                                    {
                                      label: '貯蓄のため',
                                      value: PURPOSE.savings,
                                    },
                                  ]}
                                  validate={required}
                                />
                              </div>

                              <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                                <Typography
                                  className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                                  isBold
                                >
                                  契約者
                                </Typography>
                                <div className="flex flex-auto items-baseline">
                                  <SelectBox
                                    name={`${name}contractor_type`}
                                    placeholder="選択する"
                                    isFullWidth
                                    options={
                                      hasSpouse
                                        ? CONTRACTOR_TYPE_SELECT_ITEMS
                                        : CONTRACTOR_TYPE_WITHOUT_SPOUSE_SELECT_ITEMS
                                    }
                                    validate={required}
                                  />
                                  {fields.value[index]?.contractor_type ===
                                    CONTRACTOR_TYPE.otherPersonContract && (
                                    <TextField
                                      type="text"
                                      name={`${name}contractor_name`}
                                      className="pl-[8px]"
                                      isFullWidth
                                      validate={required}
                                    />
                                  )}
                                </div>
                              </div>

                              <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                                <Typography
                                  className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                                  isBold
                                >
                                  被保険者
                                </Typography>
                                <div className="flex flex-auto items-baseline">
                                  <SelectBox
                                    name={`${name}insured_type`}
                                    placeholder="選択する"
                                    isFullWidth
                                    options={
                                      hasSpouse
                                        ? INSURED_TYPE_SELECT_ITEMS
                                        : INSURED_TYPE_WITHOUT_SPOUSE_SELECT_ITEMS
                                    }
                                    validate={required}
                                  />
                                  {fields.value[index]?.insured_type ===
                                    INSURED_TYPE.otherPersonInsured && (
                                    <TextField
                                      type="text"
                                      name={`${name}insured_name`}
                                      className="pl-[8px]"
                                      isFullWidth
                                      validate={required}
                                    />
                                  )}
                                </div>
                              </div>

                              <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                                <Typography
                                  className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                                  isBold
                                >
                                  保険期間
                                </Typography>
                                <div className="flex-auto">
                                  <SelectBox
                                    name={`${name}insurance_period_type`}
                                    placeholder="選択する"
                                    isFullWidth
                                    options={[
                                      {
                                        label: '終身',
                                        value: INSURANCE_PERIOD_TYPE.wholeLife,
                                        isDisabled:
                                          fields.value[index]?.insurance_type2 ===
                                          INSURANCE_TYPE2.incomeSecurity,
                                      },
                                      {
                                        label: '入力する',
                                        value: INSURANCE_PERIOD_TYPE.age,
                                        isDisabled: isInsuranceTypeWholeLife(
                                          fields.value[index]?.insurance_type2,
                                        ),
                                      },
                                    ]}
                                    validate={required}
                                  />
                                  {fields.value[index]?.insurance_period_type ===
                                    INSURANCE_PERIOD_TYPE.age && (
                                    <div className="flex flex-auto justify-end items-baseline pt-[8px]">
                                      {fields.value[index]?.insured_type !==
                                      INSURED_TYPE.otherPersonInsured ? (
                                        <>
                                          <Typography
                                            className="min-w-[fit-content] pr-[8px] text-[14px] text-black-800"
                                            isBold
                                          >
                                            被保険者が
                                          </Typography>
                                          <SelectBox
                                            name={`${name}insurance_period_finish_at_age`}
                                            widthClassName="max-w-[100px]"
                                            isFullWidth
                                            isPlaceholderHidden
                                            isDisabled={isNotInsurancePeriodTypeAge(
                                              fields.value[index]?.insurance_period_type,
                                            )}
                                            options={buildStandardSelectItems(
                                              buildAgeLimitSelectItems(
                                                selectTargetType(
                                                  fields.value[index]?.contractor_type,
                                                  familyAge,
                                                ),
                                              ),
                                              60,
                                              '選択する',
                                            )}
                                            validate={required}
                                          />
                                          <Typography
                                            className="pl-[8px] text-[14px] text-black-800"
                                            isBold
                                          >
                                            歳まで
                                          </Typography>
                                        </>
                                      ) : (
                                        <>
                                          <SelectBox
                                            name={`${name}insurance_period_finish_year`}
                                            className="pr-[8px]"
                                            placeholder="年を選択"
                                            isFullWidth
                                            isDisabled={isNotInsurancePeriodTypeAge(
                                              fields.value[index]?.insurance_period_type,
                                            )}
                                            options={TWO_TYPES_YEAR_SELECT_ITEMS}
                                            validate={required}
                                          />
                                          <SelectBox
                                            name={`${name}insurance_period_finish_month`}
                                            widthClassName="max-w-[100px]"
                                            placeholder="月を選択"
                                            isDisabled={isNotInsurancePeriodTypeAge(
                                              fields.value[index]?.insurance_period_type,
                                            )}
                                            options={MONTH_KANJI_SELECT_ITEMS}
                                            validate={required}
                                          />
                                          <Typography
                                            className="min-w-[fit-content] pl-[8px] text-[14px] text-black-800"
                                            isBold
                                          >
                                            まで
                                          </Typography>
                                        </>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                                <Typography
                                  className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                                  isBold
                                >
                                  保険料の払い込み
                                </Typography>
                                <div className="flex-auto">
                                  <SelectBox
                                    name={`${name}insurance_fee_payment_period_type`}
                                    placeholder="選択する"
                                    isFullWidth
                                    options={[
                                      {
                                        label: '終身',
                                        value: INSURANCE_PERIOD_TYPE.wholeLife,
                                        isDisabled:
                                          fields.value[index]?.insurance_type2 ===
                                          INSURANCE_TYPE2.incomeSecurity,
                                      },
                                      {
                                        label: '1回のみ',
                                        value: INSURANCE_PERIOD_TYPE.oneTime,
                                      },
                                      {
                                        label: '入力する',
                                        value: INSURANCE_PERIOD_TYPE.age,
                                      },
                                    ]}
                                    validate={required}
                                  />
                                  {fields.value[index]
                                    ?.insurance_fee_payment_period_type ===
                                    INSURANCE_PERIOD_TYPE.age && (
                                    <div className="flex flex-auto justify-end items-baseline pt-[8px]">
                                      {fields.value[index]?.contractor_type !==
                                      CONTRACTOR_TYPE.otherPersonContract ? (
                                        <>
                                          <Typography
                                            className="min-w-[fit-content] pr-[8px] text-[14px] text-black-800"
                                            isBold
                                          >
                                            契約者が
                                          </Typography>
                                          <SelectBox
                                            name={`${name}insurance_fee_payment_period_finish_at_age`}
                                            widthClassName="max-w-[100px]"
                                            isFullWidth
                                            isPlaceholderHidden
                                            isDisabled={isNotInsurancePeriodTypeAge(
                                              fields.value[index]
                                                ?.insurance_fee_payment_period_type,
                                            )}
                                            options={buildStandardSelectItems(
                                              buildAgeLimitSelectItems(
                                                selectTargetType(
                                                  fields.value[index]?.contractor_type,
                                                  familyAge,
                                                ),
                                              ),
                                              60,
                                              '選択する',
                                            )}
                                            validate={required}
                                          />
                                          <Typography
                                            className="pl-[8px] text-[14px] text-black-800"
                                            isBold
                                          >
                                            歳まで
                                          </Typography>
                                        </>
                                      ) : (
                                        <>
                                          <SelectBox
                                            name={`${name}insurance_fee_payment_period_finish_year`}
                                            className="pr-[8px]"
                                            placeholder="年を選択"
                                            isFullWidth
                                            isDisabled={isNotInsurancePeriodTypeAge(
                                              fields.value[index]
                                                ?.insurance_fee_payment_period_type,
                                            )}
                                            options={TWO_TYPES_YEAR_SELECT_ITEMS}
                                            validate={required}
                                          />
                                          <SelectBox
                                            name={`${name}insurance_fee_payment_period_finish_month`}
                                            widthClassName="max-w-[100px]"
                                            placeholder="月を選択"
                                            isDisabled={isNotInsurancePeriodTypeAge(
                                              fields.value[index]
                                                ?.insurance_fee_payment_period_type,
                                            )}
                                            options={MONTH_KANJI_SELECT_ITEMS}
                                            validate={required}
                                          />
                                          <Typography
                                            className="min-w-[fit-content] pl-[8px] text-[14px] text-black-800"
                                            isBold
                                          >
                                            まで
                                          </Typography>
                                        </>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>

                              {fields.value[index]?.insurance_fee_payment_period_type !==
                                INSURANCE_PERIOD_TYPE.oneTime && (
                                <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                                  <Typography
                                    className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                                    isBold
                                  >
                                    支払い頻度
                                  </Typography>
                                  <SelectBox
                                    name={`${name}insurance_fee_payment_occurrence_in_months`}
                                    placeholder="選択する"
                                    isFullWidth
                                    options={
                                      INSURANCE_FEE_PAYMENT_OCCURRENCE_IN_MONTHS_RADIO_ITEMS
                                    }
                                    validate={required}
                                  />
                                </div>
                              )}

                              <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                                <Typography
                                  className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                                  isBold
                                >
                                  １回あたりの保険料
                                </Typography>
                                <div className="flex flex-auto items-baseline">
                                  <NumberField
                                    name={`${name}insurance_fee`}
                                    isFullWidth
                                    validate={composeValidators(
                                      required,
                                      zeroOrMoreNumber,
                                      (value: any) => commaNumberMaxLength(value, 7),
                                    )}
                                  />
                                  <Typography
                                    className="pl-[8px] text-[14px] text-black-800"
                                    isBold
                                  >
                                    円
                                  </Typography>
                                </div>
                              </div>

                              {fields.value[index]?.insurance_type1 ===
                                INSURANCE_TYPE1.life &&
                                fields.value[index]?.insurance_type2 !==
                                  INSURANCE_TYPE2.incomeSecurity && (
                                  <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                                    <div className="min-w-[140px] lg:min-w-[164px] flex items-center">
                                      <Typography
                                        className="pb-[10px] md:pb-0 text-[14px] text-black-800"
                                        isBold
                                      >
                                        死亡保険金額
                                      </Typography>
                                      <div className="mx-[10px]">
                                        <Tooltip
                                          item="死亡保険金額とは、保険事故（死亡・病気など）が生じた場合に支払われる金額のことです。"
                                          itemClassName="w-[80px] sm:w-[200px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                                        >
                                          <HelpCircleIcon />
                                        </Tooltip>
                                      </div>
                                    </div>

                                    <div className="flex flex-auto items-baseline">
                                      <NumberField
                                        name={`${name}insurance_amount_manyen`}
                                        isFullWidth
                                        validate={composeValidators(
                                          required,
                                          zeroOrMoreNumber,
                                          (value: any) => commaNumberMaxLength(value, 10),
                                        )}
                                      />
                                      <Typography
                                        className="min-w-[fit-content] pl-[8px] text-[14px] text-black-800"
                                        isBold
                                      >
                                        万円
                                      </Typography>
                                    </div>
                                  </div>
                                )}

                              {fields.value[index]?.insurance_type1 ===
                                INSURANCE_TYPE1.life && (
                                <>
                                  {fields.value[index]?.insurance_type2 ===
                                    INSURANCE_TYPE2.wholeLife &&
                                    fields.value[index]?.purpose === PURPOSE.savings && (
                                      <>
                                        <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                                          <Typography
                                            className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                                            isBold
                                          >
                                            解約返戻金返還時期
                                          </Typography>
                                          <div className="flex flex-auto justify-end items-baseline">
                                            {fields.value[index]?.insured_type !==
                                            INSURED_TYPE.otherPersonInsured ? (
                                              <>
                                                <SelectBox
                                                  name={`${name}cancellation_refund_at_age`}
                                                  widthClassName="max-w-[100px]"
                                                  isFullWidth
                                                  isPlaceholderHidden
                                                  options={buildStandardSelectItems(
                                                    buildAgeLimitSelectItems(
                                                      selectTargetType(
                                                        fields.value[index]
                                                          ?.contractor_type,
                                                        familyAge,
                                                      ),
                                                    ),
                                                    60,
                                                    '選択する',
                                                  )}
                                                  validate={required}
                                                />
                                                <Typography
                                                  className="min-w-[fit-content] pl-[8px] text-[14px] text-black-800"
                                                  isBold
                                                >
                                                  歳時
                                                </Typography>
                                              </>
                                            ) : (
                                              <>
                                                <SelectBox
                                                  name={`${name}cancellation_refund_at_year`}
                                                  className="pr-[8px]"
                                                  placeholder="年を選択"
                                                  isFullWidth
                                                  options={TWO_TYPES_YEAR_SELECT_ITEMS}
                                                  validate={required}
                                                />
                                                <SelectBox
                                                  name={`${name}cancellation_refund_at_month`}
                                                  widthClassName="max-w-[100px]"
                                                  placeholder="月を選択"
                                                  options={MONTH_KANJI_SELECT_ITEMS}
                                                  validate={required}
                                                />
                                              </>
                                            )}
                                          </div>
                                        </div>

                                        <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                                          <Typography
                                            className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                                            isBold
                                          >
                                            解約返戻金算出方法
                                          </Typography>
                                          <div className="flex-auto">
                                            <SelectBox
                                              name={`${name}cancellation_refund_amount_input_type`}
                                              placeholder="選択する"
                                              isFullWidth
                                              options={
                                                CANCELLATION_REFUND_TYPE_RADIO_ITEMS
                                              }
                                              validate={required}
                                            />
                                            <div className="flex flex-auto justify-end items-baseline pt-[8px]">
                                              {fields.value[index]
                                                ?.cancellation_refund_amount_input_type ===
                                              CANCELLATION_REFUND_TYPE.amount ? (
                                                <>
                                                  <NumberField
                                                    name={`${name}cancellation_refund_amount_manyen`}
                                                    isFullWidth
                                                    validate={composeValidators(
                                                      required,
                                                      zeroOrMoreNumber,
                                                      (value: any) =>
                                                        commaNumberMaxLength(value, 6),
                                                    )}
                                                  />
                                                  <Typography
                                                    className="min-w-[fit-content] pl-[8px] text-[14px] text-black-800"
                                                    isBold
                                                  >
                                                    万円
                                                  </Typography>
                                                </>
                                              ) : (
                                                <>
                                                  <NumberFieldDecimal
                                                    name={`${name}cancellation_refund_rate_percent`}
                                                    widthClassName="max-w-100"
                                                    isFullWidth
                                                    validate={composeValidators(
                                                      required,
                                                      zeroOrMoreNumber,
                                                      (value: any) =>
                                                        commaNumberMaxLength(value, 4),
                                                    )}
                                                  />
                                                  <Typography
                                                    className="min-w-[fit-content] pl-[8px] text-[14px] text-black-800"
                                                    isBold
                                                  >
                                                    ％
                                                  </Typography>
                                                </>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    )}

                                  {fields.value[index]?.insurance_type2 ===
                                    INSURANCE_TYPE2.incomeSecurity && (
                                    <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                                      <Typography
                                        className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                                        isBold
                                      >
                                        保険月額
                                      </Typography>
                                      <div className="flex flex-auto items-baseline">
                                        <Typography
                                          className="min-w-[fit-content] pr-[8px] text-[14px] text-black-800"
                                          isBold
                                        >
                                          月々
                                        </Typography>
                                        <NumberField
                                          name={`${name}insured_income_monthly_manyen`}
                                          isFullWidth
                                          validate={composeValidators(
                                            required,
                                            zeroOrMoreNumber,
                                            (value: any) =>
                                              commaNumberMaxLength(value, 6),
                                          )}
                                        />
                                        <Typography
                                          className="min-w-[fit-content] pl-[8px] text-[14px] text-black-800"
                                          isBold
                                        >
                                          万円
                                        </Typography>
                                      </div>
                                    </div>
                                  )}

                                  {fields.value[index]?.insurance_type2 ===
                                    INSURANCE_TYPE2.endowment && (
                                    <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                                      <Typography
                                        className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                                        isBold
                                      >
                                        満期金
                                      </Typography>
                                      <div className="flex-auto">
                                        <SelectBox
                                          name={`${name}maturity_money_type`}
                                          placeholder="選択する"
                                          isFullWidth
                                          options={[
                                            {
                                              label: 'あり',
                                              value: INSURANCE_FEE_TYPE.input,
                                            },
                                            {
                                              label: 'なし',
                                              value: INSURANCE_FEE_TYPE.none,
                                            },
                                          ]}
                                          validate={required}
                                        />
                                        {fields.value[index]?.maturity_money_type ===
                                          INSURANCE_FEE_TYPE.input && (
                                          <div className="flex flex-auto items-baseline pt-[8px]">
                                            <NumberField
                                              name={`${name}maturity_money`}
                                              isFullWidth
                                              disabled={
                                                fields.value[index]
                                                  ?.maturity_money_type !==
                                                INSURANCE_FEE_TYPE.input
                                              }
                                              validate={
                                                fields.value[index]
                                                  ?.maturity_money_type ===
                                                INSURANCE_FEE_TYPE.input
                                                  ? composeValidators(
                                                      required,
                                                      zeroOrMoreNumber,
                                                      (value: any) =>
                                                        commaNumberMaxLength(value, 10),
                                                    )
                                                  : composeValidators(
                                                      zeroOrMoreNumber,
                                                      (value: any) =>
                                                        commaNumberMaxLength(value, 10),
                                                    )
                                              }
                                            />
                                            <Typography
                                              className="pl-[8px] text-[14px] text-black-800"
                                              isBold
                                            >
                                              円
                                            </Typography>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </>
                              )}

                              {fields.value[index]?.insurance_type1 ===
                                INSURANCE_TYPE1.personalAnnuity && (
                                <>
                                  <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                                    <Typography
                                      className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                                      isBold
                                    >
                                      年金受取年齢
                                    </Typography>
                                    <div className="flex flex-auto justify-end items-baseline">
                                      <NumberField
                                        name={`${name}receiving_pension_at_age`}
                                        widthClassName="max-w-100"
                                        validate={composeValidators(
                                          required,
                                          zeroOrMoreNumber,
                                        )}
                                      />
                                      <Typography
                                        className="pl-[8px] text-[14px] text-black-800"
                                        isBold
                                      >
                                        歳
                                      </Typography>
                                    </div>
                                  </div>

                                  <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                                    <Typography
                                      className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                                      isBold
                                    >
                                      受取額
                                    </Typography>
                                    <div className="flex flex-auto items-baseline">
                                      <NumberField
                                        name={`${name}receiving_pension_amount`}
                                        isFullWidth
                                        validate={composeValidators(
                                          required,
                                          zeroOrMoreNumber,
                                          (value: any) => commaNumberMaxLength(value, 10),
                                        )}
                                      />
                                      <Typography
                                        className="pl-[8px] text-[14px] text-black-800"
                                        isBold
                                      >
                                        円/年
                                      </Typography>
                                    </div>
                                  </div>

                                  <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                                    <Typography
                                      className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                                      isBold
                                    >
                                      受取期間
                                    </Typography>
                                    <div className="flex flex-auto justify-end items-baseline">
                                      <NumberField
                                        name={`${name}receiving_years`}
                                        widthClassName="max-w-100"
                                        validate={composeValidators(
                                          required,
                                          zeroOrMoreNumber,
                                        )}
                                      />
                                      <Typography
                                        className="pl-[8px] text-[14px] text-black-800"
                                        isBold
                                      >
                                        年
                                      </Typography>
                                    </div>
                                  </div>

                                  <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                                    <Typography
                                      className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                                      isBold
                                    >
                                      保証期間
                                    </Typography>
                                    <div className="flex flex-auto justify-end items-baseline">
                                      <NumberField
                                        name={`${name}receiving_guaranteed_years`}
                                        widthClassName="max-w-100"
                                        validate={composeValidators(
                                          required,
                                          zeroOrMoreNumber,
                                        )}
                                      />
                                      <Typography
                                        className="pl-[8px] text-[14px] text-black-800"
                                        isBold
                                      >
                                        年
                                      </Typography>
                                    </div>
                                  </div>
                                </>
                              )}
                            </>
                          )}

                          {fields.value[index]?.classification === 'non_life' && (
                            <>
                              <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                                <Typography
                                  className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                                  isBold
                                >
                                  保険名称
                                </Typography>
                                <TextField
                                  type="text"
                                  name={`${name}company_name`}
                                  isFullWidth
                                  validate={required}
                                />
                              </div>

                              <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                                <Typography
                                  className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                                  isBold
                                >
                                  保険の種類
                                </Typography>
                                <SelectBox
                                  name={`${name}insurance_type1`}
                                  isFullWidth
                                  options={NON_LIFE_INSURANCE_TYPE1_SELECT_ITEMS}
                                  validate={required}
                                />
                              </div>

                              <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                                <Typography
                                  className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                                  isBold
                                >
                                  保険料の支払い方法
                                </Typography>
                                <SelectBox
                                  name={`${name}insurance_fee_payment_occurrence_in_months`}
                                  isFullWidth
                                  options={NON_LIFE_INSURANCE_FEE_MONTHS_RADIO_ITEMS}
                                  validate={required}
                                />
                              </div>

                              <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                                <Typography
                                  className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                                  isBold
                                >
                                  契約年
                                </Typography>
                                <SelectBox
                                  name={`${name}contracted_at`}
                                  isFullWidth
                                  options={buildTwoTypesRangeYearSelectItems(
                                    1970,
                                    THIS_YEAR_NUMBER,
                                    true,
                                  )}
                                  validate={required}
                                />
                              </div>

                              {fields.value[index]
                                ?.insurance_fee_payment_occurrence_in_months ===
                                NON_LIFE_INSURANCE_FEE_MONTHS.bulk && (
                                <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                                  <Typography
                                    className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                                    isBold
                                  >
                                    更新頻度
                                  </Typography>
                                  <div className="flex flex-auto justify-end items-baseline">
                                    <NumberField
                                      name={`${name}insurance_fee_payment_occurrence_in_year`}
                                      widthClassName="max-w-100"
                                      validate={composeValidators(
                                        required,
                                        (value: any) => limitOrMoreNumber(value, 2),
                                      )}
                                    />
                                    <Typography
                                      className="pl-[8px] text-[14px] text-black-800 min-w-[fit-content]"
                                      isBold
                                    >
                                      年に１回
                                    </Typography>
                                  </div>
                                </div>
                              )}

                              <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                                <Typography
                                  className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                                  isBold
                                >
                                  保険料
                                </Typography>
                                <div className="flex flex-auto items-baseline">
                                  <NumberField
                                    name={`${name}insurance_fee`}
                                    isFullWidth
                                    validate={composeValidators(required, (value: any) =>
                                      commaNumberMaxLength(value, 10),
                                    )}
                                  />
                                  <Typography
                                    className="pl-[8px] text-[14px] text-black-800"
                                    isBold
                                  >
                                    円
                                  </Typography>
                                </div>
                              </div>

                              {fields.value[index]?.insurance_type1 ===
                                NON_LIFE_INSURANCE_TYPE1.vehicle && (
                                <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                                  <Typography
                                    className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                                    isBold
                                  >
                                    お車
                                  </Typography>
                                  <SelectBox
                                    name={`${name}insured_subject_cars`}
                                    isFullWidth
                                    options={insuredSubjectCarSelectItems}
                                    validate={required}
                                  />
                                </div>
                              )}

                              {(fields.value[index]?.insurance_type1 ===
                                NON_LIFE_INSURANCE_TYPE1.fire ||
                                fields.value[index]?.insurance_type1 ===
                                  NON_LIFE_INSURANCE_TYPE1.earthquake) && (
                                <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                                  <Typography
                                    className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                                    isBold
                                  >
                                    物件
                                  </Typography>
                                  <SelectBox
                                    name={`${name}insured_subject_properties`}
                                    isFullWidth
                                    options={insuredSubjectPropertySelectItems}
                                    validate={required}
                                  />
                                </div>
                              )}
                            </>
                          )}
                        </Grid>
                        {index !== 0 && (
                          <Button
                            className="w-[112px] h-[37px] mt-[10px] ml-auto border border-secondary-500 rounded-[4px] hover:bg-secondary-50"
                            onClick={() => fields.remove(index)}
                          >
                            <Typography className="text-[14px] text-secondary-500" isBold>
                              削除する
                            </Typography>
                          </Button>
                        )}
                      </div>
                    ))
                  }
                </FieldArray>

                <Button
                  className="w-full h-[53px] mb-[32px] border border-dashed border-primary-500 text-[14px] text-primary-500 hover:bg-primary-50"
                  onClick={() => push('input_insurances', {})}
                >
                  <Typography isBold>追加</Typography>
                </Button>

                <ButtonPrimary
                  className="w-[246px] h-[64px] mx-auto mb-[16px]"
                  isDisabled={Object.keys(errors).length > 0 || isLoading}
                  onClick={() => handleSubmit(values)}
                >
                  <Typography
                    className="relative bottom-[2px] pl-[30px] text-[16px] text-white flex-auto"
                    isBold
                  >
                    保存する
                  </Typography>
                  <ChevronRightIcon className="mr-[17px]" />
                </ButtonPrimary>
                <Button
                  className="mx-auto text-[14px] text-black-700"
                  onClick={() =>
                    handleMovePage(
                      isAdmin
                        ? `/v2/accounts/${account_id}/myData/insurances`
                        : '/v2/myData/insurances',
                    )
                  }
                  isDisabled={isLoading}
                >
                  キャンセル
                </Button>
              </form>
            )}
          />
        </Paper>
      </MarginContainer>
    </>
  )
}

export default MyDataInsuranceEdit
