import React, { FC } from 'react'
import { ResponsiveContainer, PieChart, Pie, Cell } from 'recharts'

interface GraphPieProps {
  /** クラス名 */
  className?: string
  /** 高さ */
  height: number
  /** データキー */
  dataKey: string
  /** 名前キー */
  nameKey: string
  /** 内側_半径 */
  innerRadius: number | string
  /** 外側_半径 */
  outerRadius: number | string
  /** データ */
  data: { [key: string]: any }[]
  /** 色 */
  colors: string[]
}

const GraphPie: FC<GraphPieProps> = (props) => {
  const {
    className,
    height,
    dataKey,
    nameKey,
    innerRadius,
    outerRadius,
    data,
    colors,
  } = props

  return (
    <ResponsiveContainer className={className} height={height}>
      <PieChart>
        <Pie
          dataKey={dataKey}
          nameKey={nameKey}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          data={data}
          startAngle={90}
          endAngle={-270}
        >
          {colors.map((color, index) => (
            <Cell key={index} fill={color} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  )
}

export default GraphPie
