import { filter as _filter, find as _find } from 'lodash'
import {
  InvestmentType,
  TransferType,
  ContinualTransferType,
} from '@/models/commonAssetsModel'
import { API_BOOLEAN_FLAG } from '@/models/commonsModelFunc'
import { InputAssetInvestment } from '@/models/inputs/inputAssetInvestmentsModel'

export const LAST_AGE = 120

// 投資設定種別
export const TRANSFER_TYPE = {
  once: 'once',
  countinual: 'countinual',
}

// 資産の振替先
export const INVESTMENT_TYPE = {
  trustFund: 'trust_fund',
  stock: 'stock',
  bond: 'bond',
  deductedSavings: 'deducted_savings',
  other: 'other',
}

// 投資設定種別
export const ANNUAL_TYPE = {
  yes: 'yes',
  no: 'no',
}

// 年間積立額種別
export const CONTINUAL_TRANSFER_TYPE = {
  input: 'input',
  rate: 'rate',
}

export const filterInputAssetInvestment = (
  investmentType: InvestmentType,
  inputAssetInvestment: InputAssetInvestment[],
) => {
  return _filter(inputAssetInvestment, { investment_type: investmentType })
}

export const hasInvestmentType = (
  investmentType: InvestmentType,
  inputAssetInvestments: InputAssetInvestment[],
) => {
  return !!_find(filterInputAssetInvestment(investmentType, inputAssetInvestments), {
    investment_type: investmentType,
  })
}

export const convertTransferType = (value: TransferType) => {
  switch (value) {
    case TRANSFER_TYPE.once:
      return '一時金の投資を設定する'
    case TRANSFER_TYPE.countinual:
      return '積立金の投資を設定する'
    default:
      return ''
  }
}

// 資産振替先の表示変換
export const convertInvestmentType = (value: InvestmentType | null) => {
  switch (value) {
    case INVESTMENT_TYPE.trustFund:
      return '投資信託'
    case INVESTMENT_TYPE.stock:
      return '株式'
    case INVESTMENT_TYPE.deductedSavings:
      return '社内預金・財形・持株など'
    case INVESTMENT_TYPE.bond:
      return '債券'
    case INVESTMENT_TYPE.other:
      return 'その他'
    default:
      return ''
  }
}

export const convertContinualTransferType = (value: ContinualTransferType | null) => {
  switch (value) {
    case CONTINUAL_TRANSFER_TYPE.input:
      return '金額で設定'
    case CONTINUAL_TRANSFER_TYPE.rate:
      return '割合で設定'
    default:
      return ''
  }
}

// 課税対象
export const convertIsTaxChargable = (value: boolean | null) => {
  switch (value) {
    case API_BOOLEAN_FLAG.on:
      return '課税対象とする'
    case API_BOOLEAN_FLAG.off:
      return '課税対象としない'
    default:
      return ''
  }
}
