import React, { FC, useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import Typography from '@/components/v2/atoms/Typography'
import Button from '@/components/v2/atoms/Button'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import { setPageMeta } from '@/containers/pageMetasSlice'
import { fetchFamilyStructureV2 } from '@/containers/familyStructuresSlice'
import { useAccountControl } from '@/hooks/useAccountControl'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import { AccountContext } from '@/models/accountsModelFunc'
import { convertLocaleString, isNotEmptyValue } from '@/models/commonsModelFunc'
import {
  convertSchoolType,
  convertUniversitySchoolType,
  isChildbirthWithinRange,
  SCHOOL_AGE,
} from '@/models/inputs/inputPlanTuitionsModelFunc'
import MyDataListWrapper from '@/templates/v2/myData/_ListWrapper'
import { RootState } from '@/store'

const MyDataFamily: FC = () => {
  const dispatch = useDispatch()
  const { handleMovePage } = useCustomHistory()
  const { isAdmin } = useContext(AccountContext)
  const { isMyDataManipulatedApproval } = useAccountControl()
  const { account_id } = useParams<{ account_id?: string }>()

  const { familyStructures, familyStructureChildren } = useSelector(
    (state: RootState) => state.familyStructures,
  )
  const person_birth_year: number = Number(familyStructures?.birth_day?.split('-')[0])
  const person_birth_month: number = Number(familyStructures?.birth_day?.split('-')[1])
  const person_birth_day: number = Number(familyStructures?.birth_day?.split('-')[2])

  const spouse_birth_year: number = Number(
    familyStructures?.spouse_birth_day?.split('-')[0],
  )
  const spouse_birth_month: number = Number(
    familyStructures?.spouse_birth_day?.split('-')[1],
  )
  const spouse_birth_day: number = Number(
    familyStructures?.spouse_birth_day?.split('-')[2],
  )

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'マイデータ | 家族',
        description: 'マイデータ | 家族',
        keyword: 'マイデータ | 家族',
      }),
    )
    if (!familyStructures) dispatch(fetchFamilyStructureV2())
  }, [])

  const contentArr = [
    {
      school: (item: any) => (
        <Typography>{`0〜${item.is_early_born ? 2 : 3}歳児保育`}</Typography>
      ),
      school_grade: (item: any) => <Typography>0歳</Typography>,
      on_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(item.input_plan_tuitions[0]?.tuition_1_manyen)}万円`}
        </Typography>
      ),
      off_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[0]?.off_campus_expenses_1_manyen,
          )}万円`}
        </Typography>
      ),
      total: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[0]?.off_campus_expenses_1_manyen +
              item.input_plan_tuitions[0]?.tuition_1_manyen,
          )}万円`}
        </Typography>
      ),
      comperison: (item: any) => SCHOOL_AGE.toddler0,
    },
    {
      school: (item: any) => (
        <Typography>{`0〜${item.is_early_born ? 2 : 3}歳児保育`}</Typography>
      ),
      school_grade: (item: any) => <Typography>1歳</Typography>,
      on_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(item.input_plan_tuitions[0]?.tuition_2_manyen)}万円`}
        </Typography>
      ),
      off_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[0]?.off_campus_expenses_2_manyen,
          )}万円`}
        </Typography>
      ),
      total: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[0]?.off_campus_expenses_2_manyen +
              item.input_plan_tuitions[0]?.tuition_2_manyen,
          )}万円`}
        </Typography>
      ),
      comperison: (item: any) => SCHOOL_AGE.toddler1,
    },
    {
      school: (item: any) => (
        <Typography>{`0〜${item.is_early_born ? 2 : 3}歳児保育`}</Typography>
      ),
      school_grade: (item: any) => <Typography>2歳</Typography>,
      on_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(item.input_plan_tuitions[0]?.tuition_3_manyen)}万円`}
        </Typography>
      ),
      off_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[0]?.off_campus_expenses_3_manyen,
          )}万円`}
        </Typography>
      ),
      total: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[0]?.off_campus_expenses_3_manyen +
              item.input_plan_tuitions[0]?.tuition_3_manyen,
          )}万円`}
        </Typography>
      ),
      comperison: (item: any) => SCHOOL_AGE.toddler2,
    },
    {
      school: (item: any) => <Typography>0〜3歳児保育</Typography>,
      school_grade: (item: any) => <Typography>3歳</Typography>,
      on_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(item.input_plan_tuitions[0]?.tuition_4_manyen)}万円`}
        </Typography>
      ),
      off_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[0]?.off_campus_expenses_4_manyen,
          )}万円`}
        </Typography>
      ),
      total: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[0]?.off_campus_expenses_4_manyen +
              item.input_plan_tuitions[0]?.tuition_4_manyen,
          )}万円`}
        </Typography>
      ),

      comperison: (item: any) => SCHOOL_AGE.toddler3,
    },

    {
      school: (item: any) => (
        <Typography>
          {convertSchoolType(item.input_plan_tuitions[1]?.school_type)} 幼稚園／保育園
        </Typography>
      ),
      school_grade: (item: any) => <Typography>年少</Typography>,
      on_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(item.input_plan_tuitions[1]?.tuition_1_manyen)}万円`}
        </Typography>
      ),
      off_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[1]?.off_campus_expenses_1_manyen,
          )}万円`}
        </Typography>
      ),
      total: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[1]?.off_campus_expenses_1_manyen +
              item.input_plan_tuitions[1]?.tuition_1_manyen,
          )}万円`}
        </Typography>
      ),
      comperison: (item: any) => SCHOOL_AGE.kindergarten1,
    },
    {
      school: (item: any) => (
        <Typography>
          {convertSchoolType(item.input_plan_tuitions[1]?.school_type)} 幼稚園／保育園
        </Typography>
      ),
      school_grade: (item: any) => <Typography>年中</Typography>,
      on_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(item.input_plan_tuitions[1]?.tuition_2_manyen)}万円`}
        </Typography>
      ),
      off_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[1]?.off_campus_expenses_2_manyen,
          )}万円`}
        </Typography>
      ),
      total: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[1]?.off_campus_expenses_2_manyen +
              item.input_plan_tuitions[1]?.tuition_2_manyen,
          )}万円`}
        </Typography>
      ),
      comperison: (item: any) => SCHOOL_AGE.kindergarten2,
    },
    {
      school: (item: any) => (
        <Typography>
          {convertSchoolType(item.input_plan_tuitions[1]?.school_type)} 幼稚園／保育園
        </Typography>
      ),
      school_grade: (item: any) => <Typography>年長</Typography>,
      on_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(item.input_plan_tuitions[1]?.tuition_3_manyen)}万円`}
        </Typography>
      ),
      off_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[1]?.off_campus_expenses_3_manyen,
          )}万円`}
        </Typography>
      ),
      total: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[1]?.off_campus_expenses_3_manyen +
              item.input_plan_tuitions[1]?.tuition_3_manyen,
          )}万円`}
        </Typography>
      ),
      comperison: (item: any) => SCHOOL_AGE.kindergarten3,
    },

    {
      school: (item: any) => (
        <Typography>
          {convertSchoolType(item.input_plan_tuitions[2]?.school_type)} 小学校
        </Typography>
      ),
      school_grade: (item: any) => <Typography>1年生</Typography>,
      on_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(item.input_plan_tuitions[2]?.tuition_1_manyen)}万円`}
        </Typography>
      ),
      off_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[2]?.off_campus_expenses_1_manyen,
          )}万円`}
        </Typography>
      ),
      total: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[2]?.off_campus_expenses_1_manyen +
              item.input_plan_tuitions[2]?.tuition_1_manyen,
          )}万円`}
        </Typography>
      ),
      comperison: (item: any) => SCHOOL_AGE.primarySchool1,
    },
    {
      school: (item: any) => (
        <Typography>
          {convertSchoolType(item.input_plan_tuitions[2]?.school_type)} 小学校
        </Typography>
      ),
      school_grade: (item: any) => <Typography>2年生</Typography>,
      on_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(item.input_plan_tuitions[2]?.tuition_2_manyen)}万円`}
        </Typography>
      ),
      off_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[2]?.off_campus_expenses_2_manyen,
          )}万円`}
        </Typography>
      ),
      total: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[2]?.off_campus_expenses_2_manyen +
              item.input_plan_tuitions[2]?.tuition_2_manyen,
          )}万円`}
        </Typography>
      ),
      comperison: (item: any) => SCHOOL_AGE.primarySchool2,
    },
    {
      school: (item: any) => (
        <Typography>
          {convertSchoolType(item.input_plan_tuitions[2]?.school_type)} 小学校
        </Typography>
      ),
      school_grade: (item: any) => <Typography>3年生</Typography>,
      on_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(item.input_plan_tuitions[2]?.tuition_3_manyen)}万円`}
        </Typography>
      ),
      off_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[2]?.off_campus_expenses_3_manyen,
          )}万円`}
        </Typography>
      ),
      total: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[2]?.off_campus_expenses_3_manyen +
              item.input_plan_tuitions[2]?.tuition_3_manyen,
          )}万円`}
        </Typography>
      ),
      comperison: (item: any) => SCHOOL_AGE.primarySchool3,
    },
    {
      school: (item: any) => (
        <Typography>
          {convertSchoolType(item.input_plan_tuitions[2]?.school_type)} 小学校
        </Typography>
      ),
      school_grade: (item: any) => <Typography>4年生</Typography>,
      on_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(item.input_plan_tuitions[2]?.tuition_4_manyen)}万円`}
        </Typography>
      ),
      off_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[2]?.off_campus_expenses_4_manyen,
          )}万円`}
        </Typography>
      ),
      total: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[2]?.off_campus_expenses_4_manyen +
              item.input_plan_tuitions[2]?.tuition_4_manyen,
          )}万円`}
        </Typography>
      ),
      comperison: (item: any) => SCHOOL_AGE.primarySchool4,
    },
    {
      school: (item: any) => (
        <Typography>
          {convertSchoolType(item.input_plan_tuitions[2]?.school_type)} 小学校
        </Typography>
      ),
      school_grade: (item: any) => <Typography>5年生</Typography>,
      on_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(item.input_plan_tuitions[2]?.tuition_5_manyen)}万円`}
        </Typography>
      ),
      off_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[2]?.off_campus_expenses_5_manyen,
          )}万円`}
        </Typography>
      ),
      total: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[2]?.off_campus_expenses_5_manyen +
              item.input_plan_tuitions[2]?.tuition_5_manyen,
          )}万円`}
        </Typography>
      ),
      comperison: (item: any) => SCHOOL_AGE.primarySchool5,
    },
    {
      school: (item: any) => (
        <Typography>
          {convertSchoolType(item.input_plan_tuitions[2]?.school_type)} 小学校
        </Typography>
      ),
      school_grade: (item: any) => <Typography>6年生</Typography>,
      on_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(item.input_plan_tuitions[2]?.tuition_6_manyen)}万円`}
        </Typography>
      ),
      off_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[2]?.off_campus_expenses_6_manyen,
          )}万円`}
        </Typography>
      ),
      total: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[2]?.off_campus_expenses_6_manyen +
              item.input_plan_tuitions[2]?.tuition_6_manyen,
          )}万円`}
        </Typography>
      ),
      comperison: (item: any) => SCHOOL_AGE.primarySchool6,
    },

    {
      school: (item: any) => (
        <Typography>
          {convertSchoolType(item.input_plan_tuitions[3]?.school_type)} 中学校
        </Typography>
      ),
      school_grade: (item: any) => <Typography>1年生</Typography>,
      on_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(item.input_plan_tuitions[3]?.tuition_1_manyen)}万円`}
        </Typography>
      ),
      off_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[3]?.off_campus_expenses_1_manyen,
          )}万円`}
        </Typography>
      ),
      total: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[3]?.off_campus_expenses_1_manyen +
              item.input_plan_tuitions[3]?.tuition_1_manyen,
          )}万円`}
        </Typography>
      ),
      comperison: (item: any) => SCHOOL_AGE.juniorHighSchool1,
    },
    {
      school: (item: any) => (
        <Typography>
          {convertSchoolType(item.input_plan_tuitions[3]?.school_type)} 中学校
        </Typography>
      ),
      school_grade: (item: any) => <Typography>2年生</Typography>,
      on_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(item.input_plan_tuitions[3]?.tuition_2_manyen)}万円`}
        </Typography>
      ),
      off_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[3]?.off_campus_expenses_2_manyen,
          )}万円`}
        </Typography>
      ),
      total: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[3]?.off_campus_expenses_2_manyen +
              item.input_plan_tuitions[3]?.tuition_2_manyen,
          )}万円`}
        </Typography>
      ),
      comperison: (item: any) => SCHOOL_AGE.juniorHighSchool2,
    },
    {
      school: (item: any) => (
        <Typography>
          {convertSchoolType(item.input_plan_tuitions[3]?.school_type)} 中学校
        </Typography>
      ),
      school_grade: (item: any) => <Typography>3年生</Typography>,
      on_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(item.input_plan_tuitions[3]?.tuition_3_manyen)}万円`}
        </Typography>
      ),
      off_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[3]?.off_campus_expenses_3_manyen,
          )}万円`}
        </Typography>
      ),
      total: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[3]?.off_campus_expenses_3_manyen +
              item.input_plan_tuitions[3]?.tuition_3_manyen,
          )}万円`}
        </Typography>
      ),
      comperison: (item: any) => SCHOOL_AGE.juniorHighSchool3,
    },

    {
      school: (item: any) => (
        <Typography>
          {convertSchoolType(item.input_plan_tuitions[4]?.school_type)} 高校
        </Typography>
      ),
      school_grade: (item: any) => <Typography>1年生</Typography>,
      on_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(item.input_plan_tuitions[4]?.tuition_1_manyen)}万円`}
        </Typography>
      ),
      off_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[4]?.off_campus_expenses_1_manyen,
          )}万円`}
        </Typography>
      ),
      total: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[4]?.off_campus_expenses_1_manyen +
              item.input_plan_tuitions[4]?.tuition_1_manyen,
          )}万円`}
        </Typography>
      ),
      comperison: (item: any) => SCHOOL_AGE.highSchool1,
    },
    {
      school: (item: any) => (
        <Typography>
          {convertSchoolType(item.input_plan_tuitions[4]?.school_type)} 高校
        </Typography>
      ),
      school_grade: (item: any) => <Typography>2年生</Typography>,
      on_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(item.input_plan_tuitions[4]?.tuition_2_manyen)}万円`}
        </Typography>
      ),
      off_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[4]?.off_campus_expenses_2_manyen,
          )}万円`}
        </Typography>
      ),
      total: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[4]?.off_campus_expenses_2_manyen +
              item.input_plan_tuitions[4]?.tuition_2_manyen,
          )}万円`}
        </Typography>
      ),
      comperison: (item: any) => SCHOOL_AGE.highSchool2,
    },
    {
      school: (item: any) => (
        <Typography>
          {convertSchoolType(item.input_plan_tuitions[4]?.school_type)} 高校
        </Typography>
      ),
      school_grade: (item: any) => <Typography>3年生</Typography>,
      on_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(item.input_plan_tuitions[4]?.tuition_3_manyen)}万円`}
        </Typography>
      ),
      off_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[4]?.off_campus_expenses_3_manyen,
          )}万円`}
        </Typography>
      ),
      total: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[4]?.off_campus_expenses_3_manyen +
              item.input_plan_tuitions[4]?.tuition_3_manyen,
          )}万円`}
        </Typography>
      ),
      comperison: (item: any) => SCHOOL_AGE.highSchool3,
    },

    {
      school: (item: any) => (
        <Typography>
          {convertUniversitySchoolType(item.input_plan_tuitions[5]?.school_type)} 大学
        </Typography>
      ),
      school_grade: (item: any) => <Typography>1年生</Typography>,
      on_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(item.input_plan_tuitions[5]?.tuition_1_manyen)}万円`}
        </Typography>
      ),
      off_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[5]?.off_campus_expenses_1_manyen,
          )}万円`}
        </Typography>
      ),
      total: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[5]?.off_campus_expenses_1_manyen +
              item.input_plan_tuitions[5]?.tuition_1_manyen,
          )}万円`}
        </Typography>
      ),
      comperison: (item: any) =>
        isNotEmptyValue(item.input_plan_tuitions[5]?.tuition_1_manyen)
          ? SCHOOL_AGE.university1
          : 0,
    },
    {
      school: (item: any) => (
        <Typography>
          {convertUniversitySchoolType(item.input_plan_tuitions[5]?.school_type)} 大学
        </Typography>
      ),
      school_grade: (item: any) => <Typography>2年生</Typography>,
      on_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(item.input_plan_tuitions[5]?.tuition_2_manyen)}万円`}
        </Typography>
      ),
      off_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[5]?.off_campus_expenses_2_manyen,
          )}万円`}
        </Typography>
      ),
      total: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[5]?.off_campus_expenses_2_manyen +
              item.input_plan_tuitions[5]?.tuition_2_manyen,
          )}万円`}
        </Typography>
      ),
      comperison: (item: any) =>
        isNotEmptyValue(item.input_plan_tuitions[5]?.tuition_2_manyen)
          ? SCHOOL_AGE.university2
          : 0,
    },
    {
      school: (item: any) => (
        <Typography>
          {convertUniversitySchoolType(item.input_plan_tuitions[5]?.school_type)} 大学
        </Typography>
      ),
      school_grade: (item: any) => <Typography>3年生</Typography>,
      on_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(item.input_plan_tuitions[5]?.tuition_3_manyen)}万円`}
        </Typography>
      ),
      off_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[5]?.off_campus_expenses_3_manyen,
          )}万円`}
        </Typography>
      ),
      total: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[5]?.off_campus_expenses_3_manyen +
              item.input_plan_tuitions[5]?.tuition_3_manyen,
          )}万円`}
        </Typography>
      ),
      comperison: (item: any) =>
        isNotEmptyValue(item.input_plan_tuitions[5]?.tuition_3_manyen)
          ? SCHOOL_AGE.university3
          : 0,
    },
    {
      school: (item: any) => (
        <Typography>
          {convertUniversitySchoolType(item.input_plan_tuitions[5]?.school_type)} 大学
        </Typography>
      ),
      school_grade: (item: any) => <Typography>4年生</Typography>,
      on_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(item.input_plan_tuitions[5]?.tuition_4_manyen)}万円`}
        </Typography>
      ),
      off_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[5]?.off_campus_expenses_4_manyen,
          )}万円`}
        </Typography>
      ),
      total: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[5]?.off_campus_expenses_4_manyen +
              item.input_plan_tuitions[5]?.tuition_4_manyen,
          )}万円`}
        </Typography>
      ),
      comperison: (item: any) =>
        isNotEmptyValue(item.input_plan_tuitions[5]?.tuition_4_manyen)
          ? SCHOOL_AGE.university4
          : 0,
    },

    {
      school: (item: any) => (
        <Typography>
          {convertUniversitySchoolType(item.input_plan_tuitions[6]?.school_type)} 大学院
        </Typography>
      ),
      school_grade: (item: any) => <Typography>1年生</Typography>,
      on_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(item.input_plan_tuitions[6]?.tuition_1_manyen)}万円`}
        </Typography>
      ),
      off_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[6]?.off_campus_expenses_1_manyen,
          )}万円`}
        </Typography>
      ),
      total: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[6]?.off_campus_expenses_1_manyen +
              item.input_plan_tuitions[6]?.tuition_1_manyen,
          )}万円`}
        </Typography>
      ),
      comperison: (item: any) =>
        isNotEmptyValue(item.input_plan_tuitions[6]?.tuition_1_manyen)
          ? SCHOOL_AGE.graduateSchool1
          : 0,
    },
    {
      school: (item: any) => (
        <Typography>
          {convertUniversitySchoolType(item.input_plan_tuitions[6]?.school_type)} 大学院
        </Typography>
      ),
      school_grade: (item: any) => <Typography>2年生</Typography>,
      on_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(item.input_plan_tuitions[6]?.tuition_2_manyen)}万円`}
        </Typography>
      ),
      off_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[6]?.off_campus_expenses_2_manyen,
          )}万円`}
        </Typography>
      ),
      total: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[6]?.off_campus_expenses_2_manyen +
              item.input_plan_tuitions[6]?.tuition_2_manyen,
          )}万円`}
        </Typography>
      ),
      comperison: (item: any) =>
        isNotEmptyValue(item.input_plan_tuitions[6]?.tuition_2_manyen)
          ? SCHOOL_AGE.graduateSchool2
          : 0,
    },
  ]

  const formatDate = (updatedAt?: string) =>
    updatedAt != null ? `(最終更新日: ${moment(updatedAt).format('YYYY/MM/DD')})` : ''

  const renderTuitionTable = (child: any) => {
    const hasTuition = !!child.input_plan_tuitions && child.input_plan_tuitions.length > 0
    return (
      <div className="mb-[60px] last:mb-0" key={`child_${child.id}`}>
        <div className="sm:flex block justify-between">
          <div className="flex flex-col md:flex-row gap-x-[8px] gap-y-[4px] items-baseline">
            <CircleTitle
              title={`第${child.child_index}子の教育費`}
              classNameTitle="text-[20px] md:text-[24px]"
            />
            <Typography className="text-[13px] md:text-[16px] text-[#9E9E9E]">
              {formatDate(child?.updated_at)}
            </Typography>
          </div>
          <div className="flex justify-end my-[10px]">
            {hasTuition && (
              <Button
                className="w-[84px] md:w-[112px] h-[26px] md:h-[37px] mr-[0] md:mr-[9px] rounded-[4px] bg-white border border-secondary-500 hover:bg-secondary-50"
                isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
                onClick={() =>
                  handleMovePage(
                    isAdmin
                      ? `/v2/accounts/${account_id}/myData/tuitions/edit/${child.id}`
                      : `/v2/myData/tuitions/edit/${child.id}`,
                  )
                }
              >
                <Typography
                  className="relative bottom-[1px] text-[12px] md:text-[14px] text-secondary-500"
                  isBold
                >
                  編集する
                </Typography>
              </Button>
            )}
          </div>
        </div>

        {!hasTuition && (
          <div className="md:pt-[20px]">
            <Typography
              className="pb-[15px] md:pb-[24px] text-[15px] md:text-[18px] text-center"
              isBold
            >
              {`第${child.child_index}子の教育費が登録されていません。`}
              <br className="md:hidden" />
              下記のボタンから登録してみましょう。
            </Typography>
            <Button
              className="w-[104px] md:w-[112px] h-[26px] md:h-[37px] rounded-[4px] mx-auto bg-white border border-secondary-500 hover:bg-secondary-50"
              isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
              onClick={() =>
                handleMovePage(
                  isAdmin
                    ? `/v2/accounts/${account_id}/myData/tuitions/edit/${child.id}`
                    : `/v2/myData/tuitions/edit/${child.id}`,
                )
              }
            >
              <Typography
                className="relative bottom-[1px] text-[12px] md:text-[14px] text-secondary-500"
                isBold
              >
                新しく追加する
              </Typography>
            </Button>
          </div>
        )}

        {hasTuition && (
          <table className="w-[100%] mb-[60px] last:mb-0 text-[12px] md:text-[14px]">
            <tbody>
              <tr className="h-[45px] odd:bg-black-100">
                <th className="w-[25%] px-[16px] md:px-[30px] text-left">
                  <Typography isBold>学校</Typography>
                </th>
                <th className="w-[15%] px-[16px] md:px-[30px] text-left">
                  <Typography isBold>学年</Typography>
                </th>
                <th className="w-[20%] px-[16px] md:px-[30px] text-left">
                  <Typography isBold>学費</Typography>
                </th>
                <th className="w-[20%] px-[16px] md:px-[30px] text-left">
                  <Typography isBold>学外費</Typography>
                </th>
                <th className="w-[20%] px-[16px] md:px-[30px] text-left">
                  <Typography isBold>合計額</Typography>
                </th>
              </tr>

              {contentArr.map(
                (item: any, i: number) =>
                  !(child.is_early_born && i === 3) &&
                  isChildbirthWithinRange(child.birth_day, item.comperison(child)) && (
                    <tr
                      className="h-[45px] odd:bg-black-100"
                      key={`child_tuition_${child.child_index}_${i}`}
                    >
                      <td className="w-[25%] px-[16px] md:px-[30px]">
                        {item.school(child)}
                      </td>
                      <td className="w-[15%] px-[16px] md:px-[30px]">
                        {item.school_grade(child)}
                      </td>
                      <td className="w-[20%] px-[16px] md:px-[30px]">
                        {item.on_campus_cost(child)}
                      </td>
                      <td className="w-[20%] px-[16px] md:px-[30px]">
                        {item.off_campus_cost(child)}
                      </td>
                      <td className="w-[20%] px-[16px] md:px-[30px]">
                        {item.total(child)}
                      </td>
                    </tr>
                  ),
              )}
            </tbody>
          </table>
        )}
      </div>
    )
  }

  return (
    <MyDataListWrapper
      title="家族情報"
      // description="給与明細が必要です。ご用意ください。"
    >
      <div className="sm:flex block justify-between">
        <div className="flex flex-col md:flex-row gap-x-[8px] gap-y-[4px] items-baseline">
          <CircleTitle title="ご家族の情報" classNameTitle="text-[20px] md:text-[24px]" />
          <Typography className="text-[13px] md:text-[16px] text-[#9E9E9E]">
            {formatDate(familyStructures?.updated_at)}
          </Typography>
        </div>
        <div className="flex justify-end my-[10px]">
          <Button
            className="w-[84px] md:w-[112px] h-[26px] md:h-[37px] mr-[0] md:mr-[9px] rounded-[4px] bg-white border border-secondary-500 hover:bg-secondary-50"
            isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
            onClick={() =>
              handleMovePage(
                isAdmin
                  ? `/v2/accounts/${account_id}/myData/families/edit`
                  : '/v2/myData/families/edit',
              )
            }
          >
            <Typography
              className="relative bottom-[1px] text-[12px] md:text-[14px] text-secondary-500"
              isBold
            >
              編集する
            </Typography>
          </Button>
        </div>
      </div>

      <table className="w-[100%] mb-[60px] last:mb-0 text-[12px] md:text-[14px]">
        <tbody>
          <tr className="h-[45px] odd:bg-black-100">
            <td className="w-[60%] px-[16px] md:px-[30px]">
              <Typography isBold>あなたの生年月日</Typography>
            </td>
            <td className="w-[40%] px-[16px] md:px-[30px]">
              <Typography>{`${familyStructures?.birth_day}( ${moment().diff(
                moment([person_birth_year, person_birth_month - 1, person_birth_day]),
                'years',
              )} 歳)`}</Typography>
            </td>
          </tr>

          {familyStructures?.spouse_type === 0 && (
            <tr className="h-[45px] odd:bg-black-100">
              <td className="w-[60%] px-[16px] md:px-[30px]">
                <Typography isBold>配偶者の生年月日</Typography>
              </td>
              <td className="w-[40%] px-[16px] md:px-[30px]">
                <Typography>{`${familyStructures?.spouse_birth_day}( ${moment().diff(
                  moment([spouse_birth_year, spouse_birth_month - 1, spouse_birth_day]),
                  'years',
                )} 歳)`}</Typography>
              </td>
            </tr>
          )}

          {familyStructures?.spouse_type === 0 && (
            <tr className="h-[45px] odd:bg-black-100">
              <td className="w-[60%] px-[16px] md:px-[30px]">
                <Typography isBold>入籍日</Typography>
              </td>
              <td className="w-[40%] px-[16px] md:px-[30px]">
                <Typography>{familyStructures?.enrollment_day}</Typography>
              </td>
            </tr>
          )}

          {familyStructures?.family_structure_children?.map(
            (item: any, index: number) => {
              const child_birth_year = item.birth_day.split('-')[0]
              const child_birth_month = item.birth_day.split('-')[1]
              const child_birth_day = item.birth_day.split('-')[2]
              return (
                <tr
                  className="h-[45px] odd:bg-black-100"
                  key={`child_${item.child_index}`}
                >
                  <td className="w-[60%] px-[16px] md:px-[30px]">
                    <Typography isBold>{`第${item.child_index}子の生年月日`}</Typography>
                  </td>
                  <td className="w-[40%] px-[16px] md:px-[30px]">
                    <Typography>{`${item.birth_day}( ${moment().diff(
                      moment([child_birth_year, child_birth_month - 1, child_birth_day]),
                      'years',
                    )} 歳)`}</Typography>
                  </td>
                </tr>
              )
            },
          )}
        </tbody>
      </table>

      {familyStructureChildren?.map((child: any) => {
        return renderTuitionTable(child)
      })}
    </MyDataListWrapper>
  )
}

export default MyDataFamily
