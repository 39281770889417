import React, { FC, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import arrayMutators from 'final-form-arrays'
import { Decorator } from 'final-form'
import createDecorator from 'final-form-calculate'
import { isUndefined as _isUndefined, find as _find } from 'lodash'
import { updateFutureLendHousingV2 } from '@/containers/futures/futureLendHousingsSlice'
import {
  DescriptionContext,
  useDescriptionTemplates,
} from '@/hooks/useDescriptionTemplates'
import { QuestionTemplate } from '@/models/commonModalForm'
import {
  addMomentDate,
  castNumberWithoutOperator,
  THIS_YEAR,
} from '@/models/commonsModelFunc'
import { IS_LOAN_CUSTOMIZED } from '@/models/housingLoansModelFunc'
import {
  PROPERTY_TYPE,
  VACANCY_RATE_FIELDS,
  calcVacancyRate,
} from '@/models/futures/futureEstateInvestmentsModelFunc'
import { FutureV2Housing } from '@/models/futures/futureV2HousingsModel'
import {
  LOAN_RENEWAL_TYPE,
  buildFutureLendHousingUpdateInitialValues,
} from '@/models/futures/futureLendHousingsModelFunc'
import { InputHousing } from '@/models/inputs/inputHousingsModel'
import { RootState } from '@/store'

import HousingOverview from '@/templates/v2/simulations/futureV2Housings/FutureLendHousingModalForm/HousingOverview'
import LifeInsuranceContract from '@/templates/v2/simulations/futureV2Housings/FutureLendHousingModalForm/LifeInsuranceContract'
import LoanCustomized from '@/templates/v2/simulations/futureV2Housings/FutureLendHousingModalForm/LoanCustomized'
import LoanDetail from '@/templates/v2/simulations/futureV2Housings/FutureLendHousingModalForm/LoanDetail'
import ManagementFeeType from '@/templates/v2/simulations/futureV2Housings/FutureLendHousingModalForm/ManagementFeeType'
import OtherCost from '@/templates/v2/simulations/futureV2Housings/FutureLendHousingModalForm/OtherCost'
import RentIncome from '@/templates/v2/simulations/futureV2Housings/FutureLendHousingModalForm/RentIncome'
import RepairCost from '@/templates/v2/simulations/futureV2Housings/FutureLendHousingModalForm/RepairCost'
import LoanCustomizedFooter from '@/templates/v2/simulations/futureV2Housings/FutureLendHousingModalForm/commons/LoanCustomizedFooter'
import LoanRepaymentTable from '@/templates/v2/simulations/futureV2Housings/FutureLendHousingModalForm/commons/LoanRepaymentTable'

import Modal from '@/components/v2/atoms/Modal'
import { Form } from 'react-final-form'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import Grid from '@/components/v2/atoms/Grid'
import Typography from '@/components/v2/atoms/Typography'
import ChevronRightIcon from '@/assets/images/v2/chevron-right.svg'

import LoadingOverlay from '@/components/v1/atoms/LoadingOverlay'

const DESCRIPTION_TEMPLATES: QuestionTemplate[] = [
  { children: <HousingOverview isEdit /> },
  {
    children: <LoanCustomized />,
    footer: <LoanCustomizedFooter />,
    condition: (values: any) =>
      values.future_lend_housing?.loan_renewal_type === LOAN_RENEWAL_TYPE.renew,
  },
  {
    children: <LoanDetail />,
    footer: <LoanRepaymentTable />,
    condition: (values: any) =>
      values.future_lend_housing?.loan_renewal_type === LOAN_RENEWAL_TYPE.renew &&
      // 以下動作未確認
      values.future_lend_housing?.is_loan_customized ===
        String(IS_LOAN_CUSTOMIZED.manual),
  },
  {
    children: <LifeInsuranceContract />,
    condition: (values: any) =>
      values.future_lend_housing?.loan_renewal_type === LOAN_RENEWAL_TYPE.renew &&
      // 以下動作未確認
      values.future_lend_housing?.is_loan_customized ===
        String(IS_LOAN_CUSTOMIZED.manual),
  },
  { children: <RentIncome /> },
  { children: <ManagementFeeType /> },
  { children: <RepairCost /> },
  { children: <OtherCost /> },
]

const calculator: Decorator<any> = createDecorator({
  field: VACANCY_RATE_FIELDS,
  updates: {
    'future_lend_housing.vacancy_rate_percent': (_, allValues: any) =>
      calcVacancyRate(allValues),
  },
})

interface FutureLendHousingUpdateModalFormProps {
  /** true: オープン, false: クローズ */
  isOpen: boolean
  /** 編集ID */
  editId: number
  /** 時系列一番最後の住居  */
  prevHousing: FutureV2Housing | InputHousing | undefined
  /** 完了後コールバック */
  completedCallback: () => void
  /** モーダルクローズメソッド */
  handleClose: () => void
}

const FutureLendHousingUpdateModalForm: FC<FutureLendHousingUpdateModalFormProps> = (
  props,
) => {
  const dispatch = useDispatch()
  const { isOpen, editId, prevHousing, completedCallback, handleClose } = props

  const {
    futureBaseId,
    familyInfo: { person_current_age },
  } = useSelector((state: RootState) => state.futureBases)
  const futureLendHousings = useSelector(
    (state: RootState) => state.accounts.futureItems?.future_lend_housings,
  )

  const targetFutureLendHousing = useMemo(
    () => _find(futureLendHousings, (item) => item.id === editId),
    [futureLendHousings],
  )

  const initialValues = useMemo(
    () => buildFutureLendHousingUpdateInitialValues(targetFutureLendHousing),
    [],
  )

  const { getContextValue } = useDescriptionTemplates(
    initialValues,
    DESCRIPTION_TEMPLATES,
  )

  const handleSubmit = (values: any) => {
    const pickValues: any = values
    const futureLendHousingDeclines = pickValues.future_lend_housing.future_lend_housing_declines_attributes?.map(
      (item: any, index: number) => ({
        id: item.id,
        affected_at:
          index !== 0
            ? addMomentDate(
                THIS_YEAR,
                item.affected_number as number,
                'years',
                'YYYY/MM/DD',
              )
            : null,
        rate_percent: item.rate_percent,
      }),
    )

    const newValues = {
      id: editId,
      future_base_id: futureBaseId,
      future_lend_housing: {
        ...pickValues.future_lend_housing,
        future_lend_housing_declines_attributes: futureLendHousingDeclines,
      },
    }
    const { management_fee_money, property_type } = newValues.future_lend_housing

    if (!_isUndefined(management_fee_money)) {
      newValues.future_lend_housing.management_fee_money =
        castNumberWithoutOperator(management_fee_money) * 12
    }

    dispatch(updateFutureLendHousingV2(newValues, editId, () => completedCallback()))
  }

  return (
    <Modal
      className="w-[95%] xl:w-[1000px]"
      isOpen={isOpen}
      isDisableBackdropClick
      onClose={handleClose}
    >
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        mutators={{ ...arrayMutators }}
        decorators={[calculator]}
        render={({ handleSubmit, values, errors }) => {
          return (
            <form className="pb-[30px]" onSubmit={handleSubmit}>
              <DescriptionContext.Provider
                value={{ ...getContextValue(), age: person_current_age, prevHousing }}
              >
                <Grid className="grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px]">
                  {DESCRIPTION_TEMPLATES.map((item, index) => {
                    return (
                      ((item.condition && item.condition(values)) || !item.condition) && (
                        <React.Fragment key={index}>{item.children}</React.Fragment>
                      )
                    )
                  })}
                </Grid>
              </DescriptionContext.Provider>

              <div className="flex justify-center">
                <ButtonPrimary
                  className="h-64 w-246"
                  isDisabled={Object.keys(errors).length > 0}
                  onClick={() => handleSubmit(values)}
                >
                  <Typography
                    className="bottom-2 flex-auto pl-30 relative text-16 text-white"
                    isBold
                  >
                    保存する
                  </Typography>
                  <ChevronRightIcon className="mr-17" />
                </ButtonPrimary>
              </div>
            </form>
          )
        }}
      />
      <LoadingOverlay isLoading={false} />
    </Modal>
  )
}

export default FutureLendHousingUpdateModalForm
