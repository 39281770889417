import React, { FC, useEffect } from 'react'
import { useField, useForm } from 'react-final-form'
import { FUTURE_HOUSING_TRANSFER_EXCEPTION_TYPE_SELECT_ITEMS } from '@/constants/formItem/futures/futureHousingSales'
import { calcSaleCostManyen } from '@/models/futures/futureHousingSalesModelFunc'
import { castNumberWithoutOperator } from '@/models/commonsModelFunc'
import { composeValidators, required, zeroOrMoreNumber } from '@/utils/validate'
import Typography from '@/components/v2/atoms/Typography'
import SelectBox from '@/components/v2/molecules/SelectBox'
import NumberField from '@/components/v2/molecules/NumberField'
import HelpCircleIcon from '@/assets/images/v2/help-circle.svg'
import Tooltip from '@/components/v2/atoms/Tooltip'

const SaleDetail: FC = () => {
  const { change } = useForm()

  const {
    input: { value: scheduledAtAge },
  } = useField('scheduled_at_age')
  const {
    input: { value: sellAtAge },
  } = useField('future_housing_sales.sell_at_age')

  useField('sell_at_age')

  useEffect(() => {
    change(
      'future_housing_sales.sell_at_age',
      Number(scheduledAtAge) || Number(sellAtAge),
    )
  }, [])

  return (
    <>
      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2">
        <Typography
          className="min-w-[80px] pb-[10px] lg:pb-0 text-[14px] text-black-800 flex items-center"
          isBold
        >
          売却金額
        </Typography>
        <div className="flex flex-auto items-baseline">
          <NumberField
            name="future_housing_sales.sale_amount_manyen"
            isFullWidth
            validate={composeValidators(required, zeroOrMoreNumber)}
            onChange={(event: any) =>
              change(
                'future_housing_sales.cost_manyen',
                calcSaleCostManyen(castNumberWithoutOperator(event.target.value)),
              )
            }
          />
          <Typography className="pl-[8px] text-[14px] text-black-800 min-w-[40px]" isBold>
            万円
          </Typography>
        </div>
      </div>
      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-center">
        <div>
          <Typography
            className="min-w-[140px] lg:min-w-[164px] pb-[10px] mr-[10px] lg:pb-0 text-[14px] text-black-800 flex items-center"
            isBold
          >
            売却にかかる費用
            <div className="mx-[10px]">
              <Tooltip
                item={
                  <Typography>
                    自動で入力される売却にかかる費用は、
                    <br className="hidden md:block" />
                    （売却金額 x 3％ + 6万円) x 1.1 + 10万円 で算出しています。
                    <br className="hidden md:block" />
                    修正が必要な場合は入力内容を変更してください。
                  </Typography>
                }
                itemClassName="w-[80px] sm:w-[200px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
              >
                <HelpCircleIcon />
              </Tooltip>
            </div>
          </Typography>
          <Typography className="py-8 text-10 text-black-700 pr-[16px] w-full md:max-w-[200px]">
            例）仲介手数料・印紙代など
          </Typography>
        </div>

        <div className="flex flex-auto items-center">
          <NumberField
            name="future_housing_sales.cost_manyen"
            isFullWidth
            validate={composeValidators(required, zeroOrMoreNumber)}
          />
          <Typography className="pl-[8px] text-[14px] text-black-800 min-w-[40px]" isBold>
            万円
          </Typography>
        </div>
      </div>
      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-center">
        <Typography
          className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 mr-[10px] text-[14px] text-black-800 flex items-center"
          isBold
        >
          マイホーム売却時の特例利用有無
          <div className="mx-[10px]">
            <Tooltip
              item="マイホーム売却時の特例とは、所有期間に関係なく譲渡所得から最高3,000万円までの控除が出来る特例です。
              「居住用財産を譲渡した場合の3,000万円の特別控除の特例」といいます。"
              itemClassName="w-[150px] sm:w-[200px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
            >
              <HelpCircleIcon />
            </Tooltip>
          </div>
        </Typography>

        <div className="flex flex-auto items-baseline">
          <SelectBox
            isFullWidth
            name="future_housing_sales.transfer_exception_type"
            options={FUTURE_HOUSING_TRANSFER_EXCEPTION_TYPE_SELECT_ITEMS}
            validate={required}
            onChange={(event) =>
              change(
                'future_housing_sales.transfer_exception_type',
                Number(event.target.value),
              )
            }
          />
        </div>
      </div>
    </>
  )
}

export default SaleDetail
