import React, { FC, useState } from 'react'
import DataConnectionImg from '@/assets/images/v2/data-connection.png'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import Modal from '@/components/v2/atoms/Modal'
import Typography from '@/components/v2/atoms/Typography'
import Link from '@/components/v2/atoms/Link'
import { setStorageIsClickedOnConnectionPage } from '@/utils/localStorage/isClickedOnConnectionPageStorage'
import { getMoneytreeVaultUrl } from '@/utils/moneytree'
import Heading from '@/components/v2/atoms/Heading'
import ImageMagnifiableModal from '@/components/v2/organisms/ImageModal'

interface DataConnectionModalFormProps {
  /** true: オープン, false: クローズ */
  isOpen: boolean
  /** onCloseメソッド */
  onClose: () => void
}

const DataConnectionModalForm: FC<DataConnectionModalFormProps> = (props) => {
  const { isOpen, onClose } = props
  const [isImageModalOpen, setIsImageModalOpen] = useState<boolean>(false)

  const handleOpenMoneytree = () => {
    setStorageIsClickedOnConnectionPage(true)
    const url = getMoneytreeVaultUrl('/v2/plans/dataConnection')
    window.open(url)
    onClose()
  }

  const handleModalClose = () => {
    setIsImageModalOpen(false)
  }

  return (
    <>
      <Modal
        className="w-[95%] md:w-[760px]"
        isOpen={isOpen}
        isDisableBackdropClick
        onClose={onClose}
      >
        <div className="space-y-24">
          <Heading className="text-center" size={2}>
            Moneytreeと連携
          </Heading>
          <Typography className="text-14 text-black-700 sm:text-center">
            ここからMoneytreeの画面に遷移します。
            <br />
            Moneytree側で口座の登録が完了したら、
            <span className="sm:block">
              上部にある戻るボタンからこのページに戻ってきてください。
            </span>
          </Typography>
          <img
            src={DataConnectionImg}
            alt="AcountLinkage"
            onClick={() => setIsImageModalOpen(true)}
          />
          <div className="flex flex-col items-center space-y-24">
            <ButtonPrimary
              className="h-45 w-full md:w-215 px-17 text-white"
              onClick={handleOpenMoneytree}
            >
              <Typography className="bottom-2 flex-auto relative text-14" isBold>
                口座登録する
              </Typography>
              <i className="ico chevron-right text-24" />
            </ButtonPrimary>
            <Link
              className="text-14 text-primary-900"
              children="遷移せずに閉じる"
              onClick={onClose}
            />
          </div>
        </div>
      </Modal>

      <ImageMagnifiableModal
        src={DataConnectionImg}
        alt="AcountLinkage"
        isOpen={isImageModalOpen}
        onClose={handleModalClose}
      />
    </>
  )
}

export default DataConnectionModalForm
