import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import ChevronRightIcon from '@/assets/images/v2/chevron-right.svg'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import ButtonBlack from '@/components/v2/atoms/ButtonBlack'
import Typography from '@/components/v2/atoms/Typography'
import Modal from '@/components/v2/atoms/Modal'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import { updateAccountIsWithoutMoneytreeV2 } from '@/containers/accountsSlice'
import { createMtAccessTokenDisconnect } from '@/containers/mtAccessTokensSlice'
import { fetchAccountShowStepStatusV2 } from '@/containers/accountsSlice'
import { useCustomHistory } from '@/hooks/useCustomHistory'

interface MoneytreeSkipModalProps {
  /** モーダル表示フラグ */
  isOpen: boolean
  /** モーダルクローズメソッド */
  onClose: () => void
}

const MoneytreeSkipModal: FC<MoneytreeSkipModalProps> = (props) => {
  const dispatch = useDispatch()
  const { handleMovePage } = useCustomHistory()
  const { isOpen, onClose } = props

  const handleClickConnection = () => {
    onClose()
    dispatch(
      createMtAccessTokenDisconnect(() => {
        handleMovePage('/v2/plans/moneytreeConnection')
      }),
    )
  }

  const handleClickWithoutMoneytree = () => {
    const isFromSettingPage: boolean = window.localStorage.isFromSettingPage === 'true'

    dispatch(
      updateAccountIsWithoutMoneytreeV2(() => {
        onClose()
        dispatch(fetchAccountShowStepStatusV2())
        isFromSettingPage
          ? handleMovePage('/v2/settings/account')
          : handleMovePage('/v2/dashboards')
      }),
    )
  }

  return (
    <Modal
      className="sm:w-[580px] w-[95%]"
      isOpen={isOpen}
      isDisableBackdropClick
      onClose={onClose}
    >
      <CircleTitle
        className="pb-[20px]"
        classNameTitle="text-18 md:text-24"
        title="Moneytree連携をスキップする"
      />

      <Typography className="pb-[30px] text-[12px] md:text-[14px] text-black-900">
        Moneytreeと連携することで、支出入力の手間が省け、常に最新の状態を反映できます。
        <br />
        Moneytreeの連携は、「マイデータ設定」からいつでも行うことができます。
      </Typography>

      <div className="flex justify-center flex-wrap md:space-x-24 space-y-24 md:space-y-0">
        <ButtonBlack
          className="h-45 w-full md:w-215 px-17"
          onClick={handleClickWithoutMoneytree}
        >
          <Typography className="relative flex-1 text-[14px] text-white" isBold>
            今は連携しない
          </Typography>
          <ChevronRightIcon />
        </ButtonBlack>

        <ButtonPrimary
          className="h-45 w-full md:w-215 px-17"
          onClick={handleClickConnection}
        >
          <Typography className="relative flex-1 text-[14px] text-white" isBold>
            連携・登録を行う
          </Typography>
          <ChevronRightIcon />
        </ButtonPrimary>
      </div>
    </Modal>
  )
}

export default MoneytreeSkipModal
