import { convertMomentDate, convertLocaleString } from '../commonsModelFunc'
import { FutureEstateInvestmentDeclineRate } from './futureEstateInvestmentDeclineRatesModel'

export const convertDeclineRate = (
  declineRates: FutureEstateInvestmentDeclineRate[],
  index: number,
) => {
  const fromLabel =
    index === 0 ? '現在' : convertMomentDate(declineRates[index]?.affected_at, 'YYYY年')
  const toLabel =
    index === declineRates.length - 1
      ? '終身'
      : convertMomentDate(declineRates[index + 1]?.affected_at, 'YYYY年')

  return `${fromLabel}〜${toLabel} 年間${convertLocaleString(
    declineRates[index]?.amount_yearly_percent,
  )}％`
}
