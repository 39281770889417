import React, { FC } from 'react'

interface ButtonProps {
  /** クラス名 */
  className?: string
  /** タイプ */
  type?: 'button' | 'submit' | 'reset'
  /** true: 非活性, false: 活性 */
  isDisabled?: boolean
  /** onClickメソッド */
  onClick?: (e?: any) => void

  ref?: any
}

const Button: FC<ButtonProps> = (props) => {
  const { className, type, onClick, isDisabled, children, ...rest } = props

  return (
    <button
      className={`${className} flex items-center justify-center disabled:cursor-default disabled:opacity-50`}
      type={type ?? 'button'}
      disabled={isDisabled}
      onClick={onClick}
      {...rest}
    >
      {children}
    </button>
  )
}

export default Button
