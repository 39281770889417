import { times as _times } from 'lodash'
import { PAYMENT_TYPE } from '../../../models/futures/futureCarsModelFunc'

// 車に乗る年齢
export const DRIVER_AGE_SELECT_ITEMS = _times(83, (index) => ({
  value: index + 18,
  label: String(index + 18),
}))

// 乗り換え頻度
export const CHANGE_CAR_SPAN_SELECT_ITEMS = _times(15, (index) => ({
  value: index + 1,
  label: String(index + 1),
}))

// 車の購入方法
export const PAYMENT_TYPE_BUTTON_ITEMS = [
  { value: PAYMENT_TYPE.loan, label: 'ローンで購入する' },
  { value: PAYMENT_TYPE.oneTime, label: '一括で購入する' },
]
