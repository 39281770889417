// 業種
export const INPUT_INDUSTRY = {
  average: 0,
  mining: 1,
  construction: 2,
  manufacturing: 3,
  lifeline: 4,
  telecommunications: 5,
  transportation: 6,
  wholesale: 7,
  finance: 8,
  realEstate: 9,
  restaurant: 10,
  medical: 11,
  education: 12,
  postOffice: 13,
  research: 14,
  lifeService: 15,
  otherService: 16,
}

// 従業員規模
export const INPUT_EMPLOYEE_SIZE = {
  average: 0,
  thousand: 1,
  hundred: 2,
  ten: 3,
  one: 4,
}

// 加入保険/年金
export const INPUT_INSURANCE_PENSION_TYPE = {
  welfarePension: 'welfare_pension',
  mutualAidPension: 'old_mutual_aid_pension',
  nationalPension: 'national_pension',
  notSubscribed: 'uninsured',
}

// 職業
export const INPUT_PROFESSION = {
  owner: 0,
  companyExecutive: 1,
  regularEmployee: 2,
  contractEmployee: 3,
  partTimeJob: 4,
  freelance: 5,
  student: 6,
  other: 7,
  housewife: 8,
}

// 個人事業税の区分
export const INPUT_PERSONAL_BUSINESS_TAX_RATE_TYPE = {
  excluded: 0,
  threePercent: 0.03,
  fourPercent: 0.04,
  fivePercent: 0.05,
}

// 青色申告特別控除の区分
export const INPUT_PERSONAL_BUSINESS_AOIRO_DEDUCTION_TYPE = {
  none: 0,
  ten: 100000,
  fiftyFive: 550000,
  sixtyFive: 650000,
}

export const INPUT_PROFESSION_SELECT_ITEMS = [
  { value: INPUT_PROFESSION.owner, label: '経営者' },
  { value: INPUT_PROFESSION.partTimeJob, label: 'アルバイト・パート・フリーター' },
  { value: INPUT_PROFESSION.companyExecutive, label: '会社役員' },
  { value: INPUT_PROFESSION.freelance, label: 'フリーランス・自営業・個人事業主' },
  { value: INPUT_PROFESSION.regularEmployee, label: '正社員・公務員' },
  { value: INPUT_PROFESSION.student, label: '学生' },
  { value: INPUT_PROFESSION.contractEmployee, label: '契約・派遣社員' },
  { value: INPUT_PROFESSION.other, label: 'その他・無職・年金受給者' },
  { value: INPUT_PROFESSION.housewife, label: '専業主婦・主夫' },
]

export const INPUT_PERSONAL_BUSINESS_TAX_RATE_SELECT_ITEMS = [
  { value: INPUT_PERSONAL_BUSINESS_TAX_RATE_TYPE.excluded, label: '対象外' },
  { value: INPUT_PERSONAL_BUSINESS_TAX_RATE_TYPE.threePercent, label: '3％' },
  { value: INPUT_PERSONAL_BUSINESS_TAX_RATE_TYPE.fourPercent, label: '4％' },
  { value: INPUT_PERSONAL_BUSINESS_TAX_RATE_TYPE.fivePercent, label: '5％' },
]

export const INPUT_PERSONAL_BUSINESS_AOIRO_DEDUCTION_SELECT_ITEMS = [
  { value: INPUT_PERSONAL_BUSINESS_AOIRO_DEDUCTION_TYPE.none, label: 'なし（白色申告）' },
  { value: INPUT_PERSONAL_BUSINESS_AOIRO_DEDUCTION_TYPE.ten, label: '10万円' },
  { value: INPUT_PERSONAL_BUSINESS_AOIRO_DEDUCTION_TYPE.fiftyFive, label: '55万円' },
  { value: INPUT_PERSONAL_BUSINESS_AOIRO_DEDUCTION_TYPE.sixtyFive, label: '65万円' },
]

export const INPUT_INDUSTRY_BUTTON_ITEMS = [
  { value: INPUT_INDUSTRY.mining, label: '鉱業' },
  { value: INPUT_INDUSTRY.construction, label: '建設業' },
  { value: INPUT_INDUSTRY.manufacturing, label: '製造業' },
  { value: INPUT_INDUSTRY.lifeline, label: '電気・ガス・熱供給・水道業' },
  { value: INPUT_INDUSTRY.telecommunications, label: '情報通信業' },
  { value: INPUT_INDUSTRY.transportation, label: '運輸業' },
  { value: INPUT_INDUSTRY.wholesale, label: '卸売り・小売業' },
  { value: INPUT_INDUSTRY.finance, label: '金融・保険業' },
  { value: INPUT_INDUSTRY.realEstate, label: '不動産業' },
  { value: INPUT_INDUSTRY.restaurant, label: '飲食店・宿泊業' },
  { value: INPUT_INDUSTRY.medical, label: '医療・福祉' },
  { value: INPUT_INDUSTRY.education, label: '教育・学習支援業' },
  { value: INPUT_INDUSTRY.postOffice, label: '郵便局・協同組合' },
  { value: INPUT_INDUSTRY.research, label: '研究・天文・技術サービス業' },
  { value: INPUT_INDUSTRY.lifeService, label: '生活関連サービス・娯楽業' },
  { value: INPUT_INDUSTRY.otherService, label: 'その他サービス業' },
]

export const INPUT_EMPLOYEE_SIZE_BUTTON_ITEMS = [
  { value: INPUT_EMPLOYEE_SIZE.thousand, label: '1,000人以上' },
  { value: INPUT_EMPLOYEE_SIZE.hundred, label: '100人以上 999人以下' },
  { value: INPUT_EMPLOYEE_SIZE.ten, label: '10人以上 99人以下' },
  { value: INPUT_EMPLOYEE_SIZE.one, label: '1人以上 9人以下' },
]

export const INPUT_INSURANCE_PENSION_TYPE_BUTTON_ITEMS = [
  { value: INPUT_INSURANCE_PENSION_TYPE.welfarePension, label: '健康保険/厚生年金' },
  { value: INPUT_INSURANCE_PENSION_TYPE.mutualAidPension, label: '健康保険/旧共済年金' },
  { value: INPUT_INSURANCE_PENSION_TYPE.nationalPension, label: '国民健康保険/国民年金' },
  {
    value: INPUT_INSURANCE_PENSION_TYPE.notSubscribed,
    label: '未加入・第３号被保険者(会社員の配偶者)',
  },
]
