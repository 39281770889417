import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import clsx from 'clsx'
import { postAccountPayment } from '@/api/accountPayments'
import CommentIcon from '@/assets/images/v2/comment.svg'
import WomanIcon from '@/assets/images/v2/woman.svg'
import Button from '@/components/v2/atoms/Button'
import Modal from '@/components/v2/atoms/Modal'
import Typography from '@/components/v2/atoms/Typography'
import FPModalForm from '@/components/v2/organisms/FPModalForm'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import { RootState } from '@/store'
import CreditCardRegistrationForm from '@/templates/v2/settings/commons/CreditCardRegistrationForm'
import { isEnableHeightScroll } from '@/utils/element'

interface FloatingFooterProps {
  /** true: FPボタン非表示, false: FPボタン表示 */
  isHideFPButton?: boolean
}

const FloatingFooter: FC<FloatingFooterProps> = (props) => {
  const locaiton = useLocation()

  const { isHideFPButton } = props
  const [isShow, setIsShow] = useState<boolean>(false)
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const [isLoadingCreditCard, setIsLoadingCreditCard] = useState<boolean>(false)
  const { account, accountPayment } = useSelector((state: RootState) => state.accounts)

  const { showFlashMsg } = useFlashAlert()
  const { handleMovePage } = useCustomHistory()

  const handleScroll = (e: Event) => {
    const target: any = e.target
    setIsShow(target.scrollTop > 10)
  }

  useEffect(() => {
    const jsContentElement = document.getElementById('js-content')

    const timer = setTimeout(() => {
      if (isEnableHeightScroll('js-content')) {
        setIsShow(false)
        jsContentElement?.addEventListener('scroll', handleScroll)
      } else {
        setIsShow(true)
        jsContentElement?.removeEventListener('scroll', handleScroll)
      }
    }, 50)

    return () => {
      clearTimeout(timer)
      jsContentElement?.removeEventListener('scroll', handleScroll)
    }
  }, [locaiton.pathname])

  const _handleSubmitCreditCardForm = (
    values: any,
    callback: (values: any, token: string) => void,
  ) => {
    setIsLoadingCreditCard(true)

    const w: any = window as any
    w.CPToken.TokenCreate(
      {
        aid: '120009',
        cn: values.cn,
        ed: `${values.ed_year}${values.ed_month}`,
        fn: values.fn,
        ln: values.ln,
        cvv: values.cvv,
      },
      (resultCode: any, errMsg: any) => {
        if (resultCode != 'Success') {
          window.alert(`カード情報の登録に失敗しました。 ${errMsg}`)
          setIsLoadingCreditCard(false)
          return
        }
        const tokenEle: any = document?.getElementById('tkn')
        const token: string = tokenEle.value
        callback(values, token)
      },
    )
  }

  const handleSubmitCreditCardRegistrationForm = (values: any) => {
    _handleSubmitCreditCardForm(values, (values, token) => {
      postAccountPayment({
        account_payment_information: {
          robot_payment_token: token,
          last_four_digits: values.cn.substr(-4),
          expire_month: values.ed_month,
          expire_year: values.ed_year,
        },
      })
        .then(() => {
          handleMovePage('/v2/settings/account')
          showFlashMsg('有料プランへの登録を完了しました')
          setIsLoadingCreditCard(false)
        })
        .catch(() => {
          setIsOpenModal(false)
          showFlashMsg('有料プランへの登録に失敗しました')
          setIsLoadingCreditCard(false)
        })
    })
  }

  return (
    <>
      <div
        className={clsx(
          'fixed bottom-0 flex justify-center items-center gap-x-[3px] md:gap-x-[24px] w-full h-[47px] z-[50] duration-300 visible bg-[rgba(0,0,0,0.18)]',
          { 'opacity-0 invisible': !isShow },
        )}
      >
        {!isHideFPButton && (
          <Button
            className="w-[180px] h-[40px] rounded-[9px] text-tertiary-500 bg-white border border-tertiary-500 hover:bg-[#fff6e2]"
            onClick={() => setIsOpenModal(true)}
          >
            <div className="flex flex-1 items-center gap-x-[2px] pl-[4px]">
              <WomanIcon />
              <div className="text-left">
                <Typography className="text-[10px]">ライフプランについて</Typography>
                <Typography className="text-[11px]">FPにリモートで相談する</Typography>
              </div>
            </div>
            <i className="ico chevron-right text-[20px]" />
          </Button>
        )}
        <Button className="channel-talk-button w-[180px] h-[40px] rounded-[9px] text-primary-500 bg-white border border-primary-500 hover:bg-primary-50">
          <div className="flex flex-1 items-center gap-x-[6px] pl-[8px]">
            <CommentIcon />
            <div className="text-left">
              <Typography className="text-[10px]">操作について</Typography>
              <Typography className="text-[11px]">チャットで相談する</Typography>
            </div>
          </div>
          <i className="ico chevron-right text-[20px]" />
        </Button>
      </div>

      {!account['is_app_member'] && account?.['is_paid_account?'] ? (
        <FPModalForm isOpen={isOpenModal} onClose={() => setIsOpenModal(false)} />
      ) : (
        <Modal
          className="w-[95%] md:w-[760px]"
          isOpen={isOpenModal}
          onClose={() => setIsOpenModal(false)}
        >
          <CreditCardRegistrationForm
            title="有料プランに加入してFPに相談する"
            instruction_type="fp_remote"
            accountPayment={accountPayment}
            isLoading={isLoadingCreditCard}
            handleSubmit={handleSubmitCreditCardRegistrationForm}
          />
        </Modal>
      )}
    </>
  )
}

export default FloatingFooter
