import React, { FC } from 'react'
import Typography from '@/components/v2/atoms/Typography'

interface TableRowOddColorProps {
  /** trのクラス名 */
  trClassName?: string
  /** title側のtdのクラス名 */
  tdTitleClassName?: string
  /** title側のTypographyのクラス名 */
  titleClassName?: string
  /** description側のtdのクラス名 */
  tdDescripotionClassName?: string
  /** description側のTypographyのクラス名 */
  descriptionClassName?: string
  /** タイトル */
  title?: string
  /** データ詳細 */
  description?: string
  /** variant */
  variant?: 'default' | 'medium' | 'large'
}

const TableRowOddColor: FC<TableRowOddColorProps> = ({
  trClassName,
  tdTitleClassName,
  tdDescripotionClassName,
  titleClassName,
  descriptionClassName,
  title,
  description,
  variant,
}) => {
  variant = variant ?? 'default'

  const variantObj = {
    default: {
      tr: ' h-[45px] odd:bg-black-100 ',
      tdTitle: 'w-[40%] px-[16px]',
      tdDescription: 'px-[16px]',
      title: '',
      description: '',
    },
    medium: {
      tr: ' h-[45px] odd:bg-black-100 ',
      tdTitle: 'w-[50%] px-[16px]',
      tdDescription: 'px-[16px]',
      title: '',
      description: '',
    },
    large: {
      tr: ' h-[45px] odd:bg-black-100 ',
      tdTitle: 'w-[60%] px-[16px]',
      tdDescription: 'px-[16px]',
      title: '',
      description: '',
    },
  }

  return (
    <tr className={trClassName ?? variantObj[variant].tr}>
      <td className={tdTitleClassName ?? variantObj[variant].tdTitle}>
        <Typography className={titleClassName ?? variantObj[variant].title} isBold>
          {title}
        </Typography>
      </td>
      <td className={tdDescripotionClassName ?? variantObj[variant].tdDescription}>
        <Typography className={descriptionClassName ?? variantObj[variant].description}>
          {description}
        </Typography>
      </td>
    </tr>
  )
}

export default TableRowOddColor
