import React, { FC } from 'react'
import { isNull as _isNull, filter as _filter } from 'lodash'
import Typography from '@/components/v2/atoms/Typography'
import { convertLocaleString, isNotEmptyValue } from '@/models/commonsModelFunc'
import {
  isPairLoan,
  convertRateChangeValues,
  isFlatLoan,
  convertInsuranceType,
  convertDeductionType,
  convertIsFlatApplied,
} from '@/models/commonHousingModelFunc'
import { convertPersonFlg } from '@/models/familyStructuresModelFunc'
import { FutureV2HousingLoan } from '@/models/futures/futureV2HousingLoansModel'
import { FutureV2HousingLoanCalculationRate } from '@/models/futures/futureV2HousingLoanCalculationRatesModel'

interface FutureV2HousingLoanCalculationBodyProps {
  loan: Partial<FutureV2HousingLoan>
}

const FutureV2HousingLoanCalculationBody: FC<FutureV2HousingLoanCalculationBodyProps> = (
  props,
) => {
  const { loan } = props
  const { future_v2_housing_loan_calculation: loanCalc } = loan

  const selectAllCalculationRatesByRateStartAt = (
    calculationRates: Partial<FutureV2HousingLoanCalculationRate>[],
    nullType: 'null' | 'notNull',
  ) => {
    if (nullType === 'null') {
      return _filter(calculationRates!, (item) => _isNull(item.rate_start_at))
    }

    return _filter(calculationRates!, (item) => !_isNull(item.rate_start_at))
  }

  return (
    <>
      {isPairLoan(loan.loan_type) && (
        <tr className="h-[45px] odd:bg-black-100">
          <td className="w-[50%] px-[16px] md:px-[16px]">
            {convertPersonFlg(loanCalc?.person_flg)}
          </td>
          <td className="w-[50%] px-[16px] md:px-[16px]"></td>
        </tr>
      )}

      {isFlatLoan(loan.loan_lender) && (
        <Typography className="text-[14px] mt-[15px]" isBold>
          {convertIsFlatApplied(loan.is_flat_applied!)}部分
        </Typography>
      )}
      <tr className="h-[45px] odd:bg-black-100">
        <td className="w-[50%] px-[16px] md:px-[16px]">
          <Typography isBold>当初の借入金額</Typography>
        </td>
        <td className="w-[50%] px-[16px] md:px-[16px]">
          <Typography className="">
            {`${convertLocaleString(loanCalc?.started_borrowing_amount_manyen)}万円`}
          </Typography>
        </td>
      </tr>

      <tr className="h-[45px] odd:bg-black-100">
        <td className="w-[50%] px-[16px] md:px-[16px]">
          <Typography isBold>設定している返済期間</Typography>
        </td>
        <td className="w-[50%] px-[16px] md:px-[16px]">
          <Typography className="">
            {`${convertLocaleString(loanCalc?.repayment_period)}年間`}
          </Typography>
        </td>
      </tr>

      {isFlatLoan(loan!.loan_lender) ? (
        <>
          {isNotEmptyValue(
            loanCalc?.future_v2_housing_loan_calculation_rates?.[0]?.changed_rate_percent,
          ) && (
            <tr className="h-[45px] odd:bg-black-100">
              <td className="w-[50%] px-[16px] md:px-[16px]">
                <Typography className="">
                  {loan.is_flat_applied ? '金利' : '当初金利'}
                </Typography>
              </td>
              <td className="w-[50%] px-[16px] md:px-[16px]">
                <Typography className="">
                  {`${
                    selectAllCalculationRatesByRateStartAt(
                      loanCalc?.future_v2_housing_loan_calculation_rates!,
                      'null',
                    )?.[0]?.changed_rate_percent
                  }％`}
                </Typography>
              </td>
            </tr>
          )}

          {!loan?.is_flat_applied &&
            selectAllCalculationRatesByRateStartAt(
              [...loanCalc?.future_v2_housing_loan_calculation_rates!],
              'notNull',
            ).length > 0 && (
              <tr className="h-[45px] odd:bg-black-100">
                <td className="w-[50%] px-[16px] md:px-[16px]">
                  <Typography isBold>当初以降の金利</Typography>
                </td>
                <td className="w-[50%] px-[16px] md:px-[16px]">
                  {selectAllCalculationRatesByRateStartAt(
                    [...loanCalc?.future_v2_housing_loan_calculation_rates!],
                    'notNull',
                  ).map((calcRate, calcRateIndex) => (
                    <Typography key={calcRateIndex} className="">
                      {convertRateChangeValues(
                        calcRate!.rate_start_at,
                        calcRate!?.changed_rate_percent,
                      )}
                    </Typography>
                  ))}
                </td>
              </tr>
            )}
        </>
      ) : (
        <>
          <tr className="h-[45px] odd:bg-black-100">
            <td className="w-[50%] px-[16px] md:px-[16px]">
              <Typography isBold>現在の借入金利</Typography>
            </td>
            <td className="w-[50%] px-[16px] md:px-[16px]">
              <Typography className="">
                {`${
                  selectAllCalculationRatesByRateStartAt(
                    loanCalc?.future_v2_housing_loan_calculation_rates!,
                    'null',
                  )?.[0].changed_rate_percent
                }％`}
              </Typography>
            </td>
          </tr>

          {selectAllCalculationRatesByRateStartAt(
            [...loanCalc?.future_v2_housing_loan_calculation_rates!],
            'notNull',
          ).length > 0 && (
            <tr className="h-[45px] odd:bg-black-100">
              <td className="w-[50%] px-[16px] md:px-[16px]">
                <Typography isBold>借入金利の変化</Typography>
              </td>
              <td className="w-[50%] px-[16px] md:px-[16px]">
                {selectAllCalculationRatesByRateStartAt(
                  [...loanCalc?.future_v2_housing_loan_calculation_rates!],
                  'notNull',
                ).map((calcRate, calcRateIndex) => (
                  <Typography key={calcRateIndex} className="">
                    {convertRateChangeValues(
                      calcRate?.rate_start_at,
                      calcRate?.changed_rate_percent,
                    )}
                  </Typography>
                ))}
              </td>
            </tr>
          )}
        </>
      )}

      {isFlatLoan(loan.loan_lender) && (
        <tr className="h-[45px] odd:bg-black-100">
          <td className="w-[50%] px-[16px] md:px-[16px]">
            <Typography isBold>団体信用生命保険</Typography>
          </td>
          <td className="w-[50%] px-[16px] md:px-[16px]">
            <Typography className="">
              {convertInsuranceType(loanCalc?.life_insurance)}
            </Typography>
          </td>
        </tr>
      )}

      {isPairLoan(loan.loan_type) && (
        <tr className="h-[45px] odd:bg-black-100">
          <td className="w-[50%] px-[16px] md:px-[16px]">
            <Typography isBold>夫婦連生団信</Typography>
          </td>
          <td className="w-[50%] px-[16px] md:px-[16px]">
            <Typography className="">
              {convertInsuranceType(loanCalc?.married_couple_group_shin)}
            </Typography>
          </td>
        </tr>
      )}

      <tr className="h-[45px] odd:bg-black-100">
        <td className="w-[50%] px-[16px] md:px-[16px]">
          <Typography isBold>住宅ローン控除</Typography>
        </td>
        <td className="w-[50%] px-[16px] md:px-[16px]">
          <Typography className="">
            {convertDeductionType(loanCalc?.loan_deduction)}
          </Typography>
        </td>
      </tr>
    </>
  )
}

export default FutureV2HousingLoanCalculationBody
