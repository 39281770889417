import React, { FC, useEffect, useMemo, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import { cloneDeep as _cloneDeep, isUndefined as _isUndefined } from 'lodash'
import ChevronRightIcon from '@/assets/images/v2/chevron-right.svg'
import FamilyMonochromaticIcon from '@/assets/images/v2/family-monochromatic.svg'
import Button from '@/components/v2/atoms/Button'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import Grid from '@/components/v2/atoms/Grid'
import MarginContainer from '@/components/v2/atoms/MarginContainer'
import Paper from '@/components/v2/atoms/Paper'
import TextField from '@/components/v2/atoms/TextField'
import Typography from '@/components/v2/atoms/Typography'
import Breadcrumb from '@/components/v2/molecules/Breadcrumb'
import NumberField from '@/components/v2/molecules/NumberField'
import PaperTitle from '@/components/v2/molecules/PaperTitle'
import SelectBox from '@/components/v2/molecules/SelectBox'
import { MONTH_KANJI_SELECT_ITEMS } from '@/constants/formItem/commons'
import { LOAN_FLG_RADIO_ITEMS } from '@/constants/formItem/inputs/inputCars'
import {
  fetchInputCars,
  createInputCar,
  updateInputCar,
} from '@/containers/inputs/inputCarsSlice'
import { setPageMeta } from '@/containers/pageMetasSlice'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import {
  THIS_YEAR_NUMBER,
  extractYearOrMonthFromDate,
  convertDateMonth,
} from '@/models/commonsModelFunc'
import { LOAN_FLG } from '@/models/inputs/inputCarsModelFunc'
import { RootState } from '@/store'
import { buildTwoTypesRangeYearSelectItems } from '@/utils/formItem'
import {
  composeValidators,
  required,
  commaNumberMaxLength,
  oneHundredLessThanNumber,
  zeroOrMoreNumber,
} from '@/utils/validate'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import { AccountContext } from '@/models/accountsModelFunc'
import { useParams } from 'react-router-dom'
import LoadingOverlay from '@/components/v1/atoms/LoadingOverlay'
import NumberFieldDecimal from '@/components/v2/molecules/NumberFieldDecimal'

// ローンの有無: 支払いなし
const LOAN_FLG_NOT_VALUES = {
  loan_amount_monthly: null,
  loan_interest_rate_percent: null,
  loan_bonus_monthly: null,
  loan_bonus_interest_rate_percent: null,
  loan_end_at: null,
}

const MyDataCarEdit: FC = () => {
  const dispatch = useDispatch()
  const { handleMovePage } = useCustomHistory()
  const { isAdmin } = useContext(AccountContext)
  const { account_id } = useParams<{ account_id?: string }>()

  const { showFlashMsg } = useFlashAlert()
  const { inputCars, isLoading } = useSelector((state: RootState) => state.inputCars)

  const buildInitialValues = useMemo(
    () => ({
      input_cars: inputCars.map((item) => ({
        ...item,
        stop_riding_year: extractYearOrMonthFromDate(item.stop_riding_at, 'year'),
        stop_riding_month: extractYearOrMonthFromDate(item.stop_riding_at, 'month'),
        loan_period_year: extractYearOrMonthFromDate(item.loan_end_at, 'year'),
        loan_period_month: extractYearOrMonthFromDate(item.loan_end_at, 'month'),
      })),
    }),
    [inputCars],
  )

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'マイデータ | 車・バイク編集',
        description: 'マイデータ | 車・バイク編集',
        keyword: 'マイデータ | 車・バイク編集',
      }),
    )
    dispatch(fetchInputCars())
  }, [])

  const submitInputCarFunc = (values: any) => {
    const isEdit = !_isUndefined(values.id)
    const { loan_flg } = values
    let newValues = {
      ...values,
      stop_riding_at: convertDateMonth(values.stop_riding_year, values.stop_riding_month),
    }

    // ローンの有無: 支払い中
    if (loan_flg === LOAN_FLG.todo) {
      newValues = {
        ...newValues,
        loan_bonus_interest_rate_percent: values.loan_interest_rate_percent,
        loan_end_at: convertDateMonth(values.loan_period_year, values.loan_period_month),
      }
    }

    // ローンの有無: 支払いなし
    if (loan_flg === LOAN_FLG.not) {
      newValues = { ...newValues, ...LOAN_FLG_NOT_VALUES }
    }

    newValues = { id: values.id, input_car: newValues }
    return new Promise((resolve) =>
      isEdit
        ? dispatch(
            updateInputCar(newValues, () => {
              showFlashMsg(isEdit ? '更新しました' : '作成しました')
              handleMovePage(
                isAdmin ? `/v2/accounts/${account_id}/myData/cars` : '/v2/myData/cars',
              )
            }),
          )
        : dispatch(
            createInputCar(
              newValues,
              () => {
                showFlashMsg(isEdit ? '更新しました' : '作成しました')
                handleMovePage(
                  isAdmin ? `/v2/accounts/${account_id}/myData/cars` : '/v2/myData/cars',
                )
              },
              resolve,
            ),
          ),
    )
  }

  const handleSubmit = (values: any) => {
    _cloneDeep(values.input_cars)
      .reduce(
        (acc: any, item: any) => acc.then(() => submitInputCarFunc(item)),
        Promise.resolve(),
      )
      .then(() =>
        handleMovePage(
          isAdmin ? `/v2/accounts/${account_id}/myData/cars` : '/v2/myData/cars',
        ),
      )
  }

  return (
    <>
      <LoadingOverlay isLoading={isLoading} />
      <MarginContainer className="px-[8px] md:px-[32px] lg:px-0 py-[8px] md:py-[40px]">
        <Breadcrumb
          className="pb-[10px] md:pb-[32px]"
          data={[
            {
              name: 'マイデータ',
              url: isAdmin ? `/v2/accounts/${account_id}/myData` : '/v2/myData',
            },
            {
              name: 'マイデータ設定',
              url: isAdmin ? `/v2/accounts/${account_id}/myData/cars` : '/v2/myData/cars',
            },
            { name: 'お車・バイクの設定' },
          ]}
        />

        <PaperTitle
          className="mb-[16px] md:mb-[40px]"
          title="車・バイク"
          description="日々の収入や支出を登録することで改善案などのご提案が可能になります"
          icon={<FamilyMonochromaticIcon />}
          smallIcon={
            <FamilyMonochromaticIcon
              className="h-[82px] w-[130px]"
              viewBox="0 0 117 154"
            />
          }
        />

        <Paper className="bg-white p-[24px] md:p-[40px]">
          <Typography
            className="mb-[32px] pb-[10px] text-[24px] md:text-[28px] text-black-800 border-b-2 border-black-300"
            isBold
          >
            車・バイクについて
          </Typography>

          <Form
            onSubmit={handleSubmit}
            initialValues={buildInitialValues}
            mutators={{ ...arrayMutators }}
            render={({
              handleSubmit,
              values,
              errors,
              form: {
                mutators: { push },
              },
            }) => (
              <form onSubmit={handleSubmit}>
                <FieldArray name="input_cars">
                  {({ fields }) =>
                    fields.map((name, index) => {
                      const loanFlg = fields.value[index]?.loan_flg

                      return (
                        <Grid
                          key={name}
                          className="pb-[64px] grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px]"
                        >
                          <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2">
                            <Typography
                              className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                              isBold
                            >
                              車・バイクなどの
                              <br className="hidden lg:block" />
                              表示用の名前
                            </Typography>
                            <TextField
                              type="text"
                              name={`${name}car_name`}
                              isFullWidth
                              validate={required}
                            />
                          </div>

                          <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2">
                            <Typography
                              className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                              isBold
                            >
                              この車・バイクは
                              <br className="hidden lg:block" />
                              いつまで乗る予定ですか
                            </Typography>
                            <div className="flex flex-auto items-baseline">
                              <SelectBox
                                name={`${name}stop_riding_year`}
                                className="pr-[8px]"
                                placeholder="年を選択"
                                isFullWidth
                                options={buildTwoTypesRangeYearSelectItems(
                                  THIS_YEAR_NUMBER,
                                  THIS_YEAR_NUMBER + 30,
                                )}
                                validate={required}
                              />
                              <SelectBox
                                name={`${name}stop_riding_month`}
                                widthClassName="max-w-[100px]"
                                placeholder="月を選択"
                                isFullWidth
                                options={MONTH_KANJI_SELECT_ITEMS}
                                validate={required}
                              />
                              <Typography
                                className="min-w-[fit-content] pl-[8px] text-[14px] text-black-800"
                                isBold
                              >
                                まで
                              </Typography>
                            </div>
                          </div>

                          <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                            <Typography
                              className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                              isBold
                            >
                              ローンの有無
                            </Typography>
                            <SelectBox
                              name={`${name}loan_flg`}
                              placeholder="選択する"
                              options={LOAN_FLG_RADIO_ITEMS}
                              isFullWidth
                              validate={required}
                            />
                          </div>

                          <div className="hidden md:block" />
                          {loanFlg === LOAN_FLG.todo && (
                            <>
                              <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                                <Typography
                                  className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                                  isBold
                                >
                                  毎月のローン返済額
                                </Typography>
                                <div className="flex flex-auto items-baseline">
                                  <NumberField
                                    name={`${name}loan_amount_monthly`}
                                    isFullWidth
                                    validate={composeValidators(
                                      required,
                                      zeroOrMoreNumber,
                                      (value: any) => commaNumberMaxLength(value, 7),
                                    )}
                                  />
                                  <Typography
                                    className="pl-[8px] text-[14px] text-black-800"
                                    isBold
                                  >
                                    円
                                  </Typography>
                                </div>
                              </div>

                              <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                                <Typography
                                  className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                                  isBold
                                >
                                  返済金利
                                </Typography>
                                <div className="flex flex-auto items-baseline">
                                  <NumberFieldDecimal
                                    name={`${name}loan_interest_rate_percent`}
                                    isFullWidth
                                    validate={composeValidators(
                                      required,
                                      zeroOrMoreNumber,
                                      oneHundredLessThanNumber,
                                    )}
                                  />
                                  <Typography
                                    className="min-w-[fit-content] pl-[8px] text-[14px] text-black-800"
                                    isBold
                                  >
                                    %
                                  </Typography>
                                </div>
                              </div>

                              <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2">
                                <Typography
                                  className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                                  isBold
                                >
                                  ローンのボーナス月の
                                  <br className="hidden lg:block" />
                                  上乗せ金額
                                </Typography>
                                <div className="flex flex-auto items-baseline">
                                  <NumberField
                                    name={`${name}loan_bonus_monthly`}
                                    isFullWidth
                                    validate={composeValidators(
                                      zeroOrMoreNumber,
                                      (value: any) => commaNumberMaxLength(value, 7),
                                    )}
                                  />
                                  <Typography
                                    className="pl-[8px] text-[14px] text-black-800"
                                    isBold
                                  >
                                    円
                                  </Typography>
                                </div>
                              </div>

                              <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                                <Typography
                                  className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                                  isBold
                                >
                                  返済期間
                                </Typography>
                                <div className="flex flex-auto items-baseline">
                                  <SelectBox
                                    name={`${name}loan_period_year`}
                                    className="pr-[8px]"
                                    placeholder="年を選択"
                                    isFullWidth
                                    options={buildTwoTypesRangeYearSelectItems(
                                      THIS_YEAR_NUMBER,
                                      THIS_YEAR_NUMBER + 30,
                                    )}
                                    validate={required}
                                  />
                                  <SelectBox
                                    name={`${name}loan_period_month`}
                                    widthClassName="max-w-[100px]"
                                    placeholder="月を選択"
                                    isFullWidth
                                    options={MONTH_KANJI_SELECT_ITEMS}
                                    validate={required}
                                  />
                                  <Typography
                                    className="min-w-[fit-content] pl-[8px] text-[14px] text-black-800"
                                    isBold
                                  >
                                    まで
                                  </Typography>
                                </div>
                              </div>
                            </>
                          )}

                          <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                            <Typography
                              className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                              isBold
                            >
                              車検代
                            </Typography>
                            <div className="flex flex-auto items-baseline">
                              <NumberField
                                name={`${name}vehicle_inspection_fee_yearly`}
                                isFullWidth
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 7),
                                )}
                              />
                              <Typography
                                className="pl-[8px] text-[14px] text-black-800"
                                isBold
                              >
                                円/年
                              </Typography>
                            </div>
                          </div>

                          <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                            <Typography
                              className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                              isBold
                            >
                              自動車税
                            </Typography>
                            <div className="flex flex-auto items-baseline">
                              <NumberField
                                name={`${name}automobile_tax_yearly`}
                                isFullWidth
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 7),
                                )}
                              />
                              <Typography
                                className="pl-[8px] text-[14px] text-black-800"
                                isBold
                              >
                                円/年
                              </Typography>
                            </div>
                          </div>

                          <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                            <Typography
                              className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                              isBold
                            >
                              保険料
                            </Typography>
                            <div className="flex flex-auto items-baseline">
                              <NumberField
                                name={`${name}insurance_premium_monthly`}
                                isFullWidth
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 7),
                                )}
                              />
                              <Typography
                                className="pl-[8px] text-[14px] text-black-800 min-w-[fit-content]"
                                isBold
                              >
                                円/月
                              </Typography>
                            </div>
                          </div>

                          <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                            <Typography
                              className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                              isBold
                            >
                              ガソリン代
                            </Typography>
                            <div className="flex flex-auto items-baseline">
                              <NumberField
                                name={`${name}gasoline_fee_monthly`}
                                isFullWidth
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 7),
                                )}
                              />
                              <Typography
                                className="pl-[8px] text-[14px] text-black-800 min-w-[fit-content]"
                                isBold
                              >
                                円/月
                              </Typography>
                            </div>
                          </div>

                          <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                            <Typography
                              className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                              isBold
                            >
                              駐車場代
                            </Typography>
                            <div className="flex flex-auto items-baseline">
                              <NumberField
                                name={`${name}parking_fee_monthly`}
                                isFullWidth
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 7),
                                )}
                              />
                              <Typography
                                className="pl-[8px] text-[14px] text-black-800 min-w-[fit-content]"
                                isBold
                              >
                                円/月
                              </Typography>
                            </div>
                          </div>

                          {index !== 0 && (
                            <Button
                              className="w-[112px] h-[37px] ml-auto border border-secondary-500 rounded-[4px] hover:bg-secondary-50"
                              onClick={() => fields.remove(index)}
                            >
                              <Typography
                                className="text-[14px] text-secondary-500"
                                isBold
                              >
                                削除する
                              </Typography>
                            </Button>
                          )}
                        </Grid>
                      )
                    })
                  }
                </FieldArray>

                <Button
                  className="w-full h-[53px] mb-[32px] border border-dashed border-primary-500 text-[14px] text-primary-500 hover:bg-primary-50"
                  onClick={() => push('input_cars', {})}
                >
                  <Typography isBold>追加</Typography>
                </Button>

                <ButtonPrimary
                  className="w-[246px] h-[64px] mx-auto mb-[16px]"
                  isDisabled={Object.keys(errors).length > 0 || isLoading}
                  onClick={() => handleSubmit(values)}
                >
                  <Typography
                    className="relative bottom-[2px] pl-[30px] text-[16px] text-white flex-auto"
                    isBold
                  >
                    保存する
                  </Typography>
                  <ChevronRightIcon className="mr-[17px]" />
                </ButtonPrimary>
                <Button
                  className="mx-auto text-[14px] text-black-700"
                  onClick={() =>
                    handleMovePage(
                      isAdmin
                        ? `/v2/accounts/${account_id}/myData/cars`
                        : '/v2/myData/cars',
                    )
                  }
                  isDisabled={isLoading}
                >
                  キャンセル
                </Button>
              </form>
            )}
          />
        </Paper>
      </MarginContainer>
    </>
  )
}

export default MyDataCarEdit
