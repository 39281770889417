import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import MarginContainer from '@/components/v2/atoms/MarginContainer'
import AccountSettingsTitle from '@/templates/v2/settings/commons/AccountSettingsTitle'
import AccountDeactivateForm from '@/templates/v2/settings/commons/AccountDeactiveForm'
import { setPageMeta } from '@/containers/pageMetasSlice'
import { setDeactivationFormResponse } from '@/containers/accountsSlice'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import { DeactivationFormResponseCreateValues } from '@/models/deactivationFormResponsesModel'

const AccountDeactivate: FC = () => {
  const dispatch = useDispatch()
  const { handleMovePage } = useCustomHistory()

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'マネソル退会手続き',
        description: 'マネソル退会手続き',
        keyword: 'マネソル退会手続き',
      }),
    )
  }, [])

  const handleSubmit = (values: DeactivationFormResponseCreateValues) => {
    dispatch(setDeactivationFormResponse(values))
    handleMovePage('/v2/settings/accountDeactivate/confirm')
  }

  return (
    <MarginContainer className="py-40">
      <div className="mx-8">
        <AccountSettingsTitle />
        <AccountDeactivateForm
          title="退会手続き"
          buttonLabel="入力内容を確認する"
          type="deactivate"
          handleSubmit={handleSubmit}
        />
      </div>
    </MarginContainer>
  )
}

export default AccountDeactivate
