import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { sortBy as _sortBy } from 'lodash'
import {
  postFutureIncomeCalc,
  patchFutureIncome,
  postFutureIncomeV2,
  deleteFutureIncomeV2,
} from '@/api/futures/futureIncomes'
import { unauthorizedCheck } from '@/containers/accountsSlice'
import { ApiError } from '@/models/commonsModel'
import { FutureIncomeBase } from '@/models/futures/futureIncomeBasesModel'
import {
  FutureIncomeCalcResponse,
  FutureIncomeCalc,
} from '@/models/futures/futureIncomesModel'
import { AppThunk } from '@/store'

interface FutureIncomeState {
  futureIncomeBases: FutureIncomeBase[]
  futureIncomeCalc: FutureIncomeCalc
  isLoading: boolean
}

const initialState: FutureIncomeState = {
  futureIncomeBases: [],
  futureIncomeCalc: {
    0: {}, // 将来ご本人
    1: {}, // 将来配偶者
    2: {}, // マイデータご本人
    3: {}, // マイデータ配偶者
  },
  isLoading: false,
}

const futureIncomesSlice = createSlice({
  name: 'futureIncomes',
  initialState,
  reducers: {
    setFutureIncomeBases: (state, action: PayloadAction<FutureIncomeBase[]>) => {
      state.futureIncomeBases = action.payload.map((item) => ({
        ...item,
        future_income_changes: _sortBy(item.future_income_changes, 'scheduled_at_age'),
      }))
      return state
    },
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    postFutureIncomeCalcSuccess: (
      state,
      action: PayloadAction<FutureIncomeCalcResponse>,
    ) => {
      state.futureIncomeCalc = action.payload
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
  },
})

export const {
  setFutureIncomeBases,
  requestStart,
  requestSuccess,
  postFutureIncomeCalcSuccess,
  requestFailure,
} = futureIncomesSlice.actions
export const futureIncomesReducer = futureIncomesSlice.reducer

export const createFutureIncomeCalc = (values: any): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postFutureIncomeCalc(values)
    .then((response) => dispatch(postFutureIncomeCalcSuccess(response)))
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const updateFutureIncome = (
  values: any,
  callback: () => void,
  resolve?: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchFutureIncome(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error, resolve))
    })
}

export const createFutureIncomeV2 = (
  values: any,
  callback?: () => void,
  resolve?: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postFutureIncomeV2(values)
    .then(() => {
      dispatch(requestSuccess())
      callback && callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error, resolve))
    })
}

export const destroyFutureIncomeV2 = (
  values: any,
  id: number,
  callback?: () => void,
  resolve?: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  deleteFutureIncomeV2(values, id)
    .then(() => {
      dispatch(requestSuccess())
      callback && callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error, resolve))
    })
}
