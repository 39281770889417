import { times as _times } from 'lodash'
import {
  THIS_YEAR_NUMBER,
  MONTH,
  PREFECTURE,
  addZeroToStringMonth,
  combineTwoTypesYear,
} from '@/models/commonsModelFunc'
import { SEX } from '@/models/familyStructuresModelFunc'

// あり・なし、いる・いないなど(Boolean型)
export const API_BOOLEAN_FLAG = {
  on: true,
  off: false,
}

// 年（例：2021年(令和元年)）
export const TWO_TYPES_YEAR_SELECT_ITEMS = _times(70, (index) => ({
  value: String(index + THIS_YEAR_NUMBER),
  label: combineTwoTypesYear(index + THIS_YEAR_NUMBER),
}))

// 誕生年(西暦)
const birthYear = THIS_YEAR_NUMBER - 100

// 誕生年（例：2021年(令和元年)）
export const TWO_TYPES_BIRTH_YEAR_SELECT_ITEMS = _times(101, (index) => ({
  value: String(index + birthYear),
  label: combineTwoTypesYear(index + birthYear),
}))

// 誕生年（例：2021年(令和元年)）
const startYear = THIS_YEAR_NUMBER - 60
export const TWO_TYPES_LOAN_YEAR_SELECT_ITEMS = _times(121, (index) => ({
  value: String(index + startYear),
  label: combineTwoTypesYear(index + startYear),
}))

// 月（例：04）
export const MONTH_SELECT_ITEMS = [
  { value: MONTH.january, label: '01' },
  { value: MONTH.february, label: '02' },
  { value: MONTH.march, label: '03' },
  { value: MONTH.april, label: '04' },
  { value: MONTH.may, label: '05' },
  { value: MONTH.june, label: '06' },
  { value: MONTH.july, label: '07' },
  { value: MONTH.august, label: '08' },
  { value: MONTH.september, label: '09' },
  { value: MONTH.october, label: '10' },
  { value: MONTH.november, label: '11' },
  { value: MONTH.december, label: '12' },
]

// 月（例：4月）
export const MONTH_KANJI_SELECT_ITEMS = [
  { value: MONTH.january, label: '1月' },
  { value: MONTH.february, label: '2月' },
  { value: MONTH.march, label: '3月' },
  { value: MONTH.april, label: '4月' },
  { value: MONTH.may, label: '5月' },
  { value: MONTH.june, label: '6月' },
  { value: MONTH.july, label: '7月' },
  { value: MONTH.august, label: '8月' },
  { value: MONTH.september, label: '9月' },
  { value: MONTH.october, label: '10月' },
  { value: MONTH.november, label: '11月' },
  { value: MONTH.december, label: '12月' },
]

// 日
export const NEW_DAY_KANJI_SELECT_ITEMS = _times(31, (index) => ({
  value: addZeroToStringMonth(index + 1),
  label: addZeroToStringMonth(index + 1) + '日',
}))

// 都道府県
export const PREFECTURE_SELECT_ITEMS = [
  { value: PREFECTURE.hokkaido, label: '北海道' },
  { value: PREFECTURE.aomori, label: '青森県' },
  { value: PREFECTURE.iwate, label: '岩手県' },
  { value: PREFECTURE.miyagi, label: '宮城県' },
  { value: PREFECTURE.akita, label: '秋田県' },
  { value: PREFECTURE.yamagata, label: '山形県' },
  { value: PREFECTURE.fukushima, label: '福島県' },
  { value: PREFECTURE.ibaraki, label: '茨城県' },
  { value: PREFECTURE.tochigi, label: '栃木県' },
  { value: PREFECTURE.gunma, label: '群馬県' },
  { value: PREFECTURE.saitama, label: '埼玉県' },
  { value: PREFECTURE.chiba, label: '千葉県' },
  { value: PREFECTURE.tokyo, label: '東京都' },
  { value: PREFECTURE.kanagawa, label: '神奈川県' },
  { value: PREFECTURE.niigata, label: '新潟県' },
  { value: PREFECTURE.toyama, label: '富山県' },
  { value: PREFECTURE.ishikawa, label: '石川県' },
  { value: PREFECTURE.fukui, label: '福井県' },
  { value: PREFECTURE.yamanashi, label: '山梨県' },
  { value: PREFECTURE.nagano, label: '長野県' },
  { value: PREFECTURE.gifu, label: '岐阜県' },
  { value: PREFECTURE.shizuoka, label: '静岡県' },
  { value: PREFECTURE.aichi, label: '愛知県' },
  { value: PREFECTURE.mie, label: '三重県' },
  { value: PREFECTURE.shiga, label: '滋賀県' },
  { value: PREFECTURE.kyoto, label: '京都府' },
  { value: PREFECTURE.osaka, label: '大阪府' },
  { value: PREFECTURE.hyogo, label: '兵庫県' },
  { value: PREFECTURE.nara, label: '奈良県' },
  { value: PREFECTURE.wakayama, label: '和歌山県' },
  { value: PREFECTURE.tottori, label: '鳥取県' },
  { value: PREFECTURE.shimane, label: '島根県' },
  { value: PREFECTURE.okayama, label: '岡山県' },
  { value: PREFECTURE.hiroshima, label: '広島県' },
  { value: PREFECTURE.yamaguchi, label: '山口県' },
  { value: PREFECTURE.tokushima, label: '徳島県' },
  { value: PREFECTURE.kagawa, label: '香川県' },
  { value: PREFECTURE.ehime, label: '愛媛県' },
  { value: PREFECTURE.kochi, label: '高知県' },
  { value: PREFECTURE.fukuoka, label: '福岡県' },
  { value: PREFECTURE.saga, label: '佐賀県' },
  { value: PREFECTURE.nagasaki, label: '長崎県' },
  { value: PREFECTURE.kumamoto, label: '熊本県' },
  { value: PREFECTURE.ooita, label: '大分県' },
  { value: PREFECTURE.miyazaki, label: '宮崎県' },
  { value: PREFECTURE.kagoshima, label: '鹿児島県' },
  { value: PREFECTURE.okinawa, label: '沖縄県' },
]

// 年齢
export const AGE_SELECT_ITEMS = _times(120, (index) => ({
  value: index + 1,
  label: String(index + 1),
}))

// 性別
export const NEW_SEX_RADIO_ITEMS = [
  { value: SEX.men, label: '男性' },
  { value: SEX.women, label: '女性' },
  { value: SEX.other, label: 'その他' },
]

// はい/いいえ
export const YES_NO_BUTTON_ITEMS = [
  { value: API_BOOLEAN_FLAG.on, label: 'はい' },
  { value: API_BOOLEAN_FLAG.off, label: 'いいえ' },
]
