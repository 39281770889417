import React, { FC, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form } from 'react-final-form'
import Heading from '@/components/v2/atoms/Heading'
import Typography from '@/components/v2/atoms/Typography'
import Modal from '@/components/v2/atoms/Modal'
import RadioGroup from '@/components/v2/molecules/RadioGroup'
import WholeSubmitErrorMessage from '@/components/v2/molecules/WholeSubmitErrorMessage'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import { MANIPULATED_TYPE } from '@/constants/formItem/accounts'
import { fetchAccount, updateAccount } from '@/containers/accountsSlice'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import { convertDateToJapaneseFormat } from '@/models/commonsModelFunc'
import { RootState } from '@/store'
import { required } from '@/utils/validate'

interface FpManipulatedModalFormProps {
  /** 期限日付 */
  deadlineDate: string
  /** true: オープン, false: クローズ */
  isOpen: boolean
  /** モーダルクローズメソッド */
  handleClose: () => void
}

const FpManipulatedModalForm: FC<FpManipulatedModalFormProps> = (props) => {
  const dispatch = useDispatch()
  const { showFlashMsg } = useFlashAlert()

  const { deadlineDate, isOpen, handleClose } = props
  const { account, isLoading } = useSelector((state: RootState) => state.accounts)

  const handleSubmit = (values: any) => {
    const newValues = {
      manipulated_stop_at: deadlineDate,
      manipulated_type: values.manipulated_type,
    }

    return new Promise((resolve) => {
      dispatch(
        updateAccount(
          newValues,
          () => {
            showFlashMsg('更新しました')
            dispatch(fetchAccount())
            handleClose()
          },
          resolve,
        ),
      )
    })
  }

  return (
    <Modal className="w-[95%] md:w-[760px]" isOpen={isOpen} onClose={handleClose}>
      <div className="space-y-24">
        <Heading className="text-center">FP側での操作の許可</Heading>
        <Typography className="text-14 text-black-700">
          FP側であなたの現在の情報やシミュレーションを追加、編集を許可するかを設定できます。
          <br />
          操作を許可するとFP側であなたの現在の情報やシミュレーションの情報を追加、編集することができます。
        </Typography>

        <Form
          onSubmit={handleSubmit}
          initialValues={useMemo(() => ({ manipulated_type: account.manipulated_type }), [
            account,
          ])}
          render={({ handleSubmit, values, errors, submitErrors }) => (
            <form className="space-y-24" onSubmit={handleSubmit}>
              <RadioGroup
                name="manipulated_type"
                datas={[
                  {
                    label: '許可しない',
                    value: MANIPULATED_TYPE.notManipulated,
                  },
                  {
                    label: `今日から3ヶ月後の${convertDateToJapaneseFormat(
                      deadlineDate,
                      'fullDate',
                    )}まで許可する`,
                    value: MANIPULATED_TYPE.limited,
                  },
                  {
                    label: '期間の制限なしで許可する',
                    value: MANIPULATED_TYPE.forever,
                  },
                ]}
                validate={required}
              />

              <WholeSubmitErrorMessage className="u-pb10" submitErrors={submitErrors} />
              <div className="flex justify-center">
                <ButtonPrimary
                  className="h-45 w-full md:w-215 text-white"
                  isDisabled={Object.keys(errors).length > 0 || isLoading}
                  onClick={() => handleSubmit(values)}
                >
                  <Typography
                    className="bottom-2 flex-auto pl-30 relative text-16"
                    isBold
                  >
                    更新する
                  </Typography>
                  <i className="ico chevron-right mr-17 text-24" />
                </ButtonPrimary>
              </div>
            </form>
          )}
        />
      </div>
    </Modal>
  )
}

export default FpManipulatedModalForm
