import React, { FC } from 'react'
import {
  Accordion as MuiAccordion,
  AccordionProps as MuiAccordionProps,
} from '@material-ui/core'

interface AccordionProps extends MuiAccordionProps {}

const Accordion: FC<AccordionProps> = (props) => {
  return <MuiAccordion {...props} />
}

export default Accordion
