import React from 'react'
import { Bar, Cell } from 'recharts'

interface RenderCustomBarProps {
  /** 名前 */
  name: string
  /** データキー */
  dataKey: string
  /** アクティブ番号 */
  activeIndex?: number | null
  /** スタックID */
  stackId?: string
  /** カラー */
  color: string
  /** 丸み */
  radius?: number
  /** 背景スタイル */
  background?: object
  /** データ */
  data?: { [key: string]: any }[]
  /** onClickメソッド */
  onClick?: (item: any, index: number) => void
}

const renderCustomBar = (props: RenderCustomBarProps) => {
  const {
    name,
    dataKey,
    activeIndex,
    stackId,
    color,
    radius,
    background,
    data,
    onClick,
  } = props

  return (
    <Bar
      name={name}
      dataKey={dataKey}
      stackId={stackId}
      fill={color}
      radius={radius}
      background={background}
      onClick={onClick}
      style={{ cursor: 'pointer' }}
    >
      {data?.map((_, index) => (
        <Cell
          key={index}
          stroke={index === activeIndex ? '#90B83F' : undefined}
          strokeWidth={index === activeIndex ? 3 : undefined}
        />
      ))}
    </Bar>
  )
}

export default renderCustomBar
