import React, { FC } from 'react'
import { useField, useForm } from 'react-final-form'
import NumberFieldDecimal from '@/components/v2/molecules/NumberFieldDecimal'
import { INPUT_REPAIR_RESERVE_FUND_RATE_TYPE_SELECT_ITEMS } from '@/constants/formItem/inputs/inputHousings'
import {
  getTargetSelfOwnedAttributesName,
  REPAIR_RESERVE_FUND_RATE_TYPE,
} from '@/models/commonHousingModelFunc'
import {
  commaNumberMaxLength,
  composeValidators,
  maxLength,
  required,
  zeroOrMoreNumber,
} from '@/utils/validate'

import Button from '@/components/v2/atoms/Button'
import Typography from '@/components/v2/atoms/Typography'
import SelectBox from '@/components/v2/molecules/SelectBox'
import NumberField from '@/components/v2/molecules/NumberField'
import CircleTitle from '@/components/v2/molecules/CircleTitle'

interface RepairFundAndManagementProps {
  isFuture?: boolean
}

const RepairFundAndManagement: FC<RepairFundAndManagementProps> = (props) => {
  const { isFuture } = props
  const targetHousingSelfOwnedAttributesName = getTargetSelfOwnedAttributesName(isFuture)

  const {
    input: { value: repairReserveFundIncreaseType },
  } = useField(
    `${targetHousingSelfOwnedAttributesName}.repair_reserve_fund_increase_type`,
  )
  const {
    input: { value: isManagementAndFundTotal },
  } = useField(`${targetHousingSelfOwnedAttributesName}.is_management_and_fund_total`)

  const { change } = useForm()

  return (
    <>
      <div className="col-span-1 md:col-span-2" />
      <div className="col-span-1 md:col-span-2">
        <CircleTitle
          className="pt-40 pb-10 md:text-[19px]"
          title="月間の管理費と修繕積立金について"
        />
      </div>
      <div className="flex items-center my-[10px] col-span-1 md:col-span-2">
        <Button
          className='className="text-[14px] text-black-700 font-default min-w-[125px] h-[37px] border-[0.5px] border-black-700 px-[16px] mx-[16px] rounded-[10px]'
          children={
            isManagementAndFundTotal
              ? '詳細を入力する場合はこちら'
              : '総額で入力する場合はこちら'
          }
          onClick={() =>
            change(
              `${targetHousingSelfOwnedAttributesName}.is_management_and_fund_total`,
              !isManagementAndFundTotal,
            )
          }
        />
      </div>

      {isManagementAndFundTotal ? (
        <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
          <Typography
            className="min-w-[140px] lg:min-w-[210px] pb-[10px] text-[14px] text-black-800"
            isBold
          >
            管理費・修繕積立金合計
          </Typography>
          <div className="flex flex-auto items-baseline">
            <NumberField
              name={`${targetHousingSelfOwnedAttributesName}.management_fee_monthly_and_repair_reserve_fund_total`}
              isFullWidth
              validate={composeValidators(required, zeroOrMoreNumber, (value: any) =>
                commaNumberMaxLength(value, 10),
              )}
            />
            <Typography
              className="pl-[8px] text-[14px] text-black-800 min-w-[fit-content]"
              isBold
            >
              円/月
            </Typography>
          </div>
        </div>
      ) : (
        <>
          <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
            <Typography
              className="min-w-[140px] lg:min-w-[210px] pb-[10px] text-[14px] text-black-800"
              isBold
            >
              月間管理費
            </Typography>
            <div className="flex flex-auto items-baseline">
              <NumberField
                name={`${targetHousingSelfOwnedAttributesName}.management_fee_monthly`}
                isFullWidth
                validate={composeValidators(required, zeroOrMoreNumber, (value: any) =>
                  commaNumberMaxLength(value, 10),
                )}
              />
              <Typography
                className="pl-[8px] text-[14px] text-black-800 min-w-[fit-content]"
                isBold
              >
                円/月
              </Typography>
            </div>
          </div>

          <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
            <Typography
              className="min-w-[140px] lg:min-w-[210px] pb-[10px] text-[14px] text-black-800"
              isBold
            >
              月間修繕積立金
            </Typography>
            <div className="flex flex-auto items-baseline">
              <NumberField
                name={`${targetHousingSelfOwnedAttributesName}.repair_reserve_fund`}
                isFullWidth
                validate={composeValidators(required, zeroOrMoreNumber, (value: any) =>
                  commaNumberMaxLength(value, 10),
                )}
              />
              <Typography
                className="pl-[8px] text-[14px] text-black-800 min-w-[fit-content]"
                isBold
              >
                円/月
              </Typography>
            </div>
          </div>

          <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
            <Typography
              className="min-w-[140px] lg:min-w-[210px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
              isBold
            >
              修繕積立金上昇率の設定
            </Typography>
            <SelectBox
              name={`${targetHousingSelfOwnedAttributesName}.repair_reserve_fund_increase_type`}
              placeholder="選択する"
              isFullWidth
              options={INPUT_REPAIR_RESERVE_FUND_RATE_TYPE_SELECT_ITEMS}
              validate={(value) => {
                if (!isManagementAndFundTotal) { return required(value) }
              }}
            />
          </div>

          {(!!repairReserveFundIncreaseType || repairReserveFundIncreaseType === 0) &&
            Number(repairReserveFundIncreaseType) ===
              REPAIR_RESERVE_FUND_RATE_TYPE.fixed && (
              <div className="u-pb20">
                <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                  <Typography
                    className="min-w-[140px] lg:min-w-[210px] pb-[10px] text-[14px] text-black-800"
                    isBold
                  >
                    月間
                  </Typography>
                  <div className="flex flex-auto items-baseline">
                    <NumberField
                      name={`${targetHousingSelfOwnedAttributesName}.repair_reserve_fund_amount`}
                      isFullWidth
                      validate={composeValidators(
                        required,
                        zeroOrMoreNumber,
                        (value: any) => maxLength(value, 7),
                      )}
                    />
                    <Typography className="pl-[8px] text-[14px] text-black-800" isBold>
                      円ずつ
                    </Typography>
                  </div>
                </div>

                <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                  <Typography
                    className="min-w-[140px] lg:min-w-[210px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                    isBold
                  >
                    築
                  </Typography>
                  <div className="flex flex-auto items-baseline">
                    <NumberField
                      className="w-120"
                      name={`${targetHousingSelfOwnedAttributesName}.repair_reserve_fund_amount_start`}
                      validate={composeValidators(
                        required,
                        zeroOrMoreNumber,
                        (value: any) => maxLength(value, 3),
                      )}
                    />
                    <Typography className="pl-[8px] text-[14px] text-black-800" isBold>
                      年目から
                    </Typography>
                    <NumberField
                      className="w-120"
                      name={`${targetHousingSelfOwnedAttributesName}.repair_reserve_fund_amount_finish`}
                      validate={composeValidators(
                        required,
                        zeroOrMoreNumber,
                        (value: any) => maxLength(value, 3),
                      )}
                    />
                    <Typography className="pl-[8px] text-[14px] text-black-800" isBold>
                      年目まで上昇させる
                    </Typography>
                  </div>
                </div>
              </div>
            )}
          {(!!repairReserveFundIncreaseType || repairReserveFundIncreaseType === 0) &&
            Number(repairReserveFundIncreaseType) ===
              REPAIR_RESERVE_FUND_RATE_TYPE.change && (
              <div className="pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                <div className="flex items-center min-w-[180px] lg:min-w-[173px] pb-[10px]">
                  <Typography className="mr-[10px] text-[14px] text-black-800" isBold>
                    修繕積立金上昇率
                  </Typography>
                </div>
                <div>
                  <div className="flex flex-auto items-baseline mb-[10px]">
                    <Typography
                      className="pr-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                      isBold
                    >
                      築
                    </Typography>
                    <NumberField
                      isFullWidth
                      name={`${targetHousingSelfOwnedAttributesName}.repair_reserve_fund_increase_start`}
                      validate={composeValidators(
                        required,
                        zeroOrMoreNumber,
                        (value: any) => commaNumberMaxLength(value, 2),
                      )}
                    />
                    <Typography
                      className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800 min-w-[70px]"
                      isBold
                    >
                      年目から
                    </Typography>
                    <NumberField
                      isFullWidth
                      name={`${targetHousingSelfOwnedAttributesName}.repair_reserve_fund_increase_span`}
                      validate={composeValidators(
                        required,
                        zeroOrMoreNumber,
                        (value: any) => commaNumberMaxLength(value, 2),
                      )}
                    />
                    <Typography
                      className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800 min-w-[70px]"
                      isBold
                    >
                      年毎に
                    </Typography>
                  </div>
                  <div className="flex flex-auto items-baseline">
                    <Typography
                      className="pr-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                      isBold
                    >
                      築
                    </Typography>
                    <NumberField
                      isFullWidth
                      name={`${targetHousingSelfOwnedAttributesName}.repair_reserve_fund_increase_finish`}
                      validate={composeValidators(
                        required,
                        zeroOrMoreNumber,
                        (value: any) => maxLength(value, 2),
                      )}
                    />
                    <Typography
                      className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800 min-w-[70px]"
                      isBold
                    >
                      年目まで
                    </Typography>

                    <NumberFieldDecimal
                      isFullWidth
                      name={`${targetHousingSelfOwnedAttributesName}.repair_reserve_fund_increase_percent`}
                      validate={composeValidators(
                        required,
                        zeroOrMoreNumber,
                        (value: any) => commaNumberMaxLength(value, 6),
                      )}
                    />
                    <Typography
                      className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800 min-w-[70px]"
                      isBold
                    >
                      ％に設定
                    </Typography>
                  </div>
                </div>
              </div>
            )}
        </>
      )}
    </>
  )
}

export default RepairFundAndManagement
