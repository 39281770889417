import React, { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { isUndefined as _isUndefined } from 'lodash'
import Typography from '@/components/v2/atoms/Typography'
import { convertLocaleString } from '@/models/commonsModelFunc'
import { RootState } from '@/store'

interface TooltipBalanceProps {
  /** アクティブ情報 */
  activeIndex: number | undefined
}

const TooltipBalance: FC<TooltipBalanceProps> = (props) => {
  const { activeIndex } = props
  const {
    summaryTransitions: { balance_graph },
  } = useSelector((state: RootState) => state.summaries)

  const balanceGraph = useMemo(() => {
    return (
      balance_graph?.map((obj: any) => {
        let newObj = { ...obj }
        newObj.date = obj.date.replace('-', '/')
        return newObj
      }) || []
    )
  }, [balance_graph])

  const activeItem: any = !_isUndefined(activeIndex) ? balanceGraph?.[activeIndex] : {}

  return (
    <>
      {!_isUndefined(activeIndex) ? (
        <div className="md:w-[250px] py-[24px] px-[16px] rounded-[8px] bg-white md:shadow-modal visible pointer-events-auto">
          <div className="mb-[16px] p-[10px] rounded-[4px] bg-primary-50">
            <Typography className="pb-[4px]" isBold>
              {activeItem.date}
            </Typography>
            <div className="flex items-center">
              <i
                className="ico circle relative top-px pr-[4px] text-[14px]"
                style={{ color: '#22a74b' }}
              />
              <Typography isBold>{`月間収支：${convertLocaleString(
                activeItem.balance_total_manyen,
              )}万円`}</Typography>
            </div>
          </div>

          <div className="flex justify-between items-center pb-[8px] last:pb-0">
            <div className="flex items-center">
              <i
                className="ico rect-2 pr-[8px] text-[12px]"
                style={{ color: '#FFD375' }}
              />
              <Typography className="text-[12px]">収入</Typography>
            </div>
            <Typography className="text-[12px]">{`${convertLocaleString(
              activeItem.income_total_manyen,
            )}万円`}</Typography>
          </div>
          <div className="flex justify-between items-center pb-[8px] last:pb-0">
            <div className="flex items-center">
              <i
                className="ico rect-2 pr-[8px] text-[12px]"
                style={{ color: '#9682F1' }}
              />
              <Typography className="text-[12px]">支出</Typography>
            </div>
            <Typography className="text-[12px]">{`${convertLocaleString(
              activeItem.spending_total_manyen,
            )}万円`}</Typography>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default TooltipBalance
