import React, { FC, useEffect, useMemo, ChangeEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import {
  isUndefined as _isUndefined,
  isEmpty as _isEmpty,
  find as _find,
  isNull as _isNull,
  last as _last,
  some as _some,
  each as _each,
  every as _every,
  cloneDeep as _cloneDeep,
  set as _set,
  pullAt as _pullAt,
  sortBy as _sortBy,
  slice as _slice,
  includes as _includes,
} from 'lodash'
import ChevronRightIcon from '@/assets/images/v2/chevron-right.svg'
import CrossIcon from '@/assets/images/v2/cross.svg'
import HelpCircleIcon from '@/assets/images/v2/help-circle.svg'
import Button from '@/components/v2/atoms/Button'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import Grid from '@/components/v2/atoms/Grid'
import Tooltip from '@/components/v2/atoms/Tooltip'
import Typography from '@/components/v2/atoms/Typography'
import NumberField from '@/components/v2/molecules/NumberField'
import SelectBox, { SelectBoxOption } from '@/components/v2/molecules/SelectBox'
import {
  CHANGE_TYPE_SELECT_ITEMS,
  RE_EMPLOYMENT_TYPE_RADIO_ITEMS,
  RE_EMPLOYMENT_FINISH_AT_AGE_SELECT_ITEMS,
  INSURANCE_PENSION_TYPE_SELECT_ITEMS,
} from '@/constants/formItem/commonIncomeChanges'
import { CURRENT_SALARY_INCREASE_RATE_SETTING_TYPE_SELECT_ITEMS } from '@/constants/formItem/commonIncomeBases'
import {
  COMPANY_SALARY_SEVERANCE_SYSTEM_TYPE,
  BONUS_MONTHS_TYPE,
  BASE_INCOME_TYPE,
  ROLE,
} from '@/constants/formItem/admins/companySalaryBases'
import { EVALUATION_SELECT_ITEMS } from '@/constants/formItem/inputs/inputSalaryCompanies'
import { fetchFamilyStructureV2 } from '@/containers/familyStructuresSlice'
import { fetchCompanySalaryBaseV2 } from '@/containers/admins/companySalaryBasesSlice'
import {
  fetchInputIncomes,
  createInputIncomeCalc,
  createInputIncome,
  updateInputIncome,
} from '@/containers/inputs/inputIncomesSlice'
import { fetchInputSalaries } from '@/containers/inputs/inputSalariesSlice'
import {
  createInputSalaryCompanyCalc,
  createInputSalaryCompany,
  updateInputSalaryCompany,
} from '@/containers/inputs/inputSalaryCompaniesSlice'
import {
  CURRENT_SALARY_INCREASE_RATE_SETTING_TYPE,
  ACTIVE_FLG,
  convertRateSettingType,
} from '@/models/commonIncomeBasesModelFunc'
import {
  formatIncomeChanges,
  CHANGE_TYPE,
  RETIREMENT_AGE,
  convertChangeType,
} from '@/models/commonIncomeChangesModelFunc'
import {
  isNotEmptyValue,
  THIS_YEAR_NUMBER,
  extractYearOrMonthFromDate,
  convertNumberYenToManyen,
  castNumberWithoutComma,
  convertYenToManyen,
  castNumberWithoutOperator,
  convertLocaleString,
  API_FLAG,
  defaultToEmptyString,
} from '@/models/commonsModelFunc'
import { isPerson } from '@/models/familyStructuresModelFunc'
import { buildUpdateInitialValues } from '@/models/inputs/inputIncomesModelFunc'
import { buildInputSalaryInitialValues } from '@/models/inputs/inputSalaryCompaniesModelFunc'
import { InputSalaryCompanyChangesAttribute } from '@/models/inputs/inputSalaryCompaniesModel'
import { RootState } from '@/store'
import { buildRangeAgeSelectItems } from '@/utils/formItem'
import {
  required,
  composeValidators,
  zeroOrMoreNumber,
  commaNumberMaxLength,
  maxLength,
  limitRangeNumber,
} from '@/utils/validate'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import WholeSubmitErrorMessage from '@/components/v2/molecules/WholeSubmitErrorMessage'
import LoadingOverlay from '@/components/v1/atoms/LoadingOverlay'
import NumberFieldDecimal from '@/components/v2/molecules/NumberFieldDecimal'
import clsx from 'clsx'
import SubmitErrorMessage from '@/components/v2/molecules/SubmitErrorMessage'
import { convertInsurancePensionType, convertIndustry } from '@/models/inputs/inputSalariesModelFunc'
import { updateResetFlg } from '@/containers/futures/futureBasesSlice'
import { INPUT_INDUSTRY_BUTTON_ITEMS } from '@/constants/formItem/inputs/inputSalaries'

interface MyDataIncomeEditFormProps {
  personFlg: number
  onClose: () => void
  onSubmit: () => void
}

const MyDataIncomeEditForm: FC<MyDataIncomeEditFormProps> = (props) => {
  const dispatch = useDispatch()
  const { showFlashMsg } = useFlashAlert()

  const { familyInfoV2 } = useSelector((state: RootState) => state.familyStructures)
  const { inputSalaries } = useSelector((state: RootState) => state.inputSalaries)
  const { isGetAccountShowInputLoading } = useSelector(
    (state: RootState) => state.accounts,
  )
  const { inputIncomeCalc, inputIncomeBases } = useSelector(
    (state: RootState) => state.inputIncomes,
  )
  const { companySalaryBase } = useSelector(
    (state: RootState) => state.companySalaryBases,
  )
  const { inputSalaryCompanyCalc } = useSelector(
    (state: RootState) => state.inputSalaryCompanies,
  )
  const isLoadingFamilyStructures = useSelector(
    (state: RootState) => state.familyStructures.isLoading,
  )
  const isLoadingInputSalaries = useSelector(
    (state: RootState) => state.inputSalaries.isLoading,
  )
  const isLoadingInputIncome = useSelector(
    (state: RootState) => state.inputIncomes.isLoading,
  )
  const isLoadingCompanySalaryBase = useSelector(
    (state: RootState) => state.companySalaryBases.isLoading,
  )
  const isLoadingInputSalaryCompany = useSelector(
    (state: RootState) => state.inputSalaryCompanies.isLoading,
  )

  const isCompanySalary = !_isNull(companySalaryBase)
  const { personFlg } = props
  const age = isPerson(personFlg)
    ? THIS_YEAR_NUMBER - extractYearOrMonthFromDate(familyInfoV2?.birth_day, 'year') || 0
    : THIS_YEAR_NUMBER -
        extractYearOrMonthFromDate(familyInfoV2?.spouse_birth_day, 'year') || 0

  const isSeverancePointMigrated =
    companySalaryBase?.company_salary_severance_system_type ===
      COMPANY_SALARY_SEVERANCE_SYSTEM_TYPE.point &&
    companySalaryBase?.company_salary_severance_point_system?.['is_system_migrated?']
  const isSeveranceIncome =
    companySalaryBase?.company_salary_severance_system_type ===
    COMPANY_SALARY_SEVERANCE_SYSTEM_TYPE.income

  const targetIncomeCalcItem = inputSalaryCompanyCalc?.income_hash?.[personFlg]
  const targetSeverancePay = inputSalaryCompanyCalc?.severance_pay?.[personFlg]
  const targetInputIncomeCalc = inputIncomeCalc?.[personFlg]

  const currentJobAgeSelectItems = useMemo(
    () => buildRangeAgeSelectItems(age as number, RETIREMENT_AGE),
    [age],
  )

  const workingStartageSelectItems = useMemo(
    () => buildRangeAgeSelectItems(18, age as number),
    [age],
  )

  const targetInputIncome = useMemo(
    () => _find(inputIncomeBases, (item) => item.person_flg === personFlg),
    [inputIncomeBases],
  )

  const targetInputSalary = useMemo(
    () => _find(inputSalaries, (item) => item.person_flg === personFlg),
    [inputSalaries],
  )

  const gradeSelectItems = useMemo(() => {
    if (!isCompanySalary) return []

    const selectItems: SelectBoxOption[] = companySalaryBase!.company_salary_grades.map(
      (item) => ({
        value: item.id,
        label: item.name,
      }),
    )
    selectItems.push({ value: 'retired', label: '役職定年' })

    return selectItems
  }, [companySalaryBase])

  const isAverageFiveYears = useMemo(
    () =>
      _some(
        companySalaryBase?.company_salary_grades,
        (item) => item.bonus_months_type === BONUS_MONTHS_TYPE.averageFiveYears,
      ),
    [companySalaryBase],
  )

  const isEdit =
    isNotEmptyValue(targetInputIncome) ||
    isNotEmptyValue(targetInputSalary?.input_salary_company_base)

  const initialValues = useMemo(() => {
    if (!isEdit) {
      return {
        input_income: { input_income_changes_attributes: [] },
        input_salary: {
          input_salary_company_base_attributes: {
            input_salary_company_changes_attributes: [{}],
          },
        },
        retirement_age: {},
      }
    }

    const incomeInitialValues = buildUpdateInitialValues(targetInputIncome)
    const salaryInitialValues = buildInputSalaryInitialValues(targetInputSalary)

    return isCompanySalary
      ? { ...incomeInitialValues, ...salaryInitialValues }
      : incomeInitialValues
  }, [targetInputIncome, targetInputSalary, isEdit])

  useEffect(() => {
    if (inputSalaries.length == 0) dispatch(fetchInputSalaries())
    if (inputIncomeBases.length == 0) dispatch(fetchInputIncomes())
    if (!familyInfoV2) dispatch(fetchFamilyStructureV2())
    dispatch(fetchCompanySalaryBaseV2())
  }, [])

  const renderSeveranceIncomeLabel = () => {
    const baseIncomeType =
      companySalaryBase?.company_salary_severance_income_system?.base_income_type

    switch (baseIncomeType) {
      case BASE_INCOME_TYPE.basicIncome:
        return '退職時の基本給を入力してください。'
      case BASE_INCOME_TYPE.allIncome:
        return '退職時の基本給やその他手当を含んだ総収入を入力してください。'
      case BASE_INCOME_TYPE.other:
        return '退職時の算定基礎給の基準となる条件を入力してください。'
      default:
        return ''
    }
  }

  const renderFutureIncome = (targetAge: number) => {
    const year = THIS_YEAR_NUMBER + (targetAge - age)
    return `${convertYenToManyen(targetInputIncomeCalc[year])}万円`
  }

  const createInputSalaryFunc = (values: any, resolve: any) => {
    if (!isCompanySalary) return resolve({})

    const newValues = {
      input_salary: {
        ...values.input_salary,
        person_flg: personFlg,
      },
    }

    const {
      input_salary_company_changes_attributes,
    } = newValues.input_salary.input_salary_company_base_attributes

    const salaryChanges = input_salary_company_changes_attributes.map(
      (item: InputSalaryCompanyChangesAttribute) => {
        if (item.company_salary_grade_id === 'retired') {
          return {
            ...item,
            company_salary_grade_id:
              companySalaryBase?.company_salary_managerial_retired_grade?.id,
            is_managerial_retired: true,
          }
        }

        return { ...item }
      },
    )
    newValues.input_salary.input_salary_company_base_attributes.input_salary_company_changes_attributes = salaryChanges

    dispatch(
      createInputSalaryCompany(newValues, () => {
        showFlashMsg(isEdit ? '更新しました' : '作成しました')
        resolve({})
      }),
    )
  }

  const updateInputSalaryFunc = (values: any, resolve: any) => {
    if (!isCompanySalary) return resolve({})

    const newValues = {
      input_salary: {
        ...values.input_salary,
        person_flg: personFlg,
      },
    }

    const {
      input_salary_company_changes_attributes,
    } = newValues.input_salary.input_salary_company_base_attributes

    newValues.input_salary.input_salary_company_base_attributes.id =
      targetInputSalary?.input_salary_company_base?.id

    const salaryChanges = input_salary_company_changes_attributes.map(
      (item: InputSalaryCompanyChangesAttribute) => {
        if (item.company_salary_grade_id === 'retired') {
          return {
            ...item,
            company_salary_grade_id:
              companySalaryBase?.company_salary_managerial_retired_grade?.id,
            is_managerial_retired: true,
          }
        }

        return { ...item }
      },
    )
    newValues.input_salary.input_salary_company_base_attributes.input_salary_company_changes_attributes = salaryChanges

    dispatch(
      updateInputSalaryCompany(targetInputSalary!.id, newValues, () => {
        showFlashMsg(isEdit ? '更新しました' : '作成しました')
        resolve({})
      }),
    )
  }

  const handleCreate = (values: any) => {
    const createInputIncomeFunc = new Promise((resolve) => {
      const newValues = {
        input_income: {
          ...values.input_income,
          person_flg: personFlg,
          active_flg: ACTIVE_FLG.active,
        },
      }

      const lastIncomeChange: any = _last(
        _sortBy(values?.input_income?.input_income_changes_attributes, (v: any) => Number(v.scheduled_quit_at_age),),
      )

      const lastAge = !!lastIncomeChange ? Number(lastIncomeChange.scheduled_quit_at_age) : newValues.input_income.quit_current_job_at_age

      const retirementAgeItem = {
        ...values.retirement_age,
        change_type: CHANGE_TYPE.retirementAge,
        person_flg: personFlg,
        scheduled_at_age: lastAge,
      }

      if (_isUndefined(newValues.input_income.input_income_changes_attributes)) {
        newValues.input_income.input_income_changes_attributes = [retirementAgeItem]
      } else {
        const {
          quit_current_job_at_age,
          input_income_changes_attributes,
        } = newValues.input_income

        const formatedIncomeChanges = formatIncomeChanges(
          input_income_changes_attributes,
          Number(quit_current_job_at_age),
        )

        formatedIncomeChanges.push(retirementAgeItem)
        newValues.input_income.input_income_changes_attributes = formatedIncomeChanges
      }

      dispatch(
        createInputIncome(newValues, () => {
          showFlashMsg(isEdit ? '更新しました' : '作成しました')
          resolve({})
        }),
      )
    })

    const targetInputSalaryFunc = !!targetInputSalary
      ? new Promise((resolve) => updateInputSalaryFunc(values, resolve))
      : new Promise((resolve) => createInputSalaryFunc(values, resolve))

    Promise.all([createInputIncomeFunc, targetInputSalaryFunc]).then(() => {
      dispatch(fetchInputIncomes())
      dispatch(updateResetFlg())
      props.onSubmit()
    })
  }

  const handleUpdate = (values: any) => {
    const updateInputIncomeFunc = new Promise((resolve) => {
      const newValues = {
        id: targetInputIncome?.id,
        input_income: {
          ...values.input_income,
          id: targetInputIncome?.id,
          person_flg: personFlg,
          active_flg: ACTIVE_FLG.active,
        },
      }

      const lastIncomeChange: any = _last(
        _sortBy(values?.input_income?.input_income_changes_attributes, (v: any) => Number(v.scheduled_quit_at_age),),
      )

      const lastAge = !!lastIncomeChange ? Number(lastIncomeChange.scheduled_quit_at_age) : newValues.input_income.quit_current_job_at_age

      const retirementAgeItem = {
        ...values.retirement_age,
        change_type: CHANGE_TYPE.retirementAge,
        person_flg: personFlg,
        scheduled_at_age: lastAge,
      }

      if (_isUndefined(newValues.input_income.input_income_changes_attributes)) {
        newValues.input_income.input_income_changes_attributes = [retirementAgeItem]
      } else {
        const {
          quit_current_job_at_age,
          input_income_changes_attributes,
        } = newValues.input_income

        const formatedIncomeChanges = formatIncomeChanges(
          input_income_changes_attributes,
          Number(quit_current_job_at_age),
        )

        formatedIncomeChanges.push(retirementAgeItem)
        newValues.input_income.input_income_changes_attributes = formatedIncomeChanges
      }

      dispatch(
        updateInputIncome(newValues, () => {
          showFlashMsg(isEdit ? '更新しました' : '作成しました')
          resolve({})
        }),
      )
    })

    Promise.all([
      updateInputIncomeFunc,
      new Promise((resolve) => updateInputSalaryFunc(values, resolve)),
    ]).then(() => {
      dispatch(fetchInputIncomes())
      dispatch(updateResetFlg())
      props.onSubmit()
    })
  }

  const handleSubmit = (values: any) => {
    let result = true
    if (
      isNotEmptyValue(values?.change_type) ||
      isNotEmptyValue(values?.scheduled_quit_at_age) ||
      (Number(values?.change_type) === CHANGE_TYPE.employment || Number(values?.change_type) === CHANGE_TYPE.retirement) && isNotEmptyValue(values?.insurance_pension_type) ||
      (Number(values?.change_type) === CHANGE_TYPE.employment || Number(values?.change_type) === CHANGE_TYPE.retirementOfficer) && isNotEmptyValue(values?.current_salary_increase_rate_setting_type) ||
      Number(values?.change_type) === CHANGE_TYPE.employment && values?.current_salary_increase_rate_setting_type === CURRENT_SALARY_INCREASE_RATE_SETTING_TYPE.average && isNotEmptyValue(values?.industry) ||
      (Number(values?.change_type) === CHANGE_TYPE.employment || Number(values?.change_type) === CHANGE_TYPE.retirementOfficer) && values?.current_salary_increase_rate_setting_type === CURRENT_SALARY_INCREASE_RATE_SETTING_TYPE.input && isNotEmptyValue(values?.current_salary_increase_rate_percent)
    ) {
      window.confirm("入力途中の項目があります。\r\n必要事項を全て入力の上、追加ボタンを押してから保存ボタンを押してください。\r\nもしくは、最後のフォームは全て空欄のまま保存を押してください。")
      return
    }
    isEdit ? handleUpdate(values) : handleCreate(values)
  }

  return (
    <>
      <LoadingOverlay
        isLoading={
          isLoadingCompanySalaryBase ||
          isLoadingInputSalaryCompany ||
          isLoadingInputIncome ||
          isLoadingFamilyStructures ||
          isLoadingInputSalaries ||
          isGetAccountShowInputLoading
        }
      />
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validate={(values) => {
          if (isCompanySalary) {
            const quitCurrentJobAtAge = values?.input_income?.quit_current_job_at_age
            const isEmployment =
              isNotEmptyValue(quitCurrentJobAtAge) && Number(quitCurrentJobAtAge) != 0
            if (isEmployment && Number(quitCurrentJobAtAge) < age) {
              return '転職は現在の年齢以降で設定してください。' as any
            }

            const lastSalaryChange: any = _last(
              values?.input_salary?.input_salary_company_base_attributes
                ?.input_salary_company_changes_attributes,
            )

            if (Number(lastSalaryChange?.scheduled_quit_at_age) != RETIREMENT_AGE && !isEmployment) {
              return '59歳以下までのグレード設定しかない、もしくは、転職が設定されていません。'
            }

            if (Number(lastSalaryChange?.scheduled_quit_at_age) == RETIREMENT_AGE) {
              return undefined
            }
          }
        }}
        mutators={{ ...arrayMutators }}
        render={({
          handleSubmit,
          values,
          errors,
          submitErrors,
          submitFailed,
          form: { change },
        }) => {
          const lastSalaryChange: any = _last(
            values?.input_salary?.input_salary_company_base_attributes
              ?.input_salary_company_changes_attributes,
          )
          const lastSalaryChangeAge = Number(lastSalaryChange?.scheduled_quit_at_age)
          const isInputCompleted = Number(lastSalaryChange?.scheduled_quit_at_age) === RETIREMENT_AGE
          const isEmployment =
            isNotEmptyValue(values?.input_income?.quit_current_job_at_age) &&
            Number(values?.input_income?.quit_current_job_at_age) !== 0

          const lastIncomeChange: any = _last(
            values?.input_income?.input_income_changes_attributes,
          )
          const isIncomeInputCompleted =
            Number(lastIncomeChange?.scheduled_quit_at_age) >= RETIREMENT_AGE

          const isShowIncomeCompany =
            isCompanySalary &&
            Number(lastSalaryChange?.scheduled_quit_at_age) !== RETIREMENT_AGE &&
            isEmployment

          const isShowIncome =
            !isCompanySalary &&
            !!values?.input_income?.quit_current_job_at_age &&
            Number(values?.input_income?.quit_current_job_at_age) !== RETIREMENT_AGE &&
            age < RETIREMENT_AGE

          const isBeforeMigratedWork = useMemo(() => {
            if (!isSeverancePointMigrated) return false

            const workYear =
              THIS_YEAR_NUMBER -
              (age - Number(values?.input_salary?.working_start_at_age))
            const migratedYear = Number(
              extractYearOrMonthFromDate(
                companySalaryBase?.company_salary_severance_point_system
                  ?.system_migrated_at,
                'year',
              ),
            )

            return workYear < migratedYear
          }, [companySalaryBase])

          const isAllInputSalaryChange = useMemo(() => {
            const targetKeys = [
              'company_salary_grade_id',
              'scheduled_quit_at_age',
              'overtime_working_hours',
              'evaluation',
            ]
            let isAllInput = true

            _each(
              values?.input_salary?.input_salary_company_base_attributes
                ?.input_salary_company_changes_attributes,
              (item) => {
                const everyFlag = _every(targetKeys, (key) =>
                  isNotEmptyValue(item?.[key]),
                )
                if (!everyFlag) {
                  isAllInput = false
                  return
                }
              },
            )

            return isAllInput
          }, [
            values?.input_salary?.input_salary_company_base_attributes
              ?.input_salary_company_changes_attributes,
          ])

          const ageSelectItems = useMemo(
            () =>
              buildRangeAgeSelectItems(
                Number(values?.input_income?.quit_current_job_at_age) + 1,
                RETIREMENT_AGE,
              ),
            [values?.input_income?.quit_current_job_at_age],
          )

          useEffect(() => {
            if (!isSeverancePointMigrated) return
            if (isBeforeMigratedWork) return

            change(
              'input_salary.input_salary_company_base_attributes.before_migration_severance_evaluation_manyen',
              0,
            )
          }, [])

          useEffect(() => {
            if (isAllInputSalaryChange) {
              const newValues = {
                input_salary: {
                  ..._cloneDeep(values?.input_salary),
                  person_flg: personFlg,
                },
              }

              const salaryBase = {
                ...newValues?.input_salary?.input_salary_company_base_attributes,
                severance_base_income: castNumberWithoutOperator(
                  newValues?.input_salary?.input_salary_company_base_attributes
                    ?.severance_base_income,
                ),
              }
              _set(
                newValues,
                'input_salary.input_salary_company_base_attributes',
                salaryBase,
              )

              const salaryChanges = newValues?.input_salary
                ?.input_salary_company_base_attributes
                ?.input_salary_company_changes_attributes
                ? newValues?.input_salary?.input_salary_company_base_attributes?.input_salary_company_changes_attributes.map(
                    (item: InputSalaryCompanyChangesAttribute) => {
                      if (item.company_salary_grade_id === 'retired') {
                        return {
                          ...item,
                          company_salary_grade_id:
                            companySalaryBase?.company_salary_managerial_retired_grade
                              ?.id,
                          is_managerial_retired: true,
                        }
                      }

                      return { ...item }
                    },
                  )
                : []
              _set(
                newValues,
                'input_salary.input_salary_company_base_attributes.input_salary_company_changes_attributes',
                salaryChanges,
              )

              if (!_isEmpty(salaryChanges)) {
                dispatch(createInputSalaryCompanyCalc(newValues))
              }
            }
          }, [
            values?.input_salary?.input_salary_company_base_attributes
              ?.input_salary_company_changes_attributes,
            values?.input_salary?.input_salary_company_base_attributes
              ?.severance_base_income,
          ])

          useEffect(() => {
            _each(
              values?.input_salary?.input_salary_company_base_attributes
                ?.input_salary_company_changes_attributes,
              (item, index) => {
                const year = THIS_YEAR_NUMBER + (Number(item.scheduled_at_age) - age)
                change(
                  `input_salary.input_salary_company_base_attributes.input_salary_company_changes_attributes[${index}]estimated_annual_income`,
                  convertNumberYenToManyen(targetIncomeCalcItem?.[year]),
                )
              },
            )

            change(
              'input_salary.input_salary_company_base_attributes.severance_pay',
              convertNumberYenToManyen(targetSeverancePay),
            )

            if (isCompanySalary) {
              change(
                'retirement_age.severance_pay_manyen',
                convertNumberYenToManyen(targetSeverancePay),
              )
            }
          }, [inputSalaryCompanyCalc])

          useEffect(() => {
            const newValues = {
              input_income: { ...values?.input_income, person_flg: personFlg },
            }
            const formatedIncomeChanges = formatIncomeChanges(
              values?.input_income?.input_income_changes_attributes,
              Number(values?.input_income?.quit_current_job_at_age),
            )

            _set(
              newValues,
              'input_income.input_income_changes_attributes',
              formatedIncomeChanges,
            )

            dispatch(createInputIncomeCalc(newValues))
          }, [values?.input_income])

          const handleChangeWorkingStartAtAge = (value: number) => {
            change(
              'input_salary.input_salary_company_base_attributes.input_salary_company_changes_attributes[0]scheduled_at_age',
              value,
            )
          }

          const handleAddSalaryChange = () => {
            const newValues = { scheduled_at_age: lastSalaryChangeAge }
            const copiedSalaryChanges = [
              ...values?.input_salary?.input_salary_company_base_attributes
                ?.input_salary_company_changes_attributes,
            ]

            copiedSalaryChanges.push(newValues)
            change(
              'input_salary.input_salary_company_base_attributes.input_salary_company_changes_attributes',
              copiedSalaryChanges,
            )
          }

          const handleDeleteSalaryChange = (index: number) => {
            const copiedSalaryChanges = [
              ...values?.input_salary?.input_salary_company_base_attributes
                ?.input_salary_company_changes_attributes,
            ]
            _pullAt(copiedSalaryChanges, index)

            const formatedSalaryChanges = copiedSalaryChanges?.map((item, index) => {
              if (index === 0) return { ...item }
              return {
                ...item,
                scheduled_at_age: copiedSalaryChanges[index - 1].scheduled_quit_at_age,
              }
            })

            change(
              'input_salary.input_salary_company_base_attributes.input_salary_company_changes_attributes',
              formatedSalaryChanges,
            )

            const lastFormatedSalaryChanges = _last(formatedSalaryChanges)
            if (isEmployment) {
              change(
                'input_income.quit_current_job_at_age',
                lastFormatedSalaryChanges?.scheduled_quit_at_age,
              )
            }
          }

          const handleAddEmployment = () => {
            change('input_income.quit_current_job_at_age', lastSalaryChangeAge)
          }

          const handleDeleteEmployment = () => {
            change('input_income.quit_current_job_at_age', '')
          }

          const handleChangeQuitAtAge = (age: number, index: number) => {
            const isLast =
              index ===
              values?.input_salary?.input_salary_company_base_attributes
                ?.input_salary_company_changes_attributes?.length -
                1
            if (isLast) {
              if (!isEmployment) return
              change('input_income.quit_current_job_at_age', age)
            } else {
              change(
                `input_salary.input_salary_company_base_attributes.input_salary_company_changes_attributes[${
                  index + 1
                }]scheduled_at_age`,
                age,
              )
            }
          }

          const handleChangeGrade = (value: any, key: string) => {
            const isRetired = value === 'retired'
            const targetRole = isRetired
              ? companySalaryBase?.company_salary_managerial_retired_grade?.role
              : _find(
                  companySalaryBase?.company_salary_grades,
                  (item) => item.id === value,
                )?.role

            if (targetRole !== ROLE.manager) return
            change(`${key}overtime_working_hours`, 0)
          }

          const isDisabledOvertimeWorking = (gradeId: any) => {
            const isRetired = gradeId === 'retired'
            const targetRole = isRetired
              ? companySalaryBase?.company_salary_managerial_retired_grade?.role
              : _find(
                  companySalaryBase?.company_salary_grades,
                  (item) => item.id === gradeId,
                )?.role

            return targetRole === ROLE.manager
          }

          const handleAddIncomeChange = () => {
            const newValues: any = {
              person_flg: personFlg,
              change_type: values?.change_type,
              scheduled_quit_at_age: values?.scheduled_quit_at_age,
            }

            // 転職・独立を選択
            if (Number(values?.change_type) === CHANGE_TYPE.employment) {
              // 変更後の年収
              newValues.estimated_annual_income_manyen = castNumberWithoutComma( values?.estimated_annual_income_manyen,)
              // 変更後の社会保険
              newValues.insurance_pension_type = values?.insurance_pension_type
              // 収入上昇率
              newValues.current_salary_increase_rate_setting_type = values?.current_salary_increase_rate_setting_type
              // 収入上昇率（手入力）
              newValues.current_salary_increase_rate_percent = values?.current_salary_increase_rate_percent
              // 業種
              newValues.industry = values?.industry
              // 退職金
              newValues.severance_pay_manyen = castNumberWithoutComma( values?.severance_pay_manyen,)
            }

            // 退職・定年退職を選択
            if (Number(values?.change_type) === CHANGE_TYPE.retirement) {
              // 変更後の社会保険
              newValues.insurance_pension_type = values?.insurance_pension_type
            }

            // 転職を伴わない収入の変化を選択
            if (Number(values?.change_type) === CHANGE_TYPE.retirementOfficer) {
              // 変更後の年収
              newValues.retirement_officers_amount_manyen = castNumberWithoutComma( values.retirement_officers_amount_manyen,)
              // 収入上昇率
              newValues.current_salary_increase_rate_setting_type = values?.current_salary_increase_rate_setting_type
              // 収入上昇率（手入力）
              newValues.current_salary_increase_rate_percent = values?.current_salary_increase_rate_percent
              // 業種
              newValues.industry = targetInputSalary?.industry
              // 退職金
              newValues.severance_pay_manyen = castNumberWithoutComma( values?.severance_pay_manyen,)
            }

            const sortedIncomeChanges = _sortBy(
              [...(values?.input_income?.input_income_changes_attributes || []), newValues],
              'scheduled_quit_at_age',
            )

            change('input_income.input_income_changes_attributes', sortedIncomeChanges)
            change('change_type', null)
            change('scheduled_quit_at_age', null)
            change('estimated_annual_income_manyen', 0)
            change('retirement_officers_amount_manyen', 0)
            change('severance_pay_manyen', 0)
            change('insurance_pension_type', null)
            change('current_salary_increase_rate_setting_type', null)
            change('current_salary_increase_rate_percent', null)
            change('industry', null)
          }

          const handleResetIncomeChange = () => {
            change('change_type', null)
            change('scheduled_quit_at_age', null)
            change('estimated_annual_income_manyen', 0)
            change('retirement_officers_amount_manyen', 0)
            change('severance_pay_manyen', 0)
            change('insurance_pension_type', null)
            change('current_salary_increase_rate_setting_type', null)
            change('current_salary_increase_rate_percent', null)
            change('industry', null)
          }

          const renderFromAgeLabel = (index: number) => {
            if (index === 0) return Number(values?.input_income?.quit_current_job_at_age)

            const beforeIncomeChanges: any = _slice(
              values?.input_income?.input_income_changes_attributes,
              0,
              index,
            )
            const isBeforeVisible = _some(
              beforeIncomeChanges,
              (item) =>
                Number(item.scheduled_quit_at_age) >
                Number(values?.input_income?.quit_current_job_at_age),
            )

            return isBeforeVisible
              ? Number(
                  values?.input_income?.input_income_changes_attributes?.[index - 1]
                    ?.scheduled_quit_at_age,
                )
              : Number(values?.input_income?.quit_current_job_at_age)
          }

          const validateAddIncome = () => {
            if (
              !isNotEmptyValue(values?.change_type) ||
              !isNotEmptyValue(values?.scheduled_quit_at_age) ||
              (Number(values?.change_type) === CHANGE_TYPE.employment || Number(values?.change_type) === CHANGE_TYPE.retirement) && !isNotEmptyValue(values?.insurance_pension_type) ||
              (Number(values?.change_type) === CHANGE_TYPE.employment || Number(values?.change_type) === CHANGE_TYPE.retirementOfficer) && !isNotEmptyValue(values?.current_salary_increase_rate_setting_type) ||
              Number(values?.change_type) === CHANGE_TYPE.employment && values?.current_salary_increase_rate_setting_type === CURRENT_SALARY_INCREASE_RATE_SETTING_TYPE.average && !isNotEmptyValue(values?.industry) ||
              (Number(values?.change_type) === CHANGE_TYPE.employment || Number(values?.change_type) === CHANGE_TYPE.retirementOfficer) && values?.current_salary_increase_rate_setting_type === CURRENT_SALARY_INCREASE_RATE_SETTING_TYPE.input && !isNotEmptyValue(values?.current_salary_increase_rate_percent)
            ) {
              return true
            }

            let targetIncome = null
            switch (Number(values?.change_type)) {
              case CHANGE_TYPE.employment:
                targetIncome = values?.estimated_annual_income_manyen
                break
              case CHANGE_TYPE.retirement:
                targetIncome = 0
                break
              case CHANGE_TYPE.retirementOfficer:
                targetIncome = values?.retirement_officers_amount_manyen
                break
              default:
                break
            }

            if (!isNotEmptyValue(targetIncome)) return true

            const castTargetIncome = String(castNumberWithoutOperator(targetIncome))
            if (castTargetIncome.length > 6) return true

            return false
          }

          const setAnnualIncomeDefaultValue = () => {
            if (!isCompanySalary) return 0
            if (isEmployment) return 0

            return companySalaryBase?.re_employment_annual_income_manyen
          }

          return (
            <form onSubmit={handleSubmit}>
              {!isCompanySalary && (
                <Grid className="pb-[30px] grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px]">
                  <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-center">
                    <Typography className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800" isBold >
                      現在のお仕事の
                      <br className="hidden md:block" />
                      収入上昇率設定
                    </Typography>
                    <div className="flex-auto">
                      <SelectBox
                        name="input_income.current_salary_increase_rate_setting_type"
                        placeholder="選択する"
                        isFullWidth
                        options={CURRENT_SALARY_INCREASE_RATE_SETTING_TYPE_SELECT_ITEMS}
                        initialValue={CURRENT_SALARY_INCREASE_RATE_SETTING_TYPE_SELECT_ITEMS.at(0)?.value}
                      />
                    </div>
                  </div>

                  {values?.input_income?.current_salary_increase_rate_setting_type ===
                    CURRENT_SALARY_INCREASE_RATE_SETTING_TYPE.input && (
                    <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                      <Typography className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800" isBold >
                        収入上昇率
                      </Typography>
                      <div className="flex-auto">
                        <div className="flex flex-auto justify-end items-baseline pt-[8px]">
                          <NumberFieldDecimal
                            name="input_income.current_salary_increase_rate_percent"
                            widthClassName="md:max-w-[100px]"
                            isFullWidth
                            validate={composeValidators(required, (value: any) =>
                              limitRangeNumber(castNumberWithoutOperator(value), 0, 100),
                            )}
                          />
                          <Typography
                            className="pl-[8px] text-[14px] text-black-800"
                            isBold
                          >
                            ％
                          </Typography>
                        </div>
                      </div>
                    </div>
                  )}

                  {age < RETIREMENT_AGE && (
                    <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-center">
                      <Typography className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800" isBold >
                        収入変更予定年齢
                      </Typography>
                      <div className="flex flex-auto justify-end items-baseline">
                        <SelectBox
                          isFullWidth
                          name="input_income.quit_current_job_at_age"
                          widthClassName="md:max-w-[97px]"
                          placeholder="選択する"
                          options={currentJobAgeSelectItems}
                          validate={required}
                        />
                        <Typography
                          className="pl-[8px] text-[14px] text-black-800"
                          isBold
                        >
                          歳
                        </Typography>
                      </div>
                    </div>
                  )}

                  <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-center">
                    <Typography className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800" isBold >
                      現在のお仕事の退職金
                    </Typography>
                    <div className="flex items-baseline">
                      <NumberField
                        isFullWidth
                        name="input_income.severance_pay_manyen"
                        defaultValue={values?.severance_pay_manyen ?? 0}
                        widthClassName="md:w-[200px]"
                        validate={composeValidators(
                          required,
                          zeroOrMoreNumber,
                          (value: any) => commaNumberMaxLength(value, 6),
                        )}
                      />
                      <Typography
                        className="pl-[8px] text-[14px] text-black-800 min-w-[max-content]"
                        isBold
                      >
                        万円
                      </Typography>
                    </div>
                  </div>
                </Grid>
              )}

              {isCompanySalary && (
                <>
                  <Grid className="pb-[30px] grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px]">
                    <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2">
                      <Typography
                        className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                        isBold
                      >
                        現在のお仕事を開始した年齢
                      </Typography>
                      <div className="flex flex-auto justify-end items-baseline">
                        <SelectBox
                          name="input_salary.working_start_at_age"
                          widthClassName="max-w-[100px]"
                          placeholder="選択する"
                          options={workingStartageSelectItems}
                          validate={required}
                          onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                            handleChangeWorkingStartAtAge(Number(event.target.value))
                          }
                        />
                        <Typography
                          className="min-w-[fit-content] pl-[8px] text-[14px] text-black-800"
                          isBold
                        >
                          歳から
                        </Typography>
                      </div>
                    </div>

                    <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                      <Typography
                        className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                        isBold
                      >
                        通勤手当
                      </Typography>
                      <div className="flex flex-auto justify-end items-baseline">
                        <NumberField
                          name="input_salary.commutation_allowance"
                          isFullWidth
                          validate={required}
                        />
                        <Typography
                          className="pl-[8px] text-[14px] text-black-800 min-w-[fit-content]"
                          isBold
                        >
                          円/月
                        </Typography>
                      </div>
                    </div>
                  </Grid>

                  <table className="hidden lg:table w-full mb-[30px]">
                    <tbody>
                      <tr className="border-b border-black-300">
                        <td className="pb-[10px]">
                          <Typography isBold>期間</Typography>
                        </td>
                        <td className="pb-[10px]">
                          <Typography isBold>グレード</Typography>
                        </td>
                        <td className="pb-[10px]">
                          <Typography isBold>月額平均残業時間</Typography>
                        </td>
                        <td className="pb-[10px]">
                          <Typography isBold>想定人事評価</Typography>
                        </td>
                        <td className="pb-[10px]">
                          <div className="flex items-center">
                            <Typography className="pr-[5px]" isBold>
                              想定年収
                            </Typography>
                            <Tooltip
                              item={`給与は貴社のグレードごとの給与レンジを参考に人事評価と残業代を考慮した上で算出しています。${
                                isAverageFiveYears
                                  ? '賞与は貴社の過去5年間の平均値をもとに算出しています。'
                                  : ''
                              }`}
                              itemClassName="w-[200px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                            >
                              <HelpCircleIcon />
                            </Tooltip>
                          </div>
                        </td>
                        <td />
                      </tr>

                      <FieldArray name="input_salary.input_salary_company_base_attributes.input_salary_company_changes_attributes">
                        {({ fields }) =>
                          fields.map((name, index) => (
                            <tr key={name} className="border-b border-black-300">
                              <td className="py-[10px]">
                                <div className="flex items-baseline">
                                  <NumberField
                                    name={`${name}scheduled_at_age`}
                                    widthClassName="max-w-[100px]"
                                    defaultValue={fields.value[index].scheduled_at_age}
                                    disabled
                                  />
                                  <Typography className="min-w-[fit-content] px-[8px] text-[14px] text-black-800">
                                    歳から
                                  </Typography>
                                  <SelectBox
                                    name={`${name}scheduled_quit_at_age`}
                                    placeholder="選択する"
                                    widthClassName="max-w-[100px]"
                                    options={buildRangeAgeSelectItems(
                                      Number(fields.value[index].scheduled_at_age) + 1,
                                      RETIREMENT_AGE,
                                    )}
                                    validate={required}
                                    onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                                      handleChangeQuitAtAge(
                                        Number(event.target.value),
                                        index,
                                      )
                                    }
                                  />
                                  <Typography className="min-w-[fit-content] pl-[8px] text-[14px] text-black-800">
                                    歳まで
                                  </Typography>
                                </div>
                              </td>

                              <td className="py-[10px]">
                                <SelectBox
                                  name={`${name}company_salary_grade_id`}
                                  widthClassName="max-w-[100px]"
                                  placeholder="選択する"
                                  options={gradeSelectItems}
                                  validate={required}
                                  onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                                    handleChangeGrade(event.target.value, name)
                                  }
                                />
                              </td>

                              <td className="py-[10px]">
                                <div className="flex items-baseline">
                                  <NumberField
                                    name={`${name}overtime_working_hours`}
                                    widthClassName="max-w-[100px]"
                                    disabled={isDisabledOvertimeWorking(
                                      fields.value[index]?.company_salary_grade_id,
                                    )}
                                    validate={composeValidators(
                                      required,
                                      zeroOrMoreNumber,
                                    )}
                                  />
                                  <Typography className="min-w-[fit-content] pl-[8px] text-[14px] text-black-800">
                                    時間
                                  </Typography>
                                </div>
                              </td>

                              <td className="py-[10px]">
                                <SelectBox
                                  name={`${name}evaluation`}
                                  widthClassName="max-w-[100px]"
                                  placeholder="選択する"
                                  options={EVALUATION_SELECT_ITEMS}
                                  validate={required}
                                />
                              </td>

                              <td className="py-[10px]">
                                <div className="flex items-baseline">
                                  <NumberField
                                    name={`${name}estimated_annual_income`}
                                    widthClassName="max-w-[100px]"
                                    disabled
                                  />
                                  <Typography className="min-w-[fit-content] pl-[8px] text-[14px] text-black-800">
                                    万円
                                  </Typography>
                                </div>
                              </td>
                              <td className="py-[10px]">
                                {index !== 0 && (
                                  <Button onClick={() => handleDeleteSalaryChange(index)}>
                                    <CrossIcon />
                                  </Button>
                                )}
                              </td>
                            </tr>
                          ))
                        }
                      </FieldArray>

                      {isEmployment && (
                        <tr className="border-b border-black-300">
                          <td className="py-[10px]">
                            <div className="flex items-baseline">
                              <NumberField
                                name="input_income.quit_current_job_at_age"
                                widthClassName="max-w-[100px]"
                                defaultValue={
                                  values?.input_income?.quit_current_job_at_age
                                }
                                disabled
                              />
                              <Typography className="min-w-[fit-content] pl-[8px] text-[14px] text-black-800">
                                歳で転職
                              </Typography>
                            </div>
                          </td>
                          <td className="py-[10px]" colSpan={4}>
                            <Typography className="text-[14px] text-black-800">
                              転職は下記でシミュレーションすることができます
                            </Typography>
                          </td>
                          <td className="py-[10px]">
                            <Button onClick={handleDeleteEmployment}>
                              <CrossIcon />
                            </Button>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>

                  <div className="block lg:hidden pb-[30px]">
                    <FieldArray name="input_salary.input_salary_company_base_attributes.input_salary_company_changes_attributes">
                      {({ fields }) =>
                        fields.map((name, index) => (
                          <div
                            key={name}
                            className="relative mb-[10px] last:mb-0 border-b border-black-300"
                          >
                            <div className="flex items-baseline pb-[10px]">
                              <Typography
                                className="min-w-[120px] md:min-w-[200px] text-[14px] text-black-800"
                                isBold
                              >
                                期間
                              </Typography>
                              <div className="md:flex items-baseline">
                                <div className="flex items-baseline pb-[10px] md:pb-0">
                                  <NumberField
                                    name={`${name}scheduled_at_age`}
                                    widthClassName="max-w-[100px]"
                                    defaultValue={fields.value[index].scheduled_at_age}
                                    disabled
                                  />
                                  <Typography className="min-w-[fit-content] px-[8px] text-[14px] text-black-800">
                                    歳から
                                  </Typography>
                                </div>
                                <div className="flex items-baseline">
                                  <SelectBox
                                    name={`${name}scheduled_quit_at_age`}
                                    placeholder="選択する"
                                    widthClassName="max-w-[100px]"
                                    options={buildRangeAgeSelectItems(
                                      Number(fields.value[index].scheduled_at_age) + 1,
                                      RETIREMENT_AGE,
                                    )}
                                    validate={required}
                                    onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                                      handleChangeQuitAtAge(
                                        Number(event.target.value),
                                        index,
                                      )
                                    }
                                  />
                                  <Typography className="min-w-[fit-content] pl-[8px] text-[14px] text-black-800">
                                    歳まで
                                  </Typography>
                                </div>
                              </div>
                            </div>

                            <div className="flex items-baseline pb-[10px]">
                              <Typography
                                className="min-w-[120px] md:min-w-[200px] text-[14px] text-black-800"
                                isBold
                              >
                                グレード
                              </Typography>
                              <SelectBox
                                name={`${name}company_salary_grade_id`}
                                widthClassName="max-w-[100px]"
                                placeholder="選択する"
                                options={gradeSelectItems}
                                validate={required}
                                onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                                  handleChangeGrade(event.target.value, name)
                                }
                              />
                            </div>

                            <div className="flex items-baseline pb-[10px]">
                              <Typography
                                className="min-w-[120px] md:min-w-[200px] text-[14px] text-black-800"
                                isBold
                              >
                                月額平均残業時間
                              </Typography>
                              <div className="flex items-baseline">
                                <NumberField
                                  name={`${name}overtime_working_hours`}
                                  widthClassName="max-w-[100px]"
                                  disabled={isDisabledOvertimeWorking(
                                    fields.value[index]?.company_salary_grade_id,
                                  )}
                                  validate={composeValidators(required, zeroOrMoreNumber)}
                                />
                                <Typography className="min-w-[fit-content] pl-[8px] text-[14px] text-black-800">
                                  時間
                                </Typography>
                              </div>
                            </div>

                            <div className="flex items-baseline pb-[10px]">
                              <Typography
                                className="min-w-[120px] md:min-w-[200px] text-[14px] text-black-800"
                                isBold
                              >
                                想定人事評価
                              </Typography>
                              <SelectBox
                                name={`${name}evaluation`}
                                widthClassName="max-w-[100px]"
                                placeholder="選択する"
                                options={EVALUATION_SELECT_ITEMS}
                                validate={required}
                              />
                            </div>

                            <div className="flex items-baseline pb-[10px]">
                              <div className="flex items-center min-w-[120px] md:min-w-[200px]">
                                <Typography
                                  className="pr-[5px] text-[14px] text-black-800"
                                  isBold
                                >
                                  想定年収
                                </Typography>
                                <Tooltip
                                  item={`給与は貴社のグレードごとの給与レンジを参考に人事評価と残業代を考慮した上で算出しています。${
                                    isAverageFiveYears
                                      ? '賞与は貴社の過去5年間の平均値をもとに算出しています。'
                                      : ''
                                  }`}
                                  itemClassName="w-[200px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                                >
                                  <HelpCircleIcon />
                                </Tooltip>
                              </div>
                              <div className="flex items-baseline">
                                <NumberField
                                  name={`${name}estimated_annual_income`}
                                  widthClassName="max-w-[100px]"
                                  disabled
                                />
                                <Typography className="min-w-[fit-content] pl-[8px] text-[14px] text-black-800">
                                  万円
                                </Typography>
                              </div>
                            </div>

                            {index !== 0 && (
                              <Button
                                className="absolute top-[10px] right-0"
                                onClick={() => handleDeleteSalaryChange(index)}
                              >
                                <CrossIcon />
                              </Button>
                            )}
                          </div>
                        ))
                      }
                    </FieldArray>

                    {isEmployment && (
                      <div className="relative border-b border-black-300">
                        <div className="flex items-baseline pb-[10px]">
                          <Typography
                            className="min-w-[120px] md:min-w-[200px] text-[14px] text-black-800"
                            isBold
                          >
                            期間
                          </Typography>
                          <div className="flex items-baseline">
                            <NumberField
                              name="input_income.quit_current_job_at_age"
                              widthClassName="max-w-[100px]"
                              defaultValue={values?.input_income?.quit_current_job_at_age}
                              disabled
                            />
                            <Typography className="min-w-[fit-content] pl-[8px] text-[14px] text-black-800">
                              歳で転職
                            </Typography>
                          </div>
                        </div>
                        <Typography className="text-center text-[14px] text-black-800 pb-[10px]">
                          転職は次のページで
                          <br className="md:hidden" />
                          シミュレーションすることができます
                        </Typography>
                        <Button
                          className="absolute top-[10px] right-0"
                          onClick={handleDeleteEmployment}
                        >
                          <CrossIcon />
                        </Button>
                      </div>
                    )}
                  </div>

                  <div className="pb-[64px]">
                    {!isInputCompleted && !isEmployment && (
                      <Button
                        className="w-[209px] h-[21px] mx-auto mb-[70px] leading-[21px] text-[11px] text-[#686868] bg-white border border-[#686868] hover:bg-black-50"
                        isDisabled={!isAllInputSalaryChange}
                        onClick={handleAddSalaryChange}
                      >
                        この企業の中での収入の変化を追加する
                      </Button>
                    )}

                    {isSeveranceIncome && (
                      <>
                        <div className="lg:flex justify-between items-baseline mb-[10px] last:mb-0 pb-[10px] border-b border-black-300">
                          <Typography className="pb-[10px] lg:pb-0 text-[14px] text-black-800">
                            {renderSeveranceIncomeLabel()}
                          </Typography>
                          <div className="flex justify-end items-baseline">
                            <Typography className="min-w-[fit-content] pr-[8px] text-[14px] text-black-800">
                              退職金算定基礎給与
                            </Typography>
                            <NumberField
                              name="input_salary.input_salary_company_base_attributes.severance_base_income"
                              widthClassName="max-w-[200px]"
                              validate={required}
                            />
                            <Typography className="min-w-[fit-content] pl-[8px] text-[14px] text-black-800">
                              円
                            </Typography>
                          </div>
                        </div>

                        <div className="lg:flex justify-between items-baseline mb-[10px] last:mb-0 pb-[10px] border-b border-black-300">
                          <Typography className="pb-[10px] lg:pb-0 text-[14px] text-black-800">
                            あなたの退職金
                          </Typography>
                          <div className="flex justify-end items-baseline">
                            <Typography className="min-w-[fit-content] pr-[8px] text-[14px] text-black-800">
                              退職金
                            </Typography>
                            <NumberField
                              name="input_salary.input_salary_company_base_attributes.severance_pay"
                              widthClassName="max-w-[200px]"
                              disabled
                            />
                            <Typography className="min-w-[fit-content] pl-[8px] text-[14px] text-black-800">
                              万円
                            </Typography>
                          </div>
                        </div>
                      </>
                    )}

                    {!isInputCompleted && (
                      <>
                        {isSeverancePointMigrated && isBeforeMigratedWork && (
                          <div className="lg:flex justify-between items-end mb-[10px] last:mb-0 pb-[10px] border-b border-black-300">
                            <Typography className="pb-[10px] lg:pb-0 text-[14px] text-black-800">
                              現在のお勤め先は、退職金を決定するための制度があなたの在職中に移行されています。
                              <br />
                              移行時の退職金を入力してください。
                              <br />
                              ※各社所定の通知書もしくは人事部に問い合わせることでわかります。
                            </Typography>
                            <div className="flex justify-end items-baseline">
                              <NumberField
                                name="input_salary.input_salary_company_base_attributes.before_migration_severance_evaluation_manyen"
                                widthClassName="max-w-[200px]"
                                validate={required}
                              />
                              <Typography className="min-w-[fit-content] pl-[8px] text-[14px] text-black-800">
                                万円
                              </Typography>
                            </div>
                          </div>
                        )}

                        {Number(lastSalaryChange?.scheduled_quit_at_age) !== RETIREMENT_AGE &&
                          !isSeveranceIncome && (
                            <div className="lg:flex justify-between items-baseline mb-[10px] last:mb-0 pb-[10px] border-b border-black-300">
                              <Typography className="pb-[10px] lg:pb-0 text-[14px] text-black-800">
                                あなたの退職金
                              </Typography>
                              <div className="flex justify-end items-baseline">
                                <Typography className="min-w-[fit-content] pr-[8px] text-[14px] text-black-800">
                                  退職金
                                </Typography>
                                <NumberField
                                  name="input_salary.input_salary_company_base_attributes.severance_pay"
                                  widthClassName="max-w-[200px]"
                                  disabled
                                />
                                <Typography className="min-w-[fit-content] pl-[8px] text-[14px] text-black-800">
                                  万円
                                </Typography>
                              </div>
                            </div>
                          )}

                        {!isEmployment && (
                          <Button
                            className="w-[188px] h-[45px] mx-auto border border-dashed border-primary-500 text-[14px] text-primary-500 hover:bg-primary-50"
                            isDisabled={
                              !isAllInputSalaryChange || lastSalaryChangeAge < age
                            }
                            onClick={handleAddEmployment}
                          >
                            <Typography className="text-[16px]" isBold>
                              転職する
                            </Typography>
                          </Button>
                        )}
                      </>
                    )}
                  </div>
                </>
              )}

              {(isShowIncomeCompany || isShowIncome) && (
                <div className="lg:mx-[50px] pb-[50px]">
                  <div className="pb-[20px] last:pb-0">
                    <div className="flex justify-between p-[16px] border border-black-600 rounded-[4px]">
                      <Typography className="text-[14px] text-black-600" isBold>
                        現在
                      </Typography>
                    </div>
                    <div className="items-center min-h-[70px] ml-[30px] md:ml-[80px] p-[10px] pl-[20px] md:pl-[50px] border-l border-black-600">
                      <Typography className="min-w-[120px] pb-[10px] text-[14px]" isBold >
                        現在のお仕事
                      </Typography>
                      <div className="bg-[#f5f5f5] p-10" >
                        <Typography className="pb-[5px] text-[11px]">
                          {`現在の年収　${renderFutureIncome(age)}`}
                        </Typography>
                        <Typography className="pb-[5px] text-[11px]">
                          {`${
                            Number(values?.input_income?.quit_current_job_at_age) - 1 || 0
                          }歳11ヶ月時点の年収　${renderFutureIncome(
                            Number(values?.input_income?.quit_current_job_at_age) - 1,
                          )}`}
                        </Typography>
                      </div>
                    </div>
                    <div className="p-[16px] border border-black-600 rounded-[4px]">
                      <Typography className="text-[14px] text-black-600" isBold>
                        {`${
                          Number(values?.input_income?.quit_current_job_at_age) - 1 || 0
                        }歳11ヶ月まで`}
                      </Typography>
                    </div>
                  </div>

                  <FieldArray name="input_income.input_income_changes_attributes">
                    {({ fields }) =>
                      fields.map((name, index) => (
                        <React.Fragment key={name}>
                          {fields.value[index]?.scheduled_quit_at_age >
                            values?.input_income?.quit_current_job_at_age && (
                            <div className="pb-[20px] last:pb-0">
                              <div className="flex justify-between p-[16px] border border-black-600 rounded-[4px]">
                                <Typography className="text-[14px] text-black-600" isBold>
                                  {`${renderFromAgeLabel(index)}歳0ヶ月から`}
                                </Typography>
                                <Button onClick={() => fields.remove(index)}>
                                  <CrossIcon />
                                </Button>
                              </div>
                              <div className="items-center min-h-[70px] ml-[30px] md:ml-[80px] p-[10px] pl-[20px] md:pl-[50px] border-l border-black-600">
                                <Typography className="min-w-[120px] pb-[10px] text-[14px]" isBold >
                                  {convertChangeType( Number(fields.value[index]?.change_type),)}
                                </Typography>

                                {Number(fields.value[index]?.change_type) === CHANGE_TYPE.employment && (
                                  <div className="bg-[#f5f5f5] p-10" >
                                    <Typography className="pb-[5px] text-[11px]">
                                      {`変更後の年収 ${convertLocaleString(
                                        Number(fields.value[index]?.change_type) === CHANGE_TYPE.employment
                                          ? fields.value[index]?.estimated_annual_income_manyen
                                          : fields.value[index]?.retirement_officers_amount_manyen,
                                      )}万円`}
                                    </Typography>
                                    <Typography className="pb-[5px] text-[11px]">
                                      {`変更後の社会保険 ${ convertInsurancePensionType( fields.value[index]?.insurance_pension_type || null )}`}
                                    </Typography>
                                    <Typography className="pb-[5px] text-[11px]">
                                      {`変更後の収入上昇率 `}
                                      {
                                        fields.value[index]?.current_salary_increase_rate_setting_type == CURRENT_SALARY_INCREASE_RATE_SETTING_TYPE.input ?
                                          `${defaultToEmptyString(fields.value[index]?.current_salary_increase_rate_percent )}%` :
                                          `${convertRateSettingType(fields.value[index]?.current_salary_increase_rate_setting_type )}`
                                      }
                                    </Typography>
                                    { fields.value[index]?.current_salary_increase_rate_setting_type === CURRENT_SALARY_INCREASE_RATE_SETTING_TYPE.average && (
                                      <Typography className="pb-[5px] text-[11px]">
                                        {`変更後の業種 `}
                                        {convertIndustry(fields.value[index]?.industry || null)}
                                      </Typography>
                                    )}
                                    <Typography className="pb-[5px] text-[11px]">
                                      {`${ fields.value[index]?.scheduled_quit_at_age - 1 }歳11ヶ月時点の年収` +
                                       `${renderFutureIncome( Number( fields.value[index]?.scheduled_quit_at_age,) - 1 )}` }
                                    </Typography>

                                    { Number(fields.value[index]?.severance_pay_manyen) > 0 && (
                                      <Typography className="pb-[5px] text-[11px]">
                                        {`退職金 ${fields.value[index]?.severance_pay_manyen}万円`}
                                      </Typography>
                                    )}
                                  </div>
                                )}

                                {Number(fields.value[index]?.change_type) === CHANGE_TYPE.retirementOfficer && (
                                  <div className="bg-[#f5f5f5] p-10" >
                                    <Typography className="pb-[5px] text-[11px]">
                                      {`変更後の年収　${convertLocaleString(
                                        Number(fields.value[index]?.change_type) === CHANGE_TYPE.employment
                                          ? fields.value[index]?.estimated_annual_income_manyen
                                          : fields.value[index]?.retirement_officers_amount_manyen,
                                      )}万円`}
                                    </Typography>
                                    <Typography className="pb-[5px] text-[11px]">
                                      {`変更後の収入上昇率 `}
                                      {
                                        fields.value[index]?.current_salary_increase_rate_setting_type == CURRENT_SALARY_INCREASE_RATE_SETTING_TYPE.input ?
                                          `${defaultToEmptyString(fields.value[index]?.current_salary_increase_rate_percent )}%` :
                                          `${convertRateSettingType(fields.value[index]?.current_salary_increase_rate_setting_type )}`
                                      }
                                    </Typography>
                                    <Typography className="pb-[5px] text-[11px]">
                                      {`${ fields.value[index]?.scheduled_quit_at_age - 1 }歳11ヶ月時点の年収` +
                                       `${renderFutureIncome( Number( fields.value[index]?.scheduled_quit_at_age,) - 1 )}` }
                                    </Typography>

                                    { Number(fields.value[index]?.severance_pay_manyen) > 0 && (
                                      <Typography className="pb-[5px] text-[11px]">
                                        {`退職金 ${fields.value[index]?.severance_pay_manyen}万円`}
                                      </Typography>
                                    )}
                                  </div>
                                )}

                                {Number(fields.value[index]?.change_type) === CHANGE_TYPE.retirement && (
                                  <div className="bg-[#f5f5f5] p-10" >
                                    <Typography className="pb-[5px] text-[11px]">
                                      {`変更後の社会保険 ${ convertInsurancePensionType( fields.value[index]?.insurance_pension_type || null )}`}
                                    </Typography>
                                  </div>
                                )}

                              </div>
                              <div className="p-[16px] border border-black-600 rounded-[4px]">
                                <Typography className="text-[14px] text-black-600" isBold>
                                  {`${ fields.value[index]?.scheduled_quit_at_age - 1 }歳11ヶ月まで`}
                                </Typography>
                              </div>
                            </div>
                          )}
                        </React.Fragment>
                      ))
                    }
                  </FieldArray>

                  {!isIncomeInputCompleted && (
                    <>
                      <div className="p-[16px] border border-black-600 rounded-[4px] mt-[16px]">
                        <Grid className="pb-[20px] grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px]">
                        <div className="block md:flex justify-between items-baseline mb-[10px] last:mb-0 pb-[10px] border-b-2 border-dashed border-black-300">
                          <Typography className="min-w-[fit-content] md:min-w-[max-content] pb-[10px] md:pb-0 text-[14px] mr-[10px]">
                            変化の内容
                          </Typography>
                          <SelectBox
                            isFullWidth
                            name="change_type"
                            placeholder="選択する"
                            options={CHANGE_TYPE_SELECT_ITEMS}
                          />
                        </div>

                        {Number(values?.change_type) === CHANGE_TYPE.employment && (
                          <div className="block md:flex justify-between items-baseline mb-[10px] last:mb-0 pb-[10px] border-b-2 border-dashed border-black-300">
                            <Typography className="min-w-[fit-content] md:min-w-[max-content] pb-[10px] md:pb-0 text-[14px] mr-[10px]">
                              変更後の年収
                            </Typography>
                            <div className="flex items-baseline">
                              <NumberField
                                isFullWidth
                                name="estimated_annual_income_manyen"
                                defaultValue={values?.estimated_annual_income_manyen ?? 0}
                                widthClassName="md:w-[200px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="pl-[8px] text-[14px] text-black-800 min-w-[max-content]" isBold >
                                万円
                              </Typography>
                            </div>
                          </div>
                        )}

                        {Number(values?.change_type) === CHANGE_TYPE.retirementOfficer && (
                          <div className="block md:flex justify-between items-baseline mb-[10px] last:mb-0 pb-[10px] border-b-2 border-dashed border-black-300">
                            <Typography className="min-w-[fit-content] md:min-w-[max-content] pb-[10px] md:pb-0 text-[14px] mr-[10px]">
                              変更後の年収
                            </Typography>
                            <div className="flex items-baseline">
                              <NumberField
                                name="retirement_officers_amount_manyen"
                                defaultValue={
                                  values?.retirement_officers_amount_manyen ?? 0
                                }
                                isFullWidth
                                widthClassName="md:w-[200px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="pl-[8px] text-[14px] text-black-800 min-w-[max-content]" isBold >
                                万円
                              </Typography>
                            </div>
                          </div>
                        )}

                        {/* 変更後の加入保険/年金は転職を伴わないときは不要 */}
                        {(Number(values?.change_type) === CHANGE_TYPE.employment ||
                          Number(values?.change_type) === CHANGE_TYPE.retirement)&& (
                          <div className="block md:flex justify-between items-baseline mb-[10px] pb-[10px] border-b-2 border-dashed border-black-300">
                            <Typography className="min-w-[fit-content] md:min-w-[max-content] pb-[10px] md:pb-0 text-[14px]">
                              変更後の加入保険/年金
                            </Typography>
                            <div className="flex items-baseline">
                              <SelectBox
                                isFullWidth
                                className="md:w-[200px]"
                                name="insurance_pension_type"
                                options={INSURANCE_PENSION_TYPE_SELECT_ITEMS}
                                initialValue={INSURANCE_PENSION_TYPE_SELECT_ITEMS.at(0)?.value}
                                placeholder="選択する"
                              />
                            </div>
                          </div>
                        )}

                        {(Number(values?.change_type) === CHANGE_TYPE.employment ||
                          Number(values?.change_type) === CHANGE_TYPE.retirementOfficer ) && (
                            <>
                              {/* // 収入上昇率 */}
                              <div className="block md:flex justify-between items-baseline mb-[10px] last:mb-0 pb-[10px] border-b-2 border-dashed border-black-300">
                                <Typography className="min-w-[fit-content] md:min-w-[max-content] pb-[10px] md:pb-0 text-[14px] mr-[10px]">
                                  収入上昇率設定
                                </Typography>
                                <div className="flex">
                                  <SelectBox
                                    name="current_salary_increase_rate_setting_type"
                                    placeholder="選択する"
                                    isFullWidth
                                    options={CURRENT_SALARY_INCREASE_RATE_SETTING_TYPE_SELECT_ITEMS}
                                    initialValue={CURRENT_SALARY_INCREASE_RATE_SETTING_TYPE_SELECT_ITEMS.at(0)?.value}
                                  />
                                </div>
                              </div>

                              {/* // 収入上昇率（手入力） */}
                              { values?.current_salary_increase_rate_setting_type === CURRENT_SALARY_INCREASE_RATE_SETTING_TYPE.input && (
                                <div className="block md:flex justify-between items-baseline mb-[10px] last:mb-0 pb-[10px] border-b-2 border-dashed border-black-300">
                                  <Typography className="min-w-[fit-content] md:min-w-[max-content] pb-[10px] md:pb-0 text-[14px] mr-[10px]">
                                    収入上昇率
                                  </Typography>
                                  <div className="flex-auto">
                                    <div className="flex flex-auto justify-end items-baseline pt-[8px]">
                                      <NumberFieldDecimal
                                        name="current_salary_increase_rate_percent"
                                        widthClassName="md:max-w-[100px]"
                                        isFullWidth
                                        validate={composeValidators(required, (value: any) =>
                                          limitRangeNumber(castNumberWithoutOperator(value), 0, 100),
                                        )}
                                      />
                                      <Typography
                                        className="pl-[8px] text-[14px] text-black-800"
                                        isBold
                                      >
                                        ％
                                      </Typography>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {/* // 業界平均に準じて上昇させる */}
                              { Number(values?.change_type) === CHANGE_TYPE.employment && values?.current_salary_increase_rate_setting_type === CURRENT_SALARY_INCREASE_RATE_SETTING_TYPE.average && (
                                <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                                  <Typography
                                    className="min-w-[140px] lg:min-w-[280px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                                    isBold
                                  >
                                    業種
                                  </Typography>
                                  <SelectBox
                                    isFullWidth
                                    placeholder="選択してください"
                                    name="industry"
                                    options={INPUT_INDUSTRY_BUTTON_ITEMS}
                                    onChange={(event: any) => {
                                      const value = event.target.value
                                      change('industry', value)
                                    }}
                                  />
                                </div>
                              )}
                            </>
                        )}

                        <div className="block md:flex justify-between items-baseline mb-[10px] pb-[10px] border-b-2 border-dashed border-black-300">
                          <div className="flex items-center pb-[10px] md:pb-0">
                            <Typography className="min-w-[fit-content] md:min-w-[max-content] pb-[10px] md:pb-0 text-[14px] mr-[10px]">
                              退職予定年齢
                            </Typography>
                            <Tooltip
                              item="設定した年齢になるまで条件が適用されます。例えば、50歳までと登録した場合、49歳11ヶ月まで上記の条件が適用されます。"
                              itemClassName="w-[200px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                            >
                              <HelpCircleIcon />
                            </Tooltip>
                          </div>
                          <div className="flex items-baseline">
                            <SelectBox
                              isFullWidth
                              name="scheduled_quit_at_age"
                              widthClassName="md:max-w-[100px]"
                              placeholder="選択する"
                              options={ageSelectItems}
                            />
                            <Typography
                              className="pl-[8px] text-[14px] text-black-800 min-w-[fit-content]"
                              isBold
                            >
                              歳
                            </Typography>
                          </div>
                        </div>

                        {( Number(values?.change_type) === CHANGE_TYPE.employment ||
                           Number(values?.change_type) === CHANGE_TYPE.retirementOfficer) && (
                            <div className="block md:flex justify-between items-baseline mb-[10px] last:mb-0 pb-[10px] border-b-2 border-dashed border-black-300">
                              <Typography className="min-w-[fit-content] md:min-w-[max-content] pb-[10px] md:pb-0 text-[14px] mr-[10px]">
                                退職金
                              </Typography>
                              <div className="flex items-baseline">
                                <NumberField
                                  isFullWidth
                                  name="severance_pay_manyen"
                                  defaultValue={values?.severance_pay_manyen ?? 0}
                                  widthClassName="md:w-[200px]"
                                  validate={composeValidators(
                                    required,
                                    zeroOrMoreNumber,
                                    (value: any) => commaNumberMaxLength(value, 6),
                                  )}
                                />
                                <Typography
                                  className="pl-[8px] text-[14px] text-black-800 min-w-[max-content]"
                                  isBold
                                >
                                  万円
                                </Typography>
                              </div>
                            </div>
                        )}
                        </Grid>

                        <Button
                          className="w-[188px] h-[45px] mx-auto border border-dashed border-primary-500 text-[14px] text-primary-500 hover:bg-primary-50 mb-[16px]"
                          isDisabled={validateAddIncome()}
                          onClick={handleAddIncomeChange}
                        >
                          <Typography className="text-[16px]" isBold>
                            追加する
                          </Typography>
                        </Button>

                        <Button
                          className="mx-auto text-[14px] text-black-700"
                          onClick={handleResetIncomeChange}
                        >
                          リセット
                        </Button>

                        { submitFailed && errors.input_changes_age_restriction && (
                          <Typography className={clsx('text-center text-alert-600', "text-12")}>
                            { errors.input_changes_age_restriction }
                          </Typography>
                        )}
                      </div>
                    </>
                  )}
                </div>
              )}

              <WholeSubmitErrorMessage submitErrors={submitErrors} />
              <SubmitErrorMessage show={!submitErrors && submitFailed} />
              <ButtonPrimary
                className={clsx(
                  'w-[246px] h-[64px] mx-auto mb-[16px] ',
                )}
                onClick={() => handleSubmit(values)}
              >
                <Typography
                  className="relative bottom-[2px] pl-[30px] text-[16px] text-white flex-auto"
                  isBold
                >
                  保存する
                </Typography>
                <ChevronRightIcon className="mr-[17px]" />
              </ButtonPrimary>
              <Button
                className="mx-auto text-[14px] text-black-700"
                onClick={props.onClose}
              >
                キャンセル
              </Button>
            </form>
          )
        }}
      />
    </>
  )
}

export default MyDataIncomeEditForm

