import React, { FC } from 'react'
import MyDataPanelCommon from '@/templates/v2/myData/_myDataPanelCommon'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import { convertYenToDecimalPointManyen, termLabel } from '@/models/commonsModelFunc'

interface MyDataPanelIncomeProps {
  /** 表示期間 */
  term?: string
}

const MyDataPanelIncome: FC<MyDataPanelIncomeProps> = ({ term }) => {
  const { summaryBudgets } = useSelector((state: RootState) => state.summaries)

  return (
    <MyDataPanelCommon
      term={term}
      title={`${termLabel(term)}収入`}
      balance={convertYenToDecimalPointManyen(
        summaryBudgets.current_budgets_info.income_total,
        1,
      )}
      defference={convertYenToDecimalPointManyen(
        summaryBudgets.current_budgets_info.income_defference,
        1,
      )}
      bgColorClassName="bg-cream-600"
      chipBgColorClassName="bg-tertiary-500"
      defferenceColorClassName="text-tertiary-500"
    />
  )
}

export default MyDataPanelIncome
