import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { isNull as _isNull } from 'lodash'
import Typography from '@/components/v2/atoms/Typography'
import {
  convertLocaleString,
  convertDateToTwoTypesYearsDateFormat,
  defaultToEmptyString,
} from '@/models/commonsModelFunc'
import { RootState } from '@/store'
import SimulationAccordion from '@/templates/v2/simulations/_simulationAccordion'
import MyDataMonthlyCostForm from '@/templates/v2/myData/monthlyCosts/Form'
import Modal from '@/components/v2/atoms/Modal'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import Button from '@/components/v2/atoms/Button'
import SimulationNewButton from '@/templates/v2/simulations/_simulationNewButton'

export const MonthlyCostTable: FC<any> = () => {
  const { inputMonthlyCost } = useSelector((state: RootState) => state.inputMonthlyCosts)
  return inputMonthlyCost ? (
    <table className="w-[100%] text-[12px] md:text-[14px]">
      <tbody>
        {!_isNull(inputMonthlyCost?.moneytree_average_months) && (
          <tr className="h-[45px] odd:bg-black-100">
            <td className="w-[40%] px-[16px]">
              <Typography>
                基本生活費の
                <br />
                設定方法
              </Typography>
            </td>
            <td className="w-[60%] px-[16px]">
              <Typography>
                {inputMonthlyCost.moneytree_average_months >= 12
                  ? `過去${
                      inputMonthlyCost.moneytree_average_months / 12
                    }年分の平均値を使用`
                  : `過去${inputMonthlyCost.moneytree_average_months}カ月分の平均値を使用`}
              </Typography>
            </td>
          </tr>
        )}
        <tr className="h-[45px] odd:bg-black-100">
          <td className="w-[40%] px-[16px]">
            <Typography>食費</Typography>
          </td>
          <td className="w-[60%] px-[16px]">
            <Typography>{`${convertLocaleString(
              inputMonthlyCost.food_expenses_monthly,
            )}円/月`}</Typography>
          </td>
        </tr>

        <tr className="h-[45px] odd:bg-black-100">
          <td className="w-[40%] px-[16px]">
            <Typography>水道光熱費</Typography>
          </td>
          <td className="w-[60%] px-[16px]">
            <Typography>{`${convertLocaleString(
              inputMonthlyCost.utility_bills_monthly,
            )}円/月`}</Typography>
          </td>
        </tr>

        <tr className="h-[45px] odd:bg-black-100">
          <td className="w-[40%] px-[16px]">
            <Typography>日用品雑貨</Typography>
          </td>
          <td className="w-[60%] px-[16px]">
            <Typography>{`${convertLocaleString(
              inputMonthlyCost.daily_necessities_cost_monthly,
            )}円/月`}</Typography>
          </td>
        </tr>

        <tr className="h-[45px] odd:bg-black-100">
          <td className="w-[40%] px-[16px]">
            <Typography>交通費</Typography>
          </td>
          <td className="w-[60%] px-[16px]">
            <Typography>{`${convertLocaleString(
              inputMonthlyCost.transport_monthly,
            )}円/月`}</Typography>
          </td>
        </tr>

        <tr className="h-[45px] odd:bg-black-100">
          <td className="w-[40%] px-[16px]">
            <Typography>被服費</Typography>
          </td>
          <td className="w-[60%] px-[16px]">
            <Typography>{`${convertLocaleString(
              inputMonthlyCost.clothing_cost_monthly,
            )}円/月`}</Typography>
          </td>
        </tr>

        <tr className="h-[45px] odd:bg-black-100">
          <td className="w-[40%] px-[16px]">
            <Typography>趣味・娯楽費</Typography>
          </td>
          <td className="w-[60%] px-[16px]">
            <Typography>{`${convertLocaleString(
              inputMonthlyCost.hobby_expenses_monthly,
            )}円/月`}</Typography>
          </td>
        </tr>

        <tr className="h-[45px] odd:bg-black-100">
          <td className="w-[40%] px-[16px]">
            <Typography>通信費</Typography>
          </td>
          <td className="w-[60%] px-[16px]">
            <Typography>{`${convertLocaleString(
              inputMonthlyCost.communication_cost_monthly,
            )}円/月`}</Typography>
          </td>
        </tr>

        <tr className="h-[45px] odd:bg-black-100">
          <td className="w-[40%] px-[16px]">
            <Typography>交際費</Typography>
          </td>
          <td className="w-[60%] px-[16px]">
            <Typography>{`${convertLocaleString(
              inputMonthlyCost.entertainment_expenses_monthly,
            )}円/月`}</Typography>
          </td>
        </tr>

        <tr className="h-[45px] odd:bg-black-100">
          <td className="w-[40%] px-[16px]">
            <Typography>医療費</Typography>
          </td>
          <td className="w-[60%] px-[16px]">
            <Typography>{`${convertLocaleString(
              inputMonthlyCost.medical_expenses_monthly,
            )}円/月`}</Typography>
          </td>
        </tr>

        <tr className="h-[45px] odd:bg-black-100">
          <td className="w-[40%] px-[16px]">
            <Typography>こづかい</Typography>
          </td>
          <td className="w-[60%] px-[16px]">
            <Typography>{`${convertLocaleString(
              inputMonthlyCost.pocket_money_monthly,
            )}円/月`}</Typography>
          </td>
        </tr>

        {inputMonthlyCost.input_scholarships &&
          inputMonthlyCost.input_scholarships?.map((item: any, index: number) => (
            <tr
              className="h-[45px] odd:bg-black-100"
              key={`monthly_cost_scholarships_${item.id}_${index}`}
            >
              <td className="w-[40%] px-[16px]">
                <Typography>{index === 0 ? '奨学金' : ''}</Typography>
              </td>
              <td className="w-[60%] px-[16px]">
                <Typography>
                  {`${convertDateToTwoTypesYearsDateFormat(
                    item.scholarship_finish_at,
                    'yearMonthWithJapaneseYear',
                  )}まで`}
                  <br /> {`${convertLocaleString(item.scholarship_monthly)}円/月`}
                </Typography>
              </td>
            </tr>
          ))}

        {inputMonthlyCost.input_monthly_cost_others &&
          inputMonthlyCost.input_monthly_cost_others?.map((item: any, index: number) => (
            <tr
              className="h-[45px] odd:bg-black-100"
              key={`monthly_cost_others_${item.id}_${index}`}
            >
              <td className="w-[40%] px-[16px]">
                <Typography>{defaultToEmptyString(item.journal_name)}</Typography>
              </td>
              <td className="w-[60%] px-[16px]">
                <Typography>{`${convertLocaleString(
                  item.cost_monthly,
                )}円/月`}</Typography>
              </td>
            </tr>
          ))}

        <tr className="h-[45px] odd:bg-black-100">
          <td className="w-[40%] px-[16px]">
            <Typography>未分類・使途不明金</Typography>
          </td>
          <td className="w-[60%] px-[16px]">
            <Typography>{`${convertLocaleString(
              inputMonthlyCost.unknown_cost_monthly,
            )}円/月`}</Typography>
          </td>
        </tr>
      </tbody>
    </table>
  ) : (
    <Typography className="text-[14px] md:text-[16px] text-center" isBold>
      基本生活費が登録されていません。
    </Typography>
  )
}

interface InputMonthlyCostBodyProps {
  /** 更新時の処理 */
  onUpdate: () => void
}

const InputMonthlyCostBody: FC<InputMonthlyCostBodyProps> = (props) => {
  const { onUpdate } = props
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const closeModal = () => setIsOpenModal(false)
  const openModal = () => setIsOpenModal(true)

  const { inputMonthlyCost } = useSelector((state: RootState) => state.inputMonthlyCosts)
  const inputedMonthlyCost = inputMonthlyCost != null

  return (
    <>
      <SimulationAccordion className="pb-[16px]" title="現在の基本生活費">
        {inputedMonthlyCost && (
          <div className="flex justify-end mb-[8px]">
            <Button onClick={openModal}>
              <i className="ico edit text-[14px] text-black-700" />
              <Typography className="pl-[5px] text-[14px] text-black-700">
                編集
              </Typography>
            </Button>
          </div>
        )}
        <MonthlyCostTable />
        {!inputedMonthlyCost && <SimulationNewButton onClick={openModal} />}
      </SimulationAccordion>
      <Modal className="lg:w-[1024px] w-[95%]" isOpen={isOpenModal} onClose={closeModal}>
        <CircleTitle className="pb-[32px]" title="現在の基本生活費" />
        <MyDataMonthlyCostForm
          onClose={closeModal}
          onSubmit={() => {
            closeModal()
            onUpdate()
          }}
        />
      </Modal>
    </>
  )
}

export default InputMonthlyCostBody
