import ApiClient from '@/api/apiClient'
import { LearningPlatformUser } from '@/models/admins/learningPlatformUsersModel'

export const getLearningPlatformUsers = async () => {
  return await ApiClient.get(
    '/learning_platform_users',
    (response: LearningPlatformUser[]) => response,
    undefined,
    false,
    'v2',
  )
}

export const getCompanyLearningPlatformUsers = async (values: { company_id: number }) => {
  return await ApiClient.get(
    `/companies/${values.company_id}/learning_platform_users`,
    (response: LearningPlatformUser[]) => response,
    undefined,
    false,
    'v2',
  )
}
