import React, { FC } from 'react'
import clsx from 'clsx'
import { isUndefined as _isUndefined } from 'lodash'
import Typography from '@/components/v2/atoms/Typography'
import { PartiallyPartial, TableColumnData } from '@/models/commonsModel'

interface StripedTableProps {
  /** タイトル */
  className?: string
  /** データ */
  tableData: { [key: number]: any }
  /** カラム */
  columnData: PartiallyPartial<TableColumnData, 'index' | 'nested_columns'>[]
  /** フォーマッター */
  formatter?: (value: any, key: string) => void
}

const StripedTable: FC<StripedTableProps> = (props) => {
  const { className, tableData, columnData, formatter } = props

  return (
    <table
      className={clsx(className, 'block border-separate overflow-x-scroll pb-40')}
      style={{ borderSpacing: 'initial' }}
    >
      <tbody>
        <tr>
          <th className="sticky left-0 max-w-[130px] min-w-[130px] h-[50px] z-[40] bg-white border-b-[2px] border-r-[2px] border-black-400" />
          {Object.keys(tableData).map((label, index) => (
            <th
              key={index}
              className="max-w-[100px] min-w-[100px] h-[50px] bg-white odd:bg-black-100 border-b-[2px] border-black-400"
            >
              <Typography className="text-[14px] md:text-[18px]" isBold>
                {label}
              </Typography>
            </th>
          ))}
        </tr>

        {columnData.map((item, index) => (
          <tr key={index}>
            <td className="sticky left-0 max-w-[130px] min-w-[130px] h-[50px] z-[40] bg-white border-b border-r-[2px] border-black-400">
              <Typography className="text-center text-[14px] md:text-[18px]" isBold>
                {item.display_name}
              </Typography>
            </td>
            {Object.keys(tableData)?.map((label, index) => (
              <td
                key={index}
                className="max-w-[100px] min-w-[100px] h-[50px] bg-white odd:bg-black-100 border-b border-black-400"
              >
                <Typography className="text-center" isBold>
                  {!_isUndefined(formatter)
                    ? formatter(tableData[Number(label)][item.key], item.key)
                    : tableData[Number(label)][item.key]}
                </Typography>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default StripedTable
