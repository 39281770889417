import React, { FC, useEffect, useContext } from 'react'
import Typography from '@/components/v2/atoms/Typography'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import MyDataListWrapper from '@/templates/v2/myData/_ListWrapper'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/store'
import {
  destroyInputSalary,
  fetchInputSalaries,
  fetchYearlyIncomeTotal,
} from '@/containers/inputs/inputSalariesSlice'
import { fetchInputHousings } from '@/containers/inputs/inputHousingsSlice'
import { fetchFamilyStructureV2 } from '@/containers/familyStructuresSlice'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import Button from '@/components/v2/atoms/Button'
import {
  convertEmployeeSize,
  convertIndustry,
  convertInsurancePensionType,
  convertPersonalBusinessAoiroDeduction,
  convertPersonalBusinessTaxRate,
  convertProfession,
} from '@/models/inputs/inputSalariesModelFunc'
import {
  API_FLAG,
  castNumber,
  combineTwoTypesYear,
  convertDateToTwoTypesYearsDateFormat,
  convertLocaleString,
  convertYenToManyen,
  defaultToEmptyString,
  extractYearOrMonthFromDate,
  isNotEmptyValue,
} from '@/models/commonsModelFunc'
import {
  find as _find,
  isNull as _isNull,
  isUndefined as _isUndefined,
  includes as _includes,
  isEmpty as _isEmpty,
} from 'lodash'
import { PERSON_FLG, PROFESSION } from '@/models/familyStructuresModelFunc'
import { InputBonus } from '@/models/inputs/inputBonusesModel'
import { setPageMeta } from '@/containers/pageMetasSlice'
import { fetchInputIncomes } from '@/containers/inputs/inputIncomesSlice'
import { fetchCompanySalaryBaseV2 } from '@/containers/admins/companySalaryBasesSlice'
import {
  CHANGE_TYPE,
  convertChangeType,
  convertReEmploymentType,
  renderIncomeItemLabel,
} from '@/models/commonIncomeChangesModelFunc'
import {
  convertRateSettingType,
  CURRENT_SALARY_INCREASE_RATE_SETTING_TYPE,
} from '@/models/commonIncomeBasesModelFunc'
import {
  destroyInputCorporatePension,
  fetchInputCorporatePensions,
} from '@/containers/inputs/inputCorporatePensionsSlice'
import {
  convertPensionType,
  PENSION_TYPE,
} from '@/models/inputs/inputCorporatePensionsModelFunc'
import {
  CONTRIBUTION_TYPE,
  convertAverageYieldPercent,
  convertReceivingPensionType,
  MATCHING_CONTRIBUTION_TYPE,
  RECEIVING_PENSION_TYPE,
} from '@/models/inputs/inputContributionPensionsModelFunc'
import { fetchAccount } from '@/containers/accountsSlice'
import {
  destroyInputContributionPension,
  fetchInputContributionPensions,
} from '@/containers/inputs/inputContributionPensionsSlice'
import {
  destroyInputPublicPension,
  fetchInputPublicPensionsV2,
} from '@/containers/inputs/inputPublicPensionsSlice'
import { INPUT_TYPE } from '@/models/inputs/inputPublicPensionsModelFunc'
import { INPUT_SUB_INCOME_TYPE } from '@/models/inputs/inputSubIncomesModelFunc'
import { destroyInputIncome } from '@/containers/inputs/inputIncomesSlice'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import { destroyInputSubIncome } from '@/containers/inputs/inputSubIncomesSlice'
import { useParams } from 'react-router-dom'
import { AccountContext } from '@/models/accountsModelFunc'
import { useAccountControl } from '@/hooks/useAccountControl'
import moment from 'moment'

const MyDataPerson: FC = () => {
  const { showFlashMsg } = useFlashAlert()
  const dispatch = useDispatch()
  const { handleMovePage } = useCustomHistory()
  const { isAdmin } = useContext(AccountContext)
  const { isMyDataManipulatedApproval } = useAccountControl()
  const { account_id } = useParams<{ account_id?: string }>()

  const { familyStructures } = useSelector((state: RootState) => state.familyStructures)
  const { inputSalaries, yearlyIncomeTotal } = useSelector(
    (state: RootState) => state.inputSalaries,
  )
  const { inputIncomeBases } = useSelector((state: RootState) => state.inputIncomes)
  const { companySalaryBase } = useSelector(
    (state: RootState) => state.companySalaryBases,
  )
  const { inputCorporatePensions } = useSelector(
    (state: RootState) => state.inputCorporatePensions,
  )
  const { inputContributionPensions } = useSelector(
    (state: RootState) => state.inputContributionPensions,
  )
  const { inputPublicPensions } = useSelector(
    (state: RootState) => state.inputPublicPensions,
  )

  const isCompanySalary = !_isNull(companySalaryBase)

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'マイデータ | ご本人',
        description: 'マイデータ | ご本人',
        keyword: 'マイデータ | ご本人',
      }),
    )
    if (inputSalaries.length == 0) dispatch(fetchInputSalaries())
    // dispatch(fetchInputHousings())
    if (!familyStructures) dispatch(fetchFamilyStructureV2())
    if (inputIncomeBases.length == 0) dispatch(fetchInputIncomes())
    dispatch(fetchCompanySalaryBaseV2())
    if (inputCorporatePensions.length == 0) dispatch(fetchInputCorporatePensions())
    if (inputContributionPensions.length == 0) dispatch(fetchInputContributionPensions())
    if (inputPublicPensions.length == 0) dispatch(fetchInputPublicPensionsV2())
    if (!yearlyIncomeTotal) dispatch(fetchYearlyIncomeTotal())
  }, [])

  const notEmployedGroup = [PROFESSION.student, PROFESSION.housewife, PROFESSION.other]
  const NotBelongToCompanyGroup = [
    PROFESSION.freelance,
    PROFESSION.student,
    PROFESSION.other,
    PROFESSION.housewife,
  ]

  const formatDate = (updatedAt?: string) =>
    updatedAt != null ? `(最終更新日: ${moment(updatedAt).format('YYYY/MM/DD')})` : ''

  const renderIncomeTable = (isPerson: boolean) => {
    const personFlg = isPerson ? PERSON_FLG.person : PERSON_FLG.spouse
    const targetInputIncome = _find(
      inputIncomeBases,
      (item) => item.person_flg === personFlg,
    )
    const targetInputSalary = _find(
      inputSalaries,
      (item) => item.person_flg === personFlg,
    )
    const retirementAgeItem = _find(
      targetInputIncome?.input_income_changes,
      (item) => item.change_type === CHANGE_TYPE.retirementAge,
    )

    const handleDestroy = () => {
      const result = confirm('本当に削除してよろしいですか？')
      if (!result) return

      const destroySalaryFunc = new Promise((resolve) => {
        if (!isCompanySalary) return resolve(undefined)

        const targetSalary = _find(inputSalaries, (item) => item.person_flg === personFlg)
        const newValues = { params: { id: targetSalary!.id } }
        dispatch(destroyInputSalary(newValues, () => resolve('')))
      })

      const destroySubIncomeFunc = new Promise((resolve) => {
        const newValues = { params: { id: targetInputIncome!.id } }
        dispatch(destroyInputIncome(newValues, () => resolve('')))
      })

      Promise.all([destroySalaryFunc, destroySubIncomeFunc]).then(() => {
        dispatch(fetchInputIncomes())
        dispatch(fetchAccount())
        dispatch(fetchYearlyIncomeTotal())
        isCompanySalary && dispatch(fetchInputSalaries())
        showFlashMsg('削除しました')
      })
    }

    return (
      <div className="mb-[60px] last:mb-0">
        <div className="sm:flex block justify-between">
          <div className="flex flex-col md:flex-row gap-x-[8px] gap-y-[4px] items-baseline">
            <CircleTitle
              title={`${isPerson ? 'ご本人' : '配偶者'}の${
                isCompanySalary ? '収入' : '収入の変化'
              }`}
              classNameTitle="text-[20px] md:text-[24px]"
            />
            <Typography className="text-[13px] md:text-[16px] text-[#9E9E9E]">
              {formatDate(targetInputIncome?.updated_at)}
            </Typography>
          </div>
          <div className="flex justify-end my-[10px]">
            {!_isUndefined(targetInputIncome) && (
              <Button
                className="w-[84px] md:w-[112px] h-[26px] md:h-[37px] mr-[10px] rounded-[4px] bg-white border border-secondary-500 hover:bg-secondary-50"
                isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
                onClick={() =>
                  handleMovePage(
                    isAdmin
                      ? `/v2/accounts/${account_id}/myData/incomes/${
                          isPerson ? 'person' : 'spouse'
                        }/edit`
                      : `/v2/myData/incomes/${isPerson ? 'person' : 'spouse'}/edit`,
                  )
                }
              >
                <Typography
                  className="relative bottom-[1px] text-[12px] md:text-[14px] text-secondary-500"
                  isBold
                >
                  編集する
                </Typography>
              </Button>
            )}

            {!_isUndefined(targetInputIncome) && (
              <Button
                className="w-[84px] md:w-[112px] h-[26px] md:h-[37px] mr-0 md:mr-[9px] rounded-[4px] bg-white border border-black-600 hover:bg-secondary-50"
                isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
                onClick={() => handleDestroy()}
              >
                <Typography
                  className="relative bottom-[1px] text-[12px] md:text-[14px] text-black-600"
                  isBold
                >
                  削除する
                </Typography>
              </Button>
            )}
          </div>
        </div>

        {_isUndefined(targetInputIncome) && (
          <div className="md:pt-[20px]">
            <Typography
              className="pb-[15px] md:pb-[24px] text-[15px] md:text-[18px] text-center"
              isBold
            >
              {`${
                personFlg == 0 ? 'ご本人' : '配偶者'
              }の収入の変化が登録されていません。`}
              <br className="lg:hidden" />
              下記のボタンから登録してみましょう。
            </Typography>
            <Button
              className="w-[104px] md:w-[112px] h-[26px] md:h-[37px] rounded-[4px] mx-auto bg-white border border-secondary-500 hover:bg-secondary-50"
              isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
              onClick={() =>
                handleMovePage(
                  isAdmin
                    ? `/v2/accounts/${account_id}/myData/incomes/${
                        isPerson ? 'person' : 'spouse'
                      }/edit`
                    : `/v2/myData/incomes/${isPerson ? 'person' : 'spouse'}/edit`,
                )
              }
            >
              <Typography
                className="relative bottom-[1px] text-[12px] md:text-[14px] text-secondary-500"
                isBold
              >
                新しく追加する
              </Typography>
            </Button>
          </div>
        )}

        {!_isUndefined(targetInputIncome) && (
          <table className="w-[100%] text-[12px] md:text-[14px]">
            <tbody>
              {!isCompanySalary && (
                <tr className="h-[45px] odd:bg-black-100">
                  <td className="w-[30%] px-[16px]">
                    <Typography isBold>現在のお仕事</Typography>
                  </td>
                  <td className="px-[16px]">
                    <div className="p-10" >
                      <Typography className="pb-[5px] text-[11px]">
                        { `収入上昇率 `}
                        {
                          targetInputIncome?.current_salary_increase_rate_setting_type == CURRENT_SALARY_INCREASE_RATE_SETTING_TYPE.input ?
                            `${defaultToEmptyString(targetInputIncome?.current_salary_increase_rate_percent )}%` :
                            `${convertRateSettingType(targetInputIncome?.current_salary_increase_rate_setting_type )}`
                        }
                      </Typography>
                      { Number(targetInputIncome?.severance_pay_manyen) > 0 && (
                        <Typography className="pb-[5px] text-[11px]">
                          {`退職金 ${targetInputIncome?.severance_pay_manyen}万円`}
                        </Typography>
                      )}
                    </div>
                  </td>
                </tr>
              )}

              {isCompanySalary && (
                <tr className="h-[45px] odd:bg-black-100">
                  <td className="w-[30%] px-[16px] md:px-[30px]">
                    <Typography isBold>収入の変化</Typography>
                  </td>
                  <td className="w-[70%] px-[16px] md:px-[30px]">
                    {targetInputSalary?.input_salary_company_base?.input_salary_company_changes?.map(
                      (item) => (
                        <Typography key={item.id}>{`${defaultToEmptyString(
                          item.scheduled_at_age,
                        )}歳 ${
                          item.is_managerial_retired
                            ? '役職定年'
                            : item.company_salary_grade_name
                        } 年収${convertYenToManyen(
                          item.initial_salary_yearly,
                        )}万円`}</Typography>
                      ),
                    )}
                    {targetInputIncome?.input_income_changes?.map((item) => (
                      <Typography key={item.id}>{`${defaultToEmptyString(
                        item.scheduled_at_age,
                      )}歳 ${convertChangeType(item.change_type)} ${renderIncomeItemLabel(
                        item,
                      )}`}</Typography>
                    ))}
                  </td>
                </tr>
              )}

              {targetInputIncome?.input_income_changes?.map((change: any) => (
                <React.Fragment key={change.id}>
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="px-[16px]">
                      <Typography isBold>
                        {`${defaultToEmptyString( change.scheduled_at_age,)}歳の時`}
                      </Typography>
                    </td>
                    <td className="px-[16px]">
                      {Number(change.change_type) === CHANGE_TYPE.employment && (
                        <div className="p-10" >
                          <Typography className="pb-[5px] text-[11px]">
                            {convertChangeType(change.change_type)}
                          </Typography>
                          <Typography className="pb-[5px] text-[11px]">
                            {`変更後の年収 ${convertLocaleString(
                              Number(change.change_type) === CHANGE_TYPE.employment
                                ? change.estimated_annual_income_manyen
                                : change.retirement_officers_amount_manyen,
                            )}万円`}
                          </Typography>
                          <Typography className="pb-[5px] text-[11px]">
                            {`変更後の社会保険 ${ convertInsurancePensionType( change.insurance_pension_type || null )}`}
                          </Typography>
                          <Typography className="pb-[5px] text-[11px]">
                            {`変更後の収入上昇率 `}
                            {
                              change.current_salary_increase_rate_setting_type == CURRENT_SALARY_INCREASE_RATE_SETTING_TYPE.input ?
                                `${defaultToEmptyString(change.current_salary_increase_rate_percent )}%` :
                                `${convertRateSettingType(change.current_salary_increase_rate_setting_type )}`
                            }
                          </Typography>
                          { change.current_salary_increase_rate_setting_type === CURRENT_SALARY_INCREASE_RATE_SETTING_TYPE.average && (
                            <Typography className="pb-[5px] text-[11px]">
                              {`変更後の業種 `}
                              {convertIndustry(change.industry || null)}
                            </Typography>
                          )}
                          { Number(change.severance_pay_manyen) > 0 && (
                            <Typography className="pb-[5px] text-[11px]">
                              {`退職金 ${change.severance_pay_manyen}万円`}
                            </Typography>
                          )}
                        </div>
                      )}

                      {Number(change.change_type) === CHANGE_TYPE.retirementOfficer && (
                        <div className="p-10" >
                          <Typography className="pb-[5px] text-[11px]">
                            {convertChangeType(change.change_type)}
                          </Typography>
                          <Typography className="pb-[5px] text-[11px]">
                            {`変更後の年収　${convertLocaleString(
                              Number(change.change_type) === CHANGE_TYPE.employment
                                ? change.estimated_annual_income_manyen
                                : change.retirement_officers_amount_manyen,
                            )}万円`}
                          </Typography>
                          <Typography className="pb-[5px] text-[11px]">
                            {`変更後の収入上昇率 `}
                            {
                              change.current_salary_increase_rate_setting_type == CURRENT_SALARY_INCREASE_RATE_SETTING_TYPE.input ?
                                `${defaultToEmptyString(change.current_salary_increase_rate_percent )}%` :
                                `${convertRateSettingType(change.current_salary_increase_rate_setting_type )}`
                            }
                          </Typography>
                          { Number(change.severance_pay_manyen) > 0 && (
                            <Typography className="pb-[5px] text-[11px]">
                              {`退職金 ${change.severance_pay_manyen}万円`}
                            </Typography>
                          )}
                        </div>
                      )}

                      {Number(change.change_type) === CHANGE_TYPE.retirement && (
                        <div className="p-10" >
                          <Typography className="pb-[5px] text-[11px]">
                            {convertChangeType(change.change_type)}
                          </Typography>
                          <Typography className="pb-[5px] text-[11px]">
                            {`変更後の社会保険 ${ convertInsurancePensionType( change.insurance_pension_type || null )}`}
                          </Typography>
                        </div>
                      )}

                      {Number(change.change_type) === CHANGE_TYPE.retirementAge && (
                        <div className="p-10" >
                          <Typography className="pb-[5px] text-[11px]">
                            {convertChangeType(change.change_type)}
                          </Typography>
                          { change.re_employment_type == 0 ? (
                            <>
                              <Typography className="pb-[5px] text-[11px]">
                                再就職 する
                              </Typography>
                              <Typography className="pb-[5px] text-[11px]">
                                再就職後の年収 {`${change.re_employment_annual_income_manyen} 万円`}
                              </Typography>
                            </>
                          ) : (
                            <Typography className="pb-[5px] text-[11px]">
                              再就職 しない
                            </Typography>
                          )}
                        </div>
                      )}
                    </td>
                  </tr>

                  {change.change_type == 2 && change.re_employment_type == 0 && (
                    <>
                      <tr className="h-[45px] odd:bg-black-100">
                        <td className="px-[16px]">
                          <Typography isBold>
                            {`${defaultToEmptyString( change.re_employment_finish_at_age,)}歳の時`}
                          </Typography>
                        </td>
                        <td className="px-[16px]">
                          <div className="p-10" >
                            <Typography className="pb-[5px] text-[11px]">
                              退職
                            </Typography>
                          </div>
                        </td>
                      </tr>
                    </>
                  )}
                </React.Fragment>
              ))}

            </tbody>
          </table>
        )}
      </div>
    )
  }

  const renderCorporatePensionTable = (isPerson: boolean) => {
    const personFlg = isPerson ? PERSON_FLG.person : PERSON_FLG.spouse
    const targetCorporatePensions = _find(
      inputCorporatePensions,
      (item: any) => item.person_flg === personFlg,
    )

    const handleDestroy = () => {
      const result = confirm('本当に削除してよろしいですか？')
      if (!result) return

      const destroySubIncomeFunc = new Promise((resolve) => {
        const newValues = { params: { id: targetCorporatePensions!.id } }
        dispatch(destroyInputCorporatePension(newValues, () => resolve('')))
      })

      Promise.all([destroySubIncomeFunc]).then(() => {
        dispatch(fetchInputCorporatePensions())
        showFlashMsg('削除しました')
      })
    }

    return (
      <div className="mb-[60px] last:mb-0">
        <div className="sm:flex block justify-between">
          <div className="flex flex-col md:flex-row gap-x-[8px] gap-y-[4px] items-baseline">
            <CircleTitle
              title={`${isPerson ? 'ご本人' : '配偶者'}の企業年金`}
              classNameTitle="text-[20px] md:text-[24px]"
            />
            <Typography className="text-[13px] md:text-[16px] text-[#9E9E9E]">
              {formatDate(targetCorporatePensions?.updated_at)}
            </Typography>
          </div>
          <div className="flex justify-end my-[10px]">
            {!_isUndefined(targetCorporatePensions) && (
              <Button
                className="w-[84px] md:w-[112px] h-[26px] md:h-[37px] mr-[10px] rounded-[4px] bg-white border border-secondary-500 hover:bg-secondary-50"
                isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
                onClick={() =>
                  handleMovePage(
                    isAdmin
                      ? `/v2/accounts/${account_id}/myData/corporatePensions/${
                          isPerson ? 'person' : 'spouse'
                        }/edit`
                      : `/v2/myData/corporatePensions/${
                          isPerson ? 'person' : 'spouse'
                        }/edit`,
                  )
                }
              >
                <Typography
                  className="relative bottom-[1px] text-[12px] md:text-[14px] text-secondary-500"
                  isBold
                >
                  編集する
                </Typography>
              </Button>
            )}

            {!_isUndefined(targetCorporatePensions) && (
              <Button
                className="w-[84px] md:w-[112px] h-[26px] md:h-[37px] mr-0 md:mr-[9px] rounded-[4px] bg-white border border-black-600 hover:bg-secondary-50"
                isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
                onClick={() => handleDestroy()}
              >
                <Typography
                  className="relative bottom-[1px] text-[12px] md:text-[14px] text-black-600"
                  isBold
                >
                  削除する
                </Typography>
              </Button>
            )}
          </div>
        </div>

        {_isUndefined(targetCorporatePensions) && (
          <div className="md:pt-[20px]">
            <Typography
              className="pb-[15px] md:pb-[24px] text-[15px] md:text-[18px] text-center"
              isBold
            >
              {`${personFlg == 0 ? 'ご本人' : '配偶者'}の企業年金が登録されていません。`}
              <br className="md:hidden" />
              下記のボタンから登録してみましょう。
            </Typography>
            <Button
              className="w-[104px] md:w-[112px] h-[26px] md:h-[37px] rounded-[4px] mx-auto bg-white border border-secondary-500 hover:bg-secondary-50"
              isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
              onClick={() =>
                handleMovePage(
                  isAdmin
                    ? `/v2/accounts/${account_id}/myData/corporatePensions/${
                        isPerson ? 'person' : 'spouse'
                      }/edit`
                    : `/v2/myData/corporatePensions/${
                        isPerson ? 'person' : 'spouse'
                      }/edit`,
                )
              }
            >
              <Typography
                className="relative bottom-[1px] text-[12px] md:text-[14px] text-secondary-500"
                isBold
              >
                新しく追加する
              </Typography>
            </Button>
          </div>
        )}

        {!_isUndefined(targetCorporatePensions) && (
          <table className="w-[100%] text-[12px] md:text-[14px]">
            <tbody>
              <tr className="h-[45px] odd:bg-black-100">
                <td className="w-[30%] px-[16px] md:px-[30px]">
                  <Typography isBold>企業年金名</Typography>
                </td>
                <td className="w-[70%] px-[16px] md:px-[30px]">
                  <Typography>
                    {defaultToEmptyString(targetCorporatePensions?.name)}
                  </Typography>
                </td>
              </tr>
              <tr className="h-[45px] odd:bg-black-100">
                <td className="px-[16px] md:px-[30px]">
                  <Typography isBold>年金タイプ</Typography>
                </td>
                <td className="px-[16px] md:px-[30px]">
                  <Typography>
                    {convertPensionType(targetCorporatePensions?.pension_type)}
                  </Typography>
                </td>
              </tr>
              {targetCorporatePensions?.pension_type === PENSION_TYPE.confirm && (
                <tr className="h-[45px] odd:bg-black-100">
                  <td className="px-[16px] md:px-[30px]">
                    <Typography isBold>受取期間</Typography>
                  </td>
                  <td className="px-[16px] md:px-[30px]">
                    <Typography>
                      {`${defaultToEmptyString(
                        targetCorporatePensions?.receipt_period,
                      )}年`}
                    </Typography>
                  </td>
                </tr>
              )}
              <tr className="h-[45px] odd:bg-black-100">
                <td className="px-[16px] md:px-[30px]">
                  <Typography isBold>受取年金額</Typography>
                </td>
                <td className="px-[16px] md:px-[30px]">
                  <Typography>{`${defaultToEmptyString(
                    targetCorporatePensions?.start_receiving_year_at_age,
                  )}歳から${convertLocaleString(
                    targetCorporatePensions?.receiving_pension_amount_manyen,
                  )}万円`}</Typography>
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
    )
  }

  const renderCompanyContributionPensionTable = (isPerson: boolean) => {
    const personFlg = isPerson ? PERSON_FLG.person : PERSON_FLG.spouse
    const targetContributionPension = _find(
      inputContributionPensions,
      (item) =>
        item.person_flg === personFlg &&
        item.contribution_type === CONTRIBUTION_TYPE.corporate,
    )

    const handleDestroy = () => {
      const result = confirm('本当に削除してよろしいですか？')
      if (!result) return

      const destroySubIncomeFunc = new Promise((resolve) => {
        dispatch(
          destroyInputContributionPension(targetContributionPension!.id, () =>
            resolve(''),
          ),
        )
      })

      Promise.all([destroySubIncomeFunc]).then(() => {
        dispatch(fetchInputContributionPensions())
        showFlashMsg('削除しました')
      })
    }

    return (
      <div className="mb-[60px] last:mb-0">
        <div className="sm:flex block justify-between">
          <div className="flex flex-col md:flex-row gap-x-[8px] gap-y-[4px] items-baseline">
            <CircleTitle
              title={`${isPerson ? 'ご本人' : '配偶者'}の企業型確定拠出年金`}
              classNameTitle="text-[20px] md:text-[24px]"
            />
            <Typography className="text-[13px] md:text-[16px] text-[#9E9E9E]">
              {formatDate(targetContributionPension?.updated_at)}
            </Typography>
          </div>
          <div className="flex justify-end my-[10px]">
            {!_isUndefined(targetContributionPension) && (
              <Button
                className="w-[84px] md:w-[112px] h-[26px] md:h-[37px] mr-[10px] rounded-[4px] bg-white border border-secondary-500 hover:bg-secondary-50"
                isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
                onClick={() =>
                  handleMovePage(
                    isAdmin
                      ? `/v2/accounts/${account_id}/myData/companyContributionPensions/${
                          isPerson ? 'person' : 'spouse'
                        }/edit`
                      : `/v2/myData/companyContributionPensions/${
                          isPerson ? 'person' : 'spouse'
                        }/edit`,
                  )
                }
              >
                <Typography
                  className="relative bottom-[1px] text-[12px] md:text-[14px] text-secondary-500"
                  isBold
                >
                  編集する
                </Typography>
              </Button>
            )}

            {!_isUndefined(targetContributionPension) && (
              <Button
                className="w-[84px] md:w-[112px] h-[26px] md:h-[37px] mr-0 md:mr-[9px] rounded-[4px] bg-white border border-black-600 hover:bg-secondary-50"
                isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
                onClick={() => handleDestroy()}
              >
                <Typography
                  className="relative bottom-[1px] text-[12px] md:text-[14px] text-black-600"
                  isBold
                >
                  削除する
                </Typography>
              </Button>
            )}
          </div>
        </div>

        {_isUndefined(targetContributionPension) && (
          <div className="md:pt-[20px]">
            <Typography
              className="pb-[15px] md:pb-[24px] text-[15px] md:text-[18px] text-center"
              isBold
            >
              {`${
                personFlg == 0 ? 'ご本人' : '配偶者'
              }の企業型確定拠出年金が登録されていません。`}
              <br className="lg:hidden" />
              下記のボタンから登録してみましょう。
            </Typography>
            <Button
              className="w-[104px] md:w-[112px] h-[26px] md:h-[37px] rounded-[4px] mx-auto bg-white border border-secondary-500 hover:bg-secondary-50"
              isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
              onClick={() =>
                handleMovePage(
                  isAdmin
                    ? `/v2/accounts/${account_id}/myData/companyContributionPensions/${
                        isPerson ? 'person' : 'spouse'
                      }/edit`
                    : `/v2/myData/companyContributionPensions/${
                        isPerson ? 'person' : 'spouse'
                      }/edit`,
                )
              }
            >
              <Typography
                className="relative bottom-[1px] text-[12px] md:text-[14px] text-secondary-500"
                isBold
              >
                新しく追加する
              </Typography>
            </Button>
          </div>
        )}

        {!_isUndefined(targetContributionPension) && (
          <table className="w-[100%] mb-[60px] text-[12px] md:text-[14px]">
            <tbody>
              <tr className="h-[45px] odd:bg-black-100">
                <td className="w-[30%] px-[16px] md:px-[30px]">
                  <Typography isBold>支払い期間</Typography>
                </td>
                <td className="w-[70%] px-[16px] md:px-[30px]">
                  <Typography>{`${defaultToEmptyString(
                    targetContributionPension?.contribution_period_start_at_age,
                  )}歳から${defaultToEmptyString(
                    targetContributionPension?.contribution_period_end_at_age,
                  )}歳未満`}</Typography>
                </td>
              </tr>

              <tr className="h-[45px] odd:bg-black-100">
                <td className="px-[16px] md:px-[30px]">
                  <Typography isBold>拠出額</Typography>
                </td>
                <td className="px-[16px] md:px-[30px]">
                  <Typography>{`${convertLocaleString(
                    targetContributionPension?.contribution_amount_monthly,
                  )}円/月`}</Typography>
                </td>
              </tr>

              {targetContributionPension.matching_contribution_type ===
                MATCHING_CONTRIBUTION_TYPE.matching && (
                <tr className="h-[45px] odd:bg-black-100">
                  <td className="px-[16px] md:px-[30px]">
                    <Typography isBold>マッチング拠出期間</Typography>
                  </td>
                  <td className="px-[16px] md:px-[30px]">
                    <Typography>{`${defaultToEmptyString(
                      targetContributionPension.matching_contribution_start_at_age,
                    )}歳から${defaultToEmptyString(
                      targetContributionPension.matching_contribution_end_at_age,
                    )}歳未満`}</Typography>
                  </td>
                </tr>
              )}

              {targetContributionPension.matching_contribution_type ===
                MATCHING_CONTRIBUTION_TYPE.matching && (
                <tr className="h-[45px] odd:bg-black-100">
                  <td className="px-[16px] md:px-[30px]">
                    <Typography isBold>マッチング拠出額</Typography>
                  </td>
                  <td className="px-[16px] md:px-[30px]">
                    <Typography>{`${convertLocaleString(
                      targetContributionPension.matching_contribution_amount_monthly,
                    )}円/月`}</Typography>
                  </td>
                </tr>
              )}

              <tr className="h-[45px] odd:bg-black-100">
                <td className="px-[16px] md:px-[30px]">
                  <Typography isBold>評価額</Typography>
                </td>
                <td className="px-[16px] md:px-[30px]">
                  <Typography>{`${convertLocaleString(
                    targetContributionPension?.valuation,
                  )}円`}</Typography>
                </td>
              </tr>

              <tr className="h-[45px] odd:bg-black-100">
                <td className="px-[16px] md:px-[30px]">
                  <Typography isBold>平均利回り</Typography>
                </td>
                <td className="px-[16px] md:px-[30px]">
                  <Typography>{`${convertAverageYieldPercent(
                    targetContributionPension?.average_yield_percent,
                  )}％`}</Typography>
                </td>
              </tr>

              <tr className="h-[45px] odd:bg-black-100">
                <td className="px-[16px] md:px-[30px]">
                  <Typography isBold>受取方式</Typography>
                </td>
                <td className="px-[16px] md:px-[30px]">
                  <Typography>
                    {convertReceivingPensionType(
                      targetContributionPension?.receiving_pension_type,
                    )}
                  </Typography>
                </td>
              </tr>

              {[RECEIVING_PENSION_TYPE.both].includes(
                targetContributionPension?.receiving_pension_type,
              ) && (
                <tr className="h-[45px] odd:bg-black-100">
                  <td className="px-[16px] md:px-[30px]">
                    <Typography isBold>
                      一時金の
                      <br className="block md:hidden" />
                      受取割合
                    </Typography>
                  </td>
                  <td className="px-[16px] md:px-[30px]">
                    <Typography>
                      {`${defaultToEmptyString(
                        targetContributionPension?.lump_sum_ratio_percent,
                      )}%`}
                    </Typography>
                  </td>
                </tr>
              )}

              {[RECEIVING_PENSION_TYPE.both, RECEIVING_PENSION_TYPE.lump].includes(
                targetContributionPension?.receiving_pension_type,
              ) && (
                <tr className="h-[45px] odd:bg-black-100">
                  <td className="px-[16px] md:px-[30px]">
                    <Typography isBold>受取年齢</Typography>
                  </td>
                  <td className="px-[16px] md:px-[30px]">
                    <Typography>
                      {`${defaultToEmptyString(
                        targetContributionPension?.lump_sum_receiving_at_age,
                      )}歳のとき`}
                    </Typography>
                  </td>
                </tr>
              )}

              {[RECEIVING_PENSION_TYPE.both, RECEIVING_PENSION_TYPE.pension].includes(
                targetContributionPension?.receiving_pension_type,
              ) && (
                <tr className="h-[45px] odd:bg-black-100">
                  <td className="px-[16px] md:px-[30px]">
                    <Typography isBold>
                      受取開始
                      <br className="block md:hidden" />
                      年齢
                    </Typography>
                  </td>
                  <td className="px-[16px] md:px-[30px]">
                    <Typography>
                      {`${defaultToEmptyString(
                        targetContributionPension?.pension_start_at_age,
                      )}歳から${defaultToEmptyString(
                        targetContributionPension?.pension_years,
                      )}年間`}
                    </Typography>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
    )
  }

  const renderPersonContributionPensionTable = (isPerson: boolean) => {
    const personFlg = isPerson ? PERSON_FLG.person : PERSON_FLG.spouse
    const targetContributionPension = _find(
      inputContributionPensions,
      (item) =>
        item.person_flg === personFlg &&
        item.contribution_type === CONTRIBUTION_TYPE.ideco,
    )

    const handleDestroy = () => {
      const result = confirm('本当に削除してよろしいですか？')
      if (!result) return

      const destroySubIncomeFunc = new Promise((resolve) => {
        dispatch(
          destroyInputContributionPension(targetContributionPension!.id, () =>
            resolve(''),
          ),
        )
      })

      Promise.all([destroySubIncomeFunc]).then(() => {
        dispatch(fetchInputContributionPensions())
        showFlashMsg('削除しました')
      })
    }

    return (
      <div className="mb-[60px] last:mb-0">
        <div className="sm:flex block justify-between">
          <div className="flex flex-col md:flex-row gap-x-[8px] gap-y-[4px] items-baseline">
            <CircleTitle
              title={`${isPerson ? 'ご本人' : '配偶者'}の個人型確定拠出年金`}
              classNameTitle="text-[20px] md:text-[24px]"
            />
            <Typography className="text-[13px] md:text-[16px] text-[#9E9E9E]">
              {formatDate(targetContributionPension?.updated_at)}
            </Typography>
          </div>
          <div className="flex justify-end my-[10px]">
            {!_isUndefined(targetContributionPension) && (
              <Button
                className="w-[84px] md:w-[112px] h-[26px] md:h-[37px] mr-[10px] rounded-[4px] bg-white border border-secondary-500 hover:bg-secondary-50"
                isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
                onClick={() =>
                  handleMovePage(
                    isAdmin
                      ? `/v2/accounts/${account_id}/myData/personContributionPensions/${
                          isPerson ? 'person' : 'spouse'
                        }/edit`
                      : `/v2/myData/personContributionPensions/${
                          isPerson ? 'person' : 'spouse'
                        }/edit`,
                  )
                }
              >
                <Typography
                  className="relative bottom-[1px] text-[12px] md:text-[14px] text-secondary-500"
                  isBold
                >
                  編集する
                </Typography>
              </Button>
            )}

            {!_isUndefined(targetContributionPension) && (
              <Button
                className="w-[84px] md:w-[112px] h-[26px] md:h-[37px] mr-0 md:mr-[9px] rounded-[4px] bg-white border border-black-600 hover:bg-secondary-50"
                isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
                onClick={() => handleDestroy()}
              >
                <Typography
                  className="relative bottom-[1px] text-[12px] md:text-[14px] text-black-600"
                  isBold
                >
                  削除する
                </Typography>
              </Button>
            )}
          </div>
        </div>

        {_isUndefined(targetContributionPension) && (
          <div className="md:pt-[20px]">
            <Typography
              className="pb-[15px] md:pb-[24px] text-[15px] md:text-[18px] text-center"
              isBold
            >
              {`${
                personFlg == 0 ? 'ご本人' : '配偶者'
              }の個人型確定拠出年金が登録されていません。`}
              <br className="lg:hidden" />
              下記のボタンから登録してみましょう。
            </Typography>
            <Button
              className="w-[104px] md:w-[112px] h-[26px] md:h-[37px] rounded-[4px] mx-auto bg-white border border-secondary-500 hover:bg-secondary-50"
              isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
              onClick={() =>
                handleMovePage(
                  isAdmin
                    ? `/v2/accounts/${account_id}/myData/personContributionPensions/${
                        isPerson ? 'person' : 'spouse'
                      }/edit`
                    : `/v2/myData/personContributionPensions/${
                        isPerson ? 'person' : 'spouse'
                      }/edit`,
                )
              }
            >
              <Typography
                className="relative bottom-[1px] text-[12px] md:text-[14px] text-secondary-500"
                isBold
              >
                新しく追加する
              </Typography>
            </Button>
          </div>
        )}

        {!_isUndefined(targetContributionPension) && (
          <table className="w-[100%] mb-[60px] text-[12px] md:text-[14px]">
            <tbody>
              <tr className="h-[45px] odd:bg-black-100">
                <td className="w-[30%] px-[16px] md:px-[30px]">
                  <Typography isBold>支払い期間</Typography>
                </td>
                <td className="w-[70%] px-[16px] md:px-[30px]">
                  <Typography>{`${defaultToEmptyString(
                    targetContributionPension?.contribution_period_start_at_age,
                  )}歳から${defaultToEmptyString(
                    targetContributionPension?.contribution_period_end_at_age,
                  )}歳未満`}</Typography>
                </td>
              </tr>
              <tr className="h-[45px] odd:bg-black-100">
                <td className="px-[16px] md:px-[30px]">
                  <Typography isBold>拠出額</Typography>
                </td>
                <td className="px-[16px] md:px-[30px]">
                  <Typography>{`${convertLocaleString(
                    targetContributionPension?.contribution_amount_monthly,
                  )}円/月`}</Typography>
                </td>
              </tr>
              <tr className="h-[45px] odd:bg-black-100">
                <td className="px-[16px] md:px-[30px]">
                  <Typography isBold>評価額</Typography>
                </td>
                <td className="px-[16px] md:px-[30px]">
                  <Typography>{`${convertLocaleString(
                    targetContributionPension?.valuation,
                  )}円`}</Typography>
                </td>
              </tr>
              <tr className="h-[45px] odd:bg-black-100">
                <td className="px-[16px] md:px-[30px]">
                  <Typography isBold>平均利回り</Typography>
                </td>
                <td className="px-[16px] md:px-[30px]">
                  <Typography>{`${convertAverageYieldPercent(
                    targetContributionPension?.average_yield_percent,
                  )}％`}</Typography>
                </td>
              </tr>
              <tr className="h-[45px] odd:bg-black-100">
                <td className="px-[16px] md:px-[30px]">
                  <Typography isBold>受取方式</Typography>
                </td>
                <td className="px-[16px] md:px-[30px]">
                  <Typography>
                    {convertReceivingPensionType(
                      targetContributionPension?.receiving_pension_type,
                    )}
                  </Typography>
                </td>
              </tr>

              {[RECEIVING_PENSION_TYPE.both].includes(
                targetContributionPension?.receiving_pension_type,
              ) && (
                <tr className="h-[45px] odd:bg-black-100">
                  <td className="px-[16px] md:px-[30px]">
                    <Typography isBold>
                      一時金の
                      <br className="block md:hidden" />
                      受取割合
                    </Typography>
                  </td>
                  <td className="px-[16px] md:px-[30px]">
                    <Typography>
                      {`${defaultToEmptyString(
                        targetContributionPension?.lump_sum_ratio_percent,
                      )}%`}
                    </Typography>
                  </td>
                </tr>
              )}

              {[RECEIVING_PENSION_TYPE.both, RECEIVING_PENSION_TYPE.lump].includes(
                targetContributionPension?.receiving_pension_type,
              ) && (
                <tr className="h-[45px] odd:bg-black-100">
                  <td className="px-[16px] md:px-[30px]">
                    <Typography isBold>受取年齢</Typography>
                  </td>
                  <td className="px-[16px] md:px-[30px]">
                    <Typography>
                      {`${defaultToEmptyString(
                        targetContributionPension?.lump_sum_receiving_at_age,
                      )}歳のとき`}
                    </Typography>
                  </td>
                </tr>
              )}

              {[RECEIVING_PENSION_TYPE.both, RECEIVING_PENSION_TYPE.pension].includes(
                targetContributionPension?.receiving_pension_type,
              ) && (
                <tr className="h-[45px] odd:bg-black-100">
                  <td className="px-[16px] md:px-[30px]">
                    <Typography isBold>
                      受取開始
                      <br className="block md:hidden" />
                      年齢
                    </Typography>
                  </td>
                  <td className="px-[16px] md:px-[30px]">
                    <Typography>
                      {`${defaultToEmptyString(
                        targetContributionPension?.pension_start_at_age,
                      )}歳から${defaultToEmptyString(
                        targetContributionPension?.pension_years,
                      )}年間`}
                    </Typography>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
    )
  }

  const renderPublicPensionTable = (isPerson: boolean) => {
    const personFlg = isPerson ? PERSON_FLG.person : PERSON_FLG.spouse
    const targetPublicPension = _find(
      inputPublicPensions,
      (item) => item.person_flg === personFlg,
    )

    const handleDestroy = () => {
      const result = confirm('本当に削除してよろしいですか？')
      if (!result) return

      const destroySubIncomeFunc = new Promise((resolve) => {
        const newValues = { params: { id: targetPublicPension!.id } }
        dispatch(destroyInputPublicPension(newValues, () => resolve('')))
      })

      Promise.all([destroySubIncomeFunc]).then(() => {
        dispatch(fetchInputPublicPensionsV2())
        showFlashMsg('削除しました')
      })
    }

    return (
      <div className="mb-[60px] last:mb-0">
        <div className="sm:flex block justify-between">
          <div className="flex flex-col md:flex-row gap-x-[8px] gap-y-[4px] items-baseline">
            <CircleTitle
              title={`${isPerson ? 'ご本人' : '配偶者'}の公的年金`}
              classNameTitle="text-[20px] md:text-[24px]"
            />
            <Typography className="text-[13px] md:text-[16px] text-[#9E9E9E]">
              {formatDate(targetPublicPension?.updated_at)}
            </Typography>
          </div>
          <div className="flex justify-end my-[10px]">
            {!_isUndefined(targetPublicPension) && (
              <Button
                className="w-[84px] md:w-[112px] h-[26px] md:h-[37px] mr-[10px] rounded-[4px] bg-white border border-secondary-500 hover:bg-secondary-50"
                isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
                onClick={() =>
                  handleMovePage(
                    isAdmin
                      ? `/v2/accounts/${account_id}/myData/publicPensions/edit/${
                          isPerson ? '0' : '1'
                        }`
                      : `/v2/myData/publicPensions/edit/${isPerson ? '0' : '1'}`,
                  )
                }
              >
                <Typography
                  className="relative bottom-[1px] text-[12px] md:text-[14px] text-secondary-500"
                  isBold
                >
                  編集する
                </Typography>
              </Button>
            )}

            {!_isUndefined(targetPublicPension) && (
              <Button
                className="w-[84px] md:w-[112px] h-[26px] md:h-[37px] mr-0 md:mr-[9px] rounded-[4px] bg-white border border-black-600 hover:bg-secondary-50"
                isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
                onClick={() => handleDestroy()}
              >
                <Typography
                  className="relative bottom-[1px] text-[12px] md:text-[14px] text-black-600"
                  isBold
                >
                  削除する
                </Typography>
              </Button>
            )}
          </div>
        </div>

        {_isUndefined(targetPublicPension) && (
          <div className="md:pt-[20px]">
            <Typography
              className="pb-[15px] md:pb-[24px] text-[15px] md:text-[18px] text-center"
              isBold
            >
              {`${personFlg == 0 ? 'ご本人' : '配偶者'}の公的年金が登録されていません。`}
              <br className="md:hidden" />
              下記のボタンから登録してみましょう。
            </Typography>
            <Button
              className="w-[104px] md:w-[112px] h-[26px] md:h-[37px] rounded-[4px] mx-auto bg-white border border-secondary-500 hover:bg-secondary-50"
              isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
              onClick={() =>
                handleMovePage(
                  isAdmin
                    ? `/v2/accounts/${account_id}/myData/publicPensions/edit/${
                        isPerson ? '0' : '1'
                      }`
                    : `/v2/myData/publicPensions/edit/${isPerson ? '0' : '1'}`,
                )
              }
            >
              <Typography
                className="relative bottom-[1px] text-[12px] md:text-[14px] text-secondary-500"
                isBold
              >
                新しく追加する
              </Typography>
            </Button>
          </div>
        )}

        {!_isUndefined(targetPublicPension) && (
          <table className="w-[100%] mb-[60px] text-[12px] md:text-[14px]">
            <tbody>
              <tr className="h-[45px] odd:bg-black-100">
                <td className="w-[50%] px-[16px] md:px-[30px]">
                  <Typography isBold>ねんきん定期便の日付／入力日</Typography>
                </td>
                <td className="w-[50%] px-[16px] md:px-[30px]">
                  {convertDateToTwoTypesYearsDateFormat(
                    targetPublicPension.input_at,
                    'yearMonthWithJapaneseYear',
                  )}
                </td>
              </tr>

              {targetPublicPension.input_type === INPUT_TYPE.overFifty &&
                targetPublicPension.input_public_pension_receivings.map(
                  (item: any, index: number) => (
                    <React.Fragment
                      key={`input_public_pension_receivings_${item.id}_${index}`}
                    >
                      <tr className="h-[45px] odd:bg-black-100">
                        <td className="w-[50%] px-[16px] md:px-[30px]">
                          <Typography isBold>受取開始年齢</Typography>
                        </td>
                        <td className="w-[50%] px-[16px] md:px-[30px]">
                          {`${defaultToEmptyString(item.affected_at_age)}歳〜`}
                        </td>
                      </tr>

                      <tr className="h-[45px] odd:bg-black-100">
                        <td className="w-[50%] px-[16px] md:px-[30px]">
                          <Typography isBold>国民年金</Typography>
                        </td>
                        <td className="w-[50%] px-[16px] md:px-[30px]">
                          {`${convertLocaleString(
                            item.receiving_public_pension_amount,
                          )}円`}
                        </td>
                      </tr>

                      <tr className="h-[45px] odd:bg-black-100">
                        <td className="w-[50%] px-[16px] md:px-[30px]">
                          <Typography isBold>厚生年金</Typography>
                        </td>
                        <td className="w-[50%] px-[16px] md:px-[30px]">
                          {`${convertLocaleString(
                            item.receiving_welfare_pension_amount,
                          )}円`}
                        </td>
                      </tr>
                    </React.Fragment>
                  ),
                )}

              {targetPublicPension.input_type === INPUT_TYPE.other && (
                <tr className="h-[45px] odd:bg-black-100">
                  <td className="w-[50%] px-[16px] md:px-[30px]">
                    <Typography isBold>国民年金の加入期間</Typography>
                  </td>
                  <td className="w-[50%] px-[16px] md:px-[30px]">
                    {`${defaultToEmptyString(
                      castNumber(targetPublicPension.national_pension_paid_months),
                    )}ヶ月`}
                  </td>
                </tr>
              )}

              {targetPublicPension.input_type === INPUT_TYPE.other && (
                <tr className="h-[45px] odd:bg-black-100">
                  <td className="w-[50%] px-[16px] md:px-[30px]">
                    <Typography isBold>
                      厚生年金保険料の保険料納付額（累計額）の合計金額
                    </Typography>
                  </td>
                  <td className="w-[50%] px-[16px] md:px-[30px]">
                    {`${convertLocaleString(
                      targetPublicPension.welfare_pension_paid_amount,
                    )}円`}
                  </td>
                </tr>
              )}

              {targetPublicPension.input_type === INPUT_TYPE.other && (
                <tr className="h-[45px] odd:bg-black-100">
                  <td className="w-[50%] px-[16px] md:px-[30px]">
                    <Typography isBold>厚生年金（共済年金）の加入期間</Typography>
                  </td>
                  <td className="w-[50%] px-[16px] md:px-[30px]">
                    {`${defaultToEmptyString(
                      castNumber(targetPublicPension.welfare_pension_paid_months),
                    )}ヶ月`}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
    )
  }

  const renderSalaryTable = (isPerson: boolean) => {
    const personFlg = isPerson ? PERSON_FLG.person : PERSON_FLG.spouse
    const targetSalary = _find(inputSalaries, (item) => item.person_flg === personFlg)
    const isFreelancer = targetSalary?.profession === PROFESSION.freelance

    const handleDestroy = () => {
      const result = confirm('お仕事を削除すると、副業も削除されますがよろしいですか？')
      if (!result) return

      const destroySalaryFunc = new Promise((resolve) => {
        const newValues = { params: { id: targetSalary!.id } }
        dispatch(destroyInputSalary(newValues, () => resolve('')))
      })

      Promise.all([destroySalaryFunc]).then(() => {
        dispatch(fetchInputSalaries())
        dispatch(fetchYearlyIncomeTotal())
        showFlashMsg('削除しました')
      })
    }

    return (
      <div className="mb-[60px] last:mb-0">
        <div className="sm:flex block justify-between">
          <div className="flex flex-col md:flex-row gap-x-[8px] gap-y-[4px] items-baseline">
            <CircleTitle
              title={`${personFlg == 0 ? 'ご本人' : '配偶者'}のお仕事`}
              classNameTitle="text-[20px] md:text-[24px]"
            />
            <Typography className="text-[13px] md:text-[16px] text-[#9E9E9E]">
              {formatDate(targetSalary?.updated_at)}
            </Typography>
          </div>
          <div className="flex justify-end my-[10px]">
            {targetSalary && (
              <Button
                className="w-[84px] md:w-[112px] h-[26px] md:h-[37px] mr-[10px] rounded-[4px] bg-white border border-secondary-500 hover:bg-secondary-50"
                isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
                onClick={() =>
                  handleMovePage(
                    isAdmin
                      ? `/v2/accounts/${account_id}/myData/salaries/edit/${personFlg}`
                      : `/v2/myData/salaries/edit/${personFlg}`,
                  )
                }
              >
                <Typography
                  className="relative bottom-[1px] text-[12px] md:text-[14px] text-secondary-500"
                  isBold
                >
                  編集する
                </Typography>
              </Button>
            )}

            {targetSalary && (
              <Button
                className="w-[104px] md:w-[112px] h-[26px] md:h-[37px] mr-0 md:mr-[9px] rounded-[4px] bg-white border border-black-600 hover:bg-secondary-50"
                isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
                onClick={() => handleDestroy()}
              >
                <Typography
                  className="relative bottom-[1px] text-[12px] md:text-[14px] text-black-600"
                  isBold
                >
                  削除する
                </Typography>
              </Button>
            )}
          </div>
        </div>

        {_isUndefined(targetSalary) && (
          <div className="md:pt-[20px]">
            <Typography
              className="pb-[15px] md:pb-[24px] text-[15px] md:text-[18px] text-center"
              isBold
            >
              {`${personFlg == 0 ? 'ご本人' : '配偶者'}のお仕事が登録されていません。`}
              <br className="md:hidden" />
              下記のボタンから登録してみましょう。
            </Typography>
            <Button
              className="w-[104px] md:w-[112px] h-[26px] md:h-[37px] rounded-[4px] mx-auto bg-white border border-secondary-500 hover:bg-secondary-50"
              isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
              onClick={() =>
                handleMovePage(
                  isAdmin
                    ? `/v2/accounts/${account_id}/myData/salaries/edit/${personFlg}`
                    : `/v2/myData/salaries/edit/${personFlg}`,
                )
              }
            >
              <Typography
                className="relative bottom-[1px] text-[12px] md:text-[14px] text-secondary-500"
                isBold
              >
                新しく追加する
              </Typography>
            </Button>
          </div>
        )}

        {!_isUndefined(targetSalary) && (
          <table className="w-[100%] text-[12px] md:text-[14px]">
            <tbody>
              <tr className="h-[45px] odd:bg-black-100">
                <td className="w-[30%] px-[16px] md:px-[30px]">
                  <Typography isBold>職業</Typography>
                </td>
                <td className="w-[70%] px-[16px] md:px-[30px]">
                  <Typography>
                    {convertProfession(
                      targetSalary?.profession != 0
                        ? targetSalary?.profession || null
                        : targetSalary?.profession,
                    )}
                  </Typography>
                </td>
              </tr>

              {isFreelancer && (
                <tr className="h-[45px] odd:bg-black-100">
                  <td className="px-[16px] md:px-[30px]">
                    <Typography>加入保険/年金</Typography>
                  </td>
                  <td className="px-[16px] md:px-[30px]">
                    {convertInsurancePensionType(
                      targetSalary?.insurance_pension_type || null,
                    )}
                  </td>
                </tr>
              )}

              {isFreelancer && (
                <tr className="h-[45px] odd:bg-black-100">
                  <td className="px-[16px] md:px-[30px]">
                    <Typography>月商</Typography>
                  </td>
                  <td className="px-[16px] md:px-[30px]">
                    <Typography>{`${convertLocaleString(
                      targetSalary?.input_salary_freelancer?.gross_income_monthly,
                    )}円/月`}</Typography>
                  </td>
                </tr>
              )}

              {isFreelancer && (
                <tr className="h-[45px] odd:bg-black-100">
                  <td className="px-[16px] md:px-[30px]">
                    <Typography>経費</Typography>
                  </td>
                  <td className="px-[16px] md:px-[30px]">
                    <Typography>{`${convertLocaleString(
                      targetSalary?.input_salary_freelancer?.expense_monthly,
                    )}円/月`}</Typography>
                  </td>
                </tr>
              )}

              {isFreelancer && (
                <tr className="h-[45px] odd:bg-black-100">
                  <td className="px-[16px] md:px-[30px]">
                    <Typography>所得</Typography>
                  </td>
                  <td className="px-[16px] md:px-[30px]">
                    <Typography>{`${convertLocaleString(
                      targetSalary?.input_salary_freelancer?.net_income_monthly,
                    )}円/月`}</Typography>
                  </td>
                </tr>
              )}

              {isFreelancer && (
                <tr className="h-[45px] odd:bg-black-100">
                  <td className="px-[16px] md:px-[30px]">
                    <Typography>所得税</Typography>
                  </td>
                  <td className="px-[16px] md:px-[30px]">
                    <Typography>{`${convertLocaleString(
                      targetSalary?.income_tax_monthly,
                    )}円/月`}</Typography>
                  </td>
                </tr>
              )}

              {isFreelancer && (
                <tr className="h-[45px] odd:bg-black-100">
                  <td className="px-[16px] md:px-[30px]">
                    <Typography>住民税</Typography>
                  </td>
                  <td className="px-[16px] md:px-[30px]">
                    <Typography>{`${convertLocaleString(
                      targetSalary?.resident_tax_monthly,
                    )}円/月`}</Typography>
                  </td>
                </tr>
              )}

              {isFreelancer && (
                <tr className="h-[45px] odd:bg-black-100">
                  <td className="px-[16px] md:px-[30px]">
                    <Typography>個人事業税</Typography>
                  </td>
                  <td className="px-[16px] md:px-[30px]">
                    <Typography>
                      {convertPersonalBusinessTaxRate(
                        targetSalary?.input_salary_freelancer?.personal_business_tax_rate,
                      )}
                    </Typography>
                  </td>
                </tr>
              )}

              {isFreelancer && (
                <tr className="h-[45px] odd:bg-black-100">
                  <td className="px-[16px] md:px-[30px]">
                    <Typography>青色申告特別控除</Typography>
                  </td>
                  <td className="px-[16px] md:px-[30px]">
                    <Typography>
                      {convertPersonalBusinessAoiroDeduction(
                        targetSalary?.input_salary_freelancer?.personal_business_aoiro_deduction,
                      )}
                    </Typography>
                  </td>
                </tr>
              )}

              {!_includes(notEmployedGroup, targetSalary?.profession) &&
                !_includes(NotBelongToCompanyGroup, targetSalary?.profession) &&
                !isFreelancer && (
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="px-[16px] md:px-[30px]">
                      <Typography>業種</Typography>
                    </td>
                    <td className="px-[16px] md:px-[30px]">
                      <Typography>
                        {convertIndustry(targetSalary?.industry || null)}
                      </Typography>
                    </td>
                  </tr>
                )}

              {!_includes(notEmployedGroup, targetSalary?.profession) &&
                !_includes(NotBelongToCompanyGroup, targetSalary?.profession) &&
                !isFreelancer && (
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="px-[16px] md:px-[30px]">
                      <Typography>従業員規模</Typography>
                    </td>
                    <td className="px-[16px] md:px-[30px]">
                      <Typography>
                        {convertEmployeeSize(targetSalary?.employee_size || null)}
                      </Typography>
                    </td>
                  </tr>
                )}

              {!_includes(notEmployedGroup, targetSalary?.profession) && !isFreelancer && (
                <tr className="h-[45px] odd:bg-black-100">
                  <td className="px-[16px] md:px-[30px]">
                    <Typography>勤続開始年月</Typography>
                  </td>
                  <td className="px-[16px] md:px-[30px]">
                    <Typography>
                      {`${combineTwoTypesYear(
                        extractYearOrMonthFromDate(
                          targetSalary?.working_start_at,
                          'year',
                        ),
                      )}から`}
                    </Typography>
                  </td>
                </tr>
              )}

              {!_includes(notEmployedGroup, targetSalary?.profession) && !isFreelancer && (
                <tr className="h-[45px] odd:bg-black-100">
                  <td className="px-[16px] md:px-[30px]">
                    <Typography>加入保険/年金</Typography>
                  </td>
                  <td className="px-[16px] md:px-[30px]">
                    <Typography>
                      {convertInsurancePensionType(
                        targetSalary?.insurance_pension_type || null,
                      )}
                    </Typography>
                  </td>
                </tr>
              )}

              {!_includes(notEmployedGroup, targetSalary?.profession) && !isFreelancer && (
                <tr className="h-[45px] odd:bg-black-100">
                  <td className="px-[16px] md:px-[30px]">
                    <Typography>額面月収</Typography>
                  </td>
                  <td className="px-[16px] md:px-[30px]">
                    <Typography>{`${convertLocaleString(
                      targetSalary?.gross_income_monthly,
                    )}円/月`}</Typography>
                  </td>
                </tr>
              )}

              {!_includes(notEmployedGroup, targetSalary?.profession) && !isFreelancer && (
                <tr className="h-[45px] odd:bg-black-100">
                  <td className="px-[16px] md:px-[30px]">
                    <Typography>健康保険料</Typography>
                  </td>
                  <td className="px-[16px] md:px-[30px]">
                    <Typography>{`${convertLocaleString(
                      targetSalary?.health_insurance_monthly,
                    )}円/月`}</Typography>
                  </td>
                </tr>
              )}

              {!_includes(notEmployedGroup, targetSalary?.profession) && !isFreelancer && (
                <tr className="h-[45px] odd:bg-black-100">
                  <td className="px-[16px] md:px-[30px]">
                    <Typography>厚生年金保険料</Typography>
                  </td>
                  <td className="px-[16px] md:px-[30px]">
                    <Typography>{`${convertLocaleString(
                      targetSalary?.welfare_pension_monthly,
                    )}円/月`}</Typography>
                  </td>
                </tr>
              )}

              {!_includes(notEmployedGroup, targetSalary?.profession) && !isFreelancer && (
                <tr className="h-[45px] odd:bg-black-100">
                  <td className="px-[16px] md:px-[30px]">
                    <Typography>雇用保険料</Typography>
                  </td>
                  <td className="px-[16px] md:px-[30px]">
                    <Typography>{`${convertLocaleString(
                      targetSalary?.employment_insurance_monthly,
                    )}円/月`}</Typography>
                  </td>
                </tr>
              )}

              {!_includes(notEmployedGroup, targetSalary?.profession) && !isFreelancer && (
                <tr className="h-[45px] odd:bg-black-100">
                  <td className="px-[16px] md:px-[30px]">
                    <Typography>所得税</Typography>
                  </td>
                  <td className="px-[16px] md:px-[30px]">
                    <Typography>{`${convertLocaleString(
                      targetSalary?.income_tax_monthly,
                    )}円/月`}</Typography>
                  </td>
                </tr>
              )}

              {!_includes(notEmployedGroup, targetSalary?.profession) && !isFreelancer && (
                <tr className="h-[45px] odd:bg-black-100">
                  <td className="px-[16px] md:px-[30px]">
                    <Typography>住民税</Typography>
                  </td>
                  <td className="px-[16px] md:px-[30px]">
                    <Typography>{`${convertLocaleString(
                      targetSalary?.resident_tax_monthly,
                    )}円/月`}</Typography>
                  </td>
                </tr>
              )}

              {!_includes(notEmployedGroup, targetSalary?.profession) &&
                isNotEmptyValue(targetSalary?.insurance_deduction_monthly) &&
                !isFreelancer && (
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="px-[16px] md:px-[30px]">
                      <Typography>控除保険料</Typography>
                    </td>
                    <td className="px-[16px] md:px-[30px]">
                      <Typography>{`${convertLocaleString(
                        targetSalary?.insurance_deduction_monthly,
                      )}円/月`}</Typography>
                    </td>
                  </tr>
                )}

              {!_includes(notEmployedGroup, targetSalary?.profession) &&
                isNotEmptyValue(targetSalary?.mutual_aid_pension_monthly) &&
                !isFreelancer && (
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="px-[16px] md:px-[30px]">
                      <Typography>共済年金控除</Typography>
                    </td>
                    <td className="px-[16px] md:px-[30px]">
                      <Typography>{`${convertLocaleString(
                        targetSalary?.mutual_aid_pension_monthly,
                      )}円/月`}</Typography>
                    </td>
                  </tr>
                )}

              {!_includes(notEmployedGroup, targetSalary?.profession) &&
                isNotEmptyValue(targetSalary?.company_saving_amount_monthly) &&
                !isFreelancer && (
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="px-[16px] md:px-[30px]">
                      <Typography>社内預金・持株・財形などの積立金</Typography>
                    </td>
                    <td className="px-[16px] md:px-[30px]">
                      <Typography>{`${convertLocaleString(
                        targetSalary?.company_saving_amount_monthly,
                      )}円/月`}</Typography>
                    </td>
                  </tr>
                )}

              {!_includes(notEmployedGroup, targetSalary?.profession) &&
                isNotEmptyValue(targetSalary?.housing_deduct_monthly) &&
                !isFreelancer && (
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="px-[16px] md:px-[30px]">
                      <Typography>住宅費控除</Typography>
                    </td>
                    <td className="px-[16px] md:px-[30px]">
                      <Typography>{`${convertLocaleString(
                        targetSalary?.housing_deduct_monthly,
                      )}円/月`}</Typography>
                    </td>
                  </tr>
                )}

              {!_includes(notEmployedGroup, targetSalary?.profession) &&
                isNotEmptyValue(targetSalary?.other_deduction_monthly) &&
                !isFreelancer && (
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="px-[16px] md:px-[30px]">
                      <Typography>その他の控除</Typography>
                    </td>
                    <td className="px-[16px] md:px-[30px]">
                      <Typography>{`${convertLocaleString(
                        targetSalary?.other_deduction_monthly,
                      )}円/月`}</Typography>
                    </td>
                  </tr>
                )}

              {!_includes(notEmployedGroup, targetSalary?.profession) &&
                !_includes(NotBelongToCompanyGroup, targetSalary?.profession) &&
                !isFreelancer && (
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="px-[16px] md:px-[30px]">
                      <Typography>賞与</Typography>
                    </td>
                    <td className="px-[16px] md:px-[30px]">
                      {!_isEmpty(targetSalary?.input_bonuses) &&
                      !_isNull(targetSalary?.input_bonuses) ? (
                        <div>
                          {targetSalary?.input_bonuses.map(
                            (item: InputBonus, index: number) => (
                              <Typography key={index}>
                                {`${item.bonus_month}月 ${convertLocaleString(
                                  item.bonus,
                                )}円`}
                              </Typography>
                            ),
                          )}
                        </div>
                      ) : (
                        <Typography>なし</Typography>
                      )}
                    </td>
                  </tr>
                )}
            </tbody>
          </table>
        )}
      </div>
    )
  }

  const renderSubIncomeTable = (isPerson: boolean): React.ReactNode => {
    const personFlg = isPerson ? PERSON_FLG.person : PERSON_FLG.spouse
    const targetSalary = _find(inputSalaries, (item) => item.person_flg === personFlg)
    const targetInputSubIncome = _find(
      inputSalaries,
      (item) => item.person_flg === personFlg,
    )?.input_sub_income

    const handleDestroy = () => {
      const result = confirm('本当に削除してよろしいですか？')
      if (!result) return

      const destroySubIncomeFunc = new Promise((resolve) => {
        const newValues = { params: { id: targetInputSubIncome!.id } }
        dispatch(destroyInputSubIncome(newValues, () => resolve('')))
      })

      Promise.all([destroySubIncomeFunc]).then(() => {
        dispatch(fetchInputSalaries())
        dispatch(fetchYearlyIncomeTotal())
        showFlashMsg('削除しました')
      })
    }

    return (
      <>
        {!_isUndefined(targetSalary) && (
          <div className="mb-[60px] last:mb-0">
            <div className="sm:flex block justify-between">
              <div className="flex flex-col md:flex-row gap-x-[8px] gap-y-[4px] items-baseline">
                <CircleTitle
                  title={`${isPerson ? 'ご本人' : '配偶者'}の副業`}
                  classNameTitle="text-[20px] md:text-[24px]"
                />
                <Typography className="text-[13px] md:text-[16px] text-[#9E9E9E]">
                  {formatDate(targetInputSubIncome?.updated_at)}
                </Typography>
              </div>
              <div className="flex justify-end my-[10px]">
                {isNotEmptyValue(targetInputSubIncome) && (
                  <Button
                    className="w-[84px] md:w-[112px] h-[26px] md:h-[37px] mr-[10px] rounded-[4px] bg-white border border-secondary-500 hover:bg-secondary-50"
                    isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
                    onClick={() =>
                      handleMovePage(
                        isAdmin
                          ? `/v2/accounts/${account_id}/myData/subIncomes/${
                              isPerson ? 'person' : 'spouse'
                            }/edit`
                          : `/v2/myData/subIncomes/${
                              isPerson ? 'person' : 'spouse'
                            }/edit`,
                      )
                    }
                  >
                    <Typography
                      className="relative bottom-[1px] text-[12px] md:text-[14px] text-secondary-500"
                      isBold
                    >
                      編集する
                    </Typography>
                  </Button>
                )}

                {isNotEmptyValue(targetInputSubIncome) && (
                  <Button
                    className="w-[84px] md:w-[112px] h-[26px] md:h-[37px] mr-0 md:mr-[9px] rounded-[4px] bg-white border border-black-600 hover:bg-secondary-50"
                    isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
                    onClick={() => handleDestroy()}
                  >
                    <Typography
                      className="relative bottom-[1px] text-[12px] md:text-[14px] text-black-600"
                      isBold
                    >
                      削除する
                    </Typography>
                  </Button>
                )}
              </div>
            </div>

            {!isNotEmptyValue(targetInputSubIncome) && (
              <div className="md:pt-[20px]">
                <Typography
                  className="pb-[15px] md:pb-[24px] text-[15px] md:text-[18px] text-center"
                  isBold
                >
                  {`${personFlg == 0 ? 'ご本人' : '配偶者'}の副業が登録されていません。`}
                  <br className="md:hidden" />
                  下記のボタンから登録してみましょう。
                </Typography>
                <Button
                  className="w-[104px] md:w-[112px] h-[26px] md:h-[37px] rounded-[4px] mx-auto bg-white border border-secondary-500 hover:bg-secondary-50"
                  isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
                  onClick={() =>
                    handleMovePage(
                      isAdmin
                        ? `/v2/accounts/${account_id}/myData/subIncomes/${
                            isPerson ? 'person' : 'spouse'
                          }/edit`
                        : `/v2/myData/subIncomes/${isPerson ? 'person' : 'spouse'}/edit`,
                    )
                  }
                >
                  <Typography
                    className="relative bottom-[1px] text-[12px] md:text-[14px] text-secondary-500"
                    isBold
                  >
                    新しく追加する
                  </Typography>
                </Button>
              </div>
            )}

            {isNotEmptyValue(targetInputSubIncome) && (
              <table className="w-[100%] mb-[60px] text-[12px] md:text-[14px]">
                <tbody>
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="w-[30%] px-[16px] md:px-[30px]">
                      <Typography isBold>副業収入</Typography>
                    </td>
                    <td className="w-[70%] px-[16px] md:px-[30px]">
                      <Typography>
                        {targetInputSubIncome?.sub_income_type ===
                        INPUT_SUB_INCOME_TYPE.active
                          ? 'あり'
                          : 'なし'}
                      </Typography>
                    </td>
                  </tr>
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="px-[16px] md:px-[30px]">
                      <Typography isBold>年商</Typography>
                    </td>
                    <td className="px-[16px] md:px-[30px]">
                      <Typography>{`${convertLocaleString(
                        targetInputSubIncome?.sub_income_annual_sales_yearly,
                      )}円`}</Typography>
                    </td>
                  </tr>
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="px-[16px] md:px-[30px]">
                      <Typography isBold>年間経費</Typography>
                    </td>
                    <td className="px-[16px] md:px-[30px]">
                      <Typography>{`${convertLocaleString(
                        targetInputSubIncome?.sub_income_expenses_yearly,
                      )}円`}</Typography>
                    </td>
                  </tr>
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="px-[16px] md:px-[30px]">
                      <Typography isBold>年間所得</Typography>
                    </td>
                    <td className="px-[16px] md:px-[30px]">
                      <Typography>{`${convertLocaleString(
                        targetInputSubIncome?.sub_income_yearly,
                      )}円`}</Typography>
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
        )}
      </>
    )
  }

  const renderYearlyIncomeTotal = () => {
    const { inputSalaries, yearlyIncomeTotal } = useSelector(
      (state: RootState) => state.inputSalaries,
    )

    return inputSalaries.length > 0 ? (
      <div className="mt-30">
        <div className="flex flex-col md:flex-row md:items-center justify-between mb-40 p-16 md:p-24 rounded-4 bg-secondary-50">
          <Typography className="text-18" isBold>
            世帯年収
          </Typography>
          <div className="flex items-baseline ml-auto mr-0">
            <Typography className="text-30" isBold>
              {convertLocaleString(yearlyIncomeTotal)}
            </Typography>
            <Typography className="pl-5 text-16" isBold>
              円
            </Typography>
          </div>
        </div>
      </div>
    ) : null
  }

  return (
    <MyDataListWrapper
      title="お仕事・年金"
      description="給与明細が必要です。ご用意ください。"
    >
      {/* 年収総額表示 */}
      <>{renderYearlyIncomeTotal()}</>

      {/* ご本人 */}
      {!isCompanySalary && (
        <>
          <div className="mt-30">{renderSalaryTable(true)}</div>
          <div className="mt-30">{renderSubIncomeTable(true)}</div>
        </>
      )}
      <div className="mt-30">{renderIncomeTable(true)}</div>
      <div className="mt-30">{renderCorporatePensionTable(true)}</div>
      <div className="mt-30">{renderPersonContributionPensionTable(true)}</div>

      <div className="mt-30">{renderCompanyContributionPensionTable(true)}</div>
      <div className="mt-30">{renderPublicPensionTable(true)}</div>

      {/* 配偶者 */}
      {familyStructures?.spouse_type == 0 && (
        <>
          {!isCompanySalary && (
            <>
              <div className="mt-30">{renderSalaryTable(false)}</div>
              <div className="mt-30">{renderSubIncomeTable(false)}</div>
            </>
          )}
          <div className="mt-30">{renderIncomeTable(false)}</div>
          <div className="mt-30">{renderCorporatePensionTable(false)}</div>
          <div className="mt-30">{renderPersonContributionPensionTable(false)}</div>

          <div className="mt-30">{renderCompanyContributionPensionTable(false)}</div>
          <div className="mt-30">{renderPublicPensionTable(false)}</div>
        </>
      )}
    </MyDataListWrapper>
  )
}

export default MyDataPerson
