import React, { FC, useMemo } from 'react'
import { Form } from 'react-final-form'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import Modal from '@/components/v2/atoms/Modal'
import TextField from '@/components/v2/atoms/TextField'
import Typography from '@/components/v2/atoms/Typography'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import SelectBox from '@/components/v2/molecules/SelectBox'
import { NEW_SEX_RADIO_ITEMS } from '@/constants/formItem/commons'
import { RootState } from '@/store'
import { useDispatch, useSelector } from 'react-redux'
import { FpAppointmentReservationCreateValues } from '@/models/fpAppointmentReservationsModel'
import { createFpAppointmentReservation } from '@/containers/fpAppointmentReservationsSlice'
import { useFlashAlert } from '@/hooks/useFlashAlert'

interface FPModalFormProps {
  /** true: オープン, false: クローズ */
  isOpen: boolean
  /** onCloseメソッド */
  onClose: () => void
}

const FPModalForm: FC<FPModalFormProps> = (props) => {
  const { isOpen, onClose } = props
  const dispatch = useDispatch()
  const { showFlashMsg } = useFlashAlert()

  const { account } = useSelector((state: RootState) => state.accounts)

  const handleSubmit = (values: any) => {
    const pickValues = values
    const newValues = { ...pickValues }

    new Promise((resolve) => {
      dispatch(
        createFpAppointmentReservation(
          newValues as FpAppointmentReservationCreateValues,
          () => {
            showFlashMsg('相談予約を完了しました')
            onClose()
          },
          resolve,
        ),
      )
    })
  }

  const initialValues = useMemo(() => {
    return {
      fp_appointment_reservation: {
        mail_address: account?.mail_address,
        name_sei: account?.name_sei,
        name_mei: account?.name_mei,
        name_sei_kana: account?.name_sei_kana,
        name_mei_kana: account?.name_mei_kana,
        tel_number: account?.tel_number,
        sex: String(account?.sex),
      },
    }
  }, [account])

  return (
    <Modal
      className="w-[95%] md:w-[561px]"
      isDisableBackdropClick
      isOpen={isOpen}
      onClose={onClose}
    >
      <CircleTitle className="pb-[10px]" title="FPにリモート相談予約する" />
      <Typography className="pb-[32px] text-[14px]">
        FPに相談するために必要な情報をご登録いただきます。
        <br />
        すでに登録いただいている情報から作成している項目もございます。
        <br />
        ご確認の上、送信ボタンを押してください。
        <br />
        あなたを担当するFPよりご連絡をさせていただきます。
      </Typography>

      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        render={({ handleSubmit, values, errors }) => (
          <form onSubmit={handleSubmit}>
            <div className="md:flex items-baseline mb-[16px] last:mb-0 pb-[16px] border-black-300 border-dashed border-b">
              <Typography
                className="min-w-[180px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                isBold
              >
                お名前
              </Typography>
              <TextField
                type="text"
                name="fp_appointment_reservation.name_sei"
                isFullWidth
              />
              <TextField
                type="text"
                name="fp_appointment_reservation.name_mei"
                isFullWidth
              />
            </div>

            <div className="md:flex items-baseline mb-[16px] last:mb-0 pb-[16px] border-black-300 border-dashed border-b">
              <Typography
                className="min-w-[180px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                isBold
              >
                ふりがな
              </Typography>
              <TextField
                type="text"
                name="fp_appointment_reservation.name_sei_kana"
                isFullWidth
              />
              <TextField
                type="text"
                name="fp_appointment_reservation.name_mei_kana"
                isFullWidth
              />
            </div>

            <div className="md:flex items-baseline mb-[16px] last:mb-0 pb-[16px] border-black-300 border-dashed border-b">
              <Typography
                className="min-w-[180px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                isBold
              >
                性別
              </Typography>
              <SelectBox
                name="fp_appointment_reservation.sex"
                placeholder="選択する"
                options={NEW_SEX_RADIO_ITEMS}
                isFullWidth
              />
            </div>

            <div className="md:flex items-baseline mb-[16px] last:mb-0 pb-[16px] border-black-300 border-dashed border-b">
              <Typography
                className="min-w-[180px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                isBold
              >
                電話番号（ハイフンなし）
              </Typography>
              <TextField type="text" name="fp_appointment_reservation.tel_number" isFullWidth />
            </div>

            <div className="md:flex items-baseline mb-[16px] last:mb-0 pb-[16px] border-black-300 border-dashed border-b">
              <Typography
                className="min-w-[180px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                isBold
              >
                メールアドレス
              </Typography>
              <TextField
                type="text"
                name="fp_appointment_reservation.mail_address"
                isFullWidth
              />
            </div>

            <ButtonPrimary
              className="w-[188px] h-[45px] mx-auto"
              isDisabled={Object.keys(errors).length > 0}
              onClick={() => handleSubmit(values)}
            >
              <Typography className="text-[14px] text-white" isBold>
                予約する
              </Typography>
            </ButtonPrimary>
          </form>
        )}
      />
    </Modal>
  )
}

export default FPModalForm
