import React, { FC, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import ChevronRightIcon from '@/assets/images/v2/chevron-right.svg'
import Button from '@/components/v2/atoms/Button'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import Grid from '@/components/v2/atoms/Grid'
import TextField from '@/components/v2/atoms/TextField'
import Typography from '@/components/v2/atoms/Typography'
import NumberField from '@/components/v2/molecules/NumberField'
import SelectBox from '@/components/v2/molecules/SelectBox'
import { MONTH_KANJI_SELECT_ITEMS } from '@/constants/formItem/commons'
import { LOAN_FLG_RADIO_ITEMS } from '@/constants/formItem/inputs/inputCars'
import {
  fetchInputCars,
  createInputCar,
  updateInputCar,
} from '@/containers/inputs/inputCarsSlice'
import {
  THIS_YEAR_NUMBER,
  extractYearOrMonthFromDate,
  convertDateMonth,
  castNumberWithoutOperator,
} from '@/models/commonsModelFunc'
import { LOAN_FLG } from '@/models/inputs/inputCarsModelFunc'
import { RootState } from '@/store'
import { buildTwoTypesRangeYearSelectItems } from '@/utils/formItem'
import {
  composeValidators,
  required,
  commaNumberMaxLength,
  zeroOrMoreNumber,
  limitRangeNumber,
} from '@/utils/validate'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import { find as _find } from 'lodash'
import WholeSubmitErrorMessage from '@/components/v2/molecules/WholeSubmitErrorMessage'
import LoadingOverlay from '@/components/v1/atoms/LoadingOverlay'
import NumberFieldDecimal from '@/components/v2/molecules/NumberFieldDecimal'
import clsx from 'clsx'
import SubmitErrorMessage from '@/components/v2/molecules/SubmitErrorMessage'
import { updateResetFlg } from '@/containers/futures/futureBasesSlice'

// ローンの有無: 支払いなし
const LOAN_FLG_NOT_VALUES = {
  loan_amount_monthly: null,
  loan_interest_rate_percent: null,
  loan_bonus_monthly: null,
  loan_bonus_interest_rate_percent: null,
  loan_end_at: null,
}

interface MyDataCarEditFormProps {
  id: number
  onClose: () => void
  onSubmit: () => void
}

const MyDataCarEditForm: FC<MyDataCarEditFormProps> = (props) => {
  const dispatch = useDispatch()

  const { showFlashMsg } = useFlashAlert()
  const { inputCars, isLoading } = useSelector((state: RootState) => state.inputCars)

  const id = props.id

  const targetInputCar = useMemo(() => {
    return _find(inputCars, { id })
  }, [inputCars, id])

  const isEdit = useMemo(() => !!id && !!targetInputCar, [targetInputCar])

  const buildInitialValues = useMemo(
    () =>
      isEdit
        ? {
            input_car: {
              ...targetInputCar,
              stop_riding_year: extractYearOrMonthFromDate(
                targetInputCar?.stop_riding_at,
                'year',
              ),
              stop_riding_month: extractYearOrMonthFromDate(
                targetInputCar?.stop_riding_at,
                'month',
              ),
              loan_period_year: extractYearOrMonthFromDate(
                targetInputCar?.loan_end_at,
                'year',
              ),
              loan_period_month: extractYearOrMonthFromDate(
                targetInputCar?.loan_end_at,
                'month',
              ),
            },
          }
        : {},
    [targetInputCar, isEdit],
  )

  useEffect(() => {
    if (inputCars.length == 0) dispatch(fetchInputCars())
  }, [])

  const submitInputCarFunc = (values: any) => {
    const { loan_flg } = values
    let newValues = {
      ...values,
      stop_riding_at: convertDateMonth(values.stop_riding_year, values.stop_riding_month),
    }

    // ローンの有無: 支払い中
    if (loan_flg === LOAN_FLG.todo) {
      newValues = {
        ...newValues,
        loan_bonus_interest_rate_percent: values.loan_interest_rate_percent,
        loan_end_at: convertDateMonth(values.loan_period_year, values.loan_period_month),
      }
    }

    // ローンの有無: 支払いなし
    if (loan_flg === LOAN_FLG.not) {
      newValues = { ...newValues, ...LOAN_FLG_NOT_VALUES }
    }

    newValues = { id: id, input_car: newValues }

    return isEdit
      ? dispatch(
          updateInputCar(newValues, () => {
            showFlashMsg(isEdit ? '更新しました' : '作成しました')
            dispatch(fetchInputCars())
            dispatch(updateResetFlg())
            props.onSubmit()
          }),
        )
      : dispatch(
          createInputCar(newValues, () => {
            showFlashMsg(isEdit ? '更新しました' : '作成しました')
            dispatch(fetchInputCars())
            dispatch(updateResetFlg())
            props.onSubmit()
          }),
        )
  }

  const handleSubmit = (values: any) => {
    submitInputCarFunc(values.input_car)
  }

  return (
    <>
      <LoadingOverlay isLoading={isLoading} />
      <Form
        onSubmit={handleSubmit}
        initialValues={buildInitialValues}
        mutators={{ ...arrayMutators }}
        render={({
          handleSubmit,
          values,
          errors,
          submitErrors,
          submitFailed,
          form: {
            mutators: { push },
          },
        }) => {
          const loanFlg = values?.input_car?.loan_flg
          return (
            <form onSubmit={handleSubmit}>
              <Grid className="pb-[64px] grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px]">
                <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2">
                  <Typography
                    className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                    isBold
                  >
                    車・バイクなどの
                    <br className="hidden lg:block" />
                    表示用の名前
                  </Typography>
                  <TextField
                    type="text"
                    name={`input_car.car_name`}
                    isFullWidth
                    validate={required}
                  />
                </div>

                <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                  <Typography
                    className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                    isBold
                  >
                    乗車期間
                  </Typography>
                  <div className="flex flex-auto items-baseline">
                    <SelectBox
                      name={`input_car.stop_riding_year`}
                      className="pr-[8px]"
                      placeholder="年を選択"
                      isFullWidth
                      options={buildTwoTypesRangeYearSelectItems(THIS_YEAR_NUMBER, 2100)}
                      validate={required}
                    />
                    <SelectBox
                      name={`input_car.stop_riding_month`}
                      widthClassName="max-w-[100px]"
                      placeholder="月を選択"
                      isFullWidth
                      options={MONTH_KANJI_SELECT_ITEMS}
                      validate={required}
                    />
                    <Typography
                      className="min-w-[fit-content] pl-[8px] text-[14px] text-black-800"
                      isBold
                    >
                      まで
                    </Typography>
                  </div>
                </div>

                <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                  <Typography
                    className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                    isBold
                  >
                    ローンの有無
                  </Typography>
                  <SelectBox
                    name={`input_car.loan_flg`}
                    placeholder="選択する"
                    options={LOAN_FLG_RADIO_ITEMS}
                    isFullWidth
                    validate={required}
                  />
                </div>

                <div className="hidden md:block" />
                {loanFlg === LOAN_FLG.todo && (
                  <>
                    <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                      <Typography
                        className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                        isBold
                      >
                        毎月のローン返済額
                      </Typography>
                      <div className="flex flex-auto items-baseline">
                        <NumberField
                          name={`input_car.loan_amount_monthly`}
                          isFullWidth
                          validate={composeValidators(
                            required,
                            zeroOrMoreNumber,
                            (value: any) => commaNumberMaxLength(value, 7),
                          )}
                        />
                        <Typography
                          className="pl-[8px] text-[14px] text-black-800"
                          isBold
                        >
                          円
                        </Typography>
                      </div>
                    </div>

                    <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                      <Typography
                        className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                        isBold
                      >
                        返済金利
                      </Typography>
                      <div className="flex flex-auto items-baseline">
                        <NumberFieldDecimal
                          name="input_car.loan_interest_rate_percent"
                          isFullWidth
                          validate={composeValidators(required, (value: any) =>
                            limitRangeNumber(castNumberWithoutOperator(value), 0, 100),
                          )}
                        />
                        <Typography
                          className="min-w-[fit-content] pl-[8px] text-[14px] text-black-800"
                          isBold
                        >
                          %
                        </Typography>
                      </div>
                    </div>

                    <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2">
                      <Typography
                        className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                        isBold
                      >
                        ローンのボーナス月の
                        <br className="hidden lg:block" />
                        上乗せ金額
                      </Typography>
                      <div className="flex flex-auto items-baseline">
                        <NumberField
                          name={`input_car.loan_bonus_monthly`}
                          isFullWidth
                          validate={composeValidators((value: any) =>
                            commaNumberMaxLength(value, 7),
                          )}
                        />
                        <Typography
                          className="pl-[8px] text-[14px] text-black-800"
                          isBold
                        >
                          円
                        </Typography>
                      </div>
                    </div>

                    <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                      <Typography
                        className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                        isBold
                      >
                        返済期間
                      </Typography>
                      <div className="flex flex-auto items-baseline">
                        <SelectBox
                          name={`input_car.loan_period_year`}
                          className="pr-[8px]"
                          placeholder="年を選択"
                          isFullWidth
                          options={buildTwoTypesRangeYearSelectItems(
                            THIS_YEAR_NUMBER,
                            THIS_YEAR_NUMBER + 30,
                          )}
                          validate={required}
                        />
                        <SelectBox
                          name={`input_car.loan_period_month`}
                          widthClassName="max-w-[100px]"
                          placeholder="月を選択"
                          isFullWidth
                          options={MONTH_KANJI_SELECT_ITEMS}
                          validate={required}
                        />
                        <Typography
                          className="min-w-[fit-content] pl-[8px] text-[14px] text-black-800"
                          isBold
                        >
                          まで
                        </Typography>
                      </div>
                    </div>
                  </>
                )}

                <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                  <Typography
                    className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                    isBold
                  >
                    車検代
                  </Typography>
                  <div className="flex flex-auto items-baseline">
                    <NumberField
                      name={`input_car.vehicle_inspection_fee_yearly`}
                      isFullWidth
                      validate={composeValidators(
                        required,
                        zeroOrMoreNumber,
                        (value: any) => commaNumberMaxLength(value, 7),
                      )}
                    />
                    <Typography
                      className="pl-[8px] text-[14px] text-black-800 min-w-[fit-content]"
                      isBold
                    >
                      円/年
                    </Typography>
                  </div>
                </div>

                <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                  <Typography
                    className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                    isBold
                  >
                    自動車税
                  </Typography>
                  <div className="flex flex-auto items-baseline">
                    <NumberField
                      name={`input_car.automobile_tax_yearly`}
                      isFullWidth
                      validate={composeValidators(
                        required,
                        zeroOrMoreNumber,
                        (value: any) => commaNumberMaxLength(value, 7),
                      )}
                    />
                    <Typography
                      className="pl-[8px] text-[14px] text-black-800  min-w-[fit-content]"
                      isBold
                    >
                      円/年
                    </Typography>
                  </div>
                </div>

                <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                  <Typography
                    className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                    isBold
                  >
                    保険料
                  </Typography>
                  <div className="flex flex-auto items-baseline">
                    <NumberField
                      name={`input_car.insurance_premium_monthly`}
                      isFullWidth
                      validate={composeValidators(
                        required,
                        zeroOrMoreNumber,
                        (value: any) => commaNumberMaxLength(value, 7),
                      )}
                    />
                    <Typography
                      className="pl-[8px] text-[14px] text-black-800  min-w-[fit-content]"
                      isBold
                    >
                      円/月
                    </Typography>
                  </div>
                </div>

                <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                  <Typography
                    className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                    isBold
                  >
                    ガソリン代
                  </Typography>
                  <div className="flex flex-auto items-baseline">
                    <NumberField
                      name={`input_car.gasoline_fee_monthly`}
                      isFullWidth
                      validate={composeValidators(
                        required,
                        zeroOrMoreNumber,
                        (value: any) => commaNumberMaxLength(value, 7),
                      )}
                    />
                    <Typography
                      className="pl-[8px] text-[14px] text-black-800  min-w-[fit-content]"
                      isBold
                    >
                      円/月
                    </Typography>
                  </div>
                </div>

                <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                  <Typography
                    className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                    isBold
                  >
                    駐車場代
                  </Typography>
                  <div className="flex flex-auto items-baseline">
                    <NumberField
                      name={`input_car.parking_fee_monthly`}
                      isFullWidth
                      validate={composeValidators(
                        required,
                        zeroOrMoreNumber,
                        (value: any) => commaNumberMaxLength(value, 7),
                      )}
                    />
                    <Typography
                      className="pl-[8px] text-[14px] text-black-800  min-w-[fit-content]"
                      isBold
                    >
                      円/月
                    </Typography>
                  </div>
                </div>
              </Grid>

              <WholeSubmitErrorMessage submitErrors={submitErrors} />
              <SubmitErrorMessage show={!submitErrors && submitFailed} />

              <ButtonPrimary
                className={clsx(
                  { ['opacity-50']: Object.keys(errors).length > 0 },
                  'w-[246px] h-[64px] mx-auto mb-[16px] ',
                )}
                isDisabled={isLoading}
                onClick={() => handleSubmit(values)}
              >
                <Typography
                  className="relative bottom-[2px] pl-[30px] text-[16px] text-white flex-auto"
                  isBold
                >
                  保存する
                </Typography>
                <ChevronRightIcon className="mr-[17px]" />
              </ButtonPrimary>
              <Button
                className="mx-auto text-[14px] text-black-700"
                onClick={props.onClose}
                isDisabled={isLoading}
              >
                キャンセル
              </Button>
            </form>
          )
        }}
      />
    </>
  )
}

export default MyDataCarEditForm
