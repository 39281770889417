import React, { FC, useEffect, useMemo } from 'react'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import ChevronRightIcon from '@/assets/images/v2/chevron-right.svg'
import Button from '@/components/v2/atoms/Button'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import Grid from '@/components/v2/atoms/Grid'
import Typography from '@/components/v2/atoms/Typography'
import SelectBox from '@/components/v2/molecules/SelectBox'
import {
  TWO_TYPES_BIRTH_YEAR_SELECT_ITEMS,
  MONTH_KANJI_SELECT_ITEMS,
  NEW_DAY_KANJI_SELECT_ITEMS,
} from '@/constants/formItem/commons'
import { required } from '@/utils/validate'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/store'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import {
  FamilyStructureCommonValues,
  FamilyStructureUpdateValues,
} from '@/models/familyStructuresModel'
import {
  API_FLAG,
  convertDateDay,
  extractDayFromDate,
  extractYearOrMonthFromDate,
} from '@/models/commonsModelFunc'
import { cloneDeep as _cloneDeep, isNull as _isNull } from 'lodash'
import {
  fetchFamilyStructureV2,
  updateFamilyStructure,
} from '@/containers/familyStructuresSlice'
import { validateDate } from '@/models/familyStructuresModelFunc'
import { buildStandardSelectItems } from '@/utils/formItem'
import { FAMILY_TYPE_RADIO_ITEMS } from '@/constants/formItem/familyStructures'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import WholeSubmitErrorMessage from '@/components/v2/molecules/WholeSubmitErrorMessage'
import SubmitErrorMessage from '@/components/v2/molecules/SubmitErrorMessage'
import clsx from 'clsx'
import moment from 'moment'
import { updateResetFlg } from '@/containers/futures/futureBasesSlice'
import LoadingOverlay from '@/components/v1/atoms/LoadingOverlay'

interface MyDataFamilyEditFormProps {
  onClose: () => void
  onSubmit: () => void
}

const MyDataFamilyEditForm: FC<MyDataFamilyEditFormProps> = (props) => {
  const dispatch = useDispatch()

  const { showFlashMsg } = useFlashAlert()
  useEffect(() => {
    if (!familyStructures) dispatch(fetchFamilyStructureV2())
  }, [])

  const { familyStructures, isLoading } = useSelector(
    (state: RootState) => state.familyStructures,
  )

  const hasChildBirthDay = (item: any): boolean =>
    !!item.child_birth_year && !!item.child_birth_month && !!item.child_birth_day

  const handleSubmit = (values: FamilyStructureCommonValues) => {
    const newValues: FamilyStructureCommonValues = {
      family_structure: {
        ..._cloneDeep(values.family_structure),
        birth_day: convertDateDay(
          values.person_birth_year,
          values.person_birth_month,
          values.person_birth_day,
        ),
        spouse_birth_day: convertDateDay(
          values.spouse_birth_year,
          values.spouse_birth_month,
          values.spouse_birth_day,
        ),
        enrollment_day: convertDateDay(
          values.enrollment_year,
          values.enrollment_month,
          values.enrollment_day,
        ),
        family_structure_children_attributes: values.family_structure.family_structure_children_attributes?.map(
          (item, index) => ({
            id: item.id,
            child_index: index + 1,
            sex: item.sex,
            birth_day: convertDateDay(
              item.child_birth_year,
              item.child_birth_month,
              item.child_birth_day,
            ),
          }),
        ),
      },
    }
    const { child_type } = values
    const { spouse_type } = values.family_structure

    // 配偶者有無: いる以外を選択
    if (Number(spouse_type) !== API_FLAG.on) {
      delete newValues.family_structure.spouse_birth_day
      delete newValues.family_structure.enrollment_day
    }

    // お子様有無: いる以外を選択
    if (Number(child_type) !== API_FLAG.on) {
      delete newValues.family_structure.family_structure_children_attributes
    }

    return handleUpdate(newValues)
  }

  const handleUpdate = (values: FamilyStructureUpdateValues) => {
    return new Promise((resolve) =>
      dispatch(
        updateFamilyStructure(
          values,
          () => {
            showFlashMsg('更新しました')
            dispatch(fetchFamilyStructureV2())
            dispatch(updateResetFlg())
            props.onSubmit()
          },
          resolve,
        ),
      ),
    )
  }

  const initialValues = useMemo(() => {
    if (_isNull(familyStructures)) return { family_structure: {} }

    return {
      family_structure: {
        spouse_type: familyStructures.spouse_type,
        family_structure_children_attributes: familyStructures.family_structure_children.map(
          (item) => ({
            id: item.id,
            sex: item.sex,
            child_birth_year: extractYearOrMonthFromDate(item.birth_day, 'year'),
            child_birth_month: extractYearOrMonthFromDate(item.birth_day, 'month'),
            child_birth_day: extractDayFromDate(item.birth_day),
          }),
        ),
      },
      person_birth_year: extractYearOrMonthFromDate(familyStructures.birth_day, 'year'),
      person_birth_month: extractYearOrMonthFromDate(familyStructures.birth_day, 'month'),
      person_birth_day: extractDayFromDate(familyStructures.birth_day),
      spouse_birth_year: extractYearOrMonthFromDate(
        familyStructures.spouse_birth_day,
        'year',
      ),
      spouse_birth_month: extractYearOrMonthFromDate(
        familyStructures.spouse_birth_day,
        'month',
      ),
      spouse_birth_day: extractDayFromDate(familyStructures.spouse_birth_day),
      enrollment_year: extractYearOrMonthFromDate(
        familyStructures.enrollment_day,
        'year',
      ),
      enrollment_month: extractYearOrMonthFromDate(
        familyStructures.enrollment_day,
        'month',
      ),
      enrollment_day: extractDayFromDate(familyStructures.enrollment_day),
      child_type:
        familyStructures.family_structure_children.length > 0
          ? API_FLAG.on
          : API_FLAG.off,
    }
  }, [familyStructures])

  return (
    <>
      <LoadingOverlay isLoading={isLoading} />
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        mutators={{ ...arrayMutators }}
        validate={(values) => validateDate(values)}
        render={({
          handleSubmit,
          values,
          errors,
          submitFailed,
          submitErrors,
          form: {
            change,
            mutators: { push },
          },
        }) => {
          const {
            child_type,
            family_structure,
            person_birth_year,
            person_birth_month,
            person_birth_day,
            spouse_birth_year,
            spouse_birth_month,
            spouse_birth_day,
          } = values
          const { spouse_type } = family_structure
          return (
            <form onSubmit={handleSubmit}>
              <CircleTitle className="pb-[32px]" title="ご本人について" />
              <Grid className="pb-[64px] grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px]">
                <div className="pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                  <Typography
                    className="min-w-[140px] lg:min-w-[164px] pb-[10px] text-[14px] text-black-800"
                    isBold
                  >
                    ご本人の生年月日
                  </Typography>
                  <div className="flex">
                    <div className="md:flex md:justify-end">
                      <SelectBox
                        className="m-[5px] md:max-w-[180px]"
                        isFullWidth
                        name="person_birth_year"
                        isPlaceholderHidden
                        options={buildStandardSelectItems(
                          TWO_TYPES_BIRTH_YEAR_SELECT_ITEMS,
                          '1990',
                          '年を選択',
                        )}
                        validate={required}
                      />
                      <SelectBox
                        className="m-[5px] md:max-w-[70px]"
                        isFullWidth
                        placeholder="月"
                        name="person_birth_month"
                        options={MONTH_KANJI_SELECT_ITEMS}
                        validate={required}
                      />
                      <SelectBox
                        className="m-[5px] md:max-w-[70px]"
                        isFullWidth
                        placeholder="日"
                        name="person_birth_day"
                        options={NEW_DAY_KANJI_SELECT_ITEMS}
                        validate={required}
                      />
                    </div>

                    <div className="flex justify-end items-center">
                      <Typography className="text-black-700 ml-[10px]">
                        {!!person_birth_year && !!person_birth_month && !!person_birth_day
                          ? `現在 ${moment().diff(
                              moment([
                                person_birth_year,
                                person_birth_month - 1,
                                person_birth_day,
                              ]),
                              'years',
                            )} 歳`
                          : '生年月日を入力してください'}
                      </Typography>
                    </div>
                  </div>
                </div>
              </Grid>
              <CircleTitle className="pb-[32px]" title="配偶者について" />
              <Grid className="pb-[64px] grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px]">
                <div className="pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                  <Typography
                    className="min-w-[140px] lg:min-w-[164px] pb-[10px] text-[14px] text-black-800"
                    isBold
                  >
                    配偶者有無
                  </Typography>
                  <div className="md:flex md:justify-end">
                    <SelectBox
                      isFullWidth
                      name="family_structure.spouse_type"
                      options={FAMILY_TYPE_RADIO_ITEMS}
                      validate={required}
                      className="md:max-w-[180px]"
                    />
                  </div>
                </div>

                {spouse_type == String(API_FLAG.on) && (
                  <div className="pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                    <Typography
                      className="min-w-[140px] lg:min-w-[164px] pb-[10px] text-[14px] text-black-800"
                      isBold
                    >
                      配偶者の生年月日
                    </Typography>
                    <div className="flex">
                      <div className="md:flex md:justify-end">
                        <SelectBox
                          isFullWidth
                          className="m-[5px] md:max-w-[180px]"
                          name="spouse_birth_year"
                          isPlaceholderHidden
                          options={buildStandardSelectItems(
                            TWO_TYPES_BIRTH_YEAR_SELECT_ITEMS,
                            '1990',
                            '年を選択',
                          )}
                          validate={required}
                        />
                        <SelectBox
                          isFullWidth
                          className="m-[5px] md:max-w-[70px]"
                          placeholder="月"
                          name="spouse_birth_month"
                          options={MONTH_KANJI_SELECT_ITEMS}
                          validate={required}
                        />
                        <SelectBox
                          isFullWidth
                          className="m-[5px] md:max-w-[70px]"
                          placeholder="日"
                          name="spouse_birth_day"
                          options={NEW_DAY_KANJI_SELECT_ITEMS}
                          validate={required}
                        />
                      </div>

                      <div className="flex justify-end items-center">
                        <Typography className="text-black-700 ml-[10px]">
                          {!!spouse_birth_year &&
                          !!spouse_birth_month &&
                          !!spouse_birth_day
                            ? `現在 ${moment().diff(
                                moment([
                                  spouse_birth_year,
                                  spouse_birth_month - 1,
                                  spouse_birth_day,
                                ]),
                                'years',
                              )} 歳`
                            : '生年月日を入力してください'}
                        </Typography>
                      </div>
                    </div>
                  </div>
                )}

                {spouse_type == API_FLAG.on && (
                  <div className="pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                    <Typography
                      className="min-w-[140px] lg:min-w-[164px] pb-[10px] text-[14px] text-black-800"
                      isBold
                    >
                      入籍日
                    </Typography>
                    <div className="md:flex md:justify-end">
                      <SelectBox
                        isFullWidth
                        className="m-[5px] md:max-w-[180px]"
                        name="enrollment_year"
                        isPlaceholderHidden
                        options={buildStandardSelectItems(
                          TWO_TYPES_BIRTH_YEAR_SELECT_ITEMS,
                          '1990',
                          '年を選択',
                        )}
                        validate={required}
                      />
                      <SelectBox
                        isFullWidth
                        className="m-[5px] md:max-w-[70px]"
                        placeholder="月"
                        name="enrollment_month"
                        options={MONTH_KANJI_SELECT_ITEMS}
                        validate={required}
                      />
                      <SelectBox
                        isFullWidth
                        className="m-[5px] md:max-w-[70px]"
                        placeholder="日"
                        name="enrollment_day"
                        options={NEW_DAY_KANJI_SELECT_ITEMS}
                        validate={required}
                      />
                    </div>
                  </div>
                )}
              </Grid>
              <CircleTitle className="pb-[32px]" title="お子様について" />
              <Grid className="pb-[64px] grid-cols-1 md:grid-cols-6 gap-x-[40px] gap-y-[16px]">
                <div className="pb-[16px] border-black-300 border-dashed border-b-2 items-baseline col-span-4">
                  <Typography
                    className="min-w-[140px] lg:min-w-[164px] pb-[10px] text-[14px] text-black-800"
                    isBold
                  >
                    お子様有無
                  </Typography>
                  <div className="md:flex md:justify-end">
                    <SelectBox
                      isFullWidth
                      name="child_type"
                      options={FAMILY_TYPE_RADIO_ITEMS}
                      validate={required}
                      className="md:max-w-[180px]"
                    />
                  </div>
                </div>
                <div className="col-span-3"></div>
                {child_type == API_FLAG.on && (
                  <>
                    <FieldArray name="family_structure.family_structure_children_attributes">
                      {({ fields }) =>
                        fields.map((name, index) => (
                          <React.Fragment key={index}>
                            <div className="pb-[16px] border-black-300 border-dashed border-b-2 items-baseline col-span-4">
                              <Typography
                                className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                                isBold
                              >
                                {`第${index + 1}子の生年月日`}
                              </Typography>
                              <div className="flex">
                                <div className="md:flex md:justify-end" key={`${index}`}>
                                  <SelectBox
                                    isFullWidth
                                    className="m-[5px] md:max-w-[180px]"
                                    name={`${name}child_birth_year`}
                                    isPlaceholderHidden
                                    options={buildStandardSelectItems(
                                      TWO_TYPES_BIRTH_YEAR_SELECT_ITEMS,
                                      '1990',
                                      '年を選択',
                                    )}
                                    validate={required}
                                  />
                                  <SelectBox
                                    isFullWidth
                                    className="m-[5px] md:max-w-[70px]"
                                    placeholder="月"
                                    name={`${name}child_birth_month`}
                                    options={MONTH_KANJI_SELECT_ITEMS}
                                    validate={required}
                                  />
                                  <SelectBox
                                    isFullWidth
                                    className="m-[5px] md:max-w-[70px]"
                                    placeholder="日"
                                    name={`${name}child_birth_day`}
                                    options={NEW_DAY_KANJI_SELECT_ITEMS}
                                    validate={required}
                                  />
                                </div>

                                <div className="flex justify-end items-center">
                                  <Typography className="text-black-700 ml-[10px]">
                                    {hasChildBirthDay(
                                      values.family_structure
                                        .family_structure_children_attributes[index],
                                    )
                                      ? `現在 ${moment().diff(
                                          moment([
                                            values.family_structure
                                              .family_structure_children_attributes[index]
                                              .child_birth_year,
                                            values.family_structure
                                              .family_structure_children_attributes[index]
                                              .child_birth_month - 1,
                                            values.family_structure
                                              .family_structure_children_attributes[index]
                                              .child_birth_day,
                                          ]),
                                          'years',
                                        )} 歳`
                                      : '生年月日を入力してください'}
                                  </Typography>
                                </div>
                              </div>
                            </div>
                            {fields && Number(fields.length) > 1 ? (
                              <div className="flex items-center justify-end md:justify-start col-span-3 md:col-span-1">
                                <Button
                                  className="border-[0.5px] border-black-700 rounded-[4px] h-[35px]"
                                  onClick={() => {
                                    fields.remove(index)
                                  }}
                                >
                                  <div className="flex items-center">
                                    <Typography
                                      className="min-w-[120p] p-[10px] text-[14px] text-black-800 flex items-center w-[max-content]"
                                      isBold
                                    >
                                      入力枠を削除する
                                    </Typography>
                                  </div>
                                </Button>
                              </div>
                            ) : (
                              <div className="flex items-center justify-end md:justify-start" />
                            )}
                            <div className="md:col-span-1"></div>
                          </React.Fragment>
                        ))
                      }
                    </FieldArray>

                    <Button
                      className="w-full h-[53px] border border-dashed border-primary-500 text-[14px] text-primary-500 hover:bg-primary-50 col-span-4"
                      onClick={() =>
                        push('family_structure.family_structure_children_attributes', {})
                      }
                    >
                      <Typography isBold>お子様の入力枠を追加する</Typography>
                    </Button>
                  </>
                )}
              </Grid>

              <WholeSubmitErrorMessage submitErrors={submitErrors} />
              <SubmitErrorMessage show={!submitErrors && submitFailed} />
              <ButtonPrimary
                className={clsx(
                  { ['opacity-50']: Object.keys(errors).length > 0 },
                  'w-[246px] h-[64px] mx-auto mb-[16px] ',
                )}
                isDisabled={isLoading}
                onClick={() => handleSubmit(values)}
              >
                <Typography
                  className="relative bottom-[2px] pl-[30px] text-[16px] text-white flex-auto"
                  isBold
                >
                  保存する
                </Typography>
                <ChevronRightIcon className="mr-[17px]" />
              </ButtonPrimary>
              <Button
                className="mx-auto text-[14px] text-black-700"
                onClick={() => props.onClose()}
                isDisabled={isLoading}
              >
                キャンセル
              </Button>
            </form>
          )
        }}
      />
    </>
  )
}

export default MyDataFamilyEditForm
