import React, { FC } from 'react'
import { isUndefined as _isUndefined } from 'lodash'
import Typography from '@/components/v2/atoms/Typography'
import Button from '../v2/atoms/Button'
import { fetchAccountFutureItems, fetchAccountShowInput } from '@/containers/accountsSlice'
import { createDetailUnsavedFutureBase } from '@/containers/futures/futureBasesSlice'
import { useDispatch } from 'react-redux'
import ButtonPrimary from '../v2/atoms/ButtonPrimary'

interface ApiEndpointTestProps { }

const ApiEndpointTest: FC<ApiEndpointTestProps> = (props) => {
  const dispatch = useDispatch()

  return (
    <div>
      <Typography className='text-15'>API Endpoint Test</Typography>

      <div className='mt-20'>
        <Typography className='text-12'> show_inputs </Typography>
        <Typography> 目標時間: 3秒以内 </Typography>
        <ButtonPrimary className="h-45 w-full md:w-215 text-white"
          onClick={() => dispatch(fetchAccountShowInput())}
        >
          <Typography className="bottom-2 flex-auto relative text-16" isBold>
            発火させる
          </Typography>
        </ButtonPrimary>
      </div>

      <div className='mt-20'>
        <Typography className='text-12'> show_detail_unsaved </Typography>
        <Typography> 目標時間: 7秒以内 </Typography>
        <ButtonPrimary className="h-45 w-full md:w-215 text-white"
          onClick={() => dispatch(createDetailUnsavedFutureBase({}))}
        >
          <Typography className="bottom-2 flex-auto relative text-16" isBold>
            発火させる
          </Typography>
        </ButtonPrimary>
      </div>

      <div className='mt-20'>
        <Typography className='text-12'> show_future_items </Typography>
        <Typography> 目標時間: 1秒以内 </Typography>
        <ButtonPrimary className="h-45 w-full md:w-215 text-white"
          onClick={() => dispatch(fetchAccountFutureItems())}
        >
          <Typography className="bottom-2 flex-auto relative text-16" isBold>
            発火させる
          </Typography>
        </ButtonPrimary>
      </div>
    </div>
  )
}

export default ApiEndpointTest
