import ApiClient from '@/api/apiClient'
import {
  InputContributionPension,
  InputContributionPensionCreateValues,
  InputContributionPensionUpdateValues,
} from '@/models/inputs/inputContributionPensionsModel'

export const getInputContributionPensions = async () => {
  return await ApiClient.get(
    '/input_contribution_pensions',
    (response: InputContributionPension[]) => response,
    undefined,
    false,
    'v2',
  )
}

export const postInputContributionPension = async (
  values: InputContributionPensionCreateValues,
) => {
  return await ApiClient.post('/input_contribution_pensions', () => {}, values)
}

export const patchInputContributionPension = async (
  id: number,
  values: InputContributionPensionUpdateValues,
) => {
  return await ApiClient.patch(`/input_contribution_pensions/${id}`, () => {}, values)
}

export const deleteInputContributionPension = async (id: number) => {
  return await ApiClient.delete(`/input_contribution_pensions/${id}`, () => {})
}
