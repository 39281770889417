import React, { FC, useEffect, useContext } from 'react'

import { useDispatch } from 'react-redux'
import {
  isEmpty as _isEmpty,
  isNull as _isNull,
  filter as _filter,
  cloneDeep as _cloneDeep,
} from 'lodash'
import FamilyMonochromaticIcon from '@/assets/images/v2/family-monochromatic.svg'
import MarginContainer from '@/components/v2/atoms/MarginContainer'
import Paper from '@/components/v2/atoms/Paper'
import PaperTitle from '@/components/v2/molecules/PaperTitle'
import Typography from '@/components/v2/atoms/Typography'
import Breadcrumb from '@/components/v2/molecules/Breadcrumb'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import { setPageMeta } from '@/containers/pageMetasSlice'
import { AccountContext } from '@/models/accountsModelFunc'
import { useParams } from 'react-router-dom'
import MyDataMonthlyCostForm from './Form'

const MyDataMonthlyCostEdit: FC = () => {
  const dispatch = useDispatch()
  const { handleMovePage } = useCustomHistory()
  const { isAdmin } = useContext(AccountContext)
  const { account_id } = useParams<{ account_id?: string }>()

  const movePageAfterForm = () => {
    handleMovePage(
      isAdmin
        ? `/v2/accounts/${account_id}/myData/monthlyCosts`
        : '/v2/myData/monthlyCosts',
    )
  }

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'マイデータ | 基本生活費 編集',
        description: 'マイデータ | 基本生活費 編集',
        keyword: 'マイデータ | 基本生活費 編集',
      }),
    )
  }, [])

  return (
    <MarginContainer className="px-8 md:px-32 lg:px-0 py-8 md:py-40">
      <Breadcrumb
        className="pb-10 md:pb-32"
        data={[
          {
            name: 'マイデータ',
            url: isAdmin ? `/v2/accounts/${account_id}/myData` : '/v2/myData',
          },
          {
            name: 'マイデータ設定',
            url: isAdmin
              ? `/v2/accounts/${account_id}/myData/monthlyCosts`
              : '/v2/myData/monthlyCosts',
          },
          { name: '基本生活費の設定' },
        ]}
      />

      <PaperTitle
        className="mb-16 md:mb-40"
        title="基本生活費"
        description="基本生活費を設定しましょう"
        icon={<FamilyMonochromaticIcon />}
        smallIcon={
          <FamilyMonochromaticIcon className="h-[82px] w-130" viewBox="0 0 117 154" />
        }
      />

      <Paper className="bg-white p-16 md:p-40">
        <Typography
          className="mb-32 pb-10 text-24 md:text-28 text-black-800 border-b-2 border-black-300"
          isBold
        >
          基本生活費の設定について
        </Typography>

        <MyDataMonthlyCostForm onClose={movePageAfterForm} onSubmit={movePageAfterForm} />
      </Paper>
    </MarginContainer>
  )
}

export default MyDataMonthlyCostEdit
