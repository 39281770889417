import { useDispatch, useSelector } from 'react-redux'
import { isEmpty as _isEmpty } from 'lodash'
import { setFlashAlert, removeFlashAlert } from '@/containers/flashAlertsSlice'
import { RootState } from '@/store'

export const useFlashAlert = () => {
  const dispatch = useDispatch()

  const { flashAlertMessageObjects } = useSelector(
    (state: RootState) => state.flashAlerts,
  )

  const showFlashMsg = (message: string) => {
    if (!_isEmpty(flashAlertMessageObjects)) {
      dispatch(removeFlashAlert(flashAlertMessageObjects.slice(-1)[0].message))
    }

    dispatch(
      setFlashAlert({
        flashAlertMessageObjects: [{ message: message, variant: 'success' }],
      }),
    )
  }

  return { showFlashMsg }
}
