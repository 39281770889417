import React, { FC } from 'react'
import { isUndefined as _isUndefined } from 'lodash'
import CircleIcon from '@/assets/images/v2/circle.svg'
import HelpCircleIcon from '@/assets/images/v2/help-circle.svg'
import Tooltip from '@/components/v2/atoms/Tooltip'
import Typography from '@/components/v2/atoms/Typography'

interface CircleTitleProps {
  /** クラス名 */
  className?: string
  /** タイトル */
  title: string
  /** タイトルクラス */
  classNameTitle?: string
  /** ツールチップクラス名 */
  tooltipClassName?: string
  /** ツールチップアイテム */
  tooltipItem?: string | JSX.Element
}

const CircleTitle: FC<CircleTitleProps> = (props) => {
  const { className, title, classNameTitle, tooltipClassName, tooltipItem } = props

  return (
    <div className={`${className} flex items-center`}>
      <CircleIcon className="min-w-20" />
      <Typography
        className={`bottom-2 relative text-black-800 ${classNameTitle || 'text-24'}`}
        isBold
      >
        <span dangerouslySetInnerHTML={{__html: title}} />
      </Typography>

      {!_isUndefined(tooltipItem) && (
        <Tooltip itemClassName={tooltipClassName} item={tooltipItem}>
          <HelpCircleIcon />
        </Tooltip>
      )}
    </div>
  )
}

export default CircleTitle
