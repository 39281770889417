import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { parse } from 'query-string'
import ApiClient from '@/api/apiClient'
import LogindRedirect from '@/layouts/v2/LogindRedirect'
import MarginContainer from '@/components/v2/atoms/MarginContainer'
import Paper from '@/components/v2/atoms/Paper'
import AccountPasswordForm from '@/templates/v2/accounts/password/commons/AccountPasswordForm'
import { updateAccountPasswordSetup } from '@/containers/accountsSlice'
import { setPageMeta } from '@/containers/pageMetasSlice'
import { AccountPasswordUpdateValues } from '@/models/accountsModel'

const AccountPasswordNew: FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: '会員パスワード登録',
        description: '会員パスワード登録',
        keyword: '会員パスワード登録',
      }),
    )

    const { token, ma } = parse(location.search)

    ApiClient.setAuthHeaders({
      Authorization: `Token token=${token}`,
      MailAddress: ma as string,
    })
  }, [])

  const handleSubmit = (values: AccountPasswordUpdateValues) => {
    return new Promise((resolve) => {
      dispatch(
        updateAccountPasswordSetup(
          values,
          () => {
            history.push('/v2/accounts/password/completed')
          },
          resolve,
        ),
      )
    })
  }

  return (
    <LogindRedirect>
      <MarginContainer className="w-full sm:w-524 py-40">
        <Paper className="mx-8 sm:mx-0 px-16 py-24 sm:p-40 bg-white">
          <AccountPasswordForm title="会員パスワード登録" handleSubmit={handleSubmit} />
        </Paper>
      </MarginContainer>
    </LogindRedirect>
  )
}

export default AccountPasswordNew
