import React, { FC, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  filter as _filter,
  some as _some,
  uniqBy as _uniqBy,
  each as _each,
  find as _find,
  concat as _concat,
  sortBy as _sortBY,
} from 'lodash'
import { parse } from 'query-string'
import Button from '@/components/v2/atoms/Button'
import Typography from '@/components/v2/atoms/Typography'
import { setPageMeta } from '@/containers/pageMetasSlice'
import {
  fetchLearningVideos,
  initializeLearningVideo,
} from '@/containers/videoPlatforms/learningVideosSlice'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import { LearningVideoCategory } from '@/models/admins/learningVideoCategoriesModel'
import { RootState } from '@/store'
import { isSmartPhone } from '@/utils/userAgent'
import MoviesAlertModal from './MoviesAlertModal'
import { LearningVideo } from '@/models/commonLearningVideosModel'
import { learnigVideoSortedByDisplayStatus } from '@/models/admins/learningVideosModelFunc'
import { useLocation } from 'react-router-dom'
import { fetchLearningVideoCompany } from '@/containers/videoPlatforms/learningVideoCompaniesSlice'
import { fetchLearningVideo } from '@/containers/admins/learningVideosSlice'

const MovieList: FC = () => {
  const location = useLocation()
  const isRedirectd = location.search == '?redirected'
  const dispatch = useDispatch()
  const { handleMovePage } = useCustomHistory()
  const [selectedCategoryId, setSelectedCategoryId] = useState<number>(0)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const { learningVideos } = useSelector((state: RootState) => state.videoLearningVideos)
  const { category: categoryName } = parse(location.search)
  const uniqueCategories: LearningVideoCategory[] = useMemo(() => {
    const allCategoriies: LearningVideoCategory[] = []
    _each(learningVideos, (video) => {
      _each(video.learning_video_categories, (category) => {
        allCategoriies.push(category)
      })
    })
    return _sortBY(_uniqBy<LearningVideoCategory>(allCategoriies, 'id'), 'order')
  }, [learningVideos])
  const sortLearningVideos = (targetLearningVideos: LearningVideo[]) => {
    return _concat(
      learnigVideoSortedByDisplayStatus(targetLearningVideos, 'viewable'),
      learnigVideoSortedByDisplayStatus(targetLearningVideos, 'only_list'),
      learnigVideoSortedByDisplayStatus(targetLearningVideos, 'non_demo'),
    )
  }

  const categorizedLearningVideos = useMemo(() => {
    if (selectedCategoryId === 0) {
      const targetVideos: LearningVideo[] = [...learningVideos]
      return sortLearningVideos(targetVideos)
    }

    return sortLearningVideos(
      _filter(learningVideos, (item) =>
        _some(item.learning_video_categories, (item) => item.id === selectedCategoryId),
      ),
    )
  }, [learningVideos, selectedCategoryId])

  const selectedCategoryName = useMemo(() => {
    const category = _find(uniqueCategories, { id: selectedCategoryId })
    return !!category ? category.name : ''
  }, [uniqueCategories, selectedCategoryId])

  useEffect(() => {
    const category = _find(uniqueCategories, { name: String(categoryName) })
    if (!!category) {
      setSelectedCategoryId(category.id)
      return
    }

    setSelectedCategoryId(0)
  }, [categoryName, uniqueCategories])

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: '動画一覧',
        description: '動画一覧',
        keyword: '動画一覧',
      }),
    )
    dispatch(fetchLearningVideos())

    if (isSmartPhone()) {
      const element: any = document.getElementsByTagName('html')[0]
      element.style.zoom = ''
    }
  }, [])

  useEffect(() => {
    setIsModalOpen(isRedirectd)
  }, [])

  const handleClick = (id: number) => {
    handleMovePage(`/movies/${id}/preview`)
    dispatch(initializeLearningVideo())
  }

  return (
    <div className="tailwind">
      <div className="max-w-screen-xl mx-auto py-7 px-8 u-p30 w-full">
        <div className="flex mb-6 overflow-auto whitespace-nowrap mx-15">
          <Button
            className={
              selectedCategoryId === 0
                ? 'bg-yellow-400 text-xs text-white m-1 font-bold py-8 px-24 rounded-full h-34'
                : 'border border-solid border-gray-300 bg-white hover:bg-gray-200 text-xs text-gray-700 m-1 font-bold py-8 px-24 rounded-full h-34'
            }
            onClick={() => setSelectedCategoryId(0)}
          >
            すべて
          </Button>
          {uniqueCategories.map((item: LearningVideoCategory) => (
            <Button
              key={item.id}
              className={
                selectedCategoryId === item.id || selectedCategoryName === item.name
                  ? 'bg-yellow-400 font-bold h-34 m-1 py-8 px-24 rounded-full text-xs text-white'
                  : 'border border-solid border-gray-300 bg-white font-bold hover:bg-gray-200 h-34 m-1 py-8 px-24 rounded-full text-xs text-gray-700'
              }
              onClick={() => setSelectedCategoryId(item.id)}
            >
              {item.name}
            </Button>
          ))}
        </div>

        <div className="grid  grid-cols-3 sm:grid-cols-6 xl:grid-cols-9">
          {categorizedLearningVideos.map((item: LearningVideo) => (
            <div
              key={item.id}
              className="active:bg-gray-200 col-span-3 cursor-pointer mx-15 mt-0 mb-40"
              onClick={() =>
                item.display_status == 'only_list'
                  ? setIsModalOpen(true)
                  : handleClick(item.id)
              }
            >
              <div
                className={`relative w-full ${
                  item.display_status == 'only_list'
                    ? 'bg-[rgba(0,0,0,0.3)] pt-[46%]'
                    : 'pt-56.25'
                }`}
              >
                <img
                  className={`border border-solid border-gray-200 w-full h-full absolute top-0 ${
                    item.display_status == 'only_list' && 'opacity-[20%]'
                  }`}
                  src={item.thumbnail_url}
                  alt="thumbnail"
                />
                {item.display_status == 'only_list' && (
                  <>
                    <Typography className="text-[13px] w-[100px] h-[40px] py-[10px] mx-auto relative bottom-[88px] text-white bg-black-900 text-center">
                      有料会員のみ
                    </Typography>
                  </>
                )}
              </div>

              <Typography className="font-bold my-2.5 overflow-hidden text-18 w-full text-black-900">
                {item.title}
              </Typography>
              <Typography className="text-16 mb-2.5 text-black-800 font-default">
                {item.subtitle}
              </Typography>
              <Typography className="text-12 text-black-700 text-justify font-default">
                {item.description}
              </Typography>
            </div>
          ))}
        </div>

        <MoviesAlertModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      </div>
    </div>
  )
}

export default MovieList
