import { ApiError } from '@/models/commonsModel'
import { createSlice } from '@reduxjs/toolkit'
import {
  postFpAccountInvitation,
  postFpAccountInvitationResend,
} from '../../api/admins/fp_account_invitations'
import { AppThunk } from '../../store'

const fpAccountInvitationsSlice = createSlice({
  name: 'fp_account_invitations',
  initialState: {
    accountInvitations: [],
    isLoading: false,
  },
  reducers: {
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
  },
})

export const {
  requestStart,
  requestSuccess,
  requestFailure,
} = fpAccountInvitationsSlice.actions
export const fpAccountInvitationsReducer = fpAccountInvitationsSlice.reducer

export const createFpAccountInvitation = (
  values: any,
  callback: () => void,
  errorCallback?: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postFpAccountInvitation({ fp_account_invitation: values })
    .then((response) => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      errorCallback && errorCallback(error)
      dispatch(requestFailure())
    })
}

export const createFpAccountInvitationResend = (
  id: number,
  callback: () => void,
  errorCallback?: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postFpAccountInvitationResend(id)
    .then((response) => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      errorCallback && errorCallback(error)
      dispatch(requestFailure())
    })
}
