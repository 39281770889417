import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { sortBy as _sortBy } from 'lodash'
import { unauthorizedCheck } from './accountsSlice'
import {
  getFamilyStructure,
  getFamilyStructureV2,
  patchFamilyStructure,
} from '../api/familyStructures'
import { ApiError } from '../models/commonsModel'
import {
  FamilyStructureResponse,
  FamilyStructure,
  FamilyStructureUpdateValues,
} from '../models/familyStructuresModel'
import { FamilyStructureChild } from '../models/familyStructureChildren'
import { FamilyInfo } from '@/models/futures/futureBasesModel'
import { AppThunk } from '../store'

interface FamilyStructureState {
  hasFamilyStructure: boolean
  familyStructures: FamilyStructure | null
  familyStructureChildren: FamilyStructureChild[]
  familyInfo: FamilyInfo
  familyInfoV2: FamilyStructure | null
  isLoading: boolean
}

const initialState: FamilyStructureState = {
  hasFamilyStructure: false,
  familyStructures: null,
  familyStructureChildren: [],
  familyInfo: {
    person_age: 0,
    spouse_age: 0,
    person_current_age: 0,
    spouse_current_age: null,
    spouse_marrying_age: null,
    is_married: false,
    get_married_at: null,
    enrollment_day: null,
    current_child_count: 0,
    future_child_count: 0,
    total_child_count: 0,
    children_info: [],
  },
  familyInfoV2: null,
  isLoading: false,
}

const familyStructuresSlice = createSlice({
  name: 'familyStructures',
  initialState,
  reducers: {
    setFamilyStructures: (state, action: PayloadAction<FamilyStructure>) => {
      const { payload } = action

      state.familyStructures = payload
      state.familyStructureChildren = _sortBy(
        payload.family_structure_children,
        'child_index',
      )
      return state
    },
    setFamilyInfo: (state, action: PayloadAction<FamilyInfo>) => {
      state.familyInfo = action.payload
      return state
    },
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    resetFamilyStructuresStore: (state) => {
      state = initialState
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    getFamilyStructureSuccess: (
      state,
      action: PayloadAction<FamilyStructureResponse>,
    ) => {
      state.familyStructures = action.payload
      state.familyStructureChildren = _sortBy(
        action.payload.family_structure_children,
        'child_index',
      )
      state.isLoading = false
      return state
    },
    getFamilyStructureV2Success: (
      state,
      action: PayloadAction<FamilyStructureResponse>,
    ) => {
      const { family_structure_children } = action.payload
      const sortedChildren = _sortBy(family_structure_children, 'child_index')
      const familyStructure = {
        ...action.payload,
        family_structure_children: sortedChildren,
      }

      state.familyInfoV2 = familyStructure
      state.familyStructures = familyStructure
      state.familyStructureChildren = sortedChildren
      state.isLoading = false

      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
  },
})

export const {
  setFamilyStructures,
  setFamilyInfo,
  requestStart,
  resetFamilyStructuresStore,
  requestSuccess,
  getFamilyStructureSuccess,
  getFamilyStructureV2Success,
  requestFailure,
} = familyStructuresSlice.actions
export const familyStructuresReducer = familyStructuresSlice.reducer

export const fetchFamilyStructure = (): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  getFamilyStructure()
    .then((response) => dispatch(getFamilyStructureSuccess(response)))
    .catch(() => dispatch(requestFailure()))
}

export const fetchFamilyStructureV2 = (): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  getFamilyStructureV2()
    .then((response) => dispatch(getFamilyStructureV2Success(response)))
    .catch(() => dispatch(requestFailure()))
}

export const updateFamilyStructure = (
  values: FamilyStructureUpdateValues,
  callback: () => void,
  resolve: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchFamilyStructure(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error, resolve))
    })
}
