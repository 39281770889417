import React, { FC } from 'react'
import SimulationCreateItem from '@/assets/images/v2/simulation-g-create-item.png'
import Typography from '@/components/v2/atoms/Typography'
import GuidanceModal from '@/components/v2/organisms/GuidanceModal'

interface SimulationPanelModalProps {
  /** true: オープン, false: クローズ */
  isOpen: boolean
  /** モーダルクローズメソッド */
  onClose: () => void
}

const SimulationPanelModal: FC<SimulationPanelModalProps> = (props) => {
  const { isOpen, onClose } = props

  return (
    <GuidanceModal
      isOpen={isOpen}
      title="将来のプランを入力しましょう"
      contents={[
        {
          text: (
            <div className="pb-[30px]">
              <Typography className="text-12 md:text-16">
                将来のお金にかかわる様々な出来事・理想の生活を入力しましょう。
                <br />
                <br />
                何から登録すればいいか分からない場合は、「基本の生活費を想定する」から年齢ごとの生活費を入力してみましょう。
              </Typography>
            </div>
          ),
          src: SimulationCreateItem,
          alt: 'simulation panel',
        },
      ]}
      onClose={onClose}
    />
  )
}

export default SimulationPanelModal
