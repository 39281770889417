import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { reject as _reject, uniqBy as _uniqBy } from 'lodash'
import { Errors } from '../models/errorsModel'

export interface FlashAlertMessageObject {
  message: string
  variant?: 'error' | 'info' | 'success' | 'warning'
  autoHideDuration?: number | null
  persist?: boolean
}

interface FlashAlertState {
  flashAlertMessageObjects: FlashAlertMessageObject[]
}

interface FlashAlertParams extends FlashAlertState {}

const initialState: FlashAlertState = {
  flashAlertMessageObjects: [],
}

const flashAlertsSlice = createSlice({
  name: 'flashAlerts',
  initialState,
  reducers: {
    setFlashAlert: (state, action: PayloadAction<FlashAlertParams>) => {
      const { payload } = action

      state.flashAlertMessageObjects.push(...payload.flashAlertMessageObjects)
      state.flashAlertMessageObjects = _uniqBy(state.flashAlertMessageObjects, 'message')
      return state
    },
    removeFlashAlert: (state, action: PayloadAction<string>) => {
      const { payload } = action

      state.flashAlertMessageObjects = _reject(
        state.flashAlertMessageObjects,
        (item) => item.message === payload,
      )
      return state
    },
    removeAllFlashAlerts: (state) => {
      state = initialState
      return state
    },
  },
})

export const {
  setFlashAlert,
  removeFlashAlert,
  removeAllFlashAlerts,
} = flashAlertsSlice.actions

export const flashAlertReducer = flashAlertsSlice.reducer

export const convertErrorToFlashAlertMessageObjects = (
  errors: Errors,
): FlashAlertParams => ({
  flashAlertMessageObjects: errors.messages.map((message: string) => ({
    message,
    variant: 'error',
  })),
})
