import { find as _find } from 'lodash'
import { PensionType } from './inputCorporatePensionsModel'
import { PENSION_TYPE_SELECT_ITEMS } from '../../constants/formItem/inputs/inputCorporatePensions'
import { defaultToEmptyString } from '../commonsModelFunc'

//年金種別
export const PENSION_TYPE = {
  confirm: 'confirm',
  lifetime: 'life',
}

export const convertPensionType = (pensionType: PensionType | null) => {
  const label = _find(PENSION_TYPE_SELECT_ITEMS, (item) => item.value === pensionType)
    ?.label
  return defaultToEmptyString(label)
}
