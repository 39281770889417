import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  getAggBalanceLastMonth,
  patchAggBalanceMonthly,
  patchAggBalanceMonthlyInputType,
} from '@/api/aggBalanceMonthlies'
import { unauthorizedCheck } from '@/containers/accountsSlice'
import {
  AggBalanceMonthlyResponse,
  AggBalanceMonthly,
  AggBalanceMonthlyUpdateValues,
  AggBalanceMonthlyInputTypeUpdateValues,
} from '@/models/aggBalanceMonthliesModel'
import { AppThunk } from '@/store'

interface AggBalanceMonthlyState {
  aggBalanceMonthly: AggBalanceMonthly | null
  isLoading: boolean
}

const initialState: AggBalanceMonthlyState = {
  aggBalanceMonthly: null,
  isLoading: false,
}

const aggBalanceMonthliesSlice = createSlice({
  name: 'aggBalanceMonthlies',
  initialState,
  reducers: {
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    getAggBalanceLastMonthSuccess: (
      state,
      action: PayloadAction<AggBalanceMonthlyResponse | null>,
    ) => {
      state.aggBalanceMonthly = action.payload
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
  },
})

export const {
  requestStart,
  requestSuccess,
  getAggBalanceLastMonthSuccess,
  requestFailure,
} = aggBalanceMonthliesSlice.actions
export const aggBalanceMonthliesReducer = aggBalanceMonthliesSlice.reducer

export const fetchAggBalanceLastMonth = (values?: string): AppThunk => async (
  dispatch,
) => {
  dispatch(requestStart())
  getAggBalanceLastMonth(values)
    .then((response) => dispatch(getAggBalanceLastMonthSuccess(response)))
    .catch(() => dispatch(requestFailure()))
}

export const updateAggBalanceMonthly = (
  values: AggBalanceMonthlyUpdateValues,
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchAggBalanceMonthly(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const updateAggBalanceMonthlyInputType = (
  values: AggBalanceMonthlyInputTypeUpdateValues,
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchAggBalanceMonthlyInputType(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}
