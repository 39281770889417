import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { sortBy as _sortBy } from 'lodash'
import {
  postFutureHousingSaleLoanYearly,
  postFutureHousingSaleV2,
  patchFutureHousingSaleV2,
  deleteFutureHousingSaleV2,
} from '@/api/futures/futureHousingSales'
import { unauthorizedCheck } from '@/containers/accountsSlice'
import {
  FutureHousingSaleLoanYearly,
  FutureHousingSaleLoanYearlyCreateValues,
} from '@/models/futures/futureHousingSalesModel'
import { FutureHousingSale } from '@/models/futures/futureHousingSalesModel'
import { AppThunk } from '@/store'

interface FutureHousingSaleState {
  futureHousingSales: FutureHousingSale[]
  futureHousingSaleLoanYearly: FutureHousingSaleLoanYearly
  isLoading: boolean
}

const initialState: FutureHousingSaleState = {
  futureHousingSales: [],
  futureHousingSaleLoanYearly: {},
  isLoading: false,
}

const futureHousingSalesSlice = createSlice({
  name: 'futureHousingSales',
  initialState,
  reducers: {
    setFutureHousingSales: (state, action: PayloadAction<FutureHousingSale[]>) => {
      const { payload } = action
      const sortedFutureHousingSales = _sortBy(payload, 'id')

      state.futureHousingSales = sortedFutureHousingSales.map((item) => ({
        ...item,
      }))

      return state
    },
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    postFutureHousingSaleLoanYearlySuccess: (
      state,
      action: PayloadAction<FutureHousingSaleLoanYearly>,
    ) => {
      state.futureHousingSaleLoanYearly = action.payload
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
  },
})

export const {
  setFutureHousingSales,
  requestStart,
  requestSuccess,
  postFutureHousingSaleLoanYearlySuccess,
  requestFailure,
} = futureHousingSalesSlice.actions
export const futureHousingSalesReducer = futureHousingSalesSlice.reducer

export const createFutureHousingSaleLoanYearly = (
  values: FutureHousingSaleLoanYearlyCreateValues,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postFutureHousingSaleLoanYearly(values)
    .then((response: FutureHousingSaleLoanYearly) => {
      dispatch(postFutureHousingSaleLoanYearlySuccess(response))
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const createFutureHousingSaleV2 = (
  values: any,
  callback: (id?: number) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postFutureHousingSaleV2(values)
    .then((response: any) => {
      dispatch(requestSuccess())
      callback(response.id)
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const updateFutureHousingSaleV2 = (
  values: any,
  id: number,
  callback?: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchFutureHousingSaleV2(values, id)
    .then(() => {
      dispatch(requestSuccess())
      callback && callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const destroyFutureHousingSaleV2 = (
  id: number,
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  deleteFutureHousingSaleV2(id)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}
