import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import Typography from '@/components/v2/atoms/Typography'
import { convertLocaleString } from '@/models/commonsModelFunc'
import { FutureV2Housing } from '@/models/futures/futureV2HousingsModel'
import { RootState } from '@/store'

interface FutureV2HousingRentBodyProps {
  futureV2Housing: Partial<FutureV2Housing>
}

const FutureV2HousingRentBody: FC<FutureV2HousingRentBodyProps> = (props) => {
  const { futureV2Housing } = props

  const {
    familyInfo: { person_current_age },
  } = useSelector((state: RootState) => state.futureBases)

  return (
    <>
      <table className="w-[100%] mb-[60px] last:mb-0 text-[12px]">
        <tbody>
          <tr className="h-[45px] odd:bg-black-100">
            <td className="w-[40%] px-[16px]">
              <Typography>契約</Typography>
            </td>
            <td className="w-[60%] px-[16px]">
              <Typography>賃貸</Typography>
            </td>
          </tr>

          {futureV2Housing.future_v2_housing_rents!.map((item, index) => (
            <React.Fragment key={item.id}>
              <tr className="h-[45px] odd:bg-black-100">
                <td className="w-[40%] px-[16px]">
                  <Typography className="">{`${item.living_start_at_age}歳${
                    index === 0 && person_current_age === item.living_start_at_age
                      ? '(現在)'
                      : ''
                  } 〜 ${
                    item.living_end_at_age == 120
                      ? '終身まで'
                      : `${item.living_end_at_age}歳`
                  }`}</Typography>
                </td>
                <td className="w-[60%] px-[16px]">
                  <Typography className="">
                    {`家賃 ${convertLocaleString(item.rent_monthly)}円/月`}
                  </Typography>
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </>
  )
}

export default FutureV2HousingRentBody
