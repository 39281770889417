import ApiClient from '@/api/apiClient'
import {
  InputEstateInvestment,
  InputEstateInvestmentCreateValues,
  InputEstateInvestmentUpdateValues,
  InputEstateInvestmentDestroyValues,
} from '@/models/inputs/inputEstateInvestmentsModel'

export const getInputEstateInvestments = async () => {
  return await ApiClient.get(
    '/input_estate_investments',
    (response: InputEstateInvestment[]) => response,
    undefined,
    false,
    'v2',
  )
}

export const postInputEstateInvestment = async (
  values: InputEstateInvestmentCreateValues,
) => {
  return await ApiClient.post('/input_estate_investments', () => {}, values)
}

export const patchInputEstateInvestment = async (
  values: InputEstateInvestmentUpdateValues,
) => {
  return await ApiClient.patch('/input_estate_investments', () => {}, values)
}

export const deleteInputEstateInvestment = async (values: {
  params: InputEstateInvestmentDestroyValues
}) => {
  return await ApiClient.delete('/input_estate_investments', () => {}, values)
}
