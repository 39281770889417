import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getSeminar } from '@/api/seminars'
import { unauthorizedCheck } from '@/containers/accountsSlice'
import { Seminar } from '@/models/seminarsModel'
import { AppThunk } from '@/store'

interface SeminarState {
  seminar: Partial<Seminar>
  isLoading: boolean
}

const initialState: SeminarState = {
  seminar: {},
  isLoading: false,
}

const seminarSlice = createSlice({
  name: 'seminar',
  initialState,
  reducers: {
    setSeminar: (state, action: PayloadAction<Partial<Seminar>>) => {
      state.seminar = action.payload
      return state
    },
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    getSeminarSuccess: (state, action: PayloadAction<Partial<Seminar>>) => {
      state.seminar = action.payload
      state.isLoading = false

      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
    resetSeminars: () => {
      return initialState
    },
  },
})

export const {
  setSeminar,
  requestStart,
  requestSuccess,
  getSeminarSuccess,
  requestFailure,
  resetSeminars,
} = seminarSlice.actions
export const seminarReducer = seminarSlice.reducer

export const fetchSeminar = (uniqueId: string): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  getSeminar(uniqueId)
    .then((response) => dispatch(getSeminarSuccess(response)))
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}
