import { isUndefined as _isUndefined } from 'lodash'
import { convertLocaleString, convertMomentDate } from '../commonsModelFunc'
import { InputDecline } from './inputDeclinesModel'

export const convertInputDecline = (inputDeclines: InputDecline[], index: number) => {
  if (_isUndefined(inputDeclines[index])) return '設定なし'

  const fromLabel =
    index === 0
      ? '現在'
      : convertMomentDate(inputDeclines[index].annual_rate_of_decline_year, 'YYYY年')
  const toLabel =
    index === inputDeclines.length - 1
      ? '終身'
      : convertMomentDate(inputDeclines[index + 1].annual_rate_of_decline_year, 'YYYY年')

  return `${fromLabel}〜${toLabel} 年間${convertLocaleString(
    inputDeclines[index].annual_rate_of_decline_percent,
  )}％`
}
