import { createSlice } from '@reduxjs/toolkit'
import { isUndefined as _isUndefined } from 'lodash'
import { postAccountInvitation } from '../../api/admins/account_invitations'
import { AppThunk } from '../../store'

const accountInvitationsSlice = createSlice({
  name: 'account_invitations',
  initialState: {
    accountInvitations: [],
    isLoading: false,
  },
  reducers: {
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
  },
})

export const {
  requestStart,
  requestSuccess,
  requestFailure,
} = accountInvitationsSlice.actions
export const accountInvitationsReducer = accountInvitationsSlice.reducer

export const createAccountInvitation = (
  values: any,
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postAccountInvitation({ account_invitation: values })
    .then((response) => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
    })
}
