import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDom from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import App from '@/App'
import { history } from '@/history'
import { setupStore } from '@/store'
import '@/assets/sass/common.sass'
import '@/assets/sass/v2/font-icon.sass'
import '@/assets/tailwind/index.generated.css'
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const store = setupStore()

ReactDom.render(
  <Provider store={store}>
    {/* @ts-ignore */}
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.querySelector('#js-content'),
)

serviceWorkerRegistration.register();
