import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'react-final-form'
import { isUndefined as _isUndefined } from 'lodash'
import { parse } from 'query-string'
import MarginContainer from '@/components/v2/atoms/MarginContainer'
import Paper from '@/components/v2/atoms/Paper'
import Typography from '@/components/v2/atoms/Typography'
import FieldBlock from '@/components/v2/molecules/FieldBlock'
import TextField from '@/components/v2/atoms/TextField'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import WholeSubmitErrorMessage from '@/components/v2/molecules/WholeSubmitErrorMessage'
import { setPageMeta } from '@/containers/pageMetasSlice'
import { videoPlatformsLogin } from '@/containers/videoPlatforms/authenticationsSlice'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import MovieLogindRedirect from '@/layouts/MovieLogindRedirect'
import { RootState } from '@/store'
import { isSmartPhone, prohibitScalable } from '@/utils/userAgent'
import { composeValidators, required, mailAddress } from '@/utils/validate'
import LoadingOverlay from '@/components/v1/atoms/LoadingOverlay'
import backgroundImage from '@/assets/images/videoLoginBackground.png'
import MovieLogo from '@/assets/images/movie-logo.png'
import CheckboxGroup from '@/components/v2/molecules/CheckboxGroup'
import Link from '@/components/v2/atoms/Link'

const LearningVideosLoginForm: FC = () => {
  const dispatch = useDispatch()
  const { handleMovePage } = useCustomHistory()

  const [submitErrors, setSubmitErrors] = useState<any>(undefined)
  const { isLoading } = useSelector(
    (state: RootState) => state.videoPlatformsAuthentications,
  )

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: '動画閲覧 ログイン',
        description: '動画閲覧 ログイン',
        keyword: '動画閲覧 ログイン',
      }),
    )

    if (isSmartPhone()) {
      document.addEventListener('touchmove', prohibitScalable, { passive: false })
    }

    return () => {
      isSmartPhone() && document.removeEventListener('touchmove', prohibitScalable)
    }
  }, [])

  const handleMovieSubmit = async (values: any) => {
    const { redirectUrl } = parse(location.search)
    const targetUrl = !_isUndefined(redirectUrl)
      ? `/movies${redirectUrl}`
      : '/movies/list'

    const submitErrors = await new Promise((resolve) => {
      dispatch(videoPlatformsLogin(values, resolve))
    })

    if (_isUndefined(submitErrors)) {
      handleMovePage(targetUrl)
    } else {
      setSubmitErrors(submitErrors)
    }
  }

  return (
    <div style={{ backgroundImage: `url(${backgroundImage})` }} className="h-[100vh]">
      <LoadingOverlay isLoading={isLoading} />
      <MovieLogindRedirect>
        <MarginContainer className="py-40">
          <Paper className="sm:w-524 mx-8 sm:mx-auto px-16 py-24 sm:p-40 space-y-24 bg-white">
            <div className="flex justify-center">
              <img className="h-31 lg:h-[62px]" src={MovieLogo} alt="マネソル" />
            </div>

            <Form
              onSubmit={() => {}}
              render={({ handleSubmit, values, errors }) => {
                const { privacy } = values
                return (
                  <form onSubmit={handleSubmit} className="space-y-24">
                    <FieldBlock label="ご自身のメールアドレス">
                      <TextField
                        type="text"
                        name="mail_address"
                        placeholder="sample@example.com"
                        isFullWidth
                        validate={composeValidators(required, mailAddress)}
                      />
                    </FieldBlock>
                    <FieldBlock label="パスワード">
                      <TextField
                        type="password"
                        name="password"
                        autoComplete="off"
                        placeholder="パスワード"
                        isFullWidth
                        validate={composeValidators(required)}
                      />
                    </FieldBlock>

                    <CheckboxGroup
                      name="privacy"
                      datas={[
                        {
                          label: (
                            <Typography>
                              <Link
                                className="text-[#7D966D] bordrer border-b border-[#7D966D]"
                                children="個人情報保護の取り扱いについて"
                                onClick={() =>
                                  window.open(`${location.origin}/v2/policy`)
                                }
                              />
                              確認しました
                            </Typography>
                          ),
                          value: true,
                        },
                      ]}
                    />

                    <WholeSubmitErrorMessage submitErrors={submitErrors} />
                    <div className="flex justify-center">
                      <ButtonPrimary
                        className="h-45 w-full md:w-188 text-white"
                        isDisabled={
                          Object.keys(errors).length > 0 ||
                          isLoading ||
                          !privacy ||
                          (privacy && privacy.length == 0)
                        }
                        onClick={() => handleMovieSubmit(values)}
                      >
                        <Typography
                          className="bottom-2 flex-auto pl-30 relative text-16"
                          isBold
                        >
                          視聴する
                        </Typography>
                        <i className="ico chevron-right mr-17 text-24" />
                      </ButtonPrimary>
                    </div>
                  </form>
                )
              }}
            />
          </Paper>
        </MarginContainer>
      </MovieLogindRedirect>
    </div>
  )
}

export default LearningVideosLoginForm
