import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { sortBy as _sortBy } from 'lodash'
import { videoUnauthorizedCheck } from '@/containers/videoPlatforms/authenticationsSlice'
import { AppThunk } from '@/store'
import {
  LearningVideoCompany,
  LearningVideoCompanyResponse,
} from '@/models/videoPlatforms/learningVideoComapniesModel'
import { getlearningVideoCompanies } from '@/api/videoPlatforms/learningVideoCompanies'

interface LearningVideoCompanyState {
  LearningVideoCompany: LearningVideoCompany | null
  isLoading: boolean
}

const initialState: LearningVideoCompanyState = {
  LearningVideoCompany: null,
  isLoading: false,
}

const learnigVideoCompaniesSlice = createSlice({
  name: 'learnigVideoCompanies',
  initialState,
  reducers: {
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    getLearningVideoCompanySuccess: (
      state,
      action: PayloadAction<LearningVideoCompanyResponse>,
    ) => {
      state.LearningVideoCompany = action.payload
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
  },
})

export const {
  requestStart,
  requestSuccess,
  getLearningVideoCompanySuccess,
  requestFailure,
} = learnigVideoCompaniesSlice.actions
export const learningVideoCompaniesReducer = learnigVideoCompaniesSlice.reducer

export const fetchLearningVideoCompany = (): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  getlearningVideoCompanies()
    .then((response) => dispatch(getLearningVideoCompanySuccess(response)))
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(videoUnauthorizedCheck(error))
    })
}
