import { filter as _filter, pick as _pick } from 'lodash'
import { API_BOOLEAN_FLAG } from '@/constants/formItem/commons'
import {
  API_FLAG,
  castNumberWithoutOperator,
  convertDateYear,
  isEvenNumber,
  isNotEmptyValue,
} from '@/models/commonsModelFunc'
import {
  LOAN_FOR,
  LOAN_TYPE,
  REPAIR_RESERVE_FUND_RATE_TYPE,
  isMansionType,
  isHousingType2Custom,
  isWithLoan,
  isWithCalcLoan,
  isFlatLoan,
  isPairLoan,
} from '@/models/commonHousingModelFunc'
import { PERSON_FLG } from '@/models/familyStructuresModelFunc'
import { FutureV2HousingLoan, LoanFor } from '@/models/futures/futureV2HousingLoansModel'
import {
  isOneLoanPattern,
  isTwoLoanPattern,
} from '@/models/futures/futureV2HousingLoansModelFunc'
import { FutureV2HousingRent } from '@/models/futures/futureV2HousingRentsModel'
import { FutureV2HousingSelfOwned } from '@/models/futures/futureV2HousingSelfOwnedModel'
import { isSplitedLoan } from '@/models/futures/futureV2HousingSelfOwnedModelFunc'
import { FutureV2HousingOther } from '@/models/futures/futureV2HousingOthersModel'
import { FutureV2HousingOtherCost } from '@/models/futures/futureV2HousingOtherCostsModel'

const selectPersonFlg = (
  futureV2Loan: Partial<FutureV2HousingLoan>,
  loanIndex: number,
) => {
  if (futureV2Loan.loan_type === LOAN_TYPE.spouseOnly) return PERSON_FLG.spouse
  if (!isPairLoan(futureV2Loan.loan_type)) return PERSON_FLG.person

  return isEvenNumber(loanIndex) ? PERSON_FLG.person : PERSON_FLG.spouse
}

const convertLoanCalculationNonRequiredValues = (
  futureV2Loan: Partial<FutureV2HousingLoan>,
) => {
  // if (
  //   !isFlatLoan(futureV2Loan.loan_lender) &&
  //   futureV2Loan.future_v2_housing_loan_calculation_attributes!.life_insurance
  // ) {
  //   futureV2Loan.future_v2_housing_loan_calculation_attributes!.life_insurance = null
  // }
  if (
    !isPairLoan(futureV2Loan.loan_type) &&
    futureV2Loan.future_v2_housing_loan_calculation_attributes!.married_couple_group_shin
  ) {
    futureV2Loan.future_v2_housing_loan_calculation_attributes!.married_couple_group_shin = null
  }

  return futureV2Loan
}

export const buildLoanCalculationSubmitValues = (
  selfOwnedAttributes: Partial<FutureV2HousingSelfOwned>,
) => {
  const { future_v2_housing_loans_attributes: loansAttributes } = selfOwnedAttributes

  loansAttributes!.map((futureV2Loan, loanIndex) => {
    // future_v2_housing_loan_calculation_attributes を null にして返却
    if (!isWithCalcLoan(futureV2Loan.repayment_chart)) {
      futureV2Loan.future_v2_housing_loan_calculation_attributes = null

      return futureV2Loan
    }

    let {
      future_v2_housing_loan_calculation_attributes: calcAttributes,
    } = convertLoanCalculationNonRequiredValues(futureV2Loan)

    calcAttributes!.person_flg = selectPersonFlg(futureV2Loan, loanIndex)

    switch (loanIndex) {
      case 1:
        if (isFlatLoan(futureV2Loan.loan_lender)) {
          calcAttributes = {
            ...loansAttributes![0].future_v2_housing_loan_calculation_attributes,
            repayment_monthly_manyen: loansAttributes![0]
              .future_v2_housing_loan_calculation_attributes
              ?.started_borrowing_amount_manyen,
            repayment_period: loansAttributes![0]
              .future_v2_housing_loan_calculation_attributes?.repayment_period,
            loan_deduction: loansAttributes![0]
              .future_v2_housing_loan_calculation_attributes?.loan_deduction,
            life_insurance: loansAttributes![0]
              .future_v2_housing_loan_calculation_attributes?.life_insurance,
            future_v2_housing_loan_calculation_rates_attributes:
              calcAttributes?.future_v2_housing_loan_calculation_rates_attributes,
            started_borrowing_amount_manyen:
              calcAttributes?.started_borrowing_amount_manyen,
          }
        }

        break

      case 3:
        if (isFlatLoan(futureV2Loan.loan_lender)) {
          calcAttributes = {
            ...loansAttributes![2].future_v2_housing_loan_calculation_attributes,
            repayment_monthly_manyen: loansAttributes![2]
              .future_v2_housing_loan_calculation_attributes
              ?.started_borrowing_amount_manyen,
            repayment_period: loansAttributes![2]
              .future_v2_housing_loan_calculation_attributes?.repayment_period,
            loan_deduction: loansAttributes![2]
              .future_v2_housing_loan_calculation_attributes?.loan_deduction,
            life_insurance: loansAttributes![2]
              .future_v2_housing_loan_calculation_attributes?.life_insurance,
            future_v2_housing_loan_calculation_rates_attributes:
              calcAttributes?.future_v2_housing_loan_calculation_rates_attributes,
            started_borrowing_amount_manyen:
              calcAttributes?.started_borrowing_amount_manyen,
          }
        }

        break

      default:
        break
    }

    calcAttributes!.repayment_monthly_manyen = calcAttributes!.started_borrowing_amount_manyen
    // MEMO: フォームに値はないが、計算に必要なため値を代入
    calcAttributes!.bonus_flg = API_FLAG.off

    calcAttributes!.future_v2_housing_loan_calculation_rates_attributes = calcAttributes!
      .future_v2_housing_loan_calculation_rates_attributes
      ? _filter(
          [...calcAttributes!.future_v2_housing_loan_calculation_rates_attributes!],
          (item) => isNotEmptyValue(item.changed_rate_percent),
        )
      : undefined

    calcAttributes!.future_v2_housing_loan_calculation_rates_other_attributes = calcAttributes!
      .future_v2_housing_loan_calculation_rates_other_attributes
      ? _filter(
          [...calcAttributes!.future_v2_housing_loan_calculation_rates_other_attributes],
          (otherItem) =>
            isNotEmptyValue(otherItem.changed_rate_percent) &&
            otherItem.changed_rate_percent != 0,
        )
      : undefined

    if (calcAttributes!.future_v2_housing_loan_calculation_rates_other_attributes) {
      calcAttributes!.future_v2_housing_loan_calculation_rates_other_attributes!.forEach(
        (calcRateItem) => {
          if (!calcRateItem) return // なぜか undefined が入ってくるケースがあるのでその場合はスキップ
          calcRateItem.rate_start_at = convertDateYear(calcRateItem.rate_start_at_year)
        },
      )
    }

    calcAttributes!.future_v2_housing_loan_calculation_rates_attributes! = [
      ...(calcAttributes!.future_v2_housing_loan_calculation_rates_attributes
        ? calcAttributes!.future_v2_housing_loan_calculation_rates_attributes
        : []),
      ...(calcAttributes!.future_v2_housing_loan_calculation_rates_other_attributes
        ? calcAttributes!.future_v2_housing_loan_calculation_rates_other_attributes
        : []),
    ]

    futureV2Loan.future_v2_housing_loan_calculation_attributes = calcAttributes

    return futureV2Loan
  })

  loansAttributes!.map((futureV2Loan) => {
    // future_v2_housing_loan_calculation_attributes を null にして返却
    if (!isWithCalcLoan(futureV2Loan.repayment_chart)) {
      futureV2Loan.future_v2_housing_loan_calculation_attributes = null

      return futureV2Loan
    }

    let { future_v2_housing_loan_calculation_attributes: calcAttributes } = futureV2Loan

    calcAttributes = _pick(calcAttributes, [
      'person_flg',
      'started_borrowing_amount_manyen',
      'bonus_flg',
      'repayment_monthly',
      'repayment_monthly_manyen',
      'repayment_bonus',
      'repayment_bonus_manyen',
      'repayment_period',
      'repayment_rate',
      'life_insurance',
      'married_couple_group_shin',
      'loan_deduction',
      'future_v2_housing_loan_calculation_rates_attributes',
    ])

    futureV2Loan.future_v2_housing_loan_calculation_attributes = calcAttributes

    return futureV2Loan
  })

  return loansAttributes
}

const selectLoanFor = (
  isSplited: boolean,
  futureV2Loan: Partial<FutureV2HousingLoan>,
  loanIndex: number,
) => {
  if (!isSplited) return null

  switch (Number(loanIndex)) {
    case 0:
      return LOAN_FOR.land as LoanFor

    case 1:
      return isPairLoan(futureV2Loan.loan_type) || isFlatLoan(futureV2Loan.loan_lender)
        ? (LOAN_FOR.land as LoanFor)
        : (LOAN_FOR.building as LoanFor)

    case 2:
    case 3:
      return LOAN_FOR.building as LoanFor

    default:
      return null
  }
}

const selectIsFlatApplied = (isFlat: boolean, loanIndex: number) => {
  if (!isFlat) return null

  return isEvenNumber(loanIndex) ? API_BOOLEAN_FLAG.on : API_BOOLEAN_FLAG.off
}

export const buildSelfOwnedLoanSubmitValues = (
  selfOwnedAttributes: Partial<FutureV2HousingSelfOwned>,
) => {
  let { future_v2_housing_loans_attributes: loansAttributes } = selfOwnedAttributes

  //----- ローン借りない
  if (!isWithLoan(selfOwnedAttributes.loan_type1)) {
    return (loansAttributes = [])
  }

  // 共通処理
  loansAttributes!.map((futureV2Loan, loanIndex) => {
    // 将来に返済計画表は存在しないため常になし
    futureV2Loan.repayment_chart = API_FLAG.off
    futureV2Loan.repayment_start_at = convertDateYear(
      selfOwnedAttributes.purchased_at_year,
    )
    futureV2Loan.loan_for = selectLoanFor(
      isSplitedLoan(selfOwnedAttributes),
      futureV2Loan,
      loanIndex,
    )
    futureV2Loan.is_flat_applied = selectIsFlatApplied(
      isFlatLoan(futureV2Loan.loan_lender),
      loanIndex,
    )

    return futureV2Loan
  })

  if (!isSplitedLoan(selfOwnedAttributes) && isTwoLoanPattern(loansAttributes![0])) {
    loansAttributes![1].loan_type = loansAttributes![0]?.loan_type
    loansAttributes![1].loan_lender = loansAttributes![0]?.loan_lender
    loansAttributes![1].repayment_chart = loansAttributes![0]?.repayment_chart
  }
  if (
    isSplitedLoan(selfOwnedAttributes) &&
    isOneLoanPattern(loansAttributes![0]) &&
    isTwoLoanPattern(loansAttributes![2])
  ) {
    loansAttributes![3].loan_type = loansAttributes![2]?.loan_type
    loansAttributes![3].loan_lender = loansAttributes![2]?.loan_lender
    loansAttributes![3].repayment_chart = loansAttributes![2]?.repayment_chart
  }
  if (
    isSplitedLoan(selfOwnedAttributes) &&
    isTwoLoanPattern(loansAttributes![0]) &&
    isTwoLoanPattern(loansAttributes![2])
  ) {
    loansAttributes![1].loan_type = loansAttributes![0]?.loan_type
    loansAttributes![1].loan_lender = loansAttributes![0]?.loan_lender
    loansAttributes![1].repayment_chart = loansAttributes![0]?.repayment_chart
    loansAttributes![3].loan_type = loansAttributes![2]?.loan_type
    loansAttributes![3].loan_lender = loansAttributes![2]?.loan_lender
    loansAttributes![3].repayment_chart = loansAttributes![2]?.repayment_chart
  }
  if (
    isFlatLoan(loansAttributes![0]?.loan_lender) &&
    isWithCalcLoan(loansAttributes![0]?.repayment_chart)
  ) {
    loansAttributes![1].future_v2_housing_loan_calculation_attributes! = {
      ...loansAttributes![1].future_v2_housing_loan_calculation_attributes!,
      ..._pick(loansAttributes![0].future_v2_housing_loan_calculation_attributes, [
        'repayment_period',
        'loan_deduction',
        'person_flg',
      ]),
    }
    loansAttributes![1].future_v2_housing_loan_calculation_attributes!.future_v2_housing_loan_calculation_rates_other_attributes = []
    loansAttributes![1].future_v2_housing_loan_calculation_attributes!.repayment_monthly_manyen = loansAttributes![0].future_v2_housing_loan_calculation_attributes!.started_borrowing_amount_manyen
    loansAttributes![1].future_v2_housing_loan_calculation_attributes!.bonus_flg =
      API_FLAG.off
  }
  if (
    isFlatLoan(loansAttributes![2]?.loan_lender) &&
    isWithCalcLoan(loansAttributes![2]?.repayment_chart)
  ) {
    loansAttributes![3].future_v2_housing_loan_calculation_attributes!.future_v2_housing_loan_calculation_rates_other_attributes = []
    loansAttributes![3].future_v2_housing_loan_calculation_attributes!.repayment_monthly_manyen = loansAttributes![2].future_v2_housing_loan_calculation_attributes!.started_borrowing_amount_manyen
    loansAttributes![3].future_v2_housing_loan_calculation_attributes!.bonus_flg =
      API_FLAG.off
  }

  // 返済計画表なしパターンの処理
  // 将来のローンなので返済計画表は常にない
  loansAttributes = buildLoanCalculationSubmitValues(selfOwnedAttributes)

  // ローン１つ
  // ローン借りない + 分割なし
  if (
    isWithLoan(selfOwnedAttributes.loan_type1) &&
    !isSplitedLoan(selfOwnedAttributes) &&
    isOneLoanPattern(loansAttributes![0])
  ) {
    loansAttributes = [{ ...loansAttributes![0] }]
  }

  // ローン２つ
  // ローン借りる + 分割なし
  if (
    isWithLoan(selfOwnedAttributes.loan_type1) &&
    !isSplitedLoan(selfOwnedAttributes) &&
    isTwoLoanPattern(loansAttributes![0])
  ) {
    loansAttributes = [{ ...loansAttributes![0] }, { ...loansAttributes![1] }]
  }

  // ローン２つ
  // ローン借りる + 分割あり
  if (
    isWithLoan(selfOwnedAttributes.loan_type1) &&
    isSplitedLoan(selfOwnedAttributes) &&
    isOneLoanPattern(loansAttributes![0]) &&
    isOneLoanPattern(loansAttributes![2])
  ) {
    loansAttributes = [{ ...loansAttributes![0] }, { ...loansAttributes![2] }]
  }

  // ローン３つ
  // ローン借りる + 分割あり
  //   土地用：(返済計画表なし・ペアローン/フラットローン)
  //   建物用：単独ローン and (返済計画表なし・民間ローン)
  if (
    isWithLoan(selfOwnedAttributes.loan_type1) &&
    isSplitedLoan(selfOwnedAttributes) &&
    isTwoLoanPattern(loansAttributes![0]) &&
    isOneLoanPattern(loansAttributes![2])
  ) {
    loansAttributes = [
      { ...loansAttributes![0] },
      { ...loansAttributes![1] },
      { ...loansAttributes![2] },
    ]
  }

  // ローン３つ
  // ローン借りる + 分割あり
  //   土地用：単独ローン and (返済計画表なし・民間ローン)
  //   建物用：(返済計画表なし・ペアローン/フラットローン)
  if (
    isWithLoan(selfOwnedAttributes.loan_type1) &&
    isSplitedLoan(selfOwnedAttributes) &&
    isOneLoanPattern(loansAttributes![0]) &&
    isTwoLoanPattern(loansAttributes![2])
  ) {
    loansAttributes = [
      { ...loansAttributes![0] },
      { ...loansAttributes![2] },
      { ...loansAttributes![3] },
    ]
  }

  // ローン４つ
  // ローン借りる + 分割あり
  //   土地用：(返済計画表なし・ペアローン/フラットローン)
  //   建物用：(返済計画表なし・ペアローン/フラットローン)
  if (
    isWithLoan(selfOwnedAttributes.loan_type1) &&
    isSplitedLoan(selfOwnedAttributes) &&
    isTwoLoanPattern(loansAttributes![0]) &&
    isTwoLoanPattern(loansAttributes![2])
  ) {
    loansAttributes = [
      { ...loansAttributes![0] },
      { ...loansAttributes![1] },
      { ...loansAttributes![2] },
      { ...loansAttributes![3] },
    ]
  }

  selfOwnedAttributes.future_v2_housing_loans_attributes = loansAttributes

  // 共通処理
  loansAttributes!.map((futureV2Loan) => {
    futureV2Loan = _pick(futureV2Loan, [
      'repayment_chart',
      'repayment_start_at',
      'loan_type',
      'loan_lender',
      'is_flat_applied',
      'future_v2_housing_loan_calculation_attributes',
      'loan_for',
    ])

    return futureV2Loan
  })

  return loansAttributes
}

const convertHousingSelfOwnedNonRequiredValues = (
  selfOwnedAttributes: Partial<FutureV2HousingSelfOwned>,
) => {
  if (!isMansionType(selfOwnedAttributes.house_type)) {
    selfOwnedAttributes.repair_reserve_fund = null
    selfOwnedAttributes.repair_reserve_fund_increase_type = null
    selfOwnedAttributes.management_fee_monthly = null
  }
  if (
    !isMansionType(selfOwnedAttributes.house_type) ||
    selfOwnedAttributes.repair_reserve_fund_increase_type ===
      REPAIR_RESERVE_FUND_RATE_TYPE.noSet
  ) {
    selfOwnedAttributes.repair_reserve_fund_amount_start = null
    selfOwnedAttributes.repair_reserve_fund_amount_finish = null
    selfOwnedAttributes.repair_reserve_fund_increase_start = null
    selfOwnedAttributes.repair_reserve_fund_increase_finish = null
    selfOwnedAttributes.repair_reserve_fund_increase_span = null
    selfOwnedAttributes.repair_reserve_fund_increase_percent = null
  }
  if (
    selfOwnedAttributes.repair_reserve_fund_increase_type ===
    REPAIR_RESERVE_FUND_RATE_TYPE.change
  ) {
    selfOwnedAttributes.repair_reserve_fund_amount = null
    selfOwnedAttributes.repair_reserve_fund_amount_start = null
    selfOwnedAttributes.repair_reserve_fund_amount_finish = null
  }
  if (
    selfOwnedAttributes!.repair_reserve_fund_increase_type ===
    REPAIR_RESERVE_FUND_RATE_TYPE.fixed
  ) {
    selfOwnedAttributes.repair_reserve_fund_increase_start = null
    selfOwnedAttributes.repair_reserve_fund_increase_finish = null
    selfOwnedAttributes.repair_reserve_fund_increase_span = null
    selfOwnedAttributes.repair_reserve_fund_increase_percent = null
  }
  if (!isHousingType2Custom(selfOwnedAttributes.housing_type2)) {
    selfOwnedAttributes.loan_type2 = null
  }
  // 管理費・修繕積立金: 詳細入力を選択
  if (selfOwnedAttributes.is_management_and_fund_total === API_BOOLEAN_FLAG.off) {
    selfOwnedAttributes.management_fee_monthly_and_repair_reserve_fund_total = null
  }

  // 管理費・修繕積立金: 総額入力を選択
  if (selfOwnedAttributes.is_management_and_fund_total === API_BOOLEAN_FLAG.on) {
    selfOwnedAttributes.management_fee_monthly = null
    selfOwnedAttributes.repair_reserve_fund = null
    selfOwnedAttributes.repair_reserve_fund_increase_type = null
    selfOwnedAttributes.repair_reserve_fund_amount = null
    selfOwnedAttributes.repair_reserve_fund_amount_start = null
    selfOwnedAttributes.repair_reserve_fund_amount_finish = null
    selfOwnedAttributes.repair_reserve_fund_increase_start = null
    selfOwnedAttributes.repair_reserve_fund_increase_span = null
    selfOwnedAttributes.repair_reserve_fund_increase_finish = null
    selfOwnedAttributes.repair_reserve_fund_increase_percent = null
  }

  return selfOwnedAttributes
}

export const buildHousingSelfOwnedSubmitValues = (
  selfOwnedAttributes: Partial<FutureV2HousingSelfOwned>,
) => {
  selfOwnedAttributes.repair_costs_span = Math.round(
    Number(selfOwnedAttributes.repair_costs_span!) * 12,
  )
  selfOwnedAttributes!.constructed_at = convertDateYear(
    selfOwnedAttributes.constructed_at_year,
  )
  selfOwnedAttributes.purchased_at = convertDateYear(
    selfOwnedAttributes.purchased_at_year,
  )
  selfOwnedAttributes.future_v2_housing_loans_attributes = buildSelfOwnedLoanSubmitValues(
    selfOwnedAttributes,
  )

  selfOwnedAttributes = convertHousingSelfOwnedNonRequiredValues(selfOwnedAttributes)

  if (!isMansionType(selfOwnedAttributes.house_type)) {
    selfOwnedAttributes.management_fee_monthly = null
    selfOwnedAttributes.repair_reserve_fund = null
    selfOwnedAttributes.management_fee_monthly_and_repair_reserve_fund_total = null
    selfOwnedAttributes.repair_reserve_fund_increase_type = null
  }

  return {
    ..._pick(selfOwnedAttributes, [
      'house_type',
      'housing_type2',
      'constructed_at',
      'purchased_at',
      'property_price_manyen',
      'property_price_land_manyen',
      'property_price_building_manyen',
      'tax_yearly',
      'repair_costs_span',
      'repair_costs',
      'repair_reserve_fund',
      'repair_reserve_fund_increase_type',
      'repair_reserve_fund_amount',
      'repair_reserve_fund_amount_start',
      'repair_reserve_fund_amount_finish',
      'repair_reserve_fund_increase',
      'repair_reserve_fund_increase_percent',
      'repair_reserve_fund_increase_start',
      'repair_reserve_fund_increase_finish',
      'repair_reserve_fund_increase_span',
      'management_fee_monthly',
      'management_fee_monthly_and_repair_reserve_fund_total',
      'other_cost_yearly',
      'loan_type1',
      'loan_type2',
      'miscellaneous_cost_manyen',
      'future_v2_housing_loans_attributes',
      'child_rearing_deduction',
    ]),
  }
}

export const buildHousingOtherSubmitValues = (
  otherAttributes: Partial<FutureV2HousingOther>,
) => {
  otherAttributes.future_v2_housing_other_costs_attributes! = otherAttributes.future_v2_housing_other_costs_attributes!.map(
    (
      item: Partial<FutureV2HousingOtherCost> & { custom_occurance_in_years: number },
    ) => ({
      ...item,
      custom_occurance_in_months:
        castNumberWithoutOperator(item.custom_occurance_in_years) * 12,
    }),
  )

  return {
    ..._pick(otherAttributes, ['future_v2_housing_other_costs_attributes']),
  }
}

export const buildHousingRentSubmitValues = (
  rentAttributes: Partial<FutureV2HousingRent>[],
) => {
  return rentAttributes.map((rent) => {
    rent.next_update_month_at = null
    rent.next_update_cost = null
    rent.whole_rent_monthly = null
    rent.whole_next_update_cost = null

    return _pick(rent, [
      'rent_monthly',
      'whole_rent_monthly',
      'rent_type',
      'next_update_month_at',
      'next_update_cost',
      'scheduled_at',
      'scheduled_at_age',
      'whole_next_update_cost',
      'living_start_at_age',
      'living_end_at_age',
    ])
  })
}
