import ApiClient from '../apiClient'
import {
  InputRisk,
  InputRiskCreateValues,
  InputRiskUpdateValues,
  InputRiskDestroyValues,
} from '@/models/inputs/inputRisksModel'

export const getInputRisks = async () => {
  return await ApiClient.get(
    '/input_risks',
    (response: InputRisk[]) => response,
    undefined,
    false,
    'v2',
  )
}

export const postInputRisk = async (values: InputRiskCreateValues) => {
  return await ApiClient.post('/input_risks', () => {}, values, undefined, 'v2')
}

export const patchInputRisk = async (values: InputRiskUpdateValues, id: number) => {
  return await ApiClient.patch(`/input_risks/${id}`, () => {}, values, undefined, 'v2')
}

export const deleteInputRisk = async (id: number) => {
  return await ApiClient.delete(
    `/input_risks/${id}`,
    () => {},
    undefined,
    undefined,
    'v2',
  )
}
