import React, { FC, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import moment from 'moment'
import { isNull as _isNull, isEmpty as _isEmpty, orderBy as _orderBy } from 'lodash'
import { patchAccountPayment, postAccountPayment } from '@/api/accountPayments'
import Button from '@/components/v2/atoms/Button'
import Link from '@/components/v2/atoms/Link'
import MarginContainer from '@/components/v2/atoms/MarginContainer'
import AccountSettingsTitle from '@/templates/v2/settings/commons/AccountSettingsTitle'
import Paper from '@/components/v2/atoms/Paper'
import Heading from '@/components/v2/atoms/Heading'
import Divider from '@/components/v2/atoms/Divider'
import Tooltip from '@/components/v2/atoms/Tooltip'
import Typography from '@/components/v2/atoms/Typography'
import Modal from '@/components/v2/atoms/Modal'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import {
  fetchAccount,
  fetchAccountPayment,
  updateAccount,
  updateAccountPassword,
} from '@/containers/accountsSlice'
import { setFlashAlert, removeAllFlashAlerts } from '@/containers/flashAlertsSlice'
import { createMtAccessTokenDisconnect } from '@/containers/mtAccessTokensSlice'
import { setPageMeta } from '@/containers/pageMetasSlice'
import { fetchPaymentHistories } from '@/containers/paymentHistoriesSlice'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import { convertManipulatedType } from '@/models/accountsModelFunc'
import { AccountUpdateValues, AccountPasswordUpdateValues } from '@/models/accountsModel'
import {
  convertLocaleString,
  convertPrefectures,
  convertMomentDate,
  extractDayFromDate,
  formatPostalCode,
  convertDateToJapaneseFormat,
} from '@/models/commonsModelFunc'
import { convertSex } from '@/models/familyStructuresModelFunc'
import { MT_CONNECTION_STATUS } from '@/models/mtAccessTokenModelFunc'
import { RST_TYPE, convertRstType } from '@/models/paymentHistoriesModelFunc'
import { RootState } from '@/store'
import AccountForm from '@/templates/v2/accounts/commons/AccountForm'
import AccountPasswordForm from '@/templates/v2/accounts/password/commons/AccountPasswordForm'
import CreditCardRegistrationForm from '@/templates/v2/settings/commons/CreditCardRegistrationForm'
import FpShareForm from '@/templates/v2/settings/commons/FpShareForm'
import FpManipulatedModalForm from '@/templates/v2/settings/commons/FpManipulatedModalForm'
import { getMoneytreeVaultUrl } from '@/utils/moneytree'
import { getStorageHasNoFamilyStructure } from '@/utils/localStorage/hasNoFamilyStructureStorage'
import { getStorageHasNoEnoughInputData } from '@/utils/localStorage/hasNoEnoughInputDataStorage'
import LoadingOverlay from '@/components/v1/atoms/LoadingOverlay'

const classes = {
  itemGroup: 'md:px-40',
  itemBlock: 'flex p-10 text-14 odd:bg-black-100',
  itemLabel: 'w-160 md:w-300',
  itemValue: 'flex-1',
}

const MT_CONNECTION_STATUS_MAP = {
  '': '',
  [MT_CONNECTION_STATUS.unconnected]: '未連携',
  [MT_CONNECTION_STATUS.error]: '連携エラー',
  [MT_CONNECTION_STATUS.connected]: '連携済み',
}

const Account: FC = () => {
  const dispatch = useDispatch()
  const { handleMovePage } = useCustomHistory()

  const [isAccountModalOpen, setIsAccountModalOpen] = useState<boolean>(false)
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState<boolean>(false)
  const [isFpShareModalOpen, setIsFpShareModalOpen] = useState<boolean>(false)
  const [isPaymentRegistrationModalOpen, setIsPaymentRegistrationModalOpen] = useState<
    boolean
  >(false)
  const [isPaymentRenewModalOpen, setIsPaymentRenewModalOpen] = useState<boolean>(false)
  const [isFpManipulatedModalOpen, setIsFpManipulatedModalOpen] = useState<boolean>(false)
  const [isLoadingCreditCard, setIsLoadingCreditCard] = useState<boolean>(false)
  const [warningMsgs, setWarningMsgs] = useState<{ id: string; label: string }[]>([])

  const { account, accountPayment } = useSelector((state: RootState) => state.accounts)
  const { paymentHistories } = useSelector((state: RootState) => state.paymentHistories)
  const { flashAlertMessageObjects } = useSelector(
    (state: RootState) => state.flashAlerts,
  )

  // Loading Flag
  const isLoadingAccounts = useSelector((state: RootState) => state.accounts.isLoading)
  const isLoadingPaymentHistories = useSelector(
    (state: RootState) => state.paymentHistories.isLoading,
  )

  const latestPaymentHistory = useMemo(() => {
    return _orderBy(paymentHistories, 'id', 'desc')[0]
  }, [paymentHistories])

  const hasRegisteredPaymentMethod = useMemo(
    () => account['has_registered_payment_method?'],
    [account],
  )
  const disclosedTypeText = useMemo(() => {
    switch (account?.disclosed_type) {
      case 'not_disclosed':
        return '共有しない'
      case 'limited':
        return `${convertDateToJapaneseFormat(
          account?.disclosed_stop_at,
          'fullDate',
        )}まで共有する`
      case 'forever':
        return '期間の制限なしで共有する'
      default:
        return ''
    }
  }, [account])

  const ThreeMonthLaterDateText = moment().add(3, 'months').format('YYYY/MM/DD')

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: '個人設定',
        description: '個人設定',
        keyword: '個人設定',
      }),
    )
    if ((account?.id ?? 0) < 1) dispatch(fetchAccount())
    dispatch(fetchAccountPayment())
    dispatch(fetchPaymentHistories())
  }, [])

  useEffect(() => {
    const warningMsgs = []

    if (account['is_disabled_trial_account?']) {
      warningMsgs.push({
        id: 'disabled-trial',
        label: 'トライアル期間が終了しています。料金プラン設定の更新をお願いします。',
      })
    }

    if (account['is_disabled_paid_account?']) {
      warningMsgs.push({
        id: 'disabled-paid',
        label: '決済が失敗しています。決済情報をご確認の上、決済を完了させてください。',
      })
    }

    setWarningMsgs(warningMsgs)
  }, [account])

  const showFlashMsg = (
    type:
      | 'account'
      | 'password'
      | 'disconnect'
      | 'fpShare'
      | 'registerCard'
      | 'renewCard'
      | 'cardError'
      | 'cardRenewError'
      | 'deactivated'
      | 'deactivateError',
    message?: string,
  ) => {
    if (!_isEmpty(flashAlertMessageObjects)) {
      dispatch(removeAllFlashAlerts())
    }
    let alertLabel = ''
    switch (type) {
      case 'account':
        alertLabel = 'アカウント情報を更新しました'
        break
      case 'password':
        alertLabel = 'パスワードを更新しました'
        break
      case 'disconnect':
        alertLabel = 'Moneytreeとの連携が解除されました'
        break
      case 'fpShare':
        alertLabel = 'FPとの共有設定を更新しました'
        break
      case 'registerCard':
        alertLabel = 'カード情報を登録しました'
        break
      case 'renewCard':
        alertLabel = 'カード情報を更新しました'
        break
      case 'cardError':
        alertLabel = 'カード情報登録に失敗しました'
        break
      case 'cardRenewError':
        alertLabel = 'カード情報更新に失敗しました'
        break
      case 'deactivated':
        alertLabel = message!
        break
      case 'deactivateError':
        alertLabel = message!
        break
    }

    dispatch(
      setFlashAlert({
        flashAlertMessageObjects: [
          {
            message: alertLabel,
            variant:
              type === 'cardError' || type === 'deactivateError' ? 'error' : 'success',
          },
        ],
      }),
    )
  }

  const paymentHistoryPc = () => (
    <table className="hidden md:block w-full">
      <thead>
        <tr className="text-black-900 text-left border-b border-black-300">
          <th className="w-[20%] pr-30">支払い日</th>
          <th className="w-[20%] pr-30">支払いステータス</th>
          <th className="w-[30%] pr-30">支払い方法</th>
          <th className="w-[30%]">料金</th>
        </tr>
      </thead>
      <tbody className="text-14">
        <tr className="border-b border-black-300">
          <td className="py-20 pr-30">
            <Typography>
              {convertMomentDate(latestPaymentHistory.created_at, 'YYYY/MM/DD')}
            </Typography>
          </td>
          <td className="py-20 pr-30">
            <Typography
              className={clsx({
                'text-primary-500': latestPaymentHistory.rst_type === RST_TYPE.success,
                'text-alert-600': latestPaymentHistory.rst_type === RST_TYPE.error,
              })}
            >
              {convertRstType(latestPaymentHistory.rst_type)}
            </Typography>
          </td>
          <td className="py-20 pr-30">
            <Typography>クレジットカード</Typography>
          </td>
          <td className="py-20">
            <Typography>{convertLocaleString(latestPaymentHistory.ta)}円</Typography>
          </td>
        </tr>
      </tbody>
    </table>
  )

  const paymentHistorySp = () => (
    <div className="flex flex-col md:hidden pb-24 space-y-16 border-b border-black-300">
      <div className="flex items-center space-x-12 text-14">
        <Typography isBold>支払い日</Typography>
        <Typography className="flex-1">
          {convertMomentDate(latestPaymentHistory.created_at, 'YYYY/MM/DD')}
        </Typography>
      </div>
      <div className="flex items-center space-x-12 text-14">
        <Typography isBold>支払いステータス</Typography>
        <Typography
          className={clsx('flex-1', {
            'text-primary-500': latestPaymentHistory.rst_type === RST_TYPE.success,
            'text-alert-600': latestPaymentHistory.rst_type === RST_TYPE.error,
          })}
        >
          {convertRstType(latestPaymentHistory.rst_type)}
        </Typography>
      </div>
      <div className="flex items-center space-x-12 text-14">
        <Typography isBold>クレジットカード</Typography>
        <Typography className="flex-1">クレジットカード</Typography>
      </div>
      <div className="flex items-center space-x-12 text-14">
        <Typography isBold>料金</Typography>
        <Typography className="flex-1">
          {convertLocaleString(latestPaymentHistory.ta)}円
        </Typography>
      </div>
    </div>
  )

  const handleMoveHelpPage = () => {
    window.open(
      'https://help.getmoneytree.com/ja/articles/6027639-moneytree-link-%E4%B8%80%E9%83%A8%E3%81%AE%E9%8A%80%E8%A1%8C%E3%81%AE%E6%9B%B4%E6%96%B0%E9%A0%BB%E5%BA%A6%E3%81%AE%E5%A4%89%E6%9B%B4%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6',
    )
  }

  const handleMoveVaultPage = () => {
    const url = getMoneytreeVaultUrl('/v2/settings/account')
    window.open(url)
  }

  const handleAccountSubmit = (values: AccountUpdateValues) => {
    return new Promise((resolve) => {
      dispatch(
        updateAccount(
          values,
          () => {
            dispatch(fetchAccount())
            setIsAccountModalOpen(false)
            showFlashMsg('account')
          },
          resolve,
        ),
      )
    })
  }

  const handlePasswordSubmit = (values: AccountPasswordUpdateValues) => {
    return new Promise((resolve) => {
      dispatch(
        updateAccountPassword(
          values,
          () => {
            dispatch(fetchAccount())
            setIsPasswordModalOpen(false)
            showFlashMsg('password')
          },
          resolve,
        ),
      )
    })
  }

  const handleCreditRegistrationOpen = () => {
    setIsPaymentRegistrationModalOpen(true)
  }

  const handleCreditRenewOpen = () => {
    setIsPaymentRenewModalOpen(true)
  }

  const _handleSubmitCreditCardForm = (
    values: any,
    callback: (values: any, token: string) => void,
  ) => {
    setIsLoadingCreditCard(true)

    const w: any = window as any
    w.CPToken.TokenCreate(
      {
        aid: '120009',
        cn: values.cn,
        ed: `${values.ed_year}${values.ed_month}`,
        fn: values.fn,
        ln: values.ln,
        cvv: values.cvv,
      },
      (resultCode: any, errMsg: any) => {
        if (resultCode != 'Success') {
          window.alert(`カード情報の登録に失敗しました。 ${errMsg}`)
          setIsLoadingCreditCard(false)
          return
        }
        const tokenEle: any = document?.getElementById('tkn')
        const token: string = tokenEle.value
        callback(values, token)
      },
    )
  }

  const handleSubmitCreditCardRegistrationForm = (values: any) => {
    _handleSubmitCreditCardForm(values, (values, token) => {
      postAccountPayment({
        account_payment_information: {
          robot_payment_token: token,
          last_four_digits: values.cn.substr(-4),
          expire_month: values.ed_month,
          expire_year: values.ed_year,
        },
      })
        .then(() => {
          dispatch(fetchAccount())
          setIsPaymentRegistrationModalOpen(false)
          showFlashMsg('registerCard')
          setIsLoadingCreditCard(false)
        })
        .catch(() => {
          setIsPaymentRegistrationModalOpen(false)
          showFlashMsg('cardError')
          setIsLoadingCreditCard(false)
        })
    })
  }

  const handleSubmitCreditCardRenewForm = (values: any) => {
    _handleSubmitCreditCardForm(values, (values, token) => {
      patchAccountPayment({
        account_payment_information: {
          robot_payment_token: token,
          last_four_digits: values.cn.substr(-4),
          expire_month: values.ed_month,
          expire_year: values.ed_year,
        },
      })
        .then(() => {
          dispatch(fetchAccount())
          setIsPaymentRenewModalOpen(false)
          showFlashMsg('renewCard')
          setIsLoadingCreditCard(false)
        })
        .catch(() => {
          setIsPaymentRenewModalOpen(false)
          showFlashMsg('cardRenewError')
          setIsLoadingCreditCard(false)
        })
    })
  }

  const handleSubmitFpShareForm = (values: AccountUpdateValues) => {
    const newValues = {
      disclosed_stop_at: ThreeMonthLaterDateText,
      disclosed_type: values.disclosed_type,
    }

    return new Promise((resolve) => {
      dispatch(
        updateAccount(
          newValues,
          () => {
            dispatch(fetchAccount())
            setIsFpShareModalOpen(false)
            showFlashMsg('fpShare')
          },
          resolve,
        ),
      )
    })
  }

  const handleMtAccessTokenDisconnect = () => {
    dispatch(
      createMtAccessTokenDisconnect(() => {
        dispatch(fetchAccount())
        showFlashMsg('disconnect')
      }),
    )
  }

  const handleMtConnection = () => {
    const hasNoFamilyStructure = getStorageHasNoFamilyStructure()
    const hasNoEnoughInputData = getStorageHasNoEnoughInputData()
    const isRegistrationCompleted = !hasNoFamilyStructure && !hasNoEnoughInputData

    window.localStorage.isFromSettingPage = true
    handleMovePage('/v2/plans/household')
  }

  const renderMtConnectionButton = () => {
    switch (account.mt_connection_status) {
      case MT_CONNECTION_STATUS.unconnected:
        return (
          <ButtonPrimary
            className="h-45 w-full md:w-215 text-white"
            onClick={handleMtConnection}
          >
            <Typography className="bottom-2 flex-auto pl-30 relative text-16" isBold>
              接続する
            </Typography>
            <i className="ico chevron-right mr-17 text-24" />
          </ButtonPrimary>
        )
      case MT_CONNECTION_STATUS.error:
        return (
          <Tooltip
            itemClassName="bg-secondary-50 leading-tight p-12 text-14 w-[268px] text-black-800 before:border-t-secondary-50"
            item="エラー時は、接続を一旦解除してから接続し直してください"
          >
            <ButtonPrimary
              className="h-45 w-full md:w-215 text-white"
              onClick={handleMtAccessTokenDisconnect}
            >
              <Typography className="bottom-2 flex-auto pl-30 relative text-16" isBold>
                接続解除
              </Typography>
              <i className="ico chevron-right mr-17 text-24" />
            </ButtonPrimary>
          </Tooltip>
        )
      case MT_CONNECTION_STATUS.connected:
        return (
          <ButtonPrimary
            className="h-45 w-full md:w-215 text-white"
            onClick={handleMtAccessTokenDisconnect}
          >
            <Typography className="bottom-2 flex-auto pl-30 relative text-16" isBold>
              接続解除
            </Typography>
            <i className="ico chevron-right mr-17 text-24" />
          </ButtonPrimary>
        )
      default:
        return null
    }
  }

  return (
    <>
      <LoadingOverlay isLoading={isLoadingPaymentHistories || isLoadingAccounts} />
      <MarginContainer className="py-40">
        <div className="mx-8">
          <AccountSettingsTitle />
          <div className="mb-30 space-y-8">
            {warningMsgs.map((item, index) => (
              <div
                key={index}
                className="flex items-center px-16 py-12 text-alert-600 rounded-4 bg-alert-400 border border-alert-600"
              >
                <i className="ico info mr-12 text-16" />
                <Typography className="text-14" isBold>
                  {item.label}
                </Typography>
              </div>
            ))}
          </div>
          <Paper className="px-16 py-24 sm:p-40 space-y-40 bg-white">
            <section>
              <div className="flex justify-between">
                <Heading as="h2" size={2}>
                  個人設定
                </Heading>
                <Button
                  className="h-37 w-130 px-17 text-secondary-500 border border-secondary-500 rounded-8"
                  onClick={() => setIsAccountModalOpen(true)}
                >
                  <i className="ico edit text-24" />
                  <Typography
                    className="ml-10 bottom-2 flex-auto relative text-14"
                    isBold
                  >
                    編集する
                  </Typography>
                </Button>
              </div>
              <Divider className="border-black-300 border-t-2 mt-10 pb-20 md:pb-24" />
              <div className={classes.itemGroup}>
                <div className={classes.itemBlock}>
                  <Typography className={classes.itemLabel} isBold>
                    メールアドレス
                  </Typography>
                  <Typography className={classes.itemValue}>
                    {account.mail_address}
                  </Typography>
                </div>
                <div className={classes.itemBlock}>
                  <Typography className={classes.itemLabel} isBold>
                    お名前
                  </Typography>
                  <Typography className={classes.itemValue}>
                    {account.name_sei} {account.name_mei}
                  </Typography>
                </div>
                <div className={classes.itemBlock}>
                  <Typography className={classes.itemLabel} isBold>
                    ふりがな
                  </Typography>
                  <Typography className={classes.itemValue}>
                    {account.name_sei_kana} {account.name_mei_kana}
                  </Typography>
                </div>
                <div className={classes.itemBlock}>
                  <Typography className={classes.itemLabel} isBold>
                    性別
                  </Typography>
                  <Typography className={classes.itemValue}>
                    {convertSex(account.sex)}
                  </Typography>
                </div>
                <div className={classes.itemBlock}>
                  <Typography className={classes.itemLabel} isBold>
                    電話番号
                  </Typography>
                  <Typography
                    className={clsx(classes.itemValue, {
                      'text-black-700': _isNull(account.tel_number),
                    })}
                  >
                    {_isNull(account.tel_number) ? '（未登録）' : account.tel_number}
                  </Typography>
                </div>
                <div className={classes.itemBlock}>
                  <Typography className={classes.itemLabel} isBold>
                    郵便番号
                  </Typography>
                  <Typography
                    className={clsx(classes.itemValue, {
                      'text-black-700': _isNull(account.postal_code),
                    })}
                  >
                    {_isNull(account.postal_code)
                      ? '（未登録）'
                      : formatPostalCode(account.postal_code)}
                  </Typography>
                </div>
                <div className={classes.itemBlock}>
                  <Typography className={classes.itemLabel} isBold>
                    都道府県
                  </Typography>
                  <Typography
                    className={clsx(classes.itemValue, {
                      'text-black-700': _isNull(account.prefectures),
                    })}
                  >
                    {_isNull(account.prefectures)
                      ? '（未登録）'
                      : convertPrefectures(account.prefectures)}
                  </Typography>
                </div>
                <div className={classes.itemBlock}>
                  <Typography className={classes.itemLabel} isBold>
                    市区町村
                  </Typography>
                  <Typography
                    className={clsx(classes.itemValue, {
                      'text-black-700': _isNull(account.address1),
                    })}
                  >
                    {_isNull(account.address1) ? '（未登録）' : account.address1}
                  </Typography>
                </div>
                <div className={classes.itemBlock}>
                  <Typography className={classes.itemLabel} isBold>
                    以降の住所
                  </Typography>
                  <Typography
                    className={clsx(classes.itemValue, {
                      'text-black-700': _isNull(account.address2),
                    })}
                  >
                    {_isNull(account.address2) ? '（未登録）' : account.address2}
                  </Typography>
                </div>
              </div>
            </section>
            <section>
              <div className="flex justify-between">
                <Heading as="h2" size={2}>
                  パスワード
                </Heading>
                <Button
                  className="h-37 w-130 px-17 text-secondary-500 border border-secondary-500 rounded-8"
                  onClick={() => setIsPasswordModalOpen(true)}
                >
                  <i className="ico edit text-24" />
                  <Typography
                    className="ml-10 bottom-2 flex-auto relative text-14"
                    isBold
                  >
                    編集する
                  </Typography>
                </Button>
              </div>
              <Divider className="border-black-300 border-t-2 mt-10 pb-20 md:pb-24" />
              <div className={classes.itemGroup}>
                <div className={classes.itemBlock}>
                  <Typography className={classes.itemLabel}>パスワード</Typography>
                  <Typography className={classes.itemValue}>●●●●●●●●</Typography>
                </div>
              </div>
            </section>
            <section>
              <div className="flex justify-between">
                <Heading as="h2" size={2}>
                  Moneytree連携
                </Heading>
              </div>
              <Divider className="border-black-300 border-t-2 mt-10 pb-20 md:pb-24" />
              <div className={classes.itemGroup}>
                <div className={classes.itemBlock}>
                  <Typography className={classes.itemLabel}>連携ステータス</Typography>
                  <Typography className={classes.itemValue}>
                    {MT_CONNECTION_STATUS_MAP[account.mt_connection_status ?? '']}
                  </Typography>
                </div>
                <div className="flex justify-center mt-24">
                  {renderMtConnectionButton()}
                </div>
                {account.mt_connection_status === MT_CONNECTION_STATUS.connected && (
                  <div className="mt-24 text-14 text-black-700">
                    <Typography isBold>留意事項</Typography>
                    <div className="md:ml-10">
                      <Typography>
                        ・Moneytreeの情報は1日1回更新されます。Moneytreeでの金融機関の情報取得頻度については
                        <Link
                          className="text-secondary-500 text-14 stroke-current hover:underline"
                          children="こちら"
                          onClick={handleMoveHelpPage}
                        />
                        をご確認ください。
                      </Typography>
                      <Typography>
                        ・情報がうまく取得されない場合、
                        <Link
                          className="text-secondary-500 text-14 stroke-current hover:underline"
                          children="こちら"
                          onClick={handleMoveVaultPage}
                        />
                        のページからMoneytreeの金融機関の取得状況をご確認ください。
                      </Typography>
                    </div>
                  </div>
                )}
              </div>
            </section>
            <section>
              <Heading as="h2" size={2}>
                料金プラン
              </Heading>
              <Divider className="border-black-300 border-t-2 mt-10 pb-20 md:pb-24" />
              <div className={classes.itemGroup}>
                {hasRegisteredPaymentMethod ? (
                  <>
                    <div className={classes.itemBlock}>
                      <Typography className="w-[140px] md:w-300" isBold>
                        ご契約中のプラン
                      </Typography>
                      <Typography className={classes.itemValue}>
                        有料プラン（自動更新）
                      </Typography>
                    </div>
                    <div className={classes.itemBlock}>
                      <Typography className="w-[140px] md:w-300" isBold>
                        料金
                      </Typography>
                      <Typography className={classes.itemValue}>
                        月額{account.account_payment_contract?.amount_fee_with_tax}
                        円（税込）
                      </Typography>
                    </div>
                    <div className={classes.itemBlock}>
                      <Typography className="w-[140px] md:w-300" isBold>
                        引き落とし日
                      </Typography>
                      <Typography className={classes.itemValue}>
                        毎月{account.account_payment_contract?.execution_date_in_month}
                        日に翌１ヶ月分を引き落とし
                      </Typography>
                    </div>
                    <div className={classes.itemBlock}>
                      <Typography className="w-[140px] md:w-300" isBold>
                        クレジットカード
                      </Typography>
                      <Typography className={classes.itemValue}>
                        {account.account_payment_information?.masked_card_num}
                        <br />
                        {account.account_payment_information?.expire_year}/
                        {account.account_payment_information?.expire_month}
                      </Typography>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={classes.itemBlock}>
                      <Typography className={classes.itemLabel} isBold>
                        ご契約中のプラン
                      </Typography>
                      <Typography className={classes.itemValue}>
                        フリートライアル（
                        {convertDateToJapaneseFormat(account.trial_end_at, 'fullDate')}
                        まで）
                      </Typography>
                    </div>
                    <div className={classes.itemBlock}>
                      <Typography className={classes.itemLabel} isBold>
                        料金
                      </Typography>
                      <Typography className={classes.itemValue}>0円</Typography>
                    </div>
                  </>
                )}
                <div className="grid md:grid-cols-3 mt-24">
                  <div />
                  <ButtonPrimary
                    className="h-45 w-full md:w-215 mx-auto text-white"
                    onClick={
                      hasRegisteredPaymentMethod
                        ? handleCreditRenewOpen
                        : handleCreditRegistrationOpen
                    }
                  >
                    <Typography
                      className="bottom-2 flex-auto pl-30 relative text-16"
                      isBold
                    >
                      {hasRegisteredPaymentMethod ? '編集する' : 'プランを変更する'}
                    </Typography>
                    <i className="ico chevron-right mr-17 text-24" />
                  </ButtonPrimary>
                  <div className="flex justify-center md:justify-end items-center mt-24 md:mt-0">
                    <Link
                      className="flex text-black-700"
                      onClick={() => handleMovePage('/v2/settings/accountDeactivate')}
                    >
                      解約する
                      <i className="ico chevron-right mr-17 text-24" />
                    </Link>
                  </div>
                </div>
              </div>
            </section>
            {!account['is_trial_account?'] && (
              <section>
                <Heading as="h2" size={2}>
                  支払い履歴
                </Heading>
                <Divider className="border-black-300 border-t-2 mt-10 pb-20 md:pb-24" />
                <div className={classes.itemGroup}>
                  {paymentHistories.length > 0 ? (
                    <>
                      {paymentHistoryPc()}
                      {paymentHistorySp()}
                    </>
                  ) : (
                    <Typography className="text-14">支払い履歴はありません</Typography>
                  )}
                </div>
              </section>
            )}
            <section>
              <Heading as="h2" size={2}>
                FPとの情報共有
              </Heading>
              <Divider className="border-black-300 border-t-2 mt-10 pb-20 md:pb-24" />
              <div className={classes.itemGroup}>
                <div className="md:flex md:space-x-16 space-y-16 md:space-y-0">
                  <div className={clsx(classes.itemBlock, 'flex-1')}>
                    <Typography className="w-120 md:w-300" isBold>
                      現在の共有状況
                    </Typography>
                    <Typography>{disclosedTypeText}</Typography>
                  </div>
                  <Button
                    className="h-37 w-full md:w-130 px-17 text-secondary-500 border border-secondary-500 rounded-8"
                    onClick={() => setIsFpShareModalOpen(true)}
                  >
                    <i className="ico edit text-24" />
                    <Typography
                      className="ml-10 bottom-2 flex-auto relative text-14"
                      isBold
                    >
                      編集する
                    </Typography>
                  </Button>
                </div>
                <Typography className="mt-16 text-14 text-black-700">
                  FPとの共有を行っていない状態では、あなたの情報は共有されません。
                  <br />
                  FPと個別相談をしている時や、アドバイスが欲しいと思った時は、共有することでFPが閲覧し、
                  <br />
                  分析結果のアドバイスを受けることができるようになります。
                  <br />
                  ※FPは、アルファ・ファイナンシャルプランナーズの教育と試験を合格した者のみ対応させていただきます。
                  <br />
                  ※対応件数超過等により、アドバイスが無い場合もあります。
                </Typography>
              </div>
              <div className={clsx(classes.itemGroup, 'mt-24')}>
                <div className="md:flex md:space-x-16 space-y-16 md:space-y-0">
                  <div className={clsx(classes.itemBlock, 'flex-1')}>
                    <Typography className="w-[138px] md:w-300" isBold>
                      FP側での操作の許可
                    </Typography>
                    <Typography>
                      {convertManipulatedType(
                        account.manipulated_type,
                        account.manipulated_stop_at,
                      )}
                    </Typography>
                  </div>
                  <Button
                    className="h-37 w-full md:w-130 px-17 text-secondary-500 border border-secondary-500 rounded-8"
                    onClick={() => setIsFpManipulatedModalOpen(true)}
                  >
                    <i className="ico edit text-24" />
                    <Typography
                      className="ml-10 bottom-2 flex-auto relative text-14"
                      isBold
                    >
                      編集する
                    </Typography>
                  </Button>
                </div>
                <Typography className="mt-16 text-14 text-black-700">
                  操作を許可していない場合は、FP側であなたの情報を操作することができません。
                  <br />
                  操作を許可している場合、FP側であなたの現在の情報やシミュレーションの情報を追加、編集することができます。
                </Typography>
              </div>
            </section>
          </Paper>

          <Modal
            className="w-[95%] md:w-[760px]"
            isOpen={isAccountModalOpen}
            onClose={() => setIsAccountModalOpen(false)}
          >
            <AccountForm
              title="会員編集"
              buttonLabel="更新する"
              type="edit"
              handleSubmit={handleAccountSubmit}
            />
          </Modal>

          <Modal
            className="w-[95%] md:w-[760px]"
            isOpen={isPasswordModalOpen}
            onClose={() => setIsPasswordModalOpen(false)}
          >
            <AccountPasswordForm
              title="会員パスワード編集"
              description={<>新しいパスワードを入力してください。</>}
              isEdit
              handleSubmit={handlePasswordSubmit}
            />
          </Modal>

          <Modal
            className="w-[95%] md:w-[760px]"
            isOpen={isPaymentRegistrationModalOpen}
            onClose={() => setIsPaymentRegistrationModalOpen(false)}
          >
            <CreditCardRegistrationForm
              title="有料プラン登録"
              accountPayment={accountPayment}
              isLoading={isLoadingCreditCard}
              handleSubmit={handleSubmitCreditCardRegistrationForm}
            />
          </Modal>

          <Modal
            className="w-[95%] md:w-[760px]"
            isOpen={isPaymentRenewModalOpen}
            onClose={() => setIsPaymentRenewModalOpen(false)}
          >
            <CreditCardRegistrationForm
              title="クレジットカード変更"
              accountPayment={accountPayment}
              isEdit
              isLoading={isLoadingCreditCard}
              handleSubmit={handleSubmitCreditCardRenewForm}
            />
          </Modal>

          <Modal
            className="w-[95%] md:w-[760px]"
            isOpen={isFpShareModalOpen}
            onClose={() => setIsFpShareModalOpen(false)}
          >
            <FpShareForm
              title="FPとの共有設定"
              dateText={ThreeMonthLaterDateText}
              initialValues={{
                disclosed_stop_at: account?.disclosed_stop_at,
                disclosed_type: account?.disclosed_type,
              }}
              handleSubmit={handleSubmitFpShareForm}
            />
          </Modal>

          <FpManipulatedModalForm
            deadlineDate={ThreeMonthLaterDateText}
            isOpen={isFpManipulatedModalOpen}
            handleClose={() => setIsFpManipulatedModalOpen(false)}
          />
        </div>
      </MarginContainer>
    </>
  )
}

export default Account
