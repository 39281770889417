import ApiClient from '@/api/apiClient'
import {
  LearningVideoCategoryResponse,
  LearningVideoCategoryCreateValues,
  LearningVideoCategoryUpdateValues,
} from '@/models/admins/learningVideoCategoriesModel'

export const getLearningVideoCategories = async () => {
  return await ApiClient.get(
    '/learning_video_categories',
    (response: LearningVideoCategoryResponse[]) => response,
  )
}

export const getLearningVideoCategory = async (id: number) => {
  return await ApiClient.get(
    `/learning_video_categories/${id}`,
    (response: LearningVideoCategoryResponse) => response,
  )
}

export const postLearningVideoCategory = async (
  values: LearningVideoCategoryCreateValues,
) => {
  return await ApiClient.post('/learning_video_categories', () => {}, values)
}

export const patchLearningVideoCategory = async (
  id: number,
  values: LearningVideoCategoryUpdateValues,
) => {
  return await ApiClient.patch(`/learning_video_categories/${id}`, () => {}, values)
}

export const deleteLearningVideoCategory = async (id: number) => {
  return await ApiClient.delete(`/learning_video_categories/${id}`, () => {})
}
