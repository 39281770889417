import { createContext } from 'react'
import { MANIPULATED_TYPE } from '@/constants/formItem/accounts'
import { AccountStateType, ManipulatedType } from '@/models/accountsModel'
import { convertDateToJapaneseFormat } from '@/models/commonsModelFunc'

interface AccountContextValue {
  /** true: 管理者, false: 一般 */
  isAdmin: boolean
}

export const AccountContext = createContext<AccountContextValue>({
  isAdmin: false,
})

export const convertManipulatedType = (
  manipulatedType: ManipulatedType | null,
  manipulated_stop_at: string | null,
) => {
  switch (manipulatedType) {
    case MANIPULATED_TYPE.notManipulated:
      return '許可しない'
    case MANIPULATED_TYPE.limited:
      return `今日から3ヶ月後の${convertDateToJapaneseFormat(
        manipulated_stop_at,
        'fullDate',
      )}まで許可する`
    case MANIPULATED_TYPE.forever:
      return '期間の制限なしで許可する'
    default:
      return ''
  }
}

export const isAccountDefault = (account: AccountStateType) => {
  return account.id == 0 && account.mail_address == '' && account.name_sei == ''
}
