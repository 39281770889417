import _axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from 'axios'
import {
  isUndefined as _isUndefined,
  split as _split,
  isNaN as _isNaN,
  includes as _includes,
} from 'lodash'
import { AccountAuthHeaderValues } from '@/models/accountsModel'
import { FpAccountAuthHeaderValues } from '@/models/admins/fpAccountsModel'
import { VideoPlatformAuthHeaderValues } from '@/models/videoPlatforms/authenticationsModel'
import { ApiError } from '@/models/commonsModel'

class ApiClient {
  static authHeaders: Partial<AccountAuthHeaderValues> = {}

  static create(isDefaultBaseURL?: boolean, version?: string) {
    const axios = _axios.create({
      baseURL: isDefaultBaseURL ? process.env.API_URL : this.setBaseURL(version),
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...this.authHeaders,
      },
      responseType: 'json',
    })
    axios.interceptors.request.use(
      (config) => this.requestSuccess(config),
      (config) => this.requestFailure(config),
    )
    axios.interceptors.response.use(
      (config) => this.responseSuccess(config),
      (config) => this.responseFailure(config),
    )
    return axios
  }

  static setBaseURL(version: string = 'v1') {
    const pathname = _split(location.pathname, '/')

    if (
      pathname[1] === 'movies' ||
      (pathname[1] === 'v2' && pathname[2] === 'movies') ||
      // MEMO: マネソルから動画プラットフォームへ行く際にログインボタンで
      //       authenticationを叩くための条件
      (pathname[1] === 'plans' && pathname[2] === 'learningContent')
    ) {
      return `${process.env.API_URL}/video_platform_api/${version}/app`
    }

    if (pathname[1] === 'admins' && !_includes(location.pathname, 'v2')) {
      const accountId = Number(pathname[3])

      if (pathname[2] === 'accounts' && !_isNaN(accountId)) {
        return `${process.env.API_URL}/admin_api/${version}/app/account/${accountId}`
      } else {
        return `${process.env.API_URL}/admin_api/${version}/app`
      }
    }

    if (pathname[1] === 'admins' && _includes(location.pathname, 'v2')) {
      const accountId = Number(pathname[4])

      if (pathname[3] === 'accounts' && !_isNaN(accountId)) {
        return `${process.env.API_URL}/admin_api/${version}/app/account/${accountId}`
      } else {
        return `${process.env.API_URL}/admin_api/${version}/app`
      }
    }

    return `${process.env.API_URL}/api/${version}/app`
  }

  static requestSuccess(config: AxiosRequestConfig) {
    if (process.env.NODE_ENV !== 'production') {
      this.logger('// REQUEST SUCCESS', config)
    }
    // MEMO: Request(成功)時の共通処理があればココに書く
    return config
  }

  static requestFailure(config: AxiosRequestConfig) {
    if (process.env.NODE_ENV !== 'production') {
      this.logger('// REQUEST FAILURE', config)
    }
    // MEMO: Request(失敗)時の共通処理があればココに書く
    return Promise.reject(config)
  }

  static responseSuccess(config: AxiosResponse<any>) {
    if (process.env.NODE_ENV !== 'production') {
      this.logger('// RESPONCE SUCCESS', config)
    }
    // MEMO: Response(成功)時の共通処理があればココに書く
    return config
  }

  static responseFailure(config: AxiosError) {
    if (process.env.NODE_ENV !== 'production') {
      this.logger('// RESPONCE FAILURE', config)
    }
    // MEMO: Response(失敗)時の共通処理があればココに書く
    const response = config.response
    let responseBase: ApiError = {}

    switch (response?.status) {
      case 400:
        responseBase = response.data.api_errors
        break
      case 401:
        if (!_isUndefined(response.data.api_errors)) {
          responseBase = response.data.api_errors
        }
        responseBase.isUnauthorized = true
        break
      case 402:
        responseBase.isPaymentRequired = true
        break
      case 500:
        responseBase.other = 'システムエラーが発生しました。'
        break
      default:
        responseBase.other = '通信エラーが発生しました。'
    }

    return Promise.reject(responseBase)
  }

  static logger(
    label: string,
    res: AxiosRequestConfig | AxiosResponse<any> | AxiosError,
  ) {
    if (process.env.NODE_ENV !== 'production' && !process.env.DISABLE_API_CLIENT_LOG) {
      console.groupCollapsed(label)
      console.dir(res)
      console.groupEnd()
    }
  }

  static async get(
    url: string,
    callback: any,
    option?: any,
    isDefaultBaseURL?: boolean,
    version?: string,
  ) {
    const client = this.create(isDefaultBaseURL, version)
    return await client
      .get(url, option)
      .then((response) => {
        return callback(response.data)
      })
      .catch((error) => {
        console.log('ERROR!! occurred in Backend.')
        return Promise.reject(error)
      })
  }

  static async post(
    url: string,
    callback: any,
    option?: any,
    isDefaultBaseURL?: boolean,
    version?: string,
  ) {
    const client = this.create(isDefaultBaseURL, version)
    return await client
      .post(url, option)
      .then((response) => {
        return callback(response.data)
      })
      .catch((error) => {
        console.log('ERROR!! occurred in Backend.')
        return Promise.reject(error)
      })
  }

  static async patch(
    url: string,
    callback: any,
    option?: any,
    isDefaultBaseURL?: boolean,
    version?: string,
  ) {
    const client = this.create(isDefaultBaseURL, version)
    return await client
      .patch(url, option)
      .then((response) => {
        return callback(response.data)
      })
      .catch((error) => {
        console.log('ERROR!! occurred in Backend.')
        return Promise.reject(error)
      })
  }

  static async delete(
    url: string,
    callback: any,
    option?: any,
    isDefaultBaseURL?: boolean,
    version?: string,
  ) {
    const client = this.create(isDefaultBaseURL, version)
    return await client
      .delete(url, option)
      .then((response) => {
        return callback(response.data)
      })
      .catch((error) => {
        console.log('ERROR!! occurred in Backend.')
        return Promise.reject(error)
      })
  }

  static setAuthHeaders(authHeaders: Partial<AccountAuthHeaderValues>) {
    this.authHeaders = authHeaders
  }

  static setAdminAuthHeaders(authHeaders: Partial<FpAccountAuthHeaderValues>) {
    this.authHeaders = authHeaders
  }

  static setVideoPlatformsAuthHeaders(
    authHeaders: Partial<VideoPlatformAuthHeaderValues>,
  ) {
    this.authHeaders = authHeaders
  }
}

export default ApiClient
