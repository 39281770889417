import { API_FLAG } from '@/models/commonsModelFunc'
import { InputLoan } from '@/models/inputs/inputLoansModel'
import { isWithCalcLoan } from '../commonHousingModelFunc'

// ローン方式
export const INPUT_LOAN_LOAN_TYPE = {
  personOnly: 0,
  spouseOnly: 1,
  pairLoan: 2,
  combined: 3,
}

// ローン借り入れ先
export const INPUT_LOAN_LOAN_LENDER = {
  flat: 0,
  private: 1,
}

// ローンタイプ(土地/建物)
export const INPUT_LOAN_LOAN_FOR = {
  land: 'land',
  building: 'building',
}

// 単独ローン and (返済計画表あり or 返済計画表なし・民間ローン)
export const isOneLoanPattern = (inputLoan: Partial<InputLoan> | undefined) => {
  return (
    !isPairLoan(inputLoan?.loan_type) &&
    (isWithChartLoan(inputLoan?.repayment_chart) ||
      (!isFlatLoan(inputLoan?.loan_lender) && isWithCalcLoan(inputLoan?.repayment_chart)))
  )
}

// 返済計画表なし・ペアローン/フラット
export const isTowLoanWithCalculation = (inputLoan: Partial<InputLoan> | undefined) => {
  return (
    isWithCalcLoan(inputLoan?.repayment_chart) &&
    (isPairLoan(inputLoan?.loan_type) || isFlatLoan(inputLoan?.loan_lender))
  )
}

// 返済計画表あり・ペアローン
export const isTwoLoanWithChart = (inputLoan: Partial<InputLoan> | undefined) => {
  return isWithChartLoan(inputLoan?.repayment_chart) && isPairLoan(inputLoan?.loan_type)
}

export const isTwoLoanPattern = (inputLoan: Partial<InputLoan> | undefined) => {
  return isTwoLoanWithChart(inputLoan) || isTowLoanWithCalculation(inputLoan)
}

export const isFlatLoan = (loanLender: number | string | null | undefined) => {
  return Number(loanLender) == INPUT_LOAN_LOAN_LENDER.flat
}

export const isPairLoan = (loanType: number | null | undefined) => {
  return Number(loanType) == INPUT_LOAN_LOAN_TYPE.pairLoan
}

export const isWithChartLoan = (repaymentChart: number | null | undefined) => {
  return repaymentChart == API_FLAG.on
}

export const selectInputLoanObjectNumber = (
  isBuilding: boolean | undefined,
  isSpouse?: boolean | undefined,
) => {
  return isBuilding ? (!isSpouse ? 2 : 3) : !isSpouse ? 0 : 1
}

export const selectLoanObjectName = (objectNum: number, name: string) => {
  return `input_housing.input_housing_self_owned_attributes.input_loans_attributes[${objectNum}]${name}`
}
