import { API_BOOLEAN_FLAG } from '../../models/commonsModelFunc'
import { IS_LOAN_CUSTOMIZED, LOAN_TYPE } from '../../models/housingLoansModelFunc'

// ローン方式
export const LOAN_TYPE_SELECT_ITEMS = [
  { value: LOAN_TYPE.singlePerson, label: 'ご本人単独名義' },
  { value: LOAN_TYPE.singleSpouse, label: '配偶者単独名義' },
  { value: LOAN_TYPE.pairLoan, label: 'ペアローン' },
  { value: LOAN_TYPE.coupleSum, label: '収入合算' },
]

// ローン方式 (不動産投資用)
export const REJECT_LOAN_TYPE_SELECT_ITEMS = [
  { value: LOAN_TYPE.singlePerson, label: 'ご本人単独名義' },
  { value: LOAN_TYPE.singleSpouse, label: '配偶者単独名義' },
]

// ローン方式(配偶者なし)
export const LOAN_TYPE_WITHOUT_SPOUSE_SELECT_ITEM = [
  { value: LOAN_TYPE.singlePerson, label: 'ご本人単独名義' },
]

// 団体信用生命保険
export const HAS_LOAN_LIFE_INSURANCE_CONTRACT_SELECT_ITEMS = [
  { value: API_BOOLEAN_FLAG.on, label: '加入する' },
  { value: API_BOOLEAN_FLAG.off, label: '加入しない' },
]

// 住宅ローン種別
export const FUTURE_NEED_LOAN_BUTTON_ITEMS = [
  { value: API_BOOLEAN_FLAG.on, label: '借りる' },
  { value: API_BOOLEAN_FLAG.off, label: '借りない' },
]

// 住宅ローン設定種別
export const FUTURE_IS_LOAN_CUSTOMIZED_BUTTON_ITEMS = [
  { value: IS_LOAN_CUSTOMIZED.manual, label: '手動で設定する' },
  { value: IS_LOAN_CUSTOMIZED.auto, label: '自動設定する' },
]
