import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import CalculatorIcon from '@/assets/images/v2/calculator.svg'
import MarginContainer from '@/components/v2/atoms/MarginContainer'
import Paper from '@/components/v2/atoms/Paper'
import Breadcrumb from '@/components/v2/molecules/Breadcrumb'
import PaperTitle from '@/components/v2/molecules/PaperTitle'
import { useDispatch, useSelector } from 'react-redux'
import { setPageMeta } from '@/containers/pageMetasSlice'
import { RootState } from '@/store'
import { isUndefined as _isUndefined } from 'lodash'
import SelectBoxWithoutFinalForm from '@/components/v2/molecules/SelectBoxWithoutFinalForm'
import moment, { Moment } from 'moment'
import { buildDateMonthSelectItems } from '@/utils/formItem'
import {
  buildFetchJournalValues,
  JOURNAL_SELECT_START_DATE,
} from '@/models/journalsModelFunc'
import { fetchJournal } from '@/containers/journalsSlice'
import { parse } from 'query-string'
import LoadingOverlay from '@/components/v1/atoms/LoadingOverlay'
import MonthlyBalanceForm from './_MonthlyBalanceForm'

const MonthlyBalance: FC = () => {
  const dispatch = useDispatch()
  const [selectedDate, setSelectedDate] = useState<string>('')
  const { journals } = useSelector((state: RootState) => state.journals)

  // isLoadingFlag
  const isLoadingJournals = useSelector((state: RootState) => state.journals.isLoading)
  const isLoadingFamilyStructures = useSelector(
    (state: RootState) => state.familyStructures.isLoading,
  )
  const isLoadingMonthlies = useSelector(
    (state: RootState) => state.aggBalanceMonthlies.isLoading,
  )

  const handleChangeDateMonth = (dateMonth: string) => {
    dispatch(fetchJournal(buildFetchJournalValues(dateMonth)))
    setSelectedDate(dateMonth)
  }

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'マイデータ | 月ごとの収支記録',
        description: 'マイデータ | 月ごとの収支記録',
        keyword: 'マイデータ | 月ごとの収支記録',
      }),
    )

    const { date } = parse(location.search)
    let targetDate: Moment | string = ''

    if (!_isUndefined(date)) {
      targetDate = date as string
    } else {
      targetDate = moment()
    }

    dispatch(fetchJournal(buildFetchJournalValues(targetDate)))
    setSelectedDate(moment(targetDate).format('YYYY/MM'))
  }, [])

  return (
    <>
      <LoadingOverlay
        isLoading={isLoadingJournals || isLoadingFamilyStructures || isLoadingMonthlies}
      />
      <MarginContainer className="px-8 md:px-32 lg:px-0 py-8 md:py-40">
        <Breadcrumb
          className="pb-32"
          data={[{ name: 'マイデータ', url: '/v2/myData' }, { name: '月ごとの収支記録' }]}
        />

        <PaperTitle
          className="mb-40"
          title="月ごとの収支記録を入力"
          description="日々の収入や支出を登録することで改善案などのご提案が可能になります"
          icon={<CalculatorIcon />}
          smallIcon={
            <CalculatorIcon className="h-[82px] w-[130px]" viewBox="0 0 249 165" />
          }
        />

        <Paper className="bg-white p-40">
          <div className="pb-16">
            <SelectBoxWithoutFinalForm
              className="w-160 h-37"
              name=""
              value={moment(journals?.date).format('YYYY/MM')}
              onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                handleChangeDateMonth(event.target.value)
              }
              options={buildDateMonthSelectItems(
                moment(),
                moment().diff(JOURNAL_SELECT_START_DATE, 'months') + 1,
              )}
            />
          </div>

          <MonthlyBalanceForm selectedDate={selectedDate} />
        </Paper>
      </MarginContainer>
    </>
  )
}

export default MonthlyBalance
