import React, { FC } from 'react'
import Typography from '@/components/v2/atoms/Typography'

const AboutOtherCost: FC = () => {
  return (
    <>
      <Typography className="pb-[10px] font-bold">その他の費用について</Typography>
      <Typography>
        駐輪場、町内会費、共有水道光熱費などをご入力ください。
        <br />
        駐車場代については、お車のご登録時に入力する画面がありますので、入力しないでください。
      </Typography>
    </>
  )
}

export default AboutOtherCost
