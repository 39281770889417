import { sumBy as _sumBy } from 'lodash'
import { castNumberWithoutComma } from '../commonsModelFunc'
import { FutureMarriage } from './futureMarriagesModel'

// 結婚種別
export const MARRIAGE_TYPE = {
  todo: 0,
  married: 1,
  not: 2,
}

// 結婚費用タイプ
export const MARRIAGE_COST_TYPE = {
  luxury: 'luxury',
  average: 'average',
  affordable: 'affordable',
}

interface MarriageCost {
  luxury: number
  average: number
  affordable: number
}

// 結婚費用
export const MARRIAGE_COST: { [key: string]: MarriageCost } & { [key: string]: any } = {
  engagement_ring_manyen: { luxury: 60, average: 30, affordable: 15 },
  wedding_ring_manyen: { luxury: 45, average: 25, affordable: 15 },
  wedding_manyen: { luxury: 600, average: 350, affordable: 200 },
  honeymoon_manyen: { luxury: 70, average: 50, affordable: 30 },
}

export const MARRIAGE_SUM_FIELDS = [
  'future_marriage.engagement_ring_manyen',
  'future_marriage.wedding_ring_manyen',
  'future_marriage.wedding_manyen',
  'future_marriage.honeymoon_manyen',
  'future_marriage.congratulations_manyen',
  'future_marriage.support_from_parents_manyen',
]

export const MARRIAGEEXPENSE_SUM_FIELDS = [
  'engagement_ring_manyen',
  'wedding_ring_manyen',
  'wedding_manyen',
  'honeymoon_manyen',
]

export const MARRIAGE_INCOME_SUM_FIELDS = [
  'congratulations_manyen',
  'support_from_parents_manyen',
]

export const sumMarriageCost = (
  values: Partial<FutureMarriage> & { [key: string]: any },
) => {
  return (
    _sumBy(MARRIAGEEXPENSE_SUM_FIELDS, (item) => castNumberWithoutComma(values[item])) -
    _sumBy(MARRIAGE_INCOME_SUM_FIELDS, (item) => castNumberWithoutComma(values[item]))
  ).toLocaleString()
}
