import { times as _times } from 'lodash'
import { OCCURANCE_TYPE } from '@/models/futures/futureV2HousingOtherCostsModelFunc'
import {
  CURRENT_HOUSE_HANDLING,
  NEXT_HOUSE_TYPE,
} from '@/models/futures/futureV2HousingsModelFunc'

// その他の住宅: 頻度
export const OCCURANCE_TYPE_SELECT_ITEMS = [
  { value: OCCURANCE_TYPE.once, label: '一回のみ' },
  { value: OCCURANCE_TYPE.custom, label: 'カスタム' },
  { value: OCCURANCE_TYPE.everyYear, label: '毎年' },
]

// 転居用年齢
export const LIVING_END_AT_AGE_SELECT_ITEMS = _times(110, (index) => ({
  value: index + 1,
  label: String(index + 1) + '歳まで',
}))

// 賃貸段階設定: 次の住居想定
export const NEXT_HOUSE_PLAN_SELECT_ITEMS = [
  { value: NEXT_HOUSE_TYPE.rent, label: '賃貸に住む' },
  { value: NEXT_HOUSE_TYPE.own, label: '購入する' },
]

// 住み替え: 次の住居種別
export const NEXT_HOUSE_TYPE_RADIO_ITEMS = [
  { value: NEXT_HOUSE_TYPE.rent, label: '賃貸' },
  { value: NEXT_HOUSE_TYPE.own, label: '購入' },
  { value: NEXT_HOUSE_TYPE.other, label: 'その他（実家・セカンドハウス）' },
]

// 現在の住居の扱い: 戸建てからの登録の場合
export const CURRENT_OWN_HOUSE_HANDLING_RADIO_ITEMS = [
  { value: CURRENT_HOUSE_HANDLING.sale, label: '売却する' },
  { value: CURRENT_HOUSE_HANDLING.lend, label: '賃貸として貸し出す' },
]
