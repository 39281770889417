import React, { FC } from 'react'
import { FUTURE_IS_LOAN_CUSTOMIZED_BUTTON_ITEMS } from '@/constants/formItem/housingLoans'
import { required } from '@/utils/validate'

import Typography from '@/components/v2/atoms/Typography'
import SelectBox from '@/components/v2/molecules/SelectBox'

const LoanCustomized: FC = () => {
  return (
    <>
      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
        <Typography
          className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
          isBold
        >
          ローンの詳細設定
        </Typography>
        <SelectBox
          name="future_lend_housing.is_loan_customized"
          placeholder="選択する"
          isFullWidth
          options={FUTURE_IS_LOAN_CUSTOMIZED_BUTTON_ITEMS}
          validate={required}
        />
      </div>
    </>
  )
}

export default LoanCustomized
