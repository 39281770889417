import { createContext, useEffect, useState } from 'react'
import { filter as _filter, uniq as _uniq, pullAll as _pullAll } from 'lodash'
import { QuestionTemplate } from '../models/commonModalForm'

interface DescriptionContextValue {
  targetSendValueKeys: string[]
  sendValueKeys: string[]
  submitButtonLabel: string
  submitButtonTooltipLabel: string
  pickupDescriptionTemplates: (values: any) => void
  setSendPageValueKeys: (targetKeys: string[]) => void
  addSendValueKeys: () => void
  deleteSendValueKeys: () => void
  setStateSubmitButtonLabel: (label: string) => void
  setStateSubmitButtonTooltipLabel: (label: string) => void
  submitPretreatmentFunc: (values: any) => void
  setStateSubmitPretreatmentFunc: (func: () => void) => void
  resetState: () => void
  [key: string]: any
}

export const DescriptionContext = createContext<DescriptionContextValue>({
  targetSendValueKeys: [],
  sendValueKeys: [],
  submitButtonLabel: '',
  submitButtonTooltipLabel: '',
  pickupDescriptionTemplates: (_values) => {},
  setSendPageValueKeys: (_targetKeys) => {},
  addSendValueKeys: () => {},
  deleteSendValueKeys: () => {},
  setStateSubmitButtonLabel: () => {},
  setStateSubmitButtonTooltipLabel: () => {},
  submitPretreatmentFunc: (_values) => {},
  setStateSubmitPretreatmentFunc: (_func) => {},
  resetState: () => {},
})

export const useDescriptionTemplates = (values: any, templates: QuestionTemplate[]) => {
  const defaultDescriptionTemplates = templates
  const [descriptionTemplates, setDescriptionTemplates] = useState<QuestionTemplate[]>([])
  const [targetSendValueKeys, setTargetSendValueKeys] = useState<string[]>([])
  const [sendValueKeys, setSendValueKeys] = useState<string[]>([])
  const [submitButtonLabel, setSubmitButtonLabel] = useState<string>('')
  const [submitButtonTooltipLabel, setSubmitButtonTooltipLabel] = useState<string>('')
  const [submitPretreatmentFunc, setSubmitPretreatmentFunc] = useState<() => void>(
    () => {},
  )

  useEffect(() => {
    pickupDescriptionTemplates(values)
  }, [defaultDescriptionTemplates])

  const getContextValue = () => ({
    targetSendValueKeys,
    sendValueKeys,
    submitButtonLabel,
    submitButtonTooltipLabel,
    pickupDescriptionTemplates,
    setSendPageValueKeys,
    addSendValueKeys,
    deleteSendValueKeys,
    setStateSubmitButtonLabel,
    setStateSubmitButtonTooltipLabel,
    submitPretreatmentFunc,
    setStateSubmitPretreatmentFunc,
    resetState,
  })

  const pickupDescriptionTemplates = (values: any) => {
    const resultDescriptionTemplates = _filter(
      defaultDescriptionTemplates,
      (descriptionTemplate) => {
        if (descriptionTemplate.condition) {
          return descriptionTemplate.condition(values)
        } else {
          return true
        }
      },
    )
    setDescriptionTemplates(resultDescriptionTemplates)
  }

  const setSendPageValueKeys = (targetKeys: string[]) => {
    setTargetSendValueKeys(targetKeys)
  }

  const addSendValueKeys = () => {
    setSendValueKeys(_uniq([...sendValueKeys, ...targetSendValueKeys]))
    setTargetSendValueKeys([])
  }

  const deleteSendValueKeys = () => {
    setSendValueKeys(_pullAll(sendValueKeys, targetSendValueKeys))
    setTargetSendValueKeys([])
  }

  const setStateSubmitButtonLabel = (label: string) => {
    setSubmitButtonLabel(label)
  }

  const setStateSubmitButtonTooltipLabel = (label: string) => {
    setSubmitButtonTooltipLabel(label)
  }

  const setStateSubmitPretreatmentFunc = (func: () => void) => {
    setSubmitPretreatmentFunc(func)
  }

  const resetState = () => {
    setSubmitButtonLabel('')
    setSubmitButtonTooltipLabel('')
  }

  return {
    getContextValue,
    descriptionTemplates,
    sendValueKeys,
    pickupDescriptionTemplates,
    setSendPageValueKeys,
    addSendValueKeys,
    deleteSendValueKeys,
    submitPretreatmentFunc,
    setStateSubmitPretreatmentFunc,
  }
}
