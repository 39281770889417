import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getSeminar } from '@/api/seminars'
import { unauthorizedCheck } from '@/containers/accountsSlice'
import { Seminar } from '@/models/seminarsModel'
import { AppThunk } from '@/store'
import { patchSeminarParticipant } from '@/api/seminarParticipants'
import { SeminarParticipantUpdateValues } from '@/models/seminarParticipantsModel'

interface SeminarParticipantState {
  tmpSeminarParticipant: SeminarParticipantUpdateValues
  isLoading: boolean
}

const initialState: SeminarParticipantState = {
  tmpSeminarParticipant: {
    seminar_participant: {
      mail_address: '',
      name_sei: '',
      name_mei: '',
      name_sei_kana: '',
      name_mei_kana: '',
      tel_number: '',
    },
  },
  isLoading: false,
}

const seminarParticipantSlice = createSlice({
  name: 'seminarParticipant',
  initialState,
  reducers: {
    setTmpSeminarParticipant: (
      state,
      action: PayloadAction<SeminarParticipantUpdateValues>,
    ) => {
      state.tmpSeminarParticipant = action.payload
      return state
    },
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
    resetTmpSeminarParticipant: (state) => {
      state.tmpSeminarParticipant = initialState.tmpSeminarParticipant
      return state
    },
  },
})

export const {
  requestStart,
  requestSuccess,
  requestFailure,
  setTmpSeminarParticipant,
  resetTmpSeminarParticipant,
} = seminarParticipantSlice.actions
export const seminarParticipantReducer = seminarParticipantSlice.reducer

export const updateSeminarParticipant = (
  uniqueId: string,
  values: SeminarParticipantUpdateValues,
  callback: ()=> void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchSeminarParticipant(uniqueId, values)
    .then(() => {
      dispatch(requestSuccess())
      callback && callback()
    })
    .catch((error: any) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}
