import React, { FC, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core/styles'
import { includes as _includes, some } from 'lodash'
import { SnackbarProvider } from 'notistack'
import PageMeta from '@/components/v2/molecules/PageMeta'
import { theme } from '@/constants/theme'
import Movie from '@/layouts/Movie'
import UserV2 from '@/layouts/v2/User'
import { AccountContext } from '@/models/accountsModelFunc'
import FlashAlerts from '@/templates/v2/FlashAlerts'
import UserHeatService from '@/utils/UserHeatService'

// ページ下部のプライバシーポリシーのリンクを表示しないURL
const PAGES_NOT_DISPLAY_POLICY_LINK = [
  '/v2',
  '/v2/policy',
  '/v2/terms',
  '/settings/accountDeactivate',
  '/v2/settings/accountDeactivate',
  '/v2/accounts/new',
  '/v2/accounts/confirm',
  '/v2/accounts/tempCompleted',
  '/v2/accounts/password/mailHelp',
  '/v2/accounts/password/forgot',
  '/v2/accounts/password/resetMailSendCompleted',
  '/v2/accounts/activate',
  '/v2/accounts/activateMailSendCompleted',
]

const App: FC = () => {
  const locaiton = useLocation()

  const isV2 = useMemo(() => {
    return (
      _includes(locaiton.pathname, 'v2') ||
      _includes(locaiton.pathname, 'plans/household') ||
      _includes(locaiton.pathname, 'plans/moneytreeConnection') ||
      _includes(locaiton.pathname, 'plans/moneytreeRegistration') ||
      _includes(locaiton.pathname, 'plans/dataConnection') ||
      _includes(locaiton.pathname, 'plans/moneytreeDataConfirmation') ||
      _includes(locaiton.pathname, 'plans/assetBalance') ||
      _includes(locaiton.pathname, 'accounts/new')
    )
  }, [location.pathname])

  const canDisplayPolicyLink = useMemo(() => {
    const pathname = location.pathname
    return !some(PAGES_NOT_DISPLAY_POLICY_LINK, (path) => path == pathname || `${path}/` == pathname)
  }, [location.pathname])

  useEffect(() => {
    const viewportMeta = document.querySelector('meta[name=viewport]')
    const viewportContent =
      _includes(locaiton.pathname, '/movies') || isV2
        ? 'initial-scale=1.0'
        : 'initial-scale=0.1'

    viewportMeta?.setAttribute('content', viewportContent)
    UserHeatService.analysis()
  }, [locaiton.pathname])

  const renderLayout = () => {
    if (_includes(locaiton.pathname, '/movies')) return <Movie canDisplayPolicyLink={canDisplayPolicyLink} />
    return <UserV2 canDisplayPolicyLink={canDisplayPolicyLink} />
  }

  return (
    <AccountContext.Provider value={{ isAdmin: false }}>
      <ThemeProvider theme={theme}>
        {/* @ts-ignore */}
        <SnackbarProvider
          maxSnack={3}
          variant="error"
          autoHideDuration={3000}
          preventDuplicate={true}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {renderLayout()}
          <PageMeta />
          <FlashAlerts />
        </SnackbarProvider>
      </ThemeProvider>
    </AccountContext.Provider>
  )
}

export default App
