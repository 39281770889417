import ApiClient from '@/api/apiClient'
import {
  InputAssetInvestmentCreateValues,
  InputAssetInvestmentResponse,
  InputAssetInvestmentUpdateValues,
} from '@/models/inputs/inputAssetInvestmentsModel'

export const getInputAssetInvestments = async () => {
  return await ApiClient.get(
    '/input_asset_investments',
    (response: InputAssetInvestmentResponse[]) => response,
    undefined,
    false,
    'v2',
  )
}

export const postInputAssetInvestment = async (
  values: InputAssetInvestmentCreateValues,
) => {
  return await ApiClient.post('/input_asset_investments', () => {}, values, false, 'v2')
}

export const patchInputAssetInvestment = async (
  id: number,
  values: InputAssetInvestmentUpdateValues,
) => {
  return await ApiClient.patch(
    `/input_asset_investments/${id}`,
    () => {},
    values,
    false,
    'v2',
  )
}

export const deleteInputAssetInvestment = async (id: number) => {
  return await ApiClient.delete(
    `/input_asset_investments/${id}`,
    () => {},
    undefined,
    false,
    'v2',
  )
}
