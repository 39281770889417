import { InputSimpleData } from '@/models/inputs/inputSimpleDataModel'
import ApiClient from '../apiClient'

export const getInputSimpleDataV2 = async () => {
  return await ApiClient.get(
    'input_simple_data',
    (response: InputSimpleData[]) => response,
    undefined,
    false,
    'v2',
  )
}

export const postInputSimpleDataV2 = async (values: {
  input_simple_data: InputSimpleData
}) => {
  return await ApiClient.post('/input_simple_data', () => {}, values, undefined, 'v2')
}
