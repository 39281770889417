import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import Modal from '@/components/v2/atoms/Modal'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import { fetchAggBalanceLastMonth } from '@/containers/aggBalanceMonthliesSlice'
import {
  createFutureMonthlyLivingCostBaseV2,
  updateFutureMonthlyLivingCostBaseV2,
} from '@/containers/futures/futureMonthlyLivingCostBasesSlice'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/store'
import {
  sortBy as _sortBy,
  last as _last,
  isNull as _isNull,
  isEmpty as _isEmpty,
  pick as _pick,
  replace as _replace,
} from 'lodash'
import { buildRangeAgeSelectItems } from '@/utils/formItem'
import { AFFECTED_END_AT_AGE_SELECT_ITEMS } from '@/constants/formItem/futures/futureMonthlyLivingCosts'
import {
  castNumberWithoutOperator,
  convertLocaleString,
  isNotEmptyValue,
} from '@/models/commonsModelFunc'
import { FieldArray } from 'react-final-form-arrays'

import { find as _find } from 'lodash'
import ChevronRightIcon from '@/assets/images/v2/chevron-right.svg'
import CrossIcon from '@/assets/images/v2/cross.svg'
import Button from '@/components/v2/atoms/Button'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import Typography from '@/components/v2/atoms/Typography'
import NumberFieldDecimal from '@/components/v2/molecules/NumberFieldDecimal'
import arrayMutators from 'final-form-arrays'
import SelectBox from '@/components/v2/molecules/SelectBox'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import { fetchAccountFutureItems } from '@/containers/accountsSlice'
import StripedTable from '@/components/v2/molecules/StripedTable'
import FutureMonthlyLivingCostAverageLivingCostBody from '@/templates/v2/simulations/_futureMonthlyLivingCostAverageLivingCostBody'
import clsx from 'clsx'
import SubmitErrorMessage from '@/components/v2/molecules/SubmitErrorMessage'
import { FutureMonthlyLivingCostBase } from '@/models/futures/futureMonthlyLivingCostsModel'

interface FutureMonthlyLivingCostModalFormProps {
  /** true: オープン, false: クローズ */
  isOpen: boolean
  /** onCloseメソッド */
  onClose: () => void
  /** onCloseメソッド */
  isEdit: boolean
  /** 編集時のID */
  id: number | null
  /* FutureItemsを参照するID */
  futureBaseId?: number
}

const FutureMonthlyLivingCostModalForm: FC<FutureMonthlyLivingCostModalFormProps> = ({
  isOpen,
  onClose,
  isEdit,
  id,
  futureBaseId,
}) => {
  const dispatch = useDispatch()
  const { showFlashMsg } = useFlashAlert()

  useEffect(() => {
    dispatch(fetchAggBalanceLastMonth())
  }, [])

  const { familyInfoV2 } = useSelector((state: RootState) => state.familyStructures)
  const person_current_age = familyInfoV2?.person_age

  const { detailFutureBaseV2 } = useSelector((state: RootState) => state.futureBases)

  const futureMonthlyLivingCostBases = useSelector(
    (state: RootState) => state.accounts.futureItems?.future_monthly_living_cost_bases,
  )

  const [lastAffectedEndAtAge, setLastAffectedEndAtAge] = useState<number | undefined>(
    person_current_age,
  )

  const affectedAgeSelectItems = useCallback(
    (affectedEndAtAge: number, baseValues: FutureMonthlyLivingCostBase) => {
      const targetAge = _isEmpty(baseValues?.future_monthly_living_cost_changes)
        ? Number(person_current_age)
        : affectedEndAtAge
      let newAgeSelectItems = []

      newAgeSelectItems.push({ label: '終身まで', value: 120 })
      newAgeSelectItems.push(
        ...buildRangeAgeSelectItems(
          targetAge + 1,
          110,
          AFFECTED_END_AT_AGE_SELECT_ITEMS.map((item: any) => ({
            value: item.value,
            label: `${item.value}歳まで`,
          })),
        ),
      )

      return newAgeSelectItems
    },
    [],
  )

  const targetMonthlyLivingCostBase:
    | FutureMonthlyLivingCostBase
    | undefined = useMemo(
    () => (!_isNull(id) ? _find(futureMonthlyLivingCostBases, { id }) : undefined),
    [futureMonthlyLivingCostBases, id],
  )

  const initialValues = useMemo(() => {
    return isEdit
      ? {
          future_monthly_living_cost_base: {
            future_monthly_living_cost_changes_attributes:
              targetMonthlyLivingCostBase?.future_monthly_living_cost_changes,
          },
        }
      : {
          future_monthly_living_cost_base: {
            future_monthly_living_cost_changes_attributes: [],
          },
        }
  }, [isEdit, targetMonthlyLivingCostBase])

  const handleSubmit = (values: any) => {
    return isEdit ? handleUpdate(values) : handleCreate(values)
  }

  const handleCreate = (values: any) => {
    const newValues = {
      account: {
        future_monthly_living_cost_bases_attributes: [
          values.future_monthly_living_cost_base,
        ],
      },
    }
    dispatch(
      createFutureMonthlyLivingCostBaseV2(newValues, () => {
        dispatch(fetchAccountFutureItems(futureBaseId))
        showFlashMsg(isEdit ? '更新しました' : '作成しました')
        onClose()
      }),
    )
  }

  const handleUpdate = (values: any) => {
    const newValues = {
      future_monthly_living_cost_base: {
        future_monthly_living_cost_changes_attributes: values.future_monthly_living_cost_base.future_monthly_living_cost_changes_attributes.map(
          (item: any) => ({
            ...item,
            monthly_cost_manyen: castNumberWithoutOperator(item.monthly_cost_manyen),
            yearly_cost_manyen: castNumberWithoutOperator(item.yearly_cost_manyen),
          }),
        ),
      },
    }

    dispatch(
      updateFutureMonthlyLivingCostBaseV2(newValues, id as any, () => {
        dispatch(fetchAccountFutureItems(futureBaseId))
        showFlashMsg(isEdit ? '更新しました' : '作成しました')
        onClose()
      }),
    )
  }

  useEffect(() => {
    dispatch(fetchAggBalanceLastMonth())
  }, [])

  return (
    <Modal
      className="lg:w-[1024px] w-[95%]"
      isOpen={isOpen}
      isDisableBackdropClick
      onClose={onClose}
    >
      <CircleTitle
        className="pb-[32px]"
        title="基本生活費の想定を設定する"
        classNameTitle="text-[19px] md:text-[24px]"
      />
      <Form
        onSubmit={handleSubmit}
        mutators={{ ...arrayMutators }}
        validate={(values: any) => {
          const lastMonthlyLivingCostChanges: any = _last(
            values?.future_monthly_living_cost_base
              ?.future_monthly_living_cost_changes_attributes,
          )
          return lastMonthlyLivingCostChanges?.affected_end_at_age !== 120
            ? ('終身までの基本生活費の変化が設定されていません。' as any)
            : undefined
        }}
        initialValues={initialValues}
        render={({
          values,
          handleSubmit,
          errors,
          submitErrors,
          submitFailed,
          form: { change },
        }) => {
          const {
            monthly_cost_manyen,
            affected_end_at_age,
            future_monthly_living_cost_base,
          } = values
          const future_monthly_living_cost_changes_attributes = future_monthly_living_cost_base?.future_monthly_living_cost_changes_attributes || []
          const monthlyCostManyen = monthly_cost_manyen
          const affectedEndAtAge = affected_end_at_age
          const futureMonthlyLivingCostChangesAttributes = future_monthly_living_cost_changes_attributes

          const lastMonthlyLivingCostChanges: any = _last(
            futureMonthlyLivingCostChangesAttributes,
          )

          const isInputCompleted =
            lastMonthlyLivingCostChanges?.affected_end_at_age == 120

          const handleAddMonthlyLivingCostChange = () => {
            const newValues: any = {
              monthly_cost_manyen: castNumberWithoutOperator(monthlyCostManyen),
              affected_end_at_age: castNumberWithoutOperator(affectedEndAtAge),
              yearly_cost_manyen: castNumberWithoutOperator(monthlyCostManyen) * 12,
            }

            let sortedMonthlyLivingCostChange = _sortBy(
              [...futureMonthlyLivingCostChangesAttributes, newValues],
              'affected_end_at_age',
            )

            sortedMonthlyLivingCostChange = sortedMonthlyLivingCostChange.map(
              (item, index) => {
                const affectedStartAtAge =
                  index === 0
                    ? person_current_age
                    : sortedMonthlyLivingCostChange[index - 1].affected_end_at_age

                return { ...item, affected_start_at_age: affectedStartAtAge }
              },
            )

            change(
              'future_monthly_living_cost_base.future_monthly_living_cost_changes_attributes',
              sortedMonthlyLivingCostChange,
            )
            change('monthly_cost_manyen', null)
            change('affected_end_at_age', null)
          }

          const handleChangeMonthlyCostManyen = (value: any, key: string) => {
            let newValue = value ? _replace(value, /．/g, '.') : value // MEMO: 全角のカンマがある場合は、半角カンマに変換
            let commaRegex = /\./g

            if (
              ((newValue && String(newValue).match(new RegExp(commaRegex, 'g'))) || []).length > 1
            ) {
              const indexAfterFirstComma = String(newValue).indexOf('.') + 1
              newValue =
                String(newValue).slice(0, indexAfterFirstComma) +
                _replace(String(newValue).slice(indexAfterFirstComma), commaRegex, '')
            }

            newValue = _replace(newValue, /[０-９]/g, (v) =>
              String.fromCharCode(v.charCodeAt(0) - 0xfee0),
            )
            newValue = _replace(newValue, /[^0-9.]/g, '')
            change(key, convertLocaleString(newValue * 12))
          }

          return (
            <form className="pb-[30px]" onSubmit={handleSubmit}>
              <div className="lg:mx-[100px]">
                {detailFutureBaseV2.statement_table_data &&
                  detailFutureBaseV2.family_structure_table_columns && (
                    <StripedTable
                      tableData={Object.keys(
                        detailFutureBaseV2.statement_table_data,
                      ).reduce((acc: any, year: any) => {
                        acc[year] = _pick(
                          detailFutureBaseV2.statement_table_data[year],
                          detailFutureBaseV2.family_structure_table_columns.map(
                            (obj: any) => obj.key,
                          ),
                        )
                        return acc
                      }, {})}
                      columnData={detailFutureBaseV2.family_structure_table_columns}
                      formatter={(v, k) => `${v}歳`}
                    />
                  )}

                <FutureMonthlyLivingCostAverageLivingCostBody />
              </div>

              <div className="lg:mx-[100px] pb-[30px]">
                <FieldArray name="future_monthly_living_cost_base.future_monthly_living_cost_changes_attributes">
                  {({ fields }) =>
                    fields.map((name, index) => {
                      const {
                        affected_start_at_age,
                        affected_end_at_age,
                        monthly_cost_manyen,
                        yearly_cost_manyen,
                      } = fields.value[index]
                      const isCurrentAge = index === 0
                      const isWholeLife = affected_end_at_age == 120
                      setLastAffectedEndAtAge(affected_end_at_age)

                      return (
                        <React.Fragment key={name}>
                          <div className="pb-[20px] last:pb-0">
                            <div className="flex justify-between p-[16px] border border-black-600 rounded-[4px]">
                              <Typography className="text-[14px] text-black-600" isBold>
                                {isCurrentAge ? '現在' : `${affected_start_at_age}歳から`}
                              </Typography>
                              <Button onClick={() => fields.remove(index)}>
                                <CrossIcon />
                              </Button>
                            </div>
                            <div className="md:flex items-center min-h-[70px] ml-[10px] md:ml-[80px] p-[10px] pl-[20px] md:pl-[50px] border-l border-black-600 font-bold">
                              <Typography className="pb-[10px] text-[16px]">
                                {isCurrentAge
                                  ? '現在の基本生活費'
                                  : `${affected_start_at_age}歳からの基本生活費`}
                              </Typography>
                              <div className="pl-[0px] md:pl-[28px]">
                                <div className="flex flex-auto items-baseline">
                                  <NumberFieldDecimal
                                    isFullWidth
                                    name={`${name}monthly_cost_manyen`}
                                    defaultValue={monthly_cost_manyen}
                                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                      handleChangeMonthlyCostManyen(
                                        event.target.value,
                                        `${name}yearly_cost_manyen`,
                                      )
                                    }
                                  />
                                  <Typography className="pl-[10px] text-black-700 min-w-[fit-content]">
                                    万円/月
                                  </Typography>
                                </div>
                                <div className="flex flex-auto items-baseline mt-[16px]">
                                  <NumberFieldDecimal
                                    isFullWidth
                                    name={`${name}yearly_cost_manyen`}
                                    defaultValue={yearly_cost_manyen}
                                    disabled
                                  />
                                  <Typography className="pl-[10px] text-black-700 min-w-[fit-content]">
                                    万円/年
                                  </Typography>
                                </div>
                              </div>
                            </div>
                            <div className="p-[16px] border border-black-600 rounded-[4px]">
                              <Typography className="text-[14px] text-black-600" isBold>
                                {isWholeLife ? '終身' : `${affected_end_at_age}歳まで`}
                              </Typography>
                            </div>
                          </div>
                        </React.Fragment>
                      )
                    })
                  }
                </FieldArray>
              </div>

              {!isInputCompleted && (
                <div className="pb-[30px]">
                  {futureMonthlyLivingCostChangesAttributes.length == 0 && (
                    <div className="pb-[40px] last:pb-0">
                      <div className="flex justify-between p-[16px] border border-black-600 rounded-[4px]">
                        <Typography className="text-[14px] text-black-600" isBold>
                          現在から
                        </Typography>
                      </div>
                      <div className="flex items-center min-h-[70px] ml-[30px] md:ml-[80px] p-[10px] pl-[20px] md:pl-[50px] border-l border-black-600">
                        <Typography
                          className="min-w-[120px] pb-[10px] md:pb-0 text-[14px] text-black-500"
                          isBold
                        >
                          変化の内容を設定してください
                        </Typography>
                      </div>
                      <div className="p-[16px] border border-black-600 rounded-[4px]">
                        <Typography className="text-[14px] text-black-600" isBold>
                          ＿歳11ヶ月まで
                        </Typography>
                      </div>
                    </div>
                  )}

                  <div className="block md:flex items-center mb-[10px] last:mb-0 pb-[10px] border-b border-black-300 justify-between">
                    <Typography
                      className="pb-[10px] lg:pb-0 text-[14px] text-black-800 pr-[16px] min-w-[max-content]"
                      isBold
                    >
                      想定する基本生活費
                    </Typography>
                    <div className="flex flex-auto items-center w-full justify-end">
                      <NumberFieldDecimal
                        name="monthly_cost_manyen"
                        className="w-full md:max-w-[200px]"
                        widthClassName="w-full md:w-[200px]"
                      />
                      <Typography
                        className="pl-[8px] text-[14px] text-black-800 min-w-[fit-content]"
                        isBold
                      >
                        万円/月
                      </Typography>
                    </div>
                  </div>

                  <div className="block md:flex items-center mb-[10px] last:mb-0 pb-[10px] border-b border-black-300 justify-between">
                    <Typography
                      className="pb-[10px] lg:pb-0 text-[14px] text-black-800 pr-[16px] min-w-[max-content]"
                      isBold
                    >
                      基本生活費の適用期間
                    </Typography>
                    <div className="flex flex-auto items-center w-full justify-end">
                      <SelectBox
                        className="w-full md:max-w-[200px]"
                        widthClassName="w-full md:w-[200px]"
                        placeholder="適用期間を選択"
                        name="affected_end_at_age"
                        options={affectedAgeSelectItems(
                          lastAffectedEndAtAge!,
                          values?.future_monthly_living_cost_base,
                        )}
                      />
                    </div>
                  </div>

                  <Button
                    className="w-[188px] h-[45px] mx-auto border border-dashed border-primary-500 text-[14px] text-primary-500 hover:bg-primary-50"
                    isDisabled={
                      !isNotEmptyValue(monthlyCostManyen) ||
                      !isNotEmptyValue(affectedEndAtAge)
                    }
                    onClick={handleAddMonthlyLivingCostChange}
                  >
                    <Typography className="text-[16px]" isBold>
                      追加する
                    </Typography>
                  </Button>
                </div>
              )}
              <SubmitErrorMessage show={!submitErrors && submitFailed} />
              <ButtonPrimary
                className={clsx(
                  { ['opacity-50']: Object.keys(errors).length > 0 },
                  'w-[fit-content] md:w-[246px] h-[64px] mx-[auto]',
                )}
                onClick={() => handleSubmit(values)}
              >
                <Typography
                  className="bottom-2 flex-auto pl-30 relative text-16 text-white"
                  isBold
                >
                  保存する
                </Typography>
                <ChevronRightIcon className="mr-17" />
              </ButtonPrimary>
            </form>
          )
        }}
      />
    </Modal>
  )
}

export default FutureMonthlyLivingCostModalForm
