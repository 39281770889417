import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getCompanyRisk } from '@/api/companyRisks'
import { unauthorizedCheck } from '@/containers/accountsSlice'
import { ApiError } from '@/models/commonsModel'
import { CompanyRisk } from '@/models/companyRisksModel'
import { AppThunk } from '@/store'

interface CompanyRiskState {
  companyRisk: CompanyRisk[]
  isLoading: boolean
}

const initialState: CompanyRiskState = {
  companyRisk: [],
  isLoading: false,
}

const companyRisksSlice = createSlice({
  name: 'companyRisk',
  initialState,
  reducers: {
    setCompanyRisk: (state, action: PayloadAction<CompanyRisk[]>) => {
      state.companyRisk = action.payload
      return state
    },
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    getCompanyRiskSuccess: (state, action: PayloadAction<CompanyRisk[]>) => {
      state.companyRisk = action.payload
      state.isLoading = false

      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
    resetCompanyRisks: () => {
      return initialState
    },
  },
})

export const {
  setCompanyRisk,
  requestStart,
  requestSuccess,
  getCompanyRiskSuccess,
  requestFailure,
  resetCompanyRisks,
} = companyRisksSlice.actions
export const companyRisksForUserReducer = companyRisksSlice.reducer

export const fetchCompanyRisk = (): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  getCompanyRisk()
    .then((response) => dispatch(getCompanyRiskSuccess(response)))
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}
