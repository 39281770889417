import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getAifpProposals } from '@/api/aifpProposals'
import { AifpProposalsResponse, AifpProposal } from '@/models/aifpProposalsModel'
import { AppThunk } from '../store'

interface AifpProposalState {
  aifpProposals: AifpProposal[]
  isLoading: boolean
}

const initialState: AifpProposalState = {
  aifpProposals: [],
  isLoading: false,
}

const aifpProposalsSlice = createSlice({
  name: 'aifpProposals',
  initialState,
  reducers: {
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    getAifpProposalsSuccess: (state, action: PayloadAction<AifpProposalsResponse>) => {
      state.aifpProposals = action.payload.aifp_proposals
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
  },
})

export const {
  requestStart,
  requestSuccess,
  getAifpProposalsSuccess,
  requestFailure,
} = aifpProposalsSlice.actions
export const aifpProposalsReducer = aifpProposalsSlice.reducer

export const fetchAifpProposals = (): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  getAifpProposals()
    .then((response) => dispatch(getAifpProposalsSuccess(response)))
    .catch(() => dispatch(requestFailure()))
}
