import React, { FC } from 'react'
import clsx from 'clsx'
import Button from '@/components/v2/atoms/Button'

interface ButtonPrimaryProps {
  /** クラス名 */
  className?: string
  /** タイプ */
  type?: 'button' | 'submit' | 'reset'
  /** true: 非活性, false: 活性 */
  isDisabled?: boolean
  /** onClickメソッド */
  onClick?: () => void
}

const ButtonPrimary: FC<ButtonPrimaryProps> = (props) => {
  const { className, type, isDisabled, onClick, children } = props

  return (
    <Button
      className={clsx(`${className} bg-primary-500 rounded-8`, {
        ['hover:bg-primary-600']: !isDisabled,
      })}
      type={type ?? 'button'}
      isDisabled={isDisabled}
      onClick={onClick}
    >
      {children}
    </Button>
  )
}

export default ButtonPrimary
