import ApiClient from '../apiClient'
import {
  InputMonthlyCostCreateValues,
  InputMonthlyCostUpdateValues,
  InputMonthlyCostDestroyValues,
} from '../../models/inputs/inputMonthlyCostsModel'

export const postInputMonthlyCost = async (values: InputMonthlyCostCreateValues) => {
  return await ApiClient.post('/input_monthly_costs', () => {}, values)
}

export const patchInputMonthlyCost = async (values: InputMonthlyCostUpdateValues) => {
  return await ApiClient.patch(`/input_monthly_costs`, () => {}, values)
}

export const deleteInputMonthlyCost = async (values: {
  params: InputMonthlyCostDestroyValues
}) => {
  return await ApiClient.delete('/input_monthly_costs', () => {}, values)
}
export const getInputMonthlyCostV2 = async () => {
  return await ApiClient.get(
    '/input_monthly_costs',
    (response: any) => response,
    undefined,
    undefined,
    'v2',
  )
}
