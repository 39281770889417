import React, { FC, useEffect } from 'react'
import { useField } from 'react-final-form'
import { MANAGEMENT_FEE_TYPE_RADIO_ITEMS } from '@/constants/formItem/futures/futureEstateInvestments'
import { isNotEmptyValue } from '@/models/commonsModelFunc'
import {
  MANAGEMENT_FEE_TYPE,
  PROPERTY_TYPE,
} from '@/models/futures/futureEstateInvestmentsModelFunc'
import { composeValidators, required, zeroOrMoreNumber } from '@/utils/validate'

import Typography from '@/components/v2/atoms/Typography'
import SelectBox from '@/components/v2/molecules/SelectBox'
import NumberField from '@/components/v2/molecules/NumberField'
import NumberFieldDecimal from '@/components/v2/molecules/NumberFieldDecimal'

const ManagementFeeType: FC = () => {
  const {
    input: { value: propertyType },
  } = useField('future_lend_housing.property_type')

  const {
    input: { value: managementFeeType },
  } = useField('future_lend_housing.management_fee_type')

  useEffect(() => {
    const targetKeys = ['future_lend_housing.management_fee_type']
    managementFeeType === MANAGEMENT_FEE_TYPE.percent &&
      targetKeys.push('future_lend_housing.management_fee_rate_percent')
    managementFeeType === MANAGEMENT_FEE_TYPE.yen &&
      targetKeys.push('future_lend_housing.management_fee_money')
  }, [managementFeeType])

  return (
    <>
      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
        <Typography
          className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
          isBold
        >
          賃貸管理手数料の設定
        </Typography>
        <SelectBox
          name="future_lend_housing.management_fee_type"
          placeholder="選択する"
          isFullWidth
          options={MANAGEMENT_FEE_TYPE_RADIO_ITEMS}
          validate={required}
        />
      </div>

      {isNotEmptyValue(managementFeeType) && (
        <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
          <Typography
            className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
            isBold
          >
            賃貸管理手数料
          </Typography>
          {managementFeeType === MANAGEMENT_FEE_TYPE.percent && (
            <>
              <div className="flex flex-auto items-baseline">
                <Typography className="pr-[8px] text-[14px] text-black-800 min-w-[fit-content]">
                  賃料の
                </Typography>
                <NumberFieldDecimal
                  isFullWidth
                  name="future_lend_housing.management_fee_rate_percent"
                  validate={composeValidators(required, zeroOrMoreNumber)}
                />
                <Typography className="pl-[8px] text-[14px] text-black-800">%</Typography>
              </div>
            </>
          )}

          {managementFeeType === MANAGEMENT_FEE_TYPE.yen && (
            <>
              <div className="flex flex-auto items-baseline pl-[8px]">
                <NumberField
                  isFullWidth
                  name="future_lend_housing.management_fee_money"
                  validate={composeValidators(required, zeroOrMoreNumber)}
                />
                <Typography className="pl-[8px] text-[14px] text-black-800 min-w-[max-content]">
                  円/月
                </Typography>
              </div>
            </>
          )}
        </div>
      )}
    </>
  )
}

export default ManagementFeeType
