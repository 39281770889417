import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { find as _find, isUndefined as _isUndefined } from 'lodash'
import Typography from '@/components/v2/atoms/Typography'
import {
  convertDateToTwoTypesYearsDateFormat,
  defaultToEmptyString,
  convertLocaleString,
  castNumber,
} from '@/models/commonsModelFunc'
import { PERSON_FLG } from '@/models/familyStructuresModelFunc'
import { INPUT_TYPE } from '@/models/inputs/inputPublicPensionsModelFunc'
import { RootState } from '@/store'
import SimulationAccordion from '@/templates/v2/simulations/_simulationAccordion'
import PublicPensionEditForm from '@/templates/v2/myData/publicPensions/Form'
import Modal from '@/components/v2/atoms/Modal'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import Button from '@/components/v2/atoms/Button'
import SimulationNewButton from '@/templates/v2/simulations/_simulationNewButton'

interface InputPublicPensionBodyProps {
  /** true: ご本人, false: 配偶者 */
  personFlg: boolean
  /** 更新時の処理 */
  onUpdate: () => void
}

export const PublicPensionTable: FC<any> = ({ isPerson }) => {
  const { inputPublicPensions } = useSelector(
    (state: RootState) => state.inputPublicPensions,
  )
  const personFlg = isPerson ? PERSON_FLG.person : PERSON_FLG.spouse
  const targetPublicPension = _find(
    inputPublicPensions,
    (item) => item.person_flg === personFlg,
  )

  return (
    <>
      {!_isUndefined(targetPublicPension) ? (
        <table className="w-[100%] text-[12px] md:text-[14px]">
          <tbody>
            <tr className="h-[45px] odd:bg-black-100">
              <td className="w-[50%] px-[16px]">
                <Typography isBold>ねんきん定期便の日付／入力日</Typography>
              </td>
              <td className="w-[50%] px-[16px]">
                {convertDateToTwoTypesYearsDateFormat(
                  targetPublicPension.input_at,
                  'yearMonthWithJapaneseYear',
                )}
              </td>
            </tr>

            {targetPublicPension.input_type === INPUT_TYPE.overFifty &&
              targetPublicPension.input_public_pension_receivings.map(
                (item: any, index: number) => (
                  <React.Fragment
                    key={`input_public_pension_receivings_${item.id}_${index}`}
                  >
                    <tr className="h-[45px] odd:bg-black-100">
                      <td className="w-[50%] px-[16px]">
                        <Typography isBold>受取開始年齢</Typography>
                      </td>
                      <td className="w-[50%] px-[16px]">
                        {`${defaultToEmptyString(item.affected_at_age)}歳〜`}
                      </td>
                    </tr>

                    <tr className="h-[45px] odd:bg-black-100">
                      <td className="w-[50%] px-[16px]">
                        <Typography isBold>国民年金</Typography>
                      </td>
                      <td className="w-[50%] px-[16px]">
                        {`${convertLocaleString(item.receiving_public_pension_amount)}円`}
                      </td>
                    </tr>

                    <tr className="h-[45px] odd:bg-black-100">
                      <td className="w-[50%] px-[16px]">
                        <Typography isBold>厚生年金</Typography>
                      </td>
                      <td className="w-[50%] px-[16px]">
                        {`${convertLocaleString(
                          item.receiving_welfare_pension_amount,
                        )}円`}
                      </td>
                    </tr>
                  </React.Fragment>
                ),
              )}

            {targetPublicPension.input_type === INPUT_TYPE.other && (
              <tr className="h-[45px] odd:bg-black-100">
                <td className="w-[50%] px-[16px]">
                  <Typography isBold>国民年金の加入期間</Typography>
                </td>
                <td className="w-[50%] px-[16px]">
                  {`${defaultToEmptyString(
                    castNumber(targetPublicPension.national_pension_paid_months),
                  )}ヶ月`}
                </td>
              </tr>
            )}

            {targetPublicPension.input_type === INPUT_TYPE.other && (
              <tr className="h-[45px] odd:bg-black-100">
                <td className="w-[50%] px-[16px]">
                  <Typography isBold>
                    厚生年金保険料の保険料納付額（累計額）の合計金額
                  </Typography>
                </td>
                <td className="w-[50%] px-[16px]">
                  {`${convertLocaleString(
                    targetPublicPension.welfare_pension_paid_amount,
                  )}円`}
                </td>
              </tr>
            )}

            {targetPublicPension.input_type === INPUT_TYPE.other && (
              <tr className="h-[45px] odd:bg-black-100">
                <td className="w-[50%] px-[16px]">
                  <Typography isBold>
                    厚生年金（共済年金）
                    <br />
                    の加入期間
                  </Typography>
                </td>
                <td className="w-[50%] px-[16px]">
                  {`${defaultToEmptyString(
                    castNumber(targetPublicPension.welfare_pension_paid_months),
                  )}ヶ月`}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      ) : (
        <Typography className="text-[14px] md:text-[16px] text-center" isBold>
          {`${isPerson ? 'ご本人' : '配偶者'}の公的年金が登録されていません。`}
        </Typography>
      )}
    </>
  )
}

const InputPublicPensionBody: FC<InputPublicPensionBodyProps> = (props) => {
  const { personFlg, onUpdate } = props
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const closeModal = () => setIsOpenModal(false)
  const openModal = () => setIsOpenModal(true)

  const { inputPublicPensions } = useSelector(
    (state: RootState) => state.inputPublicPensions,
  )

  const inputedPublicPension =
    inputPublicPensions.find((pp) => Number(!personFlg) === pp.person_flg) != null

  return (
    <>
      <SimulationAccordion
        className="pb-[16px]"
        title={`${personFlg ? 'ご本人' : '配偶者'}の公的年金`}
      >
        {inputedPublicPension && (
          <div className="flex justify-end mb-[8px]">
            <Button onClick={openModal}>
              <i className="ico edit text-[14px] text-black-700" />
              <Typography className="pl-[5px] text-[14px] text-black-700">
                編集
              </Typography>
            </Button>
          </div>
        )}
        <PublicPensionTable isPerson={personFlg} />
        {!inputedPublicPension && <SimulationNewButton onClick={openModal} />}
      </SimulationAccordion>

      <Modal className="lg:w-[1024px] w-[95%]" isOpen={isOpenModal} onClose={closeModal}>
        <CircleTitle
          className="pb-[32px]"
          title={`${personFlg ? 'ご本人' : '配偶者'}の公的年金`}
        />
        <PublicPensionEditForm
          person_flg={personFlg ? PERSON_FLG.person : PERSON_FLG.spouse}
          onClose={closeModal}
          onSubmit={() => {
            closeModal()
            onUpdate()
          }}
        />
      </Modal>
    </>
  )
}

export default InputPublicPensionBody
