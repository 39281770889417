import { times as _times } from 'lodash'
import { THIS_YEAR_NUMBER } from '@/models/commonsModelFunc'

// アカウント発行後の操作許可
export const MANIPULATED_TYPE = {
  notManipulated: 'not_manipulated',
  limited: 'limited',
  forever: 'forever',
}

// 年
export const CARD_EXP_YEAR_SELECT_ITEMS = _times(21, (index) => ({
  value: String(index + THIS_YEAR_NUMBER).substr(2, 4),
  label: String(index + THIS_YEAR_NUMBER).substr(2, 4),
}))
