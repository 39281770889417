import React from 'react'
import { XAxis, AxisDomain } from 'recharts'

interface RenderCustomXAxisProps {
  /** データキー */
  dataKey: string
  /** true: 縦, false: 横 */
  isVertical?: boolean
  /** true: 非表示, false: 表示 */
  isHide?: boolean
  /** 領域 */
  domain?: [AxisDomain, AxisDomain]
}

const renderCustomXAxis = (props: RenderCustomXAxisProps) => {
  const { dataKey, isVertical, isHide, domain } = props

  return (
    <XAxis
      type={isVertical ? 'number' : 'category'}
      dataKey={dataKey}
      hide={isHide}
      axisLine={{ strokeWidth: 2, stroke: '#666666' }}
      tickLine={{ stroke: '#666666' }}
      tick={{ dy: 4, fontSize: 10, fill: '#666666' }}
      tickSize={9}
      domain={domain}
      allowDataOverflow
    />
  )
}

export default renderCustomXAxis
