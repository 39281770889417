import {
  MARRIAGE_TYPE,
  MARRIAGE_COST_TYPE,
} from '../../../models/futures/futureMarriagesModelFunc'

// 結婚種別
export const MARRIAGE_TYPE_SELECT_ITEMS = [
  { value: MARRIAGE_TYPE.not, label: 'しない' },
  { value: MARRIAGE_TYPE.todo, label: 'する予定' },
]

// 結婚費用タイプ
export const MARRIAGE_COST_TYPE_SELECT_ITEMS = [
  { value: MARRIAGE_COST_TYPE.luxury, label: '豪華にしたい' },
  { value: MARRIAGE_COST_TYPE.average, label: '平均的なもの' },
  { value: MARRIAGE_COST_TYPE.affordable, label: '手ごろなもの' },
]
