import React, { FC } from 'react'
import { useForm } from 'react-final-form'
import { CHILD_REARING_DEDUCTION_SELECT_ITEMS } from '@/constants/formItem/futures/futureHousings'
import { INPUT_CHILD_REARING_DEDUCTION_SELECT_ITEMS } from '@/constants/formItem/inputs/inputHousings'
import { getTargetSelfOwnedAttributesName } from '@/models/commonHousingModelFunc'
import { required } from '@/utils/validate'

import Typography from '@/components/v2/atoms/Typography'
import SelectBox from '@/components/v2/molecules/SelectBox'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
interface ChildRearingDeductionProps {
  isFuture?: boolean
}

const ChildRearingDeduction: FC<ChildRearingDeductionProps> = (props) => {
  const { isFuture } = props
  const targetHousingSelfOwnedAttributesName = getTargetSelfOwnedAttributesName(isFuture)
  const modalDesc = '子育て世帯等に対する控除'
  const selectItems = isFuture ? CHILD_REARING_DEDUCTION_SELECT_ITEMS : INPUT_CHILD_REARING_DEDUCTION_SELECT_ITEMS

  const { change } = useForm()

  return (
    <>
      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
        <Typography
          className="min-w-[140px] lg:min-w-[210px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
          isBold
        >
          {modalDesc}
        </Typography>
        <SelectBox
          name={`${targetHousingSelfOwnedAttributesName}.child_rearing_deduction`}
          placeholder="選択する"
          isFullWidth
          options={selectItems}
          validate={required}
          onChange={(e: any) => {
            change(`${targetHousingSelfOwnedAttributesName}.child_rearing_deduction`, e.target.value)
          }}
        />
      </div>
    </>
  )
}

export default ChildRearingDeduction
