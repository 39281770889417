import React, { FC, useState, useContext, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import { includes as _includes, filter as _filter } from 'lodash'
import Button from '@/components/v2/atoms/Button'
import Tooltip from '@/components/v2/atoms/Tooltip'
import Typography from '@/components/v2/atoms/Typography'
import { fetchAccountFutureItems } from '@/containers/accountsSlice'
import { destroyFutureInsuranceV2 } from '@/containers/futures/futureInsurancesSlice'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import {
  THIS_MONTH_NUMBER,
  THIS_YEAR_NUMBER,
  convertDateToTwoTypesYearsDateFormat,
  convertLocaleString,
  defaultToEmptyString,
} from '@/models/commonsModelFunc'
import {
  CONTRACTOR_TYPE,
  convertCancellationRefundAmountManyen,
  convertCancellationRefundAt,
  convertContractor,
  convertInsuranceFeePaymentOccurrenceInMonths,
  convertInsuranceType1,
  convertInsuranceType2,
  convertInsured,
  convertMaturityMoney,
  convertNonLifeInsuranceFeeMonths,
  convertNonLifeInsuranceType1,
  convertPeriod,
  convertPeriodFinishAt,
  convertPurpose,
  INSURANCE_TYPE1,
  INSURANCE_TYPE2,
  INSURED_TYPE,
  PURPOSE,
} from '@/models/commonInsurancesModelFunc'
import { SimulationDetailEditContext } from '@/models/futures/futureBasesModelFunc'
import { FutureInsurance } from '@/models/futures/futureInsurancesModel'
import { RootState } from '@/store'
import SimulationAccordion from '@/templates/v2/simulations/_simulationAccordion'
import SimulationNewButton from '@/templates/v2/simulations/_simulationNewButton'
import FutureInsuranceModalForm from '@/templates/v2/simulations/_futureInsuranceModalForm'
import SimulationItemAccordion from '@/templates/v2/simulations/_simulationItemAccordion'

interface FutureInsuranceFormProps {
  /** 選択シミュレーションID */
  selectedSimulationId: number[] | undefined
  /** 選択メソッド */
  handleSelect: (item: any) => void
  /* FutureItemsを参照するID */
  futureBaseId?: number
  /* 初期化すべきかどうか */
  shouldInitialize?: boolean
  /* 編集を確認したか */
  confirmEdit?: boolean
  /* 編集確認モーダルを展開するメソッド */
  openEditConfirmModal?: () => void
}

interface FutureInsuranceTableProps {
  /** アイテム */
  item: any
  /** td要素のクラス */
  tdClassName?: string
}

export const FutureInsuranceTable: FC<FutureInsuranceTableProps> = ({ item }) => {
  return (
    <table
      key={item.id}
      className={clsx('w-[100%] mb-[20px] last:mb-0 text-[12px] md:text-[14px] bg-white')}
    >
      <tbody>
        <tr className="h-[45px] odd:bg-black-100">
          <td className="w-[40%] px-[16px] md:px-[30px]">
            <Typography isBold>契約年月</Typography>
          </td>
          <td className="px-[16px] md:px-[30px]">
            <Typography>
              {convertDateToTwoTypesYearsDateFormat(
                item?.contracted_at,
                'yearMonthWithJapaneseYear',
              )}
            </Typography>
          </td>
        </tr>
        <tr className="h-[45px] odd:bg-black-100">
          <td className="px-[16px] md:px-[30px]">
            <Typography isBold>保険会社名</Typography>
          </td>
          <td className="px-[16px] md:px-[30px]">
            <Typography>{defaultToEmptyString(item?.company_name)}</Typography>
          </td>
        </tr>
        <tr className="h-[45px] odd:bg-black-100">
          <td className="px-[16px] md:px-[30px]">
            <Typography isBold>保険種別</Typography>
          </td>
          <td className="px-[16px] md:px-[30px]">
            {item?.['is_life_insurance?'] ? (
              <Typography>{`${convertInsuranceType1(
                item?.insurance_type1,
              )} ${convertInsuranceType2(item?.insurance_type2)}`}</Typography>
            ) : (
              <Typography>
                {convertNonLifeInsuranceType1(item?.insurance_type1)}
              </Typography>
            )}
          </td>
        </tr>

        {item?.['is_life_insurance?'] && (
          <tr className="h-[45px] odd:bg-black-100">
            <td className="px-[16px] md:px-[30px]">
              <Typography isBold>利用目的</Typography>
            </td>
            <td className="px-[16px] md:px-[30px]">
              <Typography>{convertPurpose(item?.purpose)}</Typography>
            </td>
          </tr>
        )}

        {item?.['is_life_insurance?'] && (
          <>
            <tr className="h-[45px] odd:bg-black-100">
              <td className="px-[16px] md:px-[30px]">
                <Typography isBold>契約者</Typography>
              </td>
              <td className="px-[16px] md:px-[30px]">
                <Typography>
                  {convertContractor(item?.contractor_type, item?.contractor_name)}
                </Typography>
              </td>
            </tr>
            <tr className="h-[45px] odd:bg-black-100">
              <td className="px-[16px] md:px-[30px]">
                <Typography isBold>被保険者</Typography>
              </td>
              <td className="px-[16px] md:px-[30px]">
                <Typography>
                  {convertInsured(item?.insured_type, item?.insured_name)}
                </Typography>
              </td>
            </tr>
            <tr className="h-[45px] odd:bg-black-100">
              <td className="px-[16px] md:px-[30px]">
                <Typography isBold>保険期間</Typography>
              </td>
              <td className="px-[16px] md:px-[30px]">
                <Typography>
                  {item?.insured_type === INSURED_TYPE.otherPersonInsured
                    ? convertPeriodFinishAt(
                        item?.insurance_period_type,
                        item?.insurance_period_finish_at,
                        item?.insured_type,
                        item?.insured_name,
                        'insured',
                      )
                    : convertPeriod(
                        item?.insurance_period_type,
                        item?.insurance_period_finish_at_age,
                        item?.insured_type,
                        item?.insured_name,
                        'insured',
                      )}
                </Typography>
              </td>
            </tr>
            <tr className="h-[45px] odd:bg-black-100">
              <td className="px-[16px] md:px-[30px]">
                <Typography isBold>
                  保険料
                  <br className="block md:hidden" />
                  払い込み
                </Typography>
              </td>
              <td className="px-[16px] md:px-[30px]">
                <Typography>
                  {item?.contractor_type === CONTRACTOR_TYPE.otherPersonContract
                    ? convertPeriodFinishAt(
                        item?.insurance_fee_payment_period_type,
                        item?.insurance_fee_payment_period_finish_at,
                        item?.contractor_type,
                        item?.contractor_name,
                        'contractor',
                      )
                    : convertPeriod(
                        item?.insurance_fee_payment_period_type,
                        item?.insurance_fee_payment_period_finish_at_age,
                        item?.contractor_type,
                        item?.contractor_name,
                        'contractor',
                      )}
                </Typography>
              </td>
            </tr>
          </>
        )}
        <tr className="h-[45px] odd:bg-black-100">
          <td className="px-[16px] md:px-[30px]">
            <Typography isBold>保険料</Typography>
          </td>
          <td className="px-[16px] md:px-[30px]">
            <Typography>
              {`${
                item?.['is_life_insurance?']
                  ? convertInsuranceFeePaymentOccurrenceInMonths(
                      item?.insurance_fee_payment_occurrence_in_months,
                    )
                  : convertNonLifeInsuranceFeeMonths(
                      item?.insurance_fee_payment_occurrence_in_months,
                    )
              } ${convertLocaleString(item?.insurance_fee)}円`}
            </Typography>
          </td>
        </tr>
        {item?.insurance_type1 === INSURANCE_TYPE1.life &&
          item?.insurance_type2 !== INSURANCE_TYPE2.incomeSecurity && (
            <tr className="h-[45px] odd:bg-black-100">
              <td className="px-[16px] md:px-[30px]">
                <Typography isBold>
                  死亡保険
                  <br className="block md:hidden" />
                  金額
                </Typography>
              </td>
              <td className="px-[16px] md:px-[30px]">
                <Typography>
                  {`${convertLocaleString(item?.insurance_amount_manyen)}万円`}
                </Typography>
              </td>
            </tr>
          )}
        {item.insurance_type1 === INSURANCE_TYPE1.life &&
          item.insurance_type2 === INSURANCE_TYPE2.wholeLife &&
          item.purpose === PURPOSE.savings && (
            <tr className="h-[45px] odd:bg-black-100">
              <td className="px-[16px] md:px-[30px]">
                <Typography isBold>解約返戻金</Typography>
              </td>
              <td className="px-[16px] md:px-[30px]">
                <Typography className="my-[10px]">
                  {item?.insured_type === INSURED_TYPE.otherPersonInsured
                    ? convertCancellationRefundAt(
                        item?.cancellation_refund_at,
                        item?.cancellation_refund_amount_manyen,
                        item?.cancellation_refund_rate_percent,
                        item?.cancellation_refund_amount_input_type,
                      )
                        .split(' ')
                        .map((item) => <Typography>{item}</Typography>)
                    : convertCancellationRefundAmountManyen(
                        item?.cancellation_refund_at_age,
                        item?.cancellation_refund_amount_manyen,
                        item?.cancellation_refund_rate_percent,
                        item?.cancellation_refund_amount_input_type,
                        item?.contractor_type,
                        item?.contractor_name,
                      )
                        .split(' ')
                        .map((item) => <Typography>{item}</Typography>)}
                </Typography>
              </td>
            </tr>
          )}
        {item.insurance_type1 === INSURANCE_TYPE1.life &&
          item.insurance_type2 === INSURANCE_TYPE2.incomeSecurity && (
            <tr className="h-[45px] odd:bg-black-100">
              <td className="px-[16px] md:px-[30px]">
                <Typography isBold>保険月額</Typography>
              </td>
              <td className="px-[16px] md:px-[30px]">
                <Typography>
                  {`月額 ${item.insured_income_monthly_manyen}万円`}
                </Typography>
              </td>
            </tr>
          )}
        {item?.insurance_type1 === INSURANCE_TYPE1.life &&
          item?.insurance_type2 === INSURANCE_TYPE2.endowment && (
            <tr className="h-[45px] odd:bg-black-100">
              <td className="px-[16px] md:px-[30px]">
                <Typography isBold>満期金</Typography>
              </td>
              <td className="px-[16px] md:px-[30px]">
                <Typography>
                  {convertMaturityMoney(item.maturity_money_type, item.maturity_money)}
                </Typography>
              </td>
            </tr>
          )}
        {item?.insurance_type1 === INSURANCE_TYPE1.personalAnnuity && (
          <>
            <tr className="h-[45px] odd:bg-black-100">
              <td className="px-[16px] md:px-[30px]">
                <Typography isBold>受取年齢</Typography>
              </td>
              <td className="px-[16px] md:px-[30px]">
                <Typography>
                  {`${defaultToEmptyString(item?.receiving_pension_at_age)}歳`}
                </Typography>
              </td>
            </tr>
            <tr className="h-[45px] odd:bg-black-100">
              <td className="px-[16px] md:px-[30px]">
                <Typography isBold>受取額</Typography>
              </td>
              <td className="px-[16px] md:px-[30px]">
                <Typography>
                  {`年間 ${convertLocaleString(item.receiving_pension_amount)}円`}
                </Typography>
              </td>
            </tr>
            <tr className="h-[45px] odd:bg-black-100">
              <td className="px-[16px] md:px-[30px]">
                <Typography isBold>受取期間</Typography>
              </td>
              <td className="px-[16px] md:px-[30px]">
                <Typography>
                  {`${defaultToEmptyString(item.receiving_years)}年`}
                </Typography>
              </td>
            </tr>
            <tr className="h-[45px] odd:bg-black-100">
              <td className="px-[16px] md:px-[30px]">
                <Typography isBold>保証期間</Typography>
              </td>
              <td className="px-[16px] md:px-[30px]">
                <Typography>
                  {`${defaultToEmptyString(item.receiving_guaranteed_years)}年`}
                </Typography>
              </td>
            </tr>
          </>
        )}
      </tbody>
    </table>
  )
}

const FutureInsuranceForm: FC<FutureInsuranceFormProps> = (props) => {
  const dispatch = useDispatch()
  const { showFlashMsg } = useFlashAlert()
  const { isManipulatedApproval } = useContext(SimulationDetailEditContext)

  const { selectedSimulationId, handleSelect } = props

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [insuranceId, setInsuranceId] = useState<number | null>(null)

  const futureInsurances = useSelector(
    (state: RootState) => state.accounts.futureItems?.future_insurances,
  )
  const { simulationInfo } = useSelector((state: RootState) => state.futureBases)
  const hasSpouse = simulationInfo.has_married || simulationInfo.has_married_in_future

  const isItemApplicable = (item: FutureInsurance): boolean => {
    if (!hasSpouse) {
      return (
        item.contractor_type != CONTRACTOR_TYPE.spouseContract &&
        item.insured_type != INSURED_TYPE.spouseInsured
      )
    }

    return true
  }

  useEffect(() => {
    if (props.futureBaseId == null || !props.shouldInitialize) return
    _filter(futureInsurances, { future_base_id: props.futureBaseId }).forEach((item) =>
      handleSelect(item),
    )
  }, [props.shouldInitialize])

  const sortedFutureInsurances = useMemo(() => {
    if (props.futureBaseId == null || futureInsurances == null) return futureInsurances
    const eqFbId = (item: any) => item.future_base_id === props.futureBaseId
    return [
      ...futureInsurances.filter((item) => eqFbId(item)),
      ...futureInsurances.filter((item) => !eqFbId(item)),
    ]
  }, [futureInsurances])

  const titleIndex = (index: number) => {
    if (
      props.futureBaseId == null ||
      props.confirmEdit == null ||
      futureInsurances == null
    )
      return index + 1
    const eqFbId = (item: any) => item.future_base_id === props.futureBaseId
    return index + 1 - futureInsurances.filter((item) => eqFbId(item)).length
  }

  const isExpiredSettings = (date?: string) => {
    if (date == null) return false
    const settingsDate = new Date(date)
    if (settingsDate.getFullYear() === THIS_YEAR_NUMBER)
      return settingsDate.getMonth() < THIS_MONTH_NUMBER
    return settingsDate.getFullYear() < THIS_YEAR_NUMBER
  }

  const handleExecIfConfired = (exec: (item: any) => void, item: any) => {
    props.confirmEdit === false ? props.openEditConfirmModal?.() : exec(item)
  }

  return (
    <SimulationAccordion
      className="pb-[16px]"
      title="保険にかかる費用を検討"
      isSelected={selectedSimulationId != null && selectedSimulationId.length !== 0}
      selectedCount={selectedSimulationId?.length}
      itemCount={sortedFutureInsurances?.length}
      isExpiredSettings={sortedFutureInsurances?.some(
        (item) =>
          _includes(selectedSimulationId, item.id) &&
          isExpiredSettings(item.contracted_at ?? undefined),
      )}
    >
      <div className="flex flex-col gap-y-[12px] pt-[6px]">
        {sortedFutureInsurances?.map((item: any, index: number) => {
          const handleDestroyV2 = (item: any) => {
            const result = confirm('本当に削除してよろしいですか？')
            if (!result) return

            const destroyFunc = new Promise((resolve) => {
              dispatch(
                destroyFutureInsuranceV2(item, item.id, () => {
                  resolve('')
                  showFlashMsg('削除しました')
                }),
              )
            })

            Promise.all([destroyFunc]).then(() => {
              if (_includes(selectedSimulationId, item.id)) handleSelect(item)
              dispatch(fetchAccountFutureItems(props.futureBaseId))
            })
          }

          const handleEdit = (item: any) => {
            setInsuranceId(item.id)
            setIsEdit(true)
            setIsOpenModal(true)
          }

          return (
            <SimulationItemAccordion
              key={`${item.id}_${index}`}
              title={
                props.futureBaseId === item.future_base_id && props.confirmEdit != null
                  ? '登録中の保険'
                  : `保険${titleIndex(index)}`
              }
              isSelected={_includes(selectedSimulationId, item.id)}
              isExpiredSettings={isExpiredSettings(item.contracted_at)}
              inputType="checkbox"
              onSelect={() => {
                if (isItemApplicable(item)) {
                  handleExecIfConfired(handleSelect, item)
                }
              }}
            >
              <div className="flex justify-end mb-[5px]">
                <Button
                  className="mr-[16px]"
                  isDisabled={!isManipulatedApproval}
                  onClick={(e) => {
                    handleExecIfConfired(handleEdit, item)
                    e.stopPropagation()
                  }}
                >
                  <i className="ico edit text-[14px] text-black-700" />
                  <Typography className="pl-[5px] text-[14px] text-black-700">
                    編集
                  </Typography>
                </Button>
                <Button
                  isDisabled={!isManipulatedApproval}
                  onClick={(e) => {
                    handleExecIfConfired(handleDestroyV2, item)
                    e.stopPropagation()
                  }}
                >
                  <Typography className="pl-[5px] text-[14px] text-black-700">
                    削除
                  </Typography>
                </Button>
              </div>
              {!isItemApplicable(item) ? (
                <Tooltip
                  className="w-full"
                  item={
                    <div className="w-[330px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50">
                      配偶者が登録・選択されていないため設定できません。
                      <br />
                      設定するためには、以下どちらかの条件が必要です。
                      <br />
                      1. マイデータの「ご家族の情報」から配偶者を登録する
                      <br />
                      2.
                      シミュレーションパネルの「結婚の検討」から結婚予定を登録・選択する
                    </div>
                  }
                >
                  <FutureInsuranceTable item={item} />
                </Tooltip>
              ) : (
                <FutureInsuranceTable item={item} />
              )}
            </SimulationItemAccordion>
          )
        })}
      </div>
      <SimulationNewButton
        isDisabled={!isManipulatedApproval}
        onClick={() => {
          setInsuranceId(null)
          setIsEdit(false)
          setIsOpenModal(true)
        }}
      />

      {isOpenModal && (
        <FutureInsuranceModalForm
          isOpen={isOpenModal}
          isEdit={isEdit}
          hasSpouse={hasSpouse}
          id={insuranceId}
          onClose={() => setIsOpenModal(false)}
          futureBaseId={props.futureBaseId}
        />
      )}
    </SimulationAccordion>
  )
}

export default FutureInsuranceForm
