import React, { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { isUndefined as _isUndefined } from 'lodash'
import Typography from '@/components/v2/atoms/Typography'
import { convertLocaleString } from '@/models/commonsModelFunc'
import { RootState } from '@/store'

interface TooltipTransitionAssetProps {
  /** アクティブ情報 */
  activeIndex: number | undefined
}

const TooltipTransitionAsset: FC<TooltipTransitionAssetProps> = (props) => {
  const { activeIndex } = props
  const {
    summaryTransitions: { asset_graph },
  } = useSelector((state: RootState) => state.summaries)

  const assetGraph = useMemo(() => {
    return (
      asset_graph?.map((obj: any) => {
        let newObj = { ...obj }
        newObj.date = obj.date.replace('-', '/')
        return newObj
      }) || []
    )
  }, [asset_graph])

  const activeItem: any = !_isUndefined(activeIndex) ? assetGraph?.[activeIndex] : {}

  return (
    <>
      {!_isUndefined(activeIndex) ? (
        <div className="md:w-[250px] py-[24px] px-[16px] rounded-[8px] bg-white md:shadow-modal visible pointer-events-auto">
          <div className="mb-[16px] p-[10px] rounded-[4px] bg-primary-50">
            <Typography className="pb-[4px]" isBold>
              {activeItem.date}
            </Typography>
            <Typography isBold>{`合計：${convertLocaleString(
              activeItem.total_manyen,
            )}万円`}</Typography>
          </div>

          <div className="flex justify-between items-center pb-[8px] last:pb-0">
            <div className="flex items-center">
              <i
                className="ico rect-2 pr-[8px] text-[12px]"
                style={{ color: '#add6f0' }}
              />
              <Typography className="text-[12px]">流動性資産</Typography>
            </div>
            <Typography className="text-[12px]">{`${convertLocaleString(
              activeItem.bank_manyen,
            )}万円`}</Typography>
          </div>
          <div className="flex justify-between items-center pb-[8px] last:pb-0">
            <div className="flex items-center">
              <i
                className="ico rect-2 pr-[8px] text-[12px]"
                style={{ color: '#b0e3e0' }}
              />
              <Typography className="text-[12px]">投資信託</Typography>
            </div>
            <Typography className="text-[12px]">{`${convertLocaleString(
              activeItem.trust_fund_manyen,
            )}万円`}</Typography>
          </div>
          <div className="flex justify-between items-center pb-[8px] last:pb-0">
            <div className="flex items-center">
              <i
                className="ico rect-2 pr-[8px] text-[12px]"
                style={{ color: '#fde998' }}
              />
              <Typography className="text-[12px]">株式</Typography>
            </div>
            <Typography className="text-[12px]">{`${convertLocaleString(
              activeItem.stock_manyen,
            )}万円`}</Typography>
          </div>
          <div className="flex justify-between items-center pb-[8px] last:pb-0">
            <div className="flex items-center">
              <i
                className="ico rect-2 pr-[8px] text-[12px]"
                style={{ color: '#ff9933' }}
              />
              <Typography className="text-[12px]">債券</Typography>
            </div>
            <Typography className="text-[12px]">{`${convertLocaleString(
              activeItem.bond_manyen,
            )}万円`}</Typography>
          </div>
          <div className="flex justify-between items-center pb-[8px] last:pb-0">
            <div className="flex items-center">
              <i
                className="ico rect-2 pr-[8px] text-[12px]"
                style={{ color: '#cc6666' }}
              />
              <Typography className="text-[12px]">社内預金・財形・持株など</Typography>
            </div>
            <Typography className="text-[12px]">{`${convertLocaleString(
              activeItem.deducted_savings_manyen,
            )}万円`}</Typography>
          </div>
          <div className="flex justify-between items-center pb-[8px] last:pb-0">
            <div className="flex items-center">
              <i
                className="ico rect-2 pr-[8px] text-[12px]"
                style={{ color: '#e0e0e0' }}
              />
              <Typography className="text-[12px]">その他</Typography>
            </div>
            <Typography className="text-[12px]">{`${convertLocaleString(
              activeItem.other_manyen,
            )}万円`}</Typography>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default TooltipTransitionAsset
