import ApiClient from '@/api/apiClient'
import {
  HousingLoanPaymentSummary,
  InputHousing,
  InputHousingDestroyValues,
} from '@/models/inputs/inputHousingsModel'
import {
  InputHousingRentCreateValues,
  InputHousingRentUpdateValues,
} from '@/models/inputs/inputHousingRentsModel'
import {
  InputHousingSelfOwnedCreateValues,
  InputHousingSelfOwnedUpdateValues,
} from '@/models/inputs/inputHousingSelfOwnedModel'
import {
  InputHousingTransferCreateValues,
  InputHousingTransferUpdateValues,
} from '@/models/inputs/inputHousingTransfersModel'

export const getInputHousings = async () => {
  return await ApiClient.get(
    '/input_housings',
    (response: InputHousing[]) => response,
    undefined,
    false,
    'v2',
  )
}

export const getHousingLoanPaymentSummary = async () => {
  return await ApiClient.get(
    '/input_housings/housing_loan_payment_summary',
    (response: HousingLoanPaymentSummary) => response,
    undefined,
    false,
    'v2',
  )
}

export const postInputHousingRent = async (values: InputHousingRentCreateValues) => {
  return await ApiClient.post('/input_housings/create_housing_for_rent', () => {}, values)
}

export const postInputHousingSelfOwned = async (
  values: InputHousingSelfOwnedCreateValues,
) => {
  return await ApiClient.post(
    '/input_housings/create_housing_for_self_owned',
    () => {},
    values,
  )
}

export const postInputHousingTransfer = async (
  values: InputHousingTransferCreateValues,
) => {
  return await ApiClient.post(
    '/input_housings/create_housing_for_transfer',
    () => {},
    values,
  )
}

export const patchInputHousingRent = async (values: InputHousingRentUpdateValues) => {
  return await ApiClient.patch(
    '/input_housings/update_input_housing_with_rent',
    () => {},
    values,
  )
}

export const patchInputHousingSelfOwned = async (
  values: InputHousingSelfOwnedUpdateValues,
) => {
  return await ApiClient.patch(
    '/input_housings/update_input_housing_with_self_owned',
    () => {},
    values,
  )
}

export const patchInputHousingTransfer = async (
  values: InputHousingTransferUpdateValues,
) => {
  return await ApiClient.patch(
    '/input_housings/update_input_housing_with_transfer',
    () => {},
    values,
  )
}

export const deleteInputHousing = async (values: {
  params: InputHousingDestroyValues
}) => {
  return await ApiClient.delete('/input_housings', () => {}, values)
}
