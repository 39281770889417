import React, { FC } from 'react'
import { Field } from 'react-final-form'
import clsx from 'clsx'
import { isUndefined as _isUndefined } from 'lodash'
import Button from '@/components/v2/atoms/Button'
import Typography from '@/components/v2/atoms/Typography'

export interface SelectionButtonOption {
  /** 値 */
  value: any
  /** ラベル */
  label: string
  /** 補足テキスト */
  supplementText?: string
}

interface SelectionButtonProps {
  /** 名前 */
  name: string
  /** オプション */
  options: SelectionButtonOption[]
  /** バリデーションメソッド */
  validate?: (value: any) => void
  /** onClickメソッド */
  onClick?: (value: any) => void
  /** true: 押下不能, false: 押下可能 */
  isDisabled?: boolean
  /** 横幅クラス */
  widthClassName?: string
}

const SelectionButton: FC<SelectionButtonProps> = (props) => {
  const { name, options, validate, onClick, isDisabled, widthClassName } = props

  return (
    <Field name={name} validate={validate}>
      {(props) => {
        const { input } = props

        const handleClick = (value: any) => {
          input.onChange(value)
          onClick?.(value)
        }

        return (
          <div className="flex flex-wrap gap-8">
            {options.map((item) => (
              <Button
                key={item.value}
                className={clsx(
                  `bg-white border-2 border-primary-100 border-solid px-24 md:px-32 py-16 md:py-24 rounded-8 ${
                    widthClassName || ''
                  }`,
                  {
                    ['bg-primary-100']: item.value === input.value,
                    ['hover:bg-primary-50']: item.value !== input.value,
                  },
                )}
                onClick={() => handleClick(item.value)}
                isDisabled={isDisabled}
              >
                <Typography className="text-14 md:text-16 text-black-900" isBold>
                  {item.label}
                </Typography>
                {!_isUndefined(item.supplementText) && (
                  <Typography className="bottom-4 relative text-10 text-black-900" isBold>
                    {item.supplementText}
                  </Typography>
                )}
              </Button>
            ))}
          </div>
        )
      }}
    </Field>
  )
}

export default SelectionButton
