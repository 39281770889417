import { API_FLAG } from '../../models/commonsModelFunc'
import { PERSON_FLG } from '../../models/familyStructuresModelFunc'

// 配偶者有無 / お子様有無
export const FAMILY_TYPE_RADIO_ITEMS = [
  { value: API_FLAG.on, label: 'いる' },
  { value: API_FLAG.off, label: 'いない' },
]

// 対象者
export const PERSON_FLG_SELECT_ITEMS = [
  { value: PERSON_FLG.person, label: 'ご本人' },
  { value: PERSON_FLG.spouse, label: '配偶者' },
]

// 対象者(配偶者なし)
export const PERSON_WITHOUT_SPOUSE_SELECT_ITEM = [
  { value: PERSON_FLG.person, label: 'ご本人' },
]
