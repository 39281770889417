import React, { FC, useMemo } from 'react'
import { Form } from 'react-final-form'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import Modal from '@/components/v2/atoms/Modal'
import TextField from '@/components/v2/atoms/TextField'
import Typography from '@/components/v2/atoms/Typography'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import SelectBox from '@/components/v2/molecules/SelectBox'
import { NEW_SEX_RADIO_ITEMS } from '@/constants/formItem/commons'
import { useDispatch } from 'react-redux'
import { FpAppointmentReservationCreateValues } from '@/models/fpAppointmentReservationsModel'
import { createFpAppointmentReservationMoviePage } from '@/containers/fpAppointmentReservationsSlice'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import { composeValidators, kana, mailAddress, phoneNumber, required } from '@/utils/validate'
import clsx from 'clsx'

interface FPModalFormMoviePageProps {
  /** true: オープン, false: クローズ */
  isOpen: boolean
  /** onCloseメソッド */
  onClose: () => void
}

const FPModalFormMoviePage: FC<FPModalFormMoviePageProps> = (props) => {
  const { isOpen, onClose } = props
  const dispatch = useDispatch()
  const { showFlashMsg } = useFlashAlert()

  const handleSubmit = (values: any) => {
    const pickValues = values
    const newValues = { ...pickValues }

    new Promise((resolve) => {
      dispatch(
        createFpAppointmentReservationMoviePage(
          newValues as FpAppointmentReservationCreateValues,
          () => {
            showFlashMsg('相談予約を完了しました')
            onClose()
          },
          resolve,
        ),
      )
    })
  }

  const initialValues = useMemo(() => {
    return {
      fp_appointment_reservation: {
        mail_address: "",
        name_sei: "",
        name_mei: '',
        name_sei_kana: '',
        name_mei_kana: '',
        tel_number: '',
        sex: '',
      },
    }
  }, [])

  return (
    <Modal
      className="w-[95%] md:w-[561px] overscroll-none"
      modalContainerClassName="overscroll-none"
      modalOverlayClassName="overscroll-none"
      isDisableBackdropClick
      isOpen={isOpen}
      onClose={onClose}
    >
      <CircleTitle className="pb-[10px]" title="企業会員従業員様用<br class='sm:hidden'>個別相談フォーム" />
      <Typography className="pb-[32px] text-[14px]">
        企業会員に属する皆様は、このフォームからお申込みいただくことで費用負担なしでご相談いただけます。<br />
        (注)一般窓口からのお問い合わせは1相談あたり15000円が請求されてしまいます。こちらからのお問い合わせをご利用ください。<br />
        <br />
        お申込後、担当のFPから連絡申し上げます。
      </Typography>

      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        render={({ handleSubmit, values, errors }) => (
          <form onSubmit={handleSubmit}>
            <div className="md:flex items-baseline mb-[16px] last:mb-0 pb-[16px] border-black-300 border-dashed border-b">
              <Typography
                className="min-w-[180px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                isBold
              >
                お名前
              </Typography>
              <TextField
                type="text"
                name="fp_appointment_reservation.name_sei"
                placeholder='お名前'
                validate={composeValidators(required)}
                isFullWidth
              />
            </div>

            <div className="md:flex items-baseline mb-[16px] last:mb-0 pb-[16px] border-black-300 border-dashed border-b">
              <Typography
                className="min-w-[180px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                isBold
              >
                ふりがな
              </Typography>
              <TextField
                type="text"
                name="fp_appointment_reservation.name_sei_kana"
                placeholder='おなまえ'
                validate={composeValidators(required, kana)}
                isFullWidth
              />
            </div>

            {/* <div className="md:flex items-baseline mb-[16px] last:mb-0 pb-[16px] border-black-300 border-dashed border-b">
              <Typography
                className="min-w-[180px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                isBold
              >
                性別
              </Typography>
              <SelectBox
                name="fp_appointment_reservation.sex"
                placeholder="選択する"
                options={NEW_SEX_RADIO_ITEMS}
                isFullWidth
              />
            </div> */}

            <div className="md:flex items-baseline mb-[16px] last:mb-0 pb-[16px] border-black-300 border-dashed border-b">
              <Typography
                className="min-w-[180px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                isBold
              >
                電話番号（ハイフンなし）
              </Typography>
              <TextField
                type="text"
                name="fp_appointment_reservation.tel_number"
                isFullWidth
                validate={composeValidators(phoneNumber,)}
              />
            </div>

            <div className="md:flex items-baseline mb-[16px] last:mb-0 pb-[16px] border-black-300 border-dashed border-b">
              <Typography
                className="min-w-[180px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                isBold
              >
                メールアドレス
              </Typography>
              <TextField
                type="text"
                name="fp_appointment_reservation.mail_address"
                validate={composeValidators(required, mailAddress)}
                isFullWidth
              />
            </div>

            <ButtonPrimary
              className={clsx("w-[188px] h-[45px] mx-auto", { ['opacity-50']: Object.keys(errors).length > 0 },)}
              onClick={() => handleSubmit(values)}
            >
              <Typography className="text-[14px] text-white" isBold>
                予約する
              </Typography>
            </ButtonPrimary>
          </form>
        )}
      />
    </Modal>
  )
}

export default FPModalFormMoviePage
