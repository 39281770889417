import clsx from 'clsx'
import React, { FC } from 'react'

interface IconButtonProps {
  /** アイコン名 */
  icon: string
  /** クラス名 */
  className?: string
  /** onClickメソッド */
  onClick: () => void
}

const IconButton: FC<IconButtonProps> = (props) => {
  const { icon, className, onClick } = props

  return (
    <button type="button" className={className} onClick={onClick}>
      <i className={clsx('ico', icon)} />
    </button>
  )
}

export default IconButton
