import { includes as _includes } from 'lodash'
import React, { FC, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'

import Typography from '@/components/v2/atoms/Typography'
import Tab from '@/components/v2/molecules/Tab'
import { fetchAccountShowStepStatusV2 } from '@/containers/accountsSlice'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import { AccountStepStatus } from '@/models/accountsModel'
import { RootState } from '@/store'

const AccountDetailHeader: FC = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { handleMovePage } = useCustomHistory()
  const { account_id } = useParams<{ account_id?: string }>()

  const { account } = useSelector((state: RootState) => state.accounts)
  const nameLabel = useMemo(() => `${account?.name_sei} ${account?.name_mei}`, [account])

  const setCurrentIndex = () => {
    if (_includes(location.pathname, 'dashboards')) return 0
    if (_includes(location.pathname, 'simulations')) return 1
    if (_includes(location.pathname, 'myData')) return 2

    return undefined
  }

  const handleMoveAccountPage = (index: number) => {
    let url = ''
    switch (index) {
      case 0:
        url = `/v2/accounts/${account_id}/dashboards`
        break
      case 1:
        url = `/v2/accounts/${account_id}/simulations`
        break
      case 2:
        dispatch(
          fetchAccountShowStepStatusV2((response: AccountStepStatus) => {
            const url = response.has_input_simple_data
              ? `/v2/accounts/${account_id}/myData`
              : `/v2/accounts/${account_id}/myData/simple`
            handleMovePage(url)
          }),
        )
        url = `/v2/accounts/${account_id}/myData`
        break
    }

    handleMovePage(url)
  }

  return (
    <>
      <div className="sticky top-0 flex py-[25px] px-[10px] h-[30px] z-50 bg-black-300">
        <div className="flex items-center">
          <Typography className="pr-[5px] text-[20px]" isBold>
            {nameLabel}
          </Typography>
          <Typography className="pr-[5px] text-[20px]">様</Typography>
          <Typography className="relative top-[2px] pr-[5px] text-[14px]">
            を表示中
          </Typography>
        </div>
      </div>

      <Tab
        className="h-[50px]"
        currentIndex={setCurrentIndex()}
        options={['ダッシュボード', 'シミュレーション', 'マイデータ']}
        onClick={handleMoveAccountPage}
      />
    </>
  )
}

export default AccountDetailHeader
