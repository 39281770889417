import React, { FC, useContext, useEffect } from 'react'
import { useField, useForm } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { Clear as ClearIcon } from '@material-ui/icons'
import { isUndefined as _isUndefined } from 'lodash'
import CityBankInterestRateTable from '@/components/v2/atoms/CityBankInterestRateTable'
import { TWO_TYPES_YEAR_SELECT_ITEMS } from '@/constants/formItem/commons'
import { DescriptionContext } from '@/hooks/useDescriptionTemplates'
import { THIS_YEAR } from '@/models/commonsModelFunc'
import { getTargetLoanCalculationRateName } from '@/models/commonHousingModelFunc'
import { selectFutureV2LoanObjectName } from '@/models/futures/futureV2HousingLoansModelFunc'
import { selectFutureV2LoanCalculationObjectName } from '@/models/futures/futureV2HousingLoanCalculationsModelFunc'
import { InputCalculationRate } from '@/models/inputs/inputCalculationRatesModel'
import { selectLoanCalculationObjectName } from '@/models/inputs/inputLoanCalculationsModelFunc'
import {
  selectLoanObjectName,
  selectInputLoanObjectNumber,
  isFlatLoan,
} from '@/models/inputs/inputLoansModelFunc'
import {
  commaNumberMaxLength,
  composeValidators,
  required,
  zeroOrMoreNumber,
} from '@/utils/validate'
import { buildStandardSelectItems } from '@/utils/formItem'

import Button from '@/components/v2/atoms/Button'
import Typography from '@/components/v2/atoms/Typography'
import SelectBox from '@/components/v2/molecules/SelectBox'
import NumberFieldDecimal from '@/components/v2/molecules/NumberFieldDecimal'
import IconButton from '@/components/v2/atoms/IconButton'
import PlusCircleIcon from '@/assets/images/v2/plus-circle.svg'
import CrossIcon from '@/assets/images/v2/cross.svg'
import Tooltip from '@/components/v2/atoms/Tooltip'
import HelpCircleIcon from '@/assets/images/v2/help-circle.svg'

interface InterestRatesProps {
  /** true: シミュレーション, false: 現状 */
  isFuture?: boolean
  /** true: 建物用ローン, false: 土地用ローン・分割なし */
  isBuilding?: boolean
  /** true: 配偶者, false: ご本人 */
  isSpouse?: boolean
}

const InterestRates: FC<InterestRatesProps> = (props) => {
  const { isFuture, isBuilding, isSpouse } = props
  const { setSendPageValueKeys } = useContext(DescriptionContext)
  const { change } = useForm()
  const loanObjectNum = selectInputLoanObjectNumber(isBuilding, isSpouse)
  const flatLoanObjectNum = isBuilding ? 2 : 0
  const rateChangeLoanObjectNum = isBuilding ? 3 : 1
  const targetLoanCalculationRateName = getTargetLoanCalculationRateName(isFuture)
  const selectTargetLoanObjectName = isFuture
    ? selectFutureV2LoanObjectName
    : selectLoanObjectName
  const selectTargetLoanCalcObjectName = isFuture
    ? selectFutureV2LoanCalculationObjectName
    : selectLoanCalculationObjectName

  const {
    input: { value: loanLender },
  } = useField(selectTargetLoanObjectName(loanObjectNum, 'loan_lender'))

  useEffect(() => {
    setSendPageValueKeys([
      selectTargetLoanCalcObjectName(
        rateChangeLoanObjectNum,
        `${targetLoanCalculationRateName}_attributes[0]changed_rate_percent`,
      ),
      selectTargetLoanCalcObjectName(
        flatLoanObjectNum,
        `${targetLoanCalculationRateName}_attributes[0]changed_rate_percent`,
      ),
      selectTargetLoanCalcObjectName(
        loanObjectNum,
        `${targetLoanCalculationRateName}_attributes[0]changed_rate_percent`,
      ),
      selectTargetLoanCalcObjectName(
        loanObjectNum,
        `${targetLoanCalculationRateName}_other_attributes`,
      ),
    ])
  }, [])

  const handleAddInputCalculationRateBlock = (
    calculationRates: Partial<InputCalculationRate>[],
    index: number,
    change: (key: string, values: Partial<InputCalculationRate>[]) => void,
  ) => {
    const isEnd = index + 1 === calculationRates.length
    isEnd ? calculationRates.push({}) : calculationRates.splice(index + 1, 0, {})

    change(
      selectTargetLoanCalcObjectName(
        loanObjectNum,
        `${targetLoanCalculationRateName}_other_attributes`,
      ),
      calculationRates,
    )
  }

  return (
    <>
      {isFlatLoan(loanLender) ? (
        <>
          <div className="pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
            <div className="lg:flex items-center">
              <Typography
                className="flex min-w-[140px] lg:min-w-[220px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                isBold
              >
                フラット適用部分の金利
                <div className="mx-[10px]">
                  <Tooltip
                    item={<CityBankInterestRateTable />}
                    itemClassName="w-[200px] md:w-[350px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                  >
                    <HelpCircleIcon />
                  </Tooltip>
                </div>
              </Typography>
              <div className="flex flex-auto items-baseline">
                <NumberFieldDecimal
                  isFullWidth
                  name={selectTargetLoanCalcObjectName(
                    flatLoanObjectNum,
                    `${targetLoanCalculationRateName}_attributes[0]changed_rate_percent`,
                  )}
                  validate={composeValidators(required, zeroOrMoreNumber, (value: any) =>
                    commaNumberMaxLength(value, 6),
                  )}
                />
                <Typography className="pl-[8px] text-[14px] text-black-800" isBold>
                  ％
                </Typography>
              </div>
            </div>
          </div>

          <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
            <Typography
              className="min-w-[140px] lg:min-w-[210px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
              isBold
            >
              変動金利部分の金利
            </Typography>
            <div className="flex flex-auto items-baseline">
              <Typography
                className="pr-[8px] text-[14px] text-black-800 min-w-[75px]"
                isBold
              >
                当初金利
              </Typography>
              <NumberFieldDecimal
                isFullWidth
                name={selectTargetLoanCalcObjectName(
                  rateChangeLoanObjectNum,
                  `${targetLoanCalculationRateName}_attributes[0]changed_rate_percent`,
                )}
                validate={composeValidators(required, zeroOrMoreNumber, (value: any) =>
                  commaNumberMaxLength(value, 6),
                )}
              />
              <Typography className="pl-[8px] text-[14px] text-black-800" isBold>
                ％
              </Typography>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
            <div className="lg:flex items-center">
              <Typography
                className="flex items-center min-w-[140px] lg:min-w-[210px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                isBold
              >
                借入金利
                <div className="mx-[10px]">
                  <Tooltip
                    item={<CityBankInterestRateTable />}
                    itemClassName="w-[200px] md:w-[350px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                  >
                    <HelpCircleIcon />
                  </Tooltip>
                </div>
              </Typography>
              <div className="flex flex-auto justify-end items-baseline">
                <Typography
                  className="pr-[8px] text-[14px] text-black-800 min-w-[fit-content]"
                  isBold
                >
                  現在
                </Typography>
                <NumberFieldDecimal
                  isFullWidth
                  name={selectTargetLoanCalcObjectName(
                    loanObjectNum,
                    `${targetLoanCalculationRateName}_attributes[0]changed_rate_percent`,
                  )}
                  validate={composeValidators(required, zeroOrMoreNumber, (value: any) =>
                    commaNumberMaxLength(value, 6),
                  )}
                />
                <Typography className="pl-[8px] text-[14px] text-black-800" isBold>
                  ％
                </Typography>
              </div>
            </div>
          </div>
        </>
      )}

      <div className="pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
        <div className="flex justify-start min-w-[140px] lg:min-w-[210px]">
          <Typography
            className="flex items-center pb-[10px] text-[14px] text-black-800"
            isBold
          >
            金利の変化
            <div className="mx-[10px]">
              <Tooltip
                item="将来金利の変動を想定する場合は、いつから◯％という設定ができます"
                itemClassName="w-[200px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
              >
                <HelpCircleIcon />
              </Tooltip>
            </div>
          </Typography>
        </div>
        <div>
          <FieldArray
            name={selectTargetLoanCalcObjectName(
              loanObjectNum,
              `${targetLoanCalculationRateName}_other_attributes`,
            )}
          >
            {({ fields }) =>
              fields.map((name, index) => (
                <React.Fragment key={name}>
                  <div className="">
                    {index > 0 && (
                      <IconButton
                        icon="add"
                        className=""
                        children={<ClearIcon />}
                        onClick={() => fields.remove(index)}
                      />
                    )}
                    <div className="flex flex-auto items-baseline">
                      <SelectBox
                        isFullWidth
                        name={`${name}rate_start_at_year`}
                        isPlaceholderHidden
                        options={buildStandardSelectItems(
                          TWO_TYPES_YEAR_SELECT_ITEMS,
                          THIS_YEAR,
                          '年を選択',
                        )}
                      />
                      <Typography className="pl-[8px] text-[14px] text-black-800 min-w-[45px]">
                        から
                      </Typography>
                      <NumberFieldDecimal
                        isFullWidth
                        className=""
                        name={`${name}changed_rate_percent`}
                        validate={composeValidators(zeroOrMoreNumber, (value: any) =>
                          commaNumberMaxLength(value, 5),
                        )}
                      />
                      <Typography className="pl-[8px] text-[14px] text-black-800" isBold>
                        %
                      </Typography>
                      {fields.length != 1 && (
                        <div className="flex relative items-center  w-[80px] justify-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => fields.remove(index)}
                          >
                            <CrossIcon className="cursor-pointer" />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  {!_isUndefined(fields) && fields.length === index + 1 && (
                    <Button
                      className="ml-auto border-[0.5px] border-black-700 rounded-[4px] my-[16px]"
                      onClick={() =>
                        handleAddInputCalculationRateBlock(
                          [...fields.value],
                          index,
                          change,
                        )
                      }
                    >
                      <div className="flex items-center">
                        <PlusCircleIcon className="ml-[10px] mt-[2px]" />
                        <Typography
                          className="min-w-[120p] p-[10px] text-[14px] text-black-800 flex items-center"
                          isBold
                        >
                          入力枠を追加する
                        </Typography>
                      </div>
                    </Button>
                  )}
                </React.Fragment>
              ))
            }
          </FieldArray>
        </div>
      </div>
    </>
  )
}

export default InterestRates
