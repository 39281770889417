import React, { FC } from 'react'
import { YES_NO_BUTTON_ITEMS } from '@/constants/formItem/commons'
import { required } from '@/utils/validate'

import Typography from '@/components/v2/atoms/Typography'
import SelectBox from '@/components/v2/molecules/SelectBox'

const LifeInsuranceContract: FC = () => {
  return (
    <>
      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
        <Typography
          className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 mr-[10px] text-[14px] text-black-800"
          isBold
        >
          団体信用生命保険への加入
        </Typography>
        <SelectBox
          name="future_lend_housing.housing_loan_attributes.has_loan_life_insurance_contract"
          placeholder="選択する"
          isFullWidth
          options={YES_NO_BUTTON_ITEMS}
          validate={required}
        />
      </div>
    </>
  )
}

export default LifeInsuranceContract
