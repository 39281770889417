import {
  isWithChartLoan,
  isPairLoan,
  isFlatLoan,
  isWithCalcLoan,
} from '@/models/commonHousingModelFunc'
import { FutureV2HousingLoan } from '@/models/futures/futureV2HousingLoansModel'

// 単独ローン and (返済計画表あり or 返済計画表なし・民間ローン)
export const isOneLoanPattern = (
  futureLoan: Partial<FutureV2HousingLoan> | undefined,
) => {
  return (
    !isPairLoan(futureLoan?.loan_type) &&
    (isWithChartLoan(futureLoan?.repayment_chart) ||
      (!isFlatLoan(futureLoan?.loan_lender) &&
        isWithCalcLoan(futureLoan?.repayment_chart)))
  )
}

// 返済計画表なし・ペアローン/フラット
export const isTowLoanWithCalculation = (
  futureLoan: Partial<FutureV2HousingLoan> | undefined,
) => {
  return (
    isWithCalcLoan(futureLoan?.repayment_chart) &&
    (isPairLoan(futureLoan?.loan_type) || isFlatLoan(futureLoan?.loan_lender))
  )
}

// 返済計画表あり・ペアローン
export const isTwoLoanWithChart = (
  futureLoan: Partial<FutureV2HousingLoan> | undefined,
) => {
  return isWithChartLoan(futureLoan?.repayment_chart) && isPairLoan(futureLoan?.loan_type)
}

export const isTwoLoanPattern = (
  futureLoan: Partial<FutureV2HousingLoan> | undefined,
) => {
  return isTwoLoanWithChart(futureLoan) || isTowLoanWithCalculation(futureLoan)
}

export const selectFutureV2LoanObjectName = (objectNum: number, name: string) => {
  return `future_v2_housing.future_v2_housing_self_owned_attributes.future_v2_housing_loans_attributes[${objectNum}]${name}`
}
