import React, { FC, useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Typography from '@/components/v2/atoms/Typography'
import { setPageMeta } from '@/containers/pageMetasSlice'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import { RootState } from '@/store'
import MyDataListWrapper from '@/templates/v2/myData/_ListWrapper'
import { fetchFamilyStructureV2 } from '@/containers/familyStructuresSlice'
import { FamilyStructureChild } from '@/models/familyStructureChildren'
import Button from '@/components/v2/atoms/Button'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import {
  SCHOOL_AGE,
  isChildbirthWithinRange,
  convertSchoolType,
  convertUniversitySchoolType,
} from '@/models/inputs/inputPlanTuitionsModelFunc'
import { convertLocaleString } from '@/models/commonsModelFunc'
import { AccountContext } from '@/models/accountsModelFunc'
import { useAccountControl } from '@/hooks/useAccountControl'

const MyDataTuition: FC = () => {
  const dispatch = useDispatch()
  const { handleMovePage } = useCustomHistory()
  const { isAdmin } = useContext(AccountContext)
  const { isMyDataManipulatedApproval } = useAccountControl()

  const { familyStructureChildren } = useSelector(
    (state: RootState) => state.familyStructures,
  )

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'マイデータ | 教育費',
        description: 'マイデータ | 教育費',
        keyword: 'マイデータ | 教育費',
      }),
    )

    dispatch(fetchFamilyStructureV2())
  }, [])

  const contentArr = [
    {
      school: (item: any) => <Typography>0〜3歳児保育</Typography>,
      school_grade: (item: any) => <Typography>0歳</Typography>,
      on_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(item.input_plan_tuitions[0]?.tuition_1_manyen)}万円`}
        </Typography>
      ),
      off_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[0]?.off_campus_expenses_1_manyen,
          )}万円`}
        </Typography>
      ),
      total: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[0]?.off_campus_expenses_1_manyen +
              item.input_plan_tuitions[0]?.tuition_1_manyen,
          )}万円`}
        </Typography>
      ),
      comperison: (item: any) => SCHOOL_AGE.toddler0,
    },
    {
      school: (item: any) => <Typography>0〜3歳児保育</Typography>,
      school_grade: (item: any) => <Typography>1歳</Typography>,
      on_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(item.input_plan_tuitions[0]?.tuition_2_manyen)}万円`}
        </Typography>
      ),
      off_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[0]?.off_campus_expenses_2_manyen,
          )}万円`}
        </Typography>
      ),
      total: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[0]?.off_campus_expenses_2_manyen +
              item.input_plan_tuitions[0]?.tuition_2_manyen,
          )}万円`}
        </Typography>
      ),
      comperison: (item: any) => SCHOOL_AGE.toddler1,
    },
    {
      school: (item: any) => <Typography>0〜3歳児保育</Typography>,
      school_grade: (item: any) => <Typography>2歳</Typography>,
      on_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(item.input_plan_tuitions[0]?.tuition_3_manyen)}万円`}
        </Typography>
      ),
      off_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[0]?.off_campus_expenses_3_manyen,
          )}万円`}
        </Typography>
      ),
      total: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[0]?.off_campus_expenses_3_manyen +
              item.input_plan_tuitions[0]?.tuition_3_manyen,
          )}万円`}
        </Typography>
      ),
      comperison: (item: any) => SCHOOL_AGE.toddler2,
    },
    {
      school: (item: any) => <Typography>0〜3歳児保育</Typography>,
      school_grade: (item: any) => <Typography>3歳</Typography>,
      on_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(item.input_plan_tuitions[0]?.tuition_4_manyen)}万円`}
        </Typography>
      ),
      off_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[0]?.off_campus_expenses_4_manyen,
          )}万円`}
        </Typography>
      ),
      total: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[0]?.off_campus_expenses_4_manyen +
              item.input_plan_tuitions[0]?.tuition_4_manyen,
          )}万円`}
        </Typography>
      ),

      comperison: (item: any) => SCHOOL_AGE.toddler3,
    },

    {
      school: (item: any) => (
        <Typography>
          {convertSchoolType(item.input_plan_tuitions[2]?.school_type)} 幼稚園／保育園
        </Typography>
      ),
      school_grade: (item: any) => <Typography>年少</Typography>,
      on_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(item.input_plan_tuitions[1]?.tuition_1_manyen)}万円`}
        </Typography>
      ),
      off_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[1]?.off_campus_expenses_1_manyen,
          )}万円`}
        </Typography>
      ),
      total: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[1]?.off_campus_expenses_1_manyen +
              item.input_plan_tuitions[1]?.tuition_1_manyen,
          )}万円`}
        </Typography>
      ),
      comperison: (item: any) => SCHOOL_AGE.kindergarten1,
    },
    {
      school: (item: any) => (
        <Typography>
          {convertSchoolType(item.input_plan_tuitions[2]?.school_type)} 幼稚園／保育園
        </Typography>
      ),
      school_grade: (item: any) => <Typography>年中</Typography>,
      on_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(item.input_plan_tuitions[1]?.tuition_2_manyen)}万円`}
        </Typography>
      ),
      off_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[1]?.off_campus_expenses_2_manyen,
          )}万円`}
        </Typography>
      ),
      total: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[1]?.off_campus_expenses_2_manyen +
              item.input_plan_tuitions[1]?.tuition_2_manyen,
          )}万円`}
        </Typography>
      ),
      comperison: (item: any) => SCHOOL_AGE.kindergarten2,
    },
    {
      school: (item: any) => (
        <Typography>
          {convertSchoolType(item.input_plan_tuitions[2]?.school_type)} 幼稚園／保育園
        </Typography>
      ),
      school_grade: (item: any) => <Typography>年長</Typography>,
      on_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(item.input_plan_tuitions[1]?.tuition_3_manyen)}万円`}
        </Typography>
      ),
      off_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[1]?.off_campus_expenses_3_manyen,
          )}万円`}
        </Typography>
      ),
      total: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[1]?.off_campus_expenses_3_manyen +
              item.input_plan_tuitions[1]?.tuition_3_manyen,
          )}万円`}
        </Typography>
      ),
      comperison: (item: any) => SCHOOL_AGE.kindergarten3,
    },

    {
      school: (item: any) => (
        <Typography>
          {convertSchoolType(item.input_plan_tuitions[2]?.school_type)} 小学校
        </Typography>
      ),
      school_grade: (item: any) => <Typography>1年生</Typography>,
      on_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(item.input_plan_tuitions[2]?.tuition_1_manyen)}万円`}
        </Typography>
      ),
      off_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[2]?.off_campus_expenses_1_manyen,
          )}万円`}
        </Typography>
      ),
      total: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[2]?.off_campus_expenses_1_manyen +
              item.input_plan_tuitions[2]?.tuition_1_manyen,
          )}万円`}
        </Typography>
      ),
      comperison: (item: any) => SCHOOL_AGE.primarySchool1,
    },
    {
      school: (item: any) => (
        <Typography>
          {convertSchoolType(item.input_plan_tuitions[2]?.school_type)} 小学校
        </Typography>
      ),
      school_grade: (item: any) => <Typography>2年生</Typography>,
      on_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(item.input_plan_tuitions[2]?.tuition_2_manyen)}万円`}
        </Typography>
      ),
      off_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[2]?.off_campus_expenses_2_manyen,
          )}万円`}
        </Typography>
      ),
      total: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[2]?.off_campus_expenses_2_manyen +
              item.input_plan_tuitions[2]?.tuition_2_manyen,
          )}万円`}
        </Typography>
      ),
      comperison: (item: any) => SCHOOL_AGE.primarySchool2,
    },
    {
      school: (item: any) => (
        <Typography>
          {convertSchoolType(item.input_plan_tuitions[2]?.school_type)} 小学校
        </Typography>
      ),
      school_grade: (item: any) => <Typography>3年生</Typography>,
      on_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(item.input_plan_tuitions[2]?.tuition_3_manyen)}万円`}
        </Typography>
      ),
      off_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[2]?.off_campus_expenses_3_manyen,
          )}万円`}
        </Typography>
      ),
      total: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[2]?.off_campus_expenses_3_manyen +
              item.input_plan_tuitions[2]?.tuition_3_manyen,
          )}万円`}
        </Typography>
      ),
      comperison: (item: any) => SCHOOL_AGE.primarySchool3,
    },
    {
      school: (item: any) => (
        <Typography>
          {convertSchoolType(item.input_plan_tuitions[2]?.school_type)} 小学校
        </Typography>
      ),
      school_grade: (item: any) => <Typography>4年生</Typography>,
      on_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(item.input_plan_tuitions[2]?.tuition_4_manyen)}万円`}
        </Typography>
      ),
      off_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[2]?.off_campus_expenses_4_manyen,
          )}万円`}
        </Typography>
      ),
      total: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[2]?.off_campus_expenses_4_manyen +
              item.input_plan_tuitions[2]?.tuition_4_manyen,
          )}万円`}
        </Typography>
      ),
      comperison: (item: any) => SCHOOL_AGE.primarySchool4,
    },
    {
      school: (item: any) => (
        <Typography>
          {convertSchoolType(item.input_plan_tuitions[2]?.school_type)} 小学校
        </Typography>
      ),
      school_grade: (item: any) => <Typography>5年生</Typography>,
      on_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(item.input_plan_tuitions[2]?.tuition_5_manyen)}万円`}
        </Typography>
      ),
      off_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[2]?.off_campus_expenses_5_manyen,
          )}万円`}
        </Typography>
      ),
      total: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[2]?.off_campus_expenses_5_manyen +
              item.input_plan_tuitions[2]?.tuition_5_manyen,
          )}万円`}
        </Typography>
      ),
      comperison: (item: any) => SCHOOL_AGE.primarySchool5,
    },
    {
      school: (item: any) => (
        <Typography>
          {convertSchoolType(item.input_plan_tuitions[2]?.school_type)} 小学校
        </Typography>
      ),
      school_grade: (item: any) => <Typography>6年生</Typography>,
      on_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(item.input_plan_tuitions[2]?.tuition_6_manyen)}万円`}
        </Typography>
      ),
      off_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[2]?.off_campus_expenses_6_manyen,
          )}万円`}
        </Typography>
      ),
      total: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[2]?.off_campus_expenses_6_manyen +
              item.input_plan_tuitions[2]?.tuition_6_manyen,
          )}万円`}
        </Typography>
      ),
      comperison: (item: any) => SCHOOL_AGE.primarySchool6,
    },

    {
      school: (item: any) => (
        <Typography>
          {convertSchoolType(item.input_plan_tuitions[2]?.school_type)} 中学校
        </Typography>
      ),
      school_grade: (item: any) => <Typography>1年生</Typography>,
      on_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(item.input_plan_tuitions[3]?.tuition_1_manyen)}万円`}
        </Typography>
      ),
      off_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[3]?.off_campus_expenses_1_manyen,
          )}万円`}
        </Typography>
      ),
      total: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[3]?.off_campus_expenses_1_manyen +
              item.input_plan_tuitions[3]?.tuition_1_manyen,
          )}万円`}
        </Typography>
      ),
      comperison: (item: any) => SCHOOL_AGE.juniorHighSchool1,
    },
    {
      school: (item: any) => (
        <Typography>
          {convertSchoolType(item.input_plan_tuitions[2]?.school_type)} 中学校
        </Typography>
      ),
      school_grade: (item: any) => <Typography>2年生</Typography>,
      on_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(item.input_plan_tuitions[3]?.tuition_2_manyen)}万円`}
        </Typography>
      ),
      off_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[3]?.off_campus_expenses_2_manyen,
          )}万円`}
        </Typography>
      ),
      total: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[3]?.off_campus_expenses_2_manyen +
              item.input_plan_tuitions[3]?.tuition_2_manyen,
          )}万円`}
        </Typography>
      ),
      comperison: (item: any) => SCHOOL_AGE.juniorHighSchool2,
    },
    {
      school: (item: any) => (
        <Typography>
          {convertSchoolType(item.input_plan_tuitions[2]?.school_type)} 中学校
        </Typography>
      ),
      school_grade: (item: any) => <Typography>3年生</Typography>,
      on_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(item.input_plan_tuitions[3]?.tuition_3_manyen)}万円`}
        </Typography>
      ),
      off_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[3]?.off_campus_expenses_3_manyen,
          )}万円`}
        </Typography>
      ),
      total: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[3]?.off_campus_expenses_3_manyen +
              item.input_plan_tuitions[3]?.tuition_3_manyen,
          )}万円`}
        </Typography>
      ),
      comperison: (item: any) => SCHOOL_AGE.juniorHighSchool3,
    },

    {
      school: (item: any) => (
        <Typography>
          {convertSchoolType(item.input_plan_tuitions[2]?.school_type)} 高校
        </Typography>
      ),
      school_grade: (item: any) => <Typography>1年生</Typography>,
      on_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(item.input_plan_tuitions[4]?.tuition_1_manyen)}万円`}
        </Typography>
      ),
      off_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[4]?.off_campus_expenses_1_manyen,
          )}万円`}
        </Typography>
      ),
      total: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[4]?.off_campus_expenses_1_manyen +
              item.input_plan_tuitions[4]?.tuition_1_manyen,
          )}万円`}
        </Typography>
      ),
      comperison: (item: any) => SCHOOL_AGE.highSchool1,
    },
    {
      school: (item: any) => (
        <Typography>
          {convertSchoolType(item.input_plan_tuitions[2]?.school_type)} 高校
        </Typography>
      ),
      school_grade: (item: any) => <Typography>2年生</Typography>,
      on_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(item.input_plan_tuitions[4]?.tuition_2_manyen)}万円`}
        </Typography>
      ),
      off_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[4]?.off_campus_expenses_2_manyen,
          )}万円`}
        </Typography>
      ),
      total: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[4]?.off_campus_expenses_2_manyen +
              item.input_plan_tuitions[4]?.tuition_2_manyen,
          )}万円`}
        </Typography>
      ),
      comperison: (item: any) => SCHOOL_AGE.highSchool2,
    },
    {
      school: (item: any) => (
        <Typography>
          {convertSchoolType(item.input_plan_tuitions[2]?.school_type)} 高校
        </Typography>
      ),
      school_grade: (item: any) => <Typography>3年生</Typography>,
      on_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(item.input_plan_tuitions[4]?.tuition_3_manyen)}万円`}
        </Typography>
      ),
      off_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[4]?.off_campus_expenses_3_manyen,
          )}万円`}
        </Typography>
      ),
      total: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[4]?.off_campus_expenses_3_manyen +
              item.input_plan_tuitions[4]?.tuition_3_manyen,
          )}万円`}
        </Typography>
      ),
      comperison: (item: any) => SCHOOL_AGE.highSchool3,
    },

    {
      school: (item: any) => (
        <Typography>
          {convertUniversitySchoolType(item.input_plan_tuitions[2]?.school_type)} 大学
        </Typography>
      ),
      school_grade: (item: any) => <Typography>1年生</Typography>,
      on_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(item.input_plan_tuitions[5]?.tuition_1_manyen)}万円`}
        </Typography>
      ),
      off_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[5]?.off_campus_expenses_1_manyen,
          )}万円`}
        </Typography>
      ),
      total: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[5]?.off_campus_expenses_1_manyen +
              item.input_plan_tuitions[5]?.tuition_1_manyen,
          )}万円`}
        </Typography>
      ),
      comperison: (item: any) =>
        !!item.input_plan_tuitions[5]?.tuition_1_manyen ? SCHOOL_AGE.university1 : 0,
    },
    {
      school: (item: any) => (
        <Typography>
          {convertUniversitySchoolType(item.input_plan_tuitions[2]?.school_type)} 大学
        </Typography>
      ),
      school_grade: (item: any) => <Typography>2年生</Typography>,
      on_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(item.input_plan_tuitions[5]?.tuition_2_manyen)}万円`}
        </Typography>
      ),
      off_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[5]?.off_campus_expenses_2_manyen,
          )}万円`}
        </Typography>
      ),
      total: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[5]?.off_campus_expenses_2_manyen +
              item.input_plan_tuitions[5]?.tuition_2_manyen,
          )}万円`}
        </Typography>
      ),
      comperison: (item: any) =>
        !!item.input_plan_tuitions[5]?.tuition_1_manyen ? SCHOOL_AGE.university2 : 0,
    },
    {
      school: (item: any) => (
        <Typography>
          {convertUniversitySchoolType(item.input_plan_tuitions[2]?.school_type)} 大学
        </Typography>
      ),
      school_grade: (item: any) => <Typography>3年生</Typography>,
      on_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(item.input_plan_tuitions[5]?.tuition_3_manyen)}万円`}
        </Typography>
      ),
      off_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[5]?.off_campus_expenses_3_manyen,
          )}万円`}
        </Typography>
      ),
      total: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[5]?.off_campus_expenses_3_manyen +
              item.input_plan_tuitions[5]?.tuition_3_manyen,
          )}万円`}
        </Typography>
      ),
      comperison: (item: any) =>
        !!item.input_plan_tuitions[5]?.tuition_1_manyen ? SCHOOL_AGE.university3 : 0,
    },
    {
      school: (item: any) => (
        <Typography>
          {convertUniversitySchoolType(item.input_plan_tuitions[2]?.school_type)} 大学
        </Typography>
      ),
      school_grade: (item: any) => <Typography>4年生</Typography>,
      on_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(item.input_plan_tuitions[5]?.tuition_4_manyen)}万円`}
        </Typography>
      ),
      off_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[5]?.off_campus_expenses_4_manyen,
          )}万円`}
        </Typography>
      ),
      total: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[5]?.off_campus_expenses_4_manyen +
              item.input_plan_tuitions[5]?.tuition_4_manyen,
          )}万円`}
        </Typography>
      ),
      comperison: (item: any) =>
        !!item.input_plan_tuitions[5]?.tuition_1_manyen ? SCHOOL_AGE.university4 : 0,
    },

    {
      school: (item: any) => (
        <Typography>
          {convertUniversitySchoolType(item.input_plan_tuitions[2]?.school_type)} 大学院
        </Typography>
      ),
      school_grade: (item: any) => <Typography>1年生</Typography>,
      on_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(item.input_plan_tuitions[6]?.tuition_1_manyen)}万円`}
        </Typography>
      ),
      off_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[6]?.off_campus_expenses_1_manyen,
          )}万円`}
        </Typography>
      ),
      total: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[6]?.off_campus_expenses_1_manyen +
              item.input_plan_tuitions[6]?.tuition_1_manyen,
          )}万円`}
        </Typography>
      ),
      comperison: (item: any) =>
        !!item.input_plan_tuitions[6]?.tuition_1_manyen ? SCHOOL_AGE.graduateSchool1 : 0,
    },
    {
      school: (item: any) => (
        <Typography>
          {convertUniversitySchoolType(item.input_plan_tuitions[2]?.school_type)} 大学院
        </Typography>
      ),
      school_grade: (item: any) => <Typography>2年生</Typography>,
      on_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(item.input_plan_tuitions[6]?.tuition_2_manyen)}万円`}
        </Typography>
      ),
      off_campus_cost: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[6]?.off_campus_expenses_2_manyen,
          )}万円`}
        </Typography>
      ),
      total: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_plan_tuitions[6]?.off_campus_expenses_2_manyen +
              item.input_plan_tuitions[6]?.tuition_2_manyen,
          )}万円`}
        </Typography>
      ),
      comperison: (item: any) =>
        !!item.input_plan_tuitions[6]?.tuition_1_manyen ? SCHOOL_AGE.graduateSchool2 : 0,
    },
  ]

  return (
    <MyDataListWrapper
      title="教育費"
      description="給与明細が必要です。ご用意ください。"
      onClick={() => handleMovePage('/v2/myData/tuitions/edit')}
    >
      {familyStructureChildren.map((child: FamilyStructureChild, index: number) => (
        <React.Fragment key={`${child.id}_${index}`}>
          <div className="mt-30">
            <div className="sm:flex block justify-between">
              <CircleTitle
                title={`第${index + 1}子`}
                classNameTitle="text-[20px] md:text-[24px]"
              />
              <Button
                className="w-[84px] md:w-[112px] h-[26px] md:h-[37px] mr-[0] md:mr-[9px] rounded-[4px] bg-white border border-secondary-500 hover:bg-secondary-50"
                isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
                onClick={() => handleMovePage(`/v2/myData/tuitions/edit/${child.id}`)}
              >
                <Typography
                  className="relative bottom-[1px] text-[12px] md:text-[14px] text-secondary-500"
                  isBold
                >
                  編集する
                </Typography>
              </Button>
            </div>

            <table className="w-[100%] mb-[60px] last:mb-0 text-[12px] md:text-[14px]">
              <tbody>
                <tr className="h-[45px] odd:bg-black-100">
                  <th className="w-[20%] px-[16px] md:px-[30px] text-left">
                    <Typography isBold>学校</Typography>
                  </th>
                  <th className="w-[20%] px-[16px] md:px-[30px] text-left">
                    <Typography isBold>学年</Typography>
                  </th>
                  <th className="w-[20%] px-[16px] md:px-[30px] text-left">
                    <Typography isBold>学費</Typography>
                  </th>
                  <th className="w-[20%] px-[16px] md:px-[30px] text-left">
                    <Typography isBold>学外費</Typography>
                  </th>
                  <th className="w-[20%] px-[16px] md:px-[30px] text-left">
                    <Typography isBold>合計額</Typography>
                  </th>
                </tr>

                {contentArr.map(
                  (item: any) =>
                    isChildbirthWithinRange(
                      familyStructureChildren[index]?.birth_day,
                      item.comperison(child),
                    ) && (
                      <tr className="h-[45px] odd:bg-black-100">
                        <td className="w-[20%] px-[16px] md:px-[30px]">
                          {item.school(child)}
                        </td>
                        <td className="w-[20%] px-[16px] md:px-[30px]">
                          {item.school_grade(child)}
                        </td>
                        <td className="w-[20%] px-[16px] md:px-[30px]">
                          {item.on_campus_cost(child)}
                        </td>
                        <td className="w-[20%] px-[16px] md:px-[30px]">
                          {item.off_campus_cost(child)}
                        </td>
                        <td className="w-[20%] px-[16px] md:px-[30px]">
                          {item.total(child)}
                        </td>
                      </tr>
                    ),
                )}
              </tbody>
            </table>
          </div>
        </React.Fragment>
      ))}
    </MyDataListWrapper>
  )
}

export default MyDataTuition
