import React, { FC, ReactNode } from 'react'
import { includes as _includes, replace as _replace } from 'lodash'
import ChevronRightIcon from '@/assets/images/v2/chevron-right.svg'
import DataAnalyzingIcon from '@/assets/images/v2/data-analyzing.svg'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import Paper from '@/components/v2/atoms/Paper'
import Typography from '@/components/v2/atoms/Typography'
import CircleTitle from '@/components/v2/molecules/CircleTitle'

interface AifpPaperProps {
  /** タイトル */
  title?: string
  /** 表示テキスト */
  text: string
  /** ボタンラベル */
  buttonLabel?: string
  /** 表示アイコン */
  icon?: ReactNode
  /** cssクラス名 */
  className?: string
  /** クリックメソッド */
  onClick?: () => void
  /** true: ボタン非活性, false: ボタン活性 */
  isDisabled?: boolean
}

const AifpPaper: FC<AifpPaperProps> = (props) => {
  const { title, text, buttonLabel, icon, className, onClick, isDisabled } = props

  return (
    <Paper className={`bg-cream-500 flex flex-1 p-24 ${className}`}>
      <div className="flex-1">
        <CircleTitle
          className="pb-16"
          classNameTitle="text-20 md:text-24"
          title={title || 'AIFPからのコメント'}
        />
        <div className="flex">
          <div className="mr-0 md:mr-40 items-center">
            {icon ? icon : <DataAnalyzingIcon />}
          </div>
          <div>
            <Typography className="text-12 md:text-14 text-black-900 mb-20">
              {text.split(/\\n/).map((oneline, index) => (
                <React.Fragment key={index}>
                  {index != 0 && <br />}
                  {_includes(oneline, '[space]') && <br />}
                  {_replace(oneline, '[space]', '')}
                </React.Fragment>
              ))}
            </Typography>
            {buttonLabel && (
              <ButtonPrimary
                className="w-fit h-[45px] mt-auto px-[17px] md:px-[5px] lg:px-[17px]"
                onClick={() => onClick?.()}
                isDisabled={isDisabled}
              >
                <Typography
                  className="relative bottom-[2px] flex-1 text-[12px] md:text-[13px] text-white min-w-[max-content]"
                  isBold
                >
                  {buttonLabel}
                </Typography>
                <ChevronRightIcon />
              </ButtonPrimary>
            )}
          </div>
        </div>
      </div>
    </Paper>
  )
}

export default AifpPaper
