import React, { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { RootState } from '@/store'
import ChannelService from '@/utils/ChannelService'
import LogRocket from 'logrocket'

const LocationEffect: FC = () => {
  const location = useLocation()
  const { account } = useSelector((state: RootState) => state.accounts)

  useEffect(() => {
    if (account.id === 0) return

    const values = {
      pluginKey: process.env.CHANNEL_PLUGIN_KEY,
      memberId: account.id,
      profile: {
        name: `${account.name_sei} ${account.name_mei}`,
        email: account.mail_address,
        person_age: account.channel_talk_data?.person_age,
        family_structure_string: account.channel_talk_data?.family_structure_string,
        income_yearly: account.channel_talk_data?.income_yearly,
        financial_asset_total: account.channel_talk_data?.financial_asset_total,
        has_password: account.channel_talk_data?.has_password,
        has_family_structure: account.channel_talk_data?.has_family_structure,
        has_input_salaries: account.channel_talk_data?.has_input_salaries,
        has_input_housing: account.channel_talk_data?.has_input_housing,
        has_input_monthly_cost: account.channel_talk_data?.has_input_monthly_cost,
        has_input_education_cost: account.channel_talk_data?.has_input_education_cost,
        has_input_insurances: account.channel_talk_data?.has_input_insurances,
        has_public_pensions: account.channel_talk_data?.has_public_pensions,
        has_input_assets: account.channel_talk_data?.has_input_assets,
        has_connected_with_moneytree:
          account.channel_talk_data?.has_connected_with_moneytree,
        last_login_at: account.channel_talk_data?.last_login_at
          ? Date.parse(account.channel_talk_data?.last_login_at)
          : null,
        registration_text: account.channel_talk_data?.registration_text,
        trial_end_at: account.trial_end_at ? Date.parse(account.trial_end_at) : null,
        is_paid_account: account['is_paid_account?'],
      },
    }

    LogRocket.identify(`account_${account.id}`, {
      name: `${account.name_sei} ${account.name_mei}`,
      email: account.mail_address,
      subscriptionType: account?.['is_paid_account?'] ? 'pro' : 'free',
    })

    // MEMO: 事業者側のテストアカウントはチャンネルトークを起動しない
    if (account.channel_talk_data.is_test_account) return
    ChannelService.boot(values)
  }, [location.pathname, account])

  return null
}

export default LocationEffect
