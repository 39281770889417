import ApiClient from './apiClient'
import {
  GeneralStatisticResponse,
  GeneralStatisticValues,
} from '../models/generalStatisticsModel'

export const postGeneralStatistics = async (values: GeneralStatisticValues) => {
  return await ApiClient.post(
    '/general_statistics/search',
    (response: GeneralStatisticResponse[]) => response,
    values,
  )
}
