import { isUndefined as _isUndefined } from 'lodash'
import {
  THIS_YEAR,
  extractYearOrMonthFromDate,
  compareDiffMomentDate,
  convertMomentDate,
  convertLocaleString,
} from '@/models/commonsModelFunc'
import {
  FutureLendHousing,
  LoanRenewalType,
  PropertyType,
} from '@/models/futures/futureLendHousingsModel'

// 物件タイプ
export const PROPERTY_TYPE = {
  whole: 'whole',
  division: 'division',
  house: 'house',
}

// 建物管理手数料タイプ
export const MANAGEMENT_FEE_TYPE = {
  percent: 'percent',
  yen: 'yen',
}

// 空室率
export const VACANCY_SETTING_TYPE = {
  own: 0,
  auto: 1,
}

// 投資用ローンへの借り換え
export const LOAN_RENEWAL_TYPE = {
  renew: 'renew',
  continue: 'continue',
}

export const buildFutureLendHousingUpdateInitialValues = (futureLendHousing: any) => {
  return !!futureLendHousing
    ? {
        lend_start_year: extractYearOrMonthFromDate(
          futureLendHousing.lend_start_at,
          'year',
        ),
        future_lend_housing: {
          ...futureLendHousing,
          future_lend_housing_declines_attributes:
            futureLendHousing.future_lend_housing_declines.length > 0
              ? futureLendHousing.future_lend_housing_declines?.map((item: any) => ({
                  ...item,
                  affected_number: compareDiffMomentDate(
                    item.affected_at,
                    THIS_YEAR,
                    'years',
                  ),
                }))
              : [{ affected_number: null }],
          housing_loan_attributes: futureLendHousing.housing_loan,
          vacancy_rate_type: VACANCY_SETTING_TYPE.own,
          management_fee_money: !!futureLendHousing.management_fee_money
            ? Math.round(Number(futureLendHousing.management_fee_money) / 12)
            : futureLendHousing.management_fee_money,
        },
      }
    : {}
}

export const convertLoanRenewalType = (loanRenewalType: LoanRenewalType | null) => {
  switch (loanRenewalType) {
    case LOAN_RENEWAL_TYPE.renew:
      return '借り換える'
    case LOAN_RENEWAL_TYPE.continue:
      return '借り換えない'
    default:
      return ''
  }
}

export const convertLendHousingDeclineRate = (declineRates: any, index: number) => {
  if (_isUndefined(declineRates)) return

  const fromLabel =
    index === 0 ? '現在' : convertMomentDate(declineRates[index]?.affected_at, 'YYYY年')
  const toLabel =
    index === declineRates.length - 1
      ? '終身'
      : convertMomentDate(declineRates[index + 1]?.affected_at, 'YYYY年')

  return `${fromLabel}〜${toLabel} 年間${convertLocaleString(
    declineRates[index]?.rate_percent,
  )}％`
}

export const convertNonRequiredValuesToNull = (
  futureLendHousing: FutureLendHousing,
  isRenewalFeeTypeOn: boolean,
  isRenewalFeeInputYenType: boolean,
) => {
  if (!isRenewalFeeTypeOn) {
    futureLendHousing.renewal_fee = null
    futureLendHousing.renewal_fee_to_rent_income_rate = null
  }
  if (isRenewalFeeTypeOn && isRenewalFeeInputYenType) {
    futureLendHousing.renewal_fee_to_rent_income_rate = null
  }
  if (isRenewalFeeTypeOn && !isRenewalFeeInputYenType) {
    futureLendHousing.renewal_fee = null
  }

  return futureLendHousing
}

export const convertPropertyType = (propertyType: PropertyType | null) => {
  switch (propertyType) {
    case PROPERTY_TYPE.whole:
      return '１棟'
    case PROPERTY_TYPE.division:
      return '区分'
    case PROPERTY_TYPE.house:
      return '戸建て'
    default:
      return ''
  }
}
