import { isNull as _isNull } from 'lodash'
import ApiClient from '@/api/apiClient'
import { getStorageVideoPlatformsAuthHeaders } from '@/utils/localStorage/videoPlatformsAuthHeadersStorage'

export const isMovieLogind = () => {
  const authHeaders = getStorageVideoPlatformsAuthHeaders()
  if (_isNull(authHeaders)) return false

  ApiClient.setVideoPlatformsAuthHeaders(authHeaders)
  return true
}
