import React, { createRef, FC, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createPopper } from '@popperjs/core'
import HorizontalLogo from '@/assets/images/horizontal-logo.png'
import ContactIcon from '@/assets/images/v2/contact.svg'
import CrossMenuIcon from '@/assets/images/v2/cross-menu.svg'
import DashboardIcon from '@/assets/images/v2/dashboard.svg'
import DatabaseIcon from '@/assets/images/v2/database.svg'
import HamburgerMenuIcon from '@/assets/images/v2/hamburger-menu.svg'
import SettingIcon from '@/assets/images/v2/setting.svg'
import Badge from '@/components/v1/atoms/Badge'
import Button from '@/components/v2/atoms/Button'
import Typography from '@/components/v2/atoms/Typography'
import HamburgerMenu from '@/components/v2/organisms/HamburgerMenu'
import { fetchAccountShowStepStatusV2, accountLogoutV2 } from '@/containers/accountsSlice'
import { updateTemporaryAccountDemoResetV2 } from '@/containers/admins/temporaryAccountsSlice'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import { AccountStepStatus } from '@/models/accountsModel'
import { RootState } from '@/store'
import { removeBaseItemsInLocalStorage } from '@/utils/localStorage/commonLocalStorage'
import { getMoneytreeVaultUrl } from '@/utils/moneytree'

export type pathCategory = 'dashboard' | 'simulation' | 'myData' | ''
const Header: FC = () => {
  const dispatch = useDispatch()
  const { handleMovePage } = useCustomHistory()
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState<boolean>(false)

  const [popoverShow, setPopoverShow] = useState(false)
  const btnRef = createRef<any>()
  const popoverRef = createRef<any>()
  const openPopover = () => {
    createPopper(btnRef.current, popoverRef.current, {
      placement: 'bottom',
    })
    setPopoverShow(true)
  }
  const closePopover = () => {
    setPopoverShow(false)
  }

  const { isConfirmingMydata } = useSelector((state: RootState) => state.futureBases)
  const { account, isLogin } = useSelector((state: RootState) => state.accounts)

  const activeCategory: pathCategory = useMemo(() => {
    if (location.pathname.includes('/v2/dashboard')) return 'dashboard'
    if (location.pathname.includes('/v2/myData')) return 'myData'
    if (location.pathname.includes('/v2/simulations')) return 'simulation'
    return ''
  }, [location.pathname])

  const isNotFixedHeader: boolean = useMemo(() => {
    return location.pathname.includes('/v2/simulations/')
  }, [location.pathname])

  const getActiveClassNameButton = (category: pathCategory) => {
    return category == activeCategory ? 'border-primary-500' : 'border-white'
  }

  const getActiveClassNameTextIcon = (category: pathCategory) => {
    return category == activeCategory
      ? 'stroke-current text-primary-500'
      : 'stroke-[#000]'
  }

  const handleLogout = () => {
    closePopover()
    dispatch(accountLogoutV2())
  }

  const handleClickAway = (e: any) => {
    if (popoverRef.current?.contains(e.target)) return
    closePopover()
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickAway)

    return () => {
      document.removeEventListener('mousedown', handleClickAway)
    }
  }, [popoverRef])

  const handleMoveMyData = () => {
    dispatch(
      fetchAccountShowStepStatusV2((response: AccountStepStatus) => {
        const url = response.has_input_simple_data ? '/v2/myData' : '/v2/myData/simple'
        handleMovePage(url)
      }),
    )
  }

  const handleClickMtConnectionButton = () => {
    if (account.mt_connection_status == 'connected') {
      const url = getMoneytreeVaultUrl('/v2/myData/assets')
      setTimeout(() => window.open(url))
    } else {
      handleMovePage('/v2/plans/household')
    }
  }

  const handleDataReset = () => {
    const result = confirm('本当にリセットしてよろしいですか？')
    if (!result) return

    closePopover()
    dispatch(
      updateTemporaryAccountDemoResetV2(account?.id, () => {
        removeBaseItemsInLocalStorage()
        window.alert('データをリセットを完了しました。画面を再読み込みします。')
        window.location.href = '/v2/dashboards'
      }),
    )
  }

  const isHiddenHeader: boolean = useMemo(() => {
    if (
      location.pathname.includes('v2/myData/') &&
      !location.pathname.includes('v2/myData/journals')
    ) {
      return isConfirmingMydata
    }
    return false
  }, [location.pathname, isConfirmingMydata])

  return (
    <div
      className={
        'bg-white flex h-55 md:h-88 items-center justify-between px-12 md:px-24' +
        (isNotFixedHeader ? '' : ' sticky top-0 z-50') +
        (isHiddenHeader ? ' hidden' : '')
      }
    >
      <img className="h-31 md:h-42" src={HorizontalLogo} alt="マネソル" />
      {isLogin && (
        <>
          {!account['is_disabled_trial_account?'] && (
            <>
              <div className="hidden md:flex">
                <Button
                  className={`border-b-3 border-solid h-88 w-172 ${getActiveClassNameButton(
                    'dashboard',
                  )}`}
                  onClick={() => handleMovePage('/v2/dashboards')}
                >
                  <DashboardIcon className={getActiveClassNameTextIcon('dashboard')} />
                  <Badge
                    className="w-[117px]"
                    classes={{ badge: 'text-white bg-red-500' }}
                    invisible={account['is_done_with_steps?']}
                    badgeContent={'!'}
                  >
                    <Typography
                      className={`pl-10 text-14 ${getActiveClassNameTextIcon(
                        'dashboard',
                      )}`}
                      isBold
                    >
                      ダッシュボード
                    </Typography>
                  </Badge>
                </Button>

                <Button
                  className={`border-b-3 border-solid h-88 w-172 ${getActiveClassNameButton(
                    'simulation',
                  )}`}
                  onClick={() => handleMovePage('/v2/simulations')}
                >
                  <DatabaseIcon className={getActiveClassNameTextIcon('simulation')} />
                  <Typography
                    className={`pl-10 text-14 ${getActiveClassNameTextIcon(
                      'simulation',
                    )}`}
                    isBold
                  >
                    シミュレーション
                  </Typography>
                </Button>

                <Button
                  className={`border-b-3 border-solid h-88 w-172 ${getActiveClassNameButton(
                    'myData',
                  )}`}
                  onClick={() => handleMoveMyData()}
                >
                  <ContactIcon className={getActiveClassNameTextIcon('myData')} />
                  <Typography
                    className={`pl-10 text-14 ${getActiveClassNameTextIcon('myData')}`}
                    isBold
                  >
                    マイデータ
                  </Typography>
                </Button>
              </div>
            </>
          )}

          {/* <Button
            className="hidden md:flex border-b-3 border-white border-solid h-88 w-114"
            // onClick={() => {
            //   popoverShow ? closePopover() : openPopover()
            // }}
            // ref={btnRef}
          > */}
          <div className="hidden md:flex">
            <div className="hidden md:flex border-b-3 border-white border-solid h-88 w-114 items-center justify-center disabled:cursor-default disabled:opacity-50 cursor-pointer">
              <Button
                className="w-[150px] h-[32px] px-[17px] md:px-[5px] lg:px-[17px] bg-primary-500 rounded-8 hover:bg-primary-600 flex items-center justify-center disabled:cursor-default disabled:opacity-50 text-12 text-white"
                onClick={() => handleClickMtConnectionButton()}
              >
                口座連携する
              </Button>
            </div>

            <div
              className="hidden md:flex border-b-3 border-white border-solid h-88 w-114 items-center justify-center disabled:cursor-default disabled:opacity-50 cursor-pointer"
              onClick={() => {
                popoverShow ? closePopover() : openPopover()
              }}
              ref={btnRef}
            >
              <SettingIcon className="min-w-[22px]" />
              <Typography
                className="pl-[2px] text-12 text-black-700 w-[max-content]"
                isBold
              >
                アカウント
              </Typography>
            </div>
          </div>

          <div className="md:hidden flex">
            <Button
              className="md:hidden w-[110px] h-[32px] px-[17px] md:px-[5px] lg:px-[17px] bg-primary-500 rounded-8 hover:bg-primary-600 flex items-center justify-center disabled:cursor-default disabled:opacity-50 text-12 text-white"
              onClick={() => handleClickMtConnectionButton()}
            >
              口座連携する
            </Button>

            <Button
              className="md:hidden w-[30px] ml-[15px]"
              onClick={() => setIsHamburgerMenuOpen(!isHamburgerMenuOpen)}
            >
              {isHamburgerMenuOpen ? <CrossMenuIcon /> : <HamburgerMenuIcon />}
            </Button>
          </div>

          <HamburgerMenu
            isOpen={isHamburgerMenuOpen}
            onClickAway={() => setIsHamburgerMenuOpen(false)}
          />
        </>
      )}

      <div
        className={'z-[100] ' + (popoverShow ? 'bg-white border' : 'hidden')}
        ref={popoverRef}
      >
        <div
          className="cursor-pointer h-45 leading-45 px-20 border-b"
          onClick={() => {
            closePopover()
            handleMovePage('/v2/settings/account')
          }}
        >
          個人設定
        </div>
        {account?.['is_demo_account?'] && (
          <div
            className="cursor-pointer h-45 leading-45 px-20 border-b"
            onClick={handleDataReset}
          >
            データリセット
          </div>
        )}
        <div
          className="cursor-pointer h-45 leading-45 px-20"
          onClick={() => {
            handleLogout()
          }}
        >
          ログアウト
        </div>
        <div
          className="cursor-pointer h-45 leading-45 px-20"
        >
          <a href={process.env.BAG_REPORT_URL} target="_blank">不具合報告</a>
        </div>
      </div>
    </div>
  )
}

export default Header
