import React, { FC, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useField, useForm } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import {
  sortBy as _sortBy,
  includes as _includes,
  last as _last,
  slice as _slice,
  some as _some,
  find as _find,
} from 'lodash'
import CrossIcon from '@/assets/images/cross.svg'
import { CHANGE_TYPE_SELECT_ITEMS, INSURANCE_PENSION_TYPE_SELECT_ITEMS } from '@/constants/formItem/commonIncomeChanges'
import { createFutureIncomeCalc } from '@/containers/futures/futureIncomesSlice'
import { ACTIVE_FLG, CURRENT_SALARY_INCREASE_RATE_SETTING_TYPE, convertRateSettingType } from '@/models/commonIncomeBasesModelFunc'
import {
  CHANGE_TYPE,
  RETIREMENT_AGE,
  convertChangeType,
  formatIncomeChanges,
} from '@/models/commonIncomeChangesModelFunc'
import {
  THIS_YEAR_NUMBER,
  isNotEmptyValue,
  convertLocaleString,
  convertYenToManyen,
  castNumberWithoutOperator,
  defaultToEmptyString,
  castNumberWithoutComma,
} from '@/models/commonsModelFunc'
import { RootState } from '@/store'
import { buildRangeAgeSelectItems } from '@/utils/formItem'
import {
  composeValidators,
  required,
  zeroOrMoreNumber,
  commaNumberMaxLength,
  oneHundredLessThanNumber,
} from '@/utils/validate'

import Button from '@/components/v2/atoms/Button'
import Typography from '@/components/v2/atoms/Typography'
import SelectBox from '@/components/v2/molecules/SelectBox'
import NumberField from '@/components/v2/molecules/NumberField'
import HelpCircleIcon from '@/assets/images/v2/help-circle.svg'
import Tooltip from '@/components/v2/atoms/Tooltip'
import LoadingOverlay from '@/components/v1/atoms/LoadingOverlay'
import { CURRENT_SALARY_INCREASE_RATE_SETTING_TYPE_SELECT_ITEMS } from '@/constants/formItem/commonIncomeBases'
import NumberFieldDecimal from '@/components/v2/molecules/NumberFieldDecimal'
import Grid from '@/components/v2/atoms/Grid'
import { convertInsurancePensionType, convertIndustry } from '@/models/inputs/inputSalariesModelFunc'
import { INPUT_INDUSTRY_BUTTON_ITEMS } from '@/constants/formItem/inputs/inputSalaries'

interface IncomeChangeProps {
  /** true: 更新, false: 登録 */
  isEdit?: boolean
  /** 選択中の将来の結婚ID */
  selectedMarriageId?: number | undefined
}

const IncomeChange: FC<IncomeChangeProps> = (props) => {
  const dispatch = useDispatch()
  const { selectedMarriageId } = props

  const { futureBaseId } = useSelector((state: RootState) => state.futureBases)
  const { futureIncomeCalc, isLoading } = useSelector((state: RootState) => state.futureIncomes)
  const { inputSalaries } = useSelector((state: RootState) => state.inputSalaries)

  const { change, getState } = useForm()
  const { values } = getState()

  const { personFlg, age, future_income } = values
  const quitCurrentJobAtAge = future_income.quit_current_job_at_age

  const {
    input: { value: futureIncomeChangesAttributes },
  } = useField('future_income.future_income_changes_attributes')
  const lastIncomeChange: any = _last(futureIncomeChangesAttributes)

  useField('estimated_annual_income_manyen')
  useField('severance_pay_manyen')
  useField('retirement_officers_amount_manyen')

  const ageSelectItems = useMemo(
    () => buildRangeAgeSelectItems(quitCurrentJobAtAge, 70),
    [quitCurrentJobAtAge],
  )
  const targetFutureIncomeCalc = futureIncomeCalc[personFlg]
  const targetInputIncomeCalc  = futureIncomeCalc[personFlg+2]

  const targetInputSalary = useMemo(
    () => _find(inputSalaries, (item) => item.person_flg === personFlg),
    [inputSalaries],
  )

  useEffect(() => {
    const newValues = {
      future_base_id: futureBaseId,
      future_marriage_id: selectedMarriageId,
      future_income: {
        ...values?.future_income,
        future_base_id: futureBaseId,
        person_flg: personFlg,
        active_flg: ACTIVE_FLG.active,
      },
    }
    const formatedIncomeChanges = formatIncomeChanges(
      futureIncomeChangesAttributes,
      quitCurrentJobAtAge,
    )

    newValues.future_income.future_income_changes_attributes = formatedIncomeChanges
    dispatch(createFutureIncomeCalc(newValues))
  }, [futureIncomeChangesAttributes, future_income.quit_current_job_at_age])

  const handleAddIncomeChange = () => {
    const { change_type } = values
    const newValues: any = {
      future_base_id: futureBaseId,
      person_flg: personFlg,
      change_type,
      scheduled_quit_at_age: values.scheduled_quit_at_age,
    }

    // 転職・独立を選択
    if (Number(values?.change_type) === CHANGE_TYPE.employment) {
      // 変更後の年収
      newValues.estimated_annual_income_manyen = castNumberWithoutComma( values?.estimated_annual_income_manyen,)
      // 変更後の社会保険
      newValues.insurance_pension_type = values?.insurance_pension_type
      // 収入上昇率
      newValues.current_salary_increase_rate_setting_type = values?.current_salary_increase_rate_setting_type
      // 収入上昇率（手入力）
      newValues.current_salary_increase_rate_percent = values?.current_salary_increase_rate_percent
      // 業種
      newValues.industry = values?.industry
      // 退職金
      newValues.severance_pay_manyen = castNumberWithoutComma( values?.severance_pay_manyen,)
    }

    // 退職・定年退職を選択
    if (Number(values?.change_type) === CHANGE_TYPE.retirement) {
      // 変更後の社会保険
      newValues.insurance_pension_type = values?.insurance_pension_type
    }

    // 転職を伴わない収入の変化を選択
    if (Number(values?.change_type) === CHANGE_TYPE.retirementOfficer) {
      // 変更後の年収
      newValues.retirement_officers_amount_manyen = castNumberWithoutComma( values.retirement_officers_amount_manyen,)
      // 収入上昇率
      newValues.current_salary_increase_rate_setting_type = values?.current_salary_increase_rate_setting_type
      // 収入上昇率（手入力）
      newValues.current_salary_increase_rate_percent = values?.current_salary_increase_rate_percent
      console.log(targetInputSalary?.industry)
      // 業種
      newValues.industry = targetInputSalary?.industry
      // 退職金
      newValues.severance_pay_manyen = castNumberWithoutComma( values?.severance_pay_manyen,)
    }

    const sortedIncomeChanges = _sortBy(
      [...futureIncomeChangesAttributes, newValues],
      'scheduled_quit_at_age',
    )

    change('future_income.future_income_changes_attributes', sortedIncomeChanges)
    change('change_type', null)
    change('scheduled_quit_at_age', null)
    change('estimated_annual_income_manyen', 0)
    change('retirement_officers_amount_manyen', 0)
    change('severance_pay_manyen', 0)
    change('insurance_pension_type', null)
    change('current_salary_increase_rate_setting_type', null)
    change('current_salary_increase_rate_percent', null)
    change('industry', null)
  }

  const handleResetIncomeChange = () => {
    change('change_type', null)
    change('scheduled_quit_at_age', null)
    change('estimated_annual_income_manyen', 0)
    change('retirement_officers_amount_manyen', 0)
    change('severance_pay_manyen', 0)
    change('insurance_pension_type', null)
    change('current_salary_increase_rate_setting_type', null)
    change('current_salary_increase_rate_percent', null)
    change('industry', null)
  }

  const renderFromAgeLabel = (index: number) => {
    if (index === 0) return quitCurrentJobAtAge

    const beforeIncomeChanges: any = _slice(futureIncomeChangesAttributes, 0, index)
    const isBeforeVisible = _some(
      beforeIncomeChanges,
      (item) => item.scheduled_quit_at_age > quitCurrentJobAtAge,
    )

    return isBeforeVisible
      ? futureIncomeChangesAttributes[index - 1].scheduled_quit_at_age
      : quitCurrentJobAtAge
  }

  const renderFutureIncome = (targetAge: number) => {
    const year = THIS_YEAR_NUMBER + (targetAge - age)
    return `${convertYenToManyen(targetFutureIncomeCalc[year])}万円`
  }
  const renderInputIncome = (targetAge: number) => {
    const year = THIS_YEAR_NUMBER + (targetAge - age)
    return `${convertYenToManyen(targetInputIncomeCalc[year])}万円`
  }

  const isShowIncomeCompany = false
  const isShowIncome = true

  const isIncomeInputCompleted = Number(lastIncomeChange?.scheduled_quit_at_age) === RETIREMENT_AGE

  const validateAddIncome = () => {
    if (
      !isNotEmptyValue(values?.change_type) ||
      !isNotEmptyValue(values?.scheduled_quit_at_age) ||
      (Number(values?.change_type) === CHANGE_TYPE.employment || Number(values?.change_type) === CHANGE_TYPE.retirement) && !isNotEmptyValue(values?.insurance_pension_type) ||
      (Number(values?.change_type) === CHANGE_TYPE.employment || Number(values?.change_type) === CHANGE_TYPE.retirementOfficer) && !isNotEmptyValue(values?.current_salary_increase_rate_setting_type) ||
      Number(values?.change_type) === CHANGE_TYPE.employment && values?.current_salary_increase_rate_setting_type === CURRENT_SALARY_INCREASE_RATE_SETTING_TYPE.average && !isNotEmptyValue(values?.industry) ||
      (Number(values?.change_type) === CHANGE_TYPE.employment || Number(values?.change_type) === CHANGE_TYPE.retirementOfficer) && values?.current_salary_increase_rate_setting_type === CURRENT_SALARY_INCREASE_RATE_SETTING_TYPE.input && !isNotEmptyValue(values?.current_salary_increase_rate_percent)
    ) {
      return true
    }

    let targetIncome = null
    switch (Number(values?.change_type)) {
      case CHANGE_TYPE.employment:
        if (!isNotEmptyValue(values?.estimated_annual_income_manyen)) return true
        if (!isNotEmptyValue(values?.insurance_pension_type)) return true
        if (!isNotEmptyValue(values?.current_salary_increase_rate_setting_type)) return true
        break
      case CHANGE_TYPE.retirement:
        if (!isNotEmptyValue(values?.insurance_pension_type)) return true
        break
      case CHANGE_TYPE.retirementOfficer:
        if (!isNotEmptyValue(values?.retirement_officers_amount_manyen)) return true
        if (!isNotEmptyValue(values?.current_salary_increase_rate_setting_type)) return true
        break
      default:
        break
    }

    const castTargetIncome = String(castNumberWithoutOperator(targetIncome))
    if (castTargetIncome.length > 6) return true

    return false
  }

  return (
    <>
      {(isShowIncomeCompany || isShowIncome) && (
        <div className="lg:px-[50px] pt-[20px] pb-[30px] relative">
          <div>
            <div className="pb-[20px]">
              <div className="flex justify-between p-[16px] border border-black-600 rounded-[4px]">
                <Typography className="text-[14px] text-black-600" isBold>
                  現在
                </Typography>
              </div>
              <div className="items-center min-h-[70px] ml-[30px] md:ml-[80px] p-[10px] pl-[20px] md:pl-[50px] border-l border-black-600">
                <Typography className="min-w-[120px] pb-[10px] text-[14px]" isBold >
                  現在のお仕事
                </Typography>
                <div className="bg-[#f5f5f5] p-10" >
                  <Typography className="pb-[5px] text-[11px]">
                    {`現在の年収　${renderFutureIncome(age)}`}
                  </Typography>
                  <Typography className="pb-[5px] text-[11px]">
                    {`${
                      Number(values?.future_income?.quit_current_job_at_age) - 1 || 0
                    }歳11ヶ月時点の年収　${
                      age > (Number(values?.future_income?.quit_current_job_at_age) - 1) ?
                        renderInputIncome(age) :
                        renderFutureIncome( Number(values?.future_income?.quit_current_job_at_age) - 1,)
                    }`}
                  </Typography>
                </div>
              </div>
              <div className="p-[16px] border border-black-600 rounded-[4px]">
                <Typography className="text-[14px] text-black-600" isBold>
                  {`${
                    Number(values?.future_income?.quit_current_job_at_age) - 1 || 0
                  }歳11ヶ月まで`}
                </Typography>
              </div>
            </div>

            <FieldArray name="future_income.future_income_changes_attributes">
              {({ fields }) =>
                fields.map((name, index) => (
                  <React.Fragment key={name}>
                    {fields.value[index]?.scheduled_quit_at_age >
                      values?.future_income?.quit_current_job_at_age && (
                      <div className="pb-[20px]">
                        <div className="flex justify-between p-[16px] border border-black-600 rounded-[4px]">
                          <Typography className="text-[14px] text-black-600" isBold>
                            {`${renderFromAgeLabel(index)}歳0ヶ月から`}
                          </Typography>
                          <Button onClick={() => fields.remove(index)}>
                            <CrossIcon />
                          </Button>
                        </div>

                        <div className="items-center min-h-[70px] ml-[30px] md:ml-[80px] p-[10px] pl-[20px] md:pl-[50px] border-l border-black-600">
                          <Typography className="min-w-[120px] pb-[10px] text-[14px]" isBold >
                            {convertChangeType(Number(fields.value[index]?.change_type))}
                          </Typography>

                          {Number(fields.value[index]?.change_type) === CHANGE_TYPE.employment && (
                            <div className="bg-[#f5f5f5] p-10" >
                              <Typography className="pb-[5px] text-[11px]">
                                {`変更後の年収 ${convertLocaleString(
                                  Number(fields.value[index]?.change_type) === CHANGE_TYPE.employment
                                    ? fields.value[index]?.estimated_annual_income_manyen
                                    : fields.value[index]?.retirement_officers_amount_manyen,
                                )}万円`}
                              </Typography>
                              <Typography className="pb-[5px] text-[11px]">
                                {`変更後の社会保険 ${ convertInsurancePensionType( fields.value[index]?.insurance_pension_type || null )}`}
                              </Typography>
                              <Typography className="pb-[5px] text-[11px]">
                                {`変更後の収入上昇率 `}
                                {
                                  fields.value[index]?.current_salary_increase_rate_setting_type == CURRENT_SALARY_INCREASE_RATE_SETTING_TYPE.input ?
                                    `${defaultToEmptyString(fields.value[index]?.current_salary_increase_rate_percent )}%` :
                                    `${convertRateSettingType(fields.value[index]?.current_salary_increase_rate_setting_type )}`
                                }
                              </Typography>
                              { fields.value[index]?.current_salary_increase_rate_setting_type === CURRENT_SALARY_INCREASE_RATE_SETTING_TYPE.average && (
                                <Typography className="pb-[5px] text-[11px]">
                                  {`変更後の業種 `}
                                  {convertIndustry(fields.value[index]?.industry || null)}
                                </Typography>
                              )}
                              <Typography className="pb-[5px] text-[11px]">
                                {`${ fields.value[index]?.scheduled_quit_at_age - 1 }歳11ヶ月時点の年収` +
                                  `${renderFutureIncome( Number( fields.value[index]?.scheduled_quit_at_age,) - 1 )}` }
                              </Typography>

                              { Number(fields.value[index]?.severance_pay_manyen) > 0 && (
                                <Typography className="pb-[5px] text-[11px]">
                                  {`退職金 ${fields.value[index]?.severance_pay_manyen}万円`}
                                </Typography>
                              )}
                            </div>
                          )}

                          {Number(fields.value[index]?.change_type) === CHANGE_TYPE.retirementOfficer && (
                            <div className="bg-[#f5f5f5] p-10" >
                              <Typography className="pb-[5px] text-[11px]">
                                {`変更後の年収　${convertLocaleString(
                                  Number(fields.value[index]?.change_type) === CHANGE_TYPE.employment
                                    ? fields.value[index]?.estimated_annual_income_manyen
                                    : fields.value[index]?.retirement_officers_amount_manyen,
                                )}万円`}
                              </Typography>
                              <Typography className="pb-[5px] text-[11px]">
                                {`変更後の収入上昇率 `}
                                {
                                  fields.value[index]?.current_salary_increase_rate_setting_type == CURRENT_SALARY_INCREASE_RATE_SETTING_TYPE.input ?
                                    `${defaultToEmptyString(fields.value[index]?.current_salary_increase_rate_percent )}%`:
                                    `${convertRateSettingType(fields.value[index]?.current_salary_increase_rate_setting_type )}`
                                }
                              </Typography>
                              <Typography className="pb-[5px] text-[11px]">
                                {`${ fields.value[index]?.scheduled_quit_at_age - 1 }歳11ヶ月時点の年収` +
                                  `${renderFutureIncome( Number( fields.value[index]?.scheduled_quit_at_age,) - 1 )}` }
                              </Typography>

                              { Number(fields.value[index]?.severance_pay_manyen) > 0 && (
                                <Typography className="pb-[5px] text-[11px]">
                                  {`退職金 ${fields.value[index]?.severance_pay_manyen}万円`}
                                </Typography>
                              )}
                            </div>
                          )}

                          {Number(fields.value[index]?.change_type) === CHANGE_TYPE.retirement && (
                            <div className="bg-[#f5f5f5] p-10" >
                              <Typography className="pb-[5px] text-[11px]">
                                {`変更後の社会保険 ${ convertInsurancePensionType( fields.value[index]?.insurance_pension_type || null )}`}
                              </Typography>
                            </div>
                          )}
                        </div>

                        <div className="p-[16px] border border-black-600 rounded-[4px]">
                          <Typography className="text-[14px] text-black-600" isBold>
                            {`${
                              fields.value[index]?.scheduled_quit_at_age - 1
                            }歳11ヶ月まで`}
                          </Typography>
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                ))
              }
            </FieldArray>

            <LoadingOverlay isLoading={isLoading} />
          </div>
          {!isIncomeInputCompleted && (
            <>
              <div className="p-[16px] border border-black-600 rounded-[4px] mt-[16px]">
                <Grid className="grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px] pt-40 pb-20">
                <div className="block md:flex justify-between items-baseline mb-[10px] last:mb-0 pb-[10px] border-b-2 border-dashed border-black-300">
                  <Typography className="min-w-[fit-content] md:min-w-[max-content] pb-[10px] md:pb-0 text-[14px] mr-[10px]">
                    変化の内容
                  </Typography>
                  <SelectBox
                    isFullWidth
                    name="change_type"
                    placeholder="選択する"
                    options={CHANGE_TYPE_SELECT_ITEMS}
                  />
                </div>

                {Number(values?.change_type) === CHANGE_TYPE.employment && (
                  <div className="block md:flex justify-between items-baseline mb-[10px] pb-[10px] border-b-2 border-dashed border-black-300">
                    <Typography className="min-w-[fit-content] md:min-w-[max-content] pb-[10px] md:pb-0 text-[14px] mr-[10px]">
                      変更後の年収
                    </Typography>
                    <div className="flex items-baseline">
                      <NumberField
                        isFullWidth
                        name="estimated_annual_income_manyen"
                        defaultValue={values?.estimated_annual_income_manyen ?? 0}
                        validate={composeValidators(
                          required,
                          zeroOrMoreNumber,
                          (value: any) => commaNumberMaxLength(value, 6),
                        )}
                      />
                      <Typography
                        className="pl-[8px] text-[14px] text-black-800 min-w-[fit-content]"
                        isBold
                      >
                        万円
                      </Typography>
                    </div>
                  </div>
                )}

                {Number(values?.change_type) === CHANGE_TYPE.retirementOfficer && (
                  <div className="block md:flex justify-between items-center mb-[10px] pb-[10px] border-b-2 border-dashed border-black-300">
                    <Typography className="min-w-[fit-content] md:min-w-[max-content]  pb-[10px] md:pb-0 text-[14px]">
                      変更後の年収
                    </Typography>
                    <div className="flex items-baseline">
                      <NumberField
                        isFullWidth
                        name="retirement_officers_amount_manyen"
                        defaultValue={values?.retirement_officers_amount_manyen ?? 0}
                        validate={composeValidators(
                          required,
                          zeroOrMoreNumber,
                          (value: any) => commaNumberMaxLength(value, 6),
                        )}
                      />
                      <Typography
                        className="pl-[8px] text-[14px] text-black-800 min-w-[fit-content]"
                        isBold
                      >
                        万円
                      </Typography>
                    </div>
                  </div>
                )}

                {/* 変更後の加入保険/年金は転職を伴わないときは不要 */}
                {(Number(values?.change_type) === CHANGE_TYPE.employment ||
                  Number(values?.change_type) === CHANGE_TYPE.retirement)&& (
                  <div className="block md:flex justify-between items-baseline mb-[10px] pb-[10px] border-b-2 border-dashed border-black-300">
                    <Typography className="min-w-[fit-content] md:min-w-[max-content] pb-[10px] md:pb-0 text-[14px]">
                      変更後の加入保険/年金
                    </Typography>
                    <div className="flex items-baseline">
                      <SelectBox
                        isFullWidth
                        className="md:w-[200px]"
                        name="insurance_pension_type"
                        placeholder="選択する"
                        options={INSURANCE_PENSION_TYPE_SELECT_ITEMS}
                        initialValue={INSURANCE_PENSION_TYPE_SELECT_ITEMS.at(0)?.value}
                      />
                    </div>
                  </div>
                )}

                {(Number(values?.change_type) === CHANGE_TYPE.employment ||
                  Number(values?.change_type) === CHANGE_TYPE.retirementOfficer ) && (
                    <>
                      {/* // 収入上昇率 */}
                      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline mb-10">
                        <Typography
                          className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                          isBold
                        >
                          収入上昇率設定
                        </Typography>
                        <SelectBox
                          name="current_salary_increase_rate_setting_type"
                          options={CURRENT_SALARY_INCREASE_RATE_SETTING_TYPE_SELECT_ITEMS}
                          placeholder="選択する"
                          isFullWidth
                          initialValue={CURRENT_SALARY_INCREASE_RATE_SETTING_TYPE_SELECT_ITEMS.at(0)?.value}
                        />
                      </div>

                      {/* // 収入上昇率（手入力） */}
                      { values?.current_salary_increase_rate_setting_type === CURRENT_SALARY_INCREASE_RATE_SETTING_TYPE.input && (
                        <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline mb-10">
                          <Typography
                            className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                            isBold
                          >
                            収入の上昇率
                          </Typography>
                          <div className="flex flex-auto items-baseline">
                            <NumberFieldDecimal
                              name="current_salary_increase_rate_percent"
                              validate={composeValidators(
                                required,
                                zeroOrMoreNumber,
                                oneHundredLessThanNumber,
                              )}
                              isFullWidth
                            />
                            <Typography
                              className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                              isBold
                            >
                              ％
                            </Typography>
                          </div>
                        </div>
                      )}

                      {/* // 業界平均に準じて上昇させる */}
                      { Number(values?.change_type) === CHANGE_TYPE.employment && values?.current_salary_increase_rate_setting_type === CURRENT_SALARY_INCREASE_RATE_SETTING_TYPE.average && (
                          <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                            <Typography
                              className="min-w-[140px] lg:min-w-[280px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                              isBold
                            >
                              業種
                            </Typography>
                            <SelectBox
                              isFullWidth
                              placeholder="選択してください"
                              name="industry"
                              options={INPUT_INDUSTRY_BUTTON_ITEMS}
                              onChange={(event: any) => {
                                const value = event.target.value
                                change('industry', value)
                              }}
                            />
                          </div>
                        )}
                    </>
                )}

                <div className="block md:flex justify-between items-center mb-[10px] pb-[10px] border-b-2 border-dashed border-black-300">
                  <div className="flex items-center pb-[10px] md:pb-0">
                    <Typography className="min-w-[miin-content] md:min-w-[max-content] pr-[5px] text-[14px]">
                      退職予定年齢
                    </Typography>
                    <div className="mx-[10px]">
                      <Tooltip
                        item="設定した年齢になるまで条件が適用されます。例えば、50歳までと登録した場合、49歳11ヶ月まで上記の条件が適用されます。"
                        itemClassName="w-[80px] sm:w-[200px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                      >
                        <HelpCircleIcon />
                      </Tooltip>
                    </div>
                  </div>
                  <div className="flex items-baseline">
                    <SelectBox
                      isFullWidth
                      name="scheduled_quit_at_age"
                      placeholder="選択する"
                      options={ageSelectItems}
                    />
                    <Typography
                      className="pl-[8px] text-[14px] text-black-800 min-w-[fit-content]"
                      isBold
                    >
                      歳
                    </Typography>
                  </div>
                </div>

                {(Number(values?.change_type) === CHANGE_TYPE.employment ||
                  Number(values?.change_type) === CHANGE_TYPE.retirementOfficer ) && (
                    <div className="block md:flex justify-between items-baseline mb-[10px] pb-[10px] border-b-2 border-dashed border-black-300">
                      <Typography className="min-w-[fit-content] md:min-w-[max-content]  pb-[10px] md:pb-0 text-[14px]">
                        退職金
                      </Typography>
                      <div className="flex items-baseline">
                        <NumberField
                          isFullWidth
                          name="severance_pay_manyen"
                          defaultValue={values?.severance_pay_manyen ?? 0}
                          validate={composeValidators(
                            required,
                            zeroOrMoreNumber,
                            (value: any) => commaNumberMaxLength(value, 6),
                          )}
                        />
                        <Typography
                          className="pl-[8px] text-[14px] text-black-800 min-w-[fit-content]"
                          isBold
                        >
                          万円
                        </Typography>
                      </div>
                    </div>
                )}
                </Grid>

                <Button
                  className="w-[188px] h-[45px] mx-auto border border-dashed border-primary-500 text-[14px] text-primary-500 hover:bg-primary-50 mb-[16px]"
                  isDisabled={validateAddIncome()}
                  onClick={handleAddIncomeChange}
                >
                  <Typography className="text-[16px]" isBold>
                    追加する
                  </Typography>
                </Button>

                <Button
                  className="mx-auto text-[14px] text-black-700"
                  onClick={handleResetIncomeChange}
                >
                  リセット
                </Button>
              </div>
            </>
          )}
        </div>
      )}
    </>
  )
}

export default IncomeChange
