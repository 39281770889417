import React, { FC } from 'react'
import SimulationReflectSummary from '@/assets/images/v2/simulation-g-reflect-summary.png'
import SimulationCheck from '@/assets/images/v2/simulation-g-summary-check.png'
import SimulationCreate from '@/assets/images/v2/simulation-g-create.png'
import Typography from '@/components/v2/atoms/Typography'
import GuidanceModal from '@/components/v2/organisms/GuidanceModal'

interface SimulationAfterCreateModalProps {
  /** true: オープン, false: クローズ */
  isOpen: boolean
  /** モーダルクローズメソッド */
  onClose: () => void
}

const SimulationAfterCreateModal: FC<SimulationAfterCreateModalProps> = (props) => {
  const { isOpen, onClose } = props

  return (
    <GuidanceModal
      isOpen={isOpen}
      contents={[
        {
          title: 'グラフ・表に反映させましょう',
          text: (
            <div className="pb-[30px]">
              <Typography className="pb-[10px] text-12 md:text-16">
                １つ目のシミュレーションが登録できました。
                <br />
                登録した項目を選択することで、シミュレーションがサマリーへ反映されます。
              </Typography>
              <Typography className="text-12 md:text-16">
                それぞれの表・グラフの見方をおさえていきましょう！
              </Typography>
            </div>
          ),
          src: SimulationReflectSummary,
          alt: 'simulation reflection',
        },
        {
          title: 'サマリーのチェックポイント',
          text: (
            <div className="pb-[30px]">
              <Typography className="pb-[10px] text-12 md:text-16">
                サマリーでは、次の２つを確認しましょう！
              </Typography>
              <Typography className="text-12 md:text-16">
                ①総資産額はイメージ通りか？
                <br />
                ②お金の寿命は１００歳に届いているか？
              </Typography>
            </div>
          ),
          afterText: (
            <div className="pt-[30px]">
              <Typography className="text-12 md:text-16">
                総資産額が思ったより少ない場合や、お金の寿命が１００歳を切っている場合は、クリアできるようにシミュレーションお登録を追加・修正しましょう。
                <br />
                工夫しながら理想の将来像に近づけていきましょう！
              </Typography>
            </div>
          ),
          src: SimulationCheck,
          alt: 'simulation check',
        },
        {
          title: 'シミュレーションプランを保存しましょう',
          text: (
            <div className="pb-[30px]">
              <Typography className="text-12 md:text-16">
                一通りのシミュレーションの入力が終わったら、
                <br />
                画面右上の「保存する」ボタンをクリックし、
                <br />
                プラン名を入力して保存しましょう！
              </Typography>
            </div>
          ),
          src: SimulationCreate,
          alt: 'simulation create',
        },
      ]}
      onClose={onClose}
    />
  )
}

export default SimulationAfterCreateModal
