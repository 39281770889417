import React, { FC, useMemo, ChangeEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'react-final-form'
import clsx from 'clsx'
import { set as _set, every as _every, isNull as _isNull } from 'lodash'
import ChevronRightIcon from '@/assets/images/v2/chevron-right.svg'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import Grid from '@/components/v2/atoms/Grid'
import Modal from '@/components/v2/atoms/Modal'
import Typography from '@/components/v2/atoms/Typography'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import SelectBox from '@/components/v2/molecules/SelectBox'
import SubmitErrorMessage from '@/components/v2/molecules/SubmitErrorMessage'
import { fetchAccountInputHousingOptionsV2 } from '@/containers/accountsSlice'
import {
  createInputHousingRent,
  createInputHousingTransfer,
  fetchInputHousings,
} from '@/containers/inputs/inputHousingsSlice'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import { HOUSE_TYPE } from '@/models/commonHousingModelFunc'
import { API_FLAG, castNumberWithoutOperator } from '@/models/commonsModelFunc'
import { INPUT_HOUSING_RENT_TYPE } from '@/models/inputs/inputHousingRentsModelFunc'
import { InputHousingCreateValues } from '@/models/inputs/inputHousingsModel'
import {
  createInitialValues,
  isRentHouseType,
  isNotSelfHouseType,
  hasTransferHouse,
} from '@/models/inputs/inputHousingsModelFunc'
import MonthlyRent from '@/templates/v2/commons/modals/commonHousing/rents/MonthlyRent'
import NextContractUpdate from '@/templates/v2/commons/modals/commonHousing/rents/NextContractUpdate'
import NextUpdateCost from '@/templates/v2/commons/modals/commonHousing/rents/NextUpdateCost'
import TransferMonthlyRent from '@/templates/v2/myData/housings/transfers/TransferMonthlyRent'
import TransferContractUpdate from '@/templates/v2/myData/housings/transfers/TransferContractUpdate'
import TransferUpdateCost from '@/templates/v2/myData/housings/transfers/TransferUpdateCost'
import { required } from '@/utils/validate'
import {
  buildHousingRentSubmitValues,
  buildHousingTransferSubmitValues,
} from '@/utils/modalForms/inputHousings'
import { RootState } from '@/store'

const NOT_SELF_TEMPLATES = [
  {
    children: <MonthlyRent />,
    condition: (values: any) =>
      isRentHouseType(Number(values?.input_housing?.house_type)) ||
      isNotSelfHouseType(Number(values?.input_housing?.house_type)),
  },
  {
    children: <NextContractUpdate />,
    condition: (values: any) =>
      isRentHouseType(Number(values?.input_housing?.house_type)) ||
      isNotSelfHouseType(Number(values?.input_housing?.house_type)),
  },
  {
    children: <NextUpdateCost />,
    condition: (values: any) =>
      (isRentHouseType(Number(values?.input_housing?.house_type)) ||
        isNotSelfHouseType(Number(values?.input_housing?.house_type))) &&
      Number(
        values?.input_housing?.input_housing_rents_attributes?.[0]?.next_update_type,
      ) == API_FLAG.on,
  },
]

const TRANSFER_TEMPLATES = [
  {
    children: <TransferMonthlyRent />,
    condition: (values: any) =>
      hasTransferHouse(
        values?.input_housing?.input_housing_transfer_attributes.transfer_type,
      ),
  },
  {
    children: <TransferContractUpdate />,
    condition: (values: any) =>
      hasTransferHouse(
        values?.input_housing?.input_housing_transfer_attributes.transfer_type,
      ),
  },
  {
    children: <TransferUpdateCost />,
    condition: (values: any) =>
      hasTransferHouse(
        values?.input_housing?.input_housing_transfer_attributes.transfer_type,
      ) &&
      Number(
        values?.input_housing?.input_housing_transfer_attributes?.transfer_update_type,
      ) === API_FLAG.on,
  },
]

interface MyDataHousingModalFormProps {
  /** true: オープン, false: クローズ */
  isOpen: boolean
  /** onCloseメソッド */
  onClose: () => void
}

const MyDataHousingModalForm: FC<MyDataHousingModalFormProps> = (props) => {
  const dispatch = useDispatch()
  const { showFlashMsg } = useFlashAlert()
  const { isOpen, onClose } = props

  const { inputHousings } = useSelector((state: RootState) => state.inputHousings)
  const initialValues = useMemo(() => createInitialValues, [])

  const houseTypeOptions = useMemo(() => {
    const options = []
    const isEmptyHousing = _every(
      inputHousings,
      (housing) =>
        housing.input_housing_rents?.length === 0 &&
        _isNull(housing.input_housing_self_owned),
    )
    const isEmptyTransfer = _every(inputHousings, (housing) =>
      _isNull(housing.input_housing_transfer),
    )

    isEmptyHousing && options.push({ value: HOUSE_TYPE.notSelf, label: '社宅・寮' })
    isEmptyTransfer && options.push({ value: HOUSE_TYPE.transfer, label: '単身赴任先' })

    return options
  }, [inputHousings])

  const handleChangeHouseType = (
    houseType: number,
    change: (name: string, value: any) => void,
  ) => {
    if (houseType == HOUSE_TYPE.notSelf) {
      change(
        'input_housing.input_housing_rents_attributes[0]rent_type',
        INPUT_HOUSING_RENT_TYPE.companyRent,
      )
    } else {
      change('input_housing.transfer_type', API_FLAG.on)
      change('input_housing.input_housing_transfer_attributes.transfer_type', API_FLAG.on)
    }
  }

  const handleSubmit = (values: InputHousingCreateValues) => {
    const {
      house_type,
      input_housing_rents_attributes,
      input_housing_transfer_attributes,
    } = values.input_housing || {}

    const commonCallback = () => {
      dispatch(fetchInputHousings())
      dispatch(fetchAccountInputHousingOptionsV2())

      showFlashMsg('作成しました')
      onClose()
    }

    if (house_type == HOUSE_TYPE.notSelf) {
      const newValuesWithRent = {
        input_housing: {
          house_type: HOUSE_TYPE.rent,
          input_housing_rents_attributes: [
            { ...buildHousingRentSubmitValues(input_housing_rents_attributes![0]!) },
          ],
        },
      }

      new Promise((resolve) =>
        dispatch(createInputHousingRent(newValuesWithRent, commonCallback, resolve)),
      )
    } else {
      const newTransferValues = {
        input_housing: {
          house_type: HOUSE_TYPE.transfer,
          input_housing_transfer_attributes: {
            ...buildHousingTransferSubmitValues(input_housing_transfer_attributes!),
          },
        },
      }

      new Promise((resolve) =>
        dispatch(createInputHousingTransfer(newTransferValues, commonCallback, resolve)),
      )
    }
  }

  return (
    <Modal
      className="w-[95%] xl:w-[1140px]"
      isOpen={isOpen}
      isDisableBackdropClick
      onClose={onClose}
    >
      <CircleTitle
        className="pb-[32px]"
        title="住宅を追加する"
        classNameTitle="text-[19px] md:text-[24px]"
      />
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validate={(values) => {
          const validateMessage = {}
          const {
            rent_type,
            rent_monthly,
            whole_rent_monthly,
            next_update_cost,
            whole_next_update_cost,
          } = values.input_housing?.input_housing_rents_attributes?.[0] || {}
          const isNotSelfRent = rent_type !== INPUT_HOUSING_RENT_TYPE.selfRent

          if (
            isNotSelfRent &&
            castNumberWithoutOperator(rent_monthly) >
              castNumberWithoutOperator(whole_rent_monthly)
          ) {
            _set(
              validateMessage,
              ['input_housing', 'input_housing_rents_attributes', '0', 'rent_monthly'],
              '本来の家賃以下でご入力ください。',
            )
          }

          if (
            isNotSelfRent &&
            castNumberWithoutOperator(next_update_cost) >
              castNumberWithoutOperator(whole_next_update_cost)
          ) {
            _set(
              validateMessage,
              [
                'input_housing',
                'input_housing_rents_attributes',
                '0',
                'next_update_cost',
              ],
              '本来の更新費用以下でご入力ください。',
            )
          }

          return validateMessage
        }}
        render={({
          handleSubmit,
          values,
          errors,
          submitErrors,
          submitFailed,
          form: { change },
        }) => {
          const { house_type } = values.input_housing || {}

          return (
            <form onSubmit={handleSubmit}>
              <Grid className="pb-[64px] grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px]">
                <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                  <Typography
                    className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                    isBold
                  >
                    住宅の分類
                  </Typography>
                  <SelectBox
                    name="input_housing.house_type"
                    placeholder="選択する"
                    options={houseTypeOptions}
                    isFullWidth
                    validate={required}
                    onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                      handleChangeHouseType(Number(event.target.value), change)
                    }
                  />
                </div>
                <div className="hidden md:block" />

                {house_type == HOUSE_TYPE.notSelf && (
                  <>
                    {NOT_SELF_TEMPLATES.map(
                      (item, index) =>
                        item.condition(values) && (
                          <React.Fragment key={index}>{item.children}</React.Fragment>
                        ),
                    )}
                  </>
                )}

                {house_type == HOUSE_TYPE.transfer && (
                  <>
                    {TRANSFER_TEMPLATES.map(
                      (item, index) =>
                        item.condition(values) && (
                          <React.Fragment key={index}>{item.children}</React.Fragment>
                        ),
                    )}
                  </>
                )}
              </Grid>

              <SubmitErrorMessage show={!submitErrors && submitFailed} />
              <ButtonPrimary
                className={clsx('w-[fit-content] md:w-[246px] h-[64px] mx-[auto]', {
                  'opacity-50': Object.keys(errors).length > 0,
                })}
                onClick={() => handleSubmit(values as any)}
              >
                <Typography
                  className="bottom-2 flex-auto pl-30 relative text-16 text-white"
                  isBold
                >
                  保存する
                </Typography>
                <ChevronRightIcon className="mr-17" />
              </ButtonPrimary>
            </form>
          )
        }}
      />
    </Modal>
  )
}

export default MyDataHousingModalForm
