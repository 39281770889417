import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Typography from '@/components/v1/atoms/Typography'
import { convertLocaleString } from '@/models/commonsModelFunc'
import { RootState } from '@/store'

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      paddingBottom: '10px',
      fontWeight: 'bold',
    },
    table: {
      marginTop: '10px',
      width: '100%',
      borderCollapse: 'collapse',
      border: '1px solid',
    },
    tableCell: {
      padding: '15px',
      maxWidth: '250px',
      border: '1px solid',
    },
  }),
)

interface HousingLoanAmountTableProps {
  /** true: マイホーム購入, false: 不動産投資 */
  isHousePurchase: boolean
}

const HousingLoanAmountTable: FC<HousingLoanAmountTableProps> = (props) => {
  const classes = useStyles()
  const { isHousePurchase } = props
  const { futureHousingBorrowingLimit } = useSelector(
    (state: RootState) => state.futureHousings,
  )

  return (
    <>
      <Typography className={classes.title}>
        各方式の説明とそれぞれの上限額について
      </Typography>
      <Typography>
        現在登録されている職業と収入から借入上限額を算出いたしました。
        <br />
        あくまでも予測値になりますので、上限額を保証するものではないことをご理解いただけますよう宜しくお願いいたします。
      </Typography>
      <table className={classes.table}>
        <thead>
          <tr>
            <td className={classes.tableCell}>
              <Typography>借入方式</Typography>
            </td>
            <td className={classes.tableCell}>
              <Typography>説明文</Typography>
            </td>
            <td className={classes.tableCell}>
              <Typography>借入上限金額</Typography>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className={classes.tableCell}>
              <Typography>ご本人単独名義</Typography>
            </td>
            <td className={classes.tableCell}>
              <Typography>
                どちらかお一人の名義でローンを借り入れる方式です。借入審査が名義人を対象に行われます。
              </Typography>
            </td>
            <td className={classes.tableCell}>
              <Typography variant="h4" display="inline">
                {convertLocaleString(futureHousingBorrowingLimit.person)}
              </Typography>
              <Typography display="inline">万円</Typography>
            </td>
          </tr>
          <tr>
            <td className={classes.tableCell}>
              <Typography>配偶者単独名義</Typography>
            </td>
            <td className={classes.tableCell}>
              <Typography>
                どちらかお一人の名義でローンを借り入れる方式です。借入審査が名義人を対象に行われます。
              </Typography>
            </td>
            <td className={classes.tableCell}>
              <Typography variant="h4" display="inline">
                {convertLocaleString(futureHousingBorrowingLimit.spouse)}
              </Typography>
              <Typography display="inline">万円</Typography>
            </td>
          </tr>
          {isHousePurchase && (
            <>
              <tr>
                <td className={classes.tableCell}>
                  <Typography>ペアローン</Typography>
                </td>
                <td className={classes.tableCell}>
                  <Typography>
                    夫婦それぞれが自分の名義でローンを借り入れる方式です。共働きの場合に住宅ローン減税の枠を増やすことができる場合があります。
                  </Typography>
                </td>
                <td className={classes.tableCell}>
                  <Typography variant="h4" display="inline">
                    {convertLocaleString(futureHousingBorrowingLimit.pear)}
                  </Typography>
                  <Typography display="inline">万円</Typography>
                </td>
              </tr>
              <tr>
                <td className={classes.tableCell}>
                  <Typography>収入合算</Typography>
                </td>
                <td className={classes.tableCell}>
                  <Typography>
                    夫婦の収入を合算した世帯年収をベースにローンを借り入れる方式です。配偶者の方がパートの場合などに、ご本人の収入に加算することで借入可能額を増やすことができます。
                  </Typography>
                </td>
                <td className={classes.tableCell}>
                  <Typography variant="h4" display="inline">
                    {convertLocaleString(futureHousingBorrowingLimit.sum)}
                  </Typography>
                  <Typography display="inline">万円</Typography>
                </td>
              </tr>
            </>
          )}
        </tbody>
      </table>
    </>
  )
}

export default HousingLoanAmountTable
