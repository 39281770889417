import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import LogindRedirect from '@/layouts/v2/LogindRedirect'
import MarginContainer from '@/components/v2/atoms/MarginContainer'
import Paper from '@/components/v2/atoms/Paper'
import Heading from '@/components/v2/atoms/Heading'
import Typography from '@/components/v2/atoms/Typography'
import Button from '@/components/v2/atoms/Button'
import { setPageMeta } from '@/containers/pageMetasSlice'
import { useCustomHistory } from '@/hooks/useCustomHistory'

const Policy: FC = () => {
  const dispatch = useDispatch()
  const { handleMovePage } = useCustomHistory()

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'プライバシーポリシー',
        description: 'プライバシーポリシー',
        keyword: 'プライバシーポリシー',
      }),
    )
  }, [])

  return (
    <MarginContainer className="py-40">
      <Paper className="mx-8 px-16 py-24 sm:p-40 space-y-30 text-black-800 bg-white">
        <Heading className="text-center">
          マネソル<span className="inline-block">プライバシーポリシー</span>
        </Heading>
        <section>
          <Typography>
            株式会社アルファ・ファイナンシャルプランナーズ（以下「当社」といいます。）は、当社が運営するウェブサービス「マネソル」（関連するウェブサイトを含みます。以下「本サービス」といいます。）において、ユーザー（以下「お客様」といいます。）から取得する個人情報（個人情報の保護に関する法律（以下「個人情報保護法」といいます。）に定義される「個人情報」をいいます。）を保護する為の方針を定めています。
          </Typography>
        </section>
        <section>
          <Heading as="h2" size={4}>
            1. 取組方針について
          </Heading>
          <Typography>
            当社は、個人情報の適切な保護と利用に関し、関連法令等に加えて、本ポリシーに定めた事項を遵守し、お客様の個人情報の適切な保護と利用に努めるとともに、情報化の進展に適切に対応するため、当社における個人情報保護の管理体制およびその取組みについて、継続的な改善に努めます。
          </Typography>
        </section>
        <section>
          <Heading as="h2" size={4}>
            2. 個人情報の取得とその目的について
          </Heading>
          <Typography>
            当社は本サービスにおいて、業務上必要な範囲内で、お客様の個人情報を取得する場合があります。
          </Typography>
          <Typography>お客様の個人情報は下記の目的のために利用します。</Typography>
          <Typography>（1） 本サービスの提供、管理、改善および開発のため。</Typography>
          <Typography>
            （3） お客様に対して本サービスの利用状況、稼働状況等を案内するため。
          </Typography>
          <Typography>
            （4） 当社グループ会社が取り扱うサービスおよび商品の提供のため。
          </Typography>
          <Typography>
            （5）
            当社が指定するファイナンシャルプランナーによるライフプランの構築支援サービスをユーザーが利用する場合、当該ファイナンシャルプランナーにおいて当該サービスを提供するため。
          </Typography>
          <Typography>
            （6）
            上記（1）（2）の情報、サービス提供のための郵便物、電話、電子メール等によるご案内およびマーケティング（アンケートのお願い等）活動を行うため。
          </Typography>
          <Typography>
            （7）
            個人または特定の利用者を識別できない形式に加工した、本サービスの利用状況に関する統計データを作成するため。
          </Typography>
          <Typography>（8） 動向分析もしくは商品開発等の調査分析のため。</Typography>
          <Typography>
            （9） 上記の利用目的の達成に必要な範囲内での個人情報の第三者への提供のため。
          </Typography>
          <Typography>（10） その他前各項目に付帯・関連する事項のため。</Typography>
          <Typography>
            ※情報・サービスの提供は、ご本人からのお申し出がありましたら、取りやめさせていただきます。
          </Typography>
        </section>
        <section>
          <Heading as="h2" size={4}>
            3. 個人情報の適正な取得について
          </Heading>
          <Typography>
            当社では、前記2.で特定した利用目的の達成に必要な範囲で、適正かつ適法な手段により、お客様の個人情報を取得いたします。
          </Typography>
        </section>
        <section>
          <Heading as="h2" size={4}>
            4. 個人情報の第三者提供について
          </Heading>
          <Typography>
            お客様の個人情報は、法令に基づく場合のほか、上記の利用目的の達成に必要な範囲以内で、お名前、ご住所、電話番号等の所要項目について、書面、郵便物、電話、電子メール等により、以下の相手先などに提供されます。
          </Typography>
          <Typography>
            （1） 本サービスに関して当社と業務提携している販売代理会社、媒介会社
          </Typography>
          <Typography>
            （2） 前記2.（4）で特定した利用目的に関与する当社グループ会社、取引会社
          </Typography>
          <Typography>
            （3）
            前記2.（5）で特定した当社が指定するファイナンシャルプランナー（ファイナンシャルプランナー個人の他、当社以外の組織に属する場合には当該サービス提供に必要となる当該組織の役職員を含みます。）
          </Typography>
          <Typography>
            （4）
            その他、前記2.で特定した利用目的の達成に必要な範囲の第三者当社は、法で定める場合を除き、前項以外にはあらかじめご本人の同意を得ることなく、お客様の個人情報を第三者に提供することはいたしません。
          </Typography>
        </section>
        <section>
          <Heading as="h2" size={4}>
            5. SSL（Secure Socket Layer）について
          </Heading>
          <Typography>
            本サービスでは、お客様の個人情報を安全に保護するために、SSL（Secure Socket
            Layer）を利用した暗号化通信を使用しています。SSLに対応したブラウザを使用することで、お客様が入力される氏名や住所あるいは電話番号などの個人情報が自動的に暗号化されて送受信されるため、万が一、送受信データが第三者に傍受された場合でも、内容が盗み取られる心配はありません。
          </Typography>
        </section>
        <section>
          <Heading as="h2" size={4}>
            6. ログファイル・クッキー等の技術的情報について
          </Heading>
          <Typography>
            本サービスでは、ログファイルの収集、クッキーの送信などによりお客様のIPアドレス、アクセス回数、ご利用ブラウザ、OSその他利用端末などの情報を収集しています。また、本サービスでは、オンライン利用およびインターネット全般の利用を、より充実したものとするために、当サイトや、当社の第三者プロバイダー、および当社の業務提携先がお客様のコンピュータにクッキー情報等を送付したり、同種の技術を利用したりすることがあります。当該第三者によって取得されたクッキー情報等は、当該第三者のプライバシーポリシーに従って取り扱われます。お客様は、当該第三者のウェブサイト内に設けられたオプトアウトページにアクセスして、当該第三者によるクッキー情報等を利用する広告配信を停止クッキーはお客様がご利用のブラウザの設定によって受け入れを拒否することができますが、その場合には本サービスの一部がご利用頂けなくなる可能性があります。これらの情報は、本サービスの利用傾向の分析や問題発生時の原因究明を目的に収集しており、個人を特定する目的の利用はしておりません。
          </Typography>
        </section>
        <section>
          <Heading as="h2" size={4}>
            7. 広告関連情報について
          </Heading>
          <Typography>
            1.
            当社は、当社が提供するSDKやライブラリを含む本システムを通じ、広告関連情報（個人情報を含まない、閲覧環境や広告閲覧履歴、広告識別子などの情報。以下「広告関連情報」といいます。）を収集し、これを広告の配信や効果測定、広告配信システムにおける不正・セキュリティ対策や性能の改善などの目的で利用、あるいは提携パートナーへ当該情報を提供するほか、当社プライバシーポリシーに基づいてこの情報を管理することができるものとします。
          </Typography>
          <Typography>
            2.
            利用者は、本広告掲載メディアまたはそのリンク先において目立つように少なくとも次の事項を含む通知を行い、追加で同意取得等の必要な対応を実施するものとし、既にユーザーに対してこれを実施していることを確認します。
          </Typography>
          <Typography>
            （1）
            当社を含む第三者の提供する広告配信システムを利用するにあたり、本広告掲載メディアからcookieやSDK等の技術を利用して、本条1項に記載するよう広告関連情報を当該第三者（本サービスの場合には当社）が収集し、同項に記載するような目的において利用する場合があること
          </Typography>
          <Typography>
            （2）
            上記（1）で取得された広告関連情報が、取得後に第三者において個人情報に紐づいて管理される可能性があること
          </Typography>
          <Typography>
            （3）
            広告配信システムを提供する第三者が提供するオプトアウト手段により、以降収集される訪問・行動履歴の広告配信への利用を拒否することができること
          </Typography>
          <Typography>（4） オプトアウトが行使できる手段へのリンク</Typography>
          <Typography>
            3.
            利用者は本サービスの使用にあたり、その他適用される法律及び業界標準で求められるユーザーへの配慮措置を講じる責任を負うものとします。
          </Typography>
        </section>
        <section>
          <Heading as="h2" size={4}>
            8．安全管理措置について
          </Heading>
          <Typography>
            当社は、お客様の個人情報に関し、情報の紛失・改ざんおよび漏えい等の防止のため、適切な安全管理措置を実施いたします。また、お客様の個人情報を取扱う全ての役職員に対し、個人情報保護の重要性についての教育を行うとともに、ご相談者さまの個人情報を委託する場合には、委託先について適切に監督いたします。
          </Typography>
        </section>
        <section>
          <Heading as="h2" size={4}>
            9．開示請求等手続について
          </Heading>
          <Typography>
            当社は、法で定める開示請求等手続に関して、適切かつ迅速に対応いたします。お手続きをご要望の方は、下記窓口にお申し出下さい。また、本人以外が個人を認証または識別できる情報を入手して使用した場合、当社は責任を負いません。
          </Typography>
        </section>
        <section>
          <Heading as="h2" size={4}>
            10. セキュリティ対策について
          </Heading>
          <Typography>
            当社は、取得した個人情報保護のために合理的な予防措置を講じ、オンライン・オフラインの両方において保護を行います。ファイヤウォール、不正侵入検知、SSLなどの技術を用いて個人情報の取り扱いには細心の注意を図ると共に、業務上必要とされる権限を与えられた者のみがアクセス可能としています。
            その他、本サービスにおけるセキュリティ対策の詳細については、当社が別途定める情報セキュリティポリシーもご確認ください。
          </Typography>
        </section>
        <section>
          <Heading as="h2" size={4}>
            11. プライバシーポリシーの変更・更新通知について
          </Heading>
          <Typography>
            当社は、本サービスを定期的に見直し、改善を行います。それに伴い、プライバシーポリシーを変更する場合があります。プライバシーポリシーに重要な変更がある場合には、本サービス上で告知します。
          </Typography>
        </section>
        <section>
          <Heading as="h2" size={4}>
            12. お問い合わせについて
          </Heading>
          <Typography>
            当社は、個人情報の取扱いに関するご意見・ご要望につきまして、適切かつ迅速に対応いたします。当社の個人情報の取扱いおよび安全管理措置に関するご意見・ご要望・お問い合わせ等につきましては、下記窓口にお申し出下さい。
          </Typography>
        </section>
        <section>
          <Typography>【メールアドレス】</Typography>
          <Typography>info@alpha-fp.com</Typography>
        </section>
        <section>
          <Typography>【受付時間】</Typography>
          <Typography>水曜日以外の午前9時00分～午後5時00分</Typography>
        </section>
        <div className="flex justify-center">
          <Button
            className="h-45 w-full md:w-265 px-17 text-primary-500 border border-primary-500 rounded-8"
            onClick={() => handleMovePage('/v2/')}
          >
            <Typography className="bottom-2 flex-auto relative text-14" isBold>
              TOPへ戻る
            </Typography>
            <i className="ico chevron-right text-24" />
          </Button>
        </div>
      </Paper>
    </MarginContainer>
  )
}

export default Policy
