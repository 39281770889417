import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { unauthorizedCheck } from './accountsSlice'
import { postHousingLoanRepayment } from '../api/housingLoans'
import {
  HousingLoanRepaymentResponce,
  HousingLoanRepayment,
  HousingLoanRepaymentValues,
} from '../models/housingLoansModel'
import { AppThunk } from '../store'

interface HousingLoanState {
  housingLoanRepayment: HousingLoanRepayment
  isLoading: boolean
}

const initialState: HousingLoanState = {
  housingLoanRepayment: {
    repayment_monthly: 0,
    repayment_yearly: 0,
    sub_repayment_monthly: 0,
    sub_repayment_yearly: 0,
  },
  isLoading: false,
}

const housingLoansSlice = createSlice({
  name: 'housingLoans',
  initialState,
  reducers: {
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    postHousingLoanRepaymentSuccess: (
      state,
      action: PayloadAction<HousingLoanRepaymentResponce>,
    ) => {
      state.housingLoanRepayment = action.payload
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
  },
})

export const {
  requestStart,
  postHousingLoanRepaymentSuccess,
  requestFailure,
} = housingLoansSlice.actions
export const housingLoansReducer = housingLoansSlice.reducer

export const createHousingLoanRepayment = (
  values: HousingLoanRepaymentValues,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postHousingLoanRepayment(values)
    .then((response) => {
      dispatch(postHousingLoanRepaymentSuccess(response))
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}
