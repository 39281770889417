import { OccuranceType } from '@/models/futures/futureV2HousingOtherCostsModel'

export const OCCURANCE_TYPE = {
  once: 'once',
  everyYear: 'every_year',
  custom: 'custom',
}

export const convertOccuranceType = (occuranceType: OccuranceType | undefined) => {
  switch (occuranceType) {
    case OCCURANCE_TYPE.once:
      return '一回のみ'
    case OCCURANCE_TYPE.everyYear:
      return '毎年'
    case OCCURANCE_TYPE.once:
      return '年ごと'

    default:
      break
  }
  return ''
}
