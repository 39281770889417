import React, { FC } from 'react'
import clsx from 'clsx'
import {
  filter as _filter,
  isEmpty as _isEmpty,
  isNull as _isNull,
  isUndefined as _isUndefined,
} from 'lodash'
import Typography from '@/components/v2/atoms/Typography'
import { API_FLAG, convertLocaleString, isNotEmptyValue } from '@/models/commonsModelFunc'
import {
  convertLoanType2,
  convertInputLoanLoanType,
  convertInsuranceType,
  convertDeductionType,
  convertRateChangeValues,
  convertLoanLender,
  convertIsFlatApplied,
  isWithAdvanceRepayment,
  isBonusFlgOn,
  convertToJapaneseFullDate,
} from '@/models/commonHousingModelFunc'
import { convertPersonFlg } from '@/models/familyStructuresModelFunc'
import {
  RATE_TYPE,
  convertRepaymentChart,
  convertAdvanceType,
  convertAdvanceValues,
  convertLandOrBuildingLoanTitle,
} from '@/models/inputs/inputHousingsModelFunc'
import { isSplitedLoan } from '@/models/inputs/inputHousingSelfOwnedModelFunc'
import {
  isFlatLoan,
  isOneLoanPattern,
  isPairLoan,
  isTwoLoanPattern,
  isWithChartLoan,
} from '@/models/inputs/inputLoansModelFunc'
import { InputHousingSelfOwned } from '@/models/inputs/inputHousingSelfOwnedModel'
import { InputLoanRate } from '@/models/inputs/inputLoanRatesModel'
import { InputCalculationRate } from '@/models/inputs/inputCalculationRatesModel'

interface InputHousingLoanBodyProps {
  /** 現状住居データ  */
  housingSelfOwned: InputHousingSelfOwned
}

const InputHousingLoanBody: FC<InputHousingLoanBodyProps> = (props) => {
  const { housingSelfOwned } = props

  const selectAllInputLoanRates = (
    inputLoanRates: Partial<InputLoanRate>[] | undefined,
    nullType: 'null' | 'notNull',
    type?: 'bonus' | 'monthly',
  ) => {
    const rateType = type === 'bonus' ? RATE_TYPE.bonus : RATE_TYPE.monthly

    if (nullType === 'null') {
      return _filter(
        inputLoanRates,
        (loanRate) =>
          _isNull(loanRate?.rate_start_at) && loanRate?.rate_type === rateType,
      )
    }
    return _filter(
      inputLoanRates,
      (loanRate) => !_isNull(loanRate?.rate_start_at) && loanRate?.rate_type === rateType,
    )
  }

  const selectAllInputCalculationRatesByRateStartAt = (
    inputCalculationRates: Partial<InputCalculationRate>[],
    nullType: 'null' | 'notNull',
  ) => {
    if (nullType === 'null') {
      return _filter(inputCalculationRates!, (item) => _isNull(item.rate_start_at))
    }

    return _filter(inputCalculationRates!, (item) => !_isNull(item.rate_start_at))
  }

  return (
    <>
      <Typography styleType="underlinedHeader">ローン情報</Typography>
      <table className="w-[100%] mb-[60px] last:mb-0 text-[12px]">
        <tbody>
          {_isEmpty(housingSelfOwned?.input_loans) ? (
            <>
              <tr className="h-[45px] odd:bg-black-100">
                <td className="w-[50%] px-[16px]">
                  <Typography isBold>住宅ローン有無</Typography>
                </td>
                <td className="w-[50%] px-[16px]">
                  <Typography>借りていない</Typography>
                </td>
              </tr>
            </>
          ) : (
            <>
              <tr className="h-[45px] odd:bg-black-100">
                <td className="w-[50%] px-[16px]">
                  <Typography isBold>土地・建物で分割</Typography>
                </td>
                <td className="w-[50%] px-[16px]">
                  <Typography>
                    {convertLoanType2(housingSelfOwned?.loan_type2)}
                  </Typography>
                </td>
              </tr>

              {Object.keys(housingSelfOwned!.input_loans!).map((_, loanIndex) => {
                const { input_loans: inputLoans } = housingSelfOwned!
                const inputLoan = inputLoans![loanIndex]

                return (
                  <React.Fragment key={loanIndex}>
                    {(loanIndex === 0 ||
                      (housingSelfOwned!.loan_type2 === API_FLAG.on &&
                        ((loanIndex === 1 && isOneLoanPattern(inputLoans![0])) ||
                          (loanIndex === 2 && isTwoLoanPattern(inputLoans![0]))))) && (
                      <>
                        {isSplitedLoan(housingSelfOwned) && (
                          <tr className="h-[45px] odd:bg-black-100">
                            <td className="w-[50%] px-[16px]">
                              <Typography isBold>土地・建物で分割</Typography>
                            </td>
                            <td className="w-[50%] px-[16px]">
                              <Typography>
                                {convertLandOrBuildingLoanTitle(
                                  loanIndex,
                                  housingSelfOwned!,
                                )}
                              </Typography>
                            </td>
                          </tr>
                        )}

                        <tr className="h-[45px] odd:bg-black-100">
                          <td className="w-[50%] px-[16px]">
                            <Typography isBold>ローン方式</Typography>
                          </td>
                          <td className="w-[50%] px-[16px]">
                            <Typography>
                              {convertInputLoanLoanType(inputLoan.loan_type)}
                            </Typography>
                          </td>
                        </tr>

                        <tr className="h-[45px] odd:bg-black-100">
                          <td className="w-[50%] px-[16px]">
                            <Typography isBold>返済計画表</Typography>
                          </td>
                          <td className="w-[50%] px-[16px]">
                            <Typography>
                              {convertRepaymentChart(inputLoan.repayment_chart)}
                            </Typography>
                          </td>
                        </tr>

                        <tr className="h-[45px] odd:bg-black-100">
                          <td className="w-[50%] px-[16px]">
                            <Typography isBold>借り入れ先</Typography>
                          </td>
                          <td className="w-[50%] px-[16px]">
                            <Typography>
                              {convertLoanLender(inputLoan.loan_lender)}
                            </Typography>
                          </td>
                        </tr>
                      </>
                    )}
                    {!_isUndefined(inputLoan.input_loan_chart) &&
                      isWithChartLoan(inputLoan.repayment_chart) && (
                        <>
                          {isPairLoan(inputLoan.loan_type) && (
                            <tr className="h-[45px] odd:bg-black-100">
                              <td colSpan={2}>
                                <Typography className="">
                                  {convertPersonFlg(
                                    inputLoan.input_loan_chart!.person_flg!,
                                  )}
                                </Typography>
                              </td>
                            </tr>
                          )}

                          <tr className="h-[45px] odd:bg-black-100">
                            <td className="w-[50%] px-[16px]">
                              <Typography isBold>最終返済日</Typography>
                            </td>
                            <td className="w-[50%] px-[16px]">
                              <Typography>
                                {convertToJapaneseFullDate(
                                  inputLoan.input_loan_chart!.repayment_end_at,
                                )}
                              </Typography>
                            </td>
                          </tr>

                          <tr className="h-[45px] odd:bg-black-100">
                            <td className="w-[50%] px-[16px]">
                              <Typography isBold>現在の金利</Typography>
                            </td>
                            <td className="w-[50%] px-[16px]">
                              <Typography>
                                {`${
                                  selectAllInputLoanRates(
                                    inputLoan.input_loan_chart!.input_loan_rates!,
                                    'null',
                                    'monthly',
                                  )?.[0]?.changed_rate_percent
                                }％`}
                              </Typography>
                            </td>
                          </tr>

                          {inputLoan.input_loan_chart!.input_loan_rates!.length > 1 && (
                            <>
                              <tr className="h-[45px] odd:bg-black-100">
                                <td className="w-[50%] px-[16px]">
                                  <Typography isBold>金利の変化</Typography>
                                </td>

                                {inputLoan.input_loan_chart!.rate_change_monthly ===
                                API_FLAG.off ? (
                                  <td className="w-[50%] px-[16px]">
                                    <Typography className="">ない</Typography>
                                  </td>
                                ) : (
                                  <>
                                    {Object.keys(
                                      selectAllInputLoanRates(
                                        inputLoan.input_loan_chart!.input_loan_rates!,
                                        'notNull',
                                        'monthly',
                                      ),
                                    ).map((_, loanRateIndex) => {
                                      const loanRates = selectAllInputLoanRates(
                                        inputLoan.input_loan_chart!.input_loan_rates!,
                                        'notNull',
                                        'monthly',
                                      )

                                      return (
                                        <td
                                          key={loanRateIndex}
                                          className="w-[50%] px-[16px] md:px-[30px]"
                                        >
                                          <Typography
                                            key={loanRateIndex}
                                            className="u-fs14"
                                          >
                                            {convertRateChangeValues(
                                              loanRates[loanRateIndex].rate_start_at,
                                              loanRates[loanRateIndex]
                                                ?.changed_rate_percent,
                                            )}
                                          </Typography>
                                        </td>
                                      )
                                    })}
                                  </>
                                )}
                              </tr>
                            </>
                          )}

                          <tr className="h-[45px] odd:bg-black-100">
                            <td className="w-[50%] px-[16px]">
                              <Typography className="">
                                データ登録月の
                                <br />
                                返済予定日
                              </Typography>
                            </td>
                            <td className="w-[50%] px-[16px]">
                              <Typography className="">
                                {convertToJapaneseFullDate(
                                  inputLoan.input_loan_chart!
                                    .repayment_date_this_month_at,
                                )}
                              </Typography>
                            </td>
                          </tr>

                          <tr className="h-[45px] odd:bg-black-100">
                            <td className="w-[50%] px-[16px]">
                              <Typography className="">
                                データ登録時の毎月支払い分の
                                <br />
                                残元金合計
                              </Typography>
                            </td>
                            <td className="w-[50%] px-[16px]">
                              <Typography className="">
                                {`${convertLocaleString(
                                  inputLoan.input_loan_chart!
                                    .remaining_principal_monthly_total,
                                )}円`}
                              </Typography>
                            </td>
                          </tr>

                          {isBonusFlgOn(
                            String(inputLoan.input_loan_chart!.bonus_flg),
                          ) && (
                            <tr className="h-[45px] odd:bg-black-100">
                              <td className="w-[50%] px-[16px]">
                                <Typography className="">
                                  データ登録時のボーナス支払い分の
                                  <br />
                                  残元金合計
                                </Typography>
                              </td>
                              <td className="w-[50%] px-[16px]">
                                <Typography className="">
                                  {`${convertLocaleString(
                                    inputLoan.input_loan_chart!
                                      .remaining_principal_bonus_total,
                                  )}円`}
                                </Typography>
                              </td>
                            </tr>
                          )}

                          {isFlatLoan(inputLoans![loanIndex].loan_lender) && (
                            <tr className="h-[45px] odd:bg-black-100">
                              <td className="w-[50%] px-[16px]">
                                <Typography className="">団体信用生命保険</Typography>
                              </td>
                              <td className="w-[50%] px-[16px]">
                                <Typography className="">
                                  {convertInsuranceType(
                                    inputLoan.input_loan_chart!.life_insurance,
                                  )}
                                </Typography>
                              </td>
                            </tr>
                          )}

                          {isPairLoan(inputLoan.loan_type) && (
                            <tr className="h-[45px] odd:bg-black-100">
                              <td className="w-[50%] px-[16px]">
                                <Typography className="">夫婦連生団信</Typography>
                              </td>
                              <td className="w-[50%] px-[16px]">
                                <Typography className="">
                                  {convertInsuranceType(
                                    inputLoan.input_loan_chart!.married_couple_group_shin,
                                  )}
                                </Typography>
                              </td>
                            </tr>
                          )}

                          <tr className="h-[45px] odd:bg-black-100">
                            <td className="w-[50%] px-[16px]">
                              <Typography className="">住宅ローン控除</Typography>
                            </td>
                            <td className="w-[50%] px-[16px]">
                              <Typography className="">
                                {convertDeductionType(
                                  inputLoan.input_loan_chart!.loan_deduction,
                                )}
                              </Typography>
                            </td>
                          </tr>
                        </>
                      )}

                    {!_isUndefined(inputLoan.input_loan_calculation) &&
                      !isWithChartLoan(inputLoan.repayment_chart) && (
                        <>
                          {isPairLoan(inputLoan.loan_type) && (
                            <tr className="h-[45px] odd:bg-black-100">
                              <td colSpan={2}>
                                <Typography className={clsx('')}>
                                  {convertPersonFlg(
                                    inputLoan.input_loan_calculation?.person_flg!,
                                  )}
                                </Typography>
                              </td>
                            </tr>
                          )}
                          {isFlatLoan(inputLoan.loan_lender) && (
                            <tr className="h-[45px] odd:bg-black-100">
                              <td colSpan={2}>
                                <Typography className={clsx('')}>
                                  {convertIsFlatApplied(inputLoan.is_flat_applied!)}
                                </Typography>
                              </td>
                            </tr>
                          )}
                          {(inputLoan.is_flat_applied ||
                            _isNull(inputLoan.is_flat_applied)) && (
                            <tr className="h-[45px] odd:bg-black-100">
                              <td className="w-[50%] px-[16px]">
                                <Typography className="">
                                  過去の繰り上げ返済有無
                                </Typography>
                              </td>
                              <td className="w-[50%] px-[16px]">
                                {!isWithAdvanceRepayment(
                                  inputLoan?.input_loan_calculation?.advance_repayment,
                                ) ? (
                                  <Typography className="">ない</Typography>
                                ) : (
                                  <>
                                    {Object.keys(
                                      inputLoan.input_loan_calculation!.input_advances!,
                                    ).map((_, advanceIndex) => {
                                      const { input_advances } = inputLoans![
                                        loanIndex
                                      ].input_loan_calculation!

                                      return (
                                        <tr
                                          key={advanceIndex}
                                          className="h-[45px] odd:bg-black-100"
                                        >
                                          <td colSpan={2}>
                                            <Typography className="">
                                              {convertAdvanceType(
                                                input_advances![advanceIndex]
                                                  .repayment_type,
                                              )}
                                            </Typography>
                                            <Typography className="">
                                              {convertAdvanceValues(
                                                input_advances![advanceIndex]
                                                  .advance_repayment_month_at,
                                                input_advances![advanceIndex]
                                                  .repayment_amount,
                                              )}
                                            </Typography>
                                          </td>
                                        </tr>
                                      )
                                    })}
                                  </>
                                )}
                              </td>
                            </tr>
                          )}

                          <tr className="h-[45px] odd:bg-black-100">
                            <td className="w-[50%] px-[16px]">
                              <Typography className="">当初の借入金額</Typography>
                            </td>
                            <td className="w-[50%] px-[16px]">
                              <Typography className="">
                                {`${convertLocaleString(
                                  inputLoan?.input_loan_calculation
                                    ?.started_borrowing_amount_manyen,
                                )}万円`}
                              </Typography>
                            </td>
                          </tr>

                          <tr className="h-[45px] odd:bg-black-100">
                            <td className="w-[50%] px-[16px]">
                              <Typography className="">設定している返済期間</Typography>
                            </td>
                            <td className="w-[50%] px-[16px]">
                              <Typography className="">
                                {`${convertLocaleString(
                                  inputLoan?.input_loan_calculation?.repayment_period,
                                )}年間`}
                              </Typography>
                            </td>
                          </tr>

                          {isFlatLoan(inputLoans![loanIndex].loan_lender) ? (
                            <>
                              {isNotEmptyValue(
                                inputLoans?.[loanIndex].input_loan_calculation
                                  ?.input_calculation_rates?.[0]?.changed_rate_percent,
                              ) && (
                                <tr className="h-[45px] odd:bg-black-100">
                                  <td className="w-[50%] px-[16px]">
                                    <Typography className="">
                                      {inputLoans?.[loanIndex]?.is_flat_applied
                                        ? '金利'
                                        : '当初金利'}
                                    </Typography>
                                  </td>
                                  <td className="w-[50%] px-[16px]">
                                    <Typography className="">
                                      {`${convertLocaleString(
                                        inputLoan.input_loan_calculation
                                          ?.input_calculation_rates![0]
                                          ?.changed_rate_percent,
                                      )}％`}
                                    </Typography>
                                  </td>
                                </tr>
                              )}

                              {!inputLoan.is_flat_applied &&
                                selectAllInputCalculationRatesByRateStartAt(
                                  inputLoan?.input_loan_calculation
                                    ?.input_calculation_rates!
                                    ? [
                                        ...inputLoan?.input_loan_calculation
                                          ?.input_calculation_rates!,
                                      ]
                                    : [],
                                  'notNull',
                                ).length > 0 && (
                                  <tr className="h-[45px] odd:bg-black-100">
                                    <td className="w-[50%] px-[16px]">
                                      <Typography className="">当初以降の金利</Typography>
                                    </td>
                                    <td className="w-[50%] px-[16px]">
                                      {Object.keys(
                                        selectAllInputCalculationRatesByRateStartAt(
                                          [
                                            ...inputLoan?.input_loan_calculation
                                              ?.input_calculation_rates!,
                                          ],
                                          'notNull',
                                        ),
                                      ).map((_, calcRateIndex) => {
                                        const { input_calculation_rates } = inputLoans?.[
                                          loanIndex
                                        ]?.input_loan_calculation!
                                        const calculationRates = selectAllInputCalculationRatesByRateStartAt(
                                          [...input_calculation_rates!],
                                          'notNull',
                                        )

                                        return (
                                          <Typography key={calcRateIndex} className="">
                                            {convertRateChangeValues(
                                              calculationRates?.[calcRateIndex]
                                                ?.rate_start_at,
                                              calculationRates?.[calcRateIndex]
                                                ?.changed_rate_percent,
                                            )}
                                          </Typography>
                                        )
                                      })}
                                    </td>
                                  </tr>
                                )}
                            </>
                          ) : (
                            <>
                              <tr className="h-[45px] odd:bg-black-100">
                                <td className="w-[50%] px-[16px]">
                                  <Typography className="">現在の借入金利</Typography>
                                </td>
                                <td className="w-[50%] px-[16px]">
                                  <Typography className="">
                                    {`${
                                      selectAllInputCalculationRatesByRateStartAt(
                                        inputLoan.input_loan_calculation
                                          ?.input_calculation_rates!,
                                        'null',
                                      )?.[0]?.changed_rate_percent
                                    }％`}
                                  </Typography>
                                </td>
                              </tr>

                              {selectAllInputCalculationRatesByRateStartAt(
                                inputLoan?.input_loan_calculation
                                  ?.input_calculation_rates!,
                                'notNull',
                              ).length > 0 && (
                                <tr className="h-[45px] odd:bg-black-100">
                                  <td className="w-[50%] px-[16px]">
                                    <Typography className="">借入金利の変化</Typography>
                                  </td>
                                  <td className="w-[50%] px-[16px]">
                                    {Object.keys(
                                      selectAllInputCalculationRatesByRateStartAt(
                                        inputLoan.input_loan_calculation
                                          ?.input_calculation_rates!,
                                        'notNull',
                                      ),
                                    ).map((_, calcRateIndex) => {
                                      const calcRates = selectAllInputCalculationRatesByRateStartAt(
                                        inputLoan?.input_loan_calculation
                                          ?.input_calculation_rates!,
                                        'notNull',
                                      )

                                      return (
                                        <Typography key={calcRateIndex} className="">
                                          {convertRateChangeValues(
                                            calcRates?.[calcRateIndex]?.rate_start_at,
                                            calcRates?.[calcRateIndex]
                                              ?.changed_rate_percent,
                                          )}
                                        </Typography>
                                      )
                                    })}
                                  </td>
                                </tr>
                              )}
                            </>
                          )}

                          {isFlatLoan(inputLoans?.[loanIndex]?.loan_lender) && (
                            <tr className="h-[45px] odd:bg-black-100">
                              <td className="w-[50%] px-[16px]">
                                <Typography className="">団体信用生命保険</Typography>
                              </td>
                              <td className="w-[50%] px-[16px]">
                                <Typography className="">
                                  {convertInsuranceType(
                                    String(
                                      inputLoan?.input_loan_calculation?.life_insurance,
                                    ),
                                  )}
                                </Typography>
                              </td>
                            </tr>
                          )}

                          {isPairLoan(inputLoan?.loan_type) && (
                            <tr className="h-[45px] odd:bg-black-100">
                              <td className="w-[50%] px-[16px]">
                                <Typography className="">夫婦連生団信</Typography>
                              </td>
                              <td className="w-[50%] px-[16px]">
                                <Typography className="">
                                  {convertInsuranceType(
                                    String(
                                      inputLoan?.input_loan_calculation
                                        ?.married_couple_group_shin,
                                    ),
                                  )}
                                </Typography>
                              </td>
                            </tr>
                          )}

                          <tr className="h-[45px] odd:bg-black-100">
                            <td className="w-[50%] px-[16px]">
                              <Typography className="">住宅ローン控除</Typography>
                            </td>
                            <td className="w-[50%] px-[16px]">
                              <Typography className="">
                                {convertDeductionType(
                                  String(
                                    inputLoan?.input_loan_calculation?.loan_deduction,
                                  ),
                                )}
                              </Typography>
                            </td>
                          </tr>
                        </>
                      )}
                  </React.Fragment>
                )
              })}
            </>
          )}
        </tbody>
      </table>
    </>
  )
}

export default InputHousingLoanBody
