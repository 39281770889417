import ApiClient from '../apiClient'
import {
  InputAssetsSummaryListResponse,
  InputAssetCreateValues,
  InputAssetUpdateValues,
} from '../../models/inputs/inputAssetsModel'

export const getInputAssetsSummaryList = async () => {
  return await ApiClient.get(
    '/input_assets',
    (response: InputAssetsSummaryListResponse) => response,
  )
}

export const postInputAsset = async (values: InputAssetCreateValues) => {
  return await ApiClient.post('/input_assets', () => {}, values)
}

export const patchInputAsset = async (id: number, values: InputAssetUpdateValues) => {
  return await ApiClient.patch(`/input_assets/${id}`, () => {}, values)
}

export const deleteInputAsset = async (id: number) => {
  return await ApiClient.delete(`/input_assets/${id}`, () => {})
}
