import React, { FC } from 'react'
import { useForm } from 'react-final-form'
import { INPUT_TRANSFER_TYPE_BUTTON_ITEMS } from '@/constants/formItem/inputs/inputHousings'
import { required } from '@/utils/validate'

import Typography from '@/components/v2/atoms/Typography'
import SelectBox from '@/components/v2/molecules/SelectBox'

const TransferType: FC = () => {
  const { change } = useForm()

  const handleChangeTransferType = (value: number) => {
    change('input_housing.transfer_type', value)
  }

  return (
    <>
      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
        <Typography
          className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
          isBold
        >
          単身赴任の有無
        </Typography>
        <SelectBox
          name="input_housing.input_housing_transfer_attributes.transfer_type"
          placeholder="選択する"
          isFullWidth
          options={INPUT_TRANSFER_TYPE_BUTTON_ITEMS}
          validate={required}
          onChange={(e: any) => handleChangeTransferType(e.target.value)}
        />
      </div>
    </>
  )
}

export default TransferType
