import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { sortBy as _sortBy } from 'lodash'
import {
  deleteInputAssetInvestment,
  getInputAssetInvestments,
  patchInputAssetInvestment,
  postInputAssetInvestment,
} from '@/api/inputs/inputAssetInvestments'
import { unauthorizedCheck } from '@/containers/accountsSlice'
import { ApiError } from '@/models/commonsModel'
import {
  InputAssetInvestment,
  InputAssetInvestmentCreateValues,
  InputAssetInvestmentUpdateValues,
} from '@/models/inputs/inputAssetInvestmentsModel'
import { AppThunk } from '@/store'

interface InputAssetInvestmentState {
  inputAssetInvestments: InputAssetInvestment[]
  isLoading: boolean
}

const initialState: InputAssetInvestmentState = {
  inputAssetInvestments: [],
  isLoading: false,
}

const inputAssetInvestmentsSlice = createSlice({
  name: 'inputAssetInvestments',
  initialState,
  reducers: {
    setInputAssetInvestments: (state, action: PayloadAction<InputAssetInvestment[]>) => {
      const inputAssetInvestments = _sortBy(action.payload, 'id')
      state.inputAssetInvestments = inputAssetInvestments.map((asset) => ({
        ...asset,
        asset_annuals: _sortBy(asset.asset_annuals, 'affected_at'),
      }))
      return state
    },
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    getInputAssetInvestmentsSuccess: (
      state,
      action: PayloadAction<InputAssetInvestment[]>,
    ) => {
      const inputAssetInvestments = _sortBy(action.payload, 'id')
      state.inputAssetInvestments = inputAssetInvestments.map((asset) => ({
        ...asset,
        asset_annuals: _sortBy(asset.asset_annuals, 'affected_at'),
      }))
      state.isLoading = false

      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
  },
})

export const {
  setInputAssetInvestments,
  requestStart,
  requestSuccess,
  getInputAssetInvestmentsSuccess,
  requestFailure,
} = inputAssetInvestmentsSlice.actions
export const inputAssetInvestmentsReducer = inputAssetInvestmentsSlice.reducer

export const fetchInputAssetInvestments = (): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  getInputAssetInvestments()
    .then((response) => dispatch(getInputAssetInvestmentsSuccess(response)))
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const createInputAssetInvestment = (
  values: InputAssetInvestmentCreateValues,
  callback: () => void,
  resolve?: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postInputAssetInvestment(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error, resolve))
    })
}

export const updateInputAssetInvestment = (
  values: InputAssetInvestmentUpdateValues,
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchInputAssetInvestment(values.id, values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const destroyInputAssetInvestment = (
  values: number,
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  deleteInputAssetInvestment(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}
