import React, { FC, useEffect, useRef } from 'react'
import { Magnifier } from 'react-image-magnifiers'
import CrossWhiteIcon from '@/assets/images/v2/cross-white.svg'
import Button from '@/components/v2/atoms/Button'
import { ListenerEvent } from '@/models/commonsModel'

interface ImageModalProps {
  /** 画像パス */
  src: string
  /** failback */
  alt?: string
  /** true: モーダル外クリック無効, false: モーダル外クリック有効 */
  isDisableBackdropClick?: boolean
  /** true: オープン, false: クローズ */
  isOpen: boolean
  /** クローズメソッド */
  onClose: () => void
}

const ImageMagnifiableModal: FC<ImageModalProps> = (props) => {
  const { src, alt, isDisableBackdropClick, isOpen, onClose } = props

  const modalRef = useRef<HTMLDivElement>(null)

  const handleClickAway = (event: ListenerEvent<HTMLDivElement>) => {
    if (modalRef.current?.contains(event.target) || isDisableBackdropClick) return
    onClose()
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickAway)

    return () => {
      document.removeEventListener('mousedown', handleClickAway)
    }
  }, [])

  return (
    <>
      {isOpen && (
        <>
          <div className="fixed inset-[0] z-[2000]">
            <div className="fixed inset-[0] bg-black-900/50" />
            <div className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] max-h-[calc(100vh-90px)] w-90% overflow-y-scroll">
              <div className="relative flex justify-end">
                <Button className="" onClick={onClose}>
                  <CrossWhiteIcon />
                </Button>
              </div>
              <div
                ref={modalRef}
                className="relative rounded-[16px] bg-white shadow-modal"
              >
                <div className="relative after:block after:w-[1px] after:h-[40px] h-full pt-[10px] px-[1px]">
                  <Magnifier imageSrc={src} imageAlt={alt} />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default ImageMagnifiableModal
