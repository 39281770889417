import React, { FC, useMemo, useState } from 'react'
import clsx from 'clsx'
import ChevronRightIcon from '@/assets/images/v2/chevron-right.svg'
import TransactionIcon from '@/assets/images/v2/transaction.svg'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import Paper from '@/components/v2/atoms/Paper'
import Typography from '@/components/v2/atoms/Typography'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import { postAccountPayment } from '@/api/accountPayments'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import Modal from '@/components/v2/atoms/Modal'
import CreditCardRegistrationForm from '@/templates/v2/settings/commons/CreditCardRegistrationForm'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import moment from 'moment'
interface PaperContractDeadlineProps {
  /** クラス名 */
  className?: string
}

const PaperContractDeadline: FC<PaperContractDeadlineProps> = (props) => {
  const { className } = props

  const { showFlashMsg } = useFlashAlert()
  const { handleMovePage } = useCustomHistory()

  const [isPaymentRegistrationModalOpen, setIsPaymentRegistrationModalOpen] = useState<
    boolean
  >(false)
  const [isLoadingCreditCard, setIsLoadingCreditCard] = useState<boolean>(false)
  const { account, accountPayment } = useSelector((state: RootState) => state.accounts)

  const _handleSubmitCreditCardForm = (
    values: any,
    callback: (values: any, token: string) => void,
  ) => {
    setIsLoadingCreditCard(true)

    const w: any = window as any
    w.CPToken.TokenCreate(
      {
        aid: '120009',
        cn: values.cn,
        ed: `${values.ed_year}${values.ed_month}`,
        fn: values.fn,
        ln: values.ln,
        cvv: values.cvv,
      },
      (resultCode: any, errMsg: any) => {
        if (resultCode != 'Success') {
          window.alert(`カード情報の登録に失敗しました。 ${errMsg}`)
          setIsLoadingCreditCard(false)
          return
        }
        const tokenEle: any = document?.getElementById('tkn')
        const token: string = tokenEle.value
        callback(values, token)
      },
    )
  }

  const handleSubmitCreditCardRegistrationForm = (values: any) => {
    _handleSubmitCreditCardForm(values, (values, token) => {
      postAccountPayment({
        account_payment_information: {
          robot_payment_token: token,
          last_four_digits: values.cn.substr(-4),
          expire_month: values.ed_month,
          expire_year: values.ed_year,
        },
      })
        .then(() => {
          handleMovePage('/v2/settings/account')
          showFlashMsg('有料プランへの登録を完了しました')
          setIsLoadingCreditCard(false)
        })
        .catch(() => {
          setIsPaymentRegistrationModalOpen(false)
          showFlashMsg('有料プランへの登録に失敗しました')
          setIsLoadingCreditCard(false)
        })
    })
  }

  const daysTillTrialFinished = useMemo(() => {
    return moment(account.trial_end_at).diff(moment(), 'days') + 1
  }, [account])

  const isOverDeadline = daysTillTrialFinished <= 0

  return (
    <>
      {isOverDeadline ? null : (
        <>
          <Paper className={clsx(className, 'p-[16px] md:p-[24px] bg-cream-700')}>
            <CircleTitle
              className="md:hidden pb-[10px]"
              title="無料お試し期間の終了が近づいています"
            />
            <div className="flex items-center">
              <div className="flex-1 min-w-[200px]">
                <CircleTitle
                  className="hidden md:flex pb-[16px]"
                  title="無料お試し期間の終了が近づいています"
                />
                <Typography className="pb-[10px] md:pb-[24px] text-[14px]">
                  {`無料お試し期間はあと${daysTillTrialFinished}日です。期間を過ぎてしまうと、`}
                  <br />
                  入力したデータにアクセス出来なくなりますのでお早めに更新手続きを行なってください。
                </Typography>
                <ButtonPrimary
                  className="w-[188px] h-[45px] px-[17px]"
                  onClick={() => setIsPaymentRegistrationModalOpen(true)}
                >
                  <Typography
                    className="relative bottom-[2px] flex-1 text-[14px] text-white"
                    isBold
                  >
                    引き続き利用する
                  </Typography>
                  <ChevronRightIcon />
                </ButtonPrimary>
              </div>
              <TransactionIcon viewBox="0 0 213 161" />
            </div>
          </Paper>

          <Modal
            className="w-[95%] md:w-[760px]"
            isOpen={isPaymentRegistrationModalOpen}
            onClose={() => setIsPaymentRegistrationModalOpen(false)}
          >
            <CreditCardRegistrationForm
              title="引き続きマネソルを利用する"
              accountPayment={accountPayment}
              isLoading={isLoadingCreditCard}
              handleSubmit={handleSubmitCreditCardRegistrationForm}
            />
          </Modal>
        </>
      )}
    </>
  )
}

export default PaperContractDeadline
