import ApiClient from '@/api/apiClient'
import {
  InputSalary,
  CalcSocialInsuranceFeeResponse,
  InputSalaryCreateValues,
  InputSalaryUpdateValues,
  InputSalaryDestroyValues,
  YearlyIncomeTotal,
} from '@/models/inputs/inputSalariesModel'

export const getSalaries = async () => {
  return await ApiClient.get(
    '/input_salaries',
    (response: InputSalary[]) => response,
    undefined,
    false,
    'v2',
  )
}

export const getYearlyIncomeTotal = async () => {
  return await ApiClient.get(
    '/input_salaries/yearly_income_total',
    (response: YearlyIncomeTotal) => response,
    undefined,
    false,
    'v2',
  )
}

export const postCalcSocialInsuranceFee = async (values: InputSalaryCreateValues) => {
  return await ApiClient.post(
    '/input_salaries/calc_social_insurance_fees',
    (response: CalcSocialInsuranceFeeResponse) => response,
    values,
  )
}

export const postInputSalary = async (values: InputSalaryCreateValues) => {
  return await ApiClient.post('/input_salaries', () => {}, values)
}

export const patchInputSalary = async (values: InputSalaryUpdateValues) => {
  return await ApiClient.patch('/input_salaries', () => {}, values)
}

export const deleteInputSalary = async (values: { params: InputSalaryDestroyValues }) => {
  return await ApiClient.delete('/input_salaries', () => {}, values)
}
