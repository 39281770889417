import { reduce as _reduce } from 'lodash'
import { DeactivationFormResponseCreateValues } from './deactivationFormResponsesModel'

export const replaceDeactivationFormResponseValues = (
  values: DeactivationFormResponseCreateValues,
) => {
  const {
    reason,
    good_point,
    bad_point,
    agreement_conditions,
    agreement_conditions_arr,
  } = values.deactivation_form_response

  const agreementConditions = _reduce(
    agreement_conditions_arr,
    (result: any, value) => {
      result[value] = true
      return result
    },
    { ...agreement_conditions! },
  )

  const newValues = {
    deactivation_form_response: {
      reason: reason!,
      good_point: good_point!,
      bad_point: bad_point!,
      agreement_conditions: agreementConditions,
    },
  }

  return newValues
}
