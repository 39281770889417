import React, { FC, useContext } from 'react'
import FamilyMonochromaticIcon from '@/assets/images/v2/family-monochromatic.svg'
import MarginContainer from '@/components/v2/atoms/MarginContainer'
import Paper from '@/components/v2/atoms/Paper'
import Typography from '@/components/v2/atoms/Typography'
import Breadcrumb from '@/components/v2/molecules/Breadcrumb'
import PaperTitle from '@/components/v2/molecules/PaperTitle'
import { useParams } from 'react-router'
import InsuranceForm from '@/templates/v2/myData/insurances/_Form'
import { AccountContext } from '@/models/accountsModelFunc'

const MyDataInsuranceEdit: FC = () => {
  const { isAdmin } = useContext(AccountContext)
  const { account_id } = useParams<{ account_id?: string }>()

  return (
    <MarginContainer className="px-[8px] md:px-[32px] lg:px-0 py-[8px] md:py-[40px]">
      <Breadcrumb
        className="pb-[10px] md:pb-[32px]"
        data={[
          {
            name: 'マイデータ',
            url: isAdmin ? `/v2/accounts/${account_id}/myData` : '/v2/myData',
          },
          {
            name: 'マイデータ設定',
            url: isAdmin
              ? `/v2/accounts/${account_id}/myData/insurances`
              : '/v2/myData/insurances',
          },
          { name: '保険の設定' },
        ]}
      />

      <PaperTitle
        className="mb-[16px] md:mb-[40px]"
        title="保険"
        description="日々の収入や支出を登録することで改善案などのご提案が可能になります"
        icon={<FamilyMonochromaticIcon />}
        smallIcon={
          <FamilyMonochromaticIcon className="h-[82px] w-[130px]" viewBox="0 0 117 154" />
        }
      />

      <Paper className="bg-white p-[24px] md:p-[40px]">
        <Typography
          className="mb-[32px] pb-[10px] text-[24px] md:text-[28px] text-black-800 border-b-2 border-black-300"
          isBold
        >
          保険について
        </Typography>

        <InsuranceForm />
      </Paper>
    </MarginContainer>
  )
}

export default MyDataInsuranceEdit
