import { API_FLAG } from '@/models/commonsModelFunc'

// 住宅ローン有無
export const LOAN_TYPE1_BUTTON_ITEMS = [
  { value: API_FLAG.on, label: '借りる' },
  { value: API_FLAG.off, label: '借りない' },
]

// 住宅ローン分割有無
export const LOAN_TYPE2_BUTTON_ITEMS = [
  { value: API_FLAG.on, label: '分けて借りる' },
  { value: API_FLAG.off, label: '分けて借りない' },
]

// 子育て世帯等に対する控除の有無
export const CHILD_REARING_DEDUCTION_SELECT_ITEMS = [
  { value: API_FLAG.on, label: '対象' },
  { value: API_FLAG.off, label: '非対象' },
]
