import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import LogindRedirect from '@/layouts/v2/LogindRedirect'
import MarginContainer from '@/components/v2/atoms/MarginContainer'
import Paper from '@/components/v2/atoms/Paper'
import Heading from '@/components/v2/atoms/Heading'
import Typography from '@/components/v2/atoms/Typography'
import { setPageMeta } from '@/containers/pageMetasSlice'

const AccountTempCompleted: FC = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'ユーザー仮登録完了',
        description: 'ユーザー仮登録完了',
        keyword: 'ユーザー仮登録完了',
      }),
    )
  }, [])

  return (
    <LogindRedirect>
      <MarginContainer className="w-full sm:w-524 py-40">
        <Paper className="mx-8 sm:mx-0 px-16 py-24 sm:p-40 space-y-24 bg-white">
          <Heading className="text-center">ユーザー仮登録完了</Heading>
          <Typography className="mt-24 sm:text-center text-14 text-black-700">
            ご登録のアドレスにメールを送信しました。
            <br />
            本文に記載されたURLから本パスワードを設定してください。
            <br />
            セキュリティの関係から、仮登録可能期間は
            <span className="text-black-900 font-extrabold">３日間</span>
            となっています。
            <br />
            できるだけ早く本パスワードの設定をお願いいたします。
          </Typography>
        </Paper>
      </MarginContainer>
    </LogindRedirect>
  )
}

export default AccountTempCompleted
