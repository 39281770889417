import { times as _times } from 'lodash'
import {
  API_FLAG,
  combineTwoTypesYear,
  THIS_YEAR_NUMBER,
} from '@/models/commonsModelFunc'
import {
  HOUSE_TYPE,
  OWNED_HOUSE_TYPE,
  HOUSING_TYPE2,
  REPAIR_RESERVE_FUND_RATE_TYPE,
  LOAN_TYPE,
} from '@/models/commonHousingModelFunc'
import { REPAYMENT_TYPE } from '@/models/inputs/inputHousingsModelFunc'

// 家タイプ
export const INPUT_HOUSE_TYPE_BUTTON_ITEMS = [
  { value: HOUSE_TYPE.rent, label: '賃貸' },
  { value: HOUSE_TYPE.notSelf, label: '社宅・寮' },
  { value: HOUSE_TYPE.own, label: '自己保有' },
]

// 家タイプ
export const INPUT_OWNED_HOUSE_TYPE_BUTTON_ITEMS = [
  { value: OWNED_HOUSE_TYPE.mansion, label: 'マンション' },
  { value: OWNED_HOUSE_TYPE.house, label: '戸建て' },
]

// 家状態(マンション)
export const INPUT_HOUSING_TYPE2_MANSION_BUTTON_ITEMS = [
  { value: HOUSING_TYPE2.lowCarbon, label: '新築マンションまたは買取再販（長期優良基準）' },
  { value: HOUSING_TYPE2.zehNewly, label: '新築マンションまたは買取再販（ZEH基準）' },
  { value: HOUSING_TYPE2.newly, label: '新築マンションまたは買取再販（通常）' },
  { value: HOUSING_TYPE2.secondhand, label: '中古（通常）' },
  { value: HOUSING_TYPE2.energySavingSecondhand, label: '中古（省エネ以上）' },
]

// 住宅ローン有無
export const INPUT_LOAN_TYPE1_BUTTON_ITEMS = [
  { value: API_FLAG.on, label: '借りている' },
  { value: API_FLAG.off, label: '借りていない' },
]

// 住宅ローン分割有無
export const INPUT_LOAN_TYPE2_BUTTON_ITEMS = [
  { value: API_FLAG.on, label: '分けて借りた' },
  { value: API_FLAG.off, label: '分けて借りていない' },
]

// ローン方式
export const INPUT_LOAN_TYPE_BUTTON_ITEMS = [
  { value: LOAN_TYPE.personOnly, label: 'ご本人単独' },
  { value: LOAN_TYPE.spouseOnly, label: '配偶者単独' },
  { value: LOAN_TYPE.pairLoan, label: 'ペアローン・連帯債務' },
  { value: LOAN_TYPE.combined, label: '収入合算' },
]

// ローン方式(配偶者なし)
export const INPUT_LOAN_TYPE_WITHOUT_SPOUSE_BUTTON_ITEMS = [
  { value: LOAN_TYPE.personOnly, label: 'ご本人単独' },
  { value: LOAN_TYPE.spouseOnly, label: '配偶者単独', disabled: true },
  { value: LOAN_TYPE.pairLoan, label: 'ペアローン', disabled: true },
  { value: LOAN_TYPE.combined, label: '収入合算', disabled: true },
]

// ローン方式(分割)
export const INPUT_SPLIT_LOAN_TYPE_BUTTON_ITEMS = [
  { value: LOAN_TYPE.personOnly, label: 'ご本人単独' },
  { value: LOAN_TYPE.spouseOnly, label: '配偶者単独' },
  { value: LOAN_TYPE.pairLoan, label: 'ペアローン' },
  { value: LOAN_TYPE.combined, label: '収入合算' },
]

// 返済計画表有無
export const INPUT_REPAYMENT_CHART_BUTTON_ITEMS = [
  { value: API_FLAG.on, label: 'ある' },
  { value: API_FLAG.off, label: 'ない' },
]

// 単身赴任タイプ
export const INPUT_TRANSFER_TYPE_BUTTON_ITEMS = [
  { value: API_FLAG.on, label: 'している' },
  { value: API_FLAG.off, label: 'していない' },
]

export const INPUT_REPAIR_RESERVE_FUND_RATE_TYPE_SELECT_ITEMS = [
  { value: REPAIR_RESERVE_FUND_RATE_TYPE.change, label: '上昇率を設定' },
  { value: REPAIR_RESERVE_FUND_RATE_TYPE.noSet, label: '上昇率を設定しない' },
]

// 繰り上げ返済方法
export const INPUT_REPAYMENT_TYPE_SELECT_ITEMS = [
  { value: REPAYMENT_TYPE.shortening, label: '期間短縮型' },
  { value: REPAYMENT_TYPE.reduction, label: '返済額軽減型' },
]

// 礼金の頻度
export const INPUT_REPAIR_COSTS_SPAN_SELECT_ITEMS = _times(25, (index) => ({
  value: index + 1,
  label: String(index + 1),
}))

// ボーナス払い設定の有無
export const INPUT_BONUS_FLG_RADIO_ITEMS = [
  { value: String(API_FLAG.on), label: '設定している' },
  { value: String(API_FLAG.off), label: '設定していない' },
]

// ボーナス払い金利変化の有無
export const INPUT_RATE_CHANGE_RADIO_ITEMS = [
  { value: String(API_FLAG.on), label: 'ある' },
  { value: String(API_FLAG.off), label: 'ない' },
]

// 過去繰り上げ返済有無
export const INPUT_ADVANCE_RADIO_ITEMS = [
  { value: String(API_FLAG.off), label: '行ったことがない' },
  { value: String(API_FLAG.on), label: '行ったことがある' },
]

// 団体信用生命保険の加入有無
export const INPUT_HOUSING_LIFE_INSURANCE_RADIO_ITEMS = [
  { value: String(API_FLAG.on), label: '加入している' },
  { value: String(API_FLAG.off), label: '加入していない' },
]

// 夫婦連生団信の加入有無
export const INPUT_MARRIED_COUPLE_GROUP_SHIN_RADIO_ITEMS = [
  { value: String(API_FLAG.on), label: '加入している' },
  { value: String(API_FLAG.off), label: '加入していない' },
]

// 住宅ローン控除
export const INPUT_LOAN_DEDUCTION_RADIO_ITEMS = [
  { value: String(API_FLAG.on), label: '対象' },
  { value: String(API_FLAG.off), label: '非対象' },
]

// 返済予定日
export const INPUT_REPAYMENT_DATE_SELECT_ITEMS = _times(6, (index) => ({
  value: String(index + (THIS_YEAR_NUMBER - 5)),
  label: combineTwoTypesYear(index + (THIS_YEAR_NUMBER - 5)),
}))

// 引渡年月等(+3年)
const birthYear = THIS_YEAR_NUMBER - 100
export const INPUT_HOUSING_YEAR_SELECT_ITEMS = _times(104, (index) => ({
  value: String(index + birthYear),
  label: combineTwoTypesYear(index + birthYear),
}))

// 子育て世帯等に対する控除の有無
export const INPUT_CHILD_REARING_DEDUCTION_SELECT_ITEMS = [
  { value: String(API_FLAG.on), label: '対象' },
  { value: String(API_FLAG.off), label: '非対象' },
]
