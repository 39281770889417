import React, { FC } from 'react'
import { Switch, Route } from 'react-router-dom'
import SimulationSets from '@/templates/v2/simulations/sets'
import SimulationNew from '@/templates/v2/simulations/New'
import SimulationEdit from '@/templates/v2/simulations/Edit'
import SimulationIndex from '@/templates/v2/simulations'

const Simulation: FC = () => {
  return (
    <Switch>
      <Route exact path="/v2/simulations" component={SimulationIndex} />
      <Route exact path="/v2/simulations/sets" component={SimulationSets} />
      <Route exact path="/v2/simulations/new" component={SimulationNew} />
      <Route exact path="/v2/simulations/:future_base_id" component={SimulationEdit} />
    </Switch>
  )
}

export default Simulation
