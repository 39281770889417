import React, { FC } from 'react'
import { INPUT_REPAIR_COSTS_SPAN_SELECT_ITEMS } from '@/constants/formItem/inputs/inputHousings'
import { getTargetSelfOwnedAttributesName } from '@/models/commonHousingModelFunc'
import {
  commaNumberMaxLength,
  composeValidators,
  required,
  zeroOrMoreNumber,
} from '@/utils/validate'

import Typography from '@/components/v2/atoms/Typography'
import SelectBox from '@/components/v2/molecules/SelectBox'
import NumberField from '@/components/v2/molecules/NumberField'

interface RepairCostProps {
  isFuture?: boolean
}

const RepairCost: FC<RepairCostProps> = (props) => {
  const { isFuture } = props
  const targetHousingSelfOwnedAttributesName = getTargetSelfOwnedAttributesName(isFuture)

  return (
    <>
      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
        <Typography
          className="min-w-[140px] lg:min-w-[210px] pb-[10px] text-[14px] text-black-800"
          isBold
        >
          １回あたりのリフォーム費
        </Typography>
        <div className="flex flex-auto items-baseline">
          <NumberField
            name={`${targetHousingSelfOwnedAttributesName}.repair_costs`}
            isFullWidth
            validate={composeValidators(required, zeroOrMoreNumber, (value: any) =>
              commaNumberMaxLength(value, 10),
            )}
          />
          <Typography className="pl-[8px] text-[14px] text-black-800" isBold>
            円
          </Typography>
        </div>
      </div>

      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
        <Typography
          className="min-w-[140px] lg:min-w-[210px] pb-[10px] text-[14px] text-black-800"
          isBold
        >
          リフォームの頻度
        </Typography>
        <div className="flex flex-auto items-baseline">
          <SelectBox
            isFullWidth
            name={`${targetHousingSelfOwnedAttributesName}.repair_costs_span`}
            placeholder="選択する"
            options={INPUT_REPAIR_COSTS_SPAN_SELECT_ITEMS}
            validate={required}
          />
          <Typography className="pl-[8px] text-[14px] text-black-800 min-w-[82px]" isBold>
            年に１回
          </Typography>
        </div>
      </div>
    </>
  )
}

export default RepairCost
