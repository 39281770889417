import React, { FC, useEffect, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { isUndefined as _isUndefined, find as _find, filter as _filter } from 'lodash'
import FamilyMonochromaticIcon from '@/assets/images/v2/family-monochromatic.svg'
import MarginContainer from '@/components/v2/atoms/MarginContainer'
import Paper from '@/components/v2/atoms/Paper'
import PaperTitle from '@/components/v2/molecules/PaperTitle'
import Typography from '@/components/v2/atoms/Typography'
import Breadcrumb from '@/components/v2/molecules/Breadcrumb'
import { setPageMeta } from '@/containers/pageMetasSlice'
import { convertPersonFlg, getUrlPersonFlg } from '@/models/familyStructuresModelFunc'
import { AccountContext } from '@/models/accountsModelFunc'
import { useParams } from 'react-router-dom'
import MyDataCompanyContributionPensionEditForm from './Form'
import { useCustomHistory } from '@/hooks/useCustomHistory'

const MyDataCompanyContributionPensionEdit: FC = () => {
  const dispatch = useDispatch()
  const { isAdmin } = useContext(AccountContext)
  const { account_id } = useParams<{ account_id?: string }>()
  const { handleMovePage } = useCustomHistory()

  const personFlg = getUrlPersonFlg()

  const movePageAfterForm = () => {
    handleMovePage(
      isAdmin ? `/v2/accounts/${account_id}/myData/salaries` : '/v2/myData/salaries',
    )
  }

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'マイデータ | 企業型確定拠出年金 編集',
        description: 'マイデータ | 企業型確定拠出年金 編集',
        keyword: 'マイデータ | 企業型確定拠出年金 編集',
      }),
    )
  }, [])

  return (
    <MarginContainer className="px-[8px] md:px-[32px] lg:px-0 py-[8px] md:py-[40px]">
      <Breadcrumb
        className="pb-[10px] md:pb-[32px]"
        linkClassName="text-[11px] md:text-[12px]"
        data={[
          {
            name: 'マイデータ',
            url: isAdmin ? `/v2/accounts/${account_id}/myData` : '/v2/myData',
          },
          {
            name: 'マイデータ設定',
            url: isAdmin
              ? `/v2/accounts/${account_id}/myData/salaries`
              : '/v2/myData/salaries',
          },
          { name: '企業型確定拠出年金の設定' },
        ]}
      />
      <PaperTitle
        className="mb-[16px] md:mb-[40px]"
        titleClassName="text-[22px] md:text-[32px]"
        title="企業型確定拠出年金"
        description="日々の収入や支出を登録することで改善案などのご提案が可能になります"
        icon={<FamilyMonochromaticIcon />}
        smallIcon={
          <FamilyMonochromaticIcon className="h-[82px] w-[130px]" viewBox="0 0 117 154" />
        }
      />

      <Paper className="bg-white p-[24px] md:p-[40px]">
        <Typography
          className="mb-[32px] pb-[10px] text-[18px] md:text-[28px] text-black-800 border-b-2 border-black-300"
          isBold
        >
          {`${convertPersonFlg(personFlg)}の企業型確定拠出年金について`}
        </Typography>
        <MyDataCompanyContributionPensionEditForm
          personFlg={personFlg}
          onClose={movePageAfterForm}
          onSubmit={movePageAfterForm}
        />
      </Paper>
    </MarginContainer>
  )
}

export default MyDataCompanyContributionPensionEdit
