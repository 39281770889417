import { extractYearOrMonthFromDate } from '@/models/commonsModelFunc'
import { NON_LIFE_INSURANCE_FEE_MONTHS } from '../commonInsurancesModelFunc'

export const buildFutureInsurancesInitialValues = (futureInsurance: any) => {
  const isBulk = futureInsurance?.insurance_fee_payment_occurrence_in_months >= 24
  const insuranceFeeMonths = isBulk
    ? NON_LIFE_INSURANCE_FEE_MONTHS.bulk
    : futureInsurance?.insurance_fee_payment_occurrence_in_months
    ? String(futureInsurance?.insurance_fee_payment_occurrence_in_months)
    : null

  const insuranceFeeYear = isBulk
    ? futureInsurance?.insurance_fee_payment_occurrence_in_months / 12
    : null

  const insuredSubjectCars =
    futureInsurance?.input_cars && futureInsurance?.input_cars.length > 0
      ? futureInsurance?.input_cars.map((item: any) => `InputCar_${item.id}`)
      : []

  const inputEstateInvestments =
    futureInsurance?.input_estate_investments &&
    futureInsurance?.input_estate_investments.length > 0
      ? futureInsurance?.input_estate_investments.map(
          (item: any) => `InputEstateInvestment_${item.id}`,
        )
      : []

  const inputHousings =
    futureInsurance?.input_housings && futureInsurance?.input_housings.length > 0
      ? futureInsurance?.input_housings.map((item: any) => `InputHousing_${item.id}`)
      : []

  const classification = [
    'life',
    'medical',
    'cancer',
    'nursing',
    'personal_annuity',
  ].includes(futureInsurance?.insurance_type1)
    ? 'life'
    : 'non_life'

  return {
    future_insurance: {
      ...futureInsurance,
      classification,
      company_name: futureInsurance?.company_name,
      insurance_type1: futureInsurance?.insurance_type1,
      insurance_fee_payment_occurrence_in_months: insuranceFeeMonths,
      contracted_at: extractYearOrMonthFromDate(futureInsurance?.contracted_at, 'year'),
      insurance_fee: futureInsurance?.insurance_fee,
    },
    cancellation_refund_at_year: extractYearOrMonthFromDate(
      futureInsurance?.cancellation_refund_at,
      'year',
    ),
    cancellation_refund_at_month: extractYearOrMonthFromDate(
      futureInsurance?.cancellation_refund_at,
      'month',
    ),
    contracted_year: extractYearOrMonthFromDate(futureInsurance?.contracted_at, 'year'),
    contracted_month: extractYearOrMonthFromDate(futureInsurance?.contracted_at, 'month'),
    insurance_fee_payment_period_finish_year: extractYearOrMonthFromDate(
      futureInsurance?.insurance_fee_payment_period_finish_at,
      'year',
    ),
    insurance_fee_payment_period_finish_month: extractYearOrMonthFromDate(
      futureInsurance?.insurance_fee_payment_period_finish_at,
      'month',
    ),
    insurance_period_finish_year: extractYearOrMonthFromDate(
      futureInsurance?.insurance_period_finish_at,
      'year',
    ),
    insurance_period_finish_month: extractYearOrMonthFromDate(
      futureInsurance?.insurance_period_finish_at,
      'month',
    ),
    insurance_fee_payment_occurrence_in_year: insuranceFeeYear,
    insured_subject_cars: insuredSubjectCars[0],
    insured_subject_properties: [...inputEstateInvestments, ...inputHousings][0],
  }
}
