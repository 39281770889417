import React, { FC } from 'react'
import { useField } from 'react-final-form'
import {
  INPUT_HOUSING_LIFE_INSURANCE_RADIO_ITEMS,
  INPUT_LOAN_DEDUCTION_RADIO_ITEMS,
  INPUT_MARRIED_COUPLE_GROUP_SHIN_RADIO_ITEMS,
} from '@/constants/formItem/inputs/inputHousings'
import { API_FLAG } from '@/models/commonsModelFunc'
import { selectFutureV2LoanObjectName } from '@/models/futures/futureV2HousingLoansModelFunc'
import { selectFutureV2LoanChartObjectName } from '@/models/futures/futureV2HousingLoanChartsModelFunc'
import { selectFutureV2LoanCalculationObjectName } from '@/models/futures/futureV2HousingLoanCalculationsModelFunc'
import { selectLoanCalculationObjectName } from '@/models/inputs/inputLoanCalculationsModelFunc'
import { selectLoanChartObjectName } from '@/models/inputs/inputLoanChartsModelFunc'
import {
  selectLoanObjectName,
  isPairLoan,
  selectInputLoanObjectNumber,
  isFlatLoan,
} from '@/models/inputs/inputLoansModelFunc'
import { required } from '@/utils/validate'

import Typography from '@/components/v2/atoms/Typography'
import SelectBox from '@/components/v2/molecules/SelectBox'
import HelpCircleIcon from '@/assets/images/v2/help-circle.svg'
import Tooltip from '@/components/v2/atoms/Tooltip'

import { HAS_LOAN_LIFE_INSURANCE_CONTRACT_SELECT_ITEMS } from '@/constants/formItem/housingLoans'

interface LoanDeductionProps {
  /** true: シミュレーション, false: 現状 */
  isFuture?: boolean
  /** true: 建物用ローン, false: 土地用ローン・分割なし */
  isBuilding?: boolean
  /** true: 配偶者, false: ご本人 */
  isSpouse?: boolean
}

const LoanDeduction: FC<LoanDeductionProps> = (props) => {
  const { isFuture, isBuilding, isSpouse } = props
  const loanObjectNum = selectInputLoanObjectNumber(isBuilding, isSpouse)
  const selectTargetLoanObjectName = isFuture
    ? selectFutureV2LoanObjectName
    : selectLoanObjectName
  const selectTargetLoanChartObjectName = isFuture
    ? selectFutureV2LoanChartObjectName
    : selectLoanChartObjectName
  const selectTargetLoanCalcObjectName = isFuture
    ? selectFutureV2LoanCalculationObjectName
    : selectLoanCalculationObjectName
  const selectInputHousingLifeInsuranceRadioItems = isFuture
    ? HAS_LOAN_LIFE_INSURANCE_CONTRACT_SELECT_ITEMS
    : INPUT_HOUSING_LIFE_INSURANCE_RADIO_ITEMS

  const {
    input: { value: loanType },
  } = useField(selectTargetLoanObjectName(loanObjectNum, 'loan_type'))
  const {
    input: { value: loanLender },
  } = useField(selectTargetLoanObjectName(loanObjectNum, 'loan_lender'))
  const {
    input: { value: repaymentChart },
  } = useField(selectTargetLoanObjectName(loanObjectNum, 'repayment_chart'))

  const isWithRepaymentChart = isFuture ? false : repaymentChart == API_FLAG.on

  return (
    <>
      {!isPairLoan(loanType) && (
        <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
          <Typography
            className="min-w-[140px] lg:min-w-[210px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
            isBold
          >
            団体信用生命保険
          </Typography>
          <SelectBox
            name={
              isWithRepaymentChart
                ? selectTargetLoanChartObjectName(loanObjectNum, 'life_insurance')
                : selectTargetLoanCalcObjectName(loanObjectNum, 'life_insurance')
            }
            placeholder="選択する"
            isFullWidth
            options={selectInputHousingLifeInsuranceRadioItems}
            validate={required}
          />
        </div>
      )}

      {isPairLoan(loanType) && (
        <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
          <Typography
            className="min-w-[140px] lg:min-w-[210px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
            isBold
          >
            夫婦連生団信
          </Typography>
          <SelectBox
            name={
              isWithRepaymentChart
                ? selectTargetLoanChartObjectName(
                    loanObjectNum,
                    'married_couple_group_shin',
                  )
                : selectTargetLoanCalcObjectName(
                    loanObjectNum,
                    'married_couple_group_shin',
                  )
            }
            placeholder="選択する"
            isFullWidth
            options={INPUT_MARRIED_COUPLE_GROUP_SHIN_RADIO_ITEMS}
            validate={required}
          />
        </div>
      )}

      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-center">
        <div className="lg:flex items-center">
          <div>
            <Typography
              className="flex items-center min-w-[140px] lg:min-w-[210px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
              isBold
            >
              住宅ローン控除の対象
              <div className="mx-[10px]">
                <Tooltip
                  item={
                    <div>
                      住宅ローン控除は、下記の１、２の両方ともに当てはまる方に適用されます。
                      <br />
                      １. 家の面積が５０平方メートル以上
                      <br />
                      ２. 新築もしくは、認定中古住宅
                    </div>
                  }
                  itemClassName="w-[80px] sm:w-[200px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                >
                  <HelpCircleIcon />
                </Tooltip>
              </div>
            </Typography>
          </div>

          <SelectBox
            isFullWidth
            name={
              isWithRepaymentChart
                ? selectTargetLoanChartObjectName(loanObjectNum, 'loan_deduction')
                : selectTargetLoanCalcObjectName(loanObjectNum, 'loan_deduction')
            }
            placeholder="選択する"
            options={INPUT_LOAN_DEDUCTION_RADIO_ITEMS}
            validate={required}
          />
        </div>
      </div>
    </>
  )
}

export default LoanDeduction
