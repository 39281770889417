import React, { FC, useEffect, useMemo, useState, ChangeEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import { every as _every, isUndefined as _isUndefined } from 'lodash'
import ChevronRightIcon from '@/assets/images/v2/chevron-right.svg'
import CrossIcon from '@/assets/images/v2/cross.svg'
import Button from '@/components/v2/atoms/Button'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import Checkbox from '@/components/v2/atoms/Checkbox'
import TextField from '@/components/v2/atoms/TextField'
import Typography from '@/components/v2/atoms/Typography'
import NumberField from '@/components/v2/molecules/NumberField'
import {
  YEARLY_LIVING_COST_ELECTRONIC_SHEET,
  YEARLY_LIVING_COST_CEREMONY_SHEET,
  electronicRequiredV2,
} from '@/models/commonYearlyLivingCostsModelFunc'
import { isNotEmptyValue } from '@/models/commonsModelFunc'
import {
  buildInputYearlyLivingCostCreateInitialValues,
  buildInputYearlyLivingCostSubmitValuesV2,
  buildInputYearlyLivingCostUpdateInitialValues,
} from '@/models/inputs/inputYearlyLivingCostsModelFunc'
import { RootState } from '@/store'
import { required, zeroOrMoreNumber } from '@/utils/validate'
import {
  createInputYearlyLivingCost,
  fetchInputYearlyLivingCostsV2,
  updateInputYearlyLivingCost,
} from '@/containers/inputs/inputYearlyLivingCostsSlice'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import { fetchFamilyStructureV2 } from '@/containers/familyStructuresSlice'
import WholeSubmitErrorMessage from '@/components/v2/molecules/WholeSubmitErrorMessage'
import LoadingOverlay from '@/components/v1/atoms/LoadingOverlay'
import Link from '@/components/v2/atoms/Link'
import { fetchAccountShowInput } from '@/containers/accountsSlice'
import Modal from '@/components/v2/atoms/Modal'
import StripedTable from '@/components/v2/molecules/StripedTable'
import SelectBox from '@/components/v2/molecules/SelectBox'
import { buildRangeAgeSelectItems } from '@/utils/formItem'
import { AFFECTED_END_AT_AGE_SELECT_ITEMS } from '@/constants/formItem/futures/futureMonthlyLivingCosts'
import clsx from 'clsx'
import SubmitErrorMessage from '@/components/v2/molecules/SubmitErrorMessage'
import { OCCURANCE_TERM_TYPE } from '@/models/commonYearlyLivingCostsModelFunc'
import { updateResetFlg } from '@/containers/futures/futureBasesSlice'

interface MyDataYearlyLivingCostEditFormProps {
  onClose: () => void
  onSubmit: () => void
}

const MyDataYearlyLivingCostEditForm: FC<MyDataYearlyLivingCostEditFormProps> = (
  props,
) => {
  const dispatch = useDispatch()
  const { showFlashMsg } = useFlashAlert()

  const [isFamilyAgeModalOpen, setIsFamilyAgeModalOpen] = useState<boolean>(false)
  const {
    input_yearly_living_cost_electronics,
    input_yearly_living_cost_ceremonies,
    input_yearly_living_cost_home_comings,
    input_yearly_living_cost_travels,
    isLoading,
  } = useSelector((state: RootState) => state.inputYearlyLivingCosts)
  const {
    isGetAccountShowInputLoading,
    input_statement_table_data,
    family_structure_table_columns,
  } = useSelector((state: RootState) => state.accounts)

  const isEdit = useMemo(
    () =>
      input_yearly_living_cost_electronics &&
      input_yearly_living_cost_electronics.length > 0,
    [location, input_yearly_living_cost_electronics],
  )

  const { familyInfoV2 } = useSelector((state: RootState) => state.familyStructures)

  // isLoading Flag
  const isLoadingFamilyStructures = useSelector(
    (state: RootState) => state.familyStructures.isLoading,
  )
  const isLoadingInputYearlyLivingCosts = useSelector(
    (state: RootState) => state.inputYearlyLivingCosts.isLoading,
  )

  const familyAge = useMemo(() => {
    return {
      person_current_age: familyInfoV2?.person_age,
      spouse_current_age: familyInfoV2?.spouse_age,
    }
  }, [familyInfoV2])
  const { person_current_age } = familyAge

  const ageSelectItems = useMemo(() => {
    const newAgeSelectItems = []
    newAgeSelectItems.push(
      ...buildRangeAgeSelectItems(
        person_current_age!,
        110,
        AFFECTED_END_AT_AGE_SELECT_ITEMS.map((item: any) => ({
          value: item.value,
          label: `${item.value}`,
        })),
      ),
    )

    return newAgeSelectItems
  }, [person_current_age])

  const initialValues = useMemo(
    () =>
      isEdit
        ? buildInputYearlyLivingCostUpdateInitialValues(
            input_yearly_living_cost_ceremonies,
            input_yearly_living_cost_electronics,
            input_yearly_living_cost_home_comings,
            input_yearly_living_cost_travels,
          )
        : buildInputYearlyLivingCostCreateInitialValues(),
    [
      isEdit,
      input_yearly_living_cost_ceremonies,
      input_yearly_living_cost_electronics,
      input_yearly_living_cost_home_comings,
      input_yearly_living_cost_travels,
    ],
  )

  useEffect(() => {
    if (input_yearly_living_cost_electronics.length == 0)
      dispatch(fetchInputYearlyLivingCostsV2())
    if (!familyInfoV2) dispatch(fetchFamilyStructureV2())
    dispatch(fetchAccountShowInput())
  }, [])

  const handleSubmit = useMemo(
    () => (values: any) => {
      return isEdit ? handleUpdate(values) : handleCreate(values)
    },
    [isEdit],
  )

  const handleCreate = (values: any) => {
    const pickValues: any = values
    const newValues = buildInputYearlyLivingCostSubmitValuesV2(
      pickValues,
      values?.person_current_age as any,
    )

    dispatch(
      createInputYearlyLivingCost(newValues, () => {
        showFlashMsg(isEdit ? '更新しました' : '作成しました')
        dispatch(fetchInputYearlyLivingCostsV2())
        dispatch(updateResetFlg())
        props.onSubmit()
      }),
    )
  }

  const handleUpdate = (values: any) => {
    const pickValues: any = values
    const newValues = buildInputYearlyLivingCostSubmitValuesV2(
      pickValues,
      values?.person_current_age as any,
    )

    dispatch(
      updateInputYearlyLivingCost(newValues, () => {
        showFlashMsg(isEdit ? '更新しました' : '作成しました')
        dispatch(fetchInputYearlyLivingCostsV2())
        dispatch(updateResetFlg())
        props.onSubmit()
      }),
    )
  }

  return (
    <>
      <LoadingOverlay
        isLoading={
          isLoadingFamilyStructures ||
          isLoadingInputYearlyLivingCosts ||
          isGetAccountShowInputLoading
        }
      />
      <Form
        onSubmit={handleSubmit}
        initialValues={{ ...initialValues, person_current_age }}
        mutators={{ ...arrayMutators }}
        render={({
          handleSubmit,
          values,
          errors,
          submitErrors,
          submitFailed,
          form: { change },
        }) => {
          const home_comings_occurance_term_type =
            values.account.input_yearly_living_cost_home_comings_attributes?.[0]
              ?.occurance_term_type

          const travels_occurance_term_type =
            values.account.input_yearly_living_cost_travels_attributes?.[0]
              ?.occurance_term_type

          useEffect(() => {
            const isAllCheck = _every(
              values?.input_yearly_living_cost_large_electronics,
              (item) => item.is_active,
            )

            change('is_all_large_electronic', isAllCheck)
          }, [values?.input_yearly_living_cost_large_electronics])

          useEffect(() => {
            const isAllCheck = _every(
              values?.input_yearly_living_cost_small_electronics,
              (item) => item.is_active,
            )

            change('is_all_small_electronic', isAllCheck)
          }, [values?.input_yearly_living_cost_small_electronics])

          const handleChangeIsAllLargeElectronic = (checked: boolean) => {
            const newLargeElectronics = values?.input_yearly_living_cost_large_electronics.map(
              (item: any) => ({
                ...item,
                is_active: checked,
              }),
            )

            change('input_yearly_living_cost_large_electronics', newLargeElectronics)
          }

          const handleChangeIsAllSmallElectronic = (checked: boolean) => {
            const newSmallElectronics = values?.input_yearly_living_cost_small_electronics.map(
              (item: any) => ({
                ...item,
                is_active: checked,
              }),
            )

            change('input_yearly_living_cost_small_electronics', newSmallElectronics)
          }

          const handleLargeReflectAverage = () => {
            const newLargeElectronics = values?.input_yearly_living_cost_large_electronics.map(
              (item: any) =>
                item.is_active
                  ? {
                      ...item,
                      occurance_in_years:
                        YEARLY_LIVING_COST_ELECTRONIC_SHEET[item.default_key]
                          .occurance_in_years,
                      single_cost:
                        YEARLY_LIVING_COST_ELECTRONIC_SHEET[item.default_key].single_cost,
                    }
                  : { ...item },
            )

            change('input_yearly_living_cost_large_electronics', newLargeElectronics)
          }

          const handleSmallReflectAverage = () => {
            const newSmallElectronics = values?.input_yearly_living_cost_small_electronics.map(
              (item: any) =>
                item.is_active
                  ? {
                      ...item,
                      occurance_in_years:
                        YEARLY_LIVING_COST_ELECTRONIC_SHEET[item.default_key]
                          .occurance_in_years,
                      single_cost:
                        YEARLY_LIVING_COST_ELECTRONIC_SHEET[item.default_key].single_cost,
                    }
                  : { ...item },
            )

            change('input_yearly_living_cost_small_electronics', newSmallElectronics)
          }

          const handleCeremonyReflectAverage = () => {
            const newCeremony = values?.account?.input_yearly_living_cost_ceremonies_attributes.map(
              (item: any) => ({
                ...item,
                yearly_cost:
                  YEARLY_LIVING_COST_CEREMONY_SHEET[item.affected_start_at_age],
              }),
            )

            change('account.input_yearly_living_cost_ceremonies_attributes', newCeremony)
          }

          const handleAddSmallElectronics = () => {
            const newSmallElectronics = [
              ...values?.input_yearly_living_cost_small_electronics,
              {},
            ]
            change('input_yearly_living_cost_small_electronics', newSmallElectronics)
          }

          const handleRemoveSmallElectronics = (index: number) => {
            const copiedSmallElectronics = [
              ...values?.input_yearly_living_cost_small_electronics,
            ]

            if (_isUndefined(copiedSmallElectronics[index].id)) {
              copiedSmallElectronics.splice(index, 1)
            } else {
              copiedSmallElectronics[index]._delete = true
            }

            change('input_yearly_living_cost_small_electronics', copiedSmallElectronics)
          }

          return (
            <form onSubmit={handleSubmit}>
              <div className="pb-[64px]">
                <div className="flex justify-between pb-[14px]">
                  <Typography className="text-black-900 text-[18px]" isBold>
                    大物家電
                  </Typography>
                  <Button
                    className="w-[160px] h-[37px] ml-[auto] mb-0 md:mb-[20px] rounded-[4px] bg-white border border-secondary-500 hover:bg-secondary-50"
                    onClick={handleLargeReflectAverage}
                  >
                    <Typography className="text-[14px] text-secondary-500" isBold>
                      平均値を反映させる
                    </Typography>
                  </Button>
                </div>

                <table className="hidden md:table w-full text-[14px]">
                  <tbody>
                    <tr className="border-b border-black-300">
                      <td className="w-[170px] lg:w-[240px] pb-[10px]">
                        <Checkbox
                          name="is_all_large_electronic"
                          label="家電"
                          className="font-bold"
                          onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            handleChangeIsAllLargeElectronic(event.target.checked)
                          }
                        />
                      </td>
                      <td className="pb-[10px]">
                        <Typography isBold>次回買い替え時期</Typography>
                      </td>
                      <td className="pb-[10px]">
                        <Typography isBold>買い替え頻度</Typography>
                      </td>
                      <td className="pb-[10px]">
                        <Typography isBold>1回あたりの費用</Typography>
                      </td>
                    </tr>

                    <FieldArray name="input_yearly_living_cost_large_electronics">
                      {({ fields }) =>
                        fields.map((name, index) => (
                          <tr key={name} className="border-b border-black-300">
                            <td className="py-[10px]">
                              <Checkbox
                                name={`${name}is_active`}
                                label={fields.value[index]?.display_name}
                              />
                            </td>
                            <td className="py-[10px]">
                              <div className="flex items-baseline">
                                <Typography className="min-w-[fit-content] pr-[8px]">
                                  ご本人が
                                </Typography>
                                <SelectBox
                                  name={`${name}next_purchase_at_age`}
                                  widthClassName="max-w-[65px] lg:max-w-[100px]"
                                  isDisabled={!fields.value[index]?.is_active}
                                  options={ageSelectItems}
                                  validate={(value: any, allValues: any) =>
                                    electronicRequiredV2(
                                      value,
                                      allValues,
                                      `${name}is_active`,
                                    )
                                  }
                                />
                                <Typography className="min-w-[fit-content] pl-[8px]">
                                  歳の時
                                </Typography>
                              </div>
                            </td>
                            <td className="py-[10px]">
                              <div className="flex items-baseline">
                                <NumberField
                                  name={`${name}occurance_in_years`}
                                  widthClassName="max-w-[65px] lg:max-w-[100px]"
                                  disabled={!fields.value[index]?.is_active}
                                  validate={(value: any, allValues: any) =>
                                    electronicRequiredV2(
                                      value,
                                      allValues,
                                      `${name}is_active`,
                                      zeroOrMoreNumber,
                                    )
                                  }
                                />
                                <Typography className="min-w-[fit-content] pl-[8px]">
                                  年ごと
                                </Typography>
                              </div>
                            </td>
                            <td className="py-[10px]">
                              <div className="flex items-baseline">
                                <NumberField
                                  name={`${name}single_cost`}
                                  widthClassName="max-w-[100px]"
                                  disabled={!fields.value[index]?.is_active}
                                  validate={(value: any, allValues: any) =>
                                    electronicRequiredV2(
                                      value,
                                      allValues,
                                      `${name}is_active`,
                                      zeroOrMoreNumber,
                                    )
                                  }
                                />
                                <Typography className="min-w-[fit-content] pl-[8px]">
                                  円
                                </Typography>
                              </div>
                            </td>
                          </tr>
                        ))
                      }
                    </FieldArray>
                  </tbody>
                </table>

                <div className="md:hidden text-[14px]">
                  <div className="pb-[10px] border-b border-black-300">
                    <Checkbox
                      name="is_all_large_electronic"
                      label="家電"
                      className="font-bold"
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        handleChangeIsAllLargeElectronic(event.target.checked)
                      }
                    />
                  </div>
                  <FieldArray name="input_yearly_living_cost_large_electronics">
                    {({ fields }) =>
                      fields.map((name, index) => (
                        <div key={name} className="py-[20px] border-b border-black-300">
                          <Checkbox
                            name={`${name}is_active`}
                            label={fields.value[index]?.display_name}
                            className="pb-[10px]"
                          />
                          <div className="flex items-baseline pb-[10px] last:pb-0">
                            <Typography className="min-w-[130px]" isBold>
                              次回買い替え時期
                            </Typography>
                            <div className="flex items-baseline">
                              <Typography className="min-w-[fit-content] pr-[8px]">
                                ご本人が
                              </Typography>
                              <SelectBox
                                name={`${name}next_purchase_at_age`}
                                widthClassName="max-w-[65px] lg:max-w-[100px]"
                                isDisabled={!fields.value[index]?.is_active}
                                options={ageSelectItems}
                                validate={(value: any, allValues: any) =>
                                  electronicRequiredV2(
                                    value,
                                    allValues,
                                    `${name}is_active`,
                                  )
                                }
                              />
                              <Typography className="min-w-[fit-content] pl-[8px]">
                                歳の時
                              </Typography>
                            </div>
                          </div>
                          <div className="flex items-baseline pb-[10px] last:pb-0">
                            <Typography className="min-w-[130px]" isBold>
                              買い替え頻度
                            </Typography>
                            <div className="flex items-baseline">
                              <NumberField
                                name={`${name}occurance_in_years`}
                                widthClassName="max-w-[65px]"
                                disabled={!fields.value[index]?.is_active}
                                validate={(value: any, allValues: any) =>
                                  electronicRequiredV2(
                                    value,
                                    allValues,
                                    `${name}is_active`,
                                    zeroOrMoreNumber,
                                  )
                                }
                              />
                              <Typography className="min-w-[fit-content] pl-[8px]">
                                年ごと
                              </Typography>
                            </div>
                          </div>
                          <div className="flex items-baseline pb-[10px] last:pb-0">
                            <Typography className="min-w-[130px]" isBold>
                              1回あたりの費用
                            </Typography>
                            <div className="flex items-baseline">
                              <NumberField
                                name={`${name}single_cost`}
                                isFullWidth
                                disabled={!fields.value[index]?.is_active}
                                validate={(value: any, allValues: any) =>
                                  electronicRequiredV2(
                                    value,
                                    allValues,
                                    `${name}is_active`,
                                    zeroOrMoreNumber,
                                  )
                                }
                              />
                              <Typography className="pl-[8px]">円</Typography>
                            </div>
                          </div>
                        </div>
                      ))
                    }
                  </FieldArray>
                </div>
              </div>

              <div className="pb-[64px]">
                <div className="flex justify-between pb-[14px]">
                  <Typography className="text-black-900  text-[18px]" isBold>
                    小物家電
                  </Typography>
                  <Button
                    className="w-[160px] h-[37px] ml-[auto] mb-0 md:mb-[20px] rounded-[4px] bg-white border border-secondary-500 hover:bg-secondary-50"
                    onClick={handleSmallReflectAverage}
                  >
                    <Typography className="text-[14px] text-secondary-500" isBold>
                      平均値を反映させる
                    </Typography>
                  </Button>
                </div>

                <table className="hidden md:table w-full mb-[20px] text-[14px]">
                  <tbody>
                    <tr className="border-b border-black-300">
                      <td className="w-[170px] lg:w-[240px] pb-[10px]">
                        <Checkbox
                          name="is_all_small_electronic"
                          label="家電"
                          className="font-bold"
                          onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            handleChangeIsAllSmallElectronic(event.target.checked)
                          }
                        />
                      </td>
                      <td className="pb-[10px]">
                        <Typography isBold>次回買い替え時期</Typography>
                      </td>
                      <td className="pb-[10px]">
                        <Typography isBold>買い替え頻度</Typography>
                      </td>
                      <td className="pb-[10px]">
                        <Typography isBold>1回あたりの費用</Typography>
                      </td>
                      <td className="pb-[10px]" />
                    </tr>

                    <FieldArray name="input_yearly_living_cost_small_electronics">
                      {({ fields }) =>
                        fields.map((name, index) => (
                          <React.Fragment key={name}>
                            {!fields.value[index]?._delete && (
                              <tr className="border-b border-black-300">
                                <td className="py-[10px]">
                                  <div className="flex items-baseline">
                                    <Checkbox
                                      name={`${name}is_active`}
                                      label={
                                        isNotEmptyValue(fields.value[index]?.default_key)
                                          ? fields.value[index]?.display_name
                                          : ''
                                      }
                                    />
                                    {!isNotEmptyValue(
                                      fields.value[index]?.default_key,
                                    ) && (
                                      <TextField
                                        type="text"
                                        name={`${name}display_name`}
                                        widthClassName="max-w-[130px] lg:max-w-[170px]"
                                        disabled={!fields.value[index]?.is_active}
                                        validate={(value: any, allValues: any) =>
                                          electronicRequiredV2(
                                            value,
                                            allValues,
                                            `${name}is_active`,
                                          )
                                        }
                                      />
                                    )}
                                  </div>
                                </td>
                                <td className="py-[10px]">
                                  <div className="flex items-baseline">
                                    <Typography className="min-w-[fit-content] pr-[8px]">
                                      ご本人が
                                    </Typography>
                                    <SelectBox
                                      name={`${name}next_purchase_at_age`}
                                      widthClassName="max-w-[65px] lg:max-w-[100px]"
                                      isDisabled={!fields.value[index]?.is_active}
                                      options={ageSelectItems}
                                      validate={(value: any, allValues: any) =>
                                        electronicRequiredV2(
                                          value,
                                          allValues,
                                          `${name}is_active`,
                                        )
                                      }
                                    />
                                    <Typography className="min-w-[fit-content] pl-[8px]">
                                      歳の時
                                    </Typography>
                                  </div>
                                </td>
                                <td className="py-[10px]">
                                  <div className="flex items-baseline">
                                    <NumberField
                                      name={`${name}occurance_in_years`}
                                      widthClassName="max-w-[65px] lg:max-w-[100px]"
                                      disabled={!fields.value[index]?.is_active}
                                      validate={(value: any, allValues: any) =>
                                        electronicRequiredV2(
                                          value,
                                          allValues,
                                          `${name}is_active`,
                                          zeroOrMoreNumber,
                                        )
                                      }
                                    />
                                    <Typography className="min-w-[fit-content] pl-[8px]">
                                      年ごと
                                    </Typography>
                                  </div>
                                </td>
                                <td className="py-[10px]">
                                  <div className="flex items-baseline">
                                    <NumberField
                                      name={`${name}single_cost`}
                                      widthClassName="max-w-[100px]"
                                      disabled={!fields.value[index]?.is_active}
                                      validate={(value: any, allValues: any) =>
                                        electronicRequiredV2(
                                          value,
                                          allValues,
                                          `${name}is_active`,
                                          zeroOrMoreNumber,
                                        )
                                      }
                                    />
                                    <Typography className="min-w-[fit-content] pl-[8px]">
                                      円
                                    </Typography>
                                  </div>
                                </td>
                                <td className="py-[10px]">
                                  {!isNotEmptyValue(fields.value[index]?.default_key) && (
                                    <Button
                                      onClick={() => handleRemoveSmallElectronics(index)}
                                    >
                                      <CrossIcon />
                                    </Button>
                                  )}
                                </td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))
                      }
                    </FieldArray>
                  </tbody>
                </table>

                <div className="md:hidden pb-[20px] text-[14px]">
                  <div className="pb-[10px] border-b border-black-300">
                    <Checkbox
                      name="is_all_small_electronic"
                      label="家電"
                      className="font-bold"
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        handleChangeIsAllSmallElectronic(event.target.checked)
                      }
                    />
                  </div>
                  <FieldArray name="input_yearly_living_cost_small_electronics">
                    {({ fields }) =>
                      fields.map((name, index) => (
                        <React.Fragment key={name}>
                          {!fields.value[index]?._delete && (
                            <div className="relative py-[20px] border-b border-black-300">
                              <div className="flex items-baseline pb-[10px]">
                                <Checkbox
                                  name={`${name}is_active`}
                                  label={fields.value[index]?.display_name}
                                />
                                {!isNotEmptyValue(fields.value[index]?.default_key) && (
                                  <TextField
                                    type="text"
                                    name={`${name}display_name`}
                                    widthClassName="max-w-[250px]"
                                    disabled={!fields.value[index]?.is_active}
                                  />
                                )}
                              </div>
                              <div className="flex items-baseline pb-[10px] last:pb-0">
                                <Typography className="min-w-[130px]" isBold>
                                  次回買い替え時期
                                </Typography>
                                <div className="flex items-baseline">
                                  <Typography className="min-w-[fit-content] pr-[8px]">
                                    ご本人が
                                  </Typography>
                                  <SelectBox
                                    name={`${name}next_purchase_at_age`}
                                    widthClassName="max-w-[65px] lg:max-w-[100px]"
                                    isDisabled={!fields.value[index]?.is_active}
                                    options={ageSelectItems}
                                    validate={(value: any, allValues: any) =>
                                      electronicRequiredV2(
                                        value,
                                        allValues,
                                        `${name}is_active`,
                                      )
                                    }
                                  />
                                  <Typography className="min-w-[fit-content] pl-[8px]">
                                    歳の時
                                  </Typography>
                                </div>
                              </div>
                              <div className="flex items-baseline pb-[10px] last:pb-0">
                                <Typography className="min-w-[130px]" isBold>
                                  買い替え頻度
                                </Typography>
                                <div className="flex items-baseline">
                                  <NumberField
                                    name={`${name}occurance_in_years`}
                                    widthClassName="max-w-[65px]"
                                    disabled={!fields.value[index]?.is_active}
                                    validate={(value: any, allValues: any) =>
                                      electronicRequiredV2(
                                        value,
                                        allValues,
                                        `${name}is_active`,
                                        zeroOrMoreNumber,
                                      )
                                    }
                                  />
                                  <Typography className="min-w-[fit-content] pl-[8px]">
                                    年ごと
                                  </Typography>
                                </div>
                              </div>
                              <div className="flex items-baseline pb-[10px] last:pb-0">
                                <Typography className="min-w-[130px]" isBold>
                                  1回あたりの費用
                                </Typography>
                                <div className="flex items-baseline">
                                  <NumberField
                                    name={`${name}single_cost`}
                                    isFullWidth
                                    disabled={!fields.value[index]?.is_active}
                                    validate={(value: any, allValues: any) =>
                                      electronicRequiredV2(
                                        value,
                                        allValues,
                                        `${name}is_active`,
                                        zeroOrMoreNumber,
                                      )
                                    }
                                  />
                                  <Typography className="pl-[8px]">円</Typography>
                                </div>
                              </div>
                              {!isNotEmptyValue(fields.value[index]?.default_key) && (
                                <Button
                                  className="absolute top-[20px] right-0"
                                  onClick={() => handleRemoveSmallElectronics(index)}
                                >
                                  <CrossIcon />
                                </Button>
                              )}
                            </div>
                          )}
                        </React.Fragment>
                      ))
                    }
                  </FieldArray>
                </div>

                <Button
                  className="w-full h-[53px] border border-dashed border-primary-500 text-[14px] text-primary-500 hover:bg-primary-50"
                  onClick={handleAddSmallElectronics}
                >
                  <Typography isBold>追加</Typography>
                </Button>
              </div>

              <div className="pb-[64px]">
                <Button
                  className="w-[160px] h-[37px] ml-[auto] mb-0 md:mb-[20px] rounded-[4px] bg-white border border-secondary-500 hover:bg-secondary-50"
                  onClick={handleCeremonyReflectAverage}
                >
                  <Typography className="text-[14px] text-secondary-500" isBold>
                    平均値を反映させる
                  </Typography>
                </Button>

                <table className="hidden md:table table-fixed w-full text-[14px]">
                  <tbody>
                    <tr className="border-b border-black-300">
                      <td className="pb-[10px]">
                        <Typography isBold>ご本人の年齢</Typography>
                      </td>
                      <td className="pb-[10px]">
                        <Typography isBold>年間の冠婚葬祭にかかる費用</Typography>
                      </td>
                    </tr>
                    <FieldArray name="account.input_yearly_living_cost_ceremonies_attributes">
                      {({ fields }) =>
                        fields.map((name, index) => (
                          <tr key={name} className="border-b border-black-300">
                            <td className="py-[10px]">
                              <Typography>{`${fields.value[index]?.affected_start_at_age}歳から${fields.value[index]?.affected_end_at_age}歳`}</Typography>
                            </td>
                            <td className="py-[10px]">
                              <div className="flex items-baseline">
                                <NumberField
                                  name={`${name}yearly_cost`}
                                  validate={required}
                                />
                                <Typography className="pl-[8px]">円/年</Typography>
                              </div>
                            </td>
                          </tr>
                        ))
                      }
                    </FieldArray>
                  </tbody>
                </table>

                <div className="md:hidden pb-[20px] text-[14px]">
                  <FieldArray name="account.input_yearly_living_cost_ceremonies_attributes">
                    {({ fields }) =>
                      fields.map((name, index) => (
                        <div key={name} className="py-[20px] border-b border-black-300">
                          <div className="flex items-baseline pb-[10px] last:pb-0">
                            <Typography className="min-w-[130px]" isBold>
                              ご本人の年齢
                            </Typography>
                            <Typography>{`${fields.value[index]?.affected_start_at_age}歳から${fields.value[index]?.affected_end_at_age}歳`}</Typography>
                          </div>
                          <div className="flex pb-[10px] last:pb-0">
                            <Typography className="min-w-[130px]" isBold>
                              年間の冠婚葬祭に
                              <br />
                              かかる費用
                            </Typography>
                            <div className="flex items-baseline">
                              <NumberField
                                name={`${name}yearly_cost`}
                                widthClassName="max-w-[120px]"
                                validate={required}
                              />
                              <Typography className="pl-[8px]">円/年</Typography>
                            </div>
                          </div>
                        </div>
                      ))
                    }
                  </FieldArray>
                </div>
              </div>

              <div className="pb-[64px]">
                <div className="md:flex justify-between items-baseline py-[20px] border-b border-black-300">
                  <Typography className="pb-[10px] md:pb-0 text-[14px]">
                    帰省１回あたりの費用
                  </Typography>
                  <div className="flex items-baseline">
                    <NumberField
                      name="account.input_yearly_living_cost_home_comings_attributes[0]single_cost"
                      validate={required}
                    />
                    <Typography className="pl-[8px]">円</Typography>
                  </div>
                </div>
                <div className="md:flex justify-between items-baseline py-[20px] border-b border-black-300">
                  <Typography className="pb-[10px] md:pb-0 text-[14px]">
                    帰省の頻度
                  </Typography>
                  <div>
                    <div className="flex items-baseline pb-10">
                      <SelectBox
                        name="account.input_yearly_living_cost_home_comings_attributes[0]occurance_term_type"
                        // widthClassName="max-w-[65px] lg:max-w-[100px]"
                        isFullWidth
                        options={[
                          { value: OCCURANCE_TERM_TYPE.year, label: '数年に1回' },

                          { value: OCCURANCE_TERM_TYPE.time, label: '1年に数回' },
                        ]}
                        onChange={(e) => {
                          const value = e.target.value
                          if (value == OCCURANCE_TERM_TYPE.year) {
                            change(
                              'account.input_yearly_living_cost_home_comings_attributes[0]occurance_per_occurance_term',
                              1,
                            )
                          }
                          if (value == OCCURANCE_TERM_TYPE.time) {
                            change(
                              'account.input_yearly_living_cost_home_comings_attributes[0]occurance_term_in_years',
                              1,
                            )
                          }
                        }}
                      />
                    </div>

                    {home_comings_occurance_term_type && (
                      <div className="flex items-baseline">
                        <NumberField
                          name="account.input_yearly_living_cost_home_comings_attributes[0]occurance_term_in_years"
                          widthClassName="max-w-[100px]"
                          validate={required}
                          disabled={
                            home_comings_occurance_term_type == OCCURANCE_TERM_TYPE.time
                          }
                        />
                        <Typography className="min-[fit-content] px-[8px]">
                          年に
                        </Typography>
                        <NumberField
                          name="account.input_yearly_living_cost_home_comings_attributes[0]occurance_per_occurance_term"
                          widthClassName="max-w-[100px]"
                          validate={required}
                          disabled={
                            home_comings_occurance_term_type == OCCURANCE_TERM_TYPE.year
                          }
                        />
                        <Typography className="pl-[8px]">回</Typography>
                      </div>
                    )}
                  </div>
                </div>
                <div className="md:flex justify-between items-baseline py-[20px] border-b border-black-300">
                  <Typography className="pb-[10px] md:pb-0 text-[14px]">
                    適用期間
                  </Typography>
                  <div className="flex items-baseline">
                    <NumberField
                      name="account.input_yearly_living_cost_home_comings_attributes[0]affected_end_at_age"
                      widthClassName="max-w-[100px]"
                      validate={required}
                    />
                    <Typography className="min-[fit-content] pl-[8px]">歳まで</Typography>
                  </div>
                </div>
                <div className="pt-[10px] text-right">
                  <Link
                    className="text-[12px] text-black-700"
                    onClick={() => setIsFamilyAgeModalOpen(true)}
                  >
                    ご家族の年齢表を確認する
                  </Link>
                </div>
              </div>

              <div className="pb-[64px]">
                <div className="md:flex justify-between items-baseline py-[20px] border-b border-black-300">
                  <Typography className="pb-[10px] md:pb-0 text-[14px]">
                    旅行１回あたりの費用
                  </Typography>
                  <div className="flex items-baseline">
                    <NumberField
                      name="account.input_yearly_living_cost_travels_attributes[0]single_cost"
                      validate={required}
                    />
                    <Typography className="pl-[8px]">円</Typography>
                  </div>
                </div>
                <div className="md:flex justify-between items-baseline py-[20px] border-b border-black-300">
                  <Typography className="pb-[10px] md:pb-0 text-[14px]">
                    旅行の頻度
                  </Typography>

                  <div>
                    <div className="flex items-baseline pb-10">
                      <SelectBox
                        name="account.input_yearly_living_cost_travels_attributes[0]occurance_term_type"
                        isFullWidth
                        options={[
                          { value: OCCURANCE_TERM_TYPE.year, label: '数年に1回' },

                          { value: OCCURANCE_TERM_TYPE.time, label: '1年に数回' },
                        ]}
                        onChange={(e) => {
                          const value = e.target.value
                          if (value == OCCURANCE_TERM_TYPE.year) {
                            change(
                              'account.input_yearly_living_cost_travels_attributes[0]occurance_per_occurance_term',
                              1,
                            )
                          }
                          if (value == OCCURANCE_TERM_TYPE.time) {
                            change(
                              'account.input_yearly_living_cost_travels_attributes[0]occurance_term_in_years',
                              1,
                            )
                          }
                        }}
                      />
                    </div>

                    {travels_occurance_term_type && (
                      <div className="flex items-baseline">
                        <NumberField
                          name="account.input_yearly_living_cost_travels_attributes[0]occurance_term_in_years"
                          widthClassName="max-w-[100px]"
                          validate={required}
                          disabled={
                            travels_occurance_term_type == OCCURANCE_TERM_TYPE.time
                          }
                        />
                        <Typography className="min-[fit-content] px-[8px]">
                          年に
                        </Typography>
                        <NumberField
                          name="account.input_yearly_living_cost_travels_attributes[0]occurance_per_occurance_term"
                          widthClassName="max-w-[100px]"
                          validate={required}
                          disabled={
                            travels_occurance_term_type == OCCURANCE_TERM_TYPE.year
                          }
                        />
                        <Typography className="pl-[8px]">回</Typography>
                      </div>
                    )}
                  </div>
                </div>
                <div className="md:flex justify-between items-baseline py-[20px] border-b border-black-300">
                  <Typography className="pb-[10px] md:pb-0 text-[14px]">
                    適用期間
                  </Typography>
                  <div className="flex items-baseline">
                    <NumberField
                      name="account.input_yearly_living_cost_travels_attributes[0]affected_end_at_age"
                      widthClassName="max-w-[100px]"
                      validate={required}
                    />
                    <Typography className="min-[fit-content] pl-[8px]">歳まで</Typography>
                  </div>
                </div>
                <div className="pt-[10px] text-right">
                  <Link
                    className="text-[12px] text-black-700"
                    onClick={() => setIsFamilyAgeModalOpen(true)}
                  >
                    ご家族の年齢表を確認する
                  </Link>
                </div>
              </div>

              <WholeSubmitErrorMessage submitErrors={submitErrors} />
              <SubmitErrorMessage show={!submitErrors && submitFailed} />

              <ButtonPrimary
                className={clsx(
                  { ['opacity-50']: Object.keys(errors).length > 0 },
                  'w-[246px] h-[64px] mx-auto mb-[16px] ',
                )}
                isDisabled={isLoading}
                onClick={() => handleSubmit(values)}
              >
                <Typography
                  className="relative bottom-[2px] pl-[30px] text-[16px] text-white flex-auto"
                  isBold
                >
                  保存する
                </Typography>
                <ChevronRightIcon className="mr-[17px]" />
              </ButtonPrimary>
              <Button
                className="mx-auto text-[14px] text-black-700"
                onClick={props.onClose}
                isDisabled={isLoading}
              >
                キャンセル
              </Button>
            </form>
          )
        }}
      />

      <Modal
        className="w-[95%] xl:w-[1140px]"
        isOpen={isFamilyAgeModalOpen}
        onClose={() => setIsFamilyAgeModalOpen(false)}
      >
        <LoadingOverlay isLoading={isGetAccountShowInputLoading} />
        {input_statement_table_data && family_structure_table_columns && (
          <StripedTable
            className="mb-[40px]"
            tableData={input_statement_table_data}
            columnData={family_structure_table_columns}
            formatter={(v, k) => `${v}歳`}
          />
        )}
      </Modal>
    </>
  )
}

export default MyDataYearlyLivingCostEditForm
