import React, { FC, useEffect, useMemo, useState, useContext } from 'react'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import ChevronRightIcon from '@/assets/images/v2/chevron-right.svg'
import PlusIcon from '@/assets/images/v2/plus-primary.svg'
import RadioGroup from '@/components/v2/molecules/RadioGroup'
import { useDispatch, useSelector } from 'react-redux'
import { isUndefined as _isUndefined } from 'lodash'
import { MONTH_KANJI_SELECT_ITEMS } from '@/constants/formItem/commons'
import {
  AT_HAND_PENSION_PAPER_TYPE_RADIO_ITEMS,
  INPUT_TYPE_RADIO_ITEMS,
} from '@/constants/formItem/inputs/inputPublicPensions'
import {
  createInputPublicPension,
  fetchInputPublicPensionsV2,
  updateInputPublicPension,
} from '@/containers/inputs/inputPublicPensionsSlice'
import {
  castNumberWithoutOperator,
  convertDateMonth,
  extractYearOrMonthFromDate,
  THIS_MONTH_NUMBER,
  THIS_YEAR_NUMBER,
} from '@/models/commonsModelFunc'
import {
  AtHandPensionPaperType,
  InputPublicPensionCommonValues,
  InputType,
} from '@/models/inputs/inputPublicPensionsModel'
import {
  AT_HAND_PENSION_PAPER_TYPE,
  INPUT_TYPE,
} from '@/models/inputs/inputPublicPensionsModelFunc'
import { RootState } from '@/store'
import { buildTwoTypesRangeYearSelectItems } from '@/utils/formItem'
import {
  composeValidators,
  commaNumberMaxLength,
  required,
  zeroOrMoreNumber,
  oneHundredLessThanNumber,
} from '@/utils/validate'
import NumberField from '@/components/v2/molecules/NumberField'
import Typography from '@/components/v2/atoms/Typography'
import PensionPaper1 from '@/assets/images/v2/pension-paper-1.png'
import PensionPaper2 from '@/assets/images/v2/pension-paper-2.png'
import SelectBox from '@/components/v2/molecules/SelectBox'
import Button from '@/components/v2/atoms/Button'
import ImageMagnifiableModal from '@/components/v2/organisms/ImageModal'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import { find as _find } from 'lodash'
import { setPageMeta } from '@/containers/pageMetasSlice'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import WholeSubmitErrorMessage from '@/components/v2/molecules/WholeSubmitErrorMessage'
import LoadingOverlay from '@/components/v1/atoms/LoadingOverlay'
import clsx from 'clsx'
import SubmitErrorMessage from '@/components/v2/molecules/SubmitErrorMessage'
import { updateResetFlg } from '@/containers/futures/futureBasesSlice'

interface PublicPensionEditFormProps {
  person_flg: number
  onClose: () => void
  onSubmit: () => void
}

const PublicPensionEditForm: FC<PublicPensionEditFormProps> = (props) => {
  const dispatch = useDispatch()

  const { showFlashMsg } = useFlashAlert()
  const { inputPublicPensions, isLoading } = useSelector(
    (state: RootState) => state.inputPublicPensions,
  )

  const [isPensionImage1ModalOpen, setIsPensionImage1ModalOpen] = useState<boolean>(false)
  const [isPensionImage2ModalOpen, setIsPensionImage2ModalOpen] = useState<boolean>(false)

  const handleImageModalClose = () => {
    setIsPensionImage1ModalOpen(false)
    setIsPensionImage2ModalOpen(false)
  }

  const { person_flg } = props

  const targetInputPublicPension: any = useMemo(() => {
    return _find(inputPublicPensions, { person_flg: person_flg })
  }, [inputPublicPensions, person_flg])

  const isEdit = useMemo(() => !!targetInputPublicPension, [inputPublicPensions])

  const initialValues: any = useMemo(
    () =>
      isEdit
        ? {
            person_flg: person_flg,
            at_hand_pension_paper_type: AT_HAND_PENSION_PAPER_TYPE.yes as AtHandPensionPaperType,
            input_public_pension: {
              ...targetInputPublicPension,
              input_public_pension_receivings_attributes:
                targetInputPublicPension.input_public_pension_receivings.length > 0
                  ? targetInputPublicPension.input_public_pension_receivings
                  : [{}],
              input_at_year: extractYearOrMonthFromDate(
                targetInputPublicPension.input_at,
                'year',
              ),
              input_at_month: extractYearOrMonthFromDate(
                targetInputPublicPension.input_at,
                'month',
              ),
              national_pension_paid_years: Math.round(
                castNumberWithoutOperator(
                  targetInputPublicPension.national_pension_paid_months,
                ) / 12,
              ),
              welfare_pension_paid_years: Math.round(
                castNumberWithoutOperator(
                  targetInputPublicPension.welfare_pension_paid_months,
                ) / 12,
              ),
            },
          }
        : {
            input_public_pension: {
              input_public_pension_receivings_attributes: [{}],
            },
          },
    [inputPublicPensions, targetInputPublicPension, isEdit],
  )

  useEffect(() => {
    if (inputPublicPensions.length == 0) dispatch(fetchInputPublicPensionsV2())
  }, [])

  const handleSubmit = (values: InputPublicPensionCommonValues) => {
    const { at_hand_pension_paper_type, input_public_pension } = values
    const { input_type } = values.input_public_pension
    const newValues: InputPublicPensionCommonValues = {
      input_public_pension: {
        person_flg: person_flg,
        input_type: !_isUndefined(input_type)
          ? input_type
          : (INPUT_TYPE.other as InputType),
      },
    }

    newValues.input_public_pension.input_at =
      at_hand_pension_paper_type == AT_HAND_PENSION_PAPER_TYPE.no
        ? THIS_MONTH_NUMBER - 1 == 0
          ? `${THIS_YEAR_NUMBER - 1}/12/01`
          : `${THIS_YEAR_NUMBER}/${THIS_MONTH_NUMBER - 1}/01`
        : convertDateMonth(
            input_public_pension.input_at_year,
            input_public_pension.input_at_month,
          )

    // ねんきん定期便所持種別: 手元にあるを選択 かつ ねんきん定期便種別: 左の形式を選択
    if (
      at_hand_pension_paper_type == AT_HAND_PENSION_PAPER_TYPE.yes &&
      input_type == INPUT_TYPE.other
    ) {
      newValues.input_public_pension.national_pension_paid_amount =
        input_public_pension.national_pension_paid_amount
      newValues.input_public_pension.welfare_pension_paid_amount =
        input_public_pension.welfare_pension_paid_amount
      newValues.input_public_pension.national_pension_paid_months =
        input_public_pension.national_pension_paid_months
      newValues.input_public_pension.welfare_pension_paid_months =
        input_public_pension.welfare_pension_paid_months
      isEdit &&
        ((newValues.input_public_pension.input_public_pension_receivings_attributes = []),
        (newValues.input_public_pension.welfare_pension_average_monthly_income = null))
    }

    // ねんきん定期便所持種別: 手元にあるを選択 かつ ねんきん定期便種別: 右の形式を選択
    if (
      at_hand_pension_paper_type == AT_HAND_PENSION_PAPER_TYPE.yes &&
      input_type == INPUT_TYPE.overFifty
    ) {
      newValues.input_public_pension.input_public_pension_receivings_attributes =
        input_public_pension.input_public_pension_receivings_attributes
      isEdit &&
        ((newValues.input_public_pension.national_pension_paid_amount = null),
        (newValues.input_public_pension.welfare_pension_paid_amount = null),
        (newValues.input_public_pension.national_pension_paid_months = null),
        (newValues.input_public_pension.welfare_pension_paid_months = null),
        (newValues.input_public_pension.welfare_pension_average_monthly_income = null),
        (newValues.input_public_pension.welfare_pension_average_monthly_income = null))
    }

    // ねんきん定期便所持種別: 手元にないを選択
    if (at_hand_pension_paper_type == AT_HAND_PENSION_PAPER_TYPE.no) {
      newValues.input_public_pension.input_type = 'other'
      newValues.input_public_pension.welfare_pension_average_monthly_income = Math.round(
        castNumberWithoutOperator(
          input_public_pension.welfare_pension_average_monthly_income,
        ) / 12,
      )
      newValues.input_public_pension.national_pension_paid_months =
        castNumberWithoutOperator(input_public_pension.national_pension_paid_years) * 12
      newValues.input_public_pension.welfare_pension_paid_months =
        castNumberWithoutOperator(input_public_pension.welfare_pension_paid_years) * 12
      isEdit && (newValues.input_public_pension.national_pension_paid_amount = null)
    }

    const commonCallback = () => {
      showFlashMsg(isEdit ? '更新しました' : '作成しました')
      dispatch(fetchInputPublicPensionsV2())
      dispatch(updateResetFlg())
      props.onSubmit()
    }

    return isEdit
      ? dispatch(
          updateInputPublicPension({ ...newValues, person_flg: Number(person_flg) }, () =>
            commonCallback(),
          ),
        )
      : dispatch(createInputPublicPension(newValues, () => commonCallback()))
  }

  return (
    <>
      <LoadingOverlay isLoading={isLoading} />

      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        mutators={{ ...arrayMutators }}
        render={({
          handleSubmit,
          values,
          errors,
          submitErrors,
          submitFailed,
          form: {
            mutators: { push },
          },
        }) => {
          const { at_hand_pension_paper_type } = values
          const { input_type } = values.input_public_pension

          return (
            <form className="text-black-900" onSubmit={handleSubmit}>
              <div className="md:flex justify-between items-center py-20 text-14 border-b border-black-300">
                <Typography className="flex-1 pb-10 md:mr-10 md:pb-0">
                  ねんきん定期便の用紙は手元にありますか？
                </Typography>
                <div className="md:w-[40%] lg:w-[35%] flex items-baseline mt-8 md:mt-0">
                  <RadioGroup
                    name="at_hand_pension_paper_type"
                    isDispRow
                    datas={AT_HAND_PENSION_PAPER_TYPE_RADIO_ITEMS}
                    validate={required}
                  />
                </div>
              </div>

              {at_hand_pension_paper_type == AT_HAND_PENSION_PAPER_TYPE.yes && (
                <>
                  <div className="py-20 border-b border-black-300 text-14">
                    <Typography className="pb-10 md:pb-0">
                      お手元のねんきん定期便の形式
                    </Typography>
                    <div className="md:flex justify-between items-center pt-20">
                      <div className="flex-1 md:mr-10">
                        <div className="flex space-x-8">
                          <div>
                            <img
                              src={PensionPaper1}
                              alt="pension paper"
                              onClick={() => setIsPensionImage1ModalOpen(true)}
                            />
                          </div>
                          <div>
                            <img
                              src={PensionPaper2}
                              alt="pension paper"
                              width="850"
                              onClick={() => setIsPensionImage2ModalOpen(true)}
                            />
                          </div>
                        </div>
                        <Typography>
                          ※ 画像をクリックすると拡大イメージが表示されます
                        </Typography>
                      </div>
                      <div className="md:w-[40%] lg:w-[35%] mt-16 md:mt-0">
                        <RadioGroup
                          name="input_public_pension.input_type"
                          isDispRow
                          datas={INPUT_TYPE_RADIO_ITEMS}
                          validate={required}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="md:flex justify-between items-center py-20 border-b border-black-300">
                    <Typography className="flex-1 md:mr-10 pb-10 md:pb-0 text-14">
                      ねんきん定期便の発行年月
                    </Typography>
                    <div className="md:w-[40%] lg:w-[35%] flex items-baseline mt-8 md:mt-0 space-x-16">
                      <SelectBox
                        placeholder="年を選択"
                        name="input_public_pension.input_at_year"
                        options={buildTwoTypesRangeYearSelectItems(
                          THIS_YEAR_NUMBER - 5,
                          THIS_YEAR_NUMBER,
                          true,
                        )}
                        validate={required}
                      />
                      <SelectBox
                        placeholder="月を選択"
                        name="input_public_pension.input_at_month"
                        options={MONTH_KANJI_SELECT_ITEMS}
                        validate={required}
                      />
                    </div>
                  </div>

                  {(input_type == INPUT_TYPE.other ||
                    input_type == INPUT_TYPE.overFifty) && (
                    <>
                      <div className="py-20 space-y-16">
                        <Typography className="flex-1 pb-10 md:pb-0 text-14">
                          ねんきん定期便の用紙に従って入力を行ってください
                        </Typography>
                        <div className="lg:flex">
                          <div className="flex-1">
                            <div className="max-w-320 lg:max-w-fit mx-auto">
                              <img
                                src={
                                  input_type == INPUT_TYPE.other
                                    ? PensionPaper1
                                    : PensionPaper2
                                }
                                alt="pension paper"
                                onClick={() =>
                                  input_type == INPUT_TYPE.other
                                    ? setIsPensionImage1ModalOpen(true)
                                    : setIsPensionImage2ModalOpen(true)
                                }
                              />
                            </div>
                          </div>
                          <Typography className="text-14 text-center">
                            ※ 画像をクリックすると拡大イメージが表示されます
                          </Typography>
                        </div>
                      </div>
                      {input_type == INPUT_TYPE.other && (
                        <>
                          <div className="md:flex justify-between items-center py-20 border-b border-black-300">
                            <Typography className="flex-1 ml-16 md:mr-10 pb-10 md:pb-0 text-14">
                              ①これまでの厚生年金保険料の保険料納付額（累計額）の合計金額
                            </Typography>
                            <div className="md:w-[40%] lg:w-[35%] flex justify-end md:justify-start items-baseline">
                              <NumberField
                                name="input_public_pension.welfare_pension_paid_amount"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 10),
                                )}
                              />
                              <Typography className="ml-8 text-14">円</Typography>
                            </div>
                          </div>

                          <div className="md:flex justify-between items-center py-20 border-b border-black-300">
                            <Typography className="flex-1 ml-16 md:mr-10 pb-10 md:pb-0 text-14">
                              ②これまでの国民年金の加入期間
                            </Typography>
                            <div className="md:w-[40%] lg:w-[35%] flex justify-end md:justify-start items-baseline">
                              <NumberField
                                name="input_public_pension.national_pension_paid_months"
                                validate={composeValidators(required, zeroOrMoreNumber)}
                              />
                              <Typography className="ml-8 text-14">ヶ月</Typography>
                            </div>
                          </div>

                          <div className="md:flex justify-between items-center py-20 border-b border-black-300">
                            <Typography className="flex-1 ml-16 md:mr-10 pb-10 md:pb-0 text-14">
                              ③これまでの厚生年金保険の加入期間
                            </Typography>
                            <div className="md:w-[40%] lg:w-[35%] flex justify-end md:justify-start items-baseline">
                              <NumberField
                                name="input_public_pension.welfare_pension_paid_months"
                                validate={composeValidators(required, zeroOrMoreNumber)}
                              />
                              <Typography className="ml-8 text-14">ヶ月</Typography>
                            </div>
                          </div>
                        </>
                      )}

                      {input_type == INPUT_TYPE.overFifty && (
                        <>
                          <div className="mt-20 space-y-24">
                            <FieldArray name="input_public_pension.input_public_pension_receivings_attributes">
                              {({ fields }) =>
                                fields.map((name, index) => (
                                  <div>
                                    {index !== 0 && (
                                      <Button
                                        className="ml-auto mr-0 pr-8 text-24"
                                        onClick={() => fields.remove(index)}
                                      >
                                        <i className="ico close" />
                                      </Button>
                                    )}
                                    <div
                                      key={name}
                                      className="p-8 bg-primary-50 rounded-16"
                                    >
                                      <div className="bg-white">
                                        <div className="md:flex justify-between items-center px-8 md:px-16 py-20 border-b border-black-300">
                                          <Typography className="flex-1 pb-10 md:pb-0 text-14">
                                            ①受取開始年齢
                                          </Typography>
                                          <div className="md:w-[40%] lg:w-[35%] flex justify-end md:justify-start items-baseline">
                                            <NumberField
                                              name={`${name}affected_at_age`}
                                              validate={composeValidators(
                                                required,
                                                zeroOrMoreNumber,
                                              )}
                                            />
                                            <Typography className="ml-8 text-14">
                                              歳〜
                                            </Typography>
                                          </div>
                                        </div>

                                        <div className="md:flex justify-between items-center px-8 md:px-16 py-20 border-b border-black-300">
                                          <Typography className="flex-1 pb-10 md:pb-0 text-14">
                                            ②基礎年金
                                          </Typography>
                                          <div className="md:w-[40%] lg:w-[35%] flex justify-end md:justify-start items-baseline">
                                            <NumberField
                                              name={`${name}receiving_public_pension_amount`}
                                              validate={composeValidators(
                                                required,
                                                zeroOrMoreNumber,
                                                (value: any) =>
                                                  commaNumberMaxLength(value, 10),
                                              )}
                                            />
                                            <Typography className="ml-8 text-14">
                                              円
                                            </Typography>
                                          </div>
                                        </div>

                                        <div className="md:flex justify-between items-center px-8 md:px-16 py-20 border-b border-black-300">
                                          <div className="flex-1 md:mr-10 pb-10 md:pb-0">
                                            <Typography className="text-14">
                                              ③厚生年金
                                            </Typography>
                                            <Typography className="mt-10 text-12 text-black-700">
                                              ※受給開始年齢ごとの合計額（報酬比例部分＋経過的加算部分）をご記入ください
                                            </Typography>
                                          </div>
                                          <div className="md:w-[40%] lg:w-[35%] flex justify-end md:justify-start items-baseline">
                                            <NumberField
                                              name={`${name}receiving_welfare_pension_amount`}
                                              validate={composeValidators(
                                                required,
                                                zeroOrMoreNumber,
                                                (value: any) =>
                                                  commaNumberMaxLength(value, 10),
                                              )}
                                            />
                                            <Typography className="ml-8 text-14">
                                              円
                                            </Typography>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))
                              }
                            </FieldArray>
                          </div>
                          <Button
                            className="w-full h-53 mt-16 md:mt-32 border border-primary-400 border-dashed rounded-4 hover:bg-black-200"
                            onClick={() =>
                              push(
                                'input_public_pension.input_public_pension_receivings_attributes',
                                {},
                              )
                            }
                          >
                            <PlusIcon className="mr-8" />
                            <Typography className="text-14 text-primary-500" isBold>
                              入力枠を追加する
                            </Typography>
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
              {at_hand_pension_paper_type == AT_HAND_PENSION_PAPER_TYPE.no && (
                <>
                  <div className="md:flex justify-between items-center py-20 border-b border-black-300">
                    <div className="flex-1 md:mr-10 pb-10 md:pb-0">
                      <Typography className="text-14">
                        現在までの国民年金の総加入期間を教えてください。(年)
                      </Typography>
                      <Typography className="mt-10 text-12 text-black-700">
                        ※ 国民年金とは、学生時代、会社員ではなかった時の年金、
                        <span className="inline-block">会社員の扶養に</span>
                        <span className="inline-block">入っていた期間に</span>
                        <span className="inline-block">加入している年金のことです。</span>
                      </Typography>
                    </div>
                    <div className="md:w-[40%] lg:w-[35%] flex justify-end md:justify-start items-baseline mt-8 md:mt-0 space-x-16">
                      <NumberField
                        name="input_public_pension.national_pension_paid_years"
                        validate={composeValidators(
                          required,
                          zeroOrMoreNumber,
                          oneHundredLessThanNumber,
                        )}
                      />
                      <Typography className="ml-8 text-14">年</Typography>
                    </div>
                  </div>

                  <div className="md:flex justify-between items-center py-20 border-b border-black-300">
                    <div className="flex-1 md:mr-10 pb-10 md:pb-0">
                      <Typography className="text-14">
                        これまでの厚生（共済）年金の総加入期間を教えてください。(年)
                      </Typography>
                      <Typography className="mt-10 text-12 text-black-700">
                        ※ 厚生（共済）年金とは、会社員、役員、公務員の時に加入している
                        <span className="inline-block">年金のことです。</span>
                      </Typography>
                    </div>
                    <div className="md:w-[40%] lg:w-[35%] flex justify-end md:justify-start items-baseline mt-8 md:mt-0 space-x-16">
                      <NumberField
                        name="input_public_pension.welfare_pension_paid_years"
                        validate={composeValidators(
                          required,
                          zeroOrMoreNumber,
                          oneHundredLessThanNumber,
                        )}
                      />
                      <Typography className="ml-8 text-14">年</Typography>
                    </div>
                  </div>

                  <div className="md:flex justify-between items-center py-20 border-b border-black-300">
                    <Typography className="flex-1 md:mr-10 pb-10 md:pb-0 text-14">
                      厚生年金加入期間のボーナスを含む平均年収を入力してください。
                    </Typography>
                    <div className="md:w-[40%] lg:w-[35%] flex justify-end md:justify-start items-baseline mt-8 md:mt-0 space-x-16">
                      <NumberField
                        name="input_public_pension.welfare_pension_average_monthly_income"
                        validate={composeValidators(
                          required,
                          zeroOrMoreNumber,
                          (value: any) => commaNumberMaxLength(value, 10),
                        )}
                      />
                      <Typography className="ml-8 text-14">円</Typography>
                    </div>
                  </div>
                </>
              )}

              <WholeSubmitErrorMessage submitErrors={submitErrors} />
              <SubmitErrorMessage show={!submitErrors && submitFailed} />
              <ButtonPrimary
                className={clsx(
                  { ['opacity-50']: Object.keys(errors).length > 0 },
                  'w-[246px] h-[64px] mx-auto my-[16px] ',
                )}
                isDisabled={isLoading}
                onClick={() => handleSubmit(values)}
              >
                <Typography
                  className="relative pl-30 text-16 text-white flex-auto"
                  isBold
                >
                  保存する
                </Typography>
                <ChevronRightIcon className="mr-17" />
              </ButtonPrimary>
              <Button
                className="mx-auto text-14 text-black-700"
                onClick={props.onClose}
                isDisabled={isLoading}
              >
                キャンセル
              </Button>
            </form>
          )
        }}
      />
      <ImageMagnifiableModal
        src={PensionPaper1}
        alt="pension paper1"
        isOpen={isPensionImage1ModalOpen}
        onClose={handleImageModalClose}
      />
      <ImageMagnifiableModal
        src={PensionPaper2}
        alt="pension paper2"
        isOpen={isPensionImage2ModalOpen}
        onClose={handleImageModalClose}
      />
    </>
  )
}

export default PublicPensionEditForm
