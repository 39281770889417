import {
  AT_HAND_PENSION_PAPER_TYPE,
  INPUT_TYPE,
} from '../../../models/inputs/inputPublicPensionsModelFunc'

// ねんきん定期便所持種別
export const AT_HAND_PENSION_PAPER_TYPE_RADIO_ITEMS = [
  { value: AT_HAND_PENSION_PAPER_TYPE.yes, label: '手元にある' },
  { value: AT_HAND_PENSION_PAPER_TYPE.no, label: '手元にない' },
]

// ねんきん定期便種別
export const INPUT_TYPE_RADIO_ITEMS = [
  { value: INPUT_TYPE.other, label: '左の形式' },
  { value: INPUT_TYPE.overFifty, label: '右の形式' },
]
