import ApiClient from '@/api/apiClient'
import {
  AccountResponse,
  AccountShowInputResponse,
  AccountLoginResponse,
  AccountLoginValues,
  AccountCreateValues,
  AccountUpdateValues,
  AccountPasswordUpdateValues,
  AccountPasswordHandOverValues,
  AccountPasswordResetValues,
  AccountDeactivateResponse,
  AccountShowFutureItem,
  AccountRegistryStatus,
  AccountStepStatus,
  AccountInputInsuranceOption,
} from '@/models/accountsModel'
import { DeactivationFormResponseCreateValues } from '@/models/deactivationFormResponsesModel'
import { InputHousingOption } from '@/models/inputs/inputHousingsModel'

export const getAccount = async () => {
  return await ApiClient.get('/accounts/show', (response: AccountResponse) => response)
}

export const getAccountShowInvited = async () => {
  return await ApiClient.get('/accounts/show_invited', (response: any) => response)
}

export const getAccountShowInput = async () => {
  return await ApiClient.get(
    '/accounts/show_inputs',
    (accountShowInput: AccountShowInputResponse) => accountShowInput,
  )
}

export const postAccountLogin = async (values: AccountLoginValues) => {
  return await ApiClient.post(
    '/accounts/login',
    (account: AccountLoginResponse) => account,
    values,
  )
}

export const postAccountLogout = async () => {
  return await ApiClient.post('/accounts/logout', () => {})
}

export const postAccount = async (values: AccountCreateValues) => {
  return await ApiClient.post('/accounts', () => {}, values)
}

export const postAccountPasswordReset = async (values: AccountPasswordResetValues) => {
  return await ApiClient.post('/accounts/password_reset', () => {}, values)
}

export const postAccountResendPasswordReset = async (
  values: AccountPasswordResetValues,
) => {
  return await ApiClient.post('/accounts/resend_setup_mail', () => {}, values)
}

export const postAccountBalance = async () => {
  return await ApiClient.post('/accounts/update_balance', () => {})
}

export const postAccountActivate = async (values: AccountPasswordResetValues) => {
  return await ApiClient.post('/accounts/activate', () => {}, values)
}

export const postAccountDeactivate = async (
  values: DeactivationFormResponseCreateValues,
) => {
  return await ApiClient.post(
    '/accounts/deactivate',
    (response: AccountDeactivateResponse) => response,
    values,
  )
}

export const patchAccount = async (values: AccountUpdateValues) => {
  return await ApiClient.patch('/accounts/update', () => {}, values)
}

export const patchAccountPasswordSetup = async (values: AccountPasswordUpdateValues) => {
  return await ApiClient.patch('/accounts/account_password_setup', () => {}, values)
}

export const patchAccountPassword = async (values: AccountPasswordUpdateValues) => {
  return await ApiClient.patch('/accounts/password_update', () => {}, values)
}

export const patchAccountPasswordHandOver = async (
  values: AccountPasswordHandOverValues,
) => {
  return await ApiClient.patch('/accounts/account_hand_over', () => {}, values)
}

export const getAccountFutureItems = async (futureBaseId?: number) => {
  const params = { params: { future_base_id: futureBaseId } }
  return await ApiClient.get(
    '/accounts/show_future_items',
    (response: AccountShowFutureItem) => response,
    futureBaseId != null ? params : undefined,
    false,
    'v2',
  )
}

export const getAccountRegistryStatusV2 = async () => {
  return await ApiClient.get(
    '/accounts/show_registry_status',
    (response: AccountRegistryStatus) => response,
    undefined,
    false,
    'v2',
  )
}

export const getAccountInputInsuranceOptionsV2 = async () => {
  return await ApiClient.get(
    '/accounts/input_insurance_options',
    (response: AccountInputInsuranceOption[]) => response,
    undefined,
    false,
    'v2',
  )
}

export const getAccountInputHousingOptionsV2 = async () => {
  return await ApiClient.get(
    '/accounts/input_housing_options',
    (response: InputHousingOption[]) => response,
    undefined,
    false,
    'v2',
  )
}

export const getAccountShowStepStatusV2 = async () => {
  return await ApiClient.get(
    '/accounts/show_step_status',
    (response: AccountStepStatus) => response,
    undefined,
    false,
    'v2',
  )
}

export const patchAccountSummaryCheckStatusV2 = async () => {
  return await ApiClient.patch(
    '/accounts/update_summary_check_status',
    (response: AccountResponse) => response,
    undefined,
    false,
    'v2',
  )
}

export const patchAccountIsWithoutMoneytreeV2 = async () => {
  return await ApiClient.patch(
    '/accounts/update_is_without_moneytree',
    (response: AccountResponse) => response,
    undefined,
    false,
    'v2',
  )
}

export const patchAccountHasCreatedFirstPlanV2 = async () => {
  return await ApiClient.patch(
    '/accounts/update_has_created_first_plan',
    (response: AccountResponse) => response,
    undefined,
    false,
    'v2',
  )
}
