import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { unauthorizedCheck } from '../accountsSlice'
import {
  patchInputPublicPension,
  postInputPublicPension,
  deleteInputPublicPension,
  getInputPublicPensionV2,
} from '../../api/inputs/inputPublicPensions'
import { ApiError } from '../../models/commonsModel'
import {
  InputPublicPension,
  InputPublicPensionCreateValues,
  InputPublicPensionUpdateValues,
  InputPublicPensionDestroyValues,
} from '../../models/inputs/inputPublicPensionsModel'
import { AppThunk } from '../../store'

interface InputPublicPensionState {
  inputPublicPensions: InputPublicPension[]
  isLoading: boolean
}

const initialState: InputPublicPensionState = {
  inputPublicPensions: [],
  isLoading: false,
}

const inputPublicPensionsSlice = createSlice({
  name: 'inputPublicPensions',
  initialState,
  reducers: {
    setInputPublicPension: (state, action: PayloadAction<InputPublicPension[]>) => {
      state.inputPublicPensions = action.payload
      return state
    },
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    resetInputPublicPensionsStore: (state) => {
      state = initialState
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
  },
})

export const {
  setInputPublicPension,
  requestStart,
  resetInputPublicPensionsStore,
  requestSuccess,
  requestFailure,
} = inputPublicPensionsSlice.actions
export const inputPublicPensionsReducer = inputPublicPensionsSlice.reducer

export const createInputPublicPension = (
  values: InputPublicPensionCreateValues,
  callback: () => void,
  resolve?: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postInputPublicPension(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error, resolve))
    })
}

export const updateInputPublicPension = (
  values: InputPublicPensionUpdateValues,
  callback: () => void,
  resolve?: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchInputPublicPension(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error, resolve))
    })
}

export const destroyInputPublicPension = (
  values: { params: InputPublicPensionDestroyValues },
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  deleteInputPublicPension(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const fetchInputPublicPensionsV2 = (callback?: () => void): AppThunk => async (
  dispatch,
) => {
  dispatch(requestStart())
  getInputPublicPensionV2()
    .then((response) => {
      dispatch(requestSuccess())
      dispatch(setInputPublicPension(response))
      callback && callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}
