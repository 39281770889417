import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { push } from 'connected-react-router'
import {
  isEmpty as _isEmpty,
  isUndefined as _isUndefined,
  sortBy as _sortBy,
} from 'lodash'
import LogRocket from 'logrocket'
import {
  getAccount,
  getAccountShowInvited,
  getAccountShowInput,
  postAccountLogin,
  postAccountLogout,
  postAccount,
  postAccountPasswordReset,
  postAccountBalance,
  patchAccount,
  patchAccountPasswordSetup,
  patchAccountPassword,
  postAccountActivate,
  postAccountDeactivate,
  postAccountResendPasswordReset,
  patchAccountPasswordHandOver,
  getAccountFutureItems,
  getAccountRegistryStatusV2,
  getAccountInputInsuranceOptionsV2,
  getAccountInputHousingOptionsV2,
  getAccountShowStepStatusV2,
  patchAccountSummaryCheckStatusV2,
  patchAccountIsWithoutMoneytreeV2,
  patchAccountHasCreatedFirstPlanV2,
} from '@/api/accounts'
import apiClient from '@/api/apiClient'
import { getAccountPayment } from '@/api/accountPayments'
import { getAdminAccount } from '@/api/admins/accounts'
import { setFamilyStructures, setFamilyInfo } from '@/containers/familyStructuresSlice'
import { setCompanySalaryBase } from '@/containers/admins/companySalaryBasesSlice'
import { setFutureV2Housings } from '@/containers/futures/futureV2HousingsSlice'
import { setFutureLendHousings } from '@/containers/futures/futureLendHousingsSlice'
import { StatementTableData } from '@/models/futures/futureBasesModel'
import { setInputCars } from '@/containers/inputs/inputCarsSlice'
import { setInputCorporatePensions } from '@/containers/inputs/inputCorporatePensionsSlice'
import { setInputEstateInvestment } from '@/containers/inputs/inputEstateInvestmentsSlice'
import {
  setInputHousingOptions,
  setInputHousings,
} from '@/containers/inputs/inputHousingsSlice'
import {
  setInputInsuranceOptions,
  setInputInsurances,
} from '@/containers/inputs/inputInsurancesSlice'
import { setInputAssets } from '@/containers/inputs/inputAssetsSlice'
import { setInputAssetInvestments } from '@/containers/inputs/inputAssetInvestmentsSlice'
import { setInputContributionPensions } from '@/containers/inputs/inputContributionPensionsSlice'
import { setInputMonthlyCost } from '@/containers/inputs/inputMonthlyCostsSlice'
import { setInputPlanTuition } from '@/containers/inputs/inputPlanTuitionsSlice'
import { setInputPublicPension } from '@/containers/inputs/inputPublicPensionsSlice'
import { setInputSalary } from '@/containers/inputs/inputSalariesSlice'
import { setInputIncomeBases } from '@/containers/inputs/inputIncomesSlice'
import { setInputSubIncome } from '@/containers/inputs/inputSubIncomesSlice'
import { setInputYearCosts } from '@/containers/inputs/inputYearCostsSlice'
import { setInputYearlyLivingCost } from '@/containers/inputs/inputYearlyLivingCostsSlice'
import { setInputCustomItems } from '@/containers/inputs/inputCustomItemsSlice'
import { setInputRisk } from '@/containers/inputs/inputRisksSlice'
import { InputInsuranceOption } from '@/models/inputs/inputInsurancesModel'
import { InputHousingOption } from '@/models/inputs/inputHousingsModel'
import {
  AccountResponse,
  AccountLoginResponse,
  AccountShowInputResponse,
  AccountLoginValues,
  AccountCreateValues,
  AccountUpdateValues,
  AccountPasswordUpdateValues,
  AccountPasswordHandOverValues,
  AccountPasswordResetValues,
  AccountPayment,
  AccountPaymentResponse,
  AdminAccount,
  AdminAccountResponse,
  AccountDeactivateResponse,
  AccountRegistryStatus,
  AccountStepStatus,
  AccountShowFutureItem,
  AccountStateType,
} from '@/models/accountsModel'
import { isNotEmptyValue } from '@/models/commonsModelFunc'
import { ApiError, PartiallyPartial, TableColumnData } from '@/models/commonsModel'
import {
  DeactivationFormResponse,
  DeactivationFormResponseCreateValues,
} from '@/models/deactivationFormResponsesModel'
import { replaceDeactivationFormResponseValues } from '@/models/deactivationFormResponsesModelFunc'
import { SEX } from '@/models/familyStructuresModelFunc'
import { AppThunk } from '@/store'
import ChannelService from '@/utils/ChannelService'
import {
  setStorageAuthHeaders,
  removeStorageAuthHeaders,
} from '@/utils/localStorage/authHeadersStorage'
import { DeviseType, getDeviseType } from '@/utils/userAgent'
import { setInputEstateInvestmentTaxSetting } from './inputs/inputEstateInvestmentTaxSettingsSlice'

interface AccountState {
  account: AccountStateType
  deactivationFormResponse: Partial<Omit<DeactivationFormResponse, 'id' | 'account_id'>>
  accountPayment: AccountPayment | null
  adminUnassignedAccounts: AdminAccount[]
  financialAssetTotal: number | null
  financialAssetTotalBank: number | null
  financialAssetTotalOther: number | null
  financialAssetTotalStock: number | null
  financialAssetTotalTrustFund: number | null
  yearlyIncomeTotal: number | null
  monthlyLivingCost: number | null
  yearlyLivingCost: number | null
  monthlyHousingLoanPayment: number | null
  yearlyHousingLoanPayment: number | null
  monthlyInsurancePayment: number | null
  yearlyInsurancePayment: number | null
  insuranceIncomes: { person: number | null; spouse: number | null }
  input_statement_table_data: {
    [key: number]: Partial<StatementTableData>
  }
  family_structure_table_columns: PartiallyPartial<TableColumnData, 'nested_columns'>[]
  isLoading: boolean
  isGetAccountShowInputLoading: boolean
  isGetFutureItemsLoading: boolean
  isLogin: boolean
  deviseType: DeviseType
  futureItems: AccountShowFutureItem | null
  registryStatus: AccountRegistryStatus | null
  stepStatus: AccountStepStatus | null
}

const initialState: AccountState = {
  account: {
    id: 0,
    mail_address: '',
    name_sei: '',
    name_mei: '',
    name_sei_kana: '',
    name_mei_kana: '',
    sex: SEX.men,
    tel_number: null,
    postal_code: null,
    prefectures: null,
    address1: null,
    address2: null,
    mt_connection_status: 'unconnected',
    'has_connected_with_moneytree?': false,
    'has_registered_payment_method?': false,
    'is_trial_account?': false,
    'is_disabled_trial_account?': false,
    'is_paid_account?': false,
    'is_temporary?': false,
    'is_disabled_paid_account?': false,
    'trial_endedis_disabled_paid_account?': false,
    'is_learning_platform_enabled?': false,
    'is_done_with_steps?': false,
    'is_done_with_simple_data?': false,
    'is_done_with_required_input?': false,
    'is_demo_account?': false,
    is_without_moneytree: false,
    has_created_first_plan: false,
    learning_platform_password: '',
    trial_end_at: '',
    disclosed_type: '',
    disclosed_stop_at: '',
    account_payment_information: null,
    account_payment_contract: null,
    robot_payment_execution_histories: [],
    manipulated_type: null,
    manipulated_stop_at: null,
    'has_spouse?': false,
  },
  deactivationFormResponse: {
    reason: '',
    good_point: '',
    bad_point: '',
    agreement_conditions: {
      immediate_deactivate_agreement: false,
      paid_charges_agreement: false,
      unreachable_data_agreement: false,
      nostack_data_agreement: false,
      restore_data_agreement: false,
    },
    agreement_conditions_arr: [],
  },
  accountPayment: null,
  adminUnassignedAccounts: [],
  financialAssetTotal: null,
  financialAssetTotalBank: null,
  financialAssetTotalOther: null,
  financialAssetTotalStock: null,
  financialAssetTotalTrustFund: null,
  yearlyIncomeTotal: null,
  monthlyLivingCost: null,
  yearlyLivingCost: null,
  monthlyHousingLoanPayment: null,
  yearlyHousingLoanPayment: null,
  monthlyInsurancePayment: null,
  yearlyInsurancePayment: null,
  insuranceIncomes: { person: null, spouse: null },
  input_statement_table_data: {},
  family_structure_table_columns: [],
  isLoading: false,
  isGetAccountShowInputLoading: false,
  isGetFutureItemsLoading: false,
  isLogin: false,
  deviseType: 'pc',
  futureItems: null,
  registryStatus: null,
  stepStatus: null,
}

const accountsSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    setLogind: (state) => {
      state.isLogin = true
      state.deviseType = getDeviseType()
      return state
    },
    setAccount: (state, action: PayloadAction<AccountCreateValues>) => {
      state.account = action.payload
      return state
    },
    setDeactivationFormResponse: (
      state,
      action: PayloadAction<DeactivationFormResponseCreateValues>,
    ) => {
      state.deactivationFormResponse = action.payload.deactivation_form_response
      return state
    },
    clearAccountState: (state) => {
      state = initialState
      return state
    },
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    getAccountShowInputStart: (state) => {
      state.isGetAccountShowInputLoading = true
      return state
    },
    getFutureItemsStart: (state) => {
      state.isGetFutureItemsLoading = true
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    getAccountSuccess: (state, action: PayloadAction<AccountResponse>) => {
      state.account = action.payload
      state.isLoading = false
      return state
    },
    getAdminUnassignedAccountsSuccess: (
      state,
      action: PayloadAction<AdminAccountResponse[]>,
    ) => {
      state.adminUnassignedAccounts = action.payload
      state.isLoading = false
      return state
    },
    getAccountShowInputSuccess: (
      state,
      action: PayloadAction<AccountShowInputResponse>,
    ) => {
      const { payload } = action
      state.financialAssetTotal = payload.financial_asset_total
      state.financialAssetTotalBank = payload.financial_asset_total_bank
      state.financialAssetTotalOther = payload.financial_asset_total_other
      state.financialAssetTotalStock = payload.financial_asset_total_stock
      state.financialAssetTotalTrustFund = payload.financial_asset_total_trust_fund
      state.yearlyIncomeTotal = payload.yearly_income_total
      state.monthlyLivingCost = payload.monthly_living_cost
      state.yearlyLivingCost = payload.yearly_living_cost
      state.monthlyHousingLoanPayment = payload.monthly_housing_loan_payment
      state.yearlyHousingLoanPayment = payload.yearly_housing_loan_payment
      state.monthlyInsurancePayment = payload.monthly_insurance_payment
      state.yearlyInsurancePayment = payload.yearly_insurance_payment
      state.insuranceIncomes = payload.insurance_incomes
      state.input_statement_table_data = payload.input_statement_table_data
      state.family_structure_table_columns = payload.family_structure_table_columns
      state.isGetAccountShowInputLoading = false
      return state
    },
    getAccountPaymentSuccess: (state, action: PayloadAction<AccountPaymentResponse>) => {
      state.accountPayment = action.payload
      state.isLoading = false
      return state
    },
    postAccountLogoutSuccess: (state) => {
      state.isLoading = false
      state.isLogin = false
      return state
    },
    getAccountFutureItemsSuccess: (
      state,
      action: PayloadAction<AccountShowFutureItem>,
    ) => {
      const {
        future_family_bases,
        future_monthly_living_cost_bases,
        future_assets,
        future_v2_housings,
      } = action.payload
      const futureFamilyBases = future_family_bases.map((item) => ({
        ...item,
        future_family_childbirths: _sortBy(
          item.future_family_childbirths,
          'childbirth_index',
        ),
      }))
      const futureMonthlyLivingCostBases = future_monthly_living_cost_bases.map(
        (item) => ({
          ...item,
          future_monthly_living_cost_changes: _sortBy(
            item.future_monthly_living_cost_changes,
            'affected_start_at_age',
          ),
        }),
      )
      const futureAssets = _sortBy(
        future_assets?.map((asset) => ({
          ...asset,
          asset_annuals: _sortBy(asset.asset_annuals, 'affected_at'),
        })),
        'id',
      )
      const futureV2Housings = future_v2_housings.map((item) => {
        if (isNotEmptyValue(item.future_v2_housing_self_owned)) {
          return {
            ...item,
            model_type: 'FutureV2Housing',
            future_v2_housing_self_owned: {
              ...item.future_v2_housing_self_owned!,
              future_v2_housing_loans: _isEmpty(
                item.future_v2_housing_self_owned!.future_v2_housing_loans,
              )
                ? []
                : _sortBy(
                    item.future_v2_housing_self_owned!.future_v2_housing_loans,
                    'id',
                  ),
            },
          }
        }

        return {
          ...item,
          model_type: 'FutureV2Housing',
        }
      })

      state.futureItems = {
        ...action.payload,
        future_family_bases: futureFamilyBases,
        future_monthly_living_cost_bases: futureMonthlyLivingCostBases,
        future_assets: futureAssets,
        future_v2_housings: futureV2Housings,
      }
      state.isGetFutureItemsLoading = false
      return state
    },
    getAccountRegistryStatusV2Success: (
      state,
      action: PayloadAction<AccountRegistryStatus>,
    ) => {
      state.registryStatus = action.payload
      state.isLoading = false
      return state
    },
    getAccountShowStepStatusV2Success: (
      state,
      action: PayloadAction<AccountStepStatus>,
    ) => {
      state.stepStatus = action.payload
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      state.isGetAccountShowInputLoading = false
      return state
    },
  },
})

export const {
  setLogind,
  setAccount,
  setDeactivationFormResponse,
  clearAccountState,
  requestStart,
  getAccountShowInputStart,
  getFutureItemsStart,
  getAccountPaymentSuccess,
  requestSuccess,
  getAccountSuccess,
  getAdminUnassignedAccountsSuccess,
  getAccountShowInputSuccess,
  postAccountLogoutSuccess,
  getAccountFutureItemsSuccess,
  getAccountRegistryStatusV2Success,
  getAccountShowStepStatusV2Success,
  requestFailure,
} = accountsSlice.actions
export const accountsReducer = accountsSlice.reducer

export const setLogindFunc = (): AppThunk => async (dispatch) => {
  dispatch(setLogind())
  dispatch(requestStart())

  if (process.env.NODE_ENV === 'production') {
    LogRocket.init('8riiec/moneysol')
  }

  getAccount()
    .then((response: AccountResponse) => dispatch(getAccountSuccess(response)))
    .catch(() => dispatch(requestFailure()))
}

export const fetchAccount = (): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  getAccount()
    .then((response: AccountResponse) => {
      dispatch(getAccountSuccess(response))
    })
    .catch(() => {
      dispatch(requestFailure())
    })
}

export const fetchAccountShowInvited = (callback?: () => void): AppThunk => async (
  dispatch,
) => {
  dispatch(requestStart())
  getAccountShowInvited()
    .then((response) => {
      dispatch(requestSuccess())
      dispatch(setAccount(response))
    })
    .catch(() => {
      dispatch(requestFailure())
      callback && callback()
    })
}

const commonFetchAccountShowInputFunc = (
  response: AccountShowInputResponse,
  dispatch: (response: any) => void,
) => {
  dispatch(getAccountShowInputSuccess(response))
  dispatch(setCompanySalaryBase(response.company_salary_base))
  dispatch(setFamilyStructures(response.family_structure))
  dispatch(setFamilyInfo(response.family_info))
  dispatch(setInputAssets(response.input_assets))
  dispatch(setInputAssetInvestments(response.input_asset_investments))
  dispatch(setInputMonthlyCost(response.input_monthly_cost))
  dispatch(setInputYearCosts(response.input_year_cost))
  dispatch(
    setInputYearlyLivingCost({
      ceremonies: response.input_yearly_living_cost_ceremonies,
      electronics: response.input_yearly_living_cost_electronics,
      homeComings: response.input_yearly_living_cost_home_comings,
      travels: response.input_yearly_living_cost_travels,
    }),
  )
  dispatch(setInputCars(response.input_cars))
  dispatch(setInputCustomItems(response.input_custom_items))
  dispatch(setInputCorporatePensions(response.input_corporate_pensions))
  dispatch(setInputContributionPensions(response.input_contribution_pensions))
  dispatch(setInputHousings(response.input_housings))
  dispatch(setInputHousingOptions(response.input_housing_options))
  dispatch(setInputInsurances(response.input_insurances))
  dispatch(setInputInsuranceOptions(response.input_insurance_options))
  dispatch(setInputPlanTuition(response.family_structure.family_structure_children))
  dispatch(setInputPublicPension(response.input_public_pensions))
  dispatch(setInputEstateInvestment(response.input_estate_investments))
  dispatch(setInputSalary(response.input_salaries))
  dispatch(setInputIncomeBases(response.input_income_bases))
  dispatch(setInputSubIncome(response.input_sub_incomes))
  dispatch(setInputRisk(response.input_risks))
  dispatch(setInputEstateInvestmentTaxSetting(response.input_estate_investment_tax_setting))
}

export const fetchAccountShowInput = (): AppThunk => async (dispatch) => {
  dispatch(getAccountShowInputStart())
  getAccountShowInput()
    .then((response: AccountShowInputResponse) => {
      commonFetchAccountShowInputFunc(response, dispatch)
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const fetchAccountPayment = (): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  getAccountPayment()
    .then((response: AccountPaymentResponse) => {
      dispatch(getAccountPaymentSuccess(response))
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const fetchAdminAccount = (): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  getAdminAccount()
    .then((response: AccountResponse) => {
      dispatch(getAccountSuccess(response))
    })
    .catch(() => {
      dispatch(requestFailure())
    })
}

export const accountLogin = (
  values: AccountLoginValues,
  callback: (response: AccountLoginResponse) => void,
  resolve: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postAccountLogin(values)
    .then((response: AccountLoginResponse) => {
      dispatch(requestSuccess())
      const headers = setStorageAuthHeaders(response.token)
      apiClient.setAuthHeaders(headers)

      dispatch(setLogindFunc())
      callback(response)
    })
    .catch((error) => {
      dispatch(requestFailure())
      resolve(error)
    })
}

export const accountLogout = (redirectUrl?: string): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postAccountLogout()
    .then(() => {
      dispatch(postAccountLogoutSuccess())
      removeStorageAuthHeaders()

      apiClient.setAuthHeaders({})
      ChannelService.shutdown()

      !_isUndefined(redirectUrl) && dispatch(push(`/v2?redirectUrl=${redirectUrl}`))
    })
    .catch(() => {
      dispatch(requestFailure())
    })
}

export const accountLogoutV2 = (redirectUrl?: string): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postAccountLogout()
    .then(() => {
      dispatch(postAccountLogoutSuccess())
      removeStorageAuthHeaders()

      apiClient.setAuthHeaders({})
      ChannelService.shutdown()

      !_isUndefined(redirectUrl) && dispatch(push(`/v2?redirectUrl=${redirectUrl}`))
    })
    .catch(() => {
      dispatch(requestFailure())
    })
}

export const createAccount = (
  values: AccountCreateValues,
  callback: () => void,
  resolve: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postAccount(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      resolve(error)
    })
}

export const createAccountActivate = (
  values: AccountPasswordResetValues,
  resolve: (error: any) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postAccountActivate(values)
    .then(() => {
      dispatch(requestSuccess())
      resolve(undefined)
    })
    .catch((error) => {
      dispatch(requestFailure())
      resolve(error)
    })
}

export const createAccountDeactivateResponse = (
  values: DeactivationFormResponseCreateValues,
  successCallback: (response: AccountDeactivateResponse) => void,
  errorCallback: (response: AccountDeactivateResponse) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postAccountDeactivate(replaceDeactivationFormResponseValues(values))
    .then((response: AccountDeactivateResponse) => {
      dispatch(requestSuccess())
      removeStorageAuthHeaders()
      clearAccountState()
      successCallback(response)
    })
    .catch((response: AccountDeactivateResponse) => {
      dispatch(requestFailure())
      errorCallback(response)
    })
}

export const createAccountPasswordReset = (
  values: AccountPasswordResetValues,
  resolve: (error: any) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postAccountPasswordReset(values)
    .then(() => {
      dispatch(requestSuccess())
      resolve(undefined)
    })
    .catch((error) => {
      dispatch(requestFailure())
      resolve(error)
    })
}

export const createAccountResendPasswordReset = (
  values: AccountPasswordResetValues,
  resolve: (error: any) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postAccountResendPasswordReset(values)
    .then(() => {
      dispatch(requestSuccess())
      resolve(undefined)
    })
    .catch((error) => {
      dispatch(requestFailure())
      resolve(error)
    })
}

export const createAccountBalance = (callback: () => void): AppThunk => async (
  dispatch,
) => {
  dispatch(requestStart())
  postAccountBalance()
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
    })
}

export const updateAccount = (
  values: AccountUpdateValues,
  callback: () => void,
  resolve: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchAccount(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      resolve(error)
    })
}

export const updateAccountPasswordSetup = (
  values: AccountPasswordUpdateValues,
  callback: () => void,
  resolve: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchAccountPasswordSetup(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      resolve(error)
    })
}

export const updateAccountPassword = (
  values: AccountPasswordUpdateValues,
  callback: () => void,
  resolve: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchAccountPassword(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      resolve(error)
    })
}

export const updateAccountPasswordHandOver = (
  values: AccountPasswordHandOverValues,
  callback: () => void,
  resolve: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchAccountPasswordHandOver(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      resolve(error)
    })
}

export const unauthorizedCheck = (
  error: ApiError,
  resolve?: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  if (error?.isUnauthorized) return dispatch(accountLogout(location.pathname))
  if (error?.isPaymentRequired) return dispatch(push('/v2/settings/account'))

  !_isUndefined(resolve) && resolve(error)
}

export const fetchAccountFutureItems = (futureBaseId?: number): AppThunk => async (
  dispatch,
) => {
  dispatch(getFutureItemsStart())
  getAccountFutureItems(futureBaseId)
    .then((response: AccountShowFutureItem) => {
      dispatch(setFutureV2Housings(response.future_v2_housings))
      dispatch(setFutureLendHousings(response.future_lend_housings))
      dispatch(getAccountFutureItemsSuccess(response))
    })
    .catch(() => {
      dispatch(requestFailure())
    })
}

export const fetchAccountRegistryStatusV2 = (): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  getAccountRegistryStatusV2()
    .then((response: AccountRegistryStatus) =>
      dispatch(getAccountRegistryStatusV2Success(response)),
    )
    .catch((error) => {
      dispatch(requestFailure())
    })
}

export const fetchAccountInputInsuranceOptionsV2 = (): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  getAccountInputInsuranceOptionsV2()
    .then((response: InputInsuranceOption[]) => {
      dispatch(setInputInsuranceOptions(response))
      dispatch(requestSuccess())
    })
    .catch((error) => {
      dispatch(requestFailure())
    })
}

export const fetchAccountInputHousingOptionsV2 = (): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  getAccountInputHousingOptionsV2()
    .then((response: InputHousingOption[]) => {
      dispatch(setInputHousingOptions(response))
      dispatch(requestSuccess())
    })
    .catch((error) => {
      dispatch(requestFailure())
    })
}

export const fetchAccountShowStepStatusV2 = (
  callback?: (response: AccountStepStatus) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  getAccountShowStepStatusV2()
    .then((response: AccountStepStatus) => {
      dispatch(getAccountShowStepStatusV2Success(response))
      dispatch(requestSuccess())
      callback?.(response)
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const updateAccountSummaryCheckStatusV2 = (): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchAccountSummaryCheckStatusV2()
    .then((response: AccountResponse) => {
      dispatch(getAccountSuccess(response))
    })
    .catch((error) => {
      dispatch(requestFailure())
    })
}

export const updateAccountIsWithoutMoneytreeV2 = (
  callback?: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchAccountIsWithoutMoneytreeV2()
    .then((response: AccountResponse) => {
      dispatch(getAccountSuccess(response))
      callback?.()
    })
    .catch((error) => {
      dispatch(requestFailure())
    })
}

export const updateAccountHasCreatedFirstPlanV2 = (
  callback?: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchAccountHasCreatedFirstPlanV2()
    .then((response: AccountResponse) => {
      dispatch(getAccountSuccess(response))
      callback?.()
    })
    .catch((error) => {
      dispatch(requestFailure())
    })
}
