import React, { FC, useContext, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useField, useForm } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { find as _find } from 'lodash'
import NumberFieldDecimal from '@/components/v2/molecules/NumberFieldDecimal'
import { DECLINE_RATE_TYPE_RADIO_ITEMS } from '@/constants/formItem/futures/futureEstateInvestments'
import { DescriptionContext } from '@/hooks/useDescriptionTemplates'
import {
  API_FLAG,
  calcPassedYearTillCurrentYear,
  extractYearOrMonthFromDate,
} from '@/models/commonsModelFunc'
import { FutureEstateInvestmentDeclineRate } from '@/models/futures/futureEstateInvestmentDeclineRatesModel'
import { RootState } from '@/store'
import {
  composeValidators,
  commaNumberMaxLength,
  required,
  zeroOrMoreNumber,
} from '@/utils/validate'

import Typography from '@/components/v2/atoms/Typography'
import SelectBox from '@/components/v2/molecules/SelectBox'
import NumberField from '@/components/v2/molecules/NumberField'
import CrossIcon from '@/assets/images/v2/cross.svg'
import PlusCircleIcon from '@/assets/images/v2/plus-circle.svg'

const RentIncome: FC = () => {
  const { prevHousing } = useContext(DescriptionContext)
  const inputHousings = useSelector(
    (state: RootState) => state.accounts.futureItems?.input_housings,
  )

  const { change } = useForm()

  const {
    input: { value: declineType },
  } = useField('future_lend_housing.annual_rate_of_decline_type')

  const {
    input: { value: housingType },
  } = useField('future_lend_housing.housing_type')

  useEffect(() => {
    const targetKeys = [
      'future_lend_housing.rent_income_monthly',
      'future_lend_housing.annual_rate_of_decline_type',
    ]
    declineType === API_FLAG.on &&
      targetKeys.push('future_lend_housing.future_lend_housing_declines_attributes')
  }, [declineType])

  const handleAddDeclineRateBlock = (
    declineRates: Partial<FutureEstateInvestmentDeclineRate>[],
    index: number,
    change: (key: string, value: Partial<FutureEstateInvestmentDeclineRate>[]) => void,
  ) => {
    const isEnd = index + 1 === declineRates.length
    isEnd ? declineRates.push({}) : declineRates.splice(index + 1, 0, {})

    change('future_lend_housing.future_lend_housing_declines_attributes', declineRates)
  }

  const inputHousing = useMemo(() => {
    return _find(inputHousings, { house_type: 1 })
  }, [inputHousings])

  const renderYearsSinceConstructed = () => {
    const targetKey =
      prevHousing?.model_type == 'InputHousing' || housingType == 'InputHousing'
        ? 'input_housing_self_owned'
        : 'future_v2_housing_self_owned'

    const constructedAt =
      prevHousing?.[targetKey]?.constructed_at ||
      inputHousing?.[targetKey]?.constructed_at
    return calcPassedYearTillCurrentYear(
      extractYearOrMonthFromDate(constructedAt, 'year'),
    )
  }

  return (
    <>
      <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
        <Typography
          className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
          isBold
        >
          賃料収入の金額
        </Typography>
        <div className="flex flex-auto items-baseline">
          <NumberField
            isFullWidth
            name="future_lend_housing.rent_income_monthly"
            validate={composeValidators(required, zeroOrMoreNumber)}
          />
          <Typography
            className="pl-[8px] text-[14px] text-black-800 min-w-[fit-content]"
            isBold
          >
            円/月
          </Typography>
        </div>
      </div>

      <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-center">
        <Typography
          className="mr-[10px] pb-[10px] md:pb-[0px] text-[14px] text-black-800"
          isBold
        >
          賃料収入の年間下落率の設定
        </Typography>
        <div className="flex flex-auto items-baseline">
          <SelectBox
            name="future_lend_housing.annual_rate_of_decline_type"
            placeholder="選択する"
            isFullWidth
            options={DECLINE_RATE_TYPE_RADIO_ITEMS}
            validate={required}
            initialValue={API_FLAG.off}
          />
        </div>
      </div>
      {declineType == String(API_FLAG.on) && (
        <div className="col-span-1 md:col-span-2 border-black-300 border-dashed border-b-2">
          <div className="ml-0 md:ml-[67px] my-[30px]">
            <FieldArray name="future_lend_housing.future_lend_housing_declines_attributes">
              {({ fields }) =>
                fields.map((name, index) => (
                  <div key={name}>
                    <div className="relative flex p-[14px] border border-solid rounded-[5px]">
                      {index == 0 ? (
                        <Typography
                          className="text-[14px] text-black-700 flex items-center"
                          isBold
                        >
                          現在(築{renderYearsSinceConstructed()}年)
                        </Typography>
                      ) : (
                        <>
                          <Typography
                            className="text-[14px] text-black-700 flex items-center mr-[16px]"
                            isBold
                          >
                            築
                          </Typography>
                          <NumberField
                            name={`${name}affected_number`}
                            widthClassName="max-w-[80px]"
                            validate={composeValidators(required, zeroOrMoreNumber)}
                          />
                          <Typography
                            className="text-[14px] text-black-700 flex items-center min-w-[50px] ml-[16px]"
                            isBold
                          >
                            年未満
                          </Typography>
                          <div className="flex relative items-center  w-full justify-end">
                            <div
                              className="cursor-pointer"
                              onClick={() => fields.remove(index)}
                            >
                              <CrossIcon className="cursor-pointer" />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="flex relative left-[30px] md:left-[-106px]">
                      <div className="flex items-center min-w-[19px] md:min-w-[162px] z-[20]">
                        <Typography
                          className="text-[14px] text-black-700 font-bold hidden md:flex items-center mr-[16px] ml-[30px] border-b-[0.5px]"
                          isBold
                        >
                          期間を追加する
                        </Typography>
                        <div
                          className="bg-white cursor-pointer"
                          onClick={() =>
                            handleAddDeclineRateBlock([...fields.value], index, change)
                          }
                        >
                          <PlusCircleIcon />
                        </div>
                      </div>
                      <div className="relative flex p-[10px] border-l border-solid left-[-12px] z-[10]">
                        <div className="md:flex">
                          <Typography
                            className="text-[14px] text-black-900 font-bold flex items-center mr-[16px] ml-[30px] mb-[10px] md:mb-[0px]"
                            isBold
                          >
                            この期間の下落率
                          </Typography>
                          <div className="flex items-center">
                            <NumberFieldDecimal
                              name={`${name}rate_percent`}
                              widthClassName="max-w-[100px]"
                              validate={composeValidators(
                                required,
                                zeroOrMoreNumber,
                                (value: any) => commaNumberMaxLength(value, 4),
                              )}
                            />
                            <Typography
                              className="pl-[8px] text-[14px] text-black-800 flex items-center min-w-[fit-content]"
                              isBold
                            >
                              %/年
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              }
            </FieldArray>

            <div className="relative flex p-[14px] border border-solid rounded-[5px]">
              <Typography className="text-[14px] text-black-700 flex items-center" isBold>
                終身
              </Typography>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default RentIncome
