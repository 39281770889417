import React, { FC, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { isUndefined as _isUndefined, isNull as _isNull } from 'lodash'
import ChevronRightIcon from '@/assets/images/v2/chevron-right.svg'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import Grid from '@/components/v2/atoms/Grid'
import Modal from '@/components/v2/atoms/Modal'
import Typography from '@/components/v2/atoms/Typography'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import { find as _find } from 'lodash'
import NumberFieldDecimal from '@/components/v2/molecules/NumberFieldDecimal'

import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/store'
import {
  createFutureInflationV2,
  updateFutureInflationV2,
} from '@/containers/futures/futureInflationsSlice'
import {
  FutureInflation,
  FutureInflationCreateValues,
} from '@/models/futures/futureInflationsModel'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import { fetchAccountFutureItems } from '@/containers/accountsSlice'
import clsx from 'clsx'
import SubmitErrorMessage from '@/components/v2/molecules/SubmitErrorMessage'

interface FutureInflationModalFormProps {
  /** true: オープン, false: クローズ */
  isOpen: boolean
  /** onCloseメソッド */
  onClose: () => void
  /** onCloseメソッド */
  isEdit: boolean
  /** 編集時のID */
  id: number | null
  /* FutureItemsを参照するID */
  futureBaseId?: number
}

const FutureInflationModalForm: FC<FutureInflationModalFormProps> = ({
  isOpen,
  onClose,
  isEdit,
  id,
  futureBaseId,
}) => {
  const dispatch = useDispatch()
  const { showFlashMsg } = useFlashAlert()

  const [submitErrors, setSubmitErrors] = useState<any>(undefined)
  const futureInflations = useSelector(
    (state: RootState) => state.accounts.futureItems?.future_inflations,
  )

  const targetInflation: FutureInflation | undefined = useMemo(
    () => (!_isNull(id) ? _find(futureInflations, { id }) : undefined),
    [futureInflations, isEdit, id],
  )

  const handleCreate = async (values: FutureInflationCreateValues) => {
    const newValues = {
      account: { future_inflations_attributes: [{ ...values.future_inflation }] },
    }

    const submitErrors = await new Promise((resolve) =>
      dispatch(createFutureInflationV2(newValues, resolve)),
    )

    if (_isUndefined(submitErrors)) {
      dispatch(fetchAccountFutureItems(futureBaseId))
      showFlashMsg(isEdit ? '更新しました' : '作成しました')
      onClose()
    } else {
      setSubmitErrors(submitErrors)
    }
  }

  const handleUpdate = async (values: any) => {
    const newValues: any = {
      future_inflation: { ...values.future_inflation },
    }

    const submitErrors = await new Promise((resolve) =>
      dispatch(updateFutureInflationV2(newValues, id!, resolve)),
    )

    if (_isUndefined(submitErrors)) {
      dispatch(fetchAccountFutureItems(futureBaseId))
      showFlashMsg(isEdit ? '更新しました' : '作成しました')
      onClose()
    } else {
      setSubmitErrors(submitErrors)
    }
  }

  const handleInflationSubmit = (values: any) => {
    return isEdit ? handleUpdate(values) : handleCreate(values)
  }

  const initialValues = useMemo(
    () =>
      isEdit
        ? {
            future_inflation: { ...targetInflation },
            inflation_plan: !!targetInflation?.inflation_rate ? '0' : '1',
          }
        : {
            future_inflation: {
              inflation_rate_percent: null,
            },
          },
    [targetInflation],
  )

  return (
    <Modal
      className="w-[95%] xl:w-[1000px]"
      isOpen={isOpen}
      isDisableBackdropClick
      onClose={onClose}
    >
      <CircleTitle
        className="pb-[32px]"
        title="インフレ時の家計について"
        classNameTitle="text-[19px] md:text-[24px]"
      />
      <Form
        onSubmit={() => {}}
        initialValues={initialValues}
        render={({ handleSubmit, values, errors }) => {
          return (
            <form onSubmit={handleSubmit} className="pb-30">
              <Grid className="gap-x-40 gap-y-16 grid-cols-1 xl:grid-cols-2 pb-24 md:pb-40">
                <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                  <Typography
                    className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                    isBold
                  >
                    インフレ発生時の生活費
                  </Typography>

                  <div className="flex items-center justify-end">
                    <Typography
                      className="min-w-[44px] pr-[8px] text-14 text-black-800"
                      isBold
                    >
                      毎年
                    </Typography>
                    <NumberFieldDecimal
                      name="future_inflation.inflation_rate_percent"
                      isFullWidth
                    />
                    <Typography
                      className="pl-16 text-14 text-black-800 min-w-[fit-content]"
                      isBold
                    >
                      %増
                    </Typography>
                  </div>
                </div>
              </Grid>
              <SubmitErrorMessage show={submitErrors} />
              <ButtonPrimary
                className={clsx(
                  { ['opacity-50']: Object.keys(errors).length > 0 },
                  'w-[fit-content] md:w-[246px] h-[64px] mx-[auto]',
                )}
                onClick={() => handleInflationSubmit(values)}
              >
                <Typography
                  className="bottom-2 flex-auto pl-30 relative text-16 text-white"
                  isBold
                >
                  保存する
                </Typography>
                <ChevronRightIcon className="mr-17" />
              </ButtonPrimary>
            </form>
          )
        }}
      />
    </Modal>
  )
}

export default FutureInflationModalForm
