import {
  CUSTOM_ITEM_CATEGORY,
  PAYMENT_MODE,
} from '@/models/inputs/inputCustomItemsModelFunc'

// 適用方法
export const PAYMENT_MODE_SELECT_ITEMS = [
  { value: '', label: '選択する', isDisabled: true },
  { value: PAYMENT_MODE.once, label: '１回のみ' },
  { value: PAYMENT_MODE.repeatedly, label: '繰り返し' },
]

// 科目
export const CUSTOM_ITEM_EXPENSE_CATEGORY_SELECT_ITEMS = [
  { value: '', label: '支出' },
  { value: CUSTOM_ITEM_CATEGORY.livingCost, label: '基本生活費' },
  { value: CUSTOM_ITEM_CATEGORY.insurance, label: '生命保険料' },
  { value: CUSTOM_ITEM_CATEGORY.yearlyLivingCost, label: '特別な支出・大きな支出' },
  //MEMO: 学費はテンプレートファイルで 第__子学費 の形に整形
  // { value: CUSTOM_ITEM_CATEGORY.tuition, label: '学費' },
  { value: CUSTOM_ITEM_CATEGORY.housingCost, label: '住宅関連費用' },
  {
    value: CUSTOM_ITEM_CATEGORY.houseRunningCost,
    label: '自宅 管理費・修繕積立金・固定資産税',
  },
  {
    value: CUSTOM_ITEM_CATEGORY.estateInvestmentOtherCost,
    label: '不動産投資 その他費用',
  },
  { value: CUSTOM_ITEM_CATEGORY.personSideBusinessExpense, label: 'ご本人副業経費' },
  { value: CUSTOM_ITEM_CATEGORY.spouseSideBusinessExpense, label: '配偶者副業経費' },
  { value: CUSTOM_ITEM_CATEGORY.customOtherSpending, label: 'その他の支出' },
]

export const CUSTOM_ITEM_INCOME_CATEGORY_SELECT_ITEMS = [
  { value: '', label: '収入' },
  { value: CUSTOM_ITEM_CATEGORY.personGrossIncome, label: 'ご本人額面給与' },
  { value: CUSTOM_ITEM_CATEGORY.spouseGrossIncome, label: '配偶者額面給与' },
  { value: CUSTOM_ITEM_CATEGORY.personSeverancePay, label: 'ご本人退職金' },
  { value: CUSTOM_ITEM_CATEGORY.spouseSeverancePay, label: '配偶者退職金' },
  { value: CUSTOM_ITEM_CATEGORY.personOtherIncome, label: 'ご本人のその他収入' },
  { value: CUSTOM_ITEM_CATEGORY.spouseOtherIncome, label: '配偶者のその他収入' },
  { value: CUSTOM_ITEM_CATEGORY.estateInvestmentIncome, label: '不動産投資 収入' },
  { value: CUSTOM_ITEM_CATEGORY.personSideBusinessIncome, label: 'ご本人副業収入' },
  { value: CUSTOM_ITEM_CATEGORY.spouseSideBusinessIncome, label: '配偶者副業収入' },
  { value: CUSTOM_ITEM_CATEGORY.customOtherIncome, label: 'その他の収入' },
]
