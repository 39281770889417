import React, { FC } from 'react'
import Chip from '@/components/v2/atoms/Chip'
import Typography from '@/components/v2/atoms/Typography'
import { comparingTermLabel } from '@/models/commonsModelFunc'

interface MyDataPanelCommonProps {
  /** 選択期間 */
  term?: string
  /** タイトル */
  title: string
  /** 収支 */
  balance: string
  /** 前月比 */
  defference: string
  /** 背景カラークラス名 */
  bgColorClassName: string
  /** チップ背景カラークラス名 */
  chipBgColorClassName: string
  /** 前月比カラークラス名 */
  defferenceColorClassName: string
}

const MyDataPanelCommon: FC<MyDataPanelCommonProps> = (props) => {
  const {
    term,
    title,
    balance,
    defference,
    bgColorClassName,
    chipBgColorClassName,
    defferenceColorClassName,
  } = props

  return (
    <div
      className={`${bgColorClassName} rounded-8 py-16 text-center w-246 col-span-2 mx-auto mb-20`}
    >
      <Chip className={`${chipBgColorClassName} mx-auto pb-2 text-white text-16`} isBold>
        {title}
      </Chip>
      <div className="flex items-baseline justify-center">
        <Typography className="text-40" isBold>
          {balance}
        </Typography>
        <Typography className="text-16" isBold>
          万円
        </Typography>
      </div>
      <Typography className={defferenceColorClassName}>{`${comparingTermLabel(
        term,
      )} ${defference}万円`}</Typography>
    </div>
  )
}

export default MyDataPanelCommon
