import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import { setLogindFunc } from '@/containers/accountsSlice'
import { RootState } from '@/store'
import { isLogind } from '@/utils/auth'

const Auth: FC = (props) => {
  const dispatch = useDispatch()

  const { children } = props
  const [isInitialProsessFinished, setIsInitialProsessFinished] = useState<boolean>(false)
  const { isLogin } = useSelector((state: RootState) => state.accounts)

  useEffect(() => {
    if (isLogin) return setIsInitialProsessFinished(true)

    isLogind() && dispatch(setLogindFunc())
    setIsInitialProsessFinished(true)
  }, [])

  return (
    <>
      {isInitialProsessFinished ? (
        <>
          {/* @ts-ignore */}
          {isLogin ? <Route children={children} /> : <Redirect to={`/v2/?redirectUrl=${location.pathname}`} />}
        </>
      ) : null}
    </>
  )
}

export default Auth
