import clsx from 'clsx'
import React, { ChangeEvent, FC, ReactNode } from 'react'
import { Field } from 'react-final-form'
import ValidMessage from '@/components/v2/atoms/ValidMessage'

export interface CheckboxData {
  /*** チェックボックスに表示するラベルテキスト */
  label: ReactNode
  /** ラジオボタンのValue値 */
  value: any
}

interface CheckboxGroupProps {
  /** name属性 */
  name: string
  /** 横並びに表示するか */
  isDispRow?: boolean
  /** 無効化するか */
  disabled?: boolean
  /** 各ラジオボタンの情報 */
  datas: CheckboxData[]
  /** Changeイベントメソッド */
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  /** バリデーションメソッド */
  validate?: (value: any) => void
}

const CheckboxGroup: FC<CheckboxGroupProps> = (props) => {
  const { name, isDispRow, disabled, datas, onChange, validate } = props

  return (
    <>
      <div className={clsx({ 'flex space-x-24': isDispRow, 'space-y-14': !isDispRow })}>
        {datas.map((item, index: number) => (
          <Field
            key={index}
            type="checkbox"
            name={name}
            value={item.value}
            validate={validate}
          >
            {(props) => {
              const { input } = props

              const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
                input.onChange(event)
                onChange?.(event)
              }

              return (
                <label className="flex items-center">
                  <input
                    {...input}
                    className="w-20 h-20 mr-10"
                    disabled={disabled}
                    onChange={handleChange}
                  />
                  <div className="flex-1">{item.label}</div>
                </label>
              )
            }}
          </Field>
        ))}
      </div>
      <Field name={name} subscription={{ error: true, touched: true }}>
        {({ meta }) => <ValidMessage meta={meta} />}
      </Field>
    </>
  )
}

export default CheckboxGroup
