import { isUndefined as _isUndefined } from 'lodash'

export const STATE_LOCAL_STORAGE_KEY: string = 'moneytreeState'

export const getMoneytreeRedirectUrl = (): string => {
  return encodeURIComponent(process.env.MONEY_TREE_REDIRECT_URL || '')
}

// # Moneytreeアカウントの基本情報（メールアドレス、言語、地域）の閲覧
// guest_read
//
// # Moneytreeに登録している銀行口座、クレジットカード、デビットカード、電子マネーの名称、口座の種別、残高の閲覧
// accounts_read
//
// # Moneytreeに登録している銀行口座、クレジットカード、デビットカード、電子マネーの明細の閲覧
// transactions_read
//
// # Moneytreeに登録している銀行口座、クレジットカード、デビットカード、電子マネーの明細のカテゴリやメモの編集
// transactions_write
//
// # Moneytreeに登録している金融サービスの資産情報の取得
// request_refresh
//
// # Moneytreeに登録している投資用口座（投資信託、株式、確定拠出年金）の名称、口座の種別、残高の閲覧
// investment_accounts_read
//
// # Moneytreeに登録している保有銘柄の明細の閲覧
// investment_transactions_read
//
// # Moneytreeに登録しているポイントの名称、残高、明細の閲覧
// points_read
//
// # Moneytreeに登録している生命保険の契約内容の閲覧
// life_insurance_read

export const getMoneytreeClientId = (): string => {
  return process.env.MONEY_TREE_CLIENT_ID || ''
}

export const getMoneytreeRegistrationUrl = (moneytreeStateString: string): string => {
  const redirectUri = getMoneytreeRedirectUrl()

  const url = [
    'https://myaccount.getmoneytree.com/oauth/authorize',
    `?client_id=${getMoneytreeClientId()}`,
    '&response_type=code',
    '&scope=guest_read+accounts_read+transactions_read+transactions_write+request_refresh+investment_accounts_read+investment_transactions_read+points_read',
    `&state=${moneytreeStateString}`,
    '&locale=ja-JP',
    '&configs=auth_action=signup',
    `&redirect_uri=${redirectUri}`,
  ].join('')

  return url
}

export const getMoneytreeLoginUrl = (moneytreeStateString: string): string => {
  const redirectUri = getMoneytreeRedirectUrl()

  const url = [
    'https://myaccount.getmoneytree.com/oauth/authorize',
    `?client_id=${getMoneytreeClientId()}`,
    '&response_type=code',
    '&scope=guest_read+accounts_read+transactions_read+transactions_write+request_refresh+investment_accounts_read+investment_transactions_read+points_read',
    `&state=${moneytreeStateString}`,
    '&locale=ja-JP',
    `&redirect_uri=${redirectUri}`,
    `&configs=back_to=${redirectUri}`,
  ].join('')

  return url
}

export const getMoneytreeVaultUrl = (redirectPath?: string): string => {
  const backUrl = `${process.env.APP_URL + (redirectPath ? redirectPath : '/')}`
  const url = [
    'https://vault.getmoneytree.com',
    `?client_id=${getMoneytreeClientId()}`,
    `&configs=back_to=${backUrl}`,
  ].join('')
  return url
}
