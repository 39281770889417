import {
  filter as _filter,
  includes as _includes,
  pick as _pick,
  split as _split,
} from 'lodash'
import { InputCustomItem } from '@/models/inputs/inputCustomItemsModel'

export const PAYMENT_MODE = {
  once: 'once',
  repeatedly: 'repeatedly',
}

export const CUSTOM_ITEM_CATEGORY = {
  personGrossIncome: 'person_gross_income',
  spouseGrossIncome: 'spouse_gross_income',
  personSeverancePay: 'person_severance_income',
  spouseSeverancePay: 'spouse_severance_income',
  personOtherIncome: 'person_other_income',
  spouseOtherIncome: 'spouse_other_income',
  personPublicPensionIncome: 'person_public_pension_income',
  spousePublicPensionIncome: 'spouse_public_pension_income',
  livingCost: 'living_cost',
  insurance: 'insurance',
  yearlyLivingCost: 'yearly_living_cost',
  tuition: 'tuition',
  housingCost: 'housing_cost',
  houseRunningCost: 'house_running_cost',
  estateInvestmentIncome: 'estate_investment_income',
  estateInvestmentOtherCost: 'estate_investment_other_cost',
  personSideBusinessIncome: 'person_side_business_income',
  spouseSideBusinessIncome: 'spouse_side_business_income',
  personSideBusinessExpense: 'person_side_business_expense',
  spouseSideBusinessExpense: 'spouse_side_business_expense',
  customOtherIncome: 'custom_other_income',
  customOtherSpending: 'custom_other_spending',
}

export const CUSTOM_ITEM_INCOME_GROUP = [
  CUSTOM_ITEM_CATEGORY.personGrossIncome,
  CUSTOM_ITEM_CATEGORY.spouseGrossIncome,
  CUSTOM_ITEM_CATEGORY.personSeverancePay,
  CUSTOM_ITEM_CATEGORY.spouseSeverancePay,
  CUSTOM_ITEM_CATEGORY.personOtherIncome,
  CUSTOM_ITEM_CATEGORY.spouseOtherIncome,
  CUSTOM_ITEM_CATEGORY.estateInvestmentIncome,
  CUSTOM_ITEM_CATEGORY.personSideBusinessIncome,
  CUSTOM_ITEM_CATEGORY.spouseSideBusinessIncome,
  CUSTOM_ITEM_CATEGORY.personPublicPensionIncome,
  CUSTOM_ITEM_CATEGORY.spousePublicPensionIncome,
  CUSTOM_ITEM_CATEGORY.customOtherIncome,
]

export const CUSTOM_ITEM_EXPENSE_GROUP = [
  CUSTOM_ITEM_CATEGORY.livingCost,
  CUSTOM_ITEM_CATEGORY.insurance,
  CUSTOM_ITEM_CATEGORY.yearlyLivingCost,
  CUSTOM_ITEM_CATEGORY.tuition,
  CUSTOM_ITEM_CATEGORY.housingCost,
  CUSTOM_ITEM_CATEGORY.houseRunningCost,
  CUSTOM_ITEM_CATEGORY.estateInvestmentOtherCost,
  CUSTOM_ITEM_CATEGORY.personSideBusinessExpense,
  CUSTOM_ITEM_CATEGORY.spouseSideBusinessExpense,
  CUSTOM_ITEM_CATEGORY.customOtherSpending,
]

export const convertCategory = (
  category: string | null | undefined,
  childIndex?: number,
) => {
  switch (category) {
    case CUSTOM_ITEM_CATEGORY.personGrossIncome:
      return 'ご本人額面給与'
    case CUSTOM_ITEM_CATEGORY.spouseGrossIncome:
      return '配偶者額面給与'
    case CUSTOM_ITEM_CATEGORY.personSeverancePay:
      return 'ご本人退職金'
    case CUSTOM_ITEM_CATEGORY.spouseSeverancePay:
      return '配偶者退職金'
    case CUSTOM_ITEM_CATEGORY.personOtherIncome:
      return 'ご本人その他の収入'
    case CUSTOM_ITEM_CATEGORY.spouseOtherIncome:
      return '配偶者その他の収入'
    case CUSTOM_ITEM_CATEGORY.personPublicPensionIncome:
      return 'ご本人公的年金収入'
    case CUSTOM_ITEM_CATEGORY.spousePublicPensionIncome:
      return '配偶者公的年金収入'
    case CUSTOM_ITEM_CATEGORY.livingCost:
      return '基本生活費'
    case CUSTOM_ITEM_CATEGORY.insurance:
      return '生命保険料'
    case CUSTOM_ITEM_CATEGORY.yearlyLivingCost:
      return '特別な支出・大きな支出'
    case CUSTOM_ITEM_CATEGORY.tuition:
      return childIndex ? `第${childIndex}子学費` : '学費'
    case CUSTOM_ITEM_CATEGORY.housingCost:
      return '住宅関連費用'
    case CUSTOM_ITEM_CATEGORY.houseRunningCost:
      return '自宅 管理費・修繕積立金・固定資産税'
    case CUSTOM_ITEM_CATEGORY.estateInvestmentIncome:
      return '不動産投資 収入'
    case CUSTOM_ITEM_CATEGORY.estateInvestmentOtherCost:
      return '不動産投資 その他費用'
    case CUSTOM_ITEM_CATEGORY.personSideBusinessIncome:
      return 'ご本人副業収入'
    case CUSTOM_ITEM_CATEGORY.spouseSideBusinessIncome:
      return '配偶者副業収入'
    case CUSTOM_ITEM_CATEGORY.personSideBusinessExpense:
      return 'ご本人副業経費'
    case CUSTOM_ITEM_CATEGORY.spouseSideBusinessExpense:
      return '配偶者副業経費'
    case CUSTOM_ITEM_CATEGORY.customOtherIncome:
      return 'その他の収入'
    case CUSTOM_ITEM_CATEGORY.customOtherSpending:
      return 'その他の支出'
    default:
      return ''
  }
}

export const convertFrequency = (inputCustomItem: InputCustomItem) => {
  return inputCustomItem.payment_mode === PAYMENT_MODE.once
    ? `ご本人が ${inputCustomItem.payment_start_at_age || ' '}歳の時に１回のみ`
    : `ご本人が ${inputCustomItem.payment_start_at_age || ' '}歳から${
        inputCustomItem.payment_end_at_age || ' '
      }歳まで ${inputCustomItem.occurance_in_years || ' '}年ごとに`
}

export const buildUpdateInitialValues = (
  inputCustomItems: InputCustomItem[],
  tempId: string,
  setTempId: (tempId: string) => void,
) => {
  const newInputCustomItems = inputCustomItems.map((item, index) => ({
    ...item,
    temp_id: `temp_${index}`,
    is_active: item.is_active ? [true] : [],
    category: _includes(item.category, CUSTOM_ITEM_CATEGORY.tuition)
      ? `${item.category}_${item.other_objects?.child_index}`
      : item.category,
  }))
  setTempId(`temp_${newInputCustomItems.length - 1}`)

  const newInputCustomItemIncome = _filter([...newInputCustomItems], (item) =>
    _includes(CUSTOM_ITEM_INCOME_GROUP, item.category),
  )
  const newInputCustomItemExpense = _filter([...newInputCustomItems], (item) => {
    const category = _includes(item.category, CUSTOM_ITEM_CATEGORY.tuition)
      ? _split(item.category, '_')[0]
      : item.category

    return _includes(CUSTOM_ITEM_EXPENSE_GROUP, category)
  })

  return {
    input_custom_items_income_attributes: [...newInputCustomItemIncome],
    input_custom_items_expense_attributes: [...newInputCustomItemExpense],
  }
}

export const pickInputCustomItemRequiredValues = (inputCustomItem: InputCustomItem) => {
  return _pick(inputCustomItem, [
    'is_active',
    'display_name',
    'category',
    'payment_mode',
    'payment_start_at_age',
    'payment_end_at_age',
    'occurance_in_years',
    'cost',
    'changed_rate_percent',
    'other_objects',
  ])
}
