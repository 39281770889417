import React, { FC } from 'react'
import { useField } from 'react-final-form'
import {
  commaNumberMaxLength,
  composeValidators,
  required,
  zeroOrMoreNumber,
} from '@/utils/validate'

import Typography from '@/components/v2/atoms/Typography'
import NumberField from '@/components/v2/molecules/NumberField'

const TransferUpdateCost: FC = () => {
  const {
    input: { value: transferRent },
  } = useField('input_housing.input_housing_transfer_attributes.transfer_rent')

  return (
    <>
      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
        <Typography
          className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
          isBold
        >
          次回更新費用
        </Typography>
        <div className="flex flex-auto items-baseline">
          <NumberField
            name="input_housing.input_housing_transfer_attributes.transfer_update_cost"
            isFullWidth
            validate={composeValidators(required, zeroOrMoreNumber, (value: any) =>
              commaNumberMaxLength(value, 10),
            )}
            defaultValue={transferRent ?? 0}
          />
          <Typography className="pl-[8px] text-[14px] text-black-800" isBold>
            円
          </Typography>
        </div>
      </div>
    </>
  )
}

export default TransferUpdateCost
