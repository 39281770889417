import React, { FC, useEffect, useMemo, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import {
  isString as _isString,
  replace as _replace,
  isNull as _isNull,
  isUndefined as _isUndefined,
} from 'lodash'
import ChevronRightIcon from '@/assets/images/v2/chevron-right.svg'
import FamilyMonochromaticIcon from '@/assets/images/v2/family-monochromatic.svg'
import Button from '@/components/v2/atoms/Button'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import Grid from '@/components/v2/atoms/Grid'
import MarginContainer from '@/components/v2/atoms/MarginContainer'
import Paper from '@/components/v2/atoms/Paper'
import TextField from '@/components/v2/atoms/TextField'
import Typography from '@/components/v2/atoms/Typography'
import Breadcrumb from '@/components/v2/molecules/Breadcrumb'
import NumberField from '@/components/v2/molecules/NumberField'
import PaperTitle from '@/components/v2/molecules/PaperTitle'
import SelectBox from '@/components/v2/molecules/SelectBox'
import { IS_TAX_CHARGABLE_SELECT_ITEMS } from '@/constants/formItem/commonAssets'
import {
  PERSON_FLG_SELECT_ITEMS,
  PERSON_WITHOUT_SPOUSE_SELECT_ITEM,
} from '@/constants/formItem/familyStructures'
import { INPUT_ASSET_TYPE_SELECT_ITEMS } from '@/constants/formItem/inputs/inputAssets'
import { fetchFamilyStructure } from '@/containers/familyStructuresSlice'
import { setPageMeta } from '@/containers/pageMetasSlice'
import {
  fetchInputAssetSummary,
  createInputAsset,
  updateInputAsset,
} from '@/containers/inputs/inputAssetsSlice'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import { API_FLAG, castNumberWithoutOperator } from '@/models/commonsModelFunc'
import { RootState } from '@/store'
import {
  composeValidators,
  required,
  zeroOrMoreNumber,
  commaNumberMaxLength,
  limitRangeNumber,
} from '@/utils/validate'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import { AccountContext } from '@/models/accountsModelFunc'
import { useParams } from 'react-router'
import LoadingOverlay from '@/components/v1/atoms/LoadingOverlay'
import NumberFieldDecimal from '@/components/v2/molecules/NumberFieldDecimal'

const AssetEdit: FC = () => {
  const dispatch = useDispatch()
  const { handleMovePage } = useCustomHistory()
  const { showFlashMsg } = useFlashAlert()
  const { isAdmin } = useContext(AccountContext)
  const { account_id } = useParams<{ account_id?: string }>()

  const { inputAssets, isLoading } = useSelector((state: RootState) => state.inputAssets)
  const { familyStructures } = useSelector((state: RootState) => state.familyStructures)
  const isLoadingFamilyStructures = useSelector(
    (state: RootState) => state.familyStructures.isLoading,
  )

  const buildInitialValues = useMemo(
    () => ({
      input_assets: inputAssets.map((item) => ({
        ...item,
        person_flg: _isNull(item.person_flg)
          ? `child${item.family_structure_child?.id}`
          : item.person_flg,
      })),
    }),
    [inputAssets],
  )

  const personFlgSelectItems = useMemo(() => {
    const hasSpouse = familyStructures?.spouse_type === API_FLAG.on
    const targetSelectItems = hasSpouse
      ? PERSON_FLG_SELECT_ITEMS
      : PERSON_WITHOUT_SPOUSE_SELECT_ITEM

    const childSelectItems = familyStructures?.family_structure_children?.map((item) => ({
      value: `child${item.id}`,
      label: `第${item.child_index}子`,
    }))

    return !_isUndefined(childSelectItems)
      ? [...targetSelectItems, ...childSelectItems]
      : [...targetSelectItems]
  }, [familyStructures])

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'マイデータ | 金融資産編集',
        description: 'マイデータ | 金融資産編集',
        keyword: 'マイデータ | 金融資産編集',
      }),
    )
    dispatch(fetchInputAssetSummary())
    dispatch(fetchFamilyStructure())
  }, [])

  const submitInputAssetFunc = (values: any) => {
    const { person_flg } = values

    // 子供を選択した場合
    if (_isString(person_flg)) {
      values.person_flg = null
      values.family_structure_child_id = Number(_replace(person_flg, 'child', ''))
    } else {
      values.family_structure_child_id = null
    }

    const newValues = { input_asset: values }
    const isEdit = !_isUndefined(values.id)

    return new Promise((resolve) =>
      isEdit
        ? dispatch(
            updateInputAsset(
              values.id,
              newValues,
              () => {
                showFlashMsg(isEdit ? '更新しました' : '作成しました')
                handleMovePage(
                  isAdmin
                    ? `/v2/accounts/${account_id}/myData/assets`
                    : '/v2/myData/assets',
                )
              },
              resolve,
            ),
          )
        : dispatch(
            createInputAsset(
              newValues,
              () => {
                showFlashMsg(isEdit ? '更新しました' : '作成しました')
                handleMovePage(
                  isAdmin
                    ? `/v2/accounts/${account_id}/myData/assets`
                    : '/v2/myData/assets',
                )
              },
              resolve,
            ),
          ),
    )
  }

  const handleSubmit = (values: any) => {
    values.input_assets
      .reduce(
        (acc: any, item: any) => acc.then(() => submitInputAssetFunc(item)),
        Promise.resolve(),
      )
      .then(() =>
        handleMovePage(
          isAdmin ? `/v2/accounts/${account_id}/myData/assets` : '/v2/myData/assets',
        ),
      )
  }

  return (
    <>
      <LoadingOverlay isLoading={isLoading || isLoadingFamilyStructures} />
      <MarginContainer className="px-[8px] md:px-[32px] lg:px-0 py-[8px] md:py-[40px]">
        <Breadcrumb
          className="pb-[10px] md:pb-[32px]"
          data={[
            {
              name: 'マイデータ',
              url: isAdmin ? `/v2/accounts/${account_id}/myData` : '/v2/myData',
            },
            {
              name: 'マイデータ設定',
              url: isAdmin
                ? `/v2/accounts/${account_id}/myData/assets`
                : '/v2/myData/assets',
            },
            { name: '金融資産' },
          ]}
        />

        <PaperTitle
          className="mb-[16px] md:mb-[40px]"
          title="金融資産"
          description="日々の収入や支出を登録することで改善案などのご提案が可能になります"
          icon={<FamilyMonochromaticIcon />}
          smallIcon={
            <FamilyMonochromaticIcon
              className="h-[82px] w-[130px]"
              viewBox="0 0 117 154"
            />
          }
        />

        <Paper className="bg-white p-[24px] md:p-[40px]">
          <Typography
            className="mb-[16px] pb-[10px] text-[24px] md:text-[28px] text-black-800 border-b-2 border-black-300"
            isBold
          >
            金融資産
          </Typography>
          <Typography className="pb-[32px] text-[14px]">
            種別と想定利回りを設定することでライフプランに反映します。
            <br />
            適正な利回りを設定しましょう。
          </Typography>

          <Form
            onSubmit={handleSubmit}
            initialValues={buildInitialValues}
            mutators={{ ...arrayMutators }}
            render={({
              handleSubmit,
              values,
              errors,
              form: {
                mutators: { push },
              },
            }) => (
              <form onSubmit={handleSubmit}>
                <FieldArray name="input_assets">
                  {({ fields }) =>
                    fields.map((name, index) => {
                      const isConnectedWithMT =
                        fields.value[index]?.['is_connected_with_moneytree?']

                      return (
                        <Grid
                          key={name}
                          className="pb-[64px] grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px]"
                        >
                          <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                            <Typography
                              className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                              isBold
                            >
                              金融資産の種別
                            </Typography>
                            <SelectBox
                              name={`${name}asset_type`}
                              placeholder="選択する"
                              options={INPUT_ASSET_TYPE_SELECT_ITEMS}
                              isFullWidth
                              validate={required}
                            />
                          </div>

                          <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                            <Typography
                              className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                              isBold
                            >
                              金融資産の名称
                            </Typography>
                            <TextField
                              type="text"
                              name={
                                isConnectedWithMT ? `${name}current_name` : `${name}name`
                              }
                              isFullWidth
                              disabled={isConnectedWithMT}
                              validate={required}
                            />
                          </div>

                          <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                            <Typography
                              className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                              isBold
                            >
                              名義人
                            </Typography>
                            <SelectBox
                              name={`${name}person_flg`}
                              placeholder="選択する"
                              options={personFlgSelectItems}
                              isFullWidth
                              validate={required}
                            />
                          </div>

                          <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                            <Typography
                              className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                              isBold
                            >
                              残高
                            </Typography>
                            <div className="flex flex-auto items-baseline">
                              <NumberField
                                name={
                                  isConnectedWithMT
                                    ? `${name}current_value`
                                    : `${name}value`
                                }
                                isFullWidth
                                disabled={isConnectedWithMT}
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 10),
                                )}
                              />
                              <Typography
                                className="pl-[8px] text-[14px] text-black-800"
                                isBold
                              >
                                円
                              </Typography>
                            </div>
                          </div>

                          <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                            <Typography
                              className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                              isBold
                            >
                              想定利回り
                            </Typography>
                            <div className="flex flex-auto items-baseline">
                              <Typography
                                className="min-w-[fit-content] pr-[8px] text-[14px] text-black-800"
                                isBold
                              >
                                年利
                              </Typography>
                              <NumberFieldDecimal
                                name={`${name}interest_rate_yearly_percent`}
                                isFullWidth
                                validate={composeValidators(required, (value: any) =>
                                  limitRangeNumber(
                                    castNumberWithoutOperator(value),
                                    -100,
                                    100,
                                  ),
                                )}
                              />
                              <Typography
                                className="pl-[8px] text-[14px] text-black-800"
                                isBold
                              >
                                %
                              </Typography>
                            </div>
                          </div>

                          <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                            <Typography
                              className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                              isBold
                            >
                              課税対象の有無
                            </Typography>
                            <SelectBox
                              name={`${name}is_tax_chargable`}
                              placeholder="選択する"
                              options={IS_TAX_CHARGABLE_SELECT_ITEMS}
                              isFullWidth
                              validate={required}
                            />
                          </div>

                          {index !== 0 && (
                            <>
                              <div />
                              <Button
                                className="w-[112px] h-[37px] ml-auto border border-secondary-500 rounded-[4px] hover:bg-secondary-50"
                                onClick={() => fields.remove(index)}
                              >
                                <Typography
                                  className="text-[14px] text-secondary-500"
                                  isBold
                                >
                                  削除する
                                </Typography>
                              </Button>
                            </>
                          )}
                        </Grid>
                      )
                    })
                  }
                </FieldArray>

                <Button
                  className="w-full h-[53px] mb-[32px] border border-dashed border-primary-500 text-[14px] text-primary-500 hover:bg-primary-50"
                  onClick={() => push('input_assets', {})}
                >
                  <Typography isBold>追加</Typography>
                </Button>

                <ButtonPrimary
                  className="w-[246px] h-[64px] mx-auto mb-[16px]"
                  isDisabled={Object.keys(errors).length > 0 || isLoading}
                  onClick={() => handleSubmit(values)}
                >
                  <Typography
                    className="relative bottom-[2px] pl-[30px] text-[16px] text-white flex-auto"
                    isBold
                  >
                    保存する
                  </Typography>
                  <ChevronRightIcon className="mr-[17px]" />
                </ButtonPrimary>
                <Button
                  className="mx-auto text-[14px] text-black-700"
                  onClick={() =>
                    handleMovePage(
                      isAdmin
                        ? `/v2/accounts/${account_id}/myData/assets`
                        : '/v2/myData/assets',
                    )
                  }
                  isDisabled={isLoading}
                >
                  キャンセル
                </Button>
              </form>
            )}
          />
        </Paper>
      </MarginContainer>
    </>
  )
}

export default AssetEdit
