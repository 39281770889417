import React, { FC } from 'react'
import { Popover as MuiPopover, PopoverProps as MuiPopoverProps } from '@material-ui/core'

interface PopoverProps extends MuiPopoverProps {}

const Popover: FC<PopoverProps> = (props) => {
  return <MuiPopover {...props} />
}

export default Popover
