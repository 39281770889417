import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { parse } from 'query-string'
import ApiClient from '@/api/apiClient'
import LogindRedirect from '@/layouts/v2/LogindRedirect'
import MarginContainer from '@/components/v2/atoms/MarginContainer'
import Paper from '@/components/v2/atoms/Paper'
import AccountPasswordForm from '@/templates/v2/accounts/password/commons/AccountPasswordForm'
import { updateAccountPasswordHandOver } from '@/containers/accountsSlice'
import { setPageMeta } from '@/containers/pageMetasSlice'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import { AccountPasswordHandOverValues } from '@/models/accountsModel'

const AccountPasswordHandOver: FC = () => {
  const dispatch = useDispatch()
  const { handleMovePage } = useCustomHistory()
  const { showFlashMsg } = useFlashAlert()

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'アカウントパスワード登録',
        description: 'アカウントパスワード登録',
        keyword: 'アカウントパスワード登録',
      }),
    )

    const { token, ma } = parse(location.search)

    ApiClient.setAuthHeaders({
      Authorization: `Token token=${token}`,
      MailAddress: ma as string,
    })
  }, [])

  const handleSubmit = (values: AccountPasswordHandOverValues) => {
    return new Promise((resolve) =>
      dispatch(
        updateAccountPasswordHandOver(
          values,
          () => {
            showFlashMsg('登録しました')
            handleMovePage('/v2/accounts/password/handOverCompleted')
          },
          resolve,
        ),
      ),
    )
  }

  return (
    <LogindRedirect>
      <MarginContainer className="w-full sm:w-524 mx-auto pt-40 sm:pt-73">
        <Paper className="mx-8 sm:mx-0 px-16 py-24 sm:p-40 bg-white">
          <AccountPasswordForm
            title="アカウントパスワード登録"
            description={
              <>
                FPより、アカウントが発行されました。
                <br />
                パスワードを設定してください。
              </>
            }
            handleSubmit={handleSubmit}
          />
        </Paper>
      </MarginContainer>
    </LogindRedirect>
  )
}

export default AccountPasswordHandOver
