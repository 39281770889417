import LocalStorage from '@/utils/localStorage'

const KEY = 'hasNoFamilyStructure'

export const getStorageHasNoFamilyStructure = () => {
  return LocalStorage.getItem(KEY)
}

export const setStorageHasNoFamilyStructure = (value: boolean) => {
  return LocalStorage.setItem(KEY, value)
}

export const removeStorageHasNoFamilyStructure = () => {
  LocalStorage.removeItem(KEY)
}
