import ApiClient from '@/api/apiClient'
import {
  TemporaryAccountResponse,
  TemporaryAccountLoginResponse,
  TemporaryAccountLoginValues,
  TemporaryAccountCreateValues,
  TemporaryAccountHandOverValues,
  TemporaryAccountResendValues,
  TemporaryAccountDeleteValues,
} from '@/models/admins/temporaryAccountsModel'
import { CommonResponse } from '@/models/commonsModel'

export const getTemporaryAccounts = async () => {
  return await ApiClient.get(
    '/temporary_accounts',
    (response: TemporaryAccountResponse[]) => response,
  )
}

export const postTemporaryAccountLogin = async (values: TemporaryAccountLoginValues) => {
  return await ApiClient.post(
    '/temporary_accounts/login',
    (response: TemporaryAccountLoginResponse) => response,
    values,
  )
}

export const postTemporaryAccount = async (values: TemporaryAccountCreateValues) => {
  return await ApiClient.post('/temporary_accounts', () => {}, values)
}

export const patchTemporaryAccountHandOver = async (
  values: TemporaryAccountHandOverValues,
) => {
  return await ApiClient.patch(
    '/admin_api/v1/app/temporary_accounts/hand_over',
    () => {},
    values,
    true,
  )
}

export const patchTemporaryAccountDemoResetV2 = async (id: number) => {
  return await ApiClient.patch(
    '/temporary_accounts/demo_reset',
    (response: CommonResponse) => response,
    { id },
    false,
    'v2',
  )
}

export const updateTemporaryAccountResend = async (
  values: TemporaryAccountResendValues,
) => {
  return await ApiClient.patch('/temporary_accounts/resend', () => {}, values)
}
export const deleteTemporaryAccountDelete = async (
  values: TemporaryAccountDeleteValues,
) => {
  return await ApiClient.delete(`/temporary_accounts/${values.id}`, () => {}, values)
}
