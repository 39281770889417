import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import MarginContainer from '@/components/v2/atoms/MarginContainer'
import Paper from '@/components/v2/atoms/Paper'
import Heading from '@/components/v2/atoms/Heading'
import Typography from '@/components/v2/atoms/Typography'
import { setPageMeta } from '@/containers/pageMetasSlice'
import { resetTmpSeminarParticipant } from '@/containers/seminarParticipantsSlice'

const SeminarParticipantCompleted: FC = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'セミナー申し込みフォーム 申し込み完了',
        description: 'セミナー申し込みフォーム 申し込み完了',
        keyword: 'セミナー申し込みフォーム 申し込み完了',
      }),
    )
    dispatch(resetTmpSeminarParticipant())
  }, [])

  return (
    <MarginContainer className="w-full sm:w-524 py-40">
      <Paper className="mx-8 sm:mx-0 px-16 py-24 sm:p-40 space-y-24 bg-white">
        <Heading className="text-center">セミナー申し込みフォーム 申し込み完了</Heading>
        <Typography className="mt-24 sm:text-center text-14 text-black-700">
          ご登録のアドレスに確認用のメールを送信しました。
          <br />
          当日ご参加いただけることを楽しみにしております。
        </Typography>
      </Paper>
    </MarginContainer>
  )
}

export default SeminarParticipantCompleted
