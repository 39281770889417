export const INPUT_LOAN_CALCULATION_FLAT_CHANGE_LAND_FIELDS = [
  'input_housing.input_housing_self_owned_attributes.input_loans_attributes[0]input_loan_calculation_attributes.started_borrowing_amount_manyen',
  'started_borrowing_total_amount_land_manyen',
]

export const INPUT_LOAN_CALCULATION_FLAT_CHANGE_BUILDING_FIELDS = [
  'input_housing.input_housing_self_owned_attributes.input_loans_attributes[2]input_loan_calculation_attributes.started_borrowing_amount_manyen',
  'started_borrowing_total_amount_building_manyen',
]

export const selectLoanCalculationObjectName = (objectNum: number, name: string) => {
  return `input_housing.input_housing_self_owned_attributes.input_loans_attributes[${objectNum}]input_loan_calculation_attributes.${name}`
}
