import React, { FC, useContext, useEffect, useState } from 'react'
import ChevronDownIcon from '@/assets/images/v2/chevron-down-new.svg'
import ChevronUpIcon from '@/assets/images/v2/chevron-up.svg'
import Typography from '@/components/v2/atoms/Typography'
import Tooltip from '@/components/v2/atoms/Tooltip'
import { SimulationAccordionContext } from '@/templates/v2/simulations/_simulationPanelUser'

interface SimulationAccordionProps {
  /** クラス名 */
  className?: string
  /** タイトル */
  title: string

  isSelected?: boolean
  selectedCount?: number
  itemCount?: number
  isExpiredSettings?: boolean
}

const SimulationAccordion: FC<SimulationAccordionProps> = (props) => {
  const {
    className,
    title,
    isSelected,
    selectedCount,
    itemCount,
    isExpiredSettings,
    children,
  } = props
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const allAccordionState = useContext(SimulationAccordionContext)

  useEffect(() => {
    if (allAccordionState === 'allOpen') setIsOpen(true)
    if (allAccordionState === 'allClose') setIsOpen(false)
  }, [allAccordionState])

  return (
    <div className={className}>
      <div
        className={
          'px-[16px] py-[8px] flex justify-between items-center cursor-pointer ' +
          (isSelected
            ? 'border-2 border-solid rounded-[4px] border-primary-500 bg-primary-50'
            : 'bg-black-200') +
          (itemCount == null ? ' md:py-[16px]' : '')
        }
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex-1">
          <div className="flex flex-col md:flex-row md:items-center md:justify-between">
            <div className="felx flex-col justify-between">
              <div className="flex flex-row">
                <Typography className="text-[16px] leading-normal text-black-900" isBold>
                  {title}
                </Typography>

                {isExpiredSettings && (
                  <Tooltip
                    item={
                      <Typography className="leading-normal text-[10px] text-alert-600 text-left">
                        過去の年齢が含まれる設定を選択しています。 <br />
                        設定を確認し、更新してください。
                      </Typography>
                    }
                    itemClassName="w-[80px] md:w-[260px] p-[8px] bg-alert-500 before:border-t-alert-500"
                  >
                    <i className="ico info ml-2 text-16 text-alert-600" />
                  </Tooltip>
                )}
              </div>

              {itemCount != null && (
                <Typography className="leading-normal text-[14px] text-black-500">
                  {`設定数: ${itemCount ?? 0}`}
                </Typography>
              )}
            </div>

            {isSelected != null && (
              <Typography
                className={
                  'leading-normal text-[16px] text-primary-500 ' +
                  (isSelected ? 'text-primary-500' : 'text-black-500')
                }
              >
                {isSelected
                  ? !!selectedCount
                    ? `グラフ・表へ${selectedCount}つ反映中`
                    : `グラフ・表へ反映中`
                  : 'グラフ・表へ反映なし'}
              </Typography>
            )}
          </div>
        </div>

        <div className="flex-none">
          {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
        </div>
      </div>
      {isOpen && <div className="p-[10px] pb-[0]">{children}</div>}
    </div>
  )
}

export default SimulationAccordion
