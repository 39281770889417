import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { sortBy as _sortBy } from 'lodash'
import { unauthorizedCheck } from '../accountsSlice'
import {
  postFutureFamilyV2,
  patchFutureFamilyV2,
  deleteFutureFamilyV2,
  patchFutureFamilyChildbirthV2,
} from '../../api/futures/futureFamilies'
import { ApiError } from '../../models/commonsModel'
import { convertMomentDate } from '../../models/commonsModelFunc'
import { FutureFamilyChildbirth } from '../../models/futures/futureFamilyChildbirthsModel'
import { FutureFamilyHour } from '../../models/futures/futureFamilyHoursModel'
import { AppThunk } from '../../store'

interface FutureFamilyState {
  futureFamilyChildbirths: FutureFamilyChildbirth[]
  futureFamilyHours: FutureFamilyHour[]
  isLoading: boolean
}

const initialState: FutureFamilyState = {
  futureFamilyChildbirths: [],
  futureFamilyHours: [],
  isLoading: false,
}

const futureFamiliesSlice = createSlice({
  name: 'futureFamilies',
  initialState,
  reducers: {
    setFutureFamilyChildbirths: (
      state,
      action: PayloadAction<FutureFamilyChildbirth[]>,
    ) => {
      const { payload } = action
      const sortedFutureFamilyChildbirths = _sortBy(payload, 'childbirth_index')

      state.futureFamilyChildbirths = sortedFutureFamilyChildbirths.map((item) => ({
        ...item,
        childbirth_scheduled_at: convertMomentDate(item.childbirth_scheduled_at, 'YYYY'),
      }))

      return state
    },
    setFutureFamilyHours: (state, action: PayloadAction<FutureFamilyHour[]>) => {
      state.futureFamilyHours = action.payload
      return state
    },
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
  },
})

export const {
  setFutureFamilyChildbirths,
  setFutureFamilyHours,
  requestStart,
  requestSuccess,
  requestFailure,
} = futureFamiliesSlice.actions
export const futureFamiliesReducer = futureFamiliesSlice.reducer

export const createFutureFamilyV2 = (
  values: any,
  callback: () => void,
  resolve: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postFutureFamilyV2(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error, resolve))
    })
}

export const updateFutureFamilyV2 = (
  values: any,
  id: number,
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchFutureFamilyV2(values, id)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const destroyFutureFamilyV2 = (
  values: any,
  id: number,
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  deleteFutureFamilyV2(values, id)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const updateFutureFamilyChildbirthV2 = (
  values: any,
  id: number,
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchFutureFamilyChildbirthV2(values, id)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}
