import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { unauthorizedCheck } from '../accountsSlice'
import {
  getInputCorporatePensions,
  postInputCorporatePension,
  patchInputCorporatePension,
  deleteInputCorporatePension,
} from '../../api/inputs/inputCorporatePensions'
import { ApiError } from '../../models/commonsModel'
import {
  InputCorporatePension,
  InputCorporatePensionCreateValues,
  InputCorporatePensionUpdateValues,
  InputCorporatePensionDestroyValues,
} from '../../models/inputs/inputCorporatePensionsModel'
import { AppThunk } from '../../store'

interface InputCorporatePensionState {
  inputCorporatePensions: InputCorporatePension[]
  isLoading: boolean
}

const initialState: InputCorporatePensionState = {
  inputCorporatePensions: [],
  isLoading: false,
}

const inputCorporatePensionsSlice = createSlice({
  name: 'inputCorporatePensions',
  initialState,
  reducers: {
    setInputCorporatePensions: (
      state,
      action: PayloadAction<InputCorporatePension[]>,
    ) => {
      state.inputCorporatePensions = action.payload
      return state
    },
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    resetInputCorporatePensionsStore: (state) => {
      state = initialState
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    getInputCorporatePensionsSuccess: (
      state,
      action: PayloadAction<InputCorporatePension[]>,
    ) => {
      state.inputCorporatePensions = action.payload
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
  },
})

export const {
  setInputCorporatePensions,
  requestStart,
  resetInputCorporatePensionsStore,
  requestSuccess,
  getInputCorporatePensionsSuccess,
  requestFailure,
} = inputCorporatePensionsSlice.actions
export const inputCorporatePensionsReducer = inputCorporatePensionsSlice.reducer

export const fetchInputCorporatePensions = (): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  getInputCorporatePensions()
    .then((response) => dispatch(getInputCorporatePensionsSuccess(response)))
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const createInputCorporatePension = (
  values: InputCorporatePensionCreateValues,
  callback: () => void,
  resolve: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postInputCorporatePension(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error, resolve))
    })
}

export const updateInputCorporatePension = (
  values: InputCorporatePensionUpdateValues,
  callback: () => void,
  resolve: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchInputCorporatePension(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error, resolve))
    })
}

export const destroyInputCorporatePension = (
  values: { params: InputCorporatePensionDestroyValues },
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  deleteInputCorporatePension(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}
