import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { unauthorizedCheck } from '../accountsSlice'
import {
  postInputPlanTuition,
  patchInputPlanTuition,
  deleteInputPlanTuition,
} from '@/api/inputs/inputPlanTuitions'
import { ApiError } from '@/models/commonsModel'
import { FamilyStructureChild } from '@/models/familyStructureChildren'
import {
  InputPlanTuition,
  InputPlanTuitionCreateValues,
  InputPlanTuitionUpdateValues,
  InputPlanTuitionDestroyValues,
} from '@/models/inputs/inputPlanTuitionsModel'
import { AppThunk } from '@/store'

interface InputPlanTuitionState {
  inputPlanTuitions: InputPlanTuition[][]
  isLoading: boolean
}

const initialState: InputPlanTuitionState = {
  inputPlanTuitions: [],
  isLoading: false,
}

const inputPlanTuitionsSlice = createSlice({
  name: 'inputPlanTuitions',
  initialState,
  reducers: {
    setInputPlanTuition: (state, action: PayloadAction<FamilyStructureChild[]>) => {
      const { payload } = action

      state.inputPlanTuitions = payload.map((item) => item.input_plan_tuitions)
      return state
    },
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    resetInputPlanTuitionsStore: (state) => {
      state = initialState
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
    resetInputPlanTuitions: (state) => {
      state.inputPlanTuitions = initialState.inputPlanTuitions
      return state
    },
  },
})

export const {
  setInputPlanTuition,
  requestStart,
  resetInputPlanTuitionsStore,
  requestSuccess,
  requestFailure,
  resetInputPlanTuitions,
} = inputPlanTuitionsSlice.actions
export const inputPlanTuitionsReducer = inputPlanTuitionsSlice.reducer

export const createInputPlanTuition = (
  values: InputPlanTuitionCreateValues,
  resolve: (error: ApiError) => void,
  callback?: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postInputPlanTuition(values)
    .then(() => {
      dispatch(requestSuccess())
      callback && callback()
      resolve({})
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error, resolve))
    })
}

export const updateInputPlanTuition = (
  values: InputPlanTuitionUpdateValues,
  resolve: (error: ApiError) => void,
  callback?: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchInputPlanTuition(values)
    .then(() => {
      dispatch(requestSuccess())
      callback && callback()
      resolve({})
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const destroyInputPlanTuition = (
  values: { params: InputPlanTuitionDestroyValues },
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  deleteInputPlanTuition(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}
