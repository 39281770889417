import React, { FC } from 'react'

interface LinkProps {
  /** クラス名 */
  className?: string
  /** onClickメソッド */
  onClick?: () => void
  /** true: 非活性, false: 活性 */
  isDisabled?: boolean
}

const Link: FC<LinkProps> = (props) => {
  const { className, onClick, isDisabled, children } = props

  const pointerEvent = isDisabled ? 'pointer-events-none' : ''

  return (
    <a className={`${className} ${pointerEvent} cursor-pointer`} onClick={onClick}>
      {children}
    </a>
  )
}

export default Link
