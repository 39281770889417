import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { sortBy as _sortBy } from 'lodash'
import {
  patchFutureEstateInvestmentSaleV2,
  postFutureEstateInvestmentSaleV2,
  deleteFutureEstateInvestmentSaleV2,
} from '@/api/futures/futureEstateInvestmentSales'
import { unauthorizedCheck } from '@/containers/accountsSlice'
import { ApiError } from '@/models/commonsModel'
import {
  FutureEstateInvestmentSale,
  FutureEstateInvestmentSaleCreateValues,
  FutureEstateInvestmentSaleUpdateValues,
} from '@/models/futures/futureEstateInvestmentSalesModel'
import { AppThunk } from '@/store'

interface FutureEstateInvestmentSaleState {
  futureEstateInvestmentSales: FutureEstateInvestmentSale[]
  isLoading: boolean
}

const initialState: FutureEstateInvestmentSaleState = {
  futureEstateInvestmentSales: [],
  isLoading: false,
}

const futureEstateInvestmentSalesSlice = createSlice({
  name: 'futureEstateInvestmentSales',
  initialState,
  reducers: {
    setFutureEstateInvestmentSales: (
      state,
      action: PayloadAction<FutureEstateInvestmentSale[]>,
    ) => {
      const { payload } = action
      const sortedFutureEstateInvestmentSales = _sortBy(payload, 'id')

      state.futureEstateInvestmentSales = sortedFutureEstateInvestmentSales

      return state
    },
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
  },
})

export const {
  setFutureEstateInvestmentSales,
  requestStart,
  requestSuccess,
  requestFailure,
} = futureEstateInvestmentSalesSlice.actions
export const futureEstateInvestmentSalesReducer = futureEstateInvestmentSalesSlice.reducer

export const createFutureEstateInvestmentSaleV2 = (
  values: FutureEstateInvestmentSaleCreateValues,
  callback: () => void,
  resolve: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postFutureEstateInvestmentSaleV2(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error, resolve))
    })
}

export const updateFutureEstateInvestmentSaleV2 = (
  values: FutureEstateInvestmentSaleUpdateValues,
  id: number,
  callback: () => void,
  resolve?: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchFutureEstateInvestmentSaleV2(values, id)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error, resolve))
    })
}

export const destroyFutureEstateInvestmentSaleV2 = (
  values: any,
  id: number,
  callback: () => void,
  resolve?: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  deleteFutureEstateInvestmentSaleV2(values, id)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error, resolve))
    })
}
