import React, { FC } from 'react'
import clsx from 'clsx'
import Typography from '@/components/v2/atoms/Typography'
import Button from '@/components/v2/atoms/Button'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import { LearningVideo } from '@/models/commonLearningVideosModel'

interface MoviePreviewBodyProps {
  /** true: 管理者, false: 一般 */
  isAdmin?: boolean
  /** 動画情報 */
  learningVideo: LearningVideo | null | undefined
}

const MoviePreviewBody: FC<MoviePreviewBodyProps> = (props) => {
  const { handleMovePage } = useCustomHistory()
  const { isAdmin, learningVideo } = props

  return (
    <div className={clsx('box-border', { ['pt-[100px] mx-[100px]']: isAdmin })}>
      <div className="pt-55% relative">
        <iframe
          className="absolute h-full left-0 top-0 w-full"
          title="vimeo-player"
          src={learningVideo?.vimeo_url}
          width="720"
          height="450"
          frameBorder="0"
          style={{ border: 0 }}
          allowFullScreen
        />
      </div>
      <div>
        <Typography className="font-semibold my-3 text-24 text-black-900 font-default">
          {learningVideo?.title}
        </Typography>
        <Typography className="pb-3.5 text-16 text-black-800 font-default">
          {learningVideo?.subtitle}
        </Typography>
        <Typography className="text-12 text-black-700 text-justify pb-16 font-default">
          {learningVideo?.description}
        </Typography>
        <div className="u-flex">
          <div className="flex overflow-auto whitespace-nowrap">
            {learningVideo?.learning_video_categories?.map((item) => (
              <Button
                key={item.id}
                type="button"
                className="border border-solid border-gray-300 bg-white font-bold h-34 hover:bg-gray-200 mr-8 px-24 py-8 rounded-full text-xs text-black-800 font-default"
                onClick={
                  !isAdmin
                    ? () => handleMovePage(`/movies/list?category=${item.name}`)
                    : undefined
                }
              >
                {item.name}
              </Button>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MoviePreviewBody
