import { times as _times } from 'lodash'
import { CHANGE_TYPE } from '@/models/commonIncomeChangesModelFunc'
import { API_FLAG } from '@/models/commonsModelFunc'
import { INPUT_INSURANCE_PENSION_TYPE } from '@/models/inputs/inputSalariesModelFunc'

// 変化の内容
export const CHANGE_TYPE_SELECT_ITEMS = [
  { value: CHANGE_TYPE.employment, label: '転職・独立' },
  { value: CHANGE_TYPE.retirement, label: '退職・定年退職' },
  { value: CHANGE_TYPE.retirementOfficer, label: '転職を伴わない収入の変化' },
]

export const INSURANCE_PENSION_TYPE_SELECT_ITEMS = [
  { value: INPUT_INSURANCE_PENSION_TYPE.welfarePension, label: '健康保険/厚生年金' },
  { value: INPUT_INSURANCE_PENSION_TYPE.nationalPension, label: '国民健康保険/国民年金' },
  { value: INPUT_INSURANCE_PENSION_TYPE.notSubscribed, label: '未加入・第３号被保険者(会社員の配偶者)', },
]

// 再就職予定
export const RE_EMPLOYMENT_TYPE_RADIO_ITEMS = [
  { value: API_FLAG.on, label: 'する予定' },
  { value: API_FLAG.off, label: 'しない' },
]

// 再就職後の年齢
export const RE_EMPLOYMENT_FINISH_AT_AGE_SELECT_ITEMS = _times(41, (index) => ({
  value: index + 60,
  label: String(index + 60),
}))
