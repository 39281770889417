import React, { FC } from 'react'
import clsx from 'clsx'
import Typography from '@/components/v2/atoms/Typography'

interface FieldBlockProps {
  /** ラベル名 */
  label: string
  /** 必須フラグ */
  required?: boolean
  /** クラス名 */
  className?: string
}

const FieldBlock: FC<FieldBlockProps> = (props) => {
  const { label, className, children } = props
  return (
    <div className={clsx(className, 'flex flex-col')}>
      <div className="flex items-center pb-8">
        <Typography className="text-14 text-black" isBold>
          {label}
        </Typography>
      </div>
      {children}
    </div>
  )
}

FieldBlock.defaultProps = {
  required: false,
}

export default FieldBlock
