import React, { FC } from 'react'
import { useForm } from 'react-final-form'
import { LOAN_TYPE1_BUTTON_ITEMS } from '@/constants/formItem/futures/futureHousings'
import { INPUT_LOAN_TYPE1_BUTTON_ITEMS } from '@/constants/formItem/inputs/inputHousings'
import { getTargetSelfOwnedAttributesName } from '@/models/commonHousingModelFunc'
import { required } from '@/utils/validate'

import Typography from '@/components/v2/atoms/Typography'
import SelectBox from '@/components/v2/molecules/SelectBox'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
interface WithOrWithoutLoanProps {
  isFuture?: boolean
}

const WithOrWithoutLoan: FC<WithOrWithoutLoanProps> = (props) => {
  const { isFuture } = props
  const targetHousingSelfOwnedAttributesName = getTargetSelfOwnedAttributesName(isFuture)
  const modalDesc = isFuture ? '住宅ローンの予定' : '住宅ローンの有無'
  const selectItems = isFuture ? LOAN_TYPE1_BUTTON_ITEMS : INPUT_LOAN_TYPE1_BUTTON_ITEMS

  const { change } = useForm()

  return (
    <>
      <div>
        <CircleTitle className="pt-40 pb-20" title="ローンについて" />
      </div>
      <div />

      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
        <Typography
          className="min-w-[140px] lg:min-w-[210px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
          isBold
        >
          {modalDesc}
        </Typography>
        <SelectBox
          name={`${targetHousingSelfOwnedAttributesName}.loan_type1`}
          placeholder="選択する"
          isFullWidth
          options={selectItems}
          validate={required}
          onChange={(e: any) => {
            change(`${targetHousingSelfOwnedAttributesName}.loan_type1`, e.target.value)
          }}
        />
      </div>
    </>
  )
}

export default WithOrWithoutLoan
