import {
  ACCOUNT_TYPE,
  INPUT_ASSET_TYPE,
  ROLLOVER_TYPE,
} from '@/models/inputs/inputAssetsModelFunc'

// 金融資産種別
export const INPUT_ASSET_TYPE_SELECT_ITEMS = [
  {
    value: INPUT_ASSET_TYPE.bank,
    label: '流動性資産（銀行預金（定期含む）、MRF、MMFなど）',
  },
  { value: INPUT_ASSET_TYPE.deductedSavings, label: '社内預金、財形、持株など' },
  { value: INPUT_ASSET_TYPE.trustFund, label: '投資信託' },
  { value: INPUT_ASSET_TYPE.stock, label: '株式' },
  { value: INPUT_ASSET_TYPE.bond, label: '債券' },
  { value: INPUT_ASSET_TYPE.other, label: 'その他' },
]

export const ACCOUNT_TYPE_SELECT_ITEMS = [
  { value: ACCOUNT_TYPE.general, label: '特定口座・一般口座' },
  { value: ACCOUNT_TYPE.tsumitateNisa, label: 'つみたてNISA枠' },
  { value: ACCOUNT_TYPE.nisa, label: '成長投資枠' },
]

export const STOCK_ACCOUNT_TYPE_SELECT_ITEMS = [
  { value: ACCOUNT_TYPE.general, label: '特定口座・一般口座' },
  { value: ACCOUNT_TYPE.nisa, label: '成長投資枠' },
]

export const ROLLOVER_TYPE_SELECT_ITEMS = [
  { value: ROLLOVER_TYPE.planed, label: 'する予定' },
  { value: ROLLOVER_TYPE.notPlaned, label: 'しない' },
]
