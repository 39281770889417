import {
  INSURANCE_TYPE1,
  INSURANCE_TYPE2,
  PURPOSE,
  CONTRACTOR_TYPE,
  INSURED_TYPE,
  INSURANCE_FEE_PAYMENT_OCCURRENCE_IN_MONTHS,
  CANCELLATION_REFUND_TYPE,
  NON_LIFE_INSURANCE_TYPE1,
  NON_LIFE_INSURANCE_FEE_MONTHS,
} from '@/models/commonInsurancesModelFunc'
import { API_FLAG } from '@/models/commonsModelFunc'

// 保険分類
export const INSURANCE_CLASSIFICATION = {
  life: 'life',
  nonLife: 'non_life',
}

export const INSURANCE_CLASSIFICATION_SELECT_ITEMS = [
  { value: INSURANCE_CLASSIFICATION.life, label: '生命・医療・年金保険' },
  { value: INSURANCE_CLASSIFICATION.nonLife, label: '損害・少額短期保険' },
]

// 保険種別1
export const INSURANCE_TYPE1_SELECT_ITEMS = [
  { value: INSURANCE_TYPE1.life, label: '生命保険' },
  { value: INSURANCE_TYPE1.medical, label: '医療保険' },
  { value: INSURANCE_TYPE1.cancer, label: 'がん保険' },
  { value: INSURANCE_TYPE1.nursing, label: '介護保険' },
  { value: INSURANCE_TYPE1.personalAnnuity, label: '個人年金保険' },
]

export const NON_LIFE_INSURANCE_TYPE1_SELECT_ITEMS = [
  { value: NON_LIFE_INSURANCE_TYPE1.fire, label: '火災保険' },
  { value: NON_LIFE_INSURANCE_TYPE1.earthquake, label: '地震保険' },
  { value: NON_LIFE_INSURANCE_TYPE1.vehicle, label: '自動車・バイク' },
  { value: NON_LIFE_INSURANCE_TYPE1.pet, label: 'ペット保険' },
  { value: NON_LIFE_INSURANCE_TYPE1.injuredIncomeSecurity, label: '所得補償保険' },
  { value: NON_LIFE_INSURANCE_TYPE1.other, label: 'その他' },
]

// 保険種別2
export const INSURANCE_TYPE2_SELECT_ITEMS = [
  { value: INSURANCE_TYPE2.wholeLife, label: '終身型' },
  { value: INSURANCE_TYPE2.term, label: '定期型' },
  { value: INSURANCE_TYPE2.incomeSecurity, label: '収入保障' },
  { value: INSURANCE_TYPE2.endowment, label: '養老保険' },
]

// 保険種別2 (保険種別1で医療保険, がん保険, 介護保険を選択した場合)
export const REJECT_INSURANCE_TYPE2_SELECT_ITEMS = [
  { value: INSURANCE_TYPE2.wholeLife, label: '終身型' },
  { value: INSURANCE_TYPE2.term, label: '定期型' },
]

// 契約者
export const CONTRACTOR_TYPE_SELECT_ITEMS = [
  { value: CONTRACTOR_TYPE.personContract, label: 'ご本人' },
  { value: CONTRACTOR_TYPE.spouseContract, label: '配偶者' },
  { value: CONTRACTOR_TYPE.otherPersonContract, label: '自由入力' },
]

// 契約者(配偶者なし)
export const CONTRACTOR_TYPE_WITHOUT_SPOUSE_SELECT_ITEMS = [
  { value: CONTRACTOR_TYPE.personContract, label: 'ご本人' },
  { value: CONTRACTOR_TYPE.otherPersonContract, label: '自由入力' },
]

// 被保険者
export const INSURED_TYPE_SELECT_ITEMS = [
  { value: INSURED_TYPE.personInsured, label: 'ご本人' },
  { value: INSURED_TYPE.spouseInsured, label: '配偶者' },
  { value: INSURED_TYPE.otherPersonInsured, label: '自由入力' },
]

// 被保険者(配偶者なし)
export const INSURED_TYPE_WITHOUT_SPOUSE_SELECT_ITEMS = [
  { value: INSURED_TYPE.personInsured, label: 'ご本人' },
  { value: INSURED_TYPE.otherPersonInsured, label: '自由入力' },
]

// 利用目的
export const PURPOSE_RADIO_ITEMS = [
  { value: PURPOSE.security, label: '保障のため' },
  { value: PURPOSE.savings, label: '貯蓄のため' },
]

// 保険料の支払い頻度
export const INSURANCE_FEE_PAYMENT_OCCURRENCE_IN_MONTHS_RADIO_ITEMS = [
  {
    value: String(INSURANCE_FEE_PAYMENT_OCCURRENCE_IN_MONTHS.month),
    label: '毎月',
  },
  {
    value: String(INSURANCE_FEE_PAYMENT_OCCURRENCE_IN_MONTHS.halfYear),
    label: '半年ごと',
  },
  {
    value: String(INSURANCE_FEE_PAYMENT_OCCURRENCE_IN_MONTHS.year),
    label: '1年ごと',
  },
]

export const NON_LIFE_INSURANCE_FEE_MONTHS_RADIO_ITEMS = [
  { value: NON_LIFE_INSURANCE_FEE_MONTHS.month, label: '毎月' },
  { value: NON_LIFE_INSURANCE_FEE_MONTHS.halfYear, label: '半年ごと' },
  { value: NON_LIFE_INSURANCE_FEE_MONTHS.year, label: '1年ごと' },
  { value: NON_LIFE_INSURANCE_FEE_MONTHS.bulk, label: '長期一括' },
]

// 返戻金算出方法
export const CANCELLATION_REFUND_TYPE_RADIO_ITEMS = [
  { value: CANCELLATION_REFUND_TYPE.rate, label: '返戻率から設定' },
  { value: CANCELLATION_REFUND_TYPE.amount, label: '金額を入力' },
]

// 利用目的_確認
export const PURPOSE_CONFIRM_CHECKBOX_ITEM = [
  { value: API_FLAG.on, label: 'はい、理解しました' },
]
