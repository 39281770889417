import ApiClient from '@/api/apiClient'

export const postPastMonthlyAssetSummaryBulkPastData = async () => {
  return await ApiClient.post(
    '/past_monthly_asset_summaries/bulk_create_past_data',
    () => {},
    undefined,
    false,
    'v2',
  )
}
