import React, { FC } from 'react'
import { CURRENT_SALARY_INCREASE_RATE_SETTING_TYPE_SELECT_ITEMS } from '@/constants/formItem/commonIncomeBases'
import { required } from '@/utils/validate'
import Typography from '@/components/v2/atoms/Typography'
import SelectBox from '@/components/v2/molecules/SelectBox'

const RateSettingType: FC = () => {
  return (
    <>
      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-center">
        <Typography
          className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
          isBold
        >
          現在のお仕事の
          <br className="hidden lg:block" />
          収入上昇率設定
        </Typography>
        <SelectBox
          name="future_income.current_salary_increase_rate_setting_type"
          options={CURRENT_SALARY_INCREASE_RATE_SETTING_TYPE_SELECT_ITEMS}
          placeholder="選択する"
          isFullWidth
          validate={required}
        />
      </div>
    </>
  )
}

export default RateSettingType
