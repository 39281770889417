import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { unauthorizedCheck } from '../accountsSlice'
import {
  postFutureInflationV2,
  patchFutureInflationV2,
  deleteFutureInflationV2,
} from '../../api/futures/futureInflations'
import { ApiError } from '../../models/commonsModel'
import {
  FutureInflation,
  FutureInflationUpdateValues,
} from '../../models/futures/futureInflationsModel'
import { AppThunk } from '../../store'

interface FutureInflationState {
  futureInflation: FutureInflation | null
  isLoading: boolean
}

const initialState: FutureInflationState = {
  futureInflation: null,
  isLoading: false,
}

const futureInflationsSlice = createSlice({
  name: 'futureInflations',
  initialState,
  reducers: {
    setFutureInflation: (state, action: PayloadAction<FutureInflation | null>) => {
      state.futureInflation = action.payload
      return state
    },
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
  },
})

export const {
  setFutureInflation,
  requestStart,
  requestSuccess,
  requestFailure,
} = futureInflationsSlice.actions
export const futureInflationsReducer = futureInflationsSlice.reducer

export const createFutureInflationV2 = (
  values: any,
  resolve: (error: any) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postFutureInflationV2(values)
    .then(() => {
      dispatch(requestSuccess())
      resolve(undefined)
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error, resolve))
    })
}

export const updateFutureInflationV2 = (
  values: FutureInflationUpdateValues,
  id: number,
  resolve: (error: any) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchFutureInflationV2(values, id)
    .then(() => {
      dispatch(requestSuccess())
      resolve(undefined)
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const destroyFutureInflationV2 = (
  values: any,
  id: number,
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  deleteFutureInflationV2(values, id)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}
