import React, { FC } from 'react'
import JournalPaperCommon from '@/templates/v2/myData/journals/_journalPaperCommon'
import ExclamationIcon from '@/assets/images/v2/exclamation.svg'

interface JournalPaperIncomeProps {
  /** クラス名 */
  className?: string
  /** データ */
  data: any[]
  /** 選択肢 */
  options: any[]
  /** 仕訳後のコールバック */
  journalUpdateCallback: () => void
}

const JournalPaperIncome: FC<JournalPaperIncomeProps> = (props) => {
  const { className, data, options, journalUpdateCallback } = props

  return (
    <JournalPaperCommon
      className={className}
      title="未分類の収入"
      data={data}
      options={options}
      headerBgColorClassName="bg-action-600"
      dataBgColorClassName="bg-action-400"
      textColorClassName="text-action-600"
      icon={<ExclamationIcon />}
      journalUpdateCallback={journalUpdateCallback}
    />
  )
}

export default JournalPaperIncome
