import React, { FC, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'react-final-form'
import createDecorator from 'final-form-calculate'
import { Decorator } from 'final-form'
import { sumBy as _sumBy, get as _get, pick as _pick, isEmpty as _isEmpty } from 'lodash'
import ChevronRightIcon from '@/assets/images/v2/chevron-right.svg'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import Chip from '@/components/v2/atoms/Chip'
import Grid from '@/components/v2/atoms/Grid'
import Typography from '@/components/v2/atoms/Typography'
import LoadingOverlay from '@/components/v1/atoms/LoadingOverlay'
import { updateAggBalanceMonthly } from '@/containers/aggBalanceMonthliesSlice'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import { castNumberWithoutOperator, convertLocaleString } from '@/models/commonsModelFunc'
import { AggBalanceMonthlyUpdateValues } from '@/models/aggBalanceMonthliesModel'
import { RootState } from '@/store'
import BalanceFieldBlock from '@/templates/v2/myData/journals/_BalanceFieldBlock'

const SPENDING_SUM_KEYS = [
  'agg_balance_monthly.expenses',
  'agg_balance_monthly.sub_income_expenses',
  'agg_balance_monthly.estate_investment_expenses',
  'agg_balance_monthly.food_expenses',
  'agg_balance_monthly.utility_bills',
  'agg_balance_monthly.daily_necessities_cost',
  'agg_balance_monthly.transport',
  'agg_balance_monthly.clothing_cost',
  'agg_balance_monthly.hobby_expenses',
  'agg_balance_monthly.communication_cost',
  'agg_balance_monthly.entertainment_expenses',
  'agg_balance_monthly.tuition',
  'agg_balance_monthly.pocket_money',
  'agg_balance_monthly.scholarship',
  'agg_balance_monthly.car',
  'agg_balance_monthly.house_cost_total',
  'agg_balance_monthly.insurance_payment',
  'agg_balance_monthly.yearly_spending',
  'agg_balance_monthly.tax_payment_total',
  'agg_balance_monthly.other_cost',
  'agg_balance_monthly.medical_expenses',
]

const INCOME_SUM_KEYS = [
  'agg_balance_monthly.person_net_income',
  'agg_balance_monthly.spouse_net_income',
  'agg_balance_monthly.sub_income_income',
  'agg_balance_monthly.estate_investment_income',
  'agg_balance_monthly.other_income',
]

const calculator: Decorator<any> = createDecorator(
  {
    field: SPENDING_SUM_KEYS,
    updates: {
      spending_total: (_, allValues: AggBalanceMonthlyUpdateValues) =>
        _sumBy(SPENDING_SUM_KEYS, (key) =>
          castNumberWithoutOperator(_get(allValues, key)),
        ),
    },
  },
  {
    field: INCOME_SUM_KEYS,
    updates: {
      income_total: (_, allValues: AggBalanceMonthlyUpdateValues) =>
        _sumBy(INCOME_SUM_KEYS, (key) => castNumberWithoutOperator(_get(allValues, key))),
    },
  },
)

const MonthlyBalanceForm: FC<any> = (props) => {
  const dispatch = useDispatch()
  const { showFlashMsg } = useFlashAlert()
  const { handleMovePage } = useCustomHistory()
  const { selectedDate } = props

  const { journals } = useSelector((state: RootState) => state.journals)
  const { familyStructureChildren } = useSelector(
    (state: RootState) => state.familyStructures,
  )

  // isLoadingFlag
  const isLoadingJournals = useSelector((state: RootState) => state.journals.isLoading)
  const isLoadingFamilyStructures = useSelector(
    (state: RootState) => state.familyStructures.isLoading,
  )
  const isLoadingMonthlies = useSelector(
    (state: RootState) => state.aggBalanceMonthlies.isLoading,
  )

  const hasChildren = !_isEmpty(familyStructureChildren)

  const buildInitialValues = () => {
    const pickValues = _pick(journals, [...SPENDING_SUM_KEYS, ...INCOME_SUM_KEYS])
    return { spending_total: 0, income_total: 0, ...pickValues }
  }

  const handleSubmit = (values: any) => {
    const newValues = {
      date: selectedDate,
      agg_balance_monthly: { ...values.agg_balance_monthly },
    }

    dispatch(
      updateAggBalanceMonthly(newValues, () => {
        showFlashMsg('保存しました')
        handleMovePage('/v2/myData')
      }),
    )
  }

  return (
    <>
      <LoadingOverlay
        isLoading={isLoadingJournals || isLoadingFamilyStructures || isLoadingMonthlies}
      />
      <Form
        onSubmit={handleSubmit}
        initialValues={useMemo(() => buildInitialValues(), [journals])}
        decorators={[calculator]}
        render={({ handleSubmit, values }) => {
          const { spending_total, income_total } = values

          return (
            <form className="pb-48" onSubmit={handleSubmit}>
              <div className="bg-secondary-50 md:flex items-center justify-between mb-40 p-24 rounded-4">
                <Typography className="text-18" isBold>
                  {`1ヶ月間（
                                  ${selectedDate.split('/')[0]}年${
                    selectedDate.split('/')[1]
                  }月）`}
                  <br className="md:hidden" />
                  の貯金額
                </Typography>
                <div className="flex items-baseline justify-end">
                  <Typography className="text-30" isBold>
                    {convertLocaleString(income_total - spending_total)}
                  </Typography>
                  <Typography className="pl-5 text-16" isBold>
                    円
                  </Typography>
                </div>
              </div>

              <Grid className="gap-x-40 gap-y-16 grid-cols-1 md:grid-cols-2">
                <div>
                  <div className="border-b border-black-300 border-solid flex h-fit-content items-center justify-between mb-16 pb-16">
                    <Chip className="bg-alert-600 pb-2 text-white text-14" isBold>
                      支出合計
                    </Chip>
                    <div className="flex items-center">
                      <Typography className="pl-8 text-14 text-black-900" isBold>
                        {convertLocaleString(spending_total)}
                      </Typography>
                      <Typography className="pl-8 text-14 text-black-900" isBold>
                        円
                      </Typography>
                    </div>
                  </div>

                  <BalanceFieldBlock
                    name="agg_balance_monthly.food_expenses"
                    className="mb-16"
                    textFieldClassName="w-120"
                    label="食費"
                    description="例) 食材、お弁当、外食代など"
                    rightUnit="円"
                  />

                  <BalanceFieldBlock
                    name="agg_balance_monthly.utility_bills"
                    className="mb-16"
                    textFieldClassName="w-120"
                    label="水道光熱費"
                    description="例) 水道、電気、ガス代など"
                    rightUnit="円"
                  />

                  <BalanceFieldBlock
                    name="agg_balance_monthly.daily_necessities_cost"
                    className="mb-16"
                    textFieldClassName="w-120"
                    label="日用品雑貨費"
                    description="例) シャンプー、ティッシュペーパー、おむつ代"
                    rightUnit="円"
                  />

                  <BalanceFieldBlock
                    name="agg_balance_monthly.transport"
                    className="mb-16"
                    textFieldClassName="w-120"
                    label="交通費"
                    description="例) 電車賃、バス代"
                    rightUnit="円"
                  />

                  <BalanceFieldBlock
                    name="agg_balance_monthly.clothing_cost"
                    className="mb-16"
                    textFieldClassName="w-120"
                    label="被服費"
                    description="例) 洋服、靴、肌着、子供服代など"
                    rightUnit="円"
                  />

                  <BalanceFieldBlock
                    name="agg_balance_monthly.hobby_expenses"
                    className="mb-16"
                    textFieldClassName="w-120"
                    label="趣味・娯楽費"
                    description="例) 入園料、ユニフォーム、道具代など"
                    rightUnit="円"
                  />

                  <BalanceFieldBlock
                    name="agg_balance_monthly.medical_expenses"
                    className="mb-16"
                    textFieldClassName="w-120"
                    label="医療費"
                    description="例) 診察費、入院費など"
                    rightUnit="円"
                  />

                  <BalanceFieldBlock
                    name="agg_balance_monthly.communication_cost"
                    className="mb-16"
                    textFieldClassName="w-120"
                    label="通信費"
                    description="例) 携帯電話、固定電話、プロバイダー、NHK受信料など"
                    rightUnit="円"
                  />

                  <BalanceFieldBlock
                    name="agg_balance_monthly.entertainment_expenses"
                    className="mb-16"
                    textFieldClassName="w-120"
                    label="交際費"
                    description="例) プレゼント、お土産、お見舞い、お中元、お歳暮代など"
                    rightUnit="円"
                  />

                  <BalanceFieldBlock
                    name="agg_balance_monthly.pocket_money"
                    className="mb-16"
                    textFieldClassName="w-120"
                    label="こづかい"
                    description="例) おこづかい代"
                    rightUnit="円"
                  />

                  <BalanceFieldBlock
                    name="agg_balance_monthly.other_cost"
                    className="mb-16"
                    textFieldClassName="w-120"
                    label="その他の支出"
                    rightUnit="円"
                  />

                  <BalanceFieldBlock
                    name="agg_balance_monthly.car"
                    className="mb-16"
                    textFieldClassName="w-120"
                    label="車"
                    description="例) 車のローン、駐車場代、ガソリン代、保険など"
                    rightUnit="円"
                  />

                  <BalanceFieldBlock
                    name="agg_balance_monthly.house_cost_total"
                    className="mb-16"
                    textFieldClassName="w-120"
                    label="住宅費"
                    description="例) 住宅ローン、管理費など"
                    rightUnit="円"
                  />

                  <BalanceFieldBlock
                    name="agg_balance_monthly.insurance_payment"
                    className="mb-16"
                    textFieldClassName="w-120"
                    label="保険料"
                    rightUnit="円"
                  />

                  {hasChildren && (
                    <BalanceFieldBlock
                      name="agg_balance_monthly.tuition"
                      className="mb-16"
                      textFieldClassName="w-120"
                      label="学費"
                      description="例) 学費、学外費、習い事"
                      rightUnit="円"
                    />
                  )}

                  <BalanceFieldBlock
                    name="agg_balance_monthly.scholarship"
                    className="mb-16"
                    textFieldClassName="w-120"
                    label="奨学金"
                    description=""
                    rightUnit="円"
                  />

                  <BalanceFieldBlock
                    name="agg_balance_monthly.yearly_spending"
                    className="mb-16"
                    textFieldClassName="w-120"
                    label="特別な支出・大きな出費"
                    description="例) 家電の購入や旅行、冠婚葬祭など、一時的な出費"
                    rightUnit="円"
                  />

                  <BalanceFieldBlock
                    name="agg_balance_monthly.expenses"
                    className="mb-16"
                    textFieldClassName="w-120"
                    label="立替経費"
                    description="勤務先などで経費精算する支出です。基本生活費には計上されません。"
                    rightUnit="円"
                  />

                  <BalanceFieldBlock
                    name="agg_balance_monthly.sub_income_expenses"
                    className="mb-16"
                    textFieldClassName="w-120"
                    label="副業経費"
                    description="領収書を切っているが、実は生活費として使っている金額はここには入力せず、交際費に追加してください"
                    rightUnit="円"
                  />

                  <BalanceFieldBlock
                    name="agg_balance_monthly.estate_investment_expenses"
                    className="mb-16"
                    textFieldClassName="w-120"
                    label="不動産投資支出"
                    description="例) 不動産投資用ローン、不動産管理費など"
                    rightUnit="円"
                  />

                  <BalanceFieldBlock
                    name="agg_balance_monthly.tax_payment_total"
                    className="mb-16"
                    textFieldClassName="w-120"
                    label="税金・社会保険料"
                    rightUnit="円"
                  />
                </div>

                <div>
                  <div className="border-b border-black-300 border-solid flex h-fit-content items-center justify-between mb-16 pb-16">
                    <Chip className="bg-action-600 pb-2 text-white text-14" isBold>
                      収入合計
                    </Chip>
                    <div className="flex items-center">
                      <Typography className="pl-8 text-14 text-black-900" isBold>
                        {convertLocaleString(income_total)}
                      </Typography>
                      <Typography className="pl-8 text-14 text-black-900" isBold>
                        円
                      </Typography>
                    </div>
                  </div>

                  <BalanceFieldBlock
                    name="agg_balance_monthly.person_net_income"
                    className="mb-16"
                    textFieldClassName="w-160"
                    label="ご本人手取り給与"
                    rightUnit="円"
                  />

                  <BalanceFieldBlock
                    name="agg_balance_monthly.spouse_net_income"
                    className="mb-16"
                    textFieldClassName="w-160"
                    label="配偶者手取り給与"
                    rightUnit="円"
                  />

                  <BalanceFieldBlock
                    name="agg_balance_monthly.sub_income_income"
                    className="mb-16"
                    textFieldClassName="w-160"
                    label="副業収入"
                    rightUnit="円"
                  />

                  <BalanceFieldBlock
                    name="agg_balance_monthly.estate_investment_income"
                    className="mb-16"
                    textFieldClassName="w-160"
                    label="不動産投資収入"
                    rightUnit="円"
                  />

                  <BalanceFieldBlock
                    name="agg_balance_monthly.other_income"
                    className="mb-16"
                    textFieldClassName="w-160"
                    label="その他の収入"
                    rightUnit="円"
                  />
                </div>
              </Grid>

              <ButtonPrimary
                className="h-64 mx-auto my-0 w-246"
                onClick={() => handleSubmit(values)}
              >
                <Typography
                  className="bottom-2 flex-1 pl-30 relative text-16 text-white"
                  isBold
                >
                  保存する
                </Typography>
                <ChevronRightIcon className="mr-17" />
              </ButtonPrimary>
            </form>
          )
        }}
      />
    </>
  )
}

export default MonthlyBalanceForm
