import { CURRENT_SALARY_INCREASE_RATE_SETTING_TYPE } from '@/models/commonIncomeBasesModelFunc'

// 現在_上昇率タイプ
export const CURRENT_SALARY_INCREASE_RATE_SETTING_TYPE_SELECT_ITEMS = [
  {
    value: CURRENT_SALARY_INCREASE_RATE_SETTING_TYPE.average,
    label: '業界平均に準じて上昇させる',
  },
  { value: CURRENT_SALARY_INCREASE_RATE_SETTING_TYPE.input, label: '上昇率を手入力する' },
  {
    value: CURRENT_SALARY_INCREASE_RATE_SETTING_TYPE.stable,
    label: '上昇率を適用しない',
  },
]
