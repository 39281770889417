import React, { FC, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  filter as _filter,
  isEmpty as _isEmpty,
  isNull as _isNull,
  find as _find,
} from 'lodash'
import Typography from '@/components/v2/atoms/Typography'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import {
  convertDeductionType,
  convertHousingType2,
  convertInputLoanLoanType,
  convertInsuranceType,
  convertIsFlatApplied,
  convertLoanLender,
  convertLoanType2,
  convertOwnedHouseType,
  convertRateChangeValues,
  convertToJapaneseFullDate,
  HOUSE_TYPE,
  isBonusFlgOn,
  isMansionType,
  isSelfRentType,
  isWithAdvanceRepayment,
  REPAIR_RESERVE_FUND_RATE_TYPE,
} from '@/models/commonHousingModelFunc'
import {
  convertLocaleString,
  convertDateToTwoTypesYearsDateFormat,
  API_FLAG,
  isNotEmptyValue,
} from '@/models/commonsModelFunc'
import { convertPersonFlg } from '@/models/familyStructuresModelFunc'
import { convertHouseType } from '@/models/inputs/inputHousingsModelFunc'
import { convertRentType } from '@/models/inputs/inputHousingRentsModelFunc'
import {
  RATE_TYPE,
  convertRepaymentChart,
  convertAdvanceType,
  convertAdvanceValues,
  convertLandOrBuildingLoanTitle,
} from '@/models/inputs/inputHousingsModelFunc'
import { InputLoanRate } from '@/models/inputs/inputLoanRatesModel'
import {
  isFlatLoan,
  isOneLoanPattern,
  isPairLoan,
  isTwoLoanPattern,
  isWithChartLoan,
} from '@/models/inputs/inputLoansModelFunc'
import { InputCalculationRate } from '@/models/inputs/inputCalculationRatesModel'
import { isSplitedLoan } from '@/models/inputs/inputHousingSelfOwnedModelFunc'
import { RootState } from '@/store'
import SimulationAccordion from '@/templates/v2/simulations/_simulationAccordion'
import Modal from '@/components/v2/atoms/Modal'
import Button from '@/components/v2/atoms/Button'
import MyDataHousingsEditForm from '@/templates/v2/myData/housings/Form'
import MyDataHousingsEditFormTransfer from '@/templates/v2/myData/housings/FormTransfer'

export const InputHousingTables: FC<any> = (props) => {
  const { inputHousings } = useSelector((state: RootState) => state.inputHousings)

  const [isOpenHousingModal, setIsOpenHousingModal] = useState<boolean>(false)
  const [isOpenHousingTransferModal, setIsOpenHousingTransferModal] = useState<boolean>(
    false,
  )

  // 自己保有情報の取得
  const inputHousingSelfOwned: any = useMemo(
    () => _find(inputHousings, (item) => item?.house_type === HOUSE_TYPE.own),
    [inputHousings],
  )

  // 賃貸情報の取得
  const inputHousingRent = useMemo(
    () => _find(inputHousings, (item) => item?.house_type === HOUSE_TYPE.rent),
    [inputHousings],
  )

  // 単身赴任情報の取得
  const inputHousingTransfer = useMemo(
    () => _find(inputHousings, (item) => item?.house_type === HOUSE_TYPE.transfer),
    [inputHousings],
  )

  const inputHousingSelfOwnedLoans =
    inputHousingSelfOwned?.input_housing_self_owned.input_loans

  const selectAllInputLoanRates = (
    inputLoanRates: Partial<InputLoanRate>[] | undefined,
    nullType: 'null' | 'notNull',
    type?: 'bonus' | 'monthly',
  ) => {
    const rateType = type === 'bonus' ? RATE_TYPE.bonus : RATE_TYPE.monthly

    if (nullType === 'null') {
      return _filter(
        inputLoanRates,
        (loanRate) =>
          _isNull(loanRate?.rate_start_at) && loanRate?.rate_type === rateType,
      )
    }
    return _filter(
      inputLoanRates,
      (loanRate) => !_isNull(loanRate?.rate_start_at) && loanRate?.rate_type === rateType,
    )
  }

  const selectAllInputCalculationRatesByRateStartAt = (
    inputCalculationRates: Partial<InputCalculationRate>[],
    nullType: 'null' | 'notNull',
  ) => {
    if (nullType === 'null') {
      return _filter(inputCalculationRates!, (item) => _isNull(item.rate_start_at))
    }

    return _filter(inputCalculationRates!, (item) => !_isNull(item.rate_start_at))
  }

  const contentArrRent = [
    {
      title: (item: any) => <Typography>住居タイプ</Typography>,
      content: (item: any) => <Typography>{convertRentType(item?.rent_type)}</Typography>,
    },

    {
      title: (item: any) => <Typography>家賃</Typography>,
      content: (item: any) => (
        <Typography>
          {isSelfRentType(item?.rent_type)
            ? `${convertLocaleString(item?.rent_monthly)}円/月`
            : `${convertLocaleString(item?.whole_rent_monthly)}円/月`}
        </Typography>
      ),
    },

    {
      title: (item: any) => <Typography>自分で払う家賃</Typography>,
      content: (item: any) => (
        <Typography>{`${convertLocaleString(item?.rent_monthly)}円/月`}</Typography>
      ),
      condition: (item: any) => !isSelfRentType(item?.rent_type),
    },

    {
      title: (item: any) => <Typography>次回更新月</Typography>,
      content: (item: any) =>
        _isNull(item?.next_update_month_at) ? (
          <Typography>更新なし</Typography>
        ) : (
          <Typography>
            {convertDateToTwoTypesYearsDateFormat(
              item?.next_update_month_at,
              'yearMonthWithJapaneseYear',
            )}
          </Typography>
        ),
    },

    {
      title: (item: any) => <Typography>次回更新費用</Typography>,
      content: (item: any) => (
        <Typography>
          {isSelfRentType(item?.rent_type)
            ? `${convertLocaleString(item?.next_update_cost)}円`
            : `${convertLocaleString(item?.whole_next_update_cost)}円`}
        </Typography>
      ),
      condition: (item: any) => !_isNull(item?.next_update_month_at),
    },

    {
      title: (item: any) => (
        <Typography>
          自分で払う
          <br />
          更新費用
        </Typography>
      ),
      content: (item: any) => (
        <Typography>{`${convertLocaleString(item?.next_update_cost)}円`}</Typography>
      ),
      condition: (item: any) =>
        !_isNull(item?.next_update_month_at) && !isSelfRentType(item?.rent_type),
    },
  ]

  const contentArrTransfer = [
    {
      title: (item: any) => <Typography>住居タイプ</Typography>,
      content: (item: any) => (
        <Typography>{convertHouseType(item?.house_type)}</Typography>
      ),
    },
    {
      title: (item: any) => <Typography>家賃</Typography>,
      content: (item: any) => (
        <Typography>{`${convertLocaleString(
          item?.input_housing_transfer!.transfer_rent,
        )}円/月`}</Typography>
      ),
    },
    {
      title: (item: any) => <Typography>次回更新</Typography>,
      content: (item: any) =>
        _isNull(item?.input_housing_transfer!.transfer_update_month_at) ? (
          <Typography>更新なし</Typography>
        ) : (
          <Typography>
            {convertDateToTwoTypesYearsDateFormat(
              item?.input_housing_transfer!.transfer_update_month_at,
              'yearMonthWithJapaneseYear',
            )}
          </Typography>
        ),
    },
    {
      title: (item: any) => <Typography>次回更新費用</Typography>,
      content: (item: any) => (
        <Typography>{`${convertLocaleString(
          item?.input_housing_transfer!.transfer_update_cost,
        )}円`}</Typography>
      ),
      condition: (item: any) =>
        !_isNull(item?.input_housing_transfer!.transfer_update_month_at),
    },
  ]

  // ここから
  const contentArrSelfOwned: any = [
    {
      title: (item: any) => <Typography>住居タイプ</Typography>,
      content: (item: any) => {
        return <Typography>{convertHouseType(item?.house_type)}</Typography>
      },
    },
    {
      title: (item: any) => <Typography>家タイプ</Typography>,
      content: (item: any) => {
        return (
          <Typography>
            {convertOwnedHouseType(item!.input_housing_self_owned.house_type)}
          </Typography>
        )
      },
    },
    {
      title: (item: any) => (
        <Typography>
          購入時の
          <br className="md:hidden" />
          家の状態
        </Typography>
      ),
      content: (item: any) => (
        <Typography>
          {convertHousingType2(
            item!.input_housing_self_owned.housing_type2,
            isMansionType(item!.input_housing_self_owned.house_type),
          )}
        </Typography>
      ),
    },
    {
      title: (item: any) => <Typography>築年</Typography>,
      content: (item: any) => (
        <Typography>
          {convertDateToTwoTypesYearsDateFormat(
            item!.input_housing_self_owned.constructed_at,
            'japaneseYear',
          )}
        </Typography>
      ),
    },
    {
      title: (item: any) => <Typography>引渡時期</Typography>,
      content: (item: any) => (
        <Typography>
          {convertDateToTwoTypesYearsDateFormat(
            item!.input_housing_self_owned.purchased_at,
            'japaneseYear',
          )}
        </Typography>
      ),
    },
    {
      title: (item: any) => <Typography>購入時の土地価格</Typography>,
      content: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item!.input_housing_self_owned.property_price_land_manyen,
          )}万円`}
        </Typography>
      ),
      condition: (item: any) => !isMansionType(item!.input_housing_self_owned.house_type),
    },
    {
      title: (item: any) => <Typography>購入時の建物価格</Typography>,
      content: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item!.input_housing_self_owned.property_price_building_manyen,
          )}万円`}
        </Typography>
      ),
      condition: (item: any) => !isMansionType(item!.input_housing_self_owned.house_type),
    },
    {
      title: (item: any) => <Typography>購入価格</Typography>,
      content: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item!.input_housing_self_owned.property_price_manyen,
          )}万円`}
        </Typography>
      ),
      condition: (item: any) => isMansionType(item!.input_housing_self_owned.house_type), // マンション
    },
    {
      title: (item: any) => (
        <Typography>
          固定資産税・
          <br />
          都市計画税
        </Typography>
      ),
      content: (item: any) => (
        <Typography>{`年間 ${convertLocaleString(
          item!.input_housing_self_owned.tax_yearly,
        )}円`}</Typography>
      ),
    },
    {
      title: (item: any) => <Typography>リフォームの頻度</Typography>,
      content: (item: any) => (
        <Typography>{`${convertLocaleString(
          item!.input_housing_self_owned.repair_costs_span,
        )}年に1回`}</Typography>
      ),
    },
    {
      title: (item: any) => <Typography>リフォーム費</Typography>,
      content: (item: any) => (
        <Typography>{`一回あたり ${convertLocaleString(
          item!.input_housing_self_owned.repair_costs,
        )}円`}</Typography>
      ),
    },
    {
      title: (item: any) => <Typography>管理費・修繕積立金合計</Typography>,
      content: (item: any) => (
        <Typography>{`${convertLocaleString(
          item!.input_housing_self_owned
            .management_fee_monthly_and_repair_reserve_fund_total,
        )}円/月`}</Typography>
      ),
      condition: (item: any) =>
        isMansionType(item?.input_housing_self_owned.house_type) && // マンション
        !_isNull(
          item!.input_housing_self_owned
            .management_fee_monthly_and_repair_reserve_fund_total,
        ),
    },
    {
      title: (item: any) => <Typography>修繕積立金</Typography>,
      content: (item: any) => (
        <Typography>{`${convertLocaleString(
          item!.input_housing_self_owned.repair_reserve_fund,
        )}円/月`}</Typography>
      ),
      condition: (item: any) =>
        isMansionType(item?.input_housing_self_owned.house_type) && // マンション
        _isNull(
          item!.input_housing_self_owned
            .management_fee_monthly_and_repair_reserve_fund_total,
        ),
    },
    {
      title: (item: any) => <Typography>積立金の上昇率</Typography>,
      content: (item: any) => (
        <>
          {item!.input_housing_self_owned.repair_reserve_fund_increase_type ===
            REPAIR_RESERVE_FUND_RATE_TYPE.fixed && (
            <Typography>
              {`${convertLocaleString(
                item!.input_housing_self_owned.repair_reserve_fund_amount,
              )}円/月ずつ`}
            </Typography>
          )}
          {item!.input_housing_self_owned.repair_reserve_fund_increase_type ===
            REPAIR_RESERVE_FUND_RATE_TYPE.change && (
            <>
              <Typography>
                {`築${
                  item!.input_housing_self_owned.repair_reserve_fund_increase_start
                }年目から
                  ${
                    item!.input_housing_self_owned.repair_reserve_fund_increase_span
                  }年毎に`}
              </Typography>
              <Typography>
                {`築${
                  item!.input_housing_self_owned.repair_reserve_fund_increase_finish
                }年目まで ${convertLocaleString(
                  item!.input_housing_self_owned.repair_reserve_fund_increase_percent,
                )}％ずつ上昇`}
              </Typography>
            </>
          )}
          {item!.input_housing_self_owned.repair_reserve_fund_increase_type ===
            REPAIR_RESERVE_FUND_RATE_TYPE.noSet && <Typography>設定なし</Typography>}
        </>
      ),
      condition: (item: any) =>
        isMansionType(item?.input_housing_self_owned.house_type) && // マンション
        _isNull(
          item!.input_housing_self_owned
            .management_fee_monthly_and_repair_reserve_fund_total,
        ),
    },
    {
      title: (item: any) => <Typography>その他費用</Typography>,
      content: (item: any) => (
        <Typography>{`年間 ${convertLocaleString(
          item!.input_housing_self_owned.other_cost_yearly,
        )}円`}</Typography>
      ),
    },
  ]

  // 以下ローンの部分
  const contentArrSelfOwnedLoan: any = [
    {
      title: (item: any) => <Typography>住宅ローン有無</Typography>,
      content: (item: any) => <Typography>借りていない</Typography>,
      condition: (item: any) => _isEmpty(item!.input_housing_self_owned.input_loans),
    },
    {
      title: (item: any) => <Typography>土地・建物で分割</Typography>,
      content: (item: any) => (
        <Typography>
          {convertLoanType2(item!.input_housing_self_owned.loan_type2)}
        </Typography>
      ),
      condition: (item: any) => !_isEmpty(item!.input_housing_self_owned.input_loans),
    },
  ]
  // ローン（建物 or 土地）
  const contentArrLoanCommon: any = [
    {
      title: (item: any) => <Typography>ローン方式</Typography>,
      content: (item: any) => (
        <Typography>{convertInputLoanLoanType(item.loan_type)}</Typography>
      ),
      condition: (item: any) => true,
    },
    {
      title: (item: any) => <Typography>返済計画表</Typography>,
      content: (item: any) => (
        <Typography>{convertRepaymentChart(item.repayment_chart)}</Typography>
      ),
      condition: (item: any) => true,
    },
    {
      title: (item: any) => <Typography>借り入れ先</Typography>,
      content: (item: any) => (
        <Typography>{convertLoanLender(item.loan_lender)}</Typography>
      ),
      condition: (item: any) => true,
    },
  ]

  // チャートローン有
  const contentArrWithChartLoan: any = [
    {
      title: (item: any) => <Typography>最終返済日</Typography>,
      content: (item: any) => (
        <Typography>
          {convertToJapaneseFullDate(item.input_loan_chart!.repayment_end_at)}
        </Typography>
      ),
      condition: (item: any) => true,
    },
    {
      title: (item: any) => <Typography>現在の金利</Typography>,
      content: (item: any) => (
        <Typography>
          {`${
            selectAllInputLoanRates(
              item.input_loan_chart!.input_loan_rates!,
              'null',
              'monthly',
            )?.[0]?.changed_rate_percent ?? 0
          }％`}
        </Typography>
      ),
      condition: (item: any) => true,
    },
    {
      title: (item: any) => <Typography>金利の変化</Typography>,
      content: (item: any) => {
        return item.input_loan_chart!.rate_change_monthly === API_FLAG.off ? (
          <Typography>ない</Typography>
        ) : (
          <>
            {Object.keys(
              selectAllInputLoanRates(
                item.input_loan_chart!.input_loan_rates!,
                'notNull',
                'monthly',
              ),
            ).map((_, loanRateIndex) => {
              const loanRates = selectAllInputLoanRates(
                item.input_loan_chart!.input_loan_rates!,
                'notNull',
                'monthly',
              )

              return (
                <Typography key={loanRateIndex}>
                  {convertRateChangeValues(
                    loanRates[loanRateIndex].rate_start_at,
                    loanRates[loanRateIndex]?.changed_rate_percent,
                  )}
                </Typography>
              )
            })}
          </>
        )
      },
      condition: (item: any) => true,
    },
    {
      title: (item: any) => (
        <Typography>
          データ登録月の
          <br />
          返済予定日
        </Typography>
      ),
      content: (item: any) => (
        <Typography>
          {convertToJapaneseFullDate(item.input_loan_chart!.repayment_date_this_month_at)}
        </Typography>
      ),
      condition: (item: any) => true,
    },
    {
      title: (item: any) => (
        <Typography>
          データ登録時の
          <br />
          毎月支払い分の残元金合計
        </Typography>
      ),
      content: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_loan_chart!.remaining_principal_monthly_total,
          )}円`}
        </Typography>
      ),
      condition: (item: any) => true,
    },
    {
      title: (item: any) => (
        <Typography>
          データ登録時の
          <br />
          ボーナス支払い分の残元金合計
        </Typography>
      ),
      content: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_loan_chart!.remaining_principal_bonus_total,
          )}円`}
        </Typography>
      ),
      condition: (item: any) => isBonusFlgOn(String(item.input_loan_chart!.bonus_flg)),
    },
    {
      title: (item: any) => <Typography>団体信用生命保険</Typography>,
      content: (item: any) => (
        <Typography>
          {convertInsuranceType(item.input_loan_chart!.life_insurance)}
        </Typography>
      ),
      condition: (item: any, index?: any) =>
        isFlatLoan(inputHousingSelfOwnedLoans[index].loan_lender),
    },
    {
      title: (item: any) => <Typography>夫婦連生団信</Typography>,
      content: (item: any) => (
        <Typography>
          {convertInsuranceType(item.input_loan_chart!.married_couple_group_shin)}
        </Typography>
      ),
      condition: (item: any) => isPairLoan(item.loan_type),
    },
    {
      title: (item: any) => <Typography>住宅ローン控除</Typography>,
      content: (item: any) => (
        <Typography>
          {convertDeductionType(item.input_loan_chart!.loan_deduction)}
        </Typography>
      ),
      condition: (item: any) => true,
    },
  ]
  // チャートローン無
  const contentArrNotWithChartLoan = [
    {
      title: (item: any) => <Typography>過去の繰り上げ返済有無</Typography>,
      content: (item: any, index?: any) => {
        return !isWithAdvanceRepayment(item.input_loan_calculation!.advance_repayment) ? (
          <Typography>ない</Typography>
        ) : (
          <>
            <Typography>ある</Typography>
            {Object.keys(item.input_loan_calculation!.input_advances!).map(
              (_, advanceIndex) => {
                const { input_advances } = inputHousingSelfOwnedLoans[
                  index
                ].input_loan_calculation!

                return (
                  <div key={advanceIndex}>
                    <Typography>
                      {convertAdvanceType(
                        item.input_loan_calculation!.input_advances![advanceIndex]
                          .repayment_type,
                      )}
                    </Typography>
                    <Typography>
                      {convertAdvanceValues(
                        item.input_loan_calculation!.input_advances![advanceIndex]
                          .advance_repayment_month_at,
                        item.input_loan_calculation!.input_advances![advanceIndex]
                          .repayment_amount,
                      )}
                    </Typography>
                  </div>
                )
              },
            )}
          </>
        )
      },
      condition: (item: any) => item.is_flat_applied || _isNull(item.is_flat_applied),
    },
    {
      title: (item: any) => <Typography>当初の借入金額</Typography>,
      content: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_loan_calculation!.started_borrowing_amount_manyen,
          )}万円`}
        </Typography>
      ),
      condition: (item: any) => true,
    },
    {
      title: (item: any) => <Typography>設定している返済期間</Typography>,
      content: (item: any) => (
        <Typography>
          {`${convertLocaleString(item.input_loan_calculation!.repayment_period)}年間`}
        </Typography>
      ),
      condition: (item: any) => true,
    },
    {
      title: (item: any, index?: any) => (
        <Typography>
          {inputHousingSelfOwnedLoans![index].is_flat_applied ? '金利' : '当初金利'}
        </Typography>
      ),
      content: (item: any) => (
        <Typography>
          {`${convertLocaleString(
            item.input_loan_calculation!.input_calculation_rates![0]
              ?.changed_rate_percent,
          )}％`}
        </Typography>
      ),
      condition: (item: any, index?: any) =>
        isFlatLoan(inputHousingSelfOwnedLoans![index].loan_lender) &&
        isNotEmptyValue(
          inputHousingSelfOwnedLoans![index].input_loan_calculation!
            .input_calculation_rates![0]?.changed_rate_percent,
        ),
    },
    {
      title: (item: any) => <Typography>当初以降の金利</Typography>,
      content: (item: any) => {
        return Object.keys(
          selectAllInputCalculationRatesByRateStartAt(
            item.input_loan_calculation!.input_calculation_rates!,
            'notNull',
          ),
        ).map((_, calcRateIndex) => {
          const calcRates = selectAllInputCalculationRatesByRateStartAt(
            item.input_loan_calculation!.input_calculation_rates!,
            'notNull',
          )

          return (
            <Typography key={calcRateIndex}>
              {convertRateChangeValues(
                calcRates![calcRateIndex].rate_start_at,
                calcRates![calcRateIndex]?.changed_rate_percent,
              )}
            </Typography>
          )
        })
      },
      condition: (item: any, index?: any) =>
        isFlatLoan(inputHousingSelfOwnedLoans![index].loan_lender) &&
        !item.is_flat_applied &&
        selectAllInputCalculationRatesByRateStartAt(
          [...item.input_loan_calculation!.input_calculation_rates!],
          'notNull',
        ).length > 0,
    },
    {
      title: (item: any) => <Typography>現在の借入金利</Typography>,
      content: (item: any) => (
        <Typography>
          {`${
            selectAllInputCalculationRatesByRateStartAt(
              item.input_loan_calculation!.input_calculation_rates!,
              'null',
            )?.[0].changed_rate_percent
          }％`}
        </Typography>
      ),
      condition: (item: any, index?: any) =>
        !isFlatLoan(inputHousingSelfOwnedLoans![index].loan_lender),
    },
    {
      title: (item: any) => <Typography>借入金利の変化</Typography>,
      content: (item: any) => {
        return Object.keys(
          selectAllInputCalculationRatesByRateStartAt(
            item.input_loan_calculation!.input_calculation_rates!,
            'notNull',
          ),
        ).map((_, calcRateIndex) => {
          const calcRates = selectAllInputCalculationRatesByRateStartAt(
            item.input_loan_calculation!.input_calculation_rates!,
            'notNull',
          )

          return (
            <Typography key={calcRateIndex}>
              {convertRateChangeValues(
                calcRates![calcRateIndex].rate_start_at,
                calcRates![calcRateIndex]?.changed_rate_percent,
              )}
            </Typography>
          )
        })
      },
      condition: (item: any, index?: any) =>
        !isFlatLoan(inputHousingSelfOwnedLoans![index].loan_lender) &&
        selectAllInputCalculationRatesByRateStartAt(
          item.input_loan_calculation!.input_calculation_rates!,
          'notNull',
        ).length > 0,
    },
    {
      title: (item: any) => <Typography>団体信用生命保険</Typography>,
      content: (item: any) => (
        <Typography>
          {convertInsuranceType(String(item.input_loan_calculation!.life_insurance))}
        </Typography>
      ),
      condition: (item: any, index?: any) =>
        isFlatLoan(inputHousingSelfOwnedLoans![index].loan_lender),
    },
    {
      title: (item: any) => <Typography>夫婦連生団信</Typography>,
      content: (item: any) => (
        <Typography>
          {convertInsuranceType(
            String(item.input_loan_calculation!.married_couple_group_shin),
          )}
        </Typography>
      ),
      condition: (item: any) => isPairLoan(item.loan_type),
    },
    {
      title: (item: any) => <Typography>住宅ローン控除</Typography>,
      content: (item: any) => (
        <Typography>
          {convertDeductionType(String(item.input_loan_calculation!.loan_deduction))}
        </Typography>
      ),
      condition: (item: any) => true,
    },
  ]

  return (
    <>
      <div className="pb-[20px]">
        {!!inputHousingSelfOwned ? (
          <>
            <div className="flex justify-between">
              <CircleTitle className="pb-[5px]" title="現在の住居" />
              <div className="flex justify-end mb-[8px]">
                <Button onClick={() => setIsOpenHousingModal(true)}>
                  <i className="ico edit text-[14px] text-black-700" />
                  <Typography className="pl-[5px] text-[14px] text-black-700">
                    編集
                  </Typography>
                </Button>
              </div>
            </div>
            <table className="w-[100%] text-[12px] md:text-[14px]">
              <tbody>
                {contentArrSelfOwned.map((v: any, i: number) => {
                  return (
                    (!v.condition ||
                      (v.condition && v.condition(inputHousingSelfOwned))) && (
                      <tr
                        className="h-[45px] odd:bg-black-100"
                        key={`${inputHousingSelfOwned.id}_${i}`}
                      >
                        <td className="w-[40%] px-[16px]">
                          {v.title(inputHousingSelfOwned)}
                        </td>
                        <td className="w-[60%] px-[16px]">
                          {v.content(inputHousingSelfOwned)}
                        </td>
                      </tr>
                    )
                  )
                })}
              </tbody>
            </table>

            {!!contentArrSelfOwnedLoan?.input_loans &&
              contentArrSelfOwnedLoan?.input_loans.length > 0 && (
                <>
                  <div className="mt-[16px] border-b-[0.5px] pb-[10px] mb-[10px]">
                    <Typography className="font-bold">住宅ローン情報</Typography>
                  </div>

                  <table className="w-[100%] text-[12px] md:text-[14px]">
                    <tbody>
                      {contentArrSelfOwnedLoan.map((v: any, i: number) => {
                        return (
                          (!v.condition ||
                            (v.condition && v.condition(inputHousingSelfOwned))) && (
                            <tr
                              className="h-[45px] odd:bg-black-100"
                              key={`${inputHousingSelfOwned.id}_${i}`}
                            >
                              <td className="w-[40%] px-[16px]">
                                {v.title(inputHousingSelfOwned)}
                              </td>
                              <td className="w-[60%] px-[16px]">
                                {v.content(inputHousingSelfOwned)}
                              </td>
                            </tr>
                          )
                        )
                      })}
                    </tbody>
                  </table>
                </>
              )}

            {Object.keys(inputHousingSelfOwnedLoans).map((_, loanIndex) => {
              const inputLoan = inputHousingSelfOwnedLoans[loanIndex]
              return (
                <>
                  {(loanIndex === 0 ||
                    (inputHousingSelfOwned!.input_housing_self_owned.loan_type2 ===
                      API_FLAG.on &&
                      ((loanIndex === 1 &&
                        isOneLoanPattern(inputHousingSelfOwnedLoans[0])) ||
                        (loanIndex === 2 &&
                          isTwoLoanPattern(inputHousingSelfOwnedLoans[0]))))) && (
                    <>
                      {isSplitedLoan(inputHousingSelfOwned.input_housing_self_owned) && (
                        <div className="mt-[16px] border-b-[0.5px] pb-[10px] mb-[10px]">
                          <Typography>
                            {convertLandOrBuildingLoanTitle(
                              loanIndex,
                              inputHousingSelfOwned.input_housing_self_owned!,
                            )}
                          </Typography>
                        </div>
                      )}
                      <table className="w-[100%] text-[12px] md:text-[14px]">
                        <tbody>
                          {contentArrLoanCommon.map((v: any, i: number) => {
                            return (
                              (!v.condition ||
                                (v.condition && v.condition(inputLoan))) && (
                                <tr
                                  className="h-[45px] even:bg-black-100"
                                  key={`${inputLoan.id}_${i}`}
                                >
                                  <td className="w-[40%] px-[16px]">
                                    {v.title(inputLoan)}
                                  </td>
                                  <td className="w-[60%] px-[16px]">
                                    {v.content(inputLoan)}
                                  </td>
                                </tr>
                              )
                            )
                          })}
                        </tbody>
                      </table>
                    </>
                  )}

                  {!_isNull(inputLoan.input_loan_chart) &&
                    isWithChartLoan(inputLoan.repayment_chart) && (
                      <>
                        {isPairLoan(inputLoan.loan_type) && (
                          <Typography className="u-bold">
                            {convertPersonFlg(inputLoan.input_loan_chart!.person_flg!)}
                          </Typography>
                        )}
                        <table className="w-[100%] text-[12px] md:text-[14px]">
                          <tbody>
                            {contentArrWithChartLoan.map((v: any, i: number) => {
                              return (
                                (!v.condition ||
                                  (v.condition && v.condition(inputLoan, loanIndex))) && (
                                  <tr
                                    className="h-[45px] odd:bg-black-100"
                                    key={`${inputLoan.id}_${i}`}
                                  >
                                    <td className="w-[40%] px-[16px]">
                                      {v.title(inputLoan)}
                                    </td>
                                    <td className="w-[60%] px-[16px]">
                                      {v.content(inputLoan)}
                                    </td>
                                  </tr>
                                )
                              )
                            })}
                          </tbody>
                        </table>
                      </>
                    )}

                  {!_isNull(inputLoan.input_loan_calculation) &&
                    !isWithChartLoan(inputLoan.repayment_chart) && (
                      <>
                        {isPairLoan(inputLoan.loan_type) && (
                          <Typography className="u-bold">
                            {convertPersonFlg(
                              inputLoan.input_loan_calculation?.person_flg!,
                            )}
                          </Typography>
                        )}
                        {isFlatLoan(inputLoan.loan_lender) && (
                          <Typography className="u-bold">
                            {convertIsFlatApplied(inputLoan.is_flat_applied!)}
                          </Typography>
                        )}
                        <table className="w-[100%] text-[12px] md:text-[14px]">
                          <tbody>
                            {contentArrNotWithChartLoan.map((v: any, i: number) => {
                              return (
                                (!v.condition ||
                                  (v.condition && v.condition(inputLoan, loanIndex))) && (
                                  <tr
                                    className="h-[45px] odd:bg-black-100"
                                    key={`${inputLoan.id}_${i}`}
                                  >
                                    <td className="w-[40%] px-[16px]">
                                      {v.title(inputLoan, loanIndex)}
                                    </td>
                                    <td className="w-[60%] px-[16px]">
                                      {v.content(inputLoan, loanIndex)}
                                    </td>
                                  </tr>
                                )
                              )
                            })}
                          </tbody>
                        </table>
                      </>
                    )}
                </>
              )
            })}
          </>
        ) : (
          inputHousingRent && (
            <>
              <div className="flex justify-between">
                <CircleTitle title="現在の住居" />
                <div className="flex justify-end mb-[8px]">
                  <Button onClick={() => setIsOpenHousingModal(true)}>
                    <i className="ico edit text-[14px] text-black-700" />
                    <Typography className="pl-[5px] text-[14px] text-black-700">
                      編集
                    </Typography>
                  </Button>
                </div>
              </div>
              <table className="w-[100%] text-[12px] md:text-[14px]">
                <tbody>
                  {contentArrRent.map((v, i) => {
                    return (
                      !!inputHousingRent?.input_housing_rents &&
                      (!v.condition ||
                        (v.condition &&
                          v.condition(inputHousingRent?.input_housing_rents[0]))) && (
                        <tr
                          className="h-[45px] odd:bg-black-100"
                          key={`${inputHousingRent?.id}_${i}`}
                        >
                          <td className="w-[40%] px-[16px]">
                            {v.title(inputHousingRent?.input_housing_rents[0])}
                          </td>
                          <td className="w-[60%] px-[16px]">
                            {v.content(inputHousingRent?.input_housing_rents[0])}
                          </td>
                        </tr>
                      )
                    )
                  })}
                </tbody>
              </table>
            </>
          )
        )}
      </div>

      {!inputHousingSelfOwned && !inputHousingRent && (
        <div className="pb-[20px]">
          <div className="flex justify-between">
            <CircleTitle className="pb-[5px]" title="現在の住居" />
            <div className="flex justify-end mb-[8px]">
              <Button onClick={() => setIsOpenHousingModal(true)}>
                <i className="ico edit text-[14px] text-black-700" />
                <Typography className="pl-[5px] text-[14px] text-black-700">
                  編集
                </Typography>
              </Button>
            </div>
          </div>
          <Typography className="text-[14px] md:text-[16px] text-center" isBold>
            現在の住居が登録されていません。
          </Typography>
        </div>
      )}

      {!!inputHousingTransfer ? (
        <div className="mt-30">
          <div className="flex justify-between">
            <CircleTitle title="単身赴任先" classNameTitle="text-[20px] md:text-[24px]" />
            <div className="flex justify-end mb-[8px]">
              <Button onClick={() => setIsOpenHousingTransferModal(true)}>
                <i className="ico edit text-[14px] text-black-700" />
                <Typography className="pl-[5px] text-[14px] text-black-700">
                  編集
                </Typography>
              </Button>
            </div>
          </div>
          <table className="w-[100%] text-[12px] md:text-[14px]">
            <tbody>
              {contentArrTransfer.map((v: any, i: number) => {
                return (
                  (!v.condition ||
                    (v.condition && v.condition(inputHousingTransfer))) && (
                    <tr
                      className="h-[45px] odd:bg-black-100"
                      key={`${inputHousingTransfer.id}_${i}`}
                    >
                      <td className="w-[40%] px-[16px]">
                        {v.title(inputHousingTransfer)}
                      </td>
                      <td className="w-[60%] px-[16px]">
                        {v.content(inputHousingTransfer)}
                      </td>
                    </tr>
                  )
                )
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <div>
          <div className="flex justify-between">
            <CircleTitle className="pb-[5px]" title="単身赴任先" />
            <div className="flex justify-end mb-[8px]">
              <Button onClick={() => setIsOpenHousingTransferModal(true)}>
                <i className="ico edit text-[14px] text-black-700" />
                <Typography className="pl-[5px] text-[14px] text-black-700">
                  編集
                </Typography>
              </Button>
            </div>
          </div>
          <Typography className="text-[14px] md:text-[16px] text-center" isBold>
            単身赴任先が登録されていません。
          </Typography>
        </div>
      )}

      <Modal
        className="lg:w-[1024px] w-[95%]"
        isOpen={isOpenHousingModal}
        onClose={() => setIsOpenHousingModal(false)}
      >
        <CircleTitle className="pb-[32px]" title="現在の住居" />
        <MyDataHousingsEditForm
          id={inputHousingSelfOwned?.id ?? inputHousingRent?.id}
          onClose={() => setIsOpenHousingModal(false)}
          onSubmit={() => {
            setIsOpenHousingModal(false)
            props.onUpdate()
          }}
        />
      </Modal>

      <Modal
        className="lg:w-[1024px] w-[95%]"
        isOpen={isOpenHousingTransferModal}
        onClose={() => setIsOpenHousingTransferModal(false)}
      >
        <CircleTitle className="pb-[32px]" title="単身赴任先" />
        <MyDataHousingsEditFormTransfer
          id={
            inputHousingTransfer?.id != null
              ? String(inputHousingTransfer?.id)
              : undefined
          }
          onClose={() => setIsOpenHousingTransferModal(false)}
          onSubmit={() => {
            setIsOpenHousingTransferModal(false)
            props.onUpdate()
          }}
        />
      </Modal>
    </>
  )
}

const InputHousingBody: FC<{ onUpdate: () => void }> = (props) => {
  return (
    <SimulationAccordion className="pb-[16px]" title="現在の住居">
      <InputHousingTables onUpdate={props.onUpdate} />
    </SimulationAccordion>
  )
}

export default InputHousingBody
