import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { unauthorizedCheck } from '../accountsSlice'
import {
  postV2FutureLoanAdvance,
  patchFutureLoanAdvanceV2,
  deleteFutureLoanAdvanceV2,
} from '../../api/futures/futureLoanAdvances'
import { convertMomentDate } from '../../models/commonsModelFunc'
import { FutureLoanAdvance } from '../../models/futures/futureLoanAdvancesModel'
import { AppThunk } from '../../store'

interface FutureLoanAdvanceState {
  futureLoanAdvances: FutureLoanAdvance[]
  isLoading: boolean
}

const initialState: FutureLoanAdvanceState = {
  futureLoanAdvances: [],
  isLoading: false,
}

const futureLoanAdvancesSlice = createSlice({
  name: 'futureLoanAdvances',
  initialState,
  reducers: {
    setFutureLoanAdvances: (state, action: PayloadAction<FutureLoanAdvance[]>) => {
      const { payload } = action

      state.futureLoanAdvances = payload.map((item) => ({
        ...item,
        repayment_year: convertMomentDate(item.repayment_date, 'YYYY'),
        repayment_month: convertMomentDate(item.repayment_date, 'MM'),
      }))

      return state
    },
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
  },
})

export const {
  setFutureLoanAdvances,
  requestStart,
  requestSuccess,
  requestFailure,
} = futureLoanAdvancesSlice.actions
export const futureLoanAdvancesReducer = futureLoanAdvancesSlice.reducer

export const createV2FutureLoanAdvance = (
  values: any,
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postV2FutureLoanAdvance(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const updateFutureLoanAdvanceV2 = (
  values: any,
  id: number,
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchFutureLoanAdvanceV2(values, id)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const destroyFutureLoanAdvanceV2 = (
  id: number,
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  deleteFutureLoanAdvanceV2(id)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}
