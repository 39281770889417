import React, { FC, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { findIndex as _findIndex, includes as _includes } from 'lodash'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import Typography from '@/components/v2/atoms/Typography'
import ChevronLeftIcon from '@/assets/images/v2/chevron-left.svg'
import Button from '@/components/v2/atoms/Button'
import { setIsConfirmingMydata } from '@/containers/futures/futureBasesSlice'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/store'

const ConfirmMydata: FC = () => {
  const locaiton = useLocation()
  const dispatch = useDispatch()

  const query = new URLSearchParams(locaiton.search)
  const futureBaseId = query.get('future_base_id')
  const action = query.get('action')

  const { handleMovePage } = useCustomHistory()

  const [confirmedCallBackURL, setConfirmedCallBackURL] = useState<string>('')

  const { isConfirmingMydata } = useSelector((state: RootState) => state.futureBases)

  useEffect(() => {
    const path = location.pathname
    if (!path.includes('v2/myData/') || path.includes('v2/myData/journals')) return

    const item = sessionStorage.getItem('confirmMydataState')
    if (item == null && futureBaseId != null && action != null) {
      const callbackPath =
        action === 'edit'
          ? '/v2/simulations/' + futureBaseId
          : '/v2/simulations/new?id=' + futureBaseId
      const value = { callbackPath, confirmed: false }
      sessionStorage.setItem('confirmMydataState', JSON.stringify(value))
      setConfirmedCallBackURL(callbackPath)
      dispatch(setIsConfirmingMydata(true))
    } else if (item != null) {
      const confirmMydataState = JSON.parse(item)
      setConfirmedCallBackURL(confirmMydataState.callbackPath)
      dispatch(setIsConfirmingMydata(true))
    } else {
      dispatch(setIsConfirmingMydata(false))
    }
  }, [locaiton.pathname])

  return (
    <>
      {isConfirmingMydata && (
        <div
          className={
            'absolute bottom-[60px] left-[12px] md:left-[40px] ' +
            (!isConfirmingMydata ? ' hidden' : '')
          }
        >
          <Button
            className="p-[12px] bg-white rounded-[8px] border border-solid border-tertiary-500 shadow-[0px_0px_12px_rgba(0,0,0,0.15)]"
            onClick={() => {
              const value = { confirmed: true }
              sessionStorage.setItem('confirmMydataState', JSON.stringify(value))
              dispatch(setIsConfirmingMydata(false))
              handleMovePage(confirmedCallBackURL)
            }}
          >
            <ChevronLeftIcon />
            <Typography
              className="leading-normal text-[14px] text-tertiary-500 text-left"
              isBold
            >
              このデータを元に
              <br className="hidden md:block" />
              シミュレーション編集に戻る
            </Typography>
          </Button>
        </div>
      )}
    </>
  )
}

export default ConfirmMydata
