import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { sortBy as _sortBy } from 'lodash'
import { getLearningVideos, getLearningVideo } from '@/api/videoPlatforms/learningVideos'
import { videoUnauthorizedCheck } from '@/containers/videoPlatforms/authenticationsSlice'
import { LearningVideoResponse, LearningVideo } from '@/models/commonLearningVideosModel'
import { AppThunk } from '@/store'

interface LearningVideoState {
  learningVideos: LearningVideo[]
  learningVideo: LearningVideo | null | undefined
  isLoading: boolean
}

const initialState: LearningVideoState = {
  learningVideos: [],
  learningVideo: undefined,
  isLoading: false,
}

const videoLearningVideosSlice = createSlice({
  name: 'videoLearningVideos',
  initialState,
  reducers: {
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    getLearningVideosSuccess: (state, action: PayloadAction<LearningVideoResponse[]>) => {
      state.learningVideos = _sortBy(action.payload, 'order')
      state.isLoading = false
      return state
    },
    getLearningVideoSuccess: (state, action: PayloadAction<LearningVideoResponse>) => {
      state.learningVideo = action.payload
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.learningVideo = null
      state.isLoading = false
      return state
    },
    resetLearningVideo: (state) => {
      state.learningVideo = undefined
      return state
    },
  },
})

export const {
  requestStart,
  requestSuccess,
  getLearningVideosSuccess,
  getLearningVideoSuccess,
  requestFailure,
  resetLearningVideo,
} = videoLearningVideosSlice.actions
export const videoLearningVideosReducer = videoLearningVideosSlice.reducer

export const fetchLearningVideos = (): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  getLearningVideos()
    .then((response) => dispatch(getLearningVideosSuccess(response)))
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(videoUnauthorizedCheck(error))
    })
}

export const fetchLearningVideo = (id: number): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  getLearningVideo(id)
    .then((response) => dispatch(getLearningVideoSuccess(response)))
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(videoUnauthorizedCheck(error))
    })
}
export const initializeLearningVideo = (): AppThunk => async (dispatch) => {
  dispatch(resetLearningVideo())
}
