import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { unauthorizedAdminCheck } from '@/containers/admins/fpAccountsSlice'
import { ApiError } from '@/models/commonsModel'
import { AppThunk } from '@/store'
import {
  LearningVideoDisplay,
  LearningVideoDisplayCreateValues,
  LearningVideoDisplaysResponse,
  LearningVideoDisplayUpdateValues,
} from '@/models/videoPlatforms/learningVideoDisplaysModel'
import {
  getLearningVideoDisplays,
  patchLearningVideoDisplay,
  postLearningVideoDisplays,
} from '@/api/videoPlatforms/learningVideoDisplays'

interface LearningVideoDisplayState {
  learningVideoDisplays: LearningVideoDisplay[]
  isLoading: boolean
}

const initialState: LearningVideoDisplayState = {
  learningVideoDisplays: [],
  isLoading: false,
}

const learningVideoDisplaySlice = createSlice({
  name: 'learningVideoDisplay',
  initialState,
  reducers: {
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    getLearningVideosDemoAccountsSuccess: (
      state,
      action: PayloadAction<LearningVideoDisplaysResponse[]>,
    ) => {
      state.learningVideoDisplays = action.payload
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
  },
})

export const {
  requestStart,
  requestSuccess,
  getLearningVideosDemoAccountsSuccess,
  requestFailure,
} = learningVideoDisplaySlice.actions

export const learningVideoDisplayReducer = learningVideoDisplaySlice.reducer

export const fetchLearningVideoDisplays = (): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  getLearningVideoDisplays()
    .then((response) => dispatch(getLearningVideosDemoAccountsSuccess(response)))
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedAdminCheck(error))
    })
}

export const updateLearningVideoDisplay = (
  values: LearningVideoDisplayUpdateValues,
  callback: () => void,
  resolve: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchLearningVideoDisplay(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      resolve(error)
    })
}
