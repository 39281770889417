import React, { FC, useState } from 'react'
import { useForm } from 'react-final-form'
import Typography from '@/components/v2/atoms/Typography'
import SelectBox from '@/components/v2/molecules/SelectBox'
import { required } from '@/utils/validate'
import { HOUSE_TYPE } from '@/models/commonHousingModelFunc'
import { INPUT_HOUSE_TYPE_BUTTON_ITEMS } from '@/constants/formItem/inputs/inputHousings'
import { isNotEmptyValue } from '@/models/commonsModelFunc'
import { INPUT_HOUSING_RENT_TYPE } from '@/models/inputs/inputHousingRentsModelFunc'

const CurrentHouseType: FC = () => {
  const [initialHouseType, _] = useState<number>()

  const { change } = useForm()

  const handleChangeHouseType = (houseType: number) => {
    const rentHouseTypeGroup = [HOUSE_TYPE.rent, HOUSE_TYPE.notSelf]
    let result = true

    // MEMO: 社宅・寮から賃貸、自己保有に変更する場合
    if (
      isNotEmptyValue(initialHouseType) &&
      rentHouseTypeGroup.includes(Number(initialHouseType)) &&
      Number(houseType) === HOUSE_TYPE.own
    ) {
      result = confirm(
        `住居タイプを変更して更新することで、\n現状住居のデータに紐づいているシミュレーション詳細ページの「将来の住居」データが全て削除されます。\n\n変更してもよろしいですか？`,
      )
    }
    // MEMO: 自己保有から賃貸、社宅・寮に変更する場合
    if (
      isNotEmptyValue(initialHouseType) &&
      initialHouseType === HOUSE_TYPE.own &&
      rentHouseTypeGroup.includes(houseType)
    ) {
      result = confirm(
        `住居タイプを変更して更新することで、\n現状住居のデータに紐づいているシミュレーション詳細ページの「住居の売却」、「住居の賃貸化」データが全て削除されます。\n\n変更してもよろしいですか？`,
      )
    }
    // MEMO: confirm後にキャンセルしても値が変わってしまうため、
    //       buttonの選択をsetTimeoutで初期値に戻す
    if (!result)
      return setTimeout(() => change('input_housing.house_type', initialHouseType), 5)

    if (houseType == HOUSE_TYPE.notSelf) {
      change(
        'input_housing.input_housing_rents_attributes[0]rent_type',
        INPUT_HOUSING_RENT_TYPE.companyRent,
      )
    }
    if (houseType == HOUSE_TYPE.rent) {
      change(
        'input_housing.input_housing_rents_attributes[0]rent_type',
        INPUT_HOUSING_RENT_TYPE.selfRent,
      )
    }
    return change('input_housing.house_type', houseType)
  }

  return (
    <>
      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
        <Typography
          className="min-w-[140px] lg:min-w-[210px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
          isBold
        >
          住居種別
        </Typography>
        <SelectBox
          name="input_housing.house_type"
          options={INPUT_HOUSE_TYPE_BUTTON_ITEMS}
          placeholder="選択する"
          isFullWidth
          validate={required}
          onChange={(event: any) => handleChangeHouseType(event.target.value)}
        />
      </div>
    </>
  )
}

export default CurrentHouseType
