import React, { FC, useEffect, useState, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  reduce as _reduce,
  filter as _filter,
  isNull as _isNull,
  isUndefined as _isUndefined,
  set as _set,
  findIndex as _findIndex,
} from 'lodash'
import ChevronRightIcon from '@/assets/images/v2/chevron-right.svg'
import RankingIcon from '@/assets/images/v2/ranking.svg'
import Button from '@/components/v2/atoms/Button'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import ButtonBlack from '@/components/v2/atoms/ButtonBlack'
import MarginContainer from '@/components/v2/atoms/MarginContainer'
import Modal from '@/components/v2/atoms/Modal'
import {
  INSURANCE_GRAPH_SET_TYPE,
  INSURANCE_FINANCIAL_TYPE,
} from '@/models/commonsModelFunc'
import Paper from '@/components/v2/atoms/Paper'
import Typography from '@/components/v2/atoms/Typography'
import LoadingOverlay from '@/components/v1/atoms/LoadingOverlay'
import Breadcrumb from '@/components/v2/molecules/Breadcrumb'
import PaperTitle from '@/components/v2/molecules/PaperTitle'
import AifpPaper from '@/components/v2/organisms/AifpPaper'
import { fetchAccount, fetchAccountShowStepStatusV2 } from '@/containers/accountsSlice'
import { setPageMeta } from '@/containers/pageMetasSlice'
import {
  createDetailUnsavedFutureBase,
  destroyFutureBase,
  fetchFutureBasesV2,
  fetchNewAdminDetailFutureBase,
  fetchNewDetailFutureBase,
  updateMainPlan,
} from '@/containers/futures/futureBasesSlice'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import { AccountContext, isAccountDefault } from '@/models/accountsModelFunc'
import { RootState } from '@/store'
import { getStorageHasOpenedDashboard } from '@/utils/localStorage/hasOpenedDashboardStorage'
import { HANDLER_TYPE } from '@/constants/formItem/futures/futureBases'
import { MARRIAGE_TYPE } from '@/models/futures/futureMarriagesModelFunc'
import SimulationListItem from '@/components/v2/organisms/SimulationListItem'

const SimulationIndex: FC = () => {
  const dispatch = useDispatch()
  const { handleMovePage } = useCustomHistory()
  const { isAdmin } = useContext(AccountContext)
  const { showFlashMsg } = useFlashAlert()

  const { futureBasesV2 } = useSelector((state: RootState) => state.futureBases)
  const { account, stepStatus } = useSelector((state: RootState) => state.accounts)

  // Loading Flag
  const isLoadingAccounts = useSelector((state: RootState) => state.accounts.isLoading)
  const { isLoading: isFutureBaseLoading, isGetDetailFutureBaseLoading } = useSelector(
    (state: RootState) => state.futureBases,
  )

  const hasOpenedDashBoard = getStorageHasOpenedDashboard()

  const [deleteModal, setDeleteModal] = useState<{
    isOpen: boolean
    canDelete?: boolean
    futureBaseId?: number
    isFpCreated?: boolean
  }>({ isOpen: false })

  const [mainPlanModal, setMainPlanModal] = useState<{
    isOpen: boolean
    futureBaseId?: number
  }>({ isOpen: false })

  const handleUpdateMainPlan = () => {
    if (mainPlanModal.futureBaseId == null) return
    dispatch(
      updateMainPlan(mainPlanModal.futureBaseId, () => {
        dispatch(fetchFutureBasesV2())
        showFlashMsg('メインプランを更新しました')
        setMainPlanModal({ isOpen: false })
      }),
    )
  }

  const handleDeletePlan = () => {
    if (deleteModal.futureBaseId == null) return
    dispatch(
      destroyFutureBase(deleteModal.futureBaseId, () => {
        dispatch(fetchFutureBasesV2())
        showFlashMsg('プランを削除しました')
        setDeleteModal({ isOpen: false })
      }),
    )
  }

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'シミュレーション詳細',
        description: 'シミュレーション詳細',
        keyword: 'シミュレーション詳細',
      }),
    )
    dispatch(fetchFutureBasesV2())
    dispatch(fetchAccountShowStepStatusV2())
    !isAdmin && (account?.id ?? 0) < 1 && dispatch(fetchAccount())
  }, [])

  return (
    <>
      <MarginContainer className="px-[8px] md:px-[32px] lg:px-0 py-[8px] md:py-[40px]">
        <Breadcrumb
          className="pb-[10px] md:pb-[32px]"
          data={[{ name: 'シミュレーション一覧' }]}
        />

        <PaperTitle
          className="mb-[40px]"
          title="シミュレーション一覧"
          description="様々なシミュレーションをして将来のプランを立てていきましょう"
          icon={<RankingIcon />}
          smallIcon={<RankingIcon className="h-[82px] w-[130px]" viewBox="0 0 212 185" />}
        />

        {account &&
          account['is_done_with_required_input?'] &&
          !account['is_done_with_steps?'] && (
            <AifpPaper
              className="mb-[16px] md:mb-[40px]"
              text="設定したマイデータをもとにグラフがより詳細になりました。\n老後までお金の余裕を持てていますか？無理のない支出になっているでしょうか？\n[space]次に、未来のお金の動きを想定したシミュレーションプランを作成し、\n現状と比較できるようにしていきましょう！"
              buttonLabel="シミュレーションを作成する"
              onClick={() => handleMovePage('/v2/simulations/new')}
            />
          )}
        {!hasOpenedDashBoard && account['is_done_with_steps?'] && (
          <AifpPaper
            className="mb-[16px] md:mb-[40px]"
            text="これでお金の管理に必要設定が完了しました。\nシミュレーション通りに進んでいるか日々チェックしていきましょう！"
            buttonLabel="ダッシュボードに戻る"
            onClick={() => handleMovePage('/v2/dashboards')}
          />
        )}

        <Typography
          className="mt-[24px] mb-[16px] text-[12px] leading-normal text-black-900"
          isBold
        >
          シミュレーションプランは、新規作成時点もしくは編集時点のマイデータ設定をベースに、設定されたシミュレーション条件を上書きした結果が反映されています。
        </Typography>

        <Paper className="bg-white p-[14px] md:p-[20px]">
          <div className="flex flex-col md:flex-row md:items-baseline md:justify-between pb-[10px] border-b-2 border-black-300 m-[10px]">
            <Typography
              className="pb-[10px] text-[19px] md:text-[28px] text-black-800"
              isBold
            >
              シミュレーション一覧
            </Typography>

            <Button
              className="self-end md:self-baseline px-16 py-8 rounded-xl-[4px] bg-white border border-secondary-500 hover:bg-secondary-50 rounded"
              isDisabled={!stepStatus?.has_input_simple_data}
              onClick={() => handleMovePage('/v2/simulations/new')}
            >
              <Typography
                className="leading-normal ml-[4px] relative bottom-[1px] text-[12px] md:text-[14px] text-secondary-500 whitespace-nowrap"
                isBold
              >
                新しく作成する
              </Typography>
            </Button>
          </div>

          <div className="p-[10px] pt-[12px] md:p-[20px] md:pt-[24px] relative">
            <LoadingOverlay
              isLoading={
                isLoadingAccounts || isFutureBaseLoading || isGetDetailFutureBaseLoading
              }
            />
            {stepStatus?.has_input_simple_data ? (
              <>
                {futureBasesV2.length > 0 ? (
                  <div className="flex flex-col gap-24">
                    {futureBasesV2.map((fb) => {
                      return (
                        <SimulationListItem key={fb.id} fb={fb} setMainPlanModal={setMainPlanModal} setDeleteModal={setDeleteModal} baseUrl="/v2/simulations" />
                      )
                    })}
                  </div>
                ) : (
                  <Typography
                    className="leading-normal text-[14px] text-black-800"
                    isBold
                  >
                    今後の生活の目標になるプランが登録されていません。
                    <br />
                    「新しく作成する」から将来のシミュレーションを行い、目標プランを作成しましょう。
                  </Typography>
                )}
              </>
            ) : (
              <div>
                <Typography className="pb-[24px] text-[18px] text-center" isBold>
                  シミュレーションは、マイデータを入力後
                  <br />
                  ご使用になれます。
                </Typography>
                <ButtonPrimary
                  className="h-45 mx-auto w-full md:w-[200px]"
                  onClick={() => handleMovePage('/v2/myData/simple')}
                >
                  <Typography
                    className="relative bottom-px flex-1 pl-20 text-14 text-white"
                    isBold
                  >
                    マイデータを入力する
                  </Typography>
                  <ChevronRightIcon className="mr-17" />
                </ButtonPrimary>
              </div>
            )}
          </div>
        </Paper>
      </MarginContainer>

      <Modal
        className="md:w-[620px] w-[95%]"
        isOpen={deleteModal.isOpen}
        onClose={() => {
          setDeleteModal({ isOpen: false })
        }}
      >
        <div className="flex flex-col gap-34 items-center md:m-20">
          {deleteModal.isFpCreated ? (
            <Typography className="leading-normal text-[14px]">
              FPが作成したプランは削除できません。
            </Typography>
          ) : deleteModal.canDelete ? (
            <Typography className="leading-normal text-[14px]">
              シミュレーションを削除すると、データを戻すことができません。
              <br />
              本当に削除してよろしいですか？
            </Typography>
          ) : (
            <Typography className="leading-normal text-[14px]">
              目標に設定されているプランは削除できません。
              <br />
              削除を行いたい場合は、目標プランを変更してください。
            </Typography>
          )}
          <div
            className={
              'flex flex-row gap-40 justify-center' +
              (!deleteModal.canDelete ? ' mx-80' : '')
            }
          >
            <ButtonBlack
              className="h-45 w-full md:w-188 text-black-400"
              onClick={() => {
                setDeleteModal({ isOpen: false })
              }}
            >
              <Typography className="flex-auto pl-30 leading-normal text-14 whitespace-nowrap">
                戻る
              </Typography>
              <i className="ico chevron-right text-24 mr-18" />
            </ButtonBlack>
            {deleteModal.canDelete && !deleteModal.isFpCreated && (
              <Button
                className="h-45 w-full md:w-188 text-white bg-alert-600 rounded-8"
                onClick={handleDeletePlan}
              >
                <Typography className="flex-auto pl-30 leading-normal text-14 whitespace-nowrap">
                  削除する
                </Typography>
                <i className="ico chevron-right text-24 mr-18" />
              </Button>
            )}
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={mainPlanModal.isOpen}
        onClose={() => {
          setMainPlanModal({ isOpen: false })
        }}
        className="md:w-[620px] w-[95%]"
      >
        <div className="flex flex-col gap-34 items-center md:m-20">
          <Typography className="leading-normal text-[14px]">
            目標プランを変更してよろしいですか？
          </Typography>
          <div className="flex flex-row gap-40 justify-center">
            <ButtonBlack
              className="h-45 w-full md:w-188 text-black-400"
              onClick={() => {
                setMainPlanModal({ isOpen: false })
              }}
            >
              <Typography className="flex-auto pl-30 leading-normal text-14 whitespace-nowrap">
                戻る
              </Typography>
              <i className="ico chevron-right text-24 mr-18" />
            </ButtonBlack>
            <ButtonPrimary
              className="h-45 w-full md:w-188 text-white"
              onClick={handleUpdateMainPlan}
            >
              <Typography className="flex-auto pl-30 leading-normal text-14 whitespace-nowrap">
                変更する
              </Typography>
              <i className="ico chevron-right text-24 mr-18" />
            </ButtonPrimary>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default SimulationIndex
