import { createSlice } from '@reduxjs/toolkit'
import { isUndefined as _isUndefined } from 'lodash'
import {
  getMtCnnectingStatus,
  postRegistrationAccessToken,
  postMtAccessTokenDisconnect,
} from '@/api/mtAccessTokens'
import { unauthorizedCheck } from '@/containers/accountsSlice'
import { MtAccessTokenCreateValue, MtConnectingStatus } from '@/models/mtAccessTokenModel'
import { AppThunk } from '@/store'

interface MtAccessTokenState {
  isLoading: boolean
  isLogin: boolean
}

const initialState: MtAccessTokenState = {
  isLoading: false,
  isLogin: false,
}

const mtAccessTokensSlice = createSlice({
  name: 'mtAccessTokens',
  initialState,
  reducers: {
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
    postRegistrationAccessTokenSuccess: (state) => {
      state.isLogin = true
      return state
    },
  },
})

export const {
  requestStart,
  requestSuccess,
  requestFailure,
  postRegistrationAccessTokenSuccess,
} = mtAccessTokensSlice.actions
export const mtAccessTokensReducer = mtAccessTokensSlice.reducer

export const fetchMtCnnectingStatus = (
  callback: (response: MtConnectingStatus) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  getMtCnnectingStatus()
    .then((response: MtConnectingStatus) => {
      dispatch(requestSuccess())
      callback(response)
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const createMtAccessToken = (
  values: MtAccessTokenCreateValue,
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postRegistrationAccessToken(values)
    .then(() => {
      dispatch(requestSuccess())
      dispatch(postRegistrationAccessTokenSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
    })
}

export const createMtAccessTokenDisconnect = (callback: () => void): AppThunk => async (
  dispatch,
) => {
  dispatch(requestStart())
  postMtAccessTokenDisconnect()
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}
