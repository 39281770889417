import ApiClient from '@/api/apiClient'
import {
  InputYearCost,
  InputYearCostCreateValues,
  InputYearCostUpdateValues,
  InputYearCostDestroyValues,
} from '@/models/inputs/inputYearCostsModel'

export const getInputYearCost = async () => {
  return await ApiClient.get(
    '/input_year_costs',
    (response: InputYearCost) => response,
    undefined,
    false,
    'v2',
  )
}

export const postInputYearCost = async (values: InputYearCostCreateValues) => {
  return await ApiClient.post('/input_year_costs', () => {}, values)
}

export const patchInputYearCost = async (values: InputYearCostUpdateValues) => {
  return await ApiClient.patch('/input_year_costs', () => {}, values)
}

export const deleteInputYearCost = async (values: {
  params: InputYearCostDestroyValues
}) => {
  return await ApiClient.delete('/input_year_costs', () => {}, values)
}
