import React, { FC } from 'react'
import CircularProgress from '@/components/v1/atoms/CircularProgress'

interface LoadingOverlayProps {
  /** true: ローディング有り, false: ローディング無し */
  isLoading: boolean
}

const LoadingOverlayModal: FC<LoadingOverlayProps> = (props) => {
  const { isLoading } = props

  return (
    <>
      {isLoading && (
        <div className="absolute top-0 bottom-0 left-0 right-0 w-full h-full z-[3000]">
          <div className="w-full bg-[#000] opacity-[0.5] h-[calc(100%-48px)] mt-[48px] rounded-[16px]" />
          <div className="absolute top-0 bottom-0 left-0 right-0 m-auto w-[100px] h-[100px] bg-white rounded-[6px]" />
          <CircularProgress
            className="absolute top-0 bottom-0 left-0 right-0 m-auto"
            size={50}
          />
        </div>
      )}
    </>
  )
}

export default LoadingOverlayModal
