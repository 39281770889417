import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Form } from 'react-final-form'
import { isUndefined as _isUndefined } from 'lodash'
import Heading from '@/components/v2/atoms/Heading'
import Typography from '@/components/v2/atoms/Typography'
import TextField, { SHORT_LENGTH } from '@/components/v2/atoms/TextField'
import WholeSubmitErrorMessage from '@/components/v2/molecules/WholeSubmitErrorMessage'
import { RootState } from '@/store'
import { composeValidators, required, password, passwordMatch } from '@/utils/validate'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'

interface AccountPasswordFormProps {
  /** タイトル */
  title: string
  /** true: 編集, false: 登録 */
  isEdit?: boolean
  /** 説明文 */
  description?: JSX.Element
  /** onSubmitイベントメソッド */
  handleSubmit: (values: any) => void
}

const AccountPasswordForm: FC<AccountPasswordFormProps> = (props) => {
  const { title, isEdit, description, handleSubmit } = props
  const { isLoading } = useSelector((state: RootState) => state.accounts)

  return (
    <div className="space-y-24">
      <Heading className="text-center">{title}</Heading>
      {!_isUndefined(description) && (
        <Typography className="text-14 text-black-700 text-center">
          {description}
        </Typography>
      )}
      <Typography className="text-14 text-black-700 text-center">
        英文字、数字を組み合わせた8文字以上72文字以内でご入力ください
      </Typography>
      <Form
        onSubmit={handleSubmit}
        validate={(values) => {
          return {
            password_confirmation: passwordMatch(
              values.password,
              values.password_confirmation,
            ),
          }
        }}
        render={({ handleSubmit, values, errors, submitErrors }) => (
          <form className="space-y-24" onSubmit={handleSubmit}>
            <TextField
              type="password"
              name="password"
              placeholder="パスワード"
              isFullWidth
              maxLength={SHORT_LENGTH}
              validate={composeValidators(required, password)}
            />
            <TextField
              type="password"
              name="password_confirmation"
              placeholder="パスワード確認"
              isFullWidth
              maxLength={SHORT_LENGTH}
              validate={composeValidators(required, password)}
            />
            <WholeSubmitErrorMessage submitErrors={submitErrors} />
            <div className="flex justify-center">
              <ButtonPrimary
                className="h-45 w-full md:w-188 text-white"
                isDisabled={Object.keys(errors).length > 0 || isLoading}
                onClick={() => handleSubmit(values)}
              >
                <Typography className="bottom-2 flex-auto pl-30 relative text-16" isBold>
                  {isEdit ? '更新する' : '登録する'}
                </Typography>
                <i className="ico chevron-right mr-17 text-24" />
              </ButtonPrimary>
            </div>
          </form>
        )}
      />
    </div>
  )
}

export default AccountPasswordForm
