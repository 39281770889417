import { CHILDCARE_LEAVE_MONTHS } from '../../../models/futures/futureFamilyChildbirthsModelFunc'

// 育休
export const CHILDCARE_LEAVE_MONTHS_SELECT_ITEMS = [
  { value: CHILDCARE_LEAVE_MONTHS.no, label: '育休を取得しない' },
  { value: CHILDCARE_LEAVE_MONTHS.month, label: '1ヶ月間取得予定' },
  { value: CHILDCARE_LEAVE_MONTHS.half, label: '半年間取得予定' },
  { value: CHILDCARE_LEAVE_MONTHS.one, label: '1年間取得予定' },
  { value: CHILDCARE_LEAVE_MONTHS.oneHalf, label: '1年半取得予定' },
]
