import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Form } from 'react-final-form'
import Paper from '@/components/v2/atoms/Paper'
import Heading from '@/components/v2/atoms/Heading'
import Typography from '@/components/v2/atoms/Typography'
import TextArea from '@/components/v2/atoms/TextArea'
import Divider from '@/components/v2/atoms/Divider'
import Link from '@/components/v2/atoms/Link'
import Button from '@/components/v2/atoms/Button'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import CheckboxGroup from '@/components/v2/molecules/CheckboxGroup'
import WholeSubmitErrorMessage from '@/components/v1/molecules/WholeSubmitErrorMessage'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import { RootState } from '@/store'
import { required, requiredForArrayAllChecked } from '@/utils/validate'

interface AccountDeactivateFormProps {
  /** タイトル */
  title: string
  /** ボタンラベル */
  buttonLabel: string
  /** 画面タイプ */
  type: 'deactivate' | 'confirm'
  /** onSubmitイベントメソッド */
  handleSubmit: (values: any) => void
  /** 修正ボタンメソッド */
  handleFix?: () => void
}

const AGREEMENT_CONDITIONS = [
  {
    label: '解約をした場合、今日付で利用ができなくなります。よろしいですか？',
    value: 'immediate_deactivate_agreement',
  },
  {
    label:
      '有料会員様の場合は、支払い済みのご利用料金が無駄になってしまいますが、今解約してよろしいですか？',
    value: 'paid_charges_agreement',
  },
  {
    label: '解約をするとこれまで見れていたデータが一切見れなくなりますがよろしいですか？',
    value: 'unreachable_data_agreement',
  },
  {
    label: '解約をした日からデータ蓄積されなくなりますが、よろしいですか？',
    value: 'nostack_data_agreement',
  },
  {
    label: (
      <Typography>
        解約後の復活も可能です。解約日までに蓄積されたデータは保存され、復活時にご利用いただくことは可能ですが、
        <span className="md:inline-block">
          解約日から復活日のデータは蓄積されませんがよろしいですか？
        </span>
      </Typography>
    ),
    value: 'restore_data_agreement',
  },
]

const AccountDeactivateForm: FC<AccountDeactivateFormProps> = (props) => {
  const { handleMovePage } = useCustomHistory()

  const { title, buttonLabel, type, handleSubmit, handleFix } = props

  const { deactivationFormResponse, account } = useSelector(
    (state: RootState) => state.accounts,
  )
  const isConfirm = type === 'confirm'
  const hasConnectedWithMoneytree = account?.['has_connected_with_moneytree?']

  return (
    <Paper className="px-16 py-24 sm:p-40 text-black-800 bg-white">
      {hasConnectedWithMoneytree ? (
        <>
          <Heading as="h2" size={2}>
            マネソルを退会する
          </Heading>
          <Divider className="border-black-300 border-t-2 mt-10 pb-20 md:pb-24" />
          <div className="space-y-24">
            <div className="p-20 bg-alert-400">
              <Typography className="text-14 text-alert-600">
                大変申し訳ございません。
                <br />
                お客様のアカウントは、Moneytreeとの連携が解除されていないため、退会手続きを進めることができません。
                <br />
                個人設定ページからMoneytreeとの連携を解除したのちに、再度お手続きをお願いします。
              </Typography>
            </div>
            <div className="flex justify-center">
              <Button
                className="h-45 w-full md:w-265 px-17 text-primary-500 border border-primary-500 rounded-8"
                onClick={() => handleMovePage('/v2/settings/account')}
              >
                <Typography className="bottom-2 flex-auto relative text-14" isBold>
                  個人設定ページに戻る
                </Typography>
                <i className="ico chevron-right text-24" />
              </Button>
            </div>
          </div>
        </>
      ) : (
        <>
          <Heading as="h2" size={2}>
            {title}
          </Heading>
          <Divider className="border-black-300 border-t-2 mt-10 pb-20 md:pb-24" />
          <Typography className="mb-40 text-14">
            マネソルをご利用いただきありがとうございました。
            <br />
            退会のお手続きを進めていただきますので下記のフォームに入力をお願いします。
          </Typography>

          <Form
            onSubmit={handleSubmit}
            initialValues={{
              deactivation_form_response: { ...deactivationFormResponse },
            }}
            render={({ handleSubmit, submitErrors, errors, values }) => (
              <form className="space-y-30" onSubmit={handleSubmit}>
                <section>
                  <Typography className="mb-16 text-16 text-black" isBold>
                    以下の質問に同意していただき、チェックを入れてください。
                  </Typography>
                  <CheckboxGroup
                    name="deactivation_form_response.agreement_conditions_arr"
                    disabled={isConfirm}
                    datas={AGREEMENT_CONDITIONS}
                    validate={(value) => requiredForArrayAllChecked(value, 5)}
                  />
                </section>
                <section>
                  <Typography className="mb-16 text-16 text-black" isBold>
                    ■ 解約理由を教えてください
                  </Typography>
                  <TextArea
                    name="deactivation_form_response.reason"
                    rows={6}
                    placeholder="解約理由"
                    disabled={isConfirm}
                    validate={required}
                    isFullWidth
                  />
                </section>
                <section>
                  <Typography className="mb-16 text-16 text-black" isBold>
                    ■ 良かったところを教えてください
                  </Typography>
                  <TextArea
                    name="deactivation_form_response.good_point"
                    rows={6}
                    placeholder="マネソルの良かったところ"
                    disabled={isConfirm}
                    validate={required}
                    isFullWidth
                  />
                </section>
                <section>
                  <Typography className="mb-16 text-16 text-black" isBold>
                    ■ 悪かったところを教えてください
                  </Typography>
                  <TextArea
                    name="deactivation_form_response.bad_point"
                    rows={6}
                    placeholder="マネソルの悪かったところ"
                    disabled={isConfirm}
                    validate={required}
                    isFullWidth
                  />
                </section>

                <WholeSubmitErrorMessage submitErrors={submitErrors} />

                <div className="flex flex-col space-y-24">
                  <div className="flex justify-center">
                    <ButtonPrimary
                      className="h-45 w-full md:w-215 text-white"
                      isDisabled={Object.keys(errors).length > 0}
                      onClick={() => handleSubmit(values)}
                    >
                      <Typography
                        className="bottom-2 flex-auto pl-30 relative text-16"
                        isBold
                      >
                        {buttonLabel}
                      </Typography>
                      <i className="ico chevron-right mr-17 text-24" />
                    </ButtonPrimary>
                  </div>
                  {isConfirm && (
                    <div className="text-center">
                      <Link
                        className="text-14 text-primary-900"
                        children="修正する"
                        onClick={handleFix}
                      />
                    </div>
                  )}
                </div>
              </form>
            )}
          />
        </>
      )}
    </Paper>
  )
}

export default AccountDeactivateForm
