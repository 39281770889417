import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { unauthorizedCheck } from '../accountsSlice'
import {
  getInputCars,
  postInputCar,
  patchInputCar,
  deleteInputCar,
} from '@/api/inputs/inputCars'
import { ApiError } from '@/models/commonsModel'
import {
  InputCar,
  InputCarCreateValues,
  InputCarUpdateValues,
  InputCarDestroyValues,
} from '@/models/inputs/inputCarsModel'
import { AppThunk } from '@/store'
import { sortBy as _sortBy } from 'lodash'

interface InputCarState {
  inputCars: InputCar[]
  isLoading: boolean
}

const initialState: InputCarState = {
  inputCars: [],
  isLoading: false,
}

const inputCarsSlice = createSlice({
  name: 'inputCars',
  initialState,
  reducers: {
    setInputCars: (state, action: PayloadAction<InputCar[]>) => {
      state.inputCars = _sortBy(action.payload, 'id')
      return state
    },
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    resetInputCarsStore: (state) => {
      state = initialState
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    getInputCarsSuccess: (state, action: PayloadAction<InputCar[]>) => {
      state.inputCars = action.payload
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
  },
})

export const {
  setInputCars,
  requestStart,
  resetInputCarsStore,
  requestSuccess,
  getInputCarsSuccess,
  requestFailure,
} = inputCarsSlice.actions
export const inputCarsReducer = inputCarsSlice.reducer

export const fetchInputCars = (): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  getInputCars()
    .then((response) => dispatch(getInputCarsSuccess(response)))
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const createInputCar = (
  values: InputCarCreateValues,
  callback: () => void,
  resolve?: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postInputCar(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error, resolve))
    })
}

export const updateInputCar = (
  values: InputCarUpdateValues,
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchInputCar(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const destroyInputCar = (
  values: { params: InputCarDestroyValues },
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  deleteInputCar(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}
