import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setPageMeta } from '@/containers/pageMetasSlice'
import MarginContainer from '@/components/v2/atoms/MarginContainer'
import MailSendCompleted from '@/templates/v2/accounts/commons/MailSendCompleted'

const AccountActivateMailSendCompleted: FC = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'アカウント復元メール送信完了',
        description: 'アカウント復元メール送信完了',
        keyword: 'アカウント復元メール送信完了',
      }),
    )
  }, [])

  return (
    <MarginContainer className="sm:w-524 mx-auto pt-40 sm:pt-73">
      <MailSendCompleted title="アカウント復元メール送信完了" space={36}>
        ご入力していただいたメールアドレスにメールを送信しました。
        <br />
        メールをご確認いただきパスワードを再設定してください。
      </MailSendCompleted>
    </MarginContainer>
  )
}

export default AccountActivateMailSendCompleted
