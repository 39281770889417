import LocalStorage from '@/utils/localStorage/index'

const KEY = 'fpRegistrationCode'

export const getStorageFpRegistrationCode = () => {
  return LocalStorage.getItem(KEY)
}

export const setStorageFpRegistrationCode = (value: string) => {
  return LocalStorage.setItem(KEY, value)
}

export const removeStorageFpRegistrationCode = () => {
  LocalStorage.removeItem(KEY)
}
