import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { sortBy as _sortBy } from 'lodash'
import { unauthorizedCheck } from '../accountsSlice'
import {
  postFutureInsuranceV2,
  patchFutureInsuranceV2,
  deleteFutureInsuranceV2,
} from '../../api/futures/futureInsurances'
import { ApiError } from '../../models/commonsModel'
import { convertMomentDate } from '../../models/commonsModelFunc'
import {
  FutureInsurance,
  FutureInsuranceCreateValues,
  FutureInsuranceUpdateValues,
} from '../../models/futures/futureInsurancesModel'
import { AppThunk } from '../../store'
import { convertRadioGroupValue } from '../../utils/formItem'

interface FutureInsuranceState {
  futureInsurances: FutureInsurance[]
  isLoading: boolean
}

const initialState: FutureInsuranceState = {
  futureInsurances: [],
  isLoading: false,
}

const futureInsurancesSlice = createSlice({
  name: 'futureInsurances',
  initialState,
  reducers: {
    setFutureInsurances: (state, action: PayloadAction<FutureInsurance[]>) => {
      const { payload } = action
      const sortedFutureInsurances = _sortBy(payload, 'id')

      state.futureInsurances = sortedFutureInsurances.map((item) => ({
        ...item,
        contracted_year: convertMomentDate(item.contracted_at, 'YYYY'),
        contracted_month: convertMomentDate(item.contracted_at, 'MM'),
        insurance_fee_payment_occurrence_in_months: convertRadioGroupValue(
          item.insurance_fee_payment_occurrence_in_months,
        ),
        insurance_period_finish_year: convertMomentDate(
          item.insurance_period_finish_at,
          'YYYY',
        ),
        insurance_period_finish_month: convertMomentDate(
          item.insurance_period_finish_at,
          'MM',
        ),
        insurance_fee_payment_period_finish_year: convertMomentDate(
          item.insurance_fee_payment_period_finish_at,
          'YYYY',
        ),
        insurance_fee_payment_period_finish_month: convertMomentDate(
          item.insurance_fee_payment_period_finish_at,
          'MM',
        ),
        cancellation_refund_at_year: convertMomentDate(
          item.cancellation_refund_at,
          'YYYY',
        ),
        cancellation_refund_at_month: convertMomentDate(
          item.cancellation_refund_at,
          'MM',
        ),
      }))

      return state
    },
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
  },
})

export const {
  setFutureInsurances,
  requestStart,
  requestSuccess,
  requestFailure,
} = futureInsurancesSlice.actions
export const futureInsurancesReducer = futureInsurancesSlice.reducer

export const createFutureInsuranceV2 = (
  values: FutureInsuranceCreateValues,
  callback: () => void,
  resolve?: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postFutureInsuranceV2(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error, resolve))
    })
}

export const updateFutureInsuranceV2 = (
  values: FutureInsuranceUpdateValues,
  id: number,
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchFutureInsuranceV2(values, id)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const destroyFutureInsuranceV2 = (
  values: any,
  id: number,
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  deleteFutureInsuranceV2(values, id)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}
