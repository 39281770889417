import React, { FC } from 'react'
import { Form } from 'react-final-form'
import Heading from '@/components/v2/atoms/Heading'
import Typography from '@/components/v2/atoms/Typography'
import RadioGroup from '@/components/v2/molecules/RadioGroup'
import WholeSubmitErrorMessage from '@/components/v2/molecules/WholeSubmitErrorMessage'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import { convertDateToJapaneseFormat } from '@/models/commonsModelFunc'
import { required } from '@/utils/validate'

interface FpShareFormProps {
  title: string
  /** 3ヶ月後の日付テキスト(YYYY/MM/DD) */
  dateText: string
  /** 初期値 */
  initialValues: {
    disclosed_stop_at: string | null
    disclosed_type: string | null
  }
  /** onSubmitイベントメソッド */
  handleSubmit: (values: any) => void
}

const FpShareForm: FC<FpShareFormProps> = (props) => {
  const { title, dateText, initialValues, handleSubmit } = props

  return (
    <div className="space-y-24">
      <Heading className="text-center">{title}</Heading>
      <Typography className="text-14 text-black-700">
        FPとの共有を許可するかを設定できます。
        <br />
        共有するとFP側はあなたの情報を閲覧し、分析結果のアドバイスを受けることができるようになります。
        <br />
        ※FPは、アルファ・ファイナンシャルプランナーズの教育と試験を合格した者のみ対応させていただきます。
        <br />
        ※対応件数超過等により、アドバイスが無い場合もあります。
      </Typography>

      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, values, submitErrors, errors }) => {
          return (
            <form className="space-y-24" onSubmit={handleSubmit}>
              <RadioGroup
                name="disclosed_type"
                datas={[
                  {
                    label: '共有しない',
                    value: 'not_disclosed',
                  },
                  {
                    label: `今日から3ヶ月後の${convertDateToJapaneseFormat(
                      dateText,
                      'fullDate',
                    )}まで共有する`,
                    value: 'limited',
                  },
                  {
                    label: '期間の制限なしで共有する',
                    value: 'forever',
                  },
                ]}
                validate={required}
              />

              <WholeSubmitErrorMessage className="u-pb10" submitErrors={submitErrors} />

              <div className="flex flex-col items-center space-y-24">
                <ButtonPrimary
                  className="h-45 w-full md:w-215 text-white"
                  isDisabled={Object.keys(errors).length > 0}
                  onClick={() => handleSubmit(values)}
                >
                  <Typography
                    className="bottom-2 flex-auto pl-30 relative text-16"
                    isBold
                  >
                    更新する
                  </Typography>
                  <i className="ico chevron-right mr-17 text-24" />
                </ButtonPrimary>
              </div>
            </form>
          )
        }}
      />
    </div>
  )
}

export default FpShareForm
