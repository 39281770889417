import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import LogindRedirect from '@/layouts/v2/LogindRedirect'
import MarginContainer from '@/components/v2/atoms/MarginContainer'
import Paper from '@/components/v2/atoms/Paper'
import Heading from '@/components/v2/atoms/Heading'
import Typography from '@/components/v2/atoms/Typography'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import { setPageMeta } from '@/containers/pageMetasSlice'

const AccountPasswordCompleted: FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: '会員パスワード登録完了',
        description: '会員パスワード登録完了',
        keyword: '会員パスワード登録完了',
      }),
    )
  }, [])

  const handleMovePage = () => {
    history.push('/v2/')
  }

  return (
    <LogindRedirect>
      <MarginContainer className="w-full sm:w-524 py-40">
        <Paper className="mx-8 sm:mx-0 px-16 py-24 sm:p-40 space-y-24 bg-white">
          <Heading className="text-center">
            会員パスワード<span className="inline-block">登録完了</span>
          </Heading>
          <Typography className="mt-24 sm:text-center text-14 text-black-700">
            会員パスワードの登録が完了しました。
            <br />
            ログイン画面に遷移し、ログインを実行してください。
          </Typography>
          <div className="flex justify-center">
            <ButtonPrimary
              className="h-45 w-full md:w-188 text-white"
              onClick={handleMovePage}
            >
              <Typography className="bottom-2 flex-auto pl-30 relative text-16" isBold>
                ログイン画面へ
              </Typography>
              <i className="ico chevron-right mr-17 text-24" />
            </ButtonPrimary>
          </div>
        </Paper>
      </MarginContainer>
    </LogindRedirect>
  )
}

export default AccountPasswordCompleted
