import React, { FC } from 'react'
import {
  IconButton as MuiIconButton,
  IconButtonProps as MuiIconButtonProps,
} from '@material-ui/core'

interface IconButtonProps extends MuiIconButtonProps {}

const IconButton: FC<IconButtonProps> = (props) => {
  return <MuiIconButton {...props} />
}

export default IconButton
