import React, { FC } from 'react'
import { useField, useForm } from 'react-final-form'
import { selectLoanObjectNumber } from '@/models/commonHousingModelFunc'
import { selectFutureV2LoanObjectName } from '@/models/futures/futureV2HousingLoansModelFunc'
import {
  selectLoanObjectName,
  INPUT_LOAN_LOAN_LENDER,
  isPairLoan,
  isFlatLoan,
  isWithChartLoan,
} from '@/models/inputs/inputLoansModelFunc'
import { required } from '@/utils/validate'

import Typography from '@/components/v2/atoms/Typography'
import SelectBox from '@/components/v2/molecules/SelectBox'

interface LoanLenderProps {
  /** true: シミュレーション, false: 現状 */
  isFuture?: boolean
  /** true: 建物用ローン, false: 土地用ローン・分割なし */
  isBuilding?: boolean
  /** true: 建物用ローン, false: 土地用ローン・分割なし */
  isNotSplit?: boolean
}

const LoanLender: FC<LoanLenderProps> = (props) => {
  const { isFuture, isBuilding } = props
  const { change } = useForm()
  const loanObjectNum = selectLoanObjectNumber(isBuilding)
  const targetPairObjectNum = isBuilding ? 3 : 1
  const selectTargetLoanObjectName = isFuture
    ? selectFutureV2LoanObjectName
    : selectLoanObjectName

  const {
    input: { value: loanType },
  } = useField(selectTargetLoanObjectName(loanObjectNum, 'loan_type'))
  const {
    input: { value: repaymentChart },
  } = useField(selectTargetLoanObjectName(loanObjectNum, 'repayment_chart'))

  const handleChangejLoanLender = (value: string) => {
    change(selectTargetLoanObjectName(loanObjectNum, 'loan_lender'), value)

    if (isPairLoan(loanType)) {
      change(selectTargetLoanObjectName(targetPairObjectNum, 'loan_lender'), value)
    }
    if (isFlatLoan(value) && !isWithChartLoan(repaymentChart)) {
      change(selectTargetLoanObjectName(targetPairObjectNum, 'loan_lender'), value)
      change(selectTargetLoanObjectName(targetPairObjectNum, 'loan_type'), loanType)
      change(
        selectTargetLoanObjectName(targetPairObjectNum, 'repayment_chart'),
        repaymentChart,
      )
    }
  }

  return (
    <>
      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
        <Typography
          className="min-w-[140px] lg:min-w-[210px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
          isBold
        >
          ローンの借り入れ先
        </Typography>
        <SelectBox
          name={selectTargetLoanObjectName(loanObjectNum, 'loan_lender')}
          placeholder="選択する"
          isFullWidth
          options={[
            {
              value: String(INPUT_LOAN_LOAN_LENDER.flat),
              label: 'フラット２０／フラット３５',
              isDisabled: isPairLoan(loanType),
            },
            {
              value: String(INPUT_LOAN_LOAN_LENDER.private),
              label: '民間住宅ローン',
            },
          ]}
          validate={required}
          onChange={(event: any) => handleChangejLoanLender(event.target.value)}
        />
      </div>
    </>
  )
}

export default LoanLender
