import React, { FC } from 'react'
import { isUndefined as _isUndefined } from 'lodash'
import HelpCircleIcon from '@/assets/images/v2/help-circle.svg'
import Button from '@/components/v2/atoms/Button'
import Paper from '@/components/v2/atoms/Paper'
import Tooltip from '@/components/v2/atoms/Tooltip'
import Typography from '@/components/v2/atoms/Typography'

interface MyDataListMainWrapperProps {
  /** タイトル */
  title: string
  /** 説明 */
  description?: string | JSX.Element
  /** 追加要素としてのボタン */
  subButton?: string | JSX.Element
  buttonTitle?: string
  /** true: 操作許可有り, false: 操作許可無し */
  isManipulatedApproval: boolean
  /** onClickメソッド */
  onClick?: () => void
  updatedAt?: string
}

const MyDataListMainWrapper: FC<MyDataListMainWrapperProps> = (props) => {
  const {
    title,
    description,
    buttonTitle,
    isManipulatedApproval,
    onClick,
    children,
    subButton,
  } = props

  return (
    <Paper className="bg-white p-[16px] md:p-[40px]">
      <div className="flex items-center justify-between pb-[8px] border-b-[2px] border-black-300">
        <div className="flex flex-col md:flex-row gap-x-[8px] items-baseline">
          <Typography className="text-[22px] md:text-[28px] text-black-800" isBold>
            {title}
          </Typography>
          <Typography className="text-[13px] md:text-[16px] text-[#9E9E9E]">
            {props.updatedAt}
          </Typography>
        </div>
        <div className="md:flex items-center flex-row-reverse">
          <div className="flex items-center justify-end">
            {!_isUndefined(onClick) && (
              <Button
                className="w-[104px] md:w-[112px] h-[26px] md:h-[37px] mr-[0] md:mr-[9px] rounded-[4px] bg-white border border-secondary-500 hover:bg-secondary-50"
                isDisabled={!isManipulatedApproval}
                onClick={onClick}
              >
                <Typography
                  className="relative bottom-[1px] text-[12px] md:text-[14px] text-secondary-500"
                  isBold
                >
                  {buttonTitle || '編集する'}
                </Typography>
              </Button>
            )}
            {description && (
              <Tooltip
                item={description}
                itemClassName="w-[220px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
              >
                <HelpCircleIcon className="hidden md:block" />
              </Tooltip>
            )}
          </div>
          {subButton}
        </div>
      </div>
      <div className="lg:px-[80px] pt-[10px] md:pt-[32px] pb-[0px]">
        <Typography className="md:hidden pb-[10px] text-[12px] text-black-700">
          {description}
        </Typography>
        {children}
      </div>
    </Paper>
  )
}

export default MyDataListMainWrapper
