import React, { FC, useContext, useMemo, useState } from 'react'
import { last as _last } from 'lodash'
import Link from '@/components/v1/atoms/Link'
import FamilyAgeModal from '@/templates/v2/simulations/FamilyAgeModal'
import {
  CURRENT_OWN_HOUSE_HANDLING_RADIO_ITEMS,
  NEXT_HOUSE_TYPE_RADIO_ITEMS,
} from '@/constants/formItem/futures/futureV2Housings'
import { DescriptionContext } from '@/hooks/useDescriptionTemplates'
import {
  castNumberWithoutOperator,
  convertDateToJapaneseFormat,
  convertLocaleString,
} from '@/models/commonsModelFunc'
import { PREV_HOUSING_TYPE } from '@/models/futures/futureV2HousingsModelFunc'
import { FutureV2Housing } from '@/models/futures/futureV2HousingsModel'
import { InputHousing } from '@/models/inputs/inputHousingsModel'
import { buildRangeAgeSelectItems } from '@/utils/formItem'
import { required } from '@/utils/validate'

import Typography from '@/components/v2/atoms/Typography'
import SelectBox from '@/components/v2/molecules/SelectBox'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import Tooltip from '@/components/v2/atoms/Tooltip'
import HelpCircleIcon from '@/assets/images/v2/help-circle.svg'

const ScheduleBasicInfo: FC = () => {
  const { isAnotherModal, age, prevHousingType, prevHousing } = useContext(
    DescriptionContext,
  )

  const [isFamilyAgeModalOpen, setIsFamilyAgeModalOpen] = useState<boolean>(false)

  const isPrevHousingInput = useMemo(() => {
    return (
      !!prevHousing &&
      (prevHousing?.model_type === 'InputHousing' ||
        Object.keys(prevHousing).indexOf('input_housing_self_owned') >= 0)
    )
  }, [prevHousing])

  const lastRent = useMemo(
    () =>
      isPrevHousingInput
        ? _last((prevHousing as InputHousing)?.input_housing_rents)
        : _last((prevHousing as FutureV2Housing)?.future_v2_housing_rents),
    [isPrevHousingInput, prevHousing, age],
  )
  const lastRentEndAge = useMemo(
    () =>
      isPrevHousingInput
        ? age
        : _last((prevHousing as FutureV2Housing)?.future_v2_housing_rents)
            ?.living_end_at_age,
    [isPrevHousingInput, prevHousing, age],
  )
  const selfOwned = useMemo(
    () =>
      isPrevHousingInput
        ? (prevHousing as InputHousing)?.input_housing_self_owned
        : (prevHousing as FutureV2Housing)?.future_v2_housing_self_owned,
    [isPrevHousingInput, prevHousing, age],
  )

  const targetAge = useMemo(() => {
    if (!!lastRent) {
      return Number(lastRentEndAge)
    } else if (!!selfOwned) {
      return isPrevHousingInput ? age : Number(selfOwned?.purchased_at_age)
    } else if (!!prevHousing) {
      return Number(prevHousing?.scheduled_at_age)
    } else {
      return age
    }
  }, [age, lastRent, selfOwned, prevHousing, lastRentEndAge])

  const ageSelectItems = useMemo(() => buildRangeAgeSelectItems(targetAge + 1, 120), [
    targetAge,
  ])

  const modalTitle = useMemo(
    () => `${!!prevHousing || !isAnotherModal ? '将来の住居' : '別宅'}を想定する`,
    [prevHousing],
  )
  const firstHousingText = useMemo(
    () => (!!prevHousing || !isAnotherModal ? '次の住居' : '別宅'),
    [prevHousing],
  )
  const renderPrevHousingInfoLabel = () => {
    let ageText: string
    let yearText: string
    let costText: string
    if (!!lastRent) {
      ageText = `${lastRent?.scheduled_at_age}歳`

      yearText = `(${convertDateToJapaneseFormat(
        lastRent?.scheduled_at,
        'year',
      )})から居住予定の`

      costText = `家賃${convertLocaleString(lastRent?.rent_monthly)}円の賃貸`
    } else if (!!selfOwned) {
      ageText = `${selfOwned?.purchased_at_age}歳`

      yearText = `(${convertDateToJapaneseFormat(
        selfOwned?.purchased_at,
        'year',
      )})から居住予定の`

      const cost = selfOwned?.property_price_manyen
        ? selfOwned?.property_price_manyen
        : castNumberWithoutOperator(selfOwned?.property_price_land_manyen) +
          castNumberWithoutOperator(selfOwned?.property_price_building_manyen)
      costText = `${convertLocaleString(cost)}万円の住居`
    } else {
      return ''
    }

    return isPrevHousingInput
      ? `現在居住中の${costText}`
      : `${ageText}${yearText}${costText}`
  }

  return (
    <>
      <div className="col-span-1 md:col-span-2">
        <CircleTitle className="pb-[32px]" title={modalTitle} />
        <Typography>
          {/*TODO: No.25 住宅ローン減税の更新: こちらのリンク先が決定したらリンクを設定してコメントイン*/}
          {/*住宅ローン減税と補助金の反映内容、例外の入力方法については<a href="">こちら</a>をご確認ください。
          <br />
          <br />*/}
          <div className="text-[12px]">本シミュレーションに関わる計算は、以下の情報をもとに計算しております（2024年2月1日現在）。</div>
          <div className="text-[12px]">住宅ローン減税：<a className="text-[12px] text-tertiary-500 border-b border-tertiary-500" target="_blank" href="https://www.mlit.go.jp/report/press/content/001714809.pdf">https://www.mlit.go.jp/report/press/content/001714809.pdf</a></div>
          <br />
          <div className="text-[12px]">以下補助金については、計算対象としておりませんので、カスタム設定にて設定をお願いいたします。</div>
          <div className="text-[12px]">補助金（子育てエコホーム支援事業）：<a className="text-[12px] text-tertiary-500 border-b border-tertiary-500" target="_blank" href="https://kosodate-ecohome.mlit.go.jp/">https://kosodate-ecohome.mlit.go.jp/</a></div>
          <div className="text-[12px]">※ 出典元：国土交通省</div>
        </Typography>
      </div>

      {!!prevHousing &&
        (prevHousingType === PREV_HOUSING_TYPE.own ||
          prevHousingType === PREV_HOUSING_TYPE.rent) && (
          <div className="col-span-1 md:col-span-2 md:flex justify-center border-[#C7DB9F]  border-[3px] items-center py-[20px] px-0 md:px-[50px] mb-[40px] md:mx-auto md:max-w-[680px]">
            <Typography
              className="w-[95px] md:min-w-[164px] mx-auto pb-[10px] md:pb-0 text-[14px] text-black-800"
              isBold
            >
              １つ前の住居
            </Typography>
            <Typography className="w-[fit-content] mx-auto lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800">
              {renderPrevHousingInfoLabel()}
            </Typography>
          </div>
        )}

      <div className=" border-black-300 border-dashed border-b-2 items-center pb-[10px]">
        <Typography
          className="min-w-[140px] lg:min-w-[164px] pb-[10px] text-[14px] text-black-800"
          isBold
        >
          {firstHousingText}
        </Typography>
        <SelectBox
          name="next_house_type"
          placeholder="選択する"
          isFullWidth
          options={NEXT_HOUSE_TYPE_RADIO_ITEMS}
          validate={required}
        />
      </div>

      <div className=" border-black-300 border-dashed border-b-2">
        <div>
          <Typography
            className="min-w-[140px] lg:min-w-[164px] pb-[10px] text-[14px] text-black-800"
            isBold
          >
            新居購入予定年齢
          </Typography>
          <div className="flex flex-auto items-baseline">
            <Typography
              className="pr-[8px] text-[14px] text-black-800 min-w-[68px]"
              isBold
            >
              ご本人が
            </Typography>
            <SelectBox
              name="scheduled_at_age"
              placeholder="選択する"
              isFullWidth
              options={ageSelectItems}
              validate={required}
            />
            <Typography
              className="pl-[8px] text-[14px] text-black-800 min-w-[68px] mt-[8px] cursor-pointer"
              isBold
            >
              歳から
            </Typography>
          </div>
        </div>
        <div className="my-[10px] flex justify-end">
          <Link
            children="ご家族の年齢表を確認する"
            onClick={() => setIsFamilyAgeModalOpen(true)}
            className="text-[12px] text-tertiary-500 border-b border-tertiary-500"
          />
        </div>
      </div>

      {!!prevHousing && prevHousingType === PREV_HOUSING_TYPE.own && (
        <>
          <div className="pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
            <div className="flex">
              <Typography className="pb-[10px] text-[14px] text-black-800" isBold>
                現在の住居の扱い
              </Typography>
              <Tooltip
                itemClassName="w-[200px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                item={
                  <Typography>
                    現在の住居からの住み替えシミュレーションを作成している方は、
                    <br />
                    シミュレーションで作成した住居をどうするか選択してください。
                  </Typography>
                }
              >
                <HelpCircleIcon />
              </Tooltip>
            </div>
            <SelectBox
              name="current_house_handling"
              placeholder="選択する"
              isFullWidth
              options={CURRENT_OWN_HOUSE_HANDLING_RADIO_ITEMS}
              validate={required}
            />
          </div>
        </>
      )}

      <FamilyAgeModal
        isOpen={isFamilyAgeModalOpen}
        handleClose={() => setIsFamilyAgeModalOpen(false)}
      />
    </>
  )
}

export default ScheduleBasicInfo
