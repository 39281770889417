import { useState, useRef } from 'react'

export const useRechartsActiveInfo = (): [
  any,
  (item: any, index: number) => void,
  () => void,
] => {
  const [activeInfo, setActiveInfo] = useState<any>({})
  const activeInfoRef: any = useRef(null)
  activeInfoRef.current = activeInfo

  const handleActiveInfo = (item: any, index: number) => {
    if (index === activeInfo.activeIndex) return setActiveInfo({})
    setActiveInfo({ ...item, activeIndex: index })
  }

  const resetActiveInfo = () => {
    Object.keys(activeInfoRef.current).length > 0 && setActiveInfo({})
  }

  return [activeInfo, handleActiveInfo, resetActiveInfo]
}
