import ApiClient from '../apiClient'
import {
  InputPublicPensionCreateValues,
  InputPublicPensionUpdateValues,
  InputPublicPensionDestroyValues,
} from '../../models/inputs/inputPublicPensionsModel'

export const postInputPublicPension = async (values: InputPublicPensionCreateValues) => {
  return await ApiClient.post('/input_public_pensions', () => {}, values)
}

export const patchInputPublicPension = async (values: InputPublicPensionUpdateValues) => {
  return await ApiClient.patch('/input_public_pensions', () => {}, values)
}

export const deleteInputPublicPension = async (values: {
  params: InputPublicPensionDestroyValues
}) => {
  return await ApiClient.delete('/input_public_pensions', () => {}, values)
}

export const getInputPublicPensionV2 = async () => {
  return await ApiClient.get(
    '/input_public_pensions',
    (response: any) => response,
    undefined,
    undefined,
    'v2',
  )
}
