import ApiClient from '../apiClient'

export const postFpAccountInvitation = async (values: any) => {
  return await ApiClient.post(
    `/fp_account_invitations`,
    (response: any[]) => response,
    values,
  )
}

export const postFpAccountInvitationResend = async (id: number) => {
  return await ApiClient.post(
    `/fp_account_invitations/${id}/resend`,
    (response: any[]) => response,
    {},
  )
}
