import ApiClient from '@/api/apiClient'
import { DetailFutureBaseResponse } from '@/models/futures/futureBasesModel'

export const getAdminDetailFutureBase = async (futureBaseId: number) => {
  return await ApiClient.get(
    `/future_bases/${futureBaseId}/show_detail`,
    (response: DetailFutureBaseResponse) => response,
  )
}

export const getAdminDetailFutureBaseV2 = async (futureBaseId: number) => {
  return await ApiClient.get(
    `/future_bases/show_detail`,
    (response: DetailFutureBaseResponse) => response,
    { params: { id: futureBaseId } },
    undefined,
    'v2',
  )
}

export const patchAdminFutureBasePublish = async (values: any) => {
  return await ApiClient.patch(`/future_bases/${values?.id}/publish`, () => {}, values)
}

export const patchAdminFutureBaseHide = async (values: any) => {
  return await ApiClient.patch(`/future_bases/${values?.id}/hide`, () => {}, values)
}
