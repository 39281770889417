import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import Typography from '@/components/v2/atoms/Typography'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import {
  convertDateToTwoTypesYearsDateFormat,
  convertLocaleString,
} from '@/models/commonsModelFunc'
import { LOAN_FLG } from '@/models/inputs/inputCarsModelFunc'
import { RootState } from '@/store'
import SimulationAccordion from '@/templates/v2/simulations/_simulationAccordion'
import Modal from '@/components/v2/atoms/Modal'
import Button from '@/components/v2/atoms/Button'
import SimulationNewButton from '@/templates/v2/simulations/_simulationNewButton'
import MyDataCarEditForm from '@/templates/v2/myData/cars/Form'

export const CarTable: FC<{ onUpdate?: () => void }> = (props) => {
  const { inputCars } = useSelector((state: RootState) => state.inputCars)
  const [modalState, setModalState] = useState<{ isOpen: boolean; id?: number }>({
    isOpen: false,
  })

  return (
    <>
      {inputCars.length === 0 && (
        <Typography className="text-[14px] md:text-[16px] text-center" isBold>
          車・バイクが登録されていません。
        </Typography>
      )}

      {inputCars.map((item: any, index: number) => {
        const isLoanFlgTodo = item.loan_flg === LOAN_FLG.todo

        return (
          <div key={item.id} className="mb-[20px] last:mb-0">
            <div className="flex justify-between">
              <CircleTitle className="pb-[5px]" title={`お車${index + 1}`} />
              <div className="flex justify-end mb-[8px]">
                <Button onClick={() => setModalState({ isOpen: true, id: item.id })}>
                  <i className="ico edit text-[14px] text-black-700" />
                  <Typography className="pl-[5px] text-[14px] text-black-700">
                    編集
                  </Typography>
                </Button>
              </div>
            </div>

            <table className="w-[100%] text-[12px] md:text-[14px]">
              <tbody>
                <tr className="h-[45px] odd:bg-black-100">
                  <td className="w-[40%] px-[16px]">
                    <Typography isBold>名前</Typography>
                  </td>
                  <td className="w-[60%] px-[16px]">
                    <Typography>{item?.car_name}</Typography>
                  </td>
                </tr>
                <tr className="h-[45px] odd:bg-black-100">
                  <td className="px-[16px]">
                    <Typography isBold>乗車期間</Typography>
                  </td>
                  <td className="px-[16px]">
                    <Typography>
                      {`${convertDateToTwoTypesYearsDateFormat(
                        item?.stop_riding_at,
                        'yearMonthWithJapaneseYear',
                      )}まで`}
                    </Typography>
                  </td>
                </tr>
                <tr className="h-[45px] odd:bg-black-100">
                  <td className="px-[16px]">
                    <Typography isBold>
                      ローンの
                      <br className="md:hidden" />
                      有無
                    </Typography>
                  </td>
                  <td className="px-[16px]">
                    <Typography>{isLoanFlgTodo ? '支払い中' : '支払いなし'}</Typography>
                  </td>
                </tr>

                {isLoanFlgTodo && (
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="px-[16px]">
                      <Typography isBold>
                        毎月の
                        <br />
                        ローン返済額
                      </Typography>
                    </td>
                    <td className="px-[16px]">
                      <Typography>
                        {isLoanFlgTodo
                          ? `${convertLocaleString(item?.loan_amount_monthly)}円/月`
                          : '未選択'}
                      </Typography>
                    </td>
                  </tr>
                )}

                {isLoanFlgTodo && (
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="px-[16px]">
                      <Typography isBold>返済金利</Typography>
                    </td>
                    <td className="px-[16px]">
                      <Typography>
                        {isLoanFlgTodo
                          ? `${item?.loan_interest_rate_percent}%`
                          : '未選択'}
                      </Typography>
                    </td>
                  </tr>
                )}

                {isLoanFlgTodo && (
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="px-[16px]">
                      <Typography isBold>
                        ボーナス月の
                        <br />
                        上乗せ金額
                      </Typography>
                    </td>
                    <td className="px-[16px]">
                      <Typography>
                        {isLoanFlgTodo
                          ? `1回あたり ${convertLocaleString(item?.loan_bonus_monthly)}円`
                          : '未選択'}
                      </Typography>
                    </td>
                  </tr>
                )}

                {isLoanFlgTodo && (
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="px-[16px]">
                      <Typography isBold>返済期間</Typography>
                    </td>
                    <td className="px-[16px]">
                      <Typography>
                        {isLoanFlgTodo
                          ? `${convertDateToTwoTypesYearsDateFormat(
                              item?.loan_end_at,
                              'yearMonthWithJapaneseYear',
                            )}まで`
                          : '未選択'}
                      </Typography>
                    </td>
                  </tr>
                )}

                <tr className="h-[45px] odd:bg-black-100">
                  <td className="px-[16px]">
                    <Typography isBold>車検代</Typography>
                  </td>
                  <td className="px-[16px]">
                    <Typography>
                      {`${convertLocaleString(item?.vehicle_inspection_fee_yearly)}円/年`}
                    </Typography>
                  </td>
                </tr>
                <tr className="h-[45px] odd:bg-black-100">
                  <td className="px-[16px]">
                    <Typography isBold>自動車税</Typography>
                  </td>
                  <td className="px-[16px]">
                    <Typography>
                      {`${convertLocaleString(item?.automobile_tax_yearly)}円/年`}
                    </Typography>
                  </td>
                </tr>
                <tr className="h-[45px] odd:bg-black-100">
                  <td className="px-[16px]">
                    <Typography isBold>保険料</Typography>
                  </td>
                  <td className="px-[16px]">
                    <Typography>
                      {`${convertLocaleString(item?.insurance_premium_monthly)}円/月`}
                    </Typography>
                  </td>
                </tr>
                <tr className="h-[45px] odd:bg-black-100">
                  <td className="px-[16px]">
                    <Typography isBold>ガソリン代</Typography>
                  </td>
                  <td className="px-[16px]">
                    <Typography>
                      {`${convertLocaleString(item?.gasoline_fee_monthly)}円/月`}
                    </Typography>
                  </td>
                </tr>
                <tr className="h-[45px] odd:bg-black-100">
                  <td className="px-[16px]">
                    <Typography isBold>駐車場代</Typography>
                  </td>
                  <td className="px-[16px]">
                    <Typography>
                      {`${convertLocaleString(item?.parking_fee_monthly)}円/月`}
                    </Typography>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )
      })}

      <SimulationNewButton onClick={() => setModalState({ isOpen: true })} />

      <Modal
        className="lg:w-[1024px] w-[95%]"
        isOpen={modalState.isOpen}
        onClose={() => setModalState({ isOpen: false })}
      >
        <CircleTitle className="pb-[32px]" title="現在の車・バイク" />
        <MyDataCarEditForm
          id={modalState.id ?? 0}
          onClose={() => setModalState({ isOpen: false })}
          onSubmit={() => {
            setModalState({ isOpen: false })
            if (props.onUpdate != null) props.onUpdate()
          }}
        />
      </Modal>
    </>
  )
}

const InputCarBody: FC<{ onUpdate: () => void }> = (props) => {
  return (
    <SimulationAccordion className="pb-[16px]" title="現在の車・バイク">
      <CarTable onUpdate={props.onUpdate} />
    </SimulationAccordion>
  )
}

export default InputCarBody
