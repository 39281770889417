import React, { FC, useCallback } from 'react'
import { isNil as _isNil, isUndefined as _isUndefined } from 'lodash'
import Typography from '@/components/v2/atoms/Typography'
import { convertLocaleString } from '@/models/commonsModelFunc'

interface TooltipBalanceProps {
  /** アクティブ情報 */
  activeIndex: number | undefined
  /** ベースプラン名 */
  basePlanName?: any
  /** true: ベースプラン名非表示, false: ベースプラン名表示 */
  isHideBasePlanName?: boolean
  /** ベースアイテム */
  baseItem: any
  /** true: 比較有り, false: 比較無し */
  isCompare?: boolean
  /** 比較プラン名 */
  comparePlanName?: any
  /** 比較アイテム */
  compareItem?: any
}

const TooltipBalance: FC<TooltipBalanceProps> = (props) => {
  const {
    activeIndex,
    basePlanName,
    isHideBasePlanName,
    baseItem,
    isCompare,
    comparePlanName,
    compareItem,
  } = props

  const activeBalanceItem = !_isUndefined(activeIndex) ? baseItem?.[activeIndex] : {}
  const compareActiveBalanceItem = !_isUndefined(activeIndex)
    ? compareItem?.[activeIndex]
    : {}

  const fillIfEmpty = useCallback(
    (str: string) => {
      if (!_isNil(activeBalanceItem.balance_total)) {
        return str
      }
      return '-'
    },
    [activeBalanceItem],
  )

  const fillIfEmptyCompare = useCallback(
    (str: string) => {
      if (!_isNil(compareActiveBalanceItem.balance_total)) {
        return str
      }
      return '-'
    },
    [compareActiveBalanceItem],
  )

  return (
    <>
      {!_isUndefined(activeIndex) ? (
        <div
          className={`${
            isCompare ? 'flex' : ''
          } gap-x-[32px] py-[24px] px-[16px] rounded-[8px] bg-white md:shadow-modal visible pointer-events-auto overflow-x-scroll`}
        >
          <div className="w-full md:w-[230px] min-w-[230px]">
            <div className="pb-[16px] last:pb-0">
              {!isHideBasePlanName && (
                <Typography
                  className="pb-[4px] text-[12px] overflow-hidden whitespace-nowrap overflow-ellipsis"
                  isBold
                >
                  ベースプラン：
                  <br />
                  {basePlanName ?? '（プラン名未設定）'}
                </Typography>
              )}
              <div className="p-[10px] rounded-[4px] bg-primary-50">
                <Typography className="pb-[4px]" isBold>
                  {`${activeBalanceItem.name}年(${activeBalanceItem.age}歳)`}
                </Typography>
                <div className="flex items-center">
                  <i className="ico rect-2 pr-[4px]" style={{ color: '#ADD6F0' }} />
                  <Typography isBold>
                    収支合計：
                    {fillIfEmpty(
                      `${convertLocaleString(activeBalanceItem.balance_total)}万円`,
                    )}
                  </Typography>
                </div>
              </div>
            </div>

            <div className="pb-[16px] last:pb-0">
              <div className="flex justify-between mb-[5px] pb-[5px] border-b border-black-300">
                <div className="flex items-center">
                  <i
                    className="ico circle relative top-px pr-[4px] text-[12px]"
                    style={{ color: '#4A667F' }}
                  />
                  <Typography className="text-[12px]" isBold>
                    収入
                  </Typography>
                </div>
                <Typography className="text-[12px]" isBold>
                  {fillIfEmpty(
                    `総額　${convertLocaleString(activeBalanceItem.income_total)}万円 `,
                  )}
                </Typography>
              </div>
              <div className="flex justify-between pb-[5px] last:pb-0">
                <Typography className="text-[12px]">給与・退職金</Typography>
                <Typography className="text-[12px]">
                  {fillIfEmpty(
                    `${convertLocaleString(activeBalanceItem.salary_and_severance)}万円`,
                  )}
                </Typography>
              </div>
              <div className="flex justify-between pb-[5px] last:pb-0">
                <Typography className="text-[12px]">公的年金等</Typography>
                <Typography className="text-[12px]">
                  {fillIfEmpty(
                    `${convertLocaleString(activeBalanceItem.pension_income)}万円`,
                  )}
                </Typography>
              </div>
              <div className="flex justify-between pb-[5px] last:pb-0">
                <Typography className="text-[12px]">その他</Typography>
                <Typography className="text-[12px]">
                  {fillIfEmpty(
                    `${convertLocaleString(activeBalanceItem.other_income)}万円`,
                  )}
                </Typography>
              </div>
              <div className="flex justify-between pb-[5px] last:pb-0">
                <Typography className="text-[12px]">副業収入</Typography>
                <Typography className="text-[12px]">
                  {fillIfEmpty(
                    `${convertLocaleString(
                      activeBalanceItem.sub_income_annual_sales,
                    )}万円`,
                  )}
                </Typography>
              </div>
              <div className="flex justify-between pb-[5px] last:pb-0">
                <Typography className="text-[12px]">不動産収入</Typography>
                <Typography className="text-[12px]">
                  {fillIfEmpty(
                    `${convertLocaleString(
                      activeBalanceItem.estate_investment_income_total,
                    )}万円`,
                  )}
                </Typography>
              </div>
            </div>

            <div className="pb-[16px] last:pb-0">
              <div className="flex justify-between mb-[5px] pb-[5px] border-b border-black-300">
                <div className="flex items-center">
                  <i
                    className="ico circle relative top-px pr-[4px] text-[12px]"
                    style={{ color: '#C891AE' }}
                  />
                  <Typography className="text-[12px]" isBold>
                    支出
                  </Typography>
                </div>
                <Typography className="text-[12px]" isBold>
                  {fillIfEmpty(
                    `総額　${convertLocaleString(activeBalanceItem.spending_total)}万円 `,
                  )}
                </Typography>
              </div>
              <div className="flex justify-between pb-[5px] last:pb-0">
                <Typography className="text-[12px]">基本生活費</Typography>
                <Typography className="text-[12px]">
                  {fillIfEmpty(
                    `${convertLocaleString(activeBalanceItem.living_cost_basic)}万円`,
                  )}
                </Typography>
              </div>
              <div className="flex justify-between pb-[5px] last:pb-0">
                <Typography className="text-[12px]">学費</Typography>
                <Typography className="text-[12px]">
                  {fillIfEmpty(`${convertLocaleString(activeBalanceItem.tuition)}万円`)}
                </Typography>
              </div>
              <div className="flex justify-between pb-[5px] last:pb-0">
                <Typography className="text-[12px]">住宅費</Typography>
                <Typography className="text-[12px]">
                  {fillIfEmpty(
                    `${convertLocaleString(activeBalanceItem.housing_cost_total)}万円`,
                  )}
                </Typography>
              </div>
              <div className="flex justify-between pb-[5px] last:pb-0">
                <Typography className="text-[12px]">車両関係費</Typography>
                <Typography className="text-[12px]">
                  {fillIfEmpty(`${convertLocaleString(activeBalanceItem.car)}万円`)}
                </Typography>
              </div>
              <div className="flex justify-between pb-[5px] last:pb-0">
                <Typography className="text-[12px]">保険料</Typography>
                <Typography className="text-[12px]">
                  {fillIfEmpty(
                    `${convertLocaleString(activeBalanceItem.insurance_payment)}万円`,
                  )}
                </Typography>
              </div>
              <div className="flex justify-between pb-[5px] last:pb-0">
                <Typography className="text-[12px]">
                  特別な支出
                  {isCompare ? (
                    <>
                      <span className="hidden md:inline">・</span>
                      <br className="block md:hidden" />
                    </>
                  ) : (
                    <span>・</span>
                  )}
                  大きな支出
                </Typography>
                <Typography className="text-[12px]">
                  {fillIfEmpty(
                    `${convertLocaleString(activeBalanceItem.yearly_living_cost)}万円`,
                  )}
                </Typography>
              </div>
              <div className="flex justify-between pb-[5px] last:pb-0">
                <Typography className="text-[12px]">
                  天引き／税
                  {isCompare ? (
                    <>
                      <span className="hidden md:inline">・</span>
                      <br className="block md:hidden" />
                    </>
                  ) : (
                    <span>・</span>
                  )}
                  社会保険料
                </Typography>
                <Typography className="text-[12px]">
                  {fillIfEmpty(
                    `${convertLocaleString(
                      activeBalanceItem.deducted_expenses_and_tax,
                    )}万円`,
                  )}
                </Typography>
              </div>
              <div className="flex justify-between pb-[5px] last:pb-0">
                <Typography className="text-[12px]">副業支出</Typography>
                <Typography className="text-[12px]">
                  {fillIfEmpty(
                    `${convertLocaleString(activeBalanceItem.sub_income_expenses)}万円`,
                  )}
                </Typography>
              </div>
              <div className="flex justify-between pb-[5px] last:pb-0">
                <Typography className="text-[12px]">不動産支出</Typography>
                <Typography className="text-[12px]">
                  {fillIfEmpty(
                    `${convertLocaleString(
                      activeBalanceItem.estate_investment_cost_total,
                    )}万円`,
                  )}
                </Typography>
              </div>
            </div>
          </div>

          {isCompare && (
            <div className="w-full md:w-[230px] min-w-[230px]">
              <div className="pb-[16px] last:pb-0">
                <Typography
                  className="pb-[4px] text-[12px] overflow-hidden whitespace-nowrap overflow-ellipsis"
                  isBold
                >
                  比較プラン：
                  <br />
                  {comparePlanName ?? '（プラン名未設定）'}
                </Typography>
                <div className="p-[10px] rounded-[4px] bg-primary-50">
                  <Typography className="pb-[4px]" isBold>
                    {`${compareActiveBalanceItem.name}年(${compareActiveBalanceItem.age}歳)`}
                  </Typography>
                  <div className="flex items-center">
                    <i className="ico rect-2 pr-[4px]" style={{ color: '#ADD6F0' }} />
                    <Typography isBold>
                      収支合計：
                      {fillIfEmptyCompare(
                        `${convertLocaleString(
                          compareActiveBalanceItem.balance_total,
                        )}万円`,
                      )}
                    </Typography>
                  </div>
                </div>
              </div>

              <div className="pb-[16px] last:pb-0">
                <div className="flex justify-between mb-[5px] pb-[5px] border-b border-black-300">
                  <div className="flex items-center">
                    <i
                      className="ico circle relative top-px pr-[4px] text-[12px]"
                      style={{ color: '#4A667F' }}
                    />
                    <Typography className="text-[12px]" isBold>
                      収入
                    </Typography>
                  </div>
                  <Typography className="text-[12px]" isBold>
                    {fillIfEmptyCompare(
                      `総額　${convertLocaleString(
                        compareActiveBalanceItem.income_total,
                      )}万円 `,
                    )}
                  </Typography>
                </div>
                <div className="flex justify-between pb-[5px] last:pb-0">
                  <Typography className="text-[12px]">給与・退職金</Typography>
                  <Typography className="text-[12px]">
                    {fillIfEmptyCompare(
                      `${convertLocaleString(
                        compareActiveBalanceItem.salary_and_severance,
                      )}万円`,
                    )}
                  </Typography>
                </div>
                <div className="flex justify-between pb-[5px] last:pb-0">
                  <Typography className="text-[12px]">公的年金等</Typography>
                  <Typography className="text-[12px]">
                    {fillIfEmptyCompare(
                      `${convertLocaleString(
                        compareActiveBalanceItem.pension_income,
                      )}万円`,
                    )}
                  </Typography>
                </div>
                <div className="flex justify-between pb-[5px] last:pb-0">
                  <Typography className="text-[12px]">その他</Typography>
                  <Typography className="text-[12px]">
                    {fillIfEmptyCompare(
                      `${convertLocaleString(compareActiveBalanceItem.other_income)}万円`,
                    )}
                  </Typography>
                </div>
                <div className="flex justify-between pb-[5px] last:pb-0">
                  <Typography className="text-[12px]">副業収入</Typography>
                  <Typography className="text-[12px]">
                    {fillIfEmptyCompare(
                      `${convertLocaleString(
                        compareActiveBalanceItem.sub_income_annual_sales,
                      )}万円`,
                    )}
                  </Typography>
                </div>
                <div className="flex justify-between pb-[5px] last:pb-0">
                  <Typography className="text-[12px]">不動産収入</Typography>
                  <Typography className="text-[12px]">
                    {fillIfEmptyCompare(
                      `${convertLocaleString(
                        compareActiveBalanceItem.estate_investment_income_total,
                      )}万円`,
                    )}
                  </Typography>
                </div>
              </div>

              <div className="pb-[16px] last:pb-0">
                <div className="flex justify-between mb-[5px] pb-[5px] border-b border-black-300">
                  <div className="flex items-center">
                    <i
                      className="ico circle relative top-px pr-[4px] text-[12px]"
                      style={{ color: '#C891AE' }}
                    />
                    <Typography className="text-[12px]" isBold>
                      支出
                    </Typography>
                  </div>
                  <Typography className="text-[12px]" isBold>
                    {fillIfEmptyCompare(
                      `総額　${convertLocaleString(
                        compareActiveBalanceItem.spending_total,
                      )}万円 `,
                    )}
                  </Typography>
                </div>
                <div className="flex justify-between pb-[5px] last:pb-0">
                  <Typography className="text-[12px]">基本生活費</Typography>
                  <Typography className="text-[12px]">
                    {fillIfEmptyCompare(
                      `${convertLocaleString(
                        compareActiveBalanceItem.living_cost_basic,
                      )}万円`,
                    )}
                  </Typography>
                </div>
                <div className="flex justify-between pb-[5px] last:pb-0">
                  <Typography className="text-[12px]">学費</Typography>
                  <Typography className="text-[12px]">
                    {fillIfEmptyCompare(
                      `${convertLocaleString(compareActiveBalanceItem.tuition)}万円`,
                    )}
                  </Typography>
                </div>
                <div className="flex justify-between pb-[5px] last:pb-0">
                  <Typography className="text-[12px]">住宅費</Typography>
                  <Typography className="text-[12px]">
                    {fillIfEmptyCompare(
                      `${convertLocaleString(
                        compareActiveBalanceItem.housing_cost_total,
                      )}万円`,
                    )}
                  </Typography>
                </div>
                <div className="flex justify-between pb-[5px] last:pb-0">
                  <Typography className="text-[12px]">車両関係費</Typography>
                  <Typography className="text-[12px]">
                    {fillIfEmptyCompare(
                      `${convertLocaleString(compareActiveBalanceItem.car)}万円`,
                    )}
                  </Typography>
                </div>
                <div className="flex justify-between pb-[5px] last:pb-0">
                  <Typography className="text-[12px]">保険料</Typography>
                  <Typography className="text-[12px]">
                    {fillIfEmptyCompare(
                      `${convertLocaleString(
                        compareActiveBalanceItem.insurance_payment,
                      )}万円`,
                    )}
                  </Typography>
                </div>
                <div className="flex justify-between pb-[5px] last:pb-0">
                  <Typography className="text-[12px]">
                    特別な支出
                    <span className="hidden md:inline">・</span>
                    <br className="block md:hidden" />
                    大きな支出
                  </Typography>
                  <Typography className="text-[12px]">
                    {fillIfEmptyCompare(
                      `${convertLocaleString(
                        compareActiveBalanceItem.yearly_living_cost,
                      )}万円`,
                    )}
                  </Typography>
                </div>
                <div className="flex justify-between pb-[5px] last:pb-0">
                  <Typography className="text-[12px]">
                    天引き／税
                    <span className="hidden md:inline">・</span>
                    <br className="block md:hidden" />
                    社会保険料
                  </Typography>
                  <Typography className="text-[12px]">
                    {fillIfEmptyCompare(
                      `${convertLocaleString(
                        compareActiveBalanceItem.deducted_expenses_and_tax,
                      )}万円`,
                    )}
                  </Typography>
                </div>
                <div className="flex justify-between pb-[5px] last:pb-0">
                  <Typography className="text-[12px]">副業支出</Typography>
                  <Typography className="text-[12px]">
                    {fillIfEmptyCompare(
                      `${convertLocaleString(
                        compareActiveBalanceItem.sub_income_expenses,
                      )}万円`,
                    )}
                  </Typography>
                </div>
                <div className="flex justify-between pb-[5px] last:pb-0">
                  <Typography className="text-[12px]">不動産支出</Typography>
                  <Typography className="text-[12px]">
                    {fillIfEmptyCompare(
                      `${convertLocaleString(
                        compareActiveBalanceItem.estate_investment_cost_total,
                      )}万円`,
                    )}
                  </Typography>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : null}
    </>
  )
}

export default TooltipBalance
