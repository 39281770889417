import React, { FC } from 'react'
import {
  CircularProgress as MuiCircularProgress,
  CircularProgressProps as MuiCircularProgressProps,
} from '@material-ui/core'

interface CircularProgressProps extends MuiCircularProgressProps {}

const CircularProgress: FC<CircularProgressProps> = (props) => {
  return <MuiCircularProgress {...props} />
}

export default CircularProgress
