import React, { FC } from 'react'
import { HOUSE_TYPE } from '@/models/commonHousingModelFunc'
import { required } from '@/utils/validate'

import Typography from '@/components/v2/atoms/Typography'
import SelectBox from '@/components/v2/molecules/SelectBox'

const RelocationSelection: FC = () => {
  return (
    <>
      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
        <Typography
          className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
          isBold
        >
          住み替え先
        </Typography>
        <SelectBox
          name="future_v2_housing.house_type"
          placeholder="選択する"
          isFullWidth
          options={[
            { value: HOUSE_TYPE.rent, label: '賃貸' },
            { value: HOUSE_TYPE.own, label: '買い替え' },
            { value: HOUSE_TYPE.other, label: 'その他' },
          ]}
          validate={required}
        />
      </div>
    </>
  )
}

export default RelocationSelection
