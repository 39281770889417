import React, { FC } from 'react'
import { useField, useForm } from 'react-final-form'
import {
  INPUT_LOAN_TYPE_BUTTON_ITEMS,
  INPUT_LOAN_TYPE_WITHOUT_SPOUSE_BUTTON_ITEMS,
  INPUT_SPLIT_LOAN_TYPE_BUTTON_ITEMS,
} from '@/constants/formItem/inputs/inputHousings'
import { API_FLAG } from '@/models/commonsModelFunc'
import {
  selectLoanObjectNumber,
  getTargetSelfOwnedAttributesName,
} from '@/models/commonHousingModelFunc'
import { selectFutureV2LoanObjectName } from '@/models/futures/futureV2HousingLoansModelFunc'
import {
  INPUT_LOAN_LOAN_LENDER,
  selectLoanObjectName,
  isPairLoan,
} from '@/models/inputs/inputLoansModelFunc'
import { required } from '@/utils/validate'
import { isSplitedLoan } from '@/models/inputs/inputHousingSelfOwnedModelFunc'

import Typography from '@/components/v2/atoms/Typography'
import SelectBox from '@/components/v2/molecules/SelectBox'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'

interface LoanTypeProps {
  /** true: シミュレーション, false: 現状 */
  isFuture?: boolean
  /** true: 建物用ローン, false: 土地用ローン・分割なし */
  isBuilding?: boolean
}

const LoanType: FC<LoanTypeProps> = (props) => {
  const { isFuture, isBuilding } = props
  const { change } = useForm()
  const loanObjectNum = selectLoanObjectNumber(isBuilding)
  const targetPairObjectNum = isBuilding ? 3 : 1
  const targetHousingSelfOwnedAttributesName = getTargetSelfOwnedAttributesName(isFuture)
  const selectTargetLoanObjectName = isFuture
    ? selectFutureV2LoanObjectName
    : selectLoanObjectName

  const { familyStructures } = useSelector((state: RootState) => state.familyStructures)
  const hasSpouse = familyStructures?.spouse_type === API_FLAG.on
  const { detailFutureBaseV2 } = useSelector((state: RootState) => state.futureBases)

  const {
    input: { value: loanType1 },
  } = useField(`${targetHousingSelfOwnedAttributesName}.loan_type1`)
  const {
    input: { value: loanType2 },
  } = useField(`${targetHousingSelfOwnedAttributesName}.loan_type2`)

  const handleChangeLoanType = (value: number) => {
    change(selectTargetLoanObjectName(loanObjectNum, 'loan_type'), value)

    if (isPairLoan(value)) {
      change(selectTargetLoanObjectName(targetPairObjectNum, 'loan_type'), value)
      change(
        selectTargetLoanObjectName(loanObjectNum, 'loan_lender'),
        String(INPUT_LOAN_LOAN_LENDER.private),
      )
      change(
        selectTargetLoanObjectName(targetPairObjectNum, 'loan_lender'),
        String(INPUT_LOAN_LOAN_LENDER.private),
      )
    }
  }

  const renderDescription = () => {
    let descHead = ''
    if (!isBuilding && loanType2 === API_FLAG.on) {
      descHead = '土地用'
    }
    if (isBuilding) {
      descHead = '建物用'
    }
    return descHead + 'ローンの方式'
  }

  const renderButtonItems = () => {
    const commonHasSpouse = isFuture
      ? detailFutureBaseV2?.simulation_info?.has_married ||
        detailFutureBaseV2?.simulation_info?.has_married_in_future
      : hasSpouse

    if (
      isSplitedLoan({
        loan_type1: String(loanType1),
        loan_type2: String(loanType2),
      } as any)
    ) {
      return commonHasSpouse
        ? INPUT_SPLIT_LOAN_TYPE_BUTTON_ITEMS.map((item: any) => ({
            ...item,
            isDisabled: item.disabled,
          }))
        : INPUT_LOAN_TYPE_WITHOUT_SPOUSE_BUTTON_ITEMS.map((item: any) => ({
            ...item,
            isDisabled: item.disabled,
          }))
    } else {
      return commonHasSpouse
        ? INPUT_LOAN_TYPE_BUTTON_ITEMS.map((item: any) => ({
            ...item,
            isDisabled: item.disabled,
          }))
        : INPUT_LOAN_TYPE_WITHOUT_SPOUSE_BUTTON_ITEMS.map((item: any) => ({
            ...item,
            isDisabled: item.disabled,
          }))
    }
  }

  return (
    <>
      <div className="lg:flex border-black-300 border-dashed border-b-2 items-center pb-[16px]">
        <Typography
          className="min-w-[140px] lg:min-w-[210px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
          isBold
        >
          {renderDescription()}
        </Typography>
        <SelectBox
          name={selectTargetLoanObjectName(loanObjectNum, 'loan_type')}
          placeholder="選択する"
          isFullWidth
          options={renderButtonItems()}
          validate={required}
          onChange={(event: any) => handleChangeLoanType(event.target.value)}
        />
      </div>
    </>
  )
}

export default LoanType
