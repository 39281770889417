import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Prompt, useLocation } from 'react-router-dom'
import { Location, Action } from 'history'
import Button from '@/components/v2/atoms/Button'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import ButtonBlack from '@/components/v2/atoms/ButtonBlack'
import Typography from '@/components/v2/atoms/Typography'
import LoadingOverlay from '@/components/v1/atoms/LoadingOverlay'
import Modal from '@/components/v2/atoms/Modal'
import HelpCircleTertiaryIcon from '@/assets/images/v2/help-circle-tertiary.svg'
import {
  fetchAccount,
  updateAccountHasCreatedFirstPlanV2,
} from '@/containers/accountsSlice'
import { setPageMeta } from '@/containers/pageMetasSlice'
import { HANDLER_TYPE } from '@/constants/formItem/futures/futureBases'
import { commonFutureBaseNewApis } from '@/containers/futures/futureBasesSlice'
import { fetchFamilyStructureV2 } from '@/containers/familyStructuresSlice'
import { fetchInputAssetSummary } from '@/containers/inputs/inputAssetsSlice'
import { isNotEmptyValue } from '@/models/commonsModelFunc'
import { SimulationDetailEditContext } from '@/models/futures/futureBasesModelFunc'
import { RootState } from '@/store'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import SimulationFirstInstructionModal from '@/templates/v2/guidances/SimulationFirstInstructionModal'
import SimulationPanelModal from '@/templates/v2/guidances/SimulationPanelModal'
import SimulationPageGuidanceModal from '@/templates/v2/guidances/SimulationPageGuidanceModal'
import SimulationAfterCreateModal from '@/templates/v2/guidances/SimulationAfterCreateModal'
import SimulationGraphBody from '@/templates/v2/simulations/_simulationGraphBodyUser'
import SimulationSummaryBody from '@/templates/v2/simulations/_simulationSummaryBody'
import SimulationTableBody from '@/templates/v2/simulations/_simulationTableBody'
import SimulationPanel from '@/templates/v2/simulations/_simulationPanelUser'
import ConfirmMydataTable from '@/templates/v2/simulations/_confirmMydataTable'
import { find as _find } from 'lodash'
import SimulationFirstInstructionNextModal from '../guidances/SimulationFirstInstructionNextModal'

const SimulationNew: FC = () => {
  const dispatch = useDispatch()
  const { handleMovePage } = useCustomHistory()
  const query = new URLSearchParams(useLocation().search)
  const futureBaseId = query.get('id')

  const [isCompare, setIsCompare] = useState<boolean>(false)
  const [isCompareMyData, setIsCompareMyData] = useState<boolean>(true)
  const [isOpenRegisterModal, setIsOpenRegisterModal] = useState<boolean>(false)
  const [hasCheckedCreateGuidance, setHasCheckedCreateGuidance] = useState<boolean>(false)
  const [isOpenFirstInstructionModal, setIsOpenFirstInstractionModal] = useState<boolean>(false)
  const [hasDisplayedFirstInstructionModal, sethasDisplayedFirstInstractionModal] = useState<boolean>(false)
  const [isOpenFirstInstructionNextModal, setIsOpenFirstInstractionNextModal] = useState<boolean>(false)
  const [isOpenPanelGuidanceModal, setIsOpenPanelGuidanceModal] = useState<boolean>(false)
  const [isShowInsurance, setIsShowInsurance] = useState<boolean>(false)
  const [isOpenBeforeCreateGuidanceModal, setIsOpenBeforeCreateGuidanceModal] = useState<
    boolean
  >(false)
  const [isOpenAfterCreateGuidanceModal, setIsOpenAfterCreateGuidanceModal] = useState<
    boolean
  >(false)
  const [simulationTitle, setSimulationTitle] = useState<string>(
    '新規シミュレーションプラン',
  )
  const [isGraphLoading, setIsGraphLoading] = useState<boolean>(false)

  // confirm navigation states
  const [isOpenConfirmedNavigationModal, setIsOpenConfirmedNavigationModal] = useState<
    boolean
  >(false)
  const [confirmedNavigation, setConfirmedNavigation] = useState(false)
  const [lastLocation, setLastLocation] = useState<Location | null>(null)
  const [handleMoveURLAfterSave, setHandleMoveURLAfterSave] = useState<string | null>(
    null,
  )

  const [isOpenConfirmMydataModal, setOpenConfirmMydataModal] = useState<boolean>(true)

  const {
    account,
    futureItems,
    isLoading: isAccountLoading,
    isGetAccountShowInputLoading,
  } = useSelector((state: RootState) => state.accounts)
  const {
    simulationInfo,
    isLoading: isFutureBaseLoading,
    isGetDetailFutureBaseLoading,
  } = useSelector((state: RootState) => state.futureBases)

  const { futureBasesV2 } = useSelector((state: RootState) => state.futureBases)

  const handleBlockedNavigation = (nextLocation: Location, _: Action): boolean => {
    if (!confirmedNavigation) {
      setIsOpenConfirmedNavigationModal(true)
      setLastLocation(nextLocation)
      return false
    }
    return true
  }

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'シミュレーションセット作成',
        description: 'シミュレーションセット作成',
        keyword: 'シミュレーションセット作成',
      }),
    )
    const numFutureBaseId = futureBaseId != null ? Number(futureBaseId) : undefined
    dispatch(commonFutureBaseNewApis(numFutureBaseId))
    dispatch(fetchFamilyStructureV2())
    dispatch(fetchInputAssetSummary())
  }, [])

  useEffect(() => {
    const item = sessionStorage.getItem('confirmMydataState')
    if (item != null) {
      const confirmMydataState = JSON.parse(item)
      if (confirmMydataState.confirmed) setOpenConfirmMydataModal(false)
      sessionStorage.removeItem('confirmMydataState')
    }
  }, [])

  useEffect(() => {
    if (isAccountLoading || isFutureBaseLoading) return

    if (
      !account['has_created_first_plan'] &&
      Number(simulationInfo.future_item_count) > 0
    ) {
      dispatch(updateAccountHasCreatedFirstPlanV2(() => dispatch(fetchAccount())))
      return
    }

    if (!account['has_created_first_plan'] && !hasDisplayedFirstInstructionModal) {
      setIsOpenFirstInstractionModal(true)
      sethasDisplayedFirstInstractionModal(true)
    }
  }, [account['has_created_first_plan'], simulationInfo.future_item_count, isAccountLoading, isFutureBaseLoading])

  useEffect(() => {
    if (isFutureBaseLoading) return
    if ( !isNotEmptyValue(account['has_created_first_plan']) && !account['has_created_first_plan'])
      return

    if (
      simulationInfo.future_item_count == 1 &&
      !hasCheckedCreateGuidance &&
      futureBasesV2.length == 0
    ) {
      setIsOpenAfterCreateGuidanceModal(true)
    }
  }, [simulationInfo, hasCheckedCreateGuidance, futureBasesV2])

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      handleMovePage(lastLocation.pathname + lastLocation.search)
    }
  }, [confirmedNavigation, lastLocation])

  useEffect(() => {
    if (futureBaseId != null) {
      const item = _find(futureBasesV2, { id: Number(futureBaseId) })
      if (item != null) setSimulationTitle(item.future_name + 'のコピー')
    }
  }, [futureBasesV2])

  const openPanelGuidanceIfNeeded = useCallback(() => {
    if (isAccountLoading || isFutureBaseLoading) return
    if (!account['has_created_first_plan']) {
      setIsOpenPanelGuidanceModal(true)
    }
  }, [account['has_created_first_plan'], isAccountLoading, isFutureBaseLoading, setIsOpenPanelGuidanceModal])


  const mydateDate = useMemo(() => {
    if (futureBaseId != null) {
      const item = _find(futureBasesV2, { id: Number(futureBaseId) })
      if (item != null) return item.updated_at
    }
    return ''
  }, [futureBasesV2])

  return (
    <SimulationDetailEditContext.Provider
      // 新規作成時、複製時は常に true
      value={{ isManipulatedApproval: futureItems?.handler_type === HANDLER_TYPE.user || true }}
    >
      <LoadingOverlay
        isLoading={
          !isGraphLoading &&
          (isAccountLoading || isFutureBaseLoading || isGetDetailFutureBaseLoading)
        }
      />
      <div className="md:hidden flex flex-row justify-between items-baseline px-[12px] py-[6px] sticky top-0 z-50 bg-primary-50">
        <Typography
          className="leading-normal text-[14px] text-black-900 whitespace-nowrap"
          isBold
        >
          新規シミュレーションプラン
        </Typography>
        <ButtonPrimary
          onClick={() => {
            setIsOpenRegisterModal(true)
            setHandleMoveURLAfterSave(null)
          }}
          className="px-[16px] py-[6px]"
        >
          <Typography
            className="leading-normal text-[12px] text-white whitespace-nowrap"
            isBold
          >
            保存
          </Typography>
        </ButtonPrimary>
      </div>

      <div className="hidden md:flex flex-row justify-between px-24 py-12 sticky top-0 z-50 bg-black-100">
        <div className="flex flex-row items-center gap-x-24">
          <Button
            onClick={() => {
              handleMovePage('/v2/simulations')
            }}
          >
            <Typography
              className="leading-normal text-[14px] text-secondary-500 whitespace-nowrap"
              isBold
            >
              戻る
            </Typography>
          </Button>
          <input
            type="text"
            className="w-[382px] h-[37px] px-16 py-8 border border-black-300 rounded-sm text-black-900 text-[14px] leading-normal"
            value={simulationTitle}
            onChange={(event) => setSimulationTitle(event.target.value)}
          ></input>
        </div>
        <div>
          <ButtonPrimary
            onClick={() => {
              setIsOpenRegisterModal(true)
              setHandleMoveURLAfterSave(null)
            }}
            className="px-16 py-8"
          >
            <Typography
              className="leading-normal text-[14px] text-white whitespace-nowrap"
              isBold
            >
              保存する
            </Typography>
          </ButtonPrimary>
        </div>
      </div>

      <SimulationPanel
        isOpenRegisterModal={isOpenRegisterModal}
        onCloseRegisterModal={() => {
          setIsOpenRegisterModal(false)
        }}
        futureName={simulationTitle}
        setFutureName={setSimulationTitle}
        futureBaseId={futureBaseId == null ? null : Number(futureBaseId)}
        handleMovePage={(url: string) => {
          handleMovePage(url)
          setConfirmedNavigation(true)
        }}
        handleMoveURLAfterSave={handleMoveURLAfterSave}
        handlePanelOpen={() => {
          openPanelGuidanceIfNeeded()
        }}
      />

      <div>
        <div className="p-[10px] md:p-[30px] bg-white">
          <div className="flex items-center gap-x-[18px] mb-[12px] pb-[10px] border-b-2 border-black-300">
            <Typography
              className="text-[19px] md:text-[28px] text-black-800 hidden md:block"
              isBold
            >
              シミュレーションプラン作成
            </Typography>
            <Typography
              className="text-[19px] md:text-[28px] text-black-800 block md:hidden"
              isBold
            >
              シミュレーション作成
            </Typography>
            <div className="flex items-center">
              <Button
                onClick={() => {
                  setIsOpenBeforeCreateGuidanceModal(true)
                }}
              >
                <HelpCircleTertiaryIcon />
                <Typography className="text-[12px] text-tertiary-500 border-b border-tertiary-500">
                  ガイダンスを見る
                </Typography>
              </Button>
            </div>
          </div>
          <Typography className="leading-normal text-[14px] text-black-900 mb-[12px]">
            設定パネルから将来の予定を追加しましょう。
            <br />
            追加した設定を反映しながら、グラフと表を理想の将来像に近づけていきましょう！
          </Typography>
          <SimulationGraphBody
            className="pb-[80px]"
            isCompare={isCompare}
            isCompareMyData={isCompareMyData}
            setIsCompare={setIsCompare}
            setIsCompareMyData={setIsCompareMyData}
            setIsShowInsurance={setIsShowInsurance}
            setIsGraphLoading={setIsGraphLoading}
          />
          <SimulationSummaryBody className="pb-[80px]" />
          <SimulationTableBody
            isCompare={isCompare}
            isCompareMyData={isCompareMyData}
            isShowInsurance={isShowInsurance}
          />
        </div>
      </div>

      {/* ガイダンスを見る押下時に表示するガイダンス */}
      <SimulationPageGuidanceModal
        isOpen={isOpenBeforeCreateGuidanceModal}
        onClose={() => setIsOpenBeforeCreateGuidanceModal(false)}
      />

      {/* シミュレーションが１つも登録されていない場合に表示するガイダンス */}
      <SimulationFirstInstructionModal
        isOpen={isOpenFirstInstructionModal}
        onClose={() => {
          setIsOpenFirstInstractionModal(false)
          setIsOpenFirstInstractionNextModal(true)
        }}
      />
      {/* シミュレーションが１つも登録されていない場合に表示するガイダンスの続き
          （プログレス表示をなくしたいため致し方なく分けている）
      */}
      <SimulationFirstInstructionNextModal
        isOpen={isOpenFirstInstructionNextModal}
        onClose={() => {
          setIsOpenFirstInstractionNextModal(false)
        }}
      />

      {/* シミュレーションが１つも登録されていないユーザーでシミュレーションパネルを表示した時に表示するガイダンス */}
      <SimulationPanelModal
        isOpen={isOpenPanelGuidanceModal}
        onClose={() => setIsOpenPanelGuidanceModal(false)}
      />

      {/* シミュレーションが１つも登録されていないユーザーでシミュレーションの項目を登録した時に表示するガイダンス */}
      <SimulationAfterCreateModal
        isOpen={isOpenAfterCreateGuidanceModal}
        onClose={() => {
          setIsOpenAfterCreateGuidanceModal(false)
          setHasCheckedCreateGuidance(true)
        }}
      />

      <Prompt when={true} message={handleBlockedNavigation} />
      <Modal
        className="md:w-[620px] w-[95%]"
        isOpen={isOpenConfirmedNavigationModal}
        onClose={() => {
          setIsOpenConfirmedNavigationModal(false)
        }}
      >
        <div className="md:px-[54px] md:py-[43px]">
          <Typography className="leading-normal text-[14px] text-black-900 mb-[38px]">
            編集中の内容が保存されていません。
            <br />
            本当に移動してよろしいですか？
          </Typography>

          <div className="flex flex-col gap-y-[24px] items-center md:flex-row md:gap-x-[40px] md:justify-center">
            <ButtonBlack
              className="w-[188px] h-[45px]"
              onClick={() => {
                setIsOpenConfirmedNavigationModal(false)
                setConfirmedNavigation(true)
              }}
            >
              <Typography
                className="flex-auto pl-30 leading-normal text-[14px] text-black-400 whitespace-nowrap"
                isBold
              >
                保存せずに移動する
              </Typography>
              <i className="ico chevron-right text-24 mr-18 text-black-400" />
            </ButtonBlack>
            <ButtonPrimary
              className="w-[188px] h-[45px]"
              onClick={() => {
                setIsOpenConfirmedNavigationModal(false)
                setIsOpenRegisterModal(true)
                setHandleMoveURLAfterSave(
                  lastLocation ? lastLocation.pathname + lastLocation.search : null,
                )
              }}
            >
              <Typography
                className="flex-auto pl-30 leading-normal text-[14px] text-white whitespace-nowrap"
                isBold
              >
                保存して移動する
              </Typography>
              <i className="ico chevron-right text-24 mr-18 text-white" />
            </ButtonPrimary>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={futureBaseId != null && isOpenConfirmMydataModal}
        onClose={() => setOpenConfirmMydataModal(false)}
        className="md:w-[620px] w-[95%]"
      >
        <LoadingOverlay isLoading={isGetAccountShowInputLoading} />
        <div className="flex flex-col gap-34 items-center md:m-20">
          <Typography className="leading-normal text-[14px]">
            マイデータは最新の情報に更新されていますか？
            <br /> <br />「{simulationTitle}」のシミュレーション結果は、
            {mydateDate}時点のマイデータをベースに算出されています。
            <br /> <br />
            より正確なシミュレーション結果を出すために、
            ご状況に変化があった場合は、マイデータを更新してください。
          </Typography>

          <ConfirmMydataTable />

          <div className="flex flex-col md:flex-row gap-20 md:gap-40 justify-center">
            <ButtonBlack
              className="w-[188px] py-[6px]"
              onClick={() => setOpenConfirmMydataModal(false)}
            >
              <Typography
                className="flex-auto pl-30 leading-normal text-[14px] text-black-400 whitespace-nowrap"
                isBold
              >
                マイデータ設定を <br /> 確認・編集せずに進む
              </Typography>
              <i className="ico chevron-right text-24 mr-18 text-black-400" />
            </ButtonBlack>
            <ButtonPrimary
              className="w-[188px] py-[6px]"
              onClick={() => {
                console.log(
                  `/v2/myData/families?future_base_id=${futureBaseId}&action=copy`,
                )
                handleMovePage(
                  `/v2/myData/families?future_base_id=${futureBaseId}&action=copy`,
                )
                setConfirmedNavigation(true)
              }}
            >
              <Typography
                className="flex-auto pl-30 leading-normal text-[14px] text-white whitespace-nowrap"
                isBold
              >
                マイデータ設定を <br /> 確認・編集する
              </Typography>
              <i className="ico chevron-right text-24 mr-18 text-white" />
            </ButtonPrimary>
          </div>
        </div>
      </Modal>
    </SimulationDetailEditContext.Provider>
  )
}

export default SimulationNew
