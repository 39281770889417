import { API_FLAG, isNotEmptyValue } from '@/models/commonsModelFunc'
import { FutureV2HousingSelfOwned } from './futureV2HousingSelfOwnedModel'

export const isSplitedLoan = (
  futureSelfOwnedAttributes: Partial<FutureV2HousingSelfOwned> | undefined | null,
) => {
  if (
    !isNotEmptyValue(futureSelfOwnedAttributes?.loan_type1) ||
    !isNotEmptyValue(futureSelfOwnedAttributes?.loan_type2)
  )
    return false

  return (
    Number(futureSelfOwnedAttributes!.loan_type1) === API_FLAG.on &&
    Number(futureSelfOwnedAttributes!.loan_type2) === API_FLAG.on
  )
}
