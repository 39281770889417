import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  filter as _filter,
  find as _find,
  isEmpty as _isEmpty,
  isNull as _isNull,
  sortBy as _sortBy,
} from 'lodash'
import {
  patchFutureV2HousingRent,
  patchFutureV2HousingSelfOwned,
  patchFutureV2HousingOther,
  postFutureV2HousingRentV2,
  postFutureV2HousingSelfOwnedV2,
  postFutureV2HousingOtherV2,
  deleteFutureV2HousingV2,
} from '@/api/futures/futureV2Housings'
import { unauthorizedCheck } from '@/containers/accountsSlice'
import { ApiError } from '@/models/commonsModel'
import { castNumberWithoutOperator, isNotEmptyValue } from '@/models/commonsModelFunc'
import { AppThunk } from '@/store'
import { FutureV2Housing } from '@/models/futures/futureV2HousingsModel'
import { FutureV2HousingLoanSelectItem } from '@/models/futures/futureV2HousingLoansModel'
import { FutureV2HousingRentUpdateValues } from '@/models/futures/futureV2HousingRentsModel'
import { FutureV2HousingSelfOwnedUpdateValues } from '@/models/futures/futureV2HousingSelfOwnedModel'
import { FutureV2HousingOtherUpdateValues } from '@/models/futures/futureV2HousingOthersModel'

interface FutureV2HousingState {
  futureV2Housings: FutureV2Housing[]
  futureV2AnotherHousings: FutureV2Housing[]
  futureV2Loans: FutureV2HousingLoanSelectItem[]
  hasFutureV2HousingForInput: boolean
  isLoading: boolean
}

const initialState: FutureV2HousingState = {
  futureV2Housings: [],
  futureV2AnotherHousings: [],
  futureV2Loans: [],
  hasFutureV2HousingForInput: false,
  isLoading: false,
}

const futureV2HousingsSlice = createSlice({
  name: 'futureV2Housing',
  initialState,
  reducers: {
    setFutureV2Housings: (state, action: PayloadAction<FutureV2Housing[]>) => {
      const { payload } = action

      let newFutureV2Housings = payload.map((item) => {
        if (!_isNull(item.future_v2_housing_self_owned)) {
          return {
            ...item,
            model_type: 'FutureV2Housing',
            future_v2_housing_self_owned: {
              ...item.future_v2_housing_self_owned!,
              repair_costs_span: Math.round(
                castNumberWithoutOperator(
                  item.future_v2_housing_self_owned!.repair_costs_span,
                ) / 12,
              ),
            },
          }
        }
        if (!_isNull(item.future_v2_housing_other)) {
          return {
            ...item,
            model_type: 'FutureV2Housing',
            future_v2_housing_other: {
              ...item.future_v2_housing_other!,
              future_v2_housing_other_costs: [
                ...item.future_v2_housing_other!.future_v2_housing_other_costs.map(
                  (otherCost) => {
                    return {
                      ...otherCost,
                      custom_occurance_in_months: Math.round(
                        castNumberWithoutOperator(otherCost.custom_occurance_in_months) /
                          12,
                      ),
                    }
                  },
                ),
              ],
            },
          }
        }
        if (isNotEmptyValue(item.future_v2_housing_self_owned)) {
          return {
            ...item,
            model_type: 'FutureV2Housing',
            future_v2_housing_self_owned: {
              ...item.future_v2_housing_self_owned!,
              future_v2_housing_loans: _isEmpty(
                item.future_v2_housing_self_owned!.future_v2_housing_loans,
              )
                ? []
                : _sortBy(
                    item.future_v2_housing_self_owned!.future_v2_housing_loans,
                    'id',
                  ),
            },
          }
        }

        return {
          ...item,
          model_type: 'FutureV2Housing',
        }
      })

      newFutureV2Housings = _sortBy(newFutureV2Housings, 'scheduled_at_age')

      state.futureV2Housings = newFutureV2Housings

      state.futureV2AnotherHousings = _filter(
        newFutureV2Housings,
        (item) => item.prev_housing_type !== 'SimpleHousing' && !item.prev_housing_id,
      )

      state.hasFutureV2HousingForInput = !!_find(
        newFutureV2Housings,
        (item) => item.prev_housing_type === 'InputHousing',
      )

      return state
    },
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
  },
})

export const {
  setFutureV2Housings,
  requestStart,
  requestSuccess,
  requestFailure,
} = futureV2HousingsSlice.actions
export const futureV2HousingsReducer = futureV2HousingsSlice.reducer

export const updateFutureV2HousingRent = (
  values: FutureV2HousingRentUpdateValues,
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchFutureV2HousingRent(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const updateFutureV2HousingSelfOwned = (
  values: FutureV2HousingSelfOwnedUpdateValues,
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchFutureV2HousingSelfOwned(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const updateFutureV2HousingOther = (
  values: FutureV2HousingOtherUpdateValues,
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchFutureV2HousingOther(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const createFutureV2HousingRentV2 = (
  values: any,
  callback: (response?: any) => void,
  resolve?: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postFutureV2HousingRentV2(values)
    .then((response) => {
      dispatch(requestSuccess())
      callback(response)
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error, resolve))
    })
}

export const createFutureV2HousingSelfOwnedV2 = (
  values: any,
  callback: (response?: any) => void,
  resolve?: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postFutureV2HousingSelfOwnedV2(values)
    .then((response) => {
      dispatch(requestSuccess())
      callback(response)
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error, resolve))
    })
}

export const createFutureV2HousingOtherV2 = (
  values: any,
  callback: (response?: any) => void,
  resolve?: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postFutureV2HousingOtherV2(values)
    .then((response) => {
      dispatch(requestSuccess())
      callback(response)
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const destroyFutureV2HousingV2 = (
  id: number,
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  deleteFutureV2HousingV2(id)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}
