import ApiClient from '../apiClient'
import { FutureEstateInvestmentSaleUpdateValues } from '@/models/futures/futureEstateInvestmentSalesModel'

export const postFutureEstateInvestmentSaleV2 = async (values: any) => {
  return await ApiClient.post(
    '/future_estate_investment_sales',
    () => {},
    values,
    undefined,
    'v2',
  )
}

export const patchFutureEstateInvestmentSaleV2 = async (
  values: FutureEstateInvestmentSaleUpdateValues,
  id: number,
) => {
  return await ApiClient.patch(
    `/future_estate_investment_sales/${id}`,
    () => {},
    values,
    undefined,
    'v2',
  )
}

export const deleteFutureEstateInvestmentSaleV2 = async (values: any, id: number) => {
  return await ApiClient.delete(
    `/future_estate_investment_sales/${id}`,
    () => {},
    values,
    undefined,
    'v2',
  )
}
