import React, { FC, useEffect, useContext } from 'react'
import { useDispatch } from 'react-redux'
import FamilyMonochromaticIcon from '@/assets/images/v2/family-monochromatic.svg'
import MarginContainer from '@/components/v2/atoms/MarginContainer'
import Paper from '@/components/v2/atoms/Paper'
import Typography from '@/components/v2/atoms/Typography'
import Breadcrumb from '@/components/v2/molecules/Breadcrumb'
import PaperTitle from '@/components/v2/molecules/PaperTitle'
import { setPageMeta } from '@/containers/pageMetasSlice'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import {
  includes as _includes,
  split as _split,
  set as _set,
  each as _each,
} from 'lodash'
import { AccountContext } from '@/models/accountsModelFunc'
import { useParams } from 'react-router-dom'
import MyDataCommonItemForm from './Form'

const MyDataCommonItemEdit: FC = () => {
  const dispatch = useDispatch()
  const { handleMovePage } = useCustomHistory()
  const { isAdmin } = useContext(AccountContext)
  const { account_id } = useParams<{ account_id?: string }>()

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'マイデータ | カスタム設定',
        description: 'マイデータ | カスタム設定',
        keyword: 'マイデータ | カスタム設定',
      }),
    )
  }, [])

  const movePageAfterForm = () => {
    handleMovePage(
      isAdmin
        ? `/v2/accounts/${account_id}/myData/customItems`
        : '/v2/myData/customItems',
    )
  }

  return (
    <MarginContainer className="px-[8px] lg:px-[32px] py-[8px] lg:py-[40px]">
      <Breadcrumb
        className="pb-[10px] lg:pb-[32px]"
        data={[
          {
            name: 'マイデータ',
            url: isAdmin ? `/v2/accounts/${account_id}/myData` : '/v2/myData',
          },
          {
            name: 'マイデータ設定',
            url: isAdmin
              ? `/v2/accounts/${account_id}/myData/customItems`
              : '/v2/myData/customItems',
          },
          { name: 'カスタム設定' },
        ]}
      />

      <PaperTitle
        className="mb-[16px] lg:mb-[40px]"
        title="カスタム設定"
        description="日々の収入や支出を登録することで改善案などのご提案が可能になります"
        icon={<FamilyMonochromaticIcon />}
        smallIcon={
          <FamilyMonochromaticIcon className="h-[82px] w-[130px]" viewBox="0 0 117 154" />
        }
      />

      <Paper className="bg-white p-[24px] lg:p-[40px]">
        <Typography
          className="mb-[32px] pb-[10px] text-[24px] lg:text-[28px] text-black-800 border-b-2 border-black-300"
          isBold
        >
          カスタム設定について
        </Typography>

        <Typography className="text-black-800">
          カスタム設定では、ご自身で自由に収入、支出の入力をすることができます。
          <br className="hidden lg:block" />
          計上したい科目を設定し、期間と頻度をご入力することで、シミュレーションの収支を調整することができます。
          <br className="hidden lg:block" />
          項目名はご自身でご確認いただくためのものですので、後から見直した時に思い出しやすい名称を設定してください。
        </Typography>

        <MyDataCommonItemForm onClose={movePageAfterForm} onSubmit={movePageAfterForm} />
      </Paper>
    </MarginContainer>
  )
}

export default MyDataCommonItemEdit
