import React, { ChangeEvent, FC, useState } from 'react'
import clsx from 'clsx'
import { isEmpty as _isEmpty } from 'lodash'
import ChevronDownSmallGrayIcon from '@/assets/images/v2/chevron-down-small-gray.svg'
import ChevronRightSmallGrayIcon from '@/assets/images/v2/chevron-right-small-gray.svg'
import Button from '@/components/v2/atoms/Button'
import Chip from '@/components/v2/atoms/Chip'
import Typography from '@/components/v2/atoms/Typography'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import GraphBar from '@/components/v2/molecules/GraphBar'
import GraphPie from '@/components/v2/molecules/GraphPie'
import renderCustomBar from '@/components/v2/molecules/renderCustomBar'
import SelectBoxWithoutFinalForm from '@/components/v2/molecules/SelectBoxWithoutFinalForm'
import {
  convertLocaleString,
  termLabel,
  comparingTermLabel,
  isNotEmptyValue,
} from '@/models/commonsModelFunc'
import { convertCategoryName, INCOME_KEYS } from '@/models/journalsModelFunc'
import { useDispatch } from 'react-redux'
import { updateJournal } from '@/containers/journalsSlice'
import { incomeColorArr } from '@/utils/graphColors'
import HelpCircleIcon from '@/assets/images/v2/help-circle.svg'
import Tooltip from '@/components/v2/atoms/Tooltip'

const classes = {
  table: {
    CellPaddingLeft: 'pl-5',
    ProgressCellPaddingLeft: 'pl-15 md:pl-0',
  },
}

interface MyDataGraphIncomeBodyProps {
  /** クラス名 */
  className?: string
  /** 仕訳変更時の処理 */
  onJournalChange?: () => void
  /** グラフデータ */
  pieGraphData: { [key: string]: any }[]
  /** 円グラフ凡例データ */
  pieGraphLegendData: { [key: string]: any }[]
  /** テーブルデータ */
  tableData: any[]
  /** true: 仕訳, false: マイデータトップ */
  isJournal?: boolean
  /** 今月の金額 */
  balanceCurrentMonth: string
  /** 前月比 */
  balanceLastMonth: string
  /** 選択期間 */
  term?: string

  transferTableData?: any[]
}

const MyDataGraphIncomeBody: FC<MyDataGraphIncomeBodyProps> = (props) => {
  const {
    className,
    onJournalChange,
    pieGraphData,
    pieGraphLegendData,
    tableData,
    isJournal,
    balanceCurrentMonth,
    balanceLastMonth,
    term,
    transferTableData,
  } = props

  const dispatch = useDispatch()
  const [accordionOpen, setAccordionOpen] = useState<{ [key: string]: boolean }>({})

  const handleUpdate = (id: number, money_sol_category_key: any) => {
    dispatch(
      updateJournal({ id: id, money_sol_category_key: money_sol_category_key }, () => {
        onJournalChange && onJournalChange()
      }),
    )
  }

  const handleOpenJournalDetail = (category_key: string, willOpen: boolean) => {
    const nextAccordionOpen = { ...accordionOpen, [category_key]: willOpen }
    setAccordionOpen(nextAccordionOpen)
  }

  const renderTableRow = (item: any, index: number) => {
    return (
      <React.Fragment key={index}>
        <tr className={clsx('h-45 border-b border-dashed border-black-300')}>
          <td>
            <div className="flex items-center">
              {isJournal && (
                <Button>
                  {accordionOpen[item.category_key] ? (
                    <ChevronDownSmallGrayIcon
                      className="mr-5"
                      onClick={() => handleOpenJournalDetail(item.category_key, false)}
                    />
                  ) : (
                    <ChevronRightSmallGrayIcon
                      className="mr-5"
                      onClick={() => handleOpenJournalDetail(item.category_key, true)}
                    />
                  )}
                </Button>
              )}
              <Typography>{item.name}</Typography>
              {item.category_key == 'cash_transfer' && (
                <Tooltip
                  item="振替は合計金額に含まれません"
                  itemClassName="bg-secondary-50 leading-tight p-[8px] text-[10px] w-[268px] before:border-t-secondary-50"
                >
                  <HelpCircleIcon />
                </Tooltip>
              )}
            </div>
          </td>
          <td className={classes.table.CellPaddingLeft}>
            <Typography>{item.amount || '-'}</Typography>
          </td>
          <td className={classes.table.CellPaddingLeft}>
            <Typography>{item.planned_amount || '- '}万円</Typography>
          </td>
          <td className={classes.table.ProgressCellPaddingLeft}>
            <GraphBar
              height={25}
              isVertical
              xStandardValue={100}
              xDomain={[0, 150]}
              isXHide
              yDataKey="name"
              isYHide
              data={item.graphs}
            >
              {renderCustomBar({
                name: '目標との進捗',
                dataKey: 'value',
                color: item.graphs[0].value > 100 ? '#FCA7AC' : '#7DC7F6',
                radius: 10,
                background: { radius: 10 },
              })}
            </GraphBar>
          </td>
        </tr>

        {accordionOpen[item.category_key] && (
          <tr className="border-b border-dashed border-black-300">
            <td colSpan={4}>
              <div className="bg-black-50 mb-12 p-14">
                {/** MEMO: カテゴリ名が長いとスマホで文字サイズが大きくなるため 以下のdivタグを配置 */}
                <div className="max-w-[350px]">
                  {(_isEmpty(item.journals) || !isNotEmptyValue(item.journals)) && (
                    <Typography className="text-12">
                      月ごとの収支を手入力されている場合は項目は表示されません
                    </Typography>
                  )}
                  {item.journals?.map((journal: any) => (
                    <div
                      key={journal.id}
                      className="md:flex items-center pb-[8px] last:pb-0"
                    >
                      <Typography className="inline md:block order-1 pr-[14px] md:pr-8 text-12">
                        {journal.date}
                      </Typography>
                      <Typography className="inline md:block order-3 min-w-[fit-content] md:pr-8 text-12">
                        {convertLocaleString(journal.amount)}円
                      </Typography>
                      <div className="order-2 w-full py-[10px] md:py-0 md:pr-[8px] leading-[22px]">
                        <Typography className="inline text-12">
                          {journal.description_pretty}
                        </Typography>
                        {journal.mt_link_connected_account_name && (
                          <Tooltip
                            item={journal.mt_link_connected_account_name}
                            className="inline"
                            itemClassName={clsx(
                              'bg-secondary-50 leading-tight p-[8px] text-[10px] before:border-t-secondary-50',
                              {
                                'w-[150px] !translate-x-[-20%] whitespace-normal before:!left-[20%]':
                                  journal.mt_link_connected_account_name.length < 14,
                              },
                            )}
                          >
                            <HelpCircleIcon className="inline" />
                          </Tooltip>
                        )}
                      </div>
                      <SelectBoxWithoutFinalForm
                        name={`income_${journal.id}_category_key`}
                        className="order-4"
                        widthClassName="w-[200px]"
                        heightClassName="h-31"
                        placeholder="選択する"
                        value={journal.category_key}
                        options={INCOME_KEYS.map((key) => ({
                          label: convertCategoryName(key),
                          value: key,
                        }))}
                        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                          handleUpdate(journal.id, e.target.value)
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </td>
          </tr>
        )}
      </React.Fragment>
    )
  }

  return (
    <div className={className}>
      <CircleTitle
        className="pb-16"
        classNameTitle="text-20 md:text-24"
        title={`${termLabel(term)}収入`}
      />

      <div className="md:flex gap-x-20">
        <div className="md:flex-1 mb-20 md:mb-auto">
          <div className="mb-10 relative">
            <GraphPie
              height={210}
              dataKey="value"
              nameKey="name"
              innerRadius="90%"
              outerRadius="100%"
              data={pieGraphData}
              colors={incomeColorArr}
            />

            <div
              className="absolute left-1/2 top-1/2 text-center"
              style={{ transform: 'translate(-50%, -50%)' }}
            >
              <Chip className="bg-tertiary-500 mx-auto pb-2 text-white text-16" isBold>
                {`${termLabel(term)}収入`}
              </Chip>
              <div className="flex items-baseline justify-center">
                <Typography className="text-40" isBold>
                  {balanceCurrentMonth}
                </Typography>
                <Typography className="text-16" isBold>
                  万円
                </Typography>
              </div>
              <Typography className="text-tertiary-500">
                {comparingTermLabel(term)} {balanceLastMonth}
                万円
              </Typography>
            </div>
          </div>

          <div className="hidden md:block">
            {pieGraphLegendData.map((item, index) => (
              <div key={index} className="pb-8 last:pb-0 px-30">
                <div className="flex items-center pb-[10px]">
                  <Typography className="pr-12 text-10" style={{ color: item.color }}>
                    ●
                  </Typography>
                  <Typography className="text-14 text-black-500">{item.name}</Typography>
                </div>
                <div className="flex items-center">
                  <Typography className="pr-5 text-16" isBold>
                    {item.value}万円
                  </Typography>
                  <Typography className="text-14 text-black-500" isBold>
                    {`(${item.percent}%)`}
                  </Typography>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="md:flex-3">
          <table className="border-collapse text-14 w-full block md:table overflow-x-scroll md:overflow-hidden whitespace-nowrap">
            <tbody>
              <tr className="border-b border-dashed border-black-300">
                <td className="pb-12 w-150">
                  <Typography isBold>カテゴリ</Typography>
                </td>
                <td className={clsx('pb-12 w-150', classes.table.CellPaddingLeft)}>
                  <Typography isBold>
                    金額
                    {term &&
                      !['this_year', 'past_one_year', 'custom_date'].includes(term) &&
                      `（${comparingTermLabel(term)}）`}
                  </Typography>
                </td>
                <td className={clsx('pb-12 w-150', classes.table.CellPaddingLeft)}>
                  <Typography isBold>プラン設定値</Typography>
                </td>
                <td className={clsx('pb-12', classes.table.ProgressCellPaddingLeft)}>
                  <Typography isBold>目標との進捗</Typography>
                </td>
              </tr>

              {tableData.map((item, index) => renderTableRow(item, index))}

              {transferTableData?.map((item, index) => renderTableRow(item, index))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default MyDataGraphIncomeBody
