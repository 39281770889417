import React, { FC } from 'react'
import {
  composeValidators,
  required,
  zeroOrMoreNumber,
  oneHundredLessThanNumber,
} from '@/utils/validate'
import NumberFieldDecimal from '@/components/v2/molecules/NumberFieldDecimal'
import Typography from '@/components/v2/atoms/Typography'

const RatePercent: FC = () => {
  return (
    <>
      <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
        <Typography
          className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
          isBold
        >
          収入上昇率
        </Typography>
        <div className="flex flex-auto items-baseline">
          <NumberFieldDecimal
            name="future_income.current_salary_increase_rate_percent"
            validate={composeValidators(
              required,
              zeroOrMoreNumber,
              oneHundredLessThanNumber,
            )}
            isFullWidth
          />
          <Typography
            className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800"
            isBold
          >
            ％
          </Typography>
        </div>
      </div>
    </>
  )
}

export default RatePercent
