import ApiClient from '../apiClient'

export const postFutureEstateInvestmentV2 = async (values: any) => {
  return await ApiClient.post(
    '/future_estate_investments',
    () => {},
    values,
    undefined,
    'v2',
  )
}

export const patchFutureEstateInvestmentV2 = async (values: any, id: number) => {
  return await ApiClient.patch(
    `/future_estate_investments/${id}`,
    () => {},
    values,
    undefined,
    'v2',
  )
}

export const deleteFutureEstateInvestmentV2 = async (values: any, id: number) => {
  return await ApiClient.delete(
    `/future_estate_investments/${id}`,
    () => {},
    values,
    undefined,
    'v2',
  )
}
