import React, { FC, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import { Decorator } from 'final-form'
import createDecorator from 'final-form-calculate'
import clsx from 'clsx'
import ChevronRightIcon from '@/assets/images/v2/chevron-right.svg'
import CrossMenu from '@/assets/images/v2/cross-menu.svg'
import HelpCircleIcon from '@/assets/images/v2/help-circle.svg'
import PlusIcon from '@/assets/images/v2/plus.svg'
import Button from '@/components/v2/atoms/Button'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import Grid from '@/components/v2/atoms/Grid'
import LoadingOverlay from '@/components/v1/atoms/LoadingOverlay'
import Modal from '@/components/v2/atoms/Modal'
import Tooltip from '@/components/v2/atoms/Tooltip'
import Typography from '@/components/v2/atoms/Typography'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import NumberField from '@/components/v2/molecules/NumberField'
import SelectBox, { SelectBoxOption } from '@/components/v2/molecules/SelectBox'
import WholeSubmitErrorMessage from '@/components/v2/molecules/WholeSubmitErrorMessage'
import SubmitErrorMessage from '@/components/v2/molecules/SubmitErrorMessage'
import { MONTH_SELECT_ITEMS } from '@/constants/formItem/commons'
import {
  INPUT_PROFESSION_SELECT_ITEMS,
  INPUT_EMPLOYEE_SIZE_BUTTON_ITEMS,
  INPUT_INDUSTRY_BUTTON_ITEMS,
  INPUT_INSURANCE_PENSION_TYPE_BUTTON_ITEMS,
  INPUT_PERSONAL_BUSINESS_TAX_RATE_SELECT_ITEMS,
  INPUT_PERSONAL_BUSINESS_AOIRO_DEDUCTION_SELECT_ITEMS,
} from '@/constants/formItem/inputs/inputSalaries'
import { RootState } from '@/store'

import {
  includes as _includes,
  isNull as _isNull,
  isUndefined as _isUndefined,
  sumBy as _sumBy,
  find as _find,
  isEmpty as _isEmpty,
  reduce as _reduce,
  filter as _filter,
  every as _every,
} from 'lodash'
import { SelectItem } from '@/components/v1/molecules/SelectBox'
import {
  fetchAccountInputHousingOptionsV2,
  fetchAccountInputInsuranceOptionsV2,
} from '@/containers/accountsSlice'
import { fetchFamilyStructureV2 } from '@/containers/familyStructuresSlice'
import { fetchInputHousings } from '@/containers/inputs/inputHousingsSlice'
import {
  createCalcSocialInsuranceFee,
  createInputSalary,
  fetchInputSalaries,
  fetchYearlyIncomeTotal,
  updateInputSalary,
} from '@/containers/inputs/inputSalariesSlice'
import { fetchInputInsurances } from '@/containers/inputs/inputInsurancesSlice'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import {
  InputBonus,
  InputBonusCreateValues,
  InputBonusUpdateValues,
} from '@/models/inputs/inputBonusesModel'
import { convertInsuranceOptionValue } from '@/models/commonInsurancesModelFunc'
import {
  INPUT_FREELANCE_NET_INCOME_MONTHLY_FIELDS,
  INPUT_NET_INCOME_MONTHLY_FIELDS,
  INPUT_PROFESSION,
  isFreelancer,
  isNoBonusProfession,
  isNoSalaryProfession,
} from '@/models/inputs/inputSalariesModelFunc'
import { PERSON_FLG } from '@/models/familyStructuresModelFunc'
import {
  addZeroToStringMonth,
  API_BOOLEAN_FLAG,
  API_FLAG,
  castNumberWithoutOperator,
  convertLocaleString,
  convertMathFloor,
  extractYearOrMonthFromDate,
  THIS_YEAR_NUMBER,
} from '@/models/commonsModelFunc'
import {
  InputSalaryCreateValues,
  InputSalaryUpdateValues,
} from '@/models/inputs/inputSalariesModel'
import InsuranceForm from '@/templates/v2/myData/insurances/_Form'
import MyDataHousingModalForm from '@/templates/v2/myData/salaries/_myDataHousingModalForm'
import { buildTwoTypesRangeYearSelectItems } from '@/utils/formItem'
import {
  commaNumberMaxLength,
  composeValidators,
  required,
  zeroOrMoreNumber,
} from '@/utils/validate'
import { updateResetFlg } from '@/containers/futures/futureBasesSlice'

interface MyDataPersonEditFormProps {
  personFlg: number
  onClose: () => void
  onSubmit: () => void
}

const MyDataPersonEditForm: FC<MyDataPersonEditFormProps> = (props) => {
  const dispatch = useDispatch()
  const { showFlashMsg } = useFlashAlert()

  const { personFlg } = props

  const [isInsuranceModalOpen, setIsInsuranceModalOpen] = useState<boolean>(false)
  const [isOpenHousingModalForm, setIsOpenHousingModalForm] = useState<boolean>(false)

  useEffect(() => {
    if (inputSalaries.length == 0) dispatch(fetchInputSalaries())
    if (inputInsurances.length == 0) dispatch(fetchInputInsurances())
    if (inputHousings.length == 0) dispatch(fetchInputHousings())
    dispatch(fetchAccountInputInsuranceOptionsV2())
    dispatch(fetchAccountInputHousingOptionsV2())
    if (!familyInfoV2) dispatch(fetchFamilyStructureV2())
  }, [])

  const { familyInfoV2 } = useSelector((state: RootState) => state.familyStructures)

  const { inputHousings, inputHousingOptions } = useSelector(
    (state: RootState) => state.inputHousings,
  )
  const isHousingRegistered = inputHousings.length === 2

  const { inputInsuranceOptions, inputInsurances } = useSelector(
    (state: RootState) => state.inputInsurances,
  )

  const { inputSalaries } = useSelector((state: RootState) => state.inputSalaries)

  // isLoadingFlag
  const { isGetAccountShowInputLoading } = useSelector(
    (state: RootState) => state.accounts,
  )
  const isLoadingFamilyStructures = useSelector(
    (state: RootState) => state.familyStructures.isLoading,
  )
  const isLoadingInputSalaries = useSelector(
    (state: RootState) => state.inputSalaries.isLoading,
  )
  const isLoadingInputHousings = useSelector(
    (state: RootState) => state.inputHousings.isLoading,
  )
  const isLoadingInputInsurances = useSelector(
    (state: RootState) => state.inputInsurances.isLoading,
  )

  const familyAgesObject = useMemo(
    () => ({
      person_current_age: familyInfoV2?.person_age,
      spouse_current_age: familyInfoV2?.spouse_age,
    }),
    [familyInfoV2],
  )
  const targetInputSalary = useMemo(
    () => _find(inputSalaries, (item) => item.person_flg === personFlg),
    [personFlg, inputSalaries],
  )
  const isEdit = useMemo(() => (personFlg == 0 || personFlg == 1) && targetInputSalary, [
    targetInputSalary,
    personFlg,
  ])

  const initialValues: any = useMemo(() => {
    if (!isEdit) return { deducted_insurances_attributes: null }

    let tmpInitialValues = targetInputSalary
    return {
      ...tmpInitialValues,
      input_bonuses_attributes:
        !_isUndefined(tmpInitialValues!.input_bonuses) &&
        !_isNull(tmpInitialValues!.input_bonuses)
          ? [
              ...tmpInitialValues!.input_bonuses.map((item: any) => {
                return {
                  ...item,
                  bonus_month: addZeroToStringMonth(item.bonus_month),
                }
              }),
            ]
          : [{}],
      input_salary_freelancer_attributes:
        !_isUndefined(tmpInitialValues!.input_salary_freelancer) &&
        !_isNull(tmpInitialValues!.input_salary_freelancer)
          ? { ...tmpInitialValues!.input_salary_freelancer }
          : {},

      working_start_at_year: tmpInitialValues!.working_start_at
        ? Number(extractYearOrMonthFromDate(tmpInitialValues!.working_start_at, 'year'))
        : '',
      deducted_insurances_attributes:
        !_isEmpty(tmpInitialValues!.deducted_insurances) &&
        !_isUndefined(tmpInitialValues!.deducted_insurances)
          ? [
              ...tmpInitialValues!.deducted_insurances.map((item: any) => ({
                id: String(item.id),
              })),
            ]
          : [],
      deducted_housing_ids: !_isNull(tmpInitialValues!.deducted_housing)
        ? tmpInitialValues!.deducted_housing.id
        : null,
    }
  }, [isEdit, targetInputSalary])

  const inputInsuranceSelectItems = (
    selectedValues: { id: number }[],
  ): SelectBoxOption[] => {
    const options = [{ value: '', label: '選択なし', isDisabled: true }]
    const selectedIdArr = selectedValues.map((item) => String(item?.id))
    let unSelectedArr: SelectBoxOption[] = []

    inputInsuranceOptions.forEach((option) => {
      if (selectedIdArr.includes(String(option.id))) {
        return unSelectedArr.push({
          value: option.id,
          label: convertInsuranceOptionValue(
            option.name,
            option.insurance_type1,
            option.insurance_type2,
            option.insurance_fee,
          ),
          isDisabled: true,
        })
      }

      return unSelectedArr.push({
        value: option.id,
        label: convertInsuranceOptionValue(
          option.name,
          option.insurance_type1,
          option.insurance_type2,
          option.insurance_fee,
        ),
      })
    })

    return [...options, ...unSelectedArr]
  }

  const inputRentHousingSelectItems: SelectItem[] = useMemo(() => {
    const options = [{ value: '', label: '選択なし' }]
    const housingOptions = inputHousingOptions.map((option) => {
      const isNotSelf = !_isNull(option.input_housing_rent)
      const label = isNotSelf
        ? `社宅・寮 ${convertLocaleString(option.housing_deduct_monthly)}円`
        : `単身赴任先 ${convertLocaleString(
            option.input_housing_transfer?.transfer_rent,
          )}円`

      return { value: option.input_housing_id, label }
    })

    return [...options, ...housingOptions]
  }, [inputHousingOptions])

  const notEmployedProfessionGroup = [
    INPUT_PROFESSION.housewife,
    INPUT_PROFESSION.other,
    INPUT_PROFESSION.student,
  ]
  const targetFamilyAge = useMemo(
    () =>
      personFlg === PERSON_FLG.person
        ? familyAgesObject.person_current_age || -50
        : familyAgesObject.spouse_current_age || -50,
    [familyAgesObject, personFlg],
  )

  const handleFindInsuranceFee = (deductedInsurance: { id: number }[] | null) => {
    if (_isUndefined(deductedInsurance)) return 0
    if (_isEmpty(deductedInsurance)) return 0

    const insuranceTotalFee = _reduce(
      deductedInsurance,
      (sum, item) => {
        return (
          sum +
          castNumberWithoutOperator(
            _find(inputInsurances, { id: Number(item?.id) })?.insurance_fee,
          )
        )
      },
      0,
    )

    return castNumberWithoutOperator(insuranceTotalFee)
  }

  const handleFindHousingRent = (treatmentedHousingIds: number[] | null) => {
    if (_isUndefined(treatmentedHousingIds)) return 0
    if (_isEmpty(treatmentedHousingIds)) return 0

    const housingTotalRent = _sumBy(
      inputHousingOptions,
      'input_housing_rent.rent_monthly',
    )

    return castNumberWithoutOperator(housingTotalRent)
  }

  const handleChangeSocialInsuranceFee = (
    values: InputSalaryCreateValues,
    change: (key: string, value: number) => void,
  ) => {
    const {
      gross_income_monthly,
      commutation_allowance,
      resident_tax_monthly,
      mutual_aid_pension_monthly,
      company_saving_amount_monthly,
      other_deduction_monthly,
    } = values

    const casted_values = { ...values, person_flg: personFlg }
    casted_values.gross_income_monthly = castNumberWithoutOperator(
      values.gross_income_monthly,
    )
    casted_values.commutation_allowance = castNumberWithoutOperator(
      values.commutation_allowance,
    )
    casted_values.resident_tax_monthly = castNumberWithoutOperator(
      values.resident_tax_monthly,
    )
    casted_values.mutual_aid_pension_monthly = castNumberWithoutOperator(
      values.mutual_aid_pension_monthly,
    )
    casted_values.company_saving_amount_monthly = castNumberWithoutOperator(
      values.company_saving_amount_monthly,
    )
    casted_values.other_deduction_monthly = castNumberWithoutOperator(
      values.other_deduction_monthly,
    )

    if (_isNull(gross_income_monthly)) return
    if (_isNull(commutation_allowance)) return
    if (_isNull(resident_tax_monthly)) return
    if (_isNull(mutual_aid_pension_monthly)) return
    if (_isNull(company_saving_amount_monthly)) return
    if (_isNull(other_deduction_monthly)) return
    if (
      castNumberWithoutOperator(gross_income_monthly) +
        castNumberWithoutOperator(commutation_allowance) -
        (castNumberWithoutOperator(resident_tax_monthly) +
          castNumberWithoutOperator(mutual_aid_pension_monthly) +
          castNumberWithoutOperator(company_saving_amount_monthly) +
          castNumberWithoutOperator(other_deduction_monthly)) <
      0
    )
      return

    dispatch(
      createCalcSocialInsuranceFee(casted_values, (calcSocialInsuranceFee) => {
        change(
          'health_insurance_monthly',
          convertMathFloor(calcSocialInsuranceFee.health_insurance_monthly!),
        )
        change('welfare_pension_monthly', calcSocialInsuranceFee.welfare_pension_monthly!)
        change(
          'employment_insurance_monthly',
          convertMathFloor(calcSocialInsuranceFee.employment_insurance_monthly!),
        )
        change(
          'income_tax_monthly',
          convertMathFloor(calcSocialInsuranceFee.income_tax_monthly!),
        )
      }),
    )
  }

  const calculator: Decorator<any> = useMemo(
    () =>
      createDecorator(
        {
          field: INPUT_NET_INCOME_MONTHLY_FIELDS,
          updates: {
            net_income_monthly: (_, allValues: any) => {
              const insuranceFee = handleFindInsuranceFee(
                allValues.deducted_insurances_attributes,
              )
              const housingRent = handleFindHousingRent(
                allValues.deducted_housing_ids ? [allValues.deducted_housing_ids] : [],
              )
              const mutualAidPension = castNumberWithoutOperator(
                allValues.mutual_aid_pension_monthly,
              )
              const companySavingAmount = castNumberWithoutOperator(
                allValues.company_saving_amount_monthly,
              )
              const otherDeduction = castNumberWithoutOperator(
                allValues.other_deduction_monthly,
              )
              const additionalCalcValue =
                insuranceFee +
                housingRent +
                mutualAidPension +
                companySavingAmount +
                otherDeduction

              return (
                castNumberWithoutOperator(allValues.gross_income_monthly) +
                castNumberWithoutOperator(allValues.commutation_allowance) -
                castNumberWithoutOperator(allValues.health_insurance_monthly) -
                castNumberWithoutOperator(allValues.welfare_pension_monthly) -
                castNumberWithoutOperator(allValues.employment_insurance_monthly) -
                castNumberWithoutOperator(allValues.income_tax_monthly) -
                castNumberWithoutOperator(allValues.resident_tax_monthly) -
                castNumberWithoutOperator(additionalCalcValue)
              )
            },
          },
        },
        {
          field: INPUT_FREELANCE_NET_INCOME_MONTHLY_FIELDS,
          updates: {
            'input_salary_freelancer_attributes.net_income_monthly': (
              _,
              allValues: any,
            ) => {
              const { input_salary_freelancer_attributes } = allValues
              const grossIncomeMonthly = _isUndefined(
                input_salary_freelancer_attributes.gross_income_monthly,
              )
                ? 0
                : castNumberWithoutOperator(
                    input_salary_freelancer_attributes.gross_income_monthly,
                  )
              const expenseMonthly = _isUndefined(
                input_salary_freelancer_attributes.expense_monthly,
              )
                ? 0
                : castNumberWithoutOperator(
                    input_salary_freelancer_attributes.expense_monthly,
                  )

              return grossIncomeMonthly - expenseMonthly
            },
          },
        },
      ),
    [inputInsurances],
  )

  const handleSubmit = (values: InputSalaryCreateValues & InputSalaryUpdateValues) => {
    const handleNotRequiredValues = (
      newValues: InputSalaryCreateValues | InputSalaryUpdateValues,
    ) => {
      if (isNoBonusProfession(newValues.profession)) {
        newValues.industry = null
        newValues.employee_size = null
      }
      if (isFreelancer(newValues.profession)) {
        newValues.net_income_monthly = null
        newValues.gross_income_monthly = null
        newValues.commutation_allowance = null
        newValues.health_insurance_monthly = null
        newValues.welfare_pension_monthly = null
        newValues.employment_insurance_monthly = null
        newValues.mutual_aid_pension_monthly = null
        newValues.company_saving_amount_monthly = null
        newValues.has_contribution_pension_payment = false
        newValues.other_deduction_monthly = null
        newValues.working_start_at = null
        newValues.input_sub_income = null
        newValues.input_bonuses_attributes = null
      }
      if (_includes(notEmployedProfessionGroup, newValues.profession)) {
        newValues.insurance_pension_type = null
        newValues.net_income_monthly = null
        newValues.gross_income_monthly = null
        newValues.commutation_allowance = null
        newValues.health_insurance_monthly = null
        newValues.welfare_pension_monthly = null
        newValues.employment_insurance_monthly = null
        newValues.income_tax_monthly = null
        newValues.resident_tax_monthly = null
        newValues.mutual_aid_pension_monthly = null
        newValues.company_saving_amount_monthly = null
        newValues.has_contribution_pension_payment = false
        newValues.other_deduction_monthly = null
        newValues.working_start_at = null
        newValues.input_sub_income = null
        newValues.input_bonuses_attributes = null
      }
      if (Number(newValues.insurance_deduction_type) === API_FLAG.off) {
        newValues.deducted_insurance_ids = []
      }
      if (Number(newValues.housing_deduction_type) === API_FLAG.off) {
        newValues.deducted_housing_ids = null
      }
      if (Number(newValues.mutual_aid_pension_type) === API_FLAG.off) {
        newValues.mutual_aid_pension_monthly = null
      }
      if (Number(newValues.company_saving_amount_type) === API_FLAG.off) {
        newValues.company_saving_amount_monthly = null
      }
      if (Number(newValues.other_deduction_type) === API_FLAG.off) {
        newValues.other_deduction_monthly = null
      }
      delete newValues.mutual_aid_pension_type
      delete newValues.company_saving_amount_type
      delete newValues.employee_benefit_fund_type
      delete newValues.other_deduction_type
      delete newValues.insurance_deduction_type
      delete newValues.working_start_at_year

      return newValues
    }

    const handleCreateValues = (values: InputSalaryCreateValues) => {
      values.input_bonuses = []
      values.input_sub_income = null
      values.input_salary_freelancer_attributes = !isFreelancer(values.profession)
        ? null
        : values.input_salary_freelancer_attributes

      const newValues = { ...values }
      newValues.person_flg = personFlg

      if (!isFreelancer(newValues.profession)) {
        newValues.has_contribution_pension_payment =
          values.has_contribution_pension_payment === API_FLAG.on
            ? API_BOOLEAN_FLAG.on
            : API_BOOLEAN_FLAG.off
        newValues.deducted_insurances_attributes = !!values.deducted_insurances_attributes
          ? [...values.deducted_insurances_attributes]
          : []
        newValues.deducted_housing_attributes = !!values.deducted_housing_ids
          ? { id: values.deducted_housing_ids! }
          : null
        newValues.working_start_at = `${values.working_start_at_year}/01/01`

        if (
          !_isUndefined(newValues.input_bonuses_attributes) &&
          !_isNull(newValues.input_bonuses_attributes)
        ) {
          let tmpInputBonusArr = [...newValues.input_bonuses_attributes]
          newValues.input_bonuses_attributes = tmpInputBonusArr.reduce(
            (final_value: InputBonusCreateValues[], obj) => {
              if (Object.keys(obj).length > 0) {
                final_value.push(obj)
              }
              return final_value
            },
            [],
          )
        }
        if (
          !_isUndefined(newValues.input_bonuses_attributes) &&
          !_isNull(newValues.input_bonuses_attributes)
        ) {
          newValues.input_bonuses_attributes = (newValues.input_bonuses_attributes as InputBonus[]).map(
            (item: InputBonus) => ({
              input_salary_id: isEdit ? initialValues?.id : null,
              bonus_month: item.bonus_month,
              bonus: item.bonus,
            }),
          )
        }
      }

      return handleNotRequiredValues(newValues) as InputSalaryCreateValues
    }

    const handleUpdateValues = (values: any) => {
      values.input_bonuses = []
      values.input_sub_income = null
      values.input_salary_freelancer_attributes = !isFreelancer(values.profession)
        ? null
        : values.input_salary_freelancer_attributes

      const newValues = { ...values, id: values.id }
      newValues.person_flg = personFlg

      if (!isFreelancer(newValues.profession)) {
        newValues.has_contribution_pension_payment =
          values.has_contribution_pension_payment === API_FLAG.on
            ? API_BOOLEAN_FLAG.on
            : API_BOOLEAN_FLAG.off
        newValues.deducted_insurances_attributes = [
          ...values.deducted_insurances_attributes,
        ]
        newValues.deducted_housing_attributes = !!values.deducted_housing_ids
          ? { id: Number(values.deducted_housing_ids) }
          : null
        newValues.working_start_at = `${values.working_start_at_year}/01/01`

        if (
          !_isUndefined(newValues.input_bonuses_attributes) &&
          !_isNull(newValues.input_bonuses_attributes)
        ) {
          let tmpInputBonusArr = [...newValues.input_bonuses_attributes]
          newValues.input_bonuses_attributes = !_isUndefined(tmpInputBonusArr)
            ? tmpInputBonusArr.reduce((final_value: any, obj: InputBonusUpdateValues) => {
                if (Object.keys(obj).length > 0) {
                  final_value.push(obj)
                }
                return final_value
              }, [])
            : null
        }
        if (
          !_isUndefined(newValues.input_bonuses_attributes) &&
          !_isNull(newValues.input_bonuses_attributes)
        ) {
          newValues.input_bonuses_attributes = _filter(
            newValues.input_bonuses_attributes as InputBonusUpdateValues[],
            (v: any) => v.bonus > 0,
          ).map((item: InputBonus) => ({
            id: item.id,
            input_salary_id: initialValues!.id,
            bonus_month: item.bonus_month,
            bonus: item.bonus,
          }))
        }
      }

      return handleNotRequiredValues(newValues) as InputSalaryUpdateValues
    }

    const commonCallBack = () => {
      showFlashMsg(isEdit ? '更新しました' : '作成しました')
      dispatch(fetchInputSalaries())
      dispatch(fetchYearlyIncomeTotal())
      dispatch(updateResetFlg())
      props.onSubmit()
    }

    return new Promise((resolve) => {
      isEdit
        ? dispatch(
            updateInputSalary(
              handleUpdateValues(values as InputSalaryUpdateValues),
              () => {
                commonCallBack()
              },
            ),
          )
        : dispatch(
            createInputSalary(
              handleCreateValues(values as InputSalaryCreateValues),
              () => {
                commonCallBack()
              },
              resolve,
            ),
          )
    })
  }

  return (
    <>
      <LoadingOverlay
        isLoading={
          isLoadingInputInsurances ||
          isLoadingInputHousings ||
          isLoadingInputSalaries ||
          isLoadingFamilyStructures ||
          isGetAccountShowInputLoading
        }
      />
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        mutators={{ ...arrayMutators }}
        decorators={[calculator]}
        render={({
          handleSubmit,
          values,
          errors,
          submitErrors,
          submitFailed,
          form: {
            change,
            mutators: { push },
          },
        }) => {
          const {
            profession,
            net_income_monthly,
            deducted_insurances_attributes,
          } = values

          return (
            <form onSubmit={handleSubmit}>
              <Grid className="gap-x-40 gap-y-16 grid-cols-1 md:grid-cols-2 pb-24 md:pb-40">
                <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                  <Typography
                    className="min-w-[140px] lg:min-w-[280px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                    isBold
                  >
                    ご職業
                  </Typography>
                  <SelectBox
                    placeholder="選択してください"
                    name="profession"
                    options={INPUT_PROFESSION_SELECT_ITEMS}
                    validate={required}
                    isFullWidth
                    onChange={(event: any) => {
                      const value = event.target.value
                      change('profession', Number(value))
                    }}
                  />
                </div>

                {!isNoSalaryProfession(profession) &&
                  profession != INPUT_PROFESSION.freelance && (
                    <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                      <Typography
                        className="min-w-[140px] lg:min-w-[280px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                        isBold
                      >
                        業種
                      </Typography>
                      <SelectBox
                        isFullWidth
                        placeholder="選択してください"
                        name="industry"
                        options={INPUT_INDUSTRY_BUTTON_ITEMS}
                        validate={required}
                        onChange={(event: any) => {
                          const value = event.target.value
                          change('industry', value)
                        }}
                      />
                    </div>
                  )}
                {!isNoSalaryProfession(profession) &&
                  profession != INPUT_PROFESSION.freelance && (
                    <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                      <Typography
                        className="min-w-[140px] lg:min-w-[280px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                        isBold
                      >
                        従業員の規模
                      </Typography>
                      <SelectBox
                        isFullWidth
                        placeholder="選択してください"
                        name="employee_size"
                        options={INPUT_EMPLOYEE_SIZE_BUTTON_ITEMS}
                        validate={required}
                        onChange={(event: any) => {
                          const value = event.target.value
                          change('employee_size', value)
                        }}
                      />
                    </div>
                  )}

                {!isNoSalaryProfession(profession) && (
                  <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                    <Typography
                      className="min-w-[140px] lg:min-w-[280px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                      isBold
                    >
                      加入保険/年金
                    </Typography>
                    <SelectBox
                      isFullWidth
                      placeholder="選択してください"
                      name="insurance_pension_type"
                      options={INPUT_INSURANCE_PENSION_TYPE_BUTTON_ITEMS}
                      validate={required}
                      onChange={(event: any) => {
                        const value = event.target.value
                        change('insurance_pension_type', value)
                      }}
                    />
                  </div>
                )}

                {profession == INPUT_PROFESSION.freelance && (
                  <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                    <Typography
                      className="min-w-[140px] lg:min-w-[280px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                      isBold
                    >
                      月商
                    </Typography>
                    <div className="flex flex-auto items-baseline">
                      <NumberField
                        name="input_salary_freelancer_attributes.gross_income_monthly"
                        validate={composeValidators(
                          required,
                          zeroOrMoreNumber,
                          (value: any) => commaNumberMaxLength(value, 10),
                        )}
                        isFullWidth
                      />
                      <Typography
                        className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                        isBold
                      >
                        円
                      </Typography>
                    </div>
                  </div>
                )}
                {profession == INPUT_PROFESSION.freelance && (
                  <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                    <Typography
                      className="min-w-[140px] lg:min-w-[280px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                      isBold
                    >
                      経費
                    </Typography>
                    <div className="flex flex-auto items-baseline">
                      <NumberField
                        name="input_salary_freelancer_attributes.expense_monthly"
                        validate={composeValidators(
                          required,
                          zeroOrMoreNumber,
                          (value: any) => commaNumberMaxLength(value, 10),
                        )}
                        isFullWidth
                      />
                      <Typography
                        className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                        isBold
                      >
                        円
                      </Typography>
                    </div>
                  </div>
                )}
                {profession == INPUT_PROFESSION.freelance && (
                  <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                    <Typography
                      className="min-w-[140px] lg:min-w-[280px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                      isBold
                    >
                      所得
                    </Typography>
                    <div className="flex flex-auto items-baseline">
                      <NumberField
                        name="input_salary_freelancer_attributes.net_income_monthly"
                        validate={composeValidators(
                          required,
                          zeroOrMoreNumber,
                          (value: any) => commaNumberMaxLength(value, 10),
                        )}
                        disabled={true}
                        isFullWidth
                      />
                      <Typography
                        className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                        isBold
                      >
                        円
                      </Typography>
                    </div>
                  </div>
                )}
                {profession == INPUT_PROFESSION.freelance && (
                  <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                    <Typography
                      className="min-w-[140px] lg:min-w-[280px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                      isBold
                    >
                      個人事業税の区別
                    </Typography>
                    <div className="flex flex-auto items-baseline">
                      <SelectBox
                        placeholder="選択してください"
                        name="input_salary_freelancer_attributes.personal_business_tax_rate"
                        validate={required}
                        options={INPUT_PERSONAL_BUSINESS_TAX_RATE_SELECT_ITEMS}
                        isFullWidth
                      />
                    </div>
                  </div>
                )}
                {profession == INPUT_PROFESSION.freelance && (
                  <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                    <Typography
                      className="min-w-[140px] lg:min-w-[280px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                      isBold
                    >
                      青色申告特別控除
                    </Typography>
                    <div className="flex flex-auto items-baseline">
                      <SelectBox
                        placeholder="選択してください"
                        name="input_salary_freelancer_attributes.personal_business_aoiro_deduction"
                        validate={required}
                        options={INPUT_PERSONAL_BUSINESS_AOIRO_DEDUCTION_SELECT_ITEMS}
                        isFullWidth
                      />
                    </div>
                  </div>
                )}
                {profession == INPUT_PROFESSION.freelance && (
                  <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                    <Typography
                      className="min-w-[140px] lg:min-w-[280px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                      isBold
                    >
                      住民税
                    </Typography>
                    <div className="flex flex-auto items-baseline">
                      <NumberField
                        name="resident_tax_monthly"
                        validate={composeValidators(
                          required,
                          zeroOrMoreNumber,
                          (value: any) => commaNumberMaxLength(value, 10),
                        )}
                        isFullWidth
                      />
                      <Typography
                        className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                        isBold
                      >
                        円
                      </Typography>
                    </div>
                  </div>
                )}
                {profession == INPUT_PROFESSION.freelance && (
                  <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                    <Typography
                      className="min-w-[140px] lg:min-w-[280px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                      isBold
                    >
                      所得税
                    </Typography>
                    <div className="flex flex-auto items-baseline">
                      <NumberField
                        name="income_tax_monthly"
                        validate={composeValidators(
                          required,
                          zeroOrMoreNumber,
                          (value: any) => commaNumberMaxLength(value, 10),
                        )}
                        isFullWidth
                      />
                      <Typography
                        className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                        isBold
                      >
                        円
                      </Typography>
                    </div>
                  </div>
                )}
              </Grid>

              {!isNoSalaryProfession(profession) &&
                profession != INPUT_PROFESSION.freelance && (
                  <Grid className="gap-x-40 gap-y-16 grid-cols-1 pb-24 md:pb-40">
                    <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                      <Typography className="pb-16 text-14 text-black-700">
                        ※以下、給与明細を見ながらご入力ください
                      </Typography>
                    </div>
                  </Grid>
                )}
              {!isNoSalaryProfession(profession) &&
                profession != INPUT_PROFESSION.freelance && (
                  <Grid className="gap-x-40 gap-y-16 grid-cols-1 md:grid-cols-2 pb-24 md:pb-40">
                    <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                      <Typography
                        className="min-w-[140px] lg:min-w-[280px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                        isBold
                      >
                        額面月収
                      </Typography>
                      <div className="flex flex-auto items-baseline">
                        <NumberField
                          name="gross_income_monthly"
                          validate={composeValidators(
                            required,
                            zeroOrMoreNumber,
                            (value: any) => commaNumberMaxLength(value, 10),
                          )}
                          isFullWidth
                        />
                        <Typography
                          className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                          isBold
                        >
                          円
                        </Typography>
                      </div>
                    </div>

                    <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                      <Typography
                        className="min-w-[140px] lg:min-w-[280px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                        isBold
                      >
                        勤務開始年
                      </Typography>
                      <SelectBox
                        name="working_start_at_year"
                        placeholder="年を選択"
                        options={buildTwoTypesRangeYearSelectItems(
                          THIS_YEAR_NUMBER - targetFamilyAge + 15,
                          THIS_YEAR_NUMBER,
                        )}
                        validate={required}
                        isFullWidth
                      />
                    </div>

                    <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                      <Typography
                        className="min-w-[140px] lg:min-w-[280px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                        isBold
                      >
                        通勤手当
                      </Typography>
                      <div className="flex flex-auto items-baseline">
                        <NumberField
                          name="commutation_allowance"
                          validate={composeValidators(
                            required,
                            zeroOrMoreNumber,
                            (value: any) => commaNumberMaxLength(value, 10),
                          )}
                          isFullWidth
                        />
                        <Typography
                          className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                          isBold
                        >
                          円
                        </Typography>
                      </div>
                    </div>

                    <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                      <Typography
                        className="min-w-[140px] lg:min-w-[280px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                        isBold
                      >
                        住民税
                      </Typography>
                      <div className="flex flex-auto items-baseline">
                        <NumberField
                          name="resident_tax_monthly"
                          validate={composeValidators(
                            required,
                            zeroOrMoreNumber,
                            (value: any) => commaNumberMaxLength(value, 10),
                          )}
                          isFullWidth
                        />
                        <Typography
                          className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                          isBold
                        >
                          円
                        </Typography>
                      </div>
                    </div>

                    <Grid className="gap-x-40 grid-cols-1 pb-[16px]  border-black-300 border-dashed border-b-2">
                      <FieldArray name="deducted_insurances_attributes">
                        {({ fields }) =>
                          fields.length == 0 ? (
                            <>
                              <div>
                                <div className="lg:flex justify-between pb-17">
                                  <div className="flex items-center pb-10 lg:pb-0">
                                    <Typography
                                      className="pr-16 text-14 text-black-800"
                                      isBold
                                    >
                                      給与から直接差し引かれる保険料
                                      <br />
                                      （生命保険、医療保険など）
                                    </Typography>
                                  </div>
                                  <div className="flex items-center pb-10 lg:pb-0 justify-end">
                                    <Button
                                      className="bg-white border border-black-600 border-solid h-37 rounded-4 hover:bg-black-200 px-[8px]"
                                      onClick={() => {
                                        setIsInsuranceModalOpen(true)
                                      }}
                                    >
                                      <PlusIcon className="mr-8" />
                                      <Typography
                                        className="text-14 text-black-600 w-[max-content]"
                                        isBold
                                      >
                                        新しく保険を登録する
                                      </Typography>
                                    </Button>
                                  </div>
                                </div>
                              </div>
                              <div />
                            </>
                          ) : (
                            fields.map((name, index) => (
                              <React.Fragment key={name}>
                                {index === 0 && (
                                  <div className="lg:flex justify-between pb-17">
                                    <div className="block md:flex items-center pb-10 lg:pb-0">
                                      <Typography
                                        className="min-w-[140px] lg:min-w-[280px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                                        isBold
                                      >
                                        給与から直接差し引かれる保険料
                                        <br />
                                        （生命保険、医療保険など）
                                      </Typography>
                                      <div className="md:flex items-baseline">
                                        <Button
                                          className="bg-white border border-black-600 border-solid h-37 rounded-4 hover:bg-black-200 px-[8px]"
                                          onClick={() => {
                                            setIsInsuranceModalOpen(true)
                                          }}
                                        >
                                          <PlusIcon className="mr-8" />
                                          <Typography
                                            className="text-14 text-black-600 w-[max-content]"
                                            isBold
                                          >
                                            新しく保険を登録する
                                          </Typography>
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                <div className="lg:flex justify-end pb-17">
                                  <div className="md:flex items-center justify-end">
                                    <div className="flex items-center">
                                      <SelectBox
                                        isPlaceholderHidden
                                        name={`${name}id`}
                                        options={inputInsuranceSelectItems(
                                          deducted_insurances_attributes ?? [],
                                        )}
                                        isFullWidth
                                      />
                                      <Button
                                        className="bg-white border border-white border-solid h-37 rounded-[50px] w-[38px] hover:bg-black-200 ml-10 my-[10px]"
                                        onClick={() => fields.remove(index)}
                                      >
                                        <CrossMenu />
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </React.Fragment>
                            ))
                          )
                        }
                      </FieldArray>
                      {!_every(
                        inputInsuranceSelectItems(deducted_insurances_attributes ?? []),
                        { isDisabled: true },
                      ) && (
                        <Button
                          className="w-full h-[53px] border border-dashed border-primary-500 text-[14px] text-primary-500 hover:bg-primary-50"
                          onClick={() => push('deducted_insurances_attributes', {})}
                        >
                          <Typography className="text-14" isBold>
                            保険の入力枠を追加する
                          </Typography>
                        </Button>
                      )}
                    </Grid>

                    <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                      <Typography
                        className="min-w-[140px] lg:min-w-[280px] pb-[10px] md:pb-0 text-[14px] text-black-800 mr-[16px]"
                        isBold
                      >
                        給与から直接差し引かれる共済年金で
                        <br />
                        資産として積み立てられる控除額
                      </Typography>
                      <div className="flex flex-auto items-baseline">
                        <NumberField
                          name="mutual_aid_pension_monthly"
                          validate={composeValidators(
                            required,
                            zeroOrMoreNumber,
                            (value: any) => commaNumberMaxLength(value, 10),
                          )}
                          isFullWidth
                        />
                        <Typography
                          className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                          isBold
                        >
                          円
                        </Typography>
                      </div>
                    </div>

                    <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-center">
                      <Typography
                        className="min-w-[140px] lg:min-w-[280px] pb-[10px] md:pb-0 text-[14px] text-black-800 mr-[16px] flex items-center"
                        isBold
                      >
                        給与から直接差し引かれる社内預金、財形、
                        <br />
                        持株など 資産として積み立てられる控除額
                      </Typography>
                      <div className="flex flex-auto items-baseline">
                        <NumberField
                          name="company_saving_amount_monthly"
                          validate={composeValidators(
                            required,
                            zeroOrMoreNumber,
                            (value: any) => commaNumberMaxLength(value, 10),
                          )}
                          isFullWidth
                        />
                        <Typography
                          className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                          isBold
                        >
                          円
                        </Typography>
                      </div>
                    </div>

                    <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                      <Typography
                        className="min-w-[140px] lg:min-w-[280px] pb-[10px] md:pb-0 text-[14px] text-black-800 mr-[16px]"
                        isBold
                      >
                        給与から直接差し引かれる住宅費
                        <br />
                        (社宅・寮、単身赴任先など)
                      </Typography>
                      <div>
                        {!isHousingRegistered && (
                          <Button
                            className="bg-white border border-black-600 border-solid h-37 rounded-4 hover:bg-black-200 px-[8px] ml-auto mb-[10px]"
                            onClick={() => setIsOpenHousingModalForm(true)}
                          >
                            <PlusIcon className="mr-8" />
                            <Typography
                              className="text-14 text-black-600 w-[max-content]"
                              isBold
                            >
                              新しく住宅を登録する
                            </Typography>
                          </Button>
                        )}
                        <SelectBox
                          isPlaceholderHidden
                          name="deducted_housing_ids"
                          options={inputRentHousingSelectItems}
                          isFullWidth
                        />
                      </div>
                    </div>

                    <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-center">
                      <div className="flex items-center">
                        <Typography
                          className="min-w-[140px] lg:min-w-[280px] pb-[10px] md:pb-0 text-[14px] text-black-800 mr-[16px] flex items-center"
                          isBold
                        >
                          給与から直接引かれる上記以外の控除
                          <div className="mx-[8px]">
                            <Tooltip
                              item="例）共済会費、互助会費、労働組合費など"
                              itemClassName="left-[-26px] w-160 p-8 text-10 text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                            >
                              <HelpCircleIcon />
                            </Tooltip>
                          </div>
                        </Typography>
                      </div>

                      <div className="flex flex-auto items-baseline">
                        <NumberField
                          name="other_deduction_monthly"
                          validate={composeValidators(
                            required,
                            zeroOrMoreNumber,
                            (value: any) => commaNumberMaxLength(value, 10),
                          )}
                          isFullWidth
                        />
                        <Typography
                          className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                          isBold
                        >
                          円
                        </Typography>
                      </div>
                    </div>
                  </Grid>
                )}

              {!isNoSalaryProfession(profession) &&
                profession != INPUT_PROFESSION.freelance && (
                  <Grid className="gap-x-40 gap-y-16 grid-cols-1 pb-24">
                    <div className="md:flex items-center">
                      <Typography
                        className="min-w-[140px] lg:min-w-[280px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                        isBold
                      >
                        社会保険料・所得税の自動計算
                      </Typography>
                      <Button
                        className="bg-white border border-black-600 border-solid h-37 rounded-4 w-168 hover:bg-black-200 block ml-[45%] md:ml-10 my-[10px]"
                        onClick={() => {
                          const newValues = { ...values }
                          handleChangeSocialInsuranceFee(newValues, change)
                        }}
                      >
                        <Typography className="text-14 text-black-600" isBold>
                          自動計算を適用
                        </Typography>
                      </Button>
                    </div>

                    <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                      <Typography className="pb-16 text-14 text-black-700">
                        ※ 自動計算で適用される金額について
                        <br />
                        　４月~６月の給与、会社独自の健康保険組合の保険料率の違いによりずれが生じる可能性がありますので、
                        <br />
                        給与明細と照らし合わせ、金額が異なる場合は修正してください。
                      </Typography>
                    </div>
                  </Grid>
                )}
              {!isNoSalaryProfession(profession) &&
                profession != INPUT_PROFESSION.freelance && (
                  <Grid className="gap-x-40 gap-y-16 grid-cols-1 md:grid-cols-2 pb-24 md:pb-40">
                    {!isNoSalaryProfession(profession) &&
                      profession != INPUT_PROFESSION.freelance && (
                        <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                          <Typography
                            className="min-w-[140px] lg:min-w-[280px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                            isBold
                          >
                            健康保険料
                          </Typography>
                          <div className="flex flex-auto items-baseline">
                            <NumberField
                              name="health_insurance_monthly"
                              validate={composeValidators(
                                required,
                                zeroOrMoreNumber,
                                (value: any) => commaNumberMaxLength(value, 10),
                              )}
                              isFullWidth
                            />
                            <Typography
                              className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                              isBold
                            >
                              円
                            </Typography>
                          </div>
                        </div>
                      )}

                    {!isNoSalaryProfession(profession) &&
                      profession != INPUT_PROFESSION.freelance && (
                        <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                          <Typography
                            className="min-w-[140px] lg:min-w-[280px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                            isBold
                          >
                            厚生年金保険料
                          </Typography>
                          <div className="flex flex-auto items-baseline">
                            <NumberField
                              name="welfare_pension_monthly"
                              validate={composeValidators(
                                required,
                                zeroOrMoreNumber,
                                (value: any) => commaNumberMaxLength(value, 10),
                              )}
                              isFullWidth
                            />
                            <Typography
                              className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                              isBold
                            >
                              円
                            </Typography>
                          </div>
                        </div>
                      )}

                    {!isNoSalaryProfession(profession) &&
                      profession != INPUT_PROFESSION.freelance && (
                        <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                          <Typography
                            className="min-w-[140px] lg:min-w-[280px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                            isBold
                          >
                            雇用保険料
                          </Typography>
                          <div className="flex flex-auto items-baseline">
                            <NumberField
                              name="employment_insurance_monthly"
                              validate={composeValidators(
                                required,
                                zeroOrMoreNumber,
                                (value: any) => commaNumberMaxLength(value, 10),
                              )}
                              isFullWidth
                            />
                            <Typography
                              className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                              isBold
                            >
                              円
                            </Typography>
                          </div>
                        </div>
                      )}

                    {!isNoSalaryProfession(profession) &&
                      profession != INPUT_PROFESSION.freelance && (
                        <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                          <Typography
                            className="min-w-[140px] lg:min-w-[280px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                            isBold
                          >
                            所得税
                          </Typography>
                          <div className="flex flex-auto items-baseline">
                            <NumberField
                              name="income_tax_monthly"
                              validate={composeValidators(
                                required,
                                zeroOrMoreNumber,
                                (value: any) => commaNumberMaxLength(value, 10),
                              )}
                              isFullWidth
                            />
                            <Typography
                              className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                              isBold
                            >
                              円
                            </Typography>
                          </div>
                        </div>
                      )}

                    {!isNoSalaryProfession(profession) &&
                      profession != INPUT_PROFESSION.freelance && (
                        <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                          <Typography
                            className="min-w-[140px] lg:min-w-[280px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                            isBold
                          >
                            手取り月収
                          </Typography>
                          <div className="flex flex-auto items-baseline justify-end">
                            <Typography
                              className="pl-[10px] pb-[10px] md:pb-0 text-[18px] text-black-800"
                              isBold
                            >
                              {convertLocaleString(Number(net_income_monthly))}
                            </Typography>
                            <Typography
                              className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                              isBold
                            >
                              円
                            </Typography>
                          </div>
                        </div>
                      )}
                  </Grid>
                )}

              {!isNoSalaryProfession(profession) &&
                profession != INPUT_PROFESSION.freelance && (
                  <Grid className="gap-x-40 gap-y-4 grid-cols-1 md:grid-cols-2 pb-40 md:pb-64">
                    <FieldArray name="input_bonuses_attributes">
                      {({ fields }) =>
                        fields.length == 0 ? (
                          <>
                            <div>
                              <div className="lg:flex justify-between pb-17">
                                <div className="flex items-center pb-10 lg:pb-0">
                                  <Typography
                                    className="pr-16 text-14 text-black-800"
                                    isBold
                                  >
                                    賞与
                                  </Typography>
                                </div>
                                <div className="flex items-center pb-10 lg:pb-0 justify-end">
                                  <Typography
                                    className="pr-16 text-14 text-black-800"
                                    isBold
                                  >
                                    賞与が登録されていません
                                  </Typography>
                                </div>
                              </div>
                            </div>
                            <div />
                          </>
                        ) : (
                          fields.map((name, index) => (
                            <React.Fragment key={name}>
                              <div className="lg:flex justify-between pb-17 border-black-300 border-dashed border-b-2">
                                <div className="flex items-center pb-10 lg:pb-0">
                                  {index === 0 && (
                                    <>
                                      <Typography
                                        className="pr-16 text-14 text-black-800"
                                        isBold
                                      >
                                        賞与
                                      </Typography>
                                    </>
                                  )}
                                </div>
                                <div className="md:flex items-center justify-end">
                                  <div className="flex items-center">
                                    <SelectBox
                                      name={`${name}bonus_month`}
                                      validate={required}
                                      options={MONTH_SELECT_ITEMS}
                                    />
                                    <Typography
                                      className="pl-8 pr-10 text-14 text-black-800"
                                      isBold
                                    >
                                      月
                                    </Typography>
                                    <NumberField
                                      className="w-160"
                                      name={`${name}bonus`}
                                      validate={zeroOrMoreNumber}
                                    />
                                    <Typography
                                      className="pl-8 text-14 text-black-800"
                                      isBold
                                    >
                                      円
                                    </Typography>
                                  </div>
                                  <div className="flex justify-end">
                                    <Button
                                      className="bg-white border border-black-600 border-solid h-37 rounded-4 w-168 hover:bg-black-200 block md:hidden ml-10 my-[10px]"
                                      onClick={() => fields.remove(index)}
                                    >
                                      <Typography
                                        className="text-14 text-black-600"
                                        isBold
                                      >
                                        賞与の入力枠を削除する
                                      </Typography>
                                    </Button>
                                  </div>
                                </div>
                              </div>
                              <div className="lg:flex justify-between pb-17 hidden md:block">
                                <Button
                                  className="bg-white border border-black-600 border-solid h-37 rounded-4 w-168 hover:bg-black-200"
                                  onClick={() => fields.remove(index)}
                                >
                                  <Typography className="text-14 text-black-600" isBold>
                                    賞与の入力枠を削除する
                                  </Typography>
                                </Button>
                              </div>
                            </React.Fragment>
                          ))
                        )
                      }
                    </FieldArray>

                    <Button
                      className="w-full h-[53px] border border-dashed border-primary-500 text-[14px] text-primary-500 hover:bg-primary-50"
                      onClick={() => push('input_bonuses_attributes', {})}
                    >
                      <Typography className="text-14" isBold>
                        賞与の入力枠を追加する
                      </Typography>
                    </Button>
                  </Grid>
                )}

              <Modal
                className="w-[95%] xl:w-[1140px]"
                isOpen={isInsuranceModalOpen}
                isDisableBackdropClick
                onClose={() => {
                  setIsInsuranceModalOpen(false)
                }}
              >
                <CircleTitle className="pb-[32px]" title="保険を追加する" />
                <InsuranceForm
                  handleCancel={() => setIsInsuranceModalOpen(false)}
                  submitCallback={() => {
                    setIsInsuranceModalOpen(false)
                    dispatch(fetchAccountInputInsuranceOptionsV2())
                  }}
                />
              </Modal>

              <MyDataHousingModalForm
                isOpen={isOpenHousingModalForm}
                onClose={() => setIsOpenHousingModalForm(false)}
              />

              <WholeSubmitErrorMessage submitErrors={submitErrors} />
              <SubmitErrorMessage show={!submitErrors && submitFailed} />
              <ButtonPrimary
                className={clsx(
                  { ['opacity-50']: Object.keys(errors).length > 0 },
                  'w-[246px] h-[64px] mx-auto mb-[16px] ',
                )}
                isDisabled={isLoadingInputSalaries}
                onClick={() => handleSubmit(values)}
              >
                <Typography
                  className="relative bottom-[2px] pl-[30px] text-[16px] text-white flex-auto"
                  isBold
                >
                  保存する
                </Typography>
                <ChevronRightIcon className="mr-[17px]" />
              </ButtonPrimary>
              <Button
                className="mx-auto text-[14px] text-black-700"
                onClick={props.onClose}
                isDisabled={isLoadingInputSalaries}
              >
                キャンセル
              </Button>
            </form>
          )
        }}
      />
    </>
  )
}

export default MyDataPersonEditForm
