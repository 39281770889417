import React, { FC } from 'react'
import JournalPaperCommon from '@/templates/v2/myData/journals/_journalPaperCommon'
import ErrorExclamationIcon from '@/assets/images/v2/error-exclamation.svg'

interface JournalPaperSpendingProps {
  /** クラス名 */
  className?: string
  /** データ */
  data: any[]
  /** 選択肢 */
  options: any[]
  /** 仕訳後のコールバック */
  journalUpdateCallback: () => void
}

const JournalPaperSpending: FC<JournalPaperSpendingProps> = (props) => {
  const { className, data, options, journalUpdateCallback } = props

  return (
    <JournalPaperCommon
      className={className}
      title="未分類の支出"
      data={data}
      options={options}
      headerBgColorClassName="bg-alert-600"
      dataBgColorClassName="bg-alert-500"
      textColorClassName="text-alert-600"
      icon={<ErrorExclamationIcon />}
      journalUpdateCallback={journalUpdateCallback}
    />
  )
}

export default JournalPaperSpending
