import React, { FC, useEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import MovieAuth from '@/layouts/MovieAuth'
import MovieHeader from '@/templates/v2/movies/_movieHeader'
import MovieList from '@/templates/v2/movies/List'
import MoviePreview from '@/templates/v2/movies/Preview'
import { isMovieLogind } from '@/utils/movieAuth'
import LearningVideosLoginForm from '@/templates/v2/movies/login/LoginForm'
import FloatingFooterMoviePage from '@/components/v2/molecules/FloatingFooterMoviePage'
import PolicyLink from '@/components/v2/atoms/PolicyLink'

interface MovieProps {
  canDisplayPolicyLink: boolean
}

const Movie: FC<MovieProps> = ({ canDisplayPolicyLink }) => {
  useEffect(() => {
    if (isMovieLogind()) return
  }, [])

  return (
    <>
      <div className="tailwind">
        <div className="font-default pb-[100px]">
          {location.pathname !== '/movies/login' && <MovieHeader />}
          <Switch>
            <Route path="/movies/login" component={LearningVideosLoginForm} />
            <MovieAuth>
              <Route exact path="/movies">
                <Redirect to="/movies/list" />
              </Route>
              <Route path="/movies/list" component={MovieList} />
              <Route path="/movies/:movie_id/preview" component={MoviePreview} />
              <FloatingFooterMoviePage />
            </MovieAuth>
          </Switch>
        </div>

        {canDisplayPolicyLink && <PolicyLink className="bottom-[100px]" />}
      </div>
    </>
  )
}

export default Movie
