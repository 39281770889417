import moment, { Moment } from 'moment'

export const JOURNAL_SELECT_START_DATE = '2021/04/01'
export const JOURNAL_SELECT_START_MONTH = '2022/04'

export const INCOME_KEYS = [
  'person_net_income',
  'spouse_net_income',
  'sub_income_income',
  'estate_investment_income',
  'other_income',
  'uncategorized_income',
  'cash_transfer',
]

export const SPENDING_KEYS = [
  'food_expenses',
  'utility_bills',
  'daily_necessities_cost',
  'transport',
  'clothing_cost',
  'hobby_expenses',
  'car',
  'house_cost_total',
  'medical_expenses',
  'insurance_payment',
  'communication_cost',
  'entertainment_expenses',
  'tuition',
  'pocket_money',
  'scholarship',
  'yearly_spending',
  'expenses',
  'sub_income_expenses',
  'estate_investment_expenses',
  'tax_payment_total',
  'other_cost',
  'uncategorized_cash',
  'uncategorized_expense',
  'cash_transfer',
]

export const CATEGORY_KEY = {
  expenses: 'expenses',
  subIncomeExpenses: 'sub_income_expenses',
  estateInvestmentExpenses: 'estate_investment_expenses',
  foodExpenses: 'food_expenses',
  utilityBills: 'utility_bills',
  dailyNecessitiesCost: 'daily_necessities_cost',
  transport: 'transport',
  clothingCost: 'clothing_cost',
  hobbyExpenses: 'hobby_expenses',
  communicationCost: 'communication_cost',
  entertainmentExpenses: 'entertainment_expenses',
  tuition: 'tuition',
  pocketMoney: 'pocket_money',
  scholarship: 'scholarship',
  car: 'car',
  insurancePayment: 'insurance_payment',
  yearlySpending: 'yearly_spending',
  taxPaymentTotal: 'tax_payment_total',
  otherCost: 'other_cost',
  uncategorizedCash: 'uncategorized_cash',
  uncategorizedExpense: 'uncategorized_expense',
  cashTransfer: 'cash_transfer',
  personNetIncome: 'person_net_income',
  spouseNetIncome: 'spouse_net_income',
  subIncomeIncome: 'sub_income_income',
  estateInvestmentIncome: 'estate_investment_income',
  otherIncome: 'other_income',
  uncategorizedIncome: 'uncategorized_income',
  houseCostTotal: 'house_cost_total',
  medicalExpenses: 'medical_expenses',
}

export const buildFetchJournalValues = (dateMonth: Moment | string) => {
  return {
    start_date: moment(dateMonth, 'YYYY/MM/DD').startOf('month').format('YYYY/MM/DD'),
    end_date: moment(dateMonth, 'YYYY/MM/DD')
      .add(1, 'months')
      .startOf('month')
      .format('YYYY/MM/DD'),
  }
}

export const convertCategoryName = (categoryName: string) => {
  switch (categoryName) {
    case CATEGORY_KEY.expenses:
      return '立替経費'
    case CATEGORY_KEY.subIncomeExpenses:
      return '副業経費'
    case CATEGORY_KEY.subIncomeIncome:
      return '副業所得'
    case CATEGORY_KEY.estateInvestmentExpenses:
      return '不動産投資支出'
    case CATEGORY_KEY.foodExpenses:
      return '食費'
    case CATEGORY_KEY.utilityBills:
      return '水道光熱費'
    case CATEGORY_KEY.dailyNecessitiesCost:
      return '日用品雑貨費'
    case CATEGORY_KEY.clothingCost:
      return '被服費'
    case CATEGORY_KEY.hobbyExpenses:
      return '趣味・娯楽費'
    case CATEGORY_KEY.communicationCost:
      return '通信費'
    case CATEGORY_KEY.entertainmentExpenses:
      return '交際費'
    case CATEGORY_KEY.medicalExpenses:
      return '医療費'
    case CATEGORY_KEY.tuition:
      return '学費'
    case CATEGORY_KEY.pocketMoney:
      return 'こづかい'
    case CATEGORY_KEY.scholarship:
      return '奨学金'
    case CATEGORY_KEY.car:
      return '車'
    case CATEGORY_KEY.insurancePayment:
      return '保険料'
    case CATEGORY_KEY.yearlySpending:
      return '特別な支出・大きな出費'
    case CATEGORY_KEY.uncategorizedCash:
      return '現金未分類'
    case CATEGORY_KEY.uncategorizedExpense:
      return '未分類支出'
    case CATEGORY_KEY.cashTransfer:
      return '振替'
    case CATEGORY_KEY.personNetIncome:
      return 'ご本人収入'
    case CATEGORY_KEY.spouseNetIncome:
      return '配偶者収入'
    case CATEGORY_KEY.estateInvestmentIncome:
      return '不動産投資収入'
    case CATEGORY_KEY.otherIncome:
      return 'その他の収入'
    case CATEGORY_KEY.otherCost:
      return 'その他の支出'
    case CATEGORY_KEY.uncategorizedIncome:
      return '未分類収入'
    case CATEGORY_KEY.taxPaymentTotal:
      return '税金・社会保険料'
    case CATEGORY_KEY.houseCostTotal:
      return '住宅費'
    case CATEGORY_KEY.transport:
      return '交通費'
    default:
      return ''
  }
}
