import ApiClient from '@/api/apiClient'
import { InputIncomeBase } from '@/models/inputs/inputIncomeBasesModel'
import {
  InputIncomeCalcResponse,
  InputIncomeDestroyValues,
} from '@/models/inputs/inputIncomesModel'

export const getInputIncomes = async () => {
  return await ApiClient.get(
    '/input_incomes',
    (response: InputIncomeBase[]) => response,
    undefined,
    false,
    'v2',
  )
}

export const postInputIncome = async (values: any) => {
  return await ApiClient.post('/input_incomes', () => {}, values)
}

export const postInputIncomeCalc = async (values: any) => {
  return await ApiClient.post(
    '/input_incomes/calc',
    (response: InputIncomeCalcResponse) => response,
    values,
  )
}

export const patchInputIncome = async (values: any) => {
  return await ApiClient.patch('/input_incomes', () => {}, values)
}

export const deleteInputIncome = async (values: { params: InputIncomeDestroyValues }) => {
  return await ApiClient.delete('/input_incomes', () => {}, values)
}
