import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { isUndefined as _isUndefined, isNull as _isNull } from 'lodash'
import { parse } from 'query-string'
import LogindRedirect from '@/layouts/v2/LogindRedirect'
import MarginContainer from '@/components/v2/atoms/MarginContainer'
import Paper from '@/components/v2/atoms/Paper'
import AccountForm from '@/templates/v2/accounts/commons/AccountForm'
import { setAccount } from '@/containers/accountsSlice'
import { setPageMeta } from '@/containers/pageMetasSlice'
import { createSearchCompany } from '@/containers/admins/companiesSlice'
import { AccountCreateValues } from '@/models/accountsModel'
import { setStorageFpRegistrationCode } from '@/utils/localStorage/fpRegistrationCodeStorage'
import {
  setStorageRegistrationCode,
  getStorageRegistrationCode,
} from '@/utils/localStorage/registrationCodeStorage'

const AccountNew: FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'ユーザー仮登録',
        description: 'ユーザー仮登録',
        keyword: 'ユーザー仮登録',
      }),
    )

    const { registration_code, fp_registration_code } = parse(location.search)

    if (_isUndefined(registration_code) && _isUndefined(fp_registration_code)) {
      const registrationCode = getStorageRegistrationCode()
      !_isNull(registrationCode) && dispatch(createSearchCompany(registrationCode))
      return
    }

    if (!_isUndefined(registration_code)) {
      setStorageRegistrationCode(registration_code as string)
      dispatch(createSearchCompany(registration_code as string))
    }

    if (!_isUndefined(fp_registration_code)) {
      setStorageFpRegistrationCode(fp_registration_code as string)
    }

    history.push('/v2/accounts/new')
  }, [])

  const handleSubmit = (values: AccountCreateValues) => {
    dispatch(setAccount(values))
    history.push('/v2/accounts/confirm')
  }

  return (
    <LogindRedirect>
      <MarginContainer className="py-40">
        <Paper className="mx-8 px-16 py-24 sm:p-40 bg-white">
          <AccountForm
            title="ユーザー仮登録"
            buttonLabel="入力内容を確認する"
            type="new"
            handleSubmit={handleSubmit}
          />
        </Paper>
      </MarginContainer>
    </LogindRedirect>
  )
}

export default AccountNew
