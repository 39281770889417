import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { unauthorizedCheck } from './accountsSlice'
import { getJournals, getJournalUncategorizedV2, patchJournal } from '@/api/journals'
import {
  Journal,
  JournalDate,
  JournalResponse,
  JournalUpdateValues,
} from '@/models/journalsModel'
import { AppThunk } from '@/store'
import { filter as _filter } from 'lodash'

interface JournalState {
  journals: Journal | null
  uncategorizedMtTrans: any
  uncategorizedCount: number
  isLoading: boolean
  isLoadingUncategorized: boolean
}

const initialState: JournalState = {
  journals: null,
  uncategorizedMtTrans: null,
  uncategorizedCount: 0,
  isLoading: false,
  isLoadingUncategorized: false,
}

const journalsSlice = createSlice({
  name: 'journals',
  initialState,
  reducers: {
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    getJournalUncategorizedV2Start: (state) => {
      state.isLoadingUncategorized = true
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    getJournalSuccess: (state, action: PayloadAction<JournalResponse>) => {
      state.journals = action.payload
      state.isLoading = false
      return state
    },
    getJournalUncategorizedSuccess: (state, action: PayloadAction<any>) => {
      state.uncategorizedMtTrans = action.payload
      state.isLoadingUncategorized = false
      return state
    },
    getJournalUncategorizedUpdateSuccess: (state, action: PayloadAction<any>) => {
      const { income, expense } = state.uncategorizedMtTrans || {}
      state.uncategorizedMtTrans = {
        income: _filter(income, (item) => item.id != Number(action.payload)),
        expense: _filter(expense, (item) => item.id != Number(action.payload)),
      }
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
  },
})

export const {
  requestStart,
  getJournalUncategorizedV2Start,
  requestSuccess,
  getJournalSuccess,
  getJournalUncategorizedSuccess,
  getJournalUncategorizedUpdateSuccess,
  requestFailure,
} = journalsSlice.actions
export const journalsReducer = journalsSlice.reducer

export const fetchJournal = (values: JournalDate): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  getJournals(values)
    .then((response: JournalResponse) => {
      dispatch(getJournalSuccess(response))
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const updateJournal = (
  values: JournalUpdateValues,
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchJournal(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const fetchJournalUncategorizedV2 = (): AppThunk => async (dispatch) => {
  dispatch(getJournalUncategorizedV2Start())
  getJournalUncategorizedV2()
    .then((response: any) => {
      dispatch(getJournalUncategorizedSuccess(response))
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const updateJournalV2 = (
  values: JournalUpdateValues,
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchJournal(values)
    .then(() => {
      dispatch(getJournalUncategorizedUpdateSuccess(values.id))
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}
