import React, { FC, useMemo, useState, useContext, useEffect } from 'react'
import clsx from 'clsx'
import { includes as _includes, filter as _filter } from 'lodash'
import Typography from '@/components/v2/atoms/Typography'
import SimulationAccordion from '@/templates/v2/simulations/_simulationAccordion'
import SimulationNewButton from '@/templates/v2/simulations/_simulationNewButton'
import FutureEstateInvestmentSaleModalForm from '@/templates/v2/simulations/_futureEstateInvestmentSaleModalForm'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/store'
import Button from '@/components/v2/atoms/Button'
import {
  THIS_YEAR_NUMBER,
  convertDateToJapaneseFormat,
  convertLocaleString,
  extractYearOrMonthFromDate,
} from '@/models/commonsModelFunc'
import { find as _find } from 'lodash'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import { destroyFutureEstateInvestmentSaleV2 } from '@/containers/futures/futureEstateInvestmentSalesSlice'
import { fetchAccountFutureItems } from '@/containers/accountsSlice'
import { SimulationDetailEditContext } from '@/models/futures/futureBasesModelFunc'
import Tooltip from '@/components/v2/atoms/Tooltip'
import SimulationItemAccordion from '@/templates/v2/simulations/_simulationItemAccordion'

interface FutureEstateInvestmentSaleFormProps {
  /** 選択シミュレーションID */
  selectedSimulationId: number[] | undefined
  /** 選択メソッド */
  handleSelect: (item: any) => void

  /** 不動産投資選択シミュレーションID */
  selectedEstateInvestmentSimulationId: number[] | undefined
  /** 住居の賃し出しシミュレーションID */
  selectedLendHousingSimulationId: number[] | undefined
  /* FutureItemsを参照するID */
  futureBaseId?: number
  /* 初期化すべきかどうか */
  shouldInitialize?: boolean
  /* 編集を確認したか */
  confirmEdit?: boolean
  /* 編集確認モーダルを展開するメソッド */
  openEditConfirmModal?: () => void
}

interface FutureEstateInvestmentSaleTableProps {
  /** アイテム */
  item: any
  /** セレクトアイテム */
  propertySelectItems?: any
  /** td要素のクラス */
  tdClassName?: string
}

export const FutureEstateInvestmentSaleTable: FC<
  FutureEstateInvestmentSaleTableProps
> = ({ item }) => {
  const futureEstateInvestments = useSelector(
    (state: RootState) => state.accounts.futureItems?.future_estate_investments,
  )

  const inputEstateInvestments = useSelector(
    (state: RootState) => state.accounts.futureItems?.input_estate_investments,
  )

  const futureLendHousings = useSelector(
    (state: RootState) => state.accounts.futureItems?.future_lend_housings,
  )

  const clonedFutureEstateInvestments = useSelector(
    (state: RootState) => state.futureEstateInvestments.futureEstateInvestments || {},
  )

  const inputEsateItems = useMemo(
    () =>
      inputEstateInvestments?.map((item: any) => ({
        value: `InputEstateInvestment_${item.id}`,
        label: `${convertDateToJapaneseFormat(item.delivery_date, 'year')}購入 ${
          item.property_price_manyen
        }万円`,
      })),
    [inputEstateInvestments],
  )

  const futureEstateItems = useMemo(
    () =>
      futureEstateInvestments?.map((item: any) => ({
        value: `FutureEstateInvestment_${item.id}`,
        label: `${convertDateToJapaneseFormat(item.purchased_at, 'year')}購入 ${
          item.property_price_manyen
        }万円`,
      })),
    [futureEstateInvestments],
  )

  const futureLendHousingItems = useMemo(
    () =>
      futureLendHousings?.map((item: any) => ({
        value: `FutureLendHousing_${item.id}`,
        label: `${convertDateToJapaneseFormat(
          item?.lend_start_at,
          'year',
        )}賃貸として貸し出し 月間賃料${item?.rent_income_monthly}円`,
      })),
    [futureLendHousings],
  )

  const propertySelectItems = [
    ...(inputEsateItems || []),
    ...(futureEstateItems || []),
    ...(futureLendHousingItems || []),
  ]

  const getEstateLabel = (value: string) => {
    return _find(propertySelectItems, { value })?.label
  }

  return (
    <table
      key={item.id}
      className={clsx('w-[100%] mb-[20px] last:mb-0 text-[12px] md:text-[14px] bg-white')}
    >
      <tbody>
        <tr className="h-[45px] odd:bg-black-100">
          <td className="w-[40%] px-[16px] md:px-[30px]">
            <Typography isBold>売却する物件</Typography>
          </td>
          <td className="w-[60%] px-[16px] md:px-[30px]">
            <Typography>
              {getEstateLabel(
                `${item.estate_investment_type}_${item.estate_investment_id}`,
              ) ??
                _find(
                  clonedFutureEstateInvestments,
                  // @ts-ignore
                  (cloned_item) => cloned_item.id == item.estate_investment_id,
                  // @ts-ignore
                )?.property_name_for_option}
            </Typography>
          </td>
        </tr>

        <tr className="h-[45px] odd:bg-black-100">
          <td className="w-[40%] px-[16px] md:px-[30px]">
            <Typography isBold>売却予定</Typography>
          </td>
          <td className="w-[60%] px-[16px] md:px-[30px]">
            <Typography>{extractYearOrMonthFromDate(item.sell_at, 'year')} 年</Typography>
          </td>
        </tr>

        <tr className="h-[45px] odd:bg-black-100">
          <td className="w-[40%] px-[16px] md:px-[30px]">
            <Typography isBold>売却金額</Typography>
          </td>
          <td className="w-[60%] px-[16px] md:px-[30px]">
            <Typography>{convertLocaleString(item.sale_amount_manyen)} 万円</Typography>
          </td>
        </tr>

        <tr className="h-[45px] odd:bg-black-100">
          <td className="w-[40%] px-[16px] md:px-[30px]">
            <Typography isBold>売却にかかる費用</Typography>
          </td>
          <td className="w-[60%] px-[16px] md:px-[30px]">
            <Typography>{convertLocaleString(item.cost_manyen)} 万円</Typography>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

const FutureEstateInvestmentSaleForm: FC<FutureEstateInvestmentSaleFormProps> = (
  props,
) => {
  const dispatch = useDispatch()
  const { showFlashMsg } = useFlashAlert()
  const { isManipulatedApproval } = useContext(SimulationDetailEditContext)

  const {
    selectedSimulationId,
    handleSelect,
    selectedEstateInvestmentSimulationId,
    selectedLendHousingSimulationId,
  } = props

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [id, setId] = useState<number | null>(null)

  const futureEstateInvestmentSales = useSelector(
    (state: RootState) => state.accounts.futureItems?.future_estate_investment_sales,
  )

  const isItemApplicable = (item: any): boolean => {
    const pt1 = item.estate_investment_type == 'InputEstateInvestment'
    const pt2 =
      _includes(selectedEstateInvestmentSimulationId, item.estate_investment_id) &&
      item.estate_investment_type == 'FutureEstateInvestment'
    const pt3 =
      _includes(selectedLendHousingSimulationId, item.estate_investment_id) &&
      item.estate_investment_type == 'FutureLendHousing'
    return pt1 || pt2 || pt3
  }

  useEffect(() => {
    if (props.futureBaseId == null || !props.shouldInitialize) return
    _filter(futureEstateInvestmentSales, {
      future_base_id: props.futureBaseId,
    }).forEach((item) => handleSelect(item))
  }, [props.shouldInitialize])

  const sortedFutureEstateInvestmentSales = useMemo(() => {
    if (props.futureBaseId == null || futureEstateInvestmentSales == null)
      return futureEstateInvestmentSales
    const eqFbId = (item: any) => item.future_base_id === props.futureBaseId
    return [
      ...futureEstateInvestmentSales.filter((item) => eqFbId(item)),
      ...futureEstateInvestmentSales.filter((item) => !eqFbId(item)),
    ]
  }, [futureEstateInvestmentSales])

  const titleIndex = (index: number) => {
    if (
      props.futureBaseId == null ||
      props.confirmEdit == null ||
      futureEstateInvestmentSales == null
    )
      return index + 1
    const eqFbId = (item: any) => item.future_base_id === props.futureBaseId
    return index + 1 - futureEstateInvestmentSales.filter((item) => eqFbId(item)).length
  }

  const isExpiredSettings = (date: string | null) => {
    if (date == null) return false
    const settingsDate = new Date(date)
    return settingsDate.getFullYear() < THIS_YEAR_NUMBER
  }

  const handleExecIfConfired = (exec: (item: any) => void, item: any) => {
    props.confirmEdit === false ? props.openEditConfirmModal?.() : exec(item)
  }

  return (
    <SimulationAccordion
      className="pb-[16px]"
      title="不動産投資売却"
      isSelected={selectedSimulationId != null && selectedSimulationId.length !== 0}
      selectedCount={selectedSimulationId?.length}
      itemCount={sortedFutureEstateInvestmentSales?.length}
      isExpiredSettings={sortedFutureEstateInvestmentSales?.some(
        (item) =>
          _includes(selectedSimulationId, item.id) && isExpiredSettings(item.sell_at),
      )}
    >
      <div className="flex flex-col gap-y-[12px] pt-[6px]">
        {sortedFutureEstateInvestmentSales?.map((item: any, index: number) => {
          const handleDestroyV2 = (item: any) => {
            const result = confirm('本当に削除してよろしいですか？')
            if (!result) return

            const destroyFunc = new Promise((resolve) => {
              dispatch(
                destroyFutureEstateInvestmentSaleV2(item, item.id, () => {
                  resolve('')
                  showFlashMsg('削除しました')
                }),
              )
            })

            Promise.all([destroyFunc]).then(() => {
              if (_includes(selectedSimulationId, item.id)) handleSelect(item)
              dispatch(fetchAccountFutureItems(props.futureBaseId))
            })
          }

          const handleEdit = (item: any) => {
            setId(item.id)
            setIsEdit(true)
            setIsOpenModal(true)
          }

          return (
            <Tooltip
              className="w-auto"
              isDispaly={!isItemApplicable(item)}
              itemClassName="w-[330px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
              item="対象の物件がシミュレーションにセットされていないため設定できません"
            >
              <SimulationItemAccordion
                key={`future_estate_investment_${item.id}_${index}`}
                title={
                  props.futureBaseId === item.future_base_id && props.confirmEdit != null
                    ? '登録中の不動産投資売却'
                    : `不動産投資売却${titleIndex(index)}`
                }
                isSelected={_includes(selectedSimulationId, item.id)}
                isExpiredSettings={isExpiredSettings(item.sell_at)}
                displayExpiredSettingsTips={isItemApplicable(item)}
                inputType="checkbox"
                onSelect={() => {
                  if (isItemApplicable(item)) {
                    handleExecIfConfired(handleSelect, item)
                  }
                }}
              >
                <div className="flex justify-end mb-[5px]">
                  <Button
                    className="mr-[16px]"
                    isDisabled={!isManipulatedApproval}
                    onClick={(e) => {
                      handleExecIfConfired(handleEdit, item)
                      e.stopPropagation()
                    }}
                  >
                    <i className="ico edit text-[14px] text-black-700" />
                    <Typography className="pl-[5px] text-[14px] text-black-700">
                      編集
                    </Typography>
                  </Button>
                  <Button
                    isDisabled={!isManipulatedApproval}
                    onClick={(e) => {
                      handleExecIfConfired(handleDestroyV2, item)
                      e.stopPropagation()
                    }}
                  >
                    <Typography className="pl-[5px] text-[14px] text-black-700">
                      削除
                    </Typography>
                  </Button>
                </div>
                <FutureEstateInvestmentSaleTable item={item} />
              </SimulationItemAccordion>
            </Tooltip>
          )
        })}
      </div>

      <SimulationNewButton
        isDisabled={!isManipulatedApproval}
        onClick={() => {
          setId(null)
          setIsEdit(false)
          setIsOpenModal(true)
        }}
      />

      {isOpenModal && (
        <FutureEstateInvestmentSaleModalForm
          isOpen={isOpenModal}
          isEdit={isEdit}
          onClose={() => setIsOpenModal(false)}
          id={id}
          futureBaseId={props.futureBaseId}
        />
      )}
    </SimulationAccordion>
  )
}

export default FutureEstateInvestmentSaleForm
