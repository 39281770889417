import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import Typography from '@/components/v2/atoms/Typography'
import Modal from '@/components/v2/atoms/Modal'
import Button from '@/components/v2/atoms/Button'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import { convertLocaleString, isNotEmptyValue } from '@/models/commonsModelFunc'
import {
  SCHOOL_AGE,
  convertSchoolType,
  convertUniversitySchoolType,
  isChildbirthWithinRange,
} from '@/models/inputs/inputPlanTuitionsModelFunc'
import { RootState } from '@/store'
import SimulationAccordion from '@/templates/v2/simulations/_simulationAccordion'
import MyDataTuitionEditForm from '@/templates/v2/myData/tuitions/Form'

const contentArr = [
  {
    school: (item: any) => (
      <Typography>{`0〜${item.is_early_born ? 2 : 3}歳児保育`}</Typography>
    ),
    school_grade: (item: any) => <Typography>0歳</Typography>,
    on_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(item.input_plan_tuitions[0]?.tuition_1_manyen)}万円`}
      </Typography>
    ),
    off_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.input_plan_tuitions[0]?.off_campus_expenses_1_manyen,
        )}万円`}
      </Typography>
    ),
    total: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.input_plan_tuitions[0]?.off_campus_expenses_1_manyen +
            item.input_plan_tuitions[0]?.tuition_1_manyen,
        )}万円`}
      </Typography>
    ),
    comperison: (item: any) => SCHOOL_AGE.toddler0,
  },
  {
    school: (item: any) => (
      <Typography>{`0〜${item.is_early_born ? 2 : 3}歳児保育`}</Typography>
    ),
    school_grade: (item: any) => <Typography>1歳</Typography>,
    on_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(item.input_plan_tuitions[0]?.tuition_2_manyen)}万円`}
      </Typography>
    ),
    off_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.input_plan_tuitions[0]?.off_campus_expenses_2_manyen,
        )}万円`}
      </Typography>
    ),
    total: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.input_plan_tuitions[0]?.off_campus_expenses_2_manyen +
            item.input_plan_tuitions[0]?.tuition_2_manyen,
        )}万円`}
      </Typography>
    ),
    comperison: (item: any) => SCHOOL_AGE.toddler1,
  },
  {
    school: (item: any) => (
      <Typography>{`0〜${item.is_early_born ? 2 : 3}歳児保育`}</Typography>
    ),
    school_grade: (item: any) => <Typography>2歳</Typography>,
    on_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(item.input_plan_tuitions[0]?.tuition_3_manyen)}万円`}
      </Typography>
    ),
    off_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.input_plan_tuitions[0]?.off_campus_expenses_3_manyen,
        )}万円`}
      </Typography>
    ),
    total: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.input_plan_tuitions[0]?.off_campus_expenses_3_manyen +
            item.input_plan_tuitions[0]?.tuition_3_manyen,
        )}万円`}
      </Typography>
    ),
    comperison: (item: any) => SCHOOL_AGE.toddler2,
  },
  {
    school: (item: any) => (
      <Typography>
        0〜3歳児
        <br />
        保育
      </Typography>
    ),
    school_grade: (item: any) => <Typography>3歳</Typography>,
    on_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(item.input_plan_tuitions[0]?.tuition_4_manyen)}万円`}
      </Typography>
    ),
    off_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.input_plan_tuitions[0]?.off_campus_expenses_4_manyen,
        )}万円`}
      </Typography>
    ),
    total: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.input_plan_tuitions[0]?.off_campus_expenses_4_manyen +
            item.input_plan_tuitions[0]?.tuition_4_manyen,
        )}万円`}
      </Typography>
    ),

    comperison: (item: any) => SCHOOL_AGE.toddler3,
  },

  {
    school: (item: any) => (
      <Typography>
        {convertSchoolType(item.input_plan_tuitions[1]?.school_type)} 幼稚園
        <br />／ 保育園
      </Typography>
    ),
    school_grade: (item: any) => <Typography>年少</Typography>,
    on_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(item.input_plan_tuitions[1]?.tuition_1_manyen)}万円`}
      </Typography>
    ),
    off_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.input_plan_tuitions[1]?.off_campus_expenses_1_manyen,
        )}万円`}
      </Typography>
    ),
    total: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.input_plan_tuitions[1]?.off_campus_expenses_1_manyen +
            item.input_plan_tuitions[1]?.tuition_1_manyen,
        )}万円`}
      </Typography>
    ),
    comperison: (item: any) => SCHOOL_AGE.kindergarten1,
  },
  {
    school: (item: any) => (
      <Typography>
        {convertSchoolType(item.input_plan_tuitions[1]?.school_type)} 幼稚園
        <br />／ 保育園
      </Typography>
    ),
    school_grade: (item: any) => <Typography>年中</Typography>,
    on_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(item.input_plan_tuitions[1]?.tuition_2_manyen)}万円`}
      </Typography>
    ),
    off_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.input_plan_tuitions[1]?.off_campus_expenses_2_manyen,
        )}万円`}
      </Typography>
    ),
    total: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.input_plan_tuitions[1]?.off_campus_expenses_2_manyen +
            item.input_plan_tuitions[1]?.tuition_2_manyen,
        )}万円`}
      </Typography>
    ),
    comperison: (item: any) => SCHOOL_AGE.kindergarten2,
  },
  {
    school: (item: any) => (
      <Typography>
        {convertSchoolType(item.input_plan_tuitions[1]?.school_type)} 幼稚園
        <br />／ 保育園
      </Typography>
    ),
    school_grade: (item: any) => <Typography>年長</Typography>,
    on_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(item.input_plan_tuitions[1]?.tuition_3_manyen)}万円`}
      </Typography>
    ),
    off_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.input_plan_tuitions[1]?.off_campus_expenses_3_manyen,
        )}万円`}
      </Typography>
    ),
    total: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.input_plan_tuitions[1]?.off_campus_expenses_3_manyen +
            item.input_plan_tuitions[1]?.tuition_3_manyen,
        )}万円`}
      </Typography>
    ),
    comperison: (item: any) => SCHOOL_AGE.kindergarten3,
  },

  {
    school: (item: any) => (
      <Typography>
        {convertSchoolType(item.input_plan_tuitions[2]?.school_type)} 小学校
      </Typography>
    ),
    school_grade: (item: any) => <Typography>1年生</Typography>,
    on_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(item.input_plan_tuitions[2]?.tuition_1_manyen)}万円`}
      </Typography>
    ),
    off_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.input_plan_tuitions[2]?.off_campus_expenses_1_manyen,
        )}万円`}
      </Typography>
    ),
    total: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.input_plan_tuitions[2]?.off_campus_expenses_1_manyen +
            item.input_plan_tuitions[2]?.tuition_1_manyen,
        )}万円`}
      </Typography>
    ),
    comperison: (item: any) => SCHOOL_AGE.primarySchool1,
  },
  {
    school: (item: any) => (
      <Typography>
        {convertSchoolType(item.input_plan_tuitions[2]?.school_type)} 小学校
      </Typography>
    ),
    school_grade: (item: any) => <Typography>2年生</Typography>,
    on_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(item.input_plan_tuitions[2]?.tuition_2_manyen)}万円`}
      </Typography>
    ),
    off_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.input_plan_tuitions[2]?.off_campus_expenses_2_manyen,
        )}万円`}
      </Typography>
    ),
    total: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.input_plan_tuitions[2]?.off_campus_expenses_2_manyen +
            item.input_plan_tuitions[2]?.tuition_2_manyen,
        )}万円`}
      </Typography>
    ),
    comperison: (item: any) => SCHOOL_AGE.primarySchool2,
  },
  {
    school: (item: any) => (
      <Typography>
        {convertSchoolType(item.input_plan_tuitions[2]?.school_type)} 小学校
      </Typography>
    ),
    school_grade: (item: any) => <Typography>3年生</Typography>,
    on_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(item.input_plan_tuitions[2]?.tuition_3_manyen)}万円`}
      </Typography>
    ),
    off_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.input_plan_tuitions[2]?.off_campus_expenses_3_manyen,
        )}万円`}
      </Typography>
    ),
    total: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.input_plan_tuitions[2]?.off_campus_expenses_3_manyen +
            item.input_plan_tuitions[2]?.tuition_3_manyen,
        )}万円`}
      </Typography>
    ),
    comperison: (item: any) => SCHOOL_AGE.primarySchool3,
  },
  {
    school: (item: any) => (
      <Typography>
        {convertSchoolType(item.input_plan_tuitions[2]?.school_type)} 小学校
      </Typography>
    ),
    school_grade: (item: any) => <Typography>4年生</Typography>,
    on_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(item.input_plan_tuitions[2]?.tuition_4_manyen)}万円`}
      </Typography>
    ),
    off_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.input_plan_tuitions[2]?.off_campus_expenses_4_manyen,
        )}万円`}
      </Typography>
    ),
    total: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.input_plan_tuitions[2]?.off_campus_expenses_4_manyen +
            item.input_plan_tuitions[2]?.tuition_4_manyen,
        )}万円`}
      </Typography>
    ),
    comperison: (item: any) => SCHOOL_AGE.primarySchool4,
  },
  {
    school: (item: any) => (
      <Typography>
        {convertSchoolType(item.input_plan_tuitions[2]?.school_type)} 小学校
      </Typography>
    ),
    school_grade: (item: any) => <Typography>5年生</Typography>,
    on_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(item.input_plan_tuitions[2]?.tuition_5_manyen)}万円`}
      </Typography>
    ),
    off_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.input_plan_tuitions[2]?.off_campus_expenses_5_manyen,
        )}万円`}
      </Typography>
    ),
    total: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.input_plan_tuitions[2]?.off_campus_expenses_5_manyen +
            item.input_plan_tuitions[2]?.tuition_5_manyen,
        )}万円`}
      </Typography>
    ),
    comperison: (item: any) => SCHOOL_AGE.primarySchool5,
  },
  {
    school: (item: any) => (
      <Typography>
        {convertSchoolType(item.input_plan_tuitions[2]?.school_type)} 小学校
      </Typography>
    ),
    school_grade: (item: any) => <Typography>6年生</Typography>,
    on_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(item.input_plan_tuitions[2]?.tuition_6_manyen)}万円`}
      </Typography>
    ),
    off_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.input_plan_tuitions[2]?.off_campus_expenses_6_manyen,
        )}万円`}
      </Typography>
    ),
    total: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.input_plan_tuitions[2]?.off_campus_expenses_6_manyen +
            item.input_plan_tuitions[2]?.tuition_6_manyen,
        )}万円`}
      </Typography>
    ),
    comperison: (item: any) => SCHOOL_AGE.primarySchool6,
  },

  {
    school: (item: any) => (
      <Typography>
        {convertSchoolType(item.input_plan_tuitions[3]?.school_type)} 中学校
      </Typography>
    ),
    school_grade: (item: any) => <Typography>1年生</Typography>,
    on_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(item.input_plan_tuitions[3]?.tuition_1_manyen)}万円`}
      </Typography>
    ),
    off_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.input_plan_tuitions[3]?.off_campus_expenses_1_manyen,
        )}万円`}
      </Typography>
    ),
    total: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.input_plan_tuitions[3]?.off_campus_expenses_1_manyen +
            item.input_plan_tuitions[3]?.tuition_1_manyen,
        )}万円`}
      </Typography>
    ),
    comperison: (item: any) => SCHOOL_AGE.juniorHighSchool1,
  },
  {
    school: (item: any) => (
      <Typography>
        {convertSchoolType(item.input_plan_tuitions[3]?.school_type)} 中学校
      </Typography>
    ),
    school_grade: (item: any) => <Typography>2年生</Typography>,
    on_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(item.input_plan_tuitions[3]?.tuition_2_manyen)}万円`}
      </Typography>
    ),
    off_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.input_plan_tuitions[3]?.off_campus_expenses_2_manyen,
        )}万円`}
      </Typography>
    ),
    total: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.input_plan_tuitions[3]?.off_campus_expenses_2_manyen +
            item.input_plan_tuitions[3]?.tuition_2_manyen,
        )}万円`}
      </Typography>
    ),
    comperison: (item: any) => SCHOOL_AGE.juniorHighSchool2,
  },
  {
    school: (item: any) => (
      <Typography>
        {convertSchoolType(item.input_plan_tuitions[3]?.school_type)} 中学校
      </Typography>
    ),
    school_grade: (item: any) => <Typography>3年生</Typography>,
    on_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(item.input_plan_tuitions[3]?.tuition_3_manyen)}万円`}
      </Typography>
    ),
    off_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.input_plan_tuitions[3]?.off_campus_expenses_3_manyen,
        )}万円`}
      </Typography>
    ),
    total: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.input_plan_tuitions[3]?.off_campus_expenses_3_manyen +
            item.input_plan_tuitions[3]?.tuition_3_manyen,
        )}万円`}
      </Typography>
    ),
    comperison: (item: any) => SCHOOL_AGE.juniorHighSchool3,
  },

  {
    school: (item: any) => (
      <Typography>
        {convertSchoolType(item.input_plan_tuitions[4]?.school_type)} 高校
      </Typography>
    ),
    school_grade: (item: any) => <Typography>1年生</Typography>,
    on_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(item.input_plan_tuitions[4]?.tuition_1_manyen)}万円`}
      </Typography>
    ),
    off_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.input_plan_tuitions[4]?.off_campus_expenses_1_manyen,
        )}万円`}
      </Typography>
    ),
    total: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.input_plan_tuitions[4]?.off_campus_expenses_1_manyen +
            item.input_plan_tuitions[4]?.tuition_1_manyen,
        )}万円`}
      </Typography>
    ),
    comperison: (item: any) => SCHOOL_AGE.highSchool1,
  },
  {
    school: (item: any) => (
      <Typography>
        {convertSchoolType(item.input_plan_tuitions[4]?.school_type)} 高校
      </Typography>
    ),
    school_grade: (item: any) => <Typography>2年生</Typography>,
    on_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(item.input_plan_tuitions[4]?.tuition_2_manyen)}万円`}
      </Typography>
    ),
    off_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.input_plan_tuitions[4]?.off_campus_expenses_2_manyen,
        )}万円`}
      </Typography>
    ),
    total: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.input_plan_tuitions[4]?.off_campus_expenses_2_manyen +
            item.input_plan_tuitions[4]?.tuition_2_manyen,
        )}万円`}
      </Typography>
    ),
    comperison: (item: any) => SCHOOL_AGE.highSchool2,
  },
  {
    school: (item: any) => (
      <Typography>
        {convertSchoolType(item.input_plan_tuitions[4]?.school_type)} 高校
      </Typography>
    ),
    school_grade: (item: any) => <Typography>3年生</Typography>,
    on_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(item.input_plan_tuitions[4]?.tuition_3_manyen)}万円`}
      </Typography>
    ),
    off_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.input_plan_tuitions[4]?.off_campus_expenses_3_manyen,
        )}万円`}
      </Typography>
    ),
    total: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.input_plan_tuitions[4]?.off_campus_expenses_3_manyen +
            item.input_plan_tuitions[4]?.tuition_3_manyen,
        )}万円`}
      </Typography>
    ),
    comperison: (item: any) => SCHOOL_AGE.highSchool3,
  },

  {
    school: (item: any) => (
      <Typography>
        {convertUniversitySchoolType(item.input_plan_tuitions[5]?.school_type)} <br />
        大学
      </Typography>
    ),
    school_grade: (item: any) => <Typography>1年生</Typography>,
    on_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(item.input_plan_tuitions[5]?.tuition_1_manyen)}万円`}
      </Typography>
    ),
    off_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.input_plan_tuitions[5]?.off_campus_expenses_1_manyen,
        )}万円`}
      </Typography>
    ),
    total: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.input_plan_tuitions[5]?.off_campus_expenses_1_manyen +
            item.input_plan_tuitions[5]?.tuition_1_manyen,
        )}万円`}
      </Typography>
    ),
    comperison: (item: any) =>
      isNotEmptyValue(item.input_plan_tuitions[5]?.tuition_1_manyen)
        ? SCHOOL_AGE.university1
        : 0,
  },
  {
    school: (item: any) => (
      <Typography>
        {convertUniversitySchoolType(item.input_plan_tuitions[5]?.school_type)} <br />
        大学
      </Typography>
    ),
    school_grade: (item: any) => <Typography>2年生</Typography>,
    on_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(item.input_plan_tuitions[5]?.tuition_2_manyen)}万円`}
      </Typography>
    ),
    off_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.input_plan_tuitions[5]?.off_campus_expenses_2_manyen,
        )}万円`}
      </Typography>
    ),
    total: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.input_plan_tuitions[5]?.off_campus_expenses_2_manyen +
            item.input_plan_tuitions[5]?.tuition_2_manyen,
        )}万円`}
      </Typography>
    ),
    comperison: (item: any) =>
      isNotEmptyValue(item.input_plan_tuitions[5]?.tuition_2_manyen)
        ? SCHOOL_AGE.university2
        : 0,
  },
  {
    school: (item: any) => (
      <Typography>
        {convertUniversitySchoolType(item.input_plan_tuitions[5]?.school_type)} <br />
        大学
      </Typography>
    ),
    school_grade: (item: any) => <Typography>3年生</Typography>,
    on_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(item.input_plan_tuitions[5]?.tuition_3_manyen)}万円`}
      </Typography>
    ),
    off_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.input_plan_tuitions[5]?.off_campus_expenses_3_manyen,
        )}万円`}
      </Typography>
    ),
    total: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.input_plan_tuitions[5]?.off_campus_expenses_3_manyen +
            item.input_plan_tuitions[5]?.tuition_3_manyen,
        )}万円`}
      </Typography>
    ),
    comperison: (item: any) =>
      isNotEmptyValue(item.input_plan_tuitions[5]?.tuition_3_manyen)
        ? SCHOOL_AGE.university3
        : 0,
  },
  {
    school: (item: any) => (
      <Typography>
        {convertUniversitySchoolType(item.input_plan_tuitions[5]?.school_type)} <br />
        大学
      </Typography>
    ),
    school_grade: (item: any) => <Typography>4年生</Typography>,
    on_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(item.input_plan_tuitions[5]?.tuition_4_manyen)}万円`}
      </Typography>
    ),
    off_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.input_plan_tuitions[5]?.off_campus_expenses_4_manyen,
        )}万円`}
      </Typography>
    ),
    total: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.input_plan_tuitions[5]?.off_campus_expenses_4_manyen +
            item.input_plan_tuitions[5]?.tuition_4_manyen,
        )}万円`}
      </Typography>
    ),
    comperison: (item: any) =>
      isNotEmptyValue(item.input_plan_tuitions[5]?.tuition_4_manyen)
        ? SCHOOL_AGE.university4
        : 0,
  },

  {
    school: (item: any) => (
      <Typography>
        {convertUniversitySchoolType(item.input_plan_tuitions[6]?.school_type)} 大学院
      </Typography>
    ),
    school_grade: (item: any) => <Typography>1年生</Typography>,
    on_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(item.input_plan_tuitions[6]?.tuition_1_manyen)}万円`}
      </Typography>
    ),
    off_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.input_plan_tuitions[6]?.off_campus_expenses_1_manyen,
        )}万円`}
      </Typography>
    ),
    total: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.input_plan_tuitions[6]?.off_campus_expenses_1_manyen +
            item.input_plan_tuitions[6]?.tuition_1_manyen,
        )}万円`}
      </Typography>
    ),
    comperison: (item: any) =>
      isNotEmptyValue(item.input_plan_tuitions[6]?.tuition_1_manyen)
        ? SCHOOL_AGE.graduateSchool1
        : 0,
  },
  {
    school: (item: any) => (
      <Typography>
        {convertUniversitySchoolType(item.input_plan_tuitions[6]?.school_type)} 大学院
      </Typography>
    ),
    school_grade: (item: any) => <Typography>2年生</Typography>,
    on_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(item.input_plan_tuitions[6]?.tuition_2_manyen)}万円`}
      </Typography>
    ),
    off_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.input_plan_tuitions[6]?.off_campus_expenses_2_manyen,
        )}万円`}
      </Typography>
    ),
    total: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.input_plan_tuitions[6]?.off_campus_expenses_2_manyen +
            item.input_plan_tuitions[6]?.tuition_2_manyen,
        )}万円`}
      </Typography>
    ),
    comperison: (item: any) =>
      isNotEmptyValue(item.input_plan_tuitions[6]?.tuition_2_manyen)
        ? SCHOOL_AGE.graduateSchool2
        : 0,
  },
]

export const TuitionTables: FC<{ openEditModal: (id: string) => void }> = (props) => {
  const { familyStructureChildren } = useSelector(
    (state: RootState) => state.familyStructures,
  )

  return (
    <>
      {familyStructureChildren?.map((child) => {
        const hasTuition =
          !!child.input_plan_tuitions && child.input_plan_tuitions.length > 0

        return (
          <div className="mb-[20px] last:mb-0" key={`child_${child.id}`}>
            <div className="flex justify-between">
              <CircleTitle
                className="pb-[5px]"
                title={`第${child.child_index}子の教育費`}
              />

              <div className="flex justify-end mb-[8px]">
                <Button onClick={() => props.openEditModal(String(child.id))}>
                  <i className="ico edit text-[14px] text-black-700" />
                  <Typography className="pl-[5px] text-[14px] text-black-700">
                    編集
                  </Typography>
                </Button>
              </div>
            </div>

            {hasTuition ? (
              <table className="w-[100%] text-[12px] md:text-[14px]">
                <tbody>
                  <tr className="h-[45px] odd:bg-black-100">
                    <th className="w-[25%] px-[16px] text-left">
                      <Typography isBold>学校</Typography>
                    </th>
                    <th className="w-[15%] px-[16px] text-left">
                      <Typography isBold>学年</Typography>
                    </th>
                    <th className="w-[20%] px-[16px] text-left">
                      <Typography isBold>学費</Typography>
                    </th>
                    <th className="w-[20%] px-[16px] text-left">
                      <Typography isBold>学外費</Typography>
                    </th>
                    <th className="w-[20%] px-[16px] text-left">
                      <Typography isBold>合計額</Typography>
                    </th>
                  </tr>

                  {contentArr.map(
                    (item: any, i: number) =>
                      !(child.is_early_born && i === 3) &&
                      isChildbirthWithinRange(
                        child.birth_day,
                        item.comperison(child),
                      ) && (
                        <tr
                          className="h-[45px] odd:bg-black-100"
                          key={`child_tuition_${child.child_index}_${i}`}
                        >
                          <td className="w-[25%] px-[16px]">{item.school(child)}</td>
                          <td className="w-[15%] px-[16px]">
                            {item.school_grade(child)}
                          </td>
                          <td className="w-[20%] px-[16px]">
                            {item.on_campus_cost(child)}
                          </td>
                          <td className="w-[20%] px-[16px]">
                            {item.off_campus_cost(child)}
                          </td>
                          <td className="w-[20%] px-[16px]">{item.total(child)}</td>
                        </tr>
                      ),
                  )}
                </tbody>
              </table>
            ) : (
              <Typography className="text-[14px] md:text-[16px] text-center" isBold>
                {`第${child.child_index}子の教育費が登録されていません。`}
              </Typography>
            )}
          </div>
        )
      })}
    </>
  )
}

const InputTuitionBody: FC<{ onUpdate: () => void }> = (props) => {
  const [modalState, setModalState] = useState<{ isOpen: boolean; id?: string }>({
    isOpen: false,
  })
  const closeModal = () => setModalState({ isOpen: false })
  const openModal = (id: string) => setModalState({ isOpen: true, id })
  return (
    <>
      <SimulationAccordion className="pb-[16px]" title="現在の教育費">
        <TuitionTables openEditModal={openModal} />
      </SimulationAccordion>
      <Modal
        className="lg:w-[1024px] w-[95%]"
        isOpen={modalState.isOpen}
        onClose={closeModal}
      >
        <CircleTitle className="pb-[32px]" title="現在の教育費" />
        <MyDataTuitionEditForm
          id={modalState.id}
          onClose={closeModal}
          onSubmit={() => {
            closeModal()
            props.onUpdate()
          }}
        />
      </Modal>
    </>
  )
}

export default InputTuitionBody
