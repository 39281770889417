import ApiClient from '@/api/apiClient'
import { FutureIncomeBaseUpdateValues } from '@/models/futures/futureIncomeBasesModel'
import { FutureIncomeChangeUpdateValues } from '@/models/futures/futureIncomeChangesModel'
import {
  FutureIncomeCalcResponse,
  FutureIncomeCreateValues,
  FutureIncomeUpdateValues,
  FutureIncomeDestroyValues,
} from '@/models/futures/futureIncomesModel'

export const postFutureIncomeCalc = async (values: any) => {
  return await ApiClient.post(
    '/future_incomes/calc',
    (response: FutureIncomeCalcResponse) => response,
    values,
  )
}

export const patchFutureIncome = async (values: FutureIncomeUpdateValues) => {
  return await ApiClient.patch('/future_incomes', () => {}, values)
}

export const postFutureIncomeV2 = async (values: FutureIncomeCreateValues) => {
  return await ApiClient.post('/future_incomes', () => {}, values, undefined, 'v2')
}

export const deleteFutureIncomeV2 = async (values: any, id: number) => {
  return await ApiClient.delete(
    `/future_incomes/${id}`,
    () => {},
    values,
    undefined,
    'v2',
  )
}
