import React, { FC, useEffect, useRef } from 'react'
import clsx from 'clsx'
import CrossWhiteIcon from '@/assets/images/v2/cross-white.svg'
import Button from '@/components/v2/atoms/Button'
import { ListenerEvent } from '@/models/commonsModel'
import LoadingOverlayModal from '@/components/v2/atoms/LoadingOverlayModal'

interface ModalProps {
  /** クラス名 */
  className?: string
  /** true: オープン, false: クローズ */
  isOpen: boolean
  /** true: モーダル外クリック無効, false: モーダル外クリック有効 */
  isDisableBackdropClick?: boolean
  /** クローズメソッド */
  onClose: () => void

  /** モーダルメイン部分のクラス指定 */
  modalContainerClassName?: string
  modalOverlayClassName?: string
  /** ローディングフラグ */
  isLoading?: boolean
  /** 全画面表示か */
  isFullModal?: boolean
}

const Modal: FC<ModalProps> = (props) => {
  const {
    className,
    isOpen,
    isDisableBackdropClick,
    onClose,
    children,
    modalContainerClassName,
    modalOverlayClassName,
    isLoading,
    isFullModal,
  } = props
  const modalRef = useRef<HTMLDivElement>(null)

  const handleClickAway = (event: ListenerEvent<HTMLDivElement>) => {
    if (modalRef.current?.contains(event.target) || isDisableBackdropClick) return
    onClose()
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickAway)

    return () => {
      document.removeEventListener('mousedown', handleClickAway)
    }
  }, [])

  return (
    <>
      {isOpen && (
        <>
          <div className="fixed inset-[0] z-[2000]">
            {isFullModal ? (
              <>
                {isLoading && <LoadingOverlayModal isLoading={isLoading} />}
                <div
                  ref={modalRef}
                  className={`flex justify-center items-center w-full h-[100vh] px-[20px] py-[20px] bg-white overflow-y-scroll ${
                    modalContainerClassName || ''
                  }`}
                >
                  {children}
                </div>
              </>
            ) : (
              <>
                <div className={`fixed inset-[0] bg-black-900/50 ${modalOverlayClassName || "" }`} />
                <div
                  className={clsx(
                    className,
                    'fixed top-[40%] md:top-[47%] lg:top-[46%] left-[50%] translate-x-[-50%] translate-y-[-50%] max-h-[65vh]',
                  )}
                >
                  <div className="relative flex justify-end">
                    <Button className="" onClick={onClose}>
                      <CrossWhiteIcon />
                    </Button>
                  </div>
                  {isLoading && <LoadingOverlayModal isLoading={isLoading} />}
                  <div
                    ref={modalRef}
                    className={`relative px-[40px] py-[40px] rounded-[16px] bg-white overflow-y-scroll shadow-modal max-h-[65vh] ${
                      modalContainerClassName || ''
                    }`}
                  >
                    <div className="relative">{children}</div>
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  )
}

export default Modal
