import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import ChevronRightIcon from '@/assets/images/v2/chevron-right.svg'
import UserPrifileIsometricIcon from '@/assets/images/v2/user-prifile-isometric.svg'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import Divider from '@/components/v2/atoms/Divider'
import MarginContainer from '@/components/v2/atoms/MarginContainer'
import Paper from '@/components/v2/atoms/Paper'
import Typography from '@/components/v2/atoms/Typography'
import AlertError from '@/components/v2/molecules/AlertError'
import Breadcrumb from '@/components/v2/molecules/Breadcrumb'
import PaperTitle from '@/components/v2/molecules/PaperTitle'
import SelectBox from '@/components/v2/molecules/SelectBox'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import MyDataGraphIncomeBody from '@/templates/v2/myData/_myDataGraphIncomeBody'
import MyDataGraphSpendingBody from '@/templates/v2/myData/_myDataGraphSpendingBody'
import MyDataPanelBody from '@/templates/v2/myData/_myDataPanelBody'
import JournalPaperIncome from '@/templates/v2/myData/journals/_journalPaperIncome'
import JournalPaperSpending from '@/templates/v2/myData/journals/_journalPaperSpending'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/store'
import {
  convertToTwoDecimalNumber,
  convertYenToDecimalPointManyen,
} from '@/models/commonsModelFunc'
import { fetchJournal, fetchJournalUncategorizedV2 } from '@/containers/journalsSlice'
import moment from 'moment'
import { setPageMeta } from '@/containers/pageMetasSlice'
import {
  SPENDING_KEYS,
  INCOME_KEYS,
  convertCategoryName,
  buildFetchJournalValues,
  JOURNAL_SELECT_START_DATE,
} from '@/models/journalsModelFunc'
import { buildDateMonthSelectItems } from '@/utils/formItem'
import {
  fetchSummaryBudgetDetail,
  fetchSummaryBudgets,
} from '@/containers/summariesSlice'
import LoadingOverlay from '@/components/v1/atoms/LoadingOverlay'
import MonthlyBalanceForm from './_MonthlyBalanceForm'
import Button from '@/components/v2/atoms/Button'
import Modal from '@/components/v2/atoms/Modal'
import { updateAggBalanceMonthlyInputType } from '@/containers/aggBalanceMonthliesSlice'

const Journal: FC = () => {
  const dispatch = useDispatch()
  const { handleMovePage } = useCustomHistory()

  const { uncategorizedMtTrans } = useSelector((state: RootState) => state.journals)
  const { summaryBudgets } = useSelector((state: RootState) => state.summaries)

  // isLoadingFlag
  const isLoadingJournals = useSelector((state: RootState) => state.journals.isLoading)
  const isLoadingJournalsUncategorized = useSelector(
    (state: RootState) => state.journals.isLoadingUncategorized,
  )
  const isLoadingSummaries = useSelector((state: RootState) => state.summaries.isLoading)

  const [currentDateMonth, setCurrentDateMonth] = useState<string>(
    moment().format('YYYY/MM'),
  )
  const [isInputTypeModalOpen, setIsInputTypeModalOpen] = useState<boolean>(false)

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'マイデータ | 仕訳',
        description: 'マイデータ | 仕訳',
        keyword: 'マイデータ | 仕訳',
      }),
    )

    dispatch(fetchSummaryBudgets())
    dispatch(fetchJournal(buildFetchJournalValues(currentDateMonth)))
    dispatch(fetchJournalUncategorizedV2())
  }, [])

  const handleChangeDateMonth = (dateMonth: string) => {
    const values = { params: { year_month: dateMonth } }
    dispatch(fetchSummaryBudgetDetail(values))
    dispatch(fetchJournal(buildFetchJournalValues(dateMonth)))
    setCurrentDateMonth(dateMonth)
  }

  const transferTableData = useMemo(() => {
    return (
      summaryBudgets.budget_detail.transfer?.category_grouped.map((item) => ({
        name: item.category_name,
        category_key: item.category_key,
        amount: `${convertToTwoDecimalNumber(item.amount)}万円 ${
          item.last_term_ratio_percent
            ? '( ' + String(item.last_term_ratio_percent) + '% )'
            : ''
        }`,
        planned_amount: convertToTwoDecimalNumber(item.planned_amount),
        graphs: [{ name: item.category_name, value: item.ratio_percent }],
        journals:
          summaryBudgets.budget_detail.transfer?.all_journals.reduce(
            (arr: any[], obj) => {
              if (obj.category_key == item.category_key) {
                arr.push(obj)
              }
              return arr
            },
            [],
          ) || [],
      })) || []
    )
  }, [summaryBudgets])

  const handleUpdateInputType = () => {
    const newValues = {
      date: currentDateMonth,
      agg_balance_monthly: {
        is_from_moneytree: !summaryBudgets.budget_detail.is_from_moneytree,
      },
    }

    dispatch(
      updateAggBalanceMonthlyInputType(newValues, () => {
        dispatch(fetchSummaryBudgetDetail({ params: { year_month: currentDateMonth } }))
        setIsInputTypeModalOpen(false)
      }),
    )
  }

  return (
    <>
      <LoadingOverlay
        isLoading={
          isLoadingJournals || isLoadingJournalsUncategorized || isLoadingSummaries
        }
      />
      <MarginContainer className="px-8 md:px-32 lg:px-0 py-8 md:py-40">
        <Breadcrumb
          className="pb-32"
          data={[{ name: 'マイデータ', url: '/v2/myData' }, { name: '仕訳の設定' }]}
        />

        <PaperTitle
          className="mb-40"
          title="仕訳の設定"
          description="現状の情報を入力して、可視化していきましょう"
          icon={<UserPrifileIsometricIcon />}
          smallIcon={
            <UserPrifileIsometricIcon
              className="h-[82px] w-[130px]"
              viewBox="0 0 180 146"
            />
          }
        />

        {!!uncategorizedMtTrans &&
          (uncategorizedMtTrans.expense?.length > 0 ||
            uncategorizedMtTrans.income?.length > 0) && (
            <div className="mb-24 mx-40">
              <AlertError
                className="mb-10 last:mb-0"
                message="仕分けを完了してください。"
                hideCloseButton
              />
            </div>
          )}

        <div className="flex gap-x-40 mb-40 grid grid-cols-1 lg:grid-cols-2">
          <div className="col-span-1">
            <JournalPaperSpending
              data={uncategorizedMtTrans?.expense}
              options={SPENDING_KEYS.map((key) => ({
                label: convertCategoryName(key),
                value: key,
              }))}
              journalUpdateCallback={() => handleChangeDateMonth(currentDateMonth)}
            />
          </div>
          <div className="col-span-1">
            <JournalPaperIncome
              data={uncategorizedMtTrans?.income}
              options={INCOME_KEYS.map((key) => ({
                label: convertCategoryName(key),
                value: key,
              }))}
              journalUpdateCallback={() => handleChangeDateMonth(currentDateMonth)}
            />
          </div>
        </div>

        <div className="flex gap-x-24 justify-center mb-40">
          <ButtonPrimary
            className="h-45 w-188"
            onClick={() => handleMovePage('/v2/myData/monthlyCosts')}
          >
            <Typography
              className="bottom-px flex-1 pl-20 relative text-14 text-white"
              isBold
            >
              マイデータの設定
            </Typography>
            <ChevronRightIcon className="mr-17 stroke-[#fff]" />
          </ButtonPrimary>
        </div>

        <Paper className="bg-white p-40">
          <Typography className="pb-10 text-28 text-black" isBold>
            月ごとの仕訳
          </Typography>
          <Divider className="border-black-300 border-t-2 pb-24" />

          <Form
            onSubmit={() => {}}
            initialValues={{ aaa: currentDateMonth }}
            render={({ handleSubmit }) => (
              <form className="pb-36" onSubmit={handleSubmit}>
                <SelectBox
                  name="aaa"
                  widthClassName="w-285 max-w-[100%]"
                  heightClassName="h-45"
                  placeholder="選択してください"
                  onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                    handleChangeDateMonth(e.target.value)
                  }}
                  options={buildDateMonthSelectItems(
                    moment(),
                    moment().diff(JOURNAL_SELECT_START_DATE, 'months') + 1,
                  ).map((item) => ({
                    value: item.value,
                    label: item.label,
                  }))}
                />
              </form>
            )}
          />

          {summaryBudgets.budget_detail?.is_from_moneytree ? (
            <>
              <MyDataPanelBody
                className="pb-80"
                date={`${currentDateMonth.split('/')[0]}年${
                  currentDateMonth.split('/')[1]
                }月`}
                term="single"
              />

              <MyDataGraphIncomeBody
                className="pb-73"
                onJournalChange={() => {
                  dispatch(
                    fetchSummaryBudgetDetail({
                      params: { year_month: currentDateMonth! },
                    }),
                  )
                  dispatch(fetchJournalUncategorizedV2())
                }}
                balanceCurrentMonth={convertYenToDecimalPointManyen(
                  summaryBudgets.current_budgets_info.income_total,
                  1,
                )}
                balanceLastMonth={convertYenToDecimalPointManyen(
                  summaryBudgets.current_budgets_info.income_defference,
                  1,
                )}
                pieGraphData={
                  summaryBudgets.budget_detail.income?.category_grouped.map((item) => ({
                    name: item.category_name,
                    value: item.amount_manyen,
                  })) || []
                }
                pieGraphLegendData={
                  summaryBudgets.budget_detail.income?.category_grouped.map((item) => ({
                    name: item.category_name,
                    value: convertToTwoDecimalNumber(item.amount),
                    percent: Math.round(
                      (item.amount / summaryBudgets.current_budgets_info.income_total) *
                        100,
                    ),
                    color: '#F2994A',
                    journals: {},
                  })) || []
                }
                tableData={
                  summaryBudgets.budget_detail.income?.category_grouped.map((item) => ({
                    name: item.category_name,
                    category_key: item.category_key,
                    amount: `${convertToTwoDecimalNumber(item.amount)}万円 ${
                      item.last_term_ratio_percent
                        ? '( ' + String(item.last_term_ratio_percent) + '% )'
                        : ''
                    }`,
                    planned_amount: convertToTwoDecimalNumber(item.planned_amount),
                    graphs: [{ name: item.category_name, value: item.ratio_percent }],
                    journals:
                      summaryBudgets.budget_detail.income?.all_journals.reduce(
                        (arr: any[], obj) => {
                          if (obj.category_key == item.category_key) {
                            arr.push(obj)
                          }
                          return arr
                        },
                        [],
                      ) || [],
                  })) || []
                }
                term="single"
                transferTableData={transferTableData}
                isJournal={summaryBudgets.budget_detail.is_from_moneytree}
              />

              <MyDataGraphSpendingBody
                className="pb-73"
                onJournalChange={() => {
                  dispatch(
                    fetchSummaryBudgetDetail({
                      params: { year_month: currentDateMonth! },
                    }),
                  )
                }}
                balanceCurrentMonth={convertYenToDecimalPointManyen(
                  summaryBudgets.current_budgets_info.spending_total,
                  1,
                )}
                balanceLastMonth={convertYenToDecimalPointManyen(
                  summaryBudgets.current_budgets_info.spending_defference,
                  1,
                )}
                pieGraphData={
                  summaryBudgets.budget_detail.spending?.category_grouped.map((item) => ({
                    name: item.category_name,
                    value: item.amount_manyen,
                  })) || []
                }
                pieGraphLegendData={
                  summaryBudgets.budget_detail.spending?.category_grouped.map((item) => ({
                    name: item.category_name,
                    value: convertToTwoDecimalNumber(item.amount),
                    percent: Math.round(
                      (item.amount / summaryBudgets.current_budgets_info.spending_total) *
                        100,
                    ),
                    color: '#6F52ED',
                    journals: {},
                  })) || []
                }
                tableData={
                  summaryBudgets.budget_detail.spending?.category_grouped.map((item) => ({
                    name: item.category_name,
                    category_key: item.category_key,
                    amount: `${convertToTwoDecimalNumber(item.amount)}万円 ${
                      item.last_term_ratio_percent
                        ? '( ' + String(item.last_term_ratio_percent) + '% )'
                        : ''
                    }`,
                    planned_amount: convertToTwoDecimalNumber(item.planned_amount),
                    graphs: [{ name: item.category_name, value: item.ratio_percent }],
                    journals:
                      summaryBudgets.budget_detail.spending?.all_journals.reduce(
                        (arr: any[], obj) => {
                          if (obj.category_key == item.category_key) {
                            arr.push(obj)
                          }
                          return arr
                        },
                        [],
                      ) || [],
                  })) || []
                }
                term="single"
                transferTableData={transferTableData}
                isJournal={summaryBudgets.budget_detail.is_from_moneytree}
              />
            </>
          ) : (
            <>
              <MonthlyBalanceForm selectedDate={currentDateMonth} />
            </>
          )}
        </Paper>
        <div className="mt-20 flex justify-end">
          <Button
            className="w-220 h-[26px] px-10 md:h-[37px] mr-[0] md:mr-[9px] rounded-[4px] bg-white border border-secondary-500 hover:bg-secondary-50 ml-auto"
            onClick={() => setIsInputTypeModalOpen(true)}
          >
            <Typography
              className="relative bottom-[1px] text-[12px] md:text-[14px] text-secondary-500"
              isBold
            >
              {summaryBudgets.budget_detail.is_from_moneytree
                ? `表示月の入力は手入力に切り替える`
                : `表示月はMoneytreeからの情報に切り替える`}
            </Typography>
          </Button>
        </div>

        <Modal
          isOpen={isInputTypeModalOpen}
          onClose={() => setIsInputTypeModalOpen(false)}
        >
          <>
            <Typography>
              {summaryBudgets.budget_detail.is_from_moneytree ? (
                <>
                  切り替えた月の仕訳データは手入力のデータに引き継がれません。
                  <br />
                  本当によろしいですか？
                </>
              ) : (
                <>
                  切り替えた月の手入力データは削除されます。
                  <br />
                  本当によろしいですか？
                </>
              )}
            </Typography>
            <div className="my-20">
              <ButtonPrimary
                className="w-[220px] h-[45px] px-10 md:h-[37px] mx-auto"
                onClick={handleUpdateInputType}
              >
                <Typography
                  className="relative bottom-[1px] text-[12px] md:text-[14px] text-white"
                  isBold
                >
                  はい
                </Typography>
              </ButtonPrimary>
              <Button
                className="w-[220px] h-[45px] px-10 md:h-[37px] rounded-[4px] bg-white border border-secondary-500 hover:bg-secondary-50 mx-auto mt-10"
                onClick={() => setIsInputTypeModalOpen(false)}
              >
                <Typography
                  className="relative bottom-[1px] text-[12px] md:text-[14px] text-secondary-500 m-auto"
                  isBold
                >
                  いいえ
                </Typography>
              </Button>
            </div>
          </>
        </Modal>
      </MarginContainer>
    </>
  )
}

export default Journal
