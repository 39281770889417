import React, { FC, useContext, useEffect, useState } from 'react'
import ChevronDownIcon from '@/assets/images/v2/chevron-down-new.svg'
import ChevronUpIcon from '@/assets/images/v2/chevron-up.svg'
import Typography from '@/components/v2/atoms/Typography'
import Tooltip from '@/components/v2/atoms/Tooltip'
import clsx from 'clsx'
import { SimulationAccordionContext } from '@/templates/v2/simulations/_simulationPanelUser'

interface SimulationItemAccordionProps {
  title: string
  isSelected: boolean
  isExpiredSettings?: boolean
  displayExpiredSettingsTips?: boolean
  onSelect: () => void
  inputType: 'radio' | 'checkbox'
  isDisabled?: boolean
  errorMessage?: string
}

const SimulationItemAccordion: FC<SimulationItemAccordionProps> = (props) => {
  const {
    title,
    isSelected,
    onSelect,
    inputType,
    isExpiredSettings,
    displayExpiredSettingsTips,
    children,
    isDisabled,
    errorMessage,
  } = props
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const allAccordionState = useContext(SimulationAccordionContext)
  useEffect(() => {
    if (allAccordionState === 'allOpen' || 'allClose') setIsOpen(false)
  }, [allAccordionState])

  return (
    <div
      className={clsx(
        'py-[8px] px-[12px] border border-solid rounded-[4px]',
        { ['bg-primary-50 border-primary-500']: isSelected },
        { ['border-black-400']: !isSelected },
      )}
    >
      <div className="flex flex-col gap-y-[6px] md:flex-row md:justify-between">
        <div className="flex-none">
          <div onClick={!isDisabled && onSelect} className="cursor-pointer flex items-center">
            <input
              type={inputType}
              checked={isSelected}
              className="w-[20px] h-[20px]"
              disabled={isDisabled}
            ></input>
            <Typography
              className={
                'leading-normal text-[12px] pr-[16px] pl-[8px]' +
                (isSelected ? ' text-primary-500' : ' text-black-600')
              }
              isBold={isSelected}
            >
              {isSelected ? '反映解除' : '反映する'}
            </Typography>
          </div>
        </div>

        <div className="flex-1">
          <div className="flex justify-between">
            <div className="flex flex-col">
              <Typography className="text-[14px] leading-normal text-black-800" isBold>
                {title}
              </Typography>
              {isExpiredSettings && (
                <Tooltip
                  item={
                    <Typography className="leading-normal text-[10px] text-alert-600 text-left">
                      設定に、過去の年齢が含まれています。 <br />
                      編集をクリックし、最新の状態に更新してください。
                    </Typography>
                  }
                  isDispaly={displayExpiredSettingsTips}
                  itemClassName="w-[80px] md:w-[260px] p-[8px] bg-alert-500 before:border-t-alert-500"
                >
                  <div className="flex flex-row items-center">
                    <i className="ico info mr-2 text-16 text-alert-600" />
                    <Typography
                      className={'leading-normal text-[10px] text-alert-600'}
                      isBold
                    >
                      設定を更新してください
                    </Typography>
                  </div>
                </Tooltip>
              )}
              {errorMessage && (
                <Tooltip
                  item={
                    <Typography className="leading-normal text-[10px] text-alert-600 text-left">
                      {errorMessage}
                    </Typography>
                  }
                  itemClassName="w-[80px] md:w-[260px] p-[8px] bg-alert-500 before:border-t-alert-500"
                >
                  <div className="flex flex-row items-center">
                    <i className="ico info mr-2 text-16 text-alert-600" />
                    <Typography
                      className={'leading-normal text-[10px] text-alert-600'}
                      isBold
                    >
                      設定を更新するか、削除して再作成してください。
                    </Typography>
                  </div>
                </Tooltip>
              )}
            </div>

            <div
              onClick={() => setIsOpen(!isOpen)}
              className="cursor-pointer flex items-center"
            >
              <Typography className="leading-normal text-[12px] text-black-600 pr-[4px]">
                詳細
              </Typography>
              {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
            </div>
          </div>
        </div>
      </div>
      {isOpen && <div className="p-[16px]">{children}</div>}
    </div>
  )
}

export default SimulationItemAccordion
