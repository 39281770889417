import React, { FC } from 'react'
import { ResponsiveContainer, BarChart, AxisDomain, ReferenceLine } from 'recharts'
import { isUndefined as _isUndefined } from 'lodash'
import renderCustomXAxis from '@/components/v2/molecules/renderCustomXAxis'
import renderCustomYAxis from '@/components/v2/molecules/renderCustomYAxis'

interface GraphBarProps {
  /** クラス名 */
  className?: string
  /** 高さ */
  height: number
  /** true: 縦, false: 横 */
  isVertical?: boolean
  /** x軸_データキー */
  xDataKey?: string
  /** x軸_基準値 */
  xStandardValue?: number
  /** x軸_領域 */
  xDomain?: [AxisDomain, AxisDomain]
  /** true: x軸非表示, false: x軸表示 */
  isXHide?: boolean
  /** y軸_データキー */
  yDataKey?: string
  /** true: y軸非表示, false: y軸表示 */
  isYHide?: boolean
  /** データ */
  data: { [key: string]: any }[]
}

const GraphBar: FC<GraphBarProps> = (props) => {
  const {
    className,
    height,
    isVertical,
    xDataKey,
    xStandardValue,
    xDomain,
    isXHide,
    yDataKey,
    isYHide,
    data,
    children,
  } = props

  return (
    <ResponsiveContainer className={className} height={height}>
      <BarChart
        layout={isVertical ? 'vertical' : 'horizontal'}
        data={data}
        stackOffset="sign"
      >
        {children as any}
        {renderCustomXAxis({
          dataKey: xDataKey ?? '',
          isVertical,
          isHide: isXHide,
          domain: xDomain,
        })}
        {renderCustomYAxis({
          dataKey: yDataKey ?? '',
          isVertical,
          isHide: isYHide,
        })}
        {!_isUndefined(xStandardValue) && (
          <ReferenceLine x={xStandardValue} stroke="#696969" />
        )}
      </BarChart>
    </ResponsiveContainer>
  )
}

export default GraphBar
