// ねんきん定期便所持種別
export const AT_HAND_PENSION_PAPER_TYPE = {
  yes: 'yes',
  no: 'no',
}

// ねんきん定期便種別
export const INPUT_TYPE = {
  other: 'other',
  overFifty: 'over_fifty',
}
