import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { unauthorizedCheck } from '../accountsSlice'
import {
  postInputMonthlyCost,
  patchInputMonthlyCost,
  deleteInputMonthlyCost,
  getInputMonthlyCostV2,
} from '../../api/inputs/inputMonthlyCosts'
import { ApiError } from '../../models/commonsModel'
import {
  InputMonthlyCost,
  InputMonthlyCostCreateValues,
  InputMonthlyCostUpdateValues,
  InputMonthlyCostDestroyValues,
} from '../../models/inputs/inputMonthlyCostsModel'
import { AppThunk } from '../../store'

interface InputMonthlyCostState {
  inputMonthlyCost: InputMonthlyCost | null
  isLoading: boolean
}

const initialState: InputMonthlyCostState = {
  inputMonthlyCost: null,
  isLoading: false,
}

const inputMonthlyCostsSlice = createSlice({
  name: 'inputMonthlyCosts',
  initialState,
  reducers: {
    setInputMonthlyCost: (state, action: PayloadAction<InputMonthlyCost | null>) => {
      state.inputMonthlyCost = action.payload
      return state
    },
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    resetInputMonthlyCostsStore: (state) => {
      state = initialState
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
  },
})

export const {
  setInputMonthlyCost,
  requestStart,
  resetInputMonthlyCostsStore,
  requestSuccess,
  requestFailure,
} = inputMonthlyCostsSlice.actions
export const inputMonthlyCostsReducer = inputMonthlyCostsSlice.reducer

export const createInputMonthlyCost = (
  values: InputMonthlyCostCreateValues,
  callback: () => void,
  resolve: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postInputMonthlyCost(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error, resolve))
    })
}

export const updateInputMonthlyCost = (
  values: InputMonthlyCostUpdateValues,
  callback: () => void,
  resolve: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchInputMonthlyCost(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error, resolve))
    })
}

export const destroyInputMonthlyCost = (
  values: { params: InputMonthlyCostDestroyValues },
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  deleteInputMonthlyCost(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const fetchInputMonthlyCostV2 = (callback?: () => void): AppThunk => async (
  dispatch,
) => {
  dispatch(requestStart())
  getInputMonthlyCostV2()
    .then((response: any) => {
      dispatch(requestSuccess())
      dispatch(setInputMonthlyCost(response))
      callback && callback()
    })
    .catch((error: any) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}
