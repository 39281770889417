export const spendingColorArr = [
  '#6F52ED',
  '#9985F1',
  '#BEB4E9',
  '#E5E0FB',
  '#E5E0FB',
  '#E5E0FB',
  '#E5E0FB',
  '#E5E0FB',
  '#E5E0FB',
  '#E5E0FB',
]
export const incomeColorArr = [
  '#F2994A',
  '#FFD375',
  '#FFF5D4',
  '#FFF5D4',
  '#FFF5D4',
  '#FFF5D4',
  '#FFF5D4',
  '#FFF5D4',
  '#FFF5D4',
  '#FFF5D4',
]
