import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { isEmpty as _isEmpty, isNull as _isNull, sortBy as _sortBy } from 'lodash'
import { unauthorizedCheck } from '../accountsSlice'
import {
  getInputHousings,
  postInputHousingTransfer,
  patchInputHousingTransfer,
  patchInputHousingRent,
  postInputHousingRent,
  postInputHousingSelfOwned,
  patchInputHousingSelfOwned,
  deleteInputHousing,
  getHousingLoanPaymentSummary,
} from '@/api/inputs/inputHousings'
import { ApiError } from '@/models/commonsModel'
import { castNumberWithoutOperator } from '@/models/commonsModelFunc'
import {
  HousingLoanPaymentSummary,
  InputHousing,
  InputHousingDestroyValues,
  InputHousingOption,
} from '@/models/inputs/inputHousingsModel'
import {
  InputHousingRentCreateValues,
  InputHousingRentUpdateValues,
} from '@/models/inputs/inputHousingRentsModel'
import {
  InputHousingSelfOwnedCreateValues,
  InputHousingSelfOwnedUpdateValues,
} from '@/models/inputs/inputHousingSelfOwnedModel'
import {
  InputHousingTransferCreateValues,
  InputHousingTransferUpdateValues,
} from '@/models/inputs/inputHousingTransfersModel'
import { InputLoanSelectItem } from '@/models/inputs/inputLoansModel'
import { AppThunk } from '@/store'

interface InputHousingState {
  inputHousings: InputHousing[]
  inputLoans: InputLoanSelectItem[]
  inputHousingOptions: InputHousingOption[]
  monthlyHousingLoanPayment: number | null
  yearlyHousingLoanPayment: number | null
  isLoading: boolean
}

const initialState: InputHousingState = {
  inputHousings: [],
  inputLoans: [],
  inputHousingOptions: [],
  monthlyHousingLoanPayment: 0,
  yearlyHousingLoanPayment: 0,
  isLoading: false,
}

const inputHousingsSlice = createSlice({
  name: 'inputHousings',
  initialState,
  reducers: {
    setInputHousings: (state, action: PayloadAction<InputHousing[]>) => {
      const { payload } = action

      state.inputHousings = payload.map((item) => {
        return _isNull(item.input_housing_self_owned)
          ? {
              ...item,
              model_type: 'InputHousing',
            }
          : {
              ...item,
              model_type: 'InputHousing',
              input_housing_self_owned: {
                ...item.input_housing_self_owned!,
                repair_costs_span:
                  castNumberWithoutOperator(
                    item.input_housing_self_owned!.repair_costs_span,
                  ) / 12,
                input_loans: _isEmpty(item.input_housing_self_owned!.input_loans)
                  ? []
                  : _sortBy(item.input_housing_self_owned!.input_loans, 'id'),
              },
            }
      })

      return state
    },
    setInputLoans: (state, action: PayloadAction<InputLoanSelectItem[]>) => {
      state.inputLoans = action.payload
      return state
    },
    setInputHousingOptions: (state, action: PayloadAction<InputHousingOption[]>) => {
      state.inputHousingOptions = action.payload
    },
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    resetInputHousingsStore: (state) => {
      state = initialState
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    getInputHousingsSuccess: (state, action: PayloadAction<InputHousing[]>) => {
      state.inputHousings = action.payload.map((item) =>
        _isNull(item.input_housing_self_owned)
          ? {
              ...item,
              model_type: 'InputHousing',
            }
          : {
              ...item,
              model_type: 'InputHousing',
              input_housing_self_owned: {
                ...item.input_housing_self_owned,
                repair_costs_span:
                  castNumberWithoutOperator(
                    item.input_housing_self_owned!.repair_costs_span,
                  ) / 12,
                input_loans: _isEmpty(item.input_housing_self_owned!.input_loans)
                  ? []
                  : _sortBy(item.input_housing_self_owned!.input_loans, 'id'),
              },
            },
      )
      state.isLoading = false
      return state
    },
    getHousingLoanPaymentSummarySuccess: (
      state,
      action: PayloadAction<HousingLoanPaymentSummary>,
    ) => {
      state.monthlyHousingLoanPayment = action.payload.monthly_housing_loan_payment
      state.yearlyHousingLoanPayment = action.payload.yearly_housing_loan_payment
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
  },
})

export const {
  setInputHousings,
  setInputLoans,
  setInputHousingOptions,
  requestStart,
  resetInputHousingsStore,
  requestSuccess,
  getInputHousingsSuccess,
  getHousingLoanPaymentSummarySuccess,
  requestFailure,
} = inputHousingsSlice.actions
export const inputHousingsReducer = inputHousingsSlice.reducer

export const fetchInputHousings = (): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  getInputHousings()
    .then((response) => dispatch(getInputHousingsSuccess(response)))
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const fetchHousingLoanPaymentSummary = (): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  getHousingLoanPaymentSummary()
    .then((response) => dispatch(getHousingLoanPaymentSummarySuccess(response)))
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const createInputHousingRent = (
  values: InputHousingRentCreateValues,
  callback: () => void,
  resolve: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postInputHousingRent(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error, resolve))
    })
}

export const createInputHousingSelfOwned = (
  values: InputHousingSelfOwnedCreateValues,
  callback: () => void,
  resolve: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postInputHousingSelfOwned(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error, resolve))
    })
}

export const createInputHousingTransfer = (
  values: InputHousingTransferCreateValues,
  callback: () => void,
  resolve: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postInputHousingTransfer(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error, resolve))
    })
}

export const updateInputHousingRent = (
  values: InputHousingRentUpdateValues,
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchInputHousingRent(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const updateInputHousingSelfOwned = (
  values: InputHousingSelfOwnedUpdateValues,
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchInputHousingSelfOwned(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const updateInputHousingTransfer = (
  values: InputHousingTransferUpdateValues,
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchInputHousingTransfer(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const destroyInputHousing = (
  values: { params: InputHousingDestroyValues },
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  deleteInputHousing(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}
