import { sumBy as _sumBy, split as _split } from 'lodash'
import { InputMonthlyCostCommonValues } from './inputMonthlyCostsModel'
import { castNumberWithoutOperator } from '../commonsModelFunc'

export const INPUT_MONTHLY_COST_SUM_FIELDS = [
  'input_monthly_cost.food_expenses_monthly',
  'input_monthly_cost.utility_bills_monthly',
  'input_monthly_cost.daily_necessities_cost_monthly',
  'input_monthly_cost.transport_monthly',
  'input_monthly_cost.clothing_cost_monthly',
  'input_monthly_cost.hobby_expenses_monthly',
  'input_monthly_cost.communication_cost_monthly',
  'input_monthly_cost.entertainment_expenses_monthly',
  'input_monthly_cost.pocket_money_monthly',
  'input_monthly_cost.unknown_cost_monthly',
  'input_monthly_cost.medical_expenses_monthly',
]

export const sumInputMonthlyCost = (
  values: InputMonthlyCostCommonValues | { [key: string]: any },
) => {
  const { input_monthly_cost } = values
  const {
    input_scholarships_attributes,
    input_monthly_cost_others_attributes,
  } = values.input_monthly_cost

  const monthlyCost = _sumBy(INPUT_MONTHLY_COST_SUM_FIELDS, (item) => {
    const key = _split(item, '.')[1]
    return castNumberWithoutOperator(input_monthly_cost[key])
  })
  const scholarshipCost = _sumBy(input_scholarships_attributes, (item: any) =>
    castNumberWithoutOperator(item.scholarship_monthly),
  )
  const otherCost = _sumBy(input_monthly_cost_others_attributes, (item: any) =>
    castNumberWithoutOperator(item.cost_monthly),
  )

  return monthlyCost + scholarshipCost + otherCost
}

export const convertMoneytreeAverageMonths = (value: any) => {
  switch (Number(value)) {
    case 1:
      return '過去1ヶ月分の平均'
    case 3:
      return '過去3ヶ月分の平均'
    case 6:
      return '過去6ヶ月分の平均'
    case 12:
      return '過去1年分の平均'
    case 24:
      return '過去2年分の平均'
    case 36:
      return '過去3年分の平均'
    default:
      return '過去3ヶ月の平均'
  }
}
