import React, { FC, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import clsx from 'clsx'
import { isNull as _isNull } from 'lodash'
import Typography from '@/components/v2/atoms/Typography'
import { INPUT_ASSET_TYPE_SELECT_ITEMS } from '@/constants/formItem/inputs/inputAssets'
import { convertLocaleString, defaultToEmptyString } from '@/models/commonsModelFunc'
import { AssetType, InputAsset } from '@/models/inputs/inputAssetsModel'
import {
  hasSummaryOf,
  filterInputAsset,
  convertInputAssetType,
  convertInputAssetPersonFlg,
  convertAccountType,
  convertRolloverType,
  ACCOUNT_TYPE,
  isInvestmentAssetType,
} from '@/models/inputs/inputAssetsModelFunc'
import { RootState } from '@/store'
import SimulationAccordion from '@/templates/v2/simulations/_simulationAccordion'
import Modal from '@/components/v2/atoms/Modal'
import Button from '@/components/v2/atoms/Button'
import SimulationNewButton from '@/templates/v2/simulations/_simulationNewButton'
import AssetForm from '@/templates/v2/myData/assets/Form'
import CircleTitle from '@/components/v2/molecules/CircleTitle'

const classes = {
  sp: {
    itemBlock: 'flex items-center space-x-12 text-14',
  },
}

export const AssetTable: FC<{ onUpdate?: () => void }> = (props) => {
  const { inputAssetsSummaryList } = useSelector((state: RootState) => state.inputAssets)
  const [modalState, setModalState] = useState<{ isOpen: boolean; id?: number }>({
    isOpen: false,
  })

  const inputAssetArr = useMemo(() => {
    if (_isNull(inputAssetsSummaryList)) return []

    return [...inputAssetsSummaryList?.input_assets]
  }, [inputAssetsSummaryList])

  const inputAssetTableRowSp = (inputAsset: InputAsset) => (
    <div className="flex flex-col pb-24 space-y-16 border-b border-black-300">
      <div className="flex justify-between">
        <Typography className="text-16 break-all" isBold>
          {defaultToEmptyString(inputAsset?.institution_name) +
            defaultToEmptyString(inputAsset?.current_name)}
        </Typography>
        <div className="flex justify-end mb-[8px]">
          <Button onClick={() => setModalState({ isOpen: true, id: inputAsset.id })}>
            <i className="ico edit text-[14px] text-black-700" />
            <Typography className="pl-[5px] text-[14px] text-black-700">編集</Typography>
          </Button>
        </div>
      </div>
      <div className={classes.sp.itemBlock}>
        <Typography isBold>残高</Typography>
        <Typography
          className={clsx('flex-1', {
            'text-alert-600': (inputAsset?.current_value ?? 0) < 0,
          })}
        >
          {convertLocaleString(inputAsset?.current_value)}円
        </Typography>
      </div>
      <div className={classes.sp.itemBlock}>
        <Typography isBold>種別</Typography>
        <Typography>{convertInputAssetType(inputAsset?.asset_type, true)}</Typography>
      </div>
      {isInvestmentAssetType(inputAsset?.asset_type) && (
        <div className={classes.sp.itemBlock}>
          <Typography isBold>口座種類</Typography>
          <Typography>{convertAccountType(inputAsset?.account_type)}</Typography>
        </div>
      )}
      <div className={classes.sp.itemBlock}>
        <Typography isBold>名義人</Typography>
        <Typography>
          {convertInputAssetPersonFlg(
            inputAsset.person_flg as number | null,
            inputAsset.family_structure_child?.child_index,
          )}
        </Typography>
      </div>
      <div className={classes.sp.itemBlock}>
        <Typography isBold>想定利回り</Typography>
        <Typography>{`${defaultToEmptyString(
          inputAsset.interest_rate_yearly_percent,
        )}%`}</Typography>
      </div>
      {isInvestmentAssetType(inputAsset?.asset_type) && (
        <>
          <div className={classes.sp.itemBlock}>
            <Typography isBold>積立額</Typography>
            <Typography>{`${convertLocaleString(
              inputAsset.reserved_amount_monthly,
            )}円/月`}</Typography>
          </div>
          {inputAsset?.account_type == ACCOUNT_TYPE.nisa && (
            <div className={classes.sp.itemBlock}>
              <Typography isBold>ロールオーバー</Typography>
              <Typography>{convertRolloverType(inputAsset.rollover_type)}</Typography>
            </div>
          )}
        </>
      )}
    </div>
  )
  return (
    <>
      {_isNull(inputAssetsSummaryList) ||
        (inputAssetsSummaryList.input_assets.length === 0 && (
          <Typography className="text-[14px] md:text-[16px] text-center" isBold>
            金融資産が登録されていません。
          </Typography>
        ))}

      {!_isNull(inputAssetsSummaryList) &&
        inputAssetsSummaryList.input_assets.length > 0 && (
          <div className="flex flex-col justify-between mb-20 p-16 rounded-4 bg-secondary-50">
            <Typography className="text-18" isBold>
              金融資産総額
            </Typography>
            <div className="flex items-baseline ml-auto mr-0">
              <Typography className="text-30" isBold>
                {convertLocaleString(
                  inputAssetsSummaryList?.financial_asset_total_amount,
                )}
              </Typography>
              <Typography className="pl-5 text-16" isBold>
                円
              </Typography>
            </div>
          </div>
        )}

      {!_isNull(inputAssetsSummaryList) &&
        inputAssetsSummaryList.input_assets.length > 0 && (
          <div>
            {INPUT_ASSET_TYPE_SELECT_ITEMS.map((item, index) => (
              <React.Fragment key={index}>
                {hasSummaryOf(item?.value as AssetType, inputAssetArr) && (
                  <>
                    <div className="flex justify-between mb-16 px-5 py-8 bg-black-300">
                      <Typography className="text-16" isBold>
                        {convertInputAssetType(item?.value as AssetType, true)}
                      </Typography>
                      <div className="flex items-center space-x-12 text-14">
                        <Typography isBold>総額</Typography>
                        <Typography className="flex-1">
                          {convertLocaleString(
                            inputAssetsSummaryList?.summary?.[item?.value as AssetType]
                              ?.total_amount,
                          )}
                          円
                        </Typography>
                      </div>
                    </div>
                    <div className="space-y-24">
                      {filterInputAsset(item?.value as AssetType, inputAssetArr).map(
                        (inputAsset, assetIndex) => (
                          <React.Fragment key={assetIndex}>
                            {inputAssetTableRowSp(inputAsset)}
                          </React.Fragment>
                        ),
                      )}
                    </div>
                  </>
                )}
              </React.Fragment>
            ))}
          </div>
        )}

      <SimulationNewButton onClick={() => setModalState({ isOpen: true })} />

      <Modal
        className="lg:w-[1024px] w-[95%]"
        isOpen={modalState.isOpen}
        onClose={() => setModalState({ isOpen: false })}
      >
        <CircleTitle className="pb-[32px]" title="現在の金融資産" />
        <AssetForm
          id={modalState.id}
          onClose={() => setModalState({ isOpen: false })}
          onSubmit={() => {
            setModalState({ isOpen: false })
            if (props.onUpdate != null) props.onUpdate()
          }}
        />
      </Modal>
    </>
  )
}

const InputAssetBody: FC<{ onUpdate?: () => void }> = (props) => {
  return (
    <SimulationAccordion className="pb-[16px]" title="現在の金融資産">
      <AssetTable onUpdate={props.onUpdate} />
    </SimulationAccordion>
  )
}

export default InputAssetBody
