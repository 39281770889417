import ApiClient from '@/api/apiClient'
import {
  LearningPlatformUserResponse,
  LearningPlatformUserCreateValues,
} from '@/models/videoPlatforms/learningPlatformUsersModel'

export const postLearningPlatformUser = async (
  values: LearningPlatformUserCreateValues,
) => {
  return await ApiClient.post(
    '/learning_platform_users',
    (response: LearningPlatformUserResponse) => response,
    values,
  )
}
