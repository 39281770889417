import React, { ChangeEvent, FC, useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'react-final-form'
import clsx from 'clsx'
import moment from 'moment'
import {
  reduce as _reduce,
  filter as _filter,
  times as _times,
  each as _each,
  isNull as _isNull,
  isUndefined as _isUndefined,
  includes as _includes,
  set as _set,
  pick as _pick,
  find as _find,
} from 'lodash'
import ChevronRightIcon from '@/assets/images/v2/chevron-right-small-gray.svg'
import ChevronUpIcon from '@/assets/images/v2/chevron-up-small-gray.svg'
import DataAnalyzingIcon from '@/assets/images/v2/data-analyzing.svg'
import UserPrifileIsometricIcon from '@/assets/images/v2/user-prifile-isometric.svg'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import ButtonTertiary from '@/components/v2/atoms/ButtonTertiary'
import MarginContainer from '@/components/v2/atoms/MarginContainer'
import Paper from '@/components/v2/atoms/Paper'
import Typography from '@/components/v2/atoms/Typography'
import Breadcrumb from '@/components/v2/molecules/Breadcrumb'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import GraphComposed from '@/components/v2/molecules/GraphComposed'
import PaperTitle from '@/components/v2/molecules/PaperTitle'
import renderCustomBar from '@/components/v2/molecules/renderCustomBar'
import renderCustomLine from '@/components/v2/molecules/renderCustomLine'
import SelectBox, { SelectBoxOption } from '@/components/v2/molecules/SelectBox'
import SelectBoxCalendar from '@/components/v2/molecules/SelectBoxCalendar'
import SelectBoxWithoutFinalForm from '@/components/v2/molecules/SelectBoxWithoutFinalForm'
import Tab from '@/components/v2/molecules/Tab'
import { setPageMeta } from '@/containers/pageMetasSlice'
import {
  fetchSummaryBudgetDetail,
  fetchSummaryBudgets,
  fetchSummaryTransitions,
  fetchSummaryBalancesSearchV2,
  createSummaryTransitionSearch,
  fetchSummaryTuitions,
} from '@/containers/summariesSlice'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import { useRechartsActiveInfo } from '@/hooks/useRecharts'
import {
  convertToTwoDecimalNumber,
  convertYenToDecimalPointManyen,
  isNotEmptyValue,
  convertYenToManyen,
  TERM,
  termLabel,
  INSURANCE_GRAPH_SET_TYPE,
  INSURANCE_FINANCIAL_TYPE,
} from '@/models/commonsModelFunc'
import { RootState } from '@/store'
import { buildDateMonthSelectItems } from '@/utils/formItem'
import MyDataGraphIncomeBody from '@/templates/v2/myData/_myDataGraphIncomeBody'
import MyDataGraphSpendingBody from '@/templates/v2/myData/_myDataGraphSpendingBody'
import MyDataPanelBody from '@/templates/v2/myData/_myDataPanelBody'
import TooltipBalance from '@/templates/v2/myData/_tooltipBalance'
import TooltipTransitionAsset from '@/templates/v2/myData/_tooltipTransitionAsset'
import TooltipRisk from '@/templates/v2/simulations/_tooltipRisk'
import { fetchAccount } from '@/containers/accountsSlice'
import { fetchFamilyStructureV2 } from '@/containers/familyStructuresSlice'
import Modal from '@/components/v2/atoms/Modal'
import { incomeColorArr, spendingColorArr } from '@/utils/graphColors'
import LoadingOverlay from '@/components/v1/atoms/LoadingOverlay'
import { createMtAccessTokenDisconnect } from '@/containers/mtAccessTokensSlice'
import { createMyDataDetailUnsavedFutureBase } from '@/containers/futures/futureBasesSlice'
import TooltipTuition from '@/templates/v2/myData/_tooltipTuition'
import TooltipBalanceMyData from '@/templates/v2/simulations/_tooltipBalance'
import TooltipInvestment from '@/templates/v2/simulations/_tooltipInvestment'
import TooltipNetAsset from '@/templates/v2/simulations/_tooltipNetAsset'
import { PartiallyPartial, TableColumnData } from '@/models/commonsModel'
import Button from '@/components/v2/atoms/Button'
import { afterDate, required } from '@/utils/validate'
import ValidMessage from '@/components/v2/atoms/ValidMessage'
import {
  JOURNAL_SELECT_START_DATE,
  JOURNAL_SELECT_START_MONTH,
} from '@/models/journalsModelFunc'
import Tooltip from '@/components/v2/atoms/Tooltip'
import TooltipTransitionNetAsset from '@/templates/v2/myData/_tooltipTransitionNetAsset'
import { fetchInputRisk } from '@/containers/inputs/inputRisksSlice'

const MY_DATA_TAB_INDEX = { balance: 0, transition: 1, future: 2 }
const MY_DATA_ASSET_TAB_INDEX = { investment: 0, netAsset: 1 }
const MY_DATA_AFTER_RETIREMENT_TAB_INDEX = { balance: 0, income: 1, spending: 2 }

export const NESTED_TABLE_COLORS: any[] = [
  {
    even: {
      color: '#DDF1F1',
      border: '#8DCDCF',
    },
    odd: { color: '#FFF8FD', border: '#DAC4E0' },
  },
  {
    even: { color: '#FAE7E2', border: '#F3CDDE' },
    odd: { color: '#DAECF5', border: '#ADD6F0' },
  },
  {
    even: { color: '#FFFAE4', border: '#FDE998' },
    odd: { color: '#FFF4E7', border: '#FBCAA1' },
  },
]

export const ESTATE_EVALUATION_COLOR = [
  '#DDE283',
  '#f4f9a2',
  '#FAFCDA',
  '#F4F6C2',
  '#FAFCDA',
  '#FAFCDA',
  '#FAFCDA',
  '#FAFCDA',
  '#FAFCDA',
  '#FAFCDA',
]
export const ESTATE_REMAIN_LOANS_COLOR = [
  '#ADD6F0',
  '#c6e5f9',
  '#EBF7FF',
  '#D6EFFF',
  '#EBF7FF',
  '#EBF7FF',
  '#EBF7FF',
  '#EBF7FF',
  '#EBF7FF',
  '#EBF7FF',
]

const MyDataIndex: FC = () => {
  const dispatch = useDispatch()
  const thWidthClassName = 'max-w-[55px] min-w-[55px] hover-bg-8fb6ff'
  const classes = {
    columnDataTable: {
      table: 'border-separate text-[11px]',
      th: `pr-[5px] ${thWidthClassName} h-[20px] text-right box-border border-b border-r border-black-400 bg-white text-[13px] p-0`,
      td:
        'sticky left-0 max-w-[130px] md:max-w-[270px] min-w-[130px] md:min-w-[270px] h-[20px] items-center box-border z-[10] bg-white pl-[10px] border-b border-l border-r-[2px] border-black-400',
    },
  }

  const { handleMovePage } = useCustomHistory()

  const customDateArray = buildDateMonthSelectItems(
    moment(),
    moment().diff(JOURNAL_SELECT_START_DATE, 'months') + 1,
  ).map((item) => ({
    value: item.value,
    label: item.label,
  }))

  const thisMonth = moment().format('YYYY/MM')

  const [
    activeMonthlyBalanceInfo,
    setActiveMonthlyBalanceInfo,
    resetActiveMonthlyBalanceInfo,
  ] = useRechartsActiveInfo()
  const [
    activeAssetInfo,
    setActiveAssetInfo,
    resetActiveAssetInfo,
  ] = useRechartsActiveInfo()
  const [
    activeTransitionNetAssetInfo,
    setActiveTransitionNetAssetInfo,
    resetActiveTransitionNetAssetInfo,
  ] = useRechartsActiveInfo()
  const [
    activeInvestmentInfo,
    setActiveInvestmentInfo,
    resetActiveInvestmentInfo,
  ] = useRechartsActiveInfo()
  const [
    activeNetAssetInfo,
    setActiveNetAssetInfo,
    resetActiveNetAssetInfo,
  ] = useRechartsActiveInfo()
  const [
    activeTuitionInfo,
    setActiveTuitionInfo,
    resetActiveTuitionInfo,
  ] = useRechartsActiveInfo()
  const [
    activeBalanceInfo,
    setActiveBalanceInfo,
    resetActiveBalanceInfo,
  ] = useRechartsActiveInfo()
  const [
    activeInsuranceInfo,
    setActiveInsuranceInfo,
    resetActiveInsuranceInfo,
  ] = useRechartsActiveInfo()

  const [collapseStatus, setCollapseStatus] = useState<{ [key: string]: boolean }>({
    asset_total: true,
    financial_asset_total: true,
    net_asset_total: true,
  })
  const [breakpoint, setBreakpoint] = useState<string>('lg')
  const [selectedAge, setSelectedAge] = useState<number | ''>('')
  const [currentTabIndex, setCurrentTabIndex] = useState<number>(
    MY_DATA_TAB_INDEX.balance,
  )
  const [currentAssetTabIndex, setCurrentAssetTabIndex] = useState<number>(
    MY_DATA_ASSET_TAB_INDEX.investment,
  )
  const [currentAfterRetirementTabIndex, setCurrentAfterRetirementTabIndex] = useState<
    number
  >(MY_DATA_AFTER_RETIREMENT_TAB_INDEX.balance)

  const [currentDateMonth, setCurrentDateMonth] = useState<string>(thisMonth)
  const [currentTransitionDateMonth, setCurrentTransitionDateMonth] = useState<string>(
    thisMonth,
  )
  const [journalTerm, setJournalTerm] = useState<string>(TERM.single)
  const [journalStartDate, setJournalStartDate] = useState<string>(
    JOURNAL_SELECT_START_MONTH,
  )
  const [journalEndDate, setJournalEndDate] = useState<string>(thisMonth)
  const [transitionTerm, setTransitionTerm] = useState<string>(TERM.single)
  const [transitionStartDate, setTransitionStartDate] = useState<string>(
    JOURNAL_SELECT_START_MONTH,
  )
  const [transitionEndDate, setTransitionEndDate] = useState<string>(thisMonth)
  const [dateTitle, setDateTitle] = useState<string>(moment().format('YYYY年MM月'))
  const [isShowInsurance, setIsShowInsurance] = useState<boolean>(false)
  const [currentCellId, setCurrentCellId] = useState<string>('')

  const {
    summaryBudgets,
    summaryTransitions: { balance_graph, asset_graph, net_asset_graph },
    summaryTuitions: { child_count, graph },
  } = useSelector((state: RootState) => state.summaries)
  const { account } = useSelector((state: RootState) => state.accounts)
  const { familyInfoV2 } = useSelector((state: RootState) => state.familyStructures)
  const { myDataDetailFutureBaseV2 } = useSelector(
    (state: RootState) => state.futureBases,
  )

  // Loading Flag
  const isLoadingSummaries = useSelector((state: RootState) => state.summaries.isLoading)
  const isLoadingTransition = useSelector(
    (state: RootState) => state.summaries.isLoadingTransition,
  )
  const isLoadingAccounts = useSelector((state: RootState) => state.accounts.isLoading)
  const isLoadingFamilyStructures = useSelector(
    (state: RootState) => state.familyStructures.isLoading,
  )
  const isGetDetailFutureBaseLoading = useSelector(
    (state: RootState) => state.futureBases.isGetDetailFutureBaseLoading,
  )

  const balanceGraph = useMemo(() => {
    return (
      balance_graph?.map((obj: any) => {
        let newObj = { ...obj }
        newObj.date = obj.date.replace('-', '/')
        return newObj
      }) || []
    )
  }, [balance_graph])

  const assetGraph = useMemo(() => {
    return (
      asset_graph?.map((obj: any) => {
        let newObj = { ...obj }
        newObj.date = obj.date.replace('-', '/')
        return newObj
      }) || []
    )
  }, [asset_graph])

  const netAssetGraph = useMemo(() => {
    return (
      net_asset_graph?.map((obj: any) => {
        let newObj = { ...obj }
        newObj.date = obj.date.replace('-', '/')
        return newObj
      }) || []
    )
  }, [net_asset_graph])

  const ageOptions = useMemo(
    () =>
      _reduce(
        myDataDetailFutureBaseV2.v2_investment_graph,
        (result: SelectBoxOption[], item) => {
          if (item.age % 10 === 0) {
            result.push({ value: item.age, label: `${item.age}歳まで` })
          }
          return result
        },
        [],
      ),
    [myDataDetailFutureBaseV2],
  )

  const futureGraphs = useMemo(() => {
    const isSelectAge = selectedAge !== ''
    const {
      input_v2_investment_graph,
      input_net_asset_graph,
      input_v2_balance_graph,
      family_info,
      input_insurance_graph,
    } = myDataDetailFutureBaseV2

    return {
      inputV2InvestmentGraph: isSelectAge
        ? _filter(input_v2_investment_graph, (item: any) => item.age <= selectedAge)
        : input_v2_investment_graph,
      inputNetAssetGraph: isSelectAge
        ? _filter(input_net_asset_graph, (item: any) => item.age <= selectedAge)
        : input_net_asset_graph,
      tuitionGraph: isSelectAge
        ? _filter(
            graph,
            (item: any) =>
              item.age <= selectedAge && family_info?.person_current_age <= item.age,
          )
        : _filter(graph, (item: any) => family_info?.person_current_age <= item.age),
      inputV2BalanceGraph: isSelectAge
        ? _filter(
            input_v2_balance_graph,
            (item: any) =>
              item.age <= selectedAge && family_info?.person_current_age <= item.age,
          )
        : _filter(input_v2_balance_graph, (item: any) => item.age),
      inputInsuranceGraph: isSelectAge
        ? _filter(input_insurance_graph, (item: any) => item.age <= selectedAge)
        : input_insurance_graph,
    }
  }, [selectedAge, myDataDetailFutureBaseV2, graph])

  const afterRetirementTable = useMemo(() => {
    const filterAge = selectedAge !== '' ? selectedAge : 999
    const {
      family_structure_table_columns,
      input_statement_table_data,
      input_statement_table_insurance_data,
    } = myDataDetailFutureBaseV2
    const familyTableData = input_statement_table_data
      ? Object.keys(input_statement_table_data).reduce((acc: any, year: any) => {
          acc[year] = _pick(
            input_statement_table_data[year],
            family_structure_table_columns.map((obj: any) => obj.key),
          )
          return acc
        }, {})
      : {}

    return {
      familyStructureTableData: _reduce(
        familyTableData,
        (result: any, values: any, key: any) => {
          if (values.person_age <= filterAge) {
            result[key] = values
          }
          return result
        },
        {},
      ),
      statementTableData: _reduce(
        input_statement_table_data,
        (result: any, values: any, key: any) => {
          if (values.person_age <= filterAge) {
            result[key] = values
          }
          return result
        },
        {},
      ),
      inputStatementTableInsuranceData: _reduce(
        input_statement_table_insurance_data,
        (result: any, values: any, key: any) => {
          if (values.person_age <= filterAge) {
            result[key] = values
          }
          return result
        },
        {},
      ),
    }
  }, [selectedAge, myDataDetailFutureBaseV2])

  const handleWindowResize = () => {
    let breakpoint = ''

    if (window.innerWidth >= 1024) {
      breakpoint = 'lg'
    } else if (window.innerWidth >= 768) {
      breakpoint = 'md'
    } else {
      breakpoint = 'sm'
    }

    setBreakpoint(breakpoint)
  }

  const handleClickAway = () => {
    resetActiveMonthlyBalanceInfo()
    resetActiveAssetInfo()
    resetActiveTransitionNetAssetInfo()
    resetActiveInvestmentInfo()
    resetActiveNetAssetInfo()
    resetActiveTuitionInfo()
    resetActiveBalanceInfo()
    resetActiveInsuranceInfo()
  }

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'マイデータ',
        description: 'マイデータ',
        keyword: 'マイデータ',
      }),
    )

    dispatch(fetchSummaryBudgets())
    if (balance_graph.length == 0) dispatch(fetchSummaryTransitions())
    if ((account?.id ?? 0) < 1) dispatch(fetchAccount())
    if (!familyInfoV2) dispatch(fetchFamilyStructureV2())
    if (graph.length == 0) dispatch(fetchSummaryTuitions())
    if (Object.keys(myDataDetailFutureBaseV2).length == 0) {
      dispatch(
        fetchInputRisk((inputRisks) => {
          let insuranceParams = {
            insurance_type: 'person',
            insurance_financial_type: INSURANCE_FINANCIAL_TYPE.not_include,
          }
          let inputRisk = _find(inputRisks, { person_flg: 0 }) || null

          if (!_isNull(inputRisk)) {
            insuranceParams.insurance_financial_type =
              inputRisk.includes_financial_asset == 0
                ? INSURANCE_FINANCIAL_TYPE.include
                : INSURANCE_FINANCIAL_TYPE.not_include
          } else {
            inputRisk = _find(inputRisks, { person_flg: 1 }) || null
            if (!_isNull(inputRisk)) {
              insuranceParams.insurance_type = 'spouse'
              insuranceParams.insurance_financial_type =
                inputRisk.includes_financial_asset == 0
                  ? INSURANCE_FINANCIAL_TYPE.include
                  : INSURANCE_FINANCIAL_TYPE.not_include
            }
          }

          // // MEMO: 初期データの読み込み時は、保険を指定せずに保存されているデータを取得する
          // return dispatch(createMyDataDetailUnsavedFutureBase(insuranceParams))
          return dispatch(createMyDataDetailUnsavedFutureBase())
        }),
      )
    }

    handleWindowResize()
    window.addEventListener('resize', handleWindowResize)
    document.addEventListener('mousedown', handleClickAway)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
      document.removeEventListener('mousedown', handleClickAway)
    }
  }, [])

  useEffect(() => {
    const personAge = familyInfoV2?.person_age

    if (personAge) {
      const minimumRenderingYears = 40
      let targetAge = 80
      let renderingYears = targetAge - personAge

      if (renderingYears < minimumRenderingYears) {
        // 1の位を四捨五入する
        targetAge = Math.ceil((personAge + minimumRenderingYears) / 10) * 10
        // 上限は110歳
        targetAge = Math.min(targetAge, 110)
      }

      setSelectedAge(targetAge)
    }
  }, [familyInfoV2])

  useEffect(() => {
    if (!isNotEmptyValue(myDataDetailFutureBaseV2?.family_structure_table_columns)) return
    const trElementHeight = 20

    _each(
      myDataDetailFutureBaseV2?.family_structure_table_columns,
      (item, index: number) => {
        const trElement = document.getElementById(item.key)
        if (_isNull(trElement)) return

        trElement.style.top = `${index * trElementHeight}px`
      },
    )
  }, [myDataDetailFutureBaseV2, currentTabIndex])

  const tableData = isShowInsurance
    ? [afterRetirementTable.inputStatementTableInsuranceData]
    : [afterRetirementTable.statementTableData]

  const columnData = isShowInsurance
    ? [myDataDetailFutureBaseV2?.statement_table_insurance_columns]
    : [myDataDetailFutureBaseV2?.statement_table_columns]

  const boldKeys = [
    'income_total',
    'spending_total',
    'saving_amount',
    'financial_asset_total',
  ]

  const getNestedKey = (index: number) => {
    return index % 2 === 0 ? 'even' : 'odd'
  }

  const renderDataLabel = (data: any) => {
    if (_isUndefined(data)) return 0
    return convertYenToManyen(data, 'ceil')
  }

  const handleMoveMyData = () => {
    if (Object.keys(familyInfoV2!).length > 0) {
      handleMovePage('/v2/myData/families')
    } else {
      handleMovePage('/v2/myData/simple')
    }
  }

  const renderTableCell = (
    item: PartiallyPartial<TableColumnData, 'index' | 'nested_columns'>,
    nestedKeys: string[],
    isRoot: boolean,
    parentIndex: number,
    tableData: any,
  ) => {
    const hasNestedColumn = !_isUndefined(item.nested_columns)
    const endIndex = nestedKeys.length - 1
    const parentNestedKeys = nestedKeys.slice(0, -1)
    const currentColorItem = NESTED_TABLE_COLORS?.[endIndex]?.[nestedKeys[endIndex]]

    const renderDisplayName = () => {
      const standardLength = endIndex === 0 ? 8 : endIndex === 1 ? 6 : 4
      const isEllipsis = item.display_name.length > standardLength && breakpoint === 'sm'
      const displayName = isEllipsis
        ? item.display_name.substring(0, standardLength)
        : item.display_name

      return (
        <>
          <Typography
            className={clsx({
              'font-bold': !_isUndefined(boldKeys)
                ? _includes(boldKeys, item.key)
                : isRoot || hasNestedColumn,
              ['pl-26']: !hasNestedColumn,
            })}
          >
            {displayName}
          </Typography>
          {isEllipsis && (
            <Tooltip
              item={item.display_name}
              itemClassName="w-[max-content] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
            >
              <Typography>...</Typography>
            </Tooltip>
          )}
        </>
      )
    }

    return (
      <React.Fragment key={item.key}>
        {collapseStatus[item.key] && (
          <>
            {item.nested_columns?.map((item, index) =>
              renderTableCell(
                item,
                !_isUndefined(item.nested_columns)
                  ? [...nestedKeys, getNestedKey(index)]
                  : nestedKeys,
                false,
                parentIndex,
                tableData,
              ),
            )}
          </>
        )}
        <tr>
          <td className={`${classes.columnDataTable.td} p-0 first:pl-0 z-[40]`}>
            <div className="flex h-full">
              {parentNestedKeys.map((key, index) => (
                <div
                  key={index}
                  className="min-w-[20px] md:min-w-[30px]"
                  style={{
                    backgroundColor: NESTED_TABLE_COLORS?.[index]?.[key]?.color,
                    borderLeft: `3px solid ${NESTED_TABLE_COLORS?.[index]?.[key]?.border}`,
                  }}
                />
              ))}
              {!hasNestedColumn && (
                <div
                  className="min-w-[20px] md:min-w-[30px]"
                  style={{
                    backgroundColor: currentColorItem?.color,
                    borderLeft: `3px solid ${currentColorItem?.border}`,
                  }}
                />
              )}
              <div
                className="flex w-full items-center"
                style={{
                  backgroundColor: hasNestedColumn ? currentColorItem?.color : '#ffffff',
                  borderLeft: hasNestedColumn
                    ? `3px solid ${currentColorItem?.border}`
                    : '',
                }}
              >
                {hasNestedColumn && (
                  <Button
                    className="mx-[5px]"
                    onClick={() =>
                      setCollapseStatus({
                        ...collapseStatus,
                        [item.key]: !collapseStatus[item.key],
                      })
                    }
                  >
                    {collapseStatus[item.key] ? <ChevronUpIcon /> : <ChevronRightIcon />}
                  </Button>
                )}
                {renderDisplayName()}
              </div>
            </div>
          </td>
          {Object.keys(tableData?.[parentIndex]).map((year) => {
            const cellId = `${item.key}_${year}`
            return (
              <td
                key={year}
                className={`${classes.columnDataTable.th} last:border-r odd:bg-black-100`}
                style={{
                  borderRight: '1px dashed #C6C6C6',
                  backgroundColor: hasNestedColumn ? currentColorItem?.color : '#ffffff',
                  border: currentCellId == cellId ? '2px solid #0071ff' : '',
                }}
                id={cellId}
                onClick={() => setCurrentCellId(cellId)}
              >
                <Typography
                  className={clsx({
                    'text-alert-600':
                      !_isUndefined(tableData?.[parentIndex][Number(year)][item.key]) &&
                      tableData?.[parentIndex][Number(year)][item.key] < 0,
                  })}
                >
                  {renderDataLabel(tableData?.[parentIndex][Number(year)][item.key])}
                </Typography>
              </td>
            )
          })}
        </tr>
      </React.Fragment>
    )
  }

  const setGraphWidth = () => {
    return breakpoint === 'sm' ? 1280 : undefined
  }

  const setGraphHeight = () => {
    return breakpoint === 'lg' ? 400 : 300
  }

  const setTooltipPosition = (activeInfo: any, graph: any[]) => {
    const y = 0
    const x =
      graph?.length / 2 < activeInfo.activeIndex ? activeInfo.x - 250 : activeInfo.x + 20

    return { x, y }
  }

  const handleChangeAge = (age: number) => {
    setSelectedAge(age)
    resetActiveMonthlyBalanceInfo()
    resetActiveAssetInfo()
    resetActiveTransitionNetAssetInfo()
    resetActiveInvestmentInfo()
    resetActiveNetAssetInfo()
    resetActiveTuitionInfo()
    resetActiveBalanceInfo()
    resetActiveInsuranceInfo()
  }

  const handleClickLine = (
    payload: any,
    setActiveInfo: (item: any, index: number) => void,
  ) => {
    setActiveInfo({ ...payload.payload, x: payload.cx, y: payload.cy }, payload.index)
  }

  // 保険グラフ設定切り替え
  const handleSubmitInsurance = (values: any) => {
    dispatch(
      createMyDataDetailUnsavedFutureBase({
        insurance_type: values.insurance_type,
        insurance_financial_type: values.insurance_financial_type,
      }),
    )
    return
  }

  const handleSubmitJournal = (values: any) => {
    const { term, start_date, end_date, custom_month_date } = values

    setJournalTerm(term)
    setJournalStartDate(start_date)
    setJournalEndDate(end_date)
    setCurrentDateMonth(custom_month_date)

    handleUpdateJournal(start_date, end_date, term)
  }

  const handleSubmitTransition = (values: any) => {
    const {
      transition_term,
      transition_start_date,
      transition_end_date,
      transition_custom_month_date,
    } = values

    setCurrentTransitionDateMonth(transition_custom_month_date)
    setTransitionStartDate(transition_start_date)
    setTransitionEndDate(transition_end_date)
    setTransitionTerm(transition_term)

    handleUpdateTransition(transition_start_date, transition_end_date, transition_term)
  }

  const handleUpdateJournal = (start_date: string, end_date: string, term: string) => {
    // 今月
    if (term == TERM.single) {
      const values = { params: { year_month: thisMonth } }
      setDateTitle(`${thisMonth.split('/')[0]}年${thisMonth.split('/')[1]}月`)
      dispatch(fetchSummaryBudgetDetail(values))
      return
    }

    // 今月から過去1年間
    if (term == TERM.past_one_year) {
      const values = {
        start_date: moment(`${thisMonth}/01`)
          .subtract(1, 'years')
          .add(1, 'months')
          .format('YYYY/MM/DD'),
        end_date: moment(`${thisMonth}/01`).format('YYYY/MM/DD'),
      }
      setDateTitle(
        `${thisMonth.split('/')[0]}年${thisMonth.split('/')[1]}月から過去1年間`,
      )
      dispatch(fetchSummaryBalancesSearchV2(values))
      return
    }

    // 今年
    if (term == TERM.this_year) {
      const end_date = moment().endOf('year')
      const start_date = moment().startOf('year')
      setDateTitle(`今年`)

      const values = {
        start_date: start_date.format('YYYY/MM/DD'),
        end_date: end_date.format('YYYY/MM/DD'),
      }
      dispatch(fetchSummaryBalancesSearchV2(values))
      return
    }

    // カスタム（単月）
    if (term == TERM.custom_month) {
      const values = { params: { year_month: currentDateMonth } }
      setDateTitle(
        `${currentDateMonth.split('/')[0]}年${currentDateMonth.split('/')[1]}月`,
      )
      dispatch(fetchSummaryBudgetDetail(values))
      return
    }

    // カスタム（期間）
    if (term == TERM.custom_date) {
      setDateTitle(
        `${start_date.split('/')[0]}年${start_date.split('/')[1]}月 ~ ${
          end_date.split('/')[0]
        }年${end_date.split('/')[1]}月`,
      )

      const values = {
        start_date: `${start_date}/01`,
        end_date: `${end_date}/01`,
      }
      dispatch(fetchSummaryBalancesSearchV2(values))
      return
    }
  }

  const handleUpdateTransition = (start_date: string, end_date: string, term: string) => {
    // 今月
    if (term == TERM.single) {
      const values = {
        start_date: moment(thisMonth).format('YYYY/MM/DD'),
        end_date: moment(thisMonth).format('YYYY/MM/DD'),
      }
      dispatch(createSummaryTransitionSearch(values))
      return
    }

    // 今月から過去1年間
    if (term == TERM.past_one_year) {
      const values = {
        start_date: moment(`${thisMonth}/01`)
          .subtract(1, 'years')
          .add(1, 'months')
          .format('YYYY/MM/DD'),
        end_date: moment(`${thisMonth}/01`).format('YYYY/MM/DD'),
      }
      dispatch(createSummaryTransitionSearch(values))
      return
    }

    // 今年
    if (term == TERM.this_year) {
      const values = {
        start_date: moment().startOf('year').format('YYYY/MM/DD'),
        end_date: moment().endOf('year').format('YYYY/MM/DD'),
      }

      dispatch(createSummaryTransitionSearch(values))
      return
    }

    // カスタム(単月)
    if (term == TERM.custom_month) {
      const values = {
        start_date: moment(currentTransitionDateMonth).format('YYYY/MM/DD'),
        end_date: moment(currentTransitionDateMonth).format('YYYY/MM/DD'),
      }
      dispatch(createSummaryTransitionSearch(values))
      return
    }

    // カスタム（期間）
    if (term == TERM.custom_date) {
      const values = {
        start_date: moment(`${start_date}/01`).format('YYYY/MM/DD'),
        end_date: moment(`${end_date}/01`).format('YYYY/MM/DD'),
      }
      dispatch(createSummaryTransitionSearch(values))
      return
    }
  }

  const openMoneytreeConnection = () => {
    dispatch(
      createMtAccessTokenDisconnect(() => {
        handleMovePage('/v2/plans/moneytreeConnection')
      }),
    )
  }

  const handleClickBalanceLine = (payload: any) => {
    setActiveMonthlyBalanceInfo(
      { ...payload.payload, x: payload.cx, y: payload.cy },
      payload.index,
    )
  }

  const handleClickAssetLine = (payload: any) => {
    setActiveAssetInfo(
      { ...payload.payload, x: payload.cx, y: payload.cy },
      payload.index,
    )
  }

  const handleClickNetAssetLine = (payload: any) => {
    setActiveTransitionNetAssetInfo(
      { ...payload.payload, x: payload.cx, y: payload.cy },
      payload.index,
    )
  }

  const transferTableData = useMemo(() => {
    return (
      summaryBudgets.budget_detail.transfer?.category_grouped.map((item) => ({
        name: item.category_name,
        category_key: item.category_key,
        amount: `${convertToTwoDecimalNumber(item.amount)}万円 ${
          item.last_term_ratio_percent
            ? '( ' + String(item.last_term_ratio_percent) + '% )'
            : ''
        }`,
        planned_amount: convertToTwoDecimalNumber(item.planned_amount),
        graphs: [{ name: item.category_name, value: item.ratio_percent }],
        journals:
          summaryBudgets.budget_detail.transfer?.all_journals.reduce(
            (arr: any[], obj) => {
              if (obj.category_key == item.category_key) {
                arr.push(obj)
              }
              return arr
            },
            [],
          ) || [],
      })) || []
    )
  }, [summaryBudgets])

  const riskFinancialTypePerson = useMemo(
    () => myDataDetailFutureBaseV2.mydata_risk_financial_type_person,
    [myDataDetailFutureBaseV2],
  )

  const riskFinancialTypeSpouse = useMemo(
    () => myDataDetailFutureBaseV2.mydata_risk_financial_type_spouse,
    [myDataDetailFutureBaseV2],
  )

  const canShowInsuranceGraph = useMemo(() => {
    return !_isNull(riskFinancialTypePerson) || !_isNull(riskFinancialTypeSpouse)
  }, [riskFinancialTypePerson, riskFinancialTypeSpouse])

  const insuranceGraphSetTypes = useMemo(() => {
    let setTypes = []

    if (!_isNull(riskFinancialTypePerson)) {
      setTypes.push({
        value: INSURANCE_GRAPH_SET_TYPE.person,
        label: 'ご本人設定',
      })
    }
    if (!_isNull(riskFinancialTypeSpouse)) {
      setTypes.push({
        value: INSURANCE_GRAPH_SET_TYPE.spouse,
        label: '配偶者設定',
      })
    }
    return setTypes
  }, [riskFinancialTypePerson, riskFinancialTypeSpouse])

  const getGraphSetTypeInitialValue = useMemo(() => {
    if (!_isNull(riskFinancialTypePerson)) return INSURANCE_GRAPH_SET_TYPE.person
    return INSURANCE_GRAPH_SET_TYPE.spouse
  }, [riskFinancialTypePerson, riskFinancialTypeSpouse])

  const getRiskFinancialTypeInitialValue = useMemo(() => {
    if (!_isNull(riskFinancialTypePerson)) {
      return riskFinancialTypePerson == 0
        ? INSURANCE_FINANCIAL_TYPE.include
        : INSURANCE_FINANCIAL_TYPE.not_include
    }
    return riskFinancialTypeSpouse == 0
      ? INSURANCE_FINANCIAL_TYPE.include
      : INSURANCE_FINANCIAL_TYPE.not_include
  }, [riskFinancialTypePerson, riskFinancialTypeSpouse])

  return (
    <>
      <MarginContainer className="px-8 md:px-32 lg:px-0 py-8 md:py-40">
        <Breadcrumb className="pb-32" data={[{ name: 'マイデータ' }]} />

        <PaperTitle
          className="mb-40"
          title="マイデータ"
          description="現状の情報を入力して、可視化していきましょう"
          icon={<UserPrifileIsometricIcon />}
          smallIcon={
            <UserPrifileIsometricIcon
              className="h-[82px] w-[130px]"
              viewBox="0 0 180 146"
            />
          }
        />

        <div className="flex-none md:flex md:flex-wrap gap-x-48 mb-40 relative">
          <LoadingOverlay isLoading={isLoadingAccounts || isLoadingFamilyStructures} />

          <Paper className="bg-cream-500 flex flex-1 mb-20 md:mb-0 p-14">
            <div className="flex-1">
              <CircleTitle
                className="pb-16"
                classNameTitle="text-20 md:text-24"
                title="AIFPからのコメント"
              />
              {account?.['has_connected_with_moneytree?'] ? (
                <>
                  <Typography className="text-12 text-black-900">
                    AI:
                    マイデータの入力ありがとうございます！さらに詳しく入力すると詳細サマリーが出来上がります。
                  </Typography>
                </>
              ) : (
                <>
                  <Typography className="text-12 text-black-900">
                    AI:
                    マイデータの入力ありがとうございます！さらに詳しく入力すると詳細サマリーが出来上がります。マネーツリーの連携もぜひチャレンジしてみてください☆
                  </Typography>
                </>
              )}
            </div>
            <div className="flex items-center">
              <DataAnalyzingIcon />
            </div>
          </Paper>

          <div className="flex-auto md:flex-none">
            <ButtonPrimary
              className="h-45 mb-12 w-full md:w-188"
              onClick={() =>
                account?.['has_connected_with_moneytree?']
                  ? handleMovePage('/v2/myData/journals')
                  : handleMovePage('/v2/myData/journals/monthlyBalance')
              }
            >
              <Typography className="flex-1 pl-20 text-14 text-white" isBold>
                月ごとの収支登録
              </Typography>
              <ChevronRightIcon className="mr-17" />
            </ButtonPrimary>

            <ButtonPrimary
              className="h-45 mb-12 w-full md:w-188"
              onClick={() => {
                handleMoveMyData()
              }}
            >
              <Typography className="flex-1 pl-20 text-14 text-white" isBold>
                マイデータ設定
              </Typography>
              <ChevronRightIcon className="mr-17" />
            </ButtonPrimary>

            {!account?.['has_connected_with_moneytree?'] && (
              <ButtonTertiary
                className="h-45 w-full md:w-188"
                onClick={openMoneytreeConnection}
              >
                <Typography className="flex-1 pl-20 text-14 text-white" isBold>
                  Moneytree連携
                </Typography>
                <ChevronRightIcon className="mr-17" />
              </ButtonTertiary>
            )}
          </div>
        </div>

        <Paper className="bg-white p-[20px] md:p-40">
          <Tab
            className="h-50 mb-[10px]"
            titleClassName="text-[15px] md:text-[18px]"
            currentIndex={currentTabIndex}
            options={['収支サマリー', '推移', '未来予想']}
            onClick={(index) => setCurrentTabIndex(index)}
          />

          {currentTabIndex === MY_DATA_TAB_INDEX.balance && (
            <div className="relative pt-[30px]">
              <LoadingOverlay isLoading={isLoadingSummaries} />

              <Form
                onSubmit={handleSubmitJournal}
                initialValues={{
                  start_date: journalStartDate,
                  end_date: journalEndDate,
                  term: journalTerm,
                  custom_month_date: currentDateMonth,
                }}
                validate={(values) => {
                  const validateMessage = {}
                  const { term, start_date, end_date } = values
                  const startDate = `${start_date}/01`
                  const endDate = `${end_date}/01`
                  if (term === TERM.custom_date) {
                    if (!_isUndefined(afterDate(endDate, startDate))) {
                      _set(validateMessage, `end_date`, afterDate(endDate, startDate))
                    }
                  }
                  return validateMessage
                }}
                render={({ handleSubmit, values, errors }) => {
                  const { term } = values

                  return (
                    <>
                      <form
                        className={clsx('md:flex pb-36', {
                          ['hidden']: currentTabIndex === MY_DATA_TAB_INDEX.future,
                        })}
                        onSubmit={handleSubmit}
                      >
                        <SelectBoxCalendar
                          isFullWidth
                          name="term"
                          widthClassName="md:w-[175px]"
                          heightClassName="h-34"
                          placeholder="期間表示設定"
                          options={[
                            { value: TERM.single, label: '今月' },
                            { value: TERM.this_year, label: '今年' },
                            { value: TERM.past_one_year, label: '今月から過去１年間' },
                            { value: TERM.custom_month, label: 'カスタム（単月）' },
                            { value: TERM.custom_date, label: 'カスタム（期間）' },
                          ]}
                        />
                        {term === TERM.custom_month && (
                          <>
                            <div className="flex items-center">
                              <SelectBox
                                isFullWidth
                                name="custom_month_date"
                                className="md:ml-16 mt-[16px] md:mt-0"
                                widthClassName="md:w-[120px]"
                                heightClassName="h-34"
                                placeholder="選択してください"
                                options={customDateArray}
                                validate={required}
                                disableValidationMessage
                              />
                            </div>
                          </>
                        )}
                        {term === TERM.custom_date && (
                          <>
                            <div className="flex items-center mt-[16px] md:mt-0">
                              <SelectBox
                                isFullWidth
                                name="start_date"
                                className="md:ml-16"
                                widthClassName="md:w-[120px]"
                                heightClassName="h-34"
                                placeholder="選択してください"
                                options={customDateArray}
                              />
                              <Typography className="mx-[10px]" isBold>
                                〜
                              </Typography>
                              <SelectBox
                                isFullWidth
                                name="end_date"
                                className=""
                                widthClassName="md:w-[120px]"
                                heightClassName="h-34"
                                placeholder="選択してください"
                                options={customDateArray}
                                disableValidationMessage
                              />
                            </div>
                          </>
                        )}

                        <ButtonPrimary
                          className="md:ml-[16px] mt-[16px] md:mt-0 w-full md:max-w-[110px] h-[32px]"
                          isDisabled={Object.keys(errors).length > 0}
                          onClick={() => handleSubmit(values)}
                        >
                          <Typography
                            className="relative bottom-[2px] px-[15px] text-[14px] text-white flex-auto items-center"
                            isBold
                          >
                            表示する
                          </Typography>
                        </ButtonPrimary>
                      </form>
                      <div className="relative top-[-25px] md:left-[211px] left-[87px]">
                        <ValidMessage
                          meta={{
                            touched: true,
                            error: errors.end_date,
                          }}
                        />
                      </div>
                    </>
                  )
                }}
              />

              <MyDataPanelBody className="pb-80" date={dateTitle} term={journalTerm} />
              <MyDataGraphIncomeBody
                className="pb-73"
                onJournalChange={() => {
                  handleUpdateJournal(journalStartDate, journalEndDate, journalTerm)
                }}
                balanceCurrentMonth={convertYenToDecimalPointManyen(
                  summaryBudgets.current_budgets_info.income_total,
                  1,
                )}
                balanceLastMonth={convertYenToDecimalPointManyen(
                  summaryBudgets.current_budgets_info.income_defference,
                  1,
                )}
                pieGraphData={
                  summaryBudgets.budget_detail.income?.category_grouped.map((item) => ({
                    name: item.category_name,
                    value: item.amount_manyen,
                  })) || []
                }
                pieGraphLegendData={
                  summaryBudgets.budget_detail.income?.category_grouped.map(
                    (item: any, index: number) => ({
                      name: item.category_name,
                      value: convertToTwoDecimalNumber(item.amount),
                      percent: Math.round(
                        (item.amount / summaryBudgets.current_budgets_info.income_total) *
                          100,
                      ),
                      color: incomeColorArr[index],
                      journals: {},
                    }),
                  ) || []
                }
                tableData={
                  summaryBudgets.budget_detail.income?.category_grouped.map((item) => ({
                    name: item.category_name,
                    category_key: item.category_key,
                    amount: `${convertToTwoDecimalNumber(item.amount)}万円 ${
                      item.last_term_ratio_percent
                        ? '( ' + String(item.last_term_ratio_percent) + '% )'
                        : ''
                    }`,
                    planned_amount: convertToTwoDecimalNumber(item.planned_amount),
                    graphs: [{ name: item.category_name, value: item.ratio_percent }],
                    journals:
                      summaryBudgets.budget_detail.income?.all_journals.reduce(
                        (arr: any[], obj) => {
                          if (obj.category_key == item.category_key) {
                            arr.push(obj)
                          }
                          return arr
                        },
                        [],
                      ) || [],
                  })) || []
                }
                transferTableData={transferTableData}
                isJournal={summaryBudgets.budget_detail.is_from_moneytree}
                term={journalTerm}
              />

              <MyDataGraphSpendingBody
                className="pb-73"
                onJournalChange={() => {
                  handleUpdateJournal(journalStartDate, journalEndDate, journalTerm)
                }}
                balanceCurrentMonth={convertYenToDecimalPointManyen(
                  summaryBudgets.current_budgets_info.spending_total,
                  1,
                )}
                balanceLastMonth={convertYenToDecimalPointManyen(
                  summaryBudgets.current_budgets_info.spending_defference,
                  1,
                )}
                pieGraphData={
                  summaryBudgets.budget_detail.spending?.category_grouped.map((item) => ({
                    name: item.category_name,
                    value: item.amount_manyen,
                  })) || []
                }
                pieGraphLegendData={
                  summaryBudgets.budget_detail.spending?.category_grouped.map(
                    (item: any, index: number) => ({
                      name: item.category_name,
                      value: convertToTwoDecimalNumber(item.amount),
                      percent: Math.round(
                        (item.amount /
                          summaryBudgets.current_budgets_info.spending_total) *
                          100,
                      ),
                      color: spendingColorArr[index],
                      journals: {},
                    }),
                  ) || []
                }
                tableData={
                  summaryBudgets.budget_detail.spending?.category_grouped.map((item) => ({
                    name: item.category_name,
                    category_key: item.category_key,
                    amount: `${convertToTwoDecimalNumber(item.amount)}万円 ${
                      item.last_term_ratio_percent
                        ? '( ' + String(item.last_term_ratio_percent) + '% )'
                        : ''
                    }`,
                    planned_amount: convertToTwoDecimalNumber(item.planned_amount),
                    graphs: [{ name: item.category_name, value: item.ratio_percent }],
                    journals:
                      summaryBudgets.budget_detail.spending?.all_journals.reduce(
                        (arr: any[], obj) => {
                          if (obj.category_key == item.category_key) {
                            arr.push(obj)
                          }
                          return arr
                        },
                        [],
                      ) || [],
                  })) || []
                }
                transferTableData={transferTableData}
                isJournal={summaryBudgets.budget_detail.is_from_moneytree}
                term={journalTerm}
              />
            </div>
          )}

          {currentTabIndex === MY_DATA_TAB_INDEX.transition && (
            <div className="relative pt-[30px]">
              <LoadingOverlay isLoading={isLoadingTransition} />
              {account?.['has_connected_with_moneytree?'] ? (
                <>
                  <Form
                    onSubmit={handleSubmitTransition}
                    initialValues={{
                      transition_start_date: transitionStartDate,
                      transition_end_date: transitionEndDate,
                      transition_term: transitionTerm,
                      transition_custom_month_date: currentTransitionDateMonth,
                    }}
                    validate={(values) => {
                      const validateMessage = {}
                      const {
                        transition_term,
                        transition_start_date,
                        transition_end_date,
                      } = values
                      const startDate = `${transition_start_date}/01`
                      const endDate = `${transition_end_date}/01`
                      if (transition_term === TERM.custom_date) {
                        if (!_isUndefined(afterDate(endDate, startDate))) {
                          _set(
                            validateMessage,
                            `transition_end_date`,
                            afterDate(endDate, startDate),
                          )
                        }
                      }
                      return validateMessage
                    }}
                    render={({ handleSubmit, values, errors }) => {
                      const { transition_term } = values
                      return (
                        <>
                          <form className="md:flex pb-36" onSubmit={handleSubmit}>
                            <SelectBoxCalendar
                              isFullWidth
                              name="transition_term"
                              widthClassName="md:w-[175px]"
                              heightClassName="h-34"
                              placeholder="期間表示設定"
                              options={[
                                { value: TERM.single, label: '今月' },
                                { value: TERM.this_year, label: '今年' },
                                {
                                  value: TERM.past_one_year,
                                  label: '今月から過去１年間',
                                },
                                { value: TERM.custom_month, label: 'カスタム（単月）' },
                                { value: TERM.custom_date, label: 'カスタム（期間）' },
                              ]}
                            />
                            {transition_term === TERM.custom_month && (
                              <>
                                <div className="flex items-center">
                                  <SelectBox
                                    isFullWidth
                                    name="transition_custom_month_date"
                                    className="md:ml-16 mt-[16px] md:mt-0"
                                    widthClassName="md:w-[120px]"
                                    heightClassName="h-34"
                                    placeholder="選択してください"
                                    options={customDateArray}
                                    validate={required}
                                    disableValidationMessage
                                  />
                                </div>
                              </>
                            )}
                            {transition_term === TERM.custom_date && (
                              <>
                                <div className="flex items-center mt-[16px] md:mt-0">
                                  <SelectBox
                                    isFullWidth
                                    name="transition_start_date"
                                    className="md:ml-16"
                                    widthClassName="md:w-[120px]"
                                    heightClassName="h-34"
                                    placeholder="選択してください"
                                    options={customDateArray}
                                  />
                                  <Typography className="mx-[10px]" isBold>
                                    〜
                                  </Typography>
                                  <SelectBox
                                    isFullWidth
                                    name="transition_end_date"
                                    className=""
                                    widthClassName="md:w-[120px]"
                                    heightClassName="h-34"
                                    placeholder="選択してください"
                                    options={customDateArray}
                                    disableValidationMessage
                                  />
                                </div>
                              </>
                            )}
                            <ButtonPrimary
                              className="md:ml-[16px] mt-[16px] md:mt-0 w-full md:max-w-[110px] h-[32px]"
                              isDisabled={Object.keys(errors).length > 0}
                              onClick={() => handleSubmit(values)}
                            >
                              <Typography
                                className="relative bottom-[2px] px-[15px] text-[14px] text-white flex-auto items-center"
                                isBold
                              >
                                表示する
                              </Typography>
                            </ButtonPrimary>
                          </form>
                          <div className="relative top-[-25px] md:left-[211px] left-[87px]">
                            <ValidMessage
                              meta={{
                                touched: true,
                                error: errors.transition_end_date,
                              }}
                            />
                          </div>
                        </>
                      )
                    }}
                  />

                  <div className="pb-57">
                    <CircleTitle
                      classNameTitle="text-20 md:text-24"
                      className="pb-16"
                      title={`収支`}
                    />
                    <div className="overflow-x-scroll md:overflow-x-visible">
                      <GraphComposed
                        id="balance-graph"
                        width={setGraphWidth()}
                        height={setGraphHeight()}
                        xDataKey="date"
                        data={balanceGraph}
                        tooltipHide={breakpoint === 'sm'}
                        tooltipPosition={{
                          x: setTooltipPosition(activeMonthlyBalanceInfo, balanceGraph).x,
                          y: setTooltipPosition(activeMonthlyBalanceInfo, balanceGraph).y,
                        }}
                        tooltipContent={
                          <TooltipBalance
                            activeIndex={activeMonthlyBalanceInfo.activeIndex}
                          />
                        }
                      >
                        {renderCustomBar({
                          name: '収入',
                          dataKey: 'income_total_manyen',
                          activeIndex: activeMonthlyBalanceInfo.activeIndex,
                          color: '#FFD375',
                          data: balanceGraph,
                          onClick: setActiveMonthlyBalanceInfo,
                        })}
                        {renderCustomBar({
                          name: '支出',
                          dataKey: 'spending_total_manyen',
                          activeIndex: activeMonthlyBalanceInfo.activeIndex,
                          color: '#9682F1',
                          data: balanceGraph,
                          onClick: setActiveMonthlyBalanceInfo,
                        })}
                        {renderCustomLine({
                          name: `${termLabel(transitionTerm)}収支`,
                          dataKey: 'balance_total_manyen',
                          color: '#22a74b',
                          onClick: handleClickBalanceLine,
                        })}
                      </GraphComposed>
                    </div>
                  </div>

                  <div className="pb-57">
                    <CircleTitle
                      classNameTitle="text-20 md:text-24"
                      className="pb-16"
                      title="純資産"
                    />
                    <div className="overflow-x-scroll md:overflow-x-visible">
                      <GraphComposed
                        id="net-asset-graph"
                        width={setGraphWidth()}
                        height={setGraphHeight()}
                        xDataKey="date"
                        data={netAssetGraph}
                        tooltipHide={breakpoint === 'sm'}
                        tooltipPosition={{
                          x: setTooltipPosition(
                            activeTransitionNetAssetInfo,
                            netAssetGraph,
                          ).x,
                          y: setTooltipPosition(
                            activeTransitionNetAssetInfo,
                            netAssetGraph,
                          ).y,
                        }}
                        tooltipContent={
                          <TooltipTransitionNetAsset
                            activeIndex={activeTransitionNetAssetInfo.activeIndex}
                          />
                        }
                      >
                        {renderCustomBar({
                          name: '金融資産',
                          dataKey: 'total_manyen',
                          activeIndex: activeTransitionNetAssetInfo.activeIndex,
                          stackId: 'netAsset',
                          color: '#FFD9B1',
                          data: netAssetGraph,
                          onClick: setActiveTransitionNetAssetInfo,
                        })}
                        {renderCustomBar({
                          name: '不動産',
                          dataKey: 'evaluation_total_manyen',
                          activeIndex: activeTransitionNetAssetInfo.activeIndex,
                          stackId: 'netAsset',
                          color: '#AFE2E9',
                          data: netAssetGraph,
                          onClick: setActiveTransitionNetAssetInfo,
                        })}
                        {renderCustomBar({
                          name: '負債',
                          dataKey: 'substruction_total_manyen_negative',
                          activeIndex: activeTransitionNetAssetInfo.activeIndex,
                          stackId: 'netAsset',
                          color: '#F6B2A1',
                          data: netAssetGraph,
                          onClick: setActiveTransitionNetAssetInfo,
                        })}
                        {renderCustomLine({
                          name: '純資産合計',
                          dataKey: 'net_asset_total_manyen',
                          color: '#C891AE',
                          onClick: handleClickNetAssetLine,
                        })}
                      </GraphComposed>
                    </div>
                  </div>

                  <div>
                    <CircleTitle
                      classNameTitle="text-20 md:text-24"
                      className="pb-16"
                      title="金融資産"
                    />
                    <div className="overflow-x-scroll md:overflow-x-visible">
                      <GraphComposed
                        id="asset-graph"
                        width={setGraphWidth()}
                        height={setGraphHeight()}
                        xDataKey="date"
                        data={assetGraph}
                        tooltipHide={breakpoint === 'sm'}
                        tooltipPosition={{
                          x: setTooltipPosition(activeAssetInfo, assetGraph).x,
                          y: setTooltipPosition(activeAssetInfo, assetGraph).y,
                        }}
                        tooltipContent={
                          <TooltipTransitionAsset
                            activeIndex={activeAssetInfo.activeIndex}
                          />
                        }
                      >
                        {renderCustomBar({
                          name: '流動性資産',
                          dataKey: 'bank_manyen',
                          activeIndex: activeAssetInfo.activeIndex,
                          stackId: 'transitionAsset',
                          color: '#add6f0',
                          data: assetGraph,
                          onClick: setActiveAssetInfo,
                        })}
                        {renderCustomBar({
                          name: '投資信託',
                          dataKey: 'trust_fund_manyen',
                          activeIndex: activeAssetInfo.activeIndex,
                          stackId: 'transitionAsset',
                          color: '#b0e3e0',
                          data: assetGraph,
                          onClick: setActiveAssetInfo,
                        })}
                        {renderCustomBar({
                          name: '株式',
                          dataKey: 'stock_manyen',
                          activeIndex: activeAssetInfo.activeIndex,
                          stackId: 'transitionAsset',
                          color: '#fde998',
                          data: assetGraph,
                          onClick: setActiveAssetInfo,
                        })}
                        {renderCustomBar({
                          name: '債券',
                          dataKey: 'bond_manyen',
                          activeIndex: activeAssetInfo.activeIndex,
                          stackId: 'transitionAsset',
                          color: '#ff9933',
                          data: assetGraph,
                          onClick: setActiveAssetInfo,
                        })}
                        {renderCustomBar({
                          name: '社内預金・財形・持株など',
                          dataKey: 'deducted_savings_manyen',
                          activeIndex: activeAssetInfo.activeIndex,
                          stackId: 'transitionAsset',
                          color: '#cc6666',
                          data: assetGraph,
                          onClick: setActiveAssetInfo,
                        })}
                        {renderCustomBar({
                          name: 'その他',
                          dataKey: 'other_manyen',
                          activeIndex: activeAssetInfo.activeIndex,
                          stackId: 'transitionAsset',
                          color: '#e0e0e0',
                          data: assetGraph,
                          onClick: setActiveAssetInfo,
                        })}
                      </GraphComposed>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <Typography className="pb-[24px] text-[18px] text-center" isBold>
                      推移は、Moneytreeの連携後
                      <br />
                      ご使用になれます。
                    </Typography>
                    <ButtonPrimary
                      className="h-45 mx-auto w-full md:w-[200px]"
                      onClick={openMoneytreeConnection}
                    >
                      <Typography
                        className="relative bottom-px flex-1 pl-20 text-14 text-white"
                        isBold
                      >
                        Moneytreeを連携する
                      </Typography>
                      <ChevronRightIcon className="mr-17" />
                    </ButtonPrimary>
                  </div>
                </>
              )}
            </div>
          )}

          {currentTabIndex === MY_DATA_TAB_INDEX.future && (
            <div className="relative pt-[30px]">
              <LoadingOverlay isLoading={isGetDetailFutureBaseLoading} />
              <SelectBoxWithoutFinalForm
                name="age"
                placeholder="表示期間を選択する"
                className="pb-[40px]"
                widthClassName="w-[285px]"
                value={selectedAge}
                options={ageOptions}
                isFullWidth
                onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                  handleChangeAge(Number(e.target.value))
                }
              />
              <div className="pb-[56px] last:pb-0">
                <CircleTitle
                  classNameTitle="text-20 md:text-24"
                  className="pb-[24px]"
                  title="資産"
                />
                <Tab
                  className="h-[50px] mb-[20px]"
                  currentIndex={currentAssetTabIndex}
                  options={['金融資産', '純資産']}
                  onClick={(index) => setCurrentAssetTabIndex(index)}
                />
                <div className="overflow-x-scroll md:overflow-x-visible">
                  {currentAssetTabIndex === MY_DATA_ASSET_TAB_INDEX.investment && (
                    <GraphComposed
                      id="input-v2-investment-graph"
                      width={setGraphWidth()}
                      height={setGraphHeight()}
                      xDataKey="age"
                      data={futureGraphs.inputV2InvestmentGraph}
                      tooltipHide={breakpoint === 'sm'}
                      tooltipPosition={{
                        x: setTooltipPosition(
                          activeInvestmentInfo,
                          futureGraphs.inputV2InvestmentGraph,
                        ).x,
                        y: setTooltipPosition(
                          activeInvestmentInfo,
                          futureGraphs.inputV2InvestmentGraph,
                        ).y,
                      }}
                      tooltipContent={
                        <TooltipInvestment
                          activeIndex={activeInvestmentInfo.activeIndex}
                          isHideBasePlanName
                          baseItem={futureGraphs.inputV2InvestmentGraph}
                        />
                      }
                    >
                      {renderCustomBar({
                        name: '流動性資産',
                        dataKey: 'deposit',
                        activeIndex: activeInvestmentInfo.activeIndex,
                        stackId: 'investment',
                        color: '#B9D3E9',
                        data: futureGraphs.inputV2InvestmentGraph,
                        onClick: setActiveInvestmentInfo,
                      })}
                      {renderCustomBar({
                        name: '投資信託',
                        dataKey: 'investment_trust',
                        activeIndex: activeInvestmentInfo.activeIndex,
                        stackId: 'investment',
                        color: '#BBE1DF',
                        data: futureGraphs.inputV2InvestmentGraph,
                        onClick: setActiveInvestmentInfo,
                      })}
                      {renderCustomBar({
                        name: '株式',
                        dataKey: 'stock',
                        activeIndex: activeInvestmentInfo.activeIndex,
                        stackId: 'investment',
                        color: '#F9EBA3',
                        data: futureGraphs.inputV2InvestmentGraph,
                        onClick: setActiveInvestmentInfo,
                      })}
                      {renderCustomBar({
                        name: '債券',
                        dataKey: 'bond',
                        activeIndex: activeInvestmentInfo.activeIndex,
                        stackId: 'investment',
                        color: '#ff9933',
                        data: futureGraphs.inputV2InvestmentGraph,
                        onClick: setActiveInvestmentInfo,
                      })}
                      {renderCustomBar({
                        name: '社内預金・財形・持株など',
                        dataKey: 'deducted_savings',
                        activeIndex: activeInvestmentInfo.activeIndex,
                        stackId: 'investment',
                        color: '#cc6666',
                        data: futureGraphs.inputV2InvestmentGraph,
                        onClick: setActiveInvestmentInfo,
                      })}
                      {renderCustomBar({
                        name: 'その他',
                        dataKey: 'other',
                        activeIndex: activeInvestmentInfo.activeIndex,
                        stackId: 'investment',
                        color: '#E1E0E2',
                        data: futureGraphs.inputV2InvestmentGraph,
                        onClick: setActiveInvestmentInfo,
                      })}
                      {renderCustomBar({
                        name: 'マイナス資産',
                        dataKey: 'substruction',
                        activeIndex: activeInvestmentInfo.activeIndex,
                        stackId: 'investment',
                        color: '#D4C7DA',
                        data: futureGraphs.inputV2InvestmentGraph,
                        onClick: setActiveInvestmentInfo,
                      })}
                      {renderCustomBar({
                        name: '貯蓄用保険資産',
                        dataKey: 'insurance_asset',
                        activeIndex: activeInvestmentInfo.activeIndex,
                        stackId: 'investment',
                        color: '#F3BCC2',
                        data: futureGraphs.inputV2InvestmentGraph,
                        onClick: setActiveInvestmentInfo,
                      })}
                    </GraphComposed>
                  )}

                  {currentAssetTabIndex === MY_DATA_ASSET_TAB_INDEX.netAsset && (
                    <GraphComposed
                      id="input-net-asset-graph"
                      width={setGraphWidth()}
                      height={setGraphHeight()}
                      xDataKey="age"
                      data={futureGraphs.inputNetAssetGraph}
                      tooltipHide={breakpoint === 'sm'}
                      tooltipPosition={{
                        x: setTooltipPosition(
                          activeNetAssetInfo,
                          futureGraphs.inputNetAssetGraph,
                        ).x,
                        y: setTooltipPosition(
                          activeNetAssetInfo,
                          futureGraphs.inputNetAssetGraph,
                        ).y,
                      }}
                      tooltipContent={
                        <TooltipNetAsset
                          activeIndex={activeNetAssetInfo.activeIndex}
                          isHideBasePlanName
                          baseItem={futureGraphs.inputNetAssetGraph}
                        />
                      }
                    >
                      {renderCustomBar({
                        name: '金融資産',
                        dataKey: 'financial_asset_total',
                        activeIndex: activeNetAssetInfo.activeIndex,
                        stackId: 'netAsset',
                        color: '#FFD9B1',
                        data: futureGraphs.inputNetAssetGraph,
                        onClick: setActiveNetAssetInfo,
                      })}
                      {renderCustomBar({
                        name: '不動産',
                        dataKey: 'estate_evaluation_total_manyen',
                        activeIndex: activeNetAssetInfo.activeIndex,
                        stackId: 'netAsset',
                        color: '#AFE2E9',
                        data: futureGraphs.inputNetAssetGraph,
                        onClick: setActiveNetAssetInfo,
                      })}
                      {renderCustomBar({
                        name: '負債',
                        dataKey: 'substruction_total_negative',
                        activeIndex: activeNetAssetInfo.activeIndex,
                        stackId: 'netAsset',
                        color: '#F6B2A1',
                        data: futureGraphs.inputNetAssetGraph,
                        onClick: setActiveNetAssetInfo,
                      })}
                      {renderCustomLine({
                        name: '純資産合計',
                        dataKey: 'net_asset_total',
                        color: '#C891AE',
                        onClick: (payload) =>
                          handleClickLine(payload, setActiveNetAssetInfo),
                      })}
                    </GraphComposed>
                  )}
                </div>
              </div>

              <div className="pb-[56px] last:pb-0">
                <CircleTitle
                  classNameTitle="text-20 md:text-24"
                  className="pb-[24px]"
                  title="教育費推移"
                />
                <div className="overflow-x-scroll md:overflow-x-visible">
                  <GraphComposed
                    id="tuition-graph"
                    width={setGraphWidth()}
                    height={setGraphHeight()}
                    xDataKey="age"
                    data={futureGraphs.tuitionGraph}
                    tooltipHide={breakpoint === 'sm'}
                    tooltipPosition={{
                      x: setTooltipPosition(activeTuitionInfo, futureGraphs.tuitionGraph)
                        .x,
                      y: setTooltipPosition(activeTuitionInfo, futureGraphs.tuitionGraph)
                        .y,
                    }}
                    tooltipContent={
                      <TooltipTuition
                        activeItem={
                          !_isUndefined(activeTuitionInfo.activeIndex)
                            ? futureGraphs.tuitionGraph?.[activeTuitionInfo.activeIndex]
                            : undefined
                        }
                      />
                    }
                  >
                    {_times(child_count, (index) => [
                      renderCustomBar({
                        name: `第${index + 1}子学費`,
                        dataKey: `child_${index + 1}_tuition_manyen`,
                        activeIndex: activeTuitionInfo.activeIndex,
                        stackId: 'tuition',
                        color: index % 2 === 0 ? '#f88c93' : '#9bdfb5',
                        data: futureGraphs.tuitionGraph,
                        onClick: setActiveTuitionInfo,
                      }),
                      renderCustomBar({
                        name: `第${index + 1}子学外費`,
                        dataKey: `child_${index + 1}_off_campus_expenses_manyen`,
                        activeIndex: activeTuitionInfo.activeIndex,
                        stackId: 'tuition',
                        color: index % 2 === 0 ? '#fcc6c9' : '#cdefda',
                        data: futureGraphs.tuitionGraph,
                        onClick: setActiveTuitionInfo,
                      }),
                    ])}
                  </GraphComposed>
                </div>
              </div>

              <div className="pb-[56px] last:pb-0">
                <CircleTitle
                  classNameTitle="text-20 md:text-24"
                  className="pb-[24px]"
                  title="想定シミュレーション"
                />
                <Tab
                  className="h-[50px] mb-[20px]"
                  currentIndex={currentAfterRetirementTabIndex}
                  options={['収支', '保険']}
                  onClick={(index) => {
                    setCurrentAfterRetirementTabIndex(index)
                    setIsShowInsurance(index == MY_DATA_ASSET_TAB_INDEX.netAsset)
                  }}
                />

                {currentAfterRetirementTabIndex === MY_DATA_ASSET_TAB_INDEX.netAsset &&
                  !canShowInsuranceGraph && (
                    <>
                      <div>
                        <Typography className="pb-[24px] text-[18px] text-center" isBold>
                          保険の未来予想は、マイデータ設定の「万一の保障」を設定後
                          <br />
                          ご使用になれます。
                        </Typography>

                        <ButtonPrimary
                          className="h-45 mx-auto w-full md:w-[200px]"
                          onClick={() => handleMovePage('/v2/myData/risk')}
                        >
                          <Typography
                            className="relative bottom-px flex-1 pl-20 text-14 text-white"
                            isBold
                          >
                            万一の保障を設定する
                          </Typography>
                          <ChevronRightIcon className="mr-17" />
                        </ButtonPrimary>
                      </div>
                    </>
                  )}

                {currentAfterRetirementTabIndex === MY_DATA_ASSET_TAB_INDEX.netAsset &&
                  canShowInsuranceGraph && (
                    <>
                      <Form
                        onSubmit={handleSubmitInsurance}
                        validate={(values) => {
                          const validateMessage = {}
                          return validateMessage
                        }}
                        render={({ handleSubmit, values, errors }) => {
                          return (
                            <>
                              <form
                                className={clsx('md:flex pb-36', {
                                  ['hidden']:
                                    currentAfterRetirementTabIndex ===
                                    MY_DATA_ASSET_TAB_INDEX.netAsset,
                                })}
                                onSubmit={handleSubmitInsurance}
                              >
                                <SelectBox
                                  isFullWidth
                                  name="insurance_type"
                                  widthClassName="md:w-[175px]"
                                  heightClassName="h-34"
                                  isPlaceholderHidden={true}
                                  options={insuranceGraphSetTypes}
                                  initialValue={getGraphSetTypeInitialValue}
                                />

                                <div className="flex items-center">
                                  <SelectBox
                                    isFullWidth
                                    name="insurance_financial_type"
                                    className="md:ml-16 mt-[16px] md:mt-0"
                                    widthClassName="md:w-[175px]"
                                    heightClassName="h-34"
                                    isPlaceholderHidden={true}
                                    options={[
                                      {
                                        value: INSURANCE_FINANCIAL_TYPE.not_include,
                                        label: '金融資産を含めない',
                                      },
                                      {
                                        value: INSURANCE_FINANCIAL_TYPE.include,
                                        label: '金融資産を含む',
                                      },
                                    ]}
                                    initialValue={getRiskFinancialTypeInitialValue}
                                  />
                                </div>

                                <ButtonPrimary
                                  className="md:ml-[16px] mt-[16px] md:mt-0 w-full md:max-w-[110px] h-[32px]"
                                  isDisabled={Object.keys(errors).length > 0}
                                  onClick={() => handleSubmit(values)}
                                >
                                  <Typography
                                    className="relative bottom-[2px] px-[15px] text-[14px] text-white flex-auto items-center"
                                    isBold
                                  >
                                    表示する
                                  </Typography>
                                </ButtonPrimary>
                              </form>
                            </>
                          )
                        }}
                      />
                    </>
                  )}

                <div className="overflow-x-scroll md:overflow-x-visible">
                  {currentAfterRetirementTabIndex ===
                    MY_DATA_ASSET_TAB_INDEX.investment && (
                    <GraphComposed
                      id="v2-balance-graph"
                      width={setGraphWidth()}
                      height={setGraphHeight()}
                      xDataKey="age"
                      data={futureGraphs.inputV2BalanceGraph}
                      tooltipHide={breakpoint === 'sm'}
                      tooltipPosition={{
                        x: setTooltipPosition(
                          activeBalanceInfo,
                          futureGraphs.inputV2BalanceGraph,
                        ).x,
                        y: setTooltipPosition(
                          activeBalanceInfo,
                          futureGraphs.inputV2BalanceGraph,
                        ).y,
                      }}
                      tooltipContent={
                        <TooltipBalanceMyData
                          activeIndex={activeBalanceInfo.activeIndex}
                          isHideBasePlanName
                          baseItem={futureGraphs.inputV2BalanceGraph}
                        />
                      }
                    >
                      {renderCustomBar({
                        name: '年間収支',
                        dataKey: 'balance_total',
                        activeIndex: activeBalanceInfo.activeIndex,
                        color: '#ADD6F0',
                        data: futureGraphs.inputV2BalanceGraph,
                        onClick: setActiveBalanceInfo,
                      })}
                      {renderCustomLine({
                        name: '収入合計',
                        dataKey: 'income_total',
                        color: '#4A667F',
                        onClick: (payload) =>
                          handleClickLine(payload, setActiveBalanceInfo),
                      })}
                      {renderCustomLine({
                        name: '支出合計',
                        dataKey: 'spending_total',
                        color: '#C891AE',
                        onClick: (payload) =>
                          handleClickLine(payload, setActiveBalanceInfo),
                      })}
                    </GraphComposed>
                  )}

                  {currentAfterRetirementTabIndex === MY_DATA_ASSET_TAB_INDEX.netAsset &&
                    canShowInsuranceGraph && (
                      <GraphComposed
                        width={setGraphWidth()}
                        height={setGraphHeight()}
                        xDataKey="age"
                        data={futureGraphs.inputInsuranceGraph}
                        tooltipHide={breakpoint === 'sm'}
                        tooltipPosition={{
                          x: setTooltipPosition(
                            activeInsuranceInfo,
                            futureGraphs.inputInsuranceGraph,
                          ).x,
                          y: setTooltipPosition(
                            activeInsuranceInfo,
                            futureGraphs.inputInsuranceGraph,
                          ).y,
                        }}
                        tooltipContent={
                          <TooltipRisk
                            activeIndex={activeInsuranceInfo.activeIndex}
                            isHideBasePlanName
                            baseItem={futureGraphs.inputInsuranceGraph}
                          />
                        }
                      >
                        {renderCustomBar({
                          name: '金融資産合計',
                          dataKey: 'total_financial_assets',
                          activeIndex: activeInsuranceInfo.activeIndex,
                          stackId: 'risk',
                          color: '#191970',
                          data: futureGraphs.inputInsuranceGraph,
                          onClick: setActiveInsuranceInfo,
                        })}
                        {renderCustomBar({
                          name: '既加入保険金額',
                          dataKey: 'existing_insurance_total',
                          activeIndex: activeInsuranceInfo.activeIndex,
                          stackId: 'risk',
                          color: '#c71585',
                          data: futureGraphs.inputInsuranceGraph,
                          onClick: setActiveInsuranceInfo,
                        })}
                        {renderCustomBar({
                          name: '不足額',
                          dataKey: 'shortfall_total',
                          activeIndex: activeInsuranceInfo.activeIndex,
                          stackId: 'risk',
                          color: '#c0c0c0',
                          data: futureGraphs.inputInsuranceGraph,
                          onClick: setActiveInsuranceInfo,
                        })}
                        {renderCustomLine({
                          name: '必要保障額',
                          dataKey: 'required_security_amount_total',
                          color: '#ffa500',
                          onClick: handleClickLine,
                        })}
                      </GraphComposed>
                    )}
                </div>

                {currentAfterRetirementTabIndex === MY_DATA_ASSET_TAB_INDEX.investment &&
                  columnData.length > 0 &&
                  myDataDetailFutureBaseV2?.family_structure_table_columns?.length >
                    0 && (
                    <table
                      className="border-separate block overflow-x-scroll h-[calc(100vh-100px)] md:h-[calc(100vh-418px)] min-h-[480px] mt-[40px] text-[11px]"
                      style={{ borderSpacing: 'initial' }}
                    >
                      <tbody>
                        <tr>
                          <td className={`${classes.columnDataTable.td} border-t`}>
                            <Typography isBold>経過年数</Typography>
                          </td>
                          {Object.keys(
                            afterRetirementTable.familyStructureTableData,
                          )?.map((year, index) => (
                            <td
                              key={year}
                              className={`${classes.columnDataTable.th} border-t`}
                            >
                              <Typography isBold>{index + 1}</Typography>
                            </td>
                          ))}
                        </tr>
                        <tr>
                          <td className={`${classes.columnDataTable.td}`}>
                            <Typography isBold>年号</Typography>
                          </td>
                          {Object.keys(
                            afterRetirementTable.familyStructureTableData,
                          )?.map((year) => (
                            <td key={year} className={`${classes.columnDataTable.th}`}>
                              <Typography>{year}</Typography>
                            </td>
                          ))}
                        </tr>

                        {myDataDetailFutureBaseV2?.family_structure_table_columns.map(
                          (item: any) => (
                            <tr key={item.key} id={item.key} className="sticky z-[19]">
                              <td
                                className={`${classes.columnDataTable.td}`}
                                style={{
                                  backgroundColor:
                                    item.key === 'person_age' || item.key === 'spouse_age'
                                      ? '#FFEEA4'
                                      : '#FFFAE4',
                                }}
                              >
                                <Typography isBold>{item.display_name}</Typography>
                              </td>
                              {Object.keys(
                                afterRetirementTable.familyStructureTableData,
                              ).map((year) => (
                                <td
                                  key={year}
                                  className={`${classes.columnDataTable.th}`}
                                  style={{
                                    backgroundColor:
                                      item.key === 'person_age' ||
                                      item.key === 'spouse_age'
                                        ? '#FFEEA4'
                                        : '#FFFAE4',
                                  }}
                                >
                                  <Typography>
                                    {
                                      afterRetirementTable.familyStructureTableData?.[
                                        Number(year)
                                      ]?.[item.key]
                                    }
                                  </Typography>
                                </td>
                              ))}
                            </tr>
                          ),
                        )}
                      </tbody>

                      {_times(columnData.length, (parentIndex) => (
                        <tbody key={parentIndex} className="before:block before:h-[30px]">
                          <tr>
                            {Object.keys(tableData?.[parentIndex] || {})?.map(
                              (year, index) => (
                                <React.Fragment key={year}>
                                  {index === 0 && (
                                    <th
                                      className={`${classes.columnDataTable.td} z-[40] bg-white p-0 border-t border-b-[2px]`}
                                    />
                                  )}
                                  <th
                                    key={year}
                                    className={`${classes.columnDataTable.th} border-t border-b-[2px] last:border-r`}
                                    style={{ borderRight: '1px dashed #C6C6C6' }}
                                  >
                                    <Typography isBold>{year}</Typography>
                                  </th>
                                </React.Fragment>
                              ),
                            )}
                          </tr>
                          {columnData?.[parentIndex]?.map((item: any, index: number) => (
                            <React.Fragment key={item.key}>
                              {renderTableCell(
                                item,
                                [getNestedKey(index)],
                                true,
                                parentIndex,
                                tableData,
                              )}
                            </React.Fragment>
                          ))}
                        </tbody>
                      ))}
                    </table>
                  )}

                {currentAfterRetirementTabIndex === MY_DATA_ASSET_TAB_INDEX.netAsset &&
                  canShowInsuranceGraph &&
                  columnData.length > 0 &&
                  myDataDetailFutureBaseV2?.family_structure_table_columns?.length >
                    0 && (
                    <table
                      className="border-separate block overflow-x-scroll h-[calc(100vh-100px)] md:h-[calc(100vh-418px)] min-h-[480px] mt-[40px] text-[11px]"
                      style={{ borderSpacing: 'initial' }}
                    >
                      <tbody>
                        <tr>
                          <td className={`${classes.columnDataTable.td} border-t`}>
                            <Typography isBold>経過年数</Typography>
                          </td>
                          {Object.keys(
                            afterRetirementTable.familyStructureTableData,
                          )?.map((year, index) => {
                            const cellId = `${index + 1}_${year}`
                            return (
                              <td
                                key={year}
                                className={`${classes.columnDataTable.th} border-t`}
                                style={{
                                  border:
                                    currentCellId == cellId ? '2px solid #0071ff' : '',
                                }}
                                id={cellId}
                                onClick={() => setCurrentCellId(cellId)}
                              >
                                <Typography isBold>{index + 1}</Typography>
                              </td>
                            )
                          })}
                        </tr>
                        <tr>
                          <td className={`${classes.columnDataTable.td}`}>
                            <Typography isBold>年号</Typography>
                          </td>
                          {Object.keys(
                            afterRetirementTable.familyStructureTableData,
                          )?.map((year) => {
                            const cellId = `${year}_family`
                            return (
                              <td
                                key={year}
                                className={`${classes.columnDataTable.th}`}
                                style={{
                                  border:
                                    currentCellId == cellId ? '2px solid #0071ff' : '',
                                }}
                                id={cellId}
                                onClick={() => setCurrentCellId(cellId)}
                              >
                                <Typography>{year}</Typography>
                              </td>
                            )
                          })}
                        </tr>

                        {myDataDetailFutureBaseV2?.family_structure_table_columns.map(
                          (item: any) => (
                            <tr key={item.key} id={item.key} className="sticky z-[19]">
                              <td
                                className={`${classes.columnDataTable.td}`}
                                style={{
                                  backgroundColor:
                                    item.key === 'person_age' || item.key === 'spouse_age'
                                      ? '#FFEEA4'
                                      : '#FFFAE4',
                                }}
                              >
                                <Typography isBold>{item.display_name}</Typography>
                              </td>
                              {Object.keys(
                                afterRetirementTable.familyStructureTableData,
                              ).map((year) => {
                                const cellId = `${item.key}_${year}`
                                return (
                                  <td
                                    key={year}
                                    className={`${classes.columnDataTable.th}`}
                                    style={{
                                      backgroundColor:
                                        item.key === 'person_age' ||
                                        item.key === 'spouse_age'
                                          ? '#FFEEA4'
                                          : '#FFFAE4',
                                      border:
                                        currentCellId == cellId
                                          ? '2px solid #0071ff'
                                          : '',
                                    }}
                                    id={cellId}
                                    onClick={() => setCurrentCellId(cellId)}
                                  >
                                    <Typography>
                                      {
                                        afterRetirementTable.familyStructureTableData?.[
                                          Number(year)
                                        ]?.[item.key]
                                      }
                                    </Typography>
                                  </td>
                                )
                              })}
                            </tr>
                          ),
                        )}
                      </tbody>

                      {_times(columnData.length, (parentIndex) => (
                        <tbody key={parentIndex} className="before:block before:h-[30px]">
                          <tr>
                            {Object.keys(tableData?.[parentIndex] || {})?.map(
                              (year, index) => {
                                const cellId = `${year}_statement`
                                return (
                                  <React.Fragment key={year}>
                                    {index === 0 && (
                                      <th
                                        className={`${classes.columnDataTable.td} z-[40] bg-white p-0 border-t border-b-[2px]`}
                                      />
                                    )}
                                    <th
                                      key={year}
                                      className={`${classes.columnDataTable.th} border-t border-b-[2px] last:border-r`}
                                      style={{
                                        borderRight: '1px dashed #C6C6C6',
                                        border:
                                          currentCellId == cellId
                                            ? '2px solid #0071ff'
                                            : '',
                                      }}
                                      id={cellId}
                                      onClick={() => setCurrentCellId(cellId)}
                                    >
                                      <Typography isBold>{year}</Typography>
                                    </th>
                                  </React.Fragment>
                                )
                              },
                            )}
                          </tr>
                          {columnData?.[parentIndex]?.map((item: any, index: number) => (
                            <React.Fragment key={item.key}>
                              {renderTableCell(
                                item,
                                [getNestedKey(index)],
                                true,
                                parentIndex,
                                tableData,
                              )}
                            </React.Fragment>
                          ))}
                        </tbody>
                      ))}
                    </table>
                  )}
              </div>
            </div>
          )}
        </Paper>
      </MarginContainer>

      {breakpoint === 'sm' && (
        <>
          <Modal
            className="w-[95%]"
            modalContainerClassName="h-[95%] px-10 py-10"
            isOpen={
              !!activeMonthlyBalanceInfo.activeIndex ||
              activeMonthlyBalanceInfo.activeIndex === 0
            }
            onClose={() => handleClickAway()}
          >
            <TooltipBalance activeIndex={activeMonthlyBalanceInfo.activeIndex} />
          </Modal>

          <Modal
            className="w-[95%]"
            modalContainerClassName="h-[95%] px-10 py-10"
            isOpen={!!activeAssetInfo.activeIndex || activeAssetInfo.activeIndex === 0}
            onClose={() => handleClickAway()}
          >
            <TooltipTransitionAsset activeIndex={activeAssetInfo.activeIndex} />
          </Modal>

          <Modal
            className="w-[95%]"
            modalContainerClassName="h-[95%] px-10 py-10"
            isOpen={
              !!activeTransitionNetAssetInfo.activeIndex ||
              activeTransitionNetAssetInfo.activeIndex === 0
            }
            onClose={() => handleClickAway()}
          >
            <TooltipTransitionNetAsset
              activeIndex={activeTransitionNetAssetInfo.activeIndex}
            />
          </Modal>

          <Modal
            className="w-[95%]"
            modalContainerClassName="h-[95%] px-10 py-10"
            isOpen={
              !!activeInvestmentInfo.activeIndex || activeInvestmentInfo.activeIndex === 0
            }
            onClose={() => handleClickAway()}
          >
            <TooltipInvestment
              activeIndex={activeInvestmentInfo.activeIndex}
              isHideBasePlanName
              baseItem={futureGraphs.inputV2InvestmentGraph}
            />
          </Modal>

          <Modal
            className="w-[95%]"
            modalContainerClassName="h-[95%] px-10 py-10"
            isOpen={
              !!activeNetAssetInfo.activeIndex || activeNetAssetInfo.activeIndex === 0
            }
            onClose={() => handleClickAway()}
          >
            <TooltipNetAsset
              activeIndex={activeNetAssetInfo.activeIndex}
              isHideBasePlanName
              baseItem={futureGraphs.inputNetAssetGraph}
            />
          </Modal>

          <Modal
            className="w-[95%]"
            modalContainerClassName="h-[95%] px-10 py-10"
            isOpen={
              !!activeTuitionInfo.activeIndex || activeTuitionInfo.activeIndex === 0
            }
            onClose={() => handleClickAway()}
          >
            <TooltipTuition
              activeItem={
                !_isUndefined(activeTuitionInfo.activeIndex)
                  ? futureGraphs.tuitionGraph?.[activeTuitionInfo.activeIndex]
                  : undefined
              }
            />
          </Modal>

          <Modal
            className="w-[95%]"
            modalContainerClassName="h-[95%] px-10 py-10"
            isOpen={
              !!activeBalanceInfo.activeIndex || activeBalanceInfo.activeIndex === 0
            }
            onClose={() => handleClickAway()}
          >
            <TooltipBalanceMyData
              activeIndex={activeBalanceInfo.activeIndex}
              isHideBasePlanName
              baseItem={futureGraphs.inputV2BalanceGraph}
            />
          </Modal>

          <Modal
            className="w-[95%]"
            modalContainerClassName="h-[95%] px-10 py-10"
            isOpen={
              !!activeInsuranceInfo.activeIndex || activeInsuranceInfo.activeIndex === 0
            }
            onClose={() => handleClickAway()}
          >
            <TooltipRisk
              activeIndex={activeInsuranceInfo.activeIndex}
              isHideBasePlanName
              baseItem={futureGraphs.inputInsuranceGraph}
            />
          </Modal>
        </>
      )}
    </>
  )
}

export default MyDataIndex
