import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { sortBy as _sortBy } from 'lodash'
import {
  getInputIncomes,
  postInputIncome,
  postInputIncomeCalc,
  patchInputIncome,
  deleteInputIncome,
} from '@/api/inputs/inputIncomes'
import { unauthorizedCheck } from '@/containers/accountsSlice'
import {
  InputIncomeCalcResponse,
  InputIncomeCalc,
  InputIncomeDestroyValues,
} from '@/models/inputs/inputIncomesModel'
import { InputIncomeBase } from '@/models/inputs/inputIncomeBasesModel'
import { AppThunk } from '@/store'

interface InputIncomeState {
  inputIncomeBases: InputIncomeBase[]
  inputIncomeCalc: InputIncomeCalc
  isLoading: boolean
}

const initialState: InputIncomeState = {
  inputIncomeBases: [],
  inputIncomeCalc: {
    0: {},
    1: {},
  },
  isLoading: false,
}

const inputIncomesSlice = createSlice({
  name: 'inputIncomes',
  initialState,
  reducers: {
    setInputIncomeBases: (state, action: PayloadAction<InputIncomeBase[]>) => {
      state.inputIncomeBases = action.payload.map((item) => ({
        ...item,
        input_income_changes: _sortBy(item.input_income_changes, 'scheduled_at_age'),
      }))
      return state
    },
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    resetInputIncomesStore: (state) => {
      state = initialState
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    getInputIncomesSuccess: (state, action: PayloadAction<InputIncomeBase[]>) => {
      state.inputIncomeBases = action.payload.map((item) => ({
        ...item,
        input_income_changes: _sortBy(item.input_income_changes, 'scheduled_at_age'),
      }))
      state.isLoading = false
      return state
    },
    postInputIncomeCalcSuccess: (
      state,
      action: PayloadAction<InputIncomeCalcResponse>,
    ) => {
      state.inputIncomeCalc = action.payload
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
  },
})

export const {
  setInputIncomeBases,
  requestStart,
  resetInputIncomesStore,
  requestSuccess,
  getInputIncomesSuccess,
  postInputIncomeCalcSuccess,
  requestFailure,
} = inputIncomesSlice.actions
export const inputIncomesReducer = inputIncomesSlice.reducer

export const fetchInputIncomes = (): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  getInputIncomes()
    .then((response) => dispatch(getInputIncomesSuccess(response)))
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const createInputIncome = (values: any, callback: () => void): AppThunk => async (
  dispatch,
) => {
  dispatch(requestStart())
  postInputIncome(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const createInputIncomeCalc = (values: any): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postInputIncomeCalc(values)
    .then((response) => dispatch(postInputIncomeCalcSuccess(response)))
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const updateInputIncome = (values: any, callback: () => void): AppThunk => async (
  dispatch,
) => {
  dispatch(requestStart())
  patchInputIncome(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const destroyInputIncome = (
  values: { params: InputIncomeDestroyValues },
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  deleteInputIncome(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}
