import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import GroupIcon from '@/assets/images/group.svg'
import GroupIcon2 from '@/assets/images/group2.svg'
import GroupIcon3 from '@/assets/images/group3.svg'
import GroupIcon4 from '@/assets/images/group4.svg'
import GroupIcon5 from '@/assets/images/group5.svg'
import MarginContainer from '@/components/v2/atoms/MarginContainer'
import Paper from '@/components/v2/atoms/Paper'
import Typography from '@/components/v2/atoms/Typography'
import { setPageMeta } from '@/containers/pageMetasSlice'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import Heading from '@/components/v2/atoms/Heading'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'

const classes = {
  pointItem: {
    base: 'relative px-20 lg:px-[50px] pt-40 lg:pt-30 lg:pb-15 bg-white',
    title: {
      base: 'absolute top-[-12px] flex font-roboto text-primary-950',
      point: 'text-16',
      number: 'relative bottom-20 pl-10 text-48',
    },
    content: {
      base: 'flex flex-col lg:flex-row items-center',
      description: 'lg:w-[65%] mb-16 lg:mb-0 space-y-24 text-14 text-black-800',
    },
    icon: 'mx-auto',
  },
}

const AccountCompleted: FC = () => {
  const dispatch = useDispatch()
  const { handleMovePage } = useCustomHistory()

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: '会員登録完了',
        description: '会員登録完了',
        keyword: '会員登録完了',
      }),
    )
  }, [])

  return (
    <MarginContainer className="py-40">
      <Paper className="mx-8 xl:mx-0 px-16 py-24 sm:p-36 space-y-24 bg-white">
        <Heading className="text-center">会員登録完了</Heading>
        <Typography className="text-14 text-black-700 text-center">
          ご登録いただきありがとうございます。
          <span className="inline-block">登録が完了いたしました。</span>
          <br />
          順を追ってライフプランを作成してみましょう!
        </Typography>
        <div className="text-center">
          <Typography className="inline-block p-5 text-16 md:text-18 text-primary-950 bg-primary-75">
            まずは、正しくライフプランを作る
            <span className="inline-block">意義と方法をお読みください。</span>
          </Typography>
        </div>
        <div className="p-16 md:p-40 space-y-64 rounded-16 bg-primary-75">
          <Heading as="h2" size={2} color="primary" className="text-center">
            正しいライフプランを
            <span className="inline-block">作成するためのポイント</span>
          </Heading>
          <section className={classes.pointItem.base}>
            <div className={classes.pointItem.title.base}>
              <Typography className={classes.pointItem.title.point}>POINT</Typography>
              <Typography className={classes.pointItem.title.number}>01</Typography>
            </div>
            <div className={classes.pointItem.content.base}>
              <div className={classes.pointItem.content.description}>
                <Heading as="h3" size={4}>
                  精密なライフプランで将来をきれいに見える化しよう
                </Heading>
                <Typography>
                  最初に”家計簿連携”と”現状入力”をしていただきます。
                  <br />
                  曖昧な箇所も調べながら詳細に
                  <br />
                  入力することで、将来のシミュレーションを正しく
                  <br />
                  精密に作ることができるようになります。
                </Typography>
              </div>
              <GroupIcon width="240" height="230" className={classes.pointItem.icon} />
            </div>
          </section>
          <section className={classes.pointItem.base}>
            <div className={classes.pointItem.title.base}>
              <Typography className={classes.pointItem.title.point}>POINT</Typography>
              <Typography className={classes.pointItem.title.number}>02</Typography>
            </div>
            <div className={classes.pointItem.content.base}>
              <div className={classes.pointItem.content.description}>
                <Heading as="h3" size={4}>
                  サマリーページで今のあなたの現状と将来をチェック
                </Heading>
                <Typography>
                  サマリーページでは、将来のお金の寿命、
                  <br />
                  現在と未来の資産状況、家計やライフプランの目標
                  <br />
                  を確認することできます。過不足がある将来の
                  <br />
                  お金を今から把握し、安心なライフプランを歩む
                  <br />
                  ために解決すべき課題を見つけましょう。
                </Typography>
              </div>
              <GroupIcon2 width="265" height="230" className={classes.pointItem.icon} />
            </div>
          </section>
          <section className={classes.pointItem.base}>
            <div className={classes.pointItem.title.base}>
              <Typography className={classes.pointItem.title.point}>POINT</Typography>
              <Typography className={classes.pointItem.title.number}>03</Typography>
            </div>
            <div className={classes.pointItem.content.base}>
              <div className={classes.pointItem.content.description}>
                <Heading as="h3" size={4}>
                  ライフプランシミュレーションをしてみよう
                </Heading>
                <Typography>
                  課題を発見したら次は、理想のプラン作り。
                  <br />
                  将来どのような収入や支出があると思い通りの
                  <br />
                  将来を描けるのかをシミュレーションしてみましょう。
                  <br />
                  ここでは、生活費を変えてみたり、家を買ってみたり、
                  <br />
                  結婚・子供を増やしてみたりとさまざまな形での将来を
                  <br />
                  シミュレートすることができます。
                </Typography>
              </div>
              <GroupIcon3 width="240" height="230" className={classes.pointItem.icon} />
            </div>
          </section>
          <section className={classes.pointItem.base}>
            <div className={classes.pointItem.title.base}>
              <Typography className={classes.pointItem.title.point}>POINT</Typography>
              <Typography className={classes.pointItem.title.number}>04</Typography>
            </div>
            <div className={classes.pointItem.content.base}>
              <div className={classes.pointItem.content.description}>
                <Heading as="h3" size={4}>
                  AI-FPくんのアドバイスに沿って、計画を達成させよう
                </Heading>
                <Typography>
                  理想プランができたら、理想に向けて生活
                  <br />
                  スタート。理想と現実のギャップや正しい情報
                  <br />
                  を常に管理できるように、AI-FPくんが
                  <br />
                  アドバイスしてくれます。毎日、AI-FPくんの提案をみて、
                  <br />
                  理想と現実のギャップを埋めましょう。
                </Typography>
              </div>
              <GroupIcon4 width="240" height="230" className={classes.pointItem.icon} />
            </div>
          </section>
          <section className={classes.pointItem.base}>
            <div className={classes.pointItem.title.base}>
              <Typography className={classes.pointItem.title.point}>POINT</Typography>
              <Typography className={classes.pointItem.title.number}>05</Typography>
            </div>
            <div className={classes.pointItem.content.base}>
              <div className={classes.pointItem.content.description}>
                <Heading as="h3" size={4}>
                  マネソルユーザーはリアルFPに相談できる
                </Heading>
                <Typography>
                  FPと情報を共有することで、
                  <br />
                  あなたのライフプランに必要な課題解決を
                  <br />
                  相談することができます。
                  <br />
                  さまざまな課題解決のヒントやあなたと同じ境遇の
                  <br />
                  人へのアドバイス経験などを聞くことができます。
                </Typography>
              </div>
              <GroupIcon5 width="240" height="230" className={classes.pointItem.icon} />
            </div>
          </section>
        </div>

        <div className="flex justify-center">
          <ButtonPrimary
            className="h-45 w-full md:w-188 text-white"
            onClick={() => {
              window.localStorage.isFromSettingPage = false
              handleMovePage('/v2/registration/household')
            }}
          >
            <Typography className="bottom-2 flex-auto pl-30 relative text-16" isBold>
              次へ
            </Typography>
            <i className="ico chevron-right mr-17 text-24" />
          </ButtonPrimary>
        </div>
      </Paper>
    </MarginContainer>
  )
}

export default AccountCompleted
