import React, { FC } from 'react'
import { useForm } from 'react-final-form'
import Typography from '@/components/v2/atoms/Typography'
import SelectBox from '@/components/v2/molecules/SelectBox'
import { INPUT_OWNED_HOUSE_TYPE_BUTTON_ITEMS } from '@/constants/formItem/inputs/inputHousings'
import { getTargetSelfOwnedAttributesName } from '@/models/commonHousingModelFunc'
import { required } from '@/utils/validate'
import { API_FLAG } from '@/models/commonsModelFunc'

interface HouseTypeProps {
  /** true: シミュレーション, false: 現状 */
  isFuture?: boolean
}

const HouseType: FC<HouseTypeProps> = (props) => {
  const { isFuture } = props
  const targetHousingSelfOwnedAttributesName = getTargetSelfOwnedAttributesName(isFuture)

  const { change } = useForm()

  const handleChangeHouseType = (value: number) => {
    change(`${targetHousingSelfOwnedAttributesName}.house_type`, value)
    change(`${targetHousingSelfOwnedAttributesName}.housing_type2`, null)
    change(`${targetHousingSelfOwnedAttributesName}.loan_type2`, API_FLAG.off)
  }

  return (
    <>
      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
        <Typography
          className="min-w-[140px] lg:min-w-[210px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
          isBold
        >
          マンション・戸建て
        </Typography>
        <SelectBox
          name={`${targetHousingSelfOwnedAttributesName}.house_type`}
          placeholder="選択する"
          isFullWidth
          options={INPUT_OWNED_HOUSE_TYPE_BUTTON_ITEMS}
          validate={required}
          onChange={(e: any) => handleChangeHouseType(e.target.value)}
        />
      </div>
    </>
  )
}

export default HouseType
