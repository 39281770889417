import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { isUndefined as _isUndefined, sortBy as _sortBy } from 'lodash'
import {
  getAgentCompanies,
  getAgentCompanyPaymentHistories,
  patchAgentCompany,
  postAgentCompany,
} from '@/api/admins/agentCompanies'
import { unauthorizedAdminCheck } from '@/containers/admins/fpAccountsSlice'
import {
  AgentCompany,
  AgentCompanyPaymentHistoryResponse,
  AgentCompanyPaymentHistory,
  AgentCompanyCreateValues,
  AgentCompanyUpdateValues,
} from '@/models/admins/AgentCompaniesModel'
import { ApiError } from '@/models/commonsModel'
import { AppThunk } from '@/store'

interface AgentCompanyState {
  agentCompanies: AgentCompany[]
  agentCompanyPaymentHistories: AgentCompanyPaymentHistory[]
  isLoading: boolean
}

const initialState: AgentCompanyState = {
  agentCompanies: [],
  agentCompanyPaymentHistories: [],
  isLoading: false,
}

const agentCompaniesSlice = createSlice({
  name: 'agent_companies',
  initialState,
  reducers: {
    setAgentCompanies: (state, action: PayloadAction<AgentCompany[]>) => {
      state.agentCompanies = _sortBy(action.payload, 'id')
      return state
    },
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    getAgentCompanyPaymentHistoriesSuccess: (
      state,
      action: PayloadAction<AgentCompanyPaymentHistoryResponse[]>,
    ) => {
      state.agentCompanyPaymentHistories = action.payload
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
  },
})

export const {
  requestStart,
  requestSuccess,
  getAgentCompanyPaymentHistoriesSuccess,
  requestFailure,
  setAgentCompanies,
} = agentCompaniesSlice.actions
export const agentCompaniesReducer = agentCompaniesSlice.reducer

export const fetchAgentCompanies = (): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  getAgentCompanies()
    .then((response) => {
      dispatch(setAgentCompanies(response))
    })
    .catch((error) => {
      dispatch(requestFailure())
    })
}

export const fetchAgentCompanyPaymentHistories = (): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  getAgentCompanyPaymentHistories()
    .then((response) => dispatch(getAgentCompanyPaymentHistoriesSuccess(response)))
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedAdminCheck(error))
    })
}

export const createAgentCompany = (
  values: AgentCompanyCreateValues,
  callback: () => void,
  resolve: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postAgentCompany(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedAdminCheck(error, resolve))
    })
}

export const updateAgentCompany = (
  values: AgentCompanyUpdateValues,
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchAgentCompany(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedAdminCheck(error))
    })
}
