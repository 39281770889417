import ApiClient from '../apiClient'

export const postFutureFamilyV2 = async (values: any) => {
  return await ApiClient.post('/future_families', () => {}, values, undefined, 'v2')
}

export const patchFutureFamilyV2 = async (values: any, id: number) => {
  return await ApiClient.patch(
    `/future_families/${id}`,
    () => {},
    values,
    undefined,
    'v2',
  )
}

export const deleteFutureFamilyV2 = async (values: any, id: number) => {
  return await ApiClient.delete(
    `/future_families/${id}`,
    () => {},
    values,
    undefined,
    'v2',
  )
}

export const patchFutureFamilyChildbirthV2 = async (values: any, id: number) => {
  return await ApiClient.patch(
    `/future_families/${id}/update_childbirth`,
    () => {},
    values,
    undefined,
    'v2',
  )
}
