import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { sortBy as _sortBy } from 'lodash'
import { getFpCompanies } from '../../api/admins/fp_companies'
import { AppThunk } from '../../store'

const fpCompaniesSlice = createSlice({
  name: 'fp_companies',
  initialState: {
    fpCompanies: [],
    isLoading: false,
  },
  reducers: {
    setFpCompanies: (state, action: PayloadAction<any>) => {
      state.fpCompanies = _sortBy(action.payload, 'id') as any
      return state
    },
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
  },
})

export const {
  requestStart,
  requestSuccess,
  requestFailure,
  setFpCompanies,
} = fpCompaniesSlice.actions
export const fpCompaniesReducer = fpCompaniesSlice.reducer

export const fetchFpCompanies = (): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  getFpCompanies()
    .then((response) => {
      dispatch(setFpCompanies(response))
    })
    .catch((error) => {
      dispatch(requestFailure())
    })
}
