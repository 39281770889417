import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import MarginContainer from '@/components/v2/atoms/MarginContainer'
import { setPageMeta } from '@/containers/pageMetasSlice'
import LogindRedirect from '@/layouts/v2/LogindRedirect'
import MailSendCompleted from '@/templates/v2/accounts/commons/MailSendCompleted'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import Typography from '@/components/v2/atoms/Typography'
import { useCustomHistory } from '@/hooks/useCustomHistory'

const AccountPasswordResetCompleted: FC = () => {
  const dispatch = useDispatch()
  const { handleMovePage } = useCustomHistory()

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: '会員パスワード再設定完了',
        description: '会員パスワード再設定完了',
        keyword: '会員パスワード再設定完了',
      }),
    )
  }, [])

  return (
    <LogindRedirect>
      <MarginContainer className="w-full sm:w-524 mx-auto pt-40 sm:pt-73">
        <MailSendCompleted title="パスワード再設定完了">
          パスワードを再設定しました。
          <br />
          ログインページからログインをお願いします。
          <div className="flex justify-center pt-20">
            <ButtonPrimary
              className="h-45 w-full md:w-265 text-white"
              onClick={() => {
                handleMovePage('/v2')
              }}
            >
              <Typography className="bottom-2 flex-auto pl-30 relative text-16" isBold>
                ログインページへ
              </Typography>
              <i className="ico chevron-right mr-17 text-24" />
            </ButtonPrimary>
          </div>
        </MailSendCompleted>
      </MarginContainer>
    </LogindRedirect>
  )
}

export default AccountPasswordResetCompleted
