import ApiClient from '@/api/apiClient'
import {
  InputSalaryCompanyCreateValues,
  InputSalaryCompanyUpdateValues,
  InputSalaryCompanyCalcResponse,
  InputSalaryCompanyCalcValues,
} from '@/models/inputs/inputSalaryCompaniesModel'

export const postInputSalaryCompany = async (values: InputSalaryCompanyCreateValues) => {
  return await ApiClient.post('/input_salary_companies', () => {}, values)
}

export const postInputSalaryCompanyCalc = async (
  values: InputSalaryCompanyCalcValues,
) => {
  return await ApiClient.post(
    '/input_salary_companies/calc',
    (response: InputSalaryCompanyCalcResponse) => response,
    values,
  )
}

export const patchInputSalaryCompany = async (
  id: number,
  values: InputSalaryCompanyUpdateValues,
) => {
  return await ApiClient.patch(`/input_salary_companies/${id}`, () => {}, values)
}
