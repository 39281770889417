import React, { FC, useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { find as _find, isUndefined as _isUndefined, isNull as _isNull } from 'lodash'
import Button from '@/components/v2/atoms/Button'
import Typography from '@/components/v2/atoms/Typography'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import { setPageMeta } from '@/containers/pageMetasSlice'
import { fetchCompanySalaryBaseV2 } from '@/containers/admins/companySalaryBasesSlice'
import { fetchInputIncomes } from '@/containers/inputs/inputIncomesSlice'
import { fetchInputSalaries } from '@/containers/inputs/inputSalariesSlice'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import {
  convertChangeType,
  renderIncomeItemLabel,
  convertReEmploymentType,
  CHANGE_TYPE,
} from '@/models/commonIncomeChangesModelFunc'
import { PERSON_FLG } from '@/models/familyStructuresModelFunc'
import {
  convertRateSettingType,
  CURRENT_SALARY_INCREASE_RATE_SETTING_TYPE,
} from '@/models/commonIncomeBasesModelFunc'
import {
  defaultToEmptyString,
  convertYenToManyen,
  API_FLAG,
  convertLocaleString,
} from '@/models/commonsModelFunc'
import { RootState } from '@/store'
import MyDataListWrapper from '@/templates/v2/myData/_ListWrapper'
import { useAccountControl } from '@/hooks/useAccountControl'
import { AccountContext } from '@/models/accountsModelFunc'

const MyDataIncome: FC = () => {
  const dispatch = useDispatch()
  const { isAdmin } = useContext(AccountContext)
  const { handleMovePage } = useCustomHistory()
  const { isMyDataManipulatedApproval } = useAccountControl()

  const { inputSalaries } = useSelector((state: RootState) => state.inputSalaries)
  const { inputIncomeBases } = useSelector((state: RootState) => state.inputIncomes)
  const { companySalaryBase } = useSelector(
    (state: RootState) => state.companySalaryBases,
  )

  const isCompanySalary = !_isNull(companySalaryBase)

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'マイデータ | 収入',
        description: 'マイデータ | 収入',
        keyword: 'マイデータ | 収入',
      }),
    )
    dispatch(fetchInputSalaries())
    dispatch(fetchInputIncomes())
    dispatch(fetchCompanySalaryBaseV2())
  }, [])

  const renderTable = (isPerson: boolean) => {
    const personFlg = isPerson ? PERSON_FLG.person : PERSON_FLG.spouse
    const targetInputIncome = _find(
      inputIncomeBases,
      (item) => item.person_flg === personFlg,
    )
    const targetInputSalary = _find(
      inputSalaries,
      (item) => item.person_flg === personFlg,
    )
    const retirementAgeItem = _find(
      targetInputIncome?.input_income_changes,
      (item) => item.change_type === CHANGE_TYPE.retirementAge,
    )

    return (
      <div className="mb-[60px] last:mb-0">
        <div className="sm:flex items-center justify-between pb-[20px]">
          <CircleTitle
            title={isPerson ? 'ご本人' : '配偶者'}
            classNameTitle="text-[20px] md:text-[24px]"
          />
          <Button
            className="w-[84px] md:w-[112px] h-[26px] md:h-[37px] mr-0 md:mr-[9px] rounded-[4px] bg-white border border-secondary-500 hover:bg-secondary-50"
            isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
            onClick={() =>
              handleMovePage(`/v2/myData/incomes/${isPerson ? 'person' : 'spouse'}/edit`)
            }
          >
            <Typography
              className="relative bottom-[1px] text-[12px] md:text-[14px] text-secondary-500"
              isBold
            >
              編集する
            </Typography>
          </Button>
        </div>

        {!_isUndefined(targetInputIncome) && (
          <table className="w-[100%] text-[12px] md:text-[14px]">
            <tbody>
              {!isCompanySalary && (
                <>
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="w-[30%] px-[16px] md:px-[30px]">
                      <Typography isBold>上昇率タイプ</Typography>
                    </td>
                    <td className="w-[70%] px-[16px] md:px-[30px]">
                      <Typography>
                        {convertRateSettingType(
                          targetInputIncome?.current_salary_increase_rate_setting_type,
                        )}
                      </Typography>
                    </td>
                  </tr>
                  {targetInputIncome?.current_salary_increase_rate_setting_type ===
                    CURRENT_SALARY_INCREASE_RATE_SETTING_TYPE.input && (
                    <tr className="h-[45px] odd:bg-black-100">
                      <td className="px-[16px] md:px-[30px]">
                        <Typography isBold>収入の上昇率</Typography>
                      </td>
                      <td className="px-[16px] md:px-[30px]">
                        <Typography>{`${defaultToEmptyString(
                          targetInputIncome?.current_salary_increase_rate_percent,
                        )}%`}</Typography>
                      </td>
                    </tr>
                  )}
                </>
              )}

              {isCompanySalary && (
                <>
                  {targetInputSalary?.input_salary_company_base?.input_salary_company_changes?.map(
                    (item) => (
                      <tr key={item.id} className="h-[45px] odd:bg-black-100">
                        <td className="w-[30%] px-[16px] md:px-[30px]">
                          <Typography isBold>{`${defaultToEmptyString(
                            item.scheduled_at_age,
                          )}歳`}</Typography>
                        </td>
                        <td className="w-[70%] px-[16px] md:px-[30px]">
                          <Typography>
                            {`${
                              item.is_managerial_retired
                                ? '役職定年'
                                : item.company_salary_grade_name
                            } 年収${convertYenToManyen(item.initial_salary_yearly)}万円`}
                          </Typography>
                        </td>
                      </tr>
                    ),
                  )}
                </>
              )}

              {targetInputIncome?.input_income_changes?.map((item) => (
                <tr key={item.id} className="h-[45px] odd:bg-black-100">
                  <td className="w-[30%] px-[16px] md:px-[30px]">
                    <Typography isBold>{`${defaultToEmptyString(
                      item.scheduled_at_age,
                    )}歳`}</Typography>
                  </td>
                  <td className="w-[70%] px-[16px] md:px-[30px]">
                    <Typography>
                      {`${convertChangeType(item.change_type)} ${renderIncomeItemLabel(
                        item,
                      )}`}
                    </Typography>
                  </td>
                </tr>
              ))}

              <tr className="h-[45px] odd:bg-black-100">
                <td className="px-[16px] md:px-[30px]">
                  <Typography isBold>再就職</Typography>
                </td>
                <td className="px-[16px] md:px-[30px]">
                  <Typography>
                    {convertReEmploymentType(retirementAgeItem?.re_employment_type)}
                  </Typography>
                </td>
              </tr>
              {retirementAgeItem?.re_employment_type === API_FLAG.on && (
                <>
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="px-[16px] md:px-[30px]">
                      <Typography isBold>再就職後の給与</Typography>
                    </td>
                    <td className="px-[16px] md:px-[30px]">
                      <Typography>
                        {`年収 ${convertLocaleString(
                          retirementAgeItem?.re_employment_annual_income_manyen,
                        )}万円`}
                      </Typography>
                    </td>
                  </tr>
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="px-[16px] md:px-[30px]">
                      <Typography isBold>再就職後の退職年齢</Typography>
                    </td>
                    <td className="px-[16px] md:px-[30px]">
                      <Typography>
                        {`${defaultToEmptyString(
                          retirementAgeItem?.re_employment_finish_at_age,
                        )}歳`}
                      </Typography>
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        )}
      </div>
    )
  }

  return (
    <MyDataListWrapper title="収入" description="給与明細が必要です。ご用意ください。">
      {renderTable(true)}
      {renderTable(false)}
    </MyDataListWrapper>
  )
}

export default MyDataIncome
