import React, { FC, ChangeEvent } from 'react'
import { Field } from 'react-final-form'
import clsx from 'clsx'
import { isUndefined as _isUndefined } from 'lodash'
import SelectTriangleIcon from '@/assets/images/v2/select-triangle.svg'
import ValidMessage from '@/components/v2/atoms/ValidMessage'
import { isNotEmptyValue } from '@/models/commonsModelFunc'

export interface SelectBoxOption {
  /** 値 */
  value: any
  /** ラベル */
  label: string
  /** true: 非活性, false: 活性 */
  isDisabled?: boolean
}

interface SelectBoxProps {
  /** 名前 */
  name: string
  /** クラス名 */
  className?: string
  /** 初期値 */
  initialValue?: any
  /** selectタグ用クラス名 */
  selectClassName?: string
  /** 幅用クラス名 */
  widthClassName?: string
  /** 高さ用クラス名 */
  heightClassName?: string
  /** プレースホルダ */
  placeholder?: string
  /** プレースホルダを非表示 */
  isPlaceholderHidden?: boolean
  /** オプション */
  options: SelectBoxOption[]
  /** true: width100%, false: width100%無し */
  isFullWidth?: boolean
  /** true: 非活性, false: 活性 */
  isDisabled?: boolean
  /** バリデーションメソッド */
  validate?: (value: any, allValues: any) => void
  /** onChangeメソッド */
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void
  /** true: バリデーションメッセージを表示しない, false: バリデーションメッセージ表示あり */
  disableValidationMessage?: boolean
  /** true: プレースホルダーを再選択できる, false: プレースホルダー再選択できない */
  selectablePlaceholder?: boolean
}

const SelectBox: FC<SelectBoxProps> = (props) => {
  const {
    name,
    initialValue,
    className,
    selectClassName,
    widthClassName,
    heightClassName,
    placeholder,
    options,
    isFullWidth,
    isDisabled,
    validate,
    onChange,
    isPlaceholderHidden,
    disableValidationMessage,
    selectablePlaceholder,
  } = props

  const activeOptionColor = 'text-black-900'
  const inActiveOptionColor = 'text-black-400'

  return (
    <Field name={name} initialValue={initialValue} validate={validate}>
      {(props) => {
        const { input, meta } = props

        const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
          input.onChange(event)
          onChange?.(event)
        }

        return (
          <div
            className={clsx(className, widthClassName, {
              ['w-full']: isFullWidth,
            })}
          >
            <div
              className={clsx(`${widthClassName} inline-block relative`, {
                ['w-full']: isFullWidth,
              })}
            >
              <select
                {...input}
                className={clsx(
                  `${selectClassName} ${widthClassName} ${
                    heightClassName ?? 'h-44'
                  } appearance-none bg-white border border-black-300 pl-16 pr-1.5rem rounded-2 text-14 disabled:bg-black-200 hover:border-black-500 overflow-hidden`,
                  {
                    ['w-full']: isFullWidth,
                    [activeOptionColor]: isNotEmptyValue(input.value),
                    [inActiveOptionColor]: !isNotEmptyValue(input.value),
                  },
                )}
                disabled={isDisabled}
                onChange={handleChange}
              >
                {!isPlaceholderHidden && !_isUndefined(placeholder) ? (
                  <option
                    value=""
                    className={inActiveOptionColor}
                    disabled={!selectablePlaceholder}
                  >
                    {placeholder}
                  </option>
                ) : (
                  !isPlaceholderHidden && <option className="hidden" value="" />
                )}
                {options.map((item, index) => (
                  <option
                    key={`${name}_${item.value}_${index}`}
                    value={item.value}
                    disabled={item.isDisabled}
                    className={activeOptionColor}
                  >
                    {item.label}
                  </option>
                ))}
              </select>
              <div className="absolute flex inset-y-0 items-center pointer-events-none px-0.5rem right-0">
                <SelectTriangleIcon />
              </div>
            </div>
            {!disableValidationMessage && <ValidMessage meta={meta} />}
          </div>
        )
      }}
    </Field>
  )
}

export default SelectBox
