import React, { ChangeEvent, FC, useMemo } from 'react'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import ChevronRightIcon from '@/assets/images/v2/chevron-right.svg'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import Modal from '@/components/v2/atoms/Modal'
import Typography from '@/components/v2/atoms/Typography'
import SelectBox from '@/components/v2/molecules/SelectBox'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import NumberField from '@/components/v2/molecules/NumberField'
import { composeValidators, required, commaNumberMaxLength } from '@/utils/validate'
import { extractYearOrMonthFromDate } from '@/models/commonsModelFunc'
import { FieldArray } from 'react-final-form-arrays'
import { zeroOrMoreNumber } from '@/utils/validate'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/store'
import {
  EDUCATIONAL_POLICY_TYPE_SELECT_ITEMS,
  NORMAL_SCHOOL_TYPE_SELECT_ITEMS,
  UNIVERSITY_SCHOOL_TYPE_SELECT_ITEMS,
} from '@/constants/formItem/futures/futurePlanTuitions'
import Tooltip from '@/components/v2/atoms/Tooltip'
import {
  AGE_TYPE,
  buildReceiveTuition,
  buildSendTuition,
  convertEducationalPolicyTypeKey,
  EDUCATIONAL_POLICY_TYPE,
  isChildbirthWithinRange,
  SCHOOL_AGE,
  SCHOOL_KEY,
  SCHOOL_TYPE,
  SCHOOL_TYPE_TEMPLATES,
  SCHOOL_YEAR,
  TUITION_TEMPLATES,
} from '@/models/futures/futurePlanTuitionsModelFunc'
import {
  FuturePlanTuitionCreateValues,
  FuturePlanTuitionUpdateValues,
} from '@/models/futures/futurePlanTuitionsModel'
import { calcAge } from '@/models/familyStructuresModelFunc'
import { isUndefined as _isUndefined, isNull as _isNull, find as _find } from 'lodash'
import HelpCircleIcon from '@/assets/images/v2/help-circle.svg'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import { updateFutureFamilyChildbirthV2 } from '@/containers/futures/futureFamiliesSlice'
import { fetchAccountFutureItems } from '@/containers/accountsSlice'
import clsx from 'clsx'
import SubmitErrorMessage from '@/components/v2/molecules/SubmitErrorMessage'

interface FutureEducationCostModalFormProps {
  isEdit: boolean
  /** true: オープン, false: クローズ */
  isOpen: boolean
  /** onCloseメソッド */
  onClose: () => void

  id: number | null
  /* FutureItemsを参照するID */
  futureBaseId?: number
}

const FutureEducationCostModalForm: FC<FutureEducationCostModalFormProps> = ({
  isEdit,
  isOpen,
  onClose,
  id,
  futureBaseId,
}) => {
  const dispatch = useDispatch()
  const { showFlashMsg } = useFlashAlert()

  const futureFamilyBases = useSelector(
    (state: RootState) => state.accounts.futureItems?.future_family_bases,
  )

  const targetFamilyChildbirth: any = useMemo(() => {
    return futureFamilyBases?.reduce((v: any, futureFamilyBase: any) => {
      const filteredChildbirth = _find(futureFamilyBase.future_family_childbirths, { id })
      if (!!filteredChildbirth) {
        v = filteredChildbirth
      }
      return v
    }, null)
  }, [futureFamilyBases, id])

  const childbirth = useMemo(
    () =>
      extractYearOrMonthFromDate(
        targetFamilyChildbirth?.childbirth_scheduled_at,
        'year',
      ) || null,
    [targetFamilyChildbirth],
  )

  const handleSubmit = (
    values: FuturePlanTuitionCreateValues & FuturePlanTuitionUpdateValues,
  ) => {
    const { future_plan_tuitions } = values

    const createInputPlanTuitionFunc = (
      familyStructureChildId: number,
      childIndex: number,
    ) => {
      return new Promise((resolve) => {
        const newValues: any = {
          future_family_childbirth_id: familyStructureChildId,
          future_plan_tuitions_attributes: [],
        }
        const childAge = calcAge(childbirth)

        // 0〜3歳児保育
        newValues.future_plan_tuitions_attributes.push({
          future_family_childbirth_id: familyStructureChildId,
          age_type: AGE_TYPE.toddler,
          ...buildSendTuition(
            SCHOOL_KEY.toddler,
            SCHOOL_YEAR.toddler,
            !!id ? future_plan_tuitions?.[0] : future_plan_tuitions?.[childIndex],
          ),
        })

        // 幼稚園・保育園
        newValues.future_plan_tuitions_attributes.push({
          future_family_childbirth_id: familyStructureChildId,
          age_type: AGE_TYPE.kindergarten,
          school_type:
            childAge <= SCHOOL_AGE.kindergarten3
              ? future_plan_tuitions?.[0]?.kindergarten_school_type
              : null,
          ...buildSendTuition(
            SCHOOL_KEY.kindergarten,
            SCHOOL_YEAR.kindergarten,
            !!id ? future_plan_tuitions?.[0] : future_plan_tuitions?.[childIndex],
          ),
        })

        // 小学校
        newValues.future_plan_tuitions_attributes.push({
          future_family_childbirth_id: familyStructureChildId,
          age_type: AGE_TYPE.primarySchool,
          school_type:
            childAge <= SCHOOL_AGE.primarySchool6
              ? future_plan_tuitions?.[0]?.primary_school_type
              : null,
          ...buildSendTuition(
            SCHOOL_KEY.primarySchool,
            SCHOOL_YEAR.primarySchool,
            !!id ? future_plan_tuitions?.[0] : future_plan_tuitions?.[childIndex],
          ),
        })

        // 中学校
        newValues.future_plan_tuitions_attributes.push({
          future_family_childbirth_id: familyStructureChildId,
          age_type: AGE_TYPE.juniorHighSchool,
          school_type:
            childAge <= SCHOOL_AGE.juniorHighSchool3
              ? future_plan_tuitions?.[0]?.junior_high_school_type
              : null,
          ...buildSendTuition(
            SCHOOL_KEY.juniorHighSchool,
            SCHOOL_YEAR.juniorHighSchool,
            !!id ? future_plan_tuitions?.[0] : future_plan_tuitions?.[childIndex],
          ),
        })

        // 高校
        newValues.future_plan_tuitions_attributes.push({
          future_family_childbirth_id: familyStructureChildId,
          age_type: AGE_TYPE.highSchool,
          school_type:
            childAge <= SCHOOL_AGE.highSchool3
              ? future_plan_tuitions?.[0]?.high_school_type
              : null,
          ...buildSendTuition(
            SCHOOL_KEY.highSchool,
            SCHOOL_YEAR.highSchool,
            !!id ? future_plan_tuitions?.[0] : future_plan_tuitions?.[childIndex],
          ),
        })

        // 大学
        newValues.future_plan_tuitions_attributes.push({
          future_family_childbirth_id: familyStructureChildId,
          age_type: AGE_TYPE.university,
          school_type:
            childAge <= SCHOOL_AGE.university4
              ? future_plan_tuitions?.[0]?.university_school_type
              : null,
          ...buildSendTuition(
            SCHOOL_KEY.university,
            SCHOOL_YEAR.university,
            !!id ? future_plan_tuitions?.[0] : future_plan_tuitions?.[childIndex],
          ),
        })

        // 大学院
        newValues.future_plan_tuitions_attributes.push({
          future_family_childbirth_id: familyStructureChildId,
          age_type: AGE_TYPE.graduateSchool,
          ...buildSendTuition(
            SCHOOL_KEY.graduateSchool,
            SCHOOL_YEAR.graduateSchool,
            !!id ? future_plan_tuitions?.[0] : future_plan_tuitions?.[childIndex],
          ),
        })

        dispatch(
          updateFutureFamilyChildbirthV2(
            { future_family_childbirth: { ...newValues } },
            familyStructureChildId,
            () => {
              showFlashMsg(isEdit ? '更新しました' : '作成しました')
              dispatch(fetchAccountFutureItems(futureBaseId))
              onClose()
              resolve({})
            },
          ),
        )
      })
    }

    const updateInputPlanTuitionFunc = (familyStructureChildId: number) => {
      return new Promise(() => {
        const newValues: any = {
          future_family_childbirth_id: familyStructureChildId,
          future_plan_tuitions_attributes: [],
        }
        const targetPlanTuition = targetFamilyChildbirth?.future_plan_tuitions as any

        // 0〜3歳児保育
        targetPlanTuition[0] &&
          newValues.future_plan_tuitions_attributes.push({
            id: targetPlanTuition[0].id,
            age_type: AGE_TYPE.toddler,
            ...buildSendTuition(
              SCHOOL_KEY.toddler,
              SCHOOL_YEAR.toddler,
              future_plan_tuitions?.[0],
            ),
          })

        // 幼稚園・保育園
        targetPlanTuition[1] &&
          newValues.future_plan_tuitions_attributes.push({
            id: targetPlanTuition[1].id,
            age_type: AGE_TYPE.kindergarten,
            school_type: future_plan_tuitions?.[0]?.kindergarten_school_type,
            ...buildSendTuition(
              SCHOOL_KEY.kindergarten,
              SCHOOL_YEAR.kindergarten,
              future_plan_tuitions?.[0],
            ),
          })

        // 小学校
        targetPlanTuition[2] &&
          newValues.future_plan_tuitions_attributes.push({
            id: targetPlanTuition[2].id,
            age_type: AGE_TYPE.primarySchool,
            school_type: future_plan_tuitions?.[0]?.primary_school_type,
            ...buildSendTuition(
              SCHOOL_KEY.primarySchool,
              SCHOOL_YEAR.primarySchool,
              future_plan_tuitions?.[0],
            ),
          })

        // 中学校
        targetPlanTuition[3] &&
          newValues.future_plan_tuitions_attributes.push({
            id: targetPlanTuition[3].id,
            age_type: AGE_TYPE.juniorHighSchool,
            school_type: future_plan_tuitions?.[0]?.junior_high_school_type,
            ...buildSendTuition(
              SCHOOL_KEY.juniorHighSchool,
              SCHOOL_YEAR.juniorHighSchool,
              future_plan_tuitions?.[0],
            ),
          })

        // 高校
        targetPlanTuition[4] &&
          newValues.future_plan_tuitions_attributes.push({
            id: targetPlanTuition[4].id,
            age_type: AGE_TYPE.highSchool,
            school_type: future_plan_tuitions?.[0]?.high_school_type,
            ...buildSendTuition(
              SCHOOL_KEY.highSchool,
              SCHOOL_YEAR.highSchool,
              future_plan_tuitions?.[0],
            ),
          })

        // 大学
        targetPlanTuition[5] &&
          newValues.future_plan_tuitions_attributes.push({
            id: targetPlanTuition[5].id,
            age_type: AGE_TYPE.university,
            school_type: future_plan_tuitions?.[0]?.university_school_type,
            ...buildSendTuition(
              SCHOOL_KEY.university,
              SCHOOL_YEAR.university,
              future_plan_tuitions?.[0],
            ),
          })

        // 大学院
        targetPlanTuition[6] &&
          newValues.future_plan_tuitions_attributes.push({
            id: targetPlanTuition[6].id,
            age_type: AGE_TYPE.graduateSchool,
            ...buildSendTuition(
              SCHOOL_KEY.graduateSchool,
              SCHOOL_YEAR.graduateSchool,
              future_plan_tuitions?.[0],
            ),
          })

        dispatch(
          updateFutureFamilyChildbirthV2(
            { future_family_childbirth: { ...newValues } },
            familyStructureChildId,
            () => {
              dispatch(fetchAccountFutureItems(futureBaseId))
              showFlashMsg(isEdit ? '更新しました' : '作成しました')
              onClose()
            },
          ),
        )
      })
    }

    isEdit && !!targetFamilyChildbirth
      ? updateInputPlanTuitionFunc(Number(id)!)
      : !!targetFamilyChildbirth &&
        createInputPlanTuitionFunc(
          targetFamilyChildbirth.id,
          targetFamilyChildbirth.child_index,
        )
  }

  const builtInputPlanTuitions = useMemo(() => {
    return {
      future_plan_tuitions: !targetFamilyChildbirth
        ? [{}]
        : [targetFamilyChildbirth].map((item: any) => ({
            child_id: item.id,
            ...buildReceiveTuition(
              SCHOOL_KEY.toddler,
              SCHOOL_YEAR.toddler,
              item.future_plan_tuitions[0],
            ),
            kindergarten_school_type:
              !_isUndefined(item.future_plan_tuitions[1]) &&
              !_isNull(item.future_plan_tuitions[1].school_type)
                ? item.future_plan_tuitions[1].school_type
                : 0,
            ...buildReceiveTuition(
              SCHOOL_KEY.kindergarten,
              SCHOOL_YEAR.kindergarten,
              item.future_plan_tuitions[1],
            ),
            primary_school_type:
              !_isUndefined(item.future_plan_tuitions[2]) &&
              !_isNull(item.future_plan_tuitions[2].school_type)
                ? item.future_plan_tuitions[2].school_type
                : 0,
            ...buildReceiveTuition(
              SCHOOL_KEY.primarySchool,
              SCHOOL_YEAR.primarySchool,
              item.future_plan_tuitions[2],
            ),
            junior_high_school_type:
              !_isUndefined(item.future_plan_tuitions[3]) &&
              !_isNull(item.future_plan_tuitions[3].school_type)
                ? item.future_plan_tuitions[3].school_type
                : 0,
            ...buildReceiveTuition(
              SCHOOL_KEY.juniorHighSchool,
              SCHOOL_YEAR.juniorHighSchool,
              item.future_plan_tuitions[3],
            ),
            high_school_type:
              !_isUndefined(item.future_plan_tuitions[4]) &&
              !_isNull(item.future_plan_tuitions[4].school_type)
                ? item.future_plan_tuitions[4].school_type
                : 0,
            ...buildReceiveTuition(
              SCHOOL_KEY.highSchool,
              SCHOOL_YEAR.highSchool,
              item.future_plan_tuitions[4],
            ),
            university_school_type:
              !_isUndefined(item.future_plan_tuitions[5]) &&
              !_isNull(item.future_plan_tuitions[5].school_type)
                ? item.future_plan_tuitions[5].school_type
                : 0,
            ...buildReceiveTuition(
              SCHOOL_KEY.university,
              SCHOOL_YEAR.university,
              item.future_plan_tuitions[5],
            ),
            ...buildReceiveTuition(
              SCHOOL_KEY.graduateSchool,
              SCHOOL_YEAR.graduateSchool,
              item.future_plan_tuitions[6],
            ),
          })),
      educational_policy_type: String(EDUCATIONAL_POLICY_TYPE.useCreatedValues),
    }
  }, [targetFamilyChildbirth])

  const handleChangePolicyType = (
    name: string,
    educationalPolicyType: number,
    change: (key: string, value: number) => void,
  ) => {
    const key = convertEducationalPolicyTypeKey(educationalPolicyType)

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.toddler0)) {
      change(`${name}toddler_tuition_1_manyen`, TUITION_TEMPLATES[key].firstToddler)
      change(
        `${name}off_toddler_tuition_1_manyen`,
        TUITION_TEMPLATES[key].firstOffToddler,
      )
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.toddler1)) {
      change(`${name}toddler_tuition_2_manyen`, TUITION_TEMPLATES[key].nextToddler)
      change(`${name}off_toddler_tuition_2_manyen`, TUITION_TEMPLATES[key].nextOffToddler)
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.toddler2)) {
      change(`${name}toddler_tuition_3_manyen`, TUITION_TEMPLATES[key].nextToddler)
      change(`${name}off_toddler_tuition_3_manyen`, TUITION_TEMPLATES[key].nextOffToddler)
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.toddler3)) {
      change(`${name}toddler_tuition_4_manyen`, TUITION_TEMPLATES[key].nextToddler)
      change(`${name}off_toddler_tuition_4_manyen`, TUITION_TEMPLATES[key].nextOffToddler)
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.kindergarten1)) {
      change(
        `${name}kindergarten_tuition_1_manyen`,
        TUITION_TEMPLATES[key].firstKindergarten,
      )
      change(
        `${name}off_kindergarten_tuition_1_manyen`,
        TUITION_TEMPLATES[key].firstOffKindergarten,
      )
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.kindergarten2)) {
      change(
        `${name}kindergarten_tuition_2_manyen`,
        TUITION_TEMPLATES[key].nextKindergarten,
      )
      change(
        `${name}off_kindergarten_tuition_2_manyen`,
        TUITION_TEMPLATES[key].nextOffKindergarten,
      )
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.kindergarten3)) {
      change(
        `${name}kindergarten_tuition_3_manyen`,
        TUITION_TEMPLATES[key].nextKindergarten,
      )
      change(
        `${name}off_kindergarten_tuition_3_manyen`,
        TUITION_TEMPLATES[key].nextOffKindergarten,
      )
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.primarySchool1)) {
      change(
        `${name}primary_school_tuition_1_manyen`,
        TUITION_TEMPLATES[key].firstPrimarySchool,
      )
      change(
        `${name}off_primary_school_tuition_1_manyen`,
        TUITION_TEMPLATES[key].firstOffPrimarySchool,
      )
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.primarySchool2)) {
      change(
        `${name}primary_school_tuition_2_manyen`,
        TUITION_TEMPLATES[key].nextPrimarySchool,
      )
      change(
        `${name}off_primary_school_tuition_2_manyen`,
        TUITION_TEMPLATES[key].nextOffPrimarySchool,
      )
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.primarySchool3)) {
      change(
        `${name}primary_school_tuition_3_manyen`,
        TUITION_TEMPLATES[key].nextPrimarySchool,
      )
      change(
        `${name}off_primary_school_tuition_3_manyen`,
        TUITION_TEMPLATES[key].nextOffPrimarySchool,
      )
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.primarySchool4)) {
      change(
        `${name}primary_school_tuition_4_manyen`,
        TUITION_TEMPLATES[key].nextPrimarySchool,
      )
      change(
        `${name}off_primary_school_tuition_4_manyen`,
        TUITION_TEMPLATES[key].nextOffPrimarySchool,
      )
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.primarySchool5)) {
      change(
        `${name}primary_school_tuition_5_manyen`,
        TUITION_TEMPLATES[key].nextPrimarySchool,
      )
      change(
        `${name}off_primary_school_tuition_5_manyen`,
        TUITION_TEMPLATES[key].nextOffPrimarySchool,
      )
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.primarySchool6)) {
      change(
        `${name}primary_school_tuition_6_manyen`,
        TUITION_TEMPLATES[key].nextPrimarySchool,
      )
      change(
        `${name}off_primary_school_tuition_6_manyen`,
        TUITION_TEMPLATES[key].nextOffPrimarySchool,
      )
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.juniorHighSchool1)) {
      change(
        `${name}junior_high_school_tuition_1_manyen`,
        TUITION_TEMPLATES[key].firstJuniorHighSchool,
      )
      change(
        `${name}off_junior_high_school_tuition_1_manyen`,
        TUITION_TEMPLATES[key].firstOffJuniorHighSchool,
      )
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.juniorHighSchool2)) {
      change(
        `${name}junior_high_school_tuition_2_manyen`,
        TUITION_TEMPLATES[key].nextJuniorHighSchool,
      )
      change(
        `${name}off_junior_high_school_tuition_2_manyen`,
        TUITION_TEMPLATES[key].nextOffJuniorHighSchool,
      )
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.juniorHighSchool3)) {
      change(
        `${name}junior_high_school_tuition_3_manyen`,
        TUITION_TEMPLATES[key].nextJuniorHighSchool,
      )
      change(
        `${name}off_junior_high_school_tuition_3_manyen`,
        TUITION_TEMPLATES[key].nextOffJuniorHighSchool,
      )
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.highSchool1)) {
      change(
        `${name}high_school_tuition_1_manyen`,
        TUITION_TEMPLATES[key].firstHighSchool,
      )
      change(
        `${name}off_high_school_tuition_1_manyen`,
        TUITION_TEMPLATES[key].firstOffHighSchool,
      )
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.highSchool2)) {
      change(`${name}high_school_tuition_2_manyen`, TUITION_TEMPLATES[key].nextHighSchool)
      change(
        `${name}off_high_school_tuition_2_manyen`,
        TUITION_TEMPLATES[key].nextOffHighSchool,
      )
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.highSchool3)) {
      change(`${name}high_school_tuition_3_manyen`, TUITION_TEMPLATES[key].nextHighSchool)
      change(
        `${name}off_high_school_tuition_3_manyen`,
        TUITION_TEMPLATES[key].nextOffHighSchool,
      )
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.university1)) {
      change(`${name}university_tuition_1_manyen`, TUITION_TEMPLATES[key].firstUniversity)
      change(
        `${name}off_university_tuition_1_manyen`,
        TUITION_TEMPLATES[key].firstOffUniversity,
      )
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.university2)) {
      change(`${name}university_tuition_2_manyen`, TUITION_TEMPLATES[key].nextUniversity)
      change(
        `${name}off_university_tuition_2_manyen`,
        TUITION_TEMPLATES[key].nextOffUniversity,
      )
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.university3)) {
      change(`${name}university_tuition_3_manyen`, TUITION_TEMPLATES[key].nextUniversity)
      change(
        `${name}off_university_tuition_3_manyen`,
        TUITION_TEMPLATES[key].nextOffUniversity,
      )
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.university4)) {
      change(`${name}university_tuition_4_manyen`, TUITION_TEMPLATES[key].nextUniversity)
      change(
        `${name}off_university_tuition_4_manyen`,
        TUITION_TEMPLATES[key].nextOffUniversity,
      )
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.graduateSchool1)) {
      change(
        `${name}graduate_school_tuition_1_manyen`,
        TUITION_TEMPLATES[key].firstGraduateSchool,
      )
      change(
        `${name}off_graduate_school_tuition_1_manyen`,
        TUITION_TEMPLATES[key].firstOffGraduateSchool,
      )
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.graduateSchool2)) {
      change(
        `${name}graduate_school_tuition_2_manyen`,
        TUITION_TEMPLATES[key].nextGraduateSchool,
      )
      change(
        `${name}off_graduate_school_tuition_2_manyen`,
        TUITION_TEMPLATES[key].nextOffGraduateSchool,
      )
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.kindergarten3)) {
      change(`${name}kindergarten_school_type`, SCHOOL_TYPE_TEMPLATES[key].kindergarten)
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.primarySchool6)) {
      change(`${name}primary_school_type`, SCHOOL_TYPE_TEMPLATES[key].primarySchool)
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.juniorHighSchool3)) {
      change(
        `${name}junior_high_school_type`,
        SCHOOL_TYPE_TEMPLATES[key].juniorHighSchool,
      )
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.highSchool3)) {
      change(`${name}high_school_type`, SCHOOL_TYPE_TEMPLATES[key].highSchool)
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.university4)) {
      change(`${name}university_school_type`, SCHOOL_TYPE_TEMPLATES[key].university)
    }
  }

  const handleChangeSchoolType = (
    name: string,
    schoolKey: string,
    value: number,
    change: (key: string, value: number) => void,
  ) => {
    const targetTuitionTemplates =
      value === SCHOOL_TYPE.public
        ? TUITION_TEMPLATES.notReallyHang
        : value === SCHOOL_TYPE.privateLiberalArts
        ? TUITION_TEMPLATES.averageHang
        : TUITION_TEMPLATES.thoroughlyHang

    switch (schoolKey) {
      case SCHOOL_KEY.kindergarten:
        changeKindergartenTuition(name, targetTuitionTemplates, change)
        break
      case SCHOOL_KEY.primarySchool:
        changePrimarySchoolTuition(name, targetTuitionTemplates, change)
        break
      case SCHOOL_KEY.juniorHighSchool:
        changeJuniorHighSchoolTuition(name, targetTuitionTemplates, change)
        break
      case SCHOOL_KEY.highSchool:
        changeHighSchoolTuition(name, targetTuitionTemplates, change)
        break
      case SCHOOL_KEY.university:
        changeUniversityTuition(name, targetTuitionTemplates, change)
        break
      default:
        break
    }
  }

  const changeKindergartenTuition = (
    name: string,
    tuitionTemplate: { [key: string]: number },
    change: (key: string, value: number) => void,
  ) => {
    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.kindergarten1)) {
      change(`${name}kindergarten_tuition_1_manyen`, tuitionTemplate.firstKindergarten)
      change(
        `${name}off_kindergarten_tuition_1_manyen`,
        tuitionTemplate.firstOffKindergarten,
      )
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.kindergarten2)) {
      change(`${name}kindergarten_tuition_2_manyen`, tuitionTemplate.nextKindergarten)
      change(
        `${name}off_kindergarten_tuition_2_manyen`,
        tuitionTemplate.nextOffKindergarten,
      )
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.kindergarten3)) {
      change(`${name}kindergarten_tuition_3_manyen`, tuitionTemplate.nextKindergarten)
      change(
        `${name}off_kindergarten_tuition_3_manyen`,
        tuitionTemplate.nextOffKindergarten,
      )
    }
  }

  const changePrimarySchoolTuition = (
    name: string,
    tuitionTemplate: { [key: string]: number },
    change: (key: string, value: number) => void,
  ) => {
    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.primarySchool1)) {
      change(`${name}primary_school_tuition_1_manyen`, tuitionTemplate.firstPrimarySchool)
      change(
        `${name}off_primary_school_tuition_1_manyen`,
        tuitionTemplate.firstOffPrimarySchool,
      )
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.primarySchool2)) {
      change(`${name}primary_school_tuition_2_manyen`, tuitionTemplate.nextPrimarySchool)
      change(
        `${name}off_primary_school_tuition_2_manyen`,
        tuitionTemplate.nextOffPrimarySchool,
      )
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.primarySchool3)) {
      change(`${name}primary_school_tuition_3_manyen`, tuitionTemplate.nextPrimarySchool)
      change(
        `${name}off_primary_school_tuition_3_manyen`,
        tuitionTemplate.nextOffPrimarySchool,
      )
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.primarySchool4)) {
      change(`${name}primary_school_tuition_4_manyen`, tuitionTemplate.nextPrimarySchool)
      change(
        `${name}off_primary_school_tuition_4_manyen`,
        tuitionTemplate.nextOffPrimarySchool,
      )
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.primarySchool5)) {
      change(`${name}primary_school_tuition_5_manyen`, tuitionTemplate.nextPrimarySchool)
      change(
        `${name}off_primary_school_tuition_5_manyen`,
        tuitionTemplate.nextOffPrimarySchool,
      )
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.primarySchool6)) {
      change(`${name}primary_school_tuition_6_manyen`, tuitionTemplate.nextPrimarySchool)
      change(
        `${name}off_primary_school_tuition_6_manyen`,
        tuitionTemplate.nextOffPrimarySchool,
      )
    }
  }

  const changeJuniorHighSchoolTuition = (
    name: string,
    tuitionTemplate: { [key: string]: number },
    change: (key: string, value: number) => void,
  ) => {
    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.juniorHighSchool1)) {
      change(
        `${name}junior_high_school_tuition_1_manyen`,
        tuitionTemplate.firstJuniorHighSchool,
      )
      change(
        `${name}off_junior_high_school_tuition_1_manyen`,
        tuitionTemplate.firstOffJuniorHighSchool,
      )
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.juniorHighSchool2)) {
      change(
        `${name}junior_high_school_tuition_2_manyen`,
        tuitionTemplate.nextJuniorHighSchool,
      )
      change(
        `${name}off_junior_high_school_tuition_2_manyen`,
        tuitionTemplate.nextOffJuniorHighSchool,
      )
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.juniorHighSchool3)) {
      change(
        `${name}junior_high_school_tuition_3_manyen`,
        tuitionTemplate.nextJuniorHighSchool,
      )
      change(
        `${name}off_junior_high_school_tuition_3_manyen`,
        tuitionTemplate.nextOffJuniorHighSchool,
      )
    }
  }

  const changeHighSchoolTuition = (
    name: string,
    tuitionTemplate: { [key: string]: number },
    change: (key: string, value: number) => void,
  ) => {
    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.highSchool1)) {
      change(`${name}high_school_tuition_1_manyen`, tuitionTemplate.firstHighSchool)
      change(
        `${name}off_high_school_tuition_1_manyen`,
        tuitionTemplate.firstOffHighSchool,
      )
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.highSchool2)) {
      change(`${name}high_school_tuition_2_manyen`, tuitionTemplate.nextHighSchool)
      change(`${name}off_high_school_tuition_2_manyen`, tuitionTemplate.nextOffHighSchool)
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.highSchool3)) {
      change(`${name}high_school_tuition_3_manyen`, tuitionTemplate.nextHighSchool)
      change(`${name}off_high_school_tuition_3_manyen`, tuitionTemplate.nextOffHighSchool)
    }
  }

  const changeUniversityTuition = (
    name: string,
    tuitionTemplate: { [key: string]: number },
    change: (key: string, value: number) => void,
  ) => {
    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.university1)) {
      change(`${name}university_tuition_1_manyen`, tuitionTemplate.firstUniversity)
      change(`${name}off_university_tuition_1_manyen`, tuitionTemplate.firstOffUniversity)
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.university2)) {
      change(`${name}university_tuition_2_manyen`, tuitionTemplate.nextUniversity)
      change(`${name}off_university_tuition_2_manyen`, tuitionTemplate.nextOffUniversity)
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.university3)) {
      change(`${name}university_tuition_3_manyen`, tuitionTemplate.nextUniversity)
      change(`${name}off_university_tuition_3_manyen`, tuitionTemplate.nextOffUniversity)
    }

    if (isChildbirthWithinRange(childbirth, SCHOOL_AGE.university4)) {
      change(`${name}university_tuition_4_manyen`, tuitionTemplate.nextUniversity)
      change(`${name}off_university_tuition_4_manyen`, tuitionTemplate.nextOffUniversity)
    }
  }

  const initialValues: any = useMemo(
    () =>
      isEdit
        ? { future_plan_tuitions: builtInputPlanTuitions.future_plan_tuitions }
        : { future_plan_tuitions: [{}] },
    [targetFamilyChildbirth, isEdit, builtInputPlanTuitions],
  )

  return (
    <Modal
      className="w-[95%] xl:w-[1140px]"
      isOpen={isOpen}
      isDisableBackdropClick
      onClose={onClose}
    >
      <CircleTitle
        className="pb-[32px]"
        title="将来の教育費を想定する"
        classNameTitle="text-[19px] md:text-[24px]"
      />
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        mutators={{ ...arrayMutators }}
        render={({
          handleSubmit,
          values,
          errors,
          submitErrors,
          submitFailed,
          form: { change },
        }) => (
          <form onSubmit={handleSubmit}>
            <FieldArray name="future_plan_tuitions">
              {({ fields }) =>
                fields.map((name) => (
                  <div key={name} className="pb-[64px] last:pb-0">
                    <div className="md:flex md:w-6/12 pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                      <Typography
                        className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                        isBold
                      >
                        教育方針
                      </Typography>
                      <SelectBox
                        name={`educational_policy_type`}
                        placeholder="選択する"
                        options={EDUCATIONAL_POLICY_TYPE_SELECT_ITEMS}
                        isFullWidth
                        onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                          handleChangePolicyType(name, Number(event.target.value), change)
                        }
                      />
                    </div>

                    <div className="md:flex justify-between items-center py-[20px] border-b border-black-300">
                      <Typography className="pb-[10px] md:pb-0 text-[14px]">
                        0〜3歳児保育
                      </Typography>
                      <Typography className="pb-[20px] md:pb-0 text-[14px]">
                        ー
                      </Typography>

                      <div className="md:flex items-center">
                        <div className="flex items-center pb-[10px] md:pb-0 pr-[20px]">
                          <Typography className="pr-[5px] text-[14px]">
                            学費+学外費
                          </Typography>
                          <Tooltip
                            item="※学外費とは、学習塾や家庭教師・習い事などの活動費のことを指します。"
                            itemClassName="w-[200px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                          >
                            <HelpCircleIcon />
                          </Tooltip>
                        </div>
                        <div>
                          {isChildbirthWithinRange(childbirth, SCHOOL_AGE.toddler0) ? (
                            <div className="flex items-baseline pb-[10px] last:pb-0">
                              <NumberField
                                name={`${name}toddler_tuition_1_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="px-[8px] text-[14px]">
                                万円
                              </Typography>
                              <NumberField
                                name={`${name}off_toddler_tuition_1_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="pl-[8px] text-[14px]">
                                万円
                              </Typography>
                            </div>
                          ) : (
                            <Typography className="pb-[10px] last:pb-0 text-[14px] text-center">
                              ー
                            </Typography>
                          )}
                          {isChildbirthWithinRange(childbirth, SCHOOL_AGE.toddler1) ? (
                            <div className="flex items-baseline pb-[10px] last:pb-0">
                              <NumberField
                                name={`${name}toddler_tuition_2_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="px-[8px] text-[14px]">
                                万円
                              </Typography>
                              <NumberField
                                name={`${name}off_toddler_tuition_2_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="pl-[8px] text-[14px]">
                                万円
                              </Typography>
                            </div>
                          ) : (
                            <Typography className="pb-[10px] last:pb-0 text-[14px] text-center">
                              ー
                            </Typography>
                          )}
                          {isChildbirthWithinRange(childbirth!, SCHOOL_AGE.toddler2) ? (
                            <div className="flex items-baseline pb-[10px] last:pb-0">
                              <NumberField
                                name={`${name}toddler_tuition_3_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="px-[8px] text-[14px]">
                                万円
                              </Typography>
                              <NumberField
                                name={`${name}off_toddler_tuition_3_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="pl-[8px] text-[14px]">
                                万円
                              </Typography>
                            </div>
                          ) : (
                            <Typography className="pb-[10px] last:pb-0 text-[14px] text-center">
                              ー
                            </Typography>
                          )}
                          {isChildbirthWithinRange(childbirth!, SCHOOL_AGE.toddler3) ? (
                            <div className="flex items-baseline pb-[10px] last:pb-0">
                              <NumberField
                                name={`${name}toddler_tuition_4_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="px-[8px] text-[14px]">
                                万円
                              </Typography>
                              <NumberField
                                name={`${name}off_toddler_tuition_4_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="pl-[8px] text-[14px]">
                                万円
                              </Typography>
                            </div>
                          ) : (
                            <Typography className="pb-[10px] last:pb-0 text-[14px] text-center">
                              ー
                            </Typography>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="md:flex justify-between items-center py-[20px] border-b border-black-300">
                      <Typography className="pb-[10px] md:pb-0 text-[14px]">
                        幼稚園
                        <br />
                        保育園
                      </Typography>
                      {isChildbirthWithinRange(childbirth!, SCHOOL_AGE.kindergarten3) ? (
                        <SelectBox
                          name={`${name}kindergarten_school_type`}
                          className="pb-[20px] md:pb-0"
                          placeholder="選択する"
                          options={NORMAL_SCHOOL_TYPE_SELECT_ITEMS}
                          validate={required}
                          onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                            handleChangeSchoolType(
                              name,
                              SCHOOL_KEY.kindergarten,
                              Number(event.target.value),
                              change,
                            )
                          }
                        />
                      ) : (
                        <Typography className="pb-[20px] md:pb-0 text-[14px]">
                          ー
                        </Typography>
                      )}

                      <div className="md:flex items-center">
                        <div className="flex items-center pb-[10px] md:pb-0 pr-[20px]">
                          <Typography className="pr-[5px] text-[14px]">
                            学費+学外費
                          </Typography>
                          <Tooltip
                            item="※学外費とは、学習塾や家庭教師・習い事などの活動費のことを指します。"
                            itemClassName="w-[200px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                          >
                            <HelpCircleIcon />
                          </Tooltip>
                        </div>
                        <div>
                          {isChildbirthWithinRange(
                            childbirth!,
                            SCHOOL_AGE.kindergarten1,
                          ) ? (
                            <div className="flex items-baseline pb-[10px] last:pb-0">
                              <NumberField
                                name={`${name}kindergarten_tuition_1_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="px-[8px] text-[14px]">
                                万円
                              </Typography>
                              <NumberField
                                name={`${name}off_kindergarten_tuition_1_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="pl-[8px] text-[14px]">
                                万円
                              </Typography>
                            </div>
                          ) : (
                            <Typography className="pb-[10px] last:pb-0 text-[14px] text-center">
                              ー
                            </Typography>
                          )}
                          {isChildbirthWithinRange(
                            childbirth!,
                            SCHOOL_AGE.kindergarten2,
                          ) ? (
                            <div className="flex items-baseline pb-[10px] last:pb-0">
                              <NumberField
                                name={`${name}kindergarten_tuition_2_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="px-[8px] text-[14px]">
                                万円
                              </Typography>
                              <NumberField
                                name={`${name}off_kindergarten_tuition_2_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="pl-[8px] text-[14px]">
                                万円
                              </Typography>
                            </div>
                          ) : (
                            <Typography className="pb-[10px] last:pb-0 text-[14px] text-center">
                              ー
                            </Typography>
                          )}
                          {isChildbirthWithinRange(
                            childbirth!,
                            SCHOOL_AGE.kindergarten3,
                          ) ? (
                            <div className="flex items-baseline pb-[10px] last:pb-0">
                              <NumberField
                                name={`${name}kindergarten_tuition_3_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="px-[8px] text-[14px]">
                                万円
                              </Typography>
                              <NumberField
                                name={`${name}off_kindergarten_tuition_3_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="pl-[8px] text-[14px]">
                                万円
                              </Typography>
                            </div>
                          ) : (
                            <Typography className="pb-[10px] last:pb-0 text-[14px] text-center">
                              ー
                            </Typography>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="md:flex justify-between items-center py-[20px] border-b border-black-300">
                      <Typography className="pb-[10px] md:pb-0 text-[14px]">
                        小学校
                      </Typography>
                      {isChildbirthWithinRange(childbirth!, SCHOOL_AGE.primarySchool6) ? (
                        <SelectBox
                          name={`${name}primary_school_type`}
                          className="pb-[20px] md:pb-0"
                          placeholder="選択する"
                          options={NORMAL_SCHOOL_TYPE_SELECT_ITEMS}
                          validate={required}
                          onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                            handleChangeSchoolType(
                              name,
                              SCHOOL_KEY.primarySchool,
                              Number(event.target.value),
                              change,
                            )
                          }
                        />
                      ) : (
                        <Typography className="pb-[20px] md:pb-0 text-[14px]">
                          ー
                        </Typography>
                      )}

                      <div className="md:flex items-center">
                        <div className="flex items-center pb-[10px] md:pb-0 pr-[20px]">
                          <Typography className="pr-[5px] text-[14px]">
                            学費+学外費
                          </Typography>
                          <Tooltip
                            item="※学外費とは、学習塾や家庭教師・習い事などの活動費のことを指します。"
                            itemClassName="w-[200px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                          >
                            <HelpCircleIcon />
                          </Tooltip>
                        </div>
                        <div>
                          {isChildbirthWithinRange(
                            childbirth!,
                            SCHOOL_AGE.primarySchool1,
                          ) ? (
                            <div className="flex items-baseline pb-[10px] last:pb-0">
                              <NumberField
                                name={`${name}primary_school_tuition_1_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="px-[8px] text-[14px]">
                                万円
                              </Typography>
                              <NumberField
                                name={`${name}off_primary_school_tuition_1_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="pl-[8px] text-[14px]">
                                万円
                              </Typography>
                            </div>
                          ) : (
                            <Typography className="pb-[10px] last:pb-0 text-[14px] text-center">
                              ー
                            </Typography>
                          )}
                          {isChildbirthWithinRange(
                            childbirth!,
                            SCHOOL_AGE.primarySchool2,
                          ) ? (
                            <div className="flex items-baseline pb-[10px] last:pb-0">
                              <NumberField
                                name={`${name}primary_school_tuition_2_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="px-[8px] text-[14px]">
                                万円
                              </Typography>
                              <NumberField
                                name={`${name}off_primary_school_tuition_2_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="pl-[8px] text-[14px]">
                                万円
                              </Typography>
                            </div>
                          ) : (
                            <Typography className="pb-[10px] last:pb-0 text-[14px] text-center">
                              ー
                            </Typography>
                          )}
                          {isChildbirthWithinRange(
                            childbirth!,
                            SCHOOL_AGE.primarySchool3,
                          ) ? (
                            <div className="flex items-baseline pb-[10px] last:pb-0">
                              <NumberField
                                name={`${name}primary_school_tuition_3_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="px-[8px] text-[14px]">
                                万円
                              </Typography>
                              <NumberField
                                name={`${name}off_primary_school_tuition_3_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="pl-[8px] text-[14px]">
                                万円
                              </Typography>
                            </div>
                          ) : (
                            <Typography className="pb-[10px] last:pb-0 text-[14px] text-center">
                              ー
                            </Typography>
                          )}
                          {isChildbirthWithinRange(
                            childbirth!,
                            SCHOOL_AGE.primarySchool4,
                          ) ? (
                            <div className="flex items-baseline pb-[10px] last:pb-0">
                              <NumberField
                                name={`${name}primary_school_tuition_4_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="px-[8px] text-[14px]">
                                万円
                              </Typography>
                              <NumberField
                                name={`${name}off_primary_school_tuition_4_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="pl-[8px] text-[14px]">
                                万円
                              </Typography>
                            </div>
                          ) : (
                            <Typography className="pb-[10px] last:pb-0 text-[14px] text-center">
                              ー
                            </Typography>
                          )}
                          {isChildbirthWithinRange(
                            childbirth!,
                            SCHOOL_AGE.primarySchool5,
                          ) ? (
                            <div className="flex items-baseline pb-[10px] last:pb-0">
                              <NumberField
                                name={`${name}primary_school_tuition_5_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="px-[8px] text-[14px]">
                                万円
                              </Typography>
                              <NumberField
                                name={`${name}off_primary_school_tuition_5_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="pl-[8px] text-[14px]">
                                万円
                              </Typography>
                            </div>
                          ) : (
                            <Typography className="pb-[10px] last:pb-0 text-[14px] text-center">
                              ー
                            </Typography>
                          )}
                          {isChildbirthWithinRange(
                            childbirth!,
                            SCHOOL_AGE.primarySchool6,
                          ) ? (
                            <div className="flex items-baseline pb-[10px] last:pb-0">
                              <NumberField
                                name={`${name}primary_school_tuition_6_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="px-[8px] text-[14px]">
                                万円
                              </Typography>
                              <NumberField
                                name={`${name}off_primary_school_tuition_6_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="pl-[8px] text-[14px]">
                                万円
                              </Typography>
                            </div>
                          ) : (
                            <Typography className="pb-[10px] last:pb-0 text-[14px] text-center">
                              ー
                            </Typography>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="md:flex justify-between items-center py-[20px] border-b border-black-300">
                      <Typography className="pb-[10px] md:pb-0 text-[14px]">
                        中学校
                      </Typography>
                      {isChildbirthWithinRange(
                        childbirth!,
                        SCHOOL_AGE.juniorHighSchool3,
                      ) ? (
                        <SelectBox
                          name={`${name}junior_high_school_type`}
                          className="pb-[20px] md:pb-0"
                          placeholder="選択する"
                          options={NORMAL_SCHOOL_TYPE_SELECT_ITEMS}
                          validate={required}
                          onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                            handleChangeSchoolType(
                              name,
                              SCHOOL_KEY.juniorHighSchool,
                              Number(event.target.value),
                              change,
                            )
                          }
                        />
                      ) : (
                        <Typography className="pb-[20px] md:pb-0 text-[14px]">
                          ー
                        </Typography>
                      )}

                      <div className="md:flex items-center">
                        <div className="flex items-center pb-[10px] md:pb-0 pr-[20px]">
                          <Typography className="pr-[5px] text-[14px]">
                            学費+学外費
                          </Typography>
                          <Tooltip
                            item="※学外費とは、学習塾や家庭教師・習い事などの活動費のことを指します。"
                            itemClassName="w-[200px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                          >
                            <HelpCircleIcon />
                          </Tooltip>
                        </div>
                        <div>
                          {isChildbirthWithinRange(
                            childbirth!,
                            SCHOOL_AGE.juniorHighSchool1,
                          ) ? (
                            <div className="flex items-baseline pb-[10px] last:pb-0">
                              <NumberField
                                name={`${name}junior_high_school_tuition_1_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="px-[8px] text-[14px]">
                                万円
                              </Typography>
                              <NumberField
                                name={`${name}off_junior_high_school_tuition_1_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="pl-[8px] text-[14px]">
                                万円
                              </Typography>
                            </div>
                          ) : (
                            <Typography className="pb-[10px] last:pb-0 text-[14px] text-center">
                              ー
                            </Typography>
                          )}
                          {isChildbirthWithinRange(
                            childbirth!,
                            SCHOOL_AGE.juniorHighSchool2,
                          ) ? (
                            <div className="flex items-baseline pb-[10px] last:pb-0">
                              <NumberField
                                name={`${name}junior_high_school_tuition_2_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="px-[8px] text-[14px]">
                                万円
                              </Typography>
                              <NumberField
                                name={`${name}off_junior_high_school_tuition_2_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="pl-[8px] text-[14px]">
                                万円
                              </Typography>
                            </div>
                          ) : (
                            <Typography className="pb-[10px] last:pb-0 text-[14px] text-center">
                              ー
                            </Typography>
                          )}
                          {isChildbirthWithinRange(
                            childbirth!,
                            SCHOOL_AGE.juniorHighSchool3,
                          ) ? (
                            <div className="flex items-baseline pb-[10px] last:pb-0">
                              <NumberField
                                name={`${name}junior_high_school_tuition_3_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="px-[8px] text-[14px]">
                                万円
                              </Typography>
                              <NumberField
                                name={`${name}off_junior_high_school_tuition_3_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="pl-[8px] text-[14px]">
                                万円
                              </Typography>
                            </div>
                          ) : (
                            <Typography className="pb-[10px] last:pb-0 text-[14px] text-center">
                              ー
                            </Typography>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="md:flex justify-between items-center py-[20px] border-b border-black-300">
                      <Typography className="pb-[10px] md:pb-0 text-[14px]">
                        高校
                      </Typography>
                      {isChildbirthWithinRange(childbirth!, SCHOOL_AGE.highSchool3) ? (
                        <SelectBox
                          name={`${name}high_school_type`}
                          className="pb-[20px] md:pb-0"
                          placeholder="選択する"
                          options={NORMAL_SCHOOL_TYPE_SELECT_ITEMS}
                          validate={required}
                          onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                            handleChangeSchoolType(
                              name,
                              SCHOOL_KEY.highSchool,
                              Number(event.target.value),
                              change,
                            )
                          }
                        />
                      ) : (
                        <Typography className="pb-[20px] md:pb-0 text-[14px]">
                          ー
                        </Typography>
                      )}

                      <div className="md:flex items-center">
                        <div className="flex items-center pb-[10px] md:pb-0 pr-[20px]">
                          <Typography className="pr-[5px] text-[14px]">
                            学費+学外費
                          </Typography>
                          <Tooltip
                            item="※学外費とは、学習塾や家庭教師・習い事などの活動費のことを指します。"
                            itemClassName="w-[200px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                          >
                            <HelpCircleIcon />
                          </Tooltip>
                        </div>
                        <div>
                          {isChildbirthWithinRange(
                            childbirth!,
                            SCHOOL_AGE.highSchool1,
                          ) ? (
                            <div className="flex items-baseline pb-[10px] last:pb-0">
                              <NumberField
                                name={`${name}high_school_tuition_1_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="px-[8px] text-[14px]">
                                万円
                              </Typography>
                              <NumberField
                                name={`${name}off_high_school_tuition_1_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="pl-[8px] text-[14px]">
                                万円
                              </Typography>
                            </div>
                          ) : (
                            <Typography className="pb-[10px] last:pb-0 text-[14px] text-center">
                              ー
                            </Typography>
                          )}
                          {isChildbirthWithinRange(
                            childbirth!,
                            SCHOOL_AGE.highSchool2,
                          ) ? (
                            <div className="flex items-baseline pb-[10px] last:pb-0">
                              <NumberField
                                name={`${name}high_school_tuition_2_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="px-[8px] text-[14px]">
                                万円
                              </Typography>
                              <NumberField
                                name={`${name}off_high_school_tuition_2_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="pl-[8px] text-[14px]">
                                万円
                              </Typography>
                            </div>
                          ) : (
                            <Typography className="pb-[10px] last:pb-0 text-[14px] text-center">
                              ー
                            </Typography>
                          )}
                          {isChildbirthWithinRange(
                            childbirth!,
                            SCHOOL_AGE.highSchool3,
                          ) ? (
                            <div className="flex items-baseline pb-[10px] last:pb-0">
                              <NumberField
                                name={`${name}high_school_tuition_3_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="px-[8px] text-[14px]">
                                万円
                              </Typography>
                              <NumberField
                                name={`${name}off_high_school_tuition_3_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="pl-[8px] text-[14px]">
                                万円
                              </Typography>
                            </div>
                          ) : (
                            <Typography className="pb-[10px] last:pb-0 text-[14px] text-center">
                              ー
                            </Typography>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="md:flex justify-between items-center py-[20px] border-b border-black-300">
                      <Typography className="pb-[10px] md:pb-0 text-[14px]">
                        大学
                      </Typography>
                      {isChildbirthWithinRange(childbirth!, SCHOOL_AGE.university4) ? (
                        <SelectBox
                          name={`${name}university_school_type`}
                          className="pb-[20px] md:pb-0"
                          placeholder="選択する"
                          options={UNIVERSITY_SCHOOL_TYPE_SELECT_ITEMS}
                          validate={required}
                          onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                            handleChangeSchoolType(
                              name,
                              SCHOOL_KEY.university,
                              Number(event.target.value),
                              change,
                            )
                          }
                        />
                      ) : (
                        <Typography className="pb-[20px] md:pb-0 text-[14px]">
                          ー
                        </Typography>
                      )}

                      <div className="md:flex items-center">
                        <div className="flex items-center pb-[10px] md:pb-0 pr-[20px]">
                          <Typography className="pr-[5px] text-[14px]">
                            学費+学外費
                          </Typography>
                          <Tooltip
                            item="※学外費とは、学習塾や家庭教師・習い事などの活動費のことを指します。"
                            itemClassName="w-[200px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                          >
                            <HelpCircleIcon />
                          </Tooltip>
                        </div>
                        <div>
                          {isChildbirthWithinRange(
                            childbirth!,
                            SCHOOL_AGE.university1,
                          ) ? (
                            <div className="flex items-baseline pb-[10px] last:pb-0">
                              <NumberField
                                name={`${name}university_tuition_1_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="px-[8px] text-[14px]">
                                万円
                              </Typography>
                              <NumberField
                                name={`${name}off_university_tuition_1_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="pl-[8px] text-[14px]">
                                万円
                              </Typography>
                            </div>
                          ) : (
                            <Typography className="pb-[10px] last:pb-0 text-[14px] text-center">
                              ー
                            </Typography>
                          )}
                          {isChildbirthWithinRange(
                            childbirth!,
                            SCHOOL_AGE.university2,
                          ) ? (
                            <div className="flex items-baseline pb-[10px] last:pb-0">
                              <NumberField
                                name={`${name}university_tuition_2_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="px-[8px] text-[14px]">
                                万円
                              </Typography>
                              <NumberField
                                name={`${name}off_university_tuition_2_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="pl-[8px] text-[14px]">
                                万円
                              </Typography>
                            </div>
                          ) : (
                            <Typography className="pb-[10px] last:pb-0 text-[14px] text-center">
                              ー
                            </Typography>
                          )}
                          {isChildbirthWithinRange(
                            childbirth!,
                            SCHOOL_AGE.university3,
                          ) ? (
                            <div className="flex items-baseline pb-[10px] last:pb-0">
                              <NumberField
                                name={`${name}university_tuition_3_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="px-[8px] text-[14px]">
                                万円
                              </Typography>
                              <NumberField
                                name={`${name}off_university_tuition_3_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="pl-[8px] text-[14px]">
                                万円
                              </Typography>
                            </div>
                          ) : (
                            <Typography className="pb-[10px] last:pb-0 text-[14px] text-center">
                              ー
                            </Typography>
                          )}
                          {isChildbirthWithinRange(
                            childbirth!,
                            SCHOOL_AGE.university4,
                          ) ? (
                            <div className="flex items-baseline pb-[10px] last:pb-0">
                              <NumberField
                                name={`${name}university_tuition_4_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="px-[8px] text-[14px]">
                                万円
                              </Typography>
                              <NumberField
                                name={`${name}off_university_tuition_4_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="pl-[8px] text-[14px]">
                                万円
                              </Typography>
                            </div>
                          ) : (
                            <Typography className="pb-[10px] last:pb-0 text-[14px] text-center">
                              ー
                            </Typography>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="md:flex justify-between items-center py-[20px] border-b border-black-300">
                      <Typography className="pb-[10px] md:pb-0 text-[14px]">
                        大学院
                      </Typography>
                      <Typography className="pb-[20px] md:pb-0 text-[14px]">
                        ー
                      </Typography>

                      <div className="md:flex items-center">
                        <div className="flex items-center pb-[10px] md:pb-0 pr-[20px]">
                          <Typography className="pr-[5px] text-[14px]">
                            学費+学外費
                          </Typography>
                          <Tooltip
                            item="※学外費とは、学習塾や家庭教師・習い事などの活動費のことを指します。"
                            itemClassName="w-[200px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                          >
                            <HelpCircleIcon />
                          </Tooltip>
                        </div>
                        <div>
                          {isChildbirthWithinRange(
                            childbirth!,
                            SCHOOL_AGE.graduateSchool1,
                          ) ? (
                            <div className="flex items-baseline pb-[10px] last:pb-0">
                              <NumberField
                                name={`${name}graduate_school_tuition_1_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="px-[8px] text-[14px]">
                                万円
                              </Typography>
                              <NumberField
                                name={`${name}off_graduate_school_tuition_1_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="pl-[8px] text-[14px]">
                                万円
                              </Typography>
                            </div>
                          ) : (
                            <Typography className="pb-[10px] last:pb-0 text-[14px] text-center">
                              ー
                            </Typography>
                          )}
                          {isChildbirthWithinRange(
                            childbirth!,
                            SCHOOL_AGE.graduateSchool2,
                          ) ? (
                            <div className="flex items-baseline pb-[10px] last:pb-0">
                              <NumberField
                                name={`${name}graduate_school_tuition_2_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="px-[8px] text-[14px]">
                                万円
                              </Typography>
                              <NumberField
                                name={`${name}off_graduate_school_tuition_2_manyen`}
                                widthClassName="max-w-[100px]"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="pl-[8px] text-[14px]">
                                万円
                              </Typography>
                            </div>
                          ) : (
                            <Typography className="pb-[10px] last:pb-0 text-[14px] text-center">
                              ー
                            </Typography>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              }
            </FieldArray>

            <SubmitErrorMessage show={!submitErrors && submitFailed} />
            <ButtonPrimary
              className={clsx(
                { ['opacity-50']: Object.keys(errors).length > 0 },
                'w-[fit-content] md:w-[246px] h-[64px] mx-[auto]',
              )}
              isDisabled={Object.keys(errors).length > 0}
              onClick={() => handleSubmit(values)}
            >
              <Typography
                className="bottom-2 flex-auto pl-30 relative text-16 text-white"
                isBold
              >
                保存する
              </Typography>
              <ChevronRightIcon className="mr-17" />
            </ButtonPrimary>
          </form>
        )}
      />
    </Modal>
  )
}

export default FutureEducationCostModalForm
