import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { filter as _filter, isEmpty as _isEmpty, isNull as _isNull } from 'lodash'
import Typography from '@/components/v2/atoms/Typography'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import {
  convertDepreciation,
  convertMonthlyPeriodCost,
  convertMonthlyPeriodCostWithRent,
  REPAIR_RESERVE_FUND_INCREASE_TYPE,
} from '@/models/commonEstateInvestmentModelFunc'
import {
  convertLocaleString,
  combineTwoTypesYear,
  API_FLAG,
  convertDateToTwoTypesYearsDateFormat,
} from '@/models/commonsModelFunc'
import { convertPersonFlg } from '@/models/familyStructuresModelFunc'
import { convertInputDecline } from '@/models/inputs/inputDeclinesModelFunc'
import {
  convertLifeInsurance,
  convertLoanType,
  convertPropertyType,
  convertRepaymentChart,
  convertRepaymentTwoTypesYearMonthDate,
  INPUT_MANAGEMENT_FEE_TYPE,
  INPUT_PROPERTY_TYPE,
} from '@/models/inputs/inputEstateInvestmentsModelFunc'
import { convertRateStartAt } from '@/models/inputs/inputRateChangesModelFunc'
import {
  convertAdvanceRepaymentAt,
  convertRepaymentType,
} from '@/models/inputs/inputEstateLoanCalculationsModelFunc'
import { RootState } from '@/store'
import SimulationAccordion from '@/templates/v2/simulations/_simulationAccordion'
import Modal from '@/components/v2/atoms/Modal'
import Button from '@/components/v2/atoms/Button'
import SimulationNewButton from '@/templates/v2/simulations/_simulationNewButton'
import MyDataEstateInvestmentForm from '@/templates/v2/myData/estateInvestments/Form'
import Tooltip from '@/components/v2/atoms/Tooltip'
import { fetchInputEstateInvestmentTaxSetting, updateInputEstateInvestmentTaxSetting } from '@/containers/inputs/inputEstateInvestmentTaxSettingsSlice'
import HelpCircleIcon from '@/assets/images/v2/help-circle.svg'

const contentArr = [
  {
    title: (item: any) => <Typography>物件名</Typography>,
    content: (item: any) => <Typography>{item?.property_name}</Typography>,
  },
  {
    title: (item: any) => <Typography>物件価格</Typography>,
    content: (item: any) => (
      <Typography>
        {`${convertLocaleString(item?.property_price_manyen)} 万円`}
      </Typography>
    ),
  },
  {
    title: (item: any) => <Typography>名義人</Typography>,
    content: (item: any) => <Typography>{convertPersonFlg(item?.person_flg)}</Typography>,
  },
  {
    title: (item: any) => <Typography>物件タイプ</Typography>,
    content: (item: any) => (
      <Typography>{convertPropertyType(item?.property_type)}</Typography>
    ),
  },

  {
    title: (item: any) => <Typography>築年</Typography>,
    content: (item: any) => (
      <Typography>{combineTwoTypesYear(Number(item?.year_of_construction))}</Typography>
    ),
  },

  {
    title: (item: any) => <Typography>引き渡し年</Typography>,
    content: (item: any) => (
      <Typography>
        {convertRepaymentTwoTypesYearMonthDate(item?.delivery_date)}
      </Typography>
    ),
  },

  {
    title: (item: any) => <Typography>広さ</Typography>,
    content: (item: any) => (
      <Typography>{`${convertLocaleString(item?.breadth)}㎡`}</Typography>
    ),
    condition: (item: any) => item?.property_type === INPUT_PROPERTY_TYPE.section,
  },

  {
    title: (item: any) => <Typography>延床面積</Typography>,
    content: (item: any) => (
      <Typography>{`${convertLocaleString(item?.total_floor_area)}㎡`}</Typography>
    ),
    condition: (item: any) => item?.property_type === INPUT_PROPERTY_TYPE.building,
  },

  {
    title: (item: any) => <Typography>敷地面積</Typography>,
    content: (item: any) => (
      <Typography>{`${convertLocaleString(item?.site_area)}㎡`}</Typography>
    ),
    condition: (item: any) => item?.property_type === INPUT_PROPERTY_TYPE.building,
  },

  {
    title: (item: any) => <Typography>賃料収入</Typography>,
    content: (item: any) => (
      <Typography>{`${convertLocaleString(item?.rent_income_monthly)}円`}</Typography>
    ),
  },

  {
    title: (item: any) => <Typography>年間下落率</Typography>,
    content: (item: any) =>
      _isEmpty(item.input_declines) ? (
        <Typography>設定なし</Typography>
      ) : (
        <>
          {Object.keys(item.input_declines).map((_, childIndex) => {
            return (
              <Typography key={childIndex}>
                {convertInputDecline(item.input_declines, childIndex)}
              </Typography>
            )
          })}
        </>
      ),
  },

  {
    title: (item: any) => <Typography>礼金・更新料</Typography>,
    content: (item: any) => (
      <Typography>
        {item?.renewal_fee
          ? convertMonthlyPeriodCost(item?.renewal_fee_period_months, item?.renewal_fee)
          : convertMonthlyPeriodCostWithRent(
              item?.renewal_fee_period_months,
              item?.rent_income_monthly,
              item?.renewal_fee_to_rent_income_rate,
            )}
      </Typography>
    ),
    condition: (item: any) => !_isNull(item?.renewal_fee_period_months),
  },

  {
    title: (item: any) => (
      <Typography className="min-w-[max-content]">固定資産税・都市計画税</Typography>
    ),
    content: (item: any) => (
      <Typography>{`年間 ${convertLocaleString(item?.property_tax)}円`}</Typography>
    ),
  },

  {
    title: (item: any) => <Typography>リフォーム費</Typography>,
    content: (item: any) => (
      <Typography>
        {` ${combineTwoTypesYear(Number(item?.year_of_construction))}〜${
          item?.repair_costs_span
        }年毎に${convertLocaleString(item?.repair_costs)}円`}
      </Typography>
    ),
  },

  {
    title: (item: any) => <Typography>管理費・修繕積立金合計</Typography>,
    content: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item?.management_fee_monthly_and_repair_reserve_fund_total,
        )}円/月`}
      </Typography>
    ),
    condition: (item: any) =>
      item?.property_type === INPUT_PROPERTY_TYPE.section &&
      item?.management_fee_monthly_and_repair_reserve_fund_total,
  },

  {
    title: (item: any) => <Typography>管理費</Typography>,
    content: (item: any) =>
      item.management_fee_monthly ? (
        <Typography>
          {`${convertLocaleString(item?.management_fee_monthly)}円/月`}
        </Typography>
      ) : (
        <Typography>未入力</Typography>
      ),
    condition: (item: any) =>
      item?.property_type === INPUT_PROPERTY_TYPE.section &&
      !item?.management_fee_monthly_and_repair_reserve_fund_total,
  },

  {
    title: (item: any) => <Typography>修繕積立金</Typography>,
    content: (item: any) =>
      item.repair_reserve_fund ? (
        <Typography>
          {`${convertLocaleString(item?.repair_reserve_fund)}円/月`}
        </Typography>
      ) : (
        <Typography>未入力</Typography>
      ),
    condition: (item: any) =>
      item?.property_type === INPUT_PROPERTY_TYPE.section &&
      !item?.management_fee_monthly_and_repair_reserve_fund_total,
  },

  {
    title: (item: any) => <Typography>修繕積立金上昇</Typography>,
    content: (item: any) => (
      <>
        {item?.repair_reserve_fund_increase_type ===
          REPAIR_RESERVE_FUND_INCREASE_TYPE.fixed && (
          <>
            <Typography>
              {`月間 ${convertLocaleString(item?.repair_reserve_fund_amount)}円ずつ`}
            </Typography>
            <Typography>
              {`築${
                item?.repair_reserve_fund_amount_start
              }年目から 築${convertLocaleString(
                item?.repair_reserve_fund_amount_finish,
              )}年目まで上昇させる`}
            </Typography>
          </>
        )}

        {item?.repair_reserve_fund_increase_type ===
          REPAIR_RESERVE_FUND_INCREASE_TYPE.change && (
          <>
            <Typography>
              {`築${item?.repair_reserve_fund_increase_start}年目から
                                ${item?.repair_reserve_fund_increase_span}年毎に`}
            </Typography>
            <Typography>
              {`築${
                item?.repair_reserve_fund_increase_finish
              }年目まで ${convertLocaleString(
                item?.repair_reserve_fund_increase_percent,
              )}％ずつ上昇`}
            </Typography>
          </>
        )}
        {((!item?.repair_reserve_fund_increase_type &&
          item?.repair_reserve_fund_increase_type !== 0) ||
          item?.repair_reserve_fund_increase_type ===
            REPAIR_RESERVE_FUND_INCREASE_TYPE.noSet) && <Typography>設定なし</Typography>}
      </>
    ),
    condition: (item: any) =>
      item?.property_type === INPUT_PROPERTY_TYPE.section &&
      !item?.management_fee_monthly_and_repair_reserve_fund_total,
  },

  {
    title: (item: any) => <Typography>設備減価償却</Typography>,
    content: (item: any) => (
      <Typography>
        {convertDepreciation(
          item?.equipment_durable_years,
          item?.equipment_depreciation,
          item?.year_of_construction,
        )}
      </Typography>
    ),
  },

  {
    title: (item: any) => <Typography>建物減価償却</Typography>,
    content: (item: any) => (
      <Typography>
        {convertDepreciation(
          item?.building_durable_years,
          item?.building_depreciation,
          item?.year_of_construction,
        )}
      </Typography>
    ),
  },

  {
    title: (item: any) => <Typography>建物・賃貸管理手数料</Typography>,
    content: (item: any) =>
      item?.management_fee_type === INPUT_MANAGEMENT_FEE_TYPE.yen ? (
        <Typography>{convertLocaleString(item?.management_fee_money)}円/年</Typography>
      ) : (
        <Typography>
          年間 賃料の
          {item?.management_fee_rate_percent}％
        </Typography>
      ),
  },

  {
    title: (item: any) => <Typography>空室率</Typography>,
    content: (item: any) => <Typography>{`${item?.vacancy_rate_percent}％`}</Typography>,
  },

  {
    title: (item: any) => <Typography>その他経費</Typography>,
    content: (item: any) => (
      <Typography>{`年間 ${convertLocaleString(item?.other_expenses)}円`}</Typography>
    ),
  },

  {
    title: (item: any) => <Typography>ローン有無</Typography>,
    content: (item: any) => <Typography>借りていない</Typography>,
    condition: (item: any) => item?.loan_flg === API_FLAG.off,
  },

  {
    title: (item: any) => <Typography>返済計画表</Typography>,
    content: (item: any) => (
      <Typography>{convertRepaymentChart(item?.repayment_chart)}</Typography>
    ),
    condition: (item: any) => item?.loan_flg === API_FLAG.on,
  },

  {
    title: (item: any) => <Typography>ローン方式</Typography>,
    content: (item: any) => <Typography>{convertLoanType(item?.loan_type)}</Typography>,
    condition: (item: any) =>
      item?.repayment_chart === API_FLAG.on && item?.loan_flg === API_FLAG.on,
  },

  {
    title: (item: any) => <Typography>最終返済日</Typography>,
    content: (item: any) => (
      <Typography>
        {convertDateToTwoTypesYearsDateFormat(
          item?.last_repayment_date,
          'fullDateWithJapaneseYear',
        )}
      </Typography>
    ),
    condition: (item: any) =>
      item?.repayment_chart === API_FLAG.on && item?.loan_flg === API_FLAG.on,
  },

  {
    title: (item: any) => <Typography>毎月払い現在の金利</Typography>,
    content: (item: any) => (
      <Typography>
        {`${convertLocaleString(item?.input_rate_changes[0]?.changed_rate_percent)}％`}
      </Typography>
    ),
    condition: (item: any) =>
      item?.repayment_chart === API_FLAG.on && item?.loan_flg === API_FLAG.on,
  },

  {
    title: (item: any) => <Typography>毎月払いの金利の変化</Typography>,
    content: (item: any) =>
      Object.keys(
        _filter([...item!.input_rate_changes!], (item) => item.rate_start_at !== null),
      ).map((_, changeIndex) => {
        const rateChange = _filter(
          [...item!.input_rate_changes!],
          (item) => item.rate_start_at !== null,
        )[changeIndex]

        return (
          <Typography key={changeIndex}>
            {convertRateStartAt(
              rateChange?.rate_start_at,
              rateChange?.changed_rate_percent,
            )}
          </Typography>
        )
      }),
    condition: (item: any) =>
      item?.repayment_chart === API_FLAG.on &&
      item?.loan_flg === API_FLAG.on &&
      item?.input_rate_changes &&
      item?.input_rate_changes.length >= 2,
  },

  {
    title: (item: any) => <Typography>毎月払いのローン返済額</Typography>,
    content: (item: any) => (
      <Typography>{`${convertLocaleString(item?.repayment_monthly)}円`}</Typography>
    ),
    condition: (item: any) =>
      item?.repayment_chart === API_FLAG.on && item?.loan_flg === API_FLAG.on,
  },

  {
    title: (item: any) => <Typography>登録された返済予定日</Typography>,
    content: (item: any) => (
      <Typography>
        {convertDateToTwoTypesYearsDateFormat(
          item?.expected_return_date,
          'fullDateWithJapaneseYear',
        )}
      </Typography>
    ),
    condition: (item: any) =>
      item?.repayment_chart === API_FLAG.on && item?.loan_flg === API_FLAG.on,
  },

  {
    title: (item: any) => <Typography>残元金合計</Typography>,
    content: (item: any) => (
      <Typography>{`${convertLocaleString(item?.total_amount)}円`}</Typography>
    ),
    condition: (item: any) =>
      item?.repayment_chart === API_FLAG.on && item?.loan_flg === API_FLAG.on,
  },

  {
    title: (item: any) => <Typography>繰り上げ返済</Typography>,
    content: (item: any) => <Typography>行ったことがない</Typography>,
    condition: (item: any) =>
      item?.repayment_chart === API_FLAG.off &&
      item?.loan_flg === API_FLAG.on &&
      _isEmpty(item?.input_estate_loan_calculations),
  },

  {
    title: (item: any) => <Typography>繰り上げ返済</Typography>,
    content: (item: any) =>
      Object.keys(item?.input_estate_loan_calculations).map((_, calcIndex) => {
        const { input_estate_loan_calculations } = item

        return (
          <>
            <div className="flex">
              <div className="flex-1">
                <Typography>繰り上げ返済年</Typography>
              </div>
              <div className="flex-1">
                <Typography>
                  {convertAdvanceRepaymentAt(
                    input_estate_loan_calculations[calcIndex]?.advance_repayment_at,
                  )}
                </Typography>
              </div>
            </div>

            <div className="flex">
              <div className="flex-1">
                <Typography>繰り上げ返済額</Typography>
              </div>
              <div className="flex-1">
                <Typography>
                  {`${convertLocaleString(
                    input_estate_loan_calculations[calcIndex]?.repayment_amount,
                  )}円`}
                </Typography>
              </div>
            </div>

            <div className="flex">
              <div className="flex-1">
                <Typography>返済方法</Typography>
              </div>
              <div className="flex-1">
                <Typography>
                  {convertRepaymentType(
                    input_estate_loan_calculations[calcIndex]?.repayment_type,
                  )}
                </Typography>
              </div>
            </div>
          </>
        )
      }),
    condition: (item: any) =>
      item?.repayment_chart === API_FLAG.off &&
      item?.loan_flg === API_FLAG.on &&
      !_isEmpty(item?.input_estate_loan_calculations),
  },

  {
    title: (item: any) => <Typography>当初の借入金額</Typography>,
    content: (item: any) => (
      <Typography>{`${convertLocaleString(
        item?.started_borrowing_amount_manyen,
      )}万円`}</Typography>
    ),
    condition: (item: any) =>
      item?.repayment_chart === API_FLAG.off && item?.loan_flg === API_FLAG.on,
  },

  {
    title: (item: any) => <Typography>返済期間</Typography>,
    content: (item: any) => (
      <Typography>{`${convertLocaleString(item?.repayment_period_years)}年`}</Typography>
    ),
    condition: (item: any) =>
      item?.repayment_chart === API_FLAG.off && item?.loan_flg === API_FLAG.on,
  },

  {
    title: (item: any) => <Typography>借入金利</Typography>,
    content: (item: any) => (
      <Typography>{`${convertLocaleString(
        item?.repayment_monthly_interest_percent,
      )}％`}</Typography>
    ),
    condition: (item: any) =>
      item?.repayment_chart === API_FLAG.off && item?.loan_flg === API_FLAG.on,
  },

  {
    title: (item: any) => <Typography>団体信用生命保険</Typography>,
    content: (item: any) => (
      <Typography>{convertLifeInsurance(item?.life_insurance)}</Typography>
    ),
    condition: (item: any) => item?.loan_flg === API_FLAG.on,
  },
]

export const EstateInvestmentTable: FC<{ onUpdate?: () => void }> = (props) => {
  const dispatch = useDispatch()
  const { inputEstateInvestments } = useSelector(
    (state: RootState) => state.inputEstateInvestments,
  )
  const { inputEstateInvestmentTaxSetting } = useSelector(
    (state: RootState) => state.inputEstateInvestmentTaxSetting,
  )

  const [modalState, setModalState] = useState<{ isOpen: boolean; id?: number }>({
    isOpen: false,
  })

  const handleTaxSettingChange = (value: string) => {
    dispatch(updateInputEstateInvestmentTaxSetting(value, (res) => {
      if (props.onUpdate != null) props.onUpdate()
      dispatch(fetchInputEstateInvestmentTaxSetting())
    }))
  }

  return (
    <>
      <div className="flex">
        <Typography className="leading-normal text-[14px] text-black-900">
          不動産投資の節税対策を行う
        </Typography>
        <div className="mr-[10px]">
          <Tooltip
            item="ONにすることで、節税対策を考慮し、不動産投資から発生する税金は0円で計算されます。"
            itemClassName="w-[80px] sm:w-[200px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
          >
            <HelpCircleIcon />
          </Tooltip>
        </div>
        <div className="flex border-black-500 border rounded-[3px] overflow-hidden">
          <button
            className="flex items-center justify-center disabled:cursor-default disabled:bg-action-600 bg-black-300 rounded-none w-[48px] h-[24px] disabled:text-white text-black-500"
            // @ts-ignore
            disabled={inputEstateInvestmentTaxSetting?.tax_saving_type == "saving"}
            onClick={() => {
              handleTaxSettingChange('saving')
            }}
          >
            <Typography className="text-[14px]" isBold>
              ON
            </Typography>
          </button>

          <button
            className="flex items-center justify-center disabled:cursor-default disabled:bg-action-600 bg-black-300 rounded-none w-[48px] h-[24px] disabled:text-white text-black-500"
            // @ts-ignore
            disabled={inputEstateInvestmentTaxSetting?.tax_saving_type != "saving"}
            onClick={() => {
              handleTaxSettingChange('none')
            }}
          >
            <Typography className="text-[14px]" isBold>
              OFF
            </Typography>
          </button>

        </div>
      </div>

      {inputEstateInvestments.length === 0 && (
        <Typography className="text-[14px] md:text-[16px] text-center" isBold>
          不動産投資が登録されていません。
        </Typography>
      )}

      {inputEstateInvestments.map((item: any, index: number) => (
        <React.Fragment key={item.id}>
          <div key={item.id} className="mb-[20px] last:mb-0">
            <div className="flex justify-between">
              <CircleTitle className="pb-[5px]" title={`物件${index + 1}`} />
              <div className="flex justify-end mb-[8px]">
                <Button onClick={() => setModalState({ isOpen: true, id: item.id })}>
                  <i className="ico edit text-[14px] text-black-700" />
                  <Typography className="pl-[5px] text-[14px] text-black-700">
                    編集
                  </Typography>
                </Button>
              </div>
            </div>

            <table className="w-[100%] text-[12px] md:text-[14px]">
              <tbody>
                {contentArr.map(
                  (v, i) =>
                    (!v.condition || (v.condition && v.condition(item))) && (
                      <tr
                        className="h-[45px] odd:bg-black-100"
                        key={`${item.id}_${index}_${i}`}
                      >
                        <td className="w-[40%] px-[16px]">{v.title(item)}</td>
                        <td className="w-[60%] px-[16px]">{v.content(item)}</td>
                      </tr>
                    ),
                )}
              </tbody>
            </table>
          </div>
        </React.Fragment>
      ))}

      <SimulationNewButton onClick={() => setModalState({ isOpen: true })} />

      <Modal
        className="lg:w-[1024px] w-[95%]"
        isOpen={modalState.isOpen}
        onClose={() => setModalState({ isOpen: false })}
      >
        <CircleTitle className="pb-[32px]" title="現在の不動産投資" />
        <MyDataEstateInvestmentForm
          id={modalState.id != null ? String(modalState.id) : undefined}
          onClose={() => setModalState({ isOpen: false })}
          onSubmit={() => {
            setModalState({ isOpen: false })
            if (props.onUpdate != null) props.onUpdate()
          }}
        />
      </Modal>
    </>
  )
}
const InputEstateInvestmentBody: FC<{ onUpdate?: () => void }> = (props) => {
  return (
    <SimulationAccordion className="pb-[16px]" title="現在の不動産投資">
      <EstateInvestmentTable onUpdate={props.onUpdate} />
    </SimulationAccordion>
  )
}

export default InputEstateInvestmentBody
