import React, { FC, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Decorator } from 'final-form'
import createDecorator from 'final-form-calculate'
import arrayMutators from 'final-form-arrays'
import { isNull as _isNull, isUndefined as _isUndefined } from 'lodash'
import { createFutureHousingSaleV2 } from '@/containers/futures/futureHousingSalesSlice'
import { createFutureLendHousingV2 } from '@/containers/futures/futureLendHousingsSlice'
import {
  DescriptionContext,
  useDescriptionTemplates,
} from '@/hooks/useDescriptionTemplates'
import { QuestionTemplate } from '@/models/commonModalForm'
import {
  castNumberWithoutOperator,
  isNotEmptyValue,
  addMomentDate,
  THIS_YEAR,
  API_FLAG,
} from '@/models/commonsModelFunc'
import { RENEWAL_FEE_INPUT_TYPE } from '@/models/commonEstateInvestmentModelFunc'
import { IS_LOAN_CUSTOMIZED } from '@/models/housingLoansModelFunc'
import {
  FUTURE_ESTATE_INVESTMENT_DEFAULT_VALUES,
  VACANCY_RATE_FIELDS,
  calcVacancyRate,
} from '@/models/futures/futureEstateInvestmentsModelFunc'
import { FutureHousingSaleCreateValues } from '@/models/futures/futureHousingSalesModel'
import { calcSaleCostManyen } from '@/models/futures/futureHousingSalesModelFunc'
import {
  LOAN_RENEWAL_TYPE,
  convertNonRequiredValuesToNull,
} from '@/models/futures/futureLendHousingsModelFunc'
import {
  CURRENT_HOUSE_HANDLING,
  PREV_HOUSING_TYPE,
} from '@/models/futures/futureV2HousingsModelFunc'
import {
  FutureV2Housing,
  NextHouseType,
  PrevHousingType,
} from '@/models/futures/futureV2HousingsModel'
import { InputHousing } from '@/models/inputs/inputHousingsModel'
import { RootState } from '@/store'

import ScheduleBasicInfo from '@/templates/v2/simulations/futureV2Housings/FutureV2HousingSaleOrLendCreateModalForm/ScheduleBasicInfo'
import ModalInstruction from '@/templates/v2/simulations/futureV2Housings/FutureV2HousingSaleOrLendCreateModalForm/ModalInstruction'

import SaleDetail from '@/templates/v2/simulations/futureV2Housings/FutureHousingSaleModalForm/SaleDetail'
import HousingOverview from '@/templates/v2/simulations/futureV2Housings/FutureLendHousingModalForm/HousingOverview'
import LifeInsuranceContract from '@/templates/v2/simulations/futureV2Housings/FutureLendHousingModalForm/LifeInsuranceContract'
import LoanCustomized from '@/templates/v2/simulations/futureV2Housings/FutureLendHousingModalForm/LoanCustomized'
import LoanDetail from '@/templates/v2/simulations/futureV2Housings/FutureLendHousingModalForm/LoanDetail'
import ManagementFeeType from '@/templates/v2/simulations/futureV2Housings/FutureLendHousingModalForm/ManagementFeeType'
import OtherCost from '@/templates/v2/simulations/futureV2Housings/FutureLendHousingModalForm/OtherCost'
import RentIncome from '@/templates/v2/simulations/futureV2Housings/FutureLendHousingModalForm/RentIncome'
import RenewalFee from '@/templates/v2/simulations/futureV2Housings/FutureLendHousingModalForm/RenewalFee'
import RepairCost from '@/templates/v2/simulations/futureV2Housings/FutureLendHousingModalForm/RepairCost'
import LoanCustomizedFooter from '@/templates/v2/simulations/futureV2Housings/FutureLendHousingModalForm/commons/LoanCustomizedFooter'
import LoanRepaymentTable from '@/templates/v2/simulations/futureV2Housings/FutureLendHousingModalForm/commons/LoanRepaymentTable'

import Modal from '@/components/v2/atoms/Modal'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import { Form } from 'react-final-form'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import Grid from '@/components/v2/atoms/Grid'
import Typography from '@/components/v2/atoms/Typography'
import ChevronRightIcon from '@/assets/images/v2/chevron-right.svg'

const DESCRIPTION_TEMPLATES: QuestionTemplate[] = [{ children: <ScheduleBasicInfo /> }]

const DESCRIPTION_TEMPLATES_SALE: QuestionTemplate[] = [
  {
    children: <ModalInstruction isSale />,
    condition: (values: any) =>
      values?.prevHousingType == PREV_HOUSING_TYPE.own &&
      values?.current_house_handling == CURRENT_HOUSE_HANDLING.sale,
  },
  {
    children: <SaleDetail />,
    condition: (values: any) =>
      values?.prevHousingType == PREV_HOUSING_TYPE.own &&
      values?.current_house_handling == CURRENT_HOUSE_HANDLING.sale,
  },
]

const DESCRIPTION_TEMPLATES_LEND: QuestionTemplate[] = [
  {
    children: <HousingOverview />,
    condition: (values: any) =>
      values?.prevHousingType == PREV_HOUSING_TYPE.own &&
      values?.current_house_handling == CURRENT_HOUSE_HANDLING.lend,
  },
  {
    children: <LoanCustomized />,
    footer: <LoanCustomizedFooter />,
    condition: (values: any) =>
      values?.prevHousingType == PREV_HOUSING_TYPE.own &&
      values?.current_house_handling == CURRENT_HOUSE_HANDLING.lend &&
      values.future_lend_housing?.loan_renewal_type == LOAN_RENEWAL_TYPE.renew,
  },
  {
    children: <LoanDetail />,
    footer: <LoanRepaymentTable />,
    condition: (values: any) =>
      values?.prevHousingType == PREV_HOUSING_TYPE.own &&
      values?.current_house_handling == CURRENT_HOUSE_HANDLING.lend &&
      values.future_lend_housing?.loan_renewal_type == LOAN_RENEWAL_TYPE.renew &&
      values.future_lend_housing?.is_loan_customized == String(IS_LOAN_CUSTOMIZED.manual),
  },
  {
    children: <LifeInsuranceContract />,
    condition: (values: any) =>
      values?.prevHousingType == PREV_HOUSING_TYPE.own &&
      values?.current_house_handling == CURRENT_HOUSE_HANDLING.lend &&
      values.future_lend_housing?.loan_renewal_type == LOAN_RENEWAL_TYPE.renew &&
      values.future_lend_housing?.is_loan_customized == String(IS_LOAN_CUSTOMIZED.manual),
  },
]

const DESCRIPTION_TEMPLATES_INCOME: QuestionTemplate[] = [
  {
    children: <RentIncome />,
    condition: (values: any) =>
      values?.prevHousingType == PREV_HOUSING_TYPE.own &&
      values?.current_house_handling == CURRENT_HOUSE_HANDLING.lend,
  },
  {
    children: <RenewalFee />,
    condition: (values: any) =>
      values?.prevHousingType == PREV_HOUSING_TYPE.own &&
      values?.current_house_handling == CURRENT_HOUSE_HANDLING.lend,
  },
]

const DESCRIPTION_TEMPLATES_EXPENSE: QuestionTemplate[] = [
  {
    children: <ManagementFeeType />,
    condition: (values: any) =>
      values?.prevHousingType == PREV_HOUSING_TYPE.own &&
      values?.current_house_handling == CURRENT_HOUSE_HANDLING.lend,
  },
  {
    children: <RepairCost />,
    condition: (values: any) =>
      values?.prevHousingType == PREV_HOUSING_TYPE.own &&
      values?.current_house_handling == CURRENT_HOUSE_HANDLING.lend,
  },
  {
    children: <OtherCost />,
    condition: (values: any) =>
      values?.prevHousingType == PREV_HOUSING_TYPE.own &&
      values?.current_house_handling == CURRENT_HOUSE_HANDLING.lend,
  },
]

const calculator: Decorator<any> = createDecorator(
  {
    field: VACANCY_RATE_FIELDS,
    updates: {
      'future_lend_housing.vacancy_rate_percent': (_, allValues: any) =>
        calcVacancyRate(allValues),
    },
  },
  {
    field: 'future_housing_sales.sale_amount_manyen',
    updates: {
      'future_housing_sales.cost_manyen': (
        _,
        allValues: FutureHousingSaleCreateValues,
      ) => {
        const { sale_amount_manyen } = allValues.future_housing_sales
        if (!isNotEmptyValue(sale_amount_manyen)) return 0

        return calcSaleCostManyen(castNumberWithoutOperator(sale_amount_manyen))
      },
    },
  },
)

interface FutureV2HousingSaleOrLendCreateModalFormProps {
  /** true:別宅用のモーダル false:住居モーダル*/
  isAnotherModal?: boolean
  /** true: オープン, false: クローズ */
  isOpen: boolean
  /** true: 別宅以外 false: 別宅 */
  isMainType?: boolean
  /** rent: 賃貸, own: 戸建て, another: 別宅  */
  prevHousingType: PrevHousingType | undefined
  /** 時系列一つ前の住居  */
  prevHousing: FutureV2Housing | InputHousing | undefined
  /** モーダルクローズメソッド */
  handleClose: () => void
  /** 共通API呼び出しメソッド */
  completedCallback: (
    isMain: boolean,
    type: 'sale' | 'lend' | 'another' | undefined,
    nextHouseValues: {
      nextHouseType: NextHouseType
      nextScheduledAtAge: number
    },
    id?: number,
  ) => void
}

const FutureV2HousingSaleOrLendCreateModalForm: FC<FutureV2HousingSaleOrLendCreateModalFormProps> = (
  props,
) => {
  const dispatch = useDispatch()
  const {
    isAnotherModal,
    isOpen,
    isMainType,
    prevHousingType,
    prevHousing,
    handleClose,
    completedCallback,
  } = props

  const { getContextValue } = useDescriptionTemplates({}, DESCRIPTION_TEMPLATES)

  const [isRentLastAgeModalOpen, setIsRentLastAgeModalOpen] = useState<boolean>(false)
  const [_, setIsReadyForSubmit] = useState<boolean>(false)

  const {
    futureBaseId,
    familyInfo: { is_married },
  } = useSelector((state: RootState) => state.futureBases)

  const { familyInfoV2 } = useSelector((state: RootState) => state.familyStructures)
  const familyAgesObject = useMemo(
    () => ({
      person_current_age: familyInfoV2?.person_age,
      spouse_current_age: familyInfoV2?.spouse_age,
    }),
    [familyInfoV2],
  )

  const { futureMarriage } = useSelector((state: RootState) => state.futureMarriages)
  const isSaleLoading = useSelector(
    (state: RootState) => state.futureHousingSales.isLoading,
  )
  const isLendLoading = useSelector(
    (state: RootState) => state.futureLendHousings.isLoading,
  )

  const hasSpouse = useMemo(() => is_married || !_isNull(futureMarriage), [
    is_married,
    futureMarriage,
  ])

  const prevHousingModelType = useMemo(
    () =>
      prevHousing?.model_type == 'InputHousing' ? 'InputHousing' : 'FutureV2Housing',
    [prevHousing],
  )
  const prevHousingId = prevHousing?.id

  const handleSubmitHousingSale = (pickedValues: any) => {
    const { next_house_type, scheduled_at_age, future_housing_sales } = pickedValues

    const newValues: FutureHousingSaleCreateValues = {
      future_base_id: futureBaseId,
      future_housing_sales: {
        housing_id: prevHousingId,
        housing_type: prevHousingModelType,
        sale_amount_manyen: future_housing_sales?.sale_amount_manyen,
        cost_manyen: future_housing_sales?.cost_manyen,
        sell_at_age: future_housing_sales?.sell_at_age,
        transfer_exception_type: future_housing_sales?.transfer_exception_type,
      },
    }

    dispatch(
      createFutureHousingSaleV2(
        {
          account: {
            future_housing_sales_attributes: [{ ...newValues.future_housing_sales }],
          },
        },
        (id) =>
          completedCallback(
            !!isMainType,
            'sale',
            { nextHouseType: next_house_type, nextScheduledAtAge: scheduled_at_age },
            id,
          ),
      ),
    )
  }

  const handleSubmitLendHousing = (pickedValues: any) => {
    const { next_house_type, scheduled_at_age } = pickedValues

    let futureLendHousingDeclines = pickedValues.future_lend_housing.future_lend_housing_declines_attributes?.map(
      (item: any, index: number) => ({
        affected_at:
          index !== 0
            ? addMomentDate(
                THIS_YEAR,
                item.affected_number as number,
                'years',
                'YYYY/MM/DD',
              )
            : null,
        rate_percent: item.rate_percent,
      }),
    )
    if (
      futureLendHousingDeclines.length == 1 &&
      !futureLendHousingDeclines[0].rate_percent
    ) {
      futureLendHousingDeclines = []
    }

    const newValues = {
      future_base_id: futureBaseId,
      future_lend_housing: {
        ...pickedValues.future_lend_housing,
        housing_id: prevHousingId,
        housing_type: prevHousingModelType,
        future_lend_housing_declines_attributes: futureLendHousingDeclines,
      },
    }

    const { renewal_fee_type, renewal_fee_input_type } = pickedValues
    const { management_fee_money, property_type } = newValues.future_lend_housing

    if (!_isUndefined(management_fee_money)) {
      newValues.future_lend_housing.management_fee_money =
        castNumberWithoutOperator(management_fee_money) * 12
    }

    newValues.future_lend_housing = convertNonRequiredValuesToNull(
      newValues.future_lend_housing,
      renewal_fee_type == API_FLAG.on,
      renewal_fee_input_type == RENEWAL_FEE_INPUT_TYPE.yen,
    )

    if (
      !newValues.future_lend_housing.loan_renewal_type ||
      newValues.future_lend_housing.loan_renewal_type == 'continue' ||
      newValues.future_lend_housing.is_loan_customized == String(IS_LOAN_CUSTOMIZED.auto)
    ) {
      delete newValues.future_lend_housing.housing_loan_attributes
    }

    dispatch(
      createFutureLendHousingV2(
        {
          account: {
            future_lend_housings_attributes: [{ ...newValues.future_lend_housing }],
          },
        },
        (id) =>
          completedCallback(
            !!isMainType,
            'lend',
            { nextHouseType: next_house_type, nextScheduledAtAge: scheduled_at_age },
            id,
          ),
      ),
    )
  }

  const handleSubmit = (values: any) => {
    const pickedValues: any = values
    const { next_house_type, current_house_handling, scheduled_at_age } = pickedValues

    // 新居購入予定年齢を後から変更できないため、確認モーダルを表示
    if (
      (current_house_handling === CURRENT_HOUSE_HANDLING.lend ||
      current_house_handling === CURRENT_HOUSE_HANDLING.sale) &&
      !window.confirm("新居購入予定年齢は後から編集できません。\r\n編集する場合は削除して再作成をお願いします。")
    ) {
      return
    }

    if (
      current_house_handling !== CURRENT_HOUSE_HANDLING.lend &&
      current_house_handling !== CURRENT_HOUSE_HANDLING.sale
    ) {
      return isAnotherModal
        ? completedCallback(!!isMainType, 'another', {
            nextHouseType: next_house_type,
            nextScheduledAtAge: scheduled_at_age,
          })
        : completedCallback(!!isMainType, undefined, {
            nextHouseType: next_house_type,
            nextScheduledAtAge: scheduled_at_age,
          })
    }

    if (pickedValues?.current_house_handling == CURRENT_HOUSE_HANDLING.sale) {
      return handleSubmitHousingSale(pickedValues)
    }

    return handleSubmitLendHousing(pickedValues)
  }

  const initialValues = useMemo(() => {
    return {
      future_housing_sales: {},
      future_lend_housing: {
        future_lend_housing_declines_attributes: [{ affected_number: null }],
        housing_loan_attributes: {
          loan_down_payment_manyen:
            FUTURE_ESTATE_INVESTMENT_DEFAULT_VALUES.loan_down_payment_manyen,
          loan_interest_ratio_percent:
            FUTURE_ESTATE_INVESTMENT_DEFAULT_VALUES.loan_interest_ratio_percent,
          repayment_period_years:
            FUTURE_ESTATE_INVESTMENT_DEFAULT_VALUES.repayment_period_years,
        },
        loan_renewal_type: 'continue',
      },
      prevHousingType,
    }
  }, [])

  return (
    <Modal
      className="w-[95%] xl:w-[1000px]"
      isOpen={isOpen}
      isDisableBackdropClick
      onClose={handleClose}
      isLoading={isLendLoading || isSaleLoading}
    >
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        mutators={{ ...arrayMutators }}
        decorators={[calculator]}
        render={({ handleSubmit, values, errors }) => {
          return (
            <form className="pb-[30px]" onSubmit={handleSubmit}>
              <DescriptionContext.Provider
                value={{
                  ...getContextValue(),
                  age: familyAgesObject.person_current_age,
                  hasSpouse,
                  isAnotherModal,
                  isMainType,
                  isRentLastAgeModalOpen,
                  setIsRentLastAgeModalOpen,
                  setIsReadyForSubmit,
                  prevHousingType,
                  prevHousing,
                }}
              >
                <Grid className="grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px]">
                  {DESCRIPTION_TEMPLATES.map((item, index) => {
                    return (
                      ((item.condition && item.condition(values)) || !item.condition) && (
                        <>
                          <React.Fragment key={index}>{item.children}</React.Fragment>
                        </>
                      )
                    )
                  })}
                </Grid>

                {values?.prevHousingType == PREV_HOUSING_TYPE.own &&
                  values?.current_house_handling == CURRENT_HOUSE_HANDLING.sale && (
                    <Grid className="grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px]">
                      {DESCRIPTION_TEMPLATES_SALE.map((item, index) => {
                        return (
                          ((item.condition && item.condition(values)) ||
                            !item.condition) && (
                            <>
                              <React.Fragment key={index}>{item.children}</React.Fragment>
                            </>
                          )
                        )
                      })}
                    </Grid>
                  )}

                {values?.prevHousingType == PREV_HOUSING_TYPE.own &&
                  values?.current_house_handling == CURRENT_HOUSE_HANDLING.lend && (
                    <>
                      <CircleTitle className="pt-40 pb-[32px]" title="物件の概要" />
                      <Grid className="grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px]">
                        {DESCRIPTION_TEMPLATES_LEND.map((item, index) => {
                          return (
                            ((item.condition && item.condition(values)) ||
                              !item.condition) && (
                              <>
                                <React.Fragment key={index}>
                                  {item.children}
                                </React.Fragment>
                              </>
                            )
                          )
                        })}
                      </Grid>
                    </>
                  )}

                {values?.prevHousingType == PREV_HOUSING_TYPE.own &&
                  values?.current_house_handling == CURRENT_HOUSE_HANDLING.lend && (
                    <>
                      <CircleTitle className="pt-40 pb-[32px]" title="賃料収入について" />
                      <Grid className="grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px]">
                        {DESCRIPTION_TEMPLATES_INCOME.map((item, index) => {
                          return (
                            ((item.condition && item.condition(values)) ||
                              !item.condition) && (
                              <>
                                <React.Fragment key={index}>
                                  {item.children}
                                </React.Fragment>
                              </>
                            )
                          )
                        })}
                      </Grid>
                    </>
                  )}

                {values?.prevHousingType == PREV_HOUSING_TYPE.own &&
                  values?.current_house_handling == CURRENT_HOUSE_HANDLING.lend && (
                    <>
                      <CircleTitle className="pt-40 pb-[32px]" title="費用について" />
                      <Grid className="grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px]">
                        {DESCRIPTION_TEMPLATES_EXPENSE.map((item, index) => {
                          return (
                            ((item.condition && item.condition(values)) ||
                              !item.condition) && (
                              <>
                                <React.Fragment key={index}>
                                  {item.children}
                                </React.Fragment>
                              </>
                            )
                          )
                        })}
                      </Grid>
                    </>
                  )}
              </DescriptionContext.Provider>

              <div className="flex justify-center mt-40">
                <ButtonPrimary
                  className="h-64 w-246"
                  isDisabled={Object.keys(errors).length > 0}
                  onClick={() => handleSubmit(values)}
                >
                  <Typography
                    className="bottom-2 flex-auto pl-30 relative text-16 text-white"
                    isBold
                  >
                    次へ進む
                  </Typography>
                  <ChevronRightIcon className="mr-17" />
                </ButtonPrimary>
              </div>
            </form>
          )
        }}
      />
    </Modal>
  )
}

export default FutureV2HousingSaleOrLendCreateModalForm
