import React, { FC, useState } from 'react'
import clsx from 'clsx'
import FinanceAppIcon from '@/assets/images/v2/finance-app.svg'
import ChevronRightIcon from '@/assets/images/v2/chevron-right-small-gray.svg'
import Button from '@/components/v2/atoms/Button'
import Paper from '@/components/v2/atoms/Paper'
import Typography from '@/components/v2/atoms/Typography'
import Modal from '@/components/v2/atoms/Modal'
interface PaperTitleWelcomeProps {
  /** クラス名 */
  className?: string
}

const PaperTitleWelcome: FC<PaperTitleWelcomeProps> = (props) => {
  const { className } = props

  const [isOpenTutorialModal, setIsOpenTutorialModal] = useState<boolean>(false)
  const [isWelcomeOpen, _] = useState<boolean>(true)

  return isWelcomeOpen ? (
    <>
      <Paper
        className={clsx(
          className,
          'relative px-[16px] md:px-[32px] lg:px-[48px] py-[10px] rounded-t-none bg-secondary-500',
        )}
      >
        <div className="flex items-center">
          <div className="flex-1 min-w-[193px]">
            <Typography className="pb-[8px] text-[18px] md:text-[24px] text-white" isBold>
              マネソルへようこそ！
            </Typography>
            <Typography className="pb-[12px] text-[12px] md:text-[14px] text-white">
              マネソルをご利用いただきありがとうございます。マネソルの使い方はこちらの動画をチェック！
            </Typography>
            <Button
              className="w-[162px] h-[34px] px-[5px] rounded-[8px] bg-secondary-700 hover:bg-secondary-800"
              onClick={() => {
                setIsOpenTutorialModal(true)
              }}
            >
              <Typography
                className="relative bottom-[2px] flex-1 text-[12px] text-white"
                isBold
              >
                説明動画を見る
              </Typography>
              <ChevronRightIcon />
            </Button>
          </div>
          <FinanceAppIcon viewBox="0 0 194 146" />
        </div>
      </Paper>
      {isOpenTutorialModal && (
        <Modal
          isOpen={isOpenTutorialModal}
          onClose={() => {
            setIsOpenTutorialModal(false)
          }}
          className="md:w-[60vw] w-[95vw]"
        >
          <div className="relative w-full pt-[55%]">
            <iframe
              className="absolute w-full top-0 h-full"
              src="https://www.youtube.com/embed/NIG4el22cnw"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </Modal>
      )}
    </>
  ) : (
    <div className="mb-[24px] md:mb-[32px] lg:mb-[40px]" />
  )
}

export default PaperTitleWelcome
