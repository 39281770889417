import ApiClient from '@/api/apiClient'
import {
  CompanyRiskCreateValues,
  CompanyRiskUpdateValues,
} from '@/models/admins/companyRisksModel'
import { CompanyRisk } from '@/models/admins/companyRisksModel'

export const getCompanyRisk = async (companyId: number) => {
  return await ApiClient.get(
    `/companies/${companyId}/company_risks`,
    (response: CompanyRisk | null) => response,
    undefined,
    false,
    'v2',
  )
}

export const postCompanyRisk = async (
  companyId: number,
  values: CompanyRiskCreateValues,
) => {
  return await ApiClient.post(
    `/companies/${companyId}/company_risks`,
    (response: CompanyRisk | null) => response,
    values,
    undefined,
    'v2',
  )
}

export const patchCompanyRisk = async (
  companyId: number,
  companyRiskId: number,
  values: CompanyRiskUpdateValues,
) => {
  return await ApiClient.patch(
    `/companies/${companyId}/company_risks/${companyRiskId}`,
    (response: CompanyRisk | null) => response,
    values,
    undefined,
    'v2',
  )
}

export const deleteCompanyRisk = async (companyId: number) => {
  return await ApiClient.delete(
    `/companies/${companyId}/company_risks`,
    () => {},
    undefined,
    undefined,
    'v2',
  )
}
