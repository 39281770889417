import { isNotEmptyValue } from '@/models/commonsModelFunc'
import {
  isEmpty as _isEmpty,
  maxBy as _maxBy,
  size as _size,
  reduce as _reduce,
  filter as _filter,
  minBy as _minBy,
  sortBy as _sortBy,
} from 'lodash'

export const getMaxSizeEstateEvaluationObject = (
  simulationGraphs: any,
  isInput?: boolean,
) => {
  const targetLoanGraph = isInput
    ? simulationGraphs?.inputLoanGraph
    : simulationGraphs?.loanGraph

  if (!isNotEmptyValue(targetLoanGraph) || _isEmpty(targetLoanGraph)) return {}

  const maxSizeEvaluationLoanGraphObject = targetLoanGraph.reduce(
    (acc: any, obj: any) => {
      if (obj?.estate_evaluations) {
        for (const key of Object.keys(obj?.estate_evaluations)) {
          acc[key] = obj?.estate_evaluations[key]
        }
      }
      return acc
    },
    {},
  )

  return maxSizeEvaluationLoanGraphObject
}

export const getMaxSizeEstateRemainLoanObject = (
  simulationGraphs: any,
  isInput?: boolean,
) => {
  const targetLoanGraph = isInput
    ? simulationGraphs?.inputLoanGraph
    : simulationGraphs?.loanGraph

  if (!isNotEmptyValue(targetLoanGraph) || _isEmpty(targetLoanGraph)) return {}

  const maxSizeLoanLoanGraphObject = targetLoanGraph.reduce((acc: any, obj: any) => {
    if (obj?.estate_remain_loans_hash) {
      for (const key of Object.keys(obj?.estate_remain_loans_hash)) {
        acc[key] = obj?.estate_remain_loans_hash[key]
      }
    }
    return acc
  }, {})

  return maxSizeLoanLoanGraphObject
}

const _getAxisFromMinAndMaxNumber = (min: number, max: number) => {
  let returnHash = [0]
  const maxStr = String(Math.round(max))
  const maxLength = maxStr.length
  const minStr = String(Math.round(Math.abs(min)))
  const minLength = minStr.length
  const coefficientLength = Number(_maxBy([maxLength, minLength])) - 2
  const coefficient = 10 ** coefficientLength

  if (max > 0) {
    for (let i = 1; max > (i - 1) * 5 * coefficient; i++) {
      returnHash.push(i * 5 * coefficient)
    }
  }

  if (min < 0) {
    for (let i = 1; min < (i - 1) * 5 * coefficient * -1; i++) {
      returnHash.push(i * 5 * coefficient * -1)
    }
  }

  return _sortBy(returnHash)
}

export const getInvestmentGraphTicks = (v2InvestmentGraph: any, compareGraph: any) => {
  const minObj: any = _minBy(v2InvestmentGraph, 'substruction')
  const minObjCompare: any = _minBy(compareGraph, 'substruction')
  const minNumber = minObj ? minObj.substruction : -10000
  const minNumberCompare = minObjCompare ? minObjCompare.substruction : -10000

  const maxObj: any = _maxBy(v2InvestmentGraph, 'total')
  const maxObjCompare: any = _maxBy(compareGraph, 'total')
  const maxNumber = maxObj ? maxObj.total : 10000
  const maxNumberCompare = maxObjCompare ? maxObjCompare.total : 10000

  return _getAxisFromMinAndMaxNumber(
    _minBy([minNumber, minNumberCompare]),
    _maxBy([maxNumber, maxNumberCompare]),
  )
}

export const getNetAssetGraphTicks = (netAssetGraph: any, compareGraph: any) => {
  const minObj: any = _minBy(netAssetGraph, 'substruction_total_negative')
  const minObjCompare: any = _minBy(compareGraph, 'substruction_total_negative')
  const minNumber = minObj ? minObj.substruction_total_negative : -10000
  const minNumberCompare = minObjCompare
    ? minObjCompare.substruction_total_negative
    : -10000

  const maxObj: any = _maxBy(
    netAssetGraph,
    (obj) =>
      obj.financial_asset_total +
      obj.housing_asset_evaluation +
      obj.estate_evaluation_total,
  )
  const maxObjCompare: any = _maxBy(
    compareGraph,
    (obj) =>
      obj.financial_asset_total +
      obj.housing_asset_evaluation +
      obj.estate_evaluation_total,
  )
  const maxNumber = maxObj
    ? maxObj.financial_asset_total +
      maxObj.housing_asset_evaluation +
      maxObj.estate_evaluation_total
    : 10000
  const maxNumberCompare = maxObjCompare
    ? maxObjCompare.financial_asset_total +
      maxObjCompare.housing_asset_evaluation +
      maxObjCompare.estate_evaluation_total
    : 10000

  return _getAxisFromMinAndMaxNumber(
    _minBy([minNumber, minNumberCompare]),
    _maxBy([maxNumber, maxNumberCompare]),
  )
}

export const getLoanGraphTicks = (loanGraph: any, compareGraph: any) => {
  const minNumber = 0

  const maxObj: any = _maxBy(loanGraph, 'asset_evaluation_total')
  const maxObjCompare: any = _maxBy(compareGraph, 'asset_evaluation_total')
  const maxNumber = maxObj ? maxObj.asset_evaluation_total : 10000
  const maxNumberCompare = maxObjCompare ? maxObjCompare.asset_evaluation_total : 10000

  return _getAxisFromMinAndMaxNumber(minNumber, _maxBy([maxNumber, maxNumberCompare]))
}

export const getBalanceGraphTicks = (v2BalanceGraph: any, compareGraph: any) => {
  const minObj: any = _minBy(v2BalanceGraph, 'balance_total')
  const minObjCompare: any = _minBy(compareGraph, 'balance_total')
  const minNumber = minObj ? minObj.balance_total : -10000
  const minNumberCompare = minObjCompare ? minObjCompare.balance_total : -10000

  const maxIncomeObj: any = _maxBy(v2BalanceGraph, 'income_total')
  const maxIncomeObjCompare: any = _maxBy(compareGraph, 'income_total')
  const maxIncomeNumber = maxIncomeObj ? maxIncomeObj.income_total : 10000
  const maxIncomeNumberCompare = maxIncomeObjCompare
    ? maxIncomeObjCompare.income_total
    : 10000

  const maxSpendingObj: any = _maxBy(v2BalanceGraph, 'spending_total')
  const maxSpendingObjCompare: any = _maxBy(compareGraph, 'spending_total')
  const maxSpendingNumber = maxSpendingObj ? maxSpendingObj.spending_total : 10000
  const maxSpendingNumberCompare = maxSpendingObjCompare
    ? maxSpendingObjCompare.spending_total
    : 10000

  const maxNumber: any = _maxBy([
    maxIncomeNumber,
    maxIncomeNumberCompare,
    maxSpendingNumber,
    maxSpendingNumberCompare,
  ])

  return _getAxisFromMinAndMaxNumber(_minBy([minNumber, minNumberCompare]), maxNumber)
}

export const getInsuranceGraphTicks = (insuranceGraph: any, compareGraph: any) => {
  const minNumber = 0

  const maxSecurityObj: any = _maxBy(insuranceGraph, 'required_security_amount_total')
  const maxSecurityObjCompare: any = _maxBy(
    compareGraph,
    'required_security_amount_total',
  )
  const maxSecurityNumber = maxSecurityObj
    ? maxSecurityObj.required_security_amount_total
    : 10000
  const maxSecurityNumberCompare = maxSecurityObjCompare
    ? maxSecurityObjCompare.required_security_amount_total
    : 10000

  const maxAssetObj: any = _maxBy(
    insuranceGraph,
    (obj) => obj.existing_insurance_total + obj.total_financial_assets,
  )
  const maxAssetObjCompare: any = _maxBy(
    compareGraph,
    (obj) => obj.existing_insurance_total + obj.total_financial_assets,
  )
  const maxAssetNumber = maxAssetObj
    ? maxAssetObj.existing_insurance_total + maxAssetObj.total_financial_assets
    : 10000
  const maxAssetNumberCompare = maxAssetObjCompare
    ? maxAssetObjCompare.existing_insurance_total +
      maxAssetObjCompare.total_financial_assets
    : 10000

  return _getAxisFromMinAndMaxNumber(
    minNumber,
    _maxBy([
      maxSecurityNumber,
      maxSecurityNumberCompare,
      maxAssetNumber,
      maxAssetNumberCompare,
    ]),
  )
}
