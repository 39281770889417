import React, { FC } from 'react'
import clsx from 'clsx'
import Typography from '@/components/v2/atoms/Typography'

export interface SelectionIconButtonOption {
  /** タイトル */
  title: string
  /** アイコン */
  icon: JSX.Element
  /** true: 通知有り, false: 通知無し */
  isNotice?: boolean
  [key: string]: any
}

interface SelectionIconButtonProps {
  /** クラス名 */
  className?: string
  /** 現在の番号 */
  currentIndex?: number
  /** オプション */
  options: SelectionIconButtonOption[]
  /** onClickメソッド */
  onClick?: (item: SelectionIconButtonOption, index: number) => void
}

const SelectionIconButton: FC<SelectionIconButtonProps> = (props) => {
  const { className, currentIndex, options, onClick } = props

  return (
    <div className={`${className} flex lg:justify-center overflow-x-scroll gap-x-[16px]`}>
      {options.map((item, index) => (
        <div key={index}>
          <div
            className="flex lg:block items-center cursor-pointer"
            onClick={() => onClick?.(item, index)}
          >
            <div
              className={clsx(
                'relative w-[24px] md:w-[50px] lg:w-[75px] h-[24px] md:h-[50px] lg:h-[75px] mx-[auto] lg:mb-[8px] rounded-[40px]',
                {
                  ['bg-secondary-500']: index === currentIndex,
                  ['bg-white']: index !== currentIndex,
                },
              )}
            >
              <div
                className={clsx(
                  'absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] text-[12px] md:text-[24px] lg:text-[35px]',
                  {
                    ['text-white']: index === currentIndex,
                    ['text-black-700']: index !== currentIndex,
                  },
                )}
              >
                {item.icon}
              </div>
              {item.isNotice && (
                <i className="ico ellipse absolute md:top-[2px] lg:top-[7px] right-[-2px] md:right-[2px] lg:right-[7px] text-[4px] md:text-[11px] text-alert-600" />
              )}
            </div>

            <Typography
              className={clsx(
                'w-[max-content] lg:w-[initial] pl-[5px] lg:pl-[0] text-[14px] lg:text-[16px] text-center',
                {
                  ['text-secondary-500']: index === currentIndex,
                  ['text-black-900']: index !== currentIndex,
                },
              )}
              isBold
            >
              {item.title}
            </Typography>
          </div>

          {index === currentIndex && (
            <div className="text-center">
              <i className="ico polygon text-[10px] md:text-[14px] text-secondary-500" />
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

export default SelectionIconButton
