import React, { ChangeEvent, FC, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'react-final-form'
import clsx from 'clsx'
import { isNull as _isNull } from 'lodash'
import Typography from '@/components/v2/atoms/Typography'
import SelectBox from '@/components/v2/molecules/SelectBox'
import {
  fetchInputAssetSummary,
  updateInputAsset,
} from '@/containers/inputs/inputAssetsSlice'
import { INPUT_ASSET_TYPE_SELECT_ITEMS } from '@/constants/formItem/inputs/inputAssets'
import { convertLocaleString, defaultToEmptyString } from '@/models/commonsModelFunc'
import { AssetType, InputAsset } from '@/models/inputs/inputAssetsModel'
import {
  filterInputAsset,
  hasSummaryOf,
  convertInputAssetType,
} from '@/models/inputs/inputAssetsModelFunc'
import { RootState } from '@/store'

interface AssetBalanceTableProps {
  /** フラッシュメッセージ表示メソッド */
  showFlashMsg: () => void
}

const AssetBalanceTable: FC<AssetBalanceTableProps> = (props) => {
  const dispatch = useDispatch()
  const { showFlashMsg } = props

  const { inputAssetsSummaryList } = useSelector((state: RootState) => state.inputAssets)

  const inputAssetArr = useMemo(() => {
    if (_isNull(inputAssetsSummaryList)) return []

    return [...inputAssetsSummaryList?.input_assets]
  }, [inputAssetsSummaryList])

  const handleChangeAssetType = (assetId: number, assetType: AssetType) => {
    const newValues = {
      input_asset: {
        asset_type: assetType as AssetType,
      },
    }

    return new Promise((resolve) =>
      dispatch(
        updateInputAsset(
          assetId,
          newValues,
          () => {
            dispatch(fetchInputAssetSummary())
            showFlashMsg()
          },
          resolve,
        ),
      ),
    )
  }

  // MEMO: PC表示用一覧
  const inputAssetTableRowPc = (inputAsset: InputAsset, assetType: AssetType) => (
    <tr className="border-b border-black-300">
      <td className="w-[50%] xl:w-[40%] pr-30 py-8 text-left">
        {defaultToEmptyString(inputAsset?.institution_name) +
          defaultToEmptyString(inputAsset?.current_name)}
      </td>
      <td
        className={clsx('w-[20%] xl:w-[30%] pr-30 xl:px-30 py-8 text-right', {
          'text-alert-600': (inputAsset?.current_value ?? 0) < 0,
        })}
      >
        {convertLocaleString(inputAsset?.current_value)}円
      </td>
      <td className="w-[30%] xl:pl-30 py-8 text-left">
        <SelectBox
          widthClassName="w-300"
          name={`inputAsset_${inputAsset.id}`}
          options={INPUT_ASSET_TYPE_SELECT_ITEMS}
          initialValue={assetType}
          onChange={(event: ChangeEvent<HTMLSelectElement>) =>
            handleChangeAssetType(inputAsset?.id, event.target.value as AssetType)
          }
        />
      </td>
    </tr>
  )

  // MEMO: SP表示用一覧
  const inputAssetTableRowSp = (inputAsset: InputAsset, assetType: AssetType) => (
    <div className="flex flex-col pb-24 space-y-16 border-b border-black-300">
      <Typography className="text-16" isBold>
        {defaultToEmptyString(inputAsset?.institution_name) +
          defaultToEmptyString(inputAsset?.current_name)}
      </Typography>
      <div className="flex items-center space-x-12 text-14">
        <Typography isBold>残高</Typography>
        <Typography
          className={clsx('flex-1', {
            'text-alert-600': (inputAsset?.current_value ?? 0) < 0,
          })}
        >
          {convertLocaleString(inputAsset?.current_value)}円
        </Typography>
      </div>
      <div className="flex items-center space-x-12 text-14">
        <Typography isBold>種別</Typography>
        <SelectBox
          className="flex-1"
          name={`inputAsset_${inputAsset.id}`}
          options={INPUT_ASSET_TYPE_SELECT_ITEMS}
          initialValue={assetType}
          isFullWidth
          onChange={(event: ChangeEvent<HTMLSelectElement>) =>
            handleChangeAssetType(inputAsset?.id, event.target.value as AssetType)
          }
        />
      </div>
    </div>
  )

  return (
    <Form
      onSubmit={() => {}}
      render={({ handleSubmit }) => {
        return (
          <form className="text-black-900" onSubmit={handleSubmit}>
            <div className="flex flex-col md:flex-row md:items-center justify-between mb-40 p-16 md:p-24 rounded-4 bg-secondary-50">
              <Typography className="text-18" isBold>
                Moneytreeから取得した<span className="inline-block">金融資産総額</span>
              </Typography>
              <div className="flex items-baseline ml-auto mr-0">
                <Typography className="text-30" isBold>
                  {convertLocaleString(
                    inputAssetsSummaryList?.financial_asset_total_amount,
                  )}
                </Typography>
                <Typography className="pl-5 text-16" isBold>
                  円
                </Typography>
              </div>
            </div>

            {/* Memo: PC表示 */}
            <table className="hidden md:block w-full">
              <thead>
                <tr className="border-b border-black-300">
                  <th className="w-[40%] pr-30 py-2 text-left">接続先名称</th>
                  <th className="w-[30%] pr-30 xl:px-30 py-2 text-right">残高</th>
                  <th className="w-[30%] xl:pl-30 py-2 text-left">種別</th>
                </tr>
              </thead>
              <tbody>
                {INPUT_ASSET_TYPE_SELECT_ITEMS.map((item, index) => (
                  <React.Fragment key={index}>
                    {hasSummaryOf(item?.value as AssetType, inputAssetArr) && (
                      <>
                        <tr className="bg-black-300">
                          <td className="w-[50%] xl:w-[40%] pr-30 py-8 text-left">
                            {convertInputAssetType(item?.value as AssetType, true)}
                          </td>
                          <td className="w-[20%] xl:w-[30%] pr-30 xl:px-30 py-8 text-right">
                            <span className="mr-5">総額</span>
                            {convertLocaleString(
                              inputAssetsSummaryList?.summary?.[item?.value as AssetType]
                                ?.total_amount,
                            )}
                            円
                          </td>
                          <td></td>
                        </tr>
                        {filterInputAsset(item?.value as AssetType, inputAssetArr).map(
                          (inputAsset, assetIndex) => (
                            <React.Fragment key={assetIndex}>
                              {inputAssetTableRowPc(inputAsset, item?.value as AssetType)}
                            </React.Fragment>
                          ),
                        )}
                      </>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
            {/* Memo: SP表示 */}
            <div className="md:hidden">
              {INPUT_ASSET_TYPE_SELECT_ITEMS.map((item, index) => (
                <React.Fragment key={index}>
                  {hasSummaryOf(item?.value as AssetType, inputAssetArr) && (
                    <>
                      <div className="flex justify-between mb-16 px-2 py-8 bg-black-300">
                        <Typography className="text-16" isBold>
                          {convertInputAssetType(item?.value as AssetType, true)}
                        </Typography>
                        <div className="flex items-center space-x-12 text-14">
                          <Typography isBold>総額</Typography>
                          <Typography className="flex-1">
                            {convertLocaleString(
                              inputAssetsSummaryList?.summary?.[item?.value as AssetType]
                                ?.total_amount,
                            )}
                            円
                          </Typography>
                        </div>
                      </div>
                      <div className="space-y-24">
                        {filterInputAsset(item?.value as AssetType, inputAssetArr).map(
                          (inputAsset, assetIndex) => (
                            <React.Fragment key={assetIndex}>
                              {inputAssetTableRowSp(inputAsset, item?.value as AssetType)}
                            </React.Fragment>
                          ),
                        )}
                      </div>
                    </>
                  )}
                </React.Fragment>
              ))}
            </div>
          </form>
        )
      }}
    />
  )
}

export default AssetBalanceTable
