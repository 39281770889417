import React, { FC } from 'react'
import { Switch, Route } from 'react-router-dom'
import DashboardIndex from '@/templates/v2/dashboards'

const Dashboard: FC = () => {
  return (
    <Switch>
      <Route exact path="/v2/dashboards" component={DashboardIndex} />
    </Switch>
  )
}

export default Dashboard
