export type DeviseType = 'sp' | 'pc'

export const DEVISE_TYPE = {
  smartPhone: 'sp' as DeviseType,
  pcOrTablet: 'pc' as DeviseType,
}

export const getDeviseType = (): DeviseType => {
  const userAgent = window.navigator.userAgent.toLowerCase()
  const iphone = userAgent.indexOf('iPhone') > 0
  const androidSp = userAgent.indexOf('Android') > 0 && userAgent.indexOf('Mobile') > 0
  // MEMO: タブレットの判別が別途必要になれば使用
  // const ipad = userAgent.indexOf('iPad')
  // const androidT = userAgent.indexOf('Android')

  if (iphone || androidSp) {
    return DEVISE_TYPE.smartPhone
  }

  return DEVISE_TYPE.pcOrTablet
}

export const isSmartPhone = () => {
  return navigator.userAgent.match(/iPhone|Android.+Mobile/) ? true : false
}

export const prohibitScalable = (event: any) => {
  event.touches.length >= 2 && event.preventDefault()
}
