import React, { FC, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { isUndefined as _isUndefined } from 'lodash'
import { parse } from 'query-string'
import { setVideoPlatformsLogindFunc } from '@/containers/videoPlatforms/authenticationsSlice'
import { RootState } from '@/store'
import { isMovieLogind } from '@/utils/movieAuth'

const MovieLogindRedirect: FC = (props) => {
  const dispatch = useDispatch()
  const { redirectUrl } = parse(location.search)

  const targetUrl = useMemo(
    () => (!_isUndefined(redirectUrl) ? `/movies${redirectUrl}` : '/movies/list'),
    [redirectUrl],
  )

  const { children } = props
  const { isLogin } = useSelector(
    (state: RootState) => state.videoPlatformsAuthentications,
  )

  useEffect(() => {
    isMovieLogind() && dispatch(setVideoPlatformsLogindFunc())
  }, [])

  return <>{isLogin ? <Redirect to={targetUrl} /> : <>{children}</>}</>
}

export default MovieLogindRedirect
