import React, { FC, SyntheticEvent } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons'
import clsx from 'clsx'
import Accordion from '@/components/v1/atoms/Accordion'
import AccordionDetails from '@/components/v1/atoms/AccordionDetails'
import AccordionSummary from '@/components/v1/atoms/AccordionSummary'
import Typography from '@/components/v1/atoms/Typography'
import { FURTHER_PALE_GRAY_COLOR } from '@/constants/theme'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      border: `3px solid ${FURTHER_PALE_GRAY_COLOR}`,
      marginBottom: '10px',
    },
    accordionSummary: {
      padding: '0px 10px 10px 10px',
      maxHeight: '38px',
      minHeight: '38px !important',
    },
    summaryContent: {
      display: 'block',
      padding: '10px 0px 0px',
    },
    summaryExpandIcon: {
      marginTop: '10px',
    },
    accordionDetails: {
      display: 'block',
      padding: '0 15px 15px 15px',
    },
    flexBetweenBlock: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    noneShadow: {
      boxShadow: 'none',
    },
  }),
)

interface AccordionSetProps {
  /** タイトル */
  title: string | JSX.Element
  /** タイトルクラス */
  titleClassName?: string
  /** タイトルクラス */
  customElement?: JSX.Element
  /** 初期表示の表示非表示 */
  defaultClose?: boolean
}

const AccordionSet: FC<AccordionSetProps> = (props) => {
  const classes = useStyles()
  const { title, titleClassName, customElement, children, defaultClose } = props

  return (
    <div className={classes.root}>
      <Accordion
        classes={{ root: classes.noneShadow }}
        defaultExpanded={!defaultClose}
        square
        onChange={(event: SyntheticEvent) => event.stopPropagation()}
      >
        <AccordionSummary
          classes={{
            root: classes.accordionSummary,
            content: classes.summaryContent,
            expandIcon: classes.summaryExpandIcon,
          }}
          expandIcon={<ExpandMoreIcon />}
        >
          {!!customElement ? (
            <div className={classes.flexBetweenBlock}>
              <Typography className={clsx({ [titleClassName!]: !!titleClassName })}>
                {title}
              </Typography>
              {customElement}
            </div>
          ) : (
            <Typography className={clsx({ [titleClassName!]: !!titleClassName })}>
              {title}
            </Typography>
          )}
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          {children}
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default AccordionSet
