import React, { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import MoneytreeIcon from '@/assets/images/v2/moneytree.svg'
import MarginContainer from '@/components/v2/atoms/MarginContainer'
import Paper from '@/components/v2/atoms/Paper'
import Heading from '@/components/v2/atoms/Heading'
import Typography from '@/components/v2/atoms/Typography'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import Button from '@/components/v2/atoms/Button'
import Tooltip from '@/components/v2/atoms/Tooltip'
import { setPageMeta } from '@/containers/pageMetasSlice'
import DataConnectionModalForm from '@/templates/v2/plans/commons/DataConnectionModalForm'
import { removeStorageIsClickedOnConnectionPage } from '@/utils/localStorage/isClickedOnConnectionPageStorage'

const DataConnection: FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'Moneytree口座情報連携',
        description: 'Moneytree口座情報連携',
        keyword: 'Moneytree口座情報連携',
      }),
    )
  }, [])

  const handleNextPage = () => {
    removeStorageIsClickedOnConnectionPage()
    history.push('/v2/plans/moneytreeDataConfirmation')
  }

  const handleModalClose = () => {
    setIsModalOpen(false)
  }

  return (
    <MarginContainer className="py-40 space-y-24">
      <Paper className="lg:max-w-820 mx-8 lg:mx-auto px-16 py-24 sm:p-40 space-y-24 bg-white">
        <Heading className="text-center">
          Moneytreeとの連携が<span className="inline-block">完了しました！</span>
          <br />
          口座の連携はお済みですか？
        </Heading>
        <Typography className="text-14 text-black-700 sm:text-center">
          Moneytreeアカウントとの連携が完了しました。
          <br />
          口座連携とは、マネソルのライフプランを簡単に作成するために、
          <span className="sm:inline-block">
            クレジットカード・銀行・証券などの口座の情報を取得することです。
          </span>
          <br />
          正確なライフプランを作成するために、Moneytreeアカウントに口座を連携してみましょう！
        </Typography>
        <div className="p-16 md:p-24 xl:p-40 rounded-16 bg-primary-75">
          <Heading as="h2" size={3} className="mb-24 text-primary-950 text-center">
            Moneytreeと連携するメリット
          </Heading>
          <div className="flex flex-col md:flex-row justify-center md:space-x-24 space-y-24 md:space-y-0">
            <div className="flex flex-col items-center px-12 sm:px-34 py-24 sm:py-30 md:max-w-320 rounded-8 bg-white">
              <img src="/static/images/v2/household-1.svg" className="mb-16" />
              <Typography className="text-14 text-black-900">
                自動で資産状況、収支状況をマネソルに送信できるため、手間なく、かつ、正確にライフプランニングができます。
              </Typography>
            </div>
            <div className="flex flex-col items-center px-12 sm:px-34 py-24 sm:py-30 md:max-w-320 rounded-8 bg-white">
              <img src="/static/images/v2/household-2.svg" className="mb-16" />
              <Typography className="text-14 text-center text-black-900">
                リアルタイムにデータを取得！
                <br />
                ライフプランの管理を容易に！
              </Typography>
            </div>
          </div>
        </div>
        <Typography className="text-14 text-center" isBold>
          下記の口座登録するボタンをクリックすると、
          <span className="inline-block">Moneytreeのページが開き、</span>
          <span className="inline-block">そちらで口座連携をしていただきます。</span>
        </Typography>
        <Typography className="text-20 text-alert-600 sm:text-center" isBold>
          口座情報の入力が完了したあとは、
          <span className="sm:inline-block">必ずこのページに戻ってきてください。</span>
        </Typography>
        <div className="p-16 md:p-24 xl:p-40 rounded-16 bg-primary-75">
          <div className="flex flex-col items-center p-12 space-y-24 rounded-8 bg-white">
            <MoneytreeIcon />
            <Typography className="text-14 text-center" isBold>
              Moneytreeの口座情報登録画面へ
            </Typography>
            <ButtonPrimary
              className="h-45 w-full md:w-215 px-17 text-white"
              onClick={() => setIsModalOpen(true)}
            >
              <Typography className="bottom-2 flex-auto relative text-14" isBold>
                口座登録する
              </Typography>
              <i className="ico chevron-right text-24" />
            </ButtonPrimary>
          </div>
        </div>
        <Tooltip
          className="mx-auto"
          itemClassName="bg-secondary-50 leading-tight p-12 text-14 w-[268px] text-black-800 before:border-t-secondary-50"
          item="上の口座登録するボタンからMoneytreeに遷移し、口座情報の登録・確認をしないと先へは進めません。"
        >
          <Button
            className="h-70 w-full md:w-350 px-17 text-white bg-alert-600 disabled:bg-black-500 rounded-8"
            onClick={handleNextPage}
          >
            <Typography className="bottom-2 flex-auto relative text-16" isBold>
              Moneytreeへの口座の情報が完了したので
              <span className="inline-block">マネソルに情報を取り込む</span>
            </Typography>
            <i className="ico chevron-right text-24" />
          </Button>
        </Tooltip>
        {isModalOpen && (
          <DataConnectionModalForm isOpen={isModalOpen} onClose={handleModalClose} />
        )}
      </Paper>
    </MarginContainer>
  )
}

export default DataConnection
