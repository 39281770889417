import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { isUndefined as _isUndefined, isNull as _isNull } from 'lodash'
import MarginContainer from '@/components/v2/atoms/MarginContainer'
import Paper from '@/components/v2/atoms/Paper'
import SeminarParticipantForm from '@/templates/v2/seminarParticipants/commons/SeminarParticipantForm'
import { setPageMeta } from '@/containers/pageMetasSlice'
import { SeminarParticipantUpdateValues } from '@/models/seminarParticipantsModel'
import { setTmpSeminarParticipant } from '@/containers/seminarParticipantsSlice'

const SeminarParticipantNew: FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'セミナー申し込みフォーム',
        description: 'セミナー申し込みフォーム',
        keyword: 'セミナー申し込みフォーム',
      }),
    )
  }, [])

  const { unique_id } = useParams<{
    unique_id?: string
  }>()

  const handleSubmit = (values: SeminarParticipantUpdateValues) => {
    dispatch(setTmpSeminarParticipant(values))
    history.push(`/seminarParticipants/${unique_id}/confirm`)
  }

  return (
    <MarginContainer className="py-40">
      <Paper className="mx-8 px-16 py-24 sm:p-40 bg-white">
        <SeminarParticipantForm
          title="セミナー申し込みフォーム"
          buttonLabel="入力内容を確認する"
          uniqueId={unique_id || ''}
          type="new"
          handleSubmit={handleSubmit}
        />
      </Paper>
    </MarginContainer>
  )
}

export default SeminarParticipantNew
