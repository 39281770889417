import React, { FC } from 'react'
import { useField, useForm } from 'react-final-form'
import { INPUT_REPAYMENT_CHART_BUTTON_ITEMS } from '@/constants/formItem/inputs/inputHousings'
import { API_FLAG } from '@/models/commonsModelFunc'
import { selectLoanObjectNumber } from '@/models/commonHousingModelFunc'
import { selectLoanObjectName, isPairLoan } from '@/models/inputs/inputLoansModelFunc'
import { required } from '@/utils/validate'

import Typography from '@/components/v2/atoms/Typography'
import SelectBox from '@/components/v2/molecules/SelectBox'

interface RepaymentChartProps {
  /** true: 建物用ローン, false: 土地用ローン・分割なし */
  isBuilding?: boolean
}

const RepaymentChart: FC<RepaymentChartProps> = (props) => {
  const { isBuilding } = props

  const { change } = useForm()
  const loanObjectNum = selectLoanObjectNumber(isBuilding)
  const targetPairObjectNum = isBuilding ? 3 : 1

  const {
    input: { value: loanType },
  } = useField(selectLoanObjectName(loanObjectNum, 'loan_type'))
  const {
    input: { value: loanType2 },
  } = useField(`input_housing.input_housing_self_owned_attributes.loan_type2`)

  const handleChangeRepaymentChart = (value: number) => {
    change(selectLoanObjectName(loanObjectNum, 'repayment_chart'), value)

    if (isPairLoan(loanType)) {
      change(selectLoanObjectName(targetPairObjectNum, 'repayment_chart'), value)
    }
  }

  const renderDescription = () => {
    let descHead = ''
    if (!isBuilding && loanType2 === API_FLAG.on) {
      descHead = '土地用ローンの'
    }
    if (isBuilding) {
      descHead = '建物用ローンの'
    }
    return descHead + '返済計画表の有無'
  }

  return (
    <>
      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
        <Typography
          className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
          isBold
        >
          {renderDescription()}
        </Typography>
        <SelectBox
          name={selectLoanObjectName(loanObjectNum, 'repayment_chart')}
          placeholder="選択する"
          isFullWidth
          options={INPUT_REPAYMENT_CHART_BUTTON_ITEMS}
          validate={required}
          onChange={(e: any) => handleChangeRepaymentChart(e.target.value)}
        />
      </div>
    </>
  )
}

export default RepaymentChart
