import React, { FC, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'react-final-form'
import { useParams } from 'react-router-dom'
import { FieldArray } from 'react-final-form-arrays'
import arrayMutators from 'final-form-arrays'
import moment from 'moment'

import ChevronRightIcon from '@/assets/images/v2/chevron-right.svg'
import UserPrifileIsometricIcon from '@/assets/images/v2/user-prifile-isometric.svg'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import Divider from '@/components/v2/atoms/Divider'
import MarginContainer from '@/components/v2/atoms/MarginContainer'
import Paper from '@/components/v2/atoms/Paper'
import Typography from '@/components/v2/atoms/Typography'
import Breadcrumb from '@/components/v2/molecules/Breadcrumb'
import { setPageMeta } from '@/containers/pageMetasSlice'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import SelectionButton from '@/components/v2/molecules/SelectionButton'
import PaperTitle from '@/components/v2/molecules/PaperTitle'
import LoadingOverlay from '@/components/v1/atoms/LoadingOverlay'
import SelectBox from '@/components/v2/molecules/SelectBox'
import NumberField from '@/components/v2/molecules/NumberField'
import { createInputSimpleDataV2 } from '@/containers/inputs/inputSimpeDataSlice'
import {
  MONTH_KANJI_SELECT_ITEMS,
  NEW_DAY_KANJI_SELECT_ITEMS,
} from '@/constants/formItem/commons'
import { useAccountControl } from '@/hooks/useAccountControl'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import { AccountContext } from '@/models/accountsModelFunc'
import { convertObjectToValueLabelObj, THIS_YEAR_NUMBER } from '@/models/commonsModelFunc'
import {
  PERSON_OCCUPATION,
  PERSON_ANNUAL_INCOME,
  SPOUSE_TYPE,
  SPOUSE_OCCUPATION,
  SPOUSE_ANNUAL_INCOME,
  EDUCATION_COST,
  LIVING_COST_MONTHLY,
  HOUSE_TYPE,
  HOUSING_COST_MONTHLY,
  SAVING_AMOUNT,
} from '@/models/inputs/inputSimpleDataModelFunc'
import { RootState } from '@/store'
import AccountDetailHeader from '@/templates/admins/accounts/details/_accountDetailHeader'
import {
  buildStandardSelectItems,
  buildTwoTypesRangeYearSelectItems,
} from '@/utils/formItem'
import { composeValidators, commaNumberMaxLength, required } from '@/utils/validate'

const MyDataSimple: FC = () => {
  const dispatch = useDispatch()
  const { isAdmin } = useContext(AccountContext)
  const { isMyDataManipulatedApproval } = useAccountControl()
  const { handleMovePage } = useCustomHistory()
  const { account_id } = useParams<{ account_id?: string }>()

  const { isLoading } = useSelector((state: RootState) => state.inputSimpleData)

  const handleSubmit = (values: any) => {
    // 年齢を組み立て

    const {
      person_birth_year,
      person_birth_month,
      person_birth_date,
      spouse_birth_year,
      spouse_birth_month,
      spouse_birth_date,
      input_simple_data_children_attributes,
    } = values.input_simple_data

    const newValues = {
      input_simple_data: {
        ...values.input_simple_data,
        person_birth_day: `${person_birth_year}-${person_birth_month}-${person_birth_date}`,
        spouse_birth_day: `${spouse_birth_year}-${spouse_birth_month}-${spouse_birth_date}`,
        input_simple_data_children_attributes: input_simple_data_children_attributes.map(
          (item: any) => {
            const { birth_year, birth_month, birth_date } = item
            return { birth_day: `${birth_year}-${birth_month}-${birth_date}` }
          },
        ),
      },
    }

    // 送信

    dispatch(
      createInputSimpleDataV2(newValues, () => {
        dispatch(
          setPageMeta({
            title: 'マイデータ | 簡単入力',
            description: 'マイデータ | 簡単入力',
            keyword: 'マイデータ | 簡単入力',
          }),
        )
        {
          isAdmin
            ? handleMovePage(`/v2/accounts/${account_id}/dashboards`)
            : handleMovePage('/v2/dashboards')
        }
      }),
    )
  }

  return (
    <>
      <LoadingOverlay isLoading={isLoading} />
      {isAdmin && <AccountDetailHeader />}
      <MarginContainer className="px-8 md:px-32 lg:px-0 py-8 md:py-40">
        <Breadcrumb className="pb-24 md:pb-32" data={[{ name: 'マイデータを入力' }]} />

        <PaperTitle
          className="mb-[16px] md:mb-[40px]"
          title="マイデータを入力"
          description="日々の収入や支出を登録することで改善案などのご提案が可能になります"
          icon={<UserPrifileIsometricIcon />}
          smallIcon={
            <UserPrifileIsometricIcon
              className="h-[82px] w-[130px]"
              viewBox="0 0 180 146"
            />
          }
        />

        <Paper className="bg-white p-24 md:p-40">
          <Typography className="pb-10 pr-8 text-24 md:text-28 text-black-800" isBold>
            あなたの収支について
          </Typography>

          <Divider className="border-black-300 border-t-2 pb-20 md:pb-24" />

          <Form
            onSubmit={handleSubmit}
            mutators={{ ...arrayMutators }}
            initialValues={{
              input_simple_data: {
                child_count: 0,
                input_simple_data_children_attributes: [],
              },
            }}
            render={({ handleSubmit, errors, values, form: { change } }) => {
              const {
                person_occupation,
                person_annual_income,
                spouse_type,
                spouse_occupation,
                spouse_annual_income,
                living_cost_monthly,
                housing_cost_monthly,
                child_count,
                saving_amount,
                person_birth_year,
                person_birth_month,
                person_birth_date,
                spouse_birth_year,
                spouse_birth_month,
                spouse_birth_date,
              } = values.input_simple_data

              return (
                <form onSubmit={handleSubmit}>
                  <div className="pb-40">
                    <CircleTitle
                      title="あなたの生年月日を教えて下さい"
                      classNameTitle="text-[19px] md:text-[24px]"
                      className="mb-[10px]"
                    />

                    <div className="lg:flex flex-auto items-baseline">
                      <div className="md:flex flex-auto items-baseline max-w-[645px]">
                        <SelectBox
                          isFullWidth
                          className="mx-[10px] pb-[10px] md:pb-0 md:max-w-[190px]"
                          name="input_simple_data.person_birth_year"
                          isPlaceholderHidden={true}
                          options={buildStandardSelectItems(
                            buildTwoTypesRangeYearSelectItems(
                              THIS_YEAR_NUMBER - 100,
                              THIS_YEAR_NUMBER,
                              true,
                            ),
                            '1990',
                            '年を選択',
                          )}
                          validate={required}
                          isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
                        />
                        <SelectBox
                          isFullWidth
                          className="mx-[10px] pb-[10px] md:pb-0  md:max-w-[190px]"
                          placeholder="月を選択"
                          name="input_simple_data.person_birth_month"
                          options={MONTH_KANJI_SELECT_ITEMS}
                          validate={required}
                          isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
                        />
                        <SelectBox
                          isFullWidth
                          className="mx-[10px] pb-[10px] md:pb-0  md:max-w-[190px]"
                          placeholder="日を選択"
                          name="input_simple_data.person_birth_date"
                          options={NEW_DAY_KANJI_SELECT_ITEMS}
                          validate={required}
                          isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
                        />
                      </div>

                      <div className="flex justify-end">
                        <Typography className="text-black-700 ml-[10px]">
                          {!!person_birth_year &&
                          !!person_birth_month &&
                          !!person_birth_date
                            ? `現在 ${moment().diff(
                                moment([
                                  person_birth_year,
                                  person_birth_month - 1,
                                  person_birth_date,
                                ]),
                                'years',
                              )} 歳`
                            : '生年月日を入力してください'}
                        </Typography>
                      </div>
                    </div>
                  </div>

                  <div className="pb-40">
                    <CircleTitle
                      title="あなたのご職業は？"
                      classNameTitle="text-[19px] md:text-[24px]"
                      className="mb-[10px]"
                    />

                    <SelectionButton
                      widthClassName="w-full md:w-[220px]"
                      name="input_simple_data.person_occupation"
                      options={convertObjectToValueLabelObj(PERSON_OCCUPATION)}
                      validate={required}
                      isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
                    />
                  </div>
                  {person_occupation != 'housewife' && (
                    <div className="pb-40">
                      <CircleTitle
                        title="年収を教えて下さい"
                        classNameTitle="text-[19px] md:text-[24px]"
                        className="mb-[10px]"
                      />
                      <SelectionButton
                        widthClassName="w-full md:w-[220px]"
                        name="input_simple_data.person_annual_income"
                        options={convertObjectToValueLabelObj(PERSON_ANNUAL_INCOME)}
                        validate={required}
                        isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
                      />

                      {person_annual_income == 'custom' && (
                        <div className="flex flex-auto items-baseline">
                          <NumberField
                            isFullWidth
                            name="input_simple_data.custom_person_annual_income_manyen"
                            validate={composeValidators(required, (value: any) =>
                              commaNumberMaxLength(value, 4),
                            )}
                            disabled={isAdmin ? !isMyDataManipulatedApproval() : false}
                          />
                          <Typography
                            className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800 min-w-[40px]"
                            isBold
                          >
                            万円
                          </Typography>
                        </div>
                      )}
                    </div>
                  )}

                  <div className="pb-40">
                    <CircleTitle
                      title="配偶者はいますか？"
                      classNameTitle="text-[19px] md:text-[24px]"
                      className="mb-[10px]"
                    />
                    <SelectionButton
                      widthClassName="w-full md:w-[220px]"
                      name="input_simple_data.spouse_type"
                      options={convertObjectToValueLabelObj(SPOUSE_TYPE)}
                      validate={required}
                      isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
                    />
                  </div>

                  {spouse_type == 'married' && (
                    <div className="pb-40">
                      <CircleTitle
                        title="配偶者の生年月日を教えて下さい"
                        classNameTitle="text-[19px] md:text-[24px]"
                        className="mb-[10px]"
                      />

                      <div className="lg:flex flex-auto items-baseline">
                        <div className="md:flex flex-auto items-baseline  max-w-[645px]">
                          <SelectBox
                            isFullWidth
                            className="m-[10px] md:max-w-[190px]"
                            name="input_simple_data.spouse_birth_year"
                            isPlaceholderHidden={true}
                            options={buildStandardSelectItems(
                              buildTwoTypesRangeYearSelectItems(
                                THIS_YEAR_NUMBER - 100,
                                THIS_YEAR_NUMBER,
                                true,
                              ),
                              '1990',
                              '年を選択',
                            )}
                            validate={required}
                            isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
                          />
                          <SelectBox
                            isFullWidth
                            className="m-[10px] md:max-w-[190px]"
                            placeholder="月を選択"
                            name="input_simple_data.spouse_birth_month"
                            options={MONTH_KANJI_SELECT_ITEMS}
                            validate={required}
                            isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
                          />
                          <SelectBox
                            isFullWidth
                            className="m-[10px] md:max-w-[190px]"
                            placeholder="日を選択"
                            name="input_simple_data.spouse_birth_date"
                            options={NEW_DAY_KANJI_SELECT_ITEMS}
                            validate={required}
                            isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
                          />
                        </div>

                        <div className="flex justify-end">
                          <Typography className="text-black-700 ml-[10px]">
                            {!!spouse_birth_year &&
                            !!spouse_birth_month &&
                            !!spouse_birth_date
                              ? `現在 ${moment().diff(
                                  moment([
                                    spouse_birth_year,
                                    spouse_birth_month - 1,
                                    spouse_birth_date,
                                  ]),
                                  'years',
                                )} 歳`
                              : '生年月日を入力してください'}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  )}

                  {spouse_type == 'married' && (
                    <div className="pb-40">
                      <CircleTitle
                        title="配偶者のご職業は？"
                        classNameTitle="text-[19px] md:text-[24px]"
                        className="mb-[10px]"
                      />
                      <SelectionButton
                        widthClassName="w-full md:w-[220px]"
                        name="input_simple_data.spouse_occupation"
                        options={convertObjectToValueLabelObj(SPOUSE_OCCUPATION)}
                        validate={required}
                        isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
                      />
                    </div>
                  )}

                  {spouse_type == 'married' && spouse_occupation != 'housewife' && (
                    <div className="pb-40">
                      <CircleTitle
                        title="配偶者の年収は？"
                        classNameTitle="text-[19px] md:text-[24px]"
                        className="mb-[10px]"
                      />
                      <SelectionButton
                        widthClassName="w-full md:w-[220px]"
                        name="input_simple_data.spouse_annual_income"
                        options={convertObjectToValueLabelObj(SPOUSE_ANNUAL_INCOME)}
                        validate={required}
                        isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
                      />
                      <Typography className="pt-24 text-12">
                        ※ 扶養の範囲内でパート・アルバイトをしている場合も含みます
                      </Typography>

                      {spouse_annual_income == 'custom' && (
                        <div className="flex flex-auto items-baseline mt-[30px]">
                          <NumberField
                            isFullWidth
                            name="input_simple_data.custom_spouse_annual_income_manyen"
                            validate={composeValidators(required, (value: any) =>
                              commaNumberMaxLength(value, 4),
                            )}
                            disabled={isAdmin ? !isMyDataManipulatedApproval() : false}
                          />
                          <Typography
                            className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800 min-w-[40px]"
                            isBold
                          >
                            万円
                          </Typography>
                        </div>
                      )}
                    </div>
                  )}

                  <div className="pb-40">
                    <CircleTitle
                      title="子供の人数は？"
                      classNameTitle="text-[19px] md:text-[24px]"
                      className="mb-[10px]"
                    />
                    <SelectionButton
                      widthClassName="w-full md:w-[120px]"
                      name="input_simple_data.child_count"
                      options={[
                        { value: 0, label: 'いない' },
                        { value: 1, label: '1人' },
                        { value: 2, label: '2人' },
                        { value: 3, label: '3人' },
                        { value: 4, label: '4人' },
                        { value: 5, label: '5人' },
                        { value: 6, label: '6人' },
                        { value: 7, label: '7人' },
                        { value: 8, label: '8人' },
                        { value: 9, label: '9人' },
                      ]}
                      validate={required}
                      onClick={(value: any) => {
                        values.input_simple_data.input_simple_data_children_attributes.push(
                          {},
                          {},
                          {},
                          {},
                          {},
                          {},
                          {},
                          {},
                          {},
                          {},
                        )
                        change(
                          'input_simple_data.input_simple_data_children_attributes',
                          values.input_simple_data.input_simple_data_children_attributes.slice(
                            0,
                            Number(value),
                          ),
                        )
                      }}
                      isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
                    />
                  </div>

                  {child_count > 0 && (
                    <div className="pb-40">
                      <CircleTitle
                        title="子供の誕生日は？"
                        classNameTitle="text-[19px] md:text-[24px]"
                        className="mb-[10px]"
                      />

                      <FieldArray name="input_simple_data.input_simple_data_children_attributes">
                        {({ fields }) =>
                          fields.map((name, index) => (
                            <React.Fragment key={index}>
                              <div className="md:flex flex-auto items-baseline">
                                <div>
                                  <Typography
                                    isBold
                                    className="min-w-[fit-content]"
                                  >{`第${index + 1}子`}</Typography>
                                </div>
                                <div className="md:flex">
                                  <SelectBox
                                    isFullWidth
                                    className="m-[10px] md:max-w-[190px]"
                                    name={`${name}birth_year`}
                                    isPlaceholderHidden={true}
                                    options={buildStandardSelectItems(
                                      buildTwoTypesRangeYearSelectItems(
                                        THIS_YEAR_NUMBER - 100,
                                        THIS_YEAR_NUMBER,
                                        true,
                                      ),
                                      '2010',
                                      '年を選択',
                                    )}
                                    validate={required}
                                    isDisabled={
                                      isAdmin ? !isMyDataManipulatedApproval() : false
                                    }
                                  />
                                  <SelectBox
                                    isFullWidth
                                    className="m-[10px] md:max-w-[190px]"
                                    placeholder="月を選択"
                                    name={`${name}birth_month`}
                                    options={MONTH_KANJI_SELECT_ITEMS}
                                    validate={required}
                                    isDisabled={
                                      isAdmin ? !isMyDataManipulatedApproval() : false
                                    }
                                  />
                                  <SelectBox
                                    isFullWidth
                                    className="m-[10px] md:max-w-[190px]"
                                    placeholder="日を選択"
                                    name={`${name}birth_date`}
                                    options={NEW_DAY_KANJI_SELECT_ITEMS}
                                    validate={required}
                                    isDisabled={
                                      isAdmin ? !isMyDataManipulatedApproval() : false
                                    }
                                  />
                                </div>

                                <div className="flex justify-end">
                                  <Typography className="text-black-700 ml-[10px]">
                                    {!!values.input_simple_data
                                      .input_simple_data_children_attributes[index]
                                      .birth_year &&
                                    !!values.input_simple_data
                                      .input_simple_data_children_attributes[index]
                                      .birth_month &&
                                    !!values.input_simple_data
                                      .input_simple_data_children_attributes[index]
                                      .birth_date
                                      ? `現在 ${moment().diff(
                                          moment([
                                            values.input_simple_data
                                              .input_simple_data_children_attributes[
                                              index
                                            ].birth_year,
                                            values.input_simple_data
                                              .input_simple_data_children_attributes[
                                              index
                                            ].birth_month - 1,
                                            values.input_simple_data
                                              .input_simple_data_children_attributes[
                                              index
                                            ].birth_date,
                                          ]),
                                          'years',
                                        )} 歳`
                                      : '生年月日を入力してください'}
                                  </Typography>
                                </div>
                              </div>
                            </React.Fragment>
                          ))
                        }
                      </FieldArray>
                    </div>
                  )}

                  {child_count > 0 && (
                    <div className="pb-40">
                      <CircleTitle
                        title="教育費はどれくらいかけていますか？"
                        classNameTitle="text-[19px] md:text-[24px]"
                        className="mb-[10px]"
                      />
                      <SelectionButton
                        widthClassName="w-full md:w-[220px]"
                        name="input_simple_data.education_cost"
                        options={convertObjectToValueLabelObj(EDUCATION_COST)}
                        validate={required}
                        isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
                      />
                    </div>
                  )}

                  <div className="pb-40">
                    <CircleTitle
                      title="月の生活費は？"
                      classNameTitle="text-[19px] md:text-[24px]"
                      className="mb-[10px]"
                    />
                    <SelectionButton
                      widthClassName="w-full md:w-[220px]"
                      name="input_simple_data.living_cost_monthly"
                      options={convertObjectToValueLabelObj(LIVING_COST_MONTHLY)}
                      validate={required}
                      isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
                    />
                    {living_cost_monthly == 'custom' && (
                      <div className="flex flex-auto items-baseline mt-[30px]">
                        <NumberField
                          isFullWidth
                          name="input_simple_data.custom_living_cost_monthly_manyen"
                          validate={composeValidators(required, (value: any) =>
                            commaNumberMaxLength(value, 4),
                          )}
                          disabled={isAdmin ? !isMyDataManipulatedApproval() : false}
                        />
                        <Typography
                          className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800 min-w-[40px]"
                          isBold
                        >
                          万円
                        </Typography>
                      </div>
                    )}
                  </div>

                  <div className="pb-40">
                    <CircleTitle
                      title="お住まいの形態"
                      classNameTitle="text-[19px] md:text-[24px]"
                      className="mb-[10px]"
                    />
                    <SelectionButton
                      widthClassName="w-full md:w-[220px]"
                      name="input_simple_data.house_type"
                      options={convertObjectToValueLabelObj(HOUSE_TYPE)}
                      validate={required}
                      isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
                    />
                  </div>

                  <div className="pb-40">
                    <CircleTitle
                      title="月の住宅費は？"
                      classNameTitle="text-[19px] md:text-[24px]"
                      className="mb-[10px]"
                    />
                    <SelectionButton
                      widthClassName="w-full md:w-[220px]"
                      name="input_simple_data.housing_cost_monthly"
                      options={convertObjectToValueLabelObj(HOUSING_COST_MONTHLY)}
                      validate={required}
                      isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
                    />
                    {housing_cost_monthly == 'custom' && (
                      <div className="flex flex-auto items-baseline mt-[30px]">
                        <NumberField
                          isFullWidth
                          name="input_simple_data.custom_housing_cost_monthly_manyen"
                          validate={composeValidators(required, (value: any) =>
                            commaNumberMaxLength(value, 4),
                          )}
                          disabled={isAdmin ? !isMyDataManipulatedApproval() : false}
                        />
                        <Typography
                          className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800 min-w-[40px]"
                          isBold
                        >
                          万円
                        </Typography>
                      </div>
                    )}
                  </div>

                  <div className="pb-40">
                    <CircleTitle
                      title="現在の貯蓄額は？"
                      classNameTitle="text-[19px] md:text-[24px]"
                      className="mb-[10px]"
                    />
                    <SelectionButton
                      widthClassName="w-full md:w-[220px]"
                      name="input_simple_data.saving_amount"
                      options={convertObjectToValueLabelObj(SAVING_AMOUNT)}
                      validate={required}
                      isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
                    />
                    {saving_amount == 'custom' && (
                      <div className="flex flex-auto items-baseline mt-[30px]">
                        <NumberField
                          isFullWidth
                          name="input_simple_data.custom_saving_amount_manyen"
                          validate={composeValidators(required, (value: any) =>
                            commaNumberMaxLength(value, 7),
                          )}
                          disabled={isAdmin ? !isMyDataManipulatedApproval() : false}
                        />
                        <Typography
                          className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800 min-w-[40px]"
                          isBold
                        >
                          万円
                        </Typography>
                      </div>
                    )}
                  </div>

                  <div className="flex justify-center">
                    <ButtonPrimary
                      className="h-64 w-246"
                      isDisabled={Object.keys(errors).length > 0}
                      onClick={() => handleSubmit(values)}
                    >
                      <Typography
                        className="bottom-2 flex-auto pl-30 relative text-16 text-white"
                        isBold
                      >
                        登録する
                      </Typography>
                      <ChevronRightIcon className="mr-17" />
                    </ButtonPrimary>
                  </div>
                </form>
              )
            }}
          />
        </Paper>
      </MarginContainer>
    </>
  )
}

export default MyDataSimple
