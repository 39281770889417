import React, { FC } from 'react'
import Typography from '@/components/v2/atoms/Typography'

const classes = {
  table: 'block border-separate overflow-x-scroll',
  tr: 'text-black-800',
  th:
    'text-black-800 max-w-[80px] min-w-[80px] h-[50px] bg-white odd:bg-black-100 border-b-[2px] border-black-400',
  td: 'h-[50px] bg-white odd:bg-black-100 border-b border-black-400',
}

const CityBankInterestRateTable: FC = () => {
  const rateTableData = [
    ['2000年', '1.675％', '2.65％', '2.95％'],
    ['2005年', '1.675％', '2.55％', '2.820％'],
    ['2010年', '1.475％', '2.50％', '2.57％'],
    ['2015年', '0.775％', '1.75％', '1.47％'],
    ['2020年', '0.475％', '1.10％', '1.29％'],
  ]

  return (
    <div className="relative max-w-full overflow-x-auto whitespace-nowrap w-[fit-content] m-auto">
      <Typography
        className="text-black-800 text-[14px] py-[10px] items-center text-center"
        isBold
      >
        都市銀行の過去の金利推移
      </Typography>

      <table className={classes.table} style={{ borderSpacing: 'initial' }}>
        <tbody>
          <tr>
            <th className="text-black-800 sticky left-0 max-w-[70px] min-w-[70px] h-[50px] z-[40] bg-white border-b-[2px] border-r-[2px] border-black-400">
              <Typography className="text-[12px]" isBold>
                年
              </Typography>
            </th>
            <th className={classes.th}>
              <Typography className="text-[12px]" isBold>
                変動金利
              </Typography>
            </th>
            <th className={classes.th}>
              <Typography className="text-[12px]" isBold>
                固定10年
              </Typography>
            </th>
            <th className={classes.th}>
              <Typography className="text-[12px]" isBold>
                フラット35
              </Typography>
            </th>
          </tr>
          {rateTableData.map((rateTable: any, index) => {
            return (
              <tr key={index} className={classes.tr}>
                <td className="sticky left-0 h-[50px] z-[40] bg-white border-b border-r-[2px] border-black-400">
                  <Typography className="text-center text-[12px]" isBold>
                    {rateTable[0]}
                  </Typography>
                </td>
                <td className={classes.td}>
                  <Typography className="text-center">{rateTable[1]}</Typography>
                </td>
                <td className={classes.td}>
                  <Typography className="text-center">{rateTable[2]}</Typography>
                </td>
                <td className={classes.td}>
                  <Typography className="text-center">{rateTable[3]}</Typography>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>

      <Typography className="text-black-700 my-[10px] text-[10px] text-right pr-[10px]">
        出典：三井住友銀行・住宅金融支援機構
      </Typography>
    </div>
  )
}

export default CityBankInterestRateTable
