import React, { FC } from 'react'

interface TooltipProps {
  /** クラス名 */
  className?: string
  /** アイテムクラス名 */
  itemClassName?: string
  /** アイテム */
  item: string | JSX.Element
  /** 位置: top | bottom */
  position?: 'top' | 'bottom'
  /** 表示するかどうか */
  isDispaly?: boolean
}

const Tooltip: FC<TooltipProps> = (props) => {
  const { className, itemClassName, item, children, position, isDispaly } = props
  const positionStyle = {
    top: `
      before:top-full
      before:border-t-secondary-50
      before:border-r-transparent
      before:border-l-transparent
      before:border-b-transparent
    `,
    bottom: `
      translate-y-[142%]
      before:top-[-14px]
      before:border-t-transparent
      before:border-r-transparent
      before:border-l-transparent
      before:border-b-secondary-50
    `,
  }

  return (
    <>
      {isDispaly == null || isDispaly ? (
        <div className={`${className} group h-fit-content relative w-fit-content`}>
          <div
            className={`${itemClassName} ${
              positionStyle[position || 'top'] || ''
            } hidden absolute bottom-full left-2/4 mb-[7px] -translate-x-2/4 z-10
          before:absolute before:left-2/4
          before:ml-[-7px] before:border-[7px]
          group-hover:block
        `}
          >
            {item}
          </div>
          {children}
        </div>
      ) : (
        children
      )}
    </>
  )
}

export default Tooltip
