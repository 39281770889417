import React, { FC } from 'react'

interface PaperProps {
  /** クラス名 */
  className?: string
}

const Paper: FC<PaperProps> = (props) => {
  const { className, children } = props
  return <div className={`${className} rounded-16 shadow-paper`}>{children}</div>
}

export default Paper
