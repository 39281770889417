import React, { FC } from 'react'
import Simulation02 from '@/assets/images/v2/simulation-g02.png'
import Simulation03 from '@/assets/images/v2/simulation-g03.png'
import Simulation04 from '@/assets/images/v2/simulation-g04.png'
import SimulationPanelImg from '@/assets/images/v2/simulation-g-panel.png'
import Typography from '@/components/v2/atoms/Typography'
import GuidanceModal from '@/components/v2/organisms/GuidanceModal'

interface SimulationFirstInstructionModalProps {
  /** true: オープン, false: クローズ */
  isOpen: boolean
  /** モーダルクローズメソッド */
  onClose: () => void
}

const SimulationFirstInstructionModal: FC<SimulationFirstInstructionModalProps> = (props) => {
  const { isOpen, onClose } = props

  return (
    <GuidanceModal
      isOpen={isOpen}
      title="シミュレーションページのガイダンス"
      isDisplayProgress
      contents={[
        {
          text: (
            <>
              <Typography className="pb-[10px] text-12 md:text-16">
                ここでは、あなたの将来のプランを登録することで
                <br />
                現状と将来のお金の動きをシミュレーションすることができます。
              </Typography>
              <Typography className="text-12 md:text-16">
                それぞれの表・グラフの見方をおさえていきましょう！
              </Typography>
            </>
          ),
        },
        {
          text: (
            <div className="pb-[30px]">
              <Typography className="text-12 md:text-16">
                サマリーでは、現状と将来プランのそれぞれの場合での、
                <br />
                「何歳まで資産があるのか？」というお金の寿命を確認できます。
              </Typography>
            </div>
          ),
          src: Simulation02,
          alt: 'simulation02',
        },
        {
          text: (
            <div className="pb-[30px]">
              <Typography className="text-12 md:text-16">
                ライフプランシミュレーショングラフでは、
                <br />
                マイデータや別プランとお金の動きを比較することができます。
              </Typography>
            </div>
          ),
          src: Simulation03,
          alt: 'simulation03',
        },
        {
          text: (
            <div className="pb-[30px]">
              <Typography className="text-12 md:text-16">
                ライフプランシミュレーション表では、
                <br />
                お金の動きを、お金の内訳ごとに確認できます。
              </Typography>
            </div>
          ),
          src: Simulation04,
          alt: 'simulation04',
        },
      ]}
      onClose={onClose}
    />
  )
}

export default SimulationFirstInstructionModal
