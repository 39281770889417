import React, { FC, useEffect, useState, useContext, useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import clsx from 'clsx'
import {
  isUndefined as _isUndefined,
  includes as _includes,
  times as _times,
  find as _find,
  each as _each,
  isNull as _isNull,
  pick as _pick,
} from 'lodash'
import ChevronRightIcon from '@/assets/images/v2/chevron-right-small-gray.svg'
import ChevronUpIcon from '@/assets/images/v2/chevron-up-small-gray.svg'
import Button from '@/components/v2/atoms/Button'
import Tooltip from '@/components/v2/atoms/Tooltip'
import Typography from '@/components/v2/atoms/Typography'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import { AccountContext } from '@/models/accountsModelFunc'
import { PartiallyPartial, TableColumnData } from '@/models/commonsModel'
import { convertYenToManyen, isNotEmptyValue } from '@/models/commonsModelFunc'
import { RootState } from '@/store'

import ErrorExclamationIcon from '@/assets/images/v2/error-exclamation.svg'
import CautionIcon from '@/assets/images/caution.svg'

export const NESTED_TABLE_COLORS: any[] = [
  {
    even: {
      color: '#DDF1F1',
      border: '#8DCDCF',
    },
    odd: { color: '#FFF8FD', border: '#DAC4E0' },
  },
  {
    even: { color: '#FAE7E2', border: '#F3CDDE' },
    odd: { color: '#DAECF5', border: '#ADD6F0' },
  },
  {
    even: { color: '#FFFAE4', border: '#FDE998' },
    odd: { color: '#FFF4E7', border: '#FBCAA1' },
  },
]

interface SimulationTableBodyProps {
  /** クラス名 */
  className?: string
  /** true: 比較有り, false: 比較無し */
  isCompare: boolean
  /** true: マイデータ, false: シミュレーションプラン */
  isCompareMyData: boolean
  /** true: 保険グラフ表示中, false: その他のグラフ */
  isShowInsurance: boolean
}

const SimulationTableBody: FC<SimulationTableBodyProps> = (props) => {
  const { isAdmin } = useContext(AccountContext)

  const { className, isCompare, isCompareMyData, isShowInsurance } = props
  const thWidthClassName = 'max-w-[55px] min-w-[55px] hover-bg-8fb6ff'
  const classes = {
    columnDataTable: {
      table: 'border-separate text-[11px]',
      th: `pr-[5px] ${thWidthClassName} h-[20px] text-right box-border border-b border-r border-black-400 bg-white text-[13px] p-0`,
      td:
        'sticky left-0 max-w-[130px] md:max-w-[270px] min-w-[130px] md:min-w-[270px] h-[20px] items-center box-border z-[10] bg-white pl-[10px] border-b border-l border-r-[2px] border-black-400',
      tdWoZ:
        'sticky left-0 max-w-[130px] md:max-w-[270px] min-w-[130px] md:min-w-[270px] h-[20px] items-center box-border bg-white pl-[10px] border-b border-l border-r-[2px] border-black-400',
    },
  }

  const [breakpoint, setBreakpoint] = useState<string>('lg')
  const [currentCellId, setCurrentCellId] = useState<string>('')
  const [collapseStatus, setCollapseStatus] = useState<{ [key: string]: boolean }>({
    asset_total: true,
    financial_asset_total: true,
    net_asset_total: true,
  })

  const {
    detailFutureBase,
    detailFutureBaseV2: {
      statement_table_data, // 表に使うデータ
      statement_table_columns, // 表に使うカラム
      family_structure,
      family_structure_table_columns, // 通常時に表に使う家族家族のカラム
      input_family_structure_table_columns, // マイデータ選択時に表に使う家族データのカラム
      input_statement_table_data, // マイデータ選択時に表に使うマイデータのカラム
      input_statement_table_insurance_data, //  マイデータ選択時に表に使うマイデータのカラム(保険タブ選択時のみ)
      statement_table_insurance_columns, // 保険グラフ選択時に表に使うデータのカラム
      statement_table_insurance_data, // 保険グラフ選択時に表に使うデータ

      death_target, // 想定の死亡者
    },
  } = useSelector((state: RootState) => state.futureBases)

  const handleWindowResize = () => {
    let breakpoint = ''

    if (window.innerWidth >= 1024) {
      breakpoint = 'lg'
    } else if (window.innerWidth >= 768) {
      breakpoint = 'md'
    } else {
      breakpoint = 'sm'
    }

    setBreakpoint(breakpoint)
  }

  useEffect(() => {
    handleWindowResize()
    window.addEventListener('resize', handleWindowResize)

    return () => document.removeEventListener('mousedown', handleWindowResize)
  }, [])

  useEffect(() => {
    if (!isNotEmptyValue(family_structure_table_columns)) return
    const trElementHeight = 20

    const targetColumnKeys = [
      { key: 'passed_year_num' },
      { key: 'year' },
      ...family_structure_table_columns.map((item: any) => {
        return { key: item.key }
      }),
    ]

    _each(targetColumnKeys, (item, index: number) => {
      const trElement = document.getElementById(item.key)
      if (_isNull(trElement)) return

      trElement.style.top = `${index * trElementHeight}px`
    })

    if (!isCompare) return

    const comparePlanElement = document.getElementById('compare-plan')
    if (_isNull(comparePlanElement)) return

    comparePlanElement.style.top = `${targetColumnKeys.length * trElementHeight}px`
    comparePlanElement.style.backgroundColor = '#fff'

    _each(targetColumnKeys, (item, index: number) => {
      const trElement = document.getElementById(`compare-${item.key}`)
      if (_isNull(trElement)) return

      trElement.style.top = `${index * trElementHeight}px`
    })
  }, [family_structure_table_columns, isCompare])

  // カラム名のデータ
  const columnData = useMemo(() => {
    return isShowInsurance
      ? [statement_table_insurance_columns]
      : [statement_table_columns]
  }, [isShowInsurance, statement_table_insurance_columns, statement_table_columns])

  const comparePlanName = useMemo(() => {
    return isCompareMyData ? 'マイデータ' : detailFutureBase.simulation_info?.future_name
  }, [isCompareMyData, detailFutureBase.simulation_info?.future_name])

  // 比較で使う家族データ(カラム)
  const compareTableFamilyColumns = useMemo(() => {
    return isCompareMyData
      ? input_family_structure_table_columns // マイデータ
      : detailFutureBase.family_structure_table_columns // 比較対象のシミュレーション
  }, [
    isCompareMyData,
    input_family_structure_table_columns,
    detailFutureBase.family_structure_table_columns,
  ])

  // ベースで使う家族データ(カラム)
  const tableFamilyColumns = useMemo(() => {
    return family_structure_table_columns
  }, [family_structure_table_columns])

  // 比較で使うデータ(値)
  const compareTableData = useMemo(() => {
    if (isCompareMyData) {
      return isShowInsurance
        ? [input_statement_table_insurance_data]
        : [input_statement_table_data]
    }

    // 比較対象のシミュレーション
    return isShowInsurance
      ? [detailFutureBase.statement_table_insurance_data]
      : [detailFutureBase.statement_table_data]
  }, [isCompare, isCompareMyData, isShowInsurance, statement_table_insurance_data])

  // 比較で使う家族データ(値)
  const compareTableFamilyData = useMemo(() => {
    if (!compareTableData[0] || !compareTableFamilyColumns) return null

    const returnValue = Object.keys(compareTableData[0]).reduce((acc: any, year: any) => {
      acc[year] = _pick(
        compareTableData[0][year],
        compareTableFamilyColumns.map((obj: any) => obj.key),
      )
      return acc
    }, {})
    return returnValue
  }, [isCompareMyData, compareTableData, compareTableFamilyColumns])

  // ベースで使うデータ(値)
  const tableData = useMemo(() => {
    let returnBaseObj = isShowInsurance
      ? { ...statement_table_insurance_data }
      : { ...statement_table_data }
    if (!isCompare) return [returnBaseObj]

    Object.keys(compareTableData[0]).forEach((year: any) => {
      if (Object.keys(returnBaseObj).indexOf(year) < 0) {
        returnBaseObj[year] = {
          target_year: year,
        }
      }
    })
    return [returnBaseObj]
  }, [
    isShowInsurance,
    statement_table_insurance_data,
    statement_table_data,
    isCompare,
    compareTableData,
  ])

  // ベースで使う家族データ(値)
  const tableFamilyData = useMemo(() => {
    if (!tableData[0] || !tableFamilyColumns) return null

    const returnValue = Object.keys(tableData[0]).reduce((acc: any, year: any) => {
      acc[year] = _pick(
        tableData[0][year],
        tableFamilyColumns.map((obj: any) => obj.key),
      )
      return acc
    }, {})
    return returnValue
  }, [tableData, tableFamilyColumns])

  const boldKeys = [
    'income_total',
    'spending_total',
    'saving_amount',
    'financial_asset_total',
  ]

  const getNestedKey = (index: number) => {
    return index % 2 === 0 ? 'even' : 'odd'
  }

  const fillIfEmpty = useCallback(
    (year: any, displayData: any) => {
      if (tableData?.[0]?.[year]?.person_age) {
        return displayData
      }
      return '-'
    },
    [tableData],
  )

  const renderDataLabel = (data: any) => {
    if (_isUndefined(data)) return 0
    return convertYenToManyen(data, 'ceil')
  }

  // ライフプランシミュレーション表の背景色決定
  // 本人死亡の場合は本人の行をグレーアウト、
  // 配偶者死亡の場合は配偶者の行をグレーアウトする
  const getBackgroundColor = (key: string) => {
    // マイデータと比較中、かつマイデータのデータが登録されてない場合
    if (isCompareMyData && compareTableData.length === 0) {
      return key === 'person_age' || key === 'spouse_age' ? '#FFEEA4' : '#FFFAE4'
    }

    if (key === 'person_age') {
      return isShowInsurance && death_target === 0 ? '#c0c0c0' : '#FFEEA4'
    }

    if (key === 'spouse_age') {
      return isShowInsurance && death_target === 1 ? '#c0c0c0' : '#FFEEA4'
    }

    return '#FFFAE4'
  }

  const renderTableCell = (
    item: PartiallyPartial<TableColumnData, 'index' | 'nested_columns'>,
    nestedKeys: string[],
    isRoot: boolean,
    parentIndex: number,
    tableData: any,
    isShowingCompare: boolean,
  ) => {
    const hasNestedColumn = !_isUndefined(item.nested_columns)
    const endIndex = nestedKeys.length - 1
    const parentNestedKeys = nestedKeys.slice(0, -1)
    const currentColorItem = NESTED_TABLE_COLORS?.[endIndex]?.[nestedKeys[endIndex]]

    const standardLength = endIndex === 0 ? 8 : endIndex === 1 ? 6 : 4
    const isEllipsis = item.display_name.length > standardLength && breakpoint === 'sm'
    const displayName = isEllipsis
      ? item.display_name.substring(0, standardLength)
      : item.display_name

    const renderDisplayName = () => {
      return (
        <>
          <Typography
            className={clsx({
              'font-bold': !_isUndefined(boldKeys)
                ? _includes(boldKeys, item.key)
                : isRoot || hasNestedColumn,
              ['pl-5']: !hasNestedColumn,
            })}
          >
            {displayName}
          </Typography>
          {isShowInsurance &&
            _includes(
              ['不動産投資収支', '不動産投資経費', 'ローン支払い'],
              displayName,
            ) && (
              <div className="min-w-[30px]">
                <Tooltip
                  item="団体信用生命保険に加入していない場合は、相続人がローンを相続できるケースが少ないため、団体信用生命保険がかかっていない物件のみ売却するシミュレーションとなっております。"
                  itemClassName="absolute w-[80px] sm:w-[200px] p-[8px] text-secondary-500 bg-secondary-50 leading-tight before:border-b-secondary-50"
                  position="bottom"
                >
                  <div className="max-w-[20px] pl-[5px]">
                    <ErrorExclamationIcon viewBox="-2 -3 25 25" />
                  </div>
                </Tooltip>
              </div>
            )}

          {isEllipsis && (
            <Tooltip
              item={item.display_name}
              itemClassName="w-[max-content] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
            >
              <Typography>...</Typography>
            </Tooltip>
          )}
        </>
      )
    }

    return (
      <React.Fragment key={item.key}>
        {collapseStatus[item.key] && (
          <>
            {item.nested_columns?.map((item, index) =>
              renderTableCell(
                item,
                !_isUndefined(item.nested_columns)
                  ? [...nestedKeys, getNestedKey(index)]
                  : nestedKeys,
                false,
                parentIndex,
                tableData,
                isShowingCompare,
              ),
            )}
          </>
        )}
        <tr>
          <td
            className={`${classes.columnDataTable.tdWoZ} p-0 first:pl-0 ${
              isShowInsurance && _includes(['ローン支払い'], displayName)
                ? 'z-[13]'
                : _includes(['不動産投資経費'], displayName)
                ? 'z-[12]'
                : _includes(['不動産投資収支'], displayName)
                ? 'z-[11]'
                : 'z-[10]'
            }`}
          >
            <div className="flex h-full">
              {parentNestedKeys.map((key, index) => (
                <div
                  key={index}
                  className="min-w-[20px] md:min-w-[30px]"
                  style={{
                    backgroundColor: NESTED_TABLE_COLORS?.[index]?.[key]?.color,
                    borderLeft: `3px solid ${NESTED_TABLE_COLORS?.[index]?.[key]?.border}`,
                  }}
                />
              ))}
              {!hasNestedColumn && (
                <div
                  className="min-w-[20px] md:min-w-[30px]"
                  style={{
                    backgroundColor: currentColorItem?.color,
                    borderLeft: `3px solid ${currentColorItem?.border}`,
                  }}
                />
              )}
              <div
                className="flex w-full items-center"
                style={{
                  backgroundColor: hasNestedColumn ? currentColorItem?.color : '#ffffff',
                  borderLeft: hasNestedColumn
                    ? `3px solid ${currentColorItem?.border}`
                    : '',
                }}
              >
                {hasNestedColumn && (
                  <Button
                    className="mx-[5px]"
                    onClick={() =>
                      setCollapseStatus({
                        ...collapseStatus,
                        [item.key]: !collapseStatus[item.key],
                      })
                    }
                  >
                    {collapseStatus[item.key] ? <ChevronUpIcon /> : <ChevronRightIcon />}
                  </Button>
                )}
                {renderDisplayName()}
              </div>
            </div>
          </td>
          {Object.keys(tableData?.[parentIndex]).map((year) => {
            const cellId = `${item.key}_${year}`
            return (
              <td
                key={year}
                className={`${classes.columnDataTable.th} last:border-r odd:bg-black-100`}
                style={{
                  borderRight: '1px dashed #C6C6C6',
                  backgroundColor: hasNestedColumn ? currentColorItem?.color : '#ffffff',
                  border: currentCellId == cellId ? '2px solid #0071ff' : '',
                }}
                id={cellId}
                onClick={() => setCurrentCellId(cellId)}
              >
                <Typography
                  className={clsx({
                    'text-alert-600':
                      !_isUndefined(tableData?.[parentIndex][Number(year)][item.key]) &&
                      tableData?.[parentIndex][Number(year)][item.key] < 0,
                  })}
                >
                  {isShowingCompare
                    ? renderDataLabel(tableData?.[parentIndex][Number(year)][item.key])
                    : fillIfEmpty(
                        year,
                        renderDataLabel(tableData?.[parentIndex][Number(year)][item.key]),
                      )}
                </Typography>
              </td>
            )
          })}
        </tr>
      </React.Fragment>
    )
  }

  return (
    <div className={className}>
      <CircleTitle
        classNameTitle="text-20 md:text-24"
        className="pb-[24px]"
        title={
          isShowInsurance
            ? '遺族のライフプランシミュレーション表'
            : 'ライフプランシミュレーション表'
        }
      />

      {columnData.length > 0 && tableFamilyColumns?.length > 0 && (
        <>
          <table
            className={`border-separate block overflow-x-scroll text-[11px] ${
              isAdmin ? 'h-[calc(100vh-104px)] min-h-[480px]' : ''
            } ${
              !isAdmin
                ? 'h-[calc(100vh-100px)] md:h-[calc(100vh-378px)] md:min-h-[480px]'
                : ''
            }`}
            style={{ borderSpacing: 'initial' }}
          >
            <tbody>
              {isCompare && (
                <tr id="plan" className="z-[19]">
                  <td className="sticky left-0">
                    <Typography className="text-[16px] pb-[5px]" isBold>
                      ベースプラン：現在作成中のプラン
                    </Typography>
                  </td>
                  <td colSpan={Object.keys(tableFamilyData)?.length} />
                </tr>
              )}
              <tr id="passed_year_num" className="sticky z-[19]">
                <td className={`${classes.columnDataTable.td} border-t`}>
                  <Typography isBold>経過年数</Typography>
                </td>
                {Object.keys(tableFamilyData)?.map((year, index) => {
                  const cellId = `${index + 1}_${year}`
                  return (
                    <td
                      key={year}
                      className={`${classes.columnDataTable.th} border-t`}
                      style={{
                        border: currentCellId == cellId ? '2px solid #0071ff' : '',
                      }}
                      id={cellId}
                      onClick={() => setCurrentCellId(cellId)}
                    >
                      <Typography isBold>{index + 1}</Typography>
                    </td>
                  )
                })}
              </tr>
              <tr id="year" className="sticky z-[19]">
                <td className={`${classes.columnDataTable.td}`}>
                  <Typography isBold>年号</Typography>
                </td>
                {Object.keys(tableFamilyData)?.map((year) => {
                  const cellId = `${year}_family`
                  return (
                    <td
                      key={year}
                      className={`${classes.columnDataTable.th}`}
                      style={{
                        border: currentCellId == cellId ? '2px solid #0071ff' : '',
                      }}
                      id={cellId}
                      onClick={() => setCurrentCellId(cellId)}
                    >
                      <Typography>{year}</Typography>
                    </td>
                  )
                })}
              </tr>

              {tableFamilyColumns.map((item: any) => (
                <tr key={item.key} id={item.key} className="sticky z-[19]">
                  <td
                    className={`${classes.columnDataTable.td}`}
                    style={{
                      backgroundColor: getBackgroundColor(item.key),
                    }}
                  >
                    <Typography isBold>{item.display_name}</Typography>
                  </td>
                  {Object.keys(tableFamilyData).map((year) => {
                    const cellId = `${item.key}_${year}`
                    return (
                      <td
                        key={year}
                        className={`${classes.columnDataTable.th}`}
                        id={cellId}
                        style={{
                          backgroundColor: getBackgroundColor(item.key),
                          border: currentCellId == cellId ? '2px solid #0071ff' : '',
                        }}
                        onClick={() => setCurrentCellId(cellId)}
                      >
                        <Typography>
                          {fillIfEmpty(year, tableFamilyData?.[Number(year)]?.[item.key])}
                        </Typography>
                      </td>
                    )
                  })}
                </tr>
              ))}

              <tr>
                <td className={`${classes.columnDataTable.td}`}>
                  <Typography isBold>イベント</Typography>
                </td>
                {Object.keys(tableFamilyData)?.map((year) => {
                  const cellId = `${year}_event`
                  return (
                    <td
                      id={cellId}
                      key={year}
                      className={`pr-[5px] ${thWidthClassName} h-[30px] text-right box-border border-b border-r border-black-400 bg-white `}
                      style={{
                        border: currentCellId == cellId ? '2px solid #0071ff' : '',
                      }}
                      onClick={() => setCurrentCellId(cellId)}
                    >
                      {family_structure?.families.map((item: any, index: number) => (
                        <React.Fragment key={index}>
                          <Typography>
                            {_find(item.events, (event) => event.year === Number(year))
                              ?.name ?? ''}
                          </Typography>
                        </React.Fragment>
                      ))}
                    </td>
                  )
                })}
              </tr>
            </tbody>

            {_times(columnData.length, (parentIndex) => (
              <tbody key={parentIndex} className="before:block before:h-[30px]">
                <tr>
                  {Object.keys(tableData?.[parentIndex] || {})?.map((year, index) => {
                    const cellId = `${year}_statement`
                    return (
                      <React.Fragment key={year}>
                        {index === 0 && (
                          <th
                            className={`${classes.columnDataTable.td} z-[40] bg-white p-0 border-t border-b-[2px]`}
                          />
                        )}
                        <th
                          key={year}
                          className={`${classes.columnDataTable.th} border-t border-b-[2px] last:border-r`}
                          style={{
                            borderRight: '1px dashed #C6C6C6',
                            border: currentCellId == cellId ? '2px solid #0071ff' : '',
                          }}
                          id={cellId}
                          onClick={() => setCurrentCellId(cellId)}
                        >
                          <Typography isBold>{year}</Typography>
                        </th>
                      </React.Fragment>
                    )
                  })}
                </tr>
                {columnData?.[parentIndex]?.map((item: any, index: number) => (
                  <React.Fragment key={item.key}>
                    {renderTableCell(
                      item,
                      [getNestedKey(index)],
                      true,
                      parentIndex,
                      tableData,
                      false,
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            ))}

            {isCompare && (
              <>
                <tbody className="before:block before:h-[30px]">
                  <tr id="compare-plan" className="z-[19]">
                    <td className="sticky left-0">
                      <Typography className="text-[16px] pb-[5px]" isBold>
                        {`比較プラン：${comparePlanName ?? '（プラン名未設定）'}`}
                      </Typography>
                    </td>
                    <td colSpan={Object.keys(compareTableFamilyData)?.length} />
                  </tr>
                  <tr id="compare-passed_year_num" className="sticky z-[19]">
                    <td className={`${classes.columnDataTable.td} border-t`}>
                      <Typography isBold>経過年数</Typography>
                    </td>
                    {Object.keys(compareTableFamilyData)?.map((year, index) => {
                      const cellId = `${index + 1}_${year}`
                      return (
                        <td
                          key={year}
                          className={`${classes.columnDataTable.th} border-t`}
                          style={{
                            border: currentCellId == cellId ? '2px solid #0071ff' : '',
                          }}
                          id={cellId}
                          onClick={() => setCurrentCellId(cellId)}
                        >
                          <Typography isBold>{index + 1}</Typography>
                        </td>
                      )
                    })}
                  </tr>
                  <tr id="compare-year" className="sticky z-[19]">
                    <td className={`${classes.columnDataTable.td}`}>
                      <Typography isBold>年号</Typography>
                    </td>
                    {Object.keys(compareTableFamilyData)?.map((year) => {
                      const cellId = `${year}_family`
                      return (
                        <td
                          key={year}
                          className={`${classes.columnDataTable.th}`}
                          style={{
                            border: currentCellId == cellId ? '2px solid #0071ff' : '',
                          }}
                          id={cellId}
                          onClick={() => setCurrentCellId(cellId)}
                        >
                          <Typography>{year}</Typography>
                        </td>
                      )
                    })}
                  </tr>

                  {compareTableFamilyColumns.map((item: any) => (
                    <tr
                      key={item.key}
                      id={`compare-${item.key}`}
                      className="sticky z-[19]"
                    >
                      <td
                        className={`${classes.columnDataTable.td}`}
                        style={{
                          backgroundColor: getBackgroundColor(item.key),
                        }}
                      >
                        <Typography isBold>{item.display_name}</Typography>
                      </td>
                      {Object.keys(compareTableFamilyData).map((year) => {
                        const cellId = `${item.key}_${year}`
                        return (
                          <td
                            key={year}
                            className={`${classes.columnDataTable.th}`}
                            style={{
                              backgroundColor: getBackgroundColor(item.key),
                              border: currentCellId == cellId ? '2px solid #0071ff' : '',
                            }}
                            id={cellId}
                            onClick={() => setCurrentCellId(cellId)}
                          >
                            <Typography>
                              {compareTableFamilyData?.[Number(year)]?.[item.key]}
                            </Typography>
                          </td>
                        )
                      })}
                    </tr>
                  ))}

                  <tr>
                    <td className={`${classes.columnDataTable.td}`}>
                      <Typography isBold>イベント</Typography>
                    </td>
                    {Object.keys(compareTableFamilyData)?.map((year) => {
                      const cellId = `${year}_event`
                      return (
                        <td
                          key={year}
                          className={`${classes.columnDataTable.th}`}
                          id={cellId}
                          style={{
                            border: currentCellId == cellId ? '2px solid #0071ff' : '',
                          }}
                          onClick={() => setCurrentCellId(cellId)}
                        >
                          {family_structure?.families.map((item: any, index: number) => (
                            <React.Fragment key={index}>
                              <Typography>
                                {_find(
                                  item.events,
                                  (event) => event.year === Number(year),
                                )?.name ?? ''}
                              </Typography>
                            </React.Fragment>
                          ))}
                        </td>
                      )
                    })}
                  </tr>
                </tbody>

                {compareTableData &&
                  _times(columnData.length, (parentIndex) => (
                    <tbody key={parentIndex} className="before:block before:h-[30px]">
                      <tr>
                        {Object.keys(compareTableData?.[parentIndex] || {})?.map(
                          (year, index) => {
                            const cellId = `${year}_statement`
                            return (
                              <React.Fragment key={year}>
                                {index === 0 && (
                                  <th
                                    className={`${classes.columnDataTable.td} z-[40] bg-white p-0 border-t border-b-[2px]`}
                                  />
                                )}
                                <th
                                  key={year}
                                  className={`${classes.columnDataTable.th} border-t border-b-[2px] last:border-r`}
                                  id={cellId}
                                  style={{
                                    borderRight: '1px dashed #C6C6C6',
                                    border:
                                      currentCellId == cellId ? '2px solid #0071ff' : '',
                                  }}
                                  onClick={() => setCurrentCellId(cellId)}
                                >
                                  <Typography isBold>{year}</Typography>
                                </th>
                              </React.Fragment>
                            )
                          },
                        )}
                      </tr>
                      {columnData?.[parentIndex]?.map((item: any, index: number) => (
                        <React.Fragment key={item.key}>
                          {renderTableCell(
                            item,
                            [getNestedKey(index)],
                            true,
                            parentIndex,
                            compareTableData,
                            true,
                          )}
                        </React.Fragment>
                      ))}
                    </tbody>
                  ))}
              </>
            )}
          </table>
        </>
      )}
    </div>
  )
}

export default SimulationTableBody
