import { extractYearOrMonthFromDate } from '../commonsModelFunc'

export const convertRateStartAt = (
  rateStartAt: string | null,
  changedRatePercent: number | null,
) => {
  return `${extractYearOrMonthFromDate(
    rateStartAt,
    'year',
  )}年から ${changedRatePercent}％`
}
