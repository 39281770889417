import React, { FC, useState, useContext, useEffect, useMemo } from 'react'
import clsx from 'clsx'
import { includes as _includes, filter as _filter } from 'lodash'
import Typography from '@/components/v2/atoms/Typography'
import SimulationAccordion from '@/templates/v2/simulations/_simulationAccordion'
import SimulationNewButton from './_simulationNewButton'
import FutureCarModalForm from '@/templates/v2/simulations/_futureCarModalForm'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/store'
import Button from '@/components/v2/atoms/Button'
import { convertPaymentType, PAYMENT_TYPE } from '@/models/futures/futureCarsModelFunc'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import { destroyFutureCarV2 } from '@/containers/futures/futureCarsSlice'
import { fetchAccountFutureItems } from '@/containers/accountsSlice'
import { SimulationDetailEditContext } from '@/models/futures/futureBasesModelFunc'
import {
  THIS_YEAR_NUMBER,
  convertLocaleString,
  extractYearOrMonthFromDate,
} from '@/models/commonsModelFunc'
import SimulationItemAccordion from '@/templates/v2/simulations/_simulationItemAccordion'

interface FutureCarFormProps {
  /** 選択シミュレーションID */
  selectedSimulationId: number[] | undefined
  /** 選択メソッド */
  handleSelect: (item: any) => void
  /* FutureItemsを参照するID */
  futureBaseId?: number
  /* 初期化すべきかどうか */
  shouldInitialize?: boolean
  /* 編集を確認したか */
  confirmEdit?: boolean
  /* 編集確認モーダルを展開するメソッド */
  openEditConfirmModal?: () => void
}

interface FutureCarTableProps {
  /** アイテム */
  item: any
  /** td要素のクラス */
  tdClassName?: string
}

export const FutureCarTable: FC<FutureCarTableProps> = ({ item, tdClassName }) => {
  return (
    <table
      key={item.id}
      className={clsx('w-[100%] mb-[20px] last:mb-0 text-[12px] md:text-[14px] bg-white')}
    >
      <tbody>
        <tr className="h-[45px] odd:bg-black-100">
          <td className={`${tdClassName ?? 'w-[50%]'} px-[16px] md:px-[30px]`}>
            <Typography isBold>所有期間</Typography>
          </td>
          <td className="px-[16px] md:px-[30px]">
            <Typography>{`${item.ride_start_at_age}歳 から ${item.ride_end_at_age}歳`}</Typography>
          </td>
        </tr>

        <tr className="h-[45px] odd:bg-black-100">
          <td className="px-[16px] md:px-[30px]">
            <Typography isBold>乗り換え頻度</Typography>
          </td>
          <td className="x-[16px] md:px-[30px]">
            <Typography>{`${item.transfer_frequency}年毎`}</Typography>
          </td>
        </tr>

        <tr className="h-[45px] odd:bg-black-100">
          <td className="px-[16px] md:px-[30px]">
            <Typography className="min-w-[max-content]" isBold>
              購入金額（諸費用込み）
            </Typography>
          </td>
          <td className="px-[16px] md:px-[30px]">
            <Typography>{`${convertLocaleString(
              item.purchase_price_manyen,
            )}万円`}</Typography>
          </td>
        </tr>

        <tr className="h-[45px] odd:bg-black-100">
          <td className="px-[16px] md:px-[30px]">
            <Typography isBold>支払方法</Typography>
          </td>
          <td className="px-[16px] md:px-[30px]">
            <Typography>{convertPaymentType(item.payment_type)}</Typography>
          </td>
        </tr>

        {item.payment_type === PAYMENT_TYPE.loan && (
          <tr className="h-[45px] odd:bg-black-100">
            <td className="px-[16px] md:px-[30px]">
              <Typography isBold>ローン頭金</Typography>
            </td>
            <td className="px-[16px] md:px-[30px]">
              <Typography>{`${convertLocaleString(item.loan_earnest)}円`}</Typography>
            </td>
          </tr>
        )}

        {item.payment_type === PAYMENT_TYPE.loan && (
          <tr className="h-[45px] odd:bg-black-100">
            <td className=" px-[16px] md:px-[30px]">
              <Typography isBold>ローン返済期間</Typography>
            </td>
            <td className="px-[16px] md:px-[30px]">
              <Typography>{`${item.loan_period}ヶ月`}</Typography>
            </td>
          </tr>
        )}

        {item.payment_type === PAYMENT_TYPE.loan && (
          <tr className="h-[45px] odd:bg-black-100">
            <td className="px-[16px] md:px-[30px]">
              <Typography isBold>ローン金利</Typography>
            </td>
            <td className="px-[16px] md:px-[30px]">
              <Typography>{`${item.loan_interest_rate_percent}％`}</Typography>
            </td>
          </tr>
        )}

        <tr className="h-[45px] odd:bg-black-100">
          <td className=" px-[16px] md:px-[30px]">
            <Typography isBold>車検代</Typography>
          </td>
          <td className="px-[16px] md:px-[30px]">
            <Typography>{`${convertLocaleString(
              item.vehicle_inspection_fee,
            )}円/年`}</Typography>
          </td>
        </tr>

        <tr className="h-[45px] odd:bg-black-100">
          <td className="px-[16px] md:px-[30px]">
            <Typography isBold>自動車税</Typography>
          </td>
          <td className="px-[16px] md:px-[30px]">
            <Typography>{`${convertLocaleString(item.automobile_tax)}円/年`}</Typography>
          </td>
        </tr>

        <tr className="h-[45px] odd:bg-black-100">
          <td className="px-[16px] md:px-[30px]">
            <Typography isBold>月ごとの保険料</Typography>
          </td>
          <td className="px-[16px] md:px-[30px]">
            <Typography>{`${convertLocaleString(
              item.insurance_premium,
            )}円/月`}</Typography>
          </td>
        </tr>

        <tr className="h-[45px] odd:bg-black-100">
          <td className="px-[16px] md:px-[30px]">
            <Typography isBold>ガソリン代</Typography>
          </td>
          <td className="px-[16px] md:px-[30px]">
            <Typography>{`${convertLocaleString(item.gasoline_fee)}円/月`}</Typography>
          </td>
        </tr>

        <tr className="h-[45px] odd:bg-black-100">
          <td className="px-[16px] md:px-[30px]">
            <Typography isBold>駐車場代</Typography>
          </td>
          <td className="px-[16px] md:px-[30px]">
            <Typography>{`${convertLocaleString(item.parking_fee)}円/月`}</Typography>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

const FutureCarForm: FC<FutureCarFormProps> = (props) => {
  const dispatch = useDispatch()
  const { showFlashMsg } = useFlashAlert()
  const { isManipulatedApproval } = useContext(SimulationDetailEditContext)

  const { selectedSimulationId, handleSelect } = props

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [insuranceId, setInsuranceId] = useState<number | null>(null)

  const futureCars = useSelector(
    (state: RootState) => state.accounts.futureItems?.future_cars,
  )
  const { familyInfoV2 } = useSelector((state: RootState) => state.familyStructures)

  useEffect(() => {
    if (props.futureBaseId == null || !props.shouldInitialize) return
    _filter(futureCars, { future_base_id: props.futureBaseId }).forEach((item) =>
      handleSelect(item),
    )
  }, [props.shouldInitialize])

  const sortedFutureCars = useMemo(() => {
    if (props.futureBaseId == null || futureCars == null) return futureCars
    const eqFbId = (item: any) => item.future_base_id === props.futureBaseId
    return [
      ...futureCars.filter((item) => eqFbId(item)),
      ...futureCars.filter((item) => !eqFbId(item)),
    ]
  }, [futureCars])

  const titleIndex = (index: number) => {
    if (props.futureBaseId == null || props.confirmEdit == null || futureCars == null)
      return index + 1
    const eqFbId = (item: any) => item.future_base_id === props.futureBaseId
    return index + 1 - futureCars.filter((item) => eqFbId(item)).length
  }

  const isExpiredSettings = (age: number) => {
    const personAge =
      THIS_YEAR_NUMBER - extractYearOrMonthFromDate(familyInfoV2?.birth_day, 'year') || 0
    return age < personAge
  }

  const handleExecIfConfired = (exec: (item: any) => void, item: any) => {
    props.confirmEdit === false ? props.openEditConfirmModal?.() : exec(item)
  }

  return (
    <SimulationAccordion
      className="pb-[16px]"
      title="車・バイクの費用の検討"
      isSelected={selectedSimulationId != null && selectedSimulationId.length !== 0}
      selectedCount={selectedSimulationId?.length}
      itemCount={sortedFutureCars?.length}
      isExpiredSettings={sortedFutureCars?.some(
        (item) =>
          _includes(selectedSimulationId, item.id) &&
          isExpiredSettings(item.ride_start_at_age ?? 0),
      )}
    >
      <div className="flex flex-col gap-y-[12px] pt-[6px]">
        {sortedFutureCars?.map((item: any, index: number) => {
          const handleDestroyV2 = (item: any) => {
            const result = confirm('本当に削除してよろしいですか？')
            if (!result) return

            const destroyFunc = new Promise((resolve) => {
              dispatch(
                destroyFutureCarV2(item, item.id, () => {
                  resolve('')
                  showFlashMsg('削除しました')
                }),
              )
            })

            Promise.all([destroyFunc]).then(() => {
              if (_includes(selectedSimulationId, item.id)) handleSelect(item)
              dispatch(fetchAccountFutureItems(props.futureBaseId))
            })
          }

          const handleEdit = (item: any) => {
            setInsuranceId(item.id)
            setIsEdit(true)
            setIsOpenModal(true)
          }

          return (
            <SimulationItemAccordion
              key={`future_car_${item.id}_${index}`}
              title={
                props.futureBaseId === item.future_base_id && props.confirmEdit != null
                  ? '登録中のお車・バイク'
                  : `お車・バイク${titleIndex(index)}`
              }
              isSelected={_includes(selectedSimulationId, item.id)}
              isExpiredSettings={isExpiredSettings(item.ride_start_at_age)}
              inputType="checkbox"
              onSelect={() => {
                handleExecIfConfired(handleSelect, item)
              }}
            >
              <div className="flex justify-end mb-[5px]">
                <Button
                  className="mr-[16px]"
                  isDisabled={!isManipulatedApproval}
                  onClick={(e) => {
                    handleExecIfConfired(handleEdit, item)
                    e.stopPropagation()
                  }}
                >
                  <i className="ico edit text-[14px] text-black-700" />
                  <Typography className="pl-[5px] text-[14px] text-black-700">
                    編集
                  </Typography>
                </Button>
                <Button
                  isDisabled={!isManipulatedApproval}
                  onClick={(e) => {
                    handleExecIfConfired(handleDestroyV2, item)
                    e.stopPropagation()
                  }}
                >
                  <Typography className="pl-[5px] text-[14px] text-black-700">
                    削除
                  </Typography>
                </Button>
              </div>
              {/* {renderFutureCarTable(item)} */}
              <FutureCarTable item={item} />
            </SimulationItemAccordion>
          )
        })}
      </div>

      <SimulationNewButton
        isDisabled={!isManipulatedApproval}
        onClick={() => {
          setInsuranceId(null)
          setIsEdit(false)
          setIsOpenModal(true)
        }}
      />

      {isOpenModal && (
        <FutureCarModalForm
          isOpen={isOpenModal}
          isEdit={isEdit}
          onClose={() => setIsOpenModal(false)}
          id={insuranceId}
          futureBaseId={props.futureBaseId}
        />
      )}
    </SimulationAccordion>
  )
}

export default FutureCarForm
