import React, { FC } from 'react'
import {
  commaNumberMaxLength,
  composeValidators,
  zeroOrMoreNumber,
  required,
} from '@/utils/validate'

import Typography from '@/components/v2/atoms/Typography'
import NumberField from '@/components/v2/molecules/NumberField'

const TransferMonthlyRent: FC = () => {
  return (
    <>
      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
        <Typography
          className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
          isBold
        >
          単身赴任先の家賃
        </Typography>
        <div className="flex flex-auto items-baseline">
          <NumberField
            isFullWidth
            name="input_housing.input_housing_transfer_attributes.transfer_rent"
            validate={composeValidators(required, zeroOrMoreNumber, (value: any) =>
              commaNumberMaxLength(value, 10),
            )}
          />
          <Typography className="pl-[8px] text-[14px] text-black-800 min-w-[fit-content]">
            円/月
          </Typography>
        </div>
      </div>
    </>
  )
}

export default TransferMonthlyRent
