import {
  Company,
  CompanyPaymentHistoryResponse,
  CompanyPaymentHistory,
  CompanyContractUpdateValues,
  CompanyCreateValues,
  CompanyUpdateValues,
  CompanyInfo,
  SearchCompany,
  SearchCompanyResponse,
} from '@/models/admins/CompaniesModel'
import { ApiError } from '@/models/commonsModel'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { isUndefined as _isUndefined, sortBy as _sortBy } from 'lodash'
import {
  getCompanies,
  getCompanyPaymentHistories,
  postCompany,
  postCompanyLearningPlatformPassword,
  postSearchCompany,
  patchCompany,
  patchCompanyContact,
  deleteCompanyLearningPlatformPassword,
} from '../../api/admins/companies'
import { AppThunk } from '../../store'
import { unauthorizedAdminCheck } from './fpAccountsSlice'

interface CompanyState {
  companies: Company[]
  companyInfo: CompanyInfo
  companyPaymentHistories: CompanyPaymentHistory[]
  searchCompany: SearchCompany | null
  isLoading: boolean
}

const initialState: CompanyState = {
  companies: [],
  companyInfo: {
    companies: [],
    homepage_paid_valid_account_count: 0,
    homepage_unpaid_valid_account_count: 0,
  },
  companyPaymentHistories: [],
  searchCompany: null,
  isLoading: false,
}

const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    setCompaniesInfo: (state, action: PayloadAction<CompanyInfo>) => {
      state.companyInfo = action.payload
      state.companies = _sortBy(action.payload.companies, 'id')
      return state
    },
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    getCompanyPaymentHistoriesSuccess: (
      state,
      action: PayloadAction<CompanyPaymentHistoryResponse[]>,
    ) => {
      state.companyPaymentHistories = action.payload
      state.isLoading = false
      return state
    },
    postSearchCompanySuccess: (state, action: PayloadAction<SearchCompanyResponse>) => {
      state.searchCompany = action.payload
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
  },
})

export const {
  requestStart,
  requestSuccess,
  getCompanyPaymentHistoriesSuccess,
  postSearchCompanySuccess,
  requestFailure,
  setCompaniesInfo,
} = companiesSlice.actions
export const companiesReducer = companiesSlice.reducer

export const fetchCompaniesInfo = (): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  getCompanies()
    .then((response) => {
      dispatch(requestSuccess())
      dispatch(setCompaniesInfo(response))
    })
    .catch((error) => {
      dispatch(requestFailure())
    })
}

export const fetchCompanyPaymentHistories = (): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  getCompanyPaymentHistories()
    .then((response) => dispatch(getCompanyPaymentHistoriesSuccess(response)))
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedAdminCheck(error))
    })
}

export const createCompany = (
  values: CompanyCreateValues,
  callback: () => void,
  resolve: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postCompany(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedAdminCheck(error, resolve))
    })
}

export const createCompanyLearningPlatformPassword = (
  id: number,
  callback: () => void,
  resolve: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postCompanyLearningPlatformPassword(id)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedAdminCheck(error, resolve))
    })
}

export const createSearchCompany = (registrationCode: string): AppThunk => async (
  dispatch,
) => {
  dispatch(requestStart())
  postSearchCompany(registrationCode)
    .then((response) => dispatch(postSearchCompanySuccess(response)))
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedAdminCheck(error))
    })
}

export const updateCompany = (
  values: CompanyUpdateValues,
  callback: () => void,
  resolve: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchCompany(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedAdminCheck(error, resolve))
    })
}

export const updateCompanyContract = (
  values: CompanyContractUpdateValues,
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchCompanyContact(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedAdminCheck(error))
    })
}

export const destroyCompanyLearningPlatformPassword = (
  id: number,
  callback: () => void,
  resolve: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  deleteCompanyLearningPlatformPassword(id)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedAdminCheck(error, resolve))
    })
}
