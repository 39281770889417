import React, { FC } from 'react'
import Typography from '@/components/v2/atoms/Typography'
import CircleTitle from '@/components/v2/molecules/CircleTitle'

interface ModalInstructionProps {
  isSale?: boolean
}

const ModalInstruction: FC<ModalInstructionProps> = (props) => {
  const { isSale } = props

  const titleText = isSale ? '売却' : '賃貸化'
  const secondText = isSale
    ? 'まずは、売却の詳細について入力しましょう。'
    : 'まずは、賃貸化の詳細について入力していきましょう。'

  return (
    <>
      <CircleTitle
        className="md:col-span-2 col-span-1 my-[16px]"
        title={`${titleText}の詳細`}
      />
      <Typography
        className="min-w-[140px] lg:min-w-[164px] mb-[16px] md:pb-0 text-[14px] text-black-800 md:col-span-2 col-span-1"
        isBold
      >
        その後に住み替え先についてお伺いします。
        <br />
        {secondText}
      </Typography>
    </>
  )
}

export default ModalInstruction
