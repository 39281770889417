import ApiClient from '@/api/apiClient'
import { FutureAssetCreateValues } from '@/models/futures/futureAssetsModel'

export const postFutureAssetV2 = async (values: FutureAssetCreateValues) => {
  return await ApiClient.post('/future_assets', () => {}, values, undefined, 'v2')
}

export const patchFutureAssetV2 = async (values: any, id: number) => {
  return await ApiClient.patch(`/future_assets/${id}`, () => {}, values, undefined, 'v2')
}

export const deleteFutureAssetV2 = async (values: any, id: number) => {
  return await ApiClient.delete(`/future_assets/${id}`, () => {}, values, undefined, 'v2')
}
