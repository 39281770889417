import React, { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import MarginContainer from '@/components/v2/atoms/MarginContainer'
import Paper from '@/components/v2/atoms/Paper'
import Heading from '@/components/v2/atoms/Heading'
import Typography from '@/components/v2/atoms/Typography'
import Link from '@/components/v2/atoms/Link'
import MoneytreeSkipModal from '@/components/v2/organisms/MoneytreeSkipModal'
import { setPageMeta } from '@/containers/pageMetasSlice'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import MoneytreeConnect from '@/templates/v2/plans/commons/MoneytreeConnect'

const MoneytreeConnection: FC = () => {
  const dispatch = useDispatch()
  const { handleBackPage } = useCustomHistory()

  const [isMoneysolSkipModalOpen, setIsMoneysolSkipModalOpen] = useState<boolean>(false)

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'Moneytree連携',
        description: 'Moneytree連携',
        keyword: 'Moneytree連携',
      }),
    )
  }, [])

  return (
    <MarginContainer className="py-40 space-y-24">
      <Paper className="lg:max-w-820 mx-8 lg:mx-auto px-16 py-24 sm:p-40 space-y-24 bg-white">
        <Heading className="text-center">Moneytreeと連携</Heading>
        <Typography className="text-14 text-black-700 sm:text-center">
          家計簿アプリと連携することで、入力の手間が省ける他、常に最新の状態を反映できるため、
          <span className="sm:inline-block">
            ライフプランを作成した後の実行管理が大幅に楽になります。
          </span>
          <br />
          住宅ローンを借りている場合は、残高が簡単に確認できる、繰上げ返済の計画が建てられます。
        </Typography>
        <MoneytreeConnect />
      </Paper>
      <div className="text-center">
        <Link
          className="text-14 text-primary-900 pb-[8px]"
          children="前の画面へ戻る"
          onClick={handleBackPage}
        />
      </div>
      <div className="text-center">
        <Link
          className="cursor-pointer text-center text-14 text-black-700"
          children="今は連携しない"
          onClick={() => setIsMoneysolSkipModalOpen(true)}
        />
      </div>

      {isMoneysolSkipModalOpen && (
        <MoneytreeSkipModal
          isOpen={isMoneysolSkipModalOpen}
          onClose={() => {
            setIsMoneysolSkipModalOpen(false)
          }}
        />
      )}
    </MarginContainer>
  )
}

export default MoneytreeConnection
