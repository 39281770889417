import React, { FC, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Switch, Route, useLocation, Redirect } from 'react-router-dom'
import PolicyLink from '@/components/v2/atoms/PolicyLink'
import FloatingFooter from '@/components/v2/molecules/FloatingFooter'
import Header from '@/components/v2/organisms/Header'
import Login from '@/templates/v2/Login'
import Terms from '@/templates/v2/Terms'
import Policy from '@/templates/v2/Policy'
import Account from '@/layouts/v2/Account'
import Auth from '@/layouts/v2/Auth'
import Dashboard from '@/layouts/v2/Dashboard'
import Plan from '@/layouts/v2/Plan'
import Simulation from '@/layouts/v2/Simulation'
import MyData from '@/layouts/v2/MyData'
import Registration from '@/layouts/v2/Registration'
import Setting from '@/layouts/v2/Setting'
import { RootState } from '@/store'
import Household from '@/templates/v2/plans/Household'
import MoneytreeConnection from '@/templates/v2/plans/MoneytreeConnection'
import MoneytreeRegistration from '@/templates/v2/plans/MoneytreeRegistration'
import DataConnection from '@/templates/v2/plans/DataConnection'
import MoneytreeDataConfirmation from '@/templates/v2/plans/MoneytreeDataConfirmation'
import AssetBalance from '@/templates/v2/plans/AssetBalance'
import { getRootElement } from '@/utils/element'
import LocationEffect from '../LocationEffect'
import SeminarParticipantNew from '@/templates/v2/seminarParticipants/New'
import SeminarParticipantConfirm from '@/templates/v2/seminarParticipants/Confirm'
import SeminarParticipantCompleted from '@/templates/v2/seminarParticipants/Completed'

interface UserV2Props {
  canDisplayPolicyLink: boolean
}

const UserV2: FC<UserV2Props> = ({ canDisplayPolicyLink }) => {
  const locaiton = useLocation()
  const { isLogin } = useSelector((state: RootState) => state.accounts)

  useEffect(() => {
    const rootElement = getRootElement()
    rootElement?.scrollTo(0, 0)
  }, [locaiton.pathname])

  const canDisplayFloatingFotter = useMemo(() => isLogin && locaiton.pathname !== '/v2/simulations/new', [isLogin, locaiton.pathname])

  const policyLinkPositionText = useMemo(() => canDisplayFloatingFotter ? 'bottom-[60px]' : 'bottom-[0px]', [canDisplayFloatingFotter])

  return (
    <div className="tailwind">
      <div className="font-default">
        <Header />
        <div className="pb-[250px]">
          <Switch>
            <Redirect exact path="/" to="/v2/" />
            <Redirect
              exact
              path="/settings/accountDeactivate"
              to="/v2/settings/accountDeactivate"
            />

            <Route exact path="/v2/" component={Login} />
            <Route path="/v2/terms" component={Terms} />
            <Route path="/v2/policy" component={Policy} />
            <Route path="/v2/accounts" component={Account} />
            <Route path="/accounts" component={Account} />
            <Route
              exact
              path="/seminarParticipants/:unique_id/new"
              component={SeminarParticipantNew}
            />
            <Route
              exact
              path="/seminarParticipants/:unique_id/confirm"
              component={SeminarParticipantConfirm}
            />
            <Route
              exact
              path="/seminarParticipants/:unique_id/completed"
              component={SeminarParticipantCompleted}
            />
            <Auth>
              <LocationEffect />
              <Route path="/v2/dashboards" component={Dashboard} />
              <Route path="/v2/plans" component={Plan} />
              <Route path="/v2/simulations" component={Simulation} />
              <Route path="/v2/myData" component={MyData} />
              <Route path="/v2/registration" component={Registration} />
              <Route path="/v2/settings" component={Setting} />

              <Route exact path="/plans/household" component={Household} />
              <Route
                exact
                path="/plans/moneytreeRegistration"
                component={MoneytreeRegistration}
              />
              <Route
                exact
                path="/plans/moneytreeConnection"
                component={MoneytreeConnection}
              />
              <Route exact path="/plans/dataConnection" component={DataConnection} />
              <Route
                exact
                path="/plans/moneytreeDataConfirmation"
                component={MoneytreeDataConfirmation}
              />
              <Route exact path="/plans/assetBalance" component={AssetBalance} />
            </Auth>
          </Switch>
        </div>

        {canDisplayPolicyLink && <PolicyLink className={policyLinkPositionText} />}
        {canDisplayFloatingFotter && <FloatingFooter />}
      </div>
    </div>
  )
}

export default UserV2
