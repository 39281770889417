import {
  isUndefined as _isUndefined,
  isNull as _isNull,
  includes as _includes,
  find as _find,
  findKey as _findKey,
  reduce as _reduce,
  uniqBy as _uniqBy,
  times as _times,
} from 'lodash'
import {
  THIS_YEAR,
  defaultToEmptyString,
  convertLocaleString,
  addMomentDate,
  castNumber,
  castNumberWithoutOperator,
  compareDiffMomentDate,
} from '../commonsModelFunc'
import { InputPlanTuition, InputPlanTuitionCommonItem } from './inputPlanTuitionsModel'

// 教育方針タイプ
export const EDUCATIONAL_POLICY_TYPE = {
  notHang: 0,
  notReallyHang: 1,
  averageHang: 2,
  averageOrMoreHang: 3,
  thoroughlyHang: 4,
  useCreatedValues: 5,
}

// 年齢種別
export const AGE_TYPE = {
  toddler: 0,
  kindergarten: 1,
  primarySchool: 2,
  juniorHighSchool: 3,
  highSchool: 4,
  university: 5,
  graduateSchool: 6,
}

// 学校種別
export const SCHOOL_TYPE = {
  public: 0,
  private: 1,
  privateLiberalArts: 2,
  privateSciences: 3,
}

// 学校キー
export const SCHOOL_KEY = {
  toddler: 'toddler',
  kindergarten: 'kindergarten',
  primarySchool: 'primary_school',
  juniorHighSchool: 'junior_high_school',
  highSchool: 'high_school',
  university: 'university',
  graduateSchool: 'graduate_school',
}

// 学校年数
export const SCHOOL_YEAR = {
  toddler: 4,
  kindergarten: 3,
  primarySchool: 6,
  juniorHighSchool: 3,
  highSchool: 3,
  university: 4,
  graduateSchool: 2,
}

// 年齢
export const SCHOOL_AGE = {
  toddler0: 0,
  toddler1: 1,
  toddler2: 2,
  toddler3: 3,
  kindergarten1: 4,
  kindergarten2: 5,
  kindergarten3: 6,
  primarySchool1: 7,
  primarySchool2: 8,
  primarySchool3: 9,
  primarySchool4: 10,
  primarySchool5: 11,
  primarySchool6: 12,
  juniorHighSchool1: 13,
  juniorHighSchool2: 14,
  juniorHighSchool3: 15,
  highSchool1: 16,
  highSchool2: 17,
  highSchool3: 18,
  university1: 19,
  university2: 20,
  university3: 21,
  university4: 22,
  graduateSchool1: 23,
  graduateSchool2: 24,
}

// 学校種別テンプレート
export const SCHOOL_TYPE_TEMPLATES: { [key: string]: any } = {
  notHang: {
    kindergarten: SCHOOL_TYPE.public,
    primarySchool: SCHOOL_TYPE.public,
    juniorHighSchool: SCHOOL_TYPE.public,
    highSchool: SCHOOL_TYPE.public,
    university: SCHOOL_TYPE.public,
  },
  notReallyHang: {
    kindergarten: SCHOOL_TYPE.public,
    primarySchool: SCHOOL_TYPE.public,
    juniorHighSchool: SCHOOL_TYPE.public,
    highSchool: SCHOOL_TYPE.public,
    university: SCHOOL_TYPE.public,
  },
  averageHang: {
    kindergarten: SCHOOL_TYPE.private,
    primarySchool: SCHOOL_TYPE.public,
    juniorHighSchool: SCHOOL_TYPE.public,
    highSchool: SCHOOL_TYPE.private,
    university: SCHOOL_TYPE.privateLiberalArts,
  },
  averageOrMoreHang: {
    kindergarten: SCHOOL_TYPE.private,
    primarySchool: SCHOOL_TYPE.public,
    juniorHighSchool: SCHOOL_TYPE.private,
    highSchool: SCHOOL_TYPE.private,
    university: SCHOOL_TYPE.privateSciences,
  },
  thoroughlyHang: {
    kindergarten: SCHOOL_TYPE.private,
    primarySchool: SCHOOL_TYPE.private,
    juniorHighSchool: SCHOOL_TYPE.private,
    highSchool: SCHOOL_TYPE.private,
    university: SCHOOL_TYPE.privateSciences,
  },
}

// 学費テンプレート
export const TUITION_TEMPLATES: { [key: string]: any } = {
  notHang: {
    firstToddler: 0,
    firstOffToddler: 0,
    nextToddler: 0,
    nextOffToddler: 0,
    totalToddler: 0,
    firstKindergarten: 6,
    firstOffKindergarten: 11,
    nextKindergarten: 6,
    nextOffKindergarten: 11,
    totalKindergarten: 51,
    firstPrimarySchool: 6,
    firstOffPrimarySchool: 26,
    nextPrimarySchool: 6,
    nextOffPrimarySchool: 26,
    totalPrimarySchool: 192,
    firstJuniorHighSchool: 13,
    firstOffJuniorHighSchool: 34,
    nextJuniorHighSchool: 13,
    nextOffJuniorHighSchool: 34,
    totalJuniorHighSchool: 141,
    firstHighSchool: 28,
    firstOffHighSchool: 17,
    nextHighSchool: 28,
    nextOffHighSchool: 17,
    totalHighSchool: 135,
    firstUniversity: 0,
    firstOffUniversity: 0,
    nextUniversity: 0,
    nextOffUniversity: 0,
    totalUniversity: 0,
    firstGraduateSchool: 0,
    firstOffGraduateSchool: 0,
    nextGraduateSchool: 0,
    nextOffGraduateSchool: 0,
    totalGraduateSchool: 0,
    total: 519,
  },
  notReallyHang: {
    firstToddler: 38,
    firstOffToddler: 18,
    nextToddler: 38,
    nextOffToddler: 18,
    totalToddler: 224,
    firstKindergarten: 6,
    firstOffKindergarten: 11,
    nextKindergarten: 6,
    nextOffKindergarten: 11,
    totalKindergarten: 51,
    firstPrimarySchool: 6,
    firstOffPrimarySchool: 26,
    nextPrimarySchool: 6,
    nextOffPrimarySchool: 26,
    totalPrimarySchool: 192,
    firstJuniorHighSchool: 13,
    firstOffJuniorHighSchool: 34,
    nextJuniorHighSchool: 13,
    nextOffJuniorHighSchool: 34,
    totalJuniorHighSchool: 141,
    firstHighSchool: 28,
    firstOffHighSchool: 17,
    nextHighSchool: 28,
    nextOffHighSchool: 17,
    totalHighSchool: 135,
    firstUniversity: 82,
    firstOffUniversity: 93,
    nextUniversity: 52,
    nextOffUniversity: 58,
    totalUniversity: 505,
    firstGraduateSchool: 0,
    firstOffGraduateSchool: 0,
    nextGraduateSchool: 0,
    nextOffGraduateSchool: 0,
    totalGraduateSchool: 0,
    total: 1248,
  },
  averageHang: {
    firstToddler: 38,
    firstOffToddler: 18,
    nextToddler: 38,
    nextOffToddler: 18,
    totalToddler: 224,
    firstKindergarten: 17,
    firstOffKindergarten: 16,
    nextKindergarten: 10,
    nextOffKindergarten: 16,
    totalKindergarten: 85,
    firstPrimarySchool: 6,
    firstOffPrimarySchool: 26,
    nextPrimarySchool: 6,
    nextOffPrimarySchool: 26,
    totalPrimarySchool: 192,
    firstJuniorHighSchool: 13,
    firstOffJuniorHighSchool: 34,
    nextJuniorHighSchool: 13,
    nextOffJuniorHighSchool: 34,
    totalJuniorHighSchool: 141,
    firstHighSchool: 93,
    firstOffHighSchool: 29,
    nextHighSchool: 76,
    nextOffHighSchool: 29,
    totalHighSchool: 332,
    firstUniversity: 124,
    firstOffUniversity: 95,
    nextUniversity: 93,
    nextOffUniversity: 59,
    totalUniversity: 675,
    firstGraduateSchool: 0,
    firstOffGraduateSchool: 0,
    nextGraduateSchool: 0,
    nextOffGraduateSchool: 0,
    totalGraduateSchool: 0,
    total: 1649,
  },
  averageOrMoreHang: {
    firstToddler: 38,
    firstOffToddler: 18,
    nextToddler: 38,
    nextOffToddler: 18,
    totalToddler: 224,
    firstKindergarten: 17,
    firstOffKindergarten: 16,
    nextKindergarten: 10,
    nextOffKindergarten: 16,
    totalKindergarten: 85,
    firstPrimarySchool: 6,
    firstOffPrimarySchool: 26,
    nextPrimarySchool: 6,
    nextOffPrimarySchool: 26,
    totalPrimarySchool: 192,
    firstJuniorHighSchool: 120,
    firstOffJuniorHighSchool: 33,
    nextJuniorHighSchool: 100,
    nextOffJuniorHighSchool: 33,
    totalJuniorHighSchool: 419,
    firstHighSchool: 93,
    firstOffHighSchool: 29,
    nextHighSchool: 76,
    nextOffHighSchool: 29,
    totalHighSchool: 332,
    firstUniversity: 165,
    firstOffUniversity: 95,
    nextUniversity: 134,
    nextOffUniversity: 59,
    totalUniversity: 839,
    firstGraduateSchool: 0,
    firstOffGraduateSchool: 0,
    nextGraduateSchool: 0,
    nextOffGraduateSchool: 0,
    totalGraduateSchool: 0,
    total: 2091,
  },
  thoroughlyHang: {
    firstToddler: 38,
    firstOffToddler: 18,
    nextToddler: 38,
    nextOffToddler: 18,
    totalToddler: 224,
    firstKindergarten: 17,
    firstOffKindergarten: 16,
    nextKindergarten: 10,
    nextOffKindergarten: 16,
    totalKindergarten: 85,
    firstPrimarySchool: 108,
    firstOffPrimarySchool: 66,
    nextPrimarySchool: 87,
    nextOffPrimarySchool: 66,
    totalPrimarySchool: 939,
    firstJuniorHighSchool: 120,
    firstOffJuniorHighSchool: 33,
    nextJuniorHighSchool: 100,
    nextOffJuniorHighSchool: 33,
    totalJuniorHighSchool: 419,
    firstHighSchool: 93,
    firstOffHighSchool: 29,
    nextHighSchool: 76,
    nextOffHighSchool: 29,
    totalHighSchool: 332,
    firstUniversity: 165,
    firstOffUniversity: 95,
    nextUniversity: 134,
    nextOffUniversity: 59,
    totalUniversity: 839,
    firstGraduateSchool: 134,
    firstOffGraduateSchool: 59,
    nextGraduateSchool: 134,
    nextOffGraduateSchool: 59,
    totalGraduateSchool: 386,
    total: 3224,
  },
}

import {
  EDIT_EDUCATIONAL_POLICY_TYPE_SELECT_ITEMS,
  EDUCATIONAL_POLICY_TYPE_SELECT_ITEMS,
  NORMAL_SCHOOL_TYPE_SELECT_ITEMS,
  UNIVERSITY_SCHOOL_TYPE_SELECT_ITEMS,
} from '../../constants/formItem/inputs/inputPlanTuitions'

export const isChildbirthWithinRange = (childbirth: string | null, addNumber: number) => {
  if (_isNull(childbirth)) return true

  const calcYear = addMomentDate(childbirth, addNumber, 'years', 'YYYY')
  return compareDiffMomentDate(calcYear, THIS_YEAR, 'years') >= 0
}

export const sumTotalTuition = (
  values: Partial<InputPlanTuitionCommonItem> | undefined,
) => {
  if (_isUndefined(values)) return

  const rejectKeys = [
    'child_id',
    'kindergarten_school_type',
    'primary_school_type',
    'junior_high_school_type',
    'high_school_type',
    'university_school_type',
  ]
  const totalTuition = _reduce(
    values,
    (result, value, key) => {
      if (_includes(rejectKeys, key)) return result
      return (result += castNumberWithoutOperator(value))
    },
    0,
  )

  return convertLocaleString(totalTuition)
}

const selectSchoolAge = (schoolKey: string, index: number) => {
  const orderNum = index + 1
  let standardAge = 0

  switch (schoolKey) {
    case SCHOOL_KEY.toddler:
      if (orderNum === 1) return (standardAge = SCHOOL_AGE.toddler0)
      if (orderNum === 2) return (standardAge = SCHOOL_AGE.toddler1)
      if (orderNum === 3) return (standardAge = SCHOOL_AGE.toddler2)
      if (orderNum === 4) return (standardAge = SCHOOL_AGE.toddler3)
    case SCHOOL_KEY.kindergarten:
      if (orderNum === 1) return (standardAge = SCHOOL_AGE.kindergarten1)
      if (orderNum === 2) return (standardAge = SCHOOL_AGE.kindergarten2)
      if (orderNum === 3) return (standardAge = SCHOOL_AGE.kindergarten3)
      break
    case SCHOOL_KEY.primarySchool:
      if (orderNum === 1) return (standardAge = SCHOOL_AGE.primarySchool1)
      if (orderNum === 2) return (standardAge = SCHOOL_AGE.primarySchool2)
      if (orderNum === 3) return (standardAge = SCHOOL_AGE.primarySchool3)
      if (orderNum === 4) return (standardAge = SCHOOL_AGE.primarySchool4)
      if (orderNum === 5) return (standardAge = SCHOOL_AGE.primarySchool5)
      if (orderNum === 6) return (standardAge = SCHOOL_AGE.primarySchool6)
      standardAge = SCHOOL_AGE.primarySchool6
      break
    case SCHOOL_KEY.juniorHighSchool:
      if (orderNum === 1) return (standardAge = SCHOOL_AGE.juniorHighSchool1)
      if (orderNum === 2) return (standardAge = SCHOOL_AGE.juniorHighSchool2)
      if (orderNum === 3) return (standardAge = SCHOOL_AGE.juniorHighSchool3)
      break
    case SCHOOL_KEY.highSchool:
      standardAge = SCHOOL_AGE.highSchool3
      if (orderNum === 1) return (standardAge = SCHOOL_AGE.highSchool1)
      if (orderNum === 2) return (standardAge = SCHOOL_AGE.highSchool2)
      if (orderNum === 3) return (standardAge = SCHOOL_AGE.highSchool3)
      break
    case SCHOOL_KEY.university:
      if (orderNum === 1) return (standardAge = SCHOOL_AGE.university1)
      if (orderNum === 2) return (standardAge = SCHOOL_AGE.university2)
      if (orderNum === 3) return (standardAge = SCHOOL_AGE.university3)
      if (orderNum === 4) return (standardAge = SCHOOL_AGE.university4)
      break
    case SCHOOL_KEY.graduateSchool:
      if (orderNum === 1) return (standardAge = SCHOOL_AGE.graduateSchool1)
      if (orderNum === 2) return (standardAge = SCHOOL_AGE.graduateSchool2)
      break
  }

  return castNumber(standardAge)
}

export const buildSendTuition = (
  schoolKey: string,
  year: number,
  currentAge: number,
  item: (Partial<InputPlanTuitionCommonItem> & { [key: string]: any }) | undefined,
  isEarlyBorn?: boolean | null | undefined,
) => {
  const inputPlanTuition: Partial<InputPlanTuition> & {
    [key: string]: number | null
  } = {}

  _times(year, (index) => {
    const isUnderCurrentAge = currentAge > selectSchoolAge(schoolKey, index)
    const isEarlyBornCondition =
      schoolKey == SCHOOL_KEY.toddler && index == 3 && isEarlyBorn

    inputPlanTuition[`tuition_${index + 1}_manyen`] =
      isEarlyBornCondition || isUnderCurrentAge
        ? 0
        : item?.[`${schoolKey}_tuition_${index + 1}_manyen`]
    inputPlanTuition[`off_campus_expenses_${index + 1}_manyen`] =
      isEarlyBornCondition || isUnderCurrentAge
        ? 0
        : item?.[`off_${schoolKey}_tuition_${index + 1}_manyen`]
  })

  return inputPlanTuition
}

export const buildReceiveTuition = (
  schoolKey: string,
  year: number,
  item: InputPlanTuition & { [key: string]: any },
) => {
  const inputPlanTuition: Partial<InputPlanTuitionCommonItem> & {
    [key: string]: number | null
  } = {}

  _times(year, (index) => {
    inputPlanTuition[`${schoolKey}_tuition_${index + 1}_manyen`] = !_isUndefined(item)
      ? item[`tuition_${index + 1}_manyen`]
      : 0
    inputPlanTuition[`off_${schoolKey}_tuition_${index + 1}_manyen`] = !_isUndefined(item)
      ? item[`off_campus_expenses_${index + 1}_manyen`]
      : 0
  })

  return inputPlanTuition
}

export const convertEducationalPolicyTypeName = (
  educationalPolicyType: number | undefined,
) => {
  const educationalPolicyTypeLabel = _find(
    EDIT_EDUCATIONAL_POLICY_TYPE_SELECT_ITEMS,
    (item) => item.value === educationalPolicyType,
  )?.label

  return defaultToEmptyString(educationalPolicyTypeLabel)
}

export const convertEducationalPolicyTypeKey = (
  educationalPolicyType: number | undefined,
) => {
  const educationalPolicyTypeKey = _findKey(
    EDUCATIONAL_POLICY_TYPE,
    (item) => item === educationalPolicyType,
  )

  return defaultToEmptyString(educationalPolicyTypeKey)
}

export const convertSchoolType = (schoolType: number | null) => {
  const schoolTypeLabel = _find(
    _uniqBy(
      [...NORMAL_SCHOOL_TYPE_SELECT_ITEMS, ...UNIVERSITY_SCHOOL_TYPE_SELECT_ITEMS],
      'value',
    ),
    (item) => item.value === schoolType,
  )?.label

  return defaultToEmptyString(schoolTypeLabel)
}

export const convertUniversitySchoolType = (schoolType: number | null) => {
  const schoolTypeLabel = _find(
    UNIVERSITY_SCHOOL_TYPE_SELECT_ITEMS,
    (item) => item.value === schoolType,
  )?.label

  return defaultToEmptyString(schoolTypeLabel)
}

export const sumTotalToddlerTuition = (values: Partial<InputPlanTuitionCommonItem>) => {
  return (
    values!.toddler_tuition_1_manyen! +
    values!.toddler_tuition_2_manyen! +
    values!.toddler_tuition_3_manyen! +
    values!.toddler_tuition_4_manyen! +
    values!.off_toddler_tuition_1_manyen! +
    values!.off_toddler_tuition_2_manyen! +
    values!.off_toddler_tuition_3_manyen! +
    values!.off_toddler_tuition_4_manyen!
  )
}

export const sumTotalKindergartenTuition = (
  values: Partial<InputPlanTuitionCommonItem>,
) => {
  return (
    values!.kindergarten_tuition_1_manyen! +
    values!.kindergarten_tuition_2_manyen! +
    values!.kindergarten_tuition_3_manyen! +
    values!.off_kindergarten_tuition_1_manyen! +
    values!.off_kindergarten_tuition_2_manyen! +
    values!.off_kindergarten_tuition_3_manyen!
  )
}

export const sumTotalPrimarySchoolTuition = (
  values: Partial<InputPlanTuitionCommonItem>,
) => {
  return (
    values!.primary_school_tuition_1_manyen! +
    values!.primary_school_tuition_2_manyen! +
    values!.primary_school_tuition_3_manyen! +
    values!.primary_school_tuition_4_manyen! +
    values!.primary_school_tuition_5_manyen! +
    values!.primary_school_tuition_6_manyen! +
    values!.off_primary_school_tuition_1_manyen! +
    values!.off_primary_school_tuition_2_manyen! +
    values!.off_primary_school_tuition_3_manyen! +
    values!.off_primary_school_tuition_4_manyen! +
    values!.off_primary_school_tuition_5_manyen! +
    values!.off_primary_school_tuition_6_manyen!
  )
}

export const sumTotalJuniorHighSchoolTuition = (
  values: Partial<InputPlanTuitionCommonItem>,
) => {
  return (
    values!.junior_high_school_tuition_1_manyen! +
    values!.junior_high_school_tuition_2_manyen! +
    values!.junior_high_school_tuition_3_manyen! +
    values!.off_junior_high_school_tuition_1_manyen! +
    values!.off_junior_high_school_tuition_2_manyen! +
    values!.off_junior_high_school_tuition_3_manyen!
  )
}

export const sumTotalHighSchoolTuition = (
  values: Partial<InputPlanTuitionCommonItem>,
) => {
  return (
    values!.high_school_tuition_1_manyen! +
    values!.high_school_tuition_2_manyen! +
    values!.high_school_tuition_3_manyen! +
    values!.off_high_school_tuition_1_manyen! +
    values!.off_high_school_tuition_2_manyen! +
    values!.off_high_school_tuition_3_manyen!
  )
}

export const sumTotalUniversityTuition = (
  values: Partial<InputPlanTuitionCommonItem>,
) => {
  return (
    values!.university_tuition_1_manyen! +
    values!.university_tuition_2_manyen! +
    values!.university_tuition_3_manyen! +
    values!.university_tuition_4_manyen! +
    values!.off_university_tuition_1_manyen! +
    values!.off_university_tuition_2_manyen! +
    values!.off_university_tuition_3_manyen! +
    values!.off_university_tuition_4_manyen!
  )
}

export const sumTotalGraduateSchoolTuition = (
  values: Partial<InputPlanTuitionCommonItem>,
) => {
  return (
    values!.graduate_school_tuition_1_manyen! +
    values!.graduate_school_tuition_2_manyen! +
    values!.off_graduate_school_tuition_1_manyen! +
    values!.off_graduate_school_tuition_2_manyen!
  )
}

export const sumAllTotalTuition = (values: Partial<InputPlanTuitionCommonItem>) => {
  return (
    sumTotalToddlerTuition(values) +
    sumTotalKindergartenTuition(values) +
    sumTotalPrimarySchoolTuition(values) +
    sumTotalJuniorHighSchoolTuition(values) +
    sumTotalHighSchoolTuition(values) +
    sumTotalUniversityTuition(values) +
    sumTotalGraduateSchoolTuition(values)
  )
}
