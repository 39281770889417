import ApiClient from '@/api/apiClient'
import {
  FpAccountResponse,
  FpAccountShowInvitedResponse,
  FpAccountLoginResponse,
  FpAccountLoginValues,
  FpAccountInvitedValues,
  FpAccountPasswordUpdateValues,
  FpAccountPasswordResetValues,
  FpAccountUpdateAuthorityValues,
} from '@/models/admins/fpAccountsModel'

export const getFpAccount = async () => {
  return await ApiClient.get(
    '/admin_api/v1/app/fp_accounts/show',
    (response: FpAccountResponse) => response,
    undefined,
    true,
  )
}

export const getFpAccountShowInvited = async () => {
  return await ApiClient.get(
    '/fp_accounts/show_invited',
    (response: FpAccountShowInvitedResponse) => response,
  )
}

export const postFpAccountLogin = async (values: FpAccountLoginValues) => {
  return await ApiClient.post(
    '/fp_accounts/login',
    (response: FpAccountLoginResponse) => response,
    values,
  )
}

export const postFpAccountLogout = async () => {
  return await ApiClient.post('/fp_accounts/logout', () => {})
}

export const postFpAccountPasswordReset = async (
  values: FpAccountPasswordResetValues,
) => {
  return await ApiClient.post('/fp_accounts/password_reset', () => {}, values)
}

export const patchFpAccountPasswordSetup = async (
  values: FpAccountPasswordUpdateValues,
) => {
  return await ApiClient.patch('/fp_accounts/account_password_setup', () => {}, values)
}

export const patchFpAccountUpdateWhole = async (values: FpAccountInvitedValues) => {
  return await ApiClient.patch('/fp_accounts/update_whole', () => {}, values)
}

export const patchFpAccountUpdateAuthority = async (values: FpAccountUpdateAuthorityValues) => {
  return await ApiClient.patch('/fp_accounts/update_authority', () => {}, values)
}
