import { API_FLAG, isNotEmptyValue } from '@/models/commonsModelFunc'
import { InputHousingSelfOwned } from '@/models/inputs/inputHousingSelfOwnedModel'

export const isSplitedLoan = (
  inputSelfOwnedAttributes: Partial<InputHousingSelfOwned> | undefined | null,
) => {
  if (
    !isNotEmptyValue(inputSelfOwnedAttributes?.loan_type1) ||
    !isNotEmptyValue(inputSelfOwnedAttributes?.loan_type2)
  )
    return false

  return (
    inputSelfOwnedAttributes!.loan_type1 == API_FLAG.on &&
    inputSelfOwnedAttributes!.loan_type2 == API_FLAG.on
  )
}
