import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { sortBy as _sortBy } from 'lodash'
import {
  getLearningVideoCategories,
  getLearningVideoCategory,
  postLearningVideoCategory,
  patchLearningVideoCategory,
  deleteLearningVideoCategory,
} from '@/api/admins/learningVideoCategories'
import { unauthorizedAdminCheck } from '@/containers/admins/fpAccountsSlice'
import {
  LearningVideoCategoryResponse,
  LearningVideoCategory,
  LearningVideoCategoryCreateValues,
  LearningVideoCategoryUpdateValues,
} from '@/models/admins/learningVideoCategoriesModel'
import { ApiError } from '@/models/commonsModel'
import { AppThunk } from '@/store'

interface LearningVideoCategoryState {
  learningVideoCategories: LearningVideoCategory[]
  learningVideoCategory: LearningVideoCategory | null
  isLoading: boolean
}

const initialState: LearningVideoCategoryState = {
  learningVideoCategories: [],
  learningVideoCategory: null,
  isLoading: false,
}

const learningVideoCategoriesSlice = createSlice({
  name: 'learningVideoCategories',
  initialState,
  reducers: {
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    getLearningVideoCategoriesSuccess: (
      state,
      action: PayloadAction<LearningVideoCategoryResponse[]>,
    ) => {
      state.learningVideoCategories = _sortBy(action.payload, 'order')
      state.isLoading = false
      return state
    },
    getLearningVideoCategorySuccess: (
      state,
      action: PayloadAction<LearningVideoCategoryResponse>,
    ) => {
      state.learningVideoCategory = action.payload
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
  },
})

export const {
  requestStart,
  requestSuccess,
  getLearningVideoCategoriesSuccess,
  getLearningVideoCategorySuccess,
  requestFailure,
} = learningVideoCategoriesSlice.actions
export const learningVideoCategoriesReducer = learningVideoCategoriesSlice.reducer

export const fetchLearningVideoCategories = (): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  getLearningVideoCategories()
    .then((response) => dispatch(getLearningVideoCategoriesSuccess(response)))
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedAdminCheck(error))
    })
}

export const fetchLearningVideoCategory = (id: number): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  getLearningVideoCategory(id)
    .then((response) => dispatch(getLearningVideoCategorySuccess(response)))
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedAdminCheck(error))
    })
}

export const createLearningVideoCategory = (
  values: LearningVideoCategoryCreateValues,
  callback: () => void,
  resolve: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postLearningVideoCategory(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedAdminCheck(error, resolve))
    })
}

export const updateLearningVideoCategory = (
  id: number,
  values: LearningVideoCategoryUpdateValues,
  callback: () => void,
  resolve: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchLearningVideoCategory(id, values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedAdminCheck(error, resolve))
    })
}

export const destroyLearningVideoCategory = (
  id: number,
  callback: () => void,
  resolve?: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  deleteLearningVideoCategory(id)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedAdminCheck(error, resolve))
    })
}
