import React, { FC, useState, useContext, useEffect, useMemo } from 'react'
import Typography from '@/components/v2/atoms/Typography'
import SimulationAccordion from '@/templates/v2/simulations/_simulationAccordion'
import SimulationNewButton from '@/templates/v2/simulations/_simulationNewButton'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/store'
import Button from '@/components/v2/atoms/Button'
import FutureYearlyLivingCostModalForm from '@/templates/v2/simulations/_futureYearlyLivingCostModalForm'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import { destroyFutureYearlyLivingCostV2 } from '@/containers/futures/futureYearlyLivingCostsSlice'
import { fetchAccountFutureItems } from '@/containers/accountsSlice'
import { sortBy as _sortBy, find as _find } from 'lodash'
import { SimulationDetailEditContext } from '@/models/futures/futureBasesModelFunc'
import SimulationItemAccordion from '@/templates/v2/simulations/_simulationItemAccordion'

interface FutureYearlyLivingCostFormProps {
  /** 選択シミュレーションID */
  selectedSimulationId: number | undefined
  /** 選択メソッド */
  handleSelect: (item: any) => void
  /* FutureItemsを参照するID */
  futureBaseId?: number
  /* 初期化すべきかどうか */
  shouldInitialize?: boolean
  /* 編集を確認したか */
  confirmEdit?: boolean
  /* 編集確認モーダルを展開するメソッド */
  openEditConfirmModal?: () => void
}

interface FutureYearlyLivingCostTableProps {
  /** アイテム */
  item: any
  /** td要素のクラス */
  tdClassName?: string
}

export const FutureYearlyLivingCostTable: FC<FutureYearlyLivingCostTableProps> = ({
  item,
}) => {
  return (
    <div>
      <Typography className="border-b-[0.5px] pb-[5px] md:mb-[10px]">家電</Typography>
      <table className="w-[100%] mb-[20px] last:mb-0 text-[14px] bg-white">
        <tbody>
          <tr className="hidden  h-[45px] odd:bg-black-100">
            <td className="w-full  px-[16px]">
              <Typography></Typography>
            </td>
            <td className="hidden  w-[25%] px-[16px] ">
              <Typography isBold>次回買い替え時期</Typography>
            </td>
            <td className="hidden  w-[25%] px-[16px] ">
              <Typography isBold>買い替え頻度</Typography>
            </td>
            <td className="hidden  w-[25%] px-[16px] ">
              <Typography isBold>1回あたりの費用</Typography>
            </td>
          </tr>
          {item.future_yearly_living_cost_electronics &&
            _sortBy(item.future_yearly_living_cost_electronics, 'id')?.map(
              (electronic: any, index: number) =>
                electronic.is_active && (
                  <tr
                    className="h-[45px] odd:bg-black-100 border-b-[0.5px] "
                    key={`electronics_${electronic.id}_${index}`}
                  >
                    <td className="w-full block mb-[10px] mt-[10px] ml-[10px]">
                      <Typography className="md:font-bold">
                        {electronic.display_name}
                      </Typography>
                    </td>
                    <td className="w-full  block   my-[10px] ml-[10px]">
                      <div className="flex">
                        <Typography isBold className="block  w-[50%]">
                          次回買い替え時期
                        </Typography>
                        <Typography>
                          ご本人が{electronic.next_purchase_at_age}歳の時
                        </Typography>
                      </div>
                    </td>
                    <td className="w-full  block   my-[10px] ml-[10px]">
                      <div className="flex">
                        <Typography isBold className="block  w-[50%]">
                          買い替え頻度
                        </Typography>
                        <Typography>{electronic.occurance_in_years}年ごと</Typography>
                      </div>
                    </td>
                    <td className="w-full  block   mt-[10px] mb-[10px]  ml-[10px]">
                      <div className="flex">
                        <Typography isBold className="block  w-[50%]">
                          1回あたりの費用
                        </Typography>
                        <Typography>
                          {electronic.single_cost?.toLocaleString()}円
                        </Typography>
                      </div>
                    </td>
                  </tr>
                ),
            )}
        </tbody>
      </table>

      <Typography styleType="underlinedHeaderSmall">冠婚葬祭</Typography>
      <table className="w-[100%] mb-[0px] last:mb-0 text-[14px] bg-white">
        <tbody>
          <tr className="h-[45px] odd:bg-black-100">
            <td className="w-[50%] px-[16px] md:px-[30px]">
              <Typography isBold>ご本人の年齢</Typography>
            </td>
            <td className="w-[50%] px-[16px] md:px-[30px]">
              <Typography className="min-w-[max-content]" isBold>
                年間の冠婚葬祭費用
              </Typography>
            </td>
          </tr>

          {item.future_yearly_living_cost_ceremonies?.map(
            (ceremony: any, index: number) => (
              <tr
                className="h-[45px] odd:bg-black-100"
                key={`ceremonies_${ceremony.id}_${index}`}
              >
                <td className="w-[50%] px-[16px] md:px-[30px]">
                  <Typography>
                    {ceremony.affected_start_at_age}歳から{ceremony.affected_end_at_age}歳
                  </Typography>
                </td>
                <td className="w-[50%] px-[16px] md:px-[30px]">
                  <Typography>{ceremony.yearly_cost?.toLocaleString()}円</Typography>
                </td>
              </tr>
            ),
          )}
        </tbody>
      </table>

      <Typography styleType="underlinedHeaderSmall">帰省</Typography>
      <table className="w-[100%] mb-[20px] last:mb-0 text-[12px] md:text-[14px] bg-white">
        <tbody>
          {item.future_yearly_living_cost_home_comings?.map(
            (home_coming: any, index: number) => (
              <React.Fragment key={`home_comings_${home_coming.id}_${index}`}>
                <tr className="h-[45px] odd:bg-black-100">
                  <td className="w-[50%] px-[16px] md:px-[30px]">
                    <Typography isBold>費用</Typography>
                  </td>
                  <td className="w-[50%] px-[16px] md:px-[30px]">
                    <Typography>{home_coming.single_cost?.toLocaleString()}円</Typography>
                  </td>
                </tr>
                <tr className="h-[45px] odd:bg-black-100">
                  <td className="w-[50%] px-[16px] md:px-[30px]">
                    <Typography isBold>頻度</Typography>
                  </td>
                  <td className="w-[50%] px-[16px] md:px-[30px]">
                    <Typography>
                      {home_coming.occurance_per_occurance_term}年に
                      {home_coming.occurance_term_in_years}回
                    </Typography>
                  </td>
                </tr>
                <tr className="h-[45px] odd:bg-black-100">
                  <td className="w-[50%] px-[16px] md:px-[30px]">
                    <Typography isBold>適用頻度</Typography>
                  </td>
                  <td className="w-[50%] px-[16px] md:px-[30px]">
                    <Typography>
                      {home_coming.affected_start_at_age}歳から
                      {home_coming.affected_end_at_age}
                      歳まで
                    </Typography>
                  </td>
                </tr>
              </React.Fragment>
            ),
          )}
        </tbody>
      </table>

      <Typography styleType="underlinedHeaderSmall">旅行</Typography>
      <table className="w-[100%] mb-[20px] last:mb-0 text-[12px] md:text-[14px] bg-white">
        <tbody>
          {item.future_yearly_living_cost_travels?.map((travel: any, index: number) => (
            <React.Fragment key={`home_travels_${travel.id}_${index}`}>
              <tr className="h-[45px] odd:bg-black-100">
                <td className="w-[50%] px-[16px] md:px-[30px]">
                  <Typography isBold>費用</Typography>
                </td>
                <td className="w-[50%] px-[16px] md:px-[30px]">
                  <Typography>{travel.single_cost?.toLocaleString()}円</Typography>
                </td>
              </tr>
              <tr className="h-[45px] odd:bg-black-100">
                <td className="w-[50%] px-[16px] md:px-[30px]">
                  <Typography isBold>頻度</Typography>
                </td>
                <td className="w-[50%] px-[16px] md:px-[30px]">
                  <Typography>
                    {travel.occurance_per_occurance_term}年に
                    {travel.occurance_term_in_years}回
                  </Typography>
                </td>
              </tr>
              <tr className="h-[45px] odd:bg-black-100">
                <td className="w-[50%] px-[16px] md:px-[30px]">
                  <Typography isBold>適用頻度</Typography>
                </td>
                <td className="w-[50%] px-[16px] md:px-[30px]">
                  <Typography>
                    {travel.affected_start_at_age}歳から{travel.affected_end_at_age}
                    歳まで
                  </Typography>
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  )
}

const FutureYearlyLivingCostForm: FC<FutureYearlyLivingCostFormProps> = (props) => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [inflationId, setInflationId] = useState<number | null>(null)

  const futureYearlyLivingCostBases = useSelector(
    (state: RootState) => state.accounts.futureItems?.future_yearly_living_cost_bases,
  )

  const dispatch = useDispatch()
  const { showFlashMsg } = useFlashAlert()
  const { isManipulatedApproval } = useContext(SimulationDetailEditContext)
  const { selectedSimulationId, handleSelect } = props

  useEffect(() => {
    if (props.futureBaseId == null || !props.shouldInitialize) return
    const item = _find(futureYearlyLivingCostBases, {
      future_base_id: props.futureBaseId,
    })
    if (item != null) handleSelect(item)
  }, [props.shouldInitialize])

  const handleDestroyV2 = (item: any) => {
    const result = confirm('本当に削除してよろしいですか？')
    if (!result) return

    const destroyFunc = new Promise((resolve) => {
      dispatch(
        destroyFutureYearlyLivingCostV2(item.id, () => {
          resolve('')
          showFlashMsg('削除しました')
        }),
      )
    })

    Promise.all([destroyFunc]).then(() => {
      if (item.id === selectedSimulationId) handleSelect(item)
      dispatch(fetchAccountFutureItems(props.futureBaseId))
    })
  }

  const handleEdit = (item: any) => {
    setInflationId(item.id)
    setIsEdit(true)
    setIsOpenModal(true)
  }

  const sortedFutureYearlyLivingCostBases = useMemo(() => {
    if (props.futureBaseId == null || futureYearlyLivingCostBases == null)
      return futureYearlyLivingCostBases
    const eqFbId = (item: any) => item.future_base_id === props.futureBaseId
    return [
      ...futureYearlyLivingCostBases.filter((item) => eqFbId(item)),
      ...futureYearlyLivingCostBases.filter((item) => !eqFbId(item)),
    ]
  }, [futureYearlyLivingCostBases])

  const titleIndex = (index: number) => {
    if (
      props.futureBaseId == null ||
      props.confirmEdit == null ||
      futureYearlyLivingCostBases == null
    )
      return index + 1
    const eqFbId = (item: any) => item.future_base_id === props.futureBaseId
    return index + 1 - futureYearlyLivingCostBases.filter((item) => eqFbId(item)).length
  }

  const handleExecIfConfired = (exec: (item: any) => void, item: any) => {
    props.confirmEdit === false ? props.openEditConfirmModal?.() : exec(item)
  }

  return (
    <SimulationAccordion
      className="pb-[16px]"
      title="特別な支出・大きな支出を想定する"
      isSelected={!!selectedSimulationId}
      itemCount={sortedFutureYearlyLivingCostBases?.length}
    >
      <div className="flex flex-col gap-y-[12px] pt-[6px]">
        {sortedFutureYearlyLivingCostBases?.map((item: any, index: number) => {
          return (
            <SimulationItemAccordion
              key={`${item.id}_${index}`}
              title={
                props.futureBaseId === item.future_base_id && props.confirmEdit != null
                  ? '登録中の特別な支出・大きな支出設定'
                  : `特別な支出・大きな支出設定${titleIndex(index)}`
              }
              isSelected={item.id === selectedSimulationId}
              inputType="radio"
              onSelect={() => {
                handleExecIfConfired(handleSelect, item)
              }}
            >
              <div>
                <div className="flex justify-end mb-[5px]">
                  <Button
                    className="mr-[16px]"
                    isDisabled={!isManipulatedApproval}
                    onClick={(e) => {
                      handleExecIfConfired(handleEdit, item)
                      e.stopPropagation()
                    }}
                  >
                    <i className="ico edit text-[14px] text-black-700" />
                    <Typography className="pl-[5px] text-[14px] text-black-700">
                      編集
                    </Typography>
                  </Button>
                  <Button
                    isDisabled={!isManipulatedApproval}
                    onClick={(e) => {
                      handleExecIfConfired(handleDestroyV2, item)
                      e.stopPropagation()
                    }}
                  >
                    <Typography className="pl-[5px] text-[14px] text-black-700">
                      削除
                    </Typography>
                  </Button>
                </div>
                <FutureYearlyLivingCostTable item={item} />
              </div>
            </SimulationItemAccordion>
          )
        })}
      </div>

      <SimulationNewButton
        isDisabled={!isManipulatedApproval}
        onClick={() => {
          setInflationId(null)
          setIsEdit(false)
          setIsOpenModal(true)
        }}
      />

      {isOpenModal && (
        <FutureYearlyLivingCostModalForm
          isOpen={isOpenModal}
          isEdit={isEdit}
          onClose={() => setIsOpenModal(false)}
          id={inflationId}
          futureBaseId={props.futureBaseId}
        />
      )}
    </SimulationAccordion>
  )
}

export default FutureYearlyLivingCostForm
