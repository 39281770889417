import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { unauthorizedCheck } from './accountsSlice'
import { postGeneralStatistics } from '../api/generalStatistics'
import {
  GeneralStatisticResponse,
  GeneralStatistic,
  GeneralStatisticValues,
} from '../models/generalStatisticsModel'
import { AppThunk } from '../store'

interface GeneralStatisticState {
  generalStatistics: GeneralStatistic[]
  isLoading: boolean
}

const initialState: GeneralStatisticState = {
  generalStatistics: [],
  isLoading: false,
}

const generalStatisticsSlice = createSlice({
  name: 'generalStatistics',
  initialState,
  reducers: {
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    postGeneralStatisticsSuccess: (
      state,
      action: PayloadAction<GeneralStatisticResponse[]>,
    ) => {
      state.generalStatistics = action.payload
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
  },
})

export const {
  requestStart,
  postGeneralStatisticsSuccess,
  requestFailure,
} = generalStatisticsSlice.actions
export const generalStatisticsReducer = generalStatisticsSlice.reducer

export const createGeneralStatistics = (
  values: GeneralStatisticValues,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postGeneralStatistics(values)
    .then((response) => {
      dispatch(postGeneralStatisticsSuccess(response))
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}
