import { PaymentType } from './futureCarsModel'
import { defaultToEmptyString } from '../commonsModelFunc'
import { find as _find } from 'lodash'
import { PAYMENT_TYPE_BUTTON_ITEMS } from '@/constants/formItem/futures/futureCars'

// 支払い方法
export const PAYMENT_TYPE = {
  loan: 'loan',
  oneTime: 'one_time',
}

export const convertPaymentType = (value: PaymentType) => {
  const label = _find(PAYMENT_TYPE_BUTTON_ITEMS, (item) => item.value === value)?.label
  return defaultToEmptyString(label)
}
