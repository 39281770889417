import LocalStorage from '@/utils/localStorage'

const KEY = 'hasOpenedDashboard'

export const getStorageHasOpenedDashboard = () => {
  return LocalStorage.getItem(KEY)
}

export const setStorageHasOpenedDashboard = (value: boolean) => {
  return LocalStorage.setItem(KEY, value)
}

export const removeStorageHasOpenedDashboard = () => {
  LocalStorage.removeItem(KEY)
}
