import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  postInputYearlyLivingCost,
  patchInputYearlyLivingCost,
  deleteInputYearlyLivingCost,
  getInputYearlyLivingCostV2,
} from '@/api/inputs/inputYearlyLivingCosts'
import { unauthorizedCheck } from '@/containers/accountsSlice'
import { AppThunk } from '@/store'
import { sortBy as _sortBy } from 'lodash'

interface InputYearlyLivingCostState {
  input_yearly_living_cost_ceremonies: any[]
  input_yearly_living_cost_electronics: any[]
  input_yearly_living_cost_home_comings: any[]
  input_yearly_living_cost_travels: any[]
  isLoading: boolean
}

const initialState: InputYearlyLivingCostState = {
  input_yearly_living_cost_ceremonies: [],
  input_yearly_living_cost_electronics: [],
  input_yearly_living_cost_home_comings: [],
  input_yearly_living_cost_travels: [],
  isLoading: false,
}

const inputYearlyLivingCostsSlice = createSlice({
  name: 'inputYearlyLivingCosts',
  initialState,
  reducers: {
    setInputYearlyLivingCost: (state, action: PayloadAction<any>) => {
      state.input_yearly_living_cost_ceremonies = action.payload.ceremonies
      state.input_yearly_living_cost_electronics = _sortBy(
        action.payload.electronics,
        'id',
      )
      state.input_yearly_living_cost_home_comings = action.payload.homeComings
      state.input_yearly_living_cost_travels = action.payload.travels

      return state
    },
    setInputYearlyLivingCostV2: (state, action: PayloadAction<any>) => {
      state.input_yearly_living_cost_ceremonies =
        action.payload.input_yearly_living_cost_ceremonies
      state.input_yearly_living_cost_electronics = _sortBy(
        action.payload.input_yearly_living_cost_electronics,
        'id',
      )
      state.input_yearly_living_cost_home_comings =
        action.payload.input_yearly_living_cost_home_comings
      state.input_yearly_living_cost_travels =
        action.payload.input_yearly_living_cost_travels

      return state
    },
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    resetInputYearlyLivingCostsStore: (state) => {
      state = initialState
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
  },
})

export const {
  setInputYearlyLivingCost,
  setInputYearlyLivingCostV2,
  requestStart,
  resetInputYearlyLivingCostsStore,
  requestSuccess,
  requestFailure,
} = inputYearlyLivingCostsSlice.actions
export const inputYearlyLivingCostsReducer = inputYearlyLivingCostsSlice.reducer

export const createInputYearlyLivingCost = (
  values: any,
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postInputYearlyLivingCost(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const updateInputYearlyLivingCost = (
  values: any,
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchInputYearlyLivingCost(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const destroyInputYearlyLivingCost = (callback: () => void): AppThunk => async (
  dispatch,
) => {
  dispatch(requestStart())
  deleteInputYearlyLivingCost()
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const fetchInputYearlyLivingCostsV2 = (callback?: () => void): AppThunk => async (
  dispatch,
) => {
  dispatch(requestStart())
  getInputYearlyLivingCostV2()
    .then((response) => {
      dispatch(setInputYearlyLivingCostV2(response))
      dispatch(requestSuccess())
      callback && callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}
