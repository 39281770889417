import { isUndefined as _isUndefined, isNaN as _isNaN, zip } from 'lodash'
import { InputEstateInvestmentCreateValues } from '@/models/inputs/inputEstateInvestmentsModel'
import {
  THIS_YEAR_NUMBER,
  castNumber,
  extractYearOrMonthFromDate,
  API_FLAG,
  THIS_MONTH_NUMBER,
  addZeroToStringMonth,
  combineTwoTypesYear,
} from '@/models/commonsModelFunc'

// 物件タイプ
export const INPUT_PROPERTY_TYPE = {
  building: 0,
  section: 1,
}

export const INPUT_ESTATE_INVESTMENT_DEFAULT_VALUES = {
  input_declines_attributes: [
    {
      annual_rate_of_decline_year: null,
      annual_rate_of_decline_percent: null,
    },
  ],
  input_rate_changes_attributes: [
    {
      rate_start_year: null,
      changed_rate_percent: null,
    },
  ],
  input_rate_changes_other_attributes: [
    {
      rate_start_year: null,
      changed_rate_percent: null,
    },
  ],
  input_estate_loan_calculations_attributes: [
    {
      advance_repayment_year: null,
      repayment_amount: null,
      repayment_type: null,
    },
  ],
  repair_costs_span: 1,
  repair_reserve_fund_rate_of_up_span: 24,
  management_fee_type: 0,
  expected_return_year: THIS_YEAR_NUMBER,
  expected_return_month: addZeroToStringMonth(THIS_MONTH_NUMBER),
  other_expenses: 5000,
}

// 建物・賃貸管理手数料 設定タイプ
export const INPUT_MANAGEMENT_FEE_TYPE = {
  rate: 0,
  yen: 1,
}

// 空室率の設定タイプ
export const INPUT_VACANCY_SETTING_TYPE = {
  own: 0,
  auto: 1,
}

// ローン方式
export const INPUT_LOAN_TYPE = {
  personOnly: 0,
  spouseOnly: 1,
}

export const INPUT_VACANCY_RATE_FIELDS = [
  'input_estate_investment.room_count',
  'input_estate_investment.move_out_frequency',
  'input_estate_investment.time_between',
]

// 空室率算出
export const calcVacancyRate = (values: InputEstateInvestmentCreateValues) => {
  const { room_count, move_out_frequency, time_between } = values.input_estate_investment
  if (
    _isUndefined(values) ||
    _isUndefined(room_count) ||
    _isUndefined(move_out_frequency) ||
    _isUndefined(time_between)
  )
    return

  const vacancy_rate = Math.round(
    ((room_count * ((120 / move_out_frequency) * time_between)) / 600) * 100,
  )

  return _isNaN(vacancy_rate) ? 0 : vacancy_rate
}

export const convertPropertyType = (propertyType: number | null) => {
  switch (propertyType) {
    case INPUT_PROPERTY_TYPE.building:
      return '１棟'
    case INPUT_PROPERTY_TYPE.section:
      return '区分'
    default:
      return ''
  }
}

// 年（例：2021年(令和元年)）
export const convertRepaymentTwoTypesYearMonthDate = (date: string | null) => {
  return combineTwoTypesYear(extractYearOrMonthFromDate(date, 'year'))
}

export const convertRepaymentChart = (repaymentChart: number | null) => {
  return repaymentChart === API_FLAG.on ? 'ある' : 'ない'
}

export const convertLoanType = (loanType: number | null) => {
  switch (loanType) {
    case INPUT_LOAN_TYPE.personOnly:
      return 'ご本人単独'
    case INPUT_LOAN_TYPE.spouseOnly:
      return '配偶者単独'
    default:
      return ''
  }
}

export const convertLifeInsurance = (lifeInsurance: number | null) => {
  switch (lifeInsurance) {
    case API_FLAG.on:
      return '加入している'
    case API_FLAG.off:
      return '加入していない'
    default:
      return ''
  }
}

export const convertYearOfConstruction = (year: number | null) => {
  return THIS_YEAR_NUMBER - castNumber(year)
}

export const convertAnnualRateOfDeclineYear = (date: string | null) => {
  const year = extractYearOrMonthFromDate(date, 'year')

  return castNumber(year) - THIS_YEAR_NUMBER
}
