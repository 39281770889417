import React, { FC } from 'react'
import { Switch, Route } from 'react-router-dom'
import AccountDeactivate from '@/templates/v2/settings/accountDeactivations/Deactivate'
import AccountDeactivateConfirm from '@/templates/v2/settings/accountDeactivations/Confirm'
import Account from '@/templates/v2/settings/Account'
import SettingTests from '@/templates/v2/settings/Tests'

const Setting: FC = () => {
  return (
    <Switch>
      <Route exact path="/v2/settings/account" component={Account} />
      <Route exact path="/v2/settings/accountDeactivate" component={AccountDeactivate} />
      <Route
        exact
        path="/v2/settings/accountDeactivate/confirm"
        component={AccountDeactivateConfirm}
      />
      <Route
        exact
        path="/v2/settings/test"
        component={SettingTests}
      />
    </Switch>
  )
}

export default Setting
