import React, { FC, useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import MoneytreeIcon from '@/assets/images/v2/moneytree.svg'
import Typography from '@/components/v2/atoms/Typography'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import Button from '@/components/v2/atoms/Button'
import { fetchMtCnnectingStatus } from '@/containers/mtAccessTokensSlice'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import { MtConnectingStatus } from '@/models/mtAccessTokenModel'
import { getMoneytreeLoginUrl, getMoneytreeRegistrationUrl } from '@/utils/moneytree'
import { getRandomString } from '@/utils/randomString'

const MoneytreeConnect: FC = () => {
  const dispatch = useDispatch()
  const { handleMovePage } = useCustomHistory()

  const timerRef = useRef<any>(null)
  const [isDisabled, setIsDisabled] = useState<boolean>(false)

  useEffect(() => {
    return () => clearInterval(timerRef.current)
  }, [])

  const handleOpenMoneytree = (urlType: 'login' | 'register') => {
    const fetchMtCnnectingStatusFunc = () => {
      dispatch(
        fetchMtCnnectingStatus((response: MtConnectingStatus) => {
          if (response.action !== 'polling') {
            clearInterval(timer)
            setIsDisabled(false)
          }

          if (response.action === 'next') {
            handleMovePage('/v2/plans/dataConnection')
          }

          if (response.action === 'request') {
            const moneytreeStateString = getRandomString(30)
            window.localStorage.moneytreeState = moneytreeStateString
            const url =
              urlType === 'register'
                ? getMoneytreeRegistrationUrl(moneytreeStateString)
                : getMoneytreeLoginUrl(moneytreeStateString)

            setTimeout(() => window.open(url))
          }
        }),
      )
    }

    setIsDisabled(true)
    fetchMtCnnectingStatusFunc()

    const timer = setInterval(fetchMtCnnectingStatusFunc, 1000)
    timerRef.current = timer
  }

  return (
    <div className="space-y-24">
      <div className="flex justify-center">
        <MoneytreeIcon />
      </div>
      <Typography className="text-14 text-center" isBold>
        家計簿アプリの連携画面へ遷移します
      </Typography>
      <div className="flex justify-center flex-wrap md:space-x-24 space-y-24 md:space-y-0">
        <ButtonPrimary
          className="h-45 w-full md:w-215 px-17 text-white"
          isDisabled={isDisabled}
          onClick={() => handleOpenMoneytree('login')}
        >
          <Typography className="bottom-2 flex-auto relative text-14" isBold>
            アカウントをお持ちの方
          </Typography>
          <i className="ico chevron-right text-24" />
        </ButtonPrimary>
        <Button
          className=" h-45 w-full md:w-265 px-17 text-primary-500 border border-primary-500 rounded-8"
          isDisabled={isDisabled}
          onClick={() => handleOpenMoneytree('register')}
        >
          <Typography className="bottom-2 flex-auto relative text-14" isBold>
            アカウントをお持ちではない方
          </Typography>
          <i className="ico chevron-right text-24" />
        </Button>
      </div>
    </div>
  )
}

export default MoneytreeConnect
