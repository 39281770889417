import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setPageMeta } from '@/containers/pageMetasSlice'
import LogindRedirect from '@/layouts/v2/LogindRedirect'
import MarginContainer from '@/components/v2/atoms/MarginContainer'
import Paper from '@/components/v2/atoms/Paper'
import Heading from '@/components/v2/atoms/Heading'
import Typography from '@/components/v2/atoms/Typography'
import Button from '@/components/v2/atoms/Button'
import { useCustomHistory } from '@/hooks/useCustomHistory'

const Terms: FC = () => {
  const dispatch = useDispatch()
  const { handleMovePage } = useCustomHistory()

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: '利用規約',
        description: '利用規約',
        keyword: '利用規約',
      }),
    )
  }, [])

  return (
    <MarginContainer className="py-40">
      <Paper className="mx-8 px-16 py-24 sm:p-40 space-y-30 text-black-800 bg-white">
        <Heading className="text-center">利用規約</Heading>
        <section className="space-y-24">
          <Heading as="h2" size={4}>
            第１章　総則
          </Heading>
          <div>
            <Typography>第１条 （規約）</Typography>
            <Typography>
              １　本規約は、株式会社アルファ・ファイナンシャルプランナーズ（以下「当社」といいます。）が提供するマネソルサービス（以下「本サービス」といいます。）の利用条件を定めるものであり、ユーザー（次条で定義します。）による本サービスの利用に際し、当社とユーザーの間に適用されます。当社とユーザーは、本規約が本サービスの利用に関する契約（以下「本利用契約」といいます。）の内容となることに合意します。
            </Typography>
            <Typography>
              ２　当社が本サイト（次条で定義します。）上で随時掲載する本サービスに関するルール、諸規程等は、本規約の一部を構成します。当該ルール等と本規約の一部が矛盾抵触する場合には、当該ルールが優先して適用されるものとします。
            </Typography>
          </div>
          <div>
            <Typography>第２条 （定義）</Typography>
            <Typography>
              本規約において、次の各号に掲げる用語の意味は、当該各号に定めるとおりとします。
            </Typography>
            <Typography>（1）ユーザー</Typography>
            <Typography>本サイトの使用が許可されている者をいいます。</Typography>
            <Typography>（2）本サイト</Typography>
            <Typography>
              当社が本サービスを提供するために運営するウェブサイトをいいます。本サービスは、本サイトを介してユーザーに提供されます。
            </Typography>
            <Typography>（3）登録情報</Typography>
            <Typography>
              本サービスを利用する前提として登録することが求められる、当社が定めるユーザーに関する情報をいいます。
            </Typography>
            <Typography>（4）ユーザー情報</Typography>
            <Typography>
              本サービスのために、連携機能（第12条で定義します。）により取得した情報、また、ユーザーの使用端末から開示され、当社が管理するサーバーに送信された各種情報や通信記録その他の一切の情報をいい、登録情報、ログ情報、アプリケーション実行情報、Web検索情報等を含みますが、これらに限りません。
            </Typography>
          </div>
          <div>
            <Typography>第３条 （規約の変更）</Typography>
            <Typography>
              当社は、いつでも、ユーザーの事前の承諾を得ることなく、本規約の内容を変更することができます。
            </Typography>
            <Typography>
              ２　当社が本規約の内容を変更し、その変更内容を本規約の定めに従いユーザーに通知した場合、通知において指定された期日以降に、ユーザーが本サービスを利用した場合、変更後の本規約に同意したものとみなし、変更後の本規約が適用されます。
            </Typography>
          </div>
          <div>
            <Typography>第４条 （通知）</Typography>
            <Typography>
              当社は、本サービスに関連してユーザーに通知をする場合には、本サイトに掲示する方法または登録情報として登録された電子メールアドレス・住所に宛てて電子メール・文書を送信する方法など、当社が適当と判断する方法で実施します。
            </Typography>
            <Typography>
              ２　前項に定める方法により行われた通知は、前者の場合には通知内容が本サイトに掲示された時点に、後者の場合は当社が電子メール・文書を発信した時点で、それぞれその効力を生じるものとします。
            </Typography>
            <Typography>
              ３　本サービスに関する問い合わせその他ユーザーから当社に対する連絡または通知は、当社の定める方法で行うものとします。
            </Typography>
            <Typography>
              ４　本条に定める通知の効力は、ユーザーが現実に通知を受領または認識したかどうかにかかわらず、発生するものとします。
            </Typography>
          </div>
        </section>
        <section className="space-y-24">
          <Heading as="h2" size={4}>
            第２章　契約の成立
          </Heading>
          <div>
            <Typography>第５条 （本サービスの目的および内容）</Typography>
            <Typography>
              本サービスは、連携機能（第12条で定義します。）により取得した情報の集計およびその分析により、ユーザーのライフプランシミュレーションの作成、ライフプランシミュレーションと現実の行動の差分管理および資産運用のシミュレーション等に寄与することを目的としています。
            </Typography>
            <Typography>
              ２　本サービスではオプションとして、ユーザーの当社に対する委託に基づき、連携機能（第12条で定義します。）により取得したユーザー情報を、当社指定のファイナンシャルプランナーが分析し、ライフプランの構築を支援するサービスを提供します。本サービスの詳細な内容および機能は、本サイトおよび資料をご参照ください。
            </Typography>
          </div>
          <div>
            <Typography>第６条 （本利用契約の成立（入会））</Typography>
            <Typography>
              ２　ユーザーは、当社が前項の申込みを受諾した日から、当社と合意した契約期間において、本サービスを利用できます。当社が本サイト上で定める期間を契約期間とします。
            </Typography>
            <Typography>
              ３　未成年者が本サービスの利用を希望する場合には、法定代理人の同意が必要になります。未成年者がユーザーとなった場合には、本サービスの利用および本規約の内容について、法定代理人の同意があったものとみなします。
            </Typography>
            <Typography>
              ４　契約期間は、契約期間満了以前に解約、解除等により本利用契約が終了しない限り、同一内容で更新されるものとし、以後も同様とします。
            </Typography>
          </div>
          <div>
            <Typography>第７条 （利用料金および支払方法）</Typography>
            <Typography>
              ユーザーは、当社に対して本サービスの利用の対価として、当社が別途定める料金プランに従い、利用料金を支払います。ただし、当社が期間を指定して本サービスを無料で提供する場合はこの限りではありません。
            </Typography>
            <Typography>
              ２　利用料金の支払方法は、月額費用を本サービス利用月の前月末日（金融機関の休業日の場合はその前営業日）までに支払うものとします。ただし、当社とユーザーとの別途の合意がある場合は、当該合意内容を優先するものとします。
            </Typography>
            <Typography>
              ３　ユーザーは、原則として、利用料金をクレジットカード決済により支払うものとし、詳細は利用申込書に記載することができるものとします。当社は、いかなる場合にも受領した利用料金の返金には応じません。
            </Typography>
            <Typography>
              ４　月額費用は、利用開始日または有料サービス期間開始日が月の途中である場合など、当社が指定した場合は、日割り計算とします。ただし、解約月またはプラン変更月の月額費用の日割り計算を行いません。
            </Typography>
            <Typography>
              ５　本サービスを利用するために必要となる通信費、および通信機器等は、ユーザーの負担と責任により準備するものとします。但し、ユーザーの使用する通信機器等において、本サイトが正常に動作することを保証するものではありません。
            </Typography>
          </div>
          <div>
            <Typography>第８条 （料金プランの変更）</Typography>
            <Typography>
              ユーザーは、料金プランの変更を希望する場合は、当社が指定する方法により連絡を行うものとし、当社とユーザーがプラン変更を合意した日（以下「プラン変更日」といいます。）に、料金プランの変更が認められるものとします。
            </Typography>
            <Typography>
              ２　プラン変更日に翌月の利用料金の支払が完了している場合は、翌々月の支払から料金プランが変更されるものとし、プラン変更日に翌月の利用料金の支払が完了していない場合は、翌月の支払から料金プランが変更されるものとします。ただし、当社とユーザーとの別途の合意がある場合は、当該合意内容を優先するものとします。
            </Typography>
          </div>
        </section>
        <section className="space-y-24">
          <Heading as="h2" size={4}>
            第３章　サービス利用上の注意事項
          </Heading>
          <div>
            <Typography>第９条 （個人情報の保護および取扱いについて）</Typography>
            <Typography>
              当社は、ユーザーから開示される個人情報（個人情報の保護に関する法律第2条に定める個人情報を意味します。）については、ユーザーの委託に基づき提供を受けるものとします。
            </Typography>
            <Typography>
              ２　当社は、ユーザーから委託された個人情報を、別途定めるプライバシーポリシーに従い取扱い、委託の趣旨の範囲を超えて利用、加工、複写および複製を行わないものとします。
            </Typography>
            <Typography>
              ３　当社は、ユーザーから委託された個人情報について、漏えい、滅失または毀損等の防止のため、必要かつ適切な措置を講じるものとし、漏えい、滅失または毀損等が発生した場合、速やかに適切な措置を取るものとします。
            </Typography>
          </div>
          <div>
            <Typography>第１０条（ユーザーID等）</Typography>
            <Typography>
              ユーザーは、本サービス申込みの際に指定したユーザーID（メールアドレス）およびユーザーパスワード（以下総称して「ユーザーID等」といいます。）を自己の責任において厳重に管理するものとします。
            </Typography>
            <Typography>
              ２　ユーザーは、ユーザーID等を第三者に開示、貸与、共有、譲渡等をしてはならず、自分以外の者に本サービスを利用させてはいけません。
            </Typography>
            <Typography>
              ３　本サービスにおいてユーザーID等を用いてなされた一切の行為は、当該ユーザーID等を管理するユーザーによる行為とみなし、当該ユーザーがその責任を負うものとします。
            </Typography>
            <Typography>
              ４　ユーザーは、ユーザーID等が盗まれたことや、第三者に使用されていることが判明した場合には、直ちにその旨を当社に通知し、当社からの指示に従うものとします。
            </Typography>
          </div>
          <div>
            <Typography>第１１条 （登録情報の変更）</Typography>
            <Typography>
              ユーザーは、登録情報に変更が生じた場合には、当社が指定する方法により速やかに届出を行います。当社は、登録情報の変更の届出がなされなかったことによりユーザーに生じた損害について一切の責任を負いません。
            </Typography>
          </div>
          <div>
            <Typography>第１２条 （連携機能）</Typography>
            <Typography>
              ユーザーは、自己の責任において、本サービスと第三者が運営するサービス（以下「連携先サービス」といいます。）とをAPIを用いて連携する機能（以下「連携機能」といいます。）の利用に必要な連携先サービスのIDおよびパスワードその他の情報（以下総称して「連携先ID等」といいます。）を登録します。
            </Typography>
            <Typography>
              ２　当社は、ユーザーが登録した連携先ID等について、連携機能以外の用途での一切の利用を行いません。
            </Typography>
            <Typography>
              ３　当社は、連携可能サービスの指定にあたって最善の注意を尽くします。但し、当該指定は、当社と連携可能サービスの運営者との間での提携、協調、授権その他の一切の協力関係を意味するものではなく、当社は、連携機能の利用により取得されるデータの正確性、完全性等につき、一切の保証を行いません。ユーザーは、自己の責任において連携機能及び連携先サービスを利用するものとし、連携機能の利用により取得されるデータの正確性、完全性等につき、適宜、連携先サービスにおいても確認を行うものとします。
            </Typography>
            <Typography>
              ４　ユーザーは、連携先ID等の誤入力もしくは連携機能の利用に起因する連携先サービスの運営者または第三者との間での紛争その他一切の債権債務関係について、自己の責任と費用で解決するものとし、当社に何ら迷惑をかけず、またこれにより当社が被った損害を第24条に基づき補償します。
            </Typography>
            <Typography>
              ５　ユーザーは、連携機能の利用により取得するデータが、本サービスまたは連携先サービスにおけるシステムの不具合、通信設備等の異変その他の事情により本サイトにおいて正確に表示されない可能性があることを予め了承します。
            </Typography>
          </div>
          <div>
            <Typography>第１３条 （第三者サービス）</Typography>
            <Typography>
              本サービスにおける当社以外の第三者がウェブサイトを介して運営するサービス（以下「第三者サービス」といい、連携先サービスを含みます。）との連携は、当社と第三者サービスの運営者との間の提携、協調、授権その他の一切の協力関係を意味するものではなく、ユーザーは、自己の責任において第三者サービスを利用するものとし、第三者サービスとの連携により取得されるデータ等の正確性、完全性等につき、適宜、第三者サービスにおいても確認を行うものとします。
            </Typography>
            <Typography>
              ２　ユーザーは、第三者サービスとの連携に起因する当該サービスの運営者または第三者との間での紛争その他一切の債権債務関係について、自己の責任と費用で解決するものとし、当社に何ら迷惑をかけず、またこれにより当社が被った損害を第24条に基づき補償します。
            </Typography>
            <Typography>
              ３　ユーザーは、第三者サービスとの連携により取得するデータが、本サービスまたは第三者サービスにおけるシステムの不具合、通信設備等の異変により本サイトにおいて正確に表示されない可能性があることを予め了承します。
            </Typography>
            <Typography>
              ４　第三者サービスの利用は、ユーザーと第三者サービスの運営者との間での別途の契約に従うものとします。
            </Typography>
          </div>
          <div>
            <Typography>第１４条 （バックアップ）</Typography>
            <Typography>
              当社は、ユーザー情報をバックアップとして記録することがあります。但し、当該情報をバックアップする義務を負うものではなく、当該情報の復旧を保証するものではありません。ユーザーにおいてバックアップの必要がある場合は自己の責任と費用において行ってください。
            </Typography>
            <Typography>
              ２　当社は、ユーザー情報のバックアップデータが存在しないことにより発生したユーザーの損害および不利益につき、一切の責任を負いません。
            </Typography>
          </div>
          <div>
            <Typography>第１５条 （禁止行為）</Typography>
            <Typography>
              ユーザーは、本サービスに関連して次の各号に定める行為を行ってはいけません。
            </Typography>
            <Typography>（1） 当社に対して虚偽の申告をする行為</Typography>
            <Typography>
              （2）
              本利用契約に基づき当社から提供された本サービスおよび本サイトを含む情報および役務を本サービスの利用以外の目的のために使用する行為
            </Typography>
            <Typography>
              （3）
              当社もしくは第三者の財産（知的財産権（第23条で定義します。）を含みます。）、プライバシー、名誉、信用、肖像またはパブリシティに係る権利、その他の権利・利益を侵害する行為または侵害するおそれのある行為
            </Typography>
            <Typography>
              （4）
              前号以外で当社もしくは第三者の権利・利益を侵害する行為または侵害するおそれのある行為
            </Typography>
            <Typography>
              （5）
              法令、本規約その他当社が定めるルール等に違反し、もしくは公序良俗に反する行為またはそのおそれのある行為
            </Typography>
            <Typography>
              （6）
              第三者のユーザーID等または連携先ID等を不正に使用または取得する行為その他ユーザーが、第三者を装って本サービスを利用する行為
            </Typography>
            <Typography>
              （7）
              コンピュータウィルスなどの有害なプログラムを使用し、もしくは送信する行為、またはそのおそれのある行為
            </Typography>
            <Typography>
              （8）
              前号に定めるものの他、不正アクセス行為等当社による業務の遂行、本サービスの実施もしくは当社の通信設備等に支障を及ぼし、またはそのおそれがあると当社が判断する行為
            </Typography>
            <Typography>（9）その他当社が不適切と判断する行為</Typography>
          </div>
        </section>
        <section className="space-y-24">
          <Heading as="h2" size={4}>
            第４章　解約・解除・退会
          </Heading>
          <div>
            <Typography>第１６条 （ユーザーによる本サービスの解約）</Typography>
            <Typography>
              ユーザーは、本利用契約を解約する場合、希望する解約月の前々月末日までに、当社指定の届け出を行うことにより解約手続きを行うこととし、解約月末日をもって、当該サービスの利用契約の全部または一部が解約されるものとします。ただし、無料サービス期間中に解約手続きを行った場合、無料サービス期間の満了時をもって解約されるものとします。
            </Typography>
            <Typography>
              ２　前項に基づきユーザーが解約した場合でも、当社は、解約理由の如何にかかわらず既に受領した利用料金の返金は行いません。また、ユーザーは、解約後においても、解約時までに発生した利用料金の支払義務を負うものとします。
            </Typography>
            <Typography>
              ３　ユーザーが第１項により本利用契約を解約した場合、当社はユーザー情報を消去することができます。
            </Typography>
          </div>
          <div>
            <Typography>第１７条 （当社による契約解除）</Typography>
            <Typography>
              当社は、ユーザーが次の各号の一つに該当した場合には、ユーザーに対して何らの通知催告をすることなく、本利用契約の一部または全部を解除してユーザーに対する退会処分を行い、または本サービスの提供停止および再登録の禁止等の必要な措置を行うことができます。
            </Typography>
            <Typography>（1） 本規約に違反する行為を行った場合</Typography>
            <Typography>
              （2）
              当社に提供された登録情報の全部または一部につき虚偽、誤記または記載漏れがあった場合
            </Typography>
            <Typography>
              （3）
              現に制限行為能力者であるか、または制限行為能力者になった場合において、催告後相当期間を経過しても法定代理人等の記名押印のある同意書または追認書の提出がない場合
            </Typography>
            <Typography>（4）ユーザーが死亡した場合</Typography>
            <Typography>
              （5）
              反社会的勢力等（暴力団、暴力団員、右翼団体、暴力団準構成員、総会屋、社会運動等標榜ゴロ、特殊知能暴力集団その他これに準ずる者を意味します。以下同じ。）である、資金提供その他を通じて反社会的勢力等の維持、運営もしくは経営に協力もしくは関与する等反社会的勢力等との何らかの交流もしくは関与を行っている、または反社会的勢力等が経営に実質的に関与している法人等であると当社が合理的に判断した場合
            </Typography>
            <Typography>
              （6）
              カード会社、立替代行業者等によりユーザー指定のカード、支払口座の利用が停止された場合
            </Typography>
            <Typography>
              （7）
              仮差押、差押、競売、破産手続開始、会社更生手続開始、民事再生手続開始等の申立があった場合、または公租公課等の滞納処分を受けた場合
            </Typography>
            <Typography>
              （8）
              過去に本サービスについて退会処分または提供停止等を受けたことが判明した場合
            </Typography>
            <Typography>
              （9） ユーザーが90日以上にわたって所在不明または連絡不能となった場合
            </Typography>
            <Typography>
              （10） その他、当社がユーザーとして不適当であると合理的に判断した場合
            </Typography>
            <Typography>
              ２　前項に基づきユーザーが退会処分または提供停止等を受けた場合でも、当社は、既に受領した利用料金の返金は行わず、またユーザーおよび第三者に生じた損害および不利益につき一切の責任を負いません。
            </Typography>
            <Typography>
              ３　第１項に基づきユーザーが退会処分を受けた場合、当社はユーザー情報を消去することができます。
            </Typography>
          </div>
        </section>
        <section className="space-y-24">
          <Heading as="h2" size={4}>
            第５章　サービスの停止、変更および終了
          </Heading>
          <div>
            <Typography>第１８条 （サービスの停止）</Typography>
            <Typography>
              当社は、次の各号のいずれかの事由が生じた場合には、事前にユーザーに通知をすることなく、本サービスの一部または全部を停止することができます。
            </Typography>
            <Typography>
              （1）
              本サービス提供にあたり必要なシステム、設備等に障害が発生し、またはメンテナンス、保守もしくは工事等が必要となった場合
            </Typography>
            <Typography>
              （2）
              電気通信事業者が電気通信サービスの提供を中止するなど、当社以外の第三者の行為に起因して、本サービスの提供を行うことが困難になった場合
            </Typography>
            <Typography>
              （3）
              非常事態（天災、疫病、戦争、テロ、暴動、騒乱、行政処分、労働争議等）の発生により、本サービスの提供が困難になった場合、または困難になる可能性のある場合
            </Typography>
            <Typography>
              （4） 連携可能サービスの事情により、連携可能サービスが利用できなくなった場合
            </Typography>
            <Typography>
              （5） 法令規制、行政命令等により、本サービスの提供が困難になった場合
            </Typography>
            <Typography>
              （6）
              ユーザーID等または連携先ID等が不正使用されている可能性があると当社が判断した場合
            </Typography>
            <Typography>
              （7）
              その他、当社の責めに帰することができない事由により、当社が必要やむを得ないと判断した場合
            </Typography>
            <Typography>
              ２　当社は、前項に基づいて本サービスを停止したことによりユーザーおよび第三者に生じた損害および不利益につき一切の責任を負いません。
            </Typography>
          </div>
          <div>
            <Typography>第２０条 （サービスの中止および終了）</Typography>
            <Typography>
              当社は、事前にユーザーに通知をしたうえで、当社の裁量により本サービスの一部もしくは全部の提供を中止または終了することができます。但し、中止または終了の内容が重大でない場合には、通知をすることなくこれらを実施することができます。
            </Typography>
            <Typography>
              ２　当社は、前項に基づいて本サービスを中止または終了したことによりユーザーに損害が発生した場合でも、一切の責任を負いません。
            </Typography>
          </div>
        </section>
        <section className="space-y-24">
          <Heading as="h2" size={4}>
            第６章　一般条項
          </Heading>
          <div>
            <Typography>第２１条 (反社会的勢力等の排除)</Typography>
            <Typography>
              ユーザーおよび当社は、相手方に対して、自己または自己の役員若しくは従業員が、反社会的勢力等に該当しないこと、および次の各号のいずれにも該当しないことを表明し、かつ将来にわたっても該当しないことを確約するものとします。
            </Typography>
            <Typography>
              （1）本サービス利用申込みに際して、故意過失の有無にかかわらず、他人名義や架空名義の利用・虚偽記載・誤記等、事実と異なる記載がある場合または記入漏れがある場合
            </Typography>
            <Typography>
              （2）当該利用希望者が、本サービス利用に際して、本サービス利用契約成立後において利用者登録抹消等のサービス利用停止措置を過去に受けたまたは受けている場合
            </Typography>
            <Typography>
              （3）当該利用希望者が、過去に本サービスを利用し中傷行為を行った場合、本サービス利用契約違反を繰り返した場合、またはこのような者であると合理的に疑われる場合
            </Typography>
            <Typography>
              （4）反社会的勢力等であると判明した場合または反社会的勢力等が経営に実質的に関与している法人等であると判明した場合
            </Typography>
          </div>
          <div>
            <Typography>第２２条（保証）</Typography>
            <Typography>
              当社は、本サービスが動作環境において機能するように合理的な最大限の努力を行います。但し、当社は、ユーザー情報が正確性、正当性、有用性、完全性、最新性等を有することを保証するものではありません。ユーザーは、ユーザー情報について、自らの判断および責任において必要に応じ変更、修正等を行ったうえで利用するものとします。
            </Typography>
          </div>
          <div>
            <Typography>第２３条 （知的財産権）</Typography>
            <Typography>
              本サービスに関する著作権、著作者人格権、特許権、実用新案権、意匠権、商標権およびパブリシティ権、ノウハウ等の一切の知的財産権（知的財産権を受ける権利を含み、以下単に「知的財産権」といいます。）は当社および正当な権利者たる第三者に帰属し、本利用契約の成立は、本サービスの利用に必要な範囲を超える知的財産権の利用許諾を意味するものではありません。
            </Typography>
          </div>
          <div>
            <Typography>第２４条 （損害賠償および免責）</Typography>
            <Typography>
              当社は、本サービスに関してユーザーに生じた損害について賠償責任を負う場合、当該損害の直接の原因となったサービスについて、当該ユーザーから受領した直近６か月分の月額利用料金の総額を上限としてその損害を賠償するものとし、それ以外の損害については一切の責任を負いません。但し、当社に故意または重過失が認められる場合はこの限りではありません。
            </Typography>
            <Typography>
              ２　ユーザーは、本規約に違反することにより、または本サービスの利用に関して当社または第三者に損害（弁護士費用を含みます。以下同じ。）を与えた場合、ユーザーの責任と費用で損害を賠償しなければなりません。
            </Typography>
            <Typography>
              ３　本サービスに関してユーザーと第三者（他のユーザーを含みます。）との間に紛争が生じた場合、ユーザーは自己の責任と費用で解決するものとし、当社に何ら迷惑をかけず、またこれにより当社が被った損害を補償します。
            </Typography>
          </div>
          <div>
            <Typography>第２５条 （委託）</Typography>
            <Typography>
              当社は、本サービスに関する業務の一部または全部を第三者に委託することができるものとします。
            </Typography>
          </div>
          <div>
            <Typography>第２６条 （情報管理）</Typography>
            <Typography>
              当社は、ユーザー情報（個人情報を除きます。）について、ユーザーの事前の同意を得ずに第三者に開示しません。但し、次の各号の場合はこの限りではありません。
            </Typography>
            <Typography>
              （1） 法令または公的機関からの要請を受け、要請に応じる必要を認めた場合
            </Typography>
            <Typography>
              （2）
              人の生命、身体または財産の保護のために必要があり、かつユーザーの同意を得ることが困難である場合
            </Typography>
            <Typography>
              （3）
              ユーザーに利用料金を請求する目的で、決済システム会社、クレジット会社、銀行等に本サービスを利用している登録ユーザーの情報を預託する場合
            </Typography>
            <Typography>
              （4）
              本サービス提供または機能向上もしくは改善のために必要な受託者、または代理人等に情報を開示する場合
            </Typography>
            <Typography>
              （5）
              当社および当社子会社間で連携したサービスを提供するために共同利用する場合
            </Typography>
            <Typography>
              ２　当社は、ユーザーが、第5条第2項に定める当社指定のファイナンシャルプランナーが分析し、ライフプランの構築を支援するサービスを利用する場合、当該ファイナンシャルプランナー（当社従業員の他、当社以外の組織に属する場合には当該サービス提供に必要となる当該組織の役職員を含みます。）に対し、当該ユーザーに関するユーザー情報を開示することがあります。なお、本項におけるユーザー情報には個人情報を含むものとし、ユーザーが承諾した場合及びユーザーが当社指定のファイナンシャルプランナーの招待により本サービスの利用を開始する場合、当社及び当社指定のファイナンシャルプランナーは、当社の判断により、当該ユーザーの個人設定画面を閲覧し、本サービス提供のために必要な操作を行うことができるものとします。但し、ユーザーが個人設定画面において当社及び当社指定のファイナンシャルプランナーへの閲覧等を制限した場合はこの限りではありません。
            </Typography>
            <Typography>
              ３　前項にかかわらず、当社は、ユーザーの情報の属性集計・分析を行い、ユーザーが識別・特定できないように加工したものであり個人情報に該当しないもの（以下「統計資料」といいます。）を作成し、本サービスおよび当社のその他のサービスのために利用することがあります。また、統計資料を第三者に開示することがあります。
            </Typography>
            <Typography>
              ４　当社は、ユーザー情報の紛失、破壊、改鼠、漏洩等の危険に対して、合理的な安全対策を講じます。
            </Typography>
            <Typography>
              ５　当社は、電話応対品質向上等のため、ユーザーとの間の電話応対を録音し、録音内容を業務において使用することができるものとします。
            </Typography>
          </div>
          <div>
            <Typography>第２７条 （権利義務の譲渡禁止）</Typography>
            <Typography>
              ユーザーは、当社の事前の書面による承諾を得ることなく、本利用契約に基づく権利義務を第三者に譲渡（合併、会社分割、相続等による包括承継も含みます。）し、引受させ、または担保の目的に供してはならないものとします。
            </Typography>
            <Typography>
              ２　当社が、本サービスまたは本サービスにかかる事業を第三者に譲渡し、または合併若しくは会社分割等により本サービスに係る事業を第三者に承継したときは、当社は、当該譲渡等に伴い、本利用契約上の地位、権利および義務並びに登録情報およびユーザー情報等その他本サービス提供に必要な情報を当該第三者に承継させることができるものとします。ユーザーは、かかる承継につき予め包括的に同意したものとします。
            </Typography>
            <Typography></Typography>
            <Typography></Typography>
          </div>
          <div>
            <Typography>第２８条 （準拠法、管轄裁判所）</Typography>
            <Typography>
              本規約および本利用契約は、日本法によって解釈され、日本法を準拠法とします。
            </Typography>
            <Typography>
              ２　本利用契約に関してユーザーと当社の間に紛争が生じた場合には、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
            </Typography>
          </div>
        </section>
        <div className="flex justify-center">
          <Button
            className="h-45 w-full md:w-265 px-17 text-primary-500 border border-primary-500 rounded-8"
            onClick={() => handleMovePage('/v2/')}
          >
            <Typography className="bottom-2 flex-auto relative text-14" isBold>
              TOPへ戻る
            </Typography>
            <i className="ico chevron-right text-24" />
          </Button>
        </div>
      </Paper>
    </MarginContainer>
  )
}

export default Terms
