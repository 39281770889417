import React, { FC, useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { find as _find } from 'lodash'
import Button from '@/components/v2/atoms/Button'
import Typography from '@/components/v2/atoms/Typography'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import { setPageMeta } from '@/containers/pageMetasSlice'
import { fetchInputSalaries } from '@/containers/inputs/inputSalariesSlice'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import { isNotEmptyValue, convertLocaleString } from '@/models/commonsModelFunc'
import { PERSON_FLG } from '@/models/familyStructuresModelFunc'
import { INPUT_SUB_INCOME_TYPE } from '@/models/inputs/inputSubIncomesModelFunc'
import { RootState } from '@/store'
import MyDataListWrapper from '@/templates/v2/myData/_ListWrapper'
import { AccountContext } from '@/models/accountsModelFunc'
import { useAccountControl } from '@/hooks/useAccountControl'

const MyDataSubIncome: FC = () => {
  const dispatch = useDispatch()
  const { handleMovePage } = useCustomHistory()
  const { isAdmin } = useContext(AccountContext)
  const { isMyDataManipulatedApproval } = useAccountControl()

  const { inputSalaries } = useSelector((state: RootState) => state.inputSalaries)

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'マイデータ | 副業',
        description: 'マイデータ | 副業',
        keyword: 'マイデータ | 副業',
      }),
    )
    dispatch(fetchInputSalaries())
  }, [])

  const renderTable = (isPerson: boolean) => {
    const personFlg = isPerson ? PERSON_FLG.person : PERSON_FLG.spouse
    const targetInputSubIncome = _find(
      inputSalaries,
      (item) => item.person_flg === personFlg,
    )?.input_sub_income

    return (
      <div className="mb-[60px] last:mb-0">
        <div className="sm:flex items-center justify-between pb-[20px]">
          <CircleTitle
            title={isPerson ? 'ご本人' : '配偶者'}
            classNameTitle="text-[20px] md:text-[24px]"
          />
          <Button
            className="w-[84px] md:w-[112px] h-[26px] md:h-[37px] mr-[0] md:mr-[9px] rounded-[4px] bg-white border border-secondary-500 hover:bg-secondary-50"
            isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
            onClick={() =>
              handleMovePage(
                `/v2/myData/subIncomes/${isPerson ? 'person' : 'spouse'}/edit`,
              )
            }
          >
            <Typography
              className="relative bottom-[1px] text-[12px] md:text-[14px] text-secondary-500"
              isBold
            >
              編集する
            </Typography>
          </Button>
        </div>

        {isNotEmptyValue(targetInputSubIncome) && (
          <table className="w-[100%] mb-[60px] text-[12px] md:text-[14px]">
            <tbody>
              <tr className="h-[45px] odd:bg-black-100">
                <td className="w-[30%] px-[16px] md:px-[30px]">
                  <Typography isBold>副業収入</Typography>
                </td>
                <td className="w-[70%] px-[16px] md:px-[30px]">
                  <Typography>
                    {targetInputSubIncome?.sub_income_type ===
                    INPUT_SUB_INCOME_TYPE.active
                      ? 'あり'
                      : 'なし'}
                  </Typography>
                </td>
              </tr>
              <tr className="h-[45px] odd:bg-black-100">
                <td className="px-[16px] md:px-[30px]">
                  <Typography isBold>年商</Typography>
                </td>
                <td className="px-[16px] md:px-[30px]">
                  <Typography>{`${convertLocaleString(
                    targetInputSubIncome?.sub_income_annual_sales_yearly,
                  )}円`}</Typography>
                </td>
              </tr>
              <tr className="h-[45px] odd:bg-black-100">
                <td className="px-[16px] md:px-[30px]">
                  <Typography isBold>年間経費</Typography>
                </td>
                <td className="px-[16px] md:px-[30px]">
                  <Typography>{`${convertLocaleString(
                    targetInputSubIncome?.sub_income_expenses_yearly,
                  )}円`}</Typography>
                </td>
              </tr>
              <tr className="h-[45px] odd:bg-black-100">
                <td className="px-[16px] md:px-[30px]">
                  <Typography isBold>年間所得</Typography>
                </td>
                <td className="px-[16px] md:px-[30px]">
                  <Typography>{`${convertLocaleString(
                    targetInputSubIncome?.sub_income_yearly,
                  )}円`}</Typography>
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
    )
  }

  return (
    <MyDataListWrapper title="副業" description="給与明細が必要です。ご用意ください。">
      {renderTable(true)}
      {renderTable(false)}
    </MyDataListWrapper>
  )
}

export default MyDataSubIncome
