import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  getTemporaryAccounts,
  postTemporaryAccountLogin,
  postTemporaryAccount,
  patchTemporaryAccountHandOver,
  updateTemporaryAccountResend,
  deleteTemporaryAccountDelete,
  patchTemporaryAccountDemoResetV2,
} from '@/api/admins/temporaryAccounts'
import { unauthorizedAdminCheck } from '@/containers/admins/fpAccountsSlice'
import { AccountLoginResponse } from '@/models/accountsModel'
import {
  TemporaryAccountResponse,
  TemporaryAccount,
  TemporaryAccountLoginValues,
  TemporaryAccountCreateValues,
  TemporaryAccountHandOverValues,
  TemporaryAccountResendValues,
  TemporaryAccountDeleteValues,
} from '@/models/admins/temporaryAccountsModel'
import { ApiError, CommonResponse } from '@/models/commonsModel'
import { AppThunk } from '@/store'

interface TemporaryAccountState {
  temporaryAccounts: TemporaryAccount[]
  isLoading: boolean
}

const initialState: TemporaryAccountState = {
  temporaryAccounts: [],
  isLoading: false,
}

const temporaryAccountsSlice = createSlice({
  name: 'temporaryAccounts',
  initialState,
  reducers: {
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    getTemporaryAccountsSuccess: (
      state,
      action: PayloadAction<TemporaryAccountResponse[]>,
    ) => {
      state.temporaryAccounts = action.payload
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
  },
})

export const {
  requestStart,
  requestSuccess,
  getTemporaryAccountsSuccess,
  requestFailure,
} = temporaryAccountsSlice.actions
export const temporaryAccountsReducer = temporaryAccountsSlice.reducer

export const fetchTemporaryAccounts = (): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  getTemporaryAccounts()
    .then((response) => dispatch(getTemporaryAccountsSuccess(response)))
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedAdminCheck(error))
    })
}

export const createTemporaryAccountLogin = (
  values: TemporaryAccountLoginValues,
  callback: (response: AccountLoginResponse) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postTemporaryAccountLogin(values)
    .then((response) => {
      dispatch(requestSuccess())
      callback(response)
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedAdminCheck(error))
    })
}

export const createTemporaryAccount = (
  values: TemporaryAccountCreateValues,
  callback: () => void,
  resolve: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postTemporaryAccount(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedAdminCheck(error, resolve))
    })
}

export const updateTemporaryAccountHandOver = (
  values: TemporaryAccountHandOverValues,
  callback: () => void,
  errorCallback: (error: ApiError) => void,
  resolve: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchTemporaryAccountHandOver(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      errorCallback(error)
      dispatch(requestFailure())
      dispatch(unauthorizedAdminCheck(error, resolve))
      resolve(error)
    })
}

export const patchResendTemporaryAccount = (
  values: TemporaryAccountResendValues,
  callback: () => void,
  resolve: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  updateTemporaryAccountResend(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedAdminCheck(error, resolve))
    })
}

export const updateTemporaryAccountDemoResetV2 = (
  id: number,
  callback?: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchTemporaryAccountDemoResetV2(id)
    .then((response: CommonResponse) => {
      dispatch(requestSuccess())
      callback?.()
    })
    .catch(() => {
      dispatch(requestFailure())
    })
}

export const destroyTemporaryAccount = (
  values: TemporaryAccountDeleteValues,
  callback: () => void,
  resolve: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  deleteTemporaryAccountDelete(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedAdminCheck(error, resolve))
    })
}
