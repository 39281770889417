import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { sortBy as _sortBy } from 'lodash'
import { unauthorizedCheck } from '../accountsSlice'
import {
  postFutureEstateInvestmentV2,
  patchFutureEstateInvestmentV2,
  deleteFutureEstateInvestmentV2,
} from '../../api/futures/futureEstateInvestments'
import { ApiError } from '../../models/commonsModel'
import {
  THIS_YEAR,
  convertMomentDate,
  compareDiffMomentDate,
} from '../../models/commonsModelFunc'
import { FutureEstateInvestment } from '../../models/futures/futureEstateInvestmentsModel'
import { AppThunk } from '../../store'

interface FutureEstateInvestmentState {
  futureEstateInvestments: FutureEstateInvestment[]
  isLoading: boolean
}

const initialState: FutureEstateInvestmentState = {
  futureEstateInvestments: [],
  isLoading: false,
}

const futureEstateInvestmentsSlice = createSlice({
  name: 'futureEstateInvestments',
  initialState,
  reducers: {
    setFutureEstateInvestments: (
      state,
      action: PayloadAction<FutureEstateInvestment[]>,
    ) => {
      const { payload } = action
      const sortedFutureEstateInvestments = _sortBy(payload, 'id')

      state.futureEstateInvestments = sortedFutureEstateInvestments.map((item) => ({
        ...item,
        purchased_year: convertMomentDate(item.purchased_at, 'YYYY'),
        constructed_number: compareDiffMomentDate(
          THIS_YEAR,
          item.constructed_at,
          'years',
        ),
        future_estate_investment_decline_rates: item.future_estate_investment_decline_rates.map(
          (item) => ({
            ...item,
            affected_number: compareDiffMomentDate(item.affected_at, THIS_YEAR, 'years'),
          }),
        ),
        sale_year: convertMomentDate(item.sell_at, 'YYYY'),
      }))

      return state
    },
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
  },
})

export const {
  setFutureEstateInvestments,
  requestStart,
  requestSuccess,
  requestFailure,
} = futureEstateInvestmentsSlice.actions
export const futureEstateInvestmentsReducer = futureEstateInvestmentsSlice.reducer

export const createFutureEstateInvestmentV2 = (
  values: any,
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postFutureEstateInvestmentV2(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const updateFutureEstateInvestmentV2 = (
  values: any,
  id: number,
  callback: () => void,
  resolve?: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchFutureEstateInvestmentV2(values, id)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error, resolve))
    })
}

export const destroyFutureEstateInvestmentV2 = (
  values: any,
  id: number,
  callback: () => void,
  resolve?: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  deleteFutureEstateInvestmentV2(values, id)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error, resolve))
    })
}
