import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { sortBy as _sortBy } from 'lodash'
import { unauthorizedCheck } from '../accountsSlice'
import {
  getSalaries,
  postCalcSocialInsuranceFee,
  postInputSalary,
  patchInputSalary,
  deleteInputSalary,
  getYearlyIncomeTotal,
} from '../../api/inputs/inputSalaries'
import { ApiError } from '../../models/commonsModel'
import {
  CalcSocialInsuranceFeeResponse,
  InputSalary,
  InputSalaryCreateValues,
  InputSalaryUpdateValues,
  InputSalaryDestroyValues,
  YearlyIncomeTotal,
} from '../../models/inputs/inputSalariesModel'
import { AppThunk } from '../../store'

interface InputSalaryState {
  inputSalaries: InputSalary[]
  yearlyIncomeTotal: number | null
  isLoading: boolean
  isLoadingCalc: boolean
}

const initialState: InputSalaryState = {
  inputSalaries: [],
  yearlyIncomeTotal: 0,
  isLoading: false,
  isLoadingCalc: false,
}

const inputSalariesSlice = createSlice({
  name: 'inputSalaries',
  initialState,
  reducers: {
    setInputSalary: (state, action: PayloadAction<InputSalary[]>) => {
      state.inputSalaries = action.payload.map((item) => ({
        ...item,
        input_bonuses: _sortBy(item.input_bonuses, 'bonus_month'),
      }))

      return state
    },
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    postCalcSocialInsuranceFeeStart: (state) => {
      state.isLoadingCalc = true
      return state
    },
    resetInputSalariesStore: (state) => {
      state = initialState
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    getSalariesSuccess: (state, action: PayloadAction<InputSalary[]>) => {
      state.inputSalaries = action.payload.map((item) => ({
        ...item,
        input_bonuses: _sortBy(item.input_bonuses, 'bonus_month'),
      }))
      state.isLoading = false

      return state
    },
    getYearlyIncomeTotalSuccess: (state, action: PayloadAction<YearlyIncomeTotal>) => {
      state.isLoading = false
      state.yearlyIncomeTotal = action.payload.yearly_income_total
      return state
    },
    postCalcSocialInsuranceFeeSuccess: (state) => {
      state.isLoadingCalc = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
  },
})

export const {
  setInputSalary,
  requestStart,
  postCalcSocialInsuranceFeeStart,
  requestSuccess,
  resetInputSalariesStore,
  getSalariesSuccess,
  getYearlyIncomeTotalSuccess,
  postCalcSocialInsuranceFeeSuccess,
  requestFailure,
} = inputSalariesSlice.actions
export const inputSalariesReducer = inputSalariesSlice.reducer

export const fetchInputSalaries = (): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  getSalaries()
    .then((response) => dispatch(getSalariesSuccess(response)))
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const fetchYearlyIncomeTotal = (): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  getYearlyIncomeTotal()
    .then((response) => dispatch(getYearlyIncomeTotalSuccess(response)))
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const createCalcSocialInsuranceFee = (
  values: InputSalaryCreateValues,
  callback: (response: CalcSocialInsuranceFeeResponse) => void,
): AppThunk => async (dispatch) => {
  dispatch(postCalcSocialInsuranceFeeStart())
  postCalcSocialInsuranceFee(values)
    .then((response) => {
      dispatch(postCalcSocialInsuranceFeeSuccess())
      callback(response)
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const createInputSalary = (
  values: InputSalaryCreateValues,
  callback: () => void,
  resolve: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postInputSalary(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error, resolve))
    })
}

export const updateInputSalary = (
  values: InputSalaryUpdateValues,
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchInputSalary(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const destroyInputSalary = (
  values: { params: InputSalaryDestroyValues },
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  deleteInputSalary(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}
