import ApiClient from '@/api/apiClient'
import {
  LearningVideoDemoAccountCreateValues,
  LearningVideoDemoAccount,
  LearningVideoDemoAccountUpdateValues,
} from '@/models/videoPlatforms/learningVideoDemoAccountsModel'

export const postLearningVideoDemoAccounts = async (
  values: LearningVideoDemoAccountCreateValues,
) => {
  return await ApiClient.post('/learning_video_demo_accounts', () => {}, values)
}

export const getLearningVideoDemoAccounts = async () => {
  return await ApiClient.get(
    '/learning_video_demo_accounts',
    (response: LearningVideoDemoAccount[]) => response,
  )
}

export const patchLearningVideoDemoAccount = async (
  values: LearningVideoDemoAccountUpdateValues,
) => {
  return await ApiClient.patch(
    `/learning_video_demo_accounts/${values.id}`,
    () => {},
    values,
  )
}
