import React, { FC } from 'react'

interface GridProps {
  /** クラス名 */
  className?: string
}

const Grid: FC<GridProps> = (props) => {
  const { className, children } = props
  return <div className={`${className} grid`}>{children}</div>
}

export default Grid
