import React, { FC, useMemo } from 'react'
import Typography from '@/components/v2/atoms/Typography'
import AccordionSet from '@/components/v1/molecules/AccordionSet'
import {
  convertLocaleString,
  isNotEmptyValue,
  convertDateToTwoTypesYearsDateFormat,
} from '@/models/commonsModelFunc'
import {
  isMansionType,
  convertOwnedHouseType,
  REPAIR_RESERVE_FUND_RATE_TYPE,
} from '@/models/commonHousingModelFunc'
import { InputHousing } from '@/models/inputs/inputHousingsModel'
import InputHousingLoanBody from '@/templates/v2/simulations/futureV2Housings/InputHousingLoanBody'

interface InputHousingSelfOwndBodyProps {
  /** 現状住居データ  */
  inputHousing: Partial<InputHousing>
}

const InputHousingSelfOwndBody: FC<InputHousingSelfOwndBodyProps> = (props) => {
  const { inputHousing } = props

  const housingSelfOwned = useMemo(() => inputHousing?.input_housing_self_owned, [
    inputHousing,
  ])

  return (
    <>
      <div className="m-[25px]">
        <AccordionSet titleClassName="text-14" title="現在のご自宅について" defaultClose>
          <Typography styleType="underlinedHeader">基本情報</Typography>
          <table className="w-[100%] mb-[20px] last:mb-0 text-[12px]">
            <tbody>
              <tr className="h-[45px] odd:bg-black-100">
                <td className="w-[50%] px-[16px]">
                  <Typography>家タイプ</Typography>
                </td>
                <td className="w-[50%] px-[16px]">
                  <Typography>
                    {convertOwnedHouseType(housingSelfOwned?.house_type)}
                  </Typography>
                </td>
              </tr>

              <tr className="h-[45px] odd:bg-black-100">
                <td className="w-[50%] px-[16px]">
                  <Typography>築年</Typography>
                </td>
                <td className="w-[50%] px-[16px]">
                  <Typography>
                    {convertDateToTwoTypesYearsDateFormat(
                      housingSelfOwned?.constructed_at,
                      'japaneseYear',
                    )}
                  </Typography>
                </td>
              </tr>

              <tr className="h-[45px] odd:bg-black-100">
                <td className="w-[50%] px-[16px]">
                  <Typography>引渡時期</Typography>
                </td>
                <td className="w-[50%] px-[16px]">
                  <Typography>
                    {convertDateToTwoTypesYearsDateFormat(
                      housingSelfOwned?.purchased_at,
                      'japaneseYear',
                    )}
                  </Typography>
                </td>
              </tr>

              {!isMansionType(housingSelfOwned?.house_type) ? (
                <>
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="w-[50%] px-[16px]">
                      <Typography>購入時の土地価格</Typography>
                    </td>
                    <td className="w-[50%] px-[16px]">
                      <Typography>
                        {`${convertLocaleString(
                          housingSelfOwned?.property_price_land_manyen,
                        )}万円`}
                      </Typography>
                    </td>
                  </tr>

                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="w-[50%] px-[16px]">
                      <Typography>購入時の建物価格</Typography>
                    </td>
                    <td className="w-[50%] px-[16px]">
                      <Typography>
                        {`${convertLocaleString(
                          housingSelfOwned?.property_price_building_manyen,
                        )}万円`}
                      </Typography>
                    </td>
                  </tr>
                </>
              ) : (
                <tr className="h-[45px] odd:bg-black-100">
                  <td className="w-[50%] px-[16px]">
                    <Typography className="u-fs14">購入価格</Typography>
                  </td>
                  <td className="w-[50%] px-[16px]">
                    <Typography>
                      {`${convertLocaleString(
                        housingSelfOwned?.property_price_manyen,
                      )}万円`}
                    </Typography>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <Typography styleType="underlinedHeader">支出情報</Typography>
          <table className="w-[100%] mb-[20px] last:mb-0 text-[12px]">
            <tbody>
              <tr className="h-[45px] odd:bg-black-100">
                <td className="w-[50%] px-[16px]">
                  <Typography className="min-w-[max-content]">
                    固定資産税・
                    <br />
                    都市計画税
                  </Typography>
                </td>
                <td className="w-[50%] px-[16px]">
                  <Typography>{`年間 ${convertLocaleString(
                    housingSelfOwned?.tax_yearly,
                  )}円`}</Typography>
                </td>
              </tr>
              <tr className="h-[45px] odd:bg-black-100">
                <td className="w-[50%] px-[16px]">
                  <Typography>リフォームの頻度</Typography>
                </td>
                <td className="w-[50%] px-[16px]">
                  <Typography>{`${convertLocaleString(
                    !!housingSelfOwned
                      ? Number(housingSelfOwned.repair_costs_span) / 12
                      : null,
                  )}年に1回`}</Typography>
                </td>
              </tr>
              <tr className="h-[45px] odd:bg-black-100">
                <td className="w-[50%] px-[16px]">
                  <Typography>リフォーム費</Typography>
                </td>
                <td className="w-[50%] px-[16px]">
                  <Typography>{`一回あたり ${convertLocaleString(
                    housingSelfOwned?.repair_costs,
                  )}円`}</Typography>
                </td>
              </tr>

              {isMansionType(housingSelfOwned?.house_type) && (
                <>
                  {isNotEmptyValue(
                    housingSelfOwned?.management_fee_monthly_and_repair_reserve_fund_total,
                  ) ? (
                    <tr className="h-[45px] odd:bg-black-100">
                      <td className="w-[50%] px-[16px]">
                        <Typography>管理費・修繕積立金合計</Typography>
                      </td>
                      <td className="w-[50%] px-[16px]">
                        <Typography>
                          {`${convertLocaleString(
                            housingSelfOwned?.management_fee_monthly_and_repair_reserve_fund_total,
                          )}円/月`}
                        </Typography>
                      </td>
                    </tr>
                  ) : (
                    <>
                      <tr className="h-[45px] odd:bg-black-100">
                        <td className="w-[50%] px-[16px]">
                          <Typography>修繕積立金</Typography>
                        </td>
                        <td className="w-[50%] px-[16px]">
                          <Typography>
                            {`${convertLocaleString(
                              housingSelfOwned?.repair_reserve_fund,
                            )}円/月`}
                          </Typography>
                        </td>
                      </tr>

                      <tr className="h-[45px] odd:bg-black-100">
                        <td className="w-[50%] px-[16px]">
                          <Typography>積立金の上昇率</Typography>
                        </td>
                        <td className="w-[50%] px-[16px]">
                          {housingSelfOwned?.repair_reserve_fund_increase_type ===
                            REPAIR_RESERVE_FUND_RATE_TYPE.change && (
                            <div>
                              <Typography className="u-fs14">
                                {`築${housingSelfOwned?.repair_reserve_fund_increase_start}年目から
                          ${housingSelfOwned?.repair_reserve_fund_increase_span}年毎に`}
                              </Typography>
                              <Typography className="u-fs14">
                                {`築${
                                  housingSelfOwned?.repair_reserve_fund_increase_finish
                                }年目まで ${convertLocaleString(
                                  housingSelfOwned?.repair_reserve_fund_increase_percent,
                                )}％ずつ上昇`}
                              </Typography>
                            </div>
                          )}
                          {housingSelfOwned!.repair_reserve_fund_increase_type ===
                            REPAIR_RESERVE_FUND_RATE_TYPE.noSet && (
                            <Typography className="u-fs14">設定なし</Typography>
                          )}
                        </td>
                      </tr>

                      <tr className="h-[45px] odd:bg-black-100">
                        <td className="w-[50%] px-[16px]">
                          <Typography>管理費</Typography>
                        </td>
                        <td className="w-[50%] px-[16px]">
                          <Typography>
                            {`${convertLocaleString(
                              housingSelfOwned?.management_fee_monthly,
                            )}円/月`}
                          </Typography>
                        </td>
                      </tr>
                    </>
                  )}
                </>
              )}

              <tr className="h-[45px] odd:bg-black-100">
                <td className="w-[50%] px-[16px]">
                  <Typography>その他費用</Typography>
                </td>
                <td className="w-[50%] px-[16px]">
                  <Typography>
                    {`${convertLocaleString(housingSelfOwned?.other_cost_yearly)}円/年`}
                  </Typography>
                </td>
              </tr>
            </tbody>
          </table>
          <InputHousingLoanBody housingSelfOwned={housingSelfOwned!} />
        </AccordionSet>
      </div>
    </>
  )
}

export default InputHousingSelfOwndBody
