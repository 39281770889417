import React, { FC, useEffect, useContext } from 'react'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import FamilyMonochromaticIcon from '@/assets/images/v2/family-monochromatic.svg'
import MarginContainer from '@/components/v2/atoms/MarginContainer'
import Paper from '@/components/v2/atoms/Paper'
import Breadcrumb from '@/components/v2/molecules/Breadcrumb'
import { useDispatch } from 'react-redux'
import { cloneDeep as _cloneDeep, isNull as _isNull } from 'lodash'
import { setPageMeta } from '@/containers/pageMetasSlice'
import PaperTitle from '@/components/v2/molecules/PaperTitle'
import { useParams } from 'react-router-dom'
import { AccountContext } from '@/models/accountsModelFunc'
import MyDataFamilyEditForm from './Form'

const MyDataFamilyEdit: FC = () => {
  const dispatch = useDispatch()
  const { handleMovePage } = useCustomHistory()
  const { isAdmin } = useContext(AccountContext)
  const { account_id } = useParams<{ account_id?: string }>()

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'マイデータ | 家族 編集',
        description: 'マイデータ | 家族 編集',
        keyword: 'マイデータ | 家族 編集',
      }),
    )
  }, [])

  const movePageAfterForm = () => {
    handleMovePage(
      isAdmin ? `/v2/accounts/${account_id}/myData/families` : '/v2/myData/families',
    )
  }

  return (
    <MarginContainer className="px-8 md:px-32 lg:px-0 py-8 md:py-40">
      <Breadcrumb
        className="pb-24 md:pb-32"
        data={[
          {
            name: 'マイデータ',
            url: isAdmin ? `/v2/accounts/${account_id}/myData` : '/v2/myData',
          },
          {
            name: 'マイデータ設定',
            url: isAdmin
              ? `/v2/accounts/${account_id}/myData/families`
              : '/v2/myData/families',
          },
          { name: '家族情報の設定' },
        ]}
      />

      <PaperTitle
        className="mb-[16px] md:mb-[40px]"
        title="家族情報の設定"
        description="日々の収入や支出を登録することで改善案などのご提案が可能になります"
        icon={<FamilyMonochromaticIcon />}
        smallIcon={
          <FamilyMonochromaticIcon className="h-[82px] w-[130px]" viewBox="0 0 117 154" />
        }
      />

      <Paper className="bg-white p-24 md:p-40">
        <MyDataFamilyEditForm onClose={movePageAfterForm} onSubmit={movePageAfterForm} />
      </Paper>
    </MarginContainer>
  )
}

export default MyDataFamilyEdit
