import React, { FC, useState, useContext, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import { isNull as _isNull } from 'lodash'
import Button from '@/components/v2/atoms/Button'
import Typography from '@/components/v2/atoms/Typography'
import AccordionSet from '@/components/v1/molecules/AccordionSet'
import { destroyFutureV2HousingV2 } from '@/containers/futures/futureV2HousingsSlice'
import { HOUSE_TYPE } from '@/models/commonHousingModelFunc'
import { SimulationDetailEditContext } from '@/models/futures/futureBasesModelFunc'
import { FutureV2Housing } from '@/models/futures/futureV2HousingsModel'
import { RootState } from '@/store'
import FutureV2HousingRentBody from '@/templates/v2/simulations/futureV2Housings/FutureV2HousingRentBody'
import FutureV2HousingSelfOwnedBody from '@/templates/v2/simulations/futureV2Housings/FutureV2HousingSelfOwnedBody'
import FutureV2HousingOtherBody from '@/templates/v2/simulations/futureV2Housings/FutureV2HousingOtherBody'
import FutureV2HousingUpdateModalForm from '@/templates/v2/simulations/futureV2Housings/FutureV2HousingModalForm/updateIndex'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import Tooltip from '@/components/v2/atoms/Tooltip'

interface FutureV2HousingBodyProps {
  title?: string
  /** futureV2Housingオブジェクト */
  futureV2Housing: FutureV2Housing | undefined
  /** 対象futureHousingSaleID */
  targetHousingSaleId?: number | undefined
  /** 対象futureLendHousingID */
  targetHousingLendId?: number | undefined
  /** 共通API呼び出しメソッド */
  commonCallApi: () => void
  /** 削除後の処理メソッド */
  afterDestory: () => void
}

const FutureV2HousingBody: FC<FutureV2HousingBodyProps> = (props) => {
  const dispatch = useDispatch()
  const { showFlashMsg } = useFlashAlert()
  const { isManipulatedApproval } = useContext(SimulationDetailEditContext)

  const {
    title,
    futureV2Housing,
    targetHousingSaleId,
    targetHousingLendId,
    commonCallApi,
  } = props

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const { futureMarriage } = useSelector((state: RootState) => state.futureMarriages)
  const { familyInfo } = useSelector((state: RootState) => state.futureBases)
  const personAge = useMemo(() => familyInfo?.person_current_age, [familyInfo])
  const hasOrWillHaveSpouse = useMemo(
    () => familyInfo?.is_married || !_isNull(futureMarriage),
    [familyInfo],
  )

  const handleDestroy = (id: number) => {
    const result = confirm(
      'これ以降の予定が全て削除されます。\n選択した売却予定を削除してもよろしいですか？',
    )
    if (!result) return

    dispatch(
      destroyFutureV2HousingV2(id, () => {
        commonCallApi()
        showFlashMsg('削除しました')
        props.afterDestory()
      }),
    )
  }

  return (
    <div
      className={clsx({ ['m-[25px]']: !targetHousingSaleId })}
      onClick={(event: any) => isModalOpen && event.stopPropagation()}
    >
      <AccordionSet
        title={title || '住み替えについて'}
        titleClassName="text-14"
        defaultClose
        customElement={
          <div className="flex items-center">
            {futureV2Housing?.house_type !== HOUSE_TYPE.own && (
              <Tooltip
                item={
                  <Typography className="fs-12">
                    住み替え先の種別を変更する場合は、一度削除して作り直してください
                  </Typography>
                }
                itemClassName="w-[220px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
              >
                <span>
                  <Button
                    className="mx-[5px]"
                    isDisabled={!isManipulatedApproval}
                    onClick={(event: any) => {
                      event.stopPropagation()
                      setIsModalOpen(true)
                    }}
                  >
                    <i className="ico edit text-[14px] text-black-700" />
                    <Typography className="pl-[5px] text-[14px] text-black-700">
                      編集
                    </Typography>
                  </Button>
                </span>
              </Tooltip>
            )}

            <Button
              className="mx-[5px]"
              isDisabled={!isManipulatedApproval}
              onClick={(event: any) => {
                event.stopPropagation()
                handleDestroy(futureV2Housing!.id!)
              }}
            >
              <i className="ico edit text-[14px] text-black-700" />
              <Typography className="pl-[5px] text-[14px] text-black-700">
                削除
              </Typography>
            </Button>
          </div>
        }
      >
        <div className="bg-white p-[10px]">
          {futureV2Housing?.house_type === HOUSE_TYPE.other && (
            <FutureV2HousingOtherBody futureV2Housing={futureV2Housing} />
          )}
          {futureV2Housing?.house_type === HOUSE_TYPE.rent && (
            <FutureV2HousingRentBody futureV2Housing={futureV2Housing} />
          )}
          {futureV2Housing?.house_type === HOUSE_TYPE.own && (
            <FutureV2HousingSelfOwnedBody
              age={personAge}
              hasSpouse={hasOrWillHaveSpouse}
              futureV2Housing={futureV2Housing!}
              commonCallApi={commonCallApi}
            />
          )}
        </div>
      </AccordionSet>

      {isModalOpen && (
        <FutureV2HousingUpdateModalForm
          age={personAge}
          isOpen={isModalOpen}
          hasSpouse={hasOrWillHaveSpouse}
          futureV2Housing={futureV2Housing!}
          targetHousingSaleId={targetHousingSaleId}
          targetHousingLendId={targetHousingLendId}
          houseType={futureV2Housing!.house_type!}
          commonCallApi={commonCallApi}
          handleClose={() => setIsModalOpen(false)}
        />
      )}
    </div>
  )
}

export default FutureV2HousingBody
