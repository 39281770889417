import React, { FC } from 'react'
import Typography from '../atoms/Typography'
import Button from '../atoms/Button'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import { HANDLER_TYPE, PLAN_STATUS } from '@/constants/formItem/futures/futureBases'
import { MARRIAGE_TYPE } from '@/models/futures/futureMarriagesModelFunc'

interface SimulationListItem {
  /** FutureBase */
  fb: any

  /** 削除モーダルを立ち上げるためのステートの更新関数 */
  setDeleteModal: (args)=> void

  /** メインプランに設定するモーダルを立ち上げるためのステートの更新関数 */
  setMainPlanModal?: (args)=> void


  isDisabledDeleteButton? : boolean

  baseUrl: string

  isForFpPlanList?: boolean

  handlePlanHide?: (args)=> void
  handlePlanPublish?: (args)=> void
  setHideDisabledModal?: (args)=> void
}

const SimulationListItem: FC<SimulationListItem> = (props) => {
  const {
    fb,
    setMainPlanModal,
    setDeleteModal,
    isDisabledDeleteButton,
    baseUrl,
    isForFpPlanList,
    handlePlanHide,
    handlePlanPublish,
    setHideDisabledModal,
  } = props
  const { handleMovePage } = useCustomHistory()
  const details = fb.detail_items
  const isFpCreated = fb.handler_type === HANDLER_TYPE.fp
  const isPublished = fb.plan_status === PLAN_STATUS.published

  return (
    <React.Fragment key={fb.id}>
      <div className="border-black-400 border-[1px] rounded overflow-hidden">
        <div className="flex flex-col gap-y-8 md:flex-row justify-between px-16 py-12 bg-black-100">
          <div className="flex flex-col-reverse items-start gap-y-8 md:flex-row md:items-baseline md:gap-x-12">
            <Typography
              className="leading-normal text-[14px] text-secondary-500 whitespace-nowrap"
              isBold
            >
              <Button
                className="hover:border-b border-secondary-500"
                onClick={() => {
                  handleMovePage(`${baseUrl}/${fb.id}`)
                }}
              >
                {fb.future_name}
              </Button>
            </Typography>

            <div className="flex flex-row gap-x-12">
              {isFpCreated && !isForFpPlanList && (
                <Typography
                  className="leading-normal text-[12px] text-white border-[1px] rounded-2xl bg-secondary-500 px-[12px] py-[2px] whitespace-nowrap"
                  isBold
                >
                  FPからのご提案
                </Typography>
              )}

              {fb.is_main ? (
                <Typography
                  className="leading-normal text-[12px] text-white border-[1px] rounded-2xl bg-tertiary-500 px-[12px] py-[2px] whitespace-nowrap"
                  isBold
                >
                  目標プランに設定中
                </Typography>
              ) : (
                setMainPlanModal && (
                  <Typography
                    className="leading-normal text-[12px] text-tertiary-500 border-[1px] border-tertiary-400 rounded-2xl bg-white px-[12px] py-[2px] whitespace-nowrap"
                    isBold
                  >
                    <Button
                      onClick={() => {
                        setMainPlanModal && setMainPlanModal({
                          isOpen: true,
                          futureBaseId: fb.id,
                        })
                      }}
                    >
                      目標プランに設定する
                    </Button>
                  </Typography>
                )
              )}

              {isForFpPlanList && (
                isPublished ? (
                  <Typography
                    className="leading-normal text-[12px] text-white border-[1px] rounded-2xl bg-secondary-500 px-[12px] py-[2px] whitespace-nowrap"
                    isBold
                  >
                    公開中
                  </Typography>
                ) : (
                  <Typography
                    className="leading-normal text-[12px] text-white border-[1px] rounded-2xl bg-tertiary-500 px-[12px] py-[2px] whitespace-nowrap"
                    isBold
                  >
                    非公開
                  </Typography>
                )
              )}
            </div>
          </div>

          <div className="flex justify-end gap-x-16 md:gap-x-24">
            { isForFpPlanList && (
              isPublished ? (
                fb.is_main ? (
                  <Button onClick={() => { setHideDisabledModal({ isOpen: true }) }} >
                    <Typography
                      className="leading-normal text-[14px] text-black-500 whitespace-nowrap"
                      isBold
                    >
                      非公開にする
                    </Typography>
                  </Button>
                ) : (
                  <Button onClick={() => { handlePlanHide(fb.id) }} >
                    <Typography
                      className="leading-normal text-[14px] text-secondary-500 whitespace-nowrap"
                      isBold
                    >
                      非公開にする
                    </Typography>
                  </Button>
                )
              ) : (
                <Button onClick={() => { handlePlanPublish(fb.id) }} >
                  <Typography
                    className="leading-normal text-[14px] text-secondary-500 whitespace-nowrap"
                    isBold
                  >
                    公開にする
                  </Typography>
                </Button>
              )
            )}
            <Button
              onClick={() => {
                handleMovePage(`${baseUrl}/new?id=${fb.id}`)
              }}
            >
              <Typography
                className="leading-normal text-[14px] text-secondary-500 whitespace-nowrap"
                isBold
              >
                複製する
              </Typography>
            </Button>
            <Button
              onClick={() => {
                setDeleteModal({
                  isOpen: true,
                  canDelete: !fb.is_main,
                  isFpCreated: isFpCreated,
                  futureBaseId: fb.id,
                })
              }}
            >
              <Typography
                className={
                  'leading-normal text-[14px] whitespace-nowrap ' +
                  (fb.is_main || isDisabledDeleteButton || (!isForFpPlanList && isFpCreated) ? 'text-black-500' : 'text-alert-600')
                }
                isBold
              >
                削除する
              </Typography>
            </Button>
          </div>
        </div>

        <div className="flex flex-col md:flex-row md:justify-between">
          <div className="flex flex-wrap items-start gap-10 px-16 py-12">
            {details.has_future_monthly_living_cost_bases && (
              <Typography className="text-[10px] text-black border-black-300 border-[1px] rounded-xl px-[11px] py-[3px] whitespace-nowrap">
                基本生活費の変化 あり
              </Typography>
            )}
            {details.has_future_yearly_living_cost_bases && (
              <Typography className="text-[10px] text-black border-black-300 border-[1px] rounded-xl px-[11px] py-[3px] whitespace-nowrap">
                特別な支出・大きな支出 あり
              </Typography>
            )}
            {details.future_marriage_type === MARRIAGE_TYPE.todo && (
              <Typography className="text-[10px] text-black border-black-300 border-[1px] rounded-xl px-[11px] py-[3px] whitespace-nowrap">
                結婚の予定 あり
              </Typography>
            )}
            {details.future_marriage_type === MARRIAGE_TYPE.not && (
              <Typography className="text-[10px] text-black border-black-300 border-[1px] rounded-xl px-[11px] py-[3px] whitespace-nowrap">
                結婚の予定 なし
              </Typography>
            )}
            {details.future_child_count !== 0 && (
              <Typography className="text-[10px] text-black border-black-300 border-[1px] rounded-xl px-[11px] py-[3px] whitespace-nowrap">
                {`将来のお子様 ${details.future_child_count}人`}
              </Typography>
            )}
            {details.has_future_income_bases_person && (
              <Typography className="text-[10px] text-black border-black-300 border-[1px] rounded-xl px-[11px] py-[3px] whitespace-nowrap">
                本人の収入の変化 あり
              </Typography>
            )}
            {details.has_future_income_bases_spouse && (
              <Typography className="text-[10px] text-black border-black-300 border-[1px] rounded-xl px-[11px] py-[3px] whitespace-nowrap">
                配偶者の収入の変化 あり
              </Typography>
            )}
            {details.future_v2_housing_years.self_owneds_years.map(
              (year: number) => {
                return (
                  <Typography className="text-[10px] text-black border-black-300 border-[1px] rounded-xl px-[11px] py-[3px] whitespace-nowrap">
                    {`住居の購入予定 ${year}年`}
                  </Typography>
                )
              },
            )}
            {details.future_lend_housings_years.map(
              (year: number) => {
                return (
                  <Typography className="text-[10px] text-black border-black-300 border-[1px] rounded-xl px-[11px] py-[3px] whitespace-nowrap">
                    {`住居の賃貸化予定 ${year}年`}
                  </Typography>
                )
              },
            )}
            {details.future_housing_sales_years.map(
              (year: number) => {
                return (
                  <Typography className="text-[10px] text-black border-black-300 border-[1px] rounded-xl px-[11px] py-[3px] whitespace-nowrap">
                    {`住居の売却予定 ${year}年`}
                  </Typography>
                )
              },
            )}
            {details.future_v2_housing_years.rents_years.map(
              (year: number) => {
                return (
                  <Typography className="text-[10px] text-black border-black-300 border-[1px] rounded-xl px-[11px] py-[3px] whitespace-nowrap">
                    {`賃貸へ引っ越し ${year}年`}
                  </Typography>
                )
              },
            )}
            {details.future_car_conditions.map((car: any) => {
              return (
                <Typography className="text-[10px] text-black border-black-300 border-[1px] rounded-xl px-[11px] py-[3px] whitespace-nowrap">
                  {`お車・バイクの予定 ${car.transfer_frequency}年に1回 ${car.start_age}歳から${car.end_age}歳まで`}
                </Typography>
              )
            })}
            {details.future_insurance_count !== 0 && (
              <Typography className="text-[10px] text-black border-black-300 border-[1px] rounded-xl px-[11px] py-[3px] whitespace-nowrap">
                {`保険の加入予定 ${details.future_insurance_count}件`}
              </Typography>
            )}
            {details.future_asset_count !== 0 && (
              <Typography className="text-[10px] text-black border-black-300 border-[1px] rounded-xl px-[11px] py-[3px] whitespace-nowrap">
                {`資産運用の想定 ${details.future_asset_count}件`}
              </Typography>
            )}
            {details.has_future_loan_advances && (
              <Typography className="text-[10px] text-black border-black-300 border-[1px] rounded-xl px-[11px] py-[3px] whitespace-nowrap">
                繰上げ返済予定 あり
              </Typography>
            )}
            {details.future_estate_investment_count !== 0 && (
              <Typography className="text-[10px] text-black border-black-300 border-[1px] rounded-xl px-[11px] py-[3px] whitespace-nowrap">
                {`不動産投資予定 ${details.future_estate_investment_count}件`}
              </Typography>
            )}
            {details.future_estate_investment_sale_count !== 0 && (
              <Typography className="text-[10px] text-black border-black-300 border-[1px] rounded-xl px-[11px] py-[3px] whitespace-nowrap">
                {`不動産投資の売却予定 ${details.future_estate_investment_sale_count}件`}
              </Typography>
            )}
            {details.inflation_rate !== null && (
              <Typography className="text-[10px] text-black border-black-300 border-[1px] rounded-xl px-[11px] py-[3px] whitespace-nowrap">
                {`インフレの想定 ${details.inflation_rate}%増`}
              </Typography>
            )}
            {details.has_future_risks && (
              <Typography className="text-[10px] text-black border-black-300 border-[1px] rounded-xl px-[11px] py-[3px] whitespace-nowrap">
                万が一の試算 あり
              </Typography>
            )}
          </div>
          <div className="flex flex-col justify-center gap-8 py-12 mx-16 md:py-0 md:ml-0 md:my-16 md:pl-16 md:mr-40 border-t md:border-t-0 md:border-l border-black-300">
            <Typography className="leading-normal text-[12px] text-black-900 whitespace-nowrap">
              プランの新規作成日: {fb.created_at}
            </Typography>
            <Typography className="leading-normal text-[12px] text-black-900 whitespace-nowrap">
              ベースのマイデータ: {fb.updated_at} 時点
            </Typography>
          </div>
        </div>
      </div>
    </React.Fragment>
)
                                }
export default SimulationListItem