import ApiClient from '@/api/apiClient'
import {
  AuthenticationResponse,
  AuthenticationCreateValues,
} from '@/models/videoPlatforms/authenticationsModel'

export const postAuthentication = async (values: AuthenticationCreateValues) => {
  return await ApiClient.post(
    '/authentications',
    (response: AuthenticationResponse) => response,
    values,
  )
}
