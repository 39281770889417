import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { unauthorizedCheck } from '../accountsSlice'
import {
  postInputSubIncome,
  patchInputSubIncome,
  deleteInputSubIncome,
} from '../../api/inputs/inputSubIncomes'
import { ApiError } from '../../models/commonsModel'
import {
  InputSubIncome,
  InputSubIncomeCreateValues,
  InputSubIncomeDestroyValues,
  InputSubIncomeUpdateValues,
} from '../../models/inputs/inputSubIncomesModel'
import { AppThunk } from '../../store'

interface InputSubIncomeState {
  inputSubIncomes: InputSubIncome[]
  isLoading: boolean
}

const initialState: InputSubIncomeState = {
  inputSubIncomes: [],
  isLoading: false,
}

const inputSubIncomesSlice = createSlice({
  name: 'inputSubIncomes',
  initialState,
  reducers: {
    setInputSubIncome: (state, action: PayloadAction<InputSubIncome[]>) => {
      state.inputSubIncomes = action.payload

      return state
    },
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    resetInputSubIncomesStore: (state) => {
      state = initialState
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
  },
})

export const {
  setInputSubIncome,
  requestStart,
  resetInputSubIncomesStore,
  requestSuccess,
  requestFailure,
} = inputSubIncomesSlice.actions
export const inputSubIncomesReducer = inputSubIncomesSlice.reducer

export const createInputSubIncome = (
  values: InputSubIncomeCreateValues,
  callback: () => void,
  resolve: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postInputSubIncome(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error, resolve))
    })
}

export const updateInputSubIncome = (
  values: InputSubIncomeUpdateValues,
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchInputSubIncome(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const destroyInputSubIncome = (
  values: { params: InputSubIncomeDestroyValues },
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  deleteInputSubIncome(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}
