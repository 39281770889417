import { times as _times } from 'lodash'

// 時短勤務
const startAge = 2
export const LEAVING_YEARS_CHILD_BECOMES_SELECT_ITEMS = [
  { value: 0, label: '時短勤務を行わない' },
  ..._times(14, (index) => ({
    value: startAge + index,
    label: `末子が${startAge + index}歳になるまで`,
  })),
]
