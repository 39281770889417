import ApiClient from '../apiClient'
import {
  InputInsuranceCreateValues,
  InputInsuranceUpdateValues,
  InputInsuranceDestroyValues,
  InputInsuranceResponse,
  InsurancePaymentSummary,
} from '../../models/inputs/inputInsurancesModel'

export const getInputInsurances = async () => {
  return await ApiClient.get(
    '/input_insurances/index',
    (response: InputInsuranceResponse[]) => response,
  )
}

export const getInsurancePaymentSummary = async () => {
  return await ApiClient.get(
    '/input_insurances/insurance_payment_summary',
    (response: InsurancePaymentSummary) => response,
    undefined,
    false,
    'v2',
  )
}

export const postInputInsurance = async (values: InputInsuranceCreateValues) => {
  return await ApiClient.post(
    '/input_insurances',
    (response: InputInsuranceResponse) => response,
    values,
  )
}

export const patchInputInsurance = async (values: InputInsuranceUpdateValues) => {
  return await ApiClient.patch('/input_insurances', () => {}, values)
}

export const deleteInputInsurance = async (values: {
  params: InputInsuranceDestroyValues
}) => {
  return await ApiClient.delete('/input_insurances', () => {}, values)
}
