import LocalStorage from '@/utils/localStorage'
import { AuthenticationResponse } from '@/models/videoPlatforms/authenticationsModel'

const KEY = 'videoPlatformsAuthHeaders'

export const getStorageVideoPlatformsAuthHeaders = () => {
  return LocalStorage.getItem(KEY)
}

export const setStorageVideoPlatformsAuthHeaders = (response: AuthenticationResponse) => {
  const authHeaders = { Authorization: `Token token=${response.token}` }
  return LocalStorage.setItem(KEY, authHeaders)
}

export const removeStorageVideoPlatformsAuthHeaders = () => {
  LocalStorage.removeItem(KEY)
}
