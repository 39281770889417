import React, { FC } from 'react'
import Paper from '@/components/v2/atoms/Paper'
import Typography from '@/components/v2/atoms/Typography'

interface TitlePaperProps {
  /** クラス名 */
  className?: string
  /** タイトルクラス名 */
  titleClassName?: string
  /** タイトル */
  title: string
  /** 説明 */
  description: string
  /** アイコン */
  icon: JSX.Element
  /** スモールアイコン */
  smallIcon?: JSX.Element
}

const PaperTitle: FC<TitlePaperProps> = (props) => {
  const { className, titleClassName, title, description, icon, smallIcon } = props

  return (
    <Paper className={`${className} bg-secondary-500 py-16 md:py-24 relative`}>
      <div className="flex items-center">
        <div className="flex-auto">
          <div className="flex pb-8 md:pb-16">
            <div className="bg-white h-40 md:h-48 mr-16 rounded-r-8 w-8" />
            <Typography
              className={`${titleClassName} md:bottom-2 md:relative text-[19px] sm:text-24 md:text-32 text-white`}
              isBold
            >
              {title}
            </Typography>
          </div>
          <Typography className="pl-24 text-12 md:text-14 text-white">
            {description}
          </Typography>
        </div>
        <div className="hidden md:block absolute bottom-17 right-20 lg:right-123">
          {icon}
        </div>
        <div className="md:hidden h-[82px] w-[130px]">{smallIcon}</div>
      </div>
    </Paper>
  )
}

export default PaperTitle
