import { createSlice } from '@reduxjs/toolkit'
import { postFpAppointmentReservation } from '@/api/fpAppointmentReservations'
import { unauthorizedCheck } from '@/containers/accountsSlice'
import { ApiError } from '@/models/commonsModel'
import {
  FpAppointmentReservation,
  FpAppointmentReservationCreateValues,
} from '@/models/fpAppointmentReservationsModel'
import { AppThunk } from '@/store'
import { postFpAppointmentReservationMoviePage } from '@/api/videoPlatforms/fpAppointmentReservations'

interface FpAppointmentReservationState {
  fpAppointmentReservations: FpAppointmentReservation | null
  isLoading: boolean
}

const initialState: FpAppointmentReservationState = {
  fpAppointmentReservations: null,
  isLoading: false,
}

const fpAppointmentReservationsSlice = createSlice({
  name: 'fpAppointmentReservations',
  initialState,
  reducers: {
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
  },
})

export const {
  requestStart,
  requestSuccess,
  requestFailure,
} = fpAppointmentReservationsSlice.actions
export const fpAppointmentReservationsReducer = fpAppointmentReservationsSlice.reducer

export const createFpAppointmentReservation = (
  values: FpAppointmentReservationCreateValues,
  callback: () => void,
  resolve: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postFpAppointmentReservation(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error, resolve))
    })
}

export const createFpAppointmentReservationMoviePage = (
  values: FpAppointmentReservationCreateValues,
  callback: () => void,
  resolve: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postFpAppointmentReservationMoviePage(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error, resolve))
    })
}
