import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'react-final-form'
import { isEmpty as _isEmpty, isUndefined as _isUndefined } from 'lodash'
import ChevronRightIcon from '@/assets/images/v2/chevron-right.svg'
import MarginContainer from '@/components/v2/atoms/MarginContainer'
import Heading from '@/components/v2/atoms/Heading'
import Paper from '@/components/v2/atoms/Paper'
import Typography from '@/components/v2/atoms/Typography'
import TextField from '@/components/v2/atoms/TextField'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import FieldBlock from '@/components/v2/molecules/FieldBlock'
import WholeSubmitErrorMessage from '@/components/v2/molecules/WholeSubmitErrorMessage'
import { createAccountPasswordReset } from '@/containers/accountsSlice'
import { removeAllFlashAlerts, setFlashAlert } from '@/containers/flashAlertsSlice'
import { setPageMeta } from '@/containers/pageMetasSlice'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import LogindRedirect from '@/layouts/v2/LogindRedirect'
import { RootState } from '@/store'
import { composeValidators, required, mailAddress } from '@/utils/validate'

const AccountPasswordForgot: FC = () => {
  const dispatch = useDispatch()
  const { handleMovePage } = useCustomHistory()

  const [submitErrors, setSubmitErrors] = useState<any>(undefined)
  const { flashAlertMessageObjects } = useSelector(
    (state: RootState) => state.flashAlerts,
  )

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'パスワードを忘れた方へ',
        description: 'パスワードを忘れた方へ',
        keyword: 'パスワードを忘れた方へ',
      }),
    )
  }, [])

  const showFlashMsg = () => {
    if (!_isEmpty(flashAlertMessageObjects)) {
      dispatch(removeAllFlashAlerts())
    }

    dispatch(
      setFlashAlert({
        flashAlertMessageObjects: [
          { message: 'メールを送信しました', variant: 'success' },
        ],
      }),
    )
  }

  const handleAccountSubmit = async (values: any) => {
    const submitErrors = await new Promise((resolve) =>
      dispatch(createAccountPasswordReset(values, resolve)),
    )

    if (_isUndefined(submitErrors)) {
      handleMovePage('/v2/accounts/password/resetMailSendCompleted')
      showFlashMsg()
    } else {
      setSubmitErrors(submitErrors)
    }
  }

  return (
    <LogindRedirect>
      <MarginContainer className="w-full sm:w-524 mx-auto pt-40 sm:pt-73">
        <Paper className="mx-8 sm:mx-0 px-16 py-24 sm:p-40 space-y-24 bg-white">
          <Heading className="text-center">パスワード再発行</Heading>
          <Typography className="text-14 text-black-700 text-center">
            メールアドレスを入力してください。
          </Typography>
          <Form
            onSubmit={() => {}}
            render={({ handleSubmit, values, errors }) => (
              <form className="space-y-24" onSubmit={handleSubmit}>
                <FieldBlock label="メールアドレス">
                  <TextField
                    type="text"
                    name="account.mail_address"
                    autoComplete="off"
                    placeholder="sample@example.com"
                    isFullWidth
                    validate={composeValidators(required, mailAddress)}
                  />
                </FieldBlock>
                <WholeSubmitErrorMessage submitErrors={submitErrors} />
                <div className="flex justify-center">
                  <ButtonPrimary
                    className="h-64 w-full md:w-188"
                    isDisabled={Object.keys(errors).length > 0}
                    onClick={() => handleAccountSubmit(values)}
                  >
                    <Typography
                      className="bottom-2 flex-auto pl-30 relative text-16 text-white"
                      isBold
                    >
                      送信
                    </Typography>
                    <ChevronRightIcon className="mr-17" />
                  </ButtonPrimary>
                </div>
              </form>
            )}
          />
        </Paper>
      </MarginContainer>
    </LogindRedirect>
  )
}

export default AccountPasswordForgot
