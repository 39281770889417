import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  getCompanyLearningPlatformUsers,
  getLearningPlatformUsers,
} from '@/api/admins/learningPlatformUsers'
import { unauthorizedAdminCheck } from '@/containers/admins/fpAccountsSlice'
import { LearningPlatformUser } from '@/models/admins/learningPlatformUsersModel'
import { AppThunk } from '@/store'
import { sortBy as _sortBy } from 'lodash'

interface LearningPlatformUserState {
  learningPlatformUsers: LearningPlatformUser[]
  companyLearningPlatformUsers: LearningPlatformUser[]
  isLoading: boolean
}

const initialState: LearningPlatformUserState = {
  learningPlatformUsers: [],
  companyLearningPlatformUsers: [],
  isLoading: false,
}

const learningPlatformUsersSlice = createSlice({
  name: 'learningPlatformUsers',
  initialState,
  reducers: {
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    getLearningPlatformUsersSuccess: (
      state,
      action: PayloadAction<LearningPlatformUser[]>,
    ) => {
      state.learningPlatformUsers = _sortBy(action.payload, ['company_id', 'created_at'])
      state.isLoading = false
      return state
    },
    getCompanyLearningPlatformUsersSuccess: (
      state,
      action: PayloadAction<LearningPlatformUser[]>,
    ) => {
      state.companyLearningPlatformUsers = _sortBy(action.payload, [
        'company_id',
        'created_at',
      ])
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
  },
})

export const {
  requestStart,
  requestSuccess,
  getLearningPlatformUsersSuccess,
  getCompanyLearningPlatformUsersSuccess,
  requestFailure,
} = learningPlatformUsersSlice.actions
export const learningPlatformUsersReducer = learningPlatformUsersSlice.reducer

export const fetchLearningPlatformUsers = (): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  getLearningPlatformUsers()
    .then((response) => dispatch(getLearningPlatformUsersSuccess(response)))
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedAdminCheck(error))
    })
}

export const fetchCompanyLearningPlatformUsers = (company_id: number): AppThunk => async (
  dispatch,
) => {
  dispatch(requestStart())
  getCompanyLearningPlatformUsers({ company_id })
    .then((response) => dispatch(getCompanyLearningPlatformUsersSuccess(response)))
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedAdminCheck(error))
    })
}
