import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { find as _find, isUndefined as _isUndefined } from 'lodash'
import Typography from '@/components/v2/atoms/Typography'
import { PERSON_FLG } from '@/models/familyStructuresModelFunc'
import { defaultToEmptyString, convertLocaleString } from '@/models/commonsModelFunc'
import {
  CONTRIBUTION_TYPE,
  convertAverageYieldPercent,
  convertReceivingPensionType,
  RECEIVING_PENSION_TYPE,
} from '@/models/inputs/inputContributionPensionsModelFunc'
import { RootState } from '@/store'
import SimulationAccordion from '@/templates/v2/simulations/_simulationAccordion'
import MyDataPersonContributionPensionEditForm from '@/templates/v2/myData/personContributionPensions/Form'
import Modal from '@/components/v2/atoms/Modal'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import Button from '@/components/v2/atoms/Button'
import SimulationNewButton from '@/templates/v2/simulations/_simulationNewButton'

export const PersonContributionPensionTable: FC<any> = ({ isPerson }) => {
  const personFlg = isPerson ? PERSON_FLG.person : PERSON_FLG.spouse
  const { inputContributionPensions } = useSelector(
    (state: RootState) => state.inputContributionPensions,
  )
  const targetContributionPension = _find(
    inputContributionPensions,
    (item) =>
      item.person_flg === personFlg && item.contribution_type === CONTRIBUTION_TYPE.ideco,
  )

  return (
    <>
      {!_isUndefined(targetContributionPension) ? (
        <table className="w-[100%] text-[12px] md:text-[14px]">
          <tbody>
            <tr className="h-[45px] odd:bg-black-100">
              <td className="w-[30%] px-[16px]">
                <Typography isBold>支払い期間</Typography>
              </td>
              <td className="w-[70%] px-[16px]">
                <Typography>{`${defaultToEmptyString(
                  targetContributionPension?.contribution_period_start_at_age,
                )}歳から${defaultToEmptyString(
                  targetContributionPension?.contribution_period_end_at_age,
                )}歳未満`}</Typography>
              </td>
            </tr>
            <tr className="h-[45px] odd:bg-black-100">
              <td className="px-[16px]">
                <Typography isBold>拠出額</Typography>
              </td>
              <td className="px-[16px]">
                <Typography>{`${convertLocaleString(
                  targetContributionPension?.contribution_amount_monthly,
                )}円/月`}</Typography>
              </td>
            </tr>
            <tr className="h-[45px] odd:bg-black-100">
              <td className="px-[16px]">
                <Typography isBold>評価額</Typography>
              </td>
              <td className="px-[16px]">
                <Typography>{`${convertLocaleString(
                  targetContributionPension?.valuation,
                )}円`}</Typography>
              </td>
            </tr>
            <tr className="h-[45px] odd:bg-black-100">
              <td className="px-[16px]">
                <Typography isBold>平均利回り</Typography>
              </td>
              <td className="px-[16px]">
                <Typography>{`${convertAverageYieldPercent(
                  targetContributionPension?.average_yield_percent,
                )}％`}</Typography>
              </td>
            </tr>
            <tr className="h-[45px] odd:bg-black-100">
              <td className="px-[16px]">
                <Typography isBold>受取方式</Typography>
              </td>
              <td className="px-[16px]">
                <Typography>
                  {convertReceivingPensionType(
                    targetContributionPension?.receiving_pension_type,
                  )}
                </Typography>
              </td>
            </tr>

            {[RECEIVING_PENSION_TYPE.both].includes(
              targetContributionPension?.receiving_pension_type,
            ) && (
              <tr className="h-[45px] odd:bg-black-100">
                <td className="px-[16px]">
                  <Typography isBold>
                    一時金の
                    <br className="block md:hidden" />
                    受取割合
                  </Typography>
                </td>
                <td className="px-[16px]">
                  <Typography>
                    {`${defaultToEmptyString(
                      targetContributionPension?.lump_sum_ratio_percent,
                    )}%`}
                  </Typography>
                </td>
              </tr>
            )}

            {[RECEIVING_PENSION_TYPE.both, RECEIVING_PENSION_TYPE.lump].includes(
              targetContributionPension?.receiving_pension_type,
            ) && (
              <tr className="h-[45px] odd:bg-black-100">
                <td className="px-[16px]">
                  <Typography isBold>受取年齢</Typography>
                </td>
                <td className="px-[16px]">
                  <Typography>
                    {`${defaultToEmptyString(
                      targetContributionPension?.lump_sum_receiving_at_age,
                    )}歳のとき`}
                  </Typography>
                </td>
              </tr>
            )}

            {[RECEIVING_PENSION_TYPE.both, RECEIVING_PENSION_TYPE.pension].includes(
              targetContributionPension?.receiving_pension_type,
            ) && (
              <tr className="h-[45px] odd:bg-black-100">
                <td className="px-[16px]">
                  <Typography isBold>
                    受取開始
                    <br className="block md:hidden" />
                    年齢
                  </Typography>
                </td>
                <td className="px-[16px]">
                  <Typography>
                    {`${defaultToEmptyString(
                      targetContributionPension?.pension_start_at_age,
                    )}歳から${defaultToEmptyString(
                      targetContributionPension?.pension_years,
                    )}年間`}
                  </Typography>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      ) : (
        <Typography className="text-[14px] md:text-[16px] text-center" isBold>
          {`${isPerson ? 'ご本人' : '配偶者'}の個人型確定拠出年金が登録されていません。`}
        </Typography>
      )}
    </>
  )
}
interface InputPersonContributionPensionBodyProps {
  /** true: ご本人, false: 配偶者 */
  personFlg: boolean
  /** 更新時の処理 */
  onUpdate: () => void
}

const InputPersonContributionPensionBody: FC<InputPersonContributionPensionBodyProps> = (
  props,
) => {
  const { personFlg, onUpdate } = props
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const closeModal = () => setIsOpenModal(false)
  const openModal = () => setIsOpenModal(true)

  const { inputContributionPensions } = useSelector(
    (state: RootState) => state.inputContributionPensions,
  )
  const inputedContributionPension =
    inputContributionPensions.find(
      (cp) =>
        Number(!personFlg) === cp.person_flg &&
        cp.contribution_type === CONTRIBUTION_TYPE.ideco,
    ) != null

  return (
    <>
      <SimulationAccordion
        className="pb-[16px]"
        title={`${personFlg ? 'ご本人' : '配偶者'}の個人型確定拠出年金`}
      >
        {inputedContributionPension && (
          <div className="flex justify-end mb-[8px]">
            <Button onClick={openModal}>
              <i className="ico edit text-[14px] text-black-700" />
              <Typography className="pl-[5px] text-[14px] text-black-700">
                編集
              </Typography>
            </Button>
          </div>
        )}
        <PersonContributionPensionTable isPerson={personFlg} />
        {!inputedContributionPension && <SimulationNewButton onClick={openModal} />}
      </SimulationAccordion>

      <Modal className="lg:w-[1024px] w-[95%]" isOpen={isOpenModal} onClose={closeModal}>
        <CircleTitle
          className="pb-[32px]"
          title={`${personFlg ? 'ご本人' : '配偶者'}の個人型確定拠出年金`}
        />
        <MyDataPersonContributionPensionEditForm
          personFlg={personFlg ? PERSON_FLG.person : PERSON_FLG.spouse}
          onClose={closeModal}
          onSubmit={() => {
            closeModal()
            onUpdate()
          }}
        />
      </Modal>
    </>
  )
}

export default InputPersonContributionPensionBody
