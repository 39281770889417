import React, { FC } from 'react'
import Typography from '@/components/v2/atoms/Typography'
import Paper from '@/components/v2/atoms/Paper'
import Heading from '@/components/v2/atoms/Heading'

interface MailSendCompletedProps {
  /** タイトル */
  title: string
  /** 余白サイズ */
  space?: number
}

const MailSendCompleted: FC<MailSendCompletedProps> = (props) => {
  const { title, space, children } = props

  return (
    <Paper className={`mx-8 sm:mx-0 px-16 py-24 sm:p-${space} bg-white`}>
      <Heading className="text-center">{title}</Heading>
      <Typography className="mt-24 sm:text-center text-14 text-black-700">
        {children}
      </Typography>
    </Paper>
  )
}

MailSendCompleted.defaultProps = {
  space: 40,
}

export default MailSendCompleted
