import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import RankingIcon from '@/assets/images/v2/ranking.svg'
import MarginContainer from '@/components/v2/atoms/MarginContainer'
import Paper from '@/components/v2/atoms/Paper'
import Typography from '@/components/v2/atoms/Typography'
import Breadcrumb from '@/components/v2/molecules/Breadcrumb'
import PaperTitle from '@/components/v2/molecules/PaperTitle'
import { setPageMeta } from '@/containers/pageMetasSlice'
import Button from '@/components/v2/atoms/Button'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import { fetchFutureBasesV2, updateMainPlan } from '@/containers/futures/futureBasesSlice'
import { RootState } from '@/store'
import Link from '@/components/v2/atoms/Link'
import Modal from '@/components/v2/atoms/Modal'
import RadioGroup from '@/components/v2/molecules/RadioGroup'
import { Form } from 'react-final-form'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import WholeSubmitErrorMessage from '@/components/v2/molecules/WholeSubmitErrorMessage'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import Chip from '@/components/v2/atoms/Chip'
import LoadingOverlay from '@/components/v1/atoms/LoadingOverlay'
import { HANDLER_TYPE } from '@/constants/formItem/futures/futureBases'
import { sortBy as _sortBy } from 'lodash'
import ChevronRightIcon from '@/assets/images/v2/chevron-right.svg'

const SimulationSetList: FC = () => {
  const dispatch = useDispatch()
  const { handleMovePage } = useCustomHistory()
  const { showFlashMsg } = useFlashAlert()

  const { futureBasesV2 } = useSelector((state: RootState) => state.futureBases)
  const { account } = useSelector((state: RootState) => state.accounts)

  // Loading Flag
  const isLoadingFutureBases = useSelector(
    (state: RootState) => state.futureBases.isLoading,
  )
  const isLoadingAccounts = useSelector((state: RootState) => state.accounts.isLoading)

  const [isMainPlanSettingModalOpen, setIsMainPlanSettingModalOpen] = useState<boolean>(
    false,
  )

  const handleSubmit = (values: any) => {
    dispatch(
      updateMainPlan(values.future_base_id, () => {
        dispatch(fetchFutureBasesV2())
        showFlashMsg('メインプランを更新しました')
        setIsMainPlanSettingModalOpen(false)
      }),
    )
  }

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'シミュレーション',
        description: 'シミュレーション',
        keyword: 'シミュレーション',
      }),
    )

    dispatch(fetchFutureBasesV2())
  }, [])

  return (
    <>
      <LoadingOverlay isLoading={isLoadingFutureBases || isLoadingAccounts} />
      <MarginContainer className="px-[8px] md:px-[32px] lg:px-0 py-[8px] md:py-[40px]">
        <Breadcrumb
          className="pb-[10px] md:pb-[32px]"
          data={[
            { name: 'シミュレーション', url: '/v2/simulations' },
            { name: 'シミュレーション管理' },
          ]}
        />

        <PaperTitle
          className="mb-[16px] md:mb-[40px]"
          title="シミュレーション管理"
          description="様々なシミュレーションをして将来のプランを立てていきましょう"
          icon={<RankingIcon />}
          smallIcon={<RankingIcon className="h-[82px] w-[130px]" viewBox="0 0 212 185" />}
        />

        <Paper className="bg-white p-[24px] md:p-[40px]">
          <div className="md:flex items-center justify-between pb-[20px] border-b-2 border-black-300">
            <Typography
              className="pb-[10px] text-[19px] md:text-[28px] text-black-800"
              isBold
            >
              保存したシミュレーションセット
            </Typography>
            <Button
              className="w-220 h-[26px] px-10 md:h-[37px] mr-[0] md:mr-[9px] rounded-[4px] bg-white border border-secondary-500 hover:bg-secondary-50 ml-auto"
              onClick={() => handleMovePage(`/v2/simulations/new`)}
            >
              <Typography
                className="relative bottom-[1px] text-[12px] md:text-[14px] text-secondary-500"
                isBold
              >
                セットを追加する
              </Typography>
            </Button>
          </div>

          <div className="md:hidden my-[10px] flex justify-end items-center">
            <Typography className="inline-block mx-[10px] text-[12px]">
              メインプランの設定
            </Typography>
            <Typography className="pl-5 text-secondary-500  inline-block top-[3px]">
              <i
                className="ico edit text-20"
                onClick={() => setIsMainPlanSettingModalOpen(true)}
              />
            </Typography>
          </div>

          <div className="block md:hidden">
            {_sortBy(futureBasesV2, (fb) => 0 - fb.id)?.map((item: any) => (
              <div key={item.id} className="border-b py-10">
                <div className="flex justify-between items-baseline">
                  <div className="flex">
                    {item.is_main && (
                      <Chip
                        className="bg-tertiary-500 text-white text-12 mr-[2px] last:0 px-10 py-[4px]"
                        isBold
                      >
                        メイン
                      </Chip>
                    )}

                    {item.handler_type === HANDLER_TYPE.fp && (
                      <Chip
                        className="bg-secondary-500 text-white text-12 mr-[2px] last:0 px-10 py-[4px]"
                        isBold
                      >
                        FPからの提案
                      </Chip>
                    )}
                  </div>

                  <Typography className="text-12">作成日: {item.created_at}</Typography>
                </div>

                <div className="flex">
                  <Typography isBold>{item.future_name || '(名称未設定)'}</Typography>
                </div>

                <div className="text-right">
                  <Link
                    className="text-secondary-500 text-14 flex justify-end stroke-current"
                    onClick={() => handleMovePage(`/v2/simulations/${item.id}`)}
                  >
                    確認する
                    <ChevronRightIcon />
                  </Link>
                </div>
              </div>
            ))}
          </div>

          <table className="hidden md:table w-[100%] text-[12px] md:text-[14px]">
            <tbody>
              <tr className="h-[45px] odd:bg-black-100">
                <th className="px-[16px] md:px-[30px] text-left">
                  <Typography isBold>セット名</Typography>
                </th>
                <th className="px-[16px] md:px-[30px] text-left">
                  <Typography className="inline-block" isBold>
                    ステータス
                  </Typography>

                  <Typography className="pl-5 text-secondary-500  inline-block top-[3px]">
                    <i
                      className="ico edit text-20"
                      onClick={() => setIsMainPlanSettingModalOpen(true)}
                    />
                  </Typography>
                </th>
                <th className="px-[16px] md:px-[30px] text-left">
                  <Typography isBold>作成日</Typography>
                </th>
                <th className="px-[16px] md:px-[30px] text-left"></th>
              </tr>

              {_sortBy(futureBasesV2, (fb) => 0 - fb.id)?.map(
                (item: any, index: number) => (
                  <tr
                    className="h-[45px] odd:bg-black-100"
                    key={`future_base_${item.id}_${index}`}
                  >
                    <td className="w-[40%] px-[16px] md:px-[30px]">
                      <Typography>{item.future_name || '(名称未設定)'}</Typography>
                    </td>

                    <td className="w-[20%] px-[16px] md:px-[30px]">
                      <div className="flex">
                        {item.is_main && (
                          <Chip
                            className="bg-tertiary-500 text-white text-12 mr-[2px] last:0 px-10 py-[4px]"
                            isBold
                          >
                            メイン
                          </Chip>
                        )}

                        {item.handler_type === HANDLER_TYPE.fp && (
                          <Chip
                            className="bg-secondary-500 text-white text-12 mr-[2px] last:0 px-10 py-[4px]"
                            isBold
                          >
                            FPからの提案
                          </Chip>
                        )}

                        {!item.is_main && item.handler_type === HANDLER_TYPE.user && (
                          <Typography>-</Typography>
                        )}
                      </div>
                    </td>

                    <td className="w-[20%] px-[16px] md:px-[30px]">
                      <Typography>{item.created_at}</Typography>
                    </td>

                    <td className="w-[20%] px-[16px] md:px-[30px]">
                      <Link
                        className="text-secondary-500 text-14 flex justify-end stroke-current hover:underline"
                        onClick={() => handleMovePage(`/v2/simulations/${item.id}`)}
                      >
                        確認する
                        <ChevronRightIcon />
                      </Link>
                    </td>
                  </tr>
                ),
              )}
            </tbody>
          </table>
        </Paper>
      </MarginContainer>

      <Modal
        className="w-[95%] md:w-[760px]"
        modalContainerClassName="max-h-[465px]"
        isOpen={isMainPlanSettingModalOpen}
        onClose={() => setIsMainPlanSettingModalOpen(false)}
      >
        <div className="space-y-24">
          <CircleTitle title="メインプランを変更する" />
          <Form
            initialValues={{
              future_base_id: String(account?.main_future_base_id),
            }}
            onSubmit={handleSubmit}
            render={({ handleSubmit, values, errors, submitErrors }) => (
              <form className="space-y-24" onSubmit={handleSubmit}>
                <RadioGroup
                  name="future_base_id"
                  datas={futureBasesV2.map((item: any, index: number) => {
                    return {
                      label: item.future_name || `ID: ${item.id} -（名称未設定）`,
                      value: String(item.id),
                    }
                  })}
                />

                <WholeSubmitErrorMessage submitErrors={submitErrors} />

                <div className="flex flex-col items-center my-30">
                  <ButtonPrimary
                    className="h-45 w-full md:w-215 text-white"
                    isDisabled={Object.keys(errors).length > 0}
                    onClick={() => handleSubmit(values)}
                  >
                    <Typography
                      className="bottom-2 flex-auto pl-30 relative text-16"
                      isBold
                    >
                      保存する
                    </Typography>
                    <i className="ico chevron-right mr-17 text-24" />
                  </ButtonPrimary>
                </div>
              </form>
            )}
          />
        </div>
      </Modal>
    </>
  )
}

export default SimulationSetList
