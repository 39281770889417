import React, { FC } from 'react'
import { LOAN_TYPE2_BUTTON_ITEMS } from '@/constants/formItem/futures/futureHousings'
import { INPUT_LOAN_TYPE2_BUTTON_ITEMS } from '@/constants/formItem/inputs/inputHousings'
import { getTargetSelfOwnedAttributesName } from '@/models/commonHousingModelFunc'
import { required } from '@/utils/validate'

import Typography from '@/components/v2/atoms/Typography'
import SelectBox from '@/components/v2/molecules/SelectBox'

interface LoanSplitProps {
  isFuture?: boolean
}

const LoanSplit: FC<LoanSplitProps> = (props) => {
  const { isFuture } = props
  const targetHousingSelfOwnedAttributesName = getTargetSelfOwnedAttributesName(isFuture)

  const selectItems = isFuture ? LOAN_TYPE2_BUTTON_ITEMS : INPUT_LOAN_TYPE2_BUTTON_ITEMS

  return (
    <>
      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
        <Typography
          className="min-w-[140px] lg:min-w-[210px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
          isBold
        >
          ローンの分割
        </Typography>
        <SelectBox
          name={`${targetHousingSelfOwnedAttributesName}.loan_type2`}
          placeholder="選択する"
          isFullWidth
          options={selectItems}
          validate={required}
        />
      </div>
    </>
  )
}

export default LoanSplit
