import React, { FC, useState, useContext, useEffect, useMemo, createContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Form } from 'react-final-form'
import {
  includes as _includes,
  uniq as _uniq,
  pull as _pull,
  filter as _filter,
  isNull as _isNull,
  isEmpty as _isEmpty,
  find as _find,
  cloneDeep as _cloneDeep,
  isEqual as _isEqual,
} from 'lodash'
import CrossMenu from '@/assets/images/v2/cross-menu.svg'
import Button from '@/components/v2/atoms/Button'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import ButtonBlack from '@/components/v2/atoms/ButtonBlack'
import LoadingOverlay from '@/components/v1/atoms/LoadingOverlay'
import Modal from '@/components/v2/atoms/Modal'
import TextField from '@/components/v2/atoms/TextField'
import Typography from '@/components/v2/atoms/Typography'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import SelectBox from '@/components/v2/molecules/SelectBox'
import Tab from '@/components/v2/molecules/Tab'
import Checkbox from '@/components/v2/atoms/Checkbox'
import {
  createFutureBaseV2,
  createDetailUnsavedFutureBase,
  fetchFutureBaseApplicableLoansUnsavedV2,
  fetchFutureBaseApplicableHousingsUnsavedV2,
  updateFutureBaseV2,
  commonFutureBaseNewApis,
  fetchDetailFutureBaseV2,
} from '@/containers/futures/futureBasesSlice'
import { PLAN_STATUS } from '@/constants/formItem/futures/futureBases'
import { HistoryItem } from '@/hooks/useCustomHistory'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import { AccountContext } from '@/models/accountsModelFunc'
import { isSpouseOptionSelected } from '@/models/commonInsurancesModelFunc'
import FamilyBody from '@/templates/v2/simulations/_familyBody'
import FutureAssetForm from '@/templates/v2/simulations/_futureAssetForm'
import FutureCarForm from '@/templates/v2/simulations/_futureCarForm'
import FutureChildForm from '@/templates/v2/simulations/_futureChildForm'
import FutureEstateInvestmentForm from '@/templates/v2/simulations/_futureEstateInvestmentForm'
import FutureEstateInvestmentSaleForm from '@/templates/v2/simulations/_futureEstateInvestmentSaleForm'
import FutureIncomeForm from '@/templates/v2/simulations/_futureIncomeForm'
import FutureInflationForm from '@/templates/v2/simulations/_futureInflationForm'
import FutureRiskForm from '@/templates/v2/simulations/_futureRiskForm'
import FutureInsuranceForm from '@/templates/v2/simulations/_futureInsuranceForm'
import FutureLoanAdvanceForm from '@/templates/v2/simulations/_futureLoanAdvanceForm'
import FutureMarriageForm from '@/templates/v2/simulations/_futureMarriageForm'
import FutureMonthlyLivingCostForm from '@/templates/v2/simulations/_futureMonthlyLivingCostForm'
import FutureYearlyLivingCostForm from '@/templates/v2/simulations/_futureYearlyLivingCostForm'
import FutureV2HousingForm from '@/templates/v2/simulations/_futureV2HousingForm'
import InputAssetBody from '@/templates/v2/simulations/_inputAssetBody'
// import InputAssetInvestmentBody from '@/templates/v2/simulations/_inputAssetInvestmentBody'
import InputCarBody from '@/templates/v2/simulations/_inputCarBody'
import InputCompanyContributionPensionBody from '@/templates/v2/simulations/_inputCompanyContributionPensionBody'
import InputCorporatePensionBody from '@/templates/v2/simulations/_inputCorporatePensionBody'
import InputCustomItemBody from '@/templates/v2/simulations/_inputCustomItemBody'
import InputEstateInvestmentBody from '@/templates/v2/simulations/_inputEstateInvestmentBody'
import InputHousingBody from '@/templates/v2/simulations/_inputHousingBody'
import InputIncomeBody from '@/templates/v2/simulations/_inputIncomeBody'
import InputInsuranceBody from '@/templates/v2/simulations/_inputInsuranceBody'
import InputMonthlyCostBody from '@/templates/v2/simulations/_inputMonthlyCostBody'
import InputPersonContributionPensionBody from '@/templates/v2/simulations/_inputPersonContributionPensionBody'
import InputPublicPensionBody from '@/templates/v2/simulations/_inputPublicPensionBody'
import InputSalaryBody from '@/templates/v2/simulations/_inputSalaryBody'
import InputTuitionBody from '@/templates/v2/simulations/_inputTuitionBody'
import InputYearlyLivingCostBody from '@/templates/v2/simulations/_inputYearlyLivingCostBody'
import { RootState } from '@/store'
import { required } from '@/utils/validate'
import { fetchAccountShowInput } from '@/containers/accountsSlice'
import InputRiskBody from './_inputRiskBody'

interface SimulationPanelProps {
  /** true: オープン, false: クローズ */
  isOpenRegisterModal: boolean
  /** クローズメソッド */
  onCloseRegisterModal: () => void
  /** 保存される際の名前 */
  futureName: string
  /** 名前の更新メソッド */
  setFutureName: (name: string) => void
  /** 編集、複製時に参照するID */
  futureBaseId: number | null
  /** 保存される際の名前 */
  handleMovePage: (url: string | HistoryItem) => void
  /** 編集処理であるか */
  isEdit?: boolean
  /* 編集を確認したか */
  confirmEdit?: boolean
  /* 編集確認モーダルを展開するメソッド */
  openEditConfirmModal?: () => void
  /* 保存後に移動するパス */
  handleMoveURLAfterSave: string | null
  /* パネル開閉時に実行するメソッド */
  handlePanelOpen?: () => void
}

const SIMULATION_PANEL_TAB_INDEX = {
  simulation: 0,
  myData: 1,
}

export const SimulationAccordionContext = createContext<
  'allOpen' | 'allClose' | 'individual'
>('individual')

const SimulationPanel: FC<SimulationPanelProps> = (props) => {
  const dispatch = useDispatch()
  const { showFlashMsg } = useFlashAlert()
  const { account_id } = useParams<{ account_id?: string }>()

  const { isAdmin } = useContext(AccountContext)

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [selectedSimulationId, setSelectedSimulationId] = useState<any>({})
  const [currentSelectedSimulationId, setCurrentSelectedSimulationId] = useState<any>(null)
  const [selectedPersonIncomeId, setSelectedPersonIncomeId] = useState<any>(null)
  const [selectedSpouseIncomeId, setSelectedSpouseIncomeId] = useState<any>(null)
  const [currentTabIndex, setCurrentTabIndex] = useState<number>(
    SIMULATION_PANEL_TAB_INDEX.simulation,
  )
  const [currentIsLoading, setCurrentIsLoading] = useState<boolean>(false)
  const [wantInitialize, setWantInitialize] = useState<boolean>(true)
  const [shouldInitialize, setShouldInitialize] = useState<boolean>(false)
  const [allAccordionState, setAllAccordionState] = useState<
    'allOpen' | 'allClose' | 'individual'
  >('individual')
  const { isOpenRegisterModal, onCloseRegisterModal, futureBaseId, handlePanelOpen } = props

  const { detailFutureBaseV2, isLoading: isFutureBaseLoading } = useSelector(
    (state: RootState) => state.futureBases,
  )
  const { familyInfoV2 } = useSelector((state: RootState) => state.familyStructures)
  const { isLoading, futureItems, isGetFutureItemsLoading } = useSelector(
    (state: RootState) => state.accounts,
  )
  const isLoadingFamilyStructures = useSelector(
    (state: RootState) => state.familyStructures.isLoading,
  )
  const isConditionKeepMarriage = useMemo(() => {
    if (!selectedSimulationId && !selectedSimulationId.future_marriage_id) return false
    if (!selectedSimulationId.future_insurance_ids) return false

    const appliedConditionArr = selectedSimulationId.future_insurance_ids.map(
      (id: number) => {
        const targetInsurance = _find(
          futureItems?.future_insurances,
          (item) => item.id == id,
        )
        if (!targetInsurance) return false

        return isSpouseOptionSelected(
          targetInsurance.contractor_type,
          targetInsurance.insured_type,
        )
      },
    )

    return _includes(appliedConditionArr, true)
  }, [futureItems, selectedSimulationId])

  const reloadDetailUnsavedFutureBase = () => {
    dispatch(createDetailUnsavedFutureBase({ ...selectedSimulationId }))
    dispatch(fetchFutureBaseApplicableLoansUnsavedV2())
    dispatch(fetchFutureBaseApplicableHousingsUnsavedV2())
  }

  const selectSingleSimulationId = (key: string, id: any) => {
    setSelectedSimulationId(({ [key]: value, ...prev }) => {
      return value === id ? { ...prev } : { [key]: id, ...prev }
    })
  }

  const selectYearLivingCostId = (item: any) => {
    const yearLivingCostId = {
      future_year_living_cost_id: item.id,
      future_yearly_living_cost_ceremony_ids: item.future_yearly_living_cost_ceremonies.map(
        (item: any) => item.id,
      ),
      future_yearly_living_cost_electronic_ids: item.future_yearly_living_cost_electronics.map(
        (item: any) => item.id,
      ),
      future_yearly_living_cost_home_coming_ids: item.future_yearly_living_cost_home_comings.map(
        (item: any) => item.id,
      ),
      future_yearly_living_cost_travel_ids: item.future_yearly_living_cost_travels.map(
        (item: any) => item.id,
      ),
    }

    setSelectedSimulationId(
      ({
        future_year_living_cost_id,
        future_yearly_living_cost_ceremony_ids,
        future_yearly_living_cost_electronic_ids,
        future_yearly_living_cost_home_coming_ids,
        future_yearly_living_cost_travel_ids,
        ...prev
      }: {
        future_year_living_cost_id: number
        future_yearly_living_cost_ceremony_ids: number
        future_yearly_living_cost_electronic_ids: number
        future_yearly_living_cost_home_coming_ids: number
        future_yearly_living_cost_travel_ids: number
      }) => {
        return future_year_living_cost_id === item.id
          ? { ...prev }
          : { ...yearLivingCostId, ...prev }
      },
    )
  }

  const selectIncomeSimulationId = (id: number, isPerson: boolean) => {
    const targetIncomeId = isPerson ? selectedPersonIncomeId : selectedSpouseIncomeId
    const targetSetSelectedIncomeId = isPerson
      ? setSelectedPersonIncomeId
      : setSelectedSpouseIncomeId

    const selectedId = id !== targetIncomeId ? id : null
    const incomeBaseIds = isPerson
      ? [selectedId, selectedSpouseIncomeId]
      : [selectedPersonIncomeId, selectedId]
    const filteredIncomeBaseIds = _filter(incomeBaseIds, (id) => !_isNull(id))

    setSelectedSimulationId(
      ({ future_income_base_ids, ...prev }: { future_income_base_ids: any[] }) => ({
        future_income_base_ids: filteredIncomeBaseIds,
        ...prev,
      }),
    )
    targetSetSelectedIncomeId(selectedId)
  }

  const selectV2HousingOtherId = (
    first_future_v2_housing_id: number,
    future_v2_housing_ids: number[],
    future_lend_housing_ids: number[],
    future_housing_sale_ids: number[],
  ) => {
    setSelectedSimulationId((prev: any) => {
      let newValues = { ...prev }
      if (!newValues.first_future_v2_housing_ids) {
        newValues.first_future_v2_housing_ids = []
      }

      if (newValues.first_future_v2_housing_ids.includes(first_future_v2_housing_id)) {
        newValues.first_future_v2_housing_ids.splice(
          newValues.first_future_v2_housing_ids.indexOf(first_future_v2_housing_id),
          1,
        )

        newValues.future_v2_housing_ids = newValues.future_v2_housing_ids?.reduce(
          (arr: number[], id: number) => {
            if (future_v2_housing_ids?.includes(id)) {
              return arr
            }
            arr.push(id)
            return arr
          },
          [],
        )

        newValues.future_lend_housing_ids = newValues.future_lend_housing_ids?.reduce(
          (arr: number[], id: number) => {
            if (future_lend_housing_ids?.includes(id)) {
              return arr
            }
            arr.push(id)
            return arr
          },
          [],
        )

        newValues.future_housing_sale_ids = newValues.future_housing_sale_ids?.reduce(
          (arr: number[], id: number) => {
            if (future_housing_sale_ids?.includes(id)) {
              return arr
            }
            arr.push(id)
            return arr
          },
          [],
        )
      } else {
        newValues.first_future_v2_housing_ids.push(first_future_v2_housing_id)

        newValues.future_v2_housing_ids = [
          ...(newValues.future_v2_housing_ids || []),
          ...future_v2_housing_ids,
        ]

        newValues.future_lend_housing_ids = [
          ...(newValues.future_lend_housing_ids || []),
          ...future_lend_housing_ids,
        ]

        newValues.future_housing_sale_ids = [
          ...(newValues.future_housing_sale_ids || []),
          ...future_housing_sale_ids,
        ]
      }
      return newValues
    })
  }

  const selectV2HousingFromInputId = (
    first_housing_type: 'FutureLendHousing' | 'FutureV2Housing' | 'FutureHousingSale',
    first_housing_id: number,
    future_v2_housing_ids: number[],
    future_lend_housing_ids: number[],
    future_housing_sale_ids: number[],
  ) => {
    setSelectedSimulationId((prev: any) => {
      let newValues = { ...prev }

      // 同じか 別のが存在している場合は現在のデータを削除
      if (
        !!selectedSimulationId.first_housing_type &&
        !!selectedSimulationId.first_housing_id
      ) {
        newValues.first_housing_type = null
        newValues.first_housing_id = null

        newValues.future_v2_housing_ids = newValues.future_v2_housing_ids?.reduce(
          (arr: number[], id: number) => {
            if (
              newValues.current_future_v2_housing_ids &&
              newValues.current_future_v2_housing_ids.includes(id)
            ) {
              return arr
            }
            arr.push(id)
            return arr
          },
          [],
        )

        newValues.future_lend_housing_ids = newValues.future_lend_housing_ids?.reduce(
          (arr: number[], id: number) => {
            if (
              newValues.current_future_lend_housing_ids &&
              newValues.current_future_lend_housing_ids.includes(id)
            ) {
              return arr
            }
            arr.push(id)
            return arr
          },
          [],
        )

        newValues.future_housing_sale_ids = newValues.future_housing_sale_ids?.reduce(
          (arr: number[], id: number) => {
            if (
              newValues.current_future_housing_sale_ids &&
              newValues.current_future_housing_sale_ids.includes(id)
            ) {
              return arr
            }
            arr.push(id)
            return arr
          },
          [],
        )
      }

      // 別のが存在しているか　存在していない場合は追加
      if (
        !(
          !!selectedSimulationId.first_housing_type &&
          selectedSimulationId.first_housing_type == first_housing_type &&
          !!selectedSimulationId.first_housing_id &&
          selectedSimulationId.first_housing_id == first_housing_id
        ) ||
        (!selectedSimulationId.first_housing_type &&
          !selectedSimulationId.first_housing_id)
      ) {
        newValues.first_housing_type = first_housing_type
        newValues.first_housing_id = first_housing_id

        newValues.future_v2_housing_ids = [
          ...(newValues.future_v2_housing_ids || []),
          ...(future_v2_housing_ids || []),
        ]
        newValues.current_future_v2_housing_ids = [...(future_v2_housing_ids || [])]

        newValues.future_lend_housing_ids = [
          ...(newValues.future_lend_housing_ids || []),
          ...(future_lend_housing_ids || []),
        ]
        newValues.current_future_lend_housing_ids = [...(future_lend_housing_ids || [])]

        newValues.future_housing_sale_ids = [
          ...(newValues.future_housing_sale_ids || []),
          ...(future_housing_sale_ids || []),
        ]
        newValues.current_future_housing_sale_ids = [...(future_housing_sale_ids || [])]
      }

      return newValues
    })
  }

  const selectMultipleSimulationId = (key: string, id: number) => {
    setSelectedSimulationId((prev: any) => {
      const formattedSimulationId = prev[key] || []
      const newSelectedSimulationId = _includes(formattedSimulationId, id)
        ? _pull(formattedSimulationId, id)
        : _uniq([...formattedSimulationId, id])

      return { ...prev, [key]: newSelectedSimulationId }
    })
  }

  const selectedEstateInvestmentTaxSetting = (key: string, value: string) => {
    setSelectedSimulationId((prev: any) => {
      return { ...prev, [key]: value }
    })
  }

  const handleAddSimulationSet = (values: any) => {
    const newValues = { ...selectedSimulationId, ...values }

    if (props.isEdit && props.futureBaseId != null) {
      dispatch(
        updateFutureBaseV2(props.futureBaseId, newValues, () => {
          dispatch(commonFutureBaseNewApis(props.futureBaseId ?? undefined))
          setWantInitialize(true)
          if (props.handleMoveURLAfterSave != null)
            props.handleMovePage(props.handleMoveURLAfterSave)
          showFlashMsg('更新しました')
        }),
      )
      props.onCloseRegisterModal()
    } else {
      dispatch(
        createFutureBaseV2(newValues, (response) => {
          showFlashMsg('登録しました')
          if (props.handleMoveURLAfterSave != null) {
            props.handleMovePage(props.handleMoveURLAfterSave)
          } else {
            props.handleMovePage(
              isAdmin
                ? `/v2/accounts/${account_id}/simulations`
                : `/v2/simulations/${response.id}`,
            )
          }
        }),
      )
    }
  }

  useEffect(() => {
    if (props.confirmEdit == null || props.confirmEdit) {
      if(_isEqual(currentSelectedSimulationId, selectedSimulationId)) return
      reloadDetailUnsavedFutureBase()
      setCurrentSelectedSimulationId(_cloneDeep(selectedSimulationId))
    }
  }, [selectedSimulationId, props.confirmEdit])

  useEffect(() => {
    const doneGetFutureItems = currentIsLoading && !isGetFutureItemsLoading

    if (doneGetFutureItems) {
      if (wantInitialize) {
        setWantInitialize(false)
        setShouldInitialize(true)
        setSelectedSimulationId({})
      } else {
        reloadDetailUnsavedFutureBase()
      }
      setCurrentIsLoading(isGetFutureItemsLoading)
      return
    }

    setShouldInitialize(false)
    setCurrentIsLoading(isGetFutureItemsLoading)
  }, [isGetFutureItemsLoading])

  useEffect(() => {
    dispatch(fetchAccountShowInput())
    if (props.isEdit) {
      dispatch(fetchDetailFutureBaseV2(Number(props.futureBaseId)))
    }
  }, [])

  useEffect(() => {
    if (allAccordionState != 'individual') {
      setAllAccordionState('individual')
    }
  }, [allAccordionState])

  return (
    <>
      <div
        className={
          'flex sticky z-[1000] ' +
          (isOpen ? 'top-[61px] h-0' : 'top-[52px] md:top-[80px]')
        }
      >
        <Button
          className={
            'flex flex-col justify-center w-[36px] md:w-[44px] p-[12px] bg-tertiary-500 absolute rounded-l-xl ' +
            (isOpen ? 'right-[528px]' : 'right-0 top-[16px] md:top-[43px]')
          }
          onClick={() => {
            setIsOpen(!isOpen)
            if(!isOpen) handlePanelOpen && handlePanelOpen()
          }}
        >
          <i className="ico chevron-right text-24 text-white" />
          <Typography
            className="leading-tight text-[12px] md:text-[14px] text-white"
            isBold
          >
            {isOpen ? '設定パネルを閉じる' : '設定パネルを開く'}
          </Typography>
        </Button>

        <div
          className={
            'absolute right-0 h-screen w-full md:w-[528px] p-[10px] md:p-[24px] bg-white shadow-simulation-panel ' +
            (isOpen ? '' : 'hidden')
          }
        >
          <div className="h-[calc(100vh-139px)] overflow-y-scroll">
            <div className="flex md:hidden justify-end">
              <Button
                className="flex px-[12px] py-[5px] gap-[5px] bg-tertiary-500 rounded-[6px]"
                onClick={() => {
                  setIsOpen(false)
                }}
              >
                <i className="ico close text-24 text-white" />
                <Typography
                  className="leading-normal text-[14px] md:text-[14px] text-white"
                  isBold
                >
                  設定パネルを閉じる
                </Typography>
              </Button>
            </div>

            <Tab
              className="h-50 mb-[12px] md:mb-[32px]"
              currentIndex={currentTabIndex}
              options={['シミュレーション', 'マイデータ']}
              onClick={(index) => setCurrentTabIndex(index)}
            />

            <LoadingOverlay
              isLoading={
                isLoading || isLoadingFamilyStructures || isGetFutureItemsLoading
              }
            />

            <div
              className={
                'pb-[32px] ' +
                (currentTabIndex === SIMULATION_PANEL_TAB_INDEX.simulation
                  ? ''
                  : 'hidden')
              }
            >
              <div className="pb-[12px] flex flex-col gap-y-[8px] md:flex-row md:items-center md:justify-between">
                <Typography className="text-[14px] self-start">
                  設定を選択するとグラフ・表へ反映できます
                </Typography>
                <div className="flex gap-x-[10px] self-end">
                  <Button
                    className="px-[8px] py-[4px] border border-secondary-600 border-solid"
                    onClick={() => setAllAccordionState('allOpen')}
                  >
                    <Typography className="text-[12px] text-secondary-600 leading-normal whitespace-nowrap">
                      設定を開く
                    </Typography>
                  </Button>
                  <Button
                    className="px-[8px] py-[4px] border border-secondary-600 border-solid whitespace-nowrap"
                    onClick={() => setAllAccordionState('allClose')}
                  >
                    <Typography className="text-[12px] text-secondary-600 leading-normal">
                      設定を閉じる
                    </Typography>
                  </Button>
                </div>
              </div>
              <SimulationAccordionContext.Provider value={allAccordionState}>
                <FutureMonthlyLivingCostForm
                  selectedSimulationId={
                    selectedSimulationId.future_monthly_living_cost_base_id
                  }
                  futureBaseId={futureBaseId ?? undefined}
                  handleSelect={(item) =>
                    selectSingleSimulationId(
                      'future_monthly_living_cost_base_id',
                      item.id,
                    )
                  }
                  shouldInitialize={shouldInitialize}
                  confirmEdit={props.confirmEdit}
                  openEditConfirmModal={props.openEditConfirmModal}
                />
                <FutureYearlyLivingCostForm
                  selectedSimulationId={selectedSimulationId.future_year_living_cost_id}
                  futureBaseId={futureBaseId ?? undefined}
                  handleSelect={(item) => selectYearLivingCostId(item)}
                  shouldInitialize={shouldInitialize}
                  confirmEdit={props.confirmEdit}
                  openEditConfirmModal={props.openEditConfirmModal}
                />
                {familyInfoV2?.spouse_type == 1 && (
                  <FutureMarriageForm
                    selectedSimulationId={selectedSimulationId.future_marriage_id}
                    futureBaseId={futureBaseId ?? undefined}
                    isConditionKeepSelected={isConditionKeepMarriage}
                    handleSelect={(item) =>
                      selectSingleSimulationId('future_marriage_id', item.id)
                    }
                    shouldInitialize={shouldInitialize}
                    confirmEdit={props.confirmEdit}
                    openEditConfirmModal={props.openEditConfirmModal}
                  />
                )}
                <FutureChildForm
                  selectedSimulationId={selectedSimulationId.future_family_base_id}
                  futureBaseId={futureBaseId ?? undefined}
                  handleSelect={(item) =>
                    selectSingleSimulationId('future_family_base_id', item.id)
                  }
                  shouldInitialize={shouldInitialize}
                  confirmEdit={props.confirmEdit}
                  openEditConfirmModal={props.openEditConfirmModal}
                />
                <FutureIncomeForm
                  title="本人の収入の検討"
                  personFlg={0}
                  selectedSimulationId={selectedPersonIncomeId}
                  futureBaseId={futureBaseId ?? undefined}
                  handleSelect={(item) => selectIncomeSimulationId(item.id, true)}
                  shouldInitialize={shouldInitialize}
                  confirmEdit={props.confirmEdit}
                  openEditConfirmModal={props.openEditConfirmModal}
                />
                {(detailFutureBaseV2?.simulation_info?.has_married ||
                  detailFutureBaseV2?.simulation_info?.has_married_in_future) && (
                  <FutureIncomeForm
                    title="配偶者の収入の検討"
                    personFlg={1}
                    selectedSimulationId={selectedSpouseIncomeId}
                    selectedMarriageId={selectedSimulationId?.future_marriage_id}
                    futureBaseId={futureBaseId ?? undefined}
                    handleSelect={(item) => selectIncomeSimulationId(item.id, false)}
                    shouldInitialize={shouldInitialize}
                    confirmEdit={props.confirmEdit}
                    openEditConfirmModal={props.openEditConfirmModal}
                  />
                )}
                <FutureV2HousingForm
                  selectedSimulationId={{
                    first_housing_type: selectedSimulationId.first_housing_type,
                    first_housing_id: selectedSimulationId.first_housing_id,
                    first_future_v2_housing_ids:
                      selectedSimulationId.first_future_v2_housing_ids,
                  }}
                  selectedSimulationSaleId={
                    selectedSimulationId.future_estate_investment_sale_ids
                  }
                  futureBaseId={props.futureBaseId}
                  shouldInitialize={shouldInitialize}
                  handleSelect={(
                    first_housing_type:
                      | 'FutureLendHousing'
                      | 'FutureV2Housing'
                      | 'FutureHousingSale',
                    first_housing_id: number,
                    future_v2_housing_ids: number[],
                    future_lend_housing_ids: number[],
                    future_housing_sale_ids: number[],
                  ) => {
                    selectV2HousingFromInputId(
                      first_housing_type,
                      first_housing_id,
                      future_v2_housing_ids,
                      future_lend_housing_ids,
                      future_housing_sale_ids,
                    )
                  }}
                  handleOtherSelect={(
                    first_future_v2_housing_id: number,
                    future_v2_housing_ids: number[],
                    future_lend_housing_ids: number[],
                    future_housing_sale_ids: number[],
                  ) => {
                    selectV2HousingOtherId(
                      first_future_v2_housing_id,
                      future_v2_housing_ids,
                      future_lend_housing_ids,
                      future_housing_sale_ids,
                    )
                  }}
                  confirmEdit={props.confirmEdit}
                  openEditConfirmModal={props.openEditConfirmModal}
                />
                <FutureCarForm
                  selectedSimulationId={selectedSimulationId.future_car_ids}
                  futureBaseId={futureBaseId ?? undefined}
                  shouldInitialize={shouldInitialize}
                  handleSelect={(item) =>
                    selectMultipleSimulationId('future_car_ids', item.id)
                  }
                  confirmEdit={props.confirmEdit}
                  openEditConfirmModal={props.openEditConfirmModal}
                />
                <FutureInsuranceForm
                  selectedSimulationId={selectedSimulationId.future_insurance_ids}
                  futureBaseId={futureBaseId ?? undefined}
                  shouldInitialize={shouldInitialize}
                  handleSelect={(item) =>
                    selectMultipleSimulationId('future_insurance_ids', item.id)
                  }
                  confirmEdit={props.confirmEdit}
                  openEditConfirmModal={props.openEditConfirmModal}
                />
                <FutureAssetForm
                  selectedSimulationId={selectedSimulationId.future_asset_ids}
                  futureBaseId={futureBaseId ?? undefined}
                  shouldInitialize={shouldInitialize}
                  handleSelect={(item) =>
                    selectMultipleSimulationId('future_asset_ids', item.id)
                  }
                  confirmEdit={props.confirmEdit}
                  openEditConfirmModal={props.openEditConfirmModal}
                />
                <FutureLoanAdvanceForm
                  selectedSimulationId={selectedSimulationId.future_loan_advance_ids}
                  futureBaseId={futureBaseId ?? undefined}
                  shouldInitialize={shouldInitialize}
                  handleSelect={(item) =>
                    selectMultipleSimulationId('future_loan_advance_ids', item.id)
                  }
                  confirmEdit={props.confirmEdit}
                  openEditConfirmModal={props.openEditConfirmModal}
                />
                <FutureEstateInvestmentForm
                  selectedTaxSettingType={selectedSimulationId.future_estate_investment_tax_setting}
                  handleTaxSettingSelect={(value) =>
                    selectedEstateInvestmentTaxSetting('future_estate_investment_tax_setting', value)
                  }
                  selectedSimulationId={selectedSimulationId.future_estate_investment_ids}
                  selectedSimulationSaleId={
                    selectedSimulationId.future_estate_investment_sale_ids
                  }
                  futureBaseId={futureBaseId ?? undefined}
                  shouldInitialize={shouldInitialize}
                  handleSelect={(item) =>
                    selectMultipleSimulationId('future_estate_investment_ids', item.id)
                  }
                  confirmEdit={props.confirmEdit}
                  openEditConfirmModal={props.openEditConfirmModal}
                />
                <FutureEstateInvestmentSaleForm
                  selectedEstateInvestmentSimulationId={
                    selectedSimulationId.future_estate_investment_ids
                  }
                  selectedLendHousingSimulationId={
                    selectedSimulationId.future_lend_housing_ids
                  }
                  selectedSimulationId={
                    selectedSimulationId.future_estate_investment_sale_ids
                  }
                  futureBaseId={futureBaseId ?? undefined}
                  shouldInitialize={shouldInitialize}
                  handleSelect={(item) =>
                    selectMultipleSimulationId(
                      'future_estate_investment_sale_ids',
                      item.id,
                    )
                  }
                  confirmEdit={props.confirmEdit}
                  openEditConfirmModal={props.openEditConfirmModal}
                />
                <FutureInflationForm
                  selectedSimulationId={selectedSimulationId.future_inflation_id}
                  futureBaseId={futureBaseId ?? undefined}
                  shouldInitialize={shouldInitialize}
                  handleSelect={(item) =>
                    selectSingleSimulationId('future_inflation_id', item.id)
                  }
                  confirmEdit={props.confirmEdit}
                  openEditConfirmModal={props.openEditConfirmModal}
                />
                <FutureRiskForm
                  selectedSimulationId={selectedSimulationId.future_risk_id}
                  futureBaseId={futureBaseId ?? undefined}
                  shouldInitialize={shouldInitialize}
                  handleSelect={(item) =>
                    selectSingleSimulationId('future_risk_id', item.id)
                  }
                  selectedMarriageId={selectedSimulationId?.future_marriage_id}
                  confirmEdit={props.confirmEdit}
                  openEditConfirmModal={props.openEditConfirmModal}
                />
              </SimulationAccordionContext.Provider>
            </div>

            {currentTabIndex === SIMULATION_PANEL_TAB_INDEX.myData && (
              <div className="pb-[32px]">
                <div className="pb-[32px] flex items-center justify-between">
                  <CircleTitle
                    title="マイデータ"
                    tooltipClassName="w-[80px] sm:w-[200px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                    tooltipItem="マイデータで入力している情報が表示されています。"
                  />
                  <Button
                    className="flex md:hidden justify-end"
                    onClick={() => {
                      setIsOpen(false)
                    }}
                  >
                    <CrossMenu />
                  </Button>
                </div>

                <FamilyBody onUpdate={reloadDetailUnsavedFutureBase} />
                <InputTuitionBody onUpdate={reloadDetailUnsavedFutureBase} />
                <InputSalaryBody
                  personFlg={true}
                  onUpdate={reloadDetailUnsavedFutureBase}
                />
                {detailFutureBaseV2?.simulation_info?.has_married && (
                  <InputSalaryBody
                    personFlg={false}
                    onUpdate={reloadDetailUnsavedFutureBase}
                  />
                )}
                <InputIncomeBody
                  personFlg={true}
                  onUpdate={reloadDetailUnsavedFutureBase}
                />
                {detailFutureBaseV2?.simulation_info?.has_married && (
                  <InputIncomeBody
                    personFlg={false}
                    onUpdate={reloadDetailUnsavedFutureBase}
                  />
                )}
                <InputCorporatePensionBody
                  personFlg={true}
                  onUpdate={reloadDetailUnsavedFutureBase}
                />
                {detailFutureBaseV2?.simulation_info?.has_married && (
                  <InputCorporatePensionBody
                    personFlg={false}
                    onUpdate={reloadDetailUnsavedFutureBase}
                  />
                )}
                <InputPersonContributionPensionBody
                  personFlg={true}
                  onUpdate={reloadDetailUnsavedFutureBase}
                />
                {detailFutureBaseV2?.simulation_info?.has_married && (
                  <InputPersonContributionPensionBody
                    personFlg={false}
                    onUpdate={reloadDetailUnsavedFutureBase}
                  />
                )}
                <InputCompanyContributionPensionBody
                  personFlg={true}
                  onUpdate={reloadDetailUnsavedFutureBase}
                />
                {detailFutureBaseV2?.simulation_info?.has_married && (
                  <InputCompanyContributionPensionBody
                    personFlg={false}
                    onUpdate={reloadDetailUnsavedFutureBase}
                  />
                )}
                <InputPublicPensionBody
                  personFlg={true}
                  onUpdate={reloadDetailUnsavedFutureBase}
                />
                {detailFutureBaseV2?.simulation_info?.has_married && (
                  <InputPublicPensionBody
                    personFlg={false}
                    onUpdate={reloadDetailUnsavedFutureBase}
                  />
                )}
                <InputMonthlyCostBody onUpdate={reloadDetailUnsavedFutureBase} />
                <InputYearlyLivingCostBody onUpdate={reloadDetailUnsavedFutureBase} />
                <InputHousingBody onUpdate={reloadDetailUnsavedFutureBase} />
                <InputCarBody onUpdate={reloadDetailUnsavedFutureBase} />
                <InputInsuranceBody onUpdate={reloadDetailUnsavedFutureBase} />
                <InputAssetBody onUpdate={reloadDetailUnsavedFutureBase} />
                {/** MEMO: 新しい金融資産のフォームが正式に反映されるまで一旦コメントアウト */}
                {/* <InputAssetInvestmentBody /> */}
                <InputEstateInvestmentBody onUpdate={reloadDetailUnsavedFutureBase} />
                <InputCustomItemBody onUpdate={reloadDetailUnsavedFutureBase} />
                <InputRiskBody onUpdate={reloadDetailUnsavedFutureBase} />
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        className="lg:w-[620px] w-[95%]"
        isOpen={isOpenRegisterModal}
        isDisableBackdropClick
        isLoading={isFutureBaseLoading}
        onClose={onCloseRegisterModal}
      >
        <CircleTitle className="pb-[25px]" title="プランを保存する" />

        <Form
          onSubmit={() => {}}
          initialValues={{ future_name: props.futureName }}
          render={({ handleSubmit, values, errors }) => (
            <form onSubmit={handleSubmit}>
              <div className="pb-[15px]">
                <div className="md:flex md:items-baseline md:px-[16px] md:gap-x-[19px]">
                  <Typography
                    className="text-14 text-black-900 leading-normal whitespace-nowrap"
                    isBold
                  >
                    プラン名
                  </Typography>
                  <TextField
                    type="text-right"
                    name="future_name"
                    validate={required}
                    isFullWidth
                  />
                </div>

                {isAdmin && (
                  <div className="md:flex md:items-baseline md:px-[16px] md:gap-x-[19px] my-20">
                    <Typography
                      className="text-14 text-black-900 leading-normal whitespace-nowrap"
                      isBold
                    >
                      公開設定
                    </Typography>
                    <SelectBox
                      name="plan_status"
                      placeholder="選択する"
                      isFullWidth
                      options={[
                        { value: PLAN_STATUS.published, label: 'お客様に公開する' },
                        { value: PLAN_STATUS.hide, label: 'お客様に公開しない' },
                      ]}
                      validate={required}
                    />
                  </div>
                )}
              </div>

              {!isAdmin && (
                <Checkbox
                  name="is_main"
                  label={
                    <>
                      <span className="inline-block">目標プランに設定し、</span>
                      <span className="inline-block">今後の生活の基準にする</span>
                    </>
                  }
                  className="md:px-[16px] mb-[30px] text-[14px]"
                  checkboxClassName="w-[20px] h-[20px]"
                />
              )}

              <div className="flex gap-x-[24px] justify-center">
                <ButtonBlack
                  className="w-[188px] h-[45px]"
                  onClick={onCloseRegisterModal}
                >
                  <Typography
                    className="flex-auto pl-30 leading-normal text-[14px] text-black-400 whitespace-nowrap"
                    isBold
                  >
                    戻る
                  </Typography>
                  <i className="ico chevron-right text-24 mr-18 text-black-400" />
                </ButtonBlack>
                <ButtonPrimary
                  className="w-[188px] h-[45px]"
                  isDisabled={Object.keys(errors).length > 0}
                  onClick={() => handleAddSimulationSet(values)}
                >
                  <Typography
                    className="flex-auto pl-30 leading-normal text-[14px] text-white whitespace-nowrap"
                    isBold
                  >
                    保存する
                  </Typography>
                  <i className="ico chevron-right text-24 mr-18 text-white" />
                </ButtonPrimary>
              </div>
            </form>
          )}
        />
      </Modal>
    </>
  )
}

export default SimulationPanel
