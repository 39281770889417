export const LOAN_CALCULATION_FLAT_CHANGE_LAND_FIELDS = [
  'future_v2_housing.future_v2_housing_self_owned_attributes.future_v2_housing_loans_attributes[0]future_v2_housing_loan_calculation_attributes.started_borrowing_amount_manyen',
  'started_borrowing_total_amount_land_manyen',
]

export const LOAN_CALCULATION_FLAT_CHANGE_BUILDING_FIELDS = [
  'future_v2_housing.future_v2_housing_self_owned_attributes.future_v2_housing_loans_attributes[2]future_v2_housing_loan_calculation_attributes.started_borrowing_amount_manyen',
  'started_borrowing_total_amount_building_manyen',
]

export const selectFutureV2LoanCalculationObjectName = (
  objectNum: number,
  name: string,
) => {
  return `future_v2_housing.future_v2_housing_self_owned_attributes.future_v2_housing_loans_attributes[${objectNum}]future_v2_housing_loan_calculation_attributes.${name}`
}
