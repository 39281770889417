import React, { FC, useMemo } from 'react'
import { isNull as _isNull, last as _last } from 'lodash'
import Typography from '@/components/v2/atoms/Typography'
import AccordionSet from '@/components/v1/molecules/AccordionSet'
import {
  convertDateToTwoTypesYearsDateFormat,
  convertLocaleString,
} from '@/models/commonsModelFunc'
import { isSelfRentType } from '@/models/commonHousingModelFunc'
import { convertRentType } from '@/models/inputs/inputHousingRentsModelFunc'
import { InputHousing } from '@/models/inputs/inputHousingsModel'
interface InputHousingRentBodyProps {
  /** 現状住居データ  */
  inputHousing: Partial<InputHousing>
}

const InputHousingRentBody: FC<InputHousingRentBodyProps> = (props) => {
  const { inputHousing } = props

  const housingRent = useMemo(() => _last(inputHousing?.input_housing_rents), [
    inputHousing,
  ])

  return (
    <div className="m-[25px]">
      <AccordionSet titleClassName="text-14" title="現在のご自宅について" defaultClose>
        <Typography styleType="underlinedHeader">基本情報</Typography>
        <table className="w-[100%] mb-[60px] last:mb-0 text-[12px]">
          <tbody>
            <tr className="h-[45px] odd:bg-black-100">
              <td className="w-[40%] px-[16px]">
                <Typography isBold>住居タイプ</Typography>
              </td>
              <td className="w-[60%] px-[16px]">
                <Typography>{convertRentType(housingRent?.rent_type)}</Typography>
              </td>
            </tr>

            <tr className="h-[45px] odd:bg-black-100">
              <td className="w-[40%] px-[16px]">
                <Typography isBold>家賃</Typography>
              </td>
              <td className="w-[60%] px-[16px]">
                <Typography>
                  {' '}
                  {isSelfRentType(housingRent?.rent_type)
                    ? `${convertLocaleString(housingRent?.rent_monthly)}円/月`
                    : `${convertLocaleString(housingRent?.whole_rent_monthly)}円/月`}
                </Typography>
              </td>
            </tr>

            {!isSelfRentType(housingRent?.rent_type) && (
              <tr className="h-[45px] odd:bg-black-100">
                <td className="w-[40%] px-[16px]">
                  <Typography isBold>自分で払う家賃</Typography>
                </td>
                <td className="w-[60%] px-[16px]">
                  <Typography>
                    {`${convertLocaleString(housingRent?.rent_monthly)}円/月`}
                  </Typography>
                </td>
              </tr>
            )}

            <tr className="h-[45px] odd:bg-black-100">
              <td className="w-[40%] px-[16px]">次回更新月</td>
              <td className="w-[60%] px-[16px]">
                {_isNull(housingRent?.next_update_month_at) ? (
                  <Typography className="u-fs14">更新なし</Typography>
                ) : (
                  <Typography className="u-fs14">
                    {convertDateToTwoTypesYearsDateFormat(
                      housingRent?.next_update_month_at,
                      'yearMonthWithJapaneseYear',
                    )}
                  </Typography>
                )}
              </td>
            </tr>

            {!_isNull(housingRent?.next_update_month_at) && (
              <>
                <tr className="h-[45px] odd:bg-black-100">
                  <td className="w-[40%] px-[16px]">次回更新費用</td>
                  <td className="w-[60%] px-[16px]">
                    <Typography className="u-fs14">
                      {isSelfRentType(housingRent?.rent_type)
                        ? `${convertLocaleString(housingRent?.next_update_cost)}円`
                        : `${convertLocaleString(housingRent?.whole_next_update_cost)}円`}
                    </Typography>
                  </td>
                </tr>
                {!isSelfRentType(housingRent?.rent_type) && (
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="w-[40%] px-[16px]">自分で払う更新費用</td>
                    <td className="w-[60%] px-[16px]">
                      <Typography className="u-fs14">
                        {`${convertLocaleString(housingRent?.next_update_cost)}円`}
                      </Typography>
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </table>
      </AccordionSet>
    </div>
  )
}

export default InputHousingRentBody
