import {
  isUndefined as _isUndefined,
  isNaN as _isNaN,
  isNull as _isNull,
  find as _find,
  replace as _replace,
} from 'lodash'
import moment, { DurationInputArg2 } from 'moment'

export const convertNumberOneToKanji = function (year: number | undefined): string {
  return String(year === 1 ? '元' : year)
}

// 今年(西暦)
export const THIS_DATE = moment().format()
export const THIS_YEAR = moment().format('YYYY')
export const THIS_YEAR_NUMBER = Number(moment().format('YYYY'))

// 西暦から和暦を取得
export const selectJapaneseYearFromWesternYear = (year: any) => {
  if (typeof year === 'string') {
    year = Number(year.replace('年', ''))
  }
  if (!year) return
  if (typeof year !== 'number' || Number.isNaN(year)) return
  if (year <= 0) return
  const data = [
    {
      name: '令和',
      startYear: 2018,
    },
    {
      name: '平成',
      startYear: 1988,
    },
    {
      name: '昭和',
      startYear: 1925,
    },
    {
      name: '大正',
      startYear: 1911,
    },
    {
      name: '明治',
      startYear: 1867,
    },
  ]

  const item: any = _find(data, (o) => o.startYear < year)
  return {
    name: item?.name,
    year: year - item?.startYear,
  }
}

export const combineTwoTypesYear = (westernYear: number): string => {
  const japaneseYearObject = selectJapaneseYearFromWesternYear(westernYear)
  return (
    String(westernYear) +
    '年(' +
    japaneseYearObject?.name +
    String(convertNumberOneToKanji(japaneseYearObject?.year)) +
    '年)'
  )
}

// 今月
export const THIS_MONTH = moment().format('MM')
export const THIS_MONTH_NUMBER = Number(moment().format('MM'))

// 今日
export const THIS_DAY = moment().format('DD')
export const THIS_DAY_NUMBER = Number(moment().format('DD'))

// 月
export const MONTH = {
  january: '01',
  february: '02',
  march: '03',
  april: '04',
  may: '05',
  june: '06',
  july: '07',
  august: '08',
  september: '09',
  october: '10',
  november: '11',
  december: '12',
}

// あり・なし、いる・いないなど
export const API_FLAG = {
  on: 0,
  off: 1,
}

// あり・なし、いる・いないなど(Boolean型)
export const API_BOOLEAN_FLAG = {
  on: true,
  off: false,
}

// 都道府県
export const PREFECTURE = {
  hokkaido: 1,
  aomori: 2,
  iwate: 3,
  miyagi: 4,
  akita: 5,
  yamagata: 6,
  fukushima: 7,
  ibaraki: 8,
  tochigi: 9,
  gunma: 10,
  saitama: 11,
  chiba: 12,
  tokyo: 13,
  kanagawa: 14,
  niigata: 15,
  toyama: 16,
  ishikawa: 17,
  fukui: 18,
  yamanashi: 19,
  nagano: 20,
  gifu: 21,
  shizuoka: 22,
  aichi: 23,
  mie: 24,
  shiga: 25,
  kyoto: 26,
  osaka: 27,
  hyogo: 28,
  nara: 29,
  wakayama: 30,
  tottori: 31,
  shimane: 32,
  okayama: 33,
  hiroshima: 34,
  yamaguchi: 35,
  tokushima: 36,
  kagawa: 37,
  ehime: 38,
  kochi: 39,
  fukuoka: 40,
  saga: 41,
  nagasaki: 42,
  kumamoto: 43,
  ooita: 44,
  miyazaki: 45,
  kagoshima: 46,
  okinawa: 47,
}

// 期間
export const TERM = {
  single: 'single',
  this_year: 'this_year',
  past_one_year: 'past_one_year',
  custom_month: 'custom_month',
  custom_date: 'custom_date',
}

export const isEvenNumber = (value: any) => {
  return isNotEmptyValue(value) && Number(value) % 2 === 0
}

export const isNotEmptyValue = (value: any) => {
  return !_isUndefined(value) && !_isNull(value) && value !== ''
}

export const isDateExisted = (strDate: any) => {
  if (!isNotEmptyValue(strDate)) return false
  if (
    !strDate.match(/^\d{4}\/\d{1,2}\/\d{1,2}$/) &&
    !strDate.match(/^\d{4}\-\d{1,2}\-\d{1,2}$/)
  ) {
    return false
  }

  const strYear = strDate.includes('-') ? strDate.split('-')[0] : strDate.split('/')[0]
  const strMonth = strDate.includes('-') ? strDate.split('-')[1] : strDate.split('/')[1]
  const strDay = strDate.includes('-') ? strDate.split('-')[2] : strDate.split('/')[2]
  const date = new Date(Number(strYear), Number(strMonth) - 1, Number(strDay))

  return (
    date.getFullYear() === Number(strYear) &&
    date.getMonth() + 1 === Number(strMonth) &&
    date.getDate() === Number(strDay)
  )
}

export const defaultToEmptyString = (value: any) => {
  return isNotEmptyValue(value) ? value : ''
}

export const convertLocaleString = (value: any) => {
  return isNotEmptyValue(value) ? Number(value).toLocaleString() : ''
}

export const convertFloatLocaleString = (value: any, floatDigits: number = 0) => {
  if (typeof value != 'number' || !isNotEmptyValue(value)) return ''

  const num = Number(value)
  if (num < 1 && num > -1 && num != 0) return value.toFixed(floatDigits)

  return Number(value).toLocaleString()
}

export const convertYenToManyen = (
  value: any,
  type: 'round' | 'floor' | 'ceil' = 'round',
) => {
  if (!isNotEmptyValue(value)) return 0

  switch (type) {
    case 'round':
      return Math.round(value / 10000).toLocaleString()
    case 'floor':
      return Math.floor(value / 10000).toLocaleString()
    case 'ceil':
      return Math.ceil(value / 10000).toLocaleString()
    default:
      return 0
  }
}

export const convertNumberYenToManyen = (value: any) => {
  return isNotEmptyValue(value) ? Math.round(value / 10000) : 0
}

export const convertNumberYenToManyenFloor = (value: any) => {
  return isNotEmptyValue(value) ? Math.floor(value / 10000) : 0
}

export const convertYenToDecimalPointManyen = (value: any, digit: number) => {
  return isNotEmptyValue(value)
    ? Number((value / 10000).toFixed(digit)).toLocaleString()
    : ''
}

export const convertManyenToYen = (value: any) => {
  return isNotEmptyValue(value) ? Math.round(Number(value) * 10000) : 0
}

export const convertMathFloor = (value: any) => {
  return isNotEmptyValue(value) ? Math.floor(value) : 0
}

export const convertMomentDate = (date: any, format: string) => {
  return isNotEmptyValue(date) ? moment(date).format(format) : ''
}
export const convertDateYear = (year: any) => {
  return isNotEmptyValue(year) ? `${year}/01/01` : ''
}

export const convertDateMonth = (year: any, month: any) => {
  return isNotEmptyValue(year) && isNotEmptyValue(month) ? `${year}/${month}/01` : ''
}

export const convertDateDay = (year: any, month: any, day: any) => {
  const newDay = String(day).length < 2 ? '0' + String(day) : day
  return isNotEmptyValue(year) && isNotEmptyValue(month) && isNotEmptyValue(newDay)
    ? `${year}/${month}/${newDay}`
    : ''
}

export const convertDateToJapaneseFormat = (
  date: any,
  format: 'fullDate' | 'yearMonth' | 'monthDay' | 'month' | 'year' | 'day',
) => {
  if (!isNotEmptyValue(date)) return ''

  const yearNumber = defaultToEmptyString(date).includes('-')
    ? date.split('-')[0]
    : date.split('/')[0]
  const monthNumber = defaultToEmptyString(date).includes('-')
    ? date.split('-')[1]
    : date.split('/')[1]
  const dayNumber = defaultToEmptyString(date).includes('-')
    ? date.split('-')[2]
    : date.split('/')[2]

  switch (format) {
    case 'fullDate':
      return `${yearNumber}年${monthNumber}月${dayNumber}日`
    case 'yearMonth':
      return `${yearNumber}年${monthNumber}月`
    case 'monthDay':
      return `${monthNumber}月${dayNumber}日`
    case 'year':
      return `${yearNumber}年`
    case 'month':
      return `${monthNumber}月`
    case 'day':
      return `${dayNumber}日`
  }
}
export const convertDateToTwoTypesYearsDateFormat = (
  date: any,
  format:
    | 'fullDateWithJapaneseYear'
    | 'yearMonthWithJapaneseYear'
    | 'japaneseYear'
    | 'fullDate'
    | 'yearMonth'
    | 'monthDay'
    | 'month'
    | 'year'
    | 'day',
) => {
  if (!isNotEmptyValue(date)) return ''

  const dateString = defaultToEmptyString(date)

  const yearNumber = dateString.includes('-') ? date.split('-')[0] : date.split('/')[0]
  const monthNumber = dateString.includes('-') ? date.split('-')[1] : date.split('/')[1]
  const dayNumber = dateString.includes('-') ? date.split('-')[2] : date.split('/')[2]
  const japaneseYearObject = selectJapaneseYearFromWesternYear(yearNumber)
  switch (format) {
    case 'fullDateWithJapaneseYear':
      return `${yearNumber}年(${String(japaneseYearObject?.name)}${String(
        japaneseYearObject?.year,
      )}年)${monthNumber}月${dayNumber}日`
    case 'yearMonthWithJapaneseYear':
      return `${yearNumber}年(${String(japaneseYearObject?.name)}${String(
        japaneseYearObject?.year,
      )}年)${monthNumber}月`
    case 'japaneseYear':
      return `${yearNumber}年(${String(japaneseYearObject?.name)}${String(
        japaneseYearObject?.year,
      )}年)`
    case 'fullDate':
      return `${yearNumber}年${monthNumber}月${dayNumber}日`
    case 'yearMonth':
      return `${yearNumber}年${monthNumber}月`
    case 'monthDay':
      return `${monthNumber}月${dayNumber}日`
    case 'year':
      return `${yearNumber}年`
    case 'month':
      return `${monthNumber}月`
    case 'day':
      return `${dayNumber}日`
  }
}

export const convertCheckboxValue = (value: any, checkValue: any, notCheckValue: any) => {
  if (_isUndefined(value)) return notCheckValue
  return value.length > 0 ? checkValue : notCheckValue
}

export const extractYearOrMonthFromDate = (date: any, yearOrMonth: 'year' | 'month') => {
  const dateString = defaultToEmptyString(date)
  if (yearOrMonth === 'year' && dateString.includes('-')) return date.split('-')[0]
  if (yearOrMonth === 'year' && dateString.includes('/')) return date.split('/')[0]
  if (yearOrMonth === 'month' && dateString.includes('-')) return date.split('-')[1]
  if (yearOrMonth === 'month' && dateString.includes('/')) return date.split('/')[1]
  return ''
}

export const extractDayFromDate = (date: any) => {
  const dateString = defaultToEmptyString(date)
  if (dateString.includes('-')) return date.split('-')[2]
  if (dateString.includes('/')) return date.split('/')[2]
  return ''
}

export const addZeroToStringMonth = (month: number | null) => {
  const stringMonth = convertLocaleString(month)
  if (stringMonth.length < 2) return '0' + stringMonth
  return stringMonth
}

export const removeZeroToNumberDay = (day: number | string | null) => {
  const stringDay = convertLocaleString(day)
  if (stringDay.length === 2 && stringDay.includes('0'))
    return castNumber(stringDay.split('0')[1])
  return castNumber(stringDay)
}

export const addMomentDate = (
  date: string,
  addNumber: number | undefined | null,
  unit: DurationInputArg2,
  format: string,
) => {
  return isNotEmptyValue(addNumber)
    ? moment(date).add(addNumber, unit).format(format)
    : ''
}

export const subtractMomentDate = (
  date: string,
  subtractNumber: number | undefined | null,
  unit: DurationInputArg2,
  format: string,
) => {
  return isNotEmptyValue(subtractNumber)
    ? moment(date).subtract(subtractNumber, unit).format(format)
    : ''
}

export const compareDiffMomentDate = (
  dateFrom: string | null,
  dateTo: string | null,
  unit: DurationInputArg2,
) => {
  return isNotEmptyValue(dateFrom) && isNotEmptyValue(dateTo)
    ? moment(dateFrom).diff(dateTo, unit)
    : ''
}

export const castNumber = (value: any) => {
  const castValue = Number(value)
  return !_isNaN(castValue) ? castValue : 0
}

export const excludeCommaFromNumber = (value: any) => {
  const excludedCommaNum = String(value).includes(',')
    ? String(value).replace(/,/g, '')
    : value
  return isNotEmptyValue(excludedCommaNum) ? excludedCommaNum : ''
}

export const castNumberWithoutComma = (value: any) => {
  const castValue = Number(excludeCommaFromNumber(value))
  return !_isNaN(castValue) ? castValue : 0
}

export const excludeExceptNumber = (value: any) => {
  let newValue = _replace(value, /．/g, '.') // MEMO: 全角のカンマがある場合は、半角カンマに変換
  newValue = _replace(newValue, /[０-９]/g, (v) =>
    String.fromCharCode(v.charCodeAt(0) - 0xfee0),
  )
  const replacedValue = _replace(String(value), /[^0-9.-]/g, '')
  return isNotEmptyValue(replacedValue) ? replacedValue : ''
}

// NumberFieldのパラメータを送信・調整するときに使用する
export const castNumberWithoutOperator = (values: any) => {
  const castValue = Number(excludeExceptNumber(values))
  return !_isNaN(castValue) ? castValue : 0
}

import { PREFECTURE_SELECT_ITEMS } from '@/constants/formItem/commons'

export const convertPrefectures = (value: number | null) => {
  const label = _find(PREFECTURE_SELECT_ITEMS, (item) => item.value === value)?.label
  return defaultToEmptyString(label)
}

export const formatPostalCode = (value: string | null | undefined) => {
  return isNotEmptyValue(value) ? `${value?.slice(0, 3)}-${value?.slice(3, 7)}` : ''
}

export const calcPassedYearTillCurrentYear = (year: any) => {
  return isNotEmptyValue(year) ? THIS_YEAR_NUMBER - Number(year) : ''
}

export const convertObjectToValueLabelObj = (obj: { [key: string]: any }) =>
  Object.keys(obj).map((key, index) => {
    return {
      value: key,
      label: obj[key],
    }
  })

export const convertToTwoDecimalNumber = (value: number | string | null | undefined) => {
  if (typeof value == 'number') return Math.floor((value / 10000) * 10) / 10
  if (typeof value == 'string') return Math.floor((Number(value) / 10000) * 10) / 10
  return
}

export const hasPassedOneHour = (pastTime: string | null) => {
  if (!isNotEmptyValue(pastTime)) return

  return moment(pastTime).diff(moment(), 'hours') <= -1
}

export const termLabel = (term: string | undefined) => {
  return term
    ? ({
        single: '当月',
        this_year: '年間',
        past_one_year: '年間',
        custom_month: '単月',
        custom_date: '期間内',
      } as { [key: string]: string })[term] || ''
    : ''
}

export const comparingTermLabel = (term: string | undefined) => {
  return term
    ? ({
        single: '前月比',
        this_year: '前年比',
        past_one_year: '前年比',
        custom_month: '前月比',
        custom_date: '前期間比',
      } as { [key: string]: string })[term] || ''
    : ''
}

export const convertCostTypeToJp = (costType: string | undefined) => {
  if (costType === 'cremation') return '火葬式'
  if (costType === 'domestic') return '家族葬'
  if (costType === 'average') return '人並み'
  if (costType === 'plenty') return 'とことん'
  if (costType === 'custom') return 'カスタム'

  // 葬儀費用が未入力の場合は日本語を返さない
  return ''
}

// 保険グラフ読み込み設定対象
export const INSURANCE_GRAPH_SET_TYPE = {
  person: 'person',
  spouse: 'spouse',
}

// 保険グラフ 金融資産設定
export const INSURANCE_FINANCIAL_TYPE = {
  include: 'include',
  not_include: 'not_include',
}
