import React, { FC } from 'react'
import { useField, useForm } from 'react-final-form'
import {
  ESTATE_INVESTMENT_SPAN_SELECT_ITEMS,
  RENEWAL_FEE_TYPE_RADIO_ITEMS,
} from '@/constants/formItem/commonEstateInvestment'
import { RENEWAL_FEE_INPUT_TYPE } from '@/models/commonEstateInvestmentModelFunc'
import {
  API_FLAG,
  castNumberWithoutOperator,
  convertLocaleString,
} from '@/models/commonsModelFunc'
import {
  commaNumberMaxLength,
  composeValidators,
  required,
  zeroOrMoreNumber,
} from '@/utils/validate'

import Typography from '@/components/v2/atoms/Typography'
import SelectBox from '@/components/v2/molecules/SelectBox'
import NumberField from '@/components/v2/molecules/NumberField'

const RenewalFee: FC = () => {
  const { change } = useForm()
  const {
    input: { value: renewalFeeType },
  } = useField('renewal_fee_type')
  const {
    input: { value: renewalFeeInputType },
  } = useField('renewal_fee_input_type')
  const {
    input: { value: rentIncomeMonthly },
  } = useField('future_lend_housing.rent_income_monthly')

  return (
    <>
      <div className="pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
        <Typography
          className="min-w-[140px] lg:min-w-[164px] pb-[10px] text-[14px] text-black-800"
          isBold
        >
          礼金・更新料設定
        </Typography>
        <SelectBox
          name="renewal_fee_type"
          placeholder="選択する"
          isFullWidth
          options={RENEWAL_FEE_TYPE_RADIO_ITEMS}
          validate={required}
          onChange={(event: any) =>
            change('renewal_fee_type', Number(event.target.value))
          }
        />
      </div>

      {renewalFeeType === API_FLAG.on && (
        <>
          <div className="pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
            <Typography
              className="min-w-[140px] lg:min-w-[164px] pb-[10px] text-[14px] text-black-800"
              isBold
            >
              礼金・更新料１回あたりの金額
            </Typography>
            <SelectBox
              name="renewal_fee_input_type"
              placeholder="選択する"
              isFullWidth
              options={[
                { value: RENEWAL_FEE_INPUT_TYPE.yen, label: '金額を直接入力' },
                {
                  value: RENEWAL_FEE_INPUT_TYPE.month,
                  label: '家賃に対する比率で入力',
                },
              ]}
              validate={required}
              onChange={(event: any) =>
                change('renewal_fee_input_type', Number(event.target.value))
              }
            />
            {Number(renewalFeeInputType) === RENEWAL_FEE_INPUT_TYPE.yen && (
              <div className="flex flex-auto items-baseline mt-[10px]">
                <NumberField
                  isFullWidth
                  name="future_lend_housing.renewal_fee"
                  validate={
                    Number(renewalFeeInputType) === RENEWAL_FEE_INPUT_TYPE.yen
                      ? composeValidators(required, zeroOrMoreNumber, (value: any) =>
                          commaNumberMaxLength(value, 10),
                        )
                      : undefined
                  }
                  disabled={Number(renewalFeeInputType) !== RENEWAL_FEE_INPUT_TYPE.yen}
                  onChange={() =>
                    change('renewal_fee_input_type', RENEWAL_FEE_INPUT_TYPE.yen)
                  }
                />
                <Typography className="pl-[8px] text-[14px] text-black-800" isBold>
                  円
                </Typography>
              </div>
            )}
            {Number(renewalFeeInputType) === RENEWAL_FEE_INPUT_TYPE.month && (
              <div className="flex flex-auto items-baseline mt-[10px]">
                <Typography
                  className="pr-[8px] text-[14px] text-black-800 min-w-[140px]"
                  isBold
                >
                  {`家賃 ${convertLocaleString(
                    castNumberWithoutOperator(rentIncomeMonthly),
                  )}円の`}
                </Typography>
                <NumberField
                  isFullWidth
                  name="future_lend_housing.renewal_fee_to_rent_income_rate"
                  validate={
                    Number(renewalFeeInputType) === RENEWAL_FEE_INPUT_TYPE.month
                      ? composeValidators(required, zeroOrMoreNumber)
                      : undefined
                  }
                  disabled={Number(renewalFeeInputType) !== RENEWAL_FEE_INPUT_TYPE.month}
                  onChange={() =>
                    change('renewal_fee_input_type', RENEWAL_FEE_INPUT_TYPE.month)
                  }
                />
                <Typography
                  className="pl-[8px] text-[14px] text-black-800 min-w-[50px]"
                  isBold
                >
                  ヶ月分
                </Typography>
              </div>
            )}
          </div>

          <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
            <Typography
              className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
              isBold
            >
              頻度の設定
            </Typography>
            <div className="flex flex-auto items-baseline">
              <SelectBox
                name="future_lend_housing.renewal_fee_period_months"
                placeholder="選択する"
                isFullWidth
                options={ESTATE_INVESTMENT_SPAN_SELECT_ITEMS}
                validate={required}
              />
              <Typography
                className="pl-[8px] text-[14px] text-black-800 min-w-[100px]"
                isBold
              >
                ヶ月に1回
              </Typography>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default RenewalFee
