import React, { FC } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Close as CloseIcon } from '@material-ui/icons'
import Dialog, { DialogProps } from '@/components/v1/atoms/Dialog'
import IconButton from '@/components/v1/atoms/IconButton'

interface ModalProps extends DialogProps {
  /** onCloseイベントメソッド */
  onClose: () => void
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      margin: '0',
      padding: '30px',
    },
    closeButton: {
      position: 'absolute',
      right: '8px',
      top: '8px',
    },
  }),
)

const Modal: FC<ModalProps> = (props) => {
  const classes = useStyles()
  const { onClose, children, ...dialogProps } = props

  return (
    <Dialog onClose={onClose} {...dialogProps}>
      <div className={classes.root}>{children}</div>
      <IconButton
        className={classes.closeButton}
        children={<CloseIcon />}
        onClick={onClose}
      />
    </Dialog>
  )
}

export default Modal
