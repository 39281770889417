import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { unauthorizedCheck } from '../accountsSlice'
import { getInputSimpleDataV2, postInputSimpleDataV2 } from '@/api/inputs/inputSimpleData'
import { ApiError } from '@/models/commonsModel'
import { InputSimpleData } from '@/models/inputs/inputSimpleDataModel'
import { AppThunk } from '@/store'

interface InputSimpleDataState {
  inputSimpleData: InputSimpleData | null
  isLoading: boolean
}

const initialState: InputSimpleDataState = {
  inputSimpleData: null,
  isLoading: false,
}

const inputSimpleDatasSlice = createSlice({
  name: 'inputSimpleData',
  initialState,
  reducers: {
    setInputSimpleData: (state, action: PayloadAction<InputSimpleData>) => {
      state.inputSimpleData = action.payload
      return state
    },
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    resetInputSimpleDataStore: (state) => {
      state = initialState
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    getInputSimpleDatasSuccess: (state, action: PayloadAction<InputSimpleData>) => {
      state.inputSimpleData = action.payload
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
  },
})

export const {
  setInputSimpleData,
  requestStart,
  resetInputSimpleDataStore,
  requestSuccess,
  getInputSimpleDatasSuccess,
  requestFailure,
} = inputSimpleDatasSlice.actions
export const inputSimpleDatasReducer = inputSimpleDatasSlice.reducer

export const fetchInputSimpleDataV2 = (): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  getInputSimpleDataV2()
    .then((response) => dispatch(getInputSimpleDatasSuccess(response)))
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const createInputSimpleDataV2 = (
  values: { input_simple_data: InputSimpleData },
  callback: () => void,
  resolve?: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postInputSimpleDataV2(values)
    .then(() => {
      dispatch(requestSuccess())
      callback && callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error, resolve))
    })
}
