import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { unauthorizedCheck } from '../accountsSlice'
import {
  getInputYearCost,
  postInputYearCost,
  patchInputYearCost,
  deleteInputYearCost,
} from '../../api/inputs/inputYearCosts'
import { ApiError } from '../../models/commonsModel'
import {
  InputYearCost,
  InputYearCostCreateValues,
  InputYearCostUpdateValues,
  InputYearCostDestroyValues,
} from '../../models/inputs/inputYearCostsModel'
import { AppThunk } from '../../store'

interface InputYearCostState {
  inputYearCost: InputYearCost | null
  isLoading: boolean
}

const initialState: InputYearCostState = {
  inputYearCost: null,
  isLoading: false,
}

const inputYearCostsSlice = createSlice({
  name: 'inputYearCosts',
  initialState,
  reducers: {
    setInputYearCosts: (state, action: PayloadAction<InputYearCost | null>) => {
      state.inputYearCost = action.payload
      return state
    },
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    resetInputYearCostsStore: (state) => {
      state = initialState
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    getInputYearCostSuccess: (state, action: PayloadAction<InputYearCost[]>) => {
      state.inputYearCost = action.payload?.[0] ?? null
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
  },
})

export const {
  setInputYearCosts,
  requestStart,
  resetInputYearCostsStore,
  requestSuccess,
  getInputYearCostSuccess,
  requestFailure,
} = inputYearCostsSlice.actions
export const inputYearCostsReducer = inputYearCostsSlice.reducer

export const fetchInputYearCost = (): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  getInputYearCost()
    .then((response) => dispatch(getInputYearCostSuccess(response)))
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const createInputYearCost = (
  values: InputYearCostCreateValues,
  callback: () => void,
  resolve: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postInputYearCost(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
      resolve({})
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error, resolve))
    })
}

export const updateInputYearCost = (
  values: InputYearCostUpdateValues,
  callback: () => void,
  resolve: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchInputYearCost(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error, resolve))
    })
}

export const destroyInputYearCost = (
  values: { params: InputYearCostDestroyValues },
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  deleteInputYearCost(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}
