import React, { FC, useContext } from 'react'
import clsx from 'clsx'
import { isEmpty as _isEmpty } from 'lodash'
import Button from '@/components/v2/atoms/Button'
import Tooltip from '@/components/v1/atoms/TooltipMui'
import Typography from '@/components/v2/atoms/Typography'
import { API_FLAG, isNotEmptyValue } from '@/models/commonsModelFunc'
import { SimulationDetailEditContext } from '@/models/futures/futureBasesModelFunc'
import { convertLandOrBuildingLoanTitle } from '@/models/futures/futureV2HousingsModelFunc'
import { isSplitedLoan } from '@/models/futures/futureV2HousingSelfOwnedModelFunc'
import {
  isHousingType2Custom,
  convertLoanType2,
  convertLoanType,
  convertLoanLender,
  isWithCalcLoan,
  convertDeductionType,
} from '@/models/commonHousingModelFunc'
import { FutureV2HousingSelfOwned } from '@/models/futures/futureV2HousingSelfOwnedModel'
import {
  isOneLoanPattern,
  isTwoLoanPattern,
} from '@/models/futures/futureV2HousingLoansModelFunc'
import FutureV2HousingLoanCalculationBody from '@/templates/v2/simulations/futureV2Housings/FutureV2HousingLoanCalculationBody'

interface FutureV2HousingSelfOwnedCostTableProps {
  /** アイテム */
  housingSelfOwned: any
  /** td要素のクラス */
  tdClassName?: string
}

export const FutureV2HousingLoanTable: FC<FutureV2HousingSelfOwnedCostTableProps> = ({
  housingSelfOwned,
  tdClassName,
}) => {
  return (
    <table className="w-[100%] mb-[60px] last:mb-0 text-[12px] cursor-pointer">
      <tbody>
        {_isEmpty(housingSelfOwned?.future_v2_housing_loans) ? (
          <tr className="h-[45px] odd:bg-black-100">
            <td className={`${tdClassName ?? 'w-[50%]'} px-[16px] md:px-[16px]`}>
              <Typography isBold>ローン有無</Typography>
            </td>
            <td className="px-[16px] md:px-[16px]">
              <Typography className="">借りていない</Typography>
            </td>
          </tr>
        ) : (
          <>
            <tr className="h-[45px] odd:bg-black-100">
              <td className={`${tdClassName ?? 'w-[50%]'} px-[16px] md:px-[16px]`}>
                <Typography isBold>子育て世帯等に対する控除の有無</Typography>
              </td>
              <td className="px-[16px] md:px-[16px]">
                <Typography className="">
                  {convertDeductionType(housingSelfOwned?.child_rearing_deduction)}
                </Typography>
              </td>
            </tr>
            {isHousingType2Custom(housingSelfOwned?.housing_type2) && (
              <tr className="h-[45px] odd:bg-black-100">
                <td className="px-[16px] md:px-[16px]">
                  <Typography isBold>土地・建物で分割</Typography>
                </td>
                <td className="px-[16px] md:px-[16px]">
                  <Typography className="">
                    {convertLoanType2(housingSelfOwned?.loan_type2)}
                  </Typography>
                </td>
              </tr>
            )}
            {housingSelfOwned?.future_v2_housing_loans?.map(
              (loan: any, loanIndex: number) => (
                <React.Fragment key={loanIndex}>
                  {(loanIndex === 0 ||
                    (housingSelfOwned?.loan_type2 === API_FLAG.on &&
                      ((loanIndex === 1 &&
                        isOneLoanPattern(
                          housingSelfOwned?.future_v2_housing_loans![0],
                        )) ||
                        (loanIndex === 2 &&
                          isTwoLoanPattern(
                            housingSelfOwned?.future_v2_housing_loans![0],
                          ))))) && (
                    <>
                      {isSplitedLoan(housingSelfOwned) && (
                        <>
                          <div className="mb-[5px]">
                            <Typography className={clsx('', 'u-bold')}>
                              {convertLandOrBuildingLoanTitle(
                                loanIndex,
                                housingSelfOwned,
                              )}
                            </Typography>
                          </div>
                        </>
                      )}

                      <tr className="h-[45px] odd:bg-black-100">
                        <td className="px-[16px] md:px-[16px]">
                          <Typography isBold>ローン方式</Typography>
                        </td>
                        <td className="px-[16px] md:px-[16px]">
                          <Typography className="">
                            {convertLoanType(loan.loan_type)}
                          </Typography>
                        </td>
                      </tr>
                      <tr className="h-[45px] odd:bg-black-100">
                        <td className="px-[16px] md:px-[16px]">
                          <Typography isBold>借り入れ先</Typography>
                        </td>
                        <td className="px-[16px] md:px-[16px]">
                          <Typography className="">
                            {convertLoanLender(loan.loan_lender)}
                          </Typography>
                        </td>
                      </tr>
                    </>
                  )}
                  {isNotEmptyValue(loan.future_v2_housing_loan_calculation) &&
                    isWithCalcLoan(loan.repayment_chart) && (
                      <FutureV2HousingLoanCalculationBody loan={loan} />
                    )}
                </React.Fragment>
              ),
            )}
          </>
        )}
      </tbody>
    </table>
  )
}

interface FutureV2HousingLoanBodyProps {
  housingSelfOwned: Partial<FutureV2HousingSelfOwned>
  /** 共通API呼び出しメソッド */
  handleModalOpen: (modalNumber: number) => void
}

const FutureV2HousingLoanBody: FC<FutureV2HousingLoanBodyProps> = (props) => {
  const { isManipulatedApproval } = useContext(SimulationDetailEditContext)

  const { housingSelfOwned, handleModalOpen } = props

  return (
    <>
      <div className="flex justify-between">
        <Typography isBold className="mb-[10px]">
          ローン情報
        </Typography>
        <Tooltip title="住み替え先を変更する場合は、一度削除して作り直してください" arrow>
          <span>
            <Button
              className=""
              isDisabled={!isManipulatedApproval}
              onClick={(event: any) => {
                event.stopPropagation()
                handleModalOpen(3)
              }}
            >
              <i className="ico edit text-[14px] text-black-700" />
              <Typography className="pl-[5px] text-[14px] text-black-700">
                編集
              </Typography>
            </Button>
          </span>
        </Tooltip>
      </div>

      <FutureV2HousingLoanTable housingSelfOwned={housingSelfOwned} />
    </>
  )
}

export default FutureV2HousingLoanBody
