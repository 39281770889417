import React, { FC, useMemo, useState } from 'react'
import ChevronRightIcon from '@/assets/images/v2/chevron-right.svg'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import Modal from '@/components/v2/atoms/Modal'
import Typography from '@/components/v2/atoms/Typography'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import ImageMagnifiableModal from '@/components/v2/organisms/ImageModal'

interface GuidanceModalProps {
  title?: string
  /** true: オープン, false: クローズ */
  isOpen: boolean
  /** 表示コンテンツ */
  contents: {
    text: JSX.Element
    afterText?: JSX.Element
    title?: string
    src?: string
    alt?: string
  }[]
  /** true: モーダル番号を表示, false: モーダル番号を非表示 */
  isDisplayProgress?: boolean
  /** モーダルクローズメソッド */
  onClose: () => void
}

const GuidanceModal: FC<GuidanceModalProps> = (props) => {
  const { title, isOpen, onClose, contents, isDisplayProgress } = props
  const [isOpenImageModal, setIsOpenImageModal] = useState<boolean>(false)
  const [currentModalNumber, setCurrentModalNumber] = useState<number>(1)
  const [selectedSrc, setSelectedSrc] = useState<string>('')

  const totalModalNumber = contents.length

  const isLastPage = useMemo(() => currentModalNumber == totalModalNumber, [
    currentModalNumber,
  ])

  const handleModalClose = () => {
    setIsOpenImageModal(false)
  }

  const handleClose = () => {
    setCurrentModalNumber(1)
    onClose()
  }

  const handleNext = () => {
    if (isLastPage) {
      return handleClose()
    }

    setCurrentModalNumber(currentModalNumber + 1)
  }

  const handleOpenImageModal = (src: string, alt?: string) => {
    setSelectedSrc(src)
    setIsOpenImageModal(true)
  }

  return (
    <>
      {contents.map((item, index) => (
        <div key={index}>
          {currentModalNumber === index + 1 && (
            <Modal
              className="lg:w-[600px] w-[95%]"
              isOpen={isOpen}
              isDisableBackdropClick
              onClose={handleClose}
            >
              <CircleTitle
                className="pb-[30px]"
                title={item?.title ? item?.title : title ? title : ''}
                classNameTitle="text-[15px] md:text-[24px]"
              />

              {isDisplayProgress && (
                <div className="text-center pb-[30px] flex justify-center">
                  <Typography
                    className="font-['HiraginoSans-W6'] text-[32px] leading-[1.1]"
                    isBold
                  >
                    {currentModalNumber}
                  </Typography>
                  <Typography className="font-['HiraginoSans-W6'] text-[24px]" isBold>
                    {`/${totalModalNumber}`}
                  </Typography>
                </div>
              )}

              {item.text}
              {!!item?.src && (
                <>
                  <img
                    className="cursor-zoom-in"
                    src={item.src}
                    alt={item?.alt}
                    onClick={() => handleOpenImageModal(item.src!)}
                  />
                  <Typography className="text-[11px] md:text-12">
                    ※ 画像をクリックすると拡大イメージが表示されます
                  </Typography>
                </>
              )}
              {item.afterText && item.afterText}
              <ButtonPrimary
                className="h-40 md:h-50 w-full md:w-215 px-17 mt-[30px] mx-auto"
                onClick={handleNext}
              >
                <Typography className="relative flex-1 text-[14px] text-white" isBold>
                  {isLastPage ? '閉じる' : '次へ'}
                </Typography>
                <ChevronRightIcon />
              </ButtonPrimary>
            </Modal>
          )}
        </div>
      ))}

      <ImageMagnifiableModal
        src={selectedSrc}
        isOpen={isOpenImageModal}
        onClose={handleModalClose}
      />
    </>
  )
}

export default GuidanceModal
