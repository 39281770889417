import React, { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { isUndefined as _isUndefined } from 'lodash'
import Typography from '@/components/v2/atoms/Typography'
import { convertFloatLocaleString } from '@/models/commonsModelFunc'
import { RootState } from '@/store'

interface TooltipTransitionNetAssetProps {
  /** アクティブ情報 */
  activeIndex: number | undefined
}

const TooltipTransitionNetAsset: FC<TooltipTransitionNetAssetProps> = (props) => {
  const { activeIndex } = props
  const {
    summaryTransitions: { net_asset_graph },
  } = useSelector((state: RootState) => state.summaries)

  const netAssetGraph = useMemo(() => {
    return (
      net_asset_graph?.map((obj: any) => {
        let newObj = { ...obj }
        newObj.date = obj.date.replace('-', '/')
        return newObj
      }) || []
    )
  }, [net_asset_graph])

  const activeItem: any = !_isUndefined(activeIndex) ? netAssetGraph?.[activeIndex] : {}

  return (
    <>
      {!_isUndefined(activeIndex) ? (
        <div className="md:w-[250px] py-[24px] px-[16px] rounded-[8px] bg-white md:shadow-modal visible pointer-events-auto">
          <div className="mb-[16px] p-[10px] rounded-[4px] bg-primary-50">
            <Typography className="pb-[4px]" isBold>
              {activeItem.date}
            </Typography>
            <Typography isBold>{`純資産合計：${convertFloatLocaleString(
              activeItem.net_asset_total_manyen,
              4,
            )}万円`}</Typography>
          </div>

          <div className="pb-[16px] last:pb-0">
            <div className="flex justify-between mb-[5px] pb-[5px] border-b border-black-300">
              <div className="flex items-center">
                <i
                  className="ico rect-2 pr-[4px] text-[12px]"
                  style={{ color: '#FFD9B1' }}
                />
                <Typography className="text-[12px]" isBold>
                  金融資産
                </Typography>
              </div>
              <Typography className="text-[12px]" isBold>
                {`総額　${convertFloatLocaleString(activeItem.total_manyen)}万円`}
              </Typography>
            </div>
            <div className="flex justify-between pb-[5px] last:pb-0">
              <Typography className="text-[12px]">流動性資産</Typography>
              <Typography className="text-[12px]">{`${convertFloatLocaleString(
                activeItem.bank_manyen,
              )}万円`}</Typography>
            </div>
            <div className="flex justify-between pb-[5px] last:pb-0">
              <Typography className="text-[12px]">株式</Typography>
              <Typography className="text-[12px]">{`${convertFloatLocaleString(
                activeItem.stock_manyen,
              )}万円`}</Typography>
            </div>
            <div className="flex justify-between pb-[5px] last:pb-0">
              <Typography className="text-[12px]">投資信託</Typography>
              <Typography className="text-[12px]">{`${convertFloatLocaleString(
                activeItem.trust_fund_manyen,
              )}万円`}</Typography>
            </div>
            <div className="flex justify-between pb-[5px] last:pb-0">
              <Typography className="text-[12px]">債券</Typography>
              <Typography className="text-[12px]">{`${convertFloatLocaleString(
                activeItem.bond_manyen,
              )}万円`}</Typography>
            </div>
            <div className="flex justify-between pb-[5px] last:pb-0">
              <Typography className="text-[12px]">社内預金・財形・持株など</Typography>
              <Typography className="text-[12px]">{`${convertFloatLocaleString(
                activeItem.deducted_savings_manyen,
              )}万円`}</Typography>
            </div>
            <div className="flex justify-between pb-[5px] last:pb-0">
              <Typography className="text-[12px]">その他</Typography>
              <Typography className="text-[12px]">{`${convertFloatLocaleString(
                activeItem.other_manyen,
              )}万円`}</Typography>
            </div>
          </div>

          <div className="pb-[16px] last:pb-0">
            <div className="flex justify-between mb-[5px] pb-[5px] border-b border-black-300">
              <div className="flex items-center">
                <i
                  className="ico rect-2 pr-[4px] text-[12px]"
                  style={{ color: '#AFE2E9' }}
                />
                <Typography className="text-[12px]" isBold>
                  不動産
                </Typography>
              </div>
              <Typography className="text-[12px]" isBold>
                {`総額　${convertFloatLocaleString(
                  activeItem.evaluation_total_manyen,
                  4,
                )}万円`}
              </Typography>
            </div>
            <div className="flex justify-between pb-[5px] last:pb-0">
              <Typography className="text-[12px]">自宅</Typography>
              <Typography className="text-[12px]">{`${convertFloatLocaleString(
                activeItem.housing_asset_evaluation_manyen,
                4,
              )}万円`}</Typography>
            </div>

            {Object.keys(activeItem.estate_evaluations_manyen).map((key, index) => (
              <div key={key} className="flex justify-between pb-[5px] last:pb-0">
                <Typography className="text-[12px]">{`物件${index + 1}`}</Typography>
                <Typography className="text-[12px]">{`${convertFloatLocaleString(
                  activeItem.estate_evaluations_manyen[key],
                  4,
                )}万円`}</Typography>
              </div>
            ))}
          </div>

          <div className="pb-[16px] last:pb-0">
            <div className="flex justify-between mb-[5px] pb-[5px] border-b border-black-300">
              <div className="flex items-center">
                <i
                  className="ico rect-2 pr-[4px] text-[12px]"
                  style={{ color: '#F6B2A1' }}
                />
                <Typography className="text-[12px]" isBold>
                  負債
                </Typography>
              </div>
              <Typography className="text-[12px]" isBold>
                {`総額　${convertFloatLocaleString(
                  activeItem.substruction_total_manyen,
                  4,
                )}万円`}
              </Typography>
            </div>
            <div className="flex justify-between pb-[5px] last:pb-0">
              <Typography className="text-[12px]">住宅ローン</Typography>
              <Typography className="text-[12px]">{`${convertFloatLocaleString(
                activeItem.housing_remain_loan_amount_manyen,
                4,
              )}万円`}</Typography>
            </div>
            <div className="flex justify-between pb-[5px] last:pb-0">
              <Typography className="text-[12px]">不動産投資用ローン</Typography>
              <Typography className="text-[12px]">{`${convertFloatLocaleString(
                activeItem.estate_remain_loan_amount_total_manyen,
                4,
              )}万円`}</Typography>
            </div>
            <div className="flex justify-between pb-[5px] last:pb-0">
              <Typography className="text-[12px]">カーローン</Typography>
              <Typography className="text-[12px]">{`${convertFloatLocaleString(
                activeItem.car_remain_loan_amount_manyen,
                4,
              )}万円`}</Typography>
            </div>
            <div className="flex justify-between pb-[5px] last:pb-0">
              <Typography className="text-[12px]">その他負債</Typography>
              <Typography className="text-[12px]">{`${convertFloatLocaleString(
                activeItem.substruction_manyen,
                4,
              )}万円`}</Typography>
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default TooltipTransitionNetAsset
