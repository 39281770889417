import ApiClient from '../apiClient'
import { FutureLoanAdvanceCreateValues } from '../../models/futures/futureLoanAdvancesModel'

export const postV2FutureLoanAdvance = async (values: FutureLoanAdvanceCreateValues) => {
  return await ApiClient.post('/future_loan_advances', () => {}, values, false, 'v2')
}

export const patchFutureLoanAdvanceV2 = async (values: any, id: number) => {
  return await ApiClient.patch(
    `/future_loan_advances/${id}`,
    () => {},
    values,
    undefined,
    'v2',
  )
}

export const deleteFutureLoanAdvanceV2 = async (id: number) => {
  return await ApiClient.delete(
    `/future_loan_advances/${id}`,
    () => {},
    undefined,
    undefined,
    'v2',
  )
}
