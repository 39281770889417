import React, { FC, useEffect, useContext } from 'react'
import FamilyMonochromaticIcon from '@/assets/images/v2/family-monochromatic.svg'
import { useDispatch } from 'react-redux'
import { isUndefined as _isUndefined } from 'lodash'
import Paper from '@/components/v2/atoms/Paper'
import Typography from '@/components/v2/atoms/Typography'
import Breadcrumb from '@/components/v2/molecules/Breadcrumb'
import MarginContainer from '@/components/v2/atoms/MarginContainer'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import { find as _find } from 'lodash'
import { useParams } from 'react-router'
import { setPageMeta } from '@/containers/pageMetasSlice'
import PaperTitle from '@/components/v2/molecules/PaperTitle'
import { AccountContext } from '@/models/accountsModelFunc'
import PublicPensionEditForm from './Form'

const PublicPensionEdit: FC = () => {
  const dispatch = useDispatch()
  const { isAdmin } = useContext(AccountContext)
  const { account_id } = useParams<{ account_id?: string }>()
  const { handleMovePage } = useCustomHistory()

  const { person_flg } = useParams<{ person_flg?: string }>()

  const movePageAfterForm = () => {
    handleMovePage(
      isAdmin ? `/v2/accounts/${account_id}/myData/salaries` : '/v2/myData/salaries',
    )
  }

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'マイデータ | 公的年金 編集',
        description: 'マイデータ | 公的年金 編集',
        keyword: 'マイデータ | 公的年金 編集',
      }),
    )
  }, [])

  return (
    <MarginContainer className="px-8 py-40">
      <Breadcrumb
        className="pb-32"
        data={[
          {
            name: 'マイデータ',
            url: isAdmin ? `/v2/accounts/${account_id}/myData` : '/v2/myData',
          },
          {
            name: 'マイデータ設定',
            url: isAdmin
              ? `/v2/accounts/${account_id}/myData/salaries`
              : '/v2/myData/salaries',
          },
          { name: '年金情報の設定' },
        ]}
      />

      <PaperTitle
        className="mb-[16px] md:mb-[40px]"
        title="年金の設定"
        description="日々の収入や支出を登録することで改善案などのご提案が可能になります"
        icon={<FamilyMonochromaticIcon />}
        smallIcon={
          <FamilyMonochromaticIcon className="h-[82px] w-[130px]" viewBox="0 0 117 154" />
        }
      />

      <Paper className="bg-white p-16 md:p-40">
        <Typography
          className="mb-[32px] pb-10 text-[24px] md:text-[28px] text-black-800 border-b-2 border-black-300"
          isBold
        >
          {person_flg == '0' ? 'ご本人' : '配偶者'}の年金について
        </Typography>

        <PublicPensionEditForm
          person_flg={Number(person_flg)}
          onClose={movePageAfterForm}
          onSubmit={movePageAfterForm}
        />
      </Paper>
    </MarginContainer>
  )
}

export default PublicPensionEdit
