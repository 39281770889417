import { times as _times } from 'lodash'
import {
  RECEIVING_PENSION_TYPE,
  MATCHING_CONTRIBUTION_TYPE,
} from '../../../models/inputs/inputContributionPensionsModelFunc'

// 受取方式
export const RECEIVING_PENSION_TYPE_SELECT_ITEMS = [
  { value: RECEIVING_PENSION_TYPE.pension, label: '年金として分割して受け取る' },
  { value: RECEIVING_PENSION_TYPE.both, label: '年金と一時金を併用して受け取る' },
  { value: RECEIVING_PENSION_TYPE.lump, label: '一時金として一括で受け取る' },
]

// 受取年齢
const startAge = 60
export const RECEIVING_PENSION_AGE_SELECT_ITEMS = _times(41, (index) => ({
  value: startAge + index,
  label: String(startAge + index),
}))

// 受取期間
export const PENSION_YEARS_SELECT_ITEMS = [
  { value: 5, label: '5年間' },
  { value: 10, label: '10年間' },
  { value: 15, label: '15年間' },
  { value: 20, label: '20年間' },
]

// マッチング拠出種別
export const MATCHING_CONTRIBUTION_TYPE_RADIO_ITEMS = [
  { value: MATCHING_CONTRIBUTION_TYPE.matching, label: '行っている' },
  { value: MATCHING_CONTRIBUTION_TYPE.noMatching, label: '行っていない' },
]
