import React from 'react'
import { Line } from 'recharts'

interface RenderCustomLineProps {
  /** 名前 */
  name: string
  /** データキー */
  dataKey: string
  /** カラー */
  color: string
  /** onClickメソッド */
  onClick?: (payload: any, event: any) => void
}

const renderCustomLine = (props: RenderCustomLineProps) => {
  const { name, dataKey, color, onClick } = props

  return (
    <Line
      name={name}
      dataKey={dataKey}
      stroke={color}
      dot={{ onClick: (payload: any, event: any) => onClick?.(payload, event) }}
      activeDot={{
        cursor: 'pointer',
        onClick: (payload: any, event: any) => onClick?.(payload, event),
      }}
    />
  )
}

export default renderCustomLine
