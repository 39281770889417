import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty as _isEmpty } from 'lodash'
import MoneytreeIcon from '@/assets/images/v2/moneytree.svg'
import MarginContainer from '@/components/v2/atoms/MarginContainer'
import Paper from '@/components/v2/atoms/Paper'
import Heading from '@/components/v2/atoms/Heading'
import Typography from '@/components/v2/atoms/Typography'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import Link from '@/components/v2/atoms/Link'
import { createAccountBalance, fetchAccount } from '@/containers/accountsSlice'
import { removeAllFlashAlerts, setFlashAlert } from '@/containers/flashAlertsSlice'
import { setPageMeta } from '@/containers/pageMetasSlice'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import { RootState } from '@/store'
import { getMoneytreeVaultUrl } from '@/utils/moneytree'

const MoneytreeDataConfirmation: FC = () => {
  const isFromSettingPage: boolean = window.localStorage.isFromSettingPage === 'true'
  const dispatch = useDispatch()
  const { handleMovePage } = useCustomHistory()
  const { flashAlertMessageObjects } = useSelector(
    (state: RootState) => state.flashAlerts,
  )
  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'Moneytree口座情報連携',
        description: 'Moneytree口座情報連携',
        keyword: 'Moneytree口座情報連携',
      }),
    )

    dispatch(
      createAccountBalance(() => {
        handleNextPage()
        showFlashMsg()
        dispatch(fetchAccount())
      }),
    )
  }, [])

  const showFlashMsg = () => {
    if (!_isEmpty(flashAlertMessageObjects)) {
      dispatch(removeAllFlashAlerts())
    }

    dispatch(
      setFlashAlert({
        flashAlertMessageObjects: [{ message: `更新しました`, variant: 'success' }],
      }),
    )
  }

  const handleNextPage = () => {
    handleMovePage('/v2/plans/assetBalance')
  }

  const handleSkipConnection = () => {
    isFromSettingPage
      ? handleMovePage('/v2/settings/account')
      : handleMovePage('/v2/dashboards')
  }

  const handleOpenMoneytree = () => {
    const url = getMoneytreeVaultUrl('/v2/plans/moneytreeDataConfirmation')
    window.open(url)
  }

  return (
    <MarginContainer className="py-40 space-y-24">
      <Paper className="lg:max-w-820 mx-8 lg:mx-auto px-16 py-24 sm:p-40 space-y-24 bg-white">
        <Heading className="text-center animate-flash">
          Moneytreeに登録した
          <span className="sm:inline-block block">口座情報を取り込み中です</span>
        </Heading>
        <Typography className="text-14 text-black-700 text-center">
          このページは自動で切り替わります。
          <br />
          口座情報のデータ量によっては、
          <span className="sm:inline-block block">
            連携完了までお時間がかかる場合があります。
          </span>
          <br />
          5分以上経過しても切り替わらない場合は、
          <span className="sm:inline-block block">
            下記の口座登録するボタンから再度連携をお願いします。
          </span>
        </Typography>
        <div className="p-16 md:p-24 xl:p-40 rounded-16 bg-primary-75">
          <div className="flex flex-col items-center p-12 space-y-24 rounded-8 bg-white">
            <MoneytreeIcon />
            <Typography className="text-14 text-center" isBold>
              Moneytreeの口座情報登録画面へ
            </Typography>
            <ButtonPrimary
              className="h-45 w-full md:w-215 px-17 text-white"
              onClick={handleOpenMoneytree}
            >
              <Typography className="bottom-2 flex-auto relative text-14" isBold>
                口座登録する
              </Typography>
              <i className="ico chevron-right text-24" />
            </ButtonPrimary>
          </div>
        </div>
        <div className="text-center">
          <Link
            className="text-14 text-primary-900"
            children={
              isFromSettingPage ? '連携せずに個人設定ページに戻る' : '連携せずに進む'
            }
            onClick={handleSkipConnection}
          />
        </div>
      </Paper>
    </MarginContainer>
  )
}

export default MoneytreeDataConfirmation
