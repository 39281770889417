import { find as _find } from 'lodash'
import { defaultToEmptyString } from '@/models/commonsModelFunc'

// 返済方法
export const REPAYMENT_MODE = {
  short: 0,
  mitigation: 1,
}

// 返済の頻度
export const ADVANCE_MODE = {
  every: 0,
  once: 1,
}

import {
  ADVANCE_MODE_SELECT_ITEMS,
  REPAYMENT_MODE_SELECT_ITEMS,
} from '@/constants/formItem/futures/futureLoanAdvances'

export const convertRepaymentMode = (repaymentMode: number | null) => {
  const repaymentModeLabel = _find(
    REPAYMENT_MODE_SELECT_ITEMS,
    (item) => item.value === repaymentMode,
  )?.label

  return defaultToEmptyString(repaymentModeLabel)
}

export const convertAdvanceMode = (advanceMode: number | null) => {
  const advanceModeLabel = _find(
    ADVANCE_MODE_SELECT_ITEMS,
    (item) => item.value === advanceMode,
  )?.label

  return defaultToEmptyString(advanceModeLabel)
}
