import React, { FC, useState, useContext, useEffect, useMemo } from 'react'
import clsx from 'clsx'
import Typography from '@/components/v2/atoms/Typography'
import SimulationAccordion from '@/templates/v2/simulations/_simulationAccordion'
import SimulationNewButton from '@/templates/v2/simulations/_simulationNewButton'
import FutureInflationModalForm from '@/templates/v2/simulations/_futureInflationModalForm'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/store'
import Button from '@/components/v2/atoms/Button'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import { destroyFutureInflationV2 } from '@/containers/futures/futureInflationsSlice'
import { fetchAccountFutureItems } from '@/containers/accountsSlice'
import { SimulationDetailEditContext } from '@/models/futures/futureBasesModelFunc'
import { find as _find } from 'lodash'
import SimulationItemAccordion from '@/templates/v2/simulations/_simulationItemAccordion'

interface FutureInflationFormProps {
  /** 選択シミュレーションID */
  selectedSimulationId: number | undefined
  /** 選択メソッド */
  handleSelect: (item: any) => void
  /* FutureItemsを参照するID */
  futureBaseId?: number
  /* 初期化すべきかどうか */
  shouldInitialize?: boolean
  /* 編集を確認したか */
  confirmEdit?: boolean
  /* 編集確認モーダルを展開するメソッド */
  openEditConfirmModal?: () => void
}

interface FutureInflationTableProps {
  /** アイテム */
  item: any
  /** td要素のクラス */
  tdClassName?: string
}

export const FutureInflationTable: FC<FutureInflationTableProps> = ({
  item,
  tdClassName,
}) => {
  return (
    <table
      key={item.id}
      className={clsx('w-[100%] mb-[20px] last:mb-0 text-[12px] md:text-[14px] bg-white')}
    >
      <tbody>
        <tr className="h-[45px] odd:bg-black-100">
          <td className={`${tdClassName ?? 'w-[60%]'} px-[16px] md:px-[30px]`}>
            <Typography isBold>インフレ発生時の生活費</Typography>
          </td>
          <td className="px-[16px] md:px-[30px]">
            <Typography>毎年 {item.inflation_rate_percent} % 増</Typography>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

const FutureInflationForm: FC<FutureInflationFormProps> = (props) => {
  const dispatch = useDispatch()
  const { showFlashMsg } = useFlashAlert()
  const { isManipulatedApproval } = useContext(SimulationDetailEditContext)

  const { selectedSimulationId, handleSelect } = props

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [inflationId, setInflationId] = useState<number | null>(null)

  const futureInflations = useSelector(
    (state: RootState) => state.accounts.futureItems?.future_inflations,
  )

  useEffect(() => {
    if (props.futureBaseId == null || !props.shouldInitialize) return
    const item = _find(futureInflations, { future_base_id: props.futureBaseId })
    if (item != null) handleSelect(item)
  }, [props.shouldInitialize])

  const sortedFutureInflations = useMemo(() => {
    if (props.futureBaseId == null || futureInflations == null) return futureInflations
    const eqFbId = (item: any) => item.future_base_id === props.futureBaseId
    return [
      ...futureInflations.filter((item) => eqFbId(item)),
      ...futureInflations.filter((item) => !eqFbId(item)),
    ]
  }, [futureInflations])

  const titleIndex = (index: number) => {
    if (
      props.futureBaseId == null ||
      props.confirmEdit == null ||
      futureInflations == null
    )
      return index + 1
    const eqFbId = (item: any) => item.future_base_id === props.futureBaseId
    return index + 1 - futureInflations.filter((item) => eqFbId(item)).length
  }

  const handleExecIfConfired = (exec: (item: any) => void, item: any) => {
    props.confirmEdit === false ? props.openEditConfirmModal?.() : exec(item)
  }

  return (
    <SimulationAccordion
      className="pb-[16px]"
      title="インフレ発生時の家計を想定"
      isSelected={!!selectedSimulationId}
      itemCount={sortedFutureInflations?.length}
    >
      <div className="flex flex-col gap-y-[12px] pt-[6px]">
        {sortedFutureInflations?.map((item: any, index: number) => {
          const handleDestroyV2 = (item: any) => {
            const result = confirm('本当に削除してよろしいですか？')
            if (!result) return

            const destroyFunc = new Promise((resolve) => {
              dispatch(
                destroyFutureInflationV2(item, item.id, () => {
                  resolve('')
                  showFlashMsg('削除しました')
                }),
              )
            })

            Promise.all([destroyFunc]).then(() => {
              if (item.id === selectedSimulationId) handleSelect(item)
              dispatch(fetchAccountFutureItems(props.futureBaseId))
            })
          }

          const handleEdit = (item: any) => {
            setInflationId(item.id)
            setIsEdit(true)
            setIsOpenModal(true)
          }

          return (
            <SimulationItemAccordion
              key={`${item.id}_${index}`}
              title={
                props.futureBaseId === item.future_base_id && props.confirmEdit != null
                  ? '登録中のインフレ設定'
                  : `インフレ設定${titleIndex(index)}`
              }
              isSelected={item.id === selectedSimulationId}
              inputType="radio"
              onSelect={() => {
                handleExecIfConfired(handleSelect, item)
              }}
            >
              <div className="flex justify-end mb-[5px]">
                <Button
                  className="mr-[16px]"
                  isDisabled={!isManipulatedApproval}
                  onClick={(e) => {
                    handleExecIfConfired(handleEdit, item)
                    e.stopPropagation()
                  }}
                >
                  <i className="ico edit text-[14px] text-black-700" />
                  <Typography className="pl-[5px] text-[14px] text-black-700">
                    編集
                  </Typography>
                </Button>
                <Button
                  isDisabled={!isManipulatedApproval}
                  onClick={(e) => {
                    handleExecIfConfired(handleDestroyV2, item)
                    e.stopPropagation()
                  }}
                >
                  <Typography className="pl-[5px] text-[14px] text-black-700">
                    削除
                  </Typography>
                </Button>
              </div>
              <FutureInflationTable item={item} />
            </SimulationItemAccordion>
          )
        })}
      </div>

      <SimulationNewButton
        isDisabled={!isManipulatedApproval}
        onClick={() => {
          setInflationId(null)
          setIsEdit(false)
          setIsOpenModal(true)
        }}
      />

      {isOpenModal && (
        <FutureInflationModalForm
          isOpen={isOpenModal}
          isEdit={isEdit}
          onClose={() => setIsOpenModal(false)}
          id={inflationId}
          futureBaseId={props.futureBaseId}
        />
      )}
    </SimulationAccordion>
  )
}

export default FutureInflationForm
