import React, { FC, useContext, useEffect, useMemo, ChangeEvent } from 'react'
import { useSelector } from 'react-redux'
import { useField, useForm } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { sortBy as _sortBy, last as _last, max as _max, pick as _pick } from 'lodash'
import {
  LIVING_END_AT_AGE_SELECT_ITEMS,
  NEXT_HOUSE_PLAN_SELECT_ITEMS,
} from '@/constants/formItem/futures/futureV2Housings'
import { DescriptionContext } from '@/hooks/useDescriptionTemplates'
import { isNotEmptyValue, castNumberWithoutOperator } from '@/models/commonsModelFunc'
import { LAST_AGE, NEXT_HOUSE_TYPE } from '@/models/futures/futureV2HousingsModelFunc'
import { FutureV2HousingRent } from '@/models/futures/futureV2HousingRentsModel'
import { RootState } from '@/store'
import { buildRangeAgeSelectItems } from '@/utils/formItem'
import { required } from '@/utils/validate'

import Button from '@/components/v2/atoms/Button'
import Typography from '@/components/v2/atoms/Typography'
import SelectBox from '@/components/v2/molecules/SelectBox'
import NumberField from '@/components/v2/molecules/NumberField'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import CrossIcon from '@/assets/images/v2/cross.svg'
import Modal from '@/components/v2/atoms/Modal'
import StripedTable from '@/components/v2/molecules/StripedTable'

interface RelocationPhaseSettingProps {
  isEdit?: boolean
}

const RelocationPhaseSetting: FC<RelocationPhaseSettingProps> = (props) => {
  const { targetAge, isRentLastAgeModalOpen, setIsRentLastAgeModalOpen } = useContext(
    DescriptionContext,
  )

  const { isEdit } = props

  const familyInfo = useSelector(
    (state: RootState) => state.accounts.futureItems?.family_info,
  )
  const familyAge = useMemo(() => {
    return {
      person_current_age: familyInfo?.person_age,
      spouse_current_age: familyInfo?.spouse_age,
    }
  }, [familyInfo])

  const { change, getState } = useForm()
  const { values, errors } = getState()

  const {
    input: { value: nextHousePlan },
  } = useField('next_house_plan')

  const {
    input: { value: rentMonthly },
  } = useField('rent_monthly')

  const {
    input: { value: livingEndAtAge },
  } = useField('living_end_at_age')

  const {
    input: { value: futureV2HousingRentsAttributes },
  } = useField('future_v2_housing.future_v2_housing_rents_attributes')

  const lastfutureV2HousingRent: any = _last(futureV2HousingRentsAttributes)
  const isInputCompleted = lastfutureV2HousingRent?.living_end_at_age == LAST_AGE

  const ageSelectItems = useMemo(() => {
    const targetEndAge = Number(
      _last(futureV2HousingRentsAttributes as FutureV2HousingRent[])?.living_end_at_age,
    )

    const newAgeSelectItems = buildRangeAgeSelectItems(
      _max([targetEndAge + 1, targetAge + 1, Number(familyAge.person_current_age) + 1]),
      110,
      LIVING_END_AT_AGE_SELECT_ITEMS,
    )
    newAgeSelectItems.unshift({ label: '終身まで', value: LAST_AGE })

    return newAgeSelectItems
  }, [futureV2HousingRentsAttributes])

  useEffect(() => {
    change('future_v2_housing.scheduled_at_age', targetAge)
    change('next_house_plan', NEXT_HOUSE_TYPE.rent)

    if (isEdit) {
      const formattedRents = futureV2HousingRentsAttributes.map((rent: any) => ({
        ...rent,
        rent_yearly: castNumberWithoutOperator(rent.rent_monthly) * 12,
      }))
      const sortedfutureV2HousingRents = _sortBy([...formattedRents], 'living_end_at_age')

      return change(
        'future_v2_housing.future_v2_housing_rents_attributes',
        sortedfutureV2HousingRents,
      )
    }
  }, [])

  const handleAddHousingRent = () => {
    const newValues: any = {
      rent_monthly: castNumberWithoutOperator(rentMonthly),
      rent_yearly: castNumberWithoutOperator(rentMonthly) * 12,
      living_end_at_age: Number(livingEndAtAge),
    }

    let sortedfutureV2HousingRents = _sortBy(
      [...futureV2HousingRentsAttributes, newValues],
      'living_end_at_age',
    )

    sortedfutureV2HousingRents = sortedfutureV2HousingRents.map((item, index) => {
      const livingStartAtAge =
        index == 0
          ? Number(targetAge) || Number(familyAge.person_current_age) + 1
          : Number(sortedfutureV2HousingRents[index - 1].living_end_at_age)

      return {
        ...item,
        living_start_at_age: livingStartAtAge,
        scheduled_at_age: livingStartAtAge,
      }
    })

    change(
      'future_v2_housing.future_v2_housing_rents_attributes',
      sortedfutureV2HousingRents,
    )
    change('rent_monthly', null)
    change('rent_yearly', null)
    change('living_end_at_age', null)
  }

  const handleChangeRentMonthly = (value: any, key: string) => {
    const castedValue = castNumberWithoutOperator(value)
    change(key, castedValue * 12)
  }

  const { detailFutureBaseV2 } = useSelector((state: RootState) => state.futureBases)

  return (
    <>
      {detailFutureBaseV2.statement_table_data &&
        detailFutureBaseV2.family_structure_table_columns && (
          <StripedTable
            className="w-full col-span-1 md:col-span-2"
            tableData={Object.keys(detailFutureBaseV2.statement_table_data).reduce(
              (acc: any, year: any) => {
                acc[year] = _pick(
                  detailFutureBaseV2.statement_table_data[year],
                  detailFutureBaseV2.family_structure_table_columns.map(
                    (obj: any) => obj.key,
                  ),
                )
                return acc
              },
              {},
            )}
            columnData={detailFutureBaseV2.family_structure_table_columns}
            formatter={(v, k) => v && `${v}歳`}
          />
        )}

      <div className="col-span-1 md:col-span-2 border-dashed border-b-2">
        <FieldArray name="future_v2_housing.future_v2_housing_rents_attributes">
          {({ fields }) =>
            Number(fields.length) > 0 ? (
              fields.map((name, index) => {
                const { living_start_at_age, living_end_at_age } = fields.value[index]
                const isWholeLife = Number(living_end_at_age) == 120

                return (
                  <div key={name} className="pb-[16px] cursor-default">
                    <div className="relative flex p-[14px] border border-solid rounded-[5px]">
                      <Typography
                        className="text-[14px] text-black-700 flex items-center mr-[16px]"
                        isBold
                      >
                        {/* 前のフォームで入力した年齢がはいる */}
                        {`${living_start_at_age}歳から`}
                      </Typography>
                    </div>

                    <div className="flex relative ml-[20px] md:ml-[58px]">
                      <div className="relative md:flex p-[10px] border-l border-solid left-[-12px] z-[10]">
                        <Typography
                          className="text-[14px] text-black-900 font-bold flex items-center mr-[16px] ml-[30px] pb-[10px]"
                          isBold
                        >
                          {`${living_start_at_age}歳からの住居`}
                        </Typography>
                        <div>
                          <div className="flex items-center mb-[10px]">
                            <NumberField
                              isFullWidth
                              name={`${name}rent_monthly`}
                              defaultValue={fields.value[index].rent_monthly}
                              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                handleChangeRentMonthly(
                                  event.target.value,
                                  `${name}rent_yearly`,
                                )
                              }
                            />
                            <Typography
                              className="pl-[8px] text-[14px] text-black-800 min-w-[75px]"
                              isBold
                            >
                              円/月
                            </Typography>
                          </div>

                          <div className="flex items-center">
                            <NumberField
                              isFullWidth
                              defaultValue={fields.value[index].rent_yearly}
                              name={`${name}rent_yearly`}
                              disabled
                            />
                            <Typography
                              className="pl-[8px] text-[14px] text-black-800 min-w-[75px]"
                              isBold
                            >
                              円/年
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="relative flex p-[14px] border border-solid rounded-[5px] justify-between">
                      <Typography
                        className="text-[14px] text-black-700 flex items-center"
                        isBold
                      >
                        {isWholeLife ? '終身' : `${living_end_at_age}歳`}
                      </Typography>

                      <Button
                        onClick={() => {
                          if (
                            values.future_v2_housing.future_v2_housing_rents_attributes
                              .length == 1
                          ) {
                            change('next_house_plan', 'rent')
                          }
                          fields.remove(index)
                        }}
                      >
                        <CrossIcon />
                      </Button>
                    </div>
                  </div>
                )
              })
            ) : (
              <div className="pb-[16px] cursor-default">
                <div className="relative flex p-[14px] border border-solid rounded-[5px] justify-between">
                  <Typography
                    className="text-[14px] text-black-700 flex items-center mr-[16px]"
                    isBold
                  >
                    {/* 前のフォームで入力した年齢がはいる */}
                    {`${
                      Number(targetAge) || Number(familyAge.person_current_age) + 1
                    }歳から`}
                  </Typography>
                </div>

                <div className="flex ml-[58px]">
                  <div className="relative flex p-[10px] border-l border-solid left-[-12px] z-[10]">
                    <Typography
                      className="text-[14px] text-black-900 font-bold flex items-center mr-[16px] ml-[30px]"
                      isBold
                    >
                      {`${
                        Number(targetAge) || Number(familyAge.person_current_age) + 1
                      }歳からの住居`}
                    </Typography>
                  </div>
                </div>
              </div>
            )
          }
        </FieldArray>
      </div>

      {!isInputCompleted && (
        <div className="col-span-1 md:col-span-2">
          <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
            <Typography
              className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
              isBold
            >
              次の住居
            </Typography>
            <SelectBox
              name="next_house_plan"
              placeholder="選択する"
              isFullWidth
              options={NEXT_HOUSE_PLAN_SELECT_ITEMS}
              isDisabled={
                (values.future_v2_housing.future_v2_housing_rents_attributes?.length || 0) == 0
              }
              validate={required}
              onChange={(event: any) => change('next_house_plan', event.target.value)}
            />
          </div>
          {nextHousePlan == NEXT_HOUSE_TYPE.rent && (
            <>
              <div className="md:flex border-black-300 border-dashed border-b-2 items-center py-[16px]">
                <Typography
                  className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                  isBold
                >
                  次の住居の家賃
                </Typography>
                <div className="flex flex-auto items-baseline mb-[10px] md:mb-0">
                  <NumberField
                    name="rent_monthly"
                    isFullWidth
                    onChange={(event: any) =>
                      handleChangeRentMonthly(event.target.value, 'rent_yearly')
                    }
                  />
                  <Typography
                    className="pl-[8px] text-[14px] text-black-800 min-w-[75px]"
                    isBold
                  >
                    円/月
                  </Typography>
                </div>
                <div className="flex flex-auto items-baseline">
                  <NumberField name="rent_yearly" isFullWidth disabled />
                  <Typography
                    className="pl-[8px] text-[14px] text-black-800 min-w-[75px]"
                    isBold
                  >
                    円/年
                  </Typography>
                </div>
              </div>

              <div className="lg:flex border-black-300 border-dashed border-b-2 items-center py-[16px]">
                <Typography
                  className="min-w-[140px] lg:min-w-[440px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                  isBold
                >
                  居住期間
                </Typography>
                <SelectBox
                  name="living_end_at_age"
                  placeholder="選択する"
                  isFullWidth
                  options={ageSelectItems}
                />
              </div>

              <ButtonPrimary
                className="w-[188px] h-[45px] mx-auto  my-[16px]"
                isDisabled={
                  !isNotEmptyValue(rentMonthly) ||
                  !isNotEmptyValue(livingEndAtAge) ||
                  !!errors?.living_end_at_age
                }
                onClick={() => handleAddHousingRent()}
              >
                <Typography className="text-[16px] text-white" isBold>
                  追加する
                </Typography>
              </ButtonPrimary>
            </>
          )}
        </div>
      )}

      <Modal
        className="w-640 max-w-[95%]"
        isOpen={isRentLastAgeModalOpen}
        isDisableBackdropClick
        onClose={() => {
          change('is_ready_for_submit', false)
          setIsRentLastAgeModalOpen(false)
        }}
      >
        <Typography className="text-[14px] text-black-800" isBold>
          『終身まで』の予定が設定されていません。
          <br />
          最後に追加している年齢以降は家賃が発生しなくなりますが、
          <br />
          よろしいですか？
        </Typography>

        <ButtonPrimary className="w-[246px] h-[64px] mx-[auto] mt-30" type="submit">
          <Typography className="bottom-2 flex-auto relative text-16 text-white" isBold>
            そのまま保存する
          </Typography>
        </ButtonPrimary>

        <Button
          className="bg-white border border-secondary-500 h-[64px] mb-[14px] mx-[auto] mt-30 w-[246px] hover:bg-secondary-50 rounded-8"
          onClick={() => {
            change('is_ready_for_submit', false)
            setIsRentLastAgeModalOpen(false)
          }}
        >
          <Typography className="bottom-[1px] relative text-16 text-secondary-500" isBold>
            ウィンドウを閉じて編集する
          </Typography>
        </Button>
      </Modal>
    </>
  )
}

export default RelocationPhaseSetting
