import {
  JournalDate,
  JournalResponse,
  JournalUpdateValues,
} from '../models/journalsModel'
import ApiClient from './apiClient'

export const getJournals = async (values: JournalDate) => {
  return await ApiClient.get(
    `/journals?start_date=${values.start_date}&end_date=${values.end_date}`,
    (response: JournalResponse[]) => response,
  )
}

export const patchJournal = async (values: JournalUpdateValues) => {
  return await ApiClient.patch(`/journals/${values.id}`, () => {}, values)
}

export const getJournalUncategorizedV2 = async () => {
  return await ApiClient.get(
    `/journals/uncategorized_mt_trans`,
    (response: any[]) => response,
    undefined,
    undefined,
    'v2',
  )
}
