import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { isNull as _isNull } from 'lodash'
import Typography from '@/components/v2/atoms/Typography'
import { convertLocaleString, defaultToEmptyString } from '@/models/commonsModelFunc'
import { convertYearCost } from '@/models/inputs/inputYearCostsModelFunc'
import { RootState } from '@/store'
import SimulationAccordion from '@/templates/v2/simulations/_simulationAccordion'
import MyDataYearlyLivingCostEditForm from '@/templates/v2/myData/yearlyLivingCosts/Form'
import Modal from '@/components/v2/atoms/Modal'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import Button from '@/components/v2/atoms/Button'
import SimulationNewButton from '@/templates/v2/simulations/_simulationNewButton'

export const YearlyLivingCosstTable: FC<any> = () => {
  const { inputYearCost } = useSelector((state: RootState) => state.inputYearCosts)
  const {
    input_yearly_living_cost_electronics, // 家電
    input_yearly_living_cost_ceremonies, // 冠婚葬祭
    input_yearly_living_cost_home_comings, // 帰省
    input_yearly_living_cost_travels, // 旅行
  } = useSelector((state: RootState) => state.inputYearlyLivingCosts)
  return (
    <>
      {!_isNull(inputYearCost) && (
        <table className="w-[100%] text-[12px] md:text-[14px]">
          <tbody>
            <tr className="h-[45px] odd:bg-black-100">
              <td className="w-[30%] px-[16px]">
                <Typography isBold>
                  家電の
                  <br className="block md:hidden" />
                  買い替え
                </Typography>
              </td>
              <td className="w-[70%] px-[16px]">
                <Typography>
                  {convertYearCost(
                    inputYearCost.replacement_appliances,
                    inputYearCost.replacement_frequency_year,
                    inputYearCost.replacement_frequency_number,
                  )}
                </Typography>
              </td>
            </tr>
            <tr className="h-[45px] odd:bg-black-100">
              <td className="px-[16px]">
                <Typography isBold>帰省</Typography>
              </td>
              <td className="px-[16px]">
                <Typography>
                  {convertYearCost(
                    inputYearCost.homecoming,
                    inputYearCost.homecoming_frequency_year,
                    inputYearCost.homecoming_frequency_number,
                  )}
                </Typography>
              </td>
            </tr>
            <tr className="h-[45px] odd:bg-black-100">
              <td className="px-[16px]">
                <Typography isBold>冠婚葬祭</Typography>
              </td>
              <td className="px-[16px]">
                <Typography>
                  {`年間${convertLocaleString(
                    inputYearCost.ceremonial_occasion_yearly,
                  )}円`}
                </Typography>
              </td>
            </tr>
            <tr className="h-[45px] odd:bg-black-100">
              <td className="px-[16px]">
                <Typography isBold>旅行</Typography>
              </td>
              <td className="px-[16px]">
                <Typography>
                  {convertYearCost(
                    inputYearCost.travel,
                    inputYearCost.travel_frequency_year,
                    inputYearCost.travel_frequency_number,
                  )}
                </Typography>
              </td>
            </tr>
            {inputYearCost.input_year_cost_others.map((item) => (
              <tr key={item.id} className="h-[45px] odd:bg-black-100">
                <td className="px-[16px]">
                  <Typography isBold>
                    {defaultToEmptyString(item.journal_name)}
                  </Typography>
                </td>
                <td className="px-[16px]">
                  <Typography>
                    {`年間${convertLocaleString(item.cost_yearly)}円`}
                  </Typography>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {_isNull(inputYearCost) && input_yearly_living_cost_electronics.length !== 0 && (
        <>
          <Typography className="border-b-[0.5px] pb-[5px] md:mb-[10px]">家電</Typography>
          <table className="w-[100%] text-[12px] md:text-[14px]">
            <tbody>
              <tr className="hidden md:table-row h-[45px] odd:bg-black-100">
                <td className="w-full md:w-[25%] px-[16px]">
                  <Typography></Typography>
                </td>
                <td className="hidden md:table-cell w-[25%] pr-[16px]">
                  <Typography isBold>
                    次回買い替え
                    <br />
                    時期
                  </Typography>
                </td>
                <td className="hidden md:table-cell w-[25%] pr-[16px]">
                  <Typography isBold>買い替え頻度</Typography>
                </td>
                <td className="hidden md:table-cell w-[25%] pr-[16px]">
                  <Typography isBold>
                    1回あたりの
                    <br />
                    費用
                  </Typography>
                </td>
              </tr>
              {input_yearly_living_cost_electronics.map((item: any, index: number) => {
                return (
                  item.is_active && (
                    <tr
                      className="h-[45px] odd:bg-black-100 border-b-[0.5px] md:border-none"
                      key={`yearly_electronics_${item.id}_${index}`}
                    >
                      <td className="w-full md:w-[25%] block md:table-cell mb-[10px] mt-[20px] px-[10px] md:px-[16px]">
                        <Typography className="md:font-bold">
                          {item.display_name}
                        </Typography>
                      </td>
                      <td className="w-full md:w-[25%] block md:table-cell my-[10px] ml-[10px] pr-[16px]">
                        <div className="flex">
                          <Typography isBold className="block md:hidden w-[50%]">
                            次回買い替え時期
                          </Typography>
                          <Typography>
                            ご本人が
                            <br className="hidden md:block" />
                            {item.next_purchase_at_age}歳の時
                          </Typography>
                        </div>
                      </td>
                      <td className="w-full md:w-[25%] block md:table-cell my-[10px] ml-[10px] pr-[16px]">
                        <div className="flex">
                          <Typography isBold className="block md:hidden w-[50%]">
                            買い替え頻度
                          </Typography>
                          <Typography>{item.occurance_in_years}年ごと</Typography>
                        </div>
                      </td>
                      <td className="w-full md:w-[25%] block md:table-cell mt-[10px] mb-[20px] ml-[10px] pr-[16px]">
                        <div className="flex">
                          <Typography isBold className="block md:hidden w-[50%]">
                            1回あたりの費用
                          </Typography>
                          <Typography>{item.single_cost.toLocaleString()}円</Typography>
                        </div>
                      </td>
                    </tr>
                  )
                )
              })}
            </tbody>
          </table>

          <Typography className="border-b-[0.5px] pb-[5px] mb-[10px]">
            冠婚葬祭
          </Typography>
          <table className="w-[100%] mb-[60px] last:mb-0 text-[12px] md:text-[14px]">
            <tbody>
              <tr className="h-[45px] odd:bg-black-100 border-b-[0.5px] md:border-none">
                <td className="w-[50%] px-[16px]">
                  <Typography isBold>ご本人の年齢</Typography>
                </td>
                <td className="w-[50%] px-[16px]">
                  <Typography className="min-w-[max-content]" isBold>
                    冠婚葬祭にかかる費用
                  </Typography>
                </td>
              </tr>

              {input_yearly_living_cost_ceremonies.map((item: any, index: number) => (
                <tr
                  className="h-[45px] odd:bg-black-100"
                  key={`yearly_ceremonies_${item.id}_${index}`}
                >
                  <td className="w-[50%] px-[16px]">
                    <Typography>
                      {item.affected_start_at_age}歳から{item.affected_end_at_age}歳
                    </Typography>
                  </td>
                  <td className="w-[50%] px-[16px]">
                    <Typography>{item.yearly_cost.toLocaleString()}円/年</Typography>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <Typography className="border-b-[0.5px] pb-[5px] mb-[10px]">帰省</Typography>
          <table className="w-[100%] mb-[60px] last:mb-0 text-[12px] md:text-[14px]">
            <tbody>
              {input_yearly_living_cost_home_comings.map((item: any, index: number) => (
                <React.Fragment key={`yearly_home_comings_${item.id}_${index}`}>
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="w-[50%] px-[16px]">
                      <Typography isBold>費用</Typography>
                    </td>
                    <td className="w-[50%] px-[16px]">
                      <Typography>{item.single_cost.toLocaleString()}円</Typography>
                    </td>
                  </tr>
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="w-[50%] px-[16px]">
                      <Typography isBold>頻度</Typography>
                    </td>
                    <td className="w-[50%] px-[16px]">
                      <Typography>
                        {item.occurance_per_occurance_term}年に
                        {item.occurance_term_in_years}回
                      </Typography>
                    </td>
                  </tr>
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="w-[50%] px-[16px]">
                      <Typography isBold>適用頻度</Typography>
                    </td>
                    <td className="w-[50%] px-[16px]">
                      <Typography>
                        {item.affected_start_at_age}歳から
                        {item.affected_end_at_age}
                        歳まで
                      </Typography>
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>

          <Typography className="border-b-[0.5px] pb-[5px] mb-[10px]">旅行</Typography>
          <table className="w-[100%] mb-[60px] last:mb-0 text-[12px] md:text-[14px]">
            <tbody>
              {input_yearly_living_cost_travels.map((item: any, index: number) => (
                <React.Fragment key={`yearly_travels_${item.id}_${index}`}>
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="w-[50%] px-[16px]">
                      <Typography isBold>費用</Typography>
                    </td>
                    <td className="w-[50%] px-[16px]">
                      <Typography>{item.single_cost.toLocaleString()}円</Typography>
                    </td>
                  </tr>
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="w-[50%] px-[16px]">
                      <Typography isBold>頻度</Typography>
                    </td>
                    <td className="w-[50%] px-[16px]">
                      <Typography>
                        {item.occurance_per_occurance_term}年に
                        {item.occurance_term_in_years}回
                      </Typography>
                    </td>
                  </tr>
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="w-[50%] px-[16px]">
                      <Typography isBold>適用頻度</Typography>
                    </td>
                    <td className="w-[50%] px-[16px]">
                      <Typography>
                        {item.affected_start_at_age}歳から{item.affected_end_at_age}
                        歳まで
                      </Typography>
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </>
      )}

      {_isNull(inputYearCost) && input_yearly_living_cost_electronics.length === 0 && (
        <Typography className="text-[14px] md:text-[16px] text-center" isBold>
          特別な支出・大きな支出が登録されていません。
        </Typography>
      )}
    </>
  )
}

const InputYearlyLivingCostBody: FC<{ onUpdate: () => void }> = (props) => {
  const { onUpdate } = props
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const closeModal = () => setIsOpenModal(false)
  const openModal = () => setIsOpenModal(true)

  const { input_yearly_living_cost_electronics } = useSelector(
    (state: RootState) => state.inputYearlyLivingCosts,
  )
  const inputedYearCost = input_yearly_living_cost_electronics.length !== 0

  return (
    <>
      <SimulationAccordion className="pb-[16px]" title="現在の特別な支出・大きな支出">
        {inputedYearCost && (
          <div className="flex justify-end mb-[8px]">
            <Button onClick={openModal}>
              <i className="ico edit text-[14px] text-black-700" />
              <Typography className="pl-[5px] text-[14px] text-black-700">
                編集
              </Typography>
            </Button>
          </div>
        )}
        <YearlyLivingCosstTable />
        {!inputedYearCost && <SimulationNewButton onClick={openModal} />}
      </SimulationAccordion>
      <Modal className="lg:w-[1024px] w-[95%]" isOpen={isOpenModal} onClose={closeModal}>
        <CircleTitle className="pb-[32px]" title="現在の特別な支出・大きな支出" />
        <MyDataYearlyLivingCostEditForm
          onClose={closeModal}
          onSubmit={() => {
            closeModal()
            onUpdate()
          }}
        />
      </Modal>
    </>
  )
}

export default InputYearlyLivingCostBody
