import moment from 'moment'
import {
  every as _every,
  filter as _filter,
  isEmpty as _isEmpty,
  isNull as _isNull,
  isUndefined as _isUndefined,
  pullAt as _pullAt,
} from 'lodash'
import {
  addZeroToStringMonth,
  API_FLAG,
  castNumberWithoutOperator,
  extractDayFromDate,
  extractYearOrMonthFromDate,
  THIS_MONTH_NUMBER,
  THIS_YEAR_NUMBER,
  isNotEmptyValue,
  convertLocaleString,
  API_BOOLEAN_FLAG,
  convertDateToTwoTypesYearsDateFormat,
} from '@/models/commonsModelFunc'
import { isFlatLoan, isWithCalcLoan, HOUSE_TYPE } from '@/models/commonHousingModelFunc'
import { PERSON_FLG } from '@/models/familyStructuresModelFunc'
import {
  findCurrentRentHouse,
  INPUT_HOUSING_RENT_TYPE,
} from '@/models/inputs/inputHousingRentsModelFunc'
import { InputHousing } from '@/models/inputs/inputHousingsModel'
import { InputHousingSelfOwned } from '@/models/inputs/inputHousingSelfOwnedModel'
import { isSplitedLoan } from '@/models/inputs/inputHousingSelfOwnedModelFunc'
import { InputLoan } from '@/models/inputs/inputLoansModel'
import { isOneLoanPattern, isTwoLoanPattern } from '@/models/inputs/inputLoansModelFunc'
import { InputLoanRate } from '@/models/inputs/inputLoanRatesModel'

// 修繕積立上昇率の設定
export const INPUT_REPAIR_RESERVE_FUND_RATE_TYPE = {
  change: 0,
  fixed: 1,
  noSet: 2,
}

// 繰上げ返済方法
export const REPAYMENT_TYPE = {
  shortening: 0,
  reduction: 1,
}

// 返済計画表あり：金利タイプ
export const RATE_TYPE = {
  monthly: 0,
  bonus: 1,
}

export const isRentHouseType = (houseType: number | null | undefined) => {
  return houseType == HOUSE_TYPE.rent
}

export const isNotSelfHouseType = (houseType: number | null | undefined) => {
  return houseType == HOUSE_TYPE.notSelf
}

export const isSelfOwnedHouseType = (houseType: number | null | undefined) => {
  return houseType == HOUSE_TYPE.own
}

export const hasTransferHouse = (transferType: number | null | undefined) => {
  return transferType == API_FLAG.on
}

const isUndefinedInputHousingInitialValue = (
  inputHousing: Partial<InputHousing>,
  name: 'transfer' | 'rents' | 'self_owned',
) => {
  switch (name) {
    case 'transfer':
      return _isUndefined(inputHousing.input_housing_transfer)
    case 'rents':
      return (
        _isUndefined(inputHousing.input_housing_rents) ||
        _isEmpty(inputHousing.input_housing_rents)
      )
    case 'self_owned':
      return _isUndefined(inputHousing.input_housing_self_owned)
    default:
      break
  }
  return true
}

const isUndefinedUnderInputLoanObject = (
  inputLoans: Partial<InputLoan>[] | undefined,
  name:
    | 'input_loans'
    | 'input_loans[index]'
    | 'input_loan_chart'
    | 'input_loan_rates'
    | 'input_loan_calculation'
    | 'input_calculation_rates'
    | 'input_advances',
  index?: number,
) => {
  if (_isUndefined(inputLoans)) return true

  switch (name) {
    case 'input_loans':
      return _isUndefined(inputLoans)
    case 'input_loans[index]':
      if (_isUndefined(index)) return true
      return _isUndefined(inputLoans[index]) || _isEmpty(inputLoans[index])
    case 'input_loan_chart':
      if (_isUndefined(index)) return true
      return (
        _isUndefined(inputLoans[index]?.input_loan_chart) ||
        _isNull(inputLoans[index]?.input_loan_chart)
      )
    case 'input_loan_rates':
      if (_isUndefined(index)) return true
      return (
        _isUndefined(inputLoans[index]?.input_loan_chart?.input_loan_rates) ||
        _isEmpty(inputLoans[index]?.input_loan_chart?.input_loan_rates)
      )
    case 'input_loan_calculation':
      if (_isUndefined(index)) return true
      return (
        _isUndefined(inputLoans[index]?.input_loan_calculation) ||
        _isNull(inputLoans[index]?.input_loan_calculation)
      )
    case 'input_calculation_rates':
      if (_isUndefined(index)) return true
      return (
        _isUndefined(
          inputLoans[index]?.input_loan_calculation?.input_calculation_rates,
        ) || _isEmpty(inputLoans[index]?.input_loan_calculation?.input_calculation_rates)
      )
    case 'input_advances':
      if (_isUndefined(index)) return true
      return (
        _isUndefined(inputLoans[index]?.input_loan_calculation?.input_advances) ||
        _isEmpty(inputLoans[index]?.input_loan_calculation?.input_advances)
      )
    default:
      true
      break
  }
  return true
}

export const convertHousingTitle = (
  isHome: boolean,
  isSelfOwned: boolean | undefined,
) => {
  if (_isUndefined(isSelfOwned)) return '単身赴任先'
  return !isHome ? '単身赴任先' : !isSelfOwned ? '賃貸・社宅' : '自宅'
}

export const convertLandOrBuildingLoanTitle = (
  index: number | null | undefined,
  inputSelfOwnedAttributes: Partial<InputHousingSelfOwned> | undefined,
) => {
  if (!isNotEmptyValue(inputSelfOwnedAttributes?.input_loans)) return

  const { input_loans: inputLoans } = inputSelfOwnedAttributes!

  if (index === 0) return '土地用ローン'
  if (index === 1 && isOneLoanPattern(inputLoans![0])) return '建物用ローン'
  if (index === 2 && isTwoLoanPattern(inputLoans![1])) return '建物用ローン'

  return ''
}

export const convertHouseType = (houseType: number | null) => {
  if (houseType === HOUSE_TYPE.rent) return '賃貸・社宅'
  if (houseType === HOUSE_TYPE.own) return '自己保有'
  if (houseType === HOUSE_TYPE.transfer) return '単身赴任先'

  return ''
}

export const convertRepaymentChart = (repaymentChart: number | null | undefined) => {
  switch (repaymentChart) {
    case API_FLAG.on:
      return 'ある'
    case API_FLAG.off:
      return 'ない'
    default:
      return 'ない'
  }
}

export const convertAdvanceType = (repaymentType: number | null | undefined) => {
  switch (repaymentType) {
    case REPAYMENT_TYPE.shortening:
      return '期間短縮型'
    case REPAYMENT_TYPE.reduction:
      return '返済額軽減型'
    default:
      return '返済額軽減型'
  }
}

export const convertAdvanceValues = (
  repaymentAtMonthAt: string | null | undefined,
  repaymentAmount: number | null | undefined,
) => {
  if (_isUndefined(repaymentAtMonthAt) || _isUndefined(repaymentAmount)) return ''

  const formatYear = convertDateToTwoTypesYearsDateFormat(
    moment(repaymentAtMonthAt).format('YYYY'),
    'japaneseYear',
  )

  return `${formatYear}年${extractYearOrMonthFromDate(
    repaymentAtMonthAt,
    'month',
  )}月 ${convertLocaleString(repaymentAmount)}円`
}

export const filterInputRatesInitialValue = (
  inputLoanRates: Partial<InputLoanRate>[],
) => {
  const copiedInputLoanRates = [...inputLoanRates]
  _pullAt(copiedInputLoanRates)

  return _filter(copiedInputLoanRates, (item) => _isNull(item.rate_start_at))
}

export const filterInputRatesMonthlyOrBonusInitialValue = (
  inputLoanRates: Partial<InputLoanRate>[],
  name: 'monthly' | 'bonus',
) => {
  const copiedInputLoanRates = [...inputLoanRates]

  if (name === 'monthly') {
    return _filter(
      copiedInputLoanRates,
      (item) => item.rate_type === RATE_TYPE.monthly && !_isNull(item.rate_start_at),
    )
  }
  if (name === 'bonus') {
    return _filter(
      copiedInputLoanRates,
      (item) => item.rate_type === RATE_TYPE.bonus && !_isNull(item.rate_start_at),
    )
  }

  return inputLoanRates
}

const formattedMonthNumber = addZeroToStringMonth(THIS_MONTH_NUMBER)

const inputLoanRateDefaultValues = (rateType: number) => {
  return {
    rate_type: rateType,
  }
}

const inputLoanChartDefaultValues = (personFlg: number) => {
  return {
    person_flg: personFlg,
    repayment_date_this_month_at_year: THIS_YEAR_NUMBER,
    repayment_date_this_month_at_month: formattedMonthNumber,
    input_loan_rates_attributes: [
      { ...inputLoanRateDefaultValues(RATE_TYPE.monthly) },
      { ...inputLoanRateDefaultValues(RATE_TYPE.bonus) },
    ],
    input_loan_rates_monthly_attributes: [
      { ...inputLoanRateDefaultValues(RATE_TYPE.monthly) },
    ],
    input_loan_rates_bonus_attributes: [
      { ...inputLoanRateDefaultValues(RATE_TYPE.bonus) },
    ],
  }
}

const inputLoanCalculationDefaultValues = (personFlg: number) => {
  return {
    person_flg: personFlg,
    repayment_rate: 2.475,
    input_advances_attributes: [{}],
    input_calculation_rates_attributes: [{}],
    input_calculation_rates_other_attributes: [{}],
  }
}

const inputLoanDefaultValues = (personFlg: number) => {
  return {
    input_loan_chart_attributes: { ...inputLoanChartDefaultValues(personFlg) },
    input_loan_calculation_attributes: {
      ...inputLoanCalculationDefaultValues(personFlg),
    },
  }
}

const inputHousingSelfOwnedDefaultValues = {
  is_management_and_fund_total: API_BOOLEAN_FLAG.off,
  repair_reserve_fund_increase_start: 5,
  repair_reserve_fund_increase_span: 5,
  repair_reserve_fund_increase_finish: 20,
  repair_reserve_fund_increase_percent: 20,
  input_loans_attributes: [
    { ...inputLoanDefaultValues(PERSON_FLG.person) },
    { ...inputLoanDefaultValues(PERSON_FLG.spouse) },
    { ...inputLoanDefaultValues(PERSON_FLG.person) },
    { ...inputLoanDefaultValues(PERSON_FLG.spouse) },
  ],
}

const buildInputLoanCalculationInitialValues = (
  inputLoans: Partial<InputLoan>[],
  loanIndex: number,
  personFlg: number,
) => {
  const inputLoan = inputLoans[loanIndex]

  return {
    ...inputLoan.input_loan_calculation,
    advance_repayment: String(inputLoan.input_loan_calculation?.advance_repayment),
    bonus_flg: String(inputLoan.input_loan_calculation?.bonus_flg),
    life_insurance: _isNull(inputLoan.input_loan_calculation?.life_insurance)
      ? null
      : String(inputLoan.input_loan_calculation?.life_insurance),
    married_couple_group_shin: _isNull(
      inputLoan.input_loan_calculation?.married_couple_group_shin,
    )
      ? null
      : String(inputLoan.input_loan_calculation?.married_couple_group_shin),
    loan_deduction: String(inputLoan.input_loan_calculation?.loan_deduction),
    person_flg: personFlg,
    input_calculation_rates_attributes: !isUndefinedUnderInputLoanObject(
      inputLoans,
      'input_calculation_rates',
      loanIndex,
    )
      ? [
          ..._filter(inputLoan.input_loan_calculation!.input_calculation_rates!, (item) =>
            _isNull(item.rate_start_at),
          ),
        ]
      : [{}],
    input_calculation_rates_other_attributes:
      !isUndefinedUnderInputLoanObject(
        inputLoans,
        'input_calculation_rates',
        loanIndex,
      ) &&
      !_every(inputLoan.input_loan_calculation!.input_calculation_rates!, (item) =>
        _isNull(item.rate_start_at),
      )
        ? [
            ..._filter(
              inputLoan.input_loan_calculation!.input_calculation_rates!,
              (item) => !_isNull(item.rate_start_at),
            ).map((item) => {
              return {
                ...item,
                rate_start_at_year: extractYearOrMonthFromDate(
                  item.rate_start_at,
                  'year',
                ),
              }
            }),
          ]
        : [{}],
    input_advances_attributes: !isUndefinedUnderInputLoanObject(
      inputLoans,
      'input_advances',
      loanIndex,
    )
      ? [
          ...inputLoan.input_loan_calculation!.input_advances!.map((item) => {
            return {
              ...item,
              advance_repayment_month_at_year: extractYearOrMonthFromDate(
                item.advance_repayment_month_at,
                'year',
              ),
              advance_repayment_month_at_month: extractYearOrMonthFromDate(
                item.advance_repayment_month_at,
                'month',
              ),
            }
          }),
        ]
      : [{}],
  }
}

const buildInputLoanChartInitialValues = (
  inputLoans: Partial<InputLoan>[],
  loanIndex: number,
  personFlg: number,
) => {
  const inputLoan = inputLoans[loanIndex]

  return {
    ...inputLoan.input_loan_chart,
    person_flg: personFlg,
    bonus_flg: String(inputLoan.input_loan_chart?.bonus_flg),
    rate_change_monthly: String(inputLoan.input_loan_chart?.rate_change_monthly),
    repayment_rate_change_bonus: String(
      inputLoan.input_loan_chart?.repayment_rate_change_bonus,
    ),
    life_insurance: _isNull(inputLoan.input_loan_chart?.life_insurance)
      ? null
      : String(inputLoan.input_loan_chart?.life_insurance),
    married_couple_group_shin: _isNull(
      inputLoan.input_loan_chart?.married_couple_group_shin,
    )
      ? null
      : String(inputLoan.input_loan_chart?.married_couple_group_shin),
    loan_deduction: String(inputLoan.input_loan_chart?.loan_deduction),
    repayment_end_at_year: extractYearOrMonthFromDate(
      inputLoan.input_loan_chart?.repayment_end_at,
      'year',
    ),
    repayment_end_at_month: extractYearOrMonthFromDate(
      inputLoan.input_loan_chart?.repayment_end_at,
      'month',
    ),
    repayment_end_at_day: String(
      extractDayFromDate(inputLoan.input_loan_chart?.repayment_end_at),
    ),
    repayment_date_this_month_at_year: extractYearOrMonthFromDate(
      inputLoan.input_loan_chart?.repayment_date_this_month_at,
      'year',
    ),
    repayment_date_this_month_at_month: addZeroToStringMonth(
      extractYearOrMonthFromDate(
        inputLoan.input_loan_chart?.repayment_date_this_month_at,
        'month',
      ),
    ),
    repayment_date_this_month_at_day: String(
      extractDayFromDate(inputLoan.input_loan_chart?.repayment_date_this_month_at),
    ),
    input_loan_rates_attributes: !isUndefinedUnderInputLoanObject(
      inputLoans,
      'input_loan_rates',
      loanIndex,
    )
      ? [
          ...filterInputRatesInitialValue(
            inputLoan.input_loan_chart!.input_loan_rates!,
          ).map((item) => {
            return {
              ...item,
              rate_start_at_year: extractYearOrMonthFromDate(item.rate_start_at, 'year'),
              rate_start_at_month: extractYearOrMonthFromDate(
                item.rate_start_at,
                'month',
              ),
            }
          }),
        ]
      : [
          { ...inputLoanRateDefaultValues(RATE_TYPE.monthly) },
          { ...inputLoanRateDefaultValues(RATE_TYPE.bonus) },
        ],
    input_loan_rates_monthly_attributes:
      !isUndefinedUnderInputLoanObject(inputLoans, 'input_loan_rates', loanIndex) &&
      inputLoan.input_loan_chart!.input_loan_rates!.length >= 2 &&
      !_every(inputLoan.input_loan_chart!.input_loan_rates, (item) =>
        _isNull(item.rate_start_at),
      )
        ? [
            ...filterInputRatesMonthlyOrBonusInitialValue(
              inputLoan.input_loan_chart!.input_loan_rates!,
              'monthly',
            ).map((item) => {
              return {
                ...item,
                rate_type: RATE_TYPE.monthly,
                rate_start_at_year: extractYearOrMonthFromDate(
                  item.rate_start_at,
                  'year',
                ),
                rate_start_at_month: extractYearOrMonthFromDate(
                  item.rate_start_at,
                  'month',
                ),
              }
            }),
          ]
        : [{ ...inputLoanRateDefaultValues(RATE_TYPE.monthly) }],
    input_loan_rates_bonus_attributes:
      !isUndefinedUnderInputLoanObject(inputLoans, 'input_loan_rates', loanIndex) &&
      inputLoan.input_loan_chart!.input_loan_rates!.length >= 2 &&
      !_every(inputLoan.input_loan_chart!.input_loan_rates, (item) =>
        _isNull(item.rate_start_at),
      )
        ? [
            ...filterInputRatesMonthlyOrBonusInitialValue(
              inputLoan.input_loan_chart!.input_loan_rates!,
              'bonus',
            ).map((item) => {
              return {
                ...item,
                rate_type: RATE_TYPE.bonus,
                rate_start_at_year: extractYearOrMonthFromDate(
                  item.rate_start_at,
                  'year',
                ),
                rate_start_at_month: extractYearOrMonthFromDate(
                  item.rate_start_at,
                  'month',
                ),
              }
            }),
          ]
        : [{ ...inputLoanRateDefaultValues(RATE_TYPE.bonus) }],
  }
}

const buildInputLoanInitialValues = (
  inputLoans: Partial<InputLoan>[],
  loanIndex: number,
  personFlg: number,
) => {
  const inputLoan = inputLoans[loanIndex]

  return {
    ...inputLoan,
    loan_lender: String(inputLoan.loan_lender),
    repayment_start_at_year: extractYearOrMonthFromDate(
      inputLoan.repayment_start_at,
      'year',
    ),
    repayment_start_at_month: extractYearOrMonthFromDate(
      inputLoan.repayment_start_at,
      'month',
    ),
    input_loan_chart_attributes: !isUndefinedUnderInputLoanObject(
      inputLoans,
      'input_loan_chart',
      loanIndex,
    )
      ? { ...buildInputLoanChartInitialValues(inputLoans, loanIndex, personFlg) }
      : { ...inputLoanChartDefaultValues(personFlg) },

    input_loan_calculation_attributes: !isUndefinedUnderInputLoanObject(
      inputLoans,
      'input_loan_calculation',
      loanIndex,
    )
      ? { ...buildInputLoanCalculationInitialValues(inputLoans, loanIndex, personFlg) }
      : { ...inputLoanCalculationDefaultValues(personFlg) },
  }
}

// 新規作成時-初期値データ
export const createInitialValues = {
  input_housing: {
    input_housing_transfer_attributes: {},
    input_housing_rents_attributes: [{}],
    input_housing_self_owned_attributes: {
      input_loans_attributes: [
        { ...inputLoanDefaultValues(PERSON_FLG.person) },
        { ...inputLoanDefaultValues(PERSON_FLG.spouse) },
        { ...inputLoanDefaultValues(PERSON_FLG.person) },
        { ...inputLoanDefaultValues(PERSON_FLG.spouse) },
      ],
    },
  },
}

const buildInputHousingSelfOwnedInitialValues = (
  selfOwned: Partial<InputHousingSelfOwned>,
  inputLoans: Partial<InputLoan>[],
) => {
  return {
    ...selfOwned,
    is_management_and_fund_total: !_isNull(
      selfOwned?.management_fee_monthly_and_repair_reserve_fund_total,
    )
      ? API_BOOLEAN_FLAG.on
      : API_BOOLEAN_FLAG.off,
    repair_reserve_fund_amount_start: _isNull(selfOwned?.repair_reserve_fund_amount_start)
      ? 5
      : selfOwned?.repair_reserve_fund_amount_start,

    repair_reserve_fund_increase_start: _isNull(
      selfOwned?.repair_reserve_fund_increase_start,
    )
      ? 5
      : selfOwned?.repair_reserve_fund_increase_start,

    repair_reserve_fund_increase_span: _isNull(
      selfOwned?.repair_reserve_fund_increase_span,
    )
      ? 5
      : selfOwned?.repair_reserve_fund_increase_span,
    repair_reserve_fund_increase_finish: _isNull(
      selfOwned?.repair_reserve_fund_increase_finish,
    )
      ? 20
      : selfOwned?.repair_reserve_fund_increase_finish,
    repair_reserve_fund_increase_percent: _isNull(
      selfOwned?.repair_reserve_fund_increase_percent,
    )
      ? 20
      : selfOwned?.repair_reserve_fund_increase_percent,
    constructed_at_year: extractYearOrMonthFromDate(selfOwned!.constructed_at, 'year'),
    purchased_at_year: extractYearOrMonthFromDate(selfOwned!.purchased_at, 'year'),
    input_loans_attributes: !isUndefinedUnderInputLoanObject(inputLoans, 'input_loans')
      ? [
          // 以下input_loans[0] 初期値
          !isUndefinedUnderInputLoanObject(inputLoans, 'input_loans[index]', 0)
            ? {
                ...buildInputLoanInitialValues(inputLoans, 0, PERSON_FLG.person),
              }
            : { ...inputLoanDefaultValues(PERSON_FLG.person) },

          // 以下input_loans[1] 初期値
          !isUndefinedUnderInputLoanObject(inputLoans, 'input_loans[index]', 1)
            ? {
                ...buildInputLoanInitialValues(inputLoans, 1, PERSON_FLG.spouse),
              }
            : { ...inputLoanDefaultValues(PERSON_FLG.spouse) },

          // 以下input_loans[2] 初期値
          !isUndefinedUnderInputLoanObject(inputLoans, 'input_loans[index]', 2)
            ? {
                ...buildInputLoanInitialValues(inputLoans, 2, PERSON_FLG.spouse),
              }
            : { ...inputLoanDefaultValues(PERSON_FLG.person) },

          // 以下input_loans[3] 初期値
          !isUndefinedUnderInputLoanObject(inputLoans, 'input_loans[index]', 3)
            ? {
                ...buildInputLoanInitialValues(inputLoans, 3, PERSON_FLG.spouse),
              }
            : { ...inputLoanDefaultValues(PERSON_FLG.spouse) },
        ]
      : // input_loans undefined
        [
          { ...inputLoanDefaultValues(PERSON_FLG.person) },
          { ...inputLoanDefaultValues(PERSON_FLG.spouse) },
          { ...inputLoanDefaultValues(PERSON_FLG.person) },
          { ...inputLoanDefaultValues(PERSON_FLG.spouse) },
        ],
  }
}

export const handleSetInitialValues = (inputHousing: InputHousing | undefined) => {
  if (_isUndefined(inputHousing))
    return {
      ...createInitialValues,
      input_housing: {
        ...createInitialValues.input_housing,
        input_housing_id: 0,
      },
    }

  const {
    input_housing_rents,
    input_housing_transfer,
    input_housing_self_owned,
  } = inputHousing

  const currentInputHousingRent = findCurrentRentHouse(input_housing_rents)

  // MEMO: 以下二つは自動計算するための値のため、
  //       編集時は計算要素が初期値として存在する場合、逆算して初期値を設定
  //   - input_housing.started_borrowing_total_amount_land_manyen
  //   - input_housing.started_borrowing_total_amount_building_manyen
  const calcStartedBorrowingTotalAmount = (
    inputLoans: Partial<InputLoan>[],
    isBuilding: boolean,
  ) => {
    if (_isEmpty(inputLoans)) return

    if (
      !isBuilding &&
      isWithCalcLoan(inputLoans[0].repayment_chart) &&
      isFlatLoan(inputLoans[0].loan_lender)
    ) {
      return (
        castNumberWithoutOperator(
          inputLoans[0].input_loan_calculation?.started_borrowing_amount_manyen,
        ) +
        castNumberWithoutOperator(
          inputLoans[1].input_loan_calculation?.started_borrowing_amount_manyen,
        )
      )
    }

    if (
      inputLoans[2] &&
      inputLoans[3] &&
      isWithCalcLoan(inputLoans[2].repayment_chart) &&
      isFlatLoan(inputLoans[2].loan_lender)
    ) {
      return (
        castNumberWithoutOperator(
          inputLoans[2].input_loan_calculation?.started_borrowing_amount_manyen,
        ) +
        castNumberWithoutOperator(
          inputLoans[3].input_loan_calculation?.started_borrowing_amount_manyen,
        )
      )
    }

    return
  }

  // MEMO: ローン分割時のinput_loansは以下構成で固定しているため、
  //       この構成に合わせる必要があるので、配列の順番を調節
  //  [0]: ローンあり全パターン
  //  [1]: 分割なし/あり・土地/建物・ペア本人/配偶者・フラット
  //  [2]: 分割あり・土地/建物・ペア本人/配偶者・フラット
  //  [3]: 分割あり・建物・ペア配偶者・フラット
  let reBuiltInputLoans = isUndefinedUnderInputLoanObject(
    input_housing_self_owned?.input_loans,
    'input_loans',
  )
    ? []
    : [...inputHousing.input_housing_self_owned!.input_loans]

  if (!_isNull(input_housing_self_owned) && isSplitedLoan(input_housing_self_owned)) {
    const { input_loans } = input_housing_self_owned

    if (
      input_loans.length === 2 &&
      isOneLoanPattern(input_loans[0]) &&
      isOneLoanPattern(input_loans[1])
    ) {
      reBuiltInputLoans[2] = [...input_loans][1]
      reBuiltInputLoans[1] = {}
    }

    if (
      input_loans.length === 3 &&
      !isFlatLoan(input_loans[0].loan_lender) &&
      isOneLoanPattern(input_loans[0]) &&
      isTwoLoanPattern(input_loans[2])
    ) {
      reBuiltInputLoans[3] = [...input_housing_self_owned.input_loans][2]
      reBuiltInputLoans[2] = [...input_housing_self_owned.input_loans][1]
      reBuiltInputLoans[1] = {}
    }
  }

  return {
    id: inputHousing.id,
    started_borrowing_total_amount_land_manyen: calcStartedBorrowingTotalAmount(
      reBuiltInputLoans,
      false,
    ),
    started_borrowing_total_amount_building_manyen: calcStartedBorrowingTotalAmount(
      reBuiltInputLoans,
      true,
    ),
    input_housing: {
      ...inputHousing,
      house_type: !isRentHouseType(inputHousing.house_type)
        ? inputHousing.house_type
        : currentInputHousingRent?.rent_type === INPUT_HOUSING_RENT_TYPE.selfRent
        ? HOUSE_TYPE.rent
        : HOUSE_TYPE.notSelf,
      input_housing_id: inputHousing.id,
      transfer_type:
        !isUndefinedInputHousingInitialValue(inputHousing, 'transfer') &&
        !_isNull(input_housing_transfer)
          ? String(API_FLAG.on)
          : String(API_FLAG.off),
      input_housing_transfer_attributes:
        !isUndefinedInputHousingInitialValue(inputHousing, 'transfer') &&
        !_isNull(input_housing_transfer)
          ? {
              ...input_housing_transfer,
              transfer_update_type: !_isNull(
                input_housing_transfer!.transfer_update_month_at,
              )
                ? String(API_FLAG.on)
                : String(API_FLAG.off),
              transfer_update_month_at_year: !_isNull(
                input_housing_transfer.transfer_update_month_at,
              )
                ? extractYearOrMonthFromDate(
                    input_housing_transfer.transfer_update_month_at,
                    'year',
                  )
                : null,
              transfer_update_month_at_month: !_isNull(
                input_housing_transfer.transfer_update_month_at,
              )
                ? extractYearOrMonthFromDate(
                    input_housing_transfer.transfer_update_month_at,
                    'month',
                  )
                : null,
            }
          : {},
      input_housing_rents_attributes:
        !_isNull(currentInputHousingRent) && !_isUndefined(currentInputHousingRent)
          ? [
              {
                ...currentInputHousingRent,
                next_update_type: !_isNull(currentInputHousingRent.next_update_month_at)
                  ? String(API_FLAG.on)
                  : String(API_FLAG.off),
                next_update_year: !_isNull(currentInputHousingRent.next_update_month_at)
                  ? extractYearOrMonthFromDate(
                      currentInputHousingRent.next_update_month_at,
                      'year',
                    )
                  : null,
                next_update_month: !_isNull(currentInputHousingRent.next_update_month_at)
                  ? extractYearOrMonthFromDate(
                      currentInputHousingRent!.next_update_month_at,
                      'month',
                    )
                  : null,
              },
            ]
          : [{}],
      input_housing_self_owned_attributes:
        !isUndefinedInputHousingInitialValue(inputHousing, 'self_owned') &&
        !_isNull(input_housing_self_owned)
          ? {
              ...buildInputHousingSelfOwnedInitialValues(
                input_housing_self_owned,
                reBuiltInputLoans,
              ),
            }
          : // input_housing_self_owned undefined
            { ...inputHousingSelfOwnedDefaultValues },
    },
  }
}
