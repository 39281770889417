import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  isUndefined as _isUndefined,
  find as _find,
  includes as _includes,
  isNull as _isNull,
  isEmpty as _isEmpty,
} from 'lodash'
import Typography from '@/components/v2/atoms/Typography'
import {
  convertLocaleString,
  combineTwoTypesYear,
  extractYearOrMonthFromDate,
  isNotEmptyValue,
} from '@/models/commonsModelFunc'
import { PERSON_FLG, PROFESSION } from '@/models/familyStructuresModelFunc'
import { InputBonus } from '@/models/inputs/inputBonusesModel'
import {
  convertProfession,
  convertInsurancePensionType,
  convertPersonalBusinessTaxRate,
  convertIndustry,
  convertEmployeeSize,
  convertPersonalBusinessAoiroDeduction,
} from '@/models/inputs/inputSalariesModelFunc'
import { RootState } from '@/store'
import SimulationAccordion from '@/templates/v2/simulations/_simulationAccordion'
import Modal from '@/components/v2/atoms/Modal'
import MyDataPersonEditForm from '@/templates/v2/myData/salaries/Form'
import SimulationNewButton from '@/templates/v2/simulations/_simulationNewButton'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import Button from '@/components/v2/atoms/Button'

const notEmployedGroup = [PROFESSION.student, PROFESSION.housewife, PROFESSION.other]
const NotBelongToCompanyGroup = [
  PROFESSION.freelance,
  PROFESSION.student,
  PROFESSION.other,
  PROFESSION.housewife,
]

interface InputSalaryBodyProps {
  /** true: ご本人, false: 配偶者 */
  personFlg: boolean

  /** 更新時の処理 */
  onUpdate: () => void
}
interface SalaryTableProps {
  /** true: ご本人, false: 配偶者 */
  isPerson: boolean
}

export const SalaryTable: FC<SalaryTableProps> = ({ isPerson }) => {
  const { inputSalaries } = useSelector((state: RootState) => state.inputSalaries)

  const personFlg = isPerson ? PERSON_FLG.person : PERSON_FLG.spouse
  const targetSalary = _find(inputSalaries, (item) => item.person_flg === personFlg)
  const isFreelancer = targetSalary?.profession === PROFESSION.freelance

  return (
    <>
      {!_isUndefined(targetSalary) ? (
        <table className="w-[100%] text-[12px] md:text-[14px]">
          <tbody>
            <tr className="h-[45px] odd:bg-black-100">
              <td className="w-[40%] px-[16px]">
                <Typography isBold>職業</Typography>
              </td>
              <td className="w-[60%] px-[16px]">
                <Typography>
                  {convertProfession(
                    targetSalary?.profession != 0
                      ? targetSalary?.profession || null
                      : targetSalary?.profession,
                  )}
                </Typography>
              </td>
            </tr>

            {isFreelancer && (
              <tr className="h-[45px] odd:bg-black-100">
                <td className="px-[16px]">
                  <Typography>加入保険/年金</Typography>
                </td>
                <td className="px-[16px]">
                  {convertInsurancePensionType(
                    targetSalary?.insurance_pension_type || null,
                  )}
                </td>
              </tr>
            )}

            {isFreelancer && (
              <tr className="h-[45px] odd:bg-black-100">
                <td className="px-[16px]">
                  <Typography>月商</Typography>
                </td>
                <td className="px-[16px]">
                  <Typography>{`${convertLocaleString(
                    targetSalary?.input_salary_freelancer?.gross_income_monthly,
                  )}円/月`}</Typography>
                </td>
              </tr>
            )}

            {isFreelancer && (
              <tr className="h-[45px] odd:bg-black-100">
                <td className="px-[16px]">
                  <Typography>経費</Typography>
                </td>
                <td className="px-[16px]">
                  <Typography>{`${convertLocaleString(
                    targetSalary?.input_salary_freelancer?.expense_monthly,
                  )}円/月`}</Typography>
                </td>
              </tr>
            )}

            {isFreelancer && (
              <tr className="h-[45px] odd:bg-black-100">
                <td className="px-[16px]">
                  <Typography>所得</Typography>
                </td>
                <td className="px-[16px]">
                  <Typography>{`${convertLocaleString(
                    targetSalary?.input_salary_freelancer?.net_income_monthly,
                  )}円/月`}</Typography>
                </td>
              </tr>
            )}

            {isFreelancer && (
              <tr className="h-[45px] odd:bg-black-100">
                <td className="px-[16px]">
                  <Typography>所得税</Typography>
                </td>
                <td className="px-[16px]">
                  <Typography>{`${convertLocaleString(
                    targetSalary?.income_tax_monthly,
                  )}円/月`}</Typography>
                </td>
              </tr>
            )}

            {isFreelancer && (
              <tr className="h-[45px] odd:bg-black-100">
                <td className="px-[16px]">
                  <Typography>住民税</Typography>
                </td>
                <td className="px-[16px]">
                  <Typography>{`${convertLocaleString(
                    targetSalary?.resident_tax_monthly,
                  )}円/月`}</Typography>
                </td>
              </tr>
            )}

            {isFreelancer && (
              <tr className="h-[45px] odd:bg-black-100">
                <td className="px-[16px]">
                  <Typography>個人事業税</Typography>
                </td>
                <td className="px-[16px]">
                  <Typography>
                    {convertPersonalBusinessTaxRate(
                      targetSalary?.input_salary_freelancer?.personal_business_tax_rate,
                    )}
                  </Typography>
                </td>
              </tr>
            )}

            {isFreelancer && (
              <tr className="h-[45px] odd:bg-black-100">
                <td className="px-[16px]">
                  <Typography>青色申告特別控除</Typography>
                </td>
                <td className="px-[16px]">
                  <Typography>
                    {convertPersonalBusinessAoiroDeduction(
                      targetSalary?.input_salary_freelancer?.personal_business_aoiro_deduction,
                    )}
                  </Typography>
                </td>
              </tr>
            )}

            {!_includes(notEmployedGroup, targetSalary?.profession) &&
              !_includes(NotBelongToCompanyGroup, targetSalary?.profession) &&
              !isFreelancer && (
                <tr className="h-[45px] odd:bg-black-100">
                  <td className="px-[16px]">
                    <Typography>業種</Typography>
                  </td>
                  <td className="px-[16px]">
                    <Typography>
                      {convertIndustry(targetSalary?.industry || null)}
                    </Typography>
                  </td>
                </tr>
              )}

            {!_includes(notEmployedGroup, targetSalary?.profession) &&
              !_includes(NotBelongToCompanyGroup, targetSalary?.profession) &&
              !isFreelancer && (
                <tr className="h-[45px] odd:bg-black-100">
                  <td className="px-[16px]">
                    <Typography>従業員規模</Typography>
                  </td>
                  <td className="px-[16px]">
                    <Typography>
                      {convertEmployeeSize(targetSalary?.employee_size || null)}
                    </Typography>
                  </td>
                </tr>
              )}

            {!_includes(notEmployedGroup, targetSalary?.profession) && !isFreelancer && (
              <tr className="h-[45px] odd:bg-black-100">
                <td className="px-[16px]">
                  <Typography>勤続開始年月</Typography>
                </td>
                <td className="px-[16px]">
                  <Typography>
                    {`${combineTwoTypesYear(
                      extractYearOrMonthFromDate(targetSalary?.working_start_at, 'year'),
                    )}から`}
                  </Typography>
                </td>
              </tr>
            )}

            {!_includes(notEmployedGroup, targetSalary?.profession) && !isFreelancer && (
              <tr className="h-[45px] odd:bg-black-100">
                <td className="px-[16px]">
                  <Typography>加入保険/年金</Typography>
                </td>
                <td className="px-[16px]">
                  <Typography>
                    {convertInsurancePensionType(
                      targetSalary?.insurance_pension_type || null,
                    )}
                  </Typography>
                </td>
              </tr>
            )}

            {!_includes(notEmployedGroup, targetSalary?.profession) && !isFreelancer && (
              <tr className="h-[45px] odd:bg-black-100">
                <td className="px-[16px]">
                  <Typography>額面月収</Typography>
                </td>
                <td className="px-[16px]">
                  <Typography>{`${convertLocaleString(
                    targetSalary?.gross_income_monthly,
                  )}円/月`}</Typography>
                </td>
              </tr>
            )}

            {!_includes(notEmployedGroup, targetSalary?.profession) && !isFreelancer && (
              <tr className="h-[45px] odd:bg-black-100">
                <td className="px-[16px]">
                  <Typography>健康保険料</Typography>
                </td>
                <td className="px-[16px]">
                  <Typography>{`${convertLocaleString(
                    targetSalary?.health_insurance_monthly,
                  )}円/月`}</Typography>
                </td>
              </tr>
            )}

            {!_includes(notEmployedGroup, targetSalary?.profession) && !isFreelancer && (
              <tr className="h-[45px] odd:bg-black-100">
                <td className="px-[16px]">
                  <Typography>厚生年金保険料</Typography>
                </td>
                <td className="px-[16px]">
                  <Typography>{`${convertLocaleString(
                    targetSalary?.welfare_pension_monthly,
                  )}円/月`}</Typography>
                </td>
              </tr>
            )}

            {!_includes(notEmployedGroup, targetSalary?.profession) && !isFreelancer && (
              <tr className="h-[45px] odd:bg-black-100">
                <td className="px-[16px]">
                  <Typography>雇用保険料</Typography>
                </td>
                <td className="px-[16px]">
                  <Typography>{`${convertLocaleString(
                    targetSalary?.employment_insurance_monthly,
                  )}円/月`}</Typography>
                </td>
              </tr>
            )}

            {!_includes(notEmployedGroup, targetSalary?.profession) && !isFreelancer && (
              <tr className="h-[45px] odd:bg-black-100">
                <td className="px-[16px]">
                  <Typography>所得税</Typography>
                </td>
                <td className="px-[16px]">
                  <Typography>{`${convertLocaleString(
                    targetSalary?.income_tax_monthly,
                  )}円/月`}</Typography>
                </td>
              </tr>
            )}

            {!_includes(notEmployedGroup, targetSalary?.profession) && !isFreelancer && (
              <tr className="h-[45px] odd:bg-black-100">
                <td className="px-[16px]">
                  <Typography>住民税</Typography>
                </td>
                <td className="px-[16px]">
                  <Typography>{`${convertLocaleString(
                    targetSalary?.resident_tax_monthly,
                  )}円/月`}</Typography>
                </td>
              </tr>
            )}

            {!_includes(notEmployedGroup, targetSalary?.profession) &&
              isNotEmptyValue(targetSalary?.insurance_deduction_monthly) &&
              !isFreelancer && (
                <tr className="h-[45px] odd:bg-black-100">
                  <td className="px-[16px]">
                    <Typography>控除保険料</Typography>
                  </td>
                  <td className="px-[16px]">
                    <Typography>{`${convertLocaleString(
                      targetSalary?.insurance_deduction_monthly,
                    )}円/月`}</Typography>
                  </td>
                </tr>
              )}

            {!_includes(notEmployedGroup, targetSalary?.profession) &&
              isNotEmptyValue(targetSalary?.mutual_aid_pension_monthly) &&
              !isFreelancer && (
                <tr className="h-[45px] odd:bg-black-100">
                  <td className="px-[16px]">
                    <Typography>共済年金控除</Typography>
                  </td>
                  <td className="px-[16px]">
                    <Typography>{`${convertLocaleString(
                      targetSalary?.mutual_aid_pension_monthly,
                    )}円/月`}</Typography>
                  </td>
                </tr>
              )}

            {!_includes(notEmployedGroup, targetSalary?.profession) &&
              isNotEmptyValue(targetSalary?.company_saving_amount_monthly) &&
              !isFreelancer && (
                <tr className="h-[45px] odd:bg-black-100">
                  <td className="px-[16px]">
                    <Typography>社内預金・持株・財形などの積立金</Typography>
                  </td>
                  <td className="px-[16px]">
                    <Typography>{`${convertLocaleString(
                      targetSalary?.company_saving_amount_monthly,
                    )}円/月`}</Typography>
                  </td>
                </tr>
              )}

            {!_includes(notEmployedGroup, targetSalary?.profession) &&
              isNotEmptyValue(targetSalary?.housing_deduct_monthly) &&
              !isFreelancer && (
                <tr className="h-[45px] odd:bg-black-100">
                  <td className="px-[16px]">
                    <Typography>住宅費控除</Typography>
                  </td>
                  <td className="px-[16px]">
                    <Typography>{`${convertLocaleString(
                      targetSalary?.housing_deduct_monthly,
                    )}円/月`}</Typography>
                  </td>
                </tr>
              )}

            {!_includes(notEmployedGroup, targetSalary?.profession) &&
              isNotEmptyValue(targetSalary?.other_deduction_monthly) &&
              !isFreelancer && (
                <tr className="h-[45px] odd:bg-black-100">
                  <td className="px-[16px]">
                    <Typography>その他の控除</Typography>
                  </td>
                  <td className="px-[16px]">
                    <Typography>{`${convertLocaleString(
                      targetSalary?.other_deduction_monthly,
                    )}円/月`}</Typography>
                  </td>
                </tr>
              )}

            {!_includes(notEmployedGroup, targetSalary?.profession) &&
              !_includes(NotBelongToCompanyGroup, targetSalary?.profession) &&
              !isFreelancer && (
                <tr className="h-[45px] odd:bg-black-100">
                  <td className="px-[16px]">
                    <Typography>賞与</Typography>
                  </td>
                  <td className="px-[16px]">
                    {!_isEmpty(targetSalary?.input_bonuses) &&
                    !_isNull(targetSalary?.input_bonuses) ? (
                      <div>
                        {targetSalary?.input_bonuses.map(
                          (item: InputBonus, index: number) => (
                            <Typography key={index}>
                              {`${item.bonus_month}月 ${convertLocaleString(
                                item.bonus,
                              )}円`}
                            </Typography>
                          ),
                        )}
                      </div>
                    ) : (
                      <Typography>なし</Typography>
                    )}
                  </td>
                </tr>
              )}
          </tbody>
        </table>
      ) : (
        <Typography className="text-[14px] md:text-[16px] text-center" isBold>
          {`${isPerson ? 'ご本人' : '配偶者'}のお仕事が登録されていません。`}
        </Typography>
      )}
    </>
  )
}

const InputSalaryBody: FC<InputSalaryBodyProps> = ({ personFlg, onUpdate }) => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const closeModal = () => setIsOpenModal(false)
  const openModal = () => setIsOpenModal(true)

  const { inputSalaries } = useSelector((state: RootState) => state.inputSalaries)
  const inputedSalary =
    inputSalaries.find((is) => Number(!personFlg) === is.person_flg) != null

  return (
    <>
      <SimulationAccordion
        className="pb-[16px]"
        title={`${personFlg ? 'ご本人' : '配偶者'}の現在のお仕事`}
      >
        {inputedSalary && (
          <div className="flex justify-end mb-[8px]">
            <Button onClick={openModal}>
              <i className="ico edit text-[14px] text-black-700" />
              <Typography className="pl-[5px] text-[14px] text-black-700">
                編集
              </Typography>
            </Button>
          </div>
        )}
        <SalaryTable isPerson={personFlg} />
        {!inputedSalary && <SimulationNewButton onClick={openModal} />}
      </SimulationAccordion>

      <Modal className="lg:w-[1024px] w-[95%]" isOpen={isOpenModal} onClose={closeModal}>
        <CircleTitle
          className="pb-[32px]"
          title={`${personFlg ? 'ご本人' : '配偶者'}の現在のお仕事`}
        />
        <MyDataPersonEditForm
          personFlg={personFlg ? PERSON_FLG.person : PERSON_FLG.spouse}
          onClose={closeModal}
          onSubmit={() => {
            closeModal()
            onUpdate()
          }}
        />
      </Modal>
    </>
  )
}

export default InputSalaryBody
