import React, { FC, useEffect, useState, useContext, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { isNull as _isNull, find as _find, times as _times } from 'lodash'
import Button from '@/components/v2/atoms/Button'
import Typography from '@/components/v2/atoms/Typography'
import AccordionSet from '@/components/v1/molecules/AccordionSet'
import {
  WHITE_COLOR,
  PALE_GRAY_COLOR,
  VERY_PALE_GRAY_COLOR,
  DEEP_GRAY_COLOR,
} from '@/constants/theme'
import { destroyFutureLendHousingV2 } from '@/containers/futures/futureLendHousingsSlice'
import {
  convertLocaleString,
  defaultToEmptyString,
  isNotEmptyValue,
  convertDateToTwoTypesYearsDateFormat,
  extractYearOrMonthFromDate,
} from '@/models/commonsModelFunc'
import {
  convertDepreciation,
  convertMonthlyPeriodCost,
  convertMonthlyPeriodCostWithRent,
  RENEWAL_FEE_INPUT_TYPE,
} from '@/models/commonEstateInvestmentModelFunc'
import { SimulationDetailEditContext } from '@/models/futures/futureBasesModelFunc'
import {
  convertLendHousingDeclineRate,
  convertLoanRenewalType,
  convertPropertyType,
  PROPERTY_TYPE,
} from '@/models/futures/futureLendHousingsModelFunc'
import {
  MANAGEMENT_FEE_TYPE,
  convertPeriodCost,
  convertBuildingStructureType,
} from '@/models/futures/futureEstateInvestmentsModelFunc'
import { convertPersonFlg } from '@/models/familyStructuresModelFunc'
import { FutureLendHousing } from '@/models/futures/futureLendHousingsModel'
import { FutureV2Housing, NextHouseType } from '@/models/futures/futureV2HousingsModel'
import { NEXT_HOUSE_TYPE } from '@/models/futures/futureV2HousingsModelFunc'
import {
  convertLoanType,
  convertHasLoanLifeInsuranceContract,
} from '@/models/housingLoansModelFunc'
import { InputHousing } from '@/models/inputs/inputHousingsModel'
import { RootState } from '@/store'
import FutureLendHousingUpdateModalForm from '@/templates/v2/simulations/futureV2Housings/FutureLendHousingModalForm/updateIndex'
import FutureV2HousingBody from '@/templates/v2/simulations/futureV2Housings/FutureV2HousingBody'
import FutureV2HousingCreateModalForm from '@/templates/v2/simulations/futureV2Housings/FutureV2HousingModalForm/createIndex'

import { useFlashAlert } from '@/hooks/useFlashAlert'

const useStyles = makeStyles(() =>
  createStyles({
    settingGroup: {
      '&:not(:last-child)': {
        marginBottom: '20px',
      },
    },
    settingItem: {
      padding: '10px 0',
      backgroundColor: VERY_PALE_GRAY_COLOR,
      '&:not(:last-child)': {
        marginBottom: '20px',
      },
    },
    emptyBlockDescription: {
      color: DEEP_GRAY_COLOR,
      paddingBottom: '20px',
      fontSize: '14px',
    },
    emptyBlockButtonWrap: {
      position: 'relative',
      padding: '30px 10px',
      margin: '10px',
      textAlign: 'center',
      backgroundColor: WHITE_COLOR,
      border: `4px solid ${PALE_GRAY_COLOR}`,
    },
  }),
)

interface FutureLendHousingFormProps {
  /** 対象住居の賃貸化データ */
  futureLendHousing: Partial<FutureLendHousing> | undefined
  /** 次の住居開始年齢 */
  nextScheduledAtAge: number | null | undefined
  /** 共通API呼び出しメソッド */
  commonCallApi: () => void
  /** 次の将来住居の家タイプセットメソッド */
  setNextHouseType: (nextHousetype: NextHouseType) => void
  /** 次の将来住居の開始年齢セットメソッド */
  setNextScheduledAtAge: (nextScheduledAtAge: number) => void
  /** 直前の住居オブジェクトセットメソッド */
  setPrevHousing: ((prevHousing: FutureV2Housing) => void) | undefined
  /** 購入用住み替えモーダルオープンフラグ */
  futureV2HousingForPurchaseModalStatus:
    | { targetId: number | undefined; isModalOpen: boolean }
    | undefined
  /** 購入用住み替えモーダルオープンメソッド */
  setFutureV2HousingForPurchaseModalStatus: (
    futureV2HousingForPurchaseModalStatus:
      | {
          targetId: number | undefined
          isModalOpen: boolean
        }
      | undefined,
  ) => void
  /** 削除後の処理メソッド */
  afterDestory: () => void
}
interface FutureLendHousingTableProps {
  /** アイテム */
  item: any
  /** td要素のクラス */
  tdClassName?: string
}

export const FutureLendHousingTable: FC<FutureLendHousingTableProps> = ({ item }) => {
  return (
    <>
      <Typography styleType="underlinedHeader">物件情報</Typography>
      <table className="w-[100%] mb-[20px] last:mb-0 text-[12px]">
        <tbody>
          <tr className="h-[45px] odd:bg-black-100">
            <td className="w-[40%] px-[16px]">
              <Typography>名義人</Typography>
            </td>
            <td className="px-[16px]">
              <Typography>{convertPersonFlg(Number(item?.person_flg))}</Typography>
            </td>
          </tr>

          <tr className="h-[45px] odd:bg-black-100">
            <td className="w-[40%] px-[16px]">
              <Typography>物件タイプ</Typography>
            </td>
            <td className="px-[16px]">
              <Typography>{convertPropertyType(item?.property_type)}</Typography>
            </td>
          </tr>

          <tr className="h-[45px] odd:bg-black-100">
            <td className="w-[40%] px-[16px]">
              <Typography>物件構造</Typography>
            </td>
            <td className="px-[16px]">
              <Typography>
                {convertBuildingStructureType(item?.building_structure_type)}
              </Typography>
            </td>
          </tr>

          <tr className="h-[45px] odd:bg-black-100">
            <td className="w-[40%] px-[16px]">
              <Typography>賃貸変更日</Typography>
            </td>
            <td className="px-[16px]">
              <Typography>
                {convertDateToTwoTypesYearsDateFormat(
                  item?.lend_start_at,
                  'japaneseYear',
                )}
              </Typography>
            </td>
          </tr>

          {item?.property_type === PROPERTY_TYPE.division && (
            <tr className="h-[45px] odd:bg-black-100">
              <td className="w-[40%] px-[16px]">
                <Typography>広さ</Typography>
              </td>
              <td className="px-[16px]">
                <Typography>{convertLocaleString(item?.breadth)}㎡</Typography>
              </td>
            </tr>
          )}

          {item?.property_type !== PROPERTY_TYPE.division && (
            <>
              <tr className="h-[45px] odd:bg-black-100">
                <td className="w-[40%] px-[16px]">
                  <Typography>延床面積</Typography>
                </td>
                <td className="px-[16px]">
                  <Typography>{convertLocaleString(item?.total_floor_area)}㎡</Typography>
                </td>
              </tr>

              <tr className="h-[45px] odd:bg-black-100">
                <td className="w-[40%] px-[16px]">
                  <Typography>敷地面積</Typography>
                </td>
                <td className="px-[16px]">
                  <Typography>{convertLocaleString(item?.site_area)}㎡</Typography>
                </td>
              </tr>
            </>
          )}
        </tbody>
      </table>

      <Typography styleType="underlinedHeader">収入情報</Typography>
      <table className="w-[100%] mb-[20px] last:mb-0 text-[12px]">
        <tbody>
          <tr className="h-[45px] odd:bg-black-100">
            <td className="w-[40%] px-[16px]">
              <Typography>賃料収入</Typography>
            </td>
            <td className="px-[16px]">
              <Typography>
                {convertLocaleString(item?.rent_income_monthly)}円/月
              </Typography>
            </td>
          </tr>

          {(!_isNull(item?.renewal_fee) ||
            !_isNull(item?.renewal_fee_to_rent_income_rate)) && (
            <>
              <tr className="h-[45px] odd:bg-black-100">
                <td className="w-[40%] px-[16px]">
                  <Typography>礼金・更新料</Typography>
                </td>
                <td className="px-[16px]">
                  <Typography className="min-w-[max-content]">
                    {isNotEmptyValue(item?.renewal_fee)
                      ? convertMonthlyPeriodCost(
                          item?.renewal_fee_period_months,
                          item?.renewal_fee,
                        )
                      : convertMonthlyPeriodCostWithRent(
                          item?.renewal_fee_period_months,
                          item?.rent_income_monthly,
                          item?.renewal_fee_to_rent_income_rate,
                        )}
                  </Typography>
                </td>
              </tr>
            </>
          )}
          {Number(item?.annual_rate_of_decline_type) == 0 && (
            <tr className="h-[45px] odd:bg-black-100">
              <td className="w-[40%] px-[16px]">
                <Typography>年間下落率</Typography>
              </td>
              <td className="px-[16px]">
                {item?.future_lend_housing_declines.map(
                  (futureLendHousingDecline: any, index: number) => {
                    return (
                      <Typography key={`${index}`}>
                        {convertLendHousingDeclineRate(
                          item?.future_lend_housing_declines,
                          index,
                        )}
                      </Typography>
                    )
                  },
                )}
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <Typography styleType="underlinedHeader">支出情報</Typography>
      <table className="w-[100%] mb-[20px] last:mb-0 text-[12px]">
        <tbody>
          <tr className="h-[45px] odd:bg-black-100">
            <td className="w-[40%] px-[16px]">
              <Typography>設備・原状回復費用</Typography>
            </td>
            <td className="px-[16px]">
              <Typography>
                {convertPeriodCost(item?.repair_costs_span, item?.repair_costs)}
              </Typography>
            </td>
          </tr>

          {item?.management_fee_type === MANAGEMENT_FEE_TYPE.percent && (
            <tr className="h-[45px] odd:bg-black-100">
              <td className="w-[40%] px-[16px]">
                <Typography>賃貸管理手数料</Typography>
              </td>
              <td className="px-[16px]">
                <Typography>
                  賃料の
                  {defaultToEmptyString(item?.management_fee_rate_percent)}%
                </Typography>
              </td>
            </tr>
          )}

          {item?.management_fee_type === MANAGEMENT_FEE_TYPE.yen && (
            <tr className="h-[45px] odd:bg-black-100">
              <td className="w-[40%] px-[16px]">
                <Typography>賃貸管理手数料</Typography>
              </td>
              <td className="px-[16px]">
                <Typography>
                  {`${convertLocaleString(item?.management_fee_money)}円/年`}
                </Typography>
              </td>
            </tr>
          )}

          <tr className="h-[45px] odd:bg-black-100">
            <td className="w-[40%] px-[16px]">
              <Typography>その他の経費</Typography>
            </td>
            <td className="px-[16px]">
              <Typography>{convertLocaleString(item?.other_expenses)}円/年</Typography>
            </td>
          </tr>

          {/** MEMO: 必要になった場合コメントイン */}
          {/* <tr className="h-[45px] odd:bg-black-100">
            <td className="w-[40%] px-[16px]">
              <Typography>設備減価償却費</Typography>
            </td>
            <td className="px-[16px]">
              <Typography>
                {convertDepreciation(
                  item?.equipment_durable_years,
                  item?.equipment_depreciation,
                  extractYearOrMonthFromDate(item?.constructed_at, 'year'),
                )}
              </Typography>
            </td>
          </tr>

          <tr className="h-[45px] odd:bg-black-100">
            <td className="w-[40%] px-[16px]">
              <Typography>建物減価償却費</Typography>
            </td>
            <td className="px-[16px]">
              <Typography>
                {convertDepreciation(
                  item?.building_durable_years,
                  item?.building_depreciation,
                  extractYearOrMonthFromDate(item?.constructed_at, 'year'),
                )}
              </Typography>
            </td>
          </tr> */}

          <tr className="h-[45px] odd:bg-black-100">
            <td className="w-[40%] px-[16px]">
              <Typography>空室率</Typography>
            </td>
            <td className="px-[16px]">
              <Typography>{convertLocaleString(item?.vacancy_rate_percent)}%</Typography>
            </td>
          </tr>
        </tbody>
      </table>

      {item?.loan_renewal_type && (
        <>
          <Typography styleType="underlinedHeader">ローン情報</Typography>
          <table className="w-[100%] mb-[20px] last:mb-0 text-[12px]">
            <tbody>
              <tr className="h-[45px] odd:bg-black-100">
                <td className="w-[40%] px-[16px]">
                  <Typography>投資用ローンへの借り換え</Typography>
                </td>
                <td className="px-[16px]">
                  <Typography>
                    {convertLoanRenewalType(item?.loan_renewal_type)}
                  </Typography>
                </td>
              </tr>
              {!_isNull(item?.housing_loan) && (
                <>
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="w-[40%] px-[16px]">
                      <Typography>ローン方式</Typography>
                    </td>
                    <td className="px-[16px]">
                      <Typography>
                        {convertLoanType(item?.housing_loan?.loan_type!)}
                      </Typography>
                    </td>
                  </tr>
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="w-[40%] px-[16px]">
                      <Typography>頭金</Typography>
                    </td>
                    <td className="px-[16px]">
                      <Typography>
                        {convertLocaleString(
                          item?.housing_loan?.loan_down_payment_manyen,
                        )}
                        万円
                      </Typography>
                    </td>
                  </tr>
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="w-[40%] px-[16px]">
                      <Typography>借入金額</Typography>
                    </td>
                    <td className="px-[16px]">
                      <Typography>
                        {convertLocaleString(item?.housing_loan?.loan_amount_manyen)}
                        万円
                      </Typography>
                    </td>
                  </tr>
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="w-[40%] px-[16px]">
                      <Typography>借入金利</Typography>
                    </td>
                    <td className="px-[16px]">
                      <Typography>
                        {defaultToEmptyString(
                          item?.housing_loan?.loan_interest_ratio_percent,
                        )}
                        %
                      </Typography>
                    </td>
                  </tr>
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="w-[40%] px-[16px]">
                      <Typography>返済期間</Typography>
                    </td>
                    <td className="px-[16px]">
                      <Typography>
                        {defaultToEmptyString(item?.housing_loan?.repayment_period_years)}
                        年
                      </Typography>
                    </td>
                  </tr>
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="w-[40%] px-[16px]">
                      <Typography>団体信用生命保険</Typography>
                    </td>
                    <td className="px-[16px]">
                      <Typography>
                        {convertHasLoanLifeInsuranceContract(
                          item?.housing_loan?.has_loan_life_insurance_contract!,
                        )}
                      </Typography>
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </>
      )}
    </>
  )
}

const FutureLendHousingForm: FC<FutureLendHousingFormProps> = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { showFlashMsg } = useFlashAlert()
  const { isManipulatedApproval } = useContext(SimulationDetailEditContext)

  const {
    futureLendHousing,
    nextScheduledAtAge,
    commonCallApi,
    setNextHouseType,
    setNextScheduledAtAge,
    setPrevHousing,
    futureV2HousingForPurchaseModalStatus,
    setFutureV2HousingForPurchaseModalStatus,
  } = props

  const [editId, setEditId] = useState<number | null>(null)
  const [toggleFormStatus, setToggleFormStatus] = useState<any>({})
  const [targetHousingLendId, setTargetHousingLendId] = useState<number | undefined>(
    undefined,
  )
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState<boolean>(false)
  const [isFutureV2HousingModalOpen, setIsFutureV2HousingModalOpen] = useState<boolean>()

  const { futureBaseId } = useSelector((state: RootState) => state.futureBases)
  const futureLendHousings = useSelector(
    (state: RootState) => state.accounts.futureItems?.future_lend_housings,
  )
  const futureV2Housings = useSelector(
    (state: RootState) => state.accounts.futureItems?.future_v2_housings,
  )
  const { inputHousings } = useSelector((state: RootState) => state.inputHousings)

  const prevFutureV2Housing: FutureV2Housing | undefined = useMemo(
    () => _find(futureV2Housings, (item) => item.id === futureLendHousing?.housing_id),
    [futureLendHousing, futureV2Housings],
  )
  const prevInputHousing: InputHousing | undefined = useMemo(
    () => _find(inputHousings, (item) => item.id === futureLendHousing?.housing_id),
    [futureLendHousing, inputHousings],
  )
  const prevHousing = useMemo(() => {
    if (futureLendHousing?.housing_type === 'InputHousing') {
      return prevInputHousing
    }

    return prevFutureV2Housing
  }, [prevFutureV2Housing, prevInputHousing])

  const isModalForPurchaseOpen = useMemo(
    () =>
      futureV2HousingForPurchaseModalStatus?.targetId === futureLendHousing?.id &&
      futureV2HousingForPurchaseModalStatus?.isModalOpen,
    [futureV2HousingForPurchaseModalStatus, futureLendHousing],
  )

  useEffect(() => {
    if (!futureLendHousing?.future_lend_housing_declines) return

    setToggleFormStatus({
      decline_rates: _times(
        futureLendHousing!.future_lend_housing_declines!.length,
        () => ({
          decline_rate: false,
        }),
      ),
    })
  }, [futureLendHousings])

  const initialValues = useMemo(() => {
    return {
      ...futureLendHousing,
      renewal_fee_input_type: isNotEmptyValue(futureLendHousing?.renewal_fee)
        ? RENEWAL_FEE_INPUT_TYPE.yen
        : RENEWAL_FEE_INPUT_TYPE.month,
    } as FutureLendHousing
  }, [futureLendHousings])

  const livingStartAtAge = futureLendHousing?.lend_start_at_age

  const selectFutureV2Housing = (futureV2HousingId: number | null | undefined) => {
    return _find(futureV2Housings, (item) => item.id === futureV2HousingId)
  }

  const relocationFutureV2Housing = useMemo(
    () => selectFutureV2Housing(futureLendHousing?.future_v2_housing_id),
    [futureLendHousing],
  )

  const selectToggleForm = (key: string) => {
    setToggleFormStatus({
      ...toggleFormStatus,
      [key]: !toggleFormStatus[key],
    })
  }

  const handleUpdateModalOpen = (id: number) => {
    setIsUpdateModalOpen(true)
    setEditId(id)
  }

  const handleFutureV2HousingModalClose = () => {
    setIsFutureV2HousingModalOpen(false)
    setTargetHousingLendId(undefined)
    setFutureV2HousingForPurchaseModalStatus(undefined)
  }

  const completedFutureV2HousingCallback = () => {
    commonCallApi()
    showFlashMsg('登録しました')
    setIsFutureV2HousingModalOpen(false)
    setTargetHousingLendId(undefined)
    setFutureV2HousingForPurchaseModalStatus(undefined)
  }

  const continueForPurchaseCallback = (nextHouseValues: {
    nextHouseType: NextHouseType
    nextScheduledAtAge: number
    prevHousing: FutureV2Housing
  }) => {
    commonCallApi()
    setNextHouseType(nextHouseValues.nextHouseType)
    setNextScheduledAtAge(nextHouseValues.nextScheduledAtAge)
    setPrevHousing && setPrevHousing(nextHouseValues.prevHousing)
    setTargetHousingLendId(undefined)
    setIsFutureV2HousingModalOpen(false)
    showFlashMsg('登録しました')
    setFutureV2HousingForPurchaseModalStatus({
      targetId: futureLendHousing?.id,
      isModalOpen: true,
    })
  }

  const updateCompletedCallback = () => {
    commonCallApi()
    showFlashMsg('登録しました')
    setIsUpdateModalOpen(false)
    setEditId(null)
  }

  const handleDestroy = (id: number) => {
    const result = confirm(
      'これ以降の予定が全て削除されます。\n選択された賃貸化予定本当に削除してよろしいですか？',
    )
    if (!result) return

    dispatch(
      destroyFutureLendHousingV2(id, () => {
        commonCallApi()
        showFlashMsg('削除しました')
        props.afterDestory()
      }),
    )
  }

  return (
    <div
      onClick={(event: any) => {
        if (isUpdateModalOpen || isFutureV2HousingModalOpen || isModalForPurchaseOpen) {
          event.stopPropagation()
        }
      }}
    >
      <Form
        onSubmit={() => {}}
        initialValues={initialValues}
        mutators={{ ...arrayMutators }}
        render={() => (
          <>
            <div className="m-[25px]">
              <AccordionSet
                title="賃貸化について"
                titleClassName="text-14"
                defaultClose
                customElement={
                  <>
                    <div className="flex justify-end mb-[5px]">
                      <Button
                        className="mx-5"
                        isDisabled={!isManipulatedApproval}
                        onClick={(event: any) => {
                          event.stopPropagation()
                          handleUpdateModalOpen(initialValues?.id!)
                        }}
                      >
                        <i className="ico edit text-[14px] text-black-700" />
                        <Typography className="pl-[5px] text-[14px] text-black-700">
                          編集
                        </Typography>
                      </Button>
                      <Button
                        className="mx-5"
                        isDisabled={!isManipulatedApproval}
                        onClick={(event: any) => {
                          event.stopPropagation()
                          handleDestroy(initialValues?.id!)
                        }}
                      >
                        <i className="ico edit text-[14px] text-black-700" />
                        <Typography className="pl-[5px] text-[14px] text-black-700">
                          削除
                        </Typography>
                      </Button>
                    </div>
                  </>
                }
              >
                <FutureLendHousingTable item={initialValues} />
              </AccordionSet>
            </div>
          </>
        )}
      />

      {!!selectFutureV2Housing(initialValues?.future_v2_housing_id) ? (
        <div className={classes.settingGroup}>
          <FutureV2HousingBody
            title="住み替え予定"
            futureV2Housing={selectFutureV2Housing(initialValues.future_v2_housing_id)}
            targetHousingLendId={initialValues?.id}
            commonCallApi={commonCallApi}
            afterDestory={props.afterDestory}
          />
        </div>
      ) : (
        <div className="m-[25px]">
          <AccordionSet titleClassName="text-14" title="住み替えについて">
            <div className={classes.settingItem}>
              <div className={classes.emptyBlockButtonWrap}>
                <Typography className={classes.emptyBlockDescription}>
                  住み替え先が入力されていません。
                  <br />
                  下記のボタンから設定を行ってください。
                </Typography>
                <Button
                  className="w-full h-[30px] mx-auto my-[0] border border-dashed border-primary-500 hover:bg-primary-50"
                  isDisabled={!isManipulatedApproval}
                  onClick={(event: any) => {
                    setIsFutureV2HousingModalOpen(true)
                    setTargetHousingLendId(initialValues.id)
                    event.stopPropagation()
                  }}
                >
                  <Typography
                    className="relative bottom-[1px] px-[12px] text-primary-500"
                    isBold
                  >
                    住み替え先を登録する
                  </Typography>
                </Button>
              </div>
            </div>
          </AccordionSet>
        </div>
      )}

      {isUpdateModalOpen && (
        <FutureLendHousingUpdateModalForm
          isOpen={isUpdateModalOpen}
          editId={editId as number}
          prevHousing={prevHousing}
          completedCallback={updateCompletedCallback}
          handleClose={() => setIsUpdateModalOpen(false)}
        />
      )}

      {(isFutureV2HousingModalOpen || isModalForPurchaseOpen) && (
        <FutureV2HousingCreateModalForm
          targetHousingLendId={targetHousingLendId}
          isOpen={!!isFutureV2HousingModalOpen || !!isModalForPurchaseOpen}
          prevHousing={relocationFutureV2Housing || prevHousing}
          nextHouseType={
            !!isModalForPurchaseOpen ? (NEXT_HOUSE_TYPE.own as NextHouseType) : undefined
          }
          nextScheduledAtAge={nextScheduledAtAge || livingStartAtAge}
          handleClose={handleFutureV2HousingModalClose}
          completedCallback={completedFutureV2HousingCallback}
          continueToPurchaseCallback={continueForPurchaseCallback}
        />
      )}
    </div>
  )
}

export default FutureLendHousingForm
