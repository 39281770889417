import ApiClient from '@/api/apiClient'
import { Seminar } from '@/models/seminarsModel'

export const getSeminar = async (uniqueId: string) => {
  return await ApiClient.get(
    `/seminars/${uniqueId}`,
    (response: Partial<Seminar> | null) => response,
    undefined,
    false,
    'v2',
  )
}
