import {
  InputSubIncomeCreateValues,
  InputSubIncomeDestroyValues,
  InputSubIncomeUpdateValues,
} from '../../models/inputs/inputSubIncomesModel'
import ApiClient from '../apiClient'

export const postInputSubIncome = async (values: InputSubIncomeCreateValues) => {
  return await ApiClient.post('/input_salaries/create_sub_income', () => {}, values)
}

export const patchInputSubIncome = async (values: InputSubIncomeUpdateValues) => {
  return await ApiClient.patch('/input_salaries/update_sub_income', () => {}, values)
}

export const deleteInputSubIncome = async (values: {
  params: InputSubIncomeDestroyValues
}) => {
  return await ApiClient.delete('/input_salaries/delete_sub_income', () => {}, values)
}
