import React, { FC } from 'react'
import { useField } from 'react-final-form'
import Typography from '@/components/v2/atoms/Typography'
import SelectBox from '@/components/v2/molecules/SelectBox'
import {
  TWO_TYPES_YEAR_SELECT_ITEMS,
  MONTH_KANJI_SELECT_ITEMS,
} from '@/constants/formItem/commons'
import { required } from '@/utils/validate'
import { getTargetRentAttributesName } from '@/models/commonHousingModelFunc'
import { API_FLAG, THIS_YEAR } from '@/models/commonsModelFunc'
import { buildStandardSelectItems } from '@/utils/formItem'

interface NextContractUpdateProps {
  isFuture?: boolean
}

const NextContractUpdate: FC<NextContractUpdateProps> = (props) => {
  const { isFuture } = props

  const targetHousingRentAttributesName = getTargetRentAttributesName(isFuture)

  const {
    input: { value: nextUpdateType },
  } = useField(`${targetHousingRentAttributesName}[0]next_update_type`)
  const isNextUpdateTypeOn = Number(nextUpdateType) == API_FLAG.on

  return (
    <>
      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
        <Typography
          className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
          isBold
        >
          更新月の設定
        </Typography>
        <SelectBox
          name={`${targetHousingRentAttributesName}[0]next_update_type`}
          options={[
            {
              label: '設定する',
              value: String(API_FLAG.on),
            },
            {
              label: '設定しない',
              value: String(API_FLAG.off),
            },
          ]}
          placeholder="選択する"
          isFullWidth
          validate={required}
        />
      </div>

      {isNextUpdateTypeOn && (
        <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
          <Typography
            className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
            isBold
          >
            次回の更新月
          </Typography>
          <SelectBox
            className="mb-[10px] lg:mb-0"
            name={`${targetHousingRentAttributesName}[0]next_update_year`}
            options={buildStandardSelectItems(
              TWO_TYPES_YEAR_SELECT_ITEMS,
              THIS_YEAR,
              '年を選択',
            )}
            isPlaceholderHidden
            isFullWidth
            validate={required}
          />

          <SelectBox
            className="lg:ml-[10px]"
            name={`${targetHousingRentAttributesName}[0]next_update_month`}
            placeholder="月を選択"
            options={MONTH_KANJI_SELECT_ITEMS}
            isFullWidth
            validate={required}
          />
        </div>
      )}
    </>
  )
}

export default NextContractUpdate
