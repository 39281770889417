import { API_FLAG } from '@/models/commonsModelFunc'
import {
  SIMULATION_TYPE,
  BUILDING_STRUCTURE_TYPE,
  MANAGEMENT_FEE_TYPE,
  PROPERTY_TYPE,
  VACANCY_SETTING_TYPE,
} from '@/models/futures/futureEstateInvestmentsModelFunc'

// シミュレーションタイプ
export const SIMULATION_TYPE_BUTTON_ITEMS = [
  { value: SIMULATION_TYPE.simple, label: '簡単シミュレーション' },
  { value: SIMULATION_TYPE.detail, label: '詳細シミュレーション' },
]

// 物件構造
export const BUILDING_STRUCTURE_TYPE_SELECT_ITEMS = [
  { value: BUILDING_STRUCTURE_TYPE.rc, label: '鉄筋コンクリート造（RC造）' },
  { value: BUILDING_STRUCTURE_TYPE.src, label: '鉄骨鉄筋コンクリート造（SRC造）' },
  { value: BUILDING_STRUCTURE_TYPE.steel, label: '鉄骨造' },
  { value: BUILDING_STRUCTURE_TYPE.lightSteel, label: '軽量鉄骨造' },
  { value: BUILDING_STRUCTURE_TYPE.wood, label: '木造' },
]

// 空室率設定タイプ
export const VACANCY_SETTING_TYPE_SELECT_ITEMS = [
  { value: VACANCY_SETTING_TYPE.own, label: '直接入力' },
  { value: VACANCY_SETTING_TYPE.auto, label: '必要項目を入力して自動計算' },
]

// 年間下落率タイプ
export const DECLINE_RATE_TYPE_RADIO_ITEMS = [
  { value: API_FLAG.on, label: '設定する' },
  { value: API_FLAG.off, label: '設定しない' },
]

// 建物管理手数料タイプ
export const MANAGEMENT_FEE_TYPE_RADIO_ITEMS = [
  {
    value: MANAGEMENT_FEE_TYPE.percent,
    label: '賃料に対する比率で設定',
  },
  { value: MANAGEMENT_FEE_TYPE.yen, label: '円で設定' },
]

// 物件タイプ
export const PROPERTY_TYPE_RADIO_ITEMS = [
  { value: PROPERTY_TYPE.whole, label: '１棟' },
  { value: PROPERTY_TYPE.division, label: '区分' },
]
