import React, { FC, useCallback } from 'react'
import { isNil as _isNil, isUndefined as _isUndefined } from 'lodash'
import Typography from '@/components/v2/atoms/Typography'
import { convertLocaleString } from '@/models/commonsModelFunc'
import {
  ESTATE_EVALUATION_COLOR,
  ESTATE_REMAIN_LOANS_COLOR,
} from '@/templates/v2/simulations/_simulationGraphBody'

interface TooltipLoanProps {
  /** アクティブ情報 */
  activeIndex: number | undefined
  /** ベースプラン名 */
  basePlanName?: any
  /** true: ベースプラン名非表示, false: ベースプラン名表示 */
  isHideBasePlanName?: boolean
  /** ベースアイテム */
  baseItem: any
  /** 物件の番号を確認するためのObject */
  loanIndexObject: any
  /** true: 比較有り, false: 比較無し */
  isCompare?: boolean
  /** 比較プラン名 */
  comparePlanName?: any
  /** 比較アイテム */
  compareItem?: any
}

const TooltipLoan: FC<TooltipLoanProps> = (props) => {
  const {
    activeIndex,
    basePlanName,
    isHideBasePlanName,
    baseItem,
    loanIndexObject,
    isCompare,
    comparePlanName,
    compareItem,
  } = props

  const activeLoanItem = !_isUndefined(activeIndex) ? baseItem?.[activeIndex] : {}
  const compareActiveLoanItem = !_isUndefined(activeIndex)
    ? compareItem?.[activeIndex]
    : {}

  const fillIfEmpty = useCallback(
    (str: string) => {
      if (!_isNil(activeLoanItem.asset_evaluation_total)) {
        return str
      }
      return '-'
    },
    [activeLoanItem],
  )

  const fillIfEmptyCompare = useCallback(
    (str: string) => {
      if (!_isNil(compareActiveLoanItem.asset_evaluation_total)) {
        return str
      }
      return '-'
    },
    [compareActiveLoanItem],
  )

  return (
    <>
      {!_isUndefined(activeIndex) ? (
        <div
          className={`${
            isCompare ? 'flex' : ''
          } gap-x-[32px] py-[24px] px-[16px] rounded-[8px] bg-white md:shadow-modal visible pointer-events-auto overflow-x-scroll`}
        >
          <div className="w-full md:w-[240px] min-w-[240px]">
            <div className="pb-[16px] last:pb-0">
              {!isHideBasePlanName && (
                <Typography
                  className="pb-[4px] text-[12px] overflow-hidden whitespace-nowrap overflow-ellipsis"
                  isBold
                >
                  ベースプラン：
                  <br />
                  {basePlanName ?? '（プラン名未設定）'}
                </Typography>
              )}
              <div className="p-[10px] rounded-[4px] bg-primary-50">
                <Typography className="pb-[4px]" isBold>
                  {`${activeLoanItem.name}年(${activeLoanItem.age}歳)`}
                </Typography>
                <Typography isBold>
                  不動産純資産合計：
                  {fillIfEmpty(
                    `${convertLocaleString(
                      activeLoanItem.asset_evaluation_total -
                        activeLoanItem.asset_remain_loan_total,
                    )}万円`,
                  )}
                </Typography>
              </div>
            </div>

            <div className="pb-[16px] last:pb-0">
              <div className="flex justify-between mb-[8px] pb-[8px] border-b border-black-300">
                <div className="flex items-center">
                  <i
                    className="ico circle relative top-px pr-[4px] text-[14px]"
                    style={{ color: '#22A74B' }}
                  />
                  <Typography className="text-[14px]" isBold>
                    評価額
                  </Typography>
                </div>
                <Typography className="text-[14px]" isBold>
                  {fillIfEmpty(
                    `合計：${convertLocaleString(
                      activeLoanItem.asset_evaluation_total,
                    )}万円`,
                  )}
                </Typography>
              </div>
              <div className="flex justify-between pb-[8px] last:pb-0">
                <div className="flex items-center">
                  <i
                    className="ico rect-2 pr-[8px] text-[12px]"
                    style={{ color: '#E5F5AD' }}
                  />
                  <Typography className="text-[14px]">自宅</Typography>
                </div>
                <Typography className="text-[14px]">
                  {fillIfEmpty(
                    `${convertLocaleString(activeLoanItem.housing_asset_evaluation)}万円`,
                  )}
                </Typography>
              </div>
              {activeLoanItem.estate_evaluations &&
                Object.keys(activeLoanItem.estate_evaluations).map((key, index) => (
                  <div key={key} className="flex justify-between pb-[8px] last:pb-0">
                    <div className="flex items-center">
                      <i
                        className="ico rect-2 pr-[8px] text-[14px]"
                        style={{ color: ESTATE_EVALUATION_COLOR[index] }}
                      />
                      <Typography className="text-[14px]">
                        {`物件${
                          (Object.keys(
                            loanIndexObject.maxSizeEstateEvaluationObject,
                          ).indexOf(key) ?? index) + 1
                        }`}
                      </Typography>
                    </div>
                    <Typography className="text-[14px]">{`${convertLocaleString(
                      activeLoanItem.estate_evaluations[key],
                    )}万円`}</Typography>
                  </div>
                ))}
            </div>

            <div className="pb-[16px] last:pb-0">
              <div className="flex justify-between mb-[8px] pb-[8px] border-b border-black-300">
                <Typography className="text-[14px]" isBold>
                  ローン残高
                </Typography>
                <Typography className="text-[14px]" isBold>
                  {fillIfEmpty(
                    `合計：${convertLocaleString(
                      activeLoanItem.asset_remain_loan_total,
                    )}万円`,
                  )}
                </Typography>
              </div>
              <div className="flex justify-between pb-[8px] last:pb-0">
                <div className="flex items-center">
                  <i
                    className="ico rect-2 pr-[8px] text-[12px]"
                    style={{ color: '#5BAFE4' }}
                  />
                  <Typography className="text-[14px]">自宅</Typography>
                </div>
                <Typography className="text-[14px]">
                  {fillIfEmpty(
                    `${convertLocaleString(activeLoanItem.housing_loan_balance)}万円`,
                  )}
                </Typography>
              </div>
              {activeLoanItem.estate_remain_loans_hash &&
                Object.keys(activeLoanItem.estate_remain_loans_hash).map((key, index) => (
                  <div key={key} className="flex justify-between pb-[8px] last:pb-0">
                    <div className="flex items-center">
                      <i
                        className="ico rect-2 pr-[8px] text-[14px]"
                        style={{ color: ESTATE_REMAIN_LOANS_COLOR[index] }}
                      />
                      <Typography className="text-[14px]">{`物件${
                        (Object.keys(
                          loanIndexObject.maxSizeEstateRemainLoanObject,
                        ).indexOf(key) ?? index) + 1
                      }`}</Typography>
                    </div>
                    <Typography className="text-[14px]">{`${convertLocaleString(
                      activeLoanItem.estate_remain_loans_hash[key],
                    )}万円`}</Typography>
                  </div>
                ))}
            </div>
          </div>

          {isCompare && (
            <div className="w-full md:w-[240px] min-w-[240px]">
              <div className="pb-[16px] last:pb-0">
                <Typography
                  className="pb-[4px] text-[12px] overflow-hidden whitespace-nowrap overflow-ellipsis"
                  isBold
                >
                  比較プラン：
                  <br />
                  {comparePlanName ?? '（プラン名未設定）'}
                </Typography>
                <div className="p-[10px] rounded-[4px] bg-primary-50">
                  <Typography className="pb-[4px]" isBold>
                    {`${compareActiveLoanItem.name}年(${compareActiveLoanItem.age}歳)`}
                  </Typography>
                  <Typography isBold>
                    不動産純資産合計：
                    {fillIfEmptyCompare(
                      `${convertLocaleString(
                        compareActiveLoanItem.asset_evaluation_total -
                          compareActiveLoanItem.asset_remain_loan_total,
                      )}万円`,
                    )}
                  </Typography>
                </div>
              </div>

              <div className="pb-[16px] last:pb-0">
                <div className="flex justify-between mb-[8px] pb-[8px] border-b border-black-300">
                  <div className="flex items-center">
                    <i
                      className="ico circle relative top-px pr-[4px] text-[14px]"
                      style={{ color: '#22A74B' }}
                    />
                    <Typography className="text-[14px]" isBold>
                      評価額
                    </Typography>
                  </div>
                  <Typography className="text-[14px]" isBold>
                    {fillIfEmptyCompare(
                      `合計：${convertLocaleString(
                        compareActiveLoanItem.asset_evaluation_total,
                      )}万円`,
                    )}
                  </Typography>
                </div>
                <div className="flex justify-between pb-[8px] last:pb-0">
                  <div className="flex items-center">
                    <i
                      className="ico rect-2 pr-[8px] text-[12px]"
                      style={{ color: '#E5F5AD' }}
                    />
                    <Typography className="text-[14px]">自宅</Typography>
                  </div>
                  <Typography className="text-[14px]">
                    {fillIfEmptyCompare(
                      `${convertLocaleString(
                        compareActiveLoanItem.housing_asset_evaluation,
                      )}万円`,
                    )}
                  </Typography>
                </div>
                {compareActiveLoanItem.estate_evaluations &&
                  Object.keys(compareActiveLoanItem.estate_evaluations).map(
                    (key, index) => (
                      <div key={key} className="flex justify-between pb-[8px] last:pb-0">
                        <div className="flex items-center">
                          <i
                            className="ico rect-2 pr-[8px] text-[14px]"
                            style={{ color: ESTATE_EVALUATION_COLOR[index] }}
                          />
                          <Typography className="text-[14px]">{`物件${
                            (Object.keys(
                              loanIndexObject.maxSizeEstateEvaluationCompareObject ?? {},
                            ).indexOf(key) ?? index) + 1
                          }`}</Typography>
                        </div>
                        <Typography className="text-[14px]">{`${convertLocaleString(
                          compareActiveLoanItem.estate_evaluations[key],
                        )}万円`}</Typography>
                      </div>
                    ),
                  )}
              </div>

              <div className="pb-[16px] last:pb-0">
                <div className="flex justify-between mb-[8px] pb-[8px] border-b border-black-300">
                  <Typography className="text-[14px]" isBold>
                    ローン残高
                  </Typography>
                  <Typography className="text-[14px]" isBold>
                    {fillIfEmptyCompare(
                      `合計：${convertLocaleString(
                        compareActiveLoanItem.asset_remain_loan_total,
                      )}万円`,
                    )}
                  </Typography>
                </div>
                <div className="flex justify-between pb-[8px] last:pb-0">
                  <div className="flex items-center">
                    <i
                      className="ico rect-2 pr-[8px] text-[12px]"
                      style={{ color: '#5BAFE4' }}
                    />
                    <Typography className="text-[14px]">自宅</Typography>
                  </div>
                  <Typography className="text-[14px]">
                    {fillIfEmptyCompare(
                      `${convertLocaleString(
                        compareActiveLoanItem.housing_loan_balance,
                      )}万円`,
                    )}
                  </Typography>
                </div>
                {compareActiveLoanItem.estate_remain_loans_hash &&
                  Object.keys(compareActiveLoanItem.estate_remain_loans_hash).map(
                    (key, index) => (
                      <div key={key} className="flex justify-between pb-[8px] last:pb-0">
                        <div className="flex items-center">
                          <i
                            className="ico rect-2 pr-[8px] text-[14px]"
                            style={{ color: ESTATE_REMAIN_LOANS_COLOR[index] }}
                          />
                          <Typography className="text-[14px]">{`物件${
                            (Object.keys(
                              loanIndexObject.maxSizeEstateRemainLoanCompareObject ?? {},
                            ).indexOf(key) ?? index) + 1
                          }`}</Typography>
                        </div>
                        <Typography className="text-[14px]">{`${convertLocaleString(
                          compareActiveLoanItem.estate_remain_loans_hash[key],
                        )}万円`}</Typography>
                      </div>
                    ),
                  )}
              </div>
            </div>
          )}
        </div>
      ) : null}
    </>
  )
}

export default TooltipLoan
