import ApiClient from '@/api/apiClient'
import { InputEstateInvestmentTaxSetting } from '@/models/inputs/inputEstateInvestmentTaxSettingsModel'

export const getInputEstateInvestmentTaxSetting = async (
) => {
  return await ApiClient.get(`/input_estate_investment_tax_settings`, (response: InputEstateInvestmentTaxSetting) => response, {}, false, 'v2',)
}

export const patchInputEstateInvestmentTaxSetting = async (
  values: string,
) => {
  return await ApiClient.patch(`/input_estate_investment_tax_settings`, () => {}, { tax_saving_type: values }, false, 'v2',)
}
