import React, { FC, useEffect, useContext } from 'react'
import { useParams } from 'react-router'
import { useDispatch } from 'react-redux'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import CircleIcon from '@/assets/images/v2/circle.svg'
import FamilyMonochromaticIcon from '@/assets/images/v2/family-monochromatic.svg'
import Divider from '@/components/v2/atoms/Divider'
import MarginContainer from '@/components/v2/atoms/MarginContainer'
import Paper from '@/components/v2/atoms/Paper'
import Typography from '@/components/v2/atoms/Typography'
import Breadcrumb from '@/components/v2/molecules/Breadcrumb'
import PaperTitle from '@/components/v2/molecules/PaperTitle'

import {
  includes as _includes,
  isNull as _isNull,
  isUndefined as _isUndefined,
  sumBy as _sumBy,
  find as _find,
  isEmpty as _isEmpty,
  reduce as _reduce,
  filter as _filter,
  every as _every,
} from 'lodash'
import { setPageMeta } from '@/containers/pageMetasSlice'
import { AccountContext } from '@/models/accountsModelFunc'
import MyDataPersonEditForm from './Form'

const MyDataPersonEdit: FC = () => {
  const dispatch = useDispatch()
  const { handleMovePage } = useCustomHistory()
  const { isAdmin } = useContext(AccountContext)
  const { account_id } = useParams<{ account_id?: string }>()

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'マイデータ | お仕事 編集',
        description: 'マイデータ | お仕事 編集',
        keyword: 'マイデータ | お仕事 編集',
      }),
    )
  }, [])

  let personFlg = Number(useParams<{ person_flg?: string }>().person_flg)

  const movePageAfterForm = () => {
    handleMovePage(
      isAdmin ? `/v2/accounts/${account_id}/myData/salaries` : '/v2/myData/salaries',
    )
  }

  return (
    <MarginContainer className="px-8 md:px-32 lg:px-0 py-8 md:py-40">
      <Breadcrumb
        className="pb-24 md:pb-32"
        data={[
          {
            name: 'マイデータ',
            url: isAdmin ? `/v2/accounts/${account_id}/myData` : '/v2/myData',
          },
          {
            name: 'マイデータの設定',
            url: isAdmin
              ? `/v2/accounts/${account_id}/myData/salaries`
              : '/v2/myData/salaries',
          },
          {
            name: `${personFlg == 0 ? 'ご本人' : '配偶者'}の設定`,
          },
        ]}
      />

      <PaperTitle
        className="mb-[16px] md:mb-[40px]"
        title="ご本人情報の設定"
        description="日々の収入や支出を登録することで改善案などのご提案が可能になります"
        icon={<FamilyMonochromaticIcon />}
        smallIcon={
          <FamilyMonochromaticIcon className="h-[82px] w-[130px]" viewBox="0 0 117 154" />
        }
      />

      <Paper className="bg-white p-24 md:p-40">
        <div className="flex items-center pb-10">
          <Typography
            className="bottom-2 pr-8 relative text-24 md:text-28 text-black-800"
            isBold
          >
            {`${personFlg == 0 ? 'ご本人' : '配偶者'}のお仕事について`}
          </Typography>
        </div>

        <Divider className="border-black-300 border-t-2 pb-20 md:pb-24" />

        <div className="flex items-center pb-20 md:pb-24">
          <CircleIcon className="mr-8" />
          <Typography
            className="bottom-2 relative text-20 md:text-24 text-black-800"
            isBold
          >
            お仕事
          </Typography>
        </div>
        <MyDataPersonEditForm
          personFlg={personFlg}
          onClose={movePageAfterForm}
          onSubmit={movePageAfterForm}
        />
      </Paper>
    </MarginContainer>
  )
}

export default MyDataPersonEdit
