import React, { FC, ChangeEvent } from 'react'
import clsx from 'clsx'
import { isUndefined as _isUndefined } from 'lodash'
import SelectTriangleIcon from '@/assets/images/v2/select-triangle.svg'

interface SelectBoxWithoutFinalFormProps {
  /** 名前 */
  name: string
  /** クラス名 */
  className?: string
  /** selectタグ用クラス名 */
  selectClassName?: string
  /** 幅用クラス名 */
  widthClassName?: string
  /** 高さ用クラス名 */
  heightClassName?: string
  /** プレースホルダ */
  placeholder?: string
  /** オプション */
  options: { value: any; label: string; isDisabled?: boolean }[]
  /** true: width100%, false: width100%無し */
  isFullWidth?: boolean
  /** true: 非活性, false: 活性 */
  isDisabled?: boolean
  /** バリデーションメソッド */
  validate?: (value: any) => void
  /** onChangeメソッド */
  onChange?: (event: ChangeEvent) => void
  /** 設定値 */
  value?: any
}

const SelectBoxWithoutFinalForm: FC<SelectBoxWithoutFinalFormProps> = (props) => {
  const {
    name,
    className,
    selectClassName,
    widthClassName,
    heightClassName,
    placeholder,
    options,
    isFullWidth,
    isDisabled,
    validate,
    value,
    onChange,
  } = props

  return (
    <div
      className={clsx(className, widthClassName, {
        ['w-full']: isFullWidth,
      })}
    >
      <div
        className={clsx(`${widthClassName} inline-block relative`, {
          ['w-full']: isFullWidth,
        })}
      >
        <select
          name={name}
          className={clsx(
            `${selectClassName} ${widthClassName} ${
              heightClassName ?? 'h-44'
            } appearance-none bg-white border border-black-300 pl-16 pr-1.5rem rounded-2 text-14 disabled:bg-black-200 hover:border-black-500`,
            {
              ['w-full']: isFullWidth,
            },
          )}
          value={value}
          disabled={isDisabled}
          onChange={onChange}
        >
          {!_isUndefined(placeholder) ? (
            <option value="" disabled>
              {placeholder}
            </option>
          ) : (
            <option className="hidden" value="" />
          )}
          {options.map((item) => (
            <option key={item.value} value={item.value} disabled={item.isDisabled}>
              {item.label}
            </option>
          ))}
        </select>
        <div className="absolute flex inset-y-0 items-center pointer-events-none px-0.5rem right-0">
          <SelectTriangleIcon />
        </div>
      </div>
    </div>
  )
}

export default SelectBoxWithoutFinalForm
