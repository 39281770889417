import React, { FC, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Decorator } from 'final-form'
import createDecorator from 'final-form-calculate'
import arrayMutators from 'final-form-arrays'
import { set as _set, last as _last } from 'lodash'
import {
  updateFutureV2HousingOther,
  updateFutureV2HousingRent,
  updateFutureV2HousingSelfOwned,
} from '@/containers/futures/futureV2HousingsSlice'
import {
  DescriptionContext,
  useDescriptionTemplates,
} from '@/hooks/useDescriptionTemplates'
import { QuestionTemplate } from '@/models/commonModalForm'
import {
  HOUSE_TYPE,
  isFlatLoan,
  isPairLoan,
  isWithChartLoan,
  isHousingType2Custom,
  isMansionType,
  isWithLoan,
} from '@/models/commonHousingModelFunc'
import {
  castNumberWithoutOperator,
  convertMomentDate,
  isNotEmptyValue,
} from '@/models/commonsModelFunc'
import {
  LAST_AGE,
  NEXT_HOUSE_TYPE,
  isOtherHouseType,
  handleSetInitialValues,
} from '@/models/futures/futureV2HousingsModelFunc'
import { FutureV2HousingRent } from '@/models/futures/futureV2HousingRentsModel'
import { isSplitedLoan } from '@/models/futures/futureV2HousingSelfOwnedModelFunc'
import {
  LOAN_CALCULATION_FLAT_CHANGE_LAND_FIELDS,
  LOAN_CALCULATION_FLAT_CHANGE_BUILDING_FIELDS,
} from '@/models/futures/futureV2HousingLoanCalculationsModelFunc'
import { FutureV2Housing } from '@/models/futures/futureV2HousingsModel'
import {
  isRentHouseType,
  isSelfOwnedHouseType,
} from '@/models/futures/futureV2HousingsModelFunc'
import { RootState } from '@/store'

import OtherCostDetail from '@/templates/v2/simulations/futureV2Housings/FutureV2HousingModalForm/OtherCostDetail'
import RelocationPhaseSetting from '@/templates/v2/simulations/futureV2Housings/FutureV2HousingModalForm/RelocationPhaseSetting'
import HouseType from '@/templates/v2/commons/modals/commonHousing/selfOwned/HouseType'
import HousingType2 from '@/templates/v2/commons/modals/commonHousing/selfOwned/HousingType2'
import HouseBasicInfo from '@/templates/v2/commons/modals/commonHousing/selfOwned/HouseBasicInfo'
import TaxYearly from '@/templates/v2/commons/modals/commonHousing/selfOwned/TaxYearly'
import AboutTax from '@/templates/v2/commons/modals/commonHousing/selfOwned/AboutTax'
import RepairCost from '@/templates/v2/commons/modals/commonHousing/selfOwned/RepairCost'
import RepairFundAndManagement from '@/templates/v2/commons/modals/commonHousing/selfOwned/RepairFundAndManagement'
import OtherCost from '@/templates/v2/commons/modals/commonHousing/selfOwned/OtherCost'
import ChildRearingDeduction from '@/templates/v2/commons/modals/commonHousing/selfOwned/ChildRearingDeduction'
import AboutOtherCost from '@/templates/v2/commons/modals/commonHousing/selfOwned/AboutOtherCost'
import WithOrWithoutLoan from '@/templates/v2/commons/modals/commonHousing/selfOwned/WithOrWithoutLoan'
import LoanSplit from '@/templates/v2/commons/modals/commonHousing/selfOwned/LoanSplit'
import LoanType from '@/templates/v2/commons/modals/commonHousing/selfOwned/LoanType'
import LoanLender from '@/templates/v2/commons/modals/commonHousing/selfOwned/LoanLender'
import PairLoanSeparator from '@/templates/v2/commons/modals/commonHousing/selfOwned/PairLoanSeparator'
import RepaymentAmountDetail from '@/templates/v2/commons/modals/commonHousing/selfOwned/RepaymentAmountDetail'
import InterestRates from '@/templates/v2/commons/modals/commonHousing/selfOwned/InterestRates'
import LoanDeduction from '@/templates/v2/commons/modals/commonHousing/selfOwned/LoanDeduction'
import AbountLoanDeduction from '@/templates/v2/commons/modals/commonHousing/selfOwned/AboutLoanDeduction'
import BuildingLoanSeparator from '@/templates/v2/commons/modals/commonHousing/selfOwned/BuildingLoanSeparator'

import {
  buildHousingSelfOwnedSubmitValues,
  buildHousingRentSubmitValues,
  buildHousingOtherSubmitValues,
} from '@/utils/modalForms/futureV2Housings'

import Modal from '@/components/v2/atoms/Modal'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import { Form } from 'react-final-form'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import Grid from '@/components/v2/atoms/Grid'
import Typography from '@/components/v2/atoms/Typography'
import ChevronRightIcon from '@/assets/images/v2/chevron-right.svg'
import { useFlashAlert } from '@/hooks/useFlashAlert'

const DESCRIPTION_TEMPLATES: QuestionTemplate[] = [
  {
    children: <OtherCostDetail />,
    condition: (values: any) => isOtherHouseType(values?.houseType),
  },
  {
    children: <RelocationPhaseSetting isEdit />,
    condition: (values: any) => isRentHouseType(values?.future_v2_housing?.house_type),
    validate: (values: any) => {
      let errorMessages: { [key: string]: any } = {}

      const lastfutureV2HousingRents: any = _last(
        values?.future_v2_housing?.future_v2_housing_rents_attributes,
      )

      if (!lastfutureV2HousingRents) {
        _set(errorMessages, ['future_v2_housing'], '転居の予定を登録してください。')
      }

      if (
        values?.living_end_at_age &&
        Number(lastfutureV2HousingRents?.living_end_at_age) >=
          Number(values?.living_end_at_age)
      ) {
        _set(
          errorMessages,
          ['living_end_at_age'],
          '追加済みの年齢以後の年齢を入力してください。',
        )
      }

      return errorMessages
    },
  },
  {
    children: <HouseType isFuture />,
    condition: (values: any) =>
      isSelfOwnedHouseType(values?.houseType) && values?.modalNumber === 1,
  },
  {
    children: <HousingType2 isFuture />,
    condition: (values: any) =>
      isSelfOwnedHouseType(values?.houseType) && values?.modalNumber === 1,
  },
  {
    children: <HouseBasicInfo isFuture isEdit />,
    condition: (values: any) =>
      isSelfOwnedHouseType(values?.houseType) && values?.modalNumber === 1,
  },
  {
    children: <TaxYearly isFuture />,
    footer: <AboutTax />,
    condition: (values: any) =>
      isSelfOwnedHouseType(values?.houseType) && values?.modalNumber === 2,
  },
  {
    children: <RepairCost isFuture />,
    condition: (values: any) =>
      isSelfOwnedHouseType(values?.houseType) && values?.modalNumber === 2,
  },
  {
    children: <RepairFundAndManagement isFuture />,
    condition: (values: any) =>
      isSelfOwnedHouseType(values?.houseType) &&
      values?.modalNumber === 2 &&
      isMansionType(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes?.house_type,
      ),
    validate: (values: any) => {
      let errorMessages: { [key: string]: any } = {}
      const {
        repair_reserve_fund_amount_start: repairReserveFundAmountStart,
        repair_reserve_fund_amount_finish: repairReserveFundAmountFinish,
        repair_reserve_fund_increase_start: repairReserveFundIncreaseStart,
        repair_reserve_fund_increase_finish: repairReserveFundIncreaseFinish,
      } = values.future_v2_housing?.future_v2_housing_self_owned_attributes
      if (
        isNotEmptyValue(repairReserveFundAmountStart) &&
        isNotEmptyValue(repairReserveFundAmountFinish) &&
        castNumberWithoutOperator(repairReserveFundAmountStart) >
          castNumberWithoutOperator(repairReserveFundAmountFinish)
      ) {
        _set(
          errorMessages,
          [
            'future_v2_housing',
            'future_v2_housing_self_owned_attributes',
            'repair_reserve_fund_amount_start',
          ],
          '期間終了年数以下の年数を入力してください。',
        )
      }

      if (
        isNotEmptyValue(repairReserveFundIncreaseStart) &&
        isNotEmptyValue(repairReserveFundIncreaseFinish) &&
        castNumberWithoutOperator(repairReserveFundIncreaseStart) >
          castNumberWithoutOperator(repairReserveFundIncreaseFinish)
      ) {
        _set(
          errorMessages,
          [
            'future_v2_housing',
            'future_v2_housing_self_owned_attributes',
            'repair_reserve_fund_increase_start',
          ],
          '期間終了年数以下の年数を入力してください。',
        )
      }

      return errorMessages
    },
  },
  {
    children: <OtherCost isFuture />,
    footer: <AboutOtherCost />,
    condition: (values: any) =>
      isSelfOwnedHouseType(values?.houseType) && values?.modalNumber === 2,
  },
  {
    children: <WithOrWithoutLoan isFuture />,
    condition: (values: any) =>
      isSelfOwnedHouseType(values?.houseType) && values?.modalNumber === 3,
  },
  {
    children: <ChildRearingDeduction isFuture />,
    condition: (values: any) =>
      isSelfOwnedHouseType(values?.houseType) &&
      values?.modalNumber === 3 &&
      isWithLoan(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes?.loan_type1,
      ) &&
      values?.future_v2_housing?.future_v2_housing_self_owned_attributes?.purchased_at_year >= 2024,
  },
  {
    children: <LoanSplit isFuture />,
    condition: (values: any) =>
      isSelfOwnedHouseType(values?.houseType) &&
      values?.modalNumber === 3 &&
      isHousingType2Custom(
        values.future_v2_housing?.future_v2_housing_self_owned_attributes?.housing_type2,
      ) &&
      isWithLoan(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes?.loan_type1,
      ),
  },
  {
    children: <LoanType isFuture />,
    condition: (values: any) =>
      isSelfOwnedHouseType(values?.houseType) &&
      values?.modalNumber === 3 &&
      isWithLoan(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes?.loan_type1,
      ),
  },
  {
    children: <LoanLender isFuture />,
    condition: (values: any) =>
      isSelfOwnedHouseType(values?.houseType) &&
      values?.modalNumber === 3 &&
      isWithLoan(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes?.loan_type1,
      ),
  },
  {
    children: <PairLoanSeparator />,
    condition: (values: any) =>
      isSelfOwnedHouseType(values?.houseType) &&
      values?.modalNumber === 3 &&
      isWithLoan(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes?.loan_type1,
      ) &&
      isPairLoan(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes
          ?.future_v2_housing_loans_attributes?.[0]?.loan_type,
      ),
  },
  {
    children: <RepaymentAmountDetail isFuture />,
    condition: (values: any) =>
      isSelfOwnedHouseType(values?.houseType) &&
      values?.modalNumber === 3 &&
      isWithLoan(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes?.loan_type1,
      ) &&
      !isWithChartLoan(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes
          ?.future_v2_housing_loans_attributes?.[0]?.repayment_chart,
      ),
  },
  {
    children: <InterestRates isFuture />,
    condition: (values: any) =>
      isSelfOwnedHouseType(values?.houseType) &&
      values?.modalNumber === 3 &&
      isWithLoan(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes?.loan_type1,
      ) &&
      !isWithChartLoan(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes
          ?.future_v2_housing_loans_attributes?.[0]?.repayment_chart,
      ),
  },
  {
    children: <LoanDeduction isFuture />,
    footer: <AbountLoanDeduction />,
    condition: (values: any) =>
      isSelfOwnedHouseType(values?.houseType) &&
      values?.modalNumber === 3 &&
      isWithLoan(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes?.loan_type1,
      ),
  },
  // ====================================== 以下配偶者ローン
  {
    children: <PairLoanSeparator isSpouse />,
    condition: (values: any) =>
      isSelfOwnedHouseType(values?.houseType) &&
      values?.modalNumber === 3 &&
      isWithLoan(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes?.loan_type1,
      ) &&
      isPairLoan(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes
          ?.future_v2_housing_loans_attributes?.[0]?.loan_type,
      ),
  },
  {
    children: <RepaymentAmountDetail isFuture isSpouse />,
    condition: (values: any) =>
      isSelfOwnedHouseType(values?.houseType) &&
      values?.modalNumber === 3 &&
      isWithLoan(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes?.loan_type1,
      ) &&
      !isWithChartLoan(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes
          ?.future_v2_housing_loans_attributes?.[0]?.repayment_chart,
      ) &&
      isPairLoan(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes
          ?.future_v2_housing_loans_attributes?.[0]?.loan_type,
      ),
  },
  {
    children: <InterestRates isFuture isSpouse />,
    condition: (values: any) =>
      isSelfOwnedHouseType(values?.houseType) &&
      values?.modalNumber === 3 &&
      isWithLoan(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes?.loan_type1,
      ) &&
      !isWithChartLoan(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes
          ?.future_v2_housing_loans_attributes?.[0]?.repayment_chart,
      ) &&
      isPairLoan(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes
          ?.future_v2_housing_loans_attributes?.[0]?.loan_type,
      ),
  },
  {
    children: <LoanDeduction isFuture isSpouse />,
    footer: <AbountLoanDeduction />,
    condition: (values: any) =>
      isSelfOwnedHouseType(values?.houseType) &&
      values?.modalNumber === 3 &&
      isWithLoan(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes?.loan_type1,
      ) &&
      isPairLoan(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes
          ?.future_v2_housing_loans_attributes?.[0]?.loan_type,
      ),
  },
  {
    children: (
      <>
        <BuildingLoanSeparator />
        <div></div>
      </>
    ),
    condition: (values: any) =>
      isSelfOwnedHouseType(values?.houseType) &&
      values?.modalNumber === 3 &&
      isSplitedLoan(values?.future_v2_housing?.future_v2_housing_self_owned_attributes),
  },
  // ====================================== 以下建物用ローン
  {
    children: <LoanType isFuture isBuilding />,
    condition: (values: any) =>
      isSelfOwnedHouseType(values?.houseType) &&
      values?.modalNumber === 3 &&
      isSplitedLoan(values?.future_v2_housing?.future_v2_housing_self_owned_attributes),
  },
  {
    children: <LoanLender isFuture isBuilding />,
    condition: (values: any) =>
      isSelfOwnedHouseType(values?.houseType) &&
      values?.modalNumber === 3 &&
      isSplitedLoan(values?.future_v2_housing?.future_v2_housing_self_owned_attributes),
  },
  {
    children: <PairLoanSeparator />,
    condition: (values: any) =>
      isSelfOwnedHouseType(values?.houseType) &&
      values?.modalNumber === 3 &&
      isSplitedLoan(values?.future_v2_housing?.future_v2_housing_self_owned_attributes) &&
      isPairLoan(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes
          ?.future_v2_housing_loans_attributes?.[2]?.loan_type,
      ),
  },
  {
    children: <RepaymentAmountDetail isFuture isBuilding />,
    condition: (values: any) =>
      isSelfOwnedHouseType(values?.houseType) &&
      values?.modalNumber === 3 &&
      isSplitedLoan(values?.future_v2_housing?.future_v2_housing_self_owned_attributes) &&
      !isWithChartLoan(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes
          ?.future_v2_housing_loans_attributes?.[2]?.repayment_chart,
      ),
  },
  {
    children: <InterestRates isFuture isBuilding />,
    condition: (values: any) =>
      isSelfOwnedHouseType(values?.houseType) &&
      values?.modalNumber === 3 &&
      isSplitedLoan(values?.future_v2_housing?.future_v2_housing_self_owned_attributes) &&
      !isWithChartLoan(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes
          ?.future_v2_housing_loans_attributes?.[2]?.repayment_chart,
      ),
  },
  {
    children: <LoanDeduction isFuture isBuilding />,
    footer: <AbountLoanDeduction />,
    condition: (values: any) =>
      isSelfOwnedHouseType(values?.houseType) &&
      values?.modalNumber === 3 &&
      isSplitedLoan(values?.future_v2_housing?.future_v2_housing_self_owned_attributes),
  },
  // ====================================== 以下配偶者ローン
  {
    children: <PairLoanSeparator isSpouse />,
    condition: (values: any) =>
      isSelfOwnedHouseType(values?.houseType) &&
      values?.modalNumber === 3 &&
      isSplitedLoan(values?.future_v2_housing?.future_v2_housing_self_owned_attributes) &&
      isPairLoan(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes
          ?.future_v2_housing_loans_attributes?.[2]?.loan_type,
      ),
  },
  {
    children: <RepaymentAmountDetail isFuture isBuilding isSpouse />,
    condition: (values: any) =>
      isSelfOwnedHouseType(values?.houseType) &&
      values?.modalNumber === 3 &&
      isSplitedLoan(values?.future_v2_housing?.future_v2_housing_self_owned_attributes) &&
      !isWithChartLoan(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes
          ?.future_v2_housing_loans_attributes?.[2]?.repayment_chart,
      ) &&
      isPairLoan(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes
          ?.future_v2_housing_loans_attributes?.[2]?.loan_type,
      ),
  },
  {
    children: <InterestRates isFuture isBuilding isSpouse />,
    condition: (values: any) =>
      isSelfOwnedHouseType(values?.houseType) &&
      values?.modalNumber === 3 &&
      isSplitedLoan(values?.future_v2_housing?.future_v2_housing_self_owned_attributes) &&
      !isWithChartLoan(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes
          ?.future_v2_housing_loans_attributes?.[2]?.repayment_chart,
      ) &&
      isPairLoan(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes
          ?.future_v2_housing_loans_attributes?.[2]?.loan_type,
      ),
  },
  {
    children: <LoanDeduction isFuture isBuilding isSpouse />,
    footer: <AbountLoanDeduction />,
    condition: (values: any) =>
      isSelfOwnedHouseType(values?.houseType) &&
      values?.modalNumber === 3 &&
      isSplitedLoan(values?.future_v2_housing?.future_v2_housing_self_owned_attributes) &&
      isPairLoan(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes
          ?.future_v2_housing_loans_attributes?.[2]?.loan_type,
      ),
  },
]

const calculator: Decorator<any> = createDecorator(
  {
    field: LOAN_CALCULATION_FLAT_CHANGE_BUILDING_FIELDS,
    updates: {
      'future_v2_housing.future_v2_housing_self_owned_attributes.future_v2_housing_loans_attributes[3]future_v2_housing_loan_calculation_attributes.started_borrowing_amount_manyen': (
        _,
        allValues: any,
      ) => {
        if (
          !isFlatLoan(
            allValues.future_v2_housing.future_v2_housing_self_owned_attributes
              ?.future_v2_housing_loans_attributes?.[2]?.loan_lender,
          )
        )
          return allValues.future_v2_housing.future_v2_housing_self_owned_attributes
            .future_v2_housing_loans_attributes[3]
            ?.future_v2_housing_loan_calculation_attributes
            ?.started_borrowing_amount_manyen

        const totalAmount = allValues.started_borrowing_total_amount_building_manyen ?? 0
        const flatAmount =
          allValues.future_v2_housing?.future_v2_housing_self_owned_attributes
            ?.future_v2_housing_loans_attributes?.[2]
            ?.future_v2_housing_loan_calculation_attributes
            ?.started_borrowing_amount_manyen ?? 0

        return (
          castNumberWithoutOperator(totalAmount) - castNumberWithoutOperator(flatAmount)
        )
      },
    },
  },

  {
    field: LOAN_CALCULATION_FLAT_CHANGE_LAND_FIELDS,
    updates: {
      'future_v2_housing.future_v2_housing_self_owned_attributes.future_v2_housing_loans_attributes[1]future_v2_housing_loan_calculation_attributes.started_borrowing_amount_manyen': (
        _,
        allValues: any,
      ) => {
        if (
          !isFlatLoan(
            allValues.future_v2_housing.future_v2_housing_self_owned_attributes
              ?.future_v2_housing_loans_attributes?.[0]?.loan_lender,
          )
        )
          return allValues.future_v2_housing.future_v2_housing_self_owned_attributes
            .future_v2_housing_loans_attributes[1]
            ?.future_v2_housing_loan_calculation_attributes
            ?.started_borrowing_amount_manyen

        const totalAmount = allValues.started_borrowing_total_amount_land_manyen ?? 0
        const flatAmount =
          allValues.future_v2_housing?.future_v2_housing_self_owned_attributes
            ?.future_v2_housing_loans_attributes?.[0]
            ?.future_v2_housing_loan_calculation_attributes
            ?.started_borrowing_amount_manyen ?? 0

        return (
          castNumberWithoutOperator(totalAmount) - castNumberWithoutOperator(flatAmount)
        )
      },
    },
  },
)

interface FutureV2HousingUpdateModalFormProps {
  /** 年齢 */
  age: number
  /** true: オープン, false: クローズ */
  isOpen: boolean
  /** true: 配偶者あり, false: 配偶者なし */
  hasSpouse: boolean
  /** futureV2Housing初期値 */
  futureV2Housing: FutureV2Housing
  /** 0: 賃貸, 1: 自己保有, 3: その他 */
  houseType: number
  /** 自己保有の場合 - 1: 基本情報, 2: 支出情報, 3: ローン情報 */
  modalNumber?: number
  /** 対象futureHousingSaleID */
  targetHousingSaleId?: number | undefined
  /** 対象futureLendHousingID */
  targetHousingLendId?: number | undefined
  /** モーダルクローズメソッド */
  handleClose: () => void
  /** 共通API呼び出しメソッド */
  commonCallApi: () => void
}

const FutureV2HousingUpdateModalForm: FC<FutureV2HousingUpdateModalFormProps> = (
  props,
) => {
  const dispatch = useDispatch()
  const { showFlashMsg } = useFlashAlert()
  const {
    age,
    isOpen,
    hasSpouse,
    futureV2Housing,
    houseType,
    modalNumber,
    targetHousingSaleId,
    targetHousingLendId,
    handleClose,
    commonCallApi,
  } = props

  const [isRentLastAgeModalOpen, setIsRentLastAgeModalOpen] = useState<boolean>(false)

  const { isLoading } = useSelector((state: RootState) => state.futureV2Housings)

  const initialValues = useMemo(() => handleSetInitialValues(futureV2Housing), [
    futureV2Housing,
    houseType,
    modalNumber,
  ])

  const targetAge = useMemo(() => futureV2Housing.scheduled_at_age, [futureV2Housing])
  const targetYear = useMemo(
    () => convertMomentDate(futureV2Housing.scheduled_at, 'YYYY'),
    [futureV2Housing],
  )

  const { getContextValue } = useDescriptionTemplates(
    { ...initialValues, houseType, modalNumber },
    DESCRIPTION_TEMPLATES,
  )
  const wrappedCompletedCallback = () => {
    showFlashMsg('更新しました')
    commonCallApi()
    handleClose()
  }

  const handleSubmit = (values: any) => {
    const pickedValues: any = values
    const { future_v2_housing } = pickedValues

    const updateFutureV2HousingOtherFunc = () => {
      const { future_v2_housing_other_attributes: otherAttributes } = future_v2_housing

      const newOtherValues = {
        future_v2_housing_id: futureV2Housing.id,
        future_v2_housing: {
          house_type: HOUSE_TYPE.other,
          future_v2_housing_other_attributes: {
            ...buildHousingOtherSubmitValues(otherAttributes),
          },
        },
      }

      new Promise(() => {
        dispatch(
          updateFutureV2HousingOther(newOtherValues, () => wrappedCompletedCallback()),
        )
      })
    }

    const updateFutureV2HousingRentFunc = () => {
      const { next_house_plan, is_ready_for_submit, future_v2_housing } = values as any
      const { future_v2_housing_rents_attributes: rentsAttributes } = future_v2_housing

      const isNextOwnType = next_house_plan === NEXT_HOUSE_TYPE.own

      const lastRent = _last(rentsAttributes as FutureV2HousingRent[])

      if (
        !isNextOwnType &&
        !is_ready_for_submit &&
        Number(lastRent?.living_end_at_age) !== LAST_AGE
      ) {
        values.is_ready_for_submit = true
        return setIsRentLastAgeModalOpen(!isRentLastAgeModalOpen)
      }

      const newValuesWithRent = {
        future_v2_housing_id: futureV2Housing.id,
        future_v2_housing: {
          house_type: HOUSE_TYPE.rent,
          future_v2_housing_rents_attributes: [
            ...buildHousingRentSubmitValues(rentsAttributes),
          ],
        },
      }

      new Promise(() => {
        dispatch(
          updateFutureV2HousingRent(newValuesWithRent, () => wrappedCompletedCallback()),
        )
      })
    }

    const updateFutureV2HousingSelfOwnedFunc = () => {
      const {
        future_v2_housing_self_owned_attributes: selfOwnedAttributes,
      } = future_v2_housing

      if (!!selfOwnedAttributes.property_price_manyen) {
        selfOwnedAttributes.property_price_manyen = castNumberWithoutOperator(
          selfOwnedAttributes.property_price_manyen,
        )
      }

      if (!!selfOwnedAttributes.property_price_land_manyen) {
        selfOwnedAttributes.property_price_land_manyen = castNumberWithoutOperator(
          selfOwnedAttributes.property_price_land_manyen,
        )
      }

      if (!!selfOwnedAttributes.property_price_building_manyen) {
        selfOwnedAttributes.property_price_building_manyen = castNumberWithoutOperator(
          selfOwnedAttributes.property_price_building_manyen,
        )
      }

      const newValuesWithSelfOwned = {
        future_v2_housing_id: futureV2Housing.id,
        future_v2_housing: {
          house_type: HOUSE_TYPE.own,
          future_v2_housing_self_owned_attributes: {
            ...buildHousingSelfOwnedSubmitValues({
              ...initialValues.future_v2_housing.future_v2_housing_self_owned_attributes,
              ...selfOwnedAttributes,
            }),
          },
        },
      }

      new Promise(() => {
        dispatch(
          updateFutureV2HousingSelfOwned(newValuesWithSelfOwned, () =>
            wrappedCompletedCallback(),
          ),
        )
      })
    }

    if (isOtherHouseType(houseType)) {
      updateFutureV2HousingOtherFunc()
    } else if (isRentHouseType(houseType)) {
      updateFutureV2HousingRentFunc()
    } else {
      updateFutureV2HousingSelfOwnedFunc()
    }
  }

  const title = useMemo(() => {
    // /** 0: 賃貸, 1: 自己保有, 3: その他 */
    // houseType: number
    // /** 自己保有の場合 - 1: 基本情報, 2: 支出情報, 3: ローン情報 */
    // modalNumber?: number

    if (houseType == 0) {
      return '将来の賃貸を想定する'
    }
    if (houseType == 3) {
      return '将来のその他の住居を想定する'
    }
    if (houseType == 1 && modalNumber == 1) {
      return '基本情報について'
    }
    if (houseType == 1 && modalNumber == 2) {
      return '支出情報について'
    }

    if (houseType == 1 && modalNumber == 3) {
      return undefined
    }

    return '将来の住居を想定する'
  }, [houseType, modalNumber])

  return (
    <Modal
      className="w-[95%] xl:w-[1000px] cursor-default"
      isOpen={isOpen}
      isDisableBackdropClick
      onClose={handleClose}
      isLoading={isLoading}
    >
      <Form
        onSubmit={handleSubmit}
        initialValues={{
          ...initialValues,
          houseType,
          modalNumber,
          is_ready_for_submit: false,
        }}
        mutators={{ ...arrayMutators }}
        decorators={[calculator]}
        render={({ handleSubmit, values, errors }) => {
          return (
            <form className="pb-[30px]" onSubmit={handleSubmit}>
              <DescriptionContext.Provider
                value={{
                  ...getContextValue(),
                  age,
                  targetAge,
                  targetYear,
                  hasSpouse,
                  targetHousingSaleId,
                  targetHousingLendId,
                  isRentLastAgeModalOpen,
                  setIsRentLastAgeModalOpen,
                  handleSubmit,
                }}
              >
                {!!title && <CircleTitle className="pb-[32px]" title={title} />}
                <Grid className="grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px]">
                  {DESCRIPTION_TEMPLATES.map((item, index) => {
                    return (
                      ((item.condition && item.condition(values)) || !item.condition) && (
                        <>
                          <React.Fragment key={index}>{item.children}</React.Fragment>
                        </>
                      )
                    )
                  })}
                </Grid>
              </DescriptionContext.Provider>

              <div className="flex justify-center mt-40">
                <ButtonPrimary
                  className="h-64 w-246"
                  isDisabled={Object.keys(errors).length > 0}
                  onClick={() => handleSubmit(values)}
                >
                  <Typography
                    className="bottom-2 flex-auto pl-30 relative text-16 text-white"
                    isBold
                  >
                    保存する
                  </Typography>
                  <ChevronRightIcon className="mr-17" />
                </ButtonPrimary>
              </div>
            </form>
          )
        }}
      />
    </Modal>
  )
}

export default FutureV2HousingUpdateModalForm
