import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { setLogindFunc } from '@/containers/accountsSlice'
import { RootState } from '@/store'
import { isLogind } from '@/utils/auth'

const LogindRedirect: FC = (props) => {
  const dispatch = useDispatch()

  const { children } = props
  const { isLogin } = useSelector((state: RootState) => state.accounts)

  useEffect(() => {
    isLogind() && dispatch(setLogindFunc())
  }, [])

  return <>{isLogin ? <Redirect to="/v2/dashboards" /> : <>{children}</>}</>
}

export default LogindRedirect
