import { find as _find, every as _every } from 'lodash'
import {
  isNotEmptyValue,
  defaultToEmptyString,
  castNumberWithoutOperator,
} from './commonsModelFunc'
import { PERSON_FLG } from './familyStructuresModelFunc'
import { HousingLoan, HousingLoanRepaymentValues, LoanType } from './housingLoansModel'

// ローン設定
export const IS_LOAN_CUSTOMIZED = {
  manual: true,
  auto: false,
}

// ローン方式
export const LOAN_TYPE = {
  singlePerson: 'single_person',
  singleSpouse: 'single_spouse',
  pairLoan: 'pair_loan',
  coupleSum: 'couple_sum',
}

import {
  LOAN_TYPE_SELECT_ITEMS,
  HAS_LOAN_LIFE_INSURANCE_CONTRACT_SELECT_ITEMS,
} from '../constants/formItem/housingLoans'

export const isInputRepaymentAllValues = (
  values: Partial<Omit<HousingLoan, 'id' | 'base_type' | 'base_id'>> & {
    [key: string]: any
  },
) => {
  const targetKeys = [
    'loan_type',
    'loan_down_payment_manyen',
    'loan_amount_manyen',
    'loan_interest_ratio_percent',
    'repayment_period_years',
  ]

  return _every(targetKeys, (key) => isNotEmptyValue(values?.[key]))
}

export const buildRepaymentValues = (
  values: Partial<Omit<HousingLoan, 'id' | 'base_type' | 'base_id'>>,
) => {
  const newValues: HousingLoanRepaymentValues = { housing_loans: [] }
  const { loan_type } = values

  if (loan_type === LOAN_TYPE.pairLoan) {
    newValues.housing_loans = [
      { ...values, person_flg: PERSON_FLG.person },
      { ...values, person_flg: PERSON_FLG.spouse },
    ]
  } else {
    newValues.housing_loans = [
      {
        ...values,
        person_flg: convertLoanTypeToPersonFlg(loan_type),
        loan_amount_manyen: castNumberWithoutOperator(values.loan_amount_manyen),
        loan_down_payment_manyen: castNumberWithoutOperator(
          values.loan_down_payment_manyen,
        ),
      },
    ]
  }

  return newValues
}

export const convertLoanType = (loanType: LoanType | null) => {
  const loanTypeLabel = _find(LOAN_TYPE_SELECT_ITEMS, (item) => item.value === loanType)
    ?.label

  return defaultToEmptyString(loanTypeLabel)
}

export const convertLoanTypeToPersonFlg = (loanType: LoanType | undefined | null) => {
  if (!isNotEmptyValue(loanType)) return null
  return loanType === LOAN_TYPE.singleSpouse ? PERSON_FLG.spouse : PERSON_FLG.person
}

export const convertHasLoanLifeInsuranceContract = (
  hasLoanLifeInsuranceContract: boolean | null,
) => {
  const hasLoanLifeInsuranceContractLabel = _find(
    HAS_LOAN_LIFE_INSURANCE_CONTRACT_SELECT_ITEMS,
    (item) => item.value === hasLoanLifeInsuranceContract,
  )?.label

  return defaultToEmptyString(hasLoanLifeInsuranceContractLabel)
}
