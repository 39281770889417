import React, { FC, useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { find as _find, isUndefined as _isUndefined } from 'lodash'
import Button from '@/components/v2/atoms/Button'
import Typography from '@/components/v2/atoms/Typography'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import { setPageMeta } from '@/containers/pageMetasSlice'
import { fetchInputContributionPensions } from '@/containers/inputs/inputContributionPensionsSlice'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import { defaultToEmptyString, convertLocaleString } from '@/models/commonsModelFunc'
import { PERSON_FLG } from '@/models/familyStructuresModelFunc'
import {
  convertAverageYieldPercent,
  convertReceivingPensionType,
  CONTRIBUTION_TYPE,
} from '@/models/inputs/inputContributionPensionsModelFunc'
import { RootState } from '@/store'
import MyDataListWrapper from '@/templates/v2/myData/_ListWrapper'
import { AccountContext } from '@/models/accountsModelFunc'
import { useAccountControl } from '@/hooks/useAccountControl'

const MyDataPersonContributionPension: FC = () => {
  const dispatch = useDispatch()
  const { handleMovePage } = useCustomHistory()
  const { isAdmin } = useContext(AccountContext)
  const { isMyDataManipulatedApproval } = useAccountControl()

  const { inputContributionPensions } = useSelector(
    (state: RootState) => state.inputContributionPensions,
  )

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'マイデータ | 個人型確定拠出年金',
        description: 'マイデータ | 個人型確定拠出年金',
        keyword: 'マイデータ | 個人型確定拠出年金',
      }),
    )
    dispatch(fetchInputContributionPensions())
  }, [])

  const renderTable = (isPerson: boolean) => {
    const personFlg = isPerson ? PERSON_FLG.person : PERSON_FLG.spouse
    const targetContributionPension = _find(
      inputContributionPensions,
      (item) =>
        item.person_flg === personFlg &&
        item.contribution_type === CONTRIBUTION_TYPE.ideco,
    )

    return (
      <div className="mb-[60px] last:mb-0">
        <div className="sm:flex items-center justify-between pb-[20px]">
          <CircleTitle
            title={isPerson ? 'ご本人' : '配偶者'}
            classNameTitle="text-[20px] md:text-[24px]"
          />
          <Button
            className="w-[84px] md:w-[112px] h-[26px] md:h-[37px] mr-[0] md:mr-[9px] rounded-[4px] bg-white border border-secondary-500 hover:bg-secondary-50"
            isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
            onClick={() =>
              handleMovePage(
                `/v2/myData/personContributionPensions/${
                  isPerson ? 'person' : 'spouse'
                }/edit`,
              )
            }
          >
            <Typography
              className="relative bottom-[1px] text-[12px] md:text-[14px] text-secondary-500"
              isBold
            >
              編集する
            </Typography>
          </Button>
        </div>

        {!_isUndefined(targetContributionPension) && (
          <table className="w-[100%] mb-[60px] text-[12px] md:text-[14px]">
            <tbody>
              <tr className="h-[45px] odd:bg-black-100">
                <td className="w-[30%] px-[16px] md:px-[30px]">
                  <Typography isBold>支払い期間</Typography>
                </td>
                <td className="w-[70%] px-[16px] md:px-[30px]">
                  <Typography>{`${defaultToEmptyString(
                    targetContributionPension?.contribution_period_start_at_age,
                  )}歳から${defaultToEmptyString(
                    targetContributionPension?.contribution_period_end_at_age,
                  )}歳未満`}</Typography>
                </td>
              </tr>
              <tr className="h-[45px] odd:bg-black-100">
                <td className="px-[16px] md:px-[30px]">
                  <Typography isBold>拠出額</Typography>
                </td>
                <td className="px-[16px] md:px-[30px]">
                  <Typography>{`${convertLocaleString(
                    targetContributionPension?.contribution_amount_monthly,
                  )}円/月`}</Typography>
                </td>
              </tr>
              <tr className="h-[45px] odd:bg-black-100">
                <td className="px-[16px] md:px-[30px]">
                  <Typography isBold>評価額</Typography>
                </td>
                <td className="px-[16px] md:px-[30px]">
                  <Typography>{`${convertLocaleString(
                    targetContributionPension?.valuation,
                  )}円`}</Typography>
                </td>
              </tr>
              <tr className="h-[45px] odd:bg-black-100">
                <td className="px-[16px] md:px-[30px]">
                  <Typography isBold>平均利回り</Typography>
                </td>
                <td className="px-[16px] md:px-[30px]">
                  <Typography>{`${convertAverageYieldPercent(
                    targetContributionPension?.average_yield_percent,
                  )}％`}</Typography>
                </td>
              </tr>
              <tr className="h-[45px] odd:bg-black-100">
                <td className="px-[16px] md:px-[30px]">
                  <Typography isBold>受取方式</Typography>
                </td>
                <td className="px-[16px] md:px-[30px]">
                  <Typography>
                    {convertReceivingPensionType(
                      targetContributionPension?.receiving_pension_type,
                    )}
                  </Typography>
                </td>
              </tr>
              <tr className="h-[45px] odd:bg-black-100">
                <td className="px-[16px] md:px-[30px]">
                  <Typography isBold>
                    一時金の
                    <br className="block md:hidden" />
                    受取割合
                  </Typography>
                </td>
                <td className="px-[16px] md:px-[30px]">
                  <Typography>
                    {`${defaultToEmptyString(
                      targetContributionPension?.lump_sum_ratio_percent,
                    )}%`}
                  </Typography>
                </td>
              </tr>
              <tr className="h-[45px] odd:bg-black-100">
                <td className="px-[16px] md:px-[30px]">
                  <Typography isBold>一時金の受取年齢</Typography>
                </td>
                <td className="px-[16px] md:px-[30px]">
                  <Typography>
                    {`${defaultToEmptyString(
                      targetContributionPension?.lump_sum_receiving_at_age,
                    )}歳のとき`}
                  </Typography>
                </td>
              </tr>
              <tr className="h-[45px] odd:bg-black-100">
                <td className="px-[16px] md:px-[30px]">
                  <Typography isBold>
                    受取開始
                    <br className="block md:hidden" />
                    年齢
                  </Typography>
                </td>
                <td className="px-[16px] md:px-[30px]">
                  <Typography>
                    {`${defaultToEmptyString(
                      targetContributionPension?.pension_start_at_age,
                    )}歳から${defaultToEmptyString(
                      targetContributionPension?.pension_years,
                    )}年間`}
                  </Typography>
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
    )
  }

  return (
    <MyDataListWrapper
      title="個人型確定拠出年金"
      description="給与明細が必要です。ご用意ください。"
    >
      {renderTable(true)}
      {renderTable(false)}
    </MyDataListWrapper>
  )
}

export default MyDataPersonContributionPension
