import React, { FC, useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { find as _find, isNull as _isNull } from 'lodash'
import Modal from '@/components/v2/atoms/Modal'
import Typography from '@/components/v2/atoms/Typography'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/store'

import ChevronRightIcon from '@/assets/images/v2/chevron-right.svg'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import Divider from '@/components/v2/atoms/Divider'
import Grid from '@/components/v2/atoms/Grid'

import NumberField from '@/components/v2/molecules/NumberField'
import SelectBox from '@/components/v2/molecules/SelectBox'
import {
  ADVANCE_MODE_SELECT_ITEMS,
  REPAYMENT_MODE_SELECT_ITEMS,
} from '@/constants/formItem/futures/futureLoanAdvances'
import {
  createV2FutureLoanAdvance,
  updateFutureLoanAdvanceV2,
} from '@/containers/futures/futureLoanAdvancesSlice'
import { convertDateYear, extractYearOrMonthFromDate } from '@/models/commonsModelFunc'
import { buildRangeAgeSelectItems } from '@/utils/formItem'
import {
  composeValidators,
  commaNumberMaxLength,
  required,
  zeroOrMoreNumber,
} from '@/utils/validate'
import { TWO_TYPES_LOAN_YEAR_SELECT_ITEMS } from '@/constants/formItem/commons'
import { SelectItem } from '@/components/v1/molecules/SelectBox'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import { fetchAccountFutureItems } from '@/containers/accountsSlice'
import SubmitErrorMessage from '@/components/v2/molecules/SubmitErrorMessage'
import clsx from 'clsx'
import { FutureLoanAdvance } from '@/models/futures/futureLoanAdvancesModel'

interface FutureLoanAdvanceModalFormProps {
  /** true: オープン, false: クローズ */
  isOpen: boolean
  /** onCloseメソッド */
  onClose: () => void
  /** onCloseメソッド */
  isEdit: boolean
  /** 編集時のID */
  id: number | null
  /* FutureItemsを参照するID */
  futureBaseId?: number
}

const FutureLoanAdvanceModalForm: FC<FutureLoanAdvanceModalFormProps> = (props) => {
  const dispatch = useDispatch()
  const { showFlashMsg } = useFlashAlert()

  const { isOpen, onClose, id, isEdit } = props

  const futureLoanAdvances = useSelector(
    (state: RootState) => state.accounts.futureItems?.future_loan_advances,
  )

  const [yearRange, setYearRange] = useState<{ start: string; end: string }>({
    start: '',
    end: '',
  })

  const targetLoanAdvance: FutureLoanAdvance | undefined = useMemo(
    () => (!_isNull(id) ? _find(futureLoanAdvances, { id }) : undefined),
    [futureLoanAdvances, id],
  )

  const { applicableLoans } = useSelector((state: RootState) => state.futureBases)
  const loanSelectItems: SelectItem[] = useMemo(() => {
    return applicableLoans.map((option) => ({
      value: `${option.loan_type}-${option.loan_id}-${option.loan_start_year}-${option.loan_end_year}`,
      label: option.display_name,
    }))
  }, [applicableLoans])

  const handleChangeLoanOption = (value: string) => {
    const start = value?.split('-')[2] || ''
    const end = value?.split('-')[3] || ''
    setYearRange({ start, end })
  }

  const handleCreate = (values: any) => {
    const loanType = values.loan?.split('-')[0]
    const loanId = values.loan?.split('-')[1]

    const newValues = {
      account: {
        future_loan_advances_attributes: [
          {
            ...values.future_loan_advance,
            loan_type: loanType,
            loan_id: Number(loanId),
            repayment_date: convertDateYear(values.future_loan_advance.repayment_year),
          },
        ],
      },
    }

    return new Promise(() =>
      dispatch(
        createV2FutureLoanAdvance(newValues, () => {
          dispatch(fetchAccountFutureItems(props.futureBaseId))
          showFlashMsg(isEdit ? '更新しました' : '作成しました')
          onClose()
        }),
      ),
    )
  }

  const handleUpdate = (values: any) => {
    const newValues = {
      id,
      future_loan_advance: {},
    }

    newValues.future_loan_advance = {
      ...newValues.future_loan_advance,
      ...values.future_loan_advance,
      repayment_date: convertDateYear(values.future_loan_advance.repayment_year),
    }

    dispatch(
      updateFutureLoanAdvanceV2(newValues, id as any, () => {
        dispatch(fetchAccountFutureItems(props.futureBaseId))
        showFlashMsg(isEdit ? '更新しました' : '作成しました')
        onClose()
      }),
    )
  }

  const handleSubmit = (values: any) => {
    return isEdit ? handleUpdate(values) : handleCreate(values)
  }

  const initialValues = useMemo(() => {
    return isEdit
      ? {
          loan: _find(
            loanSelectItems,
            (item) => Number(item?.value?.split('-')[1]) === targetLoanAdvance?.loan_id,
          )?.value,
          future_loan_advance: {
            ...targetLoanAdvance,
            repayment_year: extractYearOrMonthFromDate(
              targetLoanAdvance?.repayment_date,
              'year',
            ),
            loan: _find(
              loanSelectItems,
              (item) => Number(item?.value?.split('-')[1]) === targetLoanAdvance?.loan_id,
            )?.value,
          },
        }
      : { future_loan_advance: { repayment_mode: null, advance_mode: null } }
  }, [targetLoanAdvance, isEdit, loanSelectItems])

  useEffect(() => {
    handleChangeLoanOption(
      _find(
        loanSelectItems,
        (item) => Number(item?.value?.split('-')[1]) === targetLoanAdvance?.loan_id,
      )?.value,
    )
  }, [targetLoanAdvance, initialValues])

  return (
    <Modal
      className="w-[95%] xl:w-[1000px]"
      isOpen={isOpen}
      isDisableBackdropClick
      onClose={onClose}
    >
      <CircleTitle
        className="pb-[32px]"
        title="繰上げ返済予定を設定する"
        classNameTitle="text-[19px] md:text-[24px]"
      />
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, values, errors, submitErrors, submitFailed }) => (
          <form onSubmit={handleSubmit}>
            <Grid className="gap-x-40 gap-y-16 grid-cols-1 xl:grid-cols-2 pb-24 md:pb-40">
              <div className="col-span-1 xl:col-span-2">
                <div className="md:flex pb-16 justify-between">
                  <div className="flex items-center min-w-140 lg:min-w-164 pb-10 md:pb-0">
                    <Typography
                      className="pr-16 text-14 text-black-800 min-w-[max-content]"
                      isBold
                    >
                      返済対象のローン
                    </Typography>
                  </div>
                  <div className="justify-end">
                    <SelectBox
                      name="loan"
                      placeholder="選択してください"
                      options={loanSelectItems}
                      validate={required}
                      isFullWidth
                      onChange={(event: any) =>
                        handleChangeLoanOption(event.target.value)
                      }
                    />
                  </div>
                </div>
                <Divider className="border-black-300 border-dashed border-t-2" />
              </div>
            </Grid>

            <Grid className="gap-x-40 gap-y-16 grid-cols-1 xl:grid-cols-2 pb-24 md:pb-40">
              <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                <Typography
                  className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                  isBold
                >
                  返済方法
                </Typography>

                <SelectBox
                  name="future_loan_advance.repayment_mode"
                  placeholder="選択してください"
                  validate={required}
                  options={REPAYMENT_MODE_SELECT_ITEMS}
                  isFullWidth
                />
              </div>

              <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                <Typography
                  className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                  isBold
                >
                  繰上げ返済の頻度
                </Typography>

                <SelectBox
                  name="future_loan_advance.advance_mode"
                  placeholder="選択してください"
                  validate={required}
                  options={ADVANCE_MODE_SELECT_ITEMS}
                  isFullWidth
                />
              </div>

              <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                <Typography
                  className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                  isBold
                >
                  予定年月
                </Typography>

                <SelectBox
                  name="future_loan_advance.repayment_year"
                  placeholder="選択してください"
                  validate={required}
                  options={buildRangeAgeSelectItems(
                    Number(yearRange.start),
                    Number(yearRange.end),
                    TWO_TYPES_LOAN_YEAR_SELECT_ITEMS,
                  )}
                  isFullWidth
                />
              </div>

              <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                <Typography
                  className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                  isBold
                >
                  繰上げ返済額
                </Typography>
                <div className="flex flex-auto items-baseline">
                  <NumberField
                    isFullWidth
                    name="future_loan_advance.repayment_amount_manyen"
                    validate={composeValidators(
                      required,
                      zeroOrMoreNumber,
                      (value: any) => commaNumberMaxLength(value, 10),
                    )}
                  />
                  <Typography
                    className="min-w-[fit-content] pl-[8px] text-[14px] text-black-800"
                    isBold
                  >
                    万円
                  </Typography>
                </div>
              </div>
            </Grid>
            <SubmitErrorMessage show={!submitErrors && submitFailed} />
            <div className="pb-[32px]">
              <ButtonPrimary
                className={clsx(
                  { ['opacity-50']: Object.keys(errors).length > 0 },
                  'w-[fit-content] md:w-[246px] h-[64px] mx-[auto]',
                )}
                onClick={() => handleSubmit(values)}
              >
                <Typography
                  className="relative bottom-[2px] pl-[30px] text-[16px] text-white flex-auto"
                  isBold
                >
                  保存する
                </Typography>
                <ChevronRightIcon className="mr-[17px]" />
              </ButtonPrimary>
            </div>
          </form>
        )}
      />
    </Modal>
  )
}

export default FutureLoanAdvanceModalForm
