import React, { FC } from 'react'
import CircleTitle from '@/components/v2/molecules/CircleTitle'

const BuildingLoanSeparator: FC = () => {
  return (
    <>
      <CircleTitle className="pb-[32px]" title="建物用のローンについて" />
    </>
  )
}

export default BuildingLoanSeparator
