import ApiClient from '../apiClient'
import {
  FutureCarCreateValues,
  FutureCarDestroyValues,
  FutureCarUpdateValues,
} from '../../models/futures/futureCarsModel'

export const postFutureCarV2 = async (values: FutureCarCreateValues) => {
  return await ApiClient.post('/future_cars', () => {}, values, undefined, 'v2')
}

export const patchFutureCarV2 = async (values: FutureCarUpdateValues, id: number) => {
  return await ApiClient.patch(`/future_cars/${id}`, () => {}, values, undefined, 'v2')
}

export const deleteFutureCarV2 = async (values: FutureCarDestroyValues, id: number) => {
  return await ApiClient.delete(`/future_cars/${id}`, () => {}, values, undefined, 'v2')
}
