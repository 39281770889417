import React, { FC, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  split as _split,
  includes as _includes,
  filter as _filter,
  flatten as _flatten,
} from 'lodash'
import Chip from '@/components/v2/atoms/Chip'
import Typography from '@/components/v2/atoms/Typography'
import { convertLocaleString, castNumberWithoutOperator } from '@/models/commonsModelFunc'
import {
  convertCategory,
  convertFrequency,
  CUSTOM_ITEM_CATEGORY,
  CUSTOM_ITEM_EXPENSE_GROUP,
  CUSTOM_ITEM_INCOME_GROUP,
  PAYMENT_MODE,
} from '@/models/inputs/inputCustomItemsModelFunc'
import { RootState } from '@/store'
import SimulationAccordion from '@/templates/v2/simulations/_simulationAccordion'
import Modal from '@/components/v2/atoms/Modal'
import Button from '@/components/v2/atoms/Button'
import SimulationNewButton from '@/templates/v2/simulations/_simulationNewButton'
import MyDataCommonItemForm from '@/templates/v2/myData/customItems/Form'
import CircleTitle from '@/components/v2/molecules/CircleTitle'

export const CustomIncomeTable: FC<{ onUpdate?: () => void }> = (props) => {
  const { inputCustomItems } = useSelector((state: RootState) => state.inputCustomItems)

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const closeModal = () => setIsOpenModal(false)
  const openModal = () => setIsOpenModal(true)

  const inputCustomIncomeItems = useMemo(
    () =>
      _flatten(
        CUSTOM_ITEM_INCOME_GROUP.map((category: any) =>
          _filter(inputCustomItems, { category, is_active: true }),
        ),
      ),
    [inputCustomItems],
  )

  const inputCustomExpenseItems = useMemo(
    () =>
      _flatten(
        CUSTOM_ITEM_EXPENSE_GROUP.map((category: any) =>
          _filter(inputCustomItems, { category, is_active: true }),
        ),
      ),
    [inputCustomItems],
  )
  return (
    <>
      <div className="flex justify-end mb-[8px]">
        <Button onClick={openModal}>
          <i className="ico edit text-[14px] text-black-700" />
          <Typography className="pl-[5px] text-[14px] text-black-700">編集</Typography>
        </Button>
      </div>
      {inputCustomIncomeItems.length > 0 && (
        <div>
          <Typography className="pb-[5px]" isBold>
            収入
          </Typography>
          <table className="w-[100%] text-[12px] md:text-[14px]">
            <tbody>
              {inputCustomIncomeItems.map((item: any) => (
                <tr className="h-[45px] " key={`custom_items_${item.id}`}>
                  <td className="w-[100%] px-[16px] block odd:bg-black-100">
                    <div className="flex">
                      <Typography isBold className="block flex-1 leading-45">
                        項目名
                      </Typography>
                      <Typography className="flex-1 leading-45">
                        {item?.display_name || ''}
                      </Typography>
                    </div>
                  </td>
                  <td className="w-[100%] px-[16px] block odd:bg-black-100">
                    <div className="flex items-center">
                      <Typography isBold className="block flex-1 leading-45">
                        科目
                      </Typography>
                      <div className="flex-1">
                        <Chip className="bg-tertiary-500 pb-2 text-white text-[10px] max-h-[18px]">
                          {convertCategory(
                            _includes(item?.category, CUSTOM_ITEM_CATEGORY.tuition)
                              ? _split(item?.category, '_')[0]
                              : item?.category,
                            _includes(item?.category, CUSTOM_ITEM_CATEGORY.tuition)
                              ? Number(_split(item?.category, '_')[1])
                              : undefined,
                          )}
                        </Chip>
                      </div>
                    </div>
                  </td>
                  <td className="w-[100%] px-[16px] block odd:bg-black-100">
                    <div className="flex">
                      <Typography isBold className="block flex-1 leading-45">
                        適用時期
                      </Typography>
                      <Typography className="flex-1 leading-[30px]">
                        {convertFrequency(item)
                          .split(' ')
                          .map((splitedItem) => (
                            <Typography>{splitedItem}</Typography>
                          ))}
                      </Typography>
                    </div>
                  </td>
                  <td className="w-[100%] px-[16px] block odd:bg-black-100">
                    <div className="flex">
                      <Typography isBold className="block flex-1 leading-45">
                        金額
                      </Typography>
                      <Typography className="flex-1 leading-45 md:leading-normal">
                        {`${convertLocaleString(
                          castNumberWithoutOperator(item?.cost),
                        )}円/年`}
                      </Typography>
                    </div>
                  </td>
                  <td className="w-[100%] px-[16px] block odd:bg-black-100">
                    <div className="flex">
                      <Typography isBold className="block flex-1 leading-45">
                        年間変動率
                      </Typography>
                      <Typography className="flex-1 leading-45">
                        {item?.payment_mode === PAYMENT_MODE.repeatedly
                          ? `${convertLocaleString(
                              castNumberWithoutOperator(item?.changed_rate_percent),
                            )}％`
                          : '-％'}
                      </Typography>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {inputCustomExpenseItems.length > 0 && (
        <div className="mt-30">
          <Typography className="pb-[5px]" isBold>
            支出
          </Typography>
          <table className="w-[100%] text-[12px] md:text-[14px]">
            <tbody>
              {inputCustomExpenseItems.map((item: any) => (
                <tr className="h-[45px]" key={`custom_items_${item.id}`}>
                  <td className="w-[100%] px-[16px] block odd:bg-black-100">
                    <div className="flex">
                      <Typography isBold className="block flex-1 leading-45">
                        項目名
                      </Typography>
                      <Typography className="flex-1 leading-45">
                        {item?.display_name || ''}
                      </Typography>
                    </div>
                  </td>

                  <td className="w-[100%] px-[16px] block odd:bg-black-100 ">
                    <div className="flex items-center">
                      <Typography isBold className="block flex-1 leading-45">
                        科目
                      </Typography>
                      <div className="flex-1">
                        <Chip className="bg-purple-500 pb-2 text-white text-[10px] max-h-[18px]">
                          {convertCategory(
                            _includes(item?.category, CUSTOM_ITEM_CATEGORY.tuition)
                              ? _split(item?.category, '_')[0]
                              : item?.category,
                            _includes(item?.category, CUSTOM_ITEM_CATEGORY.tuition)
                              ? Number(_split(item?.category, '_')[1])
                              : undefined,
                          )}
                        </Chip>
                      </div>
                    </div>
                  </td>
                  <td className="w-[100%] px-[16px] block odd:bg-black-100">
                    <div className="flex">
                      <Typography isBold className="block flex-1 leading-45">
                        適用時期
                      </Typography>
                      <Typography className="flex-1 leading-[30px]">
                        {convertFrequency(item)
                          .split(' ')
                          .map((splitedItem) => (
                            <Typography>{splitedItem}</Typography>
                          ))}
                      </Typography>
                    </div>
                  </td>
                  <td className="w-[100%] px-[16px] block odd:bg-black-100">
                    <div className="flex">
                      <Typography isBold className="block flex-1 leading-45">
                        金額
                      </Typography>
                      <Typography className="flex-1 leading-45">
                        {`${convertLocaleString(
                          castNumberWithoutOperator(item?.cost),
                        )}円/年`}
                      </Typography>
                    </div>
                  </td>
                  <td className="w-[100%] px-[16px] block odd:bg-black-100">
                    <div className="flex">
                      <Typography isBold className="block flex-1 leading-45">
                        年間変動率
                      </Typography>
                      <Typography className="flex-1 leading-45">
                        {item?.payment_mode === PAYMENT_MODE.repeatedly
                          ? `${convertLocaleString(
                              castNumberWithoutOperator(item?.changed_rate_percent),
                            )}％`
                          : '-％'}
                      </Typography>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {inputCustomIncomeItems.length === 0 && inputCustomExpenseItems.length == 0 && (
        <>
          <Typography className="text-[14px] md:text-[16px] text-center" isBold>
            カスタム設定が登録されていません。
          </Typography>
          <SimulationNewButton onClick={openModal} />
        </>
      )}

      <Modal className="lg:w-[1024px] w-[95%]" isOpen={isOpenModal} onClose={closeModal}>
        <MyDataCommonItemForm
          onClose={closeModal}
          onSubmit={() => {
            closeModal()
            if (props.onUpdate != null) props.onUpdate()
          }}
        />
      </Modal>
    </>
  )
}

const InputCustomItemBody: FC<{ onUpdate?: () => void }> = (props) => {
  return (
    <SimulationAccordion className="pb-[16px]" title="カスタム設定">
      <CustomIncomeTable onUpdate={props.onUpdate} />
    </SimulationAccordion>
  )
}

export default InputCustomItemBody
