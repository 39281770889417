import { find as _find } from 'lodash'
import { defaultToEmptyString } from '../commonsModelFunc'
import { ReceivingPensionType } from './inputContributionPensionsModel'

// 確定拠出年金種別
export const CONTRIBUTION_TYPE = {
  corporate: 'corporate',
  ideco: 'ideco',
}

// 受取方式
export const RECEIVING_PENSION_TYPE = {
  pension: 'pension',
  both: 'both',
  lump: 'lump',
}

// 拠出分種別
export const COMPANY_CONTRIBUTION_TYPE = {
  amount: 'amount',
  percent: 'percent',
}

// マッチング拠出種別
export const MATCHING_CONTRIBUTION_TYPE = {
  matching: 'matching',
  noMatching: 'no_matching',
}

import { RECEIVING_PENSION_TYPE_SELECT_ITEMS } from '@/constants/formItem/inputs/inputContributionPensions'

export const convertReceivingPensionType = (
  receivingPensionType: ReceivingPensionType,
) => {
  const label = _find(
    RECEIVING_PENSION_TYPE_SELECT_ITEMS,
    (item) => item.value === receivingPensionType,
  )?.label

  return defaultToEmptyString(label)
}

export const convertAverageYieldPercent = (averageYieldPercent: number) => {
  const tempNumber = averageYieldPercent * 10

  return Math.round(tempNumber) / 10
}
