import React from 'react'
import { Area } from 'recharts'

interface RenderCustomAreaProps {
  /** 名前 */
  name: string
  /** データキー */
  dataKey: string
  /** スタックID */
  stackId?: string
  /** カラー */
  color: string
}

const renderCustomArea = (props: RenderCustomAreaProps) => {
  const { name, dataKey, stackId, color } = props

  return (
    <Area
      name={name}
      dataKey={dataKey}
      stackId={stackId}
      fill={color}
      activeDot={false}
    />
  )
}

export default renderCustomArea
