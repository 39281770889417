import React, { FC, ChangeEvent } from 'react'
import { Field } from 'react-final-form'
import clsx from 'clsx'

interface CheckboxProps {
  /** 名前 */
  name: string
  /** 値 */
  value?: any
  /** ラベル名 */
  label: string | JSX.Element
  /** クラス名 */
  className?: string
  /** チェックボックスクラス名 */
  checkboxClassName?: string
  /** onChangeメソッド */
  onChange?: (event: ChangeEvent) => void
}

const Checkbox: FC<CheckboxProps> = (props) => {
  const { name, value, label, className, checkboxClassName, onChange } = props

  return (
    <Field type="checkbox" name={name} value={value}>
      {(props) => {
        const { input } = props

        const handleChange = (event: ChangeEvent) => {
          input.onChange(event)
          onChange?.(event)
        }

        return (
          <label className={clsx(className, 'flex items-center')}>
            <input {...input} className={checkboxClassName} onChange={handleChange} />
            <span className="pl-[5px]">{label}</span>
          </label>
        )
      }}
    </Field>
  )
}

export default Checkbox
