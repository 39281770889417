import {
  every as _every,
  filter as _filter,
  isEmpty as _isEmpty,
  isNull as _isNull,
  isUndefined as _isUndefined,
  pullAt as _pullAt,
} from 'lodash'
import {
  API_BOOLEAN_FLAG,
  castNumber,
  castNumberWithoutOperator,
  extractYearOrMonthFromDate,
  isNotEmptyValue,
  THIS_YEAR_NUMBER,
} from '@/models/commonsModelFunc'
import { HOUSE_TYPE } from '@/models/commonHousingModelFunc'
import { PERSON_FLG } from '@/models/familyStructuresModelFunc'
import { isFlatLoan } from '@/models/commonHousingModelFunc'
import { isSplitedLoan } from '@/models/futures/futureV2HousingSelfOwnedModelFunc'
import {
  isOneLoanPattern,
  isTwoLoanPattern,
} from '@/models/futures/futureV2HousingLoansModelFunc'
import { FutureV2Housing } from '@/models/futures/futureV2HousingsModel'
import { FutureV2HousingLoan } from '@/models/futures/futureV2HousingLoansModel'
import { FutureV2HousingSelfOwned } from '@/models/futures/futureV2HousingSelfOwnedModel'

// 終身の年齢
export const LAST_AGE = 120

export const HANDLING_TYPE = {
  sale: 'sale',
  lend: 'lend',
  rent: 'rent',
  none: 'none',
}

// 修繕積立上昇率の設定
export const REPAIR_RESERVE_FUND_RATE_TYPE = {
  change: 0,
  fixed: 1,
  noSet: 2,
}

// 返済方法
export const REPAYMENT_TYPE = {
  shortening: 0,
  reduction: 1,
}

// 賃貸/購入形態
export const NEXT_HOUSE_TYPE = {
  rent: 'rent',
  own: 'own',
  other: 'other',
}

// 賃貸/購入形態
export const PREV_HOUSING_TYPE = {
  rent: 'rent',
  own: 'own',
  other: 'other',
  another: 'another',
}

// 現在の住居の扱い: 戸建てからの登録の場合
export const CURRENT_HOUSE_HANDLING = {
  sale: 'sale',
  lend: 'lend',
  rentCancel: 'rentCancel',
}

export const isRentHouseType = (houseType: number | null | undefined) => {
  return houseType === HOUSE_TYPE.rent
}

export const isSelfOwnedHouseType = (houseType: number | null | undefined) => {
  return houseType === HOUSE_TYPE.own
}

export const isOtherHouseType = (houseType: number | null | undefined) => {
  return houseType === HOUSE_TYPE.other
}

export const getFutureV2HousingType = (houseType: number | null | undefined) => {
  switch (houseType) {
    case HOUSE_TYPE.rent:
      return 'rent'
    case HOUSE_TYPE.own:
      return 'own'
    case HOUSE_TYPE.other:
      return 'other'
    default:
      return 'another'
  }
}

const isUndefinedFutureV2HousingInitialValue = (
  futureV2Housing: Partial<FutureV2Housing>,
  name: 'other' | 'rents' | 'self_owned',
) => {
  switch (name) {
    case 'other':
      return _isUndefined(futureV2Housing.future_v2_housing_other)
    case 'rents':
      return (
        _isUndefined(futureV2Housing.future_v2_housing_rents) ||
        _isEmpty(futureV2Housing.future_v2_housing_rents)
      )
    case 'self_owned':
      return _isUndefined(futureV2Housing.future_v2_housing_self_owned)
    default:
      break
  }
  return true
}

const isUndefinedUnderFutureV2LoanObject = (
  futureLoans: Partial<FutureV2HousingLoan>[] | undefined,
  name:
    | 'future_v2_housing_loans'
    | 'future_v2_housing_loans[index]'
    | 'future_v2_housing_loan_calculation'
    | 'future_v2_housing_loan_calculation_rates',
  index?: number,
) => {
  if (_isUndefined(futureLoans)) return true

  switch (name) {
    case 'future_v2_housing_loans':
      return _isUndefined(futureLoans)
    case 'future_v2_housing_loans[index]':
      if (_isUndefined(index)) return true
      return _isUndefined(futureLoans[index]) || _isEmpty(futureLoans[index])
    case 'future_v2_housing_loan_calculation':
      if (_isUndefined(index)) return true
      return (
        _isUndefined(futureLoans[index]?.future_v2_housing_loan_calculation) ||
        _isNull(futureLoans[index]?.future_v2_housing_loan_calculation)
      )
    case 'future_v2_housing_loan_calculation_rates':
      if (_isUndefined(index)) return true
      return (
        _isUndefined(
          futureLoans[index]?.future_v2_housing_loan_calculation
            ?.future_v2_housing_loan_calculation_rates,
        ) ||
        _isEmpty(
          futureLoans[index]?.future_v2_housing_loan_calculation
            ?.future_v2_housing_loan_calculation_rates,
        )
      )
    default:
      true
      break
  }
  return true
}

export const convertHousingTitle = (
  isHome: boolean,
  isSelfOwned: boolean | undefined,
) => {
  if (_isUndefined(isSelfOwned)) return '単身赴任先'
  return !isHome ? '単身赴任先' : !isSelfOwned ? '賃貸・社宅' : '自宅'
}

export const convertLandOrBuildingLoanTitle = (
  index: number | null | undefined,
  futureSelfOwnedAttributes: Partial<FutureV2HousingSelfOwned> | undefined,
) => {
  if (!isNotEmptyValue(futureSelfOwnedAttributes?.future_v2_housing_loans)) return

  const { future_v2_housing_loans: futureLoans } = futureSelfOwnedAttributes!

  if (index === 0) return '土地用ローン'
  if (index === 1 && isOneLoanPattern(futureLoans![0])) return '建物用ローン'
  if (index === 2 && isTwoLoanPattern(futureLoans![1])) return '建物用ローン'

  return ''
}

export const convertHouseType = (houseType: number | null) => {
  if (houseType === HOUSE_TYPE.rent) return '賃貸・社宅'
  if (houseType === HOUSE_TYPE.own) return '自己保有'
  if (houseType === HOUSE_TYPE.other) return 'その他（実家・セカンドハウス）'

  return ''
}

export const calcRepaymentPeriodYearsInitialValue = (holderAge: number | null) => {
  const calcAge = 79 - castNumber(holderAge)
  return calcAge < 35 ? calcAge : 35
}

const futureV2LoanCalculationDefaultValues = (personFlg: number) => {
  return {
    person_flg: personFlg,
    repayment_rate: 2.475,
    future_v2_housing_loan_calculation_rates_attributes: [{}],
    future_v2_housing_loan_calculation_rates_other_attributes: [{}],
  }
}

const futureV2LoanDefaultValues = (personFlg: number) => {
  return {
    future_v2_housing_loan_calculation_attributes: {
      ...futureV2LoanCalculationDefaultValues(personFlg),
    },
  }
}

const futureV2HousingSelfOwnedDefaultValues = {
  is_management_and_fund_total: API_BOOLEAN_FLAG.off,
  repair_reserve_fund_increase_start: 5,
  repair_reserve_fund_increase_span: 5,
  repair_reserve_fund_increase_finish: 20,
  repair_reserve_fund_increase_percent: 20,
  future_v2_housing_loans_attributes: [
    { ...futureV2LoanDefaultValues(PERSON_FLG.person) },
    { ...futureV2LoanDefaultValues(PERSON_FLG.spouse) },
    { ...futureV2LoanDefaultValues(PERSON_FLG.person) },
    { ...futureV2LoanDefaultValues(PERSON_FLG.spouse) },
  ],
}

const buildFutureV2LoanCalculationInitialValues = (
  futureLoans: Partial<FutureV2HousingLoan>[],
  loanIndex: number,
  personFlg: number,
) => {
  const futureLoan = futureLoans[loanIndex]

  return {
    ...futureLoan.future_v2_housing_loan_calculation,
    bonus_flg: String(futureLoan.future_v2_housing_loan_calculation?.bonus_flg),
    life_insurance: _isNull(futureLoan.future_v2_housing_loan_calculation?.life_insurance)
      ? null
      : String(futureLoan.future_v2_housing_loan_calculation?.life_insurance),
    married_couple_group_shin: _isNull(
      futureLoan.future_v2_housing_loan_calculation?.married_couple_group_shin,
    )
      ? null
      : String(futureLoan.future_v2_housing_loan_calculation?.married_couple_group_shin),
    loan_deduction: String(futureLoan.future_v2_housing_loan_calculation?.loan_deduction),
    person_flg: personFlg,
    future_v2_housing_loan_calculation_rates_attributes: !isUndefinedUnderFutureV2LoanObject(
      futureLoans,
      'future_v2_housing_loan_calculation_rates',
      loanIndex,
    )
      ? [
          ..._filter(
            futureLoan.future_v2_housing_loan_calculation!
              .future_v2_housing_loan_calculation_rates!,
            (item) => _isNull(item.rate_start_at),
          ),
        ]
      : [{}],
    future_v2_housing_loan_calculation_rates_other_attributes:
      !isUndefinedUnderFutureV2LoanObject(
        futureLoans,
        'future_v2_housing_loan_calculation_rates',
        loanIndex,
      ) &&
      !_every(
        futureLoan.future_v2_housing_loan_calculation!
          .future_v2_housing_loan_calculation_rates!,
        (item) => _isNull(item.rate_start_at),
      )
        ? [
            ..._filter(
              futureLoan.future_v2_housing_loan_calculation!
                .future_v2_housing_loan_calculation_rates!,
              (item) => !_isNull(item.rate_start_at),
            ).map((item) => {
              return {
                ...item,
                rate_start_at_year: extractYearOrMonthFromDate(
                  item.rate_start_at,
                  'year',
                ),
              }
            }),
          ]
        : [{}],
  }
}

const buildFutureV2HousingLoanInitialValues = (
  futureLoans: Partial<FutureV2HousingLoan>[],
  loanIndex: number,
  personFlg: number,
) => {
  const futureLoan = futureLoans[loanIndex]

  return {
    ...futureLoan,
    loan_lender: String(futureLoan.loan_lender),
    repayment_start_at_year: extractYearOrMonthFromDate(
      futureLoan.repayment_start_at,
      'year',
    ),
    repayment_start_at_month: extractYearOrMonthFromDate(
      futureLoan.repayment_start_at,
      'month',
    ),
    future_v2_housing_loan_calculation_attributes: !isUndefinedUnderFutureV2LoanObject(
      futureLoans,
      'future_v2_housing_loan_calculation',
      loanIndex,
    )
      ? {
          ...buildFutureV2LoanCalculationInitialValues(futureLoans, loanIndex, personFlg),
        }
      : { ...futureV2LoanCalculationDefaultValues(personFlg) },
  }
}

// 新規作成時-初期値データ
export const createInitialValues = {
  future_v2_housing: {
    future_v2_housing_other_attributes: {
      future_v2_housing_other_costs_attributes: [{}],
    },
    future_v2_housing_rents_attributes: [{}],
    future_v2_housing_self_owned_attributes: {
      is_management_and_fund_total: API_BOOLEAN_FLAG.off,
      repair_reserve_fund_increase_start: 5,
      repair_reserve_fund_increase_span: 5,
      repair_reserve_fund_increase_finish: 20,
      repair_reserve_fund_increase_percent: 20,
      future_v2_housing_loans_attributes: [
        { ...futureV2LoanDefaultValues(PERSON_FLG.person) },
        { ...futureV2LoanDefaultValues(PERSON_FLG.spouse) },
        { ...futureV2LoanDefaultValues(PERSON_FLG.person) },
        { ...futureV2LoanDefaultValues(PERSON_FLG.spouse) },
      ],
    },
  },
}

const buildFutureV2HousingSelfOwnedInitialValues = (
  selfOwned: Partial<FutureV2HousingSelfOwned>,
  futureLoans: Partial<FutureV2HousingLoan>[],
) => {
  return {
    ...selfOwned,
    is_management_and_fund_total: !_isNull(
      selfOwned?.management_fee_monthly_and_repair_reserve_fund_total,
    )
      ? API_BOOLEAN_FLAG.on
      : API_BOOLEAN_FLAG.off,
    repair_reserve_fund_amount_start: _isNull(selfOwned?.repair_reserve_fund_amount_start)
      ? 5
      : selfOwned?.repair_reserve_fund_amount_start,

    repair_reserve_fund_increase_start: _isNull(
      selfOwned?.repair_reserve_fund_increase_start,
    )
      ? 5
      : selfOwned?.repair_reserve_fund_increase_start,

    repair_reserve_fund_increase_span: _isNull(
      selfOwned?.repair_reserve_fund_increase_span,
    )
      ? 5
      : selfOwned?.repair_reserve_fund_increase_span,

    repair_reserve_fund_increase_finish: _isNull(
      selfOwned?.repair_reserve_fund_increase_finish,
    )
      ? 20
      : selfOwned?.repair_reserve_fund_increase_finish,

    repair_reserve_fund_increase_percent: _isNull(
      selfOwned?.repair_reserve_fund_increase_percent,
    )
      ? 20
      : selfOwned?.repair_reserve_fund_increase_percent,

    constructed_at_year: extractYearOrMonthFromDate(selfOwned!.constructed_at, 'year'),
    purchased_at_year: extractYearOrMonthFromDate(selfOwned!.purchased_at, 'year'),
    repair_costs_span: Number(selfOwned!.repair_costs_span),
    future_v2_housing_loans_attributes: !isUndefinedUnderFutureV2LoanObject(
      futureLoans,
      'future_v2_housing_loans',
    )
      ? [
          // 以下future_v2_housing_loans[0] 初期値
          !isUndefinedUnderFutureV2LoanObject(
            futureLoans,
            'future_v2_housing_loans[index]',
            0,
          )
            ? {
                ...buildFutureV2HousingLoanInitialValues(
                  futureLoans,
                  0,
                  PERSON_FLG.person,
                ),
              }
            : { ...futureV2LoanDefaultValues(PERSON_FLG.person) },

          // 以下future_v2_housing_loans[1] 初期値
          !isUndefinedUnderFutureV2LoanObject(
            futureLoans,
            'future_v2_housing_loans[index]',
            1,
          )
            ? {
                ...buildFutureV2HousingLoanInitialValues(
                  futureLoans,
                  1,
                  PERSON_FLG.spouse,
                ),
              }
            : { ...futureV2LoanDefaultValues(PERSON_FLG.spouse) },

          // 以下future_v2_housing_loans[2] 初期値
          !isUndefinedUnderFutureV2LoanObject(
            futureLoans,
            'future_v2_housing_loans[index]',
            2,
          )
            ? {
                ...buildFutureV2HousingLoanInitialValues(
                  futureLoans,
                  2,
                  PERSON_FLG.spouse,
                ),
              }
            : { ...futureV2LoanDefaultValues(PERSON_FLG.person) },

          // 以下future_v2_housing_loans[3] 初期値
          !isUndefinedUnderFutureV2LoanObject(
            futureLoans,
            'future_v2_housing_loans[index]',
            3,
          )
            ? {
                ...buildFutureV2HousingLoanInitialValues(
                  futureLoans,
                  3,
                  PERSON_FLG.spouse,
                ),
              }
            : { ...futureV2LoanDefaultValues(PERSON_FLG.spouse) },
        ]
      : // future_v2_housing_loans undefined
        [
          { ...futureV2LoanDefaultValues(PERSON_FLG.person) },
          { ...futureV2LoanDefaultValues(PERSON_FLG.spouse) },
          { ...futureV2LoanDefaultValues(PERSON_FLG.person) },
          { ...futureV2LoanDefaultValues(PERSON_FLG.spouse) },
        ],
  }
}

export const handleSetInitialValues = (futureV2Housing: FutureV2Housing | undefined) => {
  if (_isUndefined(futureV2Housing))
    return {
      ...createInitialValues,
      future_v2_housing: {
        ...createInitialValues.future_v2_housing,
        housing_id: 0,
      },
    }

  const {
    future_v2_housing_rents,
    future_v2_housing_other,
    future_v2_housing_self_owned,
  } = futureV2Housing

  const targetFutureV2HousingRent = future_v2_housing_rents?.[0]
  const calcStartedBorrowingTotalAmount = (
    futureLoans: Partial<FutureV2HousingLoan>[],
    isBuilding: boolean,
  ) => {
    if (_isEmpty(futureLoans)) return 0

    if (!isBuilding && isFlatLoan(futureLoans[0].loan_lender)) {
      return (
        castNumberWithoutOperator(
          futureLoans[0].future_v2_housing_loan_calculation
            ?.started_borrowing_amount_manyen,
        ) +
        castNumberWithoutOperator(
          futureLoans[1].future_v2_housing_loan_calculation
            ?.started_borrowing_amount_manyen,
        )
      )
    }

    if (futureLoans[2] && futureLoans[3] && isFlatLoan(futureLoans[2].loan_lender)) {
      return (
        castNumberWithoutOperator(
          futureLoans[2].future_v2_housing_loan_calculation
            ?.started_borrowing_amount_manyen,
        ) +
        castNumberWithoutOperator(
          futureLoans[3].future_v2_housing_loan_calculation
            ?.started_borrowing_amount_manyen,
        )
      )
    }

    return 0
  }

  // MEMO: ローン分割時のfuture_v2_housing_loansは以下構成で固定しているため、
  //       この構成に合わせる必要があるので、配列の順番を調節
  //  [0]: ローンあり全パターン
  //  [1]: 分割なし/あり・土地/建物・ペア本人/配偶者・フラット
  //  [2]: 分割あり・土地/建物・ペア本人/配偶者・フラット
  //  [3]: 分割あり・建物・ペア配偶者・フラット
  let reBuiltFutureV2HousingLoans = isUndefinedUnderFutureV2LoanObject(
    future_v2_housing_self_owned?.future_v2_housing_loans,
    'future_v2_housing_loans',
  )
    ? []
    : [...futureV2Housing.future_v2_housing_self_owned!.future_v2_housing_loans]

  if (
    !_isNull(future_v2_housing_self_owned) &&
    isSplitedLoan(future_v2_housing_self_owned)
  ) {
    const { future_v2_housing_loans } = future_v2_housing_self_owned

    if (
      future_v2_housing_loans.length === 2 &&
      isOneLoanPattern(future_v2_housing_loans[0]) &&
      isOneLoanPattern(future_v2_housing_loans[1])
    ) {
      reBuiltFutureV2HousingLoans[2] = [...future_v2_housing_loans][1]
      reBuiltFutureV2HousingLoans[1] = {}
    }

    if (
      future_v2_housing_loans.length === 3 &&
      !isFlatLoan(future_v2_housing_loans[0].loan_lender) &&
      isTwoLoanPattern(future_v2_housing_loans[0]) &&
      isOneLoanPattern(future_v2_housing_loans[2])
    ) {
      reBuiltFutureV2HousingLoans[3] = [
        ...future_v2_housing_self_owned.future_v2_housing_loans,
      ][2]
      reBuiltFutureV2HousingLoans[2] = [
        ...future_v2_housing_self_owned.future_v2_housing_loans,
      ][1]
      reBuiltFutureV2HousingLoans[1] = {}
    }
  }

  return {
    id: futureV2Housing.id,
    started_borrowing_total_amount_land_manyen: calcStartedBorrowingTotalAmount(
      reBuiltFutureV2HousingLoans,
      false,
    ),
    started_borrowing_total_amount_building_manyen: calcStartedBorrowingTotalAmount(
      reBuiltFutureV2HousingLoans,
      true,
    ),
    prev_housing: !_isNull(futureV2Housing.prev_housing_id)
      ? `${futureV2Housing.prev_housing_type}-${futureV2Housing.prev_housing_id}`
      : null,
    future_v2_housing: {
      ...futureV2Housing,
      future_v2_housing_id: futureV2Housing.id,
      scheduled_at: extractYearOrMonthFromDate(futureV2Housing.scheduled_at, 'year'),
      future_v2_housing_other_attributes:
        !isUndefinedFutureV2HousingInitialValue(futureV2Housing, 'other') &&
        !_isNull(future_v2_housing_other)
          ? {
              ...future_v2_housing_other,
              future_v2_housing_other_costs_attributes: !_isEmpty(
                future_v2_housing_other.future_v2_housing_other_costs,
              )
                ? [...future_v2_housing_other.future_v2_housing_other_costs]
                : [{}],
            }
          : {
              future_v2_housing_other_costs_attributes: [{}],
            },
      future_v2_housing_rents_attributes:
        !_isNull(targetFutureV2HousingRent) && !_isUndefined(targetFutureV2HousingRent)
          ? [...future_v2_housing_rents!]
          : [{}],
      future_v2_housing_self_owned_attributes:
        !isUndefinedFutureV2HousingInitialValue(futureV2Housing, 'self_owned') &&
        !_isNull(future_v2_housing_self_owned)
          ? {
              ...buildFutureV2HousingSelfOwnedInitialValues(
                future_v2_housing_self_owned,
                reBuiltFutureV2HousingLoans,
              ),
            }
          : // future_v2_housing_self_owned undefined
            { ...futureV2HousingSelfOwnedDefaultValues },
    },
  }
}
