import { API_BOOLEAN_FLAG } from '@/constants/formItem/commons'

// 給与グレード設定_役職
export const ROLE = {
  manager: 'manager',
  nonManager: 'non_manager',
}

export const ROLE_RADIO_ITEMS = [
  { label: '管理職', value: ROLE.manager },
  { label: '非管理職', value: ROLE.nonManager },
]

// 給与グレード設定_賞与
export const BONUS_MONTHS_TYPE = {
  averageFiveYears: 'average_five_years',
  input: 'input',
}

// 給与グレード設定_昇給設定
export const INCREASING_SETTING_TYPE = {
  rate: 'rate',
  amount: 'amount',
}

// 退職金の設定方法
export const COMPANY_SALARY_SEVERANCE_SYSTEM_TYPE = {
  point: 'point',
  income: 'income',
}

export const COMPANY_SALARY_SEVERANCE_SYSTEM_TYPE_RADIO_ITEMS = [
  { label: 'ポイント制', value: COMPANY_SALARY_SEVERANCE_SYSTEM_TYPE.point },
  { label: '退職金算定給与', value: COMPANY_SALARY_SEVERANCE_SYSTEM_TYPE.income },
]

// ポイント設定_退職金算定給与からの移行有無
export const IS_SYSTEM_MIGRATED_RADIO_ITEMS = [
  { label: 'あり', value: API_BOOLEAN_FLAG.on },
  { label: 'なし', value: API_BOOLEAN_FLAG.off },
]

// 退職金算定給与_退職金算定基礎給の設定
export const BASE_INCOME_TYPE = {
  basicIncome: 'basic_income',
  allIncome: 'all_income',
  other: 'other',
}

export const BASE_INCOME_TYPE_SELECT_ITEMS = [
  { label: '基本給のみ', value: BASE_INCOME_TYPE.basicIncome },
  { label: '基本給＋その他手当を含む総収入', value: BASE_INCOME_TYPE.allIncome },
  { label: 'その他', value: BASE_INCOME_TYPE.other },
]

// 退職理由係数の設定方法
export const COMPANY_SALARY_SEVERANCE_WEIGHTING_FACTOR_TYPE = {
  byAge: 'by_age',
  byWorkingYears: 'by_working_years',
}

export const COMPANY_SALARY_SEVERANCE_WEIGHTING_FACTOR_TYPE_SELECT_ITEMS = [
  {
    label: '年齢ごとに設定',
    value: COMPANY_SALARY_SEVERANCE_WEIGHTING_FACTOR_TYPE.byAge,
  },
  {
    label: '勤続年数ごとに設定',
    value: COMPANY_SALARY_SEVERANCE_WEIGHTING_FACTOR_TYPE.byWorkingYears,
  },
]
