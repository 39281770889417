import React, { FC } from 'react'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import Modal from '@/components/v2/atoms/Modal'
import Typography from '@/components/v2/atoms/Typography'

type MoviesAlertModalProps = {
  /** モーダルの開閉フラグ */
  isOpen: boolean
  /** モーダル閉じる*/
  onClose: () => void
}

const MoviesAlertModal: FC<MoviesAlertModalProps> = ({ isOpen, onClose }) => {
  return (
    <>
      <Modal
        className="w-[95%] xl:w-[530px] cursor-default"
        isOpen={isOpen}
        isDisableBackdropClick
        onClose={onClose}
      >
        <Typography className="text-16 mb-2.5 text-black-800 font-default">
          デモアカウントでは再生できません。
        </Typography>
        <Typography className="text-16 mb-2.5 text-black-800 font-default">
          閲覧するには有料プランのご契約いただく必要がございます。
        </Typography>

        <ButtonPrimary
          className="h-40 md:h-50 w-full md:w-215 px-17 mt-[30px] mx-auto"
          onClick={onClose}
        >
          <Typography className="relative flex-1 text-[14px] text-white" isBold>
            閉じる
          </Typography>
        </ButtonPrimary>
      </Modal>
    </>
  )
}

export default MoviesAlertModal
