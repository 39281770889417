import React, { FC } from 'react'
import { Switch, Route } from 'react-router-dom'
import AccountCompleted from '@/templates/v2/accounts/Completed'
import ScrollToTopOnInit from '@/components/v2/atoms/ScrollToTopOnInit'

const Registration: FC = () => (
  <>
    <ScrollToTopOnInit />
    <Switch>
      <Route exact path="/v2/dashboards" component={AccountCompleted} />
    </Switch>
  </>
)

export default Registration
