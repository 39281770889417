import React, { FC, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import clsx from 'clsx'
import WomanIcon from '@/assets/images/v2/woman.svg'
import Button from '@/components/v2/atoms/Button'
import Typography from '@/components/v2/atoms/Typography'
import { isEnableHeightScroll } from '@/utils/element'
import FPModalFormMoviePage from '../organisms/FPModalFormMoviePage'

interface FloatingFooterMoviePageProps {
}

const FloatingFooterMoviePage: FC<FloatingFooterMoviePageProps> = (props) => {
  const locaiton = useLocation()

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const [isShow, setIsShow] = useState<boolean>(false)

  const handleScroll = (e: Event) => {
    const target: any = e.target
    setIsShow(target.scrollTop > 10)
  }

  useEffect(() => {
    const jsContentElement = document.getElementById('js-content')

    const timer = setTimeout(() => {
      if (isEnableHeightScroll('js-content')) {
        setIsShow(false)
        jsContentElement?.addEventListener('scroll', handleScroll)
      } else {
        setIsShow(true)
        jsContentElement?.removeEventListener('scroll', handleScroll)
      }
    }, 50)

    return () => {
      clearTimeout(timer)
      jsContentElement?.removeEventListener('scroll', handleScroll)
    }
  }, [locaiton.pathname])

  return (
    <>
      <div
        className={clsx(
          'fixed bottom-0 flex justify-center items-center gap-x-[3px] md:gap-x-[24px] w-full h-[86px] z-[50] duration-300 visible bg-[rgba(0,0,0,0.18)]',
          { 'opacity-0 invisible': !isShow },
        )}
      >
        <Button
          className="w-[250px] h-[66px] rounded-[9px] text-tertiary-500 bg-tertiary-500 border border-tertiary-500 hover:bg-[#d9aa44]"
          onClick={() => setIsOpenModal(true)}
        >
          <div className="flex flex-1 items-center gap-x-[2px] pl-[4px]">
            <WomanIcon />
            <div className="text-center w-full">
              <Typography className="text-[13px] text-white text-bold">お金の疑問をプロに聞く</Typography>
              <Typography className="text-[21px] text-white text-bold">個別相談に申込む</Typography>
            </div>
          </div>
          <i className="ico chevron-right text-[20px] text-white" />
        </Button>
      </div>

      <FPModalFormMoviePage isOpen={isOpenModal} onClose={() => setIsOpenModal(false)} />
    </>
  )
}

export default FloatingFooterMoviePage
