import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { FormSpy } from 'react-final-form'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { isUndefined as _isUndefined, get as _get } from 'lodash'
import Typography from '@/components/v1/atoms/Typography'
import { VERY_PALE_GRAY_COLOR } from '@/constants/theme'
import { convertLocaleString, castNumberWithoutOperator } from '@/models/commonsModelFunc'
import { HousingLoan } from '@/models/housingLoansModel'
import { LOAN_TYPE } from '@/models/housingLoansModelFunc'
import { RootState } from '@/store'

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      paddingBottom: '10px',
      fontWeight: 'bold',
    },
    table: {
      marginBottom: '10px',
      width: '100%',
      borderCollapse: 'collapse',
      border: '1px solid',
    },
    tableRow: {
      border: '1px solid',
    },
    tableHeader: {
      border: '1px solid',
      backgroundColor: VERY_PALE_GRAY_COLOR,
    },
    tableCell: {
      padding: '10px',
      textAlign: 'center',
    },
  }),
)

interface HousingLoanRepaymentTableProps {
  /** 住宅ローンのValueKey */
  housingLoansFormKey: string
}

const HousingLoanRepaymentTable: FC<HousingLoanRepaymentTableProps> = (props) => {
  const classes = useStyles()

  const { housingLoansFormKey } = props

  const { housingLoanRepayment } = useSelector((state: RootState) => state.housingLoans)
  return (
    <FormSpy>
      {({ values }) => {
        const housingLoans: Partial<Omit<
          HousingLoan,
          'id' | 'base_type' | 'base_id'
        >> = _get(values, housingLoansFormKey)
        const loanType = housingLoans?.loan_type ?? undefined

        return (
          <>
            <Typography className={classes.title}>月々のお支払い額</Typography>
            {!_isUndefined(loanType) && (
              <table className={classes.table}>
                <tbody>
                  <tr className={classes.tableHeader}>
                    <th className={classes.tableCell} />
                    <th className={classes.tableCell}>
                      <Typography className="u-bold">月間</Typography>
                    </th>
                    <th className={classes.tableCell}>
                      <Typography className="u-bold">年間</Typography>
                    </th>
                  </tr>
                  {loanType === LOAN_TYPE.pairLoan ? (
                    <>
                      <tr className={classes.tableRow}>
                        <td className={classes.tableCell}>
                          <Typography>ご本人返済額</Typography>
                        </td>
                        <td className={classes.tableCell}>
                          <Typography variant="h5" display="inline">
                            {convertLocaleString(housingLoanRepayment.repayment_monthly)}
                          </Typography>
                          <Typography display="inline">円</Typography>
                        </td>
                        <td className={classes.tableCell}>
                          <Typography variant="h5" display="inline">
                            {convertLocaleString(housingLoanRepayment.repayment_yearly)}
                          </Typography>
                          <Typography display="inline">円</Typography>
                        </td>
                      </tr>
                      <tr className={classes.tableRow}>
                        <td className={classes.tableCell}>
                          <Typography>配偶者返済額</Typography>
                        </td>
                        <td className={classes.tableCell}>
                          <Typography variant="h5" display="inline">
                            {convertLocaleString(
                              housingLoanRepayment.sub_repayment_monthly,
                            )}
                          </Typography>
                          <Typography display="inline">円</Typography>
                        </td>
                        <td className={classes.tableCell}>
                          <Typography variant="h5" display="inline">
                            {convertLocaleString(
                              housingLoanRepayment.sub_repayment_yearly,
                            )}
                          </Typography>
                          <Typography display="inline">円</Typography>
                        </td>
                      </tr>
                      <tr className={classes.tableRow}>
                        <td className={classes.tableCell}>
                          <Typography className="u-bold">合計</Typography>
                        </td>
                        <td className={classes.tableCell}>
                          <Typography variant="h5" display="inline">
                            {convertLocaleString(
                              castNumberWithoutOperator(
                                housingLoanRepayment.repayment_monthly,
                              ) +
                                castNumberWithoutOperator(
                                  housingLoanRepayment.sub_repayment_monthly,
                                ),
                            )}
                          </Typography>
                          <Typography display="inline">円</Typography>
                        </td>
                        <td className={classes.tableCell}>
                          <Typography variant="h5" display="inline">
                            {convertLocaleString(
                              castNumberWithoutOperator(
                                housingLoanRepayment.repayment_yearly,
                              ) +
                                castNumberWithoutOperator(
                                  housingLoanRepayment.sub_repayment_yearly,
                                ),
                            )}
                          </Typography>
                          <Typography display="inline">円</Typography>
                        </td>
                      </tr>
                    </>
                  ) : (
                    <tr className={classes.tableRow}>
                      <td className={classes.tableCell}>
                        <Typography>返済額</Typography>
                      </td>
                      <td className={classes.tableCell}>
                        <Typography variant="h5" display="inline">
                          {convertLocaleString(housingLoanRepayment.repayment_monthly)}
                        </Typography>
                        <Typography display="inline">円</Typography>
                      </td>
                      <td className={classes.tableCell}>
                        <Typography variant="h5" display="inline">
                          {convertLocaleString(housingLoanRepayment.repayment_yearly)}
                        </Typography>
                        <Typography display="inline">円</Typography>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
            <Typography className="u-tac" variant="body2">
              入力していただいた内容をベースに、金利は自動で設定して計算しています。
            </Typography>
          </>
        )
      }}
    </FormSpy>
  )
}

export default HousingLoanRepaymentTable
