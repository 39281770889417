import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  getLearningVideos,
  getLearningVideo,
  postLearningVideo,
  patchLearningVideo,
} from '@/api/admins/learningVideos'
import { unauthorizedAdminCheck } from '@/containers/admins/fpAccountsSlice'
import { LearningVideoResponse, LearningVideo } from '@/models/commonLearningVideosModel'
import { ApiError } from '@/models/commonsModel'
import { AppThunk } from '@/store'

interface LearningVideoState {
  learningVideos: LearningVideo[]
  learningVideo: LearningVideo | null
  isLoading: boolean
}

const initialState: LearningVideoState = {
  learningVideos: [],
  learningVideo: null,
  isLoading: false,
}

const learningVideosSlice = createSlice({
  name: 'learningVideos',
  initialState,
  reducers: {
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    getLearningVideosSuccess: (state, action: PayloadAction<LearningVideoResponse[]>) => {
      state.learningVideos = action.payload
      state.isLoading = false
      return state
    },
    getLearningVideoSuccess: (state, action: PayloadAction<LearningVideoResponse>) => {
      state.learningVideo = action.payload
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
  },
})

export const {
  requestStart,
  requestSuccess,
  getLearningVideosSuccess,
  getLearningVideoSuccess,
  requestFailure,
} = learningVideosSlice.actions
export const learningVideosReducer = learningVideosSlice.reducer

export const fetchLearningVideos = (): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  getLearningVideos()
    .then((response) => dispatch(getLearningVideosSuccess(response)))
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedAdminCheck(error))
    })
}

export const fetchLearningVideo = (id: number): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  getLearningVideo(id)
    .then((response) => dispatch(getLearningVideoSuccess(response)))
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedAdminCheck(error))
    })
}

export const createLearningVideo = (
  values: FormData,
  callback: () => void,
  resolve: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postLearningVideo(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedAdminCheck(error, resolve))
    })
}

export const updateLearningVideo = (
  id: number,
  values: FormData,
  callback: () => void,
  resolve: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchLearningVideo(id, values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedAdminCheck(error, resolve))
    })
}
