import React, { FC } from 'react'
import {
  Typography as MuiTypography,
  TypographyProps as MuiTypographyProps,
} from '@material-ui/core'

interface TypographyProps extends MuiTypographyProps {}

const Typography: FC<TypographyProps> = (props) => {
  return <MuiTypography {...props} />
}

export default Typography
