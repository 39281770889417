import React, { FC, useEffect, useMemo } from 'react'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import ChevronRightIcon from '@/assets/images/v2/chevron-right.svg'
import Button from '@/components/v2/atoms/Button'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import Grid from '@/components/v2/atoms/Grid'
import Typography from '@/components/v2/atoms/Typography'
import { useDispatch, useSelector } from 'react-redux'
import {
  InputHousingCreateValues,
  InputHousingUpdateValues,
} from '@/models/inputs/inputHousingsModel'
import {
  createInitialValues,
  hasTransferHouse,
  isNotSelfHouseType,
  isRentHouseType,
  isSelfOwnedHouseType,
} from '@/models/inputs/inputHousingsModelFunc'
import {
  HOUSE_TYPE,
  isHousingType2Custom,
  isMansionType,
  isPairLoan,
  isWithCalcLoan,
  isWithChartLoan,
  isWithLoan,
} from '@/models/commonHousingModelFunc'
import {
  buildHousingRentSubmitValues,
  buildHousingSelfOwnedSubmitValues,
  buildHousingTransferSubmitValues,
} from '@/utils/modalForms/inputHousings'
import {
  createInputHousingRent,
  createInputHousingSelfOwned,
  createInputHousingTransfer,
  fetchHousingLoanPaymentSummary,
  fetchInputHousings,
  updateInputHousingRent,
  updateInputHousingSelfOwned,
  updateInputHousingTransfer,
} from '@/containers/inputs/inputHousingsSlice'
import { RootState } from '@/store'
import {
  findCurrentRentHouse,
  INPUT_HOUSING_RENT_TYPE,
} from '@/models/inputs/inputHousingRentsModelFunc'
import { API_FLAG, castNumberWithoutOperator } from '@/models/commonsModelFunc'
import CurrentHouseType from '@/templates/v2/myData/housings/CurrentHouseType'
import MonthlyRent from '@/templates/v2/commons/modals/commonHousing/rents/MonthlyRent'
import NextContractUpdate from '@/templates/v2/commons/modals/commonHousing/rents/NextContractUpdate'
import NextUpdateCost from '@/templates/v2/commons/modals/commonHousing/rents/NextUpdateCost'
import HouseType from '@/templates/v2/commons/modals/commonHousing/selfOwned/HouseType'
import HousingType2 from '@/templates/v2/commons/modals/commonHousing/selfOwned/HousingType2'
import HouseBasicInfo from '@/templates/v2/commons/modals/commonHousing/selfOwned/HouseBasicInfo'
import TaxYearly from '@/templates/v2/commons/modals/commonHousing/selfOwned/TaxYearly'
import RepairCost from '@/templates/v2/commons/modals/commonHousing/selfOwned/RepairCost'
import RepairFundAndManagement from '@/templates/v2/commons/modals/commonHousing/selfOwned/RepairFundAndManagement'
import OtherCost from '@/templates/v2/commons/modals/commonHousing/selfOwned/OtherCost'
import ChildRearingDeduction from '@/templates/v2/commons/modals/commonHousing/selfOwned/ChildRearingDeduction'
import WithOrWithoutLoan from '@/templates/v2/commons/modals/commonHousing/selfOwned/WithOrWithoutLoan'
import LoanSplit from '@/templates/v2/commons/modals/commonHousing/selfOwned/LoanSplit'
import LoanType from '@/templates/v2/commons/modals/commonHousing/selfOwned/LoanType'
import RepaymentChart from '@/templates/v2/myData/housings/selfOwned/RepaymentChart'
import LoanLender from '@/templates/v2/commons/modals/commonHousing/selfOwned/LoanLender'
import RepaymentPlan from '@/templates/v2/myData/housings/selfOwned/RepaymentPlan'
import AdvanceRepayment from '@/templates/v2/myData/housings/selfOwned/AdvanceRepayment'
import RepaymentAmountDetail from '@/templates/v2/commons/modals/commonHousing/selfOwned/RepaymentAmountDetail'
import InterestRates from '@/templates/v2/commons/modals/commonHousing/selfOwned/InterestRates'
import LoanDeduction from '@/templates/v2/commons/modals/commonHousing/selfOwned/LoanDeduction'
import { isSplitedLoan } from '@/models/inputs/inputHousingSelfOwnedModelFunc'
import { handleSetInitialValues } from '@/models/inputs/inputHousingsModelFunc'
import { cloneDeep as _cloneDeep, find as _find, set as _set } from 'lodash'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import { fetchFamilyStructure } from '@/containers/familyStructuresSlice'
import { Decorator } from 'final-form'
import createDecorator from 'final-form-calculate'
import {
  INPUT_LOAN_CALCULATION_FLAT_CHANGE_BUILDING_FIELDS,
  INPUT_LOAN_CALCULATION_FLAT_CHANGE_LAND_FIELDS,
} from '@/models/inputs/inputLoanCalculationsModelFunc'
import { isFlatLoan } from '@/models/inputs/inputLoansModelFunc'
import WholeSubmitErrorMessage from '@/components/v2/molecules/WholeSubmitErrorMessage'
import LoadingOverlay from '@/components/v1/atoms/LoadingOverlay'
import clsx from 'clsx'
import SubmitErrorMessage from '@/components/v2/molecules/SubmitErrorMessage'
import { updateResetFlg } from '@/containers/futures/futureBasesSlice'

const DESCRIPTION_TEMPLATES: any[] = [
  {
    children: <CurrentHouseType />,
    condition: (values: any) => !values.isOnlyTransfer,
  },
  {
    children: <MonthlyRent />,
    condition: (values: any) =>
      !values.isOnlyTransfer &&
      (isRentHouseType(Number(values?.input_housing?.house_type)) ||
        isNotSelfHouseType(Number(values?.input_housing?.house_type))),
  },
  {
    children: <NextContractUpdate />,
    condition: (values: any) =>
      !values.isOnlyTransfer &&
      (isRentHouseType(Number(values?.input_housing?.house_type)) ||
        isNotSelfHouseType(Number(values?.input_housing?.house_type))),
  },
  {
    children: <NextUpdateCost />,
    condition: (values: any) =>
      !values.isOnlyTransfer &&
      (isRentHouseType(Number(values?.input_housing?.house_type)) ||
        isNotSelfHouseType(Number(values?.input_housing?.house_type))) &&
      Number(
        values?.input_housing?.input_housing_rents_attributes?.[0]?.next_update_type,
      ) == API_FLAG.on,
  },
  {
    children: <HouseType />,
    condition: (values: any) =>
      !values.isOnlyTransfer &&
      isSelfOwnedHouseType(Number(values?.input_housing?.house_type)),
  },
  {
    children: <HousingType2 />,
    condition: (values: any) =>
      !values.isOnlyTransfer &&
      isSelfOwnedHouseType(Number(values?.input_housing?.house_type)),
  },
  {
    children: <HouseBasicInfo />,
    condition: (values: any) =>
      !values.isOnlyTransfer &&
      isSelfOwnedHouseType(Number(values?.input_housing?.house_type)),
  },
  {
    children: <TaxYearly />,
    condition: (values: any) =>
      !values.isOnlyTransfer &&
      isSelfOwnedHouseType(Number(values?.input_housing?.house_type)),
  },
]

const DESCRIPTION_TEMPLATES3: any[] = [
  {
    children: <RepairCost />,
    condition: (values: any) =>
      !values.isOnlyTransfer &&
      isSelfOwnedHouseType(Number(values?.input_housing?.house_type)),
  },
  {
    children: <RepairFundAndManagement />,
    condition: (values: any) =>
      !values.isOnlyTransfer &&
      isSelfOwnedHouseType(Number(values?.input_housing?.house_type)) &&
      isMansionType(
        values?.input_housing?.input_housing_self_owned_attributes?.house_type,
      ),
  },
  {
    children: <OtherCost />,
    condition: (values: any) =>
      !values.isOnlyTransfer &&
      isSelfOwnedHouseType(Number(values?.input_housing?.house_type)),
  },
]

const DESCRIPTION_TEMPLATES5: any[] = [
  {
    children: <WithOrWithoutLoan />,
    condition: (values: any) =>
      !values.isOnlyTransfer &&
      isSelfOwnedHouseType(Number(values?.input_housing?.house_type)),
  },
  {
    children: <ChildRearingDeduction />,
    condition: (values: any) =>
      !values.isOnlyTransfer &&
      isSelfOwnedHouseType(Number(values?.input_housing?.house_type)) &&
      isWithLoan(
        Number(values?.input_housing?.input_housing_self_owned_attributes?.loan_type1),
      ) &&
      values?.input_housing?.input_housing_self_owned_attributes?.purchased_at_year >= 2024,
  },
  {
    children: <LoanSplit />,
    condition: (values: any) => {
      return (
        !values.isOnlyTransfer &&
        isSelfOwnedHouseType(Number(values?.input_housing?.house_type)) &&
        !isMansionType(
          Number(values.input_housing?.input_housing_self_owned_attributes?.house_type),
        ) &&
        isHousingType2Custom(
          values.input_housing?.input_housing_self_owned_attributes?.housing_type2,
        ) &&
        isWithLoan(
          Number(values?.input_housing?.input_housing_self_owned_attributes?.loan_type1),
        )
      )
    },
  },
]

const DESCRIPTION_TEMPLATES_LOAN_UNSPLITTED: any[] = [
  {
    children: <LoanType />,
    condition: (values: any) =>
      !values.isOnlyTransfer &&
      isSelfOwnedHouseType(Number(values?.input_housing?.house_type)) &&
      isWithLoan(
        Number(values?.input_housing?.input_housing_self_owned_attributes?.loan_type1),
      ),
  },

  {
    children: <RepaymentChart />,
    condition: (values: any) => {
      return (
        !values.isOnlyTransfer &&
        isSelfOwnedHouseType(Number(Number(values?.input_housing?.house_type))) &&
        isWithLoan(
          Number(values?.input_housing?.input_housing_self_owned_attributes?.loan_type1),
        )
      )
    },
  },
]

const DESCRIPTION_TEMPLATES_LOAN_WITH_CHART: any[] = [
  // ====================================== 返済計画表あり
  {
    children: <LoanLender isNotSplit />,
    condition: (values: any) =>
      !values.isOnlyTransfer &&
      isSelfOwnedHouseType(Number(values?.input_housing?.house_type)) &&
      isWithLoan(
        Number(values?.input_housing?.input_housing_self_owned_attributes?.loan_type1),
      ),
  },

  {
    children: <RepaymentPlan />,
    condition: (values: any) =>
      !values.isOnlyTransfer &&
      isSelfOwnedHouseType(Number(values?.input_housing?.house_type)) &&
      isWithChartLoan(
        Number(
          values?.input_housing?.input_housing_self_owned_attributes
            ?.input_loans_attributes?.[0]?.repayment_chart,
        ),
      ),
  },

  {
    children: (
      <>
        <LoanDeduction />
      </>
    ),
    condition: (values: any) =>
      !values.isOnlyTransfer &&
      isSelfOwnedHouseType(Number(values?.input_housing?.house_type)) &&
      isWithLoan(
        Number(values?.input_housing?.input_housing_self_owned_attributes?.loan_type1),
      ),
  },
]

const DESCRIPTION_TEMPLATES_LOAN_WITH_CALC: any[] = [
  {
    children: <LoanLender isNotSplit />,
    condition: (values: any) =>
      !values.isOnlyTransfer &&
      isSelfOwnedHouseType(Number(values?.input_housing?.house_type)) &&
      isWithLoan(
        Number(values?.input_housing?.input_housing_self_owned_attributes?.loan_type1),
      ),
  },

  // ====================================== 返済計画表なし
  {
    children: <AdvanceRepayment />,
    condition: (values: any) =>
      !values.isOnlyTransfer &&
      isSelfOwnedHouseType(Number(values?.input_housing?.house_type)) &&
      isWithLoan(
        Number(values?.input_housing?.input_housing_self_owned_attributes?.loan_type1),
      ) &&
      isWithCalcLoan(
        Number(
          values?.input_housing?.input_housing_self_owned_attributes
            ?.input_loans_attributes?.[0]?.repayment_chart,
        ),
      ),
  },
  {
    children: <RepaymentAmountDetail />,
    condition: (values: any) =>
      !values.isOnlyTransfer &&
      isSelfOwnedHouseType(Number(values?.input_housing?.house_type)) &&
      isWithLoan(
        Number(values?.input_housing?.input_housing_self_owned_attributes?.loan_type1),
      ) &&
      isWithCalcLoan(
        Number(
          values?.input_housing?.input_housing_self_owned_attributes
            ?.input_loans_attributes?.[0]?.repayment_chart,
        ),
      ),
  },
  {
    children: <InterestRates />,
    condition: (values: any) =>
      !values.isOnlyTransfer &&
      isSelfOwnedHouseType(Number(values?.input_housing?.house_type)) &&
      isWithLoan(
        Number(values?.input_housing?.input_housing_self_owned_attributes?.loan_type1),
      ) &&
      isWithCalcLoan(
        Number(
          values?.input_housing?.input_housing_self_owned_attributes
            ?.input_loans_attributes?.[0]?.repayment_chart,
        ),
      ),
  },

  {
    children: (
      <>
        <LoanDeduction />
      </>
    ),
    condition: (values: any) =>
      !values.isOnlyTransfer &&
      isSelfOwnedHouseType(Number(values?.input_housing?.house_type)) &&
      isWithLoan(
        Number(values?.input_housing?.input_housing_self_owned_attributes?.loan_type1),
      ),
  },
]

const DESCRIPTION_TEMPLATES_LOAN_PAIR_WITH_CHART: any[] = [
  {
    children: <LoanLender isNotSplit />,
    condition: (values: any) =>
      !values.isOnlyTransfer &&
      isSelfOwnedHouseType(Number(values?.input_housing?.house_type)) &&
      isWithLoan(
        Number(values?.input_housing?.input_housing_self_owned_attributes?.loan_type1),
      ),
  },

  // ====================================== 以下返済計画表あり
  {
    children: <RepaymentPlan isSpouse />,
    condition: (values: any) =>
      !values.isOnlyTransfer &&
      isSelfOwnedHouseType(Number(values?.input_housing?.house_type)) &&
      isWithChartLoan(
        Number(
          values?.input_housing?.input_housing_self_owned_attributes
            ?.input_loans_attributes?.[0]?.repayment_chart,
        ),
      ) &&
      isPairLoan(
        Number(
          values?.input_housing?.input_housing_self_owned_attributes
            ?.input_loans_attributes?.[0]?.loan_type,
        ),
      ),
  },
  {
    children: <LoanDeduction isSpouse />,
    condition: (values: any) =>
      !values.isOnlyTransfer &&
      isSelfOwnedHouseType(Number(values?.input_housing?.house_type)) &&
      isPairLoan(
        Number(
          values?.input_housing?.input_housing_self_owned_attributes
            ?.input_loans_attributes?.[0]?.loan_type,
        ),
      ),
  },
]

const DESCRIPTION_TEMPLATES_LOAN_PAIR_WITH_CALC: any[] = [
  // ====================================== 以下返済計画表なし
  {
    children: <AdvanceRepayment isSpouse />,
    condition: (values: any) =>
      !values.isOnlyTransfer &&
      isSelfOwnedHouseType(Number(values?.input_housing?.house_type)) &&
      isWithCalcLoan(
        Number(
          values?.input_housing?.input_housing_self_owned_attributes
            ?.input_loans_attributes?.[0]?.repayment_chart,
        ),
      ) &&
      isPairLoan(
        Number(
          values?.input_housing?.input_housing_self_owned_attributes
            ?.input_loans_attributes?.[0]?.loan_type,
        ),
      ),
  },
  {
    children: <RepaymentAmountDetail isSpouse />,
    condition: (values: any) =>
      !values.isOnlyTransfer &&
      isSelfOwnedHouseType(Number(values?.input_housing?.house_type)) &&
      isWithCalcLoan(
        Number(
          values?.input_housing?.input_housing_self_owned_attributes
            ?.input_loans_attributes?.[0]?.repayment_chart,
        ),
      ) &&
      isPairLoan(
        Number(
          values?.input_housing?.input_housing_self_owned_attributes
            ?.input_loans_attributes?.[0]?.loan_type,
        ),
      ),
  },
  {
    children: <InterestRates isSpouse />,
    condition: (values: any) =>
      !values.isOnlyTransfer &&
      isSelfOwnedHouseType(Number(values?.input_housing?.house_type)) &&
      isWithCalcLoan(
        Number(
          values?.input_housing?.input_housing_self_owned_attributes
            ?.input_loans_attributes?.[0]?.repayment_chart,
        ),
      ) &&
      isPairLoan(
        Number(
          values?.input_housing?.input_housing_self_owned_attributes
            ?.input_loans_attributes?.[0]?.loan_type,
        ),
      ),
  },
  {
    children: <LoanDeduction isSpouse />,
    condition: (values: any) =>
      !values.isOnlyTransfer &&
      isSelfOwnedHouseType(Number(values?.input_housing?.house_type)) &&
      isPairLoan(
        Number(
          values?.input_housing?.input_housing_self_owned_attributes
            ?.input_loans_attributes?.[0]?.loan_type,
        ),
      ),
  },
]

const DESCRIPTION_TEMPLATES_LOAN_LAND: any[] = [
  {
    children: <LoanType />,
    condition: (values: any) =>
      !values.isOnlyTransfer &&
      isSelfOwnedHouseType(Number(values?.input_housing?.house_type)) &&
      isWithLoan(
        Number(values?.input_housing?.input_housing_self_owned_attributes?.loan_type1),
      ),
  },

  {
    children: <RepaymentChart />,
    condition: (values: any) => {
      return (
        !values.isOnlyTransfer &&
        isSelfOwnedHouseType(Number(Number(values?.input_housing?.house_type))) &&
        isWithLoan(
          Number(values?.input_housing?.input_housing_self_owned_attributes?.loan_type1),
        )
      )
    },
  },
]

const DESCRIPTION_TEMPLATES_LOAN_BUILDING: any[] = [
  {
    children: <LoanType isBuilding />,
    condition: (values: any) =>
      !values.isOnlyTransfer &&
      isSelfOwnedHouseType(Number(values?.input_housing?.house_type)) &&
      isWithLoan(
        Number(values?.input_housing?.input_housing_self_owned_attributes?.loan_type1),
      ),
  },

  {
    children: <RepaymentChart isBuilding />,
    condition: (values: any) => {
      return (
        !values.isOnlyTransfer &&
        isSelfOwnedHouseType(Number(Number(values?.input_housing?.house_type))) &&
        isWithLoan(
          Number(values?.input_housing?.input_housing_self_owned_attributes?.loan_type1),
        )
      )
    },
  },
]

const DESCRIPTION_TEMPLATES_LOAN_LAND_WITH_CHART: any[] = [
  // ====================================== 返済計画表あり
  {
    children: <LoanLender isNotSplit />,
    condition: (values: any) =>
      !values.isOnlyTransfer &&
      isSelfOwnedHouseType(Number(values?.input_housing?.house_type)) &&
      isWithLoan(
        Number(values?.input_housing?.input_housing_self_owned_attributes?.loan_type1),
      ),
  },

  {
    children: <RepaymentPlan />,
    condition: (values: any) =>
      !values.isOnlyTransfer &&
      isSelfOwnedHouseType(Number(values?.input_housing?.house_type)) &&
      isWithChartLoan(
        Number(
          values?.input_housing?.input_housing_self_owned_attributes
            ?.input_loans_attributes?.[0]?.repayment_chart,
        ),
      ),
  },

  {
    children: (
      <>
        <LoanDeduction />
      </>
    ),
    condition: (values: any) =>
      !values.isOnlyTransfer &&
      isSelfOwnedHouseType(Number(values?.input_housing?.house_type)) &&
      isWithLoan(
        Number(values?.input_housing?.input_housing_self_owned_attributes?.loan_type1),
      ),
  },
]

const DESCRIPTION_TEMPLATES_LOAN_LAND_PAIR_WITH_CHART: any[] = [
  {
    children: <LoanLender isNotSplit />,
    condition: (values: any) =>
      !values.isOnlyTransfer &&
      isSelfOwnedHouseType(Number(values?.input_housing?.house_type)) &&
      isWithLoan(
        Number(values?.input_housing?.input_housing_self_owned_attributes?.loan_type1),
      ),
  },

  // ====================================== 以下返済計画表あり
  {
    children: <RepaymentPlan isSpouse />,
    condition: (values: any) =>
      !values.isOnlyTransfer &&
      isSelfOwnedHouseType(Number(values?.input_housing?.house_type)) &&
      isWithChartLoan(
        Number(
          values?.input_housing?.input_housing_self_owned_attributes
            ?.input_loans_attributes?.[0]?.repayment_chart,
        ),
      ) &&
      isPairLoan(
        Number(
          values?.input_housing?.input_housing_self_owned_attributes
            ?.input_loans_attributes?.[0]?.loan_type,
        ),
      ),
  },
  {
    children: <LoanDeduction isSpouse />,
    condition: (values: any) =>
      !values.isOnlyTransfer &&
      isSelfOwnedHouseType(Number(values?.input_housing?.house_type)) &&
      isPairLoan(
        Number(
          values?.input_housing?.input_housing_self_owned_attributes
            ?.input_loans_attributes?.[0]?.loan_type,
        ),
      ),
  },
]

const DESCRIPTION_TEMPLATES_LOAN_LAND_WITH_CALC: any[] = [
  {
    children: <LoanLender isNotSplit />,
    condition: (values: any) =>
      !values.isOnlyTransfer &&
      isSelfOwnedHouseType(Number(values?.input_housing?.house_type)) &&
      isWithLoan(
        Number(values?.input_housing?.input_housing_self_owned_attributes?.loan_type1),
      ),
  },

  // ====================================== 返済計画表なし
  {
    children: <AdvanceRepayment />,
    condition: (values: any) =>
      !values.isOnlyTransfer &&
      isSelfOwnedHouseType(Number(values?.input_housing?.house_type)) &&
      isWithLoan(
        Number(values?.input_housing?.input_housing_self_owned_attributes?.loan_type1),
      ) &&
      isWithCalcLoan(
        Number(
          values?.input_housing?.input_housing_self_owned_attributes
            ?.input_loans_attributes?.[0]?.repayment_chart,
        ),
      ),
  },
  {
    children: <RepaymentAmountDetail />,
    condition: (values: any) =>
      !values.isOnlyTransfer &&
      isSelfOwnedHouseType(Number(values?.input_housing?.house_type)) &&
      isWithLoan(
        Number(values?.input_housing?.input_housing_self_owned_attributes?.loan_type1),
      ) &&
      isWithCalcLoan(
        Number(
          values?.input_housing?.input_housing_self_owned_attributes
            ?.input_loans_attributes?.[0]?.repayment_chart,
        ),
      ),
  },
  {
    children: <InterestRates />,
    condition: (values: any) =>
      !values.isOnlyTransfer &&
      isSelfOwnedHouseType(Number(values?.input_housing?.house_type)) &&
      isWithLoan(
        Number(values?.input_housing?.input_housing_self_owned_attributes?.loan_type1),
      ) &&
      isWithCalcLoan(
        Number(
          values?.input_housing?.input_housing_self_owned_attributes
            ?.input_loans_attributes?.[0]?.repayment_chart,
        ),
      ),
  },

  {
    children: (
      <>
        <LoanDeduction />
      </>
    ),
    condition: (values: any) =>
      !values.isOnlyTransfer &&
      isSelfOwnedHouseType(Number(values?.input_housing?.house_type)) &&
      isWithLoan(
        Number(values?.input_housing?.input_housing_self_owned_attributes?.loan_type1),
      ),
  },
]

const DESCRIPTION_TEMPLATES_LOAN_LAND_PAIR_WITH_CALC: any[] = [
  // ====================================== 以下返済計画表なし
  {
    children: <AdvanceRepayment isSpouse />,
    condition: (values: any) =>
      !values.isOnlyTransfer &&
      isSelfOwnedHouseType(Number(values?.input_housing?.house_type)) &&
      isWithCalcLoan(
        Number(
          values?.input_housing?.input_housing_self_owned_attributes
            ?.input_loans_attributes?.[0]?.repayment_chart,
        ),
      ) &&
      isPairLoan(
        Number(
          values?.input_housing?.input_housing_self_owned_attributes
            ?.input_loans_attributes?.[0]?.loan_type,
        ),
      ),
  },
  {
    children: <RepaymentAmountDetail isSpouse />,
    condition: (values: any) =>
      !values.isOnlyTransfer &&
      isSelfOwnedHouseType(Number(values?.input_housing?.house_type)) &&
      isWithCalcLoan(
        Number(
          values?.input_housing?.input_housing_self_owned_attributes
            ?.input_loans_attributes?.[0]?.repayment_chart,
        ),
      ) &&
      isPairLoan(
        Number(
          values?.input_housing?.input_housing_self_owned_attributes
            ?.input_loans_attributes?.[0]?.loan_type,
        ),
      ),
  },
  {
    children: <InterestRates isSpouse />,
    condition: (values: any) =>
      !values.isOnlyTransfer &&
      isSelfOwnedHouseType(Number(values?.input_housing?.house_type)) &&
      isWithCalcLoan(
        Number(
          values?.input_housing?.input_housing_self_owned_attributes
            ?.input_loans_attributes?.[0]?.repayment_chart,
        ),
      ) &&
      isPairLoan(
        Number(
          values?.input_housing?.input_housing_self_owned_attributes
            ?.input_loans_attributes?.[0]?.loan_type,
        ),
      ),
  },
  {
    children: <LoanDeduction isSpouse />,
    condition: (values: any) =>
      !values.isOnlyTransfer &&
      isSelfOwnedHouseType(Number(values?.input_housing?.house_type)) &&
      isPairLoan(
        Number(
          values?.input_housing?.input_housing_self_owned_attributes
            ?.input_loans_attributes?.[0]?.loan_type,
        ),
      ),
  },
]

const DESCRIPTION_TEMPLATES_LOAN_BUILDING_WITH_CHART: any[] = [
  // ====================================== 以下建物用ローン
  {
    children: <LoanLender isBuilding />,
    condition: (values: any) =>
      isSelfOwnedHouseType(Number(values?.input_housing?.house_type)) &&
      isSplitedLoan(values?.input_housing?.input_housing_self_owned_attributes),
  },
  // ====================================== 以下返済計画あり
  {
    children: <RepaymentPlan isBuilding />,
    condition: (values: any) =>
      !values.isOnlyTransfer &&
      isSelfOwnedHouseType(Number(values?.input_housing?.house_type)) &&
      isSplitedLoan(values?.input_housing?.input_housing_self_owned_attributes) &&
      isWithChartLoan(
        Number(
          values?.input_housing?.input_housing_self_owned_attributes
            ?.input_loans_attributes?.[2]?.repayment_chart,
        ),
      ),
  },

  {
    children: (
      <>
        <LoanDeduction isBuilding />
      </>
    ),
    condition: (values: any) =>
      !values.isOnlyTransfer &&
      isSelfOwnedHouseType(Number(values?.input_housing?.house_type)) &&
      isSplitedLoan(values?.input_housing?.input_housing_self_owned_attributes),
  },
]

const DESCRIPTION_TEMPLATES_LOAN_BUILDING_PAIR_WITH_CHART: any[] = [
  {
    children: <LoanLender isNotSplit isBuilding />,
    condition: (values: any) =>
      !values.isOnlyTransfer &&
      isSelfOwnedHouseType(Number(values?.input_housing?.house_type)) &&
      isWithLoan(
        Number(values?.input_housing?.input_housing_self_owned_attributes?.loan_type1),
      ),
  },

  // ====================================== 以下返済計画表あり
  {
    children: <RepaymentPlan isSpouse isBuilding />,
    condition: (values: any) =>
      !values.isOnlyTransfer &&
      isSelfOwnedHouseType(Number(values?.input_housing?.house_type)) &&
      isWithChartLoan(
        Number(
          values?.input_housing?.input_housing_self_owned_attributes
            ?.input_loans_attributes?.[2]?.repayment_chart,
        ),
      ) &&
      isPairLoan(
        Number(
          values?.input_housing?.input_housing_self_owned_attributes
            ?.input_loans_attributes?.[2]?.loan_type,
        ),
      ),
  },
  {
    children: <LoanDeduction isSpouse isBuilding />,
    condition: (values: any) =>
      !values.isOnlyTransfer &&
      isSelfOwnedHouseType(Number(values?.input_housing?.house_type)) &&
      isPairLoan(
        Number(
          values?.input_housing?.input_housing_self_owned_attributes
            ?.input_loans_attributes?.[2]?.loan_type,
        ),
      ),
  },
]

const DESCRIPTION_TEMPLATES_LOAN_BUILDING_WITH_CALC: any[] = [
  // ====================================== 以下建物用ローン
  {
    children: <LoanLender isBuilding />,
    condition: (values: any) =>
      isSelfOwnedHouseType(Number(values?.input_housing?.house_type)) &&
      isSplitedLoan(values?.input_housing?.input_housing_self_owned_attributes),
  },
  // ====================================== 以下返済計画なし
  {
    children: <AdvanceRepayment isBuilding />,
    condition: (values: any) =>
      !values.isOnlyTransfer &&
      isSelfOwnedHouseType(Number(values?.input_housing?.house_type)) &&
      isSplitedLoan(values?.input_housing?.input_housing_self_owned_attributes) &&
      isWithCalcLoan(
        Number(
          values?.input_housing?.input_housing_self_owned_attributes
            ?.input_loans_attributes?.[2]?.repayment_chart,
        ),
      ),
  },
  {
    children: <RepaymentAmountDetail isBuilding />,
    condition: (values: any) =>
      !values.isOnlyTransfer &&
      isSelfOwnedHouseType(Number(values?.input_housing?.house_type)) &&
      isSplitedLoan(values?.input_housing?.input_housing_self_owned_attributes) &&
      isWithCalcLoan(
        Number(
          values?.input_housing?.input_housing_self_owned_attributes
            ?.input_loans_attributes?.[2]?.repayment_chart,
        ),
      ),
  },
  {
    children: <InterestRates isBuilding />,
    condition: (values: any) =>
      !values.isOnlyTransfer &&
      isSelfOwnedHouseType(Number(values?.input_housing?.house_type)) &&
      isSplitedLoan(values?.input_housing?.input_housing_self_owned_attributes) &&
      isWithCalcLoan(
        Number(
          values?.input_housing?.input_housing_self_owned_attributes
            ?.input_loans_attributes?.[2]?.repayment_chart,
        ),
      ),
  },
  {
    children: (
      <>
        <LoanDeduction isBuilding />
      </>
    ),
    condition: (values: any) =>
      !values.isOnlyTransfer &&
      isSelfOwnedHouseType(Number(values?.input_housing?.house_type)) &&
      isSplitedLoan(values?.input_housing?.input_housing_self_owned_attributes),
  },
]

const DESCRIPTION_TEMPLATES_LOAN_BUILDING_PAIR_WITH_CALC: any[] = [
  // ====================================== 以下返済計画表なし
  {
    children: <AdvanceRepayment isSpouse isBuilding />,
    condition: (values: any) =>
      !values.isOnlyTransfer &&
      isSelfOwnedHouseType(Number(values?.input_housing?.house_type)) &&
      isWithCalcLoan(
        Number(
          values?.input_housing?.input_housing_self_owned_attributes
            ?.input_loans_attributes?.[2]?.repayment_chart,
        ),
      ) &&
      isPairLoan(
        Number(
          values?.input_housing?.input_housing_self_owned_attributes
            ?.input_loans_attributes?.[2]?.loan_type,
        ),
      ),
  },
  {
    children: <RepaymentAmountDetail isSpouse isBuilding />,
    condition: (values: any) =>
      !values.isOnlyTransfer &&
      isSelfOwnedHouseType(Number(values?.input_housing?.house_type)) &&
      isWithCalcLoan(
        Number(
          values?.input_housing?.input_housing_self_owned_attributes
            ?.input_loans_attributes?.[2]?.repayment_chart,
        ),
      ) &&
      isPairLoan(
        Number(
          values?.input_housing?.input_housing_self_owned_attributes
            ?.input_loans_attributes?.[2]?.loan_type,
        ),
      ),
  },
  {
    children: <InterestRates isSpouse isBuilding />,
    condition: (values: any) =>
      !values.isOnlyTransfer &&
      isSelfOwnedHouseType(Number(values?.input_housing?.house_type)) &&
      isWithCalcLoan(
        Number(
          values?.input_housing?.input_housing_self_owned_attributes
            ?.input_loans_attributes?.[2]?.repayment_chart,
        ),
      ) &&
      isPairLoan(
        Number(
          values?.input_housing?.input_housing_self_owned_attributes
            ?.input_loans_attributes?.[2]?.loan_type,
        ),
      ),
  },
  {
    children: <LoanDeduction isSpouse isBuilding />,
    condition: (values: any) =>
      !values.isOnlyTransfer &&
      isSelfOwnedHouseType(Number(values?.input_housing?.house_type)) &&
      isPairLoan(
        Number(
          values?.input_housing?.input_housing_self_owned_attributes
            ?.input_loans_attributes?.[2]?.loan_type,
        ),
      ),
  },
]

const calculator: Decorator<any> = createDecorator(
  {
    field: INPUT_LOAN_CALCULATION_FLAT_CHANGE_BUILDING_FIELDS,
    updates: {
      'input_housing.input_housing_self_owned_attributes.input_loans_attributes[3]input_loan_calculation_attributes.started_borrowing_amount_manyen': (
        _,
        allValues: any,
      ) => {
        if (
          !isFlatLoan(
            allValues.input_housing.input_housing_self_owned_attributes
              ?.input_loans_attributes?.[2]?.loan_lender,
          )
        )
          return allValues.input_housing.input_housing_self_owned_attributes
            ?.input_loans_attributes?.[3]?.input_loan_calculation_attributes
            .started_borrowing_amount_manyen

        const totalAmount = allValues.started_borrowing_total_amount_building_manyen ?? 0
        const flatAmount =
          allValues.input_housing?.input_housing_self_owned_attributes
            ?.input_loans_attributes?.[2]?.input_loan_calculation_attributes
            ?.started_borrowing_amount_manyen ?? 0

        return (
          castNumberWithoutOperator(totalAmount) - castNumberWithoutOperator(flatAmount)
        )
      },
    },
  },
  {
    field: INPUT_LOAN_CALCULATION_FLAT_CHANGE_LAND_FIELDS,
    updates: {
      'input_housing.input_housing_self_owned_attributes.input_loans_attributes[1]input_loan_calculation_attributes.started_borrowing_amount_manyen': (
        _,
        allValues: any,
      ) => {
        if (
          !isFlatLoan(
            allValues.input_housing.input_housing_self_owned_attributes
              ?.input_loans_attributes?.[0]?.loan_lender,
          )
        )
          return allValues.input_housing.input_housing_self_owned_attributes
            ?.input_loans_attributes?.[1]?.input_loan_calculation_attributes
            .started_borrowing_amount_manyen

        const totalAmount = allValues.started_borrowing_total_amount_land_manyen ?? 0
        const flatAmount =
          allValues.input_housing?.input_housing_self_owned_attributes
            ?.input_loans_attributes?.[0]?.input_loan_calculation_attributes
            ?.started_borrowing_amount_manyen ?? 0

        return (
          castNumberWithoutOperator(totalAmount) - castNumberWithoutOperator(flatAmount)
        )
      },
    },
  },
)

interface MyDataHousingsEditFormProps {
  id?: string
  onClose: () => void
  onSubmit: () => void
}

const MyDataHousingsEditForm: FC<MyDataHousingsEditFormProps> = (props) => {
  const dispatch = useDispatch()
  const { showFlashMsg } = useFlashAlert()

  const hasTransfer = true
  const isOnlyTransfer = false

  const { inputHousings, isLoading } = useSelector(
    (state: RootState) => state.inputHousings,
  )

  // LoadingFlag
  const isLoadingFamilyStructures = useSelector(
    (state: RootState) => state.familyStructures.isLoading,
  )

  const { id } = props

  const isEdit = useMemo(() => !!id && inputHousings.length > 0, [inputHousings])

  const targetInputHousing = useMemo(
    () =>
      isOnlyTransfer
        ? _find(inputHousings, (item) => item.house_type === HOUSE_TYPE.transfer)
        : _find(inputHousings, (item) => item.house_type !== HOUSE_TYPE.transfer),
    [inputHousings],
  )

  const inputRentTypeHousing = useMemo(
    () => _find(inputHousings, (item) => isRentHouseType(item.house_type)),
    [inputHousings],
  )

  useEffect(() => {
    if (inputHousings.length == 0) {
      dispatch(fetchInputHousings())
    }
    dispatch(fetchFamilyStructure())
  }, [])

  const initialValues = useMemo(() => {
    return isEdit
      ? handleSetInitialValues(targetInputHousing) // edit
      : {
          // create
          ...createInitialValues,
          isOnlyTransfer,
          hasTransfer,
        }
  }, [targetInputHousing])

  const wrappedCompletedCallback = () => {
    showFlashMsg(isEdit ? '更新しました' : '作成しました')
    dispatch(fetchInputHousings())
    dispatch(fetchHousingLoanPaymentSummary())
    dispatch(updateResetFlg())
    props.onSubmit()
  }

  const handleSubmitCreate = (values: InputHousingCreateValues) => {
    const { input_housing } = values

    // MEMO: house_typeは本来 rent(0)/self_owned(1)/transfer(2) のため
    //       家賃・社宅(= notSelf)の選択の場合(4)、rentに変換する
    if (isNotSelfHouseType(input_housing.house_type)) {
      input_housing.house_type = HOUSE_TYPE.rent
    }

    const createInputHousingTransferFunc = () => {
      const { input_housing_transfer_attributes: transferAttributes } = input_housing

      const newTransferValues = {
        input_housing: {
          house_type: HOUSE_TYPE.transfer,
          input_housing_transfer_attributes: {
            ...buildHousingTransferSubmitValues(transferAttributes as any),
          },
        },
      }

      new Promise((resolve: any) => {
        dispatch(
          createInputHousingTransfer(
            newTransferValues,
            () => (isOnlyTransfer ? wrappedCompletedCallback() : {}),
            resolve,
          ),
        )
      })
    }

    const createInputHousingRentFunc = () => {
      const { input_housing_rents_attributes: rentsAttributes } = input_housing

      const newValuesWithRent = {
        input_housing: {
          house_type: HOUSE_TYPE.rent,
          input_housing_rents_attributes: [
            {
              ...buildHousingRentSubmitValues((rentsAttributes as any)[0]),
            },
          ],
        },
      }

      new Promise((resolve: any) => {
        dispatch(
          createInputHousingRent(
            newValuesWithRent,
            () => wrappedCompletedCallback(),
            resolve,
          ),
        )
      })
    }

    const createInputHousingSelfOwnedFunc = () => {
      const { input_housing_self_owned_attributes: selfOwnedAttributes } = input_housing

      const newValuesWithSelfOwned = {
        input_housing: {
          house_type: HOUSE_TYPE.own,
          input_housing_self_owned_attributes: {
            ...buildHousingSelfOwnedSubmitValues(selfOwnedAttributes as any),
          },
        },
      }

      new Promise((resolve: any) => {
        dispatch(
          createInputHousingSelfOwned(
            newValuesWithSelfOwned,
            () => wrappedCompletedCallback(),
            resolve,
          ),
        )
      })
    }

    if (hasTransferHouse(input_housing.transfer_type)) {
      createInputHousingTransferFunc()
    }

    if (isOnlyTransfer && hasTransferHouse(input_housing.transfer_type)) {
      return createInputHousingTransferFunc()
    }

    if (isRentHouseType(input_housing.house_type)) {
      createInputHousingRentFunc()
    } else {
      createInputHousingSelfOwnedFunc()
    }
  }

  const handleSubmitUpdate = (values: InputHousingUpdateValues) => {
    const pickedValues: any = values
    const { input_housing } = pickedValues

    // MEMO: house_typeは本来 rent(0)/self_owned(1)/transfer(2) のため
    //       家賃補助・社宅(= notSelf)を選択の場合(4)、rentに変換する
    if (isNotSelfHouseType(input_housing.house_type)) {
      input_housing.house_type = HOUSE_TYPE.rent
    }

    const updateInputHousingTransferFunc = () => {
      const { input_housing_transfer_attributes: transferAttributes } = input_housing

      const newTransferValues = {
        input_housing_id: (initialValues.input_housing as any).input_housing_id,
        input_housing: {
          house_type: HOUSE_TYPE.transfer,
          input_housing_transfer_attributes: {
            ...buildHousingTransferSubmitValues(transferAttributes),
          },
        },
      }

      new Promise(() => {
        dispatch(
          updateInputHousingTransfer(newTransferValues, () => wrappedCompletedCallback()),
        )
      })
    }

    const updateInputHousingRentFunc = () => {
      const { input_housing_rents_attributes: rentsAttributes } = input_housing

      const newValuesWithRent = {
        input_housing_id: (initialValues.input_housing as any).input_housing_id,
        input_housing: {
          house_type: HOUSE_TYPE.rent,
          input_housing_rents_attributes: [
            { ...buildHousingRentSubmitValues(rentsAttributes[0]) },
          ],
        },
      }

      new Promise(() => {
        !!inputRentTypeHousing
          ? dispatch(
              updateInputHousingRent(
                {
                  ...newValuesWithRent,
                  input_housing: {
                    input_housing_rents_attributes: [
                      {
                        ...newValuesWithRent!.input_housing
                          .input_housing_rents_attributes[0],
                        id: findCurrentRentHouse(
                          inputRentTypeHousing!.input_housing_rents,
                        )!.id,
                      },
                    ],
                  },
                },
                () => wrappedCompletedCallback(),
              ),
            )
          : dispatch(
              updateInputHousingRent(newValuesWithRent, () => wrappedCompletedCallback()),
            )
      })
    }

    const updateInputHousingSelfOwnedFunc = () => {
      const { input_housing_self_owned_attributes: selfOwnedAttributes } = input_housing

      const newValuesWithSelfOwned = {
        input_housing_id: (initialValues.input_housing as any).input_housing_id,
        input_housing: {
          house_type: HOUSE_TYPE.own,
          input_housing_self_owned_attributes: {
            ...buildHousingSelfOwnedSubmitValues(selfOwnedAttributes),
          },
        },
      }

      new Promise(() => {
        dispatch(
          updateInputHousingSelfOwned(newValuesWithSelfOwned, () =>
            wrappedCompletedCallback(),
          ),
        )
      })
    }

    if (isOnlyTransfer && hasTransferHouse(input_housing.transfer_type)) {
      return updateInputHousingTransferFunc()
    }
    if (!isOnlyTransfer && input_housing.house_type == HOUSE_TYPE.rent) {
      return updateInputHousingRentFunc()
    } else {
      updateInputHousingSelfOwnedFunc()
    }
  }

  const handleSubmit = (values: any) => {
    const newValues = _cloneDeep(values)

    return isEdit ? handleSubmitUpdate(newValues) : handleSubmitCreate(newValues)
  }

  return (
    <>
      <LoadingOverlay isLoading={isLoading || isLoadingFamilyStructures} />
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validate={(values) => {
          const validateMessage = {}
          const {
            rent_type,
            rent_monthly,
            whole_rent_monthly,
            next_update_cost,
            whole_next_update_cost,
          } = values.input_housing?.input_housing_rents_attributes[0] || {}
          const isNotSelfRent = rent_type !== INPUT_HOUSING_RENT_TYPE.selfRent

          if (
            isNotSelfRent &&
            castNumberWithoutOperator(rent_monthly) >
              castNumberWithoutOperator(whole_rent_monthly)
          ) {
            _set(
              validateMessage,
              ['input_housing', 'input_housing_rents_attributes', '0', 'rent_monthly'],
              '本来の家賃以下でご入力ください。',
            )
          }

          if (
            isNotSelfRent &&
            castNumberWithoutOperator(next_update_cost) >
              castNumberWithoutOperator(whole_next_update_cost)
          ) {
            _set(
              validateMessage,
              [
                'input_housing',
                'input_housing_rents_attributes',
                '0',
                'next_update_cost',
              ],
              '本来の更新費用以下でご入力ください。',
            )
          }

          return validateMessage
        }}
        mutators={{ ...arrayMutators }}
        decorators={[calculator]}
        render={({ handleSubmit, values, errors, submitErrors, submitFailed }) => (
          <form onSubmit={handleSubmit}>
            <CircleTitle className="pb-[32px]" title="基本情報" />
            <Grid className="grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px] mt-[16px]">
              {DESCRIPTION_TEMPLATES.map(
                (item, index) =>
                  item.condition(values) && (
                    <React.Fragment key={index}>{item.children}</React.Fragment>
                  ),
              )}
            </Grid>

            <Grid className="grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px] mt-[16px]">
              {DESCRIPTION_TEMPLATES3.map(
                (item, index) =>
                  item.condition(values) && (
                    <React.Fragment key={index}>{item.children}</React.Fragment>
                  ),
              )}
            </Grid>

            <Grid className="grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px] mt-[16px]">
              {DESCRIPTION_TEMPLATES5.map(
                (item, index) =>
                  item.condition(values) && (
                    <React.Fragment key={index}>{item.children}</React.Fragment>
                  ),
              )}
            </Grid>

            {/* 分けて借りていないパターン 共通 */}
            {!isSplitedLoan(values?.input_housing?.input_housing_self_owned_attributes) &&
              isWithLoan(
                Number(
                  values?.input_housing?.input_housing_self_owned_attributes?.loan_type1,
                ),
              ) && (
                <Grid className="grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px] mt-[16px]">
                  {DESCRIPTION_TEMPLATES_LOAN_UNSPLITTED.map(
                    (item, index) =>
                      item.condition(values) && (
                        <React.Fragment key={index}>{item.children}</React.Fragment>
                      ),
                  )}
                </Grid>
              )}

            {/* 分けて借りていない & 収入合算 or 単独 & 返済計画表あり パターン */}
            {!isSplitedLoan(values?.input_housing?.input_housing_self_owned_attributes) &&
              isWithLoan(
                Number(
                  values?.input_housing?.input_housing_self_owned_attributes?.loan_type1,
                ),
              ) &&
              isWithChartLoan(
                Number(
                  values?.input_housing?.input_housing_self_owned_attributes
                    ?.input_loans_attributes?.[0]?.repayment_chart,
                ),
              ) &&
              !isPairLoan(
                Number(
                  values?.input_housing?.input_housing_self_owned_attributes
                    ?.input_loans_attributes?.[0]?.loan_type,
                ),
              ) && (
                <Grid className="grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px] mt-[16px]">
                  {DESCRIPTION_TEMPLATES_LOAN_WITH_CHART.map(
                    (item, index) =>
                      item.condition(values) && (
                        <React.Fragment key={index}>{item.children}</React.Fragment>
                      ),
                  )}
                </Grid>
              )}

            {/* 分けて借りていない & 収入合算 or 単独 & 返済計画表なし パターン */}
            {!isSplitedLoan(values?.input_housing?.input_housing_self_owned_attributes) &&
              isWithCalcLoan(
                Number(
                  values?.input_housing?.input_housing_self_owned_attributes
                    ?.input_loans_attributes?.[0]?.repayment_chart,
                ),
              ) &&
              !isPairLoan(
                Number(
                  values?.input_housing?.input_housing_self_owned_attributes
                    ?.input_loans_attributes?.[0]?.loan_type,
                ),
              ) && (
                <Grid className="grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px] mt-[16px]">
                  {DESCRIPTION_TEMPLATES_LOAN_WITH_CALC.map(
                    (item, index) =>
                      item.condition(values) && (
                        <React.Fragment key={index}>{item.children}</React.Fragment>
                      ),
                  )}
                </Grid>
              )}

            {/* 分けて借りていない & ペアローン & 返済計画表あり パターン */}
            {!isSplitedLoan(values?.input_housing?.input_housing_self_owned_attributes) &&
              isWithLoan(
                Number(
                  values?.input_housing?.input_housing_self_owned_attributes?.loan_type1,
                ),
              ) &&
              isWithChartLoan(
                Number(
                  values?.input_housing?.input_housing_self_owned_attributes
                    ?.input_loans_attributes?.[0]?.repayment_chart,
                ),
              ) &&
              isPairLoan(
                Number(
                  values?.input_housing?.input_housing_self_owned_attributes
                    ?.input_loans_attributes?.[0]?.loan_type,
                ),
              ) && (
                <>
                  <CircleTitle className="pt-40 pb-20" title="ご本人のローンについて" />
                  <Grid className="grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px] mt-[16px]">
                    {DESCRIPTION_TEMPLATES_LOAN_WITH_CHART.map(
                      (item, index) =>
                        item.condition(values) && (
                          <React.Fragment key={index}>{item.children}</React.Fragment>
                        ),
                    )}
                  </Grid>

                  <CircleTitle className="pt-40 pb-20" title="配偶者のローンについて" />
                  <Grid className="grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px] mt-[16px]">
                    {DESCRIPTION_TEMPLATES_LOAN_PAIR_WITH_CHART.map(
                      (item, index) =>
                        item.condition(values) && (
                          <React.Fragment key={index}>{item.children}</React.Fragment>
                        ),
                    )}
                  </Grid>
                </>
              )}

            {/* 分けて借りていない & ペアローン & 返済計画表なし パターン */}
            {!isSplitedLoan(values?.input_housing?.input_housing_self_owned_attributes) &&
              isWithLoan(
                Number(
                  values?.input_housing?.input_housing_self_owned_attributes?.loan_type1,
                ),
              ) &&
              isWithCalcLoan(
                Number(
                  values?.input_housing?.input_housing_self_owned_attributes
                    ?.input_loans_attributes?.[0]?.repayment_chart,
                ),
              ) &&
              isPairLoan(
                Number(
                  values?.input_housing?.input_housing_self_owned_attributes
                    ?.input_loans_attributes?.[0]?.loan_type,
                ),
              ) && (
                <>
                  <CircleTitle className="pt-40 pb-20" title="ご本人のローンについて" />
                  <Grid className="grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px] mt-[16px]">
                    {DESCRIPTION_TEMPLATES_LOAN_WITH_CALC.map(
                      (item, index) =>
                        item.condition(values) && (
                          <React.Fragment key={index}>{item.children}</React.Fragment>
                        ),
                    )}
                  </Grid>

                  <CircleTitle className="pt-40 pb-20" title="配偶者のローンについて" />
                  <Grid className="grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px] mt-[16px]">
                    {DESCRIPTION_TEMPLATES_LOAN_PAIR_WITH_CALC.map(
                      (item, index) =>
                        item.condition(values) && (
                          <React.Fragment key={index}>{item.children}</React.Fragment>
                        ),
                    )}
                  </Grid>
                </>
              )}

            {/* 分けて借りている パターン 土地用 */}
            {values?.input_housing?.house_type == HOUSE_TYPE.own &&
              isWithLoan(
                Number(
                  values?.input_housing?.input_housing_self_owned_attributes?.loan_type1,
                ),
              ) &&
              isSplitedLoan(
                values?.input_housing?.input_housing_self_owned_attributes,
              ) && (
                <>
                  <CircleTitle className="pt-40 pb-20" title="土地用のローンについて" />
                  <Grid className="grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px] mt-[16px]">
                    {DESCRIPTION_TEMPLATES_LOAN_LAND.map(
                      (item, index) =>
                        item.condition(values) && (
                          <React.Fragment key={index}>{item.children}</React.Fragment>
                        ),
                    )}
                  </Grid>
                </>
              )}

            {values?.input_housing?.house_type == HOUSE_TYPE.own &&
              isWithLoan(
                Number(
                  values?.input_housing?.input_housing_self_owned_attributes?.loan_type1,
                ),
              ) &&
              isSplitedLoan(
                values?.input_housing?.input_housing_self_owned_attributes,
              ) && (
                <>
                  {/* 分けて借りている パターン 土地用 計画表あり */}
                  {isWithChartLoan(
                    Number(
                      values?.input_housing?.input_housing_self_owned_attributes
                        ?.input_loans_attributes?.[0]?.repayment_chart,
                    ),
                  ) && (
                    <>
                      {!isPairLoan(
                        Number(
                          values?.input_housing?.input_housing_self_owned_attributes
                            ?.input_loans_attributes?.[0]?.loan_type,
                        ),
                      ) ? (
                        <>
                          {/* 分けて借りている パターン 土地用 単独 or 収入合算 計画表あり */}
                          <Grid className="grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px] mt-[16px]">
                            {DESCRIPTION_TEMPLATES_LOAN_LAND_WITH_CHART.map(
                              (item, index) =>
                                item.condition(values) && (
                                  <React.Fragment key={index}>
                                    {item.children}
                                  </React.Fragment>
                                ),
                            )}
                          </Grid>
                        </>
                      ) : (
                        <>
                          {/* 分けて借りている パターン 土地用 ペアローン 計画表あり */}
                          <CircleTitle
                            className="pt-40 pb-20"
                            title="土地用のご本人のローンについて"
                          />
                          <Grid className="grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px] mt-[16px]">
                            {DESCRIPTION_TEMPLATES_LOAN_LAND_WITH_CHART.map(
                              (item, index) =>
                                item.condition(values) && (
                                  <React.Fragment key={index}>
                                    {item.children}
                                  </React.Fragment>
                                ),
                            )}
                          </Grid>
                          <CircleTitle
                            className="pt-40 pb-20"
                            title="土地用の配偶者のローンについて"
                          />
                          <Grid className="grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px] mt-[16px]">
                            {DESCRIPTION_TEMPLATES_LOAN_LAND_PAIR_WITH_CHART.map(
                              (item, index) =>
                                item.condition(values) && (
                                  <React.Fragment key={index}>
                                    {item.children}
                                  </React.Fragment>
                                ),
                            )}
                          </Grid>
                        </>
                      )}
                    </>
                  )}

                  {/* 分けて借りている パターン 土地用 計画表なし */}
                  {isWithCalcLoan(
                    Number(
                      values?.input_housing?.input_housing_self_owned_attributes
                        ?.input_loans_attributes?.[0]?.repayment_chart,
                    ),
                  ) && (
                    <>
                      {!isPairLoan(
                        Number(
                          values?.input_housing?.input_housing_self_owned_attributes
                            ?.input_loans_attributes?.[0]?.loan_type,
                        ),
                      ) ? (
                        <>
                          <Grid className="grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px] mt-[16px]">
                            {DESCRIPTION_TEMPLATES_LOAN_LAND_WITH_CALC.map(
                              (item, index) =>
                                item.condition(values) && (
                                  <React.Fragment key={index}>
                                    {item.children}
                                  </React.Fragment>
                                ),
                            )}
                          </Grid>
                        </>
                      ) : (
                        <>
                          <CircleTitle
                            className="pt-40 pb-20"
                            title="土地用のご本人のローンについて"
                          />
                          <Grid className="grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px] mt-[16px]">
                            {DESCRIPTION_TEMPLATES_LOAN_LAND_WITH_CALC.map(
                              (item, index) =>
                                item.condition(values) && (
                                  <React.Fragment key={index}>
                                    {item.children}
                                  </React.Fragment>
                                ),
                            )}
                          </Grid>
                          <CircleTitle
                            className="pt-40 pb-20"
                            title="土地用の配偶者のローンについて"
                          />
                          <Grid className="grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px] mt-[16px]">
                            {DESCRIPTION_TEMPLATES_LOAN_LAND_PAIR_WITH_CALC.map(
                              (item, index) =>
                                item.condition(values) && (
                                  <React.Fragment key={index}>
                                    {item.children}
                                  </React.Fragment>
                                ),
                            )}
                          </Grid>
                        </>
                      )}
                    </>
                  )}
                </>
              )}

            {/* 分けて借りている パターン 建物用 共通 */}
            {values?.input_housing?.house_type == HOUSE_TYPE.own &&
              isWithLoan(
                Number(
                  values?.input_housing?.input_housing_self_owned_attributes?.loan_type1,
                ),
              ) &&
              isSplitedLoan(
                values?.input_housing?.input_housing_self_owned_attributes,
              ) && (
                <>
                  <CircleTitle className="pt-40 pb-20" title="建物用のローンについて" />
                  <Grid className="grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px] mt-[16px]">
                    {DESCRIPTION_TEMPLATES_LOAN_BUILDING.map(
                      (item, index) =>
                        item.condition(values) && (
                          <React.Fragment key={index}>{item.children}</React.Fragment>
                        ),
                    )}
                  </Grid>
                </>
              )}

            {/* 分けて借りている パターン 建物用 */}
            {values?.input_housing?.house_type == HOUSE_TYPE.own &&
              isWithLoan(
                Number(
                  values?.input_housing?.input_housing_self_owned_attributes?.loan_type1,
                ),
              ) &&
              isSplitedLoan(
                values?.input_housing?.input_housing_self_owned_attributes,
              ) && (
                <>
                  {/* 分けて借りている パターン 建物用 計画表あり */}
                  {isWithChartLoan(
                    Number(
                      values?.input_housing?.input_housing_self_owned_attributes
                        ?.input_loans_attributes?.[2]?.repayment_chart,
                    ),
                  ) && (
                    <>
                      {!isPairLoan(
                        Number(
                          values?.input_housing?.input_housing_self_owned_attributes
                            ?.input_loans_attributes?.[2]?.loan_type,
                        ),
                      ) ? (
                        <>
                          <Grid className="grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px] mt-[16px]">
                            {DESCRIPTION_TEMPLATES_LOAN_BUILDING_WITH_CHART.map(
                              (item, index) =>
                                item.condition(values) && (
                                  <React.Fragment key={index}>
                                    {item.children}
                                  </React.Fragment>
                                ),
                            )}
                          </Grid>
                        </>
                      ) : (
                        <>
                          <CircleTitle
                            className="pt-40 pb-20"
                            title="建物用のご本人のローンについて"
                          />
                          <Grid className="grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px] mt-[16px]">
                            {DESCRIPTION_TEMPLATES_LOAN_BUILDING_WITH_CHART.map(
                              (item, index) =>
                                item.condition(values) && (
                                  <React.Fragment key={index}>
                                    {item.children}
                                  </React.Fragment>
                                ),
                            )}
                          </Grid>
                          <CircleTitle
                            className="pt-40 pb-20"
                            title="建物用の配偶者のローンについて"
                          />
                          <Grid className="grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px] mt-[16px]">
                            {DESCRIPTION_TEMPLATES_LOAN_BUILDING_PAIR_WITH_CHART.map(
                              (item, index) =>
                                item.condition(values) && (
                                  <React.Fragment key={index}>
                                    {item.children}
                                  </React.Fragment>
                                ),
                            )}
                          </Grid>
                        </>
                      )}
                    </>
                  )}

                  {/* 分けて借りている パターン 建物用 計画表なし */}
                  {isWithCalcLoan(
                    Number(
                      values?.input_housing?.input_housing_self_owned_attributes
                        ?.input_loans_attributes?.[2]?.repayment_chart,
                    ),
                  ) && (
                    <>
                      {!isPairLoan(
                        Number(
                          values?.input_housing?.input_housing_self_owned_attributes
                            ?.input_loans_attributes?.[2]?.loan_type,
                        ),
                      ) ? (
                        <>
                          <Grid className="grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px] mt-[16px]">
                            {DESCRIPTION_TEMPLATES_LOAN_BUILDING_WITH_CALC.map(
                              (item, index) =>
                                item.condition(values) && (
                                  <React.Fragment key={index}>
                                    {item.children}
                                  </React.Fragment>
                                ),
                            )}
                          </Grid>
                        </>
                      ) : (
                        <>
                          <CircleTitle
                            className="pt-40 pb-20"
                            title="建物用のご本人のローンについて"
                          />
                          <Grid className="grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px] mt-[16px]">
                            {DESCRIPTION_TEMPLATES_LOAN_BUILDING_WITH_CALC.map(
                              (item, index) =>
                                item.condition(values) && (
                                  <React.Fragment key={index}>
                                    {item.children}
                                  </React.Fragment>
                                ),
                            )}
                          </Grid>
                          <CircleTitle
                            className="pt-40 pb-20"
                            title="建物用の配偶者のローンについて"
                          />
                          <Grid className="grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px] mt-[16px]">
                            {DESCRIPTION_TEMPLATES_LOAN_BUILDING_PAIR_WITH_CALC.map(
                              (item, index) =>
                                item.condition(values) && (
                                  <React.Fragment key={index}>
                                    {item.children}
                                  </React.Fragment>
                                ),
                            )}
                          </Grid>
                        </>
                      )}
                    </>
                  )}
                </>
              )}

            <WholeSubmitErrorMessage submitErrors={submitErrors} />
            <SubmitErrorMessage show={!submitErrors && submitFailed} />
            <ButtonPrimary
              className={clsx(
                { ['opacity-50']: Object.keys(errors).length > 0 },
                'w-[246px] h-[64px] mx-auto my-[16px] ',
              )}
              isDisabled={isLoading}
              onClick={() => handleSubmit(values)}
            >
              <Typography
                className="relative bottom-[2px] pl-[30px] text-[16px] text-white flex-auto"
                isBold
              >
                保存する
              </Typography>
              <ChevronRightIcon className="mr-17" />
            </ButtonPrimary>
            <Button
              className="mx-auto text-[14px] text-black-700"
              onClick={props.onClose}
              isDisabled={isLoading}
            >
              キャンセル
            </Button>
          </form>
        )}
      />
    </>
  )
}

export default MyDataHousingsEditForm
