import { sumBy as _sumBy } from 'lodash'
import { AggBalanceMonthly } from '@/models/aggBalanceMonthliesModel'
import { convertLocaleString, castNumberWithoutComma } from '@/models/commonsModelFunc'

export const PAST_MONTHLY_COST_SUM_KEYS = [
  'food_expenses',
  'utility_bills',
  'daily_necessities_cost',
  'transport',
  'clothing_cost',
  'hobby_expenses',
  'communication_cost',
  'entertainment_expenses',
  'medical_expenses',
  'pocket_money',
  'uncategorized_cash',
  'uncategorized_expense',
  'other_cost',
]

export const sumPastMonthlyCost = (
  aggBalanceMonthly: (AggBalanceMonthly & { [key: string]: any }) | null,
) => {
  const total = _sumBy(PAST_MONTHLY_COST_SUM_KEYS, (key) =>
    castNumberWithoutComma(aggBalanceMonthly?.[key]),
  )
  return convertLocaleString(total)
}

export const renderPastMonthlyCostLabel = (
  pastMonthlyCost: number | null | undefined,
) => {
  return `${convertLocaleString(castNumberWithoutComma(pastMonthlyCost))}円/月`
}
