import React, { FC, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Decorator } from 'final-form'
import createDecorator from 'final-form-calculate'
import arrayMutators from 'final-form-arrays'
import { isNull as _isNull, find as _find, set as _set, last as _last } from 'lodash'
import {
  createFutureV2HousingOtherV2,
  createFutureV2HousingRentV2,
  createFutureV2HousingSelfOwnedV2,
} from '@/containers/futures/futureV2HousingsSlice'
import { updateFutureHousingSaleV2 } from '@/containers/futures/futureHousingSalesSlice'
import { updateFutureLendHousingV2 } from '@/containers/futures/futureLendHousingsSlice'
import {
  DescriptionContext,
  useDescriptionTemplates,
} from '@/hooks/useDescriptionTemplates'
import { QuestionTemplate } from '@/models/commonModalForm'
import {
  HOUSE_TYPE,
  isFlatLoan,
  isPairLoan,
  isWithChartLoan,
  isHousingType2Custom,
  isMansionType,
  isWithLoan,
} from '@/models/commonHousingModelFunc'
import {
  castNumberWithoutOperator,
  convertDateYear,
  isNotEmptyValue,
  THIS_YEAR_NUMBER,
} from '@/models/commonsModelFunc'
import {
  createInitialValues,
  LAST_AGE,
  NEXT_HOUSE_TYPE,
} from '@/models/futures/futureV2HousingsModelFunc'
import { isSplitedLoan } from '@/models/futures/futureV2HousingSelfOwnedModelFunc'
import {
  LOAN_CALCULATION_FLAT_CHANGE_LAND_FIELDS,
  LOAN_CALCULATION_FLAT_CHANGE_BUILDING_FIELDS,
} from '@/models/futures/futureV2HousingLoanCalculationsModelFunc'
import { FutureV2Housing, NextHouseType } from '@/models/futures/futureV2HousingsModel'
import { FutureV2HousingRent } from '@/models/futures/futureV2HousingRentsModel'
import { InputHousing } from '@/models/inputs/inputHousingsModel'
import { RootState } from '@/store'

import RelocationSelection from '@/templates/v2/simulations/futureV2Housings/FutureV2HousingModalForm/RelocationSelection'
import OtherCostDetail from '@/templates/v2/simulations/futureV2Housings/FutureV2HousingModalForm/OtherCostDetail'
import RelocationPhaseSetting from '@/templates/v2/simulations/futureV2Housings/FutureV2HousingModalForm/RelocationPhaseSetting'
import HouseType from '@/templates/v2/commons/modals/commonHousing/selfOwned/HouseType'
import HousingType2 from '@/templates/v2/commons/modals/commonHousing/selfOwned/HousingType2'
import HouseBasicInfo from '@/templates/v2/commons/modals/commonHousing/selfOwned/HouseBasicInfo'
import TaxYearly from '@/templates/v2/commons/modals/commonHousing/selfOwned/TaxYearly'
import AboutTax from '@/templates/v2/commons/modals/commonHousing/selfOwned/AboutTax'
import RepairCost from '@/templates/v2/commons/modals/commonHousing/selfOwned/RepairCost'
import RepairFundAndManagement from '@/templates/v2/commons/modals/commonHousing/selfOwned/RepairFundAndManagement'
import OtherCost from '@/templates/v2/commons/modals/commonHousing/selfOwned/OtherCost'
import ChildRearingDeduction from '@/templates/v2/commons/modals/commonHousing/selfOwned/ChildRearingDeduction'
import AboutOtherCost from '@/templates/v2/commons/modals/commonHousing/selfOwned/AboutOtherCost'
import WithOrWithoutLoan from '@/templates/v2/commons/modals/commonHousing/selfOwned/WithOrWithoutLoan'
import LoanSplit from '@/templates/v2/commons/modals/commonHousing/selfOwned/LoanSplit'
import LoanType from '@/templates/v2/commons/modals/commonHousing/selfOwned/LoanType'
import LoanLender from '@/templates/v2/commons/modals/commonHousing/selfOwned/LoanLender'
import RepaymentAmountDetail from '@/templates/v2/commons/modals/commonHousing/selfOwned/RepaymentAmountDetail'
import InterestRates from '@/templates/v2/commons/modals/commonHousing/selfOwned/InterestRates'
import LoanDeduction from '@/templates/v2/commons/modals/commonHousing/selfOwned/LoanDeduction'
import AbountLoanDeduction from '@/templates/v2/commons/modals/commonHousing/selfOwned/AboutLoanDeduction'

import {
  buildHousingSelfOwnedSubmitValues,
  buildHousingRentSubmitValues,
  buildHousingOtherSubmitValues,
} from '@/utils/modalForms/futureV2Housings'
import Modal from '@/components/v2/atoms/Modal'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import { Form } from 'react-final-form'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import Grid from '@/components/v2/atoms/Grid'
import Typography from '@/components/v2/atoms/Typography'
import ChevronRightIcon from '@/assets/images/v2/chevron-right.svg'

const DESCRIPTION_TEMPLATES: QuestionTemplate[] = [
  {
    children: <RelocationSelection />,
    condition: (values: any) => !values?.nextHouseType,
  },
]

const DESCRIPTION_TEMPLATES_OTHER: QuestionTemplate[] = [
  {
    children: <OtherCostDetail />,
    condition: (values: any) =>
      values?.future_v2_housing?.house_type == HOUSE_TYPE.other ||
      values?.nextHouseType == NEXT_HOUSE_TYPE.other,
  },
]
const DESCRIPTION_TEMPLATES_RENT: QuestionTemplate[] = [
  {
    children: <RelocationPhaseSetting />,
    condition: (values: any) =>
      values?.future_v2_housing?.house_type == HOUSE_TYPE.rent ||
      values?.nextHouseType == NEXT_HOUSE_TYPE.rent,
    validate: (values: any) => {
      let errorMessages: { [key: string]: any } = {}

      const lastfutureV2HousingRents: any = _last(
        values?.future_v2_housing?.future_v2_housing_rents_attributes,
      )

      if (!lastfutureV2HousingRents) {
        _set(errorMessages, ['future_v2_housing'], '転居の予定を登録してください。')
      }

      if (
        values?.living_end_at_age &&
        Number(lastfutureV2HousingRents?.living_end_at_age) >=
          Number(values?.living_end_at_age)
      ) {
        _set(
          errorMessages,
          ['living_end_at_age'],
          '追加済みの年齢以後の年齢を入力してください。',
        )
      }

      return errorMessages
    },
  },
]

const DESCRIPTION_TEMPLATES_OWN: QuestionTemplate[] = [
  {
    children: <HouseType isFuture />,
    condition: (values: any) =>
      values?.future_v2_housing?.house_type == HOUSE_TYPE.own ||
      values?.nextHouseType == NEXT_HOUSE_TYPE.own,
  },
  {
    children: <HousingType2 isFuture />,
    condition: (values: any) =>
      values?.future_v2_housing?.house_type == HOUSE_TYPE.own ||
      values?.nextHouseType == NEXT_HOUSE_TYPE.own,
  },
  {
    children: <HouseBasicInfo isFuture />,
    condition: (values: any) =>
      values?.future_v2_housing?.house_type == HOUSE_TYPE.own ||
      values?.nextHouseType == NEXT_HOUSE_TYPE.own,
  },
  {
    children: <TaxYearly isFuture />,
    footer: <AboutTax />,
    condition: (values: any) =>
      values?.future_v2_housing?.house_type == HOUSE_TYPE.own ||
      values?.nextHouseType == NEXT_HOUSE_TYPE.own,
  },
  {
    children: <RepairCost isFuture />,
    condition: (values: any) =>
      values?.future_v2_housing?.house_type == HOUSE_TYPE.own ||
      values?.nextHouseType == NEXT_HOUSE_TYPE.own,
  },
  {
    children: <RepairFundAndManagement isFuture />,
    condition: (values: any) =>
      (values?.future_v2_housing?.house_type == HOUSE_TYPE.own ||
        values?.nextHouseType == NEXT_HOUSE_TYPE.own) &&
      isMansionType(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes?.house_type,
      ),
    validate: (values: any) => {
      let errorMessages: { [key: string]: any } = {}
      const {
        repair_reserve_fund_amount_start: repairReserveFundAmountStart,
        repair_reserve_fund_amount_finish: repairReserveFundAmountFinish,
        repair_reserve_fund_increase_start: repairReserveFundIncreaseStart,
        repair_reserve_fund_increase_finish: repairReserveFundIncreaseFinish,
      } = values.future_v2_housing?.future_v2_housing_self_owned_attributes
      if (
        isNotEmptyValue(repairReserveFundAmountStart) &&
        isNotEmptyValue(repairReserveFundAmountFinish) &&
        castNumberWithoutOperator(repairReserveFundAmountStart) >
          castNumberWithoutOperator(repairReserveFundAmountFinish)
      ) {
        _set(
          errorMessages,
          [
            'future_v2_housing',
            'future_v2_housing_self_owned_attributes',
            'repair_reserve_fund_amount_start',
          ],
          '期間終了年数以下の年数を入力してください。',
        )
      }

      if (
        isNotEmptyValue(repairReserveFundIncreaseStart) &&
        isNotEmptyValue(repairReserveFundIncreaseFinish) &&
        castNumberWithoutOperator(repairReserveFundIncreaseStart) >
          castNumberWithoutOperator(repairReserveFundIncreaseFinish)
      ) {
        _set(
          errorMessages,
          [
            'future_v2_housing',
            'future_v2_housing_self_owned_attributes',
            'repair_reserve_fund_increase_start',
          ],
          '期間終了年数以下の年数を入力してください。',
        )
      }

      return errorMessages
    },
  },
  {
    children: <OtherCost isFuture />,
    footer: <AboutOtherCost />,
    condition: (values: any) =>
      values?.future_v2_housing?.house_type == HOUSE_TYPE.own ||
      values?.nextHouseType == NEXT_HOUSE_TYPE.own,
  },
]

const DESCRIPTION_TEMPLATES5: any[] = [
  {
    children: <WithOrWithoutLoan isFuture />,
    condition: (values: any) =>
      values?.future_v2_housing?.house_type == HOUSE_TYPE.own ||
      values?.nextHouseType == NEXT_HOUSE_TYPE.own,
  },
  {
    children: <ChildRearingDeduction />,
    condition: (values: any) => {
      return (
        (values?.future_v2_housing?.house_type == HOUSE_TYPE.own ||
          values?.nextHouseType == NEXT_HOUSE_TYPE.own) &&
        isWithLoan(
          values?.future_v2_housing?.future_v2_housing_self_owned_attributes?.loan_type1,
        ) &&
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes?.purchased_at_year >= 2024
      )
    },
  },
  {
    children: <LoanSplit isFuture />,
    condition: (values: any) => {
      return (
        (values?.future_v2_housing?.house_type == HOUSE_TYPE.own ||
          values?.nextHouseType == NEXT_HOUSE_TYPE.own) &&
        isHousingType2Custom(
          values.future_v2_housing?.future_v2_housing_self_owned_attributes
            ?.housing_type2,
        ) &&
        isWithLoan(
          values?.future_v2_housing?.future_v2_housing_self_owned_attributes?.loan_type1,
        ) &&
        !isMansionType(
          Number(
            values?.future_v2_housing?.future_v2_housing_self_owned_attributes
              ?.house_type,
          ),
        )
      )
    },
  },
]

const DESCRIPTION_TEMPLATES_LOAN_UNSPLITTED: any[] = [
  {
    children: <LoanType isFuture />,
    condition: (values: any) =>
      (values?.future_v2_housing?.house_type == HOUSE_TYPE.own ||
        values?.nextHouseType == NEXT_HOUSE_TYPE.own) &&
      isWithLoan(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes?.loan_type1,
      ) &&
      !isSplitedLoan(values?.future_v2_housing?.future_v2_housing_self_owned_attributes),
  },
]

const DESCRIPTION_TEMPLATES_LOAN_WITH_CALC: any[] = [
  {
    children: <LoanLender isNotSplit isFuture />,
    condition: (values: any) =>
      (values?.future_v2_housing?.house_type == HOUSE_TYPE.own ||
        values?.nextHouseType == NEXT_HOUSE_TYPE.own) &&
      isWithLoan(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes?.loan_type1,
      ) &&
      !isSplitedLoan(values?.future_v2_housing?.future_v2_housing_self_owned_attributes),
  },

  // ===================================== 返済計画表なし
  {
    children: <RepaymentAmountDetail isFuture />,
    condition: (values: any) =>
      (values?.future_v2_housing?.house_type == HOUSE_TYPE.own ||
        values?.nextHouseType == NEXT_HOUSE_TYPE.own) &&
      isWithLoan(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes?.loan_type1,
      ) &&
      !isWithChartLoan(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes
          ?.future_v2_housing_loans_attributes?.[0]?.repayment_chart,
      ) &&
      !isSplitedLoan(values?.future_v2_housing?.future_v2_housing_self_owned_attributes),
  },
  {
    children: <InterestRates isFuture />,
    condition: (values: any) =>
      (values?.future_v2_housing?.house_type == HOUSE_TYPE.own ||
        values?.nextHouseType == NEXT_HOUSE_TYPE.own) &&
      isWithLoan(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes?.loan_type1,
      ) &&
      !isWithChartLoan(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes
          ?.future_v2_housing_loans_attributes?.[0]?.repayment_chart,
      ) &&
      !isSplitedLoan(values?.future_v2_housing?.future_v2_housing_self_owned_attributes),
  },

  {
    children: <LoanDeduction isFuture />,
    condition: (values: any) =>
      (values?.future_v2_housing?.house_type == HOUSE_TYPE.own ||
        values?.nextHouseType == NEXT_HOUSE_TYPE.own) &&
      isWithLoan(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes?.loan_type1,
      ) &&
      !isSplitedLoan(values?.future_v2_housing?.future_v2_housing_self_owned_attributes),
  },
]

const DESCRIPTION_TEMPLATES_LOAN_PAIR_WITH_CALC: any[] = [
  // ====================================== 以下返済計画表なし
  {
    children: <RepaymentAmountDetail isFuture isSpouse />,
    condition: (values: any) =>
      (values?.future_v2_housing?.house_type == HOUSE_TYPE.own ||
        values?.nextHouseType == NEXT_HOUSE_TYPE.own) &&
      !isWithChartLoan(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes
          ?.future_v2_housing_loans_attributes?.[0]?.repayment_chart,
      ) &&
      !isSplitedLoan(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes,
      ) &&
      isPairLoan(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes
          ?.future_v2_housing_loans_attributes?.[0]?.loan_type,
      ),
  },
  {
    children: <InterestRates isFuture isSpouse />,
    condition: (values: any) =>
      (values?.future_v2_housing?.house_type == HOUSE_TYPE.own ||
        values?.nextHouseType == NEXT_HOUSE_TYPE.own) &&
      !isWithChartLoan(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes
          ?.future_v2_housing_loans_attributes?.[0]?.repayment_chart,
      ) &&
      !isSplitedLoan(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes,
      ) &&
      isPairLoan(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes
          ?.future_v2_housing_loans_attributes?.[0]?.loan_type,
      ),
  },
  {
    children: <LoanDeduction isFuture isSpouse />,
    footer: <AbountLoanDeduction />,
    condition: (values: any) =>
      (values?.future_v2_housing?.house_type == HOUSE_TYPE.own ||
        values?.nextHouseType == NEXT_HOUSE_TYPE.own) &&
      !isSplitedLoan(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes,
      ) &&
      isPairLoan(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes
          ?.future_v2_housing_loans_attributes?.[0]?.loan_type,
      ),
  },
]

const DESCRIPTION_TEMPLATES_LOAN_LAND: any[] = [
  {
    children: <LoanType isFuture />,
    condition: (values: any) => {
      return (
        (values?.future_v2_housing?.house_type == HOUSE_TYPE.own ||
          values?.nextHouseType == NEXT_HOUSE_TYPE.own) &&
        isSplitedLoan(values?.future_v2_housing?.future_v2_housing_self_owned_attributes)
      )
    },
  },
  {
    children: <LoanLender isFuture />,
    condition: (values: any) =>
      (values?.future_v2_housing?.house_type == HOUSE_TYPE.own ||
        values?.nextHouseType == NEXT_HOUSE_TYPE.own) &&
      isSplitedLoan(values?.future_v2_housing?.future_v2_housing_self_owned_attributes),
  },
]

const DESCRIPTION_TEMPLATES_LOAN_BUILDING: any[] = [
  {
    children: <LoanType isFuture isBuilding />,
    condition: (values: any) =>
      (values?.future_v2_housing?.house_type == HOUSE_TYPE.own ||
        values?.nextHouseType == NEXT_HOUSE_TYPE.own) &&
      isSplitedLoan(values?.future_v2_housing?.future_v2_housing_self_owned_attributes),
  },
  {
    children: <LoanLender isFuture isBuilding />,
    condition: (values: any) =>
      (values?.future_v2_housing?.house_type == HOUSE_TYPE.own ||
        values?.nextHouseType == NEXT_HOUSE_TYPE.own) &&
      isSplitedLoan(values?.future_v2_housing?.future_v2_housing_self_owned_attributes),
  },
]

const DESCRIPTION_TEMPLATES_LOAN_LAND_WITH_CALC: any[] = [
  // ===================================== 返済計画表なし
  {
    children: <RepaymentAmountDetail isFuture />,
    condition: (values: any) =>
      (values?.future_v2_housing?.house_type == HOUSE_TYPE.own ||
        values?.nextHouseType == NEXT_HOUSE_TYPE.own) &&
      isWithLoan(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes?.loan_type1,
      ) &&
      isSplitedLoan(values?.future_v2_housing?.future_v2_housing_self_owned_attributes) &&
      !isWithChartLoan(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes
          ?.future_v2_housing_loans_attributes?.[0]?.repayment_chart,
      ),
  },
  {
    children: <InterestRates isFuture />,
    condition: (values: any) =>
      (values?.future_v2_housing?.house_type == HOUSE_TYPE.own ||
        values?.nextHouseType == NEXT_HOUSE_TYPE.own) &&
      isWithLoan(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes?.loan_type1,
      ) &&
      isSplitedLoan(values?.future_v2_housing?.future_v2_housing_self_owned_attributes) &&
      !isWithChartLoan(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes
          ?.future_v2_housing_loans_attributes?.[0]?.repayment_chart,
      ),
  },

  {
    children: <LoanDeduction isFuture />,
    condition: (values: any) =>
      (values?.future_v2_housing?.house_type == HOUSE_TYPE.own ||
        values?.nextHouseType == NEXT_HOUSE_TYPE.own) &&
      isSplitedLoan(values?.future_v2_housing?.future_v2_housing_self_owned_attributes) &&
      isWithLoan(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes?.loan_type1,
      ),
  },
]

const DESCRIPTION_TEMPLATES_LOAN_LAND_PAIR_WITH_CALC: any[] = [
  // ====================================== 以下返済計画表なし
  {
    children: <RepaymentAmountDetail isFuture isSpouse />,
    condition: (values: any) =>
      (values?.future_v2_housing?.house_type == HOUSE_TYPE.own ||
        values?.nextHouseType == NEXT_HOUSE_TYPE.own) &&
      !isWithChartLoan(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes
          ?.future_v2_housing_loans_attributes?.[0]?.repayment_chart,
      ) &&
      isSplitedLoan(values?.future_v2_housing?.future_v2_housing_self_owned_attributes) &&
      isPairLoan(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes
          ?.future_v2_housing_loans_attributes?.[0]?.loan_type,
      ),
  },
  {
    children: <InterestRates isFuture isSpouse />,
    condition: (values: any) =>
      (values?.future_v2_housing?.house_type == HOUSE_TYPE.own ||
        values?.nextHouseType == NEXT_HOUSE_TYPE.own) &&
      !isWithChartLoan(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes
          ?.future_v2_housing_loans_attributes?.[0]?.repayment_chart,
      ) &&
      isSplitedLoan(values?.future_v2_housing?.future_v2_housing_self_owned_attributes) &&
      isPairLoan(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes
          ?.future_v2_housing_loans_attributes?.[0]?.loan_type,
      ),
  },
  {
    children: <LoanDeduction isFuture isSpouse />,
    footer: <AbountLoanDeduction />,
    condition: (values: any) =>
      (values?.future_v2_housing?.house_type == HOUSE_TYPE.own ||
        values?.nextHouseType == NEXT_HOUSE_TYPE.own) &&
      isSplitedLoan(values?.future_v2_housing?.future_v2_housing_self_owned_attributes) &&
      isPairLoan(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes
          ?.future_v2_housing_loans_attributes?.[0]?.loan_type,
      ),
  },
]

const DESCRIPTION_TEMPLATES_LOAN_BUILDING_WITH_CALC: any[] = [
  // ====================================== 以下返済計画なし
  {
    children: <RepaymentAmountDetail isFuture isBuilding />,
    condition: (values: any) =>
      (values?.future_v2_housing?.house_type == HOUSE_TYPE.own ||
        values?.nextHouseType == NEXT_HOUSE_TYPE.own) &&
      isSplitedLoan(values?.future_v2_housing?.future_v2_housing_self_owned_attributes),
  },
  {
    children: <InterestRates isFuture isBuilding />,
    condition: (values: any) =>
      (values?.future_v2_housing?.house_type == HOUSE_TYPE.own ||
        values?.nextHouseType == NEXT_HOUSE_TYPE.own) &&
      isSplitedLoan(values?.future_v2_housing?.future_v2_housing_self_owned_attributes),
  },
  {
    children: <LoanDeduction isFuture isBuilding />,
    footer: <AbountLoanDeduction />,
    condition: (values: any) =>
      (values?.future_v2_housing?.house_type == HOUSE_TYPE.own ||
        values?.nextHouseType == NEXT_HOUSE_TYPE.own) &&
      isSplitedLoan(values?.future_v2_housing?.future_v2_housing_self_owned_attributes),
  },
]

const DESCRIPTION_TEMPLATES_LOAN_BUILDING_PAIR_WITH_CALC: any[] = [
  // ====================================== 以下返済計画表なし
  {
    children: <RepaymentAmountDetail isFuture isSpouse isBuilding />,
    condition: (values: any) =>
      (values?.future_v2_housing?.house_type == HOUSE_TYPE.own ||
        values?.nextHouseType == NEXT_HOUSE_TYPE.own) &&
      !isWithChartLoan(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes
          ?.future_v2_housing_loans_attributes?.[2]?.repayment_chart,
      ) &&
      isSplitedLoan(values?.future_v2_housing?.future_v2_housing_self_owned_attributes) &&
      isPairLoan(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes
          ?.future_v2_housing_loans_attributes?.[2]?.loan_type,
      ),
  },
  {
    children: <InterestRates isFuture isSpouse isBuilding />,
    condition: (values: any) =>
      (values?.future_v2_housing?.house_type == HOUSE_TYPE.own ||
        values?.nextHouseType == NEXT_HOUSE_TYPE.own) &&
      !isWithChartLoan(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes
          ?.future_v2_housing_loans_attributes?.[2]?.repayment_chart,
      ) &&
      isSplitedLoan(values?.future_v2_housing?.future_v2_housing_self_owned_attributes) &&
      isPairLoan(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes
          ?.future_v2_housing_loans_attributes?.[2]?.loan_type,
      ),
  },
  {
    children: <LoanDeduction isFuture isSpouse isBuilding />,
    footer: <AbountLoanDeduction />,
    condition: (values: any) =>
      (values?.future_v2_housing?.house_type == HOUSE_TYPE.own ||
        values?.nextHouseType == NEXT_HOUSE_TYPE.own) &&
      isSplitedLoan(values?.future_v2_housing?.future_v2_housing_self_owned_attributes) &&
      isPairLoan(
        values?.future_v2_housing?.future_v2_housing_self_owned_attributes
          ?.future_v2_housing_loans_attributes?.[2]?.loan_type,
      ),
  },
]

const calculator: Decorator<any> = createDecorator(
  {
    field: LOAN_CALCULATION_FLAT_CHANGE_BUILDING_FIELDS,
    updates: {
      'future_v2_housing.future_v2_housing_self_owned_attributes.future_v2_housing_loans_attributes[3]future_v2_housing_loan_calculation_attributes.started_borrowing_amount_manyen': (
        _,
        allValues: any,
      ) => {
        if (
          !isFlatLoan(
            allValues.future_v2_housing.future_v2_housing_self_owned_attributes
              ?.future_v2_housing_loans_attributes?.[2]?.loan_lender,
          )
        )
          return allValues.future_v2_housing.future_v2_housing_self_owned_attributes
            .future_v2_housing_loans_attributes[3]
            ?.future_v2_housing_loan_calculation_attributes
            ?.started_borrowing_amount_manyen

        const totalAmount = allValues.started_borrowing_total_amount_building_manyen ?? 0
        const flatAmount =
          allValues.future_v2_housing?.future_v2_housing_self_owned_attributes
            ?.future_v2_housing_loans_attributes?.[2]
            ?.future_v2_housing_loan_calculation_attributes
            ?.started_borrowing_amount_manyen ?? 0

        return (
          castNumberWithoutOperator(totalAmount) - castNumberWithoutOperator(flatAmount)
        )
      },
    },
  },

  {
    field: LOAN_CALCULATION_FLAT_CHANGE_LAND_FIELDS,
    updates: {
      'future_v2_housing.future_v2_housing_self_owned_attributes.future_v2_housing_loans_attributes[1]future_v2_housing_loan_calculation_attributes.started_borrowing_amount_manyen': (
        _,
        allValues: any,
      ) => {
        if (
          !isFlatLoan(
            allValues.future_v2_housing.future_v2_housing_self_owned_attributes
              ?.future_v2_housing_loans_attributes?.[0]?.loan_lender,
          )
        )
          return allValues.future_v2_housing.future_v2_housing_self_owned_attributes
            .future_v2_housing_loans_attributes[1]
            ?.future_v2_housing_loan_calculation_attributes
            ?.started_borrowing_amount_manyen

        const totalAmount = allValues.started_borrowing_total_amount_land_manyen ?? 0
        const flatAmount =
          allValues.future_v2_housing?.future_v2_housing_self_owned_attributes
            ?.future_v2_housing_loans_attributes?.[0]
            ?.future_v2_housing_loan_calculation_attributes
            ?.started_borrowing_amount_manyen ?? 0

        return (
          castNumberWithoutOperator(totalAmount) - castNumberWithoutOperator(flatAmount)
        )
      },
    },
  },
)

interface FutureV2HousingCreateModalFormProps {
  /** 紐づく売却住居ID */
  targetHousingSaleId?: number
  /** 紐づく賃貸化住居ID */
  targetHousingLendId?: number
  /** true: オープン, false: クローズ */
  isOpen: boolean
  /** true:別宅 , false:別宅以外 */
  isAnotherHouse?: boolean
  /** 時系列一番最後の住居  */
  prevHousing: FutureV2Housing | InputHousing | undefined
  /** rent: 賃貸, purchase: 戸建て, other: その他  */
  nextHouseType?: NextHouseType
  /** 購入・賃貸契約開始年齢 */
  nextScheduledAtAge: number | undefined | null
  /** モーダルクローズメソッド */
  handleClose: () => void
  /** 登録完了時コールバックメソッド */
  completedCallback: () => void
  /** 賃貸段階設定完了後 -> 購入以降時時コールバックメソッド */
  continueToPurchaseCallback: (nextHouseValues: {
    nextHouseType: NextHouseType
    nextScheduledAtAge: number | undefined | null
    prevHousing?: FutureV2Housing
  }) => void
}

const FutureV2HousingCreateModalForm: FC<FutureV2HousingCreateModalFormProps> = (
  props,
) => {
  const dispatch = useDispatch()
  const {
    targetHousingSaleId,
    targetHousingLendId,
    isOpen,
    isAnotherHouse,
    prevHousing,
    nextHouseType,
    nextScheduledAtAge,
    handleClose,
    completedCallback,
    continueToPurchaseCallback,
  } = props

  const [isRentLastAgeModalOpen, setIsRentLastAgeModalOpen] = useState<boolean>(false)

  const { futureBaseId } = useSelector((state: RootState) => state.futureBases)
  const { futureItems } = useSelector((state: RootState) => state.accounts)
  const { is_married, person_current_age } = futureItems?.family_info || {}

  const futureMarriage = useSelector(
    (state: RootState) => state.accounts.futureItems?.future_marriages,
  )

  const futureHousingSales = useSelector(
    (state: RootState) => state.accounts.futureItems?.future_housing_sales,
  )
  const futureLendHousings = useSelector(
    (state: RootState) => state.accounts.futureItems?.future_lend_housings,
  )

  const isLoadignAccounts = useSelector((state: RootState) => state.accounts.isLoading)

  const hasSpouse = useMemo(() => is_married || !_isNull(futureMarriage), [
    is_married,
    futureMarriage,
  ])

  const prevHousingModelType = useMemo(() => {
    if (!prevHousing && !isAnotherHouse) {
      return 'SimpleHousing'
    }
    return prevHousing?.model_type === 'InputHousing' ? 'InputHousing' : 'FutureV2Housing'
  }, [prevHousing])

  const prevHousingId = !!prevHousing ? prevHousing.id : null

  const targetAge = Number(nextScheduledAtAge)
  const targetYear =
    THIS_YEAR_NUMBER +
    (castNumberWithoutOperator(nextScheduledAtAge) - Number(person_current_age))

  const { getContextValue } = useDescriptionTemplates(
    { nextHouseType },
    DESCRIPTION_TEMPLATES,
  )

  const handleSubmit = (values: any) => {
    const { future_v2_housing } = values

    // MEMO: 売却住宅に住み替え先のデータ(ID)を紐づける処理
    const updateFutureHousingSaleFunc = (id: any, callback: () => any, resolve?: any) => {
      const targetFutureHousingSale = _find(
        futureHousingSales,
        (item) => item.id == targetHousingSaleId,
      )

      const newValues = {
        id: targetFutureHousingSale?.id,
        future_housing_sale: {
          ...targetFutureHousingSale,
          future_v2_housing_id: id,
        },
      }

      return dispatch(
        updateFutureHousingSaleV2(newValues, targetHousingSaleId!, () => {
          callback()
        }),
      )
    }

    // MEMO: 賃貸化住宅に住み替え先のデータ(ID)を紐づける処理
    const updateFutureLendHousingFunc = (id: number, callback: () => void) => {
      const targetFutureHousingLend = _find(
        futureLendHousings,
        (item) => item.id == targetHousingLendId,
      )!

      const newValues = {
        future_base_id: futureBaseId,
        id: targetFutureHousingLend.id,
        future_lend_housing: {
          ...targetFutureHousingLend,
          future_v2_housing_id: id,
        },
      }

      return dispatch(
        updateFutureLendHousingV2(newValues, targetFutureHousingLend.id, () => {
          callback()
        }),
      )
    }

    const createFutureV2HousingOtherFunc = () => {
      const { future_v2_housing_other_attributes: otherAttributes } = future_v2_housing

      const newOtherValues = {
        future_v2_housing: {
          prev_housing_id: prevHousingId,
          prev_housing_type: prevHousingModelType,
          scheduled_at: convertDateYear(targetYear),
          house_type: HOUSE_TYPE.other,
          future_v2_housing_other_attributes: {
            ...buildHousingOtherSubmitValues(otherAttributes as any),
          },
        },
      }

      if (!!targetHousingSaleId || !!targetHousingLendId) {
        return new Promise((resolve: any) => {
          dispatch(
            createFutureV2HousingOtherV2(
              {
                account: {
                  future_v2_housings_attributes: [newOtherValues.future_v2_housing],
                },
              },
              (prevHousing) =>
                !!targetHousingSaleId
                  ? updateFutureHousingSaleFunc(
                      prevHousing.id!,
                      completedCallback,
                      resolve,
                    )
                  : updateFutureLendHousingFunc(prevHousing.id!, completedCallback),
              resolve,
            ),
          )
        })
      }

      return new Promise((resolve: any) => {
        dispatch(
          createFutureV2HousingOtherV2(
            {
              account: {
                future_v2_housings_attributes: [newOtherValues.future_v2_housing],
              },
            },
            () => completedCallback(),
            resolve,
          ),
        )
      })
    }

    const createFutureV2HousingRentFunc = () => {
      const { next_house_plan, is_ready_for_submit, future_v2_housing } = values as any
      const { future_v2_housing_rents_attributes: rentsAttributes } = future_v2_housing

      const isNextOwnType = next_house_plan == NEXT_HOUSE_TYPE.own

      const lastRent = _last(rentsAttributes as FutureV2HousingRent[])

      if (
        !isNextOwnType &&
        !is_ready_for_submit &&
        Number(lastRent?.living_end_at_age) !== LAST_AGE
      ) {
        values.is_ready_for_submit = true
        return setIsRentLastAgeModalOpen(!isRentLastAgeModalOpen)
      }

      const targetCallback = (prevHousing?: FutureV2Housing) => {
        if (isNextOwnType) {
          setIsRentLastAgeModalOpen(false)
          continueToPurchaseCallback({
            nextHouseType: NEXT_HOUSE_TYPE.own as NextHouseType,
            nextScheduledAtAge: Number(lastRent?.living_end_at_age),
            prevHousing,
          })
        } else {
          setIsRentLastAgeModalOpen(false)
          completedCallback()
        }
      }

      const newValuesWithRent = {
        future_base_id: futureBaseId,
        future_v2_housing: {
          prev_housing_id: prevHousingId,
          prev_housing_type: prevHousingModelType,
          scheduled_at: convertDateYear(targetYear),
          house_type: HOUSE_TYPE.rent,
          future_v2_housing_rents_attributes: [
            ...buildHousingRentSubmitValues(rentsAttributes),
          ],
        },
      }

      if (!!targetHousingSaleId || !!targetHousingLendId) {
        return dispatch(
          createFutureV2HousingRentV2(
            {
              account: {
                future_v2_housings_attributes: [newValuesWithRent.future_v2_housing],
              },
            },
            (prevHousing) => {
              return !!targetHousingSaleId
                ? updateFutureHousingSaleFunc(prevHousing?.id!, () =>
                    targetCallback(prevHousing),
                  )
                : updateFutureLendHousingFunc(prevHousing?.id!, () =>
                    targetCallback(prevHousing),
                  )
            },
          ),
        )
      }
      return new Promise((resolve: any) => {
        dispatch(
          createFutureV2HousingRentV2(
            {
              account: {
                future_v2_housings_attributes: [newValuesWithRent.future_v2_housing],
              },
            },
            (prevHousing) => targetCallback(prevHousing),
            resolve,
          ),
        )
      })
    }

    const createFutureV2HousingSelfOwnedFunc = () => {
      const {
        future_v2_housing_self_owned_attributes: selfOwnedAttributes,
      } = future_v2_housing
      selfOwnedAttributes.purchased_at = convertDateYear(targetYear)
      selfOwnedAttributes.purchased_at_year = targetYear

      if (!!selfOwnedAttributes.property_price_manyen) {
        selfOwnedAttributes.property_price_manyen = castNumberWithoutOperator(
          selfOwnedAttributes.property_price_manyen,
        )
      }

      if (!!selfOwnedAttributes.property_price_land_manyen) {
        selfOwnedAttributes.property_price_land_manyen = castNumberWithoutOperator(
          selfOwnedAttributes.property_price_land_manyen,
        )
      }

      if (!!selfOwnedAttributes.property_price_building_manyen) {
        selfOwnedAttributes.property_price_building_manyen = castNumberWithoutOperator(
          selfOwnedAttributes.property_price_building_manyen,
        )
      }

      const newValuesWithSelfOwned = {
        future_base_id: futureBaseId,
        future_v2_housing: {
          prev_housing_id: prevHousingId,
          prev_housing_type: prevHousingModelType,
          scheduled_at: convertDateYear(targetYear),
          house_type: HOUSE_TYPE.own,
          future_v2_housing_self_owned_attributes: {
            ...buildHousingSelfOwnedSubmitValues(selfOwnedAttributes as any),
            purchased_at: convertDateYear(targetYear),
          },
        },
      }

      if (!!targetHousingSaleId || !!targetHousingLendId) {
        return new Promise((resolve: any) => {
          dispatch(
            createFutureV2HousingSelfOwnedV2(
              {
                account: {
                  future_v2_housings_attributes: [
                    newValuesWithSelfOwned.future_v2_housing,
                  ],
                },
              },
              (prevHousing) =>
                !!targetHousingSaleId
                  ? updateFutureHousingSaleFunc(
                      prevHousing.id!,
                      completedCallback,
                      resolve,
                    )
                  : updateFutureLendHousingFunc(prevHousing.id!, completedCallback),
              resolve,
            ),
          )
        })
      }

      return new Promise((resolve: any) => {
        dispatch(
          createFutureV2HousingSelfOwnedV2(
            {
              account: {
                future_v2_housings_attributes: [newValuesWithSelfOwned.future_v2_housing],
              },
            },
            () => completedCallback(),
            resolve,
          ),
        )
      })
    }

    if (
      nextHouseType == NEXT_HOUSE_TYPE.other ||
      future_v2_housing?.house_type == HOUSE_TYPE.other
    ) {
      createFutureV2HousingOtherFunc()
    } else if (
      nextHouseType == NEXT_HOUSE_TYPE.rent ||
      future_v2_housing?.house_type == HOUSE_TYPE.rent
    ) {
      createFutureV2HousingRentFunc()
    } else {
      createFutureV2HousingSelfOwnedFunc()
    }
  }

  const initialValues = useMemo(() => {
    return {
      ...createInitialValues,
      future_v2_housing: {
        ...createInitialValues.future_v2_housing,
        future_v2_housing_rents_attributes: [],
      },
      nextHouseType,
      is_ready_for_submit: false,
    }
  }, [])

  const { familyInfoV2 } = useSelector((state: RootState) => state.familyStructures)
  const familyAgesObject = useMemo(
    () => ({
      person_current_age: familyInfoV2?.person_age,
      spouse_current_age: familyInfoV2?.spouse_age,
    }),
    [familyInfoV2],
  )

  return (
    <Modal
      className="w-[95%] xl:w-[1000px] cursor-default"
      isOpen={isOpen}
      isDisableBackdropClick
      onClose={handleClose}
      isLoading={isLoadignAccounts}
    >
      {!nextHouseType &&
        (!!targetHousingSaleId || !!targetHousingLendId ? (
          <CircleTitle className="pb-[32px]" title="住み替え先を設定する" />
        ) : (
          <CircleTitle className="pb-[32px]" title="住居の購入予定を設定する" />
        ))}

      <Form
        onSubmit={handleSubmit}
        mutators={{ ...arrayMutators }}
        decorators={[calculator]}
        initialValues={initialValues}
        render={({ handleSubmit, values, errors, submitErrors, form: { change } }) => (
          <form className="pb-[30px]" onSubmit={handleSubmit}>
            <DescriptionContext.Provider
              value={{
                ...getContextValue(),
                age: familyAgesObject.person_current_age,
                hasSpouse,
                isRentLastAgeModalOpen,
                setIsRentLastAgeModalOpen,
                prevHousing,
                targetAge,
              }}
            >
              <Grid className="grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px]">
                {DESCRIPTION_TEMPLATES.map(
                  (item, index) =>
                    ((item.condition && item.condition(values)) || !item.condition) && (
                      <React.Fragment key={index}>{item.children}</React.Fragment>
                    ),
                )}
              </Grid>
              {(values?.future_v2_housing?.house_type == HOUSE_TYPE.other ||
                values?.nextHouseType == NEXT_HOUSE_TYPE.other) && (
                <>
                  <CircleTitle
                    className="pt-30 pb-[32px]"
                    title="その他の住宅に住むためにかかる費用を想定する"
                  />
                  <Grid className="grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px]">
                    {DESCRIPTION_TEMPLATES_OTHER.map(
                      (item, index) =>
                        ((item.condition && item.condition(values)) ||
                          !item.condition) && (
                          <React.Fragment key={index}>{item.children}</React.Fragment>
                        ),
                    )}
                  </Grid>
                </>
              )}

              {(values?.future_v2_housing?.house_type == HOUSE_TYPE.rent ||
                values?.nextHouseType == NEXT_HOUSE_TYPE.rent) && (
                <>
                  <CircleTitle
                    className="pt-30 pb-[32px]"
                    title="賃貸住居の費用について"
                  />
                  <Grid className="grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px]">
                    {DESCRIPTION_TEMPLATES_RENT.map(
                      (item, index) =>
                        ((item.condition && item.condition(values)) ||
                          !item.condition) && (
                          <React.Fragment key={index}>{item.children}</React.Fragment>
                        ),
                    )}
                  </Grid>
                </>
              )}
              {(values?.future_v2_housing?.house_type == HOUSE_TYPE.own ||
                values?.nextHouseType == NEXT_HOUSE_TYPE.own) && (
                <>
                  <CircleTitle
                    className="pt-30 pb-[32px] md:text-[19px]"
                    title="買い替え先の住居の費用について"
                  />
                  <Grid className="grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px]">
                    {DESCRIPTION_TEMPLATES_OWN.map(
                      (item, index) =>
                        ((item.condition && item.condition(values)) ||
                          !item.condition) && (
                          <React.Fragment key={index}>{item.children}</React.Fragment>
                        ),
                    )}
                  </Grid>
                </>
              )}
              <Grid className="grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px] pb-[16px]">
                {DESCRIPTION_TEMPLATES5.map(
                  (item, index) =>
                    ((item.condition && item.condition(values)) || !item.condition) && (
                      <React.Fragment key={index}>{item.children}</React.Fragment>
                    ),
                )}
              </Grid>

              <Grid className="grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px] pb-[16px]">
                {DESCRIPTION_TEMPLATES_LOAN_UNSPLITTED.map(
                  (item, index) =>
                    ((item.condition && item.condition(values)) || !item.condition) && (
                      <React.Fragment key={index}>{item.children}</React.Fragment>
                    ),
                )}
              </Grid>

              {(values?.future_v2_housing?.house_type == HOUSE_TYPE.own ||
                values?.nextHouseType == NEXT_HOUSE_TYPE.own) &&
                !isWithChartLoan(
                  values?.future_v2_housing?.future_v2_housing_self_owned_attributes
                    ?.future_v2_housing_loans_attributes?.[0]?.repayment_chart,
                ) &&
                !isSplitedLoan(
                  values?.future_v2_housing?.future_v2_housing_self_owned_attributes,
                ) && (
                  <>
                    {!isPairLoan(
                      values?.future_v2_housing?.future_v2_housing_self_owned_attributes
                        ?.future_v2_housing_loans_attributes?.[0]?.loan_type,
                    ) ? (
                      <>
                        <Grid className="grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px]">
                          {DESCRIPTION_TEMPLATES_LOAN_WITH_CALC.map(
                            (item, index) =>
                              ((item.condition && item.condition(values)) ||
                                !item.condition) && (
                                <React.Fragment key={index}>
                                  {item.children}
                                </React.Fragment>
                              ),
                          )}
                        </Grid>
                      </>
                    ) : (
                      <>
                        <CircleTitle
                          className="pt-40 pb-20"
                          title="ご本人のローンについて"
                        />
                        <Grid className="grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px]">
                          {DESCRIPTION_TEMPLATES_LOAN_WITH_CALC.map(
                            (item, index) =>
                              ((item.condition && item.condition(values)) ||
                                !item.condition) && (
                                <React.Fragment key={index}>
                                  {item.children}
                                </React.Fragment>
                              ),
                          )}
                        </Grid>

                        <CircleTitle
                          className="pt-40 pb-20"
                          title="配偶者のローンについて"
                        />
                        <Grid className="grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px]">
                          {DESCRIPTION_TEMPLATES_LOAN_PAIR_WITH_CALC.map(
                            (item, index) =>
                              ((item.condition && item.condition(values)) ||
                                !item.condition) && (
                                <React.Fragment key={index}>
                                  {item.children}
                                </React.Fragment>
                              ),
                          )}
                        </Grid>
                      </>
                    )}
                  </>
                )}

              {(values?.future_v2_housing?.house_type == HOUSE_TYPE.own ||
                values?.nextHouseType == NEXT_HOUSE_TYPE.own) &&
                !isWithChartLoan(
                  values?.future_v2_housing?.future_v2_housing_self_owned_attributes
                    ?.future_v2_housing_loans_attributes?.[0]?.repayment_chart,
                ) &&
                isSplitedLoan(
                  values?.future_v2_housing?.future_v2_housing_self_owned_attributes,
                ) && (
                  <>
                    <CircleTitle className="pt-40 pb-20" title="土地用ローンについて" />
                    <Grid className="grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px]">
                      {DESCRIPTION_TEMPLATES_LOAN_LAND.map(
                        (item, index) =>
                          ((item.condition && item.condition(values)) ||
                            !item.condition) && (
                            <React.Fragment key={index}>{item.children}</React.Fragment>
                          ),
                      )}
                    </Grid>

                    {!isPairLoan(
                      values?.future_v2_housing?.future_v2_housing_self_owned_attributes
                        ?.future_v2_housing_loans_attributes?.[0]?.loan_type,
                    ) ? (
                      <>
                        <Grid className="grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px]">
                          {DESCRIPTION_TEMPLATES_LOAN_LAND_WITH_CALC.map(
                            (item, index) =>
                              ((item.condition && item.condition(values)) ||
                                !item.condition) && (
                                <React.Fragment key={index}>
                                  {item.children}
                                </React.Fragment>
                              ),
                          )}
                        </Grid>
                      </>
                    ) : (
                      <>
                        <CircleTitle
                          className="pt-40 pb-20"
                          title="ご本人の土地用ローンについて"
                        />
                        <Grid className="grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px]">
                          {DESCRIPTION_TEMPLATES_LOAN_LAND_WITH_CALC.map(
                            (item, index) =>
                              ((item.condition && item.condition(values)) ||
                                !item.condition) && (
                                <React.Fragment key={index}>
                                  {item.children}
                                </React.Fragment>
                              ),
                          )}
                        </Grid>
                        <CircleTitle
                          className="pt-40 pb-20"
                          title="配偶者の土地用ローンについて"
                        />
                        <Grid className="grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px]">
                          {DESCRIPTION_TEMPLATES_LOAN_LAND_PAIR_WITH_CALC.map(
                            (item, index) =>
                              ((item.condition && item.condition(values)) ||
                                !item.condition) && (
                                <React.Fragment key={index}>
                                  {item.children}
                                </React.Fragment>
                              ),
                          )}
                        </Grid>
                      </>
                    )}
                  </>
                )}

              {(values?.future_v2_housing?.house_type == HOUSE_TYPE.own ||
                values?.nextHouseType == NEXT_HOUSE_TYPE.own) &&
                !isWithChartLoan(
                  values?.future_v2_housing?.future_v2_housing_self_owned_attributes
                    ?.future_v2_housing_loans_attributes?.[2]?.repayment_chart,
                ) &&
                isSplitedLoan(
                  values?.future_v2_housing?.future_v2_housing_self_owned_attributes,
                ) && (
                  <>
                    <CircleTitle className="pt-40 pb-20" title="建物用ローンについて" />
                    <Grid className="grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px]">
                      {DESCRIPTION_TEMPLATES_LOAN_BUILDING.map(
                        (item, index) =>
                          ((item.condition && item.condition(values)) ||
                            !item.condition) && (
                            <React.Fragment key={index}>{item.children}</React.Fragment>
                          ),
                      )}
                    </Grid>

                    {!isPairLoan(
                      values?.future_v2_housing?.future_v2_housing_self_owned_attributes
                        ?.future_v2_housing_loans_attributes?.[2]?.loan_type,
                    ) ? (
                      <>
                        <Grid className="grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px]">
                          {DESCRIPTION_TEMPLATES_LOAN_BUILDING_WITH_CALC.map(
                            (item, index) =>
                              ((item.condition && item.condition(values)) ||
                                !item.condition) && (
                                <React.Fragment key={index}>
                                  {item.children}
                                </React.Fragment>
                              ),
                          )}
                        </Grid>
                      </>
                    ) : (
                      <>
                        <CircleTitle
                          className="pt-40 pb-20"
                          title="ご本人の建物用ローンについて"
                        />
                        <Grid className="grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px]">
                          {DESCRIPTION_TEMPLATES_LOAN_BUILDING_WITH_CALC.map(
                            (item, index) =>
                              ((item.condition && item.condition(values)) ||
                                !item.condition) && (
                                <React.Fragment key={index}>
                                  {item.children}
                                </React.Fragment>
                              ),
                          )}
                        </Grid>
                        <CircleTitle
                          className="pt-40 pb-20"
                          title="配偶者の建物用ローンについて"
                        />
                        <Grid className="grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px]">
                          {DESCRIPTION_TEMPLATES_LOAN_BUILDING_PAIR_WITH_CALC.map(
                            (item, index) =>
                              ((item.condition && item.condition(values)) ||
                                !item.condition) && (
                                <React.Fragment key={index}>
                                  {item.children}
                                </React.Fragment>
                              ),
                          )}
                        </Grid>
                      </>
                    )}
                  </>
                )}
            </DescriptionContext.Provider>
            <div className="flex justify-center mt-40">
              <ButtonPrimary
                className="h-64 w-246"
                isDisabled={Object.keys(errors).length > 0}
                onClick={() => handleSubmit(values)}
              >
                <Typography
                  className="bottom-2 flex-auto pl-30 relative text-16 text-white"
                  isBold
                >
                  保存する
                </Typography>
                <ChevronRightIcon className="mr-17" />
              </ButtonPrimary>
            </div>
          </form>
        )}
      />
    </Modal>
  )
}

export default FutureV2HousingCreateModalForm
