import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  postInputSalaryCompany,
  postInputSalaryCompanyCalc,
  patchInputSalaryCompany,
} from '@/api/inputs/inputSalaryCompanies'
import { unauthorizedCheck } from '@/containers/accountsSlice'
import {
  InputSalaryCompanyCreateValues,
  InputSalaryCompanyUpdateValues,
  InputSalaryCompanyCalcResponse,
  InputSalaryCompanyCalc,
  InputSalaryCompanyCalcValues,
} from '@/models/inputs/inputSalaryCompaniesModel'
import { AppThunk } from '@/store'

interface InputSalaryCompanyState {
  inputSalaryCompanyCalc: InputSalaryCompanyCalc
  isLoading: boolean
}

const initialState: InputSalaryCompanyState = {
  inputSalaryCompanyCalc: {
    income_hash: {
      0: {},
      1: {},
    },
    severance_pay: {
      0: null,
      1: null,
    },
  },
  isLoading: false,
}

const inputSalaryCompaniesSlice = createSlice({
  name: 'inputSalaryCompanies',
  initialState,
  reducers: {
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    resetInputSalaryCompaniesStore: (state) => {
      state = initialState
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    postInputSalaryCompanyCalcSuccess: (
      state,
      action: PayloadAction<InputSalaryCompanyCalcResponse>,
    ) => {
      state.inputSalaryCompanyCalc = action.payload
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
  },
})

export const {
  requestStart,
  resetInputSalaryCompaniesStore,
  requestSuccess,
  postInputSalaryCompanyCalcSuccess,
  requestFailure,
} = inputSalaryCompaniesSlice.actions
export const inputSalaryCompaniesReducer = inputSalaryCompaniesSlice.reducer

export const createInputSalaryCompany = (
  values: InputSalaryCompanyCreateValues,
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postInputSalaryCompany(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const createInputSalaryCompanyCalc = (
  values: InputSalaryCompanyCalcValues,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postInputSalaryCompanyCalc(values)
    .then((response) => dispatch(postInputSalaryCompanyCalcSuccess(response)))
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const updateInputSalaryCompany = (
  id: number,
  values: InputSalaryCompanyUpdateValues,
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchInputSalaryCompany(id, values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}
