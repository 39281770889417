import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { sortBy as _sortBy } from 'lodash'
import {
  postFutureAssetV2,
  patchFutureAssetV2,
  deleteFutureAssetV2,
} from '@/api/futures/futureAssets'
import { unauthorizedCheck } from '@/containers/accountsSlice'
import { FutureAsset } from '@/models/futures/futureAssetsModel'
import { AppThunk } from '@/store'

interface FutureAssetState {
  futureAssets: FutureAsset[]
  isLoading: boolean
}

const initialState: FutureAssetState = {
  futureAssets: [],
  isLoading: false,
}

const futureAssetsSlice = createSlice({
  name: 'futureAssets',
  initialState,
  reducers: {
    setFutureAssets: (state, action: PayloadAction<FutureAsset[]>) => {
      const futureAssets = _sortBy(action.payload, 'id')
      state.futureAssets = futureAssets.map((asset) => ({
        ...asset,
        asset_annuals: _sortBy(asset.asset_annuals, 'affected_at'),
      }))
      return state
    },
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
  },
})

export const {
  setFutureAssets,
  requestStart,
  requestSuccess,
  requestFailure,
} = futureAssetsSlice.actions
export const futureAssetsReducer = futureAssetsSlice.reducer

export const createFutureAssetV2 = (
  values: any,
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postFutureAssetV2(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const updateFutureAssetV2 = (
  values: any,
  id: number,
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchFutureAssetV2(values, id)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const destroyFutureAssetV2 = (
  values: any,
  id: number,
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  deleteFutureAssetV2(values, id)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}
