import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  deleteInputCustomItem,
  getInputCustomItemV2,
  patchInputCustomItem,
} from '@/api/inputs/inputCustomItems'
import { unauthorizedCheck } from '@/containers/accountsSlice'
import {
  InputCustomItem,
  InputCustomItemCreateValues,
  InputCustomItemDestroyValues,
  InputCustomItemUpdateValues,
} from '@/models/inputs/inputCustomItemsModel'
import { AppThunk } from '@/store'
import { sortBy as _sortBy } from 'lodash'

interface InputCustomItemState {
  inputCustomItems: InputCustomItem[]
  isLoading: boolean
}

const initialState: InputCustomItemState = {
  inputCustomItems: [],
  isLoading: false,
}

const inputCustomItemsSlice = createSlice({
  name: 'inputCustomItems',
  initialState,
  reducers: {
    setInputCustomItems: (state, action: PayloadAction<InputCustomItem[]>) => {
      state.inputCustomItems = _sortBy(action.payload, 'id')
      return state
    },
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    resetInputCustomItemsStore: (state) => {
      state = initialState
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
    resetInputCustomItems: (state) => {
      state.inputCustomItems = initialState.inputCustomItems
      return state
    },
  },
})

export const {
  setInputCustomItems,
  requestStart,
  resetInputCustomItemsStore,
  requestSuccess,
  requestFailure,
  resetInputCustomItems,
} = inputCustomItemsSlice.actions
export const inputCustomItemsReducer = inputCustomItemsSlice.reducer

export const createInputCustomItem = (
  values: InputCustomItemCreateValues | InputCustomItemUpdateValues,
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchInputCustomItem(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const updateInputCustomItem = (
  values: InputCustomItemUpdateValues,
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchInputCustomItem(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const destroyInputCustomItem = (
  values: { params: InputCustomItemDestroyValues },
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  deleteInputCustomItem(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const fetchInputCustomItemV2 = (callback?: () => void): AppThunk => async (
  dispatch,
) => {
  dispatch(requestStart())
  getInputCustomItemV2()
    .then((response: any) => {
      dispatch(setInputCustomItems(response))
      dispatch(requestSuccess())
      callback && callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}
