import React, { FC, CSSProperties } from 'react'
import clsx from 'clsx'

interface TypographyProps {
  /** クラス名 */
  className?: string
  /** スタイル */
  style?: CSSProperties
  /** true: 太字, false: 通常 */
  isBold?: boolean
  /** スタイルのデフォルト設定 */
  styleType?: 'underlinedHeader' | 'underlinedHeaderSmall'
}

const Typography: FC<TypographyProps> = (props) => {
  const { className, style, isBold, children, styleType } = props
  const styleTypeUnderlinedHeader = styleType == 'underlinedHeader'
  const styleTypeUnderlinedHeaderSmall = styleType == 'underlinedHeaderSmall'
  return (
    <p
      className={clsx(`${className || ''}`, {
        ['font-bold']: isBold,
        ['border-b-[0.5px] mb-[10px] pb-[10px]']: styleTypeUnderlinedHeader,
        ['border-b-[0.5px] mb-[10px] pb-[5px] ']: styleTypeUnderlinedHeaderSmall,
      })}
      style={style}
    >
      {children}
    </p>
  )
}

export default Typography
