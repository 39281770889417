import React, { FC, useState, useContext, useEffect, useMemo } from 'react'
import {
  filter as _filter,
  find as _find,
  includes as _includes,
  isEmpty as _isEmpty,
  isNull as _isNull,
} from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import Button from '@/components/v2/atoms/Button'
import Tooltip from '@/components/v2/atoms/Tooltip'
import Typography from '@/components/v2/atoms/Typography'
import SimulationAccordion from '@/templates/v2/simulations/_simulationAccordion'
import SimulationNewButton from '@/templates/v2/simulations/_simulationNewButton'
import FutureEstateInvestmentModalForm from '@/templates/v2/simulations/_futureEstateInvestmentModalForm'

import { RootState } from '@/store'
import {
  convertDepreciation,
  convertMonthlyPeriodCost,
  convertMonthlyPeriodCostWithRent,
  REPAIR_RESERVE_FUND_INCREASE_TYPE,
} from '@/models/commonEstateInvestmentModelFunc'
import {
  convertDateToTwoTypesYearsDateFormat,
  convertLocaleString,
  isNotEmptyValue,
  defaultToEmptyString,
  extractYearOrMonthFromDate,
  THIS_YEAR_NUMBER,
} from '@/models/commonsModelFunc'
import { convertPersonFlg } from '@/models/familyStructuresModelFunc'
import { convertDeclineRate } from '@/models/futures/futureEstateInvestmentDeclineRatesModelFunc'
import {
  SIMULATION_TYPE,
  MANAGEMENT_FEE_TYPE,
  convertBuildingStructureType,
  convertPeriodCost,
  convertRepairReserveFundIncreaseType,
  convertPropertyType,
  PROPERTY_TYPE,
} from '@/models/futures/futureEstateInvestmentsModelFunc'
import {
  convertLoanType,
  convertHasLoanLifeInsuranceContract,
} from '@/models/housingLoansModelFunc'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import { destroyFutureEstateInvestmentV2 } from '@/containers/futures/futureEstateInvestmentsSlice'
import { fetchAccountFutureItems } from '@/containers/accountsSlice'
import { SimulationDetailEditContext } from '@/models/futures/futureBasesModelFunc'
import SimulationItemAccordion from '@/templates/v2/simulations/_simulationItemAccordion'
import HelpCircleIcon from '@/assets/images/v2/help-circle.svg'

interface FutureEstateInvestmentFormProps {
  /** 選択シミュレーションID */
  selectedSimulationId: number[] | undefined
  /** 不動産投資売却シミュレーションID */
  selectedSimulationSaleId: number[] | undefined
  /** 選択メソッド */
  handleSelect: (item: any) => void
  /* FutureItemsを参照するID */
  futureBaseId?: number
  /* 初期化すべきかどうか */
  shouldInitialize?: boolean
  /* 編集を確認したか */
  confirmEdit?: boolean
  /* 編集確認モーダルを展開するメソッド */
  openEditConfirmModal?: () => void
  /** 選択されている節税タイプ */
  selectedTaxSettingType: string | undefined
  /** 節税タイプを変更するメソッド */
  handleTaxSettingSelect: (item: any) => void
}

interface FutureEstateInvestmentTableProps {
  /** アイテム */
  item: any
  /** td要素のクラス */
  tdClassName?: string
}

export const FutureEstateInvestmentTable: FC<FutureEstateInvestmentTableProps> = ({
  item,
  tdClassName,
}) => {
  return (
    <div>
      <Typography styleType="underlinedHeader">物件情報</Typography>
      <table className="w-[100%] mb-[20px] last:mb-0 text-[12px] md:text-[14px] bg-white">
        <tbody>
          <tr className="h-[45px] odd:bg-black-100">
            <td className={`${tdClassName ?? 'w-[50%]'} px-[16px] md:px-[30px]`}>
              <Typography isBold>購入時期</Typography>
            </td>
            <td className="px-[16px] md:px-[30px]">
              <Typography>
                {convertDateToTwoTypesYearsDateFormat(item.purchased_at, 'japaneseYear')}
              </Typography>
            </td>
          </tr>
          <tr className="h-[45px] odd:bg-black-100">
            <td className="px-[16px] md:px-[30px]">
              <Typography isBold>名義人</Typography>
            </td>
            <td className="px-[16px] md:px-[30px]">
              <Typography>{convertPersonFlg(item?.person_flg)}</Typography>
            </td>
          </tr>
          <tr className="h-[45px] odd:bg-black-100">
            <td className="px-[16px] md:px-[30px]">
              <Typography isBold>価格</Typography>
            </td>
            <td className="px-[16px] md:px-[30px]">
              <Typography>
                {convertLocaleString(item?.property_price_manyen)}
                万円
              </Typography>
            </td>
          </tr>
          <tr className="h-[45px] odd:bg-black-100">
            <td className="px-[16px] md:px-[30px]">
              <Typography isBold>物件タイプ</Typography>
            </td>
            <td className="px-[16px] md:px-[30px]">
              <Typography>{convertPropertyType(item?.property_type)}</Typography>
            </td>
          </tr>
          <tr className="h-[45px] odd:bg-black-100">
            <td className="px-[16px] md:px-[30px]">
              <Typography isBold>構造</Typography>
            </td>
            <td className="w-[70%] px-[16px] md:px-[30px]">
              <Typography className="min-w-[max-content]">
                {convertBuildingStructureType(item?.building_structure_type)}
              </Typography>
            </td>
          </tr>
          <tr className="h-[45px] odd:bg-black-100">
            <td className="px-[16px] md:px-[30px]">
              <Typography isBold>築年数</Typography>
            </td>
            <td className="px-[16px] md:px-[30px]">
              <Typography>
                {THIS_YEAR_NUMBER -
                  extractYearOrMonthFromDate(item?.constructed_at, 'year')}
                年
              </Typography>
            </td>
          </tr>
          <tr className="h-[45px] odd:bg-black-100">
            <td className=" px-[16px] md:px-[30px]">
              <Typography isBold>面積</Typography>
            </td>
            <td className="px-[16px] md:px-[30px]">
              <Typography>{convertLocaleString(item?.breadth)}㎡</Typography>
            </td>
          </tr>
        </tbody>
      </table>
      {item.simulation_type === SIMULATION_TYPE.detail && (
        <>
          <Typography styleType="underlinedHeader">収入情報</Typography>

          <table className="w-[100%] mb-[20px] last:mb-0 text-[12px] md:text-[14px] bg-white">
            <tbody>
              <tr className="h-[45px] odd:bg-black-100">
                <td className={`${tdClassName ?? 'w-[50%]'} px-[16px] md:px-[30px]`}>
                  <Typography isBold>賃料収入</Typography>
                </td>
                <td className="px-[16px] md:px-[30px]">
                  <Typography>{convertLocaleString(item?.rent_income)}円/月</Typography>
                </td>
              </tr>

              {item.future_estate_investment_decline_rates.length !== 0 && (
                <tr className="h-[45px] odd:bg-black-100">
                  <td className="px-[16px] md:px-[30px]">
                    <Typography isBold>{'年間下落率'}</Typography>
                  </td>
                  <td className="px-[16px] md:px-[30px]">
                    {item.future_estate_investment_decline_rates?.map(
                      (childItem: any, childIndex: any) => (
                        <Typography
                          key={`future_estate_investment_decline_rates_${childItem.id}`}
                        >
                          {convertDeclineRate(
                            item.future_estate_investment_decline_rates,
                            childIndex,
                          )}
                        </Typography>
                      ),
                    )}
                  </td>
                </tr>
              )}

              {(!_isNull(item?.renewal_fee) ||
                !_isNull(item?.renewal_fee_to_rent_income_rate)) && (
                <tr className="h-[45px] odd:bg-black-100">
                  <td className="px-[16px] md:px-[30px]">
                    <Typography isBold>礼金・更新料</Typography>
                  </td>
                  <td className="px-[16px] md:px-[30px]">
                    <Typography>
                      {isNotEmptyValue(item?.renewal_fee)
                        ? convertMonthlyPeriodCost(
                            item?.renewal_fee_period_months,
                            item?.renewal_fee,
                          )
                        : convertMonthlyPeriodCostWithRent(
                            item?.renewal_fee_period_months,
                            item?.rent_income,
                            item?.renewal_fee_to_rent_income_rate,
                          )}
                    </Typography>
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <Typography styleType="underlinedHeader">支出情報</Typography>
          <table className="w-[100%] mb-[20px] last:mb-0 text-[12px] md:text-[14px]  bg-white">
            <tbody>
              {item?.property_type === PROPERTY_TYPE.division && (
                <>
                  {!_isNull(
                    item?.management_fee_monthly_and_repair_reserve_fund_total,
                  ) && (
                    <tr className="h-[45px] odd:bg-black-100">
                      <td
                        className={`${tdClassName ?? 'w-[50%]'} px-[16px] md:px-[30px]`}
                      >
                        <Typography isBold>管理費・修繕積立金合計</Typography>
                      </td>
                      <td className="px-[16px] md:px-[30px]">
                        <Typography>
                          {convertLocaleString(
                            item?.management_fee_monthly_and_repair_reserve_fund_total,
                          )}
                          円/月
                        </Typography>
                      </td>
                    </tr>
                  )}

                  {!_isNull(item?.management_fee_monthly) &&
                    _isNull(
                      item?.management_fee_monthly_and_repair_reserve_fund_total,
                    ) && (
                      <tr className="h-[45px] odd:bg-black-100">
                        <td
                          className={`${tdClassName ?? 'w-[50%]'} px-[16px] md:px-[30px]`}
                        >
                          <Typography isBold>管理費</Typography>
                        </td>
                        <td className="px-[16px] md:px-[30px]">
                          <Typography>
                            {convertLocaleString(item?.management_fee_monthly)}
                            円/月
                          </Typography>
                        </td>
                      </tr>
                    )}

                  {_isNull(
                    item?.management_fee_monthly_and_repair_reserve_fund_total,
                  ) && (
                    <tr className="h-[45px] odd:bg-black-100">
                      <td className="px-[16px] md:px-[30px]">
                        <Typography isBold>修繕積立金</Typography>
                      </td>
                      <td className="px-[16px] md:px-[30px]">
                        <Typography>
                          {convertLocaleString(item?.repair_reserve_fund)}円/月
                        </Typography>
                      </td>
                    </tr>
                  )}

                  {_isNull(
                    item?.management_fee_monthly_and_repair_reserve_fund_total,
                  ) && (
                    <>
                      <tr className="h-[45px] odd:bg-black-100">
                        <td
                          className={`${tdClassName ?? 'w-[50%]'} px-[16px] md:px-[30px]`}
                        >
                          <Typography isBold>上昇率</Typography>
                        </td>
                        <td className="px-[16px] md:px-[30px]">
                          <Typography>
                            {convertRepairReserveFundIncreaseType(
                              item?.repair_reserve_fund_increase_type,
                            )}
                          </Typography>
                        </td>
                      </tr>

                      {item?.repair_reserve_fund_increase_type !==
                        REPAIR_RESERVE_FUND_INCREASE_TYPE.noSet && (
                        <tr className="h-[45px] odd:bg-black-100">
                          <td
                            className={`${
                              tdClassName ?? 'w-[50%]'
                            } px-[16px] md:px-[30px]`}
                          >
                            <Typography isBold>上昇率設定</Typography>
                          </td>
                          <td className=" px-[16px] md:px-[30px]">
                            {item?.repair_reserve_fund_increase_type ===
                              REPAIR_RESERVE_FUND_INCREASE_TYPE.fixed && (
                              <Typography className="isBold">
                                {`月間${convertLocaleString(
                                  item?.repair_reserve_fund_amount,
                                )}円ずつ`}
                                {`築${
                                  item?.repair_reserve_fund_amount_start ?? ''
                                }年目から`}
                                <br />
                                {`築${
                                  item?.repair_reserve_fund_amount_finish ?? ''
                                }年目まで上昇させる`}
                              </Typography>
                            )}

                            {item?.repair_reserve_fund_increase_type ===
                              REPAIR_RESERVE_FUND_INCREASE_TYPE.change && (
                              <Typography className="isBold">
                                {`築${
                                  item?.repair_reserve_fund_increase_start ?? ''
                                }年目から`}
                                {`${item?.repair_reserve_fund_increase_span ?? ''}年毎に`}
                                <br />
                                {`築${
                                  item?.repair_reserve_fund_increase_finish ?? ''
                                }年目まで`}
                                {`${
                                  item?.repair_reserve_fund_increase_percent ?? ''
                                }％に設定`}
                              </Typography>
                            )}
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </>
              )}

              <tr className="h-[45px] odd:bg-black-100">
                <td className={`${tdClassName ?? 'w-[50%]'} px-[16px] md:px-[30px]`}>
                  <Typography className="min-w-[max-contnet]" isBold>
                    固定資産税・都市計画税
                  </Typography>
                </td>
                <td className="px-[16px] md:px-[30px]">
                  <Typography>{convertLocaleString(item?.tax)}円/年</Typography>
                </td>
              </tr>

              {item?.management_fee_type === MANAGEMENT_FEE_TYPE.percent && (
                <tr className="h-[45px] odd:bg-black-100">
                  <td className={`${tdClassName ?? 'w-[50%]'} px-[16px] md:px-[30px]`}>
                    <Typography isBold>
                      {item?.property_type === PROPERTY_TYPE.division
                        ? '賃貸管理手数料'
                        : '建物管理費(PMフィー)'}
                    </Typography>
                  </td>
                  <td className="px-[16px] md:px-[30px]">
                    <Typography>
                      賃料の
                      {defaultToEmptyString(item?.management_fee_rate_percent)}%
                    </Typography>
                  </td>
                </tr>
              )}

              {item?.management_fee_type === MANAGEMENT_FEE_TYPE.yen && (
                <tr className="h-[45px] odd:bg-black-100">
                  <td className={`${tdClassName ?? 'w-[50%]'} px-[16px] md:px-[30px]`}>
                    <Typography isBold>
                      {item?.property_type === PROPERTY_TYPE.division
                        ? '賃貸管理手数料'
                        : '建物管理費(PMフィー)'}
                    </Typography>
                  </td>
                  <td className="px-[16px] md:px-[30px]">
                    <Typography>
                      {`${convertLocaleString(item?.management_fee_amount)}円/年`}
                    </Typography>
                  </td>
                </tr>
              )}

              <tr className="h-[45px] odd:bg-black-100">
                <td className={`${tdClassName ?? 'w-[50%]'} px-[16px] md:px-[30px]`}>
                  <Typography isBold>
                    {item?.property_type === PROPERTY_TYPE.division
                      ? '設備・原状回復費用'
                      : 'リフォーム費'}
                  </Typography>
                </td>
                <td className=" px-[16px] md:px-[30px]">
                  <Typography>
                    {convertPeriodCost(item?.repair_cost_span_years, item?.repair_cost)}
                  </Typography>
                </td>
              </tr>

              <tr className="h-[45px] odd:bg-black-100">
                <td className={`${tdClassName ?? 'w-[50%]'} px-[16px] md:px-[30px]`}>
                  <Typography isBold>設備減価償却費</Typography>
                </td>
                <td className="px-[16px] md:px-[30px]">
                  <Typography>
                    {convertDepreciation(
                      item?.equipment_durable_years,
                      item?.equipment_depreciation,
                      extractYearOrMonthFromDate(item?.constructed_at, 'year'),
                    )}
                  </Typography>
                </td>
              </tr>

              <tr className="h-[45px] odd:bg-black-100">
                <td className={`${tdClassName ?? 'w-[50%]'} px-[16px] md:px-[30px]`}>
                  <Typography isBold>建物減価償却費</Typography>
                </td>
                <td className="px-[16px] md:px-[30px]">
                  <Typography>
                    {convertDepreciation(
                      item?.building_durable_years,
                      item?.building_depreciation,
                      extractYearOrMonthFromDate(item?.constructed_at, 'year'),
                    )}
                  </Typography>
                </td>
              </tr>

              <tr className="h-[45px] odd:bg-black-100">
                <td className={`${tdClassName ?? 'w-[50%]'} px-[16px] md:px-[30px]`}>
                  <Typography isBold>その他の経費</Typography>
                </td>
                <td className="px-[16px] md:px-[30px]">
                  <Typography>
                    {convertLocaleString(item?.other_cost_yearly)}円/年
                  </Typography>
                </td>
              </tr>

              <tr className="h-[45px] odd:bg-black-100">
                <td className={`${tdClassName ?? 'w-[50%]'} px-[16px] md:px-[30px]`}>
                  <Typography isBold>空室率</Typography>
                </td>
                <td className="px-[16px] md:px-[30px]">
                  <Typography>
                    {convertLocaleString(item?.vacancy_rate_percent)}%
                  </Typography>
                </td>
              </tr>
            </tbody>
          </table>

          {!_isNull(item?.housing_loan) && (
            <>
              <Typography styleType="underlinedHeader">ローン情報</Typography>
              <table className="w-[100%] mb-[20px] text-[12px] md:text-[14px]  bg-white">
                <tbody>
                  {!_isNull(item?.miscellaneous_cost) && (
                    <tr className="h-[45px] odd:bg-black-100">
                      <td
                        className={`${tdClassName ?? 'w-[50%]'} px-[16px] md:px-[30px]`}
                      >
                        <Typography isBold>諸費用</Typography>
                      </td>
                      <td className="px-[16px] md:px-[30px]">
                        <Typography>
                          {convertLocaleString(item?.miscellaneous_cost)}万円
                        </Typography>
                      </td>
                    </tr>
                  )}
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className={`${tdClassName ?? 'w-[50%]'} px-[16px] md:px-[30px]`}>
                      <Typography isBold>ローン方式</Typography>
                    </td>
                    <td className="px-[16px] md:px-[30px]">
                      <Typography>
                        {convertLoanType(item?.housing_loan?.loan_type!)}
                      </Typography>
                    </td>
                  </tr>

                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="px-[16px] md:px-[30px]">
                      <Typography isBold>頭金</Typography>
                    </td>
                    <td className="px-[16px] md:px-[30px]">
                      <Typography>
                        {convertLocaleString(
                          item?.housing_loan?.loan_down_payment_manyen,
                        )}
                        万円
                      </Typography>
                    </td>
                  </tr>
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="px-[16px] md:px-[30px]">
                      <Typography isBold>借入金額</Typography>
                    </td>
                    <td className="px-[16px] md:px-[30px]">
                      <Typography>
                        {convertLocaleString(item?.housing_loan?.loan_amount_manyen)}
                        万円
                      </Typography>
                    </td>
                  </tr>
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="px-[16px] md:px-[30px]">
                      <Typography isBold>借入金利</Typography>
                    </td>
                    <td className="px-[16px] md:px-[30px]">
                      <Typography>
                        {defaultToEmptyString(
                          item?.housing_loan?.loan_interest_ratio_percent,
                        )}
                        %
                      </Typography>
                    </td>
                  </tr>
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="px-[16px] md:px-[30px]">
                      <Typography isBold>返済期間</Typography>
                    </td>
                    <td className="px-[16px] md:px-[30px]">
                      <Typography>
                        {defaultToEmptyString(item?.housing_loan?.repayment_period_years)}
                        年
                      </Typography>
                    </td>
                  </tr>
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="px-[16px] md:px-[30px]">
                      <Typography isBold>団体信用生命保険</Typography>
                    </td>
                    <td className="px-[16px] md:px-[30px]">
                      <Typography>
                        {convertHasLoanLifeInsuranceContract(
                          item?.housing_loan?.has_loan_life_insurance_contract!,
                        )}
                      </Typography>
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
        </>
      )}
    </div>
  )
}

const FutureEstateInvestmentForm: FC<FutureEstateInvestmentFormProps> = (props) => {
  const dispatch = useDispatch()
  const { showFlashMsg } = useFlashAlert()
  const { isManipulatedApproval } = useContext(SimulationDetailEditContext)

  const { selectedSimulationId, selectedSimulationSaleId, handleSelect } = props

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)

  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [assetId, setAssetId] = useState<number | null>(null)

  const { futureItems } = useSelector((state: RootState) => state.accounts)
  const futureEstateInvestments = useSelector(
    (state: RootState) => state.accounts.futureItems?.future_estate_investments,
  )

  const isSelectedWithSale = (estateInvestment: any): boolean => {
    const selectedEstateInvestmentSales = _filter(
      futureItems?.future_estate_investment_sales,
      (item) => _includes(selectedSimulationSaleId, item.id),
    )

    if (_isEmpty(selectedEstateInvestmentSales)) return false

    return !!_find(
      selectedEstateInvestmentSales,
      (item) => item.estate_investment_id == estateInvestment.id,
    )
  }

  useEffect(() => {
    if (props.futureBaseId == null || !props.shouldInitialize) return
    _filter(futureEstateInvestments, {
      future_base_id: props.futureBaseId,
    }).forEach((item) => handleSelect(item))
    handleTaxSettingChange(futureItems.future_estate_investment_tax_setting.tax_saving_type)
  }, [props.shouldInitialize])

  const sortedFutureEstateInvestments = useMemo(() => {
    if (props.futureBaseId == null || futureEstateInvestments == null)
      return futureEstateInvestments
    const eqFbId = (item: any) => item.future_base_id === props.futureBaseId
    return [
      ...futureEstateInvestments.filter((item) => eqFbId(item)),
      ...futureEstateInvestments.filter((item) => !eqFbId(item)),
    ]
  }, [futureEstateInvestments])

  const titleIndex = (index: number) => {
    if (
      props.futureBaseId == null ||
      props.confirmEdit == null ||
      futureEstateInvestments == null
    )
      return index + 1
    const eqFbId = (item: any) => item.future_base_id === props.futureBaseId
    return index + 1 - futureEstateInvestments.filter((item) => eqFbId(item)).length
  }

  const isExpiredSettings = (date: string | null) => {
    if (date == null) return false
    const settingsDate = new Date(date)
    return settingsDate.getFullYear() < THIS_YEAR_NUMBER
  }

  const handleExecIfConfired = (exec: (item: any) => void, item: any) => {
    props.confirmEdit === false ? props.openEditConfirmModal?.() : exec(item)
  }

  const handleTaxSettingChange = (value) => {
    props.handleTaxSettingSelect(value)
  }

  return (
    <SimulationAccordion
      className="pb-[16px]"
      title="不動産投資"
      isSelected={selectedSimulationId != null && selectedSimulationId.length !== 0}
      selectedCount={selectedSimulationId?.length}
      itemCount={sortedFutureEstateInvestments?.length}
      isExpiredSettings={sortedFutureEstateInvestments?.some(
        (item) =>
          _includes(selectedSimulationId, item.id) &&
          isExpiredSettings(item.purchased_at),
      )}
    >

      <div className="flex">
        <Typography className="leading-normal text-[14px] text-black-900">
          不動産投資の節税対策を行う
        </Typography>
        <div className="mr-[10px]">
          <Tooltip
            item="ONにすることで、節税対策を考慮し、不動産投資から発生する税金は0円で計算されます。"
            itemClassName="w-[80px] sm:w-[200px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
          >
            <HelpCircleIcon />
          </Tooltip>
        </div>
        <div className="flex border-black-500 border rounded-[3px] overflow-hidden">
          <button
            className="flex items-center justify-center disabled:cursor-default disabled:bg-action-600 bg-black-300 rounded-none w-[48px] h-[24px] disabled:text-white text-black-500"
            // @ts-ignore
            disabled={props.selectedTaxSettingType == "saving"}
            onClick={(e) => {
              // @ts-ignore
              handleExecIfConfired(handleTaxSettingChange, 'saving')
              e.stopPropagation()
            }}
          >
            <Typography className="text-[14px]" isBold>
              ON
            </Typography>
          </button>

          <button
            className="flex items-center justify-center disabled:cursor-default disabled:bg-action-600 bg-black-300 rounded-none w-[48px] h-[24px] disabled:text-white text-black-500"
            // @ts-ignore
            disabled={props.selectedTaxSettingType != "saving"}
            onClick={(e) => {
              // @ts-ignore
              handleExecIfConfired(handleTaxSettingChange, 'none')
              e.stopPropagation()
            }}
          >
            <Typography className="text-[14px]" isBold>
              OFF
            </Typography>
          </button>

        </div>
      </div>

      <div className="flex flex-col gap-y-[12px] pt-[6px]">
        {sortedFutureEstateInvestments?.map((item: any, index: number) => {
          const handleDestroyV2 = (item: any) => {
            const result = confirm('本当に削除してよろしいですか？')
            if (!result) return

            const destroyFunc = new Promise((resolve) => {
              dispatch(
                destroyFutureEstateInvestmentV2(item, item.id, () => {
                  resolve('')
                  showFlashMsg('削除しました')
                }),
              )
            })

            Promise.all([destroyFunc]).then(() => {
              if (_includes(selectedSimulationId, item.id)) handleSelect(item)
              dispatch(fetchAccountFutureItems(props.futureBaseId))
            })
          }

          const handleEdit = (item: any) => {
            setAssetId(item.id)
            setIsEdit(true)
            setIsOpenModal(true)
          }

          return (
            <Tooltip
              className="w-auto"
              isDispaly={isSelectedWithSale(item)}
              itemClassName="w-[330px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
              item={
                <>
                  <Typography>
                    この物件の売却プランがシミュレーションにセットされているため選択解除できません。
                  </Typography>
                  <Typography>
                    先に不動産投資売却の該当プランを選択解除してください。
                  </Typography>
                </>
              }
            >
              <SimulationItemAccordion
                key={`future_estate_investment_${item.id}_${index}`}
                title={
                  props.futureBaseId === item.future_base_id && props.confirmEdit != null
                    ? '登録中の投資用物件'
                    : `投資用物件${titleIndex(index)}`
                }
                isSelected={_includes(selectedSimulationId, item.id)}
                isExpiredSettings={isExpiredSettings(item.purchased_at)}
                displayExpiredSettingsTips={!isSelectedWithSale(item)}
                inputType="checkbox"
                onSelect={() => {
                  if (!isSelectedWithSale(item)) {
                    handleExecIfConfired(handleSelect, item)
                  }
                }}
              >
                <div className="flex justify-end mb-[5px]">
                  <Button
                    className="mr-[16px]"
                    isDisabled={!isManipulatedApproval}
                    onClick={(e) => {
                      handleExecIfConfired(handleEdit, item)
                      e.stopPropagation()
                    }}
                  >
                    <i className="ico edit text-[14px] text-black-700" />
                    <Typography className="pl-[5px] text-[14px] text-black-700">
                      編集
                    </Typography>
                  </Button>
                  <Button
                    isDisabled={!isManipulatedApproval}
                    onClick={(e) => {
                      handleExecIfConfired(handleDestroyV2, item)
                      e.stopPropagation()
                    }}
                  >
                    <Typography className="pl-[5px] text-[14px] text-black-700">
                      削除
                    </Typography>
                  </Button>
                </div>
                <FutureEstateInvestmentTable item={item} />
              </SimulationItemAccordion>
            </Tooltip>
          )
        })}
      </div>

      <SimulationNewButton
        isDisabled={!isManipulatedApproval}
        onClick={() => {
          setAssetId(null)
          setIsEdit(false)
          setIsOpenModal(true)
        }}
      />

      {isOpenModal && (
        <FutureEstateInvestmentModalForm
          isOpen={isOpenModal}
          isEdit={isEdit}
          onClose={() => setIsOpenModal(false)}
          id={assetId}
          futureBaseId={props.futureBaseId}
        />
      )}
    </SimulationAccordion>
  )
}

export default FutureEstateInvestmentForm
