import {
  Company,
  CompanyInfo,
  CompanyPaymentHistoryResponse,
  SearchCompanyResponse,
  CompanyContractUpdateValues,
  CompanyCreateValues,
  CompanyUpdateValues,
} from '@/models/admins/CompaniesModel'
import ApiClient from '../apiClient'

export const getCompanies = async () => {
  return await ApiClient.get('/companies', (response: CompanyInfo) => response)
}

export const getCompanyPaymentHistories = async () => {
  return await ApiClient.get(
    '/company_payment_histories',
    (response: CompanyPaymentHistoryResponse[]) => response,
  )
}

export const postCompany = async (values: CompanyCreateValues) => {
  return await ApiClient.post('/companies', () => {}, values)
}

export const postCompanyLearningPlatformPassword = async (id: number) => {
  return await ApiClient.post(
    `/companies/${id}/company_learning_platform_passwords`,
    () => {},
  )
}

export const postSearchCompany = async (registrationCode: string) => {
  return await ApiClient.post(
    `/companies/search/${registrationCode}`,
    (response: SearchCompanyResponse) => response,
  )
}

export const patchCompany = async (values: CompanyUpdateValues) => {
  return await ApiClient.patch(`/companies/${values.company.id}`, () => {}, values)
}

export const patchCompanyContact = async (values: CompanyContractUpdateValues) => {
  return await ApiClient.patch(
    `/companies/${values.id}/update_contract`,
    () => {},
    values,
  )
}

export const deleteCompanyLearningPlatformPassword = async (id: number) => {
  return await ApiClient.delete(
    `/companies/${id}/company_learning_platform_passwords`,
    () => {},
  )
}
