import { extractYearOrMonthFromDate } from '../commonsModelFunc'

// 繰り上げ返済方法
export const INPUT_REPAYMENT_TYPE = {
  shortTerm: 0,
  amountMigration: 1,
}

export const convertRepaymentType = (repaymentType: number | null) => {
  switch (repaymentType) {
    case INPUT_REPAYMENT_TYPE.shortTerm:
      return '期間短縮型'
    case INPUT_REPAYMENT_TYPE.amountMigration:
      return '返済額軽減型'
    default:
      return ''
  }
}

export const convertAdvanceRepaymentAt = (repaymentAt: string | null) => {
  return `${extractYearOrMonthFromDate(repaymentAt, 'year')}年`
}
