import React, { FC, useState, useContext, useEffect, useMemo } from 'react'
import clsx from 'clsx'
import Typography from '@/components/v2/atoms/Typography'
import FutureMonthlyLivingCostModalForm from '@/templates/v2/simulations/_futureMonthlyLivingCostModalForm'
import SimulationAccordion from '@/templates/v2/simulations/_simulationAccordion'
import SimulationNewButton from '@/templates/v2/simulations/_simulationNewButton'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/store'
import TableRowOddColor from '@/components/v2/molecules/TableRowOddColor'
import Button from '@/components/v2/atoms/Button'
import { destroyFutureMonthlyLivingCostBaseV2 } from '@/containers/futures/futureMonthlyLivingCostBasesSlice'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import { fetchAccountFutureItems } from '@/containers/accountsSlice'
import { SimulationDetailEditContext } from '@/models/futures/futureBasesModelFunc'
import { find as _find } from 'lodash'
import SimulationItemAccordion from '@/templates/v2/simulations/_simulationItemAccordion'

interface FutureMonthlyLivingCostFormProps {
  /** 選択シミュレーションID */
  selectedSimulationId: number | undefined
  /** 選択メソッド */
  handleSelect: (item: any) => void
  /* FutureItemsを参照するID */
  futureBaseId?: number
  /* 初期化すべきかどうか */
  shouldInitialize?: boolean
  /* 編集を確認したか */
  confirmEdit?: boolean
  /* 編集確認モーダルを展開するメソッド */
  openEditConfirmModal?: () => void
}

interface FutureMonthlyLivingCostTableProps {
  /** アイテム */
  item: any
  /** td要素のクラス */
  tdClassName?: string
}

export const FutureMonthlyLivingCostTable: FC<FutureMonthlyLivingCostTableProps> = ({
  item,
}) => {
  return (
    <table
      key={item.id}
      className={clsx('w-[100%] mb-[20px] last:mb-0 text-[12px] md:text-[14px] bg-white')}
    >
      <tbody>
        {item.future_monthly_living_cost_changes?.map((changed: any, index: number) => {
          return (
            <React.Fragment key={`monthly_living_cost_changes_${changed.id}_${index}`}>
              <TableRowOddColor
                title={`${
                  changed.affected_start_at_age === 120
                    ? '終身'
                    : `${changed.affected_start_at_age}歳`
                }から${
                  changed.affected_end_at_age === 120
                    ? '終身'
                    : `${changed.affected_end_at_age}歳`
                }`}
                description={`${changed.monthly_cost_manyen}万円/月 | ${changed.yearly_cost_manyen}万円/年`}
              />
            </React.Fragment>
          )
        })}
      </tbody>
    </table>
  )
}

const FutureMonthlyLivingCostForm: FC<FutureMonthlyLivingCostFormProps> = (props) => {
  const dispatch = useDispatch()
  const { showFlashMsg } = useFlashAlert()
  const { isManipulatedApproval } = useContext(SimulationDetailEditContext)

  const { selectedSimulationId, handleSelect } = props

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [monthlyCostBaseId, setMonthlyCostBaseId] = useState<number | null>(null)

  const futureMonthlyLivingCostBases = useSelector(
    (state: RootState) => state.accounts.futureItems?.future_monthly_living_cost_bases,
  )

  const handleDestroyV2 = (item: any) => {
    const result = confirm('本当に削除してよろしいですか？')
    if (!result) return

    const destroyFunc = new Promise((resolve) => {
      dispatch(
        destroyFutureMonthlyLivingCostBaseV2(item.id, () => {
          resolve('')
          showFlashMsg('削除しました')
        }),
      )
    })

    Promise.all([destroyFunc]).then(() => {
      if (item.id === selectedSimulationId) handleSelect(item)
      dispatch(fetchAccountFutureItems(props.futureBaseId))
    })
  }

  const handleEdit = (item: any) => {
    setMonthlyCostBaseId(item.id)
    setIsEdit(true)
    setIsOpenModal(true)
  }

  useEffect(() => {
    if (props.futureBaseId == null || !props.shouldInitialize) return
    const item = _find(futureMonthlyLivingCostBases, {
      future_base_id: props.futureBaseId,
    })
    if (item != null) handleSelect(item)
  }, [props.shouldInitialize])

  const sortedFutureMonthlyLivingCostBases = useMemo(() => {
    if (props.futureBaseId == null || futureMonthlyLivingCostBases == null)
      return futureMonthlyLivingCostBases
    const eqFbId = (item: any) => item.future_base_id === props.futureBaseId
    return [
      ...futureMonthlyLivingCostBases.filter((item) => eqFbId(item)),
      ...futureMonthlyLivingCostBases.filter((item) => !eqFbId(item)),
    ]
  }, [futureMonthlyLivingCostBases])

  const titleIndex = (index: number) => {
    if (
      props.futureBaseId == null ||
      props.confirmEdit == null ||
      futureMonthlyLivingCostBases == null
    )
      return index + 1
    const eqFbId = (item: any) => item.future_base_id === props.futureBaseId
    return index + 1 - futureMonthlyLivingCostBases.filter((item) => eqFbId(item)).length
  }

  const handleExecIfConfired = (exec: (item: any) => void, item: any) => {
    props.confirmEdit === false ? props.openEditConfirmModal?.() : exec(item)
  }

  return (
    <SimulationAccordion
      className="pb-[16px]"
      title="基本の生活費を想定する"
      isSelected={!!selectedSimulationId}
      itemCount={sortedFutureMonthlyLivingCostBases?.length}
    >
      <div className="flex flex-col gap-y-[12px] pt-[6px]">
        {sortedFutureMonthlyLivingCostBases?.map((item: any, index: number) => {
          return (
            <SimulationItemAccordion
              key={`future_monthly_cost_base_${item.id}_${index}`}
              title={
                props.futureBaseId === item.future_base_id && props.confirmEdit != null
                  ? '登録中の基本生活費の変化'
                  : `基本生活費の変化${titleIndex(index)}`
              }
              isSelected={item.id === selectedSimulationId}
              inputType="radio"
              onSelect={() => {
                handleExecIfConfired(handleSelect, item)
              }}
            >
              <div className="flex justify-end mb-[5px]">
                <Button
                  className="mr-[16px]"
                  isDisabled={!isManipulatedApproval}
                  onClick={(e) => {
                    handleExecIfConfired(handleEdit, item)
                    e.stopPropagation()
                  }}
                >
                  <i className="ico edit text-[14px] text-black-700" />
                  <Typography className="pl-[5px] text-[14px] text-black-700">
                    編集
                  </Typography>
                </Button>
                <Button
                  isDisabled={!isManipulatedApproval}
                  onClick={(e) => {
                    handleExecIfConfired(handleDestroyV2, item)
                    e.stopPropagation()
                  }}
                >
                  <Typography className="pl-[5px] text-[14px] text-black-700">
                    削除
                  </Typography>
                </Button>
              </div>
              <FutureMonthlyLivingCostTable item={item} />
            </SimulationItemAccordion>
          )
        })}
      </div>

      <SimulationNewButton
        isDisabled={!isManipulatedApproval}
        onClick={() => {
          setMonthlyCostBaseId(null)
          setIsEdit(false)
          setIsOpenModal(true)
        }}
      />

      {isOpenModal && (
        <FutureMonthlyLivingCostModalForm
          isOpen={isOpenModal}
          onClose={() => setIsOpenModal(false)}
          isEdit={isEdit}
          id={monthlyCostBaseId}
          futureBaseId={props.futureBaseId}
        />
      )}
    </SimulationAccordion>
  )
}

export default FutureMonthlyLivingCostForm
