import React, { FC, useMemo } from 'react'
import { useField } from 'react-final-form'
import HelpCircleIcon from '@/assets/images/v2/help-circle.svg'
import Typography from '@/components/v2/atoms/Typography'
import NumberField from '@/components/v2/molecules/NumberField'
import {
  commaNumberMaxLength,
  composeValidators,
  required,
  zeroOrMoreNumber,
} from '@/utils/validate'
import { getTargetRentAttributesName } from '@/models/commonHousingModelFunc'
import { INPUT_HOUSING_RENT_TYPE } from '@/models/inputs/inputHousingRentsModelFunc'
import { castNumberWithoutOperator } from '@/models/commonsModelFunc'
import Tooltip from '@/components/v2/atoms/Tooltip'

interface NextUpdateCostProps {
  isFuture?: boolean
}

const NextUpdateCost: FC<NextUpdateCostProps> = (props) => {
  const { isFuture } = props
  const targetHousingRentAttributesName = getTargetRentAttributesName(isFuture)
  const {
    input: { value: rentMonthly },
  } = useField(`${targetHousingRentAttributesName}[0]rent_monthly`)
  const {
    input: { value: wholeRentMonthly },
  } = useField(`${targetHousingRentAttributesName}[0]whole_rent_monthly`)
  const {
    input: { value: rentType },
  } = useField(`${targetHousingRentAttributesName}[0]rent_type`)

  const isSelfRentType = useMemo(() => rentType === INPUT_HOUSING_RENT_TYPE.selfRent, [
    rentType,
  ])
  const isNotSelfRentType = useMemo(
    () =>
      rentType === INPUT_HOUSING_RENT_TYPE.companyRent ||
      rentType === INPUT_HOUSING_RENT_TYPE.allowance,
    [rentType],
  )
  const targetRentDefaultValue = useMemo(
    () =>
      isSelfRentType
        ? castNumberWithoutOperator(rentMonthly)
        : castNumberWithoutOperator(wholeRentMonthly) ?? 0,
    [isSelfRentType],
  )
  const rentMonthlyDefaultValue = useMemo(
    () => castNumberWithoutOperator(rentMonthly) ?? 0,
    [rentMonthly],
  )

  return (
    <>
      <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
        <div className="flex items-center min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0">
          <Typography className="text-[14px] text-black-800" isBold>
            次回更新費用
          </Typography>
          <Tooltip
            itemClassName="w-[200px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
            item={
              <Typography className="text-12">
                「${isSelfRentType ? '' : '本来の'}
                家賃」でご入力いただいた数値が、初期値として入力されています。
              </Typography>
            }
          >
            <HelpCircleIcon />
          </Tooltip>
        </div>
        <div className="flex flex-auto items-baseline">
          <NumberField
            name={
              isSelfRentType
                ? `${targetHousingRentAttributesName}[0]next_update_cost`
                : `${targetHousingRentAttributesName}[0]whole_next_update_cost`
            }
            validate={composeValidators(required, zeroOrMoreNumber, (value: any) =>
              commaNumberMaxLength(value, 10),
            )}
            defaultValue={targetRentDefaultValue}
            isFullWidth
          />
          <Typography className="pl-[8px] text-[14px] text-black-800" isBold>
            円
          </Typography>
        </div>
      </div>

      {isNotSelfRentType && (
        <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
          <Typography
            className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
            isBold
          >
            自分で払う金額
          </Typography>
          <div className="flex flex-auto items-baseline">
            <NumberField
              name={`${targetHousingRentAttributesName}[0]next_update_cost`}
              validate={composeValidators(required, zeroOrMoreNumber, (value: any) =>
                commaNumberMaxLength(value, 10),
              )}
              defaultValue={rentMonthlyDefaultValue}
              isFullWidth
            />
            <Typography className="pl-[8px] text-[14px] text-black-800" isBold>
              円
            </Typography>
          </div>
        </div>
      )}
    </>
  )
}

export default NextUpdateCost
