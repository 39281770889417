import React, { FC, useCallback } from 'react'
import { isNil as _isNil, isUndefined as _isUndefined } from 'lodash'
import Typography from '@/components/v2/atoms/Typography'
import { convertLocaleString, defaultToEmptyString } from '@/models/commonsModelFunc'

interface TooltipRiskProps {
  /** アクティブ情報 */
  activeIndex: number | undefined
  /** ベースプラン名 */
  basePlanName?: any
  /** true: ベースプラン名非表示, false: ベースプラン名表示 */
  isHideBasePlanName?: boolean
  /** ベースアイテム */
  baseItem: any
  /** true: 比較有り, false: 比較無し */
  isCompare?: boolean
  /** 比較プラン名 */
  comparePlanName?: any
  /** 比較アイテム */
  compareItem?: any
}

const TooltipRisk: FC<TooltipRiskProps> = (props) => {
  const {
    activeIndex,
    basePlanName,
    isHideBasePlanName,
    baseItem,
    isCompare,
    comparePlanName,
    compareItem,
  } = props

  const activeRiskItem = !_isUndefined(activeIndex) ? baseItem?.[activeIndex] : {}
  const compareActiveRiskItem = !_isUndefined(activeIndex)
    ? compareItem?.[activeIndex]
    : {}

  const fillIfEmpty = useCallback(
    (str: string) => {
      if (!_isNil(activeRiskItem.total_financial_assets)) {
        return str
      }
      return '-'
    },
    [activeRiskItem],
  )

  const fillIfEmptyCompare = useCallback(
    (str: string) => {
      if (!_isNil(compareActiveRiskItem.total_financial_assets)) {
        return str
      }
      return '-'
    },
    [compareActiveRiskItem],
  )

  return (
    <>
      {!_isUndefined(activeIndex) ? (
        <div
          className={`${
            isCompare ? 'flex' : ''
          } gap-x-[32px] py-[24px] px-[16px] rounded-[8px] bg-white md:shadow-modal visible pointer-events-auto overflow-x-scroll`}
        >
          <div className="w-full md:w-[200px] min-w-[200px]">
            {!isHideBasePlanName && (
              <Typography
                className="pb-[4px] text-[12px] overflow-hidden whitespace-nowrap overflow-ellipsis"
                isBold
              >
                ベースプラン：
                <br />
                {basePlanName ?? '（プラン名未設定）'}
              </Typography>
            )}
            <div className="p-[10px] rounded-[4px] bg-primary-50">
              <Typography className="pb-[4px]" isBold>
                {`${defaultToEmptyString(activeRiskItem?.name)}年(${defaultToEmptyString(
                  activeRiskItem?.age,
                )}歳)`}
              </Typography>
              {/* <Typography isBold>{`金融資産合計：${convertLocaleString(
                  activeRiskItem?.total + activeRiskItem?.substruction,
                )}万円`}</Typography> */}
            </div>

            <div className="flex justify-between pb-[12px] pt-[12px] last:pb-0 border-b border-black-300">
              <div className="flex items-center">
                <i
                  className="ico rect-2 pr-[8px] text-[12px]"
                  style={{ color: '#c0c0c0' }}
                />
                <Typography className="text-[12px] isBold">不足額</Typography>
              </div>
              <Typography className="text-[12px]">
                {fillIfEmpty(
                  `${convertLocaleString(activeRiskItem?.shortfall_total)}万円`,
                )}
              </Typography>
            </div>

            <div className="flex justify-between pb-[12px] pt-[12px] last:pb-0 border-b border-black-300">
              <div className="flex items-center">
                <i
                  className="ico circle top-px pr-[4px] text-[12px]"
                  style={{ color: '#ffa500' }}
                />
                <Typography className="text-[12px] isBold">必要保障額</Typography>
              </div>
              <Typography className="text-[12px]">
                {fillIfEmpty(
                  `${convertLocaleString(
                    activeRiskItem?.required_security_amount_total,
                  )}万円`,
                )}
              </Typography>
            </div>

            <div className="flex justify-between pb-[12px] pt-[12px] last:pb-0 border-b border-black-300">
              <div className="flex items-center">
                <i
                  className="ico rect-2 pr-[8px] text-[12px]"
                  style={{ color: '#c71585' }}
                />
                <Typography className="text-[12px] isBold">既加入保険金額</Typography>
              </div>
              <Typography className="text-[12px]">
                {fillIfEmpty(
                  `${convertLocaleString(activeRiskItem?.existing_insurance_total)}万円`,
                )}
              </Typography>
            </div>

            <div className="flex justify-between pb-[12px] pt-[12px] last:pb-0 border-b border-black-300">
              <div className="flex items-center">
                <i
                  className="ico rect-2 pr-[8px] text-[12px]"
                  style={{ color: '#191970' }}
                />
                <Typography className="text-[12px] isBold">金融資産合計</Typography>
              </div>
              <Typography className="text-[12px]">
                {fillIfEmpty(
                  `${convertLocaleString(activeRiskItem?.total_financial_assets)}万円`,
                )}
              </Typography>
            </div>
          </div>

          {isCompare && (
            <div className="w-full md:w-[200px] min-w-[200px]">
              <Typography
                className="pb-[4px] text-[12px] overflow-hidden whitespace-nowrap overflow-ellipsis"
                isBold
              >
                比較プラン：
                <br />
                {comparePlanName ?? '（プラン名未設定）'}
              </Typography>
              <div className="p-[10px] rounded-[4px] bg-primary-50">
                <Typography className="pb-[4px]" isBold>
                  {`${compareActiveRiskItem?.name}年(${compareActiveRiskItem?.age}歳)`}
                </Typography>
                {/* <Typography isBold>{`金融資産合計：${convertLocaleString(
                    compareActiveRiskItem?.total +
                      compareActiveRiskItem?.substruction,
                  )}万円`}</Typography> */}
              </div>

              <div className="flex justify-between pb-[12px] pt-[12px] last:pb-0 border-b border-black-300">
                <div className="flex items-center">
                  <i
                    className="ico rect-2 pr-[8px] text-[12px]"
                    style={{ color: '#c0c0c0' }}
                  />
                  <Typography className="text-[12px] isBold">不足額</Typography>
                </div>
                <Typography className="text-[12px]">
                  {fillIfEmptyCompare(
                    `${convertLocaleString(compareActiveRiskItem?.shortfall_total)}万円`,
                  )}
                </Typography>
              </div>

              <div className="flex justify-between pb-[12px] pt-[12px] last:pb-0 border-b border-black-300">
                <div className="flex items-center">
                  <i
                    className="ico circle pr-[8px] text-[12px]"
                    style={{ color: '#ffa500' }}
                  />
                  <Typography className="text-[12px] isBold">必要保障額</Typography>
                </div>
                <Typography className="text-[12px]">
                  {fillIfEmptyCompare(
                    `${convertLocaleString(
                      compareActiveRiskItem?.required_security_amount_total,
                    )}万円`,
                  )}
                </Typography>
              </div>

              <div className="flex justify-between pb-[12px] pt-[12px] last:pb-0 border-b border-black-300">
                <div className="flex items-center">
                  <i
                    className="ico rect-2 pr-[8px] text-[12px]"
                    style={{ color: '#c71585' }}
                  />
                  <Typography className="text-[12px] isBold">既加入保険金額</Typography>
                </div>
                <Typography className="text-[12px]">
                  {fillIfEmptyCompare(
                    `${convertLocaleString(
                      compareActiveRiskItem?.existing_insurance_total,
                    )}万円`,
                  )}
                </Typography>
              </div>

              <div className="flex justify-between pb-[12px] pt-[12px] last:pb-0 border-b border-black-300">
                <div className="flex items-center">
                  <i
                    className="ico rect-2 pr-[8px] text-[12px]"
                    style={{ color: '#191970' }}
                  />
                  <Typography className="text-[12px] isBold">金融資産合計</Typography>
                </div>
                <Typography className="text-[12px]">
                  {fillIfEmptyCompare(
                    `${convertLocaleString(
                      compareActiveRiskItem?.total_financial_assets,
                    )}万円`,
                  )}
                </Typography>
              </div>
            </div>
          )}
        </div>
      ) : null}
    </>
  )
}

export default TooltipRisk
