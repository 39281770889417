import ApiClient from '@/api/apiClient'
import { RecursivePartial } from '@/models/commonsModel'
import {
  CompanySalaryBaseResponse,
  CompanySalaryBaseUpdateValues,
  CompanySalaryBaseCalcResponse,
  CompanySalaryBaseCalcValues,
} from '@/models/admins/companySalaryBasesModel'

export const getCompanySalaryBase = async (id: number) => {
  return await ApiClient.get(
    `/companies/${id}/company_salary_bases`,
    (response: CompanySalaryBaseResponse) => response,
  )
}

export const getCompanySalaryBaseV2 = async () => {
  return await ApiClient.get(
    '/company_salary_bases',
    (response: CompanySalaryBaseResponse) => response,
    undefined,
    false,
    'v2',
  )
}

export const postCompanySalaryBaseCalc = async (
  id: number,
  values: RecursivePartial<CompanySalaryBaseCalcValues>,
) => {
  return await ApiClient.post(
    `/companies/${id}/company_salary_bases/calc`,
    (response: CompanySalaryBaseCalcResponse) => response,
    values,
  )
}

export const patchCompanySalaryBase = async (
  id: number,
  values: RecursivePartial<CompanySalaryBaseUpdateValues>,
) => {
  return await ApiClient.patch(`/companies/${id}/company_salary_bases`, () => {}, values)
}
