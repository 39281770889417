export const PERSON_OCCUPATION = {
  with_severance_income: '会社員(退職金あり)',
  no_severance_income: '会社員(退職金なし)',
  self_employed: '自営業',
  housewife: '専業主婦・主夫',
}

export const PERSON_ANNUAL_INCOME = {
  under_200: '300万円以下',
  income_300_400: '300万円-400万円',
  income_400_500: '400万円-500万円',
  income_500_600: '500万円-600万円',
  over_800: '600万円以上',
  custom: '自由入力', // (=> custom_person_annual_income_manyen)
}

export const SPOUSE_TYPE = {
  married: 'いる',
  single: 'いない',
}

export const SPOUSE_OCCUPATION = {
  with_severance_income: '会社員(退職金あり)',
  no_severance_income: '会社員(退職金なし)',
  self_employed: '自営業',
  housewife: '専業主婦・主夫',
}

export const SPOUSE_ANNUAL_INCOME = {
  under_200: '300万円以下',
  income_300_400: '300万円-400万円',
  income_400_500: '400万円-500万円',
  income_500_600: '500万円-600万円',
  over_800: '600万円以上',
  custom: '自由入力', // (=> custom_spouse_annual_income_manyen)
}

export const EDUCATION_COST = {
  no_hang: 'かけていない',
  no_really_hang: 'あまりかけていない',
  average_hang: '人並みにかけている',
  average_or_more_hang: '人並み以上にかけている',
  thorough_hang: '教育費をとことんかけている',
}

export const LIVING_COST_MONTHLY = {
  cost_10: '10万円',
  cost_15: '15万円',
  cost_20: '20万円',
  cost_25: '25万円',
  cost_30: '30万円',
  cost_35: '35万円',
  over_40: '40万円以上',
  custom: '自由入力', // (=> custom_living_cost_monthly_manyen)
}

export const HOUSE_TYPE = {
  rent: '賃貸',
  self_owned: '持ち家',
}

export const HOUSING_COST_MONTHLY = {
  cost_10: '10万円',
  cost_15: '15万円',
  cost_20: '20万円',
  cost_25: '25万円',
  cost_30: '30万円',
  cost_35: '35万円',
  over_40: '40万円以上',
  custom: '自由入力', // (=> custom_housing_cost_monthly_manyen)
}

export const SAVING_AMOUNT = {
  amount_0: '0円',
  amount_100: '100万円',
  amount_200: '200万円',
  amount_300: '300万円',
  amount_500: '500万円',
  amount_800: '600万円',
  over_1000: '700万円以上',
  custom: '自由入力', // (=> custom_saving_amount_manyen)
}
