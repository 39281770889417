import ApiClient from '@/api/apiClient'

export const postFutureMonthlyLivingCostBaseV2 = async (values: any) => {
  return await ApiClient.post(
    '/future_monthly_living_cost_bases',
    () => {},
    values,
    undefined,
    'v2',
  )
}

export const patchFutureMonthlyLivingCostBaseV2 = async (values: any, id: number) => {
  return await ApiClient.patch(
    `/future_monthly_living_cost_bases/${id}`,
    () => {},
    values,
    undefined,
    'v2',
  )
}

export const deleteFutureMonthlyLivingCostBaseV2 = async (id: number) => {
  return await ApiClient.delete(
    `/future_monthly_living_cost_bases/${id}`,
    () => {},
    undefined,
    undefined,
    'v2',
  )
}
