import React, { FC } from 'react'
import MovieLogo from '@/assets/images/movie-logo.png'
import { useCustomHistory } from '@/hooks/useCustomHistory'

const MovieHeader: FC = () => {
  const { handleMovePage } = useCustomHistory()

  return (
    <div className="bg-white flex h-55 lg:h-88 items-center justify-between px-12 lg:px-24 sticky top-0 z-50">
      <div className="cursor-pointer" onClick={() => handleMovePage('/movies/list')}>
        <img className="h-31 lg:h-42" src={MovieLogo} alt="マネソル" />
      </div>
    </div>
  )
}

export default MovieHeader
