import React, { FC } from 'react'
import { isUndefined as _isUndefined } from 'lodash'
import ChevronRightSmallIcon from '@/assets/images/v2/chevron-right-small.svg'
import Link from '@/components/v2/atoms/Link'
import { useCustomHistory } from '@/hooks/useCustomHistory'

interface BreadcrumbProps {
  /** クラス名 */
  className?: string
  /** リンククラス名 */
  linkClassName?: string
  /** データ */
  data: { name: string; url?: string }[]
}

const Breadcrumb: FC<BreadcrumbProps> = (props) => {
  const { className, linkClassName, data } = props
  const { handleMovePage } = useCustomHistory()

  const handleClickLink = (url?: string) => {
    !_isUndefined(url) && handleMovePage(url)
  }

  return (
    <ol className={`${className} flex items-center`}>
      {data.map((item, index) => (
        <React.Fragment key={item.name}>
          <li className="pr-10">
            <Link
              className={`${linkClassName} bottom-2 relative text-12 text-black-800 border-b border-transparent hover:border-black-800`}
              onClick={() => handleClickLink(item.url)}
            >
              {item.name}
            </Link>
          </li>

          {index !== data.length - 1 && (
            <li className="pr-10">
              <ChevronRightSmallIcon />
            </li>
          )}
        </React.Fragment>
      ))}
    </ol>
  )
}

export default Breadcrumb
