import {
  PROPERTY_TYPE,
  LOAN_RENEWAL_TYPE,
} from '@/models/futures/futureLendHousingsModelFunc'

export const LOAN_RENEWAL_TYPE_SELECT_ITEMS = [
  { value: LOAN_RENEWAL_TYPE.renew, label: 'はい' },
  { value: LOAN_RENEWAL_TYPE.continue, label: 'いいえ' },
]

// 物件タイプ
export const PROPERTY_TYPE_RADIO_ITEMS = [
  { value: PROPERTY_TYPE.house, label: '戸建て' },
  { value: PROPERTY_TYPE.division, label: '区分' },
  { value: PROPERTY_TYPE.whole, label: '１棟' },
]
