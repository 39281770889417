import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
  Action,
} from '@reduxjs/toolkit'
import { ThunkAction } from 'redux-thunk'
import logger from 'redux-logger'
import { routerMiddleware, connectRouter } from 'connected-react-router'
import { accountsReducer } from '@/containers/accountsSlice'
import { aggBalanceMonthliesReducer } from '@/containers/aggBalanceMonthliesSlice'
import { aifpProposalsReducer } from '@/containers/aifpProposalsSlice'
import { companySalaryBasesReducer } from '@/containers/admins/companySalaryBasesSlice'
import { companyRisksReducer } from '@/containers/admins/companyRisksSlice'
import { familyStructuresReducer } from '@/containers/familyStructuresSlice'
import { generalStatisticsReducer } from '@/containers/generalStatisticsSlice'
import { housingLoansReducer } from '@/containers/housingLoansSlice'
import { mtAccessTokensReducer } from '@/containers/mtAccessTokensSlice'
import { pageMetasReducer } from '@/containers/pageMetasSlice'
import { summariesReducer } from '@/containers/summariesSlice'
import { accountInvitationsReducer } from '@/containers/admins/accountInvitationsSlice'
import { flashAlertReducer } from '@/containers/flashAlertsSlice'
import { fpAccountAssignsReducer } from '@/containers/admins/fpAccountAssignsSlice'
import { fpAccountInvitationsReducer } from '@/containers/admins/fpAccountInvitationsSlice'
import { fpAccountsReducer } from '@/containers/admins/fpAccountsSlice'
import { fpCompaniesReducer } from '@/containers/admins/fpCompaniesSlice'
import { futureAssetsReducer } from '@/containers/futures/futureAssetsSlice'
import { futureBasesReducer } from '@/containers/futures/futureBasesSlice'
import { futureCarsReducer } from '@/containers/futures/futureCarsSlice'
import { futureEstateInvestmentsReducer } from '@/containers/futures/futureEstateInvestmentsSlice'
import { futureEstateInvestmentSalesReducer } from '@/containers/futures/futureEstateInvestmentSalesSlice'
import { futureFamiliesReducer } from '@/containers/futures/futureFamiliesSlice'
import { futureHousingsReducer } from '@/containers/futures/futureHousingsSlice'
import { futureHousingSalesReducer } from './containers/futures/futureHousingSalesSlice'
import { futureIncomesReducer } from '@/containers/futures/futureIncomesSlice'
import { futureInflationsReducer } from '@/containers/futures/futureInflationsSlice'
import { futureInsurancesReducer } from '@/containers/futures/futureInsurancesSlice'
import { futureLendHousingsReducer } from '@/containers/futures/futureLendHousingsSlice'
import { futureLoanAdvancesReducer } from '@/containers/futures/futureLoanAdvancesSlice'
import { futureMarriagesReducer } from '@/containers/futures/futureMarriagesSlice'
import { futureMonthlyLivingCostBasesReducer } from '@/containers/futures/futureMonthlyLivingCostBasesSlice'
import { futurePlanTuitionsReducer } from '@/containers/futures/futurePlanTuitionsSlice'
import { futureRisksReducer } from '@/containers/futures/futureRisksSlice'
import { futureYearlyLivingCostsReducer } from '@/containers/futures/futureYearlyLivingCostsSlice'
import { futureV2HousingsReducer } from '@/containers/futures/futureV2HousingsSlice'
import { inputAssetsReducer } from '@/containers/inputs/inputAssetsSlice'
import { inputAssetInvestmentsReducer } from '@/containers/inputs/inputAssetInvestmentsSlice'
import { inputCarsReducer } from '@/containers/inputs/inputCarsSlice'
import { inputCustomItemsReducer } from '@/containers/inputs/inputCustomItemsSlice'
import { inputContributionPensionsReducer } from '@/containers/inputs/inputContributionPensionsSlice'
import { inputCorporatePensionsReducer } from '@/containers/inputs/inputCorporatePensionsSlice'
import { inputHousingsReducer } from '@/containers/inputs/inputHousingsSlice'
import { inputIncomesReducer } from './containers/inputs/inputIncomesSlice'
import { inputInsurancesReducer } from '@/containers/inputs/inputInsurancesSlice'
import { inputPlanTuitionsReducer } from '@/containers/inputs/inputPlanTuitionsSlice'
import { inputPublicPensionsReducer } from '@/containers/inputs/inputPublicPensionsSlice'
import { inputMonthlyCostsReducer } from '@/containers/inputs/inputMonthlyCostsSlice'
import { inputEstateInvestmentsReducer } from '@/containers/inputs/inputEstateInvestmentsSlice'
import { inputSalariesReducer } from '@/containers/inputs/inputSalariesSlice'
import { inputSalaryCompaniesReducer } from '@/containers/inputs/inputSalaryCompaniesSlice'
import { inputSubIncomesReducer } from '@/containers/inputs/inputSubIncomesSlice'
import { inputYearCostsReducer } from '@/containers/inputs/inputYearCostsSlice'
import { inputYearlyLivingCostsReducer } from '@/containers/inputs/inputYearlyLivingCostsSlice'
import { inputRiskReducer } from '@/containers/inputs/inputRisksSlice'
import { companyRisksForUserReducer } from '@/containers/companyRisksSlice'
import { learningVideoCategoriesReducer } from '@/containers/admins/learningVideoCategoriesSlice'
import { learningVideosReducer } from '@/containers/admins/learningVideosSlice'
import { learningVideoCompaniesReducer } from '@/containers/videoPlatforms/learningVideoCompaniesSlice'
import { journalsReducer } from '@/containers/journalsSlice'
import { companiesReducer } from '@/containers/admins/companiesSlice'
import { agentCompaniesReducer } from '@/containers/admins/agentCompaniesSlice'
import { paymentHistoriesReducer } from '@/containers/paymentHistoriesSlice'
import { temporaryAccountsReducer } from '@/containers/admins/temporaryAccountsSlice'
import { videoPlatformsAuthenticationsReducer } from '@/containers/videoPlatforms/authenticationsSlice'
import { videoPlatformsUsersReducer } from '@/containers/videoPlatforms/learningPlatformUsersSlice'
import { videoLearningVideosReducer } from '@/containers/videoPlatforms/learningVideosSlice'
import { history } from '@/history'
import { inputSimpleDatasReducer } from './containers/inputs/inputSimpeDataSlice'
import { learningPlatformUsersReducer } from './containers/admins/learningPlatformUsersSlice'
import { learningVideoDemoAccountsReducer } from './containers/admins/learningVideoDemoAccountsSlice'
import { learningVideoDisplayReducer } from './containers/admins/learningVideoDisplaySlice'
import { seminarsReducer } from './containers/admins/seminarsSlice'
import { seminarReducer } from './containers/seminarSlice'
import { seminarParticipantReducer } from './containers/seminarParticipantsSlice'
import { inputEstateInvestmentTaxSettingReducer } from './containers/inputs/inputEstateInvestmentTaxSettingsSlice'

const rootReducer = combineReducers({
  router: connectRouter(history),
  accounts: accountsReducer,
  aggBalanceMonthlies: aggBalanceMonthliesReducer,
  aifpProposals: aifpProposalsReducer,
  companySalaryBases: companySalaryBasesReducer,
  companyRisk: companyRisksReducer,
  familyStructures: familyStructuresReducer,
  generalStatistics: generalStatisticsReducer,
  housingLoans: housingLoansReducer,
  mtAccessTokens: mtAccessTokensReducer,
  pageMetas: pageMetasReducer,
  summaries: summariesReducer,
  accountInvitations: accountInvitationsReducer,
  fpAccountAssigns: fpAccountAssignsReducer,
  fpAccountInvitations: fpAccountInvitationsReducer,
  fpAccounts: fpAccountsReducer,
  fpCompanies: fpCompaniesReducer,
  flashAlerts: flashAlertReducer,
  futureAssets: futureAssetsReducer,
  futureBases: futureBasesReducer,
  futureCars: futureCarsReducer,
  futureEstateInvestments: futureEstateInvestmentsReducer,
  futureEstateInvestmentSales: futureEstateInvestmentSalesReducer,
  futureFamilies: futureFamiliesReducer,
  futureHousings: futureHousingsReducer,
  futureHousingSales: futureHousingSalesReducer,
  futureIncomes: futureIncomesReducer,
  futureInflations: futureInflationsReducer,
  futureInsurances: futureInsurancesReducer,
  futureLendHousings: futureLendHousingsReducer,
  futureLoanAdvances: futureLoanAdvancesReducer,
  futureMarriages: futureMarriagesReducer,
  futureMonthlyLivingCostBases: futureMonthlyLivingCostBasesReducer,
  futurePlanTuitions: futurePlanTuitionsReducer,
  futureRisks: futureRisksReducer,
  futureYearlyLivingCosts: futureYearlyLivingCostsReducer,
  futureV2Housings: futureV2HousingsReducer,
  inputAssets: inputAssetsReducer,
  inputAssetInvestments: inputAssetInvestmentsReducer,
  inputCars: inputCarsReducer,
  inputCustomItems: inputCustomItemsReducer,
  inputContributionPensions: inputContributionPensionsReducer,
  inputCorporatePensions: inputCorporatePensionsReducer,
  inputHousings: inputHousingsReducer,
  inputIncomes: inputIncomesReducer,
  inputInsurances: inputInsurancesReducer,
  inputMonthlyCosts: inputMonthlyCostsReducer,
  inputPlanTuitions: inputPlanTuitionsReducer,
  inputPublicPensions: inputPublicPensionsReducer,
  inputEstateInvestments: inputEstateInvestmentsReducer,
  inputSalaries: inputSalariesReducer,
  inputSalaryCompanies: inputSalaryCompaniesReducer,
  inputSubIncomes: inputSubIncomesReducer,
  inputYearCosts: inputYearCostsReducer,
  inputYearlyLivingCosts: inputYearlyLivingCostsReducer,
  inputSimpleData: inputSimpleDatasReducer,
  inputRisk: inputRiskReducer,
  inputEstateInvestmentTaxSetting: inputEstateInvestmentTaxSettingReducer,
  companyRiskForUser: companyRisksForUserReducer,
  journals: journalsReducer,
  learningVideoCategories: learningVideoCategoriesReducer,
  learningVideos: learningVideosReducer,
  learningVideoCompanies: learningVideoCompaniesReducer,
  companies: companiesReducer,
  agentCompanies: agentCompaniesReducer,
  paymentHistories: paymentHistoriesReducer,
  temporaryAccounts: temporaryAccountsReducer,
  videoPlatformsAuthentications: videoPlatformsAuthenticationsReducer,
  videoPlatformsUsers: videoPlatformsUsersReducer,
  videoLearningVideos: videoLearningVideosReducer,
  learningPlatformUsers: learningPlatformUsersReducer,
  learningVideoDemoAccounts: learningVideoDemoAccountsReducer,
  learningVideoDisplays: learningVideoDisplayReducer,
  seminars: seminarsReducer,
  seminar: seminarReducer,
  seminarParticipants: seminarParticipantReducer,
})

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>

export type RootState = ReturnType<typeof rootReducer>

export const setupStore = () => {
  const isDevelopment = process.env.NODE_ENV !== 'production'
  const middlewares = [...getDefaultMiddleware(), routerMiddleware(history)]

  if (!process.env.DISABLE_STORE_LOG && isDevelopment) {
    middlewares.push(logger)
  }

  const store = configureStore({
    reducer: rootReducer,
    middleware: middlewares,
    devTools: isDevelopment,
  })

  return store
}
