import ApiClient from '@/api/apiClient'
import {
  AccountPaymentCreateValues,
  AccountPaymentUpdateValues,
} from '@/models/accountPaymentsModel'
import { AccountPaymentResponse } from '@/models/accountsModel'

export const getAccountPayment = async () => {
  return await ApiClient.get(
    '/account_payment/sample_plan',
    (response: AccountPaymentResponse) => response,
  )
}

export const postAccountPayment = async (values: AccountPaymentCreateValues) => {
  return await ApiClient.post('/account_payment', () => {}, values)
}

export const patchAccountPayment = async (values: AccountPaymentUpdateValues) => {
  return await ApiClient.patch('/account_payment', () => {}, values)
}
