import LocalStorage from '@/utils/localStorage'

const KEY = 'isClickedOnConnectionPage'

export const getStorageIsClickedOnConnectionPage = () => {
  return LocalStorage.getItem(KEY)
}

export const setStorageIsClickedOnConnectionPage = (value: boolean) => {
  return LocalStorage.setItem(KEY, value)
}

export const removeStorageIsClickedOnConnectionPage = () => {
  LocalStorage.removeItem(KEY)
}
