import React, { FC } from 'react'
import clsx from 'clsx'
import Typography from '@/components/v2/atoms/Typography'
import Button from '@/components/v2/atoms/Button'

interface TabProps {
  /** クラス名 */
  className?: string
  /** タイトルクラス名 */
  titleClassName?: string
  /** 現在の番号 */
  currentIndex?: number
  /** オプション */
  options: string[]
  /** onClickメソッド */
  onClick?: (index: number) => void
}

const Tab: FC<TabProps> = (props) => {
  const { className, titleClassName, currentIndex, options, onClick } = props

  return (
    <div className={`${className} flex`}>
      {options.map((name, index) => (
        <Button
          key={name}
          className={clsx('border-b-3 border-solid flex-1', {
            ['border-secondary-500']: index === currentIndex,
            ['border-black-300 hover:bg-black-200']: index !== currentIndex,
          })}
          onClick={() => onClick?.(index)}
        >
          <Typography
            className={clsx(`${titleClassName} text-14 md:text-18`, {
              ['text-black-800']: index === currentIndex,
              ['text-black-700']: index !== currentIndex,
            })}
            isBold
          >
            <span dangerouslySetInnerHTML={{ __html: name }}></span>
          </Typography>
        </Button>
      ))}
    </div>
  )
}

export default Tab
