import ApiClient from '@/api/apiClient'
import { LearningVideoResponse } from '@/models/commonLearningVideosModel'

export const getLearningVideos = async () => {
  return await ApiClient.get(
    '/learning_videos',
    (response: LearningVideoResponse[]) => response,
  )
}

export const getLearningVideo = async (id: number) => {
  return await ApiClient.get(
    `/learning_videos/${id}`,
    (response: LearningVideoResponse) => response,
  )
}
