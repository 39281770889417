import React, { FC, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Typography from '@/components/v2/atoms/Typography'
import {
  INSURANCE_TYPE1,
  INSURANCE_TYPE2,
  PURPOSE,
  CONTRACTOR_TYPE,
  INSURED_TYPE,
  convertContractor,
  convertInsured,
  convertPeriodFinishAt,
  convertPeriod,
  convertCancellationRefundAmountManyen,
  convertCancellationRefundAt,
  convertMaturityMoney,
  convertPurpose,
  convertInsuranceFeePaymentOccurrenceInMonths,
  convertInsuranceType1,
  convertInsuranceType2,
  convertNonLifeInsuranceType1,
  convertNonLifeInsuranceFeeMonths,
} from '@/models/commonInsurancesModelFunc'
import {
  convertDateToTwoTypesYearsDateFormat,
  defaultToEmptyString,
  convertLocaleString,
} from '@/models/commonsModelFunc'
import { RootState } from '@/store'
import SimulationAccordion from '@/templates/v2/simulations/_simulationAccordion'
import Modal from '@/components/v2/atoms/Modal'
import Button from '@/components/v2/atoms/Button'
import SimulationNewButton from '@/templates/v2/simulations/_simulationNewButton'
import MyDataInsuranceEditForm from '@/templates/v2/myData/insurances/_Form'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import { fetchInputInsurances } from '@/containers/inputs/inputInsurancesSlice'
import { updateResetFlg } from '@/containers/futures/futureBasesSlice'

export const InsuranceTable: FC<{ onUpdate?: () => void }> = (props) => {
  const dispatch = useDispatch()
  const { inputInsurances } = useSelector((state: RootState) => state.inputInsurances)
  const [modalState, setModalState] = useState<{ isOpen: boolean; id?: number }>({
    isOpen: false,
  })

  return (
    <>
      {inputInsurances.length === 0 && (
        <Typography className="text-[14px] md:text-[16px] text-center" isBold>
          保険が登録されていません。
        </Typography>
      )}

      {inputInsurances.map((item: any, index: number) => (
        <div key={item.id} className="mb-[20px] last:mb-0">
          <div className="flex justify-between">
            <CircleTitle className="pb-[5px]" title={`保険${index + 1}`} />
            <div className="flex justify-end mb-[8px]">
              <Button onClick={() => setModalState({ isOpen: true, id: item.id })}>
                <i className="ico edit text-[14px] text-black-700" />
                <Typography className="pl-[5px] text-[14px] text-black-700">
                  編集
                </Typography>
              </Button>
            </div>
          </div>

          <table className="w-[100%] text-[12px] md:text-[14px]">
            <tbody>
              <tr className="h-[45px] odd:bg-black-100">
                <td className="w-[30%] px-[16px]">
                  <Typography isBold>契約年月</Typography>
                </td>
                <td className="w-[70%] px-[16px]">
                  <Typography>
                    {convertDateToTwoTypesYearsDateFormat(
                      item?.contracted_at,
                      'yearMonthWithJapaneseYear',
                    )}
                  </Typography>
                </td>
              </tr>
              <tr className="h-[45px] odd:bg-black-100">
                <td className="px-[16px]">
                  <Typography isBold>保険会社名</Typography>
                </td>
                <td className="px-[16px]">
                  <Typography>{defaultToEmptyString(item?.company_name)}</Typography>
                </td>
              </tr>
              <tr className="h-[45px] odd:bg-black-100">
                <td className="px-[16px]">
                  <Typography isBold>保険種別</Typography>
                </td>
                <td className="px-[16px]">
                  {item?.['is_life_insurance?'] ? (
                    <Typography>{`${convertInsuranceType1(
                      item?.insurance_type1,
                    )} ${convertInsuranceType2(item?.insurance_type2)}`}</Typography>
                  ) : (
                    <Typography>
                      {convertNonLifeInsuranceType1(item?.insurance_type1)}
                    </Typography>
                  )}
                </td>
              </tr>
              <tr className="h-[45px] odd:bg-black-100">
                <td className="px-[16px]">
                  <Typography isBold>利用目的</Typography>
                </td>
                <td className="px-[16px]">
                  <Typography>{convertPurpose(item?.purpose)}</Typography>
                </td>
              </tr>
              {item?.['is_life_insurance?'] && (
                <>
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="px-[16px]">
                      <Typography isBold>契約者</Typography>
                    </td>
                    <td className="px-[16px]">
                      <Typography>
                        {convertContractor(item?.contractor_type, item?.contractor_name)}
                      </Typography>
                    </td>
                  </tr>
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="px-[16px]">
                      <Typography isBold>被保険者</Typography>
                    </td>
                    <td className="px-[16px]">
                      <Typography>
                        {convertInsured(item?.insured_type, item?.insured_name)}
                      </Typography>
                    </td>
                  </tr>
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="px-[16px]">
                      <Typography isBold>保険期間</Typography>
                    </td>
                    <td className="px-[16px]">
                      <Typography>
                        {item?.insured_type === INSURED_TYPE.otherPersonInsured
                          ? convertPeriodFinishAt(
                              item?.insurance_period_type,
                              item?.insurance_period_finish_at,
                              item?.insured_type,
                              item?.insured_name,
                              'insured',
                            )
                          : convertPeriod(
                              item?.insurance_period_type,
                              item?.insurance_period_finish_at_age,
                              item?.insured_type,
                              item?.insured_name,
                              'insured',
                            )}
                      </Typography>
                    </td>
                  </tr>
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="px-[16px]">
                      <Typography isBold>
                        保険料
                        <br className="block md:hidden" />
                        払い込み
                      </Typography>
                    </td>
                    <td className="px-[16px]">
                      <Typography>
                        {item?.contractor_type === CONTRACTOR_TYPE.otherPersonContract
                          ? convertPeriodFinishAt(
                              item?.insurance_fee_payment_period_type,
                              item?.insurance_fee_payment_period_finish_at,
                              item?.contractor_type,
                              item?.contractor_name,
                              'contractor',
                            )
                          : convertPeriod(
                              item?.insurance_fee_payment_period_type,
                              item?.insurance_fee_payment_period_finish_at_age,
                              item?.contractor_type,
                              item?.contractor_name,
                              'contractor',
                            )}
                      </Typography>
                    </td>
                  </tr>
                </>
              )}
              <tr className="h-[45px] odd:bg-black-100">
                <td className="px-[16px]">
                  <Typography isBold>保険料</Typography>
                </td>
                <td className="px-[16px]">
                  <Typography>
                    {`${
                      item?.['is_life_insurance?']
                        ? convertInsuranceFeePaymentOccurrenceInMonths(
                            item?.insurance_fee_payment_occurrence_in_months,
                          )
                        : convertNonLifeInsuranceFeeMonths(
                            item?.insurance_fee_payment_occurrence_in_months,
                          )
                    } ${convertLocaleString(item?.insurance_fee)}円`}
                  </Typography>
                </td>
              </tr>
              {item?.insurance_type1 == INSURANCE_TYPE1.life &&
                item?.insurance_type2 !== INSURANCE_TYPE2.incomeSecurity && (
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="px-[16px]">
                      <Typography isBold>
                        死亡保険
                        <br className="block md:hidden" />
                        金額
                      </Typography>
                    </td>
                    <td className="px-[16px]">
                      <Typography>
                        {`${convertLocaleString(item?.insurance_amount_manyen)}万円`}
                      </Typography>
                    </td>
                  </tr>
                )}
              {item.insurance_type1 === INSURANCE_TYPE1.life &&
                item.insurance_type2 === INSURANCE_TYPE2.wholeLife &&
                item.purpose === PURPOSE.savings && (
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="px-[16px]">
                      <Typography isBold>解約返戻金</Typography>
                    </td>
                    <td className="px-[16px]">
                      <Typography>
                        {item?.insured_type === INSURED_TYPE.otherPersonInsured
                          ? convertCancellationRefundAt(
                              item?.cancellation_refund_at,
                              item?.cancellation_refund_amount_manyen,
                              item?.cancellation_refund_rate_percent,
                              item?.cancellation_refund_amount_input_type,
                            )
                          : convertCancellationRefundAmountManyen(
                              item?.cancellation_refund_at_age,
                              item?.cancellation_refund_amount_manyen,
                              item?.cancellation_refund_rate_percent,
                              item?.cancellation_refund_amount_input_type,
                              item?.contractor_type,
                              item?.contractor_name,
                            )}
                      </Typography>
                    </td>
                  </tr>
                )}
              {item.insurance_type1 === INSURANCE_TYPE1.life &&
                item.insurance_type2 === INSURANCE_TYPE2.incomeSecurity && (
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="px-[16px]">
                      <Typography isBold>保険月額</Typography>
                    </td>
                    <td className="px-[16px]">
                      <Typography>
                        {`月額 ${item.insured_income_monthly_manyen}万円`}
                      </Typography>
                    </td>
                  </tr>
                )}
              {item?.insurance_type1 === INSURANCE_TYPE1.life &&
                item?.insurance_type2 === INSURANCE_TYPE2.endowment && (
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="px-[16px]">
                      <Typography isBold>満期金</Typography>
                    </td>
                    <td className="px-[16px]">
                      <Typography>
                        {convertMaturityMoney(
                          item.maturity_money_type,
                          item.maturity_money,
                        )}
                      </Typography>
                    </td>
                  </tr>
                )}
              {item?.insurance_type1 === INSURANCE_TYPE1.personalAnnuity && (
                <>
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="px-[16px]">
                      <Typography isBold>受取年齢</Typography>
                    </td>
                    <td className="px-[16px]">
                      <Typography>
                        {`${defaultToEmptyString(item?.receiving_pension_at_age)}歳`}
                      </Typography>
                    </td>
                  </tr>
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="px-[16px]">
                      <Typography isBold>受取額</Typography>
                    </td>
                    <td className="px-[16px]">
                      <Typography>
                        {`年間 ${convertLocaleString(item.receiving_pension_amount)}円`}
                      </Typography>
                    </td>
                  </tr>
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="px-[16px]">
                      <Typography isBold>受取期間</Typography>
                    </td>
                    <td className="px-[16px]">
                      <Typography>
                        {`${defaultToEmptyString(item.receiving_years)}年`}
                      </Typography>
                    </td>
                  </tr>
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="px-[16px]">
                      <Typography isBold>保証期間</Typography>
                    </td>
                    <td className="px-[16px]">
                      <Typography>
                        {`${defaultToEmptyString(item.receiving_guaranteed_years)}年`}
                      </Typography>
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </div>
      ))}

      <SimulationNewButton onClick={() => setModalState({ isOpen: true })} />

      <Modal
        className="lg:w-[1024px] w-[95%]"
        isOpen={modalState.isOpen}
        onClose={() => setModalState({ isOpen: false })}
      >
        <CircleTitle className="pb-[32px]" title="現在加入中の保険" />
        <MyDataInsuranceEditForm
          insuranceId={modalState.id}
          handleCancel={() => setModalState({ isOpen: false })}
          submitCallback={() => {
            dispatch(fetchInputInsurances())
            dispatch(updateResetFlg())
            setModalState({ isOpen: false })
            if (props.onUpdate != null) props.onUpdate()
          }}
        />
      </Modal>
    </>
  )
}

const InputInsuranceBody: FC<{ onUpdate?: () => void }> = (props) => {
  return (
    <SimulationAccordion className="pb-[16px]" title="現在加入中の保険">
      <InsuranceTable onUpdate={props.onUpdate} />
    </SimulationAccordion>
  )
}

export default InputInsuranceBody
