import {
  filter as _filter,
  pick as _pick,
  isNull as _isNull,
  isUndefined as _isUndefined,
} from 'lodash'
import { API_BOOLEAN_FLAG } from '@/constants/formItem/commons'
import {
  isWithChartLoan,
  isWithCalcLoan,
  isFlatLoan,
  isPairLoan,
  LOAN_FOR,
  LOAN_TYPE,
  REPAIR_RESERVE_FUND_RATE_TYPE,
} from '@/models/commonHousingModelFunc'
import {
  API_FLAG,
  castNumberWithoutOperator,
  convertDateDay,
  convertDateMonth,
  convertDateYear,
  isEvenNumber,
  isNotEmptyValue,
  THIS_YEAR_NUMBER,
} from '@/models/commonsModelFunc'
import {
  isMansionType,
  isHousingType2Custom,
  isWithLoan,
} from '@/models/commonHousingModelFunc'
import { PERSON_FLG } from '@/models/familyStructuresModelFunc'
import { InputHousingRent, RentType } from '@/models/inputs/inputHousingRentsModel'
import { INPUT_HOUSING_RENT_TYPE } from '@/models/inputs/inputHousingRentsModelFunc'
import { InputHousingSelfOwned } from '@/models/inputs/inputHousingSelfOwnedModel'
import { isSplitedLoan } from '@/models/inputs/inputHousingSelfOwnedModelFunc'
import { InputHousingTransfer } from '@/models/inputs/inputHousingTransfersModel'
import { InputLoan, LoanFor } from '@/models/inputs/inputLoansModel'
import { isOneLoanPattern, isTwoLoanPattern } from '@/models/inputs/inputLoansModelFunc'
import { RATE_TYPE } from '@/models/inputs/inputHousingsModelFunc'

const selectPersonFlg = (inputLoan: Partial<InputLoan>, loanIndex: number) => {
  if (inputLoan.loan_type === LOAN_TYPE.spouseOnly) return PERSON_FLG.spouse
  if (!isPairLoan(inputLoan.loan_type)) return PERSON_FLG.person

  return isEvenNumber(loanIndex) ? PERSON_FLG.person : PERSON_FLG.spouse
}

const convertLoanChartNonRequiredValues = (inputLoan: Partial<InputLoan>) => {
  if (isPairLoan(inputLoan.loan_type)) {
    inputLoan.input_loan_chart_attributes!.life_insurance = null
  }
  if (!isPairLoan(inputLoan.loan_type)) {
    inputLoan.input_loan_chart_attributes!.married_couple_group_shin = null
  }
  if (Number(inputLoan.input_loan_chart_attributes!.bonus_flg) === API_FLAG.off) {
    inputLoan.input_loan_chart_attributes!.equal_redemption_installment_principal_bonus = null
    inputLoan.input_loan_chart_attributes!.repayment_rate_change_bonus = null
    inputLoan.input_loan_chart_attributes!.remaining_principal_bonus_total = null
  }

  return inputLoan
}

export const buildLoanChartSubmitValues = (
  selfOwnedAttributes: Partial<InputHousingSelfOwned>,
) => {
  const { input_loans_attributes: loansAttributes } = selfOwnedAttributes

  loansAttributes!.map((inputLoan, loanIndex) => {
    // 返済計画表なしのローンの場合
    // input_loan_chart_attributes を null にして返却
    if (!isWithChartLoan(inputLoan.repayment_chart)) {
      inputLoan.input_loan_chart_attributes = null

      return inputLoan
    }

    let {
      input_loan_chart_attributes: chartAttributes,
    } = convertLoanChartNonRequiredValues(inputLoan)

    chartAttributes!.person_flg = selectPersonFlg(inputLoan, loanIndex)
    chartAttributes!.repayment_end_at = convertDateDay(
      chartAttributes!.repayment_end_at_year,
      chartAttributes!.repayment_end_at_month,
      chartAttributes!.repayment_end_at_day,
    )
    chartAttributes!.repayment_date_this_month_at = convertDateDay(
      chartAttributes!.repayment_date_this_month_at_year,
      chartAttributes!.repayment_date_this_month_at_month,
      chartAttributes!.repayment_date_this_month_at_day,
    )

    const loanRatesAttributes = _filter(
      chartAttributes!.input_loan_rates_attributes,
      (loanRate) => isNotEmptyValue(loanRate.changed_rate_percent),
    )
    chartAttributes!.input_loan_rates_attributes =
      Number(chartAttributes?.rate_change_monthly) === API_FLAG.on
        ? [
            ...loanRatesAttributes!,
            ...chartAttributes!.input_loan_rates_monthly_attributes!,
          ]
        : [...loanRatesAttributes!]

    if (Number(chartAttributes!.bonus_flg) === API_FLAG.on) {
      // MEMO: ボーナスの金利は毎月払いの金利と同様のため値をコピー
      chartAttributes!.repayment_rate_change_bonus = chartAttributes!.rate_change_monthly
      chartAttributes!.input_loan_rates_bonus_attributes = [
        ...chartAttributes!.input_loan_rates_attributes.map((loanRate) => ({
          ...loanRate,
          rate_type: RATE_TYPE.bonus,
        })),
      ]
    }
    chartAttributes!.input_loan_rates_attributes =
      Number(chartAttributes!.bonus_flg) === API_FLAG.on
        ? [
            ...chartAttributes!.input_loan_rates_attributes!,
            ...chartAttributes!.input_loan_rates_bonus_attributes!,
          ]
        : [...chartAttributes!.input_loan_rates_attributes!]

    chartAttributes!.input_loan_rates_attributes!.forEach((rateItem) => {
      if (!rateItem) return

      rateItem.rate_start_at = convertDateMonth(
        rateItem.rate_start_at_year,
        rateItem.rate_start_at_month,
      )
    })

    chartAttributes = _pick(chartAttributes, [
      'person_flg',
      'repayment_end_at',
      'bonus_flg',
      'rate_change_monthly',
      'equal_redemption_installment_principal_monthly',
      'repayment_rate_change_bonus',
      'equal_redemption_installment_principal_bonus',
      'repayment_date_this_month_at',
      'remaining_principal_bonus_total',
      'remaining_principal_monthly_total',
      'life_insurance',
      'married_couple_group_shin',
      'loan_deduction',
      'input_loan_rates_attributes',
    ])

    inputLoan.input_loan_chart_attributes = chartAttributes

    return inputLoan
  })

  return loansAttributes
}

const convertLoanCalculationNonRequiredValues = (
  inputLoan: Partial<InputLoan>,
  loanIndex: number,
) => {
  if (isPairLoan(inputLoan.loan_type)) {
    inputLoan.input_loan_calculation_attributes!.life_insurance = null
  }
  if (isFlatLoan(inputLoan.loan_lender) && !isEvenNumber(loanIndex)) {
    inputLoan.input_loan_calculation_attributes!.advance_repayment = null
  }
  if (!isPairLoan(inputLoan.loan_type)) {
    inputLoan.input_loan_calculation_attributes!.married_couple_group_shin = null
  }

  return inputLoan
}

export const buildLoanCalculationSubmitValues = (
  selfOwnedAttributes: Partial<InputHousingSelfOwned>,
) => {
  const { input_loans_attributes: loansAttributes } = selfOwnedAttributes

  loansAttributes!.map((inputLoan, loanIndex) => {
    // 返済計画表ありのローンの場合
    // input_loan_calculation_attributes を null にして返却
    if (!isWithCalcLoan(inputLoan.repayment_chart)) {
      inputLoan.input_loan_calculation_attributes = null

      return inputLoan
    }

    let {
      input_loan_calculation_attributes: calcAttributes,
    } = convertLoanCalculationNonRequiredValues(inputLoan, loanIndex)

    calcAttributes!.person_flg = selectPersonFlg(inputLoan, loanIndex)

    switch (loanIndex) {
      case 1:
        if (isFlatLoan(inputLoan.loan_lender)) {
          calcAttributes = {
            ...loansAttributes![0].input_loan_calculation_attributes,
            repayment_monthly_manyen: loansAttributes![0]
              .input_loan_calculation_attributes?.started_borrowing_amount_manyen,
            repayment_period: loansAttributes![0].input_loan_calculation_attributes
              ?.repayment_period,
            loan_deduction: loansAttributes![0].input_loan_calculation_attributes
              ?.loan_deduction,
            life_insurance: loansAttributes![0].input_loan_calculation_attributes
              ?.life_insurance,
            input_calculation_rates_attributes:
              calcAttributes?.input_calculation_rates_attributes,
            input_calculation_rates_other_attributes:
              calcAttributes?.input_calculation_rates_other_attributes ?? [],
            input_advances_attributes: calcAttributes?.input_advances_attributes ?? [],
            started_borrowing_amount_manyen:
              calcAttributes?.started_borrowing_amount_manyen,
          }
        }

        break

      case 3:
        if (isFlatLoan(inputLoan.loan_lender)) {
          calcAttributes = {
            ...loansAttributes![2].input_loan_calculation_attributes,
            repayment_monthly_manyen: loansAttributes![2]
              .input_loan_calculation_attributes?.started_borrowing_amount_manyen,
            repayment_period: loansAttributes![2].input_loan_calculation_attributes
              ?.repayment_period,
            loan_deduction: loansAttributes![2].input_loan_calculation_attributes
              ?.loan_deduction,
            life_insurance: loansAttributes![2].input_loan_calculation_attributes
              ?.life_insurance,
            input_calculation_rates_attributes:
              calcAttributes?.input_calculation_rates_attributes ?? [],
            input_calculation_rates_other_attributes:
              calcAttributes?.input_calculation_rates_other_attributes ?? [],
            started_borrowing_amount_manyen:
              calcAttributes?.started_borrowing_amount_manyen,
            input_advances_attributes: calcAttributes?.input_advances_attributes ?? [],
          }
        }

        break

      default:
        break
    }

    calcAttributes!.repayment_monthly_manyen = calcAttributes!.started_borrowing_amount_manyen
    // MEMO: フォームに値はないが、計算に必要なため値を代入
    calcAttributes!.bonus_flg = API_FLAG.off

    calcAttributes!.input_calculation_rates_attributes = _filter(
      [...calcAttributes!.input_calculation_rates_attributes!],
      (item) => isNotEmptyValue(item.changed_rate_percent),
    )
    calcAttributes!.input_calculation_rates_other_attributes = _filter(
      [...calcAttributes!.input_calculation_rates_other_attributes!],
      (otherItem) =>
        isNotEmptyValue(otherItem.changed_rate_percent) &&
        otherItem.changed_rate_percent != 0,
    )

    calcAttributes!.input_calculation_rates_other_attributes!.forEach((calcRateItem) => {
      if (!calcRateItem) return // なぜか undefined が入ってくるケースがあるのでその場合はスキップ
      calcRateItem.rate_start_at = convertDateYear(calcRateItem.rate_start_at_year)
    })

    calcAttributes!.input_calculation_rates_attributes! = [
      ...calcAttributes!.input_calculation_rates_attributes!,
      ...calcAttributes!.input_calculation_rates_other_attributes!,
    ]

    calcAttributes!.input_advances_attributes =
      Number(calcAttributes?.advance_repayment) === API_FLAG.off ||
      _isNull(calcAttributes?.advance_repayment)
        ? []
        : [
            ...calcAttributes!.input_advances_attributes!.map((inputAdvance) => ({
              repayment_amount: inputAdvance.repayment_amount,
              repayment_type: inputAdvance.repayment_type,
              advance_repayment_month_at: convertDateMonth(
                inputAdvance.advance_repayment_month_at_year,
                inputAdvance.advance_repayment_month_at_month,
              ),
            })),
          ]

    inputLoan.input_loan_calculation_attributes = calcAttributes

    return inputLoan
  })

  loansAttributes!.map((inputLoan) => {
    // 返済計画表ありのローンの場合
    // input_loan_calculation_attributes を null にして返却
    if (!isWithCalcLoan(inputLoan.repayment_chart)) {
      inputLoan.input_loan_calculation_attributes = null

      return inputLoan
    }

    let { input_loan_calculation_attributes: calcAttributes } = inputLoan

    calcAttributes = _pick(calcAttributes, [
      'person_flg',
      'advance_repayment',
      'started_borrowing_amount_manyen',
      'bonus_flg',
      'repayment_monthly',
      'repayment_monthly_manyen',
      'repayment_bonus',
      'repayment_bonus_manyen',
      'repayment_period',
      'repayment_rate',
      'life_insurance',
      'married_couple_group_shin',
      'loan_deduction',
      'input_advances_attributes',
      'input_calculation_rates_attributes',
    ])

    inputLoan.input_loan_calculation_attributes = calcAttributes

    return inputLoan
  })

  return loansAttributes
}

const selectLoanFor = (
  isSplited: boolean,
  inputLoan: Partial<InputLoan>,
  loanIndex: number,
) => {
  if (!isSplited) return null

  switch (Number(loanIndex)) {
    case 0:
      return LOAN_FOR.land as LoanFor

    case 1:
      return isPairLoan(inputLoan.loan_type) ||
        (isWithCalcLoan(inputLoan.repayment_chart) && isFlatLoan(inputLoan.loan_lender))
        ? (LOAN_FOR.land as LoanFor)
        : (LOAN_FOR.building as LoanFor)

    case 2:
    case 3:
      return LOAN_FOR.building as LoanFor

    default:
      return null
  }
}

const selectIsFlatApplied = (
  isFlat: boolean,
  isWithChartLoan: boolean,
  loanIndex: number,
) => {
  if (!isFlat) return null

  if (isFlat && isWithChartLoan) return API_BOOLEAN_FLAG.on

  return isEvenNumber(loanIndex) ? API_BOOLEAN_FLAG.on : API_BOOLEAN_FLAG.off
}

export const buildSelfOwnedLoanSubmitValues = (
  selfOwnedAttributes: Partial<InputHousingSelfOwned>,
) => {
  let { input_loans_attributes: loansAttributes } = selfOwnedAttributes

  // 共通処理
  loansAttributes!.map((inputLoan, loanIndex) => {
    inputLoan.repayment_start_at = convertDateYear(selfOwnedAttributes.purchased_at_year)
    inputLoan.loan_for = selectLoanFor(
      isSplitedLoan(selfOwnedAttributes),
      inputLoan,
      loanIndex,
    )
    inputLoan.is_flat_applied = selectIsFlatApplied(
      isFlatLoan(inputLoan.loan_lender),
      isWithChartLoan(inputLoan.repayment_chart),
      loanIndex,
    )

    return inputLoan
  })

  //----- ローン借りない
  if (!isWithLoan(selfOwnedAttributes.loan_type1)) {
    return (loansAttributes = [])
  }

  if (
    !isSplitedLoan(selfOwnedAttributes) &&
    isTwoLoanPattern(loansAttributes![0]) &&
    !_isUndefined(loansAttributes?.[1])
  ) {
    loansAttributes![1].loan_type = loansAttributes![0]?.loan_type
    loansAttributes![1].loan_lender = loansAttributes![0]?.loan_lender
    loansAttributes![1].repayment_chart = loansAttributes![0]?.repayment_chart
  }
  if (
    isSplitedLoan(selfOwnedAttributes) &&
    isTwoLoanPattern(loansAttributes![0]) &&
    !_isUndefined(loansAttributes?.[1])
  ) {
    loansAttributes![1].loan_type = loansAttributes![0]?.loan_type
    loansAttributes![1].loan_lender = loansAttributes![0]?.loan_lender
    loansAttributes![1].repayment_chart = loansAttributes![0]?.repayment_chart
  }
  if (
    isSplitedLoan(selfOwnedAttributes) &&
    isTwoLoanPattern(loansAttributes![2]) &&
    !_isUndefined(loansAttributes?.[3])
  ) {
    loansAttributes![3].loan_type = loansAttributes![2]?.loan_type
    loansAttributes![3].loan_lender = loansAttributes![2]?.loan_lender
    loansAttributes![3].repayment_chart = loansAttributes![2]?.repayment_chart
  }
  // フラットローン部分の入力はモーダルで行われないため、裏側で調整
  if (
    isFlatLoan(loansAttributes![0]?.loan_lender) &&
    isWithCalcLoan(loansAttributes![0]?.repayment_chart) &&
    !_isUndefined(loansAttributes?.[1])
  ) {
    loansAttributes![1].input_loan_calculation_attributes! = {
      ...loansAttributes![1].input_loan_calculation_attributes!,
      ..._pick(loansAttributes![0].input_loan_calculation_attributes, [
        'repayment_period',
        'loan_deduction',
        'person_flg',
      ]),
    }
    loansAttributes![1].input_loan_calculation_attributes!.input_calculation_rates_other_attributes = []
    loansAttributes![1].input_loan_calculation_attributes!.repayment_monthly_manyen = loansAttributes![0].input_loan_calculation_attributes!.started_borrowing_amount_manyen
    loansAttributes![1].input_loan_calculation_attributes!.bonus_flg = API_FLAG.off
    loansAttributes![1].input_loan_calculation_attributes!.advance_repayment = null
  }
  // フラットローン部分の入力はモーダルで行われないため、裏側で調整
  if (
    isFlatLoan(loansAttributes![2]?.loan_lender) &&
    isWithCalcLoan(loansAttributes![2]?.repayment_chart)
  ) {
    loansAttributes![3].input_loan_calculation_attributes! = {
      ...loansAttributes![3].input_loan_calculation_attributes!,
      ..._pick(loansAttributes![2].input_loan_calculation_attributes, [
        'repayment_period',
        'loan_deduction',
        'person_flg',
      ]),
    }
    loansAttributes![3].input_loan_calculation_attributes!.input_calculation_rates_other_attributes = []
    loansAttributes![3].input_loan_calculation_attributes!.repayment_monthly_manyen = loansAttributes![2].input_loan_calculation_attributes!.started_borrowing_amount_manyen
    loansAttributes![3].input_loan_calculation_attributes!.bonus_flg = API_FLAG.off
    loansAttributes![3].input_loan_calculation_attributes!.advance_repayment = null
  }

  //----- ローン借りる
  // 返済計画表ありパターンの処理
  loansAttributes = buildLoanChartSubmitValues(selfOwnedAttributes)

  // 返済計画表なしパターンの処理
  loansAttributes = buildLoanCalculationSubmitValues(selfOwnedAttributes)

  // ローン１つ 1
  // ローン借りない + 分割なし
  if (
    isWithLoan(selfOwnedAttributes.loan_type1) &&
    !isSplitedLoan(selfOwnedAttributes) &&
    isOneLoanPattern(loansAttributes![0])
  ) {
    loansAttributes = [{ ...loansAttributes![0] }]
  }

  // ローン２つ
  // ローン借りる + 分割なし
  if (
    isWithLoan(selfOwnedAttributes.loan_type1) &&
    !isSplitedLoan(selfOwnedAttributes) &&
    isTwoLoanPattern(loansAttributes![0])
  ) {
    loansAttributes = [{ ...loansAttributes![0] }, { ...loansAttributes![1] }]
  }

  // ローン２つ
  // ローン借りる + 分割あり
  if (
    isWithLoan(selfOwnedAttributes.loan_type1) &&
    isSplitedLoan(selfOwnedAttributes) &&
    isOneLoanPattern(loansAttributes![0]) &&
    isOneLoanPattern(loansAttributes![2])
  ) {
    loansAttributes = [{ ...loansAttributes![0] }, { ...loansAttributes![2] }]
  }

  // ローン３つ
  // ローン借りる + 分割あり
  //   土地用：(返済計画表あり・ペアローン) or (返済計画表なし・ペアローン/フラットローン)
  //   建物用：単独ローン and (返済計画表あり or 返済計画表なし・民間ローン)
  if (
    isWithLoan(selfOwnedAttributes.loan_type1) &&
    isSplitedLoan(selfOwnedAttributes) &&
    isTwoLoanPattern(loansAttributes![0]) &&
    isOneLoanPattern(loansAttributes![2])
  ) {
    loansAttributes = [
      { ...loansAttributes![0] },
      { ...loansAttributes![1] },
      { ...loansAttributes![2] },
    ]
  }

  // ローン３つ
  // ローン借りる + 分割あり
  //   土地用：単独ローン and (返済計画表あり or 返済計画表なし・民間ローン)
  //   建物用：(返済計画表あり・ペアローン) or (返済計画表なし・ペアローン/フラットローン)
  if (
    isWithLoan(selfOwnedAttributes.loan_type1) &&
    isSplitedLoan(selfOwnedAttributes) &&
    isOneLoanPattern(loansAttributes![0]) &&
    isTwoLoanPattern(loansAttributes![2])
  ) {
    loansAttributes = [
      { ...loansAttributes![0] },
      { ...loansAttributes![2] },
      { ...loansAttributes![3] },
    ]
  }

  // ローン４つ
  // ローン借りる + 分割あり
  //   土地用：(返済計画表あり・ペアローン) or (返済計画表なし・ペアローン/フラットローン)
  //   建物用：(返済計画表あり・ペアローン) or (返済計画表なし・ペアローン/フラットローン)
  if (
    isWithLoan(selfOwnedAttributes.loan_type1) &&
    isSplitedLoan(selfOwnedAttributes) &&
    isTwoLoanPattern(loansAttributes![0]) &&
    isTwoLoanPattern(loansAttributes![2])
  ) {
    loansAttributes = [
      { ...loansAttributes![0] },
      { ...loansAttributes![1] },
      { ...loansAttributes![2] },
      { ...loansAttributes![3] },
    ]
  }

  selfOwnedAttributes.input_loans_attributes = loansAttributes

  // 共通処理
  loansAttributes!.map((inputLoan) => {
    inputLoan = _pick(inputLoan, [
      'repayment_chart',
      'repayment_start_at',
      'loan_type',
      'loan_lender',
      'is_flat_applied',
      'input_loan_chart_attributes',
      'input_loan_calculation_attributes',
      'loan_for',
    ])

    return inputLoan
  })

  return loansAttributes
}

const convertHousingSelfOwnedNonRequiredValues = (
  selfOwnedAttributes: Partial<InputHousingSelfOwned>,
) => {
  if (!isMansionType(selfOwnedAttributes.house_type)) {
    selfOwnedAttributes.repair_reserve_fund = null
    selfOwnedAttributes.repair_reserve_fund_increase_type = null
    selfOwnedAttributes.management_fee_monthly = null
  }
  if (
    !isMansionType(selfOwnedAttributes.house_type) ||
    selfOwnedAttributes.repair_reserve_fund_increase_type ===
      REPAIR_RESERVE_FUND_RATE_TYPE.noSet
  ) {
    selfOwnedAttributes.repair_reserve_fund_amount_start = null
    selfOwnedAttributes.repair_reserve_fund_amount_finish = null
    selfOwnedAttributes.repair_reserve_fund_increase_start = null
    selfOwnedAttributes.repair_reserve_fund_increase_finish = null
    selfOwnedAttributes.repair_reserve_fund_increase_span = null
    selfOwnedAttributes.repair_reserve_fund_increase_percent = null
  }
  if (
    selfOwnedAttributes.repair_reserve_fund_increase_type ===
    REPAIR_RESERVE_FUND_RATE_TYPE.change
  ) {
    selfOwnedAttributes.repair_reserve_fund_amount = null
    selfOwnedAttributes.repair_reserve_fund_amount_start = null
    selfOwnedAttributes.repair_reserve_fund_amount_finish = null
  }
  if (
    selfOwnedAttributes!.repair_reserve_fund_increase_type ===
    REPAIR_RESERVE_FUND_RATE_TYPE.fixed
  ) {
    selfOwnedAttributes.repair_reserve_fund_increase_start = null
    selfOwnedAttributes.repair_reserve_fund_increase_finish = null
    selfOwnedAttributes.repair_reserve_fund_increase_span = null
    selfOwnedAttributes.repair_reserve_fund_increase_percent = null
  }
  if (!isHousingType2Custom(selfOwnedAttributes.housing_type2)) {
    selfOwnedAttributes.loan_type2 = null
  }
  // 管理費・修繕積立金: 詳細入力を選択
  if (selfOwnedAttributes.is_management_and_fund_total === API_BOOLEAN_FLAG.off) {
    selfOwnedAttributes.management_fee_monthly_and_repair_reserve_fund_total = null
  }

  // 管理費・修繕積立金: 総額入力を選択
  if (selfOwnedAttributes.is_management_and_fund_total === API_BOOLEAN_FLAG.on) {
    selfOwnedAttributes.management_fee_monthly = null
    selfOwnedAttributes.repair_reserve_fund = null
    selfOwnedAttributes.repair_reserve_fund_increase_type = null
    selfOwnedAttributes.repair_reserve_fund_amount = null
    selfOwnedAttributes.repair_reserve_fund_amount_start = null
    selfOwnedAttributes.repair_reserve_fund_amount_finish = null
    selfOwnedAttributes.repair_reserve_fund_increase_start = null
    selfOwnedAttributes.repair_reserve_fund_increase_span = null
    selfOwnedAttributes.repair_reserve_fund_increase_finish = null
    selfOwnedAttributes.repair_reserve_fund_increase_percent = null
  }

  if (isNotEmptyValue(selfOwnedAttributes.property_price_manyen)) {
    selfOwnedAttributes.property_price_manyen = castNumberWithoutOperator(
      selfOwnedAttributes.property_price_manyen,
    )
  }

  if (isNotEmptyValue(selfOwnedAttributes.property_price_land_manyen)) {
    selfOwnedAttributes.property_price_land_manyen = castNumberWithoutOperator(
      selfOwnedAttributes.property_price_land_manyen,
    )
  }

  if (isNotEmptyValue(selfOwnedAttributes.property_price_building_manyen)) {
    selfOwnedAttributes.property_price_building_manyen = castNumberWithoutOperator(
      selfOwnedAttributes.property_price_building_manyen,
    )
  }

  return selfOwnedAttributes
}

export const buildHousingSelfOwnedSubmitValues = (
  selfOwnedAttributes: Partial<InputHousingSelfOwned>,
) => {
  selfOwnedAttributes.repair_costs_span = Math.round(
    selfOwnedAttributes.repair_costs_span! * 12,
  )
  selfOwnedAttributes!.constructed_at = convertDateYear(
    selfOwnedAttributes.constructed_at_year,
  )
  selfOwnedAttributes.purchased_at = convertDateYear(
    selfOwnedAttributes.purchased_at_year,
  )
  selfOwnedAttributes.input_loans_attributes = buildSelfOwnedLoanSubmitValues(
    selfOwnedAttributes,
  )

  selfOwnedAttributes = convertHousingSelfOwnedNonRequiredValues(selfOwnedAttributes)

  return {
    ..._pick(selfOwnedAttributes, [
      'house_type',
      'housing_type2',
      'constructed_at',
      'purchased_at',
      'property_price_manyen',
      'property_price_land_manyen',
      'property_price_building_manyen',
      'tax_yearly',
      'repair_costs_span',
      'repair_costs',
      'repair_reserve_fund',
      'repair_reserve_fund_increase_type',
      'repair_reserve_fund_amount',
      'repair_reserve_fund_amount_start',
      'repair_reserve_fund_amount_finish',
      'repair_reserve_fund_increase',
      'repair_reserve_fund_increase_percent',
      'repair_reserve_fund_increase_start',
      'repair_reserve_fund_increase_finish',
      'repair_reserve_fund_increase_span',
      'management_fee_monthly',
      'management_fee_monthly_and_repair_reserve_fund_total',
      'other_cost_yearly',
      'loan_type1',
      'loan_type2',
      'input_loans_attributes',
      'input_loans_attributes',
      'child_rearing_deduction',
    ]),
  }
}

export const buildHousingTransferSubmitValues = (
  transferAttributes: Partial<InputHousingTransfer>,
) => {
  if (Number(transferAttributes!.transfer_type) === API_FLAG.off) {
    transferAttributes.transfer_rent = 0
    delete transferAttributes.transfer_update_type
    delete transferAttributes.transfer_update_month_at
    delete transferAttributes.transfer_update_cost
  }
  if (Number(transferAttributes!.transfer_update_type) === API_FLAG.on) {
    transferAttributes!.transfer_update_month_at = convertDateMonth(
      transferAttributes!.transfer_update_month_at_year,
      transferAttributes!.transfer_update_month_at_month,
    )
  }
  if (Number(transferAttributes!.transfer_update_type) === API_FLAG.off) {
    transferAttributes!.transfer_update_month_at = null
    transferAttributes!.transfer_update_cost = null
  }

  return {
    ..._pick(transferAttributes, [
      'transfer_type',
      'transfer_rent',
      'transfer_update_type',
      'transfer_update_month_at',
      'transfer_update_cost',
    ]),
  }
}

export const buildHousingRentSubmitValues = (
  rentAttributes: Partial<InputHousingRent>,
) => {
  if (Number(rentAttributes.next_update_type) === API_FLAG.on) {
    rentAttributes.next_update_month_at = convertDateMonth(
      rentAttributes.next_update_year,
      rentAttributes.next_update_month,
    )
  }
  if (Number(rentAttributes.next_update_type) === API_FLAG.off) {
    rentAttributes.next_update_month_at = null
    rentAttributes.next_update_cost = null
  }
  if (rentAttributes.rent_type === INPUT_HOUSING_RENT_TYPE.selfRent) {
    rentAttributes.whole_rent_monthly = null
    rentAttributes.whole_next_update_cost = null
  }

  return {
    ..._pick(rentAttributes, [
      'rent_monthly',
      'whole_rent_monthly',
      'rent_type',
      'next_update_month_at',
      'whole_next_update_cost',
      'next_update_cost',
      'scheduled_at',
    ]),
  }
}

export const buildMovingRentSubmitValues = (
  rentAttributes: Partial<InputHousingRent>,
  age: number,
) => {
  const rentType = rentAttributes.rent_type ?? INPUT_HOUSING_RENT_TYPE.selfRent

  if (rentType === INPUT_HOUSING_RENT_TYPE.selfRent) {
    rentAttributes.whole_rent_monthly = null
    rentAttributes.whole_next_update_cost = null
  }

  return {
    ...rentAttributes,
    rent_type: rentType as RentType,
  }
}
