import React, { FC } from 'react'
import clsx from 'clsx'
import Typography from '@/components/v2/atoms/Typography'

interface SubmitErrorMessageProps {
  /** クラス名 */
  className?: string
  /** サブミットエラー */
  show?: boolean
}

const SubmitErrorMessage: FC<SubmitErrorMessageProps> = (props) => {
  const { className, show } = props

  return (
    <>
      {show && (
        <Typography
          className={clsx(
            'flex items-center mt-5 text-12 text-alert-600 max-w-fit mb-10 mx-auto',
            className,
          )}
        >
          正しく入力されていない項目があります。
          <br />
          再度ご確認ください。
        </Typography>
      )}
    </>
  )
}

export default SubmitErrorMessage
