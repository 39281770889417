import {
  EDUCATIONAL_POLICY_TYPE,
  SCHOOL_TYPE,
} from '../../../models/futures/futurePlanTuitionsModelFunc'

// 教育方針タイプ
export const EDUCATIONAL_POLICY_TYPE_SELECT_ITEMS = [
  { value: EDUCATIONAL_POLICY_TYPE.notHang, label: '教育費をかけない' },
  { value: EDUCATIONAL_POLICY_TYPE.notReallyHang, label: '教育費をあまりかけない' },
  { value: EDUCATIONAL_POLICY_TYPE.averageHang, label: '教育費を人並みにかける' },
  {
    value: EDUCATIONAL_POLICY_TYPE.averageOrMoreHang,
    label: '教育費を人並み以上にかける',
  },
  { value: EDUCATIONAL_POLICY_TYPE.thoroughlyHang, label: '教育費をとことんかける' },
]

// 学校種別
export const NORMAL_SCHOOL_TYPE_SELECT_ITEMS = [
  { value: SCHOOL_TYPE.public, label: '公立' },
  { value: SCHOOL_TYPE.private, label: '私立' },
]

// 学校種別 (大学用)
export const UNIVERSITY_SCHOOL_TYPE_SELECT_ITEMS = [
  { value: SCHOOL_TYPE.public, label: '国公立' },
  { value: SCHOOL_TYPE.privateLiberalArts, label: '私立文系' },
  { value: SCHOOL_TYPE.privateSciences, label: '私立理系' },
]
