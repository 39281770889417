import React, { FC } from 'react'
import { useField, useForm } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { isUndefined as _isUndefined } from 'lodash'
import { MONTH_KANJI_SELECT_ITEMS } from '@/constants/formItem/commons'
import {
  INPUT_ADVANCE_RADIO_ITEMS,
  INPUT_HOUSING_YEAR_SELECT_ITEMS,
  INPUT_REPAYMENT_TYPE_SELECT_ITEMS,
} from '@/constants/formItem/inputs/inputHousings'
import { API_FLAG, THIS_YEAR } from '@/models/commonsModelFunc'
import { InputAdvance } from '@/models/inputs/inputAdvancesModel'
import { selectLoanCalculationObjectName } from '@/models/inputs/inputLoanCalculationsModelFunc'
import { selectInputLoanObjectNumber } from '@/models/inputs/inputLoansModelFunc'
import { buildStandardSelectItems } from '@/utils/formItem'
import {
  commaNumberMaxLength,
  composeValidators,
  zeroOrMoreNumber,
  required,
} from '@/utils/validate'

import Button from '@/components/v2/atoms/Button'
import Typography from '@/components/v2/atoms/Typography'
import SelectBox from '@/components/v2/molecules/SelectBox'
import NumberField from '@/components/v2/molecules/NumberField'
import PlusCircleIcon from '@/assets/images/v2/plus-circle.svg'
import CrossIcon from '@/assets/images/v2/cross.svg'

interface AdvanceRepaymentProps {
  /** true: 建物用ローン, false: 土地用ローン・分割なし */
  isBuilding?: boolean
  /** true: 配偶者, false: ご本人 */
  isSpouse?: boolean
}

const AdvanceRepayment: FC<AdvanceRepaymentProps> = (props) => {
  const { isBuilding, isSpouse } = props
  const { change } = useForm()
  const loanObjectNum = selectInputLoanObjectNumber(isBuilding, isSpouse)

  const {
    input: { value: advanceRepayment },
  } = useField(selectLoanCalculationObjectName(loanObjectNum, 'advance_repayment'))

  const handleAddInputAdvanceBlock = (
    advances: Partial<InputAdvance>[],
    index: number,
    change: (key: string, values: Partial<InputAdvance>[]) => void,
  ) => {
    const isEnd = index + 1 === advances.length
    isEnd ? advances.push({}) : advances.splice(index + 1, 0, {})

    change(
      selectLoanCalculationObjectName(loanObjectNum, 'input_advances_attributes'),
      advances,
    )
  }

  return (
    <>
      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
        <Typography
          className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
          isBold
        >
          過去の繰り上げ返済
        </Typography>
        <SelectBox
          name={selectLoanCalculationObjectName(loanObjectNum, 'advance_repayment')}
          placeholder="選択する"
          isFullWidth
          options={INPUT_ADVANCE_RADIO_ITEMS}
          validate={required}
        />
      </div>

      {advanceRepayment && Number(advanceRepayment) === API_FLAG.on && (
        <>
          <FieldArray
            name={selectLoanCalculationObjectName(
              loanObjectNum,
              'input_advances_attributes',
            )}
          >
            {({ fields }) => {
              return (
                <>
                  <Typography
                    className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800 leading-45 lg:leading-0"
                    isBold
                  >
                    繰り上げ返済実績
                  </Typography>
                  {fields.map((name, index) => (
                    <React.Fragment key={index}>
                      <div className="md:flex pb-[16px] items-baseline md:col-span-2 justify-left lg:space-x-24">
                        <div className="flex items-baseline">
                          <SelectBox
                            isFullWidth
                            name={`${name}advance_repayment_month_at_year`}
                            isPlaceholderHidden
                            options={buildStandardSelectItems(
                              INPUT_HOUSING_YEAR_SELECT_ITEMS,
                              THIS_YEAR,
                              '年を選択',
                            )}
                            validate={required}
                            className="mr-[10px]"
                          />
                          <SelectBox
                            isFullWidth
                            name={`${name}advance_repayment_month_at_month`}
                            placeholder="月を選択"
                            options={MONTH_KANJI_SELECT_ITEMS}
                            validate={required}
                          />
                          <Typography
                            className="px-[8px] text-[14px] text-black-800"
                            isBold
                          >
                            に
                          </Typography>
                        </div>
                        <div className="flex items-baseline my-[10px] md:my-0">
                          <NumberField
                            isFullWidth
                            name={`${name}repayment_amount`}
                            validate={composeValidators(
                              required,
                              zeroOrMoreNumber,
                              (value: any) => commaNumberMaxLength(value, 10),
                            )}
                          />
                          <Typography
                            className="px-[8px] text-[14px] text-black-800 min-w-[fit-content]"
                            isBold
                          >
                            円を
                          </Typography>
                        </div>
                        <div className="flex items-baseline">
                          <SelectBox
                            isFullWidth
                            name={`${name}repayment_type`}
                            options={INPUT_REPAYMENT_TYPE_SELECT_ITEMS}
                            validate={required}
                            placeholder="選択する"
                          />
                          <Typography
                            className="pl-[8px] text-[14px] text-black-800 min-w-[fit-content]"
                            isBold
                          >
                            で返済
                          </Typography>
                        </div>

                        {Number(fields.length) > 1 && (
                          <div className="flex relative items-center pl-10 md:justify-end">
                            <div
                              className="cursor-pointer"
                              onClick={() => fields.remove(index)}
                            >
                              <span className="hidden md:block">
                                <CrossIcon className="cursor-pointer" />
                              </span>

                              <span className="block md:hidden ml-auto border-[0.5px] border-black-700 rounded-[4px] my-[16px] px-10  text-black-800 py-5 text-[12px]">
                                上の繰上げ返済予定を削除
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </React.Fragment>
                  ))}
                  {!_isUndefined(fields) && (
                    <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline md:col-span-2 justify-left lg:space-x-24">
                      <Button
                        className="ml-auto border-[0.5px] border-black-700 rounded-[4px] my-[16px]"
                        onClick={() =>
                          handleAddInputAdvanceBlock(
                            [...fields.value],
                            fields.length || 1,
                            change,
                          )
                        }
                      >
                        <div className="flex items-center">
                          <PlusCircleIcon className="ml-[10px] mt-[2px]" />
                          <Typography
                            className="min-w-[120p] p-[10px] text-[14px] text-black-800 flex items-center"
                            isBold
                          >
                            入力枠を追加する
                          </Typography>
                        </div>
                      </Button>
                    </div>
                  )}
                </>
              )
            }}
          </FieldArray>
        </>
      )}
    </>
  )
}

export default AdvanceRepayment
