import React, { FC } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { BLACK_COLOR, WHITE_COLOR } from '@/constants/theme'
import CircularProgress from '@/components/v1/atoms/CircularProgress'

const useStyles = (zIndex?: number) =>
  makeStyles(() =>
    createStyles({
      root: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        zIndex: zIndex ?? 1000,
      },
      progressOverlay: {
        backgroundColor: BLACK_COLOR,
        opacity: '0.5',
        width: '100%',
        height: '100%',
      },
      progressBase: {
        position: 'absolute',
        top: '0',
        bottom: '0',
        left: '0',
        right: '0',
        margin: 'auto',
        width: '100px',
        height: '100px',
        backgroundColor: WHITE_COLOR,
        borderRadius: '6px',
      },
      progress: {
        position: 'absolute',
        top: '0',
        bottom: '0',
        left: '0',
        right: '0',
        margin: 'auto',
      },
    }),
  )

interface LoadingOverlayProps {
  /** true: ローディング有り, false: ローディング無し */
  isLoading: boolean
  zIndex?: number
}

const LoadingOverlay: FC<LoadingOverlayProps> = (props) => {
  const { isLoading, zIndex } = props
  const classes = useStyles(zIndex)()

  return (
    <>
      {isLoading && (
        <div className={classes.root}>
          <div className={classes.progressOverlay} />
          <div className={classes.progressBase} />
          <CircularProgress className={classes.progress} size={50} />
        </div>
      )}
    </>
  )
}

export default LoadingOverlay
