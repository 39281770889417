import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { isNull as _isNull } from 'lodash'
import LogindRedirect from '@/layouts/v2/LogindRedirect'
import MarginContainer from '@/components/v2/atoms/MarginContainer'
import Paper from '@/components/v2/atoms/Paper'
import AccountForm from '@/templates/v2/accounts/commons/AccountForm'
import { createAccount } from '@/containers/accountsSlice'
import { setPageMeta } from '@/containers/pageMetasSlice'
import { AccountCreateValues } from '@/models/accountsModel'
import {
  getStorageRegistrationCode,
  removeStorageRegistrationCode,
} from '@/utils/localStorage/registrationCodeStorage'
import {
  getStorageFpRegistrationCode,
  removeStorageFpRegistrationCode,
} from '@/utils/localStorage/fpRegistrationCodeStorage'

const AccountConfirm: FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'ユーザー仮登録確認',
        description: 'ユーザー仮登録確認',
        keyword: 'ユーザー仮登録確認',
      }),
    )
  }, [])

  const handleFix = () => {
    history.push('/v2/accounts/new')
  }

  const handleSubmit = (values: AccountCreateValues) => {
    const newValues = { ...values }
    const registrationCode = getStorageRegistrationCode()
    const fpRegistrationCode = getStorageFpRegistrationCode()

    if (!_isNull(registrationCode)) {
      newValues.registration_code = registrationCode
    }

    if (!_isNull(fpRegistrationCode)) {
      newValues.fp_registration_code = fpRegistrationCode
    }

    // アプリ会員の場合、is_app_memberをtrueにする
    const isInStandaloneMode = (window.matchMedia && window.matchMedia('(display-mode: standalone)').matches) || (window.navigator as any).standalone;
    newValues.is_app_member = isInStandaloneMode

    return new Promise((resolve) => {
      dispatch(
        createAccount(
          newValues,
          () => {
            removeStorageRegistrationCode()
            removeStorageFpRegistrationCode()
            history.push('/v2/accounts/tempCompleted')
          },
          resolve,
        ),
      )
    })
  }

  return (
    <LogindRedirect>
      <MarginContainer className="py-40">
        <Paper className="mx-8 px-16 py-24 sm:p-40 bg-white">
          <AccountForm
            title="ユーザー仮登録 入力内容確認"
            buttonLabel="登録する"
            type="confirm"
            handleSubmit={handleSubmit}
            handleFix={handleFix}
          />
        </Paper>
      </MarginContainer>
    </LogindRedirect>
  )
}

export default AccountConfirm
