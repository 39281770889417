import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty as _isEmpty } from 'lodash'
import AccountSettingsTitle from '@/templates/v2/settings/commons/AccountSettingsTitle'
import AccountDeactivateForm from '@/templates/v2/settings/commons/AccountDeactiveForm'
import MarginContainer from '@/components/v2/atoms/MarginContainer'
import { setPageMeta } from '@/containers/pageMetasSlice'
import { createAccountDeactivateResponse } from '@/containers/accountsSlice'
import { setFlashAlert, removeAllFlashAlerts } from '@/containers/flashAlertsSlice'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import { DeactivationFormResponseCreateValues } from '@/models/deactivationFormResponsesModel'
import { RootState } from '@/store'

const AccountDeactivateConfirm: FC = () => {
  const dispatch = useDispatch()
  const { handleMovePage } = useCustomHistory()

  const { flashAlertMessageObjects } = useSelector(
    (state: RootState) => state.flashAlerts,
  )

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'マネソル退会手続き 確認',
        description: 'マネソル退会手続き 確認',
        keyword: 'マネソル退会手続き 確認',
      }),
    )
  }, [])

  const showFlashMsg = (type: 'deactivated' | 'deactivateError', message?: string) => {
    if (!_isEmpty(flashAlertMessageObjects)) {
      dispatch(removeAllFlashAlerts())
    }
    let alertLabel = ''
    switch (type) {
      case 'deactivated':
        alertLabel = message!
        break
      case 'deactivateError':
        alertLabel = message!
        break
    }

    dispatch(
      setFlashAlert({
        flashAlertMessageObjects: [
          {
            message: alertLabel,
            variant: type === 'deactivateError' ? 'error' : 'success',
          },
        ],
      }),
    )
  }

  const handleFix = () => {
    handleMovePage('/v2/settings/accountDeactivate')
  }

  const handleSubmit = (values: DeactivationFormResponseCreateValues) => {
    return new Promise(() => {
      dispatch(
        createAccountDeactivateResponse(
          values,
          (res) => {
            showFlashMsg('deactivated', res.message)
            handleMovePage('/v2/accounts/deactivationCompleted')
          },
          (res) => {
            showFlashMsg('deactivateError', !!res.message ? res.message : res.other)
          },
        ),
      )
    })
  }

  return (
    <MarginContainer className="py-40">
      <div className="mx-8">
        <AccountSettingsTitle />
        <AccountDeactivateForm
          title="退会手続き 確認画面"
          buttonLabel="退会する"
          type="confirm"
          handleSubmit={handleSubmit}
          handleFix={handleFix}
        />
      </div>
    </MarginContainer>
  )
}

export default AccountDeactivateConfirm
