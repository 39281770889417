import ApiClient from '../apiClient'
import {
  FutureInsuranceCreateValues,
  FutureInsuranceUpdateValues,
} from '../../models/futures/futureInsurancesModel'

export const postFutureInsuranceV2 = async (values: FutureInsuranceCreateValues) => {
  return await ApiClient.post('/future_insurances', () => {}, values, undefined, 'v2')
}

export const patchFutureInsuranceV2 = async (
  values: FutureInsuranceUpdateValues,
  id: number,
) => {
  return await ApiClient.patch(
    `/future_insurances/${id}`,
    () => {},
    values,
    undefined,
    'v2',
  )
}

export const deleteFutureInsuranceV2 = async (
  values: FutureInsuranceUpdateValues,
  id: number,
) => {
  return await ApiClient.delete(
    `/future_insurances/${id}`,
    () => {},
    values,
    undefined,
    'v2',
  )
}
