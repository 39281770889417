import React, { FC, useContext, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, useField } from 'react-final-form'
import { isNull as _isNull } from 'lodash'
import { createFutureHousingSaleLoanYearly } from '@/containers/futures/futureHousingSalesSlice'
import {
  PERSON_FLG_SELECT_ITEMS,
  PERSON_WITHOUT_SPOUSE_SELECT_ITEM,
} from '@/constants/formItem/familyStructures'
import {
  LOAN_RENEWAL_TYPE_SELECT_ITEMS,
  PROPERTY_TYPE_RADIO_ITEMS,
} from '@/constants/formItem/futures/futureLendHousings'
import { BUILDING_STRUCTURE_TYPE_SELECT_ITEMS } from '@/constants/formItem/futures/futureEstateInvestments'
import { DescriptionContext } from '@/hooks/useDescriptionTemplates'
import { convertDateYear, THIS_YEAR_NUMBER } from '@/models/commonsModelFunc'
import { PERSON_FLG } from '@/models/familyStructuresModelFunc'
import { BUILDING_STRUCTURE_TYPE } from '@/models/futures/futureEstateInvestmentsModelFunc'
import { PROPERTY_TYPE } from '@/models/futures/futureLendHousingsModelFunc'
import { LOAN_TYPE } from '@/models/housingLoansModelFunc'
import { RootState } from '@/store'
import { required } from '@/utils/validate'

import Typography from '@/components/v2/atoms/Typography'
import SelectBox from '@/components/v2/molecules/SelectBox'
import HelpCircleIcon from '@/assets/images/v2/help-circle.svg'
import Tooltip from '@/components/v2/atoms/Tooltip'

import HousingBreadth from '@/templates/v2/simulations/futureV2Housings/FutureLendHousingModalForm/HousingBreadth'
interface HousingOverviewProps {
  isEdit?: boolean
}

const HousingOverview: FC<HousingOverviewProps> = (props) => {
  const dispatch = useDispatch()
  const { isEdit } = props
  const { age, prevHousing } = useContext(DescriptionContext)

  const { futureBaseId, familyInfo } = useSelector(
    (state: RootState) => state.futureBases,
  )
  const { futureHousingSaleLoanYearly } = useSelector(
    (state: RootState) => state.futureHousingSales,
  )
  const { futureMarriage } = useSelector((state: RootState) => state.futureMarriages)

  const hasOrWillHaveSpouse = familyInfo?.is_married || !_isNull(futureMarriage)

  const { change } = useForm()

  const {
    input: { value: scheduledAtAge },
  } = useField('scheduled_at_age')
  const {
    input: { value: lendStartAtAge },
  } = useField('future_lend_housing.lend_start_at_age')
  const {
    input: { value: loanYearly },
  } = useField('loan_yearly')

  const prevHousingType = useMemo(() => prevHousing?.model_type, [prevHousing])

  const targetScheduledAtAge = useMemo(() => scheduledAtAge || lendStartAtAge, [
    scheduledAtAge,
    lendStartAtAge,
  ])

  const targetYear = useMemo(() => {
    const ageGap = Number(targetScheduledAtAge) - Number(age)

    return THIS_YEAR_NUMBER + ageGap
  }, [targetScheduledAtAge])

  useEffect(() => {
    if (!isEdit) {
      change('future_lend_housing.lend_start_at', convertDateYear(targetYear))
      change('future_lend_housing.delivery_date', convertDateYear(targetYear))
    }

    const newValues = {
      future_base_id: futureBaseId,
      housing_id: Number(prevHousing?.id),
      housing_type: prevHousingType,
    }

    dispatch(createFutureHousingSaleLoanYearly(newValues))
  }, [])

  const buildLoanYearly = () => {
    const key = `${Number(targetYear) - 1}-12`

    return (
      Math.round(
        Number(futureHousingSaleLoanYearly?.[key]?.remain_loan_amount) / 10000,
      ) || 0
    )
  }

  const hasLoan = useMemo(() => {
    const currentLoanYearly = buildLoanYearly()

    change('loan_yearly', currentLoanYearly)

    if (!loanYearly && !currentLoanYearly) return false

    return Number(loanYearly) > 0 || currentLoanYearly > 0
  }, [targetYear, futureHousingSaleLoanYearly])

  const handleChangePropertyType = (propertyType: string) => {
    if (propertyType === PROPERTY_TYPE.division) {
      return change(
        'future_lend_housing.building_structure_type',
        BUILDING_STRUCTURE_TYPE.rc,
      )
    }

    change('future_lend_housing.building_structure_type', BUILDING_STRUCTURE_TYPE.wood)
  }

  return (
    <>
      <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
        <Typography
          className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
          isBold
        >
          名義人
        </Typography>
        <div className="flex flex-auto items-baseline">
          <SelectBox
            isFullWidth
            name="future_lend_housing.person_flg"
            options={
              hasOrWillHaveSpouse
                ? PERSON_FLG_SELECT_ITEMS
                : PERSON_WITHOUT_SPOUSE_SELECT_ITEM
            }
            validate={required}
            onChange={(event: any) => {
              change(
                'future_lend_housing.housing_loan_attributes.person_flg',
                Number(event.target.value),
              )
              change(
                'future_lend_housing.housing_loan_attributes.loan_type',
                Number(event.target.value) === PERSON_FLG.person
                  ? LOAN_TYPE.singlePerson
                  : LOAN_TYPE.singleSpouse,
              )
            }}
          />
        </div>
      </div>

      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
        <Typography
          className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
          isBold
        >
          物件タイプ
        </Typography>
        <SelectBox
          name="future_lend_housing.property_type"
          placeholder="選択する"
          isFullWidth
          options={PROPERTY_TYPE_RADIO_ITEMS}
          validate={required}
          onChange={(event: any) => handleChangePropertyType(event.target.value)}
        />
      </div>

      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
        <div className="flex min-w-[140px] md:min-w-[164px]">
          <Typography
            className=" text-[14px] text-black-800 mr-5 pb-[10px] lg:pb-0"
            isBold
          >
            物件構造
          </Typography>
          <Tooltip
            item="物件構造の選択によって、減価償却費の計算が変化します"
            itemClassName="w-[200px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
          >
            <HelpCircleIcon />
          </Tooltip>
        </div>

        <SelectBox
          name="future_lend_housing.building_structure_type"
          placeholder="選択する"
          isFullWidth
          options={BUILDING_STRUCTURE_TYPE_SELECT_ITEMS}
          validate={required}
        />
      </div>

      <HousingBreadth />

      {hasLoan && (
        <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
          <Typography
            className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
            isBold
          >
            投資用ローンへの借換え
          </Typography>
          <SelectBox
            name="future_lend_housing.loan_renewal_type"
            placeholder="選択する"
            isFullWidth
            options={LOAN_RENEWAL_TYPE_SELECT_ITEMS}
            validate={required}
          />
        </div>
      )}
    </>
  )
}

export default HousingOverview
