import { createSlice } from '@reduxjs/toolkit'
import { push } from 'connected-react-router'
import { isUndefined as _isUndefined, replace as _replace } from 'lodash'
import LogRocket from 'logrocket'
import ApiClient from '@/api/apiClient'
import { postAuthentication } from '@/api/videoPlatforms/authentications'
import {
  AuthenticationResponse,
  AuthenticationCreateValues,
} from '@/models/videoPlatforms/authenticationsModel'
import { ApiError } from '@/models/commonsModel'
import { AppThunk } from '@/store'
import ChannelService from '@/utils/ChannelService'
import {
  setStorageVideoPlatformsAuthHeaders,
  removeStorageVideoPlatformsAuthHeaders,
} from '@/utils/localStorage/videoPlatformsAuthHeadersStorage'
import { createLearningPlatformUser } from './learningPlatformUsersSlice'

interface AuthenticationState {
  isLoading: boolean
  isLogin: boolean
}

const initialState: AuthenticationState = {
  isLoading: false,
  isLogin: false,
}

const videoPlatformsAuthenticationsSlice = createSlice({
  name: 'videoPlatformsAuthentications',
  initialState,
  reducers: {
    setVideoPlatformsLogind: (state) => {
      state.isLogin = true
      return state
    },
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    postVideoLogoutSuccess: (state) => {
      state.isLoading = false
      state.isLogin = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
  },
})

export const {
  setVideoPlatformsLogind,
  requestStart,
  requestSuccess,
  postVideoLogoutSuccess,
  requestFailure,
} = videoPlatformsAuthenticationsSlice.actions
export const videoPlatformsAuthenticationsReducer =
  videoPlatformsAuthenticationsSlice.reducer

export const setVideoPlatformsLogindFunc = (): AppThunk => async (dispatch) => {
  dispatch(setVideoPlatformsLogind())

  if (process.env.NODE_ENV === 'production') {
    LogRocket.init('8riiec/moneysol')
  }
}

export const videoPlatformsLogin = (
  values: AuthenticationCreateValues,
  resolve: (error: any) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postAuthentication(values)
    .then((response: AuthenticationResponse) => {
      dispatch(requestSuccess())
      const headers = setStorageVideoPlatformsAuthHeaders(response)
      ApiClient.setVideoPlatformsAuthHeaders(headers)

      dispatch(setVideoPlatformsLogindFunc())
      dispatch(createLearningPlatformUser(values, resolve))
      resolve(undefined)
    })
    .catch((error) => {
      dispatch(requestFailure())
      resolve(error)
    })
}

export const videoPlatformsLogout = (redirectUrl?: string): AppThunk => async (
  dispatch,
) => {
  dispatch(postVideoLogoutSuccess())
  removeStorageVideoPlatformsAuthHeaders()
  ApiClient.setVideoPlatformsAuthHeaders({})

  const values = { pluginKey: process.env.CHANNEL_PLUGIN_KEY }
  ChannelService.shutdown()
  ChannelService.boot(values)

  if (!_isUndefined(redirectUrl) && redirectUrl !== '/movies/login/password') {
    const replacedRedirectUrl = _replace(redirectUrl, '/movies', '')
    dispatch(push(`/movies/login/password?redirectUrl=${replacedRedirectUrl}`))
  }
}

export const videoUnauthorizedCheck = (
  error: ApiError,
  resolve?: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  if (error?.isUnauthorized) return dispatch(videoPlatformsLogout(location.pathname))
  !_isUndefined(resolve) && resolve(error)
}
