import React, { FC, useEffect, useState, useMemo, ChangeEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'react-final-form'
import { Decorator } from 'final-form'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import createDecorator from 'final-form-calculate'
import clsx from 'clsx'
import { find as _find, isNull as _isNull, isUndefined as _isUndefined } from 'lodash'
import ChevronRightIcon from '@/assets/images/v2/chevron-right.svg'
import CrossIcon from '@/assets/images/v2/cross.svg'
import DepreciationTable from '@/assets/images/v2/depreciation-table.png'
import HelpCircleIcon from '@/assets/images/v2/help-circle.svg'
import PlusCircleIcon from '@/assets/images/v2/plus-circle.svg'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import Button from '@/components/v2/atoms/Button'
import Grid from '@/components/v2/atoms/Grid'
import Modal from '@/components/v2/atoms/Modal'
import Typography from '@/components/v2/atoms/Typography'
import RadioGroup from '@/components/v2/molecules/RadioGroup'
import SelectBox from '@/components/v2/molecules/SelectBox'
import ImageMagnifiableModal from '@/components/v2/organisms/ImageModal'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import NumberField from '@/components/v2/molecules/NumberField'
import NumberFieldDecimal from '@/components/v2/molecules/NumberFieldDecimal'
import Tooltip from '@/components/v2/atoms/Tooltip'
import SubmitErrorMessage from '@/components/v2/molecules/SubmitErrorMessage'
import { YES_NO_BUTTON_ITEMS } from '@/constants/formItem/commons'
import {
  ESTATE_INVESTMENT_SPAN_SELECT_ITEMS,
  RENEWAL_FEE_TYPE_RADIO_ITEMS,
  REPAIR_RESERVE_FUND_INCREASE_TYPE_SELECT_ITEMS,
  TAX_PAPER_PRESENCE_TYPE_RADIO_ITEMS,
} from '@/constants/formItem/commonEstateInvestment'
import {
  FUTURE_IS_LOAN_CUSTOMIZED_BUTTON_ITEMS,
  FUTURE_NEED_LOAN_BUTTON_ITEMS,
  LOAN_TYPE_WITHOUT_SPOUSE_SELECT_ITEM,
  REJECT_LOAN_TYPE_SELECT_ITEMS,
} from '@/constants/formItem/housingLoans'
import {
  BUILDING_STRUCTURE_TYPE_SELECT_ITEMS,
  DECLINE_RATE_TYPE_RADIO_ITEMS,
  MANAGEMENT_FEE_TYPE_RADIO_ITEMS,
  PROPERTY_TYPE_RADIO_ITEMS,
  SIMULATION_TYPE_BUTTON_ITEMS,
  VACANCY_SETTING_TYPE_SELECT_ITEMS,
} from '@/constants/formItem/futures/futureEstateInvestments'
import {
  PERSON_FLG_SELECT_ITEMS,
  PERSON_WITHOUT_SPOUSE_SELECT_ITEM,
} from '@/constants/formItem/familyStructures'
import {
  createFutureEstateInvestmentV2,
  updateFutureEstateInvestmentV2,
} from '@/containers/futures/futureEstateInvestmentsSlice'
import { fetchAccountFutureItems } from '@/containers/accountsSlice'
import { createHousingLoanRepayment } from '@/containers/housingLoansSlice'

import { useFlashAlert } from '@/hooks/useFlashAlert'
import {
  RENEWAL_FEE_INPUT_TYPE,
  REPAIR_RESERVE_FUND_INCREASE_TYPE,
  TAX_PAPER_PRESENCE_TYPE,
} from '@/models/commonEstateInvestmentModelFunc'
import { calcEstimatedMiscellaneousCost } from '@/models/commonHousingModelFunc'
import {
  API_BOOLEAN_FLAG,
  API_FLAG,
  castNumberWithoutComma,
  THIS_YEAR_NUMBER,
  castNumberWithoutOperator,
  convertDateYear,
  subtractMomentDate,
  THIS_YEAR,
  addMomentDate,
  extractYearOrMonthFromDate,
  convertLocaleString,
  compareDiffMomentDate,
  isNotEmptyValue,
} from '@/models/commonsModelFunc'
import { FutureEstateInvestmentDeclineRate } from '@/models/futures/futureEstateInvestmentDeclineRatesModel'
import {
  BUILDING_STRUCTURE_TYPE,
  SIMULATION_TYPE,
  calculateTax,
  VACANCY_SETTING_TYPE,
  VACANCY_RATE_FIELDS,
  calcVacancyRate,
} from '@/models/futures/futureEstateInvestmentsModelFunc'
import {
  MANAGEMENT_FEE_TYPE,
  PROPERTY_TYPE,
} from '@/models/futures/futureLendHousingsModelFunc'
import { FUTURE_ESTATE_INVESTMENT_DEFAULT_VALUES } from '@/models/futures/futureEstateInvestmentsModelFunc'
import { FutureEstateInvestment } from '@/models/futures/futureEstateInvestmentsModel'
import {
  convertLoanTypeToPersonFlg,
  IS_LOAN_CUSTOMIZED,
  isInputRepaymentAllValues,
  buildRepaymentValues,
} from '@/models/housingLoansModelFunc'
import { RootState } from '@/store'
import HousingLoanAmountTable from '@/templates/v2/simulations/_housingLoanAmountTable'
import { buildTwoTypesRangeYearSelectItems } from '@/utils/formItem'
import {
  composeValidators,
  required,
  commaNumberMaxLength,
  maxLength,
  zeroOrMoreNumber,
  oneOrMoreNumber,
} from '@/utils/validate'

interface FutureMonthlyLivingCostModalFormProps {
  isEdit: boolean
  /** true: オープン, false: クローズ */
  isOpen: boolean
  /** onCloseメソッド */
  onClose: () => void

  id: number | null
  /* FutureItemsを参照するID */
  futureBaseId?: number
}

const calculator: Decorator<any> = createDecorator({
  field: VACANCY_RATE_FIELDS,
  updates: {
    'future_estate_investment.vacancy_rate_percent': (_, allValues: any) =>
      calcVacancyRate(allValues),
  },
})

const FutureEstateInvestmentModalForm: FC<FutureMonthlyLivingCostModalFormProps> = ({
  isEdit,
  isOpen,
  onClose,
  id,
  futureBaseId,
}) => {
  const [isLoanAmountModalOpen, setIsLoanAmountModalOpen] = useState<boolean>(false)
  const [isImage1ModalOpen, setIsImage1ModalOpen] = useState<boolean>(false)

  const futureMarriages = useSelector(
    (state: RootState) => state.accounts.futureItems?.future_marriages,
  )
  const { familyInfo } = useSelector((state: RootState) => state.familyStructures)
  const hasOrWillHaveSpouse = familyInfo?.is_married || !_isNull(futureMarriages)

  const dispatch = useDispatch()
  const { showFlashMsg } = useFlashAlert()

  const { housingLoanRepayment } = useSelector((state: RootState) => state.housingLoans)
  const futureEstateInvestments = useSelector(
    (state: RootState) => state.accounts.futureItems?.future_estate_investments,
  )

  const handleCreate = (newValues: any) => {
    dispatch(
      createFutureEstateInvestmentV2(
        {
          account: {
            future_estate_investments_attributes: [newValues.future_estate_investment],
          },
        },
        () => {
          dispatch(fetchAccountFutureItems(futureBaseId))
          showFlashMsg(isEdit ? '更新しました' : '作成しました')
          onClose()
        },
      ),
    )
  }

  const handleUpdate = (newValues: any) => {
    dispatch(
      updateFutureEstateInvestmentV2(
        {
          future_estate_investment: { ...newValues.future_estate_investment },
        } as any,
        id!,
        () => {
          dispatch(fetchAccountFutureItems(futureBaseId))
          showFlashMsg(isEdit ? '更新しました' : '作成しました')
          onClose()
        },
      ),
    )
  }

  const handleSubmit = (values: any) => {
    const { future_estate_investment } = values

    const newValues: any = {
      future_estate_investment: {
        ...future_estate_investment,
        vacancy_rate_percent: Math.round(future_estate_investment.vacancy_rate_percent!),
        purchased_at: convertDateYear(values.purchased_year),
        constructed_at: subtractMomentDate(
          THIS_YEAR,
          values.constructed_number,
          'years',
          'YYYY/MM/DD',
        ),
        breadth: Number(future_estate_investment.breadth),
      },
    }

    const {
      decline_rate_type,
      renewal_fee_type,
      renewal_fee_input_type,
      is_management_and_fund_total,
    } = future_estate_investment

    const {
      simulation_type,
      property_type,
      future_estate_investment_decline_rates_attributes,
      repair_reserve_fund_increase_type,
      management_fee_type,
      management_fee_amount,
      need_loan,
      is_loan_customized,
      housing_loan_attributes,
    } = future_estate_investment

    // シミュレーションタイプ: 詳細シミュレーションを選択
    if (simulation_type == SIMULATION_TYPE.detail) {
      delete newValues.future_estate_investment.gross_ratio_percent

      // 年間下落率: 設定するを選択
      if (decline_rate_type == API_FLAG.on) {
        newValues.future_estate_investment.future_estate_investment_decline_rates_attributes = future_estate_investment_decline_rates_attributes?.map(
          (item: FutureEstateInvestmentDeclineRate, index: number) => ({
            id: item.id,
            affected_at:
              index !== 0
                ? addMomentDate(
                    THIS_YEAR,
                    item.affected_number as number,
                    'years',
                    'YYYY/MM/DD',
                  )
                : null,
            amount_yearly_percent: item.amount_yearly_percent,
          }),
        )
      }

      // 年間下落率: 設定しないを選択
      if (decline_rate_type == API_FLAG.off) {
        newValues.future_estate_investment.future_estate_investment_decline_rates_attributes = []
      }

      // 礼金・更新料種別: いいえを選択
      if (renewal_fee_type == API_FLAG.off) {
        newValues.future_estate_investment.renewal_fee = null
        newValues.future_estate_investment.renewal_fee_period_months = null
      }

      // 礼金・更新料種別: はい かつ 円で入力 を選択
      if (
        renewal_fee_type == API_FLAG.on &&
        renewal_fee_input_type == RENEWAL_FEE_INPUT_TYPE.yen
      ) {
        newValues.future_estate_investment.renewal_fee_to_rent_income_rate = null
      }
      // 礼金・更新料種別: はい かつ 家賃のOヶ月分で入力 を選択
      if (
        renewal_fee_type == API_FLAG.on &&
        renewal_fee_input_type == RENEWAL_FEE_INPUT_TYPE.month
      ) {
        newValues.future_estate_investment.renewal_fee = null
      }

      // 管理費・修繕積立金: 詳細入力を選択
      if (is_management_and_fund_total == 'detail') {
        newValues.future_estate_investment.management_fee_monthly_and_repair_reserve_fund_total = null
      }

      // 管理費・修繕積立金: 総額入力を選択
      if (is_management_and_fund_total == 'total') {
        newValues.future_estate_investment.management_fee_monthly = null
        newValues.future_estate_investment.repair_reserve_fund = null
        newValues.future_estate_investment.repair_reserve_fund_increase_type = null
        newValues.future_estate_investment.repair_reserve_fund_amount = null
        newValues.future_estate_investment.repair_reserve_fund_amount_start = null
        newValues.future_estate_investment.repair_reserve_fund_amount_finish = null
        newValues.future_estate_investment.repair_reserve_fund_increase_start = null
        newValues.future_estate_investment.repair_reserve_fund_increase_span = null
        newValues.future_estate_investment.repair_reserve_fund_increase_finish = null
        newValues.future_estate_investment.repair_reserve_fund_increase_percent = null
      }

      // 上昇率の方式: 固定の金額 または 上昇率を設定しないを選択
      if (
        repair_reserve_fund_increase_type == REPAIR_RESERVE_FUND_INCREASE_TYPE.fixed ||
        repair_reserve_fund_increase_type == REPAIR_RESERVE_FUND_INCREASE_TYPE.noSet
      ) {
        newValues.future_estate_investment.repair_reserve_fund_increase_start = null
        newValues.future_estate_investment.repair_reserve_fund_increase_span = null
        newValues.future_estate_investment.repair_reserve_fund_increase_finish = null
        newValues.future_estate_investment.repair_reserve_fund_increase_percent = null
      }

      // 上昇率の方式: 上昇率で設定 または 上昇率を設定しないを選択
      if (
        repair_reserve_fund_increase_type == REPAIR_RESERVE_FUND_INCREASE_TYPE.change ||
        repair_reserve_fund_increase_type == REPAIR_RESERVE_FUND_INCREASE_TYPE.noSet
      ) {
        newValues.future_estate_investment.repair_reserve_fund_amount = null
        newValues.future_estate_investment.repair_reserve_fund_amount_start = null
        newValues.future_estate_investment.repair_reserve_fund_amount_finish = null
      }

      // 建物管理手数料: 賃料に対する比率で設定を選択
      if (management_fee_type == MANAGEMENT_FEE_TYPE.percent) {
        newValues.future_estate_investment.management_fee_amount = null
      }

      // 建物管理手数料: 円で設定を選択
      if (management_fee_type == MANAGEMENT_FEE_TYPE.yen) {
        newValues.future_estate_investment.management_fee_rate_percent = null
      }

      if (management_fee_type == MANAGEMENT_FEE_TYPE.yen) {
        newValues.future_estate_investment.management_fee_amount =
          castNumberWithoutOperator(management_fee_amount) * 12
      }

      // 物件タイプ: 区分を選択
      if (property_type == PROPERTY_TYPE.division) {
        newValues.future_estate_investment.macroscale_repair_costs = null
        newValues.future_estate_investment.macroscale_repair_costs_occurance_in_months = null
      }

      // 物件タイプ: １棟を選択
      if (property_type == PROPERTY_TYPE.whole) {
        newValues.is_management_and_fund_total = undefined
        newValues.future_estate_investment.management_fee_monthly_and_repair_reserve_fund_total = null
        newValues.future_estate_investment.management_fee_monthly = null
        newValues.future_estate_investment.repair_reserve_fund = null
        newValues.future_estate_investment.repair_reserve_fund_increase_type = null
        newValues.future_estate_investment.repair_reserve_fund_amount = null
        newValues.future_estate_investment.repair_reserve_fund_amount_start = null
        newValues.future_estate_investment.repair_reserve_fund_amount_finish = null
        newValues.future_estate_investment.repair_reserve_fund_increase_start = null
        newValues.future_estate_investment.repair_reserve_fund_increase_span = null
        newValues.future_estate_investment.repair_reserve_fund_increase_finish = null
        newValues.future_estate_investment.repair_reserve_fund_increase_percent = null
      }

      // 住宅ローン: 借りるを選択
      if (
        need_loan == String(API_BOOLEAN_FLAG.on) &&
        newValues.future_estate_investment.housing_loan_attributes &&
        !isNotEmptyValue(
          newValues.future_estate_investment.housing_loan_attributes!.person_flg,
        )
      ) {
        newValues.future_estate_investment.housing_loan_attributes!.person_flg = convertLoanTypeToPersonFlg(
          housing_loan_attributes?.loan_type,
        )
      }

      // 住宅ローン: 借りないを選択 または 設定方法: 自動設定するを選択
      if (
        need_loan == String(API_BOOLEAN_FLAG.off) ||
        is_loan_customized == String(IS_LOAN_CUSTOMIZED.auto)
      ) {
        newValues.future_estate_investment.miscellaneous_cost = null
        newValues.future_estate_investment.housing_loan_attributes = null

        if (need_loan == String(API_BOOLEAN_FLAG.off)) {
          newValues.future_estate_investment.is_loan_customized = null
        }
      }
    }

    if (simulation_type == SIMULATION_TYPE.simple) {
      newValues.future_estate_investment.housing_loan_attributes = null
      newValues.future_estate_investment.future_estate_investment_decline_rates_attributes = []
    }

    return isEdit ? handleUpdate(newValues) : handleCreate(newValues)
  }

  const initialValues = useMemo(() => {
    const targetFutureEstateInvestment: FutureEstateInvestment | undefined = !_isNull(id)
      ? _find(futureEstateInvestments, { id })
      : undefined
    return isEdit && !!targetFutureEstateInvestment
      ? {
          tax_paper_presence_type: TAX_PAPER_PRESENCE_TYPE.no,
          future_estate_investment: {
            ...targetFutureEstateInvestment,
            future_estate_investment_decline_rates_attributes:
              targetFutureEstateInvestment?.future_estate_investment_decline_rates
                .length > 0
                ? targetFutureEstateInvestment?.future_estate_investment_decline_rates.map(
                    (item: any) => ({
                      ...item,
                      affected_number: compareDiffMomentDate(
                        item.affected_at,
                        THIS_YEAR,
                        'years',
                      ),
                    }),
                  )
                : [{ affected_number: null }],
            is_management_and_fund_total: !!targetFutureEstateInvestment?.management_fee_monthly_and_repair_reserve_fund_total
              ? 'total'
              : 'detail',
            management_fee_amount: !!targetFutureEstateInvestment.management_fee_amount
              ? Math.round(
                  Number(targetFutureEstateInvestment.management_fee_amount) / 12,
                )
              : targetFutureEstateInvestment.management_fee_amount,
            decline_rate_type:
              targetFutureEstateInvestment.future_estate_investment_decline_rates
                ?.length > 0
                ? '0'
                : '1',
            renewal_fee_type:
              !!targetFutureEstateInvestment.renewal_fee ||
              !!targetFutureEstateInvestment.renewal_fee_to_rent_income_rate
                ? '0'
                : '1',
            renewal_fee_input_type: !!targetFutureEstateInvestment.renewal_fee
              ? RENEWAL_FEE_INPUT_TYPE.yen
              : !!targetFutureEstateInvestment.renewal_fee_to_rent_income_rate &&
                RENEWAL_FEE_INPUT_TYPE.month,

            vacancy_rate_type: VACANCY_SETTING_TYPE.own,
            housing_loan_attributes: targetFutureEstateInvestment?.housing_loan,
            need_loan:
              targetFutureEstateInvestment?.need_loan === API_BOOLEAN_FLAG.on
                ? 'true'
                : 'false',
            is_loan_customized:
              targetFutureEstateInvestment?.is_loan_customized ===
              IS_LOAN_CUSTOMIZED.manual
                ? 'true'
                : 'false',
          },
          purchased_year: extractYearOrMonthFromDate(
            targetFutureEstateInvestment?.purchased_at,
            'year',
          ),
          constructed_number:
            THIS_YEAR_NUMBER -
            extractYearOrMonthFromDate(
              targetFutureEstateInvestment?.constructed_at,
              'year',
            ),
        }
      : {
          tax_paper_presence_type: TAX_PAPER_PRESENCE_TYPE.yes,
          future_estate_investment: {
            tax: 0,
            rent_income: FUTURE_ESTATE_INVESTMENT_DEFAULT_VALUES.rent_income,
            future_estate_investment_decline_rates_attributes: [
              {
                affected_number: null,
              },
            ],
            renewal_fee_period_months:
              FUTURE_ESTATE_INVESTMENT_DEFAULT_VALUES.renewal_fee_period_months,
            repair_cost_span_years:
              FUTURE_ESTATE_INVESTMENT_DEFAULT_VALUES.repair_cost_span_years,
            housing_loan_attributes: {
              loan_down_payment_manyen:
                FUTURE_ESTATE_INVESTMENT_DEFAULT_VALUES.loan_down_payment_manyen,
              loan_interest_ratio_percent:
                FUTURE_ESTATE_INVESTMENT_DEFAULT_VALUES.loan_interest_ratio_percent,
              repayment_period_years:
                FUTURE_ESTATE_INVESTMENT_DEFAULT_VALUES.repayment_period_years,
            },
          },
        }
  }, [isEdit, futureEstateInvestments, id])

  const handleAddDeclineRateBlock = (
    declineRates: Partial<FutureEstateInvestmentDeclineRate>[],
    index: number,
    change: (key: string, value: Partial<FutureEstateInvestmentDeclineRate>[]) => void,
  ) => {
    const isEnd = index + 1 == declineRates.length
    isEnd ? declineRates.push({}) : declineRates.splice(index + 1, 0, {})

    change(
      'future_estate_investment.future_estate_investment_decline_rates_attributes',
      declineRates,
    )
  }

  return (
    <>
      <Modal
        className="w-[95%] xl:w-[1140px]"
        isOpen={isOpen}
        isDisableBackdropClick
        onClose={onClose}
      >
        <CircleTitle
          className="pb-[32px]"
          title="不動産の想定を設定する"
          classNameTitle="text-[19px] md:text-[24px]"
        />
        <Form
          onSubmit={handleSubmit}
          mutators={{ ...arrayMutators }}
          decorators={[calculator]}
          initialValues={initialValues}
          render={({
            handleSubmit,
            values,
            errors,
            submitErrors,
            submitFailed,
            form: { change },
          }) => {
            const {
              rent_income,
              simulation_type,
              decline_rate_type,
              renewal_fee_type,
              renewal_fee_input_type,
              property_type,
              is_management_and_fund_total,
              property_price_manyen,
              vacancy_rate_type,
              repair_reserve_fund_increase_type,
              management_fee_type,
              need_loan,
              is_loan_customized,
              miscellaneous_cost,
              breadth,
            } = values.future_estate_investment || {}
            const { constructed_number, tax_paper_presence_type } = values

            useEffect(() => {
              if (
                !isInputRepaymentAllValues(
                  values?.future_estate_investment?.housing_loan_attributes || {},
                )
              )
                return

              const newValues = buildRepaymentValues(
                values?.future_estate_investment?.housing_loan_attributes,
              )
              dispatch(createHousingLoanRepayment(newValues))
            }, [values?.future_estate_investment?.housing_loan_attributes])

            return (
              <form onSubmit={handleSubmit} className="pb-30">
                <Grid className="pb-[64px] grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px]">
                  <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2">
                    <Typography
                      className="min-w-[140px] lg:min-w-[232px] pb-[10px] md:pb-0 text-[14px] text-black-800 flex items-center"
                      isBold
                    >
                      シミュレーション方法
                    </Typography>
                    <div className="flex flex-auto items-baseline">
                      <SelectBox
                        name={`future_estate_investment.simulation_type`}
                        placeholder="選択する"
                        options={SIMULATION_TYPE_BUTTON_ITEMS}
                        isFullWidth
                        validate={required}
                      />
                    </div>
                  </div>
                </Grid>

                <div className="hidden md:block" />

                {simulation_type == SIMULATION_TYPE.simple && (
                  <Grid className="pb-[64px] grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px]">
                    <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                      <Typography
                        className="min-w-[140px] lg:min-w-[232px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                        isBold
                      >
                        購入時期
                      </Typography>
                      <div className="flex flex-auto items-baseline">
                        <SelectBox
                          name="purchased_year"
                          placeholder="年を選択"
                          isFullWidth
                          options={buildTwoTypesRangeYearSelectItems(
                            THIS_YEAR_NUMBER,
                            THIS_YEAR_NUMBER + 70,
                          )}
                          validate={required}
                        />
                      </div>
                    </div>

                    <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                      <Typography
                        className="min-w-[140px] lg:min-w-[232px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                        isBold
                      >
                        名義人
                      </Typography>
                      <div className="flex flex-auto items-baseline">
                        <SelectBox
                          name="future_estate_investment.person_flg"
                          placeholder="選択する"
                          isFullWidth
                          options={
                            hasOrWillHaveSpouse
                              ? PERSON_FLG_SELECT_ITEMS
                              : PERSON_WITHOUT_SPOUSE_SELECT_ITEM
                          }
                          validate={required}
                        />
                      </div>
                    </div>

                    <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2">
                      <Typography
                        className="min-w-[140px] lg:min-w-[232px] pb-[10px] lg:pb-0 text-[14px] text-black-800 flex items-center"
                        isBold
                      >
                        物件の価格
                      </Typography>
                      <div className="flex flex-auto items-baseline">
                        <NumberField
                          name="future_estate_investment.property_price_manyen"
                          isFullWidth
                          validate={composeValidators(zeroOrMoreNumber, (value: any) =>
                            commaNumberMaxLength(value, 7),
                          )}
                        />
                        <Typography
                          className="pl-[8px] text-[14px] text-black-800 min-w-[40px]"
                          isBold
                        >
                          万円
                        </Typography>
                      </div>
                    </div>

                    <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2">
                      <Typography
                        className="min-w-[140px] lg:min-w-[232px] pb-[10px] lg:pb-0 text-[14px] text-black-800 flex items-center"
                        isBold
                      >
                        物件のタイプ
                      </Typography>
                      <div className="flex flex-auto items-baseline">
                        <SelectBox
                          name="future_estate_investment.property_type"
                          placeholder="選択する"
                          isFullWidth
                          options={PROPERTY_TYPE_RADIO_ITEMS}
                          validate={required}
                          onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                            if (event.target.value == PROPERTY_TYPE.division) {
                              return change(
                                'future_estate_investment.building_structure_type',
                                BUILDING_STRUCTURE_TYPE.rc,
                              )
                            }
                            change(
                              'future_estate_investment.building_structure_type',
                              BUILDING_STRUCTURE_TYPE.wood,
                            )
                          }}
                        />
                      </div>
                    </div>

                    <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2">
                      <Typography
                        className="min-w-[140px] lg:min-w-[232px] pb-[10px] lg:pb-0 text-[14px] text-black-800 flex items-center"
                        isBold
                      >
                        物件の構造
                      </Typography>
                      <div className="flex flex-auto items-baseline">
                        <SelectBox
                          name="future_estate_investment.building_structure_type"
                          placeholder="選択する"
                          isFullWidth
                          options={BUILDING_STRUCTURE_TYPE_SELECT_ITEMS}
                          validate={required}
                        />
                      </div>
                    </div>

                    <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2">
                      <Typography
                        className="min-w-[140px] lg:min-w-[232px] pb-[10px] lg:pb-0 text-[14px] text-black-800 flex items-center"
                        isBold
                      >
                        築年数
                      </Typography>
                      <div className="flex flex-auto items-baseline">
                        <Typography
                          className="pr-[8px] text-[14px] text-black-800"
                          isBold
                        >
                          築
                        </Typography>
                        <NumberField
                          name="constructed_number"
                          isFullWidth
                          validate={composeValidators((value: any) =>
                            commaNumberMaxLength(value, 7),
                          )}
                        />
                        <Typography
                          className="pl-[8px] text-[14px] text-black-800"
                          isBold
                        >
                          年
                        </Typography>
                      </div>
                    </div>

                    <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2">
                      <Typography
                        className="min-w-[140px] lg:min-w-[232px] pb-[10px] lg:pb-0 text-[14px] text-black-800 flex items-center"
                        isBold
                      >
                        占有面積
                      </Typography>
                      <div className="flex flex-auto items-baseline">
                        <NumberFieldDecimal
                          name="future_estate_investment.breadth"
                          isFullWidth
                          validate={composeValidators(zeroOrMoreNumber, (value: any) =>
                            commaNumberMaxLength(value, 7),
                          )}
                        />
                        <Typography
                          className="pl-[8px] text-[14px] text-black-800"
                          isBold
                        >
                          ㎡
                        </Typography>
                      </div>
                    </div>

                    <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2">
                      <Typography
                        className="min-w-[140px] lg:min-w-[232px] pb-[10px] lg:pb-0 text-[14px] text-black-800 flex items-center"
                        isBold
                      >
                        表面利回り
                      </Typography>
                      <div className="flex flex-auto items-baseline">
                        <NumberFieldDecimal
                          name="future_estate_investment.gross_ratio_percent"
                          isFullWidth
                          validate={composeValidators(zeroOrMoreNumber, (value: any) =>
                            commaNumberMaxLength(value, 7),
                          )}
                        />
                        <Typography
                          className="pl-[8px] text-[14px] text-black-800"
                          isBold
                        >
                          %
                        </Typography>
                      </div>
                    </div>
                  </Grid>
                )}

                {simulation_type == SIMULATION_TYPE.detail && (
                  <>
                    <Grid className="pb-[64px] grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px]">
                      <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                        <Typography
                          className="min-w-[140px] lg:min-w-[232px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                          isBold
                        >
                          購入時期
                        </Typography>
                        <div className="flex flex-auto items-baseline">
                          <SelectBox
                            name="purchased_year"
                            placeholder="年を選択"
                            isFullWidth
                            options={buildTwoTypesRangeYearSelectItems(
                              THIS_YEAR_NUMBER,
                              THIS_YEAR_NUMBER + 70,
                            )}
                            validate={required}
                          />
                        </div>
                      </div>

                      <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                        <Typography
                          className="min-w-[140px] lg:min-w-[232px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                          isBold
                        >
                          名義人
                        </Typography>
                        <div className="flex flex-auto items-baseline">
                          <SelectBox
                            name="future_estate_investment.person_flg"
                            placeholder="選択する"
                            isFullWidth
                            options={
                              hasOrWillHaveSpouse
                                ? PERSON_FLG_SELECT_ITEMS
                                : PERSON_WITHOUT_SPOUSE_SELECT_ITEM
                            }
                            validate={required}
                          />
                        </div>
                      </div>

                      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2">
                        <Typography
                          className="min-w-[140px] lg:min-w-[232px] pb-[10px] lg:pb-0 text-[14px] text-black-800 flex items-center"
                          isBold
                        >
                          物件の価格
                        </Typography>
                        <div className="flex flex-auto items-baseline">
                          <NumberField
                            name="future_estate_investment.property_price_manyen"
                            isFullWidth
                            validate={composeValidators(zeroOrMoreNumber, (value: any) =>
                              commaNumberMaxLength(value, 7),
                            )}
                          />
                          <Typography
                            className="pl-[8px] text-[14px] text-black-800 min-w-[40px]"
                            isBold
                          >
                            万円
                          </Typography>
                        </div>
                      </div>

                      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2">
                        <Typography
                          className="min-w-[140px] lg:min-w-[232px] pb-[10px] lg:pb-0 text-[14px] text-black-800 flex items-center"
                          isBold
                        >
                          物件のタイプ
                        </Typography>
                        <div className="flex flex-auto items-baseline">
                          <SelectBox
                            name="future_estate_investment.property_type"
                            placeholder="選択する"
                            isFullWidth
                            options={PROPERTY_TYPE_RADIO_ITEMS}
                            validate={required}
                            onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                              if (event.target.value == PROPERTY_TYPE.division) {
                                return change(
                                  'future_estate_investment.building_structure_type',
                                  BUILDING_STRUCTURE_TYPE.rc,
                                )
                              }
                              change(
                                'future_estate_investment.building_structure_type',
                                BUILDING_STRUCTURE_TYPE.wood,
                              )
                            }}
                          />
                        </div>
                      </div>

                      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2">
                        <Typography
                          className="min-w-[140px] lg:min-w-[232px] pb-[10px] lg:pb-0 text-[14px] text-black-800 flex items-center"
                          isBold
                        >
                          物件の構造
                        </Typography>
                        <div className="flex flex-auto items-baseline">
                          <SelectBox
                            name="future_estate_investment.building_structure_type"
                            placeholder="選択する"
                            isFullWidth
                            options={BUILDING_STRUCTURE_TYPE_SELECT_ITEMS}
                            validate={required}
                          />
                        </div>
                      </div>

                      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2">
                        <Typography
                          className="min-w-[140px] lg:min-w-[232px] pb-[10px] lg:pb-0 text-[14px] text-black-800 flex items-center"
                          isBold
                        >
                          築年数
                        </Typography>
                        <div className="flex flex-auto items-baseline">
                          <Typography
                            className="pr-[8px] text-[14px] text-black-800"
                            isBold
                          >
                            築
                          </Typography>
                          <NumberField
                            name="constructed_number"
                            isFullWidth
                            validate={composeValidators((value: any) =>
                              commaNumberMaxLength(value, 7),
                            )}
                          />
                          <Typography
                            className="pl-[8px] text-[14px] text-black-800"
                            isBold
                          >
                            年
                          </Typography>
                        </div>
                      </div>

                      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2">
                        <Typography
                          className="min-w-[140px] lg:min-w-[232px] pb-[10px] lg:pb-0 text-[14px] text-black-800 flex items-center"
                          isBold
                        >
                          占有面積
                        </Typography>
                        <div className="flex flex-auto items-baseline">
                          <NumberFieldDecimal
                            name="future_estate_investment.breadth"
                            isFullWidth
                            validate={composeValidators(zeroOrMoreNumber, (value: any) =>
                              commaNumberMaxLength(value, 7),
                            )}
                          />
                          <Typography
                            className="pl-[8px] text-[14px] text-black-800"
                            isBold
                          >
                            ㎡
                          </Typography>
                        </div>
                      </div>
                    </Grid>

                    <CircleTitle className="pb-[32px]" title="収入について" />

                    <Grid className="pb-[64px] grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px]">
                      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2">
                        <Typography
                          className="min-w-[140px] lg:min-w-[232px] pb-[10px] lg:pb-0 text-[14px] text-black-800 flex items-center"
                          isBold
                        >
                          賃料収入
                        </Typography>
                        <div className="flex flex-auto items-baseline">
                          <NumberField
                            name="future_estate_investment.rent_income"
                            isFullWidth
                            validate={composeValidators(zeroOrMoreNumber, (value: any) =>
                              commaNumberMaxLength(value, 7),
                            )}
                          />
                          <Typography
                            className="pl-[8px] text-[14px] text-black-800 min-w-[fit-content]"
                            isBold
                          >
                            円/月
                          </Typography>
                        </div>
                      </div>

                      <div className="hidden md:block" />

                      <div className="pb-[16px] border-black-300 border-dashed border-b-2">
                        <div className="lg:flex ">
                          <Typography
                            className="min-w-[140px] lg:min-w-[232px] pb-[10px] lg:pb-0 text-[14px] text-black-800 flex items-center"
                            isBold
                          >
                            賃料収入の下落率
                          </Typography>
                          <div className="flex flex-auto items-baseline">
                            <SelectBox
                              name="future_estate_investment.decline_rate_type"
                              placeholder="選択する"
                              isFullWidth
                              options={DECLINE_RATE_TYPE_RADIO_ITEMS}
                              validate={required}
                            />
                          </div>
                        </div>

                        {Number(decline_rate_type) == API_FLAG.on && (
                          <div className="ml-[0px] md:ml-[67px] my-[30px]">
                            <FieldArray name="future_estate_investment.future_estate_investment_decline_rates_attributes">
                              {({ fields }) =>
                                fields.map((name, index) => (
                                  <div key={name}>
                                    <div className="relative flex p-[14px] border border-solid rounded-[5px]">
                                      {index == 0 ? (
                                        <Typography
                                          className="text-[14px] text-black-700 flex items-center"
                                          isBold
                                        >
                                          現在
                                        </Typography>
                                      ) : (
                                        <>
                                          <Typography
                                            className="text-[14px] text-black-700 flex items-center mr-[16px]"
                                            isBold
                                          >
                                            築
                                          </Typography>
                                          <NumberField
                                            name={`${name}affected_number`}
                                            widthClassName="max-w-[50px] md:max-w-[100px]"
                                            validate={composeValidators(
                                              zeroOrMoreNumber,
                                              (value: any) =>
                                                commaNumberMaxLength(value, 7),
                                            )}
                                          />
                                          <Typography
                                            className="text-[14px] text-black-700 flex items-center min-w-[50px] ml-[16px]"
                                            isBold
                                          >
                                            年未満
                                          </Typography>
                                          <div className="flex relative items-center  w-full justify-end">
                                            <div
                                              className="cursor-pointer"
                                              onClick={() => fields.remove(index)}
                                            >
                                              <CrossIcon />
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                    <div className="flex relative left-[15px] md:left-[-106px]">
                                      <div className="flex items-center min-w-[19px] md:min-w-[162px] z-[20]">
                                        <Typography
                                          className="text-[14px] text-black-700 font-bold hidden md:flex items-center mr-[16px] ml-[30px] border-b-[0.5px]"
                                          isBold
                                        >
                                          期間を追加する
                                        </Typography>
                                        <div
                                          className="bg-white cursor-pointer"
                                          onClick={() =>
                                            handleAddDeclineRateBlock(
                                              [...fields.value],
                                              index,
                                              change,
                                            )
                                          }
                                        >
                                          <PlusCircleIcon />
                                        </div>
                                      </div>
                                      <div className="relative block md:flex p-[10px] border-l border-solid left-[-12px] z-[10]">
                                        <Typography
                                          className="text-[14px] text-black-900 font-bold flex items-center mr-[16px] ml-[30px] pb-[10px] md:pb-0 justify-items-centerjustify-center w-[max-content] min-w-[120px]"
                                          isBold
                                        >
                                          この期間の下落率
                                        </Typography>
                                        <div className="flex items-center justify-center">
                                          <NumberFieldDecimal
                                            name={`${name}amount_yearly_percent`}
                                            widthClassName="max-w-[50px] md:max-w-[100px]"
                                            validate={composeValidators(
                                              zeroOrMoreNumber,
                                              (value: any) =>
                                                commaNumberMaxLength(value, 7),
                                            )}
                                          />
                                          <Typography
                                            className="pl-[8px] text-[14px] text-black-800 flex items-center min-w-[fit-content]"
                                            isBold
                                          >
                                            %/年
                                          </Typography>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))
                              }
                            </FieldArray>

                            <div className="relative flex p-[14px] border border-solid rounded-[5px]">
                              <Typography
                                className="text-[14px] text-black-700 flex items-center"
                                isBold
                              >
                                終身
                              </Typography>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="hidden md:block" />

                      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2">
                        <Typography
                          className="min-w-[140px] lg:min-w-[232px] pb-[10px] lg:pb-0 text-[14px] text-black-800 flex items-center"
                          isBold
                        >
                          礼金・更新料の有無
                        </Typography>
                        <div className="flex flex-auto items-baseline">
                          <SelectBox
                            name="future_estate_investment.renewal_fee_type"
                            placeholder="選択する"
                            isFullWidth
                            options={RENEWAL_FEE_TYPE_RADIO_ITEMS}
                            validate={required}
                          />
                        </div>
                      </div>

                      <div className="hidden md:block" />

                      {Number(renewal_fee_type) == API_FLAG.on && (
                        <>
                          <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2">
                            <Typography
                              className="min-w-[140px] lg:min-w-[180px] pb-[10px] lg:pb-0 text-[14px] text-black-800 flex items-center"
                              isBold
                            >
                              １回あたりの礼金・更新料
                            </Typography>
                            <div className="flex flex-auto items-baseline">
                              <SelectBox
                                isFullWidth
                                name="future_estate_investment.renewal_fee_input_type"
                                placeholder="選択する"
                                options={[
                                  {
                                    value: RENEWAL_FEE_INPUT_TYPE.yen,
                                    label: '総額を入力',
                                  },
                                  {
                                    value: RENEWAL_FEE_INPUT_TYPE.month,
                                    label: '家賃で算出',
                                  },
                                ]}
                                validate={required}
                              />
                            </div>
                          </div>

                          {Number(renewal_fee_input_type) ==
                            RENEWAL_FEE_INPUT_TYPE.month && (
                            <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2">
                              <div className="flex items-center">
                                <Typography
                                  className="min-w-[max-content] mr-[10px] text-[14px] text-black-800 flex items-center"
                                  isBold
                                >
                                  {`家賃 ${rent_income}円の`}
                                </Typography>
                                <NumberField
                                  isFullWidth
                                  name="future_estate_investment.renewal_fee_to_rent_income_rate"
                                  validate={required}
                                />
                                <Typography
                                  className="min-w-[fit-content] text-[14px] text-black-800 flex items-center ml-[16px]"
                                  isBold
                                >
                                  ヶ月分
                                </Typography>
                              </div>
                            </div>
                          )}

                          {Number(renewal_fee_input_type) ==
                            RENEWAL_FEE_INPUT_TYPE.yen && (
                            <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2">
                              <div className="flex flex-auto items-baseline">
                                <Typography
                                  className="pr-[8px] text-[14px] text-black-800 min-w-[max-content]"
                                  isBold
                                >
                                  総額
                                </Typography>
                                <NumberField
                                  name="future_estate_investment.renewal_fee"
                                  isFullWidth
                                  validate={composeValidators(
                                    zeroOrMoreNumber,
                                    (value: any) => commaNumberMaxLength(value, 7),
                                  )}
                                />
                                <Typography
                                  className="pl-[8px] text-[14px] text-black-800"
                                  isBold
                                >
                                  円
                                </Typography>
                              </div>
                            </div>
                          )}

                          <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2">
                            <Typography
                              className="min-w-[140px] lg:min-w-[232px] pb-[10px] pr-[8px] lg:pb-0 text-[14px] text-black-800 flex items-center"
                              isBold
                            >
                              礼金・更新料の受取頻度
                            </Typography>
                            <div className="flex flex-auto items-baseline">
                              <NumberField
                                isFullWidth
                                name="future_estate_investment.renewal_fee_period_months"
                                validate={composeValidators(required, oneOrMoreNumber)}
                              />
                              <Typography
                                className="pl-[8px] text-[14px] text-black-800 min-w-[90px]"
                                isBold
                              >
                                ヶ月に一回
                              </Typography>
                            </div>
                          </div>
                        </>
                      )}
                    </Grid>

                    <CircleTitle className="pb-[32px]" title="支出について" />

                    <Grid className="pb-[16px] grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px]">
                      {property_type == PROPERTY_TYPE.division && (
                        <>
                          <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2">
                            <Typography
                              className="min-w-[140px] lg:min-w-[232px] pb-[10px] lg:pb-0 text-[14px] text-black-800 flex items-center"
                              isBold
                            >
                              管理費・修繕積立金の入力方法
                            </Typography>
                            <div className="flex flex-auto items-baseline">
                              <SelectBox
                                isFullWidth
                                name="future_estate_investment.is_management_and_fund_total"
                                placeholder="選択する"
                                options={[
                                  {
                                    value: 'total',
                                    label: '総額で入力',
                                  },
                                  {
                                    value: 'detail',
                                    label: '詳細に入力',
                                  },
                                ]}
                                validate={required}
                              />
                            </div>
                          </div>
                          {is_management_and_fund_total == 'detail' && (
                            <>
                              <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2">
                                <Typography
                                  className="min-w-[140px] lg:min-w-[232px] pb-[10px] lg:pb-0 text-[14px] text-black-800 flex items-center"
                                  isBold
                                >
                                  管理費
                                </Typography>
                                <div className="flex flex-auto items-baseline">
                                  <NumberField
                                    name="future_estate_investment.management_fee_monthly"
                                    isFullWidth
                                    validate={composeValidators(
                                      zeroOrMoreNumber,
                                      (value: any) => commaNumberMaxLength(value, 7),
                                    )}
                                  />
                                  <Typography
                                    className="pl-[8px] text-[14px] text-black-800 min-w-[fit-content]"
                                    isBold
                                  >
                                    円/月
                                  </Typography>
                                </div>
                              </div>
                              <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2">
                                <Typography
                                  className="min-w-[140px] lg:min-w-[232px] pb-[10px] lg:pb-0 text-[14px] text-black-800 flex items-center"
                                  isBold
                                >
                                  修繕積立金
                                </Typography>
                                <div className="flex flex-auto items-baseline">
                                  <NumberField
                                    name="future_estate_investment.repair_reserve_fund"
                                    isFullWidth
                                    validate={composeValidators(
                                      zeroOrMoreNumber,
                                      (value: any) => commaNumberMaxLength(value, 7),
                                    )}
                                  />
                                  <Typography
                                    className="pl-[8px] text-[14px] text-black-800 min-w-[fit-content]"
                                    isBold
                                  >
                                    円/月
                                  </Typography>
                                </div>
                              </div>

                              <div className="pb-[16px] border-black-300 border-dashed border-b-2">
                                <div className="lg:flex">
                                  <Typography
                                    className="min-w-[140px] lg:min-w-[232px] pb-[10px] lg:pb-0 text-[14px] text-black-800 flex items-center"
                                    isBold
                                  >
                                    修繕積立金上昇率の設定
                                  </Typography>

                                  <div className="flex flex-auto items-baseline">
                                    <SelectBox
                                      isFullWidth
                                      name="future_estate_investment.repair_reserve_fund_increase_type"
                                      placeholder="選択する"
                                      options={
                                        REPAIR_RESERVE_FUND_INCREASE_TYPE_SELECT_ITEMS
                                      }
                                      validate={required}
                                    />
                                  </div>
                                </div>
                                {Number(repair_reserve_fund_increase_type) ==
                                  REPAIR_RESERVE_FUND_INCREASE_TYPE.change && (
                                  <>
                                    <div className="flex flex-auto items-baseline my-[16px]">
                                      <Typography
                                        className="pr-[8px] text-[14px] text-black-800"
                                        isBold
                                      >
                                        築
                                      </Typography>
                                      <NumberField
                                        name="future_estate_investment.repair_reserve_fund_increase_start"
                                        isFullWidth
                                        validate={composeValidators(
                                          zeroOrMoreNumber,
                                          (value: any) => commaNumberMaxLength(value, 7),
                                        )}
                                      />
                                      <Typography
                                        className="pl-[8px] text-[14px] text-black-800 min-w-[70px]"
                                        isBold
                                      >
                                        年目から
                                      </Typography>
                                      <NumberField
                                        name="future_estate_investment.repair_reserve_fund_increase_span"
                                        isFullWidth
                                        validate={composeValidators(
                                          oneOrMoreNumber,
                                          (value: any) => commaNumberMaxLength(value, 7),
                                        )}
                                      />
                                      <Typography
                                        className="pl-[8px] text-[14px] text-black-800 min-w-[70px]"
                                        isBold
                                      >
                                        年毎に
                                      </Typography>
                                    </div>
                                    <div className="flex flex-auto items-baseline">
                                      <Typography
                                        className="pr-[8px] text-[14px] text-black-800"
                                        isBold
                                      >
                                        築
                                      </Typography>
                                      <NumberField
                                        name="future_estate_investment.repair_reserve_fund_increase_finish"
                                        isFullWidth
                                        validate={composeValidators(
                                          zeroOrMoreNumber,
                                          (value: any) => commaNumberMaxLength(value, 7),
                                        )}
                                      />
                                      <Typography
                                        className="pl-[8px] text-[14px] text-black-800 min-w-[70px]"
                                        isBold
                                      >
                                        年目まで
                                      </Typography>
                                      <NumberFieldDecimal
                                        name="future_estate_investment.repair_reserve_fund_increase_percent"
                                        isFullWidth
                                        validate={composeValidators(
                                          zeroOrMoreNumber,
                                          (value: any) => commaNumberMaxLength(value, 7),
                                        )}
                                      />
                                      <Typography
                                        className="pl-[8px] text-[14px] text-black-800 min-w-[70px]"
                                        isBold
                                      >
                                        %に設定
                                      </Typography>
                                    </div>
                                  </>
                                )}
                              </div>
                            </>
                          )}

                          {is_management_and_fund_total == 'total' && (
                            <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2">
                              <Typography
                                className="min-w-[140px] lg:min-w-[232px] pb-[10px] lg:pb-0 text-[14px] text-black-800 flex items-center"
                                isBold
                              >
                                管理費・修繕積立金合計
                              </Typography>
                              <div className="flex flex-auto items-baseline">
                                <NumberField
                                  name="future_estate_investment.management_fee_monthly_and_repair_reserve_fund_total"
                                  isFullWidth
                                  validate={composeValidators(
                                    zeroOrMoreNumber,
                                    (value: any) => commaNumberMaxLength(value, 7),
                                  )}
                                />
                                <Typography
                                  className="pl-[8px] text-[14px] text-black-800 min-w-[fit-content]"
                                  isBold
                                >
                                  円/月
                                </Typography>
                              </div>
                            </div>
                          )}
                        </>
                      )}

                      <div className="pb-[16px] border-black-300 border-dashed border-b-2">
                        <div className="lg:flex">
                          <Typography
                            className="min-w-[140px] lg:min-w-[232px] pb-[10px] lg:pb-0 text-[14px] text-black-800 flex items-center"
                            isBold
                          >
                            {`${
                              property_type == PROPERTY_TYPE.whole
                                ? '建物管理費（PMフィー）'
                                : '賃貸管理手数料'
                            }`}
                            の設定
                          </Typography>
                          <div className="flex flex-auto items-baseline">
                            <SelectBox
                              isFullWidth
                              name="future_estate_investment.management_fee_type"
                              placeholder="選択する"
                              options={MANAGEMENT_FEE_TYPE_RADIO_ITEMS}
                              validate={required}
                            />
                          </div>
                        </div>
                        {management_fee_type == MANAGEMENT_FEE_TYPE.yen && (
                          <div className="flex flex-auto items-baseline mt-[16px]">
                            <div className="flex flex-auto items-baseline">
                              <NumberField
                                name="future_estate_investment.management_fee_amount"
                                isFullWidth
                                validate={composeValidators(
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 7),
                                )}
                              />
                              <Typography
                                className="pl-[8px] text-[14px] text-black-800 min-w-[fit-content]"
                                isBold
                              >
                                円/月
                              </Typography>
                            </div>
                          </div>
                        )}
                        {management_fee_type == MANAGEMENT_FEE_TYPE.percent && (
                          <div className="flex flex-auto items-baseline mt-[16px]">
                            <Typography
                              className="min-w-[48px] pb-[10px] lg:pb-0 text-[14px] text-black-800 flex items-center"
                              isBold
                            >
                              賃料の
                            </Typography>
                            <div className="flex flex-auto items-baseline">
                              <NumberFieldDecimal
                                name="future_estate_investment.management_fee_rate_percent"
                                isFullWidth
                                validate={composeValidators(
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 7),
                                )}
                              />
                              <Typography
                                className="pl-[8px] text-[14px] text-black-800"
                                isBold
                              >
                                %
                              </Typography>
                            </div>
                          </div>
                        )}
                      </div>

                      {property_type == PROPERTY_TYPE.whole && (
                        <>
                          <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-center">
                            <div className="flex items-center">
                              <Typography
                                className="min-w-[140px] lg:min-w-[232px] pb-[10px] lg:pb-0 text-[14px] text-black-800 flex items-center"
                                isBold
                              >
                                大規模修繕１回あたりの金額
                                <div className="mx-[10px]">
                                  <Tooltip
                                    item="物件購入後に実施する大規模修繕（外壁塗装、屋上防水等）の時期と金額の想定値を入力してください。目安として、建物面積 x 1万円が初期値として入力されています。なお、建物の構造・設備、築年数、状態によって、大規模修繕の金額の幅があります。"
                                    itemClassName="w-[80px] sm:w-[200px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                                  >
                                    <HelpCircleIcon />
                                  </Tooltip>
                                </div>
                              </Typography>
                            </div>

                            <div className="flex flex-auto items-baseline">
                              <div className="flex flex-auto items-baseline">
                                <NumberField
                                  name="future_estate_investment.macroscale_repair_costs"
                                  isFullWidth
                                  validate={composeValidators(
                                    zeroOrMoreNumber,
                                    (value: any) => commaNumberMaxLength(value, 7),
                                  )}
                                />
                                <Typography
                                  className="pl-[8px] text-[14px] text-black-800"
                                  isBold
                                >
                                  円
                                </Typography>
                              </div>
                            </div>
                          </div>

                          <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2">
                            <Typography
                              className="min-w-[140px] lg:min-w-[232px] pb-[10px] lg:pb-0 text-[14px] text-black-800 flex items-center"
                              isBold
                            >
                              大規模修繕の頻度
                            </Typography>
                            <div className="flex flex-auto items-baseline">
                              <div className="flex flex-auto items-baseline">
                                <SelectBox
                                  isFullWidth
                                  name="future_estate_investment.macroscale_repair_costs_occurance_in_months"
                                  placeholder="選択する"
                                  options={ESTATE_INVESTMENT_SPAN_SELECT_ITEMS}
                                  validate={required}
                                />
                                <Typography
                                  className="pl-[8px] text-[14px] text-black-800 min-w-[70px]"
                                  isBold
                                >
                                  年に一回
                                </Typography>
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2">
                        <Typography
                          className="min-w-[140px] lg:min-w-[232px] pb-[10px] lg:pb-0 text-[14px] text-black-800 flex items-center"
                          isBold
                        >
                          {property_type == PROPERTY_TYPE.whole
                            ? '１回あたりのリフォーム費'
                            : '設備・原状回復費用'}
                        </Typography>
                        <div className="flex flex-auto items-baseline">
                          <div className="flex flex-auto items-baseline">
                            <NumberField
                              name="future_estate_investment.repair_cost"
                              isFullWidth
                              validate={composeValidators(
                                zeroOrMoreNumber,
                                (value: any) => commaNumberMaxLength(value, 7),
                              )}
                            />
                            <Typography
                              className="pl-[8px] text-[14px] text-black-800"
                              isBold
                            >
                              円
                            </Typography>
                          </div>
                        </div>
                      </div>

                      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2">
                        <Typography
                          className="min-w-[140px] lg:min-w-[232px] pb-[10px] lg:pb-0 text-[14px] text-black-800 flex items-center"
                          isBold
                        >
                          {property_type == PROPERTY_TYPE.whole
                            ? 'リフォーム'
                            : '原状回復'}
                          の頻度
                        </Typography>
                        <div className="flex flex-auto items-baseline">
                          <div className="flex flex-auto items-baseline">
                            <SelectBox
                              isFullWidth
                              name="future_estate_investment.repair_cost_span_years"
                              placeholder="選択する"
                              options={ESTATE_INVESTMENT_SPAN_SELECT_ITEMS}
                              validate={required}
                            />
                            <Typography
                              className="pl-[8px] text-[14px] text-black-800 min-w-[70px]"
                              isBold
                            >
                              年に一回
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </Grid>

                    {isEdit && (
                      <>
                        <Grid className="pb-[16px] grid-cols-1 gap-x-[40px] gap-y-[16px]">
                          <div className="items-baseline pb-[16px] border-dashed border-b-2 border-black-300">
                            <div className="md:flex items-center py-20 text-14">
                              <Typography className="pb-10 md:mr-30 md:pb-0">
                                確定申告書類はお手元にありますか？
                              </Typography>
                              <div className="flex items-baseline mt-8 md:mt-0">
                                <RadioGroup
                                  name="tax_paper_presence_type"
                                  isDispRow
                                  datas={TAX_PAPER_PRESENCE_TYPE_RADIO_ITEMS}
                                />
                              </div>
                            </div>
                            {tax_paper_presence_type == TAX_PAPER_PRESENCE_TYPE.yes && (
                              <div className="md:max-w-[85%] w-full">
                                <Typography className="text-black-600 my-[10px] text-[14px]">
                                  白色申告をされる場合は、収支内訳書の２枚目の「減価償却費の計算」の項目をご参照ください
                                  <br />
                                  青色申告をされる場合は、青色申告決算書の３枚目の「減価償却費の計算」の項目をご参照ください
                                </Typography>
                                <div>
                                  <img
                                    src={DepreciationTable}
                                    alt="depreciation table"
                                    onClick={() => setIsImage1ModalOpen(true)}
                                  />
                                </div>
                                <Typography className="text-black-800 text-center my-[10px] text-[14px]">
                                  ※ 画像をクリックすると拡大イメージが表示されます
                                </Typography>
                              </div>
                            )}
                          </div>
                        </Grid>

                        <Grid className="pb-[16px] grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px]">
                          <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                            <div className="items-center mr-[10px] min-w-[180px] lg:min-w-[230px] pb-[10px]">
                              <Typography
                                className="mb-[10px] text-[14px] text-black-800"
                                isBold
                              >
                                設備の耐用年数
                              </Typography>
                              {tax_paper_presence_type == TAX_PAPER_PRESENCE_TYPE.yes && (
                                <Typography className="text-[14px] text-black-600">
                                  「設備」に該当する項目の赤枠①
                                  <br />
                                  「耐用年数」を入力ください
                                </Typography>
                              )}
                            </div>
                            <div className="flex flex-auto items-baseline">
                              <NumberField
                                widthClassName="w-full min-w-[50px]"
                                name="future_estate_investment.equipment_durable_years"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => maxLength(value, 3),
                                )}
                              />
                              <Typography
                                className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800 min-w-[70px]"
                                isBold
                              >
                                年
                              </Typography>
                            </div>
                          </div>

                          <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                            <div className="items-center">
                              <Typography
                                className="min-w-[140px] lg:min-w-[232px] pb-[10px] lg:pb-0 text-[14px] text-black-800 flex items-center"
                                isBold
                              >
                                設備の償却費合計
                              </Typography>
                              {tax_paper_presence_type == TAX_PAPER_PRESENCE_TYPE.yes && (
                                <Typography className="text-[14px] text-black-600">
                                  「設備」に該当する項目の赤枠②
                                  <br />
                                  「本年度の償却費合計」を入力ください
                                </Typography>
                              )}
                            </div>
                            <div className="flex flex-auto items-baseline">
                              <NumberField
                                isFullWidth
                                name="future_estate_investment.equipment_depreciation"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 10),
                                )}
                              />
                              <Typography
                                className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800 min-w-[60px]"
                                isBold
                              >
                                円/年
                              </Typography>
                            </div>
                          </div>

                          <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                            <div className="items-center mr-[10px] min-w-[180px] lg:min-w-[230px] pb-[10px]">
                              <Typography
                                className="mb-[10px] text-[14px] text-black-800"
                                isBold
                              >
                                建物の耐用年数
                              </Typography>
                              {tax_paper_presence_type == TAX_PAPER_PRESENCE_TYPE.yes && (
                                <Typography className="text-[14px] text-black-600">
                                  「建物」に該当する項目の赤枠①
                                  <br />
                                  「耐用年数」を入力ください
                                </Typography>
                              )}
                            </div>
                            <div className="flex flex-auto items-baseline">
                              <NumberField
                                widthClassName="w-full min-w-[50px]"
                                name="future_estate_investment.building_durable_years"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => maxLength(value, 3),
                                )}
                              />
                              <Typography
                                className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800 min-w-[70px]"
                                isBold
                              >
                                年
                              </Typography>
                            </div>
                          </div>

                          <div className="md:flex justify-between pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                            <div className="items-center">
                              <Typography
                                className="min-w-[140px] lg:min-w-[232px] pb-[10px] lg:pb-0 text-[14px] text-black-800 flex items-center"
                                isBold
                              >
                                建物の償却費合計
                              </Typography>
                              {tax_paper_presence_type == TAX_PAPER_PRESENCE_TYPE.yes && (
                                <Typography className="text-[14px] text-black-600">
                                  「建物」に該当する項目の赤枠②
                                  <br />
                                  「本年度の償却費合計」を入力ください
                                </Typography>
                              )}
                            </div>

                            <div className="flex flex-auto items-baseline">
                              <NumberField
                                isFullWidth
                                name="future_estate_investment.building_depreciation"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 10),
                                )}
                              />
                              <Typography
                                className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800 min-w-[60px]"
                                isBold
                              >
                                円/年
                              </Typography>
                            </div>
                          </div>

                          <div className="pb-[16px] border-black-300 border-dashed border-b-2">
                            <div className="flex items-center">
                              <Typography
                                className="min-w-[140px] lg:min-w-[232px] pb-[10px] lg:pb-0 text-[14px] text-black-800 flex items-center"
                                isBold
                              >
                                固定資産税・都市計画税
                                <div className="mx-[10px]">
                                  <Tooltip
                                    item="固定資産税納税通知書をご確認ください。「概算を入力」ボタンの押下による計算結果は、 物件価格×0.3%で計算しています。"
                                    itemClassName="w-[80px] sm:w-[200px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                                  >
                                    <HelpCircleIcon />
                                  </Tooltip>
                                </div>
                              </Typography>
                              <Button
                                className="text-[14px] text-black-800 font-default min-w-[112px] h-[37px] border-[0.5px] border-black-700 px-[16px] mx-[16px] my-[5px] rounded-4"
                                type="button"
                                children="概算を入力"
                                onClick={() =>
                                  change(
                                    'future_estate_investment.tax',
                                    calculateTax(
                                      castNumberWithoutComma(constructed_number),
                                      castNumberWithoutComma(breadth),
                                      castNumberWithoutComma(property_price_manyen),
                                    ),
                                  )
                                }
                              />
                            </div>

                            <div className="flex flex-auto items-center justify-end md:mt-[16px]">
                              <NumberField
                                name="future_estate_investment.tax"
                                widthClassName={'md:w-[200px] w-full'}
                                validate={composeValidators(
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 7),
                                )}
                              />
                              <Typography
                                className="pl-[8px] text-[14px] text-black-800 min-w-[fit-content]"
                                isBold
                              >
                                円/年
                              </Typography>
                            </div>
                          </div>

                          <div className="md:flex justify-between pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                            <div className="flex items-center min-w-[180px] lg:min-w-[230px] pb-[10px]">
                              <Typography
                                className="mr-[10px] text-[14px] text-black-800"
                                isBold
                              >
                                空室率の設定方法
                              </Typography>
                            </div>

                            <SelectBox
                              isFullWidth
                              placeholder="選択してください"
                              name="future_estate_investment.vacancy_rate_type"
                              options={VACANCY_SETTING_TYPE_SELECT_ITEMS}
                              validate={required}
                            />
                          </div>

                          {vacancy_rate_type == VACANCY_SETTING_TYPE.own && (
                            <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                              <div className="flex items-center min-w-[180px] lg:min-w-[230px] pb-[10px]">
                                <Typography className="text-[14px] text-black-800" isBold>
                                  空室率
                                </Typography>
                                <div className="mx-[8px]">
                                  <Tooltip
                                    itemClassName="w-[330px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                                    item={
                                      <div>
                                        <Typography className="pb-[5px]" isBold>
                                          空室率の計算方法
                                        </Typography>
                                        <Typography className="pb-[5px]">
                                          ５部屋あるアパートの場合を例に考えてみます。
                                          まず初めに、10年間常に全ての部屋が満室の場合を考えると
                                          ５部屋 x １２０ヶ月 = ６００ヶ月 となります。
                                        </Typography>
                                        <Typography className="pb-[5px]">
                                          次に、１０年間の間にどのくらい空室の期間があるかを考えます。
                                          必要な数字は、①平均的な転出の頻度と、②転出後次の入居者が
                                          決まるまでの平均的な期間です。
                                          ①平均的な転出の頻度が２４ヶ月（=２年間）に1回で、②次の入居者が
                                          決まるまでの平均的な期間が３ヶ月だとすれば、
                                          ５部屋 x (１２０ヶ月 / ①２４ヶ月 x ②３ヶ月) =
                                          ７５ヶ月 となります。
                                        </Typography>
                                        <Typography>
                                          ７５ヶ月 / ６００ヶ月 = ０.１２５ ≒ １２.５％
                                          が空室率となります。
                                        </Typography>
                                      </div>
                                    }
                                  >
                                    <HelpCircleIcon className="hidden md:block" />
                                  </Tooltip>
                                </div>
                              </div>
                              <div className="flex flex-auto items-baseline">
                                <NumberFieldDecimal
                                  name="future_estate_investment.vacancy_rate_percent"
                                  isFullWidth
                                  validate={composeValidators(
                                    required,
                                    zeroOrMoreNumber,
                                    (value: any) => commaNumberMaxLength(value, 6),
                                  )}
                                />
                                <Typography
                                  className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                                  isBold
                                >
                                  ％
                                </Typography>
                              </div>
                            </div>
                          )}

                          {vacancy_rate_type == VACANCY_SETTING_TYPE.auto && (
                            <>
                              <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                                <div className="flex items-center min-w-[180px] lg:min-w-[230px] pb-[10px]">
                                  <Typography
                                    className="text-[14px] text-black-800"
                                    isBold
                                  >
                                    保有部屋数
                                  </Typography>
                                  <div className="mx-[10px]">
                                    <Tooltip
                                      item="※区分の場合は１と入力してください。"
                                      itemClassName="w-[116px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                                    >
                                      <HelpCircleIcon className="hidden md:block" />
                                    </Tooltip>
                                  </div>
                                </div>
                                <div className="flex flex-auto items-baseline">
                                  <NumberField
                                    isFullWidth
                                    name="future_estate_investment.room_count"
                                    validate={composeValidators(
                                      required,
                                      zeroOrMoreNumber,
                                      (value: any) => maxLength(value, 3),
                                    )}
                                  />
                                  <Typography
                                    className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800 min-w-[60px]"
                                    isBold
                                  >
                                    部屋
                                  </Typography>
                                </div>
                              </div>

                              <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                                <div className="flex items-center min-w-[180px] lg:min-w-[230px] mb-[10px] md:mb-0">
                                  <Typography
                                    className="mr-[10px] text-[14px] text-black-800"
                                    isBold
                                  >
                                    平均的な転出の頻度
                                  </Typography>
                                </div>
                                <div className="flex flex-auto items-baseline">
                                  <NumberField
                                    isFullWidth
                                    name="future_estate_investment.move_out_frequency"
                                    validate={composeValidators(
                                      required,
                                      zeroOrMoreNumber,
                                      (value: any) => maxLength(value, 3),
                                    )}
                                  />
                                  <Typography
                                    className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800 min-w-[fit-content]"
                                    isBold
                                  >
                                    ヶ月に１回
                                  </Typography>
                                </div>
                              </div>

                              <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-center">
                                <div className="flex items-center min-w-[180px] lg:min-w-[230px] mb-[10px] md:mb-0">
                                  <Typography
                                    className="mr-[10px] text-[14px] text-black-800"
                                    isBold
                                  >
                                    次の入居者が決まるまでの
                                    <br className="hidden md:block" />
                                    平均的な期間
                                  </Typography>
                                </div>

                                <div className="flex flex-auto items-baseline">
                                  <NumberField
                                    isFullWidth
                                    name="future_estate_investment.time_between"
                                    validate={composeValidators(
                                      required,
                                      zeroOrMoreNumber,
                                      (value: any) => maxLength(value, 3),
                                    )}
                                  />
                                  <Typography
                                    className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800 min-w-[40px]"
                                    isBold
                                  >
                                    ヶ月
                                  </Typography>
                                </div>
                              </div>

                              <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                                <Typography
                                  className="min-w-[180px] lg:min-w-[230px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                                  isBold
                                >
                                  空室率
                                </Typography>
                                <div className="flex flex-auto items-baseline">
                                  <NumberFieldDecimal
                                    isFullWidth
                                    name="future_estate_investment.vacancy_rate_percent"
                                    validate={composeValidators(
                                      required,
                                      zeroOrMoreNumber,
                                      (value: any) => maxLength(value, 3),
                                    )}
                                    disabled
                                  />
                                  <Typography
                                    className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800 min-w-[60px]"
                                    isBold
                                  >
                                    ％
                                  </Typography>
                                </div>
                              </div>
                            </>
                          )}
                        </Grid>
                      </>
                    )}

                    <Grid className="pb-[64px] grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px]">
                      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-center">
                        <Typography
                          className="pb-[10px] lg:pb-0 text-[14px] text-black-800 flex items-center"
                          isBold
                        >
                          その他の経費
                          <div className="mx-[10px]">
                            <Tooltip
                              item="※建物・賃貸管理手数料、修繕積立金、リフォーム費、固定資産税、ローン、賃貸管理手数料以外で不動産にかかる支出を入力してください。"
                              itemClassName="w-[80px] sm:w-[200px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                            >
                              <HelpCircleIcon />
                            </Tooltip>
                          </div>
                        </Typography>

                        <div className="flex flex-auto items-baseline">
                          <div className="flex flex-auto items-baseline">
                            <NumberField
                              name="future_estate_investment.other_cost_yearly"
                              isFullWidth
                              validate={composeValidators(
                                zeroOrMoreNumber,
                                (value: any) => commaNumberMaxLength(value, 7),
                              )}
                            />
                            <Typography
                              className="pl-[8px] text-[14px] text-black-800 min-w-[fit-content]"
                              isBold
                            >
                              円/年
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </Grid>

                    <CircleTitle className="pb-[32px]" title="ローンについて" />

                    <Grid className="pb-[64px] grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px]">
                      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2">
                        <Typography
                          className="min-w-[140px] lg:min-w-[232px] pb-[10px] lg:pb-0 text-[14px] text-black-800 flex items-center"
                          isBold
                        >
                          ローンの有無
                        </Typography>
                        <div className="flex flex-auto items-baseline">
                          <div className="flex flex-auto items-baseline">
                            <SelectBox
                              isFullWidth
                              name="future_estate_investment.need_loan"
                              placeholder="選択する"
                              options={FUTURE_NEED_LOAN_BUTTON_ITEMS}
                              validate={required}
                            />
                          </div>
                        </div>
                      </div>

                      {String(need_loan) == 'true' && (
                        <>
                          <div className="hidden md:block" />
                          <div className="pb-[16px] border-black-300 border-dashed border-b-2">
                            <div className="lg:flex">
                              <Typography
                                className="min-w-[140px] lg:min-w-[232px] pb-[10px] lg:pb-0 text-[14px] text-black-800 flex items-center"
                                isBold
                              >
                                ローンの設定
                              </Typography>
                              <div className="flex flex-auto items-baseline">
                                <div className="flex flex-auto items-baseline">
                                  <SelectBox
                                    isFullWidth
                                    name="future_estate_investment.is_loan_customized"
                                    placeholder="選択する"
                                    options={FUTURE_IS_LOAN_CUSTOMIZED_BUTTON_ITEMS}
                                    validate={required}
                                  />
                                </div>
                              </div>
                            </div>
                            {is_loan_customized == 'true' && (
                              <>
                                <div className="border-[0.5px] mt-[16px] pt-[16px] pl-[16px] w-[300px]">
                                  <div className="flex pb-[16px] border-black-300">
                                    <Typography
                                      className="min-w-[140px] pb-[10px] lg:pb-0 text-[14px] text-black-800 flex items-center"
                                      isBold
                                    >
                                      物件価格
                                    </Typography>
                                    <Typography
                                      className="min-w-[140px] lg:min-w-[232px] pb-[10px] lg:pb-0 text-[14px] text-black-800 flex items-center"
                                      isBold
                                    >
                                      {`${property_price_manyen}万円`}
                                    </Typography>
                                  </div>
                                  <div className="flex pb-[16px] border-black-300">
                                    <Typography
                                      className="min-w-[140px]  pb-[10px] lg:pb-0 text-[14px] text-black-800 flex items-center"
                                      isBold
                                    >
                                      諸費用
                                    </Typography>
                                    <Typography
                                      className="min-w-[140px] lg:min-w-[232px] pb-[10px] lg:pb-0 text-[14px] text-black-800 flex items-center"
                                      isBold
                                    >
                                      {`${miscellaneous_cost}万円`}
                                    </Typography>
                                  </div>
                                  <div className="flex pb-[16px] border-black-300">
                                    <Typography
                                      className="min-w-[140px] pb-[10px] lg:pb-0 text-[14px] text-black-800 flex items-center"
                                      isBold
                                    >
                                      総額
                                    </Typography>
                                    <Typography
                                      className="min-w-[140px] lg:min-w-[232px] pb-[10px] lg:pb-0 text-[14px] text-black-800 flex items-center"
                                      isBold
                                    >
                                      {`${
                                        castNumberWithoutOperator(property_price_manyen) +
                                        castNumberWithoutOperator(miscellaneous_cost)
                                      }万円`}
                                    </Typography>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>

                          <div className="hidden md:block" />

                          {is_loan_customized == 'true' && (
                            <>
                              <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-center">
                                <div className="flex items-center">
                                  <Typography
                                    className="pb-[10px] lg:pb-0 text-[14px] text-black-800 flex items-center"
                                    isBold
                                  >
                                    諸費用
                                  </Typography>
                                  <div className="mx-[10px]">
                                    <Tooltip
                                      item="物件のタイプによって異なりますが、一般的には、購入価格の 5 ~ 7 ％になることが多いです。"
                                      itemClassName="w-[80px] sm:w-[200px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                                    >
                                      <HelpCircleIcon />
                                    </Tooltip>
                                  </div>
                                </div>

                                <div className="flex flex-auto items-baseline">
                                  <div className="flex flex-auto items-baseline">
                                    <NumberField
                                      name="future_estate_investment.miscellaneous_cost"
                                      isFullWidth
                                      validate={composeValidators(
                                        zeroOrMoreNumber,
                                        (value: any) => commaNumberMaxLength(value, 7),
                                      )}
                                    />
                                    <Typography
                                      className="pl-[8px] text-[14px] text-black-800 min-w-[40px]"
                                      isBold
                                    >
                                      万円
                                    </Typography>
                                    <Button
                                      className="text-[14px] text-black-800 font-default min-w-[112px] h-[37px] border-[0.5px] border-black-700 px-[16px] ml-[16px] rounded-4"
                                      type="button"
                                      children="概算を入力"
                                      onClick={() =>
                                        change(
                                          'future_estate_investment.miscellaneous_cost',
                                          calcEstimatedMiscellaneousCost(
                                            castNumberWithoutComma(property_price_manyen),
                                          ),
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="pb-[16px] border-black-300 border-dashed border-b-2">
                                <div className="lg:flex  flex-auto items-center">
                                  <Typography
                                    className="min-w-[140px] lg:min-w-[232px] pb-[10px] lg:pb-0 text-[14px] text-black-800 flex items-center"
                                    isBold
                                  >
                                    住宅ローンの方式
                                  </Typography>
                                  <SelectBox
                                    isFullWidth
                                    name="future_estate_investment.housing_loan_attributes.loan_type"
                                    placeholder="選択する"
                                    options={
                                      hasOrWillHaveSpouse
                                        ? REJECT_LOAN_TYPE_SELECT_ITEMS
                                        : LOAN_TYPE_WITHOUT_SPOUSE_SELECT_ITEM
                                    }
                                    validate={required}
                                  />
                                </div>
                                <div className="flex justify-end">
                                  <Button
                                    className="text-black-600 text-[12px] my-[10px]"
                                    children="各方式で借りれる上限額について"
                                    onClick={() => setIsLoanAmountModalOpen(true)}
                                  />
                                </div>
                              </div>

                              <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2">
                                <Typography
                                  className="min-w-[140px] lg:min-w-[232px] pb-[10px] lg:pb-0 text-[14px] text-black-800 flex items-center"
                                  isBold
                                >
                                  頭金の金額
                                </Typography>
                                <div className="flex flex-auto items-baseline">
                                  <NumberField
                                    name="future_estate_investment.housing_loan_attributes.loan_down_payment_manyen"
                                    isFullWidth
                                    validate={composeValidators(
                                      zeroOrMoreNumber,
                                      (value: any) => commaNumberMaxLength(value, 7),
                                    )}
                                  />
                                  <Typography
                                    className="pl-[8px] text-[14px] text-black-800 min-w-[40px]"
                                    isBold
                                  >
                                    万円
                                  </Typography>
                                </div>
                              </div>

                              <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2">
                                <Typography
                                  className="min-w-[140px] lg:min-w-[232px] pb-[10px] lg:pb-0 text-[14px] text-black-800 flex items-center"
                                  isBold
                                >
                                  お借り入れ金額
                                </Typography>
                                <div className="flex flex-auto items-baseline">
                                  <NumberField
                                    name="future_estate_investment.housing_loan_attributes.loan_amount_manyen"
                                    isFullWidth
                                    defaultValue={
                                      castNumberWithoutOperator(property_price_manyen) +
                                      castNumberWithoutOperator(miscellaneous_cost) -
                                      castNumberWithoutOperator(
                                        values.future_estate_investment
                                          ?.housing_loan_attributes
                                          ?.loan_down_payment_manyen,
                                      )
                                    }
                                    validate={composeValidators(
                                      zeroOrMoreNumber,
                                      (value: any) => commaNumberMaxLength(value, 7),
                                    )}
                                  />
                                  <Typography
                                    className="pl-[8px] text-[14px] text-black-800 min-w-[40px]"
                                    isBold
                                  >
                                    万円
                                  </Typography>
                                </div>
                              </div>

                              <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2">
                                <Typography
                                  className="min-w-[140px] lg:min-w-[180px] pb-[10px] lg:pb-0 text-[14px] text-black-800 flex items-center"
                                  isBold
                                >
                                  借り入れ金利
                                </Typography>
                                <div className="flex flex-auto items-baseline">
                                  <div className="flex flex-auto items-baseline">
                                    <NumberFieldDecimal
                                      name="future_estate_investment.housing_loan_attributes.loan_interest_ratio_percent"
                                      isFullWidth
                                      validate={composeValidators(
                                        zeroOrMoreNumber,
                                        (value: any) => commaNumberMaxLength(value, 7),
                                      )}
                                    />
                                    <Typography
                                      className="pl-[8px] text-[14px] text-black-800"
                                      isBold
                                    >
                                      %
                                    </Typography>
                                    <Button
                                      className="text-[14px] text-black-800 font-default min-w-[152px] h-[37px] border-[0.5px] border-black-700 px-[16px] ml-[16px] rounded-4"
                                      type="button"
                                      children="デフォルトを設定"
                                      onClick={() =>
                                        change(
                                          'future_estate_investment.housing_loan_attributes.loan_interest_ratio_percent',
                                          FUTURE_ESTATE_INVESTMENT_DEFAULT_VALUES.loan_interest_ratio_percent,
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2">
                                <Typography
                                  className="min-w-[140px] lg:min-w-[232px] pb-[10px] lg:pb-0 text-[14px] text-black-800 flex items-center"
                                  isBold
                                >
                                  返済期間
                                </Typography>
                                <div className="flex flex-auto items-baseline">
                                  <div className="flex flex-auto items-baseline">
                                    <NumberField
                                      name="future_estate_investment.housing_loan_attributes.repayment_period_years"
                                      isFullWidth
                                      validate={composeValidators(
                                        required,
                                        zeroOrMoreNumber,
                                        (value: any) => maxLength(value, 2),
                                      )}
                                    />
                                    <Typography
                                      className="pl-[8px] text-[14px] text-black-800"
                                      isBold
                                    >
                                      年
                                    </Typography>
                                  </div>
                                </div>
                              </div>
                              {!_isUndefined(
                                values?.future_estate_investment?.housing_loan_attributes
                                  ?.loan_type,
                              ) && (
                                <div className="md:col-span-2">
                                  <Typography className="pb-[10px] text-black-800" isBold>
                                    月々のお支払い額
                                  </Typography>

                                  <table className="w-full mb-[10px] border">
                                    <tbody>
                                      <tr className="border bg-black-200">
                                        <th className="p-[10px] text-center" />
                                        <th className="p-[10px] text-center">
                                          <Typography className="u-bold">月間</Typography>
                                        </th>
                                        <th className="p-[10px] text-center">
                                          <Typography className="u-bold">年間</Typography>
                                        </th>
                                      </tr>
                                      <tr className="border">
                                        <td className="p-[10px] text-center">
                                          <Typography>返済額</Typography>
                                        </td>
                                        <td className="p-[10px] text-center">
                                          <div className="flex justify-center items-baseline">
                                            <Typography className="text-[20px]">
                                              {convertLocaleString(
                                                housingLoanRepayment.repayment_monthly,
                                              )}
                                            </Typography>
                                            <Typography>円</Typography>
                                          </div>
                                        </td>
                                        <td className="p-[10px] text-center">
                                          <div className="flex justify-center items-baseline">
                                            <Typography className="text-[20px]">
                                              {convertLocaleString(
                                                housingLoanRepayment.repayment_yearly,
                                              )}
                                            </Typography>
                                            <Typography>円</Typography>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <Typography className="text-center text-[14px]">
                                    入力していただいた内容をベースに、金利は自動で設定して計算しています。
                                  </Typography>
                                </div>
                              )}
                              <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2">
                                <Typography
                                  className="min-w-[140px] lg:min-w-[232px] pb-[10px] lg:pb-0 text-[14px] text-black-800 flex items-center"
                                  isBold
                                >
                                  団体信用生命保険への加入
                                </Typography>
                                <div className="flex flex-auto items-baseline">
                                  <div className="flex flex-auto items-baseline">
                                    <div className="flex flex-auto items-baseline">
                                      <SelectBox
                                        isFullWidth
                                        name="future_estate_investment.housing_loan_attributes.has_loan_life_insurance_contract"
                                        placeholder="選択する"
                                        options={YES_NO_BUTTON_ITEMS}
                                        validate={required}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </Grid>
                  </>
                )}

                <SubmitErrorMessage show={!submitErrors && submitFailed} />
                <ButtonPrimary
                  className={clsx(
                    { ['opacity-50']: Object.keys(errors).length > 0 },
                    'w-[fit-content] md:w-[246px] h-[64px] mx-[auto]',
                  )}
                  onClick={() => handleSubmit(values)}
                >
                  <Typography
                    className="bottom-2 flex-auto pl-30 relative text-16 text-white"
                    isBold
                  >
                    保存する
                  </Typography>
                  <ChevronRightIcon className="mr-17" />
                </ButtonPrimary>
              </form>
            )
          }}
        />
      </Modal>

      <ImageMagnifiableModal
        src={DepreciationTable}
        alt="depreciation table"
        isOpen={isImage1ModalOpen}
        onClose={() => setIsImage1ModalOpen(false)}
      />
      {isLoanAmountModalOpen && (
        <Modal
          className="w-[95%] xl:w-auto"
          isOpen={isLoanAmountModalOpen}
          onClose={() => setIsLoanAmountModalOpen(false)}
        >
          <HousingLoanAmountTable isHousePurchase={false} />
        </Modal>
      )}
    </>
  )
}

export default FutureEstateInvestmentModalForm
