import React, { FC } from 'react'
import clsx from 'clsx'

type sizeType = 0 | 1 | 2 | 3 | 4
type colorType = 'black' | 'white' | 'primary'

interface HeadingProps {
  /** クラス名 */
  className?: string
  /** タグ */
  as?: React.ElementType
  /** サイズ */
  size?: sizeType
  /** カラー */
  color?: colorType
}

const classes = {
  size: {
    0: 'text-32',
    1: 'text-24 md:text-32',
    2: 'text-20 md:text-28',
    3: 'text-16 md:text-24',
    4: 'text-16',
  },
  color: {
    black: 'text-black-800',
    white: 'text-white',
    primary: 'text-primary-950',
  },
}

const Heading: FC<HeadingProps> = (props) => {
  const { className, as: CustomTag = 'h1', size, color, children } = props
  return (
    <>
      {/* @ts-ignore */}
      <CustomTag
        className={clsx(
          className,
          classes.size[size as sizeType],
          classes.color[color as colorType],
          'font-bold',
        )}
      >
        {children}
      </CustomTag>
    </>
  )
}

Heading.defaultProps = {
  as: 'h1',
  size: 1,
  color: 'black',
}

export default Heading
