import React, { FC } from 'react'
import { getTargetSelfOwnedAttributesName } from '@/models/commonHousingModelFunc'
import {
  commaNumberMaxLength,
  composeValidators,
  required,
  zeroOrMoreNumber,
} from '@/utils/validate'

import Typography from '@/components/v2/atoms/Typography'
import NumberField from '@/components/v2/molecules/NumberField'
import HelpCircleIcon from '@/assets/images/v2/help-circle.svg'
import Tooltip from '@/components/v2/atoms/Tooltip'
interface OtherCostProps {
  isFuture?: boolean
}

const OtherCost: FC<OtherCostProps> = (props) => {
  const { isFuture } = props
  const targetHousingSelfOwnedAttributesName = getTargetSelfOwnedAttributesName(isFuture)

  return (
    <>
      <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-center">
        <div className="">
          <Typography
            className="flex min-w-[140px] lg:min-w-[210px] pb-[10px] md:pb-0 text-[14px] text-black-800"
            isBold
          >
            年間のその他の費用
            <div className="flex items-center mx-[10px]">
              <Tooltip
                item="駐輪場、町内会費、共有水道光熱費などをご入力ください。駐車場代については、お車のご登録時に入力する画面がありますので、入力しないでください。"
                itemClassName="w-[80px] sm:w-[200px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
              >
                <HelpCircleIcon />
              </Tooltip>
            </div>
          </Typography>
        </div>
        <div className="flex flex-auto items-center">
          <NumberField
            name={`${targetHousingSelfOwnedAttributesName}.other_cost_yearly`}
            isFullWidth
            validate={composeValidators(required, zeroOrMoreNumber, (value: any) =>
              commaNumberMaxLength(value, 10),
            )}
          />
          <Typography className="pl-[8px] text-[14px] text-black-800" isBold>
            円
          </Typography>
        </div>
      </div>
    </>
  )
}

export default OtherCost
