import { find as _find, filter as _filter } from 'lodash'
import { defaultToEmptyString, convertLocaleString } from '@/models/commonsModelFunc'

// 変化の内容
export const CHANGE_TYPE = {
  employment: 0,
  retirement: 1,
  retirementAge: 2,
  retirementOfficer: 3,
}

// 定年退職年齢
export const RETIREMENT_AGE = 99

import { RE_EMPLOYMENT_TYPE_RADIO_ITEMS } from '@/constants/formItem/commonIncomeChanges'

export const convertChangeType = (value: number | null | undefined) => {
  switch (value) {
    case CHANGE_TYPE.employment:
      return '転職'
      return '転職・独立'
    case CHANGE_TYPE.retirement:
      return '早期退職・休業'
      return '退職・定年退職'
    case CHANGE_TYPE.retirementAge:
      return '定年退職'
    case CHANGE_TYPE.retirementOfficer:
      return '役職定年'
      return '転職を伴わない収入の変化'
    default:
      return ''
  }
}

export const convertReEmploymentType = (value: number | null | undefined) => {
  const label = _find(RE_EMPLOYMENT_TYPE_RADIO_ITEMS, (item) => item.value === value)
    ?.label

  return defaultToEmptyString(label)
}

export const formatIncomeChanges = (incomeChanges: any, quitCurrentJobAtAge: number) => {
  let targetIncomeChanges = _filter(
    incomeChanges,
    (item) => item.scheduled_quit_at_age > quitCurrentJobAtAge,
  )
  targetIncomeChanges = targetIncomeChanges.map((item, index) => {
    const scheduledAtAge =
      index === 0
        ? quitCurrentJobAtAge
        : targetIncomeChanges[index - 1].scheduled_quit_at_age
    return { ...item, scheduled_at_age: scheduledAtAge }
  })

  return targetIncomeChanges
}

export const renderIncomeItemLabel = (incomeItem: any) => {
  const {
    change_type,
    estimated_annual_income_manyen,
    severance_pay_manyen,
    retirement_officers_amount_manyen,
  } = incomeItem

  switch (change_type) {
    case CHANGE_TYPE.employment:
      return `年収 ${convertLocaleString(estimated_annual_income_manyen)}万円`
    case CHANGE_TYPE.retirement:
    case CHANGE_TYPE.retirementAge:
      return `退職金 ${convertLocaleString(severance_pay_manyen)}万円`
    case CHANGE_TYPE.retirementOfficer:
      return `年収 ${convertLocaleString(retirement_officers_amount_manyen)}万円`
    default:
      return '万円'
  }
}
