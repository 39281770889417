import React, { FC, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty as _isEmpty } from 'lodash'
import MarginContainer from '@/components/v2/atoms/MarginContainer'
import Paper from '@/components/v2/atoms/Paper'
import Heading from '@/components/v2/atoms/Heading'
import Typography from '@/components/v2/atoms/Typography'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import AssetBalanceTable from '@/components/v2/organisms/AssetBalanceTable'
import { fetchAccount } from '@/containers/accountsSlice'
import { removeAllFlashAlerts, setFlashAlert } from '@/containers/flashAlertsSlice'
import { fetchInputAssetSummary } from '@/containers/inputs/inputAssetsSlice'
import { setPageMeta } from '@/containers/pageMetasSlice'
import { createBulkPastData } from '@/containers/pastMonthlyAssetSummariesSlice'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import { RootState } from '@/store'

const AssetBalance: FC = () => {
  const dispatch = useDispatch()
  const isFromSettingPage: boolean = window.localStorage.isFromSettingPage === 'true'
  const { handleMovePage } = useCustomHistory()

  const { account } = useSelector((state: RootState) => state.accounts)
  const { flashAlertMessageObjects } = useSelector(
    (state: RootState) => state.flashAlerts,
  )

  const hasConnectedWithMoneytree = useMemo(
    () => account['has_connected_with_moneytree?'],
    [account],
  )

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: '口座情報確認',
        description: '口座情報確認',
        keyword: '口座情報確認',
      }),
    )
    dispatch(fetchAccount())
    dispatch(fetchInputAssetSummary())
  }, [])

  const showFlashMsg = () => {
    if (!_isEmpty(flashAlertMessageObjects)) {
      dispatch(removeAllFlashAlerts())
    }

    dispatch(
      setFlashAlert({
        flashAlertMessageObjects: [{ message: `更新しました`, variant: 'success' }],
      }),
    )
  }

  const handleNextPage = () => {
    dispatch(createBulkPastData())
    isFromSettingPage
      ? handleMovePage('/v2/settings/account')
      : handleMovePage('/v2/dashboards')
  }

  return (
    <MarginContainer className="py-40">
      <Paper className="mx-8 px-16 py-24 sm:p-40 space-y-24 bg-white">
        <Heading className="text-center">
          Moneytreeから取得した<span className="inline-block">口座一覧</span>
        </Heading>
        {hasConnectedWithMoneytree ? (
          <>
            <div className="text-14 text-black-700 sm:text-center">
              <Typography>
                Moneytreeから取得した接続先の一覧です。
                <br />
                それぞれの種別を設定してください。
              </Typography>
            </div>
            <AssetBalanceTable showFlashMsg={showFlashMsg} />
          </>
        ) : (
          <Typography className="text-14 text-black-700 sm:text-center">
            取得した接続先はありません。
          </Typography>
        )}
        <div className="flex justify-center">
          <ButtonPrimary className="h-45 w-full text-white" onClick={handleNextPage}>
            <Typography className="bottom-2 flex-auto pl-30 relative text-16" isBold>
              {isFromSettingPage
                ? '登録を完了して個人設定ページへ戻る'
                : '登録を完了してダッシュボードページへ進む'}
            </Typography>
            <i className="ico chevron-right mr-17 text-24" />
          </ButtonPrimary>
        </div>
      </Paper>
    </MarginContainer>
  )
}

export default AssetBalance
