import React, { FC, useState, useContext, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import { includes as _includes, find as _find } from 'lodash'
import Button from '@/components/v2/atoms/Button'
import Tooltip from '@/components/v2/atoms/Tooltip'
import Typography from '@/components/v2/atoms/Typography'
import { destroyFutureMarriageV2 } from '@/containers/futures/futureMarriagesSlice'
import { fetchAccountFutureItems } from '@/containers/accountsSlice'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import { THIS_YEAR_NUMBER, extractYearOrMonthFromDate } from '@/models/commonsModelFunc'
import { SimulationDetailEditContext } from '@/models/futures/futureBasesModelFunc'
import { FutureMarriage } from '@/models/futures/futureMarriagesModel'
import { RootState } from '@/store'
import SimulationAccordion from '@/templates/v2/simulations/_simulationAccordion'
import SimulationNewButton from '@/templates/v2/simulations/_simulationNewButton'
import FutureMarriageModalForm from '@/templates/v2/simulations/_futureMarriageModalForm'
import SimulationItemAccordion from '@/templates/v2/simulations/_simulationItemAccordion'

interface FutureMarriageFormProps {
  /** 選択シミュレーションID */
  selectedSimulationId: number | undefined
  /** true: 結婚の選択解除不可, false: 結婚の選択解除可能 */
  isConditionKeepSelected: boolean
  /** 選択メソッド */
  handleSelect: (item: any) => void
  /* FutureItemsを参照するID */
  futureBaseId?: number
  /* 初期化すべきかどうか */
  shouldInitialize?: boolean
  /* 編集を確認したか */
  confirmEdit?: boolean
  /* 編集確認モーダルを展開するメソッド */
  openEditConfirmModal?: () => void
}

interface FutureMarriageTableProps {
  /** アイテム */
  item: any

  /** td要素のクラス */
  tdClassName?: string
}

export const FutureMarriageTable: FC<FutureMarriageTableProps> = ({
  item,
  tdClassName,
}) => {
  return (
    <table
      key={item.id}
      className={clsx('w-[100%] mb-[20px] last:mb-0 text-[12px] md:text-[14px] bg-white')}
    >
      <tbody>
        <tr className="h-[45px] odd:bg-black-100">
          <td className={`${tdClassName ?? 'w-[60%]'} px-[16px] md:px-[30px]`}>
            <Typography isBold>結婚の予定</Typography>
          </td>
          <td className="px-[16px] md:px-[30px]">
            <Typography>{item.marriage_type == 0 ? 'する' : 'しない'}</Typography>
          </td>
        </tr>

        {item.marriage_type == 0 && (
          <tr className="h-[45px] odd:bg-black-100">
            <td className="px-[16px] md:px-[30px]">
              <Typography isBold>結婚予定年</Typography>
            </td>
            <td className="px-[16px] md:px-[30px]">
              <Typography>
                {extractYearOrMonthFromDate(item.get_married_at, 'year')}年
              </Typography>
            </td>
          </tr>
        )}

        {item.marriage_type == 0 && (
          <tr className="h-[45px] odd:bg-black-100">
            <td className="px-[16px] md:px-[30px]">
              <Typography isBold>結婚相手の誕生年想定</Typography>
            </td>
            <td className="px-[16px] md:px-[30px]">
              <Typography>
                {extractYearOrMonthFromDate(item.partner_birth_day, 'year')}年
              </Typography>
            </td>
          </tr>
        )}

        {item.marriage_type == 0 && (
          <tr className="h-[45px] odd:bg-black-100">
            <td className="px-[16px] md:px-[30px]">
              <Typography isBold>結婚相手の想定年収</Typography>
            </td>
            <td className="px-[16px] md:px-[30px]">
              <Typography>{item.partner_annual_income_manyen}万円</Typography>
            </td>
          </tr>
        )}

        {item.marriage_type == 0 && (
          <tr className="h-[45px] odd:bg-black-100">
            <td className="px-[16px] md:px-[30px]">
              <Typography isBold>婚約指輪の金額</Typography>
            </td>
            <td className="px-[16px] md:px-[30px]">
              <Typography>{item.engagement_ring_manyen}万円</Typography>
            </td>
          </tr>
        )}

        {item.marriage_type == 0 && (
          <tr className="h-[45px] odd:bg-black-100">
            <td className="px-[16px] md:px-[30px]">
              <Typography isBold>結婚指輪の金額</Typography>
            </td>
            <td className="px-[16px] md:px-[30px]">
              <Typography>{item.wedding_ring_manyen}万円</Typography>
            </td>
          </tr>
        )}

        {item.marriage_type == 0 && (
          <tr className="h-[45px] odd:bg-black-100">
            <td className="px-[16px] md:px-[30px]">
              <Typography isBold>挙式・披露宴の金額</Typography>
            </td>
            <td className="px-[16px] md:px-[30px]">
              <Typography>{item.wedding_manyen}万円</Typography>
            </td>
          </tr>
        )}

        {item.marriage_type == 0 && (
          <tr className="h-[45px] odd:bg-black-100">
            <td className="px-[16px] md:px-[30px]">
              <Typography isBold>新婚旅行の金額</Typography>
            </td>
            <td className="px-[16px] md:px-[30px]">
              <Typography>{item.honeymoon_manyen}万円</Typography>
            </td>
          </tr>
        )}

        {item.marriage_type == 0 && (
          <tr className="h-[45px] odd:bg-black-100">
            <td className="px-[16px] md:px-[30px]">
              <Typography isBold>ご祝儀</Typography>
            </td>
            <td className="px-[16px] md:px-[30px]">
              <Typography>{item.congratulations_manyen}万円</Typography>
            </td>
          </tr>
        )}

        {item.marriage_type == 0 && (
          <tr className="h-[45px] odd:bg-black-100">
            <td className="px-[16px] md:px-[30px]">
              <Typography isBold>親からの援助</Typography>
            </td>
            <td className="px-[16px] md:px-[30px]">
              <Typography>{item.support_from_parents_manyen}万円</Typography>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  )
}

const FutureMarriageForm: FC<FutureMarriageFormProps> = (props) => {
  const dispatch = useDispatch()
  const { showFlashMsg } = useFlashAlert()
  const { isManipulatedApproval } = useContext(SimulationDetailEditContext)
  const { selectedSimulationId, isConditionKeepSelected, handleSelect } = props

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [id, setId] = useState<number | null>(null)

  const futureMarriages = useSelector(
    (state: RootState) => state.accounts.futureItems?.future_marriages,
  )

  const isPlanRequired = (item: FutureMarriage): boolean => {
    if (
      selectedSimulationId &&
      selectedSimulationId == item?.id &&
      isConditionKeepSelected
    )
      return true

    return false
  }

  useEffect(() => {
    if (props.futureBaseId == null || !props.shouldInitialize) return
    const item = _find(futureMarriages, { future_base_id: props.futureBaseId })
    if (item != null) handleSelect(item)
  }, [props.shouldInitialize])

  const sortedFutureMarriages = useMemo(() => {
    if (props.futureBaseId == null || futureMarriages == null) return futureMarriages
    const eqFbId = (item: any) => item.future_base_id === props.futureBaseId
    return [
      ...futureMarriages.filter((item) => eqFbId(item)),
      ...futureMarriages.filter((item) => !eqFbId(item)),
    ]
  }, [futureMarriages])

  const titleIndex = (index: number) => {
    if (
      props.futureBaseId == null ||
      props.confirmEdit == null ||
      futureMarriages == null
    )
      return index + 1
    const eqFbId = (item: any) => item.future_base_id === props.futureBaseId
    return index + 1 - futureMarriages.filter((item) => eqFbId(item)).length
  }

  const isExpiredSettings = (date?: string) => {
    if (date == null) return false
    const settingsDate = new Date(date)
    return settingsDate.getFullYear() < THIS_YEAR_NUMBER
  }

  const handleExecIfConfired = (exec: (item: any) => void, item: any) => {
    props.confirmEdit === false ? props.openEditConfirmModal?.() : exec(item)
  }

  return (
    <SimulationAccordion
      className="pb-[16px]"
      title="結婚の検討"
      isSelected={!!selectedSimulationId}
      itemCount={sortedFutureMarriages?.length}
      isExpiredSettings={sortedFutureMarriages?.some(
        (item) =>
          item.id === selectedSimulationId &&
          isExpiredSettings(item.get_married_at ?? undefined),
      )}
    >
      <div className="flex flex-col gap-y-[12px] pt-[6px]">
        {sortedFutureMarriages?.map((item: any, index: number) => {
          const handleDestroyV2 = (item: any) => {
            const result = confirm('本当に削除してよろしいですか？')
            if (!result) return

            const destroyFunc = new Promise((resolve) => {
              dispatch(
                destroyFutureMarriageV2(item, item.id, () => {
                  resolve('')
                  showFlashMsg('削除しました')
                }),
              )
            })

            Promise.all([destroyFunc]).then(() => {
              if (item.id === selectedSimulationId) handleSelect(item)
              dispatch(fetchAccountFutureItems(props.futureBaseId))
            })
          }

          const handleEdit = (item: any) => {
            setId(item.id)
            setIsEdit(true)
            setIsOpenModal(true)
          }

          return (
            <SimulationItemAccordion
              key={`${item.id}_${index}`}
              title={
                props.futureBaseId === item.future_base_id && props.confirmEdit != null
                  ? `登録中の結婚予定`
                  : `結婚予定${titleIndex(index)}`
              }
              isSelected={item.id === selectedSimulationId}
              isExpiredSettings={isExpiredSettings(item.get_married_at)}
              inputType="radio"
              onSelect={() => {
                if (!isPlanRequired(item)) {
                  handleExecIfConfired(handleSelect, item)
                }
              }}
            >
              <div className="flex justify-end mb-[5px]">
                <Button
                  className="mr-[16px]"
                  isDisabled={!isManipulatedApproval}
                  onClick={(e) => {
                    handleExecIfConfired(handleEdit, item)
                    e.stopPropagation()
                  }}
                >
                  <i className="ico edit text-[14px] text-black-700" />
                  <Typography className="pl-[5px] text-[14px] text-black-700">
                    編集
                  </Typography>
                </Button>
                <Button
                  isDisabled={!isManipulatedApproval}
                  onClick={(e) => {
                    handleExecIfConfired(handleDestroyV2, item)
                    e.stopPropagation()
                  }}
                >
                  <Typography className="pl-[5px] text-[14px] text-black-700">
                    削除
                  </Typography>
                </Button>
              </div>

              {isPlanRequired(item) ? (
                <Tooltip
                  className="w-full"
                  item={
                    <div className="w-[330px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50">
                      配偶者が設定されたシミュレーションプランを選択中です。
                      <br />
                      結婚予定の選択解除をするには、配偶者を含む設定項目の選択解除、または設定を変更してください。
                    </div>
                  }
                >
                  <FutureMarriageTable item={item} />
                </Tooltip>
              ) : (
                <FutureMarriageTable item={item} />
              )}
            </SimulationItemAccordion>
          )
        })}
      </div>

      <SimulationNewButton
        isDisabled={!isManipulatedApproval}
        onClick={() => {
          setId(null)
          setIsEdit(false)
          setIsOpenModal(true)
        }}
      />

      {isOpenModal && (
        <FutureMarriageModalForm
          isOpen={isOpenModal}
          isEdit={isEdit}
          onClose={() => setIsOpenModal(false)}
          id={id}
          futureBaseId={props.futureBaseId}
        />
      )}
    </SimulationAccordion>
  )
}

export default FutureMarriageForm
