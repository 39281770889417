import { createSlice } from '@reduxjs/toolkit'
import { postLearningPlatformUser } from '@/api/videoPlatforms/learningPlatformUsers'
import { LearningPlatformUserCreateValues } from '@/models/videoPlatforms/learningPlatformUsersModel'
import { AppThunk } from '@/store'

interface LearningPlatformUserState {
  isLoading: boolean
}

const initialState: LearningPlatformUserState = {
  isLoading: false,
}

const videoPlatformsUsersSlice = createSlice({
  name: 'videoPlatformsUsers',
  initialState,
  reducers: {
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
  },
})

export const {
  requestStart,
  requestSuccess,
  requestFailure,
} = videoPlatformsUsersSlice.actions
export const videoPlatformsUsersReducer = videoPlatformsUsersSlice.reducer

export const createLearningPlatformUser = (
  values: LearningPlatformUserCreateValues,
  resolve: (error: any) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postLearningPlatformUser(values)
    .then(() => {
      dispatch(requestSuccess())
      resolve(undefined)
    })
    .catch((error) => {
      dispatch(requestFailure())
      resolve(error)
    })
}
