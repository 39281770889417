import { isUndefined as _isUndefined, find as _find, filter as _filter } from 'lodash'
import { CHANGE_TYPE } from '@/models/commonIncomeChangesModelFunc'
import { InputIncomeBase } from '@/models/inputs/inputIncomeBasesModel'

export const buildUpdateInitialValues = (inputIncome: InputIncomeBase | undefined) => {
  if (_isUndefined(inputIncome)) {
    return { input_income: { input_income_changes_attributes: [] }, retirement_age: {} }
  }

  const firstIncomeChangeItems = _filter(
    inputIncome.input_income_changes,
    (item) => item.change_type !== CHANGE_TYPE.retirementAge,
  )

  const secondIncomeChangeItem = _find(
    inputIncome.input_income_changes,
    (item) => item.change_type === CHANGE_TYPE.retirementAge,
  )

  return {
    input_income: {
      ...inputIncome,
      input_income_changes_attributes: firstIncomeChangeItems,
    },
    retirement_age: secondIncomeChangeItem,
  }
}
