import { isNull as _isNull } from 'lodash'
import { convertLocaleString } from '../commonsModelFunc'

export const convertYearCost = (
  amount: number | null,
  year: number | null,
  number: number | null,
) => {
  if (_isNull(year) || _isNull(number) || _isNull(amount)) return '0円'
  return `${year}年に${number}度 ${convertLocaleString(amount)}円`
}
