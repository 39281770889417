import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateFutureHousingSaleV2 } from '@/containers/futures/futureHousingSalesSlice'
import {
  DescriptionContext,
  useDescriptionTemplates,
} from '@/hooks/useDescriptionTemplates'
import { QuestionTemplate } from '@/models/commonModalForm'
import { extractYearOrMonthFromDate } from '@/models/commonsModelFunc'
import { FutureHousingSale } from '@/models/futures/futureHousingSalesModel'
import { RootState } from '@/store'

import SaleDetail from '@/templates/v2/simulations/futureV2Housings/FutureHousingSaleModalForm/SaleDetail'

import Modal from '@/components/v2/atoms/Modal'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import { Form } from 'react-final-form'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import Grid from '@/components/v2/atoms/Grid'
import Typography from '@/components/v2/atoms/Typography'
import ChevronRightIcon from '@/assets/images/v2/chevron-right.svg'
import { useFlashAlert } from '@/hooks/useFlashAlert'

const DESCRIPTION_TEMPLATES: QuestionTemplate[] = [
  {
    children: <SaleDetail />,
  },
]

interface FutureHousingSaleUpdateModalFormProps {
  targetHousingSale: Partial<FutureHousingSale>
  /** true: オープン, false: クローズ */
  isOpen: boolean
  /** モーダルクローズメソッド */
  handleClose: () => void
  /** 共通API呼び出しメソッド */
  commonCallApi: () => void
}

const FutureHousingSaleUpdateModalForm: FC<FutureHousingSaleUpdateModalFormProps> = (
  props,
) => {
  const dispatch = useDispatch()
  const { showFlashMsg } = useFlashAlert()
  const { targetHousingSale, isOpen, handleClose, commonCallApi } = props

  const { isLoading } = useSelector((state: RootState) => state.futureHousingSales)

  const { getContextValue } = useDescriptionTemplates({}, DESCRIPTION_TEMPLATES)

  const handleSubmit = (values: any) => {
    const pickedValues: any = values
    const { future_housing_sales } = pickedValues

    const newValues: any = {
      future_housing_sale: {
        ...targetHousingSale,
        sale_amount_manyen: future_housing_sales?.sale_amount_manyen,
        cost_manyen: future_housing_sales?.cost_manyen,
        sell_at: `${future_housing_sales?.sell_at}/01/01`,
        transfer_exception_type: future_housing_sales?.transfer_exception_type,
      },
    }

    return new Promise(() =>
      dispatch(
        updateFutureHousingSaleV2(newValues, targetHousingSale.id!, () => {
          commonCallApi()
          handleClose()
          showFlashMsg('更新しました')
        }),
      ),
    )
  }

  return (
    <Modal
      className="w-[95%] xl:w-[1000px]"
      isOpen={isOpen}
      isDisableBackdropClick
      onClose={handleClose}
      isLoading={isLoading}
    >
      <Form
        onSubmit={handleSubmit}
        initialValues={{
          future_housing_sales: {
            ...targetHousingSale,
            sell_at: extractYearOrMonthFromDate(targetHousingSale?.sell_at, 'year'),
          },
        }}
        render={({ handleSubmit, values, errors }) => {
          return (
            <form className="pb-[30px]" onSubmit={handleSubmit}>
              <DescriptionContext.Provider
                value={{
                  ...getContextValue(),
                }}
              >
                <CircleTitle className="pb-[32px]" title="住居の売却を想定する" />
                <Grid className="grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px]">
                  {DESCRIPTION_TEMPLATES.map((item, index) => {
                    return (
                      ((item.condition && item.condition(values)) || !item.condition) && (
                        <>
                          <React.Fragment key={index}>{item.children}</React.Fragment>
                        </>
                      )
                    )
                  })}
                </Grid>
              </DescriptionContext.Provider>

              <div className="mt-30 flex justify-center">
                <ButtonPrimary
                  className="h-64 w-246"
                  isDisabled={Object.keys(errors).length > 0}
                  onClick={() => handleSubmit(values)}
                >
                  <Typography
                    className="bottom-2 flex-auto pl-30 relative text-16 text-white"
                    isBold
                  >
                    保存する
                  </Typography>
                  <ChevronRightIcon className="mr-17" />
                </ButtonPrimary>
              </div>
            </form>
          )
        }}
      />
    </Modal>
  )
}

export default FutureHousingSaleUpdateModalForm
