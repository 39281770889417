import React, { FC } from 'react'
import { ESTATE_INVESTMENT_SPAN_SELECT_ITEMS } from '@/constants/formItem/commonEstateInvestment'
import { composeValidators, required, zeroOrMoreNumber } from '@/utils/validate'

import Typography from '@/components/v2/atoms/Typography'
import SelectBox from '@/components/v2/molecules/SelectBox'
import NumberField from '@/components/v2/molecules/NumberField'

const RepairCost: FC = () => {
  return (
    <>
      <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
        <Typography
          className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
          isBold
        >
          設備・原状回復費用
        </Typography>
        <div className="flex flex-auto items-baseline">
          <NumberField
            name="future_lend_housing.repair_costs"
            isFullWidth
            validate={composeValidators(required, zeroOrMoreNumber)}
          />
          <Typography className="pl-[8px] text-[14px] text-black-800" isBold>
            円
          </Typography>
        </div>
      </div>

      <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
        <Typography
          className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
          isBold
        >
          設備・原状回復の頻度
        </Typography>
        <div className="flex flex-auto items-baseline">
          <SelectBox
            isFullWidth
            name="future_lend_housing.repair_costs_span"
            options={ESTATE_INVESTMENT_SPAN_SELECT_ITEMS}
            validate={required}
          />
          <Typography className="pl-[8px] text-[14px] text-black-800 min-w-[64px]" isBold>
            年に1回
          </Typography>
        </div>
      </div>
    </>
  )
}

export default RepairCost
