import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { unauthorizedAdminCheck } from '@/containers/admins/fpAccountsSlice'
import { ApiError } from '@/models/commonsModel'
import { AppThunk } from '@/store'
import {
  LearningVideoDemoAccount,
  LearningVideoDemoAccountCreateValues,
  LearningVideoDemoAccountsResponse,
  LearningVideoDemoAccountUpdateValues,
} from '@/models/videoPlatforms/learningVideoDemoAccountsModel'
import {
  getLearningVideoDemoAccounts,
  patchLearningVideoDemoAccount,
  postLearningVideoDemoAccounts,
} from '@/api/videoPlatforms/learningVideoDemoAccounts'

interface LearningVideoDemoAccountState {
  learningVideoDemoAccounts: LearningVideoDemoAccount[]
  isLoading: boolean
}

const initialState: LearningVideoDemoAccountState = {
  learningVideoDemoAccounts: [],
  isLoading: false,
}

const learningVideoDemoAccountsSlice = createSlice({
  name: 'learningVideoDemoAccounts',
  initialState,
  reducers: {
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    getLearningVideosDemoAccountsSuccess: (
      state,
      action: PayloadAction<LearningVideoDemoAccountsResponse[]>,
    ) => {
      state.learningVideoDemoAccounts = action.payload
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
  },
})

export const {
  requestStart,
  requestSuccess,
  getLearningVideosDemoAccountsSuccess,
  requestFailure,
} = learningVideoDemoAccountsSlice.actions

export const learningVideoDemoAccountsReducer = learningVideoDemoAccountsSlice.reducer

export const fetchLearningVideoDemoAccounts = (): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  getLearningVideoDemoAccounts()
    .then((response) => dispatch(getLearningVideosDemoAccountsSuccess(response)))
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedAdminCheck(error))
    })
}

export const createLearningVideoDemoAccount = (
  values: LearningVideoDemoAccountCreateValues,
  callback: () => void,
  resolve: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postLearningVideoDemoAccounts(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      resolve(error)
    })
}

export const updateLearningVideoDemoAccount = (
  values: LearningVideoDemoAccountUpdateValues,
  callback: () => void,
  resolve: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchLearningVideoDemoAccount(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      resolve(error)
    })
}
