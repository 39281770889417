import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { each as _each } from 'lodash'
import IconButton from '@/components/v2/atoms/IconButton'
import { removeFlashAlert } from '@/containers/flashAlertsSlice'
import { RootState } from '@/store'

const FlashAlerts: FC = () => {
  const dispatch = useDispatch()
  const { closeSnackbar, enqueueSnackbar } = useSnackbar()
  const { flashAlertMessageObjects } = useSelector(
    (state: RootState) => state.flashAlerts,
  )

  const handleDismiss = (message: string) => {
    closeSnackbar(message)
    dispatch(removeFlashAlert(message))
  }

  const action = (key: any) => (
    <>
      <div className="tailwind">
        <IconButton
          icon="close"
          className="p-2 text-24"
          onClick={() => handleDismiss(key)}
        />
      </div>
    </>
  )

  useEffect(() => {
    _each(flashAlertMessageObjects, (item) => {
      enqueueSnackbar(item.message, {
        key: item.message,
        variant: item.variant || 'error',
        autoHideDuration: item.autoHideDuration || 3000,
        persist: item.persist,
        onClose: () => dispatch(removeFlashAlert(item.message)),
        action,
      })
    })
  }, [flashAlertMessageObjects])

  return null
}

export default FlashAlerts
