import React, { ChangeEvent, FC, useEffect, useMemo } from 'react'
import { Form } from 'react-final-form'
import Modal from '@/components/v2/atoms/Modal'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import { fetchAggBalanceLastMonth } from '@/containers/aggBalanceMonthliesSlice'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/store'
import { sortBy as _sortBy } from 'lodash'
import { buildRangeAgeSelectItems } from '@/utils/formItem'
import { AFFECTED_END_AT_AGE_SELECT_ITEMS } from '@/constants/formItem/futures/futureMonthlyLivingCosts'
import { isNotEmptyValue } from '@/models/commonsModelFunc'
import { FieldArray } from 'react-final-form-arrays'

import { find as _find } from 'lodash'
import ChevronRightIcon from '@/assets/images/v2/chevron-right.svg'
import CrossIcon from '@/assets/images/v2/cross.svg'
import Button from '@/components/v2/atoms/Button'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import TextField from '@/components/v2/atoms/TextField'
import Typography from '@/components/v2/atoms/Typography'
import NumberField from '@/components/v2/molecules/NumberField'
import arrayMutators from 'final-form-arrays'
import SelectBox from '@/components/v2/molecules/SelectBox'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import { every as _every, isUndefined as _isUndefined, isNull as _isNull } from 'lodash'
import {
  YEARLY_LIVING_COST_CEREMONY_SHEET,
  YEARLY_LIVING_COST_ELECTRONIC_SHEET,
  electronicRequiredV2,
} from '@/models/commonYearlyLivingCostsModelFunc'
import Checkbox from '@/components/v2/atoms/Checkbox'
import { required, zeroOrMoreNumber } from '@/utils/validate'
import {
  buildFutureYearlyLivingCostCreateInitialValues,
  buildFutureYearlyLivingCostSubmitValuesV2,
  buildFutureYearlyLivingCostUpdateInitialValues,
} from '@/models/futures/futureYearlyLivingCostsModelFunc'
import {
  createFutureYearlyLivingCostV2,
  updateFutureYearlyLivingCostV2,
} from '@/containers/futures/futureYearlyLivingCostsSlice'
import { fetchAccountFutureItems } from '@/containers/accountsSlice'
import clsx from 'clsx'
import SubmitErrorMessage from '@/components/v2/molecules/SubmitErrorMessage'
import { FutureYearlyLivingCostBase } from '@/models/futures/futureYearlyLivingCostsModel'

interface FutureYearlyLivingCostModalFormProps {
  /** true: オープン, false: クローズ */
  isOpen: boolean
  /** onCloseメソッド */
  onClose: () => void
  /** onCloseメソッド */
  isEdit: boolean

  /** 編集時のID */
  id: number | null
  /* FutureItemsを参照するID */
  futureBaseId?: number
}

const FutureYearlyLivingCostModalForm: FC<FutureYearlyLivingCostModalFormProps> = (
  props,
) => {
  const { isOpen, onClose, isEdit, id } = props

  const dispatch = useDispatch()
  const { showFlashMsg } = useFlashAlert()

  useEffect(() => {
    dispatch(fetchAggBalanceLastMonth())
  }, [])

  const futureYearlyLivingCostBases = useSelector(
    (state: RootState) => state.accounts.futureItems?.future_yearly_living_cost_bases,
  )

  const targetFutureYearlyLivingCostBase:
    | FutureYearlyLivingCostBase
    | undefined = useMemo(() => {
    return !_isNull(id) ? _find(futureYearlyLivingCostBases, { id }) : undefined
  }, [futureYearlyLivingCostBases, id])

  const {
    future_yearly_living_cost_electronics,
    future_yearly_living_cost_ceremonies,
    future_yearly_living_cost_home_comings,
    future_yearly_living_cost_travels,
  } = targetFutureYearlyLivingCostBase || {}

  const { futureItems } = useSelector((state: RootState) => state.accounts)
  const { person_current_age } = futureItems?.family_info || {}

  const ageSelectItems = useMemo(() => {
    let newAgeSelectItems = []
    newAgeSelectItems.push(
      ...buildRangeAgeSelectItems(
        person_current_age!,
        110,
        AFFECTED_END_AT_AGE_SELECT_ITEMS.map((item: any) => ({
          value: item.value,
          label: `${item.value}`,
        })),
      ),
    )

    return newAgeSelectItems
  }, [])

  const initialValues = useMemo(
    () =>
      isEdit
        ? buildFutureYearlyLivingCostUpdateInitialValues(
            future_yearly_living_cost_ceremonies,
            _sortBy(future_yearly_living_cost_electronics, ['id']),
            future_yearly_living_cost_home_comings,
            future_yearly_living_cost_travels,
          )
        : buildFutureYearlyLivingCostCreateInitialValues(),
    [
      isEdit,
      future_yearly_living_cost_ceremonies,
      future_yearly_living_cost_electronics,
      future_yearly_living_cost_home_comings,
      future_yearly_living_cost_travels,
    ],
  )

  const handleSubmit = (values: any) => {
    return isEdit ? handleUpdate(values) : handleCreate(values)
  }

  const handleCreate = (values: any) => {
    const pickValues: any = values
    const newValues = buildFutureYearlyLivingCostSubmitValuesV2(
      pickValues,
      person_current_age as any,
    )

    dispatch(
      createFutureYearlyLivingCostV2(
        {
          account: {
            future_yearly_living_cost_bases_attributes: [newValues.future_base],
          },
        },
        () => {
          dispatch(fetchAccountFutureItems(props.futureBaseId))
          showFlashMsg(isEdit ? '更新しました' : '作成しました')
          onClose()
        },
      ),
    )
  }

  const handleUpdate = (values: any) => {
    const pickValues: any = values
    const newValues = buildFutureYearlyLivingCostSubmitValuesV2(
      pickValues,
      person_current_age as any,
    )

    dispatch(
      updateFutureYearlyLivingCostV2(
        { future_yearly_living_cost_base: newValues.future_base },
        id!,
        () => {
          dispatch(fetchAccountFutureItems(props.futureBaseId))
          showFlashMsg(isEdit ? '更新しました' : '作成しました')
          onClose()
        },
      ),
    )
  }

  return (
    <Modal
      className="lg:w-[1024px] w-[95%]"
      isOpen={isOpen}
      isDisableBackdropClick
      onClose={onClose}
    >
      <CircleTitle
        className="pb-[32px]"
        title="特別な支出・大きな支出を設定する"
        classNameTitle="text-[19px] md:text-[24px]"
      />

      <div className="w-full lg:w-[80%] mb-[30px]">
        <Typography className="text-black-600">
          家電の買い替え時期と買い替え頻度、1回あたりの費用を設定しましょう。
        </Typography>
        <Typography className="text-black-600">
          持っていないものはチェックを外してください。
        </Typography>
        <Typography className="text-black-600">
          平均値は全て1台あたりの金額を想定しています。
        </Typography>
        <Typography className="text-black-600">
          台数によって金額を入れ替えてください。
        </Typography>
      </div>

      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        mutators={{ ...arrayMutators }}
        render={({
          handleSubmit,
          values,
          errors,
          submitErrors,
          submitFailed,
          form: { change },
        }) => {
          useEffect(() => {
            const isAllCheck = _every(
              values?.future_yearly_living_cost_large_electronics,
              (item) => item.is_active && item.is_active.length > 0,
            )

            change('is_all_large_electronic', isAllCheck)
          }, [values?.future_yearly_living_cost_large_electronics])

          useEffect(() => {
            const isAllCheck = _every(
              values?.future_yearly_living_cost_small_electronics,
              (item) => item.is_active && item.is_active.length > 0,
            )

            change('is_all_small_electronic', isAllCheck)
          }, [values?.future_yearly_living_cost_small_electronics])

          const handleChangeIsAllLargeElectronic = (checked: boolean) => {
            const newLargeElectronics = values?.future_yearly_living_cost_large_electronics.map(
              (item: any) => ({
                ...item,
                is_active: checked,
              }),
            )

            change('future_yearly_living_cost_large_electronics', newLargeElectronics)
          }

          const handleChangeIsAllSmallElectronic = (checked: boolean) => {
            const newSmallElectronics = values?.future_yearly_living_cost_small_electronics.map(
              (item: any) => ({
                ...item,
                is_active: checked,
              }),
            )

            change('future_yearly_living_cost_small_electronics', newSmallElectronics)
          }

          const handleLargeReflectAverage = () => {
            const newLargeElectronics = values?.future_yearly_living_cost_large_electronics.map(
              (item: any) =>
                item.is_active
                  ? {
                      ...item,
                      occurance_in_years:
                        YEARLY_LIVING_COST_ELECTRONIC_SHEET[item.default_key]
                          .occurance_in_years,
                      single_cost:
                        YEARLY_LIVING_COST_ELECTRONIC_SHEET[item.default_key].single_cost,
                    }
                  : { ...item },
            )

            change('future_yearly_living_cost_large_electronics', newLargeElectronics)
          }

          const handleSmallReflectAverage = () => {
            const newSmallElectronics = values?.future_yearly_living_cost_small_electronics.map(
              (item: any) =>
                item.is_active
                  ? {
                      ...item,
                      occurance_in_years:
                        YEARLY_LIVING_COST_ELECTRONIC_SHEET[item.default_key]
                          .occurance_in_years,
                      single_cost:
                        YEARLY_LIVING_COST_ELECTRONIC_SHEET[item.default_key].single_cost,
                    }
                  : { ...item },
            )

            change('future_yearly_living_cost_small_electronics', newSmallElectronics)
          }

          const handleCeremonyReflectAverage = () => {
            const newCeremony = values?.future_base?.future_yearly_living_cost_ceremonies_attributes.map(
              (item: any) => ({
                ...item,
                yearly_cost:
                  YEARLY_LIVING_COST_CEREMONY_SHEET[item.affected_start_at_age],
              }),
            )

            change(
              'future_base.future_yearly_living_cost_ceremonies_attributes',
              newCeremony,
            )
          }

          const handleAddSmallElectronics = () => {
            const newSmallElectronics = [
              ...values?.future_yearly_living_cost_small_electronics,
              {},
            ]
            change('future_yearly_living_cost_small_electronics', newSmallElectronics)
          }

          const handleRemoveSmallElectronics = (index: number) => {
            const copiedSmallElectronics = [
              ...values?.future_yearly_living_cost_small_electronics,
            ]

            if (_isUndefined(copiedSmallElectronics[index].id)) {
              copiedSmallElectronics.splice(index, 1)
            } else {
              copiedSmallElectronics[index]._delete = true
            }

            change('future_yearly_living_cost_small_electronics', copiedSmallElectronics)
          }

          return (
            <form className="pb-[30px]" onSubmit={handleSubmit}>
              <div className="pb-[64px]">
                <div className="flex justify-between items-baseline pb-10 md:pb-0">
                  <Typography className="text-[18px] md:text-[21px]">
                    大物家電について
                  </Typography>

                  <Button
                    className="w-[160px] h-[37px] ml-[auto] mb-0 md:mb-[20px] rounded-[4px] bg-white border border-secondary-500 hover:bg-secondary-50"
                    onClick={handleLargeReflectAverage}
                  >
                    <Typography className="text-[14px] text-secondary-500" isBold>
                      平均値を反映させる
                    </Typography>
                  </Button>
                </div>

                <table className="hidden md:table w-full text-[14px]">
                  <tbody>
                    <tr className="border-b border-black-300">
                      <td className="w-[170px] lg:w-[240px] pb-[10px]">
                        <Checkbox
                          name="is_all_large_electronic"
                          label="家電"
                          className="font-bold"
                          onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            handleChangeIsAllLargeElectronic(event.target.checked)
                          }
                        />
                      </td>
                      <td className="pb-[10px]">
                        <Typography isBold>次回買い替え時期</Typography>
                      </td>
                      <td className="pb-[10px]">
                        <Typography isBold>買い替え頻度</Typography>
                      </td>
                      <td className="pb-[10px]">
                        <Typography isBold>1回あたりの費用</Typography>
                      </td>
                    </tr>

                    <FieldArray name="future_yearly_living_cost_large_electronics">
                      {({ fields }) =>
                        fields.map((name, index) => (
                          <tr key={name} className="border-b border-black-300">
                            <td className="py-[10px]">
                              <Checkbox
                                name={`${name}is_active`}
                                label={fields.value[index]?.display_name}
                              />
                            </td>
                            <td className="py-[10px]">
                              <div className="flex items-baseline">
                                <Typography className="min-w-[fit-content] pr-[8px] pb-[10px]">
                                  ご本人が
                                </Typography>
                                <SelectBox
                                  name={`${name}next_purchase_at_age`}
                                  widthClassName="max-w-[65px] lg:max-w-[100px]"
                                  isDisabled={!fields.value[index]?.is_active}
                                  options={ageSelectItems}
                                  validate={(value: any, allValues: any) =>
                                    electronicRequiredV2(
                                      value,
                                      allValues,
                                      `${name}is_active`,
                                    )
                                  }
                                />

                                <Typography className="min-w-[fit-content] pl-[8px]">
                                  歳の時
                                </Typography>
                              </div>
                            </td>
                            <td className="py-[10px]">
                              <div className="flex items-baseline">
                                <NumberField
                                  name={`${name}occurance_in_years`}
                                  widthClassName="max-w-[65px] lg:max-w-[100px]"
                                  disabled={
                                    !fields.value[index]?.is_active ||
                                    fields.value[index]?.is_active.length == 0
                                  }
                                  validate={(value: any, allValues: any) =>
                                    electronicRequiredV2(
                                      value,
                                      allValues,
                                      `${name}is_active`,
                                      zeroOrMoreNumber,
                                    )
                                  }
                                />
                                <Typography className="min-w-[fit-content] pl-[8px]">
                                  年ごと
                                </Typography>
                              </div>
                            </td>
                            <td className="py-[10px]">
                              <div className="flex items-baseline">
                                <NumberField
                                  name={`${name}single_cost`}
                                  widthClassName="max-w-[100px]"
                                  disabled={
                                    !fields.value[index]?.is_active ||
                                    fields.value[index]?.is_active.length == 0
                                  }
                                  validate={(value: any, allValues: any) =>
                                    electronicRequiredV2(
                                      value,
                                      allValues,
                                      `${name}is_active`,
                                      zeroOrMoreNumber,
                                    )
                                  }
                                />
                                <Typography className="min-w-[fit-content] pl-[8px]">
                                  円
                                </Typography>
                              </div>
                            </td>
                          </tr>
                        ))
                      }
                    </FieldArray>
                  </tbody>
                </table>

                <div className="md:hidden text-[14px]">
                  <div className="pb-[10px] border-b border-black-300">
                    <Checkbox
                      name="is_all_large_electronic"
                      label="家電"
                      className="font-bold"
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        handleChangeIsAllLargeElectronic(event.target.checked)
                      }
                    />
                  </div>
                  <FieldArray name="future_yearly_living_cost_large_electronics">
                    {({ fields }) =>
                      fields.map((name, index) => (
                        <div key={name} className="py-[20px] border-b border-black-300">
                          <Checkbox
                            name={`${name}is_active`}
                            label={fields.value[index]?.display_name}
                            className="pb-[10px]"
                          />
                          <div className="items-center pb-[10px] last:pb-0">
                            <div className="md:flex items-baseline">
                              <div className="flex items-center">
                                <Typography className="min-w-[fit-content] pr-[8px] pb-[10px]">
                                  ご本人が
                                </Typography>
                                <SelectBox
                                  isFullWidth
                                  name={`${name}next_purchase_at_age`}
                                  widthClassName="md:max-w-[65px]"
                                  isDisabled={!fields.value[index]?.is_active}
                                  options={ageSelectItems}
                                  validate={(value: any, allValues: any) =>
                                    electronicRequiredV2(
                                      value,
                                      allValues,
                                      `${name}is_active`,
                                    )
                                  }
                                />
                                <Typography className="min-w-[fit-content] pl-[8px]">
                                  歳の時から
                                </Typography>
                              </div>
                              <div className="flex items-center my-[10px] md:my-0">
                                <NumberField
                                  isFullWidth
                                  name={`${name}occurance_in_years`}
                                  widthClassName="md:max-w-[65px]"
                                  disabled={
                                    !fields.value[index]?.is_active ||
                                    fields.value[index]?.is_active
                                  }
                                  validate={(value: any, allValues: any) =>
                                    electronicRequiredV2(
                                      value,
                                      allValues,
                                      `${name}is_active`,
                                      zeroOrMoreNumber,
                                    )
                                  }
                                />
                                <Typography className="min-w-[fit-content] pl-[8px]">
                                  年ごとに購入
                                </Typography>
                              </div>
                            </div>
                            <div className="flex items-baseline justify-between">
                              <Typography className="min-w-[130px]" isBold>
                                1回あたりの金額
                              </Typography>
                              <div className="flex items-baseline">
                                <NumberField
                                  name={`${name}single_cost`}
                                  isFullWidth
                                  disabled={
                                    !fields.value[index]?.is_active ||
                                    fields.value[index]?.is_active
                                  }
                                  validate={(value: any, allValues: any) =>
                                    electronicRequiredV2(
                                      value,
                                      allValues,
                                      `${name}is_active`,
                                      zeroOrMoreNumber,
                                    )
                                  }
                                />
                                <Typography className="pl-[8px]">円</Typography>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    }
                  </FieldArray>
                </div>
              </div>

              <div className="pb-[64px]">
                <div className="flex justify-between items-baseline pb-10 md:pb-0">
                  <Typography className="text-[18px] md:text-[21px]">
                    小物家電について
                  </Typography>

                  <Button
                    className="w-[160px] h-[37px] ml-[auto] mb-0 md:mb-[20px] rounded-[4px] bg-white border border-secondary-500 hover:bg-secondary-50"
                    onClick={handleSmallReflectAverage}
                  >
                    <Typography className="text-[14px] text-secondary-500" isBold>
                      平均値を反映させる
                    </Typography>
                  </Button>
                </div>

                <table className="hidden md:table w-full mb-[20px] text-[14px]">
                  <tbody>
                    <tr className="border-b border-black-300">
                      <td className="w-[170px] lg:w-[240px] pb-[10px]">
                        <Checkbox
                          name="is_all_small_electronic"
                          label="小物家電"
                          className="font-bold"
                          onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            handleChangeIsAllSmallElectronic(event.target.checked)
                          }
                        />
                      </td>
                      <td className="pb-[10px]">
                        <Typography isBold>次回買い替え時期</Typography>
                      </td>
                      <td className="pb-[10px]">
                        <Typography isBold>買い替え頻度</Typography>
                      </td>
                      <td className="pb-[10px]">
                        <Typography isBold>1回あたりの費用</Typography>
                      </td>
                      <td className="pb-[10px]" />
                    </tr>

                    <FieldArray name="future_yearly_living_cost_small_electronics">
                      {({ fields }) =>
                        fields.map((name, index) => (
                          <tr key={name} className="border-b border-black-300">
                            <td className="py-[10px]">
                              <div className="flex items-baseline">
                                <Checkbox
                                  name={`${name}is_active`}
                                  label={
                                    isNotEmptyValue(fields.value[index]?.default_key)
                                      ? fields.value[index]?.display_name
                                      : ''
                                  }
                                />
                                {!isNotEmptyValue(fields.value[index]?.default_key) && (
                                  <TextField
                                    type="text"
                                    name={`${name}display_name`}
                                    widthClassName="max-w-[130px] lg:max-w-[170px]"
                                    disabled={
                                      !fields.value[index]?.is_active ||
                                      fields.value[index]?.is_active.length == 0
                                    }
                                    validate={(value: any, allValues: any) =>
                                      electronicRequiredV2(
                                        value,
                                        allValues,
                                        `${name}is_active`,
                                      )
                                    }
                                  />
                                )}
                              </div>
                            </td>
                            <td className="py-[10px]">
                              <div className="flex items-baseline">
                                <Typography className="min-w-[fit-content] pr-[8px] pb-[10px]">
                                  ご本人が
                                </Typography>

                                <SelectBox
                                  name={`${name}next_purchase_at_age`}
                                  widthClassName="max-w-[65px] lg:max-w-[100px]"
                                  isDisabled={!fields.value[index]?.is_active}
                                  options={ageSelectItems}
                                  validate={(value: any, allValues: any) =>
                                    electronicRequiredV2(
                                      value,
                                      allValues,
                                      `${name}is_active`,
                                    )
                                  }
                                />

                                <Typography className="min-w-[fit-content] pl-[8px]">
                                  歳の時
                                </Typography>
                              </div>
                            </td>
                            <td className="py-[10px]">
                              <div className="flex items-baseline">
                                <NumberField
                                  name={`${name}occurance_in_years`}
                                  widthClassName="max-w-[65px] lg:max-w-[100px]"
                                  disabled={
                                    !fields.value[index]?.is_active ||
                                    fields.value[index]?.is_active.length == 0
                                  }
                                  validate={(value: any, allValues: any) =>
                                    electronicRequiredV2(
                                      value,
                                      allValues,
                                      `${name}is_active`,
                                      zeroOrMoreNumber,
                                    )
                                  }
                                />
                                <Typography className="min-w-[fit-content] pl-[8px]">
                                  年ごと
                                </Typography>
                              </div>
                            </td>
                            <td className="py-[10px]">
                              <div className="flex items-baseline">
                                <NumberField
                                  name={`${name}single_cost`}
                                  widthClassName="max-w-[100px]"
                                  disabled={
                                    !fields.value[index]?.is_active ||
                                    fields.value[index]?.is_active.length == 0
                                  }
                                  validate={(value: any, allValues: any) =>
                                    electronicRequiredV2(
                                      value,
                                      allValues,
                                      `${name}is_active`,
                                      zeroOrMoreNumber,
                                    )
                                  }
                                />
                                <Typography className="min-w-[fit-content] pl-[8px]">
                                  円
                                </Typography>
                              </div>
                            </td>
                            <td className="py-[10px]">
                              {!isNotEmptyValue(fields.value[index]?.default_key) && (
                                <Button
                                  onClick={() => handleRemoveSmallElectronics(index)}
                                >
                                  <CrossIcon />
                                </Button>
                              )}
                            </td>
                          </tr>
                        ))
                      }
                    </FieldArray>
                  </tbody>
                </table>

                <div className="md:hidden pb-[20px] text-[14px]">
                  <div className="pb-[10px] border-b border-black-300">
                    <Checkbox
                      name="is_all_small_electronic"
                      label="家電"
                      className="font-bold"
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        handleChangeIsAllSmallElectronic(event.target.checked)
                      }
                    />
                  </div>
                  <FieldArray name="future_yearly_living_cost_small_electronics">
                    {({ fields }) =>
                      fields.map((name, index) => (
                        <div
                          key={name}
                          className="relative py-[20px] border-b border-black-300"
                        >
                          <div className="flex items-baseline pb-[10px]">
                            <Checkbox
                              name={`${name}is_active`}
                              label={fields.value[index]?.display_name}
                            />
                            {!isNotEmptyValue(fields.value[index]?.default_key) && (
                              <TextField
                                type="text"
                                name={`${name}display_name`}
                                widthClassName="max-w-[250px]"
                                disabled={
                                  !fields.value[index]?.is_active ||
                                  fields.value[index]?.is_active
                                }
                                validate={(value: any, allValues: any) =>
                                  electronicRequiredV2(
                                    value,
                                    allValues,
                                    `${name}is_active`,
                                  )
                                }
                              />
                            )}
                          </div>
                          <div className="items-center pb-[10px] last:pb-0">
                            <div className="md:flex items-center">
                              <div className="flex items-center">
                                <Typography className="min-w-[fit-content] pr-[8px] pb-[10px]">
                                  ご本人が
                                </Typography>
                                <SelectBox
                                  isFullWidth
                                  name={`${name}next_purchase_at_age`}
                                  widthClassName="md:max-w-[65px]"
                                  isDisabled={!fields.value[index]?.is_active}
                                  options={ageSelectItems}
                                  validate={(value: any, allValues: any) =>
                                    electronicRequiredV2(
                                      value,
                                      allValues,
                                      `${name}is_active`,
                                    )
                                  }
                                />
                                <Typography className="min-w-[fit-content] pl-[8px]">
                                  歳の時から
                                </Typography>
                              </div>

                              <div className="flex items-center my-[10px] md:my-0">
                                <NumberField
                                  isFullWidth
                                  name={`${name}occurance_in_years`}
                                  widthClassName="md:max-w-[65px]"
                                  disabled={
                                    !fields.value[index]?.is_active ||
                                    fields.value[index]?.is_active
                                  }
                                  validate={(value: any, allValues: any) =>
                                    electronicRequiredV2(
                                      value,
                                      allValues,
                                      `${name}is_active`,
                                      zeroOrMoreNumber,
                                    )
                                  }
                                />
                                <Typography className="min-w-[fit-content] pl-[8px]">
                                  年ごとに購入
                                </Typography>
                              </div>
                            </div>
                            <div className="flex items-baseline justify-between">
                              <Typography className="min-w-[130px]" isBold>
                                1回あたりの金額
                              </Typography>
                              <div className="flex items-baseline">
                                <NumberField
                                  name={`${name}single_cost`}
                                  isFullWidth
                                  disabled={
                                    !fields.value[index]?.is_active ||
                                    fields.value[index]?.is_active
                                  }
                                  validate={(value: any, allValues: any) =>
                                    electronicRequiredV2(
                                      value,
                                      allValues,
                                      `${name}is_active`,
                                      zeroOrMoreNumber,
                                    )
                                  }
                                />
                                <Typography className="pl-[8px]">円</Typography>
                              </div>
                            </div>
                          </div>
                          {!isNotEmptyValue(fields.value[index]?.default_key) && (
                            <Button
                              className="absolute top-[20px] right-0"
                              onClick={() => handleRemoveSmallElectronics(index)}
                            >
                              <CrossIcon />
                            </Button>
                          )}
                        </div>
                      ))
                    }
                  </FieldArray>
                </div>

                <Button
                  className="w-full h-[53px] border border-dashed border-primary-500 text-[14px] text-primary-500 hover:bg-primary-50"
                  onClick={handleAddSmallElectronics}
                >
                  <Typography isBold>追加</Typography>
                </Button>
              </div>

              <div className="pb-[64px]">
                <div className="flex justify-between items-baseline pb-10 md:pb-0">
                  <Typography className="text-[18px] md:text-[21px]">
                    冠婚葬祭について
                  </Typography>

                  <Button
                    className="w-[160px] h-[37px] ml-[auto] mb-0 md:mb-[20px] rounded-[4px] bg-white border border-secondary-500 hover:bg-secondary-50"
                    onClick={handleCeremonyReflectAverage}
                  >
                    <Typography className="text-[14px] text-secondary-500" isBold>
                      平均値を反映させる
                    </Typography>
                  </Button>
                </div>

                <table className="hidden md:table table-fixed w-full text-[14px]">
                  <tbody>
                    <tr className="border-b border-black-300">
                      <td className="pb-[10px]">
                        <Typography isBold>ご本人の年齢</Typography>
                      </td>
                      <td className="pb-[10px]">
                        <Typography isBold>年間の冠婚葬祭にかかる費用</Typography>
                      </td>
                    </tr>
                    <FieldArray name="future_base.future_yearly_living_cost_ceremonies_attributes">
                      {({ fields }) =>
                        fields.map((name, index) => (
                          <tr key={name} className="border-b border-black-300">
                            <td className="py-[10px]">
                              <Typography>{`${fields.value[index]?.affected_start_at_age}歳から${fields.value[index]?.affected_end_at_age}歳`}</Typography>
                            </td>
                            <td className="py-[10px]">
                              <div className="flex items-baseline">
                                <NumberField
                                  name={`${name}yearly_cost`}
                                  validate={required}
                                />
                                <Typography className="pl-[8px] min-w-[fit-content]">
                                  円/年
                                </Typography>
                              </div>
                            </td>
                          </tr>
                        ))
                      }
                    </FieldArray>
                  </tbody>
                </table>

                <div className="md:hidden pb-[20px] text-[14px]">
                  <FieldArray name="future_base.future_yearly_living_cost_ceremonies_attributes">
                    {({ fields }) =>
                      fields.map((name, index) => (
                        <div key={name} className="py-[20px] border-b border-black-300">
                          <div className="flex items-baseline pb-[10px] last:pb-0">
                            <Typography className="min-w-[130px]" isBold>
                              ご本人の年齢
                            </Typography>
                            <Typography>{`${fields.value[index]?.affected_start_at_age}歳から${fields.value[index]?.affected_end_at_age}歳`}</Typography>
                          </div>
                          <div className="flex pb-[10px] last:pb-0">
                            <Typography className="min-w-[130px]" isBold>
                              年間の冠婚葬祭に
                              <br />
                              かかる費用
                            </Typography>
                            <div className="flex items-baseline">
                              <NumberField
                                name={`${name}yearly_cost`}
                                isFullWidth
                                validate={required}
                              />
                              <Typography className="pl-[8px] min-w-[fit-contetnt]">
                                円/年
                              </Typography>
                            </div>
                          </div>
                        </div>
                      ))
                    }
                  </FieldArray>
                </div>
              </div>

              <div className="pb-[64px]">
                <div className="flex justify-between items-baseline pb-10 md:pb-0">
                  <Typography className="text-[18px] md:text-[21px]">
                    帰省について
                  </Typography>
                </div>

                <div className="md:flex justify-between items-baseline py-[20px] border-b border-black-300">
                  <Typography className="pb-[10px] md:pb-0 text-[14px]">
                    帰省１回あたりの費用
                  </Typography>
                  <div className="flex items-baseline">
                    <NumberField
                      isFullWidth
                      name="future_base.future_yearly_living_cost_home_comings_attributes[0]single_cost"
                      validate={required}
                    />
                    <Typography className="pl-[8px]">円</Typography>
                  </div>
                </div>
                <div className="md:flex justify-between items-baseline py-[20px] border-b border-black-300">
                  <Typography className="pb-[10px] md:pb-0 text-[14px]">
                    帰省の頻度
                  </Typography>
                  <div className="flex items-baseline">
                    <NumberField
                      isFullWidth
                      name="future_base.future_yearly_living_cost_home_comings_attributes[0]occurance_term_in_years"
                      widthClassName="md:max-w-[100px]"
                      validate={required}
                    />
                    <Typography className="min-w-[fit-content] px-[8px]">年に</Typography>
                    <NumberField
                      isFullWidth
                      name="future_base.future_yearly_living_cost_home_comings_attributes[0]occurance_per_occurance_term"
                      widthClassName="md:max-w-[100px]"
                      validate={required}
                    />
                    <Typography className="pl-[8px]">回</Typography>
                  </div>
                </div>
                <div className="md:flex justify-between items-baseline py-[20px] border-b border-black-300">
                  <Typography className="pb-[10px] md:pb-0 text-[14px]">
                    適用期間
                  </Typography>
                  <div className="flex items-baseline">
                    <NumberField
                      name="future_base.future_yearly_living_cost_home_comings_attributes[0]affected_end_at_age"
                      widthClassName="max-w-[100px]"
                      validate={required}
                    />
                    <Typography className="min-[fit-content] pl-[8px]">歳まで</Typography>
                  </div>
                </div>
              </div>

              <div className="pb-[64px]">
                <div className="flex justify-between items-baseline pb-10 md:pb-0">
                  <Typography className="text-[18px] md:text-[21px]">
                    旅行について
                  </Typography>
                </div>
                <div className="md:flex justify-between items-baseline py-[20px] border-b border-black-300">
                  <Typography className="pb-[10px] md:pb-0 text-[14px]">
                    旅行１回あたりの費用
                  </Typography>
                  <div className="flex items-baseline">
                    <NumberField
                      isFullWidth
                      name="future_base.future_yearly_living_cost_travels_attributes[0]single_cost"
                      validate={required}
                    />
                    <Typography className="pl-[8px]">円</Typography>
                  </div>
                </div>
                <div className="md:flex justify-between items-baseline py-[20px] border-b border-black-300">
                  <Typography className="pb-[10px] md:pb-0 text-[14px]">
                    旅行の頻度
                  </Typography>
                  <div className="flex items-baseline">
                    <NumberField
                      isFullWidth
                      name="future_base.future_yearly_living_cost_travels_attributes[0]occurance_term_in_years"
                      widthClassName="md:max-w-[100px]"
                      validate={required}
                    />
                    <Typography className="min-w-[fit-content] px-[8px]">年に</Typography>
                    <NumberField
                      isFullWidth
                      name="future_base.future_yearly_living_cost_travels_attributes[0]occurance_per_occurance_term"
                      widthClassName="md:max-w-[100px]"
                      validate={required}
                    />
                    <Typography className="pl-[8px]">回</Typography>
                  </div>
                </div>
                <div className="md:flex justify-between items-baseline py-[20px] border-b border-black-300">
                  <Typography className="pb-[10px] md:pb-0 text-[14px]">
                    適用期間
                  </Typography>
                  <div className="flex items-baseline">
                    <NumberField
                      name="future_base.future_yearly_living_cost_travels_attributes[0]affected_end_at_age"
                      widthClassName="max-w-[100px]"
                      validate={required}
                    />
                    <Typography className="min-[fit-content] pl-[8px]">歳まで</Typography>
                  </div>
                </div>
              </div>
              <SubmitErrorMessage show={!submitErrors && submitFailed} />
              <ButtonPrimary
                className={clsx(
                  { ['opacity-50']: Object.keys(errors).length > 0 },
                  'w-[fit-content] md:w-[246px] h-[64px] mx-[auto]',
                )}
                onClick={() => handleSubmit(values)}
              >
                <Typography
                  className="relative bottom-[2px] pl-[30px] text-[16px] text-white flex-auto"
                  isBold
                >
                  保存する
                </Typography>
                <ChevronRightIcon className="mr-[17px]" />
              </ButtonPrimary>
            </form>
          )
        }}
      />
    </Modal>
  )
}

export default FutureYearlyLivingCostModalForm
