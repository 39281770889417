import React, { FC } from 'react'
import { isEmpty as _isEmpty } from 'lodash'
import MarginContainer from '@/components/v2/atoms/MarginContainer'
import ApiEndpointTest from '@/components/tests/ApiEndpointTest'

const SettingTests: FC = () => {
  return (
    <MarginContainer className="py-40">
      <ApiEndpointTest />
    </MarginContainer>
  )
}

export default SettingTests



