import React, { FC, ChangeEvent } from 'react'
import { Field } from 'react-final-form'
import clsx from 'clsx'
import { isUndefined as _isUndefined } from 'lodash'
import CalendarIcon from '@/assets/images/v2/calendar.svg'
import ValidMessage from '@/components/v2/atoms/ValidMessage'
import { isNotEmptyValue } from '@/models/commonsModelFunc'

interface CalendarSelectBoxProps {
  /** 名前 */
  name: string
  /** クラス名 */
  className?: string
  /** selectタグ用クラス名 */
  selectClassName?: string
  /** 幅用クラス名 */
  widthClassName?: string
  /** 高さ用クラス名 */
  heightClassName?: string
  /** プレースホルダ */
  placeholder?: string
  /** オプション */
  options: { value: any; label: string }[]
  /** true: width100%, false: width100%無し */
  isFullWidth?: boolean
  /** バリデーションメソッド */
  validate?: (value: any) => void
  /** onChangeメソッド */
  onChange?: (event: ChangeEvent) => void
}

const CalendarSelectBox: FC<CalendarSelectBoxProps> = (props) => {
  const {
    name,
    className,
    selectClassName,
    widthClassName,
    heightClassName,
    placeholder,
    options,
    isFullWidth,
    validate,
    onChange,
  } = props

  return (
    <Field name={name} validate={validate}>
      {(props) => {
        const { input, meta } = props

        const handleChange = (event: ChangeEvent) => {
          input.onChange(event)
          onChange?.(event)
        }

        return (
          <div
            className={clsx(className, widthClassName, {
              ['w-full']: isFullWidth,
            })}
          >
            <div
              className={clsx(`${widthClassName} inline-block relative`, {
                ['w-full']: isFullWidth,
              })}
            >
              <div className="absolute flex inset-y-0 items-center pointer-events-none px-0.5rem">
                <CalendarIcon />
              </div>
              <select
                {...input}
                className={clsx(
                  `${selectClassName} ${widthClassName} ${
                    heightClassName ?? 'h-44'
                  } appearance-none bg-white border border-black-300 pl-30 pr-16 rounded-2 text-14 hover:border-black-500`,
                  {
                    ['w-full']: isFullWidth,
                    ['text-black-900']: isNotEmptyValue(input.value),
                    ['text-black-400']: !isNotEmptyValue(input.value),
                  },
                )}
                onChange={handleChange}
              >
                {!_isUndefined(placeholder) ? (
                  <option value="" disabled>
                    {placeholder}
                  </option>
                ) : (
                  <option className="hidden" value="" />
                )}
                {options.map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
            <ValidMessage meta={meta} />
          </div>
        )
      }}
    </Field>
  )
}

export default CalendarSelectBox
