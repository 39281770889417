import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { isUndefined as _isUndefined, set as _set } from 'lodash'
import {
  getSummaryBudgets,
  getSummaryBudgetDetail,
  getSummaryTuitions,
  getSummaryTransitions,
  postSummaryTransitionSearch,
  postSummaryBalancesSearchV2,
} from '@/api/summaries'
import { unauthorizedCheck } from '@/containers/accountsSlice'
import {
  SummaryFuture,
  SummaryBudgetResponse,
  SummaryBudget,
  SummaryBudgetDetailFetchValues,
  SummaryAsset,
  SummaryHousing,
  SummaryTuitionResponse,
  SummaryTuition,
  SummaryAfterRetirement,
  SummaryTransition,
  SummaryTransitionCreateValues,
  SummaryBalanceCreateValues,
} from '@/models/summariesModel'
import { buildSortAllJournals } from '@/models/summariesModelFunc'
import { AppThunk } from '@/store'

interface SummaryState {
  summaryFuture: SummaryFuture
  summaryBudgets: SummaryBudget
  summaryAssets: SummaryAsset
  summaryHousings: SummaryHousing
  summaryTuitions: SummaryTuition
  summaryAfterRetirements: SummaryAfterRetirement
  summaryTransitions: SummaryTransition
  isLoading: boolean
  isLoadingTransition: boolean
}

const currentBudgetsInfo = {
  spending_total: 0,
  spending_defference: 0,
  income_total: 0,
  income_defference: 0,
  balance_total: 0,
  balance_defference: 0,
}

const budgetDetail = {
  is_from_moneytree: false,
  last_month: null,
  current_month: '',
  next_month: null,
}

const loanInfo = {
  remain_loan_amount: 0,
  remain_loan_count: 0,
  last_repayment_year: 0,
}

const initialState: SummaryState = {
  summaryFuture: {
    asset_summary: {
      future: {
        current_year_asset_total: 0,
        money_dead_year: ' ー ',
        money_dead_age: null,
        year_of_age_65_asset: 0,
      },
      input: {
        current_year_asset_total: 0,
        money_dead_year: ' ー ',
        money_dead_age: null,
        year_of_age_65_asset: 0,
      },
      difference: {
        current_year_asset_total: 0,
        money_dead_year: ' ー ',
        year_of_age_65_asset: 0,
      },
    },
    simulation_graph: [],
    balance_graph: {},
    investment_graph: {},
    current_year_asset: {
      assets: [],
      total: 0,
    },
    net_asset_graph: [],
    financial_asset_graph: [],
    has_main_future_base: false,
    plan_summary: {
      id: 0,
      is_main: false,
      future_name: '',
      handler_type: 'user',
      plan_status: 'hide',
      detail_items: {
        asset_investment_real_estate_count: 0,
        monthly_living_cost_changes: 0,
        yearly_living_cost_setting: false,
        buying_insurance_count: 0,
        childcare_leave: null,
        children_count: 0,
        educational_policy: null,
        future_risk_applicant: '',
        get_marriage_year: null,
        home_buying_month: null,
        home_buying_price: null,
        home_buying_year: null,
        home_selling_month: null,
        home_selling_year: null,
        inflation_increase_rate_percent: null,
        monthly_basic_expenses: null,
        person_job_change_count: 0,
        real_estate_selling_month: null,
        real_estate_selling_year: null,
        ride_end_at_age: null,
        ride_period_year: null,
        ride_times: null,
        special_expense: null,
        spouse_job_change_count: 0,
        transfer_destination_count: 0,
        v2_housing_simulation_count: 0,
        prepayment_count: 0,
        housing_sale_count: 0,
        lend_housing_simulation_years: [],
        estate_investment_sale_years: [],
      },
      updated_at: '',
      created_at: '',
    },
  },
  summaryBudgets: {
    current_budgets_info: currentBudgetsInfo,
    budget_detail: budgetDetail,
  },
  summaryAssets: {
    current_year_asset_total: 0,
    year_of_age_65_asset: 0,
    money_dead_year: 0,
    money_dead_age: 0,
    net_asset_graph: [],
    financial_asset_graph: [],
    current_year_asset: {
      assets: [],
      total: 0,
    },
    has_loan: false,
    loan_info: loanInfo,
    loan_detail: [],
  },
  summaryHousings: {
    has_loan: false,
    loan_info: loanInfo,
    detail: [],
    repayment_chart: {},
  },
  summaryTuitions: {
    child_count: 0,
    graph: [],
    table: {
      header: [],
      data: [],
    },
    messages: [],
  },
  summaryAfterRetirements: {
    balance_graph: {},
    balance_chart_graph: [],
    income_chart_graph: [],
    spending_chart_graph: [],
    family_structure_table_data: {},
    family_structure_table_columns: [],
    asset_table_data: {},
    asset_table_columns: [],
    statement_table_data: {},
    statement_table_columns: [],
    messages: [],
  },
  summaryTransitions: {
    asset_graph: [],
    balance_graph: [],
    net_asset_graph: [],
  },
  isLoading: false,
  isLoadingTransition: false,
}

const summariesSlice = createSlice({
  name: 'summaries',
  initialState,
  reducers: {
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    resetSummariesStore: (state) => {
      state = initialState
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    requestTransitionStart: (state) => {
      state.isLoadingTransition = true
      return state
    },
    getSummaryBudgetsSuccess: (state, action: PayloadAction<SummaryBudgetResponse>) => {
      const { income, spending } = action.payload.budget_detail
      state.summaryBudgets = action.payload

      if (!_isUndefined(income)) {
        _set(
          state.summaryBudgets,
          'budget_detail.income.all_journals',
          buildSortAllJournals(income.all_journals),
        )
      }

      if (!_isUndefined(spending)) {
        _set(
          state.summaryBudgets,
          'budget_detail.spending.all_journals',
          buildSortAllJournals(spending.all_journals),
        )
      }

      state.isLoading = false
      return state
    },
    getSummaryBudgetDetailSuccess: (
      state,
      action: PayloadAction<SummaryBudgetResponse>,
    ) => {
      const { payload } = action
      const { income, spending } = payload.budget_detail
      state.summaryBudgets.budget_detail = payload.budget_detail
      state.summaryBudgets.current_budgets_info = payload.current_budgets_info

      if (!_isUndefined(income)) {
        _set(
          state.summaryBudgets,
          'budget_detail.income.all_journals',
          buildSortAllJournals(income.all_journals),
        )
      }

      if (!_isUndefined(spending)) {
        _set(
          state.summaryBudgets,
          'budget_detail.spending.all_journals',
          buildSortAllJournals(spending.all_journals),
        )
      }

      state.isLoading = false
      return state
    },
    getSummaryTuitionsSuccess: (state, action: PayloadAction<SummaryTuitionResponse>) => {
      state.summaryTuitions = action.payload
      state.isLoading = false
      return state
    },
    getSummaryTransitionsSuccess: (state, action: PayloadAction<SummaryTransition>) => {
      state.summaryTransitions = action.payload
      state.isLoadingTransition = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      state.isLoadingTransition = false
      return state
    },
  },
})

export const {
  requestStart,
  resetSummariesStore,
  requestSuccess,
  requestTransitionStart,
  getSummaryBudgetsSuccess,
  getSummaryBudgetDetailSuccess,
  getSummaryTuitionsSuccess,
  getSummaryTransitionsSuccess,
  requestFailure,
} = summariesSlice.actions
export const summariesReducer = summariesSlice.reducer

export const fetchSummaryBudgets = (): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  getSummaryBudgets()
    .then((response) => {
      dispatch(getSummaryBudgetsSuccess(response))
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const fetchSummaryBudgetDetail = (values: {
  params: SummaryBudgetDetailFetchValues
}): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  getSummaryBudgetDetail(values)
    .then((response) => {
      dispatch(getSummaryBudgetDetailSuccess(response))
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const fetchSummaryTuitions = (): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  getSummaryTuitions()
    .then((response) => {
      dispatch(getSummaryTuitionsSuccess(response))
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const fetchSummaryTransitions = (): AppThunk => async (dispatch) => {
  dispatch(requestTransitionStart())
  getSummaryTransitions()
    .then((response) => dispatch(getSummaryTransitionsSuccess(response)))
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const createSummaryTransitionSearch = (
  values: SummaryTransitionCreateValues,
): AppThunk => async (dispatch) => {
  dispatch(requestTransitionStart())
  postSummaryTransitionSearch(values)
    .then((response) => dispatch(getSummaryTransitionsSuccess(response)))
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const fetchSummaryBalancesSearchV2 = (
  values: SummaryBalanceCreateValues,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postSummaryBalancesSearchV2(values)
    .then((response) => {
      dispatch(getSummaryBudgetDetailSuccess(response))
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}
