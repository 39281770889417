import LocalStorage from '@/utils/localStorage'

const KEY = 'isFromSettingPage'

export const getStorageIsFromSettingPage = () => {
  return LocalStorage.getItem(KEY)
}

export const setStorageIsFromSettingPage = (value: boolean) => {
  return LocalStorage.setItem(KEY, value)
}

export const removeStorageIsFromSettingPage = () => {
  return LocalStorage.removeItem(KEY)
}
