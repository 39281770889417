import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, Route, Redirect } from 'react-router-dom'
import { replace as _replace } from 'lodash'
import { setVideoPlatformsLogindFunc } from '@/containers/videoPlatforms/authenticationsSlice'
import { RootState } from '@/store'
import { isMovieLogind } from '@/utils/movieAuth'

const MovieAuth: FC = (props) => {
  const locaiton = useLocation()
  const dispatch = useDispatch()

  const { children } = props
  const [redirectUrl, setRedirectUrl] = useState<string>('/movies/login')
  const [isInitialProsessFinished, setIsInitialProsessFinished] = useState<boolean>(false)
  const { isLogin } = useSelector(
    (state: RootState) => state.videoPlatformsAuthentications,
  )

  useEffect(() => {
    if (isLogin) return setIsInitialProsessFinished(true)

    if (isMovieLogind()) {
      dispatch(setVideoPlatformsLogindFunc())
    } else {
      const redirectUrl = _replace(locaiton.pathname, '/movies', '')
      setRedirectUrl(`/movies/login/password?redirectUrl=${redirectUrl}`)
    }

    setIsInitialProsessFinished(true)
  }, [])

  return (
    <>
      {isInitialProsessFinished ? (
        <>
          {/* @ts-ignore */}
          {isLogin ? <Route children={children} /> : <Redirect to={redirectUrl} />}
        </>
      ) : null}
    </>
  )
}

export default MovieAuth
