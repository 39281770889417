import React, { FC } from 'react'
import { isUndefined as _isUndefined } from 'lodash'
import Typography from '@/components/v2/atoms/Typography'
import NumberField from '@/components/v2/molecules/NumberField'

interface TextFieldBlockProps {
  /** 名前 */
  name: string
  /** クラス名 */
  className?: string
  /** テキストフィールドクラス名 */
  textFieldClassName?: string
  /** ラベル */
  label: string
  /** 説明 */
  description?: string
  /** 単位_右 */
  rightUnit?: string
  /** true: width100%, false: width100%無し */
  isFullWidth?: boolean
}

const BalanceFieldBlock: FC<TextFieldBlockProps> = (props) => {
  const { name, className, label, description, rightUnit } = props

  return (
    <div
      className={`${className} border-b border-black-300 border-dashed md:flex gap-x-10 items-center justify-between pb-16`}
    >
      <div className="pb-[8px] md:pb-0">
        <Typography className="text-14 text-black-800" isBold>
          {label}
        </Typography>
        {!_isUndefined(description) && (
          <Typography className="pt-8 text-10 text-black-700">{description}</Typography>
        )}
      </div>

      <div className="flex items-center">
        <NumberField name={name} widthClassName="w-full md:w-120" />
        {!_isUndefined(rightUnit) && (
          <Typography className="pl-8 text-14 text-black-900" isBold>
            {rightUnit}
          </Typography>
        )}
      </div>
    </div>
  )
}

export default BalanceFieldBlock
