import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  getCompanyRisk,
  postCompanyRisk,
  patchCompanyRisk,
} from '@/api/admins/companyRisks'
import { unauthorizedAdminCheck } from '@/containers/admins/fpAccountsSlice'
import { ApiError } from '@/models/commonsModel'
import { CompanyRisk, CompanyRiskCommonValues } from '@/models/admins/companyRisksModel'
import { AppThunk } from '@/store'

interface CompanyRiskState {
  companyRisk: CompanyRisk[]
  isLoading: boolean
}

const initialState: CompanyRiskState = {
  companyRisk: [],
  isLoading: false,
}

const companyRisksSlice = createSlice({
  name: 'companyRisk',
  initialState,
  reducers: {
    setCompanyRisk: (state, action: PayloadAction<CompanyRisk[]>) => {
      state.companyRisk = action.payload
      return state
    },
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    getCompanyRiskSuccess: (state, action: PayloadAction<CompanyRisk[]>) => {
      state.companyRisk = action.payload
      state.isLoading = false

      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
    resetCompanyRisks: () => {
      return initialState
    },
  },
})

export const {
  setCompanyRisk,
  requestStart,
  requestSuccess,
  getCompanyRiskSuccess,
  requestFailure,
  resetCompanyRisks,
} = companyRisksSlice.actions
export const companyRisksReducer = companyRisksSlice.reducer

export const fetchCompanyRisk = (companyId: number): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  getCompanyRisk(companyId)
    .then((response) => dispatch(getCompanyRiskSuccess(response)))
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedAdminCheck(error))
    })
}

export const createCompanyRisk = (
  companyId: number,
  values: CompanyRiskCommonValues,
  callback: () => void,
  resolve: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postCompanyRisk(companyId, values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedAdminCheck(error, resolve))
    })
}

export const updateCompanyRisk = (
  companyId: number,
  companyRiskId: number,
  values: CompanyRiskCommonValues,
  callback: () => void,
  resolve: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchCompanyRisk(companyId, companyRiskId, values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedAdminCheck(error, resolve))
    })
}
