import { isNull as _isNull } from 'lodash'
import { API_FLAG, castNumberWithoutOperator } from '@/models/commonsModelFunc'

export const convertTransferExceptionType = (type: number | null) => {
  if (_isNull(type)) return ''

  return Number(type) === API_FLAG.on ? 'する' : 'しない'
}

export const calcSaleCostManyen = (saleAmountManyen: number | null) => {
  const cost =
    ((castNumberWithoutOperator(saleAmountManyen) || 0) * 10000 * 0.03 + 60000) * 1.1 +
    100000

  return Math.round(cost / 10000)
}
