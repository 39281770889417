import ApiClient from '../apiClient'

export const postFutureMarriageV2 = async (values: any) => {
  return await ApiClient.post('/future_marriages', () => {}, values, undefined, 'v2')
}

export const patchFutureMarriageV2 = async (values: any, id: number) => {
  return await ApiClient.patch(
    `/future_marriages/${id}`,
    () => {},
    values,
    undefined,
    'v2',
  )
}

export const deleteFutureMarriageV2 = async (values: any, id: number) => {
  return await ApiClient.delete(
    `/future_marriages/${id}`,
    () => {},
    values,
    undefined,
    'v2',
  )
}
