import ApiClient from './apiClient'
import {
  SummaryBudgetResponse,
  SummaryBudgetDetailResponse,
  SummaryBudgetDetailFetchValues,
  SummaryTuitionResponse,
  SummaryTransition,
  SummaryTransitionCreateValues,
  SummaryBalanceCreateValues,
} from '@/models/summariesModel'

export const getSummaryBudgets = async () => {
  return await ApiClient.get(
    '/summary_budgets',
    (response: SummaryBudgetResponse) => response,
  )
}

export const getSummaryBudgetDetail = async (values: {
  params: SummaryBudgetDetailFetchValues
}) => {
  return await ApiClient.get(
    '/summary_budgets/show_budget_detail',
    (response: SummaryBudgetDetailResponse) => response,
    values,
  )
}

export const getSummaryTuitions = async () => {
  return await ApiClient.get(
    '/summary_tuitions',
    (response: SummaryTuitionResponse) => response,
  )
}

export const getSummaryTransitions = async () => {
  return await ApiClient.get(
    '/summary_transitions',
    (response: SummaryTransition) => response,
    undefined,
    false,
    'v2',
  )
}

export const postSummaryTransitionSearch = async (
  values: SummaryTransitionCreateValues,
) => {
  return await ApiClient.post(
    '/summary_transitions/search',
    (response: SummaryTransition) => response,
    values,
    false,
    'v2',
  )
}

export const postSummaryBalancesSearchV2 = async (values: SummaryBalanceCreateValues) => {
  return await ApiClient.post(
    '/summary_balances/search',
    (response: SummaryBudgetResponse) => response,
    values,
    false,
    'v2',
  )
}
