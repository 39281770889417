import React, { FC, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import clsx from 'clsx'
import {
  isString as _isString,
  replace as _replace,
  isNull as _isNull,
  isUndefined as _isUndefined,
  find as _find,
  isEmpty as _isEmpty,
} from 'lodash'
import ChevronRightIcon from '@/assets/images/v2/chevron-right.svg'
import CrossIcon from '@/assets/images/v2/cross.svg'
import HelpCircleIcon from '@/assets/images/v2/help-circle.svg'
import PlusCircleIcon from '@/assets/images/v2/plus-circle.svg'
import Button from '@/components/v2/atoms/Button'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import Grid from '@/components/v2/atoms/Grid'
import LoadingOverlay from '@/components/v1/atoms/LoadingOverlay'
import TextField from '@/components/v2/atoms/TextField'
import Tooltip from '@/components/v2/atoms/Tooltip'
import Typography from '@/components/v2/atoms/Typography'
import NumberField from '@/components/v2/molecules/NumberField'
import NumberFieldDecimal from '@/components/v2/molecules/NumberFieldDecimal'
import SelectBox from '@/components/v2/molecules/SelectBox'
import SubmitErrorMessage from '@/components/v2/molecules/SubmitErrorMessage'
import WholeSubmitErrorMessage from '@/components/v2/molecules/WholeSubmitErrorMessage'
import {
  ANNUAL_TYPE_SELECT_ITEMS,
  IS_TAX_CHARGABLE_SELECT_ITEMS,
} from '@/constants/formItem/commonAssets'
import {
  PERSON_FLG_SELECT_ITEMS,
  PERSON_WITHOUT_SPOUSE_SELECT_ITEM,
} from '@/constants/formItem/familyStructures'
import {
  INPUT_ASSET_TYPE_SELECT_ITEMS,
  ACCOUNT_TYPE_SELECT_ITEMS,
  STOCK_ACCOUNT_TYPE_SELECT_ITEMS,
  ROLLOVER_TYPE_SELECT_ITEMS,
} from '@/constants/formItem/inputs/inputAssets'
import { fetchFamilyStructure } from '@/containers/familyStructuresSlice'
import {
  fetchInputAssetSummary,
  createInputAsset,
  updateInputAsset,
} from '@/containers/inputs/inputAssetsSlice'
import { setPageMeta } from '@/containers/pageMetasSlice'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import { AssetAnnual } from '@/models/assetAnnualsModel'
import { ANNUAL_TYPE } from '@/models/commonAssetsModelFunc'
import {
  API_FLAG,
  convertDateYear,
  extractYearOrMonthFromDate,
  isNotEmptyValue,
  THIS_YEAR_NUMBER,
} from '@/models/commonsModelFunc'
import {
  ACCOUNT_TYPE,
  isNisaAccount,
  isInvestmentAssetType,
} from '@/models/inputs/inputAssetsModelFunc'
import { RootState } from '@/store'
import { buildTwoTypesRangeYearSelectItems } from '@/utils/formItem'
import {
  composeValidators,
  required,
  zeroOrMoreNumber,
  commaNumberMaxLength,
  lessThanOrEqualNumber,
} from '@/utils/validate'
import { updateResetFlg } from '@/containers/futures/futureBasesSlice'
import { AssetType } from '@/models/inputs/inputAssetsModel'
import Modal from '@/components/v2/atoms/Modal'

interface AssetFormProps {
  id?: number
  onClose: () => void
  onSubmit: () => void
}

const AssetForm: FC<AssetFormProps> = (props) => {
  const dispatch = useDispatch()
  const { showFlashMsg } = useFlashAlert()

  const { inputAssets, isLoading } = useSelector((state: RootState) => state.inputAssets)
  const { familyStructures } = useSelector((state: RootState) => state.familyStructures)
  const isLoadingFamilyStructures = useSelector(
    (state: RootState) => state.familyStructures.isLoading,
  )

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)

  const { id } = props

  const targetInputAsset = useMemo(() => {
    return _find(inputAssets, { id })
  }, [inputAssets, id])

  const isEdit = useMemo(() => !!id && !!targetInputAsset, [targetInputAsset])

  const buildInitialValues = useMemo(() => {
    return isEdit
      ? {
          annual_type: !_isEmpty(targetInputAsset?.asset_annuals)
            ? ANNUAL_TYPE.yes
            : ANNUAL_TYPE.no,
          investment_start_at_year: extractYearOrMonthFromDate(
            targetInputAsset?.investment_start_at,
            'year',
          ),
          investment_end_at_year: extractYearOrMonthFromDate(
            targetInputAsset?.investment_end_at,
            'year',
          ),
          input_asset: {
            ...targetInputAsset,
            person_flg: _isNull(targetInputAsset?.person_flg)
              ? `child${targetInputAsset?.family_structure_child?.id}`
              : targetInputAsset?.person_flg,
            asset_annuals_attributes:
              !_isUndefined(targetInputAsset?.asset_annuals) &&
              !_isEmpty(targetInputAsset?.asset_annuals)
                ? [
                    ...targetInputAsset!.asset_annuals.map((annual: AssetAnnual) => ({
                      ...annual,
                      affected_at_year: extractYearOrMonthFromDate(
                        annual.affected_at,
                        'year',
                      ),
                    })),
                  ]
                : [{}],
          },
        }
      : {
          annual_type: ANNUAL_TYPE.no,
          input_asset: {
            asset_annuals_attributes: [{}],
          },
        }
  }, [targetInputAsset, isEdit])

  const personFlgSelectItems = useMemo(() => {
    const hasSpouse = familyStructures?.spouse_type === API_FLAG.on
    const targetSelectItems = hasSpouse
      ? PERSON_FLG_SELECT_ITEMS
      : PERSON_WITHOUT_SPOUSE_SELECT_ITEM

    const childSelectItems = familyStructures?.family_structure_children?.map((item) => ({
      value: `child${item.id}`,
      label: `第${item.child_index}子`,
    }))

    return !_isUndefined(childSelectItems)
      ? [...targetSelectItems, ...childSelectItems]
      : [...targetSelectItems]
  }, [familyStructures])

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'マイデータ | 金融資産編集',
        description: 'マイデータ | 金融資産編集',
        keyword: 'マイデータ | 金融資産編集',
      }),
    )
    if (inputAssets.length == 0) dispatch(fetchInputAssetSummary())
    dispatch(fetchFamilyStructure())
  }, [])

  const submitInputAssetFunc = (
    inputAsset: any,
    annualType: string,
    investmentStartAtYear?: string,
    investmentEndAtYear?: string,
  ) => {
    const { person_flg } = inputAsset

    // 子供を選択した場合
    if (_isString(person_flg) && person_flg.indexOf('child') >= 0) {
      inputAsset.person_flg = null
      inputAsset.family_structure_child_id = Number(_replace(person_flg, 'child', ''))
    } else {
      inputAsset.family_structure_child_id = null
    }

    // 想定利回りの変化「ない」を選択した場合
    if (annualType == ANNUAL_TYPE.no) {
      inputAsset.asset_annuals_attributes = []
    }
    // 想定利回りの変化「ある」を選択した場合
    if (annualType == ANNUAL_TYPE.yes) {
      inputAsset.asset_annuals_attributes = inputAsset.asset_annuals_attributes.map(
        (item: AssetAnnual) => ({
          ...item,
          affected_at: convertDateYear(item.affected_at_year),
        }),
      )
    }

    // 口座種類「成長投資枠」あるいは「つみたてNISA枠」を選択した場合
    if (
      isInvestmentAssetType(inputAsset.asset_type) &&
      isNisaAccount(inputAsset.account_type)
    ) {
      inputAsset.investment_start_at = convertDateYear(investmentStartAtYear)
      inputAsset.investment_end_at = convertDateYear(investmentEndAtYear)
      inputAsset.is_tax_chargable = null
    }

    // 口座種類「特定口座・一般口座」を選択した場合
    if (inputAsset.account_type == ACCOUNT_TYPE.general) {
      inputAsset.investment_start_at = null
      inputAsset.investment_end_at = null
    }

    // 金融資産の種別「投資信託」「株式」以外の場合
    if (!isInvestmentAssetType(inputAsset.asset_type)) {
      inputAsset.account_type = null
      inputAsset.investment_start_at = null
      inputAsset.investment_end_at = null
    }

    // 口座種類「成長投資枠」以外を選択した場合
    if (inputAsset.account_type != ACCOUNT_TYPE.nisa) {
      inputAsset.rollover_type = null
    }

    const newValues = { input_asset: inputAsset }
    const isEdit = !_isUndefined(inputAsset.id)

    new Promise((resolve) =>
      isEdit
        ? dispatch(
            updateInputAsset(
              inputAsset.id,
              newValues,
              () => {
                showFlashMsg(isEdit ? '更新しました' : '作成しました')
                dispatch(fetchInputAssetSummary())
                dispatch(updateResetFlg())
              },
              resolve,
            ),
          )
        : dispatch(
            createInputAsset(
              newValues,
              () => {
                showFlashMsg(isEdit ? '更新しました' : '作成しました')
                dispatch(fetchInputAssetSummary())
                dispatch(updateResetFlg())
              },
              resolve,
            ),
          ),
    )

    if (
      isInvestmentAssetType(inputAsset.asset_type) &&
      isNisaAccount(inputAsset.account_type)
    ) {
      return setIsOpenModal(true)
    } else {
      return props.onSubmit()
    }
  }

  const handleSubmit = (values: any) => {
    submitInputAssetFunc(
      values.input_asset,
      values.annual_type,
      values.investment_start_at_year,
      values.investment_end_at_year,
    )
  }

  const defaultYearSelectItems = () => {
    return buildTwoTypesRangeYearSelectItems(
      THIS_YEAR_NUMBER - 30,
      THIS_YEAR_NUMBER + 30,
      true,
    )
  }

  const renderAffectedAtYearOptions = (
    index: number,
    investment5tartAtYear: number,
    tmpAffectedAtYear: number,
  ) => {
    let selectItems = []

    if (index == 0 && !isNotEmptyValue(investment5tartAtYear)) {
      selectItems.push(...defaultYearSelectItems())
      return selectItems
    }

    index == 0
      ? selectItems.push(
          ...buildTwoTypesRangeYearSelectItems(
            Number(investment5tartAtYear),
            Number(investment5tartAtYear) + 50,
          ),
        )
      : selectItems.push(
          ...buildTwoTypesRangeYearSelectItems(
            Number(tmpAffectedAtYear) + 1,
            Number(tmpAffectedAtYear) + 50,
          ),
        )
    return selectItems
  }

  // 年間下落率の期間の追加
  const handleAddAssetAnnualBlock = (
    assetAnnual: Partial<AssetAnnual>[],
    index: number,
    change: (key: string, value: Partial<any>[]) => void,
  ) => {
    const isEnd = index + 1 == assetAnnual.length
    isEnd ? assetAnnual.push({}) : assetAnnual.splice(index + 1, 0, {})

    change('input_asset.asset_annuals_attributes', assetAnnual)
  }

  return (
    <>
      <LoadingOverlay isLoading={isLoading || isLoadingFamilyStructures} />
      <Form
        onSubmit={handleSubmit}
        initialValues={buildInitialValues}
        mutators={{ ...arrayMutators }}
        render={({
          values,
          handleSubmit,
          errors,
          submitFailed,
          submitErrors,
          form: { change },
        }) => {
          const {
            annual_type,
            input_asset: { asset_type, account_type },
          } = values
          const isConnectedWithMT = values?.input_asset?.['is_connected_with_moneytree?']

          return (
            <form onSubmit={handleSubmit}>
              <Grid className="pb-[64px] grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px]">
                <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                  <Typography
                    className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                    isBold
                  >
                    金融資産の種別
                  </Typography>
                  <SelectBox
                    name={`input_asset.asset_type`}
                    placeholder="選択する"
                    options={INPUT_ASSET_TYPE_SELECT_ITEMS}
                    isFullWidth
                    validate={required}
                    onChange={(e) => {
                      if (!isInvestmentAssetType(e.target.value as AssetType)) {
                        change('input_asset.account_type', null)
                        change('input_asset.reserved_amount_monthly', 0)
                      }
                    }}
                  />
                </div>

                <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                  <Typography
                    className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                    isBold
                  >
                    金融資産の名称
                  </Typography>
                  <TextField
                    type="text"
                    name={
                      isConnectedWithMT ? `input_asset.current_name` : `input_asset.name`
                    }
                    isFullWidth
                    disabled={isConnectedWithMT}
                    validate={required}
                  />
                </div>

                {isInvestmentAssetType(asset_type) && (
                  <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                    <Typography
                      className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                      isBold
                    >
                      口座種類
                    </Typography>
                    <SelectBox
                      name="input_asset.account_type"
                      placeholder="選択する"
                      options={asset_type === "stock" ? STOCK_ACCOUNT_TYPE_SELECT_ITEMS : ACCOUNT_TYPE_SELECT_ITEMS}
                      isFullWidth
                      validate={required}
                    />
                  </div>
                )}

                <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                  <Typography
                    className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                    isBold
                  >
                    想定利回り
                  </Typography>
                  <div className="flex flex-auto items-baseline justify-end">
                    <NumberFieldDecimal
                      className="w-full"
                      name="input_asset.interest_rate_yearly_percent"
                      validate={composeValidators(
                        required,
                        zeroOrMoreNumber,
                        (value: any) => commaNumberMaxLength(value, 6),
                      )}
                    />
                    <Typography className="pl-[8px] text-[14px] text-black-800">
                      ％
                    </Typography>
                  </div>
                </div>

                <div className="pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                  <div className="md:flex pb-[16px] items-baseline">
                    <Typography
                      className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                      isBold
                    >
                      想定利回りの変化
                    </Typography>
                    <SelectBox
                      name="annual_type"
                      placeholder="選択する"
                      options={ANNUAL_TYPE_SELECT_ITEMS}
                      isFullWidth
                      validate={required}
                    />
                  </div>
                  {annual_type == ANNUAL_TYPE.yes && (
                    <FieldArray name="input_asset.asset_annuals_attributes">
                      {({ fields }) => (
                        <>
                          {fields.map((name, index) => (
                            <div
                              key={name}
                              className="flex items-baseline justify-end flex-auto mb-[10px]"
                            >
                              <SelectBox
                                className="mr-[5px] md:mr-[10px] max-w-[155px]"
                                name={`${name}affected_at_year`}
                                placeholder="年を選択"
                                options={renderAffectedAtYearOptions(
                                  index,
                                  values?.investment_start_at_year,
                                  fields.value[index - 1]?.affected_at_year,
                                )}
                                validate={required}
                                isFullWidth
                              />
                              <Typography className="min-w-[fit-content] px-[5px] md:px-[10px] text-[14px] text-black-800">
                                から
                              </Typography>

                              <NumberFieldDecimal
                                className="max-w-[75px] md:max-w-[100px]"
                                name={`${name}annual_percent`}
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 6),
                                )}
                              />
                              <Typography className="px-[5px] md:px-[10px] text-[14px] text-black-800">
                                ％
                              </Typography>
                              {index > 0 ? (
                                <Button>
                                  <CrossIcon onClick={() => fields.remove(index)} />
                                </Button>
                              ) : (
                                <div className="min-w-[16px]" />
                              )}
                            </div>
                          ))}
                          <Button
                            className="ml-auto border-[0.5px] border-black-700 rounded-[4px]"
                            onClick={() => {
                              const targetValue = fields.value ? [...fields.value] : []

                              handleAddAssetAnnualBlock(
                                targetValue,
                                targetValue.length + 1,
                                change,
                              )
                            }}
                          >
                            <div className="flex items-center">
                              <PlusCircleIcon className="ml-[10px] mt-[2px]" />
                              <Typography
                                className="min-w-[120p] p-[10px] text-[14px] text-black-800 flex items-center"
                                isBold
                              >
                                入力枠を追加する
                              </Typography>
                            </div>
                          </Button>
                        </>
                      )}
                    </FieldArray>
                  )}
                </div>

                <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                  <Typography
                    className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                    isBold
                  >
                    名義人
                  </Typography>
                  <SelectBox
                    name={`input_asset.person_flg`}
                    placeholder="選択する"
                    options={personFlgSelectItems}
                    isFullWidth
                    validate={required}
                  />
                </div>

                <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                  <Typography
                    className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                    isBold
                  >
                    残高
                  </Typography>
                  <div className="flex flex-auto items-baseline">
                    <NumberField
                      name={
                        isConnectedWithMT
                          ? `input_asset.current_value`
                          : `input_asset.value`
                      }
                      isFullWidth
                      disabled={isConnectedWithMT}
                      validate={composeValidators(
                        required,
                        zeroOrMoreNumber,
                        (value: any) => commaNumberMaxLength(value, 10),
                      )}
                    />
                    <Typography className="pl-[8px] text-[14px] text-black-800" isBold>
                      円
                    </Typography>
                  </div>
                </div>

                <div className="pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                  <div className="md:flex pb-[16px] items-baseline">
                    <div className="items-center mr-[10px] min-w-[180px] sm:min-w-[120px] lg:min-w-[230px] pb-[10px]">
                      <Typography
                        className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                        isBold
                      >
                        積立額
                      </Typography>
                      <Typography className="max-w-[140px] lg:max-w-[230px] mt-[10px] text-[14px] text-black-600">
                        {isInvestmentAssetType(asset_type)
                          ? 'NISAなど定期積立している場合は、積立額を入力してください'
                          : '積立額は投資信託・株式をご選択の場合に入力できます'}
                      </Typography>
                      {isInvestmentAssetType(asset_type) && values.input_asset.account_type == ACCOUNT_TYPE.tsumitateNisa && (
                        <Typography className="max-w-[140px] lg:max-w-[230px] mt-[10px] text-[14px] text-black-600">
                          つみたて投資枠の上限は年間で120万円までです。月額10万円以下で設定してください
                        </Typography>
                      )}
                      {isInvestmentAssetType(asset_type) && values.input_asset.account_type == ACCOUNT_TYPE.nisa && (
                        <Typography className="max-w-[140px] lg:max-w-[230px] mt-[10px] text-[14px] text-black-600">
                          成長投資枠の上限は年間で240万円までです。月額20万円以下で設定してください
                        </Typography>
                      )}
                    </div>
                    <div className="flex flex-auto items-baseline justify-end">
                      <NumberField
                        className="w-full"
                        name="input_asset.reserved_amount_monthly"
                        validate={composeValidators(
                          zeroOrMoreNumber,
                          (value: any) => commaNumberMaxLength(value, 9),
                          (value: any) => {
                            if (isInvestmentAssetType(asset_type) && values.input_asset.account_type == ACCOUNT_TYPE.tsumitateNisa) {
                              return lessThanOrEqualNumber(value, 100000)
                            }
                          },
                          (value: any) => {
                            if (isInvestmentAssetType(asset_type) && values.input_asset.account_type == ACCOUNT_TYPE.nisa) {
                              return lessThanOrEqualNumber(value, 200000)
                            }
                          },
                        )}
                        disabled={!isInvestmentAssetType(asset_type)}
                      />
                      <Typography className="min-w-[42px] pl-[8px] text-[14px] text-black-800">
                        円/月
                      </Typography>
                    </div>
                  </div>
                </div>

                {isInvestmentAssetType(asset_type) && isNisaAccount(account_type) && (
                  <>
                    <div className="md:flex md:justify-between pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                      <div className="items-center mr-[10px] min-w-[180px] lg:min-w-[230px] pb-[10px]">
                        <Typography
                          className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                          isBold
                        >
                          利用開始年
                        </Typography>
                        <Typography className="max-w-[140px] lg:max-w-[200px] mt-[10px] text-[14px] text-black-600">
                          税金計算を正しくするために、つみたてNISA枠と成長投資枠をご利用の場合には、利用開始年を設定してください
                        </Typography>
                      </div>
                      <div className="flex justify-between">
                        <SelectBox
                          name="investment_start_at_year"
                          className="mr-10"
                          placeholder="年を選択"
                          options={buildTwoTypesRangeYearSelectItems(
                            THIS_YEAR_NUMBER - 50,
                            THIS_YEAR_NUMBER,
                            true,
                          )}
                          isFullWidth
                          validate={required}
                        />
                      </div>
                    </div>
                  </>
                )}

                {(!isInvestmentAssetType(asset_type) ||
                  (isInvestmentAssetType(asset_type) &&
                    isNotEmptyValue(account_type) &&
                    !isNisaAccount(account_type))) && (
                  <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                    <Typography
                      className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                      isBold
                    >
                      課税対象の有無
                    </Typography>
                    <SelectBox
                      name={`input_asset.is_tax_chargable`}
                      placeholder="選択する"
                      options={IS_TAX_CHARGABLE_SELECT_ITEMS}
                      isFullWidth
                      validate={required}
                    />
                  </div>
                )}
              </Grid>

              <WholeSubmitErrorMessage submitErrors={submitErrors} />
              <SubmitErrorMessage show={!submitErrors && submitFailed} />
              <ButtonPrimary
                className={clsx(
                  { ['opacity-50']: Object.keys(errors).length > 0 },
                  'w-[246px] h-[64px] mx-auto mb-[16px] ',
                )}
                isDisabled={isLoading}
                onClick={() => handleSubmit(values)}
              >
                <Typography
                  className="relative bottom-[2px] pl-[30px] text-[16px] text-white flex-auto"
                  isBold
                >
                  保存する
                </Typography>
                <ChevronRightIcon className="mr-[17px]" />
              </ButtonPrimary>
              <Button
                className="mx-auto text-[14px] text-black-700"
                onClick={props.onClose}
                isDisabled={isLoading}
              >
                キャンセル
              </Button>
            </form>
          )
        }}
      />
      <Modal
        className="w-[95%] md:w-[760px]"
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(false)}
      >
        <Typography className="text-12" isBold>
          未来予想に設定が反映されました。
        </Typography>
        <div className="bg-secondary-50 p-10">
          <Typography className="text-secondary-500 text-12 pb-5" isBold>
            以下、ご確認の際にご注意ください。
          </Typography>

          <Typography className="text-12">
            <span className="text-secondary-500">・</span>
            1 つみたて投資枠、成長投資枠は法令上の上限に達した時点で自動的に積立が解除されるシミュレーションとなっています。
          </Typography>
          <Typography className="text-12">
            <span className="text-secondary-500">・</span>
            2 預金残高が設定された積立額よりも少ない年は、自動的に預金残高が積立額となります。
          </Typography>
        </div>
        <div className="flex flex-col items-center space-y-24">
          <ButtonPrimary
            className="h-45 w-full md:w-215 text-white"
            onClick={() => props.onSubmit()}
          >
            <Typography className="bottom-2 flex-auto pl-30 relative text-16" isBold>
              了解
            </Typography>
            <i className="ico chevron-right mr-17 text-24" />
          </ButtonPrimary>
        </div>
      </Modal>
    </>
  )
}

export default AssetForm
