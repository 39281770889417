import React, { FC, useState, useContext, useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  find as _find,
  filter as _filter,
  sortBy as _sortBy,
  includes as _includes,
  isEmpty as _isEmpty,
} from 'lodash'
import Typography from '@/components/v2/atoms/Typography'
import { THIS_YEAR_NUMBER, convertDateToJapaneseFormat } from '@/models/commonsModelFunc'
import { HOUSE_TYPE } from '@/models/commonHousingModelFunc'
import {
  getFutureV2HousingType,
  PREV_HOUSING_TYPE,
} from '@/models/futures/futureV2HousingsModelFunc'
import { SimulationDetailEditContext } from '@/models/futures/futureBasesModelFunc'
import {
  FutureV2Housing,
  NextHouseType,
  PrevHousingType,
} from '@/models/futures/futureV2HousingsModel'
import { FutureLendHousing } from '@/models/futures/futureLendHousingsModel'
import { FutureHousingSale } from '@/models/futures/futureHousingSalesModel'
import {
  isRentHouseType,
  isSelfOwnedHouseType,
} from '@/models/inputs/inputHousingsModelFunc'
import { InputHousing } from '@/models/inputs/inputHousingsModel'
import { RootState } from '@/store'
import FutureV2HousingCreateModalForm from '@/templates/v2/simulations/futureV2Housings/FutureV2HousingModalForm/createIndex'
import FutureHousingSaleBody from '@/templates/v2/simulations/futureV2Housings/FutureHousingSaleBody'
import FutureLendHousingForm from '@/templates/v2/simulations/futureV2Housings/FutureLendHousingForm'
import FutureV2HousingSaleOrLendCreateModalForm from '@/templates/v2/simulations/futureV2Housings/FutureV2HousingSaleOrLendCreateModalForm/createIndex'
import HousingSectionItem from '@/templates/v2/simulations/futureV2Housings/FutureV2HousingMainBodySection'
import InputHousingRentBody from '@/templates/v2/simulations/futureV2Housings/InputHousingRentBody'
import InputHousingSelfOwndBody from '@/templates/v2/simulations/futureV2Housings/InputHousingSelfOwnedBody'
import SimulationAccordion from '@/templates/v2/simulations/_simulationAccordion'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import SimulationNewButton from '@/templates/v2/simulations/_simulationNewButton'
import { commonFutureBaseNewApis } from '@/containers/futures/futureBasesSlice'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import clsx from 'clsx'
import Tooltip from '@/components/v2/atoms/Tooltip'
import SimulationItemAccordion from '@/templates/v2/simulations/_simulationItemAccordion'

const DeselectControlTooltipWrapper: FC<any> = (props) => {
  const { isSelectedWithSale, getLendHousingRelatedIds, housing, children } = props

  return (
    <>
      {isSelectedWithSale(getLendHousingRelatedIds(housing)?.future_lend_housing_ids) ? (
        <Tooltip
          className="w-full"
          itemClassName="w-[330px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
          item={
            <>
              <Typography>
                賃貸として貸し出し予定の物件の売却プランが
                シミュレーションにセットされているため選択解除できません
              </Typography>
              <Typography>
                先に不動産投資売却の該当プランを選択解除してください
              </Typography>
            </>
          }
        >
          {children}
        </Tooltip>
      ) : (
        <>{children}</>
      )}
    </>
  )
}

const FutureV2HousingMainBody: FC<any> = (props) => {
  const { isManipulatedApproval } = useContext(SimulationDetailEditContext)
  const dispatch = useDispatch()

  const {
    selectedSimulationId,
    selectedSimulationSaleId,
    handleSelect,
    handleOtherSelect,
    shouldInitialize,
    confirmEdit,
    openEditConfirmModal,
  } = props
  const commonCallApi = () => {
    dispatch(commonFutureBaseNewApis(props.futureBaseId))
  }

  const { showFlashMsg } = useFlashAlert()
  const [isMainCreateModalOpen, setIsMainCreateModalOpen] = useState<boolean>(false)
  const [isAnotherCreateModalOpen, setIsAnotherCreateModalOpen] = useState<boolean>(false)
  const [isFutureV2HousingModalOpen, setIsFutureV2HousingModalOpen] = useState<boolean>(
    false,
  )

  const [
    futureV2HousingForLendModalStatus,
    setFutureV2HousingForLendModalStatus,
  ] = useState<{ targetId: number | undefined; isModalOpen: boolean } | undefined>(
    undefined,
  )
  const [
    futureV2HousingForSaleModalStatus,
    setFutureV2HousingForSaleModalStatus,
  ] = useState<{ targetId: number | undefined; isModalOpen: boolean } | undefined>(
    undefined,
  )
  const [modalTypeInProgress, setModalTypeInProgress] = useState<'main' | 'another' | ''>(
    '',
  )
  const [prevMainHousing, setPrevMainHousing] = useState<
    FutureV2Housing | InputHousing | undefined
  >(undefined)
  const [prevAnotherHousing, setPrevAnotherHousing] = useState<
    FutureV2Housing | undefined
  >(undefined)
  const [nextHouseType, setNextHouseType] = useState<NextHouseType>()
  const [nextScheduledAtAge, setNextScheduledAtAge] = useState<
    number | null | undefined
  >()
  const [targetHousingSaleId, setTargetHousingSaleId] = useState<number | undefined>(
    undefined,
  )
  const [targetHousingLendId, setTargetHousingLendId] = useState<number | undefined>(
    undefined,
  )

  const [isAnotherHouse, setIsAnotherHouse] = useState<boolean>(false)

  const { futureItems } = useSelector((state: RootState) => state.accounts)

  const inputHousings = useSelector(
    (state: RootState) => state.accounts.futureItems?.input_housings,
  )

  const futureHousingSales = useSelector(
    (state: RootState) => state.accounts.futureItems?.future_housing_sales,
  )

  const futureLendHousings = useSelector(
    (state: RootState) => state.accounts.futureItems?.future_lend_housings,
  )

  let { futureV2Housings, futureV2AnotherHousings } = useSelector(
    (state: RootState) => state.futureV2Housings,
  )

  futureV2Housings = _sortBy(futureV2Housings, 'scheduled_at_age')

  const targetInputHousing = _find(
    inputHousings,
    (item) => item.house_type !== HOUSE_TYPE.transfer,
  )

  const firstFutureV2MainHousings = _filter(futureV2Housings, (item) => {
    const targetHousingSale = _find(futureHousingSales, { future_v2_housing_id: item.id })
    const targetLendHousing = _find(futureLendHousings, { future_v2_housing_id: item.id })

    return item.prev_housing_type === 'InputHousing'
      ? !targetHousingSale && !targetLendHousing
      : item.prev_housing_type === 'SimpleHousing'
  })

  const firstFutureHousingSales = useMemo(
    () => _filter(futureHousingSales, (item) => item.housing_type === 'InputHousing'),
    [futureHousingSales],
  )

  const firstFutureLendHousings = _filter(
    futureLendHousings,
    (item) => item.housing_type === 'InputHousing',
  )

  const prevMainHousingType = useMemo(
    () => getFutureV2HousingType(prevMainHousing?.house_type),
    [prevMainHousing],
  )

  const completedSaleOrLendCallback = (
    isMain: boolean,
    type: 'sale' | 'lend' | 'another' | undefined,
    nextHouseValues: {
      nextHouseType: NextHouseType
      nextScheduledAtAge: number
    },
    id?: number,
  ) => {
    setNextHouseType(nextHouseValues.nextHouseType)
    setNextScheduledAtAge(nextHouseValues.nextScheduledAtAge)

    if (type === 'sale') {
      setTargetHousingSaleId(id!)
    }
    if (type === 'lend') {
      setTargetHousingLendId(id!)
    }
    if (type === 'sale' || type === 'lend') {
      commonCallApi()
      showFlashMsg('登録しました')
    }
    if (type == 'another') {
      setIsAnotherHouse(true)
    }

    isMain ? setIsMainCreateModalOpen(false) : setIsAnotherCreateModalOpen(false)
    setIsFutureV2HousingModalOpen(true)
  }

  const completedNoPrevCallback = (
    isMain: boolean,
    type: undefined,
    nextHouseValues: {
      nextHouseType: NextHouseType
      nextScheduledAtAge: number
    },
  ) => {
    setNextHouseType(nextHouseValues.nextHouseType)
    setNextScheduledAtAge(nextHouseValues.nextScheduledAtAge)

    commonCallApi()

    setIsAnotherHouse(false)

    isMain ? setIsMainCreateModalOpen(false) : setIsAnotherCreateModalOpen(false)
    setIsFutureV2HousingModalOpen(true)
  }

  const continueForPurchaseCallback = (nextHouseValues: {
    nextHouseType: NextHouseType
    nextScheduledAtAge: number
    prevHousing: FutureV2Housing
  }) => {
    setNextHouseType(nextHouseValues.nextHouseType)
    setNextScheduledAtAge(nextHouseValues.nextScheduledAtAge)
    setPrevMainHousing(nextHouseValues.prevHousing)
    setTargetHousingSaleId(undefined)
    setTargetHousingLendId(undefined)
    setIsFutureV2HousingModalOpen(false)
    showFlashMsg('登録しました')
    commonCallApi()
    setIsFutureV2HousingModalOpen(true)
  }

  const completedFutureV2HousingCallback = () => {
    commonCallApi()
    showFlashMsg('登録しました')
    handleFutureV2HousingModalClose()
    setModalTypeInProgress('')
  }

  const handleOpenMainHousingModal = () => {
    setIsMainCreateModalOpen(true)
    setModalTypeInProgress('main')
  }
  const handleOpenAnotherHousingModal = () => {
    setIsAnotherCreateModalOpen(true)
    setModalTypeInProgress('another')
  }

  const handleModalClose = () => {
    setIsMainCreateModalOpen(false)
    setIsAnotherCreateModalOpen(false)
    setPrevMainHousing(undefined)
    setPrevAnotherHousing(undefined)
  }

  const handleFutureV2HousingModalClose = () => {
    setIsFutureV2HousingModalOpen(false)
    setModalTypeInProgress('')
    setNextHouseType(undefined)
    setNextScheduledAtAge(undefined)
    setTargetHousingSaleId(undefined)
    setTargetHousingLendId(undefined)
    setPrevMainHousing(undefined)
    setPrevAnotherHousing(undefined)
  }

  const getHousingSaleRelatedIds = (futureHousingSale: any) => {
    let ids: any

    if (!!futureHousingSale.future_v2_housing_id) {
      ids = { ...getHousingRelatedIds(futureHousingSale.future_v2_housing_id) }
    } else {
      ids = { future_housing_sale_ids: [futureHousingSale.id] }
    }

    return ids
  }

  const getLendHousingRelatedIds = (futureLendHousing: any) => {
    let ids: any
    if (!!futureLendHousing.future_v2_housing_id) {
      ids = { ...getHousingRelatedIds(futureLendHousing.future_v2_housing_id) }
    } else {
      ids = { future_lend_housing_ids: [futureLendHousing.id] }
    }
    return ids
  }

  const getV2HousingRelatedIds = (futureV2Housing: any) => {
    let ids: any = { ...getHousingRelatedIds(futureV2Housing.id) }
    return ids
  }

  const getHousingRelatedIds = (firstFutureV2HousingId: number) => {
    let future_v2_housing_ids: number[] = []
    let future_housing_sale_ids: number[] = []
    let future_lend_housing_ids: number[] = []

    const getV2HousingIds = (acc: number[], future_v2_housing_id: number): number[] => {
      const futureV2Housing = _find(futureV2Housings, {
        prev_housing_id: future_v2_housing_id,
        prev_housing_type: 'FutureV2Housing',
      })
      if (futureV2Housing) {
        acc.push(futureV2Housing.id)
        return getV2HousingIds(acc, futureV2Housing.id)
      }
      return acc
    }
    future_v2_housing_ids = getV2HousingIds(
      [firstFutureV2HousingId],
      firstFutureV2HousingId,
    )

    const getHousingSaleIds = (future_v2_housing_ids: number[]): number[] => {
      return future_v2_housing_ids.reduce((acc: number[], id: number) => {
        futureHousingSales?.forEach((futureHousingSale: any) => {
          if (
            futureHousingSale.housing_id == id &&
            futureHousingSale.housing_type == 'FutureV2Housing'
          ) {
            acc.push(futureHousingSale.id)
          }
          if (futureHousingSale.future_v2_housing_id == id) {
            acc.push(futureHousingSale.id)
          }
        })
        return acc
      }, [])
    }
    future_housing_sale_ids = getHousingSaleIds(future_v2_housing_ids)

    const getLendHousingIds = (future_v2_housing_ids: number[]): number[] => {
      return future_v2_housing_ids.reduce((acc: number[], id: number) => {
        futureLendHousings?.forEach((futureLendHousing: any) => {
          if (
            futureLendHousing.housing_id == id &&
            futureLendHousing.housing_type == 'FutureV2Housing'
          ) {
            acc.push(futureLendHousing.id)
          }
          if (futureLendHousing.future_v2_housing_id == id) {
            acc.push(futureLendHousing.id)
          }
        })
        return acc
      }, [])
    }
    future_lend_housing_ids = getLendHousingIds(future_v2_housing_ids)

    return {
      future_v2_housing_ids,
      future_housing_sale_ids,
      future_lend_housing_ids,
    }
  }

  const isSelectedWithSale = (lendHousingIds: number[]): boolean => {
    const selectedEstateInvestmentSales = _filter(
      futureItems?.future_estate_investment_sales,
      (item) => _includes(selectedSimulationSaleId, item.id),
    )
    if (_isEmpty(selectedEstateInvestmentSales)) return false

    return !!_find(selectedEstateInvestmentSales, (item) =>
      _includes(lendHousingIds, item.estate_investment_id),
    )
  }

  useEffect(() => {
    if (props.futureBaseId == null || !shouldInitialize) return

    // select FutureV2Housing
    const firstFutureV2MainHousing = _find<FutureV2Housing>(firstFutureV2MainHousings, {
      future_base_id: props.futureBaseId,
    })
    if (firstFutureV2MainHousing != null) {
      const housingSaleRelatedIds = getV2HousingRelatedIds(firstFutureV2MainHousing)
      const LendHousingIds = housingSaleRelatedIds.future_lend_housing_ids
      if (!isSelectedWithSale(LendHousingIds)) {
        handleSelect(
          'FutureV2Housing',
          firstFutureV2MainHousing.id,
          housingSaleRelatedIds.future_v2_housing_ids,
          housingSaleRelatedIds.future_lend_housing_ids,
          housingSaleRelatedIds.future_housing_sale_ids,
        )
      }
    }

    // select FutureHousingSale
    const firstFutureHousingSale = _find<FutureHousingSale>(firstFutureHousingSales, {
      future_base_id: props.futureBaseId,
    })
    if (firstFutureHousingSale != null) {
      const housingSaleRelatedIds = getHousingSaleRelatedIds(firstFutureHousingSale)
      const LendHousingIds = housingSaleRelatedIds.future_lend_housing_ids
      if (!isSelectedWithSale(LendHousingIds)) {
        handleSelect(
          'FutureHousingSale',
          firstFutureHousingSale.id,
          housingSaleRelatedIds.future_v2_housing_ids,
          housingSaleRelatedIds.future_lend_housing_ids,
          housingSaleRelatedIds.future_housing_sale_ids,
        )
      }
    }

    // select FutureLendHousing
    const firstFutureLendHousing = _find<FutureLendHousing>(firstFutureLendHousings, {
      future_base_id: props.futureBaseId,
    })
    if (firstFutureLendHousing != null) {
      const housingSaleRelatedIds = getLendHousingRelatedIds(firstFutureLendHousing)
      const LendHousingIds = housingSaleRelatedIds.future_lend_housing_ids
      if (!isSelectedWithSale(LendHousingIds)) {
        handleSelect(
          'FutureLendHousing',
          firstFutureLendHousing.id,
          housingSaleRelatedIds.future_v2_housing_ids,
          housingSaleRelatedIds.future_lend_housing_ids,
          housingSaleRelatedIds.future_housing_sale_ids,
        )
      }
    }

    // select AnotherHousings
    _filter<FutureV2Housing>(futureV2AnotherHousings, {
      future_base_id: props.futureBaseId,
    }).forEach((item) => {
      const housingSaleRelatedIds = getV2HousingRelatedIds(item)
      const LendHousingIds = housingSaleRelatedIds.future_lend_housing_ids
      if (!isSelectedWithSale(LendHousingIds)) {
        handleOtherSelect(
          item.id,
          housingSaleRelatedIds.future_v2_housing_ids,
          housingSaleRelatedIds.future_housing_sale_ids,
          housingSaleRelatedIds.future_lend_housing_ids,
        )
      }
    })
  }, [shouldInitialize])

  const sortedFirstFutureV2MainHousings = useMemo(() => {
    if (props.futureBaseId == null) return firstFutureV2MainHousings
    const eqFbId = (item: any) => item.future_base_id === props.futureBaseId
    return [
      ...firstFutureV2MainHousings.filter((item) => eqFbId(item)),
      ...firstFutureV2MainHousings.filter((item) => !eqFbId(item)),
    ]
  }, [firstFutureV2MainHousings])

  const sortedFirstFutureHousingSales = useMemo(() => {
    if (props.futureBaseId == null) return firstFutureHousingSales
    const eqFbId = (item: any) => item.future_base_id === props.futureBaseId
    return [
      ...firstFutureHousingSales.filter((item) => eqFbId(item)),
      ...firstFutureHousingSales.filter((item) => !eqFbId(item)),
    ]
  }, [firstFutureHousingSales])

  const sortedFirstFutureLendHousings = useMemo(() => {
    if (props.futureBaseId == null) return firstFutureLendHousings
    const eqFbId = (item: any) => item.future_base_id === props.futureBaseId
    return [
      ...firstFutureLendHousings.filter((item) => eqFbId(item)),
      ...firstFutureLendHousings.filter((item) => !eqFbId(item)),
    ]
  }, [firstFutureLendHousings])

  const sortedFutureV2AnotherHousings = useMemo(() => {
    if (props.futureBaseId == null) return futureV2AnotherHousings
    const eqFbId = (item: any) => item.future_base_id === props.futureBaseId
    return [
      ...futureV2AnotherHousings.filter((item) => eqFbId(item)),
      ...futureV2AnotherHousings.filter((item) => !eqFbId(item)),
    ]
  }, [futureV2AnotherHousings])

  const titleIndex = (index: number, arr: any[]) => {
    if (props.futureBaseId == null || props.confirmEdit == null) return index + 1
    const eqFbId = (item: any) => item.future_base_id === props.futureBaseId
    return index + 1 - arr.filter((item) => eqFbId(item)).length
  }

  const isExpiredSettings = (date: string | null) => {
    if (date == null) return false
    const settingsDate = new Date(date)
    return settingsDate.getFullYear() < THIS_YEAR_NUMBER
  }

  return (
    <>
      <SimulationAccordion
        className="pb-[16px]"
        title="将来の自宅を設定"
        isSelected={!!selectedSimulationId.first_housing_id}
        itemCount={
          sortedFirstFutureV2MainHousings.length +
          sortedFirstFutureHousingSales.length +
          sortedFirstFutureLendHousings.length
        }
        isExpiredSettings={
          sortedFirstFutureV2MainHousings.some(
            (item) =>
              item.id === selectedSimulationId.first_housing_id &&
              selectedSimulationId.first_housing_type === 'FutureV2Housing' &&
              isExpiredSettings(item.scheduled_at),
          ) ||
          sortedFirstFutureHousingSales.some(
            (item) =>
              item.id === selectedSimulationId.first_housing_id &&
              selectedSimulationId.first_housing_type === 'FutureHousingSale' &&
              isExpiredSettings(item.sell_at),
          ) ||
          sortedFirstFutureLendHousings.some(
            (item) =>
              item.id === selectedSimulationId.first_housing_id &&
              selectedSimulationId.first_housing_type === 'FutureLendHousing' &&
              isExpiredSettings(
                item.constructed_at != null ? String(item.constructed_at) : null,
              ),
          )
        }
      >
        <div className="flex flex-col gap-y-[12px] pt-[6px]">
          {/* 転居から始まる場合 */}
          {!!firstFutureV2MainHousings &&
            firstFutureV2MainHousings.length > 0 &&
            sortedFirstFutureV2MainHousings.map(
              (firstFutureV2MainHousing: any, index: number) => {
                return (
                  <SimulationItemAccordion
                    key={`first_future_v2_housing_${firstFutureV2MainHousing.id}_${index}`}
                    title={
                      props.futureBaseId != null &&
                      props.confirmEdit != null &&
                      props.futureBaseId === firstFutureV2MainHousing.future_base_id
                        ? `登録中の将来の自宅`
                        : `将来の自宅${titleIndex(
                            index,
                            sortedFirstFutureV2MainHousings,
                          )}`
                    }
                    isSelected={
                      firstFutureV2MainHousing.id ===
                        selectedSimulationId.first_housing_id &&
                      selectedSimulationId.first_housing_type === 'FutureV2Housing'
                    }
                    isExpiredSettings={isExpiredSettings(
                      firstFutureV2MainHousing.scheduled_at,
                    )}
                    inputType="radio"
                    onSelect={() => {
                      const housingSaleRelatedIds = getV2HousingRelatedIds(
                        firstFutureV2MainHousing,
                      )
                      const LendHousingIds = housingSaleRelatedIds.future_lend_housing_ids
                      if (!isSelectedWithSale(LendHousingIds)) {
                        props.confirmEdit != null && !props.confirmEdit
                          ? props.openEditConfirmModal?.()
                          : handleSelect(
                              'FutureV2Housing',
                              firstFutureV2MainHousing.id,
                              housingSaleRelatedIds.future_v2_housing_ids,
                              housingSaleRelatedIds.future_lend_housing_ids,
                              housingSaleRelatedIds.future_housing_sale_ids,
                            )
                      }
                    }}
                  >
                    <DeselectControlTooltipWrapper
                      isSelectedWithSale={isSelectedWithSale}
                      getLendHousingRelatedIds={getV2HousingRelatedIds}
                      housing={firstFutureV2MainHousing}
                    >
                      <div
                        id={`first_future_v2_housing_${firstFutureV2MainHousing.id}_${index}`}
                        key={`first_future_v2_housing_${firstFutureV2MainHousing.id}_${index}`}
                        className={clsx(
                          'w-[100%] mb-[60px] last:mb-0 text-[12px] md:text-[14px] cursor-pointer p-10',
                          {
                            ['cursor-not-allowed']: isSelectedWithSale(
                              getV2HousingRelatedIds(firstFutureV2MainHousing)
                                ?.future_lend_housing_ids,
                            ),
                          },
                        )}
                      >
                        {firstFutureV2MainHousings[0]?.prev_housing_type !==
                          'SimpleHousing' && (
                          <Typography className="text-black-900" isBold>
                            現在のご自宅
                          </Typography>
                        )}
                        <div className="border-l-[3px] border-black-500 mx-[10px]">
                          {targetInputHousing &&
                            isSelfOwnedHouseType(targetInputHousing.house_type) && (
                              <InputHousingSelfOwndBody
                                inputHousing={targetInputHousing}
                              />
                            )}
                          {targetInputHousing &&
                            isRentHouseType(targetInputHousing.house_type) && (
                              <InputHousingRentBody inputHousing={targetInputHousing} />
                            )}
                        </div>

                        <HousingSectionItem
                          isHousingForInput
                          commonCallApi={commonCallApi}
                          nextScheduledAtAge={nextScheduledAtAge}
                          handleModalOpen={handleOpenMainHousingModal}
                          setPrevMainHousing={setPrevMainHousing}
                          setNextHouseType={setNextHouseType}
                          setNextScheduledAtAge={setNextScheduledAtAge}
                          futureV2HousingForSaleModalStatus={
                            futureV2HousingForSaleModalStatus
                          }
                          futureV2HousingForLendModalStatus={
                            futureV2HousingForLendModalStatus
                          }
                          setFutureV2HousingForSaleModalStatus={
                            setFutureV2HousingForSaleModalStatus
                          }
                          setFutureV2HousingForLendModalStatus={
                            setFutureV2HousingForLendModalStatus
                          }
                          futureV2Housing={firstFutureV2MainHousing}
                          afterDestory={() => {
                            if (
                              firstFutureV2MainHousing.id ===
                                selectedSimulationId.first_housing_id &&
                              selectedSimulationId.first_housing_type ===
                                'FutureV2Housing'
                            ) {
                              const housingSaleRelatedIds = getV2HousingRelatedIds(
                                firstFutureV2MainHousing,
                              )
                              handleSelect(
                                'FutureV2Housing',
                                firstFutureV2MainHousing.id,
                                housingSaleRelatedIds.future_v2_housing_ids,
                                housingSaleRelatedIds.future_lend_housing_ids,
                                housingSaleRelatedIds.future_housing_sale_ids,
                              )
                            }
                          }}
                        />
                      </div>
                    </DeselectControlTooltipWrapper>
                  </SimulationItemAccordion>
                )
              },
            )}

          {/* 売却から始まる場合 */}
          {sortedFirstFutureHousingSales?.map(
            (firstFutureHousingSale: any, index: number) => {
              return (
                <SimulationItemAccordion
                  key={`first_future_v2_housing_${firstFutureHousingSale.id}_${index}`}
                  title={
                    props.futureBaseId != null &&
                    props.confirmEdit != null &&
                    props.futureBaseId === firstFutureHousingSale.future_base_id
                      ? `登録中の将来の自宅`
                      : `将来の自宅${
                          firstFutureV2MainHousings.length +
                          titleIndex(index, sortedFirstFutureHousingSales)
                        }`
                  }
                  isSelected={
                    firstFutureHousingSale.id === selectedSimulationId.first_housing_id &&
                    selectedSimulationId.first_housing_type === 'FutureHousingSale'
                  }
                  isExpiredSettings={isExpiredSettings(firstFutureHousingSale.sell_at)}
                  inputType="radio"
                  onSelect={() => {
                    const housingSaleRelatedIds = getHousingSaleRelatedIds(
                      firstFutureHousingSale,
                    )
                    const LendHousingIds = housingSaleRelatedIds.future_lend_housing_ids
                    if (!isSelectedWithSale(LendHousingIds)) {
                      props.confirmEdit != null && !props.confirmEdit
                        ? props.openEditConfirmModal?.()
                        : handleSelect(
                            'FutureHousingSale',
                            firstFutureHousingSale.id,
                            housingSaleRelatedIds.future_v2_housing_ids,
                            housingSaleRelatedIds.future_lend_housing_ids,
                            housingSaleRelatedIds.future_housing_sale_ids,
                          )
                    }
                  }}
                >
                  <DeselectControlTooltipWrapper
                    isSelectedWithSale={isSelectedWithSale}
                    getLendHousingRelatedIds={getHousingSaleRelatedIds}
                    housing={firstFutureHousingSale}
                  >
                    <div
                      id={`first_future_v2_housing_${firstFutureHousingSale.id}_${index}`}
                      key={`first_future_v2_housing_${firstFutureHousingSale.id}_${index}`}
                      className={clsx(
                        'w-[100%] mb-[60px] last:mb-0 text-[12px] md:text-[14px] cursor-pointer  p-10',
                        {
                          ['cursor-not-allowed']: isSelectedWithSale(
                            getHousingSaleRelatedIds(firstFutureHousingSale)
                              ?.future_lend_housing_ids,
                          ),
                        },
                      )}
                    >
                      {firstFutureV2MainHousings[0]?.prev_housing_type !==
                        'SimpleHousing' && (
                        <Typography className="text-black-900" isBold>
                          現在のご自宅
                        </Typography>
                      )}
                      <div className="border-l-[3px] border-black-500 mx-[10px]">
                        {targetInputHousing &&
                          isSelfOwnedHouseType(targetInputHousing.house_type) && (
                            <InputHousingSelfOwndBody inputHousing={targetInputHousing} />
                          )}
                        {targetInputHousing &&
                          isRentHouseType(targetInputHousing.house_type) && (
                            <InputHousingRentBody inputHousing={targetInputHousing} />
                          )}
                      </div>

                      {_find(futureV2Housings, {
                        id: firstFutureHousingSale.future_v2_housing_id,
                      }) ? (
                        <HousingSectionItem
                          isHousingForInput
                          futureV2Housing={
                            _find(futureV2Housings, {
                              id: firstFutureHousingSale.future_v2_housing_id,
                            }) as any
                          }
                          commonCallApi={commonCallApi}
                          nextScheduledAtAge={nextScheduledAtAge}
                          handleModalOpen={handleOpenMainHousingModal}
                          setPrevMainHousing={setPrevMainHousing}
                          setNextHouseType={setNextHouseType}
                          setNextScheduledAtAge={setNextScheduledAtAge}
                          futureV2HousingForSaleModalStatus={
                            futureV2HousingForSaleModalStatus
                          }
                          futureV2HousingForLendModalStatus={
                            futureV2HousingForLendModalStatus
                          }
                          setFutureV2HousingForSaleModalStatus={
                            setFutureV2HousingForSaleModalStatus
                          }
                          setFutureV2HousingForLendModalStatus={
                            setFutureV2HousingForLendModalStatus
                          }
                          afterDestory={() => {
                            if (
                              firstFutureHousingSale.id ===
                                selectedSimulationId.first_housing_id &&
                              selectedSimulationId.first_housing_type ===
                                'FutureHousingSale'
                            ) {
                              const housingSaleRelatedIds = getHousingSaleRelatedIds(
                                firstFutureHousingSale,
                              )
                              handleSelect(
                                'FutureHousingSale',
                                firstFutureHousingSale.id,
                                housingSaleRelatedIds.future_v2_housing_ids,
                                housingSaleRelatedIds.future_lend_housing_ids,
                                housingSaleRelatedIds.future_housing_sale_ids,
                              )
                            }
                          }}
                        />
                      ) : (
                        <>
                          <Typography className="text-black-900" isBold>
                            {`${convertDateToJapaneseFormat(
                              firstFutureHousingSale?.sell_at,
                              'year',
                            )} 売却して住み替え`}
                          </Typography>
                          <div className="border-l-[3px] border-black-500 mx-[10px]">
                            <FutureHousingSaleBody
                              futureHousingSale={
                                firstFutureHousingSale as FutureHousingSale
                              }
                              nextScheduledAtAge={nextScheduledAtAge}
                              commonCallApi={commonCallApi}
                              setNextHouseType={setNextHouseType}
                              setNextScheduledAtAge={setNextScheduledAtAge}
                              setPrevHousing={setPrevMainHousing}
                              futureV2HousingForPurchaseModalStatus={
                                futureV2HousingForSaleModalStatus
                              }
                              setFutureV2HousingForPurchaseModalStatus={
                                setFutureV2HousingForSaleModalStatus
                              }
                              afterDestory={() => {
                                if (
                                  firstFutureHousingSale.id ===
                                    selectedSimulationId.first_housing_id &&
                                  selectedSimulationId.first_housing_type ===
                                    'FutureHousingSale'
                                ) {
                                  const housingSaleRelatedIds = getHousingSaleRelatedIds(
                                    firstFutureHousingSale,
                                  )
                                  handleSelect(
                                    'FutureHousingSale',
                                    firstFutureHousingSale.id,
                                    housingSaleRelatedIds.future_v2_housing_ids,
                                    housingSaleRelatedIds.future_lend_housing_ids,
                                    housingSaleRelatedIds.future_housing_sale_ids,
                                  )
                                }
                              }}
                            />
                          </div>

                          <Tooltip
                            item={
                              <Typography className="fs-12">
                                住み替え先が登録されていないため
                                <br />
                                次の住居を検討できません
                              </Typography>
                            }
                            itemClassName="w-[220px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                          >
                            <span>
                              <SimulationNewButton
                                buttonTitle="次の住居を追加する"
                                isDisabled={true}
                                onClick={() => {}}
                              />
                            </span>
                          </Tooltip>
                        </>
                      )}
                    </div>
                  </DeselectControlTooltipWrapper>
                </SimulationItemAccordion>
              )
            },
          )}

          {/* 賃貸化から始まる場合 */}
          {sortedFirstFutureLendHousings?.map(
            (firstFutureLendHousing: any, index: number) => {
              return (
                <SimulationItemAccordion
                  key={`first_future_v2_housing_${firstFutureLendHousing.id}_${index}`}
                  title={
                    props.futureBaseId != null &&
                    props.confirmEdit != null &&
                    props.futureBaseId === firstFutureLendHousing.future_base_id
                      ? `登録中の将来の自宅`
                      : `将来の自宅${
                          firstFutureHousingSales.length +
                          firstFutureV2MainHousings.length +
                          +titleIndex(index, sortedFirstFutureLendHousings)
                        }`
                  }
                  isSelected={
                    firstFutureLendHousing.id === selectedSimulationId.first_housing_id &&
                    selectedSimulationId.first_housing_type === 'FutureLendHousing'
                  }
                  isExpiredSettings={isExpiredSettings(
                    firstFutureLendHousing.constructed_at,
                  )}
                  inputType="radio"
                  onSelect={() => {
                    const housingSaleRelatedIds = getLendHousingRelatedIds(
                      firstFutureLendHousing,
                    )
                    const LendHousingIds = housingSaleRelatedIds.future_lend_housing_ids

                    if (!isSelectedWithSale(LendHousingIds)) {
                      props.confirmEdit != null && !props.confirmEdit
                        ? props.openEditConfirmModal?.()
                        : handleSelect(
                            'FutureLendHousing',
                            firstFutureLendHousing.id,
                            housingSaleRelatedIds.future_v2_housing_ids,
                            housingSaleRelatedIds.future_lend_housing_ids,
                            housingSaleRelatedIds.future_housing_sale_ids,
                          )
                    }
                  }}
                >
                  <DeselectControlTooltipWrapper
                    isSelectedWithSale={isSelectedWithSale}
                    getLendHousingRelatedIds={getLendHousingRelatedIds}
                    housing={firstFutureLendHousing}
                  >
                    <div
                      id={`first_future_v2_housing_${firstFutureLendHousing.id}_${index}`}
                      key={`first_future_v2_housing_${firstFutureLendHousing.id}_${index}`}
                      className={clsx(
                        'w-[100%] mb-[60px] last:mb-0 text-[12px] md:text-[14px] cursor-pointer p-10',
                        {
                          ['cursor-not-allowed']: isSelectedWithSale(
                            getLendHousingRelatedIds(firstFutureLendHousing)
                              ?.future_lend_housing_ids,
                          ),
                        },
                      )}
                    >
                      {firstFutureV2MainHousings[0]?.prev_housing_type !==
                        'SimpleHousing' && (
                        <Typography className="text-black-900" isBold>
                          現在のご自宅
                        </Typography>
                      )}
                      <div className="border-l-[3px] border-black-500 mx-[10px]">
                        {targetInputHousing &&
                          isSelfOwnedHouseType(targetInputHousing.house_type) && (
                            <InputHousingSelfOwndBody inputHousing={targetInputHousing} />
                          )}
                        {targetInputHousing &&
                          isRentHouseType(targetInputHousing.house_type) && (
                            <InputHousingRentBody inputHousing={targetInputHousing} />
                          )}
                      </div>

                      {_find(futureV2Housings, {
                        id: firstFutureLendHousing.future_v2_housing_id,
                      }) ? (
                        <HousingSectionItem
                          isHousingForInput
                          futureV2Housing={
                            _find(futureV2Housings, {
                              id: firstFutureLendHousing.future_v2_housing_id,
                            }) as any
                          }
                          commonCallApi={commonCallApi}
                          nextScheduledAtAge={nextScheduledAtAge}
                          handleModalOpen={handleOpenMainHousingModal}
                          setPrevMainHousing={setPrevMainHousing}
                          setNextHouseType={setNextHouseType}
                          setNextScheduledAtAge={setNextScheduledAtAge}
                          futureV2HousingForSaleModalStatus={
                            futureV2HousingForSaleModalStatus
                          }
                          futureV2HousingForLendModalStatus={
                            futureV2HousingForLendModalStatus
                          }
                          setFutureV2HousingForSaleModalStatus={
                            setFutureV2HousingForSaleModalStatus
                          }
                          setFutureV2HousingForLendModalStatus={
                            setFutureV2HousingForLendModalStatus
                          }
                          afterDestory={() => {
                            if (
                              firstFutureLendHousing.id ===
                                selectedSimulationId.first_housing_id &&
                              selectedSimulationId.first_housing_type ===
                                'FutureLendHousing'
                            ) {
                              const housingSaleRelatedIds = getLendHousingRelatedIds(
                                firstFutureLendHousing,
                              )
                              handleSelect(
                                'FutureLendHousing',
                                firstFutureLendHousing.id,
                                housingSaleRelatedIds.future_v2_housing_ids,
                                housingSaleRelatedIds.future_lend_housing_ids,
                                housingSaleRelatedIds.future_housing_sale_ids,
                              )
                            }
                          }}
                        />
                      ) : (
                        <>
                          <Typography className="text-black-900" isBold>
                            {`${convertDateToJapaneseFormat(
                              firstFutureLendHousing?.lend_start_at,
                              'year',
                            )} 賃貸化して住み替え`}
                          </Typography>
                          <div className="border-l-[3px] border-black-500 mx-[10px]">
                            <FutureLendHousingForm
                              futureLendHousing={
                                firstFutureLendHousing as FutureLendHousing
                              }
                              nextScheduledAtAge={nextScheduledAtAge}
                              commonCallApi={commonCallApi}
                              // afterDestroy=
                              setNextHouseType={setNextHouseType}
                              setNextScheduledAtAge={setNextScheduledAtAge}
                              setPrevHousing={setPrevMainHousing}
                              futureV2HousingForPurchaseModalStatus={
                                futureV2HousingForLendModalStatus
                              }
                              setFutureV2HousingForPurchaseModalStatus={
                                setFutureV2HousingForLendModalStatus
                              }
                              afterDestory={() => {
                                if (
                                  firstFutureLendHousing.id ===
                                    selectedSimulationId.first_housing_id &&
                                  selectedSimulationId.first_housing_type ===
                                    'FutureLendHousing'
                                ) {
                                  const housingSaleRelatedIds = getLendHousingRelatedIds(
                                    firstFutureLendHousing,
                                  )
                                  handleSelect(
                                    'FutureLendHousing',
                                    firstFutureLendHousing.id,
                                    housingSaleRelatedIds.future_v2_housing_ids,
                                    housingSaleRelatedIds.future_lend_housing_ids,
                                    housingSaleRelatedIds.future_housing_sale_ids,
                                  )
                                }
                              }}
                            />
                          </div>

                          <Tooltip
                            item={
                              <Typography className="fs-12">
                                住み替え先が登録されていないため
                                <br />
                                次の住居を検討できません
                              </Typography>
                            }
                            itemClassName="w-[220px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                          >
                            <span>
                              <SimulationNewButton
                                buttonTitle="次の住居を追加する"
                                isDisabled={true}
                                onClick={() => {}}
                              />
                            </span>
                          </Tooltip>
                        </>
                      )}
                    </div>
                  </DeselectControlTooltipWrapper>
                </SimulationItemAccordion>
              )
            },
          )}
        </div>

        {/* 将来の住居の設定を新しく追加する場合 */}
        <CircleTitle
          className="pt-40 pb-[10px]"
          title="将来の自宅を新しく登録する"
          classNameTitle="text-[18px]"
        />
        {firstFutureV2MainHousings[0]?.prev_housing_type !== 'SimpleHousing' && (
          <Typography className="text-black-900" isBold>
            現在のご自宅
          </Typography>
        )}
        <div className="border-l-[3px] border-black-500 mx-[10px]">
          {targetInputHousing && isSelfOwnedHouseType(targetInputHousing.house_type) && (
            <InputHousingSelfOwndBody inputHousing={targetInputHousing} />
          )}
          {targetInputHousing && isRentHouseType(targetInputHousing.house_type) && (
            <InputHousingRentBody inputHousing={targetInputHousing} />
          )}
        </div>

        <div className="my-[16px]">
          <SimulationNewButton
            buttonTitle="住居を追加する"
            isDisabled={!isManipulatedApproval}
            onClick={() => {
              handleOpenMainHousingModal()
              setPrevMainHousing(targetInputHousing)
            }}
          />
        </div>
      </SimulationAccordion>

      <SimulationAccordion
        className="pb-[16px]"
        title="別宅を設定"
        isSelected={
          !!selectedSimulationId.first_future_v2_housing_ids &&
          selectedSimulationId.first_future_v2_housing_ids.length !== 0
        }
        selectedCount={selectedSimulationId.first_future_v2_housing_ids?.length}
        itemCount={sortedFutureV2AnotherHousings.length}
        isExpiredSettings={sortedFutureV2AnotherHousings.some(
          (item) =>
            !!selectedSimulationId.first_future_v2_housing_ids &&
            selectedSimulationId.first_future_v2_housing_ids.includes(item.id) &&
            isExpiredSettings(item.scheduled_at),
        )}
      >
        <div className="flex flex-col gap-y-[12px] pt-[6px]">
          {/* 別宅 */}
          {sortedFutureV2AnotherHousings?.map((item, index) => {
            return (
              <SimulationItemAccordion
                key={`first_future_v2_housing_${item.id}_${index}`}
                title={
                  props.futureBaseId != null &&
                  props.confirmEdit != null &&
                  props.futureBaseId === item.future_base_id
                    ? `登録中の別宅の設定`
                    : `別宅の設定${titleIndex(index, sortedFutureV2AnotherHousings)}`
                }
                isSelected={
                  !!selectedSimulationId.first_future_v2_housing_ids &&
                  selectedSimulationId.first_future_v2_housing_ids.includes(item.id)
                }
                isExpiredSettings={isExpiredSettings(item.scheduled_at)}
                inputType="checkbox"
                onSelect={() => {
                  const housingSaleRelatedIds = getV2HousingRelatedIds(item)

                  const LendHousingIds = housingSaleRelatedIds.future_lend_housing_ids

                  if (!isSelectedWithSale(LendHousingIds)) {
                    props.confirmEdit != null && !props.confirmEdit
                      ? props.openEditConfirmModal?.()
                      : handleOtherSelect(
                          item.id,
                          housingSaleRelatedIds.future_v2_housing_ids,
                          housingSaleRelatedIds.future_housing_sale_ids,
                          housingSaleRelatedIds.future_lend_housing_ids,
                        )
                  }
                }}
              >
                <DeselectControlTooltipWrapper
                  isSelectedWithSale={isSelectedWithSale}
                  getLendHousingRelatedIds={getV2HousingRelatedIds}
                  housing={item}
                >
                  <div
                    id={`first_future_v2_housing_${item.id}_${index}`}
                    key={index}
                    className={clsx(
                      'w-[100%] mb-[60px] last:mb-0 text-[12px] md:text-[14px] cursor-pointer  p-10',
                      {
                        ['cursor-not-allowed']: isSelectedWithSale(
                          getLendHousingRelatedIds(item)?.future_lend_housing_ids,
                        ),
                      },
                    )}
                  >
                    <HousingSectionItem
                      futureV2Housing={item}
                      commonCallApi={commonCallApi}
                      isAnotherHousing
                      nextScheduledAtAge={nextScheduledAtAge}
                      handleModalOpen={handleOpenMainHousingModal}
                      setPrevAnotherHousing={setPrevMainHousing}
                      setNextHouseType={setNextHouseType}
                      setNextScheduledAtAge={setNextScheduledAtAge}
                      setPrevMainHousing={setPrevMainHousing}
                      futureV2HousingForSaleModalStatus={
                        futureV2HousingForSaleModalStatus
                      }
                      futureV2HousingForLendModalStatus={
                        futureV2HousingForLendModalStatus
                      }
                      setFutureV2HousingForSaleModalStatus={
                        setFutureV2HousingForSaleModalStatus
                      }
                      setFutureV2HousingForLendModalStatus={
                        setFutureV2HousingForLendModalStatus
                      }
                      afterDestory={() => {
                        if (
                          !!selectedSimulationId.first_future_v2_housing_ids &&
                          selectedSimulationId.first_future_v2_housing_ids.includes(
                            item.id,
                          )
                        ) {
                          const housingSaleRelatedIds = getV2HousingRelatedIds(item)
                          handleOtherSelect(
                            item.id,
                            housingSaleRelatedIds.future_v2_housing_ids,
                            housingSaleRelatedIds.future_housing_sale_ids,
                            housingSaleRelatedIds.future_lend_housing_ids,
                          )
                        }
                      }}
                    />
                  </div>
                </DeselectControlTooltipWrapper>
              </SimulationItemAccordion>
            )
          })}
        </div>
        <SimulationNewButton
          buttonTitle="別宅を追加する"
          isDisabled={!isManipulatedApproval}
          onClick={handleOpenAnotherHousingModal}
        />
      </SimulationAccordion>

      {isMainCreateModalOpen && (
        <FutureV2HousingSaleOrLendCreateModalForm
          isOpen={isMainCreateModalOpen}
          isMainType
          prevHousingType={prevMainHousingType}
          prevHousing={prevMainHousing}
          handleClose={handleModalClose}
          completedCallback={
            prevMainHousing ? completedSaleOrLendCallback : completedNoPrevCallback
          }
        />
      )}

      {isAnotherCreateModalOpen && (
        <FutureV2HousingSaleOrLendCreateModalForm
          isAnotherModal
          isOpen={isAnotherCreateModalOpen}
          prevHousingType={PREV_HOUSING_TYPE.another as PrevHousingType}
          prevHousing={prevAnotherHousing}
          handleClose={handleModalClose}
          completedCallback={completedSaleOrLendCallback}
        />
      )}

      {isFutureV2HousingModalOpen && (
        <FutureV2HousingCreateModalForm
          targetHousingSaleId={targetHousingSaleId}
          targetHousingLendId={targetHousingLendId}
          isOpen={isFutureV2HousingModalOpen}
          isAnotherHouse={isAnotherHouse}
          prevHousing={modalTypeInProgress === 'main' ? prevMainHousing : undefined}
          nextHouseType={nextHouseType as NextHouseType}
          nextScheduledAtAge={nextScheduledAtAge}
          handleClose={handleFutureV2HousingModalClose}
          completedCallback={completedFutureV2HousingCallback}
          continueToPurchaseCallback={continueForPurchaseCallback}
        />
      )}
    </>
  )
}

export default FutureV2HousingMainBody
