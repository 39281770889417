import React, { FC, useContext } from 'react'
import FamilyMonochromaticIcon from '@/assets/images/v2/family-monochromatic.svg'
import MarginContainer from '@/components/v2/atoms/MarginContainer'
import Paper from '@/components/v2/atoms/Paper'
import Typography from '@/components/v2/atoms/Typography'
import Breadcrumb from '@/components/v2/molecules/Breadcrumb'
import PaperTitle from '@/components/v2/molecules/PaperTitle'
import { useParams } from 'react-router'
import RiskForm from '@/templates/v2/myData/risks/_Form'
import { AccountContext } from '@/models/accountsModelFunc'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import { fetchInputRisk } from '@/containers/inputs/inputRisksSlice'
import { updateResetFlg } from '@/containers/futures/futureBasesSlice'
import { useDispatch } from 'react-redux'

const MyDataRiskEdit: FC = () => {
  const { isAdmin } = useContext(AccountContext)
  const { account_id } = useParams<{ account_id?: string }>()
  let targetPerson = String(useParams<{ target?: string }>().target)
  const { handleMovePage } = useCustomHistory()
  const dispatch = useDispatch()

  const movePageAfterFormCancel = () => {
    handleMovePage(
      isAdmin ? `/v2/accounts/${account_id}/myData/risk` : '/v2/myData/risk',
    )
  }

  const movePageAfterFormSubmit = () => {
    dispatch(fetchInputRisk())
    dispatch(updateResetFlg())
    handleMovePage(
      isAdmin ? `/v2/accounts/${account_id}/myData/risk` : '/v2/myData/risk',
    )
  }

  return (
    <MarginContainer className="px-[8px] md:px-[32px] lg:px-0 py-[8px] md:py-[40px]">
      <Breadcrumb
        className="pb-[10px] md:pb-[32px]"
        data={[
          {
            name: 'マイデータ',
            url: isAdmin ? `/v2/accounts/${account_id}/myData` : '/v2/myData',
          },
          {
            name: 'マイデータ設定',
            url: isAdmin ? `/v2/accounts/${account_id}/myData/risk` : '/v2/myData/risk',
          },
          { name: '万が一の保障の設定' },
        ]}
      />

      <PaperTitle
        className="mb-[16px] md:mb-[40px]"
        title="万が一の保障"
        description="日々の収入や支出を登録することで改善案などのご提案が可能になります"
        icon={<FamilyMonochromaticIcon />}
        smallIcon={
          <FamilyMonochromaticIcon className="h-[82px] w-[130px]" viewBox="0 0 117 154" />
        }
      />

      {targetPerson === 'person' && (
        <Paper className="bg-white p-[24px] md:p-[40px]">
          <Typography
            className="mb-[32px] pb-[10px] text-[24px] md:text-[28px] text-black-800 border-b-2 border-black-300"
            isBold
          >
            ご本人が亡くなった場合の万が一の保障について
          </Typography>

          <RiskForm handleCancel={movePageAfterFormCancel} submitCallback={movePageAfterFormSubmit} />
        </Paper>
      )}

      {targetPerson === 'spouse' && (
        <Paper className="bg-white p-[24px] md:p-[40px]">
          <Typography
            className="mb-[32px] pb-[10px] text-[24px] md:text-[28px] text-black-800 border-b-2 border-black-300"
            isBold
          >
            配偶者が亡くなった場合の万が一の保障について
          </Typography>

          <RiskForm handleCancel={movePageAfterFormCancel} submitCallback={movePageAfterFormSubmit} />
        </Paper>
      )}
    </MarginContainer>
  )
}

export default MyDataRiskEdit
