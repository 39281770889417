import React, { FC } from 'react'
import { useField, useForm } from 'react-final-form'
import { getTargetSelfOwnedAttributesName } from '@/models/commonHousingModelFunc'
import { castNumberWithoutOperator } from '@/models/commonsModelFunc'
import { calcEstimatedPropertyTax } from '@/models/commonEstateInvestmentModelFunc'
import {
  commaNumberMaxLength,
  composeValidators,
  required,
  zeroOrMoreNumber,
} from '@/utils/validate'

import Button from '@/components/v2/atoms/Button'
import Typography from '@/components/v2/atoms/Typography'
import NumberField from '@/components/v2/molecules/NumberField'
import HelpCircleIcon from '@/assets/images/v2/help-circle.svg'
import Tooltip from '@/components/v2/atoms/Tooltip'

interface TaxYearlyProps {
  isFuture?: boolean
}

const TaxYearly: FC<TaxYearlyProps> = (props) => {
  const { change } = useForm()
  const { isFuture } = props
  const targetHousingSelfOwnedAttributesName = getTargetSelfOwnedAttributesName(isFuture)

  const {
    input: { value: propertyPriceManyen },
  } = useField(`${targetHousingSelfOwnedAttributesName}.property_price_manyen`)
  const {
    input: { value: propertyPriceLandManyen },
  } = useField(`${targetHousingSelfOwnedAttributesName}.property_price_land_manyen`)
  const {
    input: { value: propertyPriceBuildingManyen },
  } = useField(`${targetHousingSelfOwnedAttributesName}.property_price_building_manyen`)

  const targetPropertyPriceManyen = propertyPriceManyen
    ? castNumberWithoutOperator(propertyPriceManyen)
    : castNumberWithoutOperator(propertyPriceLandManyen) +
      castNumberWithoutOperator(propertyPriceBuildingManyen)
  const estimatedAmount = calcEstimatedPropertyTax(targetPropertyPriceManyen)

  return (
    <>
      <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-center">
        <div className="flex flex-auto items-center">
          <Typography
            className="flex min-w-[170px] pb-[10px] md:pb-0 text-[14px] text-black-800"
            isBold
          >
            年間の固定資産税・
            <br />
            都市計画税
            <div className="flex items-center mx-[10px]">
              <Tooltip
                item="固定資産税納税通知書をご確認ください。「概算を入力」ボタンの押下による計算結果は、 物件価格×0.3%で計算しています。"
                itemClassName="w-[80px] sm:w-[200px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
              >
                <HelpCircleIcon />
              </Tooltip>
            </div>
          </Typography>

          <div>
            <Button
              children="概算を入力"
              className="text-[12px] bg-white border border-black-600 border-solid h-37 rounded-4 w-80 hover:bg-black-200 block mr-10 my-[10px]"
              onClick={() => {
                change(
                  `${targetHousingSelfOwnedAttributesName}.tax_yearly`,
                  estimatedAmount,
                )
              }}
            />
          </div>
        </div>

        <div className="flex flex-auto items-center w-full">
          <NumberField
            name={`${targetHousingSelfOwnedAttributesName}.tax_yearly`}
            isFullWidth
            validate={composeValidators(required, zeroOrMoreNumber, (value: any) =>
              commaNumberMaxLength(value, 10),
            )}
          />
          <Typography className="pl-[8px] text-[14px] text-black-800" isBold>
            円
          </Typography>
        </div>
      </div>
    </>
  )
}

export default TaxYearly
