import React, { FC, useEffect } from 'react'
import { isUndefined as _isUndefined, isNull as _isNull } from 'lodash'

interface RenderCustomYAxisDummyProps {
  /** クラス名 */
  id?: string
  /** 高さ */
  height: number
  /** データ */
  data: { [key: string]: any }[]
}

const renderCustomYAxisDummy: FC<RenderCustomYAxisDummyProps> = (props) => {
  const { id, height, data } = props

  useEffect(() => {
    if (_isUndefined(id) || !data || data.length === 0) return

    const timer = setTimeout(() => {
      const yAxisDummyElement = document.getElementById(`${id}_yAxis`)
      yAxisDummyElement!.innerHTML = ''
      const yAxisElement = document.querySelector(`#${id} .yAxis`)
      const clonedYAxisElement = yAxisElement?.cloneNode(true)

      !_isNull(clonedYAxisElement) && yAxisDummyElement?.appendChild(clonedYAxisElement!)
    }, 100)

    return () => clearTimeout(timer)
  }, [data])

  return (
    <>
      {!_isUndefined(id) && data && data.length > 0 && (
        <div className="sticky left-0 w-0 z-10">
          <svg id={`${id}_yAxis`} className="bg-white" width={66} height={height} />
        </div>
      )}
    </>
  )
}

export default renderCustomYAxisDummy
