import React from 'react'
import { Tooltip, Coordinate } from 'recharts'

interface RenderCustomTooltipProps {
  /** ツールチップ位置 */
  position?: Coordinate
  /** カスタマイズツールチップ */
  content?: JSX.Element
}

const renderCustomTooltip = (props: RenderCustomTooltipProps) => {
  const { position, content } = props
  return (
    <Tooltip
      wrapperStyle={{ zIndex: 20 }}
      cursor={false}
      position={position}
      content={content}
    />
  )
}

export default renderCustomTooltip
