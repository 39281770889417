import React, { FC, useMemo } from 'react'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { set as _set } from 'lodash'
import ChevronRightIcon from '@/assets/images/v2/chevron-right.svg'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import Grid from '@/components/v2/atoms/Grid'
import Modal from '@/components/v2/atoms/Modal'
import Typography from '@/components/v2/atoms/Typography'
import SelectBox from '@/components/v2/molecules/SelectBox'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import NumberField from '@/components/v2/molecules/NumberField'
import NumberFieldDecimal from '@/components/v2/molecules/NumberFieldDecimal'
import {
  composeValidators,
  required,
  commaNumberMaxLength,
  zeroOrMoreNumber,
  oneHundredLessThanNumber,
} from '@/utils/validate'
import {
  CHANGE_CAR_SPAN_SELECT_ITEMS,
  DRIVER_AGE_SELECT_ITEMS,
  PAYMENT_TYPE_BUTTON_ITEMS,
} from '@/constants/formItem/futures/futureCars'
import { PAYMENT_TYPE } from '@/models/futures/futureCarsModelFunc'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/store'
import { castNumberWithoutOperator } from '@/models/commonsModelFunc'
import {
  createFutureCarV2,
  updateFutureCarV2,
} from '@/containers/futures/futureCarsSlice'
import { find as _find, filter as _filter } from 'lodash'
import { FutureCarCreateValues } from '@/models/futures/futureCarsModel'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import { fetchAccountFutureItems } from '@/containers/accountsSlice'
import clsx from 'clsx'
import SubmitErrorMessage from '@/components/v2/molecules/SubmitErrorMessage'

interface FutureMonthlyLivingCostModalFormProps {
  /** true: オープン, false: クローズ */
  isOpen: boolean
  /** onCloseメソッド */
  onClose: () => void
  /** 編集時のID */
  id: number | null

  isEdit: boolean
  /* FutureItemsを参照するID */
  futureBaseId?: number
}

const FutureCarModalForm: FC<FutureMonthlyLivingCostModalFormProps> = (props) => {
  const dispatch = useDispatch()
  const { showFlashMsg } = useFlashAlert()
  const { isOpen, onClose, isEdit, id } = props
  const futureCars = useSelector(
    (state: RootState) => state.accounts.futureItems?.future_cars,
  )
  const familyInfoV2 = useSelector(
    (state: RootState) => state.accounts.futureItems?.family_info,
  )

  const initialValues = useMemo(() => {
    const targetFutureAsset = _find(futureCars, { id })
    return isEdit && !!targetFutureAsset
      ? { future_car: targetFutureAsset }
      : { future_car: { payment_type: null } }
  }, [isEdit, futureCars, id])

  const handleCreate = (newValues: any) => {
    return dispatch(
      createFutureCarV2(
        { account: { future_cars_attributes: [newValues.future_car] } },
        () => {
          dispatch(fetchAccountFutureItems(props.futureBaseId))
          showFlashMsg(isEdit ? '更新しました' : '作成しました')
          onClose()
        },
      ),
    )
  }

  const handleUpdate = (newValues: any) => {
    dispatch(
      updateFutureCarV2(newValues, id as any, () => {
        dispatch(fetchAccountFutureItems(props.futureBaseId))
        showFlashMsg(isEdit ? '更新しました' : '作成しました')
        onClose()
      }),
    )
  }

  const handleSubmit = (values: FutureCarCreateValues) => {
    const newValues = {
      future_car: { ...values.future_car },
    }

    return isEdit ? handleUpdate(newValues) : handleCreate(newValues)
  }

  return (
    <Modal
      className="w-[95%] xl:w-[1140px]"
      isOpen={isOpen}
      isDisableBackdropClick
      onClose={onClose}
    >
      <CircleTitle
        className="pb-[32px]"
        title="お車・バイクの想定を設定する"
        classNameTitle="text-[19px] md:text-[24px]"
      />
      <Form
        onSubmit={handleSubmit}
        mutators={{ ...arrayMutators }}
        initialValues={initialValues}
        validate={(values: any) => {
          const errorMessage: { [key: string]: any } = {}
          const { future_car } = values

          if (future_car?.payment_type == PAYMENT_TYPE.oneTime) return

          const transfer_fequency_month = future_car?.transfer_frequency * 12
          if (
            transfer_fequency_month < castNumberWithoutOperator(future_car.loan_period)
          ) {
            _set(
              errorMessage,
              ['future_car', 'loan_period'],
              `乗り換え頻度(${transfer_fequency_month}ヶ月)以下の期間で設定してください`,
            )
          }

          return errorMessage
        }}
        render={({ handleSubmit, values, errors, submitErrors, submitFailed }) => {
          const { payment_type } = values.future_car
          return (
            <form onSubmit={handleSubmit} className="pb-30">
              <Grid className="pb-[64px] grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px]">
                <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 min-w-[320px] md:items-baseline">
                  <Typography
                    className="pb-[10px] lg:pb-0 text-[14px] text-black-800 flex items-center"
                    isBold
                  >
                    所有期間
                  </Typography>
                  <div className="flex flex-auto items-baseline min-w-[fit-content] justify-end">
                    <SelectBox
                      name={`future_car.ride_start_at_age`}
                      className="pr-[8px]"
                      placeholder="歳を選択"
                      widthClassName="max-w-[80px]"
                      options={
                        _filter(
                          DRIVER_AGE_SELECT_ITEMS,
                          (item: any) =>
                            item.value > (familyInfoV2?.person_current_age || 18),
                        ) as any
                      }
                      validate={required}
                    />
                    <Typography
                      className="min-w-[fit-content] px-[8px] text-[14px] text-black-800"
                      isBold
                    >
                      歳から
                    </Typography>
                    <SelectBox
                      name={`future_car.ride_end_at_age`}
                      widthClassName="max-w-[80px]"
                      placeholder="歳を選択"
                      options={
                        _filter(
                          DRIVER_AGE_SELECT_ITEMS,
                          (item: any) =>
                            item.value >
                            (values?.future_car?.ride_start_at_age ||
                              familyInfoV2?.person_current_age),
                        ) as any
                      }
                      validate={required}
                    />
                    <Typography
                      className="min-w-[fit-content] px-[8px] text-[14px] text-black-800"
                      isBold
                    >
                      歳まで
                    </Typography>
                  </div>
                </div>
                <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 min-w-[320px] md:items-baseline">
                  <Typography
                    className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800 flex items-center"
                    isBold
                  >
                    乗り換え頻度
                  </Typography>
                  <div className="flex flex-auto items-baseline min-w-[160px] justify-end">
                    <SelectBox
                      name={`future_car.transfer_frequency`}
                      widthClassName="max-w-[115px]"
                      placeholder="年数を選択"
                      isFullWidth
                      options={CHANGE_CAR_SPAN_SELECT_ITEMS}
                      validate={required}
                    />
                    <Typography
                      className="min-w-[fit-content] pl-[8px] text-[14px] text-black-800"
                      isBold
                    >
                      年に一回乗り換える
                    </Typography>
                  </div>
                </div>

                <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2  md:items-baseline">
                  <Typography
                    className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                    isBold
                  >
                    一台あたりの購入金額
                  </Typography>
                  <div className="flex flex-auto items-baseline justify-end">
                    <NumberField
                      name={`future_car.purchase_price_manyen`}
                      widthClassName="w-full md:w-[120px]"
                      validate={composeValidators(
                        required,
                        zeroOrMoreNumber,
                        (value: any) => commaNumberMaxLength(value, 7),
                      )}
                    />
                    <Typography
                      className="pl-[8px] text-[14px] text-black-800 min-w-[40px]"
                      isBold
                    >
                      万円
                    </Typography>
                  </div>
                </div>
                <div className="hidden md:block" />

                <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                  <Typography
                    className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                    isBold
                  >
                    支払方法
                  </Typography>
                  <SelectBox
                    name={`future_car.payment_type`}
                    placeholder="選択する"
                    options={PAYMENT_TYPE_BUTTON_ITEMS}
                    isFullWidth
                    validate={required}
                  />
                </div>
                <div className="hidden md:block" />

                {payment_type === PAYMENT_TYPE.loan && (
                  <>
                    <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                      <Typography
                        className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                        isBold
                      >
                        ローンの頭金
                      </Typography>
                      <div className="flex flex-auto items-baseline">
                        <NumberField
                          name={`future_car.loan_earnest`}
                          isFullWidth
                          validate={composeValidators(
                            required,
                            zeroOrMoreNumber,
                            (value: any) => commaNumberMaxLength(value, 7),
                          )}
                        />
                        <Typography
                          className="pl-[8px] text-[14px] text-black-800"
                          isBold
                        >
                          円
                        </Typography>
                      </div>
                    </div>

                    <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                      <Typography
                        className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                        isBold
                      >
                        ローン返済期間
                      </Typography>
                      <div className="flex flex-auto items-baseline">
                        <NumberField
                          name={`future_car.loan_period`}
                          isFullWidth
                          validate={composeValidators(required, zeroOrMoreNumber)}
                        />
                        <Typography
                          className="min-w-[fit-content] pl-[8px] text-[14px] text-black-800"
                          isBold
                        >
                          ヶ月
                        </Typography>
                      </div>
                    </div>

                    <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 md:items-baseline">
                      <Typography
                        className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                        isBold
                      >
                        ローンの金利
                      </Typography>
                      <div className="flex flex-auto items-baseline justify-end">
                        <NumberFieldDecimal
                          name={`future_car.loan_interest_rate_percent`}
                          widthClassName="w-full md:w-[120px]"
                          validate={composeValidators(
                            required,
                            zeroOrMoreNumber,
                            oneHundredLessThanNumber,
                          )}
                        />
                        <Typography
                          className="pl-[8px] text-[14px] text-black-800"
                          isBold
                        >
                          %
                        </Typography>
                      </div>
                    </div>
                    <div className="hidden md:block" />
                  </>
                )}

                <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                  <Typography
                    className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                    isBold
                  >
                    車検代
                  </Typography>
                  <div className="flex flex-auto items-baseline">
                    <NumberField
                      name={`future_car.vehicle_inspection_fee`}
                      isFullWidth
                      validate={composeValidators(
                        required,
                        zeroOrMoreNumber,
                        (value: any) => commaNumberMaxLength(value, 7),
                      )}
                    />
                    <Typography
                      className="pl-[8px] text-[14px] text-black-800 min-w-[fit-content]"
                      isBold
                    >
                      円/年
                    </Typography>
                  </div>
                </div>

                <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                  <Typography
                    className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                    isBold
                  >
                    自動車税
                  </Typography>
                  <div className="flex flex-auto items-baseline">
                    <NumberField
                      name={`future_car.automobile_tax`}
                      isFullWidth
                      validate={composeValidators(
                        required,
                        zeroOrMoreNumber,
                        (value: any) => commaNumberMaxLength(value, 7),
                      )}
                    />
                    <Typography
                      className="pl-[8px] text-[14px] text-black-800 min-w-[fit-content]"
                      isBold
                    >
                      円/年
                    </Typography>
                  </div>
                </div>

                <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                  <Typography
                    className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                    isBold
                  >
                    保険料
                  </Typography>
                  <div className="flex flex-auto items-baseline">
                    <NumberField
                      name={`future_car.insurance_premium`}
                      isFullWidth
                      validate={composeValidators(
                        required,
                        zeroOrMoreNumber,
                        (value: any) => commaNumberMaxLength(value, 7),
                      )}
                    />
                    <Typography
                      className="pl-[8px] text-[14px] text-black-800 min-w-[fit-content]"
                      isBold
                    >
                      円/月
                    </Typography>
                  </div>
                </div>

                <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                  <Typography
                    className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                    isBold
                  >
                    ガソリン代
                  </Typography>
                  <div className="flex flex-auto items-baseline">
                    <NumberField
                      name={`future_car.gasoline_fee`}
                      isFullWidth
                      validate={composeValidators(
                        required,
                        zeroOrMoreNumber,
                        (value: any) => commaNumberMaxLength(value, 7),
                      )}
                    />
                    <Typography
                      className="pl-[8px] text-[14px] text-black-800 min-w-[fit-content]"
                      isBold
                    >
                      円/月
                    </Typography>
                  </div>
                </div>

                <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                  <Typography
                    className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                    isBold
                  >
                    駐車場代
                  </Typography>
                  <div className="flex flex-auto items-baseline">
                    <NumberField
                      name={`future_car.parking_fee`}
                      isFullWidth
                      validate={composeValidators(
                        required,
                        zeroOrMoreNumber,
                        (value: any) => commaNumberMaxLength(value, 7),
                      )}
                    />
                    <Typography
                      className="pl-[8px] text-[14px] text-black-800 min-w-[fit-content]"
                      isBold
                    >
                      円/月
                    </Typography>
                  </div>
                </div>
              </Grid>

              <SubmitErrorMessage show={!submitErrors && submitFailed} />

              <ButtonPrimary
                className={clsx(
                  { ['opacity-50']: Object.keys(errors).length > 0 },
                  'w-[fit-content] md:w-[246px] h-[64px] mx-[auto]',
                )}
                isDisabled={Object.keys(errors).length > 0}
                onClick={() => handleSubmit(values)}
              >
                <Typography
                  className="bottom-2 flex-auto pl-30 relative text-16 text-white"
                  isBold
                >
                  保存する
                </Typography>
                <ChevronRightIcon className="mr-17" />
              </ButtonPrimary>
            </form>
          )
        }}
      />
    </Modal>
  )
}

export default FutureCarModalForm
