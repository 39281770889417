import React, { FC, useEffect, useMemo, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import {
  cloneDeep as _cloneDeep,
  filter as _filter,
  isEmpty as _isEmpty,
  isNull as _isNull,
  isUndefined as _isUndefined,
} from 'lodash'
import ChevronRightIcon from '@/assets/images/v2/chevron-right.svg'
import CrossIcon from '@/assets/images/v2/cross.svg'
import FamilyMonochromaticIcon from '@/assets/images/v2/family-monochromatic.svg'
import Button from '@/components/v2/atoms/Button'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import Grid from '@/components/v2/atoms/Grid'
import MarginContainer from '@/components/v2/atoms/MarginContainer'
import Paper from '@/components/v2/atoms/Paper'
import TextField from '@/components/v2/atoms/TextField'
import Typography from '@/components/v2/atoms/Typography'
import Breadcrumb from '@/components/v2/molecules/Breadcrumb'
import NumberField from '@/components/v2/molecules/NumberField'
import PaperTitle from '@/components/v2/molecules/PaperTitle'
import { setPageMeta } from '@/containers/pageMetasSlice'
import {
  fetchInputYearCost,
  createInputYearCost,
  updateInputYearCost,
} from '@/containers/inputs/inputYearCostsSlice'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import { RootState } from '@/store'
import { composeValidators, required, zeroOrMoreNumber } from '@/utils/validate'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import { AccountContext } from '@/models/accountsModelFunc'
import { useParams } from 'react-router-dom'
import WholeSubmitErrorMessage from '@/components/v2/molecules/WholeSubmitErrorMessage'
import LoadingOverlay from '@/components/v1/atoms/LoadingOverlay'
import { updateResetFlg } from '@/containers/futures/futureBasesSlice'

const MyDataYearCostEdit: FC = () => {
  const dispatch = useDispatch()
  const { handleMovePage } = useCustomHistory()
  const { isAdmin } = useContext(AccountContext)
  const { account_id } = useParams<{ account_id?: string }>()
  const { showFlashMsg } = useFlashAlert()
  const { inputYearCost, isLoading } = useSelector(
    (state: RootState) => state.inputYearCosts,
  )

  const isLoadingInputYearCosts = useSelector(
    (state: RootState) => state.inputYearCosts.isLoading,
  )

  const buildInitialValues = useMemo(
    () => ({
      input_year_cost: {
        ...inputYearCost,
        input_year_cost_others_attributes: inputYearCost?.input_year_cost_others,
      },
    }),
    [inputYearCost],
  )

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'マイデータ | 特別な支出・大きな支出 編集',
        description: 'マイデータ | 特別な支出・大きな支出 編集',
        keyword: 'マイデータ | 特別な支出・大きな支出 編集',
      }),
    )
    if (!inputYearCost) dispatch(fetchInputYearCost())
  }, [])

  const handleSubmit = (values: any) => {
    const isEdit = !_isUndefined(values.input_year_cost.id)
    const filteredCostOthersAttributes = _filter(
      values.input_year_cost.input_year_cost_others_attributes,
      (item) => !_isNull(item.cost_yearly),
    )

    const newValues = {
      input_year_cost: {
        ..._cloneDeep(values.input_year_cost),
        input_year_cost_others_attributes: !_isEmpty(filteredCostOthersAttributes[0])
          ? filteredCostOthersAttributes
          : [],
      },
    }

    return new Promise((resolve) =>
      isEdit
        ? dispatch(
            updateInputYearCost(
              newValues,
              () => {
                showFlashMsg(isEdit ? '更新しました' : '作成しました')
                dispatch(fetchInputYearCost())
                dispatch(updateResetFlg())
                handleMovePage(
                  isAdmin
                    ? `/v2/accounts/${account_id}/myData/monthlyCosts`
                    : '/v2/myData/monthlyCosts',
                )
              },
              resolve,
            ),
          )
        : dispatch(
            createInputYearCost(
              newValues,
              () => {
                showFlashMsg(isEdit ? '更新しました' : '作成しました')
                dispatch(fetchInputYearCost())
                dispatch(updateResetFlg())
                handleMovePage(
                  isAdmin
                    ? `/v2/accounts/${account_id}/myData/monthlyCosts`
                    : '/v2/myData/monthlyCosts',
                )
              },
              resolve,
            ),
          ),
    )
  }

  return (
    <>
      <LoadingOverlay isLoading={isLoadingInputYearCosts} />
      <MarginContainer className="px-[8px] md:px-[32px] lg:px-0 py-[8px] md:py-[40px]">
        <Breadcrumb
          className="pb-[10px] md:pb-[32px]"
          linkClassName="text-[10px] md:text-[12px]"
          data={[
            {
              name: 'マイデータ',
              url: isAdmin ? `/v2/accounts/${account_id}/myData` : '/v2/myData',
            },
            {
              name: 'マイデータ設定',
              url: isAdmin
                ? `/v2/accounts/${account_id}/myData/monthlyCosts`
                : '/v2/myData/monthlyCosts',
            },
            { name: '特別な支出・大きな支出の設定' },
          ]}
        />

        <PaperTitle
          className="mb-[16px] md:mb-[40px]"
          title="特別な支出・大きな支出"
          titleClassName="relative top-[5px] md:top-0 text-[18px] md:text-[32px]"
          description="日々の収入や支出を登録することで改善案などのご提案が可能になります"
          icon={<FamilyMonochromaticIcon />}
          smallIcon={
            <FamilyMonochromaticIcon
              className="h-[82px] w-[130px]"
              viewBox="0 0 117 154"
            />
          }
        />

        <Paper className="bg-white p-[24px] md:p-[40px]">
          <Typography
            className="mb-[32px] pb-[10px] text-[20px] md:text-[28px] text-black-800 border-b-2 border-black-300"
            isBold
          >
            特別な支出・大きな支出について
          </Typography>

          <Form
            onSubmit={handleSubmit}
            initialValues={buildInitialValues}
            mutators={{ ...arrayMutators }}
            render={({
              handleSubmit,
              values,
              errors,
              submitErrors,
              form: {
                mutators: { push },
              },
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid className="pb-[32px] grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px]">
                  <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                    <Typography
                      className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                      isBold
                    >
                      家電総額
                    </Typography>
                    <div className="flex flex-auto items-baseline">
                      <NumberField
                        name="input_year_cost.replacement_appliances"
                        isFullWidth
                        validate={composeValidators(required, zeroOrMoreNumber)}
                      />
                      <Typography className="pl-[8px] text-[14px] text-black-800" isBold>
                        円
                      </Typography>
                    </div>
                  </div>

                  <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                    <Typography
                      className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                      isBold
                    >
                      家電買い替え頻度
                    </Typography>
                    <div className="flex flex-auto justify-end items-baseline">
                      <NumberField
                        name="input_year_cost.replacement_frequency_year"
                        widthClassName="max-w-[100px]"
                        validate={composeValidators(required, zeroOrMoreNumber)}
                      />
                      <Typography
                        className="min-w-[fit-content] px-[8px] text-[14px] text-black-800"
                        isBold
                      >
                        年に
                      </Typography>
                      <NumberField
                        name="input_year_cost.replacement_frequency_number"
                        widthClassName="max-w-[100px]"
                        validate={composeValidators(required, zeroOrMoreNumber)}
                      />
                      <Typography className="pl-[8px] text-[14px] text-black-800" isBold>
                        回
                      </Typography>
                    </div>
                  </div>

                  <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                    <Typography
                      className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                      isBold
                    >
                      帰省費用
                    </Typography>
                    <div className="flex flex-auto items-baseline">
                      <NumberField
                        name="input_year_cost.homecoming"
                        isFullWidth
                        validate={composeValidators(required, zeroOrMoreNumber)}
                      />
                      <Typography className="pl-[8px] text-[14px] text-black-800" isBold>
                        円
                      </Typography>
                    </div>
                  </div>

                  <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                    <Typography
                      className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                      isBold
                    >
                      帰省頻度
                    </Typography>
                    <div className="flex flex-auto justify-end items-baseline">
                      <NumberField
                        name="input_year_cost.homecoming_frequency_year"
                        widthClassName="max-w-[100px]"
                        validate={composeValidators(required, zeroOrMoreNumber)}
                      />
                      <Typography
                        className="min-w-[fit-content] px-[8px] text-[14px] text-black-800"
                        isBold
                      >
                        年に
                      </Typography>
                      <NumberField
                        name="input_year_cost.homecoming_frequency_number"
                        widthClassName="max-w-[100px]"
                        validate={composeValidators(required, zeroOrMoreNumber)}
                      />
                      <Typography className="pl-[8px] text-[14px] text-black-800" isBold>
                        回
                      </Typography>
                    </div>
                  </div>

                  <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                    <Typography
                      className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                      isBold
                    >
                      冠婚葬祭費用
                    </Typography>
                    <div className="flex flex-auto items-baseline">
                      <NumberField
                        name="input_year_cost.ceremonial_occasion_yearly"
                        isFullWidth
                        validate={composeValidators(required, zeroOrMoreNumber)}
                      />
                      <Typography className="pl-[8px] text-[14px] text-black-800" isBold>
                        円/年
                      </Typography>
                    </div>
                  </div>
                  <div className="hidden md:block" />

                  <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                    <Typography
                      className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                      isBold
                    >
                      旅行費用
                    </Typography>
                    <div className="flex flex-auto items-baseline">
                      <NumberField
                        name="input_year_cost.travel"
                        isFullWidth
                        validate={composeValidators(required, zeroOrMoreNumber)}
                      />
                      <Typography className="pl-[8px] text-[14px] text-black-800" isBold>
                        円
                      </Typography>
                    </div>
                  </div>

                  <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                    <Typography
                      className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                      isBold
                    >
                      旅行頻度
                    </Typography>
                    <div className="flex flex-auto justify-end items-baseline">
                      <NumberField
                        name="input_year_cost.travel_frequency_year"
                        widthClassName="max-w-[100px]"
                        validate={composeValidators(required, zeroOrMoreNumber)}
                      />
                      <Typography
                        className="min-w-[fit-content] px-[8px] text-[14px] text-black-800"
                        isBold
                      >
                        年に
                      </Typography>
                      <NumberField
                        name="input_year_cost.travel_frequency_number"
                        widthClassName="max-w-[100px]"
                        validate={composeValidators(required, zeroOrMoreNumber)}
                      />
                      <Typography className="pl-[8px] text-[14px] text-black-800" isBold>
                        回
                      </Typography>
                    </div>
                  </div>

                  <FieldArray name="input_year_cost.input_year_cost_others_attributes">
                    {({ fields }) =>
                      fields.map((name, index) => (
                        <div
                          key={name}
                          className="md:flex col-auto md:col-span-2 pb-[16px] border-black-300 border-dashed border-b-2 items-baseline"
                        >
                          {index === 0 ? (
                            <Typography
                              className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                              isBold
                            >
                              その他年間費用
                            </Typography>
                          ) : (
                            <div className="min-w-[140px] lg:min-w-[164px]" />
                          )}
                          <div className="flex flex-auto items-center">
                            <div className="md:flex flex-auto pr-[20px]">
                              <div className="flex-auto pb-[8px] md:pb-0">
                                <TextField
                                  type="text"
                                  name={`${name}journal_name`}
                                  isFullWidth
                                />
                              </div>
                              <div className="flex flex-auto items-baseline md:pl-[50px] lg:pl-[100px]">
                                <NumberField name={`${name}cost_yearly`} isFullWidth />
                                <Typography
                                  className="pl-[8px] text-[14px] text-black-800"
                                  isBold
                                >
                                  円/年
                                </Typography>
                              </div>
                            </div>
                            <Button onClick={() => fields.remove(index)}>
                              <CrossIcon />
                            </Button>
                          </div>
                        </div>
                      ))
                    }
                  </FieldArray>
                </Grid>

                <Button
                  className="w-full h-[53px] mb-[64px] border border-dashed border-primary-500 text-[14px] text-primary-500 hover:bg-primary-50"
                  onClick={() =>
                    push('input_year_cost.input_year_cost_others_attributes', {})
                  }
                >
                  <Typography isBold>追加</Typography>
                </Button>

                <WholeSubmitErrorMessage submitErrors={submitErrors} />
                <ButtonPrimary
                  className="w-[246px] h-[64px] mx-auto mb-[16px]"
                  isDisabled={Object.keys(errors).length > 0 || isLoading}
                  onClick={() => handleSubmit(values)}
                >
                  <Typography
                    className="relative bottom-[2px] pl-[30px] text-[16px] text-white flex-auto"
                    isBold
                  >
                    保存する
                  </Typography>
                  <ChevronRightIcon className="mr-[17px]" />
                </ButtonPrimary>
                <Button
                  className="mx-auto text-[14px] text-black-700"
                  onClick={() =>
                    handleMovePage(
                      isAdmin
                        ? `/v2/accounts/${account_id}/myData/monthlyCosts`
                        : '/v2/myData/monthlyCosts',
                    )
                  }
                  isDisabled={isLoading}
                >
                  キャンセル
                </Button>
              </form>
            )}
          />
        </Paper>
      </MarginContainer>
    </>
  )
}

export default MyDataYearCostEdit
