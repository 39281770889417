import ApiClient from './apiClient'
import {
  MtAccessTokenCreateValue,
  MtAccessTokenResponse,
  MtConnectingStatus,
} from '@/models/mtAccessTokenModel'

export const getMtCnnectingStatus = async () => {
  return await ApiClient.get(
    '/mt_access_tokens/mt_connecting_status',
    (response: MtConnectingStatus) => response,
  )
}

export const postRegistrationAccessToken = async (values: MtAccessTokenCreateValue) => {
  return await ApiClient.post(
    '/mt_access_tokens/registration_access_token',
    (response: MtAccessTokenResponse) => {
      return response
    },
    values,
  )
}

export const postMtAccessTokenDisconnect = async () => {
  return await ApiClient.post('/mt_access_tokens/disconnect', () => {})
}
