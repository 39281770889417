import ApiClient from '@/api/apiClient'
import { FutureV2HousingRentUpdateValues } from '@/models/futures/futureV2HousingRentsModel'
import { FutureV2HousingSelfOwnedUpdateValues } from '@/models/futures/futureV2HousingSelfOwnedModel'
import { FutureV2HousingOtherUpdateValues } from '@/models/futures/futureV2HousingOthersModel'

export const patchFutureV2HousingRent = async (
  values: FutureV2HousingRentUpdateValues,
) => {
  return await ApiClient.patch(
    '/future_v2_housings/update_future_v2_housing_with_rent',
    () => {},
    values,
  )
}

export const patchFutureV2HousingSelfOwned = async (
  values: FutureV2HousingSelfOwnedUpdateValues,
) => {
  return await ApiClient.patch(
    '/future_v2_housings/update_future_v2_housing_with_self_owned',
    () => {},
    values,
  )
}

export const patchFutureV2HousingOther = async (
  values: FutureV2HousingOtherUpdateValues,
) => {
  return await ApiClient.patch(
    '/future_v2_housings/update_future_v2_housing_with_other',
    () => {},
    values,
  )
}

export const postFutureV2HousingRentV2 = async (values: any) => {
  return await ApiClient.post(
    '/future_v2_housings/create_housing_for_rent',
    (response: any) => response,
    values,
    undefined,
    'v2',
  )
}

export const postFutureV2HousingOtherV2 = async (values: any) => {
  return await ApiClient.post(
    '/future_v2_housings/create_housing_for_other',
    (response: any) => response,
    values,
    undefined,
    'v2',
  )
}

export const postFutureV2HousingSelfOwnedV2 = async (values: any) => {
  return await ApiClient.post(
    '/future_v2_housings/create_housing_for_self_owned',
    (response: any) => response,
    values,
    undefined,
    'v2',
  )
}

export const deleteFutureV2HousingV2 = async (id: number) => {
  return await ApiClient.delete(
    `/future_v2_housings/${id}`,
    () => {},
    {},
    undefined,
    'v2',
  )
}
