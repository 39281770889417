import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { sortBy as _sortBy } from 'lodash'
import { unauthorizedCheck } from '../accountsSlice'
import {
  postFuturePlanTuitionV2,
  deleteFuturePlanTuitionV2,
} from '../../api/futures/futurePlanTuitions'
import { ApiError } from '../../models/commonsModel'
import { FutureFamilyChildbirth } from '../../models/futures/futureFamilyChildbirthsModel'
import { FuturePlanTuition } from '../../models/futures/futurePlanTuitionsModel'
import { AppThunk } from '../../store'

interface FuturePlanTuitionState {
  futurePlanTuitions: FuturePlanTuition[][]
  isLoading: boolean
}

const initialState: FuturePlanTuitionState = {
  futurePlanTuitions: [],
  isLoading: false,
}

const futurePlanTuitionsSlice = createSlice({
  name: 'futurePlanTuitions',
  initialState,
  reducers: {
    setFuturePlanTuition: (state, action: PayloadAction<FutureFamilyChildbirth[]>) => {
      const { payload } = action
      const sortedFutureFamilyChildbirths = _sortBy(payload, 'childbirth_index')

      state.futurePlanTuitions = sortedFutureFamilyChildbirths.map(
        (item) => item.future_plan_tuitions,
      )
      return state
    },
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
  },
})

export const {
  setFuturePlanTuition,
  requestStart,
  requestSuccess,
  requestFailure,
} = futurePlanTuitionsSlice.actions
export const futurePlanTuitionsReducer = futurePlanTuitionsSlice.reducer

export const createFuturePlanTuitionV2 = (
  values: any,
  resolve: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postFuturePlanTuitionV2(values)
    .then(() => {
      dispatch(requestSuccess())
      resolve({})
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error, resolve))
    })
}

export const destroyFuturePlanTuitionV2 = (
  values: any,
  resolve: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  deleteFuturePlanTuitionV2(values)
    .then(() => {
      dispatch(requestSuccess())
      resolve({})
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error, resolve))
    })
}
