import React, { FC, useEffect, useContext, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Typography from '@/components/v2/atoms/Typography'
import { setPageMeta } from '@/containers/pageMetasSlice'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import { RootState } from '@/store'
import MyDataListWrapper from '@/templates/v2/myData/_ListWrapper'
import { fetchAccount } from '@/containers/accountsSlice'
import { fetchFamilyStructureV2 } from '@/containers/familyStructuresSlice'
import {
  convertDateToTwoTypesYearsDateFormat,
  convertLocaleString,
  defaultToEmptyString,
} from '@/models/commonsModelFunc'
import {
  destroyInputMonthlyCost,
  fetchInputMonthlyCostV2,
} from '@/containers/inputs/inputMonthlyCostsSlice'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import Button from '@/components/v2/atoms/Button'
import { isNull as _isNull } from 'lodash'
import {
  fetchInputYearCost,
  destroyInputYearCost,
} from '@/containers/inputs/inputYearCostsSlice'
import { convertYearCost } from '@/models/inputs/inputYearCostsModelFunc'
import {
  destroyInputYearlyLivingCost,
  fetchInputYearlyLivingCostsV2,
} from '@/containers/inputs/inputYearlyLivingCostsSlice'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import { useParams } from 'react-router-dom'
import { AccountContext } from '@/models/accountsModelFunc'
import { useAccountControl } from '@/hooks/useAccountControl'
import moment from 'moment'

const MyDataMonthlyCost: FC = () => {
  const { showFlashMsg } = useFlashAlert()
  const dispatch = useDispatch()
  const { handleMovePage } = useCustomHistory()
  const { isAdmin } = useContext(AccountContext)
  const { isMyDataManipulatedApproval } = useAccountControl()
  const { account_id } = useParams<{ account_id?: string }>()

  const { inputMonthlyCost } = useSelector(
    (state: RootState) => state.inputMonthlyCosts,
  ) as any
  const { inputYearCost } = useSelector((state: RootState) => state.inputYearCosts)

  const {
    input_yearly_living_cost_electronics, // 家電
    input_yearly_living_cost_ceremonies, // 冠婚葬祭
    input_yearly_living_cost_home_comings, // 帰省
    input_yearly_living_cost_travels, // 旅行
  } = useSelector((state: RootState) => state.inputYearlyLivingCosts)

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'マイデータ | 基本生活費',
        description: 'マイデータ | 基本生活費',
        keyword: 'マイデータ | 基本生活費',
      }),
    )

    if (!inputMonthlyCost) dispatch(fetchInputMonthlyCostV2())
    if (!inputYearCost && input_yearly_living_cost_electronics.length == 0)
      dispatch(fetchInputYearCost())
    if (input_yearly_living_cost_electronics.length == 0)
      dispatch(fetchInputYearlyLivingCostsV2())
  }, [])

  // 基本生活費の削除
  const handleInputMonthlyCostDestroy = () => {
    const result = confirm('本当に削除してよろしいですか？')
    if (!result) return

    const destroySubIncomeFunc = new Promise((resolve) => {
      const newValues = { params: { id: inputMonthlyCost!.id } }
      dispatch(destroyInputMonthlyCost(newValues, () => resolve('')))
    })

    Promise.all([destroySubIncomeFunc]).then(() => {
      dispatch(fetchInputMonthlyCostV2())
      dispatch(fetchAccount())
      showFlashMsg('削除しました')
    })
  }

  const handleInputYearlyLivingCostDestroy = () => {
    const result = confirm('本当に削除してよろしいですか？')
    if (!result) return

    dispatch(
      destroyInputYearlyLivingCost(() => {
        dispatch(fetchInputYearlyLivingCostsV2())
        showFlashMsg('削除しました')
      }),
    )
  }

  const handleInputYearCostDestroy = () => {
    const result = confirm('本当に削除してよろしいですか？')
    if (!result) return

    const newValues = { params: { id: inputYearCost!.id } }
    dispatch(
      destroyInputYearCost(newValues, () => {
        dispatch(fetchInputYearCost())
        showFlashMsg('削除しました')
      }),
    )
  }

  const inputYearlyLivingCostUpdatedAt = useMemo(() => {
    const inputYearlyLivingCosts = [
      ...input_yearly_living_cost_electronics,
      ...input_yearly_living_cost_ceremonies,
      ...input_yearly_living_cost_home_comings,
      ...input_yearly_living_cost_travels,
    ]
    const unixTimes = inputYearlyLivingCosts.map((item) =>
      Number(moment(item?.updated_at).format('X')),
    )
    return unixTimes.length !== 0
      ? `(最終更新日: ${moment(String(Math.max(...unixTimes)), 'X').format(
          'YYYY/MM/DD',
        )})`
      : ''
  }, [
    input_yearly_living_cost_electronics,
    input_yearly_living_cost_ceremonies,
    input_yearly_living_cost_home_comings,
    input_yearly_living_cost_travels,
  ])

  const formatDate = (updatedAt?: string) =>
    updatedAt != null ? `(最終更新日: ${moment(updatedAt).format('YYYY/MM/DD')})` : ''

  return (
    <MyDataListWrapper title="基本生活費">
      <div className="mt-30">
        <div className="sm:flex block justify-between">
          <div className="flex flex-col md:flex-row gap-x-[8px] gap-y-[4px] items-baseline">
            <CircleTitle title="基本生活費" classNameTitle="text-[20px] md:text-[24px]" />
            <Typography className="text-[13px] md:text-[16px] text-[#9E9E9E]">
              {formatDate(inputMonthlyCost?.updated_at)}
            </Typography>
          </div>
          <div className="flex justify-end my-[10px]">
            {inputMonthlyCost && (
              <Button
                className="w-[84px] md:w-[112px] h-[26px] md:h-[37px] mr-[10px] rounded-[4px] bg-white border border-secondary-500 hover:bg-secondary-50"
                isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
                onClick={() =>
                  handleMovePage(
                    isAdmin
                      ? `/v2/accounts/${account_id}/myData/monthlyCosts/edit`
                      : '/v2/myData/monthlyCosts/edit',
                  )
                }
              >
                <Typography
                  className="relative bottom-[1px] text-[12px] md:text-[14px] text-secondary-500"
                  isBold
                >
                  編集する
                </Typography>
              </Button>
            )}

            {inputMonthlyCost && (
              <Button
                className="w-[84px] md:w-[112px] h-[26px] md:h-[37px] mr-0 md:mr-[9px] rounded-[4px] bg-white border border-black-600 hover:bg-secondary-50"
                isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
                onClick={() => handleInputMonthlyCostDestroy()}
              >
                <Typography
                  className="relative bottom-[1px] text-[12px] md:text-[14px] text-black-600"
                  isBold
                >
                  削除する
                </Typography>
              </Button>
            )}
          </div>
        </div>

        {!inputMonthlyCost && (
          <div className="md:pt-[20px]">
            <Typography
              className="pb-[15px] md:pb-[24px] text-[15px] md:text-[18px] text-center"
              isBold
            >
              基本生活費が登録されていません。
              <br className="md:hidden" />
              下記のボタンから登録してみましょう。
            </Typography>
            <Button
              className="w-[104px] md:w-[112px] h-[26px] md:h-[37px] rounded-[4px] mx-auto bg-white border border-secondary-500 hover:bg-secondary-50"
              isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
              onClick={() =>
                handleMovePage(
                  isAdmin
                    ? `/v2/accounts/${account_id}/myData/monthlyCosts/edit`
                    : '/v2/myData/monthlyCosts/edit',
                )
              }
            >
              <Typography
                className="relative bottom-[1px] text-[12px] md:text-[14px] text-secondary-500"
                isBold
              >
                新しく追加する
              </Typography>
            </Button>
          </div>
        )}

        {inputMonthlyCost && Number(inputMonthlyCost?.moneytree_average_months) > 0 && (
          <table className="w-[100%] text-[12px] md:text-[14px]">
            <tbody>
              <tr className="h-[45px] odd:bg-black-100">
                <td className="w-[40%] px-[16px] md:px-[30px]">
                  <Typography>基本生活費の設定方法</Typography>
                </td>
                <td className="w-[60%] px-[16px] md:px-[30px]">
                  <Typography>
                    {inputMonthlyCost.moneytree_average_months >= 12
                      ? `過去${
                          inputMonthlyCost.moneytree_average_months / 12
                        }年分の平均値を使用`
                      : `過去${inputMonthlyCost.moneytree_average_months}カ月分の平均値を使用`}
                  </Typography>
                </td>
              </tr>
            </tbody>
          </table>
        )}

        {inputMonthlyCost && !(Number(inputMonthlyCost?.moneytree_average_months) > 0) && (
          <table className="w-[100%] text-[12px] md:text-[14px]">
            <tbody>
              <tr className="h-[45px] odd:bg-black-100">
                <td className="w-[40%] px-[16px] md:px-[30px]">
                  <Typography>食費</Typography>
                </td>
                <td className="w-[60%] px-[16px] md:px-[30px]">
                  <Typography>{`${convertLocaleString(
                    inputMonthlyCost.food_expenses_monthly,
                  )}円/月`}</Typography>
                </td>
              </tr>

              <tr className="h-[45px] odd:bg-black-100">
                <td className="w-[40%] px-[16px] md:px-[30px]">
                  <Typography>水道光熱費</Typography>
                </td>
                <td className="w-[60%] px-[16px] md:px-[30px]">
                  <Typography>{`${convertLocaleString(
                    inputMonthlyCost.utility_bills_monthly,
                  )}円/月`}</Typography>
                </td>
              </tr>

              <tr className="h-[45px] odd:bg-black-100">
                <td className="w-[40%] px-[16px] md:px-[30px]">
                  <Typography>日用品雑貨</Typography>
                </td>
                <td className="w-[60%] px-[16px] md:px-[30px]">
                  <Typography>{`${convertLocaleString(
                    inputMonthlyCost.daily_necessities_cost_monthly,
                  )}円/月`}</Typography>
                </td>
              </tr>

              <tr className="h-[45px] odd:bg-black-100">
                <td className="w-[40%] px-[16px] md:px-[30px]">
                  <Typography>交通費</Typography>
                </td>
                <td className="w-[60%] px-[16px] md:px-[30px]">
                  <Typography>{`${convertLocaleString(
                    inputMonthlyCost.transport_monthly,
                  )}円/月`}</Typography>
                </td>
              </tr>

              <tr className="h-[45px] odd:bg-black-100">
                <td className="w-[40%] px-[16px] md:px-[30px]">
                  <Typography>被服費</Typography>
                </td>
                <td className="w-[60%] px-[16px] md:px-[30px]">
                  <Typography>{`${convertLocaleString(
                    inputMonthlyCost.clothing_cost_monthly,
                  )}円/月`}</Typography>
                </td>
              </tr>

              <tr className="h-[45px] odd:bg-black-100">
                <td className="w-[40%] px-[16px] md:px-[30px]">
                  <Typography>趣味・娯楽費</Typography>
                </td>
                <td className="w-[60%] px-[16px] md:px-[30px]">
                  <Typography>{`${convertLocaleString(
                    inputMonthlyCost.hobby_expenses_monthly,
                  )}円/月`}</Typography>
                </td>
              </tr>

              <tr className="h-[45px] odd:bg-black-100">
                <td className="w-[40%] px-[16px] md:px-[30px]">
                  <Typography>通信費</Typography>
                </td>
                <td className="w-[60%] px-[16px] md:px-[30px]">
                  <Typography>{`${convertLocaleString(
                    inputMonthlyCost.communication_cost_monthly,
                  )}円/月`}</Typography>
                </td>
              </tr>

              <tr className="h-[45px] odd:bg-black-100">
                <td className="w-[40%] px-[16px] md:px-[30px]">
                  <Typography>交際費</Typography>
                </td>
                <td className="w-[60%] px-[16px] md:px-[30px]">
                  <Typography>{`${convertLocaleString(
                    inputMonthlyCost.entertainment_expenses_monthly,
                  )}円/月`}</Typography>
                </td>
              </tr>

              <tr className="h-[45px] odd:bg-black-100">
                <td className="w-[40%] px-[16px] md:px-[30px]">
                  <Typography>医療費</Typography>
                </td>
                <td className="w-[60%] px-[16px] md:px-[30px]">
                  <Typography>{`${convertLocaleString(
                    inputMonthlyCost.medical_expenses_monthly,
                  )}円/月`}</Typography>
                </td>
              </tr>

              <tr className="h-[45px] odd:bg-black-100">
                <td className="w-[40%] px-[16px] md:px-[30px]">
                  <Typography>こづかい</Typography>
                </td>
                <td className="w-[60%] px-[16px] md:px-[30px]">
                  <Typography>{`${convertLocaleString(
                    inputMonthlyCost.pocket_money_monthly,
                  )}円/月`}</Typography>
                </td>
              </tr>

              {inputMonthlyCost.input_scholarships &&
                inputMonthlyCost.input_scholarships?.map((item: any, index: number) => (
                  <tr
                    className="h-[45px] odd:bg-black-100"
                    key={`monthly_cost_scholarships_${item.id}_${index}`}
                  >
                    <td className="w-[40%] px-[16px] md:px-[30px]">
                      <Typography>{index === 0 ? '奨学金' : ''}</Typography>
                    </td>
                    <td className="w-[60%] px-[16px] md:px-[30px]">
                      <Typography>
                        {`${convertDateToTwoTypesYearsDateFormat(
                          item.scholarship_finish_at,
                          'yearMonthWithJapaneseYear',
                        )}まで`}
                        <br /> {`${convertLocaleString(item.scholarship_monthly)}円/月`}
                      </Typography>
                    </td>
                  </tr>
                ))}

              {inputMonthlyCost.input_monthly_cost_others &&
                inputMonthlyCost.input_monthly_cost_others?.map(
                  (item: any, index: number) => (
                    <tr
                      className="h-[45px] odd:bg-black-100"
                      key={`monthly_cost_others_${item.id}_${index}`}
                    >
                      <td className="w-[40%] px-[16px] md:px-[30px]">
                        <Typography>{defaultToEmptyString(item.journal_name)}</Typography>
                      </td>
                      <td className="w-[60%] px-[16px] md:px-[30px]">
                        <Typography>{`${convertLocaleString(
                          item.cost_monthly,
                        )}円/月`}</Typography>
                      </td>
                    </tr>
                  ),
                )}

              <tr className="h-[45px] odd:bg-black-100">
                <td className="w-[40%] px-[16px] md:px-[30px]">
                  <Typography>未分類・使途不明金</Typography>
                </td>
                <td className="w-[60%] px-[16px] md:px-[30px]">
                  <Typography>{`${convertLocaleString(
                    inputMonthlyCost.unknown_cost_monthly,
                  )}円/月`}</Typography>
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </div>

      {!_isNull(inputYearCost) && (
        <div className="mt-30">
          <div className="md:flex justify-between">
            <div className="flex flex-col md:flex-row gap-x-[8px] gap-y-[4px] items-baseline">
              <CircleTitle
                title="特別な支出・大きな支出"
                classNameTitle="text-[20px] md:text-[24px]"
              />
              <Typography className="text-[13px] md:text-[16px] text-[#9E9E9E]">
                {inputYearlyLivingCostUpdatedAt}
              </Typography>
            </div>
            <div className="flex justify-end my-[10px]">
              {inputYearCost && (
                <Button
                  className="w-[84px] md:w-[112px] h-[26px] md:h-[37px] mr-[10px] rounded-[4px] bg-white border border-secondary-500 hover:bg-secondary-50"
                  isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
                  onClick={() =>
                    handleMovePage(
                      isAdmin
                        ? `/v2/accounts/${account_id}/myData/yearCosts/edit`
                        : '/v2/myData/yearCosts/edit',
                    )
                  }
                >
                  <Typography
                    className="relative bottom-[1px] text-[12px] md:text-[14px] text-secondary-500"
                    isBold
                  >
                    編集する
                  </Typography>
                </Button>
              )}

              {inputYearCost && (
                <Button
                  className="w-[84px] md:w-[112px] h-[26px] md:h-[37px] mr-0 md:mr-[9px] rounded-[4px] bg-white border border-black-600 hover:bg-secondary-50"
                  isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
                  onClick={() => handleInputYearCostDestroy()}
                >
                  <Typography
                    className="relative bottom-[1px] text-[12px] md:text-[14px] text-black-600"
                    isBold
                  >
                    削除する
                  </Typography>
                </Button>
              )}
            </div>
          </div>

          {!inputYearCost && (
            <div className="md:pt-[20px]">
              <Typography
                className="pb-[15px] md:pb-[24px] text-[15px] md:text-[18px] text-center"
                isBold
              >
                特別な支出・大きな支出が登録されていません。
                <br className="md:hidden" />
                下記のボタンから登録してみましょう。
              </Typography>
              <Button
                className="w-[104px] md:w-[112px] h-[26px] md:h-[37px] rounded-[4px] mx-auto bg-white border border-secondary-500 hover:bg-secondary-50"
                isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
                onClick={() =>
                  handleMovePage(
                    isAdmin
                      ? `/v2/accounts/${account_id}/myData/yearCosts/edit`
                      : '/v2/myData/yearCosts/edit',
                  )
                }
              >
                <Typography
                  className="relative bottom-[1px] text-[12px] md:text-[14px] text-secondary-500"
                  isBold
                >
                  新しく追加する
                </Typography>
              </Button>
            </div>
          )}

          <table className="w-[100%] mb-[60px] last:mb-0 text-[12px] md:text-[14px]">
            <tbody>
              <tr className="h-[45px] odd:bg-black-100">
                <td className="w-[30%] px-[16px] md:px-[30px]">
                  <Typography isBold>
                    家電の
                    <br className="block md:hidden" />
                    買い替え
                  </Typography>
                </td>
                <td className="w-[70%] px-[16px] md:px-[30px]">
                  <Typography>
                    {convertYearCost(
                      inputYearCost.replacement_appliances,
                      inputYearCost.replacement_frequency_year,
                      inputYearCost.replacement_frequency_number,
                    )}
                  </Typography>
                </td>
              </tr>
              <tr className="h-[45px] odd:bg-black-100">
                <td className="px-[16px] md:px-[30px]">
                  <Typography isBold>帰省</Typography>
                </td>
                <td className="px-[16px] md:px-[30px]">
                  <Typography>
                    {convertYearCost(
                      inputYearCost.homecoming,
                      inputYearCost.homecoming_frequency_year,
                      inputYearCost.homecoming_frequency_number,
                    )}
                  </Typography>
                </td>
              </tr>
              <tr className="h-[45px] odd:bg-black-100">
                <td className="px-[16px] md:px-[30px]">
                  <Typography isBold>冠婚葬祭</Typography>
                </td>
                <td className="px-[16px] md:px-[30px]">
                  <Typography>
                    {`年間${convertLocaleString(
                      inputYearCost.ceremonial_occasion_yearly,
                    )}円`}
                  </Typography>
                </td>
              </tr>
              <tr className="h-[45px] odd:bg-black-100">
                <td className="px-[16px] md:px-[30px]">
                  <Typography isBold>旅行</Typography>
                </td>
                <td className="px-[16px] md:px-[30px]">
                  <Typography>
                    {convertYearCost(
                      inputYearCost.travel,
                      inputYearCost.travel_frequency_year,
                      inputYearCost.travel_frequency_number,
                    )}
                  </Typography>
                </td>
              </tr>
              {inputYearCost.input_year_cost_others.map((item) => (
                <tr key={item.id} className="h-[45px] odd:bg-black-100">
                  <td className="px-[16px] md:px-[30px]">
                    <Typography isBold>
                      {defaultToEmptyString(item.journal_name)}
                    </Typography>
                  </td>
                  <td className="px-[16px] md:px-[30px]">
                    <Typography>
                      {`年間${convertLocaleString(item.cost_yearly)}円`}
                    </Typography>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {_isNull(inputYearCost) && (
        <React.Fragment>
          <div className="mt-30">
            <div className="md:flex justify-between">
              <div className="flex flex-col md:flex-row gap-x-[8px] gap-y-[4px] items-baseline">
                <CircleTitle
                  title="特別な支出・大きな支出"
                  classNameTitle="text-[20px] md:text-[24px]"
                />
                <Typography className="text-[13px] md:text-[16px] text-[#9E9E9E]">
                  {inputYearlyLivingCostUpdatedAt}
                </Typography>
              </div>
              <div className="flex justify-end my-[10px]">
                {(input_yearly_living_cost_electronics.length !== 0 ||
                  input_yearly_living_cost_home_comings.length !== 0 ||
                  input_yearly_living_cost_travels.length !== 0 ||
                  input_yearly_living_cost_ceremonies.length !== 0) && (
                  <Button
                    className="w-[84px] md:w-[112px] h-[26px] md:h-[37px] mr-[10px] rounded-[4px] bg-white border border-secondary-500 hover:bg-secondary-50"
                    isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
                    onClick={() =>
                      handleMovePage(
                        isAdmin
                          ? `/v2/accounts/${account_id}/myData/yearlyLivingCosts/edit`
                          : '/v2/myData/yearlyLivingCosts/edit',
                      )
                    }
                  >
                    <Typography
                      className="relative bottom-[1px] text-[12px] md:text-[14px] text-secondary-500"
                      isBold
                    >
                      編集する
                    </Typography>
                  </Button>
                )}

                {(input_yearly_living_cost_electronics.length !== 0 ||
                  input_yearly_living_cost_home_comings.length !== 0 ||
                  input_yearly_living_cost_travels.length !== 0 ||
                  input_yearly_living_cost_ceremonies.length !== 0) && (
                  <Button
                    className="w-[84px] md:w-[112px] h-[26px] md:h-[37px] mr-0 md:mr-[9px] rounded-[4px] bg-white border border-black-600 hover:bg-secondary-50"
                    isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
                    onClick={() => handleInputYearlyLivingCostDestroy()}
                  >
                    <Typography
                      className="relative bottom-[1px] text-[12px] md:text-[14px] text-black-600"
                      isBold
                    >
                      削除する
                    </Typography>
                  </Button>
                )}
              </div>
            </div>

            {input_yearly_living_cost_electronics.length === 0 &&
              input_yearly_living_cost_home_comings.length === 0 &&
              input_yearly_living_cost_travels.length === 0 &&
              input_yearly_living_cost_ceremonies.length === 0 && (
                <div className="md:pt-[20px]">
                  <Typography
                    className="pb-[15px] md:pb-[24px] text-[15px] md:text-[18px] text-center"
                    isBold
                  >
                    特別な支出・大きな支出が登録されていません。
                    <br className="md:hidden" />
                    下記のボタンから登録してみましょう。
                  </Typography>
                  <Button
                    className="w-[104px] md:w-[112px] h-[26px] md:h-[37px] rounded-[4px] mx-auto bg-white border border-secondary-500 hover:bg-secondary-50"
                    isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
                    onClick={() =>
                      handleMovePage(
                        isAdmin
                          ? `/v2/accounts/${account_id}/myData/yearlyLivingCosts/edit`
                          : '/v2/myData/yearlyLivingCosts/edit',
                      )
                    }
                  >
                    <Typography
                      className="relative bottom-[1px] text-[12px] md:text-[14px] text-secondary-500"
                      isBold
                    >
                      新しく追加する
                    </Typography>
                  </Button>
                </div>
              )}

            {input_yearly_living_cost_electronics.length !== 0 && (
              <>
                <Typography className="border-b-[0.5px] pb-[5px] md:mb-[10px]">
                  家電
                </Typography>
                <table className="w-[100%] mb-[60px] last:mb-0 text-[14px]">
                  <tbody>
                    <tr className="hidden md:table-row h-[45px] odd:bg-black-100">
                      <td className="w-full md:w-[25%] px-[16px] md:px-[30px]">
                        <Typography></Typography>
                      </td>
                      <td className="hidden md:table-cell w-[25%] px-[16px] md:px-[30px]">
                        <Typography isBold>次回買い替え時期</Typography>
                      </td>
                      <td className="hidden md:table-cell w-[25%] px-[16px] md:px-[30px]">
                        <Typography isBold>買い替え頻度</Typography>
                      </td>
                      <td className="hidden md:table-cell w-[25%] px-[16px] md:px-[30px]">
                        <Typography isBold>1回あたりの費用</Typography>
                      </td>
                    </tr>
                    {input_yearly_living_cost_electronics.map(
                      (item: any, index: number) => {
                        return (
                          item.is_active && (
                            <tr
                              className="h-[45px] odd:bg-black-100 border-b-[0.5px] md:border-none"
                              key={`yearly_electronics_${item.id}_${index}`}
                            >
                              <td className="w-full md:w-[25%] block md:table-cell md:px-[30px] mb-[10px] mt-[20px]">
                                <Typography className="md:font-bold">
                                  {item.display_name}
                                </Typography>
                              </td>
                              <td className="w-full md:w-[25%] block md:table-cell md:px-[30px] my-[10px] ml-[10px]">
                                <div className="flex">
                                  <Typography isBold className="block md:hidden w-[50%]">
                                    次回買い替え時期
                                  </Typography>
                                  <Typography>
                                    ご本人が{item.next_purchase_at_age}歳の時
                                  </Typography>
                                </div>
                              </td>
                              <td className="w-full md:w-[25%] block md:table-cell md:px-[30px] my-[10px] ml-[10px]">
                                <div className="flex">
                                  <Typography isBold className="block md:hidden w-[50%]">
                                    買い替え頻度
                                  </Typography>
                                  <Typography>{item.occurance_in_years}年ごと</Typography>
                                </div>
                              </td>
                              <td className="w-full md:w-[25%] block md:table-cell md:px-[30px] mt-[10px] mb-[20px]  ml-[10px]">
                                <div className="flex">
                                  <Typography isBold className="block md:hidden w-[50%]">
                                    1回あたりの費用
                                  </Typography>
                                  <Typography>
                                    {item.single_cost.toLocaleString()}円
                                  </Typography>
                                </div>
                              </td>
                            </tr>
                          )
                        )
                      },
                    )}
                  </tbody>
                </table>

                <Typography styleType="underlinedHeaderSmall">冠婚葬祭</Typography>
                <table className="w-[100%] mb-[60px] last:mb-0 text-[14px]">
                  <tbody>
                    <tr className="h-[45px] odd:bg-black-100 border-b-[0.5px] md:border-none">
                      <td className="w-[50%] px-[16px] md:px-[30px]">
                        <Typography isBold>ご本人の年齢</Typography>
                      </td>
                      <td className="w-[50%] px-[16px] md:px-[30px]">
                        <Typography className="min-w-[max-content]" isBold>
                          冠婚葬祭にかかる費用
                        </Typography>
                      </td>
                    </tr>

                    {input_yearly_living_cost_ceremonies.map(
                      (item: any, index: number) => (
                        <tr
                          className="h-[45px] odd:bg-black-100"
                          key={`yearly_ceremonies_${item.id}_${index}`}
                        >
                          <td className="w-[50%] px-[16px] md:px-[30px]">
                            <Typography>
                              {item.affected_start_at_age}歳から{item.affected_end_at_age}
                              歳
                            </Typography>
                          </td>
                          <td className="w-[50%] px-[16px] md:px-[30px]">
                            <Typography>
                              {item.yearly_cost.toLocaleString()}円/年
                            </Typography>
                          </td>
                        </tr>
                      ),
                    )}
                  </tbody>
                </table>

                <Typography styleType="underlinedHeaderSmall">帰省</Typography>
                <table className="w-[100%] mb-[60px] last:mb-0 text-[12px] md:text-[14px]">
                  <tbody>
                    {input_yearly_living_cost_home_comings.map(
                      (item: any, index: number) => (
                        <React.Fragment key={`yearly_home_comings_${item.id}_${index}`}>
                          <tr className="h-[45px] odd:bg-black-100">
                            <td className="w-[50%] px-[16px] md:px-[30px]">
                              <Typography isBold>費用</Typography>
                            </td>
                            <td className="w-[50%] px-[16px] md:px-[30px]">
                              <Typography>
                                {item.single_cost.toLocaleString()}円
                              </Typography>
                            </td>
                          </tr>
                          <tr className="h-[45px] odd:bg-black-100">
                            <td className="w-[50%] px-[16px] md:px-[30px]">
                              <Typography isBold>頻度</Typography>
                            </td>
                            <td className="w-[50%] px-[16px] md:px-[30px]">
                              <Typography>
                                {item.occurance_term_in_years}年に
                                {item.occurance_per_occurance_term}回
                              </Typography>
                            </td>
                          </tr>
                          <tr className="h-[45px] odd:bg-black-100">
                            <td className="w-[50%] px-[16px] md:px-[30px]">
                              <Typography isBold>適用頻度</Typography>
                            </td>
                            <td className="w-[50%] px-[16px] md:px-[30px]">
                              <Typography>
                                {item.affected_start_at_age}歳から
                                {item.affected_end_at_age}
                                歳まで
                              </Typography>
                            </td>
                          </tr>
                        </React.Fragment>
                      ),
                    )}
                  </tbody>
                </table>

                <Typography styleType="underlinedHeaderSmall">旅行</Typography>
                <table className="w-[100%] mb-[60px] last:mb-0 text-[14px]">
                  <tbody>
                    {input_yearly_living_cost_travels.map((item: any, index: number) => (
                      <React.Fragment key={`yearly_travels_${item.id}_${index}`}>
                        <tr className="h-[45px] odd:bg-black-100">
                          <td className="w-[50%] px-[16px] md:px-[30px]">
                            <Typography isBold>費用</Typography>
                          </td>
                          <td className="w-[50%] px-[16px] md:px-[30px]">
                            <Typography>{item.single_cost.toLocaleString()}円</Typography>
                          </td>
                        </tr>
                        <tr className="h-[45px] odd:bg-black-100">
                          <td className="w-[50%] px-[16px] md:px-[30px]">
                            <Typography isBold>頻度</Typography>
                          </td>
                          <td className="w-[50%] px-[16px] md:px-[30px]">
                            <Typography>
                              {item.occurance_term_in_years}年に
                              {item.occurance_per_occurance_term}回
                            </Typography>
                          </td>
                        </tr>
                        <tr className="h-[45px] odd:bg-black-100">
                          <td className="w-[50%] px-[16px] md:px-[30px]">
                            <Typography isBold>適用頻度</Typography>
                          </td>
                          <td className="w-[50%] px-[16px] md:px-[30px]">
                            <Typography>
                              {item.affected_start_at_age}歳から{item.affected_end_at_age}
                              歳まで
                            </Typography>
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </>
            )}
          </div>
        </React.Fragment>
      )}
    </MyDataListWrapper>
  )
}

export default MyDataMonthlyCost
