import { filter as _filter, some as _some, isNull as _isNull } from 'lodash'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useParams } from 'react-router-dom'

import Banner from '@/assets//images/v2/banner.png'
import ScrollToTopOnInit from '@/components/v2/atoms/ScrollToTopOnInit'
import Typography from '@/components/v2/atoms/Typography'
import { setPageMeta } from '@/containers/pageMetasSlice'
import { fetchLearningVideoCompany } from '@/containers/videoPlatforms/learningVideoCompaniesSlice'
import {
  fetchLearningVideo,
  fetchLearningVideos,
} from '@/containers/videoPlatforms/learningVideosSlice'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import { LearningVideo } from '@/models/commonLearningVideosModel'
import { isNotEmptyValue } from '@/models/commonsModelFunc'
import { RootState } from '@/store'
import MoviePreviewBody from '@/templates/v2/movies/_moviePreviewBody'
import MoviesAlertModal from '@/templates/v2/movies/MoviesAlertModal'
import LimitedMoviesAlertModal from '@/templates/v2/movies/LimitedMoviesAlertModal'
import { isSmartPhone } from '@/utils/userAgent'

const MoviePreview: FC = () => {
  const { handleMovePage } = useCustomHistory()
  const dispatch = useDispatch()
  const { movie_id } = useParams<{ movie_id: string }>()
  const movieId = Number(movie_id)
  const { learningVideo, learningVideos } = useSelector(
    (state: RootState) => state.videoLearningVideos,
  )
  const strictedLearningVideos = learningVideos.filter((learningVideo) => {
    return learningVideo.display_status == 'only_list'
  })
  const viewableLearningVideos = learningVideos.filter((learningVideo) => {
    return learningVideo.display_status == 'viewable'
  })
  const { LearningVideoCompany } = useSelector(
    (state: RootState) => state.learningVideoCompanies,
  )
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [isLimitedAlertModalOpen, setIsLimitedAlertModalOpen] = useState<boolean>(false)
  const [selectedLearnigVideo, setSelectedLearnigVideo] = useState(learningVideo)

  useEffect(() => {
    if (selectedLearnigVideo) {
      dispatch(fetchLearningVideo(movieId))
    }
  }, [selectedLearnigVideo])

  useEffect(() => {
    dispatch(fetchLearningVideo(movieId))

    if (isSmartPhone()) {
      ;(document.getElementsByTagName('html')[0].style as any).zoom = ''
    }
  }, [])

  useEffect(() => {
    if (!isNotEmptyValue(learningVideo)) return

    if (!learningVideo!.is_allowed_watching) {
      setIsLimitedAlertModalOpen(true)
    }
  }, [learningVideo])

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: learningVideo
          ? `${learningVideo.title} ${String(learningVideo.subtitle)}`
          : '動画再生ページ',
        description: learningVideo ? learningVideo.description : '動画再生ページ',
        keyword: '動画再生ページ',
      }),
    )
    dispatch(fetchLearningVideoCompany())

    if (isNotEmptyValue(learningVideo)) {
      dispatch(fetchLearningVideos())
    }
  }, [learningVideo])

  const previewedVideoCategories = learningVideo?.learning_video_categories.map(
    (item) => {
      return item.id
    },
  )

  const nextLearningVideos = useMemo(() => {
    let nextLearningVideos = _filter(learningVideos, (item) =>
      _some(item.learning_video_categories, (item) =>
        previewedVideoCategories?.includes(item.id),
      ),
    )
    if (nextLearningVideos.length === 0) {
      nextLearningVideos = _filter(learningVideos, (item) => item.id !== movieId)
    }
    return nextLearningVideos
  }, [learningVideos])

  const handleClickedLearnigVideo = (nextLearningVideo: LearningVideo) => {
    handleMovePage(`/movies/${nextLearningVideo.id}/preview`)
    setSelectedLearnigVideo(nextLearningVideo)
  }

  if (
    (_isNull(LearningVideoCompany) && _isNull(learningVideo)) ||
    learningVideo?.display_status == 'only_list'
  ) {
    return <Redirect to="/movies/list?redirected" />
  }

  return (
    <>
      {!_isNull(learningVideo) ? (
        <div className="tailwind">
          <ScrollToTopOnInit />

          <div className="grid grid-cols-9 max-w-screen-xl mx-auto w-full p-30">
            <div className="col-span-9 lg:col-span-6 ">
              <div className="mx-15">
                <MoviePreviewBody learningVideo={learningVideo} />
              </div>
              <div>
                {viewableLearningVideos.length > 0 ? (
                  <>
                    <div className="grid grid-cols-6 max-w-screen-xl mx-auto w-full mt-30">
                      <p className="font-bold mt-0 mb-26 mx-15 lg:mr pb-10 text-black-800 border-b-3 border-black-300 border-solid col-span-6">
                        その他のおすすめ動画
                      </p>
                      <div className="col-span-6">
                        <div className="grid  sm:grid-cols-12 grid-cols-6">
                          {viewableLearningVideos.map((item) => (
                            <div
                              key={item.id}
                              className="cursor-pointer mt-0 mx-15 mb-30 col-span-6 active:bg-gray-200 hover:bg-gray-200"
                              onClick={() => handleClickedLearnigVideo(item)}
                            >
                              <div className="relative w-full pt-56.25">
                                <img
                                  className="border border-gray-300 w-full h-full absolute top-0"
                                  src={item.thumbnail_url}
                                  alt="thumbnail"
                                />
                              </div>

                              <p className="my-2.5 mx-0 font-bold text-black-900 text-18 overflow-hidden w-full">
                                {item.title}
                              </p>
                              <p className="text-16 mb-2.5 text-black-800">
                                {item.subtitle}
                              </p>
                              <p className="text-12 text-justify text-black-700">
                                {item.description}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {learningVideo &&
                      learningVideo.ordered_related_learning_videos.length > 0 && (
                        <div className="grid grid-cols-6 max-w-screen-xl mx-auto w-full mt-30">
                          <p className="font-bold mt-0 mb-26 mx-15 lg:mr pb-10 text-black-800 border-b-3 border-black-300 border-solid col-span-6">
                            その他のおすすめ動画
                          </p>
                          <div className="col-span-6">
                            <div className="grid  sm:grid-cols-12 grid-cols-6">
                              {learningVideo.ordered_related_learning_videos.map(
                                (item) => (
                                  <div
                                    key={item.id}
                                    className="cursor-pointer mt-0 mx-15 mb-30 col-span-6 active:bg-gray-200 hover:bg-gray-200"
                                    onClick={() => handleClickedLearnigVideo(item)}
                                  >
                                    <div className="relative w-full pt-56.25">
                                      <img
                                        className="border border-gray-300 w-full h-full absolute top-0"
                                        src={item.thumbnail_url}
                                        alt="thumbnail"
                                      />
                                    </div>

                                    <p className="my-2.5 mx-0 font-bold text-black-900 text-18 overflow-hidden w-full">
                                      {item.title}
                                    </p>
                                    <p className="text-16 mb-2.5 text-black-800">
                                      {item.subtitle}
                                    </p>
                                    <p className="text-12 text-justify text-black-700">
                                      {item.description}
                                    </p>
                                  </div>
                                ),
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                  </>
                )}
              </div>
            </div>
            <div className="col-span-9 mt-30 lg:col-span-3 lg:mt-0 lg:pl-25">
              <img
                className="mb-40 w-full cursor-pointer hover:opacity-80"
                src={Banner}
                alt="movieBanner"
                onClick={() => {
                  window.open(
                    `https://app.moneysol.jp/accounts/new/?registration_code=${LearningVideoCompany?.registration_code}`,
                  )
                }}
              />
              {strictedLearningVideos.length > 0 ? (
                <>
                  <Typography
                    className="text-[16px] mb-[20px] justify-center text-black-900 flex"
                    isBold
                  >
                    有料ご契約されると以下の動画も閲覧できます
                  </Typography>
                  {strictedLearningVideos.map((item) => (
                    <div className="cursor-pointer" onClick={() => setIsModalOpen(true)}>
                      <Typography
                        className="text-[14px] justify-center text-white bg-black-900 flex relative top-[115px] z-[35] text-center w-[120px] mx-auto p-[5px] mt-[-77px]"
                        isBold
                      >
                        有料会員のみ
                      </Typography>
                      <div className=" bg-[rgba(0,0,0,0.6)] opacity-[66%] z-30 w-auto h-[67px] relative top-[68px] "></div>
                      <div
                        className="flex mb-3.5 hover:opacity-80 cursor-pointer relative z-10"
                        key={item.id}
                      >
                        <img
                          className="border border-solid border-gray-200 h-67 w-120"
                          src={item.thumbnail_url}
                          alt="thumbnail"
                        />
                        <div className="pl-8 font-bold text-14 text-black-900 font-default flex-[1]">{`${item.title}`}</div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  {nextLearningVideos.map((item) => (
                    <div
                      className="flex mb-3.5 hover:opacity-80 cursor-pointer"
                      key={item.id}
                      onClick={() => handleClickedLearnigVideo(item)}
                    >
                      <div className="">
                        <img
                          className="border border-solid border-gray-200 h-67 w-120"
                          src={item.thumbnail_url}
                          alt="thumbnail"
                        />
                      </div>
                      <div className="pl-8 font-bold text-14 text-black-900 font-default flex-[1]">{`${item.title}`}</div>
                    </div>
                  ))}
                </>
              )}
            </div>

            <MoviesAlertModal
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
            />
            <LimitedMoviesAlertModal
              isOpen={isLimitedAlertModalOpen}
              onClose={() => {
                setIsLimitedAlertModalOpen(false)
                handleMovePage('/movies/list')
              }}
            />
          </div>
        </div>
      ) : (
        <Redirect to="/movies/list" />
      )}
    </>
  )
}

export default MoviePreview
