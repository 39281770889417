import ApiClient from '@/api/apiClient'
import {
  FutureBaseResponse,
  DetailFutureBaseResponse,
  ApplicableLoanResponse,
} from '@/models/futures/futureBasesModel'

export const getFutureBase = async (futureBaseId: number) => {
  return await ApiClient.get(
    `/future_bases/${futureBaseId}`,
    (response: FutureBaseResponse) => response,
  )
}

export const getDetailFutureBaseV2 = async (futureBaseId: number) => {
  return await ApiClient.get(
    `/future_bases/show_detail`,
    (response: DetailFutureBaseResponse) => response,
    { params: { id: futureBaseId } },
    undefined,
    'v2',
  )
}

export const getDetailFutureBaseV2Insurance = async (
  futureBaseId: number,
  insuranceType: string,
) => {
  return await ApiClient.get(
    `/future_bases/show_detail`,
    (response: DetailFutureBaseResponse) => response,
    { params: { id: futureBaseId, insurance_type: insuranceType } },
    undefined,
    'v2',
  )
}

export const getFutureBaseApplicableHousingsUnsavedV2 = async () => {
  return await ApiClient.post(
    `/future_bases/show_applicable_housings_unsaved`,
    (response: ApplicableLoanResponse) => response,
    {},
    undefined,
    'v2',
  )
}

export const getFutureBaseApplicableLoans = async (futureBaseId: number) => {
  return await ApiClient.get(
    `/future_bases/${futureBaseId}/show_applicable_loans`,
    (response: ApplicableLoanResponse) => response,
    { params: { id: futureBaseId } },
  )
}

export const getFutureBaseApplicableLoansUnsavedV2 = async () => {
  return await ApiClient.post(
    `/future_bases/show_applicable_loans_unsaved`,
    (response: ApplicableLoanResponse) => response,
    {},
    undefined,
    'v2',
  )
}

export const postFutureBaseV2 = async (values: any) => {
  return await ApiClient.post(
    '/future_bases',
    (response: any) => response,
    values,
    false,
    'v2',
  )
}

export const patchFutureBaseV2 = async (futureBaseId: number, values: any) => {
  const path = `/future_bases/${futureBaseId}`
  return await ApiClient.patch(path, () => {}, values, false, 'v2')
}

export const postDetailUnsavedFutureBase = async (values: any) => {
  return await ApiClient.post(
    '/future_bases/show_detail_unsaved',
    (response: any) => response,
    values,
    false,
    'v2',
  )
}

export const patchMainPlan = async (futureBaseId: number) => {
  return await ApiClient.patch(
    `/future_bases/${futureBaseId}/update_main_plan`,
    () => {},
    futureBaseId,
  )
}

export const deleteFutureBase = async (futureBaseId: number) => {
  return await ApiClient.delete(`/future_bases/${futureBaseId}`, () => {})
}

export const getFutureBasesV2 = async () => {
  return await ApiClient.get(
    `/future_bases`,
    (response: any) => response,
    undefined,
    undefined,
    'v2',
  )
}
