import { isUndefined as _isUndefined } from 'lodash'

export const getRootElement = (isAdmin?: boolean) => {
  const element = document.getElementById(isAdmin ? 'admin-content' : 'js-content')
  return element
}

export const isEnableHeightScroll = (className: string) => {
  const { clientHeight } = document.getElementById(className) || {}
  const { scrollHeight } = document.getElementById(className) || {}

  if (_isUndefined(clientHeight) || _isUndefined(scrollHeight)) return false
  return scrollHeight - clientHeight > 0
}
