import React, { FC, useEffect, useState, useMemo, useContext, ChangeEvent } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form } from 'react-final-form'
import {
  reduce as _reduce,
  filter as _filter,
  maxBy as _maxBy,
  minBy as _minBy,
  sortBy as _sortBy,
} from 'lodash'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import Checkbox from '@/components/v2/atoms/Checkbox'
import Typography from '@/components/v2/atoms/Typography'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import GraphComposed from '@/components/v2/molecules/GraphComposed'
import renderCustomArea from '@/components/v2/molecules/renderCustomArea'
import renderCustomBar from '@/components/v2/molecules/renderCustomBar'
import renderCustomLine from '@/components/v2/molecules/renderCustomLine'
import SelectBox, { SelectBoxOption } from '@/components/v2/molecules/SelectBox'
import Tab from '@/components/v2/molecules/Tab'
import SelectBoxWithoutFinalForm from '@/components/v2/molecules/SelectBoxWithoutFinalForm'
import {
  fetchNewDetailFutureBase,
  fetchNewAdminDetailFutureBase,
} from '@/containers/futures/futureBasesSlice'
import { useRechartsActiveInfo } from '@/hooks/useRecharts'
import { AccountContext } from '@/models/accountsModelFunc'
import { RootState } from '@/store'
import TooltipBalance from '@/templates/v2/simulations/_tooltipBalance'
import TooltipInvestment from '@/templates/v2/simulations/_tooltipInvestment'
import TooltipLoan from '@/templates/v2/simulations/_tooltipLoan'
import TooltipNetAsset from '@/templates/v2/simulations/_tooltipNetAsset'
import TooltipRisk from '@/templates/v2/simulations/_tooltipRisk'
import Modal from '@/components/v2/atoms/Modal'
import {
  getBalanceGraphTicks,
  getInsuranceGraphTicks,
  getInvestmentGraphTicks,
  getLoanGraphTicks,
  getMaxSizeEstateEvaluationObject,
  getMaxSizeEstateRemainLoanObject,
  getNetAssetGraphTicks,
} from '@/utils/graph'

export const SIMULATION_GRAPH_TAB_INDEX = {
  investment: 0,
  netAsset: 1,
  loan: 2,
  balance: 3,
  risk: 4,
}

export const ESTATE_EVALUATION_COLOR = [
  '#DDE283',
  '#f4f9a2',
  '#FAFCDA',
  '#F4F6C2',
  '#FAFCDA',
  '#FAFCDA',
  '#FAFCDA',
  '#FAFCDA',
  '#FAFCDA',
  '#FAFCDA',
]
export const ESTATE_REMAIN_LOANS_COLOR = [
  '#ADD6F0',
  '#c6e5f9',
  '#EBF7FF',
  '#D6EFFF',
  '#EBF7FF',
  '#EBF7FF',
  '#EBF7FF',
  '#EBF7FF',
  '#EBF7FF',
  '#EBF7FF',
]

const MY_DATA_VALUE = 0

interface SimulationGraphBodyProps {
  /** クラス名 */
  className?: string
  /** true: 比較有り, false: 比較無し */
  isCompare: boolean
  /** true: マイデータ, false: シミュレーションプラン */
  isCompareMyData: boolean
  /** 比較フラグ変更メソッド */
  setIsCompare: (isCompare: boolean) => void
  /** マイデータフラグ変更メソッド */
  setIsCompareMyData: (isCompareMyData: boolean) => void
  /** 保険グラフ表示フラグ変更メソッド */
  setIsShowInsurance: (isShowInsurance: boolean) => void
}

const SimulationGraphBody: FC<SimulationGraphBodyProps> = (props) => {
  const dispatch = useDispatch()
  const { isAdmin } = useContext(AccountContext)

  const {
    className,
    isCompare,
    isCompareMyData,
    setIsCompare,
    setIsCompareMyData,
    setIsShowInsurance,
  } = props
  const { futureBasesV2, detailFutureBase, detailFutureBaseV2, isLoading } = useSelector(
    (state: RootState) => state.futureBases,
  )

  const { familyInfoV2 } = useSelector((state: RootState) => state.familyStructures)

  const [activeInfo, setActiveInfo, resetActiveInfo] = useRechartsActiveInfo()
  const [breakpoint, setBreakpoint] = useState<string>('lg')
  const [comparePlanName, setComparePlanName] = useState<any>(null)
  const [selectedAge, setSelectedAge] = useState<number | ''>('')
  const [currentTabIndex, setCurrentTabIndex] = useState<number>(
    SIMULATION_GRAPH_TAB_INDEX.investment,
  )

  const futureBaseIdOptions = useMemo(() => {
    const myDataOptions = [{ value: MY_DATA_VALUE, label: 'マイデータ' }]
    const planOptions = futureBasesV2.map((item) => ({
      value: item.id,
      label: item.future_name || '（プラン名未設定）',
    }))

    return [...myDataOptions, ...planOptions]
  }, [futureBasesV2])

  const ageOptions = useMemo(
    () =>
      _reduce(
        detailFutureBaseV2.v2_investment_graph,
        (result: SelectBoxOption[], item) => {
          if (item.age % 10 === 0) {
            result.push({ value: item.age, label: `${item.age}歳まで` })
          }
          return result
        },
        [],
      ),
    [detailFutureBaseV2],
  )

  const simulationGraphs = useMemo(() => {
    const isSelectAge = selectedAge !== ''
    const {
      v2_investment_graph,
      net_asset_graph,
      loan_graph,
      v2_balance_graph,
      insurance_graph,
    } = detailFutureBaseV2

    let returnValue: any = {
      v2InvestmentGraph: isSelectAge
        ? _filter(v2_investment_graph, (item) => item.age <= selectedAge)
        : v2_investment_graph,
      netAssetGraph: isSelectAge
        ? _filter(net_asset_graph, (item) => item.age <= selectedAge)
        : net_asset_graph,
      loanGraph: isSelectAge
        ? _filter(loan_graph, (item) => item.age <= selectedAge)
        : loan_graph,
      v2BalanceGraph: isSelectAge
        ? _filter(v2_balance_graph, (item) => item.age <= selectedAge)
        : v2_balance_graph,
      insuranceGraph: isSelectAge
        ? _filter(insurance_graph, (item) => item.age <= selectedAge)
        : insurance_graph,
    }

    if (!isCompare) {
      return returnValue
    }

    const targetV2InvestmentGraph = isCompareMyData
      ? detailFutureBaseV2.input_v2_investment_graph
      : detailFutureBase.v2_investment_graph

    const blankYearObjArr = targetV2InvestmentGraph.reduce((acc: any[], data: any) => {
      if (data.name < new Date().getFullYear()) {
        acc.push({
          name: data.name,
          age: data.age,
        })
      }
      return acc
    }, [])

    Object.keys(returnValue).forEach((key: any) => {
      returnValue[key].unshift(...blankYearObjArr)
    })
    return returnValue
  }, [selectedAge, detailFutureBaseV2, detailFutureBase, isCompareMyData, isCompare])

  const compareSimulationGraphs = useMemo(() => {
    const isSelectAge = selectedAge !== ''
    const {
      v2_investment_graph,
      net_asset_graph,
      loan_graph,
      v2_balance_graph,
      insurance_graph,
    } = detailFutureBase

    const {
      input_v2_investment_graph,
      input_net_asset_graph,
      input_loan_graph,
      input_v2_balance_graph,
      input_insurance_graph,
    } = detailFutureBaseV2

    const targetV2InvestmentGraph = isCompareMyData
      ? input_v2_investment_graph
      : v2_investment_graph
    const targetNetAssetGraph = isCompareMyData ? input_net_asset_graph : net_asset_graph
    const targetLoanGraph = isCompareMyData ? input_loan_graph : loan_graph
    const targetV2BalanceGraph = isCompareMyData
      ? input_v2_balance_graph
      : v2_balance_graph
    const targetInsuranceGraph = isCompareMyData ? input_insurance_graph : insurance_graph

    return {
      v2InvestmentGraph: isSelectAge
        ? _filter(targetV2InvestmentGraph, (item) => item.age <= selectedAge)
        : targetV2InvestmentGraph,
      netAssetGraph: isSelectAge
        ? _filter(targetNetAssetGraph, (item) => item.age <= selectedAge)
        : targetNetAssetGraph,
      loanGraph: isSelectAge
        ? _filter(targetLoanGraph, (item) => item.age <= selectedAge)
        : targetLoanGraph,
      v2BalanceGraph: isSelectAge
        ? _filter(targetV2BalanceGraph, (item) => item.age <= selectedAge)
        : targetV2BalanceGraph,
      insuranceGraph: isSelectAge
        ? _filter(targetInsuranceGraph, (item) => item.age <= selectedAge)
        : targetInsuranceGraph,
    }
  }, [selectedAge, detailFutureBase, detailFutureBaseV2, isCompareMyData])

  const graphLength = simulationGraphs.v2InvestmentGraph?.length

  const handleWindowResize = () => {
    let breakpoint = ''

    if (window.innerWidth >= 1024) {
      breakpoint = 'lg'
    } else if (window.innerWidth >= 768) {
      breakpoint = 'md'
    } else {
      breakpoint = 'sm'
    }

    setBreakpoint(breakpoint)
  }

  const handleClickAway = () => {
    resetActiveInfo()
  }

  useEffect(() => {
    handleWindowResize()
    window.addEventListener('resize', handleWindowResize)
    document.addEventListener('mousedown', handleClickAway)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
      document.removeEventListener('mousedown', handleClickAway)
    }
  }, [])

  useEffect(() => {
    const personAge = familyInfoV2?.person_age

    if (personAge) {
      const minimumRenderingYears = 40
      let targetAge = 80
      let renderingYears = targetAge - personAge

      if (renderingYears < minimumRenderingYears) {
        // 1の位を四捨五入する
        targetAge = Math.ceil((personAge + minimumRenderingYears) / 10) * 10
        // 上限は110歳
        targetAge = Math.min(targetAge, 110)
      }

      setSelectedAge(targetAge)
    }
  }, [familyInfoV2])

  const maxSizeEstateEvaluationObject = useMemo(() => {
    return getMaxSizeEstateEvaluationObject(simulationGraphs)
  }, [simulationGraphs?.loanGraph])
  const maxSizeEstateRemainLoanObject = useMemo(() => {
    return getMaxSizeEstateRemainLoanObject(simulationGraphs)
  }, [simulationGraphs?.loanGraph])

  const maxSizeEstateEvaluationCompareObject = useMemo(() => {
    return getMaxSizeEstateEvaluationObject(compareSimulationGraphs)
  }, [compareSimulationGraphs?.loanGraph])
  const maxSizeEstateRemainLoanCompareObject = useMemo(() => {
    return getMaxSizeEstateRemainLoanObject(compareSimulationGraphs)
  }, [compareSimulationGraphs?.loanGraph])

  const setTooltipPosition = () => {
    const y = 0
    const x =
      !isCompare && graphLength / 2 < activeInfo.activeIndex
        ? activeInfo.x - 250
        : activeInfo.x + 20

    return { x, y }
  }

  const setGraphWidth = () => {
    return breakpoint === 'sm' ? 1280 : undefined
  }

  const setGraphHeight = () => {
    return breakpoint === 'lg' ? 400 : 300
  }

  const handleChangeAge = (age: number) => {
    setSelectedAge(age)
    resetActiveInfo()
  }

  const handleClickLine = (payload: any) => {
    setActiveInfo({ ...payload.payload, x: payload.cx, y: payload.cy }, payload.index)
  }

  const handleSubmit = (values: any) => {
    resetActiveInfo()

    if (!values?.is_compare) {
      setIsCompare(false)
      setIsCompareMyData(false)
      setComparePlanName(null)
      return
    }

    if (values?.future_base_id == MY_DATA_VALUE) {
      setIsCompare(true)
      setIsCompareMyData(true)
      setComparePlanName('マイデータ')
      return
    }

    dispatch(
      isAdmin
        ? fetchNewAdminDetailFutureBase(values?.future_base_id, (response: any) => {
            setIsCompare(true)
            setIsCompareMyData(false)
            setComparePlanName(
              response.simulation_info?.future_name || '（プラン名未設定）',
            )
          })
        : fetchNewDetailFutureBase(values?.future_base_id, (response: any) => {
            setIsCompare(true)
            setIsCompareMyData(false)
            setComparePlanName(
              response.simulation_info?.future_name || '（プラン名未設定）',
            )
          }),
    )
  }

  const investmentGraphTicks = useMemo(() => {
    return isCompare
      ? getInvestmentGraphTicks(
          simulationGraphs.v2InvestmentGraph,
          compareSimulationGraphs.v2InvestmentGraph,
        )
      : undefined
  }, [
    simulationGraphs.v2InvestmentGraph,
    compareSimulationGraphs.v2InvestmentGraph,
    isCompare,
  ])

  const netAssetGraphTicks = useMemo(() => {
    return isCompare
      ? getNetAssetGraphTicks(
          simulationGraphs.netAssetGraph,
          compareSimulationGraphs.netAssetGraph,
        )
      : undefined
  }, [simulationGraphs.netAssetGraph, compareSimulationGraphs.netAssetGraph, isCompare])

  const loanGraphTicks = useMemo(() => {
    return isCompare
      ? getLoanGraphTicks(simulationGraphs.loanGraph, compareSimulationGraphs.loanGraph)
      : undefined
  }, [simulationGraphs.loanGraph, compareSimulationGraphs.loanGraph, isCompare])

  const balanceGraphTicks = useMemo(() => {
    return isCompare
      ? getBalanceGraphTicks(
          simulationGraphs.v2BalanceGraph,
          compareSimulationGraphs.v2BalanceGraph,
        )
      : undefined
  }, [simulationGraphs.v2BalanceGraph, compareSimulationGraphs.v2BalanceGraph, isCompare])

  const insuranceGraphTicks = useMemo(() => {
    return isCompare
      ? getInsuranceGraphTicks(
          simulationGraphs.insuranceGraph,
          compareSimulationGraphs.insuranceGraph,
        )
      : undefined
  }, [simulationGraphs.insuranceGraph, compareSimulationGraphs.insuranceGraph, isCompare])

  return (
    <>
      <div className={className}>
        <CircleTitle
          classNameTitle="text-18 md:text-24"
          className="pb-[24px]"
          title="ライフプランシミュレーショングラフ"
        />
        <Form
          onSubmit={handleSubmit}
          render={({ handleSubmit, values }) => (
            <form
              className="lg:w-2/4 p-[24px] mb-[32px] rounded-[16px] bg-primary-50"
              onSubmit={handleSubmit}
            >
              <Typography className="pb-[32px] text-[18px]" isBold>
                マイデータ、他のシミュレーションと比較する
              </Typography>
              <div className="md:flex items-baseline">
                <Checkbox
                  name="is_compare"
                  label="他のシミュレーションと比較する"
                  className="mb-[5px] md:mb-0 mr-[16px] text-[14px]"
                  checkboxClassName="relative top-px"
                />
                <div className="flex flex-auto items-baseline">
                  <SelectBox
                    name="future_base_id"
                    className="pr-[8px]"
                    placeholder="選択する"
                    options={futureBaseIdOptions}
                    isFullWidth
                  />
                  <ButtonPrimary
                    className="w-[134px] h-[45px]"
                    isDisabled={isLoading}
                    onClick={() => handleSubmit(values)}
                  >
                    <Typography className="text-[14px] text-white" isBold>
                      比較する
                    </Typography>
                  </ButtonPrimary>
                </div>
              </div>
            </form>
          )}
        />

        <div className="md:flex items-baseline pb-[40px]">
          <Typography className="pb-[5px] md:pb-0 pr-[12px] text-[14px]" isBold>
            期間指定
          </Typography>
          <SelectBoxWithoutFinalForm
            name="age"
            placeholder="選択する"
            widthClassName="w-[285px]"
            value={selectedAge}
            options={ageOptions}
            isFullWidth
            onChange={(e: ChangeEvent<HTMLSelectElement>) =>
              handleChangeAge(Number(e.target.value))
            }
          />
        </div>

        <Tab
          className="h-[50px] mb-[48px]"
          currentIndex={currentTabIndex}
          options={[
            '金融資産',
            '純資産',
            '不動産<span class="hidden md:inline">・</span><br class="block md:hidden" />ローン',
            '収支',
            '保険',
          ]}
          onClick={(index) => {
            setCurrentTabIndex(index)
            setIsShowInsurance(index === SIMULATION_GRAPH_TAB_INDEX.risk)
          }}
        />

        <div className="lg:flex pb-[48px] overflow-x-scroll md:overflow-x-visible overflow-y-visible">
          <div className={isCompare ? 'w-full lg:w-3/6' : 'w-full'}>
            {isCompare && (
              <div className="flex w-[1280px] md:w-auto">
                <Typography className="sticky left-0 pl-[16px] pb-[10px]" isBold>
                  ベースプラン：現在作成中のプラン
                </Typography>
              </div>
            )}
            {currentTabIndex === SIMULATION_GRAPH_TAB_INDEX.investment && (
              <GraphComposed
                id="v2-investment-graph"
                width={setGraphWidth()}
                height={setGraphHeight()}
                xDataKey="age"
                data={simulationGraphs.v2InvestmentGraph}
                tooltipHide={breakpoint === 'sm' || (breakpoint === 'md' && isCompare)}
                tooltipPosition={{ x: setTooltipPosition().x, y: setTooltipPosition().y }}
                tooltipContent={
                  <TooltipInvestment
                    activeIndex={activeInfo.activeIndex}
                    basePlanName="現在作成中のプラン"
                    baseItem={simulationGraphs.v2InvestmentGraph}
                    isCompare={isCompare}
                    comparePlanName={comparePlanName}
                    compareItem={compareSimulationGraphs.v2InvestmentGraph}
                  />
                }
                yTicks={investmentGraphTicks}
              >
                {renderCustomBar({
                  name: '流動性資産',
                  dataKey: 'deposit',
                  activeIndex: activeInfo.activeIndex,
                  stackId: 'investment',
                  color: '#B9D3E9',
                  data: simulationGraphs.v2InvestmentGraph,
                  onClick: setActiveInfo,
                })}
                {renderCustomBar({
                  name: '投資信託',
                  dataKey: 'investment_trust',
                  activeIndex: activeInfo.activeIndex,
                  stackId: 'investment',
                  color: '#BBE1DF',
                  data: simulationGraphs.v2InvestmentGraph,
                  onClick: setActiveInfo,
                })}
                {renderCustomBar({
                  name: '株式',
                  dataKey: 'stock',
                  activeIndex: activeInfo.activeIndex,
                  stackId: 'investment',
                  color: '#F9EBA3',
                  data: simulationGraphs.v2InvestmentGraph,
                  onClick: setActiveInfo,
                })}
                {renderCustomBar({
                  name: '債券',
                  dataKey: 'bond',
                  activeIndex: activeInfo.activeIndex,
                  stackId: 'investment',
                  color: '#FF9933',
                  data: simulationGraphs.v2InvestmentGraph,
                  onClick: setActiveInfo,
                })}
                {renderCustomBar({
                  name: '社内預金・財形・持株など',
                  dataKey: 'deducted_savings',
                  activeIndex: activeInfo.activeIndex,
                  stackId: 'investment',
                  color: '#CC6666',
                  data: simulationGraphs.v2InvestmentGraph,
                  onClick: setActiveInfo,
                })}
                {renderCustomBar({
                  name: 'その他',
                  dataKey: 'other',
                  activeIndex: activeInfo.activeIndex,
                  stackId: 'investment',
                  color: '#E1E0E2',
                  data: simulationGraphs.v2InvestmentGraph,
                  onClick: setActiveInfo,
                })}
                {renderCustomBar({
                  name: 'マイナス資産',
                  dataKey: 'substruction',
                  activeIndex: activeInfo.activeIndex,
                  stackId: 'investment',
                  color: '#D4C7DA',
                  data: simulationGraphs.v2InvestmentGraph,
                  onClick: setActiveInfo,
                })}
                {renderCustomBar({
                  name: '貯蓄用保険資産',
                  dataKey: 'insurance_asset',
                  activeIndex: activeInfo.activeIndex,
                  stackId: 'investment',
                  color: '#F3BCC2',
                  data: simulationGraphs.v2InvestmentGraph,
                  onClick: setActiveInfo,
                })}
              </GraphComposed>
            )}

            {currentTabIndex === SIMULATION_GRAPH_TAB_INDEX.netAsset && (
              <GraphComposed
                id="net-asset-graph"
                width={setGraphWidth()}
                height={setGraphHeight()}
                xDataKey="age"
                data={simulationGraphs.netAssetGraph}
                tooltipHide={breakpoint === 'sm' || (breakpoint === 'md' && isCompare)}
                tooltipPosition={{ x: setTooltipPosition().x, y: setTooltipPosition().y }}
                tooltipContent={
                  <TooltipNetAsset
                    activeIndex={activeInfo.activeIndex}
                    basePlanName="現在作成中のプラン"
                    baseItem={simulationGraphs.netAssetGraph}
                    isCompare={isCompare}
                    comparePlanName={comparePlanName}
                    compareItem={compareSimulationGraphs.netAssetGraph}
                  />
                }
                yTicks={netAssetGraphTicks}
              >
                {renderCustomBar({
                  name: '金融資産',
                  dataKey: 'financial_asset_total',
                  activeIndex: activeInfo.activeIndex,
                  stackId: 'netAsset',
                  color: '#FFD9B1',
                  data: simulationGraphs.netAssetGraph,
                  onClick: setActiveInfo,
                })}
                {renderCustomBar({
                  name: '不動産',
                  dataKey: 'estate_evaluation_total_manyen',
                  activeIndex: activeInfo.activeIndex,
                  stackId: 'netAsset',
                  color: '#AFE2E9',
                  data: simulationGraphs.netAssetGraph,
                  onClick: setActiveInfo,
                })}
                {renderCustomBar({
                  name: '負債',
                  dataKey: 'substruction_total_negative',
                  activeIndex: activeInfo.activeIndex,
                  stackId: 'netAsset',
                  color: '#F6B2A1',
                  data: simulationGraphs.netAssetGraph,
                  onClick: setActiveInfo,
                })}
                {renderCustomLine({
                  name: '純資産合計',
                  dataKey: 'net_asset_total',
                  color: '#C891AE',
                  onClick: handleClickLine,
                })}
              </GraphComposed>
            )}

            {currentTabIndex === SIMULATION_GRAPH_TAB_INDEX.loan && (
              <GraphComposed
                id="loan-graph"
                width={setGraphWidth()}
                height={setGraphHeight()}
                xDataKey="age"
                data={simulationGraphs.loanGraph}
                tooltipHide={breakpoint === 'sm' || (breakpoint === 'md' && isCompare)}
                tooltipPosition={{ x: setTooltipPosition().x, y: setTooltipPosition().y }}
                tooltipContent={
                  <TooltipLoan
                    activeIndex={activeInfo.activeIndex}
                    loanIndexObject={{
                      maxSizeEstateEvaluationObject,
                      maxSizeEstateRemainLoanObject,
                      maxSizeEstateEvaluationCompareObject,
                      maxSizeEstateRemainLoanCompareObject,
                    }}
                    basePlanName="現在作成中のプラン"
                    baseItem={simulationGraphs.loanGraph}
                    isCompare={isCompare}
                    comparePlanName={comparePlanName}
                    compareItem={compareSimulationGraphs.loanGraph}
                  />
                }
                yTicks={loanGraphTicks}
              >
                {renderCustomArea({
                  name: '評価額 自宅',
                  dataKey: 'housing_asset_evaluation',
                  stackId: 'loanArea',
                  color: '#E5F5AD',
                })}
                {Object.keys(maxSizeEstateEvaluationObject || {}).map((key, index) => [
                  renderCustomArea({
                    name: `評価額 物件${index + 1}`,
                    dataKey: `estate_evaluations.${key}`,
                    stackId: 'loanArea',
                    color: ESTATE_EVALUATION_COLOR[index],
                  }),
                ])}
                {renderCustomBar({
                  name: 'ローン残高 自宅',
                  dataKey: 'housing_loan_balance',
                  activeIndex: activeInfo.activeIndex,
                  stackId: 'loanBar',
                  color: '#5BAFE4',
                  data: simulationGraphs.loanGraph,
                  onClick: setActiveInfo,
                })}
                {Object.keys(maxSizeEstateRemainLoanObject || {}).map((key, index) => [
                  renderCustomBar({
                    name: `ローン残高 物件${index + 1}`,
                    dataKey: `estate_remain_loans_hash.${key}`,
                    activeIndex: activeInfo.activeIndex,
                    stackId: 'loanBar',
                    color: ESTATE_REMAIN_LOANS_COLOR[index],
                    data: simulationGraphs.loanGraph,
                    onClick: setActiveInfo,
                  }),
                ])}
                {renderCustomLine({
                  name: '評価額',
                  dataKey: 'asset_evaluation_total',
                  color: '#289D48',
                  onClick: handleClickLine,
                })}
              </GraphComposed>
            )}

            {currentTabIndex === SIMULATION_GRAPH_TAB_INDEX.balance && (
              <GraphComposed
                id="v2-balance-graph"
                width={setGraphWidth()}
                height={setGraphHeight()}
                xDataKey="age"
                data={simulationGraphs.v2BalanceGraph}
                tooltipHide={breakpoint === 'sm' || (breakpoint === 'md' && isCompare)}
                tooltipPosition={{ x: setTooltipPosition().x, y: setTooltipPosition().y }}
                tooltipContent={
                  <TooltipBalance
                    activeIndex={activeInfo.activeIndex}
                    basePlanName="現在作成中のプラン"
                    baseItem={simulationGraphs.v2BalanceGraph}
                    isCompare={isCompare}
                    comparePlanName={comparePlanName}
                    compareItem={compareSimulationGraphs.v2BalanceGraph}
                  />
                }
                yTicks={balanceGraphTicks}
              >
                {renderCustomBar({
                  name: '年間収支',
                  dataKey: 'balance_total',
                  activeIndex: activeInfo.activeIndex,
                  color: '#ADD6F0',
                  data: simulationGraphs.v2BalanceGraph,
                  onClick: setActiveInfo,
                })}
                {renderCustomLine({
                  name: '収入合計',
                  dataKey: 'income_total',
                  color: '#4A667F',
                  onClick: handleClickLine,
                })}
                {renderCustomLine({
                  name: '支出合計',
                  dataKey: 'spending_total',
                  color: '#C891AE',
                  onClick: handleClickLine,
                })}
              </GraphComposed>
            )}

            {currentTabIndex === SIMULATION_GRAPH_TAB_INDEX.risk && (
              <GraphComposed
                id="insurance-graph"
                width={setGraphWidth()}
                height={setGraphHeight()}
                xDataKey="age"
                data={simulationGraphs.insuranceGraph}
                tooltipHide={breakpoint === 'sm' || (breakpoint === 'md' && isCompare)}
                tooltipPosition={{ x: setTooltipPosition().x, y: setTooltipPosition().y }}
                tooltipContent={
                  <TooltipRisk
                    activeIndex={activeInfo.activeIndex}
                    basePlanName="現在作成中のプラン"
                    baseItem={simulationGraphs.insuranceGraph}
                    isCompare={isCompare}
                    comparePlanName={comparePlanName}
                    compareItem={compareSimulationGraphs.insuranceGraph}
                  />
                }
                yTicks={insuranceGraphTicks}
              >
                {renderCustomBar({
                  name: '金融資産合計',
                  dataKey: 'total_financial_assets',
                  activeIndex: activeInfo.activeIndex,
                  stackId: 'risk',
                  color: '#191970',
                  data: simulationGraphs.insuranceGraph,
                  onClick: setActiveInfo,
                })}
                {renderCustomBar({
                  name: '既加入保険金額',
                  dataKey: 'existing_insurance_total',
                  activeIndex: activeInfo.activeIndex,
                  stackId: 'risk',
                  color: '#c71585',
                  data: simulationGraphs.insuranceGraph,
                  onClick: setActiveInfo,
                })}
                {renderCustomBar({
                  name: '不足額',
                  dataKey: 'shortfall_total',
                  activeIndex: activeInfo.activeIndex,
                  stackId: 'risk',
                  color: '#c0c0c0',
                  data: simulationGraphs.insuranceGraph,
                  onClick: setActiveInfo,
                })}
                {renderCustomLine({
                  name: '必要保障額',
                  dataKey: 'required_security_amount_total',
                  color: '#ffa500',
                  onClick: handleClickLine,
                })}
              </GraphComposed>
            )}
          </div>

          {isCompare && (
            <div className="w-full lg:w-3/6">
              <div className="flex w-[1280px] md:w-auto">
                <Typography className="sticky left-0 pl-[16px] pb-[10px]" isBold>
                  {`比較プラン：${comparePlanName ?? '（プラン名未設定）'}`}
                </Typography>
              </div>
              {currentTabIndex === SIMULATION_GRAPH_TAB_INDEX.investment && (
                <GraphComposed
                  id="v2-investment-compare-graph"
                  width={setGraphWidth()}
                  height={setGraphHeight()}
                  xDataKey="age"
                  data={compareSimulationGraphs.v2InvestmentGraph}
                  tooltipHide
                  yTicks={investmentGraphTicks}
                >
                  {renderCustomBar({
                    name: '流動性資産',
                    dataKey: 'deposit',
                    activeIndex: activeInfo.activeIndex,
                    stackId: 'investment',
                    color: '#B9D3E9',
                    data: compareSimulationGraphs.v2InvestmentGraph,
                    onClick: setActiveInfo,
                  })}
                  {renderCustomBar({
                    name: '投資信託',
                    dataKey: 'investment_trust',
                    activeIndex: activeInfo.activeIndex,
                    stackId: 'investment',
                    color: '#BBE1DF',
                    data: compareSimulationGraphs.v2InvestmentGraph,
                    onClick: setActiveInfo,
                  })}
                  {renderCustomBar({
                    name: '株式',
                    dataKey: 'stock',
                    activeIndex: activeInfo.activeIndex,
                    stackId: 'investment',
                    color: '#F9EBA3',
                    data: compareSimulationGraphs.v2InvestmentGraph,
                    onClick: setActiveInfo,
                  })}
                  {renderCustomBar({
                    name: '債券',
                    dataKey: 'bond',
                    activeIndex: activeInfo.activeIndex,
                    stackId: 'investment',
                    color: '#FF9933',
                    data: compareSimulationGraphs.v2InvestmentGraph,
                    onClick: setActiveInfo,
                  })}
                  {renderCustomBar({
                    name: '社内預金・財形・持株など',
                    dataKey: 'deducted_savings',
                    activeIndex: activeInfo.activeIndex,
                    stackId: 'investment',
                    color: '#CC6666',
                    data: compareSimulationGraphs.v2InvestmentGraph,
                    onClick: setActiveInfo,
                  })}
                  {renderCustomBar({
                    name: 'その他',
                    dataKey: 'other',
                    activeIndex: activeInfo.activeIndex,
                    stackId: 'investment',
                    color: '#E1E0E2',
                    data: compareSimulationGraphs.v2InvestmentGraph,
                    onClick: setActiveInfo,
                  })}
                  {renderCustomBar({
                    name: 'マイナス資産',
                    dataKey: 'substruction',
                    activeIndex: activeInfo.activeIndex,
                    stackId: 'investment',
                    color: '#D4C7DA',
                    data: compareSimulationGraphs.v2InvestmentGraph,
                    onClick: setActiveInfo,
                  })}
                  {renderCustomBar({
                    name: '貯蓄用保険資産',
                    dataKey: 'insurance_asset',
                    activeIndex: activeInfo.activeIndex,
                    stackId: 'investment',
                    color: '#F3BCC2',
                    data: compareSimulationGraphs.v2InvestmentGraph,
                    onClick: setActiveInfo,
                  })}
                </GraphComposed>
              )}

              {currentTabIndex === SIMULATION_GRAPH_TAB_INDEX.netAsset && (
                <GraphComposed
                  id="net-asset-compare-graph"
                  width={setGraphWidth()}
                  height={setGraphHeight()}
                  xDataKey="age"
                  data={compareSimulationGraphs.netAssetGraph}
                  tooltipHide
                  yTicks={netAssetGraphTicks}
                >
                  {renderCustomBar({
                    name: '金融資産',
                    dataKey: 'financial_asset_total',
                    activeIndex: activeInfo.activeIndex,
                    stackId: 'netAsset',
                    color: '#FFD9B1',
                    data: compareSimulationGraphs.netAssetGraph,
                    onClick: setActiveInfo,
                  })}
                  {renderCustomBar({
                    name: '不動産',
                    dataKey: 'estate_evaluation_total_manyen',
                    activeIndex: activeInfo.activeIndex,
                    stackId: 'netAsset',
                    color: '#AFE2E9',
                    data: compareSimulationGraphs.netAssetGraph,
                    onClick: setActiveInfo,
                  })}
                  {renderCustomBar({
                    name: '負債',
                    dataKey: 'substruction_total_negative',
                    activeIndex: activeInfo.activeIndex,
                    stackId: 'netAsset',
                    color: '#F6B2A1',
                    data: compareSimulationGraphs.netAssetGraph,
                    onClick: setActiveInfo,
                  })}
                  {renderCustomLine({
                    name: '純資産合計',
                    dataKey: 'net_asset_total',
                    color: '#C891AE',
                    onClick: handleClickLine,
                  })}
                </GraphComposed>
              )}

              {currentTabIndex === SIMULATION_GRAPH_TAB_INDEX.loan && (
                <GraphComposed
                  id="loan-compare-graph"
                  width={setGraphWidth()}
                  height={setGraphHeight()}
                  xDataKey="age"
                  data={compareSimulationGraphs.loanGraph}
                  tooltipHide
                  yTicks={loanGraphTicks}
                >
                  {renderCustomArea({
                    name: '評価額 自宅',
                    dataKey: 'housing_asset_evaluation',
                    stackId: 'loanArea',
                    color: '#E5F5AD',
                  })}
                  {Object.keys(maxSizeEstateEvaluationCompareObject || {}).map(
                    (key, index) => [
                      renderCustomArea({
                        name: `評価額 物件${index + 1}`,
                        dataKey: `estate_evaluations.${key}`,
                        stackId: 'loanArea',
                        color: ESTATE_EVALUATION_COLOR[index],
                      }),
                    ],
                  )}
                  {renderCustomBar({
                    name: 'ローン残高 自宅',
                    dataKey: 'housing_loan_balance',
                    activeIndex: activeInfo.activeIndex,
                    stackId: 'loanBar',
                    color: '#5BAFE4',
                    data: compareSimulationGraphs.loanGraph,
                    onClick: setActiveInfo,
                  })}
                  {Object.keys(maxSizeEstateRemainLoanCompareObject || {}).map(
                    (key, index) => [
                      renderCustomBar({
                        name: `ローン残高 物件${index + 1}`,
                        dataKey: `estate_remain_loans_hash.${key}`,
                        activeIndex: activeInfo.activeIndex,
                        stackId: 'loanBar',
                        color: ESTATE_REMAIN_LOANS_COLOR[index],
                        data: compareSimulationGraphs.loanGraph,
                        onClick: setActiveInfo,
                      }),
                    ],
                  )}
                  {renderCustomLine({
                    name: '評価額',
                    dataKey: 'asset_evaluation_total',
                    color: '#289D48',
                    onClick: handleClickLine,
                  })}
                </GraphComposed>
              )}

              {currentTabIndex === SIMULATION_GRAPH_TAB_INDEX.balance && (
                <GraphComposed
                  id="v2-balance-compare-graph"
                  width={setGraphWidth()}
                  height={setGraphHeight()}
                  xDataKey="age"
                  data={compareSimulationGraphs.v2BalanceGraph}
                  tooltipHide
                  yTicks={balanceGraphTicks}
                >
                  {renderCustomBar({
                    name: '年間収支',
                    dataKey: 'balance_total',
                    activeIndex: activeInfo.activeIndex,
                    color: '#ADD6F0',
                    data: compareSimulationGraphs.v2BalanceGraph,
                    onClick: setActiveInfo,
                  })}
                  {renderCustomLine({
                    name: '収入合計',
                    dataKey: 'income_total',
                    color: '#4A667F',
                    onClick: handleClickLine,
                  })}
                  {renderCustomLine({
                    name: '支出合計',
                    dataKey: 'spending_total',
                    color: '#C891AE',
                    onClick: handleClickLine,
                  })}
                </GraphComposed>
              )}

              {currentTabIndex === SIMULATION_GRAPH_TAB_INDEX.risk && (
                <GraphComposed
                  id="insurance-compare-graph"
                  width={setGraphWidth()}
                  height={setGraphHeight()}
                  xDataKey="age"
                  data={compareSimulationGraphs.insuranceGraph}
                  tooltipHide
                  yTicks={insuranceGraphTicks}
                >
                  {renderCustomBar({
                    name: '金融資産合計',
                    dataKey: 'total_financial_assets',
                    activeIndex: activeInfo.activeIndex,
                    stackId: 'risk',
                    color: '#191970',
                    data: compareSimulationGraphs.insuranceGraph,
                    onClick: setActiveInfo,
                  })}
                  {renderCustomBar({
                    name: '既加入保険金額',
                    dataKey: 'existing_insurance_total',
                    activeIndex: activeInfo.activeIndex,
                    stackId: 'risk',
                    color: '#c71585',
                    data: compareSimulationGraphs.insuranceGraph,
                    onClick: setActiveInfo,
                  })}
                  {renderCustomBar({
                    name: '不足額',
                    dataKey: 'shortfall_total',
                    activeIndex: activeInfo.activeIndex,
                    stackId: 'risk',
                    color: '#c0c0c0',
                    data: compareSimulationGraphs.insuranceGraph,
                    onClick: setActiveInfo,
                  })}
                  {renderCustomLine({
                    name: '必要保障額',
                    dataKey: 'required_security_amount_total',
                    color: '#ffa500',
                    onClick: handleClickLine,
                  })}
                </GraphComposed>
              )}
            </div>
          )}
        </div>
      </div>

      {(breakpoint === 'sm' || (breakpoint === 'md' && isCompare)) && (
        <Modal
          className="w-[95%] md:w-[fit-content]"
          modalContainerClassName="h-[95%] px-10 py-10"
          isOpen={!!activeInfo?.activeIndex || activeInfo?.activeIndex === 0}
          onClose={() => handleClickAway()}
        >
          {currentTabIndex === SIMULATION_GRAPH_TAB_INDEX.investment && (
            <TooltipInvestment
              activeIndex={activeInfo.activeIndex}
              baseItem={simulationGraphs.v2InvestmentGraph}
              isCompare={isCompare}
              comparePlanName={comparePlanName}
              compareItem={compareSimulationGraphs.v2InvestmentGraph}
            />
          )}
          {currentTabIndex === SIMULATION_GRAPH_TAB_INDEX.netAsset && (
            <TooltipNetAsset
              activeIndex={activeInfo.activeIndex}
              basePlanName="現在作成中のプラン"
              baseItem={simulationGraphs.netAssetGraph}
              isCompare={isCompare}
              comparePlanName={comparePlanName}
              compareItem={compareSimulationGraphs.netAssetGraph}
            />
          )}
          {currentTabIndex === SIMULATION_GRAPH_TAB_INDEX.loan && (
            <TooltipLoan
              activeIndex={activeInfo.activeIndex}
              loanIndexObject={{
                maxSizeEstateEvaluationObject,
                maxSizeEstateRemainLoanObject,
                maxSizeEstateEvaluationCompareObject,
                maxSizeEstateRemainLoanCompareObject,
              }}
              basePlanName="現在作成中のプラン"
              baseItem={simulationGraphs.loanGraph}
              isCompare={isCompare}
              comparePlanName={comparePlanName}
              compareItem={compareSimulationGraphs.loanGraph}
            />
          )}
          {currentTabIndex === SIMULATION_GRAPH_TAB_INDEX.balance && (
            <TooltipBalance
              activeIndex={activeInfo.activeIndex}
              basePlanName="現在作成中のプラン"
              baseItem={simulationGraphs.v2BalanceGraph}
              isCompare={isCompare}
              comparePlanName={comparePlanName}
              compareItem={compareSimulationGraphs.v2BalanceGraph}
            />
          )}
          {currentTabIndex === SIMULATION_GRAPH_TAB_INDEX.risk && (
            <TooltipRisk
              activeIndex={activeInfo.activeIndex}
              basePlanName="現在作成中のプラン"
              baseItem={simulationGraphs.insuranceGraph}
              isCompare={isCompare}
              comparePlanName={comparePlanName}
              compareItem={compareSimulationGraphs.insuranceGraph}
            />
          )}
        </Modal>
      )}
    </>
  )
}

export default SimulationGraphBody
