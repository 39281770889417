import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useField, useForm } from 'react-final-form'
import { isNull as _isNull } from 'lodash'
import Modal from '@/components/v1/atoms/Modal'
import {
  LOAN_TYPE_WITHOUT_SPOUSE_SELECT_ITEM,
  REJECT_LOAN_TYPE_SELECT_ITEMS,
} from '@/constants/formItem/housingLoans'
import { createHousingLoanRepayment } from '@/containers/housingLoansSlice'
import { castNumberWithoutOperator } from '@/models/commonsModelFunc'
import { FUTURE_ESTATE_INVESTMENT_DEFAULT_VALUES } from '@/models/futures/futureEstateInvestmentsModelFunc'
import { LoanType } from '@/models/housingLoansModel'
import {
  buildRepaymentValues,
  isInputRepaymentAllValues,
  convertLoanTypeToPersonFlg,
} from '@/models/housingLoansModelFunc'
import { RootState } from '@/store'
import HousingLoanAmountTable from '@/templates/v2/simulations/_housingLoanAmountTable'
import {
  composeValidators,
  required,
  zeroOrMoreNumber,
  oneHundredLessThanNumber,
  limitRangeNumber,
  commaNumberMaxLength,
} from '@/utils/validate'

import Button from '@/components/v2/atoms/Button'
import Typography from '@/components/v2/atoms/Typography'
import SelectBox from '@/components/v2/molecules/SelectBox'
import NumberField from '@/components/v2/molecules/NumberField'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import Link from '@/components/v2/atoms/Link'
import Tooltip from '@/components/v2/atoms/Tooltip'
import HelpCircleIcon from '@/assets/images/v2/help-circle.svg'
import NumberFieldDecimal from '@/components/v2/molecules/NumberFieldDecimal'

const LoanDetail: FC = () => {
  const dispatch = useDispatch()
  const [isLoanAmountModalOpen, setIsLoanAmountModalOpen] = useState<boolean>(false)

  const { familyInfo } = useSelector((state: RootState) => state.futureBases)
  const { futureMarriage } = useSelector((state: RootState) => state.futureMarriages)
  const hasOrWillHaveSpouse = familyInfo?.is_married || !_isNull(futureMarriage)

  const { change } = useForm()

  const {
    input: { value: scheduledAtAge },
  } = useField('scheduled_at_age')
  const {
    input: { value: lendStartAtAge },
  } = useField('future_lend_housing.lend_start_at_age')
  const {
    input: { value: housingLoanAttributes },
  } = useField('future_lend_housing.housing_loan_attributes')
  const {
    input: { value: loanYearly },
  } = useField('loan_yearly')
  const {
    input: { value: loanDownPaymentManyen },
  } = useField('future_lend_housing.housing_loan_attributes.loan_down_payment_manyen')
  const {
    input: { value: repaymentPeriodYears },
  } = useField('future_lend_housing.housing_loan_attributes.repayment_period_years')

  const targetScheduledAtAge = useMemo(() => scheduledAtAge || lendStartAtAge, [
    scheduledAtAge,
    lendStartAtAge,
  ])

  const paymentEndAge = useMemo(
    () => Number(targetScheduledAtAge) + Number(repaymentPeriodYears),
    [repaymentPeriodYears, targetScheduledAtAge],
  )

  useEffect(() => {
    if (!isInputRepaymentAllValues(housingLoanAttributes)) return

    const newValues = buildRepaymentValues(housingLoanAttributes)
    dispatch(createHousingLoanRepayment(newValues))
  }, [housingLoanAttributes])

  return (
    <>
      <div className="col-span-1 md:col-span-2">
        <CircleTitle className="pt-40 pb-[32px] flex items-center" title="ローンの詳細" />
      </div>

      <div className="">
        <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
          <Typography
            className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
            isBold
          >
            住宅ローンの方式
          </Typography>
          <div>
            <SelectBox
              name="future_lend_housing.housing_loan_attributes.loan_type"
              placeholder="選択する"
              isFullWidth
              options={
                hasOrWillHaveSpouse
                  ? REJECT_LOAN_TYPE_SELECT_ITEMS
                  : LOAN_TYPE_WITHOUT_SPOUSE_SELECT_ITEM
              }
              validate={required}
              onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                change(
                  'future_lend_housing.housing_loan_attributes.person_flg',
                  convertLoanTypeToPersonFlg(event.target.value as LoanType),
                )
              }
            />
            <div className="pt-[10px] text-right">
              <Link
                className="text-[12px] text-black-700"
                onClick={() => setIsLoanAmountModalOpen(true)}
              >
                各方式で借りれる上限額について
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="col-span-1 md:col-span-2" />
      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
        <div className="flex justify-start min-w-[140px] lg:min-w-[210px]">
          <Typography
            className="flex items-center pb-[10px] text-[14px] text-black-800"
            isBold
          >
            必要金額
            <div className="mx-[10px]">
              <Tooltip
                item="賃貸化を実施するタイミングでのローンの残高が自動で設定されます。"
                itemClassName="w-[200px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
              >
                <HelpCircleIcon />
              </Tooltip>
            </div>
          </Typography>
        </div>

        <div className="">
          <div className="flex flex-auto items-baseline">
            <NumberField
              name="loan_remain_amount_manyen" // MEMO: DBでは必要のない値
              defaultValue={castNumberWithoutOperator(loanYearly)}
              isFullWidth
              disabled={true}
            />
            <Typography
              className="pl-[8px] text-[14px] text-black-800 min-w-[fit-content]"
              isBold
            >
              万円
            </Typography>
          </div>
        </div>
      </div>
      <div />

      <div className="">
        <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
          <Typography
            className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
            isBold
          >
            頭金
          </Typography>
          <div className="flex flex-auto items-baseline">
            <NumberField
              name="future_lend_housing.housing_loan_attributes.loan_down_payment_manyen"
              isFullWidth
              validate={composeValidators(required, zeroOrMoreNumber, (value: any) =>
                commaNumberMaxLength(value, 7),
              )}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                const value = event.target.value
                change(
                  'future_lend_housing.housing_loan_attributes.loan_amount_manyen',
                  loanYearly - castNumberWithoutOperator(value),
                )
              }}
            />
            <Typography
              className="pl-[8px] text-[14px] text-black-800 min-w-[fit-content]"
              isBold
            >
              万円
            </Typography>
          </div>
        </div>
      </div>

      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
        <div className="flex justify-start min-w-[140px] lg:min-w-[210px]">
          <Typography
            className="flex items-center pb-[10px] text-[14px] text-black-800"
            isBold
          >
            借入金額
            <div className="mx-[10px]">
              <Tooltip
                item="ローン総額から頭金を引いた数値を借入金額として自動計算しています"
                itemClassName="w-[200px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
              >
                <HelpCircleIcon />
              </Tooltip>
            </div>
          </Typography>
        </div>

        <div className="flex flex-auto items-baseline">
          <NumberField
            name="future_lend_housing.housing_loan_attributes.loan_amount_manyen"
            isFullWidth
            validate={composeValidators(required, zeroOrMoreNumber, (value: any) =>
              commaNumberMaxLength(value, 6),
            )}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              const value = event.target.value
              change(
                'future_lend_housing.housing_loan_attributes.loan_amount_manyen',
                loanYearly - castNumberWithoutOperator(value),
              )
            }}
            defaultValue={loanYearly - castNumberWithoutOperator(loanDownPaymentManyen)}
          />
          <Typography
            className="pl-[8px] text-[14px] text-black-800 min-w-[fit-content]"
            isBold
          >
            万円
          </Typography>
        </div>
      </div>

      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
        <div className="flex items-center mb-[10px]">
          <Typography className="text-[14px] text-black-800" isBold>
            借入金利
          </Typography>
          <div className="">
            <span>
              <Button
                className="text-[13px] text-black-800 font-default min-w-[140px] h-[37px] border-[0.5px] border-black-700 px-[16px] mx-[10px] rounded-4"
                children="デフォルトを設定"
                onClick={() =>
                  change(
                    'future_lend_housing.housing_loan_attributes.loan_interest_ratio_percent',
                    FUTURE_ESTATE_INVESTMENT_DEFAULT_VALUES.loan_interest_ratio_percent,
                  )
                }
              />
            </span>
          </div>
        </div>
        <div className="flex flex-auto items-baseline">
          <NumberFieldDecimal
            name="future_lend_housing.housing_loan_attributes.loan_interest_ratio_percent"
            isFullWidth
            validate={composeValidators(
              required,
              zeroOrMoreNumber,
              oneHundredLessThanNumber,
            )}
          />
          <Typography className="pl-[8px] text-[14px] text-black-800" isBold>
            %
          </Typography>
        </div>
      </div>

      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline justify-between">
        <Typography
          className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
          isBold
        >
          返済期間
        </Typography>
        <div>
          <div className="flex flex-auto items-baseline">
            <NumberField
              name="future_lend_housing.housing_loan_attributes.repayment_period_years"
              isFullWidth
              validate={(value: any) => limitRangeNumber(value, 2, 35)}
            />
            <Typography className="pl-[8px] text-[14px] text-black-800" isBold>
              年
            </Typography>
          </div>
          <div className="pt-5">
            <Typography className="text-[14px] text-black-800 text-right" isBold>
              {`${paymentEndAge}歳で完済予定`}
            </Typography>
          </div>
        </div>
      </div>

      {isLoanAmountModalOpen && (
        <Modal
          open={isLoanAmountModalOpen}
          maxWidth={false}
          onClose={() => setIsLoanAmountModalOpen(false)}
        >
          <HousingLoanAmountTable isHousePurchase={false} />
        </Modal>
      )}
    </>
  )
}

export default LoanDetail
