import ApiClient from './apiClient'
import {
  HousingLoanRepaymentResponce,
  HousingLoanRepaymentValues,
} from '../models/housingLoansModel'

export const postHousingLoanRepayment = async (values: HousingLoanRepaymentValues) => {
  return await ApiClient.post(
    '/housing_loans/calc',
    (response: HousingLoanRepaymentResponce) => response,
    values,
  )
}
