import React, { FC } from 'react'
import ErrorExclamationIcon from '@/assets/images/v2/error-exclamation.svg'
import Alert from '@/components/v2/molecules/Alert'

interface AlertErrorProps {
  /** クラス名 */
  className?: string
  /** メッセージ */
  message: string

  hideCloseButton?: boolean

  onClick?: () => void
}

const AlertError: FC<AlertErrorProps> = (props) => {
  const { className, message, hideCloseButton, onClick } = props

  return (
    <Alert
      className={className}
      message={message}
      icon={<ErrorExclamationIcon />}
      isBold
      bgColorClassName="bg-alert-400"
      borderColorClassName="border-alert-600"
      messageColorClassName="text-alert-600"
      hideCloseButton={hideCloseButton}
      onClick={onClick}
    />
  )
}

export default AlertError
