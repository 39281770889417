import { removeStorageHasOpenedDashboard } from '@/utils/localStorage/hasOpenedDashboardStorage'
import { removeStorageHasNoEnoughInputData } from '@/utils/localStorage/hasNoEnoughInputDataStorage'
import { removeStorageHasNoFamilyStructure } from '@/utils/localStorage/hasNoFamilyStructureStorage'
import { removeStorageIsClickedOnConnectionPage } from '@/utils/localStorage/isClickedOnConnectionPageStorage'
import { removeStorageIsDisabledPaidAccount } from '@/utils/localStorage/isDisabledPaidAccountStorage'
import { removeStorageIsDisabledTrialAccount } from '@/utils/localStorage/isDisabledTrialAccountStorage'
import { removeStorageIsFromSettingPage } from '@/utils/localStorage/isFromSettingPageStorage'

export const removeBaseItemsInLocalStorage = () => {
  removeStorageHasNoEnoughInputData()
  removeStorageHasNoFamilyStructure()
  removeStorageHasOpenedDashboard()
  removeStorageIsClickedOnConnectionPage()
  removeStorageIsDisabledPaidAccount()
  removeStorageIsDisabledTrialAccount()
  removeStorageIsFromSettingPage()
}
