import React, { FC, useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { find as _find, last as _last } from 'lodash'
import Tooltip from '@/components/v2/atoms/Tooltip'
import Typography from '@/components/v2/atoms/Typography'
import {
  convertDateToJapaneseFormat,
  convertDateToTwoTypesYearsDateFormat,
} from '@/models/commonsModelFunc'
import { HOUSE_TYPE } from '@/models/commonHousingModelFunc'
import { SimulationDetailEditContext } from '@/models/futures/futureBasesModelFunc'
import { FutureHousingSale } from '@/models/futures/futureHousingSalesModel'
import { HANDLING_TYPE, LAST_AGE } from '@/models/futures/futureV2HousingsModelFunc'
import { FutureLendHousing } from '@/models/futures/futureLendHousingsModel'
import {
  FutureV2Housing,
  HandlingType,
  NextHouseType,
} from '@/models/futures/futureV2HousingsModel'
import { InputHousing } from '@/models/inputs/inputHousingsModel'
import { RootState } from '@/store'
import FutureHousingSaleBody from '@/templates/v2/simulations/futureV2Housings/FutureHousingSaleBody'
import FutureLendHousingForm from '@/templates/v2/simulations/futureV2Housings/FutureLendHousingForm'
import FutureV2HousingBody from '@/templates/v2/simulations/futureV2Housings/FutureV2HousingBody'
import SimulationNewButton from '../_simulationNewButton'

interface HousingSectionItemProps {
  /** true: 現状住居の表示, false: 将来住居の表示 */
  isHousingForInput?: boolean
  /** 将来住居 */
  futureV2Housing: FutureV2Housing
  /** 共通API呼び出しメソッド */
  commonCallApi: () => void
  /** モーダルオープンメソッド */
  handleModalOpen: () => void
  /** 次の住居開始年齢 */
  nextScheduledAtAge: number | null | undefined
  /** 直前の住居セットメソッド */
  setPrevMainHousing?: (
    futureV2Housing: FutureV2Housing | InputHousing | undefined,
  ) => void
  /** 直前の別宅住居セットメソッド */
  setPrevAnotherHousing?: (
    futureV2Housing: FutureV2Housing | InputHousing | undefined,
  ) => void
  /** 次の将来住居の家タイプセットメソッド */
  setNextHouseType: (nextHousetype: NextHouseType) => void
  /** 次の将来住居の開始年齢セットメソッド */
  setNextScheduledAtAge: (nextScheduledAtAge: number | null | undefined) => void
  /** true: 別宅, false: 現在の住宅からの転居 */
  isAnotherHousing?: boolean
  /** 売却・購入用住み替えモーダルオープンフラグ */
  futureV2HousingForSaleModalStatus:
    | { targetId: number | undefined; isModalOpen: boolean }
    | undefined
  /** 賃貸化・購入用住み替えモーダルオープンフラグ */
  futureV2HousingForLendModalStatus:
    | { targetId: number | undefined; isModalOpen: boolean }
    | undefined
  /** 売却・購入用住み替えモーダルオープンメソッド */
  setFutureV2HousingForSaleModalStatus: (futureV2HousingForSaleModalStatus: {
    targetId: number | undefined
    isModalOpen: boolean
  }) => void
  /** 賃貸化・購入用住み替えモーダルオープンメソッド */
  setFutureV2HousingForLendModalStatus: (futureV2HousingForLendModalStatus: {
    targetId: number | undefined
    isModalOpen: boolean
  }) => void
  /** 削除後の処理メソッド */
  afterDestory: () => void
}

const HousingSectionItem: FC<HousingSectionItemProps> = (props) => {
  const { isManipulatedApproval } = useContext(SimulationDetailEditContext)
  const {
    isHousingForInput,
    futureV2Housing,
    commonCallApi,
    handleModalOpen,
    nextScheduledAtAge,
    setPrevMainHousing,
    setPrevAnotherHousing,
    setNextHouseType,
    setNextScheduledAtAge,
    isAnotherHousing,
    futureV2HousingForSaleModalStatus,
    futureV2HousingForLendModalStatus,
    setFutureV2HousingForSaleModalStatus,
    setFutureV2HousingForLendModalStatus,
  } = props

  const futureLendHousings = useSelector(
    (state: RootState) => state.accounts.futureItems?.future_lend_housings,
  )
  const futureHousingSales = useSelector(
    (state: RootState) => state.accounts.futureItems?.future_housing_sales,
  )
  const futureV2Housings = useSelector(
    (state: RootState) => state.accounts.futureItems?.future_v2_housings,
  )
  const inputHousings = useSelector(
    (state: RootState) => state.accounts.futureItems?.input_housings,
  )

  const nextFutureV2Housing = _find(
    futureV2Housings,
    (item) => item.prev_housing_id === futureV2Housing.id,
  )

  const prevInputHousing = _find(
    inputHousings,
    (item) => item.house_type !== HOUSE_TYPE.transfer,
  )

  const targetHousingSale = _find(
    futureHousingSales,
    (item) => item.future_v2_housing_id === futureV2Housing?.id,
  )
  const targetLendHousing = _find(
    futureLendHousings,
    (item) => item.future_v2_housing_id === futureV2Housing?.id,
  )
  const futureV2HousingForSale: any = _find(
    futureV2Housings,
    (item) => item.id === targetHousingSale?.future_v2_housing_id,
  )
  const futureV2HousingForLend = _find(
    futureV2Housings,
    (item) => item.id === targetLendHousing?.future_v2_housing_id,
  )
  const singleHousingSale = _find(
    futureHousingSales,
    (item) => item.housing_id === futureV2Housing?.id && !item.future_v2_housing_id,
  )
  const singleLendHousing = _find(
    futureLendHousings,
    (item) => item.housing_id === futureV2Housing?.id && !item.future_v2_housing_id,
  )

  const hasSingleSaleOrLend = !!singleHousingSale || !!singleLendHousing

  const isFilledTillLastAge = useMemo(() => {
    return (
      (futureV2Housing.house_type === HOUSE_TYPE.rent &&
        Number(_last(futureV2Housing.future_v2_housing_rents)?.living_end_at_age) >=
          LAST_AGE) ||
      Number(futureV2Housing.scheduled_at_age) >= LAST_AGE
    )
  }, [futureV2Housing])

  const handlingType = !!targetHousingSale
    ? (HANDLING_TYPE.sale as HandlingType)
    : !!targetLendHousing
    ? (HANDLING_TYPE.lend as HandlingType)
    : futureV2Housing.house_type === HOUSE_TYPE.rent
    ? (HANDLING_TYPE.rent as HandlingType)
    : (HANDLING_TYPE.none as HandlingType)

  const getPrevHousing = (handlingType: HandlingType) => {
    if (isHousingForInput && !futureV2Housing) {
      return prevInputHousing
    } else if (handlingType === HANDLING_TYPE.sale) {
      return futureV2HousingForSale
    } else if (handlingType === HANDLING_TYPE.lend) {
      return futureV2HousingForLend
    } else if (isAnotherHousing) {
      return futureV2Housing
    } else if (handlingType === HANDLING_TYPE.rent) {
      return futureV2Housing
    } else {
      return futureV2Housing
    }
  }

  const renderSingleItemTitle = (isSale: boolean) => {
    const yearText = isSale
      ? convertDateToJapaneseFormat(singleHousingSale?.sell_at, 'year')
      : convertDateToJapaneseFormat(singleLendHousing?.lend_start_at, 'year')
    const actionText = isSale ? '売却して住み替え' : '賃貸化して住み替え'

    return `${yearText} ${actionText}`
  }

  const getNextScheduledAtAge = (handlingType: HandlingType) => {
    let targetAge
    if (handlingType === HANDLING_TYPE.sale) {
      targetAge =
        futureV2HousingForSale?.house_type === HOUSE_TYPE.rent
          ? (_last(futureV2HousingForSale?.future_v2_housing_rents) as any)
              ?.living_end_at_age
          : futureV2HousingForSale?.scheduled_at_age
    } else if (handlingType === HANDLING_TYPE.lend) {
      targetAge =
        futureV2HousingForLend?.house_type === HOUSE_TYPE.rent
          ? (_last(futureV2HousingForLend?.future_v2_housing_rents) as any)
              ?.living_end_at_age
          : futureV2HousingForLend?.scheduled_at_age
    } else {
      targetAge = futureV2Housing?.scheduled_at_age
    }

    return targetAge
  }

  const getItemTitle = (handlingType: HandlingType) => {
    let yearText: string
    let actionText: string

    if (handlingType === HANDLING_TYPE.sale) {
      yearText = convertDateToTwoTypesYearsDateFormat(
        targetHousingSale?.sell_at,
        'japaneseYear',
      )
      actionText = '売却して住み替え'
    } else if (handlingType === HANDLING_TYPE.lend) {
      yearText = convertDateToTwoTypesYearsDateFormat(
        targetLendHousing?.lend_start_at,
        'japaneseYear',
      )
      actionText = '賃貸化して住み替え'
    } else if (isAnotherHousing) {
      const action = futureV2Housing.house_type === HOUSE_TYPE.rent ? '契約' : '購入'
      yearText = convertDateToTwoTypesYearsDateFormat(
        futureV2Housing?.scheduled_at,
        'japaneseYear',
      )
      actionText = '別宅を' + action
    } else if (handlingType === HANDLING_TYPE.rent) {
      yearText = convertDateToTwoTypesYearsDateFormat(
        futureV2Housing?.scheduled_at,
        'japaneseYear',
      )
      actionText =
        futureV2Housing.house_type === HOUSE_TYPE.rent ? '賃貸へ引っ越し' : '住居を購入'
    } else {
      yearText = convertDateToTwoTypesYearsDateFormat(
        futureV2Housing?.scheduled_at,
        'japaneseYear',
      )
      actionText =
        futureV2Housing.house_type === HOUSE_TYPE.other
          ? 'その他の住居に転居'
          : '住居を購入'
    }

    return `${yearText} ${actionText}`
  }

  const itemTitle = getItemTitle(handlingType)
  const prevHousing = getPrevHousing(handlingType)
  const targetNextScheduledAtAge = getNextScheduledAtAge(handlingType)

  return (
    <>
      <Typography className="text-black-900" isBold>
        {itemTitle}
      </Typography>
      <div className="border-l-[3px] border-black-500 mx-[10px]">
        {/** MEMO: 売却・住み替え先について: 自己保有->購入のパターン */}
        {handlingType === HANDLING_TYPE.sale && (
          <FutureHousingSaleBody
            futureHousingSale={targetHousingSale as FutureHousingSale}
            commonCallApi={commonCallApi}
            nextScheduledAtAge={nextScheduledAtAge}
            setNextHouseType={setNextHouseType}
            setNextScheduledAtAge={setNextScheduledAtAge}
            setPrevHousing={setPrevMainHousing}
            futureV2HousingForPurchaseModalStatus={futureV2HousingForSaleModalStatus}
            setFutureV2HousingForPurchaseModalStatus={
              setFutureV2HousingForSaleModalStatus
            }
            afterDestory={props.afterDestory}
          />
        )}
        {/** MEMO: 賃貸化・住み替え先について: 自己保有->購入のパターン */}
        {handlingType === HANDLING_TYPE.lend && (
          <FutureLendHousingForm
            futureLendHousing={targetLendHousing as FutureLendHousing}
            nextScheduledAtAge={nextScheduledAtAge}
            commonCallApi={commonCallApi}
            setNextHouseType={setNextHouseType}
            setNextScheduledAtAge={setNextScheduledAtAge}
            setPrevHousing={setPrevMainHousing}
            futureV2HousingForPurchaseModalStatus={futureV2HousingForLendModalStatus}
            setFutureV2HousingForPurchaseModalStatus={
              setFutureV2HousingForLendModalStatus
            }
            afterDestory={props.afterDestory}
          />
        )}

        {/** MEMO: 住み替え先について: 別宅・賃貸->購入のパターン */}
        {(handlingType === HANDLING_TYPE.none || handlingType === HANDLING_TYPE.rent) && (
          <FutureV2HousingBody
            title={isAnotherHousing ? '別宅について' : '住み替えについて'}
            futureV2Housing={futureV2Housing as FutureV2Housing}
            commonCallApi={commonCallApi}
            afterDestory={props.afterDestory}
          />
        )}
      </div>

      {!nextFutureV2Housing && (!!singleHousingSale || !!singleLendHousing) && (
        <>
          <Typography className="text-black-900" isBold>
            {renderSingleItemTitle(!!singleHousingSale)}
          </Typography>
          <div className="border-l-[3px] border-black-500 mx-[10px]">
            {/** MEMO: 売却: 住み替え先が未登録パターン */}
            {singleHousingSale && (
              <FutureHousingSaleBody
                futureHousingSale={singleHousingSale as FutureHousingSale}
                nextScheduledAtAge={nextScheduledAtAge}
                commonCallApi={commonCallApi}
                setNextHouseType={setNextHouseType}
                setNextScheduledAtAge={setNextScheduledAtAge}
                setPrevHousing={setPrevMainHousing}
                futureV2HousingForPurchaseModalStatus={futureV2HousingForSaleModalStatus}
                setFutureV2HousingForPurchaseModalStatus={
                  setFutureV2HousingForSaleModalStatus
                }
                afterDestory={props.afterDestory}
              />
            )}

            {/** MEMO: 賃貸化: 住み替え先が未登録パターン */}
            {singleLendHousing && (
              <FutureLendHousingForm
                futureLendHousing={singleLendHousing as FutureLendHousing}
                nextScheduledAtAge={nextScheduledAtAge}
                commonCallApi={commonCallApi}
                setNextHouseType={setNextHouseType}
                setNextScheduledAtAge={setNextScheduledAtAge}
                setPrevHousing={setPrevMainHousing}
                futureV2HousingForPurchaseModalStatus={futureV2HousingForLendModalStatus}
                setFutureV2HousingForPurchaseModalStatus={
                  setFutureV2HousingForLendModalStatus
                }
                afterDestory={props.afterDestory}
              />
            )}
          </div>
        </>
      )}

      {nextFutureV2Housing && (
        <HousingSectionItem
          futureV2Housing={nextFutureV2Housing}
          commonCallApi={commonCallApi}
          handleModalOpen={handleModalOpen}
          nextScheduledAtAge={nextScheduledAtAge}
          setPrevMainHousing={setPrevMainHousing}
          setPrevAnotherHousing={setPrevAnotherHousing}
          setNextHouseType={setNextHouseType}
          setNextScheduledAtAge={setNextScheduledAtAge}
          futureV2HousingForSaleModalStatus={futureV2HousingForSaleModalStatus}
          futureV2HousingForLendModalStatus={futureV2HousingForLendModalStatus}
          setFutureV2HousingForSaleModalStatus={setFutureV2HousingForSaleModalStatus}
          setFutureV2HousingForLendModalStatus={setFutureV2HousingForLendModalStatus}
          afterDestory={props.afterDestory}
        />
      )}

      {!nextFutureV2Housing && (
        <>
          {!isFilledTillLastAge &&
            (hasSingleSaleOrLend ? (
              <Tooltip
                item={
                  <Typography className="fs-12">
                    住み替え先が登録されていないため
                    <br />
                    次の住居を検討できません
                  </Typography>
                }
                itemClassName="w-[220px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
              >
                <span>
                  <SimulationNewButton
                    buttonTitle="次の住居を追加する"
                    isDisabled={hasSingleSaleOrLend || !isManipulatedApproval}
                    onClick={() => {
                      setPrevAnotherHousing && setPrevAnotherHousing(prevHousing)
                      setPrevMainHousing && setPrevMainHousing(prevHousing)
                      setNextScheduledAtAge &&
                        setNextScheduledAtAge(targetNextScheduledAtAge)
                      handleModalOpen()
                    }}
                  />
                </span>
              </Tooltip>
            ) : (
              <span>
                <SimulationNewButton
                  buttonTitle="次の住居を追加する"
                  isDisabled={hasSingleSaleOrLend || !isManipulatedApproval}
                  onClick={() => {
                    setPrevAnotherHousing && setPrevAnotherHousing(prevHousing)
                    setPrevMainHousing && setPrevMainHousing(prevHousing)
                    setNextScheduledAtAge &&
                      setNextScheduledAtAge(targetNextScheduledAtAge)
                    handleModalOpen()
                  }}
                />
              </span>
            ))}

          {isFilledTillLastAge && (
            <Typography className="text-black-900" isBold>
              終身
            </Typography>
          )}
        </>
      )}
    </>
  )
}

export default HousingSectionItem
