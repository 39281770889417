import React, { FC, useEffect, useContext, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Typography from '@/components/v2/atoms/Typography'
import { setPageMeta } from '@/containers/pageMetasSlice'
import { destroyInputCar, fetchInputCars } from '@/containers/inputs/inputCarsSlice'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import {
  convertDateToTwoTypesYearsDateFormat,
  convertLocaleString,
} from '@/models/commonsModelFunc'
import { LOAN_FLG } from '@/models/inputs/inputCarsModelFunc'
import { RootState } from '@/store'
import MyDataListWrapper from '@/templates/v2/myData/_ListWrapper'

import CircleTitle from '@/components/v2/molecules/CircleTitle'
import Button from '@/components/v2/atoms/Button'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import { useParams } from 'react-router-dom'
import { AccountContext } from '@/models/accountsModelFunc'
import { useAccountControl } from '@/hooks/useAccountControl'
import moment from 'moment'

const MyDataCar: FC = () => {
  const { showFlashMsg } = useFlashAlert()
  const dispatch = useDispatch()
  const { isAdmin } = useContext(AccountContext)
  const { account_id } = useParams<{ account_id?: string }>()

  const { handleMovePage } = useCustomHistory()
  const { isMyDataManipulatedApproval } = useAccountControl()
  const { inputCars } = useSelector((state: RootState) => state.inputCars)

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'マイデータ | 車・バイク',
        description: 'マイデータ | 車・バイク',
        keyword: 'マイデータ | 車・バイク',
      }),
    )
    if (inputCars.length == 0) dispatch(fetchInputCars())
  }, [])

  const inputCarUpdatedAt = useMemo(() => {
    const unixTimes = inputCars.map((item) =>
      Number(moment(item?.updated_at).format('X')),
    )
    return unixTimes.length !== 0
      ? `(最終更新日: ${moment(String(Math.max(...unixTimes)), 'X').format(
          'YYYY/MM/DD',
        )})`
      : undefined
  }, [inputCars])

  return (
    <MyDataListWrapper
      title="車・バイク"
      buttonTitle="新しく追加する"
      onClick={
        inputCars.length > 0
          ? () =>
              handleMovePage(
                isAdmin
                  ? `/v2/accounts/${account_id}/myData/cars/new`
                  : '/v2/myData/cars/new',
              )
          : undefined
      }
      updatedAt={inputCarUpdatedAt}
    >
      {inputCars.length === 0 && (
        <div>
          <Typography
            className="pb-[15px] md:pb-[24px] text-[15px] md:text-[18px] text-center"
            isBold
          >
            車・バイクが登録されていません。
            <br className="md:hidden" />
            下記のボタンから登録してみましょう。
          </Typography>
          <Button
            className="w-[104px] md:w-[112px] h-[26px] md:h-[37px] rounded-[4px] mx-auto bg-white border border-secondary-500 hover:bg-secondary-50"
            isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
            onClick={() =>
              handleMovePage(
                isAdmin
                  ? `/v2/accounts/${account_id}/myData/cars/new`
                  : '/v2/myData/cars/new',
              )
            }
          >
            <Typography
              className="relative bottom-[1px] text-[12px] md:text-[14px] text-secondary-500"
              isBold
            >
              新しく追加する
            </Typography>
          </Button>
        </div>
      )}

      {inputCars.map((item: any, index: number) => {
        const isLoanFlgTodo = item.loan_flg === LOAN_FLG.todo

        const handleDestroy = () => {
          const result = confirm('本当に削除してよろしいですか？')
          if (!result) return

          const destroyFunc = new Promise((resolve) => {
            const newValues = { params: { id: item!.id } }
            dispatch(destroyInputCar(newValues, () => resolve('')))
          })

          Promise.all([destroyFunc]).then(() => {
            dispatch(fetchInputCars())
            showFlashMsg('削除しました')
          })
        }

        return (
          <React.Fragment key={item.id}>
            <div className="mt-30">
              <div className="sm:flex block justify-between">
                <CircleTitle
                  title={`お車${index + 1}`}
                  classNameTitle="text-[20px] md:text-[24px]"
                />
                <div className="flex justify-end my-[10px]">
                  <Button
                    className="w-[84px] md:w-[112px] h-[26px] md:h-[37px] mr-[10px] rounded-[4px] bg-white border border-secondary-500 hover:bg-secondary-50"
                    isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
                    onClick={() =>
                      handleMovePage(
                        isAdmin
                          ? `/v2/accounts/${account_id}/myData/cars/edit/${item.id}`
                          : `/v2/myData/cars/edit/${item.id}`,
                      )
                    }
                  >
                    <Typography
                      className="relative bottom-[1px] text-[12px] md:text-[14px] text-secondary-500"
                      isBold
                    >
                      編集する
                    </Typography>
                  </Button>
                  <Button
                    className="w-[84px] md:w-[112px] h-[26px] md:h-[37px] mr-0 md:mr-[9px] rounded-[4px] bg-white border border-black-600 hover:bg-secondary-50"
                    isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
                    onClick={() => handleDestroy()}
                  >
                    <Typography
                      className="relative bottom-[1px] text-[12px] md:text-[14px] text-black-600"
                      isBold
                    >
                      削除する
                    </Typography>
                  </Button>
                </div>
              </div>

              <table className="w-[100%] mb-[60px] last:mb-0 text-[12px] md:text-[14px]">
                <tbody>
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="w-[30%] px-[16px] md:px-[30px]">
                      <Typography isBold>名前</Typography>
                    </td>
                    <td className="w-[70%] px-[16px] md:px-[30px]">
                      <Typography>{item?.car_name}</Typography>
                    </td>
                  </tr>
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="px-[16px] md:px-[30px]">
                      <Typography isBold>乗車期間</Typography>
                    </td>
                    <td className="px-[16px] md:px-[30px]">
                      <Typography>
                        {`${convertDateToTwoTypesYearsDateFormat(
                          item?.stop_riding_at,
                          'yearMonthWithJapaneseYear',
                        )}まで`}
                      </Typography>
                    </td>
                  </tr>
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="px-[16px] md:px-[30px]">
                      <Typography isBold>ローンの有無</Typography>
                    </td>
                    <td className="px-[16px] md:px-[30px]">
                      <Typography>{isLoanFlgTodo ? '支払い中' : '支払いなし'}</Typography>
                    </td>
                  </tr>

                  {isLoanFlgTodo && (
                    <tr className="h-[45px] odd:bg-black-100">
                      <td className="px-[16px] md:px-[30px]">
                        <Typography isBold>毎月のローン返済額</Typography>
                      </td>
                      <td className="px-[16px] md:px-[30px]">
                        <Typography>
                          {isLoanFlgTodo
                            ? `${convertLocaleString(item?.loan_amount_monthly)}円/月`
                            : '未選択'}
                        </Typography>
                      </td>
                    </tr>
                  )}

                  {isLoanFlgTodo && (
                    <tr className="h-[45px] odd:bg-black-100">
                      <td className="px-[16px] md:px-[30px]">
                        <Typography isBold>返済金利</Typography>
                      </td>
                      <td className="px-[16px] md:px-[30px]">
                        <Typography>
                          {isLoanFlgTodo
                            ? `${item?.loan_interest_rate_percent}%`
                            : '未選択'}
                        </Typography>
                      </td>
                    </tr>
                  )}

                  {isLoanFlgTodo && (
                    <tr className="h-[45px] odd:bg-black-100">
                      <td className="px-[16px] md:px-[30px]">
                        <Typography isBold>ボーナス月の上乗せ金額</Typography>
                      </td>
                      <td className="px-[16px] md:px-[30px]">
                        <Typography>
                          {isLoanFlgTodo
                            ? `1回あたり ${convertLocaleString(
                                item?.loan_bonus_monthly,
                              )}円`
                            : '未選択'}
                        </Typography>
                      </td>
                    </tr>
                  )}

                  {isLoanFlgTodo && (
                    <tr className="h-[45px] odd:bg-black-100">
                      <td className="px-[16px] md:px-[30px]">
                        <Typography isBold>返済期間</Typography>
                      </td>
                      <td className="px-[16px] md:px-[30px]">
                        <Typography>
                          {isLoanFlgTodo
                            ? `${convertDateToTwoTypesYearsDateFormat(
                                item?.loan_end_at,
                                'yearMonthWithJapaneseYear',
                              )}まで`
                            : '未選択'}
                        </Typography>
                      </td>
                    </tr>
                  )}

                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="px-[16px] md:px-[30px]">
                      <Typography isBold>車検代</Typography>
                    </td>
                    <td className="px-[16px] md:px-[30px]">
                      <Typography>
                        {`${convertLocaleString(
                          item?.vehicle_inspection_fee_yearly,
                        )}円/年`}
                      </Typography>
                    </td>
                  </tr>
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="px-[16px] md:px-[30px]">
                      <Typography isBold>自動車税</Typography>
                    </td>
                    <td className="px-[16px] md:px-[30px]">
                      <Typography>
                        {`${convertLocaleString(item?.automobile_tax_yearly)}円/年`}
                      </Typography>
                    </td>
                  </tr>
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="px-[16px] md:px-[30px]">
                      <Typography isBold>保険料</Typography>
                    </td>
                    <td className="px-[16px] md:px-[30px]">
                      <Typography>
                        {`${convertLocaleString(item?.insurance_premium_monthly)}円/月`}
                      </Typography>
                    </td>
                  </tr>
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="px-[16px] md:px-[30px]">
                      <Typography isBold>ガソリン代</Typography>
                    </td>
                    <td className="px-[16px] md:px-[30px]">
                      <Typography>
                        {`${convertLocaleString(item?.gasoline_fee_monthly)}円/月`}
                      </Typography>
                    </td>
                  </tr>
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="px-[16px] md:px-[30px]">
                      <Typography isBold>駐車場代</Typography>
                    </td>
                    <td className="px-[16px] md:px-[30px]">
                      <Typography>
                        {`${convertLocaleString(item?.parking_fee_monthly)}円/月`}
                      </Typography>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </React.Fragment>
        )
      })}
    </MyDataListWrapper>
  )
}

export default MyDataCar
