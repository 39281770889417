import React, { FC } from 'react'
import { useField, useForm } from 'react-final-form'
import Typography from '@/components/v2/atoms/Typography'
import SelectBox from '@/components/v2/molecules/SelectBox'
import { INPUT_HOUSING_TYPE2_MANSION_BUTTON_ITEMS } from '@/constants/formItem/inputs/inputHousings'
import { API_FLAG } from '@/models/commonsModelFunc'
import {
  HOUSING_TYPE2,
  OWNED_HOUSE_TYPE,
  getTargetSelfOwnedAttributesName,
} from '@/models/commonHousingModelFunc'
import { required } from '@/utils/validate'
import Tooltip from '@/components/v2/atoms/Tooltip'
import HelpCircleIcon from '@/assets/images/v2/help-circle.svg'

interface HousingType2Props {
  isFuture?: boolean
}

const HousingType2: FC<HousingType2Props> = (props) => {
  const { isFuture } = props
  const targetHousingSelfOwnedAttributesName = getTargetSelfOwnedAttributesName(isFuture)

  const { change } = useForm()
  const {
    input: { value: houseType },
  } = useField(`${targetHousingSelfOwnedAttributesName}.house_type`)

  const handleChangeHousingType2 = (value: number) => {
    change(`${targetHousingSelfOwnedAttributesName}.housing_type2`, value)
    if (value !== HOUSING_TYPE2.fullOrder && value !== HOUSING_TYPE2.sumuStock) {
      change(`${targetHousingSelfOwnedAttributesName}.loan_type2`, API_FLAG.off)
    }
  }

  return (
    <>
      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
        <div className="min-w-[210px]">
          <Typography
            className="flex min-w-[fit-content] pb-[10px] lg:pb-0 text-[14px] text-black-800"
            isBold
          >
            購入時のお家の状態
            <div className="mx-[10px]">
              <Tooltip
                itemClassName="w-[180px] md:w-[250px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                item={
                  <Typography>
                    正しい情報をご選択ください。
                    <br />
                    併せて引渡年も正しく入力することで当時の住宅ローン減税を適用して計算することが可能です。
                    <br />
                    また、不動産の将来価値試算もこちらの情報をベースに算出します。
                  </Typography>
                }
              >
                <HelpCircleIcon />
              </Tooltip>
            </div>
          </Typography>
        </div>
        <SelectBox
          name={`${targetHousingSelfOwnedAttributesName}.housing_type2`}
          placeholder="選択する"
          isFullWidth
          options={
            houseType == OWNED_HOUSE_TYPE.mansion
              ? INPUT_HOUSING_TYPE2_MANSION_BUTTON_ITEMS
              : [
                  {
                    value: HOUSING_TYPE2.sumuStock,
                    label: '長期優良・低炭素住宅（注文）',
                  },
                  {
                    value: HOUSING_TYPE2.lowCarbon,
                    label: '長期優良・低炭素住宅（建売または買取再販）',
                  },
                  {
                    value: HOUSING_TYPE2.zehFullOrder,
                    label: 'ZEH水準省エネ住宅（注文）',
                  },
                  {
                    value: HOUSING_TYPE2.zehNewly,
                    label: 'ZEH水準省エネ住宅（建売または買取再販）',
                  },
                  {
                    value: HOUSING_TYPE2.energySavingFullOrder,
                    label: '省エネ基準適合住宅（注文）',
                  },
                  {
                    value: HOUSING_TYPE2.energySavingNewly,
                    label: '省エネ基準適合住宅（建売または買取再販）',
                  },
                  {
                    value: HOUSING_TYPE2.newly,
                    label: 'その他住宅（建売または買取再販）',
                  },
                  {
                    value: HOUSING_TYPE2.fullOrder,
                    label: 'その他住宅（注文）',
                  },
                  {
                    value: HOUSING_TYPE2.energySavingSecondhand,
                    label: '中古（省エネ以上）',
                  },
                  {
                    value: HOUSING_TYPE2.secondhand,
                    label: '中古（通常）',
                  },
                ]
          }
          validate={required}
          onChange={(e: any) => handleChangeHousingType2(e.target.value)}
        />
      </div>
    </>
  )
}

export default HousingType2
