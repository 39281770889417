import ApiClient from '@/api/apiClient'

export const postInputYearlyLivingCost = async (values: any) => {
  return await ApiClient.patch('/input_yearly_living_costs', () => {}, values)
}

export const patchInputYearlyLivingCost = async (values: any) => {
  return await ApiClient.patch('/input_yearly_living_costs', () => {}, values)
}

export const deleteInputYearlyLivingCost = async () => {
  return await ApiClient.delete('/input_yearly_living_costs', () => {})
}

export const getInputYearlyLivingCostV2 = async () => {
  return await ApiClient.get(
    '/input_yearly_living_costs',
    (response: any) => response,
    {},
    undefined,
    'v2',
  )
}
