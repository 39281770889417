import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'react-final-form'
import { isEmpty as _isEmpty, isUndefined as _isUndefined } from 'lodash'
import MarginContainer from '@/components/v2/atoms/MarginContainer'
import Paper from '@/components/v2/atoms/Paper'
import Heading from '@/components/v2/atoms/Heading'
import Typography from '@/components/v2/atoms/Typography'
import TextField from '@/components/v2/atoms/TextField'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import FieldBlock from '@/components/v2/molecules/FieldBlock'
import WholeSubmitErrorMessage from '@/components/v2/molecules/WholeSubmitErrorMessage'
import { createAccountActivate } from '@/containers/accountsSlice'
import { removeAllFlashAlerts, setFlashAlert } from '@/containers/flashAlertsSlice'
import { setPageMeta } from '@/containers/pageMetasSlice'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import { RootState } from '@/store'
import { composeValidators, required, mailAddress } from '@/utils/validate'

const Activate: FC = () => {
  const dispatch = useDispatch()
  const { handleMovePage } = useCustomHistory()

  const [submitErrors, setSubmitErrors] = useState<any>(undefined)
  const { flashAlertMessageObjects } = useSelector(
    (state: RootState) => state.flashAlerts,
  )

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: '過去に退会された方',
        description: '過去に退会された方',
        keyword: '過去に退会された方',
      }),
    )
  }, [])

  const showFlashMsg = () => {
    if (!_isEmpty(flashAlertMessageObjects)) {
      dispatch(removeAllFlashAlerts())
    }

    dispatch(
      setFlashAlert({
        flashAlertMessageObjects: [
          { message: 'メールを送信しました', variant: 'success' },
        ],
      }),
    )
  }

  const handleAccountSubmit = async (values: any) => {
    const submitErrors = await new Promise((resolve) =>
      dispatch(createAccountActivate(values, resolve)),
    )

    if (_isUndefined(submitErrors)) {
      handleMovePage('/v2/accounts/activateMailSendCompleted')
      showFlashMsg()
    } else {
      setSubmitErrors(submitErrors)
    }
  }

  return (
    <MarginContainer className="sm:w-524 mx-auto pt-40 sm:pt-73">
      <Paper className="mx-8 sm:mx-0 px-16 py-24 sm:p-36 space-y-24 bg-white">
        <Heading className="text-center">過去に退会された方</Heading>
        <Typography className="text-14 text-black-700 text-center">
          過去に退会された方は、
          <span className="sm:inline-block">
            過去にご登録いただいていたメールアドレスを入力することで、
          </span>
          <span className="sm:inline-block">
            蓄積されていた情報を復元し、続きから利用することができます。
          </span>
        </Typography>
        <Form
          onSubmit={() => {}}
          render={({ handleSubmit, values, errors }) => (
            <form className="space-y-24" onSubmit={handleSubmit}>
              <FieldBlock label="メールアドレス">
                <TextField
                  type="text"
                  name="account.mail_address"
                  autoComplete="off"
                  placeholder="sample@example.com"
                  isFullWidth
                  validate={composeValidators(required, mailAddress)}
                />
              </FieldBlock>

              <WholeSubmitErrorMessage submitErrors={submitErrors} />
              <div className="flex justify-center">
                <ButtonPrimary
                  className="h-64 w-full md:w-285 text-white"
                  isDisabled={Object.keys(errors).length > 0}
                  onClick={() => handleAccountSubmit(values)}
                >
                  <Typography
                    className="bottom-2 flex-auto pl-30 relative text-16"
                    isBold
                  >
                    アカウント復元メールを送信
                  </Typography>
                  <i className="ico chevron-right mr-17 text-24" />
                </ButtonPrimary>
              </div>
            </form>
          )}
        />
      </Paper>
    </MarginContainer>
  )
}

export default Activate
