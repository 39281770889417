import React, { FC, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import clsx from 'clsx'
import { find as _find, isUndefined as _isUndefined, last as _last, sortBy as _sortBy, } from 'lodash'
import ChevronRightIcon from '@/assets/images/v2/chevron-right.svg'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import Grid from '@/components/v2/atoms/Grid'
import Modal from '@/components/v2/atoms/Modal'
import Typography from '@/components/v2/atoms/Typography'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import SubmitErrorMessage from '@/components/v2/molecules/SubmitErrorMessage'
import { fetchAccountFutureItems } from '@/containers/accountsSlice'
import {
  createFutureIncomeV2,
  updateFutureIncome,
} from '@/containers/futures/futureIncomesSlice'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import {
  API_FLAG,
  extractYearOrMonthFromDate,
  THIS_YEAR_NUMBER,
  isNotEmptyValue,
} from '@/models/commonsModelFunc'
import {
  ACTIVE_FLG,
  CURRENT_SALARY_INCREASE_RATE_SETTING_TYPE,
} from '@/models/commonIncomeBasesModelFunc'
import {
  CHANGE_TYPE,
  formatIncomeChanges,
  RETIREMENT_AGE,
} from '@/models/commonIncomeChangesModelFunc'
import { PERSON_FLG } from '@/models/familyStructuresModelFunc'
import { FutureAssetCreateValues } from '@/models/futures/futureAssetsModel'
import { buildUpdateInitialValues } from '@/models/futures/futureIncomeModelFunc'
import { RootState } from '@/store'
import RateSettingType from '@/templates/v2/simulations/incomes/RateSettingType'
import RatePercent from '@/templates/v2/simulations/incomes/RatePercent'
import CurrentWork from '@/templates/v2/simulations/incomes/CurrentWork'
import IncomeChange from '@/templates/v2/simulations/incomes/IncomeChange'
import RetirementAge from '@/templates/v2/simulations/incomes/RetirementAge'

interface FutureIncomeModalFormProps {
  isEdit: boolean
  /** true: オープン, false: クローズ */
  isOpen: boolean
  /** onCloseメソッド */
  onClose: () => void

  id: number | null
  /** personFlg */
  personFlg: number
  /** 選択中の将来結婚ID */
  selectedMarriageId?: number | undefined
  /* FutureItemsを参照するID */
  futureBaseId?: number
}

const FutureIncomeModalForm: FC<FutureIncomeModalFormProps> = (props) => {
  const { isOpen, onClose, isEdit, personFlg, selectedMarriageId } = props
  const dispatch = useDispatch()
  const { showFlashMsg } = useFlashAlert()
  const futureMarriages = useSelector(
    (state: RootState) => state.accounts.futureItems?.future_marriages,
  )

  const { futureItems } = useSelector((state: RootState) => state.accounts)
  const { person_current_age, spouse_current_age, spouse_marrying_age, is_married } =
    futureItems?.family_info || {}

  const futureIncomeBases = useSelector(
    (state: RootState) => state.accounts.futureItems?.future_income_bases,
  )
  const familyInfo = useSelector(
    (state: RootState) => state.accounts.futureItems?.family_info,
  )

  const targetFutureMarriage = _find(
    futureMarriages,
    (item) => item.id == selectedMarriageId,
  )

  const age = useMemo(() => {
    let age: number | undefined | null = person_current_age

    if (personFlg === PERSON_FLG.spouse) {
      age = is_married ? spouse_current_age : spouse_marrying_age

      age = targetFutureMarriage
        ? THIS_YEAR_NUMBER -
          Number(
            extractYearOrMonthFromDate(targetFutureMarriage.partner_birth_day, 'year'),
          )
        : age
    }

    return age
  }, [familyInfo, targetFutureMarriage])

  const completedCallback = () => {
    dispatch(fetchAccountFutureItems(props.futureBaseId))
    showFlashMsg(isEdit ? '更新しました' : '作成しました')
    onClose && onClose()
  }

  const targetFutureIncome = useMemo(
    () => _find(futureIncomeBases, (item) => item.person_flg === personFlg && item.id === props.id),
    [futureIncomeBases],
  )

  const initialValues = useMemo(() => {
    const tmpInitialValues = isEdit
      ? buildUpdateInitialValues(targetFutureIncome)
      : {
          future_income: { future_income_changes_attributes: [] },
          retirement_age: { re_employment_type: API_FLAG.off },
        }

    return { ...tmpInitialValues, age, isEdit, personFlg }
  }, [targetFutureIncome])

  const handleCreate = (values: any) => {
    const pickValues: any = values
    const newValues = {
      future_marriage_id: selectedMarriageId,
      future_income: {
        ...pickValues.future_income,
        person_flg: personFlg,
        active_flg: ACTIVE_FLG.active,
      },
    }

    const lastIncomeChange: any = _last(
      _sortBy(values?.future_income?.future_income_changes_attributes, (v: any) => Number(v.scheduled_quit_at_age),),
    )

    const lastAge = !!lastIncomeChange ? Number(lastIncomeChange.scheduled_quit_at_age) : newValues.future_income.quit_current_job_at_age

    const retirementAgeItem = {
      ...pickValues.retirement_age,
      change_type: CHANGE_TYPE.retirementAge,
      person_flg: personFlg,
      scheduled_at_age: lastAge,
    }

    if (_isUndefined(newValues.future_income.future_income_changes_attributes)) {
      newValues.future_income.future_income_changes_attributes = [retirementAgeItem]
    } else {
      const {
        quit_current_job_at_age,
        future_income_changes_attributes,
      } = newValues.future_income

      const formatedIncomeChanges = formatIncomeChanges(
        future_income_changes_attributes,
        quit_current_job_at_age,
      )

      formatedIncomeChanges.push(retirementAgeItem)
      newValues.future_income.future_income_changes_attributes = formatedIncomeChanges
    }

    dispatch(
      createFutureIncomeV2(
        {
          account: {
            future_income_bases_attributes: [{ ...newValues.future_income }],
          },
        },
        () => completedCallback(),
      ),
    )
  }

  const handleUpdate = (values: any) => {
    const pickValues: any = values
    const newValues = {
      id: targetFutureIncome?.id,
      future_marriage_id: selectedMarriageId,
      future_income: {
        ...pickValues.future_income,
        id: targetFutureIncome?.id,
        person_flg: personFlg,
        active_flg: ACTIVE_FLG.active,
      },
    }

    const lastIncomeChange: any = _last(
      _sortBy(values?.future_income?.future_income_changes_attributes, (v: any) => Number(v.scheduled_quit_at_age),),
    )

    const lastAge = !!lastIncomeChange ? Number(lastIncomeChange.scheduled_quit_at_age) : newValues.future_income.quit_current_job_at_age

    const retirementAgeItem = {
      ...pickValues.retirement_age,
      change_type: CHANGE_TYPE.retirementAge,
      person_flg: personFlg,
      scheduled_at_age: lastAge,
    }

    if (_isUndefined(newValues.future_income.future_income_changes_attributes)) {
      newValues.future_income.future_income_changes_attributes = [retirementAgeItem]
    } else {
      const {
        quit_current_job_at_age,
        future_income_changes_attributes,
      } = newValues.future_income

      const formatedIncomeChanges = formatIncomeChanges(
        future_income_changes_attributes,
        quit_current_job_at_age,
      )

      formatedIncomeChanges.push(retirementAgeItem)
      newValues.future_income.future_income_changes_attributes = formatedIncomeChanges
    }

    dispatch(updateFutureIncome(newValues, () => completedCallback()))
  }

  const handleSubmit = (values: any) => {
    let result = true
    if (
      isNotEmptyValue(values?.change_type) ||
      isNotEmptyValue(values?.scheduled_quit_at_age) ||
      (Number(values?.change_type) === CHANGE_TYPE.employment || Number(values?.change_type) === CHANGE_TYPE.retirement) && isNotEmptyValue(values?.insurance_pension_type) ||
      (Number(values?.change_type) === CHANGE_TYPE.employment || Number(values?.change_type) === CHANGE_TYPE.retirementOfficer) && isNotEmptyValue(values?.current_salary_increase_rate_setting_type) ||
      Number(values?.change_type) === CHANGE_TYPE.employment && values?.current_salary_increase_rate_setting_type === CURRENT_SALARY_INCREASE_RATE_SETTING_TYPE.average && isNotEmptyValue(values?.industry) ||
      (Number(values?.change_type) === CHANGE_TYPE.employment || Number(values?.change_type) === CHANGE_TYPE.retirementOfficer) && values?.current_salary_increase_rate_setting_type === CURRENT_SALARY_INCREASE_RATE_SETTING_TYPE.input && isNotEmptyValue(values?.current_salary_increase_rate_percent)
    ) {
      window.confirm("入力途中の項目があります。\r\n必要事項を全て入力の上、追加ボタンを押してから保存ボタンを押してください。\r\nもしくは、最後のフォームは全て空欄のまま保存を押してください。")
      return
    }
    isEdit ? handleUpdate(values) : handleCreate(values)
  }

  return (
    <Modal
      className="w-[95%] xl:w-[1140px]"
      isOpen={isOpen}
      isDisableBackdropClick
      onClose={onClose}
    >
      <CircleTitle
        className="pb-[32px]"
        title={`${personFlg == 0 ? 'ご本人' : '配偶者'}の収入の変化を設定する`}
        classNameTitle="text-[19px] md:text-[24px]"
      />
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        mutators={{ ...arrayMutators }}
        render={({ handleSubmit, values, errors, submitErrors, submitFailed }) => {
          const { quit_current_job_at_age } = values?.future_income || {}
          return (
            <form onSubmit={handleSubmit} className="pb-30">
              <Grid className="grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px]">
                <RateSettingType />

                {values?.future_income?.current_salary_increase_rate_setting_type ===
                  CURRENT_SALARY_INCREASE_RATE_SETTING_TYPE.input && <RatePercent />}

                {values?.age < RETIREMENT_AGE && <CurrentWork />}
              </Grid>

              <Grid className="grid-cols-1 gap-x-[40px] gap-y-[16px] pt-40">
                {!!quit_current_job_at_age &&
                  quit_current_job_at_age != RETIREMENT_AGE &&
                  values?.age < RETIREMENT_AGE && (
                    <IncomeChange selectedMarriageId={selectedMarriageId} />
                  )}
              </Grid>

              <SubmitErrorMessage show={!submitErrors && submitFailed} />

              { submitFailed && errors.input_changes_age_restriction && (
                <Typography className={clsx('text-center text-alert-600', "text-12")}>
                  { errors.input_changes_age_restriction }
                </Typography>
              )}
              <div className="pt-30">
                <ButtonPrimary
                  className={clsx(
                    'w-[246px] h-[64px] mx-auto mb-[16px] ',
                  )}
                  onClick={() => handleSubmit(values)}
                >
                  <Typography
                    className="bottom-2 flex-auto pl-30 relative text-16 text-white"
                    isBold
                  >
                    保存する
                  </Typography>
                  <ChevronRightIcon className="mr-17" />
                </ButtonPrimary>
              </div>
            </form>
          )
        }}
      />
    </Modal>
  )
}

export default FutureIncomeModalForm

