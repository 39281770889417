import React, { FC, useMemo } from 'react'
import { SubmissionErrors } from 'final-form'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { includes as _includes, split as _split, filter as _filter } from 'lodash'
import clsx from 'clsx'
import { isUndefined as _isUndefined } from 'lodash'
import Typography from '@/components/v1/atoms/Typography'
import { ORIGINAL_RED_COLOR } from '@/constants/theme'

const useStyles = makeStyles(() =>
  createStyles({
    submitErrorText: {
      color: ORIGINAL_RED_COLOR,
      textAlign: 'center',
    },
  }),
)

interface SubmitErrorMessageProps {
  /** クラス名 */
  className?: string
  /** サブミットエラー */
  submitErrors: SubmissionErrors
}

const WholeSubmitErrorMessage: FC<SubmitErrorMessageProps> = (props) => {
  const classes = useStyles()
  const { className, submitErrors } = props
  const isIncludePunctuation = useMemo(() => _includes(submitErrors?.other, '。'), [
    submitErrors?.other,
  ])
  const errorMessages = _split(submitErrors?.other, '。')
  const filterErrorMessages = _filter(errorMessages, (item) => item !== '')

  return (
    <>
      {!_isUndefined(submitErrors?.other) && (
        <Typography className={clsx(classes.submitErrorText, className)}>
          {!isIncludePunctuation
            ? submitErrors.other
            : filterErrorMessages.map((errorMessage) => (
                <div key={errorMessage}>{`${errorMessage}。`}</div>
              ))}
        </Typography>
      )}
    </>
  )
}

export default WholeSubmitErrorMessage
