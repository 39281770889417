import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { isNull as _isNull } from 'lodash'
import Chip from '@/components/v2/atoms/Chip'
import Typography from '@/components/v2/atoms/Typography'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import { convertLocaleString } from '@/models/commonsModelFunc'
import { RootState } from '@/store'
import moment from 'moment'
import Tooltip from '@/components/v2/atoms/Tooltip'
import HelpCircleWhiteIcon from '@/assets/images/v2/help-circle-white.svg'

interface SimulationSummaryBodyProps {
  /** クラス名 */
  className?: string
  /** PC 用Tableタグのクラス名 */
  classNameSummaryTable?: string
}

const SimulationSummaryBody: FC<SimulationSummaryBodyProps> = (props) => {
  const { className, classNameSummaryTable } = props
  const {
    detailFutureBaseV2: { asset_summary },
  } = useSelector((state: RootState) => state.futureBases)

  const renderValuation = (value: number | null) => {
    if (_isNull(value)) return 'お金の寿命は十分です。'

    if (value < 80) {
      return (
        <>
          お金の寿命が短すぎます。
          <br />
          シミュレーションを見直してみましょう。
        </>
      )
    } else if (value >= 80 && value < 90) {
      return (
        <>
          お金の寿命が少し短いかもしれません。
          <br />
          シミュレーションを見直してみましょう。
        </>
      )
    } else if (value >= 80 && value < 90) {
      return 'お金の寿命は比較的十分です。'
    } else {
      return 'お金の寿命は十分です。'
    }
  }

  const colorByAmount = (value: number | null) => {
    return value == null || value >= 0 ? 'text-black' : 'text-[#FF0000]'
  }

  const classes = {
    summaryTable: {
      table: `w-full hidden md:block ${classNameSummaryTable}`,
      tr: 'border-b border-black-400',
      th: 'py-[16px] border-r border-dashed border-black-500',
      thTypography: 'flex justify-center text-12 md:text-14 lg:text-16',
      td: 'py-[16px] border-r border-dashed border-black-500',
      tdTypography: 'text-center text-12 md:text-14 lg:text-16',
      chip:
        'flex items-center h-[50px] mx-auto pb-[2px] bg-graph-200 rounded-[39px] text-[12px] md:text-[14px] text-white',
      chipBreakLine: 'block xl:hidden',
    },
    summaryTableMobile: {
      td: 'border border-black-400 text-center w-1/3',
    },
  }

  return (
    <div className={className}>
      <CircleTitle
        classNameTitle="text-20 md:text-24"
        className="pb-[12px] md:pb-[24px]"
        title="サマリー"
      />
      <table className="block md:hidden w-full border-black-400">
        <tbody>
          <tr>
            <th className={`${classes.summaryTableMobile.td} py-[8px]`}>
              <Typography className="leading-normal text-[10px]">
                ① <br />
                マイデータに <br />
                登録中の情報で <br />
                生活を続けた場合 <br />
              </Typography>
            </th>
            <th className={`${classes.summaryTableMobile.td} py-[8px]`}>
              <Typography className="leading-normal text-[10px]">
                ② <br />
                将来の予定を <br />
                実行した場合 <br />
                (作成中シミュレーション) <br />
              </Typography>
            </th>
            <th className={`${classes.summaryTableMobile.td} bg-alert-500 py-[8px]`}>
              <Typography className="leading-normal text-[10px]">①と②の比較</Typography>
            </th>
          </tr>

          <tr>
            <td colSpan={3} className="text-center w-full bg-[#DAEAF1] py-[4px]">
              <Typography className="text-[10px] text-black-900">
                今年度末の純資産
              </Typography>
            </td>
          </tr>

          <tr>
            <td className={`${classes.summaryTableMobile.td} py-[24px]`}>
              <Typography
                className={`text-[18px] ${colorByAmount(
                  asset_summary?.input.current_year_asset_total,
                )}`}
              >
                {convertLocaleString(asset_summary?.input.current_year_asset_total)}
                <span className="text-[14px]">万円</span>
              </Typography>
            </td>
            <td className={`${classes.summaryTableMobile.td} py-[24px]`}>
              <Typography
                className={`text-[18px] ${colorByAmount(
                  asset_summary?.future.current_year_asset_total,
                )}`}
              >
                {convertLocaleString(asset_summary?.future.current_year_asset_total)}
                <span className="text-[14px]">万円</span>
              </Typography>
            </td>
            <td className={`${classes.summaryTableMobile.td} py-[24px] bg-alert-500`}>
              <Typography
                className={`text-[18px] ${colorByAmount(
                  asset_summary?.difference.current_year_asset_total,
                )}`}
              >
                {convertLocaleString(asset_summary?.difference.current_year_asset_total)}
                <span className="text-[14px]">万円</span>
              </Typography>
            </td>
          </tr>

          <tr>
            <td colSpan={3} className="text-center w-full bg-[#F9DEA4] py-[4px]">
              <Typography className="text-[10px] text-black-900">
                65歳時点の純資産総額
              </Typography>
            </td>
          </tr>

          <tr>
            <td className={`${classes.summaryTableMobile.td} py-[24px]`}>
              <Typography
                className={`text-[18px] ${colorByAmount(
                  asset_summary?.input.year_of_age_65_asset,
                )}`}
              >
                {convertLocaleString(asset_summary?.input.year_of_age_65_asset)}
                <span className="text-[14px]">万円</span>
              </Typography>
            </td>
            <td className={`${classes.summaryTableMobile.td} py-[24px]`}>
              <Typography
                className={`text-[18px] ${colorByAmount(
                  asset_summary?.future.year_of_age_65_asset,
                )}`}
              >
                {convertLocaleString(asset_summary?.future.year_of_age_65_asset)}
                <span className="text-[14px]">万円</span>
              </Typography>
            </td>
            <td className={`${classes.summaryTableMobile.td} py-[24px] bg-alert-500`}>
              <Typography
                className={`text-[18px] ${colorByAmount(
                  asset_summary?.difference.year_of_age_65_asset,
                )}`}
              >
                {convertLocaleString(asset_summary?.difference.year_of_age_65_asset)}
                <span className="text-[14px]">万円</span>
              </Typography>
            </td>
          </tr>

          <tr>
            <td colSpan={3} className="text-center w-full bg-[#C5DDE0] py-[4px]">
              <Typography className="text-[10px] text-black-900">お金の寿命</Typography>
            </td>
          </tr>

          <tr>
            <td className={`${classes.summaryTableMobile.td} py-[12px]`}>
              <Typography className="text-[18px]">
                {`${asset_summary?.input.money_dead_age ?? ' ー '}歳`}
                <span className="text-[14px]">{`（${
                  asset_summary?.input.money_dead_year ?? ' ー '
                }年）`}</span>
              </Typography>
            </td>
            <td className={`${classes.summaryTableMobile.td} py-[12px]`}>
              <Typography className="text-[18px]">
                {`${asset_summary?.future.money_dead_age ?? ' ー '}歳`}
                <span className="text-[14px]">{`（${
                  asset_summary?.future.money_dead_year ?? ' ー '
                }年）`}</span>
              </Typography>
            </td>
            <td className={`${classes.summaryTableMobile.td} py-[12px] bg-alert-500`}>
              <Typography className="text-[18px]">
                {`${asset_summary?.difference.money_dead_year ?? ' ー '}年`}
              </Typography>
            </td>
          </tr>

          <tr>
            <td colSpan={3} className="text-center w-full bg-[#FFA8A8] py-[4px]">
              <Typography className="text-[10px] text-black-900">評価</Typography>
            </td>
          </tr>

          <tr>
            <td className={`${classes.summaryTableMobile.td} py-[16px] px-[6px]`}>
              <Typography className="leading-normal text-[12px] text-left">
                {renderValuation(asset_summary?.input.money_dead_age)}
              </Typography>
            </td>
            <td className={`${classes.summaryTableMobile.td} py-[16px] px-[6px]`}>
              <Typography className="leading-normal text-[12px] text-left">
                {renderValuation(asset_summary?.future.money_dead_age)}
              </Typography>
            </td>
            <td
              className={`${classes.summaryTableMobile.td} py-[16px] bg-alert-500`}
            ></td>
          </tr>
        </tbody>
      </table>

      <table className={`${classes.summaryTable.table}`}>
        <tbody>
          <tr className={`${classes.summaryTable.tr}`}>
            <th className={`${classes.summaryTable.th} min-w-[170px] md:min-w-[210px]`} />
            <th
              className={`${classes.summaryTable.th} min-w-[120px] md:min-w-[150px] xl:min-w-[230px]`}
            >
              <Chip className={`${classes.summaryTable.chip}`} isBold>
                {`${moment().format('YYYY')}`}年度末の
                <br className={`${classes.summaryTable.chipBreakLine}`} />
                純資産総額
              </Chip>
            </th>
            <th
              className={`${classes.summaryTable.th} min-w-[120px] md:min-w-[150px] xl:min-w-[230px]`}
            >
              <Chip className={`${classes.summaryTable.chip} bg-graph-500`} isBold>
                65歳時点の
                <br className={`${classes.summaryTable.chipBreakLine}`} />
                純資産総額
              </Chip>
            </th>
            <th
              className={`${classes.summaryTable.th} min-w-[120px] md:min-w-[140px] xl:min-w-[230px]`}
            >
              <Chip className={`${classes.summaryTable.chip} bg-graph-300`} isBold>
                お金の寿命
              </Chip>
            </th>
            <th className="min-w-[280px] lg:min-w-[410px] py-[16px] bg-graph-400">
              <div className="flex justify-center items-center gap-x-[6px]">
                <Typography className="text-[18px] text-white">評価</Typography>
                <Tooltip
                  item={
                    <Typography className="leading-normal text-[10px] text-secondary-500 text-left">
                      ・お金の寿命が90歳以上 <br />
                      お金の寿命は十分です。 <br />
                      <br />
                      ・お金の寿命が80〜89歳 <br />
                      お金の寿命が少し短いかもしれません。 <br />
                      シミュレーションを見直してみましょう。 <br />
                      <br />
                      ・お金の寿命が80歳未満 <br />
                      お金の寿命が短すぎます。 <br />
                      シミュレーションを見直してみましょう。 <br />
                    </Typography>
                  }
                  itemClassName="w-[80px] md:w-[252px] p-[8px] bg-secondary-50"
                >
                  <HelpCircleWhiteIcon />
                </Tooltip>
              </div>
            </th>
          </tr>
          <tr className={`${classes.summaryTable.tr}`}>
            <td className={`${classes.summaryTable.td}`}>
              <Typography className={`${classes.summaryTable.thTypography}`}>
                ①マイデータに登録中の情報で
                <br />
                生活を続けた場合
              </Typography>
            </td>
            <td className={`${classes.summaryTable.td}`}>
              <Typography className={`${classes.summaryTable.tdTypography}`} isBold>
                {`${convertLocaleString(
                  asset_summary?.input.current_year_asset_total,
                )}万円`}
              </Typography>
            </td>
            <td className={`${classes.summaryTable.td}`}>
              <Typography className={`${classes.summaryTable.tdTypography}`} isBold>
                {`${convertLocaleString(asset_summary?.input.year_of_age_65_asset)}万円`}
              </Typography>
            </td>
            <td className={`${classes.summaryTable.td}`}>
              <Typography className={`${classes.summaryTable.tdTypography}`} isBold>
                {`${asset_summary?.input.money_dead_age ?? ' ー '}歳`}
                <br className="block xl:hidden" />
                {`（${asset_summary?.input.money_dead_year ?? ' ー '}年）`}
              </Typography>
            </td>
            <td className={`${classes.summaryTable.td}`}>
              <Typography className={`${classes.summaryTable.tdTypography}`} isBold>
                {renderValuation(asset_summary?.input.money_dead_age)}
              </Typography>
            </td>
          </tr>
          <tr className={`${classes.summaryTable.tr}`}>
            <td className={`${classes.summaryTable.td}`}>
              <Typography className={`${classes.summaryTable.thTypography}`}>
                ②将来の予定を実行した場合
                <br />
                (作成中シミュレーション)
              </Typography>
            </td>
            <td className={`${classes.summaryTable.td}`}>
              <Typography className={`${classes.summaryTable.tdTypography}`} isBold>
                {`${convertLocaleString(
                  asset_summary?.future.current_year_asset_total,
                )}万円`}
              </Typography>
            </td>
            <td className={`${classes.summaryTable.td}`}>
              <Typography className={`${classes.summaryTable.tdTypography}`} isBold>
                {`${convertLocaleString(asset_summary?.future.year_of_age_65_asset)}万円`}
              </Typography>
            </td>
            <td className={`${classes.summaryTable.td}`}>
              <Typography className={`${classes.summaryTable.tdTypography}`} isBold>
                {`${asset_summary?.future.money_dead_age ?? ' ー '}歳`}
                <br className="block xl:hidden" />
                {`（${asset_summary?.future.money_dead_year ?? ' ー '}年）`}
              </Typography>
            </td>
            <td className={`${classes.summaryTable.td}`}>
              <Typography className={`${classes.summaryTable.tdTypography}`} isBold>
                {renderValuation(asset_summary?.future.money_dead_age)}
              </Typography>
            </td>
          </tr>
          <tr className={`${classes.summaryTable.tr} bg-alert-300`}>
            <td className={`${classes.summaryTable.td}`}>
              <Typography className={`${classes.summaryTable.thTypography}`}>
                ①と②の比較
              </Typography>
            </td>
            <td className={`${classes.summaryTable.td}`}>
              <Typography className={`${classes.summaryTable.tdTypography}`} isBold>
                {`${convertLocaleString(
                  asset_summary?.difference.current_year_asset_total,
                )}万円`}
              </Typography>
            </td>
            <td className={`${classes.summaryTable.td}`}>
              <Typography className={`${classes.summaryTable.tdTypography}`} isBold>
                {`${convertLocaleString(
                  asset_summary?.difference.year_of_age_65_asset,
                )}万円`}
              </Typography>
            </td>
            <td className={`${classes.summaryTable.td}`}>
              <Typography className={`${classes.summaryTable.tdTypography}`} isBold>
                {`${asset_summary?.difference.money_dead_year ?? ' ー '}年`}
              </Typography>
            </td>
            <td className={`${classes.summaryTable.td}`} />
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default SimulationSummaryBody
