import React, { FC } from 'react'
import Typography from '@/components/v2/atoms/Typography'
import MyDataPanelBalance from '@/templates/v2/myData/_myDataPanelBalance'
import MyDataPanelIncome from '@/templates/v2/myData/_myDataPanelIncome'
import MyDataPanelSpending from '@/templates/v2/myData/_myDataPanelSpending'

interface MyDataPanelBodyProps {
  /** クラス名 */
  className?: string
  /** 日付 */
  date: string
  /** 表示期間 */
  term?: string
}

const MyDataPanelBody: FC<MyDataPanelBodyProps> = (props) => {
  const { className, date, term } = props

  return (
    <div className={className}>
      <Typography className="pb-16 text-18 text-center text-black-900" isBold>
        {date}
      </Typography>
      <div className="md:flex items-center justify-evenly">
        <MyDataPanelIncome term={term} />
        <Typography className="text-32 hidden md:block" isBold>
          ー
        </Typography>
        <MyDataPanelSpending term={term} />
        <Typography className="text-32 hidden md:block" isBold>
          =
        </Typography>
        <MyDataPanelBalance term={term} />
      </div>
    </div>
  )
}

export default MyDataPanelBody
