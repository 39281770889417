import React, { FC, useMemo } from 'react'
import { find as _find } from 'lodash'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import { PERSON_FLG } from '@/models/familyStructuresModelFunc'
import { CONTRIBUTION_TYPE } from '@/models/inputs/inputContributionPensionsModelFunc'
import moment from 'moment'
import Typography from '@/components/v2/atoms/Typography'

interface ConfirmMydataTableProps {}

const ConfirmMydataTable: FC<ConfirmMydataTableProps> = (props) => {
  const { familyStructures } = useSelector((state: RootState) => state.familyStructures)
  const { familyStructureChildren } = useSelector(
    (state: RootState) => state.familyStructures,
  )
  const { inputSalaries } = useSelector((state: RootState) => state.inputSalaries)
  const { inputIncomeBases } = useSelector((state: RootState) => state.inputIncomes)
  const { inputCorporatePensions } = useSelector(
    (state: RootState) => state.inputCorporatePensions,
  )
  const { inputContributionPensions } = useSelector(
    (state: RootState) => state.inputContributionPensions,
  )
  const { inputPublicPensions } = useSelector(
    (state: RootState) => state.inputPublicPensions,
  )
  const { inputMonthlyCost } = useSelector((state: RootState) => state.inputMonthlyCosts)
  const {
    input_yearly_living_cost_electronics,
    input_yearly_living_cost_ceremonies,
    input_yearly_living_cost_home_comings,
    input_yearly_living_cost_travels,
  } = useSelector((state: RootState) => state.inputYearlyLivingCosts)
  const { inputHousings } = useSelector((state: RootState) => state.inputHousings)
  const { inputCars } = useSelector((state: RootState) => state.inputCars)
  const { inputInsurances } = useSelector((state: RootState) => state.inputInsurances)
  const { inputEstateInvestments } = useSelector(
    (state: RootState) => state.inputEstateInvestments,
  )
  const { inputRisk } = useSelector((state: RootState) => state.inputRisk)
  const { inputCustomItems } = useSelector((state: RootState) => state.inputCustomItems)
  const { isGetAccountShowInputLoading } = useSelector(
    (state: RootState) => state.accounts,
  )

  const tableItems = useMemo(() => {
    // find function
    const isPerson = (item: any) => item.person_flg === PERSON_FLG.person
    const isSpouse = (item: any) => item.person_flg === PERSON_FLG.spouse
    const isIdeco = (item: any) => item.contribution_type === CONTRIBUTION_TYPE.ideco
    const isCorporate = (i: any) => i.contribution_type === CONTRIBUTION_TYPE.corporate
    const isPersonIdeco = (item: any) => isPerson(item) && isIdeco(item)
    const isPersonCorp = (item: any) => isSpouse(item) && isCorporate(item)
    const isSpouseIdeco = (item: any) => isSpouse(item) && isIdeco(item)
    const isSpouseCorp = (item: any) => isSpouse(item) && isCorporate(item)

    // format function
    const updatedAt = (item: any | any[], findFn?: (item: any) => boolean) => {
      const findItem = Array.isArray(item) && findFn ? item.find(findFn) : item
      return findItem != null ? moment(findItem.updated_at).format('YYYY/MM/DD') : ''
    }
    const latestUpdatedAt = (arr: any[]) => {
      const unixTimes = arr.map((item) => Number(moment(item?.updated_at).format('X')))
      return unixTimes.length !== 0
        ? moment(String(Math.max(...unixTimes)), 'X').format('YYYY/MM/DD')
        : ''
    }

    // new update history
    const inputYearlyLivingCosts = [
      ...input_yearly_living_cost_electronics,
      ...input_yearly_living_cost_ceremonies,
      ...input_yearly_living_cost_home_comings,
      ...input_yearly_living_cost_travels,
    ]
    const contributionPensions = inputContributionPensions
    const i = (title: string, updatedAt: string) => ({ title, updatedAt })
    const updatHistory = [
      i('ご家族の情報', updatedAt(familyStructures)),
      ...familyStructureChildren.map((children) =>
        i(`第${children.child_index}子の教育費`, updatedAt(children)),
      ),
      i('ご本人のお仕事', updatedAt(inputSalaries, isPerson)),
      i('ご本人の副業', updatedAt(inputSalaries.find(isPerson)?.input_sub_income)),
      i('ご本人の収入の変化', updatedAt(inputIncomeBases, isPerson)),
      i('ご本人の企業年金', updatedAt(inputCorporatePensions, isPerson)),
      i('ご本人の個人型確定拠出年金', updatedAt(contributionPensions, isPersonIdeco)),
      i('ご本人の企業型確定拠出年金', updatedAt(contributionPensions, isPersonCorp)),
      i('ご本人の公的年金', updatedAt(inputPublicPensions, isPerson)),
      i('配偶者のお仕事', updatedAt(inputSalaries, isSpouse)),
      i('配偶者の副業', updatedAt(inputSalaries.find(isSpouse)?.input_sub_income)),
      i('配偶者の収入の変化', updatedAt(inputIncomeBases, isSpouse)),
      i('配偶者の企業年金', updatedAt(inputCorporatePensions, isSpouse)),
      i('配偶者の個人型確定拠出年金', updatedAt(contributionPensions, isSpouseIdeco)),
      i('配偶者の企業型確定拠出年金', updatedAt(contributionPensions, isSpouseCorp)),
      i('配偶者の公的年金', updatedAt(inputPublicPensions, isSpouse)),
      i('基本生活費', updatedAt(inputMonthlyCost)),
      i('特別な支出・大きな支出', latestUpdatedAt(inputYearlyLivingCosts)),
      i('現在の住居', latestUpdatedAt(inputHousings)),
      i('車・バイク', latestUpdatedAt(inputCars)),
      i('保険', latestUpdatedAt(inputInsurances)),
      i('ご本人がなくなった場合の万が一の保障', updatedAt(inputRisk, isPerson)),
      i('配偶者がなくなった場合の万が一の保障', updatedAt(inputRisk, isSpouse)),
      i('不動産投資', latestUpdatedAt(inputEstateInvestments)),
      i('カスタム設定', latestUpdatedAt(inputCustomItems)),
    ]

    // validation
    return updatHistory.filter(({ title, updatedAt }) => {
      if (title.includes('公的年金') && updatedAt !== '') return false
      if (title === '車・バイク' && updatedAt !== '') return false
      return true
    })
  }, [
    familyStructures,
    familyStructureChildren,
    inputSalaries,
    inputIncomeBases,
    inputCorporatePensions,
    inputContributionPensions,
    inputPublicPensions,
    inputMonthlyCost,
    input_yearly_living_cost_electronics,
    input_yearly_living_cost_ceremonies,
    input_yearly_living_cost_home_comings,
    input_yearly_living_cost_travels,
    inputHousings,
    inputCars,
    inputInsurances,
    inputEstateInvestments,
    inputRisk,
    inputCustomItems,
    isGetAccountShowInputLoading,
  ])

  return (
    <div className="overflow-y-scroll h-[216px] md:w-[480px] w-[95%] bg-black-100 px-[14px] py-[6px]">
      <table className="w-full">
        <tbody>
          <tr className="border-b border-black-600 border-solid">
            <td className="p-[6px]">
              <Typography className="leading-normal text-[14px]">設定項目</Typography>
            </td>
            <td className="p-[6px]">
              <Typography className="leading-normal text-[14px]">最終更新日</Typography>
            </td>
          </tr>
          {tableItems.map((item, i) => (
            <tr
              key={i}
              className={
                i !== tableItems.length - 1
                  ? 'border-b border-black-400 border-solid'
                  : ''
              }
            >
              <td className="p-[6px]">
                <Typography className="leading-normal text-[14px]">
                  {item.title}
                </Typography>
              </td>
              <td className="p-[6px]">
                <Typography className="leading-normal text-[14px]">
                  {item.updatedAt !== '' ? item.updatedAt : '登録なし'}
                </Typography>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default ConfirmMydataTable
