import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setPageMeta } from '@/containers/pageMetasSlice'
import MarginContainer from '@/components/v2/atoms/MarginContainer'
import LogindRedirect from '@/layouts/v2/LogindRedirect'
import MailSendCompleted from '@/templates/v2/accounts/commons/MailSendCompleted'

const AccountPasswordResetMailSendCompleted: FC = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'パスワードリセットメール送信完了',
        description: 'パスワードリセットメール送信完了',
        keyword: 'パスワードリセットメール送信完了',
      }),
    )
  }, [])

  return (
    <LogindRedirect>
      <MarginContainer className="w-full sm:w-524 mx-auto pt-40 sm:pt-73">
        <MailSendCompleted title="パスワード再発行">
          パスワード再設定のメールをお送りしました。
          <br />
          メールをご確認いただきパスワードを設定してください。
        </MailSendCompleted>
      </MarginContainer>
    </LogindRedirect>
  )
}

export default AccountPasswordResetMailSendCompleted
