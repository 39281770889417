import React, { FC, useContext, useMemo } from 'react'
import { useField } from 'react-final-form'
import { DescriptionContext } from '@/hooks/useDescriptionTemplates'
import { castNumberWithoutOperator, THIS_YEAR_NUMBER } from '@/models/commonsModelFunc'
import {
  getTargetLoanAttributesName,
  getTargetSelfOwnedAttributesName,
  isMansionType,
} from '@/models/commonHousingModelFunc'
import { selectFutureV2LoanObjectName } from '@/models/futures/futureV2HousingLoansModelFunc'
import { selectFutureV2LoanCalculationObjectName } from '@/models/futures/futureV2HousingLoanCalculationsModelFunc'
import { selectLoanCalculationObjectName } from '@/models/inputs/inputLoanCalculationsModelFunc'
import {
  selectLoanObjectName,
  isPairLoan,
  selectInputLoanObjectNumber,
  isFlatLoan,
} from '@/models/inputs/inputLoansModelFunc'
import { isSplitedLoan } from '@/models/inputs/inputHousingSelfOwnedModelFunc'
import {
  commaNumberMaxLength,
  composeValidators,
  maxLength,
  required,
  zeroOrMoreNumber,
} from '@/utils/validate'

import Typography from '@/components/v2/atoms/Typography'
import NumberField from '@/components/v2/molecules/NumberField'
import HelpCircleIcon from '@/assets/images/v2/help-circle.svg'
import Tooltip from '@/components/v2/atoms/Tooltip'

interface RepaymentAmountDetailProps {
  /** true: シミュレーション, false: 現状 */
  isFuture?: boolean
  /** true: 建物用ローン, false: 土地用ローン・分割なし */
  isBuilding?: boolean
  /** true: 配偶者, false: ご本人 */
  isSpouse?: boolean
}

const RepaymentAmountDetail: FC<RepaymentAmountDetailProps> = (props) => {
  const { isFuture, isBuilding, isSpouse } = props
  const { age, targetAge } = useContext(DescriptionContext)
  const loanObjectNum = selectInputLoanObjectNumber(isBuilding, isSpouse)
  const flatLoanObjectNum = isBuilding ? 2 : 0
  const rateChangeLoanObjectNum = isBuilding ? 3 : 1
  const targetHousingSelfOwnedAttributesName = getTargetSelfOwnedAttributesName(isFuture)
  const targetLoanAttributesName = getTargetLoanAttributesName(isFuture)
  const selectTargetLoanObjectName = isFuture
    ? selectFutureV2LoanObjectName
    : selectLoanObjectName
  const selectTargetLoanCalcObjectName = isFuture
    ? selectFutureV2LoanCalculationObjectName
    : selectLoanCalculationObjectName

  const {
    input: { value: selfOwnedAttributes },
  } = useField(targetHousingSelfOwnedAttributesName)
  const {
    input: { value: houseType },
  } = useField(`${targetHousingSelfOwnedAttributesName}.house_type`)
  const {
    input: { value: purchasedAtYear },
  } = useField(`${targetHousingSelfOwnedAttributesName}.purchased_at_year`)
  const {
    input: { value: propertyPriceManyen },
  } = useField(`${targetHousingSelfOwnedAttributesName}.property_price_manyen`)
  const {
    input: { value: propertyPriceLandManyen },
  } = useField(`${targetHousingSelfOwnedAttributesName}.property_price_land_manyen`)
  const {
    input: { value: propertyPriceBuildingManyen },
  } = useField(`${targetHousingSelfOwnedAttributesName}.property_price_building_manyen`)
  const {
    input: { value: loanAttributes },
  } = useField(targetLoanAttributesName)
  const {
    input: { value: loanLender },
  } = useField(selectTargetLoanObjectName(loanObjectNum, 'loan_lender'))
  const {
    input: { value: startedBorrowingTotalAmountLandManyen },
  } = useField('started_borrowing_total_amount_land_manyen')
  const {
    input: { value: firstStartedBorrowingAmountManyen },
  } = useField(`${targetLoanAttributesName}[0].started_borrowing_amount_manyen`)
  const {
    input: { value: repaymentPeriod },
  } = useField(selectTargetLoanCalcObjectName(loanObjectNum, 'repayment_period'))

  const targetPropertyPriceTotalManyen = useMemo(
    () =>
      isMansionType(houseType)
        ? castNumberWithoutOperator(propertyPriceManyen)
        : castNumberWithoutOperator(propertyPriceLandManyen) +
          castNumberWithoutOperator(propertyPriceBuildingManyen),
    [houseType],
  )

  const targetPurchasedAtAge = useMemo(
    () => targetAge || age + (Number(purchasedAtYear) - THIS_YEAR_NUMBER),
    [targetAge, age],
  )

  const repaymentEndAge = useMemo(
    () => Number(targetPurchasedAtAge) + Number(repaymentPeriod),
    [targetAge, repaymentPeriod],
  )

  const renderFlatStartedBorrowingTotal = () => {
    if (isSplitedLoan(selfOwnedAttributes)) {
      // 分割: している + 土地パターン: ペア以外・フラットローン
      if (
        isBuilding &&
        isSplitedLoan(selfOwnedAttributes) &&
        !isPairLoan(loanAttributes?.[0]?.loan_type) &&
        isFlatLoan(loanAttributes?.[0]?.loan_lender)
      ) {
        return (
          castNumberWithoutOperator(targetPropertyPriceTotalManyen) -
          castNumberWithoutOperator(startedBorrowingTotalAmountLandManyen)
        )
      }
      // 分割: している + 土地パターン: ペア以外・民間ローン
      if (
        isBuilding &&
        isSplitedLoan(selfOwnedAttributes) &&
        !isPairLoan(loanAttributes?.[0]?.loan_type) &&
        !isFlatLoan(loanAttributes?.[0]?.loan_lender)
      ) {
        return (
          castNumberWithoutOperator(targetPropertyPriceTotalManyen) -
          castNumberWithoutOperator(firstStartedBorrowingAmountManyen)
        )
      }

      // 分割: している + 土地パターン: ペア以外・民間ローン
      if (!isBuilding && isSplitedLoan(selfOwnedAttributes)) {
        return propertyPriceLandManyen
      }
    } else {
      // 分割: している + 土地パターン: ペア以外・フラットローン
      if (
        isBuilding &&
        isSplitedLoan(selfOwnedAttributes) &&
        !isPairLoan(loanAttributes?.[0]?.loan_type) &&
        isFlatLoan(loanAttributes?.[0]?.loan_lender)
      ) {
        return (
          castNumberWithoutOperator(targetPropertyPriceTotalManyen) -
          castNumberWithoutOperator(startedBorrowingTotalAmountLandManyen)
        )
      }
      // 分割: している + 土地パターン: ペア以外・民間ローン
      if (
        isBuilding &&
        isSplitedLoan(selfOwnedAttributes) &&
        !isPairLoan(loanAttributes?.[0]?.loan_type) &&
        !isFlatLoan(loanAttributes?.[0]?.loan_lender)
      ) {
        return (
          castNumberWithoutOperator(targetPropertyPriceTotalManyen) -
          castNumberWithoutOperator(firstStartedBorrowingAmountManyen)
        )
      }

      // 分割: している + 土地パターン: ペア以外・民間ローン
      if (!isBuilding && isSplitedLoan(selfOwnedAttributes)) {
        return propertyPriceLandManyen
      }
    }

    // 1回目(全パターン適用)
    return targetPropertyPriceTotalManyen
  }

  const renderStartedBorrowingTotal = () => {
    // 2回目
    // 分割: していない + ローン方式: ペア(配偶者) + ローン借入先: 民間ローン
    if (
      !isSplitedLoan(selfOwnedAttributes) &&
      isPairLoan(loanAttributes?.[0]?.loan_type)
    ) {
      return (
        castNumberWithoutOperator(targetPropertyPriceTotalManyen) -
        castNumberWithoutOperator(firstStartedBorrowingAmountManyen)
      )
    }
    // 分割: している + 土地パターン: ペア以外・民間ローン
    if (
      isSplitedLoan(selfOwnedAttributes) &&
      !isPairLoan(loanAttributes?.[0]?.loan_type) &&
      !isFlatLoan(loanAttributes?.[0]?.loan_lender)
    ) {
      return (
        castNumberWithoutOperator(targetPropertyPriceTotalManyen) -
        castNumberWithoutOperator(firstStartedBorrowingAmountManyen)
      )
    }
    // 分割: している + 土地パターン: ペア以外・フラットローン
    if (
      isSplitedLoan(selfOwnedAttributes) &&
      !isPairLoan(loanAttributes?.[0]?.loan_type) &&
      isFlatLoan(loanAttributes?.[0]?.loan_lender)
    ) {
      return (
        castNumberWithoutOperator(targetPropertyPriceTotalManyen) -
        castNumberWithoutOperator(startedBorrowingTotalAmountLandManyen)
      )
    }

    // 1回目(全パターン)
    return targetPropertyPriceTotalManyen
  }

  return (
    <>
      {isFlatLoan(loanLender) ? (
        <>
          <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
            <Typography
              className="min-w-[140px] lg:min-w-[210px] pb-[10px]  text-[14px] text-black-800"
              isBold
            >
              借入総額
            </Typography>
            <div className="flex flex-auto items-baseline">
              <NumberField
                isFullWidth
                name={
                  isBuilding
                    ? 'started_borrowing_total_amount_building_manyen'
                    : 'started_borrowing_total_amount_land_manyen'
                }
                defaultValue={renderFlatStartedBorrowingTotal()}
              />
              <Typography
                className="pl-[8px] text-[14px] text-black-800 min-w-[40px]"
                isBold
              >
                万円
              </Typography>
            </div>
          </div>

          <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-center">
            <div className="flex items-center min-w-[140px] lg:min-w-[220px] pb-[10px] md:pb-0 ">
              <Typography className="text-[14px] text-black-800" isBold>
                フラット適用部分の返済額
              </Typography>
              <div className="mx-[8px]">
                <Tooltip
                  item="フラット適用部分は、住宅金融支援機構からの借入分をご入力ください。変動金利部分のお借入れがある方は、その分を変動金利部分に設定してください。"
                  itemClassName="w-[200px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                >
                  <HelpCircleIcon />
                </Tooltip>
              </div>
            </div>

            <div className="flex flex-auto items-baseline">
              <NumberField
                isFullWidth
                name={selectTargetLoanCalcObjectName(
                  flatLoanObjectNum,
                  'started_borrowing_amount_manyen',
                )}
                validate={composeValidators(required, zeroOrMoreNumber, (value: any) =>
                  commaNumberMaxLength(value, 6),
                )}
              />
              <Typography
                className="pl-[8px] text-[14px] text-black-800  min-w-[40px]"
                isBold
              >
                万円
              </Typography>
            </div>
          </div>

          <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-center">
            <div className="flex items-center min-w-[140px] lg:min-w-[220px] pb-[10px] md:pb-0 ">
              <Typography className="text-[14px] text-black-800" isBold>
                変動金利部分の返済額
              </Typography>

              <div className="mx-[8px]">
                <Tooltip
                  item="変動金利部分とは、物件価格の90％以上を借りた場合に適用される借入です。"
                  itemClassName="w-[200px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                >
                  <HelpCircleIcon />
                </Tooltip>
              </div>
            </div>

            <div className="flex flex-auto items-baseline">
              <NumberField
                name={selectTargetLoanCalcObjectName(
                  rateChangeLoanObjectNum,
                  'started_borrowing_amount_manyen',
                )}
                isFullWidth
                disabled
                validate={(value: any) => commaNumberMaxLength(value, 6)}
              />
              <Typography
                className="pl-[8px] text-[14px] text-black-800  min-w-[40px]"
                isBold
              >
                万円
              </Typography>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
            <Typography
              className="min-w-[140px] lg:min-w-[210px] pb-[10px] text-[14px] text-black-800"
              isBold
            >
              {`${isFuture ? '想定する借入金額' : '当初の借入金額'}`}
            </Typography>
            <div className="flex flex-auto items-baseline">
              <NumberField
                isFullWidth
                name={selectTargetLoanCalcObjectName(
                  loanObjectNum,
                  'started_borrowing_amount_manyen',
                )}
                validate={composeValidators(required, zeroOrMoreNumber, (value: any) =>
                  commaNumberMaxLength(value, 7),
                )}
                defaultValue={renderStartedBorrowingTotal()}
              />
              <Typography
                className="pl-[8px] text-[14px] text-black-800  min-w-[40px]"
                isBold
              >
                万円
              </Typography>
            </div>
          </div>
        </>
      )}

      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline justify-between">
        <Typography
          className="min-w-[140px] lg:min-w-[210px] pb-[10px] text-[14px] text-black-800"
          isBold
        >
          {`${isFuture ? '想定する返済期間' : '返済期間'}`}
        </Typography>
        <div>
          <div className="flex flex-auto items-baseline">
            <NumberField
              isFullWidth
              name={selectTargetLoanCalcObjectName(loanObjectNum, 'repayment_period')}
              validate={composeValidators(required, zeroOrMoreNumber, (value: any) =>
                maxLength(value, 3),
              )}
            />
            <Typography
              className="pl-[8px] text-[14px] text-black-800  min-w-[40px]"
              isBold
            >
              年間
            </Typography>
          </div>
          {isFuture && (
            <div className="pt-5">
              <Typography
                className="text-[14px] text-black-800 text-right"
                isBold
              >{`${repaymentEndAge}歳で完済予定`}</Typography>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default RepaymentAmountDetail
