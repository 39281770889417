import React, { FC, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import arrayMutators from 'final-form-arrays'
import clsx from 'clsx'
import { find as _find, isEmpty as _isEmpty } from 'lodash'
import ChevronRightIcon from '@/assets/images/v2/chevron-right.svg'
import CrossIcon from '@/assets/images/v2/cross.svg'
import HelpCircleIcon from '@/assets/images/v2/help-circle.svg'
import PlusCircleIcon from '@/assets/images/v2/plus-circle.svg'
import Button from '@/components/v2/atoms/Button'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import Divider from '@/components/v2/atoms/Divider'
import Grid from '@/components/v2/atoms/Grid'
import Modal from '@/components/v2/atoms/Modal'
import Tooltip from '@/components/v2/atoms/Tooltip'
import Typography from '@/components/v2/atoms/Typography'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import NumberField from '@/components/v2/molecules/NumberField'
import NumberFieldDecimal from '@/components/v2/molecules/NumberFieldDecimal'
import SelectBox from '@/components/v2/molecules/SelectBox'
import SubmitErrorMessage from '@/components/v2/molecules/SubmitErrorMessage'
import {
  ANNUAL_TYPE_SELECT_ITEMS,
  INVESTMENT_TYPE_SELECT_ITEMS,
  IS_TAX_CHARGABLE_SELECT_ITEMS,
  CONTINUAL_TRANSFER_TYPE_RADIO_ITEMS,
  TRANSFER_TYPE_SELECT_ITEMS,
} from '@/constants/formItem/commonAssets'
import { TWO_TYPES_YEAR_SELECT_ITEMS } from '@/constants/formItem/commons'
import { fetchAccountFutureItems } from '@/containers/accountsSlice'
import { createGeneralStatistics } from '@/containers/generalStatisticsSlice'
import {
  createFutureAssetV2,
  updateFutureAssetV2,
} from '@/containers/futures/futureAssetsSlice'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import { AssetAnnual } from '@/models/assetAnnualsModel'
import {
  ANNUAL_TYPE,
  CONTINUAL_TRANSFER_TYPE,
  TRANSFER_TYPE,
} from '@/models/commonAssetsModelFunc'
import {
  convertDateYear,
  convertLocaleString,
  extractYearOrMonthFromDate,
  isNotEmptyValue,
  THIS_YEAR,
  THIS_YEAR_NUMBER,
} from '@/models/commonsModelFunc'
import { FutureAsset } from '@/models/futures/futureAssetsModel'
import { RootState } from '@/store'
import {
  buildStandardSelectItems,
  buildTwoTypesRangeYearSelectItems,
} from '@/utils/formItem'
import {
  commaNumberMaxLength,
  composeValidators,
  required,
  zeroOrMoreNumber,
  zeroGreaterThanNumber,
} from '@/utils/validate'

interface FutureMonthlyLivingCostModalFormProps {
  isEdit: boolean
  /** true: オープン, false: クローズ */
  isOpen: boolean
  /** onCloseメソッド */
  onClose: () => void

  id: number | null
  /* FutureItemsを参照するID */
  futureBaseId?: number
}

const FutureAssetModalForm: FC<FutureMonthlyLivingCostModalFormProps> = (props) => {
  const { isOpen, onClose, isEdit, id } = props
  const dispatch = useDispatch()
  const { showFlashMsg } = useFlashAlert()
  const futureAssets = useSelector(
    (state: RootState) => state.accounts.futureItems?.future_assets,
  )
  const { familyStructures } = useSelector((state: RootState) => state.familyStructures)

  const {
    detailFutureBaseV2: { statement_table_data },
  } = useSelector((state: RootState) => state.futureBases)

  const thisYearStatementData = statement_table_data?.[THIS_YEAR_NUMBER]

  useEffect(() => {
    const values = { general_statistics: { search_keys: [] } }
    dispatch(createGeneralStatistics(values))
  }, [])

  const handleSubmit = (values: any) => {
    const newValues = {
      future_asset: { ...values.future_asset },
    }
    const {
      transfer_type,
      continual_transfer_type,
      asset_annuals_attributes,
    } = values.future_asset

    // 投資設定種別: 一時金の投資を設定するを選択
    if (transfer_type == TRANSFER_TYPE.once) {
      delete newValues.future_asset.continual_transfer_type
      delete newValues.future_asset.continual_transfer_rate_percent
      delete newValues.future_asset.continual_transfer_input
      newValues.future_asset.investment_start_at = convertDateYear(
        values.investment_start_at_year,
      )
      newValues.future_asset.investment_end_at = null
    }

    // 投資設定種別: 積立金の投資を設定するを選択
    if (transfer_type == TRANSFER_TYPE.countinual) {
      newValues.future_asset.investment_start_at = convertDateYear(
        values.investment_start_at_year,
      )
      newValues.future_asset.investment_end_at = convertDateYear(
        values.investment_end_at_year,
      )

      delete newValues.future_asset.amount
      // 年間積立額種別: 金額で設定を選択
      if (continual_transfer_type === CONTINUAL_TRANSFER_TYPE.input) {
        delete newValues.future_asset.continual_transfer_rate_percent
      }

      // 年間積立額種別: 割合で設定を選択
      if (continual_transfer_type === CONTINUAL_TRANSFER_TYPE.rate) {
        delete newValues.future_asset.continual_transfer_input
      }
    }

    // 運用利回りの変化: あるを選択
    if (values.annual_type == ANNUAL_TYPE.yes) {
      newValues.future_asset.asset_annuals_attributes = asset_annuals_attributes.map(
        (annual: AssetAnnual) => ({
          ...annual,
          affected_at: convertDateYear(annual.affected_at_year),
        }),
      )
    }
    // 運用利回りの変化: ないを選択
    if (values.annual_type == ANNUAL_TYPE.no) {
      newValues.future_asset.asset_annuals_attributes = []
    }

    isEdit ? handleUpdate(newValues) : handleCreate(newValues)
  }

  const handleCreate = (newValues: any) => {
    dispatch(
      createFutureAssetV2(
        {
          account: {
            future_assets_attributes: [newValues.future_asset],
          },
        },
        () => {
          dispatch(fetchAccountFutureItems(props.futureBaseId))
          showFlashMsg(isEdit ? '更新しました' : '作成しました')
          onClose()
        },
      ),
    )
  }

  const handleUpdate = (newValues: any) => {
    dispatch(
      updateFutureAssetV2(newValues, id!, () => {
        dispatch(fetchAccountFutureItems(props.futureBaseId))
        showFlashMsg(isEdit ? '更新しました' : '作成しました')
        onClose()
      }),
    )
  }

  const handleAddAssetAnnualBlock = (
    assetAnnuals: Partial<any>[],
    index: number,
    change: (key: string, value: Partial<any>[]) => void,
  ) => {
    const isEnd = index + 1 == assetAnnuals.length
    isEnd ? assetAnnuals.push({}) : assetAnnuals.splice(index + 1, 0, {})

    change('future_asset.asset_annuals_attributes', assetAnnuals)
  }

  const initialValues = useMemo(() => {
    const targetFutureAsset = _find(futureAssets, { id })
    return isEdit && !!targetFutureAsset
      ? {
          future_asset: {
            ...(targetFutureAsset as FutureAsset),
            asset_annuals_attributes: _isEmpty(
              (targetFutureAsset as FutureAsset).asset_annuals,
            )
              ? [{}]
              : [
                  ...(targetFutureAsset as FutureAsset).asset_annuals.map(
                    (annual: AssetAnnual) => ({
                      ...annual,
                      affected_at_year: extractYearOrMonthFromDate(
                        annual.affected_at,
                        'year',
                      ),
                    }),
                  ),
                ],
          },
          annual_type: _isEmpty((targetFutureAsset as FutureAsset).asset_annuals)
            ? ANNUAL_TYPE.no
            : ANNUAL_TYPE.yes,
          investment_start_at_year: extractYearOrMonthFromDate(
            (targetFutureAsset as FutureAsset).investment_start_at,
            'year',
          ),
          investment_end_at_year: extractYearOrMonthFromDate(
            (targetFutureAsset as FutureAsset).investment_end_at,
            'year',
          ),
        }
      : {
          future_asset: {
            is_tax_chargable: true,
            asset_annuals_attributes: [{}],
          },
        }
  }, [isEdit, futureAssets, id])

  const defaultYearSelectItems = () => {
    return buildStandardSelectItems(TWO_TYPES_YEAR_SELECT_ITEMS, THIS_YEAR)
  }

  const renderInvestmentEndAtYearOptions = (investment5tartAtYear: number | null) => {
    const endYear = extractYearOrMonthFromDate(familyStructures?.birth_day, 'year')
    let selectItems = [{ label: '終身まで', value: `${Number(endYear) + 120}` }]

    if (!isNotEmptyValue(investment5tartAtYear)) {
      selectItems.push(...defaultYearSelectItems())
      return selectItems
    }

    selectItems.push(
      ...buildTwoTypesRangeYearSelectItems(
        Number(investment5tartAtYear) + 1,
        Number(investment5tartAtYear) + 50,
      ),
    )
    return selectItems
  }

  const renderAffectedAtYearOptions = (
    index: number,
    investment5tartAtYear: number,
    tmpAffectedAtYear: number,
  ) => {
    let selectItems = []

    if (index == 0 && !isNotEmptyValue(investment5tartAtYear)) {
      selectItems.push(...defaultYearSelectItems())
      return selectItems
    }

    index == 0
      ? selectItems.push(
          ...buildTwoTypesRangeYearSelectItems(
            Number(investment5tartAtYear),
            Number(investment5tartAtYear) + 50,
          ),
        )
      : selectItems.push(
          ...buildTwoTypesRangeYearSelectItems(
            Number(tmpAffectedAtYear) + 1,
            Number(tmpAffectedAtYear) + 50,
          ),
        )
    return selectItems
  }

  return (
    <Modal
      className="w-[95%] xl:w-[1140px]"
      isOpen={isOpen}
      isDisableBackdropClick
      onClose={onClose}
    >
      <CircleTitle
        className="pb-[32px]"
        title="資産運用の想定を設定する"
        classNameTitle="text-[19px] md:text-[24px]"
      />

      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        mutators={{ ...arrayMutators }}
        render={({
          handleSubmit,
          values,
          errors,
          submitErrors,
          submitFailed,
          form: { change },
        }) => {
          const { transfer_type, continual_transfer_type } = values.future_asset
          const { annual_type } = values

          return (
            <>
              <div className="mt-[30px] mb-[60px] mx-auto">
                <Typography className="text-black-700 flex justify-center">
                  {transfer_type === TRANSFER_TYPE.once
                    ? `あなたの現在の銀行預金残高は${convertLocaleString(
                        thisYearStatementData?.financial_bank_asset_total,
                      )}円です。`
                    : `あなたの今年年間貯蓄予想額は${convertLocaleString(
                        thisYearStatementData?.saving_amount,
                      )}円です。`}
                </Typography>
              </div>
              <form onSubmit={handleSubmit} className="pb-30">
                <Grid className="gap-x-40 gap-y-16 grid-cols-1 xl:grid-cols-2 pb-24 md:pb-40">
                  <div>
                    <div className="md:flex items-center pb-16 justify-between">
                      <div className="flex items-center min-w-140 lg:min-w-164 pb-10 md:pb-0">
                        <Typography className="pr-16 text-14 text-black-800" isBold>
                          振替頻度
                        </Typography>
                      </div>
                      <div className="flex items-center justify-end">
                        <SelectBox
                          isFullWidth
                          placeholder="選択する"
                          name="future_asset.transfer_type"
                          options={TRANSFER_TYPE_SELECT_ITEMS}
                        />
                      </div>
                    </div>
                    <Divider className="border-black-300 border-dashed border-t-2" />
                  </div>
                  <div>
                    <div className="md:flex items-center pb-16 justify-between">
                      <div className="flex items-center min-w-140 lg:min-w-164 pb-10 md:pb-0">
                        <Typography className="pr-16 text-14 text-black-800" isBold>
                          資産の運用方法
                        </Typography>
                      </div>
                      <div className="flex items-center justify-end">
                        <SelectBox
                          isFullWidth
                          placeholder="選択する"
                          name="future_asset.investment_type"
                          options={INVESTMENT_TYPE_SELECT_ITEMS}
                        />
                      </div>
                    </div>
                    <Divider className="border-black-300 border-dashed border-t-2" />
                  </div>

                  {transfer_type && transfer_type == TRANSFER_TYPE.once && (
                    <div>
                      <div className="md:flex md:justify-between pb-[16px] items-baseline">
                        <Typography
                          className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                          isBold
                        >
                          振替年
                        </Typography>
                        <div className="flex justify-between">
                          <SelectBox
                            name="investment_start_at_year"
                            className="mr-10"
                            placeholder="年を選択"
                            options={buildStandardSelectItems(
                              TWO_TYPES_YEAR_SELECT_ITEMS,
                              THIS_YEAR,
                            )}
                            validate={required}
                          />
                        </div>
                      </div>
                      <Divider className="border-black-300 border-dashed border-t-2" />
                    </div>
                  )}

                  {transfer_type && transfer_type == TRANSFER_TYPE.countinual && (
                    <>
                      <div>
                        <div className="md:flex md:justify-between pb-[16px] items-baseline">
                          <Typography
                            className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                            isBold
                          >
                            開始年
                          </Typography>
                          <div className="flex justify-between">
                            <SelectBox
                              name="investment_start_at_year"
                              className="mr-10"
                              placeholder="年を選択"
                              options={buildStandardSelectItems(
                                TWO_TYPES_YEAR_SELECT_ITEMS,
                                THIS_YEAR,
                              )}
                              validate={required}
                            />
                          </div>
                        </div>
                        <Divider className="border-black-300 border-dashed border-t-2" />
                      </div>

                      <div>
                        <div className="md:flex md:justify-between pb-[16px] items-baseline">
                          <Typography
                            className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                            isBold
                          >
                            終了年
                          </Typography>
                          <div className="flex justify-between">
                            <SelectBox
                              name="investment_end_at_year"
                              className="mr-10"
                              placeholder="年を選択"
                              options={renderInvestmentEndAtYearOptions(
                                values?.investment_start_at_year,
                              )}
                              validate={required}
                            />
                          </div>
                        </div>
                        <Divider className="border-black-300 border-dashed border-t-2" />
                      </div>
                    </>
                  )}

                  {transfer_type == TRANSFER_TYPE.once ? (
                    <div>
                      <div className="md:flex items-center pb-16 justify-between">
                        <div className="flex items-center min-w-140 lg:min-w-164 pb-10 md:pb-0">
                          <Typography className="pr-16 text-14 text-black-800" isBold>
                            振替金額
                          </Typography>
                        </div>
                        <div className="flex items-center justify-end">
                          <NumberField
                            isFullWidth
                            name="future_asset.amount"
                            validate={composeValidators(
                              required,
                              zeroOrMoreNumber,
                              (value: any) => commaNumberMaxLength(value, 10),
                            )}
                          />
                          <Typography className="pl-16 text-14 text-black-800" isBold>
                            円
                          </Typography>
                        </div>
                      </div>
                      <Divider className="border-black-300 border-dashed border-t-2" />
                    </div>
                  ) : (
                    <>
                      <div>
                        <div className="md:flex items-center pb-16 justify-between">
                          <div className="flex items-center min-w-140 lg:min-w-164 pb-10 md:pb-0">
                            <Typography className="pr-16 text-14 text-black-800" isBold>
                              積立額の設定方法
                            </Typography>
                          </div>
                          <div className="flex items-center justify-end">
                            <SelectBox
                              isFullWidth
                              name="future_asset.continual_transfer_type"
                              placeholder="選択する"
                              options={CONTINUAL_TRANSFER_TYPE_RADIO_ITEMS}
                              validate={required}
                            />
                          </div>
                        </div>
                        <Divider className="border-black-300 border-dashed border-t-2 " />
                      </div>
                      {continual_transfer_type == CONTINUAL_TRANSFER_TYPE.input && (
                        <div className="md:flex items-center pb-16 justify-between border-black-300 border-dashed border-b-2">
                          <div className="flex items-center min-w-140 lg:min-w-164 pb-10 md:pb-0">
                            <Typography className="pr-16 text-14 text-black-800" isBold>
                              年間積立額
                            </Typography>
                          </div>
                          <div className="flex items-center justify-end">
                            <NumberField
                              isFullWidth
                              name="future_asset.continual_transfer_input"
                              validate={composeValidators(
                                required,
                                zeroOrMoreNumber,
                                (value: any) => commaNumberMaxLength(value, 10),
                              )}
                            />
                            <Typography className="pl-16 text-14 text-black-800" isBold>
                              円
                            </Typography>
                          </div>
                        </div>
                      )}
                      {continual_transfer_type == CONTINUAL_TRANSFER_TYPE.rate && (
                        <div>
                          <div className="md:flex items-center pb-16 justify-between">
                            <div className="flex items-center min-w-140 lg:min-w-164 pb-10 md:pb-0">
                              <Typography className="pr-16 text-14 text-black-800" isBold>
                                年間貯蓄額に対する積立額の比率
                              </Typography>
                            </div>
                            <div className="flex items-center justify-end">
                              <NumberFieldDecimal
                                isFullWidth
                                name="future_asset.continual_transfer_rate_percent"
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 10),
                                )}
                              />
                              <Typography className="pl-16 text-14 text-black-800" isBold>
                                %
                              </Typography>
                            </div>
                          </div>
                          <Divider className="border-black-300 border-dashed border-t-2" />
                        </div>
                      )}
                    </>
                  )}

                  <div>
                    <div className="pb-[16px] items-baseline">
                      <div className="md:flex pb-[16px] items-baseline justify-between">
                        <div className="flex items-center min-w-140 lg:min-w-164 pb-10 md:pb-0">
                          <Typography
                            className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                            isBold
                          >
                            運用利回り
                          </Typography>
                        </div>
                        <div className="flex items-baseline justify-end flex-auto">
                          <NumberFieldDecimal
                            name="future_asset.annual_percent"
                            validate={composeValidators(
                              required,
                              zeroOrMoreNumber,
                              (value: any) => commaNumberMaxLength(value, 6),
                            )}
                          />
                          <Typography className="px-[5px] md:px-[10px] text-[14px] text-black-800">
                            ％
                          </Typography>
                        </div>
                      </div>
                    </div>
                    <Divider className="border-black-300 border-dashed border-t-2" />
                  </div>

                  <div>
                    <div className="pb-[16px] items-baseline">
                      <div className="md:flex pb-[16px] items-baseline justify-between">
                        <div className="flex items-center min-w-140 lg:min-w-164 pb-10 md:pb-0">
                          <Typography
                            className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                            isBold
                          >
                            運用利回りの変化
                          </Typography>
                        </div>
                        <div className="flex items-center justify-end">
                          <SelectBox
                            name="annual_type"
                            placeholder="選択する"
                            options={ANNUAL_TYPE_SELECT_ITEMS}
                            isFullWidth
                            validate={required}
                          />
                        </div>
                      </div>
                      {annual_type == ANNUAL_TYPE.yes && (
                        <FieldArray name="future_asset.asset_annuals_attributes">
                          {({ fields }) => (
                            <>
                              {fields.map((name, index) => (
                                <div
                                  key={name}
                                  className="flex items-baseline justify-end flex-auto mb-[10px]"
                                >
                                  <SelectBox
                                    className="mr-[5px] md:mr-[10px] max-w-[150px] md:max-w-[155px]"
                                    name={`${name}affected_at_year`}
                                    placeholder="年を選択"
                                    options={renderAffectedAtYearOptions(
                                      index,
                                      values?.investment_start_at_year,
                                      fields.value[index - 1]?.affected_at_year,
                                    )}
                                    validate={required}
                                    isFullWidth
                                  />

                                  <Typography className="min-w-[fit-content] px-[3px] md:px-[10px] text-[12px] md:text-[14px] text-black-800">
                                    から
                                  </Typography>

                                  <NumberFieldDecimal
                                    className="max-w-[65px] md:max-w-[100px]"
                                    name={`${name}annual_percent`}
                                    validate={composeValidators(
                                      required,
                                      zeroOrMoreNumber,
                                      (value: any) => commaNumberMaxLength(value, 6),
                                    )}
                                  />
                                  <Typography className="px-[5px] md:px-[10px] text-[14px] text-black-800">
                                    ％
                                  </Typography>
                                  {index > 0 ? (
                                    <Button>
                                      <CrossIcon onClick={() => fields.remove(index)} />
                                    </Button>
                                  ) : (
                                    <div className="min-w-[16px]" />
                                  )}
                                </div>
                              ))}
                              <Button
                                className="ml-auto border-[0.5px] border-black-700 rounded-[4px]"
                                onClick={() => {
                                  const targetValue = fields.value
                                    ? [...fields.value]
                                    : []

                                  handleAddAssetAnnualBlock(
                                    targetValue,
                                    targetValue.length + 1,
                                    change,
                                  )
                                }}
                              >
                                <div className="flex items-center">
                                  <PlusCircleIcon className="ml-[10px] mt-[2px]" />
                                  <Typography
                                    className="min-w-[120p] p-[10px] text-[14px] text-black-800 flex items-center"
                                    isBold
                                  >
                                    入力枠を追加する
                                  </Typography>
                                </div>
                              </Button>
                            </>
                          )}
                        </FieldArray>
                      )}
                    </div>
                    <Divider className="border-black-300 border-dashed border-t-2" />
                  </div>

                  <div>
                    <div className="md:flex items-center pb-16 justify-between">
                      <div className="flex items-center min-w-140 lg:min-w-164 pb-10 md:pb-0">
                        <Typography
                          className="flex items-center text-14 text-black-800"
                          isBold
                        >
                          課税対象
                          <div className="mx-[10px]">
                            <Tooltip
                              item="源泉分離課税として２０％が課税されます。"
                              itemClassName="w-[80px] sm:w-[200px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50 pr-16 "
                            >
                              <HelpCircleIcon />
                            </Tooltip>
                          </div>
                        </Typography>
                      </div>
                      <div className="flex items-center justify-end">
                        <SelectBox
                          isFullWidth
                          placeholder="選択する"
                          name="future_asset.is_tax_chargable"
                          options={IS_TAX_CHARGABLE_SELECT_ITEMS}
                        />
                      </div>
                    </div>
                    <div className="md:flex items-center pb-16 justify-between">
                      <Typography className="flex items-center text-14 text-black-800">
                        新NISAの設定をする場合は、課税対象を「課税対象としない」と設定し、<br />
                        積立て完了時期を上限（※）に達する年に設定してください。<br />
                        ※つみたてNISA枠のみ＝１８００万円<br />
                        &emsp;成長投資枠のみ＝１２００万円<br />
                        &emsp;それぞれを合わせた上限＝１８００万円<br />
                      </Typography>
                    </div>
                    <Divider className="border-black-300 border-dashed border-t-2" />
                  </div>
                </Grid>
                <SubmitErrorMessage show={!submitErrors && submitFailed} />
                <ButtonPrimary
                  className={clsx(
                    { ['opacity-50']: Object.keys(errors).length > 0 },
                    'w-[fit-content] md:w-[246px] h-[64px] mx-[auto]',
                  )}
                  onClick={() => handleSubmit(values)}
                  // isDisabled={Object.keys(errors).length > 0}
                >
                  <Typography
                    className="bottom-2 flex-auto pl-30 relative text-16 text-white"
                    isBold
                  >
                    保存する
                  </Typography>
                  <ChevronRightIcon className="mr-17" />
                </ButtonPrimary>
              </form>
            </>
          )
        }}
      />
    </Modal>
  )
}

export default FutureAssetModalForm
