import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { isUndefined as _isUndefined, times as _times } from 'lodash'
import Typography from '@/components/v2/atoms/Typography'
import { convertLocaleString } from '@/models/commonsModelFunc'
import { RootState } from '@/store'

interface TooltipTuitionProps {
  /** アクティブ情報 */
  activeItem: any
}

const TooltipTuition: FC<TooltipTuitionProps> = (props) => {
  const { activeItem } = props
  const {
    summaryTuitions: { child_count },
  } = useSelector((state: RootState) => state.summaries)

  return (
    <>
      {!_isUndefined(activeItem) ? (
        <div className="md:w-[200px] py-[24px] px-[16px] rounded-[8px] bg-white md:shadow-modal visible pointer-events-auto">
          <div className="mb-[16px] p-[10px] rounded-[4px] bg-primary-50">
            <Typography
              isBold
            >{`${activeItem?.name}年(${activeItem?.age}歳)`}</Typography>
          </div>

          {_times(child_count, (index) => (
            <React.Fragment key={index}>
              <div className="flex justify-between pb-[8px] last:pb-0">
                <div className="flex items-center">
                  <i
                    className="ico rect-2 pr-[8px] text-[12px]"
                    style={{ color: index % 2 === 0 ? '#f88c93' : '#9bdfb5' }}
                  />
                  <Typography className="text-[12px]">{`第${
                    index + 1
                  }子学費`}</Typography>
                </div>
                <Typography className="text-[12px]">{`${convertLocaleString(
                  activeItem?.[`child_${index + 1}_tuition_manyen`],
                )}万円`}</Typography>
              </div>

              <div className="flex justify-between pb-[8px] last:pb-0">
                <div className="flex items-center">
                  <i
                    className="ico rect-2 pr-[8px] text-[12px]"
                    style={{ color: index % 2 === 0 ? '#fcc6c9' : '#cdefda' }}
                  />
                  <Typography className="text-[12px]">{`第${
                    index + 1
                  }子学外費`}</Typography>
                </div>
                <Typography className="text-[12px]">{`${convertLocaleString(
                  activeItem?.[`child_${index + 1}_off_campus_expenses_manyen`],
                )}万円`}</Typography>
              </div>
            </React.Fragment>
          ))}
        </div>
      ) : null}
    </>
  )
}

export default TooltipTuition
