import React, { FC, useEffect, useMemo, useContext, ChangeEvent } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form } from 'react-final-form'
import { split as _split } from 'lodash'
import arrayMutators from 'final-form-arrays'
import ChevronRightIcon from '@/assets/images/v2/chevron-right.svg'
import Button from '@/components/v2/atoms/Button'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import Grid from '@/components/v2/atoms/Grid'
import TextField from '@/components/v2/atoms/TextField'
import Typography from '@/components/v2/atoms/Typography'
import NumberField from '@/components/v2/molecules/NumberField'
import SelectBox from '@/components/v2/molecules/SelectBox'
import {
  MONTH_KANJI_SELECT_ITEMS,
  TWO_TYPES_YEAR_SELECT_ITEMS,
} from '@/constants/formItem/commons'
import {
  CANCELLATION_REFUND_TYPE_RADIO_ITEMS,
  CONTRACTOR_TYPE_SELECT_ITEMS,
  INSURANCE_CLASSIFICATION_SELECT_ITEMS,
  INSURANCE_FEE_PAYMENT_OCCURRENCE_IN_MONTHS_RADIO_ITEMS,
  INSURANCE_TYPE1_SELECT_ITEMS,
  INSURANCE_TYPE2_SELECT_ITEMS,
  INSURED_TYPE_SELECT_ITEMS,
  NON_LIFE_INSURANCE_FEE_MONTHS_RADIO_ITEMS,
  NON_LIFE_INSURANCE_TYPE1_SELECT_ITEMS,
  REJECT_INSURANCE_TYPE2_SELECT_ITEMS,
  CONTRACTOR_TYPE_WITHOUT_SPOUSE_SELECT_ITEMS,
  INSURED_TYPE_WITHOUT_SPOUSE_SELECT_ITEMS,
} from '@/constants/formItem/commonInsurances'
import { fetchFamilyStructureV2 } from '@/containers/familyStructuresSlice'
import { setPageMeta } from '@/containers/pageMetasSlice'
import { fetchInputCars } from '@/containers/inputs/inputCarsSlice'
import { fetchInputEstateInvestments } from '@/containers/inputs/inputEstateInvestmentsSlice'
import { fetchInputHousings } from '@/containers/inputs/inputHousingsSlice'
import {
  fetchInputInsurances,
  createInputInsurance,
  updateInputInsurance,
} from '@/containers/inputs/inputInsurancesSlice'
import { INSURANCE_CLASSIFICATION } from '@/constants/formItem/commonInsurances'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import {
  CANCELLATION_REFUND_TYPE,
  CONTRACTOR_TYPE,
  INSURANCE_FEE_TYPE,
  INSURANCE_PERIOD_TYPE,
  INSURANCE_TYPE1,
  INSURANCE_TYPE2,
  INSURED_TYPE,
  PURPOSE,
  NON_LIFE_INSURANCE_TYPE1,
  NON_LIFE_INSURANCE_FEE_MONTHS,
  selectChangeTargetForPurpose,
  selectChangeTargetForPeriodType,
  isInsuranceTypeWholeLife,
  selectTargetType,
  isNotInsurancePeriodTypeAge,
  isNotTargetInsuranceType2Selected,
} from '@/models/commonInsurancesModelFunc'
import {
  extractYearOrMonthFromDate,
  THIS_YEAR_NUMBER,
  API_FLAG,
  convertDateMonth,
  convertDateYear,
  isNotEmptyValue,
} from '@/models/commonsModelFunc'
import { convertHouseType } from '@/models/inputs/inputHousingsModelFunc'
import { RootState } from '@/store'
import {
  convertRadioGroupValue,
  buildTwoTypesRangeYearSelectItems,
  buildStandardSelectItems,
  buildAgeLimitSelectItems,
} from '@/utils/formItem'
import {
  required,
  composeValidators,
  commaNumberMaxLength,
  zeroOrMoreNumber,
  limitOrMoreNumber,
} from '@/utils/validate'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import { useParams } from 'react-router'
import { AccountContext } from '@/models/accountsModelFunc'
import WholeSubmitErrorMessage from '@/components/v2/molecules/WholeSubmitErrorMessage'

import { find as _find } from 'lodash'
import LoadingOverlay from '@/components/v1/atoms/LoadingOverlay'
import Tooltip from '@/components/v2/atoms/Tooltip'
import HelpCircleIcon from '@/assets/images/v2/help-circle.svg'
import clsx from 'clsx'
import SubmitErrorMessage from '@/components/v2/molecules/SubmitErrorMessage'
import NumberFieldDecimal from '@/components/v2/molecules/NumberFieldDecimal'
import { updateResetFlg } from '@/containers/futures/futureBasesSlice'

const MyDataInsuranceEdit: FC<{
  handleCancel?: () => void
  submitCallback?: () => void
  insuranceId?: number
  isSeiho?: boolean
}> = ({ handleCancel, submitCallback, insuranceId, isSeiho }) => {
  const dispatch = useDispatch()
  const { handleMovePage } = useCustomHistory()
  const { showFlashMsg } = useFlashAlert()
  const { isAdmin } = useContext(AccountContext)
  const { account_id } = useParams<{ account_id?: string }>()

  let id = insuranceId || Number(useParams<{ id?: string }>().id)

  const { inputCars } = useSelector((state: RootState) => state.inputCars)
  const { inputHousings } = useSelector((state: RootState) => state.inputHousings)
  const { inputInsurances, isLoading } = useSelector(
    (state: RootState) => state.inputInsurances,
  )
  const { inputEstateInvestments } = useSelector(
    (state: RootState) => state.inputEstateInvestments,
  )

  // LoadingFlag
  const isLoadingInputCars = useSelector((state: RootState) => state.inputCars.isLoading)
  const isLoadingInputHousings = useSelector(
    (state: RootState) => state.inputHousings.isLoading,
  )
  const isLoadingInputEstateInvestments = useSelector(
    (state: RootState) => state.inputEstateInvestments.isLoading,
  )

  const targetInputInsurance = useMemo(() => {
    return _find(inputInsurances, { id })
  }, [id, inputInsurances])

  const isEdit = useMemo(() => !!id && !!targetInputInsurance, [targetInputInsurance, id])

  const { familyStructures } = useSelector((state: RootState) => state.familyStructures)
  const hasSpouse = familyStructures?.spouse_type === API_FLAG.on
  const familyAge = {
    person_current_age: familyStructures?.person_age,
    spouse_current_age: familyStructures?.spouse_age,
  }

  const insuredSubjectCarSelectItems = useMemo(
    () =>
      inputCars.map((item) => ({
        label: item.car_name,
        value: `InputCar_${item.id}`,
      })),
    [inputCars],
  )

  const insuredSubjectPropertySelectItems = useMemo(() => {
    const estateInvestmentSelectItems = inputEstateInvestments.map((item) => ({
      label: item.property_name,
      value: `InputEstateInvestment_${item.id}`,
    }))

    const housingSelectItems = inputHousings.map((item) => ({
      label: convertHouseType(item.house_type),
      value: `InputHousing_${item.id}`,
    }))

    return [...estateInvestmentSelectItems, ...housingSelectItems]
  }, [inputEstateInvestments, inputHousings])

  const buildLifeInitialValues = (item: any) => {
    return {
      ...item,
      classification: INSURANCE_CLASSIFICATION.life,
      insurance_fee_payment_occurrence_in_months: convertRadioGroupValue(
        item.insurance_fee_payment_occurrence_in_months,
      ),
      contracted_year: extractYearOrMonthFromDate(item.contracted_at, 'year'),
      contracted_month: extractYearOrMonthFromDate(item.contracted_at, 'month'),
      insurance_period_finish_year: extractYearOrMonthFromDate(
        item.insurance_period_finish_at,
        'year',
      ),
      insurance_period_finish_month: extractYearOrMonthFromDate(
        item.insurance_period_finish_at,
        'month',
      ),
      insurance_fee_payment_period_finish_year: extractYearOrMonthFromDate(
        item.insurance_fee_payment_period_finish_at,
        'year',
      ),
      insurance_fee_payment_period_finish_month: extractYearOrMonthFromDate(
        item.insurance_fee_payment_period_finish_at,
        'month',
      ),
      cancellation_refund_at_year: extractYearOrMonthFromDate(
        item.cancellation_refund_at,
        'year',
      ),
      cancellation_refund_at_month: extractYearOrMonthFromDate(
        item.cancellation_refund_at,
        'month',
      ),
    }
  }

  const buildNonLifeInitialValues = (item: any) => {
    const isBulk = item?.insurance_fee_payment_occurrence_in_months >= 24
    const insuranceFeeMonths = isBulk
      ? NON_LIFE_INSURANCE_FEE_MONTHS.bulk
      : String(item?.insurance_fee_payment_occurrence_in_months)

    const insuranceFeeYear = isBulk
      ? item?.insurance_fee_payment_occurrence_in_months / 12
      : null

    const insuredSubjectCars =
      isNotEmptyValue(item?.input_cars) && item?.input_cars.length > 0
        ? item?.input_cars.map((item: any) => `InputCar_${item.id}`)
        : []

    const inputEstateInvestments =
      isNotEmptyValue(item?.input_estate_investments) &&
      item?.input_estate_investments.length > 0
        ? item?.input_estate_investments.map(
            (item: any) => `InputEstateInvestment_${item.id}`,
          )
        : []

    const inputHousings =
      isNotEmptyValue(item?.input_housings) && item?.input_housings.length > 0
        ? item?.input_housings.map((item: any) => `InputHousing_${item.id}`)
        : []

    return {
      id: item.id,
      classification: INSURANCE_CLASSIFICATION.nonLife,
      company_name: item?.company_name,
      insurance_type1: item?.insurance_type1,
      insurance_fee_payment_occurrence_in_months: insuranceFeeMonths,
      contracted_at: extractYearOrMonthFromDate(item?.contracted_at, 'year'),
      insurance_fee: item?.insurance_fee,
      insurance_fee_payment_occurrence_in_year: insuranceFeeYear,
      insured_subject_cars: insuredSubjectCars[0],
      insured_subject_properties: [...inputEstateInvestments, ...inputHousings][0],
    }
  }

  const buildInitialValuesBase = useMemo(
    () =>
      isEdit
        ? {
            input_insurance: targetInputInsurance?.['is_life_insurance?']
              ? buildLifeInitialValues(targetInputInsurance)
              : buildNonLifeInitialValues(targetInputInsurance),
          }
        : {
            input_insurance: {
              classification: isSeiho ? INSURANCE_TYPE1.life : null,
            },
          },
    [targetInputInsurance, isEdit],
  )

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'マイデータ | 保険 編集',
        description: 'マイデータ | 保険 編集',
        keyword: 'マイデータ | 保険 編集',
      }),
    )
    if (inputInsurances.length == 0) dispatch(fetchInputInsurances())
    dispatch(fetchInputCars())
    dispatch(fetchInputHousings())
    dispatch(fetchInputEstateInvestments())
    dispatch(fetchFamilyStructureV2())
  }, [])

  const submitLifeInsuranceFunc = (values: any) => {
    const { input_insurance } = values

    let newValues = {
      ...input_insurance,
      contracted_at: convertDateMonth(
        input_insurance.contracted_year,
        input_insurance.contracted_month,
      ),
    }

    // 「保険種別1, 保険種別2
    if (
      isNotTargetInsuranceType2Selected(
        input_insurance?.insurance_type1,
        input_insurance?.insurance_type2,
      )
    ) {
      newValues.insurance_type2 = null
    }

    // 保険種別1: 生命保険以外を選択
    if (input_insurance?.insurance_type1 !== INSURANCE_TYPE1.life) {
      newValues.cancellation_refund_amount_manyen = null
      newValues.insured_income_monthly_manyen = null
      newValues.maturity_money = null
    }

    // 「保険種別1: 生命保険を選択」 かつ 「保険種別2: 終身型以外を選択」
    if (
      input_insurance?.insurance_type1 === INSURANCE_TYPE1.life &&
      input_insurance?.insurance_type2 !== INSURANCE_TYPE2.wholeLife
    ) {
      newValues.cancellation_refund_amount_input_type = null
      newValues.cancellation_refund_amount_manyen = null
      newValues.cancellation_refund_rate_percent = null
      newValues.cancellation_refund_target = null
      newValues.cancellation_refund_at = null
      newValues.cancellation_refund_at_age = null
      delete newValues.cancellation_refund_type
    }

    // 「保険種別1: 生命保険を選択」 かつ 「保険種別2: 終身型を選択」 かつ 「利用目的: 貯蓄のためを選択」
    if (
      input_insurance?.insurance_type1 === INSURANCE_TYPE1.life &&
      input_insurance?.insurance_type2 === INSURANCE_TYPE2.wholeLife &&
      input_insurance?.purpose === PURPOSE.savings
    ) {
      newValues.cancellation_refund_at = convertDateMonth(
        input_insurance.cancellation_refund_at_year,
        input_insurance.cancellation_refund_at_month,
      )
    }

    // 「保険種別1: 生命保険を選択」 かつ 「保険種別2: 終身型を選択」 かつ 「利用目的: 保障のためを選択」
    if (
      input_insurance?.insurance_type1 === INSURANCE_TYPE1.life &&
      input_insurance?.insurance_type2 === INSURANCE_TYPE2.wholeLife &&
      input_insurance?.purpose === PURPOSE.security
    ) {
      newValues.cancellation_refund_amount_input_type = null
      newValues.cancellation_refund_amount_manyen = null
      newValues.cancellation_refund_rate_percent = null
      newValues.cancellation_refund_target = null
      newValues.cancellation_refund_at = null
      newValues.cancellation_refund_at_age = null
      delete newValues.cancellation_refund_type
    }

    // 「保険種別1: 生命保険を選択」 かつ 「保険種別2: 収入保障保険以外を選択」
    if (
      input_insurance?.insurance_type1 === INSURANCE_TYPE1.life &&
      input_insurance?.insurance_type2 !== INSURANCE_TYPE2.incomeSecurity
    ) {
      newValues.insured_income_monthly_manyen = null
    }

    // 「保険種別1: 生命保険を選択」 かつ 「保険種別2: 収入保障を選択」
    if (
      input_insurance?.insurance_type1 === INSURANCE_TYPE1.life &&
      input_insurance?.insurance_type2 === INSURANCE_TYPE2.incomeSecurity
    ) {
      newValues.insurance_amount_manyen = null
    }

    // 「保険種別1: 生命保険を選択」 かつ 「保険種別2: 養老保険以外を選択」
    if (
      input_insurance?.insurance_type1 === INSURANCE_TYPE1.life &&
      input_insurance?.insurance_type2 !== INSURANCE_TYPE2.endowment
    ) {
      newValues.maturity_money_type = null
      newValues.maturity_money = null
    }

    // 保険種別: 個人年金保険以外を選択
    if (input_insurance?.insurance_type1 !== INSURANCE_TYPE1.personalAnnuity) {
      newValues.receiving_pension_at = null
      newValues.receiving_pension_at_age = null
      newValues.receiving_pension_amount = null
      newValues.receiving_years = null
      newValues.receiving_guaranteed_years = null
    }

    // 契約者: 自由入力以外を選択
    if (input_insurance?.contractor_type !== CONTRACTOR_TYPE.otherPersonContract) {
      newValues.contractor_name = null
      newValues.insurance_fee_payment_period_finish_at = null
    }

    // 契約者: 自由入力を選択
    if (input_insurance?.contractor_type === CONTRACTOR_TYPE.otherPersonContract) {
      newValues.insurance_fee_payment_period_finish_at_age = null
      newValues.insurance_fee_payment_period_finish_at = convertDateMonth(
        input_insurance.insurance_fee_payment_period_finish_year,
        input_insurance.insurance_fee_payment_period_finish_month,
      )
    }

    // 被保険者: 自由入力以外を選択
    if (input_insurance?.insured_type !== INSURED_TYPE.otherPersonInsured) {
      newValues.insured_name = null
      newValues.insurance_period_finish_at = null
    }

    // 被保険者: 自由入力を選択
    if (input_insurance?.insured_type === INSURED_TYPE.otherPersonInsured) {
      newValues.insurance_period_finish_at_age = null
      newValues.insurance_period_finish_at = convertDateMonth(
        input_insurance.insurance_period_finish_year,
        input_insurance.insurance_period_finish_month,
      )
    }

    // 保険期間: 終身を選択
    if (input_insurance?.insurance_period_type === INSURANCE_PERIOD_TYPE.wholeLife) {
      newValues.insurance_period_finish_at = null
      newValues.insurance_period_finish_at_age = null
    }

    // 保険の払い込み: 終身を選択
    if (
      input_insurance?.insurance_fee_payment_period_type ===
      INSURANCE_PERIOD_TYPE.wholeLife
    ) {
      newValues.insurance_fee_payment_period_finish_at = null
      newValues.insurance_fee_payment_period_finish_at_age = null
    }

    // 保険の払い込み: 1回のみを選択
    if (
      input_insurance?.insurance_fee_payment_period_type === INSURANCE_PERIOD_TYPE.oneTime
    ) {
      newValues.insurance_fee_payment_period_finish_at = null
      newValues.insurance_fee_payment_occurrence_in_months = null
      newValues.insurance_fee_payment_period_finish_at_age = null
    }

    // 満期金: なしを選択
    if (input_insurance?.maturity_money_type === INSURANCE_FEE_TYPE.none) {
      newValues.maturity_money = null
    }

    newValues = { id: id, input_insurance: newValues }
    return isEdit
      ? dispatch(
          updateInputInsurance(newValues, () => {
            showFlashMsg(isEdit ? '更新しました' : '作成しました')
            submitCallback
              ? submitCallback()
              : (() => {
                  dispatch(fetchInputInsurances())
                  dispatch(updateResetFlg())
                  handleMovePage(
                    isAdmin
                      ? `/v2/accounts/${account_id}/myData/insurances`
                      : '/v2/myData/insurances',
                  )
                })()
          }),
        )
      : dispatch(
          createInputInsurance(newValues, () => {
            showFlashMsg(isEdit ? '更新しました' : '作成しました')
            submitCallback
              ? submitCallback()
              : (() => {
                  dispatch(fetchInputInsurances())
                  dispatch(updateResetFlg())
                  handleMovePage(
                    isAdmin
                      ? `/v2/accounts/${account_id}/myData/insurances`
                      : '/v2/myData/insurances',
                  )
                })()
          }),
        )
  }

  const submitNonLifeInsuranceFunc = (values: any) => {
    const { input_insurance } = values
    const newValues = {
      id: id,
      input_insurance: {
        ...input_insurance,
        contracted_at: convertDateYear(input_insurance?.contracted_at),
        purpose: PURPOSE.security,
        insurance_fee_payment_period_type: INSURANCE_PERIOD_TYPE.wholeLife,
        insurance_fee_type: INSURANCE_FEE_TYPE.input,
        insurance_fee_payment_period_finish_at: null,
        contractor_type: CONTRACTOR_TYPE.otherPersonContract,
        insured_type: INSURED_TYPE.otherPersonInsured,
      },
    }

    if (
      input_insurance?.insurance_fee_payment_occurrence_in_months ===
      NON_LIFE_INSURANCE_FEE_MONTHS.bulk
    ) {
      newValues.input_insurance.insurance_fee_payment_occurrence_in_months =
        input_insurance?.insurance_fee_payment_occurrence_in_year * 12
    }

    if (input_insurance?.insurance_type1 === NON_LIFE_INSURANCE_TYPE1.vehicle) {
      newValues.input_insurance.insured_subjects_attributes = isNotEmptyValue(
        input_insurance.insured_subject_cars,
      )
        ? [
            {
              subject_type: _split(input_insurance.insured_subject_cars, '_')[0],
              subject_id: _split(input_insurance.insured_subject_cars, '_')[1],
            },
          ]
        : []
    }

    if (
      input_insurance?.insurance_type1 === NON_LIFE_INSURANCE_TYPE1.fire ||
      input_insurance?.insurance_type1 === NON_LIFE_INSURANCE_TYPE1.earthquake
    ) {
      newValues.input_insurance.insured_subjects_attributes = isNotEmptyValue(
        input_insurance.insured_subject_properties,
      )
        ? [
            {
              subject_type: _split(input_insurance.insured_subject_properties, '_')[0],
              subject_id: _split(input_insurance.insured_subject_properties, '_')[1],
            },
          ]
        : []
    }

    return isEdit
      ? dispatch(
          updateInputInsurance(newValues, () => {
            showFlashMsg(isEdit ? '更新しました' : '作成しました')
            submitCallback
              ? submitCallback()
              : (() => {
                  dispatch(fetchInputInsurances())
                  handleMovePage(
                    isAdmin
                      ? `/v2/accounts/${account_id}/myData/insurances`
                      : '/v2/myData/insurances',
                  )
                })()
          }),
        )
      : dispatch(
          createInputInsurance(newValues, () => {
            showFlashMsg(isEdit ? '更新しました' : '作成しました')
            submitCallback
              ? submitCallback()
              : (() => {
                  dispatch(fetchInputInsurances())
                  handleMovePage(
                    isAdmin
                      ? `/v2/accounts/${account_id}/myData/insurances`
                      : '/v2/myData/insurances',
                  )
                })()
          }),
        )
  }

  const handleSubmit = (values: any) => {
    values.input_insurance?.classification == INSURANCE_CLASSIFICATION.life
      ? submitLifeInsuranceFunc(values)
      : submitNonLifeInsuranceFunc(values)
  }
  return (
    <>
      <LoadingOverlay
        isLoading={
          isLoading ||
          isLoadingInputEstateInvestments ||
          isLoadingInputHousings ||
          isLoadingInputCars
        }
      />
      <Form
        onSubmit={handleSubmit}
        initialValues={buildInitialValuesBase}
        mutators={{ ...arrayMutators }}
        render={({
          handleSubmit,
          values,
          errors,
          submitErrors,
          submitFailed,
          form: {
            change,
            mutators: { push },
          },
        }) => {
          const {
            classification,
            insurance_fee_payment_period_type,
            contractor_type,
            purpose,
            insurance_type1,
            insurance_type2,
            insured_type,
            cancellation_refund_amount_input_type,
            maturity_money_type,
            insurance_fee_payment_occurrence_in_months,
            insurance_period_type,
          } = values.input_insurance || {}

          return (
            <form onSubmit={handleSubmit}>
              <div className="pb-[64px]">
                <Grid className="grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px]">
                  <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                    <Typography
                      className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                      isBold
                    >
                      保険の分類
                    </Typography>
                    <SelectBox
                      name={`input_insurance.classification`}
                      placeholder="選択する"
                      isFullWidth
                      options={INSURANCE_CLASSIFICATION_SELECT_ITEMS}
                      validate={required}
                      isDisabled={isEdit}
                    />
                  </div>
                  <div className="hidden md:block" />

                  {classification == INSURANCE_CLASSIFICATION.life && (
                    <>
                      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                        <Typography
                          className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                          isBold
                        >
                          契約年月
                        </Typography>
                        <div className="flex flex-auto items-baseline">
                          <SelectBox
                            name={`input_insurance.contracted_year`}
                            className="pr-[8px]"
                            placeholder="年を選択"
                            isFullWidth
                            options={buildTwoTypesRangeYearSelectItems(
                              THIS_YEAR_NUMBER - 40,
                              THIS_YEAR_NUMBER,
                              true,
                            )}
                            validate={required}
                          />
                          <SelectBox
                            name={`input_insurance.contracted_month`}
                            widthClassName="max-w-[100px]"
                            placeholder="月を選択"
                            options={MONTH_KANJI_SELECT_ITEMS}
                            validate={required}
                          />
                        </div>
                      </div>

                      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                        <Typography
                          className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                          isBold
                        >
                          保険会社
                        </Typography>
                        <TextField
                          type="text"
                          name={`input_insurance.company_name`}
                          isFullWidth
                          validate={required}
                        />
                      </div>

                      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                        <Typography
                          className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                          isBold
                        >
                          保険種別
                        </Typography>
                        <div className="flex flex-auto items-baseline">
                          <SelectBox
                            name={`input_insurance.insurance_type1`}
                            placeholder="選択する"
                            isFullWidth
                            options={INSURANCE_TYPE1_SELECT_ITEMS}
                            validate={required}
                          />
                          {insurance_type1 != INSURANCE_TYPE1.personalAnnuity && (
                            <SelectBox
                              name={`input_insurance.insurance_type2`}
                              className="pl-[8px]"
                              placeholder="選択する"
                              isFullWidth
                              options={
                                insurance_type1 == INSURANCE_TYPE1.life
                                  ? INSURANCE_TYPE2_SELECT_ITEMS
                                  : REJECT_INSURANCE_TYPE2_SELECT_ITEMS
                              }
                              validate={required}
                              onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                                selectChangeTargetForPurpose(
                                  event.target.value,
                                  `input_insurance.purpose`,
                                  change,
                                )
                                selectChangeTargetForPeriodType(
                                  event.target.value,
                                  `input_insurance.insurance_fee_payment_period_type`,
                                  change,
                                )
                                selectChangeTargetForPeriodType(
                                  event.target.value,
                                  `input_insurance.insurance_period_type`,
                                  change,
                                )
                              }}
                            />
                          )}
                        </div>
                      </div>

                      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                        <Typography
                          className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                          isBold
                        >
                          利用目的
                        </Typography>
                        <SelectBox
                          name={`input_insurance.purpose`}
                          placeholder="選択する"
                          isFullWidth
                          options={[
                            {
                              label: '保障のため',
                              value: PURPOSE.security,
                            },
                            {
                              label: '貯蓄のため',
                              value: PURPOSE.savings,
                            },
                          ]}
                          validate={required}
                        />
                      </div>

                      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                        <Typography
                          className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                          isBold
                        >
                          契約者
                        </Typography>
                        <div className="flex flex-auto items-baseline">
                          <SelectBox
                            name={`input_insurance.contractor_type`}
                            placeholder="選択する"
                            isFullWidth
                            options={
                              hasSpouse
                                ? CONTRACTOR_TYPE_SELECT_ITEMS
                                : CONTRACTOR_TYPE_WITHOUT_SPOUSE_SELECT_ITEMS
                            }
                            validate={required}
                          />
                          {contractor_type == CONTRACTOR_TYPE.otherPersonContract && (
                            <TextField
                              type="text"
                              placeholder="契約者名を入力"
                              name={`input_insurance.contractor_name`}
                              className="pl-[8px] text-14"
                              isFullWidth
                              validate={required}
                            />
                          )}
                        </div>
                      </div>

                      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                        <Typography
                          className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                          isBold
                        >
                          被保険者
                        </Typography>
                        <div className="flex flex-auto items-baseline">
                          <SelectBox
                            name={`input_insurance.insured_type`}
                            placeholder="選択する"
                            isFullWidth
                            options={
                              hasSpouse
                                ? INSURED_TYPE_SELECT_ITEMS
                                : INSURED_TYPE_WITHOUT_SPOUSE_SELECT_ITEMS
                            }
                            validate={required}
                          />
                          {insured_type == INSURED_TYPE.otherPersonInsured && (
                            <TextField
                              type="text"
                              placeholder="被保険者名を入力"
                              name={`input_insurance.insured_name`}
                              className="pl-[8px] text-14"
                              isFullWidth
                              validate={required}
                            />
                          )}
                        </div>
                      </div>

                      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                        <Typography
                          className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                          isBold
                        >
                          保険期間
                        </Typography>
                        <div className="flex-auto">
                          <SelectBox
                            name={`input_insurance.insurance_period_type`}
                            placeholder="選択する"
                            isFullWidth
                            options={[
                              {
                                label: '終身',
                                value: INSURANCE_PERIOD_TYPE.wholeLife,
                                isDisabled:
                                  insurance_type2 == INSURANCE_TYPE2.incomeSecurity,
                              },
                              {
                                label: '入力する',
                                value: INSURANCE_PERIOD_TYPE.age,
                                isDisabled: isInsuranceTypeWholeLife(insurance_type2),
                              },
                            ]}
                            validate={required}
                          />
                          {insurance_period_type == INSURANCE_PERIOD_TYPE.age && (
                            <div className="flex flex-auto justify-end items-baseline pt-[8px]">
                              {insured_type != INSURED_TYPE.otherPersonInsured ? (
                                <>
                                  <Typography
                                    className="min-w-[fit-content] pr-[8px] text-[14px] text-black-800"
                                    isBold
                                  >
                                    被保険者が
                                  </Typography>
                                  <SelectBox
                                    name={`input_insurance.insurance_period_finish_at_age`}
                                    widthClassName="max-w-[100px]"
                                    isFullWidth
                                    isPlaceholderHidden
                                    isDisabled={isNotInsurancePeriodTypeAge(
                                      insurance_period_type,
                                    )}
                                    options={buildStandardSelectItems(
                                      buildAgeLimitSelectItems(
                                        selectTargetType(insured_type, familyAge),
                                      ),
                                      60,
                                      '選択する',
                                    )}
                                    validate={required}
                                  />
                                  <Typography
                                    className="pl-[8px] text-[14px] text-black-800"
                                    isBold
                                  >
                                    歳まで
                                  </Typography>
                                </>
                              ) : (
                                <>
                                  <SelectBox
                                    name={`input_insurance.insurance_period_finish_year`}
                                    className="pr-[8px]"
                                    placeholder="年を選択"
                                    isFullWidth
                                    isDisabled={isNotInsurancePeriodTypeAge(
                                      insurance_period_type,
                                    )}
                                    options={TWO_TYPES_YEAR_SELECT_ITEMS}
                                    validate={required}
                                  />
                                  <SelectBox
                                    name={`input_insurance.insurance_period_finish_month`}
                                    widthClassName="max-w-[100px]"
                                    placeholder="月を選択"
                                    isDisabled={isNotInsurancePeriodTypeAge(
                                      insurance_period_type,
                                    )}
                                    options={MONTH_KANJI_SELECT_ITEMS}
                                    validate={required}
                                  />
                                  <Typography
                                    className="min-w-[fit-content] pl-[8px] text-[14px] text-black-800"
                                    isBold
                                  >
                                    まで
                                  </Typography>
                                </>
                              )}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                        <Typography
                          className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                          isBold
                        >
                          保険料の払い込み
                        </Typography>
                        <div className="flex-auto">
                          <SelectBox
                            name={`input_insurance.insurance_fee_payment_period_type`}
                            placeholder="選択する"
                            isFullWidth
                            options={[
                              {
                                label: '終身',
                                value: INSURANCE_PERIOD_TYPE.wholeLife,
                                isDisabled:
                                  insurance_type2 == INSURANCE_TYPE2.incomeSecurity,
                              },
                              {
                                label: '1回のみ',
                                value: INSURANCE_PERIOD_TYPE.oneTime,
                              },
                              {
                                label: '入力する',
                                value: INSURANCE_PERIOD_TYPE.age,
                              },
                            ]}
                            validate={required}
                          />
                          {insurance_fee_payment_period_type ==
                            INSURANCE_PERIOD_TYPE.age && (
                            <div className="flex flex-auto justify-end items-baseline pt-[8px]">
                              {contractor_type != CONTRACTOR_TYPE.otherPersonContract ? (
                                <>
                                  <Typography
                                    className="min-w-[fit-content] pr-[8px] text-[14px] text-black-800"
                                    isBold
                                  >
                                    契約者が
                                  </Typography>
                                  <SelectBox
                                    name={`input_insurance.insurance_fee_payment_period_finish_at_age`}
                                    widthClassName="max-w-[100px]"
                                    isFullWidth
                                    isPlaceholderHidden
                                    isDisabled={isNotInsurancePeriodTypeAge(
                                      insurance_fee_payment_period_type,
                                    )}
                                    options={buildStandardSelectItems(
                                      buildAgeLimitSelectItems(
                                        selectTargetType(contractor_type, familyAge),
                                      ),
                                      60,
                                      '選択する',
                                    )}
                                    validate={required}
                                  />
                                  <Typography
                                    className="pl-[8px] text-[14px] text-black-800"
                                    isBold
                                  >
                                    歳まで
                                  </Typography>
                                </>
                              ) : (
                                <>
                                  <SelectBox
                                    name={`input_insurance.insurance_fee_payment_period_finish_year`}
                                    className="pr-[8px]"
                                    placeholder="年を選択"
                                    isFullWidth
                                    isDisabled={isNotInsurancePeriodTypeAge(
                                      insurance_fee_payment_period_type,
                                    )}
                                    options={TWO_TYPES_YEAR_SELECT_ITEMS}
                                    validate={required}
                                  />
                                  <SelectBox
                                    name={`input_insurance.insurance_fee_payment_period_finish_month`}
                                    widthClassName="max-w-[100px]"
                                    placeholder="月を選択"
                                    isDisabled={isNotInsurancePeriodTypeAge(
                                      insurance_fee_payment_period_type,
                                    )}
                                    options={MONTH_KANJI_SELECT_ITEMS}
                                    validate={required}
                                  />
                                  <Typography
                                    className="min-w-[fit-content] pl-[8px] text-[14px] text-black-800"
                                    isBold
                                  >
                                    まで
                                  </Typography>
                                </>
                              )}
                            </div>
                          )}
                        </div>
                      </div>

                      {insurance_fee_payment_period_type !=
                        INSURANCE_PERIOD_TYPE.oneTime && (
                        <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                          <Typography
                            className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                            isBold
                          >
                            支払い頻度
                          </Typography>
                          <SelectBox
                            name={`input_insurance.insurance_fee_payment_occurrence_in_months`}
                            placeholder="選択する"
                            isFullWidth
                            options={
                              INSURANCE_FEE_PAYMENT_OCCURRENCE_IN_MONTHS_RADIO_ITEMS
                            }
                            validate={required}
                          />
                        </div>
                      )}

                      <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                        <Typography
                          className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                          isBold
                        >
                          1回あたりの保険料
                        </Typography>
                        <div className="flex flex-auto items-baseline">
                          <NumberField
                            name={`input_insurance.insurance_fee`}
                            isFullWidth
                            validate={composeValidators(
                              required,
                              zeroOrMoreNumber,
                              (value: any) => commaNumberMaxLength(value, 9),
                            )}
                          />
                          <Typography
                            className="pl-[8px] text-[14px] text-black-800"
                            isBold
                          >
                            円
                          </Typography>
                        </div>
                      </div>

                      {insurance_type1 == INSURANCE_TYPE1.life &&
                        insurance_type2 != INSURANCE_TYPE2.incomeSecurity && (
                          <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                            <div className="min-w-[140px] lg:min-w-[164px] flex items-center">
                              <Typography
                                className="pb-[10px] md:pb-0 text-[14px] text-black-800"
                                isBold
                              >
                                死亡保険金額
                              </Typography>
                              <div className="mx-[10px]">
                                <Tooltip
                                  item="死亡保険金額とは、保険事故（死亡・病気など）が生じた場合に支払われる金額のことです。"
                                  itemClassName="w-[80px] sm:w-[200px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                                >
                                  <HelpCircleIcon />
                                </Tooltip>
                              </div>
                            </div>

                            <div className="flex flex-auto items-baseline">
                              <NumberField
                                name={`input_insurance.insurance_amount_manyen`}
                                isFullWidth
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 10),
                                )}
                              />
                              <Typography
                                className="min-w-[fit-content] pl-[8px] text-[14px] text-black-800"
                                isBold
                              >
                                万円
                              </Typography>
                            </div>
                          </div>
                        )}

                      {insurance_type1 == INSURANCE_TYPE1.life && (
                        <>
                          {insurance_type2 == INSURANCE_TYPE2.wholeLife &&
                            purpose == PURPOSE.savings && (
                              <>
                                <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                                  <Typography
                                    className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                                    isBold
                                  >
                                    解約返戻金返還時期
                                  </Typography>
                                  <div className="flex flex-auto justify-end items-baseline">
                                    {insured_type != INSURED_TYPE.otherPersonInsured ? (
                                      <>
                                        <SelectBox
                                          name={`input_insurance.cancellation_refund_at_age`}
                                          widthClassName="max-w-[100px]"
                                          isFullWidth
                                          isPlaceholderHidden
                                          options={buildStandardSelectItems(
                                            buildAgeLimitSelectItems(
                                              selectTargetType(
                                                contractor_type,
                                                familyAge,
                                              ),
                                            ),
                                            60,
                                            '選択する',
                                          )}
                                          validate={required}
                                        />
                                        <Typography
                                          className="min-w-[fit-content] pl-[8px] text-[14px] text-black-800"
                                          isBold
                                        >
                                          歳時
                                        </Typography>
                                      </>
                                    ) : (
                                      <>
                                        <SelectBox
                                          name={`input_insurance.cancellation_refund_at_year`}
                                          className="pr-[8px]"
                                          placeholder="年を選択"
                                          isFullWidth
                                          options={TWO_TYPES_YEAR_SELECT_ITEMS}
                                          validate={required}
                                        />
                                        <SelectBox
                                          name={`input_insurance.cancellation_refund_at_month`}
                                          widthClassName="max-w-[100px]"
                                          placeholder="月を選択"
                                          options={MONTH_KANJI_SELECT_ITEMS}
                                          validate={required}
                                        />
                                      </>
                                    )}
                                  </div>
                                </div>

                                <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                                  <Typography
                                    className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                                    isBold
                                  >
                                    解約返戻金算出方法
                                  </Typography>
                                  <div className="flex-auto">
                                    <SelectBox
                                      name={`input_insurance.cancellation_refund_amount_input_type`}
                                      placeholder="選択する"
                                      isFullWidth
                                      options={CANCELLATION_REFUND_TYPE_RADIO_ITEMS}
                                      validate={required}
                                    />
                                    <div className="flex flex-auto justify-end items-baseline pt-[8px]">
                                      {cancellation_refund_amount_input_type ==
                                      CANCELLATION_REFUND_TYPE.amount ? (
                                        <>
                                          <NumberField
                                            name={`input_insurance.cancellation_refund_amount_manyen`}
                                            isFullWidth
                                            validate={composeValidators(
                                              required,
                                              zeroOrMoreNumber,
                                              (value: any) =>
                                                commaNumberMaxLength(value, 6),
                                            )}
                                          />
                                          <Typography
                                            className="min-w-[fit-content] pl-[8px] text-[14px] text-black-800"
                                            isBold
                                          >
                                            万円
                                          </Typography>
                                        </>
                                      ) : (
                                        <>
                                          <NumberFieldDecimal
                                            name={`input_insurance.cancellation_refund_rate_percent`}
                                            widthClassName="max-w-100"
                                            isFullWidth
                                            validate={composeValidators(
                                              required,
                                              zeroOrMoreNumber,
                                              (value: any) =>
                                                commaNumberMaxLength(value, 6),
                                            )}
                                          />
                                          <Typography
                                            className="min-w-[fit-content] pl-[8px] text-[14px] text-black-800"
                                            isBold
                                          >
                                            ％
                                          </Typography>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}

                          {insurance_type2 == INSURANCE_TYPE2.incomeSecurity && (
                            <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                              <Typography
                                className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                                isBold
                              >
                                保険月額
                              </Typography>
                              <div className="flex flex-auto items-baseline">
                                <Typography
                                  className="min-w-[fit-content] pr-[8px] text-[14px] text-black-800"
                                  isBold
                                >
                                  月々
                                </Typography>
                                <NumberField
                                  name={`input_insurance.insured_income_monthly_manyen`}
                                  isFullWidth
                                  validate={composeValidators(
                                    required,
                                    zeroOrMoreNumber,
                                    (value: any) => commaNumberMaxLength(value, 6),
                                  )}
                                />
                                <Typography
                                  className="min-w-[fit-content] pl-[8px] text-[14px] text-black-800"
                                  isBold
                                >
                                  万円
                                </Typography>
                              </div>
                            </div>
                          )}

                          {insurance_type2 == INSURANCE_TYPE2.endowment && (
                            <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                              <Typography
                                className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                                isBold
                              >
                                満期金
                              </Typography>
                              <div className="flex-auto">
                                <SelectBox
                                  name={`input_insurance.maturity_money_type`}
                                  placeholder="選択する"
                                  isFullWidth
                                  options={[
                                    {
                                      label: 'あり',
                                      value: INSURANCE_FEE_TYPE.input,
                                    },
                                    {
                                      label: 'なし',
                                      value: INSURANCE_FEE_TYPE.none,
                                    },
                                  ]}
                                  validate={required}
                                />
                                {maturity_money_type == INSURANCE_FEE_TYPE.input && (
                                  <div className="flex flex-auto items-baseline pt-[8px]">
                                    <NumberField
                                      name={`input_insurance.maturity_money`}
                                      isFullWidth
                                      disabled={
                                        maturity_money_type != INSURANCE_FEE_TYPE.input
                                      }
                                      validate={
                                        maturity_money_type == INSURANCE_FEE_TYPE.input
                                          ? composeValidators(
                                              required,
                                              zeroOrMoreNumber,
                                              (value: any) =>
                                                commaNumberMaxLength(value, 10),
                                            )
                                          : composeValidators(
                                              zeroOrMoreNumber,
                                              (value: any) =>
                                                commaNumberMaxLength(value, 10),
                                            )
                                      }
                                    />
                                    <Typography
                                      className="pl-[8px] text-[14px] text-black-800"
                                      isBold
                                    >
                                      円
                                    </Typography>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </>
                      )}

                      {insurance_type1 == INSURANCE_TYPE1.personalAnnuity && (
                        <>
                          <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                            <Typography
                              className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                              isBold
                            >
                              年金受取年齢
                            </Typography>
                            <div className="flex flex-auto justify-end items-baseline">
                              <NumberField
                                name={`input_insurance.receiving_pension_at_age`}
                                widthClassName="max-w-100"
                                validate={composeValidators(required, zeroOrMoreNumber)}
                              />
                              <Typography
                                className="pl-[8px] text-[14px] text-black-800"
                                isBold
                              >
                                歳
                              </Typography>
                            </div>
                          </div>

                          <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                            <Typography
                              className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                              isBold
                            >
                              受取額
                            </Typography>
                            <div className="flex flex-auto items-baseline">
                              <NumberField
                                name={`input_insurance.receiving_pension_amount`}
                                isFullWidth
                                validate={composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 10),
                                )}
                              />
                              <Typography
                                className="pl-[8px] text-[14px] text-black-800 min-w-[fit-content]"
                                isBold
                              >
                                円/年
                              </Typography>
                            </div>
                          </div>

                          <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                            <Typography
                              className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                              isBold
                            >
                              受取期間
                            </Typography>
                            <div className="flex flex-auto justify-end items-baseline">
                              <NumberField
                                name={`input_insurance.receiving_years`}
                                widthClassName="max-w-100"
                                validate={composeValidators(required, zeroOrMoreNumber)}
                              />
                              <Typography
                                className="pl-[8px] text-[14px] text-black-800"
                                isBold
                              >
                                年
                              </Typography>
                            </div>
                          </div>

                          <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                            <Typography
                              className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                              isBold
                            >
                              保証期間
                            </Typography>
                            <div className="flex flex-auto justify-end items-baseline">
                              <NumberField
                                name={`input_insurance.receiving_guaranteed_years`}
                                widthClassName="max-w-100"
                                validate={composeValidators(required, zeroOrMoreNumber)}
                              />
                              <Typography
                                className="pl-[8px] text-[14px] text-black-800"
                                isBold
                              >
                                年
                              </Typography>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}

                  {classification === 'non_life' && (
                    <>
                      <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                        <Typography
                          className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                          isBold
                        >
                          保険名称
                        </Typography>
                        <TextField
                          type="text"
                          name={`input_insurance.company_name`}
                          isFullWidth
                          validate={required}
                        />
                      </div>

                      <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                        <Typography
                          className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                          isBold
                        >
                          保険の種類
                        </Typography>
                        <SelectBox
                          name={`input_insurance.insurance_type1`}
                          isFullWidth
                          options={NON_LIFE_INSURANCE_TYPE1_SELECT_ITEMS}
                          validate={required}
                        />
                      </div>

                      <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                        <Typography
                          className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                          isBold
                        >
                          支払い頻度
                        </Typography>
                        <SelectBox
                          name={`input_insurance.insurance_fee_payment_occurrence_in_months`}
                          isFullWidth
                          options={NON_LIFE_INSURANCE_FEE_MONTHS_RADIO_ITEMS}
                          validate={required}
                        />
                      </div>

                      <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                        <Typography
                          className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                          isBold
                        >
                          1回あたりの保険料
                        </Typography>
                        <div className="flex flex-auto items-baseline">
                          <NumberField
                            name={`input_insurance.insurance_fee`}
                            isFullWidth
                            validate={composeValidators(required, (value: any) =>
                              commaNumberMaxLength(value, 9),
                            )}
                          />
                          <Typography
                            className="pl-[8px] text-[14px] text-black-800"
                            isBold
                          >
                            円
                          </Typography>
                        </div>
                      </div>

                      <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                        <Typography
                          className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                          isBold
                        >
                          契約年
                        </Typography>
                        <SelectBox
                          name={`input_insurance.contracted_at`}
                          isFullWidth
                          options={buildTwoTypesRangeYearSelectItems(
                            1970,
                            THIS_YEAR_NUMBER,
                            true,
                          )}
                          validate={required}
                        />
                      </div>

                      {insurance_fee_payment_occurrence_in_months ==
                        NON_LIFE_INSURANCE_FEE_MONTHS.bulk && (
                        <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                          <Typography
                            className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                            isBold
                          >
                            更新頻度
                          </Typography>
                          <div className="flex flex-auto justify-end items-baseline">
                            <NumberField
                              name={`input_insurance.insurance_fee_payment_occurrence_in_year`}
                              widthClassName="max-w-100"
                              validate={composeValidators(required, (value: any) =>
                                limitOrMoreNumber(value, 2),
                              )}
                            />
                            <Typography
                              className="pl-[8px] text-[14px] text-black-800"
                              isBold
                            >
                              年に1回
                            </Typography>
                          </div>
                        </div>
                      )}

                      {insurance_type1 == NON_LIFE_INSURANCE_TYPE1.vehicle && (
                        <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                          <Typography
                            className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                            isBold
                          >
                            お車
                          </Typography>
                          <SelectBox
                            name={`input_insurance.insured_subject_cars`}
                            isFullWidth
                            options={insuredSubjectCarSelectItems}
                          />
                        </div>
                      )}

                      {(insurance_type1 == NON_LIFE_INSURANCE_TYPE1.fire ||
                        insurance_type1 == NON_LIFE_INSURANCE_TYPE1.earthquake) && (
                        <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                          <Typography
                            className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                            isBold
                          >
                            物件
                          </Typography>
                          <SelectBox
                            name={`input_insurance.insured_subject_properties`}
                            isFullWidth
                            options={insuredSubjectPropertySelectItems}
                          />
                        </div>
                      )}
                    </>
                  )}
                </Grid>
              </div>

              <WholeSubmitErrorMessage submitErrors={submitErrors} />
              <SubmitErrorMessage show={!submitErrors && submitFailed} />
              <ButtonPrimary
                className={clsx(
                  { ['opacity-50']: Object.keys(errors).length > 0 },
                  'w-[246px] h-[64px] mx-auto mb-[16px] ',
                )}
                isDisabled={isLoading}
                onClick={() => handleSubmit(values)}
              >
                <Typography
                  className="relative bottom-[2px] pl-[30px] text-[16px] text-white flex-auto"
                  isBold
                >
                  保存する
                </Typography>
                <ChevronRightIcon className="mr-[17px]" />
              </ButtonPrimary>
              <Button
                className="mx-auto text-[14px] text-black-700"
                onClick={() =>
                  !!handleCancel
                    ? handleCancel()
                    : handleMovePage(
                        isAdmin
                          ? `/v2/accounts/${account_id}/myData/insurances`
                          : '/v2/myData/insurances',
                      )
                }
                isDisabled={isLoading}
              >
                キャンセル
              </Button>
            </form>
          )
        }}
      />
    </>
  )
}

export default MyDataInsuranceEdit
