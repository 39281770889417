import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { isNull as _isNull } from 'lodash'
import MarginContainer from '@/components/v2/atoms/MarginContainer'
import Paper from '@/components/v2/atoms/Paper'
import { setPageMeta } from '@/containers/pageMetasSlice'
import { updateSeminarParticipant } from '@/containers/seminarParticipantsSlice'
import { SeminarParticipantUpdateValues } from '@/models/seminarParticipantsModel'
import SeminarParticipantForm from '@/templates/v2/seminarParticipants/commons/SeminarParticipantForm'

const SeminarParticipantConfirm: FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'セミナー申し込みフォーム 確認',
        description: 'セミナー申し込みフォーム 確認',
        keyword: 'セミナー申し込みフォーム 確認',
      }),
    )
  }, [])

  const { unique_id } = useParams<{
    unique_id?: string
  }>()

  const handleFix = () => {
    history.push(`/seminarParticipants/${unique_id}/new`)
  }

  const handleSubmit = (values: SeminarParticipantUpdateValues) => {
    const newValues = { ...values }

    dispatch(
      updateSeminarParticipant(unique_id!, newValues, () => {
        history.push(`/seminarParticipants/${unique_id}/completed`)
      }),
    )
  }

  return (
    <MarginContainer className="py-40">
      <Paper className="mx-8 px-16 py-24 sm:p-40 bg-white">
        <SeminarParticipantForm
          title="セミナー申し込みフォーム 確認"
          buttonLabel="登録する"
          type="confirm"
          uniqueId={unique_id || ''}
          handleSubmit={handleSubmit}
          handleFix={handleFix}
        />
      </Paper>
    </MarginContainer>
  )
}

export default SeminarParticipantConfirm
