import React, { FC } from 'react'
import { Field } from 'react-final-form'
import clsx from 'clsx'
import ValidMessage from '@/components/v2/atoms/ValidMessage'

interface TextAreaProps {
  /** 名前 */
  name: string
  /** クラス名 */
  className?: string
  /** 幅用クラス名 */
  widthClassName?: string
  /** 表示行数 */
  rows: number
  /** プレースホルダー */
  placeholder?: string
  /** true: width100%, false: width100%無し */
  isFullWidth?: boolean
  /** true: 非活性, false: 活性 */
  disabled?: boolean
  /** バリデーションメソッド */
  validate?: (value: any) => void
}

const TextArea: FC<TextAreaProps> = (props) => {
  const { name, className, widthClassName, isFullWidth, validate, ...rest } = props

  return (
    <Field name={name} validate={validate}>
      {(props) => {
        const { input, meta } = props

        return (
          <div
            className={clsx(className, widthClassName, {
              ['w-full']: isFullWidth,
            })}
          >
            <textarea
              {...input}
              className={clsx(
                widthClassName,
                'appearance-none bg-white border border-black-300 p-[10px] rounded-[2px] text-black-900 disabled:bg-black-200 hover:border-black-500',
                { ['w-full']: isFullWidth },
              )}
              {...rest}
            />
            <ValidMessage meta={meta} />
          </div>
        )
      }}
    </Field>
  )
}

export default TextArea
