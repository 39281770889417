import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  getSeminars,
  postSeminar,
  patchSeminar,
  deleteSeminar,
} from '@/api/admins/seminars'
import { unauthorizedAdminCheck } from '@/containers/admins/fpAccountsSlice'
import { Seminar, SeminarCreateParams } from '@/models/seminarsModel'
import { ApiError } from '@/models/commonsModel'
import { AppThunk } from '@/store'

interface SeminarState {
  seminars: Seminar[]
  seminar: Seminar | null
  isLoading: boolean
}

const initialState: SeminarState = {
  seminars: [],
  seminar: null,
  isLoading: false,
}

const seminarsSlice = createSlice({
  name: 'seminars',
  initialState,
  reducers: {
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    getSeminarsSuccess: (state, action: PayloadAction<Seminar[]>) => {
      state.seminars = action.payload
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
  },
})

export const {
  requestStart,
  requestSuccess,
  getSeminarsSuccess,
  requestFailure,
} = seminarsSlice.actions
export const seminarsReducer = seminarsSlice.reducer

export const fetchSeminars = (): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  getSeminars()
    .then((response) => dispatch(getSeminarsSuccess(response)))
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedAdminCheck(error))
    })
}

export const createSeminar = (
  values: SeminarCreateParams,
  callback: () => void,
  resolve: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postSeminar(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedAdminCheck(error, resolve))
    })
}

export const updateSeminar = (
  id: number,
  values: SeminarCreateParams,
  callback: () => void,
  resolve: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchSeminar(id, values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedAdminCheck(error, resolve))
    })
}

export const destroySeminar = (id: number, callback: () => void): AppThunk => async (
  dispatch,
) => {
  dispatch(requestStart())
  deleteSeminar(id)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
    })
}
