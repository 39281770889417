import LocalStorage from '@/utils/localStorage'

const KEY = 'hasNoEnoughInputData'

export const getStorageHasNoEnoughInputData = () => {
  return LocalStorage.getItem(KEY)
}

export const setStorageHasNoEnoughInputData = (value: boolean) => {
  return LocalStorage.setItem(KEY, value)
}

export const removeStorageHasNoEnoughInputData = () => {
  LocalStorage.removeItem(KEY)
}
