import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'react-final-form'
import clsx from 'clsx'
import { cloneDeep as _cloneDeep, isNull as _isNull } from 'lodash'
import Heading from '@/components/v2/atoms/Heading'
import Typography from '@/components/v2/atoms/Typography'
import FieldBlock from '@/components/v2/molecules/FieldBlock'
import TextField from '@/components/v2/atoms/TextField'
import Link from '@/components/v2/atoms/Link'
import CheckboxGroup from '@/components/v2/molecules/CheckboxGroup'
import WholeSubmitErrorMessage from '@/components/v2/molecules/WholeSubmitErrorMessage'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import { API_BOOLEAN_FLAG } from '@/models/commonsModelFunc'
import { RootState } from '@/store'
import {
  composeValidators,
  required,
  requiredForArray,
  mailAddress,
  kana,
} from '@/utils/validate'
import { fetchSeminar } from '@/containers/seminarSlice'
import { SeminarParticipantUpdateValues } from '@/models/seminarParticipantsModel'

interface SeminarParticipantFormProps {
  /** タイトル */
  title: string
  /** ボタンラベル */
  buttonLabel: string
  /** UniqueId */
  uniqueId: string
  /** 画面タイプ */
  type: 'new' | 'confirm'
  /** onSubmitイベントメソッド */
  handleSubmit: (values: SeminarParticipantUpdateValues) => void
  /** 修正ボタンメソッド */
  handleFix?: () => void
}

const SeminarParticipantForm: FC<SeminarParticipantFormProps> = (props) => {
  const dispatch = useDispatch()

  const { title, buttonLabel, uniqueId, type, handleSubmit, handleFix } = props
  const { seminar, isLoading } = useSelector((state: RootState) => state.seminar)
  const { tmpSeminarParticipant } = useSelector(
    (state: RootState) => state.seminarParticipants,
  )

  const isConfirm = type === 'confirm'

  useEffect(() => {
    dispatch(fetchSeminar(uniqueId))
  }, [])

  const handleMovePolicyPage = () => {
    window.open('/v2/policy')
  }

  const handleCommonSubmit = (values: any) => {
    const newValues = _cloneDeep(values)

    return handleSubmit(newValues)
  }

  return (
    <div className="space-y-30 text-black-800">
      <Heading className="text-center">{title}</Heading>
      <Form
        onSubmit={handleCommonSubmit}
        initialValues={{ _: seminar.seminar_title, ...tmpSeminarParticipant }}
        render={({ handleSubmit, values, errors, submitErrors }) => {
          return (
            <form className="space-y-24" onSubmit={handleSubmit}>
              <FieldBlock label="セミナー名" required>
                <TextField type="text" name="_" disabled={true} isFullWidth />
              </FieldBlock>

              <FieldBlock label="メールアドレス" required>
                <TextField
                  type="text"
                  name="seminar_participant.mail_address"
                  disabled={isConfirm}
                  isFullWidth
                  validate={composeValidators(required, mailAddress)}
                />
              </FieldBlock>
              <div className="flex flex-col md:flex-row space-y-24 md:space-y-0 md:space-x-24">
                <FieldBlock label="お名前(姓)" className="flex-1" required>
                  <TextField
                    type="text"
                    name="seminar_participant.name_sei"
                    disabled={isConfirm}
                    isFullWidth
                    validate={required}
                  />
                </FieldBlock>
                <FieldBlock label="お名前(名)" className="flex-1" required>
                  <TextField
                    type="text"
                    name="seminar_participant.name_mei"
                    disabled={isConfirm}
                    isFullWidth
                    validate={required}
                  />
                </FieldBlock>
              </div>
              {/* <div className="flex flex-col md:flex-row space-y-24 md:space-y-0 md:space-x-24">
                <FieldBlock label="ふりがな(せい)" className="flex-1" required>
                  <TextField
                    type="text"
                    name="seminar_participant.name_sei_kana"
                    disabled={isConfirm}
                    isFullWidth
                    validate={composeValidators(required, kana)}
                  />
                </FieldBlock>
                <FieldBlock label="ふりがな(めい)" className="flex-1" required>
                  <TextField
                    type="text"
                    name="seminar_participant.name_mei_kana"
                    disabled={isConfirm}
                    isFullWidth
                    validate={composeValidators(required, kana)}
                  />
                </FieldBlock>
              </div> */}

              <FieldBlock label="電話番号(ハイフンなし)" required>
                <TextField
                  type="text"
                  name="seminar_participant.tel_number"
                  disabled={isConfirm}
                  isFullWidth
                  validate={composeValidators(required)}
                />
              </FieldBlock>

              <FieldBlock label="プライバシーポリシーへの同意" required>
                <CheckboxGroup
                  name="is_agree_policy"
                  disabled={isConfirm}
                  datas={[
                    {
                      label: (
                        <Typography>
                          私は、
                          <Link
                            className={clsx({
                              'text-primary-900': !isConfirm,
                              'text-black-400 pointer-events-none': isConfirm,
                            })}
                            children="プライバシーポリシー"
                            onClick={handleMovePolicyPage}
                          />
                          に同意します。
                        </Typography>
                      ),
                      value: API_BOOLEAN_FLAG.on,
                    },
                  ]}
                  validate={requiredForArray}
                />
              </FieldBlock>

              <WholeSubmitErrorMessage submitErrors={submitErrors} />
              <div className="flex flex-col items-center space-y-24">
                <ButtonPrimary
                  className="h-45 w-full md:w-215 text-white"
                  onClick={() => handleSubmit(values)}
                >
                  <Typography
                    className="bottom-2 flex-auto pl-30 relative text-16"
                    isBold
                  >
                    {buttonLabel}
                  </Typography>
                  <i className="ico chevron-right mr-17 text-24" />
                </ButtonPrimary>
                {isConfirm && (
                  <div className="text-center">
                    <Link
                      className={clsx('text-14 text-primary-900', {
                        'text-black-400 pointer-events-none': isLoading,
                      })}
                      children="修正する"
                      onClick={handleFix}
                    />
                  </div>
                )}
              </div>
            </form>
          )
        }}
      />
    </div>
  )
}

export default SeminarParticipantForm
