import React, { FC } from 'react'
import { FieldMetaState } from 'react-final-form'
import Typography from '@/components/v2/atoms/Typography'

interface ValidMessageProps {
  /** メタデータ */
  meta: FieldMetaState<any>
}

const ValidMessage: FC<ValidMessageProps> = (props) => {
  const { meta } = props

  return (
    <>
      {(meta.error || meta.submitError) && meta.touched && (
        <Typography className="flex items-center mt-5 text-12 text-alert-600 max-w-fit">
          <i className="ico info mr-2 text-16" />
          {meta.error ?? meta.submitError}
        </Typography>
      )}
    </>
  )
}

export default ValidMessage
