import ApiClient from '@/api/apiClient'
import {
  CompanyRiskCreateValues,
  CompanyRiskUpdateValues,
} from '@/models/admins/companyRisksModel'
import { CompanyRisk } from '@/models/admins/companyRisksModel'

export const getCompanyRisk = async () => {
  return await ApiClient.get(
    `/company_risks`,
    (response: CompanyRisk | null) => response,
    undefined,
    false,
    'v2',
  )
}

export const postCompanyRisk = async (values: CompanyRiskCreateValues) => {
  return await ApiClient.post(
    `/company_risks`,
    (response: CompanyRisk | null) => response,
    values,
    undefined,
    'v2',
  )
}

export const patchCompanyRisk = async (values: CompanyRiskUpdateValues) => {
  return await ApiClient.patch(
    `/company_risks`,
    (response: CompanyRisk | null) => response,
    values,
    undefined,
    'v2',
  )
}

export const deleteCompanyRisk = async () => {
  return await ApiClient.delete(`/company_risks`, () => {}, undefined, undefined, 'v2')
}
