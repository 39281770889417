import ApiClient from '@/api/apiClient'
import {
  InputCustomItemCreateValues,
  InputCustomItemDestroyValues,
  InputCustomItemUpdateValues,
} from '@/models/inputs/inputCustomItemsModel'

export const patchInputCustomItem = async (
  values: InputCustomItemCreateValues | InputCustomItemUpdateValues,
) => {
  return await ApiClient.patch('/input_custom_items', () => {}, values)
}

export const deleteInputCustomItem = async (values: {
  params: InputCustomItemDestroyValues
}) => {
  return await ApiClient.delete('/input_custom_items', () => {}, values)
}

export const getInputCustomItemV2 = async (
) => {
  return await ApiClient.get('/input_custom_items', (response: any) => response, undefined, false, "v2")
}
