import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Typography from '@/components/v2/atoms/Typography'
import { setPageMeta } from '@/containers/pageMetasSlice'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import { RootState } from '@/store'
import MyDataListWrapper from '@/templates/v2/myData/_ListWrapper'
import { fetchInputYearlyLivingCostsV2 } from '@/containers/inputs/inputYearlyLivingCostsSlice'

const MyDataYearlyLivingCost: FC = () => {
  const dispatch = useDispatch()
  const { handleMovePage } = useCustomHistory()

  const {
    input_yearly_living_cost_electronics, // 家電
    input_yearly_living_cost_ceremonies, // 冠婚葬祭
    input_yearly_living_cost_home_comings, // 帰省
    input_yearly_living_cost_travels, // 旅行
  } = useSelector((state: RootState) => state.inputYearlyLivingCosts)

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'マイデータ | 特別な支出・大きな支出',
        description: 'マイデータ | 特別な支出・大きな支出',
        keyword: 'マイデータ | 特別な支出・大きな支出',
      }),
    )

    dispatch(fetchInputYearlyLivingCostsV2())
  }, [])

  return (
    <MyDataListWrapper
      title="特別な支出・大きな支出"
      description="給与明細が必要です。ご用意ください。"
      onClick={() => handleMovePage('/v2/myData/yearlyLivingCosts/edit')}
    >
      <Typography className="border-b-[0.5px] pb-[5px] md:mb-[10px]">家電</Typography>
      <table className="w-[100%] mb-[60px] last:mb-0 text-[14px]">
        <tbody>
          <tr className="hidden md:table-row h-[45px] odd:bg-black-100">
            <td className="w-full md:w-[25%] px-[16px] md:px-[30px]">
              <Typography></Typography>
            </td>
            <td className="hidden md:table-cell w-[25%] px-[16px] md:px-[30px]">
              <Typography isBold>次回買い替え時期</Typography>
            </td>
            <td className="hidden md:table-cell w-[25%] px-[16px] md:px-[30px]">
              <Typography isBold>買い替え頻度</Typography>
            </td>
            <td className="hidden md:table-cell w-[25%] px-[16px] md:px-[30px]">
              <Typography isBold>1回あたりの費用</Typography>
            </td>
          </tr>
          {input_yearly_living_cost_electronics.map((item: any) => (
            <tr className="h-[45px] odd:bg-black-100 border-b-[0.5px] md:border-none">
              <td className="w-full md:w-[25%] block md:table-cell md:px-[30px] mb-[10px] mt-[20px]">
                <Typography className="md:font-bold">{item.display_name}</Typography>
              </td>
              <td className="w-full md:w-[25%] block md:table-cell md:px-[30px] my-[10px] ml-[10px]">
                <div className="flex">
                  <Typography isBold className="block md:hidden w-[50%]">
                    次回買い替え時期
                  </Typography>
                  <Typography>ご本人が{item.next_purchase_at_age}歳の時</Typography>
                </div>
              </td>
              <td className="w-full md:w-[25%] block md:table-cell md:px-[30px] my-[10px] ml-[10px]">
                <div className="flex">
                  <Typography isBold className="block md:hidden w-[50%]">
                    買い替え頻度
                  </Typography>
                  <Typography>{item.occurance_in_years}年ごと</Typography>
                </div>
              </td>
              <td className="w-full md:w-[25%] block md:table-cell md:px-[30px] mt-[10px] mb-[20px]  ml-[10px]">
                <div className="flex">
                  <Typography isBold className="block md:hidden w-[50%]">
                    1回あたりの費用
                  </Typography>
                  <Typography>{item.single_cost.toLocaleString()}円</Typography>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Typography styleType="underlinedHeaderSmall">冠婚葬祭</Typography>
      <table className="w-[100%] mb-[60px] last:mb-0 text-[14px]">
        <tbody>
          <tr className="h-[45px] odd:bg-black-100 border-b-[0.5px] md:border-none">
            <td className="w-[50%] px-[16px] md:px-[30px]">
              <Typography isBold>ご本人の年齢</Typography>
            </td>
            <td className="w-[50%] px-[16px] md:px-[30px]">
              <Typography isBold>年間の冠婚葬祭にかかる費用</Typography>
            </td>
          </tr>

          {input_yearly_living_cost_ceremonies.map((item: any) => (
            <tr className="h-[45px] odd:bg-black-100">
              <td className="w-[50%] px-[16px] md:px-[30px]">
                <Typography>
                  {item.affected_start_at_age}歳から{item.affected_end_at_age}歳
                </Typography>
              </td>
              <td className="w-[50%] px-[16px] md:px-[30px]">
                <Typography>{item.yearly_cost.toLocaleString()}円</Typography>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Typography styleType="underlinedHeaderSmall">帰省</Typography>
      <table className="w-[100%] mb-[60px] last:mb-0 text-[12px] md:text-[14px]">
        <tbody>
          {input_yearly_living_cost_home_comings.map((item: any) => (
            <>
              <tr className="h-[45px] odd:bg-black-100">
                <td className="w-[50%] px-[16px] md:px-[30px]">
                  <Typography isBold>費用</Typography>
                </td>
                <td className="w-[50%] px-[16px] md:px-[30px]">
                  <Typography isBold>{item.single_cost.toLocaleString()}円</Typography>
                </td>
              </tr>
              <tr className="h-[45px] odd:bg-black-100">
                <td className="w-[50%] px-[16px] md:px-[30px]">
                  <Typography isBold>頻度</Typography>
                </td>
                <td className="w-[50%] px-[16px] md:px-[30px]">
                  <Typography isBold>
                    {item.occurance_term_in_years}年に{item.occurance_per_occurance_term}
                    回
                  </Typography>
                </td>
              </tr>
              <tr className="h-[45px] odd:bg-black-100">
                <td className="w-[50%] px-[16px] md:px-[30px]">
                  <Typography isBold>適用頻度</Typography>
                </td>
                <td className="w-[50%] px-[16px] md:px-[30px]">
                  <Typography isBold>
                    {item.affected_start_at_age}歳から{item.affected_end_at_age}歳まで
                  </Typography>
                </td>
              </tr>
            </>
          ))}
        </tbody>
      </table>

      <Typography styleType="underlinedHeaderSmall">旅行</Typography>
      <table className="w-[100%] mb-[60px] last:mb-0 text-[12px] md:text-[14px]">
        <tbody>
          {input_yearly_living_cost_travels.map((item: any) => (
            <>
              <tr className="h-[45px] odd:bg-black-100">
                <td className="w-[50%] px-[16px] md:px-[30px]">
                  <Typography isBold>費用</Typography>
                </td>
                <td className="w-[50%] px-[16px] md:px-[30px]">
                  <Typography isBold>{item.single_cost.toLocaleString()}円</Typography>
                </td>
              </tr>
              <tr className="h-[45px] odd:bg-black-100">
                <td className="w-[50%] px-[16px] md:px-[30px]">
                  <Typography isBold>頻度</Typography>
                </td>
                <td className="w-[50%] px-[16px] md:px-[30px]">
                  <Typography isBold>
                    {item.occurance_term_in_years}年に{item.occurance_per_occurance_term}
                    回
                  </Typography>
                </td>
              </tr>
              <tr className="h-[45px] odd:bg-black-100">
                <td className="w-[50%] px-[16px] md:px-[30px]">
                  <Typography isBold>適用頻度</Typography>
                </td>
                <td className="w-[50%] px-[16px] md:px-[30px]">
                  <Typography isBold>
                    {item.affected_start_at_age}歳から{item.affected_end_at_age}歳まで
                  </Typography>
                </td>
              </tr>
            </>
          ))}
        </tbody>
      </table>
    </MyDataListWrapper>
  )
}

export default MyDataYearlyLivingCost
