import React, { FC, useEffect, useContext } from 'react'
import FamilyMonochromaticIcon from '@/assets/images/v2/family-monochromatic.svg'
import HelpCircleIcon from '@/assets/images/v2/help-circle.svg'
import Divider from '@/components/v2/atoms/Divider'
import MarginContainer from '@/components/v2/atoms/MarginContainer'
import Paper from '@/components/v2/atoms/Paper'
import Typography from '@/components/v2/atoms/Typography'
import Breadcrumb from '@/components/v2/molecules/Breadcrumb'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { find as _find, set as _set } from 'lodash'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import { setPageMeta } from '@/containers/pageMetasSlice'
import PaperTitle from '@/components/v2/molecules/PaperTitle'
import { AccountContext } from '@/models/accountsModelFunc'
import MyDataHousingsEditForm from './FormTransfer'

const MyDataHousingsEdit: FC = () => {
  const dispatch = useDispatch()
  const { handleMovePage } = useCustomHistory()
  const { isAdmin } = useContext(AccountContext)
  const { account_id } = useParams<{ account_id?: string }>()

  let { id } = useParams<{ id?: string }>()

  const movePageAfterForm = () => {
    handleMovePage(
      isAdmin ? `/v2/accounts/${account_id}/myData/housings` : '/v2/myData/housings',
    )
  }

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'マイデータ | 単身赴任 編集',
        description: 'マイデータ | 単身赴任 編集',
        keyword: 'マイデータ | 単身赴任 編集',
      }),
    )
  }, [])

  return (
    <MarginContainer className="px-8 md:px-32 lg:px-0 py-8 md:py-40">
      <Breadcrumb
        className="pb-24 md:pb-32"
        data={[
          {
            name: 'マイデータ',
            url: isAdmin ? `/v2/accounts/${account_id}/myData` : '/v2/myData',
          },
          {
            name: 'マイデータ設定',
            url: isAdmin
              ? `/v2/accounts/${account_id}/myData/housings`
              : '/v2/myData/housings',
          },
          { name: '単身赴任の設定' },
        ]}
      />

      <PaperTitle
        className="mb-[16px] md:mb-[40px]"
        title="マイデータの設定"
        description="日々の収入や支出を登録することで改善案などのご提案が可能になります"
        icon={<FamilyMonochromaticIcon />}
        smallIcon={
          <FamilyMonochromaticIcon className="h-[82px] w-[130px]" viewBox="0 0 117 154" />
        }
      />

      <Paper className="bg-white p-24 md:p-40">
        <div className="flex items-center pb-10">
          <Typography
            className="bottom-2 pr-8 relative text-24 md:text-28 text-black-800"
            isBold
          >
            単身赴任について
          </Typography>
          <HelpCircleIcon />
        </div>

        <Divider className="border-black-300 border-t-2 pb-20 md:pb-24" />

        <MyDataHousingsEditForm
          id={id}
          onClose={movePageAfterForm}
          onSubmit={movePageAfterForm}
        />
      </Paper>
    </MarginContainer>
  )
}

export default MyDataHousingsEdit
