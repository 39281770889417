import ApiClient from '../apiClient'

export const getAdminAccount = async () => {
  return await ApiClient.get(`/accounts`, (response: any) => response)
}

export const getAdminUnassignedAccounts = async () => {
  return await ApiClient.get(
    `/admin/accounts/unassigned_accounts`,
    (response: any[]) => response,
  )
}
