import React, { FC } from 'react'
import PlusPrimaryIcon from '@/assets/images/v2/plus-primary.svg'
import Button from '@/components/v2/atoms/Button'
import Typography from '@/components/v2/atoms/Typography'

interface SimulationNewButtonProps {
  /** onClickメソッド */
  onClick?: () => void
  buttonTitle?: string
  isDisabled?: boolean
}

const SimulationNewButton: FC<SimulationNewButtonProps> = (props) => {
  const { onClick, buttonTitle, isDisabled } = props

  return (
    <Button
      className="w-full md:w-[400px] h-[37px] mx-[auto] mb-[0] mt-[20px] border border-dashed border-primary-500 hover:bg-primary-50"
      onClick={onClick}
      isDisabled={isDisabled}
    >
      <PlusPrimaryIcon />
      <Typography className="relative bottom-[1px] px-[12px] text-primary-500" isBold>
        {!!buttonTitle ? buttonTitle : '新規作成'}
      </Typography>
    </Button>
  )
}

export default SimulationNewButton
