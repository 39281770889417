import React, { FC, useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { find as _find, isUndefined as _isUndefined } from 'lodash'
import Button from '@/components/v2/atoms/Button'
import Typography from '@/components/v2/atoms/Typography'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import { setPageMeta } from '@/containers/pageMetasSlice'
import { fetchInputCorporatePensions } from '@/containers/inputs/inputCorporatePensionsSlice'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import { defaultToEmptyString, convertLocaleString } from '@/models/commonsModelFunc'
import {
  convertPensionType,
  PENSION_TYPE,
} from '@/models/inputs/inputCorporatePensionsModelFunc'
import { RootState } from '@/store'
import MyDataListWrapper from '@/templates/v2/myData/_ListWrapper'
import { PERSON_FLG } from '@/models/familyStructuresModelFunc'
import { AccountContext } from '@/models/accountsModelFunc'
import { useAccountControl } from '@/hooks/useAccountControl'

const MyDataCorporatePension: FC = () => {
  const dispatch = useDispatch()
  const { handleMovePage } = useCustomHistory()
  const { isAdmin } = useContext(AccountContext)
  const { isMyDataManipulatedApproval } = useAccountControl()

  const { inputCorporatePensions } = useSelector(
    (state: RootState) => state.inputCorporatePensions,
  )

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'マイデータ | 企業年金',
        description: 'マイデータ | 企業年金',
        keyword: 'マイデータ | 企業年金',
      }),
    )
    dispatch(fetchInputCorporatePensions())
  }, [])

  const renderTable = (isPerson: boolean) => {
    const personFlg = isPerson ? PERSON_FLG.person : PERSON_FLG.spouse
    const targetCorporatePensions = _find(
      inputCorporatePensions,
      (item) => item.person_flg === personFlg,
    )

    return (
      <div className="mb-[60px] last:mb-0">
        <div className="sm:flex items-center justify-between pb-[20px]">
          <CircleTitle
            title={isPerson ? 'ご本人' : '配偶者'}
            classNameTitle="text-[20px] md:text-[24px]"
          />
          <Button
            className="w-[84px] md:w-[112px] h-[26px] md:h-[37px] mr-[0] md:mr-[9px] rounded-[4px] bg-white border border-secondary-500 hover:bg-secondary-50"
            isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
            onClick={() =>
              handleMovePage(
                `/v2/myData/corporatePensions/${isPerson ? 'person' : 'spouse'}/edit`,
              )
            }
          >
            <Typography
              className="relative bottom-[1px] text-[12px] md:text-[14px] text-secondary-500"
              isBold
            >
              編集する
            </Typography>
          </Button>
        </div>

        {!_isUndefined(targetCorporatePensions) && (
          <table className="w-[100%] text-[12px] md:text-[14px]">
            <tbody>
              <tr className="h-[45px] odd:bg-black-100">
                <td className="w-[30%] px-[16px] md:px-[30px]">
                  <Typography isBold>企業年金名</Typography>
                </td>
                <td className="w-[70%] px-[16px] md:px-[30px]">
                  <Typography>
                    {defaultToEmptyString(targetCorporatePensions?.name)}
                  </Typography>
                </td>
              </tr>
              <tr className="h-[45px] odd:bg-black-100">
                <td className="px-[16px] md:px-[30px]">
                  <Typography isBold>年金タイプ</Typography>
                </td>
                <td className="px-[16px] md:px-[30px]">
                  <Typography>
                    {convertPensionType(targetCorporatePensions?.pension_type)}
                  </Typography>
                </td>
              </tr>
              <tr className="h-[45px] odd:bg-black-100">
                <td className="px-[16px] md:px-[30px]">
                  <Typography isBold>受取期間</Typography>
                </td>
                <td className="px-[16px] md:px-[30px]">
                  <Typography>
                    {targetCorporatePensions?.pension_type === PENSION_TYPE.confirm
                      ? `${defaultToEmptyString(
                          targetCorporatePensions?.receipt_period,
                        )}年`
                      : '未選択'}
                  </Typography>
                </td>
              </tr>
              <tr className="h-[45px] odd:bg-black-100">
                <td className="px-[16px] md:px-[30px]">
                  <Typography isBold>受取年金額</Typography>
                </td>
                <td className="px-[16px] md:px-[30px]">
                  <Typography>{`${defaultToEmptyString(
                    targetCorporatePensions?.start_receiving_year_at_age,
                  )}歳から${convertLocaleString(
                    targetCorporatePensions?.receiving_pension_amount_manyen,
                  )}万円`}</Typography>
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
    )
  }

  return (
    <MyDataListWrapper
      title="企業年金"
      description="給与明細が必要です。ご用意ください。"
    >
      {renderTable(true)}
      {renderTable(false)}
    </MyDataListWrapper>
  )
}

export default MyDataCorporatePension
