import React, { FC, useEffect, useMemo, useState, MouseEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import ChevronRightIcon from '@/assets/images/v2/chevron-right.svg'
import { MoreVert as MoreVertIcon } from '@material-ui/icons'
import Button from '@/components/v2/atoms/Button'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import Popover from '@/components/v1/atoms/Popover'
import Typography from '@/components/v2/atoms/Typography'
import NumberField from '@/components/v2/molecules/NumberField'
import SelectBox from '@/components/v2/molecules/SelectBox'
import { setPageMeta } from '@/containers/pageMetasSlice'
import { RootState } from '@/store'
import {
  includes as _includes,
  split as _split,
  set as _set,
  each as _each,
} from 'lodash'
import {
  castNumberWithoutOperator,
  convertCheckboxValue,
  isNotEmptyValue,
} from '@/models/commonsModelFunc'
import { fetchFamilyStructureV2 } from '@/containers/familyStructuresSlice'
import {
  createInputCustomItem,
  fetchInputCustomItemV2,
  updateInputCustomItem,
} from '@/containers/inputs/inputCustomItemsSlice'
import { Category, InputCustomItem } from '@/models/inputs/inputCustomItemsModel'
import {
  CUSTOM_ITEM_CATEGORY,
  PAYMENT_MODE,
  pickInputCustomItemRequiredValues,
  buildUpdateInitialValues,
} from '@/models/inputs/inputCustomItemsModelFunc'
import Grid from '@/components/v2/atoms/Grid'
import TextField from '@/components/v2/atoms/TextField'
import { PAYMENT_MODE_SELECT_ITEMS } from '@/constants/formItem/inputs/inputCustomItems'
import NumberFieldDecimal from '@/components/v2/molecules/NumberFieldDecimal'
import Checkbox from '@/components/v2/atoms/Checkbox'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import WholeSubmitErrorMessage from '@/components/v2/molecules/WholeSubmitErrorMessage'
import LoadingOverlay from '@/components/v1/atoms/LoadingOverlay'
import ValidMessage from '@/components/v2/atoms/ValidMessage'
import { composeValidators, required, zeroOrMoreNumber } from '@/utils/validate'
import { getUrlPersonFlg, isPerson } from '@/models/familyStructuresModelFunc'
import SubmitErrorMessage from '@/components/v2/molecules/SubmitErrorMessage'
import clsx from 'clsx'
import { updateResetFlg } from '@/containers/futures/futureBasesSlice'

export const CUSTOM_ITEM_EXPENSE_CATEGORY_SELECT_ITEMS = [
  { value: '', label: '支出', isDisabled: true },
  { value: CUSTOM_ITEM_CATEGORY.livingCost, label: '基本生活費' },
  { value: CUSTOM_ITEM_CATEGORY.insurance, label: '生命保険料' },
  { value: CUSTOM_ITEM_CATEGORY.yearlyLivingCost, label: '特別な支出・大きな支出' },
  { value: CUSTOM_ITEM_CATEGORY.housingCost, label: '住宅関連費用' },
  {
    value: CUSTOM_ITEM_CATEGORY.houseRunningCost,
    label: '自宅 管理費・修繕積立金・固定資産税',
  },
  {
    value: CUSTOM_ITEM_CATEGORY.estateInvestmentOtherCost,
    label: '不動産投資 その他費用',
  },
  { value: CUSTOM_ITEM_CATEGORY.personSideBusinessExpense, label: 'ご本人副業経費' },
  { value: CUSTOM_ITEM_CATEGORY.spouseSideBusinessExpense, label: '配偶者副業経費' },
  { value: CUSTOM_ITEM_CATEGORY.customOtherSpending, label: 'その他の支出' },
]

export const CUSTOM_ITEM_INCOME_CATEGORY_SELECT_ITEMS = [
  { value: '', label: '収入', isDisabled: true },
  { value: CUSTOM_ITEM_CATEGORY.personGrossIncome, label: 'ご本人額面給与' },
  { value: CUSTOM_ITEM_CATEGORY.spouseGrossIncome, label: '配偶者額面給与' },
  { value: CUSTOM_ITEM_CATEGORY.personSeverancePay, label: 'ご本人退職金' },
  { value: CUSTOM_ITEM_CATEGORY.spouseSeverancePay, label: '配偶者退職金' },
  { value: CUSTOM_ITEM_CATEGORY.personOtherIncome, label: 'ご本人その他の収入' },
  { value: CUSTOM_ITEM_CATEGORY.spouseOtherIncome, label: '配偶者その他の収入' },
  { value: CUSTOM_ITEM_CATEGORY.estateInvestmentIncome, label: '不動産投資収入' },
  { value: CUSTOM_ITEM_CATEGORY.personSideBusinessIncome, label: 'ご本人副業収入' },
  { value: CUSTOM_ITEM_CATEGORY.spouseSideBusinessIncome, label: '配偶者副業収入' },
  { value: CUSTOM_ITEM_CATEGORY.personPublicPensionIncome, label: 'ご本人公的年金収入' },
  { value: CUSTOM_ITEM_CATEGORY.spousePublicPensionIncome, label: '配偶者公的年金収入' },
  { value: CUSTOM_ITEM_CATEGORY.customOtherIncome, label: 'その他の収入' },
]

interface MyDataCommonItemFormProps {
  onClose: () => void
  onSubmit: () => void
}

const MyDataCommonItemForm: FC<MyDataCommonItemFormProps> = (props) => {
  const dispatch = useDispatch()
  const { showFlashMsg } = useFlashAlert()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [popoverOpenKey, setPopoverOpenIndex] = useState<string | null>(null)

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'マイデータ | カスタム設定',
        description: 'マイデータ | カスタム設定',
        keyword: 'マイデータ | カスタム設定',
      }),
    )

    dispatch(fetchFamilyStructureV2())
    if (inputCustomItems.length == 0) dispatch(fetchInputCustomItemV2())
  }, [])

  const { inputCustomItems, isLoading } = useSelector(
    (state: RootState) => state.inputCustomItems,
  )

  const {
    familyInfo: { current_child_count },
  } = useSelector((state: RootState) => state.familyStructures)

  const { familyStructures } = useSelector((state: RootState) => state.familyStructures)

  const isLoadingFamilyStructures = useSelector(
    (state: RootState) => state.familyStructures.isLoading,
  )
  const personFlg = getUrlPersonFlg()

  const currentAge = isPerson(personFlg)
    ? familyStructures?.person_age || 0
    : familyStructures?.spouse_age || 0

  const isEdit = useMemo(() => inputCustomItems.length > 0, [inputCustomItems])
  const initialValues = useMemo(
    () =>
      isEdit
        ? buildUpdateInitialValues(inputCustomItems, '', () => {})
        : {
            is_all_active: [true],
            input_custom_items_attributes: [],
            input_custom_items_income_attributes: [],
            input_custom_items_expense_attributes: [],
          },
    [inputCustomItems],
  )

  const expenseCategorySelectItems = useMemo(() => {
    let newSelectItems = [...CUSTOM_ITEM_EXPENSE_CATEGORY_SELECT_ITEMS]
    for (let i = 1; i <= current_child_count; i++) {
      newSelectItems.push({
        value: `${CUSTOM_ITEM_CATEGORY.tuition}_${i}`,
        label: `第${i}子学費`,
      })
    }
    return newSelectItems
  }, [])

  const incomeCategorySelectItems = [...CUSTOM_ITEM_INCOME_CATEGORY_SELECT_ITEMS]

  const handleOpenPopover = (event: MouseEvent<HTMLElement>, key: string) => {
    setAnchorEl(event.currentTarget)
    setPopoverOpenIndex(key)
  }
  const handleClosePopover = () => {
    setAnchorEl(null)
    setPopoverOpenIndex(null)
  }

  const handleSubmit = (values: any) => {
    return isEdit ? handleUpdate(values) : handleCreate(values)
  }

  const handleCreate = (values: any) => {
    const pickValues: any = values
    const {
      input_custom_items_income_attributes,
      input_custom_items_expense_attributes,
    } = pickValues

    const inputCutomItems = [
      ...input_custom_items_income_attributes,
      ...input_custom_items_expense_attributes,
    ].map((item: InputCustomItem) => {
      const category = _includes(item.category, CUSTOM_ITEM_CATEGORY.tuition)
        ? _split(item.category, '_')[0]
        : item.category

      return {
        ...item,
        is_active: convertCheckboxValue(item.is_active, true, false),
        category: category as Category,
        cost: castNumberWithoutOperator(item.cost),
        payment_start_at_age: castNumberWithoutOperator(item.payment_start_at_age),
        payment_end_at_age: item.payment_end_at_age
          ? castNumberWithoutOperator(item.payment_end_at_age)
          : null,
        occurance_in_years: item.occurance_in_years
          ? castNumberWithoutOperator(item.occurance_in_years)
          : null,
        changed_rate_percent:
          item.payment_mode == PAYMENT_MODE.repeatedly
            ? item.changed_rate_percent && Number(item.changed_rate_percent) !== 0
              ? item.changed_rate_percent
              : 0
            : null,
        other_objects:
          category == CUSTOM_ITEM_CATEGORY.tuition
            ? { child_index: Number(_split(item.category, '_')[1]) }
            : null,
      }
    })

    const newValues = inputCutomItems.map((item) =>
      pickInputCustomItemRequiredValues(item),
    )

    return dispatch(
      createInputCustomItem(
        {
          account: {
            input_custom_items_attributes: [...newValues],
          },
        },
        () => {
          showFlashMsg(isEdit ? '更新しました' : '作成しました')
          dispatch(fetchInputCustomItemV2())
          dispatch(updateResetFlg())
          props.onSubmit()
        },
      ),
    )
  }

  const handleUpdate = (values: any) => {
    const pickValues: any = values
    const {
      input_custom_items_income_attributes,
      input_custom_items_expense_attributes,
    } = pickValues

    const inputCutomItems = [
      ...input_custom_items_income_attributes,
      ...input_custom_items_expense_attributes,
    ].map((item: InputCustomItem) => {
      const category = _includes(item.category, CUSTOM_ITEM_CATEGORY.tuition)
        ? _split(item.category, '_')[0]
        : item.category
      const otherObjects =
        category == CUSTOM_ITEM_CATEGORY.tuition
          ? { child_index: Number(_split(item.category, '_')[1]) }
          : null

      return {
        ...item,
        is_active: convertCheckboxValue(item.is_active, true, false),
        category: category as Category,
        cost: castNumberWithoutOperator(item.cost),
        payment_start_at_age: castNumberWithoutOperator(item.payment_start_at_age),
        payment_end_at_age: item.payment_end_at_age
          ? castNumberWithoutOperator(item.payment_end_at_age)
          : null,
        occurance_in_years: isNotEmptyValue(item.occurance_in_years)
          ? castNumberWithoutOperator(item.occurance_in_years)
          : null,
        changed_rate_percent:
          item.payment_mode == PAYMENT_MODE.repeatedly
            ? item.changed_rate_percent && Number(item.changed_rate_percent) !== 0
              ? item.changed_rate_percent
              : 0
            : null,
        other_objects: otherObjects,
      }
    })

    const newValues = inputCutomItems.map((item) =>
      pickInputCustomItemRequiredValues(item),
    )

    dispatch(
      updateInputCustomItem(
        {
          account: {
            input_custom_items_attributes: [...newValues],
          },
        },
        () => {
          showFlashMsg(isEdit ? '更新しました' : '作成しました')
          dispatch(fetchInputCustomItemV2())
          dispatch(updateResetFlg())
          props.onSubmit()
        },
      ),
    )
  }

  return (
    <>
      <LoadingOverlay isLoading={isLoading || isLoadingFamilyStructures} />
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validate={(values) => {
          const validateMessage = {}

          const validatePaymentAge = (key: string) => {
            _each(values[key], (item, index) => {
              if (item.payment_mode == PAYMENT_MODE.repeatedly) {
                if (
                  castNumberWithoutOperator(item.payment_start_at_age) >
                  castNumberWithoutOperator(item.payment_end_at_age)
                ) {
                  _set(
                    validateMessage,
                    `${key}[${index}]payment_end_at_age`,
                    '開始年齢より後の年齢を入力してください',
                  )
                }
                if (castNumberWithoutOperator(item.payment_start_at_age) < currentAge) {
                  _set(
                    validateMessage,
                    `${key}[${index}]payment_start_at_age`,
                    '現在の年齢より後の年齢を入力してください',
                  )
                }
              }
              if (item.payment_mode == PAYMENT_MODE.once) {
                if (castNumberWithoutOperator(item.payment_start_at_age) < currentAge) {
                  _set(
                    validateMessage,
                    `${key}[${index}]payment_start_at_age`,
                    '現在の年齢より後の年齢を入力してください',
                  )
                }
              }
            })
          }

          validatePaymentAge('input_custom_items_income_attributes')
          validatePaymentAge('input_custom_items_expense_attributes')

          return validateMessage
        }}
        mutators={{ ...arrayMutators }}
        render={({
          handleSubmit,
          values,
          errors,
          submitErrors,
          submitFailed,
          form: {
            mutators: { push },
          },
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="mt-30">
              <CircleTitle title="収入" />
              <Grid className="pb-[64px] grid-cols-1 lg:grid-cols-1 gap-x-[40px] gap-y-[16px]">
                <table className="lg:table w-full text-[14px]">
                  <tbody>
                    <tr className="border-b border-black-300 w-full hidden lg:table-row">
                      <td className="w-[15px] pb-[10px]"></td>
                      <td className="pb-[10px] w-[20%]">
                        <Typography className="table mx-auto" isBold>
                          項目名
                        </Typography>
                      </td>
                      <td className="pb-[10px] w-[20%]">
                        <Typography className="table mx-auto" isBold>
                          科目
                        </Typography>
                      </td>
                      <td className="pb-[10px] w-[20%]">
                        <Typography className="table mx-auto" isBold>
                          適用頻度
                        </Typography>
                      </td>
                      <td className="pb-[10px] w-[20%]">
                        <Typography className="table mx-auto" isBold>
                          金額
                        </Typography>
                      </td>
                      <td className="pb-[10px] w-[20%]">
                        <Typography className="table mx-auto" isBold>
                          年間変動率
                        </Typography>
                      </td>
                      <td className="pb-[10px] w-[10px]">
                        <Typography className="table mx-auto"></Typography>
                      </td>
                    </tr>

                    <FieldArray name="input_custom_items_income_attributes">
                      {({ fields }) => {
                        return fields.map((name, index) => {
                          const isIncomeType = _includes(
                            name,
                            'input_custom_items_income_attributes',
                          )

                          const targetFieldName = isIncomeType
                            ? 'input_custom_items_income_attributes'
                            : 'input_custom_items_expense_attributes'

                          const targetAttributes = values[targetFieldName][index]
                          const paymentMode = targetAttributes['payment_mode']

                          const isOnce = paymentMode == PAYMENT_MODE.once
                          const isRepeatedlyPaymentMode =
                            paymentMode == PAYMENT_MODE.repeatedly

                          return (
                            <React.Fragment key={`${index}_income`}>
                              {fields.value[index] && (
                                <>
                                  <tr className="border-b border-black-300">
                                    {/* チェックボックス */}
                                    <td className="align-baseline lg:align-middle">
                                      <Checkbox
                                        name={`${name}is_active`}
                                        label=""
                                        value={true}
                                      />
                                    </td>

                                    {/* 項目名 */}
                                    <td className="flex p-[10px] w-[95%] lg:w-[20%] lg:table-cell mt-[30px] lg:mt-[0px]">
                                      <Typography className="lg:hidden block mx-auto min-w-[75px]">
                                        項目名
                                      </Typography>
                                      <TextField
                                        widthClassName="w-full lg:w-[110px]"
                                        type="text"
                                        name={`${name}display_name`}
                                      />
                                    </td>

                                    {/* 科目 */}
                                    <td className="flex p-[10px] w-[95%] lg:w-[20%] lg:table-cell">
                                      <Typography className="lg:hidden block mx-auto min-w-[75px]">
                                        科目
                                      </Typography>
                                      <SelectBox
                                        widthClassName="w-full lg:w-[110px]"
                                        name={`${name}category`}
                                        options={incomeCategorySelectItems}
                                        validate={required}
                                      />
                                    </td>

                                    {/* 適用頻度 */}
                                    <td className="flex p-[10px] w-[95%] lg:w-[20%] lg:table-cell">
                                      <Typography className="lg:hidden block mx-auto min-w-[75px]">
                                        適用頻度
                                      </Typography>
                                      <div className="lg:flex items-center w-full">
                                        <SelectBox
                                          widthClassName="w-full lg:w-[110px]"
                                          name={`${name}payment_mode`}
                                          options={PAYMENT_MODE_SELECT_ITEMS}
                                          validate={required}
                                        />
                                        <div className="pl-[15px]">
                                          <div className="md:flex items-baseline mt-[16px] lg:mt-0">
                                            <Typography className="min-w-[65px] text-black-800 pb-[10px]">
                                              ご本人が
                                            </Typography>
                                            <div className="flex items-center">
                                              <NumberField
                                                isFullWidth
                                                widthClassName="lg:w-[48px] min-w-[40px]"
                                                name={`${name}payment_start_at_age`}
                                                defaultValue={
                                                  fields.value[index]
                                                    ?.payment_start_at_age
                                                }
                                                validate={
                                                  !isOnce
                                                    ? composeValidators(
                                                        required,
                                                        zeroOrMoreNumber,
                                                      )
                                                    : undefined
                                                }
                                                disableValidationMessage
                                              />
                                              <Typography className="ml-[10px] min-w-[max-content] text-black-800">
                                                {isRepeatedlyPaymentMode
                                                  ? '歳から'
                                                  : '歳のとき'}
                                              </Typography>
                                            </div>
                                          </div>
                                          {/* カスタムバリデーションメッセージ */}
                                          <ValidMessage
                                            meta={{
                                              touched: true,
                                              error:
                                                errors
                                                  .input_custom_items_income_attributes?.[
                                                  index
                                                ]?.['payment_start_at_age'],
                                            }}
                                          />
                                          {isRepeatedlyPaymentMode && (
                                            <>
                                              <div className="flex items-center mt-[10px] justify-center relative md:left-[34px] left-0 ">
                                                <NumberField
                                                  widthClassName="w-full lg:w-[48px]"
                                                  name={`${name}payment_end_at_age`}
                                                  validate={
                                                    !isOnce
                                                      ? composeValidators(
                                                          required,
                                                          zeroOrMoreNumber,
                                                        )
                                                      : undefined
                                                  }
                                                  defaultValue={
                                                    fields.value[index]
                                                      ?.payment_end_at_age
                                                  }
                                                  disableValidationMessage
                                                />
                                                <Typography className="min-w-[max-content] text-black-800 ml-[10px]">
                                                  歳まで
                                                </Typography>
                                              </div>
                                              {/* カスタムバリデーションメッセージ */}
                                              <ValidMessage
                                                meta={{
                                                  touched: true,
                                                  error:
                                                    errors
                                                      .input_custom_items_income_attributes?.[
                                                      index
                                                    ]?.['payment_end_at_age'],
                                                }}
                                              />
                                              <div className="mt-[10px] flex items-center justify-start relative md:left-[66px] left-[0px]">
                                                <NumberField
                                                  name={`${name}occurance_in_years`}
                                                  widthClassName="w-full lg:w-[48px]"
                                                  defaultValue={
                                                    fields.value[index]
                                                      ?.occurance_in_years
                                                  }
                                                />
                                                <Typography className="relative left-[15px] md:left-[0px] w-[75px] lg:w-[85px] text-black-800 ml-[10px]">
                                                  年ごとに適用
                                                </Typography>
                                              </div>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </td>

                                    {/* 金額 */}
                                    <td className="flex p-[10px] w-[95%] lg:w-[20%] lg:table-cell">
                                      <Typography className="lg:hidden block mx-auto min-w-[75px]">
                                        金額
                                      </Typography>
                                      <div className="flex flex-auto items-center">
                                        <NumberField
                                          name={`${name}cost`}
                                          widthClassName="w-full lg:w-[92px]"
                                          defaultValue={fields.value[index]?.cost}
                                        />
                                        <Typography className="ml-[10px] w-[43px] text-black-800 min-w-[fit-content]">
                                          円/年
                                        </Typography>
                                      </div>
                                    </td>

                                    {/* 変動率 */}
                                    <td className="flex p-[10px] w-[95%] lg:w-[20%] lg:table-cell mb-[30px] lg:mb-0">
                                      <Typography className="lg:hidden block mx-auto min-w-[75px]">
                                        変動率
                                      </Typography>
                                      <div className="w-full lg:w-[92px]">
                                        {isRepeatedlyPaymentMode ? (
                                          <div className="flex flex-auto items-center">
                                            <NumberFieldDecimal
                                              widthClassName="w-full lg:w-[48px]"
                                              name={`${name}changed_rate_percent`}
                                              defaultValue={
                                                fields.value[index]?.changed_rate_percent
                                              }
                                            />
                                            <Typography className="ml-[10px] text-black-800">
                                              ％
                                            </Typography>
                                          </div>
                                        ) : (
                                          <Typography className="table lg:mx-auto text-black-800 mr-0 ml-auto">
                                            - ％
                                          </Typography>
                                        )}
                                      </div>
                                    </td>

                                    {/* 閉じるボタン */}
                                    <td className="align-baseline lg:align-middle">
                                      <div
                                        className="cursor-pointer"
                                        onClick={(event) =>
                                          handleOpenPopover(event, `income-${index}`)
                                        }
                                      >
                                        <MoreVertIcon />
                                      </div>
                                      <Popover
                                        open={popoverOpenKey === `income-${index}`}
                                        anchorEl={anchorEl}
                                        onClick={handleClosePopover}
                                        onClose={handleClosePopover}
                                        anchorOrigin={{
                                          vertical: 'bottom',
                                          horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                          vertical: 'top',
                                          horizontal: 'center',
                                        }}
                                      >
                                        <div
                                          style={{
                                            cursor: 'pointer',
                                            padding: '5px',
                                          }}
                                          onClick={() => fields.push(fields.value[index])}
                                        >
                                          <Typography style={{ margin: 0 }}>
                                            複製する
                                          </Typography>
                                        </div>
                                        <div
                                          style={{
                                            cursor: 'pointer',
                                            padding: '5px',
                                          }}
                                          onClick={() => fields.remove(index)}
                                        >
                                          <Typography style={{ margin: 0 }}>
                                            削除する
                                          </Typography>
                                        </div>
                                      </Popover>
                                    </td>
                                  </tr>
                                </>
                              )}
                            </React.Fragment>
                          )
                        })
                      }}
                    </FieldArray>
                  </tbody>
                </table>

                <Button
                  className="w-full h-[53px] border border-dashed border-primary-500 text-[14px] text-primary-500 hover:bg-primary-50"
                  onClick={() => {
                    push('input_custom_items_income_attributes', {})
                  }}
                >
                  <Typography isBold>追加</Typography>
                </Button>
              </Grid>
            </div>

            <div className="mt-30">
              <CircleTitle title="支出" />
              <Grid className="pb-[64px] grid-cols-1 lg:grid-cols-1 gap-x-[40px] gap-y-[16px]">
                <table className="lg:table w-full text-[14px]">
                  <tbody>
                    <tr className="border-b border-black-300 w-full lg:table-row hidden">
                      <td className="w-[15px] pb-[10px]"></td>
                      <td className="pb-[10px] w-[20%]">
                        <Typography className="table mx-auto" isBold>
                          項目名
                        </Typography>
                      </td>
                      <td className="pb-[10px] w-[20%]">
                        <Typography className="table mx-auto" isBold>
                          科目
                        </Typography>
                      </td>
                      <td className="pb-[10px] w-[20%]">
                        <Typography className="table mx-auto" isBold>
                          適用頻度
                        </Typography>
                      </td>
                      <td className="pb-[10px] w-[20%]">
                        <Typography className="table mx-auto" isBold>
                          金額
                        </Typography>
                      </td>
                      <td className="pb-[10px] w-[20%]">
                        <Typography className="table mx-auto" isBold>
                          年間変動率
                        </Typography>
                      </td>
                      <td className="pb-[10px] w-[10px]">
                        <Typography className="table mx-auto"></Typography>
                      </td>
                    </tr>

                    <FieldArray name="input_custom_items_expense_attributes">
                      {({ fields }) => {
                        return fields.map((name, index) => {
                          const isIncomeType = _includes(
                            name,
                            'input_custom_items_income_attributes',
                          )

                          const targetFieldName = isIncomeType
                            ? 'input_custom_items_income_attributes'
                            : 'input_custom_items_expense_attributes'

                          const targetAttributes = values[targetFieldName][index]
                          const paymentMode = targetAttributes['payment_mode']

                          const isOnce = paymentMode == PAYMENT_MODE.once
                          const isRepeatedlyPaymentMode =
                            paymentMode == PAYMENT_MODE.repeatedly

                          return (
                            <React.Fragment key={`${index}_expense`}>
                              {fields.value[index] && (
                                <>
                                  <tr
                                    className="border-b border-black-300"
                                    key={`${index}_expense_2`}
                                  >
                                    {/* チェックボックス */}
                                    <td className="align-baseline lg:align-middle">
                                      <Checkbox
                                        name={`${name}is_active`}
                                        label=""
                                        value={true}
                                      />
                                    </td>

                                    {/* 項目名 */}
                                    <td className="flex p-[10px] w-[95%] lg:w-[20%] lg:table-cell mt-[30px] lg:mt-[0px]">
                                      <Typography className="lg:hidden block mx-auto min-w-[75px]">
                                        項目名
                                      </Typography>
                                      <TextField
                                        widthClassName="w-full lg:w-[110px]"
                                        type="text"
                                        name={`${name}display_name`}
                                      />
                                    </td>

                                    {/* 科目 */}
                                    <td className="flex p-[10px] w-[95%] lg:w-[20%] lg:table-cell">
                                      <Typography className="lg:hidden block mx-auto min-w-[75px]">
                                        科目
                                      </Typography>
                                      <SelectBox
                                        widthClassName="w-full lg:w-[110px]"
                                        name={`${name}category`}
                                        options={expenseCategorySelectItems}
                                        validate={required}
                                      />
                                    </td>

                                    {/* 適用頻度 */}
                                    <td className="flex p-[10px] w-[95%] lg:w-[20%] lg:table-cell">
                                      <Typography className="lg:hidden block mx-auto min-w-[75px]">
                                        適用頻度
                                      </Typography>
                                      <div className="lg:flex items-center w-full">
                                        <SelectBox
                                          widthClassName="w-full lg:w-[110px]"
                                          name={`${name}payment_mode`}
                                          options={PAYMENT_MODE_SELECT_ITEMS}
                                          validate={required}
                                        />
                                        <div className="pl-[15px]">
                                          <div className="md:flex items-baseline mt-[16px] lg:mt-0">
                                            <Typography className="min-w-[65px] text-black-800 pb-[10px]">
                                              ご本人が
                                            </Typography>
                                            <div className="flex items-center">
                                              <NumberField
                                                isFullWidth
                                                widthClassName="lg:w-[48px] min-w-[40px]"
                                                name={`${name}payment_start_at_age`}
                                                validate={
                                                  !isOnce
                                                    ? composeValidators(
                                                        required,
                                                        zeroOrMoreNumber,
                                                      )
                                                    : undefined
                                                }
                                                defaultValue={
                                                  fields.value[index]
                                                    ?.payment_start_at_age
                                                }
                                                disableValidationMessage
                                              />
                                              <Typography className="ml-[10px] min-w-[max-content] text-black-800">
                                                {isRepeatedlyPaymentMode
                                                  ? '歳から'
                                                  : '歳のとき'}
                                              </Typography>
                                            </div>
                                          </div>
                                          {/* カスタムバリデーションメッセージ */}
                                          <ValidMessage
                                            meta={{
                                              touched: true,
                                              error:
                                                errors
                                                  .input_custom_items_expense_attributes?.[
                                                  index
                                                ]?.['payment_start_at_age'],
                                            }}
                                          />
                                          {isRepeatedlyPaymentMode && (
                                            <>
                                              <div className="flex items-center mt-[10px] justify-center relative md:left-[34px] left-0 ">
                                                <NumberField
                                                  widthClassName="w-full lg:w-[48px]"
                                                  name={`${name}payment_end_at_age`}
                                                  validate={
                                                    !isOnce
                                                      ? composeValidators(
                                                          required,
                                                          zeroOrMoreNumber,
                                                        )
                                                      : undefined
                                                  }
                                                  defaultValue={
                                                    fields.value[index]
                                                      ?.payment_end_at_age
                                                  }
                                                  disableValidationMessage
                                                />
                                                <Typography className="min-w-[max-content] text-black-800 ml-[10px]">
                                                  歳まで
                                                </Typography>
                                              </div>
                                              {/* カスタムバリデーションメッセージ */}
                                              <ValidMessage
                                                meta={{
                                                  touched: true,
                                                  error:
                                                    errors
                                                      .input_custom_items_expense_attributes?.[
                                                      index
                                                    ]?.['payment_end_at_age'],
                                                }}
                                              />

                                              <div className="mt-[10px] flex items-center justify-start relative md:left-[66px] left-[0px]">
                                                <NumberField
                                                  name={`${name}occurance_in_years`}
                                                  widthClassName="w-full lg:w-[48px]"
                                                  defaultValue={
                                                    fields.value[index]
                                                      ?.occurance_in_years
                                                  }
                                                />
                                                <Typography className="relative left-[15px] md:left-[0px] w-[75px] lg:w-[85px] text-black-800 ml-[10px]">
                                                  年ごとに適用
                                                </Typography>
                                              </div>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </td>

                                    {/* 金額 */}
                                    <td className="flex p-[10px] w-[95%] lg:w-[20%] lg:table-cell">
                                      <Typography className="lg:hidden block mx-auto min-w-[75px]">
                                        金額
                                      </Typography>
                                      <div className="flex flex-auto items-center">
                                        <NumberField
                                          isFullWidth
                                          name={`${name}cost`}
                                          widthClassName="lg:w-[92px]"
                                          defaultValue={fields.value[index]?.cost}
                                        />
                                        <Typography className="ml-[10px] w-[43px] text-black-800 min-w-[fit-content]">
                                          円/年
                                        </Typography>
                                      </div>
                                    </td>

                                    {/* 変動率 */}
                                    <td className="flex p-[10px] w-[95%] lg:w-[20%] lg:table-cell mb-[30px] lg:mb-0">
                                      <Typography className="lg:hidden block mx-auto min-w-[75px]">
                                        変動率
                                      </Typography>
                                      <div className="w-full lg:w-[92px]">
                                        {isRepeatedlyPaymentMode ? (
                                          <div className="flex flex-auto items-center">
                                            <NumberFieldDecimal
                                              widthClassName="w-full lg:w-[48px]"
                                              name={`${name}changed_rate_percent`}
                                              defaultValue={
                                                fields.value[index]?.changed_rate_percent
                                              }
                                            />
                                            <Typography className="ml-[10px] text-black-800">
                                              ％
                                            </Typography>
                                          </div>
                                        ) : (
                                          <Typography className="table lg:mx-auto text-black-800 mr-0 ml-auto">
                                            - ％
                                          </Typography>
                                        )}
                                      </div>
                                    </td>

                                    {/* 閉じるボタン */}
                                    <td className="align-baseline lg:align-middle">
                                      <div
                                        className="cursor-pointer"
                                        onClick={(event) =>
                                          handleOpenPopover(event, `spending-${index}`)
                                        }
                                      >
                                        <MoreVertIcon />
                                      </div>
                                      <Popover
                                        open={popoverOpenKey === `spending-${index}`}
                                        anchorEl={anchorEl}
                                        onClick={handleClosePopover}
                                        onClose={handleClosePopover}
                                        anchorOrigin={{
                                          vertical: 'bottom',
                                          horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                          vertical: 'top',
                                          horizontal: 'center',
                                        }}
                                      >
                                        <div
                                          style={{
                                            cursor: 'pointer',
                                            padding: '5px',
                                          }}
                                          onClick={() => fields.push(fields.value[index])}
                                        >
                                          <Typography style={{ margin: 0 }}>
                                            複製する
                                          </Typography>
                                        </div>
                                        <div
                                          style={{
                                            cursor: 'pointer',
                                            padding: '5px',
                                          }}
                                          onClick={() => fields.remove(index)}
                                        >
                                          <Typography style={{ margin: 0 }}>
                                            削除する
                                          </Typography>
                                        </div>
                                      </Popover>
                                    </td>
                                  </tr>
                                </>
                              )}
                            </React.Fragment>
                          )
                        })
                      }}
                    </FieldArray>
                  </tbody>
                </table>

                <Button
                  className="w-full h-[53px] border border-dashed border-primary-500 text-[14px] text-primary-500 hover:bg-primary-50"
                  onClick={() => {
                    push('input_custom_items_expense_attributes', {})
                  }}
                >
                  <Typography isBold>追加</Typography>
                </Button>
              </Grid>
            </div>

            <Typography className="pb-[30px] md:pb-[75px] text-center">
              ライフプランに適用する項目に
              <br className="md:hidden" />
              チェックをつけてください
              <br />
              チェックがない項目は、
              <br className="md:hidden" />
              ライフプランへは反映されません
            </Typography>

            <table className="w-full mb-[50px] md:mb-[100px] text-[12px] text-black-700">
              <tbody>
                <tr className="font-bold text-[14px] even:bg-black-100">
                  <td className="w-[46%] md:w-[30%] pl-[10px] pb-[10px]">
                    <Typography>科目名</Typography>
                  </td>
                  <td className="w-[54%] md:w-[70%] pl-[10px] pb-[10px]">
                    <Typography>科目説明</Typography>
                  </td>
                </tr>

                <tr className="even:bg-black-100">
                  <td className="pl-[10px] md:pl-[30px] pr-[10px] py-[15px] rounded-l-[8px]">
                    <Typography>
                      ご本人額面給与
                      <br />
                      配偶者額面給与
                    </Typography>
                  </td>
                  <td className="pl-[10px] pr-[10px] md:pr-[30px] py-[15px] rounded-r-[8px]">
                    「ご本人（配偶者）のお仕事」や「収入の変化」で設定されている給与が増減します。
                    <br className="hidden lg:block" />
                    入力いただいた数値は、収入と同じように、社会保険料などの税金等の計算がされます。
                    <br className="hidden lg:block" />
                    ただし、退職金控除計算用の勤続年数には考慮されません。
                  </td>
                </tr>

                <tr className="even:bg-black-100">
                  <td className="pl-[10px] md:pl-[30px] pr-[10px] py-[15px] rounded-l-[8px]">
                    <Typography>
                      ご本人退職金
                      <br />
                      配偶者退職金
                    </Typography>
                  </td>
                  <td className="pl-[10px] pr-[10px] md:pr-[30px] py-[15px] rounded-r-[8px]">
                    税引き前退職金額を入力することで「ご本人（配偶者）のお仕事」や「収入の変化」から算出した勤続年数を元に、退職金控除を計算した結果が反映されます。
                  </td>
                </tr>

                <tr className="even:bg-black-100">
                  <td className="pl-[10px] md:pl-[30px] pr-[10px] py-[15px] rounded-l-[8px]">
                    <Typography>
                      ご本人のその他収入
                      <br />
                      配偶者のその他収入
                      <br />
                      その他の収入
                      <br />
                      その他の支出
                    </Typography>
                  </td>
                  <td className="pl-[10px] pr-[10px] md:pr-[30px] py-[15px] rounded-r-[8px]">
                    入力された数値によって、ご本人（配偶者）の収入・支出が増減します。
                    <br className="hidden lg:block" />
                    税金計算はされません。
                  </td>
                </tr>

                <tr className="even:bg-black-100">
                  <td className="pl-[10px] md:pl-[30px] pr-[10px] py-[15px] rounded-l-[8px]">
                    <Typography>
                      不動産投資 収入
                      <br />
                      不動産投資 その他費用
                    </Typography>
                  </td>
                  <td className="pl-[10px] pr-[10px] md:pr-[30px] py-[15px] rounded-r-[8px]">
                    入力された数値によって、「不動産投資」の収入・支出の数値が増減します。
                    <br className="hidden lg:block" />
                    また、所得税などの計算時に確定申告書の『ウ：不動産』に該当する所得として、税金計算の対象になります。
                  </td>
                </tr>

                <tr className="even:bg-black-100">
                  <td className="pl-[10px] md:pl-[30px] pr-[10px] py-[15px] rounded-l-[8px]">
                    <Typography>
                      ご本人副業収入
                      <br />
                      配偶者副業収入
                      <br />
                      ご本人副業経費
                      <br />
                      配偶者副業経費
                    </Typography>
                  </td>
                  <td className="pl-[10px] pr-[10px] md:pr-[30px] py-[15px] rounded-r-[8px]">
                    「副業収支」の収入・経費それぞれの項目が増減します。
                    <br className="hidden lg:block" />
                    また、所得税などの計算時に確定申告書の『ア：事業
                    営業等』に含まれるものとして税金計算の対象になります。
                  </td>
                </tr>
              </tbody>
            </table>

            <WholeSubmitErrorMessage submitErrors={submitErrors} />
            <SubmitErrorMessage show={!submitErrors && submitFailed} />

            <ButtonPrimary
              className={clsx(
                { ['opacity-50']: Object.keys(errors).length > 0 },
                'w-[246px] h-[64px] mx-auto mb-[16px] ',
              )}
              isDisabled={isLoading}
              onClick={() => handleSubmit(values)}
            >
              <Typography
                className="relative bottom-[2px] pl-[30px] text-[16px] text-white flex-auto"
                isBold
              >
                保存する
              </Typography>
              <ChevronRightIcon className="mr-[17px]" />
            </ButtonPrimary>
            <Button
              className="mx-auto text-[14px] text-black-700"
              onClick={props.onClose}
              isDisabled={isLoading}
            >
              キャンセル
            </Button>
          </form>
        )}
      />
    </>
  )
}

export default MyDataCommonItemForm
