import ApiClient from '@/api/apiClient'

export const postFutureYearlyLivingCostV2 = async (values: any) => {
  return await ApiClient.post(
    '/future_yearly_living_cost_bases',
    () => {},
    values,
    undefined,
    'v2',
  )
}

export const patchFutureYearlyLivingCostV2 = async (values: any, id: number) => {
  return await ApiClient.patch(
    `/future_yearly_living_cost_bases/${id}`,
    () => {},
    values,
    undefined,
    'v2',
  )
}

export const deleteFutureYearlyLivingCostV2 = async (id: number) => {
  return await ApiClient.delete(
    `/future_yearly_living_cost_bases/${id}`,
    () => {},
    undefined,
    undefined,
    'v2',
  )
}
