import { filter as _filter, find as _find, isUndefined as _isUndefined } from 'lodash'
import {
  AccountType,
  AssetType,
  InputAsset,
  RolloverType,
} from '@/models/inputs/inputAssetsModel'
import { PERSON_FLG } from '@/models/familyStructuresModelFunc'
import { convertLocaleString, isNotEmptyValue } from '@/models/commonsModelFunc'

// 金融資産種別
export const INPUT_ASSET_TYPE = {
  bank: 'bank',
  trustFund: 'trust_fund',
  stock: 'stock',
  bond: 'bond',
  deductedSavings: 'deducted_savings',
  other: 'other',
}

// 口座種類
export const ACCOUNT_TYPE = {
  general: 'general',
  tsumitateNisa: 'tsumitate_nisa',
  nisa: 'nisa',
}

// ロールオーバー予定
export const ROLLOVER_TYPE = {
  planed: 'planed',
  notPlaned: 'not_planed',
}

export const convertInputAssetType = (assetType: AssetType | null, isShort?: boolean) => {
  switch (assetType) {
    case INPUT_ASSET_TYPE.bank:
      return isShort ? '流動性資産' : '流動性資産（銀行預金（定期含む）、MRF、MMFなど）'
    case INPUT_ASSET_TYPE.deductedSavings:
      return '社内預金、財形、持株など'
    case INPUT_ASSET_TYPE.trustFund:
      return '投資信託'
    case INPUT_ASSET_TYPE.stock:
      return '株式'
    case INPUT_ASSET_TYPE.bond:
      return '債券'
    case INPUT_ASSET_TYPE.other:
      return 'その他'
    default:
      return ''
  }
}

export const convertInputAssetPersonFlg = (
  personFlg: number | null,
  childIndex: number | undefined,
) => {
  if (!_isUndefined(childIndex)) return `第${childIndex}子`
  if (personFlg === PERSON_FLG.person) return 'ご本人'
  if (personFlg === PERSON_FLG.spouse) return '配偶者'

  return ''
}

export const convertAccountType = (accountType: AccountType | null) => {
  switch (accountType) {
    case ACCOUNT_TYPE.general:
      return '特定口座・一般口座'
    case ACCOUNT_TYPE.tsumitateNisa:
      return 'つみたてNISA枠'
    case ACCOUNT_TYPE.nisa:
      return '成長投資枠'
    default:
      return 'ー'
  }
}

export const convertRolloverType = (rolloverType: RolloverType | null) => {
  switch (rolloverType) {
    case ROLLOVER_TYPE.planed:
      return 'する予定'
    case ROLLOVER_TYPE.notPlaned:
      return 'しない'
    default:
      return ''
  }
}

export const convertReservedAmountMonthly = (reservedAmountMonthly: number | null) => {
  if (isNotEmptyValue(reservedAmountMonthly) && Number(reservedAmountMonthly) > 0) {
    return `${convertLocaleString(reservedAmountMonthly)}円/月`
  }

  return '設定なし'
}

export const filterInputAsset = (assetType: AssetType, inputAssetArr: InputAsset[]) => {
  return _filter(inputAssetArr, { asset_type: assetType })
}

export const hasSummaryOf = (assetType: AssetType, inputAssetArr: InputAsset[]) => {
  return !!_find(filterInputAsset(assetType, inputAssetArr), { asset_type: assetType })
}

export const isNisaAccount = (accountType: AccountType | null) => {
  return accountType == ACCOUNT_TYPE.tsumitateNisa || accountType == ACCOUNT_TYPE.nisa
}

export const isInvestmentAssetType = (assetType: AssetType | null) => {
  return assetType == INPUT_ASSET_TYPE.stock || assetType == INPUT_ASSET_TYPE.trustFund
}
