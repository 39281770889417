import {
  castNumberWithoutOperator,
  combineTwoTypesYear,
  convertLocaleString,
  convertManyenToYen,
  defaultToEmptyString,
} from '@/models/commonsModelFunc'

// 上昇率の方式
export const REPAIR_RESERVE_FUND_INCREASE_TYPE = {
  change: 0,
  fixed: 1,
  noSet: 2,
}

export const RENEWAL_FEE_INPUT_TYPE = {
  yen: 0,
  month: 1,
}

export const TAX_PAPER_PRESENCE_TYPE = {
  yes: 'yes',
  no: 'no',
}

export const calcEstimatedPropertyTax = (propertyPriceManyen: number | null) => {
  return Math.round(convertManyenToYen(propertyPriceManyen) * 0.003)
}

export const convertMonthlyPeriodCost = (period: number | null, cost: number | null) => {
  return `${defaultToEmptyString(period)}ヶ月ごとに ${convertLocaleString(cost)}円`
}

export const convertMonthlyPeriodCostWithRent = (
  period: number | null,
  rent: number | null,
  month: number | null,
) => {
  const cost = castNumberWithoutOperator(rent) * castNumberWithoutOperator(month)
  return `${defaultToEmptyString(period)}ヶ月ごとに ${convertLocaleString(cost)}円`
}

export const convertDepreciation = (
  durableYear: number | null,
  depreciation: number | null,
  constructedYear: number | null,
) => {
  const durableEndYear = combineTwoTypesYear(
    Number(constructedYear) + Number(durableYear),
  )
  return `${durableEndYear}まで ${convertLocaleString(depreciation)}円`
}
