import React, { FC, useState, useContext, useMemo } from 'react'
import { isNull as _isNull } from 'lodash'
import Button from '@/components/v2/atoms/Button'
import Tooltip from '@/components/v1/atoms/TooltipMui'
import Typography from '@/components/v2/atoms/Typography'
import {
  convertLocaleString,
  convertDateToTwoTypesYearsDateFormat,
} from '@/models/commonsModelFunc'
import {
  isMansionType,
  convertOwnedHouseType,
  convertHousingType2,
  HOUSE_TYPE,
} from '@/models/commonHousingModelFunc'
import { SimulationDetailEditContext } from '@/models/futures/futureBasesModelFunc'
import { REPAIR_RESERVE_FUND_RATE_TYPE } from '@/models/futures/futureV2HousingsModelFunc'
import { FutureV2Housing } from '@/models/futures/futureV2HousingsModel'
import FutureV2HousingLoanBody from '@/templates/v2/simulations/futureV2Housings/FutureV2HousingLoanBody'
import FutureV2HousingUpdateModalForm from '@/templates/v2/simulations/futureV2Housings/FutureV2HousingModalForm/updateIndex'

interface FutureV2HousingSelfOwnedBodyProps {
  /** 年齢 */
  age: number
  /** true: 配偶者あり, false: 配偶者なし */
  hasSpouse: boolean
  /** futureV2Housing初期値 */
  futureV2Housing: FutureV2Housing
  /** 共通API呼び出しメソッド */
  commonCallApi: () => void
}

interface FutureV2HousingSelfOwnedBasicInfoTableProps {
  /** アイテム */
  housingSelfOwned: any
  /** td要素のクラス */
  tdClassName?: string
}

export const FutureV2HousingSelfOwnedBasicInfoTable: FC<FutureV2HousingSelfOwnedBasicInfoTableProps> = ({
  housingSelfOwned,
  tdClassName,
}) => {
  return (
    <>
      <table className="w-[100%] mb-[20px] last:mb-0 text-[12px]">
        <tbody>
          <tr className="h-[45px] odd:bg-black-100">
            <td className={`${tdClassName ?? 'w-[50%]'} px-[16px]`}>
              <Typography isBold>家タイプ</Typography>
            </td>
            <td className="px-[16px]">
              <Typography className="">
                {convertOwnedHouseType(housingSelfOwned?.house_type)}
              </Typography>
            </td>
          </tr>

          <tr className="h-[45px] odd:bg-black-100">
            <td className="px-[16px]">
              <Typography isBold>
                購入時の
                <br />
                家の状態
              </Typography>
            </td>
            <td className="px-[16px]">
              <Typography className="">
                {convertHousingType2(
                  housingSelfOwned?.housing_type2,
                  isMansionType(housingSelfOwned?.house_type),
                )}
              </Typography>
            </td>
          </tr>

          <tr className="h-[45px] odd:bg-black-100">
            <td className="px-[16px]">
              <Typography isBold>築年</Typography>
            </td>
            <td className="px-[16px]">
              <Typography className="">
                {convertDateToTwoTypesYearsDateFormat(
                  housingSelfOwned?.constructed_at,
                  'japaneseYear',
                )}
              </Typography>
            </td>
          </tr>

          <tr className="h-[45px] odd:bg-black-100">
            <td className="px-[16px]">
              <Typography isBold>居住開始年</Typography>
            </td>
            <td className="px-[16px]">
              <Typography className="">
                {convertDateToTwoTypesYearsDateFormat(
                  housingSelfOwned?.purchased_at,
                  'japaneseYear',
                )}
              </Typography>
            </td>
          </tr>

          {!isMansionType(housingSelfOwned?.house_type) ? (
            <>
              <tr className="h-[45px] odd:bg-black-100">
                <td className="px-[16px]">
                  <Typography isBold>
                    購入時の
                    <br />
                    土地価格
                  </Typography>
                </td>
                <td className="px-[16px]">
                  <Typography className="">
                    {`${convertLocaleString(
                      housingSelfOwned?.property_price_land_manyen,
                    )}万円`}
                  </Typography>
                </td>
              </tr>

              <tr className="h-[45px] odd:bg-black-100">
                <td className="px-[16px]">
                  <Typography isBold>
                    購入時の
                    <br />
                    建物価格
                  </Typography>
                </td>
                <td className="px-[16px]">
                  <Typography className="">
                    {`${convertLocaleString(
                      housingSelfOwned?.property_price_building_manyen,
                    )}万円`}
                  </Typography>
                </td>
              </tr>
            </>
          ) : (
            <tr className="h-[45px] odd:bg-black-100">
              <td className="px-[16px]">
                <Typography isBold>購入価格</Typography>
              </td>
              <td className="px-[16px]">
                <Typography className="">
                  {`${convertLocaleString(housingSelfOwned?.property_price_manyen)}万円`}
                </Typography>
              </td>
            </tr>
          )}
          <tr className="h-[45px] odd:bg-black-100">
            <td className="px-[16px]">
              <Typography isBold>
                購入価格以外の
                <br />
                初期費用
              </Typography>
            </td>
            <td className="px-[16px]">
              <Typography className="">
                {`${convertLocaleString(
                  housingSelfOwned?.miscellaneous_cost_manyen,
                )}万円`}
              </Typography>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  )
}
interface FutureV2HousingSelfOwnedCostTableProps {
  /** アイテム */
  housingSelfOwned: any
  /** td要素のクラス */
  tdClassName?: string
}

export const FutureV2HousingSelfOwnedCostTable: FC<FutureV2HousingSelfOwnedCostTableProps> = ({
  housingSelfOwned,
  tdClassName,
}) => {
  return (
    <>
      <table className="w-[100%] mb-[20px] last:mb-0 text-[12px]">
        <tbody>
          <tr className="h-[45px] odd:bg-black-100">
            <td className={`${tdClassName ?? 'w-[50%]'} px-[16px]`}>
              <Typography isBold>
                固定資産税・
                <br />
                都市計画税
              </Typography>
            </td>
            <td className="px-[16px]">
              <Typography className="">
                {`年間 ${convertLocaleString(housingSelfOwned?.tax_yearly)}円`}
              </Typography>
            </td>
          </tr>

          <tr className="h-[45px] odd:bg-black-100">
            <td className="px-[16px]">
              <Typography isBold>リフォームの頻度</Typography>
            </td>
            <td className="px-[16px]">
              <Typography className="">
                {`${convertLocaleString(housingSelfOwned?.repair_costs_span)}年に1回`}
              </Typography>
            </td>
          </tr>

          <tr className="h-[45px] odd:bg-black-100">
            <td className="px-[16px]">
              <Typography isBold>リフォーム費</Typography>
            </td>
            <td className="px-[16px]">
              <Typography className="">
                {`1回あたり${convertLocaleString(housingSelfOwned?.repair_costs)}円`}
              </Typography>
            </td>
          </tr>

          {isMansionType(housingSelfOwned?.house_type) && (
            <>
              {!_isNull(
                housingSelfOwned!.management_fee_monthly_and_repair_reserve_fund_total,
              ) ? (
                <tr className="h-[45px] odd:bg-black-100">
                  <td className="px-[16px]">
                    <Typography isBold>管理費・修繕積立金合計</Typography>
                  </td>
                  <td className="px-[16px]">
                    <Typography className="">
                      {`${convertLocaleString(
                        housingSelfOwned!
                          .management_fee_monthly_and_repair_reserve_fund_total,
                      )}円/月`}
                    </Typography>
                  </td>
                </tr>
              ) : (
                <>
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="px-[16px]">
                      <Typography isBold>管理費</Typography>
                    </td>
                    <td className="px-[16px]">
                      <Typography className="">
                        {_isNull(housingSelfOwned?.management_fee_monthly)
                          ? `未入力`
                          : `${convertLocaleString(
                              housingSelfOwned?.management_fee_monthly,
                            )}円/月`}
                      </Typography>
                    </td>
                  </tr>

                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="px-[16px]">
                      <Typography isBold>修繕積立金</Typography>
                    </td>
                    <td className="px-[16px]">
                      <Typography className="">
                        {_isNull(housingSelfOwned?.repair_reserve_fund)
                          ? `未入力`
                          : `${convertLocaleString(
                              housingSelfOwned?.repair_reserve_fund,
                            )}円/月`}
                      </Typography>
                    </td>
                  </tr>

                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="px-[16px]">
                      <Typography isBold>積立金の上昇率</Typography>
                    </td>
                    <td className="px-[16px]">
                      {_isNull(housingSelfOwned?.repair_reserve_fund_increase_type) && (
                        <Typography className="">未入力</Typography>
                      )}
                      {housingSelfOwned?.repair_reserve_fund_increase_type ===
                        REPAIR_RESERVE_FUND_RATE_TYPE.noSet && (
                        <Typography className="">設定なし</Typography>
                      )}
                      {housingSelfOwned?.repair_reserve_fund_increase_type ===
                        REPAIR_RESERVE_FUND_RATE_TYPE.fixed && (
                        <>
                          <Typography className="">
                            {`月間 ${convertLocaleString(
                              housingSelfOwned?.repair_reserve_fund_amount,
                            )}円ずつ`}
                          </Typography>
                          <Typography className="">
                            {`築${
                              housingSelfOwned?.repair_reserve_fund_amount_start
                            }年目から 築${convertLocaleString(
                              housingSelfOwned?.repair_reserve_fund_amount_finish,
                            )}年目まで上昇させる`}
                          </Typography>
                        </>
                      )}
                      {housingSelfOwned?.repair_reserve_fund_increase_type ===
                        REPAIR_RESERVE_FUND_RATE_TYPE.change && (
                        <>
                          <Typography className="">
                            {`築${housingSelfOwned?.repair_reserve_fund_increase_start}年目から
                          ${housingSelfOwned?.repair_reserve_fund_increase_span}年毎に`}
                            <br />
                            {`築${
                              housingSelfOwned?.repair_reserve_fund_increase_finish
                            }年目まで ${convertLocaleString(
                              housingSelfOwned?.repair_reserve_fund_increase_percent,
                            )}％ずつ上昇`}
                          </Typography>
                        </>
                      )}
                    </td>
                  </tr>
                </>
              )}
            </>
          )}

          <tr className="h-[45px] odd:bg-black-100">
            <td className="px-[16px]">
              <Typography isBold>その他費用</Typography>
            </td>
            <td className="px-[16px]">
              <Typography className="">{`年間 ${convertLocaleString(
                housingSelfOwned?.other_cost_yearly,
              )}円`}</Typography>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

const FutureV2HousingSelfOwnedBody: FC<FutureV2HousingSelfOwnedBodyProps> = (props) => {
  const { isManipulatedApproval } = useContext(SimulationDetailEditContext)

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [modalNumber, setModalNumber] = useState<number>(1)

  const { age, hasSpouse, futureV2Housing, commonCallApi } = props

  const housingSelfOwned = useMemo(() => futureV2Housing?.future_v2_housing_self_owned, [
    futureV2Housing,
  ])

  const handleModalOpen = (modalNumber: number) => {
    setIsModalOpen(true)
    setModalNumber(modalNumber)
  }
  const handleModalClose = () => {
    setIsModalOpen(false)
    setModalNumber(1)
  }

  return (
    <div onClick={(event: any) => isModalOpen && event.stopPropagation()}>
      <div className="flex justify-between">
        <Typography isBold className="mb-[10px]">
          基本情報
        </Typography>
        <Tooltip title="住み替え先を変更する場合は、一度削除して作り直してください" arrow>
          <span>
            <Button
              className=""
              isDisabled={!isManipulatedApproval}
              onClick={(event: any) => {
                event.stopPropagation()
                handleModalOpen(1)
              }}
            >
              <i className="ico edit text-[14px] text-black-700" />
              <Typography className="pl-[5px] text-[14px] text-black-700">
                編集
              </Typography>
            </Button>
          </span>
        </Tooltip>
      </div>
      <FutureV2HousingSelfOwnedBasicInfoTable housingSelfOwned={housingSelfOwned} />

      <div className="flex justify-between">
        <Typography isBold className="mb-[10px]">
          支出情報
        </Typography>
        <Tooltip title="住み替え先を変更する場合は、一度削除して作り直してください" arrow>
          <span>
            <Button
              className=""
              onClick={(event: any) => {
                event.stopPropagation()
                handleModalOpen(2)
              }}
            >
              <i className="ico edit text-[14px] text-black-700" />
              <Typography className="pl-[5px] text-[14px] text-black-700">
                編集
              </Typography>
            </Button>
          </span>
        </Tooltip>
      </div>
      <FutureV2HousingSelfOwnedCostTable housingSelfOwned={housingSelfOwned} />

      <FutureV2HousingLoanBody
        housingSelfOwned={housingSelfOwned!}
        handleModalOpen={handleModalOpen}
      />

      <FutureV2HousingUpdateModalForm
        age={age}
        isOpen={isModalOpen}
        hasSpouse={hasSpouse}
        futureV2Housing={futureV2Housing!}
        houseType={HOUSE_TYPE.own}
        modalNumber={modalNumber}
        commonCallApi={commonCallApi}
        handleClose={handleModalClose}
      />
    </div>
  )
}

export default FutureV2HousingSelfOwnedBody
