import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { unauthorizedCheck } from '../accountsSlice'
import { ApiError } from '@/models/commonsModel'
import { AppThunk } from '@/store'
import { sortBy as _sortBy } from 'lodash'
import { getInputEstateInvestmentTaxSetting, patchInputEstateInvestmentTaxSetting } from '@/api/inputs/inputEstateInvestmentTaxSetting'
import { InputEstateInvestmentTaxSetting } from '@/models/inputs/inputEstateInvestmentTaxSettingsModel'

interface InputEstateInvestmentTaxSettingState {
  inputEstateInvestmentTaxSetting: InputEstateInvestmentTaxSetting | {}
  isLoading: boolean
}

const initialState: InputEstateInvestmentTaxSettingState = {
  inputEstateInvestmentTaxSetting: {},
  isLoading: false,
}

const inputEstateInvestmentTaxSettingSlice = createSlice({
  name: 'inputEstateInvestmentTaxSetting',
  initialState,
  reducers: {
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
    setInputEstateInvestmentTaxSetting: (state, action: PayloadAction<InputEstateInvestmentTaxSetting>) => {
      state.inputEstateInvestmentTaxSetting = action.payload
      return state
    },
    getInputEstateInvestmentTaxSettingSuccess: (state, action: PayloadAction<InputEstateInvestmentTaxSetting>) => {
      state.inputEstateInvestmentTaxSetting = action.payload
      state.isLoading = false
      return state
    },
  },
})

export const {
  requestStart,
  requestSuccess,
  requestFailure,
  setInputEstateInvestmentTaxSetting,
  getInputEstateInvestmentTaxSettingSuccess,
} = inputEstateInvestmentTaxSettingSlice.actions
export const inputEstateInvestmentTaxSettingReducer = inputEstateInvestmentTaxSettingSlice.reducer

export const fetchInputEstateInvestmentTaxSetting = (callback?: (inputEstateInvestmentTaxSetting: any) => {}): AppThunk => async (
  dispatch,
) => {
  dispatch(requestStart())
  getInputEstateInvestmentTaxSetting()
    .then((response) => {
      dispatch(getInputEstateInvestmentTaxSettingSuccess(response))
      callback && callback(response)
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const updateInputEstateInvestmentTaxSetting = (values: string, callback?: (inputEstateInvestmentTaxSetting: any) => void): AppThunk => async (
  dispatch,
) => {
  dispatch(requestStart())
  patchInputEstateInvestmentTaxSetting(values)
    .then((response) => {
      callback && callback(response)
      dispatch(requestSuccess())
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}


