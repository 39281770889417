import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty as _isEmpty } from 'lodash'
import { parse } from 'query-string'
import ApiClient from '@/api/apiClient'
import MarginContainer from '@/components/v2/atoms/MarginContainer'
import Paper from '@/components/v2/atoms/Paper'
import AccountPasswordForm from '@/templates/v2/accounts/password/commons/AccountPasswordForm'
import { removeAllFlashAlerts, setFlashAlert } from '@/containers/flashAlertsSlice'
import { updateAccountPasswordSetup } from '@/containers/accountsSlice'
import { setPageMeta } from '@/containers/pageMetasSlice'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import LogindRedirect from '@/layouts/v2/LogindRedirect'
import { RootState } from '@/store'

const AccountPasswordReset: FC = () => {
  const dispatch = useDispatch()
  const { handleMovePage } = useCustomHistory()

  const { flashAlertMessageObjects } = useSelector(
    (state: RootState) => state.flashAlerts,
  )

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: '会員パスワード再設定',
        description: '会員パスワード再設定',
        keyword: '会員パスワード再設定',
      }),
    )

    const { token, ma } = parse(location.search)

    ApiClient.setAuthHeaders({
      Authorization: `Token token=${token}`,
      MailAddress: ma as string,
    })
  }, [])

  const showFlashMsg = () => {
    if (!_isEmpty(flashAlertMessageObjects)) {
      dispatch(removeAllFlashAlerts())
    }

    dispatch(
      setFlashAlert({
        flashAlertMessageObjects: [
          { message: 'パスワードを更新しました', variant: 'success' },
        ],
      }),
    )
  }

  const handleSubmit = (values: any) => {
    return new Promise((resolve) =>
      dispatch(
        updateAccountPasswordSetup(
          values,
          () => {
            handleMovePage('/v2/accounts/password/resetCompleted')
            showFlashMsg()
          },
          resolve,
        ),
      ),
    )
  }

  return (
    <LogindRedirect>
      <MarginContainer className="w-full sm:w-524 mx-auto pt-40 sm:pt-73">
        <Paper className="mx-8 sm:mx-0 px-16 py-24 sm:p-40 bg-white">
          <AccountPasswordForm
            title="パスワード再発行"
            description={<>新しいパスワードを入力してください。</>}
            isEdit
            handleSubmit={handleSubmit}
          />
        </Paper>
      </MarginContainer>
    </LogindRedirect>
  )
}

export default AccountPasswordReset
