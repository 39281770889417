import ApiClient from '../apiClient'
import {
  InputPlanTuitionCreateValues,
  InputPlanTuitionUpdateValues,
  InputPlanTuitionDestroyValues,
} from '../../models/inputs/inputPlanTuitionsModel'

export const postInputPlanTuition = async (values: InputPlanTuitionCreateValues) => {
  return await ApiClient.post('/input_plan_tuitions', () => {}, values)
}

export const patchInputPlanTuition = async (values: InputPlanTuitionUpdateValues) => {
  return await ApiClient.patch('/input_plan_tuitions', () => {}, values)
}

export const deleteInputPlanTuition = async (values: {
  params: InputPlanTuitionDestroyValues
}) => {
  return await ApiClient.delete('/input_plan_tuitions', () => {}, values)
}
