import React, { FC, useCallback } from 'react'
import { isNil as _isNil, isUndefined as _isUndefined } from 'lodash'
import Typography from '@/components/v2/atoms/Typography'
import { convertLocaleString } from '@/models/commonsModelFunc'

interface TooltipInvestmentProps {
  /** アクティブ情報 */
  activeIndex: number | undefined
  /** ベースプラン名 */
  basePlanName?: any
  /** true: ベースプラン名非表示, false: ベースプラン名表示 */
  isHideBasePlanName?: boolean
  /** ベースアイテム */
  baseItem: any
  /** true: 比較有り, false: 比較無し */
  isCompare?: boolean
  /** 比較プラン名 */
  comparePlanName?: any
  /** 比較アイテム */
  compareItem?: any
}

const TooltipInvestment: FC<TooltipInvestmentProps> = (props) => {
  const {
    activeIndex,
    basePlanName,
    isHideBasePlanName,
    baseItem,
    isCompare,
    comparePlanName,
    compareItem,
  } = props

  const activeInvestmentItem = !_isUndefined(activeIndex) ? baseItem?.[activeIndex] : {}
  const compareActiveInvestmentItem = !_isUndefined(activeIndex)
    ? compareItem?.[activeIndex]
    : {}

  const fillIfEmpty = useCallback(
    (str: string) => {
      if (!_isNil(activeInvestmentItem.total)) {
        return str
      }
      return '-'
    },
    [activeInvestmentItem],
  )

  const fillIfEmptyCompare = useCallback(
    (str: string) => {
      if (!_isNil(compareActiveInvestmentItem.total)) {
        return str
      }
      return '-'
    },
    [compareActiveInvestmentItem],
  )

  return (
    <>
      {!_isUndefined(activeIndex) ? (
        <div
          className={`${
            isCompare ? 'flex' : ''
          } gap-x-[32px] py-[24px] px-[16px] rounded-[8px] bg-white md:shadow-modal visible pointer-events-auto overflow-x-scroll`}
        >
          <div className="w-full md:w-[200px] min-w-[200px]">
            <div className="pb-[16px] last:pb-0">
              {!isHideBasePlanName && (
                <Typography
                  className="pb-[4px] text-[12px] overflow-hidden whitespace-nowrap overflow-ellipsis"
                  isBold
                >
                  ベースプラン：
                  <br />
                  {basePlanName ?? '（プラン名未設定）'}
                </Typography>
              )}
              <div className="p-[10px] rounded-[4px] bg-primary-50">
                <Typography className="pb-[4px]" isBold>
                  {`${activeInvestmentItem.name}年(${activeInvestmentItem.age}歳)`}
                </Typography>
                <Typography isBold>
                  金融資産合計：
                  {fillIfEmpty(
                    `${convertLocaleString(
                      activeInvestmentItem.total + activeInvestmentItem.substruction,
                    )}万円`,
                  )}
                </Typography>
              </div>
            </div>

            <div className="pb-[16px] last:pb-0">
              <Typography
                className="mb-[10px] pb-[6px] text-[14px] border-b border-black-300"
                isBold
              >
                金融資産
              </Typography>
              <div className="flex justify-between pb-[12px] last:pb-0">
                <div className="flex items-center">
                  <i
                    className="ico rect-2 pr-[8px] text-[12px]"
                    style={{ color: '#B9D3E9' }}
                  />
                  <Typography className="text-[12px]">流動性資産</Typography>
                </div>
                <Typography className="text-[12px]">
                  {fillIfEmpty(
                    `${convertLocaleString(activeInvestmentItem.deposit)}万円`,
                  )}
                </Typography>
              </div>
              <div className="flex justify-between pb-[12px] last:pb-0">
                <div className="flex items-center">
                  <i
                    className="ico rect-2 pr-[8px] text-[12px]"
                    style={{ color: '#BBE1DF' }}
                  />
                  <Typography className="text-[12px]">投資信託</Typography>
                </div>
                <Typography className="text-[12px]">
                  {fillIfEmpty(
                    `${convertLocaleString(activeInvestmentItem.investment_trust)}万円`,
                  )}
                </Typography>
              </div>
              <div className="flex justify-between pb-[12px] last:pb-0">
                <div className="flex items-center">
                  <i
                    className="ico rect-2 pr-[8px] text-[12px]"
                    style={{ color: '#F9EBA3' }}
                  />
                  <Typography className="text-[12px]">株式</Typography>
                </div>
                <Typography className="text-[12px]">
                  {fillIfEmpty(`${convertLocaleString(activeInvestmentItem.stock)}万円`)}
                </Typography>
              </div>
              <div className="flex justify-between pb-[12px] last:pb-0">
                <div className="flex items-center">
                  <i
                    className="ico rect-2 pr-[8px] text-[12px]"
                    style={{ color: '#FF9933' }}
                  />
                  <Typography className="text-[12px]">債券</Typography>
                </div>
                <Typography className="text-[12px]">
                  {fillIfEmpty(`${convertLocaleString(activeInvestmentItem.bond)}万円`)}
                </Typography>
              </div>
              <div className="flex justify-between pb-[12px] last:pb-0">
                <div className="flex items-center">
                  <i
                    className="ico rect-2 pr-[8px] text-[12px]"
                    style={{ color: '#CC6666' }}
                  />
                  <Typography className="text-[12px]">
                    社内預金
                    {isCompare ? (
                      <>
                        <span className="hidden md:inline">・</span>
                        <br className="block md:hidden" />
                      </>
                    ) : (
                      <span>・</span>
                    )}
                    財形
                    {isCompare ? (
                      <>
                        <span className="hidden md:inline">・</span>
                        <br className="block md:hidden" />
                      </>
                    ) : (
                      <span>・</span>
                    )}
                    持株など
                  </Typography>
                </div>
                <Typography className="text-[12px]">
                  {fillIfEmpty(
                    `${convertLocaleString(activeInvestmentItem.deducted_savings)}万円`,
                  )}
                </Typography>
              </div>
              <div className="flex justify-between pb-[12px] last:pb-0">
                <div className="flex items-center">
                  <i
                    className="ico rect-2 pr-[8px] text-[12px]"
                    style={{ color: '#E1E0E2' }}
                  />
                  <Typography className="text-[12px]">その他</Typography>
                </div>
                <Typography className="text-[12px]">
                  {fillIfEmpty(`${convertLocaleString(activeInvestmentItem.other)}万円`)}
                </Typography>
              </div>
              <div className="flex justify-between pb-[12px] last:pb-0">
                <div className="flex items-center">
                  <i
                    className="ico rect-2 pr-[8px] text-[12px]"
                    style={{ color: '#D4C7DA' }}
                  />
                  <Typography className="text-[12px]">
                    マイナス
                    {isCompare && <br className="block md:hidden" />}
                    資産
                  </Typography>
                </div>
                <Typography className="text-[12px]">
                  {fillIfEmpty(
                    `${convertLocaleString(activeInvestmentItem.substruction)}万円`,
                  )}
                </Typography>
              </div>
              <div className="flex justify-between pb-[12px] last:pb-0">
                <div className="flex items-center">
                  <i
                    className="ico rect-2 pr-[8px] text-[12px]"
                    style={{ color: '#F3BCC2' }}
                  />
                  <Typography className="text-[12px]">
                    貯蓄用
                    {isCompare && <br className="block md:hidden" />}
                    保険資産
                  </Typography>
                </div>
                <Typography className="text-[12px]">
                  {fillIfEmpty(
                    `${convertLocaleString(activeInvestmentItem.insurance_asset)}万円`,
                  )}
                </Typography>
              </div>
            </div>

            <div className="pb-[16px] last:pb-0">
              <Typography
                className="mb-[10px] pb-[6px] text-[14px] border-b border-black-300"
                isBold
              >
                年間収支
              </Typography>
              <div className="flex justify-between pb-[12px] last:pb-0">
                <Typography className="text-[12px]">収入</Typography>
                <Typography className="text-[12px]">
                  {fillIfEmpty(`${convertLocaleString(activeInvestmentItem.income)}万円`)}
                </Typography>
              </div>
              <div className="flex justify-between pb-[12px] last:pb-0">
                <Typography className="text-[12px]">支出</Typography>
                <Typography className="text-[12px]">
                  {fillIfEmpty(
                    `${convertLocaleString(activeInvestmentItem.spending)}万円`,
                  )}
                </Typography>
              </div>
              <div className="flex justify-between pb-[12px] last:pb-0">
                <Typography className="text-[12px]">貯蓄額</Typography>
                <Typography className="text-[12px]">
                  {fillIfEmpty(
                    `${convertLocaleString(activeInvestmentItem.saving_amount)}万円`,
                  )}
                </Typography>
              </div>
            </div>
          </div>

          {isCompare && (
            <div className="w-full md:w-[200px] min-w-[200px]">
              <div className="pb-[16px] last:pb-0">
                <Typography
                  className="pb-[4px] text-[12px] overflow-hidden whitespace-nowrap overflow-ellipsis"
                  isBold
                >
                  比較プラン：
                  <br />
                  {comparePlanName ?? '（プラン名未設定）'}
                </Typography>
                <div className="p-[10px] rounded-[4px] bg-primary-50">
                  <Typography className="pb-[4px]" isBold>
                    {`${compareActiveInvestmentItem.name}年(${compareActiveInvestmentItem.age}歳)`}
                  </Typography>
                  <Typography isBold>
                    金融資産合計：
                    {fillIfEmptyCompare(
                      `${convertLocaleString(
                        compareActiveInvestmentItem.total +
                          compareActiveInvestmentItem.substruction,
                      )}万円`,
                    )}
                  </Typography>
                </div>
              </div>

              <div className="pb-[16px] last:pb-0">
                <Typography
                  className="mb-[10px] pb-[6px] text-[14px] border-b border-black-300"
                  isBold
                >
                  金融資産
                </Typography>
                <div className="flex justify-between pb-[12px] last:pb-0">
                  <div className="flex items-center">
                    <i
                      className="ico rect-2 pr-[8px] text-[12px]"
                      style={{ color: '#B9D3E9' }}
                    />
                    <Typography className="text-[12px]">流動性資産</Typography>
                  </div>
                  <Typography className="text-[12px]">
                    {fillIfEmptyCompare(
                      `${convertLocaleString(compareActiveInvestmentItem.deposit)}万円`,
                    )}
                  </Typography>
                </div>
                <div className="flex justify-between pb-[12px] last:pb-0">
                  <div className="flex items-center">
                    <i
                      className="ico rect-2 pr-[8px] text-[12px]"
                      style={{ color: '#BBE1DF' }}
                    />
                    <Typography className="text-[12px]">投資信託</Typography>
                  </div>
                  <Typography className="text-[12px]">
                    {fillIfEmptyCompare(
                      `${convertLocaleString(
                        compareActiveInvestmentItem.investment_trust,
                      )}万円`,
                    )}
                  </Typography>
                </div>
                <div className="flex justify-between pb-[12px] last:pb-0">
                  <div className="flex items-center">
                    <i
                      className="ico rect-2 pr-[8px] text-[12px]"
                      style={{ color: '#F9EBA3' }}
                    />
                    <Typography className="text-[12px]">株式</Typography>
                  </div>
                  <Typography className="text-[12px]">
                    {fillIfEmptyCompare(
                      `${convertLocaleString(compareActiveInvestmentItem.stock)}万円`,
                    )}
                  </Typography>
                </div>
                <div className="flex justify-between pb-[12px] last:pb-0">
                  <div className="flex items-center">
                    <i
                      className="ico rect-2 pr-[8px] text-[12px]"
                      style={{ color: '#FF9933' }}
                    />
                    <Typography className="text-[12px]">債券</Typography>
                  </div>
                  <Typography className="text-[12px]">
                    {fillIfEmptyCompare(
                      `${convertLocaleString(compareActiveInvestmentItem.bond)}万円`,
                    )}
                  </Typography>
                </div>
                <div className="flex justify-between pb-[12px] last:pb-0">
                  <div className="flex items-center">
                    <i
                      className="ico rect-2 pr-[8px] text-[12px]"
                      style={{ color: '#CC6666' }}
                    />
                    <Typography className="text-[12px]">
                      社内預金
                      <span className="hidden md:inline">・</span>
                      <br className="block md:hidden" />
                      財形
                      <span className="hidden md:inline">・</span>
                      <br className="block md:hidden" />
                      持株など
                    </Typography>
                  </div>
                  <Typography className="text-[12px]">
                    {fillIfEmptyCompare(
                      `${convertLocaleString(
                        compareActiveInvestmentItem.deducted_savings,
                      )}万円`,
                    )}
                  </Typography>
                </div>
                <div className="flex justify-between pb-[12px] last:pb-0">
                  <div className="flex items-center">
                    <i
                      className="ico rect-2 pr-[8px] text-[12px]"
                      style={{ color: '#E1E0E2' }}
                    />
                    <Typography className="text-[12px]">その他</Typography>
                  </div>
                  <Typography className="text-[12px]">
                    {fillIfEmptyCompare(
                      `${convertLocaleString(compareActiveInvestmentItem.other)}万円`,
                    )}
                  </Typography>
                </div>
                <div className="flex justify-between pb-[12px] last:pb-0">
                  <div className="flex items-center">
                    <i
                      className="ico rect-2 pr-[8px] text-[12px]"
                      style={{ color: '#D4C7DA' }}
                    />
                    <Typography className="text-[12px]">
                      マイナス
                      <br className="block md:hidden" />
                      資産
                    </Typography>
                  </div>
                  <Typography className="text-[12px]">
                    {fillIfEmptyCompare(
                      `${convertLocaleString(
                        compareActiveInvestmentItem.substruction,
                      )}万円`,
                    )}
                  </Typography>
                </div>
                <div className="flex justify-between pb-[12px] last:pb-0">
                  <div className="flex items-center">
                    <i
                      className="ico rect-2 pr-[8px] text-[12px]"
                      style={{ color: '#F3BCC2' }}
                    />
                    <Typography className="text-[12px]">
                      貯蓄用
                      <br className="block md:hidden" />
                      保険資産
                    </Typography>
                  </div>
                  <Typography className="text-[12px]">
                    {fillIfEmptyCompare(
                      `${convertLocaleString(
                        compareActiveInvestmentItem.insurance_asset,
                      )}万円`,
                    )}
                  </Typography>
                </div>
              </div>

              <div className="pb-[16px] last:pb-0">
                <Typography
                  className="mb-[10px] pb-[6px] text-[14px] border-b border-black-300"
                  isBold
                >
                  年間収支
                </Typography>
                <div className="flex justify-between pb-[12px] last:pb-0">
                  <Typography className="text-[12px]">収入</Typography>
                  <Typography className="text-[12px]">
                    {fillIfEmptyCompare(
                      `${convertLocaleString(compareActiveInvestmentItem.income)}万円`,
                    )}
                  </Typography>
                </div>
                <div className="flex justify-between pb-[12px] last:pb-0">
                  <Typography className="text-[12px]">支出</Typography>
                  <Typography className="text-[12px]">
                    {fillIfEmptyCompare(
                      `${convertLocaleString(compareActiveInvestmentItem.spending)}万円`,
                    )}
                  </Typography>
                </div>
                <div className="flex justify-between pb-[12px] last:pb-0">
                  <Typography className="text-[12px]">貯蓄額</Typography>
                  <Typography className="text-[12px]">
                    {fillIfEmptyCompare(
                      `${convertLocaleString(
                        compareActiveInvestmentItem.saving_amount,
                      )}万円`,
                    )}
                  </Typography>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : null}
    </>
  )
}

export default TooltipInvestment
