import {
  includes as _includes,
  find as _find,
  isNull as _isNull,
  isUndefined as _isUndefined,
} from 'lodash'
import {
  InsuranceType1,
  InsuranceType2,
  Purpose,
  ContractorType,
  InsuredType,
  InsurancePeriodType,
  InsuranceFeeType,
  CancellationRefundAmountInputType,
} from './commonInsurancesModel'
import {
  defaultToEmptyString,
  convertLocaleString,
  convertMomentDate,
  isNotEmptyValue,
  castNumberWithoutOperator,
} from './commonsModelFunc'
import { FamilyInfo } from './futures/futureBasesModel'

// 保険種別1
export const INSURANCE_TYPE1 = {
  life: 'life',
  medical: 'medical',
  cancer: 'cancer',
  nursing: 'nursing',
  personalAnnuity: 'personal_annuity',
}

// 保険種別2
export const INSURANCE_TYPE2 = {
  wholeLife: 'whole_life',
  term: 'term',
  incomeSecurity: 'income_security',
  endowment: 'endowment',
}

// 利用目的
export const PURPOSE = {
  security: 'security',
  savings: 'savings',
}

// 契約者
export const CONTRACTOR_TYPE = {
  personContract: 'person_contract',
  spouseContract: 'spouse_contract',
  otherPersonContract: 'other_person_contract',
}

// 被保険者
export const INSURED_TYPE = {
  personInsured: 'person_insured',
  spouseInsured: 'spouse_insured',
  otherPersonInsured: 'other_person_insured',
}

// 保険期間, 保険の払い込み
export const INSURANCE_PERIOD_TYPE = {
  wholeLife: 'whole_life',
  oneTime: 'one_time',
  age: 'age',
  date: 'date',
}
// 保険の払い込み期間
export const INSURANCE_PAYMENT_PERIOD_TYPE = {
  wholeLife: 'whole_life',
  oneTime: 'one_time',
  age: 'age',
  date: 'date',
}

// 返戻金算出方法
export const CANCELLATION_REFUND_TYPE = {
  rate: 'rate',
  amount: 'amount',
}

// 保険料, 満期金
export const INSURANCE_FEE_TYPE = {
  input: 'input',
  none: 'none',
}

// 保険料の支払い頻度
export const INSURANCE_FEE_PAYMENT_OCCURRENCE_IN_MONTHS = {
  month: 1,
  halfYear: 6,
  year: 12,
}

// 損害・少額短期保険用 保険種別1
export const NON_LIFE_INSURANCE_TYPE1 = {
  fire: 'fire',
  earthquake: 'earthquake',
  vehicle: 'vehicle',
  pet: 'pet',
  injuredIncomeSecurity: 'injured_income_security',
  other: 'other',
}

// 損害・少額短期保険用 保険料の支払い頻度
export const NON_LIFE_INSURANCE_FEE_MONTHS = {
  month: '1',
  halfYear: '6',
  year: '12',
  bulk: 'bulk',
}

export const isNotTargetInsuranceType2Selected = (
  insuranceType1: InsuranceType1 | undefined | null,
  insuranceType2: InsuranceType2 | undefined | null,
) => {
  const insuranceType2Group = [INSURANCE_TYPE2.incomeSecurity, INSURANCE_TYPE2.endowment]

  // 「保険種別1: 個人年金保険を選択」 または 「保険種別1: 生命保険以外を選択 かつ 保険種別2: 収入保障, 養老保険を選択」
  return (
    insuranceType1 === INSURANCE_TYPE1.personalAnnuity ||
    (insuranceType1 !== INSURANCE_TYPE1.life &&
      _includes(insuranceType2Group, insuranceType2))
  )
}

export const isNotInsurancePeriodTypeAge = (
  insurancePeriodType: InsurancePeriodType | null | undefined,
) => {
  return insurancePeriodType !== INSURANCE_PERIOD_TYPE.age
}

export const isInsuranceType2TermOrIncomeSecurity = (
  insuranceType2: InsuranceType2 | null | undefined,
) => {
  return (
    insuranceType2 === INSURANCE_TYPE2.term ||
    insuranceType2 === INSURANCE_TYPE2.incomeSecurity
  )
}

export const isInsuranceTypeWholeLife = (
  insuranceType2: InsuranceType2 | null | undefined,
) => {
  return insuranceType2 === INSURANCE_TYPE2.wholeLife
}

export const isSpouseOptionSelected = (
  contractorType: string | null | undefined,
  insuredType: string | null | undefined,
) => {
  return (
    contractorType == CONTRACTOR_TYPE.spouseContract ||
    insuredType == INSURED_TYPE.spouseInsured
  )
}

export const convertPurpose = (purpose: Purpose | null) => {
  switch (purpose) {
    case PURPOSE.savings:
      return '貯蓄のため'
    case PURPOSE.security:
      return '保障のため'
    default:
      return ''
  }
}

export const convertContractor = (
  contractorType: ContractorType | null,
  contractorName: string | null,
) => {
  switch (contractorType) {
    case CONTRACTOR_TYPE.personContract:
      return 'ご本人'
    case CONTRACTOR_TYPE.spouseContract:
      return '配偶者'
    case CONTRACTOR_TYPE.otherPersonContract:
      return contractorName
    default:
      return ''
  }
}

export const convertInsured = (
  insuredType: InsuredType | null,
  insuredName: string | null,
) => {
  switch (insuredType) {
    case INSURED_TYPE.personInsured:
      return 'ご本人'
    case INSURED_TYPE.spouseInsured:
      return '配偶者'
    case INSURED_TYPE.otherPersonInsured:
      return insuredName
    default:
      return ''
  }
}

export const convertPeriod = (
  periodType: InsurancePeriodType | null,
  age: number | null,
  targetType: ContractorType | InsuredType | null,
  targetName: string | null,
  targetTypeText: 'contractor' | 'insured',
) => {
  if (_isNull(periodType)) return ''
  if (periodType === INSURANCE_PERIOD_TYPE.wholeLife) return '終身'
  if (periodType === INSURANCE_PERIOD_TYPE.oneTime) return '1回のみ'

  const convertFunc =
    targetTypeText === 'contractor'
      ? () => convertContractor(targetType as ContractorType | null, targetName)
      : () => convertInsured(targetType as InsuredType | null, targetName)

  return `${convertFunc()} ${defaultToEmptyString(age)}歳まで`
}

export const convertPeriodFinishAt = (
  periodType: InsurancePeriodType | null,
  date: string | null,
  targetType: ContractorType | InsuredType | null,
  targetName: string | null,
  targetTypeText: 'contractor' | 'insured',
) => {
  if (_isNull(periodType)) return ''
  if (periodType === INSURANCE_PERIOD_TYPE.wholeLife) return '終身'
  if (periodType === INSURANCE_PERIOD_TYPE.oneTime) return '1回のみ'

  const convertFunc =
    targetTypeText === 'contractor'
      ? () => convertContractor(targetType as ContractorType | null, targetName)
      : () => convertInsured(targetType as InsuredType | null, targetName)

  return `${convertMomentDate(date, 'YYYY年MM月')}まで`
}

export const convertInsuranceFeePaymentOccurrenceInMonths = (
  insuranceFeePaymentOccurrenceInMonths: number | string | null,
) => {
  switch (insuranceFeePaymentOccurrenceInMonths) {
    case INSURANCE_FEE_PAYMENT_OCCURRENCE_IN_MONTHS.month:
      return '毎月'
    case INSURANCE_FEE_PAYMENT_OCCURRENCE_IN_MONTHS.halfYear:
      return '半年ごと'
    case INSURANCE_FEE_PAYMENT_OCCURRENCE_IN_MONTHS.year:
      return '1年ごと'
    default:
      return ''
  }
}

export const convertNonLifeInsuranceFeeMonths = (value: any) => {
  if (!isNotEmptyValue(value)) return ''
  if (value >= 24) return `${value / 12}年ごと`

  switch (value) {
    case NON_LIFE_INSURANCE_FEE_MONTHS.month:
      return '毎月'
    case NON_LIFE_INSURANCE_FEE_MONTHS.halfYear:
      return '半年ごと'
    case NON_LIFE_INSURANCE_FEE_MONTHS.year:
      return '1年ごと'
    case NON_LIFE_INSURANCE_FEE_MONTHS.year:
      return '長期一括'
    default:
      return ''
  }
}

export const convertMaturityMoney = (
  insuranceFeeType: InsuranceFeeType | null,
  insuranceFee: number | null,
  unit?: string,
) => {
  if (insuranceFeeType === INSURANCE_FEE_TYPE.input) {
    return `${defaultToEmptyString(unit)}${convertLocaleString(insuranceFee)}円`
  }
  if (insuranceFeeType === INSURANCE_FEE_TYPE.none) return 'なし'

  return ''
}

export const convertCancellationRefundAmountManyen = (
  cancellationRefundAtAge: number | null,
  cancellationRefundAmountManyen: number | null,
  cancellationRefundRatePercent: number | null,
  cancellationRefundAmountInputType: CancellationRefundAmountInputType | null,
  contractorType: ContractorType | null,
  contractorName: string | null,
) => {
  if (_isNull(cancellationRefundAmountInputType)) return ''

  const leftLabel = `${convertContractor(
    contractorType,
    contractorName,
  )}${defaultToEmptyString(cancellationRefundAtAge)}歳時 `
  const rightLabel =
    cancellationRefundAmountInputType === CANCELLATION_REFUND_TYPE.amount
      ? `${convertLocaleString(cancellationRefundAmountManyen)}万円`
      : `支払い済み保険料の${convertLocaleString(cancellationRefundRatePercent)}％`

  return `${leftLabel} ${rightLabel}`
}

export const convertCancellationRefundAt = (
  cancellationRefundAt: string | null,
  cancellationRefundAmountManyen: number | null,
  cancellationRefundRatePercent: number | null,
  cancellationRefundAmountInputType: CancellationRefundAmountInputType | null,
) => {
  if (cancellationRefundAmountInputType === CANCELLATION_REFUND_TYPE.amount) {
    return `${convertMomentDate(
      cancellationRefundAt,
      'YYYY年MM月',
    )} ${convertLocaleString(cancellationRefundAmountManyen)}万円`
  }
  if (cancellationRefundAmountInputType === CANCELLATION_REFUND_TYPE.rate) {
    return `${convertMomentDate(
      cancellationRefundAt,
      'YYYY年MM月',
    )}に 支払済み保険料の${convertLocaleString(cancellationRefundRatePercent)}％`
  }

  return ''
}

export const convertInsuranceType1 = (insuranceType1: InsuranceType1 | null) => {
  switch (insuranceType1) {
    case INSURANCE_TYPE1.life:
      return '生命保険'
    case INSURANCE_TYPE1.medical:
      return '医療保険'
    case INSURANCE_TYPE1.cancer:
      return 'がん保険'
    case INSURANCE_TYPE1.nursing:
      return '介護保険'
    case INSURANCE_TYPE1.personalAnnuity:
      return '個人年金保険'
    default:
      return ''
  }
}

export const convertNonLifeInsuranceType1 = (insuranceType1: any) => {
  switch (insuranceType1) {
    case NON_LIFE_INSURANCE_TYPE1.fire:
      return '火災保険'
    case NON_LIFE_INSURANCE_TYPE1.earthquake:
      return '地震保険'
    case NON_LIFE_INSURANCE_TYPE1.vehicle:
      return '自動車・バイク'
    case NON_LIFE_INSURANCE_TYPE1.pet:
      return 'ペット保険'
    case NON_LIFE_INSURANCE_TYPE1.injuredIncomeSecurity:
      return '所得補償保険'
    case NON_LIFE_INSURANCE_TYPE1.other:
      return 'その他'
    default:
      return ''
  }
}

export const convertInsuranceType2 = (insuranceType2: InsuranceType2 | null) => {
  switch (insuranceType2) {
    case INSURANCE_TYPE2.wholeLife:
      return '終身型'
    case INSURANCE_TYPE2.term:
      return '定期型'
    case INSURANCE_TYPE2.incomeSecurity:
      return '収入保障'
    case INSURANCE_TYPE2.endowment:
      return '養老保険'
    default:
      return ''
  }
}

export const convertInsuranceOptionValue = (
  name: string | null,
  insuranceType1: InsuranceType1 | null,
  insuranceType2: InsuranceType2 | null,
  insuranceAmount: number | null,
) => {
  return `${name} ${convertInsuranceType1(insuranceType1)} ${convertInsuranceType2(
    insuranceType2,
  )} ${convertLocaleString(castNumberWithoutOperator(insuranceAmount))}円`
}

export const selectChangeTargetForPurpose = (
  value: string,
  name: string,
  change: (key: string, value: string) => void,
) => {
  if (value === INSURANCE_TYPE2.term || value === INSURANCE_TYPE2.incomeSecurity) {
    change(name, PURPOSE.security)
  }

  return undefined
}

export const selectChangeTargetForPeriodType = (
  value: string,
  name: string,
  change: (key: string, value: string) => void,
) => {
  if (value === INSURANCE_TYPE2.wholeLife) {
    change(name, INSURANCE_PERIOD_TYPE.wholeLife)
  }

  return undefined
}

export const selectTargetType = (
  type: string | null | undefined,
  familyAgesObject:
    | Partial<Pick<FamilyInfo, 'person_current_age' | 'spouse_current_age'>>
    | undefined,
) => {
  if (_isUndefined(type) || _isUndefined(familyAgesObject)) return null

  if (type === CONTRACTOR_TYPE.personContract || type === INSURED_TYPE.personInsured) {
    return familyAgesObject?.person_current_age
  }
  if (type === CONTRACTOR_TYPE.spouseContract || type === INSURED_TYPE.spouseInsured) {
    return familyAgesObject?.spouse_current_age
  }

  return null
}
