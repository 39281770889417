import { InputSalary } from '@/models/inputs/inputSalariesModel'

export const buildInputSalaryInitialValues = (inputSalary: InputSalary | undefined) => {
  return {
    input_salary: {
      commutation_allowance: inputSalary?.commutation_allowance,
      working_start_at_age: inputSalary?.working_start_at_age,
      input_salary_company_base_attributes: {
        id: inputSalary?.input_salary_company_base?.id,
        before_migration_severance_evaluation_manyen:
          inputSalary?.input_salary_company_base
            ?.before_migration_severance_evaluation_manyen,
        severance_base_income:
          inputSalary?.input_salary_company_base?.severance_base_income,
        input_salary_company_changes_attributes: inputSalary?.input_salary_company_base?.input_salary_company_changes.map(
          (item) => {
            if (item.is_managerial_retired) {
              return { ...item, company_salary_grade_id: 'retired' }
            }

            return { ...item }
          },
        ),
      },
    },
  }
}
