import React, { FC } from 'react'
import SimulationItemCreateImg from '@/assets/images/v2/simulation-g-item-create.png'
import SimulationInputPlanImg from '@/assets/images/v2/simulation-g-input-plan.png'
import SimulationReflectSummaryImg from '@/assets/images/v2/simulation-g-reflect-summary.png'
import SimulationHowToReadSummaryImg from '@/assets/images/v2/simulation-g-how-to-read-summary.png'
import SimulationHowToReadGraphImg from '@/assets/images/v2/simulation-g-how-to-read-graph.png'
import SimulationHowToReadTableImg from '@/assets/images/v2/simulation-g-how-to-read-table.png'
import SimulationItemSaveImg from '@/assets/images/v2/simulation-g-item-save.png'
import Typography from '@/components/v2/atoms/Typography'
import GuidanceModal from '@/components/v2/organisms/GuidanceModal'

interface SimulationPageGuidanceModalProps {
  /** true: オープン, false: クローズ */
  isOpen: boolean
  /** モーダルクローズメソッド */
  onClose: () => void
}

const SimulationPageGuidanceModal: FC<SimulationPageGuidanceModalProps> = (props) => {
  const { isOpen, onClose } = props

  return (
    <GuidanceModal
      isOpen={isOpen}
      onClose={onClose}
      contents={[
        {
          title: 'シミュレーションのガイダンス',
          text: (
            <div>
              <Typography className="text-12 md:text-14 leading-normal text-black-800">
                シミュレーションでは、マイデータをベースに、設定したシミュレーション条件を上書きした結果を反映します。
              </Typography>
              <br />
              <Typography className="text-12 md:text-14 leading-normal text-black-800">
                今後計画していること（例：家の購入など）や、
                <br className="hidden md:block" />
                疑問に思うこと（例：転職したら収支はどうなるの？）など、 <br />
                様々なシミュレーション条件を登録・設定することで、
                <br className="hidden md:block" />
                将来のお金の動きや寿命を可視化することができます。
              </Typography>
              <br />
              <Typography className="text-12 md:text-14 leading-normal text-black-800">
                まずは、シミュレーションの作り方と、グラフ表の見方をおさえましょう！
              </Typography>
            </div>
          ),
          alt: 'simulation guidance',
        },
        {
          title: 'シミュレーションを作成しましょう！',
          text: (
            <Typography className="pb-[32px] text-12 md:text-14 leading-normal text-black-800">
              まずは、画面右の「設定パネルを開く」をクリックすると、
              <br className="hidden md:block" />
              シミュレーション条件の設定が行えます。
            </Typography>
          ),
          src: SimulationItemCreateImg,
          alt: 'create simulation item',
        },
        {
          title: '将来のプランを入力しましょう！',
          text: (
            <div className="pb-[32px]">
              <Typography className="text-12 md:text-14 leading-normal text-black-800">
                将来起こりうることや、やってみたいことを登録し、
                <br className="hidden md:block" />
                自分が納得する将来像を見つけましょう！
              </Typography>
              <br />
              <Typography className="text-12 md:text-14 leading-normal text-black-800">
                例えば、家を購入する場合、
                <br className="hidden md:block" />
                「手堅い価格」だけでなく「挑戦した価格」など複数のパターンを試すこともできます。
              </Typography>
              <br />
              <Typography className="text-12 md:text-14 leading-normal text-black-800">
                生活費を1万円変えてどのようなインパクトがあるのかを見たり、
                <br className="hidden md:block" />
                複数パターンを組み合わせることで、
                <br className="hidden md:block" />
                自分が納得する将来像を発見できます。
              </Typography>
            </div>
          ),
          src: SimulationInputPlanImg,
          alt: 'input simulation plan',
        },
        {
          title: 'サマリーに反映させましょう！',
          text: (
            <Typography className="pb-[32px] text-12 md:text-14 leading-normal text-black-800">
              登録した設定をクリックすると、 <br />
              設定項目の内容がグラフ表に反映されます。
            </Typography>
          ),
          src: SimulationReflectSummaryImg,
          alt: 'reflect summary',
        },
        {
          title: 'シミュレーション作成のコツ',
          text: (
            <div className="text-12 md:text-14 leading-normal text-black-800">
              <Typography>
                思い描いた人生プランを作成できないときは、 <br />
                以下のポイントを参考にしてみましょう！
              </Typography>

              <br />

              <Typography className="text-primary-500" isBold>
                ①設定と設定を組み合わせてみる
              </Typography>
              <Typography>
                転職などの「将来の収入の変化」 ×
                「資産運用」の組み合わせで、お金の寿命はどのくらい変化するのか？など、
                <br />
                シミュレーションの条件（設定）は、 <br />
                <span className="font-bold">
                  組み合わせながらプランニングすることで理想に近づいていきます♪
                </span>
              </Typography>

              <br />

              <Typography className="text-primary-500" isBold>
                ②いろんなパターンを作成してみる
              </Typography>
              <Typography>
                将来の住宅でも、「3000万円で購入する場合」「5000万円で購入する場合」「8000万円で購入する場合」・・・など、
                <br />
                <span className="font-bold">
                  設定を一つ以上作り、お金の寿命をチェックしてみましょう。
                </span>
                <br />
                無理だと思ったプランでも、意外と手が届くこともあります♪
              </Typography>

              <br />

              <Typography className="text-primary-500" isBold>
                ③プランを複製してアレンジしてみる
              </Typography>
              <Typography>
                人生にイレギュラーはつきもの。 <br />
                「これで完成！」と思ったプランでも、「月の生活費が一万円違うと将来どうなるの？」など、プランの一部を変えてみたバージョンを確認することも大切です。
                <br />
                <span className="font-bold">
                  プランを複製して、元データを残しながら手を加えてみましょう♪
                </span>
              </Typography>
            </div>
          ),
          alt: 'simulation item create tips',
        },
        {
          title: 'グラフ表の見方（サマリー編）',
          text: (
            <div className="pb-[32px]">
              <Typography className="text-12 md:text-14 leading-normal text-black-800">
                ライフプランシミュレーショングラフでは、 <br />
                資産のどう増減していくのかを視覚的にチェックできます。 <br />
                「比較する」をONにすることで、 <br className="hidden md:block" />
                他のプランやマイデータと比較することができます。
              </Typography>

              <br />

              <Typography className="text-12 md:text-14 leading-normal text-black-800">
                資金が0以下になっている部分が、お金の寿命です。 <br />
                ・いつ大きく増えたか、いつ大きく減ったか <br />
                ・継続的に減っているのはいつからかをチェックしましょう。
              </Typography>

              <br />

              <Typography className="text-12 md:text-14 leading-normal text-black-800">
                より詳細に変化を数字で見たい場合は、 <br />
                ライフプランシミュレーション表を確認しましょう。
              </Typography>
            </div>
          ),
          afterText: (
            <Typography className="pt-[32px] text-12 md:text-14 leading-normal text-black-800">
              お金の寿命が100歳を切っている場合や、 <br />
              100歳を超えていても、設定した条件が現実的ではない場合は、
              <br className="hidden md:block" />
              グラフや表で詳細を確認しながら、設定を変更しましょう。
            </Typography>
          ),
          src: SimulationHowToReadSummaryImg,
          alt: 'how to read summary',
        },
        {
          title: 'グラフ表の見方（グラフ編）',
          text: (
            <div className="pb-[32px]">
              <Typography className="text-12 md:text-14 leading-normal text-black-800">
                ライフプランシミュレーショングラフでは、 <br />
                資産のどう増減していくのかを視覚的にチェックできます。 <br />
                「比較する」をONにすることで、 <br />
                他のプランやマイデータと比較することができます。
              </Typography>

              <br />

              <Typography className="text-12 md:text-14 leading-normal text-black-800">
                資金が0以下になっている部分が、お金の寿命です。 <br />
                ・いつ大きく増えたか、いつ大きく減ったか <br />
                ・継続的に減っているのはいつからかをチェックしましょう。
              </Typography>

              <br />

              <Typography className="text-12 md:text-14 leading-normal text-black-800">
                より詳細に変化を数字で見たい場合は、 <br />
                ライフプランシミュレーション表を確認しましょう。
              </Typography>
            </div>
          ),
          src: SimulationHowToReadGraphImg,
          alt: 'how to read graph',
        },
        {
          title: 'グラフ表の見方（表編）',
          text: (
            <Typography className="pb-[32px] text-12 md:text-14 leading-normal text-black-800">
              ライフプランシミュレーション表では、 <br />
              お金の動きを内訳ごとに確認できます。 <br />
              表左にある をクリックすると、より詳細な内訳を表示できます。 <br />
              <br />
              ・年間貯蓄額がマイナスの場合、いくらのマイナスになっているか <br />
              ・大きな支出があった場合、貯金にどれくらい影響がでるか <br />
              など、細かいところを確認しましょう。
            </Typography>
          ),
          afterText: (
            <Typography className="pt-[32px] text-12 md:text-14 leading-normal text-black-600">
              ※収入合計ー支出合計が、年間貯蓄額になっています。 <br />
              年間貯蓄額を累計した金額が「金融資産」のグラフに反映されています。 <br />
              <br />
              ※金融資産に不動産の価格を足して <br />
              負債額を差し引いた結果が「純資産」のグラフに反映されています。
            </Typography>
          ),
          src: SimulationHowToReadTableImg,
          alt: 'how to read table',
        },
        {
          title: 'シミュレーションを保存しましょう！',
          text: (
            <Typography className="pb-[32px] text-12 md:text-14 leading-normal text-black-800">
              一通りのシミュレーションの設定が終わったら、 <br />
              画面上部から「保存する」ボタンをクリックし、 <br />
              プラン名、目標プランにするかどうかを選択して保存しましょう！ <br />
              <br />
              これでシミュレーションプランの作成が完了です。
            </Typography>
          ),
          src: SimulationItemSaveImg,
          alt: 'simulation item save',
        },
      ]}
    />
  )
}

export default SimulationPageGuidanceModal
