import ApiClient from '@/api/apiClient'
import {
  LearningVideoDisplayCreateValues,
  LearningVideoDisplay,
  LearningVideoDisplayUpdateValues,
} from '@/models/videoPlatforms/learningVideoDisplaysModel'

export const postLearningVideoDisplays = async (
  values: LearningVideoDisplayCreateValues,
) => {
  return await ApiClient.post('/learning_video_demo_accounts', () => {}, values)
}

export const getLearningVideoDisplays = async () => {
  return await ApiClient.get(
    '/learning_video_displays',
    (response: LearningVideoDisplay[]) => response,
  )
}

export const patchLearningVideoDisplay = async (
  values: LearningVideoDisplayUpdateValues,
) => {
  return await ApiClient.patch(`/learning_video_displays`, () => {}, values)
}
