import { get as _get, isUndefined as _isUndefined } from 'lodash'
import { required } from '@/utils/validate'

// 大型家電キー
export const YEARLY_LIVING_COST_LARGE_ELECTRONIC_KEYS = [
  'television',
  'washing_machine',
  'refrigerator',
  'air_conditioner',
  'pc',
  'dishwasher',
  'air_purifier',
  'ih_cooking_heater',
]

// 小型家電キー
export const YEARLY_LIVING_COST_SMALL_ELECTRONIC_KEYS = [
  'sp',
  'vacuum_cleaner',
  'digital_camera',
  'video_camera',
  'game_console',
  'toaster',
  'hairdryer',
  'rice_cooker',
  'microwave',
]

// 家電 平均値など
export const YEARLY_LIVING_COST_ELECTRONIC_SHEET: { [key: string]: any } = {
  television: { display_name: 'テレビ', occurance_in_years: 10, single_cost: 150000 },
  washing_machine: {
    display_name: '洗濯機',
    occurance_in_years: 7,
    single_cost: 200000,
  },
  refrigerator: { display_name: '冷蔵庫', occurance_in_years: 10, single_cost: 170000 },
  air_conditioner: {
    display_name: 'エアコン',
    occurance_in_years: 10,
    single_cost: 150000,
  },
  pc: { display_name: 'パソコン', occurance_in_years: 5, single_cost: 200000 },
  dishwasher: { display_name: '食洗機', occurance_in_years: 10, single_cost: 150000 },
  air_purifier: {
    display_name: '空気清浄機',
    occurance_in_years: 10,
    single_cost: 20000,
  },
  ih_cooking_heater: {
    display_name: 'IHクッキングヒーター',
    occurance_in_years: 10,
    single_cost: 150000,
  },

  sp: { display_name: 'スマートフォン', occurance_in_years: 4, single_cost: 85000 },
  vacuum_cleaner: { display_name: '掃除機', occurance_in_years: 6, single_cost: 10000 },
  digital_camera: {
    display_name: 'デジタルカメラ',
    occurance_in_years: 5,
    single_cost: 14000,
  },
  video_camera: {
    display_name: 'ビデオカメラ',
    occurance_in_years: 5,
    single_cost: 30000,
  },
  game_console: { display_name: 'ゲーム機', occurance_in_years: 3, single_cost: 32000 },
  toaster: {
    display_name: 'オーブントースター',
    occurance_in_years: 5,
    single_cost: 4000,
  },
  hairdryer: { display_name: 'ドライヤー', occurance_in_years: 4, single_cost: 20000 },
  rice_cooker: { display_name: '炊飯器', occurance_in_years: 5, single_cost: 32000 },
  microwave: { display_name: '電子レンジ', occurance_in_years: 10, single_cost: 25000 },
}

// 冠婚葬祭平均値
export const YEARLY_LIVING_COST_CEREMONY_SHEET: { [key: number]: any } = {
  20: 30000,
  30: 6000,
  40: 10000,
  50: 46000,
  60: 46000,
  70: 33000,
}

// 頻度
export const OCCURANCE_TERM_TYPE = {
  year: 'year',
  time: 'time',
}

export const electronicRequiredV2 = (
  value: any,
  allValues: any,
  key: string,
  otherValidate?: (value: any) => void,
) => {
  const isActive = _get(allValues, key)
  if (!isActive) return undefined

  const requiredMessage = required(value)
  const otherValidateMessage = otherValidate?.(value)

  if (!_isUndefined(requiredMessage)) return requiredMessage
  if (!_isUndefined(otherValidateMessage)) return otherValidateMessage

  return undefined
}
