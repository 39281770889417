import ApiClient from '../apiClient'

export const postFutureInflationV2 = async (values: any) => {
  return await ApiClient.post('/future_inflations', () => {}, values, undefined, 'v2')
}

export const patchFutureInflationV2 = async (values: any, id: number) => {
  return await ApiClient.patch(
    `/future_inflations/${id}`,
    () => {},
    values,
    undefined,
    'v2',
  )
}

export const deleteFutureInflationV2 = async (values: any, id: number) => {
  return await ApiClient.delete(
    `/future_inflations/${id}`,
    () => {},
    values,
    undefined,
    'v2',
  )
}
