import ApiClient from '@/api/apiClient'
import { Seminar, SeminarCreateParams } from '@/models/seminarsModel'

export const getSeminars = async () => {
  return await ApiClient.get(
    '/seminars',
    (response: Seminar[]) => response,
    {},
    false,
    'v2',
  )
}

export const postSeminar = async (values: SeminarCreateParams) => {
  return await ApiClient.post('/seminars', () => {}, values, false, 'v2')
}

export const patchSeminar = async (id: number, values: SeminarCreateParams) => {
  return await ApiClient.patch(`/seminars/${id}`, () => {}, values, false, 'v2')
}

export const deleteSeminar = async (id: number) => {
  return await ApiClient.delete(`/seminars/${id}`, () => {}, {}, false, 'v2')
}
