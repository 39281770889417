import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isNull as _isNull } from 'lodash'
import Typography from '@/components/v2/atoms/Typography'
import { setPageMeta } from '@/containers/pageMetasSlice'
import { fetchInputYearCost } from '@/containers/inputs/inputYearCostsSlice'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import { convertLocaleString, defaultToEmptyString } from '@/models/commonsModelFunc'
import { convertYearCost } from '@/models/inputs/inputYearCostsModelFunc'
import { RootState } from '@/store'
import MyDataListWrapper from '@/templates/v2/myData/_ListWrapper'

const MyDataYearCost: FC = () => {
  const dispatch = useDispatch()
  const { handleMovePage } = useCustomHistory()
  const { inputYearCost } = useSelector((state: RootState) => state.inputYearCosts)

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'マイデータ | 特別な支出・大きな支出',
        description: 'マイデータ | 特別な支出・大きな支出',
        keyword: 'マイデータ | 特別な支出・大きな支出',
      }),
    )
    dispatch(fetchInputYearCost())
  }, [])

  return (
    <MyDataListWrapper
      title="特別な支出・大きな支出"
      description="給与明細が必要です。ご用意ください。"
      onClick={() => handleMovePage('/v2/myData/yearCosts/edit')}
    >
      {!_isNull(inputYearCost) && (
        <table className="w-[100%] mb-[60px] last:mb-0 text-[12px] md:text-[14px]">
          <tbody>
            <tr className="h-[45px] odd:bg-black-100">
              <td className="w-[30%] px-[16px] md:px-[30px]">
                <Typography isBold>
                  家電の
                  <br className="block md:hidden" />
                  買い替え
                </Typography>
              </td>
              <td className="w-[70%] px-[16px] md:px-[30px]">
                <Typography>
                  {convertYearCost(
                    inputYearCost.replacement_appliances,
                    inputYearCost.replacement_frequency_year,
                    inputYearCost.replacement_frequency_number,
                  )}
                </Typography>
              </td>
            </tr>
            <tr className="h-[45px] odd:bg-black-100">
              <td className="px-[16px] md:px-[30px]">
                <Typography isBold>帰省</Typography>
              </td>
              <td className="px-[16px] md:px-[30px]">
                <Typography>
                  {convertYearCost(
                    inputYearCost.homecoming,
                    inputYearCost.homecoming_frequency_year,
                    inputYearCost.homecoming_frequency_number,
                  )}
                </Typography>
              </td>
            </tr>
            <tr className="h-[45px] odd:bg-black-100">
              <td className="px-[16px] md:px-[30px]">
                <Typography isBold>冠婚葬祭</Typography>
              </td>
              <td className="px-[16px] md:px-[30px]">
                <Typography>
                  {`年間${convertLocaleString(
                    inputYearCost.ceremonial_occasion_yearly,
                  )}円`}
                </Typography>
              </td>
            </tr>
            <tr className="h-[45px] odd:bg-black-100">
              <td className="px-[16px] md:px-[30px]">
                <Typography isBold>旅行</Typography>
              </td>
              <td className="px-[16px] md:px-[30px]">
                <Typography>
                  {convertYearCost(
                    inputYearCost.travel,
                    inputYearCost.travel_frequency_year,
                    inputYearCost.travel_frequency_number,
                  )}
                </Typography>
              </td>
            </tr>
            {inputYearCost.input_year_cost_others.map((item) => (
              <tr key={item.id} className="h-[45px] odd:bg-black-100">
                <td className="px-[16px] md:px-[30px]">
                  <Typography isBold>
                    {defaultToEmptyString(item.journal_name)}
                  </Typography>
                </td>
                <td className="px-[16px] md:px-[30px]">
                  <Typography>
                    {`年間${convertLocaleString(item.cost_yearly)}円`}
                  </Typography>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </MyDataListWrapper>
  )
}

export default MyDataYearCost
