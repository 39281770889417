// 想定人事評価
export const EVALUATION = {
  upper: 'upper',
  middle: 'middle',
  lower: 'lower',
}

export const EVALUATION_SELECT_ITEMS = [
  { label: '上位', value: EVALUATION.upper },
  { label: '並', value: EVALUATION.middle },
  { label: '下位', value: EVALUATION.lower },
]
