import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { unauthorizedCheck } from '../accountsSlice'
import {
  postInputAsset,
  patchInputAsset,
  getInputAssetsSummaryList,
  deleteInputAsset,
} from '../../api/inputs/inputAssets'
import { ApiError } from '../../models/commonsModel'
import {
  InputAsset,
  InputAssetCreateValues,
  InputAssetsSummaryListResponse,
  InputAssetsSummaryList,
  InputAssetUpdateValues,
} from '../../models/inputs/inputAssetsModel'
import { AppThunk } from '../../store'
import { sortBy as _sortBy } from 'lodash'

interface InputAssetState {
  inputAssets: InputAsset[]
  inputAssetsSummaryList: InputAssetsSummaryList | null
  isLoading: boolean
  isDeleteLoading: boolean
}

const initialState: InputAssetState = {
  inputAssets: [],
  inputAssetsSummaryList: null,
  isLoading: false,
  isDeleteLoading: false,
}

const inputAssetsSlice = createSlice({
  name: 'inputAssets',
  initialState,
  reducers: {
    setInputAssets: (state, action: PayloadAction<InputAsset[]>) => {
      state.inputAssets = _sortBy(action.payload, 'id')
      return state
    },
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    deleteInputAssetStart: (state) => {
      state.isDeleteLoading = true
      return state
    },
    getInputAssetsSummaryListSuccess: (
      state,
      action: PayloadAction<InputAssetsSummaryListResponse>,
    ) => {
      const { payload } = action
      state.inputAssetsSummaryList = payload
      state.inputAssets = _sortBy(payload.input_assets, 'id')
      state.isLoading = false
      return state
    },
    resetInputAssetsStore: (state) => {
      state = initialState
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    deleteInputAssetSuccess: (state) => {
      state.isDeleteLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      state.isDeleteLoading = false
      return state
    },
  },
})

export const {
  setInputAssets,
  requestStart,
  deleteInputAssetStart,
  getInputAssetsSummaryListSuccess,
  resetInputAssetsStore,
  requestSuccess,
  deleteInputAssetSuccess,
  requestFailure,
} = inputAssetsSlice.actions
export const inputAssetsReducer = inputAssetsSlice.reducer

export const fetchInputAssetSummary = (): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  getInputAssetsSummaryList()
    .then((response) => {
      dispatch(getInputAssetsSummaryListSuccess(response))
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const createInputAsset = (
  values: InputAssetCreateValues,
  callback: () => void,
  resolve?: (error?: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postInputAsset(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
      resolve?.({})
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error, resolve))
    })
}

export const updateInputAsset = (
  id: number,
  values: InputAssetUpdateValues,
  callback: () => void,
  resolve?: (error?: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchInputAsset(id, values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error, resolve))
    })
}

export const destroyInputAsset = (id: number, callback: () => void): AppThunk => async (
  dispatch,
) => {
  dispatch(deleteInputAssetStart())
  deleteInputAsset(id)
    .then(() => {
      dispatch(deleteInputAssetSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}
