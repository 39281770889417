import React, { FC } from 'react'
import clsx from 'clsx'

interface ChipProps {
  /** クラス名 */
  className?: string
  /** true: 太字, false: 通常 */
  isBold?: boolean
}

const Chip: FC<ChipProps> = (props) => {
  const { className, isBold, children } = props
  return (
    <p
      className={clsx(`${className} rounded-14 px-16 w-max`, {
        ['font-bold']: isBold,
      })}
    >
      {children}
    </p>
  )
}

export default Chip
