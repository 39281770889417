import ApiClient from '../apiClient'
import { FuturePlanTuitionCreateValues } from '../../models/futures/futurePlanTuitionsModel'

export const postFuturePlanTuitionV2 = async (values: FuturePlanTuitionCreateValues) => {
  return await ApiClient.post('/future_plan_tuitions', () => {}, values, undefined, 'v2')
}

export const deleteFuturePlanTuitionV2 = async (
  values: FuturePlanTuitionCreateValues,
) => {
  return await ApiClient.delete(
    '/future_plan_tuitions',
    () => {},
    values,
    undefined,
    'v2',
  )
}
