import {
  REPAYMENT_MODE,
  ADVANCE_MODE,
} from '../../../models/futures/futureLoanAdvancesModelFunc'

// 返済方法
export const REPAYMENT_MODE_SELECT_ITEMS = [
  { value: REPAYMENT_MODE.short, label: '期間短縮型' },
  { value: REPAYMENT_MODE.mitigation, label: '返済額軽減型' },
]

// 返済の頻度
export const ADVANCE_MODE_SELECT_ITEMS = [
  { value: ADVANCE_MODE.every, label: '毎年行う' },
  { value: ADVANCE_MODE.once, label: '1回のみ' },
]
