import ApiClient from '@/api/apiClient'
import { SeminarParticipantUpdateValues } from '@/models/seminarParticipantsModel'
import { Seminar } from '@/models/seminarsModel'

export const patchSeminarParticipant = async (
  uniqueId: string,
  values: SeminarParticipantUpdateValues,
) => {
  return await ApiClient.patch(
    `/seminar_participants/${uniqueId}`,
    (response: Partial<Seminar> | null) => response,
    values,
    false,
    'v2',
  )
}
