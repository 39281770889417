import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  sortBy as _sortBy,
  isNull as _isNull,
  isEmpty as _isEmpty,
  some as _some,
} from 'lodash'
import {
  getAdminDetailFutureBaseV2,
  patchAdminFutureBasePublish,
  patchAdminFutureBaseHide,
} from '@/api/admins/futureBases'
import {
  getFutureBase,
  getDetailFutureBaseV2,
  postFutureBaseV2,
  postDetailUnsavedFutureBase,
  patchMainPlan,
  deleteFutureBase,
  getFutureBaseApplicableLoans,
  getFutureBasesV2,
  getFutureBaseApplicableHousingsUnsavedV2,
  getFutureBaseApplicableLoansUnsavedV2,
  patchFutureBaseV2,
  getDetailFutureBaseV2Insurance,
} from '@/api/futures/futureBases'
import { unauthorizedAdminCheck } from '@/containers/admins/fpAccountsSlice'
import { fetchAccountFutureItems, unauthorizedCheck } from '@/containers/accountsSlice'
import { setFutureCars } from '@/containers/futures/futureCarsSlice'
import { setFutureEstateInvestments } from '@/containers/futures/futureEstateInvestmentsSlice'
import { setFutureEstateInvestmentSales } from './futureEstateInvestmentSalesSlice'
import {
  setFutureFamilyChildbirths,
  setFutureFamilyHours,
} from '@/containers/futures/futureFamiliesSlice'
import { setFutureHousing } from '@/containers/futures/futureHousingsSlice'
import { setFutureHousingSales } from '@/containers/futures/futureHousingSalesSlice'
import { setFutureIncomeBases } from '@/containers/futures/futureIncomesSlice'
import { setFutureInflation } from '@/containers/futures/futureInflationsSlice'
import { setFutureInsurances } from '@/containers/futures/futureInsurancesSlice'
import { setFutureRisk } from '@/containers/futures/futureRisksSlice'
import { setFutureAssets } from '@/containers/futures/futureAssetsSlice'
import { setFutureLoanAdvances } from '@/containers/futures/futureLoanAdvancesSlice'
import { setFutureMarriage } from '@/containers/futures/futureMarriagesSlice'
import { setFutureMonthlyLivingCostBase } from '@/containers/futures/futureMonthlyLivingCostBasesSlice'
import { setFutureLendHousings } from '@/containers/futures/futureLendHousingsSlice'
import { setFutureYearlyLivingCost } from '@/containers/futures/futureYearlyLivingCostsSlice'
import { setFuturePlanTuition } from '@/containers/futures/futurePlanTuitionsSlice'
import { setInputMonthlyCost } from '@/containers/inputs/inputMonthlyCostsSlice'
import { setInputYearCosts } from '@/containers/inputs/inputYearCostsSlice'
import { setInputLoans, setInputHousings } from '@/containers/inputs/inputHousingsSlice'
import { HANDLER_TYPE } from '@/constants/formItem/futures/futureBases'
import { setInputEstateInvestment } from '@/containers/inputs/inputEstateInvestmentsSlice'
import {
  IndexFutureBase,
  CommonFutureBaseResponse,
  FutureBaseResponse,
  SimulationInfo,
  AssetSummary,
  SimulationGraph,
  BalanceTooltip,
  InvestmentTooltip,
  FamilyStructureGraph,
  FamilyStructureTooltip,
  FutureBaseTable,
  FamilyInfo,
  HandlerType,
  ApplicableHousing,
  ApplicableHousingResponse,
  FutureHousingsWithSaleOrLend,
  ApplicableLoan,
} from '@/models/futures/futureBasesModel'
import { buildNetAssetGraph } from '@/models/futures/futureBasesModelFunc'
import { setFutureV2Housings } from './futureV2HousingsSlice'
import { AppThunk } from '@/store'
import { patchInputPastData } from '@/api/inputs/inputPastData'

interface FutureBaseState {
  futureBaseId: number
  handlerType: HandlerType
  futureItemExists: boolean
  futureBases: IndexFutureBase[]
  futureBasesV2: any[]
  detailFutureBase: any
  detailFutureBaseV2: any
  myDataDetailFutureBaseV2: any
  simulationInfo: SimulationInfo
  familyInfo: FamilyInfo
  assetSummary: AssetSummary
  simulationGraphs: SimulationGraph[]
  balanceTooltip: BalanceTooltip
  investmentTooltip: InvestmentTooltip
  inputBalanceTooltip: BalanceTooltip
  inputInvestmentTooltip: InvestmentTooltip
  familyStructureGraph: FamilyStructureGraph
  familyStructureTooltip: FamilyStructureTooltip
  futureBaseTable: FutureBaseTable
  applicableHousing: ApplicableHousing
  applicableLoans: ApplicableLoan[]
  futureHousingsWithSaleOrLend: FutureHousingsWithSaleOrLend
  isLoading: boolean
  isGetFutureBaseLoading: boolean
  isGetDetailFutureBaseLoading: boolean
  isConfirmingMydata: boolean
}

const initialState: FutureBaseState = {
  futureBaseId: 0,
  handlerType: HANDLER_TYPE.user as HandlerType,
  futureItemExists: false,
  futureBases: [],
  futureBasesV2: [],
  detailFutureBase: {},
  detailFutureBaseV2: {},
  myDataDetailFutureBaseV2: {},
  simulationInfo: {
    future_name: null,
    is_main: false,
    has_housing_loan: false,
    has_married: false,
    has_married_in_future: false,
    has_owned_home: false,
    has_owned_home_in_simulation: false,
    has_estate_investments: false,
    has_children: false,
    simulated_element_count: 0,
    future_item_count: 0,
  },
  familyInfo: {
    person_age: 0,
    spouse_age: 0,
    person_current_age: 0,
    spouse_current_age: null,
    spouse_marrying_age: null,
    is_married: false,
    get_married_at: null,
    enrollment_day: null,
    current_child_count: 0,
    future_child_count: 0,
    total_child_count: 0,
    children_info: [],
  },
  assetSummary: {
    future: {
      current_year_asset_total: 0,
      money_dead_year: ' ー ',
      money_dead_age: null,
      year_of_age_65_asset: 0,
    },
    input: {
      current_year_asset_total: 0,
      money_dead_year: ' ー ',
      money_dead_age: null,
      year_of_age_65_asset: 0,
    },
    difference: {
      current_year_asset_total: 0,
      money_dead_year: ' ー ',
      year_of_age_65_asset: 0,
    },
  },
  simulationGraphs: [],
  balanceTooltip: {},
  investmentTooltip: {},
  inputBalanceTooltip: {},
  inputInvestmentTooltip: {},
  familyStructureGraph: {
    start_year: 0,
    final_year: 0,
    families: [],
    money: [],
  },
  familyStructureTooltip: {},
  futureBaseTable: {
    family_structure_table_data: {},
    family_structure_table_columns: [],
    asset_table_data: {},
    asset_table_columns: [],
    statement_table_data: {},
    statement_table_columns: [],
    input_statement_table_data: {},
  },
  applicableHousing: {
    sellable_housings: {
      input_housing_ids: [],
      future_housing_ids: [],
      future_v2_housing_ids: [],
      future_lend_housing_ids: [],
      applicable_year: {},
    },
    lendable_housings: {
      input_housing_ids: [],
      future_v2_housing_ids: [],
      applicable_year: {},
    },
    movable_housings: {
      input_housing_ids: [],
      future_v2_housing_ids: [],
      applicable_year: {},
    },
  },
  applicableLoans: [],
  futureHousingsWithSaleOrLend: {
    future_housing_ids: [],
    future_v2_housing_ids: [],
  },
  isLoading: false,
  isGetFutureBaseLoading: false,
  isGetDetailFutureBaseLoading: false,
  isConfirmingMydata: false,
}

const futureBasesSlice = createSlice({
  name: 'futureBases',
  initialState,
  reducers: {
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    resetFutureBasesStore: (state) => {
      state = initialState
      return state
    },
    setFutureItemExists: (state, action: PayloadAction<boolean>) => {
      state.futureItemExists = action.payload
      return state
    },
    getFutureBaseStart: (state) => {
      state.isGetFutureBaseLoading = true
      return state
    },
    postDetailFutureBaseStart: (state) => {
      state.isGetDetailFutureBaseLoading = true
      return state
    },
    postDetailUnsavedFutureBaseStart: (state) => {
      state.isGetDetailFutureBaseLoading = true
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    getFutureBasesV2Success: (state, action: PayloadAction<any>) => {
      state.futureBasesV2 = _sortBy(action.payload, (fb) => 0 - fb.id)
      state.isLoading = false
      return state
    },
    getComparingFutureBaseSuccess: (state) => {
      state.isGetFutureBaseLoading = false
      return state
    },
    getFutureBaseSuccess: (state, action: PayloadAction<FutureBaseResponse>) => {
      const {
        id,
        handler_type,
        family_info,
        future_housings_with_sale_or_lend,
      } = action.payload
      const { children_info } = family_info

      state.futureBaseId = id
      state.handlerType = handler_type
      state.futureHousingsWithSaleOrLend = future_housings_with_sale_or_lend
      state.familyInfo = {
        ...family_info,
        children_info: _sortBy(children_info, 'child_index'),
      }
      state.isGetFutureBaseLoading = false

      return state
    },
    getNewDetailFutureBaseSuccess: (state, action: PayloadAction<any>) => {
      state.detailFutureBase = {
        ...action.payload,
        net_asset_graph: buildNetAssetGraph(action.payload.net_asset_graph),
        input_net_asset_graph: buildNetAssetGraph(action.payload.input_net_asset_graph),
      }
      state.isGetDetailFutureBaseLoading = false
      return state
    },
    getDetailFutureBaseV2Success: (state, action: PayloadAction<any>) => {
      state.detailFutureBaseV2 = {
        ...action.payload,
        net_asset_graph: buildNetAssetGraph(action.payload.net_asset_graph),
        input_net_asset_graph: buildNetAssetGraph(action.payload.input_net_asset_graph),
      }
      state.simulationInfo = action.payload.simulation_info
      state.isGetDetailFutureBaseLoading = false
      return state
    },

    getFutureBaseApplicableHousingsSuccess: (
      state,
      action: PayloadAction<ApplicableHousingResponse>,
    ) => {
      state.applicableHousing = action.payload
      state.isLoading = false

      return state
    },
    getFutureBaseApplicableLoansSuccess: (
      state,
      action: PayloadAction<ApplicableLoan[]>,
    ) => {
      state.applicableLoans = action.payload
      state.isLoading = false

      return state
    },
    postMyDataDetailUnsavedFutureBaseSuccess: (state, action: PayloadAction<any>) => {
      state.myDataDetailFutureBaseV2 = {
        ...action.payload,
        net_asset_graph: buildNetAssetGraph(action.payload.net_asset_graph),
        input_net_asset_graph: buildNetAssetGraph(action.payload.input_net_asset_graph),
      }
      state.simulationInfo = action.payload.simulation_info
      state.isGetDetailFutureBaseLoading = false
      return state
    },
    postDetailUnsavedFutureBaseSuccess: (state, action: PayloadAction<any>) => {
      state.detailFutureBaseV2 = {
        ...action.payload,
        net_asset_graph: buildNetAssetGraph(action.payload.net_asset_graph),
        input_net_asset_graph: buildNetAssetGraph(action.payload.input_net_asset_graph),
      }
      state.simulationInfo = action.payload.simulation_info
      state.isGetDetailFutureBaseLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      state.isGetFutureBaseLoading = false
      state.isGetDetailFutureBaseLoading = false
      return state
    },
    resetFutureBase: () => {
      return initialState
    },
    resetMyDataDetailFutureBaseV2: (state) => {
      state.myDataDetailFutureBaseV2 = initialState.myDataDetailFutureBaseV2
      return state
    },
    setIsConfirmingMydata: (state, action: PayloadAction<boolean>) => {
      state.isConfirmingMydata = action.payload
      return state
    },
  },
})

export const {
  requestStart,
  resetFutureBasesStore,
  setFutureItemExists,
  getFutureBaseStart,
  postDetailFutureBaseStart,
  postDetailUnsavedFutureBaseStart,
  requestSuccess,
  getFutureBasesV2Success,
  getComparingFutureBaseSuccess,
  getFutureBaseSuccess,
  getNewDetailFutureBaseSuccess,
  getDetailFutureBaseV2Success,
  getFutureBaseApplicableHousingsSuccess,
  getFutureBaseApplicableLoansSuccess,
  postDetailUnsavedFutureBaseSuccess,
  postMyDataDetailUnsavedFutureBaseSuccess,
  requestFailure,
  resetFutureBase,
  resetMyDataDetailFutureBaseV2,
  setIsConfirmingMydata,
} = futureBasesSlice.actions
export const futureBasesReducer = futureBasesSlice.reducer

const commonFetchFutureBaseFunc = (
  response: CommonFutureBaseResponse,
  dispatch: (response: any) => void,
) => {
  dispatch(getFutureBaseSuccess(response))
  dispatch(setFutureMarriage(response.future_marriage))
  dispatch(setFutureFamilyChildbirths(response.future_family_childbirths))
  dispatch(setFutureFamilyHours(response.future_family_hours))
  dispatch(setFutureIncomeBases(response.future_income_bases))
  dispatch(setFutureMonthlyLivingCostBase(response.future_monthly_living_cost_base))
  dispatch(
    setFutureYearlyLivingCost({
      ceremonies: response.future_yearly_living_cost_ceremonies,
      electronics: response.future_yearly_living_cost_electronics,
      homeComings: response.future_yearly_living_cost_home_comings,
      travels: response.future_yearly_living_cost_travels,
    }),
  )
  dispatch(setFutureCars(response.future_cars))
  dispatch(setFutureLoanAdvances(response.future_loan_advances))
  dispatch(setFutureHousing(response.future_housing))
  dispatch(setFutureHousingSales(response.future_housing_sales))
  dispatch(setFuturePlanTuition(response.future_family_childbirths))
  dispatch(setFutureAssets(response.future_assets))
  dispatch(setFutureEstateInvestments(response.future_estate_investments))
  dispatch(setFutureEstateInvestmentSales(response.future_estate_investment_sales))
  dispatch(setFutureInsurances(response.future_insurances))
  dispatch(setFutureInflation(response.future_inflation))
  dispatch(setFutureV2Housings(response.future_v2_housings))
  dispatch(setFutureLendHousings(response.future_lend_housings))
  dispatch(setFutureRisk(response.future_risk))
  dispatch(setInputMonthlyCost(response.input_monthly_cost))
  dispatch(setInputYearCosts(response.input_year_cost))
  dispatch(setInputLoans(response.input_loans))
  dispatch(setInputHousings(response.input_housings))
  dispatch(setInputEstateInvestment(response.input_estate_investments))

  const singleItemGroup = [
    response.future_marriage,
    response.future_housing,
    response.future_risk,
    response.future_inflation,
    response.future_monthly_living_cost,
    response.future_monthly_living_cost_base,
    response.future_year_living_cost,
  ]

  const multipleItemGroup = [
    response.future_family_childbirths,
    response.future_income_bases,
    response.future_cars,
    response.future_custom_items,
    response.future_loan_advances,
    response.future_assets,
    response.future_estate_investments,
    response.future_insurances,
    response.future_estate_investment_sales,
    response.future_housing_sales,
    response.future_lend_housings,
    response.future_v2_housings,
    response.future_family_hours,
    response.future_yearly_living_cost_ceremonies,
    response.future_yearly_living_cost_electronics,
    response.future_yearly_living_cost_home_comings,
    response.future_yearly_living_cost_travels,
  ]

  if (_some(singleItemGroup, (item) => !_isNull(item))) {
    return dispatch(setFutureItemExists(true))
  }

  if (_some(multipleItemGroup, (item) => !_isEmpty(item))) {
    return dispatch(setFutureItemExists(true))
  }

  dispatch(setFutureItemExists(false))
}

export const fetchFutureBase = (
  futureBaseId: number,
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(getFutureBaseStart())
  getFutureBase(futureBaseId)
    .then((response: FutureBaseResponse) => {
      commonFetchFutureBaseFunc(response, dispatch)
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const fetchNewDetailFutureBase = (
  futureBaseId: number,
  callback?: (response: any) => void,
): AppThunk => async (dispatch) => {
  dispatch(postDetailFutureBaseStart())
  getDetailFutureBaseV2(futureBaseId)
    .then((response) => {
      dispatch(getNewDetailFutureBaseSuccess(response))
      callback?.(response)
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const fetchDetailFutureBaseV2 = (
  futureBaseId: number,
  callback?: (response: any) => void,
): AppThunk => async (dispatch) => {
  dispatch(postDetailFutureBaseStart())
  getDetailFutureBaseV2(futureBaseId)
    .then((response) => {
      dispatch(getDetailFutureBaseV2Success(response))
      callback?.(response)
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const fetchNewDetailFutureBaseInsurance = (
  futureBaseId: number,
  insuranceType: string,
  callback?: (response: any) => void,
): AppThunk => async (dispatch) => {
  dispatch(postDetailFutureBaseStart())
  getDetailFutureBaseV2Insurance(futureBaseId, insuranceType)
    .then((response) => {
      dispatch(getNewDetailFutureBaseSuccess(response))
      callback?.(response)
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const fetchNewAdminDetailFutureBase = (
  futureBaseId: number,
  callback?: (response: any) => void,
): AppThunk => async (dispatch) => {
  dispatch(postDetailFutureBaseStart())
  getAdminDetailFutureBaseV2(futureBaseId)
    .then((response) => {
      dispatch(getNewDetailFutureBaseSuccess(response))
      callback?.(response)
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const fetchFutureBaseApplicableHousingsUnsavedV2 = (): AppThunk => async (
  dispatch,
) => {
  dispatch(requestStart())
  getFutureBaseApplicableHousingsUnsavedV2()
    .then((response) => dispatch(getFutureBaseApplicableHousingsSuccess(response)))
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const fetchFutureBaseApplicableLoansUnsavedV2 = (): AppThunk => async (
  dispatch,
) => {
  dispatch(requestStart())
  getFutureBaseApplicableLoansUnsavedV2()
    .then((response) => dispatch(getFutureBaseApplicableLoansSuccess(response)))
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const fetchFutureBaseApplicableLoans = (futureBaseId: number): AppThunk => async (
  dispatch,
) => {
  dispatch(requestStart())
  getFutureBaseApplicableLoans(futureBaseId)
    .then((response) => dispatch(getFutureBaseApplicableLoansSuccess(response)))
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const createFutureBaseV2 = (
  values: any,
  callback: (response: any) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postFutureBaseV2(values)
    .then((response) => {
      dispatch(requestSuccess())
      callback(response)
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const updateFutureBaseV2 = (
  futureBaseId: number,
  values: any,
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchFutureBaseV2(futureBaseId, values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const createMyDataDetailUnsavedFutureBase = (values: any = {}): AppThunk => async (
  dispatch,
) => {
  dispatch(postDetailUnsavedFutureBaseStart())
  postDetailUnsavedFutureBase(values)
    .then((response) => dispatch(postMyDataDetailUnsavedFutureBaseSuccess(response)))
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const createDetailUnsavedFutureBase = (
  values: any,
  callback?: () => void,
): AppThunk => async (dispatch) => {
  dispatch(postDetailUnsavedFutureBaseStart())
  postDetailUnsavedFutureBase(values)
    .then((response) => {
      dispatch(postDetailUnsavedFutureBaseSuccess(response))
      callback && callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const updateMainPlan = (
  futureBaseId: number,
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchMainPlan(futureBaseId)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const destroyFutureBase = (
  futureBaseId: number,
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  deleteFutureBase(futureBaseId)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const updateAdminFutureBasePublish = (
  values: any,
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchAdminFutureBasePublish(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedAdminCheck(error))
    })
}

export const updateAdminFutureBaseHide = (
  values: any,
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchAdminFutureBaseHide(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedAdminCheck(error))
    })
}

export const commonFutureBaseNewApis = (futureBaseId?: number): AppThunk => async (
  dispatch,
) => {
  dispatch(fetchFutureBasesV2())
  dispatch(fetchAccountFutureItems(futureBaseId))
}

export const fetchFutureBasesV2 = (): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  getFutureBasesV2()
    .then((response) => {
      dispatch(getFutureBasesV2Success(response))
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const fetchComparingFutureBase = (
  futureBaseId: number,
  callback: any,
): AppThunk => async (dispatch) => {
  dispatch(getFutureBaseStart())
  getFutureBase(futureBaseId)
    .then((response: FutureBaseResponse) => {
      dispatch(getComparingFutureBaseSuccess())
      callback(response)
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const updateResetFlg = (): AppThunk => async (dispatch) => {
  dispatch(resetMyDataDetailFutureBaseV2())
  patchInputPastData({input_past_data: { is_update_required: true}})
    .then(() => {
      // 特に何もしない
    })
    .catch((error) => {
      dispatch(unauthorizedCheck(error))
    })
}
