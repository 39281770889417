import React, { FC, useState } from 'react'
import clsx from 'clsx'
import CheckIcon from '@/assets/images/v2/check.svg'
import ChevronDownOrangeIcon from '@/assets/images/v2/chevron-down-orange.svg'
import ChevronRightIcon from '@/assets/images/v2/chevron-right.svg'
import ChevronRightOrangeIcon from '@/assets/images/v2/chevron-right-orange.svg'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import Link from '@/components/v2/atoms/Link'
import Typography from '@/components/v2/atoms/Typography'
import MoneytreeSkipModal from '@/components/v2/organisms/MoneytreeSkipModal'

export interface DashboardPanelStepperOption {
  /** 説明 */
  description: string
  /** ボタンラベル */
  buttonLabel: string
  /** アイコン */
  icon: JSX.Element
  /** true: 完了, false: 未完了 */
  isCompleted?: boolean
  /** onClickメソッド */
  onClick: () => void
}

interface DashboardPanelStepperProps {
  /** クラス名 */
  className?: string
  /** オプション */
  options: DashboardPanelStepperOption[]
  /** true: 管理画面, false: ユーザー画面 */
  isAdmin?: boolean
}

const DashboardPanelStepper: FC<DashboardPanelStepperProps> = (props) => {
  const { className, options, isAdmin } = props

  const [isMoneysolSkipModalOpen, setIsMoneysolSkipModalOpen] = useState<boolean>(false)

  const moneytreeButtonLabel = 'Moneytreeの連携'
  const linkColor = isAdmin ? 'text-black-400' : 'text-black-700'

  return (
    <div className={clsx(className, 'md:flex justify-center')}>
      {options.map((item, index) => (
        <React.Fragment key={index}>
          <div
            className={clsx(
              'flex flex-col w-[256px] md:w-[180px] lg:w-[256px] mx-[auto] lg:mx-0 py-[24px] px-[8px] rounded-[16px] bg-primary-50',
              {
                ['opacity-50']: item.isCompleted,
                ['relative']: item.buttonLabel == moneytreeButtonLabel,
              },
            )}
          >
            <div className="relative w-[148px] h-[148px] mx-[auto] mb-[8px] rounded-[80px] bg-white">
              <div className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]">
                {item.icon}
              </div>
              {item.isCompleted && (
                <div className="absolute right-[12px] w-[30px] h-[30px] rounded-[25px] bg-black-900">
                  <CheckIcon className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]" />
                </div>
              )}
            </div>

            <Typography className="pb-[8px] text-center text-[12px] text-black-700">
              {item.description.split(/(\n)/).map((text, index) => (
                <React.Fragment key={index}>
                  {text.match(/\n/) ? <br /> : text}
                </React.Fragment>
              ))}
            </Typography>

            <ButtonPrimary
              className="w-[188px] md:w-[160px] lg:w-[188px] h-[45px] mt-auto mx-auto px-[17px] md:px-[5px] lg:px-[17px]"
              isDisabled={item.isCompleted || isAdmin}
              onClick={item.onClick}
            >
              <Typography
                className="relative bottom-[2px] flex-1 text-[13px] text-white"
                isBold
              >
                {item.buttonLabel}
              </Typography>
              <ChevronRightIcon />
            </ButtonPrimary>

            {item.buttonLabel == moneytreeButtonLabel && (
              <Link
                className={`cursor-pointer pt-[8px] text-center text-[12px] absolute bottom-[5px] self-center ${linkColor}`}
                children="今は連携しない"
                onClick={() => setIsMoneysolSkipModalOpen(true)}
                isDisabled={isAdmin}
              />
            )}
          </div>

          {index !== options.length - 1 && (
            <>
              <ChevronRightOrangeIcon className="hidden md:block mx-[auto] mx-0 self-center" />
              <ChevronDownOrangeIcon className="md:hidden mx-[auto] mx-0 self-center" />
            </>
          )}
        </React.Fragment>
      ))}
      {isMoneysolSkipModalOpen && (
        <MoneytreeSkipModal
          isOpen={isMoneysolSkipModalOpen}
          onClose={() => {
            setIsMoneysolSkipModalOpen(false)
          }}
        />
      )}
    </div>
  )
}

export default DashboardPanelStepper
