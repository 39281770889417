import React, { FC, useEffect, useMemo, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Decorator } from 'final-form'
import { Form } from 'react-final-form'
import createDecorator from 'final-form-calculate'
import { isUndefined as _isUndefined, find as _find } from 'lodash'
import ChevronRightIcon from '@/assets/images/v2/chevron-right.svg'
import FamilyMonochromaticIcon from '@/assets/images/v2/family-monochromatic.svg'
import Button from '@/components/v2/atoms/Button'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import Grid from '@/components/v2/atoms/Grid'
import MarginContainer from '@/components/v2/atoms/MarginContainer'
import Paper from '@/components/v2/atoms/Paper'
import Typography from '@/components/v2/atoms/Typography'
import Breadcrumb from '@/components/v2/molecules/Breadcrumb'
import NumberField from '@/components/v2/molecules/NumberField'
import PaperTitle from '@/components/v2/molecules/PaperTitle'
import SelectBox from '@/components/v2/molecules/SelectBox'
import { setPageMeta } from '@/containers/pageMetasSlice'
import { fetchInputSalaries } from '@/containers/inputs/inputSalariesSlice'
import {
  createInputSubIncome,
  updateInputSubIncome,
} from '@/containers/inputs/inputSubIncomesSlice'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import { castNumberWithoutOperator } from '@/models/commonsModelFunc'
import { getUrlPersonFlg, convertPersonFlg } from '@/models/familyStructuresModelFunc'
import {
  INPUT_SUB_INCOME_TYPE,
  INPUT_SUB_INCOME_YEARLY_FIELDS,
} from '@/models/inputs/inputSubIncomesModelFunc'
import { InputSubIncomeCreateValues } from '@/models/inputs/inputSubIncomesModel'
import { RootState } from '@/store'
import {
  required,
  composeValidators,
  zeroOrMoreNumber,
  commaNumberMaxLength,
} from '@/utils/validate'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import { AccountContext } from '@/models/accountsModelFunc'
import { useParams } from 'react-router-dom'
import WholeSubmitErrorMessage from '@/components/v2/molecules/WholeSubmitErrorMessage'
import LoadingOverlay from '@/components/v1/atoms/LoadingOverlay'
import SubmitErrorMessage from '@/components/v2/molecules/SubmitErrorMessage'
import clsx from 'clsx'
import { updateResetFlg } from '@/containers/futures/futureBasesSlice'

const calculator: Decorator<any> = createDecorator({
  field: INPUT_SUB_INCOME_YEARLY_FIELDS,
  updates: {
    sub_income_yearly: (_, allValues: InputSubIncomeCreateValues) => {
      return (
        castNumberWithoutOperator(allValues.sub_income_annual_sales_yearly) -
        castNumberWithoutOperator(allValues.sub_income_expenses_yearly)
      )
    },
  },
})

const MyDataSubIncomeEdit: FC = () => {
  const dispatch = useDispatch()
  const { handleMovePage } = useCustomHistory()
  const { showFlashMsg } = useFlashAlert()
  const { isAdmin } = useContext(AccountContext)
  const { account_id } = useParams<{ account_id?: string }>()
  const { inputSalaries, isLoading } = useSelector(
    (state: RootState) => state.inputSalaries,
  )

  const personFlg = getUrlPersonFlg()
  const targetInputSalaries = useMemo(
    () => _find(inputSalaries, (item) => item.person_flg === personFlg),
    [inputSalaries],
  )

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'マイデータ | 副業 編集',
        description: 'マイデータ | 副業 編集',
        keyword: 'マイデータ | 副業 編集',
      }),
    )
    if (inputSalaries.length == 0) dispatch(fetchInputSalaries())
  }, [])

  const handleSubmit = (values: any) => {
    const isEdit = !_isUndefined(values.id)
    const newValues = {
      ...values,
      input_salary_id: targetInputSalaries?.id,
      person_flg: personFlg,
    }

    // 副業収入: なし
    if (values.sub_income_type === INPUT_SUB_INCOME_TYPE.inactive) {
      newValues.sub_income_annual_sales_yearly = 0
      newValues.sub_income_expenses_yearly = 0
      newValues.sub_income_yearly = 0
    }

    return new Promise((resolve) =>
      isEdit
        ? dispatch(
            updateInputSubIncome(newValues, () => {
              showFlashMsg(isEdit ? '更新しました' : '作成しました')
              dispatch(fetchInputSalaries())
              dispatch(updateResetFlg())
              handleMovePage(
                isAdmin
                  ? `/v2/accounts/${account_id}/myData/salaries`
                  : '/v2/myData/salaries',
              )
            }),
          )
        : dispatch(
            createInputSubIncome(
              newValues,
              () => {
                showFlashMsg(isEdit ? '更新しました' : '作成しました')
                dispatch(fetchInputSalaries())
                dispatch(updateResetFlg())
                handleMovePage(
                  isAdmin
                    ? `/v2/accounts/${account_id}/myData/salaries`
                    : '/v2/myData/salaries',
                )
              },
              resolve,
            ),
          ),
    )
  }

  return (
    <>
      <LoadingOverlay isLoading={isLoading} />
      <MarginContainer className="px-[8px] md:px-[32px] lg:px-0 py-[8px] md:py-[40px]">
        <Breadcrumb
          className="pb-[10px] md:pb-[32px]"
          data={[
            {
              name: 'マイデータ',
              url: isAdmin ? `/v2/accounts/${account_id}/myData` : '/v2/myData',
            },
            {
              name: 'マイデータ設定',
              url: isAdmin
                ? `/v2/accounts/${account_id}/myData/salaries`
                : '/v2/myData/salaries',
            },
            { name: '副業の設定' },
          ]}
        />

        <PaperTitle
          className="mb-[16px] md:mb-[40px]"
          title="副業"
          description="日々の収入や支出を登録することで改善案などのご提案が可能になります"
          icon={<FamilyMonochromaticIcon />}
          smallIcon={
            <FamilyMonochromaticIcon
              className="h-[82px] w-[130px]"
              viewBox="0 0 117 154"
            />
          }
        />

        <Paper className="bg-white p-24 md:p-40">
          <Typography
            className="mb-[32px] pb-[10px] text-[24px] md:text-[28px] text-black-800 border-b-2 border-black-300"
            isBold
          >
            {`${convertPersonFlg(personFlg)}の副業について`}
          </Typography>

          <Form
            onSubmit={handleSubmit}
            initialValues={targetInputSalaries?.input_sub_income ?? undefined}
            decorators={[calculator]}
            render={({ handleSubmit, errors, values, submitErrors, submitFailed }) => (
              <form onSubmit={handleSubmit}>
                <Grid className="pb-[64px] grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px]">
                  <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                    <Typography
                      className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                      isBold
                    >
                      副業収入
                    </Typography>
                    <SelectBox
                      name="sub_income_type"
                      placeholder="選択する"
                      options={[
                        { label: 'あり', value: INPUT_SUB_INCOME_TYPE.active },
                        { label: 'なし', value: INPUT_SUB_INCOME_TYPE.inactive },
                      ]}
                      isFullWidth
                      validate={required}
                    />
                  </div>

                  {values?.sub_income_type === INPUT_SUB_INCOME_TYPE.active && (
                    <>
                      <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                        <Typography
                          className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                          isBold
                        >
                          年商
                        </Typography>
                        <div className="flex flex-auto items-baseline">
                          <NumberField
                            name="sub_income_annual_sales_yearly"
                            isFullWidth
                            validate={composeValidators(
                              required,
                              zeroOrMoreNumber,
                              (value: any) => commaNumberMaxLength(value, 10),
                            )}
                          />
                          <Typography
                            className="pl-[8px] text-[14px] text-black-800 min-w-[fit-content]"
                            isBold
                          >
                            円/年
                          </Typography>
                        </div>
                      </div>

                      <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                        <Typography
                          className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                          isBold
                        >
                          年間経費
                        </Typography>
                        <div className="flex flex-auto items-baseline">
                          <NumberField
                            name="sub_income_expenses_yearly"
                            isFullWidth
                            validate={composeValidators(
                              required,
                              zeroOrMoreNumber,
                              (value: any) => commaNumberMaxLength(value, 10),
                            )}
                          />
                          <Typography
                            className="pl-[8px] text-[14px] text-black-800 min-w-[fit-content]"
                            isBold
                          >
                            円/年
                          </Typography>
                        </div>
                      </div>

                      <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                        <Typography
                          className="min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                          isBold
                        >
                          年間所得
                        </Typography>
                        <div className="flex flex-auto items-baseline">
                          <NumberField
                            name="sub_income_yearly"
                            isFullWidth
                            disabled
                            validate={composeValidators(required, (value: any) =>
                              commaNumberMaxLength(value, 10),
                            )}
                          />
                          <Typography
                            className="pl-[8px] text-[14px] text-black-800 min-w-[fit-content]"
                            isBold
                          >
                            円/年
                          </Typography>
                        </div>
                      </div>
                    </>
                  )}
                </Grid>

                <WholeSubmitErrorMessage submitErrors={submitErrors} />
                <SubmitErrorMessage show={!submitErrors && submitFailed} />
                <ButtonPrimary
                  className={clsx(
                    { ['opacity-50']: Object.keys(errors).length > 0 },
                    'w-[246px] h-[64px] mx-auto mb-[16px] ',
                  )}
                  isDisabled={isLoading}
                  onClick={() => handleSubmit(values)}
                >
                  <Typography
                    className="relative bottom-[2px] pl-[30px] text-[16px] text-white flex-auto"
                    isBold
                  >
                    保存する
                  </Typography>
                  <ChevronRightIcon className="mr-[17px]" />
                </ButtonPrimary>
                <Button
                  className="mx-auto text-[14px] text-black-700"
                  onClick={() =>
                    handleMovePage(
                      isAdmin
                        ? `/v2/accounts/${account_id}/myData/salaries`
                        : '/v2/myData/salaries',
                    )
                  }
                  isDisabled={isLoading}
                >
                  キャンセル
                </Button>
              </form>
            )}
          />
        </Paper>
      </MarginContainer>
    </>
  )
}

export default MyDataSubIncomeEdit
