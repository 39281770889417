import React, { FC, useContext, useEffect } from 'react'
import { useField, useForm } from 'react-final-form'
import {
  TWO_TYPES_YEAR_SELECT_ITEMS,
  MONTH_KANJI_SELECT_ITEMS,
} from '@/constants/formItem/commons'
import { DescriptionContext } from '@/hooks/useDescriptionTemplates'
import { API_FLAG, isNotEmptyValue, THIS_YEAR } from '@/models/commonsModelFunc'
import { buildStandardSelectItems } from '@/utils/formItem'
import { required } from '@/utils/validate'

import Typography from '@/components/v2/atoms/Typography'
import SelectBox from '@/components/v2/molecules/SelectBox'

const TransferContractUpdate: FC = () => {
  const { setStateSubmitButtonTooltipLabel } = useContext(DescriptionContext)
  const { change } = useForm()

  const {
    input: { value: transferUpdateType },
  } = useField('input_housing.input_housing_transfer_attributes.transfer_update_type')
  const isTransferUpdateTypeOn = Number(transferUpdateType) === API_FLAG.on
  const {
    input: { value: transferUpdateMonthAtYear },
  } = useField(
    `input_housing.input_housing_transfer_attributes.transfer_update_month_at_year`,
  )
  const {
    input: { value: transferUpdateMonthAtMonth },
  } = useField(
    `input_housing.input_housing_transfer_attributes.transfer_update_month_at_month`,
  )

  useEffect(() => {
    if (!isNotEmptyValue(transferUpdateType)) return setStateSubmitButtonTooltipLabel('')
    if (!isTransferUpdateTypeOn) return setStateSubmitButtonTooltipLabel('')
    if (
      isNotEmptyValue(transferUpdateMonthAtYear) &&
      isNotEmptyValue(transferUpdateMonthAtMonth)
    )
      return setStateSubmitButtonTooltipLabel('')

    setStateSubmitButtonTooltipLabel('更新の年月を設定してください。')
  }, [transferUpdateType, transferUpdateMonthAtYear, transferUpdateMonthAtMonth])

  const handleChangeTransferUpdateType = (value: string) => {
    change('input_housing.input_housing_transfer_attributes.transfer_update_type', value)
  }

  return (
    <>
      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
        <Typography
          className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
          isBold
        >
          次回の更新月の設定
        </Typography>
        <SelectBox
          name="input_housing.input_housing_transfer_attributes.transfer_update_type"
          placeholder="選択する"
          isFullWidth
          options={[
            { label: '設定する', value: String(API_FLAG.on) },
            { label: '設定しない', value: String(API_FLAG.off) },
          ]}
          validate={required}
        />
      </div>

      {isTransferUpdateTypeOn && (
        <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
          <Typography
            className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
            isBold
          >
            次回の更新月
          </Typography>
          <div className="md:flex flex-auto items-baseline">
            <SelectBox
              className="mb-[10px] lg:mb-0"
              name="input_housing.input_housing_transfer_attributes.transfer_update_month_at_year"
              isFullWidth
              isPlaceholderHidden
              options={buildStandardSelectItems(
                TWO_TYPES_YEAR_SELECT_ITEMS,
                THIS_YEAR,
                '年を選択',
              )}
              validate={required}
              onChange={() => handleChangeTransferUpdateType(String(API_FLAG.on))}
            />
            <SelectBox
              className="lg:ml-[10px]"
              name="input_housing.input_housing_transfer_attributes.transfer_update_month_at_month"
              placeholder="月を選択"
              isFullWidth
              options={MONTH_KANJI_SELECT_ITEMS}
              validate={required}
              onChange={() => handleChangeTransferUpdateType(String(API_FLAG.on))}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default TransferContractUpdate
