import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { unauthorizedCheck } from '../accountsSlice'
import {
  postFutureCarV2,
  patchFutureCarV2,
  deleteFutureCarV2,
} from '../../api/futures/futureCars'
import { FutureCar, FutureCarUpdateValues } from '../../models/futures/futureCarsModel'
import { AppThunk } from '../../store'

interface FutureCarState {
  futureCars: FutureCar[]
  isLoading: boolean
}

const initialState: FutureCarState = {
  futureCars: [],
  isLoading: false,
}

const futureCarsSlice = createSlice({
  name: 'futureCars',
  initialState,
  reducers: {
    setFutureCars: (state, action: PayloadAction<FutureCar[]>) => {
      state.futureCars = action.payload
      return state
    },
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
  },
})

export const {
  setFutureCars,
  requestStart,
  requestSuccess,
  requestFailure,
} = futureCarsSlice.actions
export const futureCarsReducer = futureCarsSlice.reducer

export const createFutureCarV2 = (values: any, callback: () => void): AppThunk => async (
  dispatch,
) => {
  dispatch(requestStart())
  postFutureCarV2(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const updateFutureCarV2 = (
  values: FutureCarUpdateValues,
  id: number,
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchFutureCarV2(values, id)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const destroyFutureCarV2 = (
  values: any,
  id: number,
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  deleteFutureCarV2(values, id)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}
