import React, { FC, ChangeEvent } from 'react'
import { Field } from 'react-final-form'
import { defaultTo as _defaultTo } from 'lodash'
import ValidMessage from '@/components/v2/atoms/ValidMessage'
import clsx from 'clsx'

export interface RadioData {
  /** ラジオボタンに表示するラベルテキスト */
  label?: string | JSX.Element
  /** ラジオボタンのValue値 */
  value: any
  /** true: 非活性, false: 活性 */
  disabled?: boolean
}

interface RadioGroupProps {
  /** name属性 */
  name: string
  /** 横並びに表示するか */
  isDispRow?: boolean
  /** 無効化するか */
  disabled?: boolean
  /** 各ラジオボタンの情報 */
  datas: RadioData[]
  /** Changeイベントメソッド */
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  /** バリデーションメソッド */
  validate?: (value: any) => void
}

const RadioGroup: FC<RadioGroupProps> = (props) => {
  const { name, isDispRow, disabled, datas, onChange, validate } = props

  return (
    <>
      <div className={clsx({ 'flex space-x-24': isDispRow, 'space-y-24': !isDispRow })}>
        {datas.map((item, index: number) => (
          <Field
            key={index}
            type="radio"
            name={name}
            value={item.value}
            validate={validate}
          >
            {(props) => {
              const { input } = props

              const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
                input.onChange(event)
                onChange?.(event)
              }

              return (
                <label className="flex items-center">
                  <input
                    {...input}
                    className="w-20 h-20 mr-10"
                    disabled={_defaultTo(item.disabled, disabled)}
                    onChange={handleChange}
                  />
                  {item.label}
                </label>
              )
            }}
          </Field>
        ))}
      </div>
      <Field name={name} subscription={{ error: true, touched: true }}>
        {({ meta }) => <ValidMessage meta={meta} />}
      </Field>
    </>
  )
}

export default RadioGroup
