import ApiClient from '@/api/apiClient'
import {
  AgentCompany,
  AgentCompanyPaymentHistoryResponse,
  AgentCompanyCreateValues,
  AgentCompanyUpdateValues,
} from '@/models/admins/AgentCompaniesModel'

export const getAgentCompanies = async () => {
  return await ApiClient.get('/agent_companies', (response: AgentCompany[]) => response)
}

export const getAgentCompanyPaymentHistories = async () => {
  return await ApiClient.get(
    '/agent_company_payment_histories',
    (response: AgentCompanyPaymentHistoryResponse[]) => response,
  )
}

export const postAgentCompany = async (values: AgentCompanyCreateValues) => {
  return await ApiClient.post('/agent_companies', () => {}, values)
}

export const patchAgentCompany = async (values: AgentCompanyUpdateValues) => {
  return await ApiClient.patch(
    `/agent_companies/${values.agent_company.id}`,
    () => {},
    values,
  )
}
