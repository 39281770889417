import React, { FC, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Prompt, useParams } from 'react-router-dom'
import { Location, Action } from 'history'
import Button from '@/components/v2/atoms/Button'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import ButtonBlack from '@/components/v2/atoms/ButtonBlack'
import Typography from '@/components/v2/atoms/Typography'
import LoadingOverlay from '@/components/v1/atoms/LoadingOverlay'
import Modal from '@/components/v2/atoms/Modal'
import HelpCircleTertiaryIcon from '@/assets/images/v2/help-circle-tertiary.svg'
import {
  fetchAccount,
  updateAccountHasCreatedFirstPlanV2,
} from '@/containers/accountsSlice'
import { setPageMeta } from '@/containers/pageMetasSlice'
import { HANDLER_TYPE } from '@/constants/formItem/futures/futureBases'
import {
  commonFutureBaseNewApis,
  destroyFutureBase,
} from '@/containers/futures/futureBasesSlice'
import { fetchFamilyStructureV2 } from '@/containers/familyStructuresSlice'
import { fetchInputAssetSummary } from '@/containers/inputs/inputAssetsSlice'
import { isNotEmptyValue } from '@/models/commonsModelFunc'
import { SimulationDetailEditContext } from '@/models/futures/futureBasesModelFunc'
import { RootState } from '@/store'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import SimulationPageGuidanceModal from '@/templates/v2/guidances/SimulationPageGuidanceModal'
import SimulationGraphBody from '@/templates/v2/simulations/_simulationGraphBodyUser'
import SimulationSummaryBody from '@/templates/v2/simulations/_simulationSummaryBody'
import SimulationTableBody from '@/templates/v2/simulations/_simulationTableBody'
import SimulationPanel from '@/templates/v2/simulations/_simulationPanelUser'
import ConfirmMydataTable from '@/templates/v2/simulations/_confirmMydataTable'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import { find as _find } from 'lodash'
import ChevronDownIcon from '@/assets/images/v2/chevron-down-white.svg'
import ChevronUpIcon from '@/assets/images/v2/chevron-up-white.svg'

const SimulationEdit: FC = () => {
  const dispatch = useDispatch()
  const { handleMovePage } = useCustomHistory()
  const { future_base_id } = useParams<{ future_base_id?: string }>()
  const { showFlashMsg } = useFlashAlert()

  const [isCompare, setIsCompare] = useState<boolean>(false)
  const [isCompareMyData, setIsCompareMyData] = useState<boolean>(true)
  const [isOpenRegisterModal, setIsOpenRegisterModal] = useState<boolean>(false)
  const [hasCheckedCreateGuidance, setHasCheckedCreateGuidance] = useState<boolean>(false)
  const [isOpenGuidanceModal, setIsOpenGuidanceModal] = useState<boolean>(false)
  const [isOpenPanelGuidanceModal, setIsOpenPanelGuidanceModal] = useState<boolean>(false)
  const [isShowInsurance, setIsShowInsurance] = useState<boolean>(false)
  const [isOpenBeforeCreateGuidanceModal, setIsOpenBeforeCreateGuidanceModal] = useState<
    boolean
  >(false)
  const [isOpenAfterCreateGuidanceModal, setIsOpenAfterCreateGuidanceModal] = useState<
    boolean
  >(false)
  const [simulationTitle, setSimulationTitle] = useState<string>(
    '新規シミュレーションプラン',
  )
  const [isGraphLoading, setIsGraphLoading] = useState<boolean>(false)

  // confirm navigation states
  const [isOpenConfirmedNavigationModal, setIsOpenConfirmedNavigationModal] = useState<
    boolean
  >(false)
  const [confirmedNavigation, setConfirmedNavigation] = useState(true)
  const [lastLocation, setLastLocation] = useState<Location | null>(null)
  const [handleMoveURLAfterSave, setHandleMoveURLAfterSave] = useState<string | null>(
    null,
  )

  const [isOpenDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [isOpenHeaderAccordion, setOpenHeaderAccordion] = useState<boolean>(false)
  const [isOpenEditConfirmModal, setOpenEditConfirmModal] = useState<boolean>(false)
  const [confirmEdit, setConfirmEdit] = useState<boolean>(false)
  const [isOpenConfirmMydataModal, setOpenConfirmMydataModal] = useState<boolean>(true)

  const {
    account,
    futureItems,
    isLoading: isAccountLoading,
    isGetAccountShowInputLoading,
  } = useSelector((state: RootState) => state.accounts)

  const {
    simulationInfo,
    futureBasesV2,
    isLoading: isFutureBaseLoading,
    isGetDetailFutureBaseLoading,
  } = useSelector((state: RootState) => state.futureBases)

  const handleBlockedNavigation = (nextLocation: Location, _: Action): boolean => {
    if (!confirmedNavigation) {
      setIsOpenConfirmedNavigationModal(true)
      setLastLocation(nextLocation)
      return false
    }
    return true
  }

  const handleDeletePlan = () => {
    if (future_base_id == null) return
    dispatch(
      destroyFutureBase(Number(future_base_id), () => {
        handleMovePage('/v2/simulations')
        setConfirmedNavigation(true)
        showFlashMsg('プランを削除しました')
      }),
    )
  }

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'シミュレーションセット編集',
        description: 'シミュレーションセット編集',
        keyword: 'シミュレーションセット編集',
      }),
    )
    const numFutureBaseId = future_base_id != null ? Number(future_base_id) : undefined
    dispatch(commonFutureBaseNewApis(numFutureBaseId))
    dispatch(fetchFamilyStructureV2())
    dispatch(fetchInputAssetSummary())
  }, [])

  useEffect(() => {
    const item = sessionStorage.getItem('confirmMydataState')
    if (item != null) {
      const confirmMydataState = JSON.parse(item)
      if (confirmMydataState.confirmed) setOpenConfirmMydataModal(false)
      sessionStorage.removeItem('confirmMydataState')
    }
  }, [])

  useEffect(() => {
    if (future_base_id != null) {
      const item = _find(futureBasesV2, { id: Number(future_base_id) })
      if (item != null) setSimulationTitle(item.future_name)
    }
  }, [futureBasesV2])

  useEffect(() => {
    if (isAccountLoading || isFutureBaseLoading) return

    if (
      !account['has_created_first_plan'] &&
      Number(simulationInfo.future_item_count) > 0
    ) {
      dispatch(updateAccountHasCreatedFirstPlanV2(() => dispatch(fetchAccount())))
      return
    }

    if (!account['has_created_first_plan']) {
      setIsOpenGuidanceModal(true)
    }
  }, [account['has_created_first_plan'], simulationInfo.future_item_count])

  useEffect(() => {
    if (isFutureBaseLoading) return
    if (
      !isNotEmptyValue(account['has_created_first_plan']) &&
      !account['has_created_first_plan']
    )
      return

    if (
      simulationInfo.future_item_count == 1 &&
      !hasCheckedCreateGuidance &&
      !account['is_done_with_steps?']
    ) {
      setIsOpenAfterCreateGuidanceModal(true)
    }
  }, [simulationInfo, hasCheckedCreateGuidance])

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      handleMovePage(lastLocation.pathname + lastLocation.search)
    }
  }, [confirmedNavigation, lastLocation])

  const isMainPlan = useMemo(() => {
    if (future_base_id != null) {
      const item = _find(futureBasesV2, { id: Number(future_base_id) })
      if (item != null) return item.is_main
    }
    return false
  }, [futureBasesV2])

  const isCreatedByFp = useMemo(() => {
    if (future_base_id != null) {
      const item = _find(futureBasesV2, { id: Number(future_base_id) })
      if (item != null) return item.handler_type === HANDLER_TYPE.fp
    }
    return false
  }, [futureBasesV2])

  const mydateDate = useMemo(() => {
    if (future_base_id != null) {
      const item = _find(futureBasesV2, { id: Number(future_base_id) })
      if (item != null) return item.updated_at
    }
    return ''
  }, [futureBasesV2])

  return (
    <SimulationDetailEditContext.Provider
      value={{ isManipulatedApproval: futureItems?.handler_type === HANDLER_TYPE.user }}
    >
      <LoadingOverlay
        isLoading={
          !isGraphLoading &&
          (isAccountLoading || isFutureBaseLoading || isGetDetailFutureBaseLoading)
        }
      />
      <div className="md:hidden sticky top-0 z-50">
        <div className="flex flex-col gap-y-[12px] bg-primary-50 pt-[6px] pb-[12px]">
          <div className="flex flex-row justify-between items-baseline px-[12px]">
            <div className="flex flex-row items-baseline gap-x-[6px]">
              {isMainPlan && (
                <Typography
                  className="leading-normal text-[12px] text-white border-[1px] rounded-2xl bg-tertiary-500 px-[12px] py-[2px] whitespace-nowrap"
                  isBold
                >
                  目標プラン
                </Typography>
              )}

              <Typography
                className={`leading-normal text-[14px] text-black-900 overflow-hidden whitespace-nowrap overflow-ellipsis ${!isMainPlan ? "w-[250px]" : "w-[200px]"}`}
                isBold
              >
                {simulationTitle}
              </Typography>
            </div>
            <ButtonPrimary
              onClick={() => {
                setIsOpenRegisterModal(true)
                setHandleMoveURLAfterSave(null)
              }}
              className="px-[16px] py-[6px]"
            >
              <Typography
                className="leading-normal text-[12px] text-white whitespace-nowrap"
                isBold
              >
                保存
              </Typography>
            </ButtonPrimary>
          </div>

          {isOpenHeaderAccordion && (
            <>
              <Button
                onClick={() => {
                  handleMovePage('/v2/simulations/new?id=' + future_base_id)
                }}
              >
                <Typography className="leading-normal text-[14px] text-black-900 whitespace-nowrap self-center">
                  複製
                </Typography>
              </Button>
              <Button
                onClick={() => {
                  setOpenDeleteModal(true)
                }}
              >
                <Typography className="leading-normal text-[14px] text-black-900 whitespace-nowrap self-center">
                  削除
                </Typography>
              </Button>
            </>
          )}
        </div>
        <div className="absolute top-105 left-50% -translate-x-50% w-[40px] h-[15px] bg-[#A8C575] rounded-b-[9px] px-[10px]">
          <Button
            onClick={() => {
              setOpenHeaderAccordion(!isOpenHeaderAccordion)
            }}
          >
            {isOpenHeaderAccordion ? (
              <ChevronUpIcon viewBox="0 3 20 20" />
            ) : (
              <ChevronDownIcon viewBox="0 3 20 20" />
            )}
          </Button>
        </div>
      </div>

      <div className="hidden md:flex flex-row justify-between px-24 py-12 sticky top-0 z-50 bg-[#F5F5F5]">
        <div className="flex flex-row items-baseline gap-x-24">
          <Button
            onClick={() => {
              handleMovePage('/v2/simulations')
            }}
          >
            <Typography
              className="leading-normal text-[14px] text-secondary-500 whitespace-nowrap"
              isBold
            >
              戻る
            </Typography>
          </Button>

          {isMainPlan && (
            <Typography
              className="leading-normal text-[12px] text-white border-[1px] rounded-2xl bg-tertiary-500 px-[12px] py-[2px] whitespace-nowrap"
              isBold
            >
              目標プラン
            </Typography>
          )}

          <input
            type="text"
            className="w-[382px] h-[37px] px-16 py-8 border border-black-300 rounded-sm text-black-900 text-[14px] leading-normal"
            value={simulationTitle}
            onChange={(event) => setSimulationTitle(event.target.value)}
          ></input>

          <div className="flex gap-x-12">
            <ButtonPrimary
              onClick={() => {
                handleMovePage('/v2/simulations/new?id=' + future_base_id)
              }}
              className="px-16 py-8"
            >
              <Typography
                className="leading-normal text-[14px] text-white whitespace-nowrap"
                isBold
              >
                複製する
              </Typography>
            </ButtonPrimary>
            <Button
              onClick={() => {
                setOpenDeleteModal(true)
              }}
              className={
                'px-16 py-8 rounded-8 ' + (isMainPlan || isCreatedByFp ? 'bg-black-300' : 'bg-alert-600')
              }
            >
              <Typography
                className={
                  'leading-normal text-[14px] whitespace-nowrap ' +
                  (isMainPlan || isCreatedByFp ? 'text-black-500' : 'text-white')
                }
                isBold
              >
                削除する
              </Typography>
            </Button>
          </div>
        </div>
        <div>
          <ButtonPrimary
            onClick={() => {
              setIsOpenRegisterModal(true)
              setHandleMoveURLAfterSave(null)
            }}
            className="px-16 py-8"
          >
            <Typography
              className="leading-normal text-[14px] text-white whitespace-nowrap"
              isBold
            >
              保存する
            </Typography>
          </ButtonPrimary>
        </div>
      </div>

      <SimulationPanel
        isOpenRegisterModal={isOpenRegisterModal}
        onCloseRegisterModal={() => {
          setIsOpenRegisterModal(false)
        }}
        futureName={simulationTitle}
        setFutureName={setSimulationTitle}
        futureBaseId={future_base_id == null ? null : Number(future_base_id)}
        handleMovePage={(url: string) => {
          handleMovePage(url)
          setConfirmedNavigation(true)
        }}
        handleMoveURLAfterSave={handleMoveURLAfterSave}
        isEdit={true}
        confirmEdit={confirmEdit}
        openEditConfirmModal={() => setOpenEditConfirmModal(true)}
      />

      <div>
        <div className="p-[10px] md:p-[30px] bg-white">
          <div className="flex items-center gap-x-[18px] mb-[12px] pb-[10px] border-b-2 border-black-300">
            <Typography
              className="text-[19px] md:text-[28px] text-black-800 hidden md:block"
              isBold
            >
              シミュレーションプラン編集
            </Typography>
            <Typography
              className="text-[19px] md:text-[28px] text-black-800 block md:hidden"
              isBold
            >
              シミュレーション編集
            </Typography>
            <div className="flex items-center">
              <Button
                onClick={() => {
                  setIsOpenBeforeCreateGuidanceModal(true)
                }}
              >
                <HelpCircleTertiaryIcon />
                <Typography className="text-[12px] text-tertiary-500 border-b border-tertiary-500">
                  ガイダンスを見る
                </Typography>
              </Button>
            </div>
          </div>
          <Typography className="leading-normal text-[14px] text-black-900 mb-[12px]">
            設定パネルから将来の予定を追加しましょう。
            <br />
            追加した設定を反映しながら、グラフと表を理想の将来像に近づけていきましょう！
          </Typography>
          <SimulationGraphBody
            className="pb-[80px]"
            isCompare={isCompare}
            isCompareMyData={isCompareMyData}
            setIsCompare={setIsCompare}
            setIsCompareMyData={setIsCompareMyData}
            setIsShowInsurance={setIsShowInsurance}
            setIsGraphLoading={setIsGraphLoading}
          />
          <SimulationSummaryBody className="pb-[80px]" />
          <SimulationTableBody
            isCompare={isCompare}
            isCompareMyData={isCompareMyData}
            isShowInsurance={isShowInsurance}
          />
        </div>
      </div>

      <SimulationPageGuidanceModal
        isOpen={isOpenBeforeCreateGuidanceModal}
        onClose={() => setIsOpenBeforeCreateGuidanceModal(false)}
      />

      <Prompt when={true} message={handleBlockedNavigation} />
      <Modal
        className="md:w-[620px] w-[95%]"
        isOpen={isOpenConfirmedNavigationModal}
        onClose={() => {
          setIsOpenConfirmedNavigationModal(false)
        }}
      >
        <div className="md:px-[54px] md:py-[43px]">
          <Typography className="leading-normal text-[14px] text-black-900 mb-[38px]">
            編集中の内容が保存されていません。
            <br />
            本当に移動してよろしいですか？
          </Typography>

          <div className="flex flex-col gap-y-[24px] items-center md:flex-row md:gap-x-[40px] md:justify-center">
            <ButtonBlack
              className="w-[188px] h-[45px]"
              onClick={() => {
                setIsOpenConfirmedNavigationModal(false)
                setConfirmedNavigation(true)
              }}
            >
              <Typography
                className="flex-auto pl-30 leading-normal text-[14px] text-black-400 whitespace-nowrap"
                isBold
              >
                保存せずに移動する
              </Typography>
              <i className="ico chevron-right text-24 mr-18 text-black-400" />
            </ButtonBlack>
            <ButtonPrimary
              className="w-[188px] h-[45px]"
              onClick={() => {
                setIsOpenConfirmedNavigationModal(false)
                setIsOpenRegisterModal(true)
                setHandleMoveURLAfterSave(
                  lastLocation ? lastLocation.pathname + lastLocation.search : null,
                )
              }}
            >
              <Typography
                className="flex-auto pl-30 leading-normal text-[14px] text-white whitespace-nowrap"
                isBold
              >
                保存して移動する
              </Typography>
              <i className="ico chevron-right text-24 mr-18 text-white" />
            </ButtonPrimary>
          </div>
        </div>
      </Modal>

      <Modal
        className="md:w-[620px] w-[95%]"
        isOpen={isOpenDeleteModal}
        onClose={() => {
          setOpenDeleteModal(false)
        }}
      >
        <div className="flex flex-col gap-34 items-center md:m-20">
          {isCreatedByFp ? (
            <Typography className="leading-normal text-[14px]">
              FPが作成したプランは削除できません。
            </Typography>
          ) : !isMainPlan ? (
            <Typography className="leading-normal text-[14px]">
              シミュレーションを削除すると、データを戻すことができません。
              <br />
              本当に削除してよろしいですか？
            </Typography>
          ): (
            <Typography className="leading-normal text-[14px]">
              目標に設定されているプランは削除できません。
              <br />
              削除を行いたい場合は、目標プランを変更してください。
            </Typography>
          )}
          <div
            className={
              'flex flex-row gap-40 justify-center' + (isMainPlan ? ' mx-80' : '')
            }
          >
            <ButtonBlack
              className="h-45 w-full md:w-188 text-black-400"
              onClick={() => setOpenDeleteModal(false)}
            >
              <Typography className="flex-auto pl-30 leading-normal text-14 whitespace-nowrap">
                戻る
              </Typography>
              <i className="ico chevron-right text-24 mr-18" />
            </ButtonBlack>
            {!isMainPlan && !isCreatedByFp && (
              <Button
                className="h-45 w-full md:w-188 text-white bg-alert-600 rounded-8"
                onClick={handleDeletePlan}
              >
                <Typography className="flex-auto pl-30 leading-normal text-14 whitespace-nowrap">
                  削除する
                </Typography>
                <i className="ico chevron-right text-24 mr-18" />
              </Button>
            )}
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={isOpenConfirmMydataModal}
        onClose={() => setOpenConfirmMydataModal(false)}
        className="md:w-[620px] w-[95%]"
      >
        <LoadingOverlay isLoading={isGetAccountShowInputLoading} />
        <div className="flex flex-col gap-34 items-center md:m-20">
          <Typography className="leading-normal text-[14px]">
            マイデータは最新の情報に更新されていますか？
            <br /> <br />「{simulationTitle}」のシミュレーション結果は、
            {mydateDate}時点のマイデータをベースに算出されています。
            <br /> <br />
            より正確なシミュレーション結果を出すために、
            ご状況に変化があった場合は、マイデータを更新してください。
          </Typography>

          <ConfirmMydataTable />

          <div className="flex flex-col md:flex-row gap-20 md:gap-40 justify-center">
            <ButtonBlack
              className="w-[188px] py-[6px]"
              onClick={() => setOpenConfirmMydataModal(false)}
            >
              <Typography
                className="flex-auto pl-30 leading-normal text-[14px] text-black-400 whitespace-nowrap"
                isBold
              >
                マイデータ設定を <br /> 確認・編集せずに進む
              </Typography>
              <i className="ico chevron-right text-24 mr-18 text-black-400" />
            </ButtonBlack>
            <ButtonPrimary
              className="w-[188px] py-[6px]"
              onClick={() => {
                handleMovePage(
                  `/v2/myData/families?future_base_id=${future_base_id}&action=edit`,
                )
                setConfirmedNavigation(true)
              }}
            >
              <Typography
                className="flex-auto pl-30 leading-normal text-[14px] text-white whitespace-nowrap"
                isBold
              >
                マイデータ設定を <br /> 確認・編集する
              </Typography>
              <i className="ico chevron-right text-24 mr-18 text-white" />
            </ButtonPrimary>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={isOpenEditConfirmModal}
        onClose={() => {
          setOpenEditConfirmModal(false)
        }}
        className="md:w-[620px] w-[95%]"
      >
        <div className="flex flex-col gap-34 items-center md:m-20">

          {isCreatedByFp ? (
            <Typography className="leading-normal text-[14px]">
              FPが作成したプランは編集できません。
              <br />
              「プランを複製して編集する」をクリックし、複製されたプランを編集してください。
            </Typography>
          ) : (
            <Typography className="leading-normal text-[14px]">
              本当にプランの編集を行いますか？
              <br /> <br />
              シミュレーション結果は、作成時点のマイデータをベースに算出されています。
              <br />
              編集を行うと、作成時点のデータが、現時点のマイデータに上書きされます。
              <br /> <br />
              作成時点のデータを残したまま新しいプラン作成する場合は、
              「プランを複製して編集する」をクリックしてください。
            </Typography>
          )}

          <div className="flex flex-col md:flex-row gap-20 md:gap-40 justify-center">
            {!isCreatedByFp && (
              <ButtonPrimary
                className="w-[188px] h-[45px]"
                onClick={() => {
                  setConfirmEdit(true)
                  setConfirmedNavigation(false)
                  setOpenEditConfirmModal(false)
                }}
              >
                <Typography
                  className="flex-auto pl-30 leading-normal text-[14px] text-white whitespace-nowrap"
                  isBold
                >
                  上書きして編集する
                </Typography>
                <i className="ico chevron-right text-24 mr-18 text-white" />
              </ButtonPrimary>
            )}

            <ButtonPrimary
              className="w-[188px] h-[45px]"
              onClick={() => {
                handleMovePage('/v2/simulations/new?id=' + future_base_id)
                setConfirmedNavigation(true)
              }}
            >
              <Typography
                className="flex-auto pl-30 leading-normal text-[14px] text-white whitespace-nowrap"
                isBold
              >
                プランを複製して
                <br /> 編集する
              </Typography>
              <i className="ico chevron-right text-24 mr-18 text-white" />
            </ButtonPrimary>
          </div>

          {isCreatedByFp && (
            <Button
              onClick={() => {
                setOpenEditConfirmModal(false)
              }}
            >
              <Typography
                className="leading-normal text-[14px] text-secondary-500 whitespace-nowrap"
                isBold
              >
                戻る
              </Typography>
            </Button>
          )}

        </div>
      </Modal>
    </SimulationDetailEditContext.Provider>
  )
}

export default SimulationEdit
