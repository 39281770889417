import ApiClient from '../apiClient'
import { FutureHousingSaleLoanYearly } from '@/models/futures/futureHousingSalesModel'

export const postFutureHousingSaleLoanYearly = async (values: any) => {
  return await ApiClient.post(
    '/future_housing_sales/show_loan_yearly',
    (response: FutureHousingSaleLoanYearly) => response,
    values,
  )
}

export const postFutureHousingSaleV2 = async (values: any) => {
  return await ApiClient.post(
    `/future_housing_sales`,
    (response: any) => response,
    values,
    undefined,
    'v2',
  )
}

export const patchFutureHousingSaleV2 = async (values: any, id: number) => {
  return await ApiClient.patch(
    `/future_housing_sales/${id}`,
    () => {},
    values,
    undefined,
    'v2',
  )
}

export const deleteFutureHousingSaleV2 = async (id: number) => {
  return await ApiClient.delete(
    `/future_housing_sales/${id}`,
    () => {},
    {},
    undefined,
    'v2',
  )
}
