import LocalStorage from './index'

const KEY = 'authHeaders'

export const getStorageAuthHeaders = () => {
  return LocalStorage.getItem(KEY)
}

export const setStorageAuthHeaders = (token: string) => {
  const authHeaders = { Authorization: `Token token=${token}` }
  return LocalStorage.setItem(KEY, authHeaders)
}

export const removeStorageAuthHeaders = () => {
  LocalStorage.removeItem(KEY)
}
