import React, { FC, useEffect, useMemo, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Typography from '@/components/v2/atoms/Typography'
import { setPageMeta } from '@/containers/pageMetasSlice'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import { RootState } from '@/store'
import MyDataListWrapper from '@/templates/v2/myData/_ListWrapper'
import Button from '@/components/v2/atoms/Button'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import { castNumberWithoutOperator, convertLocaleString } from '@/models/commonsModelFunc'
import { fetchInputCustomItemV2 } from '@/containers/inputs/inputCustomItemsSlice'
import {
  split as _split,
  includes as _includes,
  filter as _filter,
  flatten as _flatten,
} from 'lodash'
import {
  convertCategory,
  convertFrequency,
  CUSTOM_ITEM_CATEGORY,
  CUSTOM_ITEM_EXPENSE_GROUP,
  CUSTOM_ITEM_INCOME_GROUP,
  PAYMENT_MODE,
} from '@/models/inputs/inputCustomItemsModelFunc'
import Chip from '@/components/v2/atoms/Chip'
import { useParams } from 'react-router-dom'
import { AccountContext } from '@/models/accountsModelFunc'
import { useAccountControl } from '@/hooks/useAccountControl'
import moment from 'moment'

const MyDataCustomItem: FC = () => {
  const dispatch = useDispatch()
  const { handleMovePage } = useCustomHistory()
  const { isMyDataManipulatedApproval } = useAccountControl()
  const { isAdmin } = useContext(AccountContext)
  const { account_id } = useParams<{ account_id?: string }>()

  const { inputCustomItems } = useSelector((state: RootState) => state.inputCustomItems)

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'マイデータ | カスタム設定',
        description: 'マイデータ | カスタム設定',
        keyword: 'マイデータ | カスタム設定',
      }),
    )

    if (inputCustomItems.length == 0) dispatch(fetchInputCustomItemV2())
  }, [])

  const inputCustomIncomeItems = useMemo(
    () =>
      _flatten(
        CUSTOM_ITEM_INCOME_GROUP.map((category: any) =>
          _filter(inputCustomItems, { category, is_active: true }),
        ),
      ),
    [inputCustomItems],
  )

  const inputCustomExpenseItems = useMemo(
    () =>
      _flatten(
        CUSTOM_ITEM_EXPENSE_GROUP.map((category: any) =>
          _filter(inputCustomItems, { category, is_active: true }),
        ),
      ),
    [inputCustomItems],
  )

  const inputCustomItemUpdatedAt = useMemo(() => {
    const unixTimes = inputCustomItems.map((item) =>
      Number(moment(item?.updated_at).format('X')),
    )
    return unixTimes.length !== 0
      ? `(最終更新日: ${moment(String(Math.max(...unixTimes)), 'X').format(
          'YYYY/MM/DD',
        )})`
      : undefined
  }, [inputCustomItems])

  return (
    <MyDataListWrapper
      title="カスタム設定"
      description=""
      buttonTitle="一括編集する"
      onClick={
        inputCustomIncomeItems.length > 0
          ? () =>
              handleMovePage(
                isAdmin
                  ? `/v2/accounts/${account_id}/myData/customItems/edit`
                  : '/v2/myData/customItems/edit',
              )
          : undefined
      }
      updatedAt={inputCustomItemUpdatedAt}
    >
      {inputCustomIncomeItems.length === 0 && (
        <div>
          <Typography
            className="pb-[15px] md:pb-[24px] text-[15px] md:text-[18px] text-center"
            isBold
          >
            カスタム設定が登録されていません。
            <br className="md:hidden" />
            下記のボタンから登録してみましょう。
          </Typography>
          <Button
            className="w-[104px] md:w-[112px] h-[26px] md:h-[37px] rounded-[4px] mx-auto bg-white border border-secondary-500 hover:bg-secondary-50"
            isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
            onClick={() =>
              handleMovePage(
                isAdmin
                  ? `/v2/accounts/${account_id}/myData/customItems/edit`
                  : '/v2/myData/customItems/edit',
              )
            }
          >
            <Typography
              className="relative bottom-[1px] text-[12px] md:text-[14px] text-secondary-500"
              isBold
            >
              新しく追加する
            </Typography>
          </Button>
        </div>
      )}

      {inputCustomIncomeItems.length > 0 && (
        <>
          <div className="mt-30">
            <CircleTitle title="収入" />
            <table className="w-[100%] md:w-[100%] text-[12px] md:text-[14px]">
              <tbody>
                <tr className="hidden md:table-row h-[45px] md:odd:bg-black-100">
                  <th className="text-left px-[16px]">
                    <Typography isBold>項目名</Typography>
                  </th>
                  <th className="text-left px-[16px]">
                    <Typography isBold>科目</Typography>
                  </th>
                  <th className="text-left px-[16px]">
                    <Typography isBold>適用時期</Typography>
                  </th>
                  <th className="text-left px-[16px]">
                    <Typography isBold>金額</Typography>
                  </th>
                  <th className="text-left px-[16px]">
                    <Typography isBold>年間変動率</Typography>
                  </th>
                </tr>

                {inputCustomIncomeItems.map((item: any, index: number) => {
                  return (
                    <>
                      <div className="md:hidden block h-[30px]"></div>
                      <tr className="h-[45px] " key={`custom_items_${item.id}`}>
                        <td className="w-[100%] md:w-[20%] px-[16px] block md:table-cell md:h-[45px] odd:bg-black-100 md:odd:bg-white">
                          <div className="flex md:block px-[16px] md:px-0">
                            <Typography
                              isBold
                              className="block md:hidden flex-1 leading-45"
                            >
                              項目名
                            </Typography>
                            <Typography className="flex-1 leading-45 md:leading-normal">
                              {item?.display_name || ''}
                            </Typography>
                          </div>
                        </td>
                        <td className="w-[100%] md:w-[20%] px-[16px] block md:table-cell md:h-[45px] odd:bg-black-100 md:odd:bg-white">
                          <div className="flex md:block px-[16px] md:px-0  items-center">
                            <Typography
                              isBold
                              className="block md:hidden flex-1 leading-45"
                            >
                              科目
                            </Typography>
                            <div className="flex-1">
                              <Chip className="bg-tertiary-500 pb-2 text-white text-[10px] max-h-[18px]">
                                {convertCategory(
                                  _includes(item?.category, CUSTOM_ITEM_CATEGORY.tuition)
                                    ? _split(item?.category, '_')[0]
                                    : item?.category,
                                  _includes(item?.category, CUSTOM_ITEM_CATEGORY.tuition)
                                    ? Number(_split(item?.category, '_')[1])
                                    : undefined,
                                )}
                              </Chip>
                            </div>
                          </div>
                        </td>
                        <td className="w-[100%] md:w-[20%] px-[16px] block md:table-cell md:h-[45px] odd:bg-black-100 md:odd:bg-white">
                          <div className="flex md:block px-[16px] md:px-0">
                            <Typography
                              isBold
                              className="block md:hidden flex-1 leading-45"
                            >
                              適用時期
                            </Typography>
                            <Typography className="flex-1 leading-[30px] md:leading-normal md:py-[10px]">
                              {convertFrequency(item)
                                .split(' ')
                                .map((splitedItem) => (
                                  <Typography>{splitedItem}</Typography>
                                ))}
                            </Typography>
                          </div>
                        </td>
                        <td className="w-[100%] md:w-[20%] px-[16px] block md:table-cell md:h-[45px] odd:bg-black-100 md:odd:bg-white">
                          <div className="flex md:block px-[16px] md:px-0">
                            <Typography
                              isBold
                              className="block md:hidden flex-1 leading-45"
                            >
                              金額
                            </Typography>
                            <Typography className="flex-1 leading-45 md:leading-normal">
                              {`${convertLocaleString(
                                castNumberWithoutOperator(item?.cost),
                              )}円/年`}
                            </Typography>
                          </div>
                        </td>
                        <td className="w-[100%] md:w-[20%] px-[16px] block md:table-cell md:h-[45px] odd:bg-black-100 md:odd:bg-white">
                          <div className="flex md:block px-[16px] md:px-0">
                            <Typography
                              isBold
                              className="block md:hidden flex-1 leading-45"
                            >
                              年間変動率
                            </Typography>
                            <Typography className="flex-1 leading-45 md:leading-normal">
                              {item?.payment_mode === PAYMENT_MODE.repeatedly
                                ? `${convertLocaleString(
                                    castNumberWithoutOperator(item?.changed_rate_percent),
                                  )}％`
                                : '-％'}
                            </Typography>
                          </div>
                        </td>
                      </tr>
                    </>
                  )
                })}
              </tbody>
            </table>
          </div>
        </>
      )}

      {inputCustomExpenseItems.length > 0 && (
        <>
          <div className="mt-30">
            <CircleTitle title="支出" />
            <table className="w-[100%] md:w-[100%] text-[12px] md:text-[14px]">
              <tbody>
                <tr className="hidden md:table-row h-[45px] md:odd:bg-black-100">
                  <th className="text-left px-[16px]">
                    <Typography isBold>項目名</Typography>
                  </th>
                  <th className="text-left px-[16px]">
                    <Typography isBold>科目</Typography>
                  </th>
                  <th className="text-left px-[16px]">
                    <Typography isBold>適用時期</Typography>
                  </th>
                  <th className="text-left px-[16px]">
                    <Typography isBold>金額</Typography>
                  </th>
                  <th className="text-left px-[16px]">
                    <Typography isBold>年間変動率</Typography>
                  </th>
                </tr>
              </tbody>
              <tbody>
                {inputCustomExpenseItems.map((item: any, index: number) => {
                  return (
                    <>
                      <div className="md:hidden block h-[30px]"></div>
                      <tr className="h-[45px]" key={`custom_items_${item.id}`}>
                        <td className="w-[100%] md:w-[20%] px-[16px] block md:table-cell md:h-[45px] odd:bg-black-100 md:odd:bg-white">
                          <div className="flex md:block px-[16px] md:px-0">
                            <Typography
                              isBold
                              className="block md:hidden flex-1 leading-45"
                            >
                              項目名
                            </Typography>
                            <Typography className="flex-1 leading-45 md:leading-normal">
                              {item?.display_name || ''}
                            </Typography>
                          </div>
                        </td>

                        <td className="w-[100%] md:w-[20%] px-[16px] block md:table-cell md:h-[45px] odd:bg-black-100 md:odd:bg-white">
                          <div className="flex md:block px-[16px] md:px-0  items-center">
                            <Typography
                              isBold
                              className="block md:hidden flex-1 leading-45"
                            >
                              科目
                            </Typography>
                            <div className="flex-1">
                              <Chip className="bg-purple-500 pb-2 text-white text-[10px] max-h-[18px]">
                                {convertCategory(
                                  _includes(item?.category, CUSTOM_ITEM_CATEGORY.tuition)
                                    ? _split(item?.category, '_')[0]
                                    : item?.category,
                                  _includes(item?.category, CUSTOM_ITEM_CATEGORY.tuition)
                                    ? Number(_split(item?.category, '_')[1])
                                    : undefined,
                                )}
                              </Chip>
                            </div>
                          </div>
                        </td>
                        <td className="w-[100%] md:w-[20%] px-[16px] block md:table-cell md:h-[45px] odd:bg-black-100 md:odd:bg-white">
                          <div className="flex md:block px-[16px] md:px-0">
                            <Typography
                              isBold
                              className="block md:hidden flex-1 leading-45"
                            >
                              適用時期
                            </Typography>
                            <Typography className="flex-1 leading-[30px] md:leading-normal md:py-[10px]">
                              {convertFrequency(item)
                                .split(' ')
                                .map((splitedItem) => (
                                  <Typography>{splitedItem}</Typography>
                                ))}
                            </Typography>
                          </div>
                        </td>
                        <td className="w-[100%] md:w-[20%] px-[16px] block md:table-cell md:h-[45px] odd:bg-black-100 md:odd:bg-white">
                          <div className="flex md:block px-[16px] md:px-0">
                            <Typography
                              isBold
                              className="block md:hidden flex-1 leading-45"
                            >
                              金額
                            </Typography>
                            <Typography className="flex-1 leading-45 md:leading-normal">
                              {`${convertLocaleString(
                                castNumberWithoutOperator(item?.cost),
                              )}円/年`}
                            </Typography>
                          </div>
                        </td>
                        <td className="w-[100%] md:w-[20%] px-[16px] block md:table-cell md:h-[45px] odd:bg-black-100 md:odd:bg-white">
                          <div className="flex md:block px-[16px] md:px-0">
                            <Typography
                              isBold
                              className="block md:hidden flex-1 leading-45"
                            >
                              年間変動率
                            </Typography>
                            <Typography className="flex-1 leading-45 md:leading-normal">
                              {item?.payment_mode === PAYMENT_MODE.repeatedly
                                ? `${convertLocaleString(
                                    castNumberWithoutOperator(item?.changed_rate_percent),
                                  )}％`
                                : '-％'}
                            </Typography>
                          </div>
                        </td>
                      </tr>
                    </>
                  )
                })}
              </tbody>
            </table>
          </div>
        </>
      )}
    </MyDataListWrapper>
  )
}

export default MyDataCustomItem
