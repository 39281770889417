import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import moment from 'moment'
import {
  getFpAccountAssigns,
  getUnassignedAccounts,
  postFpAccountAssigns,
} from '@/api/admins/fp_account_assigns'
import { unauthorizedAdminCheck } from '@/containers/admins/fpAccountsSlice'
import { ApiError } from '@/models/commonsModel'
import {
  FpAccountAssign,
  FpAccountAssignResponse,
  UnassignedAccountResponse,
  UnassignedAccount,
  UnassignedAccountCreateValues,
} from '@/models/admins/fpAccountAssignsModel'
import { AppThunk } from '@/store'
import { uniqBy as _uniqBy } from 'lodash'

interface FpAccountAssignState {
  fpAccountAssigns: FpAccountAssign[]
  unassignedAccounts: UnassignedAccount[]
  isLoading: boolean
  isLoadingUnassign: boolean
  lastUpdatedAt: string
  lastPage: number
}

const initialState: FpAccountAssignState = {
  fpAccountAssigns: [],
  unassignedAccounts: [],
  isLoading: false,
  isLoadingUnassign: false,
  lastUpdatedAt: '',
  lastPage: null,
}

const fpAccountAssignsSlice = createSlice({
  name: 'fp_account_assigns',
  initialState,
  reducers: {
    setFpAccountAssigns: (state, action: PayloadAction<FpAccountAssignResponse[]>) => {
      state.fpAccountAssigns = action.payload
      state.isLoading = false
      return state
    },
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    getUnassignedAccountsStart: (state) => {
      state.isLoadingUnassign = true
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    setUnassignedAccounts: (
      state,
      action: PayloadAction<UnassignedAccountResponse[]>,
    ) => {
      state.unassignedAccounts = _uniqBy(
        [...action.payload],
        'account_id',
      )
      // 最終更新時間から1時間を超えた場合データ再取得するか判定するフラグ
      state.lastUpdatedAt = moment().format('YYYY-MM-DD HH:mm')
      return state
    },
    getUnassignedAccountsSuccess: (state) => {
      state.isLoadingUnassign = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      state.isLoadingUnassign = false
      return state
    },
    setLastPage: (
      state,
      action: PayloadAction<number>,
    ) => {
      state.lastPage = action.payload
      return state
    },
    resetStateUnassignedAccounts: (state) => {
      state.unassignedAccounts = []
      return state
    },
    resetStateAssignedAccounts: (state) => {
      state.fpAccountAssigns = []
      return state
    },
  },
})

export const {
  setFpAccountAssigns,
  requestStart,
  getUnassignedAccountsStart,
  requestSuccess,
  setUnassignedAccounts,
  getUnassignedAccountsSuccess,
  requestFailure,
  setLastPage,
  resetStateUnassignedAccounts,
  resetStateAssignedAccounts,
} = fpAccountAssignsSlice.actions
export const fpAccountAssignsReducer = fpAccountAssignsSlice.reducer

export const fetchFpAccountAssigns = (): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  getFpAccountAssigns()
    .then((response) => {
      dispatch(setFpAccountAssigns(response))
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedAdminCheck(error))
    })
}

export const fetchUnassignedAccounts = (pageNum?: number | string, params?: any | undefined): AppThunk => async (
  dispatch,
) => {
  const page = pageNum || 1
  dispatch(getUnassignedAccountsStart())
  getUnassignedAccounts(page, params)
    .then((response) => {
      dispatch(setUnassignedAccounts(response.account_list))
      dispatch(getUnassignedAccountsSuccess())
      dispatch(setLastPage(response.last_page))
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedAdminCheck(error))
    })
}

export const createFpAccountAssigns = (
  values: UnassignedAccountCreateValues,
  callback: () => void,
  resolve: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postFpAccountAssigns(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedAdminCheck(error, resolve))
    })
}

export const resetUnassignedAccounts = (): AppThunk => async (dispatch) => {
  dispatch(resetStateUnassignedAccounts())
}

export const fetchAssignedAccounts = (fp_account_id: number, pageNum?: number | string, params?: any | undefined): AppThunk => async (
  dispatch,
) => {
  if (!fp_account_id) { return }

  const page = pageNum || 1
  params.search_fp_account_id = fp_account_id
  dispatch(getUnassignedAccountsStart())
  getUnassignedAccounts(page, params)
    .then((response) => {
      dispatch(setFpAccountAssigns(response.account_list))
      dispatch(getUnassignedAccountsSuccess())
      dispatch(setLastPage(response.last_page))
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedAdminCheck(error))
    })
}

export const resetAssignedAccounts = (): AppThunk => async (dispatch) => {
  dispatch(resetStateAssignedAccounts())
}
