import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  patchFutureYearlyLivingCostV2,
  postFutureYearlyLivingCostV2,
  deleteFutureYearlyLivingCostV2,
} from '@/api/futures/futureYearlyLivingCosts'
import { unauthorizedCheck } from '@/containers/accountsSlice'
import { AppThunk } from '@/store'

interface FutureYearlyLivingCostState {
  future_yearly_living_cost_ceremonies: any[]
  future_yearly_living_cost_electronics: any[]
  future_yearly_living_cost_home_comings: any[]
  future_yearly_living_cost_travels: any[]
  isLoading: boolean
}

const initialState: FutureYearlyLivingCostState = {
  future_yearly_living_cost_ceremonies: [],
  future_yearly_living_cost_electronics: [],
  future_yearly_living_cost_home_comings: [],
  future_yearly_living_cost_travels: [],
  isLoading: false,
}

const futureYearlyLivingCostsSlice = createSlice({
  name: 'futureYearlyLivingCosts',
  initialState,
  reducers: {
    setFutureYearlyLivingCost: (state, action: PayloadAction<any>) => {
      state.future_yearly_living_cost_ceremonies = action.payload.ceremonies
      state.future_yearly_living_cost_electronics = action.payload.electronics
      state.future_yearly_living_cost_home_comings = action.payload.homeComings
      state.future_yearly_living_cost_travels = action.payload.travels

      return state
    },
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
  },
})

export const {
  setFutureYearlyLivingCost,
  requestStart,
  requestSuccess,
  requestFailure,
} = futureYearlyLivingCostsSlice.actions
export const futureYearlyLivingCostsReducer = futureYearlyLivingCostsSlice.reducer

export const createFutureYearlyLivingCostV2 = (
  values: any,
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postFutureYearlyLivingCostV2(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const updateFutureYearlyLivingCostV2 = (
  values: any,
  id: number,
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchFutureYearlyLivingCostV2(values, id)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const destroyFutureYearlyLivingCostV2 = (
  id: number,
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  deleteFutureYearlyLivingCostV2(id)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}
