import React, { FC } from 'react'
import {
  ResponsiveContainer,
  ComposedChart,
  ReferenceLine,
  CartesianGrid,
  Coordinate,
} from 'recharts'
import renderCustomTooltip from '@/components/v2/molecules/renderCustomTooltip'
import renderCustomXAxis from '@/components/v2/molecules/renderCustomXAxis'
import renderCustomYAxis from '@/components/v2/molecules/renderCustomYAxis'
import renderCustomYAxisDummy from '@/components/v2/molecules/renderCustomYAxisDummy'
import LoadingOverlay from '@/components/v1/atoms/LoadingOverlay'

interface GraphComposedProps {
  /** id */
  id?: string
  /** クラス名 */
  className?: string
  /** 幅 */
  width?: number
  /** 高さ */
  height: number
  /** x軸_データキー */
  xDataKey?: string
  /** y軸_データキー */
  yDataKey?: string
  /** データ */
  data: { [key: string]: any }[]
  /** true: ツールチップ非表示, false: ツールチップ表示 */
  tooltipHide?: boolean
  /** ツールチップ位置 */
  tooltipPosition?: Coordinate
  /** カスタマイズツールチップ */
  tooltipContent?: JSX.Element
  /** onClickメソッド */
  onClick?: () => void

  yTicks?: number[]

  isLoading?: boolean
}

const GraphComposed: FC<GraphComposedProps> = (props) => {
  const {
    id,
    className,
    width,
    height,
    xDataKey,
    yDataKey,
    data,
    tooltipHide,
    tooltipPosition,
    tooltipContent,
    onClick,
    children,
    yTicks,
    isLoading,
  } = props

  return (
    <div className="flex relative w-100% h-100%">
      {renderCustomYAxisDummy({ id, height, data })}
      <LoadingOverlay isLoading={!!isLoading} zIndex={100} />
      <ResponsiveContainer id={id} className={className} width={'100%'} height={height}>
        <ComposedChart data={data} stackOffset="sign" onClick={onClick}>
          <CartesianGrid />
          {!tooltipHide &&
            renderCustomTooltip({ position: tooltipPosition, content: tooltipContent })}
          {children as any}
          {renderCustomXAxis({ dataKey: xDataKey ?? '' })}
          {renderCustomYAxis({ dataKey: yDataKey ?? '', ticks: yTicks })}
          <ReferenceLine y={0} stroke="#666666" strokeDasharray="2.5 1.5" />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  )
}

export default GraphComposed
