import React, { FC, useEffect, useContext, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Typography from '@/components/v2/atoms/Typography'
import { setPageMeta } from '@/containers/pageMetasSlice'
import { destroyInputRisk, fetchInputRisk } from '@/containers/inputs/inputRisksSlice'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import { convertLocaleString, convertCostTypeToJp } from '@/models/commonsModelFunc'
import { PAYMENT_MODE } from '@/models/inputs/inputCustomItemsModelFunc'
import {
  PAYMENT_TARGET_SELECT_ITEMS,
  CATEGORY_SELECT_ITEMS,
} from '@/templates/v2/simulations/_futureRiskModalForm'
import { RootState } from '@/store'
import MyDataListWrapper from '@/templates/v2/myData/_ListWrapper'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import Button from '@/components/v2/atoms/Button'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import { useParams } from 'react-router-dom'
import { AccountContext } from '@/models/accountsModelFunc'
import { useAccountControl } from '@/hooks/useAccountControl'
import { filter as _filter, find as _find } from 'lodash'
import moment from 'moment'

const MyDataRisk: FC = () => {
  const { showFlashMsg } = useFlashAlert()
  const dispatch = useDispatch()
  const { isAdmin } = useContext(AccountContext)
  const { account_id } = useParams<{ account_id?: string }>()

  const { handleMovePage } = useCustomHistory()
  const { isMyDataManipulatedApproval } = useAccountControl()

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'マイデータ | 万が一の保障',
        description: 'マイデータ | 万が一の保障',
        keyword: 'マイデータ | 万が一の保障',
      }),
    )

    if (inputRisk.length == 0) dispatch(fetchInputRisk())
  }, [])

  const { inputRisk } = useSelector((state: RootState) => state.inputRisk)
  const { account } = useSelector((state: RootState) => state.accounts)

  // ご本人が亡くなった場合の想定
  const inputRiskPerson = useMemo(() => {
    return _filter(inputRisk, (item) => Number(item?.['person_flg']) === 0)
  }, [inputRisk])

  // 配偶者が亡くなった場合の想定
  const inputRiskSpouse = useMemo(() => {
    return _filter(inputRisk, (item) => Number(item?.['person_flg']) === 1)
  }, [inputRisk])

  const formatDate = (updatedAt?: string) =>
    updatedAt != null ? `(最終更新日: ${moment(updatedAt).format('YYYY/MM/DD')})` : ''

  return (
    <MyDataListWrapper title="万が一の保障">
      <Typography
        className="pb-[15px] md:pb-[24px] text-[15px] md:text-[18px] text-center"
        isBold
      >
        万が一の保障の登録を行なっておくことで、保険グラフにて万が一の想定を確認することができます。
      </Typography>

      {inputRiskPerson.length === 0 && (
        <div>
          <div className="sm:flex block justify-between mt-30">
            <CircleTitle
              title={`ご本人が亡くなった場合の万が一の保障`}
              classNameTitle="text-[20px] md:text-[24px]"
            />
          </div>
          <div className="mt-30">
            <Typography
              className="pb-[15px] md:pb-[24px] text-[15px] md:text-[18px] text-center"
              isBold
            >
              ご本人が亡くなった場合の万が一の保障が登録されていません。
              <br />
              下記のボタンから登録してみましょう。
            </Typography>
            <Button
              className="w-[104px] md:w-[112px] h-[26px] md:h-[37px] rounded-[4px] mx-auto bg-white border border-secondary-500 hover:bg-secondary-50"
              isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
              onClick={() =>
                handleMovePage(
                  isAdmin
                    ? `/v2/accounts/${account_id}/myData/risk/new/person`
                    : '/v2/myData/risk/new/person',
                )
              }
            >
              <Typography
                className="relative bottom-[1px] text-[12px] md:text-[14px] text-secondary-500"
                isBold
              >
                新しく追加する
              </Typography>
            </Button>
          </div>
        </div>
      )}

      {inputRiskPerson.map((item: any, index: number) => {
        const handleDestroy = () => {
          const result = confirm('本当に削除してよろしいですか？')
          if (!result) return

          const destroyFunc = new Promise((resolve) => {
            dispatch(destroyInputRisk(Number(item!.id), () => resolve('')))
          })

          Promise.all([destroyFunc]).then(() => {
            dispatch(fetchInputRisk())
            showFlashMsg('削除しました')
          })
        }

        return (
          <React.Fragment key={item.id}>
            <div className="mt-30">
              <div className="sm:flex block justify-between">
                <div className="flex flex-col">
                  <CircleTitle
                    title={`ご本人が亡くなった場合の万が一の保障`}
                    classNameTitle="text-[20px] md:text-[24px]"
                  />
                  <Typography className="text-[13px] md:text-[16px] text-[#9E9E9E]">
                    {formatDate(item?.updated_at)}
                  </Typography>
                </div>
                <div className="flex justify-end my-[10px]">
                  <Button
                    className="w-[84px] md:w-[112px] h-[26px] md:h-[37px] mr-[10px] rounded-[4px] bg-white border border-secondary-500 hover:bg-secondary-50"
                    isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
                    onClick={() =>
                      handleMovePage(
                        isAdmin
                          ? `/v2/accounts/${account_id}/myData/risk/edit/${item.id}/person`
                          : `/v2/myData/risk/edit/${item.id}/person`,
                      )
                    }
                  >
                    <Typography
                      className="relative bottom-[1px] text-[12px] md:text-[14px] text-secondary-500"
                      isBold
                    >
                      編集する
                    </Typography>
                  </Button>
                  <Button
                    className="w-[84px] md:w-[112px] h-[26px] md:h-[37px] mr-0 md:mr-[9px] rounded-[4px] bg-white border border-black-600 hover:bg-secondary-50"
                    isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
                    onClick={() => handleDestroy()}
                  >
                    <Typography
                      className="relative bottom-[1px] text-[12px] md:text-[14px] text-black-600"
                      isBold
                    >
                      削除する
                    </Typography>
                  </Button>
                </div>
              </div>

              <table className="w-[100%] mb-[60px] last:mb-0 text-[12px] md:text-[14px]">
                <tbody>
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="w-[40%] px-[16px] md:px-[30px]">
                      <Typography isBold>想定するケース</Typography>
                    </td>
                    <td className="w-[65%] px-[16px] md:px-[30px]">
                      <Typography>ご本人が亡くなった場合</Typography>
                    </td>
                  </tr>

                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="w-[40%] px-[16px] md:px-[30px]">
                      <Typography isBold>死亡退職金</Typography>
                    </td>
                    <td className="w-[65%] px-[16px] md:px-[30px]">
                      <Typography>
                        {convertLocaleString(item.dead_severance_pay_manyen)} 万円
                      </Typography>
                    </td>
                  </tr>

                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="w-[40%] px-[16px] md:px-[30px]">
                      <Typography isBold>弔慰金</Typography>
                    </td>
                    <td className="w-[65%] px-[16px] md:px-[30px]">
                      <Typography>
                        {convertLocaleString(item.condolence_pay_manyen)} 万円
                      </Typography>
                    </td>
                  </tr>

                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="w-[40%] px-[16px] md:px-[30px]">
                      <Typography isBold>香典</Typography>
                    </td>
                    <td className="w-[65%] px-[16px] md:px-[30px]">
                      <Typography>
                        {convertLocaleString(item.incense_pay_manyen)} 万円
                      </Typography>
                    </td>
                  </tr>

                  {item.input_risk_custom_items.map((customItem: any, index: number) => {
                    const isOnce = customItem.payment_mode == PAYMENT_MODE.once
                    return (
                      <React.Fragment key={`${index}_income`}>
                        <tr className="h-[45px] odd:bg-black-100">
                          <td className="w-[40%] px-[16px] md:px-[30px]">
                            {index === 0 && (
                              <Typography isBold>
                                会社から貰える
                                <br />
                                その他の保障
                              </Typography>
                            )}
                          </td>
                          <td className="w-[65%] px-[16px] md:px-[30px]">
                            <Typography>{customItem.name}</Typography>
                            <Typography>
                              {
                                _find(CATEGORY_SELECT_ITEMS, (items) => {
                                  return items.value === customItem.category
                                })?.label
                              }
                            </Typography>
                            <Typography>
                              {
                                _find(PAYMENT_TARGET_SELECT_ITEMS, (items) => {
                                  let targetPersonFlg: number = Number(
                                    customItem.person_flg,
                                  )
                                  if (customItem.child_index !== null) {
                                    targetPersonFlg = customItem.child_index + 2
                                  }
                                  return Number(items.value) === targetPersonFlg
                                })?.label
                              }
                              が
                              <br />
                              {convertLocaleString(customItem.payment_start_age)}
                              {isOnce ? `歳のとき` : `歳から`}
                              <br />
                              {isOnce
                                ? undefined
                                : `${convertLocaleString(
                                    customItem.payment_end_age,
                                  )}歳まで`}
                            </Typography>
                            <Typography>
                              {convertLocaleString(customItem.amount_manyen)}{' '}
                              {isOnce ? `万円` : `万円/年`}
                            </Typography>
                          </td>
                        </tr>
                      </React.Fragment>
                    )
                  })}

                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="w-[40%] px-[16px] md:px-[30px]">
                      <Typography isBold>生活費水準</Typography>
                    </td>
                    <td className="w-[65%] px-[16px] md:px-[30px]">
                      <Typography>{item.living_cost_rate_percent} %に抑える</Typography>
                    </td>
                  </tr>

                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="w-[40%] px-[16px] md:px-[30px]">
                      <Typography isBold>
                        必要保障額に
                        <br />
                        金融資産を
                      </Typography>
                    </td>
                    <td className="w-[65%] px-[16px] md:px-[30px]">
                      <Typography>
                        {item.includes_financial_asset === 0 ? '含める' : '含めない'}
                      </Typography>
                    </td>
                  </tr>

                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="w-[40%] px-[16px] md:px-[30px]">
                      <Typography isBold>ご葬儀について</Typography>
                    </td>
                    <td className="w-[65%] px-[16px] md:px-[30px]">
                      <Typography>
                        {convertCostTypeToJp(item.funeral_cost_type)}
                      </Typography>
                      <Typography>
                        {convertLocaleString(item.custom_funeral_cost_manyen)} 万円
                      </Typography>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </React.Fragment>
        )
      })}

      {account['has_spouse?'] && inputRiskSpouse.length === 0 && (
        <div>
          <div className="sm:flex block justify-between mt-30">
            <CircleTitle
              title={`配偶者が亡くなった場合の万が一の保障`}
              classNameTitle="text-[20px] md:text-[24px]"
            />
          </div>
          <div className="mt-30">
            <Typography
              className="pb-[15px] md:pb-[24px] text-[15px] md:text-[18px] text-center"
              isBold
            >
              配偶者が亡くなった場合の万が一の保障が登録されていません。
              <br />
              下記のボタンから登録してみましょう。
            </Typography>
            <Button
              className="w-[104px] md:w-[112px] h-[26px] md:h-[37px] rounded-[4px] mx-auto bg-white border border-secondary-500 hover:bg-secondary-50"
              isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
              onClick={() =>
                handleMovePage(
                  isAdmin
                    ? `/v2/accounts/${account_id}/myData/risk/new/spouse`
                    : '/v2/myData/risk/new/spouse',
                )
              }
            >
              <Typography
                className="relative bottom-[1px] text-[12px] md:text-[14px] text-secondary-500"
                isBold
              >
                新しく追加する
              </Typography>
            </Button>
          </div>
        </div>
      )}

      {inputRiskSpouse.map((item: any, index: number) => {
        const handleDestroy = () => {
          const result = confirm('本当に削除してよろしいですか？')
          if (!result) return

          const destroyFunc = new Promise((resolve) => {
            dispatch(destroyInputRisk(Number(item!.id), () => resolve('')))
          })

          Promise.all([destroyFunc]).then(() => {
            dispatch(fetchInputRisk())
            showFlashMsg('削除しました')
          })
        }

        return (
          <React.Fragment key={item.id}>
            <div className="mt-30">
              <div className="sm:flex block justify-between">
                <div className="flex flex-col">
                  <CircleTitle
                    title={`配偶者が亡くなった場合の万が一の保障`}
                    classNameTitle="text-[20px] md:text-[24px]"
                  />
                  <Typography className="text-[13px] md:text-[16px] text-[#9E9E9E]">
                    {formatDate(item?.updated_at)}
                  </Typography>
                </div>
                <div className="flex justify-end my-[10px]">
                  <Button
                    className="w-[84px] md:w-[112px] h-[26px] md:h-[37px] mr-[10px] rounded-[4px] bg-white border border-secondary-500 hover:bg-secondary-50"
                    isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
                    onClick={() =>
                      handleMovePage(
                        isAdmin
                          ? `/v2/accounts/${account_id}/myData/risk/edit/${item.id}/spouse`
                          : `/v2/myData/risk/edit/${item.id}/spouse`,
                      )
                    }
                  >
                    <Typography
                      className="relative bottom-[1px] text-[12px] md:text-[14px] text-secondary-500"
                      isBold
                    >
                      編集する
                    </Typography>
                  </Button>
                  <Button
                    className="w-[84px] md:w-[112px] h-[26px] md:h-[37px] mr-0 md:mr-[9px] rounded-[4px] bg-white border border-black-600 hover:bg-secondary-50"
                    isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
                    onClick={() => handleDestroy()}
                  >
                    <Typography
                      className="relative bottom-[1px] text-[12px] md:text-[14px] text-black-600"
                      isBold
                    >
                      削除する
                    </Typography>
                  </Button>
                </div>
              </div>

              <table className="w-[100%] mb-[60px] last:mb-0 text-[12px] md:text-[14px]">
                <tbody>
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="w-[40%] px-[16px] md:px-[30px]">
                      <Typography isBold>想定するケース</Typography>
                    </td>
                    <td className="w-[65%] px-[16px] md:px-[30px]">
                      <Typography>配偶者が亡くなった場合</Typography>
                    </td>
                  </tr>

                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="w-[40%] px-[16px] md:px-[30px]">
                      <Typography isBold>死亡退職金</Typography>
                    </td>
                    <td className="w-[65%] px-[16px] md:px-[30px]">
                      <Typography>
                        {convertLocaleString(item.dead_severance_pay_manyen)} 万円
                      </Typography>
                    </td>
                  </tr>

                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="w-[40%] px-[16px] md:px-[30px]">
                      <Typography isBold>弔慰金</Typography>
                    </td>
                    <td className="w-[65%] px-[16px] md:px-[30px]">
                      <Typography>
                        {convertLocaleString(item.condolence_pay_manyen)} 万円
                      </Typography>
                    </td>
                  </tr>

                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="w-[40%] px-[16px] md:px-[30px]">
                      <Typography isBold>香典</Typography>
                    </td>
                    <td className="w-[65%] px-[16px] md:px-[30px]">
                      <Typography>
                        {convertLocaleString(item.incense_pay_manyen)} 万円
                      </Typography>
                    </td>
                  </tr>

                  {item.input_risk_custom_items.map((customItem: any, index: number) => {
                    const isOnce = customItem.payment_mode == PAYMENT_MODE.once

                    return (
                      <React.Fragment key={`${index}_income`}>
                        <tr className="h-[45px] odd:bg-black-100">
                          <td className="w-[40%] px-[16px] md:px-[30px]">
                            {index === 0 && (
                              <Typography isBold>
                                会社から貰える
                                <br />
                                その他の保障
                              </Typography>
                            )}
                          </td>
                          <td className="w-[65%] px-[16px] md:px-[30px]">
                            <Typography>{customItem.name}</Typography>
                            <Typography>
                              {
                                _find(CATEGORY_SELECT_ITEMS, (items) => {
                                  return items.value === customItem.category
                                })?.label
                              }
                            </Typography>
                            <Typography>
                              {
                                _find(PAYMENT_TARGET_SELECT_ITEMS, (items) => {
                                  let targetPersonFlg: number = Number(
                                    customItem.person_flg,
                                  )
                                  if (customItem.child_index !== null) {
                                    targetPersonFlg = customItem.child_index + 2
                                  }
                                  return Number(items.value) === targetPersonFlg
                                })?.label
                              }
                              が
                              <br />
                              {convertLocaleString(customItem.payment_start_age)}
                              {isOnce ? `歳のとき` : `歳から`}
                              <br />
                              {isOnce
                                ? undefined
                                : `${convertLocaleString(
                                    customItem.payment_end_age,
                                  )}歳まで`}
                            </Typography>
                            <Typography>
                              {convertLocaleString(customItem.amount_manyen)}{' '}
                              {isOnce ? `万円` : `万円/年`}
                            </Typography>
                          </td>
                        </tr>
                      </React.Fragment>
                    )
                  })}

                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="w-[40%] px-[16px] md:px-[30px]">
                      <Typography isBold>生活費水準</Typography>
                    </td>
                    <td className="w-[65%] px-[16px] md:px-[30px]">
                      <Typography>{item.living_cost_rate_percent} %に抑える</Typography>
                    </td>
                  </tr>

                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="w-[40%] px-[16px] md:px-[30px]">
                      <Typography isBold>
                        必要保障額に
                        <br />
                        金融資産を
                      </Typography>
                    </td>
                    <td className="w-[65%] px-[16px] md:px-[30px]">
                      <Typography>
                        {item.includes_financial_asset === 0 ? '含める' : '含めない'}
                      </Typography>
                    </td>
                  </tr>

                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="w-[40%] px-[16px] md:px-[30px]">
                      <Typography isBold>ご葬儀について</Typography>
                    </td>
                    <td className="w-[65%] px-[16px] md:px-[30px]">
                      <Typography>
                        {convertCostTypeToJp(item.funeral_cost_type)}
                      </Typography>
                      <Typography>
                        {convertLocaleString(item.custom_funeral_cost_manyen)} 万円
                      </Typography>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </React.Fragment>
        )
      })}
    </MyDataListWrapper>
  )
}

export default MyDataRisk
