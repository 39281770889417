import React, { FC, useContext, useEffect } from 'react'
import { DescriptionContext } from '@/hooks/useDescriptionTemplates'
import { composeValidators, required, zeroOrMoreNumber } from '@/utils/validate'

import Typography from '@/components/v2/atoms/Typography'
import NumberField from '@/components/v2/molecules/NumberField'
import Tooltip from '@/components/v2/atoms/Tooltip'
import HelpCircleIcon from '@/assets/images/v2/help-circle.svg'

const OtherCost: FC = () => {
  const { setSendPageValueKeys } = useContext(DescriptionContext)

  useEffect(() => {
    setSendPageValueKeys(['future_lend_housing.other_expenses'])
  }, [])

  return (
    <>
      <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-center">
        <div className="flex items-center min-w-[140px] lg:min-w-[164px] pb-[10px] md:pb-0">
          <Typography className="mr-[10px] text-[14px] text-black-800" isBold>
            その他の経費
          </Typography>
          <Tooltip
            item="※建物・賃貸管理手数料、修繕積立金、リフォーム費、固定資産税、ローン、賃貸管理手数料以外で不動産にかかる支出を入力してください。"
            itemClassName="w-[116px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
          >
            <HelpCircleIcon className="hidden md:block" />
          </Tooltip>
        </div>
        <div className="flex flex-auto items-baseline">
          <NumberField
            name="future_lend_housing.other_expenses"
            isFullWidth
            validate={composeValidators(required, zeroOrMoreNumber)}
          />
          <Typography
            className="pl-[8px] text-[14px] text-black-800 min-w-[fit-content]"
            isBold
          >
            円/年
          </Typography>
        </div>
      </div>
    </>
  )
}

export default OtherCost
