import { find as _find } from 'lodash'
import { InputHousingRent, RentType } from '@/models/inputs/inputHousingRentsModel'

export const INPUT_HOUSING_RENT_TYPE = {
  selfRent: 'self_rent',
  companyRent: 'company_rent',
  allowance: 'allowance',
}

export const findCurrentRentHouse = (
  inputHousingRents: InputHousingRent[] | null | undefined,
) => {
  return _find(inputHousingRents, { scheduled_at_age: null })
}

export const convertRentType = (rentType: RentType | null | undefined) => {
  switch (rentType) {
    case INPUT_HOUSING_RENT_TYPE.selfRent:
      return '賃貸'
    case INPUT_HOUSING_RENT_TYPE.companyRent:
      return '社宅・寮'
    case INPUT_HOUSING_RENT_TYPE.allowance:
      return '家賃補助'
    default:
      return ''
  }
}
