import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { sortBy as _sortBy } from 'lodash'
import { unauthorizedCheck } from '../accountsSlice'
import {
  patchInputInsurance,
  postInputInsurance,
  deleteInputInsurance,
  getInputInsurances,
  getInsurancePaymentSummary,
} from '@/api/inputs/inputInsurances'
import { postInputInsuranceMtTran } from '@/api/inputs/inputInsuranceMtTrans'
import { ApiError } from '@/models/commonsModel'
import { convertMomentDate } from '@/models/commonsModelFunc'
import {
  InputInsurance,
  InputInsuranceCreateValues,
  InputInsuranceOption,
  InputInsuranceDestroyValues,
  InputInsuranceResponse,
  InsurancePaymentSummary,
} from '@/models/inputs/inputInsurancesModel'
import { InputInsuranceMtTran } from '@/models/inputs/inputInsuranceMtTransModel'
import { AppThunk } from '@/store'

interface InputInsuranceState {
  inputInsurances: InputInsurance[]
  inputInsuranceOptions: InputInsuranceOption[]
  yearlyInsurancePayment: number | null
  monthlyInsurancePayment: number | null
  isLoading: boolean
}

const initialState: InputInsuranceState = {
  inputInsurances: [],
  inputInsuranceOptions: [],
  yearlyInsurancePayment: 0,
  monthlyInsurancePayment: 0,
  isLoading: false,
}

const inputInsurancesSlice = createSlice({
  name: 'inputInsurances',
  initialState,
  reducers: {
    setInputInsurances: (state, action: PayloadAction<InputInsurance[]>) => {
      const { payload } = action

      state.inputInsurances = payload.map((item) => ({
        ...item,
        contracted_year: convertMomentDate(item.contracted_at, 'YYYY'),
        contracted_month: convertMomentDate(item.contracted_at, 'MM'),
      }))

      return state
    },
    setInputInsuranceOptions: (state, action: PayloadAction<InputInsuranceOption[]>) => {
      state.inputInsuranceOptions = action.payload

      return state
    },
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    resetInputInsurancesStore: (state) => {
      state = initialState
      return state
    },
    getInputInsurancesSuccess: (
      state,
      action: PayloadAction<InputInsuranceResponse[]>,
    ) => {
      const sortedInputInsurances = _sortBy(action.payload, 'id')
      state.inputInsurances = sortedInputInsurances.map((item) => ({
        ...item,
        contracted_year: convertMomentDate(item.contracted_at, 'YYYY'),
        contracted_month: convertMomentDate(item.contracted_at, 'MM'),
      }))
      state.isLoading = false

      return state
    },
    getInsurancePaymentSummarySuccess: (
      state,
      action: PayloadAction<InsurancePaymentSummary>,
    ) => {
      state.monthlyInsurancePayment = action.payload.monthly_insurance_payment
      state.yearlyInsurancePayment = action.payload.yearly_insurance_payment
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
  },
})

export const {
  setInputInsurances,
  setInputInsuranceOptions,
  requestStart,
  resetInputInsurancesStore,
  getInputInsurancesSuccess,
  getInsurancePaymentSummarySuccess,
  requestSuccess,
  requestFailure,
} = inputInsurancesSlice.actions
export const inputInsurancesReducer = inputInsurancesSlice.reducer

export const fetchInputInsurances = (): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  getInputInsurances()
    .then((response) => {
      dispatch(getInputInsurancesSuccess(response))
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const fetchInsurancePaymentSummary = (): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  getInsurancePaymentSummary()
    .then((response) => {
      dispatch(getInsurancePaymentSummarySuccess(response))
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const createInputInsurance = (
  values: InputInsuranceCreateValues,
  callback: (id: number) => void,
  resolve?: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postInputInsurance(values)
    .then((response: InputInsuranceResponse) => {
      dispatch(requestSuccess())
      callback(response.id)
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error, resolve))
    })
}

export const createInputInsuranceMtTran = (
  values: InputInsuranceMtTran,
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postInputInsuranceMtTran(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const updateInputInsurance = (
  values: any,
  callback: () => void,
  resolve?: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchInputInsurance(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error, resolve))
    })
}

export const destroyInputInsurance = (
  values: { params: InputInsuranceDestroyValues },
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  deleteInputInsurance(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}
