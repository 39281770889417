import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MarginContainer from '@/components/v2/atoms/MarginContainer'
import Paper from '@/components/v2/atoms/Paper'
import Heading from '@/components/v2/atoms/Heading'
import Typography from '@/components/v2/atoms/Typography'
import { setPageMeta } from '@/containers/pageMetasSlice'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import Link from '@/components/v2/atoms/Link'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import { fetchAccount } from '@/containers/accountsSlice'
import { RootState } from '@/store'
import { getMoneytreeVaultUrl } from '@/utils/moneytree'

const Household: FC = () => {
  const dispatch = useDispatch()
  const { handleMovePage, handleBackPage } = useCustomHistory()
  const { account } = useSelector((state: RootState) => state.accounts)

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'Moneytree連携',
        description: 'Moneytree連携',
        keyword: 'Moneytree連携',
      }),
    )
  }, [])

  useEffect(() => {
    if ((account?.id ?? 0) < 1) {
      dispatch(fetchAccount())
    }
  }, [account])

  const handleConnection = () => {
    handleMovePage('/v2/plans/MoneytreeConnection')
  }

  return (
    <MarginContainer className="py-40 space-y-24">
      <Paper className="lg:max-w-820 mx-8 lg:mx-auto px-16 py-24 sm:p-40 space-y-24 bg-white">
        <Heading className="text-center">
          <span className="text-[23px]">家計簿アプリと連携しましょう</span>
        </Heading>
        <Typography className="text-14 text-black-700 sm:text-center">
          家計簿アプリと連携することで、入力の手間が省ける他、常に最新の状態を反映できるため、
          <span className="sm:inline-block">
            ライフプランを作成した後の実行管理が大幅に楽になります。
          </span>
          <br />
          住宅ローンを借りている場合は、残高が簡単に確認できる、繰上げ返済の計画が建てられます。
        </Typography>
        <div className="p-16 md:p-24 xl:p-40 rounded-16 bg-primary-75">
          <Heading as="h2" size={3} className="mb-24 text-primary-950 text-center">
            Moneytreeと連携するメリット
          </Heading>
          <div className="flex flex-col md:flex-row justify-center md:space-x-24 space-y-24 md:space-y-0">
            <div className="flex flex-col items-center px-12 sm:px-34 py-24 sm:py-30 sm:max-w-320 rounded-8 bg-white">
              <img src="/static/images/v2/household-1.svg" className="mb-16" />
              <Typography className="text-14 text-center text-black-900">
                家計簿アプリと連携することで、
                <br />
                現状の把握がより正確に、より簡単に！
              </Typography>
            </div>
            <div className="flex flex-col items-center px-12 sm:px-34 py-24 sm:py-30 sm:max-w-320 rounded-8 bg-white">
              <img src="/static/images/v2/household-2.svg" className="mb-16" />
              <Typography className="text-14 text-center text-black-900">
                リアルタイムにデータを取得！
                <br />
                ライフプランの管理を容易に！
              </Typography>
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          <ButtonPrimary
            className="h-45 w-full md:w-188 text-white"
            onClick={handleConnection}
          >
            <Typography className="bottom-2 flex-auto pl-30 relative text-16" isBold>
              連携する
            </Typography>
            <i className="ico chevron-right mr-17 text-24" />
          </ButtonPrimary>
        </div>
      </Paper>
      <div className="text-center">
        <Link
          className="text-14 text-primary-900"
          children="前の画面へ戻る"
          onClick={handleBackPage}
        />
      </div>
    </MarginContainer>
  )
}

export default Household
