import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { isNull as _isNull } from 'lodash'
import { convertMomentDate } from '../../models/commonsModelFunc'
import {
  FutureHousing,
  FutureHousingBorrowingLimit,
} from '../../models/futures/futureHousingsModel'

interface FutureHousingState {
  futureHousing: FutureHousing | null
  futureHousingBorrowingLimit: FutureHousingBorrowingLimit
  futureHousingApplicable: any
  isLoading: boolean
}

const initialState: FutureHousingState = {
  futureHousing: null,
  futureHousingBorrowingLimit: {
    pear: 0,
    person: 0,
    spouse: 0,
    sum: 0,
  },
  futureHousingApplicable: {
    lendable_housings: {
      input_housing_ids: [],
      future_v2_housing_ids: [],
      applicable_year: {},
    },
    sellable_housings: {
      input_housing_ids: [],
      future_v2_housing_ids: [],
      applicable_year: {},
    },
  },
  isLoading: false,
}

const futureHousingsSlice = createSlice({
  name: 'futureHousings',
  initialState,
  reducers: {
    setFutureHousing: (state, action: PayloadAction<FutureHousing | null>) => {
      const { payload } = action

      state.futureHousing = null
      if (!_isNull(payload)) {
        state.futureHousing = {
          ...payload,
          purchased_year: convertMomentDate(payload.purchased_at, 'YYYY'),
          constructed_year: convertMomentDate(payload.constructed_at, 'YYY'),
        }
      }

      return state
    },
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
  },
})

export const {
  setFutureHousing,
  requestStart,
  requestSuccess,
  requestFailure,
} = futureHousingsSlice.actions
export const futureHousingsReducer = futureHousingsSlice.reducer
