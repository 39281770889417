import React, { FC, useContext, useMemo } from 'react'
import { useField, useForm } from 'react-final-form'
import { includes as _includes } from 'lodash'
import Button from '@/components/v2/atoms/Button'
import Tooltip from '@/components/v2/atoms/Tooltip'
import Typography from '@/components/v2/atoms/Typography'
import NumberField from '@/components/v2/molecules/NumberField'
import { TWO_TYPES_LOAN_YEAR_SELECT_ITEMS } from '@/constants/formItem/commons'
import { INPUT_HOUSING_YEAR_SELECT_ITEMS } from '@/constants/formItem/inputs/inputHousings'
import { DescriptionContext } from '@/hooks/useDescriptionTemplates'
import {
  castNumberWithoutOperator,
  THIS_YEAR,
  THIS_YEAR_NUMBER,
} from '@/models/commonsModelFunc'
import {
  HOUSING_TYPE2,
  calcEstimatedMiscellaneousCost,
  getTargetSelfOwnedAttributesName,
  isMansionType,
} from '@/models/commonHousingModelFunc'
import {
  buildStandardSelectItems,
  buildTwoTypesRangeYearSelectItems,
} from '@/utils/formItem'
import {
  commaNumberMaxLength,
  composeValidators,
  required,
  zeroOrMoreNumber,
} from '@/utils/validate'
import SelectBox from '@/components/v2/molecules/SelectBox'

interface HouseBasicInfoProps {
  isFuture?: boolean
  isEdit?: boolean
}

const HouseBasicInfo: FC<HouseBasicInfoProps> = (props) => {
  const { isFuture, isEdit } = props
  const { targetYear, targetAge, age } = useContext(DescriptionContext)
  const { change } = useForm()

  const targetHousingSelfOwnedAttributesName = getTargetSelfOwnedAttributesName(isFuture)

  const {
    input: { value: houseType },
  } = useField(`${targetHousingSelfOwnedAttributesName}.house_type`)

  const {
    input: { value: housingType2 },
  } = useField(`${targetHousingSelfOwnedAttributesName}.housing_type2`)

  const {
    input: { value: purchasedAtYear },
  } = useField(`${targetHousingSelfOwnedAttributesName}.purchased_at_year`)

  const {
    input: { value: propertyPriceManyen },
  } = useField(`${targetHousingSelfOwnedAttributesName}.property_price_manyen`)

  const {
    input: { value: propertyPriceLandManyen },
  } = useField(`${targetHousingSelfOwnedAttributesName}.property_price_land_manyen`)

  const {
    input: { value: propertyPriceBuildingManyen },
  } = useField(`${targetHousingSelfOwnedAttributesName}.property_price_building_manyen`)

  const targetPropertyPriceManyen = isMansionType(houseType)
    ? castNumberWithoutOperator(propertyPriceManyen)
    : castNumberWithoutOperator(propertyPriceLandManyen) +
      castNumberWithoutOperator(propertyPriceBuildingManyen)

  const estimatedCost = calcEstimatedMiscellaneousCost(targetPropertyPriceManyen)

  const targetPurchasedAtYear = useMemo(() => targetYear || purchasedAtYear, [
    targetYear,
    purchasedAtYear,
  ])

  const renderYearSelectItem = () => {
    if (!isFuture) {
      return buildStandardSelectItems(
        INPUT_HOUSING_YEAR_SELECT_ITEMS,
        targetYear || THIS_YEAR,
        '年を選択',
      )
    }

    const newHousingType2Group = [
      HOUSING_TYPE2.lowCarbon,
      HOUSING_TYPE2.sumuStock,
      HOUSING_TYPE2.newly,
      HOUSING_TYPE2.fullOrder,
      HOUSING_TYPE2.zehFullOrder,
      HOUSING_TYPE2.zehNewly,
      HOUSING_TYPE2.energySavingFullOrder,
      HOUSING_TYPE2.energySavingNewly,
    ]
    if (_includes(newHousingType2Group, housingType2)) {
      return buildTwoTypesRangeYearSelectItems(
        Number(targetPurchasedAtYear) - 1,
        Number(targetPurchasedAtYear) + 80,
      )
    }

    return buildStandardSelectItems(
      TWO_TYPES_LOAN_YEAR_SELECT_ITEMS,
      targetYear || THIS_YEAR,
      '年を選択',
    )
  }

  return (
    <>
      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
        <Typography
          className="min-w-[140px] lg:min-w-[210px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
          isBold
        >
          築年
        </Typography>
        <SelectBox
          name={`${targetHousingSelfOwnedAttributesName}.constructed_at_year`}
          isPlaceholderHidden
          isFullWidth
          options={renderYearSelectItem()}
          validate={required}
        />
      </div>

      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
        <Typography
          className="min-w-[140px] lg:min-w-[210px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
          isBold
        >
          {isFuture ? '居住開始年' : '引渡時期'}
        </Typography>
        <SelectBox
          name={`${targetHousingSelfOwnedAttributesName}.purchased_at_year`}
          isPlaceholderHidden
          isFullWidth
          options={renderYearSelectItem()}
          validate={required}
        />
      </div>

      {isMansionType(houseType) && (
        <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
          <Typography
            className="min-w-[140px] lg:min-w-[210px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
            isBold
          >
            購入時の価格
          </Typography>
          <div className="flex flex-auto items-baseline">
            <NumberField
              name={`${targetHousingSelfOwnedAttributesName}.property_price_manyen`}
              isFullWidth
              validate={composeValidators(required, zeroOrMoreNumber, (value: any) =>
                commaNumberMaxLength(value, 7),
              )}
            />
            <Typography className="pl-[8px] text-[14px] text-black-800 min-w-[45px]">
              万円
            </Typography>
          </div>
        </div>
      )}

      {!isMansionType(houseType) && (
        <>
          <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
            <Typography
              className="min-w-[140px] lg:min-w-[210px] pb-[10px] text-[14px] text-black-800"
              isBold
            >
              購入時の土地価格
            </Typography>
            <div className="flex flex-auto items-baseline">
              <NumberField
                name={`${targetHousingSelfOwnedAttributesName}.property_price_land_manyen`}
                isFullWidth
                validate={composeValidators(required, zeroOrMoreNumber, (value: any) =>
                  commaNumberMaxLength(value, 7),
                )}
              />
              <Typography
                className="pl-[8px] text-[14px] text-black-800 min-w-[45px]"
                isBold
              >
                万円
              </Typography>
            </div>
          </div>

          <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
            <Typography
              className="min-w-[140px] lg:min-w-[210px] pb-[10px] text-[14px] text-black-800"
              isBold
            >
              購入時の建物価格
            </Typography>
            <div className="flex flex-auto items-baseline">
              <NumberField
                name={`${targetHousingSelfOwnedAttributesName}.property_price_building_manyen`}
                isFullWidth
                validate={composeValidators(required, zeroOrMoreNumber, (value: any) =>
                  commaNumberMaxLength(value, 7),
                )}
              />
              <Typography
                className="pl-[8px] text-[14px] text-black-800 min-w-[45px]"
                isBold
              >
                万円
              </Typography>
            </div>
          </div>
        </>
      )}

      {isFuture && (
        <>
          <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
            <div className="flex items-center mb-[10px]">
              <Typography className="min-w-[140px] text-[14px] text-black-800" isBold>
                上記以外の初期費用
              </Typography>
              <div className="">
                {!targetPropertyPriceManyen ? (
                  <Tooltip
                    itemClassName="w-[200px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                    item="概算入力をするには、まず物件価格を入力してください。"
                  >
                    <span>
                      <Button
                        className="text-[12px] bg-white border border-black-600 border-solid h-37 rounded-4 w-80 hover:bg-black-200 block mr-10 my-[10px]"
                        children="概算を入力"
                        isDisabled
                        onClick={() => {
                          change(
                            `${targetHousingSelfOwnedAttributesName}.miscellaneous_cost_manyen`,
                            estimatedCost,
                          )
                        }}
                      />
                    </span>
                  </Tooltip>
                ) : (
                  <Button
                    className="text-[12px] bg-white border border-black-600 border-solid h-37 rounded-4 w-80 hover:bg-black-200 block mr-10 my-[10px]"
                    children="概算を入力"
                    onClick={() => {
                      change(
                        `${targetHousingSelfOwnedAttributesName}.miscellaneous_cost_manyen`,
                        estimatedCost,
                      )
                    }}
                  />
                )}
              </div>
            </div>

            <div className="flex flex-auto items-baseline">
              <NumberField
                name={`${targetHousingSelfOwnedAttributesName}.miscellaneous_cost_manyen`}
                isFullWidth
                validate={composeValidators(required, zeroOrMoreNumber, (value: any) =>
                  commaNumberMaxLength(value, 6),
                )}
              />
              <Typography className="pl-[8px] text-[14px] text-black-800 min-w-[45px]">
                万円
              </Typography>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default HouseBasicInfo
