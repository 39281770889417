import React, { FC } from 'react'
import { Switch, Route } from 'react-router-dom'
import AccountNew from '@/templates/v2/accounts/New'
import AccountConfirm from '@/templates/v2/accounts/Confirm'
import AccountInvited from '@/templates/v2/accounts/Invited'
import AccountTempCompleted from '@/templates/v2/accounts/TempCompleted'
import DeactivationCompleted from '@/templates/v2/accounts/DeactivationCompleted'
import Activate from '@/templates/v2/accounts/activations/Activate'
import AccountActivateMailSendCompleted from '@/templates/v2/accounts/activations/ActivateMailSendCompleted'
import AccountPasswordNew from '@/templates/v2/accounts/password/New'
import AccountPasswordCompleted from '@/templates/v2/accounts/password/Completed'
import AccountPasswordForgot from '@/templates/v2/accounts/password/Forgot'
import AccountPasswordResetMailSendCompleted from '@/templates/v2/accounts/password/ResetMailSendCompleted'
import AccountPasswordReset from '@/templates/v2/accounts/password/Reset'
import AccountPasswordResetCompleted from '@/templates/v2/accounts/password/ResetCompleted'
import MailHelp from '@/templates/v2/accounts/password/MailHelp'
import Expired from '@/templates/v2/accounts/password/Expired'
import PasswordResetMailReSendCompleted from '@/templates/v2/accounts/password/MailResendCompleted'
import AccountPasswordHandOver from '@/templates/v2/accounts/password/HandOver'
import AccountPasswordHandOverCompleted from '@/templates/v2/accounts/password/HandOverCompleted'

const Account: FC = () => {
  return (
    <Switch>
      <Route exact path="/accounts/new" component={AccountNew} />
      <Route exact path="/v2/accounts/new" component={AccountNew} />
      <Route exact path="/v2/accounts/confirm" component={AccountConfirm} />
      <Route exact path="/v2/accounts/invited" component={AccountInvited} />
      <Route exact path="/v2/accounts/tempCompleted" component={AccountTempCompleted} />
      <Route
        exact
        path="/v2/accounts/deactivationCompleted"
        component={DeactivationCompleted}
      />
      <Route exact path="/v2/accounts/activate" component={Activate} />
      <Route
        exact
        path="/v2/accounts/activateMailSendCompleted"
        component={AccountActivateMailSendCompleted}
      />
      <Route exact path="/v2/accounts/password/new" component={AccountPasswordNew} />
      <Route
        exact
        path="/v2/accounts/password/completed"
        component={AccountPasswordCompleted}
      />
      <Route
        exact
        path="/v2/accounts/password/forgot"
        component={AccountPasswordForgot}
      />
      <Route
        exact
        path="/v2/accounts/password/resetMailSendCompleted"
        component={AccountPasswordResetMailSendCompleted}
      />
      <Route exact path="/v2/accounts/password/reset" component={AccountPasswordReset} />
      <Route
        exact
        path="/v2/accounts/password/resetCompleted"
        component={AccountPasswordResetCompleted}
      />
      <Route path="/v2/accounts/password/mailHelp" component={MailHelp} />
      <Route
        path="/v2/accounts/password/mailResendCompleted"
        component={PasswordResetMailReSendCompleted}
      />
      <Route
        exact
        path="/v2/accounts/password/handOver"
        component={AccountPasswordHandOver}
      />
      <Route
        exact
        path="/v2/accounts/password/handOverCompleted"
        component={AccountPasswordHandOverCompleted}
      />
      <Route path="/v2/accounts/password/expired" component={Expired} />
    </Switch>
  )
}

export default Account
