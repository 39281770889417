import React, { FC, useCallback } from 'react'
import { isNil as _isNil, isUndefined as _isUndefined } from 'lodash'
import Typography from '@/components/v2/atoms/Typography'
import { convertLocaleString } from '@/models/commonsModelFunc'

interface TooltipNetAssetProps {
  /** アクティブ情報 */
  activeIndex: number | undefined
  /** ベースプラン名 */
  basePlanName?: any
  /** true: ベースプラン名非表示, false: ベースプラン名表示 */
  isHideBasePlanName?: boolean
  /** ベースアイテム */
  baseItem: any
  /** true: 比較有り, false: 比較無し */
  isCompare?: boolean
  /** 比較プラン名 */
  comparePlanName?: any
  /** 比較アイテム */
  compareItem?: any
}

const TooltipNetAsset: FC<TooltipNetAssetProps> = (props) => {
  const {
    activeIndex,
    basePlanName,
    isHideBasePlanName,
    baseItem,
    isCompare,
    comparePlanName,
    compareItem,
  } = props

  const activeNetAssetItem = !_isUndefined(activeIndex) ? baseItem?.[activeIndex] : {}
  const compareActiveNetAssetItem = !_isUndefined(activeIndex)
    ? compareItem?.[activeIndex]
    : {}

  const fillIfEmpty = useCallback(
    (str: string) => {
      if (!_isNil(activeNetAssetItem.net_asset_total)) {
        return str
      }
      return '-'
    },
    [activeNetAssetItem],
  )

  const fillIfEmptyCompare = useCallback(
    (str: string) => {
      if (!_isNil(compareActiveNetAssetItem.net_asset_total)) {
        return str
      }
      return '-'
    },
    [compareActiveNetAssetItem],
  )

  return (
    <>
      {!_isUndefined(activeIndex) ? (
        <div
          className={`${
            isCompare ? 'flex' : ''
          } gap-x-[32px] py-[24px] px-[16px] rounded-[8px] bg-white md:shadow-modal visible pointer-events-auto overflow-x-scroll`}
        >
          <div className="w-full md:w-[220px] min-w-[220px]">
            <div className="pb-[16px] last:pb-0">
              {!isHideBasePlanName && (
                <Typography
                  className="pb-[4px] text-[12px] overflow-hidden whitespace-nowrap overflow-ellipsis"
                  isBold
                >
                  ベースプラン：
                  <br />
                  {basePlanName ?? '（プラン名未設定）'}
                </Typography>
              )}
              <div className="p-[10px] rounded-[4px] bg-primary-50">
                <Typography className="pb-[4px]" isBold>
                  {`${activeNetAssetItem.name}年(${activeNetAssetItem.age}歳)`}
                </Typography>
                <div className="flex items-center">
                  <i
                    className="ico circle relative top-px pr-[4px] text-[14px]"
                    style={{ color: '#C891AE' }}
                  />
                  <Typography isBold>
                    純資産合計：
                    {fillIfEmpty(
                      `${convertLocaleString(activeNetAssetItem.net_asset_total)}万円`,
                    )}
                  </Typography>
                </div>
              </div>
            </div>

            <div className="pb-[16px] last:pb-0">
              <div className="flex justify-between mb-[5px] pb-[5px] border-b border-black-300">
                <div className="flex items-center">
                  <i
                    className="ico rect-2 pr-[4px] text-[12px]"
                    style={{ color: '#FFD9B1' }}
                  />
                  <Typography className="text-[12px]" isBold>
                    金融資産
                  </Typography>
                </div>
                <Typography className="text-[12px]" isBold>
                  {fillIfEmpty(
                    `総額　${convertLocaleString(
                      activeNetAssetItem.financial_asset_total,
                    )}万円`,
                  )}
                </Typography>
              </div>
              <div className="flex justify-between pb-[5px] last:pb-0">
                <Typography className="text-[12px]">流動性資産</Typography>
                <Typography className="text-[12px]">
                  {fillIfEmpty(`${convertLocaleString(activeNetAssetItem.deposit)}万円`)}
                </Typography>
              </div>
              <div className="flex justify-between pb-[5px] last:pb-0">
                <Typography className="text-[12px]">株式</Typography>
                <Typography className="text-[12px]">
                  {fillIfEmpty(`${convertLocaleString(activeNetAssetItem.stock)}万円`)}
                </Typography>
              </div>
              <div className="flex justify-between pb-[5px] last:pb-0">
                <Typography className="text-[12px]">保険</Typography>
                <Typography className="text-[12px]">
                  {fillIfEmpty(
                    `${convertLocaleString(activeNetAssetItem.insurance_asset)}万円`,
                  )}
                </Typography>
              </div>
              <div className="flex justify-between pb-[5px] last:pb-0">
                <Typography className="text-[12px]">投資信託</Typography>
                <Typography className="text-[12px]">
                  {fillIfEmpty(
                    `${convertLocaleString(activeNetAssetItem.investment_trust)}万円`,
                  )}
                </Typography>
              </div>
              <div className="flex justify-between pb-[5px] last:pb-0">
                <Typography className="text-[12px]">債券</Typography>
                <Typography className="text-[12px]">
                  {fillIfEmpty(`${convertLocaleString(activeNetAssetItem.bond)}万円`)}
                </Typography>
              </div>
              <div className="flex justify-between pb-[5px] last:pb-0">
                <Typography className="text-[12px]">
                  社内預金
                  {isCompare ? (
                    <>
                      <span className="hidden md:inline">・</span>
                      <br className="block md:hidden" />
                    </>
                  ) : (
                    <span>・</span>
                  )}
                  財形
                  {isCompare ? (
                    <>
                      <span className="hidden md:inline">・</span>
                      <br className="block md:hidden" />
                    </>
                  ) : (
                    <span>・</span>
                  )}
                  持株など
                </Typography>
                <Typography className="text-[12px]">
                  {fillIfEmpty(
                    `${convertLocaleString(activeNetAssetItem.deducted_savings)}万円`,
                  )}
                </Typography>
              </div>
              <div className="flex justify-between pb-[5px] last:pb-0">
                <Typography className="text-[12px]">その他</Typography>
                <Typography className="text-[12px]">
                  {fillIfEmpty(`${convertLocaleString(activeNetAssetItem.other)}万円`)}
                </Typography>
              </div>
            </div>

            <div className="pb-[16px] last:pb-0">
              <div className="flex justify-between mb-[5px] pb-[5px] border-b border-black-300">
                <div className="flex items-center">
                  <i
                    className="ico rect-2 pr-[4px] text-[12px]"
                    style={{ color: '#AFE2E9' }}
                  />
                  <Typography className="text-[12px]" isBold>
                    不動産
                  </Typography>
                </div>
                <Typography className="text-[12px]" isBold>
                  {fillIfEmpty(
                    `総額　${convertLocaleString(
                      activeNetAssetItem.estate_evaluation_total_manyen +
                        activeNetAssetItem.housing_asset_evaluation,
                    )}万円`,
                  )}
                </Typography>
              </div>
              <div className="flex justify-between pb-[5px] last:pb-0">
                <Typography className="text-[12px]">自宅</Typography>
                <Typography className="text-[12px]">
                  {fillIfEmpty(
                    `${convertLocaleString(
                      activeNetAssetItem.housing_asset_evaluation,
                    )}万円`,
                  )}
                </Typography>
              </div>

              {activeNetAssetItem.estate_evaluations_manyen &&
                Object.keys(activeNetAssetItem.estate_evaluations_manyen).map(
                  (key, index) => (
                    <div key={key} className="flex justify-between pb-[5px] last:pb-0">
                      <Typography className="text-[12px]">{`物件${
                        index + 1
                      }`}</Typography>
                      <Typography className="text-[12px]">
                        {fillIfEmpty(
                          `${convertLocaleString(
                            activeNetAssetItem.estate_evaluations_manyen[key],
                          )}万円`,
                        )}
                      </Typography>
                    </div>
                  ),
                )}
            </div>

            <div className="pb-[16px] last:pb-0">
              <div className="flex justify-between mb-[5px] pb-[5px] border-b border-black-300">
                <div className="flex items-center">
                  <i
                    className="ico rect-2 pr-[4px] text-[12px]"
                    style={{ color: '#F6B2A1' }}
                  />
                  <Typography className="text-[12px]" isBold>
                    負債
                  </Typography>
                </div>
                <Typography className="text-[12px]" isBold>
                  {fillIfEmpty(
                    `総額　${convertLocaleString(
                      activeNetAssetItem.substruction_total,
                    )}万円`,
                  )}
                </Typography>
              </div>
              <div className="flex justify-between pb-[5px] last:pb-0">
                <Typography className="text-[12px]">住宅ローン</Typography>
                <Typography className="text-[12px]">
                  {fillIfEmpty(
                    `${convertLocaleString(activeNetAssetItem.housing_loan_balance)}万円`,
                  )}
                </Typography>
              </div>
              <div className="flex justify-between pb-[5px] last:pb-0">
                <Typography className="text-[12px]">
                  不動産投資用
                  {isCompare && <br className="block md:hidden" />}
                  ローン
                </Typography>
                <Typography className="text-[12px]">
                  {fillIfEmpty(
                    `${convertLocaleString(
                      activeNetAssetItem.estate_investment_remain_loan_amount_total,
                    )}万円`,
                  )}
                </Typography>
              </div>
              <div className="flex justify-between pb-[5px] last:pb-0">
                <Typography className="text-[12px]">カーローン</Typography>
                <Typography className="text-[12px]">
                  {fillIfEmpty(
                    `${convertLocaleString(activeNetAssetItem.car_loan_balance)}万円`,
                  )}
                </Typography>
              </div>
              <div className="flex justify-between pb-[5px] last:pb-0">
                <Typography className="text-[12px]">その他負債</Typography>
                <Typography className="text-[12px]">
                  {fillIfEmpty(
                    `${convertLocaleString(activeNetAssetItem.substruction)}万円`,
                  )}
                </Typography>
              </div>
            </div>
          </div>

          {isCompare && (
            <div className="w-full md:w-[220px] min-w-[220px]">
              <div className="pb-[16px] last:pb-0">
                <Typography
                  className="pb-[4px] text-[12px] overflow-hidden whitespace-nowrap overflow-ellipsis"
                  isBold
                >
                  比較プラン：
                  <br />
                  {comparePlanName ?? '（プラン名未設定）'}
                </Typography>
                <div className="p-[10px] rounded-[4px] bg-primary-50">
                  <Typography className="pb-[4px]" isBold>
                    {`${compareActiveNetAssetItem.name}年(${compareActiveNetAssetItem.age}歳)`}
                  </Typography>
                  <div className="flex items-center">
                    <i
                      className="ico circle relative top-px pr-[4px] text-[14px]"
                      style={{ color: '#C891AE' }}
                    />
                    <Typography isBold>
                      純資産合計：
                      {fillIfEmptyCompare(
                        `${convertLocaleString(
                          compareActiveNetAssetItem.net_asset_total,
                        )}万円`,
                      )}
                    </Typography>
                  </div>
                </div>
              </div>

              <div className="pb-[16px] last:pb-0">
                <div className="flex justify-between mb-[5px] pb-[5px] border-b border-black-300">
                  <div className="flex items-center">
                    <i
                      className="ico rect-2 pr-[4px] text-[12px]"
                      style={{ color: '#FFD9B1' }}
                    />
                    <Typography className="text-[12px]" isBold>
                      金融資産
                    </Typography>
                  </div>
                  <Typography className="text-[12px]" isBold>
                    {fillIfEmptyCompare(
                      `総額　${convertLocaleString(
                        compareActiveNetAssetItem.financial_asset_total,
                      )}万円`,
                    )}
                  </Typography>
                </div>
                <div className="flex justify-between pb-[5px] last:pb-0">
                  <Typography className="text-[12px]">流動性資産</Typography>
                  <Typography className="text-[12px]">
                    {fillIfEmptyCompare(
                      `${convertLocaleString(compareActiveNetAssetItem.deposit)}万円`,
                    )}
                  </Typography>
                </div>
                <div className="flex justify-between pb-[5px] last:pb-0">
                  <Typography className="text-[12px]">株式</Typography>
                  <Typography className="text-[12px]">
                    {fillIfEmptyCompare(
                      `${convertLocaleString(compareActiveNetAssetItem.stock)}万円`,
                    )}
                  </Typography>
                </div>
                <div className="flex justify-between pb-[5px] last:pb-0">
                  <Typography className="text-[12px]">保険</Typography>
                  <Typography className="text-[12px]">
                    {fillIfEmptyCompare(
                      `${convertLocaleString(
                        compareActiveNetAssetItem.insurance_asset,
                      )}万円`,
                    )}
                  </Typography>
                </div>
                <div className="flex justify-between pb-[5px] last:pb-0">
                  <Typography className="text-[12px]">投資信託</Typography>
                  <Typography className="text-[12px]">
                    {fillIfEmptyCompare(
                      `${convertLocaleString(
                        compareActiveNetAssetItem.investment_trust,
                      )}万円`,
                    )}
                  </Typography>
                </div>
                <div className="flex justify-between pb-[5px] last:pb-0">
                  <Typography className="text-[12px]">債券</Typography>
                  <Typography className="text-[12px]">
                    {fillIfEmptyCompare(
                      `${convertLocaleString(compareActiveNetAssetItem.bond)}万円`,
                    )}
                  </Typography>
                </div>
                <div className="flex justify-between pb-[5px] last:pb-0">
                  <Typography className="text-[12px]">
                    社内預金
                    <span className="hidden md:inline">・</span>
                    <br className="block md:hidden" />
                    財形
                    <span className="hidden md:inline">・</span>
                    <br className="block md:hidden" />
                    持株など
                  </Typography>
                  <Typography className="text-[12px]">
                    {fillIfEmptyCompare(
                      `${convertLocaleString(
                        compareActiveNetAssetItem.deducted_savings,
                      )}万円`,
                    )}
                  </Typography>
                </div>
                <div className="flex justify-between pb-[5px] last:pb-0">
                  <Typography className="text-[12px]">その他</Typography>
                  <Typography className="text-[12px]">
                    {fillIfEmptyCompare(
                      `${convertLocaleString(compareActiveNetAssetItem.other)}万円`,
                    )}
                  </Typography>
                </div>
              </div>

              <div className="pb-[16px] last:pb-0">
                <div className="flex justify-between mb-[5px] pb-[5px] border-b border-black-300">
                  <div className="flex items-center">
                    <i
                      className="ico rect-2 pr-[4px] text-[12px]"
                      style={{ color: '#AFE2E9' }}
                    />
                    <Typography className="text-[12px]" isBold>
                      不動産
                    </Typography>
                  </div>
                  <Typography className="text-[12px]" isBold>
                    {fillIfEmptyCompare(
                      `総額　${convertLocaleString(
                        compareActiveNetAssetItem.estate_evaluation_total_manyen +
                          compareActiveNetAssetItem.housing_asset_evaluation,
                      )}万円`,
                    )}
                  </Typography>
                </div>

                <div className="flex justify-between pb-[5px] last:pb-0">
                  <Typography className="text-[12px]">自宅</Typography>
                  <Typography className="text-[12px]">
                    {fillIfEmptyCompare(
                      `${convertLocaleString(
                        compareActiveNetAssetItem.housing_asset_evaluation,
                      )}万円`,
                    )}
                  </Typography>
                </div>

                {compareActiveNetAssetItem.estate_evaluations_manyen &&
                  Object.keys(compareActiveNetAssetItem.estate_evaluations_manyen).map(
                    (key, index) => (
                      <div key={key} className="flex justify-between pb-[5px] last:pb-0">
                        <Typography className="text-[12px]">{`物件${
                          index + 1
                        }`}</Typography>
                        <Typography className="text-[12px]">
                          {fillIfEmptyCompare(
                            `${convertLocaleString(
                              compareActiveNetAssetItem.estate_evaluations_manyen[key],
                            )}万円`,
                          )}
                        </Typography>
                      </div>
                    ),
                  )}
              </div>

              <div className="pb-[16px] last:pb-0">
                <div className="flex justify-between mb-[5px] pb-[5px] border-b border-black-300">
                  <div className="flex items-center">
                    <i
                      className="ico rect-2 pr-[4px] text-[12px]"
                      style={{ color: '#F6B2A1' }}
                    />
                    <Typography className="text-[12px]" isBold>
                      負債
                    </Typography>
                  </div>
                  <Typography className="text-[12px]" isBold>
                    {fillIfEmptyCompare(
                      `総額　${convertLocaleString(
                        compareActiveNetAssetItem.substruction_total,
                      )}万円`,
                    )}
                  </Typography>
                </div>
                <div className="flex justify-between pb-[5px] last:pb-0">
                  <Typography className="text-[12px]">住宅ローン</Typography>
                  <Typography className="text-[12px]">
                    {fillIfEmptyCompare(
                      `${convertLocaleString(
                        compareActiveNetAssetItem.housing_loan_balance,
                      )}万円`,
                    )}
                  </Typography>
                </div>
                <div className="flex justify-between pb-[5px] last:pb-0">
                  <Typography className="text-[12px]">
                    不動産投資用
                    <br className="block md:hidden" />
                    ローン
                  </Typography>
                  <Typography className="text-[12px]">
                    {fillIfEmptyCompare(
                      `${convertLocaleString(
                        compareActiveNetAssetItem.estate_investment_remain_loan_amount_total,
                      )}万円`,
                    )}
                  </Typography>
                </div>
                <div className="flex justify-between pb-[5px] last:pb-0">
                  <Typography className="text-[12px]">カーローン</Typography>
                  <Typography className="text-[12px]">
                    {fillIfEmptyCompare(
                      `${convertLocaleString(
                        compareActiveNetAssetItem.car_loan_balance,
                      )}万円`,
                    )}
                  </Typography>
                </div>
                <div className="flex justify-between pb-[5px] last:pb-0">
                  <Typography className="text-[12px]">その他負債</Typography>
                  <Typography className="text-[12px]">
                    {fillIfEmptyCompare(
                      `${convertLocaleString(
                        compareActiveNetAssetItem.substruction,
                      )}万円`,
                    )}
                  </Typography>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : null}
    </>
  )
}

export default TooltipNetAsset
