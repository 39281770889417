import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getPaymentHistories } from '@/api/paymentHistories'
import { PaymentHistoryResponse, PaymentHistory } from '@/models/paymentHistoriesModel'
import { AppThunk } from '@/store'

interface PaymentHistoriesState {
  paymentHistories: PaymentHistory[]
  isLoading: boolean
}

const initialState: PaymentHistoriesState = {
  paymentHistories: [],
  isLoading: false,
}

const paymentHistoriesSlice = createSlice({
  name: 'paymentHistories',
  initialState,
  reducers: {
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    getPaymentHistoriesSuccess: (
      state,
      action: PayloadAction<PaymentHistoryResponse[]>,
    ) => {
      state.paymentHistories = action.payload
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
  },
})

export const {
  requestStart,
  requestSuccess,
  getPaymentHistoriesSuccess,
  requestFailure,
} = paymentHistoriesSlice.actions
export const paymentHistoriesReducer = paymentHistoriesSlice.reducer

export const fetchPaymentHistories = (): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  getPaymentHistories()
    .then((response) => dispatch(getPaymentHistoriesSuccess(response)))
    .catch(() => dispatch(requestFailure()))
}
