import React, { FC } from 'react'
import { Route, Switch } from 'react-router-dom'
import Household from '@/templates/v2/plans/Household'
import MoneytreeConnection from '@/templates/v2/plans/MoneytreeConnection'
import MoneytreeRegistration from '@/templates/v2/plans/MoneytreeRegistration'
import DataConnection from '@/templates/v2/plans/DataConnection'
import MoneytreeDataConfirmation from '@/templates/v2/plans/MoneytreeDataConfirmation'
import AssetBalance from '@/templates/v2/plans/AssetBalance'

const Plan: FC = () => {
  return (
    <Switch>
      <Route exact path="/v2/plans/household" component={Household} />
      <Route exact path="/v2/plans/moneytreeConnection" component={MoneytreeConnection} />
      <Route
        exact
        path="/v2/plans/moneytreeRegistration"
        component={MoneytreeRegistration}
      />
      <Route exact path="/v2/plans/dataConnection" component={DataConnection} />
      <Route
        exact
        path="/v2/plans/moneytreeDataConfirmation"
        component={MoneytreeDataConfirmation}
      />
      <Route exact path="/v2/plans/assetBalance" component={AssetBalance} />
    </Switch>
  )
}

export default Plan
