import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import StripedTable from '@/components/v2/molecules/StripedTable'
import Modal from '@/components/v2/atoms/Modal'
import { RootState } from '@/store'
import { pick as _pick } from 'lodash'

interface FamilyAgeModalProps {
  /** true: オープン, false: クローズ */
  isOpen: boolean
  /** モーダルクローズメソッド */
  handleClose: () => void
}

const FamilyAgeModal: FC<FamilyAgeModalProps> = (props) => {
  const { isOpen, handleClose } = props

  const { detailFutureBaseV2 } = useSelector((state: RootState) => state.futureBases)

  return (
    <Modal
      className="w-[95%] xl:w-[1140px]"
      isOpen={isOpen}
      isDisableBackdropClick
      onClose={handleClose}
    >
      {detailFutureBaseV2.statement_table_data &&
        detailFutureBaseV2.family_structure_table_columns && (
          <StripedTable
            tableData={Object.keys(detailFutureBaseV2.statement_table_data).reduce(
              (acc: any, year: any) => {
                acc[year] = _pick(
                  detailFutureBaseV2.statement_table_data[year],
                  detailFutureBaseV2.family_structure_table_columns.map(
                    (obj: any) => obj.key,
                  ),
                )
                return acc
              },
              {},
            )}
            columnData={detailFutureBaseV2.family_structure_table_columns}
            formatter={(v, k) => v && `${v}歳`}
          />
        )}
    </Modal>
  )
}

export default FamilyAgeModal
