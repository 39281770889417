import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import Typography from '@/components/v2/atoms/Typography'
import Modal from '@/components/v2/atoms/Modal'
import Button from '@/components/v2/atoms/Button'
import { RootState } from '@/store'
import SimulationAccordion from '@/templates/v2/simulations/_simulationAccordion'
import MyDataFamilyEditForm from '@/templates/v2/myData/families/Form'

export const FamilyTable: FC<any> = () => {
  const { familyStructures } = useSelector((state: RootState) => state.familyStructures)

  return (
    <table className="w-[100%] text-[12px] md:text-[14px] bg-white">
      <tbody>
        <tr className="h-[45px] odd:bg-black-100">
          <td className="w-[60%] px-[16px]">
            <Typography isBold>あなたの生年月日</Typography>
          </td>
          <td className="w-[40%] px-[16px]">
            <Typography>{familyStructures?.birth_day}</Typography>
          </td>
        </tr>

        {familyStructures?.spouse_type === 0 && (
          <tr className="h-[45px] odd:bg-black-100">
            <td className="w-[60%] px-[16px]">
              <Typography isBold>配偶者の生年月日</Typography>
            </td>
            <td className="w-[40%] px-[16px]">
              <Typography>{familyStructures?.spouse_birth_day}</Typography>
            </td>
          </tr>
        )}

        {familyStructures?.spouse_type === 0 && (
          <tr className="h-[45px] odd:bg-black-100">
            <td className="w-[60%] px-[16px]">
              <Typography isBold>入籍日</Typography>
            </td>
            <td className="w-[40%] px-[16px]">
              <Typography>{familyStructures?.enrollment_day}</Typography>
            </td>
          </tr>
        )}

        {familyStructures?.family_structure_children?.map((item: any) => (
          <tr className="h-[45px] odd:bg-black-100" key={`child_${item.child_index}`}>
            <td className="w-[60%] px-[16px]">
              <Typography isBold>{`第${item.child_index}子の生年月日`}</Typography>
            </td>
            <td className="w-[40%] px-[16px]">
              <Typography>{item.birth_day}</Typography>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

const FamilyBody: FC<{ onUpdate: () => void }> = (props) => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const closeModal = () => setIsOpenModal(false)
  const openModal = () => setIsOpenModal(true)

  return (
    <>
      <SimulationAccordion className="pb-[16px]" title="ご家族の情報">
        <div className="flex justify-end mb-[8px]">
          <Button onClick={openModal}>
            <i className="ico edit text-[14px] text-black-700" />
            <Typography className="pl-[5px] text-[14px] text-black-700">編集</Typography>
          </Button>
        </div>
        <FamilyTable />
      </SimulationAccordion>

      <Modal className="lg:w-[1024px] w-[95%]" isOpen={isOpenModal} onClose={closeModal}>
        <MyDataFamilyEditForm
          onClose={closeModal}
          onSubmit={() => {
            closeModal()
            props.onUpdate()
          }}
        />
      </Modal>
    </>
  )
}

export default FamilyBody
