import React, { FC, FocusEvent, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import {
  isEmpty as _isEmpty,
  isUndefined as _isUndefined,
  pick as _pick,
  isNull as _isNull,
  every as _every,
  filter as _filter,
  slice as _slice,
  reduce as _reduce,
  find as _find,
} from 'lodash'
import ChevronRightIcon from '@/assets/images/v2/chevron-right.svg'
import DepreciationTable from '@/assets/images/v2/depreciation-table.png'
import Button from '@/components/v2/atoms/Button'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import Grid from '@/components/v2/atoms/Grid'
import TextField from '@/components/v2/atoms/TextField'
import Typography from '@/components/v2/atoms/Typography'
import NumberField from '@/components/v2/molecules/NumberField'
import NumberFieldDecimal from '@/components/v2/molecules/NumberFieldDecimal'
import RadioGroup from '@/components/v2/molecules/RadioGroup'
import SelectBox from '@/components/v2/molecules/SelectBox'
import ImageMagnifiableModal from '@/components/v2/organisms/ImageModal'
import { RootState } from '@/store'
import {
  composeValidators,
  zeroOrMoreNumber,
  commaNumberMaxLength,
  required,
  maxLength,
  oneHundredLessThanNumber,
  oneOrMoreNumber,
  zeroGreaterThanNumber,
} from '@/utils/validate'
import {
  REPAIR_RESERVE_FUND_INCREASE_TYPE_SELECT_ITEMS,
  RENEWAL_FEE_TYPE_RADIO_ITEMS,
  ESTATE_INVESTMENT_SPAN_SELECT_ITEMS,
  TAX_PAPER_PRESENCE_TYPE_RADIO_ITEMS,
} from '@/constants/formItem/commonEstateInvestment'
import {
  MONTH_KANJI_SELECT_ITEMS,
  NEW_DAY_KANJI_SELECT_ITEMS,
} from '@/constants/formItem/commons'
import {
  PERSON_FLG_SELECT_ITEMS,
  PERSON_WITHOUT_SPOUSE_SELECT_ITEM,
} from '@/constants/formItem/familyStructures'
import {
  INPUT_PROPERTY_TYPE_RADIO_ITEMS,
  INPUT_ANNUAL_RATE_OF_DECLINE_TYPE_RADIO_ITEMS,
  INPUT_MANAGEMENT_FEE_TYPE_RADIO_ITEMS,
  INPUT_VACANCY_SETTING_TYPE_RADIO_ITEMS,
  INPUT_RATE_CHANGE_TYPE_RADIO_ITEMS,
  INPUT_LIFE_INSURANCE_TYPE_RADIO_ITEMS,
  INPUT_ADVANCE_REPAYMENT_TYPE_RADIO_ITEMS,
  INPUT_REPAYMENT_TYPE_SELECT_ITEMS,
} from '@/constants/formItem/inputs/inputEstateInvestments'
import {
  createInputEstateInvestment,
  fetchInputEstateInvestments,
  updateInputEstateInvestment,
} from '@/containers/inputs/inputEstateInvestmentsSlice'
import {
  calcEstimatedPropertyTax,
  RENEWAL_FEE_INPUT_TYPE,
  REPAIR_RESERVE_FUND_INCREASE_TYPE,
  TAX_PAPER_PRESENCE_TYPE,
} from '@/models/commonEstateInvestmentModelFunc'
import {
  THIS_YEAR,
  API_FLAG,
  castNumber,
  addMomentDate,
  convertDateDay,
  extractYearOrMonthFromDate,
  extractDayFromDate,
  addZeroToStringMonth,
  THIS_YEAR_NUMBER,
  isNotEmptyValue,
  removeZeroToNumberDay,
  convertDateYear,
  API_BOOLEAN_FLAG,
  convertLocaleString,
  castNumberWithoutOperator,
} from '@/models/commonsModelFunc'
import {
  InputDecline,
  InputDeclineCreateValues,
  InputDeclineUpdateValues,
} from '@/models/inputs/inputDeclinesModel'
import {
  InputEstateLoanCalculation,
  InputEstateLoanCalculationCreateValues,
  InputEstateLoanCalculationUpdateValues,
} from '@/models/inputs/inputEstateLoanCalculationsModel'
import {
  InputRateChange,
  InputRateChangeCreateValues,
} from '@/models/inputs/inputRateChangesModel'
import {
  InputEstateInvestmentCommonValues,
  InputEstateInvestmentCreateValues,
  InputEstateInvestmentUpdateValues,
} from '@/models/inputs/inputEstateInvestmentsModel'
import {
  INPUT_PROPERTY_TYPE,
  INPUT_ESTATE_INVESTMENT_DEFAULT_VALUES,
  INPUT_MANAGEMENT_FEE_TYPE,
  INPUT_VACANCY_SETTING_TYPE,
  INPUT_VACANCY_RATE_FIELDS,
  calcVacancyRate,
  convertYearOfConstruction,
} from '@/models/inputs/inputEstateInvestmentsModelFunc'
import { buildTwoTypesRangeYearSelectItems } from '@/utils/formItem'
import { Decorator } from 'final-form'
import createDecorator from 'final-form-calculate'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import { FieldArray } from 'react-final-form-arrays'
import { useFlashAlert } from '@/hooks/useFlashAlert'

import Tooltip from '@/components/v2/atoms/Tooltip'
import HelpCircleIcon from '@/assets/images/v2/help-circle.svg'
import CrossIcon from '@/assets/images/v2/cross.svg'
import PlusCircleIcon from '@/assets/images/v2/plus-circle.svg'
import { fetchFamilyStructure } from '@/containers/familyStructuresSlice'
import WholeSubmitErrorMessage from '@/components/v2/molecules/WholeSubmitErrorMessage'
import LoadingOverlay from '@/components/v1/atoms/LoadingOverlay'
import CityBankInterestRateTable from '@/components/v2/atoms/CityBankInterestRateTable'
import clsx from 'clsx'
import SubmitErrorMessage from '@/components/v2/molecules/SubmitErrorMessage'
import { updateResetFlg } from '@/containers/futures/futureBasesSlice'

const calculator: Decorator<any> = createDecorator({
  field: INPUT_VACANCY_RATE_FIELDS,
  updates: {
    'input_estate_investment.vacancy_rate_percent': (
      _,
      allValues: InputEstateInvestmentCreateValues,
    ) => calcVacancyRate(allValues),
  },
})

interface MyDataEstateInvestmentFormProps {
  id?: string
  onClose: () => void
  onSubmit: () => void
}

const MyDataEstateInvestmentForm: FC<MyDataEstateInvestmentFormProps> = (props) => {
  const dispatch = useDispatch()
  const { showFlashMsg } = useFlashAlert()

  const { id } = props

  const [isImage1ModalOpen, setIsImage1ModalOpen] = useState<boolean>(false)

  const { inputEstateInvestments, isLoading } = useSelector(
    (state: RootState) => state.inputEstateInvestments,
  )
  const { familyStructures } = useSelector((state: RootState) => state.familyStructures)
  const isLoadingFamilyStructures = useSelector(
    (state: RootState) => state.familyStructures.isLoading,
  )

  const hasSpouse = familyStructures?.spouse_type === API_FLAG.on

  const targetInputEstateInvestment = useMemo(() => {
    return _find(inputEstateInvestments, { id: Number(id) })
  }, [inputEstateInvestments, id])

  const isEdit = useMemo(() => !!id && !!targetInputEstateInvestment, [
    targetInputEstateInvestment,
    id,
  ])

  const initialValues: any = useMemo(
    () => (isEdit ? _find(inputEstateInvestments, { id: Number(id) }) : {}),
    [inputEstateInvestments],
  )

  useEffect(() => {
    if (inputEstateInvestments.length == 0) dispatch(fetchInputEstateInvestments())
    dispatch(fetchFamilyStructure())
  }, [])

  const handleAddInputRateChangeBlock = (
    inputRateChanges: Partial<InputRateChange>[],
    index: number,
    change: (key: string, values: Partial<InputRateChange>[]) => void,
  ) => {
    const isEnd = index + 1 == inputRateChanges.length
    isEnd ? inputRateChanges.push({}) : inputRateChanges.splice(index + 1, 0, {})

    change(
      'input_estate_investment.input_rate_changes_other_attributes',
      inputRateChanges,
    )
  }

  const handleAddInputEstateLoanCalculationBlock = (
    inputEstateLoanCalculationes: Partial<InputEstateLoanCalculation>[],
    index: number,
    change: (key: string, values: Partial<InputEstateLoanCalculation>[]) => void,
  ) => {
    const isEnd = index + 1 == inputEstateLoanCalculationes.length
    isEnd
      ? inputEstateLoanCalculationes.push({})
      : inputEstateLoanCalculationes.splice(index + 1, 0, {})

    change(
      'input_estate_investment.input_estate_loan_calculations_attributes',
      inputEstateLoanCalculationes,
    )
  }

  const handleChangeInitialValues = (
    rentIncomeMonthly: number | null,
    change: (key: string, values: number) => void,
  ) => {
    if (_isNull(rentIncomeMonthly)) return
    change('input_estate_investment.renewal_fee', rentIncomeMonthly)
    change('input_estate_investment.repair_costs', rentIncomeMonthly)
  }

  const handleClickPropertyTaxButton = (
    propertyPriceManyen: number | null,
    change: (key: string, values: number) => void,
  ) => {
    if (_isNull(propertyPriceManyen)) return

    change(
      'input_estate_investment.property_tax',
      calcEstimatedPropertyTax(castNumberWithoutOperator(propertyPriceManyen)),
    )
  }

  // 年間下落率の期間の追加
  const handleAddDeclineRateBlock = (
    declineRates: Partial<InputDecline>[],
    index: number,
    change: (key: string, value: Partial<InputDecline>[]) => void,
  ) => {
    const isEnd = index + 1 == declineRates.length
    isEnd ? declineRates.push({}) : declineRates.splice(index + 1, 0, {})

    change('input_estate_investment.input_declines_attributes', declineRates)
  }

  const buildSubmitValues = (values: Partial<InputEstateInvestmentCommonValues>) => {
    const { property_type, loan_flg, repayment_chart } = values
    const inputEstateInvestment = values

    let newInputEstateInvestment: Partial<InputEstateInvestmentCommonValues> = {
      delivery_date: convertDateYear(inputEstateInvestment.delivery_year),
      ..._pick(inputEstateInvestment, [
        'person_flg',
        'property_name',
        'property_price_manyen',
        'property_type',
        'year_of_construction',
        'rent_income_monthly',
        'annual_rate_of_decline_type',
        'property_tax',
        'repair_costs',
        'repair_costs_span',
        'equipment_durable_years',
        'equipment_depreciation',
        'building_durable_years',
        'building_depreciation',
        'management_fee_type',
        'vacancy_rate_percent',
        'other_expenses',
        'loan_flg',
      ]),
    }

    // 空室率
    newInputEstateInvestment.vacancy_rate_percent = Math.round(
      newInputEstateInvestment.vacancy_rate_percent!,
    )

    // 建物・賃貸管理手数料
    if (inputEstateInvestment.management_fee_type == INPUT_MANAGEMENT_FEE_TYPE.rate) {
      // 比率で設定
      newInputEstateInvestment.management_fee_rate_percent =
        inputEstateInvestment.management_fee_rate_percent
    } else {
      // 円で設定
      // MEMO:入力時は月間、送信時は年間の値として処理
      newInputEstateInvestment.management_fee_money =
        castNumberWithoutOperator(inputEstateInvestment.management_fee_money) * 12
    }

    // 物件タイプ
    switch (property_type) {
      // 区分
      case INPUT_PROPERTY_TYPE.section:
        newInputEstateInvestment = {
          ...newInputEstateInvestment,
          breadth: Number(inputEstateInvestment.breadth),
          management_fee_monthly: !_isNull(inputEstateInvestment.management_fee_monthly)
            ? inputEstateInvestment.management_fee_monthly
            : 0,
          repair_reserve_fund: !_isNull(inputEstateInvestment.repair_reserve_fund)
            ? inputEstateInvestment.repair_reserve_fund
            : 0,
          repair_reserve_fund_increase_type: !_isNull(
            inputEstateInvestment.repair_reserve_fund_increase_type,
          )
            ? inputEstateInvestment.repair_reserve_fund_increase_type
            : REPAIR_RESERVE_FUND_INCREASE_TYPE.noSet,
        }

        const isManagementAndFundTotal: any =
          (inputEstateInvestment.is_management_and_fund_total as any) ==
          String(API_BOOLEAN_FLAG.on)

        // 管理費・修繕積立金: 総額入力を選択
        if (isManagementAndFundTotal) {
          newInputEstateInvestment.management_fee_monthly_and_repair_reserve_fund_total =
            inputEstateInvestment.management_fee_monthly_and_repair_reserve_fund_total
        }

        // 管理費・修繕積立金: 詳細入力を選択
        if (!isManagementAndFundTotal) {
          newInputEstateInvestment.management_fee_monthly_and_repair_reserve_fund_total = null
          newInputEstateInvestment.management_fee_monthly =
            inputEstateInvestment.management_fee_monthly
          newInputEstateInvestment.repair_reserve_fund =
            inputEstateInvestment.repair_reserve_fund
          newInputEstateInvestment.repair_reserve_fund_increase_type =
            inputEstateInvestment.repair_reserve_fund_increase_type
        }

        // 月間修繕積立金の上昇率
        if (
          !isManagementAndFundTotal &&
          inputEstateInvestment.repair_reserve_fund_increase_type ==
            REPAIR_RESERVE_FUND_INCREASE_TYPE.fixed
        ) {
          // 固定
          newInputEstateInvestment.repair_reserve_fund_amount =
            inputEstateInvestment.repair_reserve_fund_amount
          newInputEstateInvestment.repair_reserve_fund_amount_start =
            inputEstateInvestment.repair_reserve_fund_amount_start
          newInputEstateInvestment.repair_reserve_fund_amount_finish =
            inputEstateInvestment.repair_reserve_fund_amount_finish
        }

        if (
          !isManagementAndFundTotal &&
          inputEstateInvestment.repair_reserve_fund_increase_type !=
            REPAIR_RESERVE_FUND_INCREASE_TYPE.fixed
        ) {
          // 変動
          newInputEstateInvestment = {
            ...newInputEstateInvestment,
            repair_reserve_fund_increase_start:
              inputEstateInvestment.repair_reserve_fund_increase_start,
            repair_reserve_fund_increase_finish:
              inputEstateInvestment.repair_reserve_fund_increase_finish,
            repair_reserve_fund_increase_span:
              inputEstateInvestment.repair_reserve_fund_increase_span,
            repair_reserve_fund_increase_percent:
              inputEstateInvestment.repair_reserve_fund_increase_percent,
          }
        }
        break

      // 1棟
      case INPUT_PROPERTY_TYPE.building:
        newInputEstateInvestment.management_fee_monthly_and_repair_reserve_fund_total = null
        newInputEstateInvestment.total_floor_area = Number(
          inputEstateInvestment.total_floor_area,
        )
        newInputEstateInvestment.site_area = Number(inputEstateInvestment.site_area)
        break
    }

    // 「年間下落率：設定しない」
    if (isEdit && inputEstateInvestment.annual_rate_of_decline_type == API_FLAG.off) {
      newInputEstateInvestment.input_declines_attributes = []
    }

    // 「年間下落率：設定する」
    if (inputEstateInvestment.annual_rate_of_decline_type == API_FLAG.on) {
      isEdit
        ? (newInputEstateInvestment.input_declines_attributes = inputEstateInvestment.input_declines_attributes?.map(
            (item: InputDeclineUpdateValues, index: number) => ({
              id:
                initialValues.input_declines.length ==
                inputEstateInvestment.input_declines_attributes!.length
                  ? inputEstateInvestment.input_declines?.[index]?.id
                  : undefined,
              annual_rate_of_decline_year:
                index !== 0
                  ? addMomentDate(
                      THIS_YEAR,
                      castNumber(item.annual_rate_of_decline_year),
                      'years',
                      'YYYY/MM/DD',
                    )
                  : null,
              annual_rate_of_decline_percent: item.annual_rate_of_decline_percent,
            }),
          ))
        : (newInputEstateInvestment.input_declines_attributes = inputEstateInvestment.input_declines_attributes!.map(
            (item: InputDeclineCreateValues, index: number) => ({
              annual_rate_of_decline_year:
                index !== 0
                  ? addMomentDate(
                      THIS_YEAR,
                      castNumber(item.annual_rate_of_decline_year),
                      'years',
                      'YYYY/MM/DD',
                    )
                  : null,
              annual_rate_of_decline_percent: item.annual_rate_of_decline_percent,
            }),
          ))
    }

    // 礼金・更新料を契約している
    if (inputEstateInvestment.renewal_fee_type == API_FLAG.on) {
      newInputEstateInvestment.renewal_fee_period_months =
        inputEstateInvestment.renewal_fee_period_months
    }
    // 礼金・更新料を契約している かつ 円で入力
    if (
      inputEstateInvestment.renewal_fee_type == API_FLAG.on &&
      inputEstateInvestment.renewal_fee_input_type == RENEWAL_FEE_INPUT_TYPE.yen
    ) {
      newInputEstateInvestment.renewal_fee = inputEstateInvestment.renewal_fee
      newInputEstateInvestment.renewal_fee_to_rent_income_rate = null
    }
    // 礼金・更新料を契約している かつ 家賃のOヶ月分で入力
    if (
      inputEstateInvestment.renewal_fee_type == API_FLAG.on &&
      inputEstateInvestment.renewal_fee_input_type == RENEWAL_FEE_INPUT_TYPE.month
    ) {
      newInputEstateInvestment.renewal_fee = null
      newInputEstateInvestment.renewal_fee_to_rent_income_rate =
        inputEstateInvestment.renewal_fee_to_rent_income_rate
    }
    // 礼金・更新料を契約していない
    if (inputEstateInvestment.renewal_fee_type == API_FLAG.off) {
      newInputEstateInvestment.renewal_fee = null
      newInputEstateInvestment.renewal_fee_period_months = null
    }

    // 「ローン：なし」
    if (loan_flg == API_FLAG.off) {
      newInputEstateInvestment.life_insurance = null
    }

    // 「ローン：あり」
    if (loan_flg == API_FLAG.on) {
      newInputEstateInvestment.repayment_chart = repayment_chart
      newInputEstateInvestment.life_insurance = inputEstateInvestment.life_insurance

      // 返済計画表の有無
      switch (Number(repayment_chart)) {
        // あり
        case API_FLAG.on:
          newInputEstateInvestment = {
            ...newInputEstateInvestment,
            repayment_monthly: inputEstateInvestment.repayment_monthly,
            repayment_date: convertDateYear(inputEstateInvestment.delivery_year),
            loan_type: inputEstateInvestment.person_flg,
            last_repayment_date: convertDateDay(
              inputEstateInvestment.last_repayment_year,
              inputEstateInvestment.last_repayment_month,
              inputEstateInvestment.last_repayment_day,
            ),
            installment_principal: inputEstateInvestment.installment_principal,
            expected_return_date: convertDateDay(
              inputEstateInvestment.expected_return_year,
              inputEstateInvestment.expected_return_month || 1,
              inputEstateInvestment.expected_return_day || 1,
            ),
            total_amount: inputEstateInvestment.total_amount,
          }

          // 金利の変化
          newInputEstateInvestment.input_rate_changes_attributes =
            inputEstateInvestment.rate_change_type == API_FLAG.on
              ? [
                  {
                    rate_start_at: null,
                    changed_rate_percent: inputEstateInvestment.input_rate_changes_attributes![0]
                      .changed_rate_percent,
                  },
                  ...(inputEstateInvestment.input_rate_changes_other_attributes?.map(
                    (item: InputRateChangeCreateValues) => {
                      return {
                        rate_start_at: convertDateYear(item.rate_start_year),
                        changed_rate_percent: item.changed_rate_percent,
                      }
                    },
                  ) ?? []),
                ]
              : [
                  {
                    rate_start_at: null,
                    changed_rate_percent: inputEstateInvestment.input_rate_changes_attributes![0]
                      .changed_rate_percent,
                  },
                ]
          break

        // なし
        case API_FLAG.off:
          newInputEstateInvestment = {
            ...newInputEstateInvestment,
            started_borrowing_amount_manyen:
              inputEstateInvestment.started_borrowing_amount_manyen,
            repayment_period_years: inputEstateInvestment.repayment_period_years,
            bonus_flg: API_FLAG.off,
            repayment_monthly_manyen:
              inputEstateInvestment.started_borrowing_amount_manyen,
            repayment_monthly_interest_percent:
              inputEstateInvestment.repayment_monthly_interest_percent,
          }

          // 「繰上げ返済：行ったことがない」
          if (isEdit && inputEstateInvestment.advance_repayment_type == API_FLAG.off) {
            newInputEstateInvestment.input_estate_loan_calculations_attributes = []
          }
          // 「繰上げ返済：行ったことがある」
          if (inputEstateInvestment.advance_repayment_type == API_FLAG.on) {
            if ((isEdit && repayment_chart == API_FLAG.off) || !isEdit) {
              const initialLoanCalculationAttributes =
                initialValues?.input_estate_investment
                  ?.input_estate_loan_calculations_attributes || []

              newInputEstateInvestment.input_estate_loan_calculations_attributes =
                initialLoanCalculationAttributes.length ==
                inputEstateInvestment.input_estate_loan_calculations_attributes!.length
                  ? inputEstateInvestment.input_estate_loan_calculations_attributes!.map(
                      (item: InputEstateLoanCalculationUpdateValues) => ({
                        id: item.id,
                        advance_repayment_at: convertDateYear(
                          item.advance_repayment_year,
                        ),
                        repayment_type: item.repayment_type,
                        repayment_amount: item.repayment_amount,
                      }),
                    )
                  : inputEstateInvestment.input_estate_loan_calculations_attributes!.map(
                      (item: InputEstateLoanCalculationCreateValues) => ({
                        advance_repayment_at: convertDateYear(
                          item.advance_repayment_year,
                        ),
                        repayment_type: item.repayment_type,
                        repayment_amount: item.repayment_amount,
                      }),
                    )
            }
          }

          break
      }
    }
    return newInputEstateInvestment
  }

  const handleSubmit = (
    values: InputEstateInvestmentCreateValues &
      InputEstateInvestmentUpdateValues & {
        [key: string]: any
      },
  ) => {
    const commonCallBack = () => {
      showFlashMsg(isEdit ? '更新しました' : '作成しました')
      dispatch(fetchInputEstateInvestments())
      dispatch(updateResetFlg())
      props.onSubmit()
    }

    return new Promise((resolve) => {
      if (isEdit) {
        const newValues = {
          id: initialValues.id,
          input_estate_investment: {
            ...buildSubmitValues(values.input_estate_investment),
          },
        }
        dispatch(
          updateInputEstateInvestment(newValues, () => {
            showFlashMsg(isEdit ? '更新しました' : '作成しました')
            commonCallBack()
          }),
        )
      } else {
        const newValues = {
          input_estate_investment: {
            ...buildSubmitValues(values.input_estate_investment),
          },
        }
        dispatch(
          createInputEstateInvestment(
            newValues,
            () => {
              showFlashMsg(isEdit ? '更新しました' : '作成しました')
              commonCallBack()
            },
            resolve,
          ),
        )
      }
    })
  }

  const initialOtherRateChagneArr = useMemo(() => {
    if (!isEdit) return []

    return [
      ..._filter(
        [...initialValues.input_rate_changes],
        (item) => !_isNull(item.rate_start_at),
      ).map((item) => {
        return {
          ...item,
          rate_start_year: extractYearOrMonthFromDate(item.rate_start_at, 'year'),
          rate_start_month: extractYearOrMonthFromDate(item.rate_start_at, 'month'),
        }
      }),
    ]
  }, [])

  return (
    <>
      <LoadingOverlay isLoading={isLoading || isLoadingFamilyStructures} />
      <Form
        onSubmit={handleSubmit}
        initialValues={useMemo(() => {
          return isEdit
            ? {
                tax_paper_presence_type: TAX_PAPER_PRESENCE_TYPE.no,
                input_estate_investment:
                  initialValues.loan_flg == API_FLAG.off
                    ? {
                        ...initialValues,
                        management_fee_money: !!initialValues.management_fee_money
                          ? Math.round(Number(initialValues.management_fee_money)) / 12
                          : initialValues.management_fee_money,
                        vacancy_rate_type: INPUT_VACANCY_SETTING_TYPE.own,
                        advance_repayment_type: _isEmpty(
                          initialValues.input_estate_loan_calculations,
                        )
                          ? API_FLAG.off
                          : API_FLAG.on,
                        renewal_fee_type: _isNull(initialValues.renewal_fee_period_months)
                          ? API_FLAG.off
                          : API_FLAG.on,
                        renewal_fee_input_type: _isNull(initialValues.renewal_fee)
                          ? RENEWAL_FEE_INPUT_TYPE.month
                          : RENEWAL_FEE_INPUT_TYPE.yen,
                        delivery_year: extractYearOrMonthFromDate(
                          initialValues.delivery_date,
                          'year',
                        ),
                        last_repayment_year: extractYearOrMonthFromDate(
                          initialValues.last_repayment_date,
                          'year',
                        ),
                        last_repayment_month: extractYearOrMonthFromDate(
                          initialValues.last_repayment_date,
                          'month',
                        ),
                        last_repayment_day: extractDayFromDate(
                          initialValues.last_repayment_date,
                        ),
                        expected_return_year:
                          INPUT_ESTATE_INVESTMENT_DEFAULT_VALUES.expected_return_year,
                        expected_return_month:
                          INPUT_ESTATE_INVESTMENT_DEFAULT_VALUES.expected_return_month,
                        expected_return_day: removeZeroToNumberDay(
                          extractDayFromDate(initialValues.expected_return_date),
                        ),
                        // MEMO: 値が空の場合、計算上不具合が出ないように初期値として１を代入
                        repair_costs_span: !!initialValues.repair_costs_span
                          ? Number(initialValues.repair_costs_span)
                          : 1,
                        input_declines_attributes: _isEmpty(initialValues.input_declines)
                          ? [{}]
                          : [
                              ...initialValues.input_declines.map((item: any) => {
                                return {
                                  ...item,
                                  annual_rate_of_decline_year:
                                    extractYearOrMonthFromDate(
                                      item.annual_rate_of_decline_year,
                                      'year',
                                    ) - THIS_YEAR_NUMBER,
                                }
                              }),
                            ],
                        is_management_and_fund_total: !_isNull(
                          initialValues.management_fee_monthly_and_repair_reserve_fund_total,
                        )
                          ? String(API_BOOLEAN_FLAG.on)
                          : String(API_BOOLEAN_FLAG.off),
                        management_fee_monthly_and_repair_reserve_fund_total:
                          initialValues.management_fee_monthly_and_repair_reserve_fund_total ||
                          null,
                        input_rate_changes_attributes: [{}],
                        input_rate_changes_other_attributes: [{}],
                        input_estate_loan_calculations_attributes: [{}],
                      }
                    : initialValues.repayment_chart == API_FLAG.on
                    ? {
                        ...initialValues,
                        management_fee_money: !!initialValues.management_fee_money
                          ? Math.round(Number(initialValues.management_fee_money)) / 12
                          : initialValues.management_fee_money,
                        vacancy_rate_type: INPUT_VACANCY_SETTING_TYPE.own,
                        rate_change_type: !_isEmpty(initialOtherRateChagneArr)
                          ? API_FLAG.on
                          : API_FLAG.off,
                        renewal_fee_type: _isNull(initialValues.renewal_fee_period_months)
                          ? API_FLAG.off
                          : API_FLAG.on,
                        renewal_fee_input_type: _isNull(initialValues.renewal_fee)
                          ? RENEWAL_FEE_INPUT_TYPE.month
                          : RENEWAL_FEE_INPUT_TYPE.yen,
                        delivery_year: extractYearOrMonthFromDate(
                          initialValues.delivery_date,
                          'year',
                        ),
                        last_repayment_year: extractYearOrMonthFromDate(
                          initialValues.last_repayment_date,
                          'year',
                        ),
                        last_repayment_month: extractYearOrMonthFromDate(
                          initialValues.last_repayment_date,
                          'month',
                        ),
                        last_repayment_day: extractDayFromDate(
                          initialValues.last_repayment_date,
                        ),
                        expected_return_year: extractYearOrMonthFromDate(
                          initialValues.expected_return_date,
                          'year',
                        ),
                        expected_return_month: addZeroToStringMonth(
                          extractYearOrMonthFromDate(
                            initialValues.expected_return_date,
                            'month',
                          ),
                        ),
                        expected_return_day: removeZeroToNumberDay(
                          extractDayFromDate(initialValues.expected_return_date),
                        ),
                        // MEMO: 値が空の場合、計算上不具合が出ないように初期値として１を代入
                        repair_costs_span: !!initialValues.repair_costs_span
                          ? Number(initialValues.repair_costs_span)
                          : 1,
                        is_management_and_fund_total: !_isNull(
                          initialValues.management_fee_monthly_and_repair_reserve_fund_total,
                        )
                          ? String(API_BOOLEAN_FLAG.on)
                          : String(API_BOOLEAN_FLAG.off),
                        management_fee_monthly_and_repair_reserve_fund_total:
                          initialValues.management_fee_monthly_and_repair_reserve_fund_total ||
                          null,
                        input_declines_attributes: _isEmpty(initialValues.input_declines)
                          ? [{}]
                          : [
                              ...initialValues.input_declines.map((item: any) => {
                                return {
                                  ...item,
                                  annual_rate_of_decline_year:
                                    extractYearOrMonthFromDate(
                                      item.annual_rate_of_decline_year,
                                      'year',
                                    ) - THIS_YEAR_NUMBER,
                                }
                              }),
                            ],
                        input_rate_changes_attributes: _isEmpty(
                          initialValues.input_rate_changes,
                        )
                          ? [{}]
                          : [{ ...initialValues.input_rate_changes[0] }],
                        input_rate_changes_other_attributes: _every(
                          initialValues.input_rate_changes,
                          { rate_start_at: null },
                        )
                          ? [{}]
                          : [...initialOtherRateChagneArr],
                        input_estate_loan_calculations_attributes: [{}],
                      }
                    : {
                        ...initialValues,
                        management_fee_money: !!initialValues.management_fee_money
                          ? Math.round(Number(initialValues.management_fee_money)) / 12
                          : initialValues.management_fee_money,
                        vacancy_rate_type: INPUT_VACANCY_SETTING_TYPE.own,
                        advance_repayment_type: _isEmpty(
                          initialValues.input_estate_loan_calculations,
                        )
                          ? API_FLAG.off
                          : API_FLAG.on,
                        renewal_fee_input_type: _isNull(initialValues.renewal_fee)
                          ? RENEWAL_FEE_INPUT_TYPE.month
                          : RENEWAL_FEE_INPUT_TYPE.yen,
                        renewal_fee_type: _isNull(initialValues.renewal_fee_period_months)
                          ? API_FLAG.off
                          : API_FLAG.on,
                        delivery_year: extractYearOrMonthFromDate(
                          initialValues.delivery_date,
                          'year',
                        ),
                        expected_return_year:
                          INPUT_ESTATE_INVESTMENT_DEFAULT_VALUES.expected_return_year,
                        expected_return_month:
                          INPUT_ESTATE_INVESTMENT_DEFAULT_VALUES.expected_return_month,
                        expected_return_day: removeZeroToNumberDay(
                          extractDayFromDate(initialValues.expected_return_date),
                        ),
                        // MEMO: 値が空の場合、計算上不具合が出ないように初期値として１を代入
                        repair_costs_span: !!initialValues.repair_costs_span
                          ? Number(initialValues.repair_costs_span)
                          : 1,
                        is_management_and_fund_total: !_isNull(
                          initialValues.management_fee_monthly_and_repair_reserve_fund_total,
                        )
                          ? String(API_BOOLEAN_FLAG.on)
                          : String(API_BOOLEAN_FLAG.off),
                        management_fee_monthly_and_repair_reserve_fund_total:
                          initialValues.management_fee_monthly_and_repair_reserve_fund_total ||
                          null,
                        input_declines_attributes: _isEmpty(initialValues.input_declines)
                          ? [{}]
                          : [
                              ...initialValues.input_declines.map((item: any) => {
                                return {
                                  ...item,
                                  annual_rate_of_decline_year:
                                    extractYearOrMonthFromDate(
                                      item.annual_rate_of_decline_year,
                                      'year',
                                    ) - THIS_YEAR_NUMBER,
                                }
                              }),
                            ],
                        input_estate_loan_calculations_attributes: _isEmpty(
                          initialValues.input_estate_loan_calculations,
                        )
                          ? [{}]
                          : [
                              ...initialValues.input_estate_loan_calculations.map(
                                (item: any) => {
                                  return {
                                    ...item,
                                    advance_repayment_year: extractYearOrMonthFromDate(
                                      item.advance_repayment_at,
                                      'year',
                                    ),
                                  }
                                },
                              ),
                            ],
                        input_rate_changes_attributes: [{}],
                        input_rate_changes_other_attributes: [{}],
                      },
              }
            : {
                tax_paper_presence_type: TAX_PAPER_PRESENCE_TYPE.yes,
                input_estate_investment: {
                  // MEMO: 値が空の場合、計算上不具合が出ないように初期値として１を代入
                  repair_costs_span:
                    INPUT_ESTATE_INVESTMENT_DEFAULT_VALUES.repair_costs_span,
                  management_fee_type:
                    INPUT_ESTATE_INVESTMENT_DEFAULT_VALUES.management_fee_type,
                  expected_return_year:
                    INPUT_ESTATE_INVESTMENT_DEFAULT_VALUES.expected_return_year,
                  expected_return_month:
                    INPUT_ESTATE_INVESTMENT_DEFAULT_VALUES.expected_return_month,
                  input_rate_changes_attributes:
                    INPUT_ESTATE_INVESTMENT_DEFAULT_VALUES.input_rate_changes_attributes,
                  input_rate_changes_other_attributes:
                    INPUT_ESTATE_INVESTMENT_DEFAULT_VALUES.input_rate_changes_attributes,
                  input_declines_attributes:
                    INPUT_ESTATE_INVESTMENT_DEFAULT_VALUES.input_rate_changes_attributes,
                  input_estate_loan_calculations_attributes:
                    INPUT_ESTATE_INVESTMENT_DEFAULT_VALUES.input_estate_loan_calculations_attributes,
                  is_management_and_fund_total: String(API_BOOLEAN_FLAG.off),
                },
              }
        }, [isEdit, initialValues])}
        validate={(values) => {
          const {
            annual_rate_of_decline_type,
            input_declines_attributes,
            year_of_construction,
            repair_reserve_fund_amount_start,
            repair_reserve_fund_amount_finish,
            repair_reserve_fund_increase_start,
            repair_reserve_fund_increase_finish,
          } = values.input_estate_investment
          const errorMessages: { [key: string]: any } = {
            input_estate_investment: {},
          }

          // 年間下落率
          if (annual_rate_of_decline_type == API_FLAG.on) {
            const yearOfConstruction = convertYearOfConstruction(
              year_of_construction || null,
            )
            const newInputDeclineAattributes: any[] = _slice(
              input_declines_attributes,
              1,
              input_declines_attributes?.length || 0,
            )
            const declineErrorMessages = _reduce(
              newInputDeclineAattributes,
              (
                result: { [key: string]: string }[],
                item: InputDecline,
                index: number,
              ) => {
                const compareDeclineYear =
                  index == 0
                    ? yearOfConstruction
                    : newInputDeclineAattributes[index - 1].annual_rate_of_decline_year

                if (
                  Number(item.annual_rate_of_decline_year) <= Number(compareDeclineYear)
                ) {
                  result[index + 1] = {
                    annual_rate_of_decline_year: '築年数の順番を揃えてください。',
                  }
                }

                return result
              },
              [],
            )

            if (declineErrorMessages.length > 0) {
              errorMessages.input_estate_investment.input_declines_attributes = declineErrorMessages
            }
          }

          // 修繕積立金上昇率の築年数
          if (
            (isNotEmptyValue(repair_reserve_fund_amount_start) ||
              isNotEmptyValue(repair_reserve_fund_amount_finish)) &&
            Number(repair_reserve_fund_amount_start) >
              Number(repair_reserve_fund_amount_finish)
          ) {
            errorMessages.input_estate_investment.repair_reserve_fund_amount_start =
              '期間終了年数以下の年数を入力してください。'
          }

          if (
            (isNotEmptyValue(repair_reserve_fund_increase_start) ||
              isNotEmptyValue(repair_reserve_fund_increase_finish)) &&
            Number(repair_reserve_fund_increase_start) >
              Number(repair_reserve_fund_increase_finish)
          ) {
            errorMessages.input_estate_investment.repair_reserve_fund_increase_start =
              '期間終了年数以下の年数を入力してください。'
          }

          return Object.keys(errorMessages.input_estate_investment).length > 0
            ? errorMessages
            : undefined
        }}
        mutators={{ ...arrayMutators }}
        decorators={[calculator]}
        render={({
          handleSubmit,
          values,
          errors,
          submitErrors,
          submitFailed,
          form: { change },
        }) => {
          const { tax_paper_presence_type } = values
          const {
            property_price_manyen,
            is_management_and_fund_total,
            annual_rate_of_decline_type,
            year_of_construction,
            property_type,
            rent_income_monthly,
            renewal_fee,
            renewal_fee_input_type,
            renewal_fee_type,
            repair_reserve_fund_increase_type,
            management_fee_type,
            rate_change_type,
            vacancy_rate_type,
            repayment_chart,
            advance_repayment_type,
            loan_flg,
          } = values.input_estate_investment

          return (
            <form onSubmit={handleSubmit}>
              <Grid className="pb-[64px] grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px]">
                <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                  <div className="flex items-center min-w-[180px] lg:min-w-[230px] pb-[10px]">
                    <Typography className="mr-[10px] text-[14px] text-black-800" isBold>
                      物件名
                    </Typography>
                  </div>

                  <TextField
                    type="text"
                    isFullWidth
                    name="input_estate_investment.property_name"
                    validate={required}
                  />
                </div>

                <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                  <div className="flex items-center min-w-[180px] lg:min-w-[230px] pb-[10px]">
                    <Typography className="mr-[10px] text-[14px] text-black-800" isBold>
                      名義人
                    </Typography>
                  </div>
                  <SelectBox
                    placeholder="選択する"
                    name="input_estate_investment.person_flg"
                    options={
                      hasSpouse
                        ? PERSON_FLG_SELECT_ITEMS
                        : PERSON_WITHOUT_SPOUSE_SELECT_ITEM
                    }
                    validate={required}
                    isFullWidth
                  />
                </div>

                <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                  <div className="flex items-center min-w-[180px] lg:min-w-[230px] pb-[10px]">
                    <Typography className="mr-[10px] text-[14px] text-black-800" isBold>
                      物件価格
                    </Typography>
                  </div>
                  <div className="flex flex-auto items-baseline">
                    <NumberField
                      name="input_estate_investment.property_price_manyen"
                      isFullWidth
                      validate={composeValidators(
                        required,
                        zeroOrMoreNumber,
                        (value: any) => commaNumberMaxLength(value, 6),
                      )}
                    />
                    <Typography
                      className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800 min-w-[40px]"
                      isBold
                    >
                      万円
                    </Typography>
                  </div>
                </div>

                <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                  <div className="flex items-center min-w-[180px] lg:min-w-[230px] pb-[10px]">
                    <Typography className="mr-[10px] text-[14px] text-black-800" isBold>
                      物件タイプ
                    </Typography>
                  </div>
                  <SelectBox
                    placeholder="選択する"
                    name="input_estate_investment.property_type"
                    options={INPUT_PROPERTY_TYPE_RADIO_ITEMS}
                    validate={required}
                    onChange={(event: any) =>
                      change(
                        'input_estate_investment.property_type',
                        Number(event.target.value),
                      )
                    }
                    isFullWidth
                  />
                </div>

                <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                  <div className="flex items-center min-w-[125px] lg:min-w-[230px] pb-[10px]">
                    <Typography className="mr-[10px] text-[14px] text-black-800" isBold>
                      築年
                    </Typography>
                  </div>
                  <div className="flex flex-auto items-baseline">
                    <SelectBox
                      name="input_estate_investment.year_of_construction"
                      placeholder="年を選択"
                      options={buildTwoTypesRangeYearSelectItems(
                        THIS_YEAR_NUMBER - 60,
                        THIS_YEAR_NUMBER,
                        true,
                      )}
                      validate={required}
                      isFullWidth
                    />
                    <Typography
                      className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                      isBold
                    >
                      築
                    </Typography>
                  </div>
                </div>

                <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                  <div className="flex items-center min-w-[125px] lg:min-w-[230px] pb-[10px]">
                    <Typography className="mr-[10px] text-[14px] text-black-800" isBold>
                      引き渡し年
                    </Typography>
                  </div>
                  <div className="flex flex-auto items-baseline">
                    <SelectBox
                      name="input_estate_investment.delivery_year"
                      placeholder="年を選択"
                      options={buildTwoTypesRangeYearSelectItems(
                        THIS_YEAR_NUMBER - 60,
                        THIS_YEAR_NUMBER,
                        true,
                      )}
                      validate={required}
                      isFullWidth
                    />
                  </div>
                </div>

                {property_type == INPUT_PROPERTY_TYPE.section && (
                  <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                    <div className="flex items-center min-w-[180px] lg:min-w-[230px] pb-[10px]">
                      <Typography className="mr-[10px] text-[14px] text-black-800" isBold>
                        広さ
                      </Typography>
                    </div>
                    <div className="flex flex-auto items-baseline">
                      <NumberFieldDecimal
                        isFullWidth
                        name="input_estate_investment.breadth"
                        validate={composeValidators(
                          required,
                          zeroOrMoreNumber,
                          (value: any) => commaNumberMaxLength(value, 6),
                        )}
                      />
                      <Typography
                        className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                        isBold
                      >
                        ㎡
                      </Typography>
                    </div>
                  </div>
                )}

                {property_type == INPUT_PROPERTY_TYPE.building && (
                  <>
                    <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                      <div className="flex items-center min-w-[180px] lg:min-w-[230px] pb-[10px]">
                        <Typography
                          className="mr-[10px] text-[14px] text-black-800"
                          isBold
                        >
                          延床面積
                        </Typography>
                      </div>
                      <div className="flex flex-auto items-baseline">
                        <NumberFieldDecimal
                          isFullWidth
                          name="input_estate_investment.total_floor_area"
                          validate={composeValidators(
                            required,
                            zeroOrMoreNumber,
                            (value: any) => commaNumberMaxLength(value, 6),
                          )}
                        />
                        <Typography
                          className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                          isBold
                        >
                          ㎡
                        </Typography>
                      </div>
                    </div>

                    <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                      <div className="flex items-center min-w-[180px] lg:min-w-[230px] pb-[10px]">
                        <Typography
                          className="mr-[10px] text-[14px] text-black-800"
                          isBold
                        >
                          敷地面積
                        </Typography>
                      </div>
                      <div className="flex flex-auto items-baseline">
                        <NumberFieldDecimal
                          isFullWidth
                          name="input_estate_investment.site_area"
                          validate={composeValidators(
                            required,
                            zeroOrMoreNumber,
                            (value: any) => commaNumberMaxLength(value, 6),
                          )}
                        />
                        <Typography
                          className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                          isBold
                        >
                          ㎡
                        </Typography>
                      </div>
                    </div>
                  </>
                )}
              </Grid>

              <CircleTitle className="pb-[32px]" title="収入について" />
              <Grid className="pb-[64px] grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px]">
                <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                  <div className="flex items-center min-w-[180px] lg:min-w-[230px] pb-[10px]">
                    <Typography className="mr-[10px] text-[14px] text-black-800" isBold>
                      賃料収入
                    </Typography>
                  </div>
                  <div className="flex flex-auto items-baseline">
                    <NumberField
                      isFullWidth
                      name="input_estate_investment.rent_income_monthly"
                      validate={composeValidators(
                        required,
                        zeroOrMoreNumber,
                        (value: any) => commaNumberMaxLength(value, 10),
                      )}
                      onChange={(event: FocusEvent<HTMLInputElement>) =>
                        handleChangeInitialValues(
                          castNumberWithoutOperator(event.target.value),
                          change,
                        )
                      }
                    />
                    <Typography
                      className="pb-[10px] pl-[10px] md:pb-0 text-[14px] text-black-800 min-w-[fit-content]"
                      isBold
                    >
                      円/月
                    </Typography>
                  </div>
                </div>

                <div className="hidden md:block" />

                <div className="pb-[16px] border-black-300 border-dashed border-b-2">
                  <div className="lg:flex ">
                    <div className="flex items-center min-w-[180px] lg:min-w-[230px] pb-[10px]">
                      <Typography className="mr-[10px] text-[14px] text-black-800" isBold>
                        賃料収入の下落率
                      </Typography>
                    </div>
                    <div className="flex flex-auto items-baseline">
                      <SelectBox
                        name="input_estate_investment.annual_rate_of_decline_type"
                        placeholder="選択する"
                        isFullWidth
                        options={INPUT_ANNUAL_RATE_OF_DECLINE_TYPE_RADIO_ITEMS}
                        validate={required}
                      />
                    </div>
                  </div>
                  {annual_rate_of_decline_type == API_FLAG.on && (
                    <div className="ml-[0px] md:ml-[67px] my-[30px]">
                      <FieldArray name="input_estate_investment.input_declines_attributes">
                        {({ fields }) =>
                          fields.map((name, index) => (
                            <div key={name}>
                              <div className="relative flex p-[14px] border border-solid rounded-[5px]">
                                {index == 0 ? (
                                  <Typography
                                    className="text-[14px] text-black-700 flex items-center"
                                    isBold
                                  >
                                    {`現在（築 ${convertYearOfConstruction(
                                      year_of_construction || THIS_YEAR_NUMBER,
                                    )}年）`}
                                  </Typography>
                                ) : (
                                  <>
                                    <Typography
                                      className="text-[14px] text-black-700 flex items-center mr-[16px]"
                                      isBold
                                    >
                                      築
                                    </Typography>
                                    <NumberField
                                      name={`${name}annual_rate_of_decline_year`}
                                      widthClassName="max-w-[100px]"
                                      validate={composeValidators(
                                        zeroOrMoreNumber,
                                        (value: any) => commaNumberMaxLength(value, 7),
                                      )}
                                    />
                                    <Typography
                                      className="text-[14px] text-black-700 flex items-center min-w-[50px] ml-[16px]"
                                      isBold
                                    >
                                      年未満
                                    </Typography>
                                    <div className="flex relative items-center  w-full justify-end">
                                      <div
                                        className="cursor-pointer"
                                        onClick={() => fields.remove(index)}
                                      >
                                        <CrossIcon className="cursor-pointer" />
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                              <div className="flex relative left-[15px] md:left-[-106px]">
                                <div className="flex items-center min-w-[19px] md:min-w-[162px] z-[20]">
                                  <Typography
                                    className="text-[14px] text-black-700 hidden md:flex items-center mr-[16px] ml-[30px] border-b-[0.5px]"
                                    isBold
                                  >
                                    期間を追加する
                                  </Typography>
                                  <div
                                    className="bg-white cursor-pointer"
                                    onClick={() =>
                                      handleAddDeclineRateBlock(
                                        [...fields.value],
                                        index,
                                        change,
                                      )
                                    }
                                  >
                                    <PlusCircleIcon />
                                  </div>
                                </div>
                                <div className="relative block md:flex p-[10px] border-l border-solid left-[-12px] z-[10]">
                                  <Typography
                                    className="text-[14px] text-black-900 font-bold flex items-center mr-[16px] ml-[30px] pb-[10px] justify-center w-[max-content] min-w-[120px]"
                                    isBold
                                  >
                                    この期間の下落率
                                  </Typography>
                                  <div className="flex justify-center items-center">
                                    <NumberFieldDecimal
                                      name={`${name}annual_rate_of_decline_percent`}
                                      widthClassName="max-w-[100px]"
                                      validate={composeValidators((value: any) =>
                                        commaNumberMaxLength(value, 7),
                                      )}
                                    />
                                    <Typography
                                      className="pl-[8px] text-[14px] text-black-800 flex items-center min-w-[fit-content]"
                                      isBold
                                    >
                                      %/年
                                    </Typography>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        }
                      </FieldArray>

                      <div className="relative flex p-[14px] border border-solid rounded-[5px]">
                        <Typography
                          className="text-[14px] text-black-700 flex items-center"
                          isBold
                        >
                          終身
                        </Typography>
                      </div>
                    </div>
                  )}
                </div>

                <div className="hidden md:block" />

                <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-center">
                  <div className="flex items-center min-w-[180px] lg:min-w-[230px] mb-[10px] md:mb-0">
                    <Typography className="mr-[10px] text-[14px] text-black-800" isBold>
                      礼金・更新料の
                      <br className="hidden md:block" />
                      受け取り有無
                    </Typography>
                  </div>
                  <SelectBox
                    name="input_estate_investment.renewal_fee_type"
                    placeholder="年を選択"
                    options={RENEWAL_FEE_TYPE_RADIO_ITEMS}
                    validate={required}
                    isFullWidth
                    onChange={(event: any) =>
                      change(
                        'input_estate_investment.renewal_fee_type',
                        Number(event.target.value),
                      )
                    }
                  />
                </div>

                {renewal_fee_type == API_FLAG.on && (
                  <>
                    <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-center">
                      <div className="flex items-center min-w-[180px] lg:min-w-[230px] mb-[10px] md:mb-0">
                        <Typography
                          className="mr-[10px] text-[14px] text-black-800"
                          isBold
                        >
                          礼金・更新料の
                          <br className="hidden md:block" />
                          設定方法
                        </Typography>
                      </div>
                      <SelectBox
                        name="input_estate_investment.renewal_fee_input_type"
                        placeholder="選択してください"
                        options={[
                          {
                            value: RENEWAL_FEE_INPUT_TYPE.yen,
                            label: '円で設定',
                          },
                          {
                            value: RENEWAL_FEE_INPUT_TYPE.month,
                            label: '家賃に対する比率で設定',
                          },
                        ]}
                        validate={required}
                        isFullWidth
                      />
                    </div>

                    {renewal_fee_input_type == RENEWAL_FEE_INPUT_TYPE.yen && (
                      <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                        <div className="flex items-center min-w-[180px] lg:min-w-[230px] pb-[10px]">
                          <Typography
                            className="mr-[10px] text-[14px] text-black-800"
                            isBold
                          >
                            礼金・更新料
                          </Typography>
                        </div>
                        <div className="flex flex-auto items-baseline">
                          <NumberField
                            name="input_estate_investment.renewal_fee"
                            defaultValue={renewal_fee ?? rent_income_monthly}
                            isFullWidth
                            validate={
                              renewal_fee_input_type == RENEWAL_FEE_INPUT_TYPE.yen
                                ? composeValidators(
                                    required,
                                    zeroOrMoreNumber,
                                    (value: any) => commaNumberMaxLength(value, 10),
                                  )
                                : undefined
                            }
                          />
                          <Typography
                            className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                            isBold
                          >
                            円
                          </Typography>
                        </div>
                      </div>
                    )}
                  </>
                )}
                {renewal_fee_type == API_FLAG.on &&
                  renewal_fee_input_type == RENEWAL_FEE_INPUT_TYPE.month && (
                    <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-center">
                      <div className="flex items-center min-w-[140px] lg:min-w-[230px] pb-[10px]">
                        <Typography
                          className="mr-[10px] text-[14px] text-black-800"
                          isBold
                        >
                          礼金・更新料
                        </Typography>
                      </div>
                      <div className="flex flex-auto items-baseline">
                        {isNotEmptyValue(rent_income_monthly) &&
                          rent_income_monthly >= 0 && (
                            <Typography
                              className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800 min-w-[120px]"
                              isBold
                            >
                              {`家賃 ${convertLocaleString(
                                castNumberWithoutOperator(rent_income_monthly),
                              )}円の`}
                            </Typography>
                          )}

                        <NumberField
                          defaultValue={1}
                          widthClassName="min-w-[42px] w-full"
                          name="input_estate_investment.renewal_fee_to_rent_income_rate"
                          validate={
                            renewal_fee_input_type == RENEWAL_FEE_INPUT_TYPE.month
                              ? composeValidators(
                                  required,
                                  zeroOrMoreNumber,
                                  (value: any) => commaNumberMaxLength(value, 10),
                                )
                              : undefined
                          }
                        />
                        <Typography
                          className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800 min-w-[70px]"
                          isBold
                        >
                          ヶ月分
                        </Typography>
                      </div>
                    </div>
                  )}

                {renewal_fee_type == API_FLAG.on && (
                  <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-center">
                    <div className="flex items-center min-w-[180px] lg:min-w-[230px] mb-[10px] md:mb-0">
                      <Typography className="mr-[10px] text-[14px] text-black-800" isBold>
                        礼金・更新料の
                        <br className="hidden md:block" />
                        受取頻度
                      </Typography>
                    </div>
                    <div className="flex flex-auto items-baseline">
                      <NumberField
                        name="input_estate_investment.renewal_fee_period_months"
                        isFullWidth
                        validate={composeValidators(
                          required,
                          oneOrMoreNumber,
                          (value: any) => commaNumberMaxLength(value, 6),
                        )}
                      />
                      <Typography
                        className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800 min-w-[80px]"
                        isBold
                      >
                        ヶ月に1回
                      </Typography>
                    </div>
                  </div>
                )}
              </Grid>

              <CircleTitle className="pb-[32px]" title="費用について" />
              <Grid className="pb-[16px] grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px]">
                <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                  <div className="flex items-center min-w-[185px] lg:min-w-[230px] pb-[10px]">
                    <Typography className="mr-[10px] text-[14px] text-black-800" isBold>
                      １回あたりのリフォーム費
                    </Typography>
                  </div>
                  <div className="flex flex-auto items-baseline">
                    <NumberField
                      name="input_estate_investment.repair_costs"
                      validate={composeValidators(
                        required,
                        zeroOrMoreNumber,
                        (value: any) => commaNumberMaxLength(value, 10),
                      )}
                      isFullWidth
                    />
                    <Typography
                      className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                      isBold
                    >
                      円
                    </Typography>
                  </div>
                </div>

                <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                  <div className="flex items-center min-w-[180px] lg:min-w-[230px] pb-[10px]">
                    <Typography className="mr-[10px] text-[14px] text-black-800" isBold>
                      リフォームの頻度
                    </Typography>
                  </div>
                  <div className="flex flex-auto items-baseline">
                    <SelectBox
                      isFullWidth
                      name="input_estate_investment.repair_costs_span"
                      placeholder="選択する"
                      options={ESTATE_INVESTMENT_SPAN_SELECT_ITEMS}
                      validate={required}
                    />
                    <Typography
                      className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800 min-w-[70px]"
                      isBold
                    >
                      年に一回
                    </Typography>
                  </div>
                </div>

                {property_type == INPUT_PROPERTY_TYPE.section && (
                  <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-center">
                    <div className="flex items-center min-w-[200px] lg:min-w-[230px] pb-[10px]">
                      <Typography className="mr-[10px] text-[14px] text-black-800" isBold>
                        管理費・修繕積立金の
                        <br className="md:block hidden" />
                        設定方法
                      </Typography>
                    </div>
                    <SelectBox
                      name="input_estate_investment.is_management_and_fund_total"
                      placeholder="選択してください"
                      options={[
                        {
                          value: String(API_BOOLEAN_FLAG.on),
                          label: '総額で設定',
                        },
                        {
                          value: String(API_BOOLEAN_FLAG.off),
                          label: '詳細に設定',
                        },
                      ]}
                      validate={required}
                      isFullWidth
                    />
                  </div>
                )}

                {property_type == INPUT_PROPERTY_TYPE.section &&
                  is_management_and_fund_total == String(API_BOOLEAN_FLAG.on) && (
                    <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                      <div className="flex items-center min-w-[180px] lg:min-w-[230px] pb-[10px]">
                        <Typography
                          className="mr-[10px] text-[14px] text-black-800"
                          isBold
                        >
                          管理費・修繕積立金合計
                        </Typography>
                      </div>
                      <div className="flex flex-auto items-baseline">
                        <NumberField
                          isFullWidth
                          name="input_estate_investment.management_fee_monthly_and_repair_reserve_fund_total"
                          validate={composeValidators(
                            required,
                            zeroOrMoreNumber,
                            (value: any) => commaNumberMaxLength(value, 10),
                          )}
                        />
                        <Typography
                          className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                          isBold
                        >
                          円
                        </Typography>
                      </div>
                    </div>
                  )}

                {property_type == INPUT_PROPERTY_TYPE.section &&
                  is_management_and_fund_total == String(API_BOOLEAN_FLAG.off) && (
                    <>
                      <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-center">
                        <div className="flex items-center min-w-[212px] lg:min-w-[230px] mb-[10px] md:mb-0">
                          <Typography
                            className="mr-[10px] text-[14px] text-black-800"
                            isBold
                          >
                            管理費
                          </Typography>
                        </div>
                        <div className="flex flex-auto items-baseline">
                          <NumberField
                            isFullWidth
                            name="input_estate_investment.management_fee_monthly"
                            validate={composeValidators(
                              required,
                              zeroOrMoreNumber,
                              (value: any) => commaNumberMaxLength(value, 10),
                            )}
                          />
                          <Typography
                            className="min-w-[fit-content] pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                            isBold
                          >
                            円/月
                          </Typography>
                        </div>
                      </div>

                      <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                        <div className="flex items-center min-w-[180px] lg:min-w-[230px] pb-[10px]">
                          <Typography
                            className="mr-[10px] text-[14px] text-black-800"
                            isBold
                          >
                            修繕積立金
                          </Typography>
                        </div>
                        <div className="flex flex-auto items-baseline">
                          <NumberField
                            isFullWidth
                            name="input_estate_investment.repair_reserve_fund"
                            validate={composeValidators(
                              required,
                              zeroOrMoreNumber,
                              (value: any) => commaNumberMaxLength(value, 10),
                            )}
                          />
                          <Typography
                            className="min-w-[fit-content] pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                            isBold
                          >
                            円/月
                          </Typography>
                        </div>
                      </div>

                      <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-center">
                        <div className="flex items-center min-w-[180px] lg:min-w-[230px] mb-[10px] md:mb-0">
                          <Typography
                            className="mr-[10px] text-[14px] text-black-800"
                            isBold
                          >
                            修繕積立金上昇率の
                            <br className="hidden md:block" />
                            設定方法
                          </Typography>
                        </div>
                        <SelectBox
                          isFullWidth
                          placeholder="選択してください"
                          name="input_estate_investment.repair_reserve_fund_increase_type"
                          options={REPAIR_RESERVE_FUND_INCREASE_TYPE_SELECT_ITEMS}
                          validate={required}
                        />
                      </div>
                    </>
                  )}
                {property_type == INPUT_PROPERTY_TYPE.section &&
                  is_management_and_fund_total == String(API_BOOLEAN_FLAG.off) &&
                  repair_reserve_fund_increase_type ==
                    REPAIR_RESERVE_FUND_INCREASE_TYPE.change && (
                    <>
                      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                        <div className="flex items-center min-w-[180px] lg:min-w-[173px] pb-[10px]">
                          <Typography
                            className="mr-[10px] text-[14px] text-black-800"
                            isBold
                          >
                            修繕積立金上昇率
                          </Typography>
                        </div>
                        <div>
                          <div className="flex flex-auto items-baseline mb-[10px]">
                            <Typography
                              className="pr-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                              isBold
                            >
                              築
                            </Typography>
                            <NumberField
                              className="w-full md:w-[90px]"
                              name="input_estate_investment.repair_reserve_fund_increase_start"
                              validate={composeValidators(
                                required,
                                zeroOrMoreNumber,
                                (value: any) => commaNumberMaxLength(value, 2),
                              )}
                            />
                            <Typography
                              className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800 min-w-[70px]"
                              isBold
                            >
                              年目から
                            </Typography>
                            <NumberField
                              className="w-full md:w-[50px]"
                              name="input_estate_investment.repair_reserve_fund_increase_span"
                              validate={composeValidators(
                                required,
                                oneOrMoreNumber,
                                (value: any) => commaNumberMaxLength(value, 2),
                              )}
                            />
                            <Typography
                              className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800 min-w-[70px]"
                              isBold
                            >
                              年毎に
                            </Typography>
                          </div>
                          <div className="flex flex-auto items-baseline">
                            <Typography
                              className="pr-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                              isBold
                            >
                              築
                            </Typography>
                            <NumberField
                              className="w-full md:w-[90px]"
                              name="input_estate_investment.repair_reserve_fund_increase_finish"
                              validate={composeValidators(
                                required,
                                zeroOrMoreNumber,
                                (value: any) => maxLength(value, 2),
                              )}
                            />
                            <Typography
                              className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800 min-w-[70px]"
                              isBold
                            >
                              年目まで
                            </Typography>

                            <NumberFieldDecimal
                              className="w-full md:w-[50px]"
                              name="input_estate_investment.repair_reserve_fund_increase_percent"
                              validate={composeValidators(
                                required,
                                zeroOrMoreNumber,
                                (value: any) => commaNumberMaxLength(value, 6),
                              )}
                            />
                            <Typography
                              className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800 min-w-[70px]"
                              isBold
                            >
                              ％に設定
                            </Typography>
                          </div>
                        </div>
                      </div>

                      <div className="hidden md:block" />
                    </>
                  )}

                <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-center">
                  <div className="flex items-center min-w-[180px] lg:min-w-[230px] mb-[10px] md:mb-0">
                    <Typography className="mr-[10px] text-[14px] text-black-800" isBold>
                      建物・賃貸管理手数料の
                      <br className="hidden md:block" />
                      設定方法
                    </Typography>
                  </div>
                  <SelectBox
                    isFullWidth
                    placeholder="選択してください"
                    name="input_estate_investment.management_fee_type"
                    options={INPUT_MANAGEMENT_FEE_TYPE_RADIO_ITEMS}
                    validate={required}
                  />
                </div>

                {management_fee_type == INPUT_MANAGEMENT_FEE_TYPE.yen && (
                  <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                    <div className="flex items-center min-w-[180px] lg:min-w-[230px] pb-[10px]">
                      <Typography className="mr-[10px] text-[14px] text-black-800" isBold>
                        建物・賃貸管理手数料
                      </Typography>
                    </div>
                    <div className="flex flex-auto items-baseline">
                      <NumberField
                        isFullWidth
                        name="input_estate_investment.management_fee_money"
                        validate={composeValidators(
                          required,
                          zeroOrMoreNumber,
                          (value: any) => commaNumberMaxLength(value, 7),
                        )}
                      />
                      <Typography
                        className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800 min-w-[60px]"
                        isBold
                      >
                        円/月
                      </Typography>
                    </div>
                  </div>
                )}

                {management_fee_type == INPUT_MANAGEMENT_FEE_TYPE.rate && (
                  <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                    <div className="flex items-center min-w-[180px] lg:min-w-[230px] pb-[10px]">
                      <Typography className="mr-[10px] text-[14px] text-black-800" isBold>
                        建物・賃貸管理手数料
                      </Typography>
                    </div>
                    <div className="flex flex-auto items-baseline">
                      <Typography
                        className="pr-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800 min-w-[60px]"
                        isBold
                      >
                        賃料の
                      </Typography>
                      <NumberFieldDecimal
                        isFullWidth
                        name="input_estate_investment.management_fee_rate_percent"
                        validate={composeValidators(
                          required,
                          zeroOrMoreNumber,
                          (value: any) => commaNumberMaxLength(value, 6),
                        )}
                      />
                      <Typography
                        className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                        isBold
                      >
                        ％
                      </Typography>
                    </div>
                  </div>
                )}
              </Grid>

              <Grid className="pb-[16px] grid-cols-1 gap-x-[40px] gap-y-[16px]">
                <div className="items-baseline pb-[16px] border-dashed border-b-2 border-black-300">
                  <div className="md:flex items-center py-20 text-14">
                    <Typography className="pb-10 md:mr-30 md:pb-0">
                      確定申告書類はお手元にありますか？
                    </Typography>
                    <div className="flex items-baseline mt-8 md:mt-0">
                      <RadioGroup
                        name="tax_paper_presence_type"
                        isDispRow
                        datas={TAX_PAPER_PRESENCE_TYPE_RADIO_ITEMS}
                      />
                    </div>
                  </div>
                  {tax_paper_presence_type == TAX_PAPER_PRESENCE_TYPE.yes && (
                    <div className="md:max-w-[85%] w-full">
                      <Typography className="text-black-600 my-[10px] text-[14px]">
                        白色申告をされる場合は、収支内訳書の２枚目の「減価償却費の計算」の項目をご参照ください
                        <br />
                        青色申告をされる場合は、青色申告決算書の３枚目の「減価償却費の計算」の項目をご参照ください
                      </Typography>
                      <div>
                        <img
                          src={DepreciationTable}
                          alt="depreciation table"
                          onClick={() => setIsImage1ModalOpen(true)}
                        />
                      </div>
                      <Typography className="text-black-800 text-center my-[10px] text-[14px]">
                        ※ 画像をクリックすると拡大イメージが表示されます
                      </Typography>
                    </div>
                  )}
                </div>
              </Grid>

              <Grid className="pb-[64px] grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px]">
                <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                  <div className="items-center mr-[10px] min-w-[180px] lg:min-w-[230px] pb-[10px]">
                    <Typography className="mb-[10px] text-[14px] text-black-800" isBold>
                      設備の耐用年数
                    </Typography>
                    {tax_paper_presence_type == TAX_PAPER_PRESENCE_TYPE.yes && (
                      <Typography className="text-[14px] text-black-600">
                        「設備」に該当する項目の赤枠①
                        <br />
                        「耐用年数」を入力ください
                      </Typography>
                    )}
                  </div>
                  <div className="flex flex-auto items-baseline">
                    <NumberField
                      widthClassName="w-full min-w-[50px]"
                      name="input_estate_investment.equipment_durable_years"
                      validate={composeValidators(
                        required,
                        zeroOrMoreNumber,
                        (value: any) => maxLength(value, 3),
                      )}
                    />
                    <Typography
                      className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800 min-w-[70px]"
                      isBold
                    >
                      年
                    </Typography>
                  </div>
                </div>

                <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                  <div className="items-center mr-[10px] min-w-[180px] lg:min-w-[230px] pb-[10px]">
                    <Typography className="mb-[10px] text-[14px] text-black-800" isBold>
                      設備の償却費合計
                    </Typography>
                    {tax_paper_presence_type == TAX_PAPER_PRESENCE_TYPE.yes && (
                      <Typography className="text-[14px] text-black-600">
                        「設備」に該当する項目の赤枠②
                        <br />
                        「本年度の償却費合計」を入力ください
                      </Typography>
                    )}
                  </div>
                  <div className="flex flex-auto items-baseline">
                    <NumberField
                      isFullWidth
                      name="input_estate_investment.equipment_depreciation"
                      validate={composeValidators(
                        required,
                        zeroOrMoreNumber,
                        (value: any) => commaNumberMaxLength(value, 10),
                      )}
                    />
                    <Typography
                      className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800 min-w-[60px]"
                      isBold
                    >
                      円/年
                    </Typography>
                  </div>
                </div>

                <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                  <div className="items-center mr-[10px] min-w-[180px] lg:min-w-[230px] pb-[10px]">
                    <Typography className="mb-[10px] text-[14px] text-black-800" isBold>
                      建物の耐用年数
                    </Typography>
                    {tax_paper_presence_type == TAX_PAPER_PRESENCE_TYPE.yes && (
                      <Typography className="text-[14px] text-black-600">
                        「建物」に該当する項目の赤枠①
                        <br />
                        「耐用年数」を入力ください
                      </Typography>
                    )}
                  </div>
                  <div className="flex flex-auto items-baseline">
                    <NumberField
                      widthClassName="w-full min-w-[50px]"
                      name="input_estate_investment.building_durable_years"
                      validate={composeValidators(
                        required,
                        zeroOrMoreNumber,
                        (value: any) => maxLength(value, 3),
                      )}
                    />
                    <Typography
                      className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800 min-w-[70px]"
                      isBold
                    >
                      年
                    </Typography>
                  </div>
                </div>

                <div className="md:flex justify-between pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                  <div>
                    <div className="items-center mr-[10px] min-w-[180px] lg:min-w-[230px] pb-[10px]">
                      <Typography className="mb-[10px] text-[14px] text-black-800" isBold>
                        建物の償却費合計
                      </Typography>
                      {tax_paper_presence_type == TAX_PAPER_PRESENCE_TYPE.yes && (
                        <Typography className="text-[14px] text-black-600">
                          「建物」に該当する項目の赤枠②
                          <br />
                          「本年度の償却費合計」を入力ください
                        </Typography>
                      )}
                    </div>
                  </div>

                  <div className="flex flex-auto items-baseline md:mt-0 mt-[10px]">
                    <NumberField
                      isFullWidth
                      name="input_estate_investment.building_depreciation"
                      validate={composeValidators(
                        required,
                        zeroOrMoreNumber,
                        (value: any) => commaNumberMaxLength(value, 10),
                      )}
                    />
                    <Typography
                      className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800 min-w-[60px]"
                      isBold
                    >
                      円/年
                    </Typography>
                  </div>
                </div>

                <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-center">
                  <div className="flex items-center min-w-[210px] lg:min-w-[230px] pb-[10px]">
                    <Typography className="mr-[10px] text-[14px] text-black-800" isBold>
                      固定資産税・
                      <br className="hidden md:block" />
                      都市計画税
                    </Typography>

                    <Button
                      className="text-[12px] bg-white border border-black-600 border-solid h-37 rounded-4 w-80 hover:bg-black-200 block mr-10 my-[10px]"
                      children="概算を入力"
                      onClick={() =>
                        handleClickPropertyTaxButton(property_price_manyen, change)
                      }
                    />
                  </div>
                  <div className="flex flex-auto items-baseline">
                    <NumberField
                      name="input_estate_investment.property_tax"
                      validate={composeValidators(
                        required,
                        zeroOrMoreNumber,
                        (value: any) => commaNumberMaxLength(value, 10),
                      )}
                      isFullWidth
                    />
                    <Typography
                      className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800 min-w-[fit-content]"
                      isBold
                    >
                      円/年
                    </Typography>
                  </div>
                </div>

                <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                  <div className="flex items-center min-w-[180px] lg:min-w-[230px] pb-[10px]">
                    <Typography className="text-[14px] text-black-800" isBold>
                      その他の経費
                    </Typography>
                    <div className="mx-[10px]">
                      <Tooltip
                        item="※建物・賃貸管理手数料、修繕積立金、リフォーム費、固定資産税、ローン、賃貸管理手数料以外で不動産にかかる支出を入力してください。"
                        itemClassName="w-[116px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                      >
                        <HelpCircleIcon className="hidden md:block" />
                      </Tooltip>
                    </div>
                  </div>
                  <div className="flex flex-auto items-baseline">
                    <NumberField
                      isFullWidth
                      name="input_estate_investment.other_expenses"
                      validate={composeValidators(
                        required,
                        zeroOrMoreNumber,
                        (value: any) => commaNumberMaxLength(value, 10),
                      )}
                    />
                    <Typography
                      className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800 min-w-[60px]"
                      isBold
                    >
                      円/年
                    </Typography>
                  </div>
                </div>

                <div className="md:flex justify-between pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                  <div className="flex items-center min-w-[180px] lg:min-w-[230px] pb-[10px]">
                    <Typography className="mr-[10px] text-[14px] text-black-800" isBold>
                      空室率の設定方法
                    </Typography>
                  </div>

                  <SelectBox
                    isFullWidth
                    placeholder="選択してください"
                    name="input_estate_investment.vacancy_rate_type"
                    options={INPUT_VACANCY_SETTING_TYPE_RADIO_ITEMS}
                    validate={required}
                  />
                </div>

                {vacancy_rate_type == INPUT_VACANCY_SETTING_TYPE.own && (
                  <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                    <div className="flex items-center min-w-[180px] lg:min-w-[230px] pb-[10px]">
                      <Typography className="text-[14px] text-black-800" isBold>
                        空室率
                      </Typography>
                      <div className="mx-[8px]">
                        <Tooltip
                          itemClassName="w-[330px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                          item={
                            <div>
                              <Typography className="pb-[5px]" isBold>
                                空室率の計算方法
                              </Typography>
                              <Typography className="pb-[5px]">
                                ５部屋あるアパートの場合を例に考えてみます。
                                まず初めに、10年間常に全ての部屋が満室の場合を考えると
                                ５部屋 x １２０ヶ月 = ６００ヶ月 となります。
                              </Typography>
                              <Typography className="pb-[5px]">
                                次に、１０年間の間にどのくらい空室の期間があるかを考えます。
                                必要な数字は、①平均的な転出の頻度と、②転出後次の入居者が
                                決まるまでの平均的な期間です。
                                ①平均的な転出の頻度が２４ヶ月（=２年間）に1回で、②次の入居者が
                                決まるまでの平均的な期間が３ヶ月だとすれば、 ５部屋 x
                                (１２０ヶ月 / ①２４ヶ月 x ②３ヶ月) = ７５ヶ月 となります。
                              </Typography>
                              <Typography>
                                ７５ヶ月 / ６００ヶ月 = ０.１２５ ≒ １２.５％
                                が空室率となります。
                              </Typography>
                            </div>
                          }
                        >
                          <HelpCircleIcon className="hidden md:block" />
                        </Tooltip>
                      </div>
                    </div>
                    <div className="flex flex-auto items-baseline">
                      <NumberFieldDecimal
                        name="input_estate_investment.vacancy_rate_percent"
                        isFullWidth
                        validate={composeValidators(
                          required,
                          zeroOrMoreNumber,
                          (value: any) => commaNumberMaxLength(value, 6),
                        )}
                      />
                      <Typography
                        className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                        isBold
                      >
                        ％
                      </Typography>
                    </div>
                  </div>
                )}

                {vacancy_rate_type == INPUT_VACANCY_SETTING_TYPE.auto && (
                  <>
                    <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                      <div className="flex items-center min-w-[180px] lg:min-w-[230px] pb-[10px]">
                        <Typography className="text-[14px] text-black-800" isBold>
                          保有部屋数
                        </Typography>
                        <div className="mx-[10px]">
                          <Tooltip
                            item="※区分の場合は１と入力してください。"
                            itemClassName="w-[116px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                          >
                            <HelpCircleIcon className="hidden md:block" />
                          </Tooltip>
                        </div>
                      </div>
                      <div className="flex flex-auto items-baseline">
                        <NumberField
                          isFullWidth
                          name="input_estate_investment.room_count"
                          validate={composeValidators(
                            required,
                            zeroOrMoreNumber,
                            (value: any) => maxLength(value, 3),
                          )}
                        />
                        <Typography
                          className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800 min-w-[60px]"
                          isBold
                        >
                          部屋
                        </Typography>
                      </div>
                    </div>

                    <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                      <div className="flex items-center min-w-[180px] lg:min-w-[230px] mb-[10px] md:mb-0">
                        <Typography
                          className="mr-[10px] text-[14px] text-black-800"
                          isBold
                        >
                          平均的な転出の頻度
                        </Typography>
                      </div>
                      <div className="flex flex-auto items-baseline">
                        <NumberField
                          isFullWidth
                          name="input_estate_investment.move_out_frequency"
                          validate={composeValidators(
                            required,
                            zeroOrMoreNumber,
                            (value: any) => maxLength(value, 3),
                          )}
                        />
                        <Typography
                          className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800 min-w-[fit-content]"
                          isBold
                        >
                          ヶ月に１回
                        </Typography>
                      </div>
                    </div>

                    <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-center">
                      <div className="flex items-center min-w-[180px] lg:min-w-[230px] mb-[10px] md:mb-0">
                        <Typography
                          className="mr-[10px] text-[14px] text-black-800"
                          isBold
                        >
                          次の入居者が決まるまでの
                          <br className="hidden md:block" />
                          平均的な期間
                        </Typography>
                      </div>

                      <div className="flex flex-auto items-baseline">
                        <NumberField
                          isFullWidth
                          name="input_estate_investment.time_between"
                          validate={composeValidators(
                            required,
                            zeroOrMoreNumber,
                            (value: any) => maxLength(value, 3),
                          )}
                        />
                        <Typography
                          className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800 min-w-[40px]"
                          isBold
                        >
                          ヶ月
                        </Typography>
                      </div>
                    </div>

                    <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                      <Typography
                        className="min-w-[180px] lg:min-w-[230px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                        isBold
                      >
                        空室率
                      </Typography>
                      <div className="flex flex-auto items-baseline">
                        <NumberFieldDecimal
                          isFullWidth
                          name="input_estate_investment.vacancy_rate_percent"
                          validate={composeValidators(
                            required,
                            zeroOrMoreNumber,
                            (value: any) => maxLength(value, 3),
                          )}
                          disabled
                        />
                        <Typography
                          className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800 min-w-[60px]"
                          isBold
                        >
                          ％
                        </Typography>
                      </div>
                    </div>
                  </>
                )}
              </Grid>

              <CircleTitle className="pb-[32px]" title="ローンについて" />
              <Grid className="pb-[64px] grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px]">
                <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                  <div className="flex items-center min-w-[180px] lg:min-w-[230px] pb-[10px]">
                    <Typography className="mr-[10px] text-[14px] text-black-800" isBold>
                      ローン有無
                    </Typography>
                  </div>
                  <SelectBox
                    placeholder="選択してください"
                    name="input_estate_investment.loan_flg"
                    options={[
                      {
                        value: API_FLAG.on,
                        label: 'ローンあり',
                      },
                      {
                        value: API_FLAG.off,
                        label: 'ローンなし',
                      },
                    ]}
                    validate={required}
                    isFullWidth
                  />
                </div>

                {loan_flg == API_FLAG.on && (
                  <>
                    <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                      <div className="flex items-center min-w-[180px] lg:min-w-[230px] pb-[10px]">
                        <Typography
                          className="mr-[10px] text-[14px] text-black-800"
                          isBold
                        >
                          返済計画表の有無
                        </Typography>
                      </div>
                      <SelectBox
                        placeholder="選択してください"
                        name="input_estate_investment.repayment_chart"
                        options={[
                          {
                            value: API_FLAG.on,
                            label: '返済計画表あり',
                          },
                          {
                            value: API_FLAG.off,
                            label: '返済計画表なし',
                          },
                        ]}
                        validate={required}
                        isFullWidth
                      />
                    </div>
                  </>
                )}

                {loan_flg == API_FLAG.on && repayment_chart == API_FLAG.off && (
                  <>
                    <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-center">
                      <div className="flex items-center min-w-[212px] lg:min-w-[230px] pb-[10px]">
                        <Typography
                          className="mr-[10px] text-[14px] text-black-800"
                          isBold
                        >
                          過去の繰上げ返済の有無
                        </Typography>
                      </div>
                      <SelectBox
                        placeholder="選択してください"
                        name="input_estate_investment.advance_repayment_type"
                        options={INPUT_ADVANCE_REPAYMENT_TYPE_RADIO_ITEMS}
                        validate={required}
                        isFullWidth
                      />
                    </div>
                    <div className="hidden md:block" />

                    {advance_repayment_type == API_FLAG.on && (
                      <>
                        <div className="pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                          <FieldArray name="input_estate_investment.input_estate_loan_calculations_attributes">
                            {({ fields }) => (
                              <>
                                {fields.map((name, index) => (
                                  <div key={name} className="mb-[30px]">
                                    {index > 0 && (
                                      <CrossIcon
                                        onClick={() => fields.remove(index)}
                                        className="md:mr-[-35px] ml-auto md:mb-[11px] cursor-pointer"
                                      />
                                    )}
                                    <div className="md:flex flex-auto items-center mb-[16px]">
                                      <Typography
                                        className="min-w-[180px] pb-[10px] lg:pb-0 text-[14px] text-black-800 flex items-center"
                                        isBold
                                      >
                                        繰上げ返済日
                                      </Typography>
                                      <div className="flex flex-auto items-center">
                                        <SelectBox
                                          className="mr-[10px]"
                                          name={`${name}advance_repayment_year`}
                                          placeholder="年を選択"
                                          options={buildTwoTypesRangeYearSelectItems(
                                            THIS_YEAR_NUMBER - 30,
                                            THIS_YEAR_NUMBER,
                                            true,
                                          )}
                                          validate={required}
                                          isFullWidth
                                        />
                                        <SelectBox
                                          placeholder="月を選択"
                                          name={`${name}advance_repayment_month`}
                                          options={MONTH_KANJI_SELECT_ITEMS}
                                          validate={required}
                                          isFullWidth
                                        />
                                      </div>
                                    </div>

                                    <div className="md:flex flex-auto items-center mb-[16px]">
                                      <Typography
                                        className="min-w-[180px] pb-[10px] lg:pb-0 text-[14px] text-black-800 flex items-center"
                                        isBold
                                      >
                                        繰り上げ返済額
                                      </Typography>
                                      <div className="flex flex-auto items-center">
                                        <NumberField
                                          name={`${name}repayment_amount`}
                                          validate={composeValidators(
                                            required,
                                            zeroOrMoreNumber,
                                            (value: any) =>
                                              commaNumberMaxLength(value, 10),
                                          )}
                                          isFullWidth
                                        />
                                        <Typography className="ml-[10px] pb-[10px] lg:pb-0 text-[14px] text-black-800 flex items-center">
                                          円
                                        </Typography>
                                      </div>
                                    </div>

                                    <div className="md:flex flex-auto items-center mb-[16px]">
                                      <Typography
                                        className="min-w-[180px] pb-[10px] lg:pb-0 text-[14px] text-black-800 flex items-center"
                                        isBold
                                      >
                                        返済方法
                                      </Typography>

                                      <SelectBox
                                        name={`${name}repayment_type`}
                                        options={INPUT_REPAYMENT_TYPE_SELECT_ITEMS}
                                        validate={required}
                                        isFullWidth
                                      />
                                    </div>
                                  </div>
                                ))}
                                <Button
                                  className="ml-auto border-[0.5px] border-black-700 rounded-[4px]"
                                  onClick={() =>
                                    handleAddInputEstateLoanCalculationBlock(
                                      [...fields.value],
                                      fields.value.length + 1,
                                      change,
                                    )
                                  }
                                >
                                  <div className="flex items-center">
                                    <PlusCircleIcon className="ml-[10px] mt-[2px]" />
                                    <Typography
                                      className="min-w-[120p] p-[10px] text-[14px] text-black-800 flex items-center"
                                      isBold
                                    >
                                      入力枠を追加する
                                    </Typography>
                                  </div>
                                </Button>
                              </>
                            )}
                          </FieldArray>
                        </div>
                        <div className="hidden md:block" />
                      </>
                    )}

                    <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                      <div className="flex items-center min-w-[180px] lg:min-w-[164px] mb-[10px]">
                        <Typography
                          className="mr-[10px] text-[14px] text-black-800"
                          isBold
                        >
                          当初の借入金額
                        </Typography>
                      </div>
                      <div className="flex flex-auto items-baseline">
                        <NumberField
                          isFullWidth
                          name="input_estate_investment.started_borrowing_amount_manyen"
                          validate={composeValidators(
                            required,
                            zeroOrMoreNumber,
                            (value: any) => commaNumberMaxLength(value, 6),
                          )}
                        />
                        <Typography
                          className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800 min-w-[40px]"
                          isBold
                        >
                          万円
                        </Typography>
                      </div>
                    </div>

                    <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                      <div className="flex items-center min-w-[180px] lg:min-w-[164px] mb-[10px]">
                        <Typography
                          className="mr-[10px] text-[14px] text-black-800"
                          isBold
                        >
                          返済期間
                        </Typography>
                      </div>
                      <div className="flex flex-auto items-baseline">
                        <NumberField
                          isFullWidth
                          name="input_estate_investment.repayment_period_years"
                          validate={composeValidators(
                            required,
                            zeroOrMoreNumber,
                            (value: any) => maxLength(value, 2),
                          )}
                        />
                        <Typography
                          className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800 min-w-[40px]"
                          isBold
                        >
                          年間
                        </Typography>
                      </div>
                    </div>

                    <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                      <div className="flex items-center min-w-[180px] lg:min-w-[164px] mb-[10px]">
                        <Typography
                          className="mr-[10px] text-[14px] text-black-800"
                          isBold
                        >
                          借入金利
                        </Typography>
                      </div>
                      <div className="flex flex-auto items-baseline">
                        <NumberFieldDecimal
                          isFullWidth
                          name="input_estate_investment.repayment_monthly_interest_percent"
                          validate={composeValidators(
                            required,
                            zeroOrMoreNumber,
                            oneHundredLessThanNumber,
                          )}
                        />
                        <Typography
                          className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                          isBold
                        >
                          ％
                        </Typography>
                      </div>
                    </div>
                  </>
                )}

                {loan_flg == API_FLAG.on && repayment_chart == API_FLAG.on && (
                  <>
                    <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-center">
                      <div className="flex items-center min-w-[118px] lg:min-w-[130px] mb-[10px] lg:mb-0">
                        <Typography
                          className="mr-[10px] text-[14px] text-black-800"
                          isBold
                        >
                          今月の
                          <br className="hidden lg:block" />
                          返済予定日
                        </Typography>
                      </div>
                      <div className="flex flex-auto items-baseline">
                        <SelectBox
                          isFullWidth
                          className="mx-[10px]"
                          name="input_estate_investment.expected_return_year"
                          placeholder="年を選択"
                          options={buildTwoTypesRangeYearSelectItems(
                            THIS_YEAR_NUMBER,
                            THIS_YEAR_NUMBER + 50,
                          )}
                          validate={required}
                        />
                        <SelectBox
                          isFullWidth
                          className="mx-[10px] md:max-w-[74px]"
                          placeholder="月を選択"
                          name="input_estate_investment.expected_return_month"
                          options={MONTH_KANJI_SELECT_ITEMS}
                          validate={required}
                        />
                        <SelectBox
                          isFullWidth
                          className="mx-[10px] md:max-w-[74px]"
                          placeholder="日を選択"
                          name="input_estate_investment.expected_return_day"
                          options={NEW_DAY_KANJI_SELECT_ITEMS}
                          validate={required}
                        />
                      </div>
                    </div>

                    <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-center">
                      <div className="flex items-center min-w-[230px] lg:min-w-[230px] pb-[10px]">
                        <Typography
                          className="mr-[10px] text-[14px] text-black-800"
                          isBold
                        >
                          今月の返済予定日における
                          <br className="hidden lg:block" />
                          残元金合計
                        </Typography>
                      </div>
                      <div className="flex flex-auto items-baseline">
                        <NumberField
                          isFullWidth
                          name="input_estate_investment.total_amount"
                          validate={composeValidators(
                            required,
                            zeroOrMoreNumber,
                            (value: any) => commaNumberMaxLength(value, 10),
                          )}
                        />
                        <Typography
                          className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                          isBold
                        >
                          円
                        </Typography>
                      </div>
                    </div>

                    <div className="pb-[16px] border-black-300 border-dashed border-b-2">
                      <div className="md:flex items-center">
                        <div className="flex items-center min-w-[180px] lg:min-w-[230px] pb-[10px]">
                          <Typography
                            className="mr-[10px] text-[14px] text-black-800"
                            isBold
                          >
                            現在の金利
                          </Typography>
                          <div className="mx-[10px]">
                            <Tooltip
                              itemClassName="w-[330px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                              item={
                                <div>
                                  <CityBankInterestRateTable />
                                </div>
                              }
                            >
                              <HelpCircleIcon className="hidden md:block" />
                            </Tooltip>
                          </div>
                        </div>

                        <div className="flex flex-auto items-baseline">
                          <NumberFieldDecimal
                            isFullWidth
                            name="input_estate_investment.input_rate_changes_attributes[0].changed_rate_percent"
                            validate={composeValidators(
                              required,
                              zeroOrMoreNumber,
                              (value: any) => commaNumberMaxLength(value, 7),
                            )}
                          />
                          <Typography
                            className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                            isBold
                          >
                            ％
                          </Typography>
                        </div>
                      </div>
                    </div>

                    <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                      <div className="flex items-center min-w-[180px] lg:min-w-[230px] pb-[10px]">
                        <Typography
                          className="mr-[10px] text-[14px] text-black-800"
                          isBold
                        >
                          金利の変化有無
                        </Typography>
                      </div>

                      <SelectBox
                        placeholder="選択してください"
                        name="input_estate_investment.rate_change_type"
                        options={INPUT_RATE_CHANGE_TYPE_RADIO_ITEMS}
                        validate={required}
                        isFullWidth
                      />
                    </div>

                    {rate_change_type == API_FLAG.on && (
                      <div className="pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                        <div className="flex items-center pb-[10px]">
                          <Typography
                            className="mr-[10px] text-[14px] text-black-800"
                            isBold
                          >
                            金利の変化
                          </Typography>
                        </div>

                        <FieldArray name="input_estate_investment.input_rate_changes_other_attributes">
                          {({ fields }) => (
                            <>
                              {fields.map((name, index) => (
                                <div
                                  key={name}
                                  className="flex items-baseline flex-auto mb-[10px]"
                                >
                                  <SelectBox
                                    className="mr-[10px]"
                                    name={`${name}rate_start_year`}
                                    placeholder="年を選択"
                                    options={buildTwoTypesRangeYearSelectItems(
                                      THIS_YEAR_NUMBER,
                                      THIS_YEAR_NUMBER + 50,
                                    )}
                                    validate={required}
                                    isFullWidth
                                  />

                                  <SelectBox
                                    className="md:max-w-[100px]"
                                    name={`${name}rate_start_month`}
                                    placeholder="月を選択"
                                    options={MONTH_KANJI_SELECT_ITEMS}
                                    validate={
                                      annual_rate_of_decline_type == API_FLAG.on
                                        ? required
                                        : undefined
                                    }
                                    isFullWidth
                                  />
                                  <Typography className="min-w-[fit-content] px-[10px] text-[14px] text-black-800">
                                    から
                                  </Typography>

                                  <NumberFieldDecimal
                                    className="w-full md:max-w-[74px]"
                                    name={`${name}changed_rate_percent`}
                                    validate={composeValidators(
                                      required,
                                      zeroGreaterThanNumber,
                                      (value: any) => commaNumberMaxLength(value, 6),
                                    )}
                                  />
                                  <Typography className="px-[10px] text-[14px] text-black-800">
                                    ％
                                  </Typography>
                                  {index > 0 ? (
                                    <Button>
                                      <CrossIcon onClick={() => fields.remove(index)} />
                                    </Button>
                                  ) : (
                                    <div className="min-w-[16px]" />
                                  )}
                                </div>
                              ))}
                              <Button
                                className="ml-auto border-[0.5px] border-black-700 rounded-[4px]"
                                onClick={() =>
                                  handleAddInputRateChangeBlock(
                                    [...fields.value],
                                    fields.value.length + 1,
                                    change,
                                  )
                                }
                              >
                                <div className="flex items-center">
                                  <PlusCircleIcon className="ml-[10px] mt-[2px]" />
                                  <Typography
                                    className="min-w-[120p] p-[10px] text-[14px] text-black-800 flex items-center"
                                    isBold
                                  >
                                    入力枠を追加する
                                  </Typography>
                                </div>
                              </Button>
                            </>
                          )}
                        </FieldArray>
                      </div>
                    )}

                    <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                      <div className="flex items-center min-w-[200px] mb-[10px] lg:mb-0">
                        <Typography
                          className="mr-[10px] text-[14px] text-black-800"
                          isBold
                        >
                          毎月のローン返済額
                        </Typography>
                      </div>
                      <div className="flex flex-auto items-baseline">
                        <NumberField
                          isFullWidth
                          name="input_estate_investment.repayment_monthly"
                          validate={composeValidators(
                            required,
                            zeroOrMoreNumber,
                            (value: any) => commaNumberMaxLength(value, 10),
                          )}
                        />
                        <Typography
                          className="pl-[10px] pb-[10px] md:pb-0 text-[14px] text-black-800"
                          isBold
                        >
                          円
                        </Typography>
                      </div>
                    </div>

                    <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                      <div className="flex items-center min-w-[118px] lg:min-w-[130px] mb-[10px] lg:mb-0">
                        <Typography
                          className="mr-[10px] text-[14px] text-black-800"
                          isBold
                        >
                          最終回
                          <br className="hidden lg:block" />
                          返済予定日
                        </Typography>
                      </div>
                      <div className="flex flex-auto items-baseline">
                        <SelectBox
                          isFullWidth
                          className="mx-[10px]"
                          name="input_estate_investment.last_repayment_year"
                          placeholder="年を選択"
                          options={buildTwoTypesRangeYearSelectItems(
                            THIS_YEAR_NUMBER,
                            THIS_YEAR_NUMBER + 50,
                          )}
                          validate={required}
                        />
                        <SelectBox
                          isFullWidth
                          className="mx-[10px]"
                          placeholder="月を選択"
                          name="input_estate_investment.last_repayment_month"
                          options={MONTH_KANJI_SELECT_ITEMS}
                          validate={required}
                        />
                        <SelectBox
                          isFullWidth
                          className="mx-[10px]"
                          placeholder="日を選択"
                          name="input_estate_investment.last_repayment_day"
                          options={NEW_DAY_KANJI_SELECT_ITEMS}
                          validate={required}
                        />
                      </div>
                    </div>
                  </>
                )}

                {loan_flg == API_FLAG.on && (
                  <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
                    <div className="flex items-center min-w-[180px] lg:min-w-[230px] pb-[10px]">
                      <Typography className="mr-[10px] text-[14px] text-black-800" isBold>
                        団体信用生命保険
                      </Typography>
                    </div>

                    <SelectBox
                      placeholder="選択してください"
                      name="input_estate_investment.life_insurance"
                      options={INPUT_LIFE_INSURANCE_TYPE_RADIO_ITEMS}
                      validate={required}
                      isFullWidth
                    />
                  </div>
                )}
              </Grid>

              <WholeSubmitErrorMessage submitErrors={submitErrors} />
              <SubmitErrorMessage show={!submitErrors && submitFailed} />

              <ButtonPrimary
                className={clsx(
                  { ['opacity-50']: Object.keys(errors).length > 0 },
                  'w-[246px] h-[64px] mx-auto mb-[16px] ',
                )}
                onClick={() => handleSubmit(values)}
              >
                <Typography
                  className="relative bottom-[2px] pl-[30px] text-[16px] text-white flex-auto"
                  isBold
                >
                  保存する
                </Typography>
                <ChevronRightIcon className="mr-[17px]" />
              </ButtonPrimary>
              <Button
                className="mx-auto text-[14px] text-black-700"
                onClick={props.onClose}
              >
                キャンセル
              </Button>
            </form>
          )
        }}
      />
      <ImageMagnifiableModal
        src={DepreciationTable}
        alt="depreciation table"
        isOpen={isImage1ModalOpen}
        onClose={() => setIsImage1ModalOpen(false)}
      />
    </>
  )
}

export default MyDataEstateInvestmentForm
