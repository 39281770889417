import React, { FC } from 'react'
import MyDataPanelCommon from '@/templates/v2/myData/_myDataPanelCommon'
import { convertYenToDecimalPointManyen, termLabel } from '@/models/commonsModelFunc'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'

interface MyDataPanelBalanceProps {
  term?: string
}

const MyDataPanelBalance: FC<MyDataPanelBalanceProps> = ({ term }) => {
  const { summaryBudgets } = useSelector((state: RootState) => state.summaries)

  return (
    <MyDataPanelCommon
      term={term}
      title={`${termLabel(term)}収支`}
      balance={convertYenToDecimalPointManyen(
        summaryBudgets.current_budgets_info.balance_total,
        1,
      )}
      defference={convertYenToDecimalPointManyen(
        summaryBudgets.current_budgets_info.balance_defference,
        1,
      )}
      bgColorClassName="bg-secondary-50"
      chipBgColorClassName="bg-secondary-500"
      defferenceColorClassName="text-secondary-500"
    />
  )
}

export default MyDataPanelBalance
