import React, { FC } from 'react'

import Typography from '@/components/v2/atoms/Typography'

const LoanCustomizedFooter: FC = () => {
  return (
    <Typography className="pl-[8px] text-[14px] text-black-800" isBold>
      住宅ローンをペアローン・収入合算で借入していて、投資用ローンに借換えをした場合、贈与になりますが贈与税の計算は入っていません。
    </Typography>
  )
}

export default LoanCustomizedFooter
