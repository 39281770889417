import React from 'react'
import { YAxis, Label } from 'recharts'

interface RenderCustomYAxisProps {
  /** データキー */
  dataKey: string
  /** true: 縦, false: 横 */
  isVertical?: boolean
  /** true: 非表示, false: 表示 */
  isHide?: boolean
  ticks?: number[]
}

const renderCustomYAxis = (props: RenderCustomYAxisProps) => {
  const { dataKey, isVertical, isHide, ticks } = props

  return (
    <YAxis
      type={isVertical ? 'category' : 'number'}
      dataKey={dataKey}
      hide={isHide}
      axisLine={{ strokeWidth: 2, stroke: '#666666' }}
      tickLine={{ stroke: '#666666' }}
      tickSize={9}
      tick={{ dx: -4, fontSize: 10, fill: '#666666' }}
      ticks={ticks}
      padding={!isHide ? { top: 50, bottom: 27 } : undefined}
      domain={ticks ? [ticks[0], ticks[ticks.length - 1]] : undefined}
    >
      <Label
        dx={8}
        style={{ fontSize: 10, fill: '#666666' }}
        position="insideTopLeft"
        value="(万円)"
      />
    </YAxis>
  )
}

export default renderCustomYAxis
