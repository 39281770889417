import {
  find as _find,
  set as _set,
  partition as _partition,
  sortBy as _sortBy,
  includes as _includes,
} from 'lodash'
import moment from 'moment'

// 本人配偶者フラグ
export const PERSON_FLG = {
  person: 0,
  spouse: 1,
}

// 性別
export const SEX = {
  men: 0,
  women: 1,
  other: 2,
}

// 職業
export const PROFESSION = {
  owner: 0,
  companyExecutive: 1,
  regularEmployee: 2,
  contractEmployee: 3,
  partTimeJob: 4,
  freelance: 5,
  student: 6,
  other: 7,
  housewife: 8,
}

// 業種
export const INDUSTRY = {
  average: 0,
  mining: 1,
  construction: 2,
  manufacturing: 3,
  lifeline: 4,
  telecommunications: 5,
  transportation: 6,
  wholesale: 7,
  finance: 8,
  realEstate: 9,
  restaurant: 10,
  medical: 11,
  education: 12,
  postOffice: 13,
  research: 14,
  lifeService: 15,
  otherService: 16,
}

// 従業員規模
export const EMPLOYEE_SIZE = {
  average: 0,
  thousand: 1,
  hundred: 2,
  ten: 3,
  one: 4,
}

// 加入保険/年金
export const INSURANCE_PENSION = {
  welfarePension: 0,
  mutualAidPension: 1,
  nationalPension: 2,
  notSubscribed: 3,
}

// 家族タイプ
export const FAMILY_TYPE = {
  adult: 'adult',
  child: 'child',
}

// 家族収入タイプ
export const FAMILY_INCOME_TYPE = {
  bonus: 'bonus',
  my_home: 'my_home',
}

// 家族イベントタイプ
export const FAMILY_EVENT_TYPE = {
  adult: 'adult',
  child: 'child',
  money: 'money',
}

import { NEW_SEX_RADIO_ITEMS } from '@/constants/formItem/commons'
import { isDateExisted, isNotEmptyValue } from '@/models/commonsModelFunc'
import { FamilyStructureCommonValues } from '@/models/familyStructuresModel'
import { Family } from '@/models/futures/futureBasesModel'

export const convertPersonFlg = (personFlg?: number | null) => {
  if (personFlg === PERSON_FLG.person) return 'ご本人'
  if (personFlg === PERSON_FLG.spouse) return '配偶者'

  return ''
}

export const convertSex = (sexType: number | null) =>
  _find(NEW_SEX_RADIO_ITEMS, (item) => item.value === sexType)?.label

export const calcAge = (birthday: any) => {
  const birth = moment(birthday)
  return moment().diff(birth, 'years')
}

export const validateDate = (values: FamilyStructureCommonValues) => {
  const {
    person_birth_year,
    person_birth_month,
    person_birth_day,
    spouse_birth_year,
    spouse_birth_month,
    spouse_birth_day,
    enrollment_year,
    enrollment_month,
    enrollment_day,
  } = values
  const { family_structure_children_attributes } = values.family_structure
  const errorMessages: { [key: string]: any } = { family_structure: {} }

  if (
    isNotEmptyValue(person_birth_year) &&
    isNotEmptyValue(person_birth_month) &&
    isNotEmptyValue(person_birth_day) &&
    !isDateExisted(`${person_birth_year}/${person_birth_month}/${person_birth_day}`)
  ) {
    _set(errorMessages, 'person_birth_day', '正しい日にちを入力してください。')
  }

  if (
    isNotEmptyValue(spouse_birth_year) &&
    isNotEmptyValue(spouse_birth_month) &&
    isNotEmptyValue(spouse_birth_day) &&
    !isDateExisted(`${spouse_birth_year}/${spouse_birth_month}/${spouse_birth_day}`)
  ) {
    _set(errorMessages, 'spouse_birth_day', '正しい日にちを入力してください。')
  }

  if (
    isNotEmptyValue(enrollment_year) &&
    isNotEmptyValue(enrollment_month) &&
    isNotEmptyValue(enrollment_day) &&
    !isDateExisted(`${enrollment_year}/${enrollment_month}/${enrollment_day}`)
  ) {
    _set(errorMessages, 'enrollment_day', '正しい日にちを入力してください。')
  }

  if (isNotEmptyValue(family_structure_children_attributes)) {
    family_structure_children_attributes?.map((child, index) => {
      if (
        isNotEmptyValue(child.child_birth_year) &&
        isNotEmptyValue(child.child_birth_month) &&
        isNotEmptyValue(child.child_birth_day) &&
        !isDateExisted(
          `${child.child_birth_year}/${child.child_birth_month}/${child.child_birth_day}`,
        )
      ) {
        _set(
          errorMessages,
          [
            'family_structure',
            'family_structure_children_attributes',
            `${index}`,
            'child_birth_day',
          ],
          '正しい日にちを入力してください。',
        )
      }
    })
  }

  return errorMessages
}

export const getUrlPersonFlg = () => {
  return _includes(location.pathname, 'spouse') ? PERSON_FLG.spouse : PERSON_FLG.person
}

export const isPerson = (personFlg: number) => {
  return personFlg === PERSON_FLG.person
}
